import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Autocomplete = ({
  options = [],
  value,
  placeholder,
  onChange,
  onData,
}) => {
  const autocomplete = useRef();

  const [optionsData, setOptionsData] = useState([]);
  const [query, setQuery] = useState(value);
  const [isShow, setIsShow] = useState(false);
 
  const handleInputChange = async (text) => {
    setQuery(text.toLowerCase());
    onChange(text);
    if (text.length > 2) {
      const newData = options.filter(function (item) {
        const itemData = item.x ? item.x.toUpperCase() : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      newData
        .sort(function (a, b) {
          return a.x.indexOf(text) - b.x.indexOf(text);
        })
        .slice(0, 20);

      setOptionsData(newData);
    } else {
      setOptionsData([]);
    }
  };
  const handleTwoText = async (text) => {
    setQuery(text.toLowerCase());
    onChange(text);
    if (text.length > 1) {
      const newData = options.filter(function (item) {
        const itemData = item.x ? item.x.toUpperCase() : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      newData
        .sort(function (a, b) {
          return a.x.indexOf(text) - b.x.indexOf(text);
        })
        .slice(0, 20);

      setOptionsData(newData);
    } else {
      setOptionsData([]);
    }
  };
  const handleClickOutSide = (e) => {
   
    if (!autocomplete.current.contains(e.target)) {
      setIsShow(false);
    }
  };

  const hilightSearchText = (text) => {
    var pattern = new RegExp("(" + query + ")", "gi");
    var new_text = text.replace(pattern, `<b>${query}</b>`);
    return new_text;
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, []);

  useEffect(() => {
    optionsData.length > 1 ? setIsShow(true) : setIsShow(false);
  }, [optionsData]);
  useEffect(() => {
    setQuery(value.toLowerCase());
  }, [value]);
  return (
    <>
      <Wrapper ref={autocomplete}>
        <InputField
          className="search-input"
          type="search"
          placeholder={placeholder}
          // isSearch={true}
          value={query}
          onChange={(e) => handleInputChange(e.target.value)}
        />

        {isShow && (
          <ListWrapper>
            {optionsData.map((x, index) => (
              <ListItem
                onClick={() => {
                  setQuery(x.x.toLowerCase());
                  setIsShow(false);
                  onChange(x.x);
                  onData(x.x, x.y);
                }}
                key={index}
              >
                {
                  <div
                    dangerouslySetInnerHTML={{ __html: hilightSearchText(x.x) }}
                  />
                }
              </ListItem>
            ))}
          </ListWrapper>
        )}
      </Wrapper>
      <button
        className="buttonsearch"
        onClick={() => {
          handleTwoText(query);
        }}
        type="button"
      >
        <i className="fa fa-search"></i>
      </button>
    </>
  );
};

export default Autocomplete;

const Wrapper = styled.div`
  position: relative;
  min-width: 320px;
`;

const InputField = styled.input`
  border-radius: 8px;
  background-size: 24px;
  padding-left: 35px;
  padding-right: 10px;
  font-size: 18px;
  width: 65%;
  height: 48px;
  border: 0;
  float: left;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
  position: absolute;
  top: 44px;
  z-index: 10;
  background: #fff;
  border-radius: 4px;
  width: 100%;
  max-height: 240px;
  overflow-y: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
`;

const ListItem = styled.button`
  text-align: left;
  padding: 16px 8px;
  width: 100%;
  background: #fff;
  outline: none;
  border: none;

  &:hover {
    background: #e2e2e2;
  }
`;
