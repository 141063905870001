export const primaryColor = '#0f2439';
export const elevatedBG = '#F7F8FB';
export const contrastColor = '#000000';

export const lang = [
  {
    key: 'kopyala',
    tr: 'Kopyala',
    za: 'Kopya bike',
  },
  {
    key: 'paylas',
    tr: 'Paylaş',
    za: 'Pare bike',
  },
  {
    key: 'silmekistiyormusun',
    tr: 'Silmek istiyor musnuz?',
    za: 'Ti wazenî/a ke pak bikere?',
  },
  {
    key: 'evet',
    tr: 'Evet',
    za: 'Eya',
  },
  {
    key: 'hayir',
    tr: 'Hayır',
    za: 'Ney',
  },
  {
    key: 'hakkimizda',
    tr: 'Hakkımızda',
    za: 'Derheqê Ma De',
  },
  {
    key: 'alfabe',
    tr: 'Alfabe ',
    za: 'Alfabe',
  },
  {
    key: 'iletisim',
    tr: 'İletişim',
    za: 'Têkildarîye',
  },
  {
    key: 'kisaltmalar',
    tr: 'Kısaltmalar',
    za: 'Kilmnuşteyî',
  },
  {
    key: 'zatr',
    tr: 'Zazaca-Türkçe',
    za: 'Zazakî-Tirkî',
  },
  {
    key: 'trza',
    tr: 'Tirkî-Zazakî',
    za: 'Türkçe-Zazaca',
  },
  {
    key: 'kelimeara',
    tr: 'Sözcük ara...',
    za: 'Çekuye bigêre...',
  },
  {
    key: 'dogru',
    tr: 'Doğru ',
    za: 'Rast',
  },
  {
    key: 'yanlis',
    tr: 'Yanlış ',
    za: 'Xelet',
  },
  {
    key: 'bilgi',
    tr: 'Bilgi',
    za: 'Zanayîş',
  },
  {
    key: 'favori',
    tr: 'Favori',
    za: 'Favorî',
  },
  {
    key: 'aramagecmisi',
    tr: 'Arama geçmişi ',
    za: 'Vîyarteyê agêrayîşan ',
  },
  {
    key: 'kelimeoyunu',
    tr: 'Sözcük testi',
    za: 'Testê Çekuyan',
  },
  {
    key: 'cikmakistermisin',
    tr: 'Çıkmak ister misin?',
    za: 'Ti wazenî/a ke bivejîye?',
  },
  {
    key: 'hakkimizdadetay',
    tr: `Zazano, Kürtçenin Kırmancca (Zazaca) lehçesi için hazırlanan ilk sözlük uygulamasıdır. Zazano sözcüğü Türkçede Ey Zazalar anlamına gelmektedir. Kullanıcıların platformlarda arama yaparken kolaylıkla erişebilmeleri için bu isim seçilmiştir. Zazaca öğrenenlerin, Zazaca okuryazar oranının arttırılmasını desteklemek maksadıyla hazırlanan bu uygulamada Grûba Xebate ya Vateyî (Vate Çalışma Grubu) tarafından hazırlanan Türkçe-Zazaca ve Zazaca-Türkçe sözlükleri temel alınmıştır. Grubun 37 toplantısının sonuçlarını içerir. Söz konusu iki sözlük, hakkındaki detaylı açıklamaları okumak için vate.com.tr web sitesini ziyaret edebilirsiniz.  
Kolektif bir çalışmanın sonucunda ortaya çıkan Zazano’nun hazırlanması sürecinde yer alanlar, aşağıdaki kişilerdir:

Orhan Kaya (Sponsor)
Mesut Bilir (Yazılım Mühendisi)
Sezgin Fırat (Editör)
Mutlu Can (Koordinatör)
@2024 `,
    za: `Zazano, applîkasyono tewr verên o ke seba lehçeya kirmanckî (zazakî) ya kurdkî ameyo viraştene.  Çekuya Zazano bi tirkî êna manaya Ey Zazalar. Semedo ke xebitnayoxî gama ke platforman de bigêrê bi asanî xo biresnê ci no name ame weçînayîş. Amancê nê aplîkasyonî zêdnayîşê nîsbetê musayoxan û wendoxanê zazakî rê destek dayen a. Nê aplîkasyonî de ferhengê tirkî-zazakî û ferhengê zazakî-tirkî yê Grûba Xebate ya Vateyî binge ameyî girewtene û no aplikasyon netîceyanê 37 kombîyayîşanê Grûbe îhtîva keno. Şima eşkenê seba ke derheqê nê her di ferhengan de tayê îzahatan biwanê vate.com.tr zîyaret bikerê.   

Zazano ke netîceya yew xebata kolektîfe de vejîya orte, înanê ke prosesê amadekerdişê ey de ca girewt, nê kes ê: 
   
Orhan Kaya (Sponsor)
Mesut Bilir (Muhendisê Çîynermî) 
Sezgin Fırat (Edîtor)
Mutlu Can (Koordînator)
@2024`,
  },
  {
    key: 'kisaltmalardetay',
    tr: `(b) biewnî,     	nîyade	bak
(bs) bestox			bağlaç
(cg) cografya		coğrafya
(ed) edat			ilgeç, edat
(huq.) huqûq		hukuk 
(m) makî			dişil
(mat) matematîk		matematik
(n) nêrî			eril
(nm) name			ad, isim
(pd) peyedat 		artedat (postposition)
(pr) prefîks		önek
(s) sifet			sıfat
(sf) suffîks		sonek
(sn) sînema			sinema
(TE) Teknolojîyê Enformasyonî  -	Enformasyon Teknolojisi
(ûsn) û sey nînan	  vb. (ve benzerleri)
(vr) veredat		önedat (préposition)
(z) zemîr			adıl, zamir
(zh) zafhûmar		çoğul
(zr) zerfe			belirteç, zarf`,
    za: `(b) biewnî,     	nîyade	bak
(bs) bestox			bağlaç
(cg) cografya		coğrafya
(ed) edat			ilgeç, edat
(huq.) huqûq		hukuk 
(m) makî			dişil
(mat) matematîk		matematik
(n) nêrî			eril
(nm) name			ad, isim
(pd) peyedat 		artedat (postposition)
(pr) prefîks		önek
(s) sifet			sıfat
(sf) suffîks		sonek
(sn) sînema			sinema
(TE) Teknolojîyê Enformasyonî  -	Enformasyon Teknolojisi
(ûsn) û sey nînan	  vb. (ve benzerleri)
(vr) veredat		önedat (préposition)
(z) zemîr			adıl, zamir
(zh) zafhûmar		çoğul
(zr) zerfe			belirteç, zarf`,
  },
  {
    key: 'iletisimdetay',
    tr: `zazanorg@gmail.com`,
    za: `zazanorg@gmail.com`,
  },
  {
    key: 'alfabedetay',
    tr: `Büyük Harfler
A B C Ç D E Ê F G H
I Î J K L M N O P Q R
S Ş T U Û V W X Y Z

Küçük Harfler
a b c ç d e ê f g h
i î j k l m n o p q r
s ş t u û v w x y z`,
    za: `Herfê Girdî
A B C Ç D E Ê F G H
I Î J K L M N O P Q R
S Ş T U Û V W X Y Z

Herfê Hurdî
a b c ç d e ê f g h
i î j k l m n o p q r
s ş t u û v w x y z`,
  },
];

//buyuk kucuk harf duyarliligi icin
export const regEscape = v => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

export const stil = {
  arkaRenk: '#f2dcb1',
  fontBoyut: 17,
  fontTipi: 'Verdana',
  yaziRenk: '#000000',
  formatAlign: 'left',
  satirBosluk: 'normal',
};
export const tema = [
  {arkaRenk: '#f2dcb1'},
  {fontBoyut: 17},
  {fontTipi: 'Verdana'},
  {yaziRenk: '#000000'},
  {formatAlign: 'left'},
  {satirBosluk: 'normal'},
];
export const bookCat = {
  items: [
    {label: 'zatr', value: 1},
    {label: 'trza', value: 2},
    // {label: 'İngilizce', value: 3},
  ],
};

export const keyboard = {
  id: 'klavye',
  text: 'Karekter',
  title: 'Karekter',
  items: [
    {label: 'Ê', value: 'ê'},
    {label: 'Q', value: 'q'},
    {label: 'Û', value: 'û'},
    {label: 'Î', value: 'î'},
    {label: 'W', value: 'w'},
    {label: 'X', value: 'x'},
  ],
};
export const menuList = {
  items: [
    {
      id: 'hakkimizda',
      title: 'Hakkımızda',
      details: `
   Daha önce belirtildiği gibi, seçilen sözcükler, üzerinde tartışılıp tespit edilen sözcüklerdir. Örneğin, “kurtulma” karşılığında “xelisîyayîş”, “filitîyayîş”, “qedîyayîş”, “xelasbîyayîş”, “raxelisîyayîş” sözcükleri yazılmıştır. Oysaki bir de aynı anlama gelen “reyayîş” sözcüğü vardır. “Kurtulma” karşılığı olarak “reyayîş” sözcüğünün de yazılması gerekirken Kırmancca Toplantıları’nda bu sözcük henüz ele alınmadığından şimdilik buna yer verilmemiştir. İlerde yapılacak olan toplantılarda üzerinde durulduğu zaman sözlüğün yeni baskısına bu da eklenecektir. Bu husus, yazılmamış olan diğer eşanlamlı sözcükler için de geçerlidir.
3. Diğer sözlüğümüz olan Kırmancca (Zazaca)-Türkçe Sözlük’te ise sözcüklerin farklı söyleniş biçimlerinden tespit edilebilenlerin tümüne yer verilmiş ve “b.” kısaltmasıyla yazı dilinde kullanılmak üzere seçilen forma göndermede bulunulmuştur. Örneğin, yukarda değinilenlerden “karınca” karşılığı olarak kullanılan mercueli, merjêlû, merjueli, miclewri, miclol, miclor, micmewl, mijlewreki, mijlewri, mijloki, mijloli, mijlor, mijloreki, mijlori, mijlowri, mijmewle, mijmewli, miloçike, milorçike, mircolike, mîyercila, mocla, mojela, mojla, mojle, molcela, molcila, moncle, morceli, morcila, morzela, muejla, muercela, muerceli, muleçike, muloçike formlarından her biri ayrı ayrı yazılıp karşılarına “b. morcela” diye yazılarak “morcela”ya göndermede bulunulmuştur.
4. Kırmanccanın standartlaşmasında bir adım olabileceği için, isteyenlerin yararlanabilmeleri amacıyla ilk otuz beş toplantıda tespit edilen sözcükler burada bir araya getirilmiştir. Sözlükte devlet, ülke, il ve ilçe adları gibi bazı özel adlara da yer verilmiştir. Bununla, Kırmancca yazanların bu adları aynı biçimde yazmalarına yardımcı olunması amaçlanmıştır.
5. Kırmanccadaki eril sözcüklerin yanına (n), dişil sözcüklerin yanına (m) yazılmıştır. Bazı Kırmancca sözcüklerden sonra virgül konup “-e” (veya “-ye”) yazılmıştır. Bu “-e” (veya “-ye”) sonekinden önce gelen sözcük eril formuyla yazılıdır, sözkonusu sözcük “-e” sonekini alınca dişil formuna girer. Örneğin “yazar: nuştox, -e”. Buradaki “nuştox” sözcüğü erkek yazar anlamına gelir, “-e” sonekini alınca “nuştoxe” olur ve “kadın yazar” anlamına gelir. Belirtelim ki bazı yörelerin dilinde dişil sözcükler bu “-e” yerine “-i” almakta, bazı yörelerde ise “-e” ve “-i” soneki bulunmamaktadır. Örneğin “qeleme” (kalem) yerine “qelemi” veya “qelem” de denmektedir.
6. Kırmanccada bazı sözcükler her zaman çoğul biçimiyle kullanılmaktadırlar. Böylesi sözcüklerin yanına çoğul olduklarını belirtmek için (zh) kısaltması konmuştur. “Bıyık(lar): zimbêlî (zh)” gibi.
7. Kırmanccada mastar eki “-ene” veya “-iş”/“-yîş”tir. Örneğin “kerdene”/“kerdiş” (yapmak); “dayene”/“dayîş” (vermek). Bu formlar yöreden yöreye değişmekle birlikte isim olarak da kullanılırlar. Yani “kerdene” ve “kerdiş” yapma; “dayene” ve “dayîş” verme anlamında da kullanılırlar. Bu sözlükte, ayırımı kolaylaştırmak için “-ene” ekini alan formlar mastar, “-iş”/ “-yîş” ekini alanlar ise isim sayılmıştır. Dolayısıyla bu sözcükler elinizdeki sözlükte şöyle yazılmıştır:
yapma: kerdiş (n)
yapmak: kerdene
8. Kırmanccada, konuşma dilinde, bazı sözcükler bir yörede eril olarak kullanılırken diğer bir yörede dişil olarak kullanılabilmektedirler. Kırmancca Toplantıları’nda, bunlardan biri yazı dilinde kullanılmak için seçilmiştir. Örneğin, kürsü anlamına gelen “kursî” sözcüğü bazı yörelerde eril, bazı yörelerde ise dişil olarak kullanılmaktadır. Kırmancca Toplantısı’nda, bu sözcüğün yazı dilinde eril olarak kullanılması uygun görülmüş, dolayısıyla sözcük sözlükte şöyle yazılmıştır.
kürsü: kursî (n)
9. Yazılışları aynı olup anlamları veya dilbilgisindeki fonksiyonları bakımından ayrı olan sözcükler, Romen rakamlarıyla numaralandırılmıştır. Örneğin:
yüz-I: se
yüz-II: 1)rî (n), surot (n) 2)ser (n)

10. Bazen tekrardan kaçınmak için aynı anlama gelen sözcük ya da cümleler arasına bir eğik çizgi (/) konmuştur. Örnek:
Hoş geldin. (Ti bixeyr ameyî/ameya.)
Bu şu anlama gelir: Hem “Ti bixeyr ameyî” hem de “Ti bixeyr ameya” cümlesi “Hoş geldin” anlamında kullanılır. Cümlenin tümünün tekrarlanmaması için sadece farklılık gösteren sondaki “ameyî“ ve “ameya“ sözcükleri arasına eğik çizgi konmuştur.
Başka bir örnek:
akarsu kıyısı: verê royî/robarî/çemî
Burada ise “verê royî”, “verê robarî”, “verê çemî” Türkçede “akarsu kıyısı” anlamına gelir. “Verê” sözcüğünü üç kez tekrarlamamak için eğik çizgi kullanılmıştır.
Bazen de tekrardan kaçınmak için değil, sadece aynı anlama geldiklerini belirtmek için iki sözcük veya cümle arasına eğik çizgi konmuştur. Örnek:
Baş göz üstüne. (Serî-çiman ser./Sere û çiman ser.)

`,
    },

    {
      id: 'alfabe',
      title: 'Alfabe',
      details: `Alfabemiz /Alfabeya Ma (Büyük Harfler / Herfê Girdî)
A B C Ç D E Ê F G H
I Î J K L M N O P Q R
S Ş T U Û V W X Y Z
\n
Alfabemiz /Alfabeya Ma (Küçük Harfler / Herfê Hurdî)
a b c ç d e ê f g h
i î j k l m n o p q r
s ş t u û v w x y z
`,
    },
    {
      id: 'iletisim',
      title: 'İletişim',
      details: ` zazanoferheng@gmail.com`,
    },
    {
      id: 'kisaltmalar',
      title: 'Kısaltmalar',
      details: `(b) biewnî, nîyade	bak
(bs) bestox		bağlaç
(cg) cografya		coğrafya
(ed) edat		ilgeç, edat
(huq.) huqûq		hukuk 
(m) makî		dişil
(mat) matematîk	matematik
(n) nêrî		              eril
(nm) name		ad, isim
(pd) peyedat 		artedat (postposition)
(pr) prefîks		önek
(s) sifet		              sıfat
(sf) suffîks		sonek
(sn) sînema		sinema
(TE) Teknolojîyê Enfor-	Enformasyon Tekno-
masyonî		lojisi
(ûsn) û sey nînan	vb. (ve benzerleri)
(vr) veredat		önedat (préposition)
(z) zemîr		adıl, zamir
(zh) zafhûmar	çoğul
(zr) zerfe		belirteç, zarf
`,
    },
  ],
};
export const bookBg = {
  id: 'bg',
  text: 'Arka Renk',
  title: 'Arka Renk',
  items: [
    {label: 'w', value: '#FFFBDA'},
    {label: 'w1', value: '#FFF5E0'},
    {label: 'w2', value: '#ECCA9C'},
    {label: 'w3', value: '#FFE6E6'},
    {label: 'w4', value: '#F1FADA'},
    {label: 'w7', value: '#FFC47E'},
    {label: 'Ana Renk', value: '#f2dcb1'},
    {label: 'Beyaz', value: '#FFFFFF'},
    {label: 'Siyah', value: '#000000'},
    {label: 'Klasik', value: '#f8f1e3'},
    {label: 'Gümüş', value: '#bebebe'},
    {label: 'y', value: '#be8f3c'},
    {label: 'z', value: '#5a5a5c'},
  ],
};
export const bookFc = {
  id: 'fontcolor',
  text: 'Yazı Rengi',
  title: 'Yazı Rengi',
  items: [
    {label: 'Beyaz', value: '#FFFFFF'},
    {label: 'Siyah', value: '#000000'},
  ],
};
export const bookFs = {
  id: 'fontsize',
  text: 'Yazı Boyutu',
  title: 'Yazı Boyutu',
  items: [
    {label: '15', value: 15},
    {label: '16', value: 16},
    {label: '17', value: 17},
    {label: '18', value: 18},
    {label: '19', value: 19},
    {label: '20', value: 20},
    {label: '21', value: 21},
    {label: '22', value: 22},
    {label: '23', value: 23},
    {label: '24', value: 24},
  ],
};
export const bookLh = {
  id: 'lineheight',
  text: 'Satır Arası Boşluk',
  title: 'Satır Arası Boşluk',
  items: [
    {label: '1.4', value: 1.4},
    {label: '1.6', value: 1.6},
    {label: '1.8', value: 1.8},
    {label: '2.0', value: 2.0},
    {label: '2.2', value: 2.2},
    {label: '2.4', value: 2.4},
  ],
};
export const bookFa = {
  id: 'textalign',
  text: 'Yazı Hizasi',
  title: 'Yazı Hizasi',
  items: [
    {label: 'format-align-left', value: 'left'},
    {label: 'format-align-center', value: 'center'},
    {label: 'format-align-right', value: 'right'},
  ],
};

export const bookTf = {
  id: 'textfont',
  text: 'Yazı Fontu',
  title: 'Yazı Fontu',
  items: [
    {label: 'Helvetica', value: 'Helvetica'},
    {label: 'Arial', value: 'Arial'},
    {label: 'Verdana', value: 'Verdana'},
    {label: 'Times New Roman', value: 'Times New Roman'},
    {label: 'Georgia', value: 'Georgia'},
  ],
};
export const bookArabicTf = {
  id: 'textfont',
  text: 'Arapca Yazı Fontu',
  title: 'Arapca Yazı Fontu',
  items: [
    {label: 'Amiri', value: 'Amiri'},
    {label: 'Lateef', value: 'Lateef'},
    {label: 'Mirza', value: 'Mirza'},
    {label: 'Noto Sans Arabic', value: 'Noto Sans Arabic'},
    {label: 'Amiri Quran', value: 'Amiri Quran'},
    {label: 'Scheherazade New', value: 'Scheherazade New'},
    {label: 'Noto Naskh Arabic', value: 'Noto Naskh Arabic'},
    {label: 'Source Sans Pro', value: 'Source Sans Pro'},
  ],
};
export const settings = [
  {
    id: 'bg',
    text: 'Theme',
    title: 'Choose theme',
    items: [
      {label: 'Light', value: '#fafafa'},
      {label: 'Dark', value: '#121212'},
      {label: 'Classic', value: '#f8f1e3'},
      {label: 'Silver', value: '#bebebe'},
      {label: 'Grey', value: '#5a5a5c'},
    ],
  },

  {
    id: 'size',
    text: 'Font Size',
    title: 'Choose font size',
    items: [
      {label: '15', value: '15px'},
      {label: '16', value: '16px'},
      {label: '17', value: '17px'},
      {label: '18', value: '18px'},
      {label: '19', value: '19px'},
      {label: '20', value: '20px'},
      {label: '21', value: '21px'},
      {label: '22', value: '22px'},
      {label: '23', value: '23px'},
      {label: '24', value: '24px'},
    ],
  },
  {
    id: 'height',
    text: 'Line Height',
    title: 'Choose line height',
    items: [
      {label: '1.4', value: 1.4},
      {label: '1.6', value: 1.6},
      {label: '1.8', value: 1.8},
      {label: '2.0', value: 2.0},
      {label: '2.2', value: 2.2},
      {label: '2.4', value: 2.4},
    ],
  },
];
