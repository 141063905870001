import React, { useState, useEffect } from "react";
import { forwardRef, useImperativeHandle, useRef } from "react";
import Autocomplete from "./components/Autocomplete";
import lugats from "./consts/kt";
import lugats2 from "./consts/tk";
import { bookCat, keyboard, menuList, lang } from "./consts/data";
import { menu, body, policy } from "./consts/reziman";

const Web = () => {
  const [question, setQuestion] = useState({});
  const [questionArr, setQuestionArr] = useState([]);
  const [result, setResult] = useState(0);
  const [selected, setSelected] = useState("");
  const [searchText, setSearchText] = useState("");

  const [fliterType, setFliterType] = useState(1);

  const [recordList, setRecordList] = useState([]);

  const [infoId, setInfoId] = useState("hakkimizda");
  const [aranan, setAranan] = useState({
    peyv: "kelime",
    wate: "anlam",
  });
  const [secondText, setSecondText] = useState({
    peyv: "",
    wate: "",
  });
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("records"));
    if (items) {
      setRecordList(items);
    }
  }, []);

  useEffect(() => {
    const dil = localStorage.getItem("dil");
    if (dil) {
      setFliterType(dil);
    }
    if (window.location.href.indexOf("gramer") > -1) {
      setTitleId(3);
      handleChangePanelImg();
    }
  }, []);

  const addFav = async () => {
    saveRecord(aranan.peyv + ": " + aranan.wate, 1);
    // setFav("favorite");
    alert("Fav");
  };

  function setLang(text) {
    let kelime = lang.find((item) => item.key == text);
    return fliterType == 1 ? kelime.za : kelime.tr;
  }
  const newQuestion = () => {
    //alert(11);
    var datam = fliterType == 1 ? lugats : lugats2;
    const result = datam.filter(function (item) {
      const itemData = item.y ? item.y.toUpperCase() : "".toUpperCase();
      const textData = "b.".toUpperCase();
      return itemData.indexOf(textData) === -1;
    });
    // var result =
    //   fliterType == 1
    //     ? datam.filter((word) => word.y.indexOf("b.") === -1)
    //     : datam;
    let listeIndex = Math.floor(Math.random() * result.length);
    var sorular = [];
    for (let i = 0; i < 5; i++) {
      sorular.push(result[listeIndex + 10 * i]);
    }

    let soruIndex = Math.floor(Math.random() * sorular.length);

    setQuestion(sorular[soruIndex]);
    setQuestionArr(sorular);
    setResult(0);
    setSelected("");
  };

  const newAnswer = (item) => {
    //console.log(item);
    setSelected(item);
    if (question.x == item) {
      setResult(1);
    } else {
      setResult(2);
    }
  };

  //yeniler
  const [isToggled, setIsToggled] = useState(false);
  const [isTogglePanel, setIsToggledPanel] = useState(false);
  const [isTogglePanelImg, setIsToggledPanelImg] = useState(false);
  const [deviceType, setDeviceType] = useState("");
  const [subTitleId, setTitleId] = useState(1);
  //const [selected, setSelected] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangePanel = () => {
    setIsToggledPanel(!isTogglePanel);
  };
  const handleChangePanelImg = () => {
    setIsToggledPanelImg(!isTogglePanelImg);
  };
  const handleChange = () => {
    setIsToggled(!isToggled);
  };
  const handleChildClick = (x, y) => {
    setAranan({
      peyv: x,
      wate: y,
    });
    handleShow();
    saveRecord(x + ": " + y, 2);
  };

  const saveRecord = async (item, tip) => {
    console.log(item);
    let currentDate = new Date().toJSON().slice(0, 10);

    const record = {
      id: recordList.length + 1,
      details: item,
      lang: fliterType,
      type: tip,
      date: currentDate,
    };

    localStorage.setItem("records", JSON.stringify([...recordList, record]));
    setRecordList([...recordList, record]);
  };
  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceType("tel");
    } else {
      setDeviceType("bil");
      setIsToggled(true);
    }
  }, []);
  const onChangeLang = (e) => {
    setFliterType(e.target.value);
  };
  useEffect(() => {
    if (aranan.wate.includes("b.")) {
      //console.log(item);
      var ptrn = aranan.wate.replace("b.", "").trim();
      const datam = fliterType == 1 ? lugats : lugats2;
      let state = datam.find((x) => x.x == ptrn);
      //console.log(state);
      if (state != null) {
        const newObj2 = {
          ...aranan,
          peyv: state.x,
          wate: state.y,
        };
        setSecondText(newObj2);
      } else {
        setSecondText({
          peyv: "",
          wate: "",
        });
      }
    }
  }, [aranan]);
  const onChangeChildState = (val) => {
    console.log(val);
    setSearchText(val);
  };
  const [gramer, setGramer] = useState(body);
  const [gramerIndex, setGramerIndex] = useState(0);
  const [gramerTitle, setGramerTitle] = useState(
    "RASTNUŞTIŞÊ KIRMANCKÎ (ZAZAKÎ)"
  );
  const gramerGet = (a, b, index) => {
    console.log(index);
    let result1 = body.includes(a);
    let result2 = body.includes(b);
    if (index < 0) index = 1;
    var mySubString =
      result1 && result2
        ? body.substring(body.indexOf(a) - 4, body.lastIndexOf(b) - 4)
        : "...";
    // console.log(mySubString);
    setGramerIndex(index);
    setGramer(mySubString);
    setGramerTitle(a);
    const element = document.getElementById("bodytext");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const paragraphRef = useRef(null);
  return (
    <>
      <div
        className="lugat"
        style={{ padding: 30, display: isTogglePanelImg ? "block" : "none" }}
      >
        <div className="containerpanel">
          {subTitleId == 1 && (
            <>
              <div className="lugat-header">
                {setLang("aramagecmisi")}
                <i
                  className="fa fa-times-circle-o"
                  id="lugatClose"
                  onClick={handleChangePanelImg}
                />
              </div>
              <ul>
                {recordList
                  .filter((d) => d.type == 2)
                  .map((item, i) => (
                    <li key={i}>
                      <a
                        href="#"
                        onClick={() => {
                          handleChangePanelImg();
                        }}
                      >
                        <i className="fa fa-circle-o"></i> {item.details}
                      </a>
                    </li>
                  ))}
              </ul>
            </>
          )}
          {subTitleId == 2 && (
            <>
              <div className="lugat-header">
                {setLang("favori")}
                <i
                  className="fa fa-times-circle-o"
                  id="lugatClose"
                  onClick={handleChangePanelImg}
                />
              </div>
              <ul>
                {recordList
                  .filter((d) => d.type == 1)
                  .map((item, i) => (
                    <li key={i}>
                      <a
                        href="#"
                        onClick={() => {
                          handleChangePanelImg();
                        }}
                      >
                        <i className="fa fa-circle-o"></i> {item.details}
                      </a>
                    </li>
                  ))}
              </ul>
            </>
          )}
          {subTitleId == 3 && (
            <>
              <div className="lugat-header">
                {gramerTitle}
                <i
                  className="fa fa-times-circle-o"
                  id="lugatClose"
                  onClick={handleChangePanelImg}
                />
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4  mb-4 ">
                  <ul name="id" className="sidebar">
                    {menu.map((item, i) => (
                      <li
                        key={i}
                        style={{
                          padding: 5,
                          color: item == gramerTitle ? "white" : "",
                          backgroundColor: item == gramerTitle ? "#04AA6D" : "",
                        }}
                      >
                        <a
                          href="#"
                          onClick={() => gramerGet(menu[i], menu[i + 1], i)}
                          style={{ color: item == gramerTitle ? "white" : "" }}
                        >
                          <i className="fa fa-arrow-right"></i> {item}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-8 col-md-8 mb-4">
                  <p
                    className="containerpanel"
                    id="bodytext"
                    ref={paragraphRef}
                  >
                    <div dangerouslySetInnerHTML={{ __html: gramer }} />
                  </p>
                  <a
                    href="#"
                    className="previous"
                    onClick={() =>
                      gramerGet(
                        menu[gramerIndex - 1],
                        menu[gramerIndex],
                        gramerIndex - 1
                      )
                    }
                  >
                    &laquo; VERÊN
                  </a>
                  <a
                    href="#"
                    className="next"
                    onClick={() =>
                      gramerGet(
                        menu[gramerIndex],
                        menu[gramerIndex + 1],
                        gramerIndex + 1
                      )
                    }
                  >
                    PEYÊN &raquo;
                  </a>
                </div>
              </div>
            </>
          )}
          {subTitleId == 4 && (
            <>
              <div className="lugat-header">
                {setLang("kelimeoyunu")}
                <i
                  className="fa fa-times-circle-o"
                  id="lugatClose"
                  onClick={handleChangePanelImg}
                />
              </div>
              <div style={{ textAlign: "center" }}>
                <hr style={{ marginTop: 20 }} />

                <span style={{ marginTop: 20 }} className="badge button3">
                  {question.x}
                </span>
                <hr style={{ marginTop: 20 }} />
                {result == 1 && (
                  <p className="badge button2">{setLang("dogru")}</p>
                )}
                {result == 2 && <p className="badge">{setLang("yanlis")}</p>}
                {result == 0 && <p className="badge button5">?</p>}
                <ul style={{ textAlign: "left" }}>
                  {questionArr.map((item, i) => (
                    <li key={i}>
                      <a
                        style={{ color: "blue" }}
                        href="#"
                        onClick={() => {
                          newAnswer(questionArr[i].x);
                        }}
                      >
                        {selected == questionArr[i].x ? (
                          <i className="fa fa-circle"></i>
                        ) : (
                          <i className="fa fa-circle-o"></i>
                        )}{" "}
                        {questionArr[i].y}{" "}
                        <i className="fa fa-mouse-pointer"></i>
                      </a>
                    </li>
                  ))}
                </ul>

                <hr style={{ marginTop: 60 }} leadingInset={16} />

                <button
                  className="buttongame button3"
                  onClick={() => {
                    newQuestion();
                  }}
                  type="button"
                >
                  <i className="fa fa-arrow-right"></i>
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      <div
        className="lugat"
        style={{ padding: 30, display: isTogglePanel ? "block" : "none" }}
      >
        <div className="lugat-header">
          {setLang(infoId)}
          <i
            className="fa fa-times-circle-o"
            id="lugatClose"
            onClick={handleChangePanel}
          />
        </div>
        <p className="containerpanel">
          {setLang(infoId + "detay")
            .split(/\r\n|\n|\r/gm)
            .map((line, i) => {
              return (
                <React.Fragment key={i}>
                  {line}
                  <br />
                </React.Fragment>
              );
            })}
        </p>
      </div>
      <div className="nav">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mob-menu" id="menuButton" onClick={handleChange}>
                <i className="fa fa-bars" /> MENU
              </div>

              <ul
                className="menu"
                style={{ display: isToggled ? "block" : "none" }}
              >
                <li>
                  <a href="/">
                    <i className="fa fa-home" />
                  </a>
                </li>

                {menuList.items.map((item, i) => (
                  <li key={i}>
                    <a
                      href="#"
                      onClick={() => {
                        setInfoId(item.id);
                        handleChangePanel();
                      }}
                    >
                      {setLang(item.id)}
                    </a>
                  </li>
                ))}
                <li>
                  <a href="/policy" target="_blank">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-12">
              <a href="/">
                <div className="logo">
                  <img src={require("./img/logo.png")} alt="Zazano" />
                </div>
              </a>
            </div>
            <div className="col-lg-10 col-12" style={{ marginTop: 20 }}>
              <div className="search">
                <form
                  action=""
                  method="get"
                  autoComplete="off"
                  spellCheck="false"
                >
                  <Autocomplete
                    placeholder={setLang("kelimeara")}
                    options={fliterType == 1 ? lugats : lugats2}
                    value={searchText}
                    onChange={(val) => setSearchText(val)}
                    onData={(val1, val2) => handleChildClick(val1, val2)}
                  />

                  <select
                    name="id"
                    className="search-sbox"
                    onChange={onChangeLang}
                  >
                    {/* {deviceType === "tel" && <option></option>} */}

                    {bookCat.items.map((item, i) => (
                      <option key={i} value={item.value}>
                        {setLang(item.label)}
                      </option>
                    ))}
                  </select>
                </form>
                {fliterType == 1 && (
                  <div style={{ marginTop: 10 }}>
                    {keyboard.items.map((item, i) => (
                      <a
                        href="#"
                        className="buttonchar"
                        onClick={() =>
                          onChangeChildState(searchText + item.value)
                        }
                        key={i}
                      >
                        {item.label}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "\n        td {\n            padding: 5px !important;\n            text-align: center;\n            border: white 5px solid;\n        }\n\n        .layoutImg {\n            width: 80%;\n        }\n\n            .layoutImg:hover {\n                opacity: 0.5;\n                filter: alpha(opacity=50); /* For IE8 and earlier */\n            }\n\n\n        .column {\n            -ms-flex: 25%;\n            flex: 25%;\n            /*     padding: 0 2px;*/\n        }\n\n            .column img {\n                margin-top: 2px;\n                vertical-align: middle;\n            }\n\n        .banner {\n            width: 100%;\n            height: 100%;\n            border-radius: 16px;\n        }\n    ",
        }}
      />
      <div className="container">
        <div className="row" style={{ display: show ? "block" : "none" }}>
          <div
            className="row book-contenticon"
            style={{ textAlign: "center", backgroundColor: "#8e0000" }}
          >
            <div className="col-lg-4 col-md-4 col-sm-4 col-4 mb-4"></div>

            <div className="col-lg-4 col-md-4 col-sm-4 col-4 mb-4">
              <a href="#" onClick={addFav} style={{ color: "#fff" }}>
                <i className="fa fa-heart" /> Fav
              </a>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-4 mb-4">
              <a href="#" onClick={handleClose} style={{ color: "#fff" }}>
                <i className="fa fa-close" />
              </a>
            </div>
          </div>

          <div className="col-12">
            <div
              className="book-content "
              id="readContent"
              style={{ fontSize: 18 }}
            >
              <p>
                <span>{aranan.peyv}</span>: {aranan.wate}
              </p>

              {secondText.peyv != "" && (
                <p>
                  {"\n"}
                  <i class="fa fa-hand-o-right"></i>{" "}
                  <span>{secondText.peyv}</span>: {secondText.wate}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="card card-solid">
          <div className="row" style={{ marginTop: 25 }}>
            <div className="col-md-12">
              {window.location.href.indexOf("policy") > -1 ? (
                <div className="row" style={{ marginRight: 0, marginLeft: 0 }}>
                  <div dangerouslySetInnerHTML={{ __html: policy }} />
                </div>
              ) : (
                <div className="row" style={{ marginRight: 0, marginLeft: 0 }}>
                  <div className="column">
                    <a
                      href="#"
                      onClick={() => {
                        setTitleId(1);
                        handleChangePanelImg();
                      }}
                    >
                      <img
                        className="layoutImg lazy"
                        data-src={require("./img/his" + fliterType + ".jpg")}
                        style={{ width: "100%" }}
                        alt="Zazano"
                        src={require("./img/his" + fliterType + ".jpg")}
                      />
                    </a>
                  </div>
                  <div className="column">
                    <a
                      href="#"
                      onClick={() => {
                        setTitleId(2);
                        handleChangePanelImg();
                      }}
                    >
                      <img
                        className="layoutImg lazy"
                        data-src={require("./img/fav" + fliterType + ".jpg")}
                        style={{ width: "100%" }}
                        alt="Zazano"
                        src={require("./img/fav" + fliterType + ".jpg")}
                      />
                    </a>
                    <a
                      href="#"
                      onClick={() => {
                        setTitleId(3);
                        handleChangePanelImg();
                      }}
                    >
                      <img
                        className="layoutImg lazy"
                        data-src={require("./img/gra" + fliterType + ".jpg")}
                        style={{ width: "100%" }}
                        alt="Zazano"
                        src={require("./img/gra" + fliterType + ".jpg")}
                      />
                    </a>
                  </div>
                  <div className="column">
                    <a
                      href="#"
                      onClick={() => {
                        setTitleId(4);
                        handleChangePanelImg();
                        newQuestion();
                      }}
                    >
                      <img
                        className="layoutImg lazy"
                        data-src={require("./img/test" + fliterType + ".jpg")}
                        style={{ width: "100%" }}
                        alt="Zazano"
                        src={require("./img/test" + fliterType + ".jpg")}
                      />
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-9" style={{ marginBottom: 30 }}>
              <h2 className="footer-title">{setLang("hakkimizda")}</h2>
              <div className="footer-about-text">
                {setLang("hakkimizdadetay")
                  .split(/\r\n|\n|\r/gm)
                  .map((line, i) => {
                    return (
                      <React.Fragment key={i}>
                        {line}
                        <br />
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>

            <div className="col-md-3" style={{ marginBottom: 30 }}>
              <h2 className="footer-title">Mobile App</h2>

              <div className="footer-apps">
                <a href="https://play.google.com/store/apps/details?id=zazano.mb" target="_blank">
                  <img src={require("./img/googlestore.png")} width={140} />
                </a>
              </div>
              <div className="footer-apps">
                <a href="https://apps.apple.com/tr/app/zazano/id6692621739" target="_blank">
                  <img src={require("./img/appstore.png")} width={140} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        © zazano.org Copyright {new Date().getFullYear()}, Design by Murphy
        Creative Design.
      </div>
    </>
  );
};

export default Web;
