const kt =   [
  {
    "x": "a-I (m, z)",
    "y": " 1)o \r\n~2)b. aye (m, z)"
  },
  {
    "x": "a ya",
    "y": " odur, işte"
  },
  {
    "x": "a ke dima",
    "y": " sonraki"
  },
  {
    "x": "a wa na wa",
    "y": " b. a ya na ya"
  },
  {
    "x": "a ya na ya",
    "y": " -den beri"
  },
  {
    "x": "a-II (m, s)",
    "y": " o\r\n~A kêneke vana. (O kız söylüyor.)\r\n~A kêneke ra vaje. (O kıza söyle.)\r\n~A kêneke ra girewt. (O kızdan aldı.)"
  },
  {
    "x": "a ... ra",
    "y": " ona, ondan"
  },
  {
    "x": "a jo ra",
    "y": " b. a yewere ra"
  },
  {
    "x": "a jiwere ra",
    "y": " b. a yewere ra"
  },
  {
    "x": "a juwer ra",
    "y": " b. a yewere ra"
  },
  {
    "x": "a juwere ra",
    "y": " b. a yewere ra"
  },
  {
    "x": "a yewere ra",
    "y": " o nedenle, o yüzden, ondan dolayı, ondan ötürü"
  },
  {
    "x": "a-III (pd)",
    "y": " b. ra-II (pd)\r\n~Lajekî lew kênek a day. (Oğlan kızı öptü.)\r\n~Na gam a tepîya nêbeno. (Bu andan sonra olmaz.)\r\n~Dewa ey a cêr dere est o. (Onun köyünün aşağısında dere var.)"
  },
  {
    "x": "a-IV (pd)",
    "y": " b. bi ... ya\r\n~Ey a embazî meki. (Onunla arkadaşlık yapma.)"
  },
  {
    "x": "a deqeldeyîne",
    "y": " b. a deqeldîyayene"
  },
  {
    "x": "a deqeldîyayene",
    "y": " batmak (suya, çamura vb. batmak)\r\n~Aw a deqeldîya. (Suya battı.)"
  },
  {
    "x": "...a mergî",
    "y": " ölümcül"
  },
  {
    "x": "aba-I (m)",
    "y": " 1)abla, abla (hitap hali)\r\n~2)anne (hitap hali)"
  },
  {
    "x": "aba-II (m)",
    "y": " b. eba-II (m)"
  },
  {
    "x": "abajûr (n)",
    "y": " abajur"
  },
  {
    "x": "abayey (m)",
    "y": " b. abayî (m)"
  },
  {
    "x": "abayê (m)",
    "y": " b. abayî (m)"
  },
  {
    "x": "abayî (m)",
    "y": " ablalık"
  },
  {
    "x": "abaza, -ye",
    "y": " Abaza"
  },
  {
    "x": "abazakî (m)",
    "y": " Abazaca"
  },
  {
    "x": "abiqat, -i",
    "y": " b. avûkat, -e"
  },
  {
    "x": "abiqîyayene",
    "y": " b. awiqîyayene"
  },
  {
    "x": "abiqîyayîş (n)",
    "y": " b. awiqîyayîş (n)"
  },
  {
    "x": "abirîyayene",
    "y": " ayrılmak"
  },
  {
    "x": "abirîyayîş (n)",
    "y": " ayrılma"
  },
  {
    "x": "abirnayene",
    "y": " 1)ayırmak\r\n~2)tahsis etmek"
  },
  {
    "x": "abirnayîş (n)",
    "y": " 1)ayırma\r\n~2)tahsis etme"
  },
  {
    "x": "abîde (m)",
    "y": " abide, anıt"
  },
  {
    "x": "Abîdjan (n)",
    "y": " Abidjan"
  },
  {
    "x": "abîyayene",
    "y": " 1)açılmak\r\n~2)çözülmek (düğüm vb. için)"
  },
  {
    "x": "abîyayîş (n)",
    "y": " 1)açılma\r\n~2)açılım, açılış\r\n~3)çözülme (düğüm vb. için)"
  },
  {
    "x": "abîyene",
    "y": " b. abîyayene"
  },
  {
    "x": "abluka (m)",
    "y": " abluka, kuşatma, muhasara"
  },
  {
    "x": "abluka de bîyene",
    "y": " abluka altında olmak, kuşatılmak, çevrelenmek"
  },
  {
    "x": "abluka de mendene",
    "y": " abluka altında kalmak"
  },
  {
    "x": "abluka domnayene",
    "y": " abluka altında tutmak, kuşatmayı sürdürmek"
  },
  {
    "x": "abluka hewa dayene",
    "y": " ablukayı kaldırmak"
  },
  {
    "x": "abluka hewa nayene",
    "y": " ablukayı kaldırmak"
  },
  {
    "x": "abluka kerdene",
    "y": " abluka etmek, ablukaya almak, kuşatmak, muhasara altına almak, muhasara etmek, çevrelemek, ihata etmek"
  },
  {
    "x": "abluka şeqnayene",
    "y": " ablukayı yarmak"
  },
  {
    "x": "abluka ver kerdene",
    "y": " ablukayı yarmak"
  },
  {
    "x": "abluka wedardayene",
    "y": " ablukayı kaldırmak"
  },
  {
    "x": "abluka wedaritene",
    "y": " ablukayı kaldırmak"
  },
  {
    "x": "abluka wedarnayene",
    "y": " ablukayı kaldırmak"
  },
  {
    "x": "ablukadebîyayîş (n)",
    "y": " çevrelenme, çevreleniş"
  },
  {
    "x": "ablukakerdiş (n)",
    "y": " abluka etme¸ çevreleme"
  },
  {
    "x": "abluqa (m)",
    "y": " b. abluka (m)"
  },
  {
    "x": "abone (m, n)",
    "y": " abone"
  },
  {
    "x": "abone bîyene",
    "y": " abone olmak"
  },
  {
    "x": "abone kerdene",
    "y": " abone etmek"
  },
  {
    "x": "abonetîye (m)",
    "y": " abonelik"
  },
  {
    "x": "aboneyey (m)",
    "y": " b. aboneyîye (m)"
  },
  {
    "x": "aboneyê (m)",
    "y": " b. aboneyî (m)"
  },
  {
    "x": "aboneyî (m)",
    "y": " abonelik"
  },
  {
    "x": "aboneyîye (m)",
    "y": " abonelik"
  },
  {
    "x": "abonman (n)",
    "y": " abonman"
  },
  {
    "x": "abstre, -ye",
    "y": " abstre, mücerret, soyut"
  },
  {
    "x": "Abuja (m)",
    "y": " Abuja"
  },
  {
    "x": "abuqat, -i",
    "y": " b. avûkat, -e"
  },
  {
    "x": "abûqat, -i",
    "y": " b. avûkat, -e"
  },
  {
    "x": "abxaz, -e",
    "y": " Abaza"
  },
  {
    "x": "abxazkî (m)",
    "y": " Abazaca"
  },
  {
    "x": "aca (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "acele (n)",
    "y": " b. ecele (n)"
  },
  {
    "x": "aceva",
    "y": " b. eceba"
  },
  {
    "x": "acê",
    "y": " b. acêr"
  },
  {
    "x": "acêr",
    "y": " aşağıya doğru"
  },
  {
    "x": "aciz (huq.) (n)",
    "y": " aciz"
  },
  {
    "x": "aciz bîyayene",
    "y": " b. eciz bîyene"
  },
  {
    "x": "aciz kerdene",
    "y": " b. eciz kerdene"
  },
  {
    "x": "acizbîyayîş (n)",
    "y": " b. ecizbîyayîş (n)"
  },
  {
    "x": "acizîyaya (m)",
    "y": " b. ecizîyaya (m)"
  },
  {
    "x": "acizîyaye (n)",
    "y": " b. ecizîyaye (n)"
  },
  {
    "x": "acizîyayene",
    "y": " b. ecizîyayene"
  },
  {
    "x": "acizîyayî, -ye. ",
    "y": " b. ecizîyayî, -ye"
  },
  {
    "x": "acizîyayîş (n)",
    "y": " b. ecizîyayîş (n)"
  },
  {
    "x": "acizkerdiş (n)",
    "y": " b. ecizkerdiş (n)"
  },
  {
    "x": "aciznayene",
    "y": " b. eciznayene"
  },
  {
    "x": "aciznayîş (n). ",
    "y": " b. eciznayîş (n)"
  },
  {
    "x": "acîl, -e",
    "y": " acil, ivedi"
  },
  {
    "x": "acîl bîyene",
    "y": " acil olmak, ivedi olmak"
  },
  {
    "x": "acîlî (m)",
    "y": " acillik, ivedilik"
  },
  {
    "x": "acîlîye (m)",
    "y": " acillik, ivedilik"
  },
  {
    "x": "acî",
    "y": " b. acêr"
  },
  {
    "x": "acîlkî",
    "y": " acilen, çabucak"
  },
  {
    "x": "acîr",
    "y": " b. acêr"
  },
  {
    "x": "acne (m)",
    "y": " b. ajne (m)"
  },
  {
    "x": "acne kerdene",
    "y": " b. ajne kerdene"
  },
  {
    "x": "acnekerdiş (n)",
    "y": " b. ajnekerdiş"
  },
  {
    "x": "acor",
    "y": " yukarıya doğru"
  },
  {
    "x": "acur (n)",
    "y": " b. ecur (n)"
  },
  {
    "x": "aç (m)",
    "y": " b. eçe (m)"
  },
  {
    "x": "aç kerdene",
    "y": " b. eçe kerdene"
  },
  {
    "x": "açarnaya (m)",
    "y": " çeviri, tercüme"
  },
  {
    "x": "açarnaye (n)",
    "y": " çeviri, tercüme"
  },
  {
    "x": "açarnayene",
    "y": " 1)döndürmek, geriye döndürmek\r\n~2)çevirmek, tercüme etmek"
  },
  {
    "x": "açarnayî (m)",
    "y": " çeviri"
  },
  {
    "x": "açarnayîş (n)",
    "y": " 1)döndürme, geriye döndürme\r\n~2)çevirme, tercüme etme"
  },
  {
    "x": "açarnayîye (m)",
    "y": " çeviri, tercüme"
  },
  {
    "x": "açarnayox, -e",
    "y": " çeviren, tercüme eden; çevirmen, mütercim, tercüman"
  },
  {
    "x": "açînayene",
    "y": " gözemek, örmek (kumaş ve örgülerdeki delikleri elde iplikle besleyerek kapatmak)"
  },
  {
    "x": "açînayîş (n)",
    "y": " gözeme, örme (kumaş ve örgülerdeki delikleri elde iplikle besleyerek kapatma)"
  },
  {
    "x": "açkerdiş (n)",
    "y": " b. eçekerdiş (n)"
  },
  {
    "x": "ad (n)",
    "y": " b. ehd (n)"
  },
  {
    "x": "ad kerdene",
    "y": " b. ehd kerdene"
  },
  {
    "x": "adabêmuaşeret (n)",
    "y": " adabımuaşeret"
  },
  {
    "x": "adaptasyon (n)",
    "y": " uyarlama, adaptasyon "
  },
  {
    "x": "adapte, -ye",
    "y": " uyarlanmış, adapte"
  },
  {
    "x": "adapte bîyene",
    "y": " adapte olmak"
  },
  {
    "x": "adapte kerdene",
    "y": " adapte etmek"
  },
  {
    "x": "adil, -e",
    "y": " adaletli"
  },
  {
    "x": "adilane",
    "y": " adilane"
  },
  {
    "x": "adilî (m)",
    "y": " adaletlilik, adillik"
  },
  {
    "x": "adkerdiş (n)",
    "y": " b. ehdkerdiş (n)"
  },
  {
    "x": "ada (m, s)",
    "y": " b. a (m, s)"
  },
  {
    "x": "ada ... da",
    "y": " b. di ... de"
  },
  {
    "x": "adalete (m)",
    "y": " b. edalet (n)"
  },
  {
    "x": "adaleyî (zh)",
    "y": " b. edaleyî (zh)"
  },
  {
    "x": "adaptasyon (n)",
    "y": " uyarlama"
  },
  {
    "x": "adapteker (n) (TE)",
    "y": " bağdaştırıcı"
  },
  {
    "x": "adaptekerda (m)",
    "y": " uyarlama"
  },
  {
    "x": "adaptekerde (n)",
    "y": " uyarlama"
  },
  {
    "x": "adaptekerdîye (m)",
    "y": " uyarlama"
  },
  {
    "x": "adar (m)",
    "y": " b. adare (m)"
  },
  {
    "x": "adare (m)",
    "y": " mart"
  },
  {
    "x": "adari (m)",
    "y": " b. adare (m)"
  },
  {
    "x": "adaşt (n)",
    "y": " b. wet (n)"
  },
  {
    "x": "adaştî (n)",
    "y": " b. wet (n)"
  },
  {
    "x": "adaz (n)",
    "y": " b. awdest (n)"
  },
  {
    "x": "Addîs Ababa",
    "y": " Addis Ababa"
  },
  {
    "x": "adelîyayene",
    "y": " b. edilîyayene"
  },
  {
    "x": "adelîyayîş (n)",
    "y": " b. edilîyayîş (n)"
  },
  {
    "x": "adelnayene",
    "y": " b. edilnayene"
  },
  {
    "x": "adelnayîş (n)",
    "y": " b. edilnayîş (n)"
  },
  {
    "x": "Ademî ra pey",
    "y": " Adem’den sonra"
  },
  {
    "x": "Ademî ra ver",
    "y": " Adem’den önce"
  },
  {
    "x": "adeqeldeyîş (n)",
    "y": " b. adeqeldîyayîş (n)"
  },
  {
    "x": "adeqeldîyayîş (n)",
    "y": " batma (suya, çamura vb. batma)"
  },
  {
    "x": "adet (n)",
    "y": " 1)âdet, görenek\r\n~2)töre"
  },
  {
    "x": "adet û tore",
    "y": " 1)örf âdet\r\n~2)gelenek"
  },
  {
    "x": "adet û toreyî, -ye",
    "y": " geleneksel"
  },
  {
    "x": "adetan ra teber",
    "y": " acayip, garip, gariplik; olağanüstü, çılgın,"
  },
  {
    "x": "adetan ra veta",
    "y": " acayip, garip, gariplik; olağanüstü, çılgın"
  },
  {
    "x": "adetan ra vete",
    "y": " acayip, garip, gariplik; olağanüstü, çılgın"
  },
  {
    "x": "adetan ra vetî",
    "y": " acayip, garip, gariplik; olağanüstü, çılgın"
  },
  {
    "x": "adetî, -ye",
    "y": " olağan, normal"
  },
  {
    "x": "adil, -e",
    "y": " adil"
  },
  {
    "x": "adil (n)",
    "y": " b. adir (n)"
  },
  {
    "x": "adilgan (n)",
    "y": " b. adirgan (n)"
  },
  {
    "x": "adilgûn (n)",
    "y": " b. adirgan (n)"
  },
  {
    "x": "adilkan (n)",
    "y": " b. adirgan (n)"
  },
  {
    "x": "adir (n)",
    "y": " 1)ateş\r\n~2)(hastalıkta) ateş, humma, yangın (hasta için)"
  },
  {
    "x": "adir ci kewtene",
    "y": " tutuşmak"
  },
  {
    "x": "adir ci kotene",
    "y": " b. adir ci kewtene"
  },
  {
    "x": "adir ci verdayene",
    "y": " ateşe vermek"
  },
  {
    "x": "adir ci verdene",
    "y": " b. adir ci verdayene"
  },
  {
    "x": "adir ci vistene",
    "y": " tutuşturmak"
  },
  {
    "x": "adir dekewtene",
    "y": " tutuşmak"
  },
  {
    "x": "adir dekotene",
    "y": " b. adir dekewtene"
  },
  {
    "x": "adir devistene",
    "y": " ateşe vermek, tutuşturmak"
  },
  {
    "x": "adir girewtene",
    "y": " tutuşmak"
  },
  {
    "x": "adir girotene",
    "y": " b. adir girewtene"
  },
  {
    "x": "adir guretene",
    "y": " b. adir girewtene"
  },
  {
    "x": "adir kerdene",
    "y": " ateş yakmak"
  },
  {
    "x": "adir panayene",
    "y": " ateşe vermek"
  },
  {
    "x": "adir pernayene",
    "y": " ateş saçmak"
  },
  {
    "x": "adir pira nayene",
    "y": " ateşe vermek "
  },
  {
    "x": "adir poverdayene",
    "y": " ateşe vermek"
  },
  {
    "x": "adir poverdene",
    "y": " b. adir poverdayene"
  },
  {
    "x": "adir puf kerdene",
    "y": " ateş püskürmek"
  },
  {
    "x": "adir tafînayene",
    "y": " ateşe vermek"
  },
  {
    "x": "adir tira nayene",
    "y": " ateşe vermek"
  },
  {
    "x": "adir varnayene",
    "y": " ateş püskürmek"
  },
  {
    "x": "adir wekerdene",
    "y": " ateş yakmak"
  },
  {
    "x": "adiro sur bîyene",
    "y": " ateş püskürmek"
  },
  {
    "x": "adirbest (n)",
    "y": " ateşkes, bırakışma, mütareke"
  },
  {
    "x": "adirca (n)",
    "y": " ocak (ateş yakılan veya gazla yakılan)"
  },
  {
    "x": "adircikewtiş (n)",
    "y": " tutuşma"
  },
  {
    "x": "adircikotiş (n)",
    "y": " b. adircikewtiş (n)"
  },
  {
    "x": "adirciverdayîş (n)",
    "y": " ateşe verme"
  },
  {
    "x": "adircivistiş (n)",
    "y": " tutuşturma"
  },
  {
    "x": "adirdekewtiş (n)",
    "y": " tutuşma"
  },
  {
    "x": "adirdekotiş (n)",
    "y": " b adirdekewtiş (n)"
  },
  {
    "x": "adirdevistiş (n)",
    "y": " ateşe verme, tutuşturma"
  },
  {
    "x": "adire (n)",
    "y": " çakmak (tıpta), Latincede “impetigo” denilen deri hastalığı"
  },
  {
    "x": "adirgan (n)",
    "y": " ocak (ateş yakılan veya gazla yakılan)"
  },
  {
    "x": "adirge (n)",
    "y": " çakmak"
  },
  {
    "x": "adirgi (n)",
    "y": " b. adirge (n)"
  },
  {
    "x": "adirgirewtiş (n)",
    "y": " tutuşma"
  },
  {
    "x": "adirgirotiş (n)",
    "y": " b. adirgirewtiş (n)"
  },
  {
    "x": "adirgon (n)",
    "y": " b. adirgan (n)"
  },
  {
    "x": "adirguretiş (n)",
    "y": " b. adirgirewtiş (n)"
  },
  {
    "x": "adirgûn (n)",
    "y": " b. adirgan (n)"
  },
  {
    "x": "adirkar, -e",
    "y": " ocakçı"
  },
  {
    "x": "adirkarî (m)",
    "y": " ocakçılık"
  },
  {
    "x": "adirkarîye (m)",
    "y": " ocakçılık"
  },
  {
    "x": "adirkerdiş (n)",
    "y": " ateş yakma"
  },
  {
    "x": "adirlimitiş (n)",
    "y": " b. adirnimitiş (n)"
  },
  {
    "x": "adirnimitiş (n)",
    "y": " ateş saklamak (için külle örtmek)"
  },
  {
    "x": "adirnumitiş (n)",
    "y": " b. adirnimitiş (n)"
  },
  {
    "x": "adirpanayîş (n)",
    "y": " ateşe verme"
  },
  {
    "x": "adirperest, -e",
    "y": " ateşperest"
  },
  {
    "x": "adirpiranayîş (n)",
    "y": " ateşe verme"
  },
  {
    "x": "adirpoverdayîş (n)",
    "y": " ateşe verme"
  },
  {
    "x": "adirtafînayîş (n)",
    "y": " ateşe verme"
  },
  {
    "x": "adirtiranayîş (n)",
    "y": " ateşe verme "
  },
  {
    "x": "adirwekerdiş (n)",
    "y": " ateş yakma"
  },
  {
    "x": "adî, -ye",
    "y": " adi, bayağı"
  },
  {
    "x": "adîyîye (m)",
    "y": " adilik, aşağılık"
  },
  {
    "x": "adle (n)",
    "y": " b. elle (n)"
  },
  {
    "x": "adres (n)",
    "y": " b. adrese (m)"
  },
  {
    "x": "adrese (m)",
    "y": " adres"
  },
  {
    "x": "adresa PÎ (adresa Protokolê Înternetî) (TE)",
    "y": " İP-adresi"
  },
  {
    "x": "ADSL modem (n) (TE)",
    "y": " ADSL modem"
  },
  {
    "x": "ae (m, z)",
    "y": " b. aye (m, z)"
  },
  {
    "x": "aerobîk (n)",
    "y": " aerobik"
  },
  {
    "x": "afarnayîne",
    "y": " b. afirnayene"
  },
  {
    "x": "afarnayîş (n)",
    "y": " b. afirnayîş (n)"
  },
  {
    "x": "afat (m)",
    "y": " b. afate (m)"
  },
  {
    "x": "afate (m)",
    "y": " afet"
  },
  {
    "x": "afata tebîîye (m)",
    "y": " doğal afet"
  },
  {
    "x": "afata xozayîye (m)",
    "y": " doğal afet"
  },
  {
    "x": "afatzeda (m)",
    "y": " afetzede"
  },
  {
    "x": "afatzede, -ye",
    "y": " afetzede"
  },
  {
    "x": "afernayene",
    "y": " b. afirnayene"
  },
  {
    "x": "afernayîs (n)",
    "y": " b. afirnayîş (n)"
  },
  {
    "x": "afernayîş (n)",
    "y": " b. afirnayîş (n)"
  },
  {
    "x": "afete (m)",
    "y": " b. afate (m)"
  },
  {
    "x": "afeti (m)",
    "y": " b. afate (m)"
  },
  {
    "x": "afir-I (n)",
    "y": " b. afire (m)"
  },
  {
    "x": "afir-II (m)",
    "y": " b. afire (m)"
  },
  {
    "x": "afire (m)",
    "y": " yemlik (hayvanlara yem verilen yer)"
  },
  {
    "x": "afirnayene",
    "y": " yaratmak"
  },
  {
    "x": "afirnayîş (n)",
    "y": " yaratma"
  },
  {
    "x": "afîş (n)",
    "y": " afiş"
  },
  {
    "x": "afîşe (s)",
    "y": " afişe "
  },
  {
    "x": "afîşekerdiş (n)",
    "y": " afişe etme"
  },
  {
    "x": "afîyet",
    "y": " afiyet\r\n~Afîyet bo. (Afiyet olsun.)"
  },
  {
    "x": "Afîyet bû",
    "y": " b. Afîyet bo.\r\n~Afîyet-şeker bo. (Afiyet şeker olsun.)"
  },
  {
    "x": "afonî (m)",
    "y": " afoni"
  },
  {
    "x": "aforîzma (m)",
    "y": " aforizma, aforizm"
  },
  {
    "x": "aforoz (n)",
    "y": " aforoz"
  },
  {
    "x": "aforoz kerdene",
    "y": " aforoz etmek"
  },
  {
    "x": "aforozbîyaye (n)",
    "y": " aforozlu"
  },
  {
    "x": "aforozbîyayî, -ye",
    "y": " aforozlu"
  },
  {
    "x": "aforozkerde (n)",
    "y": " aforozlu"
  },
  {
    "x": "aforozkerdiş (n)",
    "y": " aforoz etme"
  },
  {
    "x": "aforozkerdî, -ye",
    "y": " aforozlu"
  },
  {
    "x": "Afrîka (m)",
    "y": " Afrika"
  },
  {
    "x": "Afrîkaya Başûrî (m)",
    "y": " Güney Afrika"
  },
  {
    "x": "Afrîkaya Mîyanêne (m)",
    "y": " Orta Afrika Cumhuriyeti"
  },
  {
    "x": "afrîkayij, -e",
    "y": " afrikalı"
  },
  {
    "x": "afrîkayijîye (m)",
    "y": " afrikalılık"
  },
  {
    "x": "afsancî, -ye",
    "y": " b. asvançî, -ye"
  },
  {
    "x": "afsencî, -ye",
    "y": " b. asvançî, -ye"
  },
  {
    "x": "afsûn (n)",
    "y": " b. efsûn (n)"
  },
  {
    "x": "afsûn kerdene",
    "y": " b. efsûn kerdene"
  },
  {
    "x": "aftarayîne",
    "y": " b. wetardene"
  },
  {
    "x": "aftarayîş (n)",
    "y": " b. wetardiş (n)"
  },
  {
    "x": "afxan, -e",
    "y": " b. efxan, -e"
  },
  {
    "x": "afyon (n)",
    "y": " afyon "
  },
  {
    "x": "afyonkêş, -e",
    "y": " afyonkeş"
  },
  {
    "x": "afyonkêşî (m)",
    "y": " afyonkeşlik"
  },
  {
    "x": "afyonkêşîye (m)",
    "y": " afyonkeşlik"
  },
  {
    "x": "agadar, -e",
    "y": " b. agahdar, -e"
  },
  {
    "x": "agahdar, -e",
    "y": " haberdar"
  },
  {
    "x": "agahdar bîyene",
    "y": " haberdar olmak"
  },
  {
    "x": "agahdar kerdene",
    "y": " haberdar etmek"
  },
  {
    "x": "agahdarîgeh (n)",
    "y": " danışma [danışma yeri]"
  },
  {
    "x": "agahdarkerdiş (n)",
    "y": " bildirim, tebligat; haberdar etme"
  },
  {
    "x": "agahî (m)",
    "y": " haber"
  },
  {
    "x": "agahîname (n)",
    "y": " genelge, tamim"
  },
  {
    "x": "ageryayene",
    "y": " b. agêrayene"
  },
  {
    "x": "ageyrayene",
    "y": " b. agêrayene"
  },
  {
    "x": "ageyrayîş (n)",
    "y": " b. agêrayîş (n)"
  },
  {
    "x": "ageyrnayene",
    "y": " b. agêrnayene"
  },
  {
    "x": "ageyrnayîş (n)",
    "y": " b. agêrnayîş (n)"
  },
  {
    "x": "agêrayene",
    "y": " dönmek\r\n~a agêray/a gêray a:  o döndü"
  },
  {
    "x": "agêrayîş (n)",
    "y": " dönüş, dönme"
  },
  {
    "x": "agêrnaye (n)",
    "y": " çeviri"
  },
  {
    "x": "agêrnayene",
    "y": " çevirmek, döndürmek, geriye döndürmek"
  },
  {
    "x": "agêrnayîş (n)",
    "y": " çevirme, döndürme, geriye döndürme"
  },
  {
    "x": "agêrnayîye (m)",
    "y": " çeviri, tercüme"
  },
  {
    "x": "agir (n)",
    "y": " b. adir (n)"
  },
  {
    "x": "agir wekerdene",
    "y": " b. adir wekerdene"
  },
  {
    "x": "aglutinin (n)",
    "y": " aglütinin"
  },
  {
    "x": "agnostîk, -e",
    "y": " bilinemezci, agnostik"
  },
  {
    "x": "agnostîsîzm (n)",
    "y": " bilinemezcilik, agnostisizm"
  },
  {
    "x": "agnozî (m)",
    "y": " agnozi, agnosi"
  },
  {
    "x": "agora (m)",
    "y": " agora "
  },
  {
    "x": "agozîyayeni",
    "y": " b. awguzîyayene"
  },
  {
    "x": "agozîyayîş (n)",
    "y": " b. awguzîyayîş (n)"
  },
  {
    "x": "agoznayeni",
    "y": " b. awguznayene"
  },
  {
    "x": "agoznayîş (n)",
    "y": " b. awguznayîş (n)"
  },
  {
    "x": "agozyayeni",
    "y": " b. awguzîyayene"
  },
  {
    "x": "agozyayîş (n)",
    "y": " b. awguzîyayîş (n)"
  },
  {
    "x": "agronomî (m)",
    "y": " agronomi"
  },
  {
    "x": "aguzîyayene",
    "y": " b. awguzîyayene"
  },
  {
    "x": "aguzîyayîş (n)",
    "y": " b. awguzîyayîş (n)"
  },
  {
    "x": "aguznayeni",
    "y": " b. awguznayene"
  },
  {
    "x": "aguznayîş (n)",
    "y": " b. awguznayîş (n)"
  },
  {
    "x": "aguzyayene",
    "y": " b. awguzîyayene"
  },
  {
    "x": "aguzyayîş (n)",
    "y": " b. awguzîyayîş (n)"
  },
  {
    "x": "ahay",
    "y": " b. hey"
  },
  {
    "x": "aheng (n)",
    "y": " ahenk, uyum"
  },
  {
    "x": "ahengdar, -e",
    "y": " ahenkli, uyumlu, ahenktar"
  },
  {
    "x": "ahengdarîye (m)",
    "y": " ahenklilik"
  },
  {
    "x": "ahîza (m)",
    "y": " b. ahîze (n)"
  },
  {
    "x": "ahîze-I (m)",
    "y": " b. ahîze (n)"
  },
  {
    "x": "ahîze-II (n)",
    "y": " ahize, alıcı (telefon için), almaç (telefon için)"
  },
  {
    "x": "ahlaq (n)",
    "y": " b. exlaq (n)"
  },
  {
    "x": "ahlaqî, -ye",
    "y": " b. exlaqî, -ye"
  },
  {
    "x": "ahmaq, -e",
    "y": " b. ehmeq, -e"
  },
  {
    "x": "ahtapot",
    "y": " ahtapot"
  },
  {
    "x": "aîd, -e",
    "y": " ait"
  },
  {
    "x": "aîdbîyayîş (n)",
    "y": " aidiyet"
  },
  {
    "x": "aîdîyet (n)",
    "y": " aidiyet"
  },
  {
    "x": "aîle (m)",
    "y": " aile"
  },
  {
    "x": "aj (n)",
    "y": " 1)filiz, sürgün (bitki için)\r\n~2)b. az (n)"
  },
  {
    "x": "aj dayene",
    "y": " filizlenmek, filiz vermek"
  },
  {
    "x": "aj kerdene",
    "y": " budamak (ağaç için)"
  },
  {
    "x": "aja (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "Ajakkîyo (n)",
    "y": " Ajaccio"
  },
  {
    "x": "ajan, -e",
    "y": " ajan, casus, çaşıt"
  },
  {
    "x": "ajan provokator, -e",
    "y": " kışkırtıcı ajan, ajan provokatör"
  },
  {
    "x": "ajanda (m)",
    "y": " ajanda"
  },
  {
    "x": "ajaney (m)",
    "y": " b. ajanîye (m) "
  },
  {
    "x": "ajanê (m)",
    "y": " b. ajanîye (m)"
  },
  {
    "x": "ajanîye (m)",
    "y": " ajanlık, casusluk"
  },
  {
    "x": "ajans (n)",
    "y": " ajans"
  },
  {
    "x": "ajdiha (n)",
    "y": " b. ejdeha (n)"
  },
  {
    "x": "ajdirha (n)",
    "y": " b. ejderha, -ye"
  },
  {
    "x": "ajîr",
    "y": " b. jîr, -e"
  },
  {
    "x": "ajîtasyon (n)",
    "y": " ajîtasyon "
  },
  {
    "x": "ajîtator, -e",
    "y": " ajitatör"
  },
  {
    "x": "ajkerdiş (n)",
    "y": " budama (ağaç için)"
  },
  {
    "x": "ajna (m)",
    "y": " b. ajne (m)"
  },
  {
    "x": "ajna kerdene",
    "y": " b. ajne kerdene"
  },
  {
    "x": "ajnakerdiş (n)",
    "y": " b. ajnekerdiş"
  },
  {
    "x": "ajne (m)",
    "y": " yüzme, çimme"
  },
  {
    "x": "ajne kerdene",
    "y": " yüzmek, çimmek"
  },
  {
    "x": "ajneber, -e",
    "y": " yüzücü"
  },
  {
    "x": "ajneberênî (m)",
    "y": " yüzücülük"
  },
  {
    "x": "ajneberî (m)",
    "y": " yüzücülük"
  },
  {
    "x": "ajneberîye (m)",
    "y": " yüzücülük"
  },
  {
    "x": "ajnekerdiş (n)",
    "y": " yüzme"
  },
  {
    "x": "ajnê (m)",
    "y": " b. ajne (m)"
  },
  {
    "x": "ajnê kerdene",
    "y": " b. ajne kerdene"
  },
  {
    "x": "ajnêkerdiş (n)",
    "y": " b. ajnekerdiş"
  },
  {
    "x": "ajnî (m)",
    "y": " b. ajne (m)"
  },
  {
    "x": "ajnî kerdene",
    "y": " b. ajne kerdene"
  },
  {
    "x": "ajnîkerdiş (n)",
    "y": " b. ajnekerdiş"
  },
  {
    "x": "ajûr (n)",
    "y": " ince ağaç dalı, sepet örmekte kullanılan ince ağaç dalı"
  },
  {
    "x": "ak (n)",
    "y": " b. hak (n)"
  },
  {
    "x": "akademî (m)",
    "y": " akademi"
  },
  {
    "x": "akademîk, -e",
    "y": " akademik "
  },
  {
    "x": "akademîsyen, -e",
    "y": " akademisyen"
  },
  {
    "x": "akademîsyenîye (m)",
    "y": " akademisyenlik "
  },
  {
    "x": "akerda (m)",
    "y": " 1)açık\r\n~2)belirgin"
  },
  {
    "x": "akerde (n)",
    "y": " 1)açık\r\n~2)belirgin\r\n~3)açıktan açığa, açıkça"
  },
  {
    "x": "akerde bîyene",
    "y": " belirginleşmek"
  },
  {
    "x": "akerdebîyayîş (n)",
    "y": " belirginleşme"
  },
  {
    "x": "akerdek (n)",
    "y": " açacak"
  },
  {
    "x": "akerdene",
    "y": " 1)açmak\r\n~2)çözmek (düğüm vb. için)"
  },
  {
    "x": "akerd",
    "y": " açtı"
  },
  {
    "x": "akerdeyîye (m)",
    "y": " açıklık"
  },
  {
    "x": "akerdiş (n)",
    "y": " 1)açma\r\n~2)açış\r\n~3)çözme (düğüm vb. için)"
  },
  {
    "x": "akerdî, -ye",
    "y": " açık"
  },
  {
    "x": "akerdox, -e",
    "y": " açan"
  },
  {
    "x": "akewtene",
    "y": " doğmak (güneş, ay ve yıldızlar için)\r\n~Roj akewt. (Güneş doğdu.)"
  },
  {
    "x": "akewtiş (n)",
    "y": " doğma (güneş, ay ve yıldızlar için)"
  },
  {
    "x": "akin (n)",
    "y": " b. ekin (n)"
  },
  {
    "x": "Akkra (m)",
    "y": " Accra"
  },
  {
    "x": "akordîyon (n)",
    "y": " akordeon, akordiyon"
  },
  {
    "x": "akordîyoncen, -e",
    "y": " akordeoncu, akordiyoncu"
  },
  {
    "x": "akotiş (n)",
    "y": " b. akewtiş (n)"
  },
  {
    "x": "akowtiş (n)",
    "y": " b. akewtiş (n)"
  },
  {
    "x": "akoytiş (n)",
    "y": " b. akewtiş (n)"
  },
  {
    "x": "akredîta (m)",
    "y": " akredite"
  },
  {
    "x": "akredîtasyon (n)",
    "y": " akreditasyon"
  },
  {
    "x": "akredîte (n)",
    "y": " akredite"
  },
  {
    "x": "akrobasî (n)",
    "y": " 1)akrobasi, akrobatlık, cambazlık\r\n~2) cambazlık (hayvan tüccarlığı)"
  },
  {
    "x": "akrobat, -e",
    "y": " akrobat"
  },
  {
    "x": "akrobatîye (m)",
    "y": " 1)akrobasi, akrobatlık, cambazlık\r\n~2) cambazlık (hayvan tüccarlığı)"
  },
  {
    "x": "akrostîş (n)",
    "y": " akrostiş"
  },
  {
    "x": "aks-I (n)",
    "y": " aks, dingil"
  },
  {
    "x": "aksê rayîrî (n)",
    "y": " yol aksı"
  },
  {
    "x": "aks-II (n)",
    "y": " b. eks (n)"
  },
  {
    "x": "aksan (n)",
    "y": " aksan"
  },
  {
    "x": "aksesuar (n)",
    "y": " aksesuar, donatımlık"
  },
  {
    "x": "aksî, -ye",
    "y": " b. eksî, -ye"
  },
  {
    "x": "aksîyê (m)",
    "y": " b. eksîyî (m), eksîyîye (m)"
  },
  {
    "x": "aksîyon (n)",
    "y": " eylem, aksiyon"
  },
  {
    "x": "aksîyon kerdene",
    "y": " eylemde bulunmak"
  },
  {
    "x": "aktîf, -e",
    "y": " etkin, dinamik, etken, aktif, faal"
  },
  {
    "x": "karo aktîf (n)",
    "y": " aktif fiil"
  },
  {
    "x": "aktîfkerde (n) (TE)",
    "y": " etkinleştirilmiş"
  },
  {
    "x": "aktîvîst, -e",
    "y": " eylemci"
  },
  {
    "x": "aktîvîte (m)",
    "y": " etkinlik, faaliyet, aktivite"
  },
  {
    "x": "aktor (n)",
    "y": " erkek oyuncu, aktör"
  },
  {
    "x": "aktorîye (m)",
    "y": " aktörlük"
  },
  {
    "x": "aktrîse (m)",
    "y": " kadın oyuncu, aktris"
  },
  {
    "x": "aktuator (n)",
    "y": " aktüatör"
  },
  {
    "x": "aktualîte (m)",
    "y": " güncellik,  aktüalite"
  },
  {
    "x": "aktuel, -e",
    "y": " güncel, aktüel"
  },
  {
    "x": "aktuelkerdiş (n)",
    "y": " güncelleme, güncelleştirme"
  },
  {
    "x": "aku (n)",
    "y": " akımtoplar, akü, akümülatör"
  },
  {
    "x": "akumulator (n)",
    "y": " akımtoplar, akü, akümülatör"
  },
  {
    "x": "akustîk (n)",
    "y": " akustik"
  },
  {
    "x": "ala-I (m)",
    "y": " bayrak"
  },
  {
    "x": "ala-II",
    "y": " b. hela"
  },
  {
    "x": "Ala (n)",
    "y": " b. Ellah (n)"
  },
  {
    "x": "alacuxe (m)",
    "y": " b. êlancuxe (m)"
  },
  {
    "x": "alaf (n)",
    "y": " b. alef (n)"
  },
  {
    "x": "alafranga",
    "y": " alafranga"
  },
  {
    "x": "alalok, -i",
    "y": " b. elok, -e"
  },
  {
    "x": "alamete (m)",
    "y": " b. elamete (m)"
  },
  {
    "x": "alancuxe (m)",
    "y": " b. êlancuxe (m)"
  },
  {
    "x": "alaqa (m)",
    "y": " b. eleqe (n)"
  },
  {
    "x": "alaqe (n)",
    "y": " b. eleqe (n)"
  },
  {
    "x": "alaqîyayene",
    "y": " b. aliqîyayene"
  },
  {
    "x": "alaqîyayîş (n)",
    "y": " b. aliqîyayîş (n)"
  },
  {
    "x": "alaqnayene",
    "y": " b. aliqnayene"
  },
  {
    "x": "alaqnayîş (n)",
    "y": " b. aliqnayîş (n)"
  },
  {
    "x": "alarm (n)",
    "y": " alarm"
  },
  {
    "x": "alarm cenayene",
    "y": " alarm çalmak"
  },
  {
    "x": "alarm dayene",
    "y": " alarm vermek"
  },
  {
    "x": "alarmê gazî (n)",
    "y": " gaz alarmı"
  },
  {
    "x": "alatirik (n)",
    "y": " b. elektrîk (n)"
  },
  {
    "x": "alatirîng (n)",
    "y": " b. elektrîk (n)"
  },
  {
    "x": "alatirk (n)",
    "y": " b. elektrîk (n)"
  },
  {
    "x": "alatîrîk (n)",
    "y": " b. elektrîk (n)"
  },
  {
    "x": "alatîrk (n)",
    "y": " b. elektrîk (n)"
  },
  {
    "x": "alaturk (n)",
    "y": " b. elektrîk (n)"
  },
  {
    "x": "alaturka, -ye",
    "y": " alaturka, -ye"
  },
  {
    "x": "alawe (n)",
    "y": " oğlak ve kuzu gibi hayvanların annelerinin sütünü emmesini engellemek amacıyla ağızlarına takılan ağızlık"
  },
  {
    "x": "Alawekîl",
    "y": " b. Ellahwekîl"
  },
  {
    "x": "alawitene",
    "y": " b. elawitene"
  },
  {
    "x": "alawitiş (n)",
    "y": " b. elawitiş (n)"
  },
  {
    "x": "alawî (m)",
    "y": " alev"
  },
  {
    "x": "alawtene",
    "y": " b. elawitene"
  },
  {
    "x": "alawtiş (n)",
    "y": " b. elawitiş (n)"
  },
  {
    "x": "alaye (m)",
    "y": " alay"
  },
  {
    "x": "alaya muhafizan (m)",
    "y": " muhafız alayı"
  },
  {
    "x": "alayî (m)",
    "y": " b. alaye (m)"
  },
  {
    "x": "alayîye (m)",
    "y": " b. alaye (m)"
  },
  {
    "x": "alazîyayene",
    "y": " b. alozîyayene"
  },
  {
    "x": "alazîyayîş (n)",
    "y": " b. alozîyayîş (n)"
  },
  {
    "x": "alaznayene",
    "y": " b. aloznayene"
  },
  {
    "x": "alaznayîş (n)",
    "y": " b. aloznayîş (n)"
  },
  {
    "x": "alaznîyayene",
    "y": " b. aloznîyayene"
  },
  {
    "x": "alaznîyayîş (n)",
    "y": " b. aloznîyayîş (n)"
  },
  {
    "x": "Albanya (m)",
    "y": " Arnavutluk"
  },
  {
    "x": "albaz, -e",
    "y": " b. embaz, -e"
  },
  {
    "x": "albazîye (m)",
    "y": " b. embazîye (m)"
  },
  {
    "x": "albik (m)",
    "y": " b. elbike (m)"
  },
  {
    "x": "albîno (n)",
    "y": " albino"
  },
  {
    "x": "alboz, -e",
    "y": " b. embaz, -e"
  },
  {
    "x": "albozîye (m)",
    "y": " b. embazîye (m)"
  },
  {
    "x": "album (n)",
    "y": " albüm"
  },
  {
    "x": "aldan (n)",
    "y": " b. andal (n)"
  },
  {
    "x": "aldon (n)",
    "y": " b. andal (n)"
  },
  {
    "x": "Aldûş (n)",
    "y": " Gerger"
  },
  {
    "x": "alef (n)",
    "y": " yem"
  },
  {
    "x": "alem (n)",
    "y": " âlem, cihan, kâinat, evren, kozmos"
  },
  {
    "x": "aleman (n)",
    "y": " alman (bir tüfek çeşidi)"
  },
  {
    "x": "alemon (n)",
    "y": " b. aleman (n)"
  },
  {
    "x": "alemşumûl, -e",
    "y": " âlemşümul, cihanşümul, evrensel, üniversal"
  },
  {
    "x": "alemşumûlî (m)",
    "y": " âlemşümullük, cihanşümullük, evrensellik"
  },
  {
    "x": "alemşumûlîye (m)",
    "y": " âlemşümullük, cihanşümullük, evrensellik"
  },
  {
    "x": "alemûn (n)",
    "y": " b. aleman (n)"
  },
  {
    "x": "aleqa",
    "y": " b. eleqe (n)"
  },
  {
    "x": "aleqe",
    "y": " b. eleqe (n)"
  },
  {
    "x": "aleqîyayene",
    "y": " b. aliqîyayene"
  },
  {
    "x": "aleqîyayîş (n)",
    "y": " b. aliqîyayîş (n)"
  },
  {
    "x": "aleqnayene",
    "y": " b. aliqnayene"
  },
  {
    "x": "aleqnayîş (n)",
    "y": " b. aliqnayîş (n)"
  },
  {
    "x": "alerjî (m)",
    "y": " alerji"
  },
  {
    "x": "alerjîdar, -e",
    "y": " alerjili"
  },
  {
    "x": "alerjîk, -e",
    "y": " alerjik"
  },
  {
    "x": "alerjîkerdoxî (zh)",
    "y": " alerji yapıcılar"
  },
  {
    "x": "alet (n)",
    "y": " b. alete (m)"
  },
  {
    "x": "alete (m)",
    "y": " saban, karasaban"
  },
  {
    "x": "tîra alete (m)",
    "y": " saban oku, karasaban oku"
  },
  {
    "x": "aleti (m)",
    "y": " b. alete (m)"
  },
  {
    "x": "aletîrik (n)",
    "y": " b. elektrîk (n)"
  },
  {
    "x": "alevî, -ye",
    "y": " b. elewî, -ye"
  },
  {
    "x": "alevîtîye (m)",
    "y": " b. elewîtîye (m)"
  },
  {
    "x": "alevîyênî (m)",
    "y": " b. elewîyênî (m)"
  },
  {
    "x": "alevîyîye (m)",
    "y": " b. elewîyîye (m)"
  },
  {
    "x": "aleyh (n)",
    "y": " b. eleyh (n)"
  },
  {
    "x": "aleykumselam",
    "y": " b. eleykumselam"
  },
  {
    "x": "aleykumsilam",
    "y": " b. eleykumselam"
  },
  {
    "x": "alên (n)",
    "y": " b. halîn (n)"
  },
  {
    "x": "alfabe (m)",
    "y": " abece, alfabe "
  },
  {
    "x": "alfabetîk, -e",
    "y": " abecesel, alfabetik"
  },
  {
    "x": "alfabeyî, -ye",
    "y": " abecesel, alfabetik"
  },
  {
    "x": "algoşe (n)",
    "y": " kabakulak"
  },
  {
    "x": "algoşe girewtene",
    "y": " kabakulak olmak "
  },
  {
    "x": "algueşik (n)",
    "y": " b. algoşe (n)"
  },
  {
    "x": "algûşe (n)",
    "y": " b. algoşe (n)"
  },
  {
    "x": "alheş, -i",
    "y": " b. alifweş, -e"
  },
  {
    "x": "alif (n)",
    "y": " b. alef (n)"
  },
  {
    "x": "alifweş, -e",
    "y": " iştahlı"
  },
  {
    "x": "alim, -e",
    "y": " bilgin, âlim"
  },
  {
    "x": "alim (n)",
    "y": " alışma"
  },
  {
    "x": "alim girewtene",
    "y": " alışmak"
  },
  {
    "x": "alimgirewtiş (n)",
    "y": " alışma"
  },
  {
    "x": "alinc (m)",
    "y": " b. alunça (m)"
  },
  {
    "x": "alinç (m)",
    "y": " b. alunça (m)"
  },
  {
    "x": "alinça (m)",
    "y": " b. alunça (m)"
  },
  {
    "x": "alinçêri (m)",
    "y": " b. alunçêre (m)"
  },
  {
    "x": "alinçi (m)",
    "y": " b. alunça (m)"
  },
  {
    "x": "aling (n)",
    "y": " b. halîn (n)"
  },
  {
    "x": "aliqe (m)",
    "y": " askı (giysi vb için)"
  },
  {
    "x": "aliqîyayene",
    "y": " asılmak (idam edilmek)"
  },
  {
    "x": "aliqîyayîş (n)",
    "y": " asılma (idam edilme)"
  },
  {
    "x": "aliqnaya (m)",
    "y": " asılı"
  },
  {
    "x": "aliqnaye (m)",
    "y": " asılı"
  },
  {
    "x": "aliqnayene",
    "y": " 1)asmak, idam etmek\r\n~2)askıya çıkarma (evrak vb. için)"
  },
  {
    "x": "aliqnayîş (n)",
    "y": " 1)asma, idam etme\r\n~2)askıya çıkarma (evrak vb. için)\r\n~3)askı (asma işi)"
  },
  {
    "x": "aliqnayîye (m)",
    "y": " asılı"
  },
  {
    "x": "aliske (m)",
    "y": " b. alişke (m)"
  },
  {
    "x": "alişke (m)",
    "y": " yanak"
  },
  {
    "x": "alişki (m)",
    "y": " b. alişke (m)"
  },
  {
    "x": "alizîyaya (m)",
    "y": " b. alozîyaya (m) "
  },
  {
    "x": "alizîyaye (n)",
    "y": " b. alozîyaye (n)"
  },
  {
    "x": "alizîyayene-I",
    "y": " b. helisîyayene"
  },
  {
    "x": "alizîyayene-I",
    "y": " b. alozîyayene"
  },
  {
    "x": "alizîyayeyê (m)",
    "y": " b. alozîyayeyî (m)"
  },
  {
    "x": "alizîyayeyî (m)",
    "y": " b. alozîyayeyî (m)"
  },
  {
    "x": "alizîyayi (n)",
    "y": " b. alozîyaye (n)"
  },
  {
    "x": "alizîyayîne",
    "y": " b. helisîyayene"
  },
  {
    "x": "alizîyayîş-I (n)",
    "y": " b. helisîyayîş (n)"
  },
  {
    "x": "alizîyayîş-II (n)",
    "y": " b. alozîyayîş (n)"
  },
  {
    "x": "aliznaya (m)",
    "y": " b. aloznaya (m)"
  },
  {
    "x": "aliznaye (n)",
    "y": " b. aloznaye (n)"
  },
  {
    "x": "aliznayene",
    "y": " b. aloznayene"
  },
  {
    "x": "aliznayi (n)",
    "y": " b. aloznaye (n)"
  },
  {
    "x": "aliznayîş (n)",
    "y": " b. aloznayîş (n)"
  },
  {
    "x": "aliznîyayene",
    "y": " b. aloznîyayene"
  },
  {
    "x": "aliznîyayîş (n)",
    "y": " b. aloznîyayîş (n)"
  },
  {
    "x": "Alî",
    "y": " Ali"
  },
  {
    "x": "alî (m)",
    "y": " tükürük"
  },
  {
    "x": "alî kerdene",
    "y": " tükürüklemek"
  },
  {
    "x": "alî ... sawitene",
    "y": " tükürüklemek"
  },
  {
    "x": "alî ... sawitiş",
    "y": " tükürükleme"
  },
  {
    "x": "alîcux (m)",
    "y": " b. êlancuxa kere (m)"
  },
  {
    "x": "alîkerdiş (n)",
    "y": " tükürükleme"
  },
  {
    "x": "alîm, -e",
    "y": " b. alim, -e"
  },
  {
    "x": "alîn (n)",
    "y": " b. halîn (n)"
  },
  {
    "x": "alîşk (m)",
    "y": " b. alişke (m)"
  },
  {
    "x": "alîterasyon (n)",
    "y": " aliterasyon"
  },
  {
    "x": "alîye (m)",
    "y": " tükürük"
  },
  {
    "x": "alkol (n)",
    "y": " alkol"
  },
  {
    "x": "alkolin, -e",
    "y": " alkollü"
  },
  {
    "x": "alkolîk, -e",
    "y": " ayyaş"
  },
  {
    "x": "alkolmetre (n)",
    "y": " alkolmetre, alkolölçer"
  },
  {
    "x": "alkolpeym (n)",
    "y": " alkolmetre, alkolölçer"
  },
  {
    "x": "alkolşimitox, -e",
    "y": " alkollü (insan için)"
  },
  {
    "x": "alkolîk, -e",
    "y": " alkolik"
  },
  {
    "x": "Alla (n)",
    "y": " b. Ellah (n)"
  },
  {
    "x": "Alla zûnû",
    "y": " b. Ellah zano."
  },
  {
    "x": "(Ti) Allay sînenî/a",
    "y": " b. (Ti) Ellahî sînenî/a"
  },
  {
    "x": "Allawekîl",
    "y": " b. Ellahwekîl"
  },
  {
    "x": "allorîg (n)",
    "y": " b. helasorî, -ye"
  },
  {
    "x": "allorîg bîyayene",
    "y": " b. helasorî bîyene"
  },
  {
    "x": "allorîgî (m)",
    "y": " b. helasorîyîye (m)"
  },
  {
    "x": "alo",
    "y": " alo"
  },
  {
    "x": "alqol (n)",
    "y": " b. alkol (n)"
  },
  {
    "x": "alqolin, -e",
    "y": " b. alkolin, -e"
  },
  {
    "x": "alqolwerdox, -e",
    "y": " b. alkolşimitox, -e"
  },
  {
    "x": "Alla (n)",
    "y": " b. Ellah (n)"
  },
  {
    "x": "Allah (n)",
    "y": " b. Ellah (n)"
  },
  {
    "x": "allî (m)",
    "y": " b. alî (m), alîye (m)"
  },
  {
    "x": "allî kerdene",
    "y": " b. alî kerdene"
  },
  {
    "x": "allî ... sawitene",
    "y": " b. alî ... sawitene"
  },
  {
    "x": "allî ... sawitiş",
    "y": " b. alî ... sawitiş"
  },
  {
    "x": "allîkerdiş (n)",
    "y": " b. alîkerdiş (n)"
  },
  {
    "x": "allû (m)",
    "y": " b. alî (m), alîye (m)"
  },
  {
    "x": "allû kerdene",
    "y": " b. alî kerdene"
  },
  {
    "x": "allû ... sawutene",
    "y": " b. alî ... sawitene"
  },
  {
    "x": "allû ... sawutiş",
    "y": " b. alî ... sawitiş"
  },
  {
    "x": "allûkerdiş (n)",
    "y": " b. alîkerdiş (n)"
  },
  {
    "x": "alman, -e",
    "y": " Alman"
  },
  {
    "x": "almankî (m)",
    "y": " Almanca"
  },
  {
    "x": "Almanya (m)",
    "y": " Almanya"
  },
  {
    "x": "Almanyaya Federale (m)",
    "y": " Federal Almanya"
  },
  {
    "x": "almas (n)",
    "y": " b. elmas (n)"
  },
  {
    "x": "almast (n)",
    "y": " b. elmas (n)"
  },
  {
    "x": "almaşt (n)",
    "y": " b. elmas (n)"
  },
  {
    "x": "almost (n)",
    "y": " b. elmas (n)"
  },
  {
    "x": "almoşt (n)",
    "y": " b. elmas (n)"
  },
  {
    "x": "alonçe (m)",
    "y": " b. alunça (m)"
  },
  {
    "x": "alonçi (m)",
    "y": " b. alunça (m)"
  },
  {
    "x": "aloqî (zh)",
    "y": " bademcik (bademcikler)"
  },
  {
    "x": "aloqîdeginayîş (n)",
    "y": " bademcik şişmesi"
  },
  {
    "x": "alorîg (n)",
    "y": " b. harolîg (n)"
  },
  {
    "x": "alorîg bîyayîne",
    "y": " b. harolîg bîyene"
  },
  {
    "x": "alorîgbîyayîş (n)",
    "y": " b. harolîgbîyayîş (n)"
  },
  {
    "x": "alorîk (n)",
    "y": " 1)b. harolîg (n)\r\n~2)b. helasorî, -ye"
  },
  {
    "x": "alorîk bîyayene",
    "y": " 1)b. harolîg bîyene\r\n~2)b. helasorî bîyene"
  },
  {
    "x": "alorîkbîyayîş (n)",
    "y": " b. harolîgbîyayîş (n)"
  },
  {
    "x": "alorîkî (m)",
    "y": " b. helasorîyîye (m)"
  },
  {
    "x": "aloz bîyene",
    "y": " karışmak (karışık olmak)"
  },
  {
    "x": "aloz kerdene",
    "y": " karıştırmak (karışık duruma getirmek)"
  },
  {
    "x": "alozaye (n)",
    "y": " b. alozîyaye (n)"
  },
  {
    "x": "alozayene",
    "y": " b. alozîyayene"
  },
  {
    "x": "alozayî (m)",
    "y": " b. alozîyayî (m)"
  },
  {
    "x": "alozayîş (n)",
    "y": " b. alozîyayîş (n)"
  },
  {
    "x": "alozayîye (m)",
    "y": " b. alozîyayî (m)"
  },
  {
    "x": "alozbîyayîş (n)",
    "y": " karışma  (karışık olma) "
  },
  {
    "x": "alozî (m)",
    "y": " karışıklık (birbirine dolanma durumu)"
  },
  {
    "x": "alozîyaya (m)",
    "y": " karışık, karmakarışık (birbirine dolanmış olan) "
  },
  {
    "x": "alozîyaye (n)",
    "y": " karışık, karmakarışık (birbirine dolanmış olan)\r\n~No la zaf alozîyaye yo. (Bu iplik çok karışık.)"
  },
  {
    "x": "alozîyayene",
    "y": " karışmak (birbirine dolanmak, karışmak)\r\n~No la zaf alozîyayo. (Bu iplik çok karışmış.)"
  },
  {
    "x": "alozîyayeyî (m)",
    "y": " karışıklık (birbirine dolanma durumu)"
  },
  {
    "x": "alozîyayî (m)",
    "y": " karışık (birbirine dolanmış olan)"
  },
  {
    "x": "alozîyayîş (n)",
    "y": " karışma (birbirine dolanma, karışma)"
  },
  {
    "x": "alozkerdiş (n)",
    "y": " karıştırma (karışık duruma getirme)"
  },
  {
    "x": "aloznaya (m)",
    "y": " karışık, karmakarışık (birbirine dolanmış olan)"
  },
  {
    "x": "aloznaye (n)",
    "y": " karışık, karmakarışık (birbirine dolanmış olan)"
  },
  {
    "x": "aloznayene",
    "y": " karıştırmak (birbirine dolamak)  \r\n~To no la alozna. (Sen bu ipliği karıştırdın.)\r\n~Layî mealozne! (İpliği karıştırma!)\t"
  },
  {
    "x": "aloznayîş (n)",
    "y": " karıştırma (birbirine dolama)"
  },
  {
    "x": "aloznayîye (m)",
    "y": " karışık, karmakarışık (birbirine dolanmış olan)"
  },
  {
    "x": "aloznîyayene",
    "y": " karıştırılmak (birbirine dolandırılmak)"
  },
  {
    "x": "aloznîyayîş (n)",
    "y": " karıştırılma (birbirine dolandırılma)"
  },
  {
    "x": "ALS (Amyotrophic Lateral Sclerosis)",
    "y": " ALS"
  },
  {
    "x": "alternatîf (n)",
    "y": " seçenek, alternatif"
  },
  {
    "x": "alternatîfîye (m)",
    "y": " alternatiflik"
  },
  {
    "x": "altin (n)",
    "y": " b. altun (n)"
  },
  {
    "x": "altun (n)",
    "y": " altın, b. zerrn (n), zerd (n)"
  },
  {
    "x": "altûn (n)",
    "y": " b. altun (n)"
  },
  {
    "x": "alu (m)",
    "y": " b. alî (m), alîye (m)"
  },
  {
    "x": "aluc (m)",
    "y": " b. alunça (m)"
  },
  {
    "x": "aluerîg (n)",
    "y": " b. helasorî, -ye"
  },
  {
    "x": "aluerîg bîyayîne",
    "y": " b. helasorî bîyene"
  },
  {
    "x": "aluerîgê",
    "y": " b. helasorîyîye (m)"
  },
  {
    "x": "alukî (zh)",
    "y": " bademcik (bademcikler)"
  },
  {
    "x": "alukîdeginayîş (n)",
    "y": " bademcik şişmesi"
  },
  {
    "x": "alumînyum (n)",
    "y": " alüminyum"
  },
  {
    "x": "aluncuk (m)",
    "y": " b. êlancuxe (m)"
  },
  {
    "x": "alunç (m)",
    "y": " b. alunça (m)"
  },
  {
    "x": "alunça (m)",
    "y": " erik"
  },
  {
    "x": "alunçe (m)",
    "y": " b. alunça (m)"
  },
  {
    "x": "alunçêre (m)",
    "y": " erik ağacı"
  },
  {
    "x": "alunçêri (m)",
    "y": " b. alunçêre (m)"
  },
  {
    "x": "alunçi (m)",
    "y": " b. alunça (m)"
  },
  {
    "x": "aluqî (zh)",
    "y": " b. aloqî (zh)"
  },
  {
    "x": "aluşke (m)",
    "y": " b. alişke (m)"
  },
  {
    "x": "aluşte (n)",
    "y": " b. helquşte (n)"
  },
  {
    "x": "aluvyon (n)",
    "y": " alüvyon, lığ"
  },
  {
    "x": "aluw (m)",
    "y": " b. alî (m), alîye (m)"
  },
  {
    "x": "aluzîyayene",
    "y": " b. alozîyayene"
  },
  {
    "x": "aluzîyayîş (n)",
    "y": " b. alozîyayîş (n)"
  },
  {
    "x": "aluznayene",
    "y": " b. aloznayene"
  },
  {
    "x": "aluznayîş (n)",
    "y": " b. aloznayîş (n)"
  },
  {
    "x": "aluznîyayene",
    "y": " b. aloznîyayene"
  },
  {
    "x": "aluznîyayîş (n)",
    "y": " b. aloznîyayîş (n)"
  },
  {
    "x": "alû (m)",
    "y": " b. alî (m), alîye (m)"
  },
  {
    "x": "alû kerdene",
    "y": " b. alî kerdene"
  },
  {
    "x": "alû ... sawitene",
    "y": " b. alî ... sawitene"
  },
  {
    "x": "alû ... sawitiş",
    "y": " b. alî ... sawitiş"
  },
  {
    "x": "alûkerdiş (n)",
    "y": " b. alîkerdiş (n)"
  },
  {
    "x": "alûnç (m)",
    "y": " b. alunça (m)"
  },
  {
    "x": "alûw (m)",
    "y": " b. alî (m), alîye (m)"
  },
  {
    "x": "alvaz, -e",
    "y": " b. embaz, -e"
  },
  {
    "x": "alvazîye (m)",
    "y": " b. embazîye (m)"
  },
  {
    "x": "alvoz, -e",
    "y": " b. embaz, -e"
  },
  {
    "x": "alvozîye (m)",
    "y": " b. embazîye (m)"
  },
  {
    "x": "alwe (n)",
    "y": " b. alawe (n)"
  },
  {
    "x": "alwey (zh)",
    "y": " b. awleyî (zh)"
  },
  {
    "x": "alwey vetene",
    "y": " b. awleyî vetene"
  },
  {
    "x": "alweyin, -i",
    "y": " b. awleyin, -e"
  },
  {
    "x": "alweyîn, -e",
    "y": " b. awleyin, -e"
  },
  {
    "x": "alwi (n)",
    "y": " b. alî (m), alîye (m)"
  },
  {
    "x": "alwi kerdene",
    "y": " b. alî kerdene"
  },
  {
    "x": "alwi ... sawitene",
    "y": " b. alî ... sawitene"
  },
  {
    "x": "alwi ... sawitiş",
    "y": " b. alî ... sawitiş"
  },
  {
    "x": "alwikerdiş (n)",
    "y": " b. alîkerdiş (n)"
  },
  {
    "x": "alwu (n)",
    "y": " b. alî (m), alîye (m)"
  },
  {
    "x": "alwu kerdene",
    "y": " b. alî kerdene"
  },
  {
    "x": "alwu ... sawitene",
    "y": " b. alî ... sawitene"
  },
  {
    "x": "alwu ... sawitiş (n)",
    "y": " b. alî ... sawitiş"
  },
  {
    "x": "alwukerdiş (n)",
    "y": " b. alîkerdiş (n)"
  },
  {
    "x": "alzinayene",
    "y": " b. aloznayene"
  },
  {
    "x": "alzinayi (n)",
    "y": " b. aloznaye (n)"
  },
  {
    "x": "alzinayîş (n)",
    "y": " b. aloznayîş (n)"
  },
  {
    "x": "alzinîyayene",
    "y": " b. aloznîyayene"
  },
  {
    "x": "alzinîyayîş (n)",
    "y": " b. aloznîyayîş (n)"
  },
  {
    "x": "alzîyayene",
    "y": " b. alozîyayene"
  },
  {
    "x": "alzîyayîş (n)",
    "y": " b. alozîyayîş (n)"
  },
  {
    "x": "ama, -ye",
    "y": " b. ema, -ye"
  },
  {
    "x": "amac (n)",
    "y": " b. amanc (n)"
  },
  {
    "x": "amada (m)",
    "y": " amade, hazır"
  },
  {
    "x": "amade (n)",
    "y": " amade, hazır"
  },
  {
    "x": "amade bîyene",
    "y": " hazır olmak\r\n~Amade be! (Hazır ol!)"
  },
  {
    "x": "amade kerdene",
    "y": " hazırlamak, düzenlemek, tertip etmek, tertiplemek"
  },
  {
    "x": "amadekar, -e",
    "y": " hazırlayan, düzenleyici, tertipleyici"
  },
  {
    "x": "amadekarî (m)",
    "y": " hazırlık "
  },
  {
    "x": "amadekarîye (m)",
    "y": " hazırlık"
  },
  {
    "x": "amadekerdiş (n)",
    "y": " hazırlama, düzenleme, tertip etme, tertipleme"
  },
  {
    "x": "amadekerdox, -e",
    "y": " hazırlayan"
  },
  {
    "x": "amaîs (n)",
    "y": " ameyîş (n)"
  },
  {
    "x": "amanc (n)",
    "y": " amaç, erek\r\n~amancê gurenîyayîşî (n): kullanım amacı"
  },
  {
    "x": "amanc kerdene",
    "y": " amaçlamak"
  },
  {
    "x": "amancê binênî (zh)",
    "y": " alt amaçlar"
  },
  {
    "x": "amancê gurenîyayîşî (n)",
    "y": " kullanım amacı"
  },
  {
    "x": "amancê hemeyîyî (zh)",
    "y": " genel amaçlar"
  },
  {
    "x": "amancê pêroyîyî (zh)",
    "y": " genel amaçlar"
  },
  {
    "x": "amancê projeyî (n)",
    "y": " proje amacı"
  },
  {
    "x": "amancê şuxulnîyayîşî (n)",
    "y": " kullanım amacı"
  },
  {
    "x": "amancê xebitnîyayîşî (n)",
    "y": " kullanım amacı"
  },
  {
    "x": "amanete (m)",
    "y": " b. emanete (m)"
  },
  {
    "x": "amaneti (m)",
    "y": " b. emanete (m)"
  },
  {
    "x": "amartine",
    "y": " b. hûmaritene"
  },
  {
    "x": "amator, -e",
    "y": " amatör"
  },
  {
    "x": "amatorîye (m)",
    "y": " amatörlük"
  },
  {
    "x": "amayene",
    "y": " b. ameyene"
  },
  {
    "x": "ameyene beşkerdene",
    "y": " bölümlenmek, sınıflanmak"
  },
  {
    "x": "amayene çêrkerdene",
    "y": " b. ameyene çîrkerdene"
  },
  {
    "x": "ameyene dîyayene",
    "y": " b. ameyene dîyene"
  },
  {
    "x": "ameyene dîyene",
    "y": " bulunmak"
  },
  {
    "x": "amayene fomkerdene",
    "y": " b. ameyene fehmkerdene"
  },
  {
    "x": "amayene gîrenayene",
    "y": " b. ameyene girênayene"
  },
  {
    "x": "amayene gîrênayene",
    "y": " b. ameyene girênayene"
  },
  {
    "x": "amayene heskerdene",
    "y": " b. ameyene heskerdene"
  },
  {
    "x": "amayene kiştene",
    "y": " b. ameyene kiştene"
  },
  {
    "x": "amayene kizikkerdene",
    "y": " b. ameyene kijikekerdene"
  },
  {
    "x": "amayene ramitene",
    "y": " b. ameyene ramitene"
  },
  {
    "x": "amayene pêser",
    "y": " b. ameyene pêser"
  },
  {
    "x": "amayene pirozinekerdene",
    "y": " b. ameyene pirojinekerdene"
  },
  {
    "x": "amayene pînekerdene",
    "y": " b. ameyene pînekerdene"
  },
  {
    "x": "amayene potene",
    "y": " b. ameyene pewtene"
  },
  {
    "x": "amayene qirvankerdene",
    "y": " b. ameyene qurbankerdene"
  },
  {
    "x": "amayene şutene",
    "y": " b. ameyene şutene"
  },
  {
    "x": "ameyene tesnîfkerdene",
    "y": " bölümlenmek, sınıflanmak"
  },
  {
    "x": "amayene vatene",
    "y": " b. ameyene vatene"
  },
  {
    "x": "... amayene",
    "y": " b. ... ameyene"
  },
  {
    "x": "amayêne",
    "y": " b. ameyene"
  },
  {
    "x": "amayêne pêsnayêne",
    "y": " b. ameyene pêşnayene"
  },
  {
    "x": "amayêne pirozin kerdene",
    "y": " b. ameyene pirojinekerdene"
  },
  {
    "x": "... amayêne",
    "y": " b. ... ameyene"
  },
  {
    "x": "amayîne",
    "y": " b. ameyene"
  },
  {
    "x": "amayîne çîrkerdene",
    "y": " b. ameyene çîrkerdene"
  },
  {
    "x": "amayîne çîrrkerdene",
    "y": " b. ameyene çîrkerdene"
  },
  {
    "x": "amayîne helnayene",
    "y": " b. ameyene helênayene"
  },
  {
    "x": "amayîne qîjkerdene",
    "y": " b. ameyene qîşkerdene"
  },
  {
    "x": "amayîne qîşkerdene",
    "y": " b. ameyene qîşkerdene"
  },
  {
    "x": "amayîne vateni",
    "y": " b. ameyene vatene"
  },
  {
    "x": "amayîne vilêşnayene",
    "y": " b. ameyene vilêşnayene"
  },
  {
    "x": "... amayîne",
    "y": " b. ... ameyene"
  },
  {
    "x": "amayîs (n)",
    "y": " ameyîş (n)"
  },
  {
    "x": "amayîspînekerdis (n)",
    "y": " b. ameyîşpînekerdiş (n)"
  },
  {
    "x": "amayîş (n)",
    "y": " ameyîş (n)"
  },
  {
    "x": "... amayîş",
    "y": " b. ... ameyîş"
  },
  {
    "x": "amayîşerosernayîş (n)",
    "y": " b. ameyîşsernayîş (n)"
  },
  {
    "x": "amayîşfomkerdiş (n)",
    "y": " b. ameyîşfehmkerdiş (n)"
  },
  {
    "x": "amayîşgîrenayîş (n)",
    "y": " b. ameyîşgirênayîş (n)"
  },
  {
    "x": "amayîşgîrênayîş (n)",
    "y": " b. ameyîşgirênayîş (n)"
  },
  {
    "x": "amayîşhelnayîş (n)",
    "y": " b. ameyîşhelênayîş (n)"
  },
  {
    "x": "amayîşheskerdiş (n)",
    "y": " b. ameyîşheskerdiş (n)"
  },
  {
    "x": "amayîşkizikkerdiş (n)",
    "y": " b. ameyîşkijikekerdiş (n)"
  },
  {
    "x": "amayîşpêser (n)",
    "y": " b. ameyîşpêser (n)"
  },
  {
    "x": "amayîşpêsnayîş (n)",
    "y": " b. ameyîşpêşnayîş (n)"
  },
  {
    "x": "amayîşpotiş (n)",
    "y": " b. ameyîşpewtiş (n)"
  },
  {
    "x": "amayîşvatiş (n)",
    "y": " b. ameyîşvatiş (n)"
  },
  {
    "x": "amayîşvilêşnayîş (n)",
    "y": " b. ameyîşvilêşnayîş (n)"
  },
  {
    "x": "ambalaj (n)",
    "y": " ambalaj "
  },
  {
    "x": "ambalaj kerdene",
    "y": " ambalajlamak"
  },
  {
    "x": "ambalajkerda (m)",
    "y": " ambalajlı"
  },
  {
    "x": "ambalajkerde (n)",
    "y": " ambalajlı"
  },
  {
    "x": "ambalajkerdiş (n)",
    "y": " ambalajlama"
  },
  {
    "x": "ambalajkerdî, -ye",
    "y": " ambalajlı"
  },
  {
    "x": "ambar (n)",
    "y": " b. embar (n)"
  },
  {
    "x": "ambargo (n)",
    "y": " ambargo, engelleyim"
  },
  {
    "x": "ambaz (n)",
    "y": " b. embaz (n)"
  },
  {
    "x": "amberyan, -i",
    "y": " b. embiryan, -e"
  },
  {
    "x": "amberyanîni (m)",
    "y": " b. embiryanîne (m)"
  },
  {
    "x": "ambiryan, -i",
    "y": " b. embiryan, -e"
  },
  {
    "x": "ambiryanîni (m)",
    "y": " b. embiryanîne (m)"
  },
  {
    "x": "amblem (n)",
    "y": " amblem"
  },
  {
    "x": "ambulans (n)",
    "y": " b. ambulanse (m)"
  },
  {
    "x": "ambulanse (m)",
    "y": " cankurtaran, ambülans"
  },
  {
    "x": "ame (n)",
    "y": " b. amên (n)"
  },
  {
    "x": "ame (m)",
    "y": " b. eme (m)"
  },
  {
    "x": "amel (n)",
    "y": " b. emel (n)"
  },
  {
    "x": "amele (n)",
    "y": " b. emele (n)"
  },
  {
    "x": "amen (n)",
    "y": " b. amên (n)"
  },
  {
    "x": "amen bîyayene",
    "y": " b. amên bîyene"
  },
  {
    "x": "amen kerdene",
    "y": " b. amên kerdene"
  },
  {
    "x": "amenbîyayîş (n)",
    "y": " b. amênbîyayîş (n)"
  },
  {
    "x": "amenkerde (n)",
    "y": " b. amênkerde (n)"
  },
  {
    "x": "amenkerdiş (n)",
    "y": " b. amênkerdiş (n)"
  },
  {
    "x": "Amerîka (m)",
    "y": " Amerika"
  },
  {
    "x": "amerikayî, -ye",
    "y": " Amerikan"
  },
  {
    "x": "amerîkayij, -e",
    "y": " Amerikalı"
  },
  {
    "x": "amerîkyûm (n)",
    "y": " amerikyum"
  },
  {
    "x": "ameye (n)",
    "y": " gelir, hâsılat, varidat"
  },
  {
    "x": "ameyeyê rojêk",
    "y": " bir günlük gelir"
  },
  {
    "x": "ameyeyê şaredarî/beledîya (zh)",
    "y": " belediye gelirleri"
  },
  {
    "x": "ameyeyê yew roje",
    "y": " bir günlük gelir"
  },
  {
    "x": "ameyeyo rojane",
    "y": " günlük gelir"
  },
  {
    "x": "ameyene",
    "y": " 1)gelmek\r\n~O êno./O yeno. (O geliyor.)\r\n~Ti bêre!/Ti bê! (Sen gel!) \r\n~2)bitmek (bitki vb. için) \r\n~3)akmak (çeşme, musluk vb. için)"
  },
  {
    "x": "... ameyene",
    "y": " yakışmak, uygun düşmek, uygun gelmek, uygun olmak, uymak, yaraşmak"
  },
  {
    "x": "ameyene aloznayene",
    "y": " karıştırılmak (birbirine dolandırılmak, karıştırılmak)"
  },
  {
    "x": "ameyene aliqnayene",
    "y": " asılmak (idam edilmek)"
  },
  {
    "x": "ameyene arêdayene",
    "y": " devşirilmek"
  },
  {
    "x": "ameyene arêkerdene",
    "y": " devşirilmek"
  },
  {
    "x": "ameyene arşîvkerdene",
    "y": " arşivlenmek"
  },
  {
    "x": "ameyene asfaltkerdene",
    "y": " asfaltlanmak"
  },
  {
    "x": "ameyene awedayene",
    "y": " sulanmak\r\n~Gelo estorî ameyê awedayene?/Gelo estorî awedîyayî? (Atlar sulandı mı?)"
  },
  {
    "x": "ameyene axmekerdene",
    "y": " dağıtılmak"
  },
  {
    "x": "ameyene badiroskerdene",
    "y": " aşılanmak (bitki için)"
  },
  {
    "x": "ameyene barzkardene",
    "y": " b. ameyene berzkerdene"
  },
  {
    "x": "ameyene beran",
    "y": " b. beran ameyene"
  },
  {
    "x": "ameyene berdene",
    "y": " kaçırılmak"
  },
  {
    "x": "ameyene berzkerdene",
    "y": " kaldırılmak"
  },
  {
    "x": "ameyene bestene",
    "y": " bağlanmak"
  },
  {
    "x": "ameyene bexişkerdene",
    "y": " affedilmek, bağışlanmak"
  },
  {
    "x": "ameyene bexişnayene",
    "y": " affedilmek, bağışlanmak"
  },
  {
    "x": "ameyene boyaxkerdene",
    "y": " boyalanmak, boyanmak"
  },
  {
    "x": "ameyene caardene",
    "y": " gerçekleştirilmek"
  },
  {
    "x": "ameyene cêmîyanvistene",
    "y": " karıştırılmak (birbirine dolandırılmak, karıştırılmak)\r\n~Wa la nêro cêmîyanvistene! (İplik karıştırılmasın!/İplik karmakarış edilmesin!)"
  },
  {
    "x": "ameyene cikerdene",
    "y": " koparılmak"
  },
  {
    "x": "ameyene cirakerdene",
    "y": " koparılmak"
  },
  {
    "x": "ameyene cisawitene",
    "y": " -e sürülmek"
  },
  {
    "x": "ameyene cîhan",
    "y": " doğmak (insan için)"
  },
  {
    "x": "ameyene çelqnayene",
    "y": " çalkalanmak"
  },
  {
    "x": "ameyene çeraynayene",
    "y": " otlatılmak"
  },
  {
    "x": "ameyene çerdene",
    "y": " (ekin, ot vb. için) hayvanlarca yenmek"
  },
  {
    "x": "ameyene çewtkerdene",
    "y": " çarpıtılmak"
  },
  {
    "x": "ameyene çiraynayene",
    "y": " b. ameyene çeraynayene"
  },
  {
    "x": "ameyene çînitene",
    "y": " biçilmek"
  },
  {
    "x": "ameyene çîrkerdene",
    "y": " yırtılmak"
  },
  {
    "x": "ameyene çîrrkerdene",
    "y": " b. ameyene çîrkerdene"
  },
  {
    "x": "ameyene çotkerdene",
    "y": " b. ameyene çewtkerdene"
  },
  {
    "x": "ameyene çowtkerdene",
    "y": " b. ameyene çewtkerdene"
  },
  {
    "x": "ameyene çoytkerdene",
    "y": " b. ameyene çewtkerdene"
  },
  {
    "x": "ameyene daliqnayene",
    "y": " asılmak (idam edilmek)"
  },
  {
    "x": "ameyene dardekerdene",
    "y": " asılmak (idam edilmek)"
  },
  {
    "x": "ameyene derakerdene",
    "y": " b. ameyene tirakerdene, ameyene cirakerdene"
  },
  {
    "x": "ameyene desawitene",
    "y": " -e sürülmek"
  },
  {
    "x": "ameyene dest û linganê ...",
    "y": " ayağına kapanmak, ayaklarına kapanmak"
  },
  {
    "x": "ameyene destene",
    "y": " b. ameyene deştene"
  },
  {
    "x": "ameyene deştene",
    "y": " dikilmek"
  },
  {
    "x": "ameyene dewamkerdene",
    "y": " devam edilmek"
  },
  {
    "x": "ameyene dinya",
    "y": " doğmak (insan için)"
  },
  {
    "x": "ameyene dirakerdene",
    "y": " b. ameyene tirakerdene, ameyene cirakerdene"
  },
  {
    "x": "ameyene diskinayene",
    "y": " b. ameyene duskînayene"
  },
  {
    "x": "ameyene disknayene",
    "y": " b. ameyene duskînayene"
  },
  {
    "x": "ameyene disnayene",
    "y": " b. ameyene dusnayene"
  },
  {
    "x": "ameyene dîyene",
    "y": " görülmek"
  },
  {
    "x": "ameyene duskînayene",
    "y": " yapıştırılmak"
  },
  {
    "x": "ameyene dusnayene",
    "y": " yapıştırılmak"
  },
  {
    "x": "ameyene efkerdene",
    "y": " affedilmek, bağışlanmak"
  },
  {
    "x": "ameyene erahewavistene",
    "y": " b. ameyene rahewafîştene"
  },
  {
    "x": "ameyene esrîkerdene",
    "y": " çağdaşlaştırılmak"
  },
  {
    "x": "ameyene eyarkerdene",
    "y": " ayarlanmak"
  },
  {
    "x": "ameyene fehmkerdene",
    "y": " anlaşılmak"
  },
  {
    "x": "ameyene gireynayene",
    "y": " kaynatılmak"
  },
  {
    "x": "ameyene girêdayene",
    "y": " bağlanmak"
  },
  {
    "x": "ameyene girênayene",
    "y": " kaynatılmak"
  },
  {
    "x": "ameyene girînayene",
    "y": " b. ameyene girênayene"
  },
  {
    "x": "ameyene gurenayene",
    "y": " 1)çalıştırılmak\r\n~2)kullanılmak"
  },
  {
    "x": "ameyene hankerdene",
    "y": " özendirilmek, teşvik edilmek"
  },
  {
    "x": "ameyene helênayene",
    "y": " eritilmek"
  },
  {
    "x": "ameyene hemdemkerdene",
    "y": " çağdaşlaştırılmak"
  },
  {
    "x": "ameyene hemetkerdene",
    "y": " korunmak"
  },
  {
    "x": "ameyene heskerdene",
    "y": " sevilmek"
  },
  {
    "x": "ameyene hewadayene",
    "y": " kaldırılmak"
  },
  {
    "x": "ameyene hewafîştene",
    "y": " kaldırılmak"
  },
  {
    "x": "ameyene hewanayene",
    "y": " kaldırılmak"
  },
  {
    "x": "ameyene hewavistene",
    "y": " b. ameyene hewafîştene"
  },
  {
    "x": "ameyene karitene",
    "y": " ekilmek"
  },
  {
    "x": "ameyene kel",
    "y": " b. kel ameyene"
  },
  {
    "x": "ameyene keldayene",
    "y": " b. ameyene keledayene"
  },
  {
    "x": "ameyene keledayene",
    "y": " kaynatılmak"
  },
  {
    "x": "ameyene kelefîştene",
    "y": " kaynatılmak"
  },
  {
    "x": "ameyene kelenayene",
    "y": " kaynatılmak"
  },
  {
    "x": "ameyene kelênayene",
    "y": " b. ameyene kelenayene"
  },
  {
    "x": "ameyene kelinayene",
    "y": " b. ameyene kelenayene"
  },
  {
    "x": "ameyene kelivistene",
    "y": " b. ameyene kelefîştene "
  },
  {
    "x": "ameyene kelnayene",
    "y": " b. ameyene kelenayene"
  },
  {
    "x": "ameyene kemednayîne",
    "y": " b. ameyene kemidnayene"
  },
  {
    "x": "ameyene kemidnayene",
    "y": " haşlanmak"
  },
  {
    "x": "ameyene kendene",
    "y": " kazılmak"
  },
  {
    "x": "ameyene kijkerdene",
    "y": " yakılmak"
  },
  {
    "x": "ameyene kijikekerdene",
    "y": " yakılmak"
  },
  {
    "x": "ameyene kinitene",
    "y": " kazılmak"
  },
  {
    "x": "ameyene kiriştene",
    "y": " taşınmak"
  },
  {
    "x": "ameyene kirrimkerdene",
    "y": " biçilircesine dipten kesilmek veya koparılmak"
  },
  {
    "x": "ameyene kiştene",
    "y": " öldürülmek"
  },
  {
    "x": "ameyene kîştene",
    "y": " b. ameyene kiştene"
  },
  {
    "x": "ameyene kokkerdene",
    "y": " ayarlanmak"
  },
  {
    "x": "ameyene kontrol kerdene",
    "y": " denetlenmek"
  },
  {
    "x": "ameyene lawnayene",
    "y": " emzirilmek"
  },
  {
    "x": "ameyene leqnayene",
    "y": " asılmak (idam edilmek)"
  },
  {
    "x": "ameyene lulkerdene",
    "y": " aşılanmak (bitki için)"
  },
  {
    "x": "ameyene miqatkerdene",
    "y": " korunmak"
  },
  {
    "x": "ameyene modernkerdene",
    "y": " çağdaşlaştırılmak"
  },
  {
    "x": "ameyene morkerdene",
    "y": " mühürlenmek"
  },
  {
    "x": "ameyene mubalexakerdene",
    "y": " abartılmak"
  },
  {
    "x": "ameyene muhafezekerdene",
    "y": " korunmak"
  },
  {
    "x": "ameyene naskerdene",
    "y": " tanınmak"
  },
  {
    "x": "ameyene newekerdene",
    "y": " yenilenmek"
  },
  {
    "x": "ameyene nîyadayene",
    "y": " izlenmek"
  },
  {
    "x": "ameyene nîyazkerdene",
    "y": " adanmak"
  },
  {
    "x": "ameyene orte",
    "y": " oluşmak"
  },
  {
    "x": "ameyene pakkerdene",
    "y": " arınmak, paklanmak, temizlenmek"
  },
  {
    "x": "ameyene panayene",
    "y": " 1)yapıştırılmak\r\n~2)yakılmak"
  },
  {
    "x": "ameyene parzimkerdene",
    "y": " b. ameyene parzunkerdene"
  },
  {
    "x": "ameyene parzumkerdene",
    "y": " b. ameyene parzunkerdene"
  },
  {
    "x": "ameyene parzunkerdene",
    "y": " süzülmek (sıvı için)"
  },
  {
    "x": "ameyene parzûkerdene",
    "y": " b. ameyene parzunkerdene"
  },
  {
    "x": "ameyene parzûnkerdene",
    "y": " b. ameyene parzunkerdene"
  },
  {
    "x": "ameyene pawitene",
    "y": " korunmak"
  },
  {
    "x": "ameyene paykerdene",
    "y": " giyilmek (ayakkabı, pantolon vb. için)"
  },
  {
    "x": "ameyene pernayene",
    "y": " kaçırılmak"
  },
  {
    "x": "ameyene perzunkerdene",
    "y": " b. ameyene parzunkerdene"
  },
  {
    "x": "ameyene perzûnkerdene",
    "y": " b. ameyene parzunkerdene"
  },
  {
    "x": "ameyene pewtene",
    "y": " pişirilmek\r\n~Nan ame pewtene. (Ekmek pişirildi.)"
  },
  {
    "x": "ameyene pêranayene",
    "y": " bitiştirilmek"
  },
  {
    "x": "ameyene pêser",
    "y": " 1)toplanmak\r\n~2)devşirilmek"
  },
  {
    "x": "ameyene pêserardene",
    "y": " devşirilmek"
  },
  {
    "x": "ameyene pêserkerdene",
    "y": " devşirilmek"
  },
  {
    "x": "ameyene pêşanayene",
    "y": " 1)karıştırılmak (birbirine karıştırılmak)\r\n~Xele û cew ameyê pêşanayene. (Buğday ile arpa birbirine karıştırılmış.)\r\n~2)bitiştirilmek"
  },
  {
    "x": "ameyene pêşnayene",
    "y": " pişirilmek"
  },
  {
    "x": "ameyene pêyanayene",
    "y": " bitiştirilmek"
  },
  {
    "x": "ameyene piragirewtene",
    "y": " giyilmek (giysi için)\r\n~Roşan de kincê/cilê neweyî yenê piragirewtene. (Bayramda yeni giysiler giyilir.)"
  },
  {
    "x": "ameyene pirakerdene",
    "y": " -e sürülmek"
  },
  {
    "x": "ameyene pirojinekerdene",
    "y": " kalburlanmak, çalkalanmak, kalburdan geçirilmek"
  },
  {
    "x": "ameyene pînekerdene",
    "y": " yamalanmak, yamanmak"
  },
  {
    "x": "ameyene polkerdene",
    "y": "yamalanmak, yamanmak"
  },
  {
    "x": "ameyene qayîtkerdene",
    "y": " izlenmek, korunmak"
  },
  {
    "x": "ameyene qemetnayene",
    "y": " b. ameyene qemitnayene"
  },
  {
    "x": "ameyene qemitnayene",
    "y": " yapıştırılmak"
  },
  {
    "x": "ameyene qilaynayene",
    "y": " temelinden koparılmak (ağaç, dal vb. için)"
  },
  {
    "x": "ameyene qileynayene",
    "y": " b. ameyene qilaynayene"
  },
  {
    "x": "ameyene qîjkerdene",
    "y": " b. ameyene qîşkerdene"
  },
  {
    "x": "ameyene qîrkerdene",
    "y": " asfaltlanmak"
  },
  {
    "x": "ameyene qîşkerdene",
    "y": " yırtılmak"
  },
  {
    "x": "ameyene qolkerdene",
    "y": " bir kısmı koparılıp bir kısmı bırakılmak"
  },
  {
    "x": "ameyene qorîkerdene",
    "y": " korunmak"
  },
  {
    "x": "ameyene qotkerdene",
    "y": " bir kısmı koparılıp bir kısmı bırakılmak"
  },
  {
    "x": "ameyene qurbankerdene",
    "y": " kurban edilmek"
  },
  {
    "x": "ameyene rahewafîştene",
    "y": " kaldırılmak"
  },
  {
    "x": "ameyene ramitene",
    "y": " 1)sürülmek \r\n~2)ekilmek"
  },
  {
    "x": "ameyene rawuriznayene",
    "y": " kaldırılmak"
  },
  {
    "x": "ameyene remnayene",
    "y": " kaçırılmak"
  },
  {
    "x": "ameyene rengkerdene",
    "y": " boyalanmak, boyanmak"
  },
  {
    "x": "ameyene rengnayene",
    "y": " boyalanmak, boyanmak"
  },
  {
    "x": "ameyene rîsipîkerdene",
    "y": " aklanmak"
  },
  {
    "x": "ameyene rokerdene",
    "y": " dökülmek"
  },
  {
    "x": "ameyene roşnkerdene",
    "y": " aydınlatılmak"
  },
  {
    "x": "ameyene roştkerdene",
    "y": " aydınlatılmak"
  },
  {
    "x": "ameyene rovilêşnayene",
    "y": " eritilmek"
  },
  {
    "x": "ameyene sanekerdene",
    "y": " bilenmek"
  },
  {
    "x": "ameyene sawitene",
    "y": " 1)sürülmek, sürtülmek \r\n~2)bilenmek"
  },
  {
    "x": "ameyene sefênayene",
    "y": " süzülmek (sıvı için)"
  },
  {
    "x": "ameyene seqênayene",
    "y": " bilenmek"
  },
  {
    "x": "ameyene serednayene",
    "y": " kalburlanmak, çalkalanmak, kalburdan geçirilmek"
  },
  {
    "x": "ameyene seveknayene",
    "y": " korunmak"
  },
  {
    "x": "ameyene seyrkerdene",
    "y": " izlenmek"
  },
  {
    "x": "ameyene sinasnayene",
    "y": " b. ameyene şinasnayene"
  },
  {
    "x": "ameyene sînayene",
    "y": " sevilmek"
  },
  {
    "x": "ameyene solekerdene",
    "y": " tuzlanmak "
  },
  {
    "x": "ameyene solkerdene",
    "y": " b. ameyene solekerdene"
  },
  {
    "x": "ameyene sotene",
    "y": " yakılmak"
  },
  {
    "x": "ameyene soynayene",
    "y": " sürülmek, sürtülmek"
  },
  {
    "x": "ameyene sunetkerdene",
    "y": " sünnet edilmek"
  },
  {
    "x": "ameyene sunnetkerdene",
    "y": " b. ameyene sunetkerdene"
  },
  {
    "x": "ameyene şeliqnayene",
    "y": " haşlanmak"
  },
  {
    "x": "ameyene şinasnayene",
    "y": " tanınmak"
  },
  {
    "x": "ameyene şutene",
    "y": " yıkanmak"
  },
  {
    "x": "ameyene şuxulnayene",
    "y": " 1)çalıştırılmak \r\n~2)kullanılmak"
  },
  {
    "x": "ameyene taçarnayene",
    "y": " çalkalanmak"
  },
  {
    "x": "ameyene taqîbkerdene",
    "y": " izlenmek"
  },
  {
    "x": "ameyene tayînkerdene",
    "y": " atanmak"
  },
  {
    "x": "ameyene teftîş kerdene",
    "y": " denetlenmek"
  },
  {
    "x": "ameyene temamkerdene",
    "y": " tamamlanmak"
  },
  {
    "x": "ameyene temaşekerdene",
    "y": " izlenmek"
  },
  {
    "x": "ameyene temeqnayene",
    "y": " b. ameyene qemitnayene"
  },
  {
    "x": "ameyene temiqnayene",
    "y": " b. ameyene qemitnayene"
  },
  {
    "x": "ameyene temîzkerdene",
    "y": " arınmak, paklanmak, temizlenmek"
  },
  {
    "x": "ameyene tenaverdayene",
    "y": " yalnızlaştırılmak"
  },
  {
    "x": "ameyene tenêverdayene",
    "y": " b. ameyene tenaverdayene"
  },
  {
    "x": "ameyene tepîştene",
    "y": " avlanmak"
  },
  {
    "x": "ameyene terakerdene",
    "y": " b. ameyene tirakerdene, ameyene cirakerdene"
  },
  {
    "x": "ameyene teslîmkerdene",
    "y": " teslim edilmek"
  },
  {
    "x": "ameyene teşwîqkerdene",
    "y": " özendirilmek, teşvik edilmek"
  },
  {
    "x": "ameyene texmînkerdene",
    "y": " tahmin edilmek"
  },
  {
    "x": "ameyene têkilkerdene",
    "y": " karıştırılmak (birbirine karıştırılmak)"
  },
  {
    "x": "ameyene tênaverdayene",
    "y": " b. ameyene tenaverdayene"
  },
  {
    "x": "ameyene tiradayene",
    "y": " -e sürülmek"
  },
  {
    "x": "ameyene tirakerdene",
    "y": " koparılmak"
  },
  {
    "x": "ameyene tîtevîtkerdene",
    "y": " b. ameyene tîtûvîtkerdene"
  },
  {
    "x": "ameyene tîtiknayene",
    "y": " süslenmek (başkası tarafından süslenmek"
  },
  {
    "x": "ameyene tîtovîtkerdene",
    "y": " b. ameyene tîtûvîtkerdene"
  },
  {
    "x": "ameyene tîtûvîtkerdene",
    "y": " süslenmek (başkası tarafından süslenmek)"
  },
  {
    "x": "ameyene topkerdene",
    "y": " devşirilmek"
  },
  {
    "x": "ameyene utîkerdene",
    "y": " ütülenmek"
  },
  {
    "x": "ameyene vatene",
    "y": " anlatılmak, söylenmek"
  },
  {
    "x": "ameyene vaydayene",
    "y": " savrulmak"
  },
  {
    "x": "ameyene vaydayeni",
    "y": " b. ameyene vaydayene"
  },
  {
    "x": "ameyene velakerdene",
    "y": " b. ameyene vilakerdene"
  },
  {
    "x": "ameyene velaykerdene",
    "y": " b. ameyene vilakerdene"
  },
  {
    "x": "ameyene veydayeni",
    "y": " b. ameyene vaydayene"
  },
  {
    "x": "ameyene veşnayene",
    "y": " yakılmak"
  },
  {
    "x": "ameyene vetene",
    "y": " çıkarılmak"
  },
  {
    "x": "ameyene veyşnayene",
    "y": " b. ameyene veşnayene"
  },
  {
    "x": "ameyene vêsnayene",
    "y": " b. ameyene veşnayene"
  },
  {
    "x": "ameyene vêşnayene",
    "y": " b. ameyene veşnayene"
  },
  {
    "x": "ameyene vilakerdene",
    "y": " dağıtılmak"
  },
  {
    "x": "ameyene vilêşnayene",
    "y": " eritilmek"
  },
  {
    "x": "ameyene vîjnayene",
    "y": " ayıklanmak"
  },
  {
    "x": "ameyene vînayene",
    "y": " görülmek"
  },
  {
    "x": "ameyene wadkerdene",
    "y": " adanmak"
  },
  {
    "x": "ameyene wadnayene",
    "y": " adanmak"
  },
  {
    "x": "amayene wadnayêne",
    "y": " b. ameyene wadnayene"
  },
  {
    "x": "ameyene wahdkerdene",
    "y": " b. ameyene wadkerdene"
  },
  {
    "x": "ameyene wahdnayene",
    "y": " b. ameyene wadnayene"
  },
  {
    "x": "ameyene weçînayene",
    "y": " ayıklanmak"
  },
  {
    "x": "ameyene weçînitene",
    "y": " ayıklanmak"
  },
  {
    "x": "ameyene wedardayene",
    "y": " kaldırılmak"
  },
  {
    "x": "ameyene wedaritene",
    "y": " b. wedarîyayene"
  },
  {
    "x": "ameyene wedarnayene",
    "y": " kaldırılmak"
  },
  {
    "x": "ameyene wedayene",
    "y": " kaldırılmak"
  },
  {
    "x": "ameyene wedkerdene",
    "y": " b. ameyene wadkerdene"
  },
  {
    "x": "ameyene wehdkerdene",
    "y": " b. ameyene wadkerdene"
  },
  {
    "x": "ameyene wehdnayene",
    "y": " b. ameyene wadnayene"
  },
  {
    "x": "ameyene wendene",
    "y": " okunmak"
  },
  {
    "x": "ameyene werdene",
    "y": " yenilmek (yiyecek vb. için), yenmek (yiyecek vb. için)"
  },
  {
    "x": "ameyene wereznayene",
    "y": " b. ameyene weriznayene"
  },
  {
    "x": "ameyene weriznayene",
    "y": " kaldırılmak"
  },
  {
    "x": "ameyene xaşênayene",
    "y": " haşlanmak"
  },
  {
    "x": "ameyene xebitnayene",
    "y": " 1)çalıştırılmak \r\n~2)kullanılmak"
  },
  {
    "x": "ameyene xemilnayene",
    "y": " süslenmek (başkası tarafından süslenmek)"
  },
  {
    "x": "ameyene xeniqnayene",
    "y": " asılmak (idam edilmek)"
  },
  {
    "x": "ameyene xesênayene",
    "y": " hadımlaştırılmak, enenmek, iğdiş edilmek\r\n~Vîştira ameyo xesênayene. (Tosun hadımlaştırılmış.)"
  },
  {
    "x": "ameyene xêzekerdene",
    "y": " çizilmek"
  },
  {
    "x": "ameyene yewca",
    "y": " 1)toplanmak \r\n~2)devşirilmek"
  },
  {
    "x": "ameyene zeleqnayene",
    "y": " b. ameyene zeliqnayene"
  },
  {
    "x": "ameyene zeliqnayene",
    "y": " yapıştırılmak"
  },
  {
    "x": "ameyîne",
    "y": " b. ameyene"
  },
  {
    "x": "ameyîne alaqnayene",
    "y": " b. ameyene aliqnayene"
  },
  {
    "x": "ameyîne aleqnayene",
    "y": " b. ameyene aliqnayene"
  },
  {
    "x": "ameyîne aliznayene",
    "y": " b. ameyene aloznayene"
  },
  {
    "x": "ameyîne cîhan",
    "y": " b. ameyene cîhan"
  },
  {
    "x": "ameyîne çilqnayene",
    "y": " b. ameyene çelqnayene"
  },
  {
    "x": "ameyîne çîrkerdene",
    "y": " b. ameyene çîrkerdene"
  },
  {
    "x": "ameyîne daleqnayene",
    "y": " b. ameyene daliqnayene"
  },
  {
    "x": "ameyîne dardikerdene",
    "y": " b. ameyene dardekerdene"
  },
  {
    "x": "ameyîne dêştene",
    "y": " b. ameyene deştene"
  },
  {
    "x": "ameyîne dinya",
    "y": " b. ameyene dinya"
  },
  {
    "x": "ameyîne dîyayene",
    "y": " b. ameyene dîyene"
  },
  {
    "x": "ameyîne dîyayêne",
    "y": " b. ameyene dîyene"
  },
  {
    "x": "ameyîne dîyene",
    "y": " b. ameyene dîyene"
  },
  {
    "x": "ameyîne eleqnayene",
    "y": " b. ameyene aliqnayene"
  },
  {
    "x": "ameyîne famkerdeni",
    "y": " b. ameyene fehmkerdene"
  },
  {
    "x": "ameyîne fêmkerdene",
    "y": " b. ameyene fehmkerdene"
  },
  {
    "x": "ameyîne heskerdeni",
    "y": " b. ameyene heskerdene"
  },
  {
    "x": "ameyîne kariteni",
    "y": " b. ameyene karitene"
  },
  {
    "x": "ameyîne kijikkerdene",
    "y": " b. ameyene kijikekerdene"
  },
  {
    "x": "ameyîne kijikikerdene",
    "y": " b. ameyene kijikekerdene"
  },
  {
    "x": "ameyîne muherkerdene",
    "y": " b. ameyene morkerdene"
  },
  {
    "x": "ameyîne muhirkerdene",
    "y": " b. ameyene morkerdene"
  },
  {
    "x": "ameyîne peyşnayene",
    "y": " b. ameyene pêşnayene"
  },
  {
    "x": "ameyîne pêser",
    "y": " b. ameyene pêser"
  },
  {
    "x": "ameyîne pîser",
    "y": " b. ameyene pêser"
  },
  {
    "x": "ameyîne pîyeser",
    "y": " b. ameyene pêser"
  },
  {
    "x": "ameyîne rûkerdene",
    "y": " b. ameyene rokerdene"
  },
  {
    "x": "ameyîne sawutene",
    "y": " b. ameyene sawitene"
  },
  {
    "x": "ameyîne seridnayene",
    "y": " b. ameyene serednayene"
  },
  {
    "x": "ameyîne serrednayene",
    "y": " b. ameyene serednayene"
  },
  {
    "x": "ameyîne serridnayene",
    "y": " b. ameyene serednayene"
  },
  {
    "x": "ameyîne sexênayîne",
    "y": " b. ameyene xesênayene"
  },
  {
    "x": "ameyîne sexînayîne",
    "y": " b. ameyene xesênayene"
  },
  {
    "x": "ameyîne sinaşnayîne",
    "y": " b. ameyene şinasnayene"
  },
  {
    "x": "ameyîne sinetkerdene",
    "y": " b. ameyene sunetkerdene"
  },
  {
    "x": "ameyîne sotene",
    "y": " b. ameyene sotene"
  },
  {
    "x": "ameyîne sualkerdene",
    "y": " b. ameyene solekerdene"
  },
  {
    "x": "ameyîne suelkerdene",
    "y": " b. ameyene solekerdene"
  },
  {
    "x": "ameyîne sunnetkerdene",
    "y": " b. ameyene sunetkerdene"
  },
  {
    "x": "ameyîne suwel kerdene",
    "y": " b. ameyene solekerdene"
  },
  {
    "x": "ameyîne şitene",
    "y": " b. ameyene şutene"
  },
  {
    "x": "ameyîne taçanayene",
    "y": " b. ameyene taçarnayene"
  },
  {
    "x": "ameyîne vatene",
    "y": " b. ameyene vatene"
  },
  {
    "x": "ameyîne vateni",
    "y": " b. ameyene vatene"
  },
  {
    "x": "ameyîne vênayene",
    "y": " b. ameyene vînayene"
  },
  {
    "x": "ameyîne vêsnayene",
    "y": " b. ameyene veşnayene"
  },
  {
    "x": "ameyîne vêsnayêne",
    "y": " b. ameyene veşnayene"
  },
  {
    "x": "ameyîne vêşnayene",
    "y": " b. ameyene veşnayene"
  },
  {
    "x": "ameyîne vêşnayêne",
    "y": " b. ameyene veşnayene"
  },
  {
    "x": "ameyîne vînayene",
    "y": " b. ameyene vînayene"
  },
  {
    "x": "ameyîne vînayîne",
    "y": " b. ameyene vînayene"
  },
  {
    "x": "ameyîne vînitene",
    "y": " b. ameyene vînayene"
  },
  {
    "x": "ameyîne watene",
    "y": " b. ameyene vatene"
  },
  {
    "x": "ameyîne wendene",
    "y": " b. ameyene wendene"
  },
  {
    "x": "ameyîne werte",
    "y": " b. ameyene orte"
  },
  {
    "x": "... ameyîne",
    "y": " b. ... ameyene"
  },
  {
    "x": "ameyîs (n)",
    "y": " b. ameyîş (n)"
  },
  {
    "x": "... ameyîs",
    "y": " b. ... ameyîş"
  },
  {
    "x": "ameyîsaxmekerdis (n)",
    "y": " b. ameyîşaxmekerdiş (n)"
  },
  {
    "x": "ameyîsçîrkerdis (n)",
    "y": " b. ameyîşçîrkerdiş (n)"
  },
  {
    "x": "ameyîsqîşkerdis (n)",
    "y": " b. ameyîşqîşkerdiş (n)"
  },
  {
    "x": "ameyîş (n)",
    "y": " 1)gelme \r\n~2)bitme (bitki vb. İçin) \r\n~3)akma (çeşme, musluk vb. için)"
  },
  {
    "x": "... ameyîş (n)",
    "y": " yakışma"
  },
  {
    "x": "ameyîşalaqnayîş (n)",
    "y": " b. ameyîşaliqnayîş (n)"
  },
  {
    "x": "ameyîşaleqnayîş (n)",
    "y": " b. ameyîşaliqnayîş (n)"
  },
  {
    "x": "ameyîşaliqnayîş (n)",
    "y": " asılma (idam edilme)"
  },
  {
    "x": "ameyîşaliznayîş (n)",
    "y": " b. ameyîşaloznayîş (n)"
  },
  {
    "x": "ameyîşaloznayîş (n)",
    "y": " karıştırılma (birbirine dolandırılma)"
  },
  {
    "x": "ameyîşarêdayîş (n)",
    "y": " devşirilme"
  },
  {
    "x": "ameyîşarêkerdiş (n)",
    "y": " devşirilme"
  },
  {
    "x": "ameyîşarşîvkerdiş (n)",
    "y": " arşivlenme"
  },
  {
    "x": "ameyîşasfaltkerdiş (n)",
    "y": " asfaltlanma"
  },
  {
    "x": "ameyîşawedayîş (n)",
    "y": " sulanma"
  },
  {
    "x": "ameyîşaxmekerdiş (n)",
    "y": " dağıtılma"
  },
  {
    "x": "ameyîşbadiroskerdiş (n)",
    "y": " aşılanma (bitki için)"
  },
  {
    "x": "ameyîşberan",
    "y": " b. beranameyîş (n)"
  },
  {
    "x": "ameyîşbarzkardiş (n)",
    "y": " b. ameyîşberzkerdiş (n)"
  },
  {
    "x": "ameyîşberdiş (n)",
    "y": " kaçırılma"
  },
  {
    "x": "ameyîşberzkerdiş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "ameyîşbestiş (n)",
    "y": " bağlanma"
  },
  {
    "x": "ameyîşbeşkerdiş (n)",
    "y": " bölümlenme, sınıflanma"
  },
  {
    "x": "ameyîşbexişkerdiş (n)",
    "y": " affedilme, bağışlanma"
  },
  {
    "x": "ameyîşbexişnayîş (n)",
    "y": " affedilme, bağışlanma"
  },
  {
    "x": "ameyîşboyaxkerdiş (n)",
    "y": " boyalanma, boyanma"
  },
  {
    "x": "ameyîşcaardiş (n)",
    "y": " gerçekleştirilme"
  },
  {
    "x": "ameyîşcêmîyanvistiş (n)",
    "y": " karıştırılma (birbirine dolandırılma)"
  },
  {
    "x": "ameyîşcikerdiş (n)",
    "y": " koparılma"
  },
  {
    "x": "ameyîşcirakerdiş (n)",
    "y": " koparılma"
  },
  {
    "x": "ameyîşçelqnayîş (n)",
    "y": " çalkalanma"
  },
  {
    "x": "ameyîşçeraynayîş (n)",
    "y": " otlatılma"
  },
  {
    "x": "ameyîşçewtkerdiş (n)",
    "y": " çarpıtılma"
  },
  {
    "x": "ameyîşçilqnayîş (n)",
    "y": " b. ameyîşçelqnayîş (n)"
  },
  {
    "x": "ameyîşçiraynayîş (n)",
    "y": " b. ameyîşçeraynayîş (n)"
  },
  {
    "x": "ameyîşçîrkerdiş (n)",
    "y": " yırtılma"
  },
  {
    "x": "ameyîşçîrrkerdiş (n)",
    "y": " b. ameyîşçîrkerdiş (n)"
  },
  {
    "x": "ameyîşçotkerdiş (n)",
    "y": " b. ameyîşçewtkerdiş (n)"
  },
  {
    "x": "ameyîşçowtkerdiş (n)",
    "y": " b. ameyîşçewtkerdiş (n)"
  },
  {
    "x": "ameyîşçoytkerdiş (n)",
    "y": " b. ameyîşçewtkerdiş (n)"
  },
  {
    "x": "ameyîşdaleqnayîş (n)",
    "y": " b. ameyîşdaliqnayîş (n)"
  },
  {
    "x": "ameyîşdaliqnayîş (n)",
    "y": " asılma (idam edilme)"
  },
  {
    "x": "ameyîşdardekerdiş (n)",
    "y": " asılma (idam edilme)"
  },
  {
    "x": "ameyîşdardikerdiş (n)",
    "y": " b. ameyîşdardekerdiş (n)"
  },
  {
    "x": "ameyîşderakerdiş (n)",
    "y": " b. ameyîştirakerdiş (n), ameyîşcirakerdiş (n)"
  },
  {
    "x": "ameyîşdeştiş (n)",
    "y": " dikilme"
  },
  {
    "x": "ameyîşdirakerdiş (n)",
    "y": " b. ameyîştirakerdiş (n), ameyîşcirakerdiş (n)"
  },
  {
    "x": "ameyîşdiskinayîş (n)",
    "y": " b. ameyîşduskînayîş"
  },
  {
    "x": "ameyîşdisknayîş (n)",
    "y": " b. ameyîşduskînayîş"
  },
  {
    "x": "ameyîşdisnayîş (n)",
    "y": " b. ameyîşdusnayîş (n)"
  },
  {
    "x": "ameyîşdîyayîş (n)",
    "y": " bulunma "
  },
  {
    "x": "ameyîşduskînayîş (n)",
    "y": " yapıştırılma"
  },
  {
    "x": "ameyîşdusnayîş (n)",
    "y": " yapıştırılma"
  },
  {
    "x": "ameyîşefkerdiş (n)",
    "y": " affedilme, bağışlanma"
  },
  {
    "x": "ameyîşeleqnayîş (n)",
    "y": " b. ameyîşaliqnayîş (n)"
  },
  {
    "x": "ameyîşerahewavistiş (n)",
    "y": " b. ameyîşrahewafîştiş (n)"
  },
  {
    "x": "ameyîşeyarkerdiş (n)",
    "y": " ayarlanma"
  },
  {
    "x": "ameyîşfamkerdiş (n)",
    "y": " b. ameyîşfehmkerdiş (n)"
  },
  {
    "x": "ameyîşfehmkerdiş (n)",
    "y": " anlaşılma"
  },
  {
    "x": "ameyîşfêmkerdiş (n)",
    "y": " b. ameyîşfehmkerdiş (n)"
  },
  {
    "x": "ameyîşgireynayîş (n)",
    "y": " b. ameyîşgirênayîş (n)"
  },
  {
    "x": "ameyîşgirêdayîş (n)",
    "y": " bağlanma"
  },
  {
    "x": "ameyîşgirênayîş (n)",
    "y": " kaynatılma"
  },
  {
    "x": "ameyîşgirînayîş (n)",
    "y": " b. ameyîşgirênayîş (n)"
  },
  {
    "x": "ameyîşgurenayîş (n)",
    "y": " 1)çalıştırılma \r\n~2)kullanılma, kullanım"
  },
  {
    "x": "ameyîşhankerdiş (n)",
    "y": " özendirilme, teşvik edilme"
  },
  {
    "x": "ameyîşhelênayîş (n)",
    "y": " eritilme"
  },
  {
    "x": "ameyîşhemetkerdiş (n)",
    "y": " korunma"
  },
  {
    "x": "ameyîşheskerdiş (n)",
    "y": " eritilme"
  },
  {
    "x": "ameyîşhewadayîş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "ameyîşhewafîştiş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "ameyîşhewanayîş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "ameyîşhewavistiş (n)",
    "y": " b. ameyîşhewafîştiş (n)"
  },
  {
    "x": "ameyîşkel",
    "y": " b. kelameyîş (n)"
  },
  {
    "x": "ameyîşkeldayîş (n)",
    "y": " b. ameyîşkeledayîş (n)"
  },
  {
    "x": "ameyîşkeledayîş (n)",
    "y": " kaynatılma"
  },
  {
    "x": "ameyîşkelefîştiş (n)",
    "y": " kaynatılma"
  },
  {
    "x": "ameyîşkelenayîş (n)",
    "y": " kaynatılma"
  },
  {
    "x": "ameyîşkelênayîş (n)",
    "y": " b. ameyîşkelenayîş (n)"
  },
  {
    "x": "ameyîşkelinayîş (n)",
    "y": " b. ameyîşkelenayîş (n)"
  },
  {
    "x": "ameyîşkelivistiş (n)",
    "y": " b. ameyîşkelefîştiş (n) "
  },
  {
    "x": "ameyîşkelnayîş (n)",
    "y": " b. ameyîşkelenayîş (n)"
  },
  {
    "x": "ameyîşkemednayîş (n)",
    "y": " b. ameyîşkemidnayîş (n)"
  },
  {
    "x": "ameyîşkemidnayîş (n)",
    "y": " haşlanma"
  },
  {
    "x": "ameyîşkendiş (n)",
    "y": " kazılma"
  },
  {
    "x": "ameyîşkijikekerdiş (n)",
    "y": " yakılma"
  },
  {
    "x": "ameyîşkijkerdiş (n)",
    "y": " yakılma"
  },
  {
    "x": "ameyîşkinitiş (n)",
    "y": " kazılma"
  },
  {
    "x": "ameyîşkiriştiş (n)",
    "y": " taşınma"
  },
  {
    "x": "ameyîşkirrimkerdiş (n)",
    "y": " biçilircesine dipten kesilme veya koparılma"
  },
  {
    "x": "ameyîşkiştiş (n)",
    "y": " öldürülme"
  },
  {
    "x": "ameyîşkîştiş (n)",
    "y": " b. ameyîşkiştiş (n)"
  },
  {
    "x": "ameyîşkokkerdiş (n)",
    "y": " ayarlanma"
  },
  {
    "x": "ameyîşkontrolkerdiş (n)",
    "y": " denetlenme"
  },
  {
    "x": "ameyîşlawnayîş (n)",
    "y": " emzirilme"
  },
  {
    "x": "ameyîşleqnayîş (n)",
    "y": " asılma (idam edilme)"
  },
  {
    "x": "ameyîşlulkerdiş (n)",
    "y": " aşılanma (bitki için)"
  },
  {
    "x": "ameyîşmiqatkerdiş (n)",
    "y": " korunma"
  },
  {
    "x": "ameyîşmubalexakerdiş (n)",
    "y": " abartılma"
  },
  {
    "x": "ameyîşmuhafezekerdiş (n)",
    "y": " korunma"
  },
  {
    "x": "ameyîşnaskerdiş (n)",
    "y": " tanınma"
  },
  {
    "x": "ameyîşnewekerdiş (n)",
    "y": " yenilenme"
  },
  {
    "x": "ameyîşnêçîrkerdiş (n)",
    "y": " avlanma"
  },
  {
    "x": "ameyîşnîyazbîyayîş (n)",
    "y": " adanma"
  },
  {
    "x": "ameyîşpanayîş (n)",
    "y": " 1)yapıştırılma \r\n~2)yakılma"
  },
  {
    "x": "ameyîşparzimkerdiş (n)",
    "y": " b. ameyîşparzunkerdiş (n)"
  },
  {
    "x": "ameyîşparzumkerdiş (n)",
    "y": " b. ameyîşparzunkerdiş (n)"
  },
  {
    "x": "ameyîşparzunkerdiş (n)",
    "y": " süzülme (sıvı için)"
  },
  {
    "x": "ameyîşparzûkerdiş (n)",
    "y": " b. ameyîşparzunkerdiş (n)"
  },
  {
    "x": "ameyîşparzûnkerdiş (n)",
    "y": " b. ameyîşparzunkerdiş (n)"
  },
  {
    "x": "ameyîşpaykerdiş (n)",
    "y": " giyilme (ayakkabı, pantolon vb. için)"
  },
  {
    "x": "ameyîşpawitiş (n)",
    "y": " korunma"
  },
  {
    "x": "ameyîşpernayîş (n)",
    "y": " kaçırılma"
  },
  {
    "x": "ameyîşperzunkerdiş (n)",
    "y": " b. ameyîşparzunkerdiş (n)"
  },
  {
    "x": "ameyîşperzûnkerdiş (n)",
    "y": " b. ameyîşparzunkerdiş (n)"
  },
  {
    "x": "ameyîşpewtiş (n)",
    "y": " pişirilme"
  },
  {
    "x": "ameyîşpeyşnayîş (n)",
    "y": " b. ameyîşpêşnayîş (n)"
  },
  {
    "x": "ameyîşpêranayîş (n)",
    "y": " bitiştirilme"
  },
  {
    "x": "ameyîşpêser (n)",
    "y": " 1)toplanma \r\n~2)devşirilme"
  },
  {
    "x": "ameyîşpêserardiş (n)",
    "y": " devşirilme"
  },
  {
    "x": "ameyîşpêserkerdiş (n)",
    "y": " devşirilme"
  },
  {
    "x": "ameyîşpêşanayîş (n)",
    "y": " 1)karıştırılma (birbirine karıştırılma) \r\n~2)bitiştirilme"
  },
  {
    "x": "ameyîşpêşnayîş (n)",
    "y": " pişirilme (çorba vb. için)"
  },
  {
    "x": "ameyîşpêyanayîş (n)",
    "y": " bitiştirilme"
  },
  {
    "x": "ameyîşpiragirewtiş (n)",
    "y": " giyilme (giysi için)"
  },
  {
    "x": "ameyîşpînekerdiş (n)",
    "y": " yamalanma"
  },
  {
    "x": "ameyîşpîser (n)",
    "y": " b. ameyîşpêser (n)"
  },
  {
    "x": "ameyîşpîyeser (n)",
    "y": " b. ameyîşpêser (n)"
  },
  {
    "x": "ameyîşpolkerdiş n)",
    "y": " yamalanma"
  },
  {
    "x": "ameyîşqayîtkerdiş (n)",
    "y": " korunma"
  },
  {
    "x": "ameyîşqemetnayîş (n)",
    "y": " b. ameyîşqemitnayîş"
  },
  {
    "x": "ameyîşqemitnayîş (n)",
    "y": " yapıştırılma"
  },
  {
    "x": "ameyîşqilaynayîş (n)",
    "y": " temelinden koparılma (ağaç, dal vb. için)"
  },
  {
    "x": "ameyîşqileynayîş (n)",
    "y": " b. ameyîşqilaynayîş (n)"
  },
  {
    "x": "ameyîşqîrkerdiş (n)",
    "y": " asfaltlanma"
  },
  {
    "x": "ameyîşqîşkerdiş (n)",
    "y": " yırtılma"
  },
  {
    "x": "ameyîşqolkerdiş (n)",
    "y": " bir kısmı koparılıp bir kısmı bırakılma"
  },
  {
    "x": "ameyîşqorîkerdiş (n)",
    "y": " korunma"
  },
  {
    "x": "ameyîşqotkerdiş (n)",
    "y": " bir kısmı koparılıp bir kısmı bırakılma"
  },
  {
    "x": "ameyîşquetkerdiş (n)",
    "y": " b. ameyîşqotkerdiş (n)"
  },
  {
    "x": "ameyîşrahewafîştiş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "ameyîşrawuriznayîş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "ameyîşremnayîş (n)",
    "y": " kaçırılma"
  },
  {
    "x": "ameyîşrengkerdiş (n)",
    "y": " boyalanma, boyanma"
  },
  {
    "x": "ameyîşrengnayîş (n)",
    "y": " boyalanma, boyanma"
  },
  {
    "x": "ameyîşrîsipîkerdiş (n)",
    "y": " aklanma"
  },
  {
    "x": "ameyîşrokerdiş (n)",
    "y": " dökülme"
  },
  {
    "x": "ameyîşroşnkerdiş (n)",
    "y": " aydınlatılma"
  },
  {
    "x": "ameyîşroştkerdiş (n)",
    "y": " aydınlatılma"
  },
  {
    "x": "ameyîşrovilêşnayîş (n)",
    "y": " eritilme"
  },
  {
    "x": "ameyîşruekerdiş (n)",
    "y": " b. ameyîşrokerdiş (n)"
  },
  {
    "x": "ameyîşrûkerdiş (n)",
    "y": " b. ameyîşrokerdiş (n) "
  },
  {
    "x": "ameyîşsefênayîş (n)",
    "y": " süzülme (sıvı için)"
  },
  {
    "x": "ameyîşsernayîş (n)",
    "y": " pişirilme (çorba vb. için)"
  },
  {
    "x": "ameyîşseveknayîş (n)",
    "y": " korunma"
  },
  {
    "x": "ameyîşsexênayîş (n)",
    "y": " b. ameyîşxesênayîş (n)"
  },
  {
    "x": "ameyîşsexînayîş (n)",
    "y": " b. ameyîşxesênayîş (n)"
  },
  {
    "x": "ameyîşseydkerdiş (n)",
    "y": " avlanma"
  },
  {
    "x": "ameyîşsinasnayîş (n)",
    "y": " b. ameyîşşinasnayîş (n)"
  },
  {
    "x": "ameyîşsinaşnayîş (n)",
    "y": " b. ameyîşşinasnayîş (n)"
  },
  {
    "x": "ameyîşsînayîş (n)",
    "y": " sevilme"
  },
  {
    "x": "ameyîşsolekerdiş (n)",
    "y": " tuzlanma"
  },
  {
    "x": "ameyîşsolkerdiş (n)",
    "y": " b. ameyîşsolekerdiş (n)"
  },
  {
    "x": "ameyîşsotiş (n)",
    "y": " yakılma"
  },
  {
    "x": "ameyîşsotişê lince (n)",
    "y": " çamurun yakılması"
  },
  {
    "x": "ameyîşsualkerdiş (n)",
    "y": " b. ameyîşsolekerdiş (n)"
  },
  {
    "x": "ameyîşsuelkerdiş (n)",
    "y": " b. ameyîşsolekerdiş (n)"
  },
  {
    "x": "ameyîşsuwelkerdiş (n)",
    "y": " b. ameyîşsolekerdiş (n)"
  },
  {
    "x": "ameyîşşeliqnayîş (n)",
    "y": " haşlanma"
  },
  {
    "x": "ameyîşşinasnayîş (n)",
    "y": " tanınma"
  },
  {
    "x": "ameyîşşutiş (n)",
    "y": " yıkanma"
  },
  {
    "x": "ameyîşşuxulnayîş (n)",
    "y": " 1)çalıştırılma \r\n~2)kullanılma, kullanım"
  },
  {
    "x": "ameyîştaçanayîş (n)",
    "y": " b. ameyîştaçarnayîş (n)"
  },
  {
    "x": "ameyîştaçarnayîş (n)",
    "y": " çalkalanma"
  },
  {
    "x": "ameyîşteftîşkerdiş (n)",
    "y": " denetlenme"
  },
  {
    "x": "ameyîştemamkerdiş (n)",
    "y": " tamamlanma"
  },
  {
    "x": "ameyîştemeqnayîş (n)",
    "y": " b. ameyîşqemitnayîş"
  },
  {
    "x": "ameyîştemiqnayîş (n)",
    "y": " b. ameyîşqemitnayîş"
  },
  {
    "x": "ameyîştepîştiş (n)",
    "y": " avlanma"
  },
  {
    "x": "ameyîşterakerdiş (n)",
    "y": " b. ameyîştirakerdiş (n), ameyîşcirakerdiş (n)"
  },
  {
    "x": "ameyîştesnîfkerdiş (n)",
    "y": " bölümlenme, sınıflanma"
  },
  {
    "x": "ameyîşteşwîqkerdiş (n)",
    "y": " özendirilme, teşvik edilme"
  },
  {
    "x": "ameyîştexmînkerdiş (n)",
    "y": " tahmin edilme"
  },
  {
    "x": "ameyîştêkilkerdiş (n)",
    "y": " karıştırılma (birbirine karıştırılma)"
  },
  {
    "x": "ameyîştirakerdiş (n)",
    "y": " koparılma"
  },
  {
    "x": "ameyîştîtevîtkerdiş (n)",
    "y": " b. ameyîştîtûvîtkerdiş (n)"
  },
  {
    "x": "ameyîştîtiknayîş (n)",
    "y": " süslenme (başkası tarafından süslenme)"
  },
  {
    "x": "ameyîştîtovîtkerdiş (n)",
    "y": " b. ameyîştîtûvîtkerdiş (n)"
  },
  {
    "x": "ameyîştîtûvîtkerdiş (n)",
    "y": " süslenme (başkası tarafından süslenme)"
  },
  {
    "x": "ameyîştopkerdiş (n)",
    "y": " devşirilme"
  },
  {
    "x": "ameyîşvatiş (n)",
    "y": " anlatılma, söylenme"
  },
  {
    "x": "ameyîşvelakerdiş (n)",
    "y": " b. ameyîşvilakerdiş (n)"
  },
  {
    "x": "ameyîşvelaykerdiş (n)",
    "y": " b. ameyîşvilakerdiş (n)"
  },
  {
    "x": "ameyîşveşnayîş (n)",
    "y": " yakılma"
  },
  {
    "x": "ameyîşveşnayîşê lince (n)",
    "y": " çamurun yakılması"
  },
  {
    "x": "ameyîşveyşnayîş (n)",
    "y": " b. ameyîşveşnayîş (n)"
  },
  {
    "x": "ameyîşvêsnayîş (n)",
    "y": " b. ameyîşveşnayîş (n)"
  },
  {
    "x": "ameyîşvêşnayîş (n)",
    "y": " b. ameyîşveşnayîş (n)"
  },
  {
    "x": "ameyîşvilakerdiş (n)",
    "y": " dağıtılma"
  },
  {
    "x": "ameyîşvilêşnayîş (n)",
    "y": " eritilme"
  },
  {
    "x": "ameyîşvîjnayîş (n)",
    "y": " ayıklanma"
  },
  {
    "x": "ameyîşwadkerdiş (n)",
    "y": " adanma"
  },
  {
    "x": "ameyîşwadnayîş (n)",
    "y": " adanma"
  },
  {
    "x": "ameyîşwahdkerdiş (n)",
    "y": " b. ameyîşwadkerdiş (n)"
  },
  {
    "x": "ameyîşwahdnayîş (n)",
    "y": " b. ameyîşwadnayîş (n)"
  },
  {
    "x": "ameyîşwar",
    "y": " b. warameyîş (n)"
  },
  {
    "x": "ameyîşwatiş (n)",
    "y": " b. ameyîşvatiş (n)"
  },
  {
    "x": "ameyîşweçînayîş (n)",
    "y": " ayıklanma"
  },
  {
    "x": "ameyîşweçînitiş (n)",
    "y": " ayıklanma"
  },
  {
    "x": "ameyîşwedardayîş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "ameyîşwedaritiş (n)",
    "y": " b. wedarîyayîş (n)"
  },
  {
    "x": "ameyîşwedarnayîş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "ameyîşwedayîş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "ameyîşwedkerdiş (n)",
    "y": " b. ameyîşwadkerdiş (n)"
  },
  {
    "x": "ameyîşwehdkerdiş (n)",
    "y": " b. ameyîşwadkerdiş (n)"
  },
  {
    "x": "ameyîşwehdnayîş (n)",
    "y": " b. ameyîşwadnayîş (n)"
  },
  {
    "x": "ameyîşwendiş (n)",
    "y": " okunma"
  },
  {
    "x": "ameyîşwerdiş (n)",
    "y": " yenilme(yiyecek vb. için), yenme (yiyecek vb. için)"
  },
  {
    "x": "ameyîşwereznayîş (n)",
    "y": " b. ameyîşweriznayîş"
  },
  {
    "x": "ameyîşweriznayîş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "ameyîşxaşênayîş (n)",
    "y": " haşlanma"
  },
  {
    "x": "ameyîşxebitnayîş (n)",
    "y": " 1)çalıştırılma \r\n~2)kullanılma, kullanım"
  },
  {
    "x": "ameyîşxeletnayîş (n)",
    "y": " çarpıtılma (mecazî)"
  },
  {
    "x": "ameyîşxemilnayîş (n)",
    "y": " süslenme (başkası tarafından süslenme)"
  },
  {
    "x": "ameyîşxeneqnayîş (n)",
    "y": " b. ameyîşxeniqnayîş (n)"
  },
  {
    "x": "ameyîşxeniqnayîş (n)",
    "y": " asılma (idam edilme)"
  },
  {
    "x": "ameyîşxesênayîş (n)",
    "y": " hadımlaştırılma, enenme, iğdiş edilme"
  },
  {
    "x": "ameyîşxêzekerdiş (n)",
    "y": " çizilme, çiziliş"
  },
  {
    "x": "ameyîşyewca (n)",
    "y": " 1)toplanma \r\n~2)devşirilme"
  },
  {
    "x": "ameyîşzeleqnayîş (n)",
    "y": " b. ameyîşzeliqnayîş (n)"
  },
  {
    "x": "ameyîşzeliqnayîş (n)",
    "y": " yapıştırılma"
  },
  {
    "x": "ameyox, -e",
    "y": " gelecek, istikbal"
  },
  {
    "x": "amê (n)",
    "y": " b. amên (n)"
  },
  {
    "x": "amên (n)",
    "y": " maya (süt için)"
  },
  {
    "x": "amên bîyene",
    "y": " mayalanmak(süt için)"
  },
  {
    "x": "amên kerdene",
    "y": " mayalamak(süt için)"
  },
  {
    "x": "amênbîyayîş (n)",
    "y": " mayalanma (süt için)"
  },
  {
    "x": "amênkerde (n)",
    "y": " mayalı(süt için)"
  },
  {
    "x": "amênkerdiş (n)",
    "y": " mayalama(süt için)"
  },
  {
    "x": "amfî (m)",
    "y": " amfi"
  },
  {
    "x": "amfîteatr (n)",
    "y": " amfiteatr"
  },
  {
    "x": "amike (m)",
    "y": " b. emike (m)"
  },
  {
    "x": "amin (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "aminan (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "amir, -e-I",
    "y": " amir\r\n~amirê/a asayîşî: emniyet amiri"
  },
  {
    "x": "amirê/a asayîşî",
    "y": " emniyet amiri"
  },
  {
    "x": "amirê/a emnîyetî",
    "y": " emniyet amiri"
  },
  {
    "x": "amirê/a îdareyî",
    "y": " idare amiri"
  },
  {
    "x": "amirê/a îtfaîya",
    "y": " itfaiye amiri"
  },
  {
    "x": "amir-II (n)",
    "y": " b. emr-I (n)"
  },
  {
    "x": "amirîye (m)",
    "y": " amirlik\r\n~amirîya buroyê teknîkî (m): teknik büro amirliği"
  },
  {
    "x": "amirîya buroyê teknîkî (m)",
    "y": " teknik büro amirliği"
  },
  {
    "x": "amirîya garajî",
    "y": " garaj amirliği"
  },
  {
    "x": "amirîya îtfaîya",
    "y": " itfaiye amirliği"
  },
  {
    "x": "amirîya karê nuşteyan (m)",
    "y": " yazı işleri amirliği"
  },
  {
    "x": "amirîya perwerdeyî (m)",
    "y": " eğitim amirliği"
  },
  {
    "x": "amirîya zabita",
    "y": " zabıta amirliği"
  },
  {
    "x": "amirîye (m)",
    "y": " amirlik"
  },
  {
    "x": "amiş (n)",
    "y": " b. ûmiş (n)"
  },
  {
    "x": "amîgo (n)",
    "y": " amigo"
  },
  {
    "x": "amîgoyîye (m)",
    "y": " amigoluk"
  },
  {
    "x": "amîn-I (n)",
    "y": " b. amên (n)"
  },
  {
    "x": "amîn bîyayene",
    "y": " b. amên bîyene"
  },
  {
    "x": "amîn kerdene",
    "y": " b. amên kerdene"
  },
  {
    "x": "amîn-II",
    "y": " âmin"
  },
  {
    "x": "amînbîyayîş (n)",
    "y": " b. amênbîyayîş (n)"
  },
  {
    "x": "amînkerde (n)",
    "y": " b. amênkerde (n)"
  },
  {
    "x": "amînkerdiş (n)",
    "y": " b. amênkerdiş (n)"
  },
  {
    "x": "amîral, -e",
    "y": " amiral"
  },
  {
    "x": "amîyayîne",
    "y": " b. ameyene"
  },
  {
    "x": "amîyayîne pêser",
    "y": " b. ameyene pêser"
  },
  {
    "x": "amîyayîş (n)",
    "y": " b. ameyîş (n)"
  },
  {
    "x": "amîyayîşpêser (n)",
    "y": " b. ameyîşpêser (n)"
  },
  {
    "x": "amîyen (n)",
    "y": " b. amên (n)"
  },
  {
    "x": "amna (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "amnan (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "amnano peye",
    "y": " b. hamnano peyên"
  },
  {
    "x": "amnano peyen",
    "y": " b. hamnano peyên"
  },
  {
    "x": "amnano peyîn",
    "y": " b. hamnano peyên"
  },
  {
    "x": "amnano pêyen",
    "y": " b. hamnano peyên"
  },
  {
    "x": "amnano pêyê",
    "y": " b. hamnano peyên"
  },
  {
    "x": "amnano pêyên",
    "y": " b. hamnano peyên"
  },
  {
    "x": "amnano verê",
    "y": " b. hamnano verên"
  },
  {
    "x": "amnano verîn",
    "y": " b. hamnano verên"
  },
  {
    "x": "amnano virê",
    "y": " b. hamnano verên"
  },
  {
    "x": "amnano virên",
    "y": " b. hamnano verên"
  },
  {
    "x": "amnana (m)",
    "y": " b. hamnana (m)"
  },
  {
    "x": "amnane (n)",
    "y": " b. hamnane (n)"
  },
  {
    "x": "amnanên, -e",
    "y": " b. hamnanên, -e"
  },
  {
    "x": "amnanî",
    "y": " b. hamnanî"
  },
  {
    "x": "amnanîn, -e",
    "y": " b. hamnanên, -e"
  },
  {
    "x": "amnanîye (m)",
    "y": " b. hamnanîye (m)"
  },
  {
    "x": "amnezî (m)",
    "y": " amnezi"
  },
  {
    "x": "amno (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "amnon (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "amnonî",
    "y": " b. hamnanî"
  },
  {
    "x": "amnû (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "amnûn (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "amnûna (m)",
    "y": " b. hamnana (m)"
  },
  {
    "x": "amnûne (n)",
    "y": " b. hamnane (n)"
  },
  {
    "x": "amnûnê",
    "y": " b. hamnanî "
  },
  {
    "x": "amnûnî",
    "y": " b. hamnanî"
  },
  {
    "x": "amnûnîye (m)",
    "y": " b. hamnanîye (m)"
  },
  {
    "x": "amnûnyen",
    "y": " b. hamnanên, -e"
  },
  {
    "x": "amocine (m)",
    "y": " b. emcinî (m)"
  },
  {
    "x": "amojen (m)",
    "y": " b. emcinî (m)"
  },
  {
    "x": "amojine (m)",
    "y": " b. emcinî (m)"
  },
  {
    "x": "amonyak (n)",
    "y": " amonyak "
  },
  {
    "x": "amonyum (n)",
    "y": " amonyum"
  },
  {
    "x": "amorf, -e",
    "y": " amorf"
  },
  {
    "x": "amortîsman (n)",
    "y": " amortisman"
  },
  {
    "x": "amor (n)",
    "y": " b. hûmare (m)"
  },
  {
    "x": "amordene",
    "y": " b. hûmaritene"
  },
  {
    "x": "amordiş (n)",
    "y": " b. hûmaritiş (n)"
  },
  {
    "x": "amore (m)",
    "y": " b. hûmare (m)"
  },
  {
    "x": "amors (n) (sn)",
    "y": " amors "
  },
  {
    "x": "amortene",
    "y": " b. hûmaritene"
  },
  {
    "x": "amortiş (n)",
    "y": " b. hûmaritiş (n)"
  },
  {
    "x": "amper (n)",
    "y": " amper "
  },
  {
    "x": "ampermetre (n)",
    "y": " akımölçer, ampermetre, amperölçer"
  },
  {
    "x": "amperpeym (n)",
    "y": " akımölçer, ampermetre, amperölçer"
  },
  {
    "x": "ampîrîk, -e",
    "y": " ampirik, tecrübi"
  },
  {
    "x": "ampul (n)",
    "y": " ampül"
  },
  {
    "x": "ampule (m)",
    "y": " b. ampul (n)"
  },
  {
    "x": "amr (n)",
    "y": " b. emr-I (n)"
  },
  {
    "x": "Amsterdam",
    "y": " Amsterdam"
  },
  {
    "x": "amver, -i",
    "y": " b. hember, -e; hemver, -e"
  },
  {
    "x": "amyayeni",
    "y": " b. ameyene"
  },
  {
    "x": "amyayeni pêser",
    "y": " b. ameyene pêser"
  },
  {
    "x": "amyayîne",
    "y": " b. ameyene"
  },
  {
    "x": "amyayîne pêser",
    "y": " b. ameyene pêser"
  },
  {
    "x": "amyayîni",
    "y": " b. ameyene"
  },
  {
    "x": "amyayîni pêser",
    "y": " b. ameyene pêser"
  },
  {
    "x": "amyayîş (n)",
    "y": " b. ameyîş (n)"
  },
  {
    "x": "amyayîşpêser (n)",
    "y": " b. ameyîşpêser (n)"
  },
  {
    "x": "...an (sf)",
    "y": " çoğul eki (-ler/-lar)"
  },
  {
    "x": "an-I",
    "y": " b. yan-I"
  },
  {
    "x": "an ... an ...",
    "y": " b. yan ... yan ..."
  },
  {
    "x": "an-II",
    "y": " b. yan-II"
  },
  {
    "x": "ana",
    "y": " b. wina"
  },
  {
    "x": "anace (m)",
    "y": " 1)künye\r\n~2)ana arı"
  },
  {
    "x": "anaci (m)",
    "y": " b. anace (m)"
  },
  {
    "x": "anagore",
    "y": " b. anegore"
  },
  {
    "x": "anakî",
    "y": " b. wina"
  },
  {
    "x": "anakronîk, -e",
    "y": " anakronik"
  },
  {
    "x": "anakronîzm (n)",
    "y": " anakronizm"
  },
  {
    "x": "anal, -e",
    "y": " anal"
  },
  {
    "x": "analîst, -e",
    "y": " analizci, analist"
  },
  {
    "x": "analîtîk, -e",
    "y": " analitik, çözümlemeli"
  },
  {
    "x": "analîz (n)",
    "y": " analiz, çözümleme, tahlil\r\n~analîzê çorşmeyî (n): çevre analizi\r\n~analîzê dayîyan: verilerin analizi\r\n~analîzo ekonomîk (n): ekonomik analiz, iktisadi analiz"
  },
  {
    "x": "analîzê çorşmeyî (n)",
    "y": " çevre analizi"
  },
  {
    "x": "analîzê dayîyan",
    "y": " verilerin analizi"
  },
  {
    "x": "analîzê doneyan",
    "y": " verilerin analizi"
  },
  {
    "x": "analîzê dorûverî (n)",
    "y": " çevre analizi"
  },
  {
    "x": "analîzê fîlmî (n)",
    "y": " film analizi"
  },
  {
    "x": "analîzê karakteran (n)",
    "y": " karakter çözümlemeleri"
  },
  {
    "x": "analîzê malîyetî (n)",
    "y": " maliyet analizi"
  },
  {
    "x": "analîzê programî (n)",
    "y": " program analizi"
  },
  {
    "x": "analîzê rewşa esta (n)",
    "y": " mevcut durum analizi"
  },
  {
    "x": "analîzê rêzila erjan/qîymetan (n)",
    "y": " değer zinciri analizi"
  },
  {
    "x": "analîzê rîskî (n)",
    "y": " risk analizi"
  },
  {
    "x": "analîzê tehlukeyî (n)",
    "y": " tehlike analizi"
  },
  {
    "x": "analîzê xeterî (n)",
    "y": " tehlike analizi"
  },
  {
    "x": "analîzo ekonomîk (n)",
    "y": " ekonomik analiz, iktisadi analiz"
  },
  {
    "x": "analîzker, -e",
    "y": " analizci, analizci"
  },
  {
    "x": "analjezî (m)",
    "y": " analjezi"
  },
  {
    "x": "analog, -e",
    "y": " analog"
  },
  {
    "x": "analojî (n)",
    "y": " analoji, benzeşim, benzeşme"
  },
  {
    "x": "anamnezî (m)",
    "y": " anamnez, anamnezi"
  },
  {
    "x": "anarşî (m)",
    "y": " anarşi"
  },
  {
    "x": "anarşîk, -e",
    "y": " anarşik"
  },
  {
    "x": "anarşîst, -e",
    "y": " anarşist"
  },
  {
    "x": "anarşîstîye (m)",
    "y": " anarşistlik"
  },
  {
    "x": "anarşîzm (n)",
    "y": " anarşizm"
  },
  {
    "x": "ananase (m)",
    "y": " ananas"
  },
  {
    "x": "ananasêre (m)",
    "y": " ananas ağacı"
  },
  {
    "x": "anasar",
    "y": " b. inasar"
  },
  {
    "x": "anasarên, -i",
    "y": " b. inasarên, -e; winasî, -ye"
  },
  {
    "x": "anase (m)",
    "y": " b. anace (m)"
  },
  {
    "x": "anason (n)",
    "y": " anason"
  },
  {
    "x": "anasonin, -e",
    "y": " anasonlu"
  },
  {
    "x": "anaşt (n)",
    "y": " b. nata"
  },
  {
    "x": "anatomî (m)",
    "y": " anatomi"
  },
  {
    "x": "anatomîk, -e",
    "y": " anatomik"
  },
  {
    "x": "anawa",
    "y": " b. inahawa "
  },
  {
    "x": "anaxtar (n)",
    "y": " anahtar"
  },
  {
    "x": "anayin, -e",
    "y": " b. inasarên, -e; winasî, -ye"
  },
  {
    "x": "anayîn, -e",
    "y": " b. inasarên, -e; winasî, -ye"
  },
  {
    "x": "anaze (m)",
    "y": " b. anace (m)"
  },
  {
    "x": "anbaz (n)",
    "y": " b. embaz (n)"
  },
  {
    "x": "anberyan, -i",
    "y": " b. embiryan, -e"
  },
  {
    "x": "anberyanîni (m)",
    "y": " b. embiryanîne (m)"
  },
  {
    "x": "anca",
    "y": " b. ancîna"
  },
  {
    "x": "ancax",
    "y": " ancak, yalnızca, yalnız, sadece\r\n~Ancax ti zanî. (Ancak sen bilirsin.)"
  },
  {
    "x": "ancê",
    "y": " b. ancî"
  },
  {
    "x": "ancitene",
    "y": " b. antene"
  },
  {
    "x": "ancî",
    "y": " yine, gene, tekrar"
  },
  {
    "x": "ancîna",
    "y": " yine, gene, tekrar"
  },
  {
    "x": "ancîya",
    "y": " b. ancîna"
  },
  {
    "x": "ancîyayene",
    "y": " çekilmek"
  },
  {
    "x": "ancîyayîş (n)",
    "y": " çekilme"
  },
  {
    "x": "anculî, -ye",
    "y": " 1)yağsız\r\n~2)yavan"
  },
  {
    "x": "andal (n)",
    "y": " 1. yüklük, yük 2.kiler"
  },
  {
    "x": "ande",
    "y": " b. hende"
  },
  {
    "x": "andêr, -e",
    "y": " b. ondêr, -e"
  },
  {
    "x": "andiş (n)",
    "y": " b. antiş (n)"
  },
  {
    "x": "andol (n)",
    "y": " b. andal (n)"
  },
  {
    "x": "andor, -e",
    "y": " b. ondêr, -e"
  },
  {
    "x": "Andorra (m)",
    "y": " Andorra"
  },
  {
    "x": "Andorra-la-Vella (m)",
    "y": " Andorra la Vella (Andorra la Vieja)"
  },
  {
    "x": "anegore",
    "y": " göre, -e göre, bakımından"
  },
  {
    "x": "anegoreyê mi",
    "y": " bana göre, bence"
  },
  {
    "x": "anemî (m)",
    "y": " anemi"
  },
  {
    "x": "anemîya Deryayê Sipî (m)",
    "y": " Akdeniz anemisi"
  },
  {
    "x": "anestezî (m)",
    "y": " anestezi"
  },
  {
    "x": "anêyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "anêtiş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "angiştane (n)",
    "y": " b. engiştane (n)"
  },
  {
    "x": "anglosakson, -e",
    "y": " Anglosakson"
  },
  {
    "x": "Angola",
    "y": " Angola"
  },
  {
    "x": "angore",
    "y": " b. anegore"
  },
  {
    "x": "anika",
    "y": " b. nika"
  },
  {
    "x": "aniştene",
    "y": " b. niştene"
  },
  {
    "x": "anitene",
    "y": " b. antene"
  },
  {
    "x": "anix (n)",
    "y": " b. anûx (n)"
  },
  {
    "x": "anî",
    "y": " b. winî"
  },
  {
    "x": "anîmasyon (n)",
    "y": " canlandırma, animasyon"
  },
  {
    "x": "anîmator, -e",
    "y": " animatör"
  },
  {
    "x": "anîmîzm (n)",
    "y": " animizm, canlıcılık"
  },
  {
    "x": "anîyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "banî",
    "y": " b. biewnî"
  },
  {
    "x": "bianê",
    "y": " b. biewnî"
  },
  {
    "x": "anjîyo (m)",
    "y": " anjiyo"
  },
  {
    "x": "anjîyo bîyene",
    "y": " anjiyo olmak"
  },
  {
    "x": "anjîyo kerdene",
    "y": " anjiyo yapmak"
  },
  {
    "x": "anka",
    "y": " b. nika"
  },
  {
    "x": "ankastre (n)",
    "y": " ankastre"
  },
  {
    "x": "ankayin, -i",
    "y": " b. nikayin, -e"
  },
  {
    "x": "ankerdene",
    "y": " b. hankerdene"
  },
  {
    "x": "ankerdiş (n)",
    "y": " b. hankerdiş (n)"
  },
  {
    "x": "anket (n)",
    "y": " anket"
  },
  {
    "x": "anketker, -e",
    "y": " anketçi, anketör"
  },
  {
    "x": "anketkerîye (m)",
    "y": " anketçilik"
  },
  {
    "x": "anketor, -e",
    "y": " anketçi, anketör"
  },
  {
    "x": "anketorîye (m)",
    "y": " anketçilik"
  },
  {
    "x": "ankîloz (n)",
    "y": " ankiloz"
  },
  {
    "x": "ankîlozan spontadîlîtas (n)",
    "y": " ankilozan spontadilit(as)"
  },
  {
    "x": "anksîyete (m)",
    "y": " anksiyete"
  },
  {
    "x": "ano",
    "y": " b. winî"
  },
  {
    "x": "anomalî (m)",
    "y": " anomali"
  },
  {
    "x": "anonîm, -e",
    "y": " anonim"
  },
  {
    "x": "anormal, -e",
    "y": " anormal"
  },
  {
    "x": "anormalîye (m)",
    "y": " anormallik"
  },
  {
    "x": "anonîm, -e",
    "y": " anonim"
  },
  {
    "x": "anonîmîye (m)",
    "y": " anonimlik"
  },
  {
    "x": "anox (n)",
    "y": " b. anûx (n)"
  },
  {
    "x": "Anqara (m)",
    "y": " Ankara"
  },
  {
    "x": "Anqari (n)",
    "y": " b. Anqara (m)"
  },
  {
    "x": "ansîklopedî (n)",
    "y": " ansiklopedi"
  },
  {
    "x": "ansîklopedîk, -e",
    "y": " ansiklopedik"
  },
  {
    "x": "anta (m)",
    "y": " bükünlü"
  },
  {
    "x": "Antananarîvo (n)",
    "y": " Antananarivo"
  },
  {
    "x": "Antarktîka (m)",
    "y": " Antarktika"
  },
  {
    "x": "ante (n)",
    "y": " bükünlü"
  },
  {
    "x": "anten (n)",
    "y": " anten"
  },
  {
    "x": "antenê peykî (n)",
    "y": " uydu anten"
  },
  {
    "x": "antenê satelîtî (n)",
    "y": " uydu anten"
  },
  {
    "x": "antene",
    "y": " 1)çekmek \r\n~2)içmek (sigara vb. için) \r\n~3)ürün toplamak (toprak altından çıkarılan ürünler için) \r\n~4)(dişi at için) çiftleştirmek "
  },
  {
    "x": "antet (n)",
    "y": " antet"
  },
  {
    "x": "antiş (n)",
    "y": " 1)çekme, çekim \r\n~2)içme \r\n~3)ürün toplama (toprak altından çıkarılan ürünler için)"
  },
  {
    "x": "antîbakterîyel, -e",
    "y": " antibakteriyel"
  },
  {
    "x": "antîbîyotîk (n)",
    "y": " antibiyotik"
  },
  {
    "x": "antîdemokratîk, -e",
    "y": " antidemokratik"
  },
  {
    "x": "antîk",
    "y": " b. entîk"
  },
  {
    "x": "antîka (m)",
    "y": " b. entîka (m)"
  },
  {
    "x": "antîkapîtalîst, -e",
    "y": " antikapitalist"
  },
  {
    "x": "antîkapîtalîzm (n)",
    "y": " antikapitalizm"
  },
  {
    "x": "antîke (n)",
    "y": " b. entîke (n) "
  },
  {
    "x": "antîkîte (m)",
    "y": " b. entîkîte (m)"
  },
  {
    "x": "antîkomunîst, -e",
    "y": " antikomünist"
  },
  {
    "x": "antîkomunîzm (n)",
    "y": " antikomünizm"
  },
  {
    "x": "antîkor (n)",
    "y": " antikor"
  },
  {
    "x": "antîlop, -e",
    "y": " antilop"
  },
  {
    "x": "antîpatî (m)",
    "y": " antipati"
  },
  {
    "x": "antîpatîk, -e",
    "y": " antipatik"
  },
  {
    "x": "antîpropaganda (m)",
    "y": " antipropaganda"
  },
  {
    "x": "antîsemît, -e",
    "y": " antisemit"
  },
  {
    "x": "antîsemîtîk, -e",
    "y": " antisemitik"
  },
  {
    "x": "antîsemîtîzm (n)",
    "y": " antisemitizm"
  },
  {
    "x": "antîsermîyandarîye (m)",
    "y": " antikapitalizm"
  },
  {
    "x": "antîtanq, -e",
    "y": " antitank"
  },
  {
    "x": "antîtez (n)",
    "y": " antitez"
  },
  {
    "x": "antîvîrus, -e",
    "y": " antivirüs"
  },
  {
    "x": "antîye (m)",
    "y": " bükünlü"
  },
  {
    "x": "antolojî (n)",
    "y": " seçki, antoloji"
  },
  {
    "x": "antrenman (n)",
    "y": " antrenman, antreman"
  },
  {
    "x": "antrenor, -e",
    "y": " antrenör, çalıştırıcı (sporda)"
  },
  {
    "x": "antrenorîye (m)",
    "y": " antrenörlük, çalıştırıcılık (sporda)"
  },
  {
    "x": "antropolojîk, -e",
    "y": " antropolojik"
  },
  {
    "x": "anux (n)",
    "y": " b. anûx (n)"
  },
  {
    "x": "anû",
    "y": " b. winî"
  },
  {
    "x": "anûx (n)",
    "y": " kekik"
  },
  {
    "x": "anyayîne",
    "y": " b. ewnîyayene "
  },
  {
    "x": "anyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "ap (n)",
    "y": " amca, emmi"
  },
  {
    "x": "apo",
    "y": " amca (hitap hali)"
  },
  {
    "x": "apa (n)",
    "y": " b. epe (m), epo (n)"
  },
  {
    "x": "apandês (n)",
    "y": " b. apandîsît (n)"
  },
  {
    "x": "apandîs (n)",
    "y": " apandis (n)"
  },
  {
    "x": "apandîsît (n)",
    "y": " apandisit"
  },
  {
    "x": "apardumoni (m)",
    "y": " b. apartman (n)"
  },
  {
    "x": "apartman (n)",
    "y": " apartman "
  },
  {
    "x": "apartmane (m)",
    "y": " b. apartman (n)"
  },
  {
    "x": "apartmani (m)",
    "y": " b. apartman (n)"
  },
  {
    "x": "apcinî (m)",
    "y": " amca karısı, yenge (amca karısı)"
  },
  {
    "x": "aperîtîf (n)",
    "y": " aperitif"
  },
  {
    "x": "apey",
    "y": " geri, gerisingeri, gerisingeriye"
  },
  {
    "x": "apey dayene",
    "y": " 1)geri vermek, iade etmek \r\n~2)örmek (saç vb. için) \r\n~3)esirgemek (vermekten veya yapmaktan kaçınmak)"
  },
  {
    "x": "apey mendene",
    "y": " geri kalmak"
  },
  {
    "x": "apeydayîş (n)",
    "y": " 1)geri verme, iade etme, iade \r\n~2)örme (saç vb. için) \r\n~3)esirgeme (vermekten veya yapmaktan kaçınma)"
  },
  {
    "x": "apeymendiş (n)",
    "y": " geri kalma\r\n~apeymendişo zihnî (n): zekâ geriliği, mental retardasyon"
  },
  {
    "x": "apeymendişo zihnî (n)",
    "y": " zekâ geriliği, mental retardasyon"
  },
  {
    "x": "apolet (n)",
    "y": " apolet (subay giysisinde)"
  },
  {
    "x": "apolîtîk, -e",
    "y": " apolitik"
  },
  {
    "x": "applîkasyon (n) (TE)",
    "y": " uygulama programı"
  },
  {
    "x": "aprax (n)",
    "y": " sarma"
  },
  {
    "x": "apre (n)",
    "y": " apre"
  },
  {
    "x": "apre dayene",
    "y": " apre vermek"
  },
  {
    "x": "aprekar, -e",
    "y": " apreci"
  },
  {
    "x": "aprekarîye (m)",
    "y": " aprecilik"
  },
  {
    "x": "apsûn (n)",
    "y": " b. efsûn (n)"
  },
  {
    "x": "apsûn kerdene",
    "y": " b. efsûn kerdene"
  },
  {
    "x": "apşon (n)",
    "y": " b. efsûn (n)"
  },
  {
    "x": "apşon kerdene",
    "y": " b. efsûn kerdene"
  },
  {
    "x": "Apya",
    "y": " Apiah"
  },
  {
    "x": "aqalaşnayene",
    "y": " b. raqilaşnayene"
  },
  {
    "x": "aqeleşnayene",
    "y": " b. raqilaşnayene"
  },
  {
    "x": "aqeternayene",
    "y": " b. aqitnayene"
  },
  {
    "x": "aqeternayîş (n)",
    "y": " b. aqitnayîş (n)"
  },
  {
    "x": "aqetnayene",
    "y": " b. aqitnayene"
  },
  {
    "x": "aqetnayîş (n)",
    "y": " b. aqitnayîş (n)"
  },
  {
    "x": "aqil-I (n)",
    "y": " akıl, us"
  },
  {
    "x": "aqil dayene",
    "y": " akıl vermek "
  },
  {
    "x": "aqil de mendene",
    "y": " aklında kalmak"
  },
  {
    "x": "aqil firnayene",
    "y": " aklını kaçırmak, aklını oynatmak, delirmek, çıldırmak"
  },
  {
    "x": "aqil girewtene",
    "y": " akıl almak"
  },
  {
    "x": "aqil kerdene",
    "y": " akıl etmek"
  },
  {
    "x": "aqil pernayene",
    "y": " aklını kaçırmak, aklını oynatmak, delirmek, çıldırmak"
  },
  {
    "x": "aqil ra ber",
    "y": " akıl dışı, irrasyonel, us dışı"
  },
  {
    "x": "aqil ra teber",
    "y": " akıl dışı, irrasyonel, us dışı"
  },
  {
    "x": "aqil vîndî kerdene",
    "y": " b. aqilê xo vîndî kerdene"
  },
  {
    "x": "aqil vînî kerdene",
    "y": " b. aqil vîndî kerdene"
  },
  {
    "x": "aqilê xo antene xo sere",
    "y": "  aklını başına toplamak, aklını başına devşirmek, aklını başına almak"
  },
  {
    "x": "aqilê xo arê dayene",
    "y": " aklını başına toplamak, aklını başına devşirmek"
  },
  {
    "x": "aqilê xo girewtene xo sere",
    "y": " aklını başına toplamak, aklını başına devşirmek, aklını başına almak"
  },
  {
    "x": "aqilê xo vîndî kerdene",
    "y": " aklını kaçırmak, aklını oynatmak, aklını yitirmek, delirmek, çıldırmak"
  },
  {
    "x": "aqilê xwi arê dayene",
    "y": " b. aqilê xo arê dayene"
  },
  {
    "x": "aqil, -e-II",
    "y": " b. biaqil, -e"
  },
  {
    "x": "aqilbalix, -e",
    "y": " ergen, akil baliğ"
  },
  {
    "x": "aqilbalix bîyayene",
    "y": " b. aqilbalix bîyene"
  },
  {
    "x": "aqilbalix bîyene",
    "y": " ergenleşmek"
  },
  {
    "x": "aqilbalixbîyayîş (n)",
    "y": " ergenleşme"
  },
  {
    "x": "aqilbalixey (m)",
    "y": " b. aqilbalixîye (m)"
  },
  {
    "x": "aqilbalixê (m)",
    "y": " b. aqilbalixî (m) "
  },
  {
    "x": "aqilbalixi (m)",
    "y": " b. aqilbalix, -e"
  },
  {
    "x": "aqilbalixî (m)",
    "y": " ergenlik"
  },
  {
    "x": "aqilbalixîye (m)",
    "y": " ergenlik"
  },
  {
    "x": "aqilêxovîndîkerdiş (n)",
    "y": " çıldırma, çıldırış, delirme, deliriş"
  },
  {
    "x": "aqilfirnayayîş (n)",
    "y": " çıldırma, çıldırış, delirme, deliriş"
  },
  {
    "x": "aqilî",
    "y": " b. biaqil, -e"
  },
  {
    "x": "aqillî",
    "y": " b. biaqil, -e"
  },
  {
    "x": "aqilpernayayîş (n)",
    "y": " çıldırma, çıldırış, delirme, deliriş"
  },
  {
    "x": "aqilsivikî (m)",
    "y": " akıl zayıflığı"
  },
  {
    "x": "aqilşenikî (m)",
    "y": " akıl zayıflığı"
  },
  {
    "x": "aqit (n)",
    "y": " b. aqît (n)"
  },
  {
    "x": "aqiteyîş (n)",
    "y": " b. aqitîyayîş (n)"
  },
  {
    "x": "aqitin, -e (s)",
    "y": " b. aqîtin, -e (s)"
  },
  {
    "x": "aqitîyayene",
    "y": " ayrılmak"
  },
  {
    "x": "aqitîyayîş (n)",
    "y": " ayrılma"
  },
  {
    "x": "aqitnayene",
    "y": " ayırmak"
  },
  {
    "x": "aqitnayîş (n)",
    "y": " ayırma"
  },
  {
    "x": "aqît (n)",
    "y": " 1)çalma (koyu pekmez) \r\n~2)pekmez \r\n~3)reçel \r\n~4)salça \r\n~aqîtê bacikan (n): biber salçası"
  },
  {
    "x": "aqîtê bacikan (n)",
    "y": " biber salçası"
  },
  {
    "x": "aqîtê firingan (n)",
    "y": " b. awa firingîyan (m)"
  },
  {
    "x": "aqîtê firingîyan (n)",
    "y": " b. awa firingîyan (m)"
  },
  {
    "x": "aqîtin, -e (s)",
    "y": " pekmezli"
  },
  {
    "x": "aqlî, -ye",
    "y": " 1)akla uygun, akılsal, akli, rasyonel, ussal \r\n~2)b. biaqil, -e"
  },
  {
    "x": "aqreb (n)",
    "y": " akrep"
  },
  {
    "x": "aqreba (n, m)",
    "y": " akraba"
  },
  {
    "x": "aqrep (n)",
    "y": " b. aqreb (n)"
  },
  {
    "x": "aqruba (n, m)",
    "y": " aqreba (n, m)"
  },
  {
    "x": "ar-I (n)",
    "y": " ar"
  },
  {
    "x": "ar-II (n)",
    "y": " b. erd (n)"
  },
  {
    "x": "ar-III (n)",
    "y": " ar, utanma, utanç"
  },
  {
    "x": "ar kerdene",
    "y": " utanmak"
  },
  {
    "x": "ara, -ye -I",
    "y": " aç karnına olan (sabahleyin henüz bir şey yememiş olan)\r\n~Ez hema zî ara ya. (Hâlâ aç karnınayım.)"
  },
  {
    "x": "ara-II (m)",
    "y": " b. arayî (m)"
  },
  {
    "x": "ara xo kerdene",
    "y": " b. arayîya xo kerdene"
  },
  {
    "x": "ara-III (n)",
    "y": " b. are-I (n)"
  },
  {
    "x": "ara-IV (vr)",
    "y": " b. ra (vr), b. ro (vr)"
  },
  {
    "x": "ara ci şîkayene",
    "y": " b. ra ... şîkîyayene"
  },
  {
    "x": "ara ho amayene",
    "y": " b. ro xo ameyene"
  },
  {
    "x": "arabîyayîş (n)",
    "y": " aç karnına olma"
  },
  {
    "x": "aracişîkayîs (n)",
    "y": " b. ra ... şîkîyayîş"
  },
  {
    "x": "aradîni (m)",
    "y": " b. arayî (m)"
  },
  {
    "x": "aradîni kardene",
    "y": " b. arayîya xo kerdene"
  },
  {
    "x": "arahî (m)",
    "y": " b. arayî (m)"
  },
  {
    "x": "arame (n)",
    "y": " b. areme (n)"
  },
  {
    "x": "arame kerdene",
    "y": " b. areme kerdene"
  },
  {
    "x": "aramî (m)",
    "y": " huzur"
  },
  {
    "x": "aramîya zerrî (m)",
    "y": " huzur"
  },
  {
    "x": "araq-I (m)",
    "y": " b. reqî (m)"
  },
  {
    "x": "araq-II (n)",
    "y": " b. ereq (n)"
  },
  {
    "x": "araq dayene",
    "y": " b. ereq dayene"
  },
  {
    "x": "araq estene",
    "y": " b. ereq eştene"
  },
  {
    "x": "araq rokerdene",
    "y": " b. ereq rokerdene"
  },
  {
    "x": "araq vetene",
    "y": " b. ereq vetene"
  },
  {
    "x": "araqî (m)",
    "y": " b. reqî (m)"
  },
  {
    "x": "araqîyayene",
    "y": " b. ereqîyayene"
  },
  {
    "x": "araqnayene",
    "y": " b. ereqnayene"
  },
  {
    "x": "araqwer, -e",
    "y": " b. reqîwer, -e"
  },
  {
    "x": "arasa (m)",
    "y": " b. ersa (m)"
  },
  {
    "x": "arasn (n)",
    "y": " b. herçene (n)"
  },
  {
    "x": "aratîne (m)",
    "y": " b. arayî (m)"
  },
  {
    "x": "arav, -e",
    "y": " b. ereb, -e"
  },
  {
    "x": "Aravîstan (n)",
    "y": " b. Erebîstan (n)"
  },
  {
    "x": "aravkî (m)",
    "y": " b. erebkî (m)"
  },
  {
    "x": "arax (n)",
    "y": " b. ereq (n)"
  },
  {
    "x": "araye (m)",
    "y": " b. arayî (m)"
  },
  {
    "x": "arayî (m)",
    "y": " 1)kahvaltı (sabah yemeği) \r\n~2)aç karnına olma durumu"
  },
  {
    "x": "arayîya xo kerdene",
    "y": " 1)kahvaltı etmek (sabah yemeği yemek) \r\n~2)kahvaltı öncesi birşey(ler) yemek\r\n~To arayîya xo kerde? (1-Kahvaltı ettin mi?/Sabah yemeği yedin mi? 2-Kahvaltı öncesi birşey/birşeyler yedin mi?)"
  },
  {
    "x": "arayîya xo şikitene",
    "y": " kahvaltı öncesi birşey(ler) yemek\r\n~To arayîya xo şikite? (Kahvaltı öncesi birşey/birşeyler yedin mi?)"
  },
  {
    "x": "arazî (m)",
    "y": " b. erazî (m)"
  },
  {
    "x": "arbançî, -ye",
    "y": " b. arêwan, -e"
  },
  {
    "x": "arbêş (n)",
    "y": " b. hargûş (n)"
  },
  {
    "x": "arbêzi (m)",
    "y": " b. arozî (m)"
  },
  {
    "x": "arbîş (n)",
    "y": " b. hargûş (n)"
  },
  {
    "x": "arcaya (m)",
    "y": " b. erjaya (m)"
  },
  {
    "x": "arcaye (e)",
    "y": " b. erjaye (n)"
  },
  {
    "x": "arcayîne",
    "y": " b. erjayene"
  },
  {
    "x": "arcayîş (n)",
    "y": " b. erjayîş (n)"
  },
  {
    "x": "arcel (n)",
    "y": " b. ecel (n)"
  },
  {
    "x": "arcelê ... ameyene",
    "y": " b. ecelê ... ameyene"
  },
  {
    "x": "arcelê ... omeyene",
    "y": " b. ecelê ... ameyene"
  },
  {
    "x": "arci (m)",
    "y": " b. erje (m)"
  },
  {
    "x": "arcîc (n)",
    "y": " b. erjîj (n)"
  },
  {
    "x": "arcîyaya (m)",
    "y": " b. erjaya (m)"
  },
  {
    "x": "arcîyaye (n)",
    "y": " b. erjaye (n)"
  },
  {
    "x": "arcîyayîne",
    "y": " b. erjayene"
  },
  {
    "x": "arcîyayîş (n)",
    "y": " b. erjayîş (n)"
  },
  {
    "x": "arcon, -i",
    "y": " b. erjan, -e"
  },
  {
    "x": "arconey (m)",
    "y": " b. erjanîye (m)"
  },
  {
    "x": "arcûn, -i",
    "y": " b. erjan, -e"
  },
  {
    "x": "arcûney (m)",
    "y": " b. erjanîye (m)"
  },
  {
    "x": "arçarnayîş (n)",
    "y": " b. açarnayîş (n)"
  },
  {
    "x": "ard (n)",
    "y": " b. erd (n)"
  },
  {
    "x": "ardene",
    "y": " 1)getirmek  \r\n~2)(bazı hayvanlar için) doğurmak"
  },
  {
    "x": "ardene cîhan",
    "y": " doğurmak, cihana getirmek"
  },
  {
    "x": "ardene dinya",
    "y": " doğurmak, dünyaya getirmek"
  },
  {
    "x": "ardene pêser",
    "y": " toplamak"
  },
  {
    "x": "ardene pîyeser",
    "y": " b. ardene pêser"
  },
  {
    "x": "ardene ra binê goşî",
    "y": " çaktırmak, sezdirmek, sezindirmek"
  },
  {
    "x": "ardene ra xo vîr",
    "y": " b. ardene vîrê xo"
  },
  {
    "x": "ardene vîrê xo",
    "y": " anımsamak, hatırlamak; anmak"
  },
  {
    "x": "ardene warye",
    "y": " b. ware ardene"
  },
  {
    "x": "ardene werte",
    "y": " b. ware ardene"
  },
  {
    "x": "ardene xo vîr",
    "y": " b. ardene vîrê xo"
  },
  {
    "x": "ardene xo vîrî",
    "y": " b. ardene vîrê xo"
  },
  {
    "x": "ardene yewca",
    "y": " 1)toplamak \r\n~2)devşirmek"
  },
  {
    "x": "ardim (n)",
    "y": " yardım\r\n~ardimo edlî (n): adlî yardım"
  },
  {
    "x": "ardim kerdene",
    "y": " yardım etmek"
  },
  {
    "x": "ardimo edlî (n)",
    "y": " adlî yardım"
  },
  {
    "x": "ardimkar, -e",
    "y": " asistan"
  },
  {
    "x": "ardimkarîye (m)",
    "y": " asistanlık"
  },
  {
    "x": "ardimkerdox, -e",
    "y": " yardımcı\r\n~ardimkerdoxa rektore/î (m): rektör yardımcısı\r\n~karo ardimkerdox (n): yardımcı fiil"
  },
  {
    "x": "ardimkerdoxa rektore/î (m)",
    "y": " rektör yardımcısı"
  },
  {
    "x": "ardimkerdoxê rektore/î (n)",
    "y": " rektör yardımcısı"
  },
  {
    "x": "karo ardimkerdox (n)",
    "y": " yardımcı fiil"
  },
  {
    "x": "ardiş (n)",
    "y": " getirme"
  },
  {
    "x": "ardişpêser (n)",
    "y": " toplama"
  },
  {
    "x": "ardişpîser (n)",
    "y": " b. ardişpêser (n)"
  },
  {
    "x": "ardişpîyeser (n)",
    "y": " b. ardişpêser (n)"
  },
  {
    "x": "ardişvîrêxo",
    "y": " anma"
  },
  {
    "x": "ardişyewca (n)",
    "y": " toplama"
  },
  {
    "x": "ardî (zh)",
    "y": " un"
  },
  {
    "x": "ardî kerdene",
    "y": " öğütmek"
  },
  {
    "x": "ardîkerdiş (n)",
    "y": " öğütme"
  },
  {
    "x": "ardîş (m)",
    "y": " b. erdîşe (m)"
  },
  {
    "x": "ardîya (m) ",
    "y": " b. erdîya (m)"
  },
  {
    "x": "ardose (m)",
    "y": " b. herdose (m)"
  },
  {
    "x": "ardosi (m)",
    "y": " b. herdose (m)"
  },
  {
    "x": "ardû (n)",
    "y": " yakıt (odun vb. için)\r\n~ardûyê fosîlî (n): fosil yakıt\r\n~ardûyê sentetîkî (zh): sentetik yakıtlar"
  },
  {
    "x": "ardûyê fosîlî (n)",
    "y": " fosil yakıt"
  },
  {
    "x": "ardûyê sentetîkî (zh)",
    "y": " sentetik yakıtlar"
  },
  {
    "x": "are-I (n)",
    "y": " ara"
  },
  {
    "x": "are-II (n)",
    "y": " b. arê (n)"
  },
  {
    "x": "are-III (m)",
    "y": " b. arre (m)"
  },
  {
    "x": "arebe (n)",
    "y": " b. erebe (n)"
  },
  {
    "x": "arebeyê gayî (n)",
    "y": " b. erebeyê gayan (n)"
  },
  {
    "x": "areba (m)",
    "y": " b. erebe (n)"
  },
  {
    "x": "areme (n)",
    "y": " arama"
  },
  {
    "x": "areme kerdene",
    "y": " aramak"
  },
  {
    "x": "areometre (n)",
    "y": " sıvıölçer, areometre"
  },
  {
    "x": "areq (n)",
    "y": " b. ereq (n)"
  },
  {
    "x": "areq dayene",
    "y": " b. ereq dayene"
  },
  {
    "x": "areq eştene",
    "y": " b. ereq eştene"
  },
  {
    "x": "areq ruekerdene",
    "y": " b. ereq rokerdene"
  },
  {
    "x": "areq vetene",
    "y": " b. ereq vetene"
  },
  {
    "x": "areqîyayene",
    "y": " b. ereqîyayene"
  },
  {
    "x": "areqnayene",
    "y": " b. ereqnayene"
  },
  {
    "x": "arev, -e",
    "y": " b. ereb, -e"
  },
  {
    "x": "areva (m)",
    "y": " b. erebe (n)"
  },
  {
    "x": "Arevîstan (n)",
    "y": " b. Erebîstan (n)"
  },
  {
    "x": "arevkî (m)",
    "y": " b. erebkî (m)"
  },
  {
    "x": "areye (n)",
    "y": " b. arê (n)"
  },
  {
    "x": "areyî (m)",
    "y": " b. arayî (m)"
  },
  {
    "x": "areyîya xo kerdene",
    "y": " b. arayîya xo kerdene"
  },
  {
    "x": "areyîya xo şikitene",
    "y": " b. arayîya xo şikitene"
  },
  {
    "x": "arê (n)",
    "y": " değirmen"
  },
  {
    "x": "arê bîyene",
    "y": " 1)devşirilmek, derlenmek \r\n~2)toplanmak"
  },
  {
    "x": "arê dayene",
    "y": " 1)devşirmek \r\n~2)toplamak"
  },
  {
    "x": "arê dayêne",
    "y": " b. arê dayene"
  },
  {
    "x": "arê dîyayene",
    "y": " 1)devşirilmek \r\n~2)toplanmak"
  },
  {
    "x": "arê kerdene",
    "y": " 1)devşirmek, derlemek \r\n~2)toplamak"
  },
  {
    "x": "arêbîyayîş (n)",
    "y": " 1)devşirilme, derlenme \r\n~2)toplanma"
  },
  {
    "x": "arêdaye (n)",
    "y": " devşirme"
  },
  {
    "x": "arêdayîş (n)",
    "y": " 1)devşirme \r\n~2)toplama"
  },
  {
    "x": "arêdîyayîş (n)",
    "y": " 1)devşirilme \r\n~2)toplanma"
  },
  {
    "x": "arêker, -e",
    "y": " derleyici, derlemeci"
  },
  {
    "x": "arêkerda (m)",
    "y": " derleme"
  },
  {
    "x": "arêkerde (n)",
    "y": " derleme, devşirme"
  },
  {
    "x": "arêkerdiş (n) (TE)",
    "y": " 1)derleme, devşirme \r\n~2)toplama"
  },
  {
    "x": "arêkerdîye (m)",
    "y": " derleme"
  },
  {
    "x": "arêkerdox, -e",
    "y": " derleyen"
  },
  {
    "x": "arêwan, -e",
    "y": " değirmenci"
  },
  {
    "x": "arêwûn (n)",
    "y": " b. arêwan, -e"
  },
  {
    "x": "arêye (n)",
    "y": " b. arê (n)"
  },
  {
    "x": "argo (n)",
    "y": " argo "
  },
  {
    "x": "argoş (n)",
    "y": " b. hargûş, -e"
  },
  {
    "x": "argud (m)",
    "y": " b. argude (m)"
  },
  {
    "x": "argude (m)",
    "y": " yonca"
  },
  {
    "x": "argueş (n)",
    "y": " b. hargûş, -e"
  },
  {
    "x": "arguid (m)",
    "y": " b. argude (m)"
  },
  {
    "x": "arguide (m)",
    "y": " b. argude (m)"
  },
  {
    "x": "arguman (n)",
    "y": " argüman"
  },
  {
    "x": "arguweş (n)",
    "y": " b. hargûş (n)"
  },
  {
    "x": "arguwez (m)",
    "y": " b. arozî (m)"
  },
  {
    "x": "arbêzi (m)",
    "y": " b. arozî (m)"
  },
  {
    "x": "argûş (n)",
    "y": " b. hargûş, -e"
  },
  {
    "x": "ari (m)",
    "y": " b. arre (m)"
  },
  {
    "x": "arige (m)",
    "y": " b. arre (m)"
  },
  {
    "x": "ariq (n)",
    "y": " b. ereq (n)"
  },
  {
    "x": "ariq dayene",
    "y": " b. ereq dayene"
  },
  {
    "x": "ariq eştene",
    "y": " b. ereq eştene"
  },
  {
    "x": "ariq rokerdene",
    "y": " b. ereq rokerdene"
  },
  {
    "x": "ariq vetene",
    "y": " b. ereq vetene"
  },
  {
    "x": "ariqîyayene",
    "y": " b. ereqîyayene "
  },
  {
    "x": "ariqnayene",
    "y": " b. ereqnayene"
  },
  {
    "x": "ariqyayene",
    "y": " b. ereqîyayene "
  },
  {
    "x": "ariş (n)",
    "y": " ekmeklik tahıl veya un"
  },
  {
    "x": "ariza (m)",
    "y": " b. arize-I (m)"
  },
  {
    "x": "arize-I (m)",
    "y": " arıza"
  },
  {
    "x": "arize-I (n)",
    "y": " b. arize-I (m)"
  },
  {
    "x": "arizî, -ye",
    "y": " özel, hususi"
  },
  {
    "x": "arî (m)",
    "y": " b. arayî (m)"
  },
  {
    "x": "arîya xwi kerdene",
    "y": " b. arayîya xo kerdene"
  },
  {
    "x": "arîya xwi şikitene",
    "y": " b. arayîya xo şikitene"
  },
  {
    "x": "arî (n)",
    "y": " b. arê (n)"
  },
  {
    "x": "arî bîyene",
    "y": " b. arê bîyene"
  },
  {
    "x": "arî dayene",
    "y": " b. arê dayene"
  },
  {
    "x": "arî dîyayene",
    "y": " b. arê dîyayene"
  },
  {
    "x": "arî kerdene",
    "y": " b. arê kerdene"
  },
  {
    "x": "arîbîyayîş (n)",
    "y": " b. arêbîyayîş (n)"
  },
  {
    "x": "arîdayîş (n)",
    "y": " b. arêdayîş (n)"
  },
  {
    "x": "arîdîyayîş (n)",
    "y": " b. arêdîyayîş (n)"
  },
  {
    "x": "arîker (n)",
    "y": " b. arêker (n) (TE)"
  },
  {
    "x": "arîkerda (m)",
    "y": " b. arêkerda (m)"
  },
  {
    "x": "arîkerde (n)",
    "y": " b. arêkerde (n)"
  },
  {
    "x": "arîkerdiş (n) ",
    "y": " b. arêkerdiş (n) (TE)"
  },
  {
    "x": "arîkerdîye (m)",
    "y": " b. arêkerdîye (m)"
  },
  {
    "x": "arîkerdox, -e",
    "y": " b. arêkerdox, -e"
  },
  {
    "x": "arîn (m)",
    "y": " b. halîn (n)"
  },
  {
    "x": "arîstokrasî (n)",
    "y": " aristokrasi"
  },
  {
    "x": "arîstokrat, -e",
    "y": " soylu, aristokrat"
  },
  {
    "x": "arîstokratîk, -e",
    "y": " aristokratik"
  },
  {
    "x": "arîstokratîye (m)",
    "y": " aristokratlık, soyluluk"
  },
  {
    "x": "arîtmetîk (n)",
    "y": " aritmetik (mat)"
  },
  {
    "x": "arîtmetîkî, -ye",
    "y": " aritmetiksel"
  },
  {
    "x": "arîvûn, -e",
    "y": " b. arêwan, -e"
  },
  {
    "x": "arîwan (n)",
    "y": " b. arêwan, -e"
  },
  {
    "x": "arîweş (n)",
    "y": " b. hargûş, -e"
  },
  {
    "x": "arîye (n)",
    "y": " b. arê (n)"
  },
  {
    "x": "arîyedayîş (n)",
    "y": " b. arêdayîş (n)"
  },
  {
    "x": "arîyekerdiş (n)",
    "y": " b. arêkerdiş (n)"
  },
  {
    "x": "arîyet (n)",
    "y": " ariyet (huq.)"
  },
  {
    "x": "arîyonçî, -ye",
    "y": " b. arêwan, -e"
  },
  {
    "x": "arize dayene",
    "y": " arızalanmak"
  },
  {
    "x": "arizedayîş (n)",
    "y": " arızalanma"
  },
  {
    "x": "Arjantîn",
    "y": " Arjantin"
  },
  {
    "x": "arkaîk, -e",
    "y": " arkaik"
  },
  {
    "x": "arkaîzm (n)",
    "y": " arkaizm"
  },
  {
    "x": "arkeolog, -e",
    "y": " arkeolog"
  },
  {
    "x": "arkeolojî (n)",
    "y": " arkeoloji"
  },
  {
    "x": "arkeolojîk, -e",
    "y": " arkeolojik"
  },
  {
    "x": "arkerdiş (n)",
    "y": " utanma"
  },
  {
    "x": "arko, -ye",
    "y": " inatçı, inat"
  },
  {
    "x": "arkoy (m)",
    "y": " b. arkoye (m)"
  },
  {
    "x": "arkoyey (m)",
    "y": " b. arkoyî (m), arkoyîye (m)"
  },
  {
    "x": "arkoyê (m)",
    "y": " b. arkoyî (m), arkoyîye (m)"
  },
  {
    "x": "arkoyî (m)",
    "y": " inatçılık"
  },
  {
    "x": "arkoyîye (m)",
    "y": " inatçılık"
  },
  {
    "x": "arkozik (n)",
    "y": " ateş küreği"
  },
  {
    "x": "armator, -e",
    "y": " armatör"
  },
  {
    "x": "armenî, -ye",
    "y": " Ermeni"
  },
  {
    "x": "armenîkî (m)",
    "y": " Ermenice"
  },
  {
    "x": "Armenîstan (n)",
    "y": " Ermenistan"
  },
  {
    "x": "arminî, -ye",
    "y": " b. armenî, -ye"
  },
  {
    "x": "arminîkî (m)",
    "y": " b. armenîkî (m)"
  },
  {
    "x": "arn (m)",
    "y": " b. arre (m)"
  },
  {
    "x": "arnawid, -e",
    "y": " b. arnawud, -e"
  },
  {
    "x": "arnawud, -e",
    "y": " Arnavut"
  },
  {
    "x": "Arnawudîstan (n)",
    "y": " Arnavutluk"
  },
  {
    "x": "aroma (m)",
    "y": " aroma "
  },
  {
    "x": "aromatîk, -e",
    "y": " aromalı, aromatik"
  },
  {
    "x": "arosoz (n)",
    "y": " arazöz"
  },
  {
    "x": "aroş (n)",
    "y": " b. hargûş, -e"
  },
  {
    "x": "arozî (m)",
    "y": " çuvalın ağzını dikmek, çarık ve çorap bağlamak vb. için kullanılan kıldan yapılma ip"
  },
  {
    "x": "arre (m)",
    "y": " yemlik (hayvanlara yem verilen yer)"
  },
  {
    "x": "arri (m)",
    "y": " b. arre (m)"
  },
  {
    "x": "arriş (n)",
    "y": " b. ariş (n)"
  },
  {
    "x": "arsa (m)",
    "y": " b. ersa (m)"
  },
  {
    "x": "arsim (m)",
    "y": " b. harsime (m)"
  },
  {
    "x": "arsinî (n)",
    "y": " b. herçene (n)"
  },
  {
    "x": "arşîv (n)",
    "y": " arşiv"
  },
  {
    "x": "arşîv bîyene",
    "y": " arşivlenmek"
  },
  {
    "x": "arşîv kerdene",
    "y": " arşivlemek"
  },
  {
    "x": "arşîvbîyayîş (n)",
    "y": " arşivlenme"
  },
  {
    "x": "arşîvkar, -e",
    "y": " arşivci"
  },
  {
    "x": "arşîvkarîye (m)",
    "y": " arşivcilik"
  },
  {
    "x": "arşîvkerdiş (n)",
    "y": " arşivleme"
  },
  {
    "x": "artîkel (n)",
    "y": " artikel"
  },
  {
    "x": "artîst, -e",
    "y": " artist"
  },
  {
    "x": "artrogrîpozîs (n)",
    "y": " artrogripozis"
  },
  {
    "x": "arûş (n)",
    "y": " b. hargûş, -e"
  },
  {
    "x": "arûzi (m)",
    "y": " b. arozî (m)"
  },
  {
    "x": "arûzik (n)",
    "y": " sıcak mevsimlerde suda cırlak bir ses çıkaran bir su hayvanı arûziki (m):b. arûzik (n)"
  },
  {
    "x": "arvonçî (n)",
    "y": " b. arêwan (n)"
  },
  {
    "x": "arwana (m)",
    "y": " dişi deve"
  },
  {
    "x": "arwançî, -ye",
    "y": " b. arêwan, -e"
  },
  {
    "x": "arweş, -i",
    "y": " b. hargûş, -e"
  },
  {
    "x": "arwêş, -i",
    "y": " b. hargûş, -e"
  },
  {
    "x": "arwêz (m)",
    "y": " b. arozî (m)"
  },
  {
    "x": "arwûn (n)",
    "y": " b. arêwan (n)"
  },
  {
    "x": "arwûncî (n)",
    "y": " b. arêwan (n)"
  },
  {
    "x": "arwûnçî (n)",
    "y": " b. arêwan (n)"
  },
  {
    "x": "arye (n)",
    "y": " b. arê (n)"
  },
  {
    "x": "arye bîyene",
    "y": " b. arê dayene"
  },
  {
    "x": "arye kerdene",
    "y": " b. arê kerdene"
  },
  {
    "x": "aryebîyayîş (n)",
    "y": " b. arêbîyayîş (n)"
  },
  {
    "x": "aryedayîş (n)",
    "y": " b. arêdayîş (n)"
  },
  {
    "x": "aryedîyayîş (n)",
    "y": " b. arêdîyayîş (n)"
  },
  {
    "x": "aryekerda (m)",
    "y": " b. arêkerda (m)"
  },
  {
    "x": "aryekerde (n)",
    "y": " b. arêkerde (n)"
  },
  {
    "x": "aryekerdi (n)",
    "y": " b. arêkerde (n)"
  },
  {
    "x": "aryekerdiş (n)",
    "y": " b. arêkerdiş (n)"
  },
  {
    "x": "aryekerdox (n)",
    "y": " b. arêkerdox (n)"
  },
  {
    "x": "aryê (m)",
    "y": " b. arayî (m)"
  },
  {
    "x": "aryê xu kerdene",
    "y": " b.arayîya xo kerdene"
  },
  {
    "x": "arz kerdene",
    "y": " b. erz kerdene "
  },
  {
    "x": "arza (m)",
    "y": " b. arize-I (m)"
  },
  {
    "x": "arzele (n)",
    "y": " b. herzale-I (n)"
  },
  {
    "x": "arzêle-I (n)",
    "y": " yere çakılı kazıklar üzerine dizili direklerin üstüne istif edilmiş yaprak veya ot yığını"
  },
  {
    "x": "arzêle-II (n)",
    "y": " b. herzale-I (n)"
  },
  {
    "x": "arzî, -ye",
    "y": " b. arizî, -ye"
  },
  {
    "x": "arzkerdiş (n)",
    "y": " b. erzkerdiş (n)"
  },
  {
    "x": "AS (Ankilozan Spondili)",
    "y": " AS "
  },
  {
    "x": "asa (n)",
    "y": " b. asan (n)"
  },
  {
    "x": "asabî, -ye",
    "y": " b. esebî, -ye"
  },
  {
    "x": "asan, -e",
    "y": " kolay, rahat"
  },
  {
    "x": "asani (m)",
    "y": " b. asane (m)"
  },
  {
    "x": "asanî (m)",
    "y": " 1)kolaylık \r\n~2)rahatlık 2)konfor"
  },
  {
    "x": "asansor (n)",
    "y": " asansör"
  },
  {
    "x": "asansorkar, -e",
    "y": " asansörcü"
  },
  {
    "x": "asansorkarîye (m)",
    "y": " asansörcülük"
  },
  {
    "x": "asar",
    "y": " öylesi\r\n~asar kar (n): öylesi iş"
  },
  {
    "x": "asar kar (n)",
    "y": " öylesi iş"
  },
  {
    "x": "asarên, -e",
    "y": " öylesi\r\n~karo asarên (n): öylesi iş"
  },
  {
    "x": "karo asarên (n)",
    "y": " öylesi iş"
  },
  {
    "x": "asas (n)",
    "y": " b. esas (n) "
  },
  {
    "x": "asaye (n)",
    "y": " görüntü"
  },
  {
    "x": "asayene",
    "y": " görünmek"
  },
  {
    "x": "asayêne",
    "y": " b. asayene"
  },
  {
    "x": "asayîş-I (n) (TE)",
    "y": " güvenlik, emniyet, asayiş\r\n~asayîşê şewatî (n): yangın güvenliği\r\n~asayîşo cematkî (n): sosyal güvenlik, içtimai emniyet"
  },
  {
    "x": "asayîşê şewatî (n)",
    "y": " yangın güvenliği"
  },
  {
    "x": "asayîşo cematkî (n)",
    "y": " sosyal güvenlik, içtimai emniyet"
  },
  {
    "x": "asayîşo pêroyî (n)",
    "y": " genel güvenlik, genel asayiş, genel emniyet"
  },
  {
    "x": "asayîşo sosyal (n)",
    "y": " sosyal güvenlik, içtimai emniyet"
  },
  {
    "x": "asayîş-II (n)",
    "y": " görünme, görünüş\r\n~asayîşo birişkî (n): kesit görünüş"
  },
  {
    "x": "asayîşo birişkî (n)",
    "y": " kesit görünüş"
  },
  {
    "x": "asbest (n)",
    "y": " asbest"
  },
  {
    "x": "asbor (n)",
    "y": " b. espar (n)"
  },
  {
    "x": "ase (n)",
    "y": " taklit"
  },
  {
    "x": "aseyê ... kerdene",
    "y": " taklit etmek"
  },
  {
    "x": "aseyê ... kerdiş (n)",
    "y": " taklit etme"
  },
  {
    "x": "asebî, -ye",
    "y": " b. esebî, -ye"
  },
  {
    "x": "asê",
    "y": " asi, sarp"
  },
  {
    "x": "asfalt (n)",
    "y": " asfalt"
  },
  {
    "x": "asfalt kerdene",
    "y": " asfaltlamak"
  },
  {
    "x": "asfaltkerde (n)",
    "y": " asfalt"
  },
  {
    "x": "asfaltkerdiş (n)",
    "y": " asfaltlama"
  },
  {
    "x": "asfar (n)",
    "y": " b. hevsar (n)"
  },
  {
    "x": "asgarî",
    "y": " b. esxerî"
  },
  {
    "x": "asi (n)",
    "y": " b. asin (n)"
  },
  {
    "x": "asin (n)",
    "y": " demir"
  },
  {
    "x": "asinên, -e",
    "y": " demirden yapılma"
  },
  {
    "x": "asinin, -e",
    "y": " demirli"
  },
  {
    "x": "asinîn (n)",
    "y": " b. asinên (n)"
  },
  {
    "x": "asinkar, -e",
    "y": " demirci"
  },
  {
    "x": "asinkarîye (m)",
    "y": " demircilik"
  },
  {
    "x": "asinrayîr (n)",
    "y": " 1)demiryolu \r\n~2)ray "
  },
  {
    "x": "asinroş, -e",
    "y": " demirci (demir satan)"
  },
  {
    "x": "asiq, -e",
    "y": " b. aşiq, -e"
  },
  {
    "x": "asiq bîyayene",
    "y": " b. aşiq bîyene"
  },
  {
    "x": "asiq bîyayîne",
    "y": " b. aşiq bîyene"
  },
  {
    "x": "asîd (n)",
    "y": " asit"
  },
  {
    "x": "asîdo organîk (n)",
    "y": " organik asit"
  },
  {
    "x": "asîdmetre (n)",
    "y": " asidimetre, asitölçer"
  },
  {
    "x": "asîdpeym (n)",
    "y": " asidimetre, asitölçer"
  },
  {
    "x": "asîman (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "asîmetrî (n)",
    "y": " asimetri"
  },
  {
    "x": "asîmetrîk, -e",
    "y": " asimetrik, bakışımsız"
  },
  {
    "x": "asîmetrîkî (m)",
    "y": " bakışımsızlık"
  },
  {
    "x": "asîmîlasyon (n)",
    "y": " özümleme, asimilasyon\r\n~asîmîlasyonê eşteyî (n): atık özümlemesi"
  },
  {
    "x": "asîmîlasyonê eşteyî (n)",
    "y": " atık özümlemesi"
  },
  {
    "x": "asîmîle kerdene",
    "y": " özümlemek, asimile etmek"
  },
  {
    "x": "asîmon (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "asîst (n)",
    "y": " asist (sayı veya gol pası)"
  },
  {
    "x": "asîstan, -e",
    "y": " asistan"
  },
  {
    "x": "asîstanîye (m)",
    "y": " asistanlık"
  },
  {
    "x": "asîyayîş (n)",
    "y": " b. asayîş-II (n)"
  },
  {
    "x": "ask, -e",
    "y": " ceylan"
  },
  {
    "x": "aska (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "Aske (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "Aski (m)",
    "y": " b. Aske (m)"
  },
  {
    "x": "askefte (m)",
    "y": " b. şikefte (m), eşkefte (m)"
  },
  {
    "x": "asker, -e",
    "y": " b. esker, -e"
  },
  {
    "x": "asl (n)",
    "y": " b. esl (n)"
  },
  {
    "x": "aslan, -e",
    "y": " b. şêr, -e-I"
  },
  {
    "x": "aslon, -i",
    "y": " b. şêr, -e-I"
  },
  {
    "x": "Asmara",
    "y": " Asmara"
  },
  {
    "x": "asme-I (m)",
    "y": " b. aşme (m)"
  },
  {
    "x": "asma newîye (m)",
    "y": " b. aşma newîye (m)"
  },
  {
    "x": "asme-II (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "asmen (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "asmê (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "asmên (n)",
    "y": " gök, gökyüzü, felek\r\n~asmênê fekî (n): damak"
  },
  {
    "x": "asmênê fekî (n)",
    "y": " damak"
  },
  {
    "x": "asmênnas, -e",
    "y": " gökbilimci, astronom "
  },
  {
    "x": "asmênnasî (m)",
    "y": " gökbilim, astronomi, felekiyat"
  },
  {
    "x": "asmêr (m)",
    "y": " b. asmêre (m)"
  },
  {
    "x": "asmêre (m)",
    "y": " dalları çardak üzerine yayılan asma"
  },
  {
    "x": "asmi (m)",
    "y": " b. aşme (m)"
  },
  {
    "x": "asmî (m)",
    "y": " b. aşme (m)"
  },
  {
    "x": "asmî (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "asmîn (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "asmîye (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "asmîyen (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "asmye (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "asmyen (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "asnaber, -e",
    "y": " b. asnawber, -e"
  },
  {
    "x": "asnaberî (m)",
    "y": " b. asnawberî (m)"
  },
  {
    "x": "asnaw (m)",
    "y": " b. asnawe (m)"
  },
  {
    "x": "asnaw kerdene",
    "y": " b. asnawe kerdene"
  },
  {
    "x": "asnawber, -e",
    "y": " yüzücü"
  },
  {
    "x": "asnawberênî (m)",
    "y": " yüzücülük"
  },
  {
    "x": "asnawberî (m)",
    "y": " yüzücülük"
  },
  {
    "x": "asnawberîye (m)",
    "y": " yüzücülük"
  },
  {
    "x": "asnawe (m)",
    "y": " yüzme"
  },
  {
    "x": "asnawe kerdene",
    "y": " yüzmek, çimmek"
  },
  {
    "x": "asnawekerdiş (n)",
    "y": " yüzme, çimme"
  },
  {
    "x": "asnawer, -i",
    "y": " b. asnawber, -e"
  },
  {
    "x": "asnawerey (m)",
    "y": " b. asnawberî (m)"
  },
  {
    "x": "asnawi (m)",
    "y": " b. asnawe (m)"
  },
  {
    "x": "asnawi kerdene",
    "y": " b. asnawe kerdene"
  },
  {
    "x": "asnawikerdiş (n)",
    "y": " b. asnawekerdiş (n)"
  },
  {
    "x": "asnawî (m)",
    "y": " b. asnawe (m)"
  },
  {
    "x": "asnawî kerdene",
    "y": " b. asnawe kerdene"
  },
  {
    "x": "asnawîkerdiş (n)",
    "y": " b. asnawekerdiş (n)"
  },
  {
    "x": "asnawkerdiş (n)",
    "y": " b. asnawekerdiş (n)"
  },
  {
    "x": "asno (m)",
    "y": " b. asnawe (m)"
  },
  {
    "x": "asnober",
    "y": " b. asnawber, -e"
  },
  {
    "x": "aso-II (n)",
    "y": " ufuk"
  },
  {
    "x": "aso-II (n)",
    "y": " b. asan (n)"
  },
  {
    "x": "ason, -i",
    "y": " b. asan, -e"
  },
  {
    "x": "asosyal, -e",
    "y": " asosyal"
  },
  {
    "x": "asosyalîye (m)",
    "y": " asosyallik"
  },
  {
    "x": "aspar, -e",
    "y": " b. espar, -e"
  },
  {
    "x": "asparagase (m)",
    "y": " asparagas"
  },
  {
    "x": "asparbîyayîş (n)",
    "y": " b. esparbîyayîş (n)"
  },
  {
    "x": "aspic (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "aspicî ci kewtene",
    "y": " b. espijî ci kewtene"
  },
  {
    "x": "aspicî dekewtene",
    "y": " b. espijî dekewtene"
  },
  {
    "x": "aspici (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "aspicin, -i",
    "y": " b. espijin, -e"
  },
  {
    "x": "aspicîcikewtiş (n)",
    "y": " b. espijîcikewtiş (n)"
  },
  {
    "x": "aspicîdekewtiş (n)",
    "y": " b. espijîdekewtiş (n)"
  },
  {
    "x": "aspij (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "aspije (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "aspijin, -e",
    "y": " b. espijin, -e"
  },
  {
    "x": "aspijini (m)",
    "y": " b. espijin, -e"
  },
  {
    "x": "aspize (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "aspizin, -e",
    "y": " b. espijin, -e"
  },
  {
    "x": "aspîrator (n)",
    "y": " emmeç, aspiratör\r\n~aspîratorê talkerdişê dumanî (n): duman tahliye aspiratörü\r\n~aspîratorê vengkerdişê dumanî (n): duman tahliye aspiratörü"
  },
  {
    "x": "aspîratorê talkerdişê dumanî (n)",
    "y": " duman tahliye aspiratörü"
  },
  {
    "x": "aspîratorê vengkerdişê dumanî (n)",
    "y": " duman tahliye aspiratörü"
  },
  {
    "x": "aspor, -e",
    "y": " b. espar, -e"
  },
  {
    "x": "assosîasyon (n)",
    "y": " çağrışım"
  },
  {
    "x": "astamî (m)",
    "y": " ateş küreği, gelberi, köseği, evirgeç"
  },
  {
    "x": "Astana (m)",
    "y": " Astana (Akmola)"
  },
  {
    "x": "astar-I (n)",
    "y": " astar"
  },
  {
    "x": "astar, -e-II",
    "y": " b. estor, -e"
  },
  {
    "x": "astare (n)",
    "y": " b. estare (n)"
  },
  {
    "x": "astareke (m)",
    "y": " b. estareke (m)"
  },
  {
    "x": "astarelîlik (n)",
    "y": " b. estarelîlik (n)"
  },
  {
    "x": "astari (n)",
    "y": " b. estare (n)"
  },
  {
    "x": "astarin, -e",
    "y": " astarlı"
  },
  {
    "x": "astarkerda (m)",
    "y": " astarlı"
  },
  {
    "x": "astarkerde (n)",
    "y": " astarlı"
  },
  {
    "x": "astarkerdê (m)",
    "y": " b. estarkerda (m), astarkerdîye (m)"
  },
  {
    "x": "astarkerdi (n)",
    "y": " b. astarkerde (n)"
  },
  {
    "x": "astarkerdîye (m)",
    "y": " astarlı"
  },
  {
    "x": "astawuirn (m)",
    "y": " b. estewrine (m)"
  },
  {
    "x": "aste –I (n)",
    "y": " b. este-I (n)"
  },
  {
    "x": "aste -II (n)",
    "y": " b. astî (n)"
  },
  {
    "x": "asteng (n)",
    "y": " engel, mâni\r\n~astengo fîzyolojîk (n): fizyolojik engel"
  },
  {
    "x": "astengo fîzyolojîk (n)",
    "y": " fizyolojik engel"
  },
  {
    "x": "astengdar, -e",
    "y": " engelli, sakat, mânialı\r\n~astengdara/ê eşnawitişî: işitme engelli\r\n~astengdara/ê qalîkerdişî: konuşma engelli\r\n~astengdaro ortopedîk (n): ortopedik engelli"
  },
  {
    "x": "astengdar bîyene",
    "y": " sakatlanmak"
  },
  {
    "x": "astengdara mentale (m)",
    "y": " zihinsel engelli"
  },
  {
    "x": "astengdara ortopedîke (m)",
    "y": " ortopedik engelli"
  },
  {
    "x": "astengdara zihnî (m)",
    "y": " zihinsel engelli"
  },
  {
    "x": "astengdara/ê dîyayîşî",
    "y": " görme engelli, görmez, gözsüz"
  },
  {
    "x": "astengdara/ê eşnawitişî",
    "y": " işitme engelli"
  },
  {
    "x": "astengdara/ê hesnayîşî",
    "y": " işitme engelli"
  },
  {
    "x": "astengdara/ê qalîkerdişî",
    "y": " konuşma engelli"
  },
  {
    "x": "astengdara/ê qiseykerdişî",
    "y": " konuşma engelli"
  },
  {
    "x": "astengdara spastîke (m)",
    "y": " spastik engelli"
  },
  {
    "x": "astengdara/ê vînayîşî",
    "y": " b. astengdara/ê dîyayîşî"
  },
  {
    "x": "astengdaro mental (n)",
    "y": " zihinsel engelli"
  },
  {
    "x": "astengdaro ortopedîk (n)",
    "y": " ortopedik engelli"
  },
  {
    "x": "astengdaro spastîk (n)",
    "y": " spastik engelli"
  },
  {
    "x": "astengdaro zihnî (n)",
    "y": " zihinsel engelli"
  },
  {
    "x": "astengdarbîyayîş (n)",
    "y": " sakatlanma"
  },
  {
    "x": "astengdarîye (m)",
    "y": " engellilik\r\n~astengdarîya rayîrraşîyayîşî (n): yürüme engellilik"
  },
  {
    "x": "astengdarîya rayîrraşîyayîşî (n)",
    "y": " yürüme engellilik"
  },
  {
    "x": "astengdarîya rayşîyayîşî (n)",
    "y": " yürüme engellilik"
  },
  {
    "x": "astengdarîya fîzyolojîke (m)",
    "y": " fizyolojik engellilik"
  },
  {
    "x": "astere (n)",
    "y": " b. estare (n)"
  },
  {
    "x": "astereke (m)",
    "y": " b. estareke (m)"
  },
  {
    "x": "astewre (m)",
    "y": " b. estewre (m)"
  },
  {
    "x": "astewrine (m)",
    "y": " b. estewrine (m)"
  },
  {
    "x": "asti (n)",
    "y": " b. este-I (n)"
  },
  {
    "x": "astik-I (n)",
    "y": " b. este-I (n)"
  },
  {
    "x": "astik-II (n)",
    "y": " b. astî (n)"
  },
  {
    "x": "astim (n)",
    "y": " astım"
  },
  {
    "x": "astiwar",
    "y": " b. estor, -e"
  },
  {
    "x": "astir (n)-I",
    "y": " kıldan yapılan bir çeşit basit kilim"
  },
  {
    "x": "astir (m)-II",
    "y": " b. hesîre (m)"
  },
  {
    "x": "astî (n)",
    "y": " elbise kolu, giysi kolu, yen\r\n~astîyê çakêtî/şakî/setreyî (n): ceket kolu\r\n~astîyo derg (n): salma (uzun ve sarkık yen)"
  },
  {
    "x": "astîyê çakêtî/şakî/setreyî (n)",
    "y": " ceket kolu"
  },
  {
    "x": "astîyê xo to kerdene",
    "y": " kollarını çemremek¸ kollarını sıvamak, yenlerini sıvamak"
  },
  {
    "x": "astîyî to kerdene",
    "y": " kolları çemremek, kolları sıvamak, yenleri sıvamak"
  },
  {
    "x": "astîyo derg (n)",
    "y": " salma (uzun ve sarkık yen)"
  },
  {
    "x": "astma (m)",
    "y": " b. astim (n)"
  },
  {
    "x": "astomî (m)",
    "y": " b. astamî (m)"
  },
  {
    "x": "astor, -e",
    "y": " b. estor, -e"
  },
  {
    "x": "astor/astore fetesnayene",
    "y": " b. estor/estore fetisnayene"
  },
  {
    "x": "astor/astore visnayene",
    "y": " b. estor/estore visnayene"
  },
  {
    "x": "astuar beacnayene",
    "y": " b. estor/estore bacnayene"
  },
  {
    "x": "astori (m)",
    "y": " b. estore (m)"
  },
  {
    "x": "astorin (m)",
    "y": " b. estewrine (m)"
  },
  {
    "x": "astoriney (m)",
    "y": " b. estewrinîye (m)"
  },
  {
    "x": "astorinê (m)",
    "y": " b. estewrinîye (m)"
  },
  {
    "x": "astrofîzîk (n)",
    "y": " gökfiziği, astrofizik"
  },
  {
    "x": "astrolog, -e",
    "y": " astrolog, müneccim"
  },
  {
    "x": "astrolojî (n)",
    "y": " astroloji"
  },
  {
    "x": "astronom, -e",
    "y": " gökbilimci, astronom"
  },
  {
    "x": "astronomî (m)",
    "y": " gökbilim, astronomi, felekiyat"
  },
  {
    "x": "astronot, -e",
    "y": " uzayadamı, astronot"
  },
  {
    "x": "astronotîye (m)",
    "y": " astronotluk"
  },
  {
    "x": "astuar (n)",
    "y": " b. estor (n)"
  },
  {
    "x": "astuer (n)",
    "y": " b. estor (n)"
  },
  {
    "x": "astuwar (n)",
    "y": " b. estor (n)"
  },
  {
    "x": "astûm (m)",
    "y": " b. astamî (m)"
  },
  {
    "x": "astûmî (m)",
    "y": " b. astamî (m)"
  },
  {
    "x": "astwuirn (m)",
    "y": " b. estewrine (m)"
  },
  {
    "x": "Asunsîyon (n)",
    "y": " Asuncion"
  },
  {
    "x": "asû (n)",
    "y": " b. asan"
  },
  {
    "x": "asûn, -e",
    "y": " b. asan, -e"
  },
  {
    "x": "asûni (m)",
    "y": " b. asane (m)"
  },
  {
    "x": "asvancî, -ye",
    "y": " b. asvançî, -ye"
  },
  {
    "x": "asvançî, -ye",
    "y": " değirmenci"
  },
  {
    "x": "asvar (n)",
    "y": " b. hevsar (n)"
  },
  {
    "x": "Asya (m)",
    "y": " Asya"
  },
  {
    "x": "asyayîne",
    "y": " b. asayene"
  },
  {
    "x": "asyayîş (n)",
    "y": " b. asayîş-II (n)"
  },
  {
    "x": "aşil, -e",
    "y": " b. kesk, -e; hewz, -e-I"
  },
  {
    "x": "aşim (m)",
    "y": " b. aşme (m)"
  },
  {
    "x": "aşiq, -e",
    "y": " âşık"
  },
  {
    "x": "aşiq bîyene",
    "y": " âşık olmak"
  },
  {
    "x": "aşiqê yewbînan bîyene",
    "y": " birbirine âşık olmak"
  },
  {
    "x": "aşiqê yewbînî bîyene",
    "y": " birbirine âşık olmak"
  },
  {
    "x": "edebîyatê aşiqan (n)",
    "y": " âşık edebiyatı "
  },
  {
    "x": "aşir (m)",
    "y": " b. eşîre (m)"
  },
  {
    "x": "aşitî (m)",
    "y": " b. aştî-I (m):barış, sulh"
  },
  {
    "x": "aşîl, -e",
    "y": " b. kesk, -e; hewz, -e-I"
  },
  {
    "x": "aşîr-I (m)",
    "y": " b. eşîre (m)"
  },
  {
    "x": "aşîr-II (m)",
    "y": " b. aşîre (m)"
  },
  {
    "x": "aşîre-I (m)",
    "y": " küçük tuluk"
  },
  {
    "x": "aşîre-II (m)",
    "y": " b. eşîre (m)"
  },
  {
    "x": "aşît (n)",
    "y": " çığ"
  },
  {
    "x": "aşîtî (m)",
    "y": " b. aştî-I (m):barış, sulh"
  },
  {
    "x": "aşkera (s)",
    "y": " b. eşkera (s)"
  },
  {
    "x": "aşkere (s)",
    "y": " b. eşkera (s)"
  },
  {
    "x": "aşle (n)",
    "y": " aşı (bitki için)"
  },
  {
    "x": "aşm (m)",
    "y": " b. aşme (m)"
  },
  {
    "x": "aşmane (m)",
    "y": " aidat, ödenti"
  },
  {
    "x": "aşmaqê fekî (n)",
    "y": " damak"
  },
  {
    "x": "aşme (m)",
    "y": " 1)ay, kamer\r\n~aşma newa (m): yeni ay\r\n~aşma newîye (m): yeni ay\r\n~2)ay, yılın on iki bölümünden her biri"
  },
  {
    "x": "aşma newa (m)",
    "y": " yeni ay"
  },
  {
    "x": "aşma newê (m)",
    "y": " b. aşma newîye (m)"
  },
  {
    "x": "aşma newî (m)",
    "y": " b. aşma newîye (m)"
  },
  {
    "x": "aşma newîye (m)",
    "y": " yeni ay"
  },
  {
    "x": "aşmi (m)",
    "y": " b. aşme (m)"
  },
  {
    "x": "aşmî (m)",
    "y": " b. aşme (m)"
  },
  {
    "x": "aşn bîyayene",
    "y": " b. aşt bîyene"
  },
  {
    "x": "aşnawiteni",
    "y": " b. eşnawitene"
  },
  {
    "x": "aşnawitiş (n)",
    "y": " b. eşnawitiş (n)"
  },
  {
    "x": "Aşqele",
    "y": " Aşkale"
  },
  {
    "x": "aşt-I",
    "y": " b. wet"
  },
  {
    "x": "aşt a",
    "y": " b. wet ra"
  },
  {
    "x": "aşt û naşt",
    "y": " b. nat û wet"
  },
  {
    "x": "aşt, -e-II",
    "y": " barışık"
  },
  {
    "x": "aşt bîyene",
    "y": " barışmak"
  },
  {
    "x": "aşt kerdene",
    "y": " barıştırmak"
  },
  {
    "x": "aşta",
    "y": " b. weta"
  },
  {
    "x": "aştbîyayîş (n)",
    "y": " barışma"
  },
  {
    "x": "aştî-I (m)",
    "y": " barış, sulh"
  },
  {
    "x": "aştî-II (n)",
    "y": " b. astî (n)"
  },
  {
    "x": "aştîyo derg (n)",
    "y": " b. astîyo derg (n)"
  },
  {
    "x": "aştîperwer, -e",
    "y": " barışsever"
  },
  {
    "x": "aştîperwerîye (m)",
    "y": " barışseverlik"
  },
  {
    "x": "aştîwaz, -e",
    "y": " barışsever"
  },
  {
    "x": "aştîwazîye (m)",
    "y": " barışseverlik "
  },
  {
    "x": "aştîye (m)",
    "y": " barış, sulh"
  },
  {
    "x": "aştkerdiş (n)",
    "y": " barıştırma"
  },
  {
    "x": "aşura (m)",
    "y": " b. aşûre (n)"
  },
  {
    "x": "aşûre (n)",
    "y": " aşure"
  },
  {
    "x": "aşurme-I (n)",
    "y": " fişeklik, kargılık"
  },
  {
    "x": "aşurme-II (n)",
    "y": " Gımgım (Varto) bölgesinde oynanan bir halay çeşidi"
  },
  {
    "x": "aşûre (n)",
    "y": " aşure"
  },
  {
    "x": "aşxane (n)",
    "y": " lokanta, aşevi, aşhane, restoran"
  },
  {
    "x": "aşxanedar, -e",
    "y": " lokantacı"
  },
  {
    "x": "aşxanedarî (m)",
    "y": " lokantacılık"
  },
  {
    "x": "aşxanedarîye (m)",
    "y": " lokantacılık"
  },
  {
    "x": "aşxone (n)",
    "y": " b. aşxane (n)"
  },
  {
    "x": "aşxûne (n)",
    "y": " b. aşxane (n)"
  },
  {
    "x": "at (m)",
    "y": " b. ate (m)"
  },
  {
    "x": "ata",
    "y": " b. oweta"
  },
  {
    "x": "ataşe, -ye",
    "y": " ataşe\r\n~ataşeyê kulturî (n): kültür ataşesi\r\n~ataşeyo leşkerî (n): askeri ataşe"
  },
  {
    "x": "ataşeya kulturî (m)",
    "y": " kültür ataşesi"
  },
  {
    "x": "ataşeyê kulturî (n)",
    "y": " kültür ataşesi"
  },
  {
    "x": "ataşeya leşkerîye (m)",
    "y": " askeri ataşe"
  },
  {
    "x": "ataşeyo leşkerî (n)",
    "y": " askeri ataşe"
  },
  {
    "x": "ataşegeh (n)",
    "y": " ataşelik"
  },
  {
    "x": "ataşeyî (m)",
    "y": " ataşelik"
  },
  {
    "x": "ataşeyîye (m)",
    "y": " ataşelik"
  },
  {
    "x": "ate (m)",
    "y": " abla"
  },
  {
    "x": "atê",
    "y": " 1)abla (hitap hali) \r\n~2)b atêyî (m)"
  },
  {
    "x": "ateîst, -e",
    "y": " Allahsız, tanrısız"
  },
  {
    "x": "ateîstî (m)",
    "y": " Allahsızlık, tanrısızlık"
  },
  {
    "x": "ateîstîye (m)",
    "y": " Allahsızlık, tanrısızlık"
  },
  {
    "x": "ateqnayîş (n)",
    "y": " b. aqitnayîş (n)"
  },
  {
    "x": "atêyey (m)",
    "y": " b atêyî (m)"
  },
  {
    "x": "atêyî (m)",
    "y": " ablalık"
  },
  {
    "x": "ati (m)",
    "y": " b. ate (m)"
  },
  {
    "x": "atik (m)",
    "y": " b. atike (m)"
  },
  {
    "x": "atike (m)",
    "y": " abla"
  },
  {
    "x": "atikê",
    "y": " 1)abla (hitap hali) \r\n~2)b. atikîye (m)"
  },
  {
    "x": "atikey (m)",
    "y": " b. atikîye (m)"
  },
  {
    "x": "atiki (m)",
    "y": " b. atike (m)"
  },
  {
    "x": "atikî (m)",
    "y": " ablalık"
  },
  {
    "x": "atikîye (m)",
    "y": " ablalık"
  },
  {
    "x": "atile (n)",
    "y": " ara, fasıla"
  },
  {
    "x": "atile dayene",
    "y": " ara vermek, fasıla vermek"
  },
  {
    "x": "atisîyayene",
    "y": " afallamak"
  },
  {
    "x": "Atîna (m)",
    "y": " Atina"
  },
  {
    "x": "atlas (n)",
    "y": " atlas (bir tür kumaş)"
  },
  {
    "x": "atlase (m)",
    "y": " atlas (haritalar kitabı) "
  },
  {
    "x": "atle (n)",
    "y": " b. atile (n)"
  },
  {
    "x": "atle dayene",
    "y": " b. atile dayene"
  },
  {
    "x": "atlet (n)",
    "y": " atlet (kolsuz erkek fanilası)"
  },
  {
    "x": "atlet, -e",
    "y": " atlet"
  },
  {
    "x": "atlete (m)",
    "y": " b. atlet (n)"
  },
  {
    "x": "atletîk, -e",
    "y": " atletik"
  },
  {
    "x": "atletîzm (n)",
    "y": " atletizm"
  },
  {
    "x": "atmije (n)",
    "y": " atmaca"
  },
  {
    "x": "atmosfer (n)",
    "y": " ortam (mecazi), atmosfer\r\n~atmosferê xebate (n): çalışma ortamı"
  },
  {
    "x": "atmosferê xebate (n)",
    "y": " çalışma ortamı"
  },
  {
    "x": "atolye (m)",
    "y": " atölye, atelye, işlik\r\n~atolya camî (m): cam atölyesi"
  },
  {
    "x": "atolya camî (m)",
    "y": " cam atölyesi"
  },
  {
    "x": "atolya lewhayanê trafîkî (m)",
    "y": " trafik levha atölyesi"
  },
  {
    "x": "atolya sînema (m)",
    "y": " sinema atölyesi"
  },
  {
    "x": "atolya tamîrî (m)",
    "y": " tamir atölyesi"
  },
  {
    "x": "atome (m)",
    "y": " atom"
  },
  {
    "x": "atqî (m)",
    "y": " atkı, kaşkol"
  },
  {
    "x": "atqîye (m)",
    "y": " atkı, kaşkol"
  },
  {
    "x": "Avacuxe (m)",
    "y": " b. Pulur (n)"
  },
  {
    "x": "avanax, -e",
    "y": " ahmak, aptal, avanak, salak"
  },
  {
    "x": "avanax bîyene",
    "y": " ahmaklaşmak"
  },
  {
    "x": "avanax kerdene",
    "y": " ahmaklaştırmak"
  },
  {
    "x": "avanaxbîyayîş (n)",
    "y": " ahmaklaşma"
  },
  {
    "x": "avanaxîye (m)",
    "y": " ahmaklık, aptallık, avanaklık, salaklık"
  },
  {
    "x": "avanaxkerdiş (n)",
    "y": " ahmaklaştırma"
  },
  {
    "x": "avangard, -e",
    "y": " öncü, avangart"
  },
  {
    "x": "avar de",
    "y": " b. vor de"
  },
  {
    "x": "avans (n)",
    "y": " avans "
  },
  {
    "x": "avdas (n)",
    "y": " b. awdest (n)"
  },
  {
    "x": "avdaz (n)",
    "y": " b. awdest (n)"
  },
  {
    "x": "avdel (n)",
    "y": " b. evdal (n)"
  },
  {
    "x": "avdelîye (m)",
    "y": " b. evdalîye (m)"
  },
  {
    "x": "ave",
    "y": " b. aver (n)"
  },
  {
    "x": "ave berdene",
    "y": " b. aver berdene"
  },
  {
    "x": "ave şîyayene",
    "y": " b. aver şîyene "
  },
  {
    "x": "ave şîyene",
    "y": " b. aver şîyene"
  },
  {
    "x": "aver (n)",
    "y": " ileri"
  },
  {
    "x": "aver bardene",
    "y": " b. aver berdene"
  },
  {
    "x": "aver berdene",
    "y": " ilerletmek, geliştirmek"
  },
  {
    "x": "aver şîyayene",
    "y": " b. aver şîyene"
  },
  {
    "x": "aver şîyene",
    "y": " ilerlemek, gelişmek"
  },
  {
    "x": "averberdiş (n)",
    "y": " ilerletme, ilerleme; geliştirme"
  },
  {
    "x": "averperwer, -e",
    "y": " ilerici"
  },
  {
    "x": "averşîyayîş (n)",
    "y": " 1)ilerleme \r\n~2)gelişme, gelişim\r\n~averşîyayîşê kapasîte (n): kapasite gelişimi"
  },
  {
    "x": "averşîyayîşê kapasîte (n)",
    "y": " kapasite gelişimi"
  },
  {
    "x": "averwaz, -e",
    "y": " ilerici"
  },
  {
    "x": "avey",
    "y": " b. aver (n)"
  },
  {
    "x": "avê",
    "y": " b. aver (n)"
  },
  {
    "x": "avê berdene",
    "y": " b. aver berdene"
  },
  {
    "x": "avê şîyayene",
    "y": " b. aver şîyene"
  },
  {
    "x": "avêberdiş (n)",
    "y": " b. averberdiş (n)"
  },
  {
    "x": "avêr",
    "y": " b. aver (n)"
  },
  {
    "x": "avêşîyayîş (n)",
    "y": " b. averşîyayîş (n)"
  },
  {
    "x": "avgar (n)",
    "y": " karık (küçük ark)"
  },
  {
    "x": "aviliyayîne",
    "y": " b. rabilîyayene"
  },
  {
    "x": "aviqat, -e",
    "y": " b. avûkat, -e"
  },
  {
    "x": "avirnayîş (n)",
    "y": " b. abirnayîş (n)"
  },
  {
    "x": "avise (m)",
    "y": " gebe (hayvan için)"
  },
  {
    "x": "avise bîyene",
    "y": " gebe olmak (hayvan için)"
  },
  {
    "x": "avisey (m)",
    "y": " b. avisîye (m)"
  },
  {
    "x": "avisi (m)",
    "y": " b. avise (m)"
  },
  {
    "x": "avisi bîyayene",
    "y": " b. avise bîyene"
  },
  {
    "x": "avisî (m)",
    "y": " b. avisîye (m)"
  },
  {
    "x": "avisîye (m)",
    "y": " gebelik (hayvan için)"
  },
  {
    "x": "avista (m)",
    "y": " serili (çamaşır vb. için)"
  },
  {
    "x": "aviste (n)",
    "y": " serili (çamaşır vb. için)"
  },
  {
    "x": "avistene",
    "y": " ağdırmak, sermek (çamaşır vb. için)"
  },
  {
    "x": "avistiş (n)",
    "y": " ağdırma, serme (çamaşır vb. için)"
  },
  {
    "x": "avistîye (m)",
    "y": " serili (çamaşır vb. için)"
  },
  {
    "x": "avîze (m)",
    "y": " avize"
  },
  {
    "x": "avlêr",
    "y": " b. vîyale (m)"
  },
  {
    "x": "avor de",
    "y": " b. vor de"
  },
  {
    "x": "avor ro",
    "y": " b. vor ro"
  },
  {
    "x": "avrançî, -ye",
    "y": " b. arêwan, -e"
  },
  {
    "x": "avrej (n)",
    "y": " b. avrêj (n)"
  },
  {
    "x": "avrêj (n)",
    "y": " su sızıntısı olan yer/toprak "
  },
  {
    "x": "avrêjî (m)",
    "y": " b. arozî (m)"
  },
  {
    "x": "avrênc (m)",
    "y": " b. arozî (m)"
  },
  {
    "x": "avrêncî (m)",
    "y": " b. arozî (m)"
  },
  {
    "x": "avrês (m)",
    "y": " b. arozî (m)"
  },
  {
    "x": "avrêzî (m)",
    "y": " b. arozî (m)"
  },
  {
    "x": "avring (n)",
    "y": " b. hevring (n)"
  },
  {
    "x": "avrîng (n)",
    "y": " b. hevring (n)"
  },
  {
    "x": "avrîng kerdene",
    "y": " b. hevring kerdene"
  },
  {
    "x": "avronçî, -ye",
    "y": " b. arêwan, -e"
  },
  {
    "x": "avsancî, -ye",
    "y": " b. asvançî, -ye"
  },
  {
    "x": "avsûn (n)",
    "y": " b. efsûn (n)"
  },
  {
    "x": "avşancî, -ye",
    "y": " b. asvançî, -ye"
  },
  {
    "x": "avşar (n)",
    "y": " b. hevsar (n)"
  },
  {
    "x": "avûkat, -e",
    "y": " avukat"
  },
  {
    "x": "avûkatî (m)",
    "y": " avukatlık"
  },
  {
    "x": "avûkatîye (m)",
    "y": " avukatlık"
  },
  {
    "x": "avzel, -e",
    "y": " önemli (s), başlıca (s)"
  },
  {
    "x": "aw-I (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "aw fek kewtene",
    "y": " b. awe fek kewtene"
  },
  {
    "x": "aw fek ti şîyayene",
    "y": " b. awe fek de şîyene"
  },
  {
    "x": "aw pira nayene",
    "y": " b. awe pira nayene"
  },
  {
    "x": "aw û wer",
    "y": " b. awe û werd"
  },
  {
    "x": "aw veradayene ... ser",
    "y": " b. awe veradayene ... ser"
  },
  {
    "x": "aw xu ro kerdene",
    "y": " b. awe xo ro kerdene"
  },
  {
    "x": "aw xue ri kerdene",
    "y": " b. awe xo ro kerdene"
  },
  {
    "x": "aw xwe ro kerdene",
    "y": " b. awe xo ro kerdene"
  },
  {
    "x": "aw xwi ro kerdene",
    "y": " b. awe xo ro kerdene"
  },
  {
    "x": "awê bacikan (m)",
    "y": " b. awa bacikan (m)"
  },
  {
    "x": "awê bazilcûnû (m)",
    "y": " b. awa firingîyan (m)"
  },
  {
    "x": "awê binerdî (zh)",
    "y": " yer altı suları"
  },
  {
    "x": "awê binhardî (zh)",
    "y": " b. awê binerdî (zh)"
  },
  {
    "x": "awê binherdî (zh)",
    "y": " b. awê binerdî (zh)"
  },
  {
    "x": "awê hinaron (m)",
    "y": " b. awa henaran (m)"
  },
  {
    "x": "awê îsotî (m)",
    "y": " b. awa îsotan (m)"
  },
  {
    "x": "aw-II (n, z)",
    "y": " b. o (n, z)\r\n~Aw vano. (O der/söyler.)"
  },
  {
    "x": "aw-III (n, s)",
    "y": " b. o (n, s)\r\n~Aw merdim feqîr o. (O adam yoksuldur.)"
  },
  {
    "x": "aw bîn",
    "y": " öbürü, diğeri"
  },
  {
    "x": "aw perr",
    "y": " o taraf"
  },
  {
    "x": "aw pol",
    "y": " o taraf"
  },
  {
    "x": "aw sebeb ra",
    "y": " b. ê sebebî ra"
  },
  {
    "x": "aw semed a",
    "y": " b. ê semedî ra"
  },
  {
    "x": "aw semed ra",
    "y": " b. ê semedî ra"
  },
  {
    "x": "aw zeman",
    "y": " o zaman, o vakit"
  },
  {
    "x": "awa-I (n)",
    "y": " b. hawa-I (n)"
  },
  {
    "x": "awa-II",
    "y": " b. ayhawa"
  },
  {
    "x": "awa-III (m)",
    "y": " b. eba (m)"
  },
  {
    "x": "awan-I (n)",
    "y": " inşa"
  },
  {
    "x": "awan kerdene",
    "y": " 1)inşa etmek \r\n~2)kurmak (fabrika vb. için)"
  },
  {
    "x": "awan, -e-II",
    "y": " bayındır (s)"
  },
  {
    "x": "awanî (m)",
    "y": " 1)inşaat, imar, yapı\r\n~awanîya qeydkerda (m): tescilli yapı\r\n~awanîya rîskdare (m): riskli yapı\r\n~2)konstrüksiyon"
  },
  {
    "x": "awanîya abîdeyîye (m)",
    "y": " anıtsal yapı"
  },
  {
    "x": "awanîya bêruxsete (m)",
    "y": " ruhsasız yapı"
  },
  {
    "x": "awanîya pêroyîye (m)",
    "y": " kamusal yapı"
  },
  {
    "x": "awanîya qaçaxe (m)",
    "y": " kaçak yapı"
  },
  {
    "x": "awanîya qeydkerda (m)",
    "y": " tescilli yapı"
  },
  {
    "x": "awanîya qeydkerdîye (m)",
    "y": " tescilli yapı "
  },
  {
    "x": "awanîya rîskdare (m)",
    "y": " riskli yapı"
  },
  {
    "x": "awanîya tescîlkerda (m)",
    "y": " tescilli yapı"
  },
  {
    "x": "awanîya tescîlkerdîye (m)",
    "y": " tescilli yapı"
  },
  {
    "x": "awanîya umûmîye (m)",
    "y": " kamusal yapı"
  },
  {
    "x": "awanîkar, -e",
    "y": " inşaatçı"
  },
  {
    "x": "awanîkerdiş (n)",
    "y": " konfigürasyon, yapılandırma"
  },
  {
    "x": "awanîkerdişê sîstemî (n)",
    "y": " sistem konfigürasyon, sistem yapılandırması"
  },
  {
    "x": "awanîye (m)",
    "y": " 1)bayındırlık \r\n~2)inşaat"
  },
  {
    "x": "awanîya betonarmeyî (m)",
    "y": " betonarme inşaat"
  },
  {
    "x": "awankerdiş (n)",
    "y": " inşa etme\r\n~awankerdişê cumle: cümlenin yapısı"
  },
  {
    "x": "awankerdişê cumle",
    "y": " cümlenin yapısı"
  },
  {
    "x": "awankerdox, -e",
    "y": " kurucu"
  },
  {
    "x": "awanşîyayîş (n)",
    "y": " batma (güneş, ay vb. için)"
  },
  {
    "x": "aware bîyayîne",
    "y": " b. eware bîyene"
  },
  {
    "x": "aware kerdene",
    "y": " b. eware kerdene"
  },
  {
    "x": "awarte, -ye",
    "y": " olağanüstü"
  },
  {
    "x": "awasayene",
    "y": " b. asayene"
  },
  {
    "x": "awaseyene",
    "y": " b. asayene"
  },
  {
    "x": "awasîyene",
    "y": " b. asayene"
  },
  {
    "x": "awaşîyayîş (n)",
    "y": " b. awanşîyayîş (n)"
  },
  {
    "x": "awbaz, -e",
    "y": " dalgıç, balıkadam, kurbağaadam"
  },
  {
    "x": "awbend (n)",
    "y": " baraj"
  },
  {
    "x": "awca (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "awdas (n)",
    "y": " b. awdest (n)"
  },
  {
    "x": "awdast (n)",
    "y": " b. b. awdest (n)"
  },
  {
    "x": "awdastxane (n)",
    "y": " b. awdestxane (n)"
  },
  {
    "x": "awdaya (m)",
    "y": " b. awedaya (m)"
  },
  {
    "x": "awdaye (n)",
    "y": " b. awedaye (n)"
  },
  {
    "x": "awdayîş (n)",
    "y": " b. awedayîş (n)"
  },
  {
    "x": "awdayîye (m)",
    "y": " b. awedayîye (m)"
  },
  {
    "x": "awdayox, -e",
    "y": " b. awedayox, -e"
  },
  {
    "x": "awdaz (n)",
    "y": " b. awdest (n)"
  },
  {
    "x": "awdest (n)",
    "y": " abdest, aptes"
  },
  {
    "x": "awdest ra",
    "y": " abdestli, aptesli"
  },
  {
    "x": "awdestûne (n)",
    "y": " b. awdestxane (n)"
  },
  {
    "x": "awdestxane (n)",
    "y": " yüznumara, ayakyolu, abdesthane, apteshane, hela, kademhane, kenef, memişhane, tuvalet"
  },
  {
    "x": "awdestxone (n)",
    "y": " b. awdestxane (n)"
  },
  {
    "x": "awdestxûne (n)",
    "y": " b. awdestxane (n)"
  },
  {
    "x": "awdeye (n)",
    "y": " b. awedaye (n)"
  },
  {
    "x": "awdîyayîş (n)",
    "y": " b. awedîyayîş (n)"
  },
  {
    "x": "awdîyeye (n)",
    "y": " b. awedîyaye (n)"
  },
  {
    "x": "awe-I (m)",
    "y": " su\r\n~awa bacikan (m): biber salçası\r\n~awa gemarine (m): pis su\r\n~awa heyatî (m): bengisu, abıhayat\r\n~awa rîyî (m): ar, utanma, utanç (mecazi)\r\n~awa şimitişî (m): içme suyu\r\n~awa şorre (m): tuzlu su\r\n~awexoserozelalkerdiş (n): çok gecikme"
  },
  {
    "x": "awa bacikan (m)",
    "y": " biber salçası"
  },
  {
    "x": "awa balcanan (m)",
    "y": " b. awa firingîyan (m)"
  },
  {
    "x": "awa cale (m)",
    "y": " acı su"
  },
  {
    "x": "awa dekewtişî (m)",
    "y": " giriş suyu (arıtma sisteminde)"
  },
  {
    "x": "awa firingîyan (m)",
    "y": " domates salçası"
  },
  {
    "x": "awa gemarine (m)",
    "y": " pis su"
  },
  {
    "x": "awa henaran (m)",
    "y": " nar suyu"
  },
  {
    "x": "awa heyatî (m)",
    "y": " bengisu, abıhayat"
  },
  {
    "x": "awa îsotan (m)",
    "y": " biber salçası"
  },
  {
    "x": "awa lêmine (m)",
    "y": " bulanık su"
  },
  {
    "x": "awa leymine (m)",
    "y": " b. awa lêmine (m)"
  },
  {
    "x": "awa leximî (m)",
    "y": " lağım suyu, pis su"
  },
  {
    "x": "awa pîse (m)",
    "y": " pis su"
  },
  {
    "x": "awa rîyî (m)",
    "y": " ar, utanma, utanç (mecazi)"
  },
  {
    "x": "awa soline (m)",
    "y": " tuzlu su"
  },
  {
    "x": "awa sorre (m)",
    "y": " b. awa şorre (m)"
  },
  {
    "x": "awa sualin (m)",
    "y": " b. awa soline (m)"
  },
  {
    "x": "awa suelin (m)",
    "y": " b. awa soline (m)"
  },
  {
    "x": "awa şamikan (m)",
    "y": " b. awa firingîyan (m)"
  },
  {
    "x": "awa şilîye (m",
    "y": " yağmur suyu"
  },
  {
    "x": "awa şimitişî (m)",
    "y": " içme suyu"
  },
  {
    "x": "awa şorre (m)",
    "y": " tuzlu su"
  },
  {
    "x": "awa şuarr (m)",
    "y": " b. awa şorre (m)"
  },
  {
    "x": "awa tale (m)",
    "y": " acı su"
  },
  {
    "x": "awa varanî (m)",
    "y": " yağmur suyu"
  },
  {
    "x": "awa vejîyayîşî (m)",
    "y": " çıkış suyu"
  },
  {
    "x": "awe dayene",
    "y": " 1)sulamak, suvarmak \r\n~2)sızdırmak "
  },
  {
    "x": "awe dîyayene",
    "y": " sulanmak\r\n~Bostan awe dîyayo. (Bostan sulanmış.)"
  },
  {
    "x": "awe dîyene",
    "y": " b. awe dîyayene"
  },
  {
    "x": "awe fek de şîyene",
    "y": " ağzı sulanmak"
  },
  {
    "x": "awe fek kewtene",
    "y": " ağzı sulanmak"
  },
  {
    "x": "awe pira nayene",
    "y": " su bırakmak (tarla vb. için)"
  },
  {
    "x": "awe rişnayene",
    "y": " 1)su dökmek \r\n~2)işemek, su dökmek, küçük abdest bozmak"
  },
  {
    "x": "awe û werd",
    "y": " su ve yiyecek, yiyecek ve içecek"
  },
  {
    "x": "awe ver kewtene",
    "y": " akıntıya kapılmak"
  },
  {
    "x": "awe veradayene ... ser",
    "y": " su bırakmak (tarla vb. için)\r\n~Hagîdarî awe veradaya bostanî ser. (Hagîdar bostana suyu bırakmış/salmış.)"
  },
  {
    "x": "awe xo ro kerdene",
    "y": " 1)yıkanmak, duş yapmak, duş almak, su dökünmek \r\n~2)gusül abdesti (aptesi) almak, boy abdesti (aptesi) almak"
  },
  {
    "x": "awe xo ser o zelal kerdene",
    "y": " çok gecikmek"
  },
  {
    "x": "awê girewtoxî (zh)",
    "y": " alıcı sular"
  },
  {
    "x": "awe-II (n, z)",
    "y": " b. o (n, z)"
  },
  {
    "x": "awe-III (m, z)",
    "y": " b. aye (m, z)"
  },
  {
    "x": "awedane (m)",
    "y": " sulama"
  },
  {
    "x": "awedaya (m)",
    "y": " sulanmış olan"
  },
  {
    "x": "awedaye (n)",
    "y": " sulanmış olan"
  },
  {
    "x": "awedayîş (n)",
    "y": " 1)sulama, suvarma \r\n~2)sızdırma (bez, kumaş vb. için)"
  },
  {
    "x": "awedayîye (m)",
    "y": " sulanmış olan"
  },
  {
    "x": "awedayox, -e",
    "y": " suvarıcı (su verici)"
  },
  {
    "x": "awedîyaya (m)",
    "y": " sulanmış olan"
  },
  {
    "x": "awedîyaye (n)",
    "y": " sulanmış olan"
  },
  {
    "x": "awedîyayîş (n)",
    "y": " sulanma"
  },
  {
    "x": "awedîyayîye (m)",
    "y": " sulanmış olan"
  },
  {
    "x": "awefekdeşîyayîş (n)",
    "y": " ağzı sulanma"
  },
  {
    "x": "awefekkewtiş (n)",
    "y": " ağzı sulanma"
  },
  {
    "x": "awerişnayene",
    "y": " 1)su dökmek \r\n~2)işemek, su dökmek, küçük abdest bozmak, küçük aptes bozmak"
  },
  {
    "x": "awerişnayîş (n)",
    "y": " 1)su dökme\r\n~2)işeme, su dökme, küçük abdest bozma, küçük aptes bozma"
  },
  {
    "x": "awesayene",
    "y": " b. asayene"
  },
  {
    "x": "awexorokerdiş (n)",
    "y": " 1)yıkanma, duş yapma, duş alma, su dökünme \r\n~2)gusül abdesti (aptesi) alma, boy abdesti (aptesi) alma"
  },
  {
    "x": "awexoserozelalkerdiş (n)",
    "y": " çok gecikme"
  },
  {
    "x": "awfekkewtiş (n)",
    "y": " b. awefekkewtiş"
  },
  {
    "x": "awfektişîyayîş (n)",
    "y": " b. awefekdeşîyayîş (n)"
  },
  {
    "x": "awgoznayîş (n)",
    "y": " b. awguznayîş (n)"
  },
  {
    "x": "awgujîyayeni",
    "y": " b. awguzîyayene"
  },
  {
    "x": "awgujîyayîş (n)",
    "y": " b. awguzîyayîş (n)"
  },
  {
    "x": "awgujnayeni",
    "y": " b. awguznayene"
  },
  {
    "x": "awgujnayîş (n)",
    "y": " b. awguznayîş (n)"
  },
  {
    "x": "awguzîyayene",
    "y": " süzülmek (sıvı için)"
  },
  {
    "x": "awguzîyayîş (n)",
    "y": " süzülme (sıvı için)"
  },
  {
    "x": "awguznayene",
    "y": " 1)süzmek (sıvı için) \r\n~2)arıtmak"
  },
  {
    "x": "awguznayîş (n)",
    "y": " 1)süzme (sıvı için) \r\n~2)arıtma, rafinaj, arıtım\r\n~awguznayîşê awe (n): su arıtma\r\n~awguznayîşo bîyolojîk (n): biyolojik arıtma"
  },
  {
    "x": "awguznayîşê awe (n)",
    "y": " su arıtma"
  },
  {
    "x": "awguznayîşê gendawa bankîye (n)",
    "y": " evsel atık su arıtımı"
  },
  {
    "x": "awguznayîşo bîyolojîk (n)",
    "y": " biyolojik arıtma"
  },
  {
    "x": "awguznayîşo fîzîkî (n)",
    "y": " fiziksel arıtma"
  },
  {
    "x": "awguznayîşo hîrêyin (n)",
    "y": " üçüncül arıtma"
  },
  {
    "x": "awguznayîşo tebîî (n)",
    "y": " doğal arıtma"
  },
  {
    "x": "awguznayîşo xozayî (n)",
    "y": " doğal arıtma"
  },
  {
    "x": "awi-I (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "awey îsotî (m)",
    "y": " b. awa îsotan (m)"
  },
  {
    "x": "awi-II (n)",
    "y": " b. awin (n)"
  },
  {
    "x": "awi-III (n, z)",
    "y": " b. o (n, z)"
  },
  {
    "x": "awil",
    "y": " b. ewilî"
  },
  {
    "x": "awilî",
    "y": " b. ewilî"
  },
  {
    "x": "awin, -e",
    "y": " 1)sulu \r\n~2)cıvık"
  },
  {
    "x": "awin bîyene",
    "y": " sulanmak (sulu duruma gelmek)\r\n~Germîya/şorbaya to awin bîya. (Çorban sulu olmuş.)"
  },
  {
    "x": "awinbîyayîş (n)",
    "y": " sulanma (sulu duruma gelme) "
  },
  {
    "x": "awiney (m)",
    "y": " b. awinîye (m)"
  },
  {
    "x": "awinê (m)",
    "y": " b. awinîye (m)"
  },
  {
    "x": "awini (m)",
    "y": " b. awine (m)"
  },
  {
    "x": "awinîye (m)",
    "y": " sululuk"
  },
  {
    "x": "awiqat, -i",
    "y": " b. avûkat, -e"
  },
  {
    "x": "awiqîyayene",
    "y": " oyalanmak"
  },
  {
    "x": "awiqîyayîş (n)",
    "y": " oyalanma"
  },
  {
    "x": "awir (n)",
    "y": " bakış"
  },
  {
    "x": "awir dayene",
    "y": " sertçe bakmak, ters ters bakmak"
  },
  {
    "x": "awirdayîş (n)",
    "y": " sertçe bakma, ters ters bakma"
  },
  {
    "x": "awirxezal, -e",
    "y": " ceylan bakışlı"
  },
  {
    "x": "awî, -ye -I",
    "y": " 1)sıvı\r\n~awîya çamure (m):  çamur sıvısı\r\n~awîya lince (m):  çamur sıvısı\r\n~2)sulak, sulu\r\n~hêgayo awî (n): sulak tarla\r\n~zîreto awî (n):  sulu tarım, sulu ziraat\r\n~3)su ile ilgili, suda yaşayan"
  },
  {
    "x": "awîya çamure (m)",
    "y": " çamur sıvısı"
  },
  {
    "x": "awîya lince (m)",
    "y": " çamur sıvısı"
  },
  {
    "x": "hêgayo awî (n)",
    "y": " sulak tarla"
  },
  {
    "x": "zîreto awî (n)",
    "y": " sulu tarım, sulu ziraat, "
  },
  {
    "x": "awî-II (m)",
    "y": " 1)atmık, bel, belsuyu, ersuyu, sperma \r\n~2)çiy, şebnem"
  },
  {
    "x": "awî kewtene",
    "y": " çiy düşmek"
  },
  {
    "x": "awî-III (zh, z)",
    "y": " b. ê-III (zh, z)"
  },
  {
    "x": "awîpeym (n)",
    "y": " sıvıölçer, areometre"
  },
  {
    "x": "awîyey (m)",
    "y": " b. awîyîye (m)"
  },
  {
    "x": "awîyê (m)",
    "y": " b. awîyîye (m)"
  },
  {
    "x": "awîyîye (m)",
    "y": " sulaklık, sululuk"
  },
  {
    "x": "awîze (m)",
    "y": " b. avîze (m)"
  },
  {
    "x": "awja (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "awk (m)",
    "y": " b. awke (m)"
  },
  {
    "x": "awkê bazilcûnû (m)",
    "y": " b. awa firingîyan (m)"
  },
  {
    "x": "awkdayîş (n)",
    "y": " b. awedayîş (n)"
  },
  {
    "x": "awkdîyaya (m)",
    "y": " b. awedîyaya (m)"
  },
  {
    "x": "awkdîyaye (n)",
    "y": " b. awedîyaye (n)"
  },
  {
    "x": "awkdîyayîş (n)",
    "y": " b. awedîyayîş (n)"
  },
  {
    "x": "awke-I (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "awka firingîyan (m)",
    "y": " b. awa firingîyan (m)"
  },
  {
    "x": "awka îsotî (m)",
    "y": " b. awa îsotan (m)"
  },
  {
    "x": "awka rîy (m)",
    "y": " b. awa rîyî (m)"
  },
  {
    "x": "awkey rîy (m)",
    "y": " b. awa rîyî (m)"
  },
  {
    "x": "awke-II (n)",
    "y": " banyo (banyo yeri)"
  },
  {
    "x": "awkewtiş (n)",
    "y": " b. akewtiş (n)"
  },
  {
    "x": "awki (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "awkey îsotî (m)",
    "y": " b. awa îsotan (m)"
  },
  {
    "x": "awkin, -i",
    "y": " b. awin, -e"
  },
  {
    "x": "awkin bîyayîne",
    "y": " b. awin bîyene"
  },
  {
    "x": "awkinbîyayîş (n)",
    "y": " b. awinbîyayîş (n)"
  },
  {
    "x": "awkiney (m)",
    "y": " b. awinîye (m)"
  },
  {
    "x": "awkinê (m)",
    "y": " b. awinîye (m)"
  },
  {
    "x": "awley (zh)",
    "y": " b. awleyî (zh)"
  },
  {
    "x": "awley vetene",
    "y": " b. awleyî vetene"
  },
  {
    "x": "awleyin, -e",
    "y": " çiçekbozuğu, çopur"
  },
  {
    "x": "awleyini (m)",
    "y": " b. awleyine (m)"
  },
  {
    "x": "awleyî (zh)",
    "y": " çiçek (hastalığı)"
  },
  {
    "x": "awleyî vetene",
    "y": " çiçek çıkarmak"
  },
  {
    "x": "awnan (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "awnîyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "awnû (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "awnûn (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "awo (n)",
    "y": " b. awan (n)"
  },
  {
    "x": "awo kerdene",
    "y": " b. awan kerdene"
  },
  {
    "x": "awokerdiş (n)",
    "y": " b. awankerdiş (n)"
  },
  {
    "x": "awon (n)",
    "y": " b. awan (n)"
  },
  {
    "x": "awon kerdene",
    "y": " b. awan kerdene"
  },
  {
    "x": "awonê (m)",
    "y": " b. awanî (m)"
  },
  {
    "x": "awonî (m)",
    "y": " b. awanî (m)"
  },
  {
    "x": "awonkerdiş (n)",
    "y": " b. awankerdiş (n)"
  },
  {
    "x": "awonşîyayîş (n)",
    "y": " b. awanşîyayîş (n)"
  },
  {
    "x": "awoşîyayîş (n)",
    "y": " b. awanşîyayîş (n)"
  },
  {
    "x": "awpeym (n)",
    "y": " suölçer, hidrometre"
  },
  {
    "x": "awr (m)",
    "y": " b. (hayvan için) awre (m)"
  },
  {
    "x": "awr bîyayene",
    "y": " b. (hayvan için) awre bîyene"
  },
  {
    "x": "awra (m)",
    "y": " b. awre (m)"
  },
  {
    "x": "awre (m)",
    "y": " gebe (hayvan için)"
  },
  {
    "x": "awre bîyene",
    "y": " gebe olmak (hayvan için)"
  },
  {
    "x": "awres (n)",
    "y": " b. hargûş"
  },
  {
    "x": "awrey (m)",
    "y": " b. awrîye (m) (hayvan için)"
  },
  {
    "x": "awrê (m)",
    "y": " b. awrîye (m) (hayvan için)"
  },
  {
    "x": "awrênî (m)",
    "y": " gebelik (hayvan için)"
  },
  {
    "x": "awrês-I (n)",
    "y": " b. hargûş"
  },
  {
    "x": "awrês-II (n)",
    "y": " b. vewrês"
  },
  {
    "x": "awrêş (n)",
    "y": " b. hargûş"
  },
  {
    "x": "awri (m)",
    "y": " b. awre (m) (hayvan için)"
  },
  {
    "x": "awri bîyayene",
    "y": " b. (hayvan için) awre bîyene"
  },
  {
    "x": "awriş (n)",
    "y": " b. hargûş "
  },
  {
    "x": "awrî (m)",
    "y": " b. awrîye (m) (hayvan için)"
  },
  {
    "x": "awrîş (n)",
    "y": " b. hargûş "
  },
  {
    "x": "awrîye (m)",
    "y": " gebelik (hayvan için)"
  },
  {
    "x": "awroş, -e. ",
    "y": " sucu (su satan)"
  },
  {
    "x": "awroşî (m)",
    "y": " suculuk"
  },
  {
    "x": "awroşîye (m)",
    "y": " suculuk"
  },
  {
    "x": "awrûpa",
    "y": " bir çeşit bez"
  },
  {
    "x": "awsayene",
    "y": " b. asayene"
  },
  {
    "x": "awsayîs (n)",
    "y": " b. asayîş-II (n)"
  },
  {
    "x": "awtenayîne",
    "y": " b. ewtanayene"
  },
  {
    "x": "awtenayîş (n)",
    "y": " b. ewtanayîş (n)"
  },
  {
    "x": "awterayîne",
    "y": " b. wetardene"
  },
  {
    "x": "awterayîş (n)",
    "y": " b. wetardiş (n)"
  },
  {
    "x": "awtonayîne",
    "y": " b. ewtanayene"
  },
  {
    "x": "awtonayîş (n)",
    "y": " b. ewtanayîş (n)"
  },
  {
    "x": "awuke (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "awuqat, -i",
    "y": " b. avûkat, -e"
  },
  {
    "x": "awur (n)",
    "y": " b. awir (n)"
  },
  {
    "x": "Awustralya (m)",
    "y": " Avustralya"
  },
  {
    "x": "Awusturya (m)",
    "y": " Avusturya"
  },
  {
    "x": "awû (n)",
    "y": " b. awan (n)"
  },
  {
    "x": "awû kerdene",
    "y": " b. awan kerdene"
  },
  {
    "x": "awûkerdiş (n)",
    "y": " b. awankerdiş (n)"
  },
  {
    "x": "awûn (n)",
    "y": " b. awan (n)"
  },
  {
    "x": "awûn kerdene",
    "y": " b. awan kerdene"
  },
  {
    "x": "awûnê (m)",
    "y": " b. awanî (m)"
  },
  {
    "x": "awûnkerdiş (n)",
    "y": " b. awankerdiş (n)"
  },
  {
    "x": "awûnşîyayîş (n)",
    "y": " b. awanşîyayîş (n)"
  },
  {
    "x": "awûşîyayîş (n)",
    "y": " b. awanşîyayîş (n)"
  },
  {
    "x": "awxuerikerdiş (n)",
    "y": " b. awexorokerdiş (n)"
  },
  {
    "x": "awxurokerdiş (n)",
    "y": " b. awexorokerdiş (n)"
  },
  {
    "x": "awxwerokerdiş (n)",
    "y": " b. awexorokerdiş (n)"
  },
  {
    "x": "awxwirokerdiş (n)",
    "y": " b. awexorokerdiş (n)"
  },
  {
    "x": "awza (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "awze (n)",
    "y": " b. awzên (n)"
  },
  {
    "x": "awzê (n)",
    "y": " b. awzên (n)"
  },
  {
    "x": "awzêm (n)",
    "y": " su sızıntısı olan yer/toprak "
  },
  {
    "x": "awzên (n)",
    "y": " su sızıntısı olan yer/toprak "
  },
  {
    "x": "awzîm (n)",
    "y": " b. awzêm (n)"
  },
  {
    "x": "ax-I",
    "y": " ah\r\n~Ax dakila mi! (Ah anacığım!)\r\n~Ax li minê, mi bêkesî! (Ah halime ben kimsesizin!)"
  },
  {
    "x": "ax-II (n)",
    "y": " balgam"
  },
  {
    "x": "axa (n)",
    "y": " 1)ağa \r\n~2)dama oyununda, karşıdaki son sıraya ulaşıp oyunda en avantajlı duruma ulaşan taş"
  },
  {
    "x": "axaên (m)",
    "y": " b. axayênî (m)"
  },
  {
    "x": "axatî (m)",
    "y": " ağalık"
  },
  {
    "x": "axayey (m)",
    "y": " b. axayîye (m)"
  },
  {
    "x": "axayê (m)",
    "y": " b. axayî (m)"
  },
  {
    "x": "axayên (m)",
    "y": " b. axayênî (m)"
  },
  {
    "x": "axayênî (m)",
    "y": " ağalık"
  },
  {
    "x": "axayî (m)",
    "y": " ağalık"
  },
  {
    "x": "axayîye (m)",
    "y": " ağalık"
  },
  {
    "x": "axda (m)",
    "y": " ağda"
  },
  {
    "x": "axdayin, -e",
    "y": " ağdalı"
  },
  {
    "x": "axer (n)",
    "y": " b. varan (n)"
  },
  {
    "x": "axin, -e",
    "y": " balgamlı"
  },
  {
    "x": "Axin (n)",
    "y": " Ağın"
  },
  {
    "x": "axir, -e-I",
    "y": " ahir"
  },
  {
    "x": "axir-II",
    "y": " b. axirî"
  },
  {
    "x": "axir ke",
    "y": " b. axirî"
  },
  {
    "x": "axir-III (n)",
    "y": " b. axur (n)"
  },
  {
    "x": "axirete (m)",
    "y": " b. axret (n)"
  },
  {
    "x": "axirî",
    "y": " ahir, nihayet, sonunda"
  },
  {
    "x": "axirîke",
    "y": " b. axirî"
  },
  {
    "x": "axirwaxt (n)",
    "y": " b. axirwext (n)"
  },
  {
    "x": "axirwext (n)",
    "y": " ahir vakit, ahir zaman"
  },
  {
    "x": "axirzeman (n)",
    "y": " ahir vakit, ahir zaman  "
  },
  {
    "x": "axirzemo (n)",
    "y": " b. axirzeman (n)"
  },
  {
    "x": "axirzemon (n)",
    "y": " b. axirzeman (n)"
  },
  {
    "x": "axirzemû (n)",
    "y": " b. axirzeman (n)"
  },
  {
    "x": "axirzemûn (n)",
    "y": " b. axirzeman (n)"
  },
  {
    "x": "axîret (n)",
    "y": " b. axret (n)"
  },
  {
    "x": "axmaq, -e",
    "y": " b. ehmeq, -e"
  },
  {
    "x": "axmaqê (m)",
    "y": " b. ehmeqî (m)"
  },
  {
    "x": "axmax, -e",
    "y": " b. ehmeq, -e"
  },
  {
    "x": "axmaxey (m)",
    "y": " b. ehmeqîye (m)"
  },
  {
    "x": "axme (n)",
    "y": " b. yaxme (n)"
  },
  {
    "x": "axme bîyayene",
    "y": " b. axme bîyene"
  },
  {
    "x": "axme bîyayêne",
    "y": " b. axme bîyene"
  },
  {
    "x": "axme bîyayîne",
    "y": " b. axme bîyene"
  },
  {
    "x": "axme bîyene",
    "y": " 1)dağılmak \r\n~2)yayılmak"
  },
  {
    "x": "axme kerdene",
    "y": " 1)dağıtmak \r\n~2)yaymak \r\n~3)b. yaxme kerdene"
  },
  {
    "x": "axmebîyaye (n)",
    "y": " dağılmış olan, dağınık"
  },
  {
    "x": "axmebîyayî, -ye",
    "y": " dağılmış olan, dağınık"
  },
  {
    "x": "axmebîyayîs (n)",
    "y": " b. axmebîyayîş (n)"
  },
  {
    "x": "axmebîyayîş (n)",
    "y": " 1)dağılma \r\n~2)yayılma"
  },
  {
    "x": "axmekerde (n)",
    "y": " dağıtılmış olan"
  },
  {
    "x": "axmekerdî, -ye",
    "y": " dağıtılmış olan"
  },
  {
    "x": "axmekerdiş (n)",
    "y": " 1)dağıtma \r\n~2)yayma \r\n~3)yaxmekerdiş (n)"
  },
  {
    "x": "axor (n)",
    "y": " b. axur (n)"
  },
  {
    "x": "axpîn (n)",
    "y": " verimli arazi, verimli toprak"
  },
  {
    "x": "axpîr (n)",
    "y": " b. axpîn (n)"
  },
  {
    "x": "axret (n)",
    "y": " ahiret, ahret"
  },
  {
    "x": "axrete (m)",
    "y": " b. axret (n)"
  },
  {
    "x": "axreti (m)",
    "y": " b. axret (n)"
  },
  {
    "x": "axrî",
    "y": " b. axirî"
  },
  {
    "x": "axrîke",
    "y": " b. axirî"
  },
  {
    "x": "axrîki",
    "y": " b. axirî"
  },
  {
    "x": "axsata (m)",
    "y": " alışveriş"
  },
  {
    "x": "axsate (n)",
    "y": " b. axsata (m)"
  },
  {
    "x": "axtar, -i",
    "y": " b. extîyar, -e"
  },
  {
    "x": "axtîyar, -i",
    "y": " b. extîyar, -e"
  },
  {
    "x": "axu (n)",
    "y": " b. axû (n)"
  },
  {
    "x": "axui (n)",
    "y": " b. axû (n)"
  },
  {
    "x": "axuer (n)",
    "y": " b. axur (n)"
  },
  {
    "x": "axur (n)",
    "y": " ahır, dam"
  },
  {
    "x": "axure (m)",
    "y": " b. axur (n)"
  },
  {
    "x": "axû (n)",
    "y": " zehir"
  },
  {
    "x": "axûyin, -e",
    "y": " zehirli"
  },
  {
    "x": "axwe (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "axwedayîş (n)",
    "y": " b. awedayîş (n)"
  },
  {
    "x": "axwedîyayîş (n)",
    "y": " b. awedîyayîş (n)"
  },
  {
    "x": "axwî (n)",
    "y": " b. axû (n)"
  },
  {
    "x": "ay-I (m, z)",
    "y": " b. aye (m, z)"
  },
  {
    "x": "ay-II (n, z)",
    "y": " 1)b. ey (n, z) \r\n~2)b. o (n, z)"
  },
  {
    "x": "ay-III (n, s)",
    "y": " 1)b. ê (n, s) \r\n~2)b. o (n, s)"
  },
  {
    "x": "ay sebeb ra",
    "y": " b. ê sebebî ra"
  },
  {
    "x": "ay semed ra",
    "y": " b. ê semedî ra"
  },
  {
    "x": "ay-IV (zh, z)",
    "y": " b. ê-III (zh, z)"
  },
  {
    "x": "ay-V (zh, s)",
    "y": " b. ê-IV (zh, s)"
  },
  {
    "x": "ay-VI (n, m, zh)",
    "y": " b. yê-VI (n, m, zh)"
  },
  {
    "x": "ay-VII (m, z)",
    "y": " b. a (m, z)"
  },
  {
    "x": "ay-VIII",
    "y": " ah, ay\r\n~Ay dayê! (Ah anam!)"
  },
  {
    "x": "aya-I (m, z)",
    "y": " 1)b. a (m, z) \r\n~2)b. aye (m, z)"
  },
  {
    "x": "aya ra",
    "y": " b. aye ra"
  },
  {
    "x": "aya-II (m, s)",
    "y": " b. a-II (m, s)"
  },
  {
    "x": "aya, -ye-III",
    "y": " b. hayîg, -e"
  },
  {
    "x": "aya bîyene",
    "y": " b. hayîg bîyene"
  },
  {
    "x": "ayabîyayîş (n)",
    "y": " b. hayîgbîyayîş (n)"
  },
  {
    "x": "ayahewa",
    "y": " b. ayhawa"
  },
  {
    "x": "ayam (n)",
    "y": " b. eyam (n)"
  },
  {
    "x": "ayar (n)",
    "y": " b. eyar (n)"
  },
  {
    "x": "ayar kerdene",
    "y": " b. eyar kerdene"
  },
  {
    "x": "ayarkerdiş (n)",
    "y": " b. eyarkerdiş (n)"
  },
  {
    "x": "ayawa",
    "y": " b. ayhawa"
  },
  {
    "x": "ayay (m, z)",
    "y": " b. aye (m, z)"
  },
  {
    "x": "ayay ra",
    "y": " b. aye ra"
  },
  {
    "x": "ayb (n)",
    "y": " b. eyb (n)"
  },
  {
    "x": "ayca (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "aydir (n)",
    "y": " b. adir (n)"
  },
  {
    "x": "aye (m, z)",
    "y": " o, onu"
  },
  {
    "x": "aye ra",
    "y": " 1)ondan, ona \r\n~2)o nedenle, o yüzden, ondan dolayı, ondan ötürü"
  },
  {
    "x": "aye ra pey",
    "y": " ondan sonra"
  },
  {
    "x": "aye rê",
    "y": " ona"
  },
  {
    "x": "ayewa",
    "y": " b. ayhawa"
  },
  {
    "x": "ayê-I (m, z)",
    "y": " b. aye (m, z)"
  },
  {
    "x": "ayê-II (zh, s)",
    "y": " b. ê-IV (zh, s)"
  },
  {
    "x": "ayê-III (zh, z)",
    "y": " b. ê-III (zh, z)"
  },
  {
    "x": "ayê-IV (n, m, zh)",
    "y": " b. yê-VI (n, m, zh)"
  },
  {
    "x": "ayê-V",
    "y": " b. dayê"
  },
  {
    "x": "ayhawa",
    "y": " öyle"
  },
  {
    "x": "ayhewa",
    "y": " b. ayhawa"
  },
  {
    "x": "ayi (m, z)",
    "y": " b. aye (m, z)"
  },
  {
    "x": "ayin (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "ayini (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "ayino (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "ayinû (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "ayiv (n)",
    "y": " b. eyb (n)"
  },
  {
    "x": "ayî-I (n, z)",
    "y": " b. ey (n, z)"
  },
  {
    "x": "ayî-II (zh, z)",
    "y": " b. ê-III (zh, z)"
  },
  {
    "x": "ayî-III (zh, s)",
    "y": " b. ê-IV (zh, s)"
  },
  {
    "x": "ayîn (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "ayînî (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "ayîre (n)",
    "y": " b. arê (n)"
  },
  {
    "x": "ayîv (n)",
    "y": " b. eyb (n)"
  },
  {
    "x": "ayle (m)",
    "y": " b. aîle (m)"
  },
  {
    "x": "ayna-I (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "ayna-II (zr)",
    "y": " b. enî-V (zr)"
  },
  {
    "x": "aynan (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "awnayîne",
    "y": " b. ewnîyayene"
  },
  {
    "x": "awnayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "ayne (n)",
    "y": " b. eyne (n)"
  },
  {
    "x": "ayney (zh)",
    "y": " b. hayneyî (zh)"
  },
  {
    "x": "awneyîne",
    "y": " b. ewnîyayene"
  },
  {
    "x": "awneyîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "ayix, -e",
    "y": " b. hayîg, -e"
  },
  {
    "x": "ayix bîyene",
    "y": " b. hayîg bîyene"
  },
  {
    "x": "ayixbîyayîş (n)",
    "y": " b. hayîgbîyayîş (n)"
  },
  {
    "x": "ayneyîne",
    "y": " b. ewnîyayene"
  },
  {
    "x": "ayneyîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "aynê (s)",
    "y": " b. eynî (s)"
  },
  {
    "x": "ayni (n)",
    "y": " b. eyne (n)"
  },
  {
    "x": "aynî (s)",
    "y": " b. eynî (s)"
  },
  {
    "x": "ayno (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "aynon (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "aynû (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "aynûn (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "ayo-I (n, z)",
    "y": " b. o-I (n, z)"
  },
  {
    "x": "ayo-II (n, s)",
    "y": " b. o-II (n, s)"
  },
  {
    "x": "ayohewa",
    "y": " b. ayhawa"
  },
  {
    "x": "ayre (n)",
    "y": " b. arê (n)"
  },
  {
    "x": "ayreq (n)",
    "y": " b. ereq (n)"
  },
  {
    "x": "ayronçî (n)",
    "y": " b. arêwan, -e"
  },
  {
    "x": "aysayîne",
    "y": " b. asayene"
  },
  {
    "x": "aysayîs (n)",
    "y": " b. asayîş-II (n)"
  },
  {
    "x": "aysayîş (n)",
    "y": " b. asayîş-II (n)"
  },
  {
    "x": "aysberg (n)",
    "y": " aysberg"
  },
  {
    "x": "ayta (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "ayû (n, z)",
    "y": " b. o (n, z)"
  },
  {
    "x": "ayv (n)",
    "y": " b. eyb (n)"
  },
  {
    "x": "aywa",
    "y": " b. ayhawa"
  },
  {
    "x": "az-I (n)",
    "y": " 1)nesil, soy \r\n~2)b. aj (n)"
  },
  {
    "x": "az dayene",
    "y": " b. aj dayene"
  },
  {
    "x": "az kerdene",
    "y": " b. aj kerdene"
  },
  {
    "x": "az û uz",
    "y": " soy sop"
  },
  {
    "x": "az û wuz",
    "y": " b. az û uz"
  },
  {
    "x": "azê ajî",
    "y": " b. azê azî"
  },
  {
    "x": "azê azî",
    "y": " neslin nesli, soyun soyu"
  },
  {
    "x": "az-uz",
    "y": " soy sop"
  },
  {
    "x": "az-wuz",
    "y": " b. az-uz"
  },
  {
    "x": "az-II (z)",
    "y": " b. ez (z)"
  },
  {
    "x": "aza-I (n)",
    "y": " aza, organ, uzuv"
  },
  {
    "x": "aza-II (n)",
    "y": " b. ezan (n)"
  },
  {
    "x": "aza-III (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "azad, -e",
    "y": " özgür"
  },
  {
    "x": "azadey (m)",
    "y": " b. azadîye (m)"
  },
  {
    "x": "azadê (m)",
    "y": " b. azadîye (m)"
  },
  {
    "x": "azadîye (m)",
    "y": " özgürlük\r\n~azadîya çapemenîye (m): basın özgürlüğü"
  },
  {
    "x": "azadîya çapemenîye (m)",
    "y": " basın özgürlüğü"
  },
  {
    "x": "azalî (n)",
    "y": " b. azwelî (n)"
  },
  {
    "x": "azamî",
    "y": " b. ezamî"
  },
  {
    "x": "azan (n)",
    "y": " b. ezan (n)"
  },
  {
    "x": "Azarpêrt",
    "y": " Adaklı"
  },
  {
    "x": "azeb, -e",
    "y": " b. ezeb, -e"
  },
  {
    "x": "azebe (m)",
    "y": " b. ezebe (m)"
  },
  {
    "x": "azebê (m)",
    "y": " b. ezebî (m)"
  },
  {
    "x": "azebê (m)",
    "y": " b. ezebî (m)"
  },
  {
    "x": "azebîye (m)",
    "y": " b. ezebîye (m)"
  },
  {
    "x": "azemî",
    "y": " b. ezamî"
  },
  {
    "x": "Azerbaycan (n)",
    "y": " Azerbaycan"
  },
  {
    "x": "azerbaycanij, -e",
    "y": " Azerbaycanlı"
  },
  {
    "x": "azerîyayene",
    "y": " azmak (yara vb. için)"
  },
  {
    "x": "azerîyayîş (n)",
    "y": " azma (yara vb. için)"
  },
  {
    "x": "azev, -e",
    "y": " b. ezeb, -e"
  },
  {
    "x": "azeve (m)",
    "y": " b. ezebe (m)"
  },
  {
    "x": "azevê (m)",
    "y": " b. ezebî (m)"
  },
  {
    "x": "azevîye (m)",
    "y": " b. ezebîye (m)"
  },
  {
    "x": "azêv, -e",
    "y": " b. ezeb, -e"
  },
  {
    "x": "azêve (m)",
    "y": " b. ezebe (m)"
  },
  {
    "x": "azêvîye (m)",
    "y": " b. ezebîye (m)"
  },
  {
    "x": "azirîyayene",
    "y": " b. azerîyayene"
  },
  {
    "x": "azirîyayîş (n)",
    "y": " b. azerîyayîş (n)"
  },
  {
    "x": "azmên (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "azmîn (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "azmon (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "azmûn (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "azna (m)",
    "y": " b. ajne (m)"
  },
  {
    "x": "azna kerdene",
    "y": " b. ajne kerdene"
  },
  {
    "x": "aznaber",
    "y": " b. ajneber, -e"
  },
  {
    "x": "aznaberîye (m)",
    "y": " b. ajneberîye"
  },
  {
    "x": "aznakerdiş (n)",
    "y": " b. ajnekerdiş"
  },
  {
    "x": "aznayene",
    "y": " dayamak, yaslamak"
  },
  {
    "x": "aznayîş (n)",
    "y": " dayama, yaslama"
  },
  {
    "x": "azne (m)",
    "y": " b. ajne (m)"
  },
  {
    "x": "azne kerdene",
    "y": " b. ajne kerdene"
  },
  {
    "x": "azneber",
    "y": " b. ajneber, -e"
  },
  {
    "x": "azneberîye (m)",
    "y": " b. ajneberîye"
  },
  {
    "x": "aznekerdiş (n)",
    "y": " b. ajnekerdiş"
  },
  {
    "x": "azney (m)",
    "y": " b. ajne (m)"
  },
  {
    "x": "azney kerdene",
    "y": " b. ajne kerdene"
  },
  {
    "x": "azneykerdiş (n)",
    "y": " b. ajnekerdiş"
  },
  {
    "x": "azo (n)",
    "y": " b. ezan (n)"
  },
  {
    "x": "azon (n)",
    "y": " b. ezan (n)"
  },
  {
    "x": "azot (n)",
    "y": " azot"
  },
  {
    "x": "azotlu",
    "y": " azotin, -e"
  },
  {
    "x": "azotometre (n)",
    "y": " azotölçer, azotometre"
  },
  {
    "x": "azotpeym (n)",
    "y": " azotölçer, azotometre"
  },
  {
    "x": "Azrahîl (n)",
    "y": " b. Gangêr (n), Ezraîl (n)"
  },
  {
    "x": "azû (n)",
    "y": " b. ezan (n)"
  },
  {
    "x": "azûn (n)",
    "y": " b. ezan (n)"
  },
  {
    "x": "azwelî (n)",
    "y": " bir tüfek çeşidi"
  },
  {
    "x": "BBB"
  },
  {
    "x": "ba-I (n)",
    "y": " b. ban (n)"
  },
  {
    "x": "ba-II (n)",
    "y": " b. babî (n)"
  },
  {
    "x": "bab (n)",
    "y": " b. babî (n)"
  },
  {
    "x": "baba (n)",
    "y": " 1)baba (hitap hali) \r\n~2)(Alevilikte) pir"
  },
  {
    "x": "babaegît (n)",
    "y": " babayiğit, acar (gözü pek)"
  },
  {
    "x": "babaegîtey (m)",
    "y": " b. babaegîtîye (m)"
  },
  {
    "x": "babaegîtê (m)",
    "y": " b. babaegîtîye (m)"
  },
  {
    "x": "babaegîtîye (m)",
    "y": " babayiğitlik, gözü peklik"
  },
  {
    "x": "babagundî (m)",
    "y": " gürültü patırtı, kargaşa, hengâme, şamata"
  },
  {
    "x": "babagunî (m)",
    "y": " b. babagundî (m)"
  },
  {
    "x": "babalix (n)",
    "y": " 1)üvey baba (b. pîyare) \r\n~2)babalık"
  },
  {
    "x": "babançî (n, m)",
    "y": " b. baxbançî, -ye"
  },
  {
    "x": "babançîyey (m)",
    "y": " b. baxbançîyî (m), baxbançîyîye (m)"
  },
  {
    "x": "babayey (m)",
    "y": " b. babayîye (m)"
  },
  {
    "x": "babayênî (m)",
    "y": " pirlik (Alevilikte)"
  },
  {
    "x": "babayîye (m)",
    "y": " pirlik (Alevilikte)"
  },
  {
    "x": "babek (n)",
    "y": " b. pabok (n), bavok (n)"
  },
  {
    "x": "babet-I (n)",
    "y": " çeşit"
  },
  {
    "x": "babet-II (m)",
    "y": " b. babete (m)"
  },
  {
    "x": "babet-III (m)",
    "y": " b. bobelate (m)"
  },
  {
    "x": "babete (m)",
    "y": " 1)konu, mevzu \r\n~2)bap"
  },
  {
    "x": "babê (n)",
    "y": " b. babî (n)"
  },
  {
    "x": "babik",
    "y": " b. bavok, -e"
  },
  {
    "x": "babî (n)",
    "y": " baba"
  },
  {
    "x": "babo",
    "y": " baba (hitap hali)"
  },
  {
    "x": "babîge (m)",
    "y": " b. babîke (m)"
  },
  {
    "x": "babîk-I (m)",
    "y": " b. babîke (m)"
  },
  {
    "x": "babîk-II (n)",
    "y": " b. bavok (n)"
  },
  {
    "x": "babîk-III",
    "y": " b. bavok, -e-II"
  },
  {
    "x": "babîke (m)",
    "y": " pekmez, yağ ve ekmekten yapılan tatlı bir yemek"
  },
  {
    "x": "babîşahî, -ye",
    "y": " ataerkil"
  },
  {
    "x": "babîşahîye (m)",
    "y": " ataerkillik"
  },
  {
    "x": "babîye (m)",
    "y": " babalık"
  },
  {
    "x": "babîyîye (m)",
    "y": " babalık"
  },
  {
    "x": "babkal (n)",
    "y": " b. bawkal (n)"
  },
  {
    "x": "babko (zh)",
    "y": " b. babîke (m)"
  },
  {
    "x": "babok, -e",
    "y": " b. pabe, -ye"
  },
  {
    "x": "babonçî (n, m)",
    "y": " b. baxbançî, -ye"
  },
  {
    "x": "babonçîyey (m)",
    "y": " b. baxbançîyî (m), baxbançîyîye (m)"
  },
  {
    "x": "babuni (m)",
    "y": " b. babûne (m)"
  },
  {
    "x": "babûn (m)",
    "y": " b. babûne (m)"
  },
  {
    "x": "babûne (m)",
    "y": "papatya"
  },
  {
    "x": "babûni (m)",
    "y": " b. babûne (m)"
  },
  {
    "x": "bac-I (n)",
    "y": " vergi\r\n~bac de prensîpê edaletî: vergide adalet ilkesi\r\n~bacê çopî (n): çöp vergisi"
  },
  {
    "x": "bac de prensîpê edaletî",
    "y": " vergide adalet ilkesi"
  },
  {
    "x": "bacê çopî (n)",
    "y": " çöp vergisi "
  },
  {
    "x": "bacê emlakî (n)",
    "y": " emlak vergisi"
  },
  {
    "x": "bac-II (n)",
    "y": " nefret, ikrah, tiksinme, tiksinti"
  },
  {
    "x": "bacê ... ameyene",
    "y": " nefret etmek, ikrah etmek, tiksinmek"
  },
  {
    "x": "bacê ... ameyîş",
    "y": " nefret etme"
  },
  {
    "x": "bacê ... ardene",
    "y": " nefret ettirmek"
  },
  {
    "x": "bacê ... ardiş",
    "y": " nefret ettirme"
  },
  {
    "x": "bacar (n)",
    "y": " b. bajar (n)"
  },
  {
    "x": "bacaric, -e",
    "y": " b. bajarij, -e"
  },
  {
    "x": "bacenax (n)",
    "y": " bacanak"
  },
  {
    "x": "bacenaxîye (m)",
    "y": " bacanaklık"
  },
  {
    "x": "bacê",
    "y": " b. badê"
  },
  {
    "x": "bacê ri",
    "y": " b. badê rê"
  },
  {
    "x": "bacêyin, -e",
    "y": " b. badêyin, -e"
  },
  {
    "x": "bacik (n)",
    "y": " biber"
  },
  {
    "x": "bacinax (n)",
    "y": " b. bacenax (n)"
  },
  {
    "x": "bacî",
    "y": " b. badê"
  },
  {
    "x": "bacî rî",
    "y": " b. badê rê"
  },
  {
    "x": "bacî (zh)",
    "y": " 1)öfke, kızgınlık \r\n~2)nefret"
  },
  {
    "x": "bacê ... ameyene",
    "y": " öfkelenmek"
  },
  {
    "x": "bacê ... ardene",
    "y": " öfkelendirmek\r\n~Kêneke ey ra bacîyêna/nefret kena. (Kız ondan nefret ediyor.)"
  },
  {
    "x": "bacîyayene",
    "y": " 1)çatlamak (ölmek) \r\n~2)öfkelenmek"
  },
  {
    "x": "bacîyayîş (n)",
    "y": " çatlama (ölme)"
  },
  {
    "x": "bacîyin, -e",
    "y": " b. badêyin, -e"
  },
  {
    "x": "bacîyon",
    "y": " b. badê"
  },
  {
    "x": "bacnayene",
    "y": " 1)çatlatmak (ölmesine neden olmak) \r\n~2)öfkelendirmek"
  },
  {
    "x": "baçirmoke (m)",
    "y": " yarasa"
  },
  {
    "x": "baçî (m)",
    "y": " b. maçî (m)"
  },
  {
    "x": "baçî kerdene",
    "y": " b. maçî kerdene"
  },
  {
    "x": "bad",
    "y": " b. badê"
  },
  {
    "x": "badana (m)",
    "y": " badana"
  },
  {
    "x": "badana kerdene",
    "y": " badanalamak"
  },
  {
    "x": "badanaker, -e",
    "y": " badanacı"
  },
  {
    "x": "badanakerdiş (n)",
    "y": " badanalama"
  },
  {
    "x": "badanakerîye (m)",
    "y": " badanacılık "
  },
  {
    "x": "bade (n)",
    "y": " bade"
  },
  {
    "x": "badena",
    "y": " b. badê"
  },
  {
    "x": "badene (m)",
    "y": " b. badana (m)"
  },
  {
    "x": "badê",
    "y": " sonra, sonradan, sonraları, bilahare"
  },
  {
    "x": "badê co",
    "y": " b. badê cû"
  },
  {
    "x": "badê coy",
    "y": " b. badê cû"
  },
  {
    "x": "badê cû",
    "y": " ondan sonra, sonra, bilâhere"
  },
  {
    "x": "badê cû ra",
    "y": " b. badê cû"
  },
  {
    "x": "badê cûy",
    "y": " b. badê cû"
  },
  {
    "x": "badê dihîre (n)",
    "y": " öğleden sonra"
  },
  {
    "x": "badê joy",
    "y": " b. badê cû"
  },
  {
    "x": "badê nêmroje (n)",
    "y": " öğleden sonra"
  },
  {
    "x": "badê rê",
    "y": " sonraya\r\n~Badê rê verde. (Sonraya bırak.)\r\n~\t\r\n~Wa badê rê bimano. (Sonraya kalsın.)"
  },
  {
    "x": "badê rî",
    "y": " b. badê rê"
  },
  {
    "x": "badê taştarî (n)",
    "y": " b. badê teştareyî (n)"
  },
  {
    "x": "badê teştareyî (n)",
    "y": " öğleden sonra"
  },
  {
    "x": "badêna",
    "y": " b. badê"
  },
  {
    "x": "badêşamî (m)",
    "y": " gece yemeği (gece geç vakit yenen yemek)"
  },
  {
    "x": "badêşûm (m)",
    "y": " b. badêşamî (m)"
  },
  {
    "x": "badêyin, -e",
    "y": " sonraki"
  },
  {
    "x": "badhewa",
    "y": " b. badihewa"
  },
  {
    "x": "badihewa",
    "y": " bedava¸ beleş"
  },
  {
    "x": "badin (m)",
    "y": " b. badîye (m)"
  },
  {
    "x": "badiros (n)",
    "y": " aşı (bitki için)"
  },
  {
    "x": "badiros bîyene",
    "y": " aşılanmak (bitki için)"
  },
  {
    "x": "badiros kerdene",
    "y": " aşılamak (bitki için)"
  },
  {
    "x": "badirosbîyayîş (n)",
    "y": " aşılanma (bitki için)"
  },
  {
    "x": "badiroskerda (m)",
    "y": " aşılı (bitki için)"
  },
  {
    "x": "badiroskerdiş (n)",
    "y": " aşılama (bitki için)"
  },
  {
    "x": "badiroskerdî (m)",
    "y": " aşılı (bitki için)"
  },
  {
    "x": "badiroskerdîye (m)",
    "y": " aşılı (bitki için)"
  },
  {
    "x": "badî",
    "y": " b. badê"
  },
  {
    "x": "badî co",
    "y": " b. badê cû"
  },
  {
    "x": "badî coy",
    "y": " b. badê cû"
  },
  {
    "x": "badî cû",
    "y": " b. badê cû"
  },
  {
    "x": "badî nêmrueci (n)",
    "y": " b. badê nêmroje (n)"
  },
  {
    "x": "badî nêmruej (n)",
    "y": " b. badê nêmroje (n)"
  },
  {
    "x": "badî nîmeroc (n)",
    "y": " b. badê nêmroje (n)"
  },
  {
    "x": "badî nîmroj (n)",
    "y": " b. badê nêmroje (n)"
  },
  {
    "x": "badî nîmrueci (n)",
    "y": " b. badê nêmroje (n)"
  },
  {
    "x": "badî rî",
    "y": " b. badê rê"
  },
  {
    "x": "badî têştarî (n)",
    "y": " b. badê teştareyî (n)"
  },
  {
    "x": "badîhewa",
    "y": " b. badihewa"
  },
  {
    "x": "badîn",
    "y": " b. badê"
  },
  {
    "x": "badîna",
    "y": " b. badê"
  },
  {
    "x": "badînî",
    "y": " b. badê"
  },
  {
    "x": "badîşûm (m)",
    "y": " b. badêşamî (m)"
  },
  {
    "x": "badîye (m)",
    "y": " derin tas"
  },
  {
    "x": "badîyin, -e",
    "y": " b. badêyin, -e"
  },
  {
    "x": "badmînton (n)",
    "y": " badminton"
  },
  {
    "x": "bado",
    "y": " b. badê"
  },
  {
    "x": "bado coy",
    "y": " b. badê cû"
  },
  {
    "x": "badoyin, -e",
    "y": " b. badêyin, -e"
  },
  {
    "x": "badros (n)",
    "y": " b. badiros (n)"
  },
  {
    "x": "badros kerdene",
    "y": " b. badiros kerdene"
  },
  {
    "x": "badroskerda (m)",
    "y": " b. badiroskerda (m)"
  },
  {
    "x": "badroskerdiş (n)",
    "y": " b. badiroskerdiş (n)"
  },
  {
    "x": "badroskerdî (m)",
    "y": " b. badiroskerda (m)"
  },
  {
    "x": "badroskerdîye (m)",
    "y": " b. badiroskerda (m)"
  },
  {
    "x": "badşûm (m)",
    "y": " b. badêşamî (m)"
  },
  {
    "x": "badu",
    "y": " b. badê"
  },
  {
    "x": "badû",
    "y": " b. badê"
  },
  {
    "x": "badû coy",
    "y": " b. badê cû"
  },
  {
    "x": "badû cû",
    "y": " b. badê cû"
  },
  {
    "x": "badûne (m)",
    "y": " b. badana (m)"
  },
  {
    "x": "baedî",
    "y": " b. badê"
  },
  {
    "x": "baê",
    "y": " b. bawo"
  },
  {
    "x": "baê",
    "y": " b. badê"
  },
  {
    "x": "baê cû",
    "y": " b. badê cû"
  },
  {
    "x": "bafin (n)",
    "y": " b. bafûn (n)"
  },
  {
    "x": "bafinên, -i",
    "y": " b. bafûnên, -e"
  },
  {
    "x": "bafinin, -i",
    "y": " b. bafûnin, -e"
  },
  {
    "x": "bafun (n)",
    "y": " b. bafûn (n)"
  },
  {
    "x": "bafunên, -e",
    "y": " b. bafûnên, -e"
  },
  {
    "x": "bafunin, -e",
    "y": " b. bafûnin, -e"
  },
  {
    "x": "bafûn (n)",
    "y": " alüminyum"
  },
  {
    "x": "bafûnên, -e",
    "y": " alüminyumdan yapılmış olan "
  },
  {
    "x": "bafûnin, -e",
    "y": " alüminyumlu"
  },
  {
    "x": "bafûnin, -i",
    "y": " b. bafûnin, -e"
  },
  {
    "x": "bagaj (n)",
    "y": " bagaj"
  },
  {
    "x": "baha (m)",
    "y": " 1)fiyat, paha\r\n~Bahaya nê kitabî çend a? (Bu kitabın fiyatı ne kadar?)\r\n~2)bedel"
  },
  {
    "x": "baha (n)",
    "y": " b. baha (m)"
  },
  {
    "x": "bahalî",
    "y": " pahalı"
  },
  {
    "x": "bahar (n)",
    "y": " göl"
  },
  {
    "x": "baharat (n)",
    "y": " b. beharat (n)"
  },
  {
    "x": "bahatî (m)",
    "y": " pahalılık"
  },
  {
    "x": "bahatîye (m)",
    "y": " pahalılık"
  },
  {
    "x": "bahayî (m)",
    "y": " pahalılık"
  },
  {
    "x": "bahayîye (m)",
    "y": " pahalılık"
  },
  {
    "x": "bahcî (zh)",
    "y": " b. bacî (zh)"
  },
  {
    "x": "bahcê ... ameyene",
    "y": " b. bacê ... ameyene"
  },
  {
    "x": "bahcê ... ardene",
    "y": " b. bacê ... ardene"
  },
  {
    "x": "bahcîyayene",
    "y": " b. bacîyayene"
  },
  {
    "x": "bahcîyayîş (n)",
    "y": " b. bacîyayîş (n)"
  },
  {
    "x": "bahcnayene",
    "y": " b. bacnayene"
  },
  {
    "x": "bahdê",
    "y": " b. badê"
  },
  {
    "x": "bahdî",
    "y": " b. badê"
  },
  {
    "x": "bahdo",
    "y": " b. badê"
  },
  {
    "x": "bahdoyin, -e",
    "y": " b. badêyin, -e"
  },
  {
    "x": "bahir",
    "y": " b. behr (n)"
  },
  {
    "x": "bahr (n)",
    "y": " b. behr (n)"
  },
  {
    "x": "Bahreyn (n)",
    "y": " b. Behreyn (n)"
  },
  {
    "x": "bahs (n)",
    "y": " b. behs (n)"
  },
  {
    "x": "bahs kerdene",
    "y": " b. behs kerdene"
  },
  {
    "x": "bajar (n)",
    "y": " il, vilayet, kent, şehir\r\n~bajaro baxçedar (n): bahçeli kent\r\n~bajaro gird (n): anakent, büyükşehir\r\n~bajaro kan (n): eski kent, eski şehir\r\n~bajarkî, -ye: kentsel"
  },
  {
    "x": "bajaro baxçedar (n)",
    "y": " bahçeli kent"
  },
  {
    "x": "bajaro bibaxçe (n)",
    "y": " bahçeli kent"
  },
  {
    "x": "bajar bîyene",
    "y": " kentleşmek, şehirleşmek"
  },
  {
    "x": "bajaro gird (n)",
    "y": " büyükşehir, büyükkent, metropol"
  },
  {
    "x": "bajaro peyk (n)",
    "y": " uydu kent"
  },
  {
    "x": "bajarawanî (m)",
    "y": " şehircilik"
  },
  {
    "x": "bajarawanîye (m)",
    "y": " şehircilik"
  },
  {
    "x": "bajarek (n)",
    "y": " belde"
  },
  {
    "x": "bajaro gird (n)",
    "y": " anakent, büyükşehir"
  },
  {
    "x": "bajaro kan (n)",
    "y": " eski kent, eski şehir"
  },
  {
    "x": "bajaro kehen (n)",
    "y": " eski kent, eski şehir "
  },
  {
    "x": "bajaric, -e",
    "y": " b. bajarij, -e"
  },
  {
    "x": "bajarij, -e",
    "y": " kentli, şehirli"
  },
  {
    "x": "bajarbîyayîş (n)",
    "y": " kentleşme, şehirleşme"
  },
  {
    "x": "bajarkî, -ye",
    "y": " kentsel"
  },
  {
    "x": "bajê",
    "y": " b. badê"
  },
  {
    "x": "bajik (n)",
    "y": " b. bacik (n)"
  },
  {
    "x": "bajî-I",
    "y": " b. badê"
  },
  {
    "x": "bajî-II (n)",
    "y": " b. bazî (n)"
  },
  {
    "x": "bajî-III (zh)",
    "y": " b. bacî (zh)"
  },
  {
    "x": "bajê ... ameyene",
    "y": " b. bacê ... ameyene"
  },
  {
    "x": "bajê ... ardene",
    "y": " b. bacê ... ardene"
  },
  {
    "x": "bajînê",
    "y": " b. badê"
  },
  {
    "x": "bajîyayene",
    "y": " b. bacîyayene "
  },
  {
    "x": "bajîyayîş (n)",
    "y": " b. bacîyayîş (n)"
  },
  {
    "x": "bajnayene",
    "y": " b. bacnayene "
  },
  {
    "x": "bajnê",
    "y": " b. badê"
  },
  {
    "x": "bak (n)",
    "y": " b. bawk (n)"
  },
  {
    "x": "bakal (n)",
    "y": " b. bawkal (n)"
  },
  {
    "x": "bakil (n)",
    "y": " babacık"
  },
  {
    "x": "bakilo",
    "y": " babacığım (hitap hali)"
  },
  {
    "x": "bakîr (n)",
    "y": " bakir"
  },
  {
    "x": "bakîra (m)",
    "y": " bakire, erden"
  },
  {
    "x": "bakîre (m)",
    "y": " bakire, erden"
  },
  {
    "x": "bakîreyîye (m)",
    "y": " bakirelik"
  },
  {
    "x": "bakla (m)",
    "y": " b. beqla (m)"
  },
  {
    "x": "bakterî (m)",
    "y": " bakteri"
  },
  {
    "x": "bakterîyolog, -e",
    "y": " bakteriyolog"
  },
  {
    "x": "bakterîyolojî (n)",
    "y": " bakteriyoloji"
  },
  {
    "x": "Baku (n)",
    "y": " Bakü"
  },
  {
    "x": "bal (m)",
    "y": " b. bale (m)"
  },
  {
    "x": "bal antene",
    "y": " b. bale antene"
  },
  {
    "x": "balabîyayîş (n)",
    "y": " b. bilabîyayîş (n)"
  },
  {
    "x": "balans (n)",
    "y": " balans"
  },
  {
    "x": "balantiş (n)",
    "y": " uyarı, ikaz; uyarma, ikaz etme; ihtar"
  },
  {
    "x": "balantişname (n)",
    "y": " îxtarname (n)"
  },
  {
    "x": "balate (n)",
    "y": " b. belate (n)"
  },
  {
    "x": "balaxodayîş (n)",
    "y": " süzme (inceleyerek bakma)"
  },
  {
    "x": "balcane (m)",
    "y": " 1)patlıcan\r\n~balcana sîyaye (m): patlıcan\r\n~balcanê çêleyî (zh): közlenip soyulmuş patlıcan(ın) ezmesinin yağda kızartılmış yumurta ve ezilmiş sarmısağa karıştırılmasıyla yapılan bir yemek\r\n~balcanê çêleyî (zh): közlenip soyulmuş patlıcan(ın) ezmesinin yağda kızartılmış yumurta ve ezilmiş sarmısağa karıştırılmasıyla yapılan bir yemek\r\n~2)domates \r\n~balcana sûre (m): domates"
  },
  {
    "x": "balcana sîyaye (m)",
    "y": " patlıcan"
  },
  {
    "x": "balcanê çêleyî (zh)",
    "y": " közlenip soyulmuş patlıcan(ın) ezmesinin yağda kızartılmış yumurta ve ezilmiş sarmısağa karıştırılmasıyla yapılan bir yemek"
  },
  {
    "x": "balcanê pewteyî (zh)",
    "y": " közlenip soyulmuş patlıcan(ın) ezmesinin yağda kızartılmış yumurta ve ezilmiş sarmısağa karıştırılmasıyla yapılan bir yemek"
  },
  {
    "x": "balcanê powtey (zh)",
    "y": " b. balcanê pewteyî (zh)"
  },
  {
    "x": "balcana sûre (m)",
    "y": " domates"
  },
  {
    "x": "balcani (m)",
    "y": " b. balcane (m)"
  },
  {
    "x": "balcon (m)",
    "y": " b. balcane (m)"
  },
  {
    "x": "balconi (m)",
    "y": " b. balcane (m)"
  },
  {
    "x": "balconê çeylî (zh)",
    "y": " b. balcanê çêleyî (zh)"
  },
  {
    "x": "balcûn (m)",
    "y": " b. balcane (m)"
  },
  {
    "x": "balcûni (m)",
    "y": " b. balcane (m)"
  },
  {
    "x": "balcûna suri (m)",
    "y": " b. firingî (m)"
  },
  {
    "x": "balcûnî çeylî (zh)",
    "y": " b. balcanê çêleyî (zh)"
  },
  {
    "x": "balcûnî potey (zh)",
    "y": " b. balcanê pewteyî (zh)"
  },
  {
    "x": "balçîqa (n)",
    "y": " b. belçîka (n) "
  },
  {
    "x": "baldarî (m)",
    "y": " dikkat"
  },
  {
    "x": "baldircane (m)",
    "y": " b. balcane (m)"
  },
  {
    "x": "balduz (m)",
    "y": " b. balduze (m)"
  },
  {
    "x": "balduze (m)",
    "y": " baldız"
  },
  {
    "x": "baldûzi (m)",
    "y": " b. balduze (m)"
  },
  {
    "x": "bale-I (m)",
    "y": " dikkat\r\n~Ti bala xo ser ne./Ti bala xo bide. (Sen dikkat et.) "
  },
  {
    "x": "bala xo dayene",
    "y": " süzmek (inceleyerek bakmak)"
  },
  {
    "x": "bale antene",
    "y": " ikaz etmek, uyarmak"
  },
  {
    "x": "bale-II (n)",
    "y": " bale"
  },
  {
    "x": "baleantiş (n)",
    "y": " b. balantiş (n)"
  },
  {
    "x": "balerîne (m)",
    "y": " balerin "
  },
  {
    "x": "balerînîye (m)",
    "y": " balerinlik"
  },
  {
    "x": "balet (n)",
    "y": " balet "
  },
  {
    "x": "baletîye (m)",
    "y": " baletlik"
  },
  {
    "x": "balê",
    "y": " hiç olmazsa, hiç değilse, bari"
  },
  {
    "x": "balicane (m)",
    "y": " b. balcane (m)"
  },
  {
    "x": "balicon (m)",
    "y": " b. balcane (m)"
  },
  {
    "x": "balike (m)",
    "y": " bir kabak çeşidi"
  },
  {
    "x": "baliki (m)",
    "y": " balike (m)"
  },
  {
    "x": "balişna (m)",
    "y": " yastık\r\n~balişnaya derzînan (m): iğnelik, iğne yastığı, iğnedenlik\r\n~balişnaya estune (m): çatıdaki ana kirişle onu taşıyan sütun arasına konulan ağaçtan sütun başı"
  },
  {
    "x": "balişnaya derzînan (m)",
    "y": " iğnelik, iğne yastığı, iğnedenlik"
  },
  {
    "x": "balişnaya estune (m)",
    "y": " çatıdaki ana kirişle onu taşıyan sütun arasına konulan ağaçtan sütun başı"
  },
  {
    "x": "balişnayê berzkerdişî (zh)",
    "y": " kaldırma yastıkları (sıkıştırılmış hava ile çalışan)"
  },
  {
    "x": "balişnê derzînûn (m)",
    "y": " b. balişnaya derzînan (m)"
  },
  {
    "x": "balix, -e-I",
    "y": " ergen, akil baliğ"
  },
  {
    "x": "balix bîyayene",
    "y": " b. balix bîyene"
  },
  {
    "x": "balix bîyene",
    "y": " ergenleşmek"
  },
  {
    "x": "balix-II (n)",
    "y": " b. ballix (n)"
  },
  {
    "x": "balixbîyayîş (n)",
    "y": " ergenleşme"
  },
  {
    "x": "balixey (m)",
    "y": " b. balixîye (m)"
  },
  {
    "x": "balixey... ameyene",
    "y": " b. balixî ... ameyene"
  },
  {
    "x": "balixey... ameyîş",
    "y": " b. balixî ... ameyîş"
  },
  {
    "x": "balixey... ardene",
    "y": " b. balixî ardene"
  },
  {
    "x": "balixey... ardiş",
    "y": " b. balixîardiş (n)"
  },
  {
    "x": "balixê (m)",
    "y": " b. balixî (m)"
  },
  {
    "x": "balixi (m) ",
    "y": " b. balix, -e"
  },
  {
    "x": "balixî (m)",
    "y": " ergenlik"
  },
  {
    "x": "balixî ... ameyene",
    "y": " hayran kalmak, çok beğenmek, hayran olmak"
  },
  {
    "x": "balixî ... ameyîş",
    "y": " hayran kalma, çok beğenme, hayran olma"
  },
  {
    "x": "balixî ardene",
    "y": " hayran kalmak, çok beğenmek, hayran olmak"
  },
  {
    "x": "balixîardiş (n)",
    "y": " hayran kalma, çok beğenme, hayran olma"
  },
  {
    "x": "balixîye (m)",
    "y": " ergenlik"
  },
  {
    "x": "balî (m)",
    "y": " b. belû-I (m)"
  },
  {
    "x": "balîcane (m)",
    "y": " b. balcane (m)"
  },
  {
    "x": "balîcani (m)",
    "y": " b. balcane (m)"
  },
  {
    "x": "balîmase (n)",
    "y": " b. belûmase (n)"
  },
  {
    "x": "balîna, -ye",
    "y": " balina"
  },
  {
    "x": "balîsna (m)",
    "y": " b. balişna (m)"
  },
  {
    "x": "balîşna (m)",
    "y": " b. balişna (m)"
  },
  {
    "x": "balîşna ustine (m)",
    "y": " b. balişnaya estune (m)"
  },
  {
    "x": "balîye (m)",
    "y": " b. belû-I (m)"
  },
  {
    "x": "balkanij, -e",
    "y": " Balkanlı"
  },
  {
    "x": "Balkanî (zh)",
    "y": " Balkanlar"
  },
  {
    "x": "balkêş, -e",
    "y": " ilginç, enteresan, dikkat çekici, dikkate değer"
  },
  {
    "x": "balkêşîye (m)",
    "y": " ilginçlik, enteresanlık"
  },
  {
    "x": "balkon (n)",
    "y": " b. balkone (m)"
  },
  {
    "x": "balkone (m)",
    "y": " balkon, ayvan, eyvan"
  },
  {
    "x": "ballix (n)",
    "y": " arı kovanının konduğu yer"
  },
  {
    "x": "ballî (m)",
    "y": " b. belû-I (m)"
  },
  {
    "x": "ballû (m)",
    "y": " b. belû-I (m)"
  },
  {
    "x": "balmiş bîyayene",
    "y": " b. bolmîş bîyene"
  },
  {
    "x": "balmiş bîyene",
    "y": " b. bolmîş bîyene"
  },
  {
    "x": "balmişbîyayîş (n)",
    "y": " b. bolmîşbîyayîş (n)"
  },
  {
    "x": "balmîş bîyayene",
    "y": " b. bolmîş bîyene"
  },
  {
    "x": "balmîş bîyene",
    "y": " b. bolmîş bîyene"
  },
  {
    "x": "balmîşbîyayîş (n)",
    "y": " b. bolmîşbîyayîş (n)"
  },
  {
    "x": "balo (n)",
    "y": " balo "
  },
  {
    "x": "balone (m)",
    "y": " balon"
  },
  {
    "x": "balqon (n)",
    "y": " b. balkone (m)"
  },
  {
    "x": "balqone (m)",
    "y": " b. balkone (m)"
  },
  {
    "x": "balqoni (m)",
    "y": " b. balkone (m)"
  },
  {
    "x": "balqun (n)",
    "y": " b. balkone (m)"
  },
  {
    "x": "balquni (m)",
    "y": " b. balkone (m)"
  },
  {
    "x": "balsam (n)",
    "y": " balsam"
  },
  {
    "x": "balte (n)",
    "y": " 1)balta (büyük balta) \r\n~2)kesici tarafı yarım daire biçiminde olan bir savaş aleti"
  },
  {
    "x": "balteyek (n)",
    "y": " baltacık"
  },
  {
    "x": "Baltik (n)",
    "y": " Baltık"
  },
  {
    "x": "baltize (m)",
    "y": " b. balduze (m)"
  },
  {
    "x": "baltuzi (m)",
    "y": " b. balduze (m)"
  },
  {
    "x": "baltûze (m)",
    "y": " b. balduze (m)"
  },
  {
    "x": "balu (m)",
    "y": " b. belû-I (m)"
  },
  {
    "x": "baluge-I (m)",
    "y": " tavuk götü"
  },
  {
    "x": "baluge-II (m)",
    "y": " b. balûge (m)"
  },
  {
    "x": "baluye (m)",
    "y": " b. belû-I (m)"
  },
  {
    "x": "balû (m)",
    "y": " b. belû-I (m)"
  },
  {
    "x": "balûg (m)",
    "y": " b. balûge (m)"
  },
  {
    "x": "balûge (m)",
    "y": " siğil"
  },
  {
    "x": "baluî (m)",
    "y": " b. belû-I (m)"
  },
  {
    "x": "balwer (n)",
    "y": " b. belûwer, -e"
  },
  {
    "x": "balya (m)",
    "y": " balya"
  },
  {
    "x": "balya kerdene",
    "y": " balyalamak"
  },
  {
    "x": "balyakerdiş (n)",
    "y": " balyalama"
  },
  {
    "x": "balye (n)",
    "y": " b. balya (m)"
  },
  {
    "x": "balyekerdiş (n)",
    "y": " b. balyakerdiş (n)"
  },
  {
    "x": "Bamako (n)",
    "y": " Bamako"
  },
  {
    "x": "bamê (m)",
    "y": " b. bamya (m)"
  },
  {
    "x": "bamîye (n)",
    "y": " b. bamya (m)"
  },
  {
    "x": "bamrî (n)",
    "y": " b. bavmarî (n)"
  },
  {
    "x": "bamya (m)",
    "y": " bamya"
  },
  {
    "x": "bamye (n)",
    "y": " b. bamya (m)"
  },
  {
    "x": "ban (n)",
    "y": " 1)ev, konut, hane\r\n~bano diqat (n): iki katlı ev\r\n~bano erdî (n): tek katlı ev\r\n~2)yapı, bina\r\n~banê kîmyayî (n): kimyasal binası\r\n~banê pey (n): kiler\r\n~bano peyên (n): kiler"
  },
  {
    "x": "banê kîmyayî (n)",
    "y": " kimyasal binası"
  },
  {
    "x": "banê pey (n)",
    "y": " kiler"
  },
  {
    "x": "banê sînema (n)",
    "y": " sinema binası"
  },
  {
    "x": "bano diqanat (n)",
    "y": " iki katlı ev"
  },
  {
    "x": "bano diqat (n)",
    "y": " iki katlı ev"
  },
  {
    "x": "bano ditebeq (n)",
    "y": " iki katlı ev"
  },
  {
    "x": "bano erdî (n)",
    "y": " tek katlı ev"
  },
  {
    "x": "bano peyên (n)",
    "y": " kiler"
  },
  {
    "x": "bano xoser (n)",
    "y": " müstakil ev"
  },
  {
    "x": "bano yewqanat (n)",
    "y": " tek katlı ev"
  },
  {
    "x": "bano yewqat (n)",
    "y": " tek katlı ev"
  },
  {
    "x": "bano yewtebeq (n)",
    "y": " tek katlı ev"
  },
  {
    "x": "bana (m)",
    "y": " çıplak"
  },
  {
    "x": "bancan (m)",
    "y": " b. balcane (m)"
  },
  {
    "x": "bancani (m)",
    "y": " b. balcane (m)"
  },
  {
    "x": "banci (n)",
    "y": " b. bocî (n)"
  },
  {
    "x": "bancî (m)",
    "y": " b. bocîye (m)"
  },
  {
    "x": "bancîy (m)",
    "y": " b. bocîye (m)"
  },
  {
    "x": "band (n)",
    "y": " 1)bant, kaset\r\n~bandê vîdeoyî (n): video bandı, video kaseti\r\n~2)sargı"
  },
  {
    "x": "band kerdene",
    "y": " bantlamak"
  },
  {
    "x": "bandê teyîbe (n)",
    "y": " teyp bandı, teyp kaseti"
  },
  {
    "x": "bandê vîdeoyî (n)",
    "y": " video bandı, video kaseti"
  },
  {
    "x": "bandaj (n)",
    "y": " bandaj"
  },
  {
    "x": "bande (m)",
    "y": " b. band (n)"
  },
  {
    "x": "bander bîyene",
    "y": " 1)öğrenmek, bellemek \r\n~2)alışmak\r\n~Ti banderê mi bîyî. (Bana alışmışsın.)"
  },
  {
    "x": "bander kerdene",
    "y": " 1)öğretmek \r\n~2)alıştırmak"
  },
  {
    "x": "banderê xo dayene",
    "y": " alışmak\r\n~To banderê xo dayo mi. (Bana alışmışsın.)"
  },
  {
    "x": "banderbeyîş (n)",
    "y": " b. banderbîyayîş (n)"
  },
  {
    "x": "banderbîyaya (m)",
    "y": " alışkan, alışkın"
  },
  {
    "x": "banderbîyaye (n)",
    "y": " alışkan, alışkın"
  },
  {
    "x": "banderbîyayeyîye (m)",
    "y": " alışkanlık, alışkınlık"
  },
  {
    "x": "banderbîyayî, -ye",
    "y": " alışkan, alışkın"
  },
  {
    "x": "banderbîyayîş (n)",
    "y": " 1)öğrenme, belleme \r\n~2)alışma"
  },
  {
    "x": "banderêxodayîş (n)",
    "y": " alışma"
  },
  {
    "x": "banderîye (m)",
    "y": " öğretim\r\n~banderîya dûrî (m): açık öğretim\r\n~mamostaya/ê banderîya akademîke: öğretim üyesi\r\n~wezîfedara/ê banderîye: öğretim görevlisi"
  },
  {
    "x": "banderîya dûrî (m)",
    "y": " açık öğretim"
  },
  {
    "x": "mamostaya/ê banderîya akademîke",
    "y": " öğretim üyesi"
  },
  {
    "x": "wezîfedara/ê banderîye",
    "y": " öğretim görevlisi"
  },
  {
    "x": "banderkerdiş (n)",
    "y": " 1)öğretme \r\n~2)alıştırma"
  },
  {
    "x": "bandir bîyene",
    "y": " b. bander bîyene"
  },
  {
    "x": "bandir kerdene",
    "y": " b. bander kerdene"
  },
  {
    "x": "bandire (m)",
    "y": " b. bandure (m)"
  },
  {
    "x": "bandirîye (m)",
    "y": " b. banderîye (m)"
  },
  {
    "x": "bandirkerdiş (n)",
    "y": " b. banderkerdiş (n)"
  },
  {
    "x": "bandî",
    "y": " b. badê"
  },
  {
    "x": "bandî der",
    "y": " b. badê"
  },
  {
    "x": "bandîna",
    "y": " b. badê"
  },
  {
    "x": "bandînî",
    "y": " b. badê"
  },
  {
    "x": "bandkerdiş (n)",
    "y": " bantlama"
  },
  {
    "x": "bandore (m)",
    "y": " b. bandure (m)"
  },
  {
    "x": "bandrol (n)",
    "y": " bandrol"
  },
  {
    "x": "bandure (m)",
    "y": " egemenlik, hâkimiyet"
  },
  {
    "x": "bane (n)",
    "y": " çıplak"
  },
  {
    "x": "bane kerdene",
    "y": " soymak (giysisini çıkarmak, elbisesini çıkarmak)"
  },
  {
    "x": "bane û banqilîz, -e",
    "y": " çırılçıplak, çırçıplak"
  },
  {
    "x": "banek (n)",
    "y": " evcik (küçücük ev)"
  },
  {
    "x": "banekerdiş (n)",
    "y": " soyma (giysisini çıkarma)"
  },
  {
    "x": "banekî (zh)",
    "y": " evcilik"
  },
  {
    "x": "banekî kay kerdene",
    "y": " evcilik oynamak"
  },
  {
    "x": "baneko (n)",
    "y": " b. banku (n)"
  },
  {
    "x": "bangerane (m)",
    "y": " b. bangêre (m)"
  },
  {
    "x": "bangerene (m)",
    "y": " b. bangêre (m)"
  },
  {
    "x": "bangêre (m)",
    "y": " yuvak, loğ, yuvgu"
  },
  {
    "x": "bangiran (m)",
    "y": " b. bangêre (m)"
  },
  {
    "x": "banik (n)",
    "y": " b. banek (n)"
  },
  {
    "x": "banikkay (n)",
    "y": " evcilik"
  },
  {
    "x": "banikkay kay kerdene",
    "y": " evcilik oynamak "
  },
  {
    "x": "banikkey (n)",
    "y": " b. banikkay (n)"
  },
  {
    "x": "banikkey key kerdene",
    "y": " b. banikkay kay kerdene"
  },
  {
    "x": "Bangî",
    "y": " Bangui"
  },
  {
    "x": "bangîri (m)",
    "y": " b. bangêre (m)"
  },
  {
    "x": "Bangkok (n)",
    "y": " Bangkok"
  },
  {
    "x": "Banjûl (n)",
    "y": " Banjul"
  },
  {
    "x": "banka (m)",
    "y": " banka"
  },
  {
    "x": "bankacî, -ye",
    "y": " b. bankaçî, -ye"
  },
  {
    "x": "bankaçî, -ye",
    "y": " bankacı"
  },
  {
    "x": "bankaçîyîye (m)",
    "y": " bankacılık"
  },
  {
    "x": "bankadar, -e",
    "y": " banker"
  },
  {
    "x": "bankamatîk (n)",
    "y": " bankamatik"
  },
  {
    "x": "bankecî, -ye",
    "y": " b. bankaçî, -ye"
  },
  {
    "x": "banker, -e",
    "y": " 1)bankacı \r\n~2)banker"
  },
  {
    "x": "banket (n)",
    "y": " (karayolu için) banket"
  },
  {
    "x": "bankila (m)",
    "y": " b. beqla (m)"
  },
  {
    "x": "bankila xatunan (m)",
    "y": " b. beqlaya xatunan (m)"
  },
  {
    "x": "bankî, -ye",
    "y": " evsel"
  },
  {
    "x": "banku (n)",
    "y": " tokaç"
  },
  {
    "x": "banlîyo (n)",
    "y": " banliyö"
  },
  {
    "x": "banqa (m)",
    "y": " b. banka (m)"
  },
  {
    "x": "banqe (n)",
    "y": " b. banka (m)"
  },
  {
    "x": "banqecî, -ye",
    "y": " b. bankaçî, -ye"
  },
  {
    "x": "bant (n)",
    "y": " b. band (n)"
  },
  {
    "x": "banyo (n)",
    "y": " banyo"
  },
  {
    "x": "bapîr (n)",
    "y": " dede, ata, büyükbaba, ecdat"
  },
  {
    "x": "bapîrik (n)",
    "y": " b. bapîr (n)"
  },
  {
    "x": "baq (n)",
    "y": " b. bax (n)"
  },
  {
    "x": "baqê solan (n)",
    "y": " b. baxê solan (n)"
  },
  {
    "x": "baqa peyîke (m)",
    "y": " baldırın arka kısmı"
  },
  {
    "x": "baqa qorrî (m)",
    "y": " uyluğun arka kısmı"
  },
  {
    "x": "baqal, -e",
    "y": " b. beqal, -e"
  },
  {
    "x": "baqil, -e",
    "y": " b. biaqil, -e"
  },
  {
    "x": "baqî, -ye",
    "y": " baki"
  },
  {
    "x": "baqla (m)",
    "y": " b. beqla (m)"
  },
  {
    "x": "baqla siltanî (m)",
    "y": " b. beqlaya sultanî (m)"
  },
  {
    "x": "baqla xatunî (m)",
    "y": " b. beqlaya xatunan (m)"
  },
  {
    "x": "baqlawa (m)",
    "y": " baklava"
  },
  {
    "x": "baqlawaya tadayîye (m)",
    "y": " burma baklava"
  },
  {
    "x": "baqlawakar, -e",
    "y": " baklavacı"
  },
  {
    "x": "baqlawakarîye (m)",
    "y": " baklavacılık"
  },
  {
    "x": "baqlawaroş, -e",
    "y": " baklavacı"
  },
  {
    "x": "baqlawaroşîye (m)",
    "y": " baklavacılık"
  },
  {
    "x": "baqlewa (m)",
    "y": " b. baqlawa (m)"
  },
  {
    "x": "baqur (n)",
    "y": " sığır sürüsü"
  },
  {
    "x": "baqûr (n)",
    "y": " b. baqur (n)"
  },
  {
    "x": "bar-I (n)",
    "y": " yük\r\n~baro giran (n): ağır yük"
  },
  {
    "x": "bar bîyayene",
    "y": " b. bar bîyene"
  },
  {
    "x": "bar bîyene",
    "y": " yüklenmek"
  },
  {
    "x": "bar kerdene",
    "y": " 1)göç etmek, göçmek \r\n~2)taşınmak (başka bir yere gitmek)\r\n~3)yüklemek (TE)"
  },
  {
    "x": "barê giranî ver de bîyene",
    "y": " gebe olmak (mecazi)"
  },
  {
    "x": "barê xo ronayene",
    "y": " doğurmak (insan için), doğum yapmak"
  },
  {
    "x": "barî bin di bîyayene",
    "y": " b. binê barî de bîyene"
  },
  {
    "x": "barî bin di mendene",
    "y": " b. binê barî de mendene"
  },
  {
    "x": "baro giran (n)",
    "y": " ağır yük"
  },
  {
    "x": "bar-II (m)",
    "y": " b. pare (m)"
  },
  {
    "x": "bar kerdene",
    "y": " b. pare kerdene"
  },
  {
    "x": "bar a kerdene",
    "y": " b. pare kerdene"
  },
  {
    "x": "bar-III (n)",
    "y": " b. behr (n)"
  },
  {
    "x": "bar-IV (n)",
    "y": " yan, nezd\r\n~“Homay barê feqîron o.” (Deyîra “Gidîyono”yî)\r\n~\t\r\n~“Xura di barê îne de edet bi.” (Serdar Roşan, “Gozêre”) (Zaten onların yanında/nezdinde adetti.)"
  },
  {
    "x": "bar-V (n)",
    "y": " bar"
  },
  {
    "x": "...bar (sf)",
    "y": " yapılabilirlik anlamı veren bir sonek"
  },
  {
    "x": "berdbar",
    "y": " taşınır (taşınabilen, götürülebilen)"
  },
  {
    "x": "kiriştbar",
    "y": " taşınır (taşınabilen, götürülebilen)"
  },
  {
    "x": "bara (m)",
    "y": " b. pare (m)"
  },
  {
    "x": "bara kerdene",
    "y": " b. pare kerdene"
  },
  {
    "x": "baraj (n)",
    "y": " baraj"
  },
  {
    "x": "barakerdiş (n)",
    "y": " b. parekerdiş (n)"
  },
  {
    "x": "barane (m)",
    "y": " bağda üzüm teveği sıralarından her biri"
  },
  {
    "x": "barani (m)",
    "y": " b. barane (m)"
  },
  {
    "x": "baraqa (m)",
    "y": " baraka"
  },
  {
    "x": "barayene",
    "y": " b. barrayene"
  },
  {
    "x": "barayîş (n)",
    "y": " b. barrayîş (n)"
  },
  {
    "x": "barayox, -e",
    "y": " b. barrayox, -e"
  },
  {
    "x": "barbar, -e",
    "y": " barbar"
  },
  {
    "x": "barbar bîyene",
    "y": " barbarlaşmak"
  },
  {
    "x": "barbarbîyayîş (n)",
    "y": " barbarlaşma"
  },
  {
    "x": "barbare (m)",
    "y": " ağlama ve feryat sesi"
  },
  {
    "x": "barbarîye (m)",
    "y": " barbarlık"
  },
  {
    "x": "barbarîzm (n)",
    "y": " barbarizm"
  },
  {
    "x": "barbarkî",
    "y": " barbarca"
  },
  {
    "x": "barber, -e",
    "y": " hamal"
  },
  {
    "x": "barbîyayîş (n)",
    "y": " yüklenme"
  },
  {
    "x": "barbunya (m)",
    "y": " barbunya "
  },
  {
    "x": "barbunye (m)",
    "y": " b. barbunya (m)"
  },
  {
    "x": "barbûnya (m)",
    "y": " b. barbunya (m)"
  },
  {
    "x": "barc",
    "y": " b. berz, -e"
  },
  {
    "x": "barcê",
    "y": " b. badê"
  },
  {
    "x": "barcî",
    "y": " b. badê"
  },
  {
    "x": "barcîyan",
    "y": " b. badê"
  },
  {
    "x": "barcîyon",
    "y": " b. badê"
  },
  {
    "x": "barcîyûn",
    "y": " b. badê"
  },
  {
    "x": "barçawiş, -e",
    "y": " b. başçawiş, -e"
  },
  {
    "x": "barçawîş, -e",
    "y": " b. başçawiş, -e  "
  },
  {
    "x": "barçawuş, -e",
    "y": " b. başçawiş, -e"
  },
  {
    "x": "bardar, -e",
    "y": " yüklü"
  },
  {
    "x": "bardax (n)",
    "y": " b. bardaxe (m)"
  },
  {
    "x": "bardaxê çay (n)",
    "y": " b. bardaxa çay (m)"
  },
  {
    "x": "bardaxe (m)",
    "y": " bardak\r\n~bardaxa awe (m): su bardağı\r\n~bardaxa çay (m): çay bardağı"
  },
  {
    "x": "bardaxa awe (m)",
    "y": " su bardağı"
  },
  {
    "x": "bardaxa çay (m)",
    "y": " çay bardağı"
  },
  {
    "x": "bardene",
    "y": " b. berdene"
  },
  {
    "x": "bardi (m)",
    "y": " b. berde (m)"
  },
  {
    "x": "bardiş (n)",
    "y": " b. berdiş (n)"
  },
  {
    "x": "bare-I (m)",
    "y": " b. pare (m)"
  },
  {
    "x": "bare kerdene",
    "y": " b. pare kerdene"
  },
  {
    "x": "bare-II (n)",
    "y": " mülk (orman, tarla vb. arazi için)"
  },
  {
    "x": "barekerdiş (n)",
    "y": " b. parekerdiş (n)"
  },
  {
    "x": "barete (m)",
    "y": " barete "
  },
  {
    "x": "barê bijêkan (n)",
    "y": " b. barê bizêkan (n)"
  },
  {
    "x": "barê bizêkan (n)",
    "y": " oğlaklar davara katılırken düzenlenen bir eğlence"
  },
  {
    "x": "barêk",
    "y": " yüklü, çok fazla, pek çok\r\n~Seba hêrînayîşê nê banî barêk pereyî dayê. (Bu evi satın almak için yüklü bir para vermiş.)"
  },
  {
    "x": "bargîn (m)",
    "y": " b. bangêre (m)"
  },
  {
    "x": "bargîni (m)",
    "y": " b. bangêre (m)"
  },
  {
    "x": "bargîr (n)",
    "y": " b. bergîr (n)"
  },
  {
    "x": "bari-I (m)",
    "y": " b. pare (m)"
  },
  {
    "x": "bari kerdene",
    "y": " b. pare kerdene"
  },
  {
    "x": "bari-II (n)",
    "y": " b. barî, -ye"
  },
  {
    "x": "bari-bari",
    "y": " b. barî-barî"
  },
  {
    "x": "bari bîyene",
    "y": " b. barî bîyene"
  },
  {
    "x": "bari kerdene",
    "y": " b. barî kerdene"
  },
  {
    "x": "baribîyayîş (n)",
    "y": " b. barîbîyayîş (n)"
  },
  {
    "x": "barik",
    "y": " b. barîk, -e"
  },
  {
    "x": "barikerdiş-I (n)",
    "y": " b. parekerdiş (n)"
  },
  {
    "x": "barikerdiş-II (n)",
    "y": " b. barîkerdiş (n)"
  },
  {
    "x": "baringanî (m)",
    "y": " b. bangêre(m)"
  },
  {
    "x": "barit (n)",
    "y": " b. barut (n)"
  },
  {
    "x": "barî, -ye-I",
    "y": " ince"
  },
  {
    "x": "barî bîyene",
    "y": " incelmek"
  },
  {
    "x": "barî kerdene",
    "y": " inceltmek"
  },
  {
    "x": "barî-barî",
    "y": " ince ince"
  },
  {
    "x": "barî-II (m)",
    "y": " b. barrî (m)"
  },
  {
    "x": "barî kerdene",
    "y": " b. barrî kerdene"
  },
  {
    "x": "barî ro kerdene",
    "y": " b. barrî ro kerdene"
  },
  {
    "x": "barî û çizî (m)",
    "y": " b. barrî û çizî (m)"
  },
  {
    "x": "barî û qarî (m)",
    "y": " b. barrî û qarî (m)"
  },
  {
    "x": "barî-carî (m)",
    "y": " b. barrî-carrî (m)"
  },
  {
    "x": "barî-çizî (m)",
    "y": " b. barrî-çizî (m)"
  },
  {
    "x": "barî-qarî (m)",
    "y": " b. barrî-qarî (m)"
  },
  {
    "x": "barîbîyayîş (n)",
    "y": " incelme"
  },
  {
    "x": "barîçek, -e",
    "y": " incecik"
  },
  {
    "x": "barîk, -e",
    "y": " incecik"
  },
  {
    "x": "barîkat (n)",
    "y": " barikat"
  },
  {
    "x": "barîkek, -e",
    "y": " incecik"
  },
  {
    "x": "barîkerdiş-I (n)",
    "y": " inceltme"
  },
  {
    "x": "barîkerdiş-II (n)",
    "y": " b. barrîkerdiş (n)"
  },
  {
    "x": "barîkênî (m)",
    "y": " incelik"
  },
  {
    "x": "barîrokerdiş (n)",
    "y": " b. barrîrokerdiş (n)"
  },
  {
    "x": "barîyer (n)",
    "y": " bariyer"
  },
  {
    "x": "barîyerê şewatî (n)",
    "y": " yangın bariyeri"
  },
  {
    "x": "barîyey (m)",
    "y": " b. barîyîye (m), barîyî (m)"
  },
  {
    "x": "barîyê (m)",
    "y": " b. barîyîye (m), barîyî (m)"
  },
  {
    "x": "barîyênî (m)",
    "y": " incelik"
  },
  {
    "x": "barîyî (m)",
    "y": " incelik"
  },
  {
    "x": "barîyîye (m)",
    "y": " incelik"
  },
  {
    "x": "barkerda (m)",
    "y": " yüklü"
  },
  {
    "x": "barkerde (n)",
    "y": " yüklü"
  },
  {
    "x": "barkerdiş-I (n)",
    "y": " 1)göç, göç etme, göçme \r\n~2)taşınma (başka bir yere gitme) \r\n~3)yükleme (TE), şarj"
  },
  {
    "x": "barkerdiş-II (n)",
    "y": " b. parekerdiş (n)"
  },
  {
    "x": "barkerdî, ye",
    "y": " yüklü"
  },
  {
    "x": "barkêş, -e",
    "y": " hamal"
  },
  {
    "x": "barkîn",
    "y": " b. bimbarek, -e"
  },
  {
    "x": "barmayene",
    "y": " b. bermayene"
  },
  {
    "x": "barmayîş (n)",
    "y": " b. bermayîş (n)"
  },
  {
    "x": "barmen, -e",
    "y": " barmen"
  },
  {
    "x": "barmi (n)",
    "y": " b. berme (n)"
  },
  {
    "x": "baro (n)",
    "y": " baro"
  },
  {
    "x": "barometre (n)",
    "y": " basınçölçer, barometre "
  },
  {
    "x": "baron (n)",
    "y": " baron"
  },
  {
    "x": "baronese (m)",
    "y": " barones"
  },
  {
    "x": "baroni (m)",
    "y": " b. barane (m)"
  },
  {
    "x": "baronîye (m)",
    "y": " baronluk"
  },
  {
    "x": "baroskop (n)",
    "y": " baroskop "
  },
  {
    "x": "barrayene",
    "y": " 1)ağlamak \r\n~2)bağırmak \r\n~3)melemek (koyun ve keçi için)"
  },
  {
    "x": "barrayeni",
    "y": " b. barrayene"
  },
  {
    "x": "barrayîş (n)",
    "y": " 1)ağlama \r\n~2)bağırma \r\n~3)meleme (koyun ve keçi için)"
  },
  {
    "x": "barrayox, -e",
    "y": " ağlayan"
  },
  {
    "x": "barrbar (m)",
    "y": " b. barbare (m)"
  },
  {
    "x": "barrbarri (m)",
    "y": " b. barbare (m)"
  },
  {
    "x": "barre (n)",
    "y": " ağlama"
  },
  {
    "x": "barreni",
    "y": " b. barrayene "
  },
  {
    "x": "barri (n)",
    "y": " b. barre (n)"
  },
  {
    "x": "barrî (m)",
    "y": " ağlama sesi"
  },
  {
    "x": "barrî û çizî (m)",
    "y": " ağlama ve feryat sesi"
  },
  {
    "x": "barrî û qarî (m)",
    "y": " ağlama ve feryat sesi"
  },
  {
    "x": "barrî kerdene",
    "y": " ağlatmak"
  },
  {
    "x": "barrî ro kerdene",
    "y": " ağlatmak"
  },
  {
    "x": "barrî-carrî (m)",
    "y": " ağlama ve feryat sesi"
  },
  {
    "x": "barrî-çizî (m)",
    "y": " ağlama ve feryat sesi"
  },
  {
    "x": "barrî-qarî (m)",
    "y": " ağlama ve feryat sesi"
  },
  {
    "x": "barrîkerdiş (n)",
    "y": " ağlatma"
  },
  {
    "x": "barrîni",
    "y": " b. barrayene "
  },
  {
    "x": "barrîrokerdiş (n)",
    "y": " ağlatma"
  },
  {
    "x": "bars (n)",
    "y": " oğul (arılar için)"
  },
  {
    "x": "bars dayene",
    "y": " oğullanmak (arılar için) \r\n~Mêse bars da. (Arı oğullandı.)  "
  },
  {
    "x": "barut (n)",
    "y": " barut"
  },
  {
    "x": "barûni (m)",
    "y": " b. barane (m)"
  },
  {
    "x": "barût (n)",
    "y": " b. barut (n)"
  },
  {
    "x": "baryek",
    "y": " b. barîk, -e"
  },
  {
    "x": "barz, -i",
    "y": " b. berz, -e"
  },
  {
    "x": "barz bîyene",
    "y": " b. berz bîyene"
  },
  {
    "x": "barz kardene",
    "y": " b. berz kerdene"
  },
  {
    "x": "barzbîyayîş (n)",
    "y": " b. berzbîyayîş (n)"
  },
  {
    "x": "barzek, -i",
    "y": " b. berzek, -e"
  },
  {
    "x": "barzey (m)",
    "y": " b. berzîye (m)"
  },
  {
    "x": "barzeya pêle (m)",
    "y": " b. berzîya pêlî (m)"
  },
  {
    "x": "barzingan, -e",
    "y": " b. bazirgan, -e"
  },
  {
    "x": "barzinganîye (m)",
    "y": " b. bazirganîye (m)"
  },
  {
    "x": "barzîn (n)",
    "y": " b. berzîn (n)"
  },
  {
    "x": "barzkardiş (n)",
    "y": " b. berzkerdiş (n)"
  },
  {
    "x": "bas kerdene",
    "y": " b. behs kerdene"
  },
  {
    "x": "basarax (n)",
    "y": " basiret, sağgörü\r\n~Homayî/Heqî basaraxê to girê do. (Tanrı senin basiretini bağlamış.)"
  },
  {
    "x": "basarox (n)",
    "y": " b. basarax (n)"
  },
  {
    "x": "basirax (n)",
    "y": " b. basarax (n)"
  },
  {
    "x": "basîret (n)",
    "y": " b. besîret (n)"
  },
  {
    "x": "basît, -e",
    "y": " b. besît, -e"
  },
  {
    "x": "bask (n)",
    "y": " 1)kanat, kol\r\n~basko çep (n): sol kanat, sol kol\r\n~basko rast/raşt (n): sağ kanat, sağ kol,\r\n~2)kulaç\r\n~bask eştene: kulaç atmak"
  },
  {
    "x": "basko çep (n)",
    "y": " sol kanat, sol kol"
  },
  {
    "x": "basko rast/raşt (n)",
    "y": " sağ kanat, sağ kol,"
  },
  {
    "x": "bask eştene",
    "y": " kulaç atmak"
  },
  {
    "x": "bask eşteni",
    "y": " b. bask eştene"
  },
  {
    "x": "basket (n)",
    "y": " basket"
  },
  {
    "x": "basketbol (n)",
    "y": " basketbol "
  },
  {
    "x": "Baskîl (n)",
    "y": " Baskil"
  },
  {
    "x": "basma (n)",
    "y": " b. basme-I (n)"
  },
  {
    "x": "basme-I (n)",
    "y": " basma (kumaş için)"
  },
  {
    "x": "basme-II (n)",
    "y": " basma (tezek)"
  },
  {
    "x": "basmecî (m)",
    "y": " çir (kurutulmuş kayısı)"
  },
  {
    "x": "basmecîye (m)",
    "y": "  çir (kurutulmuş kayısı)"
  },
  {
    "x": "basmezeyî (m)",
    "y": " b. basmecî (m)"
  },
  {
    "x": "basmica (m)",
    "y": " b. basmecî (m)"
  },
  {
    "x": "basmicê (m)",
    "y": " b. basmecî (m)"
  },
  {
    "x": "bastêq (n)",
    "y": " pestil\r\n~bastêqo qilênaye (n): kızartılmış pestil"
  },
  {
    "x": "bastêqo qilênaye (n)",
    "y": " kızartılmış pestil"
  },
  {
    "x": "bastêx (n)",
    "y": " b. bastêq (n)"
  },
  {
    "x": "bastêxo qilênaye (n)",
    "y": " b. bastêqo qilênaye (n)"
  },
  {
    "x": "bastêxo qilnaye (n)",
    "y": " b. bastêqo qilênaye (n)"
  },
  {
    "x": "bastiq (n)",
    "y": " b. bastêq (n)"
  },
  {
    "x": "bastix (n)",
    "y": " b. bastêq (n)"
  },
  {
    "x": "bastîeq (n)",
    "y": " b. bastêq (n)"
  },
  {
    "x": "bastîyeq (n)",
    "y": " b. bastêq (n)"
  },
  {
    "x": "bastone (m)",
    "y": " baston"
  },
  {
    "x": "bastoni (m)",
    "y": " b. bastone (m)"
  },
  {
    "x": "bastum (m)",
    "y": " b. bastone (m)"
  },
  {
    "x": "basur (n)",
    "y": " basur"
  },
  {
    "x": "basur bîyene",
    "y": " basuru olmak"
  },
  {
    "x": "baş, -e",
    "y": " iyi\r\n~Ez baş a./ Ez de baş ane. (Ben iyiyim.)"
  },
  {
    "x": "baş bîyayene",
    "y": " b. baş bîyene"
  },
  {
    "x": "baş bîyene",
    "y": " 1)iyi olmak \r\n~2)iyileşmek"
  },
  {
    "x": "baş gurenayene",
    "y": " değerlendirmek, kıymetlendirmek"
  },
  {
    "x": "baş şuxulnayene",
    "y": " değerlendirmek, kıymetlendirmek"
  },
  {
    "x": "baş xebitnayene",
    "y": " değerlendirmek, kıymetlendirmek"
  },
  {
    "x": "başaq (n)",
    "y": " b. başax (n)"
  },
  {
    "x": "başaq kerdene",
    "y": " b. başax kerdene"
  },
  {
    "x": "başaqkerdiş (n)",
    "y": " b. başaxkerdiş (n)"
  },
  {
    "x": "başaqkerdox, -i",
    "y": " b. başaxkerdox, -e"
  },
  {
    "x": "başax (n)",
    "y": " taraş (tahıl, üzüm, badem vb. için)"
  },
  {
    "x": "başax kerdene",
    "y": " taraşlamak, başak toplamak (tahıl, üzüm, badem vb. için)"
  },
  {
    "x": "başaxkardox, -i",
    "y": " b. başaxkerdox, -e"
  },
  {
    "x": "başaxkerdiş (n)",
    "y": " taraşlama (tahıl, üzüm, badem vb. için)"
  },
  {
    "x": "başaxkerdox, -e",
    "y": " başakçı"
  },
  {
    "x": "başaxkerdoxi (m)",
    "y": " b. başaxkerdox, -e"
  },
  {
    "x": "başbîyayîş (n)",
    "y": " 1)iyileşme, sağalma \r\n~2)iyi olma"
  },
  {
    "x": "başçawiş, -e",
    "y": " başçavuş"
  },
  {
    "x": "başçawişî (m)",
    "y": " başçavuşluk"
  },
  {
    "x": "başçawişîye (m)",
    "y": " başçavuşluk"
  },
  {
    "x": "başçawîş, -e",
    "y": " b. başçawiş, -e"
  },
  {
    "x": "başçawuş, -e",
    "y": " b. başçawiş, -e"
  },
  {
    "x": "başewal",
    "y": " b. bêşelwarî (n, m)"
  },
  {
    "x": "başey (m)",
    "y": " b. başî (m)"
  },
  {
    "x": "başê (m)",
    "y": " b. başî (m)"
  },
  {
    "x": "başêr kerdene (TE)",
    "y": " yükseltmek"
  },
  {
    "x": "başgurenayîş (n)",
    "y": " değerlendirme, kıymetlendirme"
  },
  {
    "x": "başi (m)",
    "y": " b. başe (m)"
  },
  {
    "x": "başiwal",
    "y": " b. bêşelwarî (n, m)"
  },
  {
    "x": "başî (m)",
    "y": " iyilik"
  },
  {
    "x": "başîyayene",
    "y": " söylenmek (kendi kendine konuşmak)"
  },
  {
    "x": "başîyayêne",
    "y": " b. başîyayene"
  },
  {
    "x": "başîyayîş (n)",
    "y": " söylenme (kendi kendine konuşma)"
  },
  {
    "x": "başîye (m)",
    "y": " iyilik"
  },
  {
    "x": "başna (m)",
    "y": " b. balişna (m)"
  },
  {
    "x": "başneki (m)",
    "y": " b. paşna-I (m)"
  },
  {
    "x": "başneka postalî (m)",
    "y": " b. paşnaya postalî (m)"
  },
  {
    "x": "başneka solî (m)",
    "y": " b. paşnaya solî (m)"
  },
  {
    "x": "başneka sowlî (m)",
    "y": " b. paşnaya solî (m)"
  },
  {
    "x": "başnekey sewlî (m)",
    "y": " b. paşnaya solî (m)"
  },
  {
    "x": "başnekey solî (m)",
    "y": " b. paşnaya solî (m)"
  },
  {
    "x": "başşuxulnayîş (n)",
    "y": " değerlendirme, kıymetlendirme"
  },
  {
    "x": "başt (n)",
    "y": " b. wet (n)"
  },
  {
    "x": "başt a",
    "y": " b. wet ra"
  },
  {
    "x": "başta",
    "y": " b. weta"
  },
  {
    "x": "naşt û başt",
    "y": " b. nat û wet"
  },
  {
    "x": "baştiq (n)",
    "y": " b. bastêq (n)"
  },
  {
    "x": "baştix",
    "y": " b. bastêq (n)"
  },
  {
    "x": "başûr (n)",
    "y": " güney\r\n~başûrê rojawanî (n): güneybatı\r\n~başûrê rojhelatî (n): güneydoğu"
  },
  {
    "x": "başûrê rojawanî (n)",
    "y": " güneybatı"
  },
  {
    "x": "başûrê rojhelatî (n)",
    "y": " güneydoğu"
  },
  {
    "x": "başxebitnayîş (n)",
    "y": " değerlendirme, kıymetlendirme"
  },
  {
    "x": "batal (n)",
    "y": " b. betal (n)"
  },
  {
    "x": "batalê (m)",
    "y": " b. betalî (m)"
  },
  {
    "x": "batalî (m)",
    "y": " b. betalî (m)"
  },
  {
    "x": "batalîyayene",
    "y": " b. betilîyayene"
  },
  {
    "x": "batalîyayîş (n)",
    "y": " b. betilîyayîş (n)"
  },
  {
    "x": "batalnayene",
    "y": " b. betilnayene"
  },
  {
    "x": "batalnayîş (n)",
    "y": " b. betilnayîş (n)"
  },
  {
    "x": "batane (n)",
    "y": " diyafram"
  },
  {
    "x": "batanîya (m)",
    "y": " b. betanîya (m)"
  },
  {
    "x": "batanîye (m)",
    "y": " b. betanîya (m)"
  },
  {
    "x": "batarya (m)",
    "y": " batarya"
  },
  {
    "x": "batax (n)",
    "y": " batak"
  },
  {
    "x": "bati (n)",
    "y": " haya (erkeklik bezi), taşak, erbezi, husye"
  },
  {
    "x": "batil, -e",
    "y": " batıl"
  },
  {
    "x": "batilîyayîş (n)",
    "y": " b. betilîyayîş (n)"
  },
  {
    "x": "batim (n)",
    "y": " b. batin (n)"
  },
  {
    "x": "batin (n)",
    "y": " gizli"
  },
  {
    "x": "batinî, -ye",
    "y": " batıni, ezoterik, içrek"
  },
  {
    "x": "batinîyîye (m)",
    "y": " batinilik, içreklik"
  },
  {
    "x": "batmice (m)",
    "y": " b. basmecî (m)"
  },
  {
    "x": "batûnîya (m)",
    "y": " b. betanîya (m)"
  },
  {
    "x": "batûnya (m)",
    "y": " b. betanîya (m)"
  },
  {
    "x": "bav û kal",
    "y": " ata, ecdat"
  },
  {
    "x": "bava (n)",
    "y": " b. baba (n)"
  },
  {
    "x": "bavo",
    "y": " baba (hitap hali)"
  },
  {
    "x": "bavagunî (m)",
    "y": " b. babagundî (m)"
  },
  {
    "x": "bavalix (n)",
    "y": " b. babalix"
  },
  {
    "x": "bavayey (m)",
    "y": " b. babayîye (m)"
  },
  {
    "x": "bavayênî (m)",
    "y": " babayênî (m)"
  },
  {
    "x": "bavayîn (m)",
    "y": " babayênî (m)"
  },
  {
    "x": "bavayîye (m)",
    "y": " b. babayîye (m)"
  },
  {
    "x": "bavek",
    "y": " b. bavok, -e"
  },
  {
    "x": "bavekê ... bîyene",
    "y": " b. bavokê ... bîyene"
  },
  {
    "x": "bavet-I (n)",
    "y": " b. babet-I (n)"
  },
  {
    "x": "bavet-II (m)",
    "y": " b. babete (m)"
  },
  {
    "x": "bavete (m)",
    "y": " b. babete (m)"
  },
  {
    "x": "baveti (m)",
    "y": " b. babete (m)"
  },
  {
    "x": "bavî (n)",
    "y": " b. babî (n)"
  },
  {
    "x": "bavo",
    "y": " baba (hitap hali)"
  },
  {
    "x": "bavkal (n)",
    "y": " dede, ata, büyükbaba, ecdat"
  },
  {
    "x": "bavmarî (n)",
    "y": " üvey baba, babalık"
  },
  {
    "x": "bavok-I (n)",
    "y": " emanet"
  },
  {
    "x": "bavok, -e-II",
    "y": " bekleyen, bekleyici"
  },
  {
    "x": "bavokê ... bîyene",
    "y": " -i beklemek"
  },
  {
    "x": "bavokê ... vindetene",
    "y": " -i beklemek"
  },
  {
    "x": "bavokê ... vinetene",
    "y": " b. bavokê ... vindetene"
  },
  {
    "x": "baw",
    "y": " 1)b. babî (n) \r\n~2)b. bawo"
  },
  {
    "x": "bawar bîyayene",
    "y": " b. bawer bîyene"
  },
  {
    "x": "bawar kerdene",
    "y": " b. bawer kerdene"
  },
  {
    "x": "bawarî (m)",
    "y": " b. bawerîye (m), bawerî (m)"
  },
  {
    "x": "bawç (n)",
    "y": " b. bawk (n)"
  },
  {
    "x": "bawe, -ye",
    "y": " b. pabe, -ye"
  },
  {
    "x": "bawê ... bîyene",
    "y": " b. pabeyê ... bîyene"
  },
  {
    "x": "bawe-III (n)",
    "y": " b. babî (n)"
  },
  {
    "x": "bawer bîyene",
    "y": " güvenmek"
  },
  {
    "x": "bawer kerdene",
    "y": " inanmak, güvenmek"
  },
  {
    "x": "bawerbîyayîş (n)",
    "y": " inan, inanma"
  },
  {
    "x": "bawerey (m)",
    "y": " b. bawerîye (m), bawerî (m) "
  },
  {
    "x": "bawerê (m)",
    "y": " b. bawerîye (m), bawerî (m)"
  },
  {
    "x": "bawerî (m)",
    "y": " iman, inan, inanç, akide"
  },
  {
    "x": "bawerî bi ... bîyene",
    "y": " -e güvenilmek"
  },
  {
    "x": "bawerîye (m)",
    "y": " iman, inan, inanç, akide\r\n~bawerîya puçe (m): batıl inanç, batıl itikat, boş inanç\r\n~bawerîya xelete (m): batıl"
  },
  {
    "x": "bawerîya dînî (m)",
    "y": " akide"
  },
  {
    "x": "bawerîya puçe (m)",
    "y": " batıl inanç, batıl itikat, boş inanç"
  },
  {
    "x": "bawerîya xelete (m)",
    "y": " batıl"
  },
  {
    "x": "bawerkerdiş (n)",
    "y": " inan, inanma"
  },
  {
    "x": "bawerkerdox (n)",
    "y": " inançlı, inanlı, mümin"
  },
  {
    "x": "bawerkerdoxe (m)",
    "y": " inançlı kız veya kadın, inanlı kız veya kadın, mümine"
  },
  {
    "x": "bawermend (n)",
    "y": " inançlı, inanlı, mümin"
  },
  {
    "x": "bawermende (m)",
    "y": " inançlı kız veya kadın, inanlı kız veya kadın, mümine"
  },
  {
    "x": "bawêrî (m)",
    "y": " b. bawerî (m)"
  },
  {
    "x": "bawi (n)",
    "y": " b. babî (n)"
  },
  {
    "x": "bawik (n)",
    "y": " b. bawk (n)"
  },
  {
    "x": "bawil (n)",
    "y": " b. bawule (m)"
  },
  {
    "x": "bawili (m)",
    "y": " b. bawule (m)"
  },
  {
    "x": "bawir (m)",
    "y": " b. bawerî (m)"
  },
  {
    "x": "bawirîye (m)",
    "y": " b. bawerîye (m), bawerî (m)"
  },
  {
    "x": "bawî (n)",
    "y": " b. babî (n)"
  },
  {
    "x": "bawo",
    "y": " baba (hitap hali)"
  },
  {
    "x": "bawk (n)",
    "y": " baba"
  },
  {
    "x": "bawko",
    "y": " babacığım (hitap hali)"
  },
  {
    "x": "bawkal (n)",
    "y": " dede, ata, büyükbaba, ecdat"
  },
  {
    "x": "bawkalik (n)",
    "y": " 1)dedecik \r\n~2)uğurböceği"
  },
  {
    "x": "bawkila (m)",
    "y": " b. beqla (m)"
  },
  {
    "x": "bawlî",
    "y": " b. bellî, -ye-I"
  },
  {
    "x": "bawol (n)",
    "y": " b. bawule (m)"
  },
  {
    "x": "bawu (n)",
    "y": " b. baba (n)"
  },
  {
    "x": "bawule (m)",
    "y": " bavul"
  },
  {
    "x": "bawuli (m)",
    "y": " b. bawule (m)"
  },
  {
    "x": "bax (n)-I",
    "y": " bağ (üzüm bağı)"
  },
  {
    "x": "bax û bostan",
    "y": " bağlık bahçelik"
  },
  {
    "x": "bax (n)-II",
    "y": " bağ (bağlamak için kullanılan)"
  },
  {
    "x": "baxê solan (n)",
    "y": " ayakkabı bağı"
  },
  {
    "x": "baxî solûn (n)",
    "y": " b. baxê solan (n)"
  },
  {
    "x": "baxaj (n)",
    "y": " b. bagaj (n)"
  },
  {
    "x": "baxban, -e",
    "y": " bağban (bağı koruyan) "
  },
  {
    "x": "baxbançî, -ye",
    "y": " bağban (bağı koruyan)"
  },
  {
    "x": "baxbançîtî (m)",
    "y": " bağbancılık"
  },
  {
    "x": "baxbançîyê (m)",
    "y": " b. baxbançîyî (m), baxbançîyîye (m)"
  },
  {
    "x": "baxbançîyî (m)",
    "y": " bağbancılık"
  },
  {
    "x": "baxbançîyîye (m)",
    "y": " bağbancılık"
  },
  {
    "x": "baxbanî (m)",
    "y": " bağbancılık"
  },
  {
    "x": "baxbanîye (m)",
    "y": " bağbancılık"
  },
  {
    "x": "baxbûn (n)",
    "y": " b. baxban, -e"
  },
  {
    "x": "baxçe (n)",
    "y": " bahçe"
  },
  {
    "x": "baxçewan, -e",
    "y": " bahçeci, bahçıvan"
  },
  {
    "x": "baxçewanîye (m)",
    "y": " bahçecilik, bahçıvanlık"
  },
  {
    "x": "baxir (n)",
    "y": " b. paxir (n)"
  },
  {
    "x": "baxistan (n)",
    "y": " bağlık"
  },
  {
    "x": "baxizîyayene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "baxiznayene",
    "y": " kıskandırmak"
  },
  {
    "x": "baxizyayene",
    "y": " b. baxizîyayene"
  },
  {
    "x": "baxusus",
    "y": " özellikle, başta"
  },
  {
    "x": "bay (n)",
    "y": " b. babî (n)"
  },
  {
    "x": "baypas (n)",
    "y": " baypas "
  },
  {
    "x": "baye-I (n)",
    "y": " b. baba (n)"
  },
  {
    "x": "baye, -ye-II",
    "y": " b. pabe, -ye"
  },
  {
    "x": "bayê ... bîyene",
    "y": " b. pabeyê ... bîyene"
  },
  {
    "x": "bayî (n)",
    "y": " b. babî (n)"
  },
  {
    "x": "bayîk (n)",
    "y": " b. bawk (n)"
  },
  {
    "x": "bayk (n)",
    "y": " b. bawk (n)"
  },
  {
    "x": "bayraqi (m)",
    "y": " b. beyraqe (m)"
  },
  {
    "x": "bayrem (n)",
    "y": " bayram (b. roşan)"
  },
  {
    "x": "bayt (n) (TE)",
    "y": " sekiz ikil, bayt"
  },
  {
    "x": "baytar, -e",
    "y": " baytar, veteriner"
  },
  {
    "x": "baz, -e-I",
    "y": " doğan"
  },
  {
    "x": "baz-II (n)",
    "y": " b. bazin (n)"
  },
  {
    "x": "baz-III (n)",
    "y": " baz\r\n~îstasyona bazî (m): baz istasyonu"
  },
  {
    "x": "îstasyona bazî (m)",
    "y": " baz istasyonu"
  },
  {
    "x": "bazalt (n)",
    "y": " bazalt"
  },
  {
    "x": "bazar (n)",
    "y": " 1)pazar (günü) (b. yewşeme) \r\n~2)pazar (yeri)\r\n~3)pazarlık\r\n~bazar dîyene: pazarlamak\r\n~bazar kerdene: pazarlık etmek"
  },
  {
    "x": "bazar dîyene",
    "y": " pazarlamak"
  },
  {
    "x": "bazar kerdene",
    "y": " pazarlık etmek"
  },
  {
    "x": "bazarca (n)",
    "y": " pazaryeri"
  },
  {
    "x": "bazardîyayîş (n)",
    "y": " pazarlama, pazarlık etme"
  },
  {
    "x": "bazarey (m)",
    "y": " b. bazarîye"
  },
  {
    "x": "bazarê (m)",
    "y": " b. bazarîye"
  },
  {
    "x": "bazarî (m)",
    "y": " pazarlık"
  },
  {
    "x": "bazarî dîyayîne",
    "y": " b. bazar dîyene"
  },
  {
    "x": "bazarî kerdene",
    "y": " b. bazar kerdene"
  },
  {
    "x": "bazarîdîyayîş (n)",
    "y": " b. bazardîyayîş (n)"
  },
  {
    "x": "bazarîker, -e",
    "y": " pazarlıkçı"
  },
  {
    "x": "bazarîkerdiş (n)",
    "y": " b. bazarkerdiş (n)"
  },
  {
    "x": "bazarîye (m)",
    "y": " pazarlık"
  },
  {
    "x": "bazarkar, -e",
    "y": " pazarcı"
  },
  {
    "x": "bazarkarîye (m)",
    "y": " pazarcılık"
  },
  {
    "x": "bazarker, -e",
    "y": " pazarlamacı"
  },
  {
    "x": "bazarkerdiş (n)",
    "y": " pazarlık etme"
  },
  {
    "x": "bazarkerîye (m)",
    "y": " pazarlamacılık"
  },
  {
    "x": "bazartî (m)",
    "y": " pazarlık"
  },
  {
    "x": "bazbazik (n)",
    "y": " b. bazin (n)"
  },
  {
    "x": "bazbend (n)",
    "y": " kolçak (kola geçirilen ve üzerinde simge bulunan bağ), pazıbent"
  },
  {
    "x": "bavagunî (m)",
    "y": " b. babagundî (m)"
  },
  {
    "x": "bazeç (n)",
    "y": " oyuncak"
  },
  {
    "x": "bazêç (n)",
    "y": " b. bazeç (n)"
  },
  {
    "x": "bazêk (n)",
    "y": " b. bask-2 (n)"
  },
  {
    "x": "bazên (n)",
    "y": " b. pazên (n)"
  },
  {
    "x": "bazgo (n)",
    "y": " b. bask-2 (n)"
  },
  {
    "x": "bazgo estene",
    "y": " b. bask eştene"
  },
  {
    "x": "bazi (n)",
    "y": " b. bazî (n)"
  },
  {
    "x": "bazik (n)",
    "y": " b. bazin (n)"
  },
  {
    "x": "bazilcan (m)",
    "y": " b. balcane (m)"
  },
  {
    "x": "bazilcûn (m)",
    "y": " balcane (m)"
  },
  {
    "x": "bazilcûna sîya (m)",
    "y": " b. balcana sîyaye (m)"
  },
  {
    "x": "bazilcûna sîyay (m)",
    "y": " b. balcana sîyaye (m)"
  },
  {
    "x": "bazilcûna sîyê (m)",
    "y": " b. balcana sîyaye (m)"
  },
  {
    "x": "bazilcûna suir (m)",
    "y": " b. balcana sûre (m)"
  },
  {
    "x": "bazilcûna sur (m)",
    "y": " b. balcana sûre (m)"
  },
  {
    "x": "bazilzan (m)",
    "y": " b. balcane (m)"
  },
  {
    "x": "bazin (n)",
    "y": " 1)bilezik\r\n~bazino sêmên (n): gümüş bilezik\r\n~2)b. pazên (n)"
  },
  {
    "x": "bazino esîr (n)",
    "y": " b. bazino hesîr (n)"
  },
  {
    "x": "bazino hesîr (n)",
    "y": " hasır bilezik"
  },
  {
    "x": "bazino sêmên (n)",
    "y": " gümüş bilezik"
  },
  {
    "x": "bazino şemên (n)",
    "y": " b. bazino sêmên (n)"
  },
  {
    "x": "bazino şemin (n)",
    "y": " b. bazino sêmên (n)"
  },
  {
    "x": "bazino şêmên (n)",
    "y": " b. bazino sêmên (n)"
  },
  {
    "x": "bazino şêmin (n)",
    "y": " b. bazino sêmên (n)"
  },
  {
    "x": "bazino tadaye (n)",
    "y": " burma bilezik"
  },
  {
    "x": "bazing (n)",
    "y": " b. bazin (n)"
  },
  {
    "x": "bazingin, -e",
    "y": " b. bazinin, -e"
  },
  {
    "x": "bazinin, -e",
    "y": " bilezikli"
  },
  {
    "x": "bazirgan, -e",
    "y": " tüccar, bezirgân\r\n~bazirgana/ê mexlî: kabzımal, toptancı"
  },
  {
    "x": "bazirgana/ê mexlî",
    "y": " kabzımal, toptancı"
  },
  {
    "x": "bazirganênî (m)",
    "y": " tüccarlık, bezirgânlık"
  },
  {
    "x": "bazirganî (m)",
    "y": " tüccarlık, bezirgânlık"
  },
  {
    "x": "bazirganîni (m)",
    "y": " b. bazirganênî (m)"
  },
  {
    "x": "bazirganî, -ye",
    "y": " 1)tüccarlık, bezirgânlık \r\n~2)ticaret \r\n~3)ticari"
  },
  {
    "x": "bazirgon, -i",
    "y": " b. bazirgan, -e"
  },
  {
    "x": "bazirgoney (m)",
    "y": " b. bazirganîye (m)"
  },
  {
    "x": "bazî (n)",
    "y": " pazı, önkol"
  },
  {
    "x": "bazme",
    "y": " b. basme-I (n)"
  },
  {
    "x": "bazu (n)",
    "y": " b. bazî (n)"
  },
  {
    "x": "bazun (m)",
    "y": " b. bazin (n)"
  },
  {
    "x": "bazuna hesîl (m)",
    "y": " b. bazino hesîr (n)"
  },
  {
    "x": "bazuna tadayî (m)",
    "y": " b. bazino tadaye (n)"
  },
  {
    "x": "bazunin, -e",
    "y": " b. bazinin, -e"
  },
  {
    "x": "bazû (n)",
    "y": " b. bazî (n)"
  },
  {
    "x": "bazûka (m)",
    "y": " bazuka"
  },
  {
    "x": "bazûbend (n)",
    "y": " b. bazbend (n)"
  },
  {
    "x": "be",
    "y": " b. bi\r\n~Be zengene ade. (Kazma ile kaz.)"
  },
  {
    "x": "be xo ra dayene",
    "y": " b. xo ra dayene"
  },
  {
    "x": "be-be kerdene",
    "y": " melemek (koyun ve keçi için)"
  },
  {
    "x": "be-bekerdiş (n)",
    "y": " meleme (koyun ve keçi için) "
  },
  {
    "x": "beacî (zh)",
    "y": " b. bacî (zh)"
  },
  {
    "x": "beacî ... ameyene",
    "y": " b. bacê ... ameyene"
  },
  {
    "x": "beacî ... ardene",
    "y": " b. bacê ... ardene"
  },
  {
    "x": "beacîyayene",
    "y": " b. bacîyayene"
  },
  {
    "x": "beacnayene",
    "y": " b. bacnayene"
  },
  {
    "x": "beajî (zh)",
    "y": " b. bacî (zh)"
  },
  {
    "x": "beajê ... ameyene",
    "y": " b. bacê ... ameyene"
  },
  {
    "x": "beajê ... ardene",
    "y": " b. bacê ... ardene"
  },
  {
    "x": "beajîyayene",
    "y": " b. bacîyayene"
  },
  {
    "x": "beajîyayîş (n)",
    "y": " b. bacîyayîş (n)"
  },
  {
    "x": "beajnayene",
    "y": " b. bacnayene"
  },
  {
    "x": "becar kerdene",
    "y": " b. bêcer kerdene"
  },
  {
    "x": "becarkerdiş (n)",
    "y": " b. bêcerkerdiş (n)"
  },
  {
    "x": "becîyayene",
    "y": " b. bacîyayene"
  },
  {
    "x": "becîyayîş (n)",
    "y": " b. bacîyayîş (n)"
  },
  {
    "x": "becnayene",
    "y": " b. bacnayene"
  },
  {
    "x": "bede",
    "y": " b. ci"
  },
  {
    "x": "bede ameyene",
    "y": " b. ... ameyene"
  },
  {
    "x": "bede ameyîne",
    "y": " b. ... ameyene"
  },
  {
    "x": "bede aqilîyayene",
    "y": " b. bide arqilîyayene"
  },
  {
    "x": "bede aqilnayene",
    "y": " b. bide arqilnayene"
  },
  {
    "x": "bede geyrayene",
    "y": " b. cigêrayene"
  },
  {
    "x": "bede ginayene",
    "y": " b. bide ginayene, ciginayene"
  },
  {
    "x": "bede hesîyayene",
    "y": " b. bide hesîyayene"
  },
  {
    "x": "bede hesnayene",
    "y": " b. bide hesnayene"
  },
  {
    "x": "bede mendene",
    "y": " b. bide mendene"
  },
  {
    "x": "bede sawitene",
    "y": " b. desawitene"
  },
  {
    "x": "bede sawîyayene",
    "y": " b. desawîyayene"
  },
  {
    "x": "bede şîyayene",
    "y": " b. bide şîyene"
  },
  {
    "x": "bede weniştene",
    "y": " b. bide weniştene"
  },
  {
    "x": "bede wenîştene",
    "y": " b. bide weniştene"
  },
  {
    "x": "bedeameya (m)",
    "y": " b. bideameya (m)"
  },
  {
    "x": "bedeameye (n)",
    "y": " b. bideameye (n)"
  },
  {
    "x": "bedeameyeyey (m)",
    "y": " b. bideameyeyî"
  },
  {
    "x": "bedeameyîş (n)",
    "y": " b. ... ameyîş"
  },
  {
    "x": "bedeaqilîyayîş (n)",
    "y": " b. bidearqilîyayîş (n)"
  },
  {
    "x": "bedeaqilnayîş (n)",
    "y": " b. bidearqilnayîş (n)"
  },
  {
    "x": "bedegeyrayîş (n)",
    "y": " b. cigêrayîş (n)"
  },
  {
    "x": "bedeginayîş (n)",
    "y": " b. bideginayîş (n)"
  },
  {
    "x": "bedehesîyayîş (n)",
    "y": " b. bidehesîyayîş (n)"
  },
  {
    "x": "bedehesnayîş (n)",
    "y": " b. bidehesnayîş (n)"
  },
  {
    "x": "bedelîyaya (m)",
    "y": " b. bedilîyaya (m)"
  },
  {
    "x": "bedelîyaye (n)",
    "y": " b. bedilîyaye (n)"
  },
  {
    "x": "bedelîyayene",
    "y": " b. bedilîyayene"
  },
  {
    "x": "bedelîyayî, -ye",
    "y": " b. bedilîyayî, -ye"
  },
  {
    "x": "bedelîyayîş (n)",
    "y": " b. bedilîyayîş (n)"
  },
  {
    "x": "bedelnaya (m)",
    "y": " b. bedilnaya (m)"
  },
  {
    "x": "bedelnaye (n)",
    "y": " b. bedilnaye (n)"
  },
  {
    "x": "bedelnayene",
    "y": " b. bedilnayene"
  },
  {
    "x": "bedelnayî, -ye",
    "y": " b. bedilnayî, -ye"
  },
  {
    "x": "bedelnayîş (n)",
    "y": " b. bedilnayîş (n)"
  },
  {
    "x": "bedemenda (m)",
    "y": " b. bidemenda (m)"
  },
  {
    "x": "bedemende (n)",
    "y": " b. bidemende (n)"
  },
  {
    "x": "beden-I (n)",
    "y": " beden, gövde, vücut"
  },
  {
    "x": "beden-II (n)",
    "y": " sur\r\n~Bedenê Dîyarbekirî (n): Diyarbekir Suru"
  },
  {
    "x": "Bedenê Dîyarbekirî (n)",
    "y": " Diyarbekir Suru"
  },
  {
    "x": "bedene (n)",
    "y": " b. badana (m)"
  },
  {
    "x": "bedenî, -ye",
    "y": " bedenî, bedensel"
  },
  {
    "x": "beder",
    "y": " b. ci"
  },
  {
    "x": "bedesawita (m)",
    "y": " b. desawita (m)"
  },
  {
    "x": "bedesawite (n)",
    "y": " b. desawite (n)"
  },
  {
    "x": "bedeweniştiş (n)",
    "y": " -e binme"
  },
  {
    "x": "bedewenîştiş",
    "y": " b. bedeweniştiş (n)"
  },
  {
    "x": "bedê",
    "y": " b. badê"
  },
  {
    "x": "bedê co",
    "y": " b. badê cû"
  },
  {
    "x": "bedê coy",
    "y": " b. badê cû"
  },
  {
    "x": "bedê cû",
    "y": " b. badê cû"
  },
  {
    "x": "bedêl, -e",
    "y": " bedel"
  },
  {
    "x": "bedêyin, -e",
    "y": " b. badêyin, -e"
  },
  {
    "x": "bedhewa",
    "y": " b. badihewa"
  },
  {
    "x": "bedil (n)",
    "y": " b. bedl (n)"
  },
  {
    "x": "bedilbar, -e",
    "y": " değişken"
  },
  {
    "x": "bedilîyaya (m)",
    "y": " değişik"
  },
  {
    "x": "bedilîyaye (n)",
    "y": " değişik"
  },
  {
    "x": "bedilîyayene",
    "y": " değişmek, başkalaşmak"
  },
  {
    "x": "bedilîyayî, -ye",
    "y": " değişik"
  },
  {
    "x": "bedilîyayîş (n)",
    "y": " 1)değişme, başkalaşma \r\n~2)değişiklik\r\n~bedilîyayîşê adrese (n): adres değişikliği\r\n~bedilîyayîşê awanî (n): imar değişikliği"
  },
  {
    "x": "bedilîyayîşê adrese (n)",
    "y": " adres değişikliği"
  },
  {
    "x": "bedilîyayîşê awanî (n)",
    "y": " imar değişikliği"
  },
  {
    "x": "bedilker (n)",
    "y": " değiştirgeç, konvertisör, röle"
  },
  {
    "x": "bedilnaya (m)",
    "y": " değişik"
  },
  {
    "x": "bedilnaye (n)",
    "y": " değişik"
  },
  {
    "x": "bedilnayene",
    "y": " değiştirmek"
  },
  {
    "x": "bedilnayî, -ye",
    "y": " değişik"
  },
  {
    "x": "bedilnayîş (n)",
    "y": " değiştirme\r\n~bedilnayîşê awanî (zh): imar tadilatı\r\n~bedilnayîşê planî (zh): plan tadilatı"
  },
  {
    "x": "bedilnayîşê awanî (zh)",
    "y": " imar tadilatı"
  },
  {
    "x": "bedilnayîşê planî (zh)",
    "y": " plan tadilatı"
  },
  {
    "x": "bedilnayîşî (zh)",
    "y": " tadilat"
  },
  {
    "x": "bedî",
    "y": " b. badê"
  },
  {
    "x": "bedî co",
    "y": " b. badê cû"
  },
  {
    "x": "bedîlhewa",
    "y": " b. badihewa"
  },
  {
    "x": "bedînî",
    "y": " b. badê"
  },
  {
    "x": "bedîyin, -e",
    "y": " b. badêyin, -e"
  },
  {
    "x": "bedl (n)",
    "y": " kıyafet, kılık, giyim, kisve"
  },
  {
    "x": "Bedlîs (n)",
    "y": " b. Bidlîs (n)"
  },
  {
    "x": "bedo",
    "y": " b. badê"
  },
  {
    "x": "bedoyin, -e",
    "y": " b. badêyin, -e"
  },
  {
    "x": "bedû",
    "y": " b. badê"
  },
  {
    "x": "beec",
    "y": " b. badê"
  },
  {
    "x": "beg (n)",
    "y": " 1)bey \r\n~2)bay"
  },
  {
    "x": "begam kerdene",
    "y": " b. begem kerdene"
  },
  {
    "x": "begamkerdiş (n)",
    "y": " b. begemkerdiş (n)"
  },
  {
    "x": "began kerdene",
    "y": " b. begem kerdene"
  },
  {
    "x": "begankerdiş (n)",
    "y": " b. begemkerdiş (n)"
  },
  {
    "x": "begem kerdene",
    "y": " beğenmek"
  },
  {
    "x": "begemkerdiş (n)",
    "y": " beğenme"
  },
  {
    "x": "begen kerdene",
    "y": " b. begem kerdene"
  },
  {
    "x": "begenkerdiş (n)",
    "y": " b. begemkerdiş (n)"
  },
  {
    "x": "begom kerdene",
    "y": " b. begem kerdene"
  },
  {
    "x": "begomkerdiş (n)",
    "y": " b. begemkerdiş (n)"
  },
  {
    "x": "begû kerdene",
    "y": " b. begem kerdene"
  },
  {
    "x": "begûkerdiş (n)",
    "y": " b. begemkerdiş (n)"
  },
  {
    "x": "begûm kerdene",
    "y": " b. begem kerdene"
  },
  {
    "x": "begûmkerdiş (n)",
    "y": " b. begemkerdiş (n)"
  },
  {
    "x": "begûn kerdene",
    "y": " b. begem kerdene "
  },
  {
    "x": "begûnkerdiş (n)",
    "y": " b. begemkerdiş (n)"
  },
  {
    "x": "begzade (n)",
    "y": " bey oğlu, beyzade, mirza"
  },
  {
    "x": "beh ... bîyene",
    "y": " b. bih bi ... bîyene"
  },
  {
    "x": "beharat (n)",
    "y": " baharat"
  },
  {
    "x": "beharatin, -e",
    "y": " baharatlı"
  },
  {
    "x": "beharatroş, -e",
    "y": " baharatçı"
  },
  {
    "x": "behayî, -ye",
    "y": " Bahai"
  },
  {
    "x": "behayîtî (m)",
    "y": " Bahailik"
  },
  {
    "x": "behayîyîye (m)",
    "y": " Bahailik"
  },
  {
    "x": "behcî (zh)",
    "y": " b. bacî (zh)"
  },
  {
    "x": "behcê ... ameyene",
    "y": " b. bacê ... ameyene"
  },
  {
    "x": "behcê ... ardene",
    "y": " b. bacê ... ardene"
  },
  {
    "x": "behcîyayene",
    "y": " b. bacîyayene"
  },
  {
    "x": "behcîyayîş (n)",
    "y": " b. bacîyayîş (n)"
  },
  {
    "x": "behcnayene",
    "y": " b. bacnayene"
  },
  {
    "x": "behd",
    "y": " b. badê"
  },
  {
    "x": "behdê",
    "y": " b. badê"
  },
  {
    "x": "behdî",
    "y": " b. badê"
  },
  {
    "x": "behdo",
    "y": " b. badê"
  },
  {
    "x": "behdoyin, -e",
    "y": " b. badêyin, -e"
  },
  {
    "x": "beher (n)",
    "y": " b. behr (n)"
  },
  {
    "x": "behişt (n)",
    "y": " cennet"
  },
  {
    "x": "behîşt (n)",
    "y": " b. behişt (n)"
  },
  {
    "x": "behr (n)",
    "y": " deniz"
  },
  {
    "x": "behrê aqilî (m)",
    "y": " akıl küpü"
  },
  {
    "x": "Behreyn (n)",
    "y": " Bahreyn"
  },
  {
    "x": "behrî, -ye",
    "y": " bahri"
  },
  {
    "x": "behrîya (m)",
    "y": " bahriye (deniz kuvvetleri)"
  },
  {
    "x": "behrîye (m)",
    "y": " bahriye (deniz kuvvetleri)"
  },
  {
    "x": "behs (n)",
    "y": " konu, mevzu, bahis"
  },
  {
    "x": "behs kerdene",
    "y": " bahsetmek, sözünü etmek, -den konuşmak"
  },
  {
    "x": "behsê ... kerdene",
    "y": " bahsetmek, sözünü etmek, -den konuşmak"
  },
  {
    "x": "behsê ... kerdiş",
    "y": " -den bahsetme"
  },
  {
    "x": "behskerdiş (n)",
    "y": " bahsetme"
  },
  {
    "x": "bej-I (n)",
    "y": " kara, kır, susuz arazi, kuru arazi"
  },
  {
    "x": "bej, -e-II",
    "y": " bej"
  },
  {
    "x": "bejî, -ye",
    "y": " 1)karasal \r\n~2)susuz (tarla, meyve vb.)"
  },
  {
    "x": "bejî (zh)",
    "y": " b. bacî (zh)"
  },
  {
    "x": "bejê ... ameyene",
    "y": " b. bacê ... ameyene"
  },
  {
    "x": "bejê ... ardene",
    "y": " b. bacê ... ardene"
  },
  {
    "x": "bejîyayene",
    "y": " b. bacîyayene"
  },
  {
    "x": "bejîyayîş (n)",
    "y": " b. bacîyayîş (n)"
  },
  {
    "x": "bejn (m)",
    "y": " b. bejne (m)"
  },
  {
    "x": "bejndarg, -e",
    "y": " b. bejnderg, -e"
  },
  {
    "x": "bejnderg, -e",
    "y": " boylu"
  },
  {
    "x": "bejnayene",
    "y": " b. bacnayene"
  },
  {
    "x": "bejne (m)",
    "y": " boy"
  },
  {
    "x": "bejneraantiş (sn)",
    "y": " boy çekimi"
  },
  {
    "x": "bejni (m)",
    "y": " b. bejne (m)"
  },
  {
    "x": "bejnûbale (m)",
    "y": " endam, boy bos"
  },
  {
    "x": "bejrayîr (n)",
    "y": " karayolu\r\n~bejrayîro dihetin (n): iki yönlü karayolu\r\n~bejrayîro parebîyaye (n): bölünmüş karayolu"
  },
  {
    "x": "bejrayîro dihetin (n)",
    "y": " iki yönlü karayolu"
  },
  {
    "x": "bejrayîro parebîyaye (n)",
    "y": " bölünmüş karayolu"
  },
  {
    "x": "bek",
    "y": " b. belkî"
  },
  {
    "x": "bekar, -e",
    "y": " bekâr"
  },
  {
    "x": "bekarey (m)",
    "y": " b. bekarîye (m)"
  },
  {
    "x": "bekarê (m)",
    "y": " b. bekarî (m)"
  },
  {
    "x": "bekarî (m)",
    "y": " bekârlık"
  },
  {
    "x": "bekarîye (m)",
    "y": " bekârlık"
  },
  {
    "x": "bekçî, -ye",
    "y": " bekçi"
  },
  {
    "x": "bekî",
    "y": " b. belkî"
  },
  {
    "x": "bekmez (n)",
    "y": " pekmez (b. mot (n), rib (n), dims (n)"
  },
  {
    "x": "bekmezin, -e (s)",
    "y": " pekmezli"
  },
  {
    "x": "bektaşî",
    "y": " Bektaşi"
  },
  {
    "x": "bektaşîtî (m)",
    "y": " Bektaşilik"
  },
  {
    "x": "bektaşîyîye (m)",
    "y": " Bektaşilik"
  },
  {
    "x": "bel, -e-I",
    "y": " (dikleşmiş olan) dik"
  },
  {
    "x": "bel kerdene",
    "y": " (hayvan kulağı vb. için) dikleştirmek, dikeltmek "
  },
  {
    "x": "bel-II (n)",
    "y": " b. bol-II (n)"
  },
  {
    "x": "bel eştene",
    "y": " b. ber eştene"
  },
  {
    "x": "bela (n)",
    "y": " bela\r\n~belayê sereyî (n): baş belası"
  },
  {
    "x": "belay sarî (n)",
    "y": " b. belayê sereyî (n)"
  },
  {
    "x": "belay serî (n)",
    "y": " b. belayê sereyî (n)"
  },
  {
    "x": "belayê sereyî (n)",
    "y": " baş belası"
  },
  {
    "x": "belas, -e",
    "y": " b. belaş, -e"
  },
  {
    "x": "belaş, -e",
    "y": " bedava, beleş"
  },
  {
    "x": "belaşkî",
    "y": " bedava olarak, bedavadan, bedavasına, beleşe, beleşten"
  },
  {
    "x": "belaşû (n)",
    "y": " bir çeşit bez"
  },
  {
    "x": "belaşwer, -e",
    "y": " bedavacı, beleşçi"
  },
  {
    "x": "belaşwerîye (m)",
    "y": " bedavacılık"
  },
  {
    "x": "belate-I (n)",
    "y": " ben (meyvelerin olgunlaşma belirtisi)"
  },
  {
    "x": "belate bîyene",
    "y": " meyvelerin tek tük olgunlaşmaya başlaması"
  },
  {
    "x": "belate dekewtene",
    "y": " meyvelerin tek tük olgunlaşmaya başlaması"
  },
  {
    "x": "belate parayene",
    "y": " b. belate perayene"
  },
  {
    "x": "belate parrayene",
    "y": " b. belate perayene"
  },
  {
    "x": "belate perayene",
    "y": " meyvelerin tek tük olgunlaşmaya başlaması"
  },
  {
    "x": "belate perrayene",
    "y": " b. belate perayene"
  },
  {
    "x": "belate-II (n)",
    "y": " b. berate (n)"
  },
  {
    "x": "belati (n)",
    "y": " b. belate-I (n)"
  },
  {
    "x": "Belçîka (m)",
    "y": " Belçika"
  },
  {
    "x": "belçîka (n)",
    "y": " bir tabanca çeşidi"
  },
  {
    "x": "belde (n)",
    "y": " belde"
  },
  {
    "x": "Bele (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "beledîya (m)",
    "y": " belediye\r\n~beledîyaya şaristanê girdî: büyükşehir belediyesi"
  },
  {
    "x": "beledîyaya şaristanê girdî",
    "y": " büyükşehir belediyesi"
  },
  {
    "x": "beledîye (m)",
    "y": " b. beledîya (m)"
  },
  {
    "x": "belegoş (n)",
    "y": " kabakulak"
  },
  {
    "x": "belegoş girewtene",
    "y": " kabakulak olmak"
  },
  {
    "x": "beleguaş (n)",
    "y": " b. belegoş (n)"
  },
  {
    "x": "belegueş (n)",
    "y": " b. belegoş (n)"
  },
  {
    "x": "belegûş (n)",
    "y": " b. belegoş (n)"
  },
  {
    "x": "belek, -e",
    "y": " 1)alaca (iki veya daha çok renkli) \r\n~2)alaca (siyah-beyaz renkli)"
  },
  {
    "x": "Belek, -e",
    "y": " köpek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "Belek (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "Beleke (m)",
    "y": " inek, keçi ve koyun için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "beleki (m)",
    "y": " b. belek, -e"
  },
  {
    "x": "Beleki (m)",
    "y": " b. Beleke (m)"
  },
  {
    "x": "belekin, -e",
    "y": " alaca (iki veya daha çok renkli)"
  },
  {
    "x": "belendîre (m)",
    "y": " b. berendîre (m)"
  },
  {
    "x": "belendîri (m)",
    "y": " b. berendîre (m)"
  },
  {
    "x": "belengaz, -e",
    "y": " zavallı"
  },
  {
    "x": "belengazey (m)",
    "y": " b. belengazîye (m)"
  },
  {
    "x": "belengazênî (m)",
    "y": " zavallılık"
  },
  {
    "x": "belengazi (m)",
    "y": " b. belengaz, -e"
  },
  {
    "x": "belengazî (m)",
    "y": " zavallılık"
  },
  {
    "x": "belengazîye (m)",
    "y": " zavallılık"
  },
  {
    "x": "belengoş (n)",
    "y": " kulak tozu (kulağın arkası)"
  },
  {
    "x": "belengûş (n)",
    "y": " b. belengoş (n)"
  },
  {
    "x": "beleş, -e",
    "y": " b. belaş, -e"
  },
  {
    "x": "belete (n)",
    "y": " b. belate (n)"
  },
  {
    "x": "belete bîyayene",
    "y": " b. belate bîyene"
  },
  {
    "x": "belete dekotene",
    "y": " b. belate dekewtene"
  },
  {
    "x": "belete dekowtene",
    "y": " b. belate dekewtene"
  },
  {
    "x": "belê (zr)",
    "y": " evet"
  },
  {
    "x": "belêda (m)",
    "y": " b. beledîya (m)"
  },
  {
    "x": "belg (n)",
    "y": " kıyafet, kılık, giyim, kisve, kostüm, kostüm (sinema ve tiyatro için)"
  },
  {
    "x": "belgê şewe (n)",
    "y": " gece elbisesi, tuvalet (gece giysisi)"
  },
  {
    "x": "belge (n)",
    "y": " 1)belge, vesika\r\n~belgeyê abirnayîşî (n): tahsis belgesi\r\n~2)kanıt, delil\r\n~belgeyo objektîf (n): objektif kanıt"
  },
  {
    "x": "belgeyan rê",
    "y": " vesikalık"
  },
  {
    "x": "belgeyê abirnayîşî (n)",
    "y": " tahsis belgesi"
  },
  {
    "x": "belgeyê fermannameyê heqdayîşî (n)",
    "y": " ödeme emri belgesi"
  },
  {
    "x": "belgeyê nêçîrî (n)",
    "y": " av tezkeresi"
  },
  {
    "x": "belgeyê qebulî (n)",
    "y": " akseptans"
  },
  {
    "x": "belgeyê seydî (n)",
    "y": " av tezkeresi"
  },
  {
    "x": "belgeyê tesdîqî (n)",
    "y": " onay belgesi"
  },
  {
    "x": "belgeyê teslîmgirewtişî (n)",
    "y": " alındı, makbuz"
  },
  {
    "x": "belgeyê texsîsî (n)",
    "y": " tahsis belgesi"
  },
  {
    "x": "belgeyê verdestûrî (n)",
    "y": " ön izin belgesi"
  },
  {
    "x": "belgeyê verîzinî (n)",
    "y": " ön izin belgesi"
  },
  {
    "x": "belgeyo fermî (n)",
    "y": " evrak"
  },
  {
    "x": "belgeyo objektîf (n)",
    "y": " objektif kanıt"
  },
  {
    "x": "belgekerdiş (n)",
    "y": " belgeleme"
  },
  {
    "x": "belgeyî, -ye",
    "y": " belgesel\r\n~belgeyîyo muşahid (sn): gözlemci belgesel"
  },
  {
    "x": "belgeyîyo muşahid (sn)",
    "y": " gözlemci belgesel"
  },
  {
    "x": "belgeyîyo observator (sn)",
    "y": " gözlemci belgesel"
  },
  {
    "x": "Belgrad (n)",
    "y": " Belgrad"
  },
  {
    "x": "beli",
    "y": " b. bellî, -ye"
  },
  {
    "x": "Beli (m)",
    "y": " b. Bele (m)"
  },
  {
    "x": "belik (n)",
    "y": " küçükdil"
  },
  {
    "x": "belike (m)",
    "y": " gırtlak"
  },
  {
    "x": "belingaz, -i",
    "y": " b. belengaz, -e"
  },
  {
    "x": "belingazey (m)",
    "y": " b. belengazîye (m)"
  },
  {
    "x": "belingazênî (m)",
    "y": " b. belengazênî (m)"
  },
  {
    "x": "belingazî (m)",
    "y": " b. belengazî (m)"
  },
  {
    "x": "belinge (n)",
    "y": " b. berenge (n)"
  },
  {
    "x": "belî-I",
    "y": " b. bellî, -ye"
  },
  {
    "x": "belî bîyayene",
    "y": " b. bellî bîyene"
  },
  {
    "x": "belî bîyayine",
    "y": " b. bellî bîyene"
  },
  {
    "x": "belî kerdeni",
    "y": " b. bellî kerdene"
  },
  {
    "x": "belî-II (m)",
    "y": " b. belû-I (m)"
  },
  {
    "x": "belî-III (zr)",
    "y": " b. belê (zr)"
  },
  {
    "x": "belî ... bîyayene",
    "y": " b. bilî ... bîyene"
  },
  {
    "x": "belîcani (m)",
    "y": " b. balcane (m)"
  },
  {
    "x": "belîk (n)",
    "y": " elle ufaltılmış olan tütün"
  },
  {
    "x": "belîye (m)",
    "y": " b. belû-I (m)"
  },
  {
    "x": "Belîz",
    "y": " Belize"
  },
  {
    "x": "belk",
    "y": " b. belkî"
  },
  {
    "x": "belka",
    "y": " b. belkî"
  },
  {
    "x": "belkam",
    "y": " b. belkî"
  },
  {
    "x": "belkem",
    "y": " b. belkî"
  },
  {
    "x": "belkê",
    "y": " b. belkî"
  },
  {
    "x": "belkêm",
    "y": " b. belkî"
  },
  {
    "x": "belkî",
    "y": " belki"
  },
  {
    "x": "belkîa",
    "y": " b. belkî"
  },
  {
    "x": "belkîm",
    "y": " b. belkî"
  },
  {
    "x": "belkîya",
    "y": " b. belkî"
  },
  {
    "x": "belko",
    "y": " b. belkî"
  },
  {
    "x": "bell, -e",
    "y": " b. bel, -e-I"
  },
  {
    "x": "bell kerdene",
    "y": " b. bel kerdene"
  },
  {
    "x": "bellî, -ye-I",
    "y": " 1)belli\r\n~2)belirgin"
  },
  {
    "x": "bellî bîyayene",
    "y": " b. bellî bîyene"
  },
  {
    "x": "bellî bîyene",
    "y": " belli olmak"
  },
  {
    "x": "bellî kerdene",
    "y": " belli etmek"
  },
  {
    "x": "bellî bîyene",
    "y": " belirginleşmek"
  },
  {
    "x": "bellî kerdene",
    "y": " belirlemek, belirtmek"
  },
  {
    "x": "bellî-II (m)",
    "y": " b. belû-I (m)"
  },
  {
    "x": "bellîbîyayîş (n)",
    "y": " belirginleşme"
  },
  {
    "x": "bellîkerda (m)",
    "y": " belli başlı"
  },
  {
    "x": "bellîkerde (n)",
    "y": " belli başlı"
  },
  {
    "x": "bellîkerdî, -ye",
    "y": " belli başlı"
  },
  {
    "x": "bellîkerdiş (n)",
    "y": " belirleme, belirtme"
  },
  {
    "x": "bellu",
    "y": " b. bellî, -ye"
  },
  {
    "x": "bellu (m)",
    "y": " b. belû-I (m)"
  },
  {
    "x": "bellû (m)",
    "y": " b. belû-I (m)"
  },
  {
    "x": "Belmopan (n)",
    "y": " Belmopan"
  },
  {
    "x": "belu-I (m)",
    "y": " b. belû-I (m)"
  },
  {
    "x": "beluyê qeyse (m)",
    "y": " b. belûya qeyse (m)"
  },
  {
    "x": "belu-II",
    "y": " b. bellî, -ye"
  },
  {
    "x": "beluye (m)",
    "y": " b. belû-I (m)"
  },
  {
    "x": "belû-I (m)",
    "y": " palamut"
  },
  {
    "x": "belûya qeyse (m)",
    "y": " yuvağı çekmek için kullanılan çatallı aracın yuvağın girintili yerine geçen ucu"
  },
  {
    "x": "belû-II",
    "y": " b. bellî, -ye-I"
  },
  {
    "x": "belûmase (n)",
    "y": " palamut balığı"
  },
  {
    "x": "belûwer, -e",
    "y": " bir kuş çeşidi"
  },
  {
    "x": "belxem (n)",
    "y": " balgam"
  },
  {
    "x": "belxemin, -e",
    "y": " balgamlı"
  },
  {
    "x": "belxul (n)",
    "y": " b. belxur (n)"
  },
  {
    "x": "belxur (n)",
    "y": " bulgur\r\n~belxuro hurdî (n): ince bulgur"
  },
  {
    "x": "belxuro hurdî (n)",
    "y": " ince bulgur"
  },
  {
    "x": "belxuro wirdi (n)",
    "y": " b. belxuro hurdî (n)"
  },
  {
    "x": "belya",
    "y": " b. belkî"
  },
  {
    "x": "bemayîş (n)",
    "y": " b. bermayîş (n)"
  },
  {
    "x": "benate (n)",
    "y": " b. beyntar (n)"
  },
  {
    "x": "bend-I (n)",
    "y": " 1)(bağlamak için kullanılan) bağ, ip, sicim; kısa urgan\r\n~bendê çarixî (n): çarık bağı\r\n~bendê solan (n): ayakkabı bağı\r\n~bendê gewreyan/kurikan/puncan/puçan (n): çorap bağı\r\n~2)(hayvan için) tasma"
  },
  {
    "x": "bendê çarixî (n)",
    "y": " çarık bağı"
  },
  {
    "x": "bendê solan (n)",
    "y": " ayakkabı bağı"
  },
  {
    "x": "bendê gewreyan/kurikan/puncan/puçan (n)",
    "y": " çorap bağı"
  },
  {
    "x": "bendî solûn (n)",
    "y": " b. bendê solan (n)"
  },
  {
    "x": "bend-II (n)",
    "y": " b. bende (m)"
  },
  {
    "x": "bend antene",
    "y": " b. bende antene"
  },
  {
    "x": "bend virastene",
    "y": " b. bende viraştene"
  },
  {
    "x": "bend viraştene",
    "y": " b. bende viraştene"
  },
  {
    "x": "benda (m)",
    "y": " köle, kul, abd"
  },
  {
    "x": "bende-I (m)",
    "y": " 1)bent (suyu biriktirmek için önüne yapılan set), set\r\n~bende antene: set çekmek\r\n~bende viraştene: set çekmek\r\n~2)duvar\r\n~benda hêgayî: tarla duvarı"
  },
  {
    "x": "bende antene",
    "y": " set çekmek"
  },
  {
    "x": "bende viraştene",
    "y": " set çekmek"
  },
  {
    "x": "benda hêgayî",
    "y": " tarla duvarı"
  },
  {
    "x": "bende-II (n)",
    "y": " köle, kul, abd"
  },
  {
    "x": "bendeyey (m)",
    "y": " b. bendeyîye (m)"
  },
  {
    "x": "bendeyî (m)",
    "y": " kölelik, kulluk"
  },
  {
    "x": "bendeyîye (m)",
    "y": " kölelik, kulluk"
  },
  {
    "x": "bendik (n)",
    "y": " bağ (bağlamak için kullanılan), ip"
  },
  {
    "x": "bendika (m)",
    "y": " hayvana yüklenen odun vb. yükünde iki yandaki odun vb. dizilerinden her biri"
  },
  {
    "x": "bendike (n)",
    "y": " b. bendika (m)"
  },
  {
    "x": "bendî",
    "y": " b. badê"
  },
  {
    "x": "bendî der",
    "y": " b. badê"
  },
  {
    "x": "bendîna",
    "y": " b. badê"
  },
  {
    "x": "bendînî",
    "y": " b. badê"
  },
  {
    "x": "beng (n)",
    "y": " ban otu"
  },
  {
    "x": "bengî",
    "y": " b. bengîn, -e"
  },
  {
    "x": "bengîn, -e",
    "y": " tutkun, meftun"
  },
  {
    "x": "bengînê/bengîna ... bîyene",
    "y": " -e âşık olmak, tutulmak (âşık olmak), abayı yakmak "
  },
  {
    "x": "bengîyê/bengîya ... bîyene",
    "y": " -e âşık olmak, tutulmak (âşık olmak), abayı yakmak"
  },
  {
    "x": "Bengladeş (n)",
    "y": " Bengladeş"
  },
  {
    "x": "benişt (n)",
    "y": " b. benîşt (n)"
  },
  {
    "x": "benîadem (n)",
    "y": " âdemoğlu, insanoğlu, beşer, beniâdem"
  },
  {
    "x": "benîîsraîl (n)",
    "y": " Yahudi"
  },
  {
    "x": "Benîn (n)",
    "y": " Benin"
  },
  {
    "x": "benîşt (n)",
    "y": " sakız, ciklet, çiklet\r\n~benîştê xaşxaşikan (n): afyon"
  },
  {
    "x": "benîştê xaşxaşikan (n)",
    "y": " afyon"
  },
  {
    "x": "beno",
    "y": " olur (izin)"
  },
  {
    "x": "beno gi",
    "y": " b. beno ke"
  },
  {
    "x": "beno ke",
    "y": " belki"
  },
  {
    "x": "beno ki",
    "y": " b. beno ke"
  },
  {
    "x": "benû ki",
    "y": " b. beno ke"
  },
  {
    "x": "benzîn (n)",
    "y": " benzin"
  },
  {
    "x": "benzînroş, -e",
    "y": " benzinci"
  },
  {
    "x": "benzînxane (n)",
    "y": " akaryakıt istasyonu, benzinlik"
  },
  {
    "x": "beq (n)",
    "y": " kurbağa"
  },
  {
    "x": "beq kerdene",
    "y": " b. pix kerdene"
  },
  {
    "x": "beqal, -e",
    "y": " bakkal"
  },
  {
    "x": "beqa nêkerdene",
    "y": " hemen ölmek, anında ölmek"
  },
  {
    "x": "beqla (m)",
    "y": " bakla\r\n~beqlaya sultanî (m): bir çeşit yabani bakla\r\n~beqlaya xatunan (m): bir çeşit yabani bakla"
  },
  {
    "x": "beqlaya sultanî (m)",
    "y": " bir çeşit yabani bakla"
  },
  {
    "x": "beqlaya xatunan (m)",
    "y": " bir çeşit yabani bakla"
  },
  {
    "x": "beqlayin, -e",
    "y": " baklalı"
  },
  {
    "x": "beqlayînan (zh)",
    "y": " baklagiller"
  },
  {
    "x": "beqlewa (m)",
    "y": " b. baqlawa (m)"
  },
  {
    "x": "beqlîyat (n)",
    "y": " bakliyat"
  },
  {
    "x": "beqmase (n)",
    "y": " kurbağa balığı"
  },
  {
    "x": "ber-I (n) (TE)",
    "y": " kapı, bap\r\n~bero caxleme (n): parmaklık biçimindeki kapı"
  },
  {
    "x": "berê protokolî (n)",
    "y": " protokol kapısı"
  },
  {
    "x": "berê teberî (n)",
    "y": " 1)dış kapı \r\n~2)ana kapı, portal"
  },
  {
    "x": "berê vejîyayîşê acîlî (n)",
    "y": " acil çıkış kapısı"
  },
  {
    "x": "berê vejîyayîşê lezabezî (n)",
    "y": " acil çıkış kapısı"
  },
  {
    "x": "berê vejîyayîşê lezkanî (n)",
    "y": " acil çıkış kapısı"
  },
  {
    "x": "bero caxilme (n)",
    "y": " b. bero caxleme (n)"
  },
  {
    "x": "bero caxlame (n)",
    "y": " b. bero caxleme (n)"
  },
  {
    "x": "bero caxleme (n)",
    "y": " parmaklık biçimindeki kapı"
  },
  {
    "x": "bero otomatîk (n)",
    "y": " otomatik kapı"
  },
  {
    "x": "bero paralel (n) (TE)",
    "y": " paralel kapı"
  },
  {
    "x": "bero rêzkî (n) (TE)",
    "y": " dizisel kapı"
  },
  {
    "x": "ber-II (n)",
    "y": " dış, dışarı; hariç\r\n~Ez kewtane ber. (Dışarı çıktım.)"
  },
  {
    "x": "ber kerdene",
    "y": " 1)dışarıya çıkarmak, (yarada biriken irin veya kanı) dışarı akıtmak\r\n~Mekteb ra ber ke! (Okuldan dışarıya çıkar!)\r\n~2)drene etmek"
  },
  {
    "x": "ber-III (m)",
    "y": " b. bere-I (m)"
  },
  {
    "x": "ber-IV (n)",
    "y": " ürün, mahsul\r\n~berê tebîîyî (zh): doğal ürünler"
  },
  {
    "x": "ber ardene",
    "y": " üretmek, imal etmek"
  },
  {
    "x": "ber arê dayene",
    "y": " ürün toplamak"
  },
  {
    "x": "ber arê kerdene",
    "y": " ürün toplamak"
  },
  {
    "x": "ber estene",
    "y": " b. ber eştene"
  },
  {
    "x": "ber eştene",
    "y": " (hayvan için) düşük yapmak "
  },
  {
    "x": "ber eyştene",
    "y": " b. ber eştene"
  },
  {
    "x": "ber girewtene",
    "y": " üremek"
  },
  {
    "x": "ber guretene",
    "y": " b. ber girewtene"
  },
  {
    "x": "ber pêser kerdene",
    "y": " ürün toplamak"
  },
  {
    "x": "ber vistene",
    "y": " b. ber eştene"
  },
  {
    "x": "berê îdxalî (zh)",
    "y": " ithal ürünler"
  },
  {
    "x": "berê îxracî (zh)",
    "y": " ihraç ürünler"
  },
  {
    "x": "berê tebîîyî (zh)",
    "y": " doğal ürünler"
  },
  {
    "x": "berê xozayî (zh)",
    "y": " doğal ürünler"
  },
  {
    "x": "bera-I",
    "y": " b. bira-II"
  },
  {
    "x": "bera-II (n)",
    "y": " b. ber-II (n)"
  },
  {
    "x": "beradî (m)",
    "y": " b. berradî (m)"
  },
  {
    "x": "beraj (n)",
    "y": " b. baraj (n)"
  },
  {
    "x": "beran (n)",
    "y": " koç"
  },
  {
    "x": "beran ameyene",
    "y": " kösnümek (koyun için) "
  },
  {
    "x": "beran amyayeni",
    "y": " b. beran ameyene"
  },
  {
    "x": "beran bîyene",
    "y": " kösnümek (koyun için) "
  },
  {
    "x": "beran dayene",
    "y": " (koyun için) çiftleştirmek"
  },
  {
    "x": "beran verdayene",
    "y": " (koyun için) çiftleştirmek"
  },
  {
    "x": "beran werdene",
    "y": " (koyun için) çiftleşmek"
  },
  {
    "x": "beranê lokî (n) (mecazî)",
    "y": " yiğit koç"
  },
  {
    "x": "berûnê lûkî (n) (mecazî)",
    "y": " b. beranê lokî (n)"
  },
  {
    "x": "berano maz (n)",
    "y": " yaşlı koç"
  },
  {
    "x": "berano qert (n)",
    "y": " çok yaşlı olan koç"
  },
  {
    "x": "beranameyîş (n)",
    "y": " kösnüme (koyun için)  "
  },
  {
    "x": "beranamyayîş (n)",
    "y": " b. beranameyîş (n)"
  },
  {
    "x": "beranbîyayîş (n)",
    "y": " kösnüme (koyun için)"
  },
  {
    "x": "berardiş (n)",
    "y": " üretim, üretme, imal etme\r\n~berardişê xidayan de tehlukeyî (zh): gıda üretiminde tehlikeler\r\n~berardişo yewbîyaye (n): birleşik üretim"
  },
  {
    "x": "berardişê xidayan de tehlukeyî (zh)",
    "y": " gıda üretiminde tehlikeler"
  },
  {
    "x": "berardişê xidayan de xeterî (zh)",
    "y": " gıda üretiminde tehlikeler"
  },
  {
    "x": "berardişo yewbîyaye (n)",
    "y": " birleşik üretim"
  },
  {
    "x": "berardox, -e",
    "y": " üretici"
  },
  {
    "x": "berarêdayîş (n)",
    "y": " ürün toplama"
  },
  {
    "x": "berarêkerdiş (n)",
    "y": " ürün toplama"
  },
  {
    "x": "berat-I (n)",
    "y": " beraat"
  },
  {
    "x": "berat-II (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "berate (n)",
    "y": " kısmen çürümüş ya da başka hayvanlarca yenmiş hayvan ölüsü"
  },
  {
    "x": "Beraz (n)",
    "y": " köpek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "berban, -e",
    "y": " kapıcı"
  },
  {
    "x": "berbanik (n)",
    "y": " önlük"
  },
  {
    "x": "berbayene",
    "y": " b. bermayene"
  },
  {
    "x": "berbayêne",
    "y": " b. bermayene"
  },
  {
    "x": "berbayine",
    "y": " b. bermayene"
  },
  {
    "x": "berbayîne",
    "y": " b. bermayene"
  },
  {
    "x": "berbayîş (n)",
    "y": " b. bermayîş (n)"
  },
  {
    "x": "berbayox, -e",
    "y": " b. bermayox, -e"
  },
  {
    "x": "berbend (n)",
    "y": " palanın geri kaymasını önlemek için ön kısmına bağlanan ip"
  },
  {
    "x": "berber, -e",
    "y": " berber, kuaför\r\n~berbera cinîyan (m): kadın berberi\r\n~berberê cinîyan (n): kadın berberi"
  },
  {
    "x": "berbera cinîyan (m)",
    "y": " kadın berberi"
  },
  {
    "x": "berberê cinîyan (n)",
    "y": " kadın berberi"
  },
  {
    "x": "berberey (m)",
    "y": " b. berberîye (m)"
  },
  {
    "x": "berberê (m)",
    "y": " b. berberîye (m)"
  },
  {
    "x": "berberîye (m)",
    "y": " berberlik, kuaförlük"
  },
  {
    "x": "berbiş (n)",
    "y": " b. bermayîş (n)"
  },
  {
    "x": "berbîs (n)",
    "y": " b. bermayîş (n)"
  },
  {
    "x": "berbîs fîstene",
    "y": " b. bermî fîştene"
  },
  {
    "x": "berbîsfîstiş (n)",
    "y": " b. bermîfîştiş (n)"
  },
  {
    "x": "berbîş (n)",
    "y": " b. bermayîş (n)"
  },
  {
    "x": "berbîş fîstene",
    "y": " b. bermî fîştene"
  },
  {
    "x": "berbîşfîstiş (n)",
    "y": " b. bermîfîştiş (n)"
  },
  {
    "x": "berbîyayîş (n)",
    "y": " yarada biriken irin veya kanın dışarı çıkması"
  },
  {
    "x": "berbîyeyîş (n)",
    "y": " b. bermayîş (n)"
  },
  {
    "x": "berc, -e",
    "y": " b. berz, -e"
  },
  {
    "x": "berd (m)",
    "y": " b. berde (m)"
  },
  {
    "x": "berda (m)",
    "y": " kaçırılan"
  },
  {
    "x": "berdar, -e",
    "y": " verimli"
  },
  {
    "x": "berdarî (m)",
    "y": " verimlilik"
  },
  {
    "x": "berdarîye (m)",
    "y": " verimlilik"
  },
  {
    "x": "berdayîş (n)",
    "y": " dışa vurma, dışavurum"
  },
  {
    "x": "berdayox, -e",
    "y": " dışavurumcu"
  },
  {
    "x": "berdayoxî (m)",
    "y": " dışavurumculuk"
  },
  {
    "x": "berdayoxîye (m)",
    "y": " dışavurumculuk"
  },
  {
    "x": "berdbar, -e",
    "y": " menkul, taşınır (taşınabilen, götürülebilen)"
  },
  {
    "x": "berde-I (n)",
    "y": " kaçırılan"
  },
  {
    "x": "berde-II (m)",
    "y": " otuz tane (burma ot için)"
  },
  {
    "x": "berdene",
    "y": " 1)götürmek \r\n~2)kaçırmak"
  },
  {
    "x": "berdi (m)",
    "y": " b. berde (m)"
  },
  {
    "x": "berdiş (n)",
    "y": " 1)götürme \r\n~2)kaçırma"
  },
  {
    "x": "berdîye (m)",
    "y": " kaçırılan"
  },
  {
    "x": "berdoş, -e",
    "y": " berduş, başıboş, serseri (kimse)"
  },
  {
    "x": "berdox, -e",
    "y": " kaçıran"
  },
  {
    "x": "bere-I (m)",
    "y": " taş"
  },
  {
    "x": "bere-II",
    "y": " b. ci; ey, aye; bide, piro"
  },
  {
    "x": "bere ameyene",
    "y": " b. bide ameyene"
  },
  {
    "x": "bere aqilîyayene",
    "y": " b. bide arqilîyayene"
  },
  {
    "x": "bere aqilnayene",
    "y": " b. bide arqilnayene"
  },
  {
    "x": "bere geyrayene",
    "y": " b. cigêrayene"
  },
  {
    "x": "bere ginayene",
    "y": " 1)b. piro ginayene \r\n~2)b. bide ginayene"
  },
  {
    "x": "bere gunayene",
    "y": " b. piro ginayene"
  },
  {
    "x": "bere hameyîne",
    "y": " b. ... ameyene"
  },
  {
    "x": "bere mendene",
    "y": " b. bide mendene"
  },
  {
    "x": "bere sawitene",
    "y": " b. desawitene"
  },
  {
    "x": "bere sawîyayene",
    "y": " b. desawîyayene"
  },
  {
    "x": "bere şanayîne",
    "y": " b. ci şanayene"
  },
  {
    "x": "bere şîyayene",
    "y": " b. piro şîyene"
  },
  {
    "x": "bere wenayene",
    "y": " b. piro nayen"
  },
  {
    "x": "bere weniştene",
    "y": " b. piro niştene"
  },
  {
    "x": "bere-III (m)",
    "y": " bere (başlık)"
  },
  {
    "x": "bere-IV (m)",
    "y": " (savaş için) cephe"
  },
  {
    "x": "bere-bere",
    "y": " giderek, gittikçe, yavaş-yavaş"
  },
  {
    "x": "bereameya (m)",
    "y": " b. bideameya (m)"
  },
  {
    "x": "bereameye (n)",
    "y": " b. bideameye (n)"
  },
  {
    "x": "bereameyeyey (m)",
    "y": " b. bideameyeyî"
  },
  {
    "x": "bereameyîş (n)",
    "y": " b. ... ameyîş"
  },
  {
    "x": "bereaqilîyayîş (n)",
    "y": " b. bidearqilîyayîş (n)"
  },
  {
    "x": "bereaqilîyeyîş (n)",
    "y": " b. bidearqilîyayîş (n)"
  },
  {
    "x": "bereaqilnayîş (n)",
    "y": " b. bidearqilnayîş (n)"
  },
  {
    "x": "beregeyrayîş (n)",
    "y": " b. cigêrayîş (n)"
  },
  {
    "x": "bereginayîş (n)",
    "y": " b. bideginayîş (n)"
  },
  {
    "x": "berehameyîş (n)",
    "y": " b. ... ameyîş"
  },
  {
    "x": "bereket (n)",
    "y": " bereket"
  },
  {
    "x": "bereket ci kewtene",
    "y": " bereketlenmek"
  },
  {
    "x": "bereket ci vistene",
    "y": " bereketlendirmek"
  },
  {
    "x": "bereket dekewtene",
    "y": " bereketlenmek"
  },
  {
    "x": "bereket devistene",
    "y": " bereketlendirmek"
  },
  {
    "x": "bereketcikewtiş (n)",
    "y": " bereketlenme"
  },
  {
    "x": "bereketcivistiş (n)",
    "y": " bereketlendirme"
  },
  {
    "x": "bereketdekewtiş (n)",
    "y": " bereketlenme"
  },
  {
    "x": "bereketdevistiş (n)",
    "y": " bereketlendirme"
  },
  {
    "x": "beremenda (m)",
    "y": " b. bidemenda (m)"
  },
  {
    "x": "beremende (n)",
    "y": " b. bidemende (n)"
  },
  {
    "x": "berendîle (m)",
    "y": " b. berendîre (m)"
  },
  {
    "x": "berendîli (m)",
    "y": " b. berendîre (m)"
  },
  {
    "x": "berendîre (m)",
    "y": " şişek, bir iki yaşındaki dişi kuzu"
  },
  {
    "x": "berengaz, -i",
    "y": " b. belengaz, -e"
  },
  {
    "x": "berengazî (m)",
    "y": " b. belengazî (m)"
  },
  {
    "x": "bereng (n)",
    "y": " b. berenge (n)"
  },
  {
    "x": "berenge (n)",
    "y": " 1)çit kapısı, çeper kapısı \r\n~2)(savaş için) cephe"
  },
  {
    "x": "berengi (n)",
    "y": " b. berenge (n)"
  },
  {
    "x": "bereqa (m)",
    "y": " b. baraqa (m)"
  },
  {
    "x": "bereqîyayene",
    "y": " b. beriqîyayene"
  },
  {
    "x": "bereqîyayîş (n)",
    "y": " b. beriqîyayîş (n)"
  },
  {
    "x": "bereqnayene",
    "y": " b. beriqnayene"
  },
  {
    "x": "bereqnayîş (n)",
    "y": " b. beriqnayîş (n)"
  },
  {
    "x": "beresawita (m)",
    "y": " b. desawita (m)"
  },
  {
    "x": "beresawite (n)",
    "y": " b. desawite (n)"
  },
  {
    "x": "bereşanayîş (n)",
    "y": " b. cişanayîş (n)"
  },
  {
    "x": "bereşîyayîş (n)",
    "y": " b. piroşîyayîş (n)"
  },
  {
    "x": "berewenîştiş (n)",
    "y": " b. bedeweniştiş (n)"
  },
  {
    "x": "berey (n)",
    "y": " geç, tehir"
  },
  {
    "x": "berey fîştene",
    "y": " geciktirmek, tehir etmek; ağırdan almak"
  },
  {
    "x": "berey kewtene",
    "y": " gecikmek, geç kalmak"
  },
  {
    "x": "berey kewteni",
    "y": " b. berey kewtene"
  },
  {
    "x": "berey mendene",
    "y": " gecikmek, geç kalmak"
  },
  {
    "x": "berey mendeni",
    "y": " b. berey mendene"
  },
  {
    "x": "berey vistene",
    "y": " b. berey fîştene"
  },
  {
    "x": "bereykewta (m)",
    "y": " gecikmiş [olan]"
  },
  {
    "x": "bereykewte (n)",
    "y": " gecikmiş [olan]"
  },
  {
    "x": "bereykewtê (m)",
    "y": " b. bereykewta (m)"
  },
  {
    "x": "bereykewtiş (n)",
    "y": " gecikme, geç kalma"
  },
  {
    "x": "bereykewtî, -ye",
    "y": " gecikmiş [olan]"
  },
  {
    "x": "bereymendiş (n)",
    "y": " gecikme, geç kalma"
  },
  {
    "x": "bereyfîştiş (n)",
    "y": " geciktirme, tehir etme"
  },
  {
    "x": "bereyvistiş (n)",
    "y": " b. bereyfîştiş (n)"
  },
  {
    "x": "Berez (n)",
    "y": " b. Beraz (n)"
  },
  {
    "x": "berezîyayene",
    "y": " b. berizîyayene"
  },
  {
    "x": "berezîyayîş (n)",
    "y": " b. berizîyayîş (n)"
  },
  {
    "x": "berêl, -i",
    "y": " b. berîl, -e"
  },
  {
    "x": "bergale (n)",
    "y": " çit biçimindeki kapı"
  },
  {
    "x": "berge (n)",
    "y": " çit kapısı, çeper kapısı"
  },
  {
    "x": "bergela (n)",
    "y": " b. bergale (n)"
  },
  {
    "x": "bergirewtiş (n)",
    "y": " üreme"
  },
  {
    "x": "bergîn (n)",
    "y": " çit biçimindeki kapı"
  },
  {
    "x": "bergîr (n)",
    "y": " beygir"
  },
  {
    "x": "berhem (n)",
    "y": " ürün"
  },
  {
    "x": "berhem ardene",
    "y": " üretmek"
  },
  {
    "x": "berhemê îdxalî (zh)",
    "y": " ithal ürünler"
  },
  {
    "x": "berhemê îxracî (zh)",
    "y": " ihraç ürünler"
  },
  {
    "x": "berhemê promosyonî (n)",
    "y": " promosyon ürünü"
  },
  {
    "x": "berhemê tebîîyî (zh)",
    "y": " doğal ürünler"
  },
  {
    "x": "berhemê xozayî (zh)",
    "y": " doğal ürünler"
  },
  {
    "x": "berhemardiş (n)",
    "y": " üretim, üretme\r\n~berhemardişê xidayan de tehlukeyî (zh) gıda üretiminde tehlikeler \r\n~\t\r\n~berhemardişê xidayan de xeterî (zh) gıda üretiminde tehlikeler\r\n~berhemardişo yewbîyaye (n): birleşik üretim"
  },
  {
    "x": "berhemardişo yewbîyaye (n)",
    "y": " birleşik üretim"
  },
  {
    "x": "beri",
    "y": " b. ber-I (n)"
  },
  {
    "x": "berindî (m)",
    "y": " b. berendîre (m)"
  },
  {
    "x": "berindil (m)",
    "y": " b. berendîre (m)"
  },
  {
    "x": "beriqîyayene",
    "y": " parlamak, ışıldamak, parıldamak"
  },
  {
    "x": "beriqîyayîş (n)",
    "y": " parlama, ışıldama, parıldama"
  },
  {
    "x": "beriqnayene",
    "y": " parlatmak, ışıldatmak, parıldatmak"
  },
  {
    "x": "beriqnayîş (n)",
    "y": " parlatma, ışıldatma, parıldatma"
  },
  {
    "x": "beriqnayox, -e",
    "y": " polisajcı"
  },
  {
    "x": "berizîyayene",
    "y": " bağırmak"
  },
  {
    "x": "berizîyayîş (n)",
    "y": " bağırma"
  },
  {
    "x": "berî, -ye",
    "y": " b. berrî, -ye"
  },
  {
    "x": "berî (m)",
    "y": " b. bêrî (m)"
  },
  {
    "x": "berîl, -e",
    "y": " yağsız"
  },
  {
    "x": "berîli (m)",
    "y": " yağsız"
  },
  {
    "x": "berîya (m)",
    "y": " b. ber-I (n)"
  },
  {
    "x": "berîyê (m)",
    "y": " b. bêrîyîye (m)"
  },
  {
    "x": "berj, -e",
    "y": " b. berz, -e"
  },
  {
    "x": "berjen (n)",
    "y": " b. berzîn (n)"
  },
  {
    "x": "berjên (n)",
    "y": " b. berzîn (n)"
  },
  {
    "x": "berkem (n)",
    "y": " b. berken (n)"
  },
  {
    "x": "berken (n)",
    "y": " gülümseme"
  },
  {
    "x": "berkenî bîyene",
    "y": " gülümsemek"
  },
  {
    "x": "berkenîbîyayîş (n)",
    "y": " gülümseme"
  },
  {
    "x": "berket (n)",
    "y": " b. bereket (n)"
  },
  {
    "x": "berket ci kewtene",
    "y": " b. bereket ci kewtene"
  },
  {
    "x": "berket ci vistene",
    "y": " b. bereket ci vistene"
  },
  {
    "x": "berket defînayene",
    "y": " b. bereket devistene"
  },
  {
    "x": "berket dekewtene",
    "y": " b. bereket dekewtene"
  },
  {
    "x": "berketcikewtiş (n)",
    "y": " b. bereketcikewtiş (n)"
  },
  {
    "x": "berketdefînayîş (n)",
    "y": " b. bereketdevistiş (n)"
  },
  {
    "x": "berketdekewtiş (n)",
    "y": " b. bereketdekewtiş (n)"
  },
  {
    "x": "berki (n)",
    "y": " b. bereket (n)"
  },
  {
    "x": "berko (n)",
    "y": " son"
  },
  {
    "x": "berkole (n)",
    "y": " mezar taşı"
  },
  {
    "x": "berkule (n)",
    "y": " b. berkole (n)"
  },
  {
    "x": "berling (n)",
    "y": " ayakkabılık"
  },
  {
    "x": "Berlîn (n)",
    "y": " Berlin"
  },
  {
    "x": "berm (n)",
    "y": " b. berme (n)"
  },
  {
    "x": "bermak, -e",
    "y": " güzün doğan oğlak, kuzu vb. hayvan "
  },
  {
    "x": "bermal (n)",
    "y": " taban (bina vb. tabanı)"
  },
  {
    "x": "bermayene",
    "y": " ağlamak"
  },
  {
    "x": "bermayeni",
    "y": " b. bermayene"
  },
  {
    "x": "bermayîş (n)",
    "y": " ağlama, ağlayış"
  },
  {
    "x": "bermayox, -e",
    "y": " ağlayan"
  },
  {
    "x": "berme (n)",
    "y": " ağlama"
  },
  {
    "x": "berme qirrike ro pêşîyayene",
    "y": " ağlamaklı olmak"
  },
  {
    "x": "berme qirrikeropêşîyayîş (n)",
    "y": " ağlamaklı olma, ağlamsı olma, ağlamsama, doluklama, dolukma"
  },
  {
    "x": "bermi (n)",
    "y": " b. berme (n)"
  },
  {
    "x": "bermi kerdene",
    "y": " b. bermî kerdene"
  },
  {
    "x": "bermi qirriki ro pêşîyayene",
    "y": " b. berme qirrike ro pêşîyayene"
  },
  {
    "x": "bermi qirrikiropêşîyayîş (n)",
    "y": " b. berme qirrikeropêşîyayîş (n)"
  },
  {
    "x": "bermi ro dayene",
    "y": " b. bermî ro dayene"
  },
  {
    "x": "bermi ro kerdene",
    "y": " b. bermî ro kerdene"
  },
  {
    "x": "bermik (n)",
    "y": " b. bermok, -e"
  },
  {
    "x": "bermikerdiş (n)",
    "y": " b. bermîkerdiş (n)"
  },
  {
    "x": "bermikin, -i",
    "y": " b. bermokin, -e"
  },
  {
    "x": "bermikî (m)",
    "y": " b. bermok, -e"
  },
  {
    "x": "bermirodayîş (n)",
    "y": " b. bermîrodayîş (n)"
  },
  {
    "x": "bermirokerdiş (n)",
    "y": " b. bermîrokerdiş (n)"
  },
  {
    "x": "bermiş (n)",
    "y": " b. bermayîş (n)"
  },
  {
    "x": "bermî (n)",
    "y": " b. berme (n)"
  },
  {
    "x": "bermî dayene",
    "y": " ağlatmak"
  },
  {
    "x": "bermî fîştene",
    "y": " ağlatmak"
  },
  {
    "x": "bermî kerdene",
    "y": " ağlatmak"
  },
  {
    "x": "bermî ro dayene",
    "y": " ağlatmak"
  },
  {
    "x": "bermî ro kerdene",
    "y": " ağlatmak"
  },
  {
    "x": "bermîdayîş (n)",
    "y": " ağlatma"
  },
  {
    "x": "bermîfîştiş (n)",
    "y": " ağlatma"
  },
  {
    "x": "bermîkerdiş (n)",
    "y": " ağlatma"
  },
  {
    "x": "bermîn, -i",
    "y": " b. bermînok, -e"
  },
  {
    "x": "bermînok, -e",
    "y": " sulu göz, sulu gözlü, hemen ağlayan"
  },
  {
    "x": "bermîrodayîş (n)",
    "y": " ağlatma"
  },
  {
    "x": "bermîrokerdiş (n)",
    "y": " ağlatma"
  },
  {
    "x": "bermnayene",
    "y": " ağlatmak"
  },
  {
    "x": "bermnayîş (n)",
    "y": " ağlatma"
  },
  {
    "x": "bermok, -e",
    "y": " sulu göz, sulu gözlü; hemen ağlayan"
  },
  {
    "x": "bermoki (m)",
    "y": " b. bermok, -e"
  },
  {
    "x": "bermokin, -e",
    "y": " sulu göz, sulu gözlü; hemen ağlayan"
  },
  {
    "x": "bermokini (m)",
    "y": " b. bermokin, -e"
  },
  {
    "x": "Bern (n)",
    "y": " Bern"
  },
  {
    "x": "bername (n)",
    "y": " program\r\n~bernameyê perwerdeyî (n): eğitim programı\r\n~bernameyo bingeyî (n): ana program"
  },
  {
    "x": "bernameyê perwerdeyî (n)",
    "y": " eğitim programı "
  },
  {
    "x": "bernameyo bingeyî (n)",
    "y": " ana program"
  },
  {
    "x": "bernameyo hedef (n)",
    "y": " hedef program"
  },
  {
    "x": "bero (n)",
    "y": " b. beran (n)"
  },
  {
    "x": "beron (n)",
    "y": " b. beran (n)"
  },
  {
    "x": "beron dayene",
    "y": " b. beran dayene"
  },
  {
    "x": "beron werdene",
    "y": " b. beran werdene"
  },
  {
    "x": "beroş (n)",
    "y": " 1)bakraç \r\n~2)kova \r\n~3)tencere"
  },
  {
    "x": "berpêserkerdiş (n)",
    "y": " ürün toplama"
  },
  {
    "x": "berpirsîyar, -e",
    "y": " sorumlu, mesul\r\n~berpirsîyara/ê cayê karî (m): işyeri sorumlusu\r\n~berpirsîyara/ê viraştişî: yapım sorumlusu"
  },
  {
    "x": "berpirsîyara/ê cayê karî (m)",
    "y": " işyeri sorumlusu"
  },
  {
    "x": "berpirsîyara/ê produksîyonî (sn)",
    "y": " yapım sorumlusu"
  },
  {
    "x": "berpirsiyara/berpirsîyarê rêxistinkerdişî",
    "y": " örgütleme sorumlusu"
  },
  {
    "x": "berpirsîyara/ê setî (sn)",
    "y": " set sorumlusu"
  },
  {
    "x": "berpirsîyara/ê viraştişî",
    "y": " yapım sorumlusu"
  },
  {
    "x": "berpirsîyarîye (m)",
    "y": " sorumluluk, mesuliyet\r\n~berpirsîyarîya cayê karî (m): işyeri sorumluluğu"
  },
  {
    "x": "berpirsîyarîya cayê karî (m)",
    "y": " işyeri sorumluluğu"
  },
  {
    "x": "berqaf (n)",
    "y": " çit biçimindeki kapı"
  },
  {
    "x": "berqafî (n)",
    "y": " b. berqaf (n)"
  },
  {
    "x": "berqestî ra",
    "y": " 1)mahsus \r\n~2)şakadan \r\n~3)yalancıktan, yalandan"
  },
  {
    "x": "berqestî ya",
    "y": " b. berqestî ra"
  },
  {
    "x": "berqeşt ra",
    "y": " b. berqestî ra"
  },
  {
    "x": "berqeştîye ra",
    "y": " b. berqestî ra"
  },
  {
    "x": "berqêştî ra",
    "y": " b. berqestî ra"
  },
  {
    "x": "berqêştî ya",
    "y": " b. berqestî ra"
  },
  {
    "x": "berrad (m)",
    "y": " b. berradî (m)"
  },
  {
    "x": "berradî (m)",
    "y": " eğe, törpü"
  },
  {
    "x": "berradî kerdene",
    "y": " eğelemek"
  },
  {
    "x": "berradîya nenûgî (m)",
    "y": " tırnak törpüsü"
  },
  {
    "x": "berre, -ye",
    "y": " lider, önder"
  },
  {
    "x": "berrezîyayene",
    "y": " b. berizîyayene"
  },
  {
    "x": "berrezîyayîş (n)",
    "y": " b. berizîyayîş (n)"
  },
  {
    "x": "berrizîyayene",
    "y": " b. berizîyayene"
  },
  {
    "x": "berrizîyayîş (n)",
    "y": " b. berizîyayîş (n)"
  },
  {
    "x": "berrî, -ye",
    "y": " karasal"
  },
  {
    "x": "berrîl, -i",
    "y": " b. berîl, -e"
  },
  {
    "x": "berşewal",
    "y": " b. bêşelwarî (n, m)"
  },
  {
    "x": "berşoyî (zh)",
    "y": " gecelik (kadın için), pijama (kadın için)"
  },
  {
    "x": "berteng (n)",
    "y": " sofa, hol"
  },
  {
    "x": "berteref, -e (s)",
    "y": " bertaraf"
  },
  {
    "x": "bertîl (n)",
    "y": " rüşvet"
  },
  {
    "x": "bertîl dayene",
    "y": " rüşvet vermek"
  },
  {
    "x": "bertîl dayene werdene",
    "y": " rüşvet yedirmek"
  },
  {
    "x": "bertîl werdene",
    "y": " rüşvet yemek"
  },
  {
    "x": "berû (n)",
    "y": " b. beran (n)"
  },
  {
    "x": "berûn (n)",
    "y": " b. beran (n)"
  },
  {
    "x": "berûn dayene",
    "y": " b. beran dayene"
  },
  {
    "x": "berûn werdene",
    "y": " b. beran werdene"
  },
  {
    "x": "bervayene",
    "y": " b. bermayene"
  },
  {
    "x": "bervayêne",
    "y": " b. bermayene"
  },
  {
    "x": "bervayine",
    "y": " b. bermayene"
  },
  {
    "x": "bervayis (n)",
    "y": " b. bermayîş (n)"
  },
  {
    "x": "bervayîne",
    "y": " b. bermayene"
  },
  {
    "x": "bervayîs (n)",
    "y": " b. bermayîş (n)"
  },
  {
    "x": "bervayîş (n)",
    "y": " b. bermayîş (n)"
  },
  {
    "x": "bervis (n)",
    "y": " b. bermayîş (n)"
  },
  {
    "x": "berviş (n)",
    "y": " b. bermayîş (n)"
  },
  {
    "x": "bervîs (n)",
    "y": " b. bermayîş (n)"
  },
  {
    "x": "bervîş (n)",
    "y": " b. bermayîş (n)"
  },
  {
    "x": "Berwarî",
    "y": " Pervari"
  },
  {
    "x": "berx (n)",
    "y": " kuzudan büyük olan küçük yaştaki koç "
  },
  {
    "x": "berxudar",
    "y": " berhudar \r\n~Berxudar be. (Berhudar ol./Teşekkür ederim.)\r\n~\t\r\n~Berxudar bî/ba. (Berhudar olasın./Teşekkür ederim.)\r\n~\t\r\n~Berxudar bi. b. Berxudar be.\r\n~\t\r\n~Berxudar ib. b. Berxudar be."
  },
  {
    "x": "berxul (n)",
    "y": " b. belxur (n)"
  },
  {
    "x": "berya (m)",
    "y": " b. ber-I (n)"
  },
  {
    "x": "berz, -e",
    "y": " yüksek, yüce, ali"
  },
  {
    "x": "berz bîyene",
    "y": " kalkmak"
  },
  {
    "x": "berz kerdene",
    "y": " kaldırmak"
  },
  {
    "x": "berza (m)",
    "y": " b. bezra (m)"
  },
  {
    "x": "berzane (n)",
    "y": " yükselti"
  },
  {
    "x": "berzbîyayîş (n)",
    "y": " kalkma"
  },
  {
    "x": "berze (n)",
    "y": " b. bezre (n)"
  },
  {
    "x": "berzek, -e",
    "y": " yüksekçe"
  },
  {
    "x": "berzey (m)",
    "y": " b. berzîye (m)"
  },
  {
    "x": "berzeya pêle (m)",
    "y": " b. berzîya pêlî (m)"
  },
  {
    "x": "berzîya pêlî (m)",
    "y": " dalga yüksekliği"
  },
  {
    "x": "berzê (m)",
    "y": " b. berzî (m)"
  },
  {
    "x": "berzên (n)",
    "y": " b. berzîn (n)"
  },
  {
    "x": "berzênî (m)",
    "y": " yükseklik"
  },
  {
    "x": "berzi (n)",
    "y": " b. bezre (n)"
  },
  {
    "x": "berzik (n)",
    "y": " apış arası kılları, etek kılları"
  },
  {
    "x": "berzî (m)",
    "y": " yükseklik\r\n~berzîya pêlî (m): dalga yüksekliği"
  },
  {
    "x": "berzîya bîna (m)",
    "y": " bina yüksekliği"
  },
  {
    "x": "berzîn (n)",
    "y": " yatarken başın konduğu yüksekçe yer\r\n~Berzînê xeyrî bo. (Geçmiş olsun.) \r\n~\t"
  },
  {
    "x": "Berzînê xêrî bo.",
    "y": " b. Berzînê xeyrî bo."
  },
  {
    "x": "berzînî het de",
    "y": " baş ucunda"
  },
  {
    "x": "berzînî ver de",
    "y": " baş ucunda"
  },
  {
    "x": "berzîye (m)",
    "y": " yükseklik"
  },
  {
    "x": "berzîya bîna (m)",
    "y": " bina yüksekliği"
  },
  {
    "x": "berzîyen (m)",
    "y": " b. berzîn (n)"
  },
  {
    "x": "berzker (n)",
    "y": " kaldıraç, elevatör\r\n~berzkero bisele (n): sepetli kaldıraç"
  },
  {
    "x": "berzkero bisele (n)",
    "y": " sepetli kaldıraç"
  },
  {
    "x": "berzkero bisepete (n)",
    "y": " sepetli kaldıraç"
  },
  {
    "x": "berzkerdiş (n)",
    "y": " kaldırma"
  },
  {
    "x": "bes-I",
    "y": " kâfi, kifayetli, yeter, yeter ki, yeterli"
  },
  {
    "x": "bes bîyayene",
    "y": " b. bes bîyene"
  },
  {
    "x": "bes bîyene",
    "y": " yetmek, yeterli olmak"
  },
  {
    "x": "bes-II (n)",
    "y": " b. beş (n)"
  },
  {
    "x": "besbîyayîş (n)",
    "y": " yetme, yeterli olma"
  },
  {
    "x": "besekerdene",
    "y": " edebilmek, yapabilmek, muktedir olmak"
  },
  {
    "x": "besekerdiş (n)",
    "y": " edebilme, yapabilme, muktedir olma"
  },
  {
    "x": "besey (m)",
    "y": " b. besî (m), b. besîye (m)"
  },
  {
    "x": "beseykerdene",
    "y": " b. besekerdene"
  },
  {
    "x": "beseykerdiş (n)",
    "y": " b. besekerdiş (n)"
  },
  {
    "x": "besê (m)",
    "y": " b. besî (m), besîye (m)"
  },
  {
    "x": "besilma (m)",
    "y": " b. beslima (m)"
  },
  {
    "x": "besî (m)",
    "y": " kifayet"
  },
  {
    "x": "besîret (n)",
    "y": " basiret, sağgörü"
  },
  {
    "x": "besît, -e",
    "y": " basit, bayağı"
  },
  {
    "x": "besîye (m)",
    "y": " kifayet, yeterlilik, yeterlik"
  },
  {
    "x": "beskemate (m)",
    "y": " peksimet"
  },
  {
    "x": "beskemati (m)",
    "y": " b. beskemate (m)"
  },
  {
    "x": "beskimati (m)",
    "y": " b. beskemate (m)"
  },
  {
    "x": "beslima (m)",
    "y": " besleme, kadın hizmetçi, beslemelik"
  },
  {
    "x": "best (n)",
    "y": " b. beste (m)"
  },
  {
    "x": "besta (m)",
    "y": " 1)bağlı \r\n~2)beste"
  },
  {
    "x": "beste (m)",
    "y": " kulaç"
  },
  {
    "x": "beste (n) ",
    "y": " bağlı"
  },
  {
    "x": "bestekar, -e",
    "y": " besteci, bestekâr, kompozitör"
  },
  {
    "x": "bestene",
    "y": " 1)bağlamak \r\n~2)kuşanmak (kuşak, fişeklik vb. için) \r\n~3)dama vb. oyunlarda rakip oyuncuya oynama (taşını sürme) imkanı bırakmamak, bağlanmış duruma getirmek"
  },
  {
    "x": "besterdene",
    "y": " b. estiritene"
  },
  {
    "x": "besterdiş (n)",
    "y": " b. estiritiş (n)"
  },
  {
    "x": "besteritene",
    "y": " b. estiritene"
  },
  {
    "x": "besteritiş (n)",
    "y": " b. estiritiş (n)"
  },
  {
    "x": "besteyey (m)",
    "y": " b. besteyîye (m)"
  },
  {
    "x": "besteyê (m)",
    "y": " b. besteyî (m)"
  },
  {
    "x": "besteyî (m)",
    "y": " bağlılık"
  },
  {
    "x": "besteyîş (n)",
    "y": " b. bestîyayîş (n)"
  },
  {
    "x": "besteyîye (m)",
    "y": " bağlılık"
  },
  {
    "x": "bestê (m)",
    "y": " b. besta (m)"
  },
  {
    "x": "besti (m)",
    "y": " b. beste (m)"
  },
  {
    "x": "bestiş (n)",
    "y": " 1)bağlama \r\n~2)kuşanma (kuşak, fişeklik vb. için)"
  },
  {
    "x": "bestî, -ye,",
    "y": " bağlı"
  },
  {
    "x": "bestîyayene",
    "y": " 1)bağlanmak \r\n~2)oluşmak\r\n~Hewr bestîyayo. (Bulut oluşmuş.)"
  },
  {
    "x": "bestîyayîş (n)",
    "y": " 1)bağlanma \r\n~2)oluşma, oluşum"
  },
  {
    "x": "bestîyî (m)",
    "y": " b. besteyî (m)"
  },
  {
    "x": "bestox (n)",
    "y": " bağlaç"
  },
  {
    "x": "beş-I (n)",
    "y": " akıtma (küçükbaş ve büyükbaş hayvanların alnındaki aklık) "
  },
  {
    "x": "beş, -e-II",
    "y": " alnı akıtmalı hayvan"
  },
  {
    "x": "beş-III (n)",
    "y": " 1)bölüm, bap, şube \r\n~2)(mecazi) kesit"
  },
  {
    "x": "beş kerdene",
    "y": " bölümlemek, sınıflamak, sınıflandırmak, tasnif etmek, tasniflemek"
  },
  {
    "x": "beşê komelî",
    "y": " (mecazi) kesit"
  },
  {
    "x": "beşê sînema (n)",
    "y": " sinema bölümü"
  },
  {
    "x": "beş-IV (n)",
    "y": " b. puş (n)"
  },
  {
    "x": "beşê pîyanzî (n)",
    "y": " b. puşê pîyazî (n"
  },
  {
    "x": "Beş-V (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "beşdar, -e",
    "y": " katılımcı, katılan"
  },
  {
    "x": "beşdar bîyene",
    "y": " katılmak"
  },
  {
    "x": "beşdarbîyayîş (n)",
    "y": " katılma"
  },
  {
    "x": "beşdarênî (m)",
    "y": " katılım"
  },
  {
    "x": "beşdarî (m)",
    "y": " 1)katılım \r\n~2)katkı\r\n~beşdarîya eynîye (m): ayni katkı"
  },
  {
    "x": "beşdarîye (m)",
    "y": " 1)katılım \r\n~2)katkı"
  },
  {
    "x": "beşdarîya eynîye (m)",
    "y": " ayni katkı"
  },
  {
    "x": "beşdarîyî (m)",
    "y": " katılımcılık"
  },
  {
    "x": "beşdarîyîye (m)",
    "y": " katılımcılık"
  },
  {
    "x": "Beşe (m)",
    "y": " inek ve keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "beşer (n)",
    "y": " âdemoğlu, insanoğlu, beşer, beniâdem"
  },
  {
    "x": "beşerîyayene",
    "y": " b. beşirîyayene"
  },
  {
    "x": "beşerîyayîş (n)",
    "y": " b. beşirîyayîş (n)"
  },
  {
    "x": "beşêkerdene",
    "y": " b. besekerdene"
  },
  {
    "x": "besêkerdiş (n)",
    "y": " b. besekerdiş (n)"
  },
  {
    "x": "beşêla (m)",
    "y": " b. beşîla (m)"
  },
  {
    "x": "Beşi (m)",
    "y": " b. Beşe (m)"
  },
  {
    "x": "beşirîyayene",
    "y": " gülümsemek"
  },
  {
    "x": "beşirîyayîş (n)",
    "y": " gülümseme"
  },
  {
    "x": "beşî (zh)",
    "y": " aksam (kısımlar)"
  },
  {
    "x": "beşîla (m)",
    "y": " kavun"
  },
  {
    "x": "beşkerdiş (n)",
    "y": " klasifikasyon, bölümleme, sınıflama, sınıflandırma, tasnif, tasnif etme, tasnifleme"
  },
  {
    "x": "beşla (m)",
    "y": " b. beşîla (m)"
  },
  {
    "x": "beşnderg, -i",
    "y": " b. bejnderg, -e"
  },
  {
    "x": "beşne (m)",
    "y": " b. bejne (m)"
  },
  {
    "x": "beşni (m)",
    "y": " b. bejne (m)"
  },
  {
    "x": "beşnûbali (m)",
    "y": " b. bejnûbale (m)"
  },
  {
    "x": "bet (n)",
    "y": " b. bext (n)-2"
  },
  {
    "x": "BET (bacê erja tewrkerdişî)",
    "y": " KDV (katma değer vergisi)"
  },
  {
    "x": "betal, -e",
    "y": " 1)işsiz\r\n~2)geçersiz, hükümsüz; batıl\r\n~Mareyê ey betal o. (Onun nikâhı geçersizdir.)\r\n~3)iptal"
  },
  {
    "x": "betal kerdene",
    "y": " iptal etmek"
  },
  {
    "x": "betaley (m)",
    "y": " b. betalî (m)"
  },
  {
    "x": "betalê (m)",
    "y": " b. betalî (m)"
  },
  {
    "x": "betalî (m)",
    "y": " 1)işsizlik \r\n~2)geçersizlik, hükümsüzlük"
  },
  {
    "x": "betalkerdiş (n)",
    "y": " fesih, iptal etme\r\n~betalkerdişê aboneyîye (n): abonelik iptali"
  },
  {
    "x": "betalkerdiş-îhdasê kadroyî",
    "y": " kadro iptal-ihdas"
  },
  {
    "x": "betalkerdişê aboneyan (n)",
    "y": " abone iptali"
  },
  {
    "x": "betalkerdişê aboneyîye (n)",
    "y": " abonelik iptali"
  },
  {
    "x": "betalkerdişê kadroyî",
    "y": " kadro iptali"
  },
  {
    "x": "betana (m)",
    "y": " b. betanîya (m)"
  },
  {
    "x": "betanî (m)",
    "y": " b. betanîya (m)"
  },
  {
    "x": "betanîya (m)",
    "y": " battaniye\r\n~betanîyaya şewatî (m): yangın battaniyesi"
  },
  {
    "x": "betanîyaya şewatî (m)",
    "y": " yangın battaniyesi"
  },
  {
    "x": "bete (m)",
    "y": " 1)toy (bir kuş) \r\n~2)b. bette (m)"
  },
  {
    "x": "betel (n)",
    "y": " b. betil (n)"
  },
  {
    "x": "betelîyaya (m)",
    "y": " b. betilîyaya (m) "
  },
  {
    "x": "betelîyaye (n)",
    "y": " b. betilîyaye (n)"
  },
  {
    "x": "betelîyayene",
    "y": " b. betilîyayene"
  },
  {
    "x": "betelîyayeyî (m)",
    "y": " b. betilîyayeyî (m)"
  },
  {
    "x": "betelîyayîş (n)",
    "y": " b. betilîyayîş (n)"
  },
  {
    "x": "betelîyene",
    "y": " b. betilîyayene"
  },
  {
    "x": "betelnayene",
    "y": " b. betilnayene"
  },
  {
    "x": "betelnayîş (n)",
    "y": " b. betilnayîş (n)"
  },
  {
    "x": "beti (m)",
    "y": " b. bette (m)"
  },
  {
    "x": "betil (n)",
    "y": " yorgunluk\r\n~ Wa tîkê betilê xo bigîro. (Yorgunluğunu biraz gidersin.)\t"
  },
  {
    "x": "betileya (m)",
    "y": " b. betilîyaya (m)"
  },
  {
    "x": "betileye (n)",
    "y": " b. betilîyaye (n)"
  },
  {
    "x": "betileyeyê (m)",
    "y": " b. betilîyayeyî (m)"
  },
  {
    "x": "betilîyaya (m)",
    "y": " yorgun"
  },
  {
    "x": "betilîyaye (n)",
    "y": " yorgun"
  },
  {
    "x": "betilîyayene",
    "y": " yorulmak"
  },
  {
    "x": "betilîyayeyî (m)",
    "y": " yorgunluk"
  },
  {
    "x": "betilîyayîş (n)",
    "y": " yorulma"
  },
  {
    "x": "betilnayene",
    "y": " yormak"
  },
  {
    "x": "betilnayîş (n)",
    "y": " yorma"
  },
  {
    "x": "betilyaya (m)",
    "y": " b. betilîyaya (m)"
  },
  {
    "x": "betilyaye (n)",
    "y": " b. betilîyaye (n)"
  },
  {
    "x": "betilyayene",
    "y": " b. betilîyayene"
  },
  {
    "x": "betilyayeyê (m)",
    "y": " b. betilîyayeyî (m)"
  },
  {
    "x": "betilyayîş (n)",
    "y": " b. betilîyayîş (n)"
  },
  {
    "x": "betl (n)",
    "y": " b. betil (n)"
  },
  {
    "x": "betlane (n)",
    "y": " tatil\r\n~betlaneyo fermî (n): resmî tatil"
  },
  {
    "x": "betlaneyo fermî (n)",
    "y": " resmî tatil"
  },
  {
    "x": "betleya (m)",
    "y": " b. betilîyaya (m)"
  },
  {
    "x": "betleye (n)",
    "y": " b. betilîyaye (n)"
  },
  {
    "x": "betleyeyî (m)",
    "y": " b. betilîyayeyî (m)"
  },
  {
    "x": "betleyîş (n)",
    "y": " b. betilîyayîş (n)"
  },
  {
    "x": "betlîyaya (m)",
    "y": " b. betilîyaya (m)"
  },
  {
    "x": "betlîyaye (n)",
    "y": " b. betilîyaye (n)"
  },
  {
    "x": "betlîyayene",
    "y": " b. betilîyayene"
  },
  {
    "x": "betlîyayeyê (m)",
    "y": " b. betilîyayeyî (m)"
  },
  {
    "x": "betlîyayîş (n)",
    "y": " b. betilîyayîş (n)"
  },
  {
    "x": "betlnayene",
    "y": " b. betilnayene"
  },
  {
    "x": "betlnayîş (n)",
    "y": " b. betilnayîş (n)"
  },
  {
    "x": "beton (n)",
    "y": " beton"
  },
  {
    "x": "betonarme (n)",
    "y": " betonarme"
  },
  {
    "x": "bette (m)",
    "y": " ördek"
  },
  {
    "x": "betti (m)",
    "y": " b. bette (m)"
  },
  {
    "x": "betun (n)",
    "y": " b. beton (n)"
  },
  {
    "x": "betûn (n)",
    "y": " b. beton (n)"
  },
  {
    "x": "bewç (m)",
    "y": " b. boçe (m)"
  },
  {
    "x": "bewli",
    "y": " b. bellî, -ye-I"
  },
  {
    "x": "bewlî",
    "y": " b. bellî, -ye-II"
  },
  {
    "x": "bewlîye (n)",
    "y": " bevliye"
  },
  {
    "x": "bewlu",
    "y": " b. bellî, -ye-III"
  },
  {
    "x": "bewlû",
    "y": " b. bellî, -ye-IV"
  },
  {
    "x": "bewrane (m)",
    "y": " b. borane (m)"
  },
  {
    "x": "bewrani (m)",
    "y": " b. borane (m)"
  },
  {
    "x": "bewranî (m)",
    "y": " b. boranî (m) "
  },
  {
    "x": "bewron (m)",
    "y": " b. borane (m)"
  },
  {
    "x": "bewrone (m)",
    "y": " b. borane (m)"
  },
  {
    "x": "bewroni (m)",
    "y": " b. borane (m)"
  },
  {
    "x": "bewrûn (m)",
    "y": " b. borane (m)"
  },
  {
    "x": "bewse (n)",
    "y": " b. bose (n)"
  },
  {
    "x": "bewver (n)",
    "y": " b. bover (n)"
  },
  {
    "x": "bexaj (n)",
    "y": " b. bagaj (n)"
  },
  {
    "x": "bexçe (n)",
    "y": " b. baxçe (n)"
  },
  {
    "x": "bexçewan, -e",
    "y": " b. baxçewan, -e"
  },
  {
    "x": "Bexdad (n)",
    "y": " Bağdat"
  },
  {
    "x": "bexdadî (n)",
    "y": " yaş ağaç dallarıyla örülüp sıvanmış olan ince duvar"
  },
  {
    "x": "bexdenos (n)",
    "y": " b. maydanoz (n)"
  },
  {
    "x": "bexêr",
    "y": " b. bixeyr"
  },
  {
    "x": "ma bexêr",
    "y": " b. ma bixeyr dî"
  },
  {
    "x": "ma bexêr dî",
    "y": " b. ma bixeyr dî"
  },
  {
    "x": "bexiş (n)",
    "y": " 1)af\r\n~bexişê awanî (n) : imar affı\r\n~2)bağış"
  },
  {
    "x": "bexiş kerdene",
    "y": " affetmek, bağışlamak"
  },
  {
    "x": "bexişê awanî (n) ",
    "y": "imar affı"
  },
  {
    "x": "bexiş dayene",
    "y": " bağış yapmak, bağışta bulunmak"
  },
  {
    "x": "bexiş kerdene",
    "y": " bahşetmek"
  },
  {
    "x": "bexişkerdiş (n)",
    "y": " affetme, bağışlama"
  },
  {
    "x": "bexişkerdox, -e",
    "y": "  bağışlayıcı"
  },
  {
    "x": "bexişnayene",
    "y": " 1)affetmek, bağışlamak \r\n~2)bahşetmek"
  },
  {
    "x": "bexişnayîş (n)",
    "y": " 1)affetme, bağışlama \r\n~2)bahşetme"
  },
  {
    "x": "bexişnayox, -e",
    "y": " bağışlayıcı"
  },
  {
    "x": "bexîl, -e",
    "y": " b. pexîl, -e"
  },
  {
    "x": "bexîley (m)",
    "y": " b. pexîlîye (m), pexîlî (m)"
  },
  {
    "x": "bexîley kerdene",
    "y": " b. pexîlîye kerdene"
  },
  {
    "x": "bexîlê (m)",
    "y": " b. pexîlîye (m), pexîlî (m)"
  },
  {
    "x": "bexîlê kerdene",
    "y": " b. pexîlî kerdene"
  },
  {
    "x": "bexîlênî (m)",
    "y": " b. pexîlênî (m)"
  },
  {
    "x": "bexlî",
    "y": " b. belû-I (m)"
  },
  {
    "x": "bexoradayîş (n)",
    "y": " b. xoradayîş (n)"
  },
  {
    "x": "bexş (n)",
    "y": " b. bexiş (n)"
  },
  {
    "x": "bexşîş (n)",
    "y": " bahşiş"
  },
  {
    "x": "bexşîş arê dayene",
    "y": " bahşiş toplamak"
  },
  {
    "x": "bext (n)",
    "y": " 1)baht, şans, talih \r\n~2)uğurböceği"
  },
  {
    "x": "bext waştene",
    "y": " iltica etmek, sığınmak"
  },
  {
    "x": "bextakerda (m)",
    "y": " bahtı açık"
  },
  {
    "x": "bextakerde (n)",
    "y": " bahtı açık"
  },
  {
    "x": "bextakerdê (m)",
    "y": " b. bextakerda (m)"
  },
  {
    "x": "bextakerdî, -ye",
    "y": " bahtı açık"
  },
  {
    "x": "bextewar, -e",
    "y": " b. bextewer, -e"
  },
  {
    "x": "bextewarî (m)",
    "y": " b. bextewerî (m)"
  },
  {
    "x": "bextewarîye (m)",
    "y": " b. bextewerîye (m)"
  },
  {
    "x": "bextewer, -e",
    "y": " mutlu, bahtiyar"
  },
  {
    "x": "bextewerî (m)",
    "y": " mutluluk, bahtiyarlık"
  },
  {
    "x": "bextewerîye (m)",
    "y": " mutluluk, bahtiyarlık"
  },
  {
    "x": "bextîyar, -e",
    "y": " mutlu, bahtiyar"
  },
  {
    "x": "bextîyarênî (m)",
    "y": " b. bextewerîye (m)"
  },
  {
    "x": "bextîyarî (m)",
    "y": "mutluluk, bahtiyarlık"
  },
  {
    "x": "bextîyarîye (m)",
    "y": " mutluluk, bahtiyarlık"
  },
  {
    "x": "bextsîya, -ye",
    "y": " bahtı kara"
  },
  {
    "x": "bextşa, -ye",
    "y": " b. bextsîya, -ye"
  },
  {
    "x": "bextşîya, -ye",
    "y": " b. bextsîya, -ye"
  },
  {
    "x": "bextwaştiş (n)",
    "y": " iltica, sığınma"
  },
  {
    "x": "bextwaştox, -e",
    "y": " sığınık, mülteci"
  },
  {
    "x": "bexûd, -e",
    "y": " çirkin"
  },
  {
    "x": "bey (m)",
    "y": " b. beye (m)"
  },
  {
    "x": "beya (m)",
    "y": " b. bîyaya (m)"
  },
  {
    "x": "beyan (n)",
    "y": " beyan"
  },
  {
    "x": "beyan kerdene",
    "y": " beyan etmek"
  },
  {
    "x": "beyanê emlakî (n)",
    "y": " emlak beyanı"
  },
  {
    "x": "beyanê werasetî (n)",
    "y": " veraset beyanı"
  },
  {
    "x": "beyanat (n)",
    "y": " demeç, beyanat"
  },
  {
    "x": "beyankerdiş (n)",
    "y": " beyan etme "
  },
  {
    "x": "beyanname (n)",
    "y": " beyanname\r\n~beyannameyê malî (n): mal beyannamesi"
  },
  {
    "x": "beyannameyê malî (n)",
    "y": " mal beyannamesi"
  },
  {
    "x": "beyar (n)",
    "y": " bor, geleme, gen (arazi için), hozan"
  },
  {
    "x": "beyar verdayene",
    "y": " tarlayı sürmeden dinlenmeye bırakmak"
  },
  {
    "x": "beyare (n)",
    "y": " b. beyar (n)"
  },
  {
    "x": "beyat, -e",
    "y": " bayat"
  },
  {
    "x": "beyat bîyene",
    "y": " bayatlamak"
  },
  {
    "x": "beyat kerdene",
    "y": " bayatlatmak"
  },
  {
    "x": "beyatbîyayîş (n)",
    "y": " bayatlama"
  },
  {
    "x": "beyatkerdiş (n)",
    "y": " bayatlatma"
  },
  {
    "x": "beyaxçî, -ye",
    "y": " b. beyaxkî, -ye"
  },
  {
    "x": "beyaxkî, -ye",
    "y": " yabancı, bigâne\r\n~No xort beyaxkî yo. (Bu delikanlı yabancıdır.)"
  },
  {
    "x": "beye-I (m)",
    "y": " ayva"
  },
  {
    "x": "beye-II (m)",
    "y": " güve"
  },
  {
    "x": "beye ci estene",
    "y": " güve yemek (güvenin yemesi)"
  },
  {
    "x": "beye ci sanayene",
    "y": " güve yemek (güvenin yemesi)"
  },
  {
    "x": "beye-III (n)",
    "y": " b. bîyaye (n)"
  },
  {
    "x": "beyene",
    "y": " b. bîyene"
  },
  {
    "x": "beyêre (m)",
    "y": " ayva ağacı "
  },
  {
    "x": "beyêri (m)",
    "y": " b. beyêre (m)"
  },
  {
    "x": "beyi (m)",
    "y": " b. beye (m)"
  },
  {
    "x": "beyîş (n)",
    "y": " b. bîyayîş (n)"
  },
  {
    "x": "beyîti (m)",
    "y": " b. beyte (m)"
  },
  {
    "x": "beyk (n)",
    "y": " b. bawk (n) "
  },
  {
    "x": "beyli",
    "y": " b. bellî, -ye-I"
  },
  {
    "x": "beylu",
    "y": " b. bellî, -ye-I"
  },
  {
    "x": "beylu bîyayîne",
    "y": " b. bellî bîyene"
  },
  {
    "x": "beylû",
    "y": " b. bellî, -ye"
  },
  {
    "x": "beynate (n)",
    "y": " b. beyntar (n)"
  },
  {
    "x": "beynate xirab bîyene",
    "y": " b. beyntar xirab bîyene"
  },
  {
    "x": "beynatexirabbîyayîş (n)",
    "y": " b. beyntarxirabbîyayîş (n)"
  },
  {
    "x": "beynatî",
    "y": " b. beyntarî, -ye"
  },
  {
    "x": "beyntar (n)",
    "y": " ara, aralık"
  },
  {
    "x": "beyntar herimnayene",
    "y": " arayı açmak (görüşmemek)"
  },
  {
    "x": "beyntar xirab bîyene",
    "y": " bozuşmak, araları açılmak"
  },
  {
    "x": "beyntare (n)",
    "y": " b. beyntar (n)"
  },
  {
    "x": "beyntarî, -ye",
    "y": " aradaki"
  },
  {
    "x": "beyntarxirabbîyayîş (n)",
    "y": " bozuşma"
  },
  {
    "x": "beyon (n)",
    "y": " b. beyan (n)"
  },
  {
    "x": "beyon kerdene",
    "y": " b. beyan kerdene"
  },
  {
    "x": "beyonkerdiş (n)",
    "y": " b. beyankerdiş (n)"
  },
  {
    "x": "beyra (n)",
    "y": " b. ber-I (n)"
  },
  {
    "x": "beyraqe (m)",
    "y": " bayrak"
  },
  {
    "x": "beyraye",
    "y": " b. ber-I (n)"
  },
  {
    "x": "Beyrûd (n)",
    "y": " Beyrut"
  },
  {
    "x": "beyşla (m)",
    "y": " b. beşîla (m)"
  },
  {
    "x": "beyti (m)",
    "y": " b. beyte (m)"
  },
  {
    "x": "beyte (m)",
    "y": " beyit"
  },
  {
    "x": "beyta destpêkerdişî (m)",
    "y": " başlangıç beyti "
  },
  {
    "x": "beyta peyêne (m)",
    "y": " son beyit"
  },
  {
    "x": "beytulheya, -ye",
    "y": " ağzı bozuk, ağzı pis"
  },
  {
    "x": "beytutî, -ye",
    "y": " bayat"
  },
  {
    "x": "beytutî bîyene",
    "y": " bayatlamak"
  },
  {
    "x": "beytutî kerdene",
    "y": " bayatlatmak"
  },
  {
    "x": "beytutîbîyayîş (n)",
    "y": " bayatlama"
  },
  {
    "x": "beytutîkerdiş (n)",
    "y": " bayatlatma"
  },
  {
    "x": "beyûn (n)",
    "y": " b. beyan (n)"
  },
  {
    "x": "beyûn kerdene",
    "y": " b. beyan kerdene"
  },
  {
    "x": "beyûnkerdiş (n)",
    "y": " b. beyankerdiş (n)"
  },
  {
    "x": "bez (n)",
    "y": " b. bej (n)"
  },
  {
    "x": "bez (n)",
    "y": " b. bej (n)"
  },
  {
    "x": "bezî, -ye",
    "y": " b. bejî, -ye"
  },
  {
    "x": "bezbind (n)",
    "y": " b. bazbend (n)"
  },
  {
    "x": "bezelya (m)",
    "y": " bezelye "
  },
  {
    "x": "bezelye (m)",
    "y": " b. bezelya (m)"
  },
  {
    "x": "bezira (m)",
    "y": " b. bezra (m)"
  },
  {
    "x": "bezirgan",
    "y": " b. bazirgan, -e"
  },
  {
    "x": "bezirganey (m)",
    "y": " b. bazirganî (m)"
  },
  {
    "x": "bezîrgan, -e",
    "y": " b. bazirgan, -e"
  },
  {
    "x": "bezîrganê (m)",
    "y": " b. bazirganî (m)"
  },
  {
    "x": "bezîrganîye (m)",
    "y": " b. bazirganîye (m)"
  },
  {
    "x": "bezn (m)",
    "y": " b. bejne (m)"
  },
  {
    "x": "beznaye (n)",
    "y": " b. vîjnaye (n)"
  },
  {
    "x": "beznayêne",
    "y": " b. vîjnayene"
  },
  {
    "x": "beznayîş (n)",
    "y": " b. vîjnayîş (n)"
  },
  {
    "x": "beznayîye (m)",
    "y": " b. vîjnayîye (m), vîjnaya (m)"
  },
  {
    "x": "beznderg, -e",
    "y": " b. bejnderg, -e"
  },
  {
    "x": "bezne (m)",
    "y": " b. bejne (m)"
  },
  {
    "x": "bezor",
    "y": " b. bizor"
  },
  {
    "x": "bezra (m)",
    "y": " çekirdek"
  },
  {
    "x": "bezra ronayene",
    "y": " tohum ekmek"
  },
  {
    "x": "bezre (n)",
    "y": " tohum"
  },
  {
    "x": "bezre estene",
    "y": " b. bezre eştene"
  },
  {
    "x": "bezre eştene",
    "y": " tohum serpmek, tohum ekmek (tahıl için)"
  },
  {
    "x": "bezre eşteni",
    "y": " b. bezre eştene"
  },
  {
    "x": "bezre eyşteni",
    "y": " b. bezre eştene"
  },
  {
    "x": "bezre vistene",
    "y": " b. bezre eştene"
  },
  {
    "x": "bezre visteni",
    "y": " b. bezre eştene"
  },
  {
    "x": "bezredekerdiş (n)",
    "y": " yarıcılık sisteminde taraflardan birinin tohumu vermesi/karşılaması"
  },
  {
    "x": "bezri (n)",
    "y": " b. bezre (n)"
  },
  {
    "x": "bezri eşteni",
    "y": " b. bezre eştene"
  },
  {
    "x": "bezrik (m)",
    "y": " b. pizrike (m)"
  },
  {
    "x": "bezrike (m)",
    "y": " b. pizrike (m)"
  },
  {
    "x": "bezriki (m)",
    "y": " b. pizrike (m)"
  },
  {
    "x": "bê-I (vr)",
    "y": " -sız, -siz; -den başka\r\n~Ma bê to nêşinê. (Biz sensiz gitmeyiz.)\r\n~Bê to her kes amebî. (Senden başka herkes gelmişti.)"
  },
  {
    "x": "bê dec",
    "y": " b. bê dej"
  },
  {
    "x": "bê naye",
    "y": " ayrıca"
  },
  {
    "x": "bê ney",
    "y": " ayrıca"
  },
  {
    "x": "bê nimitiş",
    "y": " açık açık"
  },
  {
    "x": "bê waştiş kerdene",
    "y": " ağırdan almak"
  },
  {
    "x": "bê zerrî kerdene",
    "y": " ağırdan almak"
  },
  {
    "x": "bê-II (m)",
    "y": " b. beye (m)"
  },
  {
    "x": "bêaga",
    "y": " b. bêagah, -e"
  },
  {
    "x": "bêagah, -e",
    "y": " habersiz, bihaber"
  },
  {
    "x": "bêaheng, -e",
    "y": " ahenksiz, uyumsuz"
  },
  {
    "x": "bêahengîye (m)",
    "y": " ahenksizlik"
  },
  {
    "x": "bêahlaq, -e",
    "y": " b. bêexlaq, -e"
  },
  {
    "x": "bêahlaq bîyene",
    "y": " b. bêexlaq bîyene"
  },
  {
    "x": "bêahlaqane",
    "y": " b. bêexlaqane"
  },
  {
    "x": "bêahlaqî (m)",
    "y": " b. bêexlaqî (m)"
  },
  {
    "x": "bêahlaqîye (m)",
    "y": " b. bêexlaqîye"
  },
  {
    "x": "bêahlaqone",
    "y": " b. bêexlaqane"
  },
  {
    "x": "bêahlaqûne",
    "y": " b. bêexlaqane"
  },
  {
    "x": "bêaîle, -ye",
    "y": " ailesiz"
  },
  {
    "x": "bêalerjî, -ye",
    "y": " alerjisiz"
  },
  {
    "x": "bêalkol, -e",
    "y": " alkolsüz"
  },
  {
    "x": "bêalkolî (m)",
    "y": " alkosüzlük"
  },
  {
    "x": "bêalkolîye (m)",
    "y": " alkosüzlük"
  },
  {
    "x": "bêalternatîf, -e",
    "y": " alternatifsiz"
  },
  {
    "x": "bêalternatîfîye (m)",
    "y": " alternatifsizlik"
  },
  {
    "x": "bêambalaj, -e",
    "y": " ambalajsız"
  },
  {
    "x": "bêamir, -e",
    "y": " amirsiz"
  },
  {
    "x": "bêanason, -e",
    "y": " anasonsuz"
  },
  {
    "x": "bêantrenman, -e",
    "y": " antrenmansız"
  },
  {
    "x": "bêantrenmanîye (m)",
    "y": " antrenmansızlık"
  },
  {
    "x": "bêaqil, -e",
    "y": " akılsız"
  },
  {
    "x": "bêaqilane",
    "y": " akılsızca"
  },
  {
    "x": "bêaqilîye (m)",
    "y": " akılsızlık"
  },
  {
    "x": "bêarize, -ye",
    "y": " arızasız"
  },
  {
    "x": "bêatile",
    "y": " aralıksız, ardı ardına"
  },
  {
    "x": "bêaw, -e",
    "y": " susuz"
  },
  {
    "x": "bêawdaz, -e",
    "y": " b. bêawdest, -e"
  },
  {
    "x": "bêawdest, -e",
    "y": " 1)abdestsiz, aptessiz \r\n~2)cünüp, cenabet"
  },
  {
    "x": "bêawê (m)",
    "y": " b. bêawî (m), bêawîye (m) "
  },
  {
    "x": "bêawî (m)",
    "y": " susuzluk"
  },
  {
    "x": "bêawîye (m)",
    "y": " susuzluk"
  },
  {
    "x": "bêaxkî, -ye",
    "y": " b. beyaxkî, -ye"
  },
  {
    "x": "bêbabî, -ye",
    "y": " babasız, yetim"
  },
  {
    "x": "bêbabîyîye (m)",
    "y": " babasızlık"
  },
  {
    "x": "bêbadana",
    "y": " badanasız"
  },
  {
    "x": "bêban",
    "y": " b. filan-bêban"
  },
  {
    "x": "bêbar, -e",
    "y": " yüksüz"
  },
  {
    "x": "bêbasarax, -e",
    "y": " basiretsiz, sağgörüsüz"
  },
  {
    "x": "bêbasaraxîye (m)",
    "y": " basiretsizlik, sağgörüsüzlük"
  },
  {
    "x": "bêbasarox, -e",
    "y": " b. bêbasarax, -e"
  },
  {
    "x": "bêbasaroxîye (m)",
    "y": " b. bêbasaraxîye (m)"
  },
  {
    "x": "bêbasirax, -e",
    "y": " b. bêbasarax, -e"
  },
  {
    "x": "bêbasiraxîye (m)",
    "y": " b. bêbasaraxîye (m)"
  },
  {
    "x": "bêbasîret, -e",
    "y": " b. bêbesîret, -e"
  },
  {
    "x": "bêbasîretîye (m)",
    "y": " b. bêbesîretîye (m)"
  },
  {
    "x": "bêbav, -e",
    "y": " acımasız"
  },
  {
    "x": "bêbavane",
    "y": " acımasızca"
  },
  {
    "x": "bêbavi (n)",
    "y": " b. bêbav, -e"
  },
  {
    "x": "bêbeharat, -e",
    "y": " baharatsız"
  },
  {
    "x": "bêbereket, -e",
    "y": " bereketsiz"
  },
  {
    "x": "bêberketey (m)",
    "y": " b. bêbereketîye (m)"
  },
  {
    "x": "bêberketê (m)",
    "y": " b. bêbereketîye (m)"
  },
  {
    "x": "bêbereketîye (m)",
    "y": " bereketsizlik"
  },
  {
    "x": "bêbesîret, -e",
    "y": " basiretsiz, sağgörüsüz"
  },
  {
    "x": "bêbesîretîye (m)",
    "y": " basiretsizlik, sağgörüsüzlük"
  },
  {
    "x": "bêbext, -e",
    "y": " 1)kalleş \r\n~2)şanssız, talihsiz, bahtsız, bedbaht"
  },
  {
    "x": "bêbextîye (m)",
    "y": " 1)kalleşlik \r\n~2)şanssızlık, talihsizlik, bahtsızlık, bedbahtlık"
  },
  {
    "x": "bêbeyntar",
    "y": " aralıksız, ardı ardına"
  },
  {
    "x": "bêbin, -e-I",
    "y": " dipsiz, altsız"
  },
  {
    "x": "bêbin-II (n)",
    "y": " bent (suyu biriktirmek için önüne yapılan set); set"
  },
  {
    "x": "bêbin bestene",
    "y": " set çekmek"
  },
  {
    "x": "bêbinge, -ye",
    "y": " asılsız, batıl"
  },
  {
    "x": "bêbingeyîye (m)",
    "y": " asılsızlık"
  },
  {
    "x": "bêbinyat, -e",
    "y": " asılsız"
  },
  {
    "x": "bêbinyatîye (m)",
    "y": " asılsızlık"
  },
  {
    "x": "bêbon",
    "y": " b. filon-bêbon"
  },
  {
    "x": "bêc",
    "y": " 1)b. bej (n) \r\n~2)b. bejî, -ye"
  },
  {
    "x": "bêcam, -e",
    "y": " camsız"
  },
  {
    "x": "bêcan, -e",
    "y": " cansız"
  },
  {
    "x": "bêcer kerdene",
    "y": " becermek"
  },
  {
    "x": "bêcerkerdiş (n)",
    "y": " becerme"
  },
  {
    "x": "bêcidîyet, -e",
    "y": " ciddiyetsiz, gayriciddi"
  },
  {
    "x": "bêcins, -e",
    "y": " soysuz"
  },
  {
    "x": "bêcinsênî (m)",
    "y": " soysuzluk"
  },
  {
    "x": "bêcinsî (m)",
    "y": " soysuzluk"
  },
  {
    "x": "bêcinsîye (m)",
    "y": " soysuzluk"
  },
  {
    "x": "bêcis, -e",
    "y": " b. bêcins, -e"
  },
  {
    "x": "bêcisey (m)",
    "y": " b. bêcinsîye"
  },
  {
    "x": "bêcisê (m)",
    "y": " b. bêcinsî (m)"
  },
  {
    "x": "bêcisi (m)",
    "y": " b. bêcinsî (m)"
  },
  {
    "x": "bêcisn, -e",
    "y": " b. bêcins, -e"
  },
  {
    "x": "bêcisnê (m)",
    "y": " b. bêcinsî (m)"
  },
  {
    "x": "bêcisnênî (m)",
    "y": " b. bêcinsênî (m)"
  },
  {
    "x": "bêcisnîye (m)",
    "y": " b. bêcinsîye"
  },
  {
    "x": "bêcus, -i",
    "y": " b. bêcins, -e"
  },
  {
    "x": "bêcusey (m)",
    "y": " b. bêcinsîye"
  },
  {
    "x": "bêcusê (m)",
    "y": " b. bêcinsî (m)"
  },
  {
    "x": "bêcusî (m)",
    "y": " b. bêcinsî (m)"
  },
  {
    "x": "bêçap, -e",
    "y": " çapsız"
  },
  {
    "x": "bêçe (m)",
    "y": " b. bêçike (m)"
  },
  {
    "x": "bêçar (n, m)",
    "y": " b. bêçare, bêçarîye (m)"
  },
  {
    "x": "bêçara (m)",
    "y": " çaresiz, biçare, ilaçsız"
  },
  {
    "x": "bêçare (n)",
    "y": " çaresiz, biçare, ilaçsız"
  },
  {
    "x": "bêçare mendene",
    "y": " çaresiz kalmak, acze düşmek"
  },
  {
    "x": "bêçareyey (m)",
    "y": " b. bêçareyîye (m)"
  },
  {
    "x": "bêçareyê (m)",
    "y": " b. bêçareyîye (m)"
  },
  {
    "x": "bêçareyîye (m)",
    "y": " çaresizlik"
  },
  {
    "x": "bêçarîye (m)",
    "y": " çaresiz, biçare, ilaçsız"
  },
  {
    "x": "bêçef, -e",
    "y": " b. bêkeyf, -e"
  },
  {
    "x": "bêçef bîyayene",
    "y": " b. bêkeyf bîyene"
  },
  {
    "x": "bêçêf bîyayene",
    "y": " b. bêkeyf bîyene"
  },
  {
    "x": "bêçek, -e",
    "y": " silahsız"
  },
  {
    "x": "bêçembela (m)",
    "y": " b. bêçembile (m)"
  },
  {
    "x": "bêçembele (n)",
    "y": " b. bêçembil (n)"
  },
  {
    "x": "bêçembil, -e",
    "y": " kulpsuz"
  },
  {
    "x": "bêçemla (m)",
    "y": " b. bêçembile (m)"
  },
  {
    "x": "bêçemle (n)",
    "y": " b. bêçembil (n)"
  },
  {
    "x": "bêçepil, -e",
    "y": " b. bêçembil, -e"
  },
  {
    "x": "bêçerpe",
    "y": " badanasız"
  },
  {
    "x": "bêçetinî",
    "y": " çekişmesiz(çekişmeye yol açmayan)"
  },
  {
    "x": "bêçewder, -e",
    "y": " çavdarsız"
  },
  {
    "x": "bêçêf, -e",
    "y": " b. bêkeyf, -e"
  },
  {
    "x": "bêçêfênî (m)",
    "y": " b. bêkeyfênî (m)"
  },
  {
    "x": "bêçêfîye (m)",
    "y": " b. bêkeyfîye (m), bêkeyfî (m)"
  },
  {
    "x": "bêçike (m)",
    "y": " parmak\r\n~bêçika darloxî (m): yuvağı çekmek için kullanılan çatallı aracın yuvağın girintili yerine geçen ucu\r\n~bêçika derge (m): orta parmak\r\n~bêçika nîştaneyî (m): yüzük parmağı\r\n~bêçika pîle (m): başparmak"
  },
  {
    "x": "bêçika darloxî (m)",
    "y": " yuvağı çekmek için kullanılan çatallı aracın yuvağın girintili yerine geçen ucu"
  },
  {
    "x": "bêçika derge (m)",
    "y": " orta parmak"
  },
  {
    "x": "bêçika destî (m)",
    "y": " el parmağı"
  },
  {
    "x": "bêçika îşaretî (m)",
    "y": " işaret parmağı, şehadet parmağı"
  },
  {
    "x": "bêçika îştanî (m)",
    "y": " b. bêçika nîştaneyî (m)"
  },
  {
    "x": "bêçika linge (m)",
    "y": " ayak parmağı"
  },
  {
    "x": "bêçika nîştaneyî (m)",
    "y": " yüzük parmağı"
  },
  {
    "x": "bêçika ortî (m)",
    "y": " orta parmak"
  },
  {
    "x": "bêçika pîle (m)",
    "y": " başparmak"
  },
  {
    "x": "bêçika qalinde (m)",
    "y": " başparmak"
  },
  {
    "x": "bêçika qolinde (m)",
    "y": " b. bêçika qalinde (m)"
  },
  {
    "x": "bêçika silawetî (m)",
    "y": " işaret parmağı, şehadet parmağı"
  },
  {
    "x": "bêçika şadênî (m)",
    "y": " işaret parmağı, şehadet parmağı"
  },
  {
    "x": "bêçika wertî (m)",
    "y": " b. bêçika ortî (m)"
  },
  {
    "x": "bêçika xo ro ci nayene",
    "y": " basmak"
  },
  {
    "x": "bêçike ci kuwayene",
    "y": " baltalamak (mecazi), sabote etmek (mecazi)"
  },
  {
    "x": "bêçikecikuwayîş (n)",
    "y": " baltalama (mecazi), sabote etme (mecazi), sabotaj, sabote"
  },
  {
    "x": "bêçikecikuyayîş (n)",
    "y": " bêçikecikuwayîş (n)"
  },
  {
    "x": "bêçim, -e",
    "y": " gözsüz"
  },
  {
    "x": "bêçimekin, -e",
    "y": " çekmecesiz"
  },
  {
    "x": "bêçimeyin, -e",
    "y": " çekmecesiz"
  },
  {
    "x": "bêdadî (n, m)",
    "y": " annesiz (annesi ölmüş olan çocuk), anasız"
  },
  {
    "x": "bêdadî û bêbabî, -ye",
    "y": " öksüz"
  },
  {
    "x": "bêdadî û bêbabîyîye (m)",
    "y": " öksüzlük"
  },
  {
    "x": "bêdadîyîye (m)",
    "y": " annesizlik, anasızlık"
  },
  {
    "x": "bêdar (n, m) ",
    "y": " b. bêdare (n, m)"
  },
  {
    "x": "bêdard, -i",
    "y": " b. bêderd, -e"
  },
  {
    "x": "bêdare (n, m)",
    "y": " ağaçsız"
  },
  {
    "x": "bêdarîye (m)",
    "y": " ağaçsızlık"
  },
  {
    "x": "bêdaru, -ye",
    "y": " ilaçsız, biilaç, dermansız"
  },
  {
    "x": "bêdaye (n, m)",
    "y": " b. bêdadî (n, m)"
  },
  {
    "x": "bêdaye û bêbaye",
    "y": " b. bêdadî û bêbabî"
  },
  {
    "x": "bêdec, -e",
    "y": " b. bêdej, -e"
  },
  {
    "x": "bêdec, -i",
    "y": " b. bêdej, -e"
  },
  {
    "x": "bêdecey (m)",
    "y": " b. bêdejîye (m)"
  },
  {
    "x": "bêdecê (m)",
    "y": " b. bêdejî (m)"
  },
  {
    "x": "bêdecî (m)",
    "y": " b. bêdejî (m)"
  },
  {
    "x": "bêdej, -e",
    "y": " 1)ağrısız \r\n~2)üzüntüsüz"
  },
  {
    "x": "bêdejey (m)",
    "y": " b. bêdejîye (m)"
  },
  {
    "x": "bêdejê (m)",
    "y": " b. bêdejî (m)"
  },
  {
    "x": "bêdeji (m)",
    "y": " b. bêdeje (m)"
  },
  {
    "x": "bêdejî (m)",
    "y": " üzüntüsüzlük"
  },
  {
    "x": "bêdejîye (m)",
    "y": " 1)ağrısızlık \r\n~2)üzüntüsüzlük"
  },
  {
    "x": "bêdeq, -e",
    "y": " çilsiz"
  },
  {
    "x": "bêderd, -e",
    "y": " dertsiz"
  },
  {
    "x": "bêderman, -e",
    "y": " ilaçsız, biilaç, dermansız"
  },
  {
    "x": "bêdesen, -e",
    "y": " desensiz "
  },
  {
    "x": "bêdesmac (n)",
    "y": " b. bêdestmaj (n)"
  },
  {
    "x": "bêdesmac bîyayene",
    "y": " b. bêdestmaj bîyene"
  },
  {
    "x": "bêdesmac bîyene",
    "y": " b. bêdesmaj bîyene"
  },
  {
    "x": "bêdesmace (m)",
    "y": " b. bêdesmaje (m)"
  },
  {
    "x": "bêdesmacey (m)",
    "y": " b. bêdestmajîye (m)"
  },
  {
    "x": "bêdesmacîye (m)",
    "y": " b. bêdestmajîye (m)"
  },
  {
    "x": "bêdesmajey (m)",
    "y": " b. bêdestmajîye (m)"
  },
  {
    "x": "bêdestik, -e",
    "y": " kulpsuz"
  },
  {
    "x": "bêdestmac bîyayene",
    "y": " b. bêdestmaj bîyene"
  },
  {
    "x": "bêdestmacê (m)",
    "y": " b. bêdestmajîye (m)"
  },
  {
    "x": "bêdestmaj (n)",
    "y": " 1)abdestsiz erkek, aptessiz erkek \r\n~2)cünüp, cenabet"
  },
  {
    "x": "bêdestmaj bîyene",
    "y": " 1)abdestsiz olmak, aptessiz olmak \r\n~2)(kadın) aybaşı olmak, âdet görmek"
  },
  {
    "x": "bêdestmaje (m)",
    "y": " 1)abdestsiz kadın, aptessiz kadın\r\n~2)âdet (aybaşı, hayız) geçirmekte olan kadın"
  },
  {
    "x": "bêdestmajî (m)",
    "y": " 1)abdestsizlik, aptessizlik \r\n~2)aybaşı (kadın için), âdet, hayız \r\n~3)cünüp olma, cenabet olma"
  },
  {
    "x": "bêdestmajîye (m)",
    "y": " 1)abdestsizlik, aptessizlik \r\n~2)aybaşı (kadın için), âdet, hayız \r\n~3)cünüp olma, cenabet olma"
  },
  {
    "x": "bêdeşmaj (n)",
    "y": " b. bêdestmaj (n)"
  },
  {
    "x": "bêdeşmaj bîyayene",
    "y": " b. bêdestmaj bîyene"
  },
  {
    "x": "bêdeşmaj bîyene",
    "y": " b. bêdestmaj bîyene"
  },
  {
    "x": "bêdeşmaje (m)",
    "y": " b. bêdestmaje (m)"
  },
  {
    "x": "bêdeşmajey (m)",
    "y": " b. bêdestmajîye (m)"
  },
  {
    "x": "bêdeştene",
    "y": " dikişsiz"
  },
  {
    "x": "bêdewam, -e",
    "y": " devamsız, süreksiz"
  },
  {
    "x": "bêdewamey (m)",
    "y": " b. bêdewamîye"
  },
  {
    "x": "bêdewamê (m)",
    "y": " b. bêdewamî (m)"
  },
  {
    "x": "bêdewamî (m)",
    "y": " devamsızlık, süreksizlik"
  },
  {
    "x": "bêdewamîye (m)",
    "y": " devamsızlık, süreksizlik"
  },
  {
    "x": "bêdewomey (m)",
    "y": " b. bêdewamîye"
  },
  {
    "x": "bêdewomê (m)",
    "y": " b. bêdewamî (m)"
  },
  {
    "x": "bêdewûmey (m)",
    "y": " b. bêdewamîye"
  },
  {
    "x": "bêdewûmî (m)",
    "y": " b. bêdewamî (m)"
  },
  {
    "x": "bêdeyax, -e",
    "y": " sabırsız"
  },
  {
    "x": "bêdeyaxey (m)",
    "y": " b. bêdeyaxî (m)"
  },
  {
    "x": "bêdeyaxê (m)",
    "y": " b. bêdeyaxî (m)"
  },
  {
    "x": "bêdeyaxî (m)",
    "y": " sabırsızlık"
  },
  {
    "x": "bêdez, -e",
    "y": " b. bêdej, -e"
  },
  {
    "x": "bêdezîye (m)",
    "y": " b. bêdejîye (m)"
  },
  {
    "x": "bêdic",
    "y": " b. bêdej, -e"
  },
  {
    "x": "bêdicî (m)",
    "y": " b. bêdejî (m)"
  },
  {
    "x": "bêdij",
    "y": " b. bêdej, -e"
  },
  {
    "x": "bêdijî (m)",
    "y": " b. bêdejî (m)"
  },
  {
    "x": "bêdîn, -e",
    "y": " dinsiz"
  },
  {
    "x": "bêdîn û bêîman, -e",
    "y": " dinsiz ve imansız"
  },
  {
    "x": "bêdînane",
    "y": " dinsizce"
  },
  {
    "x": "bêdîney (m)",
    "y": " b. bêdînîye (m)"
  },
  {
    "x": "bêdînê (m)",
    "y": " b. bêdînî (m)"
  },
  {
    "x": "bêdînên (m)",
    "y": " b. bêdînênî (m)"
  },
  {
    "x": "bêdînênî (m)",
    "y": " dinsizlik"
  },
  {
    "x": "bêdîni (m)",
    "y": " b. bêdîn, -e"
  },
  {
    "x": "bêdînî (m)",
    "y": " dinsizlik"
  },
  {
    "x": "bêdînîtî (m)",
    "y": " dinsizlik"
  },
  {
    "x": "bêdînîye (m)",
    "y": " dinsizlik"
  },
  {
    "x": "bêdînkî",
    "y": " dinsizce"
  },
  {
    "x": "bêdînone",
    "y": " b. bêdînane"
  },
  {
    "x": "bêdînûne",
    "y": " b. bêdînane"
  },
  {
    "x": "bêdrok (n)",
    "y": " altı dar üstü geniş, alüminyumdan yapılma kova"
  },
  {
    "x": "bêedal",
    "y": " b. bêedil"
  },
  {
    "x": "bêedalet, -e",
    "y": " adaletsiz"
  },
  {
    "x": "bêedaletey (m)",
    "y": " b. bêedaletî (m)"
  },
  {
    "x": "bêedaletê (m)",
    "y": " b. bêedaletî (m)"
  },
  {
    "x": "bêedaletî (m)",
    "y": " adaletsizlik"
  },
  {
    "x": "bêedaletîye (m)",
    "y": " adaletsizlik"
  },
  {
    "x": "bêedel",
    "y": " b. bêedil"
  },
  {
    "x": "bêedil",
    "y": " durmaksızın"
  },
  {
    "x": "bêehemîyet, -e",
    "y": " önemsiz"
  },
  {
    "x": "bêehlaq, -e",
    "y": " b. bêexlaq, -e"
  },
  {
    "x": "bêehlaq bîyene",
    "y": " b. bêexlaq bîyene"
  },
  {
    "x": "bêehlaqane",
    "y": " b. bêexlaqane"
  },
  {
    "x": "bêehlaqey (m)",
    "y": " b. bêexlaqîye"
  },
  {
    "x": "bêehlaqê (m)",
    "y": " b. bêexlaqî (m), bêexlaqîye (m)"
  },
  {
    "x": "bêehlaqî (m)",
    "y": " b. bêexlaqî (m)"
  },
  {
    "x": "bêehlaqîye (m)",
    "y": " b. bêexlaqîye"
  },
  {
    "x": "bêehlaqone",
    "y": " b. bêexlaqane"
  },
  {
    "x": "bêeleqa (m)",
    "y": " ilgisiz, alakasız, bigâne"
  },
  {
    "x": "bêeleqeyîye (m)",
    "y": " ilgisizlik, alaksızlık"
  },
  {
    "x": "bêeleqe, -ye",
    "y": " ilgisiz, alakasız, bigâne"
  },
  {
    "x": "bêendîşe, -ye",
    "y": " endişesiz, kaygısız, tasasız"
  },
  {
    "x": "bêerje",
    "y": " çapsız (mecazî)"
  },
  {
    "x": "bêesl, -e",
    "y": " asılsız"
  },
  {
    "x": "bêeslîye (m)",
    "y": " asılsızlık"
  },
  {
    "x": "bêeslûfesl, -e",
    "y": " 1)asılsız \r\n~2)soysuz"
  },
  {
    "x": "bêeslûfeslîye (m)",
    "y": " 1)asılsızlık \r\n~2)soysuzluk"
  },
  {
    "x": "bêestere",
    "y": " b. bêestrî, -ye"
  },
  {
    "x": "bêestereyî (m)",
    "y": " b. bêestrîyî (m)"
  },
  {
    "x": "bêestire",
    "y": " b. bêestrî, -ye"
  },
  {
    "x": "bêestireyî (m)",
    "y": " b. bêestrîyî (m)"
  },
  {
    "x": "bêestiri",
    "y": " b. bêestrî, -ye"
  },
  {
    "x": "bêestiriyî (m)",
    "y": " b. bêestrîyî (m)"
  },
  {
    "x": "bêestre",
    "y": " b. bêestrî, -ye"
  },
  {
    "x": "bêestreyî (m)",
    "y": " b. bêestrîyî (m)"
  },
  {
    "x": "bêestrî, -ye",
    "y": " boynuzsuz"
  },
  {
    "x": "bêestrîyî (m)",
    "y": " boynuzsuzluk"
  },
  {
    "x": "bêestrîyîye (m)",
    "y": " boynuzsuzluk"
  },
  {
    "x": "bêexlaq, -e",
    "y": " ahlaksız"
  },
  {
    "x": "bêexlaq bîyene",
    "y": " ahlaksızlaşmak"
  },
  {
    "x": "bêexlaqane",
    "y": " ahlaksızca"
  },
  {
    "x": "bêexlaqî (m)",
    "y": " ahlaksızlık"
  },
  {
    "x": "bêexlaqîye",
    "y": " ahlaksızlık"
  },
  {
    "x": "bêeyar, -e",
    "y": " ayarsız"
  },
  {
    "x": "bêeyarîye (m)",
    "y": " ayarsızlık"
  },
  {
    "x": "bêeyb, -e",
    "y": " ayıpsız, kusursuz"
  },
  {
    "x": "bêeyne",
    "y": " aynasız"
  },
  {
    "x": "bêfaîz (n)",
    "y": " faizsiz"
  },
  {
    "x": "bêfahm, -e",
    "y": " b. bêfehm, -e"
  },
  {
    "x": "bêfam, -e",
    "y": " b. bêfehm, -e"
  },
  {
    "x": "bêfamey (m)",
    "y": " b. bêfehmî (m), bêfehmîye (m)"
  },
  {
    "x": "bêfamî (m)",
    "y": " b. bêfehmî (m), bêfehmîye (m)"
  },
  {
    "x": "bêfasal (n, m)",
    "y": " b. bêfesal, -e"
  },
  {
    "x": "bêfasila",
    "y": " b. bêfesle"
  },
  {
    "x": "bêfayda (m)",
    "y": " b. bêfeyde, -ye"
  },
  {
    "x": "bêfayde (n)",
    "y": " b. bêfeyde, -y"
  },
  {
    "x": "bêfayiz (n)",
    "y": " b. bêfaîz (n)"
  },
  {
    "x": "bêfayîz (n)",
    "y": " b. bêfaîz (n)"
  },
  {
    "x": "bêfehm, -e",
    "y": " anlayışsız"
  },
  {
    "x": "bêfehmênî (m)",
    "y": " anlayışsızlık"
  },
  {
    "x": "bêfehmî (m)",
    "y": " anlayışsızlık"
  },
  {
    "x": "bêfehmîye (m)",
    "y": " anlayışsızlık"
  },
  {
    "x": "bêferaset, -e",
    "y": " anlayışsız"
  },
  {
    "x": "bêferasetey (m)",
    "y": " b. bêferasetî (m), bêferasetîye (m)"
  },
  {
    "x": "bêferasetê (m)",
    "y": " b. bêferasetî (m), bêferasetîye (m)"
  },
  {
    "x": "bêferasetênî (m)",
    "y": " anlayışsızlık"
  },
  {
    "x": "bêferasetî (m)",
    "y": " anlayışsızlık"
  },
  {
    "x": "bêferasetîye (m)",
    "y": " anlayışsızlık"
  },
  {
    "x": "bêfesal, -e",
    "y": " ahenksiz, uyumsuz"
  },
  {
    "x": "bêfesal, -i",
    "y": " b. bêfesal, -e"
  },
  {
    "x": "bêfesle",
    "y": " aralıksız, ardı ardına"
  },
  {
    "x": "bêfeyde, -ye",
    "y": " 1)faydasız \r\n~2)beyhude"
  },
  {
    "x": "bêfêm, -e",
    "y": " b. bêfehm, -e"
  },
  {
    "x": "bêfêmey (m)",
    "y": " b. bêfehmî (m), bêfehmîye (m)"
  },
  {
    "x": "bêfêyde",
    "y": " b. bêfeyde, -ye"
  },
  {
    "x": "bêfikar, -e",
    "y": " endişesiz, kaygısız, tasasız"
  },
  {
    "x": "bêfindetiş",
    "y": " b. bêvindetiş"
  },
  {
    "x": "bêfom, -e",
    "y": " b. bêfehm, -e"
  },
  {
    "x": "bêfomîye (m)",
    "y": " b. bêfehmî (m), bêfehmîye (m)"
  },
  {
    "x": "bêform, -e",
    "y": " amorf, biçimsiz"
  },
  {
    "x": "bêfûmê (m)",
    "y": " b. bêfehmî (m), bêfehmîye (m)"
  },
  {
    "x": "bêgan, -e",
    "y": " cansız"
  },
  {
    "x": "bêgiriz, -e",
    "y": " pürüzsüz"
  },
  {
    "x": "bêgonîye (m)",
    "y": " kansızlık"
  },
  {
    "x": "bêgubra (m)",
    "y": " gübresiz"
  },
  {
    "x": "bêgubre, -ye",
    "y": " gübresiz"
  },
  {
    "x": "bêgueynê (m)",
    "y": " b. bêgonîye (m)"
  },
  {
    "x": "bêguman",
    "y": " elbet, elbette"
  },
  {
    "x": "bêguna (m)",
    "y": " bigünah, günahsız"
  },
  {
    "x": "bêgune, -ye",
    "y": " bigünah, günahsız"
  },
  {
    "x": "bêguriz, -e",
    "y": " pürüzsüz"
  },
  {
    "x": "bêguwînê (m)",
    "y": " b. bêgonîye (m)"
  },
  {
    "x": "bêgûînê (m)",
    "y": " b. bêgonîye (m)"
  },
  {
    "x": "bêgûnîyey (m)",
    "y": " b. bêgonîye (m)"
  },
  {
    "x": "bêgwîney (m)",
    "y": " b. bêgonîye (m)"
  },
  {
    "x": "bêhagî",
    "y": " b. bêhayî, -ye"
  },
  {
    "x": "bêhal, -e",
    "y": " halsiz, bitkin, mecalsiz, takatsiz"
  },
  {
    "x": "bêhal bîyayene",
    "y": " b. bêhal bîyene"
  },
  {
    "x": "bêhal bîyene",
    "y": " bitkin olmak, takatsiz olmak, halsiz olmak, mecalsiz olmak"
  },
  {
    "x": "bêhalbîyayîş (n)",
    "y": " bitkin olma, halsiz olma, mecalsiz olma, takatsiz olma"
  },
  {
    "x": "bêhaley (m)",
    "y": " b. bêhalîye (m)"
  },
  {
    "x": "bêhalê (m)",
    "y": " b. bêhalîye (m)"
  },
  {
    "x": "bêhali (m)",
    "y": " b. bêhal, -e"
  },
  {
    "x": "bêhalîye (m)",
    "y": " halsizlik, mecalsizlik, takatsizlik, bitkinlik, mecalsizlik, takatsizlik"
  },
  {
    "x": "bêhayî, -ye",
    "y": " habersiz, bihaber, aymaz, gafil"
  },
  {
    "x": "bêhayî bîyene",
    "y": " aymazlaşmak"
  },
  {
    "x": "bêhayîbîyayîş (n)",
    "y": " aymazlaşma"
  },
  {
    "x": "bêhayîtî (m)",
    "y": " aymazlık"
  },
  {
    "x": "bêhayîyey (m)",
    "y": " b. bêhayîyîye (m)"
  },
  {
    "x": "bêhayîyê (m)",
    "y": " b. bêhayîyîye (m)"
  },
  {
    "x": "bêhayîyî (m)",
    "y": " aymazlık"
  },
  {
    "x": "bêhayîyîye (m)",
    "y": " aymazlık"
  },
  {
    "x": "bêhemberî, -ye",
    "y": " çekişmesiz(çekişmeye yol açmayan)"
  },
  {
    "x": "bêheneke",
    "y": " ciddi"
  },
  {
    "x": "bêhereket, -e",
    "y": " hareketsiz"
  },
  {
    "x": "bêheş, -e",
    "y": " b. bêhiş, -e"
  },
  {
    "x": "bêhewn, -e",
    "y": " uykusuz"
  },
  {
    "x": "bêhewn verdayene",
    "y": " 1)uykusuz bırakmak \r\n~2)(kadınla) cinsel ilişkide bulunmak"
  },
  {
    "x": "bêhewnverdayîş (n)",
    "y": " 1)uykusuz bırakma \r\n~2)(kadınla) cinsel ilişkide bulunma"
  },
  {
    "x": "bêhewr (n)",
    "y": " bulutsuz"
  },
  {
    "x": "bêhêş, -i",
    "y": " b. bêhiş, -e"
  },
  {
    "x": "bêhêş, -e",
    "y": " b. bêhiş, -e"
  },
  {
    "x": "bêhêş bîyayîne",
    "y": " b. bêhiş bîyene"
  },
  {
    "x": "bêhêş kerdene",
    "y": " b. bêhiş kerdene"
  },
  {
    "x": "bêhêşey (m)",
    "y": " b. bêhişîye (m)"
  },
  {
    "x": "bêhêşê (m)",
    "y": " b. bêhişî (m)"
  },
  {
    "x": "bêhêvî, -ye",
    "y": " ümitsiz, umutsuz"
  },
  {
    "x": "bêhêz, -e",
    "y": " güçsüz, kuvvetsiz, çelimsiz"
  },
  {
    "x": "bêhiş, -e",
    "y": " 1)bilinçsiz, şuursuz \r\n~2)unutkan\r\n~Ez bêhiş a. (Ben unutkanım.)\r\n~3)sersem, alık"
  },
  {
    "x": "bêhiş bîyene",
    "y": " sersemleşmek, sersemlemek"
  },
  {
    "x": "bêhiş kerdene",
    "y": " sersemletmek, sersem etmek"
  },
  {
    "x": "bêhişane",
    "y": " bilinçsizce, şuursuzca"
  },
  {
    "x": "bêhişey (m)",
    "y": " b. bêhişîye (m)"
  },
  {
    "x": "bêhişê (m)",
    "y": " b. bêhişî (m)"
  },
  {
    "x": "bêhişî (m)",
    "y": " 1)bilinçsizlik, şuursuzluk \r\n~2)sersemlik, alıklık"
  },
  {
    "x": "bêhişîye (m)",
    "y": " 1)bilinçsizlik, şuursuzluk \r\n~2)sersemlik, alıklık"
  },
  {
    "x": "bêhîr, -e",
    "y": " bereketsiz"
  },
  {
    "x": "bêhîrîye (m)",
    "y": " bereketsizlik"
  },
  {
    "x": "bêhîs, -e",
    "y": " duygusuz, hissiz"
  },
  {
    "x": "bêhîsênî (m)",
    "y": " duygusuzluk, hissizlik"
  },
  {
    "x": "bêhîsî (m)",
    "y": " duygusuzluk, hissizlik"
  },
  {
    "x": "bêhîsîye (m)",
    "y": " duygusuzluk, hissizlik"
  },
  {
    "x": "bêhîss, -e",
    "y": " b. bêhîs, -e"
  },
  {
    "x": "bêhîssî (m)",
    "y": " b. bêhîsî (m), bêhîsîye (m)"
  },
  {
    "x": "bêhîssîye (m)",
    "y": " b. bêhîsî (m), bêhîsîye (m)"
  },
  {
    "x": "bêhoma, -ye",
    "y": " Allahsız, tanrısız"
  },
  {
    "x": "bêhomayî (m)",
    "y": " Allahsızlık, tanrısızlık"
  },
  {
    "x": "bêhomayîye (m)",
    "y": " Allahsızlık, tanrısızlık"
  },
  {
    "x": "bêhor (n)",
    "y": " b. bêhewr (n)"
  },
  {
    "x": "bêhowr (n)",
    "y": " b. bêhewr (n)"
  },
  {
    "x": "bêhukmî (m)",
    "y": " geçersizlik, hükümsüzlük"
  },
  {
    "x": "bêhuner, -e",
    "y": " beceriksiz"
  },
  {
    "x": "bêhunerîye (m)",
    "y": " beceriksizlik"
  },
  {
    "x": "bêhurmet, -e",
    "y": " saygısız, hürmetsiz"
  },
  {
    "x": "bêhurmetey (m)",
    "y": " b. bêhurmetîye (m)"
  },
  {
    "x": "bêhurmetê (m)",
    "y": " b. bêhurmetî (m)"
  },
  {
    "x": "bêhurmetî (m)",
    "y": " saygısızlık, hürmetsizlik"
  },
  {
    "x": "bêhurmetîye (m)",
    "y": " saygısızlık, hürmetsizlik"
  },
  {
    "x": "bêistere",
    "y": " b. bêestrî, -ye"
  },
  {
    "x": "bêistereyî (m)",
    "y": " b. bêestrîyî (m)"
  },
  {
    "x": "bêistre",
    "y": " b. bêestrî, -ye"
  },
  {
    "x": "bêistreyî (m)",
    "y": " b. bêestrîyî (m)"
  },
  {
    "x": "bêistrî",
    "y": " b. bêestrî, -ye"
  },
  {
    "x": "bêistrîyî (m)",
    "y": " b. bêestrîyî (m)"
  },
  {
    "x": "bêiştirî",
    "y": " b. bêestrî, -ye"
  },
  {
    "x": "bêiştirîye (m)",
    "y": " b. bêestrîyîye (m)"
  },
  {
    "x": "bêîlac, -e",
    "y": " biilaç, ilaçsız"
  },
  {
    "x": "bêîmtîyaz, -e",
    "y": " ayrıcalıksız, imtiyazsız"
  },
  {
    "x": "bêîntîzam, -e",
    "y": " düzensiz, intizamsız, nizamsız"
  },
  {
    "x": "bêîrtîbat, -e",
    "y": " bağlantısız"
  },
  {
    "x": "bêîrtîbatîye (m)",
    "y": " bağlantısızlık"
  },
  {
    "x": "bêîştahey (m)",
    "y": " b. bêîştahîye (m)"
  },
  {
    "x": "bêîştahê (m)",
    "y": " b. bêîştahîye (m)"
  },
  {
    "x": "bêîştahîye (m)",
    "y": " iştahsızlık"
  },
  {
    "x": "bêîştirî",
    "y": " b. bêestrî, -ye"
  },
  {
    "x": "bêîştirîye (m)",
    "y": " b. bêestrîyîye (m)"
  },
  {
    "x": "bêîştîrî, -ye",
    "y": " b. bêestrî, -ye"
  },
  {
    "x": "bêîştîrîye (m)",
    "y": " b. bêestrîyîye (m)"
  },
  {
    "x": "bêj-I",
    "y": " 1)b. bej (n) \r\n~2)b. bejî, -ye"
  },
  {
    "x": "bêj –II (n)",
    "y": " çeşit"
  },
  {
    "x": "bêjbibêj (s)",
    "y": " çeşitli\r\n~bêjbibêjîya bîyolojîke (m): biyolojik çeşitlilik, dirim bilimsel çeşitlilik"
  },
  {
    "x": "bêjbibêjîya bîyolojîke (m)",
    "y": " biyolojik çeşitlilik, dirim bilimsel çeşitlilik"
  },
  {
    "x": "bêjbibêjîye (m)",
    "y": " çeşitlilik"
  },
  {
    "x": "bêkar, -e-I",
    "y": " işsiz"
  },
  {
    "x": "bêkar, -e-II",
    "y": " b. bekar, -e"
  },
  {
    "x": "bêkardî, -ye",
    "y": " murdar, mundar, kesilmeden ölmüş hayvan\r\n~Goşto bêkardî nêwerîyêno. (Murdar eti yenmez.)"
  },
  {
    "x": "bêkardî bîyene",
    "y": " murdar olmak, mundar olmak"
  },
  {
    "x": "bêkardî kerdene",
    "y": " murdar etmek, mundar etmek"
  },
  {
    "x": "bêkarey-I (m)",
    "y": " b. bêkarîye (m)"
  },
  {
    "x": "bekarey-II (m)",
    "y": " b. bekarîye (m)"
  },
  {
    "x": "bêkarê (m)",
    "y": " b. bekarî (m)"
  },
  {
    "x": "bêkari (m)",
    "y": " b. bekare (m)"
  },
  {
    "x": "bêkarî-I (m)",
    "y": " işsizlik"
  },
  {
    "x": "bêkarî-II (m)",
    "y": " b. bekarî (m)"
  },
  {
    "x": "bêkarîye-I (m)",
    "y": " işsizlik"
  },
  {
    "x": "bêkarîye-II (m)",
    "y": " b. bekarîye (m)"
  },
  {
    "x": "bêkeder, -e",
    "y": " üzüntüsüz, kedersiz"
  },
  {
    "x": "bêkederî (m)",
    "y": " üzüntüsüzlük"
  },
  {
    "x": "bêkederîye (m)",
    "y": " üzüntüsüzlük"
  },
  {
    "x": "bêkerb, -e",
    "y": " üzüntüsüz"
  },
  {
    "x": "bêkerbizik, -e",
    "y": " üzüntüsüz"
  },
  {
    "x": "bêkerbizikî (m)",
    "y": " üzüntüsüzlük"
  },
  {
    "x": "bêkerbizikîye (m)",
    "y": " üzüntüsüzlük"
  },
  {
    "x": "bêkerbî (m)",
    "y": " üzüntüsüzlük"
  },
  {
    "x": "bêkerbîye (m)",
    "y": " üzüntüsüzlük"
  },
  {
    "x": "bêkes, -e",
    "y": " kimsesiz, bikes"
  },
  {
    "x": "bêkesey (m)",
    "y": " b. bêkesîye (m)"
  },
  {
    "x": "bêkesê (m)",
    "y": " b. bêkesîye (m)"
  },
  {
    "x": "bêkesî (m)",
    "y": " 1)yalnızlık \r\n~2)gariplik"
  },
  {
    "x": "bêkesîye (m)",
    "y": " 1)yalnızlık \r\n~2)gariplik"
  },
  {
    "x": "bêkeşî (m)",
    "y": " b. bêkesî (m)"
  },
  {
    "x": "bêkeşîye (m)",
    "y": " b. bêkesîye (m)"
  },
  {
    "x": "bêkeye, -ye",
    "y": " ailesiz"
  },
  {
    "x": "bêkeyf, -e",
    "y": " 1)keyifsiz, neşesiz \r\n~2)hasta, sağlıksız"
  },
  {
    "x": "bêkeyf bîyene",
    "y": " 1)keyifsizlenmek \r\n~2)hasta olmak, rahatsız olmak"
  },
  {
    "x": "bêkeyfbîyayîş (n)",
    "y": " rahatsız olma"
  },
  {
    "x": "bêkeyfey (m)",
    "y": " b. bêkeyfîye (m), bêkeyfî (m)"
  },
  {
    "x": "bêkeyfê (m)",
    "y": " b. bêkeyfîye (m), bêkeyfî (m)"
  },
  {
    "x": "bêkeyfênî (m)",
    "y": " keyifsizlik, neşesizlik"
  },
  {
    "x": "bêkeyfî (m)",
    "y": " keyifsizlik, neşesizlik"
  },
  {
    "x": "bêkeyfîye (m)",
    "y": " keyifsizlik, neşesizlik"
  },
  {
    "x": "bêkêf",
    "y": " b. bêkeyf, -e"
  },
  {
    "x": "bêkêf bîyayene",
    "y": " b. bêkeyf bîyene"
  },
  {
    "x": "bêkêfê (m)",
    "y": " b. bêkeyfîye (m), bêkeyfî (m)"
  },
  {
    "x": "bêkêfî (m)",
    "y": " b. bêkeyfîye (m), bêkeyfî (m)"
  },
  {
    "x": "bêkurata",
    "y": " ciddi"
  },
  {
    "x": "bêkuşat",
    "y": " ciddi"
  },
  {
    "x": "bêl (n)",
    "y": " b. bêle-I (m)"
  },
  {
    "x": "bêle-I (m)",
    "y": " bel"
  },
  {
    "x": "bêle-II (m)",
    "y": " b. bol-II (n)"
  },
  {
    "x": "bêlebat, -e",
    "y": " hareketsiz"
  },
  {
    "x": "bêlexî",
    "y": " ciddi"
  },
  {
    "x": "bêlezet, -e",
    "y": " tatsız, lezzetsiz"
  },
  {
    "x": "bêlezetî (m)",
    "y": " tatsızlık, lezzetsizlik"
  },
  {
    "x": "bêlezetîye (m)",
    "y": " tatsızlık, lezzetsizlik"
  },
  {
    "x": "bêlezzet, -e",
    "y": " b. bêlezet, -e"
  },
  {
    "x": "bêlezzetey (m)",
    "y": " b. bêlezetî (m), bêlezetîye (m)"
  },
  {
    "x": "bêlezzetê (m)",
    "y": " b. bêlezetî (m), bêlezetîye (m)"
  },
  {
    "x": "bêliv, -e",
    "y": " hareketsiz"
  },
  {
    "x": "bêlka",
    "y": " b. belkî"
  },
  {
    "x": "bêlkê",
    "y": " b. belkî"
  },
  {
    "x": "bêlkî",
    "y": " b. belkî"
  },
  {
    "x": "bêlu",
    "y": " b. bellî, -ye"
  },
  {
    "x": "bêma (n, m)",
    "y": " b. bêmaye (n, m)"
  },
  {
    "x": "bêma û bêpî",
    "y": " b. bêmaye û bêpî"
  },
  {
    "x": "bêmabên",
    "y": " aralıksız, ardı ardına"
  },
  {
    "x": "bêmadey (m)",
    "y": " b. bêmadîye (m)"
  },
  {
    "x": "bêmadê (m)",
    "y": " b. bêmadîye (m)"
  },
  {
    "x": "bêmadîye (m)",
    "y": " iştahsızlık"
  },
  {
    "x": "bêmana, -ye",
    "y": " 1)anlamsız, manasız \r\n~2)beyhude"
  },
  {
    "x": "bêmana bîyene",
    "y": " anlamsızlaşmak"
  },
  {
    "x": "bêmana kerdene",
    "y": " anlamsızlaştırmak"
  },
  {
    "x": "bêmanabîyayîş (n)",
    "y": " anlamsızlaşma"
  },
  {
    "x": "bêmane-I (m)",
    "y": " 1)b. bêmana (m) \r\n~2)söylemesi ayıp ..."
  },
  {
    "x": "bêmane, -ye-II",
    "y": " bahanesiz"
  },
  {
    "x": "bêmar (n, m)",
    "y": " b. bêmare (n, m)"
  },
  {
    "x": "bêmare (n, m)",
    "y": " annesiz (annesi ölmüş olan çocuk), anasız"
  },
  {
    "x": "bêmare û bêpêr, -e",
    "y": " öksüz"
  },
  {
    "x": "bêmare û bêpêrîye (m)",
    "y": " öksüzlük"
  },
  {
    "x": "bêmari (n, m)",
    "y": " b. bêmare (n, m)"
  },
  {
    "x": "bêmarîye (m)",
    "y": " annesizlik, anasızlık"
  },
  {
    "x": "bêmay (n, m)",
    "y": " b. bêmaye (n, m)"
  },
  {
    "x": "bêmaye (n, m)",
    "y": " annesiz (annesi ölmüş olan çocuk), anasız"
  },
  {
    "x": "bêmaye û bêpî, -ye",
    "y": " öksüz"
  },
  {
    "x": "bêmaye û bêpîyîye (m)",
    "y": " öksüzlük"
  },
  {
    "x": "bêmayîye (m)",
    "y": " annesizlik, anasızlık"
  },
  {
    "x": "bêmcal, -i",
    "y": " b. bêmecal, -e"
  },
  {
    "x": "bêmcaley (m)",
    "y": " b. bêmecalîye (m)"
  },
  {
    "x": "bêmcalî (m)",
    "y": " b. bêmecalî (m)"
  },
  {
    "x": "bêmecal, -e",
    "y": " 1)bitkin, halsiz, mecalsiz, takatsiz \r\n~2)zavallı"
  },
  {
    "x": "bêmecal bîyayene",
    "y": " b. bêmecal bîyene"
  },
  {
    "x": "bêmecal bîyene",
    "y": " bitkin olmak, takatsiz olmak, halsiz olmak, mecalsiz olmak"
  },
  {
    "x": "bêmecalbîyayîş (n)",
    "y": " bitkin olma, halsiz olma, mecalsiz olma, takatsiz olma"
  },
  {
    "x": "bêmecaley (m)",
    "y": " b. bêmecalîye (m)"
  },
  {
    "x": "bêmecalê (m)",
    "y": " b. bêmecalî (m)"
  },
  {
    "x": "bêmecalênî (m)",
    "y": " 1)halsizlik¸ mecalsizlik, takatsizlik¸ bitkinlik, mecalsizlik, takatsizlik \r\n~2)zavallılık"
  },
  {
    "x": "bêmecalî (m)",
    "y": " b. bêmecalîye (m)"
  },
  {
    "x": "bêmecalîye (m)",
    "y": " 1)halsizlik, mecalsizlik, takatsizlik, bitkinlik, mecalsizlik, takatsizlik \r\n~2)zavallılık"
  },
  {
    "x": "bêmecalîyene (m)",
    "y": " b. bêmecalênî (m)"
  },
  {
    "x": "bêmeharet, -e",
    "y": " beceriksiz"
  },
  {
    "x": "bêmeharetîye (m)",
    "y": " beceriksizlik"
  },
  {
    "x": "bêmerg, -e",
    "y": " ölümsüz"
  },
  {
    "x": "bêmergi (m)",
    "y": " b. bêmerge (m)"
  },
  {
    "x": "bêmerhemet, -e",
    "y": " gaddar, kıyıcı, acımasız, merhametsiz"
  },
  {
    "x": "bêmerhemetî (m)",
    "y": " gadir, acımasızlık, kıygı, merhametsizlik"
  },
  {
    "x": "bêmezal, -e",
    "y": " b. bêmecal, -e"
  },
  {
    "x": "bêmezal bîyayene",
    "y": " b. bêmecal bîyene"
  },
  {
    "x": "bêmezalbîyayîş (n)",
    "y": " b. bêmecalbîyayîş (n)"
  },
  {
    "x": "bêmezalîye (m)",
    "y": " b. bêmecalîye (m)"
  },
  {
    "x": "bêmubalexa",
    "y": " abartısız, mübalağasız"
  },
  {
    "x": "bêmudet, -e",
    "y": " süresiz"
  },
  {
    "x": "bêmuhlet, -e",
    "y": " vadesiz"
  },
  {
    "x": "bêname (n)",
    "y": " 1)adsız \r\n~2)sik, penis, kamış"
  },
  {
    "x": "bênamus, -i",
    "y": " b. bênamûs, -e"
  },
  {
    "x": "bênamusey (m)",
    "y": " b. bênamûsîye (m)"
  },
  {
    "x": "bênamûs, -e",
    "y": " namussuz"
  },
  {
    "x": "bênamûsîye (m)",
    "y": " namussuzluk"
  },
  {
    "x": "bênakokî, -ye",
    "y": " çelişkisiz, çelişmesiz"
  },
  {
    "x": "bênate (n)",
    "y": " b. beyntar (n)"
  },
  {
    "x": "bênate xerepnayene",
    "y": " b. beyntar herimnayene"
  },
  {
    "x": "bênati (n)",
    "y": " b. beyntar (n)"
  },
  {
    "x": "bênatî",
    "y": " b. beyntarî, -ye"
  },
  {
    "x": "bêneseb, -e",
    "y": " soysuz"
  },
  {
    "x": "bênesebîye (m)",
    "y": " soysuzluk"
  },
  {
    "x": "bêneynike",
    "y": " aynasız"
  },
  {
    "x": "bênimajî (m)",
    "y": " aybaşı (kadın için), hayız"
  },
  {
    "x": "bênîzam, -e",
    "y": " düzensiz, intizamsız, nizamsız"
  },
  {
    "x": "bênomus",
    "y": " b. bênamûs, -e"
  },
  {
    "x": "bêntar (n)",
    "y": " b. beyntar (n)"
  },
  {
    "x": "bêntar xirab bîyene",
    "y": " b. beyntar xirab bîyene"
  },
  {
    "x": "bêntare (n)",
    "y": " b. beyntar (n)"
  },
  {
    "x": "bêntarxirabbîyayîş (n)",
    "y": " b. beyntarxirabbîyayîş (n)"
  },
  {
    "x": "bêntirro (n)",
    "y": " b. bîntirro (n)"
  },
  {
    "x": "bênturro (n)",
    "y": " b. bîntirro (n)"
  },
  {
    "x": "bênûmis",
    "y": " b. bênamûs, -e"
  },
  {
    "x": "bêpeynî, -ye",
    "y": " ebedi, sonsuz; müebbet, sonrasız"
  },
  {
    "x": "bêpeynîyey (m)",
    "y": " b. bêpeynîyîye (m)"
  },
  {
    "x": "bêpeynîyê (m)",
    "y": " b. bêpeynîyî"
  },
  {
    "x": "bêpeynîyî (m)",
    "y": " sonrasızlık, sonsuzluk"
  },
  {
    "x": "bêpeynîyîye (m)",
    "y": " sonrasızlık, sonsuzluk"
  },
  {
    "x": "bêpêr, -e",
    "y": " babasız, yetim"
  },
  {
    "x": "bêpêrîye (m)",
    "y": " babasızlık"
  },
  {
    "x": "bêpeywendî, -ye",
    "y": " bağlantısız"
  },
  {
    "x": "bêpeywendîyîye (m)",
    "y": " bağlantısızlık"
  },
  {
    "x": "bêpî,-ye",
    "y": " babasız, yetim"
  },
  {
    "x": "bêpîyîye (m)",
    "y": " babasızlık"
  },
  {
    "x": "bêqaxû, -ye",
    "y": " endişesiz, kaygısız, tasasız"
  },
  {
    "x": "bêqayde (n)",
    "y": " kuralsız"
  },
  {
    "x": "bêqerar, -e",
    "y": " kararsız"
  },
  {
    "x": "bêqerar bîyene",
    "y": " bocalamak"
  },
  {
    "x": "bêqerar mendene",
    "y": " kararsız kalmak"
  },
  {
    "x": "bêqerarbîyayîş (n)",
    "y": " bocalama"
  },
  {
    "x": "bêqesawet, -e",
    "y": " endişesiz, kaygısız, tasasız, üzüntüsüz"
  },
  {
    "x": "bêqesawetî (m)",
    "y": " üzüntüsüzlük"
  },
  {
    "x": "bêqesawetîye (m)",
    "y": " üzüntüsüzlük"
  },
  {
    "x": "bêqewet, -e",
    "y": " güçsüz, kuvvetsiz, çelimsiz"
  },
  {
    "x": "bêqeyret, -e",
    "y": " b. bêxîret, -e"
  },
  {
    "x": "bêqeyretîye (m)",
    "y": " b. bêxîretîye (m)"
  },
  {
    "x": "bêqêret, -e",
    "y": " b. bêxîret, -e"
  },
  {
    "x": "bêqêretîye (m)",
    "y": " b. bêxîretîye (m)"
  },
  {
    "x": "bêqiloç, -e",
    "y": " b. bêqoç, -e"
  },
  {
    "x": "bêqiloçî (m)",
    "y": " b. bêqoçî (m)"
  },
  {
    "x": "bêqisawet, -e",
    "y": " b. bêqesawet, -e"
  },
  {
    "x": "bêqisawetî (m)",
    "y": " b. bêqesawetî (m)"
  },
  {
    "x": "bêqisawetîye (m)",
    "y": " b. bêqesawetîye (m)"
  },
  {
    "x": "bêqîret, -e",
    "y": " b. bêxîret, -e"
  },
  {
    "x": "bêqîretîye (m)",
    "y": " b. bêxîretîye (m)"
  },
  {
    "x": "bêqîsawet, -e",
    "y": " b. bêqesawet, -e"
  },
  {
    "x": "bêqîsawetî (m)",
    "y": " b. bêqesawetî (m)"
  },
  {
    "x": "bêqîsawetîye (m)",
    "y": " b. bêqesawetîye (m)"
  },
  {
    "x": "bêqîymet, -e",
    "y": " 1)adi, aşağılık \r\n~2)çapsız (mecazî)"
  },
  {
    "x": "bêqîymet bîyene",
    "y": " adileşmek"
  },
  {
    "x": "bêqîymet kerdene",
    "y": " adileştirmek"
  },
  {
    "x": "bêqîymetîye (m)",
    "y": " adilik"
  },
  {
    "x": "bêqoç, -e",
    "y": " boynuzsuz"
  },
  {
    "x": "bêqoçî (m)",
    "y": " boynuzsuzluk"
  },
  {
    "x": "bêqoçîye (m)",
    "y": " boynuzsuzluk"
  },
  {
    "x": "bêqonç, -e",
    "y": " b. bêqoç, -e"
  },
  {
    "x": "bêqonçî (m)",
    "y": " b. bêqoçî (m)"
  },
  {
    "x": "bêqulp, -e",
    "y": " kulpsuz"
  },
  {
    "x": "bêqulpi (m)",
    "y": " b. bêqulpe (m)"
  },
  {
    "x": "bêqusir, -i",
    "y": " b. bêqusur, -e"
  },
  {
    "x": "bêqusur, -e",
    "y": " kusursuz, ayıpsız"
  },
  {
    "x": "bêqusuri (m)",
    "y": " b. bêqusure (m)"
  },
  {
    "x": "bêqusûr, -e",
    "y": " b. bêqusure (m)"
  },
  {
    "x": "bêqutîye",
    "y": " aynasız"
  },
  {
    "x": "bêqûnî kerdene",
    "y": " b. boqanî kerdene"
  },
  {
    "x": "bêram, -e",
    "y": " b. bêrehm, -e"
  },
  {
    "x": "bêramîye(m)",
    "y": " b. bêrehmîye (m)"
  },
  {
    "x": "bêraqi (m)",
    "y": " b. beyraqe (m)"
  },
  {
    "x": "bêrbîs (n)",
    "y": " b. bermayîş (n)"
  },
  {
    "x": "Bêrecûg (n)",
    "y": " Birecik"
  },
  {
    "x": "bêrehm, -e",
    "y": " 1)gaddar, kıyıcı, acımasız, merhametsiz \r\n~2)duygusuz, hissiz; katı yürekli"
  },
  {
    "x": "bêrehmane",
    "y": " acımasızca"
  },
  {
    "x": "bêrehmey (m)",
    "y": " b. bêrehmîye (m)"
  },
  {
    "x": "bêrehmê (m)",
    "y": " b. bêrehmîye (m)"
  },
  {
    "x": "bêrehmênî (m)",
    "y": " duygusuzluk, hissizlik, katı yüreklilik"
  },
  {
    "x": "bêrehmi (m)",
    "y": " b. bêrehme (m)"
  },
  {
    "x": "bêrehmî (m)",
    "y": " 1)gadir, acımasızlık, kıygı, merhametsizlik \r\n~2)duygusuzluk, hissizlik, katı yüreklilik"
  },
  {
    "x": "bêrehmîye (m)",
    "y": " duygusuzluk, hissizlik, katı yüreklilik"
  },
  {
    "x": "bêreqabet, -e",
    "y": " çekişmesiz(çekişmeye yol açmayan)"
  },
  {
    "x": "bêrexbet, -e",
    "y": " adi, aşağılık"
  },
  {
    "x": "bêrexbet bîyene",
    "y": " adileşmek"
  },
  {
    "x": "bêrexbet kerdene",
    "y": " adileştirmek"
  },
  {
    "x": "bêrexbetîye (m)",
    "y": " adilik"
  },
  {
    "x": "bêrêz, -e",
    "y": " saygısız, hürmetsiz"
  },
  {
    "x": "bêrêzî (m)",
    "y": " saygısızlık, hürmetsizlik"
  },
  {
    "x": "bêrêzîye (m)",
    "y": " saygısızlık, hürmetsizlik"
  },
  {
    "x": "bêri (m)",
    "y": " b. bêrî (m)"
  },
  {
    "x": "bêriwen, -i",
    "y": " b. bêrûn, -e"
  },
  {
    "x": "bêrizwanik (n)",
    "y": " anasonsuz"
  },
  {
    "x": "bêrî (m)",
    "y": " 1)sağıcı (kırda sürü sağan kadın/kız) \r\n~2)sağım (kırda sürü sağımı)"
  },
  {
    "x": "bêrîpêş, -e",
    "y": " örtüsüz"
  },
  {
    "x": "bêrîvûn (m)",
    "y": " b. bêrîwane (m)"
  },
  {
    "x": "bêrîwan (m)",
    "y": " b. bêrîwane (m)"
  },
  {
    "x": "bêrîwane (m)",
    "y": " sağıcı (kırda sürü sağan kadın/kız) "
  },
  {
    "x": "bêrîwaney (m)",
    "y": " b. bêrîwanîye (m)"
  },
  {
    "x": "bêrîwani (m)",
    "y": " b. bêrîwane (m)"
  },
  {
    "x": "bêrîwanî (m)",
    "y": " sağıcılık"
  },
  {
    "x": "bêrîwanîye (m)",
    "y": " sağıcılık"
  },
  {
    "x": "bêrîwantî (m)",
    "y": " sağıcılık"
  },
  {
    "x": "bêrîwantîye (m)",
    "y": " sağıcılık"
  },
  {
    "x": "bêrîwoney (m)",
    "y": " b. bêrîwanîye (m)"
  },
  {
    "x": "bêrîwoni (m)",
    "y": " b. bêrîwane (m)"
  },
  {
    "x": "bêrîwûn (m)",
    "y": " b. bêrîwane (m)"
  },
  {
    "x": "bêrîwûnê (m)",
    "y": " b. bêrîwanî (m)"
  },
  {
    "x": "bêrîye (m)",
    "y": " 1)sağıcı (kırda sürü sağan kadın/kız) \r\n~2)sağım (kırda sürü sağımı)"
  },
  {
    "x": "bêrîyey (m)",
    "y": " b. bêrîyîye (m)"
  },
  {
    "x": "bêrîyênî (m)",
    "y": " sağıcılık"
  },
  {
    "x": "bêrîyîye (m)",
    "y": " sağıcılık"
  },
  {
    "x": "bêro-I (n)",
    "y": " b. bîro (n)"
  },
  {
    "x": "bêro-II",
    "y": " b. pêro"
  },
  {
    "x": "bêron, -e",
    "y": " b. bêrûn, -e"
  },
  {
    "x": "bêroyîn",
    "y": " b. pêrune"
  },
  {
    "x": "bêrro (n) ",
    "y": " b. bîro (n)"
  },
  {
    "x": "bêruwen, -i",
    "y": " b. bêrûn, -e"
  },
  {
    "x": "bêruxset, -e",
    "y": " ruhsatsız"
  },
  {
    "x": "bêrûçik, -e",
    "y": " çirkin"
  },
  {
    "x": "bêrûmet, -e",
    "y": " 1)adi, aşağılık \r\n~2)çapsız (mecazî)"
  },
  {
    "x": "bêrûmet bîyene",
    "y": " adileşmek"
  },
  {
    "x": "bêrûmet kerdene",
    "y": " adileştirmek"
  },
  {
    "x": "bêrûmetîye (m)",
    "y": " adilik"
  },
  {
    "x": "bêrûn, -e",
    "y": " yağsız"
  },
  {
    "x": "bêrzîn (n)",
    "y": " b. berzîn (n)"
  },
  {
    "x": "bêsabir, -e",
    "y": " b. bêsebir, -e"
  },
  {
    "x": "bêsebeb",
    "y": " nedensiz, sebepsiz"
  },
  {
    "x": "bêsebir, -e",
    "y": " sabırsız"
  },
  {
    "x": "bêsebirane",
    "y": " sabırsızca"
  },
  {
    "x": "bêsebirî (m)",
    "y": " sabırsızlık"
  },
  {
    "x": "bêsebirîye (m)",
    "y": " sabırsızlık"
  },
  {
    "x": "bêsebr, -e",
    "y": " b. bêsebir, -e"
  },
  {
    "x": "bêsebrey (m)",
    "y": " b. bêsebirîye (m)"
  },
  {
    "x": "besebrê (m)",
    "y": " b. bêsebirî (m)"
  },
  {
    "x": "bêselahîyet (n)",
    "y": " salahiyetsiz, yetkisiz"
  },
  {
    "x": "bêsera (m)",
    "y": " başıboş"
  },
  {
    "x": "bêsere, -ye",
    "y": " başıboş"
  },
  {
    "x": "bêsere geyrayene",
    "y": " b. bêsere gêrayene"
  },
  {
    "x": "bêsere gêrayene",
    "y": " başıboş dolaşmak, başıboş gezmek"
  },
  {
    "x": "bêsere veradayene",
    "y": " 1)başıboş bırakmak, başıboş gezmek \r\n~2)başıboş salmak (daha çok hayvanlar için)"
  },
  {
    "x": "bêserûber, -e",
    "y": " düzensiz, intizamsız, nizamsız"
  },
  {
    "x": "bêsevev",
    "y": " b. bêsebeb"
  },
  {
    "x": "bêsik",
    "y": " b. bêşik"
  },
  {
    "x": "bêsilma (m)",
    "y": " b. beslima (m)"
  },
  {
    "x": "bêsinet, -e",
    "y": " b. bêsunet, -e"
  },
  {
    "x": "bêsiud, -e",
    "y": " b. bêsiûd, -e"
  },
  {
    "x": "bêsiûd, -e",
    "y": " şanssız, talihsiz, bahtsız, bedbaht"
  },
  {
    "x": "bêsiûdîye (m)",
    "y": " şanssızlık, talihsizlik bahtsızlık, bedbahtlık"
  },
  {
    "x": "bêsîgorta, -ye",
    "y": " sigortasız"
  },
  {
    "x": "Bêsnî",
    "y": " Besni"
  },
  {
    "x": "bêsol, -e",
    "y": " 1)tuzsuz \r\n~2)(mecazi) tatsız (kimse), yaramaz (kimse)"
  },
  {
    "x": "bêsolê (m)",
    "y": " b. bêsolî (m) "
  },
  {
    "x": "bêsolênî (m)",
    "y": " 1)tuzsuzluk \r\n~2)(mecazi) tatsızlık, yaramazlık "
  },
  {
    "x": "bêsolî (m)",
    "y": " 1)tuzsuzluk \r\n~2)(mecazi) tatsızlık, yaramazlık "
  },
  {
    "x": "bêsolîye (m)",
    "y": " 1)tuzsuzluk \r\n~2)(mecazi) tatsızlık, yaramazlık "
  },
  {
    "x": "bêsual",
    "y": " b. bêsol, -e"
  },
  {
    "x": "bêsuel",
    "y": " b. bêsol, -e"
  },
  {
    "x": "bêsunet, -e",
    "y": " sünnetsiz"
  },
  {
    "x": "bêsiûd, -e",
    "y": " şanssız"
  },
  {
    "x": "bêsuud, -e",
    "y": " b. bêsiûd, -e"
  },
  {
    "x": "bêşalî (n, m)",
    "y": " şalvarsız"
  },
  {
    "x": "bêşalwarî (n, m)",
    "y": " b. bêşelwarî (n, m)"
  },
  {
    "x": "bêşans, -e",
    "y": " şanssız, talihsiz, bahtsız, bedbaht"
  },
  {
    "x": "bêşansîye (m)",
    "y": " şanssızlık, talihsizlik, bahtsızlık, bedbahtlık"
  },
  {
    "x": "bêşaşî, -ye",
    "y": " hatasız, yanlışsızbêşeker, -e: şekersiz"
  },
  {
    "x": "bêşekil, -e",
    "y": " biçimsiz, amorf"
  },
  {
    "x": "bêşekir, -e",
    "y": " b. bêşeker, -e"
  },
  {
    "x": "bêşelwarî (n, m)",
    "y": " şalvarsız, donsuz"
  },
  {
    "x": "bêşeref, -e",
    "y": " onursuz, şerefsiz"
  },
  {
    "x": "bêşewal",
    "y": " b. bêşelwarî (n, m)"
  },
  {
    "x": "bêşexs, -e",
    "y": " biçimsiz, çirkin"
  },
  {
    "x": "bêşige (m)",
    "y": " beşik "
  },
  {
    "x": "bêşik",
    "y": " elbet, elbette"
  },
  {
    "x": "bêşilêl, -e",
    "y": " çavdarsız"
  },
  {
    "x": "bêşitawe",
    "y": " badanasız"
  },
  {
    "x": "bêşî (m)",
    "y": " b. bîşî (m)"
  },
  {
    "x": "bêşîki (m)",
    "y": " b. bêşige (m)"
  },
  {
    "x": "bêşla (m)",
    "y": " b. beşîla (m)"
  },
  {
    "x": "bêşlî (n)",
    "y": " beşli (bir tüfek çeşidi)"
  },
  {
    "x": "bêtabat, -e",
    "y": " b. bêtebat, -e"
  },
  {
    "x": "bêtahlûke (n)",
    "y": " b. bêtehluke (n)"
  },
  {
    "x": "bêtahm, -e",
    "y": " b. bêtehm, -e"
  },
  {
    "x": "bêtale, -ye",
    "y": " b. bêtalih, -e"
  },
  {
    "x": "bêtaleh, -e",
    "y": " b. bêtalih, -e"
  },
  {
    "x": "bêtalih, -e",
    "y": " şanssız, talihsiz, bahtsız, bedbaht"
  },
  {
    "x": "bêtalihîye (m)",
    "y": " şanssızlık, talihsizlik, bahtsızlık¸ bedbahtlık"
  },
  {
    "x": "bêtalike (n)",
    "y": " b. bêtehluke (n)"
  },
  {
    "x": "bêtalî, -ye",
    "y": " b. bêtalih, -e"
  },
  {
    "x": "bêtalîke (n)",
    "y": " b. bêtehluke (n)"
  },
  {
    "x": "bêtalîyîye (m)",
    "y": " b. bêtalihîye (m)"
  },
  {
    "x": "bêtalke (n)",
    "y": " b. bêtehluke (n)"
  },
  {
    "x": "bêtalkîye (m)",
    "y": " b. bêtehluka (m), bêtehlukîye (m)"
  },
  {
    "x": "bêtaluka (m)",
    "y": " b. bêtehluka (m), bêtehlukîye (m)"
  },
  {
    "x": "bêtaluke (n)",
    "y": " b. bêtehluke (n)"
  },
  {
    "x": "bêtaluki (n)",
    "y": " b. bêtehluke (n)"
  },
  {
    "x": "bêtalukîye (m)",
    "y": " b. bêtehluka (m), bêtehlukîye (m)"
  },
  {
    "x": "bêtalûke (n)",
    "y": " b. bêtehluke (n)"
  },
  {
    "x": "bêtam, -e",
    "y": " b. bêtehm, -e"
  },
  {
    "x": "bêtamey (m)",
    "y": " b. bêtehmî (m), bêtehmîye (m)"
  },
  {
    "x": "bêtamê (m)",
    "y": " b. bêtehmî (m), bêtehmîye (m)"
  },
  {
    "x": "bêtami (m)",
    "y": " b. bêtehme (m)"
  },
  {
    "x": "bêtamî (m)",
    "y": " b. bêtehmî (m), bêtehmîye (m)"
  },
  {
    "x": "bêtamîye (m)",
    "y": " b. bêtehmî (m), bêtehmîye (m)"
  },
  {
    "x": "bêtamul, -e",
    "y": " b. bêtehemul, -e"
  },
  {
    "x": "bêtamulane",
    "y": " b. bêtehemulane"
  },
  {
    "x": "bêtamulê (m)",
    "y": " b. bêtehemulîye (m)"
  },
  {
    "x": "bêtamulî (m)",
    "y": " b. bêtehemulî (m)"
  },
  {
    "x": "bêtamulîye (m)",
    "y": " b. bêtehemulîye (m)"
  },
  {
    "x": "bêtamûtûj, -e",
    "y": " b. bêtehmûtuj, -e"
  },
  {
    "x": "bêtaqet, -e",
    "y": " bitkin, halsiz, mecalsiz, takatsiz"
  },
  {
    "x": "bêtaqet bîyayene",
    "y": " b. bêtaqet bîyene"
  },
  {
    "x": "bêtaqet bîyene",
    "y": " bitkin olmak, takatsiz olmak, halsiz olmak, mecalsiz olmak"
  },
  {
    "x": "bêtaqetbîyayîş (n)",
    "y": " bitkin olma, halsiz olma, mecalsiz olma, takatsiz olma"
  },
  {
    "x": "bêtaqetey (m)",
    "y": " b. bêtaqetîye (m)"
  },
  {
    "x": "bêtaqetê (m)",
    "y": " b. bêtaqetîye (m)"
  },
  {
    "x": "bêtaqetîye (m)",
    "y": " halsizlik, mecalsizlik, takatsizlik, bitkinlik"
  },
  {
    "x": "bêtebat, -e",
    "y": " sabırsız"
  },
  {
    "x": "bêtebatane",
    "y": " sabırsızca"
  },
  {
    "x": "bêtebatey (m)",
    "y": " b. bêtebatîye (m)"
  },
  {
    "x": "bêtebatê (m)",
    "y": " b. bêtebatî (m)"
  },
  {
    "x": "bêtebatî (m)",
    "y": " sabırsızlık"
  },
  {
    "x": "bêtebatîye (m)",
    "y": " sabırsızlık"
  },
  {
    "x": "bêtedbîr, -e",
    "y": " Tedbirsiz"
  },
  {
    "x": "bêtedbîrîye (m)",
    "y": " tedbirsizlik"
  },
  {
    "x": "bêtehemul, -e",
    "y": " tahammülsüz"
  },
  {
    "x": "bêtehemulane",
    "y": " tahammülsüzce"
  },
  {
    "x": "bêtehemuley (m)",
    "y": " b. bêtehemulîye (m)"
  },
  {
    "x": "bêtehemulî (m)",
    "y": " tahammülsüzlük"
  },
  {
    "x": "bêtehemulîye (m)",
    "y": " tahammülsüzlük"
  },
  {
    "x": "bêtehlike (n)",
    "y": " b. bêtehluke (n)"
  },
  {
    "x": "bêtehluka (m)",
    "y": " tehlikesiz"
  },
  {
    "x": "bêtehluke (n)",
    "y": " tehlikesiz"
  },
  {
    "x": "bêtehlukîye (m)",
    "y": " tehlikesiz"
  },
  {
    "x": "bêtehlûka (m)",
    "y": " tehlikesiz"
  },
  {
    "x": "bêtehlûke (n)",
    "y": " tehlikesiz"
  },
  {
    "x": "bêtehm, -e",
    "y": " tatsız, lezzetsiz"
  },
  {
    "x": "bêtehmî (m)",
    "y": " tatsızlık, lezzetsizlik"
  },
  {
    "x": "bêtehmîye (m)",
    "y": " tatsızlık, lezzetsizlik"
  },
  {
    "x": "bêtehmûtuj, -e",
    "y": " tatsız, lezzetsiz"
  },
  {
    "x": "bêtenaquz, -e",
    "y": " çelişkisiz, çelişmesiz"
  },
  {
    "x": "bêteqet, -e",
    "y": " b. bêtaqet, -e"
  },
  {
    "x": "bêteqetbîyayîş (n)",
    "y": " b. bêtaqetbîyayîş (n)"
  },
  {
    "x": "bêtere (m)",
    "y": " felaket, yıkım"
  },
  {
    "x": "bêters, -e",
    "y": " cesaretli, cesur, yürekli, gözü pek"
  },
  {
    "x": "bêters bîyene",
    "y": " cesaretlenmek, yüreklenmek"
  },
  {
    "x": "bêtersbîyayîş (n)",
    "y": " cesaretlenme, yüreklenme"
  },
  {
    "x": "bêtersey (m)",
    "y": " b. bêtersîye (m) "
  },
  {
    "x": "bêtersê (m)",
    "y": " b. bêtersîye (m)"
  },
  {
    "x": "bêtersênî (m)",
    "y": " b. bêtersîye (m)"
  },
  {
    "x": "bêtersî (m)",
    "y": " cesaretlilik, cesurluk, yüreklilik, gözü peklik"
  },
  {
    "x": "bêtersîye (m)",
    "y": " cesaretlilik, cesurluk, yüreklilik, gözü peklik"
  },
  {
    "x": "bêtevdîr, -e ",
    "y": " b. bêtedbîr, -e"
  },
  {
    "x": "bêtevger, -e",
    "y": " hareketsiz"
  },
  {
    "x": "bêtezad, -e",
    "y": " çelişkisiz, çelişmesiz"
  },
  {
    "x": "bêtêl-I (n) (nm)",
    "y": " telsiz (araç)"
  },
  {
    "x": "bêtêl, -e-II (sf)",
    "y": " telsiz"
  },
  {
    "x": "bêtiman (n, m)",
    "y": " b. bêtumanî (n, m)"
  },
  {
    "x": "bêtimanî (n, m)",
    "y": " b. bêtumanî (n, m)"
  },
  {
    "x": "bêtimonî",
    "y": " b. bêtumanî (n, m)"
  },
  {
    "x": "bêtimûn",
    "y": " b. bêtumanî (n, m)"
  },
  {
    "x": "bêtir",
    "y": " b. bitir, pitir"
  },
  {
    "x": "bêtir perê (n)",
    "y": " b. perarey (n)"
  },
  {
    "x": "bêtir pêrar",
    "y": " b. bitir pêrar"
  },
  {
    "x": "bêtom, -e",
    "y": " b. bêtehm, -e"
  },
  {
    "x": "bêtomê (m)",
    "y": " b. bêtehmî (m), bêtehmîye (m)"
  },
  {
    "x": "bêtomî (m)",
    "y": " b. bêtehmî (m), bêtehmîye (m)"
  },
  {
    "x": "bêtomîye (m)",
    "y": " b. bêtehmî (m), bêtehmîye (m)"
  },
  {
    "x": "bêtoşe (n)",
    "y": " biçimsiz"
  },
  {
    "x": "bêtumanî (n, m)",
    "y": " donsuz"
  },
  {
    "x": "bêtumayn (n, m)",
    "y": " b. bêtumanî (n, m)"
  },
  {
    "x": "bêtumûn",
    "y": " b. bêtumanî (n, m)"
  },
  {
    "x": "bêtûm, -e",
    "y": " b. bêtehm, -e"
  },
  {
    "x": "bêtûmê (m)",
    "y": " b. bêtehmî (m), bêtehmîye (m)"
  },
  {
    "x": "bêtûmî (m)",
    "y": " b. bêtehmî (m), bêtehmîye (m)"
  },
  {
    "x": "bêtûn (n)",
    "y": " b. beton (n)"
  },
  {
    "x": "bêvan",
    "y": " b. filan-bêvan"
  },
  {
    "x": "bêvav, -e",
    "y": " b. bêbav, -e"
  },
  {
    "x": "bêvenderdiş",
    "y": " b. bêvindetiş"
  },
  {
    "x": "bêvenertiş",
    "y": " b. bêvindetiş"
  },
  {
    "x": "bêveng, -e",
    "y": " sessiz"
  },
  {
    "x": "bêver, -e",
    "y": " ensiz"
  },
  {
    "x": "bêvernî, -ye",
    "y": " öncesiz, ezeli"
  },
  {
    "x": "bêvin (n)",
    "y": " b. bêbin (n)"
  },
  {
    "x": "bêvinartiş",
    "y": " b. bêvindetiş"
  },
  {
    "x": "bêvind",
    "y": " durmaksızın"
  },
  {
    "x": "bêvindar",
    "y": " b. bêvind"
  },
  {
    "x": "bêvindartiş",
    "y": " b. bêvindetiş"
  },
  {
    "x": "bêvinderdiş",
    "y": " b. bêvindetiş"
  },
  {
    "x": "bêvindertiş",
    "y": " b. bêvindetiş"
  },
  {
    "x": "bêvindetiş",
    "y": " durmaksızın"
  },
  {
    "x": "bêvinertiş",
    "y": " b. bêvindetiş"
  },
  {
    "x": "bêvinetiş",
    "y": " b. bêvindetiş"
  },
  {
    "x": "bêvirênî, -ye",
    "y": " öncesiz, ezeli"
  },
  {
    "x": "bêvîr, -e",
    "y": " unutkan"
  },
  {
    "x": "bêwa (m)",
    "y": " meret"
  },
  {
    "x": "bêwade, -ye",
    "y": " vadesiz"
  },
  {
    "x": "bêwahar, -e",
    "y": " b. bêwayîr, -e"
  },
  {
    "x": "bêwahîr, -e",
    "y": " b. bêwayîr, -e"
  },
  {
    "x": "bêwaxt, -e",
    "y": " b. bêwext"
  },
  {
    "x": "bêwayer, -i",
    "y": " b. bêwayîr, -e"
  },
  {
    "x": "bêwayêr, -i",
    "y": " b. bêwayîr, -e"
  },
  {
    "x": "bêwayir, -e",
    "y": " b. bêwayîr, -e"
  },
  {
    "x": "bêwayîr, -e",
    "y": " 1)sahipsiz \r\n~2)meret"
  },
  {
    "x": "bêwayîri (m)",
    "y": " bêwayîre (m)"
  },
  {
    "x": "bêwe (n)",
    "y": " meret"
  },
  {
    "x": "bêwehar, -e",
    "y": " b. bêwayîr, -e"
  },
  {
    "x": "bêwext, -e",
    "y": " mevsimsiz, vakitsiz"
  },
  {
    "x": "bêwextî (m)",
    "y": " vakitsizlik, zamansızlık"
  },
  {
    "x": "bêwextîye (m)",
    "y": " vakitsizlik, zamansızlık"
  },
  {
    "x": "bêweyî, -ye",
    "y": " bakımsız"
  },
  {
    "x": "bêweyîtîye (m)",
    "y": " bakımsızlık"
  },
  {
    "x": "bêweyîyîye (m)",
    "y": " bakımsızlık"
  },
  {
    "x": "bêweytî (m)",
    "y": " b. bêweyîtîye (m)"
  },
  {
    "x": "bêwin (n)",
    "y": " b. bêbin (n)"
  },
  {
    "x": "bêwin guretene",
    "y": " b. bêbin bestene"
  },
  {
    "x": "bêxaye, -ye",
    "y": " amaçsız"
  },
  {
    "x": "bêxeber, -e",
    "y": " habersiz, bihaber; gafil, aymaz"
  },
  {
    "x": "bêxeber bîyene",
    "y": " aymazlaşmak"
  },
  {
    "x": "bêxeberbîyayîş (n)",
    "y": " aymazlaşma"
  },
  {
    "x": "bêxeberey (m)",
    "y": " b. bêxeberî (m)"
  },
  {
    "x": "bêxeberê (m)",
    "y": " b. bêxeberî (m)"
  },
  {
    "x": "bêxeberi (m)",
    "y": " b. bêxebere (m)"
  },
  {
    "x": "bêxeberî (m)",
    "y": " aymazlık"
  },
  {
    "x": "bêxeberîye (m)",
    "y": " aymazlık"
  },
  {
    "x": "bêxeletî, -ye",
    "y": " hatasız, yanlışsız"
  },
  {
    "x": "bêxem, -e",
    "y": " gamsız"
  },
  {
    "x": "bêxerbay",
    "y": " b. bêxeyra"
  },
  {
    "x": "bêxeta, -ye",
    "y": " hatasız, yanlışsız"
  },
  {
    "x": "bêxeter, -e",
    "y": " tehlikesiz"
  },
  {
    "x": "bêxever, -e",
    "y": " b. bêxeber, -e"
  },
  {
    "x": "bêxewer",
    "y": " b. bêxeber, -e"
  },
  {
    "x": "bêxeyca",
    "y": " b. bêxeyja"
  },
  {
    "x": "bêxeyja",
    "y": " -den başka, hariç\r\n~Bêxeyja to kesî nênuşt. (Senden başka kimse yazmadı.)"
  },
  {
    "x": "bêxeyra",
    "y": " -den başka, hariç"
  },
  {
    "x": "bêxeyrbay",
    "y": " b. bêxeyra"
  },
  {
    "x": "bêxeyrbey",
    "y": " b. bêxeyra"
  },
  {
    "x": "bêxeyrbê",
    "y": " b. bêxeyra"
  },
  {
    "x": "bêxeyrca",
    "y": " b. bêxeyja"
  },
  {
    "x": "bêxeyret, -e",
    "y": " b. bêxîret, -e"
  },
  {
    "x": "bêxeyretey (m)",
    "y": " b. bêxîretîye (m)"
  },
  {
    "x": "bêxeyrey",
    "y": " b. bêxeyra"
  },
  {
    "x": "bêxeyrê",
    "y": " b. bêxeyra"
  },
  {
    "x": "bêxêrba",
    "y": " b. bêxeyra"
  },
  {
    "x": "bêxêrca",
    "y": " b. bêxeyja"
  },
  {
    "x": "bêxêrcey",
    "y": " b. bêxeyja"
  },
  {
    "x": "bêxirtik, -e",
    "y": " pürüzsüz"
  },
  {
    "x": "bêxîret, -e",
    "y": " gayret göstermeyen, gayretsiz "
  },
  {
    "x": "bêxîretî (m)",
    "y": " gayretsizlik"
  },
  {
    "x": "bêxîretîye (m)",
    "y": " gayretsizlik"
  },
  {
    "x": "bêxîyret, -e",
    "y": " b. bêxîret, -e"
  },
  {
    "x": "bêxîyretî (m)",
    "y": " b. bêxîretî (m)"
  },
  {
    "x": "bêyanişî, -ye",
    "y": " hatasız, yanlışsız"
  },
  {
    "x": "bêyarenî",
    "y": " ciddi"
  },
  {
    "x": "bêyarenîye",
    "y": " ciddi"
  },
  {
    "x": "bêyarî",
    "y": " ciddi"
  },
  {
    "x": "bêyîte (m)",
    "y": " b. beyte (m)"
  },
  {
    "x": "bêynate (n)",
    "y": " b. beyntar (n)"
  },
  {
    "x": "bêynatî",
    "y": " b. beyntarî, -ye"
  },
  {
    "x": "bêyntare (n)",
    "y": " b. beyntar (n)"
  },
  {
    "x": "bêynte (n)",
    "y": " b. beyntar (n)"
  },
  {
    "x": "bêz (n)",
    "y": " nefret, ikrah, tiksinme, tiksinti"
  },
  {
    "x": "bêz kerdene",
    "y": " nefret etmek, ikrah etmek, tiksinmek"
  },
  {
    "x": "bêzahmet, -e",
    "y": " b. bêzehmet, -e"
  },
  {
    "x": "bêzamet, -e",
    "y": " b. bêzehmet, -e"
  },
  {
    "x": "bêzan, -i",
    "y": " b. bêziwan, -e"
  },
  {
    "x": "bêzar, -e",
    "y": " bezgin, bizar"
  },
  {
    "x": "bêzar bîyayene",
    "y": " b. bêzar bîyene"
  },
  {
    "x": "bêzar bîyene",
    "y": " bezmek, bezginlik getirmek, bıkmak, bizar olmak, usanmak"
  },
  {
    "x": "bêzar kerdene",
    "y": " bezdirmek, bıktırmak, usandırmak"
  },
  {
    "x": "bêzarbîyayîş (n)",
    "y": " bezme, bıkma, bizar olma, usanma"
  },
  {
    "x": "bêzarey (m)",
    "y": " b. bêzarîye (m)"
  },
  {
    "x": "bêzarê (m)",
    "y": " b. bêzarîye (m)"
  },
  {
    "x": "bêzarî (m)",
    "y": " taklit"
  },
  {
    "x": "bêzarî kerdene",
    "y": " taklit etmek\r\n~Bêzarîya kesî meke! (Kimseyi taklit etme!)"
  },
  {
    "x": "bêzarîkerdiş (n)",
    "y": " taklit etme"
  },
  {
    "x": "bêzarîye (m)",
    "y": " bezginlik, bizarlık"
  },
  {
    "x": "bêzarkerdiş (n)",
    "y": " bezdirme, bıktırma, usandırma"
  },
  {
    "x": "bêzehmet, -e",
    "y": " zahmetsiz"
  },
  {
    "x": "bêzehmetî",
    "y": " çekişmesiz(çekişmeye yol açmayan)"
  },
  {
    "x": "bêzehmetîye",
    "y": " çekişmesiz (çekişmeye yol açmayan)"
  },
  {
    "x": "bêzibil, -e",
    "y": " gübresiz"
  },
  {
    "x": "bêzir (n)",
    "y": " çörek otu, çöre otu"
  },
  {
    "x": "bêzirgan, -e",
    "y": " b. bazirgan, -e"
  },
  {
    "x": "bêzirganîye (m)",
    "y": " b. bazirganîye (m)"
  },
  {
    "x": "bêzivir, -e",
    "y": " pürüzsüz"
  },
  {
    "x": "bêziwan, -e",
    "y": " dilsiz"
  },
  {
    "x": "bêzkerdiş (n)",
    "y": " nefret etme"
  },
  {
    "x": "bêznaye (n)",
    "y": " b. vîjnaye (n)"
  },
  {
    "x": "bêznayene",
    "y": " b. vîjnayene"
  },
  {
    "x": "bêznayîş (n)",
    "y": " b. vîjnayîş (n)"
  },
  {
    "x": "bêznayîye (m)",
    "y": " b. vîjnayîye (m), vîjnaya (m)"
  },
  {
    "x": "bêzon, -i",
    "y": " b. bêziwan, -e"
  },
  {
    "x": "bêzor",
    "y": " çekişmesiz(çekişmeye yol açmayan)"
  },
  {
    "x": "bêzûn",
    "y": " b. bêziwan, -e"
  },
  {
    "x": "bi-I",
    "y": " 1)ile, vasıtasıyla\r\n~Bi dest werd. (Elle yedi.)\r\n~\t\r\n~Bi qeleme nuşt. (Kalemle yazdı.)\r\n~\t\r\n~Bi pereyanê xo hêrînayo. (Parasıyla satın almış.)\r\n~\t\r\n~Bi tercumanî îfade da. (Tercüman vasıtasıyla ifade verdi.)\r\n~\t\r\n~2)-e\r\n~\t\r\n~Ez şina bi Dîyarbekir. (Diyarbakır’a gidiyorum.) \r\n~\t\r\n~Ey îman bi Homayî ardo. (O Tanrı’ya inanmış.)\r\n~3)göre, -e göre, bakımından\r\n~bi mi: bana göre, bence"
  },
  {
    "x": "bi ... a",
    "y": " b. bi ... ra"
  },
  {
    "x": "bi aîle",
    "y": " ailece, ailecek"
  },
  {
    "x": "bi alerjî, -ye",
    "y": " alerjili"
  },
  {
    "x": "bi balte birnayene",
    "y": " baltalamak"
  },
  {
    "x": "bi balte têqesnayene",
    "y": " baltalamak"
  },
  {
    "x": "bi band pîştene",
    "y": " bandajlamak"
  },
  {
    "x": "bi cilan kewtene",
    "y": " âdet görmek, (kadın) aybaşı olmak"
  },
  {
    "x": "bi çizênî (m)",
    "y": " çığlık çığlığa"
  },
  {
    "x": "bi çizîye",
    "y": " çığlık çığlığa"
  },
  {
    "x": "bi dest viraşte/a",
    "y": " manuel çalışma (elle yapılmış ürün)"
  },
  {
    "x": "bi dest xebitîyayîş",
    "y": " manuel [olarak] çalışma"
  },
  {
    "x": "bi dima",
    "y": " b. dima ra"
  },
  {
    "x": "bi dizdey",
    "y": " b. bi dizdî"
  },
  {
    "x": "bi dizdê",
    "y": " b. bi dizdî"
  },
  {
    "x": "bi dizdî",
    "y": " gizlice"
  },
  {
    "x": "bi dore",
    "y": " nöbetleşe\r\n~“Dinya bi dor a, malêza çorî ya.”"
  },
  {
    "x": "bi dore kerdene",
    "y": " nöbetleşe yapmak"
  },
  {
    "x": "bi dori",
    "y": " b. bi dore"
  },
  {
    "x": "bi ecele",
    "y": " acele ile"
  },
  {
    "x": "bi ecele kerdene",
    "y": " aceleye gelmek"
  },
  {
    "x": "bi ecele viraştene",
    "y": " aceleye gelmek"
  },
  {
    "x": "bi erşin peymitene",
    "y": " arşınlamak"
  },
  {
    "x": "bi gayî bîyayîne",
    "y": " b. bi gayî bîyene"
  },
  {
    "x": "bi gayî bîyene",
    "y": " kösnümek (inek için)"
  },
  {
    "x": "bi gude kutene",
    "y": " balyozlamak"
  },
  {
    "x": "bi gude kuwayene",
    "y": " balyozlamak"
  },
  {
    "x": "bi hak bîyene",
    "y": " tavuk vb. kümes hayvanının yumurtlayacak durumda olması"
  },
  {
    "x": "bi kel bîyene",
    "y": " kösnümek (keçi için)"
  },
  {
    "x": "bi kilmî (m)",
    "y": " özetle"
  },
  {
    "x": "bi keye û kulfet",
    "y": " ailece, ailecek"
  },
  {
    "x": "bi kom û kulfet",
    "y": " ailece, ailecek"
  },
  {
    "x": "bi lezabez kerdene",
    "y": " aceleye gelmek"
  },
  {
    "x": "bi lezabez viraştene",
    "y": " aceleye gelmek"
  },
  {
    "x": "bi lingan",
    "y": " 1)yayan \r\n~2)yürüyerek"
  },
  {
    "x": "bi lingî",
    "y": " 1)yayan \r\n~2)yürüyerek"
  },
  {
    "x": "bi lingon",
    "y": " b. bi lingan"
  },
  {
    "x": "bi lingûn",
    "y": " b. bi lingan"
  },
  {
    "x": "bi mulxutê çêyî",
    "y": " b. bi mulxutê keyeyî"
  },
  {
    "x": "bi mulxutê keyeyî",
    "y": " ailece, ailecek"
  },
  {
    "x": "bi name bê Ellay",
    "y": " b. bi nameyê Ellahî"
  },
  {
    "x": "bi name bê Hûmayî",
    "y": " b. bi nameyê Homayî"
  },
  {
    "x": "bi name û vengan, -e",
    "y": " tanınmış, ünlü"
  },
  {
    "x": "bi nameyê Ellahî",
    "y": " vallahi"
  },
  {
    "x": "bi nameyê Haqî",
    "y": " b. bi nameyê Heqî"
  },
  {
    "x": "bi nameyê Heqî",
    "y": " vallahi"
  },
  {
    "x": "bi nameyê Homayî",
    "y": " vallahi"
  },
  {
    "x": "bi namê Haqî",
    "y": " b. bi nameyê Heqî"
  },
  {
    "x": "bi namê Heqî",
    "y": " b. bi nameyê Heqî"
  },
  {
    "x": "bi newbe",
    "y": " b. bi nobe"
  },
  {
    "x": "bi newbi",
    "y": " b. bi nobe"
  },
  {
    "x": "bi nê qaydeyî",
    "y": " böylece, böylecene¸böylelikle"
  },
  {
    "x": "bi nobe",
    "y": " nöbetleşe"
  },
  {
    "x": "bi nobe kerdene",
    "y": " nöbetleşe yapmak"
  },
  {
    "x": "bi nobete",
    "y": " nöbetleşe"
  },
  {
    "x": "bi nobete kerdene",
    "y": " nöbetleşe yapmak"
  },
  {
    "x": "bi nomey Ellay",
    "y": " b. bi nameyê Ellahî"
  },
  {
    "x": "bi nomey Hûmay",
    "y": " b. bi nameyê Homayî"
  },
  {
    "x": "bi nûmê Allay",
    "y": " b. bi nameyê Ellahî"
  },
  {
    "x": "bi nûmê Homay",
    "y": " b. bi nameyê Homayî"
  },
  {
    "x": "bi paçik pîştene",
    "y": " bandajlamak"
  },
  {
    "x": "bi pay",
    "y": " 1)yayan \r\n~2)yürüyerek"
  },
  {
    "x": "bi payatî",
    "y": " b. bi peyatî"
  },
  {
    "x": "bi pereyan",
    "y": " bedelli"
  },
  {
    "x": "bi peyatî",
    "y": " 1)yayan \r\n~2)yürüyerek"
  },
  {
    "x": "bi peyetî",
    "y": " 1)yayan \r\n~2)yürüyerek"
  },
  {
    "x": "bi peyeyey",
    "y": " b. bi peyeyî"
  },
  {
    "x": "bi peyeyê",
    "y": " b. bi peyeyî"
  },
  {
    "x": "bi peyeyî",
    "y": " 1)yayan \r\n~2)yürüyerek"
  },
  {
    "x": "bi pinanî",
    "y": " gizlice"
  },
  {
    "x": "bi qapan sentene",
    "y": " kantarlamak, kantara vurmak, kantara çekmek"
  },
  {
    "x": "bi qebale gure kerdene",
    "y": " götürü usülü iş yapmak"
  },
  {
    "x": "bi qebale kar kerdene",
    "y": " götürü usülü iş yapmak"
  },
  {
    "x": "bi qebale xebetîyayene",
    "y": " b. bi qebale xebitîyayene"
  },
  {
    "x": "bi qebale xebitîyayene",
    "y": " götürü usülü iş yapmak"
  },
  {
    "x": "bi qey",
    "y": " b. qandê"
  },
  {
    "x": "bi qey ku",
    "y": " b. qando ke"
  },
  {
    "x": "bi qîjîye",
    "y": " çığlık çığlığa"
  },
  {
    "x": "bi qîrênî",
    "y": " çığlık çığlığa"
  },
  {
    "x": "bi qîrî û wîrî",
    "y": " çığlık çığlığa"
  },
  {
    "x": "bi qîrîye",
    "y": " çığlık çığlığa"
  },
  {
    "x": "bi ... ra",
    "y": " ile, vasıtasıyla, birlikte, beraber, beraberinde\r\n~Mêrdek bi yew cenî ra ameyo. (Adam bir kadınla gelmiş.)"
  },
  {
    "x": "bi rewte çingdayîş",
    "y": " sırıkla atlama"
  },
  {
    "x": "bi rewte perayîş",
    "y": " sırıkla atlama"
  },
  {
    "x": "bi rewte xoeştiş",
    "y": " sırıkla atlama"
  },
  {
    "x": "bi sebir",
    "y": " sabırla"
  },
  {
    "x": "bi sebr",
    "y": " b. bi sebir"
  },
  {
    "x": "bi seserran",
    "y": " asırlar boyunca, asırlarca, yüzyıllarca, yüzyıllar boyunca"
  },
  {
    "x": "bi sira",
    "y": " nöbetleşe, sırayla"
  },
  {
    "x": "bi şeş",
    "y": " altılı"
  },
  {
    "x": "bi şîyene",
    "y": " yürüyerek"
  },
  {
    "x": "bi tebat",
    "y": " sabırla"
  },
  {
    "x": "bi telefon muracat (n)",
    "y": " telefonla başvuru"
  },
  {
    "x": "bi tena sereyê xo",
    "y": " yapayalnız"
  },
  {
    "x": "bi texmîn",
    "y": " tahmin ile"
  },
  {
    "x": "bi tore birnayene",
    "y": " baltalamak"
  },
  {
    "x": "bi tore têqesnayene",
    "y": " baltalamak"
  },
  {
    "x": "bi tuwerzîn birnayene",
    "y": " baltalamak"
  },
  {
    "x": "bi tuwerzîn têqesnayene",
    "y": " baltalamak"
  },
  {
    "x": "bi vargozî kutene",
    "y": " balyozlamak"
  },
  {
    "x": "bi vargozî kuwayene",
    "y": " balyozlamak"
  },
  {
    "x": "bi ... wa",
    "y": " ile, vasıtasıyla, birlikte, beraber, beraberinde"
  },
  {
    "x": "bi waqînî",
    "y": " çığlık çığlığa"
  },
  {
    "x": "bi waqîye",
    "y": " çığlık çığlığa"
  },
  {
    "x": "Bi xatir’ to.",
    "y": " b. Bi xatirê to."
  },
  {
    "x": "Bi xatirê to.",
    "y": " Allah’a ısmarladık"
  },
  {
    "x": "bi xayîney",
    "y": " b. bi xayînî"
  },
  {
    "x": "bi xayînî",
    "y": " hainlikle, hıyanetle, ihanetle"
  },
  {
    "x": "bi xayînîye",
    "y": " hainlikle, hıyanetle, ihanetle"
  },
  {
    "x": "bi xîyanet",
    "y": " hainlikle, hıyanetle, ihanetle"
  },
  {
    "x": "bi xo (zr)",
    "y": " bizzat, resen (kimseye bağlı olmaksızın)"
  },
  {
    "x": "bi xo ra dayene",
    "y": " b. xo ra dayene"
  },
  {
    "x": "bi ... ya",
    "y": " b. bi ... wa\r\n~Mêrdek di xizmekaran zî bi kênaya xo ya şaweno. (Adam iki hizmetçiyi de kızı ile gönderir.)"
  },
  {
    "x": "bi zeyt, -e",
    "y": " zeytinyağlı"
  },
  {
    "x": "bi zirçayîş",
    "y": " çığlık çığlığa  "
  },
  {
    "x": "bi zirçîye",
    "y": " çığlık çığlığa"
  },
  {
    "x": "bi zompe kutene",
    "y": " balyozlamak"
  },
  {
    "x": "bi zompe kuwayene",
    "y": " balyozlamak"
  },
  {
    "x": "bi zorê ... şîyene",
    "y": " ağırına gitmek\r\n~Na qisaya to bi zorê mi şîye. (Bu sözün ağırıma gitti.)\r\n~\t"
  },
  {
    "x": "bi ... ewnîyayene",
    "y": " -e bakmak"
  },
  {
    "x": "bi mi",
    "y": " bana göre, bence"
  },
  {
    "x": "bi-II (n)",
    "y": " b. bin (n)"
  },
  {
    "x": "bi ra",
    "y": " b. bin ra"
  },
  {
    "x": "bi ra nêbo",
    "y": " b. bin ra nêbo"
  },
  {
    "x": "biadir (n)",
    "y": " ateşli (ateşi olan)"
  },
  {
    "x": "biaheng, -e",
    "y": " ahenkli, uyumlu"
  },
  {
    "x": "bialerjî, -ye",
    "y": " alerjili"
  },
  {
    "x": "bialternatîf, -e",
    "y": " alternatifli"
  },
  {
    "x": "biambalaj, -e",
    "y": " ambalajlı"
  },
  {
    "x": "bianalîz, -e",
    "y": " analitik, çözümlemeli"
  },
  {
    "x": "biantrenman, -e",
    "y": " antrenmanlı"
  },
  {
    "x": "biaqil, -e",
    "y": " 1)akıllı, akil, aklı başında \r\n~2)çokbilmiş"
  },
  {
    "x": "biaqilane",
    "y": " akıllıca, akilane"
  },
  {
    "x": "biaqilîye (m)",
    "y": " akıllılık"
  },
  {
    "x": "biariza, -ye",
    "y": " b. biarize, -ye"
  },
  {
    "x": "biarize, -ye",
    "y": " arızalı, bozuk"
  },
  {
    "x": "biarizeyî (m)",
    "y": " bozukluk"
  },
  {
    "x": "biarizeyîye (m)",
    "y": " bozukluk"
  },
  {
    "x": "biaroma, -ye",
    "y": " aromalı, aromatik"
  },
  {
    "x": "biarza, -ye",
    "y": " b. biarize, -ye"
  },
  {
    "x": "biawdest, -e",
    "y": " abdestli, aptesli"
  },
  {
    "x": "biawe",
    "y": " sulu"
  },
  {
    "x": "bibaltebirnayîş (n)",
    "y": " baltalama"
  },
  {
    "x": "bibaltetêqesnayîş (n)",
    "y": " baltalama"
  },
  {
    "x": "bibandpîştiş (n)",
    "y": " bandajlama"
  },
  {
    "x": "bibar, -e",
    "y": " yüklü"
  },
  {
    "x": "bibare (m)",
    "y": " gebe¸ iki canlı, hamile"
  },
  {
    "x": "bibare bîyene",
    "y": " gebe olmak, iki canlı olmak, hamile olmak"
  },
  {
    "x": "bibareyîye (m)",
    "y": " gebelik, hamilelik"
  },
  {
    "x": "bibari (m) ",
    "y": " b. bibare (m)"
  },
  {
    "x": "bibari bîyayene",
    "y": " b. bibare bîyene"
  },
  {
    "x": "bibarî (m)",
    "y": " b. bibareyîye (m)"
  },
  {
    "x": "bibarîye (m)",
    "y": " b. bibareyîye (m)"
  },
  {
    "x": "bibasarax, -e",
    "y": " basiretli, uzak görüşlü, sağgörülü"
  },
  {
    "x": "bibasaraxîye (m)",
    "y": " sağgörülülük, uzak görüşlülük, basiretlilik"
  },
  {
    "x": "bibasarox, -e",
    "y": " b. bibasarax, -e"
  },
  {
    "x": "bibasaroxîye (m)",
    "y": " b. bibasaraxîye (m)"
  },
  {
    "x": "bibasirax, -e",
    "y": " b. bibasarax, -e"
  },
  {
    "x": "bibasiraxîye (m)",
    "y": " b. bibasaraxîye (m)"
  },
  {
    "x": "bibasîret, -e",
    "y": " b. bibesîret, -e"
  },
  {
    "x": "bibasîretîye (m)",
    "y": " b. bibesîretîye (m)"
  },
  {
    "x": "bibazarî",
    "y": " pazarlıklı"
  },
  {
    "x": "bibedêl, -e",
    "y": " bedelli"
  },
  {
    "x": "leşkerîya bibedêle",
    "y": " bedelli askerlik"
  },
  {
    "x": "bibejn",
    "y": " b. bibejne"
  },
  {
    "x": "bibejne",
    "y": " boylu"
  },
  {
    "x": "bibejni",
    "y": " b. bibejne"
  },
  {
    "x": "bibejnûbale",
    "y": " boylu boslu, boylu poslu, endamlı"
  },
  {
    "x": "bibejnûbali",
    "y": " b. bibejnûbale"
  },
  {
    "x": "bibelge",
    "y": " vesikalı"
  },
  {
    "x": "biberane (m)",
    "y": " kösnük (koyun için)"
  },
  {
    "x": "biberketey (m)",
    "y": " b. bibereketîye (m)"
  },
  {
    "x": "biberketê (m)",
    "y": " b. bibereketîye (m)"
  },
  {
    "x": "bibereket, -e",
    "y": " bereketli"
  },
  {
    "x": "bibereketîye (m)",
    "y": " bereketlilik"
  },
  {
    "x": "bibesîret, -e",
    "y": " basiretli, uzak görüşlü, sağgörülü"
  },
  {
    "x": "bibesîretîye (m)",
    "y": " sağgörülülük, uzak görüşlülük, basiretlilik"
  },
  {
    "x": "bibeşni",
    "y": " b. bibejne"
  },
  {
    "x": "bibeşnûbali",
    "y": " b. bibejnûbale"
  },
  {
    "x": "bibexişne",
    "y": " affedersin"
  },
  {
    "x": "bibexişnîn",
    "y": " affedersiniz, af buyurun"
  },
  {
    "x": "bibext, -e",
    "y": " bahtlı, şanslı, talihli"
  },
  {
    "x": "bibezne",
    "y": " b. bibejne"
  },
  {
    "x": "bibilîyayene",
    "y": " b. pilpilîyayene"
  },
  {
    "x": "bibilîyayîş (n)",
    "y": " b. pilpilîyayîş (n)"
  },
  {
    "x": "bibo nêbo",
    "y": " hiç olmazsa, hiç değilse"
  },
  {
    "x": "bibowax, -e",
    "y": " b. biboyax, -e"
  },
  {
    "x": "biboyax, -e",
    "y": " boyalı"
  },
  {
    "x": "bibuhran, -e",
    "y": " bunalımlı"
  },
  {
    "x": "bicesaret, -e",
    "y": " cesaretli, cesur, yürekli"
  },
  {
    "x": "bicesaret bîyene",
    "y": " cesaretlenmek"
  },
  {
    "x": "bicesaretbîyayîş (n)",
    "y": " cesaretlenme"
  },
  {
    "x": "bicesaretî (m)",
    "y": " cesaretlilik, cesurluk, yüreklilik"
  },
  {
    "x": "bicike (m)",
    "y": " b. bijike (m)"
  },
  {
    "x": "bicika toraqî",
    "y": " b. bijika toraqî "
  },
  {
    "x": "bicilankewtiş (n)",
    "y": " âdet görme, (kadın) aybaşı olma"
  },
  {
    "x": "bicins, -e",
    "y": " asil, soylu"
  },
  {
    "x": "biciwî",
    "y": " yaşa, bravo"
  },
  {
    "x": "biciwîyo",
    "y": " yaşasın\r\n~Biciwîyo xoserîye!/Biciwîyo xoserî! (Yaşasın bağımsızlık!)\r\n~\t\r\n~Wa biciwîyo xoserîye!/Wa biciwîyo xoserî! (Yaşasın bağımsızlık!)"
  },
  {
    "x": "bicoş, -e",
    "y": " ateşli (coşkulu)"
  },
  {
    "x": "biçalim, -e",
    "y": " afili"
  },
  {
    "x": "biçetal, -e",
    "y": " çatal (ucu kollara ayrılmış)"
  },
  {
    "x": "biçetinî",
    "y": " çekişmeli (çekişmeye yol açan)"
  },
  {
    "x": "biçixe (n)",
    "y": " b. biçxe (n)"
  },
  {
    "x": "biçixî (n)",
    "y": " b. biçxe (n)"
  },
  {
    "x": "biçxe (n)",
    "y": " bıçkı (büyük testere)"
  },
  {
    "x": "bidadî",
    "y": " analı"
  },
  {
    "x": "bidare",
    "y": " ağaçlı"
  },
  {
    "x": "bide",
    "y": " b. ci"
  },
  {
    "x": "bide ameyene",
    "y": " yakışmak, uygun düşmek, uygun gelmek, uygun olmak, uymak, yakışmak, yaraşmak"
  },
  {
    "x": "bide aqilîyayene",
    "y": " b. bide arqilîyayene"
  },
  {
    "x": "bide aqilnayene",
    "y": " b. bide arqilnayene"
  },
  {
    "x": "bide arqilîyayene",
    "y": " farkına varmak"
  },
  {
    "x": "bide arqilnayene",
    "y": " farkına vardırmak"
  },
  {
    "x": "bide geyrayene",
    "y": " b. cigêrayene"
  },
  {
    "x": "bide ginayene",
    "y": " 1)b. ciginayene \r\n~2)dokunmak (yiyecek vb.)"
  },
  {
    "x": "bide hesîyayene",
    "y": " farkına varmak"
  },
  {
    "x": "bide hesnayene",
    "y": " farkına vardırmak"
  },
  {
    "x": "bide mendene",
    "y": " -e benzemek"
  },
  {
    "x": "bide omeyîne",
    "y": " b. ... ameyene"
  },
  {
    "x": "bide sawitene",
    "y": " b. desawitene"
  },
  {
    "x": "bide sawîyayene",
    "y": " b. desawîyayene"
  },
  {
    "x": "bide şîyene",
    "y": " -e benzemek"
  },
  {
    "x": "bide weniştene",
    "y": " -e binmek"
  },
  {
    "x": "bide wenîştene",
    "y": " b. bide weniştene"
  },
  {
    "x": "bideameya (m)",
    "y": " muvafık, münasip, uygun, yaraşır"
  },
  {
    "x": "bideameye (n)",
    "y": " muvafık, münasip, uygun, yaraşır"
  },
  {
    "x": "bideameyeyî (m)",
    "y": " uygunluk "
  },
  {
    "x": "bideameyîş (n)",
    "y": " uygun olma, uyma, yakışma"
  },
  {
    "x": "bideaqilîyayîş (n)",
    "y": " b. bidearqilîyayîş (n)"
  },
  {
    "x": "bideaqilnayîş (n)",
    "y": " b. bidearqilnayîş (n)"
  },
  {
    "x": "bidearqilîyayîş (n)",
    "y": " farkına varma"
  },
  {
    "x": "bidearqilnayîş (n)",
    "y": " farkına vardırma"
  },
  {
    "x": "bidegeyrayîş (n)",
    "y": " b. cigêrayîş (n)"
  },
  {
    "x": "bideginayîş (n)",
    "y": " dokunma (yiyecek vb.)"
  },
  {
    "x": "bidehesîyayîş (n)",
    "y": " farkına varma"
  },
  {
    "x": "bidehesnayîş (n)",
    "y": " farkına vardırma"
  },
  {
    "x": "bidemenda (m)",
    "y": " benzer"
  },
  {
    "x": "bidemende (n)",
    "y": " benzer"
  },
  {
    "x": "bideomeyîş (n)",
    "y": " b. ... ameyîş"
  },
  {
    "x": "bidepresyon, -e",
    "y": " bunalımlı (psikolojide)"
  },
  {
    "x": "bider",
    "y": " b. ci"
  },
  {
    "x": "bider aqilîyayene",
    "y": " b. bide arqilîyayene"
  },
  {
    "x": "bider aqilnayene",
    "y": " b. bide arqilnayene"
  },
  {
    "x": "bider ginayene",
    "y": " b. bide ginayene"
  },
  {
    "x": "bider şanayîne",
    "y": " b. ci şanayene"
  },
  {
    "x": "bider wenîştene",
    "y": " b. bede weniştene"
  },
  {
    "x": "bideraqilîyayîş (n)",
    "y": " b. bidearqilîyayîş (n)"
  },
  {
    "x": "bideraqilnayîş (n)",
    "y": " b. bidearqilnayîş (n)"
  },
  {
    "x": "biderginayîş (n)",
    "y": " b. bideginayîş (n)"
  },
  {
    "x": "biderşanayîş (n)",
    "y": " b. cişanayîş (n)"
  },
  {
    "x": "biderwenîştiş (n)",
    "y": " b. bedeweniştiş (n)"
  },
  {
    "x": "bidesawita (m)",
    "y": " b. desawita (m)"
  },
  {
    "x": "bidesawite (n)",
    "y": " b. desawite (n)"
  },
  {
    "x": "bidest (kerde) (n) (TE)",
    "y": " el ile (yapılan), manuel"
  },
  {
    "x": "bidestê ... ",
    "y": " ... eliyle"
  },
  {
    "x": "bidestmaj, -e",
    "y": " abdestli, aptesli"
  },
  {
    "x": "bidetay, -e",
    "y": " ayrıntılı"
  },
  {
    "x": "bidewenîştiş (n)",
    "y": " b. bedeweniştiş (n)"
  },
  {
    "x": "Bidlîs (n)",
    "y": " Bitlis"
  },
  {
    "x": "bidokuman",
    "y": " vesikalı"
  },
  {
    "x": "biedalet, -e",
    "y": " adaletli, adil"
  },
  {
    "x": "biedaletîye (m)",
    "y": " adaletlilik, adillik"
  },
  {
    "x": "biehemîyet, -e",
    "y": " önemli"
  },
  {
    "x": "biehlaq, -e",
    "y": " b. biexlaq, -e"
  },
  {
    "x": "biendîşe, -ye",
    "y": " 1)endişeli, kaygılı, tasalı \r\n~2)telaşlı"
  },
  {
    "x": "bierşinpeymitiş (n)",
    "y": " arşınlama"
  },
  {
    "x": "biesl, -e",
    "y": " asil, soylu"
  },
  {
    "x": "bieslûfesl, -e",
    "y": " soylu"
  },
  {
    "x": "bieslûfeslîye (m)",
    "y": " soyluluk"
  },
  {
    "x": "bietap, -e",
    "y": " aşamalı"
  },
  {
    "x": "biexlaq, -e",
    "y": " ahlaklı"
  },
  {
    "x": "bieyar, -e",
    "y": " ayarlı"
  },
  {
    "x": "bieyb, -e",
    "y": " ayıplı"
  },
  {
    "x": "bieyne",
    "y": " aynalı"
  },
  {
    "x": "bifam, -e",
    "y": " b. bifehm, -e"
  },
  {
    "x": "bifasal (n, m)",
    "y": " b. bifesale (n, m)"
  },
  {
    "x": "bifehm, -e",
    "y": " anlayışlı"
  },
  {
    "x": "biferaset, -e",
    "y": " anlayışlı"
  },
  {
    "x": "biferq, -e",
    "y": " değişik, farklı, ayrımlı"
  },
  {
    "x": "biferqîye (m)",
    "y": " ayrımlılık, başkalık, farklılık"
  },
  {
    "x": "bifesale (n, m)",
    "y": " ahenkli, uyumlu, ahenktar"
  },
  {
    "x": "bifesali (n, m)",
    "y": " b. bifesale (n, m)"
  },
  {
    "x": "bifêm, -e",
    "y": " b. bifehm, -e"
  },
  {
    "x": "bifikar, -e",
    "y": " 1)endişeli, kaygılı, tasalı \r\n~2)telaşlı"
  },
  {
    "x": "bifîyaqe, -ye",
    "y": " afili"
  },
  {
    "x": "bifors, -e",
    "y": " afili"
  },
  {
    "x": "bigabîyayîş (n)",
    "y": " b. bigayîbîyayîş (n)"
  },
  {
    "x": "bigaye (m)",
    "y": " kösnük (inek için)"
  },
  {
    "x": "bigayîbîyayîş (n)",
    "y": " kösnüme (inek için)"
  },
  {
    "x": "bigudekutiş (n)",
    "y": " balyozlama"
  },
  {
    "x": "bigudekuwayîş (n)",
    "y": " balyozlama"
  },
  {
    "x": "bih bi ... bîyene",
    "y": " -e güvenilmek"
  },
  {
    "x": "bih bi ... kerdene",
    "y": " -e güvenmek"
  },
  {
    "x": "bih ... bîyayene",
    "y": " b. bih bi ... bîyene"
  },
  {
    "x": "bih ... bîyene",
    "y": " b. bih bi ... bîyene"
  },
  {
    "x": "bih ... kerdene",
    "y": " b. bih bi ... kerdene"
  },
  {
    "x": "bihale (m)",
    "y": " gebe, iki canlı, hamile"
  },
  {
    "x": "bihale bîyene",
    "y": " gebe olmak, hamile olmak, iki canlı olmak"
  },
  {
    "x": "bihalê (m) ",
    "y": " bihalîye (m)"
  },
  {
    "x": "bihali (m) ",
    "y": " b. bihale (m)"
  },
  {
    "x": "bihali bîyayene",
    "y": " b. bihale bîyene"
  },
  {
    "x": "bihalî (m)",
    "y": " b. bihalîye (m)"
  },
  {
    "x": "bihalîye (m)",
    "y": " gebelik¸ hamilelik"
  },
  {
    "x": "biharat (n)",
    "y": " b. beharat (n)"
  },
  {
    "x": "bihemberî, -ye",
    "y": " çekişmeli (çekişmeye yol açan)"
  },
  {
    "x": "biheml (m)",
    "y": " b. bihemle (m)"
  },
  {
    "x": "biheml bîyayene",
    "y": " b. bihemle bîyene"
  },
  {
    "x": "bihemle (m)",
    "y": " gebe¸ iki canlı, hamile"
  },
  {
    "x": "bihemle bîyene",
    "y": " gebe olmak, hamile olmak, iki canlı olmak"
  },
  {
    "x": "bihemleyîye (m)",
    "y": " gebelik¸ hamilelik"
  },
  {
    "x": "bihemlê (m)",
    "y": " b. bihemleyîye (m)"
  },
  {
    "x": "bihemli (m)",
    "y": " b. bihemle (m)"
  },
  {
    "x": "bihemli bîyayene",
    "y": " b. bihemle bîyene"
  },
  {
    "x": "bihemlî (m)",
    "y": " b. bihemleyîye (m)"
  },
  {
    "x": "bihereket, -e",
    "y": " hareketli"
  },
  {
    "x": "bihîr, -e",
    "y": " bereketli"
  },
  {
    "x": "bihîrîye (m)",
    "y": " bereketlilik"
  },
  {
    "x": "bihîse, -ye",
    "y": " hisseli, paydaşlı"
  },
  {
    "x": "bihvêre (m)",
    "y": " b. vamêre (m)"
  },
  {
    "x": "biîmtîyaz, -e",
    "y": " ayrıcalıklı, imtiyazlı"
  },
  {
    "x": "biîrtîbat, -e",
    "y": " bağlantılı"
  },
  {
    "x": "biîskelet,-e",
    "y": " iskeletli"
  },
  {
    "x": "biîzah, -e",
    "y": " açıklamalı, izahlı"
  },
  {
    "x": "biîzahat (n)",
    "y": " açıklamalı, izahlı"
  },
  {
    "x": "biîzahkerdiş (n)",
    "y": " açıklamalı, izahlı"
  },
  {
    "x": "bijang (n)",
    "y": " b. bijangî (zh)"
  },
  {
    "x": "bijangi (m)",
    "y": " b. bijangî (zh)"
  },
  {
    "x": "bijangî (zh)",
    "y": " kirpik"
  },
  {
    "x": "bije (n)",
    "y": " b. bijangî (zh)"
  },
  {
    "x": "bijek, -e",
    "y": " b. bizêk, -e"
  },
  {
    "x": "bijek ardene",
    "y": " b. bizêk/bizêke ardene"
  },
  {
    "x": "bijeka helîye (m)",
    "y": " b. bizêka helîye (m) "
  },
  {
    "x": "bijeko helî (n)",
    "y": " b. bizêko helî (n)"
  },
  {
    "x": "bijêk, -e",
    "y": " b. bizêk, -e"
  },
  {
    "x": "bijêk ardene",
    "y": " b. bizêk/bizêke ardene"
  },
  {
    "x": "bijêk estene",
    "y": " b. bizêk eştene"
  },
  {
    "x": "bijêrekî (zh)",
    "y": " içyağının kızartılmasıyla meydana gelen bir yiyecek"
  },
  {
    "x": "bijike (m)",
    "y": " kömbe (küle gömülerek pişirilen mayasız çörek)\r\n~ bir çeşit gözleme"
  },
  {
    "x": "bijika toraqî",
    "y": " bir çeşit gözleme"
  },
  {
    "x": "bijî (m)",
    "y": " yele"
  },
  {
    "x": "bikal (n)",
    "y": " b. bawkal (n)"
  },
  {
    "x": "bikaşkole",
    "y": " atkılı"
  },
  {
    "x": "bikayûkelebut",
    "y": " cümbüşlü"
  },
  {
    "x": "bikelbîyayîş (n)",
    "y": " kösnüme (keçi için)"
  },
  {
    "x": "bikeyf",
    "y": " keyifle"
  },
  {
    "x": "bikeyfûşayî",
    "y": " cümbüşlü"
  },
  {
    "x": "bikês, -e",
    "y": " iyi\r\n~Ez bikês o./Ez kês a. (Ben iyiyim.)"
  },
  {
    "x": "bikile (n, m)",
    "y": " alevli"
  },
  {
    "x": "biko",
    "y": " oğul (hitap hali)"
  },
  {
    "x": "bikok, -e",
    "y": " ayarlı"
  },
  {
    "x": "bikoordînasyon, -e",
    "y": " eşgüdümlü"
  },
  {
    "x": "bikoordîne",
    "y": " koordineli"
  },
  {
    "x": "bikrîz, -e",
    "y": " bunalımlı"
  },
  {
    "x": "bila bîyene",
    "y": " dökülmek"
  },
  {
    "x": "bila kerdene",
    "y": " dökmek"
  },
  {
    "x": "bilabîyaye (n)",
    "y": " döküntü"
  },
  {
    "x": "bilabîyayeyê petrolî (n)",
    "y": " petrol döküntüsü"
  },
  {
    "x": "bilabîyayîş (n)",
    "y": " dökülme"
  },
  {
    "x": "bilakerdiş (n)",
    "y": " dökme"
  },
  {
    "x": "bilan (n)",
    "y": " fol"
  },
  {
    "x": "bilati (n)",
    "y": " b. belate (n)"
  },
  {
    "x": "bilbançikî (zh)",
    "y": " bademcik (bademcikler)"
  },
  {
    "x": "bilbil (n)",
    "y": " bülbül"
  },
  {
    "x": "bilbil kerdene",
    "y": " homurdanmak"
  },
  {
    "x": "bilbilîyayene",
    "y": " homurdanmak"
  },
  {
    "x": "bilçeng (n)",
    "y": " b. binçeng (n)"
  },
  {
    "x": "bile ... bîyayene",
    "y": " b. bilî ... bîyene"
  },
  {
    "x": "bilebat, -e",
    "y": " hareketli"
  },
  {
    "x": "bilezik (n)",
    "y": " b. bîlazik (n)"
  },
  {
    "x": "bilezikin, -e",
    "y": " b. bîlazikin, -e"
  },
  {
    "x": "bilêt (n)",
    "y": " b. bîlet (n)"
  },
  {
    "x": "bilik (n)",
    "y": " çük (çocuk dilinde)"
  },
  {
    "x": "bilindiri (m)",
    "y": " b. berendîre (m)"
  },
  {
    "x": "bilisk (n)",
    "y": " b. bilusk (n)"
  },
  {
    "x": "biliv, -e",
    "y": " hareketli"
  },
  {
    "x": "bilî ... bîyayene",
    "y": " b. bilî ... bîyene"
  },
  {
    "x": "bilî ... bîyene",
    "y": " derdine düşmek\r\n~O bilî nêweşîya xo bîyo. (O hastalığının derdine düşmüş.)"
  },
  {
    "x": "billa bîyayene",
    "y": " b. bila bîyene"
  },
  {
    "x": "billa kerdene",
    "y": " b. bila kerdene"
  },
  {
    "x": "billabîyayîş (n)",
    "y": " bilabîyîş (n)"
  },
  {
    "x": "billakerdiş (n)",
    "y": " b. bilakerdiş (n)"
  },
  {
    "x": "bilo (n)",
    "y": " çük (çocuk dilinde)"
  },
  {
    "x": "bilon (n)",
    "y": " b. bilan (n)"
  },
  {
    "x": "bilule (m)",
    "y": " b. bilûre (m)"
  },
  {
    "x": "bilur (m)",
    "y": " b. bilûre (m)"
  },
  {
    "x": "bilure (m)",
    "y": " b. bilûre (m)"
  },
  {
    "x": "bilurvan, -e",
    "y": " b. bilûrvan, -e"
  },
  {
    "x": "bilurwûn, -e",
    "y": " b. bilûrvan, -e"
  },
  {
    "x": "bilusk (n)",
    "y": " 1)şimşek\r\n~biluskî eştene: şimşek çakmak, şimşeklenmek\r\n~Biluskî eşt. (Şimşek çaktı.)\r\n~\t\r\n~2)yıldırım\r\n~\t\r\n~Biluskî da piro./Biluskî ci şana. (Yıldırım çarptı.)"
  },
  {
    "x": "biluskî eştene",
    "y": " şimşek çakmak, şimşeklenmek\r\n~Biluskî eşt. (Şimşek çaktı.)\r\n~"
  },
  {
    "x": "biluskgir (n)",
    "y": " yıldırımkıran, yıldırımsavar, yıldırımlık, paratoner"
  },
  {
    "x": "biluz (n)",
    "y": " b. bluz (n)"
  },
  {
    "x": "bilûn (n)",
    "y": " b. bilan (n)"
  },
  {
    "x": "bilûr (m)",
    "y": " b. bilûre (m)"
  },
  {
    "x": "bilûre (m)",
    "y": " kaval"
  },
  {
    "x": "bilûrvan, -e",
    "y": " kavalcı (kaval çalan)"
  },
  {
    "x": "bilûz (n)",
    "y": " b. bluz (n)"
  },
  {
    "x": "bilvil (n)",
    "y": " b. bilbil (n)"
  },
  {
    "x": "bilxor (n)",
    "y": " b. belxur (n)"
  },
  {
    "x": "bilxur (n)",
    "y": " b. belxur (n)"
  },
  {
    "x": "bilyan (n)",
    "y": " b. bilan (n)"
  },
  {
    "x": "bimare",
    "y": " analı"
  },
  {
    "x": "bimaye",
    "y": " analı"
  },
  {
    "x": "bimbar (n)",
    "y": " b. bumbar (n)"
  },
  {
    "x": "bimbarek, -e",
    "y": " 1)kutlu, mübarek\r\n~Roşanê to bimbarek bo. (Bayramın kutlu olsun.)\r\n~2)fetiş (uğurlu sayılan)"
  },
  {
    "x": "bimbarek kerdene",
    "y": " kutlamak"
  },
  {
    "x": "bimbareke (m)",
    "y": " cin"
  },
  {
    "x": "bimbarekî (zh)",
    "y": " cinler"
  },
  {
    "x": "bimbarekkerdiş (n)",
    "y": " kutlama"
  },
  {
    "x": "bimbarik",
    "y": " b. bimbarek, -e"
  },
  {
    "x": "bimbirikî (zh)",
    "y": " b. bimbarekî (zh)"
  },
  {
    "x": "bimberxul (n)",
    "y": " b. binbelxur (n)"
  },
  {
    "x": "bimerhele, -ye",
    "y": " aşamalı"
  },
  {
    "x": "bimilpêşe",
    "y": " atkılı"
  },
  {
    "x": "bimubalexa",
    "y": " abartılı, mübalağalı"
  },
  {
    "x": "bimudet, -e",
    "y": " süreli"
  },
  {
    "x": "bimuhlet, -e",
    "y": " vadeli"
  },
  {
    "x": "bin (n)",
    "y": " 1)alt, dip\r\n~binê destî ra: gizlice\r\n~binê erdî (n):yer altı\r\n~binê goşî (n): kulak dibi\r\n~binê linge (n): taban (ayak tabanı)\r\n~2)ast"
  },
  {
    "x": "bin a",
    "y": " b. bin ra"
  },
  {
    "x": "bin a nêbo",
    "y": " b. bin ra nêbo"
  },
  {
    "x": "bin de",
    "y": " asla, hiçbir zaman, hiç, katiyen"
  },
  {
    "x": "bin de mendene",
    "y": " altta kalmak"
  },
  {
    "x": "bin de nêbo",
    "y": " hiç olmazsa, hiç değilse, bari"
  },
  {
    "x": "bin de xerîb, -e",
    "y": " tamamen yabancı"
  },
  {
    "x": "bin di",
    "y": " b. bin de"
  },
  {
    "x": "bin di nêbo",
    "y": " b. bin de nêbo"
  },
  {
    "x": "bin di xerîb, -i",
    "y": " b. bin de xerîb, -e"
  },
  {
    "x": "bin girewtene",
    "y": " dibi tutmak (tencere vb. için)"
  },
  {
    "x": "bin girotene",
    "y": " b. bin girewtene"
  },
  {
    "x": "bin girowtene",
    "y": " b. bin girewtene"
  },
  {
    "x": "bin guretene",
    "y": " b. bin girewtene"
  },
  {
    "x": "bin kewtene",
    "y": " yenilmek, hezimete uğramak, yenilgiye uğramak, bozguna uğramak, bozguna vermek"
  },
  {
    "x": "bin kotene",
    "y": " b. bin kewtene"
  },
  {
    "x": "bin ra",
    "y": " 1)asla, hiçbir zaman, hiç, katiyen \r\n~2)sırf, sadece\r\n~3)baştan sona"
  },
  {
    "x": "bin ra nêbo",
    "y": " hiç olmazsa, hiç değilse"
  },
  {
    "x": "bin ra şîyene",
    "y": " burkulmak"
  },
  {
    "x": "bin ra xerîb, -e",
    "y": " tamamen yabancı"
  },
  {
    "x": "bin ra xerîbi (m)",
    "y": " b. bin ra xerîbe (m)"
  },
  {
    "x": "bin û binaşe",
    "y": " temel, esas"
  },
  {
    "x": "binê barî de bîyene",
    "y": " ağır yük veya yükümlülük altında olmak"
  },
  {
    "x": "binê barî de mendene",
    "y": " ağır yük veya yükümlülük altında kalmak"
  },
  {
    "x": "binê cuwenî (n)",
    "y": " b. bincuwen (n)"
  },
  {
    "x": "binê çengilî (n)",
    "y": " koltuk altı"
  },
  {
    "x": "binê destî ra",
    "y": " gizlice"
  },
  {
    "x": "binê erdî (n)",
    "y": " yer altı"
  },
  {
    "x": "binê goşî (n)",
    "y": " kulak dibi"
  },
  {
    "x": "binê linge (n)",
    "y": " taban (ayak tabanı)"
  },
  {
    "x": "binê selage de bîyayene",
    "y": " b. binê şalage de bîyene"
  },
  {
    "x": "binê selage de mendene",
    "y": " b. binê şalage de mendene"
  },
  {
    "x": "binê solî (n)",
    "y": " taban (ayakkabı tabanı)"
  },
  {
    "x": "binê şalage de bîyene",
    "y": " ağır yük veya yükümlülük altında olmak"
  },
  {
    "x": "binê şalage de mendene",
    "y": " ağır yük veya yükümlülük altında kalmak"
  },
  {
    "x": "binî barî di bîyayene",
    "y": " b. binê barî de bîyene"
  },
  {
    "x": "binî barî di mendene",
    "y": " b. binê barî de mendene"
  },
  {
    "x": "binî gueşî (n)",
    "y": " b. binê goşî (n)"
  },
  {
    "x": "binî ling (n)",
    "y": " b. binê linge (n)"
  },
  {
    "x": "binî pay (n)",
    "y": " b. binpa (n)"
  },
  {
    "x": "binî solî (n)",
    "y": " b. binê solî (n)"
  },
  {
    "x": "binadir (m)",
    "y": " b. binadire (m) "
  },
  {
    "x": "binadire (m)",
    "y": " kömbe (küle gömülerek pişirilen mayasız çörek)"
  },
  {
    "x": "binakokî, -ye",
    "y": " çelişkili, çelişmeli, mütenakız"
  },
  {
    "x": "biname, -ye",
    "y": " adlı"
  },
  {
    "x": "binamûs, -e",
    "y": " namuslu "
  },
  {
    "x": "binaşe (n)",
    "y": " asıl, temel, esas"
  },
  {
    "x": "binate-I (n)",
    "y": " b. beyntar (n)"
  },
  {
    "x": "binate-II (n)",
    "y": " b. berate (n)"
  },
  {
    "x": "binati (n)",
    "y": " b. beyntar (n)"
  },
  {
    "x": "binatîn",
    "y": " b. beyntarî, -ye"
  },
  {
    "x": "binawanî (m)",
    "y": " altyapı"
  },
  {
    "x": "binawanîye (m)",
    "y": " altyapı"
  },
  {
    "x": "binbarek",
    "y": " b. bimbarek, -e"
  },
  {
    "x": "binbardaxe (n)",
    "y": " bardak altı"
  },
  {
    "x": "binbelxur (n)",
    "y": " çok ince bulgur"
  },
  {
    "x": "binberxul (n)",
    "y": " b. binbelxur (n)"
  },
  {
    "x": "binbilerze (m)",
    "y": " bomlerze (m)"
  },
  {
    "x": "binboç (n)",
    "y": " kuskun"
  },
  {
    "x": "bincaran",
    "y": " asla, hiçbir zaman, hiç, katiyen"
  },
  {
    "x": "bincaro",
    "y": " b. bincaran"
  },
  {
    "x": "bincaron",
    "y": " b. bincaran"
  },
  {
    "x": "bincarû",
    "y": " b. bincaran"
  },
  {
    "x": "bincarûn",
    "y": " b. bincaran"
  },
  {
    "x": "bincew (m)",
    "y": " b. vîncewe (m)"
  },
  {
    "x": "bincewi (m)",
    "y": " b. vîncewe (m)"
  },
  {
    "x": "bincuwen (n)",
    "y": " harman sonu (badas)"
  },
  {
    "x": "bincuwîn (n)",
    "y": " b. bincuwen (n)"
  },
  {
    "x": "bincuyin (n)",
    "y": " b. bincuwen (n)"
  },
  {
    "x": "binçeng (n)",
    "y": " koltuk, koltuk altı"
  },
  {
    "x": "bine (n)",
    "y": " b. bin (n)"
  },
  {
    "x": "bine guretene",
    "y": " b. bin girewtene"
  },
  {
    "x": "bine ra",
    "y": " b. bin ra"
  },
  {
    "x": "bineql",
    "y": " aktarmalı"
  },
  {
    "x": "binerd (n)",
    "y": " yeraltı, yer altı"
  },
  {
    "x": "binerd kerdene",
    "y": " gömmek"
  },
  {
    "x": "binerdî, -ye",
    "y": " 1) yasadışı \r\n~2)(mecazi) yeraltı"
  },
  {
    "x": "binerdkerdiş (n)",
    "y": " gömme\r\n~binerdkerdişê eşteyî (n): atık gömme"
  },
  {
    "x": "binerdkerdişê eşteyî (n)",
    "y": " atık gömme"
  },
  {
    "x": "biney",
    "y": " biraz"
  },
  {
    "x": "bineynike",
    "y": " aynalı"
  },
  {
    "x": "binê",
    "y": " b. biney"
  },
  {
    "x": "binêk",
    "y": " b. biney"
  },
  {
    "x": "binêke (n)",
    "y": " b. binge (n)"
  },
  {
    "x": "binên, -e",
    "y": " alt taraftaki, alttaki"
  },
  {
    "x": "binfistan (n)",
    "y": " b. binfîstan (n)"
  },
  {
    "x": "binfiston (n)",
    "y": " b. binfîstan (n)"
  },
  {
    "x": "binfistûn (n)",
    "y": " b. binfîstan (n)"
  },
  {
    "x": "binfîstan (n)",
    "y": " entari veya gecelik entari (kadın için)"
  },
  {
    "x": "binfîstan-pirênbazî (zh)",
    "y": " gecelik (kadın için), pijama (kadın için)"
  },
  {
    "x": "binfîston (n)",
    "y": " b. binfîstan (n)"
  },
  {
    "x": "binfîstûn (n)",
    "y": " b. binfîstan (n)"
  },
  {
    "x": "binge (n)",
    "y": " 1)temel, esas \r\n~2)dayanak"
  },
  {
    "x": "bingeyên, -e",
    "y": " temel, asal, esasi"
  },
  {
    "x": "bingeyî",
    "y": " temel, asıl, asal, esasi"
  },
  {
    "x": "binhard (n)",
    "y": " b. binerd (n)"
  },
  {
    "x": "binherd (n)",
    "y": " b. binerd (n)"
  },
  {
    "x": "binhişmendî (m)",
    "y": " bilinçaltı, şuuraltı"
  },
  {
    "x": "binî (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "binîn (n)",
    "y": " b. binên (n)"
  },
  {
    "x": "binke (n)",
    "y": " b. binge (n)"
  },
  {
    "x": "binkewtiş (n)",
    "y": " bozgun, hezimet, yenilgi"
  },
  {
    "x": "binkol (n)",
    "y": " çapa"
  },
  {
    "x": "binlêrz (m)",
    "y": " bomlerze (m)"
  },
  {
    "x": "binmerre (n) (TE)",
    "y": " fare altlığı"
  },
  {
    "x": "binnuşte (n)",
    "y": " alt yazı"
  },
  {
    "x": "binpa (n)",
    "y": " taban (ayak tabanı) "
  },
  {
    "x": "binperin (n) ",
    "y": " b. binpirên (n)"
  },
  {
    "x": "binpirên (n)",
    "y": " fanila"
  },
  {
    "x": "binpîze (n)",
    "y": " kolan"
  },
  {
    "x": "binpîzik (n)",
    "y": " kolan"
  },
  {
    "x": "binpîzze (n)",
    "y": " b. binpîze (n)"
  },
  {
    "x": "binsinife (m)",
    "y": " alt sınıf"
  },
  {
    "x": "binsoba (n)",
    "y": " soba altlığı"
  },
  {
    "x": "binvîyer (n)",
    "y": " alt geçit"
  },
  {
    "x": "binyat (n)",
    "y": " temel, esas"
  },
  {
    "x": "bio",
    "y": " b. buuk, -e"
  },
  {
    "x": "biondaz, -e",
    "y": " abdestli, aptesli"
  },
  {
    "x": "bipaçikpîştiş (n)",
    "y": " bandajlama"
  },
  {
    "x": "bipereyan",
    "y": " bedelli\r\n~leşkerîya bipereyan: bedelli askerlik"
  },
  {
    "x": "leşkerîya bipereyan",
    "y": " bedelli askerlik"
  },
  {
    "x": "bipeynî (m)",
    "y": " sonlu"
  },
  {
    "x": "bipeynîye (m)",
    "y": " sonlu"
  },
  {
    "x": "bipeynîyeyî (m)",
    "y": " sonluluk"
  },
  {
    "x": "bipeynîyî (m)",
    "y": " sonluluk"
  },
  {
    "x": "bipezne",
    "y": " b. bibejne"
  },
  {
    "x": "biq kerdene",
    "y": " b. pix kerdene"
  },
  {
    "x": "biqabîlîyet, -e",
    "y": " kabiliyetli, yetenekli"
  },
  {
    "x": "biqame",
    "y": " boylu"
  },
  {
    "x": "biqaxû, -ye",
    "y": " 1)endişeli, kaygılı, tasalı \r\n~2)telaşlı"
  },
  {
    "x": "biqeçe (m)",
    "y": " gebe¸ iki canlı, hamile"
  },
  {
    "x": "biqeçe bîyene",
    "y": " gebe olmak, hamile olmak, iki canlı olmak"
  },
  {
    "x": "biqeçey (m)",
    "y": " b. biqeçeyîye (m)"
  },
  {
    "x": "biqeçeyîye (m) ",
    "y": " gebelik, hamilelik"
  },
  {
    "x": "biqeçê (m)",
    "y": " b. biqeçeyîye (m)"
  },
  {
    "x": "biqeçi (m) ",
    "y": " b. biqeçe (m)"
  },
  {
    "x": "biqeçi bîyayene",
    "y": " b. biqeçe bîyene"
  },
  {
    "x": "biqedr, -e",
    "y": " saygıdeğer, muhterem, sayın, saygın"
  },
  {
    "x": "biqedrî (m)",
    "y": " saygınlık"
  },
  {
    "x": "biqedrîye (m)",
    "y": " saygınlık"
  },
  {
    "x": "biqerqele",
    "y": " iskeletli"
  },
  {
    "x": "biqerqot",
    "y": " iskeletli"
  },
  {
    "x": "biqesawet, -e",
    "y": " 1)endişeli, kaygılı, tasalı \r\n~2)telaşlı"
  },
  {
    "x": "biqewet, -e",
    "y": " güçlü, kuvvetli, çelimli"
  },
  {
    "x": "biqomi",
    "y": " b. biqame"
  },
  {
    "x": "biqule (n)",
    "y": " kuleli"
  },
  {
    "x": "biqusur, -e",
    "y": " kusurlu"
  },
  {
    "x": "biqutîye",
    "y": " aynalı"
  },
  {
    "x": "biqûmi",
    "y": " b. biqame"
  },
  {
    "x": "bir-I (n)",
    "y": " b. birr (n)"
  },
  {
    "x": "biro kor (n)",
    "y": " birro kor (n)"
  },
  {
    "x": "bir-II (n)",
    "y": " b. bire-II (m)"
  },
  {
    "x": "bir-III (m)",
    "y": " b. birre-II (m)"
  },
  {
    "x": "bir pês (m)",
    "y": " b. birra pesî (m)"
  },
  {
    "x": "bir varûn (m)",
    "y": " b. birra verekan (m)"
  },
  {
    "x": "bira-I (n)",
    "y": " kardeş (erkek kardeş) "
  },
  {
    "x": "biraw pîl (n)",
    "y": " b. birayo pîl (n) "
  },
  {
    "x": "birawo pîl (n)",
    "y": " b. birayo pîl (n) "
  },
  {
    "x": "biray axretî (n)",
    "y": " b. birayê axretî (n) "
  },
  {
    "x": "biray şitî (n)",
    "y": " b. birayê şitî (n), biraşit (n)"
  },
  {
    "x": "birayê axretî (n)",
    "y": " ahiret kardeşi, ahret kardeşi"
  },
  {
    "x": "birayê şitî (n)",
    "y": " erkek sütkardeş, sütkardeş "
  },
  {
    "x": "birayo pîl (n)",
    "y": " ağabey"
  },
  {
    "x": "biro pîl (n)",
    "y": " b. birayo pîl (n)"
  },
  {
    "x": "bira-II",
    "y": " 1)bile\r\n~Ey bira ti nêdî. (O seni görmedi bile.)\r\n~\t\r\n~2)hatta\r\n~\t\r\n~Bira ey va zur a. (Hatta o yalan olduğunu söyledi.)"
  },
  {
    "x": "birabajar (n)",
    "y": " kardeş şehir"
  },
  {
    "x": "biracenî (m)",
    "y": " b. biracinî (m)"
  },
  {
    "x": "biracinî (m)",
    "y": " yenge, erkek kardeşin karısı"
  },
  {
    "x": "biraçena (m)",
    "y": " b. birakêna (m)"
  },
  {
    "x": "biraçêna (m)",
    "y": " b. birakêna (m)"
  },
  {
    "x": "birad (m)",
    "y": " b. berradî (m)"
  },
  {
    "x": "biraên (m)",
    "y": " b. birayî (m), birayîye (m)"
  },
  {
    "x": "birak, -e-I",
    "y": " 1) dost, ahbap; can ciğer, can dostu, can arkadaşı \r\n~2)yâr, metres"
  },
  {
    "x": "birak bîyene",
    "y": " can ciğer  olmak"
  },
  {
    "x": "birak-II (n)",
    "y": " b. birrek (n)"
  },
  {
    "x": "birake (m)",
    "y": " dost, yâr, metres"
  },
  {
    "x": "birakey (m)",
    "y": " b. birakîye (m)"
  },
  {
    "x": "birakeyna (m)",
    "y": " b. birakêna (m)"
  },
  {
    "x": "birakê (m)",
    "y": " b. birakîye (m)"
  },
  {
    "x": "birakêna (m)",
    "y": " erkek kardeşin kızı, yeğen"
  },
  {
    "x": "biraki (m)",
    "y": " b. birake (m)"
  },
  {
    "x": "birakîye (m)",
    "y": " dostluk, ahbaplık"
  },
  {
    "x": "biralete (n)",
    "y": " üvey erkek kardeş"
  },
  {
    "x": "biramag (n)",
    "y": " b. biramak (n)"
  },
  {
    "x": "biramak (n)",
    "y": " üvey erkek kardeş (aynı anneden)"
  },
  {
    "x": "birar (n)",
    "y": " kardeş (erkek kardeş) "
  },
  {
    "x": "birarey (m)",
    "y": " b. birarî (m)"
  },
  {
    "x": "birarî (m)",
    "y": " kardeşlik"
  },
  {
    "x": "birarîye (m)",
    "y": " kardeşlik"
  },
  {
    "x": "birarkeyna (m)",
    "y": " b. birarkêna (m)"
  },
  {
    "x": "birarkêna (m)",
    "y": " erkek kardeşin kızı, yeğen"
  },
  {
    "x": "birartî (m)",
    "y": " b. birarîye (m)"
  },
  {
    "x": "birarza (n)",
    "y": " erkek kardeşin oğlu, yeğen"
  },
  {
    "x": "biraşaristan (n)",
    "y": " kardeş şehir"
  },
  {
    "x": "biraşit (n)",
    "y": " erkek sütkardeş, sütkardeş"
  },
  {
    "x": "biratey (m)",
    "y": " b. birayîye (m)"
  },
  {
    "x": "biratî (m)",
    "y": " b. birayîye (m)"
  },
  {
    "x": "biratîne (m)",
    "y": " b. birayîye (m)"
  },
  {
    "x": "biratîye (m)",
    "y": " b. birayîye (m)"
  },
  {
    "x": "birayene",
    "y": " b birrayene"
  },
  {
    "x": "birayey (m)",
    "y": " b. birayîye (m), birayî (m)"
  },
  {
    "x": "birayeya axretî (m)",
    "y": " b. birayîya axretî (m) "
  },
  {
    "x": "birayên (m)",
    "y": " b. birayî (m), birayîye (m)"
  },
  {
    "x": "birayêne (m)",
    "y": " b. birayî (m), birayîye (m)"
  },
  {
    "x": "birayî (m)",
    "y": " kardeşlik"
  },
  {
    "x": "birayîya axretî (m)",
    "y": " ahiret kardeşliği, ahret kardeşliği"
  },
  {
    "x": "birayîn (m)",
    "y": " b. birayî (m), birayîye (m)"
  },
  {
    "x": "birayîne (m)",
    "y": " b. birayî (m), birayîye (m)"
  },
  {
    "x": "birayîş (n)",
    "y": " b. birrayîş (n)"
  },
  {
    "x": "birayîye (m)",
    "y": " kardeşlik\r\n~birayîya axretî (m): ahiret kardeşliği, ahret kardeşliği"
  },
  {
    "x": "birayîya axretî (m)",
    "y": " ahiret kardeşliği, ahret kardeşliği"
  },
  {
    "x": "biraza (n)",
    "y": " erkek kardeşin oğlu, yeğen"
  },
  {
    "x": "birazaye (m)",
    "y": " erkek kardeşin kızı, yeğen"
  },
  {
    "x": "birbil (n)",
    "y": " b. bilbil (n)"
  },
  {
    "x": "birvil (n)",
    "y": " b. bilbil (n)"
  },
  {
    "x": "bire-I (m)",
    "y": " karar\r\n~bira encumenî (m): encümen kararı\r\n~bira vindarnayîşê caardişî (n): yürütmeyi durdurma kararı"
  },
  {
    "x": "bira encumenî (m)",
    "y": " encümen kararı"
  },
  {
    "x": "bira vindarnayîşê caardişî (n)",
    "y": " yürütmeyi durdurma kararı"
  },
  {
    "x": "bire dayene",
    "y": " karar vermek, karara bağlamak"
  },
  {
    "x": "bire girewtene",
    "y": " karar almak"
  },
  {
    "x": "bire-II (m)",
    "y": " iki grupla ve koşmak suretiyle oynanan geleneksel bir oyun"
  },
  {
    "x": "birek (n)",
    "y": " b. birrek (n)"
  },
  {
    "x": "birekasin (n)",
    "y": " b. birrekasin (n)"
  },
  {
    "x": "birename (n)",
    "y": " kararname"
  },
  {
    "x": "bireqabet, -e",
    "y": " çekişmeli (çekişmeye yol açan)"
  },
  {
    "x": "birewî (zh)",
    "y": " b. birûy (zh)"
  },
  {
    "x": "birênî (zh)",
    "y": " doğum sancısı"
  },
  {
    "x": "birênan ci eştene",
    "y": " doğum sancısı çekmek"
  },
  {
    "x": "birênan ver de bîyene",
    "y": " doğum sancısı çekmek"
  },
  {
    "x": "birênan ver kewtene",
    "y": " doğum sancısı çekmek"
  },
  {
    "x": "birênîyû ver kewtene",
    "y": " b. birênan ver kewtene"
  },
  {
    "x": "birêz, -e",
    "y": " saygıdeğer, muhterem, sayın, saygın"
  },
  {
    "x": "birêzî (m)",
    "y": " saygınlık"
  },
  {
    "x": "birêzîye (m)",
    "y": " saygınlık"
  },
  {
    "x": "birik (n)",
    "y": " b. birrek (n)"
  },
  {
    "x": "birikasin (n)",
    "y": " birrekasin (n)"
  },
  {
    "x": "birinc (n)",
    "y": " pirinç"
  },
  {
    "x": "birind (m)",
    "y": " b. berendîre (m)"
  },
  {
    "x": "birinde (m)",
    "y": " b. berendîre (m)"
  },
  {
    "x": "birindî (m)",
    "y": " b. berendîre (m) "
  },
  {
    "x": "birindîli (m)",
    "y": " b. berendîre (m)"
  },
  {
    "x": "biriqîyayîş (n)",
    "y": " b. beriqîyayîş (n)"
  },
  {
    "x": "birisk (n)",
    "y": " b. bilusk (n)"
  },
  {
    "x": "biriş (n)",
    "y": " kesit"
  },
  {
    "x": "birî (zh)",
    "y": " b. birûy (zh)"
  },
  {
    "x": "birîmdar, -e",
    "y": " b. birîndar, -e"
  },
  {
    "x": "birîndar, -e",
    "y": " yaralı"
  },
  {
    "x": "birîndar bîyayene",
    "y": " b. birîndar bîyene"
  },
  {
    "x": "birîndar bîyene",
    "y": " yaralanmak"
  },
  {
    "x": "birîndarbîyayîş (n)",
    "y": " yaralanma"
  },
  {
    "x": "birîndari (m)",
    "y": " b. birîndare (m)"
  },
  {
    "x": "birîne (m)",
    "y": " yara\r\n~birîna çewrî (m): balta yarası"
  },
  {
    "x": "birîna çewrî (m)",
    "y": " balta yarası"
  },
  {
    "x": "birîna derbe (m)",
    "y": " darbe yarası "
  },
  {
    "x": "birîni (m)",
    "y": " b. birîne (m)"
  },
  {
    "x": "birînî (zh)",
    "y": " b. birênî (zh)"
  },
  {
    "x": "birîsim (n)",
    "y": " ipek"
  },
  {
    "x": "birîsimên, -e",
    "y": " ipekten yapılma, ipekli\r\n~paço/qemîso birîsimên (n): ipek gömlek\r\n~qolika birîsimêne (m): ipek gömlek"
  },
  {
    "x": "paço/qemîso birîsimên (n)",
    "y": " ipek gömlek"
  },
  {
    "x": "qolika birîsimêne (m)",
    "y": " ipek gömlek"
  },
  {
    "x": "birîsimin, -e",
    "y": " ipekli"
  },
  {
    "x": "birîsk, -e",
    "y": " riskli"
  },
  {
    "x": "birîsm (n)",
    "y": " b. birîsim (n)"
  },
  {
    "x": "birîsmên, -e",
    "y": " b. birîsimên, -e"
  },
  {
    "x": "birîsmin, -e",
    "y": " b. birîsimin, -e"
  },
  {
    "x": "birîyayene",
    "y": " kesilmek "
  },
  {
    "x": "birîyayîş (n)",
    "y": " kesilme"
  },
  {
    "x": "birk (m)",
    "y": " b. birke (m)"
  },
  {
    "x": "birke-I (m)",
    "y": " siğil\r\n~birka xişne (m): ur, tümör "
  },
  {
    "x": "birka xişne (m)",
    "y": " ur, tümör "
  },
  {
    "x": "birka xişni (m)",
    "y": " b. birka xişne (m)"
  },
  {
    "x": "birke-II (n)",
    "y": " sabanın çift sürerken elle tutulan kısmından toprağa kadar uzanan parçası"
  },
  {
    "x": "birki-I (m)",
    "y": " b. birke-I (m)"
  },
  {
    "x": "birki-II (n)",
    "y": " b. birke-II (n)"
  },
  {
    "x": "birnaye (n)",
    "y": " 1)kesik, kesilmiş olan \r\n~2)kesinti\r\n~birnayeyê caardişî (n): icra kesintisi\r\n~3)kupür"
  },
  {
    "x": "birnayeyê caardişî (n)",
    "y": " icra kesintisi"
  },
  {
    "x": "birnayeyê heqkerdeyî (n)",
    "y": " hak ediş kesintisi"
  },
  {
    "x": "birnayeyê îcra (n)",
    "y": " icra kesintisi"
  },
  {
    "x": "birnayene",
    "y": " 1)kesmek\r\n~Bibirne! (Kes!)\r\n~\t\r\n~2)biçmek, biçki yapmak (dikiş için)"
  },
  {
    "x": "birnayine",
    "y": " b. birnayene"
  },
  {
    "x": "birnayî, -ye",
    "y": " 1)kesik \r\n~2)kesenek\r\n~birnayîyê teqawutî/e: emekli keseneği"
  },
  {
    "x": "birnayîyê teqawutî/e",
    "y": " emekli keseneği"
  },
  {
    "x": "birnayîs (n)",
    "y": " b. birnayîş (n)"
  },
  {
    "x": "birnayîş (n)",
    "y": " 1)kesme \r\n~2)fason, biçme (dikiş için)"
  },
  {
    "x": "birnayîşê gijikî",
    "y": " saç tıraşı"
  },
  {
    "x": "birnayîşê porî",
    "y": " saç tıraşı"
  },
  {
    "x": "birnayîşî reyra vîyartiş (sn)",
    "y": " kesme ile geçiş"
  },
  {
    "x": "birnayox, -e",
    "y": " 1)kesici \r\n~2)biçkici"
  },
  {
    "x": "birnayoxo hîdrolîk (n)",
    "y": " hidrolik kesici"
  },
  {
    "x": "biroj",
    "y": " gündüzün"
  },
  {
    "x": "biros (n)",
    "y": " b. beroş (n)"
  },
  {
    "x": "biroş (n)",
    "y": " b. beroş (n)"
  },
  {
    "x": "biroy (zh)",
    "y": " b. birûy (zh) "
  },
  {
    "x": "birr-I (n)",
    "y": " 1)orman, ağaçlık \r\n~2)çalı çırpı \r\n~3)b. birre-I (m)"
  },
  {
    "x": "birro hurdebî (n)",
    "y": " çalılık"
  },
  {
    "x": "birro kor (n)",
    "y": " sık ağaçlık"
  },
  {
    "x": "birrad (m)",
    "y": " b. berradî (m)"
  },
  {
    "x": "birrayene",
    "y": " 1)bağırmak \r\n~2)b. barrayene"
  },
  {
    "x": "birrayîş (n)",
    "y": " 1)bağırma \r\n~2)b. barrayîş (n)"
  },
  {
    "x": "birre-I (m)",
    "y": " çalı parçası (küçük ağaç parçası)"
  },
  {
    "x": "birrî (zh)",
    "y": " çalı çırpı"
  },
  {
    "x": "birre-II (m)",
    "y": " hayvan kümesi (küme), sürü\r\n~birra pesî (m): küçükbaş hayvan sürüsü\r\n~birra verekan (m): kuzu sürüsü"
  },
  {
    "x": "birra pesî (m)",
    "y": " küçükbaş hayvan sürüsü"
  },
  {
    "x": "birra verekan (m)",
    "y": " kuzu sürüsü"
  },
  {
    "x": "birre-III (m)",
    "y": " b. bire-II (m)"
  },
  {
    "x": "birrek (n)",
    "y": " 1)testere\r\n~birrekê asinî (n): demir testeresi\r\n~2)testere balığı"
  },
  {
    "x": "birrekê asinî (n)",
    "y": " demir testeresi "
  },
  {
    "x": "birrekasin (n)",
    "y": " demir testeresi "
  },
  {
    "x": "birreke (m)",
    "y": " çalı parçası (küçük ağaç parçası)"
  },
  {
    "x": "birrekî (zh)",
    "y": " çalı çırpı"
  },
  {
    "x": "birreki (m)",
    "y": " b. birreke (m)"
  },
  {
    "x": "birri",
    "y": " b. birre-I (m)"
  },
  {
    "x": "birrik (n)",
    "y": " b. birrek (n) "
  },
  {
    "x": "birrikê asinî (n)",
    "y": " b.birrekê asinî (n)"
  },
  {
    "x": "birrikasin (n)",
    "y": " b. birrekasin (n) "
  },
  {
    "x": "birrin (n)",
    "y": " ormanlık"
  },
  {
    "x": "birrnayene",
    "y": " b. birnayene"
  },
  {
    "x": "birrnayêne",
    "y": " b. birnayene"
  },
  {
    "x": "birrnayine",
    "y": " b. birnayene"
  },
  {
    "x": "birrnayîs (n)",
    "y": " b. birnayîş (n)"
  },
  {
    "x": "birrnayîş (n)",
    "y": " b. birnayîş (n)"
  },
  {
    "x": "birsim (n)",
    "y": " b. birîsim (n)"
  },
  {
    "x": "birsimên, -e",
    "y": " b. birîsimên, -e"
  },
  {
    "x": "birsimin, -e",
    "y": " b. birîsimin, -e"
  },
  {
    "x": "birsimîn",
    "y": " b. birîsimin, -e"
  },
  {
    "x": "biruşk (n)",
    "y": " b. bilusk (n)"
  },
  {
    "x": "biruxset, -e",
    "y": " ruhsatlı"
  },
  {
    "x": "biruy (zh)",
    "y": " b. birûy (zh)"
  },
  {
    "x": "birûmet, -e",
    "y": " saygıdeğer, muhterem, sayın, saygın"
  },
  {
    "x": "birûmet kerdene",
    "y": " değerlendirmek, kıymetlendirmek"
  },
  {
    "x": "birûmetî (m)",
    "y": " saygınlık"
  },
  {
    "x": "birûmetîye (m)",
    "y": " saygınlık"
  },
  {
    "x": "birûmetkerdiş (n)",
    "y": " değerlendirme, kıymetlendirme"
  },
  {
    "x": "birûsik (n)",
    "y": " b. bilusk (n)"
  },
  {
    "x": "birûsike (m)",
    "y": " b. bilusk (n)"
  },
  {
    "x": "birûy (zh)",
    "y": " kaş (kaşlar)"
  },
  {
    "x": "birûy girewtiş (n)",
    "y": " kaş alma"
  },
  {
    "x": "birûy viraştiş (n)",
    "y": " kaş yapma"
  },
  {
    "x": "birwey (zh)",
    "y": " b. birûy (zh) "
  },
  {
    "x": "birxu (n)",
    "y": " b. burxî (n)"
  },
  {
    "x": "birxul (n)",
    "y": " b. belxur (n)"
  },
  {
    "x": "birxû (n)",
    "y": " b. burxî (n)"
  },
  {
    "x": "biryayene",
    "y": " b. birîyayene"
  },
  {
    "x": "biryayîş (n)",
    "y": " b. birîyayîş (n)"
  },
  {
    "x": "bisaz, -e",
    "y": " çalgılı"
  },
  {
    "x": "bisened (n)",
    "y": " vesikalı"
  },
  {
    "x": "biserûber, -e",
    "y": " düzenli, intizamlı, nizamlı"
  },
  {
    "x": "biserûber kerdene",
    "y": " düzenlemek, tanzim etmek, tertip etmek, tertiplemek"
  },
  {
    "x": "biserûberkar, -e",
    "y": " düzenleyici"
  },
  {
    "x": "biserûberkerdiş (n)",
    "y": " düzenleme, tanzim etme, tertip etme, tertipleme; düzeltme, tanzim"
  },
  {
    "x": "biseyte (m)",
    "y": " b. miseyte (m)"
  },
  {
    "x": "biseyti (m)",
    "y": " b. miseyte (m)"
  },
  {
    "x": "bisiklet (n)",
    "y": " b. bîsîklete (m)"
  },
  {
    "x": "bisilman, -e",
    "y": " b. musluman, -e"
  },
  {
    "x": "bisilmana (m)",
    "y": " b. musluman, -e"
  },
  {
    "x": "bisilmane (n)",
    "y": " b. musluman (n)"
  },
  {
    "x": "bisilmaney (m)",
    "y": " b. muslumanîye (m), muslumanî (m)"
  },
  {
    "x": "bisilmaneyey (m)",
    "y": " b. muslumanîye (m), muslumanî (m)"
  },
  {
    "x": "bisilmon, -e",
    "y": " b. musluman, -e"
  },
  {
    "x": "bisilmona (m)",
    "y": " b. muslumane (m)"
  },
  {
    "x": "bisilmone",
    "y": " b. musluman, -e"
  },
  {
    "x": "bisilmoney (m)",
    "y": " b. muslumanîye (m), muslumanî (m)"
  },
  {
    "x": "bisilmûn, -e",
    "y": " b. musluman, -e"
  },
  {
    "x": "bisilmûna (m)",
    "y": " b. muslumane (m)"
  },
  {
    "x": "bisilmûne",
    "y": " b. musluman, -e"
  },
  {
    "x": "bisilmûneyê (m)",
    "y": " b. muslumanîye (m), muslumanî (m)"
  },
  {
    "x": "bisilmûnê (m)",
    "y": " b. muslumanîye (m), muslumanî (m)"
  },
  {
    "x": "bisiud, -e",
    "y": " b. bisiûd, -e"
  },
  {
    "x": "bisiûd, -e",
    "y": " şanslı, talihli, bahtlı"
  },
  {
    "x": "bisîkleti (m)",
    "y": " b. bîsîklete (m)"
  },
  {
    "x": "Bisk (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "biske (m)",
    "y": " (alna düşen kısa kesilmiş saç) perçem, kâkül"
  },
  {
    "x": "Biske (m)",
    "y": " inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "biskivît (m)",
    "y": " b. bîskuvît (n)"
  },
  {
    "x": "bisklêt (n)",
    "y": " b. bîsîklete (m)"
  },
  {
    "x": "biskok (n)",
    "y": " b. bişkok (n)"
  },
  {
    "x": "biskuwît (n)",
    "y": " b. bîskuvît (n)"
  },
  {
    "x": "bismar (n)",
    "y": " çivi"
  },
  {
    "x": "bismar dayene ... ro",
    "y": " b. bismar ... ro dayene"
  },
  {
    "x": "bismar ... de kutene",
    "y": " çivi çakmak"
  },
  {
    "x": "bismar ... de kuwayene",
    "y": " çivi çakmak"
  },
  {
    "x": "bismar kerdene",
    "y": " çivilemek"
  },
  {
    "x": "bismar kutene",
    "y": " çivi çakmak"
  },
  {
    "x": "bismar kuwayene",
    "y": " çivi çakmak"
  },
  {
    "x": "bismar pero dayîne",
    "y": " b. bismar ... ro dayene"
  },
  {
    "x": "bismar ... ro dayene",
    "y": " çivi çakmak"
  },
  {
    "x": "bismar ... ro dayîne",
    "y": " b. bismar ... ro dayene"
  },
  {
    "x": "bismar tede kutene",
    "y": " b. bismar ... de kutene"
  },
  {
    "x": "bismar tede kuwayîne",
    "y": " b. bismar ... de kuwayene"
  },
  {
    "x": "Bismil (n)",
    "y": " Bismil"
  },
  {
    "x": "bisqilet (m)",
    "y": " b. bîsîklete (m)"
  },
  {
    "x": "bisuud, -e",
    "y": " b. bisiûd, -e"
  },
  {
    "x": "bişans, -e",
    "y": " şanslı, talihli, bahtlı"
  },
  {
    "x": "bişerm",
    "y": " çekine çekine, çekinerek"
  },
  {
    "x": "bişeş",
    "y": " altılı"
  },
  {
    "x": "bişewat (n)",
    "y": " ateşli (ateşi olan)"
  },
  {
    "x": "bişewe",
    "y": " geceleyin"
  },
  {
    "x": "bişîye (m)",
    "y": " b. bîşîye (m)"
  },
  {
    "x": "bişkoje (m)",
    "y": " düğme (giysi için)"
  },
  {
    "x": "bişkoje devistene/girewtene/qefilnayene/tavistene",
    "y": " düğme iliklemek"
  },
  {
    "x": "bişkok (n)",
    "y": " tomurcuk"
  },
  {
    "x": "bişkok dayene",
    "y": " tomurcuklanmak\r\n~Daran bişkok da. (Ağaçlar tomurcuklandı.)"
  },
  {
    "x": "bişumûl, -e",
    "y": " kapsamlı"
  },
  {
    "x": "bişxi (n)",
    "y": " b. biçxe (n)"
  },
  {
    "x": "bitale, -ye",
    "y": " b. bitalih, -e"
  },
  {
    "x": "bitaleh, -e",
    "y": " b. bitalih, -e"
  },
  {
    "x": "bitalih, -e",
    "y": " şanslı, talihli, bahtlı"
  },
  {
    "x": "bitalî, -ye",
    "y": " b. bitalih, -e"
  },
  {
    "x": "bitapî, -ye",
    "y": " b. bitapû, -ye"
  },
  {
    "x": "bitapo, -ye",
    "y": " b. bitapû, -ye"
  },
  {
    "x": "bitapû, -ye",
    "y": " tapulu"
  },
  {
    "x": "bitaybetî",
    "y": " özellikle, başta"
  },
  {
    "x": "bite (m)",
    "y": " but"
  },
  {
    "x": "biteferuat, -e",
    "y": " ayrıntılı"
  },
  {
    "x": "bitemamî",
    "y": " 1)başlı başına (zr) \r\n~2)baştan aşağı (zr) \r\n~3)baştan başa \r\n~4)baştan sona (zr)"
  },
  {
    "x": "bitenaquz, -e",
    "y": " çelişkili, çelişmeli, mütenakız"
  },
  {
    "x": "biters",
    "y": " çekine çekine, çekinerek"
  },
  {
    "x": "bitevger, -e",
    "y": " hareketli"
  },
  {
    "x": "bitezad, -e",
    "y": " çelişkili, çelişmeli, mütenakız"
  },
  {
    "x": "biti-I (m)",
    "y": " b. bite (m)"
  },
  {
    "x": "biti-II",
    "y": " b. bitir"
  },
  {
    "x": "biti pêrar (n)",
    "y": " b. biti pêrar (n)"
  },
  {
    "x": "bitir",
    "y": " önceki, evvelki"
  },
  {
    "x": "bitir perarey (n)",
    "y": " bugünden önceki dördüncü gün"
  },
  {
    "x": "bitir perarê (n)",
    "y": " b. bitir perarey (n)"
  },
  {
    "x": "bitir perey (n)",
    "y": " bugünden önceki üçüncü gün"
  },
  {
    "x": "bitir perê (n)",
    "y": " b. bitir perey (n)"
  },
  {
    "x": "bitir pêrar (n)",
    "y": " bu yıldan önceki üçüncü yıl"
  },
  {
    "x": "bitir pêrarey (n)",
    "y": " b. bitir perarey (n)"
  },
  {
    "x": "bitir, -e",
    "y": " azgın"
  },
  {
    "x": "bitirey (m)",
    "y": " b. bitirî (m)"
  },
  {
    "x": "bitirî (m)",
    "y": " azgınlık\r\n~“Bitirî gayê citi rî zî nêmanena.” (Azgınlık çift süren öküze bile kalmaz.)"
  },
  {
    "x": "bitorebirnayîş (n)",
    "y": " baltalama"
  },
  {
    "x": "bitoretêqesnayîş (n)",
    "y": " baltalama"
  },
  {
    "x": "bitransfer",
    "y": " aktarmalı"
  },
  {
    "x": "bituwerzînbirnayîş (n)",
    "y": " baltalama"
  },
  {
    "x": "bituwerzîntêqesnayîş (n)",
    "y": " baltalama"
  },
  {
    "x": "bivakum, -e",
    "y": " vakumlu"
  },
  {
    "x": "bivargozîkutiş (n)",
    "y": " balyozlama"
  },
  {
    "x": "bivargozîkuwayîş (n)",
    "y": " balyozlama"
  },
  {
    "x": "biveş (n)",
    "y": " ateşli (ateşi olan)"
  },
  {
    "x": "bivo (n)",
    "y": " ağaç sakızı (bazı bitkilerin salgısı), reçine, zamk"
  },
  {
    "x": "biwade, -ye",
    "y": " vadeli"
  },
  {
    "x": "biwender bîyene",
    "y": " b. bander bîyene"
  },
  {
    "x": "biwender kerdene",
    "y": " b. bander kerdene"
  },
  {
    "x": "biwenderbeyîş (n)",
    "y": " b. banderbîyayîş (n)"
  },
  {
    "x": "biwesîqe",
    "y": " vesikalı"
  },
  {
    "x": "biweşîye",
    "y": " esenlikle"
  },
  {
    "x": "bixar (n)",
    "y": " b. boxar (n)"
  },
  {
    "x": "bixarin",
    "y": " b. boxarin, -e"
  },
  {
    "x": "bixaye, -ye",
    "y": " amaçlı"
  },
  {
    "x": "bixeyr, -e",
    "y": " hayırlı\r\n~Bixeyr şo-bê. (Güle güle git, güle güle gel.)\r\n~Bixeyr û silamet a şo. Güle güle (git).\r\n~ma bixeyr dî: merhaba\r\n~Ti bixeyr ameyî/ameya. (Hoş geldin.)\r\n~Ti bixeyr ameyî/ameya, silamet. (Hoş geldin.)\r\n~Wa bixeyr bo. (Hayırlı olsun.)"
  },
  {
    "x": "ma bixeyr dî",
    "y": " merhaba"
  },
  {
    "x": "bixêr",
    "y": " b. bixeyr"
  },
  {
    "x": "ma bixêr",
    "y": " b. ma bixeyr dî"
  },
  {
    "x": "ma bixêr dî",
    "y": " b. ma bixeyr dî"
  },
  {
    "x": "bixoradayîş (n)",
    "y": " b. xoradayîş (n)"
  },
  {
    "x": "biz (m)",
    "y": " b. bize (m)"
  },
  {
    "x": "bize (m)",
    "y": " keçi"
  },
  {
    "x": "bizehmetî",
    "y": " bata çıka (zr)¸ güç bela (zr)¸ güçlükle (zr), zorlukla (zr), zor (zr), çekişmeli (çekişmeye yol açan)"
  },
  {
    "x": "bizehmetîye",
    "y": " bata çıka (zr), güç bela (zr), güçlükle (zr), zorlukla (zr), zor (zr), çekişmeli (çekişmeye yol açan)"
  },
  {
    "x": "bizek, -e",
    "y": " b. bizêk, -e"
  },
  {
    "x": "bizelalkerdiş (n)",
    "y": " açıklamalı, izahlı"
  },
  {
    "x": "bizerar, -e",
    "y": " zararlı"
  },
  {
    "x": "bizerpe, -ye",
    "y": " afili"
  },
  {
    "x": "bizêk",
    "y": " b. bizêk, -e"
  },
  {
    "x": "bizêk, -e",
    "y": " oğlak\r\n~bizêka bêwexte (m): erken doğan dişi oğlak\r\n~bizêka helîye (m): erken doğan dişi oğlak\r\n~bizêka hîlî (m): erken doğan dişi oğlak\r\n~bizêka hîtike (m): erken doğan dişi oğlak\r\n~bizêko bêwext (n): erken doğan erkek oğlak\r\n~bizêko helî (n): erken doğan erkek oğlak\r\n~bizêko hîleyin (n): erken doğan erkek oğlak\r\n~bizêko hîlî (n): erken doğan erkek oğlak\r\n~bizêko hîtik (n): erken doğan erkek oğlak"
  },
  {
    "x": "bizêk ardene",
    "y": " oğlak doğurmak, oğlaklamak"
  },
  {
    "x": "bizêk estene",
    "y": " b. bizêk eştene"
  },
  {
    "x": "bizêk eştene",
    "y": " (keçi için) düşük yapmak"
  },
  {
    "x": "bizêka bêwaxte (m)",
    "y": " b. bizêka bêwexte (m)"
  },
  {
    "x": "bizêka bêwexte (m)",
    "y": " erken doğan dişi oğlak"
  },
  {
    "x": "bizêka helîye (m)",
    "y": " erken doğan dişi oğlak"
  },
  {
    "x": "bizêka hîleyine (m)",
    "y": " erken doğan dişi oğlak"
  },
  {
    "x": "bizêka hîlî (m)",
    "y": " erken doğan dişi oğlak"
  },
  {
    "x": "bizêka hîtike (m)",
    "y": " erken doğan dişi oğlak"
  },
  {
    "x": "bizêke ardene",
    "y": " oğlak doğurmak, oğlaklamak "
  },
  {
    "x": "bizêko bêwaxt (n)",
    "y": " b. bizêko bêwext (n) "
  },
  {
    "x": "bizêko bêwext (n)",
    "y": " erken doğan erkek oğlak"
  },
  {
    "x": "bizêko helî (n)",
    "y": " erken doğan erkek oğlak"
  },
  {
    "x": "bizêko hîleyin (n)",
    "y": " erken doğan erkek oğlak"
  },
  {
    "x": "bizêko hîlî (n)",
    "y": " erken doğan erkek oğlak"
  },
  {
    "x": "bizêko hîtik (n)",
    "y": " erken doğan erkek oğlak"
  },
  {
    "x": "bizêkwan, -e",
    "y": " oğlak çobanı"
  },
  {
    "x": "bizêkwanî (m)",
    "y": " oğlak çobanlığı"
  },
  {
    "x": "bizêkwanîye (m)",
    "y": " oğlak çobanlığı"
  },
  {
    "x": "bizêwûn",
    "y": " b. bizêkwan, -e"
  },
  {
    "x": "bizêwûnê (m)",
    "y": " b. bizêkwanî (m), bizêkwanîye (m)"
  },
  {
    "x": "bizi (m)",
    "y": " b. bize (m)"
  },
  {
    "x": "bizik, -e",
    "y": " b. bizêk, -e"
  },
  {
    "x": "bizike (m)",
    "y": " b. bijike (m)"
  },
  {
    "x": "bizin (n)",
    "y": " b. bizine (n)"
  },
  {
    "x": "bizine (n)",
    "y": " keçi soyundan olan hayvanların genel adı"
  },
  {
    "x": "bizir-I (m)",
    "y": " b. pizrike (m)"
  },
  {
    "x": "bizir-II (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "biziya (m)",
    "y": " b. bizêke (m)"
  },
  {
    "x": "bizî",
    "y": " b. bizêk (n)"
  },
  {
    "x": "bizîk, -i",
    "y": " b. bizêk, -e"
  },
  {
    "x": "bizîye (n)",
    "y": " b. bizêk (n)"
  },
  {
    "x": "bizîye ardene",
    "y": " b. bizêk/bizêke ardene"
  },
  {
    "x": "bizmal (n)",
    "y": " b. bismar (n)"
  },
  {
    "x": "bizmal kerdene",
    "y": " b. bismar kerdene"
  },
  {
    "x": "bizmar (n)",
    "y": " b. bismar (n)"
  },
  {
    "x": "bizmar ... de kutene",
    "y": " b. bismar ... de kutene"
  },
  {
    "x": "bizmar kerdene",
    "y": " b. bismar kerdene"
  },
  {
    "x": "bizmar kutene",
    "y": " b. bismar kutene"
  },
  {
    "x": "bizmar piro dayene",
    "y": " b. bismar ... ro dayene"
  },
  {
    "x": "bizme (n)",
    "y": " manşet (gömlek kolu için)"
  },
  {
    "x": "bizmik (n)",
    "y": " oğlak ve kuzu gibi hayvanların annelerinin sütünü emmesini engellemek amacıyla ağızlarına takılan ağızlık"
  },
  {
    "x": "bizmiki (m)",
    "y": " b. pizrike (m)"
  },
  {
    "x": "bizompekutiş (n)",
    "y": " balyozlama"
  },
  {
    "x": "bizompekuwayîş (n)",
    "y": " balyozlama"
  },
  {
    "x": "bizor",
    "y": " bata çıka (zr), güç bela (zr)¸ güçlükle (zr), zorlukla (zr), zor (zr), çekişmeli (çekişmeye yol açan)"
  },
  {
    "x": "bizot (n)",
    "y": " b. bizote (m)"
  },
  {
    "x": "bizote (n)",
    "y": " köz"
  },
  {
    "x": "bizrê maseyan (zh)",
    "y": " balık yumurtası, havyar"
  },
  {
    "x": "bizriki (m)",
    "y": " b. pizrike (m)"
  },
  {
    "x": "bizruki (m)",
    "y": " b. pizrike (m)"
  },
  {
    "x": "bizuate (n)",
    "y": " b. bizote (n)"
  },
  {
    "x": "bizya (m)",
    "y": " b. bizêke (m)"
  },
  {
    "x": "bizye (n)",
    "y": " b. bizêk (n)"
  },
  {
    "x": "bizye ardene",
    "y": " b. bizêk/bizêke ardene"
  },
  {
    "x": "bizyewan",
    "y": " b. bizêkwan, -e"
  },
  {
    "x": "bizyewûn",
    "y": " b. bizêkwan, -e"
  },
  {
    "x": "bizyewûnê (m)",
    "y": " b. bizêkwanî (m), bizêkwanîye (m)"
  },
  {
    "x": "bizyêk (n)",
    "y": " b. bizêk (n)"
  },
  {
    "x": "bî-I (n)",
    "y": " b. bîn (n)"
  },
  {
    "x": "bî-II (n)",
    "y": " b. babî (n)"
  },
  {
    "x": "bî-III (vr)",
    "y": " b. bê-I (vr)"
  },
  {
    "x": "bîber (n)",
    "y": " biber"
  },
  {
    "x": "bîberin, -e",
    "y": " biberli"
  },
  {
    "x": "bîbik (m)",
    "y": " b. bîbike (m) "
  },
  {
    "x": "bîbike (m)",
    "y": " gözbebeği"
  },
  {
    "x": "bîbika çimî (m)",
    "y": " gözbebeği"
  },
  {
    "x": "bîbiki (m)",
    "y": " b. bîbike (m) "
  },
  {
    "x": "bîblîyograf, -e",
    "y": " bibliyograf"
  },
  {
    "x": "bîblîyografîk, -e",
    "y": " bibliyografik"
  },
  {
    "x": "bîblîyografya (m)",
    "y": " bibliyografi, kaynakça, bibliyografya"
  },
  {
    "x": "bîçere (m)",
    "y": " biçerdöver "
  },
  {
    "x": "bîçeri (m)",
    "y": " b. bîçere (m)"
  },
  {
    "x": "bîdet (n)",
    "y": " bid’at"
  },
  {
    "x": "bîdon (n)",
    "y": " bidon"
  },
  {
    "x": "bîdonê awe (n)",
    "y": " su bidonu"
  },
  {
    "x": "bîdrok (n)",
    "y": " b. bêdrok (n)"
  },
  {
    "x": "bîdûn (m)",
    "y": " b. bîdon (n)"
  },
  {
    "x": "bîdûnê awk (m)",
    "y": " b. bîdonê awe (n)"
  },
  {
    "x": "bîeneseb",
    "y": " b. bêneseb, -e"
  },
  {
    "x": "bîenesebê (m)",
    "y": " b. bênesebîye (m)"
  },
  {
    "x": "bîetom, -e",
    "y": " b. bêtehm, -e"
  },
  {
    "x": "bîftek (n)",
    "y": " biftek"
  },
  {
    "x": "bîh bi ... bîyene",
    "y": " b. bih bi ... bîyene"
  },
  {
    "x": "bîhêş, -e",
    "y": " b. bêhiş, -e"
  },
  {
    "x": "bîhêş bîyayîne",
    "y": " b. bêhiş bîyene"
  },
  {
    "x": "bîhêş kerdene",
    "y": " b. bêhiş kerdene"
  },
  {
    "x": "bîhêşey (m)",
    "y": " b. bêhişîye (m)"
  },
  {
    "x": "bîhêşê (m)",
    "y": " b. bêhişî (m)"
  },
  {
    "x": "bîhne (n)",
    "y": " b. bîne (m)"
  },
  {
    "x": "bîhne ci kewtene",
    "y": " b. bîne ci kewtene"
  },
  {
    "x": "bîhne çikîyayene",
    "y": " b. bîne çikîyayene"
  },
  {
    "x": "bîhne dayene",
    "y": " b. bîne dayene"
  },
  {
    "x": "bîhne guretene",
    "y": " b. bîne girewtene"
  },
  {
    "x": "bîhne kerdene",
    "y": " b. bîne kerdene"
  },
  {
    "x": "bîhne ro ... çikîyayene",
    "y": " b. bîne ro ... çikîyayene"
  },
  {
    "x": "bîhnecikewtiş (n)",
    "y": " b. bînecikewtiş (n)"
  },
  {
    "x": "bîhneguretis (n)",
    "y": " b. bînegirewtiş (n)"
  },
  {
    "x": "bîhnekerdis (n)",
    "y": " b. bînekerdiş (n)"
  },
  {
    "x": "bîj kerdene",
    "y": " b. bêz kerdene"
  },
  {
    "x": "bîjkerdiş (n)",
    "y": " b. bêzkerdiş (n)"
  },
  {
    "x": "bîkînî (zh)",
    "y": " bikini"
  },
  {
    "x": "bîlaheq",
    "y": " nedensiz, sebepsiz"
  },
  {
    "x": "bîlahî",
    "y": " b. bîllayî"
  },
  {
    "x": "bîlan, -e",
    "y": " ukala"
  },
  {
    "x": "bîlanço (n)",
    "y": " bilanço"
  },
  {
    "x": "bîlaq",
    "y": " b. bîlaheq"
  },
  {
    "x": "bîlaqederî",
    "y": " nedensiz, sebepsiz"
  },
  {
    "x": "bîlardo (n)",
    "y": " bilardo"
  },
  {
    "x": "bîlasa",
    "y": " b. bîlhesa"
  },
  {
    "x": "bîlaseb",
    "y": " b. bîlasebeb"
  },
  {
    "x": "bîlasebe",
    "y": " b. bîlasebeb"
  },
  {
    "x": "bîlasebeb",
    "y": " nedensiz, sebepsiz, beyhude"
  },
  {
    "x": "bîlaseve",
    "y": " b. bîlasebeb"
  },
  {
    "x": "bîlasevet",
    "y": " b. bîlasebeb"
  },
  {
    "x": "bîlasevev",
    "y": " b. bîlasebeb"
  },
  {
    "x": "bîlasewe",
    "y": " b. bîlasebeb"
  },
  {
    "x": "bîlasewet",
    "y": " b. bîlasebeb"
  },
  {
    "x": "bîlat (n)",
    "y": " b. bîlet (n)"
  },
  {
    "x": "bîlay",
    "y": " b. bîllayî"
  },
  {
    "x": "bîlayî",
    "y": " b. bîllayî"
  },
  {
    "x": "bîlazig (n)",
    "y": " b. bîlazik (n)"
  },
  {
    "x": "bîlazigin, -e",
    "y": " b. bîlazikin, -e"
  },
  {
    "x": "bîlazik (n)",
    "y": " bilezik"
  },
  {
    "x": "bîlazikin, -e",
    "y": " bilezikli"
  },
  {
    "x": "bîle-I",
    "y": " b. bîllayî"
  },
  {
    "x": "bîle-II",
    "y": " b. bira-II"
  },
  {
    "x": "bîleheq",
    "y": " b. bîlaheq"
  },
  {
    "x": "bîleseb",
    "y": " b. bîlasebeb"
  },
  {
    "x": "bîlesebe",
    "y": " b. bîlasebeb"
  },
  {
    "x": "bîlesebeb",
    "y": " b. bîlasebeb"
  },
  {
    "x": "bîlesew",
    "y": " b. bîlasebeb"
  },
  {
    "x": "bîlet (n)",
    "y": " bilet\r\n~bîletê şîyayîş û ameyîşî (n): gidiş-geliş bileti\r\n~bîletê şîyayîş-ameyîşî (n): gidiş-geliş bileti"
  },
  {
    "x": "bîletê şîyayîş û ameyîşî (n)",
    "y": " gidiş-geliş bileti"
  },
  {
    "x": "bîletê şîyayîş-ameyîşî (n)",
    "y": " gidiş-geliş bileti"
  },
  {
    "x": "bîlete (m)",
    "y": " b. bîlet (n)"
  },
  {
    "x": "bîletroş, -e",
    "y": " biletçi"
  },
  {
    "x": "bîley",
    "y": " b. bîllayî"
  },
  {
    "x": "bîlêt (n)",
    "y": " b. bîlet (n)"
  },
  {
    "x": "bîlganeyê çêyî",
    "y": " b. bîlganeyê keyeyî"
  },
  {
    "x": "bîlganeyê keyeyî",
    "y": " ailece"
  },
  {
    "x": "bîlhesa",
    "y": " özellikle, başta"
  },
  {
    "x": "bîlhessa",
    "y": " b. bîlhesa"
  },
  {
    "x": "bîlla",
    "y": " b. bîllayî"
  },
  {
    "x": "bîllah",
    "y": " b. bîllayî"
  },
  {
    "x": "bîllahî",
    "y": " b. bîllayî"
  },
  {
    "x": "bîllasebeb",
    "y": " b. bîlasebeb"
  },
  {
    "x": "bîllayî",
    "y": " billahi"
  },
  {
    "x": "bîlle",
    "y": " b. bîllayî"
  },
  {
    "x": "bîlleheq",
    "y": " b. bîlaheq "
  },
  {
    "x": "bîllehî",
    "y": " b. bîllayî"
  },
  {
    "x": "bîlley",
    "y": " b. bîllayî"
  },
  {
    "x": "bîlleyî",
    "y": " b. bîllayî"
  },
  {
    "x": "bîlli",
    "y": " b. bîllayî"
  },
  {
    "x": "bîlmuqabele",
    "y": " bilmukabele"
  },
  {
    "x": "bîlya (m)",
    "y": " bilye"
  },
  {
    "x": "bîn, -e",
    "y": " 1)öbür, diğer; ayrı \r\n~2)ertesi\r\n~roja bîne (m): ertesi gün \r\n~3)başka \r\n~4)ayrı"
  },
  {
    "x": "roja bîne (m)",
    "y": " ertesi gün "
  },
  {
    "x": "rojo bîn (n)",
    "y": " b. roja bîne (m)"
  },
  {
    "x": "bîna (m)",
    "y": " yapı, bina\r\n~bînaya abîdeyîye (m): anıtsal yapı"
  },
  {
    "x": "bînaya abîdeyîye (m)",
    "y": " anıtsal yapı"
  },
  {
    "x": "bînaya kargîre (m)",
    "y": " kârgir bina, kâgir bina"
  },
  {
    "x": "bînaya sînema (m)",
    "y": " sinema binası"
  },
  {
    "x": "bînaya pêroyîye (m)",
    "y": " kamusal yapı"
  },
  {
    "x": "bînaya qaçaxe (m)",
    "y": " kaçak yapı"
  },
  {
    "x": "bînaya rîskdare (m)",
    "y": " riskli yapı"
  },
  {
    "x": "bînaya tescîlkerda (m)",
    "y": " tescilli yapı"
  },
  {
    "x": "bînaya tescîlkerdîye (m)",
    "y": " tescilli yapı"
  },
  {
    "x": "bînaya umûmîye (m)",
    "y": " kamusal yapı"
  },
  {
    "x": "bînabîtor (n)",
    "y": " b. bînbîntirro (n)"
  },
  {
    "x": "bînat (n)",
    "y": " b. binyat (n)"
  },
  {
    "x": "bînate-I (n)",
    "y": " b. beyntar (n)"
  },
  {
    "x": "bînate-II (n)",
    "y": " b. berate (n)"
  },
  {
    "x": "bînati (n)",
    "y": " b. beyntar (n)"
  },
  {
    "x": "bînati xirab bîyene",
    "y": " b. beyntar xirab bîyene"
  },
  {
    "x": "bînatixirabbîyayîş (n)",
    "y": " b. beyntarxirabbîyayîş (n)"
  },
  {
    "x": "bînatîn",
    "y": " b. beyntarî, -ye"
  },
  {
    "x": "bînaya kîmyayî (m). kimyasal binası",
    "y": " kimyasal binası"
  },
  {
    "x": "bînaya trafoyî (m)",
    "y": " trafo binası"
  },
  {
    "x": "bînayet",
    "y": " b. binyat (n)"
  },
  {
    "x": "bînbînterr (n)",
    "y": " b. bînbîntirro (n)"
  },
  {
    "x": "bînbînterro (n)",
    "y": " b. bînbîntirro (n)"
  },
  {
    "x": "bînbîntirro (n)",
    "y": " bugünden sonraki dördüncü gün"
  },
  {
    "x": "bînbînturro (n)",
    "y": " b. bînbîntirro (n)"
  },
  {
    "x": "bîne (m)",
    "y": " 1)koku \r\n~2)soluk, nefes"
  },
  {
    "x": "bîna xo girewtene",
    "y": " ara vermek"
  },
  {
    "x": "bîne ci kewtene",
    "y": " kokmak (kötü koku için), kokuşmak"
  },
  {
    "x": "bîne çikîyayene",
    "y": " soluğu kesilmek, soluğu tıkanmak, nefesi kesilmek, nefesi tıkanmak, nefesi tutulmak"
  },
  {
    "x": "bîne dayene",
    "y": " 1)soluk vermek, nefes vermek \r\n~2)ara vermek"
  },
  {
    "x": "bîne girewtene",
    "y": " 1)koku almak, koku duymak \r\n~2)soluk almak, nefes almak"
  },
  {
    "x": "bîne girotene",
    "y": " b. bîna girewtene"
  },
  {
    "x": "bîne guretene",
    "y": " b. bîna girewtene"
  },
  {
    "x": "bîne kerdene",
    "y": " koklamak"
  },
  {
    "x": "bîne ro ... çikîyayene",
    "y": " soluğu kesilmek, soluğu tıkanmak, nefesi kesilmek, nefesi tıkanmak, nefesi tutulmak"
  },
  {
    "x": "bînecikewtiş (n)",
    "y": " kokma (kötü koku için), kokuşma"
  },
  {
    "x": "bînegirewtiş (n)",
    "y": " 1)koku alma, koku duyma \r\n~2)solunum, teneffüs"
  },
  {
    "x": "bînekerdiş (n)",
    "y": " koklama"
  },
  {
    "x": "bînetoro (n)",
    "y": " b. bîntirro (n)"
  },
  {
    "x": "bîni (m)",
    "y": " b. bîne (m)"
  },
  {
    "x": "bîntar (n)",
    "y": " b. beyntar (n)"
  },
  {
    "x": "bîntar xirab bîyene",
    "y": " b. beyntar xirab bîyene"
  },
  {
    "x": "bîntare (n)",
    "y": " b. beyntar (n)"
  },
  {
    "x": "bîntarxirabbîyayîş (n)",
    "y": " b. beyntarxirabbîyayîş (n)"
  },
  {
    "x": "bînter (n)",
    "y": " b. bîntirro (n)"
  },
  {
    "x": "bînterr (n)",
    "y": " b. bîntirro (n)"
  },
  {
    "x": "bînterro (n)",
    "y": " b. bîntirro (n)"
  },
  {
    "x": "bîntirro (n)",
    "y": " bugünden sonraki üçüncü gün"
  },
  {
    "x": "bînturro (n)",
    "y": " b. bîntirro (n)"
  },
  {
    "x": "bîr-I (n)",
    "y": " kuyu\r\n~bîrê drenajî (n): akaçlama kuyusu, drenaj kuyusu\r\n~bîro keson (n): keson kuyu"
  },
  {
    "x": "bîrê drenajî (n)",
    "y": " akaçlama kuyusu, drenaj kuyusu"
  },
  {
    "x": "bîro keson (n)",
    "y": " keson kuyu"
  },
  {
    "x": "bîr-II (n)",
    "y": " b. bîro (n)"
  },
  {
    "x": "bîra (m)",
    "y": " bira"
  },
  {
    "x": "bîraroş, -e",
    "y": " biracı"
  },
  {
    "x": "bîrdirbîr (n)",
    "y": " birdirbir"
  },
  {
    "x": "bîrîye (m)",
    "y": " b. bêrî (m)"
  },
  {
    "x": "Bîrmanya (m)",
    "y": " Birmanya"
  },
  {
    "x": "bîro (n)",
    "y": " bugünden sonraki ikinci gün, öbür gün"
  },
  {
    "x": "bîsiklete (m)",
    "y": " b. bîsîklete (m)"
  },
  {
    "x": "bîsîklet (n)",
    "y": " b. bîsîklete (m)"
  },
  {
    "x": "bîsîklete (m)",
    "y": " bisiklet"
  },
  {
    "x": "bîsîkletkar, -e",
    "y": " bisikletçi"
  },
  {
    "x": "bîsîkletroş, -e",
    "y": " bisikletçi"
  },
  {
    "x": "bîsîkletsaz, -e",
    "y": " bisikletçi"
  },
  {
    "x": "bîskuvît (n)",
    "y": " bisküvi"
  },
  {
    "x": "bîskuwî (m)",
    "y": " b. bîskuvît (n)"
  },
  {
    "x": "Bîssao (n)",
    "y": " Bissau"
  },
  {
    "x": "bîste (m)",
    "y": " b. vîste (m)"
  },
  {
    "x": "bîşigi (m)",
    "y": " b. bêşige (m) "
  },
  {
    "x": "bîşî (m)",
    "y": " pişi"
  },
  {
    "x": "bîşîye (m)",
    "y": " pişi"
  },
  {
    "x": "Bîşkek (n)",
    "y": " Bişkek"
  },
  {
    "x": "bîwe (n)",
    "y": " b. bêwe (n)"
  },
  {
    "x": "bîwiki (m)",
    "y": " b. bîbike (m)"
  },
  {
    "x": "bîyar (n)",
    "y": " b. bîro (n)"
  },
  {
    "x": "bîyare (n)",
    "y": " b. beyar (n)"
  },
  {
    "x": "bîyaxkî, -ye",
    "y": " b. beyaxkî, -ye"
  },
  {
    "x": "bîyaya (m)",
    "y": " 1)olumlu \r\n~2)zengin, varlıklı"
  },
  {
    "x": "bîyaye (n)",
    "y": " 1)olumlu \r\n~2)zengin, varlıklı"
  },
  {
    "x": "bîyayene",
    "y": " b. bîyene"
  },
  {
    "x": "bîyayeni",
    "y": " b. bîyene"
  },
  {
    "x": "bîyayeyey (m)",
    "y": " b. bîyayeyî (m), bîyayeyîye (m)"
  },
  {
    "x": "bîyayeyî (m)",
    "y": " zenginlik, varlık, varlıklılık"
  },
  {
    "x": "bîyayeyîye (m)",
    "y": " zenginlik, varlık, varlıklılık"
  },
  {
    "x": "bîyayêne",
    "y": " b. bîyene"
  },
  {
    "x": "bîyayine",
    "y": " b. bîyene"
  },
  {
    "x": "bîyayîne",
    "y": " b. bîyene"
  },
  {
    "x": "bîyayîş (n)",
    "y": " 1)olma \r\n~2)doğum, doğuş\r\n~bîyayîşê Îsayî: İsa’nın doğumu"
  },
  {
    "x": "bîyayîş ra",
    "y": " doğuştan, fıtri, yaratılıştan\r\n~bîyayîş ra caravejîyayîya kuleyî/qorrikî: doğuştan kalça çıkıklığı"
  },
  {
    "x": "bîyayîş ra caravejîyayîya kuleyî/qorrikî",
    "y": " doğuştan kalça çıkıklığı"
  },
  {
    "x": "bîyayîşê Îsayî",
    "y": " İsa’nın doğumu"
  },
  {
    "x": "bîyayîye (m)",
    "y": " 1)olumlu \r\n~2)zengin, varlıklı"
  },
  {
    "x": "bîye-I (vr)",
    "y": " b. bê- (vr)"
  },
  {
    "x": "bîye-II (m)",
    "y": " b. boye-I (m)"
  },
  {
    "x": "bîye girotene",
    "y": " b. boye girewtene "
  },
  {
    "x": "bîyê xwe veradayene",
    "y": " b. boya xo veradayene"
  },
  {
    "x": "bîyedîn (n)",
    "y": " b. bêdîn (n)"
  },
  {
    "x": "bîyedînê (m)",
    "y": " b. bêdînî (m)"
  },
  {
    "x": "bîyeehlaq, -i",
    "y": " b. bêexlaq, -e"
  },
  {
    "x": "bîyeehlaq bîyene",
    "y": " b. bêexlaq bîyene"
  },
  {
    "x": "bîyeehlaqî (m)",
    "y": " b. bêexlaqî (m)"
  },
  {
    "x": "bîyeehlaqûne",
    "y": " b. bêexlaqane"
  },
  {
    "x": "bîyeesteri",
    "y": " b. bêestrî, -ye"
  },
  {
    "x": "bîyeesteriyî (m)",
    "y": " b. bêestrîyî (m)"
  },
  {
    "x": "bîyeesturî",
    "y": " b. bêestrî, -ye"
  },
  {
    "x": "bîyeesturîyî (m)",
    "y": " b. bêestrîyî (m)"
  },
  {
    "x": "bîyefeydi (m)",
    "y": " b. bêfeyde, -ye"
  },
  {
    "x": "bîyehor (n)",
    "y": " b. bêhewr (n)"
  },
  {
    "x": "bîyekar",
    "y": " b. bêkar"
  },
  {
    "x": "bîyekes",
    "y": " b. bêkes"
  },
  {
    "x": "bîyel (n)",
    "y": " b. bol-II (n) "
  },
  {
    "x": "bîyene",
    "y": " 1)olmak\r\n~2)doğmak"
  },
  {
    "x": "bibo nêbo",
    "y": " hiç olmazsa, hiç değilse"
  },
  {
    "x": "bîyeneseb",
    "y": " b. bêneseb, -e"
  },
  {
    "x": "bîyenesebê (m)",
    "y": " b. bênesebîye (m)"
  },
  {
    "x": "bîyeqûnî kerdene",
    "y": " b. boqanî kerdene"
  },
  {
    "x": "bîyesol",
    "y": " b. bêsol, -e"
  },
  {
    "x": "bîyesual",
    "y": " b. bêsol, -e"
  },
  {
    "x": "bîyetom, -e",
    "y": " b. bêtehm, -e"
  },
  {
    "x": "bîyexeyret, -e",
    "y": " b. bêxîret, -e"
  },
  {
    "x": "bîyexeyretê (m)",
    "y": " b. bêxîretî (m)"
  },
  {
    "x": "bîyeyarî",
    "y": " b. bêyarî"
  },
  {
    "x": "bîyezûn",
    "y": " b. bêziwan, -e"
  },
  {
    "x": "bîyiş (n)",
    "y": " b. bîyayîş (n)"
  },
  {
    "x": "bîyîş (n)",
    "y": " b. bîyayîş (n)"
  },
  {
    "x": "bîyn (n)",
    "y": " b. bîn (n)"
  },
  {
    "x": "bîyogaz (n)",
    "y": " biyogaz"
  },
  {
    "x": "bîyografî (m)",
    "y": " biyografi, hayat hikâyesi hayat öyküsü, tercümeihâl, yaşam öyküsü"
  },
  {
    "x": "bîyografîk, -e",
    "y": " biyografik"
  },
  {
    "x": "bîyoke (m)",
    "y": " b. beye (m)"
  },
  {
    "x": "bîyolog, -e",
    "y": " biyolog, dirim bilimci"
  },
  {
    "x": "bîyolojî (n)",
    "y": " biyoloji, dirim bilimi"
  },
  {
    "x": "bîyolojîk, -e",
    "y": " biyolojik, dirim bilimsel"
  },
  {
    "x": "bîyometrî (n)",
    "y": " biyometri"
  },
  {
    "x": "bîyor (n)",
    "y": " b. bîro (n)"
  },
  {
    "x": "bîyosfer (n)",
    "y": " bîyosfer"
  },
  {
    "x": "bîz (n)",
    "y": " iştah"
  },
  {
    "x": "bîzon (n)",
    "y": " bizon"
  },
  {
    "x": "blof (n)",
    "y": " blöf"
  },
  {
    "x": "blok (n)",
    "y": " blok "
  },
  {
    "x": "blokaj (n)",
    "y": " blokaj "
  },
  {
    "x": "bloke, -ye",
    "y": " bloke"
  },
  {
    "x": "bluz (n)",
    "y": " bluz"
  },
  {
    "x": "blûcîn (n)",
    "y": " blucin"
  },
  {
    "x": "bo-I (n)",
    "y": " b. ban (n)"
  },
  {
    "x": "bo-II (m)",
    "y": " b. boye-I (m)"
  },
  {
    "x": "bo û pixe",
    "y": " b. boy û buxare (m)"
  },
  {
    "x": "bobelat (n)",
    "y": " b. bobelate (m)"
  },
  {
    "x": "bobelate (m)",
    "y": " 1)felaket, yıkım \r\n~2)skandal (mecazi)"
  },
  {
    "x": "bobilate (m)",
    "y": " b. bobelate (m)"
  },
  {
    "x": "bobîn (n)",
    "y": " bobin"
  },
  {
    "x": "bobînaj (n)",
    "y": " bobinaj"
  },
  {
    "x": "boblate (m)",
    "y": " b. bobelate (m)"
  },
  {
    "x": "bocî, -ye",
    "y": " küçük yaştaki köpek "
  },
  {
    "x": "boç (m)",
    "y": " b. boçe (m)"
  },
  {
    "x": "boçe (m)",
    "y": " 1)kuyruk \r\n~2)sap (araç için)\r\n~boça vaşturî (m): orak sapı"
  },
  {
    "x": "boça solî (m)",
    "y": " ayakkabıyı giyerken tutup çekmek için kullanılan -daha çok eski ayakkabılarda bulunan- ayakkabının arkasındaki çıkıntılı parça"
  },
  {
    "x": "boçe kerdene",
    "y": " kösnümek (dişi köpek vb. için)"
  },
  {
    "x": "boça qaluçe (m)",
    "y": " orak sapı"
  },
  {
    "x": "boça qalûçe (m)",
    "y": " b. boça qaluçe (m)"
  },
  {
    "x": "boça qalûnçe (m)",
    "y": " b. boça qaluçe (m)"
  },
  {
    "x": "boça vaştirî (m)",
    "y": " b. boça vaşturî (m)"
  },
  {
    "x": "boça vaşturî (m)",
    "y": " orak sapı"
  },
  {
    "x": "boça zengenî (n)",
    "y": " kazma sapı"
  },
  {
    "x": "boçey vaştirî (m)",
    "y": " b. boça vaşturî (m)"
  },
  {
    "x": "boçekerdiş (n)",
    "y": " kösnüme (dişi köpek vb. için)"
  },
  {
    "x": "boçi (m)",
    "y": " b. boçe (m)"
  },
  {
    "x": "boçik-I (m)",
    "y": " 1)boçe (m) \r\n~2)b. boçike (m)"
  },
  {
    "x": "boçik-II (n)",
    "y": " kuyruksokumu, omurganın alt ucu"
  },
  {
    "x": "boçikdekewtiş (n)",
    "y": " bel kayması"
  },
  {
    "x": "boçike (m)",
    "y": " kuyruk"
  },
  {
    "x": "boçiki (m)",
    "y": " b. boçike (m)"
  },
  {
    "x": "boçqol, -e",
    "y": " kuyruğu kesik hayvan  "
  },
  {
    "x": "bodrim (n)",
    "y": " b. bodrum (n)"
  },
  {
    "x": "bodrum (n)",
    "y": " bodrum"
  },
  {
    "x": "boe (m)",
    "y": " b. boye-I (m)"
  },
  {
    "x": "boeç (m)",
    "y": " b. boçe (m)"
  },
  {
    "x": "boeçi (m)",
    "y": " b. boçe (m)"
  },
  {
    "x": "bog (n)",
    "y": " tarla kenarındaki sürülmemiş kısım"
  },
  {
    "x": "Boglan (n)",
    "y": " b. Bongilan (n)"
  },
  {
    "x": "Bogota (m)",
    "y": " Bogota"
  },
  {
    "x": "bohem, -e",
    "y": " bohem"
  },
  {
    "x": "bojey (zh)",
    "y": " b. mujeyî (zh)"
  },
  {
    "x": "bojî (n)",
    "y": " b. bazî (n)"
  },
  {
    "x": "bojî, -ye-I",
    "y": " b. bocî, -ye"
  },
  {
    "x": "bojî-II (zh)",
    "y": " b. bacî (zh)"
  },
  {
    "x": "bojê ... amayene",
    "y": " b. bacê ... ameyene"
  },
  {
    "x": "bojê ... ardene",
    "y": " b. bacê ... ardene"
  },
  {
    "x": "bojîyaîs (n)",
    "y": " b. bacîyayîş (n)"
  },
  {
    "x": "bojîyayene",
    "y": " b. bacîyayene"
  },
  {
    "x": "bojîyayêne",
    "y": " b. bacîyayene"
  },
  {
    "x": "bojîyayîs (n)",
    "y": " b. bacîyayîş (n)"
  },
  {
    "x": "bojîyayîş (n)",
    "y": " b. bacîyayîş (n)"
  },
  {
    "x": "bojnayene",
    "y": " b. bacnayene "
  },
  {
    "x": "bojnayêne",
    "y": " b. bacnayene"
  },
  {
    "x": "boju (n)",
    "y": " b. bazî (n)"
  },
  {
    "x": "bok û beran",
    "y": " yiğit"
  },
  {
    "x": "boka",
    "y": " inşallah, Allah vere de, Allah vere"
  },
  {
    "x": "bokana",
    "y": " b. boka"
  },
  {
    "x": "bokanî",
    "y": " b. boka"
  },
  {
    "x": "bokarê",
    "y": " b. boka"
  },
  {
    "x": "boko",
    "y": " b. boka"
  },
  {
    "x": "bokona",
    "y": " b. boka"
  },
  {
    "x": "boks (n)",
    "y": " boks "
  },
  {
    "x": "boksor, -e",
    "y": " boksör"
  },
  {
    "x": "boksorîye (m)",
    "y": " boksörlük"
  },
  {
    "x": "bol, -e-I",
    "y": " 1)çok \r\n~2)bol"
  },
  {
    "x": "bol bîyene",
    "y": " artmak, çoğalmak"
  },
  {
    "x": "bol kerdene",
    "y": " artırmak"
  },
  {
    "x": "bol kerdene",
    "y": " çoğaltmak"
  },
  {
    "x": "bolê bolan",
    "y": " azami"
  },
  {
    "x": "bol-II (n)",
    "y": " küçükbaş hayvan sürüsü"
  },
  {
    "x": "bol bîyene",
    "y": " toplanmak"
  },
  {
    "x": "bol kerdene",
    "y": " toplamak"
  },
  {
    "x": "bol-III (n)",
    "y": " dişi memelilerin dişilik organı"
  },
  {
    "x": "bolage",
    "y": " çok fazla"
  },
  {
    "x": "bolagi",
    "y": " b. bolage"
  },
  {
    "x": "bolate (m)",
    "y": " b. bobelate (m)"
  },
  {
    "x": "bolbîyayîş-I (n)",
    "y": " artma, çoğalma"
  },
  {
    "x": "bolbîyayîş-II (n)",
    "y": " toplanma"
  },
  {
    "x": "boldircane (m)",
    "y": " b. balcane (m)"
  },
  {
    "x": "boldurzan (n)",
    "y": " b. balcane (m)"
  },
  {
    "x": "bole (m)",
    "y": " b. bol-II (n)"
  },
  {
    "x": "boley (m)",
    "y": " b. bolîye (m)"
  },
  {
    "x": "bolê (m)",
    "y": " b. bolî (m)"
  },
  {
    "x": "boli-I (m)",
    "y": " b. bole-I (m) "
  },
  {
    "x": "boli-II",
    "y": " b. bellî, -ye"
  },
  {
    "x": "bolin",
    "y": " b. bol, -e-I"
  },
  {
    "x": "bolî (m)",
    "y": " bolluk"
  },
  {
    "x": "Bolîvya (m)",
    "y": " Bolivya"
  },
  {
    "x": "bolîye-I (m)",
    "y": " bolluk"
  },
  {
    "x": "bolîye-II (m)",
    "y": " b. belû-I (m)"
  },
  {
    "x": "bolker (n)",
    "y": " çoğaltıcı"
  },
  {
    "x": "bolkerdiş-I (n)",
    "y": " artırma, çoğaltma"
  },
  {
    "x": "bolkerdiş-II (n)",
    "y": " toplama"
  },
  {
    "x": "bolkî",
    "y": " 1)çoğunluk \r\n~2)genellikle"
  },
  {
    "x": "boll (n)",
    "y": " b. bol-II (n) "
  },
  {
    "x": "bollagi",
    "y": " b. bolage"
  },
  {
    "x": "bolmîs bîyayene",
    "y": " b. bolmîş bîyene"
  },
  {
    "x": "bolmîsbîyayîs (n)",
    "y": " b. bolmîşbîyayîş (n)"
  },
  {
    "x": "bolmîş bîyayene",
    "y": " b. bolmîş bîyene"
  },
  {
    "x": "bolmîş bîyene",
    "y": " bayılmak"
  },
  {
    "x": "bolmîşbîyayîş (n)",
    "y": " bayılma"
  },
  {
    "x": "bolometre (n)",
    "y": " ışınımölçer, bolometre"
  },
  {
    "x": "bolşevîk, -e",
    "y": " Bolşevik"
  },
  {
    "x": "bolşevîkîye (m)",
    "y": " Bolşeviklik, Bolşevizm"
  },
  {
    "x": "bolşevîzm (n)",
    "y": " Bolşeviklik, Bolşevizm"
  },
  {
    "x": "boltize (m)",
    "y": " b. balduze (m)"
  },
  {
    "x": "bolu",
    "y": " b. bellî, -ye"
  },
  {
    "x": "bom, -e",
    "y": " deli, çılgın"
  },
  {
    "x": "bom bîyayene",
    "y": " b. bom bîyene"
  },
  {
    "x": "bom bîyene",
    "y": " delirmek, çılgına dönmek, çılgınlaşmak"
  },
  {
    "x": "bom kerdene",
    "y": " delirtmek, çıldırtmak"
  },
  {
    "x": "bomane",
    "y": " çılgınca, delice"
  },
  {
    "x": "bomba (m)",
    "y": " bomba\r\n~bombaya atome (m): atom bombası\r\n~bombaya destî (m): el bombası "
  },
  {
    "x": "bomba bîyene",
    "y": " bombalanmak"
  },
  {
    "x": "bomba dekerdene",
    "y": " bomba koymak "
  },
  {
    "x": "bomba eştene",
    "y": " bomba atmak "
  },
  {
    "x": "bomba kerdene",
    "y": " bombalamak "
  },
  {
    "x": "bomba teqnayene",
    "y": " bomba patlatmak"
  },
  {
    "x": "bombaya atome (m)",
    "y": " atom bombası"
  },
  {
    "x": "bombaya destî (m)",
    "y": " el bombası "
  },
  {
    "x": "bombaya hîdrojene (m)",
    "y": " hidrojen bombası "
  },
  {
    "x": "bombaeştox, -e",
    "y": " bombacı"
  },
  {
    "x": "bombarduman",
    "y": " bombardıman"
  },
  {
    "x": "bombarduman bîyene",
    "y": " bombardıman edilmek "
  },
  {
    "x": "bombarduman kerdene",
    "y": " bombardıman etmek "
  },
  {
    "x": "bombaviraştox, -e",
    "y": " bombacı"
  },
  {
    "x": "bombîyayîş (n)",
    "y": " çıldırma, çıldırış, çılgınlaşma, delirme, deliriş"
  },
  {
    "x": "bome (n)",
    "y": " b. bamya (m)"
  },
  {
    "x": "bomênî (m)",
    "y": " delilik"
  },
  {
    "x": "bomîye (m)",
    "y": " delilik, çılgınlık"
  },
  {
    "x": "bomîye kerdene",
    "y": " çılgınlık etmek"
  },
  {
    "x": "bomkerdiş (n)",
    "y": " çıldırtma, delirtme"
  },
  {
    "x": "bomlerze (m)",
    "y": " deprem, zelzele"
  },
  {
    "x": "bomlerzi (m)",
    "y": " b. bomlerze (m)"
  },
  {
    "x": "bomye (n)",
    "y": " b. bamya (m)"
  },
  {
    "x": "bon (n)",
    "y": " b. ban (n)"
  },
  {
    "x": "bonê pêyi (n)",
    "y": " b. banê pey (n)"
  },
  {
    "x": "bono peyen (n)",
    "y": " b. bano peyên (n)"
  },
  {
    "x": "bona (m)",
    "y": " b. bana (m)"
  },
  {
    "x": "bonc",
    "y": " b. panc"
  },
  {
    "x": "bonci (n)",
    "y": " b. bocî (n)"
  },
  {
    "x": "boncî (m)",
    "y": " b. bocîye (m)"
  },
  {
    "x": "bonder bîyene",
    "y": " b. bander bîyene"
  },
  {
    "x": "bonder kerdene",
    "y": " b. bander kerdene"
  },
  {
    "x": "bonderbeyîş (n)",
    "y": " b. banderbîyayîş (n)"
  },
  {
    "x": "bonderbîyayîş (n)",
    "y": " b. banderbîyayîş (n)"
  },
  {
    "x": "bonderkerdiş (n)",
    "y": " b. banderkerdiş (n)"
  },
  {
    "x": "bone-I (n)",
    "y": " bone (n)"
  },
  {
    "x": "bone-II (n)",
    "y": " b. bane (n)"
  },
  {
    "x": "bone û bonqilîz, -e",
    "y": " b. bane û banqilîz, -e"
  },
  {
    "x": "bonek (n)",
    "y": " b. banek (n)"
  },
  {
    "x": "bonekî (zh)",
    "y": " b. banekî (zh)"
  },
  {
    "x": "bonekî kay kerdene",
    "y": " b. banekî kay kerdene"
  },
  {
    "x": "bonekkey (n)",
    "y": " b. banikkay (n)"
  },
  {
    "x": "bonekkey key kerdene",
    "y": " b. banikkay kay kerdene"
  },
  {
    "x": "bonfîle (n)",
    "y": " bonfile"
  },
  {
    "x": "Bongilan (n)",
    "y": " Solhan"
  },
  {
    "x": "bongiri (m)",
    "y": " b. bangêre (m)"
  },
  {
    "x": "bonkila (m)",
    "y": " b. beqla (m)"
  },
  {
    "x": "bonko (n)",
    "y": " b. banku (n)"
  },
  {
    "x": "bonku (n)",
    "y": " b. banku (n)"
  },
  {
    "x": "bonkue (n)",
    "y": " b. banku (n)"
  },
  {
    "x": "bonkuwe (n)",
    "y": " b. banku (n)"
  },
  {
    "x": "bono (n)",
    "y": " bono "
  },
  {
    "x": "bonqa (m)",
    "y": " b. banka (m)"
  },
  {
    "x": "bonservîs (n)",
    "y": " bonservis"
  },
  {
    "x": "boqa ... warîştene",
    "y": " b. boqa ... weriştene (mecazi)"
  },
  {
    "x": "boqa ... weriştene",
    "y": "1)azgınlaşmak, azmak (cinsel arzudan ötürü) \r\n~2) (mecazi) şımarmak"
  },
  {
    "x": "boqa ... weriştiş",
    "y": " azgınlaşma (cinsel arzudan ötürü)"
  },
  {
    "x": "boqanî kerdene",
    "y": " dalga geçmek"
  },
  {
    "x": "boqs (n)",
    "y": " b. boks (n)"
  },
  {
    "x": "boqsor, -e",
    "y": " b. boksor, -e"
  },
  {
    "x": "boqsorey (m)",
    "y": " b. boksorîye (m)"
  },
  {
    "x": "boqsorê (m)",
    "y": " b. boksorîye (m)"
  },
  {
    "x": "bor (n)",
    "y": " b. bure (n)"
  },
  {
    "x": "Bor (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "borane (m)",
    "y": " güvercin"
  },
  {
    "x": "borani (m)",
    "y": " b. borane (m)"
  },
  {
    "x": "boranî (m)",
    "y": " borani"
  },
  {
    "x": "borayene",
    "y": " böğürmek"
  },
  {
    "x": "borayîş (n)",
    "y": " böğürme"
  },
  {
    "x": "bordro (n)",
    "y": " bordro"
  },
  {
    "x": "bordro kontrol",
    "y": " bordro kontrol"
  },
  {
    "x": "bore (n)",
    "y": " kurum (baca vb. için)"
  },
  {
    "x": "Bore (m)",
    "y": " inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "boregi (m)",
    "y": " b. boreke (m)"
  },
  {
    "x": "borek (n)",
    "y": " b. boreke (m)"
  },
  {
    "x": "boreke-I (m)",
    "y": " küçük bir karınca çeşidi "
  },
  {
    "x": "boreke-II (m)",
    "y": " börek"
  },
  {
    "x": "boreki (m)",
    "y": " b. boreke (m)"
  },
  {
    "x": "borekpewj, -e",
    "y": " börekçi"
  },
  {
    "x": "borekroş, -e",
    "y": " börekçi"
  },
  {
    "x": "borge (m)",
    "y": " aşırı tuzlu"
  },
  {
    "x": "borgeyî (m)",
    "y": " aşırı tuzluluk"
  },
  {
    "x": "borgeyîye (m)",
    "y": " aşırı tuzluluk"
  },
  {
    "x": "Bori (m)",
    "y": " b. Bore (m)"
  },
  {
    "x": "borî-I (m)",
    "y": " böğürtü"
  },
  {
    "x": "borî-II (m)",
    "y": " boru\r\n~borîya kalorîferî (m): kalorifer borusu\r\n~borîya zendine (m): dirsekli boru"
  },
  {
    "x": "borîya kalorîferî (m)",
    "y": " kalorifer borusu"
  },
  {
    "x": "borîya qanîferî (m)",
    "y": " b. borîya kalorîferî (m)"
  },
  {
    "x": "borîya soba (m)",
    "y": " soba borusu"
  },
  {
    "x": "borîya zendine (m)",
    "y": " dirsekli boru"
  },
  {
    "x": "borîyey karolîferî (m)",
    "y": " b. borîya kalorîferî (m)"
  },
  {
    "x": "borîcen, -e",
    "y": " borucu (boru çalan)"
  },
  {
    "x": "borîye-I (m)",
    "y": " böğürtü"
  },
  {
    "x": "borîye-II (m)",
    "y": " boru"
  },
  {
    "x": "bornoz (n)",
    "y": " bornoz"
  },
  {
    "x": "Boro (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "boroni (m)",
    "y": " b. borane (m)"
  },
  {
    "x": "borr (n)",
    "y": " b. bure (n)"
  },
  {
    "x": "borrayene",
    "y": " b. borayene"
  },
  {
    "x": "borrayîş (n)",
    "y": " b. borayîş (n)"
  },
  {
    "x": "borrî (m)",
    "y": " b. borî (m)"
  },
  {
    "x": "borrîya kalorîferî (m)",
    "y": " b. borîya kalorîferî (m)"
  },
  {
    "x": "borrîya qalorîferî (m)",
    "y": " b. borîya kalorîferî (m)"
  },
  {
    "x": "borrîye (m)",
    "y": " b. borî (m), borîye (m)"
  },
  {
    "x": "borsa (m)",
    "y": " borsa "
  },
  {
    "x": "borsaya heywanan (m)",
    "y": " hayvan borsası"
  },
  {
    "x": "borsa sîyay (m)",
    "y": " b. borsaya sîyaye (m)"
  },
  {
    "x": "borsaya sîyaye (m)",
    "y": " karaborsa"
  },
  {
    "x": "borsakar, -e",
    "y": " borsacı"
  },
  {
    "x": "borse (m)",
    "y": " b. borsa (m)"
  },
  {
    "x": "borûn (m)",
    "y": " b. borane (m)"
  },
  {
    "x": "bos, -e",
    "y": " b. veng, -e; tal, -e"
  },
  {
    "x": "bose (n)",
    "y": " saban zıvanası "
  },
  {
    "x": "bosi (n)",
    "y": " b. bose (n)"
  },
  {
    "x": "bosme (n)",
    "y": " b. basme-I (n)"
  },
  {
    "x": "bosmezeyî (m)",
    "y": " b. basmecî (m)"
  },
  {
    "x": "Bosna-Hersek",
    "y": " Bosna-Hersek"
  },
  {
    "x": "bostan (n)",
    "y": " bostan"
  },
  {
    "x": "boste (m)",
    "y": " karış"
  },
  {
    "x": "bosto (n)",
    "y": " b. bostan (n)"
  },
  {
    "x": "boston (n)",
    "y": " b. bostan (n)"
  },
  {
    "x": "bostû (n)",
    "y": " b. bostan (n)"
  },
  {
    "x": "bostûn (n)",
    "y": " b. bostan (n)"
  },
  {
    "x": "bot-I (n)",
    "y": " b. wet (n)"
  },
  {
    "x": "bot û cat",
    "y": " b. nat û wet"
  },
  {
    "x": "bot-II (n)",
    "y": " bot (uzun konçlu ayakkabı)"
  },
  {
    "x": "bota",
    "y": " öteye, o tarafa, karşı yakaya"
  },
  {
    "x": "botanîk (n)",
    "y": " bitkibilim, botanik"
  },
  {
    "x": "botanîknas, -e",
    "y": " bitkibilimci, botanikçi"
  },
  {
    "x": "bote (m)",
    "y": " bot (küçük gemi)\r\n~bota binawe (m): denizaltı "
  },
  {
    "x": "bota binawe (m)",
    "y": " denizaltı "
  },
  {
    "x": "botike (m)",
    "y": " b. bûtike (m)"
  },
  {
    "x": "botin (n)",
    "y": " gizli"
  },
  {
    "x": "Botswana (m)",
    "y": " Botswana "
  },
  {
    "x": "bover (n)",
    "y": " öte, o taraf, karşı yaka"
  },
  {
    "x": "boverên, -e",
    "y": " ötedeki"
  },
  {
    "x": "bowax (n)",
    "y": " b. boyax (n)"
  },
  {
    "x": "bowax kerdene",
    "y": " b. boyax kerdene"
  },
  {
    "x": "boyaxcî",
    "y": " b. boyaxker, -e"
  },
  {
    "x": "bowaxin, -e",
    "y": " b. boyaxin, -e"
  },
  {
    "x": "bowaxkerdîs",
    "y": " b. boyaxkerdiş (n)"
  },
  {
    "x": "bowkal (n)",
    "y": " b. bawkal (n)"
  },
  {
    "x": "bowli",
    "y": " b. bellî, -ye"
  },
  {
    "x": "bowli bîyayîne",
    "y": " b. bellî bîyene"
  },
  {
    "x": "bowlî",
    "y": " b. bellî, -ye"
  },
  {
    "x": "bowlî bîyayîne",
    "y": " b. bellî bîyene"
  },
  {
    "x": "bowlîng (n)",
    "y": " bovling"
  },
  {
    "x": "bowse (n)",
    "y": " b. bose (n)"
  },
  {
    "x": "boxar (n)",
    "y": " çakıl"
  },
  {
    "x": "boxarin, -e",
    "y": " çakıllı"
  },
  {
    "x": "boxaz (n)",
    "y": " boğaz"
  },
  {
    "x": "boxça (m)",
    "y": " bohça"
  },
  {
    "x": "boxçe (n)",
    "y": " b. boxça (m)"
  },
  {
    "x": "boxe (n)",
    "y": " boğa"
  },
  {
    "x": "boxeçor (n)",
    "y": " henüz öküz sayılmayan yaşça büyük tosun "
  },
  {
    "x": "boxi (n)",
    "y": " b. boxe (n)"
  },
  {
    "x": "boxt (n)",
    "y": " b. wext (n)"
  },
  {
    "x": "boxta-boxt",
    "y": " b. wext ra wext"
  },
  {
    "x": "boy (m)",
    "y": " b. boye-I (m)"
  },
  {
    "x": "boy ameyene",
    "y": " b. boye ameyene"
  },
  {
    "x": "boy ci kewtene",
    "y": " b. boye ci kewtene "
  },
  {
    "x": "boy ci vistene",
    "y": " b. boye ci vistene"
  },
  {
    "x": "boy dayene",
    "y": " b. boye dayene"
  },
  {
    "x": "boy dekewtene",
    "y": " b. boye dekewtene"
  },
  {
    "x": "boy devistene",
    "y": " b. boye devistene"
  },
  {
    "x": "boy di ternayene",
    "y": " b. boye deternayene"
  },
  {
    "x": "boy girotene",
    "y": " b. boye girewtene"
  },
  {
    "x": "boy kerdene",
    "y": " b. boye kerdene"
  },
  {
    "x": "boy û bexi",
    "y": " b. boy û buxare (m)"
  },
  {
    "x": "boy û buxare (m)",
    "y": " kötü koku"
  },
  {
    "x": "boy vetene",
    "y": " b. boye vetene"
  },
  {
    "x": "boy vilakerdene",
    "y": " b. boye vilakerdene"
  },
  {
    "x": "boy virsayene",
    "y": " b. boye virsikîyayene"
  },
  {
    "x": "boy virsnayene",
    "y": " b. boye virsiknayene"
  },
  {
    "x": "boy-bexi (m)",
    "y": " b. boy-buxare (m)"
  },
  {
    "x": "boy-buxare (m)",
    "y": " kötü koku"
  },
  {
    "x": "boy-germaxe (m)",
    "y": " kötü koku"
  },
  {
    "x": "boy-pixare (m)",
    "y": " b. boy-buxare (m)"
  },
  {
    "x": "boy-puxare (m)",
    "y": " b. boy-buxare (m)"
  },
  {
    "x": "boya Haqî",
    "y": " b. boya Heqî"
  },
  {
    "x": "boya Heqî",
    "y": " Allah için"
  },
  {
    "x": "boyameyîş (n)",
    "y": " b. boyeameyîş (n)"
  },
  {
    "x": "boyax (n)",
    "y": " boya, boyağ"
  },
  {
    "x": "boyax bîyene",
    "y": " boyalanmak, boyanmak"
  },
  {
    "x": "boyax kerdene",
    "y": " boyamak, boyalamak"
  },
  {
    "x": "boyaxbîyayîş (n)",
    "y": " boyalanma, boyanma"
  },
  {
    "x": "boyaxin, -e",
    "y": " boyalı"
  },
  {
    "x": "boyaxker, -e",
    "y": " boyacı"
  },
  {
    "x": "boyaxkerdiş (n)",
    "y": " boyama, boyalama"
  },
  {
    "x": "boyaxroş, -e",
    "y": " boyacı"
  },
  {
    "x": "boycikewtiş (n)",
    "y": " b. boyecikewtiş (n)"
  },
  {
    "x": "boycivistiş (n)",
    "y": " b. boyecivistiş (n)"
  },
  {
    "x": "boydayîş (n)",
    "y": " b. boyedayîş (n)"
  },
  {
    "x": "boydekewtiş (n)",
    "y": " b. boyedekewtiş (n)"
  },
  {
    "x": "boydevistiş (n)",
    "y": " b. boyedevistiş (n)"
  },
  {
    "x": "boyditernayîş (n)",
    "y": " b. boyedeternayîş (n)"
  },
  {
    "x": "boye-I (m)",
    "y": " 1)koku \r\n~2)nefes, soluk"
  },
  {
    "x": "boya ... vejîyayene",
    "y": " kokusu çıkmak"
  },
  {
    "x": "boya xo girewtene",
    "y": " ara vermek"
  },
  {
    "x": "boya xo veradayene",
    "y": " nefes vermek, soluk vermek"
  },
  {
    "x": "boya xo veredayene",
    "y": " b. boya xo veradayene"
  },
  {
    "x": "boye ameyene",
    "y": " kokmak"
  },
  {
    "x": "boye ci kewtene",
    "y": " kokmak (kötü koku için), kokuşmak"
  },
  {
    "x": "boye ci vistene",
    "y": " kokutmak"
  },
  {
    "x": "boye dayene",
    "y": " 1)kokmak \r\n~2)ara vermek"
  },
  {
    "x": "boye dekewtene",
    "y": " kokmak (kötü koku için), kokuşmak"
  },
  {
    "x": "boye deternayene",
    "y": " kokutmak"
  },
  {
    "x": "boye devistene",
    "y": " kokutmak"
  },
  {
    "x": "boye girewtene",
    "y": " 1)koku almak, koku duymak \r\n~2)nefes almak, soluk almak"
  },
  {
    "x": "boye girotene",
    "y": " b. boye girewtene"
  },
  {
    "x": "boye guretene",
    "y": " b. boye girewtene"
  },
  {
    "x": "boye kerdene",
    "y": " koklamak"
  },
  {
    "x": "boye veradayene",
    "y": " koku saçmak, koku salmak"
  },
  {
    "x": "boye veradîyayene",
    "y": " koku saçılmak"
  },
  {
    "x": "boye vetene",
    "y": " koku çıkarmak"
  },
  {
    "x": "boye vilakerdene",
    "y": " koku yaymak"
  },
  {
    "x": "boye virsikîdayene",
    "y": " b. boye virsiknayene"
  },
  {
    "x": "boye virsikîdîyayene",
    "y": " b. boye virsikîyayene"
  },
  {
    "x": "boye virsikîyayene",
    "y": " koku yayılmak"
  },
  {
    "x": "boye virsiknayene",
    "y": " koku yaymak"
  },
  {
    "x": "boye-II (n)",
    "y": " b. boyax (n)"
  },
  {
    "x": "boyeameyîş (n)",
    "y": " kokma"
  },
  {
    "x": "boyecikewtiş (n)",
    "y": " kokma (kötü koku için), kokuşma"
  },
  {
    "x": "boyecivistiş (n)",
    "y": " kokutma"
  },
  {
    "x": "boyedayîş (n)",
    "y": " kokma"
  },
  {
    "x": "boyedekewtiş (n)",
    "y": " kokma (kötü koku için), kokuşma"
  },
  {
    "x": "boyedeternayîş (n)",
    "y": " kokutma"
  },
  {
    "x": "boyedevistiş (n)",
    "y": " kokutma"
  },
  {
    "x": "boyegirewtiş (n)",
    "y": " 1)koku alma, koku duyma \r\n~2)solunum, teneffüs"
  },
  {
    "x": "boyekerdiş (n)",
    "y": " koklama"
  },
  {
    "x": "boyet (m)",
    "y": " b. boyete (m)"
  },
  {
    "x": "boyete (m)",
    "y": " bağ kulübesi"
  },
  {
    "x": "boyeveradayîş (n)",
    "y": " koku saçma, koku salma"
  },
  {
    "x": "boyeveradîyayîş (n)",
    "y": " koku saçılma"
  },
  {
    "x": "boyevetiş (n)",
    "y": " koku çıkarma"
  },
  {
    "x": "boyevilakerdiş (n)",
    "y": " koku yayma"
  },
  {
    "x": "boyevirsikîdayîş (n)",
    "y": " b. boyevirsiknayîş (n)"
  },
  {
    "x": "boyevirsikîdîyayîş (n)",
    "y": " b. boyevirsikîyayîş (n)"
  },
  {
    "x": "boyevirsikîyayîş (n)",
    "y": " koku yayılma"
  },
  {
    "x": "boyevirsiknayîş (n)",
    "y": " koku yayma"
  },
  {
    "x": "boygirotiş (n)",
    "y": " b. boyegirewtiş (n)"
  },
  {
    "x": "boyi (n)",
    "y": " b. boyin, -e"
  },
  {
    "x": "boyin, -e",
    "y": " kokulu"
  },
  {
    "x": "boyini (m)",
    "y": " b. boyin, -e"
  },
  {
    "x": "boyîn, -e",
    "y": " b. boyin, -e"
  },
  {
    "x": "boyîni (m)",
    "y": " b. boyin, -e"
  },
  {
    "x": "boykerdiş (n)",
    "y": " b. boyekerdiş (n)"
  },
  {
    "x": "boykot (n)",
    "y": " boykot"
  },
  {
    "x": "boyli",
    "y": " b. bellî, -ye"
  },
  {
    "x": "boyli bîyayîne",
    "y": " b. bellî bîyene"
  },
  {
    "x": "boyna",
    "y": " baştan sona (zr)"
  },
  {
    "x": "boyqot (n)",
    "y": " b. boykot (n)"
  },
  {
    "x": "boyvetiş (n)",
    "y": " b. boyevetiş (n)"
  },
  {
    "x": "boyvilakerdiş (n)",
    "y": " b. boyevilakerdiş (n)"
  },
  {
    "x": "boyvirsayîş (n)",
    "y": " b. boyevirsikîyayîş (n)"
  },
  {
    "x": "boyvirsnayîş (n)",
    "y": " b. boyevirsiknayîş (n)"
  },
  {
    "x": "boza (m)",
    "y": " boza"
  },
  {
    "x": "bozakar, -e",
    "y": " bozacı"
  },
  {
    "x": "bozaroş, -e",
    "y": " bozacı"
  },
  {
    "x": "bozî (n)",
    "y": " b. bazî (n)"
  },
  {
    "x": "bra (n)",
    "y": " b. bira-I (n)"
  },
  {
    "x": "brakîal pleksus (n)",
    "y": " brakial pleksus"
  },
  {
    "x": "branş (n)",
    "y": " branş"
  },
  {
    "x": "Bratîslava (m)",
    "y": " Bratislava"
  },
  {
    "x": "brawo",
    "y": " bravo"
  },
  {
    "x": "Brazzavîlle (m)",
    "y": " Brazzaville"
  },
  {
    "x": "Brezîl (n)",
    "y": " Brasilia"
  },
  {
    "x": "Brezîlya (m)",
    "y": " Brezilya"
  },
  {
    "x": "brisk (n)",
    "y": " b. bilusk (n)"
  },
  {
    "x": "brîç (n)",
    "y": " briç"
  },
  {
    "x": "brîfîng (n)",
    "y": " brifing"
  },
  {
    "x": "brokolî (m)",
    "y": " brokoli"
  },
  {
    "x": "bronşît (n)",
    "y": " bronşit"
  },
  {
    "x": "bronz (n)",
    "y": " bronz, tunç"
  },
  {
    "x": "bronze bîyene",
    "y": " bronzlaşmak"
  },
  {
    "x": "bronzebîyayîş (n)",
    "y": " bronzlaşma"
  },
  {
    "x": "broş (n)",
    "y": " broş"
  },
  {
    "x": "broşur (n)",
    "y": " broşür, risale"
  },
  {
    "x": "Bruksel (n)",
    "y": " Brüksel"
  },
  {
    "x": "bruşk (n)",
    "y": " b. bilusk (n)"
  },
  {
    "x": "brut, -e",
    "y": " brüt"
  },
  {
    "x": "bu (n)",
    "y": " b. babî (n)"
  },
  {
    "x": "buabî (n)",
    "y": " b. babî (n) "
  },
  {
    "x": "buarî (m)",
    "y": " b. borî (m)"
  },
  {
    "x": "buarîyê kalorîfêr (m)",
    "y": " b. borîya kalorîferî (m)"
  },
  {
    "x": "buarrî (m)",
    "y": " b. borî (m)"
  },
  {
    "x": "buarrîyê kalorîfêr (m)",
    "y": " b. borîya kalorîferî (m)"
  },
  {
    "x": "bublate (m)",
    "y": " b. bobelate (m) "
  },
  {
    "x": "budala, -ye",
    "y": " b. budela, -ye"
  },
  {
    "x": "budelayane",
    "y": " aptalca"
  },
  {
    "x": "budalayên (m)",
    "y": " b. budelayêne (m)"
  },
  {
    "x": "budalayî (m)",
    "y": " b. budelayî (m)"
  },
  {
    "x": "budalayîye (m)",
    "y": " b. budelayîye (m)"
  },
  {
    "x": "budela, -ye",
    "y": " abdal"
  },
  {
    "x": "budelayêne (m)",
    "y": " ahmaklık, aptallık, avanaklık, salaklık, budalalık"
  },
  {
    "x": "budelayî (m)",
    "y": " ahmaklık, aptallık, avanaklık, salaklık, budalalık"
  },
  {
    "x": "budelayîye (m)",
    "y": " 1)abdallık \r\n~2)ahmaklık, aptallık, avanaklık, salaklık, budalalık"
  },
  {
    "x": "bueç-I (m)",
    "y": " b. boçe (m)"
  },
  {
    "x": "bueç-II (n)",
    "y": " b. boçik (n)"
  },
  {
    "x": "bueçi (m)",
    "y": " b. boçe (m)"
  },
  {
    "x": "bueçik-I (m)",
    "y": " b. boçike (m)"
  },
  {
    "x": "bueçik-II (n)",
    "y": " b. boçik (n)"
  },
  {
    "x": "bueçikdekotiş (n)",
    "y": " b. boçikdekewtiş (n)"
  },
  {
    "x": "bueçquel",
    "y": " b. boçqol, -e"
  },
  {
    "x": "bueg (n)",
    "y": " b. bog (n)"
  },
  {
    "x": "buejî, -ye",
    "y": " b. bocî, -ye"
  },
  {
    "x": "buel-I (n)",
    "y": " b. bol-II (n)"
  },
  {
    "x": "buel bîyayîne",
    "y": " b. bol bîyene"
  },
  {
    "x": "buel kerdene",
    "y": " b. bol kerdene"
  },
  {
    "x": "buel-II (m)",
    "y": " b. bol-II (n)"
  },
  {
    "x": "buelbîyayîş (n)",
    "y": " b. bolbîyayîş (n)"
  },
  {
    "x": "buelî (m)",
    "y": " b. belû-I (m)"
  },
  {
    "x": "buelkerdiş (n)",
    "y": " b. bolkerdiş (n)"
  },
  {
    "x": "Buenos Aîres (n)",
    "y": " Buenos Aires"
  },
  {
    "x": "bueqaz (n)",
    "y": " b. boxaz (n)"
  },
  {
    "x": "bueqûnî kerdene",
    "y": " b. boqanî kerdene"
  },
  {
    "x": "Buer (n)",
    "y": " b. Bor (n)"
  },
  {
    "x": "Buer (m)",
    "y": " b. Bore (m)"
  },
  {
    "x": "buerayene",
    "y": " b. borayene"
  },
  {
    "x": "buerayîş (n)",
    "y": " b. borayîş (n)"
  },
  {
    "x": "buerg (m)",
    "y": " b. borge (m)"
  },
  {
    "x": "buergê (m)",
    "y": " b. borgeyî (m), borgeyîye (m)"
  },
  {
    "x": "buergî (m)",
    "y": " b. borgeyî (m), borgeyîye (m) "
  },
  {
    "x": "buerî (m)",
    "y": " b. borî (m)"
  },
  {
    "x": "buerîyê qalorîfêr (m)",
    "y": " b. borîya kalorîferî (m)"
  },
  {
    "x": "buerrî (m)",
    "y": " b. borî (m)"
  },
  {
    "x": "buerrîyê qalorîfêr (m)",
    "y": " b. borîya kalorîferî (m)"
  },
  {
    "x": "buestûn-I (n)",
    "y": " 1)b. bostan \r\n~(n) 2)hıyar, salatalık"
  },
  {
    "x": "buestûn-II (m)",
    "y": " karpuz"
  },
  {
    "x": "buexaz (n)",
    "y": " b. boxaz (n)"
  },
  {
    "x": "buexe (n)",
    "y": " b. boxe (n)"
  },
  {
    "x": "buey (m)",
    "y": " b. boye-I (m)"
  },
  {
    "x": "buêrayene",
    "y": " b. borayene"
  },
  {
    "x": "buêrayîş (n)",
    "y": " b. borayîş (n)"
  },
  {
    "x": "bufa (m)",
    "y": " büfe"
  },
  {
    "x": "bufe (n)",
    "y": " b. bufa"
  },
  {
    "x": "buhran (n)",
    "y": " bunalım, buhran, kriz"
  },
  {
    "x": "buhrano ruhî (n)",
    "y": " depresyon"
  },
  {
    "x": "buhrandar, -e",
    "y": " bunalımlı"
  },
  {
    "x": "buik (n)",
    "y": " b. buk (n)"
  },
  {
    "x": "builyel (m)",
    "y": " b. bulêle (m)"
  },
  {
    "x": "buire (n)",
    "y": " b. bure (n)"
  },
  {
    "x": "buîy (m)",
    "y": " b. boye-I (m)"
  },
  {
    "x": "bujî, -ye",
    "y": " b. bocî, -ye"
  },
  {
    "x": "Bujumbura (m)",
    "y": " Bujumbura"
  },
  {
    "x": "buk-I (n)",
    "y": " ağaç topluluğu (doğal ağaç veya çalı topluluğu)"
  },
  {
    "x": "buk-II (m)",
    "y": " b. buke (m)"
  },
  {
    "x": "bukal (n)",
    "y": " b. bawkal (n) "
  },
  {
    "x": "bukalemûn, -e",
    "y": " bukalemun "
  },
  {
    "x": "buke (m)",
    "y": " arpacık, itdirseği"
  },
  {
    "x": "buko",
    "y": " b. biko"
  },
  {
    "x": "bul, -e",
    "y": " kambur"
  },
  {
    "x": "bulbul (n)",
    "y": " b. bilbil (n)"
  },
  {
    "x": "buldozer (n)",
    "y": " b. buldozere (m)"
  },
  {
    "x": "buldozere (m)",
    "y": " buldozer"
  },
  {
    "x": "buldozeri (m)",
    "y": " b. buldozere (m)"
  },
  {
    "x": "bulecik (m)",
    "y": " b. miloçike-I (m)"
  },
  {
    "x": "bulel (m)",
    "y": " b. bulêle (m)"
  },
  {
    "x": "bulêle (m)",
    "y": " yumak"
  },
  {
    "x": "bulisk (n)",
    "y": " b. bilusk (n)"
  },
  {
    "x": "bulisk estene",
    "y": " b. bilusk eştene"
  },
  {
    "x": "bulmace (m)",
    "y": " pekmez ve undan yapılan tatlı bulamaç"
  },
  {
    "x": "bulmaci (m)",
    "y": " b. bulmace (m)"
  },
  {
    "x": "bulten (n)",
    "y": " bülten\r\n~bultenê agahîyan (n): haber bülteni\r\n~bultenê çapemenîye (n): basın bülteni"
  },
  {
    "x": "bultenê agahîyan (n)",
    "y": " haber bülteni"
  },
  {
    "x": "bultenê çapemenîye (n)",
    "y": " basın bülteni"
  },
  {
    "x": "bultenê xeberan (n)",
    "y": " haber bülteni"
  },
  {
    "x": "bulure (m)",
    "y": " b. bilûre (m)"
  },
  {
    "x": "bulurvan, -e",
    "y": " b. bilûrvan, -e"
  },
  {
    "x": "bulûre (m)",
    "y": " b. bilûre (m)"
  },
  {
    "x": "bulvar (n)",
    "y": " b. bûlvar (n)"
  },
  {
    "x": "bulwar (n)",
    "y": " b. bûlvar (n)"
  },
  {
    "x": "bulxir (n)",
    "y": " b. belxur (n)"
  },
  {
    "x": "bulxul (n)",
    "y": " b. belxur (n)"
  },
  {
    "x": "bulxur (n)",
    "y": " b. belxur (n)"
  },
  {
    "x": "bulyel (m)",
    "y": " b. bulêle (m)"
  },
  {
    "x": "bum (n)",
    "y": " baykuş"
  },
  {
    "x": "bumbar (n)",
    "y": " bumbar"
  },
  {
    "x": "bumbarek",
    "y": " b. bimbarek, -e"
  },
  {
    "x": "bumbarik",
    "y": " b. bimbarek, -e"
  },
  {
    "x": "bumlat (m)",
    "y": " b. bobelate (m)"
  },
  {
    "x": "buncuk (m)",
    "y": " b. bûncike (m)"
  },
  {
    "x": "bunlerz (n)",
    "y": " bomlerze (m)"
  },
  {
    "x": "bur meydan",
    "y": " hodri meydan"
  },
  {
    "x": "bure (n)",
    "y": " bor, geleme, gen (arazi için), hozan"
  },
  {
    "x": "bure verdayene",
    "y": " tarlayı sürmeden dinlenmeye bırakmak"
  },
  {
    "x": "bures (n)",
    "y": " b. beroş (n)"
  },
  {
    "x": "burês (n)",
    "y": " b. beroş (n)"
  },
  {
    "x": "burewî (zh)",
    "y": " b. birûy (zh) "
  },
  {
    "x": "buri (n)",
    "y": " b. bure (n)"
  },
  {
    "x": "buriçik (n)",
    "y": " çalılık"
  },
  {
    "x": "burî (zh)",
    "y": " b. birûy (zh)"
  },
  {
    "x": "burîyî (zh)",
    "y": " b. birûy (zh) "
  },
  {
    "x": "burjuwa, -ye",
    "y": " burjuva, kentsoylu"
  },
  {
    "x": "burjuwazî (n)",
    "y": " burjuva sınıfı, burjuvazi"
  },
  {
    "x": "buro-I (n)",
    "y": " yazıhane, büro, ofis\r\n~buroyê çapemenîye û weşanî: basın yayın bürosu"
  },
  {
    "x": "buroyê çapemenîye û weşanî",
    "y": " basın yayın bürosu"
  },
  {
    "x": "buro-II (n)",
    "y": " b. bîro (n)"
  },
  {
    "x": "burokrasî (n)",
    "y": " bürokrasi "
  },
  {
    "x": "burokrat, -e",
    "y": " bürokrat "
  },
  {
    "x": "buros (n)",
    "y": " b. beroş (n)"
  },
  {
    "x": "burr (n)",
    "y": " b. vîyer (n)"
  },
  {
    "x": "Burundî (m)",
    "y": " Burundi"
  },
  {
    "x": "buruske (m)",
    "y": " b. bilusk (n)"
  },
  {
    "x": "buruy (zh)",
    "y": " b. birûy (zh)"
  },
  {
    "x": "burûy (zh)",
    "y": " b. birûy (zh)"
  },
  {
    "x": "burxi (n)",
    "y": " b. burxî (n)"
  },
  {
    "x": "burxî (n)",
    "y": " burgu"
  },
  {
    "x": "busayene",
    "y": " uyumak"
  },
  {
    "x": "busayîş (n)",
    "y": " uyuma"
  },
  {
    "x": "buşkî (n)",
    "y": " b. bişkok (n)"
  },
  {
    "x": "but (n)",
    "y": " b. put-II (n)"
  },
  {
    "x": "butça (m)",
    "y": " b. butçe (m)"
  },
  {
    "x": "butçe (m)",
    "y": " bütçe\r\n~butçeya fîlmî (m): filmin bütçesi\r\n~butçeya serraneye (m): yıllık bütçe"
  },
  {
    "x": "butçeya fîlmî (m)",
    "y": " filmin bütçesi"
  },
  {
    "x": "butçeya leşkerî (m)",
    "y": " askeri bütçe"
  },
  {
    "x": "butçeya serrana (m)",
    "y": " yıllık bütçe"
  },
  {
    "x": "butçeya serraneye (m)",
    "y": " yıllık bütçe"
  },
  {
    "x": "butçeya temambîyayîşî (m)",
    "y": " tamamlanma bütçesi"
  },
  {
    "x": "buuk, -e",
    "y": " 1)(çocuk dilinde) böcek \r\n~2)(Çocukları korkutmak için söylenen korkunç varlık, böcek vb.) böcü \r\n~3)(çocuk dilinde) kurt \r\n~4)(çocuk dilinde) kurtçuk"
  },
  {
    "x": "buuki (m)",
    "y": " b. buuke (m)"
  },
  {
    "x": "buw (n)",
    "y": " b. babî (n)"
  },
  {
    "x": "buwar bîyayene",
    "y": " b. bawer bîyene"
  },
  {
    "x": "buwar kerdene",
    "y": " b. bawer kerdene"
  },
  {
    "x": "buweston (n)",
    "y": " b. bostan (n)"
  },
  {
    "x": "buwestûn-I (n)",
    "y": " b. buestûn-I (n)"
  },
  {
    "x": "buwestûn-II (m)",
    "y": " b. buestûn-II (m)"
  },
  {
    "x": "bux (n)",
    "y": " buğu"
  },
  {
    "x": "bux bîyene",
    "y": " b. buxar bîyene"
  },
  {
    "x": "bux kerdene",
    "y": " b. buxar kerdene"
  },
  {
    "x": "buxar (n)",
    "y": " buhar"
  },
  {
    "x": "buxar bîyene",
    "y": " buharlaşmak"
  },
  {
    "x": "buxar kerdene",
    "y": " buharlaştırmak"
  },
  {
    "x": "buxarbîyayîş (n)",
    "y": " buharlaşma"
  },
  {
    "x": "buxarin, -e",
    "y": " buharlı"
  },
  {
    "x": "buxarkerdiş (n)",
    "y": " buharlaştırma"
  },
  {
    "x": "buxbîyayîş (n)",
    "y": " b. buxarbîyayîş (n)"
  },
  {
    "x": "buxça (m)",
    "y": " b. boxça (m)"
  },
  {
    "x": "buxçe (n)",
    "y": " b. boxça (m)"
  },
  {
    "x": "buxicik (n)",
    "y": " bokböceği, pislik böceği, hapböceği"
  },
  {
    "x": "buxin (n)",
    "y": " buğulu"
  },
  {
    "x": "buxkerdiş (n)",
    "y": " b. buxarkerdiş (n)"
  },
  {
    "x": "buxuk (m)",
    "y": " b. buuk, -e"
  },
  {
    "x": "buxur (n)",
    "y": " b. buxar (n)"
  },
  {
    "x": "buxurin (n)",
    "y": " b. buxarin (n)"
  },
  {
    "x": "buy (m)",
    "y": " b. boye-I (m)"
  },
  {
    "x": "buy ameyene",
    "y": " b. boye ameyene"
  },
  {
    "x": "buy ci kewtene",
    "y": " b. boye ci kewtene"
  },
  {
    "x": "buy ci vistene",
    "y": " b. boye ci vistene"
  },
  {
    "x": "buy dayene",
    "y": " b. boye dayene"
  },
  {
    "x": "buy dekewtene",
    "y": " b. boye dekewtene"
  },
  {
    "x": "buy devistene",
    "y": " b. boye devistene"
  },
  {
    "x": "buy girotene",
    "y": " b. boye girewtene"
  },
  {
    "x": "buy gurotene",
    "y": " b. boye girewtene"
  },
  {
    "x": "buy kerdene",
    "y": " b. boye kerdene"
  },
  {
    "x": "buy veradayene",
    "y": " b. boye veradayene"
  },
  {
    "x": "buy veradîyayene",
    "y": " b. boye veradîyayene"
  },
  {
    "x": "buy vetene",
    "y": " b. boye vetene"
  },
  {
    "x": "buy vilakerdene",
    "y": " b. boye vilakerdene"
  },
  {
    "x": "buy virsikîdayene",
    "y": " b. boye virsiknayene"
  },
  {
    "x": "buy virsikîdîyayene",
    "y": " b. boye virsikîyayene"
  },
  {
    "x": "buya ... vejîyayene",
    "y": " b. boya ... vejîyayene"
  },
  {
    "x": "buyey xwe veradayene",
    "y": " b. boya xo veradayene"
  },
  {
    "x": "buy-buxari (m)",
    "y": " b. boy-buxare (m)"
  },
  {
    "x": "buy-germaxi (m)",
    "y": " b. boy-germaxe (m)"
  },
  {
    "x": "buyameyîş (n)",
    "y": " b. boyeameyîş (n)"
  },
  {
    "x": "buyax (n)",
    "y": " b. boyax (n)"
  },
  {
    "x": "buycikewtiş (n)",
    "y": " b. boyecikewtiş (n)"
  },
  {
    "x": "buycivistiş (n)",
    "y": " b. boyecivistiş (n)"
  },
  {
    "x": "buycî (m)",
    "y": " b. bocîye (m)"
  },
  {
    "x": "buydayîş (n)",
    "y": " b. boyedayîş (n)"
  },
  {
    "x": "buydekewtiş (n)",
    "y": " b. boyedekewtiş (n)"
  },
  {
    "x": "buydekewtiş (n)",
    "y": " b. boyedekewtiş (n)"
  },
  {
    "x": "buydevistiş (n)",
    "y": " b. boyedevistiş (n)"
  },
  {
    "x": "buye (n)",
    "y": " b. boyax (n)"
  },
  {
    "x": "buygirotiş (n)",
    "y": " b. boyegirewtiş (n)"
  },
  {
    "x": "buyi (n)",
    "y": " b. boyin, -e"
  },
  {
    "x": "buyin, -i",
    "y": " b. boyin, -e"
  },
  {
    "x": "buyîn, -i",
    "y": " b. boyin, -e"
  },
  {
    "x": "buykerdiş (n)",
    "y": " b. boyekerdiş (n)"
  },
  {
    "x": "buyveradayîş (n)",
    "y": " b. boyeveradayîş (n)"
  },
  {
    "x": "buyveradîyayîş (n)",
    "y": " b. boyeveradîyayîş (n)"
  },
  {
    "x": "buyvetiş (n)",
    "y": " b. boyevetiş (n)"
  },
  {
    "x": "buyvilakerdiş (n)",
    "y": " b. boyevilakerdiş (n)"
  },
  {
    "x": "buyvirsikîdayîş (n)",
    "y": " b. boyevirsiknayîş (n)"
  },
  {
    "x": "buyvirsikîdîyayîş (n)",
    "y": " b. boyevirsikîyayîş (n)"
  },
  {
    "x": "buzriki (m)",
    "y": " b. pizrike (m)"
  },
  {
    "x": "buzxane (n)",
    "y": " soğuk hava deposu"
  },
  {
    "x": "bû-I (m)",
    "y": " b. boye (n)"
  },
  {
    "x": "bû-II (n)",
    "y": " b. ban (n)"
  },
  {
    "x": "bûblat (m)",
    "y": " b. bobelate (m)"
  },
  {
    "x": "bûçi-I (m)",
    "y": " b. boçe (m)"
  },
  {
    "x": "bûçi-II (n)",
    "y": " b. boçik (n)"
  },
  {
    "x": "bûçidekewtiş (n)",
    "y": " b. boçikdekewtiş (n)"
  },
  {
    "x": "bûçik (n)",
    "y": " b. boçik (n)"
  },
  {
    "x": "bûçikdekewtiş (n)",
    "y": " b. boçikdekewtiş (n)"
  },
  {
    "x": "Bûdapeşt (n)",
    "y": " Budapeşte"
  },
  {
    "x": "bûdayî, -ye",
    "y": " Budist"
  },
  {
    "x": "bûdîst, -e",
    "y": " Budist"
  },
  {
    "x": "bûdîzm (n)",
    "y": " Budizm"
  },
  {
    "x": "bûeçdekotiş (n)",
    "y": " b. boçikdekewtiş (n)"
  },
  {
    "x": "bûjî, -ye",
    "y": " b. bocî, -ye"
  },
  {
    "x": "Bûkarest (n)",
    "y": " Bükreş"
  },
  {
    "x": "bûk (m)",
    "y": " b. buke (m)"
  },
  {
    "x": "bûke (m)",
    "y": " b. buke (m)"
  },
  {
    "x": "bûl (n)",
    "y": " b. bol-II (n)"
  },
  {
    "x": "Bûlgarîstan (n)",
    "y": " Bulgaristan "
  },
  {
    "x": "bûli (m)",
    "y": " b. bol-II (n) "
  },
  {
    "x": "bûlvar (n)",
    "y": " bulvar"
  },
  {
    "x": "bûlwar (n)",
    "y": " b. bûlvar (n)"
  },
  {
    "x": "bûlwari (m)",
    "y": " b. bûlvar (n)"
  },
  {
    "x": "bûn (n)",
    "y": " b. ban (n) "
  },
  {
    "x": "bûn peyin (n)",
    "y": " b. bano peyên (n)"
  },
  {
    "x": "bûna (m)",
    "y": " b. bana (m)"
  },
  {
    "x": "bûnci (n)",
    "y": " b. bocî (n)"
  },
  {
    "x": "bûncike (m)",
    "y": " havuç"
  },
  {
    "x": "bûnciki (m)",
    "y": " b. bûncike (m)"
  },
  {
    "x": "bûncî (m)",
    "y": " b. bocîye (m)"
  },
  {
    "x": "bûnderbeyîş (n)",
    "y": " b. banderbîyayîş (n)"
  },
  {
    "x": "bûnderbîyayîş (n)",
    "y": " b. banderbîyayîş (n)"
  },
  {
    "x": "bûnderî xu dayene",
    "y": " b. banderê xo dayene"
  },
  {
    "x": "bûnderîxudayîş (n)",
    "y": " b. banderêxodayîş (n)"
  },
  {
    "x": "bûnderkerdiş (n)",
    "y": " b. banderkerdiş (n)"
  },
  {
    "x": "bûne (n)",
    "y": " b. bane (n)"
  },
  {
    "x": "bûnek (n)",
    "y": " b. banek (n)"
  },
  {
    "x": "bûnekî (zh)",
    "y": " b. banekî (zh)"
  },
  {
    "x": "bûnekî kay kerdene",
    "y": " b. banekî kay kerdene"
  },
  {
    "x": "bûnger (m)",
    "y": " b. bangêre (m) "
  },
  {
    "x": "bûngêr (m)",
    "y": " b. bangêre (m) "
  },
  {
    "x": "bûngîr (m)",
    "y": " b. bangêre (m)"
  },
  {
    "x": "bûnikkey (n)",
    "y": " b. banikkay (n)"
  },
  {
    "x": "bûnikkey key kerdene",
    "y": " b. banikkay kay kerdene"
  },
  {
    "x": "bûnke (n)",
    "y": " b. banku (n)"
  },
  {
    "x": "bûnku (n)",
    "y": " b. banku (n) "
  },
  {
    "x": "bûnkuwe (n)",
    "y": " b. banku (n) "
  },
  {
    "x": "bûnqa (m)",
    "y": " b. banka (m)"
  },
  {
    "x": "bûrani (m)",
    "y": " b. borane (m)"
  },
  {
    "x": "bûre (n)",
    "y": " b. bure (n)"
  },
  {
    "x": "bûreki (m)",
    "y": " b. boreke (m) "
  },
  {
    "x": "Bûrkîna Faso",
    "y": " Burkina Faso (Volta)"
  },
  {
    "x": "Bûrma (m)",
    "y": " Birmanya"
  },
  {
    "x": "Bûtan (n)",
    "y": " Bhutan"
  },
  {
    "x": "bûtike (m)",
    "y": " bağ kulübesi"
  },
  {
    "x": "bûver (n)",
    "y": " b. bover (n)"
  },
  {
    "x": "bûy (m)",
    "y": " b. boye (n)"
  },
  {
    "x": "bûy ameyene",
    "y": " b. boye ameyene"
  },
  {
    "x": "bûy ci kewtene",
    "y": " b. boye ci kewtene"
  },
  {
    "x": "bûy ci vistene",
    "y": " b. boye ci vistene"
  },
  {
    "x": "bûy dayene",
    "y": " b. boye dayene"
  },
  {
    "x": "bûy dekewtene",
    "y": " b. boye dekewtene"
  },
  {
    "x": "bûy devistene",
    "y": " b. boye devistene"
  },
  {
    "x": "bûy girotene",
    "y": " b. boye girewtene"
  },
  {
    "x": "bûy kerdene",
    "y": " b. boye kerdene"
  },
  {
    "x": "bûy veradayene",
    "y": " b. boye veradayene"
  },
  {
    "x": "bûy veradîyayene",
    "y": " b. boye veradîyayene"
  },
  {
    "x": "bûy vetene",
    "y": " b. boye vetene"
  },
  {
    "x": "bûy vilakerdene",
    "y": " b. boye vilakerdene"
  },
  {
    "x": "bûy virsikîdayene",
    "y": " b. boye virsiknayene"
  },
  {
    "x": "bûy virsikîdîyayene",
    "y": " b. boye virsikîyayene"
  },
  {
    "x": "bûya ... vejîyayene",
    "y": " b. boya ... vejîyayene"
  },
  {
    "x": "bûy-germaxi (m)",
    "y": " b. boy-germaxe (m)"
  },
  {
    "x": "bûyameyîş (n)",
    "y": " b. boyeameyîş (n)"
  },
  {
    "x": "bûyci (n)",
    "y": " b. bocî (n)"
  },
  {
    "x": "bûycikewtiş (n)",
    "y": " b. boyecikewtiş (n)"
  },
  {
    "x": "bûycivistiş (n)",
    "y": " b. boyecivistiş (n)"
  },
  {
    "x": "bûycî (m)",
    "y": " b. bocîye (m)"
  },
  {
    "x": "bûydayîş (n)",
    "y": " b. boyedayîş (n)"
  },
  {
    "x": "bûydekewtiş (n)",
    "y": " b. boyedekewtiş (n)"
  },
  {
    "x": "bûydevistiş (n)",
    "y": " b. boyedevistiş (n)"
  },
  {
    "x": "bûygirotiş (n)",
    "y": " b. boyegirewtiş (n)"
  },
  {
    "x": "bûyi (n)",
    "y": " b. boyin, -e"
  },
  {
    "x": "bûyin, -i",
    "y": " b. boyin, -e"
  },
  {
    "x": "bûykerdiş (n)",
    "y": " b. boyekerdiş (n)"
  },
  {
    "x": "bûyveradayîş (n)",
    "y": " b. boyeveradayîş (n)"
  },
  {
    "x": "bûyveradîyayîş (n)",
    "y": " b. boyeveradîyayîş (n)"
  },
  {
    "x": "bûyvetiş (n)",
    "y": " b. boyevetiş (n)"
  },
  {
    "x": "bûyvilakerdiş (n)",
    "y": " b. boyevilakerdiş (n)"
  },
  {
    "x": "bûyvirsikîdayîş (n)",
    "y": " b. boyevirsiknayîş (n)"
  },
  {
    "x": "bûyvirsikîdîyayîş (n)",
    "y": " b. boyevirsikîyayîş (n)"
  },
  {
    "x": "bye (vr)",
    "y": " b. bê-I (vr)"
  },
  {
    "x": "byesilma (m)",
    "y": " b. beslima (m)"
  },
  {
    "x": "byor (n)",
    "y": " b. bîro (n)"
  },
  {
    "x": "CCC"
  },
  {
    "x": "c’ a",
    "y": " b. ci ra"
  },
  {
    "x": "c’ a kerdene",
    "y": " b. tira kerdene, cira kerdene"
  },
  {
    "x": "ca-I (n)",
    "y": " yer, mekân, mevzi; konum\r\n~cayê çekeştişî (n): silah atış yeri, atış yeri, poligon\r\n~cayê destpêkî (n): çıkış noktası\r\n~cayê fesadîye (n):cadı kazanı\r\n~cayê karî (n): iş yeri\r\n~cayê parkkerdişî (n): park yeri\r\n~cayê peynî de: sonuç yerine\r\n~cayê peynîye de: sonuç yerine"
  },
  {
    "x": "ca ardene",
    "y": " icra etmek, yapmak, yürütmek (huq.), uygulamak, tatbik etmek, yerine getirmek, gerçekleştirmek"
  },
  {
    "x": "ca bîyayene",
    "y": " b. ca bîyene"
  },
  {
    "x": "ca bîyene",
    "y": " 1)yerleşmek \r\n~2)bulunmak\r\n~Gedeyo ke vîndî bibi hîna ca nêbîyo. (Kaybolan çocuk henüz bulunmamış.)"
  },
  {
    "x": "ca bedilnayene",
    "y": " yer değiştirmek"
  },
  {
    "x": "ca dayene",
    "y": " barındırmak"
  },
  {
    "x": "ca dayene xwi",
    "y": " b. ca xo dayene"
  },
  {
    "x": "ca de",
    "y": " hemen, anında, birdenbire"
  },
  {
    "x": "ca de merdene",
    "y": " hemen ölmek, anında ölmek"
  },
  {
    "x": "ca de ravurîyayîş (n)",
    "y": " yerinde dönüşüm"
  },
  {
    "x": "ca de tebdîl (n)",
    "y": " yerinde dönüşüm"
  },
  {
    "x": "ca de verdayene",
    "y": " 1)bırakmak \r\n~2)caymak, vazgeçmek, vazgelmek"
  },
  {
    "x": "ca dekewtene",
    "y": " dayanmak (tahammül etmek, sabretmek)"
  },
  {
    "x": "ca dekotene",
    "y": " b. ca dekewtene"
  },
  {
    "x": "ca denêkotene",
    "y": " b. ca denêkewtene"
  },
  {
    "x": "ca denêkewtene",
    "y": " sabredememek\r\n~Ca mi nêkewt. (Sabredemedim.)"
  },
  {
    "x": "ca di",
    "y": " b. ca de"
  },
  {
    "x": "ca di merdene",
    "y": " b. ca de merdene"
  },
  {
    "x": "ca di verdayene",
    "y": " b. ca de verdayene"
  },
  {
    "x": "ca girewtene",
    "y": " yerleşmek"
  },
  {
    "x": "ca girotene",
    "y": " b. ca girewtene"
  },
  {
    "x": "ca guretene",
    "y": " b. ca girewtene"
  },
  {
    "x": "ca kerdene",
    "y": " bulmak"
  },
  {
    "x": "ca ra vecîyayene",
    "y": " b. ca ra vejîyayene"
  },
  {
    "x": "ca ra vecyayene",
    "y": " b. ca ra vejîyayene"
  },
  {
    "x": "ca ra vejîyayene",
    "y": " çıkmak (kol, ayak vb. için)"
  },
  {
    "x": "ca verdayene",
    "y": " 1)bırakmak \r\n~2)boşamak \r\n~3)caymak, vazgeçmek, vazgelmek"
  },
  {
    "x": "ca vurîyayene",
    "y": " deplase olmak"
  },
  {
    "x": "ca vurnayene",
    "y": " yer değiştirmek, deplase etmek"
  },
  {
    "x": "ca xo dayene",
    "y": " yerleşmek"
  },
  {
    "x": "cay camêrdey kewtiş (n)",
    "y": " b. cayê camêrdîye kewtene"
  },
  {
    "x": "cay karî",
    "y": " b. cayê karî"
  },
  {
    "x": "cay xu guretene",
    "y": " b. cayê xo girewtene"
  },
  {
    "x": "cay xwi girotene",
    "y": " b. cayê xo girewtene"
  },
  {
    "x": "cayê bazarî (n)",
    "y": " pazaryeri"
  },
  {
    "x": "cayê berardişê ...",
    "y": " ... üreten işyeri"
  },
  {
    "x": "cayê berhemardişê ...",
    "y": " ... üreten işyeri"
  },
  {
    "x": "cayê camêrdîye kewtene",
    "y": " (erkek için) ergenleşmek"
  },
  {
    "x": "cayê çekeştişî (n)",
    "y": " silah atış yeri, atış yeri, poligon"
  },
  {
    "x": "cayê destpêkî (n)",
    "y": " çıkış noktası"
  },
  {
    "x": "cayê fesadîye (n)",
    "y": " cadı kazanı"
  },
  {
    "x": "cayê fizilîye (n)",
    "y": " cadı kazanı"
  },
  {
    "x": "cayê karê xidayan (n)",
    "y": " gıda işyeri"
  },
  {
    "x": "cayê karî (n)",
    "y": " işyeri"
  },
  {
    "x": "cayê karî yê rotişî (zh)",
    "y": " satış işyerleri"
  },
  {
    "x": "cayê parkkerdişî (n)",
    "y": " park yeri"
  },
  {
    "x": "cayê peynî de",
    "y": " sonuç yerine"
  },
  {
    "x": "cayê peynîye de",
    "y": " sonuç yerine"
  },
  {
    "x": "cayê qal û qirfan (n)",
    "y": " cadı kazanı"
  },
  {
    "x": "cayê rezan (n)",
    "y": " bağlık"
  },
  {
    "x": "cayê roniştişî (n)",
    "y": " yerleşim yeri"
  },
  {
    "x": "cayê rotişê ...",
    "y": " ... satan işyeri"
  },
  {
    "x": "cayê serakerdeyî de rokerdişê çopî",
    "y": " açığa çöp dökme"
  },
  {
    "x": "cayê şutişê meyîtan (m)",
    "y": " gasilhane"
  },
  {
    "x": "Cayke beno cayke nêbeno, yew cinîke bena. ",
    "y": " Bir varmış bir yokmuş, bir kadın varmış."
  },
  {
    "x": "cayê xo girewtene",
    "y": " yerleşmek"
  },
  {
    "x": "cayo bidaran",
    "y": " ağaçlı yer"
  },
  {
    "x": "cayo bidarûber",
    "y": " ağaçlı yer"
  },
  {
    "x": "cayo darin",
    "y": " ağaçlı yer"
  },
  {
    "x": "cayo darûberin",
    "y": " ağaçlı"
  },
  {
    "x": "cayo ekolojîk (n)",
    "y": " ekolojik konum"
  },
  {
    "x": "cayo stratejîk (n)",
    "y": " stratejik yer"
  },
  {
    "x": "ca-II (n)",
    "y": " yatak"
  },
  {
    "x": "ca-III (m, z)",
    "y": " b. aye (m, z)"
  },
  {
    "x": "ca-IV",
    "y": " b. cîya, -ye"
  },
  {
    "x": "ca-V",
    "y": " b. ga"
  },
  {
    "x": "caabirnayîş (n)",
    "y": " rezervasyon"
  },
  {
    "x": "caardiş (n)",
    "y": " icra, uygulama, yerine getirme, yürütme (huq.), infaz, gerçekleştirme, uygulama\r\n~caardişê awanî (n): imar uygulaması\r\n~caardişê projeyî (n): projenin yürütülmesi\r\n~caardişo zorkanî (m): cebri icra"
  },
  {
    "x": "caardişê awanî (n)",
    "y": " imar uygulaması"
  },
  {
    "x": "caardişê bire (n)",
    "y": " kararın infazı"
  },
  {
    "x": "caardişê projeyî (n)",
    "y": " projenin yürütülmesi"
  },
  {
    "x": "caardişê qerarî (n)",
    "y": " kararın infazı"
  },
  {
    "x": "caardişo zorkanî (m)",
    "y": " cebri icra"
  },
  {
    "x": "cab (n)",
    "y": " b. cewab (n)"
  },
  {
    "x": "cabedilnayîş (n)",
    "y": " yer değiştirme, rotasyon"
  },
  {
    "x": "cabîyaya (m)",
    "y": " yerleşik"
  },
  {
    "x": "cabîyaye (n)",
    "y": " yerleşik"
  },
  {
    "x": "cabîyayî, -ye",
    "y": " yerleşik"
  },
  {
    "x": "cabîyayîş (n)",
    "y": " 1)(bir yerde oturma) yerleşim, yerleşme \r\n~2)bulunma"
  },
  {
    "x": "cacim (n)",
    "y": " cicim"
  },
  {
    "x": "cacix-I (n)",
    "y": " cacık"
  },
  {
    "x": "cacix-II (m)",
    "y": " b. cacixe (m)"
  },
  {
    "x": "cacixe (m)",
    "y": " kabak"
  },
  {
    "x": "cacixi (m)",
    "y": " b. cacixe (m)"
  },
  {
    "x": "cadayîş (n)",
    "y": " barındırma"
  },
  {
    "x": "cadde (n)",
    "y": " b. cehde (n)"
  },
  {
    "x": "cade-I",
    "y": " münasip, uygun, yaraşır, yerinde"
  },
  {
    "x": "cade bîyayene",
    "y": " b. cade bîyene"
  },
  {
    "x": "cade bîyene",
    "y": " uygun olmak"
  },
  {
    "x": "cade-II (n)",
    "y": " b. cehde (n)"
  },
  {
    "x": "cadebîyayîş (n)",
    "y": " uygun olma"
  },
  {
    "x": "cadedîyayîş (n)",
    "y": " muvafakat"
  },
  {
    "x": "cadekewtiş (n)",
    "y": " dayanma (tahammül etme, sabretme)"
  },
  {
    "x": "cadekotiş (n)",
    "y": " b. xoragirewtiş (n)"
  },
  {
    "x": "cadeverdayîş (n)",
    "y": " 1)bırakma \r\n~2)cayma, vazgeçme, vazgelme"
  },
  {
    "x": "cadi-I (n)",
    "y": " b. cehde (n)"
  },
  {
    "x": "cadi-II",
    "y": " b. cade-I"
  },
  {
    "x": "cadi bîyayene",
    "y": " b. cade bîyene"
  },
  {
    "x": "cadibîyayîş (n)",
    "y": " b. cadebîyayîş (n)"
  },
  {
    "x": "cadidîyayîş (n)",
    "y": " b. cadedîyayîş (n)"
  },
  {
    "x": "cadikere (m)",
    "y": " cadaloz"
  },
  {
    "x": "cadikere bîyene",
    "y": " cadalozlaşmak"
  },
  {
    "x": "cadikerebîyayîş (n)",
    "y": " cadalozlaşma"
  },
  {
    "x": "cadikerey (m)",
    "y": " b. cadikerîye (m)"
  },
  {
    "x": "cadikerê (m)",
    "y": " b. cadikerîye (m)"
  },
  {
    "x": "cadikeri (m)",
    "y": " b. cadikere (m), cadûkere (m)"
  },
  {
    "x": "cadikeri bîyayene",
    "y": " b. cadikere bîyene, cadûkere bîyene"
  },
  {
    "x": "cadikeribîyayîş (n)",
    "y": " b. cadikerebîyayîş (n), cadûkerebîyayîş (n)"
  },
  {
    "x": "cadikerîye (m)",
    "y": " cadalozluk"
  },
  {
    "x": "cadiverdayîş (n)",
    "y": " b. cadeverdayîş (n)"
  },
  {
    "x": "cadu (m)",
    "y": " b. cadû (m)"
  },
  {
    "x": "cadu bîyayene",
    "y": " b. cadû bîyene"
  },
  {
    "x": "cadubîyayîş (n)",
    "y": " b. cadûbîyayîş (n)"
  },
  {
    "x": "caduye (m)",
    "y": " b. cadûye (m)"
  },
  {
    "x": "cadû (m)",
    "y": " cadı"
  },
  {
    "x": "cadû bîyene",
    "y": " cadılaşmak"
  },
  {
    "x": "cadûbîyayîş (n)",
    "y": " cadılaşma"
  },
  {
    "x": "cadûkere (m)",
    "y": " cadı"
  },
  {
    "x": "cadûkere bîyene",
    "y": " cadılaşmak"
  },
  {
    "x": "cadûkerebîyayîş (n)",
    "y": " cadılaşma"
  },
  {
    "x": "cadûye (m)",
    "y": " cadı "
  },
  {
    "x": "cae (n)",
    "y": " b. cehde (n)"
  },
  {
    "x": "cagir, -e",
    "y": " yedek, ihtiyat"
  },
  {
    "x": "cagirewta (m)",
    "y": " yerleşik"
  },
  {
    "x": "cagirewte (n)",
    "y": " yerleşik"
  },
  {
    "x": "cagirewtî, -ye",
    "y": " yerleşik"
  },
  {
    "x": "cagirewtiş (n)",
    "y": " yerleşme"
  },
  {
    "x": "cagirote (n)",
    "y": " b. cagirewte (n)"
  },
  {
    "x": "cagirotê (m)",
    "y": " b. cagirewta (m)"
  },
  {
    "x": "cagiroti (n)",
    "y": " b. cagirewte (n)"
  },
  {
    "x": "cagirotiş (n)",
    "y": " b. cagirewtiş"
  },
  {
    "x": "cagurete (n)",
    "y": " b. cagirewte (n)"
  },
  {
    "x": "caguretê (m)",
    "y": " b. cagirewta (m)"
  },
  {
    "x": "cagureti (n)",
    "y": " b. cagirewte (n)"
  },
  {
    "x": "caguretiş (n)",
    "y": " b. cagirewtiş"
  },
  {
    "x": "cahdi (n)",
    "y": " b. cehde (n)"
  },
  {
    "x": "cahil, -e",
    "y": " b. cahîl, -e"
  },
  {
    "x": "cahilane",
    "y": " cahilce, cahilane, bilgisizce"
  },
  {
    "x": "cahilone",
    "y": " b. cahilane"
  },
  {
    "x": "cahilûne",
    "y": " b. cahilane"
  },
  {
    "x": "cahîl, -e",
    "y": " 1)bilgisiz, cahil \r\n~2)genç "
  },
  {
    "x": "cahîlenî (m)",
    "y": " bilgisizlik, cehalet, cahiliyet"
  },
  {
    "x": "cahîlenî kerdene",
    "y": " cahillik etmek"
  },
  {
    "x": "cahîley (m)",
    "y": " b. cahîlîye (m)"
  },
  {
    "x": "cahîley kardene",
    "y": " b. cahîlîye kerdene"
  },
  {
    "x": "cahîlê (m)",
    "y": " b. cahîlîye (m), cahîlî (m)"
  },
  {
    "x": "cahîlê kerdene",
    "y": " b. cahîlîye kerdene"
  },
  {
    "x": "cahîlî (m)",
    "y": " b. cahîlîye (m)"
  },
  {
    "x": "cahîlî kerdene",
    "y": " b. cahîlîye kerdene"
  },
  {
    "x": "cahîlîye (m)",
    "y": " 1)bilgisizlik, cahiliyet, cehalet, cahillik \r\n~2)gençlik"
  },
  {
    "x": "cahîlîye kerdene",
    "y": " cahillik etmek "
  },
  {
    "x": "cahnim (n)",
    "y": " b. cehenem (n)"
  },
  {
    "x": "cahnim-cennet (n)",
    "y": " b. cehenem-cenet (n)"
  },
  {
    "x": "cahnim-cinet (n)",
    "y": " b. cehenem-cenet (n)"
  },
  {
    "x": "cahş (n)",
    "y": " b. cehşik (n)"
  },
  {
    "x": "caîl",
    "y": " b. cahîl, -e"
  },
  {
    "x": "caîlê (m)",
    "y": " b. cahîlî (m)"
  },
  {
    "x": "cajuye (m)",
    "y": " b. cadûye (m)"
  },
  {
    "x": "cakerdiş (n)",
    "y": " bulma"
  },
  {
    "x": "caket (n)",
    "y": " b. çakêt (n)"
  },
  {
    "x": "cakêt (n)",
    "y": " b. çakêt (n)"
  },
  {
    "x": "cal, -e",
    "y": " acı"
  },
  {
    "x": "calancere (m)",
    "y": " calancêre (m)"
  },
  {
    "x": "calancêre (m)",
    "y": " kızıl renkli iri bir yabanarısı"
  },
  {
    "x": "calek, -e",
    "y": " acımsı, acımtırak"
  },
  {
    "x": "caley (m)",
    "y": " b. calîye (m)"
  },
  {
    "x": "cali (m)",
    "y": " b. cale (m)"
  },
  {
    "x": "calin, -e",
    "y": " acılı"
  },
  {
    "x": "calî (m)",
    "y": " acılık"
  },
  {
    "x": "calîye (m)",
    "y": " acılık"
  },
  {
    "x": "callaw (m) ",
    "y": " b. celawe (m)"
  },
  {
    "x": "callawi (m)",
    "y": " b. celawe (m)"
  },
  {
    "x": "cam (n)",
    "y": " cam"
  },
  {
    "x": "cam helnayene",
    "y": " cam pişirmek"
  },
  {
    "x": "cama (m)",
    "y": " b. cema (m)"
  },
  {
    "x": "cama kerdene",
    "y": " b. cema kerdene"
  },
  {
    "x": "camat (n)",
    "y": " b. cemat (n)"
  },
  {
    "x": "cambaz, -e",
    "y": " b. canbaz, -e"
  },
  {
    "x": "cambazênî (m)",
    "y": " b. canbazênî (m)"
  },
  {
    "x": "cambazîye (m)",
    "y": " b. canbazîye (m)"
  },
  {
    "x": "cambirnayîş (n)",
    "y": " cam kesme"
  },
  {
    "x": "camekan (n)",
    "y": " camekan, vitrin"
  },
  {
    "x": "camerd (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "camerdey (m)",
    "y": " b. camêrdî (m)"
  },
  {
    "x": "camerdê (m)",
    "y": " b. camêrdî (m)"
  },
  {
    "x": "camerdîye (m)",
    "y": " b. camêrdîye (m)"
  },
  {
    "x": "camêrd (n)",
    "y": " 1)erkek, er, bay \r\n~2)cesaretli, cesur, yürekli, acar (gözü pek) \r\n~3)yiğit, alp, kahraman"
  },
  {
    "x": "camêrdek-I (n)",
    "y": " 1)cesaretli, cesur, yürekli \r\n~2)yiğit, alp, kahraman"
  },
  {
    "x": "camêrdek-II (n)",
    "y": " adamcık"
  },
  {
    "x": "camêrdekî (m)",
    "y": " yiğitlik, alplık, kahramanlık"
  },
  {
    "x": "camêrdênî (m)",
    "y": " 1)erkeklik \r\n~2)cesaretlilik, cesurluk, yüreklilik, gözü peklik \r\n~3)yiğitlik, alplık, kahramanlık"
  },
  {
    "x": "camêrdî (m)",
    "y": " 1)erkeklik \r\n~2)cesaretlilik, cesurluk, yüreklilik, gözü peklik \r\n~3)yiğitlik, alplık, kahramanlık"
  },
  {
    "x": "camêrdîye (m)",
    "y": " 1)erkeklik \r\n~2)cesaretlilik, cesurluk, yüreklilik, gözü peklik \r\n~3)yiğitlik, alplık, kahramanlık"
  },
  {
    "x": "camin, -e",
    "y": " camlı"
  },
  {
    "x": "camisqiran (n)",
    "y": " b. gamêşqiran (n)"
  },
  {
    "x": "camî (n)",
    "y": " cami"
  },
  {
    "x": "camî (m)",
    "y": " b. camî (n)"
  },
  {
    "x": "camîye (m)",
    "y": " b. camî (n)"
  },
  {
    "x": "camîyek (n)",
    "y": " mescit"
  },
  {
    "x": "camîyerd (n)",
    "y": " b. camêrd (n) "
  },
  {
    "x": "camkar, -e",
    "y": " camcı"
  },
  {
    "x": "camkarîye (m)",
    "y": " camcılık"
  },
  {
    "x": "camroşîye (m)",
    "y": " camcılık"
  },
  {
    "x": "camord (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "campûn (n)",
    "y": " b. capon (n)"
  },
  {
    "x": "camroş, -e",
    "y": " camcı"
  },
  {
    "x": "camus, -e",
    "y": " b. gamêş, -e"
  },
  {
    "x": "camusi (m)",
    "y": " b. gamêşe (m)"
  },
  {
    "x": "camusnayox, -e",
    "y": " yer gösterici"
  },
  {
    "x": "camûsqirane (m)",
    "y": " b. gamêşqiran (n)"
  },
  {
    "x": "can (n)",
    "y": " can"
  },
  {
    "x": "can dayene",
    "y": " can vermek"
  },
  {
    "x": "can feda",
    "y": " b. can fîda"
  },
  {
    "x": "can fîda",
    "y": " can kurban "
  },
  {
    "x": "can û ro ra",
    "y": " b. can û ruhî ra"
  },
  {
    "x": "can û ruhî ra",
    "y": " canıgönülden "
  },
  {
    "x": "canê ...  ... waştene",
    "y": " canı istemek, canı çekmek"
  },
  {
    "x": "canê xo ra bêzar bîyene",
    "y": " canından bezmek"
  },
  {
    "x": "canê... werdene",
    "y": " bezdirmek¸ bıktırmak, usandırmak"
  },
  {
    "x": "canê... werdiş",
    "y": " bezdirme¸ bıktırma, usandırma"
  },
  {
    "x": "cana (m)",
    "y": " tay"
  },
  {
    "x": "caname (n)",
    "y": " adıl, zamir"
  },
  {
    "x": "canbaz, -e",
    "y": " 1)cambaz, akrobat \r\n~2)hayvan tüccarı"
  },
  {
    "x": "canbazênî (m)",
    "y": " 1)akrobasi, akrobatlık, cambazlık \r\n~2) cambazlık (hayvan tüccarlığı)"
  },
  {
    "x": "canbazîye (m)",
    "y": " 1)akrobasi, akrobatlık, cambazlık \r\n~2) cambazlık (hayvan tüccarlığı)  "
  },
  {
    "x": "canbexş, -e",
    "y": " can veren"
  },
  {
    "x": "cançês, -e",
    "y": " b. gankêş, -e; cankêş, -e"
  },
  {
    "x": "cançêş, -e",
    "y": " b. gankêş, -e, cankêş, -e"
  },
  {
    "x": "cançêş bîyayene",
    "y": " b. cankêş de bîyene"
  },
  {
    "x": "cançêşbîyayîş (n)",
    "y": " b. cankêşdebîyayîş (n)"
  },
  {
    "x": "cançêşênî (m)",
    "y": " b. gankêşênî (m)"
  },
  {
    "x": "cançêşîye (m)",
    "y": " b. cankêşîye (m)"
  },
  {
    "x": "canda (m)",
    "y": " canlı"
  },
  {
    "x": "candar, -e",
    "y": " canlı"
  },
  {
    "x": "candar kerdene",
    "y": " canlandırmak"
  },
  {
    "x": "candarkerdiş (n)",
    "y": " animasyon, canlandırma"
  },
  {
    "x": "candayîş (n)",
    "y": " can verme"
  },
  {
    "x": "cande (n)",
    "y": " canlı"
  },
  {
    "x": "cane (n)",
    "y": " tay"
  },
  {
    "x": "canfeda, -ye",
    "y": " b. canfîda, -ye"
  },
  {
    "x": "canfîda, -ye",
    "y": " can feda (mecaz) "
  },
  {
    "x": "cangewre (n)",
    "y": " naylon çorap"
  },
  {
    "x": "cangore",
    "y": " b. cangewre (n)"
  },
  {
    "x": "canim (n)",
    "y": " b. cehenem (n)"
  },
  {
    "x": "canim-cennet (n)",
    "y": " b. cehenem-cenet (n)"
  },
  {
    "x": "canim-cinet (n)",
    "y": " b. cehenem-cenet (n)"
  },
  {
    "x": "canî (m)",
    "y": " b. cana (m)"
  },
  {
    "x": "cankes, -e",
    "y": " b. gankêş, -e; cankêş, -e"
  },
  {
    "x": "cankesenî (m)",
    "y": " b. gankêşênî (m)"
  },
  {
    "x": "cankeşîye (m)",
    "y": " b. cankêşîye (m)"
  },
  {
    "x": "cankês, -e",
    "y": " b. gankêş, -e; cankêş, -e"
  },
  {
    "x": "cankês bîyayêne",
    "y": " b. cankêş de bîyene"
  },
  {
    "x": "cankêsbîyayîş (n)",
    "y": " b. cankêşdebîyayîş (n)"
  },
  {
    "x": "cankêş, -e",
    "y": " ölümcül (can çekişmekte olan), can çekişen"
  },
  {
    "x": "cankêş de bîyene",
    "y": " can çekişmek, ölüm döşeğinde olmak"
  },
  {
    "x": "cankêşdebîyayîş (n)",
    "y": " can çekişme"
  },
  {
    "x": "cankêşî (m)",
    "y": " can çekişme"
  },
  {
    "x": "cankêşîye (m)",
    "y": " can çekişme"
  },
  {
    "x": "canmêrd (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "canmêrdek (n)",
    "y": " b. camêrdek (n)"
  },
  {
    "x": "canmêrdekey (m)",
    "y": " b. camêrdekî (m)"
  },
  {
    "x": "canmêrdekî (m)",
    "y": " b. camêrdekî (m)"
  },
  {
    "x": "canmêrdî (m)",
    "y": " b. camêrdî (m)"
  },
  {
    "x": "canmîrd (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "cansivik, -e",
    "y": " atik, çevik"
  },
  {
    "x": "cansivikî (m)",
    "y": " atiklik, çeviklik"
  },
  {
    "x": "cansivikîye (m)",
    "y": " atiklik, çeviklik"
  },
  {
    "x": "canşenik, -e",
    "y": " atik, çevik; canlı"
  },
  {
    "x": "canşenikî (m)",
    "y": " atiklik, çeviklik"
  },
  {
    "x": "canşenikîye (m)",
    "y": " atiklik"
  },
  {
    "x": "canşik (n)",
    "y": " b. cehşik (n)"
  },
  {
    "x": "canşîrin, -e",
    "y": " canı tatlı"
  },
  {
    "x": "canşîrîn, -e",
    "y": " b. canşîrin, -e"
  },
  {
    "x": "canweşîye (m)",
    "y": " can sağlığı"
  },
  {
    "x": "canxelasker (n)",
    "y": " cankurtaran, ambülans"
  },
  {
    "x": "capon (n)",
    "y": " Japon bezi"
  },
  {
    "x": "car (n)",
    "y": " b. cor (n)"
  },
  {
    "x": "cara",
    "y": " b. caran"
  },
  {
    "x": "caran",
    "y": " asla, hiçbir zaman; hiç, katiyen\r\n~Ez caran nêşîya. (Ben hiç gitmedim.)"
  },
  {
    "x": "caravecîyaya (m)",
    "y": " b. caravejîyaya (m)"
  },
  {
    "x": "caravecîyaye (n)",
    "y": " b. caravejîyaye (n)"
  },
  {
    "x": "caravecîyayîş (n)",
    "y": " b. caravejîyayîş (n)"
  },
  {
    "x": "caravecîyayîye (m)",
    "y": " b. caravejîyayîye (m)"
  },
  {
    "x": "caravejîyaya (m)",
    "y": " çıkık"
  },
  {
    "x": "caravejîyaye (n)",
    "y": " çıkık"
  },
  {
    "x": "caravejîyayîş (n)",
    "y": " yerinden çıkma (kol, ayak vb. için), çıkma (kol, ayak vb. için)"
  },
  {
    "x": "caravejîyayîşê mîyanî",
    "y": " bel kayması"
  },
  {
    "x": "caravejîyayîye (m)",
    "y": " çıkık"
  },
  {
    "x": "caravejîyayîya kuleyî",
    "y": " kalça çıkıklığı"
  },
  {
    "x": "caravejîyayîya qorrikî",
    "y": " kalça çıkıklığı"
  },
  {
    "x": "caravîcayîş (n)",
    "y": " b. caravejîyayîş (n)"
  },
  {
    "x": "carit (n)",
    "y": " b. carut (n)"
  },
  {
    "x": "carî, -ye",
    "y": " cari, geçerli, meri"
  },
  {
    "x": "carîya (m)",
    "y": " b. carîye (m)"
  },
  {
    "x": "carîye (m)",
    "y": " cariye, halayık"
  },
  {
    "x": "carîyeyey (m)",
    "y": " b. carîyeyîye (m)"
  },
  {
    "x": "carîyeyê (m)",
    "y": " b. carîyeyîye (m)"
  },
  {
    "x": "carîyeyîye (m)",
    "y": " cariyelik, halayıklık"
  },
  {
    "x": "carîyî (m)",
    "y": " geçerlilik"
  },
  {
    "x": "carmêd (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "carmêdek (n)",
    "y": " b. camêrdek (n)"
  },
  {
    "x": "carmêdekey (m)",
    "y": " b. camêrdekî (m)"
  },
  {
    "x": "carmêdey (m)",
    "y": " b. camêrdî (m)"
  },
  {
    "x": "carmêrd (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "carmêrdek (n)",
    "y": " b. camêrdek (n)"
  },
  {
    "x": "carmêrdekey (m)",
    "y": " b. camêrdekî (m)"
  },
  {
    "x": "carmêrdey (m)",
    "y": " b. camêrdî (m)"
  },
  {
    "x": "caro",
    "y": " b. caran"
  },
  {
    "x": "caron",
    "y": " b. caran"
  },
  {
    "x": "carrit (n)",
    "y": " b. carut (n)"
  },
  {
    "x": "carrut (n)",
    "y": " b. carut (n)"
  },
  {
    "x": "carut (n)",
    "y": " ateş küreği, faraş"
  },
  {
    "x": "carû",
    "y": " b. caran"
  },
  {
    "x": "carûn",
    "y": " b. caran"
  },
  {
    "x": "carûtî (m)",
    "y": " b. carut (n)"
  },
  {
    "x": "casus, -e",
    "y": " ajan, casus, çaşıt"
  },
  {
    "x": "casusey (m)",
    "y": " b. casusîye (m)"
  },
  {
    "x": "casusê (m)",
    "y": " b. casusîye (m)"
  },
  {
    "x": "casusîye (m)",
    "y": " ajanlık, casusluk"
  },
  {
    "x": "casûs, -e",
    "y": " b. casus, -e"
  },
  {
    "x": "casûsey (m)",
    "y": " b. casusîye (m)"
  },
  {
    "x": "casûsîye (m)",
    "y": " b. casusîye (m)"
  },
  {
    "x": "caşik",
    "y": " b. cehşik, -e"
  },
  {
    "x": "cat-I (n)",
    "y": " b. wet (n)"
  },
  {
    "x": "cat ra",
    "y": " b. wet ra"
  },
  {
    "x": "cat-I (n)",
    "y": " b. cehd (n)"
  },
  {
    "x": "cat kerdene",
    "y": " b. cehd kerdene"
  },
  {
    "x": "catir (n)",
    "y": " b. cehtire (n)"
  },
  {
    "x": "catkerdiş (n)",
    "y": " b. cehdkerdiş (n)"
  },
  {
    "x": "cav (n)",
    "y": " b. cewab (n)"
  },
  {
    "x": "caverdaya (m)",
    "y": " boşanmış kişi"
  },
  {
    "x": "caverdaye (n)",
    "y": " boşanmış kişi"
  },
  {
    "x": "caverdayî, -ye",
    "y": " boşanmış kişi"
  },
  {
    "x": "caverdayîş (n)",
    "y": " 1)bırakma \r\n~2)boşama \r\n~3)cayma, vazgeçme, vazgelme"
  },
  {
    "x": "cavurnayîş (n)",
    "y": " yer değiştirme, rotasyon, deplase"
  },
  {
    "x": "cawitene",
    "y": " çiğnemek"
  },
  {
    "x": "cawitiş (n)",
    "y": " çiğneme"
  },
  {
    "x": "cax (m)",
    "y": " b. caxe (m)"
  },
  {
    "x": "caxe (m)",
    "y": " 1)parmaklık biçimindeki kapı, çit kapısı, çeper kapısı; çit biçimindeki kapı \r\n~2)kaburga \r\n~3)çopra (balık kılçığı), kılçık (balık kılçığı) \r\n~4)örgü şişi"
  },
  {
    "x": "caxeke (m)",
    "y": " çit biçimindeki kapı"
  },
  {
    "x": "caxeki (m)",
    "y": " b. caxeke (m)"
  },
  {
    "x": "caxi (m)",
    "y": " b. caxe (m)"
  },
  {
    "x": "caxike (m)",
    "y": " ızgara"
  },
  {
    "x": "caxodayîş (n)",
    "y": " yerleşme"
  },
  {
    "x": "cay (m, z)",
    "y": " b. aye (m, z)"
  },
  {
    "x": "caycamêrdeykewtiş (n)",
    "y": " b. cayêcamêrdîyekewtiş (n)"
  },
  {
    "x": "caye (m, z)",
    "y": " b. aye (m, z)"
  },
  {
    "x": "cayêcamêrdîyekewtiş (n)",
    "y": " (erkek için) ergenleşme"
  },
  {
    "x": "cayêxogirewtiş (n)",
    "y": " yerleşme"
  },
  {
    "x": "cayil, -e",
    "y": " b. cahîl, -e"
  },
  {
    "x": "cayîl, -i",
    "y": " cahîl, -e"
  },
  {
    "x": "cayîley (m)",
    "y": " b. cahîlîye (m)"
  },
  {
    "x": "cayilîye (m)",
    "y": " b. cahîlî (m)"
  },
  {
    "x": "cayî, -ye",
    "y": " yerli"
  },
  {
    "x": "cayîg (n)",
    "y": " kuzgun"
  },
  {
    "x": "cayîl",
    "y": " b. cahîl, -e"
  },
  {
    "x": "cayîlî (m)",
    "y": " b. cahîlî (m)"
  },
  {
    "x": "caz (n)",
    "y": " caz "
  },
  {
    "x": "cazband (n)",
    "y": " cazbant"
  },
  {
    "x": "cazim (n)",
    "y": " b. cacim (n)"
  },
  {
    "x": "cazî (m)",
    "y": " b. cazû (m)"
  },
  {
    "x": "cazî bîyayene",
    "y": " b. cazû bîyene"
  },
  {
    "x": "cazîb, -e",
    "y": " cazip"
  },
  {
    "x": "cazîbe (m)",
    "y": " cazibe, çekim, çekicilik; albeni, alım"
  },
  {
    "x": "cazîbedar, -e",
    "y": " alımlı, cazibeli, çekici, cazip"
  },
  {
    "x": "cazîbedarî (m)",
    "y": " çekicilik, alımlılık"
  },
  {
    "x": "cazîbedarîye (m)",
    "y": " çekicilik, alımlılık"
  },
  {
    "x": "cazîbîyayîş (n)",
    "y": " b. cazûbîyayîş (n)"
  },
  {
    "x": "cazui (m)",
    "y": " b. cazûye (m)"
  },
  {
    "x": "cazuye (m)",
    "y": " b. cazûye (m)"
  },
  {
    "x": "cazû (m)",
    "y": " cadı "
  },
  {
    "x": "cazû bîyene",
    "y": " cadılaşmak"
  },
  {
    "x": "cazûbîyayîş (n)",
    "y": " cadılaşma"
  },
  {
    "x": "cazûye (m)",
    "y": " cadı"
  },
  {
    "x": "CD (m) (TE)",
    "y": " CD, yoğun disk "
  },
  {
    "x": "cebab (n)",
    "y": " b. cewab (n)"
  },
  {
    "x": "cebernayene",
    "y": " karışmak (dokunmak)\r\n~Qeçekî meceberne! (Çocuğa karışma!)"
  },
  {
    "x": "cebernayîş (n)",
    "y": " karışma (dokunma)"
  },
  {
    "x": "cebhe (n)",
    "y": " b. cephe (n)"
  },
  {
    "x": "cebilxane (n)",
    "y": " cephane "
  },
  {
    "x": "cebilxone (n)",
    "y": " b. cebilxane (n)"
  },
  {
    "x": "cebilxûne (n)",
    "y": " b. cebilxane (n)"
  },
  {
    "x": "cebîr (n)",
    "y": " cebir (mat)"
  },
  {
    "x": "Cebrahîl (n)",
    "y": " b. Cebraîl (n)"
  },
  {
    "x": "Cebraîl (n)",
    "y": " Cebrail"
  },
  {
    "x": "Cebrayîl (n)",
    "y": " b. Cebraîl (n)"
  },
  {
    "x": "cecayene",
    "y": " b. dejayene"
  },
  {
    "x": "ceceno",
    "y": " b. dejeno"
  },
  {
    "x": "cede (n)",
    "y": " b. cehde (n)"
  },
  {
    "x": "cedew (n)",
    "y": " nasır "
  },
  {
    "x": "cedew bîyene",
    "y": " nasırlaşmak, nasırlanmak "
  },
  {
    "x": "cedvel (n)",
    "y": " b. cetwel (n) "
  },
  {
    "x": "cee (n)",
    "y": " b. cehde (n)"
  },
  {
    "x": "ceeb (n)",
    "y": " b. cewab (n)"
  },
  {
    "x": "cefa (m) ",
    "y": " cefa "
  },
  {
    "x": "cefakar,-e",
    "y": " cefakâr, cefakeş, cefalı"
  },
  {
    "x": "cefakêş, -e",
    "y": " cefakâr, cefakeş, cefalı"
  },
  {
    "x": "cegcege (n)",
    "y": " sıyırga"
  },
  {
    "x": "cehalet (n)",
    "y": " bilgisizlik, cehalet"
  },
  {
    "x": "cehd (n)",
    "y": " ceht, çaba, gayret"
  },
  {
    "x": "cehd bîyene",
    "y": " çabalanmak"
  },
  {
    "x": "cehd kerdene",
    "y": " çabalamak, çaba göstermek, çaba harcamak, cehdetmek, gayret etmek, gayret göstermek"
  },
  {
    "x": "cehdbîyayîş (n)",
    "y": " çabalanma"
  },
  {
    "x": "cehde (n)",
    "y": " yol\r\n~cehdeyo sereke (n): anayol, cadde\r\n~cehdeyo tewr sereke (n): anacadde"
  },
  {
    "x": "cehde fîştene",
    "y": " yolcu etmek, geçirmek"
  },
  {
    "x": "cehde vistene",
    "y": " b. cehde fîştene"
  },
  {
    "x": "cehdeyo sereke (n)",
    "y": " anayol, cadde"
  },
  {
    "x": "cehdeyo tewr sereke (n)",
    "y": " anacadde"
  },
  {
    "x": "cehdefîştiş (n)",
    "y": " yolcu etme, geçirme"
  },
  {
    "x": "cehdevistiş (n)",
    "y": " b. cehdefîştiş (n)"
  },
  {
    "x": "cehdi (n)",
    "y": " b. cehde (n)"
  },
  {
    "x": "cehdkerdiş (n)",
    "y": " cehdetme, çabalama, gayret etme"
  },
  {
    "x": "cehelme (n)",
    "y": " b. cehenem (n)"
  },
  {
    "x": "cehenem (n)",
    "y": " cehennem"
  },
  {
    "x": "cehenem-cenet (n)",
    "y": " papatya"
  },
  {
    "x": "cehnim (n)",
    "y": " b. cehenem (n)"
  },
  {
    "x": "cehnim-cennet (n)",
    "y": " b. cehenem-cenet (n)"
  },
  {
    "x": "cehnim-cinet (n)",
    "y": " b. cehenem-cenet (n)"
  },
  {
    "x": "cehnî (m)",
    "y": " b. cana (m)"
  },
  {
    "x": "cehş, -e",
    "y": " b. cehşik, -e"
  },
  {
    "x": "cehşik, -e",
    "y": " sıpa"
  },
  {
    "x": "ceht (n)",
    "y": " b. cehd (n)"
  },
  {
    "x": "ceht kerdene",
    "y": " b. cehd kerdene"
  },
  {
    "x": "cehter (n)",
    "y": " b. cehtire (n)"
  },
  {
    "x": "cehtire (n)",
    "y": " kekik"
  },
  {
    "x": "cehtkerdiş (n)",
    "y": " b. cehdkerdiş (n)"
  },
  {
    "x": "ceket (n)",
    "y": " b. çakêt (n)"
  },
  {
    "x": "celad, -e",
    "y": " cellat"
  },
  {
    "x": "celadênî (m)",
    "y": " cellatlık"
  },
  {
    "x": "celadîye (m)",
    "y": " cellatlık"
  },
  {
    "x": "celat, -e",
    "y": " b. celad, -e"
  },
  {
    "x": "celaw (m)",
    "y": " b. celawe (m)"
  },
  {
    "x": "celawe (m)",
    "y": " kar helvası"
  },
  {
    "x": "celaya (m)",
    "y": " b. celîyaya (m)"
  },
  {
    "x": "celaye (n)",
    "y": " b. celîyaye (n)"
  },
  {
    "x": "celayene",
    "y": " b. celîyayene"
  },
  {
    "x": "celayîye (m)",
    "y": " b. celîyayîye (m)"
  },
  {
    "x": "celb (n)",
    "y": " celp "
  },
  {
    "x": "celb kerdene",
    "y": " celp etmek"
  },
  {
    "x": "celbname (n)",
    "y": " celpname "
  },
  {
    "x": "cele (n)",
    "y": " biçilip yerde üst üste dizilmiş olan ekin yığını"
  },
  {
    "x": "celeb (n)",
    "y": " 1)hayvan tüccarı \r\n~2) küçükbaş hayvan sürüsü"
  },
  {
    "x": "celev (n)",
    "y": " b. celeb (n)"
  },
  {
    "x": "celeyene",
    "y": " b. celîyayene"
  },
  {
    "x": "celeyîye (m)",
    "y": " b. celîyayîye (m)"
  },
  {
    "x": "celênayene",
    "y": " kızdırmak, sinirlendirmek\r\n~Polatî ewro Gulîstane zaf celênaya. (Polat bugün Gülistan’ı çok kızdırmış.)\r\n~\t\t\r\n~Roşna ewro Sînaya zaf celênaya. (Roşna bugün Sinaya’yı çok sinirlendirmiş.)"
  },
  {
    "x": "celênayîş (n)",
    "y": " kızdırma, sinirlendirme"
  },
  {
    "x": "celi (n)",
    "y": " b. cele (n)"
  },
  {
    "x": "celînayene",
    "y": " b. celênayene"
  },
  {
    "x": "celînayîş (n)",
    "y": " b. celênayîş (n)"
  },
  {
    "x": "celîyaya (m)",
    "y": " kızgın (öfkeli)"
  },
  {
    "x": "celîyaye (n)",
    "y": " kızgın (öfkeli)"
  },
  {
    "x": "celîyayene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)\r\n~Felat Korja ra celîyêno. (Felat Korja’yı çekemiyor.)\r\n~\t\t\r\n~Roşan Azadî ra celîyêno. (Roşan Azad’ı kıskanıyor.)"
  },
  {
    "x": "celîyayîye (m)",
    "y": " kızgın (öfkeli)"
  },
  {
    "x": "cellad, -e",
    "y": " b. celad, -e"
  },
  {
    "x": "cellat (n)",
    "y": " b. celad (n)"
  },
  {
    "x": "cellawi (m)",
    "y": " b. celawe (m)"
  },
  {
    "x": "cellaya (m)",
    "y": " b. celîyaya (m)"
  },
  {
    "x": "cellaye (n)",
    "y": " b. celîyaye (n)"
  },
  {
    "x": "cellayene",
    "y": " b. celîyayene"
  },
  {
    "x": "cellayîye (m)",
    "y": " b. celîyayîye (m)"
  },
  {
    "x": "celleya (m)",
    "y": " b. celîyaya (m)"
  },
  {
    "x": "celleye (n)",
    "y": " b. celîyaye (n)"
  },
  {
    "x": "celleyene",
    "y": " b. celîyayene"
  },
  {
    "x": "celleyîye (m)",
    "y": " b. celîyayîye (m)"
  },
  {
    "x": "cellênayene",
    "y": " b. celênayene"
  },
  {
    "x": "cellênayîş (n)",
    "y": " b. celênayîş (n)"
  },
  {
    "x": "cellînayene",
    "y": " b. celênayene"
  },
  {
    "x": "cellînayîş (n)",
    "y": " b. celênayîş (n)"
  },
  {
    "x": "cellîyaya (m)",
    "y": " b. celîyaya (m)"
  },
  {
    "x": "cellîyaye (n)",
    "y": " b. celîyaye (n)"
  },
  {
    "x": "cellîyayene",
    "y": " b. celîyayene"
  },
  {
    "x": "cellîyayîye (m)",
    "y": " b. celîyayîye (m)"
  },
  {
    "x": "cellnayene",
    "y": " b. celênayene"
  },
  {
    "x": "cellnayîş (n)",
    "y": " b. celênayîş (n)"
  },
  {
    "x": "cellûdîyaya (m)",
    "y": " b. celûdîyaya (m)"
  },
  {
    "x": "cellûdîyaye (n)",
    "y": " b. celûdîyaye (n)"
  },
  {
    "x": "cellûdîyayene",
    "y": " b. celûdîyayene"
  },
  {
    "x": "cellûdîyayeyî (m)",
    "y": " celûdîyayeyî (m)"
  },
  {
    "x": "cellûdîyayeyîye (m)",
    "y": " b. celûdîyayeyîye (m)"
  },
  {
    "x": "cellûdîyayeyîyê (m)",
    "y": " b. celûdîyayeyîye (m)"
  },
  {
    "x": "cellûdîyayê (m)",
    "y": " b. b. celûdîyayîye (m)"
  },
  {
    "x": "cellûdîyayi (n)",
    "y": " b. celûdîyaye (n)"
  },
  {
    "x": "cellûdîyayîş (n)",
    "y": " b. celûdîyayîş (n)"
  },
  {
    "x": "cellûdîyayîye (m)",
    "y": " b. celûdîyayîye (m)"
  },
  {
    "x": "cellûdnayene",
    "y": " b. celûdnayene"
  },
  {
    "x": "cellûdnayîş (n)",
    "y": " celûdnayîş (n)"
  },
  {
    "x": "celnayene",
    "y": " b. celênayene"
  },
  {
    "x": "celnayîş (n)",
    "y": " b. celênayîş (n)"
  },
  {
    "x": "celqnayene",
    "y": " yaymak (yayık vb. için)"
  },
  {
    "x": "celqnayiş (n)",
    "y": " b. celqnayîş"
  },
  {
    "x": "celqnayîş (n)",
    "y": " yayma (yayık vb. için)"
  },
  {
    "x": "celud, -e",
    "y": " b. celûd, -e"
  },
  {
    "x": "celud kerdene",
    "y": " b. celûd kerdene"
  },
  {
    "x": "celudî (m)",
    "y": " b. celûdî (m)"
  },
  {
    "x": "celudî kerdene",
    "y": " b. celûdî kerdene"
  },
  {
    "x": "celudîye (m)",
    "y": " b. celûdîye (m)"
  },
  {
    "x": "celudîye kerdene",
    "y": " b. celûdîye kerdene"
  },
  {
    "x": "celudnayene",
    "y": " b. celûdnayene"
  },
  {
    "x": "celûd, -e",
    "y": " kıskanç, günücü (sevdiğini kıskanan)\r\n~Cinîke tersaynê ke camêrdanê bînan de qisey zî biko çunke mêrdeyê aye zaf celûd bî. (Kadın başka erkeklerle konuşmaktan da korkuyordu çünkü kocası çok kıskançtı.) "
  },
  {
    "x": "celûd kerdene",
    "y": " kıskandırmak (sevdiğini kıskandırmak)"
  },
  {
    "x": "celûdî (m)",
    "y": " kıskançlık, günü, günücülük (sevdiğini kıskanma)"
  },
  {
    "x": "celûdî kerdene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "celûdîyaya (m)",
    "y": " dargın, küskün, küs"
  },
  {
    "x": "celûdîyaye (n)",
    "y": " dargın, küskün, küs"
  },
  {
    "x": "celûdîyayene",
    "y": " 1)kıskanmak, günülemek, haset etmek (çekememek) \r\n~2)darılmak, küsmek"
  },
  {
    "x": "celûdîyayeyî (m)",
    "y": " dargınlık, küskünlük, burukluk"
  },
  {
    "x": "celûdîyayeyîye (m)",
    "y": " dargınlık, küskünlük, burukluk"
  },
  {
    "x": "celûdîyayê (m)",
    "y": " b. celûdîyayîye (m)"
  },
  {
    "x": "celûdîyayi (n)",
    "y": " b. celûdîyaye (n)"
  },
  {
    "x": "celûdîyayîş (n)",
    "y": " küsme"
  },
  {
    "x": "celûdîyayîye (m)",
    "y": " dargın, küskün, küs"
  },
  {
    "x": "celûdîye (m)",
    "y": " kıskançlık, günü, günücülük (sevdiğini kıskanma)"
  },
  {
    "x": "celûdîye kerdene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "celûdnayene",
    "y": " 1)kıskandırmak (sevdiğini kıskandırmak) \r\n~2)darıltmak, küstürmek"
  },
  {
    "x": "celûdnayîş (n)",
    "y": " darıltma, küstürme"
  },
  {
    "x": "cem (n)",
    "y": " öğün\r\n~cemê şanî (n): 1)öğleden sonra 2)akşam öğünü\r\n~cemê şodirî (n): 1)öğleden önce 2)sabah öğünü"
  },
  {
    "x": "cemê sanî (n)",
    "y": " b. cemê şanî (n)"
  },
  {
    "x": "cemê sibayî (n)",
    "y": " 1)öğleden önce \r\n~2)sabah öğünü"
  },
  {
    "x": "cemê sodirî (n)",
    "y": " b. cemê şodirî (n)"
  },
  {
    "x": "cemê sonî (n)",
    "y": " b. cemê şanî (n)"
  },
  {
    "x": "cemê şanî (n)",
    "y": " 1)öğleden sonra \r\n~2)akşam öğünü"
  },
  {
    "x": "cemê şodirî (n)",
    "y": " 1)öğleden önce \r\n~2)sabah öğünü"
  },
  {
    "x": "cêm sibay (n)",
    "y": " b. cemê sibayî (n)"
  },
  {
    "x": "cêm şonî (n)",
    "y": " b. cemê şanî (n)"
  },
  {
    "x": "cem bîyene",
    "y": " toplanmak"
  },
  {
    "x": "cem kerdene",
    "y": " toplamak"
  },
  {
    "x": "cem … ginayene",
    "y": " yellenmek, hava kaçırmak, osurmak"
  },
  {
    "x": "cem girewtene",
    "y": " havasını almak"
  },
  {
    "x": "cema (m)",
    "y": " istif, yığın"
  },
  {
    "x": "cema kerdene",
    "y": " istiflemek, istif etmek, yığmak, loda etmek"
  },
  {
    "x": "cemaat (n)",
    "y": " b. cemat (n)"
  },
  {
    "x": "cemaet (n)",
    "y": " b. cemat (n)"
  },
  {
    "x": "cemat (n)",
    "y": " 1)belli amaçlar için veya belli sorunları çözmek için bir araya gelmiş olan topluluk, cemaat \r\n~2)camia \r\n~3)cumhur"
  },
  {
    "x": "cematkî, -ye-I",
    "y": " toplumsal, sosyal, içtimai"
  },
  {
    "x": "cematkî-II (n)",
    "y": " bağdaş"
  },
  {
    "x": "cematkî roniştene",
    "y": " bağdaş kurmak"
  },
  {
    "x": "cembaz, -i",
    "y": " b. canbaz, -e"
  },
  {
    "x": "cembazey (m)",
    "y": " b. canbazîye (m)"
  },
  {
    "x": "cembîyayîş (n)",
    "y": " toplanma"
  },
  {
    "x": "cemeat (n)",
    "y": " b. cemat (n)"
  },
  {
    "x": "cemeati (n)",
    "y": " b. cemat (n)"
  },
  {
    "x": "cemed (n)",
    "y": " buz"
  },
  {
    "x": "cemed bestene",
    "y": " buz bağlamak, buz tutmak, buzlanmak"
  },
  {
    "x": "cemed bîyene",
    "y": " buzlaşmak"
  },
  {
    "x": "cemed girê dayene",
    "y": " buz bağlamak, buz tutmak, buzlanmak"
  },
  {
    "x": "cemed girewtene",
    "y": " buz bağlamak, buz tutmak, buzlanmak"
  },
  {
    "x": "cemedbestiş (n)",
    "y": " buzlanma"
  },
  {
    "x": "cemedbîyayîş (n)",
    "y": " buzlaşma"
  },
  {
    "x": "cemeddan (n)",
    "y": " buzluk"
  },
  {
    "x": "cemedgirêdayîş (n)",
    "y": " buzlanma"
  },
  {
    "x": "cemedgirewtiş (n)",
    "y": " buzlanma"
  },
  {
    "x": "cemedin, -e",
    "y": " buzlu"
  },
  {
    "x": "cemedîyayene",
    "y": " b. cemidîyayene"
  },
  {
    "x": "cemedîyayîş (n)",
    "y": " b. cemidîyayîş (n)"
  },
  {
    "x": "cemednaye (n)",
    "y": " sonbaharda geç sürülen çift"
  },
  {
    "x": "cemednayene",
    "y": " b. cemidnayene"
  },
  {
    "x": "cemednayîş (n)",
    "y": " b. cemidnayîş (n)"
  },
  {
    "x": "cemet (n)",
    "y": " b. cemat (n)"
  },
  {
    "x": "cemid (n)",
    "y": " cemed (n)"
  },
  {
    "x": "cemid bestene",
    "y": " b. cemed bestene"
  },
  {
    "x": "cemideyîş (n)",
    "y": "b. cemidîyayîş (n)"
  },
  {
    "x": "cemidîyayene",
    "y": " 1)buzlanmak, buzlaşmak \r\n~2)donmak"
  },
  {
    "x": "cemidîyayîş (n)",
    "y": " 1)buzlanma, buzlaşma \r\n~2)donma"
  },
  {
    "x": "cemidnayene",
    "y": " dondurmak"
  },
  {
    "x": "cemidnayîş (n)",
    "y": " dondurma"
  },
  {
    "x": "cemidyeyîş (n)",
    "y": "b. cemidîyayîş (n)"
  },
  {
    "x": "cemkerdiş (n)",
    "y": " toplama"
  },
  {
    "x": "cemle (n)",
    "y": " b. cemre (n)"
  },
  {
    "x": "cemlê aw (n)",
    "y": " b. cemreyê awe (n)"
  },
  {
    "x": "cemlê erdî (n)",
    "y": " b. cemreyê erdî (n)"
  },
  {
    "x": "cemlê heway (n)",
    "y": " b. cemreyê hewayî (n)"
  },
  {
    "x": "cemli (n)",
    "y": " b. cemre (n)"
  },
  {
    "x": "cemre (n)",
    "y": " cemre\r\n~cemreyê awe (n): su cemresi (Rumi takvime göre 14-15 Şubat gecesi başlar.)\r\n~cemreyê erdî (n): toprak cemresi (Rumi takvime göre 22-23 Şubat gecesi başlar.)\r\n~cemreyê hewayî (n): hava cemresi (Rumi takvime göre 6-7 Şubat gecesi başlar.)"
  },
  {
    "x": "cemreyê awe (n)",
    "y": " su cemresi (Rumi takvime göre 14-15 Şubat gecesi başlar.)"
  },
  {
    "x": "cemreyê erdî (n)",
    "y": " toprak cemresi (Rumi takvime göre 22-23 Şubat gecesi başlar.)"
  },
  {
    "x": "cemreyê hewayî (n)",
    "y": " hava cemresi (Rumi takvime göre 6-7 Şubat gecesi başlar.)"
  },
  {
    "x": "cemrê aw (n)",
    "y": " b. cemreyê awe (n)"
  },
  {
    "x": "cemrê erdî (n)",
    "y": " b. cemreyê erdî (n)"
  },
  {
    "x": "cemrê heway (n)",
    "y": " b. cemreyê hewayî (n)"
  },
  {
    "x": "cemri (n)",
    "y": " b. cemre (n)"
  },
  {
    "x": "cemse (m)",
    "y": " cemse"
  },
  {
    "x": "cemûd (n)",
    "y": " cemed (n)"
  },
  {
    "x": "cenawar (n)",
    "y": " b. cinawir (n)"
  },
  {
    "x": "cenawer (n)",
    "y": " b. cinawir (n)"
  },
  {
    "x": "cenawir (n)",
    "y": " b. cinawir (n)"
  },
  {
    "x": "cenayene",
    "y": " çalmak (zil vs.)"
  },
  {
    "x": "zengil cenayene",
    "y": " zil çalmak\r\n~Zengilî cena. (Zil çaldı.)"
  },
  {
    "x": "cenayet (n)",
    "y": " b. cînayet (n)"
  },
  {
    "x": "cenayîş (n)",
    "y": " çalma (zil vs.)"
  },
  {
    "x": "cenaza (m)",
    "y": " cenaze"
  },
  {
    "x": "cenaze (m)",
    "y": " b. cenaza (m)"
  },
  {
    "x": "cenc, -e",
    "y": " b. genc, -e"
  },
  {
    "x": "cencenî (m)",
    "y": " b. gencênî (m)"
  },
  {
    "x": "cencênî (m)",
    "y": " b. gencênî (m)"
  },
  {
    "x": "cencîye (m)",
    "y": " b. gencîye (m)"
  },
  {
    "x": "cendeg (n)",
    "y": " ceset, naaş\r\n~cendegê goştî (n): karkas (et için)"
  },
  {
    "x": "cendegê goştî (n)",
    "y": " karkas (et için)"
  },
  {
    "x": "cenderme (n)",
    "y": " b. cendirme (n)"
  },
  {
    "x": "cendermi (n)",
    "y": " b. cendirme (n)"
  },
  {
    "x": "cendirme (n)",
    "y": " jandarma "
  },
  {
    "x": "cenek (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "ceneki (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "ceneme (n)",
    "y": " b. cehenem (n)"
  },
  {
    "x": "cenet (n)",
    "y": " cennet"
  },
  {
    "x": "ceneyki (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "ceneza (m)",
    "y": " b. cenaza (m)"
  },
  {
    "x": "cenêki (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "ceng-I (n)",
    "y": " savaş, harp, cenk\r\n~cengo psîkolojîk (n): psikolojik savaş"
  },
  {
    "x": "cengo psîkolojîk (n)",
    "y": " psikolojik savaş"
  },
  {
    "x": "ceng-II (m)",
    "y": " b. zenge (m)"
  },
  {
    "x": "cengar-I (n)",
    "y": " b. zengar-II (n)"
  },
  {
    "x": "cengar-II (m)",
    "y": " b. zengare (m)"
  },
  {
    "x": "cengari (m)",
    "y": " b. zengare (m)"
  },
  {
    "x": "cengari bestene",
    "y": " b. zengare bestene"
  },
  {
    "x": "cengari girewtene",
    "y": " b. zengare girewtene"
  },
  {
    "x": "cengarin, -e",
    "y": " b. zengarin, -e"
  },
  {
    "x": "cengarin, -i",
    "y": " b. zengarin, -e"
  },
  {
    "x": "cengawer, -e",
    "y": " savaşçı (çok iyi savaşan), cenkçi, cengâver"
  },
  {
    "x": "cengawerane",
    "y": " cengâverce"
  },
  {
    "x": "cengawerî (m)",
    "y": " savaşçılık, cengâverlik"
  },
  {
    "x": "cengawerîye (m)",
    "y": " savaşçılık, cengâverlik"
  },
  {
    "x": "cengi (m)",
    "y": " b. zenge (m)"
  },
  {
    "x": "cengi bestene",
    "y": " b. zenge bestene"
  },
  {
    "x": "cengi girewtene",
    "y": " b. zenge girewtene"
  },
  {
    "x": "cengi perayene bede",
    "y": " b. zenge perayene bide"
  },
  {
    "x": "cengi perayene bere",
    "y": " b. zenge perayene bide"
  },
  {
    "x": "cengin, -e",
    "y": " b. zengin, -e"
  },
  {
    "x": "cengin, -i",
    "y": " b. zengin, -e"
  },
  {
    "x": "cengwaz, -e",
    "y": " savaşçı, cenkçi (savaş yandaşı), "
  },
  {
    "x": "cengwazî (m)",
    "y": " savaşçılık"
  },
  {
    "x": "cengwazîye (m)",
    "y": " savaşçılık"
  },
  {
    "x": "cenig (m)",
    "y": " b. cînege (m)"
  },
  {
    "x": "cenik (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cenike (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cenim (n)",
    "y": " b. cehenem (n)"
  },
  {
    "x": "cenit (n)",
    "y": " b. cenet (n)"
  },
  {
    "x": "cenî (m)",
    "y": " b. cinî (m)"
  },
  {
    "x": "cenîya biray",
    "y": " b. biracinî (m)"
  },
  {
    "x": "cenîya keyî (m)",
    "y": " b. cinîya keyeyî (m)"
  },
  {
    "x": "cenîyey keyî (m)",
    "y": " b. cinîya keyeyî (m)"
  },
  {
    "x": "cenîke (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cenîki (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cenîn (n)",
    "y": " cenin "
  },
  {
    "x": "cenîye (m)",
    "y": " b. cinîye (m)"
  },
  {
    "x": "cennet (n)",
    "y": " b. cenet (n)"
  },
  {
    "x": "centîlmen (n)",
    "y": " centilmen"
  },
  {
    "x": "centîlmenane",
    "y": " centilmence"
  },
  {
    "x": "centîlmenîye (m)",
    "y": " centilmenlik"
  },
  {
    "x": "cenyek (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cenyeke (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cepe (n)",
    "y": " b. cephe (n)"
  },
  {
    "x": "cephe (n)",
    "y": " (savaş için) cephe"
  },
  {
    "x": "ceqla (m)",
    "y": " b. çeqla (m)"
  },
  {
    "x": "cer (n)",
    "y": " testi"
  },
  {
    "x": "cera bîyayene",
    "y": " b. cêra bîyene"
  },
  {
    "x": "cerabîyayîş (n)",
    "y": " b. cêrabîyayîş (n)"
  },
  {
    "x": "cerah, -e",
    "y": " operatör, cerrah"
  },
  {
    "x": "cerahey (m)",
    "y": " b. cerahîye (m)"
  },
  {
    "x": "cerahê (m)",
    "y": " b. cerahîye (m)"
  },
  {
    "x": "cerahî, -ye",
    "y": " cerrahi"
  },
  {
    "x": "cerahîye (m)",
    "y": " cerrahlık"
  },
  {
    "x": "cerakerdiş (n)",
    "y": " b. cêrakerdiş (n)"
  },
  {
    "x": "cerasun bîyene",
    "y": " cesaretlenmek"
  },
  {
    "x": "cerasunbîyayîş (n)",
    "y": " cesaretlenme"
  },
  {
    "x": "cerayîye (m)",
    "y": " b. cerahîye (m)"
  },
  {
    "x": "ceraze (m)",
    "y": " b. gilyaze (m)"
  },
  {
    "x": "cere (n)",
    "y": " b. cerah (n)"
  },
  {
    "x": "cerebnayene",
    "y": " b. ceribnayene"
  },
  {
    "x": "cerebnayîş (n)",
    "y": " b. ceribnayîş (n)"
  },
  {
    "x": "cereyan (n)",
    "y": " 1)akım, cereyan\r\n~cereyano edebî (n): edebi akım, edebi cereyan\r\n~2)elektrik"
  },
  {
    "x": "cereyano edebî (n)",
    "y": " edebi akım, edebi cereyan"
  },
  {
    "x": "cerg (m)",
    "y": " b. cerge (m)"
  },
  {
    "x": "cerge (m)",
    "y": " 1)insan kümesi, insan grubu, kafile \r\n~2)hayvan kümesi, küme"
  },
  {
    "x": "cergi (m)",
    "y": " b. cerge (m)"
  },
  {
    "x": "cerib (n)",
    "y": " deney"
  },
  {
    "x": "ceribî, -ye",
    "y": " deneysel, ampirik"
  },
  {
    "x": "ceribnayene",
    "y": " denemek, sınamak"
  },
  {
    "x": "ceribnayîş (n)",
    "y": " deneme, sınama\r\n~ceribnayîşo edebî (n): edebi deneme"
  },
  {
    "x": "ceribnayîşo edebî (n)",
    "y": " edebi deneme"
  },
  {
    "x": "ceripnayîs (n)",
    "y": " b. ceribnayîş (n)"
  },
  {
    "x": "cerînayene",
    "y": " b. cirênayene"
  },
  {
    "x": "cerr-I (n)",
    "y": " b. cer (n)"
  },
  {
    "x": "cerr-II (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "cerre (n)",
    "y": " b. cerah (n)"
  },
  {
    "x": "çerr-II (n)",
    "y": " b. gerr (n)"
  },
  {
    "x": "cerrah, -e",
    "y": " b. cerah, -e"
  },
  {
    "x": "cerrahî (m)",
    "y": " b. cerahî (m)"
  },
  {
    "x": "ceryon (n)",
    "y": " b. cereyan (n)"
  },
  {
    "x": "ceryûn (n)",
    "y": " b. cereyan (n)"
  },
  {
    "x": "cesaret (n)",
    "y": " cesaret"
  },
  {
    "x": "cesaret ameyene bede",
    "y": " b. cesaret ameyene bide"
  },
  {
    "x": "cesaret ameyene bere",
    "y": " b. cesaret ameyene bide"
  },
  {
    "x": "cesaret ameyene bide",
    "y": " (birine) cesaret gelmek, (biri) cesarete gelmek, cesaretlenmek"
  },
  {
    "x": "cesaret ameyene ci",
    "y": " (birine) cesaret gelmek, (biri) cesarete gelmek, cesaretlenmek"
  },
  {
    "x": "cesaret ci ameyene",
    "y": " b. cesaret ameyene ci"
  },
  {
    "x": "cesaret dayene",
    "y": " cesaret vermek, cesaretlendirmek"
  },
  {
    "x": "cesaret deyayîne",
    "y": " b. cesaret dîyayene"
  },
  {
    "x": "cesaret dêrîyayene",
    "y": " cesaretlendirilmek, yüreklendi rilmek"
  },
  {
    "x": "cesaret dîyayene",
    "y": " cesaretlendirilmek, yüreklendi rilmek "
  },
  {
    "x": "cesaret girewtene",
    "y": " cesaret  bulmak, cesaret almak "
  },
  {
    "x": "cesaret girotene",
    "y": " b. cesaret girewtene"
  },
  {
    "x": "cesaret guretene",
    "y": " b. cesaret girewtene"
  },
  {
    "x": "cesaret kerdene",
    "y": " cesaret etmek, cüret etmek"
  },
  {
    "x": "cesaret re ci ameyene",
    "y": " (birine) cesaret gelmek, (biri) cesarete gelmek, cesaretlenmek"
  },
  {
    "x": "cesaretameyîş ...",
    "y": " cesaretlenme"
  },
  {
    "x": "cesaretciameyîş (n)",
    "y": " b. cesaretameyîş ..."
  },
  {
    "x": "cesaretdayîş (n)",
    "y": " cesaretlendirme"
  },
  {
    "x": "cesaretdeyayîş (n)",
    "y": " b. cesaretdîyayîş (n)"
  },
  {
    "x": "cesaretdêrîyayîş (n)",
    "y": " cesaretlendirilme, yüreklendirilme"
  },
  {
    "x": "cesaretdîyayîş (n)",
    "y": " cesaretlendirilme, yüreklendirilme "
  },
  {
    "x": "cesaretgirewtiş (n)",
    "y": " cesaret  bulma, cesaret alma"
  },
  {
    "x": "cesaretgirotiş (n)",
    "y": " b. cesaretgirewtiş (n)"
  },
  {
    "x": "cesaretguretiş (n)",
    "y": " b. cesaretgirewtiş (n)"
  },
  {
    "x": "cesaretkerdiş (n)",
    "y": " cesaret etme, cüret etme"
  },
  {
    "x": "cesaretreciameyîş (n)",
    "y": " cesaretlenme"
  },
  {
    "x": "ceseretkerdiş (n)",
    "y": " b. cesaretkerdiş (n)"
  },
  {
    "x": "cesur, -e",
    "y": " cesaretli, cesur, yürekli"
  },
  {
    "x": "cesur bîyene",
    "y": " cesaretlenmek"
  },
  {
    "x": "cesurbîyayîş (n)",
    "y": " cesaretlenme"
  },
  {
    "x": "cesurey (m)",
    "y": " b. cesurîye (m)"
  },
  {
    "x": "cesurê (m)",
    "y": " b. cesurî (m)"
  },
  {
    "x": "cesurî (m)",
    "y": " cesaretlilik, cesurluk, yüreklilik"
  },
  {
    "x": "cesurîye (m)",
    "y": " cesaretlilik, cesurluk, yüreklilik"
  },
  {
    "x": "cesûr, -e",
    "y": " b. cesur, -e"
  },
  {
    "x": "cesûrê (m)",
    "y": " b. cesurî (m)"
  },
  {
    "x": "cesûrî (m)",
    "y": " b. cesurî (m)"
  },
  {
    "x": "cetvel (n)",
    "y": " b. cetwel (n)"
  },
  {
    "x": "cetwel (n)",
    "y": " cetvel"
  },
  {
    "x": "cevervetiş (n)",
    "y": " b. cêvervetiş (n)"
  },
  {
    "x": "cew (n)",
    "y": " arpa"
  },
  {
    "x": "cewab (n)",
    "y": " yanıt, cevap"
  },
  {
    "x": "cewaben",
    "y": " cevaben"
  },
  {
    "x": "cewabî, -ye",
    "y": " cevabi"
  },
  {
    "x": "cewabname (n)",
    "y": " cevap anahtarı"
  },
  {
    "x": "cewahîr (n)",
    "y": " cevahir"
  },
  {
    "x": "cewav (n)",
    "y": " b. cewab (n)"
  },
  {
    "x": "cewer (n)",
    "y": " b. cewher (n)"
  },
  {
    "x": "cewher (n)",
    "y": " cevher, gevher, töz"
  },
  {
    "x": "cewrik, -e",
    "y": " enik, encik, encek, köpek yavrusu"
  },
  {
    "x": "cewrik ardene",
    "y": " (köpek için) doğurmak, eniklemek"
  },
  {
    "x": "cewzerd (n)",
    "y": " b. cewzerdî (zh)"
  },
  {
    "x": "cewzerdî (zh)",
    "y": " arpaların sararma zamanı"
  },
  {
    "x": "cewzerî (zh)",
    "y": " b. cewzerdî (zh)"
  },
  {
    "x": "cexra (m)",
    "y": " b. cixara (m)"
  },
  {
    "x": "cexre (m)",
    "y": " b. cixara (m)"
  },
  {
    "x": "cey (n, z)",
    "y": " b. ey (n, z)"
  },
  {
    "x": "ceyb (n)",
    "y": " b. cêbe (m)"
  },
  {
    "x": "ceynek (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "ceynî (m)",
    "y": " b. cinî (m)"
  },
  {
    "x": "ceyran (n)",
    "y": " b. cereyan (n)"
  },
  {
    "x": "ceyron (n)",
    "y": " b. cereyan (n)"
  },
  {
    "x": "ceyrûn (n)",
    "y": " b. cereyan (n)"
  },
  {
    "x": "cez (n)",
    "y": " b. gez (n) "
  },
  {
    "x": "ceza (m)",
    "y": " ceza, yaptırım, müeyyide\r\n~cezaya bêpeynîye (m): müebbet ceza\r\n~cezaya girane (m): ağır ceza\r\n~cezaya pereyan (m): para cezası\r\n~cezaya sivike (m): hafif ceza"
  },
  {
    "x": "ceza dayene",
    "y": " ceza vermek, cezalandırmak, tecziye etmek"
  },
  {
    "x": "ceza girewtene",
    "y": " cezalandırılmak"
  },
  {
    "x": "ceza kerdene",
    "y": " ceza vermek, cezalandırmak, tecziye etmek"
  },
  {
    "x": "ceza werdene",
    "y": " ceza verilmek, cezaya çarptırılmak, cezalandırılmak"
  },
  {
    "x": "cezaya bêpeynî (m)",
    "y": " b. cezaya bêpeynîye (m)"
  },
  {
    "x": "cezaya bêpeynîye (m)",
    "y": " müebbet ceza"
  },
  {
    "x": "cezaya dîsîplînî (m)",
    "y": " disiplin cezası"
  },
  {
    "x": "cezaya girane (m)",
    "y": " ağır ceza "
  },
  {
    "x": "cezaya hepisî (m)",
    "y": " hapis cezası"
  },
  {
    "x": "cezaya mergî (m)",
    "y": " ölüm cezası "
  },
  {
    "x": "cezaya muebede (m)",
    "y": " müebbet ceza"
  },
  {
    "x": "cezaya pereyan (m)",
    "y": " para cezası"
  },
  {
    "x": "cezaya pereyan ya girane (m)",
    "y": " ağır para cezası"
  },
  {
    "x": "cezaya sivike (m)",
    "y": " hafif ceza"
  },
  {
    "x": "cezaya trafîkîramenkerdişî (m)",
    "y": " trafiğe çıkmama cezası"
  },
  {
    "x": "cezaya zîndanî (m)",
    "y": " zindan cezası, hapis cezası"
  },
  {
    "x": "Cezayîr",
    "y": " Cezayir"
  },
  {
    "x": "cezbe (n)",
    "y": " b. cezwe (n)"
  },
  {
    "x": "cezve (n)",
    "y": " b. cezwe (n)"
  },
  {
    "x": "cezvi (n)",
    "y": " b. cezwe (n)"
  },
  {
    "x": "cezwe (n)",
    "y": " cezve"
  },
  {
    "x": "cezadayîş (n)",
    "y": " cezalandırma, tecziye"
  },
  {
    "x": "cezagirewtiş (n)",
    "y": " cezalandırılma"
  },
  {
    "x": "cezaî, -ye",
    "y": " b. cezayî, -ye"
  },
  {
    "x": "cezakerdiş (n)",
    "y": " cezalandırma, tecziye"
  },
  {
    "x": "cezawerdiş (n)",
    "y": " cezalandırılma"
  },
  {
    "x": "cezaya girane (m)",
    "y": " ağır ceza"
  },
  {
    "x": "cezayî, -ye",
    "y": " cezai "
  },
  {
    "x": "cê-I (m, n, z)",
    "y": "1)b. ey-I (n, z) \r\n~2) b. ci (m, n, z)"
  },
  {
    "x": "cê-II",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "cê bîyene",
    "y": " ayrılmak"
  },
  {
    "x": "cê kerdene",
    "y": " ayırmak"
  },
  {
    "x": "cêde kerdene",
    "y": " parçalamak"
  },
  {
    "x": "cêde kewtene",
    "y": " parçalanmak"
  },
  {
    "x": "cêde vetene",
    "y": " soymak (giysisini çıkarmak, elbisesini çıkarmak)"
  },
  {
    "x": "cêdi ancîyayene",
    "y": " b. cêde ancîyayene"
  },
  {
    "x": "cêdi antene",
    "y": " b. cêde antene"
  },
  {
    "x": "cêdi kerdene",
    "y": " b. cêde kerdene"
  },
  {
    "x": "cêdi kotene",
    "y": " b. cêde kewtene"
  },
  {
    "x": "cêdi kowtene",
    "y": " b. cêde kewtene"
  },
  {
    "x": "cêdi vetene",
    "y": " b. cêde vetene"
  },
  {
    "x": "cêra birnayene",
    "y": " ayırmak"
  },
  {
    "x": "cêra bîyayene",
    "y": " b. cêra bîyene"
  },
  {
    "x": "cêra bîyene",
    "y": " ayrılmak, boşanmak"
  },
  {
    "x": "cêra kerdene",
    "y": " ayırmak"
  },
  {
    "x": "cêra sereşnayene",
    "y": " b. cêra şinasnayene"
  },
  {
    "x": "cêra silasnayene",
    "y": " b. cêra şinasnayene"
  },
  {
    "x": "cêra sinaşnayene",
    "y": " b. cêra şinasnayene"
  },
  {
    "x": "cêra siraşnayene",
    "y": " b. cêra şinasnayene"
  },
  {
    "x": "cêra şinasnayene",
    "y": " fark etmek, ayrımında olmak, ayrımsamak"
  },
  {
    "x": "cêra vejîyayene",
    "y": " ayırt edilmek, tefrik edilmek, ayrılmak"
  },
  {
    "x": "cêra veradîyayene",
    "y": " doğurmak (insan için), doğum yapmak"
  },
  {
    "x": "cêra vetene",
    "y": " ayırt etmek, tefrik etmek, ayırmak"
  },
  {
    "x": "cê-III (n)",
    "y": " ca-I (n)"
  },
  {
    "x": "cê vejîyayîne",
    "y": " b. ca ra vejîyayene"
  },
  {
    "x": "cê-IV",
    "y": " b. cîya, -ye"
  },
  {
    "x": "cêb (n)",
    "y": " b. cêbe (m)"
  },
  {
    "x": "cêbe (m)",
    "y": " cep"
  },
  {
    "x": "cêbîyayîş (n)",
    "y": " ayrılma, boşanma"
  },
  {
    "x": "cêbkesan, -e",
    "y": " cepçi (argo), yankesici"
  },
  {
    "x": "cêbkesaney (m)",
    "y": " b. cêbkesanîye (m)"
  },
  {
    "x": "cêbkesanê (m)",
    "y": " b. cêbkesanîye (m)"
  },
  {
    "x": "cêbkesanîye (m)",
    "y": " cepçilik, yankesicilik"
  },
  {
    "x": "cêbkeson, -e",
    "y": " b. cêbkesan, -e"
  },
  {
    "x": "cêbkesoney",
    "y": " b. cêbkesanîye (m)"
  },
  {
    "x": "cêbkesûn, -e",
    "y": " b. cêbkesan, -e"
  },
  {
    "x": "cêbkesûnê (m)",
    "y": " b. cêbkesanîye (m)"
  },
  {
    "x": "cêde ancîyayene",
    "y": " parçalanmak"
  },
  {
    "x": "cêde antene",
    "y": " parçalamak"
  },
  {
    "x": "cêde kerdene",
    "y": " parçalamak"
  },
  {
    "x": "cêde kewtene",
    "y": " parçalanmak"
  },
  {
    "x": "cêdeancîyayîş (n)",
    "y": " parçalanma"
  },
  {
    "x": "cêdeantiş (n)",
    "y": " parçalama"
  },
  {
    "x": "cêdekerdiş (n)",
    "y": " parçalama"
  },
  {
    "x": "cêdekewtiş (n)",
    "y": " parçalanma"
  },
  {
    "x": "cêdeveta (m)",
    "y": " soyunmuş olan"
  },
  {
    "x": "cêdevete (n)",
    "y": " soyunmuş olan"
  },
  {
    "x": "cêdevetiş (n)",
    "y": " soyma (giysisini çıkarma)"
  },
  {
    "x": "cêdiancîyayîş (n)",
    "y": " b. cêdeancîyayîş (n)"
  },
  {
    "x": "cêdiantiş (n)",
    "y": " b. cêdeantiş (n)"
  },
  {
    "x": "cêdikerdiş (n)",
    "y": " b. cêdekerdiş (n)"
  },
  {
    "x": "cêdikotiş (n)",
    "y": " b. cêdekewtiş (n)"
  },
  {
    "x": "cêdikowtiş (n)",
    "y": " b. cêdekewtiş (n)"
  },
  {
    "x": "cêdiveta (m)",
    "y": " b. cêdeveta (m)"
  },
  {
    "x": "cêdivete (n)",
    "y": " b. cêdevete (n)"
  },
  {
    "x": "cêdivetiş (n)",
    "y": " b. cêdevetiş (n)"
  },
  {
    "x": "cêf (n)",
    "y": " b. cîf (n)"
  },
  {
    "x": "cêkerdiş (n)",
    "y": " ayırma"
  },
  {
    "x": "cêko",
    "y": " -den beri"
  },
  {
    "x": "cêko ke yeno vîrê mi",
    "y": " anımsadığımdan beri, hatırladığımdan beri"
  },
  {
    "x": "cêko ki yeno mi vîrî",
    "y": " b. cêko ke yeno vîrê mi"
  },
  {
    "x": "cêkona ki",
    "y": " b. cêko"
  },
  {
    "x": "cêkona ki yeno mi vîrî",
    "y": " b. cêko ke yeno vîrê mi"
  },
  {
    "x": "cêkono ki",
    "y": " b. cêko"
  },
  {
    "x": "cêkono ki yeno mi vîrî",
    "y": " b. cêko ke yeno vîrê mi"
  },
  {
    "x": "cêkû",
    "y": " b. cêko"
  },
  {
    "x": "cêkû ke yeno mi vîrî",
    "y": " b. cêko ke yeno vîrê mi"
  },
  {
    "x": "cêmîyan fînîyayene",
    "y": " karıştırılmak (birbirine dolandırılmak, karıştırılmak)"
  },
  {
    "x": "cêmîyan kerdene",
    "y": " karıştırmak (birbirine karıştırmak, birbirine katmak)"
  },
  {
    "x": "cêmîyan kewtene",
    "y": " karışmak (birbirine dolanmak, karışmak)\r\n~No la zaf cêmîyan kewt. (Bu iplik çok karıştı./Bu iplik karmakarış oldu.)"
  },
  {
    "x": "cêmîyan ra vejîyayene",
    "y": " ayrışmak"
  },
  {
    "x": "cêmîyan ra vetene",
    "y": " ayrıştırmak"
  },
  {
    "x": "cêmîyan vistene",
    "y": " karıştırmak (birbirine dolamak)\r\n~No la to cêmîyan vist. (Bu ipliği sen karıştırdın.)"
  },
  {
    "x": "cêmîyanfînîyayîş (n)",
    "y": " karıştırılma (birbirine dolandırılma, karıştırılma)"
  },
  {
    "x": "cêmîyankerdiş (n)",
    "y": " karıştırma (birbirine karıştırma)"
  },
  {
    "x": "cêmîyankewta (m)",
    "y": " karışık, karmakarışık (birbirine dolanmış olan)"
  },
  {
    "x": "cêmîyankewte (n)",
    "y": " karışık, karmakarışık (birbirine dolanmış olan)\r\n~Porê kêneke cêmîyankewte bî. (Kızın saçları karmakarışıktı.)"
  },
  {
    "x": "cêmîyankewtiş (n)",
    "y": " karışma (birbirine dolanma, karışma)"
  },
  {
    "x": "cêmîyanravejîyayîş (n)",
    "y": " ayrışma"
  },
  {
    "x": "cêmîyanravetiş (n)",
    "y": " ayrıştırma"
  },
  {
    "x": "cêmîyanvistiş (n)",
    "y": " karıştırma (birbirine dolama, karıştırma)"
  },
  {
    "x": "cêmîyon fînîyayene",
    "y": " b. cêmîyan fînîyayene"
  },
  {
    "x": "cêmîyon kerdene",
    "y": " b. cêmîyan kerdene"
  },
  {
    "x": "cêmîyon kewtene",
    "y": " b. cêmîyan kewtene"
  },
  {
    "x": "cêmîyon vistene",
    "y": " b. cêmîyan vistene"
  },
  {
    "x": "cêmîyonfînîyayîş (n)",
    "y": " b. cêmîyanfînîyayîş (n)"
  },
  {
    "x": "cêmîyonkardiş (n)",
    "y": " b. cêmîyankerdiş (n)"
  },
  {
    "x": "cêmîyonkerdiş (n)",
    "y": " b. cêmîyankerdiş (n)"
  },
  {
    "x": "cêmîyonkewta (m)",
    "y": " b. cêmîyankewta (m)"
  },
  {
    "x": "cêmîyonkewte (n)",
    "y": " b. cêmîyankewte (n)"
  },
  {
    "x": "cêmîyonkewtiş (n)",
    "y": " b. cêmîyankewtiş"
  },
  {
    "x": "cêmîyonvistiş (n)",
    "y": " b. cêmîyanvistiş (n)"
  },
  {
    "x": "cêmon kewtene",
    "y": " b. cêmîyan kewtene"
  },
  {
    "x": "cêmon vistene",
    "y": " b. cêmîyan vistene"
  },
  {
    "x": "cêmonkewta (m)",
    "y": " b. cêmîyankewta (m)"
  },
  {
    "x": "cêmonkewte (n)",
    "y": " b. cêmîyankewte (n)"
  },
  {
    "x": "cêmonkewtiş (n)",
    "y": " b. cêmîyankewtiş(n)"
  },
  {
    "x": "cêmonvistiş (n)",
    "y": " b. cêmîyanvistiş (n)"
  },
  {
    "x": "cên girewtene",
    "y": " b. cem girewtene"
  },
  {
    "x": "cênc, -e",
    "y": " b. genc, -e"
  },
  {
    "x": "cêncênî (m)",
    "y": " b. gencênî (m)"
  },
  {
    "x": "cêncîye (m)",
    "y": " b. gencîye (m)"
  },
  {
    "x": "cêne (n)",
    "y": " b. cuwen (n)"
  },
  {
    "x": "cênek (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cêneki (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cênik (m)",
    "y": " b. cînege (m)"
  },
  {
    "x": "cênike (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cênî (m)",
    "y": " b. cinî (m), cinîye (m)"
  },
  {
    "x": "cênîk (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cênîke (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cênîki (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cênîye (m)",
    "y": " b. cinîye (m)"
  },
  {
    "x": "cêr (n)",
    "y": " aşağı"
  },
  {
    "x": "cêr o",
    "y": " b. cêr ro"
  },
  {
    "x": "cêr ra",
    "y": " aşağıdan"
  },
  {
    "x": "cêr ro",
    "y": " aşağıdan"
  },
  {
    "x": "cêr bîyene",
    "y": " b. gêr bîyene"
  },
  {
    "x": "cêr kerdene",
    "y": " b. gêr kerdene"
  },
  {
    "x": "cêrabirnayîş (n)",
    "y": " ayırma"
  },
  {
    "x": "cêrabîyayîş (n)",
    "y": " ayrılma"
  },
  {
    "x": "cêracor",
    "y": " altüst"
  },
  {
    "x": "cêrakerdiş (n)",
    "y": " ayırma"
  },
  {
    "x": "cêkerdiş (n)",
    "y": " ayırma"
  },
  {
    "x": "cêrancor",
    "y": " b. cêracor"
  },
  {
    "x": "cêrasereşnayîş (n)",
    "y": " b. cêraşinasnayîş (n)"
  },
  {
    "x": "cêrasilasnayîş (n)",
    "y": " b. cêraşinasnayîş (n)"
  },
  {
    "x": "cêrasinaşnayîş (n)",
    "y": " b. cêraşinasnayîş (n)"
  },
  {
    "x": "cêrasiraşnayîş (n)",
    "y": " b. cêraşinasnayîş (n)"
  },
  {
    "x": "cêraşinasnayîş (n)",
    "y": " fark etme, ayrımında olma"
  },
  {
    "x": "cêravecîyayîş (n)",
    "y": " b. caravejîyayîş (n)"
  },
  {
    "x": "cêravejîyayîş-I (n)",
    "y": " ayrılma, ayırt edilme, tefrik edilme"
  },
  {
    "x": "cêravejîyayîş-II (n)",
    "y": " b. caravejîyayîş (n)"
  },
  {
    "x": "cêravetiş (n)",
    "y": " ayırt etme, tefrik, ayırma"
  },
  {
    "x": "cêrayox, -e",
    "y": " b. gêrayox, -e"
  },
  {
    "x": "cêrdest, -e",
    "y": " ast"
  },
  {
    "x": "cêrên, -e",
    "y": " aşağı taraftaki, aşağıdaki"
  },
  {
    "x": "cêrin, -e",
    "y": " b. cêrên, -e"
  },
  {
    "x": "cêrî (m)",
    "y": " elti"
  },
  {
    "x": "cêrîn, -e",
    "y": " b. cêrên, -e"
  },
  {
    "x": "cêrîyayêne",
    "y": " b. gîrîyayene"
  },
  {
    "x": "cêrîye (m)",
    "y": " elti"
  },
  {
    "x": "cêrnot (n)",
    "y": " dipnot"
  },
  {
    "x": "cêrnus (n)",
    "y": " altyazı\r\n~cêrnusê nîgarî (n): lejant\r\n~cêrnusê resimî (n): lejant"
  },
  {
    "x": "cêrnusê nîgarî (n)",
    "y": " lejant"
  },
  {
    "x": "cêrnusê resimî (n)",
    "y": " lejant"
  },
  {
    "x": "cêser bîyene",
    "y": " ayrılmak"
  },
  {
    "x": "cêser de vejîyayene",
    "y": " ayırt edilmek, tefrik edilmek"
  },
  {
    "x": "cêser de vetene",
    "y": " ayırt etmek, tefrik etmek"
  },
  {
    "x": "cêser kerdene",
    "y": " ayırmak"
  },
  {
    "x": "cêser kewtene",
    "y": " ayrılmak"
  },
  {
    "x": "cêser vejîyayene",
    "y": " ayrılmak"
  },
  {
    "x": "cêser vetene",
    "y": " ayırmak"
  },
  {
    "x": "cêser vistene",
    "y": " ayırmak"
  },
  {
    "x": "cêserbîyayîş (n)",
    "y": " ayrılma"
  },
  {
    "x": "cêserdevejîyayîş (n)",
    "y": " ayırt edilme, tefrik edilme"
  },
  {
    "x": "cêserdevetiş (n)",
    "y": " ayırt etme, tefrik"
  },
  {
    "x": "cêserfînayîş (n)",
    "y": " cêservistiş (n)"
  },
  {
    "x": "cêserkerdiş (n)",
    "y": " ayırma"
  },
  {
    "x": "cêserkewtiş (n)",
    "y": " ayrılma"
  },
  {
    "x": "cêservejîyayîş (n)",
    "y": " ayrılma"
  },
  {
    "x": "cêservetiş (n)",
    "y": " ayırma"
  },
  {
    "x": "cêservistiş (n)",
    "y": " ayırma"
  },
  {
    "x": "cêsevetiş (n)",
    "y": " b. cêservetiş (n)"
  },
  {
    "x": "cêv (n)",
    "y": " b. cêbe (m)"
  },
  {
    "x": "cêvejîyayîş",
    "y": " b. caravejîyayîş (n)"
  },
  {
    "x": "cêver vetene",
    "y": " ayırmak"
  },
  {
    "x": "cêvervetiş (n)",
    "y": " ayırma"
  },
  {
    "x": "cêvî (m)",
    "y": " vadi, koyak"
  },
  {
    "x": "cêw (m)",
    "y": " b. cêbe (m)"
  },
  {
    "x": "cêwikî",
    "y": " b. cêwî (n)"
  },
  {
    "x": "cêwî (n)",
    "y": " ikiz"
  },
  {
    "x": "cêz (n)",
    "y": " çeyiz"
  },
  {
    "x": "cêzroş, -e",
    "y": " çeyizci"
  },
  {
    "x": "ci (m, n, z)",
    "y": " 1)b. ey (n, z)\r\n~2)b. aye (m, z)"
  },
  {
    "x": "ci amayene",
    "y": " b. ... ameyene"
  },
  {
    "x": "ci ameyîne",
    "y": " b. ... ameyene"
  },
  {
    "x": "ci aqilîyayene",
    "y": " b. ra ci arqilîyayene"
  },
  {
    "x": "ci aqilnayene",
    "y": " b. ra ci arqilnayene"
  },
  {
    "x": "ci bîyayene",
    "y": " b. cibîyene"
  },
  {
    "x": "ci bîyayêne",
    "y": " b. cibîyene"
  },
  {
    "x": "ci bîyene-I",
    "y": " dolmak"
  },
  {
    "x": "ci bîyene-II",
    "y": " koparılmak"
  },
  {
    "x": "ci cêrayene",
    "y": " b. tagêrayene"
  },
  {
    "x": "ci estene",
    "y": " b. ci eştene"
  },
  {
    "x": "ci eştene",
    "y": " 1)katmak, serpmek \r\n~2)koşturmak (binek hayvanına binmiş olarak onu koşturmak)"
  },
  {
    "x": "ci geyrayene",
    "y": " b. ci gêrayene"
  },
  {
    "x": "ci geyrayîne",
    "y": " b. ci gêrayene"
  },
  {
    "x": "ci gêrayene",
    "y": " 1)aramak \r\n~2)araştırmak \r\n~3)kontrol etmek, yoklamak, yoklama yapmak \r\n~4)karışmamak (dokunmamak)\r\n~Cigêre wa şiro. (Karışma gitsin.)"
  },
  {
    "x": "ci ginayene",
    "y": " 1)değmek \r\n~2)dokunmak (yiyecek vb.)"
  },
  {
    "x": "ci îştene",
    "y": " b. ci eştene"
  },
  {
    "x": "ci kerdene-I",
    "y": " b. dekerdene"
  },
  {
    "x": "ci kerdene-II",
    "y": " koparmak"
  },
  {
    "x": "ci kewtene",
    "y": " girmek"
  },
  {
    "x": "ci kotayene",
    "y": " b. ci kewtene, dekewtene"
  },
  {
    "x": "ci kotene",
    "y": " b. ci kewtene, dekewtene"
  },
  {
    "x": "ci mendene",
    "y": " -e benzemek"
  },
  {
    "x": "ci niştene",
    "y": " -e binmek"
  },
  {
    "x": "ci perayene",
    "y": " bulaşmak (hastalık için)"
  },
  {
    "x": "ci perrayene",
    "y": " b. ci perayene"
  },
  {
    "x": "ci r’",
    "y": " b. ci rê, ci ra"
  },
  {
    "x": "ci ra",
    "y": " b. ey ra, aye ra"
  },
  {
    "x": "ci ra bîyene",
    "y": " 1)bezmek, bezginlik getirmek, bıkmak, bizar olmak, usanmak \r\n~2)koparılmak"
  },
  {
    "x": "ci ra çîk hêşkewtiş",
    "y": " b. ci ra çîyê/çîyêk hişkewtiş"
  },
  {
    "x": "ci ra çîyê/çîyêk hêşkewtiş",
    "y": " b. ci ra çîyê/çîyêk hişkewtiş"
  },
  {
    "x": "ci ra çîyê/çîyêk hişkewtiş",
    "y": " bir şeye yarama, -den bir şey anlaşılma"
  },
  {
    "x": "ci ra girewtene",
    "y": " iktibas etmek, aktarmak"
  },
  {
    "x": "ci ra kerdene",
    "y": " koparmak"
  },
  {
    "x": "ci ra pey",
    "y": " ondan sonra"
  },
  {
    "x": "ci ra şanayene",
    "y": " b. tira şanayene"
  },
  {
    "x": "ci ra şîyayene",
    "y": " b. ... ra şîyene"
  },
  {
    "x": "ci resayene",
    "y": " anlamak"
  },
  {
    "x": "ci restene",
    "y": " 1)anlamak \r\n~2)(teke, koç gibi erkek hayvanlar için) çiftleşmek"
  },
  {
    "x": "ci reynayene",
    "y": " b. cirênayene"
  },
  {
    "x": "ci rê",
    "y": " b. ey rê, aye rê, înan rê\r\n~Ci rê vace. (Ona söyle.)"
  },
  {
    "x": "ci rê merdene",
    "y": " delice sevmek (mecazî)"
  },
  {
    "x": "ci ri",
    "y": " b. ey rê, aye rê, înan rê"
  },
  {
    "x": "ci rî",
    "y": " b. ey rê, aye rê, înan rê"
  },
  {
    "x": "ci sawitene",
    "y": " 1)-e sürmek \r\n~2)bulaştırmak (fiziki şeyler için)"
  },
  {
    "x": "ci sawîyayene",
    "y": " 1)-e sürülmek \r\n~2)bulaşmak (fiziki şeyler için)"
  },
  {
    "x": "ci soynaene",
    "y": " b. ci soynayene"
  },
  {
    "x": "ci soynayene",
    "y": " bulaştırmak (fiziki şeyler için)"
  },
  {
    "x": "ci soynîyayene",
    "y": " bulaşmak (fiziki şeyler için)"
  },
  {
    "x": "ci şanayene",
    "y": " 1)içine katmak \r\n~2)-e serpmek"
  },
  {
    "x": "ci şarfînayene",
    "y": " b. ci şerfînayene"
  },
  {
    "x": "ci şarfnîyayene",
    "y": " b. ci şerfînîyayene"
  },
  {
    "x": "ci şayîne",
    "y": " b. ci şanayene"
  },
  {
    "x": "ci şerfînayene",
    "y": " (hayvan veya insan için) ürkütmek"
  },
  {
    "x": "ci şerfînîyayene",
    "y": " (hayvan veya insan için) ürkütülmek"
  },
  {
    "x": "ci şerfnayene",
    "y": " b. ci şerfînayene"
  },
  {
    "x": "ci şerfnîyayene",
    "y": " b. ci şerfînîyayene"
  },
  {
    "x": "ci şonayîne",
    "y": " b. ci şanayene"
  },
  {
    "x": "ci ûmeyîne",
    "y": " b. ... ameyene"
  },
  {
    "x": "ci verdayene",
    "y": " koşturmak (binek hayvanına binmiş olarak onu koşturmak)"
  },
  {
    "x": "ci vostaîn",
    "y": " b. ci vostayene"
  },
  {
    "x": "ci vostayene",
    "y": " azarlamak, paylamak, tekdir etmek"
  },
  {
    "x": "ci vostayîne",
    "y": " b. ci vostayene"
  },
  {
    "x": "ci voşîyayene",
    "y": " azarlanmak"
  },
  {
    "x": "ci voşîyayêne",
    "y": " b. ci voşîyayene"
  },
  {
    "x": "ciamayîş (n)",
    "y": " b. ... ameyîş"
  },
  {
    "x": "ciameyîş (n)",
    "y": " b. ... ameyîş"
  },
  {
    "x": "ciaqilîyayîş (n)",
    "y": " b. raciarqilîyayîş (n)"
  },
  {
    "x": "ciaqilnayîş (n)",
    "y": " b. Raciarqilnayîş"
  },
  {
    "x": "cibîyayîş (n)",
    "y": " koparılma"
  },
  {
    "x": "Cibraîl (n)",
    "y": " b. Cebraîl (n)"
  },
  {
    "x": "cicayene",
    "y": " b. dejayene"
  },
  {
    "x": "ciceno",
    "y": " b. dejeno"
  },
  {
    "x": "cicayîş (n)",
    "y": " b. dejayîş (n)"
  },
  {
    "x": "cicêrayîş (n)",
    "y": " b. cigêrayîş (n)"
  },
  {
    "x": "cicik",
    "y": " b. çiçik (n)"
  },
  {
    "x": "cicnayene",
    "y": " b. dejnayene"
  },
  {
    "x": "cicnayîş (n)",
    "y": " b. dejnayîş (n)"
  },
  {
    "x": "cidî, -ye",
    "y": " ciddi"
  },
  {
    "x": "cidî bîyayene",
    "y": " b. cidî bîyene"
  },
  {
    "x": "cidî bîyene",
    "y": " ciddi olmak"
  },
  {
    "x": "cidîyet (n)",
    "y": " ciddilik, ciddiyet"
  },
  {
    "x": "cidîyî (m)",
    "y": " ciddilik, ciddiyet"
  },
  {
    "x": "cidîyîye (m)",
    "y": " ciddilik, ciddiyet"
  },
  {
    "x": "ciestiş (n)",
    "y": " b. cieştiş (n)"
  },
  {
    "x": "cieştiş (n)",
    "y": " 1)katma, serpme \r\n~2)koşturma (hayvan için)"
  },
  {
    "x": "ciewnîyayîş (n)",
    "y": " bakım"
  },
  {
    "x": "cigeyrayîş (n)",
    "y": " b. cigêrayîş (n)"
  },
  {
    "x": "cigêrayîş (n)",
    "y": " 1)arama \r\n~2)araştırma\r\n~cigêrayîşê warî (n): alan araştırması\r\n~cigêrayîşo çendî (n): nicel araştırma\r\n~cigêrayîşo senînî (n): nitel araştırma\r\n~3)kontrol, kontrol etme, yoklama \r\n~4)karışmama (dokunmama)"
  },
  {
    "x": "cigêrayîşê pisporan (n)",
    "y": " ekspertiz"
  },
  {
    "x": "cigêrayîşê pîyaseyî (n)",
    "y": " piyasa araşırması"
  },
  {
    "x": "cigêrayîşê warî (n)",
    "y": " alan araştırması"
  },
  {
    "x": "cigêrayîşo çendî (n)",
    "y": " nicel araştırma"
  },
  {
    "x": "cigêrayîşo kalîtatîf (n)",
    "y": " nitel araştırma"
  },
  {
    "x": "cigêrayîşo kantîtatîf (n)",
    "y": " nicel araştırma"
  },
  {
    "x": "cigêrayîşo senînî (n)",
    "y": " nitel araştırma"
  },
  {
    "x": "cigêrayox, -e",
    "y": " araştırmacı"
  },
  {
    "x": "ci ginayîş (n)",
    "y": " 1)değme-I \r\n~2)dokunma (yiyecek vb.)"
  },
  {
    "x": "cigir (n)",
    "y": " dargınlık, küskünlük, burukluk"
  },
  {
    "x": "cigirîyaya (m)",
    "y": " kızgın (öfkeli), dargın, küskün, küs"
  },
  {
    "x": "cigirîyaye (n)",
    "y": " kızgın (öfkeli), dargın, küskün, küs"
  },
  {
    "x": "cigirîyaye bîyene",
    "y": " kızgın olmak, dargın olmak, küskün olmak, küs olmak"
  },
  {
    "x": "cigirîyayene",
    "y": " kızmak (öfkelenmek, sinirlenmek), darılmak, küsmek"
  },
  {
    "x": "cigirîyayê (m)",
    "y": " b. cigirîyayîye (m)"
  },
  {
    "x": "cigirîyayi (n)",
    "y": " b. cigirîyaye (n)"
  },
  {
    "x": "cigirîyayîş (n)",
    "y": " kızma (öfkelenme, sinirlenme), küsme"
  },
  {
    "x": "cigirîyayîye (m)",
    "y": " kızgın (öfkeli), dargın, küskün, küs"
  },
  {
    "x": "cigirnayene",
    "y": " kızdırmak, darıltmak, küstürmek"
  },
  {
    "x": "cigirnayîş (n)",
    "y": " kızdırma, darıltma, küstürme"
  },
  {
    "x": "cigiryayene",
    "y": " b. cigirîyayene"
  },
  {
    "x": "cigiryayîş (n)",
    "y": " b. cigiryayîş (n)"
  },
  {
    "x": "cigreyîş (n)",
    "y": " b. cigirîyayîş (n)"
  },
  {
    "x": "cigrîyaya (m)",
    "y": " b. cigirîyaya (m)"
  },
  {
    "x": "cigrîyaye (n)",
    "y": " b. cigirîyaye (n)"
  },
  {
    "x": "cigrîyayene",
    "y": " b. cigirîyayene"
  },
  {
    "x": "cigrîyayê (m)",
    "y": " b. cigirîyayîye (m)"
  },
  {
    "x": "cigrîyayîş (n)",
    "y": " b. cigirîyayîş (n)"
  },
  {
    "x": "cigrnayene",
    "y": " b. cigirnayene"
  },
  {
    "x": "cigrnayîş (n)",
    "y": " b. cigirnayîş (n)"
  },
  {
    "x": "cihêz (n)",
    "y": " b. cêz (n)"
  },
  {
    "x": "cihud, -e",
    "y": " Yahudi"
  },
  {
    "x": "cihudey (m)",
    "y": " b. cihudîye (m)"
  },
  {
    "x": "cihudê (m)",
    "y": " b. cihudî (m)"
  },
  {
    "x": "cihudênî (m)",
    "y": " Yahudilik"
  },
  {
    "x": "cihudî (m)",
    "y": " Yahudilik"
  },
  {
    "x": "cihudîye (m)",
    "y": " Yahudilik"
  },
  {
    "x": "cihûd, -e",
    "y": " b. cihud, -e"
  },
  {
    "x": "cihûdîye (m)",
    "y": " b. cihudîye (m)"
  },
  {
    "x": "cikerdiş-I (n)",
    "y": " koparma"
  },
  {
    "x": "cikerdiş-II (n)",
    "y": " b. dekerdiş (n)"
  },
  {
    "x": "cikewta (m)",
    "y": " girdi (ticaret için)"
  },
  {
    "x": "cikewte (n)",
    "y": " girdi (ticaret için)"
  },
  {
    "x": "cikewtiş (n)",
    "y": " girme\r\n~cikewtişê ewraqe (n): evrak girişi"
  },
  {
    "x": "cikewtişê ewraqe (n)",
    "y": " evrak girişi"
  },
  {
    "x": "cikewtîye (m)",
    "y": " girdi (ticaret için)"
  },
  {
    "x": "cikotayîs (n)",
    "y": " b. cikewtiş (n), b. dekewtiş (n)"
  },
  {
    "x": "cikotis (n)",
    "y": " b. cikewtiş (n), b. dekewtiş (n)"
  },
  {
    "x": "cikotiş (n)",
    "y": " b. cikewtiş (n), b. dekewtiş (n)"
  },
  {
    "x": "cil (n)",
    "y": " b. cile (m)"
  },
  {
    "x": "cil (m)",
    "y": " b. cile (m)"
  },
  {
    "x": "cil û caw",
    "y": " pılı pırtı"
  },
  {
    "x": "cil û cor",
    "y": " pılı pırtı"
  },
  {
    "x": "cilê herî (m)",
    "y": " b. cila herî (m)"
  },
  {
    "x": "cil-caw",
    "y": " pılı pırtı"
  },
  {
    "x": "cil-cor",
    "y": " pılı pırtı"
  },
  {
    "x": "cilandebîyayîş (n)",
    "y": " âdet görme, (kadın) aybaşı olma"
  },
  {
    "x": "cile (m)",
    "y": " 1)elbise, giyecek, giysi, kılık\r\n~cilê karî (zh): iş elbisesi, iş giysisi\r\n~cilê şewe (zh): gecelik\r\n~2)yatak \r\n~3)palan\r\n~cila herî (m): palan"
  },
  {
    "x": "cila herî (m)",
    "y": " palan"
  },
  {
    "x": "cila xoeştişî (m)",
    "y": " atlama yatağı"
  },
  {
    "x": "cilan de bîyene",
    "y": " âdet görmek, (kadın) aybaşı olmak"
  },
  {
    "x": "cile bedilnayene",
    "y": " elbise değiştirmek, giysi değiştirmek"
  },
  {
    "x": "cile ronayene",
    "y": " hayvanın palanını indirmek"
  },
  {
    "x": "cile ser kerdene",
    "y": " hayvanın palanını indirmek"
  },
  {
    "x": "cile ser o kerdene",
    "y": " b. cile ser kerdene"
  },
  {
    "x": "cile şîyene",
    "y": " (kadınla) cinsel ilişkide bulunmak, yatağına girmek"
  },
  {
    "x": "cile vetene",
    "y": " elbise çıkarmak, giysi çıkarmak"
  },
  {
    "x": "cile vurnayene",
    "y": " elbise değiştirmek, giysi değiştirmek"
  },
  {
    "x": "cilê karî (zh)",
    "y": " iş elbisesi, iş giysisi"
  },
  {
    "x": "cilê sunetî (zh)",
    "y": " sünnet elbisesi/giysisi"
  },
  {
    "x": "cilê şewe (zh)",
    "y": " gecelik"
  },
  {
    "x": "cilê xo amayene",
    "y": " b. cilê xo amayene"
  },
  {
    "x": "cilê xo ameyene",
    "y": " aybaşı olmak (kadın için)"
  },
  {
    "x": "cilê xo vetene",
    "y": " soyunmak"
  },
  {
    "x": "cilê ...  ... ra vetiş",
    "y": " soyma (giysisini çıkarma)"
  },
  {
    "x": "cilê ...  ... ra vetene",
    "y": " soymak (giysisini çıkarmak)"
  },
  {
    "x": "cilê ... vetene",
    "y": " soymak (giysisini çıkarmak)"
  },
  {
    "x": "cilê ... vetiş",
    "y": " soyma (giysisini çıkarma)"
  },
  {
    "x": "cilûn di bîyayîne",
    "y": " b. cilan de bîyene"
  },
  {
    "x": "cileşîyayîş (n)",
    "y": " (kadınla) cinsel ilişkide bulunma, yatağına girme"
  },
  {
    "x": "cilêxoameyîş (n)",
    "y": " aybaşı olma (kadın için)"
  },
  {
    "x": "cilêxovetiş (n)",
    "y": " soyunma"
  },
  {
    "x": "cili (m)",
    "y": " b. cile (m)"
  },
  {
    "x": "cilî (zh)",
    "y": " üst baş (giysiler)"
  },
  {
    "x": "cilî daye nevetene",
    "y": " soydurmak (giysisini çıkartmak, elbisesini çıkartmak)"
  },
  {
    "x": "cilî xo ra vetene",
    "y": " soyunmak"
  },
  {
    "x": "cilî ... ... ra vetene",
    "y": " b. cilê ...  ... ra vetene"
  },
  {
    "x": "cilî ...  ... ra vetiş",
    "y": " b. cilê ...  ... ra vetiş"
  },
  {
    "x": "cilî ... vetene",
    "y": " b. cilê ... vetene"
  },
  {
    "x": "cilî ... vetiş",
    "y": " b. cilê ... vetiş"
  },
  {
    "x": "cilîdayîşvetiş (n)",
    "y": " soydurma (giysisini çıkartma)"
  },
  {
    "x": "cilîxoravetiş (n)",
    "y": " soyunma"
  },
  {
    "x": "cilnêşuta (m)",
    "y": " çamaşır"
  },
  {
    "x": "cilor, -e",
    "y": " b. gilover, -e"
  },
  {
    "x": "cilşuw, -e",
    "y": " çamaşırcı"
  },
  {
    "x": "cilveta (m)",
    "y": " soyunmuş olan"
  },
  {
    "x": "cilvete (n)",
    "y": " soyunmuş olan"
  },
  {
    "x": "cilûbelg (n)",
    "y": " kıyafet, kılık, giyim, kisve "
  },
  {
    "x": "belgê/cilûbelgê/bedlê sunetî (n)",
    "y": " sünnet kıyafeti"
  },
  {
    "x": "cilûndibîyayîş (n)",
    "y": " b. cilandebîyayîş (n)"
  },
  {
    "x": "cim (n)",
    "y": " b. cem (n)"
  },
  {
    "x": "cimî sibay (n)",
    "y": " b. cemê sibayî (n)"
  },
  {
    "x": "cimî şonî (n)",
    "y": " b. cemê şanî (n)"
  },
  {
    "x": "cimaat (n)",
    "y": " b. cemat (n)"
  },
  {
    "x": "cimaet (n)",
    "y": " b. cemat (n)"
  },
  {
    "x": "cimat (n)",
    "y": " b. cemat (n)"
  },
  {
    "x": "cimbize (m)",
    "y": " cımbız"
  },
  {
    "x": "cimcim (n)",
    "y": " küçük bir karınca çeşidi"
  },
  {
    "x": "cime (n)",
    "y": " b. îne-I (n)"
  },
  {
    "x": "cimenda (m)",
    "y": " benzer"
  },
  {
    "x": "cimende (n)",
    "y": " benzer"
  },
  {
    "x": "cimendî, -ye",
    "y": " benzer"
  },
  {
    "x": "cimet (n)",
    "y": " b. cemat (n)"
  },
  {
    "x": "Ciminî",
    "y": " Üzümlü "
  },
  {
    "x": "cimlî (m)",
    "y": " 1)bulamaç (cıvık hamur tatlısı) \r\n~2)pekmez veya şeker ile undan yapılan ve üzerine kızartılmış yağ dökülerek yenen tatlı bulamaç"
  },
  {
    "x": "cinarza (m)",
    "y": " b. cenaza (m)"
  },
  {
    "x": "cinawir, -e",
    "y": " 1)canavar, yırtıcı hayvan, vahşi hayvan \r\n~2)kurt"
  },
  {
    "x": "cinawur, -e",
    "y": " b. cinawir, -e"
  },
  {
    "x": "cinawuri (m)",
    "y": " b. cinawire (m)"
  },
  {
    "x": "cinayeni",
    "y": " b. cenayene"
  },
  {
    "x": "cinayîne",
    "y": " b. cenayene"
  },
  {
    "x": "cinayîş (n)",
    "y": " b. cenayîş (n)"
  },
  {
    "x": "cinaza (m)",
    "y": " b. cenaza (m)"
  },
  {
    "x": "cincinik (n)",
    "y": " b. cincinike (m)"
  },
  {
    "x": "cincinike (m)",
    "y": " kelebek"
  },
  {
    "x": "cinciq",
    "y": " b. cînciq"
  },
  {
    "x": "cind (m)",
    "y": " b. cine-II (m)"
  },
  {
    "x": "cine-I (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "cine-II (m)",
    "y": " cin"
  },
  {
    "x": "cinî (zh)",
    "y": " cinler"
  },
  {
    "x": "cinek (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cinet (n)",
    "y": " b. cenet (n)"
  },
  {
    "x": "cinêk (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cinêke (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cinêki (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cingar (n)",
    "y": " b. zengar-II (n)"
  },
  {
    "x": "cingil (n)",
    "y": " çıngıl, cıngıl, şıngıl (salkım parçası, küçük salkım)"
  },
  {
    "x": "cini (m)",
    "y": " b. cine (n)"
  },
  {
    "x": "cinike (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "ciniqîyayene",
    "y": " ürküp sıçramak (hayvan için)"
  },
  {
    "x": "ciniqîyayîne",
    "y": " b. ciniqîyayene"
  },
  {
    "x": "ciniştiş (n)",
    "y": " -e binme"
  },
  {
    "x": "cinitene",
    "y": " çalmak (zil vs.)"
  },
  {
    "x": "zengil cinitene",
    "y": " zil çalmak"
  },
  {
    "x": "cinitiş (n)",
    "y": " çalma (zil vs.)"
  },
  {
    "x": "cinî (m)",
    "y": " 1)kadın, bayan \r\n~2)karı, kadın eş\r\n~Cinîya to se va? (Karın ne dedi?)"
  },
  {
    "x": "cinî het şîyene",
    "y": " kadınla cinsel ilişkide bulunmak"
  },
  {
    "x": "cinî û mêrde",
    "y": " karı koca, çift"
  },
  {
    "x": "cinîya biray",
    "y": " b. biracinî (m)"
  },
  {
    "x": "cinîya keyeyî (m)",
    "y": " ev kadını, ev hanımı"
  },
  {
    "x": "cinîya keyî (m)",
    "y": " b. cinîya keyeyî (m)"
  },
  {
    "x": "cinî-mêrde",
    "y": " karı koca, çift"
  },
  {
    "x": "cinîhetşîyayîş (n)",
    "y": " kadınla cinsel ilişkide bulunma"
  },
  {
    "x": "cinîke (m)",
    "y": " kadın, bayan"
  },
  {
    "x": "cinîkewtiş (n)",
    "y": " bir çeşit ruh hastalığı krizi geçirme"
  },
  {
    "x": "cinînas, -e",
    "y": " jinekolog, nisaiyeci"
  },
  {
    "x": "cinînasî (m)",
    "y": " jinekoloji, nisaiye"
  },
  {
    "x": "cinînasîye (m)",
    "y": " jinekoloji, nisaiye"
  },
  {
    "x": "cinîway (m)",
    "y": " baldız"
  },
  {
    "x": "cinîye (m)",
    "y": " 1)kadın, bayan \r\n~2)karı, kadın eş"
  },
  {
    "x": "cinîyek (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cinnet (n)",
    "y": " b. cenet (n)"
  },
  {
    "x": "cins (n)",
    "y": " 1)cins \r\n~2)soy"
  },
  {
    "x": "cinso dîyin (n)",
    "y": " ikinci cins"
  },
  {
    "x": "cinsîyet (n)",
    "y": " cinsiyet"
  },
  {
    "x": "cinsîyetperest, -e",
    "y": " cinsiyetçi"
  },
  {
    "x": "cinyek (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "ciperayîş (n)",
    "y": " bulaşma (hastalık için)"
  },
  {
    "x": "ciperrayîs (n)",
    "y": " b. ciperayîş (n)"
  },
  {
    "x": "ciperrayîş (n)",
    "y": " b. ciperayîş (n)"
  },
  {
    "x": "ciqla (m)",
    "y": " b. çeqla (m)"
  },
  {
    "x": "cir’ kerdene",
    "y": " b. cira kerdene"
  },
  {
    "x": "ciraameya (m)",
    "y": " becerikli"
  },
  {
    "x": "ciraameye (n)",
    "y": " becerikli"
  },
  {
    "x": "ciraameyeyîye (m)",
    "y": " beceriklilik"
  },
  {
    "x": "ciraameyîye (m)",
    "y": " becerikli"
  },
  {
    "x": "ciraameyîyîye (m)",
    "y": " beceriklilik"
  },
  {
    "x": "cirabîyayîş (n)",
    "y": " 1)koparılma \r\n~2)bezme, bıkma, bizar olma, usanma"
  },
  {
    "x": "ciragirewte (n)",
    "y": " aktarma, alıntı, iktibas"
  },
  {
    "x": "cirakerdiş (n)",
    "y": " koparma\r\n~cirakerdişê gijikî: saç tıraşı\r\n~cirakerdişê porî: saç tıraşı"
  },
  {
    "x": "cirakerdişê gijikî",
    "y": " saç tıraşı"
  },
  {
    "x": "cirakerdişê porî",
    "y": " saç tıraşı"
  },
  {
    "x": "ciraşanayîş (n)",
    "y": " b. tiraşanayîş (n)"
  },
  {
    "x": "ciraşîyayîş (n)",
    "y": " b. ... raşîyayîş"
  },
  {
    "x": "ciravetiş (n)",
    "y": " çıkarım (mantıkta), istidlâl (mantıkta)"
  },
  {
    "x": "ciraynayene",
    "y": " b. cirênayene"
  },
  {
    "x": "cird, -e",
    "y": " b. gird, -e"
  },
  {
    "x": "cireynayîş (n)",
    "y": " b. girênayîş (n)"
  },
  {
    "x": "cirênayene",
    "y": " (mecazi) önemli bir iş yapmak"
  },
  {
    "x": "cirgi (n)",
    "y": " b. cigir (n)"
  },
  {
    "x": "cirgînayene",
    "y": " b. cigirnayene"
  },
  {
    "x": "cirgînayîş (n)",
    "y": " b. cigirnayîş (n)"
  },
  {
    "x": "cirgîyaya (m)",
    "y": " b. cigirîyaya (m)"
  },
  {
    "x": "cirgîyaye (n)",
    "y": " b. cigirîyaye (n)"
  },
  {
    "x": "cirgîyayene",
    "y": " b. cigirîyayene"
  },
  {
    "x": "cirgîyayê (m)",
    "y": " b. cigirîyayîye (m)"
  },
  {
    "x": "cirgîyayi (n)",
    "y": " b. cigirîyaye (n)"
  },
  {
    "x": "cirgîyayîş (n)",
    "y": " b. cigirîyayîş (n)"
  },
  {
    "x": "cirgnayene",
    "y": " b. cigirnayene"
  },
  {
    "x": "cirgnayîş (n)",
    "y": " b. cigirnayîş (n)"
  },
  {
    "x": "cirit (n)",
    "y": " b. cirîd (n)"
  },
  {
    "x": "cirit eşteni",
    "y": " b. cirîd eştene"
  },
  {
    "x": "cirîd (n)",
    "y": " cirit"
  },
  {
    "x": "cirîd eştene",
    "y": " cirit atmak"
  },
  {
    "x": "cirît (n)",
    "y": " b. cirîd (n)"
  },
  {
    "x": "cirît eşteni",
    "y": " b. cirîd eştene"
  },
  {
    "x": "cirkerdiş (n)",
    "y": " b. cirakerdiş (n)"
  },
  {
    "x": "cirm (n)",
    "y": " cereme"
  },
  {
    "x": "cirmê ... dayene",
    "y": " ceremesini çekmek"
  },
  {
    "x": "cirmo giran (n)",
    "y": " ağır para cezası"
  },
  {
    "x": "cirne (m)",
    "y": " 1)kurna \r\n~2)yalak \r\n~3)dibek (içinde bulgur vb. dövülen büyük havan), soku"
  },
  {
    "x": "cirni (m)",
    "y": " b. cirne (m)"
  },
  {
    "x": "cirte (m)",
    "y": " b. cirîd (n)"
  },
  {
    "x": "cirtik (n)",
    "y": " b. çirtik (n)"
  },
  {
    "x": "cirtim (n)",
    "y": " çıngıl, cıngıl, şıngıl (salkım parçası, küçük salkım)"
  },
  {
    "x": "cirwe (n)",
    "y": " b. gure (n)"
  },
  {
    "x": "cis (n)",
    "y": " b. cins (n)"
  },
  {
    "x": "cisan (m)",
    "y": " b. cisane (m)"
  },
  {
    "x": "cisane (m)",
    "y": " bir üzüm çeşidi"
  },
  {
    "x": "cisani (m)",
    "y": " b. cisane (m)"
  },
  {
    "x": "cisawita (m)",
    "y": " sürülü"
  },
  {
    "x": "cisawite (n)",
    "y": " sürülü"
  },
  {
    "x": "cisawitiş (n)",
    "y": " 1)-e sürme \r\n~2)bulaştırma (fiziki şeyler için)"
  },
  {
    "x": "cisawîyayîş (n)",
    "y": " bulaşma (fiziki şeyler için)"
  },
  {
    "x": "cisir (n)",
    "y": " binanın çatısını taşıması için tavanda yatay olarak kullanılan kalın direk"
  },
  {
    "x": "cislî",
    "y": " b. bicins, -e"
  },
  {
    "x": "cisn (n)",
    "y": " b. cins (n)"
  },
  {
    "x": "cisoynayîs (n)",
    "y": " b. cisoynayîş (n)"
  },
  {
    "x": "cisoynayîş (n)",
    "y": " bulaştırma (fiziki şeyler için)"
  },
  {
    "x": "cisoynîyayîş (n)",
    "y": " bulaşma (fiziki şeyler için)"
  },
  {
    "x": "cişanayîş (n)",
    "y": " -e serpme"
  },
  {
    "x": "cişayîş (n)",
    "y": " b. cişanayîş (n)"
  },
  {
    "x": "cişonayîş (n)",
    "y": " b. cişanayîş (n)"
  },
  {
    "x": "cit (m)",
    "y": " b. cite (m)"
  },
  {
    "x": "cit kerdene",
    "y": " b. cite kerdene"
  },
  {
    "x": "cita (m)",
    "y": " çift\r\n~yew citaya solan: bir çift ayakkabı"
  },
  {
    "x": "yew citaya solan",
    "y": " bir çift ayakkabı"
  },
  {
    "x": "citastandardîye (m)",
    "y": " çifte standart"
  },
  {
    "x": "citawelatîyîye (m)",
    "y": " çifte vatandaşlık"
  },
  {
    "x": "cite (m)",
    "y": " çift"
  },
  {
    "x": "cite kerdene",
    "y": " çift sürmek"
  },
  {
    "x": "tîra cite (m)",
    "y": " b. tîra alete (m)"
  },
  {
    "x": "citekerda (m)",
    "y": " sürülü"
  },
  {
    "x": "citekerde (n)",
    "y": " sürülü"
  },
  {
    "x": "citekerdi (n)",
    "y": " b. citekerde (n)"
  },
  {
    "x": "citekerdîye (m)",
    "y": " sürülü"
  },
  {
    "x": "citêr, -i",
    "y": " b. cityer, -e"
  },
  {
    "x": "citêr (n)",
    "y": " b. cityer (n)"
  },
  {
    "x": "citêrey (m)",
    "y": " b. cityerî (m), cityerîye (m)"
  },
  {
    "x": "citi (m)",
    "y": " b. cite (m)"
  },
  {
    "x": "citi kerdene",
    "y": " b. cite kerdene"
  },
  {
    "x": "citîer (n)",
    "y": " b. cityer, -e"
  },
  {
    "x": "citîerî (m)",
    "y": " b. cityerî (m)"
  },
  {
    "x": "citkar, -e",
    "y": " çiftçi, fellah"
  },
  {
    "x": "citkarî (m)",
    "y": " çiftçilik"
  },
  {
    "x": "citkarîye (m)",
    "y": " çiftçilik"
  },
  {
    "x": "cityer, -e",
    "y": " çiftçi, fellah"
  },
  {
    "x": "cityer (n)",
    "y": " bir çiftçinin bir çift öküzle bir günde sürebileceği arazi "
  },
  {
    "x": "cityerê (m)",
    "y": " b. cityerî (m)"
  },
  {
    "x": "cityerî (m)",
    "y": " çiftçilik"
  },
  {
    "x": "cityerîye (m)",
    "y": " çiftçilik"
  },
  {
    "x": "ciûmeyîş (n)",
    "y": " b. ... ameyîş"
  },
  {
    "x": "civerdayîş (n)",
    "y": " koşturma (binek hayvanına binmiş olarak onu koşturma)"
  },
  {
    "x": "civir (n)",
    "y": " b. çivir (n)"
  },
  {
    "x": "civirnayene",
    "y": " b. çivirnayene"
  },
  {
    "x": "Civlayîl (n)",
    "y": " b. Cebraîl (n)"
  },
  {
    "x": "Civlayîr (n)",
    "y": " b. Cebraîl (n)"
  },
  {
    "x": "civostaîs (n)",
    "y": " b. civostayîş (n)"
  },
  {
    "x": "civostawox, -e",
    "y": " b. civostayox, -e"
  },
  {
    "x": "civostayîs (n)",
    "y": " b. civostayîş (n)"
  },
  {
    "x": "civostayîş (n)",
    "y": " azarlama, melamet, paylama, tekdir etme"
  },
  {
    "x": "civostayox, -e",
    "y": " azarlayan"
  },
  {
    "x": "civoşîyaye (n)",
    "y": " azarlanmış olan"
  },
  {
    "x": "civoşîyayîş (n)",
    "y": " azarlanma"
  },
  {
    "x": "civoşîyayîye (m)",
    "y": " azarlanmış olan"
  },
  {
    "x": "ciwab (n)",
    "y": " b. cewab (n)"
  },
  {
    "x": "ciwal (n)",
    "y": " çuval"
  },
  {
    "x": "ciwamerd (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "ciwamerdîye (m)",
    "y": " b. camêrdîye (m) "
  },
  {
    "x": "ciwamêrd (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "ciwan, -e",
    "y": " genç"
  },
  {
    "x": "ciwaney (m)",
    "y": " b. ciwanî (m)"
  },
  {
    "x": "ciwanênî (m)",
    "y": " gençlik"
  },
  {
    "x": "ciwani (m)",
    "y": " b. ciwan, -e"
  },
  {
    "x": "ciwanike (m)",
    "y": " hanım (kadınlığın iyi özelliklerini taşıyan)"
  },
  {
    "x": "ciwaniki (m)",
    "y": " b. ciwanike (m)"
  },
  {
    "x": "ciwanî (m)",
    "y": " gençlik"
  },
  {
    "x": "ciwanîye (m)",
    "y": " gençlik"
  },
  {
    "x": "ciwen (n)",
    "y": " b. cuwen (n) "
  },
  {
    "x": "ciwenûn ser ra gêrayîne",
    "y": " b. cuwenan ser ra gêrayene"
  },
  {
    "x": "ciwenqelbidayîş (n)",
    "y": " b. cuwenqelbedayîş (n)"
  },
  {
    "x": "ciweyn (n)",
    "y": " b. cuwen (n) "
  },
  {
    "x": "ciwên (n)",
    "y": " b. cuwen (n) "
  },
  {
    "x": "ciwênqelibnayîş (n)",
    "y": " b. cuwenqelibnayîş (n)"
  },
  {
    "x": "ciwid, -i",
    "y": " b. cihud, -e"
  },
  {
    "x": "ciwidey (m)",
    "y": " b. cihudîye (m)"
  },
  {
    "x": "ciwî (n)",
    "y": " b. cuwen (n) "
  },
  {
    "x": "ciwîn (n)",
    "y": " b. cuwen (n) "
  },
  {
    "x": "ciwîyayene",
    "y": " yaşamak\r\n~Biciwî! (Yaşa!)\r\n~\t\r\n~Biciwîyo welat! (Yaşasın vatan!)\r\n~\t\r\n~Wa biciwîyo welat! (Yaşasın vatan!)\r\n~\t\r\n~Zaf biciwî! (Çok yaşa!)"
  },
  {
    "x": "ciwîyayîş (n)",
    "y": " yaşama"
  },
  {
    "x": "ciwon, -i",
    "y": " b. ciwan, -e"
  },
  {
    "x": "ciwoney (m)",
    "y": " b. ciwanî (m), ciwanîye (m)"
  },
  {
    "x": "ciwûn, -i",
    "y": " b. ciwan, -e"
  },
  {
    "x": "ciwûney (m)",
    "y": " b. ciwanî (m)"
  },
  {
    "x": "ciwûnê (m)",
    "y": " b. ciwanî (m)"
  },
  {
    "x": "cixara (m)",
    "y": " sigara"
  },
  {
    "x": "cixare (n)",
    "y": " b. cixara (m)"
  },
  {
    "x": "ciz (n)",
    "y": " b. çije (n)"
  },
  {
    "x": "cizbi (m)",
    "y": " b. cuze (m)"
  },
  {
    "x": "cizdan (n)",
    "y": " cüzdan (para cüzdanı)"
  },
  {
    "x": "cizdanê nufusî (n)",
    "y": " nüfus cüzdanı"
  },
  {
    "x": "cizdo (n)",
    "y": " b. cizdan (n)"
  },
  {
    "x": "cizdon (n)",
    "y": " b. cizdan (n)"
  },
  {
    "x": "cizdû (n)",
    "y": " b. cizdan (n)"
  },
  {
    "x": "cizdûn (n)",
    "y": " b. cizdan (n)"
  },
  {
    "x": "cize (n)",
    "y": " b. çije (n)"
  },
  {
    "x": "ciziç",
    "y": " b. çiçik (n)"
  },
  {
    "x": "cizik (n)",
    "y": " b. çiçik (n)"
  },
  {
    "x": "cizixe (m)",
    "y": " b. xêze (m)"
  },
  {
    "x": "cizlawet (n)",
    "y": " b. gislavet (n)"
  },
  {
    "x": "cizlawit (n)",
    "y": " b. gislavet (n)"
  },
  {
    "x": "cizlawît (n)",
    "y": " b. gislavet (n)"
  },
  {
    "x": "cizlawut (n)",
    "y": " b. gislavet (n)"
  },
  {
    "x": "cizme (n)",
    "y": " çizme"
  },
  {
    "x": "cizmekar, -e",
    "y": " çizmeci"
  },
  {
    "x": "cizmeroş, -e",
    "y": " çizmeci"
  },
  {
    "x": "cizmi (n)",
    "y": " b. cizme (n)"
  },
  {
    "x": "cî-I (zr)",
    "y": " b. zî (zr)"
  },
  {
    "x": "cî-II (n)",
    "y": " b. gî (n)"
  },
  {
    "x": "cî-III (n)",
    "y": " b. ca-I (n)"
  },
  {
    "x": "cî-IV",
    "y": " b. cê-II"
  },
  {
    "x": "cîab (n)",
    "y": " b. cewab (n)"
  },
  {
    "x": "cîbs (n)",
    "y": " alçı"
  },
  {
    "x": "Cîbûtî",
    "y": " Cibuti"
  },
  {
    "x": "cîcike (m)",
    "y": " oyuncak"
  },
  {
    "x": "cîciki (m)",
    "y": " b. cîcike (m)"
  },
  {
    "x": "cîerakerdiş (n)",
    "y": " b. cêrakerdiş (n)cîeravetiş (n):b. cêravetiş (n)"
  },
  {
    "x": "cîf (n)",
    "y": " nefes, soluk "
  },
  {
    "x": "cîf birîyayene",
    "y": " nefesi kesilmek, nefesi tıkanmak, nefesi tutulmak, soluğu kesilmek, soluğu tıkanmak"
  },
  {
    "x": "cîf dayene",
    "y": " nefes vermek, soluk vermek"
  },
  {
    "x": "cîf girewtene",
    "y": " nefes almak, soluk almak"
  },
  {
    "x": "cîf girotene",
    "y": " b. cîf girewtene"
  },
  {
    "x": "cîf gîrîyayene",
    "y": " nefesi kesilmek, nefesi tıkanmak, nefesi tutulmak, soluğu kesilmek, soluğu tıkanmak"
  },
  {
    "x": "cîf peysayene",
    "y": " nefesi kesilmek, nefesi tıkanmak, nefesi tutulmak, soluğu kesilmek, soluğu tıkanmak"
  },
  {
    "x": "cîf piro peysayene",
    "y": " nefesi kesilmek, nefesi tıkanmak, nefesi tutulmak, soluğu kesilmek, soluğu tıkanmak"
  },
  {
    "x": "cîfê ... arê ro pêşîyayene",
    "y": " nefesi kesilmek, nefesi tıkanmak, nefesi tutulmak, soluğu kesilmek, soluğu tıkanmak"
  },
  {
    "x": "cîfi (n)",
    "y": " b. cîf (n)"
  },
  {
    "x": "cîfgirewtiş (n)",
    "y": " solunum, teneffüs"
  },
  {
    "x": "cîger (n)",
    "y": " b. cîgere (m)"
  },
  {
    "x": "cîgere (m)",
    "y": " ciğer, karaciğer"
  },
  {
    "x": "cîgerpewj, -e",
    "y": " ciğerci"
  },
  {
    "x": "cîgerroş, -e",
    "y": " ciğerci"
  },
  {
    "x": "cigreyîş (n)",
    "y": " b. cigirîyayîş (n)"
  },
  {
    "x": "cîhan (n)",
    "y": " 1)dünya \r\n~2)felek"
  },
  {
    "x": "cîhanşumûl,-e",
    "y": " âlemşümul, cihanşümul, evrensel, üniversal"
  },
  {
    "x": "cîhanşumûlî (m)",
    "y": " âlemşümullük, cihanşümullük, evrensellik"
  },
  {
    "x": "cîhanşumûlîye (m)",
    "y": " âlemşümullük, cihanşümullük, evrensellik"
  },
  {
    "x": "cîhat (n)",
    "y": " b. jêhatî (n)"
  },
  {
    "x": "cîhaz (n)",
    "y": " cihaz\r\n~cîhazê bînegirewtişî (zh): teneffüs cihazları\r\n~cîhazê peymîye (n): ölçüm cihazı"
  },
  {
    "x": "cîhazê bînegirewtişî (zh)",
    "y": " teneffüs cihazları"
  },
  {
    "x": "cîhazê cîfgirewtişî (zh)",
    "y": " teneffüs cihazları"
  },
  {
    "x": "cîhazê peymîye (n)",
    "y": " ölçüm cihazı"
  },
  {
    "x": "cîhet (n)",
    "y": " yön, yan, cihet\r\n~cîhetê bingeyênî (zh): ana yönler\r\n~cîhetê mîyanênî (zh): ara yönler"
  },
  {
    "x": "cîhetê bingeyênî (zh)",
    "y": " ana yönler"
  },
  {
    "x": "cîhetê mîyanênî (zh)",
    "y": " ara yönler"
  },
  {
    "x": "cîl (n)",
    "y": " b. zîl (n)"
  },
  {
    "x": "cîla (m)",
    "y": " cila"
  },
  {
    "x": "cîlaker, -e",
    "y": " cilacı"
  },
  {
    "x": "cîlat (n)",
    "y": " b. cîlet (n)"
  },
  {
    "x": "cîlate (m)",
    "y": " b. cîlet (n)"
  },
  {
    "x": "cîld (n)",
    "y": " cilt "
  },
  {
    "x": "cîldker, -e",
    "y": " ciltçi"
  },
  {
    "x": "cîle (n)",
    "y": " b. cîla (m"
  },
  {
    "x": "cîlet (n)",
    "y": " jilet"
  },
  {
    "x": "cîlete (m)",
    "y": " b. cîlet (n)"
  },
  {
    "x": "cîlêt (n)",
    "y": " b. cîlet (n)"
  },
  {
    "x": "cîm (n)",
    "y": " biçilmiş ekin demetini bağlamakta kullanılan ve otsu bitki veya ince ağaç dalından yapılmış olan bağ"
  },
  {
    "x": "cîmna",
    "y": " b. yewbînî, yewbînan "
  },
  {
    "x": "cîn-I (n)",
    "y": " cin (içki)"
  },
  {
    "x": "cîn-II (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "cînan (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "cînayet (n)",
    "y": " cinayet, kıya "
  },
  {
    "x": "cînciq",
    "y": " bilye "
  },
  {
    "x": "cîncix",
    "y": " b. cînciq"
  },
  {
    "x": "cîne-I (zh, z)",
    "y": " b. înan (zh, z)\r\n~Bihevrisayîşê cîne zor o. (Onların birbirlerine ulaşmaları zordur.)"
  },
  {
    "x": "cîne-II (m)",
    "y": " b. cinî (m)"
  },
  {
    "x": "cînege (m)",
    "y": " favori"
  },
  {
    "x": "cînek (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cîneke (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cînê (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "cîngil (n)",
    "y": " b. cingil (n)"
  },
  {
    "x": "cîni (zh, z)",
    "y": " b. înan (zh, z)\r\n~Pîy cîni merdo. (Babaları ölmüş.)"
  },
  {
    "x": "cînik (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cînike (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cînî-I (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "cînî-II (m)",
    "y": " b. cinî (m)"
  },
  {
    "x": "cînîyê keyî (m)",
    "y": " b. cinîya keyeyî (m)"
  },
  {
    "x": "cînîke (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cînîye (m)",
    "y": " b. cinîye (m)"
  },
  {
    "x": "cînîyek (m)",
    "y": " b. cinîke (m)"
  },
  {
    "x": "cîpe (m)",
    "y": " cip"
  },
  {
    "x": "cîpa leşkerî (m)",
    "y": " askeri cip"
  },
  {
    "x": "cîr (n)",
    "y": " b. cêr (n)"
  },
  {
    "x": "cîra",
    "y": " b. cîran, -e"
  },
  {
    "x": "cîran, -e",
    "y": " 1)komşu\r\n~2)mücavir"
  },
  {
    "x": "cîranî (m)",
    "y": " komşuluk"
  },
  {
    "x": "cîranîne (m)",
    "y": " komşuluk"
  },
  {
    "x": "cîranîni (m)",
    "y": " b. cîranîne (m)"
  },
  {
    "x": "cîranîye (m)",
    "y": " komşuluk"
  },
  {
    "x": "cîrg (n)",
    "y": " b. cigir (n)"
  },
  {
    "x": "cîrgaya (m)",
    "y": " b. cigirîyaya (m)"
  },
  {
    "x": "cîrgaye (n)",
    "y": " b. cigirîyaye (n)"
  },
  {
    "x": "cîrgayene",
    "y": " b. cigirîyayene"
  },
  {
    "x": "cîrgayê (m)",
    "y": " b. cigirîyayîye (m)"
  },
  {
    "x": "cîrgayîş (n)",
    "y": " b. cigirîyayîş (n)"
  },
  {
    "x": "cîrgi (n)",
    "y": " b. cigir (n)"
  },
  {
    "x": "cîrgî (n)",
    "y": " b. cigir (n)"
  },
  {
    "x": "cîrgîayîş (n)",
    "y": " b. cigirîyayîş (n)"
  },
  {
    "x": "cîrgîyaya (m)",
    "y": " b. cigirîyaya (m)"
  },
  {
    "x": "cîrgîyaye (n)",
    "y": " b. cigirîyaye (n)"
  },
  {
    "x": "cîrgîyayene",
    "y": " b. cigirîyayene"
  },
  {
    "x": "cîrgîyayê (m)",
    "y": " b. cigirîyayîye (m)"
  },
  {
    "x": "cîrgîyayîş (n)",
    "y": " b. cigirîyayîş (n)"
  },
  {
    "x": "cîrite (m)",
    "y": " b. cirîd (n)"
  },
  {
    "x": "cîrîd (n)",
    "y": " b. cirîd (n)"
  },
  {
    "x": "cîrît (n)",
    "y": " b. cirîd (n)"
  },
  {
    "x": "cîrît eşteni",
    "y": " b. cirîd eştene"
  },
  {
    "x": "cîro",
    "y": " b. cîran, -e"
  },
  {
    "x": "cîron, -i",
    "y": " b. cîran, -e"
  },
  {
    "x": "cîroney (m)",
    "y": " b. cîranîye (m), cîranî (m)"
  },
  {
    "x": "cîronîni (m)",
    "y": " b. cîranîne (m)"
  },
  {
    "x": "cîrte (m)",
    "y": " b. cirîd (n)"
  },
  {
    "x": "cîrtim (n)",
    "y": " b. cirtim (n)"
  },
  {
    "x": "cîrtime (n)",
    "y": " b. cirtim (n)"
  },
  {
    "x": "cîrû",
    "y": " b. cîran, -e"
  },
  {
    "x": "cîrûn, -i",
    "y": " b. cîran, -e"
  },
  {
    "x": "cîrûney (m)",
    "y": " b. cîranîye (m), cîranî (m)"
  },
  {
    "x": "cîrûnê (m)",
    "y": " b. cîranîye (m), cîranî (m)"
  },
  {
    "x": "cîrûnîni (m)",
    "y": " b. cîranîne (m)"
  },
  {
    "x": "cîrûnîye (m)",
    "y": " b. cîranîye (m)"
  },
  {
    "x": "cîryen, -e",
    "y": " b. cêrên, -e"
  },
  {
    "x": "cîsn (n)",
    "y": " b. cins (n)"
  },
  {
    "x": "cîvata (m)",
    "y": " cıvata "
  },
  {
    "x": "cîwal (n)",
    "y": " b. ciwal (n)"
  },
  {
    "x": "cîwale (m)",
    "y": " b. ciwal (n)"
  },
  {
    "x": "cîwan, -e",
    "y": " ciwan, -e"
  },
  {
    "x": "cîwata (m)",
    "y": " b. cîvata (m)"
  },
  {
    "x": "cîwo",
    "y": " ciwan (n)"
  },
  {
    "x": "cîwon",
    "y": " ciwan (n)"
  },
  {
    "x": "cîwû",
    "y": " ciwan (n)"
  },
  {
    "x": "cîwûn",
    "y": " ciwan (n)"
  },
  {
    "x": "cîya, -ye",
    "y": " 1)ayrı \r\n~2)değişik, farklı, ayrımlı, aytık (başkalarına benzemeyen) \r\n~3)öbür, diğer, başka"
  },
  {
    "x": "cîya bîyene ",
    "y": " ayrılmak"
  },
  {
    "x": "cîya kerdene",
    "y": " ayırmak"
  },
  {
    "x": "cîya-cîya",
    "y": " ayrı ayrı, başka başka"
  },
  {
    "x": "cîyabîyayî, -ye",
    "y": " (ayrılmış) ayrık"
  },
  {
    "x": "cîyabîyayîş (n)",
    "y": " ayrılma"
  },
  {
    "x": "cîyaker, -e",
    "y": " ayırıcı\r\n~cîyakerê qumî (n): kum ayırıcı\r\n~cîyakero hîdrolîk (n): hidrolik ayırıcı"
  },
  {
    "x": "cîyakerê qumî (n)",
    "y": " kum ayırıcı"
  },
  {
    "x": "cîyakerê xizî (n)",
    "y": " kum ayırıcı"
  },
  {
    "x": "cîyakero hîdrolîk (n)",
    "y": " hidrolik ayırıcı"
  },
  {
    "x": "cîyakerdis (n)",
    "y": " b. cîyakerdiş (n)"
  },
  {
    "x": "cîyakerdiş (n)",
    "y": " ayırma"
  },
  {
    "x": "cîyakî, -ye",
    "y": " aykırı"
  },
  {
    "x": "cîyakîtîye (m)",
    "y": " aykırılık"
  },
  {
    "x": "cîyat (n)",
    "y": " b. jêhatî (n)"
  },
  {
    "x": "cîyatî (m)",
    "y": " b. cîyayîye (m), cîyayî (m)"
  },
  {
    "x": "cîyayey (m)",
    "y": " b. cîyayîye (m), cîyayî (m)"
  },
  {
    "x": "cîyayê (m)",
    "y": " b. cîyayîye (m), cîyayî (m)"
  },
  {
    "x": "cîyayî (m)",
    "y": " 1)ayrılık, ayrım, fark \r\n~2)ayrıcalık, imtiyaz"
  },
  {
    "x": "cîyayîye (m)",
    "y": " 1)ayrılık, ayrımlılık, başkalık, farklılık \r\n~2)ayrıcalık, imtiyaz"
  },
  {
    "x": "cîye",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "cîyeb (n)",
    "y": " b. cêbe (m)"
  },
  {
    "x": "cîyer (n)",
    "y": " b. cêr (n)"
  },
  {
    "x": "cîyerîn, -e",
    "y": " b. cêrên, -e"
  },
  {
    "x": "cîyev (n)",
    "y": " b. cêbe (m)"
  },
  {
    "x": "cîyez (n)",
    "y": " b. cêz (n)"
  },
  {
    "x": "cîynan (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "cîzlawît (n)",
    "y": " b. gislavet (n)"
  },
  {
    "x": "cîzme (n)",
    "y": " b. cizme (n)"
  },
  {
    "x": "cîzmi (n)",
    "y": " b. cizme (n)"
  },
  {
    "x": "co (n)",
    "y": " b. cew (n)"
  },
  {
    "x": "co ver",
    "y": " b. verê cû"
  },
  {
    "x": "coamerd (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "cografî, -ye",
    "y": " coğrafi"
  },
  {
    "x": "cografik, -e",
    "y": " coğrafik"
  },
  {
    "x": "cografya (m)",
    "y": " coğrafya"
  },
  {
    "x": "cografyanas, -e",
    "y": " coğrafyacı"
  },
  {
    "x": "cok",
    "y": " b. coka"
  },
  {
    "x": "cok ra",
    "y": " b. coka"
  },
  {
    "x": "coka",
    "y": " o nedenle, o yüzden, ondan dolayı, ondan ötürü"
  },
  {
    "x": "coka ra",
    "y": " b. coka"
  },
  {
    "x": "coka rê",
    "y": " b. coka"
  },
  {
    "x": "coka ro",
    "y": " b. coka"
  },
  {
    "x": "cokan",
    "y": " b. coka"
  },
  {
    "x": "cokana",
    "y": " b. coka"
  },
  {
    "x": "cokanî",
    "y": " b. coka"
  },
  {
    "x": "coke",
    "y": " b. coka"
  },
  {
    "x": "coke ra",
    "y": " b. Coka"
  },
  {
    "x": "cokey, -e",
    "y": " Cokey"
  },
  {
    "x": "cokeyî (m)",
    "y": " cokeylik"
  },
  {
    "x": "cokeyîye (m)",
    "y": " cokeylik"
  },
  {
    "x": "coki",
    "y": " b. coka"
  },
  {
    "x": "coki ra",
    "y": " b. coka"
  },
  {
    "x": "coki ki",
    "y": " b. cêko"
  },
  {
    "x": "coki ki yeno vîrê mi",
    "y": " b. cêko ke yeno vîrê mi"
  },
  {
    "x": "cokinay",
    "y": " b. coka"
  },
  {
    "x": "coknay",
    "y": " b. coka"
  },
  {
    "x": "cokona",
    "y": " b. coka"
  },
  {
    "x": "cokoney",
    "y": " b. coka"
  },
  {
    "x": "cokra",
    "y": " b. coka"
  },
  {
    "x": "coku",
    "y": " b. coka"
  },
  {
    "x": "col (n)",
    "y": " küçükbaş hayvan sürüsü"
  },
  {
    "x": "colag, -e",
    "y": " b. coleg, -e"
  },
  {
    "x": "colage (m)",
    "y": " sığır sürüsü"
  },
  {
    "x": "coleg, -e",
    "y": " maraba"
  },
  {
    "x": "colegênî (m)",
    "y": " marabalık"
  },
  {
    "x": "colegî (m)",
    "y": " marabalık"
  },
  {
    "x": "colegîye (m)",
    "y": " marabalık"
  },
  {
    "x": "colêgên (m)",
    "y": " b. colegênî (m)"
  },
  {
    "x": "com (n)",
    "y": " b. cam (n)"
  },
  {
    "x": "comerd, -e",
    "y": " cömert"
  },
  {
    "x": "Comerd (n)",
    "y": " Tanrı, ilah\r\n~Comerd miradê şima bido. (Tanrı sizi muradınıza erdirsin.)"
  },
  {
    "x": "comerdek, -e",
    "y": " cömert"
  },
  {
    "x": "comerdek, -i",
    "y": " b. comerdek, -e"
  },
  {
    "x": "comerdekey (m)",
    "y": " b. comerdekî (m)"
  },
  {
    "x": "comerdekî (m)",
    "y": " cömertlik"
  },
  {
    "x": "comerdey (m)",
    "y": " b. comerdîye (m)"
  },
  {
    "x": "comerdê (m)",
    "y": " b. comerdî (m)"
  },
  {
    "x": "comerdi (m)",
    "y": " b. comerdi (m)"
  },
  {
    "x": "comerdî (m)",
    "y": " cömertlik"
  },
  {
    "x": "comerdîye (m)",
    "y": " cömertlik"
  },
  {
    "x": "comêrd (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "comêrdek (n)",
    "y": " b. camêrdek (n)"
  },
  {
    "x": "comêrdey (m)",
    "y": " b. camêrdî (m)"
  },
  {
    "x": "comêrdî (m)",
    "y": " b. camêrdî (m)"
  },
  {
    "x": "comird, -e",
    "y": " b. comerd, -e"
  },
  {
    "x": "comî (n)",
    "y": " b. camî (n)"
  },
  {
    "x": "comî (m)",
    "y": " b. camî (n)"
  },
  {
    "x": "comord (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "comus, -i",
    "y": " b. gamêş, -e "
  },
  {
    "x": "comus omeyîne",
    "y": " b. gamêş ameyene"
  },
  {
    "x": "comus omeyîş (n)",
    "y": " b. gamêşameyîş (n)"
  },
  {
    "x": "comyerd (n)",
    "y": " 1)b. camêrd (n) \r\n~2)b. comerd (n)"
  },
  {
    "x": "comyerdê (m)",
    "y": " 1)b. camêrdî (m) \r\n~2)b. comerdî (m)"
  },
  {
    "x": "con-I (n)",
    "y": " b. can (n)"
  },
  {
    "x": "con fîda",
    "y": " b. can fîda"
  },
  {
    "x": "con-II (n)",
    "y": " b. ciwan, -e"
  },
  {
    "x": "con-III (n)",
    "y": " b. cuwen (n)"
  },
  {
    "x": "conbaz, -i",
    "y": " b. canbaz, -e"
  },
  {
    "x": "conbazey (m)",
    "y": " b. canbazîye (m)"
  },
  {
    "x": "condêmdayîş (n)",
    "y": " b. cuwendêmdayîş (n)"
  },
  {
    "x": "cona (m)",
    "y": " b. cana (m) "
  },
  {
    "x": "cone (n)",
    "y": " b. cane (n)"
  },
  {
    "x": "conega (n)",
    "y": " tosun"
  },
  {
    "x": "confîda, -ye",
    "y": " b. canfîda, -ye"
  },
  {
    "x": "conga (n)",
    "y": " b. conega (n) "
  },
  {
    "x": "conik (m)",
    "y": " b. ciwanike (m)"
  },
  {
    "x": "conike (m)",
    "y": " b. ciwanike (m)"
  },
  {
    "x": "coniki (m)",
    "y": " b. ciwanike (m)"
  },
  {
    "x": "conkêş, -i",
    "y": " b. cankêş, -e"
  },
  {
    "x": "conkêş di bîyayîne",
    "y": " b. cankêş de bîyene"
  },
  {
    "x": "consivik, -i",
    "y": " b. cansivik, -e"
  },
  {
    "x": "consivikey (m)",
    "y": " b. cansivikîye (m)"
  },
  {
    "x": "consivikê (m)",
    "y": " b. cansivikî (m)"
  },
  {
    "x": "conşenik, -i",
    "y": " b. canşenik, -e"
  },
  {
    "x": "conşenikey (m)",
    "y": " b. canşenikîye (m)"
  },
  {
    "x": "conşenikê (m)",
    "y": " b. canşenikî (m)"
  },
  {
    "x": "cor (n)",
    "y": " yukarı"
  },
  {
    "x": "cor de",
    "y": " yukarıdan"
  },
  {
    "x": "cor de girewtene",
    "y": " ağzını aramak, ağzını yoklamak\r\n~Mi cor de girewt, mi rê va. (Ağzını aradım, bana söyledi.)"
  },
  {
    "x": "cor di",
    "y": " b. cor de"
  },
  {
    "x": "cor di girotene",
    "y": " b. cor de girewtene"
  },
  {
    "x": "cor ra",
    "y": " yukarıdan"
  },
  {
    "x": "cor ra bi cêr",
    "y": " baştan aşağı (zr)"
  },
  {
    "x": "cora-I",
    "y": " o nedenle, o yüzden, ondan dolayı, ondan ötürü"
  },
  {
    "x": "cora-II",
    "y": " b. caran"
  },
  {
    "x": "coran",
    "y": " b. cora-I"
  },
  {
    "x": "Corctawn (n)",
    "y": " Georgetown"
  },
  {
    "x": "cordegirewtiş (n)",
    "y": " ağzını arama, ağzını yoklama"
  },
  {
    "x": "core",
    "y": " b. caran"
  },
  {
    "x": "corên, -e",
    "y": " yukarı taraftaki, yukarıdaki"
  },
  {
    "x": "corin, -e",
    "y": " b. corên, -e"
  },
  {
    "x": "corîn, -e",
    "y": " b. corên, -e"
  },
  {
    "x": "corne (n)",
    "y": " uçkurluk"
  },
  {
    "x": "coro",
    "y": " b. caran"
  },
  {
    "x": "corû",
    "y": " b. caran"
  },
  {
    "x": "coş-I (n)",
    "y": " coşku"
  },
  {
    "x": "coş bîyene",
    "y": " coşmak, çağlamak (mecazî)"
  },
  {
    "x": "coş kerdene",
    "y": " coşturmak"
  },
  {
    "x": "coş-II (n)",
    "y": " b. goş (n)"
  },
  {
    "x": "coşbîyayîş (n)",
    "y": " coşma, çağlama (mecazî)"
  },
  {
    "x": "coşdar, -e",
    "y": " coşkulu, ateşli"
  },
  {
    "x": "coşkerdiş (n)",
    "y": " coşturma"
  },
  {
    "x": "cote (m)",
    "y": " b. cita (m)"
  },
  {
    "x": "cow (n)",
    "y": " b. cew (n)"
  },
  {
    "x": "cowzerd (n)",
    "y": " b. cewzerdî (zh)"
  },
  {
    "x": "coyin (n)",
    "y": " b. cuwen (n) "
  },
  {
    "x": "coyîn (n)",
    "y": " b. cuwen (n)"
  },
  {
    "x": "coyîne (m)",
    "y": " b. cuwen (n)"
  },
  {
    "x": "coyra",
    "y": " b. cora-I"
  },
  {
    "x": "cozerd (n)",
    "y": " b. cewzerdî (zh)"
  },
  {
    "x": "cu (m)",
    "y": " b. cuye-I"
  },
  {
    "x": "cua pey",
    "y": " b. cuwa pey"
  },
  {
    "x": "cuab (n)",
    "y": " b. cewab (n)"
  },
  {
    "x": "cuamerd (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "cuamerdîye (m)",
    "y": " b. camêrdîye (m)"
  },
  {
    "x": "cuamêrd (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "cuamêrdîye (m)",
    "y": " b. camêrdîye (m)"
  },
  {
    "x": "cuar (n)",
    "y": " b. cor (n)"
  },
  {
    "x": "cuardigirotiş (n)",
    "y": " b. cordegirewtiş"
  },
  {
    "x": "cuaryen, -i",
    "y": " b. corên, -e"
  },
  {
    "x": "cuav (n)",
    "y": " b. cewab (n)"
  },
  {
    "x": "cubbe (n)",
    "y": " b. cube (n)"
  },
  {
    "x": "cube (n)",
    "y": " cübbe"
  },
  {
    "x": "cucik (n)",
    "y": " çük (çocuk dilinde)"
  },
  {
    "x": "cue pîya",
    "y": " b. cuwa pey"
  },
  {
    "x": "cueka",
    "y": " b. coka"
  },
  {
    "x": "cuer (n)",
    "y": " b. cor (n)"
  },
  {
    "x": "cuera",
    "y": " b. cora-I"
  },
  {
    "x": "cuerna",
    "y": " b. cora-I"
  },
  {
    "x": "cueron",
    "y": " b. cora-I"
  },
  {
    "x": "cugeh (n)",
    "y": " 1)çevre \r\n~2)yaşam alanı, yaşam çevresi"
  },
  {
    "x": "cugehpawitox, -e",
    "y": " çevreci"
  },
  {
    "x": "cugehpawitoxî (m)",
    "y": " çevrecilik"
  },
  {
    "x": "cugehpawitoxîye (m)",
    "y": " çevrecilik"
  },
  {
    "x": "cugehseveknayox, -e",
    "y": " çevreci"
  },
  {
    "x": "cugehseveknayoxî (m)",
    "y": " çevrecilik"
  },
  {
    "x": "cugehseveknayoxîye (m)",
    "y": " çevrecilik"
  },
  {
    "x": "cuhî",
    "y": " b. cihud, -e"
  },
  {
    "x": "cuhîyê (m)",
    "y": " b. cihudî (m)"
  },
  {
    "x": "cuhud, -e",
    "y": " b. cihud, -e"
  },
  {
    "x": "cuhudey (m)",
    "y": " b. cihudîye (m)"
  },
  {
    "x": "cuhudênî (m)",
    "y": " b. cihudênî (m)"
  },
  {
    "x": "cuhudîye (m)",
    "y": " b. cihudîye (m)"
  },
  {
    "x": "cuiri (n)",
    "y": " b. cure (n)"
  },
  {
    "x": "cuiriyî (m)",
    "y": " b. cureyîye (m)"
  },
  {
    "x": "cuîy (m)",
    "y": " b. cuye-II (m)"
  },
  {
    "x": "culixe (m)",
    "y": " hindi"
  },
  {
    "x": "cumbuş (n)",
    "y": " cümbüş (bir saz)"
  },
  {
    "x": "cumbuşcen, -e",
    "y": " cümbüşçü"
  },
  {
    "x": "cume (n)",
    "y": " b. îne-I (n)"
  },
  {
    "x": "cumi (n)",
    "y": " b. îne-I (n)"
  },
  {
    "x": "cumle (m)",
    "y": " tümce, cümle\r\n~cumleya karî (m): eylem cümlesi, fiil cümlesi\r\n~cumleya pêrabestîye (m): bileşik cümle"
  },
  {
    "x": "cumleya karî (m)",
    "y": " eylem cümlesi, fiil cümlesi "
  },
  {
    "x": "cumleya nameyî (m)",
    "y": " ad cümlesi, isim cümlesi "
  },
  {
    "x": "cumleya negatîfe (m)",
    "y": " olumsuz cümle "
  },
  {
    "x": "cumleya pêrabestîye (m)",
    "y": " bileşik cümle"
  },
  {
    "x": "cumleya persî (m)",
    "y": " soru cümlesi "
  },
  {
    "x": "cumleya pozîtîfe (m)",
    "y": " olumlu cümle"
  },
  {
    "x": "cumleya sadeye (m)",
    "y": " basit cümle"
  },
  {
    "x": "cumleyê rêzkîyî (zh)",
    "y": " sıra cümleler"
  },
  {
    "x": "cun (n)",
    "y": " b. cuwen (n)"
  },
  {
    "x": "cuname (n)",
    "y": " biyografi, hayat hikâyesi, hayat öyküsü, tercümeihâl, yaşam öyküsü, özgeçmiş, CV (curriculum vitae)"
  },
  {
    "x": "cunameyî, -ye",
    "y": " biyografik"
  },
  {
    "x": "cunid (n)",
    "y": " b. cunit (n)"
  },
  {
    "x": "cunig (n)",
    "y": " b. cunit (n)"
  },
  {
    "x": "cunit (n)",
    "y": " sızıntı (sızan şey)"
  },
  {
    "x": "cunta (m)",
    "y": " cunta "
  },
  {
    "x": "cura (m)",
    "y": " cüce"
  },
  {
    "x": "cure (n)",
    "y": " cüce"
  },
  {
    "x": "curek, -e",
    "y": " cüce"
  },
  {
    "x": "curekîye (m)",
    "y": " cücelik"
  },
  {
    "x": "curet (n)",
    "y": " cesaret"
  },
  {
    "x": "cureyîye (m)",
    "y": " cücelik"
  },
  {
    "x": "curi (n)",
    "y": " b. cure (n)"
  },
  {
    "x": "curiyî (m)",
    "y": " b. cureyîye (m)"
  },
  {
    "x": "Curnê Reş (n)",
    "y": " Hilvan"
  },
  {
    "x": "curra (m)",
    "y": " b. cura (m)"
  },
  {
    "x": "curre (n)",
    "y": " b. cure (n)"
  },
  {
    "x": "curreyey (m)",
    "y": " b. cureyîye (m)"
  },
  {
    "x": "curûf (n)",
    "y": " cüruf, demir boku"
  },
  {
    "x": "cus (n)",
    "y": " b. cins (n)"
  },
  {
    "x": "cusan (m)",
    "y": " b. cisane (m)"
  },
  {
    "x": "cusane (m)",
    "y": " b. cisane (m)"
  },
  {
    "x": "cusani (m)",
    "y": " b. cisane (m)"
  },
  {
    "x": "cuslî",
    "y": " b. bicins, -e"
  },
  {
    "x": "cutene",
    "y": " çiğnemek"
  },
  {
    "x": "cutiş (n)",
    "y": " çiğneme"
  },
  {
    "x": "cuw (m)",
    "y": " b. cuye-II (m)"
  },
  {
    "x": "cuwa (m)",
    "y": " ark, hark"
  },
  {
    "x": "cuwa pey",
    "y": " ondan sonra"
  },
  {
    "x": "cuwa pê",
    "y": " b. cuwa pey"
  },
  {
    "x": "cuwa ver",
    "y": " b. verê cû"
  },
  {
    "x": "cuwab (n)",
    "y": " b. cewab (n)"
  },
  {
    "x": "cuwal (n)",
    "y": " b. ciwal (n)"
  },
  {
    "x": "cuwamerd (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "cuwamêr (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "cuwamêrd (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "cuwan, -i",
    "y": " b. ciwan, -e"
  },
  {
    "x": "cuwanike (m)",
    "y": " b. ciwanike (m)"
  },
  {
    "x": "cuwav (n)",
    "y": " b. cewab (n)"
  },
  {
    "x": "cuwazî (m)",
    "y": " b. cazû (m)"
  },
  {
    "x": "cuwazî bîyayene",
    "y": " b. cazû bîyene"
  },
  {
    "x": "cuwazîbîyayîş (n)",
    "y": " b. cazûbîyayîş (n)"
  },
  {
    "x": "cuwe (n)",
    "y": " b. cuwen (n)"
  },
  {
    "x": "cuweka",
    "y": " b. coka"
  },
  {
    "x": "cuwen (n)",
    "y": " 1)harman \r\n~2)harman yeri "
  },
  {
    "x": "cuwen çarnayene",
    "y": " döven sürmek, döven dövmek, harman dövmek"
  },
  {
    "x": "cuwen hewa dayene",
    "y": " harman savurmak"
  },
  {
    "x": "cuwen kutene",
    "y": " döven sürmek, döven dövmek, harman dövmek"
  },
  {
    "x": "cuwen kuwayene",
    "y": " döven sürmek, döven dövmek, harman dövmek"
  },
  {
    "x": "cuwen vay dayene",
    "y": " harman savurmak"
  },
  {
    "x": "cuwenan ser ra gêrayene",
    "y": " harman zamanı harmanları dolaşarak harman ürünü istemek suretiyle dilenmek "
  },
  {
    "x": "cuwendêmdayîş (n)",
    "y": " döven sürerken altta kalan dövülmemiş ekin saplarını üste çıkarmak için harman yığınını altüst etme"
  },
  {
    "x": "cuwenqelbedayîş (n)",
    "y": " döven sürerken altta kalan dövülmemiş ekin saplarını üste çıkarmak için harman yığınını altüst etme "
  },
  {
    "x": "cuwenqelibnayîş (n)",
    "y": " döven sürerken altta kalan dövülmemiş ekin saplarını üste çıkarmak için harman yığınını altüst etme"
  },
  {
    "x": "cuwera",
    "y": " b. cora"
  },
  {
    "x": "cuwîn (n)",
    "y": " b. cuwen (n)"
  },
  {
    "x": "cuwîn hewa dayîne",
    "y": " b. cuwen hewa dayene"
  },
  {
    "x": "cuwîn kutene",
    "y": " b. cuwen kutene"
  },
  {
    "x": "cuwîn kuwayene",
    "y": " b. cuwen kutene"
  },
  {
    "x": "cuwîn vey dayene",
    "y": " b. cuwen vay dayene"
  },
  {
    "x": "cuwînqelibnayîş (n)",
    "y": " b. cuwenqelibnayîş (n)"
  },
  {
    "x": "cuwîyayîş (n)",
    "y": " b. ciwîyayîş (n)"
  },
  {
    "x": "cuy (m)",
    "y": " b. cuye-II (m)"
  },
  {
    "x": "cuy cuy",
    "y": " b. cû cû"
  },
  {
    "x": "cuya pê",
    "y": " b. cuwa pey"
  },
  {
    "x": "cuyab (n)",
    "y": " b. cewab (n)"
  },
  {
    "x": "cuyane (n)",
    "y": " b. cane (n)"
  },
  {
    "x": "cuyanî",
    "y": " b. cane (n) "
  },
  {
    "x": "cuyanîye (m)",
    "y": " b. cana (m)"
  },
  {
    "x": "cuyayene",
    "y": " b. cutene"
  },
  {
    "x": "cuyayêne",
    "y": " b. cutene"
  },
  {
    "x": "cuyayîş-I (n)",
    "y": " b. ciwîyayîş (n)"
  },
  {
    "x": "cuyayîş-II (n)",
    "y": " b. cutiş (n)"
  },
  {
    "x": "cuye-I (m)",
    "y": " yaşam, hayat"
  },
  {
    "x": "cuya karî (m)",
    "y": " çalışma hayatı, iş hayatı"
  },
  {
    "x": "cuya xebate (m)",
    "y": " çalışma hayatı, iş hayatı"
  },
  {
    "x": "cuye-II (m)",
    "y": " ağaç sakızı (badem ağacı, geven vb. salgısı), reçine, zamk"
  },
  {
    "x": "cuyeyîş (n)",
    "y": " b. ciwîyayîş (n)"
  },
  {
    "x": "cuyi (n)",
    "y": " b. cuwen (n)"
  },
  {
    "x": "cuyih, -e",
    "y": " b. cihud, -e"
  },
  {
    "x": "cuyin (n)",
    "y": " b. cuwen (n) "
  },
  {
    "x": "cuyinqeldayîş (n)",
    "y": " b. cuwenqelbedayîş (n)"
  },
  {
    "x": "cuyî-I (m)",
    "y": " b. cu (m)"
  },
  {
    "x": "cuyî-II (n)",
    "y": " b. cuwen (n)"
  },
  {
    "x": "cuyîn (n)",
    "y": " b. cuwen (n) "
  },
  {
    "x": "cuyînqeldayîş (n)",
    "y": " b. cuwenqelbedayîş (n)"
  },
  {
    "x": "cuyun (n)",
    "y": " b. cuwen (n)"
  },
  {
    "x": "cuzbi (m)",
    "y": " b. cuze (m)"
  },
  {
    "x": "cuzda (n)",
    "y": " b. cizdan (n)"
  },
  {
    "x": "cuzdan (n)",
    "y": " b. cizdan (n)"
  },
  {
    "x": "cuzdo (n)",
    "y": " b. cizdan (n)"
  },
  {
    "x": "cuzdon (n)",
    "y": " b. cizdan (n)"
  },
  {
    "x": "cuzdû (n)",
    "y": " b. cizdan (n)"
  },
  {
    "x": "cuzdûn (n)",
    "y": " b. cizdan (n)"
  },
  {
    "x": "cuze (m)",
    "y": " Kur’an’ın otuz parçasından her biri"
  },
  {
    "x": "cuzi (m)",
    "y": " b. cuze (m)"
  },
  {
    "x": "cuzme (n)",
    "y": " b. cizme (n)"
  },
  {
    "x": "cû",
    "y": " b. yewe"
  },
  {
    "x": "cû cû",
    "y": " cik cik, cikcik (civcivleri çağırma ünlemi)"
  },
  {
    "x": "cû dima",
    "y": " b. dima cû"
  },
  {
    "x": "cûc (n)",
    "y": " b. cucik (n)"
  },
  {
    "x": "cûka",
    "y": " b. coka"
  },
  {
    "x": "cûm (n)",
    "y": " b. cam (n) "
  },
  {
    "x": "cûmerd, -i",
    "y": " b. comerd, -e"
  },
  {
    "x": "cûmerdek, -i",
    "y": " b. comerdek"
  },
  {
    "x": "cûmerdekey (m)",
    "y": " b. comerdekî (m)"
  },
  {
    "x": "cûmerdey (m)",
    "y": " b. comerdîye (m)"
  },
  {
    "x": "cûmerdê (m)",
    "y": " b. comerdî (m)"
  },
  {
    "x": "cûmêrd (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "cûmêrdek (n)",
    "y": " b. camêrdek (n)"
  },
  {
    "x": "cûmis, -i",
    "y": " b. gamêş, -e"
  },
  {
    "x": "cûmisqirûn (n)",
    "y": " b. gamêşqiran (n)"
  },
  {
    "x": "cûmî (n)",
    "y": " b. camî (n)"
  },
  {
    "x": "cûmîyerd",
    "y": " b. camêrd (n)"
  },
  {
    "x": "cûmyerd",
    "y": " b. camêrd (n)"
  },
  {
    "x": "cûn-I (n)",
    "y": " b. gan (n), can (n)"
  },
  {
    "x": "cûn fîda",
    "y": " b. can fîda"
  },
  {
    "x": "cûn-II (n)",
    "y": " b. cuwen (n)"
  },
  {
    "x": "cûn-III",
    "y": " b. ciwan, -e"
  },
  {
    "x": "cûna (m)",
    "y": " b. cana (m)"
  },
  {
    "x": "cûnbaz, -i",
    "y": " b. canbaz, -e"
  },
  {
    "x": "cûnbazê (m)",
    "y": " b. canbazîye (m)"
  },
  {
    "x": "cûnde (n)",
    "y": " b. cande (n)"
  },
  {
    "x": "cûne (n)",
    "y": " b. cane (n)"
  },
  {
    "x": "cûnfîda, -ye",
    "y": " b. canfîda, -ye"
  },
  {
    "x": "cûnga (n)",
    "y": " b. conega (n)"
  },
  {
    "x": "cûnik (m)",
    "y": " b. ciwanike (m)"
  },
  {
    "x": "cûniki (m)",
    "y": " b. ciwanike (m)"
  },
  {
    "x": "cûnsivik, -i",
    "y": " b. cansivik, -e"
  },
  {
    "x": "cûnsivikey (m)",
    "y": " b. cansivikîye (m)"
  },
  {
    "x": "cûnsivikê (m)",
    "y": " b. cansivikî (m)"
  },
  {
    "x": "cûnşenik, -i",
    "y": " b. canşenik, -e"
  },
  {
    "x": "cûnşenikey (m)",
    "y": " b. canşenikîye (m), ganşenikîye (m)"
  },
  {
    "x": "cûnşenikê (m)",
    "y": " b. canşenikî (m), ganşenikî (m)"
  },
  {
    "x": "cûra",
    "y": " b. cora-I"
  },
  {
    "x": "cûre-I (m)",
    "y": " b. jûre (m)"
  },
  {
    "x": "cûre-II (n)",
    "y": " b. cure (n)"
  },
  {
    "x": "cûrek, -e",
    "y": " b. curek, -e"
  },
  {
    "x": "cûrekîye (m)",
    "y": " b. curekîye (m)"
  },
  {
    "x": "cûreyê /m)",
    "y": " b. cureyîye (m)"
  },
  {
    "x": "cûrike (m)",
    "y": " b. jûre (m)"
  },
  {
    "x": "cûrne (n)",
    "y": " b. corne (n)"
  },
  {
    "x": "cûser (n)",
    "y": " b. dosere (n)"
  },
  {
    "x": "cûser a",
    "y": " b. dosere ra"
  },
  {
    "x": "cûser ra",
    "y": " b. dosere ra"
  },
  {
    "x": "cûsere (n)",
    "y": " b. dosere (n)"
  },
  {
    "x": "cûsere ra",
    "y": " b. dosere ra"
  },
  {
    "x": "cûwamerd (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "cûwen (n)",
    "y": " b. cuwen (n)"
  },
  {
    "x": "cûy (m)",
    "y": " b. cuye-II (m)"
  },
  {
    "x": "cûyi (n)",
    "y": " b. cuwen (n)"
  },
  {
    "x": "cûyin (n)",
    "y": " b. cuwen (n)"
  },
  {
    "x": "CV (curriculum vitae)",
    "y": " CV (curriculum vitae)"
  },
  {
    "x": "cwamêrd (n)",
    "y": " b. camêrd (n)"
  },
  {
    "x": "cyez (n)",
    "y": " b. cêz (n)"
  },
  {
    "x": "ÇÇÇ"
  },
  {
    "x": "ça-I",
    "y": " nere\r\n~Ti ça ra yî/ya? (Nerelisin?) "
  },
  {
    "x": "ça-II",
    "y": " b. çayê"
  },
  {
    "x": "ça-III (m)",
    "y": " b. çay (m)"
  },
  {
    "x": "ça-IV",
    "y": " b. çi-II"
  },
  {
    "x": "ça ray",
    "y": " b. çi rey"
  },
  {
    "x": "ça ça gim",
    "y": " b. çat çat gim"
  },
  {
    "x": "Çad",
    "y": " Çad"
  },
  {
    "x": "çadir (m)",
    "y": " b. çadire (m)"
  },
  {
    "x": "çadire (m)",
    "y": " çadır\r\n~çadira xelisnayîşî (m): kurtarma çadırı"
  },
  {
    "x": "çadira xelisnayîşî (m)",
    "y": " kurtarma çadırı"
  },
  {
    "x": "çadire piro dayene",
    "y": " çadır kurmak"
  },
  {
    "x": "çadire ronayene",
    "y": " çadır kurmak"
  },
  {
    "x": "çadire wefîştene",
    "y": " çadır kurmak"
  },
  {
    "x": "çadiri (m)",
    "y": " b. çadire (m)"
  },
  {
    "x": "çaê",
    "y": " b. çayê"
  },
  {
    "x": "çahar",
    "y": " b. çar-I"
  },
  {
    "x": "çaket (n)",
    "y": " b. çakêt (n)"
  },
  {
    "x": "çakêt (n)",
    "y": " ceket\r\n~çakêtê hamnanî (n): yazlık ceket\r\n~çakêtê zimistanî (n): kışlık ceket"
  },
  {
    "x": "çakêt û şalwarî",
    "y": " b. çakêt û şelwarî"
  },
  {
    "x": "çakêt û şelwarî",
    "y": " ceket ve şalvar"
  },
  {
    "x": "çakêtê hamnanî (n)",
    "y": " yazlık ceket"
  },
  {
    "x": "çakêtê zimistanî (n)",
    "y": " kışlık ceket"
  },
  {
    "x": "çakêto çermên (n)",
    "y": " deri ceket"
  },
  {
    "x": "çakêto sextîyanên (n)",
    "y": " deri ceket"
  },
  {
    "x": "çakit (n)",
    "y": " b. çakêt (n)"
  },
  {
    "x": "çakît (n)",
    "y": " b. çakêt (n)"
  },
  {
    "x": "çakuç (n)",
    "y": " çekiç"
  },
  {
    "x": "çakule (n)",
    "y": " b. çekule (n)"
  },
  {
    "x": "çakule bestene",
    "y": " b. çekule bestene"
  },
  {
    "x": "çakut (n)",
    "y": " çekiç "
  },
  {
    "x": "çakûç (n)",
    "y": " b. çakuç (n)"
  },
  {
    "x": "çal-I (m)",
    "y": " b. çale-I (m)"
  },
  {
    "x": "çal kotene",
    "y": " b. çale kewtene"
  },
  {
    "x": "çal-II (n)",
    "y": " b. ciwal (n)"
  },
  {
    "x": "çalak, -e",
    "y": " 1)eylemci\r\n~2)etkin\r\n~3)faal"
  },
  {
    "x": "çalakî (m)",
    "y": " eylem"
  },
  {
    "x": "çalakî kerdene",
    "y": " eylemde bulunmak"
  },
  {
    "x": "çalakîker, -e",
    "y": " eylemci"
  },
  {
    "x": "çalanî-I (m)",
    "y": " 1)yalak\r\n~2)yuva\r\n~3)kekliğin yavruladığı yer"
  },
  {
    "x": "çalanî-II (n)",
    "y": " b. çalanî (m)"
  },
  {
    "x": "çale-I (m)",
    "y": " 1)çukur\r\n~çala fesadîye (m): cadı kazanı\r\n~çala temernayîşî (m): gömme çukuru\r\n~2)yuva\r\n~3)folluk (tavuğun yumurtladığı veya kuluçkaya yattığı yer)\r\n~4)meyve veya sebze saklanan yer\r\n~5)banyo (banyo yapılan yer)"
  },
  {
    "x": "çala binerdkerdişî (m)",
    "y": " gömme çukuru"
  },
  {
    "x": "çala çimî (m)",
    "y": " b. çalika çimî (m)"
  },
  {
    "x": "çala fesadîye (m)",
    "y": " cadı kazanı"
  },
  {
    "x": "çala leximî (m)",
    "y": " fosseptik, lağım çukuru"
  },
  {
    "x": "çala temernayîşî (m)",
    "y": " gömme çukuru"
  },
  {
    "x": "çala wedaritişî (m)",
    "y": " gömme çukuru"
  },
  {
    "x": "çale bîyene",
    "y": " çukurlaşmak"
  },
  {
    "x": "çale kerdene",
    "y": " (ev dışında) meyve veya sebze saklamak"
  },
  {
    "x": "çale kewtene",
    "y": " kuluçkaya yatmak, gurka yatmak"
  },
  {
    "x": "çalê çimî (m)",
    "y": " b. çalika çimî (m)"
  },
  {
    "x": "çale-II (m)",
    "y": " 1)örtü olarak kullanılan bez\r\n~çala masa (m): masa örtüsü\r\n~2)çarşaf"
  },
  {
    "x": "çala masa (m)",
    "y": " masa örtüsü"
  },
  {
    "x": "çala nimajî (m)",
    "y": " namazlık"
  },
  {
    "x": "çalebîyayîş (n)",
    "y": " çukurlaşma"
  },
  {
    "x": "çaleki (m)",
    "y": " b. çalike (m)"
  },
  {
    "x": "çali (m)",
    "y": " b. çale (m)"
  },
  {
    "x": "çalik (m)",
    "y": " b. çalike (m)"
  },
  {
    "x": "çalike (m)",
    "y": " küçük çukur\r\n~çalika çimî (m): göz çukuru"
  },
  {
    "x": "çalika çimî (m)",
    "y": " göz çukuru"
  },
  {
    "x": "çalikê çimî (m)",
    "y": " b. çalika çimî (m)"
  },
  {
    "x": "çalikey çimî (m)",
    "y": " b. çalika çimî (m)"
  },
  {
    "x": "çaliki (m)",
    "y": " b. çalike (m)"
  },
  {
    "x": "çalim (n)",
    "y": " afi, caka, fiyaka, çalım, gösteriş"
  },
  {
    "x": "çalim kerdene",
    "y": " afi kesmek, afi satmak, afi yapmak, caka satmak, caka yapmak, fiyaka satmak, çalım satmak, gösteriş yapmak"
  },
  {
    "x": "çalî-çirpî (zh)",
    "y": " çalı çırpı"
  },
  {
    "x": "çalma (m)",
    "y": " çalma (koyu pekmez)"
  },
  {
    "x": "çalme (m)",
    "y": " b. çalma (m)"
  },
  {
    "x": "çalnî (m)",
    "y": " b. çalanî (m)"
  },
  {
    "x": "çalon (n)",
    "y": " b. kalan (n)"
  },
  {
    "x": "çalon çardeni",
    "y": " b. kalan kerdene"
  },
  {
    "x": "çalonî (m)",
    "y": " b. çalanî (m)"
  },
  {
    "x": "çam (n)",
    "y": " çam"
  },
  {
    "x": "çamasur (n)",
    "y": " b. çamaşir (n)"
  },
  {
    "x": "çamaşir (n)",
    "y": " çamaşır"
  },
  {
    "x": "çamêrî (m)",
    "y": " b. çamure (m)"
  },
  {
    "x": "çamêrrî (m)",
    "y": " b. çamure (m)"
  },
  {
    "x": "çamir (m)",
    "y": " b. çamure (m)"
  },
  {
    "x": "çamir n)",
    "y": " b. çamure (m)"
  },
  {
    "x": "çamirin, -e",
    "y": " b. çamurin, -e"
  },
  {
    "x": "çamur (n)",
    "y": " b. çamure (m)"
  },
  {
    "x": "çamure (m)",
    "y": " çamur\r\n~çamura xame (m): ham çamur\r\n~çamura vêşî (m): fazla çamur"
  },
  {
    "x": "çamura xame (m)",
    "y": " ham çamur"
  },
  {
    "x": "çamura vêşî (m)",
    "y": " fazla çamur"
  },
  {
    "x": "çamuri (m)",
    "y": " çamur"
  },
  {
    "x": "çamurin, -e",
    "y": " çamurlu"
  },
  {
    "x": "çamurî (m)",
    "y": " b. çamure (m)"
  },
  {
    "x": "çamurrî (m)",
    "y": " b. çamure (m)"
  },
  {
    "x": "çamûr (n)",
    "y": " b. çamure (m)"
  },
  {
    "x": "çamûrrî (m)",
    "y": " b. çamure (m)"
  },
  {
    "x": "çanaq (n)",
    "y": " çanak anten"
  },
  {
    "x": "çançolike (m)",
    "y": " b. çinçolike (m)"
  },
  {
    "x": "çançule (m)",
    "y": " b. sancûle (m)"
  },
  {
    "x": "çand",
    "y": " b. çend"
  },
  {
    "x": "çangal (n)",
    "y": " b. çengal (n)"
  },
  {
    "x": "çangal kerdene",
    "y": " b. çengal kerdene"
  },
  {
    "x": "çangil (n)",
    "y": " b. çanqil (n)"
  },
  {
    "x": "çangil kerdene",
    "y": " b. çengal kerdene"
  },
  {
    "x": "çangile (m)",
    "y": " b. çanqil (n)-1"
  },
  {
    "x": "çankil (n)",
    "y": " b. çengal (n)"
  },
  {
    "x": "çankil kerdene",
    "y": " b. çengal kerdene"
  },
  {
    "x": "çanqal (n)",
    "y": " b. çengal (n) "
  },
  {
    "x": "çanqal kerdene",
    "y": " b. çengal kerdene"
  },
  {
    "x": "çanqil (n)",
    "y": " 1)asa, baston\r\n~2)b. çengal (n) "
  },
  {
    "x": "çanqil kerdene",
    "y": " b. çengal kerdene"
  },
  {
    "x": "çanqile-I (m)",
    "y": " b. çanqil (n)"
  },
  {
    "x": "çanqile-II (m)",
    "y": " b. çeqla (m)"
  },
  {
    "x": "çante (n)",
    "y": " b. çente (n)"
  },
  {
    "x": "çantey destan (n)",
    "y": " b. çenteyê destan (n)"
  },
  {
    "x": "çantey paştî (n)",
    "y": " b. çenteyê piştî (n)"
  },
  {
    "x": "çantey polî (n)",
    "y": " b. çenteyê polî (n)"
  },
  {
    "x": "çanti (n)",
    "y": " b. çente (n)"
  },
  {
    "x": "çap (n)",
    "y": " kile, ölçek (tahıl ölçmeye yarayan kap), şinik"
  },
  {
    "x": "çapa borîye (m)",
    "y": " boru çapı"
  },
  {
    "x": "çapa lulîye (m)",
    "y": " boru çapı"
  },
  {
    "x": "çap kerdene",
    "y": " basmak (kitab vb. için)\r\n~Çap bike! (Bas!)\r\n~\t\r\n~Şima no kitab key çap kerd? (Bu kitabı ne zaman bastınız?)"
  },
  {
    "x": "çapaznayene",
    "y": " sıkıştırmak"
  },
  {
    "x": "çapaznayîş (n)",
    "y": " sıkıştırma"
  },
  {
    "x": "çapal (m)",
    "y": " b. çopole (m)"
  },
  {
    "x": "çapale (m)",
    "y": " b. çopole (m)"
  },
  {
    "x": "çapale werdene",
    "y": " b. çopole werdene"
  },
  {
    "x": "çapalî kerdene",
    "y": " b. çopolî kerdene"
  },
  {
    "x": "çapalî piro dayene",
    "y": " b. çopolî piro dayene"
  },
  {
    "x": "çapalewerdiş (n)",
    "y": " b. çopolewerdiş (n)"
  },
  {
    "x": "çapalîkerdiş (n)",
    "y": "b. çopolîkerdiş"
  },
  {
    "x": "çapalîpirodayîş (n)",
    "y": " b. çopolîpirodayîş (n)"
  },
  {
    "x": "çapane (m)",
    "y": " el açıldığında baş parmağın ucu ile işaret parmağının ucu arasındaki uzunluk"
  },
  {
    "x": "çapani (m)",
    "y": " b. çapane (m)"
  },
  {
    "x": "çape-I (m)",
    "y": " büyük tas"
  },
  {
    "x": "çape-II (m)",
    "y": " b. çap (n)"
  },
  {
    "x": "çape-III (m)",
    "y": " 1)çap (mat)\r\n~2)kalibre"
  },
  {
    "x": "çape-IV (m)",
    "y": " basım, baskı (kitap baskısı vb.)\r\n~çapa bikoordîne (mat): koordineli çap\r\n~"
  },
  {
    "x": "çapa bikoordîne (mat)",
    "y": " koordineli çap"
  },
  {
    "x": "çapa dîyin, -e",
    "y": " ikinci baskı"
  },
  {
    "x": "çape-V (m)",
    "y": " el açıldığında baş parmağın ucu ile işaret parmağının ucu arasındaki uzunluk"
  },
  {
    "x": "çapemenîye (m)",
    "y": " basın"
  },
  {
    "x": "çapik, -e",
    "y": " 1)çabuk, tez\r\n~2)hızlı, süratli"
  },
  {
    "x": "çapik a",
    "y": " hızlıca"
  },
  {
    "x": "çapikey (m)",
    "y": " b. çapikîye (m)"
  },
  {
    "x": "çapikî (m)",
    "y": " çabukluk, tezlik, hızlılık"
  },
  {
    "x": "çapikîye (m)",
    "y": " çabukluk, tezlik, hızlılık"
  },
  {
    "x": "çapiksiwar, -e",
    "y": " b. çapiksuwar, -e"
  },
  {
    "x": "çapiksiwarey (m)",
    "y": " b. çapiksuwarîye (m)"
  },
  {
    "x": "çapiksiwarê (m)",
    "y": " b. çapiksuwarî (m)"
  },
  {
    "x": "çapiksuwar, -e",
    "y": " atik, çevik"
  },
  {
    "x": "çapiksuwarey (m)",
    "y": " b. çapiksuwarîye (m)"
  },
  {
    "x": "çapiksuwarê (m)",
    "y": " b. çapiksuwarî (m)"
  },
  {
    "x": "çapiksuwarî (m)",
    "y": " atiklik"
  },
  {
    "x": "çapiksuwarîye (m)",
    "y": " atiklik, çeviklik"
  },
  {
    "x": "çapkar, -e",
    "y": " basımcı (basım işini yapan), matbaacı (basım işini yapan)"
  },
  {
    "x": "çapkarî (m)",
    "y": " basımcılık, matbaacılık"
  },
  {
    "x": "çapkarîye (m)",
    "y": " basımcılık, matbaacılık"
  },
  {
    "x": "çapker, -e",
    "y": " baskıcı (matbaacılıkta)"
  },
  {
    "x": "çapkerda (m)",
    "y": " b. çapkerdî, -ye"
  },
  {
    "x": "çapkerde (n)",
    "y": " b. çapkerdî, -ye"
  },
  {
    "x": "çapkerdiş (n)",
    "y": " basma (kitab vb. için)"
  },
  {
    "x": "çapkerdî, -ye",
    "y": " basılı"
  },
  {
    "x": "çaplûs, -e",
    "y": " çanak yalayıcı, dalkavuk, yalaka, yaltak"
  },
  {
    "x": "çaplûsênî (m)",
    "y": " çanak yalayıcılık, dalkavukluk, yalakalık, yaltaklık"
  },
  {
    "x": "çaplûsîye (m)",
    "y": " çanak yalayıcılık, dalkavukluk, yalakalık, yaltaklık"
  },
  {
    "x": "çapole (m)",
    "y": " b. çopole (m)"
  },
  {
    "x": "çaponi (m)",
    "y": " b. çapane (m)"
  },
  {
    "x": "çaput (n)",
    "y": " çaput, paçavra"
  },
  {
    "x": "çapxane (n)",
    "y": " basımevi, matbaa"
  },
  {
    "x": "çapxanedar, -e",
    "y": " basımcı (basımevi işleten), matbaacı (matbaa işleten)"
  },
  {
    "x": "çapxanedarî (m)",
    "y": " basımcılık, matbaacılık"
  },
  {
    "x": "çapxanedarîye (m)",
    "y": " basımcılık, matbaacılık"
  },
  {
    "x": "çaq (n)",
    "y": " bacak"
  },
  {
    "x": "çaqa (m)",
    "y": " b. çaq (n)"
  },
  {
    "x": "çaqanseroroniştiş (n)",
    "y": " çöküş"
  },
  {
    "x": "çaqe (n)",
    "y": " b. çaq (n)"
  },
  {
    "x": "çaqi (n)",
    "y": " b. çaq (n)"
  },
  {
    "x": "çaqil-I (n)",
    "y": " b. çengal (n)"
  },
  {
    "x": "çaqil kerdene",
    "y": " b. çengal kerdene"
  },
  {
    "x": "çaqil-II (n)",
    "y": " b. çaxil (n)"
  },
  {
    "x": "çaqilçîman (n)",
    "y": " gulyabani"
  },
  {
    "x": "çaqilin",
    "y": " b. çaxilin, -e"
  },
  {
    "x": "çaqirne (n)",
    "y": " b. çarqirnî (n)"
  },
  {
    "x": "çaqirni (n)",
    "y": " b. çarqirnî (n)"
  },
  {
    "x": "çaqmaq (n)",
    "y": " b. çeqmaq (n)"
  },
  {
    "x": "çaqmaqlî (n)",
    "y": " b. çeqmaqlî (n)"
  },
  {
    "x": "çar-I",
    "y": " dört\r\n~çar heme: her dört\r\n~çar hemîne: her dördü\r\n~çar kirarî: dört işlem (mat)\r\n~çar kirarîyê bingeyênî: dört temel işlem\r\n~çar ra yew: çeyrek\r\n~çar sey: dört yüz\r\n~çar-panc reyî: dört beş kez"
  },
  {
    "x": "çar heme",
    "y": " her dört"
  },
  {
    "x": "çar hemîne",
    "y": " her dördü"
  },
  {
    "x": "çar kirarî",
    "y": " dört işlem (mat)"
  },
  {
    "x": "çar kirarîyê bingeyênî",
    "y": " dört temel işlem"
  },
  {
    "x": "çar operasyonî",
    "y": " dört işlem (mat)"
  },
  {
    "x": "çar ra yew",
    "y": " çeyrek"
  },
  {
    "x": "çar sey",
    "y": " dört yüz"
  },
  {
    "x": "çar-panc reyî",
    "y": " dört beş kez"
  },
  {
    "x": "çar-II (n)",
    "y": " 1)akıtma (küçükbaş ve büyükbaş hayvanların alnındaki aklık)"
  },
  {
    "x": "çar, -e-III",
    "y": " alnı akıtmalı hayvan, alnında beyazlık olan büyük ya da küçükbaş hayvan"
  },
  {
    "x": "çar-IV (m)",
    "y": " b. çare-III (m)"
  },
  {
    "x": "Çar (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "çar û çur",
    "y": " çalı çırpı"
  },
  {
    "x": "çarane (m)",
    "y": " dörtlük, rübai"
  },
  {
    "x": "çaras",
    "y": " b. çarês"
  },
  {
    "x": "çarber (n)",
    "y": " parmaklık biçimindeki kapı, çit kapısı, çeper kapısı; çit biçimindeki kapı "
  },
  {
    "x": "çarbere (m)",
    "y": " dörttaş [oyunu]"
  },
  {
    "x": "çarberi (m)",
    "y": " b. çarbere (m) "
  },
  {
    "x": "çarç bîyene",
    "y": " çarçur olmak, çarçur"
  },
  {
    "x": "çarç kerdene",
    "y": " 1)saçmak, serpmek\r\n~2)çarçur etmek, çarçur"
  },
  {
    "x": "çarçba (m)",
    "y": " b. çarçîba (m)"
  },
  {
    "x": "çarçkerdis",
    "y": " b. çarçkerdiş (n)"
  },
  {
    "x": "çarçkerdiş (n)",
    "y": " saçma, serpme"
  },
  {
    "x": "çarçkerdîs",
    "y": " b. çarçkerdiş (n)"
  },
  {
    "x": "çarçat (n)",
    "y": " dörtyol, dörtyol kavşağı"
  },
  {
    "x": "çarçef (m)",
    "y": " b. çarşefe (m)"
  },
  {
    "x": "çarçefi (m)",
    "y": " b. çarşefe (m)"
  },
  {
    "x": "çarçewa (m)",
    "y": " çerçeve\r\n~çarçewaya konseptkî (m): kavramsal çerçeve\r\n~çarçewaya mentiqîye (m): mantıksal çerçeve"
  },
  {
    "x": "çarçewaya konseptkî (m)",
    "y": " kavramsal çerçeve"
  },
  {
    "x": "çarçewaya mefhûmkî (m)",
    "y": " kavramsal çerçeve"
  },
  {
    "x": "çarçewaya mentiqîye (m)",
    "y": " mantıksal çerçeve"
  },
  {
    "x": "çarçewakerdiş (n)",
    "y": " kadraj"
  },
  {
    "x": "çarçewe (n)",
    "y": " b. çarçewa (m)"
  },
  {
    "x": "çarçifa (m)",
    "y": " b. çarçewa (m)"
  },
  {
    "x": "çarçim, -e",
    "y": " açıkgöz, cingöz"
  },
  {
    "x": "çarçimîye (m)",
    "y": " açıkgözlülük, açıkgözlük, cingözlük"
  },
  {
    "x": "çarçiwa (m)",
    "y": " b. çarçewa (m)"
  },
  {
    "x": "çarçîba (m)",
    "y": " pencere"
  },
  {
    "x": "çardar (n)",
    "y": " sedye\r\n~çardaro gêrok (n): portatif sedye"
  },
  {
    "x": "çardaro gêrok (n)",
    "y": " portatif sedye"
  },
  {
    "x": "çardaro portatîf (n)",
    "y": " portatif sedye"
  },
  {
    "x": "çardaro seyar (n)",
    "y": " portatif sedye"
  },
  {
    "x": "çardax (m)",
    "y": " b. çardaxe (m)"
  },
  {
    "x": "çardaxe (m)",
    "y": " 1)örtme (bina için)\r\n~2)salon, ayvan"
  },
  {
    "x": "çardaxi (m)",
    "y": " b. çardaxe (m)"
  },
  {
    "x": "çare-I (n)",
    "y": " alın"
  },
  {
    "x": "çare-II (n)",
    "y": " çare, çözüm, hal, çıkar yol"
  },
  {
    "x": "çare bîyene",
    "y": " çözülmek (sorun vb. için)"
  },
  {
    "x": "çare dîyene",
    "y": " çözüm bulmak"
  },
  {
    "x": "çare kerdene",
    "y": " 1)çözmek (sorun vb. için), halletmek\r\n~2)becermek, üstesinden gelmek"
  },
  {
    "x": "çare vînayene",
    "y": " çözüm bulmak"
  },
  {
    "x": "çare-III (m)",
    "y": " çalı veya dal parçası (kesilmiş ve yapraksız)"
  },
  {
    "x": "çarî (zh)",
    "y": " çalı çırpı"
  },
  {
    "x": "Çare (m)",
    "y": " inek ve keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "çarebes, -e",
    "y": " b. çarebeş, -e"
  },
  {
    "x": "Çarebes (n)",
    "y": " b. Çarebeş (n)"
  },
  {
    "x": "Çarebese (m)",
    "y": " b. Çarebeşe (m)"
  },
  {
    "x": "çarebeş, -e",
    "y": " alnı akıtmalı hayvan, alnında beyazlık olan büyük ya da küçükbaş hayvan"
  },
  {
    "x": "Çarebeş (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "Çarebeşe (m)",
    "y": " inek ve keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "Çarebeşi (m)",
    "y": " b. Çarebeşe (m)"
  },
  {
    "x": "çarebîyayîş (n)",
    "y": " çözülme (sorun vb. için)"
  },
  {
    "x": "çarek-I (n)",
    "y": " b. çarêk (n)"
  },
  {
    "x": "çarek-II (m)",
    "y": " b. çarêke (m)"
  },
  {
    "x": "Çarek (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "Çareke (m)",
    "y": " inek ve keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "çarekerdiş (n)",
    "y": " 1)çözme (sorun vb. için), hal\r\n~2)becerme, üstesinden gelme"
  },
  {
    "x": "Çareki (m)",
    "y": " b. Çareke (m)"
  },
  {
    "x": "çareme",
    "y": " b. çar hemîne"
  },
  {
    "x": "çaremêne",
    "y": " b. çar hemîne"
  },
  {
    "x": "çaremîna",
    "y": " b. çar hemîne"
  },
  {
    "x": "çaremînan",
    "y": " b. çar hemîne"
  },
  {
    "x": "çareng (n)",
    "y": " b. kêran (n)"
  },
  {
    "x": "çarenus (n)",
    "y": " mukadderat, yazgı, kader, alın yazısı"
  },
  {
    "x": "çares",
    "y": " b. çarês"
  },
  {
    "x": "Çarevile (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "çarex (n)",
    "y": " b. çarix (n)"
  },
  {
    "x": "çarêk (n)",
    "y": " çeyrek (altın için)"
  },
  {
    "x": "çarêke (m)",
    "y": " çeyrek (zaman için)  "
  },
  {
    "x": "çarêkfînal (n)",
    "y": " çeyrek final"
  },
  {
    "x": "çarêkfînalîst, -e",
    "y": " çeyrek finalist"
  },
  {
    "x": "çarêmêna",
    "y": " b. çar hemîne"
  },
  {
    "x": "çarês",
    "y": " on dört"
  },
  {
    "x": "çarêx (n)",
    "y": " b. çarix (n)"
  },
  {
    "x": "çargamî",
    "y": " dörtnala"
  },
  {
    "x": "çargamî şîyene",
    "y": " dörtnala gitmek"
  },
  {
    "x": "çargamî vazdayene",
    "y": " dörtnala koşmak"
  },
  {
    "x": "çargamî vazdnayene",
    "y": " dörtnala koşturmak"
  },
  {
    "x": "çargamkî (zr)",
    "y": " dörtnala"
  },
  {
    "x": "çargomî vazdayene",
    "y": " b. çargamî vazdayene"
  },
  {
    "x": "çargomkî (zr)",
    "y": " b. çargamkî (zr)"
  },
  {
    "x": "çargoşe (n)",
    "y": " dörtgen"
  },
  {
    "x": "çargûm",
    "y": " b. çargamî"
  },
  {
    "x": "çargûm şîyayene",
    "y": " b. çargamî şîyene"
  },
  {
    "x": "çargûm vazdayene",
    "y": " b. çargamî vazdayene"
  },
  {
    "x": "çargûmkî (zr)",
    "y": " b. çargamkî (zr)"
  },
  {
    "x": "çarhinge (m)",
    "y": " kızıl renkli iri bir yabanarısı"
  },
  {
    "x": "çarhingi (m)",
    "y": " b. çarhinge (m)"
  },
  {
    "x": "çari-I (m)",
    "y": " b. çar, -e"
  },
  {
    "x": "çari-II (m)",
    "y": " b. çare-III (m)"
  },
  {
    "x": "Çari (m)",
    "y": " b. Çare (m"
  },
  {
    "x": "çarig (m)",
    "y": " b. çarige (m)"
  },
  {
    "x": "çarige (m)",
    "y": " 16 “welçeke” büyüklüğünde bir tahıl ölçüsü"
  },
  {
    "x": "çarigi (m)",
    "y": " b. çarige (m)"
  },
  {
    "x": "çarin, -e",
    "y": " dördüncü"
  },
  {
    "x": "çarine (m)",
    "y": " dörttaş [oyunu]  "
  },
  {
    "x": "çaring (m)",
    "y": " b. çarhinge (m)"
  },
  {
    "x": "çaringe (m)",
    "y": " b. çarhinge (m)"
  },
  {
    "x": "çaringi (m)",
    "y": " b. çarhinge (m)"
  },
  {
    "x": "çarix (n)",
    "y": " çarık"
  },
  {
    "x": "çarix/çarixî dayene deştene",
    "y": " çarık diktirmek"
  },
  {
    "x": "çarix/çarixî deştene",
    "y": " çarık dikmek"
  },
  {
    "x": "çarîg-I (n)",
    "y": " b. çarêk (n)"
  },
  {
    "x": "çarîg-II (m)",
    "y": " b. çarêke (m)"
  },
  {
    "x": "çarîng (m)",
    "y": " b. çarhinge (m)"
  },
  {
    "x": "çarkinar (n)",
    "y": " çepeçevre, çepçevre, fırdolayı"
  },
  {
    "x": "çarlîng (m)",
    "y": " b. çarhinge (m)"
  },
  {
    "x": "çarmêrkî (n)",
    "y": " bağdaş"
  },
  {
    "x": "çarmêrkî roniştene",
    "y": " bağdaş kurmak"
  },
  {
    "x": "çarnalî vaznayene",
    "y": " b. çargamî vazdnayene"
  },
  {
    "x": "çarnaya (m)",
    "y": " çeviri, tercüme"
  },
  {
    "x": "çarnaye (n)",
    "y": " çeviri, tercüme"
  },
  {
    "x": "çarnayene",
    "y": " 1)çevirmek, döndürmek\r\n~2)çevirmek, tercüme etmek\r\n~3)gezdirmek, dolaştırmak"
  },
  {
    "x": "çarnayîne",
    "y": " b. çarnayene"
  },
  {
    "x": "çarnayîş (n)",
    "y": " 1)çevirme, döndürme\r\n~2)çevirme, tercüme etme\r\n~3)gezdirme, dolaştırma"
  },
  {
    "x": "çarnayîye (m)",
    "y": " çeviri, tercüme"
  },
  {
    "x": "çarnawox, -e",
    "y": " b. çarnayox, -e"
  },
  {
    "x": "çarnayox, -e",
    "y": " 1)çeviren, çevirmen, mütercim, tercüman, tercüme eden\r\n~çarnayoxa sondwerda (m): yeminli tercüman\r\n~çarnayoxa sondwerdîye (m): yeminli tercüman\r\n~çarnayoxo sondwerde (n): yeminli tercüman"
  },
  {
    "x": "çarnayoxa sondwerda (m)",
    "y": " yeminli tercüman"
  },
  {
    "x": "çarnayoxa sondwerdîye (m)",
    "y": " yeminli tercüman"
  },
  {
    "x": "çarnayoxo sondwerde (n)",
    "y": " yeminli tercüman"
  },
  {
    "x": "Çaro (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "çaroke (m)",
    "y": " dörttaş [oyunu]"
  },
  {
    "x": "çarox (n)",
    "y": " b. çarix (n)"
  },
  {
    "x": "çarpay",
    "y": " dörtnala"
  },
  {
    "x": "çarpay şîyayene",
    "y": " b. çarpay şîyene"
  },
  {
    "x": "çarpay şîyene",
    "y": " dörtnala gitmek"
  },
  {
    "x": "çarpay vazdayene",
    "y": " dörtnala koşmak"
  },
  {
    "x": "çarpay vistene",
    "y": " dörtnala koşturmak"
  },
  {
    "x": "çarpay xar dayene",
    "y": " dörtnala koşturmak"
  },
  {
    "x": "çarpaykî (zr)",
    "y": " dörtnala"
  },
  {
    "x": "çarpencike (m)",
    "y": " yengeç "
  },
  {
    "x": "çarpê, -ye",
    "y": " eşek"
  },
  {
    "x": "çarq (n)",
    "y": " b. çerx (n)"
  },
  {
    "x": "çarqirnî (n)",
    "y": " çepeçevre, çepçevre"
  },
  {
    "x": "çarr (m)",
    "y": " b. çare (m)"
  },
  {
    "x": "çarre (m)",
    "y": " b. çare (m) "
  },
  {
    "x": "çarres",
    "y": " b. çarês"
  },
  {
    "x": "çarri (m)",
    "y": " b. çare (m)"
  },
  {
    "x": "çarrî (zh)",
    "y": " b. çarî (zh)"
  },
  {
    "x": "çarsefe (m)",
    "y": " b. çarşefe (m)"
  },
  {
    "x": "çarseme (n)",
    "y": " b. çarşeme (n)"
  },
  {
    "x": "çarsibay (n)",
    "y": " bugünden sonraki dördüncü gün"
  },
  {
    "x": "çarsi (n)",
    "y": " b. çarşu (n)"
  },
  {
    "x": "çarsu (n)",
    "y": " b. çarşu (n)"
  },
  {
    "x": "çarşeb (m)",
    "y": " b. çarşefe (m)"
  },
  {
    "x": "çarşebe (m)",
    "y": " b. çarşefe (m)"
  },
  {
    "x": "çarşebê bastêqî (m)",
    "y": " b. çarşefa bastêqî (m)"
  },
  {
    "x": "çarşef (m)",
    "y": " b. çarşefe (m)"
  },
  {
    "x": "çarşefê bastêqî (m)",
    "y": " b. çarşefa bastêqî (m)"
  },
  {
    "x": "çarşefe (m)",
    "y": " çarşaf\r\n~çarşefa doşekî (m): yatak çarşafı\r\n~çarşefa lihêfî (m): nevresim\r\n~çarşefa nivîne (m): yüklük örtüsü"
  },
  {
    "x": "çarşefa bastêqî (m)",
    "y": " üzerine pestil bulamacı sürülerek pestil yapımı için kullanılan bez parçası "
  },
  {
    "x": "çarşefa bastêxî (m)",
    "y": " b. çarşefa bastêqî (m)"
  },
  {
    "x": "çarşefa doşekî (m)",
    "y": " yatak çarşafı"
  },
  {
    "x": "çarşefa lihêfî (m)",
    "y": " nevresim"
  },
  {
    "x": "çarşefa nivîne (m)",
    "y": " yüklük örtüsü"
  },
  {
    "x": "çarşefa xoeştişî (m)",
    "y": " atlama çarşafı"
  },
  {
    "x": "çarşefi (m)",
    "y": " b. çarşefe (m)"
  },
  {
    "x": "çarşembi (n)",
    "y": " b. çarşeme (n)"
  },
  {
    "x": "çarşeme (n)",
    "y": " çarşamba"
  },
  {
    "x": "çarşemi (n)",
    "y": " b. çarşeme (n)"
  },
  {
    "x": "çarşeve (m)",
    "y": " b. çarşefe (m)"
  },
  {
    "x": "çarşewe (m)",
    "y": " b. çarşefe (m)"
  },
  {
    "x": "çarşi (n)",
    "y": " b. çarşu (n)"
  },
  {
    "x": "çarşime (n)",
    "y": " b. çarşeme (n)"
  },
  {
    "x": "çarşî (n)",
    "y": " b. çarşu (n)"
  },
  {
    "x": "çarşî (m)",
    "y": " b. çarşu (n)"
  },
  {
    "x": "çarşu (n)",
    "y": " çarşı"
  },
  {
    "x": "çarşuye (m)",
    "y": " b. çarşu (n)"
  },
  {
    "x": "çarşû (n)",
    "y": " b. çarşu (n)"
  },
  {
    "x": "çarwa (m)",
    "y": " davar, küçükbaş"
  },
  {
    "x": "çarwe (n)",
    "y": " davar, küçükbaş"
  },
  {
    "x": "çarwey (zh)",
    "y": " b. çarweyî (zh)"
  },
  {
    "x": "çarweyî (zh)",
    "y": " küçükbaş hayvan sürüsü"
  },
  {
    "x": "çarx (n)",
    "y": " b. çerx (n)"
  },
  {
    "x": "çaryex (n)",
    "y": " b. çarix (n)"
  },
  {
    "x": "çaşba (m)",
    "y": " b. çarçîba (m)"
  },
  {
    "x": "çaşme (n)",
    "y": " b. çarşeme (n)"
  },
  {
    "x": "çat (n)",
    "y": " 1)çatal (ucu kollara ayrılmış)\r\n~2)b. çatrayîr (n)"
  },
  {
    "x": "çat raharûn (n)",
    "y": " b. çatrayîr (n)"
  },
  {
    "x": "çat rarûn (n)",
    "y": " b. çatrayîr (n)"
  },
  {
    "x": "çatê çar rayîran (n)",
    "y": " b. çarçat (n)"
  },
  {
    "x": "çatê çemî",
    "y": " akarsu kavşağı"
  },
  {
    "x": "çatê di rayîran (n)",
    "y": " b. diçat (n)"
  },
  {
    "x": "çatê rayerûn (n)",
    "y": " b. çatrayîr (n)"
  },
  {
    "x": "çatê rayîran (n)",
    "y": " b. çatrayîr (n)"
  },
  {
    "x": "çatê rayîrî (n)",
    "y": " b. çatrayîr (n)"
  },
  {
    "x": "çatê robarî",
    "y": " akarsu kavşağı"
  },
  {
    "x": "çatê royî",
    "y": " akarsu kavşağı"
  },
  {
    "x": "çatî di rayîrûn (n)",
    "y": " b. diçat (n)"
  },
  {
    "x": "Çat (n)",
    "y": " Çat"
  },
  {
    "x": "çat çat gim",
    "y": " koç veya tekenin toslamasını sağlamak için söylenen bir söz"
  },
  {
    "x": "çatal (m)",
    "y": " b. çetale (m)"
  },
  {
    "x": "çatal (n)",
    "y": " b. çetale (m)"
  },
  {
    "x": "çatale (m)",
    "y": " b. çetale (m)"
  },
  {
    "x": "çatax (n)",
    "y": " çatal (yol, ağaç vb. için)"
  },
  {
    "x": "çatî (n)",
    "y": " çatı"
  },
  {
    "x": "çatme (n)",
    "y": " çatkı"
  },
  {
    "x": "çatme bestene",
    "y": " çatkı bağlamak, çatkı çatmak"
  },
  {
    "x": "çatmeyê çar rayan (n)",
    "y": " b. çarçat (n)"
  },
  {
    "x": "çatmeyê çar reyan (n)",
    "y": " b. çarçat (n)"
  },
  {
    "x": "çatmeyê di rayîran (n)",
    "y": " b. diçat (n)"
  },
  {
    "x": "çatmeyê rayîran (n)",
    "y": " b. çatrayîr (n)"
  },
  {
    "x": "çatrar (n)",
    "y": " b. çatrayîr (n)"
  },
  {
    "x": "çatray (m)",
    "y": " b. çatrayîr (n)"
  },
  {
    "x": "çatrayîr (n)",
    "y": " kavşak (yol kavşağı)"
  },
  {
    "x": "çavela (m)",
    "y": " çavela, çavalye"
  },
  {
    "x": "çavikî (zh)",
    "y": " gözlük"
  },
  {
    "x": "çavî (n)",
    "y": " akarsuyun çıktığı yer"
  },
  {
    "x": "çawiş, -e",
    "y": " çavuş"
  },
  {
    "x": "çawişê/a îtfaîya",
    "y": " itfaiye çavuşu"
  },
  {
    "x": "çawişey (m)",
    "y": " b. çawişîye (m), çawişî (m)"
  },
  {
    "x": "çawişê (m)",
    "y": " b. çawişîye (m), çawişî (m)"
  },
  {
    "x": "çawişî (m)",
    "y": " çavuşluk"
  },
  {
    "x": "çawişîye (m)",
    "y": " çavuşluk"
  },
  {
    "x": "çawîş, -e",
    "y": " b. çawiş, -e"
  },
  {
    "x": "çawîşî (m)",
    "y": " b. çawişîye (m), çawişî (m)"
  },
  {
    "x": "çawîşîye (m)",
    "y": " b. çawişîye (m), çawişî (m)"
  },
  {
    "x": "çawuş, -e",
    "y": " b. çawiş, -e"
  },
  {
    "x": "çawuşey (m)",
    "y": " b. çawişîye (m), çawişî (m)"
  },
  {
    "x": "çawuşî (m)",
    "y": " b. çawişîye (m), çawişî (m)"
  },
  {
    "x": "çawuşîye (m)",
    "y": " b. çawişîye (m), çawişî (m)"
  },
  {
    "x": "çax (n)",
    "y": " çağ, zaman, devir, vakit\r\n~çaxan hemîne de: çağlar boyu\r\n~çaxan pêrune de: çağlar boyu\r\n~çaxan ra ohêm: çağlar ötesi\r\n~çaxan ra ohet: çağlar ötesi\r\n~çaxan ra wet: çağlar ötesi\r\n~Çaxo Entîk (n): Antik Çağ"
  },
  {
    "x": "çax akerdene",
    "y": " çağ açmak, çağ başlatmak"
  },
  {
    "x": "çax dayene destpêkerdene",
    "y": " çağ başlatmak, çağ açmak"
  },
  {
    "x": "çax girewtene",
    "y": " çağ kapatmak"
  },
  {
    "x": "çax padayene",
    "y": " çağ kapatmak"
  },
  {
    "x": "çax racenayîne",
    "y": " çağ kapatmak"
  },
  {
    "x": "çaxan hemîne de",
    "y": " çağlar boyu"
  },
  {
    "x": "çaxan pêrune de",
    "y": " çağlar boyu"
  },
  {
    "x": "çaxan ra ohêm",
    "y": " çağlar ötesi"
  },
  {
    "x": "çaxan ra ohet",
    "y": " çağlar ötesi"
  },
  {
    "x": "çaxan ra wet",
    "y": " çağlar ötesi"
  },
  {
    "x": "çaxan têdîne de",
    "y": " çağlar boyu"
  },
  {
    "x": "Çaxo Entîk (n)",
    "y": " Antik Çağ"
  },
  {
    "x": "çaxûn pîyorin di",
    "y": " b. çaxan pêrune de"
  },
  {
    "x": "çaxûn pîyorin id",
    "y": " b. çaxan pêrune de"
  },
  {
    "x": "çax (m)",
    "y": " b. çax (n)"
  },
  {
    "x": "çax kerdene",
    "y": " b. çeq kerdene"
  },
  {
    "x": "çaxala (m)",
    "y": " b. çeqla (m)"
  },
  {
    "x": "çaxe (m)",
    "y": " b. çax (n)"
  },
  {
    "x": "çaxil (n)",
    "y": " çakıl"
  },
  {
    "x": "çaxilin, -e",
    "y": " çakıllı"
  },
  {
    "x": "çaxkerdiş (n)",
    "y": " b. çeqkerdiş (n)"
  },
  {
    "x": "çaxle (m)",
    "y": " b. çeqla (m)"
  },
  {
    "x": "çay-I (m)",
    "y": " çay"
  },
  {
    "x": "çay (n)",
    "y": " b. çay-I (m)"
  },
  {
    "x": "çay-II",
    "y": " b. çayê"
  },
  {
    "x": "çay-III (n)",
    "y": " b. kay (n)"
  },
  {
    "x": "çay dayene",
    "y": " b. kay dayene"
  },
  {
    "x": "çay kerdene",
    "y": " b. kay kerdene"
  },
  {
    "x": "çaydan (n)",
    "y": " b. çaydane (m)"
  },
  {
    "x": "çaydane (m)",
    "y": " çaydanlık, demlik"
  },
  {
    "x": "çaydang (n)",
    "y": " b. çaydane (m)"
  },
  {
    "x": "çaydanki (m)",
    "y": " b. çaydane (m)"
  },
  {
    "x": "çaydayîş (n)",
    "y": " b. kaydayîş (n)"
  },
  {
    "x": "çaydelix (n)",
    "y": " b. çaydane (m)"
  },
  {
    "x": "çaydoni (m)",
    "y": " b. çaydane (m)"
  },
  {
    "x": "çaydûn (m)",
    "y": " b. çaydane (m)"
  },
  {
    "x": "çayê",
    "y": " neden, niçin, niye"
  },
  {
    "x": "çayger, -e",
    "y": " çaycı"
  },
  {
    "x": "çayîr (n)",
    "y": " çimen, ot, çayır"
  },
  {
    "x": "çaykar, -e",
    "y": " 1)çaycı\r\n~2)ocakçı"
  },
  {
    "x": "çaykarênî (m)",
    "y": " ocakçılık"
  },
  {
    "x": "çaykarî (m)",
    "y": " ocakçılık"
  },
  {
    "x": "çaykarîye (m)",
    "y": " ocakçılık"
  },
  {
    "x": "çaykerdiş (n)",
    "y": " b. kaykerdiş (n)"
  },
  {
    "x": "çayxane (n)",
    "y": " 1)çayevi, çayhane\r\n~2)çay ocağı"
  },
  {
    "x": "çayxone (n)",
    "y": " b. çayxane (n)"
  },
  {
    "x": "çayxûne (n)",
    "y": " b. çayxane (n)"
  },
  {
    "x": "çe-I (n)",
    "y": " b. keye (n)"
  },
  {
    "x": "çe-II",
    "y": " b. çi-II"
  },
  {
    "x": "çe ray",
    "y": " b. çi rey"
  },
  {
    "x": "çe rey",
    "y": " b. çi rey"
  },
  {
    "x": "çe rê",
    "y": " b. çi rey"
  },
  {
    "x": "çear",
    "y": " b. çar-I"
  },
  {
    "x": "çear sey",
    "y": " b. çar sey"
  },
  {
    "x": "çeb",
    "y": " b. çew"
  },
  {
    "x": "çeber (n)",
    "y": " b. keyber (n) "
  },
  {
    "x": "Çeçenîstan (n)",
    "y": " Çeçenistan"
  },
  {
    "x": "çeçûlik (n)",
    "y": "çelik çomak"
  },
  {
    "x": "çeer",
    "y": " b. çar-I"
  },
  {
    "x": "çeer rê se",
    "y": " b. çar sey"
  },
  {
    "x": "çef (n)",
    "y": " b. keyf (n)"
  },
  {
    "x": "çef kerdayene",
    "y": " b. keyf kerdene"
  },
  {
    "x": "çefê ... amayene",
    "y": " b. keyfê ... ameyene"
  },
  {
    "x": "çefê ... ardene",
    "y": " b. keyfê ... ardene"
  },
  {
    "x": "çefin, -e",
    "y": " b. keyfin, -e"
  },
  {
    "x": "çefî (m)",
    "y": " b. kefî (m), kefîye (m)"
  },
  {
    "x": "çefye (m)",
    "y": " b. kefî (m), kefîye (m)"
  },
  {
    "x": "çehar",
    "y": " b. çar-I"
  },
  {
    "x": "çehar sey",
    "y": " b. çar sey"
  },
  {
    "x": "çeharin, -i",
    "y": " b. çarin, -e"
  },
  {
    "x": "çeharqirni (n)",
    "y": " b. çarqirnî (n)"
  },
  {
    "x": "çehend",
    "y": " b. çend"
  },
  {
    "x": "çeher",
    "y": " b. çar-I"
  },
  {
    "x": "çeher sey",
    "y": " b. çar sey"
  },
  {
    "x": "çehergom",
    "y": " b. çargamî"
  },
  {
    "x": "çeherin, -i",
    "y": " b. çarin, -e"
  },
  {
    "x": "çeherine (m)",
    "y": " b. çarine (m)"
  },
  {
    "x": "çeherpay",
    "y": " b. çarpay"
  },
  {
    "x": "çeherpaykî (zr)",
    "y": " b. çarpaykî (zr)"
  },
  {
    "x": "çehersibay (n)",
    "y": " b. çarsibay (n)"
  },
  {
    "x": "çehersiwey (n)",
    "y": " b. çarsibay (n)"
  },
  {
    "x": "çeheryen",
    "y": " b. çarin, -e"
  },
  {
    "x": "çek-I (n)",
    "y": " elbise, giyecek, giysi, kılık"
  },
  {
    "x": "çek û çol",
    "y": " pılı pırtı"
  },
  {
    "x": "çek û çolî",
    "y": " b. çek û çol"
  },
  {
    "x": "çek û çuel",
    "y": " b. çek û çol"
  },
  {
    "x": "çek-çol",
    "y": " pılı pırtı"
  },
  {
    "x": "çek-çuel",
    "y": " b. çek-çol"
  },
  {
    "x": "çek-II (n)",
    "y": " çek "
  },
  {
    "x": "çekadir (n)",
    "y": " ateşli silah"
  },
  {
    "x": "çekal (n)",
    "y": " çok eskimiş ayakkabı"
  },
  {
    "x": "çekalçî, -ye",
    "y": " ayakkabı tamircisi, ayakkabı onarımcısı, ayakkabıcı (onarımcı), yamacı (ayakkabı onaran) "
  },
  {
    "x": "çekalçîyey (m)",
    "y": " b. çekalçîyî (m), çekalçîyîye (m)"
  },
  {
    "x": "çekalçîyê (m)",
    "y": " b. çekalçîyî (m), çekalçîyîye (m)"
  },
  {
    "x": "çekalçîyî (m)",
    "y": " ayakkabıcılık, pabuççuluk, yamacılık (ayakkabı onarımcılığı)"
  },
  {
    "x": "çekalçîyîye (m)",
    "y": " ayakkabıcılık, pabuççuluk, yamacılık (ayakkabı onarımcılığı)"
  },
  {
    "x": "çekçeko (n)",
    "y": " çekirge"
  },
  {
    "x": "çekdar, -e",
    "y": " silahlı "
  },
  {
    "x": "çeke (m)",
    "y": " silah"
  },
  {
    "x": "çekecax (n)",
    "y": " çekecek"
  },
  {
    "x": "çekekî (zh)",
    "y": " evcilik"
  },
  {
    "x": "çekekî kay kerdene",
    "y": " evcilik oynamak"
  },
  {
    "x": "çekem (n)",
    "y": " ağda"
  },
  {
    "x": "çekemin, -e",
    "y": " ağdalı"
  },
  {
    "x": "çeket (n)",
    "y": " b. çakêt (n)"
  },
  {
    "x": "çekêr, -e (m)",
    "y": " b. keyeker, -e"
  },
  {
    "x": "çeki (m)",
    "y": " b. çeke (m)"
  },
  {
    "x": "çekî-I (zh)",
    "y": " 1)üst baş (giysiler)\r\n~2)aybaşı (kadın için), hayız"
  },
  {
    "x": "çekî-II",
    "y": " b. çîyekî"
  },
  {
    "x": "Çekîstan (n)",
    "y": " Çek Cumhuriyeti"
  },
  {
    "x": "çekroş, -e",
    "y": " silahçı (silah satan)"
  },
  {
    "x": "çekroşî (m)",
    "y": " silahçılık"
  },
  {
    "x": "çekroşîye (m)",
    "y": " silahçılık"
  },
  {
    "x": "çektar, -e",
    "y": " b. çekdar, -e"
  },
  {
    "x": "çeku (n)",
    "y": " b. çekuye (m)"
  },
  {
    "x": "çekule (n)",
    "y": " yara kabuğu"
  },
  {
    "x": "çekule bestene",
    "y": " kabuk bağlamak (yara için) "
  },
  {
    "x": "çekur (n)",
    "y": " kuru deri"
  },
  {
    "x": "çekurr (n)",
    "y": " b. çekur (n)"
  },
  {
    "x": "çekuye (m)",
    "y": " sözcük, kelime\r\n~çekuya hemmana (m): eş anlamlı sözcük, eş anlamlı kelime\r\n~çekuya hemvenge (m): eş sesli sözcük, eş sesli kelime\r\n~çekuya pêrabestîye (m): bileşik sözcük, bileşik kelime\r\n~çekuya sadeye (m): basit sözcük, basit kelime\r\n~çekuya viraştîye (m): türemiş sözcük, türemiş kelime"
  },
  {
    "x": "çekuya hemmana (m)",
    "y": " eş anlamlı sözcük, eş anlamlı kelime"
  },
  {
    "x": "çekuya hemvenge (m)",
    "y": " eş sesli sözcük, eş sesli kelime"
  },
  {
    "x": "çekuya pêrabestîye (m)",
    "y": " bileşik sözcük, bileşik kelime"
  },
  {
    "x": "çekuya sadeye (m)",
    "y": " basit sözcük, basit kelime "
  },
  {
    "x": "çekuya viraştîye (m)",
    "y": " türemiş sözcük, türemiş kelime"
  },
  {
    "x": "çekû (m)",
    "y": " b. çekuye (m)"
  },
  {
    "x": "çekûye (m)",
    "y": " b. çekuye (m)"
  },
  {
    "x": "çekviraştox, -e",
    "y": " silahçı (silah yapan)"
  },
  {
    "x": "çekviraştoxî (m)",
    "y": " silahçılık"
  },
  {
    "x": "çekviraştoxîye (m)",
    "y": " silahçılık"
  },
  {
    "x": "çel (n)",
    "y": " 1)çelik (kısa değnek veya dal\r\n~ 2)çelik çomak"
  },
  {
    "x": "çel û çuk",
    "y": " b. çêl û çûk"
  },
  {
    "x": "çel-çu (n)",
    "y": " çelik çomak"
  },
  {
    "x": "çele (n)",
    "y": " 1)ocak (ocak ayı)\r\n~2)kışın ortasında genellikle soğuk geçen kırk günlük süre"
  },
  {
    "x": "Çele (m)",
    "y": " inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "çelek, -i",
    "y": " b. çilek, -e"
  },
  {
    "x": "Çeli (m)",
    "y": " b. Çele (m)"
  },
  {
    "x": "Çelo (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "çelqezîyayene",
    "y": " b. çelqizîyayene"
  },
  {
    "x": "çelqezîyayîş (n)",
    "y": " b. çelqizîyayîş (n)"
  },
  {
    "x": "çelqeznayene",
    "y": " b. çelqiznayene"
  },
  {
    "x": "çelqeznayîş (n)",
    "y": " b. çelqiznayîş (n)"
  },
  {
    "x": "çelqizîyayene",
    "y": " (dışarıya dökülmeksizin) çalkalanmak"
  },
  {
    "x": "çelqizîyayîş (n)",
    "y": " (dışarıya dökülmeksizin) çalkalanma"
  },
  {
    "x": "çelqiznayene",
    "y": " (dışarıya dökmeksizin) çalkalamak"
  },
  {
    "x": "çelqiznayîş (n)",
    "y": " (dışarıya dökmeksizin) çalkalama"
  },
  {
    "x": "çelqnayene",
    "y": " çalkalamak"
  },
  {
    "x": "çelqnayîş (n)",
    "y": " çalkalama"
  },
  {
    "x": "çelte (n)",
    "y": " b. çente (n)"
  },
  {
    "x": "çeltug (n)",
    "y": " çeltik"
  },
  {
    "x": "çelxazî (zh)",
    "y": " b. çirxazî (zh)"
  },
  {
    "x": "çelxazî vetene",
    "y": " b. çirxazî vetene"
  },
  {
    "x": "çem-I (n)",
    "y": " ırmak, nehir, akarsu; çay"
  },
  {
    "x": "çem-II (n)",
    "y": " b. çim (n)"
  },
  {
    "x": "çemaçîr (n)",
    "y": " b. çimçêrî (m)"
  },
  {
    "x": "çemaçur (n)",
    "y": " b. çimçêrî (m)"
  },
  {
    "x": "çembele (n)",
    "y": " b. çembil (n)"
  },
  {
    "x": "çembeleyîn (n)",
    "y": " b. çembilin, -e"
  },
  {
    "x": "çember (n)",
    "y": " çember"
  },
  {
    "x": "çembere (m)",
    "y": " b. çember (n)"
  },
  {
    "x": "çemberi (m)",
    "y": " çember (n)"
  },
  {
    "x": "çemberî, -ye",
    "y": " çembersel"
  },
  {
    "x": "çembil (n)",
    "y": " kulp"
  },
  {
    "x": "çembilin, -e",
    "y": " kulplu"
  },
  {
    "x": "çemçe-I (n)",
    "y": " kepçe"
  },
  {
    "x": "çemçe-II (n)",
    "y": " çelik (kısa değnek veya dal)"
  },
  {
    "x": "çemçek (n)",
    "y": " b. çemçik-I (n)"
  },
  {
    "x": "çemçi (n)",
    "y": " b. çemçe-I (n)"
  },
  {
    "x": "çemçik-I (n)",
    "y": " kepçe"
  },
  {
    "x": "çemçik-II (n)",
    "y": " çelik (kısa değnek veya dal)"
  },
  {
    "x": "çemçik û çiwa",
    "y": " b. çemçik û çuwa "
  },
  {
    "x": "çemçik û çuwa",
    "y": " çelik çomak"
  },
  {
    "x": "çemçik-çiwa",
    "y": " b. çemçik-çuwa (n) "
  },
  {
    "x": "çemçik-çuwa (n) ",
    "y": "çelik çomak"
  },
  {
    "x": "çemçilik (n)",
    "y": " b. çemçîlik (n)"
  },
  {
    "x": "çemçîg (n)",
    "y": " b. çemçik-I (n)"
  },
  {
    "x": "çemçîlik (n)",
    "y": " buz sarkıntısı, buz saçağı"
  },
  {
    "x": "çemçîllik (n)",
    "y": " b. çemçîlik (n)"
  },
  {
    "x": "çemçîr (n)",
    "y": " b. çimçêrî (m)"
  },
  {
    "x": "çemçulik (n)",
    "y": " b. çemçîlik (n)"
  },
  {
    "x": "çemel (n)",
    "y": " b. çembil (n)"
  },
  {
    "x": "çemer (n)",
    "y": " b. kemer-II (n)"
  },
  {
    "x": "çemil dayene",
    "y": " arka ayakları üzerine kalkarak ön ayaklarını ağacın yüksekçe bir yerine dayamak (hayvan için)"
  },
  {
    "x": "çemilîyayene",
    "y": " arka ayakları üzerine kalkarak ön ayaklarını ağacın yüksekçe bir yerine dayamak (hayvan için)"
  },
  {
    "x": "çemirnayene",
    "y": " çemremek (kol, paça, etek vb. için), sıvamak (kol, paça, etek vb. için)"
  },
  {
    "x": "çemirnayîş (n)",
    "y": " çemreme (kol, paça, etek vb. için)"
  },
  {
    "x": "Çemişgezek (n)",
    "y": " Çemişgezek"
  },
  {
    "x": "çemle (n)",
    "y": " b. çembil (n)"
  },
  {
    "x": "çemleyin, -e",
    "y": " b. çembilin, -e"
  },
  {
    "x": "çemor n)",
    "y": " b. çamure (m)"
  },
  {
    "x": "çemuz (n)",
    "y": " b. temuz, -e-I"
  },
  {
    "x": "çemûz (n)",
    "y": " b. temuz, -e-I"
  },
  {
    "x": "çen",
    "y": " b. çend"
  },
  {
    "x": "çena-I",
    "y": " b. çina-I"
  },
  {
    "x": "çena-II (m)",
    "y": " b. çina-II (n)"
  },
  {
    "x": "çena-III (m)",
    "y": " b. kêna (m)"
  },
  {
    "x": "çençorike (m)",
    "y": " b. çinçolike (m)"
  },
  {
    "x": "çend",
    "y": " 1)kaç\r\n~2)birkaç\r\n~3)ne kadar"
  },
  {
    "x": "çend ... ehend ...",
    "y": " b. çend ... hende ..."
  },
  {
    "x": "çend ... ewqas ...",
    "y": " ne kadar ... o kadar"
  },
  {
    "x": "çend ... hende ...",
    "y": " ne kadar ... o kadar \r\n~Ez çend zana ti zî hende zanî. (Ben ne kadar biliyorsam sen de o kadar biliyorsun.) "
  },
  {
    "x": "çend texteyê ... kêmîbîyayîş",
    "y": " akıl zayıflığı"
  },
  {
    "x": "çende",
    "y": " b. çend"
  },
  {
    "x": "çendi",
    "y": " b. çend"
  },
  {
    "x": "çendi ... ehendi ...",
    "y": " b. çend ... hende ..."
  },
  {
    "x": "çendî, -ye",
    "y": " nicel"
  },
  {
    "x": "çendîye (m)",
    "y": " nicelik"
  },
  {
    "x": "çene (n)",
    "y": " çene\r\n~çeneyo binên (n): alt çene\r\n~çeneyo serên (n): üst çene"
  },
  {
    "x": "çeneyo binên (n)",
    "y": " alt çene"
  },
  {
    "x": "çeneyo serên (n)",
    "y": " üst çene"
  },
  {
    "x": "çenebaz, -e",
    "y": " 1)çenesi kuvvetli, çenebaz, çeneli\r\n~2)geveze"
  },
  {
    "x": "çenebazey (m)",
    "y": " b. çenebazî (m), çenebazîye (m)"
  },
  {
    "x": "çenebazey kerdene",
    "y": " b. çenebazî kerdene, çenebazîye kerdene"
  },
  {
    "x": "çenebazeykerdiş (n)",
    "y": " b. çenebazîkerdiş (n), çenebazîyekerdiş (n)"
  },
  {
    "x": "çenebazê (m)",
    "y": " b. çenebazî (m), çenebazîye (m)"
  },
  {
    "x": "çenebazê kerdene",
    "y": " b. çenebazî kerdene, çenebazîye kerdene"
  },
  {
    "x": "çenebazêkerdiş (n)",
    "y": " b. çenebazîkerdiş (n), çenebazîyekerdiş (n)"
  },
  {
    "x": "çenebazî (m)",
    "y": " gevezelik"
  },
  {
    "x": "çenebazî kerdene",
    "y": " gevezelik etmek"
  },
  {
    "x": "çenebazîkerdiş (n)",
    "y": " gevezelik etme"
  },
  {
    "x": "çenebazîye (m)",
    "y": " gevezelik"
  },
  {
    "x": "çenebazîye kerdene",
    "y": " gevezelik etmek"
  },
  {
    "x": "çenebazîyekerdiş (n)",
    "y": " gevezelik etme"
  },
  {
    "x": "çenedar, -e",
    "y": " çeneli "
  },
  {
    "x": "çeneke (m)",
    "y": " b. kêneke (m)"
  },
  {
    "x": "çeneyin, -e",
    "y": " çeneli"
  },
  {
    "x": "çenê",
    "y": " b. çinayî ra"
  },
  {
    "x": "çenê ra",
    "y": " b. çinayî ra"
  },
  {
    "x": "çeng-I (n)",
    "y": " 1)apaz, avuç, hapaz, koşam\r\n~2)omuz başı"
  },
  {
    "x": "çeng-II (n)",
    "y": " b. çene (n)"
  },
  {
    "x": "çengal (n)",
    "y": " 1)b. nake (m)\r\n~2)çengel, ot burma işinde kullanılan bir çeşit çengel\r\n~3)kanca"
  },
  {
    "x": "çengal kerdene",
    "y": " çengellemek\r\n~Berî çengal bike! (Kapıyı çengelle!)"
  },
  {
    "x": "çengal piro kerdene",
    "y": " çengellemek\r\n~Çengalê berî piro ke! (Kapıyı çengelle!)"
  },
  {
    "x": "çengal tafînayîne",
    "y": " b. çengal tavistene"
  },
  {
    "x": "çengal tavistene",
    "y": " çengellemek\r\n~Çengalê berî tafîne! (Kapıyı çengelle!)"
  },
  {
    "x": "çengalek (n)",
    "y": " tırnak işareti"
  },
  {
    "x": "çengalin, -e",
    "y": " çengelli"
  },
  {
    "x": "çengar (n)",
    "y": " b. çengal (n)"
  },
  {
    "x": "çengar kerdene",
    "y": " b. çengal kerdene"
  },
  {
    "x": "çenge-I (n)",
    "y": " köşe, dış köşe"
  },
  {
    "x": "çengeyê soba (n)",
    "y": " soba borusu dirseği"
  },
  {
    "x": "çenge-II (n)",
    "y": " b. çene (n)"
  },
  {
    "x": "çenge-III (m)",
    "y": " b. çeng-I (n)"
  },
  {
    "x": "çengel-I (n)",
    "y": " kaya"
  },
  {
    "x": "çengel-II (n)",
    "y": " b. çengal (n)"
  },
  {
    "x": "çengel pafînayîne",
    "y": " b. çengal tavistene"
  },
  {
    "x": "çengel-III (n)",
    "y": " b. çene (n)"
  },
  {
    "x": "çengele (n)",
    "y": " b. çengile (n)"
  },
  {
    "x": "çengelin (n)",
    "y": " kayalık"
  },
  {
    "x": "çengi (n)",
    "y": " b. çenge (n)"
  },
  {
    "x": "çengile (n)",
    "y": " kol "
  },
  {
    "x": "çengizîyayene",
    "y": " uyuşmak"
  },
  {
    "x": "çengizîyayîş (n)",
    "y": " uyuşma"
  },
  {
    "x": "çengiznayene",
    "y": " uyuşturmak"
  },
  {
    "x": "çengiznayîş (n)",
    "y": " uyuşturma"
  },
  {
    "x": "çengle (n)",
    "y": " b. çengile (n)"
  },
  {
    "x": "çenik (n)",
    "y": " b. çene (n)"
  },
  {
    "x": "çenike (m)",
    "y": " b. çene (n) "
  },
  {
    "x": "çenke (m)",
    "y": " b. çene (n)"
  },
  {
    "x": "çennê",
    "y": " b. çinayî ra"
  },
  {
    "x": "çennê ra",
    "y": " b. çinayî ra"
  },
  {
    "x": "çente (n)",
    "y": " çanta\r\n~çenteyê destan (n): el çantası\r\n~çenteyê hetkarîya lezkanîye (n): ilk yardım çantası\r\n~çenteyê piştî (n): sırt çantası\r\n~çenteyê polî (n): kol çantası"
  },
  {
    "x": "çenteyê destan (n)",
    "y": " el çantası"
  },
  {
    "x": "çenteyê hetkarîya lezkanîye (n)",
    "y": " ilk yardım çantası"
  },
  {
    "x": "çenteyê piştî (n)",
    "y": " sırt çantası"
  },
  {
    "x": "çenteyê polî (n)",
    "y": " kol çantası"
  },
  {
    "x": "çentederz, -e",
    "y": " çantacı"
  },
  {
    "x": "çenteroş, -e",
    "y": " çantacı"
  },
  {
    "x": "çentik (n)",
    "y": " küçük çanta"
  },
  {
    "x": "çenyayîne",
    "y": " b. çînayene"
  },
  {
    "x": "çenyayîş (n)",
    "y": " b. çînayîş (n)"
  },
  {
    "x": "çep, -e",
    "y": " 1)sol\r\n~2)ters\r\n~Lajekî solê xo çep pay kerdê. (Oğlan ayakkabılarını ters giymiş.)\r\n~3)aykırı"
  },
  {
    "x": "çep a",
    "y": " ters (ters tarafa)\r\n~Birayê Hişyarî vewre de rayîrê xo şaş kerdo, çep a şiyo. (Hişyar’ın kardeşi karda yolunu şaşırmış, ters tarafa gitmiş.)"
  },
  {
    "x": "çepal (n)",
    "y": " b. çopole (m)"
  },
  {
    "x": "çepeki (m)",
    "y": " b. çerpe (m)"
  },
  {
    "x": "çepel, -e",
    "y": " kirli"
  },
  {
    "x": "çeper (n)",
    "y": " çit, çeper"
  },
  {
    "x": "çepernayene",
    "y": " çabalamak, çaba göstermek, çaba harcamak, cehdetmek, gayret etmek, gayret göstermek"
  },
  {
    "x": "çepernayîş (n)",
    "y": " cehdetme, çabalama, gayret etme"
  },
  {
    "x": "çepikî (zh)",
    "y": " halay"
  },
  {
    "x": "çepil (n)",
    "y": " b. çembil (n)"
  },
  {
    "x": "çepilin, -e",
    "y": " b. çembilin, -e"
  },
  {
    "x": "çepgir, -e",
    "y": " solcu"
  },
  {
    "x": "çepîye (m)",
    "y": " aykırılık"
  },
  {
    "x": "çepkî (n)",
    "y": " halay"
  },
  {
    "x": "çepqîyayene",
    "y": " abanmak"
  },
  {
    "x": "çepqîyayîş (n)",
    "y": " abanma "
  },
  {
    "x": "çepraz, -e",
    "y": " çapraz"
  },
  {
    "x": "çeprazkî",
    "y": " çaprazvari"
  },
  {
    "x": "çeq-I (n)",
    "y": " kıvılcım"
  },
  {
    "x": "çeq-II (n)",
    "y": " hiza"
  },
  {
    "x": "çeq kerdene",
    "y": " farkına varmak"
  },
  {
    "x": "çeqan ser kewtene",
    "y": " farkına varmak"
  },
  {
    "x": "çeqan ser vistene",
    "y": " farkına vardırmak"
  },
  {
    "x": "çeqanserkewtiş (n)",
    "y": " farkına varma"
  },
  {
    "x": "çeqanservistiş (n)",
    "y": " farkına vardırma"
  },
  {
    "x": "çeqel, -e",
    "y": " çakal "
  },
  {
    "x": "çeqer",
    "y": " sarı"
  },
  {
    "x": "çeqi (m)",
    "y": " çeq (n)"
  },
  {
    "x": "çeqkerdiş (n)",
    "y": " farkına varma"
  },
  {
    "x": "çeqla (m)",
    "y": " çağla (b. firinge)"
  },
  {
    "x": "çeqle (n)",
    "y": " 1)b. çeqla (m)\r\n~2)badem"
  },
  {
    "x": "çeqmaq (n)",
    "y": " çakmak"
  },
  {
    "x": "çeqmaqlî (n)",
    "y": " bir tüfek çeşidi"
  },
  {
    "x": "çeqûbere (m)",
    "y": " dama, dokuztaş vb. oyunlarda çok yönlü olumlu (rakip için olumsuz) oyun şansı elde etme durumu"
  },
  {
    "x": "çer-I (n)",
    "y": " b. çêr (n)"
  },
  {
    "x": "çer-II",
    "y": " b. çar-I"
  },
  {
    "x": "çer sey",
    "y": " b. çar sey"
  },
  {
    "x": "çeran (n)",
    "y": " b. kêran (n)"
  },
  {
    "x": "çerang (n)",
    "y": " b. kêran (n)"
  },
  {
    "x": "çerayene",
    "y": " otlanmak, otlamak, yayılmak (koyun, keçi vb. için)\r\n~Bizî çerenê. (Keçiler otlanıyorlar.)"
  },
  {
    "x": "çerayîne",
    "y": " b. çerayene"
  },
  {
    "x": "çerayîş (n)",
    "y": " otlanma, otlama, yayılma (koyun, keçi vb. için)"
  },
  {
    "x": "çerber (n)",
    "y": " b. çarber (n)"
  },
  {
    "x": "çerçewa (m)",
    "y": " b. çarçewa (m)"
  },
  {
    "x": "çerçewe (m)",
    "y": " b. çarçewa (m)"
  },
  {
    "x": "çerçim, -i",
    "y": " b. çarçim, -e"
  },
  {
    "x": "çerçimey (m)",
    "y": " b. çarçimîye (m)"
  },
  {
    "x": "çerçîwe (m)",
    "y": " b. çarçîba (m)"
  },
  {
    "x": "çerçî, -ye",
    "y": " çerçi"
  },
  {
    "x": "çerdene",
    "y": " otlanmak, otlamak, yayılmak (koyun, keçi vb. için)"
  },
  {
    "x": "çerdis (n)",
    "y": " b. çerdiş (n)"
  },
  {
    "x": "çerdiş (n)",
    "y": " otlanma, otlama, yayılma (koyun, keçi vb. için)"
  },
  {
    "x": "çere (n)",
    "y": " çim, çimen, çayır, ot"
  },
  {
    "x": "çerepuz (n)",
    "y": " çimen"
  },
  {
    "x": "çerexîyayene",
    "y": " b. çerixîyayene"
  },
  {
    "x": "çerexîyayêne",
    "y": " b. çerixîyayene"
  },
  {
    "x": "çerexîyayîne",
    "y": " b. çerixîyayene"
  },
  {
    "x": "çerexîyayîs (n)",
    "y": " b. çerixîyayîş (n) "
  },
  {
    "x": "çerexîyayîş (n)",
    "y": " b. çerixîyayîş (n)"
  },
  {
    "x": "çerexnayene",
    "y": " b. çerixnayene"
  },
  {
    "x": "çerexnayêne",
    "y": " b. çerixnayene"
  },
  {
    "x": "çerexnayîş (n)",
    "y": " b. çerixnayîş (n)"
  },
  {
    "x": "çereyeni",
    "y": " b. çerayene"
  },
  {
    "x": "çerez (n)",
    "y": " çerez"
  },
  {
    "x": "çerên, -i",
    "y": " b. çarin, -e"
  },
  {
    "x": "çerênayene",
    "y": " otlatmak\r\n~Bizan biçerêne, dima bîyare. (Keçileri otlat, sonra getir.)"
  },
  {
    "x": "çerênayîne",
    "y": " b. çerênayene"
  },
  {
    "x": "çerênayîş (n)",
    "y": " otlatma"
  },
  {
    "x": "çerênîyayene",
    "y": " otlatılmak"
  },
  {
    "x": "çerênîyayîne",
    "y": " b. çerênîyayene"
  },
  {
    "x": "çerênîyayîş (n)",
    "y": " otlatılma"
  },
  {
    "x": "çerêyayene",
    "y": " b. çerîyayene"
  },
  {
    "x": "çerêyayene",
    "y": " b. çerênîyayene"
  },
  {
    "x": "çerêyayîş (n)",
    "y": " b. çerênîyayîş (n)"
  },
  {
    "x": "çerin, -i",
    "y": " b. çarin, -e"
  },
  {
    "x": "çerine (m)",
    "y": " b. çarine (m)"
  },
  {
    "x": "çerixîyayene",
    "y": " 1)dönmek\r\n~2)kaymak"
  },
  {
    "x": "çerixîyayîş (n)",
    "y": " 1)dönme, dönüş\r\n~2)kayma"
  },
  {
    "x": "çerixnayene",
    "y": " 1)çevirmek, döndürmek\r\n~2)kaydırmak\r\n~3)gezdirmek"
  },
  {
    "x": "çerixnayîş (n)",
    "y": " 1)çevirme, döndürme\r\n~2)kaydırma 3gezdirme"
  },
  {
    "x": "çerîyaya (m)",
    "y": " (ekin, ot vb. için) hayvanlarca yenmiş olan"
  },
  {
    "x": "çerîyaye (n)",
    "y": " (ekin, ot vb. için) hayvanlarca yenmiş olan"
  },
  {
    "x": "çerîyayene-I",
    "y": " (ekin, ot vb. için) hayvanlarca yenmek "
  },
  {
    "x": "çerîyayene-II",
    "y": " b. çerênîyayene"
  },
  {
    "x": "çerîyayîne",
    "y": " b. çerîyayene"
  },
  {
    "x": "çerîyayîş (n)",
    "y": " b. çerênîyayîş (n)"
  },
  {
    "x": "çerîye (m)",
    "y": " b. çêrîye (m), çêrî (m)"
  },
  {
    "x": "çerkem (n)",
    "y": " b. çekem (n)"
  },
  {
    "x": "çerkinar (n)",
    "y": " b. çarkinar (n)"
  },
  {
    "x": "çerm (n)",
    "y": " deri\r\n~çermê mêşna/mî/mîye (n): meşin\r\n~çermo merde (n): ölü deri"
  },
  {
    "x": "çermê mêşna/mî/mîye (n)",
    "y": " meşin"
  },
  {
    "x": "çermo merde (n)",
    "y": " ölü deri"
  },
  {
    "x": "çerme (n)",
    "y": " deri"
  },
  {
    "x": "çermên, -e",
    "y": " deriden yapılma"
  },
  {
    "x": "çermi (n)",
    "y": " b. çerme (n)"
  },
  {
    "x": "çermkar, -e",
    "y": " derici"
  },
  {
    "x": "çermroş, -e",
    "y": " derici"
  },
  {
    "x": "çermsîya, -ye",
    "y": " zenci"
  },
  {
    "x": "çerpay",
    "y": " b. çarpay"
  },
  {
    "x": "çerpay şîyayene",
    "y": " b. çarpay şîyene"
  },
  {
    "x": "çerpay şîyene",
    "y": " b. çarpay şîyene"
  },
  {
    "x": "çerpay vazdayene",
    "y": " b. çarpay vazdayene"
  },
  {
    "x": "çerpaykî (zr)",
    "y": " b. çarpaykî (zr)"
  },
  {
    "x": "çerpe (m)",
    "y": " badana"
  },
  {
    "x": "çerpe kerdene",
    "y": " badanalamak"
  },
  {
    "x": "çerpekerdiş (n)",
    "y": " badanalama"
  },
  {
    "x": "çerpeki (m)",
    "y": " b. çerpe (m)"
  },
  {
    "x": "çerpi (m)",
    "y": " b. çerpe (m)"
  },
  {
    "x": "çerq (n)",
    "y": " b. çerx-I (n)"
  },
  {
    "x": "çerrexîyayene",
    "y": " b. çerixîyayene"
  },
  {
    "x": "çerrexîyayîş (n",
    "y": " b. çerixîyayîş (n)"
  },
  {
    "x": "çerrixîyayene",
    "y": " b. çerixîyayene"
  },
  {
    "x": "çerrixîyayîş (n)",
    "y": " b. çerixîyayîş (n)"
  },
  {
    "x": "çersibay (n)",
    "y": " b. çarsibay (n)"
  },
  {
    "x": "çerşefe (m)",
    "y": " b. çarşefe (m)"
  },
  {
    "x": "çertene",
    "y": " b. çerdene"
  },
  {
    "x": "çerx-I (n)",
    "y": " 1)çark\r\n~2)bileği çarkı, bileği\r\n~3)döngü, loop\r\n~çerxê projeyî (n): proje döngüsü"
  },
  {
    "x": "çerx kerdene",
    "y": " 1)döndürmek 2)bilemek"
  },
  {
    "x": "çerx sanayene",
    "y": " b. çerx şanayene"
  },
  {
    "x": "çerx sanîyayene",
    "y": " b. çerx şanîyayene"
  },
  {
    "x": "çerx şanayene",
    "y": " bilemek"
  },
  {
    "x": "çerx şanîyayene",
    "y": " bilenmek"
  },
  {
    "x": "çerxê projeyî (n)",
    "y": " proje döngüsü"
  },
  {
    "x": "çerxî ro dayene",
    "y": " bilemek"
  },
  {
    "x": "çerxî ro dîyayene",
    "y": " bilenmek"
  },
  {
    "x": "çerxî ro ginayene",
    "y": " bilenmek"
  },
  {
    "x": "çerx-II (n)",
    "y": " banyo"
  },
  {
    "x": "çerxe (m)",
    "y": " fötr (fötr şapka)"
  },
  {
    "x": "çerxker, -e",
    "y": " çarkçı"
  },
  {
    "x": "çerxkerdiş (n)",
    "y": " döndürme "
  },
  {
    "x": "çerxnayîne",
    "y": " b. çerixnayene"
  },
  {
    "x": "çerxnayîş (n)",
    "y": " b. çerixnayîş (n)"
  },
  {
    "x": "çes (n)",
    "y": " b. kês-I (n)"
  },
  {
    "x": "çeşîd (n)",
    "y": " b. çeşît (n)"
  },
  {
    "x": "çeşît (n)",
    "y": " çeşit"
  },
  {
    "x": "çeşît-çeşît (s)",
    "y": " çeşitli"
  },
  {
    "x": "çeşm (n)",
    "y": " b. çim (n)"
  },
  {
    "x": "çet",
    "y": " b. çit"
  },
  {
    "x": "çet çet",
    "y": " b. çit"
  },
  {
    "x": "çetal (n)",
    "y": " çatal (yol, ağaç vb. için)"
  },
  {
    "x": "çetalê çar rayîran (n)",
    "y": " b. çarçat (n)"
  },
  {
    "x": "çetalê rayîran (n)",
    "y": " b. çatrayîr (n)"
  },
  {
    "x": "çetalê rayîron (n)",
    "y": " b. çatrayîr (n)"
  },
  {
    "x": "çetale (m)",
    "y": " çatal"
  },
  {
    "x": "çetali (m)",
    "y": " b. çetale (m)"
  },
  {
    "x": "çetalin, -e",
    "y": " çatallı"
  },
  {
    "x": "çetalin bîyene",
    "y": " çatallanmak (çatal gibi ikiye ayrılmak)"
  },
  {
    "x": "çetalinbîyayîş (n)",
    "y": " çatallanma"
  },
  {
    "x": "çete (n)",
    "y": " çete "
  },
  {
    "x": "çetele (m)",
    "y": " b. çetale (m)"
  },
  {
    "x": "çeteli (m)",
    "y": " b. çetale (m)"
  },
  {
    "x": "çetê çemî",
    "y": " b. çatê çemî"
  },
  {
    "x": "çetê royî",
    "y": " b. çatê royî"
  },
  {
    "x": "çetin, -e",
    "y": " güç (s), çetin (s), müşkül (s)"
  },
  {
    "x": "çever (n)",
    "y": " b. keyber (n)"
  },
  {
    "x": "çevesaye (n)",
    "y": " b. keyveşaye (n)"
  },
  {
    "x": "çew",
    "y": " 1)hiç kimse\r\n~2)b. yew-2\r\n~Çewî nêva. (Hiç kimse söylemedi.)"
  },
  {
    "x": "çewder (n)",
    "y": " çavdar"
  },
  {
    "x": "çewdere (m)",
    "y": " b. çewder (n)"
  },
  {
    "x": "çewderin, -e",
    "y": " çavdarlı"
  },
  {
    "x": "çewek",
    "y": " hiç kimse"
  },
  {
    "x": "çewer-I",
    "y": " hiç kimse"
  },
  {
    "x": "çewer-II (n)",
    "y": " b. keyber (n) "
  },
  {
    "x": "çewlig (n)",
    "y": " b. çewlîg (n)"
  },
  {
    "x": "çewlîg (n)",
    "y": " 1)çiftlik\r\n~2)akarsu kenarındaki verimli arazi"
  },
  {
    "x": "Çewlîg (n)",
    "y": " Bingöl"
  },
  {
    "x": "çewr-I (n)",
    "y": " 1)içyağı\r\n~2)kesici araçları bilemek için kullanılan yağ"
  },
  {
    "x": "çewr-II (n)",
    "y": " b. tuwerzîn (n), tore (n) \r\n~birîna çewrî (m): balta yarası"
  },
  {
    "x": "birîna çewrî (m)",
    "y": " balta yarası"
  },
  {
    "x": "çewres",
    "y": " kırk"
  },
  {
    "x": "çewresin, -e",
    "y": " kırkıncı "
  },
  {
    "x": "çewreş",
    "y": " b. çewres"
  },
  {
    "x": "çewrês",
    "y": " b. çewres"
  },
  {
    "x": "çewris",
    "y": " b. çewres"
  },
  {
    "x": "çewt, -e",
    "y": " eğri"
  },
  {
    "x": "çewt kerdene",
    "y": " çarpıtmak"
  },
  {
    "x": "çewt û çewtvir, -e",
    "y": " eğri büğrü, çarpık çurpuk, çarpuk çurpuk"
  },
  {
    "x": "çewt û çur, -e",
    "y": " b. çewt û çewtvir, -e"
  },
  {
    "x": "çewtey (m)",
    "y": " b. çewtîye (m)"
  },
  {
    "x": "çewtê (m)",
    "y": " b. çewtî (m)"
  },
  {
    "x": "çewtî (m)",
    "y": " eğrilik"
  },
  {
    "x": "çewtîye (m)",
    "y": " eğrilik"
  },
  {
    "x": "çewtkerdiş (n)",
    "y": " çarpıtma "
  },
  {
    "x": "çewtvir, -e",
    "y": " çarpık, çalık "
  },
  {
    "x": "çewtvirîye (m)",
    "y": " çarpıklık"
  },
  {
    "x": "çey-I (m)",
    "y": " b. çay (m)"
  },
  {
    "x": "çey-II",
    "y": " b. yê-VI (n, m, zh)\r\n~Yowki zî çey min a. (Bir tane de benimdir.)"
  },
  {
    "x": "çeye (n)",
    "y": " b. keye-I (n)"
  },
  {
    "x": "çeyk",
    "y": " b. çîyekî"
  },
  {
    "x": "çeykî",
    "y": " b. çîyekî"
  },
  {
    "x": "çeyle (n)",
    "y": " çêle-II (n)"
  },
  {
    "x": "çeynci (m)",
    "y": " b. teyînce (m)"
  },
  {
    "x": "çeyr",
    "y": " b. çar-I"
  },
  {
    "x": "çeyreg (n)",
    "y": " b. çarêk (n)"
  },
  {
    "x": "çeyregi (m)",
    "y": " b. çarêke (m)"
  },
  {
    "x": "çeyrek-I (n)",
    "y": " b. çarêk (n)"
  },
  {
    "x": "çeyrek-II (m)",
    "y": " b. çarêke (m)"
  },
  {
    "x": "çeyver (n)",
    "y": " b. teber (n)"
  },
  {
    "x": "çeyxûne (n)",
    "y": " b. çayxane (n)"
  },
  {
    "x": "çê-I(n)",
    "y": " b. keye-I (n)\r\n~Çê to şên bo: b. Keyeyê to şên bo."
  },
  {
    "x": "Çê to şên bo",
    "y": " b. Keyeyê to şên bo."
  },
  {
    "x": "çê-II",
    "y": " b. çi-II"
  },
  {
    "x": "çêb",
    "y": " b. çew"
  },
  {
    "x": "çêber (n)",
    "y": " b. keyber (n) "
  },
  {
    "x": "çêf (n)",
    "y": " b. keyf (n)"
  },
  {
    "x": "çêf kerdene",
    "y": " b. keyf kerdene"
  },
  {
    "x": "çêfê ... amayene",
    "y": " b. keyfê ... ameyene"
  },
  {
    "x": "çêfê ... amayêne",
    "y": " b. keyfê ... ameyene"
  },
  {
    "x": "çêfê ... ardene",
    "y": " b. keyfê ... ardene"
  },
  {
    "x": "çêfin, -e",
    "y": " b. keyfin, -e"
  },
  {
    "x": "çêflî, -ye",
    "y": " b. keyflî, -ye"
  },
  {
    "x": "çêker, -e",
    "y": " b. keyeker, -e"
  },
  {
    "x": "çêl û çuk",
    "y": " b. çêl û çûk"
  },
  {
    "x": "çêl û çûk",
    "y": " çoluk çocuk"
  },
  {
    "x": "çêl-çuk",
    "y": " b. çêl û çûk"
  },
  {
    "x": "çêle-I (n)",
    "y": " solucan, yersolucanı"
  },
  {
    "x": "çêle-II (n)",
    "y": " köz"
  },
  {
    "x": "çêle, -ye -IV",
    "y": " b. çêlik, -e"
  },
  {
    "x": "çêle ardene",
    "y": " b. çêlik ardene, b. lîçik ardene "
  },
  {
    "x": "çêle vetene",
    "y": " b. çêlik vetene, b. lîçik vetene"
  },
  {
    "x": "çêlek, -e",
    "y": " b. çêlik, -e"
  },
  {
    "x": "Çêleke (m)",
    "y": " inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "çêlemtû (m)",
    "y": " b. kelendire (m)"
  },
  {
    "x": "çêlê",
    "y": " gelmesi için ineğe seslenirken kullanılan bir sözcük "
  },
  {
    "x": "çêlik, -e",
    "y": " civciv, yavru (hayvan için)"
  },
  {
    "x": "çêlik ardene",
    "y": " yavrulamak (doğuran bazı hayvanlar için)"
  },
  {
    "x": "çêlik vetene",
    "y": " yavrulamak (yavrusu yumurtadan çıkan hayvanlar için)"
  },
  {
    "x": "çêlikê kutikî (n)",
    "y": " enik, encik, encek, köpek yavrusu"
  },
  {
    "x": "Çêlikan",
    "y": " Çelikhan"
  },
  {
    "x": "çêm (n)",
    "y": " b. çem-I (n)"
  },
  {
    "x": "çêna (m)",
    "y": " b. kêna (m)"
  },
  {
    "x": "çêna amike (m)",
    "y": " b. kêna emike (m)"
  },
  {
    "x": "çêna apî (m)",
    "y": " b. kêna apî (m)"
  },
  {
    "x": "çêna torne (m)",
    "y": " b. kêna torne (m) "
  },
  {
    "x": "çêna tornî (m)",
    "y": " b. kêna tornî (m) "
  },
  {
    "x": "çêna xale (m)",
    "y": " b. kêna xale (m) "
  },
  {
    "x": "çêna xalike (m)",
    "y": " b. kêna xale (m)"
  },
  {
    "x": "çêna xalî (m)",
    "y": " b. kêna xalî (m)"
  },
  {
    "x": "çêne (m)",
    "y": " b. kêna (m)"
  },
  {
    "x": "çêneke (m)",
    "y": " b. kêneke (m)"
  },
  {
    "x": "çêneki (m)",
    "y": " b. kêneke (m) "
  },
  {
    "x": "çêr, -e",
    "y": " 1)yiğit, alp, kahraman\r\n~2)cesaretli, cesur, yürekli"
  },
  {
    "x": "çêr bîyene",
    "y": " cesaretlenmek"
  },
  {
    "x": "çêran (n)",
    "y": " b. kêran (n)"
  },
  {
    "x": "çêrang (n)",
    "y": " kêran (n)"
  },
  {
    "x": "çêrbîyaye (n)",
    "y": " b. çîrbîyaye (n)"
  },
  {
    "x": "çêrbîyayene",
    "y": " b. çîrbîyene"
  },
  {
    "x": "çêrbîyayîs (n)",
    "y": " b. çîrbîyayîş (n)"
  },
  {
    "x": "çêrbîyayîş-I (n)",
    "y": " cesaretlenme"
  },
  {
    "x": "çêrbîyayîş-II (n)",
    "y": " b. çîrbîyayîş (n)"
  },
  {
    "x": "çêrbîyene",
    "y": " b. çîrbîyene"
  },
  {
    "x": "çêreg-I (n)",
    "y": " b. çarêk (n)"
  },
  {
    "x": "çêreg-II (m)",
    "y": " b. çarêke (m)"
  },
  {
    "x": "çêrege (m)",
    "y": " b. çarêke (m)"
  },
  {
    "x": "çêregi (m)",
    "y": " b. çarêke (m)"
  },
  {
    "x": "çêrek-I (n)",
    "y": " b. çarêk (n)"
  },
  {
    "x": "çêrek-II (m)",
    "y": " b. çarêke (m)"
  },
  {
    "x": "çêreke (m)",
    "y": " b. çarêke (m)"
  },
  {
    "x": "çêrênî (m)",
    "y": " yiğitlik, alplık, kahramanlık"
  },
  {
    "x": "çêrî (m)",
    "y": " 1)yiğitlik, alplık, kahramanlık\r\n~2)cesaretlilik, cesurluk, yüreklilik"
  },
  {
    "x": "çêrîye (m)",
    "y": " 1)yiğitlik, alplık, kahramanlık\r\n~2)cesaretlilik, cesurluk, yüreklilik"
  },
  {
    "x": "çêrkerde (n)",
    "y": " b. çîrkerde"
  },
  {
    "x": "çêrkerdene",
    "y": " b. çîrkerdene"
  },
  {
    "x": "çêrkerdis (n)",
    "y": " b. çîrkerdiş (n)"
  },
  {
    "x": "çêrme (n)",
    "y": " b. çerme (n)"
  },
  {
    "x": "Çêrme",
    "y": " Yedisu"
  },
  {
    "x": "Çêrmûge (m)",
    "y": " Çermik"
  },
  {
    "x": "çêrî (m)",
    "y": " gözü peklik"
  },
  {
    "x": "çêrîye (m)",
    "y": " gözü peklik"
  },
  {
    "x": "çês (n)",
    "y": " b. kês-I (n)"
  },
  {
    "x": "çêsik (n)",
    "y": " b. kîse (n), kîsik (n)"
  },
  {
    "x": "çêşît (n)",
    "y": " b. çeşît (n)"
  },
  {
    "x": "çêver (n)",
    "y": " b. keyber (n) "
  },
  {
    "x": "çêvesaye (n)",
    "y": " b. keyveşaye (n) "
  },
  {
    "x": "çêw",
    "y": " b. çew"
  },
  {
    "x": "çêwa, -ye",
    "y": " b. keya, -ye"
  },
  {
    "x": "çêwayîye (m)",
    "y": " b. keyayîye (m)"
  },
  {
    "x": "çêwer (n)",
    "y": " b. keyber (n) "
  },
  {
    "x": "çêwlig (n)",
    "y": " b. çewlîg (n)"
  },
  {
    "x": "çêyker, -e",
    "y": " b. keyeker, -e"
  },
  {
    "x": "çi-I",
    "y": " ne\r\n~Çi yo? (Nedir?)\r\n~çi ame fek vatene: ağzına geleni söylemek\r\n~çi ame verê fekî vatene: ağzına geleni söylemek\r\n~çi çax: ne zaman, ne vakit\r\n~çi dem: ne zaman, ne vakit\r\n~çi ke bîy: hepsi, tümü\r\n~çi ke est ê: hepsi, tümü"
  },
  {
    "x": "çi ame fek vatene",
    "y": " ağzına geleni söylemek"
  },
  {
    "x": "çi ame verê fekî vatene",
    "y": " ağzına geleni söylemek"
  },
  {
    "x": "çi çax",
    "y": " ne zaman, ne vakit"
  },
  {
    "x": "çi dem",
    "y": " ne zaman, ne vakit"
  },
  {
    "x": "çi k’ bî",
    "y": " b. çi ke bîy"
  },
  {
    "x": "çi ke bîy",
    "y": " hepsi, tümü"
  },
  {
    "x": "çi ke est ê",
    "y": " hepsi, tümü"
  },
  {
    "x": "çi ki bî",
    "y": " b. çi ke bîy"
  },
  {
    "x": "çi ki est ê",
    "y": " b. çi ke est ê"
  },
  {
    "x": "çi mehal",
    "y": " ne zaman, ne vakit"
  },
  {
    "x": "çi mehel",
    "y": " b. çi mehal"
  },
  {
    "x": "çi mehlan",
    "y": " b. çi mehal"
  },
  {
    "x": "çi mhal",
    "y": " b. çi mehal"
  },
  {
    "x": "çi taw",
    "y": " ne zaman, ne vakit"
  },
  {
    "x": "çi waxt",
    "y": " b. çi wext"
  },
  {
    "x": "çi weqt",
    "y": " b. çi wext"
  },
  {
    "x": "çi wext",
    "y": " ne zaman, ne vakit\r\n~Çi wext ame? (Ne zaman geldi?)"
  },
  {
    "x": "çi zeman",
    "y": " ne zaman, ne vakit"
  },
  {
    "x": "çi-II",
    "y": " hiç, hiçbir, tek\r\n~çi rey: asla, hiçbir zaman; hiç, katiyen"
  },
  {
    "x": "çi kes",
    "y": " hiç kimse"
  },
  {
    "x": "çi ra",
    "y": " b. çi rey"
  },
  {
    "x": "çi ray",
    "y": " b. çi rey"
  },
  {
    "x": "çi rey",
    "y": " asla, hiçbir zaman; hiç, katiyen"
  },
  {
    "x": "çi rê",
    "y": " b. çi rey"
  },
  {
    "x": "çi rêy",
    "y": " b. çi rey"
  },
  {
    "x": "çi wext",
    "y": " asla, hiçbir zaman, hiç, katiyen"
  },
  {
    "x": "çi ... çi ...",
    "y": " ... olsun ... olsun \r\n~Çi cinî çi camêrd, heme amey. (Kadın olsun, erkek olsun, hepsi geldiler.)"
  },
  {
    "x": "çiba (n)",
    "y": " b. teba (n)"
  },
  {
    "x": "çibenok (n)",
    "y": " bilmece"
  },
  {
    "x": "çice (n)",
    "y": " b. çije (n)"
  },
  {
    "x": "çicey xazmikî (zh)",
    "y": " b. çijeyê xazmikî (zh)"
  },
  {
    "x": "çicik",
    "y": " b. çiçik (n)"
  },
  {
    "x": "çiç (n)",
    "y": " b. çiçik (n)"
  },
  {
    "x": "çiçi",
    "y": " b. çi-I"
  },
  {
    "x": "çiçik (n)",
    "y": " 1)meme\r\n~2)yuvağı çekmek için kullanılan çatallı aracın yuvağın girintili yerine geçen ucu"
  },
  {
    "x": "çiçî",
    "y": " b. çi-I"
  },
  {
    "x": "çiçîrê",
    "y": " b. çirê "
  },
  {
    "x": "çifte (n)",
    "y": " bir tüfek çeşidi"
  },
  {
    "x": "çiftexas (n)",
    "y": " beyaz renkli bez"
  },
  {
    "x": "çiftike (m)",
    "y": " fiske"
  },
  {
    "x": "çiftike estene ra ci",
    "y": " b. çiftike eştene ra ci"
  },
  {
    "x": "çiftike eştene ra ci",
    "y": " fiskelemek, fiske vurmak"
  },
  {
    "x": "çiftikeesteneraci (n)",
    "y": " b. çiftikeeşteneraci (n)"
  },
  {
    "x": "çiftikeeşteneraci (n)",
    "y": " fiskeleme"
  },
  {
    "x": "çiftixas (n)",
    "y": " b. çiftexas (n)"
  },
  {
    "x": "çig-I",
    "y": " b. çik-I"
  },
  {
    "x": "çig-II",
    "y": " b. çunke"
  },
  {
    "x": "çigi",
    "y": " b. çunke"
  },
  {
    "x": "çihar",
    "y": " b. çar-I"
  },
  {
    "x": "çiharin, -i",
    "y": " b. çarin, -e"
  },
  {
    "x": "çiharpay şîyayene",
    "y": " b. çarpay şîyene"
  },
  {
    "x": "çiharpay şîyene",
    "y": " b. çarpay şîyene"
  },
  {
    "x": "çihend",
    "y": " b. çend"
  },
  {
    "x": "çiher",
    "y": " b. çar-I"
  },
  {
    "x": "çiherin, -i",
    "y": " b. çarin, -e"
  },
  {
    "x": "çihind",
    "y": " b. çend"
  },
  {
    "x": "çije (n)",
    "y": " meme"
  },
  {
    "x": "çijeyê xazmikî (zh)",
    "y": " bademcik (bademcikler)"
  },
  {
    "x": "çiji (n)",
    "y": " b. çije (n)"
  },
  {
    "x": "çik-I",
    "y": " ne\r\n~Çik o? (Nedir?)"
  },
  {
    "x": "çik-II",
    "y": " b. çîyekî"
  },
  {
    "x": "çikal (n)",
    "y": " b. çekal (n)"
  },
  {
    "x": "çike",
    "y": " b. çunke "
  },
  {
    "x": "çikel (n)",
    "y": " b. çekal (n)"
  },
  {
    "x": "çikes, -e",
    "y": " kim"
  },
  {
    "x": "çikê",
    "y": " b. çunke"
  },
  {
    "x": "çiki",
    "y": " b. çunke"
  },
  {
    "x": "çikî",
    "y": " b. çîyekî"
  },
  {
    "x": "çiko",
    "y": " b. çunke"
  },
  {
    "x": "çikole (n)",
    "y": " b. çekule (n)"
  },
  {
    "x": "çakule (n)",
    "y": " b. çekule (n)"
  },
  {
    "x": "çakule bestene",
    "y": " b. çekule bestene"
  },
  {
    "x": "çikule (n)",
    "y": " b. çekule (n)"
  },
  {
    "x": "çikule bestene",
    "y": " b. çekule bestene"
  },
  {
    "x": "çikuele (n)",
    "y": " b. çekule (n)"
  },
  {
    "x": "çikuele bestene",
    "y": " b. çekule bestene"
  },
  {
    "x": "çikur (n)",
    "y": " b. çekur (n)"
  },
  {
    "x": "çil (n)",
    "y": " sümük"
  },
  {
    "x": "çila (m)",
    "y": " 1)çıra\r\n~2)lamba\r\n~çilaya gazyaxî (m): gaz lambası\r\n~çilaya keske (m): yeşil lamba\r\n~3)gaz lambası\r\n~4)çıralık"
  },
  {
    "x": "çilaya gazyaxî (m)",
    "y": " gaz lambası"
  },
  {
    "x": "çilaya keske (m)",
    "y": " yeşil lamba"
  },
  {
    "x": "çilaya sûre (m)",
    "y": " kırmızı lamba"
  },
  {
    "x": "çilaya trafîkî (m)",
    "y": " trafik lambası"
  },
  {
    "x": "çilaya zerde (m)",
    "y": " sarı lamba"
  },
  {
    "x": "çiladane (m)",
    "y": " çıralık, çıranın veya gaz lambasının konduğu yer"
  },
  {
    "x": "çilag, -i",
    "y": " b. çulag, -e"
  },
  {
    "x": "çilagey (m)",
    "y": " b. çulagîye (m)"
  },
  {
    "x": "çilagê (m)",
    "y": " b. çulagî (m)"
  },
  {
    "x": "çilagî (m)",
    "y": " b. çulagî (m)"
  },
  {
    "x": "çilanciki (m)",
    "y": " b. çiraneke (m)"
  },
  {
    "x": "çilane (m)",
    "y": " sümük"
  },
  {
    "x": "çilani (m)",
    "y": " b. çilane (m)"
  },
  {
    "x": "çilanin, -e",
    "y": " sümüklü"
  },
  {
    "x": "çilanti (m)",
    "y": " b. çilane (m)"
  },
  {
    "x": "çilantin, -e",
    "y": " b. çilanin, -e"
  },
  {
    "x": "çilbeyte (m)",
    "y": " b. çulbeyte (m)"
  },
  {
    "x": "çildane (m)",
    "y": " atlama (uzun atlama)"
  },
  {
    "x": "çildayîş (n)",
    "y": " b. çingdayîş (n)"
  },
  {
    "x": "çile (n)",
    "y": " b. çele (n)"
  },
  {
    "x": "çiledank (n)",
    "y": " b. çiladane (m)"
  },
  {
    "x": "çilek, -e",
    "y": " ekti (canı her yiyeceği çeken)"
  },
  {
    "x": "çiley (m)",
    "y": " b. çila (m)"
  },
  {
    "x": "çili (n)",
    "y": " b. çele (n)"
  },
  {
    "x": "çilike (m)",
    "y": " b. çilke (m)"
  },
  {
    "x": "çilk (m)",
    "y": " b. çilke (m)"
  },
  {
    "x": "çilke (m)",
    "y": " damla"
  },
  {
    "x": "çilki (m)",
    "y": " b. çilke (m)"
  },
  {
    "x": "çilm (n)",
    "y": " b. çilme (m)"
  },
  {
    "x": "çilmê vaşî (n)",
    "y": " bitkilerin salgıladığı kaygan bir madde, salgı (bitki salgısı)"
  },
  {
    "x": "çilme (m)",
    "y": " sümük"
  },
  {
    "x": "çilmi (m)",
    "y": " b. çilme (m)"
  },
  {
    "x": "çilmik (n)",
    "y": " bitkilerin salgıladığı kaygan bir madde, salgı (bitki salgısı)"
  },
  {
    "x": "çilmin, -e",
    "y": " sümüklü"
  },
  {
    "x": "çilminok, -e",
    "y": " sümüklü"
  },
  {
    "x": "çilmî (m)",
    "y": " b. çilme (m)"
  },
  {
    "x": "çilmkin, -e",
    "y": " b. çilmin, -e"
  },
  {
    "x": "çilo (n)",
    "y": " sümüklü"
  },
  {
    "x": "çilonç (m)",
    "y": " b. çilane (m)"
  },
  {
    "x": "çilonçin, -e",
    "y": " b. çilanin, -e"
  },
  {
    "x": "çiloni (m)",
    "y": " b. çilane (m)"
  },
  {
    "x": "çilonin, -e",
    "y": " b. çilanin, -e"
  },
  {
    "x": "çilonti (m)",
    "y": " b. çilane (m)"
  },
  {
    "x": "çilontin, -e",
    "y": " b. çilanin, -e"
  },
  {
    "x": "çilontî (m)",
    "y": " b. çilane (m)"
  },
  {
    "x": "çilpi (n)",
    "y": " b. çirpî (m)"
  },
  {
    "x": "çilpike (m)",
    "y": " b. çirpike (m)"
  },
  {
    "x": "çilpike piro dayene",
    "y": " b. çirpike piro dayene"
  },
  {
    "x": "çilpikepirodayîş (n)",
    "y": " b. çirpikepirodayîş (n)"
  },
  {
    "x": "çilpiki (m)",
    "y": " b. çirpike (m)"
  },
  {
    "x": "çilpiki panayîne",
    "y": " b. çirpike panayene"
  },
  {
    "x": "çilpiki piro dayene",
    "y": " b. çîpike piro dayene"
  },
  {
    "x": "çilpikipanayîş (n)",
    "y": " b. çirpikepanayîş (n)"
  },
  {
    "x": "çilpikipirodayîş (n)",
    "y": " b. çîpikepirodayîş (n)"
  },
  {
    "x": "çilpî (m)",
    "y": " b. çirpî (m)"
  },
  {
    "x": "çilq-bilqe (m)",
    "y": " dama, dokuztaş vb. oyunlarda çok yönlü olumlu (rakip için olumsuz) oyun şansı elde etme durumu"
  },
  {
    "x": "çilq-bilqi (m)",
    "y": " b. çilq-bilqe (m"
  },
  {
    "x": "çilqnayene",
    "y": " b. çelqnayene"
  },
  {
    "x": "çilqnayîş (n)",
    "y": " b. çelqnayîş (n)"
  },
  {
    "x": "çilqûbere (m)",
    "y": " b. çeqûbere (m)"
  },
  {
    "x": "çilqûberi (m)",
    "y": " b. çeqûbere (m)"
  },
  {
    "x": "çilûni (m)",
    "y": " b. çilane (m)"
  },
  {
    "x": "çilûnin, -e",
    "y": " b. çilanin, -e"
  },
  {
    "x": "çilxazikî (zh)",
    "y": " b. çirxazî (zh)"
  },
  {
    "x": "çilxazikî vetene",
    "y": " b. çirxazî vetene"
  },
  {
    "x": "çilxazî (zh)",
    "y": " b. çirxazî (zh)"
  },
  {
    "x": "çilxazî vetene",
    "y": " b. çirxazî vetene"
  },
  {
    "x": "çim (n)",
    "y": " 1)göz\r\n~Çimê mi erzeno. : Gözüm seğiriyor.\r\n~\r\n~2)nazar\r\n~çim kerdene: nazar etmek\r\n~çim piro ginayene: nazar değmek, göz değmek, nazara gelmek"
  },
  {
    "x": "çim bar nêbîyene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "çim bar nêdayene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "çim birnayene",
    "y": " gözü kesmek"
  },
  {
    "x": "çim ci estene",
    "y": " b. çim ci eştene"
  },
  {
    "x": "çim ci eştene",
    "y": " göz atmak"
  },
  {
    "x": "çim ci ra fetilnayene",
    "y": " göz gezdirmek"
  },
  {
    "x": "... çim de",
    "y": " -e göre (-nin gözünde)"
  },
  {
    "x": "çim nêakerdene",
    "y": " ağır hasta olmak"
  },
  {
    "x": "çim ... rabirnayîş",
    "y": " gözü kesme"
  },
  {
    "x": "çim ser ra fetilnayene",
    "y": " göz gezdirmek\r\n~Mi ke çim ser ra fetilna, mi îmza ey/aye dîye. (Mektbuba göz gezdirince imzasını gördüm.)"
  },
  {
    "x": "çimê ... bar nêbîyene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "çimê ... bar nêdayene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "çimê ... de",
    "y": " -e göre (-nin gözünde), bakımından\r\n~Çimê wendekaran de mamosta heme çî zano. (Öğrencilere göre öğretmen her şeyi bilir.)"
  },
  {
    "x": "çimê mi de",
    "y": " bence, benim gözümde, bana göre"
  },
  {
    "x": "çimê otobuse (n)",
    "y": " far "
  },
  {
    "x": "çimê otomobîle (n)",
    "y": " far"
  },
  {
    "x": "çimê ... ... ra birnayene",
    "y": " gözü kesmek"
  },
  {
    "x": "çimê taksî (n)",
    "y": " far"
  },
  {
    "x": "çimê ... teng bîyene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "çimê ... teng kerdene",
    "y": " kıskandırmak"
  },
  {
    "x": "çimî çarnayene",
    "y": " göz gezdirmek"
  },
  {
    "x": "çimî lêlawe kerdene",
    "y": " gözleri sulanmak (hastalıktan dolayı), gözleri yaşarmak (hastalıktan dolayı)\r\n~Çimê mi lêlawe kenê. (Gözlerim sulanıyor.)"
  },
  {
    "x": "çimî lîlawi kerdene",
    "y": " b. çimî lêlawe kerdene"
  },
  {
    "x": "çimî nêakerdene",
    "y": " ağır hasta olmak"
  },
  {
    "x": "çimî tarî bîyayene",
    "y": " b. çimî tarî bîyene"
  },
  {
    "x": "çimî tarî bîyene",
    "y": " gözleri kararmak"
  },
  {
    "x": "çimî vilikî kerdene",
    "y": " gözlerinin önünde sinek uçuşmak\r\n~Çimê to vilikî kenê. (Senin gözlerinin önünde sinekler uçuşuyor.)\r\n~"
  },
  {
    "x": "çim kerdene",
    "y": " nazar etmek"
  },
  {
    "x": "çim piro ginayene",
    "y": " nazar değmek, göz değmek, nazara gelmek"
  },
  {
    "x": "çimbarnêbîyaya (m)",
    "y": " kıskanç, günücü, hasetçi (başkasını çekemeyen)"
  },
  {
    "x": "çimbarnêbîyaye (n)",
    "y": " kıskanç, günücü, hasetçi (başkasını çekemeyen)"
  },
  {
    "x": "çimbarnêbîyayeyênî (m)",
    "y": " çekememezlik, kıskançlık, günü, günücülük, hasetlik (çekemezlik)"
  },
  {
    "x": "çimbarnêbîyayeyî (m)",
    "y": " çekememezlik, kıskançlık, günü, günücülük, hasetlik (çekemezlik)"
  },
  {
    "x": "çimbarnêbîyayeyîye (m)",
    "y": " çekememezlik, kıskançlık, günü, günücülük, hasetlik (çekemezlik)"
  },
  {
    "x": "çimbarnêbîyayê (m)",
    "y": " b. çimbarnêbîyayîye (m)"
  },
  {
    "x": "çimbarnêbîyayi (n)",
    "y": " b. çimbarnêbîyaye (n)"
  },
  {
    "x": "çimbarnêbîyayîye (m)",
    "y": " kıskanç, günücü, hasetçi (başkasını çekemeyen)"
  },
  {
    "x": "çimbesteki (m)",
    "y": " b. çimbestike (m)"
  },
  {
    "x": "çimbestik (n)",
    "y": " b. çimbestike (m)"
  },
  {
    "x": "çimbestike (m)",
    "y": " körebe"
  },
  {
    "x": "çimbirnayîş (n)",
    "y": " gözü kesme"
  },
  {
    "x": "çimciestiş (n)",
    "y": " b. çimcieştiş (n)"
  },
  {
    "x": "çimcieştiş (n)",
    "y": " göz atma"
  },
  {
    "x": "çimcirafetilnayîş (n)",
    "y": " göz gezdirme"
  },
  {
    "x": "çimçêrî (m)",
    "y": " tahterevalli, çöğüncek"
  },
  {
    "x": "çimçilik (n)",
    "y": " b. çemçîlik (n)"
  },
  {
    "x": "çimdejan (n)",
    "y": " göz iltihaplanması"
  },
  {
    "x": "çime (n)",
    "y": " 1)pınar; göze, akarsuyun çıktığı yer\r\n~2)kaynak, memba\r\n~çimeyê enerjî yê newebarî (zh): yenilenebilir enerji kaynakları\r\n~çimeyo xozayî (n): doğal kaynak\r\n~3)b. çim (n)"
  },
  {
    "x": "çime kerdene",
    "y": " b. çim kerdene"
  },
  {
    "x": "çime piro ginayene",
    "y": " b. çim piro ginayene"
  },
  {
    "x": "çimeyê enerjî (zh)",
    "y": " enerji kaynakları"
  },
  {
    "x": "çimeyê enerjî yê newebarî (zh)",
    "y": " yenilenebilir enerji kaynakları"
  },
  {
    "x": "çimeyê huqûqî (n)",
    "y": " hukukun kaynağı"
  },
  {
    "x": "çimeyê kitabî",
    "y": " kitabın kaynakları"
  },
  {
    "x": "çimeyê melumatî (zh)",
    "y": " bilgi kaynakları"
  },
  {
    "x": "çimeyê merdiman",
    "y": " insan kaynakları"
  },
  {
    "x": "çimeyê teberî (n)",
    "y": " dış kaynak"
  },
  {
    "x": "çimeyê zanayîşî (zh)",
    "y": " bilgi kaynakları"
  },
  {
    "x": "çimeyî (zh)",
    "y": " kaynaklar"
  },
  {
    "x": "çimeyo tebîî (n)",
    "y": " doğal kaynak"
  },
  {
    "x": "çimeyo xozayî (n)",
    "y": " doğal kaynak"
  },
  {
    "x": "çimedejan (n)",
    "y": " b. çimdejan (n)"
  },
  {
    "x": "çimedezan (n)",
    "y": " b. çimdejan (n)"
  },
  {
    "x": "çimek (n)",
    "y": " 1)gözenek\r\n~2)çekmece, çekme\r\n~çimekê masa (n): masa çekmecesi"
  },
  {
    "x": "çimekê masa (n)",
    "y": " masa çekmecesi"
  },
  {
    "x": "çimekin, -e",
    "y": " çekmeceli"
  },
  {
    "x": "çimename (n)",
    "y": " bibliyografi, kaynakça"
  },
  {
    "x": "çimenameyî, -ye",
    "y": " bibliyografik"
  },
  {
    "x": "çimenas, -e",
    "y": " bibliyograf"
  },
  {
    "x": "çimesîya (n)",
    "y": " kıskanç, günücü, hasetçi, başkasını çekemeyen"
  },
  {
    "x": "çimesîyaye (m)",
    "y": " kıskanç, günücü, hasetçi, başkasını çekemeyen"
  },
  {
    "x": "çimesîyayênî kerdene",
    "y": " 1)kıskanmak\r\n~2)esirgemek (vermekten veya yapmaktan kaçınmak) "
  },
  {
    "x": "çimesîyayênîkerdiş (n)",
    "y": " esirgeme (vermekten veya yapmaktan kaçınma)"
  },
  {
    "x": "çimesîyayî (m)",
    "y": " kıskançlık, günü, günücülük, hasetlik, çekemezlik, çekememezlik"
  },
  {
    "x": "çimesîyayî kerdene",
    "y": " kıskanmak, günülemek, haset etmek, çekememek"
  },
  {
    "x": "çimesîyayîye (m)",
    "y": " kıskançlık, günü, günücülük, hasetlik (çekemezlik), çekememezlik"
  },
  {
    "x": "çimesîyayîye kerdene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "çimeşayênî kerdene",
    "y": " b. çimesîyayênî kerdene"
  },
  {
    "x": "çimeşayênîkerdiş (n)",
    "y": " çimesîyayênîkerdiş (n)"
  },
  {
    "x": "çimeteng, -e",
    "y": " kıskanç, günücü, hasetçi (başkasını çekemeyen)"
  },
  {
    "x": "çimetengênî (m)",
    "y": " kıskançlık, günü, günücülük, hasetlik (çekemezlik), çekememezlik"
  },
  {
    "x": "çimetengênî kerdene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "çimetengî (m)",
    "y": " kıskançlık, günü, günücülük, hasetlik (çekemezlik),çekememezlik"
  },
  {
    "x": "çimetengî kerdene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "çimetengîye (m)",
    "y": " kıskançlık, günü, günücülük, hasetlik (çekemezlik), çekememezlik"
  },
  {
    "x": "çimetengîye kerdene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "çimeyin, -e",
    "y": " çekmeceli"
  },
  {
    "x": "çimênî (m)",
    "y": " nazar"
  },
  {
    "x": "çimênî bîyene",
    "y": " nazar değmek"
  },
  {
    "x": "çimênî kerdene",
    "y": " nazar etmek"
  },
  {
    "x": "çimig",
    "y": " b. çunke"
  },
  {
    "x": "çimik-I(n)",
    "y": " ayakkabının bağ geçirilen deliği"
  },
  {
    "x": "çimik-II (n)",
    "y": " çiftparmaklı hayvanın parmağı, küçükbaş hayvanın parmağı"
  },
  {
    "x": "çimîçarnayîş (n)",
    "y": " göz gezdirme"
  },
  {
    "x": "çimka",
    "y": " b. çunke"
  },
  {
    "x": "çimke",
    "y": " b. çunke"
  },
  {
    "x": "çimkî",
    "y": " b. çunke"
  },
  {
    "x": "çimmird, -e",
    "y": " tokgözlü"
  },
  {
    "x": "çimmirdey (m)",
    "y": " b. çimmirdîye (m)"
  },
  {
    "x": "çimmirdê (m)",
    "y": " b. çimmirdîye (m)"
  },
  {
    "x": "çimmirdîye (m)",
    "y": " tokgözlülük"
  },
  {
    "x": "çimok",
    "y": " b. çunke"
  },
  {
    "x": "çimoşa (m)",
    "y": " papatya"
  },
  {
    "x": "çimpistiki-I (m)",
    "y": " b. çimbestike (m)"
  },
  {
    "x": "çimpistiki-II (m)",
    "y": " b. çimpîştike (m)"
  },
  {
    "x": "çimpistinike (m)",
    "y": " b. çimpîştike (m)"
  },
  {
    "x": "çimpiştike (m)",
    "y": " b. çimbestike (m)"
  },
  {
    "x": "çimpiştiki (m)",
    "y": " b. çimpîştike (m)"
  },
  {
    "x": "çimpîştike (m)",
    "y": " körebe"
  },
  {
    "x": "çimserrafetilnayîş (n)",
    "y": " göz gezdirme"
  },
  {
    "x": "çimsîya (n)",
    "y": " b. çimesîya (n)"
  },
  {
    "x": "çimsîyay (m)",
    "y": " b. çimesîyaye (m)"
  },
  {
    "x": "çimsîyayey (m)",
    "y": " b. çimesîyayî (m), çimesîyayîye (m)"
  },
  {
    "x": "çimsîyayey kerdene",
    "y": " b. çimesîyayîye kerdene"
  },
  {
    "x": "çimsîyayê (m)",
    "y": " b. çimesîyayî (m), çimesîyayîye (m)"
  },
  {
    "x": "çimsîyayê kerdene",
    "y": " b. çimesîyayî kerdene, çimesîyayîye kerdene"
  },
  {
    "x": "çimsîyayî (m)",
    "y": " b. çimesîyayî (m), çimesîyayîye (m)"
  },
  {
    "x": "çimsîyayî kerdene",
    "y": " b. çimesîyayî kerdene"
  },
  {
    "x": "çimsîyê (m)",
    "y": " b. çimesîyaye (m)"
  },
  {
    "x": "çimteber (n)",
    "y": " çapkın, hovarda"
  },
  {
    "x": "çimteberî (m)",
    "y": " çapkınlık, hovardalık"
  },
  {
    "x": "çimteng, -e",
    "y": " 1)b. çimeteng, -e\r\n~2)cimri"
  },
  {
    "x": "çimtengey (m)",
    "y": " b. çimtengîye (m)"
  },
  {
    "x": "çimtengey kerdene",
    "y": " b. çimetengîye kerdene"
  },
  {
    "x": "çimtengê (m)",
    "y": " b. çimtengîye (m)"
  },
  {
    "x": "çimtengê kerdene",
    "y": " b. çimetengî kerdene"
  },
  {
    "x": "çimtengî (m)",
    "y": " 1)b. çimetengî (m), çimetengîye (m)\r\n~2)cimrilik"
  },
  {
    "x": "çimtengî kerdene",
    "y": " 1)b. çimetengî kerdene\r\n~2)cimrilik etmek"
  },
  {
    "x": "çimtengîye (m)",
    "y": " 1)b. çimetengî (m), çimetengîye (m)\r\n~2)cimrilik"
  },
  {
    "x": "çimtengîye kerdene",
    "y": " 1)b. çimetengîye kerdene\r\n~2)cimrilik etmek"
  },
  {
    "x": "çimveyşan, -e",
    "y": " b. çimvêşan, -e"
  },
  {
    "x": "çimveyşaney (m)",
    "y": " b. çimvêşanîye (m)"
  },
  {
    "x": "çimveyşon, -i",
    "y": " b. çimvêşan, -e"
  },
  {
    "x": "çimveyşoney (m)",
    "y": " b. çimvêşanîye (m)"
  },
  {
    "x": "çimveyşûn, -i",
    "y": " b. çimvêşan, -e"
  },
  {
    "x": "çimveyşûnê (m)",
    "y": " b. çimvêşanîye (m)"
  },
  {
    "x": "çimvêşan, -e",
    "y": " açgözlü, açgöz, gözü aç"
  },
  {
    "x": "çimvêşaney (m)",
    "y": " b. çimvêşanîye (m)"
  },
  {
    "x": "çimvêşanê (m)",
    "y": " b. çimvêşanîye (m)"
  },
  {
    "x": "çimvêşanîye (m)",
    "y": " açgözlülük, açgözlük, gözü açlık"
  },
  {
    "x": "çimvêşon, -i",
    "y": " b. çimvêşan, -e"
  },
  {
    "x": "çimvêşûn, -i",
    "y": " b. çimvêşan, -e"
  },
  {
    "x": "çimxezal, -e",
    "y": " ceylan gözlü "
  },
  {
    "x": "çimxezali (m)",
    "y": " b. çimxezal, -e"
  },
  {
    "x": "çimxizali (m)",
    "y": " b. çimxezal, -e"
  },
  {
    "x": "çin (nm & s)",
    "y": " yok\r\n~çin a: yoktur\r\n~çin î: yokturlar\r\n~çin o: yoktur"
  },
  {
    "x": "çin a",
    "y": " yoktur"
  },
  {
    "x": "çin î",
    "y": " yokturlar"
  },
  {
    "x": "çin o",
    "y": " yoktur"
  },
  {
    "x": "çin bîyene",
    "y": " yok olmak, buharlaşmak (mecazi)"
  },
  {
    "x": "çin kerdene",
    "y": " 1)yok etmek, imha etmek, bastırmak (yok etmek)\r\n~2)gidermek\r\n~3)yıkmak"
  },
  {
    "x": "çina-I",
    "y": " ne\r\n~Çina yo?: (Nedir?)"
  },
  {
    "x": "Çina yo?",
    "y": " (Nedir?)"
  },
  {
    "x": "çina ra",
    "y": " b. çinayî ra"
  },
  {
    "x": "çina rê",
    "y": " b. çinayî rê"
  },
  {
    "x": "çinay ra",
    "y": " b. çinayî ra"
  },
  {
    "x": "çinay rê",
    "y": " b. çinayî rê"
  },
  {
    "x": "çinayî ra",
    "y": " neden"
  },
  {
    "x": "çinayî rê",
    "y": " niçin, niye"
  },
  {
    "x": "çina-II (n)",
    "y": " elbise, giyecek, giysi, kılık\r\n~çinayê sunetî (zh): sünnet elbisesi/giysisi\r\n~çinayê şewe (zh): gecelik"
  },
  {
    "x": "çina ameyene",
    "y": " âdet görmek, (kadın) aybaşı olmak"
  },
  {
    "x": "çina ameyîne",
    "y": " b. çina ameyene"
  },
  {
    "x": "çina bedilnayene",
    "y": " elbise değiştirmek, giysi değiştirmek"
  },
  {
    "x": "çina vetene",
    "y": " elbise çıkarmak, giysi çıkarmak"
  },
  {
    "x": "çina vurnayene",
    "y": " elbise değiştirmek, giysi değiştirmek"
  },
  {
    "x": "çinayê sunetî (zh)",
    "y": " sünnet elbisesi/giysisi"
  },
  {
    "x": "çinayê şewe (zh)",
    "y": " gecelik"
  },
  {
    "x": "çinaameyîş (n)",
    "y": " âdet görme, (kadın) aybaşı olma"
  },
  {
    "x": "çinalewr (m)",
    "y": " b. çinarêre (m)"
  },
  {
    "x": "çinalêri (m)",
    "y": " b. çinarêre (m)"
  },
  {
    "x": "çinalyêr (m)",
    "y": " b. çinarêre (m)"
  },
  {
    "x": "Çinar (n)",
    "y": " Çınar"
  },
  {
    "x": "çinarêr (m)",
    "y": " b. çinarêre (m)"
  },
  {
    "x": "çinarêre (m)",
    "y": " çınar"
  },
  {
    "x": "çinayî (zh)",
    "y": " üst baş (giysiler)"
  },
  {
    "x": "çinbîyayeyî (m)",
    "y": " yoksulluk, yokluk, fakirlik"
  },
  {
    "x": "çinbîyayê (m)",
    "y": " b. çinbîyayeyî (m)"
  },
  {
    "x": "çinbîyayîş (n)",
    "y": " 1)yok olma, buharlaşma (mecazi)\r\n~2)yokluk\r\n~3)yıkım"
  },
  {
    "x": "çinciqla (m)",
    "y": " b. çinçolike (m)"
  },
  {
    "x": "çincolike (m)",
    "y": " b. çinçolike (m)"
  },
  {
    "x": "çinçolike (m)",
    "y": " salıncak (içinde çocuk uyutulan salıncak) "
  },
  {
    "x": "çind",
    "y": " b. çend "
  },
  {
    "x": "çinday ... enday ...",
    "y": " b. çend ... hende ..."
  },
  {
    "x": "çindi",
    "y": " b. çend "
  },
  {
    "x": "çindi ... indi ...",
    "y": " b. çend ... hende ..."
  },
  {
    "x": "çindikdayîş (n)",
    "y": " durduğu yerden atlama "
  },
  {
    "x": "çine-I",
    "y": " b. çina-I"
  },
  {
    "x": "çine-II (nm)",
    "y": " b. çin (nm)"
  },
  {
    "x": "çine bîyene",
    "y": " b. çin bîyene "
  },
  {
    "x": "çine kerdene",
    "y": " b. çin kerdene"
  },
  {
    "x": "çinebîyayîş (n)",
    "y": " b. çinbîyayîş (n)"
  },
  {
    "x": "çiney",
    "y": " b. çina-I"
  },
  {
    "x": "çinê-I",
    "y": " b. çina-I"
  },
  {
    "x": "çinê ra",
    "y": " b. çinayî ra"
  },
  {
    "x": "çinê-II (nm)",
    "y": " b. çin (nm)"
  },
  {
    "x": "çinê ya",
    "y": " b. çin a"
  },
  {
    "x": "çinê yê",
    "y": " b. çin î"
  },
  {
    "x": "çinê yo",
    "y": " b. çin o"
  },
  {
    "x": "çinê bîyene",
    "y": " b. çin bîyene"
  },
  {
    "x": "çinê kerdene",
    "y": " b. çin kerdene"
  },
  {
    "x": "çinêbîyayîş (n)",
    "y": " b. çinbîyayîş (n)"
  },
  {
    "x": "çing (n)",
    "y": " çınlama sesi"
  },
  {
    "x": "çingal (n)",
    "y": " b. çengal (n)"
  },
  {
    "x": "çingayene",
    "y": " çınlamak"
  },
  {
    "x": "çingayîş (n)",
    "y": " çınlama"
  },
  {
    "x": "çingdayîş (n)",
    "y": " 1)durduğu yerden atlama\r\n~2)atlama (uzun atlama)"
  },
  {
    "x": "çingil (n)",
    "y": " b. cingil (n)"
  },
  {
    "x": "çingî (m)",
    "y": " çınlama, çınlama sesi"
  },
  {
    "x": "çingîye (m)",
    "y": " çınlama, çınlama sesi"
  },
  {
    "x": "çingnayene",
    "y": " çınlatmak"
  },
  {
    "x": "çini (nm)",
    "y": " b. çin (nm)"
  },
  {
    "x": "çini bîyene",
    "y": " b. çin bîyene"
  },
  {
    "x": "çinibîyayey (m)",
    "y": " b. çinbîyayeyî (m)"
  },
  {
    "x": "çinibîyayîş (n)",
    "y": " b. çinbîyayîş (n)"
  },
  {
    "x": "çinik (nm)",
    "y": " b. çin (nm)"
  },
  {
    "x": "çinik a",
    "y": " b. çin a"
  },
  {
    "x": "çinik î",
    "y": " b. çin î"
  },
  {
    "x": "çinik o",
    "y": " b. çin o"
  },
  {
    "x": "çinî (nm)",
    "y": " b. çin (nm)"
  },
  {
    "x": "çinî ya",
    "y": " b. çin a"
  },
  {
    "x": "çinî yê",
    "y": " b. çin î"
  },
  {
    "x": "çinî yo",
    "y": " b. çin o"
  },
  {
    "x": "çinî bîyene",
    "y": " b. çin bîyene"
  },
  {
    "x": "çinî kerdene",
    "y": " b. çin kerdene"
  },
  {
    "x": "çinikbîyayîş (n)",
    "y": " b. çinbîyayîş (n)"
  },
  {
    "x": "çinîbîyayîş (n)",
    "y": " b. çinbîyayîş (n)"
  },
  {
    "x": "çinîk (nm)",
    "y": " b. çin (nm)"
  },
  {
    "x": "çinîkbîyayîş (n)",
    "y": " b. çinbîyayîş (n)"
  },
  {
    "x": "çinîye (nm)",
    "y": " b. çin (nm)"
  },
  {
    "x": "çinîyebîyayîş (n)",
    "y": " b. çinbîyayîş (n)"
  },
  {
    "x": "çinkerdiş (n)",
    "y": " 1)yok etme, imha, imha etme, bastırma (yok etme)\r\n~2)yıkma\r\n~3)giderme"
  },
  {
    "x": "çinkerdişê kukirtî (n)",
    "y": " kükürtün giderilmesi"
  },
  {
    "x": "çinkê",
    "y": " b. çunke"
  },
  {
    "x": "çinku",
    "y": " b. çunke"
  },
  {
    "x": "çinkû",
    "y": " b. çunke "
  },
  {
    "x": "çiqa",
    "y": " b. çiqas"
  },
  {
    "x": "çiqas",
    "y": " ne kadar"
  },
  {
    "x": "çiqas ke şino",
    "y": " giderek, gittikçe, yavaş yavaş"
  },
  {
    "x": "çiqaşî",
    "y": " b. çiqas"
  },
  {
    "x": "çiqay",
    "y": " b. Çiqas"
  },
  {
    "x": "çiqir (n) ",
    "y": "kırışık (kırışıklık), kıvrım"
  },
  {
    "x": "çiqirey (m)",
    "y": " b. çiqirî (m), çiqirîye (m)"
  },
  {
    "x": "çiqirê (m)",
    "y": " b. çiqirî (m), çiqirîye (m)"
  },
  {
    "x": "çiqirî (m)",
    "y": " kırışıklık"
  },
  {
    "x": "çiqirîyaya (m)",
    "y": " kırışık (kırışmış olan)"
  },
  {
    "x": "çiqirîyaye (n)",
    "y": " kırışık (kırışmış olan)"
  },
  {
    "x": "çiqirîyayene",
    "y": " kırışmak"
  },
  {
    "x": "çiqirîyayeyey (m)",
    "y": " b. çiqirîyayeyî (m)"
  },
  {
    "x": "çiqirîyayeyê (m)",
    "y": " b. çiqirîyayeyî (m)"
  },
  {
    "x": "çiqirîyayeyî (m)",
    "y": " kırışıklık"
  },
  {
    "x": "çiqirîyayîye (m)",
    "y": " kırışık (kırışmış olan)"
  },
  {
    "x": "çiqirîye (m)",
    "y": " kırışıklık"
  },
  {
    "x": "çiqirnaya (m)",
    "y": " kırışık (kırışmış olan)"
  },
  {
    "x": "çiqirnaye (n)",
    "y": " kırışık (kırışmış olan)"
  },
  {
    "x": "çiqirnayene",
    "y": " kırıştırmak"
  },
  {
    "x": "çiqirnayeyey (m)",
    "y": " b. çiqirnayeyî (m)"
  },
  {
    "x": "çiqirnayeyê (m)",
    "y": " b. çiqirnayeyî (m)"
  },
  {
    "x": "çiqirnayeyî (m)",
    "y": " kırışıklık"
  },
  {
    "x": "çiqirnayîye (m)",
    "y": " kırışık (kırışmış olan)"
  },
  {
    "x": "çiqiryaya (m)",
    "y": " b. çiqirîyaya (m)"
  },
  {
    "x": "çiqiryaye (n)",
    "y": " b. çiqirîyaye (n) "
  },
  {
    "x": "çiqiryayene",
    "y": " b. çiqirîyayene"
  },
  {
    "x": "çiqiryayeyey (m)",
    "y": " b. çiqirîyayeyî (m)"
  },
  {
    "x": "çiqiryayeyê (m)",
    "y": " b. çiqirîyayeyî (m)"
  },
  {
    "x": "çiqiryayeyî (m)",
    "y": " b. çiqirîyayeyî (m)"
  },
  {
    "x": "çiqiryayîye (m)",
    "y": " b. çiqirîyayîye (m"
  },
  {
    "x": "çiqlik (n)",
    "y": " kıvılcım"
  },
  {
    "x": "çiqsipe (n)",
    "y": " b. çîqsipî, -ye;"
  },
  {
    "x": "çiqulata (m)",
    "y": " b. çîkolata (m)"
  },
  {
    "x": "çiqulatayin, -e",
    "y": " b. çîkolatayin, -e"
  },
  {
    "x": "çir-I (n)",
    "y": " b. çire-I (m)"
  },
  {
    "x": "çir-II (n)",
    "y": " b. çirr-I (n)"
  },
  {
    "x": "çir-III (m)",
    "y": " b. çirrîye (m)"
  },
  {
    "x": "çir-IV (m)",
    "y": " b. çire-I (m)"
  },
  {
    "x": "çir kerdene",
    "y": " sağmak"
  },
  {
    "x": "çira-I",
    "y": " neden"
  },
  {
    "x": "çira-II (m)",
    "y": " b. çila (m)"
  },
  {
    "x": "çiraçur (n)",
    "y": " b. çimçêrî (m)"
  },
  {
    "x": "çiralix (n)",
    "y": " çıralık (Alevilikte)"
  },
  {
    "x": "çiraneke (m)",
    "y": " oluk, çörten"
  },
  {
    "x": "çiraniki (m)",
    "y": " b. çiraneke (m)"
  },
  {
    "x": "çirax, -e",
    "y": " çırak, çömez, şakirt"
  },
  {
    "x": "çiraxîye (m)",
    "y": " çıraklık, çömezlik"
  },
  {
    "x": "çiraynayene",
    "y": " b. çerênayene"
  },
  {
    "x": "çiraynayîs (n)",
    "y": " b. çerênayîş (n)"
  },
  {
    "x": "çiraynayîş (n)",
    "y": " b. çerênayîş (n)"
  },
  {
    "x": "çiraynîyayene",
    "y": " b. çerênîyayene"
  },
  {
    "x": "çiraynîyayîş (n)",
    "y": " b. çerênîyayîş (n)"
  },
  {
    "x": "çirç kerdene",
    "y": " b. çarç kerdene"
  },
  {
    "x": "çirçele (n)",
    "y": " b. çîrçîrik, -e"
  },
  {
    "x": "çirçile (n)",
    "y": " b. çîrçîrik, -e"
  },
  {
    "x": "çirçilî (n)",
    "y": " b. çîrçîrik, -e"
  },
  {
    "x": "çirçirik, -e",
    "y": " b. çîrçîrik, -e"
  },
  {
    "x": "çirçkerdiş (n)",
    "y": " b. çarçkerdiş (n)"
  },
  {
    "x": "çirdike (m)",
    "y": " b. çiraneke (m)"
  },
  {
    "x": "çire-I (m)",
    "y": " iki grupla ve koşmak suretiyle oynanan geleneksel bir oyun"
  },
  {
    "x": "çire-II (m)",
    "y": " b. çirre-I (m)"
  },
  {
    "x": "çireç (n)",
    "y": " karık (küçük ark)"
  },
  {
    "x": "çirê",
    "y": " niçin, niye"
  },
  {
    "x": "çirênayene",
    "y": " b. çerênayene"
  },
  {
    "x": "çirênayîne",
    "y": " b. çerênayene"
  },
  {
    "x": "çirênayîş (n)",
    "y": " b. çerênayîş (n)"
  },
  {
    "x": "çirênîyayene",
    "y": " b. çerênîyayene"
  },
  {
    "x": "çirênîyayîne",
    "y": " b. çerênîyayene"
  },
  {
    "x": "çirênîyayîş (n)",
    "y": " b. çerênîyayîş (n)"
  },
  {
    "x": "çirisîyayene",
    "y": " parlamak, ışıldamak, parıldamak"
  },
  {
    "x": "çirisîyayîş (n)",
    "y": " parlama, ışıldama, parıldama"
  },
  {
    "x": "çirisnayene",
    "y": " parlatmak, ışıldatmak, parıldatmak"
  },
  {
    "x": "çirisnayîş (n)",
    "y": " parlatma, ışıldatma, parıldatma"
  },
  {
    "x": "çirî-I (m)",
    "y": " imdat çağrısı, yardım çağrısı"
  },
  {
    "x": "çirî-II",
    "y": " b. çirê"
  },
  {
    "x": "çirîyaya (m)",
    "y": " b. çerîyaya (m)"
  },
  {
    "x": "çirîyaye (n)",
    "y": " b. çerîyaye (n)"
  },
  {
    "x": "çirîyayîne",
    "y": " b. çerîyayene"
  },
  {
    "x": "çirkerdiş (n)",
    "y": " sağma"
  },
  {
    "x": "çirkesane (m)",
    "y": " iki grupla ve koşmak suretiyle oynanan geleneksel bir oyun"
  },
  {
    "x": "çirmiş bîyayene",
    "y": " b. çurmîş bîyene"
  },
  {
    "x": "çirmiş kerdene",
    "y": " b. çurmîş kerdene"
  },
  {
    "x": "çirmişbîyaye (n)",
    "y": " b. çurmîşbîyaye (n)"
  },
  {
    "x": "çirmişbîyayî (m)",
    "y": " b. çurmîşbîyayî (m), b. çurmîşbîyayîye (m)"
  },
  {
    "x": "çirmişbîyayîş (n)",
    "y": " b. çurmîşbîyayîş (n)"
  },
  {
    "x": "çirmişkerdiş (n)",
    "y": " b. çurmîşkerdiş (n)"
  },
  {
    "x": "çirnayene",
    "y": " b. çerênayene"
  },
  {
    "x": "çirnayîş (n)",
    "y": " b. çerênayîş (n)"
  },
  {
    "x": "çirniki (m)",
    "y": " b. çiraneke (m)"
  },
  {
    "x": "çirpi-I (m)",
    "y": " b. çerpe (m)"
  },
  {
    "x": "çirpi-II (n)",
    "y": " b. çirpî (m)"
  },
  {
    "x": "çirpik (m)",
    "y": " b. çirpike (m)"
  },
  {
    "x": "çirpik panayîne",
    "y": " b. çirpike panayene"
  },
  {
    "x": "çirpik piro dayîne",
    "y": " b. çirpike piro dayene"
  },
  {
    "x": "çirpike (m)",
    "y": " fiske"
  },
  {
    "x": "çirpike panayene",
    "y": " fiskelemek, fiske vurmak"
  },
  {
    "x": "çirpike piro dayene",
    "y": " fiskelemek, fiske vurmak"
  },
  {
    "x": "çirpikepanayîş (n)",
    "y": " fiskeleme"
  },
  {
    "x": "çirpikepirodayîş (n)",
    "y": " fiskeleme"
  },
  {
    "x": "çirpikpanayîş (n)",
    "y": " b. çirpikepanayîş (n)"
  },
  {
    "x": "çirpikpirodayîş (n)",
    "y": " b. çirpikepirodayîş (n)"
  },
  {
    "x": "çirpî (m)",
    "y": " 1)çırpı (kuru dal parçaları)\r\n~2)binanın çatısını kaplamakta kullanılan ince ve kısa ağaç parçaları, çubuk"
  },
  {
    "x": "çirpî (n)",
    "y": " b. çirpî (m"
  },
  {
    "x": "çirpnayene",
    "y": " budamak (ağaç için)"
  },
  {
    "x": "çirpnayîş (n)",
    "y": " budama (ağaç için)"
  },
  {
    "x": "çirpoj (n)",
    "y": " b. çerepuz (n)"
  },
  {
    "x": "çirpoz (n)",
    "y": " 1)b. çerepuz (n)\r\n~2)sonbaharda erken sürülen çift, güzlük çift"
  },
  {
    "x": "çirr-I (n)",
    "y": " çağlayan, çavlan, şelale"
  },
  {
    "x": "çirr-II (m)",
    "y": " b. çirre (m)"
  },
  {
    "x": "çirr-III (m)",
    "y": " b. çirrîye (m)"
  },
  {
    "x": "çirraçur (n)",
    "y": " b. çimçêrî (m)"
  },
  {
    "x": "çirre-I (m)",
    "y": " 1)boru ve oluk gibi şeylerden veya yüksekçe bir yerden akan çeşme\r\n~2)çörten\r\n~3)musluk"
  },
  {
    "x": "çirre-II (m)",
    "y": " b. çire-I (m)"
  },
  {
    "x": "çirreçe (n)",
    "y": " derecik"
  },
  {
    "x": "çirri-I (m)",
    "y": " b. çirre (m)"
  },
  {
    "x": "çirri-II (m)",
    "y": " b. çire (m)"
  },
  {
    "x": "çirrike (m)",
    "y": " b. çiraneke (m)"
  },
  {
    "x": "çirrîye (m)",
    "y": " bağırtı, bağırış, çığlık"
  },
  {
    "x": "çirtan (n)",
    "y": " 1)çağlayan, çavlan, şelale\r\n~2)b. çiraneke (m)"
  },
  {
    "x": "çirtik-I (n)",
    "y": " hayvanın zıplayıp koşarak attığı tekme"
  },
  {
    "x": "çirtik-II (m)",
    "y": " b. çirtike (m)"
  },
  {
    "x": "çirtik kerdene",
    "y": " b. çirtike kerdene"
  },
  {
    "x": "çirtik panayene",
    "y": " b. çirtike panayene"
  },
  {
    "x": "çirtik piro dayîne",
    "y": " b. çirtike piro dayene"
  },
  {
    "x": "çirtike-I (m)",
    "y": " fiske"
  },
  {
    "x": "çirtike kerdene",
    "y": " fiskelemek, fiske vurmak"
  },
  {
    "x": "çirtike panayene",
    "y": " fiskelemek, fiske vurmak"
  },
  {
    "x": "çirtike piro dayene",
    "y": " fiskelemek, fiske vurmak"
  },
  {
    "x": "çirtike-II (m)",
    "y": " çıtçıt"
  },
  {
    "x": "çirtike-III (m)",
    "y": " b. çiraneke (m)"
  },
  {
    "x": "çirtikekerdiş (n)",
    "y": " fiskeleme"
  },
  {
    "x": "çirtikepanayîş (n)",
    "y": " fiskeleme"
  },
  {
    "x": "çirtikepirodayîş (n)",
    "y": " fiskeleme"
  },
  {
    "x": "çirtiki-I (m)",
    "y": " b. çirtike-I (m)"
  },
  {
    "x": "çirtiki kerdene",
    "y": " b. çirtike kerdene "
  },
  {
    "x": "çirtiki panayene",
    "y": " b. çirtike panayene"
  },
  {
    "x": "çirtiki piro dayîne",
    "y": " b. çirtike piro dayene "
  },
  {
    "x": "çirtiki-II (m)",
    "y": " b. çirtike-II (m)"
  },
  {
    "x": "çirtikikerdiş (n)",
    "y": " b. çirtikekerdiş (n)"
  },
  {
    "x": "çirtikipanayîş (n)",
    "y": " b. çirtikepanayîş (n)"
  },
  {
    "x": "çirtikipirodayîş (n)",
    "y": " b. çirtikepirodayîş (n)"
  },
  {
    "x": "çirtikkerdiş (n)",
    "y": " b. çirtikekerdiş (n)"
  },
  {
    "x": "çirtikpanayîş (n)",
    "y": " b. çirtikepanayîş (n)"
  },
  {
    "x": "çirtikpirodayîş (n)",
    "y": " b. çirtikepirodayîş (n)"
  },
  {
    "x": "çirtim (n)",
    "y": " b. cirtim (n)"
  },
  {
    "x": "çirtuxe (m)",
    "y": " b. çirtike-II (m)"
  },
  {
    "x": "çirusîyayene",
    "y": " b. çirisîyayene"
  },
  {
    "x": "çirusîyayîş (n)",
    "y": " b. çirisîyayîş (n)"
  },
  {
    "x": "çirusnayene",
    "y": " b. beriqnayene"
  },
  {
    "x": "çirusnayîş (n)",
    "y": " b. çirisnayîş (n)"
  },
  {
    "x": "çirxazî (zh)",
    "y": " suçiçeği"
  },
  {
    "x": "çirxazî vetene",
    "y": " suçiçeği çıkarmak"
  },
  {
    "x": "çis (n)",
    "y": " b. çitur (n)"
  },
  {
    "x": "çism (n)",
    "y": " b. çim (n)"
  },
  {
    "x": "çisme (n)",
    "y": " b. çime (n)"
  },
  {
    "x": "çist (m)",
    "y": " b. kîşte (m)"
  },
  {
    "x": "çistkî",
    "y": " b. kîştkî"
  },
  {
    "x": "çit-I",
    "y": " keçiyi kovmak için kullanılan seslenme sözcüğü "
  },
  {
    "x": "çit-II (m)",
    "y": " b. çute (m)"
  },
  {
    "x": "çit-III",
    "y": " b. çi-I\r\n~Çit o?/Çi yo? (Nedir?)"
  },
  {
    "x": "çita",
    "y": " 1)b. Çitur\r\n~2)b. çi-I"
  },
  {
    "x": "çitan",
    "y": " 1)b. Çitur\r\n~2)b. çi-I"
  },
  {
    "x": "çitaw",
    "y": " b. çitur"
  },
  {
    "x": "çiter",
    "y": " b. çitur"
  },
  {
    "x": "çitewir",
    "y": " b. çitur"
  },
  {
    "x": "çiti (m)",
    "y": " b. çute (m)"
  },
  {
    "x": "çitil",
    "y": " b. çitur"
  },
  {
    "x": "çitir",
    "y": " b. çitur"
  },
  {
    "x": "çitme (n)",
    "y": " b. çutme (n)"
  },
  {
    "x": "çitme eşteni",
    "y": " b. çutme eştene"
  },
  {
    "x": "çito",
    "y": " b. çitur "
  },
  {
    "x": "çiton",
    "y": " b. çitur "
  },
  {
    "x": "çitor",
    "y": " b. çitur"
  },
  {
    "x": "çitorî",
    "y": " b. çitur"
  },
  {
    "x": "çitowrî",
    "y": " b. çitur"
  },
  {
    "x": "çitpite (m)",
    "y": " çıtçıt"
  },
  {
    "x": "çitur",
    "y": " nasıl\r\n~Ti çitur a, rind a? (Nasılsın, iyi misin?)"
  },
  {
    "x": "çiturî",
    "y": " b. çitur"
  },
  {
    "x": "çitû",
    "y": " b. çitur"
  },
  {
    "x": "çitûn",
    "y": " b. çitur "
  },
  {
    "x": "çiv dayene",
    "y": " kaytarmak"
  },
  {
    "x": "çiva (n)",
    "y": " b. teba (n)"
  },
  {
    "x": "çiver (n)",
    "y": " b. keyber (n) "
  },
  {
    "x": "çivir (n)",
    "y": " dönemeç, viraj"
  },
  {
    "x": "çivirnayene",
    "y": " kaytarmak"
  },
  {
    "x": "çiwa-I (n)",
    "y": " b. teba (n)"
  },
  {
    "x": "çiwa-II (m)",
    "y": " b. çuwe (n), çu (n)"
  },
  {
    "x": "çiwal (n)",
    "y": " b. ciwal (n)"
  },
  {
    "x": "çiwe (n)",
    "y": " b. çuwe (n), çu (n)"
  },
  {
    "x": "çiwras",
    "y": " b. çewres"
  },
  {
    "x": "çixa",
    "y": " b. çiqas"
  },
  {
    "x": "çixas",
    "y": " b. çiqas"
  },
  {
    "x": "çixaş",
    "y": " b. çiqas"
  },
  {
    "x": "çixe çixe",
    "y": " gelmeleri için büyükbaş ve küçükbaş hayvanlara seslenirken kullanılan sözcükler (inek ve öküz için)"
  },
  {
    "x": "çixir (n)",
    "y": " çığır"
  },
  {
    "x": "çixure (m)",
    "y": " çukur "
  },
  {
    "x": "çixure bîyene",
    "y": " çukurlaşmak"
  },
  {
    "x": "çixurebîyayîş (n)",
    "y": " çukurlaşma"
  },
  {
    "x": "çizayene",
    "y": " bağırmak, çığlık atmak, çığlığı basmak, çığlık koparmak"
  },
  {
    "x": "çizayîş (n)",
    "y": " bağırma, çığlık atma, çığlığı basma, çığlık koparma"
  },
  {
    "x": "çizênî (m)",
    "y": " bağırtı, bağırış, çığlık"
  },
  {
    "x": "çizik (n)",
    "y": " b. çiçik (n)"
  },
  {
    "x": "çizilnayene",
    "y": " son damlasına dek sağmak (tüm ürününü almak)"
  },
  {
    "x": "çizix (n)",
    "y": " b. xêze (m)"
  },
  {
    "x": "çizixe (m)",
    "y": " b. xêze (m)"
  },
  {
    "x": "çizî (m)",
    "y": " bağırtı, bağırış, çığlık"
  },
  {
    "x": "çizîye (m)",
    "y": " bağırtı, bağırış, çığlık"
  },
  {
    "x": "çizrikî (zh)",
    "y": " içyağının kızartılmasıyla meydana gelen bir yiyecek"
  },
  {
    "x": "çizxî (m)",
    "y": " seksek [oyunu]"
  },
  {
    "x": "çizzayene",
    "y": " b. çizayene"
  },
  {
    "x": "çizzayîş (n)",
    "y": " b. çizayîş (n)"
  },
  {
    "x": "çî-I (n)",
    "y": " şey, nesne\r\n~çîyê dizdênî (n): çalıntı\r\n~çîyê şimitişî (n): içecek, içilecek şey\r\n~çîyo ganî (n): organizma"
  },
  {
    "x": "çî-mî (n)",
    "y": " eşya"
  },
  {
    "x": "çîy werî (n)",
    "y": " b. çîyê werdî (n)"
  },
  {
    "x": "çîy werî yo şîrin",
    "y": " b. çîyê werdî yo şîrin"
  },
  {
    "x": "çîyê",
    "y": " bir şey"
  },
  {
    "x": "çîyê dizdênî (n)",
    "y": " çalıntı"
  },
  {
    "x": "çîyê dizdîye (n)",
    "y": " çalıntı"
  },
  {
    "x": "çîyê şimitişî (n)",
    "y": " içecek, içilecek şey"
  },
  {
    "x": "çîyê werdiş û şimitişî",
    "y": " yiyecek ve içecek"
  },
  {
    "x": "çîyê werdişî (n)",
    "y": " yiyecek"
  },
  {
    "x": "çîyê werdişî yo şîrin",
    "y": " tatlı (tatlı yiyecek)"
  },
  {
    "x": "çîyê werdî (n)",
    "y": " yiyecek, yiyecek maddesi"
  },
  {
    "x": "çîyê werdî yo şîrin",
    "y": " tatlı (tatlı yiyecek)"
  },
  {
    "x": "çîyo ganî (n)",
    "y": " organizma"
  },
  {
    "x": "çî-II (zr)",
    "y": " b. zî (zr)"
  },
  {
    "x": "çî-mî (n)",
    "y": " eşya\r\n~çî-mîyê xemlî (n): takı\r\n~çî-mîyê zîynetî (n): takı"
  },
  {
    "x": "çî-mîyê xemlî (n)",
    "y": " takı"
  },
  {
    "x": "çî-mîyê zîynetî (n)",
    "y": " takı"
  },
  {
    "x": "çîçegdar, -e",
    "y": " çiçekli"
  },
  {
    "x": "çîçege (m)",
    "y": " çiçek"
  },
  {
    "x": "çîçegi (m)",
    "y": " b. çîçege (m)"
  },
  {
    "x": "çîçegin, -e",
    "y": " çiçekli"
  },
  {
    "x": "çîçegroş, -e",
    "y": " çiçekçi"
  },
  {
    "x": "çîçek (m)",
    "y": " b. çîçege (m)"
  },
  {
    "x": "çîftexas (n)",
    "y": " b. çiftexas (n)"
  },
  {
    "x": "çîftnayene",
    "y": " b. çîvnayene "
  },
  {
    "x": "çîftnayîş (n)",
    "y": " b. çîvnayîş (n)"
  },
  {
    "x": "çîg (n)",
    "y": " 1)çiğköfte\r\n~2)ince bulgur"
  },
  {
    "x": "çîgkufte (n)",
    "y": " çiğköfte"
  },
  {
    "x": "çîk-I (n)",
    "y": " kıvılcım"
  },
  {
    "x": "çîk-II (n)",
    "y": " şey"
  },
  {
    "x": "çîk-III",
    "y": " 1)b. çîyekî\r\n~2)b. çîke"
  },
  {
    "x": "çîkayene",
    "y": " çığlık atmak, çığlığı basmak, çığlık koparmak"
  },
  {
    "x": "çîkayêne",
    "y": " b. çîkayene"
  },
  {
    "x": "çîkayîş (n)",
    "y": " çığlık atma, çığlığı basma, çığlık koparma"
  },
  {
    "x": "çîke",
    "y": " bir şey"
  },
  {
    "x": "çîkênî (m)",
    "y": " bağırtı, bağırış, çığlık"
  },
  {
    "x": "çîkî-I",
    "y": " b. çîyekî"
  },
  {
    "x": "çîkî-II",
    "y": " b. çîkîye (m)"
  },
  {
    "x": "çîkîye (m)",
    "y": " bağırtı, bağırış, çığlık"
  },
  {
    "x": "çîkofte (n)",
    "y": " b. çîgkufte (n)"
  },
  {
    "x": "çîkolata (m)",
    "y": " çikolata"
  },
  {
    "x": "çîkolataroş, -e",
    "y": " çikolatacı"
  },
  {
    "x": "çîkolataviraştox, -e",
    "y": " çikolatacı"
  },
  {
    "x": "çîkolatayin, -e",
    "y": " çikolatalı"
  },
  {
    "x": "çîksipî, -ye",
    "y": " b. çîqsipî, -ye"
  },
  {
    "x": "çîkufte (n)",
    "y": " b. çîgkufte (n)"
  },
  {
    "x": "çîlca (n)",
    "y": " b. kîlca (n)"
  },
  {
    "x": "çîle (n)",
    "y": " çêle-II (n)"
  },
  {
    "x": "çîle (m)",
    "y": " b. kîle (m)"
  },
  {
    "x": "çîlege (m)",
    "y": " çilek"
  },
  {
    "x": "çîlek (m)",
    "y": " çîlege (m)"
  },
  {
    "x": "çîlik (n)",
    "y": " bızır, klitoris "
  },
  {
    "x": "çîlin, -e",
    "y": " b. kîlin, -e"
  },
  {
    "x": "çîlîngîr, -e",
    "y": " çilingir"
  },
  {
    "x": "çîlsipe (n)",
    "y": " b. çîlsipî, -ye"
  },
  {
    "x": "çîlsipê (m)",
    "y": " b. çîlsipîye (m)"
  },
  {
    "x": "çîlsipî, -ye",
    "y": " bembeyaz, apak"
  },
  {
    "x": "çîlsipîye (m)",
    "y": " bembeyaz, apak"
  },
  {
    "x": "çîlspî (m, n)",
    "y": " b. çîlsipî, -ye"
  },
  {
    "x": "çîm (n)",
    "y": " çim"
  },
  {
    "x": "çîn-I (nm)",
    "y": " b. çin (nm)"
  },
  {
    "x": "çîn a",
    "y": " b. çin a"
  },
  {
    "x": "çin ê",
    "y": " b. çin î "
  },
  {
    "x": "çîn o",
    "y": " b. çin o"
  },
  {
    "x": "çîn bîyene",
    "y": " b. çin bîyene"
  },
  {
    "x": "çîn kerdene",
    "y": " b. çin kerdene"
  },
  {
    "x": "çîn-II (n)",
    "y": " b. kîn (n)"
  },
  {
    "x": "Çîn",
    "y": " Çin"
  },
  {
    "x": "çînayene",
    "y": " 1)biçmek\r\n~2)ürün toplamak (incir vb. için)\r\n~3)koparmak (üzüm vb. için)"
  },
  {
    "x": "engure çînayene",
    "y": " üzüm koparmak"
  },
  {
    "x": "çînayîş (n)",
    "y": " 1)biçme\r\n~2)ürün toplama (incir vb. için)"
  },
  {
    "x": "çînbîyayîş (n)",
    "y": " b. çinbîyayîş (n)"
  },
  {
    "x": "çînci-I (m)",
    "y": " b. tîje (m)"
  },
  {
    "x": "çînci-I (n)",
    "y": " b. tîjin (n)"
  },
  {
    "x": "çîncin, -i",
    "y": " b. tîjin, -e"
  },
  {
    "x": "çînçinike (m)",
    "y": " 1)kelebek\r\n~2)uğurböceği"
  },
  {
    "x": "çîne (nm)",
    "y": " b. çin (nm)"
  },
  {
    "x": "çîne ya",
    "y": " b. çin a"
  },
  {
    "x": "çîne yê",
    "y": " b. çin î"
  },
  {
    "x": "çîne yo",
    "y": " b. çin o"
  },
  {
    "x": "çîne bîyene",
    "y": " b. çin bîyene"
  },
  {
    "x": "çîne kerdene",
    "y": " b. çin kerdene"
  },
  {
    "x": "çînebîyayîş (n)",
    "y": " b. çinbîyayîş (n)"
  },
  {
    "x": "çîneyîne",
    "y": " 1)b. çînayene\r\n~2)b. çînîyayene"
  },
  {
    "x": "çîneyîş (n)",
    "y": " 1)b. çînayîş (n)\r\n~2)çînîyayîş (n)"
  },
  {
    "x": "çînê (nm)",
    "y": " b. çin (nm)"
  },
  {
    "x": "çînê bîyene",
    "y": " b. çin bîyene"
  },
  {
    "x": "çînê kerdene",
    "y": " b. çin kerdene"
  },
  {
    "x": "çînêbîyayîş (n)",
    "y": " b. çinbîyayîş (n)"
  },
  {
    "x": "çînêdî, -ye",
    "y": " sonradan görme"
  },
  {
    "x": "çîngdayîş (n)",
    "y": " b. çingdayîş (n)"
  },
  {
    "x": "çîngil (n)",
    "y": " b. cingil (n)"
  },
  {
    "x": "çîngo (n)",
    "y": " b. çînko (n)"
  },
  {
    "x": "çîni (nm)",
    "y": " b. çin (nm)"
  },
  {
    "x": "çîni ya",
    "y": " b. çin a"
  },
  {
    "x": "çîni yê",
    "y": " b. çin î"
  },
  {
    "x": "çîni yû",
    "y": " b. çin o"
  },
  {
    "x": "çîni bîyene",
    "y": " b. çin bîyene"
  },
  {
    "x": "çîni kerdene",
    "y": " b. çin kerdene"
  },
  {
    "x": "çînibîyayîş (n)",
    "y": " b. çinbîyayîş (n)"
  },
  {
    "x": "çînidî, -ye",
    "y": " b. çînêdî, -ye"
  },
  {
    "x": "çînitene",
    "y": " biçmek"
  },
  {
    "x": "çîniye (nm)",
    "y": " b. çin (nm)"
  },
  {
    "x": "çînî-I (m)",
    "y": " çini"
  },
  {
    "x": "çînî-II (nm)",
    "y": " b. çin (nm)"
  },
  {
    "x": "çînî yo",
    "y": " b. çin o"
  },
  {
    "x": "çînî bîyene",
    "y": " b. çin bîyene"
  },
  {
    "x": "çînî kerdene",
    "y": " b. çin kerdene"
  },
  {
    "x": "çînîbîyayîş (n)",
    "y": " b. çinbîyayîş (n)"
  },
  {
    "x": "çînîk (nm)",
    "y": " b. çin (nm)"
  },
  {
    "x": "çînîroş, -e",
    "y": " çinici"
  },
  {
    "x": "çînîviraştox, -e",
    "y": " çinici"
  },
  {
    "x": "çînîyayene",
    "y": " biçilmek"
  },
  {
    "x": "çînîyayîş (n)",
    "y": " biçilme"
  },
  {
    "x": "çînîye (nm)",
    "y": " b. çin (nm)"
  },
  {
    "x": "çînîye bîyene",
    "y": " b. çin bîyene"
  },
  {
    "x": "çînîye kerdene",
    "y": " b. çin kerdene"
  },
  {
    "x": "çînîyebîyayîş (n)",
    "y": " b. çinbîyayîş (n)"
  },
  {
    "x": "çînîyeyîne",
    "y": " b. çînîyayene"
  },
  {
    "x": "çînk (nm)",
    "y": " b. çin (nm)"
  },
  {
    "x": "çînko (n)",
    "y": " genellikle kulp takılarak küçük kova gibi kullanılan küçük teneke"
  },
  {
    "x": "çînye (nm)",
    "y": " b. çin (nm)"
  },
  {
    "x": "çînye bîyene",
    "y": " b. çin bîyene"
  },
  {
    "x": "çînye kerdene",
    "y": " b. çin kerdene"
  },
  {
    "x": "çînyebîyayîş (n)",
    "y": " b. çinbîyayîş (n)"
  },
  {
    "x": "çîp (m)",
    "y": " incik, baldır"
  },
  {
    "x": "çîp, -e",
    "y": " b. kîp, -e"
  },
  {
    "x": "çîp-çîplax, -e",
    "y": " çırılçıplak, çırçıplak"
  },
  {
    "x": "çîpike-I (m)",
    "y": " bilek (ayak bileği)"
  },
  {
    "x": "çîpike-II (m)",
    "y": " fiske"
  },
  {
    "x": "çîpike estene ra ci",
    "y": " b. çîpike eştene ra ci"
  },
  {
    "x": "çîpike eştene ra ci",
    "y": " fiskelemek, fiske vurmak"
  },
  {
    "x": "çîpike piro dayene",
    "y": " fiskelemek, fiske vurmak"
  },
  {
    "x": "çîpikeesteneraci (n)",
    "y": " b. çîpikeeşteneraci (n)"
  },
  {
    "x": "çîpikeeşteneraci (n)",
    "y": " fiskeleme"
  },
  {
    "x": "çîpikepirodayîş (n)",
    "y": " fiskeleme"
  },
  {
    "x": "çîplax, -e",
    "y": " çıplak"
  },
  {
    "x": "çîqolata (m)",
    "y": " b. çîkolata (m)"
  },
  {
    "x": "çîqolatayin, -e",
    "y": " b. çîkolatayin, -e"
  },
  {
    "x": "çîqsipe (n)",
    "y": " b. çîqsipî, -ye"
  },
  {
    "x": "çîqsipê (m)",
    "y": " b. çîqsipîye (m)"
  },
  {
    "x": "çîqsipî, -ye",
    "y": " bembeyaz, apak"
  },
  {
    "x": "çîqsipîye (m)",
    "y": " bembeyaz, apak"
  },
  {
    "x": "çîr-I (m)",
    "y": " b. çîre-I (m)"
  },
  {
    "x": "çîr-II (n)",
    "y": " b. çêr, -e"
  },
  {
    "x": "çîrbîyaya (m)",
    "y": " yırtık"
  },
  {
    "x": "çîrbîyaye (n)",
    "y": " yırtık"
  },
  {
    "x": "çîrbîyayene",
    "y": " b. çîrbîyene"
  },
  {
    "x": "çîrbîyayîs (n)",
    "y": " b. çîrbîyayîş (n)"
  },
  {
    "x": "çîrbîyayîş (n)",
    "y": " yırtılma"
  },
  {
    "x": "çîrbîyayîye (m)",
    "y": " yırtık"
  },
  {
    "x": "çîrbîyene",
    "y": " yırtılmak"
  },
  {
    "x": "çîrç bîyayene",
    "y": " çarç bîyene"
  },
  {
    "x": "çîrç kerdene",
    "y": " b. çarç kerdene"
  },
  {
    "x": "çîrçîrik, -e",
    "y": " cırcır böceği, cırlak"
  },
  {
    "x": "çîrçîrk (n)",
    "y": " b. çîrçîrik, -e"
  },
  {
    "x": "çîrçkerdiş (n)",
    "y": " b. çarçkerdiş (n)"
  },
  {
    "x": "çîre-I (m)",
    "y": " çir (kurutulmuş kayısı)"
  },
  {
    "x": "çîra vame (m)",
    "y": " badem içi"
  },
  {
    "x": "çîre-II (m)",
    "y": " 1)çil keklik\r\n~2)bıldırcın"
  },
  {
    "x": "çîriknayene",
    "y": " gıcırdatmak"
  },
  {
    "x": "çîriknayîş (n)",
    "y": " gıcırdatma"
  },
  {
    "x": "çîrî (m)",
    "y": " b. çîre-I (m)"
  },
  {
    "x": "çîrkerda (m)",
    "y": " yırtık"
  },
  {
    "x": "çîrkerde (n)",
    "y": " yırtık"
  },
  {
    "x": "çîrkerdene",
    "y": " yırtmak"
  },
  {
    "x": "çîrkerdis (n)",
    "y": " b. çîrkerdiş (n)"
  },
  {
    "x": "çîrkerdiş (n)",
    "y": " yırtma"
  },
  {
    "x": "çîrkerdîye (m)",
    "y": " yırtık"
  },
  {
    "x": "çîrr (n)",
    "y": " b. çêr, -e"
  },
  {
    "x": "çîrrbîyaya (m)",
    "y": " b. çîrbîyaya (m)"
  },
  {
    "x": "çîrrbîyaye (n)",
    "y": " b. çîrbîyaye (n)"
  },
  {
    "x": "çîrrbîyayêne",
    "y": " b. çîrbîyene"
  },
  {
    "x": "çîrrbîyayîs (n)",
    "y": " b. çîrbîyayîş (n)"
  },
  {
    "x": "çîrrbîyayîş (n)",
    "y": " b. çîrbîyayîş (n)"
  },
  {
    "x": "çîrrbîyayîye (m)",
    "y": " b. çîrbîyayîye (m)"
  },
  {
    "x": "çîrrbîyene",
    "y": " b. çîrbîyene"
  },
  {
    "x": "çîrre (m)",
    "y": " b. çîre (m)"
  },
  {
    "x": "çîrrîye (m)",
    "y": " b. çêrîye (m)"
  },
  {
    "x": "çîrrkerda (m)",
    "y": " b. çîrkerda (m)"
  },
  {
    "x": "çîrrkerde (n)",
    "y": " b. çîrkerde"
  },
  {
    "x": "çîrrkerdene",
    "y": " b. çîrkerdene"
  },
  {
    "x": "çîrrkerdis (n)",
    "y": " b. çîrkerdiş (n)"
  },
  {
    "x": "çîrrkerdiş (n)",
    "y": " b. çîrkerdiş (n)"
  },
  {
    "x": "çîrrkerdîye (m)",
    "y": " b. çîrkerdîye (m)"
  },
  {
    "x": "çîşt",
    "y": " b. tîşt"
  },
  {
    "x": "çît-I (n)",
    "y": " bez"
  },
  {
    "x": "çît û çîtale (n)",
    "y": " b. çît û çîtare (n)"
  },
  {
    "x": "çît û çîtare (n)",
    "y": " bez eşya"
  },
  {
    "x": "çît-II (m)",
    "y": " b. çîte (m)"
  },
  {
    "x": "çîta, -ye",
    "y": " çita"
  },
  {
    "x": "çîtale (n)",
    "y": " b. çîtare (n)"
  },
  {
    "x": "çîtare (n)",
    "y": " dokuma, tekstil, mensucat, manifatura, çitari, çitar"
  },
  {
    "x": "çîte (m)",
    "y": " çit, yemeni (kadınların başlarına bağladıkları tülbent), başörtü, başörtüsü, çekü"
  },
  {
    "x": "çîterotox, -e",
    "y": " yemenici"
  },
  {
    "x": "çîteviraştox, -e",
    "y": " yemenici"
  },
  {
    "x": "çîtik (m)",
    "y": " b. çîtike (m)"
  },
  {
    "x": "çîtike (m)",
    "y": " çit, yemeni (kadınların başlarına bağladıkları tülbent), başörtü, başörtüsü, çekü"
  },
  {
    "x": "çîtikerotox, -e",
    "y": " yemenici"
  },
  {
    "x": "çîtikeviraştox, -e",
    "y": " yemenici "
  },
  {
    "x": "çîtroş, -e",
    "y": " manifaturacı"
  },
  {
    "x": "çîtroşî (m)",
    "y": " manifaturacılık"
  },
  {
    "x": "çîtroşîye (m)",
    "y": " manifaturacılık"
  },
  {
    "x": "çîv-I (n)",
    "y": " zikzak"
  },
  {
    "x": "çîv dayene",
    "y": " zikzak yapmak\r\n~Raye, gilê koyî ra çîv dana resena dere. (Yol, dağın tepesinden zikzak yaparak dereye ulaşıyor.)"
  },
  {
    "x": "çîv-II (n)",
    "y": " kuş sesi"
  },
  {
    "x": "çîv-çîv (n)",
    "y": " kuş sesi"
  },
  {
    "x": "çîv-III (n)",
    "y": " b. çîvî (n)"
  },
  {
    "x": "çîv-çîv (n)",
    "y": " kuş sesi"
  },
  {
    "x": "çîvçîv kerdene",
    "y": " ötmek (serçe vb. kuşlar için) "
  },
  {
    "x": "çîvçîvkerdiş (n)",
    "y": " ötme (serçe vb. kuşlar için)  "
  },
  {
    "x": "çîveçîva xo bîyene",
    "y": " ötüp durmak"
  },
  {
    "x": "çîvik (n)",
    "y": " b. çîvî (n)"
  },
  {
    "x": "çîvin, -e",
    "y": " zikzaklı"
  },
  {
    "x": "çîvî (n)",
    "y": " siğil (odunları yarmakta yararlanılan), kıskı"
  },
  {
    "x": "çîvkin, -e",
    "y": " b. çîvin, -e"
  },
  {
    "x": "çîvnayene",
    "y": " ötmek (serçe vb. kuşlar için) "
  },
  {
    "x": "çîvnayîş (n)",
    "y": " ötme (serçe vb. kuşlar için)  "
  },
  {
    "x": "çîy (n)",
    "y": " b. çî (n)"
  },
  {
    "x": "çîy-mîy (n)",
    "y": " b. çî-mî (n)"
  },
  {
    "x": "çîye (n)",
    "y": " b. keye (n)"
  },
  {
    "x": "çîyek",
    "y": " b. çîyekî"
  },
  {
    "x": "çîyekî",
    "y": " hiçbir şey"
  },
  {
    "x": "çîyer",
    "y": " b. çar-I"
  },
  {
    "x": "çîyerin",
    "y": " b. çarin, -e"
  },
  {
    "x": "çîyê",
    "y": " bir şey"
  },
  {
    "x": "çîyê rê bîyayîş",
    "y": " bir şeye yarama"
  },
  {
    "x": "çîyêk",
    "y": " bir şey"
  },
  {
    "x": "çîyêkî rê bîyayîş",
    "y": " bir şeye yarama"
  },
  {
    "x": "çîyhuşkê komputure (zh) (TE)",
    "y": " donanım"
  },
  {
    "x": "çîynermê komputure (zh) (TE)",
    "y": " yazılım"
  },
  {
    "x": "çîzix (n)",
    "y": " b. xêze (m)"
  },
  {
    "x": "ço-I",
    "y": " (at ve eşek için) deh, dah"
  },
  {
    "x": "ço ço",
    "y": " (at ve eşek için) deh, dah"
  },
  {
    "x": "ço ço kerdene",
    "y": " (at ve eşek için) dehlemek, dahlamek, deh demek, deh etmek"
  },
  {
    "x": "ço kerdene",
    "y": " (at ve eşek için) dehlemek, dahlamek, deh demek, deh etmek"
  },
  {
    "x": "ço-II",
    "y": " b. Çew\r\n~Çoyî niva. (Hiç kimse söylemedi.)"
  },
  {
    "x": "ço-III",
    "y": " b. çi-II"
  },
  {
    "x": "ço wext",
    "y": " b. çi wext"
  },
  {
    "x": "ço-IV (n)",
    "y": " b. çuwe (n), çu (n)"
  },
  {
    "x": "ço-V (n)",
    "y": " b. ko-I (n)"
  },
  {
    "x": "çog (n)",
    "y": " b. çok (n)"
  },
  {
    "x": "çogane (m)",
    "y": " asa, baston, koltuk değneği"
  },
  {
    "x": "çogoni (m)",
    "y": " b. çogane (m)"
  },
  {
    "x": "çogûni (m)",
    "y": " çogane (m)"
  },
  {
    "x": "çok (n)",
    "y": " diz"
  },
  {
    "x": "çok amayene",
    "y": " b. çok ameyene"
  },
  {
    "x": "çok ameyene",
    "y": " diz çökmek¸ dize gelmek, boyun eğmek, itaat etmek"
  },
  {
    "x": "çok bîyayîne",
    "y": " b. çok bîyene"
  },
  {
    "x": "çok bîyene",
    "y": " diz çökmek"
  },
  {
    "x": "(ver de) çok dayene",
    "y": " diz çökmek¸ dize gelmek"
  },
  {
    "x": "çok de",
    "y": " diz boyu (dize kadar olan)"
  },
  {
    "x": "çok rodayene",
    "y": " diz çökmek, dize gelmek, boyun eğmek, itaat etmek"
  },
  {
    "x": "çok rodayêne",
    "y": " b. çok rodayene"
  },
  {
    "x": "çok ronayene",
    "y": " çömelmek"
  },
  {
    "x": "çokan ser o",
    "y": " diz üstü (dizleri yere gelecek biçimde)"
  },
  {
    "x": "çokamayîs (n)",
    "y": " b. çokameyîş (n)"
  },
  {
    "x": "çokameyîş (n)",
    "y": " diz çökme, dize gelme, boyun eğme, itaat etme"
  },
  {
    "x": "çokanseroroniştiş (n)",
    "y": " çöküş "
  },
  {
    "x": "çokbîyayîş (n)",
    "y": " diz çökme"
  },
  {
    "x": "(verde)çokdayîş (n)",
    "y": " diz çökme, dize gelme"
  },
  {
    "x": "çokrodayîs (n)",
    "y": " b. çokrodayîş (n)"
  },
  {
    "x": "çokrodayîş (n)",
    "y": " diz çökme¸ dize gelme, boyun eğme, itaat etme"
  },
  {
    "x": "çokronayîş",
    "y": " çömelme, çömeliş"
  },
  {
    "x": "çokûserronîştîş (n)",
    "y": " b. çokanseroroniştiş (n)"
  },
  {
    "x": "çol (n)",
    "y": " 1)çamurlu yer\r\n~2)kır, yaban\r\n~3)çöl"
  },
  {
    "x": "çol bîyayene",
    "y": " çölleşmek"
  },
  {
    "x": "çolê qumî (n)",
    "y": " kum çölü"
  },
  {
    "x": "çolê xîzî (n)",
    "y": " kum çölü"
  },
  {
    "x": "çolag, -i",
    "y": " b. çulag, -e"
  },
  {
    "x": "çolagey (m)",
    "y": " b. çulagîye (m)"
  },
  {
    "x": "çolagi (m)",
    "y": " b. çulage (m)"
  },
  {
    "x": "çolax, -e",
    "y": " çolak, eli veya kolu sakat olan kişi"
  },
  {
    "x": "çolaxey (m)",
    "y": " b. çolaxîye (m)"
  },
  {
    "x": "çolaxê (m)",
    "y": " b. çolaxî (m)"
  },
  {
    "x": "çolaxî (m)",
    "y": " çolaklık"
  },
  {
    "x": "çolaxîye (m)",
    "y": " çolaklık"
  },
  {
    "x": "çolbîyayîş (n)",
    "y": " çölleşme"
  },
  {
    "x": "çole (m)",
    "y": " b. çale-I (m)"
  },
  {
    "x": "çola fesadîye (m)",
    "y": " b. çala fesadîye (m)"
  },
  {
    "x": "çolegi (m)",
    "y": " b. çorege (m)"
  },
  {
    "x": "çoleki (m)",
    "y": " b. çorege (m)"
  },
  {
    "x": "çolemey (zh)",
    "y": " b. çolemeyî (zh)"
  },
  {
    "x": "çolemeyî (zh)",
    "y": " pestil, yağ ve undan yapılan tatlı bir yemek"
  },
  {
    "x": "çolike (m)",
    "y": " b. çalike (m)"
  },
  {
    "x": "çolîg (n)",
    "y": " b. çewlîg (n)"
  },
  {
    "x": "çom (n)",
    "y": " b. çam (n)"
  },
  {
    "x": "çomçe (n)",
    "y": " b. çemçe-I (n)"
  },
  {
    "x": "çomir (n)",
    "y": " b. çamure (m)"
  },
  {
    "x": "çomirin, -e",
    "y": " b. çamurin, -e"
  },
  {
    "x": "çon",
    "y": " b. çend"
  },
  {
    "x": "çonç-çuwa (n)",
    "y": " b. çûnç-çuwa (n)"
  },
  {
    "x": "çond",
    "y": " b. çend"
  },
  {
    "x": "çonday ... honday ...",
    "y": " b. çend ... hende ..."
  },
  {
    "x": "çone (n)",
    "y": " üzümsüz salkım çöpü (üzümü alınmış salkım artığı)"
  },
  {
    "x": "çongal (n)",
    "y": " b. çengal (n)"
  },
  {
    "x": "çongal kerdene",
    "y": " b. çengal kerdene"
  },
  {
    "x": "çonqal (n)",
    "y": " b. çanqil (n)"
  },
  {
    "x": "çonqil (n)",
    "y": " b. çanqil (n)"
  },
  {
    "x": "çonquel (n)",
    "y": " b. çanqil (n)"
  },
  {
    "x": "çonquwel (n)",
    "y": " b. çanqil (n)"
  },
  {
    "x": "çonte (n)",
    "y": " b. çente (n)"
  },
  {
    "x": "çontey desto (n)",
    "y": " b. çenteyê destan (n)"
  },
  {
    "x": "çontey deston (n)",
    "y": " b. çenteyê destan (n)"
  },
  {
    "x": "çontey paştî (n)",
    "y": " b. çenteyê piştî (n)"
  },
  {
    "x": "çop (n)",
    "y": " çöp"
  },
  {
    "x": "çop-çelax (n)",
    "y": " çerçöp"
  },
  {
    "x": "çoparêkerdox, -e",
    "y": " çöpçü, temizlik işçisi"
  },
  {
    "x": "çopdane (m)",
    "y": " çöp kutusu"
  },
  {
    "x": "çopgeh (n)",
    "y": " çöplük"
  },
  {
    "x": "çopol-I (n)",
    "y": " b. çopole (m)"
  },
  {
    "x": "çopol-II (m)",
    "y": " b. çopole (m)"
  },
  {
    "x": "çopol werdene",
    "y": " b. çopole werdene"
  },
  {
    "x": "çopole (m)",
    "y": " tokat, sille"
  },
  {
    "x": "çopole werdene",
    "y": " tokat yemek "
  },
  {
    "x": "çopolî kerdene",
    "y": " tokatlamak"
  },
  {
    "x": "çopolî piro dayene",
    "y": " tokatlamak"
  },
  {
    "x": "çopolewerdiş (n)",
    "y": " tokat yeme "
  },
  {
    "x": "çopolîkerdiş (n)",
    "y": " tokatlama"
  },
  {
    "x": "çopolîpirodayîş (n)",
    "y": " tokatlama"
  },
  {
    "x": "çopolwerdiş (n)",
    "y": " b. çopolewerdiş (n)"
  },
  {
    "x": "çoptalkerdiş (n)",
    "y": " çöp boşaltma"
  },
  {
    "x": "çopvengkerdiş (n)",
    "y": " çöp boşaltma"
  },
  {
    "x": "çor-I",
    "y": " b. çar-I"
  },
  {
    "x": "çor-II (n)",
    "y": " b. çorr (n)"
  },
  {
    "x": "çor-III (n)",
    "y": " b. çewr-I (n)"
  },
  {
    "x": "çor-IV (n)",
    "y": " b. cor (n)"
  },
  {
    "x": "çoras",
    "y": " b. çewres"
  },
  {
    "x": "çorege (m)",
    "y": " çörek"
  },
  {
    "x": "çoregi (m)",
    "y": " b. çorege (m)"
  },
  {
    "x": "çorek (m)",
    "y": " b. çorege (m)"
  },
  {
    "x": "çoreki (m)",
    "y": " b. çorege (m)"
  },
  {
    "x": "çores",
    "y": " b. çewres"
  },
  {
    "x": "çorin, -e",
    "y": " b. çarin, -e"
  },
  {
    "x": "çorîmena",
    "y": " b. çar hemîne"
  },
  {
    "x": "çorîn, -e",
    "y": " b. çarin, -e"
  },
  {
    "x": "çorma (m)",
    "y": " b. çorme (m)"
  },
  {
    "x": "çorme-I (m)",
    "y": " hayvanlara kışın yedirilmek üzere kesilip ağacın çatallı ana dalları arasına dizilen yaprak yığını"
  },
  {
    "x": "çorme-II (n)",
    "y": " b. çorşme (n)"
  },
  {
    "x": "çormi-II (n)",
    "y": " b. çorşme (n)"
  },
  {
    "x": "çormîş bîyayîne",
    "y": " b. çurmîş bîyene"
  },
  {
    "x": "çormîş kerdene",
    "y": " b. çurmîş kerdene"
  },
  {
    "x": "çormîşbîyayîş (n)",
    "y": " b. çurmîşbîyayîş (n)"
  },
  {
    "x": "çormîşkerdiş (n)",
    "y": " b. çurmîşkerdiş (n)"
  },
  {
    "x": "çorr (n)",
    "y": " 1)salgın hastalık, salgın sayrılık, epidemi\r\n~2)sığır vebası"
  },
  {
    "x": "çorr dekewtene",
    "y": " salgın hastalığa yakalanmak"
  },
  {
    "x": "çorr dekotene",
    "y": " b. çorr dekewtene"
  },
  {
    "x": "çorsme (n)",
    "y": " b. çorşme (n)"
  },
  {
    "x": "çorşme (n)",
    "y": " çevre, dolay, etraf\r\n~çorşmeyo bêcan (n): cansız çevre\r\n~çorşmeyo bêgan (n): cansız çevre"
  },
  {
    "x": "çorşmeyo bêcan (n)",
    "y": " cansız çevre"
  },
  {
    "x": "çorşmeyo bêgan (n)",
    "y": " cansız çevre"
  },
  {
    "x": "çorşmeyî, -ye",
    "y": " çevresel"
  },
  {
    "x": "çortan (n)",
    "y": " keş"
  },
  {
    "x": "çorva (m)",
    "y": " b. şorba (m)"
  },
  {
    "x": "çoş",
    "y": " (eşeğin durması için) çüş"
  },
  {
    "x": "çoşme (n)",
    "y": " b. çorşme (n)"
  },
  {
    "x": "çoşmi (n)",
    "y": " b. çorşme (n)"
  },
  {
    "x": "çot",
    "y": " b. çewt, -e"
  },
  {
    "x": "çot kerdene",
    "y": " b. çewt kerdene"
  },
  {
    "x": "çot û çotvir, -e",
    "y": " b. çewt û çewtvir, -e"
  },
  {
    "x": "çotê (m)",
    "y": " b. çewtî (m)"
  },
  {
    "x": "çotî (m)",
    "y": " b. çewtî (m)"
  },
  {
    "x": "çotkerdiş (n)",
    "y": " b. çewtkerdiş (n)"
  },
  {
    "x": "çotvir, -i",
    "y": " b. çewtvir"
  },
  {
    "x": "çotvirê (m)",
    "y": " b. çewtvirîye (m)"
  },
  {
    "x": "çow",
    "y": " b. çew"
  },
  {
    "x": "çowa (m)",
    "y": " b. çuwe (n), çu (n)"
  },
  {
    "x": "çowder (n)",
    "y": " b. çewder (n)"
  },
  {
    "x": "çowres",
    "y": " b. çewres"
  },
  {
    "x": "çowt, -i",
    "y": " b. çewt, -e"
  },
  {
    "x": "çowt kerdene",
    "y": " b. çewt kerdene"
  },
  {
    "x": "çowt û çowtvir, -e",
    "y": " b. çewt û çewtvir, -e"
  },
  {
    "x": "çowtê (m)",
    "y": " b. çewtî (m)"
  },
  {
    "x": "çowtî (m)",
    "y": " b. çewtî (m)"
  },
  {
    "x": "çowtkerdiş (n)",
    "y": " b. çewtkerdiş (n)"
  },
  {
    "x": "çowtvir, -i",
    "y": " b. çewtvir"
  },
  {
    "x": "çowtvirey (m)",
    "y": " b. çewtvirîye (m)"
  },
  {
    "x": "çoxe (n)",
    "y": " ceket"
  },
  {
    "x": "çoxo (n)",
    "y": " b. çoxe (n)"
  },
  {
    "x": "çoyreke (m)",
    "y": " b. çorege (m)"
  },
  {
    "x": "çoyres",
    "y": " b. çewres"
  },
  {
    "x": "çoyt, -i",
    "y": " b. çewt, -e"
  },
  {
    "x": "çoyt kerdene",
    "y": " b. çewt kerdene"
  },
  {
    "x": "çoyt û çoytvir, -e",
    "y": " b. çewt û çewtvir, -e"
  },
  {
    "x": "çoytê (m)",
    "y": " b. çewtî (m)"
  },
  {
    "x": "çoytî (m)",
    "y": " b. çewtî (m)"
  },
  {
    "x": "çoytkerdiş (n)",
    "y": " b. çewtkerdiş (n)"
  },
  {
    "x": "çoytvir, -e",
    "y": " b. çewtvir"
  },
  {
    "x": "çoytvirî (m)",
    "y": " b. çewtvirîye (m)"
  },
  {
    "x": "çu-I (n)",
    "y": " sopa, değnek"
  },
  {
    "x": "çu-II",
    "y": " b çi-II"
  },
  {
    "x": "çu kes",
    "y": " b. çi kes"
  },
  {
    "x": "çu-III",
    "y": " b. ço-I"
  },
  {
    "x": "çu çu",
    "y": " b. ço ço"
  },
  {
    "x": "çu çu kerdene",
    "y": " b. ço ço kerdene"
  },
  {
    "x": "çu kerdene",
    "y": " b. ço kerdene"
  },
  {
    "x": "çual (n)",
    "y": " b. ciwal (n)"
  },
  {
    "x": "çuçik, -e",
    "y": " b. çûçik, -e"
  },
  {
    "x": "çuegom (m)",
    "y": " çogane (m)"
  },
  {
    "x": "çuegûn (m)",
    "y": " çogane (m)"
  },
  {
    "x": "çuek (n)",
    "y": " b. çok (n)"
  },
  {
    "x": "çuel (n)",
    "y": " b. çol (n)"
  },
  {
    "x": "çuelax",
    "y": " b. çolax, -e"
  },
  {
    "x": "çuelaxê (m)",
    "y": " b. çolaxî (m)"
  },
  {
    "x": "çuerek (m)",
    "y": " b. çorege (m)"
  },
  {
    "x": "çuex (n)",
    "y": " b. çoxe (n)"
  },
  {
    "x": "çuexe (n)",
    "y": " b. çoxe (n)"
  },
  {
    "x": "çuexi (n)",
    "y": " b. çuxe (n)"
  },
  {
    "x": "çufte (n)",
    "y": " b. çifte (n)"
  },
  {
    "x": "çuftexas (n)",
    "y": " b. çiftexas (n)"
  },
  {
    "x": "çuil",
    "y": " b. çul, -e"
  },
  {
    "x": "çuilqaf",
    "y": " b. çulqaf, -e"
  },
  {
    "x": "çuk-I",
    "y": " b. çik"
  },
  {
    "x": "çuk-II",
    "y": " b. çîke, çîyekî"
  },
  {
    "x": "çukreş (n)",
    "y": " b. çûkreş (n)"
  },
  {
    "x": "çukuele (n)",
    "y": " b. çekule (n)"
  },
  {
    "x": "çukuele bestene",
    "y": " b. çekule bestene"
  },
  {
    "x": "çul, -e",
    "y": " aksak"
  },
  {
    "x": "çulag, -e",
    "y": " dokumacı, çulha"
  },
  {
    "x": "çulagey (m)",
    "y": " b. çulagîye (m)"
  },
  {
    "x": "çulagê (m)",
    "y": " b. çulagîye (m)"
  },
  {
    "x": "çulagênî (m)",
    "y": " dokumacılık"
  },
  {
    "x": "çulagi (m)",
    "y": " b. çulage (m)"
  },
  {
    "x": "çulagî (m)",
    "y": " dokumacılık"
  },
  {
    "x": "çulagîye (m)",
    "y": " dokumacılık "
  },
  {
    "x": "çulbeyte (m)",
    "y": " bir üzüm çeşidi"
  },
  {
    "x": "çulbeyti (m)",
    "y": " b. çulbeyte (m)"
  },
  {
    "x": "çule-I (n)",
    "y": " ceviz ve badem kabuğu"
  },
  {
    "x": "çule-II (m)",
    "y": " çalı parçası (küçük ağaç parçası)"
  },
  {
    "x": "çulî (zh)",
    "y": " çalı çırpı"
  },
  {
    "x": "çulf, -e",
    "y": " çıplak"
  },
  {
    "x": "çulfa, -ye",
    "y": " b. çulag, -e"
  },
  {
    "x": "çuli (m)",
    "y": " b. çule-II (m)"
  },
  {
    "x": "çulik-I (n)",
    "y": " 1)çalı parçası (küçük ağaç parçası)\r\n~2)çelik (kısa değnek veya dal)"
  },
  {
    "x": "çulik ronayene",
    "y": " çeliklemek (çelik dikerek ağaç yetiştirme)"
  },
  {
    "x": "çulikî (zh)",
    "y": " çalı çırpı"
  },
  {
    "x": "çulik-II (n)",
    "y": " çük (çocuk dilinde)"
  },
  {
    "x": "çulixe (m)",
    "y": " b. culixe (m)"
  },
  {
    "x": "çuliz (n)",
    "y": " sızıntı (sızan şey)"
  },
  {
    "x": "çulke",
    "y": " b. çunke "
  },
  {
    "x": "çulqaf, -e",
    "y": " aksak, topal"
  },
  {
    "x": "çulux (m)",
    "y": " b. culixe (m)"
  },
  {
    "x": "çume (n)",
    "y": " b. çime (n)"
  },
  {
    "x": "çumkî",
    "y": " b. çunke"
  },
  {
    "x": "çun (nm & s)",
    "y": " b. çin (nm & s)"
  },
  {
    "x": "çun o",
    "y": " b. çin o"
  },
  {
    "x": "çunçilik (n)",
    "y": " b. çemçîlik (n)"
  },
  {
    "x": "çunçelê (m)",
    "y": " b. kengula (m)"
  },
  {
    "x": "çund",
    "y": " b. çend"
  },
  {
    "x": "çunde (n)",
    "y": " göze, akarsuyun çıktığı yer"
  },
  {
    "x": "çungal (n)",
    "y": " b. çengal (n) "
  },
  {
    "x": "çungal kerdene",
    "y": " b. çengal kerdene"
  },
  {
    "x": "çungal piro kerdene",
    "y": " b. çengal piro kerdene"
  },
  {
    "x": "çungil (n)",
    "y": " b. çengal (n)"
  },
  {
    "x": "çungil kerdene",
    "y": " b. çengal kerdene"
  },
  {
    "x": "çungirîyayene",
    "y": " büzülmek"
  },
  {
    "x": "çungirîyayîş (n) ",
    "y": " büzülme"
  },
  {
    "x": "çunke",
    "y": " çünkü"
  },
  {
    "x": "çunki",
    "y": " b. çunke"
  },
  {
    "x": "çunkî",
    "y": " b. çunke"
  },
  {
    "x": "çunqal (n)",
    "y": " b. çanqil (n)"
  },
  {
    "x": "çurane (m)",
    "y": " b. çiraneke (m)"
  },
  {
    "x": "çuraneke (m)",
    "y": " b. çiraneke (m)"
  },
  {
    "x": "çuraneki (m)",
    "y": " b. çiraneke (m)"
  },
  {
    "x": "çurani (m)",
    "y": " b. çiraneke (m)"
  },
  {
    "x": "çuraniki (m)",
    "y": " b. çiraneke (m)"
  },
  {
    "x": "çurezgeh (n)",
    "y": " savak (suyu başka yöne akıtmak için yapılan düzenek)"
  },
  {
    "x": "çurig, -e (n)",
    "y": " çürük "
  },
  {
    "x": "çurigey (m)",
    "y": " b. çurigîye (m)"
  },
  {
    "x": "çurigê (m)",
    "y": " b. çurigî (m)"
  },
  {
    "x": "çurigi (m)",
    "y": " b. çurige (m)"
  },
  {
    "x": "çurigî (m)",
    "y": " çürüklük "
  },
  {
    "x": "çurigîye (m)",
    "y": " çürüklük"
  },
  {
    "x": "çurike (m)",
    "y": " amel, ishal, ötürük, sürgün"
  },
  {
    "x": "çurike estene",
    "y": " b. çurike eştene"
  },
  {
    "x": "çurike eştene",
    "y": " ishal olmak"
  },
  {
    "x": "çurike kewtene",
    "y": " b. çurike eştene"
  },
  {
    "x": "çurimîş bîyayene",
    "y": " b. çurmîş bîyene"
  },
  {
    "x": "çurimîş kerdene",
    "y": " b. çurmîş kerdene"
  },
  {
    "x": "çurimîşbîyayîş (n)",
    "y": " b. çurmîşbîyayîş (n)"
  },
  {
    "x": "çurimîşkerdiş (n)",
    "y": " b. çurmîşkerdiş (n)"
  },
  {
    "x": "çurmîş bîyayêne",
    "y": " b. çurmîş bîyene"
  },
  {
    "x": "çurmîş bîyene",
    "y": " çürümek"
  },
  {
    "x": "çurmîş kerdene",
    "y": " çürütmek"
  },
  {
    "x": "çurmîşbîyaye (n)",
    "y": " çürük"
  },
  {
    "x": "çurmîşbîyayî (m)",
    "y": " çürük"
  },
  {
    "x": "çurmîşbîyayîş (n)",
    "y": " çürüme"
  },
  {
    "x": "çurmîşbîyayîye (m)",
    "y": " çürük"
  },
  {
    "x": "çurmîşkerdiş (n)",
    "y": " çürütme"
  },
  {
    "x": "çurniki (m)",
    "y": " b. çiraneke (m)"
  },
  {
    "x": "çurpî (m)",
    "y": " b. çirpî (m)"
  },
  {
    "x": "çurri (m)",
    "y": " b. çirre (m)"
  },
  {
    "x": "çurtik (n)",
    "y": " 1)ağaçtan yapılma küçük kürek\r\n~2)döven sürerken öküzün dışkısının tahıl içine düşmemesi için arkasına tutulan kürekçik"
  },
  {
    "x": "çurug (n)",
    "y": " b. çurig (n)"
  },
  {
    "x": "çuruge (m)",
    "y": " b. çurige (m)"
  },
  {
    "x": "çurugey (m)",
    "y": " b. çurigîye (m)"
  },
  {
    "x": "çurugî (m)",
    "y": " b. çurigî (m)"
  },
  {
    "x": "çust, -e",
    "y": " dinç, zinde"
  },
  {
    "x": "çuş",
    "y": " b. çoş"
  },
  {
    "x": "çuşi",
    "y": " b. çoş"
  },
  {
    "x": "çut-I (n)",
    "y": " çifte (at, eşek vb. tekmesi)"
  },
  {
    "x": "çut eştene",
    "y": " çifte atmak"
  },
  {
    "x": "çut-II (m)",
    "y": " b. çute-I (m)"
  },
  {
    "x": "çut bi çut",
    "y": " b. çute bi çute"
  },
  {
    "x": "çut-çut",
    "y": " b. çute-çute"
  },
  {
    "x": "çut-III ",
    "y": " b. çitur"
  },
  {
    "x": "çute-I (m)",
    "y": " çift"
  },
  {
    "x": "çute bi çute",
    "y": " çifter çifter"
  },
  {
    "x": "çute-çute",
    "y": " çifter çifter"
  },
  {
    "x": "çute-II (m)",
    "y": " çut-I (n)"
  },
  {
    "x": "çute estene",
    "y": " b. çut eştene"
  },
  {
    "x": "çuter",
    "y": " b. çitur"
  },
  {
    "x": "çuthûmare (m)",
    "y": " çift sayı (2, 4, 6...)"
  },
  {
    "x": "çuti (m)",
    "y": " b. çute (m)"
  },
  {
    "x": "çutir",
    "y": " b. çitur"
  },
  {
    "x": "çutirî",
    "y": " b. çitur"
  },
  {
    "x": "çutîr",
    "y": " b. çitur"
  },
  {
    "x": "çutme (n)",
    "y": " çifte (at, eşek vb. tekmesi)"
  },
  {
    "x": "çutme eştene",
    "y": " çifte atmak"
  },
  {
    "x": "çutme vistene",
    "y": " b. çutme eştene"
  },
  {
    "x": "çutperayîş (n)",
    "y": " durduğu yerden atlama"
  },
  {
    "x": "çutur",
    "y": " b. çitur"
  },
  {
    "x": "çuturî",
    "y": " b. çitur"
  },
  {
    "x": "çuwa (m)",
    "y": " b. çuwe (n), çu (n)"
  },
  {
    "x": "çuwal (n)",
    "y": " b. ciwal (n) "
  },
  {
    "x": "çuwale (m)",
    "y": " b. ciwal (n)"
  },
  {
    "x": "çuwe (n)",
    "y": " 1)sopa, değnek\r\n~2)binanın çatısını taşıması için tavanda yatay olarak kullanılan kalın direk"
  },
  {
    "x": "çuwegom (m)",
    "y": " çogane (m)"
  },
  {
    "x": "çuwereg (m)",
    "y": " b. çorege (m)"
  },
  {
    "x": "çuxa (n)",
    "y": " b. çuxe (n)"
  },
  {
    "x": "çuxe (n)",
    "y": " 1)çuha\r\n~2)b. çoxe (n)"
  },
  {
    "x": "çuxedar, -e",
    "y": " çuhacı, çuhadar"
  },
  {
    "x": "çuxur (n)",
    "y": " b. çixure (m)"
  },
  {
    "x": "çuxur bîyene",
    "y": " b. çixure bîyene"
  },
  {
    "x": "çuxurbîyayîş (n)",
    "y": " b. çixurebîyayîş (n)"
  },
  {
    "x": "çuxure (m)",
    "y": " b. çixure (m)"
  },
  {
    "x": "çuxure bîyene",
    "y": " b. çixure bîyene"
  },
  {
    "x": "çuxurebîyayîş (n)",
    "y": " b. çixurebîyayîş (n)"
  },
  {
    "x": "çuyal (n)",
    "y": " b. ciwal (n)"
  },
  {
    "x": "çuy (m)",
    "y": " b. çuwe (n), çu (n)"
  },
  {
    "x": "çuye (m)",
    "y": " b. çuwe (n), çu (n)"
  },
  {
    "x": "çû",
    "y": " b. ço"
  },
  {
    "x": "çû çû",
    "y": " b. ço ço"
  },
  {
    "x": "çû çû kerdene",
    "y": " b. ço ço kerdene"
  },
  {
    "x": "çû kerdene",
    "y": " b. ço kerdene"
  },
  {
    "x": "çûçik, -e",
    "y": " 1)kuş\r\n~2)serçe"
  },
  {
    "x": "çûk (n)",
    "y": " b. çok (n) "
  },
  {
    "x": "çûkreş (n)",
    "y": " sığırcık"
  },
  {
    "x": "çûlag, -e",
    "y": " b. çulag, -e"
  },
  {
    "x": "çûlagê (m)",
    "y": " b. çulagî (m)"
  },
  {
    "x": "çûlagî (m)",
    "y": " b. çulagî (m)"
  },
  {
    "x": "çûle (n)",
    "y": " b. çule-I (m)"
  },
  {
    "x": "çûleki (m)",
    "y": " b. çorege (m)"
  },
  {
    "x": "çûm (n)",
    "y": " b. çam (n)"
  },
  {
    "x": "çûmir (n)",
    "y": " b. çamure (m)"
  },
  {
    "x": "çûmirîn, -e",
    "y": " b. çamurin, -e"
  },
  {
    "x": "çûmur (n)",
    "y": " b. çamure (m)"
  },
  {
    "x": "çûn-çûwa",
    "y": " b. çûnç-çuwa (n)"
  },
  {
    "x": "çûnç-çiwa",
    "y": " b. çûnç-çuwa (n)"
  },
  {
    "x": "çûnç-çuwa (n)",
    "y": " çelik çomak"
  },
  {
    "x": "çûnçelê (m)",
    "y": " b. çinçolike (m)"
  },
  {
    "x": "çûnçulik (n)",
    "y": " b. çemçîlik (n)"
  },
  {
    "x": "çûngal (n)",
    "y": " b. çengal (n)"
  },
  {
    "x": "çûngal kerdene",
    "y": " b. çengal kerdene"
  },
  {
    "x": "çûngil (n)",
    "y": " b. çengal (n)"
  },
  {
    "x": "çûnqal (m)",
    "y": " b. çanqil (n)"
  },
  {
    "x": "çûnte (n)",
    "y": " b. çente (n)"
  },
  {
    "x": "çûntê destû (n)",
    "y": " b. çenteyê destan (n)"
  },
  {
    "x": "çûntê destûn (n)",
    "y": " b. çenteyê destan (n)"
  },
  {
    "x": "çûntê paştî (n)",
    "y": " b. çenteyê piştî (n)"
  },
  {
    "x": "çûntê quelî (n)",
    "y": " b. çenteyê polî (n)"
  },
  {
    "x": "çûnti (n)",
    "y": " b. çente (n)"
  },
  {
    "x": "çûp (n)",
    "y": " b. çop (n)"
  },
  {
    "x": "çûri (m)",
    "y": " b. çirre (m)-2"
  },
  {
    "x": "DDD"
  },
  {
    "x": "d’ ",
    "y": " b. de"
  },
  {
    "x": "da-I (m)",
    "y": " b. dadî (m)"
  },
  {
    "x": "da-II",
    "y": " b. daha"
  },
  {
    "x": "daane",
    "y": " b. dayene"
  },
  {
    "x": "daax (n)",
    "y": " b. deyax (n)"
  },
  {
    "x": "daax kerdene",
    "y": " b. deyax kerdene"
  },
  {
    "x": "daaxkerdiş (n)",
    "y": " b. deyaxkerdiş (n)"
  },
  {
    "x": "daba (m)",
    "y": " b. dewa (m)"
  },
  {
    "x": "daban-I (n)",
    "y": " dayanma gücü"
  },
  {
    "x": "daban-II (n)",
    "y": " b. taban (n)"
  },
  {
    "x": "dabança (m)",
    "y": " b. dabançe (n)"
  },
  {
    "x": "dabançe (n)",
    "y": " tabanca, pistol"
  },
  {
    "x": "dabar (m)",
    "y": " b. debare (m)"
  },
  {
    "x": "dabax-I (n)",
    "y": " b. tebaq (n)"
  },
  {
    "x": "dabax-II (m)",
    "y": " b. debaxe (m)"
  },
  {
    "x": "dabaxkerdiş (n)",
    "y": " b. debaxekerdiş (n)"
  },
  {
    "x": "dabonçe (n)",
    "y": " b. dabançe (n)"
  },
  {
    "x": "dabûnçe (n)",
    "y": " b. dabançe (n)"
  },
  {
    "x": "dade (m)",
    "y": " b. dadî (m)"
  },
  {
    "x": "dadgehe (m)",
    "y": " mahkeme\r\n~dadgeha bazirganîye (m): ticaret mahkemesi\r\n~dadgeha berze ya temyîzî (m): Yargıtay\r\n~dadgeha ceza ya pêardişî (m): sulh ceza mahkemesi\r\n~dadgeha huqûqîye ya eslî (m): asliye hukuk mahkemesi\r\n~dadgeha îdarîye ya berze (m): danıştay"
  },
  {
    "x": "dadgeha bazirganîye (m)",
    "y": " ticaret mahkemesi"
  },
  {
    "x": "dadgeha berze ya temyîzî (m)",
    "y": " Yargıtay"
  },
  {
    "x": "dadgeha ceza (m)",
    "y": " ceza mahkemesi"
  },
  {
    "x": "dadgeha ceza ya pêardişî (m)",
    "y": " sulh ceza mahkemesi"
  },
  {
    "x": "dadgeha ceza ya werêardişî (m)",
    "y": " sulh ceza mahkemesi "
  },
  {
    "x": "Dadgeha Heqanê Merdiman ya Ewropa (DHME)",
    "y": " Avrupa İnsan Hakları Mahkemesi (AİHM)"
  },
  {
    "x": "dadgeha huqûqîye (m)",
    "y": " hukuk mahkemesi"
  },
  {
    "x": "dadgeha huqûqîye ya eslî (m)",
    "y": " asliye hukuk mahkemesi"
  },
  {
    "x": "dadgeha huqûqîye ya pêardişî (m)",
    "y": " sulh hukuk mahkemesi "
  },
  {
    "x": "dadgeha huqûqîye ya werêardişî (m)",
    "y": " sulh hukuk mahkemesi"
  },
  {
    "x": "dadgeha îdarîye (m)",
    "y": " idari mahkeme"
  },
  {
    "x": "dadgeha îdarîye ya berze (m)",
    "y": " danıştay"
  },
  {
    "x": "dadgeha pêardişî (m)",
    "y": " sulh mahkemesi"
  },
  {
    "x": "dadgeha temyîzî (m)",
    "y": " temyiz mahkemesi"
  },
  {
    "x": "dadgeha tîcaretî (m)",
    "y": " ticaret mahkemesi"
  },
  {
    "x": "dadgeha werêardişî (m)",
    "y": " sulh mahkemesi"
  },
  {
    "x": "dadgehkerdiş (n)",
    "y": " muhakeme"
  },
  {
    "x": "dadger, -e",
    "y": " hâkim, yargıç"
  },
  {
    "x": "dadgerîye (m)",
    "y": " hâkimlik, yargıçlık"
  },
  {
    "x": "dadî (m)",
    "y": " 1)anne, ana\r\n~dadîya şitî: sütanne, sütana\r\n~2)ana arı"
  },
  {
    "x": "dadîya şitî",
    "y": " sütanne, sütana"
  },
  {
    "x": "dadîya xo ra bîyene",
    "y": " doğmak"
  },
  {
    "x": "dadê",
    "y": " anne (hitap hali)"
  },
  {
    "x": "dadîke (m)",
    "y": " dadı"
  },
  {
    "x": "dadîşahî, -ye",
    "y": " anaerkil"
  },
  {
    "x": "dadîşahîye (n)",
    "y": " anaerkillik"
  },
  {
    "x": "dadîyey (m)",
    "y": " b. dadîyîye (m)"
  },
  {
    "x": "dadîyê (m)",
    "y": " b. dadîyîye (m)"
  },
  {
    "x": "dadîyîye (m)",
    "y": " analık"
  },
  {
    "x": "dadmend, -e",
    "y": " adaletli, adil"
  },
  {
    "x": "dadmendane",
    "y": " adilane"
  },
  {
    "x": "dadmendî (m)",
    "y": " adaletlilik, adillik"
  },
  {
    "x": "dadmendîye (m)",
    "y": " adaletlilik, adillik"
  },
  {
    "x": "dadname (n) (huq.)",
    "y": " ilam\r\n~dadnameyê werasetî (n): veraset ilamı"
  },
  {
    "x": "dadnameyê werasetî (n)",
    "y": " veraset ilamı"
  },
  {
    "x": "dadnamedar (n) (huq.)",
    "y": " ilamlı"
  },
  {
    "x": "dadza (n)",
    "y": " b. datîza (n)"
  },
  {
    "x": "daene",
    "y": " b. dayene"
  },
  {
    "x": "daêna",
    "y": " b. daha-3"
  },
  {
    "x": "dafe (m)",
    "y": " tuzak"
  },
  {
    "x": "dafike (m)",
    "y": " tuzak"
  },
  {
    "x": "daha",
    "y": " 1)artık\r\n~2)henüz, hâlâ\r\n~3)daha"
  },
  {
    "x": "dahana",
    "y": " b. daha-3"
  },
  {
    "x": "dahanî",
    "y": " b. daha-3"
  },
  {
    "x": "dahêna",
    "y": " b. daha-3"
  },
  {
    "x": "dahîna",
    "y": " b. daha-1, daha-3"
  },
  {
    "x": "dahînî",
    "y": " b. daha-3"
  },
  {
    "x": "dahwa (m)",
    "y": " b. dewa (m)"
  },
  {
    "x": "daîm",
    "y": " b. daîma"
  },
  {
    "x": "daîma",
    "y": " daima, hep, her zaman"
  },
  {
    "x": "daîmî, -ye",
    "y": " 1)sürekli, daimi, devamlı\r\n~2)baştan sona (zr)"
  },
  {
    "x": "daîmî bîyene",
    "y": " süreklileşmek"
  },
  {
    "x": "daîmî kerdene",
    "y": " süreklileştirmek"
  },
  {
    "x": "daîmîbîyayîş (n)",
    "y": " süreklileşme"
  },
  {
    "x": "daîmîkerdiş (n)",
    "y": " süreklileştirme"
  },
  {
    "x": "daîmîyî (m)",
    "y": " devamlılık, süreklilik"
  },
  {
    "x": "daîre (m)",
    "y": " b. dayîre (n)"
  },
  {
    "x": "daîre (n)",
    "y": " b. dayîre (n)"
  },
  {
    "x": "dajnayene",
    "y": " b. dejnayene"
  },
  {
    "x": "Dakar (n)",
    "y": " Dakar"
  },
  {
    "x": "dakbîlane (m)",
    "y": " ukala"
  },
  {
    "x": "dake (m)",
    "y": " b. dayîke (m)"
  },
  {
    "x": "dakile (m)",
    "y": " annecik "
  },
  {
    "x": "dakilê",
    "y": " anneciğim (hitap hali) "
  },
  {
    "x": "Dakka (m)",
    "y": " Dakka"
  },
  {
    "x": "daktîlo (n)",
    "y": " daktilo"
  },
  {
    "x": "daktîlograf, -e",
    "y": " daktilograf"
  },
  {
    "x": "dale (m)",
    "y": " b. dole (m)"
  },
  {
    "x": "daleqîyayîne",
    "y": " b. daliqîyayene "
  },
  {
    "x": "daleqîyayîş (n)",
    "y": " b. daliqîyayîş (n)"
  },
  {
    "x": "daleqnayene",
    "y": " b. daliqnayene"
  },
  {
    "x": "daleqnayîş (n)",
    "y": " b. daliqnayîş (n)"
  },
  {
    "x": "dali (m)",
    "y": " b. dole (m)"
  },
  {
    "x": "daliqîyayene",
    "y": " asılmak (idam edilmek)"
  },
  {
    "x": "daliqîyayîş (n)",
    "y": " asılma (idam edilme)"
  },
  {
    "x": "daliqnaya (m)",
    "y": " asılı"
  },
  {
    "x": "daliqnaye (n)",
    "y": " asılı"
  },
  {
    "x": "daliqnayene",
    "y": " asmak (idam etmek)"
  },
  {
    "x": "daliqnayîş (n)",
    "y": " 1)askı (asma işi)\r\n~2)asma (idam etme)"
  },
  {
    "x": "daliqnayîye (m)",
    "y": " asılı"
  },
  {
    "x": "daloqî (m)",
    "y": " b. darlox (n)"
  },
  {
    "x": "dalpa (m)",
    "y": " b. dilope (m)"
  },
  {
    "x": "daluex (n)",
    "y": " b. darlox (n)"
  },
  {
    "x": "dalxa kerdene",
    "y": " b. delxe kerdene"
  },
  {
    "x": "dam (m)",
    "y": " b. dame-I (n)"
  },
  {
    "x": "dam-dam",
    "y": " 1)Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında çocukların evleri dolaşarak topladıkları yiyecek türünden hediyeler\r\n~2)Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında oynanan geleneksel, teatral bir eğlence oyunu sırasında söylenen tekerleme "
  },
  {
    "x": "dam-dam gêrayene",
    "y": " Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında evleri dolaşarak çerez, kuru yemiş vb. toplamak"
  },
  {
    "x": "dama-I",
    "y": " b. dima"
  },
  {
    "x": "dama (m)-II",
    "y": " b. dame-I (n)"
  },
  {
    "x": "damançe (n)",
    "y": " b. dabançe (n)"
  },
  {
    "x": "damare (m)",
    "y": " damar"
  },
  {
    "x": "damari (m)",
    "y": " b. damare (m)"
  },
  {
    "x": "damarî (m)",
    "y": " b. damêrrî (m), dêmarrî (m)"
  },
  {
    "x": "damarrî (m)",
    "y": " b. damêrrî (m), dêmarrî (m)"
  },
  {
    "x": "dame-I (n)",
    "y": " 1)dama\r\n~2)dama oyununda, karşıdaki son sıraya ulaşıp oyunda en avantajlı duruma ulaşan taş"
  },
  {
    "x": "dame-II (m)",
    "y": " tuzak"
  },
  {
    "x": "dame ronayene",
    "y": " tuzak kurmak"
  },
  {
    "x": "damêrî (m)",
    "y": " b. damêrrî (m)"
  },
  {
    "x": "damêrîye (m)",
    "y": " b. damêrrî (m)"
  },
  {
    "x": "damêrrî (m)",
    "y": " üvey anne, analık, annelik"
  },
  {
    "x": "damiş bîyayeni",
    "y": " b. deyanmîş bîyene"
  },
  {
    "x": "damişbîyayîş (n)",
    "y": " b. deyanmîşbîyayîş (n)"
  },
  {
    "x": "damîrî (m)",
    "y": " b. damêrrî (m), dêmarrî (m)"
  },
  {
    "x": "damîs bîyayene",
    "y": " b. deyanmîş bîyene"
  },
  {
    "x": "damîsbîyayîş (n)",
    "y": " b. deyanmîşbîyayîş (n)"
  },
  {
    "x": "damîş bîyayene",
    "y": " b. deyanmîş bîyene"
  },
  {
    "x": "damîşbîyayîş (n)",
    "y": " b. deyanmîşbîyayîş (n)"
  },
  {
    "x": "damûnçe (n)",
    "y": " b. dabançe (n)"
  },
  {
    "x": "damxe (m)",
    "y": " damga"
  },
  {
    "x": "damxe piro dayene",
    "y": " damgalamak"
  },
  {
    "x": "damxeyin, -e",
    "y": " damgalı"
  },
  {
    "x": "dan (n)",
    "y": " b. danî (zh)"
  },
  {
    "x": "danê sanî (n)",
    "y": " b. danê şanî (n)"
  },
  {
    "x": "danê sodirî (n)",
    "y": " b. danê şodirî (n)"
  },
  {
    "x": "danê sonî (n)",
    "y": " b. danê şanî (n)"
  },
  {
    "x": "danê şanî (n)",
    "y": " öğleden sonra"
  },
  {
    "x": "danê şewdirî (n)",
    "y": " öğleden önce"
  },
  {
    "x": "danê şodirî (n)",
    "y": " öğleden önce"
  },
  {
    "x": "danequr (n)",
    "y": " b. darnequr (n)"
  },
  {
    "x": "dang (n)",
    "y": " b. teng (n)"
  },
  {
    "x": "danga (m)",
    "y": " kışın hayvanlara yem verilen bina dışındaki yer"
  },
  {
    "x": "danî (zh)",
    "y": " hedik, yenmekte veya bulgur yapmakta kullanılan kaynatılmış buğday"
  },
  {
    "x": "Danîmarka (m)",
    "y": " Danimarka"
  },
  {
    "x": "dans (n)",
    "y": " oyun, dans"
  },
  {
    "x": "dansîmetre (n)",
    "y": " yoğunlukölçer, dansimetre"
  },
  {
    "x": "dansor (n)",
    "y": " dansör"
  },
  {
    "x": "dansoze (m)",
    "y": " dansöz"
  },
  {
    "x": "danug (n)",
    "y": " b. danî (zh)"
  },
  {
    "x": "danû (n)",
    "y": " b. danî (zh)"
  },
  {
    "x": "danûg (n)",
    "y": " b. danî (zh)"
  },
  {
    "x": "dapîr (m)",
    "y": " b. dapîre (m)"
  },
  {
    "x": "dapîre (m)",
    "y": " nine, babaanne, anneanne, büyükana, büyükanne "
  },
  {
    "x": "dapîreke (m)",
    "y": " 1)büyükannecik\r\n~2)uğurböceği"
  },
  {
    "x": "dapîreki (m)",
    "y": " b. dapîreke (m)"
  },
  {
    "x": "dapîri (m)",
    "y": " b. dapîre (m)"
  },
  {
    "x": "daqoçe (m)",
    "y": " beştaş [oyunu]"
  },
  {
    "x": "daqoq (n)",
    "y": " tokmak, tokaç"
  },
  {
    "x": "daqoqkerdiş (n)",
    "y": " tahıl ürünlerini tokaçla döverek sapından ayırma"
  },
  {
    "x": "daqueç (m)",
    "y": " b. daqoçe (m)"
  },
  {
    "x": "daqueçi (m)",
    "y": " b. daqoçe (m)"
  },
  {
    "x": "daqueq (n)",
    "y": " b. daqoq (n)"
  },
  {
    "x": "daqueqkerdiş (n)",
    "y": " b. daqoqkerdiş (n)"
  },
  {
    "x": "daquweçi (m)",
    "y": " b. daqoçe (m)"
  },
  {
    "x": "dar (m)",
    "y": " b. dare (m)"
  },
  {
    "x": "dar erzîyayene",
    "y": " b. ra dar erzîyayene"
  },
  {
    "x": "dar eştene",
    "y": " b. ra dar eştene"
  },
  {
    "x": "dar û ber",
    "y": " ağaçlık"
  },
  {
    "x": "darê cixarî (n)",
    "y": " çubuk (uzun ağızlık)"
  },
  {
    "x": "darê dinge (n)",
    "y": " havaneli, soku tokmağı, dibek tokmağı"
  },
  {
    "x": "darê dingî (n)",
    "y": " b. darê dinge (n)"
  },
  {
    "x": "darê goştî (n)",
    "y": " üzerinde et dövülen kütük"
  },
  {
    "x": "darê kaşane (n)",
    "y": " üzerine bağlanan yükü, çekilmek suretiyle taşımakta kullanılan sırık"
  },
  {
    "x": "darê kaşûne (n)",
    "y": " b. darê kaşane (n)"
  },
  {
    "x": "dara (m)",
    "y": " dara, abra"
  },
  {
    "x": "Dara Hênî (m)",
    "y": " Genç"
  },
  {
    "x": "darax (n)",
    "y": " düzey, seviye\r\n~O daraxê to ra nîyo. (O senin düzeyinde değildir.)"
  },
  {
    "x": "daraxe (m)",
    "y": " b. darax (n)"
  },
  {
    "x": "darb (m)",
    "y": " b. darbe (m)"
  },
  {
    "x": "darbe (m)",
    "y": " 1)yara\r\n~2)b. derbe (m)"
  },
  {
    "x": "darba mergî (m)",
    "y": " b. derba mergî (m)"
  },
  {
    "x": "darbest (n)",
    "y": " sedye\r\n~darbesto gêrok (n): portatif sedye"
  },
  {
    "x": "darbesto gêrok (n)",
    "y": " portatif sedye"
  },
  {
    "x": "darbesto portatîf (n)",
    "y": " portatif sedye"
  },
  {
    "x": "darbesto seyar (n)",
    "y": " portatif sedye"
  },
  {
    "x": "darbi (m)",
    "y": " b. darbe (m)"
  },
  {
    "x": "darbica (m)",
    "y": " yaralı"
  },
  {
    "x": "darbice (n)",
    "y": " yaralı"
  },
  {
    "x": "darbice bîyayene",
    "y": " b. darbice bîyene"
  },
  {
    "x": "darbice bîyene",
    "y": " yaralanmak"
  },
  {
    "x": "darbicebîyayîş (n)",
    "y": " yaralanma"
  },
  {
    "x": "darbicê (m)",
    "y": " b. darbica (m)"
  },
  {
    "x": "darbici (n)",
    "y": " yaralı"
  },
  {
    "x": "darbije (n)",
    "y": " b. darbice (n)"
  },
  {
    "x": "darbir (n)",
    "y": " bıçkı (büyük testere) "
  },
  {
    "x": "darbna",
    "y": " b. derbna"
  },
  {
    "x": "darçe (n)",
    "y": " tokaç"
  },
  {
    "x": "darçi (n)",
    "y": " b. darçe (n)"
  },
  {
    "x": "darçin (n)",
    "y": " b. darçîn (n)"
  },
  {
    "x": "darçîn (n)",
    "y": " tarçın"
  },
  {
    "x": "dard (n)",
    "y": " b. derd (n)"
  },
  {
    "x": "darda bîyayene",
    "y": " b. darde bîyene"
  },
  {
    "x": "darda kerdene",
    "y": " b. darde kerdene"
  },
  {
    "x": "dardabîyayîş (n)",
    "y": " b. dardebîyayîş (n)"
  },
  {
    "x": "dardakerdiş (n)",
    "y": " b. dardekerdiş (n)"
  },
  {
    "x": "darde bîyene",
    "y": " asılmak (idam edilmek)"
  },
  {
    "x": "darde kerdene",
    "y": " asmak (idam etmek) "
  },
  {
    "x": "dardebîyayîş (n)",
    "y": " asılma (idam edilme)"
  },
  {
    "x": "dardekerda (m)",
    "y": " asılı"
  },
  {
    "x": "dardekerde (n)",
    "y": " asılı"
  },
  {
    "x": "dardekerdiş (n)",
    "y": " 1)asma (idam etme)\r\n~2) askı (asma işi)\r\n~3)askıya çıkarma (evrak vb. için)"
  },
  {
    "x": "dardekerdîye (m)",
    "y": " asılı"
  },
  {
    "x": "dardêze (m)",
    "y": " hayvanlara kışın yedirilmek üzere kesilip bir ağacın etrafında üst üste dizilen büyük yaprak yığını"
  },
  {
    "x": "dardêzi (m)",
    "y": " b. dardêze (m)"
  },
  {
    "x": "dardi bîyayîne",
    "y": " b. darde bîyene"
  },
  {
    "x": "dardi kerdene",
    "y": " b. darde kerdene"
  },
  {
    "x": "dardibîyayîş (n)",
    "y": " b. dardebîyayîş (n)"
  },
  {
    "x": "dardikerdiş (n)",
    "y": " b. dardekerdiş (n)"
  },
  {
    "x": "dardin, -i",
    "y": " b. derdin, -e"
  },
  {
    "x": "dardkerdiş (n)",
    "y": " b. dardekerdiş (n)"
  },
  {
    "x": "darduwar (n)",
    "y": " taş duvara yatay olarak boydan boya yerleştirilmiş kalın direk\r\n~kulêrê dare (n): ağaçta kurtçukların bulunduğu yuva"
  },
  {
    "x": "dare-I (m)",
    "y": " ağaç\r\n~dara qizbane (m): çitlembik ağacı, melengiç ağacı\r\n~darevelge (m): hayvanlara kışın yedirilmek üzere kesilip ağacın çatallı ana dalları arasına dizilen yaprak yığını"
  },
  {
    "x": "dara çamî (m)",
    "y": " çam ağacı"
  },
  {
    "x": "dara murîye (m)",
    "y": " b. murîyêre (m)"
  },
  {
    "x": "dara mûrîye (m)",
    "y": " b. murîyêre (m)"
  },
  {
    "x": "dara qizbane (m)",
    "y": " çitlembik ağacı, melengiç ağacı"
  },
  {
    "x": "dara qizwane (m)",
    "y": " b. dara qizbane (m)"
  },
  {
    "x": "dara velgî (m)",
    "y": " b. darevelge (m)"
  },
  {
    "x": "dare dekerdene",
    "y": " ağaç dikmek"
  },
  {
    "x": "dare denayene",
    "y": " ağaç dikmek"
  },
  {
    "x": "dare ronayene",
    "y": " ağaç dikmek"
  },
  {
    "x": "daro qizvan (n)",
    "y": " b. dara qizbane (m)"
  },
  {
    "x": "kulêrê dare (n)",
    "y": " ağaçta kurtçukların bulunduğu yuva"
  },
  {
    "x": "dare-II (m)",
    "y": " b. dara (m)"
  },
  {
    "x": "daredekerdiş (n)",
    "y": " ağaç dikme"
  },
  {
    "x": "daredenayîş (n)",
    "y": " ağaç dikme"
  },
  {
    "x": "darence (m)",
    "y": " b. derenca (m)"
  },
  {
    "x": "dareronayîş (n)",
    "y": " ağaç dikme"
  },
  {
    "x": "darerzîyayîş (n)",
    "y": " b. radarerzîyayîş (n)"
  },
  {
    "x": "dareştiş (n)",
    "y": " b. radareştiş (n)"
  },
  {
    "x": "darevelge (m)",
    "y": " hayvanlara kışın yedirilmek üzere kesilip ağacın çatallı ana dalları arasına dizilen yaprak yığını"
  },
  {
    "x": "darey (m)",
    "y": " tahra "
  },
  {
    "x": "Darey Yenî (m)",
    "y": " b. Dara Hênî (m)"
  },
  {
    "x": "darê-I(m)",
    "y": " b. darey (m)"
  },
  {
    "x": "darê-II",
    "y": " b. derê"
  },
  {
    "x": "Darê Hênî (m)",
    "y": " b. Dara Hênî (m)"
  },
  {
    "x": "Darê Yenî (m)",
    "y": " b. Dara Hênî (m)"
  },
  {
    "x": "Darê Yênî (m)",
    "y": " b. Dara Hênî (m)"
  },
  {
    "x": "darên, -e",
    "y": " ahşap"
  },
  {
    "x": "darên (n)",
    "y": " tekne, tahtadan yapılmış derin kap"
  },
  {
    "x": "darênd (n)",
    "y": " b. darên (n) "
  },
  {
    "x": "darg, -i",
    "y": " b. derg, -e"
  },
  {
    "x": "darga",
    "y": " b. darax (n)"
  },
  {
    "x": "dargame (m)",
    "y": " döven ile boyunduruk arasındaki direk"
  },
  {
    "x": "dargey (m)",
    "y": " b. dergî (m)"
  },
  {
    "x": "dargeya pêle (m)",
    "y": " b. dergîya pêlî (m)"
  },
  {
    "x": "dargopik (n)",
    "y": " tokmak"
  },
  {
    "x": "darguepik (n)",
    "y": " b. dargopik (n)"
  },
  {
    "x": "darhewan (n)",
    "y": " havaneli, soku tokmağı, dibek tokmağı "
  },
  {
    "x": "dari-I (m)",
    "y": " b. dare (m)"
  },
  {
    "x": "dari-II (n)",
    "y": " b. daru (n)"
  },
  {
    "x": "darik (n)",
    "y": " değnek\r\n~darikê çila (n): çıralık"
  },
  {
    "x": "darikê çila (n)",
    "y": " çıralık"
  },
  {
    "x": "darin, -e",
    "y": " ağaçlı"
  },
  {
    "x": "darin kerdene",
    "y": " ağaçlandırmak"
  },
  {
    "x": "darinkerdiş (n)",
    "y": " ağaçlandırma"
  },
  {
    "x": "daristan (n)",
    "y": " orman"
  },
  {
    "x": "daristanin (n)",
    "y": " ormanlık"
  },
  {
    "x": "daritene",
    "y": " dayamak (yaslamak)\r\n~Piştîya xo dêsî darite. (Sırtını duvara dayadı.)"
  },
  {
    "x": "daritiş (n)",
    "y": " dayama (yaslama)  "
  },
  {
    "x": "darî-I (n)",
    "y": " b. daru (n)"
  },
  {
    "x": "darî-II (m)",
    "y": " b. darey (m)"
  },
  {
    "x": "darîye (m)",
    "y": " b. darey (m)"
  },
  {
    "x": "darkuetik (n)",
    "y": " b. darkutik (n)"
  },
  {
    "x": "darkurtike (m)",
    "y": " b. darkutik (n)"
  },
  {
    "x": "darkut (n)",
    "y": " havaneli, soku tokmağı, dibek tokmağı"
  },
  {
    "x": "darkutik (n)",
    "y": "1)havaneli, soku tokmağı, dibek tokmağı\r\n~2)ağaçkakan"
  },
  {
    "x": "darloq (n)",
    "y": " b. darlox (n)"
  },
  {
    "x": "darlox (n)",
    "y": " yuvağı çekmek için kullanılan çatallı araç"
  },
  {
    "x": "darluex (n)",
    "y": " b. darlox (n)"
  },
  {
    "x": "dar-mal (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "darmase (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "darmasi (n)",
    "y": " b. darmase (n)"
  },
  {
    "x": "darnaquçe (m)",
    "y": " tahterevalli, çöğüncek"
  },
  {
    "x": "darnaquçke (m)",
    "y": " b. darnaquçe (m)"
  },
  {
    "x": "darneqo (n)",
    "y": " b. darnequr (n)"
  },
  {
    "x": "darnequr (n)",
    "y": " ağaçkakan"
  },
  {
    "x": "darniqor (n)",
    "y": " b. darnequr (n)"
  },
  {
    "x": "darpêç (n)",
    "y": " toyaka"
  },
  {
    "x": "darqanik (n)",
    "y": " ağaç sakızı, reçine"
  },
  {
    "x": "darquepik (n)",
    "y": " b. dargopik (n)"
  },
  {
    "x": "darsak (n)",
    "y": " tapan ağacı"
  },
  {
    "x": "darsaya (m)",
    "y": " b. deresaya (m), deresayîye (m)"
  },
  {
    "x": "darsaye (n)",
    "y": " b. deresaye (n)"
  },
  {
    "x": "darsayene",
    "y": " b. deresayene"
  },
  {
    "x": "darsayîş (n)",
    "y": " b. deresayîş (n)"
  },
  {
    "x": "darsê (m)",
    "y": " b. sîdare (m)"
  },
  {
    "x": "darsipe (m)",
    "y": " akçakavak, akkavak, Hollanda kavağı"
  },
  {
    "x": "darsî-I (n)",
    "y": " b. dasî (n)"
  },
  {
    "x": "darsî-II (m)",
    "y": " b. sîdare (m)"
  },
  {
    "x": "darsnayene",
    "y": " b. deresnayene"
  },
  {
    "x": "darsnayîş (n)",
    "y": " b. deresnayîş (n)"
  },
  {
    "x": "darsoq (n)",
    "y": " tokaç"
  },
  {
    "x": "darsoqî (n)",
    "y": " havaneli, soku tokmağı, dibek tokmağı"
  },
  {
    "x": "daru (n)",
    "y": " ilaç, derman"
  },
  {
    "x": "daru eştene",
    "y": " ilaçlamak"
  },
  {
    "x": "daru kerdene",
    "y": " ilaçlamak"
  },
  {
    "x": "daru vistene",
    "y": " b. daru eştene"
  },
  {
    "x": "daruyê çoregan (n)",
    "y": " çörek otu, çöre otu"
  },
  {
    "x": "darueştiş (n)",
    "y": " ilaçlama"
  },
  {
    "x": "darueyştiş (n)",
    "y": " b. darueştiş (n)"
  },
  {
    "x": "darukar, -e",
    "y": " eczacı"
  },
  {
    "x": "darukerdiş (n)",
    "y": " ilaçlama"
  },
  {
    "x": "daruvistiş (n)",
    "y": " b. darueştiş (n)"
  },
  {
    "x": "darûberin kerdene",
    "y": " ağaçlandırmak"
  },
  {
    "x": "darûberinkerdiş (n)",
    "y": " ağaçlandırma"
  },
  {
    "x": "darûdormare (n)",
    "y": " b. derûdorme (n)"
  },
  {
    "x": "darûdûmare (n)",
    "y": " b. derûdorme (n)"
  },
  {
    "x": "darv (m)",
    "y": " b. darbe (m)"
  },
  {
    "x": "darvi (m)",
    "y": " b. darbe (m)"
  },
  {
    "x": "daryen (n)",
    "y": " b. darên (n)"
  },
  {
    "x": "darze (n)",
    "y": " b. derze (n)"
  },
  {
    "x": "darze piro nayene",
    "y": " b. derze piro nayene"
  },
  {
    "x": "darze wegirewtene",
    "y": " b. derze wegirewtene"
  },
  {
    "x": "darzey çarron (n)",
    "y": " b. derzeyê çaran"
  },
  {
    "x": "darzîni (m)",
    "y": " b. derzîne (m)"
  },
  {
    "x": "das (n)",
    "y": " b. dasî (n)"
  },
  {
    "x": "dasi (n)",
    "y": " b. dasî (n)"
  },
  {
    "x": "dasik (n)",
    "y": " 1)sapçık (buğday vb. bitkiler için)\r\n~2)çöp"
  },
  {
    "x": "dasî (n)",
    "y": " 1)kılçık (başak için)\r\n~2)çopra (balık kılçığı), kılçık (balık için)\r\n~3)sap (bitki için)"
  },
  {
    "x": "dasîy masî (n)",
    "y": " b. dasîyê masî (n) "
  },
  {
    "x": "dasîyê masî (n)",
    "y": " kılçık (balık kılçığı)"
  },
  {
    "x": "dasî (m)",
    "y": " b. dasî (n)"
  },
  {
    "x": "dasok (n)",
    "y": " çopra (balık kılçığı), kılçık (balık kılçığı)"
  },
  {
    "x": "dasoke (m)",
    "y": " b. dasok (n)"
  },
  {
    "x": "daş (n)",
    "y": " b. daşir (n)"
  },
  {
    "x": "daşir (n)",
    "y": " yüznumara, ayakyolu, abdesthane, apteshane, hela, kademhane, kenef, memişhane, tuvalet"
  },
  {
    "x": "daşr (n)",
    "y": " b. daşir"
  },
  {
    "x": "daşt-I",
    "y": " b. wet"
  },
  {
    "x": "daşt a",
    "y": " b. wet ra"
  },
  {
    "x": "naşt û daşt",
    "y": " b. nat û wet"
  },
  {
    "x": "naşt-daşt",
    "y": " b. nat-wet"
  },
  {
    "x": "daşt-II (n)",
    "y": " b. duşt (n)"
  },
  {
    "x": "daşta",
    "y": " b. weta"
  },
  {
    "x": "daştên, -i",
    "y": " b. wetên, -e"
  },
  {
    "x": "daşûrxûne (n)",
    "y": " b. daşir (n)"
  },
  {
    "x": "dat (n)",
    "y": " amca, emmi"
  },
  {
    "x": "dato",
    "y": " amca (hitap hali)"
  },
  {
    "x": "data (n)",
    "y": " b. dat (n)"
  },
  {
    "x": "databas (n) (TE)",
    "y": " veritabanı, database"
  },
  {
    "x": "datcinî (m)",
    "y": " amca karısı, yenge (amca karısı)"
  },
  {
    "x": "datîza (n)",
    "y": " amca oğlu, amcazade"
  },
  {
    "x": "datkeyna (m)",
    "y": " b. datkêna (m)"
  },
  {
    "x": "datkêna (m)",
    "y": " amca kızı, amcazade"
  },
  {
    "x": "datza (n)",
    "y": " b. datîza (n)"
  },
  {
    "x": "davacer",
    "y": " b. devacêr"
  },
  {
    "x": "davacêr",
    "y": " b. devacêr"
  },
  {
    "x": "davacor",
    "y": " b. devacor"
  },
  {
    "x": "davadev",
    "y": " b. devadev"
  },
  {
    "x": "davadêv",
    "y": " b. devadev"
  },
  {
    "x": "davançe (n)",
    "y": " b. dabançe (n)"
  },
  {
    "x": "davare (m)",
    "y": " b. debare (m)"
  },
  {
    "x": "davax (n)",
    "y": " b. tebaq (n)"
  },
  {
    "x": "daw (m)",
    "y": " b. dewe (m)"
  },
  {
    "x": "dawa (m)",
    "y": " b. dewa (m)"
  },
  {
    "x": "dawa veradîyayîşî (m)",
    "y": " b. dewaya veradîyayîşî (m)"
  },
  {
    "x": "dawar, -e",
    "y": " b. dewar, -e"
  },
  {
    "x": "dawax (n)",
    "y": " b. tebaq (n)"
  },
  {
    "x": "dawayin, -i",
    "y": " b. dewayin, -e"
  },
  {
    "x": "dawet (n)",
    "y": " davet"
  },
  {
    "x": "dawet kerdene",
    "y": " davet etmek"
  },
  {
    "x": "dawetbîyaya (m)",
    "y": " davetli"
  },
  {
    "x": "dawetbîyaye (n)",
    "y": " davetli"
  },
  {
    "x": "dawete (m)",
    "y": " b. dawet (n)"
  },
  {
    "x": "dawetkerdiş (n)",
    "y": " davet etme"
  },
  {
    "x": "dawetname (n)",
    "y": " davetname, davetiye"
  },
  {
    "x": "dawil (n)",
    "y": " b. dawul (n)"
  },
  {
    "x": "dawil û zurna",
    "y": " b. dawul û zirna"
  },
  {
    "x": "dawil-zurna",
    "y": " b. dawul-zirna"
  },
  {
    "x": "dawili (m)",
    "y": " b. dawul (n)"
  },
  {
    "x": "dawisnayîne",
    "y": " b. dewisnayene"
  },
  {
    "x": "dawisnayîş (n)",
    "y": " b. dewisnayîş (n)"
  },
  {
    "x": "dawsinayîş (n)",
    "y": " b. dewisnayîş (n)"
  },
  {
    "x": "dawul (n)",
    "y": " davul"
  },
  {
    "x": "dawul û zirna",
    "y": " çalgı çağanak "
  },
  {
    "x": "dawul-zurna",
    "y": " b. dawul-zirna"
  },
  {
    "x": "dawulbaz, -e",
    "y": " davulcu"
  },
  {
    "x": "dawule (m)",
    "y": " b. dawul (n)"
  },
  {
    "x": "dawuli (m)",
    "y": " b. dawul (n)"
  },
  {
    "x": "dax (n)",
    "y": " (kızgın bir demirle vurulan damga) dağ "
  },
  {
    "x": "dax kerdene",
    "y": " dağlamak"
  },
  {
    "x": "daxdar, -e",
    "y": " (dağlanmış olan) dağlı"
  },
  {
    "x": "daxkerdiş (n)",
    "y": " dağlama"
  },
  {
    "x": "daxme (n)",
    "y": " 1)(kızgın bir demirle vurulan damga) dağ\r\n~2) b. damxe (m)\r\n~3)dağlama"
  },
  {
    "x": "daxme kerdene",
    "y": "1)dağlamak\r\n~2)b. damxe kerdene"
  },
  {
    "x": "daxme piro dayene",
    "y": " b. damxe piro dayene"
  },
  {
    "x": "daxmekerda (m)",
    "y": " b. damxekerda (m)"
  },
  {
    "x": "daxmekerde (n)",
    "y": " b. damxekerde (n)"
  },
  {
    "x": "daxmekerdiş (n)",
    "y": " 1) dağlama\r\n~2)b. damxekerdiş (n)"
  },
  {
    "x": "daxmekerdî (m)",
    "y": " b. damxekerdî (m)"
  },
  {
    "x": "daxmekerdîye (m)",
    "y": " b. damxekerdîye (m)"
  },
  {
    "x": "daxmepirodayîş (n)",
    "y": " b. damxepirodayîş (n)"
  },
  {
    "x": "daxmeyin, -e",
    "y": " b. damxeyin, -e"
  },
  {
    "x": "daxmi (n)",
    "y": " b. daxme (n)"
  },
  {
    "x": "daxnaya (m)",
    "y": " (dağlanmış olan) dağlı"
  },
  {
    "x": "daxnaye (n)",
    "y": " (dağlanmış olan) dağlı"
  },
  {
    "x": "daxnayene",
    "y": " dağlamak"
  },
  {
    "x": "daxnayî (m)",
    "y": " (dağlanmış olan) dağlı"
  },
  {
    "x": "daxnayîş (n)",
    "y": " dağlama"
  },
  {
    "x": "daxnayîye (m)",
    "y": " (dağlanmış olan) dağlı"
  },
  {
    "x": "day (m)",
    "y": " 1)b. dadî (m)\r\n~2)b. dayê (m)"
  },
  {
    "x": "dayê",
    "y": " anne (hitap hali)"
  },
  {
    "x": "dayax (n)",
    "y": " b. deyax (n)"
  },
  {
    "x": "dayax kerdene",
    "y": " b. deyax kerdene"
  },
  {
    "x": "dayaxkerdiş (n)",
    "y": " b. deyaxkerdiş (n)"
  },
  {
    "x": "dayç (m)",
    "y": " b. dayîke (m) "
  },
  {
    "x": "dayçi (m)",
    "y": " b. dayîke (m) "
  },
  {
    "x": "daye-I (n)",
    "y": " veri (araştırma vb. için)"
  },
  {
    "x": "dayeyê Homayî (n)",
    "y": " Allah vergisi"
  },
  {
    "x": "daye-I (m, z)",
    "y": " b. aye (m, z)"
  },
  {
    "x": "daye ra",
    "y": " b. aye ra"
  },
  {
    "x": "dayek (n)",
    "y": " verici"
  },
  {
    "x": "dayene",
    "y": " 1)vermek\r\n~2)dayamak (yaslamak)"
  },
  {
    "x": "dayene abirnayene",
    "y": " ayırtmak, rezerve etmek"
  },
  {
    "x": "dayene acelekerdene",
    "y": " aceleleştirmek"
  },
  {
    "x": "dayene ajnekerdene",
    "y": " yüzdürmek, çimdirmek"
  },
  {
    "x": "dayene akerdene",
    "y": " 1)açtırmak\r\n~2)çözdürmek (düğüm vb. için)"
  },
  {
    "x": "dayene aqitnayene",
    "y": " ayırtmak, rezerve etmek"
  },
  {
    "x": "dayene asnawekerdene",
    "y": " yüzdürmek, çimdirmek"
  },
  {
    "x": "dayene barekerdene",
    "y": " b. dayene parekerdene"
  },
  {
    "x": "dayene barikerdene",
    "y": " b. dayene parekerdene"
  },
  {
    "x": "dayene berbayene",
    "y": " b. dayene bermayene"
  },
  {
    "x": "dayene berbîs",
    "y": " b. bermî dayene"
  },
  {
    "x": "dayene bermayene",
    "y": " ağlatmak"
  },
  {
    "x": "dayene bermiş",
    "y": " b. bermî dayene"
  },
  {
    "x": "dayene bîhnekerdene",
    "y": " b. dayene bînekerdene"
  },
  {
    "x": "dayene bînekerdene",
    "y": " koklatmak"
  },
  {
    "x": "dayene boyekerdene",
    "y": " koklatmak"
  },
  {
    "x": "dayene boykerdene",
    "y": " b. dayene boyekerdene"
  },
  {
    "x": "dayene buykerdene",
    "y": " b. dayene boyekerdene"
  },
  {
    "x": "dayene bûykerdene",
    "y": " b. dayene boyekerdene"
  },
  {
    "x": "dayene cêkerdene",
    "y": " ayırtmak"
  },
  {
    "x": "dayene cêrakerdene",
    "y": " ayırtmak"
  },
  {
    "x": "dayene cêravetene",
    "y": " ayırtmak"
  },
  {
    "x": "dayene cêserkerdene",
    "y": " ayırtmak"
  },
  {
    "x": "dayene cêservetene",
    "y": " ayırtmak"
  },
  {
    "x": "dayene cêservistene",
    "y": " ayırtmak"
  },
  {
    "x": "dayene cêvervetene",
    "y": " ayırtmak"
  },
  {
    "x": "dayene cîyakerdene",
    "y": " ayırtmak"
  },
  {
    "x": "dayene çapkerdene",
    "y": " bastırmak"
  },
  {
    "x": "dayene çarekerdene",
    "y": " çözdürmek (sorun vb. için)"
  },
  {
    "x": "dayene çerxî ro",
    "y": " b. çerxî ro dayene"
  },
  {
    "x": "dayene dekerdene",
    "y": " çözdürmek (bulmaca vb. için)"
  },
  {
    "x": "dayene dest",
    "y": " teslim etmek\r\n~Pîyî lajê xo nêda dest. (Baba oğlunu teslim etmedi.)"
  },
  {
    "x": "dayene destene",
    "y": " b. dayene deştene"
  },
  {
    "x": "dayene destpêkerdene",
    "y": " başlatmak"
  },
  {
    "x": "dayene deştene",
    "y": " diktirmek-I"
  },
  {
    "x": "dayene dêştene",
    "y": " b. dayene deştene"
  },
  {
    "x": "dayene ewnîyayane",
    "y": " baktırmak"
  },
  {
    "x": "dayene eyarkerdene",
    "y": " ayarlatmak"
  },
  {
    "x": "dayene fahmkerdene",
    "y": " b. dayene fehmkerdene"
  },
  {
    "x": "dayene famkerdene",
    "y": " b. dayene fehmkerdene"
  },
  {
    "x": "dayene famkerdeni",
    "y": " b. dayene fehmkerdene"
  },
  {
    "x": "dayene fehmkerdene",
    "y": " anlatmak"
  },
  {
    "x": "dayene ferqkerdene",
    "y": " çaktırmak, sezdirmek, sezindirmek"
  },
  {
    "x": "dayene fetisnayene",
    "y": " boğdurmak (soluk aldırmamaktan dolayı)"
  },
  {
    "x": "dayene fêmkerdene",
    "y": " b. dayene fehmkerdene"
  },
  {
    "x": "dayene fomkerdene",
    "y": " b. dayene fehmkerdene"
  },
  {
    "x": "dayene fûmkerdene",
    "y": " b. dayene fehmkerdene"
  },
  {
    "x": "dayene gosrekerdene",
    "y": " biletmek"
  },
  {
    "x": "dayene helkerdene",
    "y": " çözdürmek (sorun vb. için)"
  },
  {
    "x": "dayene heremnayene",
    "y": " b. dayene herimnayene"
  },
  {
    "x": "dayene herimnayene",
    "y": " bozdurmak"
  },
  {
    "x": "dayene hesankerdene",
    "y": " biletmek"
  },
  {
    "x": "dayene hîskerdene",
    "y": " çaktırmak, sezdirmek, sezindirmek"
  },
  {
    "x": "dayene hurdîkerdene",
    "y": " bozdurmak (para için)"
  },
  {
    "x": "dayene îstîfakerdene",
    "y": " istifa ettirmek"
  },
  {
    "x": "dayene karitene",
    "y": " diktirmek-II"
  },
  {
    "x": "dayene kerdene",
    "y": " yaptırmak"
  },
  {
    "x": "dayene kokkerdene",
    "y": " ayarlatmak"
  },
  {
    "x": "dayene mesatkerdene",
    "y": " biletmek"
  },
  {
    "x": "dayene naskerdene",
    "y": " tanıtmak"
  },
  {
    "x": "dayene nîyadayene",
    "y": " baktırmak"
  },
  {
    "x": "dayene parekerdene",
    "y": " bölüştürmek"
  },
  {
    "x": "dayene pawitene",
    "y": " bekletmek"
  },
  {
    "x": "dayene peymitene",
    "y": " ölçtürmek"
  },
  {
    "x": "dayene pirrkerdene",
    "y": " çözdürmek (bulmaca vb. için)"
  },
  {
    "x": "dayene pînekerdene",
    "y": " yamalatmak"
  },
  {
    "x": "dayene pînitene",
    "y": " bekletmek"
  },
  {
    "x": "dayene polkerdene",
    "y": " yamalatmak"
  },
  {
    "x": "dayene qayîtkerdene",
    "y": " baktırmak"
  },
  {
    "x": "dayene rabirnayene",
    "y": " ayırtmak, rezerve etmek"
  },
  {
    "x": "dayene rakerdene",
    "y": " 1)açtırmak\r\n~2)çözdürmek (düğüm vb. için)"
  },
  {
    "x": "dayene raverdayene",
    "y": " bıraktırmak"
  },
  {
    "x": "dayene rijnayene",
    "y": " bozdurmak"
  },
  {
    "x": "dayene riznayene",
    "y": " b. dayene rijnayene"
  },
  {
    "x": "dayene rokewtene",
    "y": " çöktürmek (at, inek vb. hayvanlar için)"
  },
  {
    "x": "dayene ronayene",
    "y": " diktirmek-II"
  },
  {
    "x": "dayene rûnayîne",
    "y": " b. dayene ronayene"
  },
  {
    "x": "dayene sanekerdene",
    "y": " biletmek"
  },
  {
    "x": "dayene sawitene",
    "y": " biletmek"
  },
  {
    "x": "dayene sawutene",
    "y": " b. dayene sawitene"
  },
  {
    "x": "dayene seqakerdene",
    "y": " biletmek"
  },
  {
    "x": "dayene seqanayîne",
    "y": " b. dayene seqênayene"
  },
  {
    "x": "dayene seqeynayene",
    "y": " b. dayene seqênayene"
  },
  {
    "x": "dayene seqênayene",
    "y": " biletmek"
  },
  {
    "x": "dayene seqnayîne",
    "y": " b. dayene seqênayene"
  },
  {
    "x": "dayene sereşnayene",
    "y": " b. dayene şinasnayene"
  },
  {
    "x": "dayene sexênayene",
    "y": " b. dayene seqênayene"
  },
  {
    "x": "dayene seyrkerdene",
    "y": " baktırmak"
  },
  {
    "x": "dayene silasnayene",
    "y": " b. dayene şinasnayene"
  },
  {
    "x": "dayene sinasnayene",
    "y": " b. dayene şinasnayene"
  },
  {
    "x": "dayene sirasnayene",
    "y": " b. dayene şinasnayene"
  },
  {
    "x": "dayene siraşnayene",
    "y": " b. dayene şinasnayene"
  },
  {
    "x": "dayene soberîkerdene",
    "y": " yüzdürmek, çimdirmek"
  },
  {
    "x": "dayene sone kerdene",
    "y": " b. dayene sane kerdene"
  },
  {
    "x": "dayene soynayene",
    "y": " b. dayene sawitene"
  },
  {
    "x": "dayene soynayêne",
    "y": " b. dayene sawitene"
  },
  {
    "x": "dayene şaqole ro",
    "y": " b. şaqul ro dayene"
  },
  {
    "x": "dayene şelenayene",
    "y": " b. dayene şelênayene"
  },
  {
    "x": "dayene şelênayene",
    "y": " soydurmak (giysisini çıkartmak, elbisesini çıkartmak)"
  },
  {
    "x": "dayene şellênayene",
    "y": " b. dayene şelênayene"
  },
  {
    "x": "dayene şilnayene",
    "y": " b. dayene şelênayene"
  },
  {
    "x": "dayene şinasnayene",
    "y": " tanıtmak "
  },
  {
    "x": "dayene şirasnayene",
    "y": " b. dayene şinasnayene"
  },
  {
    "x": "dayene teber",
    "y": " sızdırmak\r\n~No dês awe dano teber. (Bu duvar su sızdırıyor.)"
  },
  {
    "x": "dayene temaşekerdene",
    "y": " baktırmak"
  },
  {
    "x": "dayene veradeyene",
    "y": " bıraktırmak"
  },
  {
    "x": "dayene verdayene",
    "y": " bıraktırmak"
  },
  {
    "x": "dayene virastene",
    "y": " b. dayene viraştene"
  },
  {
    "x": "dayene viraştene",
    "y": " yaptırmak"
  },
  {
    "x": "dayene vîjnayene",
    "y": " ayıklatmak"
  },
  {
    "x": "dayene weçînayene",
    "y": " ayıklatmak"
  },
  {
    "x": "dayene weçînitene",
    "y": " ayıklatmak"
  },
  {
    "x": "dayene wekerdene",
    "y": " 1)açtırmak\r\n~2)çözdürmek (düğüm vb. için)"
  },
  {
    "x": "dayene wendene",
    "y": " okutmak"
  },
  {
    "x": "dayene werdene",
    "y": " yedirmek"
  },
  {
    "x": "dayene werdikerdene",
    "y": " b. (para için) dayene hurdîkerdene"
  },
  {
    "x": "dayene werdîkerdene",
    "y": " b. (para için) dayene hurdîkerdene"
  },
  {
    "x": "dayene weşanayene",
    "y": " bozdurmak"
  },
  {
    "x": "dayene wirdikerdene",
    "y": " b. (para için) dayene hurdîkerdene"
  },
  {
    "x": "dayene wurdikerdene",
    "y": " b. (para için) dayene hurdîkerdene"
  },
  {
    "x": "dayene xendeknayene",
    "y": " b. (suda vb.) dayene xeniqnayene"
  },
  {
    "x": "dayene xendeqnayene",
    "y": " b. (suda vb.) dayene xeniqnayene"
  },
  {
    "x": "dayene xeniqnayene",
    "y": " boğdurmak (suda vb.) "
  },
  {
    "x": "dayene xerepnayene",
    "y": " b. dayene xeripnayene"
  },
  {
    "x": "dayene xeripnayene",
    "y": " bozdurmak"
  },
  {
    "x": "dayene zanayene",
    "y": " beyan etmek"
  },
  {
    "x": "dayene zonayene",
    "y": " b. dayene zanayene"
  },
  {
    "x": "dayene zûnayene",
    "y": " b. dayene zanayene"
  },
  {
    "x": "dayene-girewtena agahî",
    "y": " haberleşmek"
  },
  {
    "x": "dayene-girewtena xeberan",
    "y": " haberleşmek"
  },
  {
    "x": "dayê (m, z)",
    "y": " b. aye (m, z)"
  },
  {
    "x": "dayik (m)",
    "y": " b. dayîke (m)"
  },
  {
    "x": "dayiki (m)",
    "y": " b. dayîke (m)"
  },
  {
    "x": "dayî, -ye-I",
    "y": " veri (araştırma vb. için)"
  },
  {
    "x": "dayî-II (m)",
    "y": " b. dadî (m) "
  },
  {
    "x": "dayê",
    "y": " anne (hitap hali)"
  },
  {
    "x": "dayî-III",
    "y": " b. daha"
  },
  {
    "x": "dayîk (m)",
    "y": " b. dayîke (m)"
  },
  {
    "x": "dayîke (m)",
    "y": " 1)anne, ana\r\n~2)ana arı"
  },
  {
    "x": "dayîke ra bîyene",
    "y": " doğmak (insan için)"
  },
  {
    "x": "dayîkê",
    "y": " anneciğim (hitap hali) "
  },
  {
    "x": "dayîki (m)",
    "y": " b. dayîke (m)"
  },
  {
    "x": "dayîma",
    "y": " b. daîma "
  },
  {
    "x": "dayîmîş bîyayene",
    "y": " b. deyanmîş bîyene"
  },
  {
    "x": "dayîmîşbîyayîş (n)",
    "y": " b. deyanmîşbîyayîş (n)"
  },
  {
    "x": "dayîna",
    "y": " b. daha-3"
  },
  {
    "x": "dayîne",
    "y": " b. dayene"
  },
  {
    "x": "dayîne seqkerdene",
    "y": " b. dayene seqa kerdene"
  },
  {
    "x": "dayîne sinaşnayene",
    "y": " b. dayene şinasnayene"
  },
  {
    "x": "dayîne sûne kerdene",
    "y": " b. dayene sane kerdene"
  },
  {
    "x": "dayîra (m)",
    "y": " b. dayîre (n)"
  },
  {
    "x": "dayîre (n)",
    "y": " 1)daire\r\n~dayîreyê bacî (n): vergi dairesi\r\n~dayîreyê makîna (n): makine dairesi\r\n~dayîreyê zewicnayîşî (n): evlendirme dairesi\r\n~2)kat"
  },
  {
    "x": "dayîreyê bacî (n)",
    "y": " vergi dairesi"
  },
  {
    "x": "dayîreyê makîna (n)",
    "y": " makine dairesi"
  },
  {
    "x": "dayîreyê zewicnayîşî (n)",
    "y": " evlendirme dairesi"
  },
  {
    "x": "dayîs (n)",
    "y": " b. dayîş (n)"
  },
  {
    "x": "dayîsberbîs (n)",
    "y": " b. bermîdayîş (n)"
  },
  {
    "x": "dayîsbermiş (n)",
    "y": " b. bermîdayîş (n)"
  },
  {
    "x": "dayîsbîhnekerdis (n)",
    "y": " b. dayîşbînekerdiş (n)"
  },
  {
    "x": "dayîsvirastis (n)",
    "y": " b. dayîşviraştiş (n)"
  },
  {
    "x": "dayîş (n)",
    "y": " 1)verme\r\n~2)dayama (yaslama)"
  },
  {
    "x": "dayîşê Ellayî (n)",
    "y": " Allah vergisi"
  },
  {
    "x": "dayîşê Homayî (n)",
    "y": " Allah vergisi"
  },
  {
    "x": "dayîş-girewtiş (n)",
    "y": " alım satım, alışveriş"
  },
  {
    "x": "dayîş-girewtişê agahî",
    "y": " haberleşme, iletişim, muhabere"
  },
  {
    "x": "dayîş-girewtişê xeberan",
    "y": " haberleşme, iletişim, muhabere"
  },
  {
    "x": "dayîşabirnayîş (n)",
    "y": " ayırtma, rezerve etme"
  },
  {
    "x": "dayîşajnekerdiş (n)",
    "y": " yüzdürme, çimdirme"
  },
  {
    "x": "dayîşakerdiş (n)",
    "y": " 1)açtırma\r\n~2)çözdürme (düğüm vb. için)"
  },
  {
    "x": "dayîşaqitnayîş (n)",
    "y": " ayırtma, rezerve etme"
  },
  {
    "x": "dayîşasnawekerdiş (n)",
    "y": " yüzdürme, çimdirme"
  },
  {
    "x": "dayîşbarekerdiş (n)",
    "y": " b. dayîşparekerdiş"
  },
  {
    "x": "dayîşbarikerdiş (n)",
    "y": " b. dayîşparekerdiş (n)"
  },
  {
    "x": "dayîşbînekerdiş (n)",
    "y": " koklatma"
  },
  {
    "x": "dayîşboyekerdiş (n)",
    "y": " koklatma"
  },
  {
    "x": "dayîşboykerdiş (n)",
    "y": " b. dayîşboyekerdiş (n)"
  },
  {
    "x": "dayîşbuykerdiş (n)",
    "y": " b. dayîşboyekerdiş (n)"
  },
  {
    "x": "dayîşbûykerdiş (n)",
    "y": " b. dayîşboyekerdiş (n)"
  },
  {
    "x": "dayîşcêkerdiş (n)",
    "y": " ayırtma"
  },
  {
    "x": "dayîşcêrakerdiş (n)",
    "y": " ayırtma"
  },
  {
    "x": "dayîşcêravetiş (n)",
    "y": " ayırtma"
  },
  {
    "x": "dayîşcêserkerdiş (n)",
    "y": " ayırtma"
  },
  {
    "x": "dayîşcêservetiş (n)",
    "y": " ayırtma"
  },
  {
    "x": "dayîşcêservistiş (n)",
    "y": " ayırtma"
  },
  {
    "x": "dayîşcêvervetiş (n)",
    "y": " ayırtma"
  },
  {
    "x": "dayîşcîyakerdiş (n)",
    "y": " ayırtma"
  },
  {
    "x": "dayîşçapkerdiş (n)",
    "y": " bastırma (kitab vb. için)"
  },
  {
    "x": "dayîşçarekerdiş (n)",
    "y": " çözdürme (sorun vb. için)"
  },
  {
    "x": "dayîşdekerdiş (n)",
    "y": " çözdürme (bulmaca vb. için)"
  },
  {
    "x": "dayîşdestiş (n)",
    "y": " b. dayîşdeştiş (n)"
  },
  {
    "x": "dayîşdestpêkerdiş (n)",
    "y": " başlatma"
  },
  {
    "x": "dayîşdeştiş (n)",
    "y": " diktirme"
  },
  {
    "x": "dayîşecelekerdiş (n)",
    "y": " aceleleştirme"
  },
  {
    "x": "dayîşewnîyayîş (n)",
    "y": " baktırma"
  },
  {
    "x": "dayîşeyarkerdiş (n)",
    "y": " ayarlatma"
  },
  {
    "x": "dayîşfahmkerdiş (n)",
    "y": " b. dayîşfehmkerdiş (n)"
  },
  {
    "x": "dayîşfamkerdiş (n)",
    "y": " b. dayîşfehmkerdiş (n)"
  },
  {
    "x": "dayîşfehmkerdiş (n)",
    "y": " anlatma, anlatış"
  },
  {
    "x": "dayîşfetisnayîş (n)",
    "y": " boğdurma (soluk aldırmamaktan dolayı)"
  },
  {
    "x": "dayîşfêmkerdiş (n)",
    "y": " b. dayîşfehmkerdiş (n)"
  },
  {
    "x": "dayîşfomkerdiş (n)",
    "y": " b. dayîşfehmkerdiş (n)"
  },
  {
    "x": "dayîşfûmkerdiş (n)",
    "y": " b. dayîşfehmkerdiş (n)"
  },
  {
    "x": "dayîşhelkerdiş (n)",
    "y": " çözdürme (sorun vb. için)"
  },
  {
    "x": "dayîşheremnayîş (n)",
    "y": " b. dayîşherimnayîş (n)"
  },
  {
    "x": "dayîşherimnayîş (n)",
    "y": " bozdurma"
  },
  {
    "x": "dayîşhurdîkerdiş (n)",
    "y": " bozdurma (para için)"
  },
  {
    "x": "dayîşîstîfakerdiş (n)",
    "y": " istifa ettirme"
  },
  {
    "x": "dayîşkaritiş (n)",
    "y": " diktirme-II"
  },
  {
    "x": "dayîşkerdiş (n)",
    "y": " yaptırma"
  },
  {
    "x": "dayîşkokkerdiş (n)",
    "y": " ayarlatma"
  },
  {
    "x": "dayîşnaskerdiş (n)",
    "y": " tanıtma"
  },
  {
    "x": "dayîşnîyadayîş (n)",
    "y": " baktırma"
  },
  {
    "x": "dayîşparekerdiş (n)",
    "y": " bölüştürme, taksim"
  },
  {
    "x": "dayîşpawitiş (n)",
    "y": " bekletme"
  },
  {
    "x": "dayîşpirrkerdiş (n)",
    "y": " çözdürme (bulmaca vb. için)"
  },
  {
    "x": "dayîşpînekerdiş (n)",
    "y": " yamalatma"
  },
  {
    "x": "dayîşpînitiş (n)",
    "y": " bekletme"
  },
  {
    "x": "dayîşpolkerdiş (n)",
    "y": " yamalatma"
  },
  {
    "x": "dayîşqayîtkerdiş (n)",
    "y": " baktırma"
  },
  {
    "x": "dayîşrabirnayîş (n)",
    "y": " ayırtma, rezerve etme"
  },
  {
    "x": "dayîşrakerdiş (n)",
    "y": " 1)açtırma\r\n~2)çözdürme (düğüm vb. için)"
  },
  {
    "x": "dayîşraverdayîş (n)",
    "y": " bıraktırma"
  },
  {
    "x": "dayîşrijnayîş (n)",
    "y": " bozdurma"
  },
  {
    "x": "dayîşriznayîş (n)",
    "y": " b. dayîşrijnayîş (n)"
  },
  {
    "x": "dayîşrokewtiş (n)",
    "y": " çöktürme (at, inek vb. hayvanlar için)"
  },
  {
    "x": "dayîşronayîş (n)",
    "y": " diktirme-II"
  },
  {
    "x": "dayîşrûnayîş (n)",
    "y": " b. dayîşronayîş (n)"
  },
  {
    "x": "dayîşsereşnayîş (n)",
    "y": " b. dayîşşinasnayîş (n)"
  },
  {
    "x": "dayîşseyrkerdiş (n)",
    "y": " baktırma"
  },
  {
    "x": "dayîşsilasnayîş (n)",
    "y": " b. dayîşşinasnayîş (n)"
  },
  {
    "x": "dayîşsinasnayîş (n)",
    "y": " b. dayîşşinasnayîş (n)"
  },
  {
    "x": "dayîşsinaşnayîş (n)",
    "y": " b. dayîşşinasnayîş (n)"
  },
  {
    "x": "dayîşsirasnayîş (n)",
    "y": " b. dayîşşinasnayîş (n)"
  },
  {
    "x": "dayîşsiraşnayîş (n)",
    "y": " b. dayîşşinasnayîş (n)"
  },
  {
    "x": "dayîşsoberîkerdiş (n)",
    "y": " yüzdürme,çimdirme"
  },
  {
    "x": "dayîşşelenayîş (n)",
    "y": " b. dayîşşelênayîş (n)"
  },
  {
    "x": "dayîşşelênayîş (n)",
    "y": " soydurma (giysisini çıkartma)"
  },
  {
    "x": "dayîşşellênayîş (n)",
    "y": " b. dayîşşelênayîş (n)"
  },
  {
    "x": "dayîşşilnayîş (n)",
    "y": " b. dayîşşelênayîş (n)"
  },
  {
    "x": "dayîşşinasnayîş (n)",
    "y": " tanıtma"
  },
  {
    "x": "dayîşşirasnayîş (n)",
    "y": " b. dayîşşinasnayîş (n)"
  },
  {
    "x": "dayîşteber (n)",
    "y": " sızdırma (testi, toprak vb için)"
  },
  {
    "x": "dayîştemaşekerdiş (n)",
    "y": " baktırma"
  },
  {
    "x": "dayîşveradeyîş (n)",
    "y": " bıraktırma"
  },
  {
    "x": "dayîşverdayîş (n)",
    "y": " bıraktırma"
  },
  {
    "x": "dayîşvirastiş (n)",
    "y": " b. dayîşviraştiş (n)"
  },
  {
    "x": "dayîşviraştiş (n)",
    "y": " yaptırma"
  },
  {
    "x": "dayîşvîjnayîş (n)",
    "y": " ayıklatma"
  },
  {
    "x": "dayîşweçînayîş (n)",
    "y": " ayıklatma"
  },
  {
    "x": "dayîşweçînitiş (n)",
    "y": " ayıklatma"
  },
  {
    "x": "dayîşwekerdiş (n)",
    "y": " 1)açtırma\r\n~2)çözdürme (düğüm vb. için)"
  },
  {
    "x": "dayîşwendiş (n)",
    "y": " okutma"
  },
  {
    "x": "dayîşwerdikerdiş (n)",
    "y": " b. (para için) dayîşhurdîkerdiş (n)"
  },
  {
    "x": "dayîşwerdiş (n)",
    "y": " yedirme"
  },
  {
    "x": "dayîşwerdîkerdiş (n)",
    "y": " b. (para için) dayîşhurdîkerdiş (n)"
  },
  {
    "x": "dayîşweşanayîş (n)",
    "y": " bozdurma "
  },
  {
    "x": "dayîşwirdikerdiş (n)",
    "y": " b. (para için) dayîşhurdîkerdiş (n)"
  },
  {
    "x": "dayîşwurdikerdiş (n)",
    "y": " b. (para için) dayîşhurdîkerdiş (n)"
  },
  {
    "x": "dayîşxendeknayîş (n)",
    "y": " b. (suda vb.) dayîşxeniqnayîş (n)"
  },
  {
    "x": "dayîşxendeqnayîş (n)",
    "y": " b. (suda vb.) dayîşxeniqnayîş (n)"
  },
  {
    "x": "dayîşxeniqnayîş (n)",
    "y": " boğdurma (suda vb.)"
  },
  {
    "x": "dayîşxerepnayîş (n)",
    "y": " b. dayîşxeripnayîş (n)"
  },
  {
    "x": "dayîşxeripnayîş (n)",
    "y": " bozdurma"
  },
  {
    "x": "dayîşzanayîş (n)",
    "y": " beyan etme"
  },
  {
    "x": "dayîşzonayîş (n)",
    "y": " b. dayîşzanayîş (n)"
  },
  {
    "x": "dayîşzûnayîş (n)",
    "y": " b. dayîşzanayîş (n)"
  },
  {
    "x": "dayk (m)",
    "y": " b. dayîke (m) "
  },
  {
    "x": "daykê",
    "y": " b. dayîkê"
  },
  {
    "x": "dayke (m)",
    "y": " b. dayîke (m) "
  },
  {
    "x": "daykê",
    "y": " b. dayîkê"
  },
  {
    "x": "dayki (m)",
    "y": " b. dayîke (m)"
  },
  {
    "x": "daykê",
    "y": " b. dayîkê"
  },
  {
    "x": "dayki ra bîyayene",
    "y": " b. dayîke ra bîyene "
  },
  {
    "x": "daym",
    "y": " b. daîma"
  },
  {
    "x": "dayma",
    "y": " b. daîma"
  },
  {
    "x": "daymet",
    "y": " b. daîma"
  },
  {
    "x": "daymiş bîyayene",
    "y": " b. deyanmîş bîyene"
  },
  {
    "x": "daymişbîyayîş (n)",
    "y": " b. deyanmîşbîyayîş (n)"
  },
  {
    "x": "daymîs bîyayene",
    "y": " b. deyanmîş bîyene"
  },
  {
    "x": "daymîsbîyayîş (n)",
    "y": " b. deyanmîşbîyayîş (n) "
  },
  {
    "x": "daymîş bîyayene",
    "y": " b. deyanmîş bîyene"
  },
  {
    "x": "daymîşbîyayîş (n)",
    "y": " b. deyanmîşbîyayîş (n)"
  },
  {
    "x": "dayra (m)",
    "y": " b. dayîre (n)"
  },
  {
    "x": "dayre (n)",
    "y": " b. dayîre (n)"
  },
  {
    "x": "dayşt (n)",
    "y": " b. wet (n)"
  },
  {
    "x": "dayza (n)",
    "y": " b. datîza (n), deza (n) "
  },
  {
    "x": "daza (n)",
    "y": " b. datîza (n), deza (n) "
  },
  {
    "x": "dazanayîş (n)",
    "y": " bilgilendirme, brifing"
  },
  {
    "x": "daznayene",
    "y": " b. dejnayene"
  },
  {
    "x": "daznayêne",
    "y": " b. dejnayene"
  },
  {
    "x": "daznayîs (n)",
    "y": " b. dejnayîş (n)"
  },
  {
    "x": "daznayîş (n)",
    "y": " b. dejnayîş (n)"
  },
  {
    "x": "de-I",
    "y": " 1)artık\r\n~De vaje. (Artık söyle.)\r\n~\t\r\n~2)hele\r\n~\t\r\n~De bê tîya. (Hele buraya gel.)"
  },
  {
    "x": "de-II (m, n, z)",
    "y": " b. ci (m, n, z), ey (n, z), aye (m, z)"
  },
  {
    "x": "de hesîyayene",
    "y": " b. bide hesîyayene"
  },
  {
    "x": "de hesnayene",
    "y": " b. bide hesnayene"
  },
  {
    "x": "de rî",
    "y": " b. ci rê, ey rê, aye rê"
  },
  {
    "x": "... de-III (pd)",
    "y": " Bir artedat. -de, -e, -den\r\n~Lajek keye de yo. (Oğlan evdedir.)\r\n~\"Memîş Xazî cor di yenno sê heşî.\" (Memiş Xazî yukarıdan ayı gibi gelir.) (Oskar Mann, Vate, Nr. 41, s. 118)"
  },
  {
    "x": "... de dejayene",
    "y": " -e üzülmek"
  },
  {
    "x": "... de kerdene",
    "y": " batırmak\r\n~Kardî kerda meşka heywanî de. (Bıçağı hayvanın karnına batırmış.)"
  },
  {
    "x": "...de kuta",
    "y": " çakılı"
  },
  {
    "x": "...de kute",
    "y": " çakılı"
  },
  {
    "x": "... de kutene",
    "y": " çakmak"
  },
  {
    "x": "... de liqotê xo kerdene",
    "y": " dalga geçmek\r\n~Ti mi de liqotanê xo kenî. (Benimle dalga geçiyorsun.)"
  },
  {
    "x": "... de nîyadayene",
    "y": " -e bakmak"
  },
  {
    "x": "... de şîyene",
    "y": " 1)batmak (sivri şeyin batması)\r\n~Derzîne destê mi de şî. (Elime iğne battı.)\r\n~2)batmak (suya, çamura vb. batmak)"
  },
  {
    "x": "... de tinaz kerdene",
    "y": " dalga geçmek\r\n~Mi de tinaz meke! (Benimle dalga geçme!)"
  },
  {
    "x": "de ... da",
    "y": " b. di ... de"
  },
  {
    "x": "de ... de",
    "y": " b. di ... de\r\n~Hesp de dew d’ o. (At köydedir.)"
  },
  {
    "x": "de ... dir",
    "y": " b. di ... de"
  },
  {
    "x": "dea (m)",
    "y": " b. dua (m)"
  },
  {
    "x": "deba (m)",
    "y": " b. dewa (m)"
  },
  {
    "x": "debar (m)",
    "y": " b. debare (m)"
  },
  {
    "x": "debaraxokerdiş (n)",
    "y": " geçinme"
  },
  {
    "x": "debare (m)",
    "y": " geçim"
  },
  {
    "x": "debara xo kerdene",
    "y": " geçinmek"
  },
  {
    "x": "debareyxwekerdiş (n)",
    "y": " b. debaraxokerdiş (n)"
  },
  {
    "x": "debareyxwikerdiş (n)",
    "y": " b. debaraxokerdiş (n)"
  },
  {
    "x": "debari (m)",
    "y": " b. debare (m)"
  },
  {
    "x": "debarey xwe kerdene",
    "y": " b. debara xo kerdene"
  },
  {
    "x": "debarey xwi kerdene",
    "y": " b. debara xo kerdene"
  },
  {
    "x": "debarî (m)",
    "y": " nafaka"
  },
  {
    "x": "debax-I (n)",
    "y": " b. tebaq (n)"
  },
  {
    "x": "debax-II (m)",
    "y": " b. debaxe (m)"
  },
  {
    "x": "debaxe (m)",
    "y": " sepi"
  },
  {
    "x": "debaxe kerdene",
    "y": " sepilemek, tabaklamak"
  },
  {
    "x": "debaxekerdiş (n)",
    "y": " sepileme"
  },
  {
    "x": "debaxkar, -e",
    "y": " sepici, debbağ, tabak"
  },
  {
    "x": "debere (m)",
    "y": " b. debare (m)"
  },
  {
    "x": "debestene",
    "y": " çifte koşmak, koşmak"
  },
  {
    "x": "debeşe (m)",
    "y": " b. zebeşe (m)"
  },
  {
    "x": "debeyîş (n)",
    "y": " b. debîyayîş (n)"
  },
  {
    "x": "debi (n)",
    "y": " b. debo-II (n)"
  },
  {
    "x": "debir (m)",
    "y": " b. debare (m)"
  },
  {
    "x": "debirîyayene",
    "y": " barınmak"
  },
  {
    "x": "debirîyayîş (n)",
    "y": " barınma"
  },
  {
    "x": "debirnayene",
    "y": " 1)barındırmak\r\n~2)esirgemek (vermekten veya yapmaktan kaçınmak)"
  },
  {
    "x": "debirnayîş (n)",
    "y": " 1)barındırma\r\n~2)esirgeme (vermekten veya yapmaktan kaçınma)"
  },
  {
    "x": "debistene",
    "y": " b. debestene"
  },
  {
    "x": "debîyayîş (n)",
    "y": " çözülme (bulmaca vb. için)"
  },
  {
    "x": "debîyene",
    "y": " çözülmek (bulmaca vb. için)"
  },
  {
    "x": "debî (m)",
    "y": " debi"
  },
  {
    "x": "debîya seylawî (m)",
    "y": " feyezan debisi"
  },
  {
    "x": "debîmetre (n)",
    "y": " debimetre"
  },
  {
    "x": "debîmetreyo elektromanyetîk (n)",
    "y": " elektromanyetik debimetre"
  },
  {
    "x": "debîmetreyo ultrasonîk (n)",
    "y": " ultrasonik debimetre"
  },
  {
    "x": "debîyayîne",
    "y": " b. debîyene"
  },
  {
    "x": "debîyayîş (n)",
    "y": " 1)dolma\r\n~2)çözülme (bulmaca vb. için)"
  },
  {
    "x": "debîyene",
    "y": " 1)dolmak\r\n~2)çözülmek (bulmaca vb. için)"
  },
  {
    "x": "debo-I (n)",
    "y": " b. depo (n)"
  },
  {
    "x": "debo-II (n)",
    "y": " kile, ölçek (tahıl ölçmeye yarayan kap), şinik"
  },
  {
    "x": "debr (n)",
    "y": " b. debare (m)"
  },
  {
    "x": "debri (m)",
    "y": " b. debare (m)"
  },
  {
    "x": "debû (n)",
    "y": " b. depo (n)"
  },
  {
    "x": "debûnci (n)",
    "y": " b. dabançe (n)"
  },
  {
    "x": "dec (n)",
    "y": " b. dej (n)"
  },
  {
    "x": "decê cîgere (n)",
    "y": " b. dejê cîgere (n)"
  },
  {
    "x": "decê madî (n)",
    "y": " b. dejê madeyî (n)"
  },
  {
    "x": "decê pîzî (n)",
    "y": " b. dejê pîzî (n)"
  },
  {
    "x": "decê sarî (n)",
    "y": " b. dejê sereyî (n)"
  },
  {
    "x": "decê zerî (n)",
    "y": " b. dejê zereyî (n)"
  },
  {
    "x": "decê zerrî (n)",
    "y": " b. dejê zereyî (n)"
  },
  {
    "x": "decê zerri (n)",
    "y": " b. dejê zerrî (n)"
  },
  {
    "x": "decî mînî (n)",
    "y": " b. dejê mîyaneyî (n)"
  },
  {
    "x": "decî monî (n)",
    "y": " b. dejê mîyaneyî (n)"
  },
  {
    "x": "decî paşt (n)",
    "y": " b. dejê piştî (n)"
  },
  {
    "x": "decaya (m)",
    "y": " b. dejaya (m)"
  },
  {
    "x": "decaye (n)",
    "y": " b. dejaye (n)"
  },
  {
    "x": "decayene",
    "y": " b. dejayene"
  },
  {
    "x": "decayeyê (m)",
    "y": " b. dejayeyîye (m), dejayeyî (m)"
  },
  {
    "x": "decayeyî (m)",
    "y": " b. dejayeyî (m)"
  },
  {
    "x": "decayê (m)",
    "y": " b. dejayîye (m)"
  },
  {
    "x": "decayi (n)",
    "y": " b. dejaye (n)"
  },
  {
    "x": "decayîş (n)",
    "y": " b. dejayîş (n)"
  },
  {
    "x": "decdaya (m)",
    "y": " b. dejdaya (m)"
  },
  {
    "x": "decdaye (n)",
    "y": " b. dejdaye (n)"
  },
  {
    "x": "decdayê (m)",
    "y": " b. dejdayîye (m)"
  },
  {
    "x": "decdayi (n)",
    "y": " b. dejdaye (n)"
  },
  {
    "x": "decdayox, -e",
    "y": " b. dejdayox, -e"
  },
  {
    "x": "deciney (m)",
    "y": " b. dejinî (m), dejinîye (m)"
  },
  {
    "x": "decinê (m)",
    "y": " b. dejinî (m), dejinîye (m)"
  },
  {
    "x": "decnayene",
    "y": " b. dejnayene"
  },
  {
    "x": "decnayîş (n)",
    "y": "b. dejnayox, -e"
  },
  {
    "x": "decnayox, -e",
    "y": " b. dejnayox, -e"
  },
  {
    "x": "ded (n)",
    "y": " amca, emmi (b. dat (n)"
  },
  {
    "x": "dedo",
    "y": " amca (hitap hali) (b. dato)"
  },
  {
    "x": "dedcinî (m)",
    "y": " amca karısı, yenge (amca karısı)"
  },
  {
    "x": "dedeçêna (m)",
    "y": " b. datkêna (m) "
  },
  {
    "x": "dedektîf, -e",
    "y": " dedektif, hafiye"
  },
  {
    "x": "dedektîfîye (m)",
    "y": " dedektiflik, hafiyelik"
  },
  {
    "x": "dedektîv, -e",
    "y": " b. dedektîf, -e"
  },
  {
    "x": "dedektor (n)",
    "y": " dedektör, algılayıcı\r\n~dedektorê gazî (n): gaz dedektörü"
  },
  {
    "x": "dedektorê gazî (n)",
    "y": " gaz dedektörü"
  },
  {
    "x": "dedeza (n)",
    "y": " b. datîza (n)"
  },
  {
    "x": "dedîqodî (n)",
    "y": " b. dêdîqodî (n)"
  },
  {
    "x": "dedkeyna (m)",
    "y": " b. datkêna (m) "
  },
  {
    "x": "dedkêna (m)",
    "y": " b. datkêna (m)"
  },
  {
    "x": "dedza (n)",
    "y": " b. datîza (n), deza (n) "
  },
  {
    "x": "deey (m)",
    "y": " b. dua (m)"
  },
  {
    "x": "def (n)",
    "y": " davul"
  },
  {
    "x": "def û zirna",
    "y": " çalgı çağanak"
  },
  {
    "x": "def û zurna",
    "y": " b. def û zirna"
  },
  {
    "x": "defa (m)",
    "y": " kez, kere, defa, sefer"
  },
  {
    "x": "defana",
    "y": " yine, gene, tekrar"
  },
  {
    "x": "defayê",
    "y": " bir kez, bir defa"
  },
  {
    "x": "defayêk",
    "y": " bir kez, bir defa"
  },
  {
    "x": "defbaz, -e",
    "y": " davulcu"
  },
  {
    "x": "defe (m)",
    "y": " b. defa (m)"
  },
  {
    "x": "defêna",
    "y": " b. defana"
  },
  {
    "x": "defereknayene",
    "y": " b. Deferiknayene"
  },
  {
    "x": "defereknayîş (n)",
    "y": " b. deferiknayîş (n)"
  },
  {
    "x": "deferiknayene",
    "y": " içine doğramak (ekmek vb. için)"
  },
  {
    "x": "deferiknayîş (n)",
    "y": " içine doğrama (ekmek vb. için)"
  },
  {
    "x": "defê",
    "y": " b. defayê "
  },
  {
    "x": "defêna",
    "y": " b. defana"
  },
  {
    "x": "defi (m)",
    "y": " b. defa (m)"
  },
  {
    "x": "defik",
    "y": " b. dafike"
  },
  {
    "x": "defin (n)",
    "y": " defnetme"
  },
  {
    "x": "defin kerdene",
    "y": " defnetmek"
  },
  {
    "x": "defina",
    "y": " b. defana"
  },
  {
    "x": "definkerdis (n)",
    "y": " b. definkerdiş (n)"
  },
  {
    "x": "definkerdiş (n)",
    "y": " defnetme"
  },
  {
    "x": "defiştene",
    "y": " b. devistene"
  },
  {
    "x": "defiştiş (n)",
    "y": " b. devistiş (n)"
  },
  {
    "x": "defînayene",
    "y": " b. devistene"
  },
  {
    "x": "defînayîş (n)",
    "y": " b. devistiş (n)"
  },
  {
    "x": "defn (n)",
    "y": " b. defin (n)"
  },
  {
    "x": "defn kerdene",
    "y": " b. defin kerdene"
  },
  {
    "x": "defnkerdiş (n)",
    "y": " b. definkerdiş (n)"
  },
  {
    "x": "defter (n)",
    "y": " defter"
  },
  {
    "x": "degîrîyayene",
    "y": " iltihaplanmak, yangılanmak"
  },
  {
    "x": "degme",
    "y": " b. deyme"
  },
  {
    "x": "degoşî (m)",
    "y": " b. digoşe (m)"
  },
  {
    "x": "degsigî (zh)",
    "y": " kadın şalvarı"
  },
  {
    "x": "deh",
    "y": " (at vb. için) deh, dah"
  },
  {
    "x": "deh kerdene",
    "y": " (at vb. için) dehlemek, dahlamak, deh demek, deh etmek"
  },
  {
    "x": "deha",
    "y": " b. daha"
  },
  {
    "x": "dehesîyayîş (n)",
    "y": " b. bidehesîyayîş (n)"
  },
  {
    "x": "dehesnayîş (n)",
    "y": " b. bidehesnayîş (n)"
  },
  {
    "x": "dehîre (m)",
    "y": " b. dihîre (m) "
  },
  {
    "x": "dehîre de",
    "y": " b. dihîre de "
  },
  {
    "x": "dehle (m)",
    "y": " 1)koru, koruluk\r\n~2)kavak topluluğu"
  },
  {
    "x": "dehlêre (m)",
    "y": " kavak "
  },
  {
    "x": "dehlêri (m)",
    "y": " b. dehlêre (m)"
  },
  {
    "x": "dehli (m)",
    "y": " b. dehle (m)"
  },
  {
    "x": "dehlîr (m)",
    "y": " b. dehlêre (m)"
  },
  {
    "x": "dehrî (m)",
    "y": " b. darey (m)"
  },
  {
    "x": "dehwa (m)",
    "y": " b. dewa (m)"
  },
  {
    "x": "deîst, -e",
    "y": " deist"
  },
  {
    "x": "dej (n)",
    "y": " ağrı, acı, keder, üzüntü, üzünç\r\n~dejê cîgere (n): ciğer acısı, evlât acısı\r\n~dejê madeyî (n): mide ağrısı\r\n~dejê zereyî (n): karın ağrısı"
  },
  {
    "x": "dej antene",
    "y": " acı çekmek, acı duymak"
  },
  {
    "x": "dej dayene",
    "y": " acı vermek"
  },
  {
    "x": "dej girewtene",
    "y": " ağrısı tutmak"
  },
  {
    "x": "dejê ... antene",
    "y": " acısını çekmek"
  },
  {
    "x": "dejê cîgere (n)",
    "y": " ciğer acısı, evlât acısı "
  },
  {
    "x": "dejê ewladî/e",
    "y": " evlât acısı, ciğer yarası"
  },
  {
    "x": "dejê kezebe (n)",
    "y": " ciğer acısı, evlât acısı"
  },
  {
    "x": "dejê madeyî (n)",
    "y": " mide ağrısı"
  },
  {
    "x": "dejê madî (n)",
    "y": " b. dejê madeyî (n)"
  },
  {
    "x": "dejê mîdî (n)",
    "y": " b. dejê madeyî (n)"
  },
  {
    "x": "dejê mîyaneyî (n)",
    "y": " bel ağrısı"
  },
  {
    "x": "dejê mîyanî (n)",
    "y": " b. dejê mîyaneyî (n)"
  },
  {
    "x": "dejê paştî (n)",
    "y": " b. dejê piştî (n)"
  },
  {
    "x": "dejê piştî (n)",
    "y": " sırt ağrısı "
  },
  {
    "x": "dejê pîzî (n)",
    "y": " karın ağrısı"
  },
  {
    "x": "dejê qeseba (n)",
    "y": " ciğer acısı, evlât acısı"
  },
  {
    "x": "dejê sereyî (n)",
    "y": " baş ağrısı"
  },
  {
    "x": "dejê serî (n)",
    "y": " b. dejê sereyî (n)"
  },
  {
    "x": "dejê zereyî (n)",
    "y": " karın ağrısı"
  },
  {
    "x": "dejê zerî (n)",
    "y": " b. dejê zereyî (n)"
  },
  {
    "x": "dejê zerrî-I (n)",
    "y": " ciğer acısı, evlât acısı "
  },
  {
    "x": "dejê zerrî-II (n)",
    "y": " b. dejê zereyî (n)"
  },
  {
    "x": "dejî ... girewtene",
    "y": " ağrısı tutmak"
  },
  {
    "x": "dejantox, -e",
    "y": " acı görmüş"
  },
  {
    "x": "dejaya (m)",
    "y": " üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü, üzgün"
  },
  {
    "x": "dejaye (n)",
    "y": " üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü, üzgün"
  },
  {
    "x": "dejaye bîyene",
    "y": " üzgün olmak"
  },
  {
    "x": "dejayene",
    "y": " 1)ağrımak\r\n~2)acımak, üzülmek"
  },
  {
    "x": "dejeno",
    "y": " ağrıyor "
  },
  {
    "x": "dejayeyê (m)",
    "y": " b. dejayeyîye (m), dejayeyî (m)"
  },
  {
    "x": "dejayeyênî (m)",
    "y": " üzüntülülük, üzgünlük"
  },
  {
    "x": "dejayeyî (m)",
    "y": " üzüntülülük, üzgünlük"
  },
  {
    "x": "dejayeyîye (m)",
    "y": " üzgünlük, üzüntülülük"
  },
  {
    "x": "dejayê (m)",
    "y": " b. dejayîye (m)"
  },
  {
    "x": "dejayîş (n)",
    "y": " 1)ağrıma\r\n~2)acıma, üzülme"
  },
  {
    "x": "dejayîye (m)",
    "y": " üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü, üzgün"
  },
  {
    "x": "dejbir, -e",
    "y": " ağrı kesici"
  },
  {
    "x": "dejdar, -e",
    "y": " ağrılı"
  },
  {
    "x": "dejdaya (m)",
    "y": " üzüntülü (üzen durum veya olay), üzücü (durum veya olay) "
  },
  {
    "x": "dejdaye (n)",
    "y": " üzüntülü (üzen durum veya olay), üzücü (durum veya olay)"
  },
  {
    "x": "dejdayê (m)",
    "y": " b. dejdayîye (m)"
  },
  {
    "x": "dejdayîye (m)",
    "y": " üzüntülü (üzen durum veya olay), üzücü (durum veya olay)"
  },
  {
    "x": "dejdayox, -e",
    "y": " üzüntülü (üzen durum veya olay), üzücü (durum veya olay)"
  },
  {
    "x": "dejdayoxi (m)",
    "y": " b. dejdayox, -e"
  },
  {
    "x": "dejdîyayox, -e",
    "y": " acı görmüş"
  },
  {
    "x": "dejin, -e",
    "y": " üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü"
  },
  {
    "x": "dejiney (m)",
    "y": " b. dejinî (m), dejinîye (m)"
  },
  {
    "x": "dejinê (m)",
    "y": " b. dejinî (m), dejinîye (m)"
  },
  {
    "x": "dejinî (m)",
    "y": " üzüntülülük"
  },
  {
    "x": "dejinîye (m)",
    "y": " üzüntülülük"
  },
  {
    "x": "dejnayene",
    "y": " 1)acıtmak\r\n~2)ağrıtmak, üzmek"
  },
  {
    "x": "dejnayîş (n)",
    "y": " 1)ağrıtma\r\n~2)acıtma, üzme"
  },
  {
    "x": "dejnayox, -e",
    "y": " üzücü (durum veya olay), üzüntülü (üzen durum veya olay)"
  },
  {
    "x": "dejnayoxi (m)",
    "y": " b. dejnayox, -e"
  },
  {
    "x": "dek û dilavan ra bawer kerdene",
    "y": " b. dek û dolaban ra bawer kerdene"
  },
  {
    "x": "dek û dolaban ra bawer kerdene",
    "y": " afiş yutmak"
  },
  {
    "x": "dek û dolavan ra bawer kerdene",
    "y": " b. dek û dolaban ra bawer kerdene"
  },
  {
    "x": "dekagram (n)",
    "y": " dekagram "
  },
  {
    "x": "dekalître (n)",
    "y": " dekalitre"
  },
  {
    "x": "dekametre (n)",
    "y": " dekametre"
  },
  {
    "x": "dekametrekare (n)",
    "y": " dekametrekare"
  },
  {
    "x": "dekametremîkab (n)",
    "y": " dekametreküp"
  },
  {
    "x": "dekan, -e",
    "y": " dekan "
  },
  {
    "x": "dekanîye (m)",
    "y": " dekanlık"
  },
  {
    "x": "dekar (n)",
    "y": " dekar"
  },
  {
    "x": "dekerda (m)",
    "y": " balıketinde, balıketi, dolgun"
  },
  {
    "x": "dekerde (n)",
    "y": " balıketinde, balıketi, dolgun"
  },
  {
    "x": "dekerdene",
    "y": " 1)içine katmak, doldurmak\r\n~2)takmak (diş için)\r\n~3)çözmek (bulmaca vb. için)"
  },
  {
    "x": "dekerdene mîyan",
    "y": " 1)içine katmak\r\n~2)karıştırmak (içine karıştırmak)\r\n~Mi medeke mîyanê nê karanê pîsan. (Beni bu pis işlere karıştırma.)"
  },
  {
    "x": "dekerdene qule",
    "y": " deliğe koymak, deliğe tıkmak"
  },
  {
    "x": "dekerdê (m)",
    "y": " b. dekerdî (m) "
  },
  {
    "x": "dekerdiş (n)",
    "y": " 1)doldurma\r\n~2)takma (diş için)\r\n~3)çözme (bulmaca vb. için)"
  },
  {
    "x": "dekerdiş qule",
    "y": " deliğe koyma, deliğe tıkma"
  },
  {
    "x": "dekerdiş quli",
    "y": " b. dekerdiş qule"
  },
  {
    "x": "... dekerdiş",
    "y": " batırma"
  },
  {
    "x": "dekerdî (m)",
    "y": " balıketinde, balıketi, dolgun"
  },
  {
    "x": "dekerdîye (m)",
    "y": " balıketinde, balıketi, dolgun"
  },
  {
    "x": "dekernayene",
    "y": " 1)bahsetmek, sözünü etmek; -den konuşmak\r\n~Emşo ma çend embazî ameyîme têhet, ma zaf ti dekerna. (Bu gece birkaç arkadaş bir araya geldik, senden çok bahsettik.)\r\n~2)b. zikirnayene"
  },
  {
    "x": "dekernayêne",
    "y": " b. zikirnayene"
  },
  {
    "x": "dekernayîş (n)",
    "y": " 1)bahsetme, sözünü etme; -den konuşma\r\n~2)b. zikirnayîş (n)"
  },
  {
    "x": "dekerrnayene",
    "y": " 1)b. dekernayene\r\n~2)b. zikirnayene"
  },
  {
    "x": "dekewta (m)",
    "y": " girdi (ticaret için)"
  },
  {
    "x": "dekewte (n)",
    "y": " girdi (ticaret için)"
  },
  {
    "x": "dekewtene",
    "y": " girmek"
  },
  {
    "x": "dekewtene cile",
    "y": " yatağa girmek, hasta olmak, yatağa düşmek"
  },
  {
    "x": "dekewtene heşê ...",
    "y": " b. dekewtene hişê ..."
  },
  {
    "x": "dekewtene hêşê ...",
    "y": " b. dekewtene hişê ..."
  },
  {
    "x": "dekewtene hişê ...",
    "y": " anlamak"
  },
  {
    "x": "dekewtiş (n)",
    "y": " girme, giriş\r\n~dekewtiş cile: yatağa girme, hasta olma, yatağa düşme\r\n~dekewtişê ewraqe (n): evrak girişi\r\n~dekewtişê sîstemî (n): sisteme giriş"
  },
  {
    "x": "dekewtiş cile",
    "y": " yatağa girme, hasta olma, yatağa düşme"
  },
  {
    "x": "dekewtiş cili",
    "y": " b. dekewtiş cile"
  },
  {
    "x": "dekewtiş kar",
    "y": " işe girme"
  },
  {
    "x": "dekewtişê ewraqe (n)",
    "y": " evrak girişi"
  },
  {
    "x": "dekewtişê sazgehî (n)",
    "y": " tesis girişi"
  },
  {
    "x": "dekewtişê sîstemî (n)",
    "y": " sisteme giriş"
  },
  {
    "x": "dekewtiş-vejîyayîş",
    "y": " giriş-çıkış"
  },
  {
    "x": "dekewtîye (m)",
    "y": " girdi (ticaret için)"
  },
  {
    "x": "dekor (n)",
    "y": " dekor"
  },
  {
    "x": "dekorasyon (n)",
    "y": " dekorasyon"
  },
  {
    "x": "dekorator, -e",
    "y": " dekoratör"
  },
  {
    "x": "dekoteni (m)",
    "y": " b. dekewtene"
  },
  {
    "x": "dekotiş (n)",
    "y": " b. dekewtiş (n)"
  },
  {
    "x": "dekoytiş (n)",
    "y": " b. dekewtiş (n)"
  },
  {
    "x": "dekroşe (n)",
    "y": " dekroşe"
  },
  {
    "x": "dekupbîyayîş (n)",
    "y": " b. dekupîyayîş (n)"
  },
  {
    "x": "dekupîyayene",
    "y": " çökmek (çukurlaşmak)"
  },
  {
    "x": "dekupîyayîş (n)",
    "y": " çökme (çukurlaşma)"
  },
  {
    "x": "dekupnayene",
    "y": " çöktürmek (çukurlaştırmak)"
  },
  {
    "x": "dekupnayîş (n)",
    "y": " çöktürme (çukurlaştırma)"
  },
  {
    "x": "del (m)",
    "y": " b. dele (m)"
  },
  {
    "x": "delal, -e",
    "y": " 1)komisyoncu\r\n~2)tellal, simsar\r\n~3)çığırtkan"
  },
  {
    "x": "delaley (m)",
    "y": " b. delalîye (m)"
  },
  {
    "x": "delalê (m)",
    "y": " b. delalîye (m)"
  },
  {
    "x": "delalîye (m)",
    "y": " çığırtkanlık, tellallık"
  },
  {
    "x": "delama (m)",
    "y": " b. delame (n)"
  },
  {
    "x": "delame (n)",
    "y": " topaç"
  },
  {
    "x": "delami (n)",
    "y": " b. delame (n)"
  },
  {
    "x": "dele (m)",
    "y": " dişi köpek"
  },
  {
    "x": "dele-",
    "y": " dişi (bazı hayvanlar için)"
  },
  {
    "x": "deleme (n)",
    "y": " b. delame (n)"
  },
  {
    "x": "deleverge (m)",
    "y": " dişi kurt "
  },
  {
    "x": "delême (m)",
    "y": " b. delame (n)"
  },
  {
    "x": "delfîn (n)",
    "y": " yunus balığı"
  },
  {
    "x": "Delhî",
    "y": " Delhi"
  },
  {
    "x": "deli (m)",
    "y": " b. dele (m)"
  },
  {
    "x": "deli (n)",
    "y": " b. delu (n)"
  },
  {
    "x": "delî (m)",
    "y": " b. deluye (m)"
  },
  {
    "x": "delîl (n) (huq.)",
    "y": " delil, argüman\r\n~delîlo objektîf (n): objektif kanıt"
  },
  {
    "x": "delîlo objektîf (n)",
    "y": " objektif kanıt"
  },
  {
    "x": "delîlo (n)",
    "y": " halay (serçe parmakla tutuşarak oynanan)"
  },
  {
    "x": "delma (m)",
    "y": " b. delame (n)"
  },
  {
    "x": "delmast",
    "y": " b. dêmlaşt, -e"
  },
  {
    "x": "delmaşt",
    "y": " b. dêmlaşt, -e"
  },
  {
    "x": "delo",
    "y": " b. tirê, derê"
  },
  {
    "x": "delq (n)",
    "y": " b. dilq (n)"
  },
  {
    "x": "delu, -ye",
    "y": " deli, çılgın"
  },
  {
    "x": "delu bîyayîne",
    "y": " b. delu bîyene "
  },
  {
    "x": "delu bîyene",
    "y": " delirmek, çıldırmak, çılgına dönmek, çılgınlaşmak"
  },
  {
    "x": "delu kardene",
    "y": " b. delu kerdene "
  },
  {
    "x": "delu kerdene",
    "y": " delirtmek, çıldırtmak"
  },
  {
    "x": "delubîyayîş (n)",
    "y": " çıldırma, çıldırış, çılgınlaşma, delirme, deliriş"
  },
  {
    "x": "delukerdiş (n)",
    "y": " çıldırtma, delirtme"
  },
  {
    "x": "deluyane",
    "y": " çılgınca, delice"
  },
  {
    "x": "deluyey (m)",
    "y": " b. deluyîye (m)"
  },
  {
    "x": "deluyîye (m)",
    "y": " delilik, çılgınlık"
  },
  {
    "x": "deluyîye kerdene",
    "y": " çılgınlık etmek"
  },
  {
    "x": "delû (n)",
    "y": " b. delu (n)"
  },
  {
    "x": "delûye (m)",
    "y": " b. deluye (m)"
  },
  {
    "x": "delvargi (m)",
    "y": " b. deleverge (m)"
  },
  {
    "x": "delvergi (m)",
    "y": " b. deleverge (m) "
  },
  {
    "x": "delxe kerdene",
    "y": " kaytarmak"
  },
  {
    "x": "delxelin, -e",
    "y": " b. delxelî, -ye"
  },
  {
    "x": "delxelî, -ye",
    "y": " alaca (siyah-beyaz renkli)"
  },
  {
    "x": "delxelîn, -e",
    "y": " b. delxelî, -ye"
  },
  {
    "x": "delxer, -i",
    "y": " b. delxelî, -ye"
  },
  {
    "x": "delxerek, -e",
    "y": " alaca (siyah-beyaz renkli)"
  },
  {
    "x": "delxerîn, -i",
    "y": " b. delxeyîn, -e"
  },
  {
    "x": "delxeyîn, -e",
    "y": " alaca (siyah-beyaz renkli)"
  },
  {
    "x": "delxêr, -i",
    "y": " b. delxelî, -ye"
  },
  {
    "x": "delxêrek, -i",
    "y": " b. delxerek, -e"
  },
  {
    "x": "dem (n)",
    "y": " zaman, vakit; çağ\r\n~demo ameyox (n): gelecek zaman\r\n~demo nikayin (n): şimdiki zaman\r\n~demo vîyarte (n): geçmiş zaman"
  },
  {
    "x": "dem de",
    "y": " vaktinde, zamanında (tam vaktinde)"
  },
  {
    "x": "dem derbaz kerdene",
    "y": " vakit geçirmek, zaman geçirmek"
  },
  {
    "x": "demê roje (zh)",
    "y": " günün zamanları"
  },
  {
    "x": "demo ameyox (n)",
    "y": " gelecek zaman"
  },
  {
    "x": "demo derg (n)",
    "y": " hayli zaman, uzun zaman"
  },
  {
    "x": "demo hîra (n)",
    "y": " geniş zaman"
  },
  {
    "x": "demo ke",
    "y": " -diği zaman, -diği vakit, ne zaman ki"
  },
  {
    "x": "demo nikayin (n)",
    "y": " şimdiki zaman "
  },
  {
    "x": "demo vîyarte (n)",
    "y": " geçmiş zaman"
  },
  {
    "x": "demo vîyarteyo dîyar (n)",
    "y": " -di’li geçmiş zaman"
  },
  {
    "x": "demo vîyarteyo nedîyar (n)",
    "y": " -miş’li geçmiş zaman "
  },
  {
    "x": "demo vîyarteyo nedîyar o verên (n)",
    "y": " -miş’li geçmiş zamanın hikâyesi"
  },
  {
    "x": "demo vîyarteyo nikayin (n)",
    "y": " şimdiki zamanın hikâyesi"
  },
  {
    "x": "demo vîyarteyo nîyetin (n)",
    "y": " sanal geçmiş zaman, gelecek zamanın hikâyesi"
  },
  {
    "x": "demo vîyarteyo nîyetin o nêzdî (n)",
    "y": " yakın sanal geçmiş zaman"
  },
  {
    "x": "deman (n)",
    "y": " 1)icar\r\n~2)kira"
  },
  {
    "x": "deman kerdene",
    "y": " icar etmek, kiralamak"
  },
  {
    "x": "demance (n)",
    "y": " b. dabançe (n)"
  },
  {
    "x": "demança (m)",
    "y": " b. dabançe (n)"
  },
  {
    "x": "demankerdiş (n)",
    "y": " icar etme, kiralama"
  },
  {
    "x": "demar (m)",
    "y": " b. damare (m)"
  },
  {
    "x": "demarkaj (n)",
    "y": " demarkaj "
  },
  {
    "x": "demaskul (n)",
    "y": " b. dimaşkil (n)"
  },
  {
    "x": "demaşkul (n)",
    "y": " b. dimaşkil (n) "
  },
  {
    "x": "demçe (n)",
    "y": " belin (bel aracının) üzerine ayak konan parçası"
  },
  {
    "x": "demdemî, -ye",
    "y": " geçici, fâni, gelip geçici, kalımsız"
  },
  {
    "x": "demderbazkerdiş (n)",
    "y": " vakit geçirme, zaman geçirme"
  },
  {
    "x": "deme (n)",
    "y": " b. dem (n)"
  },
  {
    "x": "demew derg (n)",
    "y": " b. demo derg (n)"
  },
  {
    "x": "demewo derg (n)",
    "y": " b. demo derg (n)"
  },
  {
    "x": "demeşkul (n)",
    "y": " b. dimaşkil (n) "
  },
  {
    "x": "demeyê",
    "y": " b. demê"
  },
  {
    "x": "demeyêk",
    "y": " b. demêk "
  },
  {
    "x": "demê",
    "y": " vaktiyle, bir zamanlar"
  },
  {
    "x": "demêk",
    "y": " vaktiyle, bir zamanlar"
  },
  {
    "x": "demlig (n)",
    "y": " b. demlige (m)"
  },
  {
    "x": "demlige (m)",
    "y": " demlik"
  },
  {
    "x": "demokrasî (n)",
    "y": " demokrasi "
  },
  {
    "x": "demokrat, -e",
    "y": " demokrat"
  },
  {
    "x": "demokratîk, -e",
    "y": " demokratik "
  },
  {
    "x": "demonte, -ye",
    "y": " demonte"
  },
  {
    "x": "demserre (m)",
    "y": " mevsim"
  },
  {
    "x": "demûnci (n)",
    "y": " b. dabançe (n)"
  },
  {
    "x": "demûnçe (n)",
    "y": " b. dabançe (n)"
  },
  {
    "x": "demvîyarta (m)",
    "y": " anakronik"
  },
  {
    "x": "demvîyarte (n)",
    "y": " anakronik"
  },
  {
    "x": "demvîyartî, -ye",
    "y": " anakronik"
  },
  {
    "x": "demxe (m)",
    "y": " b. damxe (m)"
  },
  {
    "x": "demxeyin, -e",
    "y": " b. damxeyin, -e"
  },
  {
    "x": "den (n)",
    "y": " küp (büyük küp)"
  },
  {
    "x": "dendan (n)",
    "y": " b. didan (n)"
  },
  {
    "x": "dendik (n)",
    "y": " b. dendike (m)"
  },
  {
    "x": "dendike (m)",
    "y": " çekirdek"
  },
  {
    "x": "dendika goze (m)",
    "y": " çiğe"
  },
  {
    "x": "dendika vame (m)",
    "y": " badem içi, çiğe"
  },
  {
    "x": "dendikê verrojikî (zh)",
    "y": " ayçiçeği tohumu, ay çekirdeği"
  },
  {
    "x": "dendikê vila rojî (zh)",
    "y": " ayçiçeği tohumu, ay çekirdeği"
  },
  {
    "x": "dendiki (m)",
    "y": " b. dendike (m)"
  },
  {
    "x": "dene-I (n)",
    "y": " 1)büyük kazan\r\n~2)b. den (n)"
  },
  {
    "x": "dene-II (n)",
    "y": " b. tene-II (n)"
  },
  {
    "x": "deng, -e-I",
    "y": " sersem, alık"
  },
  {
    "x": "deng bîyayîne",
    "y": " b. deng bîyene"
  },
  {
    "x": "deng bîyene",
    "y": " sersemleşmek, sersemlemek"
  },
  {
    "x": "deng kerdene",
    "y": " sersemletmek, sersem etmek"
  },
  {
    "x": "deng-II (m)",
    "y": " b. dinge (m)"
  },
  {
    "x": "denga (m)",
    "y": " b. danga (m)"
  },
  {
    "x": "dengbêj, -e",
    "y": " ses sanatçısı, şarkıcı, türkücü"
  },
  {
    "x": "denge (m)",
    "y": " b. dinge (m)"
  },
  {
    "x": "denge ro dayene",
    "y": " b. dinge ro dayene"
  },
  {
    "x": "dengerodayîş (n)",
    "y": " b. dingerodayîş (n)"
  },
  {
    "x": "dengeser, -e",
    "y": " sersem, alık"
  },
  {
    "x": "dengeser bîyene",
    "y": " sersemleşmek, sersemlemek"
  },
  {
    "x": "dengeser kerdene",
    "y": " sersemletmek, sersem etmek"
  },
  {
    "x": "dengeserey (m)",
    "y": " b. dengeserîye (m)"
  },
  {
    "x": "dengeserê (m)",
    "y": " b. dengeserî (m)"
  },
  {
    "x": "dengeserî (m)",
    "y": " sersemlik, alıklık"
  },
  {
    "x": "dengeserîye (m)",
    "y": " sersemlik, alıklık"
  },
  {
    "x": "dengi (m)",
    "y": " b. dinge (m)"
  },
  {
    "x": "dengi ro dayene",
    "y": " b. dinge ro dayene"
  },
  {
    "x": "dengirodayîş (n)",
    "y": " b. dingerodayîş (n)"
  },
  {
    "x": "dengiser, -e",
    "y": " b. dengeser, -e"
  },
  {
    "x": "dengiser bîyayîne",
    "y": " b. dengeser bîyene"
  },
  {
    "x": "dengiser kerdene",
    "y": " b. dengeser kerdene"
  },
  {
    "x": "dengiserey (m)",
    "y": " b. dengeserîye (m)"
  },
  {
    "x": "dengiserê (m)",
    "y": " b. dengeserî (m)"
  },
  {
    "x": "dengiz (n)",
    "y": " deniz"
  },
  {
    "x": "dengsare",
    "y": " b. dengeser, -e"
  },
  {
    "x": "dengsare bîyayîne",
    "y": " b. dengeser bîyene"
  },
  {
    "x": "dengsare kerdene",
    "y": " b. dengeser kerdene"
  },
  {
    "x": "dengsarê (m)",
    "y": " b. dengeserî (m)"
  },
  {
    "x": "denişnayene",
    "y": " çökeltmek, çöktürmek (dibe çöktürmek)"
  },
  {
    "x": "denişnayîş (n)",
    "y": " çökeltme, çöktürme (dibe çöktürme)"
  },
  {
    "x": "deniştene",
    "y": " çökelmek, çökmek (dibe çökmek)"
  },
  {
    "x": "deniştiş (n)",
    "y": " çökelme, çökme (dibe çökme)"
  },
  {
    "x": "deniz (n)",
    "y": " b. dengiz (n)"
  },
  {
    "x": "denîsnayene",
    "y": " b. denişnayene"
  },
  {
    "x": "denîsnayîs (n)",
    "y": " b. denişnayîş (n)"
  },
  {
    "x": "denîstene",
    "y": " b. deniştene "
  },
  {
    "x": "denîstis (n)",
    "y": " b. deniştiş (n)"
  },
  {
    "x": "denîz (n)",
    "y": " b. dengiz (n)"
  },
  {
    "x": "denne (n)",
    "y": " 1)b. dene-I (n)\r\n~2)b. den (n)"
  },
  {
    "x": "Depe",
    "y": " Karakoçan"
  },
  {
    "x": "depêştiş (n)",
    "y": " b. tepîştiş (n)"
  },
  {
    "x": "depijdiş (n)",
    "y": " b. tepîştiş (n)"
  },
  {
    "x": "depiştene",
    "y": " b. tepîştene"
  },
  {
    "x": "depiştiş (n)",
    "y": " b. tepîştiş (n)"
  },
  {
    "x": "depîlasyon (n)",
    "y": " depilasyon"
  },
  {
    "x": "depîştene",
    "y": " b. tepîştene"
  },
  {
    "x": "depîştiş (n)",
    "y": " b. tepîştiş (n)"
  },
  {
    "x": "depîyeştiş (n)",
    "y": " b. tepîştiş (n)"
  },
  {
    "x": "depo (n)",
    "y": " 1)depo\r\n~depoyê ardûyî (n): yakıt deposu (odun vb. için)\r\n~depoyê çekan (n): silah deposu\r\n~depoyê sotemenî (n): yakıt deposu (sıvı ve gaz için)\r\n~2)ardiye"
  },
  {
    "x": "depo kerdene",
    "y": " depolamak, ambarlamak"
  },
  {
    "x": "depoyê ardûyî (n)",
    "y": " yakıt deposu (odun vb. için) "
  },
  {
    "x": "depoyê çekan (n)",
    "y": " silah deposu"
  },
  {
    "x": "depoyê malzemeyî (n)",
    "y": " malzeme deposu"
  },
  {
    "x": "depoyê sîlehan (n)",
    "y": " silah deposu"
  },
  {
    "x": "depoyê sotemenî (n)",
    "y": " yakıt deposu (sıvı ve gaz için)"
  },
  {
    "x": "depokar, -e",
    "y": " depocu"
  },
  {
    "x": "depokerdiş (n)",
    "y": " depolama, ambarlama"
  },
  {
    "x": "depovan, -e",
    "y": " depocu"
  },
  {
    "x": "depozîto (n)",
    "y": " depozito"
  },
  {
    "x": "depresyon (n)",
    "y": " bunalım (psikolojide), buhran, kriz, depresyon"
  },
  {
    "x": "deq (n)",
    "y": " nasır"
  },
  {
    "x": "deq kardene",
    "y": " b. deq kerdene"
  },
  {
    "x": "deq kerdene",
    "y": " damgalamak"
  },
  {
    "x": "deq piro dayene",
    "y": " damgalamak"
  },
  {
    "x": "deqa (m)",
    "y": " dakika "
  },
  {
    "x": "deqayene",
    "y": " nasırlaşmak, nasırlanmak"
  },
  {
    "x": "deqçekî (zh)",
    "y": " beştaş [oyunu]"
  },
  {
    "x": "deqdeqên, -e",
    "y": " çilli"
  },
  {
    "x": "deqdeqêni (m)",
    "y": " b. deqdeqêne (m)"
  },
  {
    "x": "deqe (m)",
    "y": " b. deqa (m)"
  },
  {
    "x": "deqîqa (m)",
    "y": " b. deqa (m)"
  },
  {
    "x": "deqîqe (m)",
    "y": " b. deqa (m)"
  },
  {
    "x": "deqkarda (m)",
    "y": " b. deqkerda (m)"
  },
  {
    "x": "deqkarde (n)",
    "y": " b. deqkerde (n)"
  },
  {
    "x": "deqkardiş (n)",
    "y": " b. deqkerdiş (n)"
  },
  {
    "x": "deqkerda (m)",
    "y": " damgalı"
  },
  {
    "x": "deqkerde (n)",
    "y": " damgalı"
  },
  {
    "x": "deqkerdiş (n)",
    "y": " damgalama"
  },
  {
    "x": "deqkerdî (m)",
    "y": " damgalı"
  },
  {
    "x": "deqkerdîye (m)",
    "y": " damgalı"
  },
  {
    "x": "deqmok (n)",
    "y": " b. toqmaq (n)"
  },
  {
    "x": "deqnaya (m)",
    "y": " damgalı"
  },
  {
    "x": "deqnaye (n)",
    "y": " damgalı"
  },
  {
    "x": "deqnayene",
    "y": " damgalamak"
  },
  {
    "x": "deqnayî (m)",
    "y": " damgalı"
  },
  {
    "x": "deqnayîş (n)",
    "y": " damgalama"
  },
  {
    "x": "deqnayîye (m)",
    "y": " damgalı"
  },
  {
    "x": "Deqo (n)",
    "y": " köpek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "deqpirodayîş (n)",
    "y": " damgalama"
  },
  {
    "x": "deqqa (m)",
    "y": " b. deqa (m)"
  },
  {
    "x": "deqqe (m)",
    "y": " b. deqa (m)"
  },
  {
    "x": "deqsur (n)",
    "y": " b. deqsûr"
  },
  {
    "x": "deqsûr (n)",
    "y": " alabalık"
  },
  {
    "x": "der (pd)",
    "y": " b. de (pd)\r\n~Lajek keye der o. (Oğlan evdedir.)"
  },
  {
    "x": "der bîyene",
    "y": " çıkmak\r\n~\"Va der bîyo, yeno Kivara mi, vayê dotî\" (Sevîm Aydın, Deyîrbazê Dêrsimî Saît Bakşî, r. 23)"
  },
  {
    "x": "der geyrayene",
    "y": " b. cigêrayene"
  },
  {
    "x": "der gêrayene",
    "y": " b. cigêrayene"
  },
  {
    "x": "der kerdene",
    "y": " 1)(yarada biriken irin veya kanı) dışarı akıtmak\r\n~2)drene etmek"
  },
  {
    "x": "der mendene (n)",
    "y": " b. dîyar mendene"
  },
  {
    "x": "der û cîra",
    "y": " b. der û cîran"
  },
  {
    "x": "der û cîran",
    "y": " konu komşu"
  },
  {
    "x": "der û cîro",
    "y": " b. der û cîran"
  },
  {
    "x": "der û cîron",
    "y": " b. der û cîran"
  },
  {
    "x": "der û cîrû",
    "y": " b. der û cîran"
  },
  {
    "x": "der û cîrûn",
    "y": " b. der û cîran"
  },
  {
    "x": "der-dor (n)",
    "y": " b. derûdor (n)"
  },
  {
    "x": "der-dormale (n)",
    "y": " b. derûdorme (n)"
  },
  {
    "x": "der-dorme (n)",
    "y": " b. derûdorme (n)"
  },
  {
    "x": "dera bîyene",
    "y": " b. tira bîyene, cira bîyene"
  },
  {
    "x": "dera kerdene",
    "y": " b. tira kerdene, cira kerdene"
  },
  {
    "x": "derabîyayîş (n)",
    "y": " b. tirabîyayîş (n), cirabîyayîş (n)"
  },
  {
    "x": "derakerdiş (n)",
    "y": " b. tirakerdiş (n), cirakerdiş (n)"
  },
  {
    "x": "deraqdê ... di",
    "y": " b. derheqê ... de"
  },
  {
    "x": "deraqê ... di",
    "y": " b. derheqê ... de"
  },
  {
    "x": "derax (n)",
    "y": " b. darax (n)"
  },
  {
    "x": "deray",
    "y": " 1)b. derê\r\n~2)b. derey"
  },
  {
    "x": "deraza (n)",
    "y": " b. datîza"
  },
  {
    "x": "derbas bîyayene",
    "y": " b. derbaz bîyene"
  },
  {
    "x": "derbas bîyene",
    "y": " b. derbaz bîyene"
  },
  {
    "x": "derbas kerdene",
    "y": " b. derbaz kerdene"
  },
  {
    "x": "derbasbîyayîş (n)",
    "y": " b. derbazbîyayîş (n)"
  },
  {
    "x": "derbaskerdiş (n)",
    "y": " b. derbazkerdiş (n)"
  },
  {
    "x": "derbaz bîyayene",
    "y": " b. derbaz bîyene"
  },
  {
    "x": "derbaz bîyene",
    "y": " 1)geçmek\r\n~2)akmak (zaman için)\r\n~3)bulaşmak (hastalık için)"
  },
  {
    "x": "derbaz kerdene",
    "y": " geçirmek"
  },
  {
    "x": "derbazê ... kerdene",
    "y": " bulaştırmak (hastalık için)"
  },
  {
    "x": "derbazê ... kerdiş",
    "y": " bulaştırma (hastalık için)"
  },
  {
    "x": "derbazbîyayîş (n)",
    "y": " 1)geçme\r\n~2)bulaşma (hastalık için)"
  },
  {
    "x": "derbazkerdiş (n)",
    "y": " geçirme"
  },
  {
    "x": "derbe (m)",
    "y": " 1)darbe\r\n~derba mergî (m): ölümcül darbe\r\n~2)kez, kere, defa, sefer\r\n~derbê: bir kez\r\n~derbêk: bir kez"
  },
  {
    "x": "derba mergî (m)",
    "y": " ölümcül darbe"
  },
  {
    "x": "derbê",
    "y": " bir kez"
  },
  {
    "x": "derbêk",
    "y": " bir kez"
  },
  {
    "x": "Derbek (m)",
    "y": " b. Dîyarbekir (n)"
  },
  {
    "x": "Derbeki (m)",
    "y": " b. Dîyarbekir (n)"
  },
  {
    "x": "Derbekir (m)",
    "y": " b. Dîyarbekir (n)"
  },
  {
    "x": "derbend (n)",
    "y": " geçit, derbent"
  },
  {
    "x": "derbi (m)",
    "y": " 1)b. darbe (m)\r\n~2)b. derbe (m)"
  },
  {
    "x": "derbîyayîş (n)",
    "y": " çıkma"
  },
  {
    "x": "derbna",
    "y": " yine, gene, tekrar"
  },
  {
    "x": "derbice (n)",
    "y": " b. darbice (n)"
  },
  {
    "x": "derbîyayîş (n)",
    "y": " yarada biriken irin veya kanın dışarı çıkması"
  },
  {
    "x": "dercenî (m)",
    "y": " b. derzîne (m) "
  },
  {
    "x": "derçêna (m)",
    "y": " b. datkêna (m)"
  },
  {
    "x": "derd (n)",
    "y": " dert\r\n~derdê cîgere (n): ciğer acısı, evlât acısı\r\n~derdê qeseba (n): ciğer acısı, evlât acısı"
  },
  {
    "x": "derdê cîgere (n)",
    "y": " ciğer acısı, evlât acısı"
  },
  {
    "x": "derdê ewladî/e (n)",
    "y": " evlât acısı, ciğer yarası "
  },
  {
    "x": "derdê oladî (n)",
    "y": " b. derdê ewladî/e (n)"
  },
  {
    "x": "derdê owladî (n)",
    "y": " b. derdê ewladî/e (n)"
  },
  {
    "x": "derdê qeseba (n)",
    "y": " ciğer acısı, evlât acısı"
  },
  {
    "x": "derdê zerri (n)",
    "y": " b. derdê zerrî (n)"
  },
  {
    "x": "derdê zerrî (n)",
    "y": " ciğer acısı, evlât acısı"
  },
  {
    "x": "derdin, -e",
    "y": " dertli"
  },
  {
    "x": "derdîyen",
    "y": " b. derdin, -e"
  },
  {
    "x": "derdnak, -e",
    "y": " dertli"
  },
  {
    "x": "dere (n)",
    "y": " dere"
  },
  {
    "x": "derewo zuwa (n)",
    "y": " b. dereyo ziwa (n)"
  },
  {
    "x": "derewo zuya (n)",
    "y": " b. dereyo ziwa (n)"
  },
  {
    "x": "dereyo ziwa (n)",
    "y": " kuruyan dere"
  },
  {
    "x": "dero juya (n)",
    "y": " b. dereyo ziwa (n)"
  },
  {
    "x": "dere bîyene",
    "y": " b. tira bîyene, cira bîyene"
  },
  {
    "x": "dere kerdene",
    "y": " b. tira kerdene, cira kerdene"
  },
  {
    "x": "derebeg (n)",
    "y": " derebeyi, feodal"
  },
  {
    "x": "derebegî (m)",
    "y": " derebeylik, feodalite, feodalizm"
  },
  {
    "x": "dereca (m)",
    "y": " b. derece (m)"
  },
  {
    "x": "derece (m)",
    "y": " derece"
  },
  {
    "x": "derek (n)",
    "y": " b. terek (n)"
  },
  {
    "x": "derekedîn, -e",
    "y": " dindışı"
  },
  {
    "x": "derenca (m)",
    "y": " merdiven"
  },
  {
    "x": "dereng (n)",
    "y": " geç"
  },
  {
    "x": "dereng kewtene",
    "y": " gecikmek, geç kalmak"
  },
  {
    "x": "dereng kûtene",
    "y": " b. dereng kewtene, erey kewtene"
  },
  {
    "x": "dereng mendene",
    "y": " gecikmek, geç kalmak"
  },
  {
    "x": "derengkewtiş (n)",
    "y": " gecikme, geç kalma"
  },
  {
    "x": "derengkûtiş (n)",
    "y": " b. derengkewtiş (n), ereykewtiş (n)"
  },
  {
    "x": "derengmendiş (n)",
    "y": " gecikme, geç kalma"
  },
  {
    "x": "deresaya (m)",
    "y": " olgun (meyve vb. için)"
  },
  {
    "x": "deresaye (n)",
    "y": " olgun (meyve vb. için)"
  },
  {
    "x": "deresayene",
    "y": " olgunlaşmak (meyve vb. için)"
  },
  {
    "x": "deresayîş (n)",
    "y": " olgunlaşma (meyve vb. için)"
  },
  {
    "x": "deresayîye (m)",
    "y": " olgun (meyve vb. için)"
  },
  {
    "x": "deresnayene",
    "y": " olgunlaştırmak (meyve vb. için)"
  },
  {
    "x": "deresnayîş (n)",
    "y": " olgunlaştırma (meyve vb. için)"
  },
  {
    "x": "derewtî (m)",
    "y": " b. direwte (m)"
  },
  {
    "x": "derey",
    "y": " hele\r\n~Derey bê tîya. (Hele buraya gel.)"
  },
  {
    "x": "dereyek (n)",
    "y": " derecik"
  },
  {
    "x": "dereza (n)",
    "y": " b. datîza"
  },
  {
    "x": "dereznayene",
    "y": " b. deriznayene"
  },
  {
    "x": "derezyayene",
    "y": " b. derizîyayene"
  },
  {
    "x": "derezyayîş (n)",
    "y": " b. derizîyayîş (n)"
  },
  {
    "x": "derê",
    "y": " 1)acaba\r\n~2)b. derey"
  },
  {
    "x": "derg, -e",
    "y": " uzun"
  },
  {
    "x": "dergevan, -e",
    "y": " b. dergewan, -e"
  },
  {
    "x": "dergewan, -e",
    "y": " kapıcı"
  },
  {
    "x": "dergey (m)",
    "y": " b. dergî (m)"
  },
  {
    "x": "dergeyrayîş (n)",
    "y": " b. cigêrayîş (n)"
  },
  {
    "x": "dergê (m)",
    "y": " b. dergî (m)"
  },
  {
    "x": "dergênî (m)",
    "y": " uzunluk"
  },
  {
    "x": "dergêrayîş (n)",
    "y": " b. cigêrayîş (n)"
  },
  {
    "x": "dergî (m)",
    "y": " 1)uzunluk \r\n~dergîya guzergahî (m): güzergâh uzunluğu\r\n~2)boy (mat)\r\n~dergîya pêlî (m): dalga boyu"
  },
  {
    "x": "dergîya guzergahî (m)",
    "y": " güzergâh uzunluğu"
  },
  {
    "x": "dergîya pêlî (m)",
    "y": " dalga boyu"
  },
  {
    "x": "dergîye (m)",
    "y": " 1)uzunluk\r\n~2)boy (mat)"
  },
  {
    "x": "dergramitişî (m)",
    "y": " sürerlik"
  },
  {
    "x": "derguse (m)",
    "y": " b. derguşe (m) "
  },
  {
    "x": "derguş (m)",
    "y": " b. derguşe (m)"
  },
  {
    "x": "derguşe (m)",
    "y": " beşik"
  },
  {
    "x": "derguşek (m)",
    "y": " b. derguşe (m)"
  },
  {
    "x": "derguşeki (m)",
    "y": " b. derguşe (m) "
  },
  {
    "x": "derguşi (m)",
    "y": " b. derguşe (m)"
  },
  {
    "x": "dergûş (m)",
    "y": " b. derguşe (m)"
  },
  {
    "x": "dergûşa (m)",
    "y": " b. derguşe (m)"
  },
  {
    "x": "dergûşek (m)",
    "y": " b. derguşe (m) "
  },
  {
    "x": "dergûşi (m)",
    "y": " b. derguşe (m)"
  },
  {
    "x": "derheq ... di",
    "y": " b. derheqê ... de"
  },
  {
    "x": "derheqa ... di",
    "y": " b. derheqê ... de"
  },
  {
    "x": "derheqê ... de",
    "y": " hakkında"
  },
  {
    "x": "derheqdê ... di",
    "y": " b. derheqê ... de"
  },
  {
    "x": "derheqê ... de qalî kerdene",
    "y": " ... hakkında konuşmak"
  },
  {
    "x": "derheqê ... de qisey kerdene",
    "y": " ... hakkında konuşmak"
  },
  {
    "x": "deri (n)",
    "y": " b. dere (n)"
  },
  {
    "x": "derisîyayene",
    "y": " b. rarêsikîyayene"
  },
  {
    "x": "derisîyayîş (n)",
    "y": " b. rarêsikîyayîş (n)"
  },
  {
    "x": "derizikin, -e",
    "y": " kaygan"
  },
  {
    "x": "derizîyayene-I",
    "y": " çatlamak (kemik vb. için) "
  },
  {
    "x": "derizîyayene-II",
    "y": " b. rarêsikîyayene"
  },
  {
    "x": "derizîyayîş-I (n)",
    "y": " çatlama (kemik vb. için)"
  },
  {
    "x": "derizîyayîş-II (n)",
    "y": " b. rarêsikîyayîş (n)"
  },
  {
    "x": "deriznayene",
    "y": " çatlatmak (kemik vb. için) "
  },
  {
    "x": "deriznayîş (n)",
    "y": " çatlatma (kemik vb. için)"
  },
  {
    "x": "derîya (n)",
    "y": " b. derya (n)"
  },
  {
    "x": "derjenî (m)",
    "y": " b. derzîne (m) "
  },
  {
    "x": "derjên (m)",
    "y": " b. derzîne (m) "
  },
  {
    "x": "derjêne (m)",
    "y": " b. derzîne (m) "
  },
  {
    "x": "derjîne (m)",
    "y": " b. derzîne (m)"
  },
  {
    "x": "derkenar (n)",
    "y": " çıkıntı (yazıda), çıkma (yazıda)"
  },
  {
    "x": "derma (n)",
    "y": " b. derman (n)"
  },
  {
    "x": "derman (n)",
    "y": " derman, ilaç"
  },
  {
    "x": "derman kerdene",
    "y": " ilaçlamak"
  },
  {
    "x": "dermanê kilîncan (n)",
    "y": " çörek otu, çöre otu"
  },
  {
    "x": "dermankar, -e",
    "y": " eczacı"
  },
  {
    "x": "dermankerdiş (n)",
    "y": " ilaçlama"
  },
  {
    "x": "dermendiş (n)",
    "y": " b. dîyar mendiş (n)"
  },
  {
    "x": "dermo (n)",
    "y": " b. derman (n)"
  },
  {
    "x": "dermon (n)",
    "y": " b. derman (n)"
  },
  {
    "x": "dermû (n)",
    "y": " b. derman (n)"
  },
  {
    "x": "dermûn (n)",
    "y": " b. derman (n)"
  },
  {
    "x": "derne (n)",
    "y": " çiçek gibi hastalıklardan sonra yüzde kalan yara izi"
  },
  {
    "x": "derneyin, -e",
    "y": " çiçekbozuğu, çopur"
  },
  {
    "x": "ders (n)",
    "y": " b. derse (m)"
  },
  {
    "x": "dersaya (m)",
    "y": " b. deresaya (m), deresayîye (m)"
  },
  {
    "x": "dersaye (n)",
    "y": " b. deresaye (n)"
  },
  {
    "x": "dersayene",
    "y": " b. deresayene"
  },
  {
    "x": "dersayîş (n)",
    "y": " b. deresayîş (n)"
  },
  {
    "x": "dersdar, -e",
    "y": " eğitmen"
  },
  {
    "x": "derse (m)",
    "y": " ders "
  },
  {
    "x": "dersa deştene (m)",
    "y": " dikiş dersi "
  },
  {
    "x": "dersi (m)",
    "y": " b. derse (m)"
  },
  {
    "x": "dersnayene",
    "y": " b. deresnayene"
  },
  {
    "x": "dersnayîş (n)",
    "y": " b. deresnayîş (n)"
  },
  {
    "x": "dersxane (n)",
    "y": " dershane, derslik"
  },
  {
    "x": "derutene",
    "y": " süpürmek"
  },
  {
    "x": "derutiş (n)",
    "y": " süpürme"
  },
  {
    "x": "derûdolme (n)",
    "y": " b. derûdorme (n)"
  },
  {
    "x": "derûdomar (n)",
    "y": " b. derûdorme (n)"
  },
  {
    "x": "derûdomare (n)",
    "y": " b. derûdorme (n)"
  },
  {
    "x": "derûdor (n)",
    "y": " çevre, dolay, etraf"
  },
  {
    "x": "derûdormare (n)",
    "y": " b. derûdorme (n)"
  },
  {
    "x": "derûdorme (n)",
    "y": " çevre, dolay, etraf"
  },
  {
    "x": "derûdorme girewtene",
    "y": " abluka etmek, çevrelemek, ihata etmek"
  },
  {
    "x": "derûdormegirewtiş (n)",
    "y": " abluka, abluka etme, kuşatma, muhasara, çevreleme"
  },
  {
    "x": "derve (m)",
    "y": " b. darbe (m)"
  },
  {
    "x": "derya (n)",
    "y": " 1)deniz\r\n~2)çok fazla, diz boyu (çok miktarda)"
  },
  {
    "x": "deryayo girewte (n)",
    "y": " kapalı deniz"
  },
  {
    "x": "deryakar, -e",
    "y": " denizci"
  },
  {
    "x": "deryarayîr (n)",
    "y": " denizyolu"
  },
  {
    "x": "deryayî, -ye",
    "y": " denizle ilgili, bahri"
  },
  {
    "x": "derz-I (n)",
    "y": " derz (mimarlıkta)"
  },
  {
    "x": "derz-II (n)",
    "y": " b. derse (m)"
  },
  {
    "x": "derzayene",
    "y": " b. derizîyayene"
  },
  {
    "x": "derzayîş (n)",
    "y": " b. derizîyayîş (n)"
  },
  {
    "x": "derze (n)",
    "y": " şelek\r\n~derzeyê çaran (n): çalı çırpı şeleği\r\n~derzeyê hejikan (n): çalı çırpı şeleği"
  },
  {
    "x": "derze guretene",
    "y": " b. derze wegirewtene"
  },
  {
    "x": "derze kerdene",
    "y": " şelek hazırlamak"
  },
  {
    "x": "derze piro nayene",
    "y": " şelek hazırlamak"
  },
  {
    "x": "derze piştî kerdene",
    "y": " şelek sırtlamak, şelek yüklenmek"
  },
  {
    "x": "derze poştî kerdene",
    "y": " b. derze piştî kerdene "
  },
  {
    "x": "derze puri nayîne",
    "y": " b. derze piro nayene"
  },
  {
    "x": "derze wegirewtene",
    "y": " şelek sırtlamak, şelek yüklenmek"
  },
  {
    "x": "derze wegirotene",
    "y": " b. derze wegirewtene"
  },
  {
    "x": "derze wegrotene",
    "y": " b. derze wegirewtene"
  },
  {
    "x": "derzey çarran (n)",
    "y": " b. derzeyê çaran"
  },
  {
    "x": "derzeyê çaran (n)",
    "y": " çalı çırpı şeleği"
  },
  {
    "x": "derzeyê hejikan (n)",
    "y": " çalı çırpı şeleği"
  },
  {
    "x": "derzeyê hezikan (n)",
    "y": " b. derzeyê hejikan (n)"
  },
  {
    "x": "derzê çarrûn (n)",
    "y": " b. derzeyê çaran"
  },
  {
    "x": "derzenî (m)",
    "y": " b. derzîne (m)"
  },
  {
    "x": "derzênî (m)",
    "y": " b. derzîne (m) "
  },
  {
    "x": "derzi (n)",
    "y": " b. derze (n)"
  },
  {
    "x": "derzinî (m)",
    "y": " b. derzîne (m)"
  },
  {
    "x": "derzî (m)",
    "y": " b. dezî (m)"
  },
  {
    "x": "derzîdane (m)",
    "y": " b. derzîndane (m)"
  },
  {
    "x": "derzîdank (m)",
    "y": " b. derzîdane (m)"
  },
  {
    "x": "derzîdanke (m)",
    "y": " b. derzîdane (m)"
  },
  {
    "x": "derzîn (m)",
    "y": " b. derzîne (m) "
  },
  {
    "x": "derzîndane (m)",
    "y": " iğnelik, iğne yastığı, iğnedenlik"
  },
  {
    "x": "derzîne (m)",
    "y": " 1)iğne\r\n~2)aşı (tıpta)"
  },
  {
    "x": "derzîna lihêfan (m)",
    "y": " yorgan iğnesi"
  },
  {
    "x": "derzîne dayene ... ro",
    "y": " iğne yapmak, iğne vurmak, aşı yapmak\r\n~Doktorî derzîne daya ey ro. (Doktor ona iğne yapmış.)"
  },
  {
    "x": "derzîne pirodayene",
    "y": " aşılamak (tıpta)"
  },
  {
    "x": "derzîne piroginayene",
    "y": " aşılanmak (tıpta)"
  },
  {
    "x": "derzîneke (m)",
    "y": " iğnelik, iğne yastığı, iğnedenlik"
  },
  {
    "x": "derzînepirodayîş (n)",
    "y": " aşılama (tıpta)"
  },
  {
    "x": "derzînepiroginayîş (n)",
    "y": " aşılanma (tıpta)"
  },
  {
    "x": "derzîni (m)",
    "y": " b. derzîne (m)"
  },
  {
    "x": "derzînkar, -e",
    "y": " iğneci"
  },
  {
    "x": "derzîyayene",
    "y": " dikilmek"
  },
  {
    "x": "derzîyayîş (n)",
    "y": " dikilme"
  },
  {
    "x": "derznayene",
    "y": " b. deriznayene"
  },
  {
    "x": "des",
    "y": " on"
  },
  {
    "x": "des bi des",
    "y": " onar onar"
  },
  {
    "x": "des çihar",
    "y": " b. çarês"
  },
  {
    "x": "des des",
    "y": " b. des bi des"
  },
  {
    "x": "des î çeer",
    "y": " b. çarês"
  },
  {
    "x": "des î di",
    "y": " b. diwês"
  },
  {
    "x": "des î heşt",
    "y": " b. heştês"
  },
  {
    "x": "des î hîrê",
    "y": " b. hîrês"
  },
  {
    "x": "des î huwt",
    "y": " b. hewtês"
  },
  {
    "x": "des î niw",
    "y": " b. newês"
  },
  {
    "x": "des î pûnc",
    "y": " b. pancês"
  },
  {
    "x": "des î seş",
    "y": " b. şîyês"
  },
  {
    "x": "des î yiw",
    "y": " b. yewendes"
  },
  {
    "x": "des û çar",
    "y": " b. çarês"
  },
  {
    "x": "des û çear",
    "y": " b. çarês"
  },
  {
    "x": "des û çehar",
    "y": " b. çarês"
  },
  {
    "x": "des û çihar",
    "y": " b. çarês"
  },
  {
    "x": "des û çor",
    "y": " b. çarês"
  },
  {
    "x": "des û di",
    "y": " b. diwês"
  },
  {
    "x": "des û dide",
    "y": " b. diwês"
  },
  {
    "x": "des û didi",
    "y": " b. diwês"
  },
  {
    "x": "des û hawt",
    "y": " b. hewtês "
  },
  {
    "x": "des û heşt",
    "y": " b. heştês"
  },
  {
    "x": "des û hewt",
    "y": " b. hewtês "
  },
  {
    "x": "des û heyşt",
    "y": " b. heştês"
  },
  {
    "x": "des û hîre",
    "y": " b. hîrês"
  },
  {
    "x": "des û hîrê",
    "y": " b. hîrês"
  },
  {
    "x": "des û hîri",
    "y": " b. hîrês"
  },
  {
    "x": "des û hot",
    "y": " b. hewtês"
  },
  {
    "x": "des û jew",
    "y": " b. yewendes"
  },
  {
    "x": "des û jo",
    "y": " b. yewendes "
  },
  {
    "x": "des û ju",
    "y": " b. yewendes"
  },
  {
    "x": "des û juyîn, -e",
    "y": " b. yewendes"
  },
  {
    "x": "des û jû",
    "y": " b. yewendes"
  },
  {
    "x": "des û new",
    "y": " b. newês"
  },
  {
    "x": "des û paj",
    "y": " b. pancês "
  },
  {
    "x": "des û panc",
    "y": " b. pancês"
  },
  {
    "x": "des û panj",
    "y": " b. pancês"
  },
  {
    "x": "des û ponc",
    "y": " b. pancês"
  },
  {
    "x": "des û ponz",
    "y": " b. pancês"
  },
  {
    "x": "des û seş",
    "y": " b. şîyês "
  },
  {
    "x": "des û şes",
    "y": " b. şîyês"
  },
  {
    "x": "des û şeş",
    "y": " b. şîyês"
  },
  {
    "x": "des û zu",
    "y": " b. yewendes"
  },
  {
    "x": "des ve des",
    "y": " b. des bi des"
  },
  {
    "x": "desan",
    "y": " onlar (mat)"
  },
  {
    "x": "desaweyîş (n)",
    "y": " b. desawîyayîş (n)"
  },
  {
    "x": "desawita (m)",
    "y": " sürülü"
  },
  {
    "x": "desawite (n)",
    "y": " sürülü"
  },
  {
    "x": "desawitene",
    "y": " 1)sürmek\r\n~2)bulaştırmak (fiziki şeyler için)"
  },
  {
    "x": "desawiteni",
    "y": " b. desawitene"
  },
  {
    "x": "desawitiş (n)",
    "y": " 1)sürme\r\n~2)bulaştırma (fiziki şeyler için)"
  },
  {
    "x": "desawîyaya (m)",
    "y": " sürülü"
  },
  {
    "x": "desawîyaye (n)",
    "y": " sürülü"
  },
  {
    "x": "desawîyayene",
    "y": " 1)-e sürülmek\r\n~2)bulaşmak (fiziki şeyler için)"
  },
  {
    "x": "desawîyayeni",
    "y": " b. desawîyayene"
  },
  {
    "x": "desawîyayîş (n)",
    "y": " bulaşma (fiziki şeyler için)"
  },
  {
    "x": "desawîyeyîş (n)",
    "y": " b. desawîyayîş (n)"
  },
  {
    "x": "desawteni",
    "y": " b. desawitene"
  },
  {
    "x": "desawtiş (n)",
    "y": " b. desawitiş (n)"
  },
  {
    "x": "desawutene",
    "y": " b. desawitene"
  },
  {
    "x": "desawutiş (n)",
    "y": " b. desawitiş (n)"
  },
  {
    "x": "desawuyayene",
    "y": " b. desawîyayene"
  },
  {
    "x": "desawuyayîş (n)",
    "y": " b. desawîyayîş (n)"
  },
  {
    "x": "desawyayene",
    "y": " b. desawîyayene"
  },
  {
    "x": "desawyayeni",
    "y": " b. desawîyayene"
  },
  {
    "x": "desawyayîş (n)",
    "y": " b. desawîyayîş (n)"
  },
  {
    "x": "deseke (m)",
    "y": " b. destekî (m)"
  },
  {
    "x": "deseki (m)",
    "y": " b. destekî (m)"
  },
  {
    "x": "desen (n)",
    "y": " desen "
  },
  {
    "x": "desenin, -e",
    "y": " desenli"
  },
  {
    "x": "desenkêş, -e",
    "y": " desinatör"
  },
  {
    "x": "desentralî (m)",
    "y": " ademimerkeziyet, yerinden yönetim"
  },
  {
    "x": "desentralîzasyon (n)",
    "y": " ademimerkeziyetçilik, yerinden yönetimcilik"
  },
  {
    "x": "desenvetiş (n)",
    "y": " desen çıkarma"
  },
  {
    "x": "desenviraştiş (n)",
    "y": " desen çıkarma"
  },
  {
    "x": "desgram (n)",
    "y": " dekagram"
  },
  {
    "x": "desin, -e",
    "y": " onuncu"
  },
  {
    "x": "desinayene",
    "y": " b. deşinayene"
  },
  {
    "x": "desinde",
    "y": " hemen, anında, birdenbire"
  },
  {
    "x": "desinde merdene",
    "y": " hemen ölmek, anında ölmek"
  },
  {
    "x": "desine (m)",
    "y": " 1)onluk\r\n~2)onuncu"
  },
  {
    "x": "desî",
    "y": " onlar (mat)"
  },
  {
    "x": "desîbel (n)",
    "y": " desibel (ses, sinyal vb. için)"
  },
  {
    "x": "desîgram (n)",
    "y": " desigram"
  },
  {
    "x": "desîlître (n)",
    "y": " desilitre"
  },
  {
    "x": "desîmetre (n)",
    "y": " desimetre"
  },
  {
    "x": "desîmetrekare (n)",
    "y": " desimetrekare"
  },
  {
    "x": "desîmetremîkab (n)",
    "y": " desimetreküp"
  },
  {
    "x": "desînator, -e",
    "y": " desinatör"
  },
  {
    "x": "deskeyna (m)",
    "y": " b. datkêna (m)"
  },
  {
    "x": "deslître (n)",
    "y": " dekalitre"
  },
  {
    "x": "desmac (n)",
    "y": " b. destmaj (n)"
  },
  {
    "x": "desmac vecîyayene",
    "y": " b. destmaj vejîyayene"
  },
  {
    "x": "desmacvecîyayîş (n)",
    "y": " b. destmajvejîyayîş (n)"
  },
  {
    "x": "desmaj (n)",
    "y": " b. destmaj (n)"
  },
  {
    "x": "desmal (m)",
    "y": " b. destmale (m)"
  },
  {
    "x": "desmale (m)",
    "y": " b. destmale (m)"
  },
  {
    "x": "desmali (m)",
    "y": " b. destmale (m)"
  },
  {
    "x": "desmetre (n)",
    "y": " dekametre"
  },
  {
    "x": "desmetrekare (n)",
    "y": " dekametrekare"
  },
  {
    "x": "desmetremîkab (n)",
    "y": " dekametreküp"
  },
  {
    "x": "despot, -e",
    "y": " despot, tiran, zorba"
  },
  {
    "x": "despotane",
    "y": " despotça, despotik"
  },
  {
    "x": "despotîzm (n)",
    "y": " despotizm, despotluk, istibdat"
  },
  {
    "x": "despotkî",
    "y": " despotça, despotik"
  },
  {
    "x": "desra",
    "y": " onda (mat)"
  },
  {
    "x": "desrayin",
    "y": " ondalık (mat)"
  },
  {
    "x": "dest (n)",
    "y": " el\r\n~Destê to tern bîy/bê. (Eline sağlık.)\r\n~\t\r\n~Destê to weş bîy/bê. (Eline sağlık.)\r\n~\t\r\n~Destê to zergûn bîy/bê. (Eline sağlık.)"
  },
  {
    "x": "dest bi aksîyon kerdene",
    "y": " eyleme geçmek"
  },
  {
    "x": "dest bi çalakî kerdene",
    "y": " eyleme geçmek"
  },
  {
    "x": "dest ci kerdene",
    "y": " b. dest pê kerdene"
  },
  {
    "x": "dest dayene",
    "y": " 1)desteklemek\r\n~2)elverişli olmak, uygun düşmek, uygun gelmek, uygun olmak, uymak,  yakışmak, yaraşmak\r\n~3)karışmak (dokunmak)\r\n~Dest mede tutî! (Çocuğa karışma!)"
  },
  {
    "x": "dest girewtene",
    "y": " desteklemek"
  },
  {
    "x": "dest pakerdene",
    "y": " b. dest pê kerdene"
  },
  {
    "x": "dest panayene",
    "y": " karışmak, dokunmak (el, parmak vb. ile)\r\n~Dest pa mene. (Dokunma.)\r\n~Destê xo pana. (Dokundu.)"
  },
  {
    "x": "dest pede nayene",
    "y": " dokunmak (el, parmak vb. ile)\r\n~Dest pede mene. (Dokunma.)"
  },
  {
    "x": "dest pey kerdene",
    "y": " b. dest pê kerdene"
  },
  {
    "x": "dest pê kerdene",
    "y": " başlamak"
  },
  {
    "x": "dest pira nayene",
    "y": " 1)karışmak (dokunmak)\r\n~Dest pira mene. (Dokunma.)\r\n~2)b. dest pê kerdene"
  },
  {
    "x": "dest piro nayene",
    "y": " b. dest pira nayene"
  },
  {
    "x": "dest ra çîyêk nêameyene",
    "y": " acze düşmek"
  },
  {
    "x": "dest ... ra nayene",
    "y": " dokunmak (el, parmak vb. ile)"
  },
  {
    "x": "dest ... ra nayîş",
    "y": " dokunma (el, parmak vb. ile)"
  },
  {
    "x": "dest ronayene",
    "y": " boyun eğmek, itaat etmek"
  },
  {
    "x": "dest sar nayîne",
    "y": " b. dest ser nayene"
  },
  {
    "x": "dest ser nayene",
    "y": " el koymak"
  },
  {
    "x": "dest ra teba nêameyene",
    "y": " acze düşmek"
  },
  {
    "x": "dest têvdayene",
    "y": " karışmak (dokunmak)"
  },
  {
    "x": "dest û lingan ra birîyayene",
    "y": " eli ayağı tutmaz olmak, yatalak olmak"
  },
  {
    "x": "dest û linganrabirîyayîş (n)",
    "y": " eli ayağı tutmaz olma"
  },
  {
    "x": "dest û lingî ra birîyayene",
    "y": " b. dest û lingan ra birîyayene"
  },
  {
    "x": "dest û lingîrabirîyayîş (n)",
    "y": " b. dest û linganrabirîyayîş (n)"
  },
  {
    "x": "dest û pay ra kewtene",
    "y": " b. dest û payan ra kewtene"
  },
  {
    "x": "dest û payan ra kewtene",
    "y": " eli ayağı tutmaz olmak, yatalak olmak"
  },
  {
    "x": "dest û payanrakewtiş (n)",
    "y": " eli ayağı tutmaz olma"
  },
  {
    "x": "dest û payrakewtiş (n)",
    "y": " b. dest û payanrakewtiş (n)"
  },
  {
    "x": "dest xo ra veradayene",
    "y": " boyun eğmek, itaat etmek, teslim olmak"
  },
  {
    "x": "dest xwi ra veradayene",
    "y": " b. dest xo ra veradayene"
  },
  {
    "x": "destê nimacî (n) ",
    "y": " b. destê nimajî (n)"
  },
  {
    "x": "destê nimajî (n)",
    "y": " şafak vakti"
  },
  {
    "x": "destê sibay (n)",
    "y": " b. destê sibayî (n)"
  },
  {
    "x": "destê sibayî (n)",
    "y": " şafak vakti"
  },
  {
    "x": "destê şefaqî (n)",
    "y": " b. destê şefeqî (n)"
  },
  {
    "x": "destê şefeqî (n)",
    "y": " şafak vakti"
  },
  {
    "x": "Destê to tern bîy/bê. ",
    "y": "(Eline sağlık.)"
  },
  {
    "x": "Destê to weş bîy/bê. ",
    "y": "(Eline sağlık.)"
  },
  {
    "x": "Destê to zergûn bîy/bê. ",
    "y": "(Eline sağlık.)"
  },
  {
    "x": "destê xo panayene",
    "y": " karışmak (dokunmak)\r\n~Eke ti destê xo pa nî ez qebul nêkena. (Karışırsan kabul etmem.)"
  },
  {
    "x": "destê xo pêser nayene",
    "y": " teslim olmak"
  },
  {
    "x": "destê xo ... ra nayene",
    "y": " -e karışmak, -e dokunmak\r\n~Eke ti destê xo ey ra nî ez qebul nêkena. (Ona karışırsan kabul etmem.)\r\n~Destê xo nê kitaban ra mene. (Bu kitaplara dokunma.)"
  },
  {
    "x": "destê xwi ponayene",
    "y": " b. destê xo panayene"
  },
  {
    "x": "destî ro pêser nayene",
    "y": " teslim olmak"
  },
  {
    "x": "destî sibay (n)",
    "y": " b. destê sibayî (n)"
  },
  {
    "x": "destî siway (n)",
    "y": " b. destê sibayî (n)"
  },
  {
    "x": "destî xo verakerdene",
    "y": " boyun eğmek, itaat etmek"
  },
  {
    "x": "destî xwi verakerdene",
    "y": " b. destî xo verakerdene"
  },
  {
    "x": "desto dîyin (n)",
    "y": " ikinci el"
  },
  {
    "x": "dêst siway (n)",
    "y": " b. destê sibayî (n)"
  },
  {
    "x": "Dêst tu tern/ter îb. ",
    "y": "b. Destê to tern bîy/bê."
  },
  {
    "x": "desta-I (m)",
    "y": " çalı süpürgesi"
  },
  {
    "x": "desta-II",
    "y": " b. deste-II"
  },
  {
    "x": "desta merdene",
    "y": " b. deste merdene"
  },
  {
    "x": "destakerda (m)",
    "y": " cömert"
  },
  {
    "x": "destakerde (n)",
    "y": " cömert"
  },
  {
    "x": "destakerdeyê (m)",
    "y": " b. destakerdeyî (m)"
  },
  {
    "x": "destakerdeyî (m)",
    "y": " cömertlik"
  },
  {
    "x": "destakerdeyîye (m)",
    "y": " cömertlik"
  },
  {
    "x": "destakerdê (m)",
    "y": " b. destakerdî (m)"
  },
  {
    "x": "destakerdêyey (m)",
    "y": " b. destakerdîyî (m)"
  },
  {
    "x": "destakerdî (m)",
    "y": " cömert"
  },
  {
    "x": "destakerdîye (m)",
    "y": " cömert"
  },
  {
    "x": "destakerdîyî (m)",
    "y": " cömertlik"
  },
  {
    "x": "destakerdîyîye (m)",
    "y": " cömertlik "
  },
  {
    "x": "destane (m)",
    "y": " destan"
  },
  {
    "x": "destankî",
    "y": " destansal "
  },
  {
    "x": "destar (m)",
    "y": " b. destarî (m)"
  },
  {
    "x": "destari (m)",
    "y": " b. destarî (m)"
  },
  {
    "x": "destarî (m)",
    "y": " el değirmeni"
  },
  {
    "x": "destarrî (m)",
    "y": " b. destarî (m)"
  },
  {
    "x": "destaw (m)",
    "y": " b. destawe (m)"
  },
  {
    "x": "destaw kerdene",
    "y": " b. destawe kerdene"
  },
  {
    "x": "destawe (m)",
    "y": " 1)defihacet gereksinimi\r\n~2)sidik, idrar, küçük abdest, küçük aptes\r\n~destawa pîle (m): büyük abdest, büyük aptes, insan dışkısı, bok\r\n~destawa şenike (m): sidik, idrar, küçük abdest, küçük aptes"
  },
  {
    "x": "destawa pîle (m)",
    "y": " büyük abdest, büyük aptes, insan dışkısı, bok"
  },
  {
    "x": "destawa şenike (m)",
    "y": " sidik, idrar, küçük abdest, küçük aptes"
  },
  {
    "x": "destawa şinike (m)",
    "y": " b. destawa şenike (m)"
  },
  {
    "x": "destawe deverdayene",
    "y": " işemek, su dökmek, küçük abdest bozmak, küçük aptes bozmak"
  },
  {
    "x": "destawe deverdayêne",
    "y": " b. destawe deverdayene"
  },
  {
    "x": "destawe kerdene",
    "y": " 1)işemek, su dökmek, küçük abdest bozmak, küçük aptes bozmak\r\n~2)tuvalet ihtiyacını gidermek"
  },
  {
    "x": "destawedeverdayîş (n)",
    "y": " işeme, su dökme, küçük abdest bozma, küçük aptes bozma"
  },
  {
    "x": "destawekerdiş (n)",
    "y": "1)işeme, su dökme, küçük abdest bozma, küçük aptes bozma\r\n~2)defihacet, tuvalet ihtiyacını giderme"
  },
  {
    "x": "destawi (m)",
    "y": " b. destawe (m)"
  },
  {
    "x": "destawi kerdene",
    "y": " b. destawe kerdene"
  },
  {
    "x": "destawikerdiş (n)",
    "y": " b. destawekerdiş (n)"
  },
  {
    "x": "destawî (m)",
    "y": " b. destawe (m)"
  },
  {
    "x": "destawî kerdene",
    "y": " b. destawe kerdene"
  },
  {
    "x": "destawîkerdiş (n)",
    "y": " b. destawekerdiş (n)"
  },
  {
    "x": "destawkerdiş (n)",
    "y": " b. destawekerdiş (n)"
  },
  {
    "x": "destawxane (n)",
    "y": " yüznumara, ayakyolu, abdesthane, apteshane, hela, kademhane, kenef, memişhane, tuvalet"
  },
  {
    "x": "destawxaneyo adetî (n)",
    "y": " alaturka tuvalet"
  },
  {
    "x": "destayewbînangirewtiş (n)",
    "y": " dayanışma"
  },
  {
    "x": "destdaya (m)",
    "y": " elverişli, müsait, uygun, yaraşır"
  },
  {
    "x": "destdaye (n)",
    "y": " elverişli, müsait, uygun, yaraşır"
  },
  {
    "x": "destdaye dîyene",
    "y": " elverişli bulmak, uygun görmek"
  },
  {
    "x": "destdayeyî (m)",
    "y": " uygunluk"
  },
  {
    "x": "destdayî, -ye",
    "y": " elverişli, müsait, uygun, yaraşır"
  },
  {
    "x": "destdayî dîyene",
    "y": " elverişli bulmak, uygun görmek"
  },
  {
    "x": "destdayîş (n)",
    "y": " 1)destekleme\r\n~2)elverişli olma, uygun olma, uyma\r\n~3)karışma (dokunma)"
  },
  {
    "x": "destdayîş yewbînan",
    "y": " dayanışma"
  },
  {
    "x": "destdayîtî (m)",
    "y": " uygunluk "
  },
  {
    "x": "destdayîyî (m)",
    "y": " uygunluk"
  },
  {
    "x": "destde",
    "y": " elde (mat)"
  },
  {
    "x": "destde esto",
    "y": " elde var (mat)"
  },
  {
    "x": "destde mend",
    "y": " elde kaldı (mat)"
  },
  {
    "x": "deste-I (n)",
    "y": " 1)deste\r\n~2)kabza\r\n~desteyê şimşêrî (n): kılıç kabzası\r\n~3)sabanın çift sürerken elle tutulan kısmı\r\n~4)sap (araç için)\r\n~desteyê vaşturî (n): orak sapı"
  },
  {
    "x": "desteyê şimşêrî (n)",
    "y": " kılıç kabzası"
  },
  {
    "x": "destey birkî (n)",
    "y": " b. desteyê birkeyî (n)"
  },
  {
    "x": "desteyê birkeyî (n)",
    "y": " sabanın çift sürerken elle tutulan kısmı"
  },
  {
    "x": "destey qilîçî (n)",
    "y": " b. desteyê qilîçî (n)"
  },
  {
    "x": "desteyê qilîçî (n)",
    "y": " sabanın çift sürerken elle tutulan kısmı"
  },
  {
    "x": "destey cirni (n)",
    "y": " b. desteyê cirne (n)"
  },
  {
    "x": "desteyê cirne (n)",
    "y": " havaneli, soku tokmağı, dibek tokmağı"
  },
  {
    "x": "desteyê vaştirî (n)",
    "y": " b. desteyê vaşturî (n)"
  },
  {
    "x": "desteyê vaşturî (n)",
    "y": " orak sapı"
  },
  {
    "x": "desteyê zengenî (n)",
    "y": " kazma sapı"
  },
  {
    "x": "deste-II",
    "y": " hemen, anında, birdenbire"
  },
  {
    "x": "deste merdene",
    "y": " hemen ölmek, anında ölmek"
  },
  {
    "x": "deste-III (m)",
    "y": " b. deşte-I (m)"
  },
  {
    "x": "deste-IV (n)",
    "y": " b. deşte-II (n)"
  },
  {
    "x": "destebira, -ye",
    "y": " dost"
  },
  {
    "x": "destebiray (m)",
    "y": " b. destebiraye (m)"
  },
  {
    "x": "destebirayîye (m)",
    "y": " dostluk"
  },
  {
    "x": "desteg (n)",
    "y": " b. destek (n)"
  },
  {
    "x": "destegul (n)",
    "y": " buket"
  },
  {
    "x": "destek (n)",
    "y": " destek, dayanak"
  },
  {
    "x": "destek dayene",
    "y": " desteklemek"
  },
  {
    "x": "destek kerdene",
    "y": " desteklemek"
  },
  {
    "x": "destekdayîş (n)",
    "y": " destekleme"
  },
  {
    "x": "desteke (m)",
    "y": " b. destekî (m)"
  },
  {
    "x": "destekî (m)",
    "y": " iplik, tire"
  },
  {
    "x": "destekîya lihêfan (m)",
    "y": " yorgan ipliği"
  },
  {
    "x": "destekîroş, -e",
    "y": " iplikçi"
  },
  {
    "x": "destekîviraştox, -e",
    "y": " iplikçi"
  },
  {
    "x": "destekkerdiş (n)",
    "y": " destekleme"
  },
  {
    "x": "destene",
    "y": " b. deştene"
  },
  {
    "x": "destere (n)",
    "y": " testere "
  },
  {
    "x": "desterma (m)",
    "y": " b. destirma (m)"
  },
  {
    "x": "destever",
    "y": " b. verdest, -e"
  },
  {
    "x": "destevil (n)",
    "y": " buket"
  },
  {
    "x": "destêke (m)",
    "y": " b. destekî (m)"
  },
  {
    "x": "destêkî (m)",
    "y": " b. destekî (m)"
  },
  {
    "x": "destêxopanayîş (n)",
    "y": " karışma (dokunma)"
  },
  {
    "x": "destêxopêsernayîş (n)",
    "y": " teslim olma"
  },
  {
    "x": "destêxwiponayîş (n)",
    "y": " b. destêxopanayîş (n)"
  },
  {
    "x": "destgirewtiş (n)",
    "y": " destekleme"
  },
  {
    "x": "desthera, -ye",
    "y": " b. desthîra, -ye"
  },
  {
    "x": "destherayey (m)",
    "y": " b. desthîrayîye (m)"
  },
  {
    "x": "desthîra, -ye",
    "y": " cömert"
  },
  {
    "x": "desthîrayîye (m)",
    "y": " b. desthîrayîye (m)"
  },
  {
    "x": "desti-I (n)",
    "y": " b. deste-I (n)"
  },
  {
    "x": "destê qilîçî (n)",
    "y": " b. desteyê qilîçî (n)"
  },
  {
    "x": "desti-II",
    "y": " b. deste-II"
  },
  {
    "x": "desti merdene",
    "y": " b. deste merdene"
  },
  {
    "x": "destig",
    "y": " b. destikî (zh)"
  },
  {
    "x": "destik (n)",
    "y": " 1)deste, kabza, sap (araç için)\r\n~2)tutaç\r\n~3)kulp\r\n~4)kol-III"
  },
  {
    "x": "destikê berî (n)",
    "y": " kapı kolu"
  },
  {
    "x": "destikin, -e",
    "y": " kulplu"
  },
  {
    "x": "destikî (zh)",
    "y": " 1)tutacak, tutak\r\n~2)tutaç"
  },
  {
    "x": "destirma (m)",
    "y": " kadın hizmetçi"
  },
  {
    "x": "destiş (n)",
    "y": " b. deştiş (n)"
  },
  {
    "x": "destim (n)",
    "y": " sabanın çift sürerken elle tutulan kısmı"
  },
  {
    "x": "destî (n)",
    "y": " testi"
  },
  {
    "x": "destîropêsernayîş (n)",
    "y": " teslim olma"
  },
  {
    "x": "destîxoraveradayîş (n)",
    "y": " teslim olma"
  },
  {
    "x": "destîxoverakerdiş (n)",
    "y": " boyun eğme, itaat etme"
  },
  {
    "x": "destîxwiverakerdiş (n)",
    "y": " b. destîxoverakerdiş (n)"
  },
  {
    "x": "destkarî (m)",
    "y": " müdahale"
  },
  {
    "x": "destkarîye (m)",
    "y": " müdahale"
  },
  {
    "x": "destkarîya lezkanîye (m)",
    "y": " hızlı müdahale"
  },
  {
    "x": "destkewte (n)",
    "y": " bulgu (bilimsel sonuç)"
  },
  {
    "x": "destkewteyî (zh)",
    "y": " bulgular"
  },
  {
    "x": "destkewteyî û şirove",
    "y": " bulgular ve yorum"
  },
  {
    "x": "destkewtî (m)",
    "y": " bulgu (bilimsel sonuç)"
  },
  {
    "x": "destkewtîyî (zh)",
    "y": " bulgular"
  },
  {
    "x": "destkewtîyî û şirove",
    "y": " bulgular ve yorum"
  },
  {
    "x": "destmaj (n)",
    "y": " abdest, aptes"
  },
  {
    "x": "destmaj ra",
    "y": " abdestli, aptesli"
  },
  {
    "x": "destmaj şikîyayene",
    "y": " 1)abdest bozulmak, aptes bozulmak\r\n~2)yellenmek, hava kaçırmak, osurmak"
  },
  {
    "x": "destmaj vejîyayene",
    "y": " tuvalet ihtiyacını gidermek"
  },
  {
    "x": "destmajşikîyayîş (n)",
    "y": " 1)abdest bozulma, aptes bozulma\r\n~2)yellenme, hava kaçırma, osurma"
  },
  {
    "x": "destmajvejîyayîş (n)",
    "y": " tuvalet ihtiyacını giderme"
  },
  {
    "x": "destmale (m)",
    "y": " mendil"
  },
  {
    "x": "destmali (m)",
    "y": " b. destmale (m)"
  },
  {
    "x": "destmiz (n)",
    "y": " ücret"
  },
  {
    "x": "destmizê dersa îlaweyî (n)",
    "y": " ek ders ücreti"
  },
  {
    "x": "destmizo rojane (n)",
    "y": " gündelik, yevmiye"
  },
  {
    "x": "destmizo tewr tay",
    "y": " asgari ücret"
  },
  {
    "x": "destox, -e",
    "y": " b. deştox, -e"
  },
  {
    "x": "destoxênî (m)",
    "y": " b. deştoxênî (m)"
  },
  {
    "x": "destoxîye (m)",
    "y": " b. deştoxîye (m)"
  },
  {
    "x": "destpakerdiş (n)",
    "y": " b. destpêkerdiş (n)"
  },
  {
    "x": "destpanayîş (n)",
    "y": " 1)karışma\r\n~2)dokunma (el, parmak vb. ile)"
  },
  {
    "x": "destpedenayîş (n)",
    "y": " dokunma (el, parmak vb. ile)"
  },
  {
    "x": "destpeykerdiş (n)",
    "y": " b. destpêkerdiş (n)"
  },
  {
    "x": "destpêk (n)",
    "y": " 1)başlangıç\r\n~destpêkê huqûqî (n): hukuk başlangıcı\r\n~2)giriş (yazılı ürün için)"
  },
  {
    "x": "destpêkê huqûqî (n)",
    "y": " hukuk başlangıcı "
  },
  {
    "x": "destpêkerdiş (n)",
    "y": " başlama, başlangıç"
  },
  {
    "x": "destpiranayîş (n)",
    "y": " karışma (dokunma)"
  },
  {
    "x": "destqol, -e",
    "y": " kesik kollu"
  },
  {
    "x": "destqoli (m)",
    "y": " b. destqol, -e"
  },
  {
    "x": "destqot, -e",
    "y": " kesik kollu"
  },
  {
    "x": "destqoti (m)",
    "y": " b. destqot, -e"
  },
  {
    "x": "destquel",
    "y": " b. destqol, -e"
  },
  {
    "x": "destquet (n)",
    "y": " b. destqot, -e"
  },
  {
    "x": "destronayîş (n)",
    "y": " boyun eğme, itaat etme"
  },
  {
    "x": "destsarnayîş (n)",
    "y": " b. destsernayîş (n)"
  },
  {
    "x": "destsernayîş (n)",
    "y": " el koyma"
  },
  {
    "x": "destşok (n)",
    "y": " lavabo"
  },
  {
    "x": "desttêvdayîş (n)",
    "y": " karışma (dokunma)"
  },
  {
    "x": "destur (n)",
    "y": " b. destûr (n)"
  },
  {
    "x": "destûr (n)",
    "y": " izin\r\n~destûrê berardişî (n): üretim izni\r\n~destûrê xebitnîyayîşê awanî (n): yapı kullanım izni"
  },
  {
    "x": "destûrê berardişî (n)",
    "y": " üretim izni"
  },
  {
    "x": "destûrê berhemardişî (n)",
    "y": " üretim izni"
  },
  {
    "x": "destûrê produksîyonî (n)",
    "y": " üretim izni"
  },
  {
    "x": "destûrê xebitnîyayîşê awanî (n)",
    "y": " yapı kullanım izni"
  },
  {
    "x": "destweş, -e",
    "y": " becerikli"
  },
  {
    "x": "destweşîye (m)",
    "y": " beceriklilik"
  },
  {
    "x": "destxoraveradayîş (n)",
    "y": " boyun eğme, itaat etme"
  },
  {
    "x": "destxwiraveradayîş (n)",
    "y": " b. destxoraveradayîş (n)"
  },
  {
    "x": "deşarj (n)",
    "y": " boşalma, deşarj"
  },
  {
    "x": "deşinayene",
    "y": " hapşırmak, aksırmak"
  },
  {
    "x": "deşinayîş (n)",
    "y": " hapşırma aksırma, aksırış"
  },
  {
    "x": "deşîn, -e",
    "y": " b. desin, -e"
  },
  {
    "x": "... deşîyayîş",
    "y": " 1)batma (sivri şeyin batması)\r\n~2)batma (suya, çamura vb. batma)"
  },
  {
    "x": "deşmaj (n)",
    "y": " b. destmaj (n)"
  },
  {
    "x": "deşmaj şikîyayene",
    "y": " b. destmaj şikîyayene"
  },
  {
    "x": "deşmaj vejîyayene",
    "y": " b. destmaj vejîyayene"
  },
  {
    "x": "deşmajşikîyayîş (n)",
    "y": " b. destmajşikîyayîş (n)"
  },
  {
    "x": "deşmajvejîyayîş (n)",
    "y": " b. destmajvejîyayîş (n)"
  },
  {
    "x": "deşt (m)",
    "y": " b. deşte (m)"
  },
  {
    "x": "deşta (m)",
    "y": " dikili (dikilmiş olan)"
  },
  {
    "x": "deştaye (n)",
    "y": " b. deşte (n)"
  },
  {
    "x": "deşte-I (m)",
    "y": " ova"
  },
  {
    "x": "deşte-II (n)",
    "y": " dikili (dikilmiş olan)"
  },
  {
    "x": "deştek (n)",
    "y": " b. destekî (m)"
  },
  {
    "x": "deşteke (m)",
    "y": " b. destekî (m)"
  },
  {
    "x": "deştene (m)",
    "y": " 1)dikmek\r\n~2)dikiş "
  },
  {
    "x": "deşti-I (m)",
    "y": " b. deşte (m)"
  },
  {
    "x": "deşti-II (n)",
    "y": " b. deşte (n)"
  },
  {
    "x": "deştiş (n)",
    "y": " dikim, dikme"
  },
  {
    "x": "deştî (m)",
    "y": " dikili (dikilmiş olan)"
  },
  {
    "x": "deştîye (m)",
    "y": " dikili (dikilmiş olan)"
  },
  {
    "x": "deştox, -e",
    "y": " diken (dikiş diken), dikici"
  },
  {
    "x": "deştoxey (m)",
    "y": " b. deştoxîye (m)"
  },
  {
    "x": "deştoxê (m)",
    "y": " b. deştoxî (m)"
  },
  {
    "x": "deştoxênî (m)",
    "y": " dikişçilik"
  },
  {
    "x": "deştoxî (m)",
    "y": " dikişçilik"
  },
  {
    "x": "deştoxîye (m)",
    "y": " dikişçilik"
  },
  {
    "x": "det (n)",
    "y": " b. dat (n)"
  },
  {
    "x": "detay (n)",
    "y": " ayrıntı, detay, teferruat"
  },
  {
    "x": "detkeyna (m)",
    "y": " b. datkêna (m)"
  },
  {
    "x": "deva (m)",
    "y": " dişi deve"
  },
  {
    "x": "devacêr",
    "y": " aşağıya doğru, iniş aşağı, yokuş aşağı"
  },
  {
    "x": "devacor",
    "y": " yukarıya doğru, yokuş yukarı"
  },
  {
    "x": "devadev",
    "y": " aşağı yukarı, yaklaşık, takriben, yaklaşık olarak, takribî"
  },
  {
    "x": "devaluasyon (n)",
    "y": " devalüasyon"
  },
  {
    "x": "devam (n)",
    "y": " b. dewam (n)"
  },
  {
    "x": "dev-dev",
    "y": " aşağı yukarı, yaklaşık, takriben, yaklaşık olarak, takribî"
  },
  {
    "x": "deve (n)",
    "y": " deve"
  },
  {
    "x": "deverdayene",
    "y": " dökmek"
  },
  {
    "x": "deverdayêne",
    "y": " b. deverdayene"
  },
  {
    "x": "deverdayîş (n)",
    "y": " dökme"
  },
  {
    "x": "deverdîyayene",
    "y": " dökülmek"
  },
  {
    "x": "deverdîyayêne",
    "y": " b. deverdîyayene"
  },
  {
    "x": "deverdîyayîş (n)",
    "y": " dökülme"
  },
  {
    "x": "deverdo (n)",
    "y": " ceviz vb. yuvarlanabilen şeyleri eğimli bir yerden bırakıp birbirine değdirmeye çalışmak suretiyle oynanan bir oyun"
  },
  {
    "x": "devgem (n)",
    "y": " döven sürerken ürünü yememesi için öküzün ağzına takılan ağızlık"
  },
  {
    "x": "devik (n)",
    "y": " döven sürerken ürünü yememesi için öküzün ağzına takılan ağızlık"
  },
  {
    "x": "devirecayêne",
    "y": " b. ravirêjnayene"
  },
  {
    "x": "devirnayene",
    "y": " b. debirnayene"
  },
  {
    "x": "devirnayîş (n)",
    "y": " b. debirnayîş (n)"
  },
  {
    "x": "devistene",
    "y": " iliklemek"
  },
  {
    "x": "gojage/make/bişkoje/qumça/sedefe/zirare devistene",
    "y": " düğme iliklemek"
  },
  {
    "x": "devistiş (n)",
    "y": " ilikleme"
  },
  {
    "x": "devlete (m)",
    "y": " b. dewlete (m)"
  },
  {
    "x": "dew (m)",
    "y": " b. dewe (m)"
  },
  {
    "x": "dewa (m)",
    "y": " dava"
  },
  {
    "x": "dewaya veradîyayîşî (m)",
    "y": " boşanma davası"
  },
  {
    "x": "dewaker, -e",
    "y": " davacı"
  },
  {
    "x": "dewam (n)",
    "y": " devam"
  },
  {
    "x": "dewam bîyene",
    "y": " devam edilmek"
  },
  {
    "x": "dewam kerdene",
    "y": " devam etmek, sürdürmek"
  },
  {
    "x": "dewambar, -e",
    "y": " sürdürülebilir"
  },
  {
    "x": "dewambarîye (m)",
    "y": " sürdürülebilirlik"
  },
  {
    "x": "dewamkerdiş (n)",
    "y": " devam etme, sürdürme"
  },
  {
    "x": "dewançe (n)",
    "y": " b. dabançe (n)"
  },
  {
    "x": "dewar, -e-I",
    "y": " 1)büyükbaş [hayvanlar], sığır\r\n~2)davar, küçükbaş\r\n~3)küçükbaş hayvan sürüsü"
  },
  {
    "x": "dewaro gayîn",
    "y": " b. gayîne (n)"
  },
  {
    "x": "dewar-II",
    "y": " b. diwar"
  },
  {
    "x": "dewari (m)",
    "y": " b. deware (m)"
  },
  {
    "x": "dewasnayîne",
    "y": " b. dewisnayene"
  },
  {
    "x": "dewasnayîş (n)",
    "y": " b. dewisnayîş (n)"
  },
  {
    "x": "dewayin, -e",
    "y": " davalı"
  },
  {
    "x": "dewbir (n)",
    "y": " toptan (hayvan için)"
  },
  {
    "x": "dewe (m)",
    "y": " köy"
  },
  {
    "x": "dewek (m)",
    "y": " b. deweke (m)"
  },
  {
    "x": "deweke (m)",
    "y": " mezra"
  },
  {
    "x": "deweki (m)",
    "y": " b. deweke (m)"
  },
  {
    "x": "dewesnayene",
    "y": " b. dewisnayene"
  },
  {
    "x": "dewesnayîş (n)",
    "y": " b. dewisnayîş (n)"
  },
  {
    "x": "dewi (m)",
    "y": " b. dewe (m)"
  },
  {
    "x": "dewic, -i",
    "y": " b. dewij, -e"
  },
  {
    "x": "dewij, -e",
    "y": " köylü"
  },
  {
    "x": "dewija Misirî",
    "y": " fellah"
  },
  {
    "x": "dewija misirije",
    "y": " fellah"
  },
  {
    "x": "dewijê Misirî",
    "y": " fellah"
  },
  {
    "x": "dewijo misirij",
    "y": " fellah"
  },
  {
    "x": "dewir-I (n)",
    "y": " b. dewr (n)"
  },
  {
    "x": "dewir-II (m)",
    "y": " b. debare (m)"
  },
  {
    "x": "dewisnayene",
    "y": " bastırıp sıkıştırmak, sıkıştırmak"
  },
  {
    "x": "dewisnayîş (n)",
    "y": " bastırıp sıkıştırma, sıkıştırma"
  },
  {
    "x": "dewiz, -e",
    "y": " b. dewij, -e"
  },
  {
    "x": "dewl (n)",
    "y": " deri kova"
  },
  {
    "x": "dewlçe (n)",
    "y": " küçük deri kova"
  },
  {
    "x": "dewlemend, -e",
    "y": " zengin, varlıklı"
  },
  {
    "x": "dewlemendênî",
    "y": " zenginlik, varlık, varlıklılık"
  },
  {
    "x": "dewlemendî (m)",
    "y": " zenginlik, varlık, varlıklılık"
  },
  {
    "x": "dewlemendîye (m)",
    "y": " zenginlik, varlık, varlıklılık"
  },
  {
    "x": "dewlet (m)",
    "y": " b. dewlete (m)"
  },
  {
    "x": "dewletê xerîb (m)",
    "y": " b. dewleta xerîbe (m)"
  },
  {
    "x": "dewlete (m)",
    "y": " devlet"
  },
  {
    "x": "dewleta ecnebî (m)",
    "y": " yabancı devlet"
  },
  {
    "x": "dewleta ecnebîye (m)",
    "y": " yabancı devlet"
  },
  {
    "x": "dewleta huqûqî (m)",
    "y": " hukuk devleti"
  },
  {
    "x": "dewleta pêseye (m)",
    "y": " b. dewleta pêşeye (m)"
  },
  {
    "x": "dewleta pêşeye (m)",
    "y": " yabancı devlet"
  },
  {
    "x": "dewleta polîsan (m)",
    "y": " polis devleti"
  },
  {
    "x": "dewleta xerîb (m)",
    "y": " b. dewleta xerîbe (m)"
  },
  {
    "x": "dewleta xerîbe (m)",
    "y": " yabancı devlet"
  },
  {
    "x": "dewleta xerîbi (m)",
    "y": " b. dewleta xerîbe (m)"
  },
  {
    "x": "dewletê yewbîyayeyî (zh)",
    "y": " birleşik devletler"
  },
  {
    "x": "Dewletê Yewbîyayeyî yê Amerîka",
    "y": " Amerika Birleşik Devletleri"
  },
  {
    "x": "dewletênî (m)",
    "y": " zenginlik, varlık, varlıklılık"
  },
  {
    "x": "dewleti (m)",
    "y": " b. dewlete (m)"
  },
  {
    "x": "dewletî, -ye",
    "y": " zengin, varlıklı"
  },
  {
    "x": "dewletîyî (m)",
    "y": " zenginlik, varlık, varlıklılık"
  },
  {
    "x": "dewletîyîye (m)",
    "y": " zenginlik, varlık, varlıklılık"
  },
  {
    "x": "dewletlî, -ye",
    "y": " b. dewletî, -ye"
  },
  {
    "x": "dewletmend, -e",
    "y": " b. dewlemend, -e"
  },
  {
    "x": "dewm (n)",
    "y": " b. dom (n)"
  },
  {
    "x": "dewm bîyene",
    "y": " b. dewam bîyene"
  },
  {
    "x": "dewm kerdene",
    "y": " b. dewam kerdene"
  },
  {
    "x": "dewmnayene",
    "y": " b. domnayene"
  },
  {
    "x": "dewom (n)",
    "y": " b. dewam (n)"
  },
  {
    "x": "dewqe (m)",
    "y": " baş dönmesi"
  },
  {
    "x": "dewqa ... şeyene",
    "y": " b. dewqa ... şîyene"
  },
  {
    "x": "dewqa ... şîyayene",
    "y": " b. dewqa ... şîyene"
  },
  {
    "x": "dewqa ... şîyene",
    "y": " başı dönmek \r\n~Dewqa şima şina. (Başınız döner.)"
  },
  {
    "x": "dewqeşîyayîş (n)",
    "y": " başı dönme"
  },
  {
    "x": "dewqi-I (m)",
    "y": " b. dewqe (m)"
  },
  {
    "x": "dewqi-II (m)",
    "y": " b. tewqe-I (m)"
  },
  {
    "x": "dewqişeyîş (n)",
    "y": " b. dewqeşîyayîş (n)"
  },
  {
    "x": "dewqişîyayîş (n)",
    "y": " b. dewqeşîyayîş (n)"
  },
  {
    "x": "dewr-I (n)",
    "y": " çağ, zaman, devir, vakit"
  },
  {
    "x": "dewr akerdene",
    "y": " çağ açmak, çağ başlatmak"
  },
  {
    "x": "dewr dayene destpêkerdene",
    "y": " çağ açmak, çağ başlatmak"
  },
  {
    "x": "dewr girewtene",
    "y": " çağ kapatmak"
  },
  {
    "x": "dewr padayene",
    "y": " çağ kapatmak"
  },
  {
    "x": "dewr racenayîne",
    "y": " çağ kapatmak "
  },
  {
    "x": "dewran hemîne de",
    "y": " çağlar boyu"
  },
  {
    "x": "dewran pêrune de",
    "y": " çağlar boyu"
  },
  {
    "x": "dewran ra ohet",
    "y": " çağlar ötesi"
  },
  {
    "x": "dewran ra ohêm",
    "y": " çağlar ötesi"
  },
  {
    "x": "dewran ra wet",
    "y": " çağlar ötesi"
  },
  {
    "x": "dewran têdîne de",
    "y": " çağlar boyu"
  },
  {
    "x": "dewrê ... ameyene",
    "y": " çağı gelmek"
  },
  {
    "x": "Dewrê Asinî (n)",
    "y": " Demir Çağı, Demir Devri (M. Ö. 1190-330)"
  },
  {
    "x": "Dewrê Atome (n)",
    "y": " Atom Çağı (1945- )"
  },
  {
    "x": "Dewrê Bronzî (n)",
    "y": " Tunç Çağı, Tunç Devri, Bronz Çağı, Bronz Devri (M. Ö. 3300-1200)"
  },
  {
    "x": "Dewrê Bronzî yo Mîyanên (n)",
    "y": " Orta Tunç Çağı, Orta Tunç Devri (M. Ö. 2000-1550)"
  },
  {
    "x": "Dewrê Bronzî yo Peyên (n)",
    "y": " Geç Tunç Çağı, Geç Tunç Devri (M. Ö. 1550–1200)"
  },
  {
    "x": "Dewrê Bronzî yo Verên (n)",
    "y": " Erken Tunç Çağı, Erken Tunç Devri (M. Ö. 3300–2000)"
  },
  {
    "x": "Dewrê Cahîlîyetî (n)",
    "y": " Cahiliye Dönemi"
  },
  {
    "x": "dewrê ... derbaz bîyene",
    "y": " çağı geçmek"
  },
  {
    "x": "Dewrê Kemere (n)",
    "y": " Taş Çağı, Taş Devri"
  },
  {
    "x": "Dewrê Kemere yo Mîyanên (n)",
    "y": " Orta Taş Çağı, Orta Taş Devri (M. Ö. 12.000-9000)"
  },
  {
    "x": "Dewrê Kemere yo Verên (n)",
    "y": " Eski Taş Çağı, Eski Taş Devri, Yontma Taş Çağı, Yontma Taş Devri, Paleolitik (M. Ö. 14 milyon-2,5 milyon)"
  },
  {
    "x": "Dewrê Kerra (n)",
    "y": " Taş Çağı, Taş Devri"
  },
  {
    "x": "Dewrê Kerra yo Mîyanên (n)",
    "y": " Orta Taş Çağı, Orta Taş Devri (M. Ö. 12.000-9000)"
  },
  {
    "x": "Dewrê Kerra yo Verên (n)",
    "y": " Eski Taş Çağı, Eski Taş Devri, Yontma Taş Çağı, Yontma Taş Devri, Paleolitik (M. Ö. 14 milyon-2,5 milyon) "
  },
  {
    "x": "Dewrê Makîna (n)",
    "y": " Makine Çağı (1880-194\r\n~5)"
  },
  {
    "x": "Dewrê Sifirî (n)",
    "y": " Bakır Çağı, Bakır Devri (M. Ö. 5500-3300)"
  },
  {
    "x": "Dewrê Tuncî (n)",
    "y": " Tunç Çağı, Tunç Devri, Bronz Çağı, Bronz Devri (M. Ö. 3300-1200)"
  },
  {
    "x": "Dewrê Tuncî yo Mîyanên (n)",
    "y": " Orta Tunç Çağı, Orta Tunç Devri (M. Ö. 2000-1550)"
  },
  {
    "x": "Dewrê Tuncî yo Peyên (n)",
    "y": " Geç Tunç Çağı, Geç Tunç Devri (M. Ö. 1550–1200)"
  },
  {
    "x": "Dewrê Tuncî yo Verên (n)",
    "y": " Erken Tunç Çağı, Erken Tunç Devri (M. Ö. 3300–2000)"
  },
  {
    "x": "dewrê ... vîyartene",
    "y": " çağı geçmek"
  },
  {
    "x": "Dewro Entîk (n)",
    "y": " Antik Çağ, Antikite "
  },
  {
    "x": "Dewro Hemdem (n)",
    "y": " Yakın Çağ (1789- ) "
  },
  {
    "x": "Dewro Mîyanên (n)",
    "y": " Orta Çağ (400–1400 veya 476-1492 veya 476-1453)"
  },
  {
    "x": "Dewro Moderno Verên (n)",
    "y": " Erken Modern Dönem, Erken Modern Devre (yaklaşık 1500-1800)"
  },
  {
    "x": "Dewro Newe (n)",
    "y": " Yeni Çağ (1492-1789 veya 1453-1789)"
  },
  {
    "x": "Dewro Postmodern (n)",
    "y": " Postmodern Çağ (1973- )"
  },
  {
    "x": "dewro tewr baş (n)",
    "y": " altın çağ"
  },
  {
    "x": "Dewro Verên (n)",
    "y": " İlk Çağ"
  },
  {
    "x": "dewro zerrên (n)",
    "y": " altın çağ"
  },
  {
    "x": "dewro zerrnên (n)",
    "y": " altın çağ"
  },
  {
    "x": "dewr-II (m)",
    "y": " b. dore-II (m)"
  },
  {
    "x": "dewr-III (n)",
    "y": " devir (aktarma)"
  },
  {
    "x": "dewr kerdene",
    "y": " havale etmek"
  },
  {
    "x": "dewrê plaka (n)",
    "y": " plaka devri"
  },
  {
    "x": "dewre (n)",
    "y": " 1)devre\r\n~2)sezon"
  },
  {
    "x": "dewreyo kilm (n)",
    "y": " kısa devre (elektrik için)"
  },
  {
    "x": "dewres (n)",
    "y": " b. dewrêş (n)"
  },
  {
    "x": "dewrês (n)",
    "y": " b. dewrêş (n)"
  },
  {
    "x": "dewrêş (n)",
    "y": " derviş"
  },
  {
    "x": "dewrêşek (n)",
    "y": " abdal, gezgin derviş"
  },
  {
    "x": "dewrêşîye (m)",
    "y": " abdallık"
  },
  {
    "x": "dewrkerdiş (n)",
    "y": " 1)devretme\r\n~2)havale"
  },
  {
    "x": "dewrkerdişê plaka (n)",
    "y": " plaka devri"
  },
  {
    "x": "dewrna",
    "y": " b. dorna"
  },
  {
    "x": "dewrûver (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "dewse (m)",
    "y": " belirti"
  },
  {
    "x": "dewsnayene",
    "y": " b. dewisnayene"
  },
  {
    "x": "dewsnayîne",
    "y": " b. dewisnayene"
  },
  {
    "x": "dewsnayîş (n)",
    "y": " b. dewisnayîş (n)"
  },
  {
    "x": "dewşnayîne",
    "y": " b. dewisnayene"
  },
  {
    "x": "dewşnayîş (n)",
    "y": " b. dewisnayîş (n)"
  },
  {
    "x": "dewuj, -e",
    "y": " b. dewij, -e"
  },
  {
    "x": "dewul (n)",
    "y": " b. dawul (n)"
  },
  {
    "x": "dewuz, -e",
    "y": " b. dewij, -e"
  },
  {
    "x": "dewûm (n)",
    "y": " b. dewam (n)"
  },
  {
    "x": "dewx (m)",
    "y": " b. dewqe (m)"
  },
  {
    "x": "dewxa ... şîyayene",
    "y": " b. dewqa ... şîyene"
  },
  {
    "x": "dewxşîyayîş (n)",
    "y": " b. dewqeşîyayîş (n)"
  },
  {
    "x": "dexl (n)",
    "y": " karışım (buğday ile darı veya buğday ile arpa karışımı)"
  },
  {
    "x": "dey-I",
    "y": " b. de-I"
  },
  {
    "x": "dey-II (m, n, z)",
    "y": " b. ey (n, z), aye (m, z), ci (m, n, z)"
  },
  {
    "x": "dey rê",
    "y": " b. ey rê, aye rê"
  },
  {
    "x": "dey rî",
    "y": " b. ey rê, aye rê"
  },
  {
    "x": "deyamiş bîyayene",
    "y": " b. deyanmîş bîyene"
  },
  {
    "x": "deyamişbîyayîş (n)",
    "y": " b. deyanmîşbîyayîş (n)"
  },
  {
    "x": "deyamîş bîyayene",
    "y": " b. deyanmîş bîyene"
  },
  {
    "x": "deyamîşbîyayîş (n)",
    "y": " b. deyanmîşbîyayîş (n)"
  },
  {
    "x": "deyanmîş bîyene",
    "y": " dayanmak (zarar görmemek)"
  },
  {
    "x": "deyanmîşbîyayîş (n)",
    "y": " dayanma (zarar görmeme)"
  },
  {
    "x": "deyax (n)",
    "y": " dayanma"
  },
  {
    "x": "deyax kerdene",
    "y": " dayanmak (tahammül etmek, sabretmek)"
  },
  {
    "x": "deyaxkerdiş (n)",
    "y": " dayanma (tahammül etme, sabretme)"
  },
  {
    "x": "deyene",
    "y": " b. dîyene "
  },
  {
    "x": "deyir (m)",
    "y": " b. deyîre (m)"
  },
  {
    "x": "deyiri (m)",
    "y": " b. deyîre (m)"
  },
  {
    "x": "deyî (n, z)",
    "y": " b. ey (n, z)"
  },
  {
    "x": "deyîr-I (m)",
    "y": " b. deyîre (m)"
  },
  {
    "x": "deyîr-II (m)",
    "y": " b. dihîre (m)"
  },
  {
    "x": "deyîr di",
    "y": " b. dihîre de"
  },
  {
    "x": "deyîrbaz, -e",
    "y": " ses sanatçısı, şarkıcı, türkücü"
  },
  {
    "x": "deyîrbazi (m)",
    "y": " b. deyîrbaze (m)"
  },
  {
    "x": "deyîre (m)",
    "y": " şarkı, türkü"
  },
  {
    "x": "deyîri (m)",
    "y": " b. deyîre (m)"
  },
  {
    "x": "deyîş (n)",
    "y": " b. dîyayîş (n)"
  },
  {
    "x": "deyk (m)",
    "y": " 1)b. dayîke (m)\r\n~2)b. dêke (m)"
  },
  {
    "x": "deyke (m)",
    "y": " b. dayîke (m)"
  },
  {
    "x": "deyki (m)",
    "y": " b. dayîke (m)"
  },
  {
    "x": "deyle (m)",
    "y": " başörtü, başörtüsü"
  },
  {
    "x": "deyli (m)",
    "y": " b. deyle (m)"
  },
  {
    "x": "deyme",
    "y": " asla, hiçbir zaman, hiç, katiyen"
  },
  {
    "x": "deyn (n)",
    "y": " borç, alacak"
  },
  {
    "x": "deyn kerdene",
    "y": " iktibas etmek"
  },
  {
    "x": "deynê xo dayene",
    "y": " (kendi) borcunu ödemek, tesviye etmek"
  },
  {
    "x": "deyndar, -e",
    "y": " verecekli, borçlu"
  },
  {
    "x": "deyndarîye (m)",
    "y": " borçluluk "
  },
  {
    "x": "deyndaye, -e",
    "y": " alacaklı"
  },
  {
    "x": "deyndayoxîye (m)",
    "y": " alacaklılık"
  },
  {
    "x": "deynêxodayîş (n)",
    "y": " (kendi) borcunu ödeme, tesviye"
  },
  {
    "x": "deynkerde (n)",
    "y": " iktibas"
  },
  {
    "x": "deynkerdiş (n)",
    "y": " iktibas"
  },
  {
    "x": "deyr (m)",
    "y": " b. deyîre (m)"
  },
  {
    "x": "deyra (n)",
    "y": " b. derya (n)"
  },
  {
    "x": "deyrbaz, -i",
    "y": " b. deyîrbaz, -e"
  },
  {
    "x": "deyri (m)",
    "y": " b. deyîre (m)"
  },
  {
    "x": "deyşte (m)",
    "y": " b. deşte-I (m)"
  },
  {
    "x": "deyşteni",
    "y": " b. deştene"
  },
  {
    "x": "dez (n)",
    "y": " b. dej (n)"
  },
  {
    "x": "dezayene",
    "y": " b. dejayene"
  },
  {
    "x": "dezayîş (n)",
    "y": " b. dejayîş"
  },
  {
    "x": "deznayene",
    "y": " b. dejnayene"
  },
  {
    "x": "deznayîş (n)",
    "y": " b. dejnayîş"
  },
  {
    "x": "dêrbaz, -i",
    "y": " b. deyîrbaz, -e"
  },
  {
    "x": "deyşti (m)",
    "y": " b. deşte-II (m)"
  },
  {
    "x": "deyştiş (n)",
    "y": " b. deştiş (n)"
  },
  {
    "x": "deyştişdayîş (n)",
    "y": " b. dayîşdeştiş (n)"
  },
  {
    "x": "dez (n)",
    "y": " b. dej (n)"
  },
  {
    "x": "dezê cîgere (n)",
    "y": " b. dejê cîgere (n)"
  },
  {
    "x": "dezê piştî (n)",
    "y": " b. dejê piştî (n)"
  },
  {
    "x": "dezê pîzî (n)",
    "y": " b. dejê pîzî (n)"
  },
  {
    "x": "dezê sarî (n)",
    "y": " b. dejê sereyî (n)"
  },
  {
    "x": "dezê zerrî (n)",
    "y": " b. dejê zereyî (n)"
  },
  {
    "x": "dezê zerrî (n)",
    "y": " b. dejê zerrî (n)"
  },
  {
    "x": "deza (n)",
    "y": " amca oğlu, amcazade"
  },
  {
    "x": "dezaye (n)",
    "y": " b. dejaye (n)"
  },
  {
    "x": "dezayene",
    "y": " b. dejayene"
  },
  {
    "x": "dezayeyênî (m)",
    "y": " b. dejayeyênî (m) "
  },
  {
    "x": "dezayeyîye (m)",
    "y": " b. dejayeyîye (m)"
  },
  {
    "x": "dezayîş (n)",
    "y": " b. dejayîş (n)"
  },
  {
    "x": "dezayîye (m)",
    "y": " b. dejayîye (m)"
  },
  {
    "x": "dezdawox, -e",
    "y": " b. dejdayox, -e"
  },
  {
    "x": "dezdaye (n)",
    "y": " b. dejdaye (n)"
  },
  {
    "x": "dezdayîye (m)",
    "y": " b. dejdayîye (m)"
  },
  {
    "x": "dezenfekte (n)",
    "y": " dezenfekte"
  },
  {
    "x": "dezenfekte kerdene",
    "y": " dezenfekte etmek"
  },
  {
    "x": "dezenfekteker, -e",
    "y": " dezenfektan"
  },
  {
    "x": "dezenfektekero gazin (n)",
    "y": " gaz dezenfektan"
  },
  {
    "x": "dezenfektekerdiş (n)",
    "y": " dezenfekte etme"
  },
  {
    "x": "dezge (n)",
    "y": " b. dezgeh (n)"
  },
  {
    "x": "dezgey çolagey (n)",
    "y": " b. dezgehê çulagîye (n)"
  },
  {
    "x": "dezgey çulagey (n)",
    "y": " b. dezgehê çulagîye (n)"
  },
  {
    "x": "dezgeh (n)",
    "y": " 1)kurum, müessese\r\n~2)tezgâh"
  },
  {
    "x": "Dezgehê Asayîşê Sosyalî (DAS)",
    "y": " Sosyal Güvenlik Kurumu (SGK)"
  },
  {
    "x": "Dezgehê Asayîşê Sosyalî dekewtiş-vejîyayîş",
    "y": " Sosyal Güvenlik Kurumu giriş-çıkış"
  },
  {
    "x": "dezgehê çulagîye (n)",
    "y": " dokuma tezgâhı"
  },
  {
    "x": "dezgeho mabênkar (n)",
    "y": " aracı kurum"
  },
  {
    "x": "dezgeho fermî (n)",
    "y": " resmî kurum"
  },
  {
    "x": "dezgehdar, -e",
    "y": " tezgâhtar"
  },
  {
    "x": "dezî (m)",
    "y": " çuvalın ağzını dikmek, çarık ve çorap bağlamak vb. için kullanılan kıldan yapılma ip"
  },
  {
    "x": "deznawox, -e",
    "y": " b. dejnayox, -e"
  },
  {
    "x": "deznayene",
    "y": " b. dejnayene"
  },
  {
    "x": "deznayêne",
    "y": " b. dejnayene"
  },
  {
    "x": "deznayîş (n)",
    "y": " b. dejnayîş (n)"
  },
  {
    "x": "dê-I",
    "y": " fiilin gelecek zamanında kullanılan bir partikel"
  },
  {
    "x": "dê-II (m, n, z)",
    "y": " b. ci (m, n, z), ey (n, z), aye (m, z)"
  },
  {
    "x": "dê r’",
    "y": " b. ci rê, ey rê, aye rê"
  },
  {
    "x": "dê rî",
    "y": " b. ci rê, ey rê, aye rê"
  },
  {
    "x": "dê-III",
    "y": " b. de-I"
  },
  {
    "x": "dêdiqodî (n)",
    "y": " b. dêdîqodî (n)"
  },
  {
    "x": "dêdiqodu (n)",
    "y": " b. dêdîqodî (n)"
  },
  {
    "x": "dêdîqodî (n)",
    "y": " dedikodu"
  },
  {
    "x": "dêdîqodu (n)",
    "y": " b. dêdîqodî (n)"
  },
  {
    "x": "dêh-IV",
    "y": " b. deh"
  },
  {
    "x": "dêh kerdene",
    "y": " b. deh kerdene"
  },
  {
    "x": "dêk (m)",
    "y": " b. dêke (m)"
  },
  {
    "x": "dêke (m)",
    "y": " 1)nine, babaanne, anneanne, büyükana, büyükanne\r\n~2)ebe\r\n~3)b. dayîke (m)"
  },
  {
    "x": "dêki (m)",
    "y": " b. dêke (m) "
  },
  {
    "x": "dêleverge (m)",
    "y": " b. deleverge (m)"
  },
  {
    "x": "dêlmast",
    "y": " b. dêmlaşt, -e"
  },
  {
    "x": "dêlmaşt",
    "y": " b. dêmlaşt, -e"
  },
  {
    "x": "dêm dayene",
    "y": " 1)çevirmek, döndürmek \r\n~2)devirmek"
  },
  {
    "x": "dêm dayêne",
    "y": " b. dêm dayene"
  },
  {
    "x": "dêm dîyayene",
    "y": " devrilmek"
  },
  {
    "x": "dêm dîyayêne",
    "y": " b. dêm dîyayene"
  },
  {
    "x": "dêmarî (m)",
    "y": " b. dêmarrî (m)"
  },
  {
    "x": "dêmarrî (m)",
    "y": " üvey anne, analık, annelik"
  },
  {
    "x": "dêmdayîş (n)",
    "y": " 1)çevirme, döndürme\r\n~2)devirme"
  },
  {
    "x": "dêmerr (m)",
    "y": " b. dêmarrî (m)"
  },
  {
    "x": "dêmîşbîyayîş (n)",
    "y": " b. deyanmîşbîyayîş (n)"
  },
  {
    "x": "dêmîş bîyayene",
    "y": " b. deyanmîş bîyene"
  },
  {
    "x": "dêmlaşt, -e",
    "y": " ters\r\n~Mêrikî şelwarê xo dêmlaşt pay kerdê. (Adam şalvarını ters [iç tarafı dışa gelecek biçimde] giymiş.)"
  },
  {
    "x": "dên (n)",
    "y": " b. deyn (n)"
  },
  {
    "x": "dêndar, -e",
    "y": " b. deyndar, -e"
  },
  {
    "x": "dêr-I (m)",
    "y": " b. deyîre (m)"
  },
  {
    "x": "dêr-II (n)",
    "y": " kilise"
  },
  {
    "x": "dêr-III (m)",
    "y": " b. dêr-II (n)"
  },
  {
    "x": "dêrbaz, -i",
    "y": " b. deyîrbaz, -e"
  },
  {
    "x": "dêre-I (m)",
    "y": " b. deyîre (m)"
  },
  {
    "x": "dêre-II (n)",
    "y": " b. derya (n)dey"
  },
  {
    "x": "dêre-III (n)",
    "y": " b. dêr-II (n)"
  },
  {
    "x": "dêre-IV (m)",
    "y": " b. dêr-II (n)"
  },
  {
    "x": "dêri (m)",
    "y": " b. deyîre (m)"
  },
  {
    "x": "Dêrsim (n)",
    "y": " 1)Tunceli yöresinin adı\r\n~2)Tunceli"
  },
  {
    "x": "dêrsimic, -i",
    "y": " b. dêrsimij, -e "
  },
  {
    "x": "dêrsimij, -e",
    "y": " Dersimli"
  },
  {
    "x": "dêrsimiz, -e",
    "y": " b. dêrsimij, -e"
  },
  {
    "x": "dêrze (n)",
    "y": " b. derze (n)"
  },
  {
    "x": "dêrzîn (m)",
    "y": " b. derzîne (m) "
  },
  {
    "x": "dês (n)",
    "y": " duvar"
  },
  {
    "x": "dêsê îstînadî (m)",
    "y": " istinat duvarı"
  },
  {
    "x": "dêsê seveknayîşî (n) (TE)",
    "y": " güvenlik duvarı"
  },
  {
    "x": "Dêsim (n)",
    "y": " b. Dêrsim (n)"
  },
  {
    "x": "dêsimic, -e",
    "y": " b. dêrsimij, -e "
  },
  {
    "x": "dêsimiz, -e",
    "y": " b. dêrsimij, -e "
  },
  {
    "x": "dêskar, -e",
    "y": " duvarcı"
  },
  {
    "x": "dêşin, -e",
    "y": " b. desin, -e"
  },
  {
    "x": "dêşîn, -e",
    "y": " b. desin, -e"
  },
  {
    "x": "dêşîne (m)",
    "y": " b. desine (m)"
  },
  {
    "x": "dêşt (m)",
    "y": " b. deşte (m)"
  },
  {
    "x": "dêşta (m)",
    "y": " b. deşta (m)"
  },
  {
    "x": "dêştene",
    "y": " b. deştene"
  },
  {
    "x": "dêşti (n)",
    "y": " b. deşte (n)"
  },
  {
    "x": "dêştiş (n)",
    "y": " b. deştiş (n)"
  },
  {
    "x": "dêştişdayîş (n)",
    "y": " b. dayîşdeştiş (n)"
  },
  {
    "x": "dêştox, -i",
    "y": " b. deştox, -e"
  },
  {
    "x": "dêştoxê (m)",
    "y": " b. deştoxî (m)"
  },
  {
    "x": "dêwazan (n)",
    "y": " b. dêwezan (n)"
  },
  {
    "x": "dêwe (m)",
    "y": " b. dewe (m)"
  },
  {
    "x": "dêwezan (n)",
    "y": " sacayağı, sacayak "
  },
  {
    "x": "dêwezûn (n)",
    "y": " b. dêwezan (n) "
  },
  {
    "x": "dêwletî, -ye",
    "y": " b. dewletî, -ye"
  },
  {
    "x": "dêy (n, z)",
    "y": " b. ey (n, z)"
  },
  {
    "x": "dêy rê (n)",
    "y": " b. ey rê"
  },
  {
    "x": "dêyax (n)",
    "y": " b. deyax (n)"
  },
  {
    "x": "dêyax kerdene",
    "y": " b. deyax kerdene"
  },
  {
    "x": "dêyaxkerdiş (n)",
    "y": " b. deyaxkerdiş (n)"
  },
  {
    "x": "dêyme",
    "y": " b. deyme"
  },
  {
    "x": "dêyra (n)",
    "y": " b. derya (n)"
  },
  {
    "x": "dêz (m)",
    "y": " b. dêze-II (m)"
  },
  {
    "x": "dêza (m)",
    "y": " b. dêze-I (m) "
  },
  {
    "x": "dêzawon (n)",
    "y": " b. dêwezan (n)"
  },
  {
    "x": "dêzdare (m)",
    "y": " hayvanlara kışın yedirilmek üzere kesilip ağacın çatallı ana dalları arasına dizilen yaprak yığını"
  },
  {
    "x": "dêze-I (m)",
    "y": " çömlek"
  },
  {
    "x": "dêze-II (m)",
    "y": " loda, büyük yığın, istif"
  },
  {
    "x": "dêza vaşî (m)",
    "y": " hayvanlara kışın yedirilmek üzere kesilip üst üste dizilen büyük ot yığını"
  },
  {
    "x": "dêza velgî (m)",
    "y": " hayvanlara kışın yedirilmek üzere kesilip üst üste dizilen büyük yaprak yığını"
  },
  {
    "x": "dêza velgî panayene",
    "y": " hayvanların yemesi için kışlık yaprak istiflemek"
  },
  {
    "x": "dêza velgî panayîne",
    "y": " b. dêza velgî panayene"
  },
  {
    "x": "dêza velgî ronayene",
    "y": " hayvanların yemesi için kışlık yaprak istiflemek"
  },
  {
    "x": "dêze kerdene",
    "y": " istiflemek, istif etmek, yığmak, loda etmek"
  },
  {
    "x": "dêze ronayene",
    "y": " yığmak, loda etmek"
  },
  {
    "x": "dêzi (m)",
    "y": " b. dêze (m)"
  },
  {
    "x": "dêzik (m)",
    "y": " b. dêzike (m)"
  },
  {
    "x": "dêzik û xilikî (zh)",
    "y": " çanak çömlek"
  },
  {
    "x": "dêzike (m)",
    "y": " 1)çömlek\r\n~2)güveç"
  },
  {
    "x": "dêziki (m)",
    "y": " b. dêzike (m)"
  },
  {
    "x": "dêzlig (n)",
    "y": " sacayağı, sacayak"
  },
  {
    "x": "di-I",
    "y": " iki"
  },
  {
    "x": "di hebî",
    "y": " iki tane"
  },
  {
    "x": "di sey",
    "y": " iki yüz "
  },
  {
    "x": "di sê",
    "y": " b. di sey"
  },
  {
    "x": "di sêy",
    "y": " b. di sey"
  },
  {
    "x": "di teneyî",
    "y": " iki tane "
  },
  {
    "x": "di tenê",
    "y": " b. di teneyî"
  },
  {
    "x": "di-hîrê",
    "y": " iki üç"
  },
  {
    "x": "di-îrê",
    "y": " b. di-hîrê"
  },
  {
    "x": "di-îri",
    "y": " b. di-hîrê"
  },
  {
    "x": "d-îrê",
    "y": " b. di-hîrê"
  },
  {
    "x": "d-îri",
    "y": " b. di-hîrê"
  },
  {
    "x": "di-II",
    "y": " b. de-I\r\n~Di vinde. (Hele dur.)"
  },
  {
    "x": "... di-III (pd)",
    "y": " b. ... de-III (pd)"
  },
  {
    "x": "... di tinaz kerdene",
    "y": " b. ... de tinaz kerdene"
  },
  {
    "x": "di ... de",
    "y": " -de"
  },
  {
    "x": "di camî de",
    "y": " camide\r\n~“Xerc kero yew dirheme kamo temam,\r\n~Yo hevalê min di cennet de mudam.” (Mela Ehmedê Xasî)\r\n~“Yew bido yew dirheme b’ qelbo şedîd\r\n~Zey di Bedr û hem Huneyn de bo şehîd.” (Mela Ehmedê Xasî)"
  },
  {
    "x": "di ... di",
    "y": " b. di ... de"
  },
  {
    "x": "dia (m)",
    "y": " b. dua (m)"
  },
  {
    "x": "dibax (m)",
    "y": " b. debaxe (m)"
  },
  {
    "x": "dibax kerdene",
    "y": " b. debaxe kerdene"
  },
  {
    "x": "dibaxi (m)",
    "y": " b. debaxe (m)"
  },
  {
    "x": "dibaxi kerdene",
    "y": " b. debaxe kerdene"
  },
  {
    "x": "dibaxikerdiş (n)",
    "y": " b. debaxekerdiş (n)"
  },
  {
    "x": "dibaxkerdiş (n)",
    "y": " b. debaxekerdiş (n)"
  },
  {
    "x": "dibere (m)",
    "y": " dama, dokuztaş vb. oyunlarda çift yönlü olumlu oyun şansı elde etme durumu"
  },
  {
    "x": "diberi (m)",
    "y": " b. dibere (m)"
  },
  {
    "x": "dibistane (m)",
    "y": " ilkokul, ilkmektep"
  },
  {
    "x": "dibitîn (n)",
    "y": " b. dîvîtîn (n)"
  },
  {
    "x": "dibri (n)",
    "y": " b. dubre (n)"
  },
  {
    "x": "dican (m)",
    "y": " b. dicane (m)"
  },
  {
    "x": "dican bîyayene",
    "y": " b. dicane bîyene"
  },
  {
    "x": "dicane (m)",
    "y": " gebe, iki canlı, hamile"
  },
  {
    "x": "dicane bîyene",
    "y": " gebe olmak, hamile olmak, iki canlı olmak"
  },
  {
    "x": "dicaney (m)",
    "y": " b. dicanîye (m)"
  },
  {
    "x": "dicanî (m)",
    "y": " b. dicanîye (m)"
  },
  {
    "x": "dicanîye (m)",
    "y": " gebelik, hamilelik"
  },
  {
    "x": "dicay",
    "y": " b. dicey"
  },
  {
    "x": "dicay kerdeni",
    "y": " b. dicey kerdene"
  },
  {
    "x": "dicayene",
    "y": " b. dejayene"
  },
  {
    "x": "dicayîş (n)",
    "y": " b. dejayîş (n)"
  },
  {
    "x": "dice (n)",
    "y": " b. jûje (n), dije (n)"
  },
  {
    "x": "dicey",
    "y": " iki bölüm, iki parça"
  },
  {
    "x": "dicey kerdene",
    "y": " iki bölüme ayırmak, iki parçaya ayırmak"
  },
  {
    "x": "dicey kerdeni",
    "y": " b. dicey kerdene"
  },
  {
    "x": "dicêy",
    "y": " b. dicey"
  },
  {
    "x": "dicêy kerdene",
    "y": " b. dicey kerdene"
  },
  {
    "x": "dicnayene",
    "y": " b. dejnayene"
  },
  {
    "x": "dicnayîş (n)",
    "y": " b. dejnayîş (n)"
  },
  {
    "x": "dicni (m)",
    "y": " b. dijne (m)"
  },
  {
    "x": "dicnin (n)",
    "y": " b. dijnin (n)"
  },
  {
    "x": "dicûnê (m)",
    "y": " b. dicanîye (m)"
  },
  {
    "x": "diçat (n)",
    "y": " iki yol kavşağı"
  },
  {
    "x": "dida (n)",
    "y": " b. didan (n)"
  },
  {
    "x": "didan (n)",
    "y": " diş"
  },
  {
    "x": "didanê kursî (n)",
    "y": " azıdişi"
  },
  {
    "x": "didanê qirnî (n)",
    "y": " azıdişi"
  },
  {
    "x": "didanî çîriknayene",
    "y": " dişlerini gıcırdatmak"
  },
  {
    "x": "didanî qirçnayene",
    "y": " dişlerini gıcırdatmak"
  },
  {
    "x": "didonî qiresnayene",
    "y": " b. didanî qirçnayene"
  },
  {
    "x": "didanî seqênayene",
    "y": " diş bilemek"
  },
  {
    "x": "didansaz, -e",
    "y": " diş doktoru, diş hekimi, dişçi"
  },
  {
    "x": "dide",
    "y": " b. di"
  },
  {
    "x": "dideyîn, -e",
    "y": " b. dîyine"
  },
  {
    "x": "didi",
    "y": " b. di"
  },
  {
    "x": "didin, -e",
    "y": " b. dîyin, -e"
  },
  {
    "x": "didini (m)",
    "y": " b. dîyine"
  },
  {
    "x": "didiyîn, -e",
    "y": " b. dîyin, -e"
  },
  {
    "x": "didîn, -e",
    "y": " b. dîyin, -e"
  },
  {
    "x": "didon (n)",
    "y": " b. didan (n)"
  },
  {
    "x": "didû (n)",
    "y": " b. didan (n)"
  },
  {
    "x": "didûn (n)",
    "y": " b. didan (n)"
  },
  {
    "x": "difek (n)",
    "y": " kazma"
  },
  {
    "x": "difs (n)",
    "y": " b. dims (n)"
  },
  {
    "x": "difsin, -e (s)",
    "y": " b. dimsin, -e (s)"
  },
  {
    "x": "digamî (zh)",
    "y": " iki adım atlama"
  },
  {
    "x": "digamî çingdayîş",
    "y": " iki adım atlama"
  },
  {
    "x": "digamî çîngdayîş",
    "y": " b. digamî çingdayîş"
  },
  {
    "x": "digamî erzîyayîş (n)",
    "y": " iki adım atlama"
  },
  {
    "x": "digamî kildayîş (n)",
    "y": " iki adım atlama"
  },
  {
    "x": "digamî perayîş (n)",
    "y": " iki adım atlama"
  },
  {
    "x": "digan (m)",
    "y": " b. digane (m)"
  },
  {
    "x": "digan bîyayene",
    "y": " b. digane bîyene"
  },
  {
    "x": "digane (m)",
    "y": " gebe, iki canlı, hamile"
  },
  {
    "x": "digane bîyene",
    "y": " gebe olmak, hamile olmak, iki canlı olmak"
  },
  {
    "x": "diganênî (m)",
    "y": " gebelik, hamilelik"
  },
  {
    "x": "digani (m)",
    "y": " b. digane (m)"
  },
  {
    "x": "digani bîyayene",
    "y": " b. digane bîyene"
  },
  {
    "x": "diganî (m)",
    "y": " b. digane (m)"
  },
  {
    "x": "diganî (m)",
    "y": " b. diganîye (m)"
  },
  {
    "x": "diganî bîyayene",
    "y": " b. digane bîyene"
  },
  {
    "x": "diganîye (m)",
    "y": " gebelik, hamilelik"
  },
  {
    "x": "digomî (zh)",
    "y": " b. digamî (zh)"
  },
  {
    "x": "digomî perayîş (n)",
    "y": " b. digamî perayîş (n)"
  },
  {
    "x": "digose (m)",
    "y": " b. digoşe (m)"
  },
  {
    "x": "digoşe (m)",
    "y": " dirgen (iki dişli dirgen)"
  },
  {
    "x": "digoşî (m)",
    "y": " b. digoşe (m)"
  },
  {
    "x": "digueş (m)",
    "y": " b. digoşe (m)"
  },
  {
    "x": "digûmî",
    "y": " b. digamî (zh)"
  },
  {
    "x": "digûmî perayîş (n)",
    "y": " b. digamî perayîş (n)"
  },
  {
    "x": "digûn (m)",
    "y": " b. digane (m)"
  },
  {
    "x": "digûn bîyayene",
    "y": " b. digane bîyene"
  },
  {
    "x": "digûnê (m)",
    "y": " b. diganîye (m)"
  },
  {
    "x": "digyûn (m)",
    "y": " b. digane (m)"
  },
  {
    "x": "digyûn bîyayene",
    "y": " b. digane bîyene"
  },
  {
    "x": "digyûnî (m)",
    "y": " b. diganîye (m)"
  },
  {
    "x": "Dih",
    "y": " Eruh "
  },
  {
    "x": "diha-I",
    "y": " b. daha"
  },
  {
    "x": "diha-II (m)",
    "y": " b. dua (m)"
  },
  {
    "x": "dihemwelatîyîye (m)",
    "y": " çifte vatandaşlık"
  },
  {
    "x": "dihîre (m)",
    "y": " öğle "
  },
  {
    "x": "dihîre de",
    "y": " öğleyin (öğle vakti)"
  },
  {
    "x": "dihîri (m)",
    "y": " b. dihîre (m)"
  },
  {
    "x": "dihîri di",
    "y": " b. dihîre de"
  },
  {
    "x": "dijdenî (m)",
    "y": " b. dizdî (m), dizdîye (m)"
  },
  {
    "x": "dijdî (m)",
    "y": " b. dizdî (m)"
  },
  {
    "x": "dijdî ya",
    "y": " b. dizdî ya"
  },
  {
    "x": "dijdîye (m)",
    "y": " b. dizdîye (m)"
  },
  {
    "x": "dijdîye kerdene",
    "y": " b. dizdîye kerdene"
  },
  {
    "x": "dijdîyekerdis (n)",
    "y": " b. dizdîyekerdiş (n)"
  },
  {
    "x": "dijdîyên (m)",
    "y": " b. dizdîye (m)"
  },
  {
    "x": "dijdîyên kerdene",
    "y": " b. dizdîye kerdene"
  },
  {
    "x": "dijdîyênkerdis (n)",
    "y": " b. dizdîyekerdiş (n)"
  },
  {
    "x": "dije (n)",
    "y": " kirpi"
  },
  {
    "x": "dijey",
    "y": " b. dicey"
  },
  {
    "x": "dijey kerdene",
    "y": " b. dicey kerdene"
  },
  {
    "x": "dijgi (m)",
    "y": " b. dijnike (m)"
  },
  {
    "x": "dijmen, -e",
    "y": " b. dişmen, -e"
  },
  {
    "x": "dijnayene",
    "y": " b. dejnayene"
  },
  {
    "x": "dijnayîş (n)",
    "y": " b. dejnayîş (n)"
  },
  {
    "x": "dijne-I (m)",
    "y": " yağmur"
  },
  {
    "x": "dijne-II (m)",
    "y": " un, tuz ve yağdan yapılan bir yemek"
  },
  {
    "x": "dijni (m)",
    "y": " b. dijne (m)"
  },
  {
    "x": "dijnike (m)",
    "y": " un, tuz ve yağdan yapılan bir yemek"
  },
  {
    "x": "dijniki (m)",
    "y": " b. dijnike (m)"
  },
  {
    "x": "dijnin (n)",
    "y": " yağmurlu"
  },
  {
    "x": "dika (n)",
    "y": " b. dikan (n)"
  },
  {
    "x": "dikan (n)",
    "y": " dükkân, bakkal"
  },
  {
    "x": "dikancî, -ye",
    "y": " b. dikandar, -e"
  },
  {
    "x": "dikandar, -e",
    "y": " dükkâncı, bakkal"
  },
  {
    "x": "dikesî, -ye",
    "y": " çift kişilik"
  },
  {
    "x": "diko (n)",
    "y": " b. dikan (n)"
  },
  {
    "x": "dikon (n)",
    "y": " b. dikan (n)"
  },
  {
    "x": "dikondar, -i",
    "y": " b. dikandar, -e"
  },
  {
    "x": "dikulik (n)",
    "y": " bir kuş çeşidi"
  },
  {
    "x": "dikû (n)",
    "y": " b. dikan (n)"
  },
  {
    "x": "dikûn (n)",
    "y": " b. dikan (n)"
  },
  {
    "x": "dikûndar, -i",
    "y": " b. dikandar, -e"
  },
  {
    "x": "dil (m)",
    "y": " b. zerrî (m)"
  },
  {
    "x": "dil (n)",
    "y": " b. zerrî (m)"
  },
  {
    "x": "dilapa (m)",
    "y": " b. dilope (m)"
  },
  {
    "x": "dilape (m)",
    "y": " b. dilope (m)"
  },
  {
    "x": "dilavi (m)",
    "y": " b. dolabe (m)"
  },
  {
    "x": "dilavî (n)",
    "y": " yıkanma ve çamaşır yıkama zamanı, yunak"
  },
  {
    "x": "dildan (n)",
    "y": " b. didan (n)"
  },
  {
    "x": "dildon (n)",
    "y": " b. didan (n)"
  },
  {
    "x": "dilejg bîyene",
    "y": " çatallanmak (çatal gibi ikiye ayrılmak)"
  },
  {
    "x": "dilejgbîyayîş (n)",
    "y": " çatallanma"
  },
  {
    "x": "dilete (m)",
    "y": " ikiz "
  },
  {
    "x": "dileti (m)",
    "y": " b. dilete (m)"
  },
  {
    "x": "diletray (m)",
    "y": " ikizray"
  },
  {
    "x": "dilmaşt",
    "y": " b. dêmlaşt, -e"
  },
  {
    "x": "dilope (m)",
    "y": " damla"
  },
  {
    "x": "dilq (n)",
    "y": " biçim, şekil"
  },
  {
    "x": "dilqueç (m)",
    "y": " b. daqoçe (m)"
  },
  {
    "x": "dilule (n)",
    "y": " bir tüfek çeşidi"
  },
  {
    "x": "dilûle (n)",
    "y": " b. dilule (n)"
  },
  {
    "x": "dilvet (m)",
    "y": " b. dirbete (m)"
  },
  {
    "x": "dilvetin (n)",
    "y": " b. dirbetin (n)"
  },
  {
    "x": "dim-I (n)",
    "y": " 1)deste\r\n~2)kabza\r\n~3)sabanın çift sürerken elle tutulan kısmı\r\n~4)sap (araç için)\r\n~5)kuyruk"
  },
  {
    "x": "dimê şimşêrî (n)",
    "y": " kılıç kabzası"
  },
  {
    "x": "dimê birkeyî (n)",
    "y": " sabanın çift sürerken elle tutulan kısmı"
  },
  {
    "x": "dimê birkî (n)",
    "y": " b. dimê birkeyî (n)"
  },
  {
    "x": "dimê hengajî (n)",
    "y": " b. dimê hengazî (n)"
  },
  {
    "x": "dimê hengazî (n)",
    "y": " sabanın çift sürerken elle tutulan kısmından toprağa kadar uzanan parçası"
  },
  {
    "x": "dimê vaştirû (n)",
    "y": " b. dimê vaşturî (n)"
  },
  {
    "x": "dimê vaşturî (n)",
    "y": " orak sapı"
  },
  {
    "x": "dimê zengenî (n)",
    "y": " kazma sapı"
  },
  {
    "x": "dim qol kerdene",
    "y": " kuyruğunu kesmek "
  },
  {
    "x": "dim quel kerdene",
    "y": " b. dim qol kerdene"
  },
  {
    "x": "dim-II",
    "y": " b. dima"
  },
  {
    "x": "bi dim",
    "y": " b. dima"
  },
  {
    "x": "dim kewtene",
    "y": " (kadın için) kaçmak"
  },
  {
    "x": "dim kotene",
    "y": " b. dim kewtene"
  },
  {
    "x": "dim kowtene",
    "y": " b. dim kewtene"
  },
  {
    "x": "dim-dim",
    "y": " b. dam-dam"
  },
  {
    "x": "dim-dim geyrayîne",
    "y": " b. dam-dam gêrayene"
  },
  {
    "x": "dima-I",
    "y": " 1)arkasından, ardından, peşinden\r\n~2)sonra, bilahare; sonradan, sonraları"
  },
  {
    "x": "bi dima",
    "y": " b. dima ra"
  },
  {
    "x": "dima coy",
    "y": " b. dima cû"
  },
  {
    "x": "dima cû",
    "y": " ondan sonra"
  },
  {
    "x": "dima ra",
    "y": " 1)ardından\r\n~2)sonra, bilahare; sonradan"
  },
  {
    "x": "dima şîyene",
    "y": " izlemek"
  },
  {
    "x": "dimay rî",
    "y": " b. dimayî rê"
  },
  {
    "x": "dimayî rê",
    "y": " sonraya\r\n~Dimayî rê verde. (Sonraya bırak.)\r\n~\t\r\n~Wa dimayî rê bimano. (Sonraya kalsın.)"
  },
  {
    "x": "ra dima",
    "y": " -den sonra, ardından"
  },
  {
    "x": "dima-II (n)",
    "y": " b. duman (n)"
  },
  {
    "x": "diman (n)",
    "y": " b. duman (n)"
  },
  {
    "x": "dimaşîyawox, -e",
    "y": " b. dimaşîyayox, -e"
  },
  {
    "x": "dimaşîyayîş (n)",
    "y": " izleme"
  },
  {
    "x": "dimaşîyayox, -e",
    "y": " izleyici"
  },
  {
    "x": "dimaşkil (n)",
    "y": " akrep"
  },
  {
    "x": "dimbilî, -ye-I",
    "y": " b. kirmanc, -e"
  },
  {
    "x": "dimbilî-II (m)",
    "y": " b. kirmanckî (m)"
  },
  {
    "x": "dimbilîkî (m)",
    "y": " b. kirmanckî (m)"
  },
  {
    "x": "dimbilkî (m)",
    "y": " b. kirmanckî (m)"
  },
  {
    "x": "dimbişkul (n)",
    "y": " b. dimaşkil (n)"
  },
  {
    "x": "dimdar, -e",
    "y": " izleyici"
  },
  {
    "x": "dime-I",
    "y": " arkasından, ardından"
  },
  {
    "x": "dime ra",
    "y": " ardından"
  },
  {
    "x": "ra dime",
    "y": " b. ra dima"
  },
  {
    "x": "dime-II (n)",
    "y": " 1)koyun veya koç kuyruğu\r\n~2)kuyrukyağı"
  },
  {
    "x": "dimgoşa (m)",
    "y": " b. digoşe (m)"
  },
  {
    "x": "dimgueş (m)",
    "y": " b. digoşe (m) "
  },
  {
    "x": "dimgûşi (m)",
    "y": " b. digoşe (m) "
  },
  {
    "x": "dimi",
    "y": " b. dima"
  },
  {
    "x": "dimilî, -ye-I",
    "y": " b. kirmanc, -e"
  },
  {
    "x": "dimilî-II (m)",
    "y": " b. kirmanckî (m)"
  },
  {
    "x": "dimilîkî (m)",
    "y": " b. kirmanckî (m)"
  },
  {
    "x": "dimilkî (m)",
    "y": " b. kirmanckî (m)"
  },
  {
    "x": "dimkewtiş (n)",
    "y": " (kadın için) kaçma"
  },
  {
    "x": "dimkotiş (n)",
    "y": " b. dimkewtiş (n)"
  },
  {
    "x": "dimkowtiş (n)",
    "y": " b. dimkewtiş (n)"
  },
  {
    "x": "dimlî-I",
    "y": " b. kirmanc, -e"
  },
  {
    "x": "dimlî-II (m)",
    "y": " b. kirmanckî (m)"
  },
  {
    "x": "dimo (n)",
    "y": " b. duman (n)"
  },
  {
    "x": "dimun (n)",
    "y": " b. duman (n)"
  },
  {
    "x": "dimoçik (n)",
    "y": " kuyruk"
  },
  {
    "x": "dimpistike (m)",
    "y": " b. dimpiştik (n)"
  },
  {
    "x": "dimpişîtik (n)",
    "y": " b. dimpiştik (n)"
  },
  {
    "x": "dimpiştik (n)",
    "y": " akrep "
  },
  {
    "x": "dimpiştike (m)",
    "y": " b. dimpiştik (n)"
  },
  {
    "x": "dimqol, -e-I",
    "y": " kuyruğu kesik hayvan"
  },
  {
    "x": "dimqol-II (n)",
    "y": " kısa saplı (kürek vb.)"
  },
  {
    "x": "dimquel (n)",
    "y": " b. dimqol (n)"
  },
  {
    "x": "dims (n)",
    "y": " pekmez"
  },
  {
    "x": "dimsin, -e (s)",
    "y": " pekmezli"
  },
  {
    "x": "dimû (n)",
    "y": " b. duman (n)"
  },
  {
    "x": "dimûn (n)",
    "y": " b. duman (n)"
  },
  {
    "x": "dindan (n)",
    "y": " b. didan (n)"
  },
  {
    "x": "dindanî qirçnayeni",
    "y": " b. didanî qirçnayene"
  },
  {
    "x": "dindon (n)",
    "y": " b. didan (n)"
  },
  {
    "x": "dindonî qîrçinayeni",
    "y": " b. didanî qirçnayene"
  },
  {
    "x": "dindûn (n)",
    "y": " b. didan (n)"
  },
  {
    "x": "dindûn qirnîy (n)",
    "y": " b. didanê qirnî (n)"
  },
  {
    "x": "ding-I (n)",
    "y": " durak "
  },
  {
    "x": "dingê otobuse (n)",
    "y": " otobüs durağı"
  },
  {
    "x": "dingê taksî (n)",
    "y": " taksi durağı"
  },
  {
    "x": "ding-II (n)",
    "y": " b. dinge (m)"
  },
  {
    "x": "ding-III (n)",
    "y": " b. danga (m)"
  },
  {
    "x": "dinge (m)",
    "y": " 1)dibek (içinde bulgur vb. dövülen büyük havan), soku, dink\r\n~2)bir tür dönen tahterevalli"
  },
  {
    "x": "dinge ro dayene",
    "y": " sıkıştırmak (birini sıkıştırmak)"
  },
  {
    "x": "dingerodayîş (n)",
    "y": " sıkıştırma (birini sıkıştırma)"
  },
  {
    "x": "dingil (n)",
    "y": " aks, dingil"
  },
  {
    "x": "dingûşi (m)",
    "y": " b. digoşe (m)"
  },
  {
    "x": "dinya (m)",
    "y": " dünya, felek"
  },
  {
    "x": "dinya Homayî",
    "y": " (mecazi) çok fazla, diz boyu (çok miktarda)"
  },
  {
    "x": "dinya xo bedilnayene",
    "y": " b. dinyaya xo bedilnayene"
  },
  {
    "x": "dinya xwi bedelnayene",
    "y": " b. dinyaya xo bedilnayene"
  },
  {
    "x": "dinya xwi virnayene",
    "y": " b. dinyaya xo vurnayene"
  },
  {
    "x": "dinya xwi vurrnayêne",
    "y": " b. dinyaya xo vurnayene"
  },
  {
    "x": "dinyaya xo bedilnayene",
    "y": " vefat etmek"
  },
  {
    "x": "dinyaya xo vurnayene",
    "y": " vefat etmek"
  },
  {
    "x": "dinya (n)",
    "y": " b. dinya (m)"
  },
  {
    "x": "dinyanedik, -e",
    "y": " b. dinyanedî, -ye"
  },
  {
    "x": "dinyanedî, -ye",
    "y": " sonradan görme"
  },
  {
    "x": "dinyaxobedilnayîş (n)",
    "y": " b. dinyayaxobedilnayîş (n)"
  },
  {
    "x": "dinyaxwibedelnayîş (n)",
    "y": " b. dinyayaxobedilnayîş (n)"
  },
  {
    "x": "dinyaxwivirnayîş (n)",
    "y": " b. dinyayaxovurnayîş (n)"
  },
  {
    "x": "dinyaxwivurrnayîş (n)",
    "y": " b. dinyayaxovurnayîş (n)"
  },
  {
    "x": "dinyayaxobedilnayîş (n)",
    "y": " vefat etme"
  },
  {
    "x": "dinyayaxovurnayîş (n)",
    "y": " vefat etme"
  },
  {
    "x": "dipistike (m)",
    "y": " b. dimpiştik (n)"
  },
  {
    "x": "dipişîtik (n)",
    "y": " b. dimpiştik (n) "
  },
  {
    "x": "... dir (pd)",
    "y": " b. ... de (pd)"
  },
  {
    "x": "dira bîyene",
    "y": " b. tira bîyene, cira bîyene"
  },
  {
    "x": "dira kerdene",
    "y": " b. tira kerdene, cira kerdene"
  },
  {
    "x": "dirabîyayîş (n)",
    "y": " b. tirabîyayîş (n), cirabîyayîş (n)"
  },
  {
    "x": "dirakerdiş (n)",
    "y": " b. tirakerdiş (n), cirakerdiş (n)"
  },
  {
    "x": "diram (m)",
    "y": " b. dîrheme (m)"
  },
  {
    "x": "dirata (m)",
    "y": " yırtık"
  },
  {
    "x": "dirate (n)",
    "y": " yırtık"
  },
  {
    "x": "diratîye (m)",
    "y": " yırtık"
  },
  {
    "x": "diray",
    "y": " b. derey"
  },
  {
    "x": "diraya (m)",
    "y": " b. dirîyaya (m)"
  },
  {
    "x": "dirayîs (n)",
    "y": " b. dirîyayîş (n)"
  },
  {
    "x": "dirayîş (n)",
    "y": " b. dirîyayîş (n)"
  },
  {
    "x": "dirbete (m)",
    "y": " yara"
  },
  {
    "x": "dirbeti (m)",
    "y": " b. dirbete (m)"
  },
  {
    "x": "dirbetin, -e",
    "y": " yaralı"
  },
  {
    "x": "dirbetin bîyayene",
    "y": " b. dirbetin bîyene"
  },
  {
    "x": "dirbetin bîyene",
    "y": " yaralanmak"
  },
  {
    "x": "dirbetinbîyayîş (n)",
    "y": " yaralanma"
  },
  {
    "x": "dirbetini (m)",
    "y": " b. dirbetine (m)"
  },
  {
    "x": "diremi (m)",
    "y": " b. dîrheme (m)"
  },
  {
    "x": "direwt (m)",
    "y": " b. direwte (m)"
  },
  {
    "x": "direwte (m)",
    "y": " biz"
  },
  {
    "x": "direwtî (m)",
    "y": " b. direwte (m)"
  },
  {
    "x": "direy",
    "y": " b. derey"
  },
  {
    "x": "direye (n)",
    "y": " b. dirîyaye (n)"
  },
  {
    "x": "direyîş (n)",
    "y": " b. dirîyayîş (n)"
  },
  {
    "x": "dirê",
    "y": " b. derey"
  },
  {
    "x": "dirgueş (m)",
    "y": " b. digoşe (m)"
  },
  {
    "x": "dirhem (m)",
    "y": " b. dîrheme (m)"
  },
  {
    "x": "diriç (m)",
    "y": " b. dirrike (m)"
  },
  {
    "x": "dirik (m)",
    "y": " b. dirrike (m)"
  },
  {
    "x": "dirike (m)",
    "y": " b. dirrike (m)"
  },
  {
    "x": "dirikin, -e",
    "y": " b. dirrikin, -e"
  },
  {
    "x": "dirinca (m)",
    "y": " b. derenca (m)"
  },
  {
    "x": "dirişk (n)",
    "y": " b. dirrike (m)"
  },
  {
    "x": "dirita (m)",
    "y": " b. dirata (m)"
  },
  {
    "x": "diriti (n)",
    "y": " b. dirate (n)"
  },
  {
    "x": "dirî (s)",
    "y": " b. dûrî (s)"
  },
  {
    "x": "dirîyaya (m)",
    "y": " yırtık"
  },
  {
    "x": "dirîyaye (n)",
    "y": " yırtık"
  },
  {
    "x": "dirîyayene",
    "y": " yırtılmak"
  },
  {
    "x": "dirîyayêne",
    "y": " b. dirîyayene"
  },
  {
    "x": "dirîyayîş (n)",
    "y": " yırtılma"
  },
  {
    "x": "dirîyayîye (m)",
    "y": " yırtık"
  },
  {
    "x": "dirmix (m)",
    "y": " b. tirmixe (m) "
  },
  {
    "x": "dirmixe (m)",
    "y": " b. tirmixe (m)"
  },
  {
    "x": "dirnaya (m)",
    "y": " yırtık"
  },
  {
    "x": "dirnaye (n)",
    "y": " yırtık"
  },
  {
    "x": "dirnayene",
    "y": " yırtmak"
  },
  {
    "x": "dirnayêne",
    "y": " b. dirnayene"
  },
  {
    "x": "dirnayîs (n)",
    "y": " b. dirnayîş (n)"
  },
  {
    "x": "dirnayîş (n)",
    "y": " yırtma"
  },
  {
    "x": "dirnayîye (m)",
    "y": " yırtık"
  },
  {
    "x": "dirne (n)",
    "y": " b. derne (n)"
  },
  {
    "x": "dirneyin, -e",
    "y": " b. derneyin, -e "
  },
  {
    "x": "dirneyîş (n)",
    "y": " b. dirnayîş (n)"
  },
  {
    "x": "dirnoz, -e",
    "y": " aksi"
  },
  {
    "x": "dirot (m)",
    "y": " b. direwte (m)"
  },
  {
    "x": "dirote (m)",
    "y": " b. direwte (m)"
  },
  {
    "x": "dirotî (m)",
    "y": " b. direwte (m)"
  },
  {
    "x": "dirowtî (m)",
    "y": " b. direwte (m)"
  },
  {
    "x": "dirrata (m)",
    "y": " b. dirata (m)"
  },
  {
    "x": "dirrate (n)",
    "y": " b. dirate (n)"
  },
  {
    "x": "dirratîye (m)",
    "y": " b. diratîye (m)"
  },
  {
    "x": "dirraya (m)",
    "y": " b. dirîyaya (m)"
  },
  {
    "x": "dirraye (n)",
    "y": " b. dirîyaye (n)"
  },
  {
    "x": "dirrayîs (n)",
    "y": " b. dirîyayîş (n)"
  },
  {
    "x": "dirrayîş (n)",
    "y": " b. dirîyayîş (n)"
  },
  {
    "x": "dirreye (n)",
    "y": " b. dirîyaye (n)"
  },
  {
    "x": "dirreyîş (n)",
    "y": " b. dirîyayîş (n)"
  },
  {
    "x": "dirrik (m)",
    "y": " b. dirrike (m) "
  },
  {
    "x": "dirrike (m)",
    "y": " böğürtlen"
  },
  {
    "x": "dirrikêl (m)",
    "y": " b. dirrikêre (m)"
  },
  {
    "x": "dirrikêli (m)",
    "y": " b. dirrikêre (m)"
  },
  {
    "x": "dirrikêre (m)",
    "y": " böğürtlen bitkisi"
  },
  {
    "x": "dirrikêri (m)",
    "y": " b. dirrikêre (m)"
  },
  {
    "x": "dirriki (m)",
    "y": " b. dirrike (m)"
  },
  {
    "x": "dirrikin, -e",
    "y": " (mecazî) yapışkan"
  },
  {
    "x": "dirrîyaya (m)",
    "y": " b. dirîyaya (m)"
  },
  {
    "x": "dirrîyaye (n)",
    "y": " b. dirîyaye (n)"
  },
  {
    "x": "dirrîyayene",
    "y": " b. dirîyayene"
  },
  {
    "x": "dirrîyayêne",
    "y": " b. dirîyayene"
  },
  {
    "x": "dirrîyayîs (n)",
    "y": " b. dirîyayîş (n)"
  },
  {
    "x": "dirrîyayîş (n)",
    "y": " b. dirîyayîş (n)"
  },
  {
    "x": "dirrîyayîye (m)",
    "y": " b. dirîyayîye (m)"
  },
  {
    "x": "dirryayîş (n)",
    "y": " b. dirîyayîş (n)"
  },
  {
    "x": "dirrnaya (m)",
    "y": " b. dirnayîye (m)"
  },
  {
    "x": "dirrnaye (n)",
    "y": " b. dirnaye"
  },
  {
    "x": "dirrnayene",
    "y": " b. dirnayene"
  },
  {
    "x": "dirrnayêne",
    "y": " b. dirnayene"
  },
  {
    "x": "dirrnayîs (n)",
    "y": " b. dirnayîş (n)"
  },
  {
    "x": "dirrnayîş (n)",
    "y": " b. dirnayîş (n)"
  },
  {
    "x": "dirrnayîye (m)",
    "y": " b. dirnayîye (m)"
  },
  {
    "x": "dirv kerdene",
    "y": " b. durî kerdene"
  },
  {
    "x": "dirvet (m)",
    "y": " b. dirbete (m)"
  },
  {
    "x": "dirvete (m)",
    "y": " b. dirbete (m)"
  },
  {
    "x": "dirvetin, -e",
    "y": " b. dirbetin, -e"
  },
  {
    "x": "dirvetî (m)",
    "y": " b. dirbete (m)"
  },
  {
    "x": "dirvêt (m)",
    "y": " b. dirbete (m)"
  },
  {
    "x": "dirvêtî (m)",
    "y": " b. dirbete (m)"
  },
  {
    "x": "dirxaçik (n)",
    "y": " dirgen (iki dişli dirgen)"
  },
  {
    "x": "dirxe, -ye",
    "y": " cimri"
  },
  {
    "x": "dirxên (m)",
    "y": " b. dirxênî (m)"
  },
  {
    "x": "dirxên kerdene",
    "y": " b. dirxênî kerdene"
  },
  {
    "x": "dirxênî (m)",
    "y": " 1)cimrilik\r\n~2)esirgeme (vermekten veya yapmaktan kaçınma)"
  },
  {
    "x": "dirxênî kerdene",
    "y": " 1)cimrilik etmek\r\n~2)esirgemek (vermekten veya yapmaktan kaçınmak)"
  },
  {
    "x": "dirxil, -e",
    "y": " b. delxelî, -ye"
  },
  {
    "x": "dirxime (m)",
    "y": " b. tirmixe (m)"
  },
  {
    "x": "dirxîye (m)",
    "y": " 1) cimrilik\r\n~2)esirgeme (vermekten veya yapmaktan kaçınma)"
  },
  {
    "x": "dirxîye kerdene",
    "y": " 1) cimrilik etmek\r\n~2)esirgemek (vermekten veya yapmaktan kaçınmak)"
  },
  {
    "x": "dirya (n)",
    "y": " b. derya (n)"
  },
  {
    "x": "diryaya (m)",
    "y": " b. dirîyaya (m)"
  },
  {
    "x": "diryaye (n)",
    "y": " b. dirîyaye (n)"
  },
  {
    "x": "diryayîş (n)",
    "y": " b. dirîyayîş (n)"
  },
  {
    "x": "dis (n)",
    "y": " b. cins (n)"
  },
  {
    "x": "disayene",
    "y": " b. dusayene"
  },
  {
    "x": "disayîş (n)",
    "y": " b. dusayîş"
  },
  {
    "x": "diseme (n)",
    "y": " b. dişeme (n)"
  },
  {
    "x": "disibay (n)",
    "y": " bugünden sonraki ikinci gün, öbür gün"
  },
  {
    "x": "disiway (n)",
    "y": " b. disibay (n)"
  },
  {
    "x": "disiwey (n)",
    "y": " b. disibay (n)"
  },
  {
    "x": "diskinayene",
    "y": " b. duskînayene"
  },
  {
    "x": "diskinayîş (n)",
    "y": " b. duskînayîş (n)"
  },
  {
    "x": "diskîyayene",
    "y": " b. duskîyayene"
  },
  {
    "x": "diskîyayîş (n)",
    "y": " b. duskîyayîş (n)"
  },
  {
    "x": "disknayene",
    "y": " b. duskînayene"
  },
  {
    "x": "disknayîş (n)",
    "y": " b. duskînayîş (n)"
  },
  {
    "x": "dislix (n)",
    "y": " dişlix (n)"
  },
  {
    "x": "dislixê ... ameyene",
    "y": " b. dişlixê ... ameyene"
  },
  {
    "x": "dismal (m)",
    "y": " b. destmale (m)"
  },
  {
    "x": "dismale (m)",
    "y": " b. destmale (m)"
  },
  {
    "x": "dismali (m)",
    "y": " b. destmale (m)"
  },
  {
    "x": "dismen, -e",
    "y": " b. dişmen, -e"
  },
  {
    "x": "dismenayîne (m)",
    "y": " b. dişmenîye (m)"
  },
  {
    "x": "disnayene",
    "y": " b. dusnayene"
  },
  {
    "x": "disnayîş (n)",
    "y": " b. dusnayîş (n)"
  },
  {
    "x": "distandardîye (m)",
    "y": " çifte standart"
  },
  {
    "x": "distarî (m)",
    "y": " b. destarî (m)"
  },
  {
    "x": "distarre (m)",
    "y": " b. destarî (m)"
  },
  {
    "x": "distarrî (m)",
    "y": " b. destarî (m)"
  },
  {
    "x": "dişax bîyene",
    "y": " çatallanmak (çatal gibi ikiye ayrılmak)"
  },
  {
    "x": "dişaxbîyayîş (n)",
    "y": " çatallanma"
  },
  {
    "x": "dişeme (n)",
    "y": " pazartesi "
  },
  {
    "x": "dişembi (n)",
    "y": " b. dişeme (n)"
  },
  {
    "x": "dişemi (n)",
    "y": " b. dişeme (n)"
  },
  {
    "x": "dişime (n)",
    "y": " b. dişeme (n)"
  },
  {
    "x": "dişlix (n)",
    "y": " dışlık"
  },
  {
    "x": "dişlixê ... ameyene",
    "y": " dışlığı gelmek "
  },
  {
    "x": "dişme (n)",
    "y": " b. dişmen (n)"
  },
  {
    "x": "dişmen, -e",
    "y": " düşman"
  },
  {
    "x": "dişmenê/a canî",
    "y": " can düşmanı"
  },
  {
    "x": "dişmenê/a ganî",
    "y": " can düşmanı "
  },
  {
    "x": "dişmenê/a gonî",
    "y": " b. dişmenê/a ganî\r\n~Wey la keynê, yê ma rî benî dişmenê gonî” (Deyîra “Zerencay Mi”)"
  },
  {
    "x": "dişmenê/a gûnî",
    "y": " b. dişmenê/a ganî"
  },
  {
    "x": "dişmenayî (m)",
    "y": " b. dişmenîye (m)"
  },
  {
    "x": "dişmeney (m)",
    "y": " b. dişmenîye (m)"
  },
  {
    "x": "dişmenî (m)",
    "y": " düşmanlık "
  },
  {
    "x": "dişmenîye (m)",
    "y": " düşmanlık"
  },
  {
    "x": "dişmin, -e",
    "y": " düşman"
  },
  {
    "x": "dişmişbîyayîş (n)",
    "y": " b. duşunmîşbîyayîş (n)"
  },
  {
    "x": "dişmîşbeyîş (n)",
    "y": " b. duşunmîşbîyayîş (n)"
  },
  {
    "x": "dişmîşbîyayîş (n)",
    "y": " b. duşunmîşbîyayîş (n)"
  },
  {
    "x": "dişmîşeyîş (n)",
    "y": " b. duşunmîşbîyayîş (n)"
  },
  {
    "x": "dişmîşîyayîş (n)",
    "y": " b. duşunmîşbîyayîş (n)"
  },
  {
    "x": "dişmîşyayîş (n)",
    "y": " b. duşunmîşbîyayîş (n)"
  },
  {
    "x": "dişmûn, -e",
    "y": " b. dişmen, -e"
  },
  {
    "x": "dişmûnî (m)",
    "y": " b. dişmenî (m)"
  },
  {
    "x": "dişmûntî (m)",
    "y": " b. dişmenî (m)"
  },
  {
    "x": "diştar (m)",
    "y": " b. diştere (m)"
  },
  {
    "x": "diştere (m)",
    "y": " grip"
  },
  {
    "x": "dişterî (m)",
    "y": " b. diştere (m)"
  },
  {
    "x": "ditene",
    "y": " sağmak"
  },
  {
    "x": "ditiş (n)",
    "y": " sağma"
  },
  {
    "x": "divacer",
    "y": " b. devacêr"
  },
  {
    "x": "diver (n)",
    "y": " çift en"
  },
  {
    "x": "diw (n)",
    "y": " kuyruk"
  },
  {
    "x": "diwar, -e-I",
    "y": " b. dewar, -e-I"
  },
  {
    "x": "diwar-II",
    "y": " aşağı\r\n~Şi diwar. (Aşağı indi.)"
  },
  {
    "x": "diwêl (m)",
    "y": " b. duwel (n)"
  },
  {
    "x": "diwês",
    "y": " on iki"
  },
  {
    "x": "diwêsin, -e",
    "y": " onikinci "
  },
  {
    "x": "diwi (m)",
    "y": " b. dewe (m)"
  },
  {
    "x": "diwic (n)",
    "y": " b. dewij (n)"
  },
  {
    "x": "dixaçkane (m)",
    "y": " b. dixaçkane (m)"
  },
  {
    "x": "dixaskane (m)",
    "y": " lohusa, loğusa"
  },
  {
    "x": "dixaskanênî (m)",
    "y": " lohusalık, loğusalık"
  },
  {
    "x": "dixaskanî (m)",
    "y": " lohusalık, loğusalık"
  },
  {
    "x": "dixaskanîye (m)",
    "y": " lohusalık, loğusalık"
  },
  {
    "x": "dixazkane (m)",
    "y": " b. dixaskane (m)"
  },
  {
    "x": "dixazkanênî (m)",
    "y": " b. dixaskanênî (m)"
  },
  {
    "x": "dixazkanî (m)",
    "y": " b. dixaskanîye (m), dixaskanî (m)"
  },
  {
    "x": "dixazkanîye (m)",
    "y": " b. dixaskanîye (m), dixaskanî (m)"
  },
  {
    "x": "dixtor (n)",
    "y": " b. doktor, -e"
  },
  {
    "x": "diyaxkerdiş (n)",
    "y": " b. deyaxkerdiş (n)"
  },
  {
    "x": "diyin, -e",
    "y": " b. dîyin, -e"
  },
  {
    "x": "dizan (n)",
    "y": " b. didan (n)"
  },
  {
    "x": "dizbih (m)",
    "y": " b. tezbîhe (m)"
  },
  {
    "x": "dizd, -e",
    "y": " hırsız"
  },
  {
    "x": "dizd (n)",
    "y": " aşık kemiğiyle oynanan bir oyunda bu kemiğin eni geniş olan iki tarafından girintili olanı"
  },
  {
    "x": "dizdey (m)",
    "y": " b. dizdî (m), dizdîye (m)"
  },
  {
    "x": "dizdey kerdene",
    "y": " b. dizdîye kerdene"
  },
  {
    "x": "dizdeykerdiş (n)",
    "y": " b. dizdîyekerdiş (n)"
  },
  {
    "x": "dizdê (m)",
    "y": " b. dizdî (m), dizdîye (m)"
  },
  {
    "x": "dizdê kerdene",
    "y": " b. dizdî kerdene"
  },
  {
    "x": "dizdêkerdiş (n)",
    "y": " b. dizdîkerdiş (n)"
  },
  {
    "x": "dizdênî (m)",
    "y": " 1)b. dizdî (m), dizdîye (m)\r\n~2)gizli"
  },
  {
    "x": "dizdênî ya",
    "y": " b. dizdî ya"
  },
  {
    "x": "dizdî (m)",
    "y": " hırsızlık"
  },
  {
    "x": "dizdî kerdene",
    "y": " çalmak"
  },
  {
    "x": "dizdî ya",
    "y": " gizlice"
  },
  {
    "x": "dizdîka",
    "y": " gizlice"
  },
  {
    "x": "dizdîkerdiş (n)",
    "y": " çalma"
  },
  {
    "x": "dizdîye (m)",
    "y": " hırsızlık"
  },
  {
    "x": "dizdîye kerdene",
    "y": " çalmak"
  },
  {
    "x": "dizdîyekerdiş (n)",
    "y": " çalma"
  },
  {
    "x": "dize-I (m)",
    "y": " kale"
  },
  {
    "x": "dize-II (m)",
    "y": " b. dêze (m)"
  },
  {
    "x": "dizi (m)",
    "y": " b. dize-I (m)"
  },
  {
    "x": "dizike (m)",
    "y": " küçük kale"
  },
  {
    "x": "diziki (m)",
    "y": " b. dizike (m)"
  },
  {
    "x": "dizme (n)",
    "y": " fişeklik, kargılık"
  },
  {
    "x": "diznaya (m)",
    "y": " çalıntı"
  },
  {
    "x": "diznaye (n)",
    "y": " çalıntı"
  },
  {
    "x": "diznayene",
    "y": " 1)çalmak\r\n~2)araklamak (argo), aşırmak (argo)"
  },
  {
    "x": "diznayîş (n)",
    "y": " çalma"
  },
  {
    "x": "diznayî, -ye",
    "y": " çalıntı"
  },
  {
    "x": "dizon (n)",
    "y": " b. didan (n)"
  },
  {
    "x": "dizonî qirçnayene",
    "y": " b. didanî qirçnayene"
  },
  {
    "x": "dî (n, z)",
    "y": " b. ey (n, z)"
  },
  {
    "x": "Dîcle (m)",
    "y": " Dicle"
  },
  {
    "x": "dîdar (n)",
    "y": " görüşme, mülakat"
  },
  {
    "x": "dîdaro fokusî (n)",
    "y": " odak görüşme"
  },
  {
    "x": "dîeza (m)",
    "y": " b. dêze-I (m)"
  },
  {
    "x": "dîfterî (m)",
    "y": " difteri, kuşpalazı"
  },
  {
    "x": "dîhîre (m)",
    "y": " b. dihîre (m)"
  },
  {
    "x": "dîje (n)",
    "y": " b. dije (n)"
  },
  {
    "x": "dîjîtal, -e (s)",
    "y": " dijital, sayısal"
  },
  {
    "x": "Dîjle (m)",
    "y": " b. Dîcle (m)"
  },
  {
    "x": "dîç (n)",
    "y": " b. dîk (n)"
  },
  {
    "x": "dîk (n)",
    "y": " 1)horoz\r\n~2)erkek kuş"
  },
  {
    "x": "dîkê misrî (n)",
    "y": " hindi"
  },
  {
    "x": "dîkê orisî (n)",
    "y": " hatmi, ağaçküpesi"
  },
  {
    "x": "dîkê qaze (n)",
    "y": " hindi"
  },
  {
    "x": "dîkê ûrisî (n)",
    "y": " b. dîkê orisî (n)"
  },
  {
    "x": "dîkê ûrizî (n)",
    "y": " b. dîkê orisî (n)"
  },
  {
    "x": "dîkleyîr (n)",
    "y": " küçük yaştaki horoz veya erkek keklik"
  },
  {
    "x": "dîkleyr (n)",
    "y": " b. dîkleyîr (n)"
  },
  {
    "x": "dîklihîr (n)",
    "y": " b. dîkleyîr (n)"
  },
  {
    "x": "dîksileman (n)",
    "y": " b. dîksilêman (n)"
  },
  {
    "x": "dîksilêman (n)",
    "y": " ibibik kuşu, hüthüt"
  },
  {
    "x": "dîktator, -e",
    "y": " diktatör "
  },
  {
    "x": "dîktatorîye (m)",
    "y": " diktatörlük "
  },
  {
    "x": "dîktraksîyon (n)",
    "y": " diktraksiyon"
  },
  {
    "x": "dîkvengdan (n)",
    "y": " güneşin doğuş vakti"
  },
  {
    "x": "dîkvengdanî",
    "y": " güneşin doğuş vaktinde"
  },
  {
    "x": "dîkveyndan (n)",
    "y": " b. dîkvengdan (n)"
  },
  {
    "x": "dîkveyndanî",
    "y": " b. dîkvengdanî"
  },
  {
    "x": "dîkveyndon (n)",
    "y": " b. dîkvengdan (n)"
  },
  {
    "x": "dîkveyndonî",
    "y": " b. dîkvengdanî"
  },
  {
    "x": "dîleg (n)",
    "y": " b. waştiş (n)"
  },
  {
    "x": "dîlime (m)",
    "y": " dilim"
  },
  {
    "x": "dîlimi (m)",
    "y": " b. dîlime (m)"
  },
  {
    "x": "dîm dayene",
    "y": " b. dêm dayene"
  },
  {
    "x": "dîm deyayîne",
    "y": " b. dêm dîyayene"
  },
  {
    "x": "dîm dîyayîne",
    "y": " b. dêm dîyayene"
  },
  {
    "x": "dîmdayîş (n)",
    "y": " b. dêmdayîş (n)"
  },
  {
    "x": "dîmen (n)",
    "y": " görüntü"
  },
  {
    "x": "dîmen de roşnî/roştî (sn)",
    "y": " görüntüde keskinlik"
  },
  {
    "x": "dîmenê pey kamera (sn)",
    "y": " kamera arkası görüntüleri"
  },
  {
    "x": "dîmeno lêl (n, sn)",
    "y": " bulanık görüntü"
  },
  {
    "x": "dîmeno mijxelî (n, sn)",
    "y": " bulanık görüntü"
  },
  {
    "x": "dîmeno zelal (sn)",
    "y": " net görüntü"
  },
  {
    "x": "dîmenkêşî (m, sn)",
    "y": " çekim"
  },
  {
    "x": "dîmenkêşîya beqkî (sn)",
    "y": " alt çekim"
  },
  {
    "x": "dîmenkêşîya ceribnayîşî (sn)",
    "y": " deneme çekim"
  },
  {
    "x": "dîmenkêşîya cêrrakî (sn)",
    "y": " alt çekim"
  },
  {
    "x": "dîmenkêşîya corrakî (sn)",
    "y": " üst çekim"
  },
  {
    "x": "dîmenkêşîya çûçikkî (sn)",
    "y": " üst çekim"
  },
  {
    "x": "dîmenkêşîya detaykî (sn)",
    "y": " detay çekim"
  },
  {
    "x": "dîmenkêşîya hêdîkî (sn)",
    "y": " yavaş çekim"
  },
  {
    "x": "dîmenkêşîya lezkî (sn)",
    "y": " hızlı çekim"
  },
  {
    "x": "dîmenkêşîya mîlçikkî (sn)",
    "y": " üst çekim"
  },
  {
    "x": "dîmenkêşîya qirîncelekî (sn)",
    "y": " alt çekim"
  },
  {
    "x": "dîmenkêşîya zoomkî (sn)",
    "y": " zoom çekim"
  },
  {
    "x": "dîmensîyon (n)",
    "y": " boyut"
  },
  {
    "x": "dîmlaşt",
    "y": " b. dêmlaşt, -e"
  },
  {
    "x": "dîn (n)",
    "y": " din"
  },
  {
    "x": "dîn dayîne",
    "y": " b. dêm dayene"
  },
  {
    "x": "dîn dîyayîne",
    "y": " b. dêm dîyayene"
  },
  {
    "x": "dîna-I (m)",
    "y": " b. dinya (m) "
  },
  {
    "x": "dîna xwi bedilnayîne",
    "y": " b. dinyaya xo bedilnayene"
  },
  {
    "x": "dîna-II (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "dînamîk, -e",
    "y": " dinamik"
  },
  {
    "x": "dînamo (n)",
    "y": " b. dinamo"
  },
  {
    "x": "dînamometre (n)",
    "y": " kuvvetölçer, dinamometre"
  },
  {
    "x": "dînan (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "dînaxwibedilnayîş (n)",
    "y": " b. dinyayaxobedilnayîş (n)"
  },
  {
    "x": "dînc, -e",
    "y": " sağlıklı"
  },
  {
    "x": "dînc bîyene",
    "y": " sağlıklı olmak, sıhhatli olmak\r\n~Ez dînc a. (Ben iyiyim.)"
  },
  {
    "x": "dîncbîyayîş (n)",
    "y": " sağlıklı olma"
  },
  {
    "x": "dîndar, -e",
    "y": " dindar"
  },
  {
    "x": "dîndarey (m)",
    "y": " b. dîndarîye (m)"
  },
  {
    "x": "dîndarê (m)",
    "y": " b. dîndarîye (m)"
  },
  {
    "x": "dîndarênî (m)",
    "y": " dindarlık"
  },
  {
    "x": "dîndarî (m)",
    "y": " dindarlık"
  },
  {
    "x": "dîndarîye (m)",
    "y": " dindarlık"
  },
  {
    "x": "dîndaş, -e",
    "y": " dindaş"
  },
  {
    "x": "dîndaşî (m)",
    "y": " dindaşlık"
  },
  {
    "x": "dîndaşîye (m)",
    "y": " dindaşlık"
  },
  {
    "x": "dîndayîş (n)",
    "y": " b. dêmdayîş (n)"
  },
  {
    "x": "dîne (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "dînî, -ye",
    "y": " dini, dinsel"
  },
  {
    "x": "dînî bîyene",
    "y": " dinselleşmek"
  },
  {
    "x": "dînîbîyayîş (n)",
    "y": " dinselleşme"
  },
  {
    "x": "dînîtî (m)",
    "y": " dinsellik"
  },
  {
    "x": "dînîyey (m)",
    "y": " b. dînîyî (m)"
  },
  {
    "x": "dînîyê (m) ",
    "y": " b. dînîyîye (m)"
  },
  {
    "x": "dînîyî (m)",
    "y": " dinsellik"
  },
  {
    "x": "dînîyîye (m)",
    "y": " dinsellik"
  },
  {
    "x": "dînratebe, -ye",
    "y": " b. dînrateber, -e"
  },
  {
    "x": "dînrateber, -e",
    "y": " dindışı"
  },
  {
    "x": "dînrateper, -e",
    "y": " b. dînrateber, -e"
  },
  {
    "x": "dînratever, -e",
    "y": " b. dînrateber, -e"
  },
  {
    "x": "dînratewer, -e",
    "y": " b. dînrateber, -e"
  },
  {
    "x": "dînratiwer, -e",
    "y": " b. dînrateber, -e"
  },
  {
    "x": "dînû (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "dînya (m)",
    "y": " b. dinya (m) "
  },
  {
    "x": "dîplema (m)",
    "y": " b. dîploma (m)"
  },
  {
    "x": "dîploma (m)",
    "y": " diploma"
  },
  {
    "x": "dîplomat, -e",
    "y": " diplomat"
  },
  {
    "x": "dîqet (n)",
    "y": " dikkat"
  },
  {
    "x": "dîqqet (n)",
    "y": " b. dîqet (n)"
  },
  {
    "x": "dîr (s)",
    "y": " b. dûrî (s)"
  },
  {
    "x": "dîreg (m)",
    "y": " b. dîrege (m)"
  },
  {
    "x": "dîrege (m)",
    "y": " direk"
  },
  {
    "x": "dîregi (m)",
    "y": " b. dîrege (m)"
  },
  {
    "x": "dîreksîyon (n)",
    "y": " direksiyon, yönelteç"
  },
  {
    "x": "dîreksîyone (m)",
    "y": " b. dîreksîyon (n)"
  },
  {
    "x": "dîrem (m)",
    "y": " b. dîrheme (m)"
  },
  {
    "x": "dîrhem (m)",
    "y": " b. dîrheme (m)"
  },
  {
    "x": "dîrheme (m)",
    "y": " dirhem"
  },
  {
    "x": "dîrî (s)",
    "y": " b. dûrî (s)"
  },
  {
    "x": "dîrsega borîya soba (m)",
    "y": " b. dîrsegê borîya soba (n)"
  },
  {
    "x": "dîrsega lûleyê soba (m)",
    "y": " b. dîrsegê lulîya soba (n)"
  },
  {
    "x": "dîrsegê borîya soba (n)",
    "y": " soba borusu dirseği"
  },
  {
    "x": "dîrsegê lulîya soba (n)",
    "y": " soba borusu dirseği "
  },
  {
    "x": "dîs (n)",
    "y": " b. dês (n)"
  },
  {
    "x": "dîsîplîn (n)",
    "y": " disiplin"
  },
  {
    "x": "dîsk (n)",
    "y": " disk"
  },
  {
    "x": "dîskete (m) (TE)",
    "y": " disket"
  },
  {
    "x": "dîskur (n)",
    "y": " söylem"
  },
  {
    "x": "dîskuro cinsîyetperest (n)",
    "y": " cinsiyetçi söylem"
  },
  {
    "x": "dîsleksî (m)",
    "y": " disleksi"
  },
  {
    "x": "dîvacer",
    "y": " b. devacêr"
  },
  {
    "x": "dîvitin (n)",
    "y": " b. dîvîtîn (n)"
  },
  {
    "x": "dîvîtîn (n)",
    "y": " divitin "
  },
  {
    "x": "dîwan (n)",
    "y": " divan"
  },
  {
    "x": "dîwan bestene",
    "y": " divan teşkil etmek, divan kurmak"
  },
  {
    "x": "dîwanê edaletî (n)",
    "y": " adalet divanı"
  },
  {
    "x": "Dîwanê Edaletî yê Laheye (n)",
    "y": " Lahey Adalet Divanı "
  },
  {
    "x": "dîwane (m)",
    "y": " b. dîwan (n)"
  },
  {
    "x": "dîwar (n)",
    "y": " duvar"
  },
  {
    "x": "dîwarê îstînadî (n)",
    "y": " istinat duvarı"
  },
  {
    "x": "dîwarkar, -e",
    "y": " duvarcı"
  },
  {
    "x": "dîwon (n)",
    "y": " b. dîwan (n)"
  },
  {
    "x": "dîwon bestene",
    "y": " b. dîwan bestene"
  },
  {
    "x": "dîwûn (n)",
    "y": " b. dîwan (n)"
  },
  {
    "x": "dîwûn bestene",
    "y": " b. dîwan bestene"
  },
  {
    "x": "dîya (m)",
    "y": " b. dua (m)"
  },
  {
    "x": "dîyafram (n)",
    "y": " diyafram"
  },
  {
    "x": "dîyalekte (m)",
    "y": " lehçe"
  },
  {
    "x": "dîyalog (n)",
    "y": " diyalog"
  },
  {
    "x": "dîyar, -e-I",
    "y": " belirtili"
  },
  {
    "x": "dîyar-II (n)",
    "y": " ziyaret"
  },
  {
    "x": "dîyar mendene",
    "y": " ziyaret etmek"
  },
  {
    "x": "dîyar şîyene",
    "y": " ziyaret etmek"
  },
  {
    "x": "Dîyarbek (m)",
    "y": " b. Dîyarbekir (n)"
  },
  {
    "x": "Dîyarbeki (m)",
    "y": " b. Dîyarbekir (n)"
  },
  {
    "x": "Dîyarbekir (n)",
    "y": " Diyarbakır"
  },
  {
    "x": "dîyarî (m)",
    "y": " ziyaret"
  },
  {
    "x": "dîyarîye (m)",
    "y": " ziyaret"
  },
  {
    "x": "dîyarmendiş (n)",
    "y": " ziyaret etme"
  },
  {
    "x": "dîyarşîyayîş (n)",
    "y": " ziyaret etme"
  },
  {
    "x": "dîyax (n)",
    "y": " b. deyax (n)"
  },
  {
    "x": "dîyaxkerdiş (n)",
    "y": " b. deyaxkerdiş (n)"
  },
  {
    "x": "dîyayene werdene",
    "y": " yedirilmek"
  },
  {
    "x": "dîyayene",
    "y": " b. dîyene"
  },
  {
    "x": "dîyayêne",
    "y": " b. dîyene"
  },
  {
    "x": "dîyayîne",
    "y": " b. dîyene"
  },
  {
    "x": "dîyayîs (n)",
    "y": " b. dîyayîş (n)"
  },
  {
    "x": "dîyayîş (n)",
    "y": " 1)görme\r\n~2)görüş\r\n~3)bulma"
  },
  {
    "x": "dîyayîşkî",
    "y": " görsel"
  },
  {
    "x": "dîyek (m)",
    "y": " b. dêke (m)"
  },
  {
    "x": "dîyene",
    "y": " 1)görmek\r\n~2)bulmak\r\n~Bizê ke vîndî bîbî nêameyê dîyene. (Kaybolan keçiler bulunmamış.)\r\n~Mi goşareyê xo dî. (Küpelerimi buldum.)"
  },
  {
    "x": "dîyer (n)",
    "y": " dîyar-II (n)"
  },
  {
    "x": "dîyer mendene",
    "y": " b. dîyar mendene"
  },
  {
    "x": "dîyar şîyene",
    "y": " b. dîyer şîyene"
  },
  {
    "x": "Dîyerbeki (m)",
    "y": " b. Dîyarbekir (n)"
  },
  {
    "x": "Dîyerbekir (n)",
    "y": " Diyarbakır "
  },
  {
    "x": "dîyerpu (n)",
    "y": " b. dîyerpue (n) "
  },
  {
    "x": "dîyerpue (n)",
    "y": " eğe, törpü"
  },
  {
    "x": "dîyerpui (n)",
    "y": " b. dîyerpue (n) "
  },
  {
    "x": "dîyes-I (n)",
    "y": " b. dês (n)"
  },
  {
    "x": "dîyes-II",
    "y": " b. diwês"
  },
  {
    "x": "dîyet (n)",
    "y": " diyet"
  },
  {
    "x": "dîyetîsyen, -e",
    "y": " diyetisyen"
  },
  {
    "x": "dîyez (m)",
    "y": " b. dêze-I (m)"
  },
  {
    "x": "dîyeza (m)",
    "y": " b. dêze-I (m)"
  },
  {
    "x": "dîyezik (m)",
    "y": " b. dêzike (m)"
  },
  {
    "x": "dîyezwûn",
    "y": " b. dêwezan (n)"
  },
  {
    "x": "dîyên, -e",
    "y": " b. dîyin, -e"
  },
  {
    "x": "dîyês",
    "y": " b. diwês"
  },
  {
    "x": "dîyin, -e",
    "y": " ikinci"
  },
  {
    "x": "dîyiri (m)",
    "y": " b. deyîre (m)"
  },
  {
    "x": "dîyîn, -e",
    "y": " b. dîyin, -e"
  },
  {
    "x": "dîyri (m)",
    "y": " b. deyîre (m)"
  },
  {
    "x": "dîzanterî (m)",
    "y": " dizanteri"
  },
  {
    "x": "dîzayn (n)",
    "y": " tasarım, tasarçizim, dizayn"
  },
  {
    "x": "dîzayno bajarkî (n)",
    "y": " kentsel tasarım"
  },
  {
    "x": "dîzayno şaristankî (n)",
    "y": " kentsel tasarım"
  },
  {
    "x": "dîzaynir, -e",
    "y": " tasarçizimci, tasarımcı"
  },
  {
    "x": "dîzaynkar, -e",
    "y": " tasarçizimci, tasarımcı"
  },
  {
    "x": "dîzaynkara/ê belgan (sn)",
    "y": " kostüm tasarımcısı"
  },
  {
    "x": "dîzaynkara/ê kostuman (sn)",
    "y": " kostüm tasarımcısı"
  },
  {
    "x": "dîzike (m)",
    "y": " b. dêzike (m)"
  },
  {
    "x": "do-I (n)",
    "y": " ayran"
  },
  {
    "x": "dowo hest (n)",
    "y": " b. doyo hest (n)"
  },
  {
    "x": "doyo hest (n)",
    "y": " koyu ayran"
  },
  {
    "x": "doyo qalin (n)",
    "y": " b. doyo qalind (n)"
  },
  {
    "x": "doyo qalind (n)",
    "y": " koyu ayran"
  },
  {
    "x": "do-II",
    "y": " fiilin gelecek zamanında kullanılan bir partikel\r\n~Ez do vaja. (Ben söyleyeceğim.)"
  },
  {
    "x": "do-III (m)",
    "y": " b. dewe (m)"
  },
  {
    "x": "doçik (n)",
    "y": " kuyruk"
  },
  {
    "x": "dode (n)",
    "y": " b. dûdû (n) "
  },
  {
    "x": "dodo (n)",
    "y": " b. dûdû (n) "
  },
  {
    "x": "Dodoma (m)",
    "y": " Dodoma"
  },
  {
    "x": "doe (m)",
    "y": " b. dewe (m)"
  },
  {
    "x": "dogma (m)",
    "y": " dogma "
  },
  {
    "x": "dogmatîk, -e",
    "y": " dogmatik"
  },
  {
    "x": "Doha (m)",
    "y": " Doha "
  },
  {
    "x": "dohêm (n)",
    "y": " b. ohêm (n)"
  },
  {
    "x": "dohil (n)",
    "y": " b. dawul (n)"
  },
  {
    "x": "doil (n)",
    "y": " b. dawul (n)"
  },
  {
    "x": "doje (n)",
    "y": " cehennem\r\n~Adirê dojî de kê (çê) to kuyo! (Cehennem ateşi evine düşsün!)"
  },
  {
    "x": "doktor, -e",
    "y": " doktor, hekim, tabip, sağaltman"
  },
  {
    "x": "doktora çiman (m)",
    "y": " göz doktoru"
  },
  {
    "x": "doktora didanan (m)",
    "y": " diş doktoru, diş hekimi, dişçi"
  },
  {
    "x": "doktora domanan/qican/tutan/gedeyan",
    "y": " çocuk doktoru"
  },
  {
    "x": "doktora keyeyî (m)",
    "y": " aile doktoru, aile hekimi"
  },
  {
    "x": "doktorê çiman (n)",
    "y": " göz doktoru"
  },
  {
    "x": "doktorê didanan (n)",
    "y": " diş doktoru, diş hekimi, dişçi"
  },
  {
    "x": "doktorê domanan/qican/tutan/gedeyan",
    "y": " çocuk doktoru"
  },
  {
    "x": "doktorê keyeyî (n)",
    "y": " aile doktoru, aile hekimi"
  },
  {
    "x": "doktora (m)",
    "y": " doktora "
  },
  {
    "x": "dokuman (n)",
    "y": " belge, vesika"
  },
  {
    "x": "dokumankerdiş (n)",
    "y": " belgeleme"
  },
  {
    "x": "dokumanter, -e",
    "y": " belgesel"
  },
  {
    "x": "dokumantero muşahid (sn)",
    "y": " gözlemci belgesel"
  },
  {
    "x": "dokumantero observator (sn)",
    "y": " gözlemci belgesel"
  },
  {
    "x": "dol-I (n)",
    "y": " b. dewl (n)"
  },
  {
    "x": "dol-II (m)",
    "y": " b. dole (m)"
  },
  {
    "x": "dolab (n)",
    "y": " b. dolabe (m)"
  },
  {
    "x": "dolabê cemedî (n)",
    "y": " b. dolaba cemedî (m)"
  },
  {
    "x": "dolabê kincan (n)",
    "y": " b. dolaba kincan (m)"
  },
  {
    "x": "dolabê solan (n)",
    "y": " b. dolaba solan (m)"
  },
  {
    "x": "dolabe (m)",
    "y": " dolap"
  },
  {
    "x": "dolaba awkeyî (m)",
    "y": " banyo dolabı"
  },
  {
    "x": "dolaba banyoyî (m)",
    "y": " banyo dolabı"
  },
  {
    "x": "dolaba cemedî (m)",
    "y": " buzdolabı"
  },
  {
    "x": "dolaba cilan (m)",
    "y": " gardırop, elbise dolabı, giysi dolabı"
  },
  {
    "x": "dolaba çale (m)",
    "y": " banyo dolabı"
  },
  {
    "x": "dolaba çerxî (m)",
    "y": " banyo dolabı"
  },
  {
    "x": "dolaba hemamekî (m)",
    "y": " banyo dolabı"
  },
  {
    "x": "dolaba kincan (m)",
    "y": " gardırop, elbise dolabı, giysi dolabı"
  },
  {
    "x": "dolaba qeşayî (m)",
    "y": " buzdolabı"
  },
  {
    "x": "dolaba sewlan (m)",
    "y": " b. dolaba solan (m)"
  },
  {
    "x": "dolaba solan (m)",
    "y": " ayakkabı dolabı "
  },
  {
    "x": "dolaba sowlan (m)",
    "y": " b. dolaba solan (m)"
  },
  {
    "x": "dolaba şewatî (m)",
    "y": " yangın dolabı"
  },
  {
    "x": "dolabe çarnayene",
    "y": " dolap çevirmek, dolap döndürmek"
  },
  {
    "x": "dolav (n)",
    "y": " b. dolabe (m)"
  },
  {
    "x": "dolavi (m)",
    "y": " b. dolabe (m)"
  },
  {
    "x": "dolaxe (m)",
    "y": " atkı"
  },
  {
    "x": "dolbend (m)",
    "y": " b. dolbende (m)"
  },
  {
    "x": "dolbende (m)",
    "y": " başörtü, başörtüsü, kıvrak (başörtü), tülbent"
  },
  {
    "x": "dolbendi (m)",
    "y": " b. dolbende (m)"
  },
  {
    "x": "dolçe (n)",
    "y": " b. dewlçe (n)"
  },
  {
    "x": "dole (m)",
    "y": " 1)vadi, koyak\r\n~2)havuz\r\n~3)göl"
  },
  {
    "x": "dolek (n)",
    "y": " gölcük"
  },
  {
    "x": "dolet (n)",
    "y": " b. dewletî (n)"
  },
  {
    "x": "doletê (m)",
    "y": "  b. dewletîyî (m), dewletîyîye (m)"
  },
  {
    "x": "doleti (n)",
    "y": " b. dewletî (n)"
  },
  {
    "x": "doletin",
    "y": " b. dewletî, -ye"
  },
  {
    "x": "doletinê (m)",
    "y": " b. dewletîyî (m), dewletîyîye (m)"
  },
  {
    "x": "doli (m)",
    "y": " b. dole (m)"
  },
  {
    "x": "dolik (m)",
    "y": " b. dollike (m)"
  },
  {
    "x": "doliki (m)",
    "y": " b. dollike (m)"
  },
  {
    "x": "dolim (n)",
    "y": " b. donime (m)"
  },
  {
    "x": "dolim (m)",
    "y": " b. donime (m)"
  },
  {
    "x": "dolime (m)",
    "y": " b. donime (m)"
  },
  {
    "x": "dolimi (m)",
    "y": " b. donime (m)"
  },
  {
    "x": "dolimna",
    "y": " b. donimna"
  },
  {
    "x": "dollik (m)",
    "y": " b. dollike (m)"
  },
  {
    "x": "dollike (m)",
    "y": " şalvarın iki bacak arasında sarkan arka bölümü"
  },
  {
    "x": "dolliki (m)",
    "y": " b. dollike (m)"
  },
  {
    "x": "dolm (n)",
    "y": " b. donime (m)"
  },
  {
    "x": "dolma (m)",
    "y": " (yemek için) dolma"
  },
  {
    "x": "dolmaya zeytine (m)",
    "y": " zeytinyağlı dolma"
  },
  {
    "x": "dol-mal (n)",
    "y": " b. dorme (n), dorûver (n)"
  },
  {
    "x": "dolme (n)",
    "y": " b. dolma (m)"
  },
  {
    "x": "dolmeyo zeytin (n)",
    "y": " b. dolmaya zeytine (m)"
  },
  {
    "x": "dolum (m)",
    "y": " b. donime (m)"
  },
  {
    "x": "dolvend (m)",
    "y": " b. dolbende (m)"
  },
  {
    "x": "dom-I (n)",
    "y": " devam"
  },
  {
    "x": "dom bîyene",
    "y": " b. dewam bîyene"
  },
  {
    "x": "dom kerdene",
    "y": " b. dewam kerdene"
  },
  {
    "x": "dom-II (m)",
    "y": " b. dame-II (m)"
  },
  {
    "x": "dom-dom",
    "y": " b. dam-dam"
  },
  {
    "x": "dom-dom geyrayîne",
    "y": " b. dam-dam gêrayene"
  },
  {
    "x": "doma",
    "y": " b. dima"
  },
  {
    "x": "doman, -e",
    "y": " çocuk"
  },
  {
    "x": "doman ver şîyene",
    "y": " düşük yapmak"
  },
  {
    "x": "doman bîyene",
    "y": " çocuklaşmak"
  },
  {
    "x": "domanane",
    "y": " çocukça"
  },
  {
    "x": "domanbîyayîş (n)",
    "y": " çocuklaşma"
  },
  {
    "x": "domangeh (n)",
    "y": " anaokulu, ana mektebi, çocuk yuvası, kreş"
  },
  {
    "x": "domankî",
    "y": " çocukça"
  },
  {
    "x": "domar (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "domare (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "domari (m)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "domari (m)",
    "y": " b. damare (m)"
  },
  {
    "x": "domarrî (m)",
    "y": " b. damêrrî (m), dêmarrî (m)"
  },
  {
    "x": "domate (m)",
    "y": " b. tomatêse (m)"
  },
  {
    "x": "domatêse (m)",
    "y": " b. tomatêse (m)"
  },
  {
    "x": "domatêze (m)",
    "y": " b. tomatêse (m)"
  },
  {
    "x": "domçe (n)",
    "y": " b. dabançe (n)"
  },
  {
    "x": "dome (n)",
    "y": " b. dame-I (n) "
  },
  {
    "x": "domi (m)",
    "y": " b. dame-II (m)"
  },
  {
    "x": "domirî (m)",
    "y": " b. damêrrî (m), dêmarrî (m)"
  },
  {
    "x": "Domînîk (n)",
    "y": " Dominik"
  },
  {
    "x": "domnayîş",
    "y": " sürdürme, devam etme"
  },
  {
    "x": "domnayene",
    "y": " devam etmek, sürdürmek"
  },
  {
    "x": "domen, -e",
    "y": " b. doman, -e"
  },
  {
    "x": "domon, -e",
    "y": " b. doman, -e"
  },
  {
    "x": "domon ver şîyayêne",
    "y": " b. doman ver şîyene"
  },
  {
    "x": "domrî (m)",
    "y": " b. damêrrî (m), dêmarrî (m)"
  },
  {
    "x": "domû (n)",
    "y": " b. doman (n)"
  },
  {
    "x": "domûn, -e",
    "y": " b. doman, -e"
  },
  {
    "x": "don (n)",
    "y": " ilik"
  },
  {
    "x": "donê astî (n)",
    "y": " b. donê esteyî (n)"
  },
  {
    "x": "donê esteyî (n)",
    "y": " ilik"
  },
  {
    "x": "dona (m)",
    "y": " b. donime (m)"
  },
  {
    "x": "done (n)",
    "y": " veri (araştırma vb. için)"
  },
  {
    "x": "doner (n)",
    "y": " döner"
  },
  {
    "x": "donga (m)",
    "y": " b. danga (m)"
  },
  {
    "x": "dongoşi (m)",
    "y": " b. digoşe (m)"
  },
  {
    "x": "donim (n)",
    "y": " b. donime (m)"
  },
  {
    "x": "donime (m)",
    "y": " 1)kez, kere, defa, sefer; dönüm\r\n~2)dönüm"
  },
  {
    "x": "donimê",
    "y": " 1)bir kez\r\n~2)bir dönüm"
  },
  {
    "x": "donimêk",
    "y": " 1)bir kez\r\n~2)bir dönüm"
  },
  {
    "x": "donimi (m)",
    "y": " b. donime (m)"
  },
  {
    "x": "donimna",
    "y": " yine, gene, tekrar"
  },
  {
    "x": "donî",
    "y": " b. dorna"
  },
  {
    "x": "donumi (m)",
    "y": " b. donime (m)"
  },
  {
    "x": "donumna",
    "y": " b. donimna"
  },
  {
    "x": "donxuz, -e",
    "y": " domuz (b. xoz, -e)"
  },
  {
    "x": "dool (n)",
    "y": " b. dawul (n)"
  },
  {
    "x": "door, -e",
    "y": " b. dewar, -e"
  },
  {
    "x": "dopîyes (n)",
    "y": " döpiyes"
  },
  {
    "x": "doq (m)",
    "y": " b. dewqe (m)"
  },
  {
    "x": "doqê ... şîyayene",
    "y": " b. dewqa ... şîyene"
  },
  {
    "x": "doqi-I (m)",
    "y": " b. dewqe (m)"
  },
  {
    "x": "doqi-II (m)",
    "y": " b. tewqe-I (m)"
  },
  {
    "x": "doqmaq (n)",
    "y": " b. toqmaq (n)"
  },
  {
    "x": "doqşîyayîş (n)",
    "y": " b. dewqeşîyayîş (n)"
  },
  {
    "x": "dor-I (m)",
    "y": " b. dore-I (m)"
  },
  {
    "x": "dor-II (m)",
    "y": " b. dore-II (m)"
  },
  {
    "x": "dor-III (m)",
    "y": " b. dore-III (m)"
  },
  {
    "x": "dor a",
    "y": " b. dore ra"
  },
  {
    "x": "dor-IV (n)",
    "y": " b. dewr-I (n)"
  },
  {
    "x": "dor-V (n)",
    "y": " çevre"
  },
  {
    "x": "dor-mag (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "dor-mal (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "dor-male (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "dor-mali (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "dor-mar (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "dor-mare (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "dor-mel (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "dor-mele (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "dor-mere (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "dorane (m)",
    "y": " incebağırsak"
  },
  {
    "x": "dorani (m)",
    "y": " b. dorane (m)"
  },
  {
    "x": "dorçî, -ye",
    "y": " nöbetçi"
  },
  {
    "x": "dore-I (m)",
    "y": " küp"
  },
  {
    "x": "dore-II (m)",
    "y": " kez, kere, defa, sefer"
  },
  {
    "x": "dorê",
    "y": " bir kez"
  },
  {
    "x": "dorêk",
    "y": " bir kez"
  },
  {
    "x": "dorîk",
    "y": " bir kez"
  },
  {
    "x": "dore-III (m)",
    "y": " nöbet"
  },
  {
    "x": "dore bi dore",
    "y": " nöbetleşe"
  },
  {
    "x": "dore kerdene",
    "y": " nöbetleşmek"
  },
  {
    "x": "dore pawitene",
    "y": " nöbet beklemek"
  },
  {
    "x": "dore ra",
    "y": " nöbetleşe"
  },
  {
    "x": "dore ra kerdene",
    "y": " nöbetleşe yapmak"
  },
  {
    "x": "dore reyde",
    "y": " nöbetleşe"
  },
  {
    "x": "dore reyra",
    "y": " nöbetleşe"
  },
  {
    "x": "dore-IV (n)",
    "y": " testi"
  },
  {
    "x": "dorêş (n)",
    "y": " b. dewrêş (n)"
  },
  {
    "x": "dorêşek (n)",
    "y": " b. dewrêşek (n)"
  },
  {
    "x": "dorêşî (m)",
    "y": " b. dewrêşîye (m)"
  },
  {
    "x": "dori-I (m)",
    "y": " b. dore-I (m)"
  },
  {
    "x": "dori-II (m)",
    "y": " b. dore-II (m)"
  },
  {
    "x": "dori-III (m)",
    "y": " b. dore-III (m)"
  },
  {
    "x": "dori-IV (n)",
    "y": " b. dore-IV (n)"
  },
  {
    "x": "dorimi (m)",
    "y": " b. donime (m)"
  },
  {
    "x": "dorimna",
    "y": " b. donimna"
  },
  {
    "x": "dorî (n)",
    "y": " b. dore-IV (n)"
  },
  {
    "x": "dor-mal (n)",
    "y": " b. dorme (n), dorûver (n)"
  },
  {
    "x": "dor-male (n)",
    "y": " b. dorme (n), dorûver (n)"
  },
  {
    "x": "dor-mar (n)",
    "y": " b. dorme (n), dorûver (n)"
  },
  {
    "x": "dor-mare (n)",
    "y": " b. dorme (n), dorûver (n)"
  },
  {
    "x": "dor-ver (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "dorme (n)",
    "y": " çevre, dolay, etraf, muhit"
  },
  {
    "x": "dorme ra ver estene",
    "y": " b. dorme ra ver eştene"
  },
  {
    "x": "dorme ra ver eştene",
    "y": " döven sürerken kenarlara kayan sapları kürek veya yabayla harmanın ortasına doğru kaydırmak"
  },
  {
    "x": "dormedor (n)",
    "y": " b. dorme (n), dorûver (n)"
  },
  {
    "x": "dorna",
    "y": " yine, gene, tekrar"
  },
  {
    "x": "dornê",
    "y": " b. dorna"
  },
  {
    "x": "doroş, -e",
    "y": " ayrancı"
  },
  {
    "x": "doroşîye (m)",
    "y": " ayrancılık"
  },
  {
    "x": "dorpêç (n)",
    "y": " abluka, kuşatma, muhasara"
  },
  {
    "x": "dorpêç domnayene",
    "y": " abluka altında tutmak, kuşatmayı sürdürmek"
  },
  {
    "x": "dorpêç hewa dayene",
    "y": " ablukayı kaldırmak"
  },
  {
    "x": "dorpêç hewa nayene",
    "y": " ablukayı kaldırmak"
  },
  {
    "x": "dorpêç kerdene",
    "y": " abluka etmek, ablukaya almak, kuşatmak, muhasara altına almak, muhasara etmek, çevrelemek, ihata etmek"
  },
  {
    "x": "dorpêç şeqnayene",
    "y": " ablukayı yarmak"
  },
  {
    "x": "dorpêç ver kerdene",
    "y": " ablukayı yarmak"
  },
  {
    "x": "dorpêç wedardayene",
    "y": " ablukayı kaldırmak"
  },
  {
    "x": "dorpêç wedaritene",
    "y": " ablukayı kaldırmak"
  },
  {
    "x": "dorpêç wedarnayene",
    "y": " ablukayı kaldırmak"
  },
  {
    "x": "dorpêçî de bîyene",
    "y": " abluka altında olmak, kuşatılmak, çevrelenmek"
  },
  {
    "x": "dorpêçî de mendene",
    "y": " abluka altında kalmak"
  },
  {
    "x": "dorpêçîdebîyayîş (n)",
    "y": " çevrelenme, çevreleniş"
  },
  {
    "x": "dorpêçkerdiş (n)",
    "y": " abluka etme, çevreleme"
  },
  {
    "x": "dorumna",
    "y": " b. donimna"
  },
  {
    "x": "doroni (m)",
    "y": " b. dorane (m)"
  },
  {
    "x": "dorumi (m)",
    "y": " b. donime (m)"
  },
  {
    "x": "dorumna",
    "y": " b. donimna"
  },
  {
    "x": "dorûber (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "dorûdormale",
    "y": " b. derûdorme (n)"
  },
  {
    "x": "dorûdormare",
    "y": " b. derûdorme (n)"
  },
  {
    "x": "dorûver (n)",
    "y": " çevre, dolay, etraf, muhit"
  },
  {
    "x": "dorûvero bêcan (n)",
    "y": " cansız çevre"
  },
  {
    "x": "dorûvero bêgan (n)",
    "y": " cansız çevre"
  },
  {
    "x": "dorûvergirewtiş (n)",
    "y": " abluka, kuşatma, muhasara"
  },
  {
    "x": "dorûverî, -ye",
    "y": " çevresel"
  },
  {
    "x": "dorûvernas, -e",
    "y": " çevrebilimci, ekolojist"
  },
  {
    "x": "dorûvernasî (m)",
    "y": " çevrebilim, ekoloji"
  },
  {
    "x": "dorûvernasîye (m)",
    "y": " çevrebilim, ekoloji"
  },
  {
    "x": "dorûverpawitox, -e",
    "y": " çevreci"
  },
  {
    "x": "dorûverpawitoxî (m)",
    "y": " çevrecilik"
  },
  {
    "x": "dorûverpawitoxîye (m)",
    "y": " çevrecilik"
  },
  {
    "x": "dorûveryar, -e",
    "y": " çevre dostu"
  },
  {
    "x": "dorxi, -ye",
    "y": " doğru (b. rast, -e; raşt, -e)"
  },
  {
    "x": "dorxî, -ye",
    "y": " doğru (b. rast, -e; raşt, -e)"
  },
  {
    "x": "dos kerdene",
    "y": " b. doz kerdene"
  },
  {
    "x": "dose (m)",
    "y": " b. doşe (m)"
  },
  {
    "x": "doseg (n)",
    "y": " b. doşek (n)"
  },
  {
    "x": "doser a",
    "y": " b. dosere ra"
  },
  {
    "x": "dosere (n)",
    "y": " tan, fecir, şafak"
  },
  {
    "x": "dosere ra",
    "y": " şafak vakti"
  },
  {
    "x": "doskerdiş (n)",
    "y": " b. dozkerdiş (n)"
  },
  {
    "x": "dosnayene",
    "y": " b. dewisnayene"
  },
  {
    "x": "dosnayîne",
    "y": " b. dewisnayene"
  },
  {
    "x": "dosnayîş (n)",
    "y": " b. dewisnayîş (n)"
  },
  {
    "x": "dost, -e",
    "y": " 1)dost, can dostu, can arkadaşı, can ciğer\r\n~2)yâr, metres"
  },
  {
    "x": "dost bîyene",
    "y": " can ciğer  olmak"
  },
  {
    "x": "dosta/ê çorşmeyî",
    "y": " çevre dostu"
  },
  {
    "x": "dosta/ê dorûverî",
    "y": " çevre dostu"
  },
  {
    "x": "dostatî (m)",
    "y": " b. dostîye (m)"
  },
  {
    "x": "doste (m)",
    "y": " metres"
  },
  {
    "x": "dostey (m)",
    "y": " b. dostîye (m)"
  },
  {
    "x": "dostî (m)",
    "y": " dostluk"
  },
  {
    "x": "dostîye (m)",
    "y": " dostluk"
  },
  {
    "x": "dosya (m)",
    "y": " dosya"
  },
  {
    "x": "dosyayê nimiteyî (zh)",
    "y": " gizli dosyalar"
  },
  {
    "x": "doş (n)",
    "y": " b. doşe (m)"
  },
  {
    "x": "doşe (m)",
    "y": " omuz"
  },
  {
    "x": "doşeg (n)",
    "y": " b. doşek (n)"
  },
  {
    "x": "doşek (n)",
    "y": " döşek, şilte"
  },
  {
    "x": "doşelme (n)",
    "y": " b. doşirme (n)"
  },
  {
    "x": "doşirme (n)",
    "y": " döşeme\r\n~“Verê berê Efendî doşirme wo.” (“Hesenê Hesikî”, Vate, Nr. 29)"
  },
  {
    "x": "doşirme kerdene",
    "y": " döşemek, tefriş etmek"
  },
  {
    "x": "doşirmekerdiş (n)",
    "y": " döşeme, tefriş etme"
  },
  {
    "x": "doşî (m)",
    "y": " b. doşe (m)"
  },
  {
    "x": "doşt, -e",
    "y": " b. dost, -e"
  },
  {
    "x": "dot",
    "y": " b. wet"
  },
  {
    "x": "dot a",
    "y": " b. wet ra\r\n~Nat a şî, dot a nîyame. (Bu taraftan gitti, o taraftan gelmedi.)"
  },
  {
    "x": "dot ra",
    "y": " b. wet ra"
  },
  {
    "x": "dota",
    "y": " b. weta"
  },
  {
    "x": "dotên, -e",
    "y": " b. wetên, -e"
  },
  {
    "x": "dotine",
    "y": " b. ditene"
  },
  {
    "x": "dotiş (n)",
    "y": " b. ditiş (n)"
  },
  {
    "x": "doûr, -e",
    "y": " b. dewar, -e"
  },
  {
    "x": "dover (n)",
    "y": " b. bover (n)"
  },
  {
    "x": "dovîz (n)",
    "y": " döviz"
  },
  {
    "x": "dow (m)",
    "y": " b. dewe (m)"
  },
  {
    "x": "dowar, -e",
    "y": " b. dewar, -e"
  },
  {
    "x": "dowe (m)",
    "y": " b. dewe (m)"
  },
  {
    "x": "dowi (m)",
    "y": " b. dewe (m)"
  },
  {
    "x": "dowil (m)",
    "y": " b. dawul (n)"
  },
  {
    "x": "dowil (n)",
    "y": " b. dawul (n)"
  },
  {
    "x": "dowilbaz, -e",
    "y": " b. dawulbaz, -e"
  },
  {
    "x": "dowl (n)",
    "y": " b. dewl (n)"
  },
  {
    "x": "dowlçe (n)",
    "y": " b. dewlçe (n)"
  },
  {
    "x": "dowm (n)",
    "y": " b. dom (n)"
  },
  {
    "x": "dowm bîyene",
    "y": " b. dewam bîyene"
  },
  {
    "x": "dowm kerdene",
    "y": " b. dewam kerdene"
  },
  {
    "x": "dowor, -e",
    "y": " b. dewar, -e"
  },
  {
    "x": "dowosnayene",
    "y": " b. dewisnayene"
  },
  {
    "x": "dowosnayêne",
    "y": " b. dewisnayene"
  },
  {
    "x": "dowosnayîs (n)",
    "y": " b. dewisnayîş (n)"
  },
  {
    "x": "dowosnayîş (n)",
    "y": " b. dewisnayîş (n)"
  },
  {
    "x": "dowqi (m)",
    "y": " b. dewqe (m)"
  },
  {
    "x": "dowqa ... şîyayene",
    "y": " b. dewqa ... şîyene"
  },
  {
    "x": "dowqişîyayîş (n)",
    "y": " b. dewqeşîyayîş (n)"
  },
  {
    "x": "dowr-I (m)",
    "y": " b. dore-III (m)"
  },
  {
    "x": "dowr a",
    "y": " b. dore ra"
  },
  {
    "x": "dowr-II (n)",
    "y": " b. dewr-IV (n)"
  },
  {
    "x": "dowrêş (n)",
    "y": " b. dewrêş (n)"
  },
  {
    "x": "dowrêşek (n)",
    "y": " b. dewrêşek (n)"
  },
  {
    "x": "dowrêşey (m)",
    "y": " b. dewrêşîye (m)"
  },
  {
    "x": "dowrover (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "dowsnayene",
    "y": " b. dewisnayene"
  },
  {
    "x": "dowsnayîne",
    "y": " b. dewisnayene"
  },
  {
    "x": "dowsnayîş (n)",
    "y": " b. dewisnayîş (n)"
  },
  {
    "x": "dowul (m)",
    "y": " b. dawul (n)"
  },
  {
    "x": "dowûr, -e",
    "y": " b. dewar, -e"
  },
  {
    "x": "dox (m)",
    "y": " b. dewqe (m)"
  },
  {
    "x": "doxa ... şîyayîne",
    "y": " b. dewqa ... şîyene"
  },
  {
    "x": "doxan, -e",
    "y": " doğan"
  },
  {
    "x": "doxe (m)",
    "y": " çizgi (bazı oyunlarda başlangıç veya sınır çizgisi)\r\n~Doxe biance! (Çizgi(yi) çek!)"
  },
  {
    "x": "doxine (m)",
    "y": " b. doxîne (m) "
  },
  {
    "x": "doxiz (n)",
    "y": " b. donxuz (n), xoz (n)"
  },
  {
    "x": "doxîne (m)",
    "y": " uçkur"
  },
  {
    "x": "doxşîyayîş (n)",
    "y": " b. dewqeşîyayîş (n)"
  },
  {
    "x": "doxtor (n)",
    "y": " b. doktor, -e"
  },
  {
    "x": "doxuz (n)",
    "y": " b. donxuz, -e; xoz, -e"
  },
  {
    "x": "doyêm (n)",
    "y": " b. ohêm (n)"
  },
  {
    "x": "doyij, -i",
    "y": " b. dewij, -e"
  },
  {
    "x": "doyine (m)",
    "y": " ayran çorbası"
  },
  {
    "x": "doyîm (n)",
    "y": " b. ohêm (n)"
  },
  {
    "x": "doyîne (m)",
    "y": " b. doyine (m)"
  },
  {
    "x": "doym n)",
    "y": " b. ohêm (n)"
  },
  {
    "x": "doz kerdene",
    "y": " bulmak"
  },
  {
    "x": "doze (n)",
    "y": " 1)cehennem\r\n~2)(mecazi) büyük kötülük"
  },
  {
    "x": "dozere (m)",
    "y": " buldozer"
  },
  {
    "x": "dozeri (m)",
    "y": " b. dozere (m)"
  },
  {
    "x": "dozger, -e",
    "y": " savcı, cumhuriyet savcısı"
  },
  {
    "x": "dozgerîye (m)",
    "y": " savcılık"
  },
  {
    "x": "dozkerdiş (n)",
    "y": " bulma"
  },
  {
    "x": "dram (n)",
    "y": " dram"
  },
  {
    "x": "drama (m)",
    "y": " drama "
  },
  {
    "x": "dramatîk, -e",
    "y": " dramatik "
  },
  {
    "x": "dramatîze kerdene",
    "y": " dramatize etmek "
  },
  {
    "x": "drayver (n) (TE)",
    "y": " sürücü"
  },
  {
    "x": "drenaj (n)",
    "y": " akaçlama, drenaj"
  },
  {
    "x": "drene kerdene",
    "y": " drene etmek"
  },
  {
    "x": "du (n)",
    "y": " b.  dû-I (n)"
  },
  {
    "x": "dua (m)",
    "y": " dua"
  },
  {
    "x": "dual (m)",
    "y": " b. dole (m)"
  },
  {
    "x": "dualçe (n)",
    "y": " b. dewlçe (n)"
  },
  {
    "x": "dualma (m)",
    "y": " b. delame (n)"
  },
  {
    "x": "duar-mal (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "duar-male (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "duar-mali (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "dubitîn (n)",
    "y": " b. dîvîtîn (n)"
  },
  {
    "x": "dublaj (n, sn)",
    "y": " sözlendirme, dublaj "
  },
  {
    "x": "dublajker, -e (sn)",
    "y": " sözlendirici, dublajcı"
  },
  {
    "x": "dubleks (n)",
    "y": " dubleks "
  },
  {
    "x": "Dublîn (n)",
    "y": " Dublin"
  },
  {
    "x": "dublor, -e (sn)",
    "y": " dublör"
  },
  {
    "x": "dubre (n)",
    "y": " güneş almayan yamaç"
  },
  {
    "x": "dubri (n)",
    "y": " b. dubre (n)"
  },
  {
    "x": "duce (n)",
    "y": " b. jûje (n), dije (n)"
  },
  {
    "x": "duçik (n)",
    "y": " b. doçik (n)"
  },
  {
    "x": "dudu",
    "y": " b. di"
  },
  {
    "x": "due (n)",
    "y": " do (n)"
  },
  {
    "x": "duwo hest (n)",
    "y": " b. doyo hest (n)"
  },
  {
    "x": "duede (n)",
    "y": " b. dûdû (n)"
  },
  {
    "x": "duehn (n)",
    "y": " b. duhn (n)"
  },
  {
    "x": "duelavê solûn (m)",
    "y": " b. dolaba solan (m)"
  },
  {
    "x": "duelax (m)",
    "y": " b. dolaxe (m)"
  },
  {
    "x": "duenim (m)",
    "y": " b. donime (m)"
  },
  {
    "x": "duenimi (m)",
    "y": " b. donime (m)"
  },
  {
    "x": "duenimna",
    "y": " b. donimna"
  },
  {
    "x": "duer (m)",
    "y": " b. dore (m)"
  },
  {
    "x": "duer-mal (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "duer-male (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "duer-mali (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "duer-mar (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "duer-mare (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "dueri-I (m)",
    "y": " b. dore (m)"
  },
  {
    "x": "dueri (n)",
    "y": " b. dore-IV (n)"
  },
  {
    "x": "duerim",
    "y": " b. donime (m)"
  },
  {
    "x": "duerimna",
    "y": " b. donimna"
  },
  {
    "x": "duest",
    "y": " b. dost, -e"
  },
  {
    "x": "duestê (m)",
    "y": " b. dostîye (m)"
  },
  {
    "x": "dueş (n)",
    "y": " b. doşe (m)"
  },
  {
    "x": "dueşeg (n)",
    "y": " b. doşek (n)"
  },
  {
    "x": "dueşek (n)",
    "y": " b. doşek (n)"
  },
  {
    "x": "duetiş (n)",
    "y": " b. ditiş (n)"
  },
  {
    "x": "dugele (m)",
    "y": " b. duwel (n)"
  },
  {
    "x": "duhn (n)",
    "y": " içyağı"
  },
  {
    "x": "duin (n)",
    "y": " b. duman (n)"
  },
  {
    "x": "duizdabûn (n)",
    "y": " b. dûzdaban"
  },
  {
    "x": "duîr (s)",
    "y": " b. dûrî (s)"
  },
  {
    "x": "duj",
    "y": " b. tuj, -e"
  },
  {
    "x": "duj bîyayene",
    "y": " b. tuj bîyene"
  },
  {
    "x": "duj kerdene",
    "y": " b. tuj kerdene"
  },
  {
    "x": "dujbîyayîş (n)",
    "y": " b. tujbîyayîş (n)"
  },
  {
    "x": "dujkerdiş (n)",
    "y": " b. tujkerdiş (n)"
  },
  {
    "x": "duka (n)",
    "y": " b. dikan (n)"
  },
  {
    "x": "dukan (n)",
    "y": " b. dikan (n)"
  },
  {
    "x": "dukancî, -ye",
    "y": " b. dikandar, -e"
  },
  {
    "x": "dukandar, -i",
    "y": " b. dikandar, -e"
  },
  {
    "x": "dukûn (n)",
    "y": " b. dikan (n)"
  },
  {
    "x": "dulav (m)",
    "y": " b. dolabe (m)"
  },
  {
    "x": "dulavê kincûn (m)",
    "y": " b. dolaba kincan (m)"
  },
  {
    "x": "dulbende (m)",
    "y": " b. dolbende (m)"
  },
  {
    "x": "dulger (m)",
    "y": " b. duwel (n)"
  },
  {
    "x": "dulik (n)",
    "y": " teneke maşrapa"
  },
  {
    "x": "duma-I",
    "y": " b. dima"
  },
  {
    "x": "duma-II (n)",
    "y": " b. duman (n)"
  },
  {
    "x": "duman (n)",
    "y": " duman"
  },
  {
    "x": "dumanê lojine (n)",
    "y": " baca dumanı"
  },
  {
    "x": "dumanê pixêrî (n)",
    "y": " baca dumanı"
  },
  {
    "x": "dumano sîya (n)",
    "y": " siyah duman"
  },
  {
    "x": "dumanpeym (n)",
    "y": " dumanölçer"
  },
  {
    "x": "dumax (n)",
    "y": " b. dûxan (n)"
  },
  {
    "x": "dumen (n)",
    "y": " dümen"
  },
  {
    "x": "dumenger, -e",
    "y": " dümenci"
  },
  {
    "x": "dumilbaz, -e",
    "y": " b. dawulbaz, -e"
  },
  {
    "x": "dumo (n)",
    "y": " b. duman (n)"
  },
  {
    "x": "dumon (n)",
    "y": " b. duman (n)"
  },
  {
    "x": "dumû (n)",
    "y": " b. duman (n)"
  },
  {
    "x": "dumûn (n)",
    "y": " b. duman (n)"
  },
  {
    "x": "dun (n)",
    "y": " b. duman (n)"
  },
  {
    "x": "duna (m)",
    "y": " b. dinya (m)"
  },
  {
    "x": "dunik (m)",
    "y": " b. dunike (m)"
  },
  {
    "x": "dunike (m)",
    "y": " kiler"
  },
  {
    "x": "dunimi (m)",
    "y": " b. donime (m)"
  },
  {
    "x": "dunimna",
    "y": " b. donimna"
  },
  {
    "x": "dunya (m)",
    "y": " b. dinya (m)"
  },
  {
    "x": "dunya (n)",
    "y": " b. dinya (m)"
  },
  {
    "x": "dunyanedik, -e",
    "y": " b. dinyanedî, -ye"
  },
  {
    "x": "dupî (n)",
    "y": " b. dîyerpue (n)"
  },
  {
    "x": "dur (s)",
    "y": " b. dûrî (s)"
  },
  {
    "x": "duralet (n)",
    "y": " duralet"
  },
  {
    "x": "dure kerdene",
    "y": " b. durî kerdene"
  },
  {
    "x": "durgel (n)",
    "y": " b. duwel (n)"
  },
  {
    "x": "durgele (m)",
    "y": " b. duwel (n)"
  },
  {
    "x": "durî (s)",
    "y": " b. dûrî (s)"
  },
  {
    "x": "dûrî kewtene",
    "y": " b. dûrî kewtene"
  },
  {
    "x": "durî kerdene",
    "y": " başkasına ait hayvanlarla karıştırılmaması için hayvanın bir yerini kırkmak"
  },
  {
    "x": "durîkewtiş (n)",
    "y": " b. dûrîkewtiş (n)"
  },
  {
    "x": "durpî (n)",
    "y": " b. dîyerpue (n)"
  },
  {
    "x": "duru kerdene",
    "y": " b. durî kerdene"
  },
  {
    "x": "durum (m)",
    "y": " b. donime (m)"
  },
  {
    "x": "durumna",
    "y": " b. donimna"
  },
  {
    "x": "dus-I (n)",
    "y": " b. duşt (n)"
  },
  {
    "x": "dus-II (n)",
    "y": " b. cins (n)"
  },
  {
    "x": "dusayene",
    "y": " yapışmak"
  },
  {
    "x": "dusayîş (n)",
    "y": " yapışma"
  },
  {
    "x": "dusîyayene",
    "y": " b. dusayene"
  },
  {
    "x": "dusîyayîş (n)",
    "y": " b. dusayîş"
  },
  {
    "x": "dusmîs bîyene",
    "y": " b. duşunmîş bîyene"
  },
  {
    "x": "doze (n)",
    "y": " cehennem"
  },
  {
    "x": "duskin, -e (elewîyîye de)",
    "y": " b. duşkin, -e (elewîyîye de)"
  },
  {
    "x": "duskin bîyene (elewîyîye de)",
    "y": " b. duşkin bîyene (elewîyîye de)"
  },
  {
    "x": "duskin kerdene (elewîyîye de)",
    "y": " b. duşkin kerdene  (elewîyîye de)"
  },
  {
    "x": "duskinîlankerdis (n) (elewîyîye de)",
    "y": " duşkinîlankerdiş (elewîyîye de)"
  },
  {
    "x": "duskinîye (m)",
    "y": " b. Duşkinîye"
  },
  {
    "x": "duskînayene",
    "y": " yapıştırmak"
  },
  {
    "x": "duskînayîş (n)",
    "y": " yapıştırma"
  },
  {
    "x": "duskîyayene",
    "y": " yapışmak"
  },
  {
    "x": "duskîyayîş (n)",
    "y": " yapışma"
  },
  {
    "x": "dusmale (m)",
    "y": " b. destmale (m)"
  },
  {
    "x": "dusmali (m)",
    "y": " b. destmale (m)"
  },
  {
    "x": "dusnayene",
    "y": " yapıştırmak\r\n~Padusne! (Yapıştır!)"
  },
  {
    "x": "dusnayîş (n)",
    "y": " yapıştırma"
  },
  {
    "x": "dust (n)",
    "y": " b. duşt (n)"
  },
  {
    "x": "duş-I (n)",
    "y": " 1)karşı, aleyh\r\n~2)b. duşt"
  },
  {
    "x": "duş de",
    "y": " aleyhinde, aleyhine"
  },
  {
    "x": "duş de bîyene",
    "y": " aleyhinde olmak"
  },
  {
    "x": "duş te",
    "y": " b. duş de"
  },
  {
    "x": "duşê ... de",
    "y": " karşısında"
  },
  {
    "x": "duşê ... de qalî kerdene",
    "y": " aleyhinde konuşmak"
  },
  {
    "x": "duşê ... de qisey kerdene",
    "y": " aleyhinde konuşmak"
  },
  {
    "x": "duş-II (n)",
    "y": " duş"
  },
  {
    "x": "Duşenbe (n)",
    "y": " Duşanbe"
  },
  {
    "x": "duşkin (elewîyîye de), -e",
    "y": " aforozlu, düşkün (Alevilikte)\r\n~Hesen duşkin o. (Hasan düşkündür.)"
  },
  {
    "x": "duşkin bîyene (elewîyîye de)",
    "y": " düşkün olmak (Alevilikte)\r\n~Hesen duşkin bîyo. (Hasan düşkün olmuş.)"
  },
  {
    "x": "duşkin îlankerdene (elewîyîye de)",
    "y": " aforoz etmek"
  },
  {
    "x": "duşkin kerdene  (elewîyîye de)",
    "y": " düşkün ilan etmek (Alevilikte)\r\n~Pîrî Hesen duşkin kerdo. (Pir, Hasan’ı düşkün ilan etmiş.)"
  },
  {
    "x": "duşkinîlankerdiş (n) (elewîyîye de)",
    "y": " aforoz etme"
  },
  {
    "x": "duşkinîye (m) (elewîyîye de)",
    "y": " aforoz"
  },
  {
    "x": "duşmen, -i",
    "y": " b. dişmen, -e"
  },
  {
    "x": "duşmenatî (m)",
    "y": " b. dişmenî (m)"
  },
  {
    "x": "duşmeney (m)",
    "y": " b. dişmenîye (m)"
  },
  {
    "x": "duşmenî (m)",
    "y": " b. dişmenî (m)"
  },
  {
    "x": "duşmin, -e",
    "y": " b. dişmen, -e"
  },
  {
    "x": "duşmîş bîyene",
    "y": " b. duşunmîş bîyene"
  },
  {
    "x": "duşmîşbeyîş (n)",
    "y": " b. duşunmîşbîyayîş (n)"
  },
  {
    "x": "duşmîşbîyayîş (n)",
    "y": " b. duşunmîşbîyayîş (n)"
  },
  {
    "x": "duşmîşeyîş (n)",
    "y": " b. duşunmîşbîyayîş (n)"
  },
  {
    "x": "duşmîşîyayîş (n)",
    "y": " b. duşunmîşbîyayîş (n)"
  },
  {
    "x": "duşmuş bîyene",
    "y": " b. duşunmîş bîyene"
  },
  {
    "x": "duşmuşbîyayîş (n)",
    "y": " b. duşunmîşbîyayîş (n)"
  },
  {
    "x": "duşt (n)",
    "y": " 1)hiza\r\n~duştê ... ra:  1-hizasında 2-düzeyinde\r\n~2)karşı\r\n~duştê ... de: karşısında\r\n~3)düzey, seviye\r\n~Hesen duştê Cemşîdî ra yo. (Hasan Cemşid’in düzeyindedir.)\r\n~4)akran, böğür, taydaş, öğür\r\n~Zerweşe duştê xo ra zewicîyaya. (Zerweşe akranıyla evlenmiş.)"
  },
  {
    "x": "duştê ... ra",
    "y": " 1-hizasında\r\n~2-düzeyinde"
  },
  {
    "x": "duştê ... de",
    "y": " karşısında"
  },
  {
    "x": "duşunmîş bîyene",
    "y": " düşünmek"
  },
  {
    "x": "duşunmîşbîyayîş (n)",
    "y": " düşünme"
  },
  {
    "x": "duşurme (n)",
    "y": " b. doşirme (n)"
  },
  {
    "x": "dutine",
    "y": " b. ditene"
  },
  {
    "x": "dutiş (n)",
    "y": " b. ditiş (n)"
  },
  {
    "x": "duwa (m)",
    "y": " b. dua (m)"
  },
  {
    "x": "duwel (n)",
    "y": " yabancı devlet"
  },
  {
    "x": "duwenimi (m)",
    "y": " b. donime (m)"
  },
  {
    "x": "duwes",
    "y": " b. diwês"
  },
  {
    "x": "duwesnayene",
    "y": " b. dewisnayene"
  },
  {
    "x": "duwesnayîş (n)",
    "y": " b. dewisnayîş (n)"
  },
  {
    "x": "duwexi (m)",
    "y": " b. doxe (m)"
  },
  {
    "x": "duwês",
    "y": " b. diwês"
  },
  {
    "x": "duwx (m)",
    "y": " b. dewqe (m)"
  },
  {
    "x": "duwxa ... şîyene",
    "y": " b. dewqa ... şîyene"
  },
  {
    "x": "duwxşîyayîş (n)",
    "y": " b. dewqeşîyayîş (n)"
  },
  {
    "x": "duy (n)",
    "y": " b. dû (n)"
  },
  {
    "x": "duya (m)",
    "y": " b. dua (m)"
  },
  {
    "x": "duyar (n)",
    "y": " b. dîwar (n)"
  },
  {
    "x": "duye (n)",
    "y": " is, kurum (baca vb. için)"
  },
  {
    "x": "duyel (n)",
    "y": " b. duwel (n)"
  },
  {
    "x": "duyes",
    "y": " b. diwês"
  },
  {
    "x": "duyesin, -e",
    "y": " b. diwêsin, -e"
  },
  {
    "x": "duyês",
    "y": " b. diwês"
  },
  {
    "x": "duyêsin, -e",
    "y": " b. diwêsin, -e"
  },
  {
    "x": "duyr (s)",
    "y": " b. dûrî (s)"
  },
  {
    "x": "duz, -e",
    "y": " düz"
  },
  {
    "x": "duz kerdene",
    "y": " düzeltmek, tesviye etmek"
  },
  {
    "x": "duzd, -i",
    "y": " b. dizd, -e"
  },
  {
    "x": "duzdey (m)",
    "y": " b. dizdî (m), dizdîye (m)"
  },
  {
    "x": "duzdey kerdene",
    "y": " b. dizdîye kerdene"
  },
  {
    "x": "duzdeykerdiş (n)",
    "y": " b. dizdîyekerdiş (n)"
  },
  {
    "x": "duzdê (m)",
    "y": " b. dizdî (m)"
  },
  {
    "x": "duzdî (m)",
    "y": " b. dizdî (m)"
  },
  {
    "x": "duzdî kerdene",
    "y": " b. dizdî kerdene"
  },
  {
    "x": "duzdîkerdiş (n)",
    "y": " b dizdîkerdiş (n)"
  },
  {
    "x": "duzey (m)",
    "y": " b. duzîye (m)"
  },
  {
    "x": "duzê (m)",
    "y": " b. duzîye (m)"
  },
  {
    "x": "duzgin, -i-I",
    "y": " b. duzgun, -e"
  },
  {
    "x": "duzgin-II (n)",
    "y": " b. serreşme (n)"
  },
  {
    "x": "duzgun, -e",
    "y": " besili, semiz"
  },
  {
    "x": "duzîye (m)",
    "y": " düzlük"
  },
  {
    "x": "duzkar, -e",
    "y": " 1)düzleştirici\r\n~2)tesviyeci"
  },
  {
    "x": "duzkarî (m)",
    "y": " tesviyecilik"
  },
  {
    "x": "duzkarîye (m)",
    "y": " tesviyecilik"
  },
  {
    "x": "duzkerdiş (n)",
    "y": " düzeltme, tesviye"
  },
  {
    "x": "duzlon (n)",
    "y": " düzlük"
  },
  {
    "x": "dû-I (n)",
    "y": " do-I (n)"
  },
  {
    "x": "dûyo hest (n)",
    "y": " b. doyo hest (n)"
  },
  {
    "x": "dû-II (n)",
    "y": " duman"
  },
  {
    "x": "dûyê lojine (n)",
    "y": " baca dumanı"
  },
  {
    "x": "dûyê pixêrî (n)",
    "y": " baca dumanı"
  },
  {
    "x": "dûyo sîya (n)",
    "y": " siyah duman"
  },
  {
    "x": "dûde (n)",
    "y": " b. dûdû (n)"
  },
  {
    "x": "dûdi (n)",
    "y": " b. dûdû (n)"
  },
  {
    "x": "dûdû (n)",
    "y": " ibibik kuşu, hüthüt"
  },
  {
    "x": "dûka (n)",
    "y": " b. dikan (n)"
  },
  {
    "x": "dûkan (n)",
    "y": " b. dikan (n)"
  },
  {
    "x": "dûkandar, -e",
    "y": " b. dikandar, -e"
  },
  {
    "x": "dûlimi (m)",
    "y": " b. donime (m)"
  },
  {
    "x": "dûlimna",
    "y": " b. donimna"
  },
  {
    "x": "dûm (m)",
    "y": " b. dame-II (m)"
  },
  {
    "x": "dûman (n)",
    "y": " b. duman (n) "
  },
  {
    "x": "dûmar-I (m)",
    "y": " b. damare (m)"
  },
  {
    "x": "dûmar-II (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "dûmare (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "dûmari-I (n)",
    "y": " b. dorûver (n)"
  },
  {
    "x": "dûmari-II (m)",
    "y": " b. damare (m)"
  },
  {
    "x": "dûmarrî (m)",
    "y": " b. damêrrî (m), dêmarrî (m)"
  },
  {
    "x": "dûme (n)",
    "y": " b. dame-I (n)"
  },
  {
    "x": "dûmi (m)",
    "y": " b. dame-II (m)"
  },
  {
    "x": "dûmirî (m)",
    "y": " b. damêrrî (m), dêmarrî (m)"
  },
  {
    "x": "dûmirrî (m)",
    "y": " b. damêrrî (m), dêmarrî (m)"
  },
  {
    "x": "dûmrî (m)",
    "y": " b. damêrrî (m), dêmarrî (m)"
  },
  {
    "x": "dûn (n)",
    "y": " b. duman (n)"
  },
  {
    "x": "dûnga (m)",
    "y": " b. danga (m)"
  },
  {
    "x": "dûnimi (m)",
    "y": " b. donime (m)"
  },
  {
    "x": "dûnimna",
    "y": " b. donimna"
  },
  {
    "x": "dûpeym (n)",
    "y": " dumanölçer"
  },
  {
    "x": "dûpişk (n)",
    "y": " b. dimpiştik (n) "
  },
  {
    "x": "dûr-I (s)",
    "y": " b. dûrî (s)"
  },
  {
    "x": "dûr-II (n)",
    "y": " b. dor (n)"
  },
  {
    "x": "dûri (n)",
    "y": " b. dore-IV (n)"
  },
  {
    "x": "dûrimi (m)",
    "y": " b. donime (m)"
  },
  {
    "x": "dûrimna",
    "y": " b. donimna"
  },
  {
    "x": "dûrî (s)",
    "y": " uzak"
  },
  {
    "x": "dûrî kewtene",
    "y": " uzaklaşmak, arayı açmak"
  },
  {
    "x": "dûrîkewtiş (n)",
    "y": " uzaklaşma"
  },
  {
    "x": "dûrnêvînayox, -e",
    "y": " basiretsiz, sağgörüsüz"
  },
  {
    "x": "dûrnêvînayoxîye (m)",
    "y": " basiretsizlik, sağgörüsüzlük"
  },
  {
    "x": "dûrpeym (n)",
    "y": " telemetre"
  },
  {
    "x": "dûrûni (m)",
    "y": " b. dorane (m)"
  },
  {
    "x": "dûrvînayoxîye (m)",
    "y": " sağgörülülük, uzak görüşlülük, basiretlilik"
  },
  {
    "x": "dûrvînayîş (n)",
    "y": " basiret, sağgörü"
  },
  {
    "x": "dûrvînayox, -e",
    "y": " basiretli, uzak görüşlü, sağgörülü"
  },
  {
    "x": "dûst",
    "y": " b. dost, -e"
  },
  {
    "x": "dûş (n)",
    "y": " b. duşt (n)"
  },
  {
    "x": "dûşn (n)",
    "y": " b. duş-I (n)"
  },
  {
    "x": "dûwa (m)",
    "y": " b. dua (m)"
  },
  {
    "x": "dûxan (n)",
    "y": " duman"
  },
  {
    "x": "dûy (n)",
    "y": " b.  dû (n)"
  },
  {
    "x": "dûye (n)",
    "y": " b. duye (n)"
  },
  {
    "x": "dûzdaban, -e",
    "y": " düztaban"
  },
  {
    "x": "dûzo (n)",
    "y": " halay (serçe parmakla tutuşarak oynanan)"
  },
  {
    "x": "DVD (m) (TE)",
    "y": " DVD"
  },
  {
    "x": "dyeza (m)",
    "y": " b. dêze-I (m)"
  },
  {
    "x": "dyezik (m)",
    "y": " b. dêzike (m)"
  },
  {
    "x": "EEE"
  },
  {
    "x": "e-I (zr)",
    "y": " 1)evet\r\n~2)olur (izin)"
  },
  {
    "x": "e kerdene",
    "y": " kabul etmek "
  },
  {
    "x": "e-II (z)",
    "y": " b. ez (z)"
  },
  {
    "x": "e çik",
    "y": " koç veya tekenin toslamasını sağlamak için söylenen bir söz"
  },
  {
    "x": "e-mail (n) (TE)",
    "y": " elektronik mektup"
  },
  {
    "x": "eb (m)",
    "y": " b. hebe-I (m)"
  },
  {
    "x": "eba-I (m)",
    "y": " aba"
  },
  {
    "x": "eba-II (m)",
    "y": " ebe"
  },
  {
    "x": "ebanî (n)",
    "y": " abani (bir tür beyaz kumaş)"
  },
  {
    "x": "ebd, -e",
    "y": " b. evd, -e"
  },
  {
    "x": "ebdal (n)",
    "y": " b. evdal (n)"
  },
  {
    "x": "ebdalîye (m)",
    "y": " b. evdalîye (m)"
  },
  {
    "x": "ebdey (m)",
    "y": " b. evdîye (m)"
  },
  {
    "x": "ebdî (m)",
    "y": " b. evdîye (m)"
  },
  {
    "x": "ebe",
    "y": " b. bi"
  },
  {
    "x": "ebe ... ra",
    "y": " b. bi ... ra"
  },
  {
    "x": "ebe ... ya",
    "y": " b. bi ... ya"
  },
  {
    "x": "ebedî, -ye",
    "y": " 1)ebedi, sonsuz; ölümsüz\r\n~2)müebbet, sonrasız"
  },
  {
    "x": "ebedîyey (m)",
    "y": " b. ebedîyîye (m)"
  },
  {
    "x": "ebedîyê (m)",
    "y": " b. ebedîyî (m)"
  },
  {
    "x": "ebedîyî (m)",
    "y": " sonrasızlık, sonsuzluk"
  },
  {
    "x": "ebedîyîye (m)",
    "y": " sonrasızlık, sonsuzluk"
  },
  {
    "x": "ebi",
    "y": " b. bi"
  },
  {
    "x": "ebi newbeti",
    "y": " b. bi nobete"
  },
  {
    "x": "ebi sira",
    "y": " b. bi sira"
  },
  {
    "x": "ebi ... ra",
    "y": " b. bi ... ra"
  },
  {
    "x": "ebi ... ya",
    "y": " b. bi ... ya"
  },
  {
    "x": "ebizor",
    "y": " b. bizor"
  },
  {
    "x": "ebîye (m)",
    "y": " b. eba (m)"
  },
  {
    "x": "ebr (m)",
    "y": " b. ebre (m)"
  },
  {
    "x": "ebra (m)",
    "y": " b. (hayvan için) awre (m)"
  },
  {
    "x": "ebra bîyayene",
    "y": " b. (hayvan için) awre bîyene"
  },
  {
    "x": "ebre-I (m)",
    "y": " kuş sürüsü"
  },
  {
    "x": "ebre-II (m)",
    "y": " tınaz"
  },
  {
    "x": "ebre-III (n)",
    "y": " dara, abra"
  },
  {
    "x": "ebri (m)",
    "y": " b. ebre (m)"
  },
  {
    "x": "ebur",
    "y": " b. ebre (m)"
  },
  {
    "x": "Ebû Dabî",
    "y": " Abu Dabi"
  },
  {
    "x": "Ec (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "ecava",
    "y": " b. eceba"
  },
  {
    "x": "ecayîb, -e",
    "y": " 1)acayip, garip, gariplik\r\n~2)baş döndürücü (mecazi)"
  },
  {
    "x": "ecayîb bîyene",
    "y": " acayipleşmek"
  },
  {
    "x": "ecayîb kerdene",
    "y": " acayipleştirmek"
  },
  {
    "x": "ecayîbane",
    "y": " acayip bir şekilde, acayip bir biçimde"
  },
  {
    "x": "ecayîbbîyayîş (n)",
    "y": " acayipleşme"
  },
  {
    "x": "ecayîbi (m)",
    "y": " b. ecayîb, -e"
  },
  {
    "x": "ecayîbîye (m)",
    "y": " acayiplik"
  },
  {
    "x": "ecayîbkerdiş (n)",
    "y": " acayipleştirme"
  },
  {
    "x": "ecayîbone",
    "y": " b. ecayîbane"
  },
  {
    "x": "ecayîbûne",
    "y": " b.ecayîbane"
  },
  {
    "x": "ecbinayîne",
    "y": " b. ecibnayene"
  },
  {
    "x": "ecbinayîş (n)",
    "y": " b. ecibnayîş (n)"
  },
  {
    "x": "ecdad (n)",
    "y": " ecdat "
  },
  {
    "x": "ecdîyad (n)",
    "y": " b. ecdad (n)"
  },
  {
    "x": "eceb",
    "y": " b. eceba"
  },
  {
    "x": "eceba",
    "y": " acaba"
  },
  {
    "x": "ecebnayene",
    "y": " b. ecibnayene"
  },
  {
    "x": "ecebnayîş (n)",
    "y": " b. ecibnayîş (n)"
  },
  {
    "x": "ecel (n)",
    "y": " ecel"
  },
  {
    "x": "ecelê ... ameyene",
    "y": " vakti gelmek (eceli gelmek), zamanı gelmek (eceli gelmek)"
  },
  {
    "x": "ecele (n)",
    "y": " acele"
  },
  {
    "x": "ecele ecele",
    "y": " acele acele"
  },
  {
    "x": "ecele kerdene",
    "y": " acele etmek"
  },
  {
    "x": "ecele ra kerdene",
    "y": " aceleye gelmek, aceleye getirmek"
  },
  {
    "x": "eceleker, -e",
    "y": " aceleci, telaşlı"
  },
  {
    "x": "ecelekerîye (m)",
    "y": " acelecilik, telaş"
  },
  {
    "x": "ecemî, -ye",
    "y": " 1)toy, acemi\r\n~2)çömez (mecazî)"
  },
  {
    "x": "ecemîtî (m)",
    "y": " toyluk, acemilik"
  },
  {
    "x": "ecemîyey (m)",
    "y": " b. ecemîyîye (m)"
  },
  {
    "x": "ecemîyê (m)",
    "y": " b. ecemîyîye (m)"
  },
  {
    "x": "ecemîyîye (m)",
    "y": " toyluk, acemilik"
  },
  {
    "x": "ecep",
    "y": " b. eceba"
  },
  {
    "x": "eceva",
    "y": " b. eceba"
  },
  {
    "x": "ecêb, -e",
    "y": " 1)acayip, garip, gariplik\r\n~2)baş döndürücü (mecazi)"
  },
  {
    "x": "ecêb bîyene",
    "y": " acayipleşmek"
  },
  {
    "x": "ecêb kerdene",
    "y": " acayipleştirmek"
  },
  {
    "x": "ecêb mendene",
    "y": " afallamak, şaşakalmak, ağzı açık kalmak (mecazi), ağzı bir karış açık kalmak (mecazi), bakakalmak"
  },
  {
    "x": "ecêba",
    "y": " b. eceba"
  },
  {
    "x": "ecêbbîyayîş (n)",
    "y": " acayipleşme"
  },
  {
    "x": "ecêbi (m)",
    "y": " b. ecêb, -e"
  },
  {
    "x": "ecêbkerdiş (n)",
    "y": " acayipleştirme"
  },
  {
    "x": "ecêbmendiş (n)",
    "y": " afallama, şaşakalma, ağzı açık kalma (mecazi), ağzı bir karış açık kalma (mecazi), bakakalma"
  },
  {
    "x": "ecêv, -e",
    "y": " b. ecêb, -e"
  },
  {
    "x": "eciba",
    "y": " b. eceba"
  },
  {
    "x": "ecibnayene",
    "y": " beğenmek"
  },
  {
    "x": "ecibnayîş (n)",
    "y": " beğenme"
  },
  {
    "x": "eciqîyayene",
    "y": " ezilmek"
  },
  {
    "x": "eciqnayene",
    "y": " ezmek"
  },
  {
    "x": "eciqnayîş (n)",
    "y": " ezme"
  },
  {
    "x": "eciz bîyayene",
    "y": " b. eciz bîyene"
  },
  {
    "x": "eciz bîyene",
    "y": " bezmek, bezginlik getirmek, bıkmak, bizar olmak, usanmak"
  },
  {
    "x": "eciz kerdene",
    "y": " bıktırmak, usandırmak"
  },
  {
    "x": "ecizbîyayîş (n)",
    "y": " bezme, bıkma, bizar olma, usanma"
  },
  {
    "x": "ecizey (m)",
    "y": " b. ecizîye (m)"
  },
  {
    "x": "ecizeyîne",
    "y": " b. ecizîyayene"
  },
  {
    "x": "ecizeyîş (n)",
    "y": " b. ecizîyayîş (n)"
  },
  {
    "x": "ecizê (m)",
    "y": " b. ecizîye (m)"
  },
  {
    "x": "ecizîyaya (m)",
    "y": " bıkkın"
  },
  {
    "x": "ecizîyaye (n)",
    "y": " bıkkın"
  },
  {
    "x": "ecizîyayene",
    "y": " 1)bezmek, bezginlik getirmek, bıkmak, bizar olmak, usanmak\r\n~2)yorulmak"
  },
  {
    "x": "ecizîyayî, -ye",
    "y": " bıkkın"
  },
  {
    "x": "ecizîyayîş (n)",
    "y": " 1)bezme, bıkma, bizar olma, usanma\r\n~2)yorulma"
  },
  {
    "x": "ecizîye (m)",
    "y": " bezginlik"
  },
  {
    "x": "ecizkerdiş (n)",
    "y": " bıktırma, usandırma"
  },
  {
    "x": "eciznayene",
    "y": " 1)bıktırmak, usandırmak\r\n~2)yormak"
  },
  {
    "x": "eciznayîş (n)",
    "y": " 1)bıktırma, usandırma\r\n~2)yorma"
  },
  {
    "x": "ecîyb",
    "y": " b. ecêb, -e"
  },
  {
    "x": "ecnebî, -ye",
    "y": " yabancı\r\n~No xort ecnebî yo. (Bu delikanlı yabancıdır.)"
  },
  {
    "x": "ecnebîya (m)",
    "y": " yabancı ülkeler"
  },
  {
    "x": "ecrêmîsîl (n) (huq.)",
    "y": " ecrimisil"
  },
  {
    "x": "ecur (n)",
    "y": " acur"
  },
  {
    "x": "ecuri (m)",
    "y": " b. ecur (n)"
  },
  {
    "x": "eç (m)",
    "y": " b. eçe (m)"
  },
  {
    "x": "eç kerdene",
    "y": " b. eçe kerdene"
  },
  {
    "x": "eçe (m)",
    "y": " 1)(çocuk dilinde) vurma, dövme\r\n~2)ebe (ebe oyunu)\r\n~3)körebe"
  },
  {
    "x": "eçe kerdene",
    "y": " (çocuk dilinde) vurmak, dövmek"
  },
  {
    "x": "eçekerdiş (n)",
    "y": " (çocuk dilinde) vurma, dövme"
  },
  {
    "x": "eçi (m)",
    "y": " b. eçe (m)"
  },
  {
    "x": "eçi kerdene",
    "y": " b. eçe kerdene"
  },
  {
    "x": "eçikerdiş (n)",
    "y": " b. eçekerdiş (n)"
  },
  {
    "x": "eçkerdiş (n)",
    "y": " b. eçekerdiş (n)"
  },
  {
    "x": "eçî (m)",
    "y": " b. eçe (m)"
  },
  {
    "x": "eçî kerdene",
    "y": " b. eçe kerdene"
  },
  {
    "x": "eçîkerdiş (n)",
    "y": " b. eçekerdiş (n)"
  },
  {
    "x": "eçkerdiş (n)",
    "y": " b. eçekerdiş (n)"
  },
  {
    "x": "edal (n)",
    "y": " b. atile (n)"
  },
  {
    "x": "edal ci dayene",
    "y": " b. atile dayene"
  },
  {
    "x": "edale (m)",
    "y": " gelincik"
  },
  {
    "x": "edalet (n)",
    "y": " adalet"
  },
  {
    "x": "edaleyî (zh)",
    "y": " adale(ler), kas(lar)"
  },
  {
    "x": "edali-I (m)",
    "y": " b. adare (m)"
  },
  {
    "x": "edali-II (m)",
    "y": " b. edale (m)"
  },
  {
    "x": "edalî (m)",
    "y": " b. atile (n)"
  },
  {
    "x": "edar (m)",
    "y": " b. adare (m)"
  },
  {
    "x": "edare (m)",
    "y": " b. adare (m)"
  },
  {
    "x": "edari (m)",
    "y": " b. adare (m)"
  },
  {
    "x": "edat (n)",
    "y": " edat, ilgeç"
  },
  {
    "x": "edatê negatîfîye (n)",
    "y": " olumsuzlama edatı "
  },
  {
    "x": "Ede (m)",
    "y": " inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "ede ... de",
    "y": " b. di ... de"
  },
  {
    "x": "edeb (n)",
    "y": " adabımuaşeret"
  },
  {
    "x": "edebî, -ye",
    "y": " edebi, yazınsal"
  },
  {
    "x": "meqaleya edebîye (m)",
    "y": " edebi makale"
  },
  {
    "x": "edebîyat (n)",
    "y": " edebiyat, yazın "
  },
  {
    "x": "edebîyatê aşiqan (n)",
    "y": " âşık edebiyatı "
  },
  {
    "x": "edebîyatê cîhanî (n)",
    "y": " dünya edebiyatı"
  },
  {
    "x": "edebîyatê dinya (n)",
    "y": " dünya edebiyatı"
  },
  {
    "x": "edebîyatê dîwanî (n)",
    "y": " divan edebiyatı"
  },
  {
    "x": "edebîyatê karkeran (n)",
    "y": " işçi edebiyatı"
  },
  {
    "x": "edebîyatê mîzehî (n)",
    "y": " mizah edebiyatı"
  },
  {
    "x": "edebîyatê şarî (n)",
    "y": " halk edebiyatı"
  },
  {
    "x": "edebîyato fekkî (n)",
    "y": " sözlü edebiyat"
  },
  {
    "x": "edebîyato klasîk (n)",
    "y": " klasik edebiyat"
  },
  {
    "x": "edebîyato milî (n)",
    "y": " milli edebiyat, ulusal edebiyat"
  },
  {
    "x": "edebîyato neteweyî (n)",
    "y": " milli edebiyat, ulusal edebiyat"
  },
  {
    "x": "edebîyato nuştekî (n)",
    "y": " yazılı edebiyat "
  },
  {
    "x": "edebîyatkar, -e",
    "y": " edebiyatçı, edip"
  },
  {
    "x": "edebîyatkarîye (m)",
    "y": " edebiyatçılık "
  },
  {
    "x": "edelet (n)",
    "y": " b. edalet (n)"
  },
  {
    "x": "edelîyayene",
    "y": " b. edilîyayene"
  },
  {
    "x": "edelîyayîş (n)",
    "y": " b. edilîyayîş (n)"
  },
  {
    "x": "edelnayene",
    "y": " b. edilnayene"
  },
  {
    "x": "edelnayîş (n)",
    "y": " b. edilnayîş (n)"
  },
  {
    "x": "edet (n)",
    "y": " b. adet (n)"
  },
  {
    "x": "edet ra tever",
    "y": " b. adetan ra teber"
  },
  {
    "x": "edet ra vete",
    "y": " b. adetan ra vete"
  },
  {
    "x": "edet ra veti",
    "y": " b. adetan ra vete"
  },
  {
    "x": "edetan ra teber",
    "y": " b. adetan ra teber"
  },
  {
    "x": "edetan ra veta",
    "y": " b. adetan ra veta"
  },
  {
    "x": "edetan ra vete",
    "y": " b. adetan ra vete"
  },
  {
    "x": "edetan ra vetî",
    "y": " b. adetan ra vetî"
  },
  {
    "x": "edetî, -ye",
    "y": " b. adetî, -ye"
  },
  {
    "x": "edev (n)",
    "y": " b. edeb (n)"
  },
  {
    "x": "edi ... de",
    "y": " b. di ... de"
  },
  {
    "x": "Edi (m)",
    "y": " b. Ede (m)"
  },
  {
    "x": "edilîyayene",
    "y": " durmak"
  },
  {
    "x": "edilîyayîş (n)",
    "y": " durma"
  },
  {
    "x": "edilnayene",
    "y": " 1)durdurmak\r\n~2)bekletmek\r\n~3)bastırmak (... önlemek)"
  },
  {
    "x": "edilnayîş (n)",
    "y": " 1)durdurma\r\n~2)bekletme\r\n~3)bastırma (önleme)"
  },
  {
    "x": "edizayene",
    "y": " b. ecizîyayene"
  },
  {
    "x": "edizayîş (n)",
    "y": " b. ecizîyayîş (n)"
  },
  {
    "x": "edizîyayene",
    "y": " b. ecizîyayene"
  },
  {
    "x": "edizîyayîş (n)",
    "y": " b. ecizîyayîş (n)"
  },
  {
    "x": "ediznayene",
    "y": " b. eciznayene"
  },
  {
    "x": "ediznayîş (n)",
    "y": " b. eciznayîş (n)"
  },
  {
    "x": "edizyayene",
    "y": " b. ecizîyayene"
  },
  {
    "x": "edizyayîş (n)",
    "y": " b. ecizîyayîş (n)"
  },
  {
    "x": "edîtor, -e",
    "y": " editör, naşir, yayımcı "
  },
  {
    "x": "edîtora vîdeoyî (n)",
    "y": " video editörü"
  },
  {
    "x": "edîtorê vîdeoyî (n)",
    "y": " video editörü"
  },
  {
    "x": "edîtorî (m)",
    "y": " editörlük, naşirlik, yayımcılık"
  },
  {
    "x": "edîtorîye (m)",
    "y": " editörlük, naşirlik, yayımcılık"
  },
  {
    "x": "edle-I (n)",
    "y": " b. elle (n)"
  },
  {
    "x": "edle-II (n)",
    "y": " b. arzêle-I (n)"
  },
  {
    "x": "edle-III (n)",
    "y": " b. herzale-I (n)"
  },
  {
    "x": "edleyîş (n)",
    "y": " b. edilîyayîş (n)"
  },
  {
    "x": "edlî",
    "y": " adli"
  },
  {
    "x": "psîkolojîyo edlî (n)",
    "y": " adli psikoloji "
  },
  {
    "x": "edres (n)",
    "y": " b. adrese (m)"
  },
  {
    "x": "edresa (m)",
    "y": " b. adrese (m)"
  },
  {
    "x": "edrese (n)",
    "y": " b. adrese (m)"
  },
  {
    "x": "edrêse (m)",
    "y": " b. adrese (m)"
  },
  {
    "x": "edzîyayîş (n)",
    "y": " b. ecizîyayîş (n)"
  },
  {
    "x": "ee çik",
    "y": " b. e çik"
  },
  {
    "x": "ef (n)",
    "y": " af"
  },
  {
    "x": "ef kerdene",
    "y": " affetmek, bağışlamak, bahşetmek"
  },
  {
    "x": "efê awanî (n)",
    "y": " imar affı"
  },
  {
    "x": "efo arizî (n)",
    "y": " özel af"
  },
  {
    "x": "efo hemeyî (n)",
    "y": " genel af"
  },
  {
    "x": "efo pêroyî (n)",
    "y": " genel af"
  },
  {
    "x": "efo taybetî (n)",
    "y": " özel af"
  },
  {
    "x": "efo umûmî (n)",
    "y": " genel af"
  },
  {
    "x": "efo xususî (n)",
    "y": " özel af"
  },
  {
    "x": "efara (m)",
    "y": " 1)harman sonu (badas)\r\n~2) (mecazi) ailede son çocuk"
  },
  {
    "x": "efare (n)",
    "y": " taraş (üzüm için)"
  },
  {
    "x": "efare kerdene",
    "y": " taraşlamak (üzüm için)"
  },
  {
    "x": "efarekerdiş (n)",
    "y": " taraşlama (üzüm için)"
  },
  {
    "x": "efekt (n, sn)",
    "y": " efekt, seslendirme"
  },
  {
    "x": "efekto dîyayîşkî (sn)",
    "y": " görsel efekt"
  },
  {
    "x": "efendî (n)",
    "y": " centilmen"
  },
  {
    "x": "efendîtîye (m)",
    "y": " centilmenlik"
  },
  {
    "x": "efendîyane",
    "y": " centilmence"
  },
  {
    "x": "efendîyîye (m)",
    "y": " centilmenlik"
  },
  {
    "x": "eferim",
    "y": " bravo"
  },
  {
    "x": "efirnayîne",
    "y": "  b. hefilnayene"
  },
  {
    "x": "efîyet",
    "y": " b. Afîyet, Efîyet bo. b. Afîyet bo."
  },
  {
    "x": "efkerdiş (n)",
    "y": " affetme, bağışlama, bahşetme"
  },
  {
    "x": "efr (n)",
    "y": " b. hefil (n)"
  },
  {
    "x": "efsane (m)",
    "y": " söylence, efsane, lejant"
  },
  {
    "x": "efsanewî, -ye",
    "y": " efsanevi"
  },
  {
    "x": "efser, -e",
    "y": " subay, zabit"
  },
  {
    "x": "efserê makîna",
    "y": " makina subayı, makina zabiti"
  },
  {
    "x": "efserî (m)",
    "y": " subaylık, zabitlik"
  },
  {
    "x": "efserîye (m)",
    "y": " subaylık, zabitlik"
  },
  {
    "x": "efsûn (n)",
    "y": " büyü, sihir, efsun, afsun"
  },
  {
    "x": "efsûn kerdene",
    "y": " büyü yapmak, büyülemek, efsunlamak, afsunlamak"
  },
  {
    "x": "efsûnkar, -e",
    "y": " 1)büyücü, sihirbaz, efsuncu, afsuncu, efsunkâr\r\n~2)büyüleyici\r\n~3)büyülü"
  },
  {
    "x": "efsûnkarî (m)",
    "y": " büyücülük, efsunculuk, afsunculuk"
  },
  {
    "x": "efsûnkerdiş (n)",
    "y": " büyüleme, efsunlama, afsunlama"
  },
  {
    "x": "eftarayîne",
    "y": " b. wetardene"
  },
  {
    "x": "eftarayîş (n)",
    "y": " b. wetardiş (n)"
  },
  {
    "x": "eftayîne",
    "y": " b. ewtanayene"
  },
  {
    "x": "eftayîş (n)",
    "y": " b. ewtanayîş (n)"
  },
  {
    "x": "efterayîne",
    "y": " b. wetardene"
  },
  {
    "x": "efterayîş (n)",
    "y": " b. wetardiş (n)"
  },
  {
    "x": "eftonayîş (n)",
    "y": " b. ewtanayîş (n)"
  },
  {
    "x": "efû (n)",
    "y": " b. ef (n)"
  },
  {
    "x": "efxan, -e",
    "y": " Afgan, Afganlı"
  },
  {
    "x": "Efxanîstan (n)",
    "y": " Afganistan "
  },
  {
    "x": "eg",
    "y": " b. eke-I"
  },
  {
    "x": "ega",
    "y": " b. do"
  },
  {
    "x": "egal (m)",
    "y": " b. egale (m)"
  },
  {
    "x": "egale (m)",
    "y": " agel, poşu, poçu"
  },
  {
    "x": "egali (m)",
    "y": " b. egale (m)"
  },
  {
    "x": "ege",
    "y": " b. eke"
  },
  {
    "x": "eger",
    "y": " eğer, şayet"
  },
  {
    "x": "egerse",
    "y": " b. eke"
  },
  {
    "x": "egersew",
    "y": " b. eke"
  },
  {
    "x": "egersow",
    "y": " b. eke"
  },
  {
    "x": "egi",
    "y": " b. eke"
  },
  {
    "x": "egîd (n)",
    "y": " b. egît, -e "
  },
  {
    "x": "egîdê (m)",
    "y": " b. egîtî (m)"
  },
  {
    "x": "egîdî (m)",
    "y": " b. egîtî (m)"
  },
  {
    "x": "egît, -e",
    "y": " yiğit, alp, kahraman, acar (gözü pek)"
  },
  {
    "x": "egîtênî (m)",
    "y": " yiğitlik, alplık, kahramanlık; gözü peklik"
  },
  {
    "x": "egîtî (m)",
    "y": " yiğitlik, alplık, kahramanlık; gözü peklik"
  },
  {
    "x": "egîtîye (m)",
    "y": " yiğitlik, alplık, kahramanlık; gözü peklik"
  },
  {
    "x": "egle kerdene",
    "y": " bekletmek"
  },
  {
    "x": "eglekerdiş (n)",
    "y": " bekletme"
  },
  {
    "x": "egmîn (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "egoîst, -e",
    "y": " bencil, egoist"
  },
  {
    "x": "egoîst bîyene",
    "y": " bencil olmak, egoist olmak"
  },
  {
    "x": "egoîstî (m)",
    "y": " bencillik, egoistlik"
  },
  {
    "x": "egoîstîye (m)",
    "y": " bencillik, egoistlik"
  },
  {
    "x": "egosantrîst, -e",
    "y": " beniçinci, benmerkezci, egosantrist"
  },
  {
    "x": "egosantrîzm (n)",
    "y": " beniçincilik, benmerkezcilik, egosantrizm"
  },
  {
    "x": "egzama (m)",
    "y": " egzama, mayasıl"
  },
  {
    "x": "egzersîz (n)",
    "y": " alıştırma, egzersiz, temrin"
  },
  {
    "x": "ehbab, -e",
    "y": " ahbap, can dostu, can arkadaşı, can ciğer"
  },
  {
    "x": "ehbab bîyene",
    "y": " can ciğer  olmak"
  },
  {
    "x": "ehbabîye (m)",
    "y": " ahbaplık"
  },
  {
    "x": "ehd (n)",
    "y": " ahit"
  },
  {
    "x": "ehd kerdene",
    "y": " ahdetmek"
  },
  {
    "x": "ehdkerdiş (n)",
    "y": " ahdetme"
  },
  {
    "x": "ehe",
    "y": " gelmesi için keçiye seslenirken kullanılan sözcük"
  },
  {
    "x": "ehe ehe",
    "y": " gelmesi için keçiye seslenirken kullanılan sözcükler"
  },
  {
    "x": "ehemîyet (n)",
    "y": " önem"
  },
  {
    "x": "ehemîyet dayene",
    "y": " önem vermek"
  },
  {
    "x": "ehemîyetê cigêrayîşî (n)",
    "y": " araştırmanın önemi"
  },
  {
    "x": "ehemîyetdar, -e",
    "y": " önemli"
  },
  {
    "x": "ehend",
    "y": " b. hende"
  },
  {
    "x": "ehende",
    "y": " b. hende"
  },
  {
    "x": "ehendêy",
    "y": " b. hende"
  },
  {
    "x": "ehendi",
    "y": " b. hende"
  },
  {
    "x": "ehind",
    "y": " b. hende"
  },
  {
    "x": "ehlaq (n)",
    "y": " b. exlaq (n)"
  },
  {
    "x": "ehlaq ra teber",
    "y": " b. exlaqî ra teber"
  },
  {
    "x": "ehlaqdar, -e",
    "y": " b. exlaqdar, -e"
  },
  {
    "x": "ehlaqen",
    "y": " b. exlaqen"
  },
  {
    "x": "ehlaqî, -ye",
    "y": " b. exlaqî, -ye"
  },
  {
    "x": "ehlê sunetî",
    "y": " ehlisünnet, sünnet ehli"
  },
  {
    "x": "ehlê sunnet",
    "y": " b. ehlê sunetî"
  },
  {
    "x": "ehlî sunet",
    "y": " b. ehlê sunetî"
  },
  {
    "x": "ehlî, -ye",
    "y": " yerli"
  },
  {
    "x": "ehlîyet (n)",
    "y": " ehliyet, ehliyetname"
  },
  {
    "x": "ehlîyetê cezawerdişî (n)",
    "y": " cezai ehliyet"
  },
  {
    "x": "ehlîyetê wasitaya girane (n)",
    "y": " ağır vasıta ehliyeti"
  },
  {
    "x": "ehlîyetname (n)",
    "y": " sürücü belgesi, ehliyetname"
  },
  {
    "x": "ehmaq, -e",
    "y": " b. ehmeq, -e"
  },
  {
    "x": "ehmaqey (m)",
    "y": " b. ehmeqî (m)"
  },
  {
    "x": "ehmaqênî (m)",
    "y": " ahmaklık, aptallık, avanaklık, salaklık"
  },
  {
    "x": "ehmaqî (m)",
    "y": " b. ehmeqî (m)"
  },
  {
    "x": "ehmaqîye (m)",
    "y": " b. ehmeqîye (m)"
  },
  {
    "x": "ehmeq, -e",
    "y": " ahmak, aptal, avanak, salak"
  },
  {
    "x": "ehmeq bîyene",
    "y": " ahmaklaşmak"
  },
  {
    "x": "ehmeq kerdene",
    "y": " ahmaklaştırmak"
  },
  {
    "x": "ehmeqane",
    "y": " ahmakça, aptalca"
  },
  {
    "x": "ehmeqbîyayîş (n)",
    "y": " ahmaklaşma, aptallaşma"
  },
  {
    "x": "ehmeqey (m)",
    "y": " b. ehmeqîye (m)"
  },
  {
    "x": "ehmeqî (m)",
    "y": " ahmaklık, aptallık, avanaklık, salaklık"
  },
  {
    "x": "ehmeqîye (m)",
    "y": " ahmaklık, aptallık, avanaklık, salaklık "
  },
  {
    "x": "ehmeqkerdiş (n)",
    "y": " ahmaklaştırma, aptallaştırma"
  },
  {
    "x": "eî (n, z)",
    "y": " b. ey-I (n, z)"
  },
  {
    "x": "Ej (n)",
    "y": " b. Ec (n)"
  },
  {
    "x": "ej (z)",
    "y": " b. ez (z)"
  },
  {
    "x": "ejdad (n)",
    "y": " ecdad (n)"
  },
  {
    "x": "ejdayîr (n)",
    "y": " b. ejderha, -ye"
  },
  {
    "x": "ejdeha (n)",
    "y": " ejderha"
  },
  {
    "x": "ejderha, -ye",
    "y": " ejderha"
  },
  {
    "x": "ejdirha (n)",
    "y": " b. ejderha, -ye"
  },
  {
    "x": "ejdîha (n)",
    "y": " b. ejdeha (n)"
  },
  {
    "x": "ejdîya (n)",
    "y": " b. ejdeha (n)"
  },
  {
    "x": "ejenda (m)",
    "y": " b. ajanda (m)"
  },
  {
    "x": "ejkiji (m)",
    "y": " b. eskije (m)"
  },
  {
    "x": "ejnebî, -ye",
    "y": " b. ecnebî, -ye"
  },
  {
    "x": "ek-I",
    "y": " b. eke-I"
  },
  {
    "x": "ek-II (m)",
    "y": " b. eke-II (m)"
  },
  {
    "x": "ek-III (n)",
    "y": " b. hak (n)"
  },
  {
    "x": "eka-I",
    "y": " b. nika"
  },
  {
    "x": "eka-II",
    "y": " b. eke-I"
  },
  {
    "x": "eke-I",
    "y": " eğer, şayet \r\n~Eke ti zanî vaje eke ti nêzanî biperse. (Şayet biliyorsan söyle, bilmiyorsan sor.)\r\n~\t\r\n~Eke ti nêzanî ti çirê nusenî, ti mecbur î ke binusî? (Bilmiyorsan niçin yazıyorsun, yazmak zorunda mısın?)"
  },
  {
    "x": "eke zehmet nêbo",
    "y": " zahmet olmazsa"
  },
  {
    "x": "eke-II (m)",
    "y": " bok, kaka (çocuk dilinde)"
  },
  {
    "x": "ekê",
    "y": " b. eke-I"
  },
  {
    "x": "eki-I",
    "y": " b. eke-I"
  },
  {
    "x": "eki-II (m)",
    "y": " b. eke-II (m)"
  },
  {
    "x": "ekil (n)",
    "y": " tat\r\n~Ekilê germî weş o la madeyê mi çin o. (Çorbanın tadı güzel ama iştahım yok.)"
  },
  {
    "x": "ekin (n)",
    "y": " ekin"
  },
  {
    "x": "ekis (n)",
    "y": " b. eks (n"
  },
  {
    "x": "ekis kerdene",
    "y": " b. eks kerdene"
  },
  {
    "x": "ekisew",
    "y": " b. eke-I"
  },
  {
    "x": "ekisow",
    "y": " b. eke"
  },
  {
    "x": "ekîpe (m)",
    "y": " ekip, takım"
  },
  {
    "x": "ekîpa îtfaîya (m)",
    "y": " itfaiye ekibi"
  },
  {
    "x": "ekîpa rewşa lezabezî (m)",
    "y": " acil durum ekibi"
  },
  {
    "x": "ekîpa rewşa lezabezîye (m)",
    "y": " acil durum ekibi"
  },
  {
    "x": "ekîpman (n)",
    "y": " ekipman"
  },
  {
    "x": "ekîpmano mekanîk (n)",
    "y": " mekanik ekipman"
  },
  {
    "x": "ekl (n)",
    "y": " b. ekil (n)"
  },
  {
    "x": "eklektîk (n)",
    "y": " eklektik"
  },
  {
    "x": "eko",
    "y": " b. ko-II"
  },
  {
    "x": "ekolojî (n)",
    "y": " çevrebilim, ekoloji"
  },
  {
    "x": "ekolojîk, -e",
    "y": " çevrebilimsel, ekolojik"
  },
  {
    "x": "ekolojîst, -e",
    "y": " çevrebilimci, ekolojist"
  },
  {
    "x": "ekonometrî (n)",
    "y": " ekonometri, iktisadi ölçüm"
  },
  {
    "x": "ekonomî (m)",
    "y": " ekonomi, iktisat"
  },
  {
    "x": "ekonomîk, -e",
    "y": " ekonomik, iktisadi"
  },
  {
    "x": "ekonomîst, -e",
    "y": " ekonomist, iktisatçı"
  },
  {
    "x": "ekose (n)",
    "y": " ekose"
  },
  {
    "x": "ekosfer (n)",
    "y": " ekosfer "
  },
  {
    "x": "ekosîstem (n)",
    "y": " ekosistem"
  },
  {
    "x": "ekr (n)",
    "y": " b. ekil (n)"
  },
  {
    "x": "ekran (n)",
    "y": " ekran"
  },
  {
    "x": "ekrano bingeyî (n)",
    "y": " ana ekran"
  },
  {
    "x": "ekrano esasî (n)",
    "y": " ana ekran"
  },
  {
    "x": "eks-I (n)",
    "y": " 1)ters\r\n~Çîyo ke mi waşt vaja to eksê ey fehm kerd. (Benim söylemek istediğim şeyin tersini anladın.)\r\n~eksê ci: aksine, tersine, bilâkis\r\n~eksê xo: aksine tersine, bilâkis\r\n~2)inat\r\n~eks kerdene: inat etmek\r\n~3)b. eksî, -ye"
  },
  {
    "x": "eksê ci",
    "y": " aksine, tersine, bilâkis"
  },
  {
    "x": "eksê xo",
    "y": " aksine tersine, bilâkis\r\n~2)inat"
  },
  {
    "x": "eks kerdene",
    "y": " inat etmek"
  },
  {
    "x": "eks-II (n)",
    "y": " b. aks (n)"
  },
  {
    "x": "eksantrîk, -e",
    "y": " ayrıksı, dış merkezli, eksantrik"
  },
  {
    "x": "ekse",
    "y": " b. eke-I"
  },
  {
    "x": "eksew",
    "y": " b. eke-I"
  },
  {
    "x": "eksî, -ye",
    "y": " inatçı, aksi, inat"
  },
  {
    "x": "eksîy (m)",
    "y": " b. eksîye (m)"
  },
  {
    "x": "eksîyey (m)",
    "y": " b. eksîyî (m), eksîyîye (m)"
  },
  {
    "x": "eksîyî (m)",
    "y": " inatçılık"
  },
  {
    "x": "eksîyîye (m)",
    "y": " inatçılık"
  },
  {
    "x": "ekso",
    "y": " b. eke-I"
  },
  {
    "x": "eksow",
    "y": " b. eke-I"
  },
  {
    "x": "ekspert, -e",
    "y": " uzman, bilirkişi, eksper"
  },
  {
    "x": "ekspertîye (m)",
    "y": " uzmanlık, eksperlik"
  },
  {
    "x": "ekspertîz (n)",
    "y": " ekspertiz"
  },
  {
    "x": "eksprese (m)",
    "y": " ekspres"
  },
  {
    "x": "ekspresyon (n)",
    "y": " dışavurum"
  },
  {
    "x": "ekspresyonîst, -e",
    "y": " dışavurumcu"
  },
  {
    "x": "ekspresyonîzm (n)",
    "y": " dışavurumculuk"
  },
  {
    "x": "ekstrem, -e",
    "y": " 1)aşırı\r\n~2)çılgın"
  },
  {
    "x": "ekstremîye (m)",
    "y": " çılgınlık"
  },
  {
    "x": "ekvatore (m)",
    "y": " ekvator"
  },
  {
    "x": "Ekwador (n)",
    "y": " Ekvador"
  },
  {
    "x": "ekzotermîk, -e",
    "y": " ısıveren, ekzotermik"
  },
  {
    "x": "El-Eyûn (n)",
    "y": " Laayoune"
  },
  {
    "x": "el-mel",
    "y": " eyel-meyel"
  },
  {
    "x": "Ela (n)",
    "y": " b. Ellah (n)"
  },
  {
    "x": "Ela hes biko",
    "y": " b. Ellah hes biko"
  },
  {
    "x": "Ela tala",
    "y": " b. Ellah teala"
  },
  {
    "x": "Ela teala",
    "y": " b. Ellah teala"
  },
  {
    "x": "Ela tela",
    "y": " b. Ellah teala"
  },
  {
    "x": "Elaw teala",
    "y": " b. Ellah teala"
  },
  {
    "x": "elafranga",
    "y": " b. alafranga"
  },
  {
    "x": "elamete (m)",
    "y": " belirti"
  },
  {
    "x": "elameti (m)",
    "y": " b. elamete (m)"
  },
  {
    "x": "elamû (n)",
    "y": " b. aleman (n)"
  },
  {
    "x": "elamûn (n)",
    "y": " b. aleman (n)"
  },
  {
    "x": "elaqa (m)",
    "y": " b. eleqe (n)"
  },
  {
    "x": "elaqe (n)",
    "y": " b. eleqe (n)"
  },
  {
    "x": "elaqedar, -e",
    "y": " b. eleqedar, -e"
  },
  {
    "x": "elatîriki (m)",
    "y": " b. elektrîk (n)"
  },
  {
    "x": "elawitene",
    "y": " yoğurmak"
  },
  {
    "x": "elawitiş (n)",
    "y": " yoğurma"
  },
  {
    "x": "Elazîz (n)",
    "y": " b. Xarpêt (n)"
  },
  {
    "x": "elb (m)",
    "y": " b. elbe (m)"
  },
  {
    "x": "elbe (m)",
    "y": " gerdel, tahta kova"
  },
  {
    "x": "elbet",
    "y": " b. helbet"
  },
  {
    "x": "elbi (m)",
    "y": " b. elbe (m)"
  },
  {
    "x": "elbik (m)",
    "y": " b. elbike (m)"
  },
  {
    "x": "elbike (m)",
    "y": " 1)gerdel, tahta kova\r\n~2)ölmez çiçek, altın otu, mayasıl otu, sarıçiçek, yaylagülü, yaylaçiçeği"
  },
  {
    "x": "elbisim (n)",
    "y": " b. birîsim (n)"
  },
  {
    "x": "elbisimên, -i",
    "y": " b. birîsimên, -e"
  },
  {
    "x": "elbisimin, -i",
    "y": " b. birîsimin, -e"
  },
  {
    "x": "Elcewaz (n)",
    "y": " Adilcevaz"
  },
  {
    "x": "elefiranga",
    "y": " b. alafranga"
  },
  {
    "x": "elefiranqa",
    "y": " b. alafranga"
  },
  {
    "x": "elefiranxa",
    "y": " b. alafranga"
  },
  {
    "x": "eleg (m)",
    "y": " b. êlege (m)"
  },
  {
    "x": "elege (m)",
    "y": " b. êlege (m)"
  },
  {
    "x": "elegi (m)",
    "y": " b. êlege (m)"
  },
  {
    "x": "elektirîk (n)",
    "y": " b. elektrîk (n)"
  },
  {
    "x": "elektirîke (m)",
    "y": " b. elektrîk (n)"
  },
  {
    "x": "elektirîki (m)",
    "y": " b. elektrîk (n)"
  },
  {
    "x": "elektîrik (n)",
    "y": " b. elektrîk (n)"
  },
  {
    "x": "elektrîk (n)",
    "y": " elektrik"
  },
  {
    "x": "elektrîkê destan (n)",
    "y": " el feneri, elektrik feneri"
  },
  {
    "x": "elektrîki (m)",
    "y": " b. elektrîk (n)"
  },
  {
    "x": "elektrîkin, -e",
    "y": " elektrikli"
  },
  {
    "x": "elektrîkkar, -e",
    "y": " elektrikçi"
  },
  {
    "x": "elektromanyetîk, -e",
    "y": " elektromanyetik"
  },
  {
    "x": "elektronîk, -e",
    "y": " elektronik"
  },
  {
    "x": "elektrot (n)",
    "y": " elektrot (n)"
  },
  {
    "x": "elelade, -ye",
    "y": " basbayağı"
  },
  {
    "x": "elelo, -ye",
    "y": " hindi"
  },
  {
    "x": "elelok, -e",
    "y": " b. elok, -e"
  },
  {
    "x": "elelok, -i",
    "y": " b. elok, -e"
  },
  {
    "x": "eleman-I (n)",
    "y": " öge, unsur"
  },
  {
    "x": "eleman, -e-II",
    "y": " eleman"
  },
  {
    "x": "elemana rotişî (m)",
    "y": " kadın satış elemanı"
  },
  {
    "x": "elemana sihetî (m)",
    "y": " sağlık elemanı"
  },
  {
    "x": "elemana weşîye (m)",
    "y": " sağlık elemanı"
  },
  {
    "x": "elemanê rotişî (n)",
    "y": " erkek satış elemanı"
  },
  {
    "x": "elemanê sihetî (n)",
    "y": " sağlık elemanı"
  },
  {
    "x": "elemanê weşîye (n)",
    "y": " sağlık elemanı"
  },
  {
    "x": "elemanê zemînî (n)",
    "y": " taban elemanı"
  },
  {
    "x": "elem-eşkera",
    "y": " açık açık"
  },
  {
    "x": "elemîyon (n)",
    "y": " b. alumînyum (n)"
  },
  {
    "x": "elemûn (n)",
    "y": " b. alumînyum (n)"
  },
  {
    "x": "eleqa (m)",
    "y": " b. eleqe (n)"
  },
  {
    "x": "eleqe (n)",
    "y": " ilgi, alaka"
  },
  {
    "x": "eleqedar, -e",
    "y": " alakadar, alakalı, ilgili"
  },
  {
    "x": "eleqedarîye (m)",
    "y": " alakadarlık"
  },
  {
    "x": "eleqeder, -e",
    "y": " b. eleqedar, -e"
  },
  {
    "x": "eleqîyayene",
    "y": " b. aliqîyayene"
  },
  {
    "x": "eleqîyayîş (n)",
    "y": " b. aliqîyayîş (n)"
  },
  {
    "x": "eleqnayene",
    "y": " b. aliqnayene"
  },
  {
    "x": "eleqnayîş (n)",
    "y": " b. aliqnayîş (n)"
  },
  {
    "x": "eletexmîn",
    "y": " tahmini olarak"
  },
  {
    "x": "eletîriki (m)",
    "y": " b. elektrîk (n)"
  },
  {
    "x": "eletrîge (m)",
    "y": " b. elektrîk (n)"
  },
  {
    "x": "eletrîk (m)",
    "y": " b. elektrîk (n)"
  },
  {
    "x": "eleturka",
    "y": " b. alaturka, -ye"
  },
  {
    "x": "elevator (n)",
    "y": " elevatör"
  },
  {
    "x": "elewî, -ye",
    "y": " Alevi "
  },
  {
    "x": "elewîtîye (m)",
    "y": " Alevilik"
  },
  {
    "x": "elewîyê (m)",
    "y": " b. elewîyîye (m)"
  },
  {
    "x": "elewîyênî (m)",
    "y": " Alevilik"
  },
  {
    "x": "elewîyîye (m)",
    "y": " Alevilik"
  },
  {
    "x": "eleyh (n)",
    "y": " aleyh"
  },
  {
    "x": "eleyh de",
    "y": " aleyhinde, aleyhine"
  },
  {
    "x": "eleyh de bîyene",
    "y": " aleyhinde olmak"
  },
  {
    "x": "eleyhê ... de qalî kerdene",
    "y": " aleyhinde konuşmak"
  },
  {
    "x": "eleyhê ... de qisey kerdene",
    "y": " aleyhinde konuşmak"
  },
  {
    "x": "eleyhdar, -e",
    "y": " karşı olan, karşıtçı, aleyhtar"
  },
  {
    "x": "eleykimesilûm",
    "y": " b. eleykumselam"
  },
  {
    "x": "eleykumeselam",
    "y": " b. eleykumselam"
  },
  {
    "x": "eleykumesilam",
    "y": " b. eleykumselam"
  },
  {
    "x": "eleykumselam",
    "y": " aleykümselam"
  },
  {
    "x": "eleykumsilam",
    "y": " b. eleykumselam"
  },
  {
    "x": "eleykumsilom",
    "y": " b. eleykumselam"
  },
  {
    "x": "eleykumsilûm",
    "y": " b. Eleykumselam"
  },
  {
    "x": "eleyle (m)",
    "y": " devekuşu "
  },
  {
    "x": "eleyli (m)",
    "y": " b. eleyle (m)"
  },
  {
    "x": "Elezîz (n)",
    "y": " b. Xarpêt (n)"
  },
  {
    "x": "elfaba (m)",
    "y": " b. alfabe (m)"
  },
  {
    "x": "elge kerdene",
    "y": " b. egle kerdene"
  },
  {
    "x": "elgekerdîs (n)",
    "y": " b. eglekerdiş (n)"
  },
  {
    "x": "elheş, -i",
    "y": " b. alifweş, -e"
  },
  {
    "x": "elge kerdene",
    "y": " b. egle kerdene"
  },
  {
    "x": "elgekerdiş (n)",
    "y": " b. eglekerdiş (n)"
  },
  {
    "x": "elik-fatik (m)",
    "y": " b. elik-fatike (m)"
  },
  {
    "x": "elik-fatiki (m)",
    "y": " b. elik-fatike (m)"
  },
  {
    "x": "elik-fatike (m)",
    "y": " papatya"
  },
  {
    "x": "Elî",
    "y": " Ali"
  },
  {
    "x": "elîfba (m)",
    "y": " b. alfabe (m)"
  },
  {
    "x": "elîfbê (m)",
    "y": " b. alfabe (m)"
  },
  {
    "x": "elîqopter (m)",
    "y": " b. helîkoptere (m)"
  },
  {
    "x": "Ella (n)",
    "y": " b. Ellah (n)"
  },
  {
    "x": "Ella hes biko",
    "y": " b. Ellah hes biko"
  },
  {
    "x": "Ella nêko",
    "y": " b. Ellah nêko"
  },
  {
    "x": "Ella zano",
    "y": " b. Ellah zano."
  },
  {
    "x": "Ella zono",
    "y": " b. Ellah zano."
  },
  {
    "x": "Ella zûnû",
    "y": " b. Ellah zano."
  },
  {
    "x": "Ella tala",
    "y": " b. Ellah teala"
  },
  {
    "x": "Ella teala",
    "y": " b. Ellah teala"
  },
  {
    "x": "Ella tela",
    "y": " b. Ellah teala"
  },
  {
    "x": "Ellaw teala",
    "y": " b. Ellah teala"
  },
  {
    "x": "(Ti) Ellay kenî/a",
    "y": " b. (Ti) Ellahî kenî/a."
  },
  {
    "x": "Ellay ra eyan a.",
    "y": " b. Ellahî ra eyan a."
  },
  {
    "x": "Ellay ra eyon a.",
    "y": " b. Ellahî ra eyan a."
  },
  {
    "x": "Ellay ra eyûn a.",
    "y": " b. Ellahî ra eyan a."
  },
  {
    "x": "(Ti) Ellay sînenî/a",
    "y": " b. (Ti) Ellahî sînenî/a."
  },
  {
    "x": "(Ti) Ellayî sînenî/a",
    "y": " b. (Ti) Ellahî sînenî/a."
  },
  {
    "x": "Ellah (n)",
    "y": " Allah, Tanrı, ilah\r\n~Ellah hes biko. (Allah izin verirse)\r\n~Ellah nêko. (Allah etmesin. / Allah göstermesin.)"
  },
  {
    "x": "Ellah teala",
    "y": " Allahuteala\r\n~Ellah zano. (Allah bilir. / Allahualem.)\r\n~Ellahî ra eyan a. (Allah bilir. / Allahualem.)\r\n~(Ti) Ellahî kenî/a (Allahını seversen /Allah aşkına)\r\n~(Ti) Ellahî sînenî/a (Allahını seversen / Allah aşkına)"
  },
  {
    "x": "Ellahû alem.",
    "y": " (Allah bilir. / Allahualem)"
  },
  {
    "x": "Ellahwekîl",
    "y": " “Allah'a bırakıyorum”, “Allah'ı vekil kabul ediyorum” anlamında bir söz. Allah'a yemin ederim ki."
  },
  {
    "x": "Ellawekîl",
    "y": " b. Ellahwekîl"
  },
  {
    "x": "Ellawekîr",
    "y": " b. Ellahwekîl"
  },
  {
    "x": "elle (n)",
    "y": " içine üzüm vb. şeyler konan derin ve büyük sepet"
  },
  {
    "x": "elm (n)",
    "y": " bilgi, malumat\r\n~Elm û hayîya mi ey ra çin a. (Ondan bilgim ve haberim yok.)"
  },
  {
    "x": "elmas (n)",
    "y": " elmas"
  },
  {
    "x": "elmaso bivakum (n)",
    "y": " vakumlu elmas"
  },
  {
    "x": "elmast",
    "y": " b. elmas (n)"
  },
  {
    "x": "elo",
    "y": " alo"
  },
  {
    "x": "elok, -e",
    "y": " hindi"
  },
  {
    "x": "elok, -i",
    "y": " b. elok, -e"
  },
  {
    "x": "eltîrik (n)",
    "y": " b. elektrîk (n)"
  },
  {
    "x": "eltîriki (m)",
    "y": " b. elektrîk (n)"
  },
  {
    "x": "eltîrika destan (n)",
    "y": " b. elektrîkê destan (n)"
  },
  {
    "x": "elvet",
    "y": " b. helbet"
  },
  {
    "x": "em-I (m)",
    "y": " b. eme (m)"
  },
  {
    "x": "em-II (m)",
    "y": " b. heme (m)"
  },
  {
    "x": "em kerdene",
    "y": " b. heme kerdene"
  },
  {
    "x": "em- (pr)",
    "y": " “bu” anlamı veren bir önek"
  },
  {
    "x": "emser",
    "y": " bu yıl"
  },
  {
    "x": "emşo",
    "y": " bu gece"
  },
  {
    "x": "ema, -ye",
    "y": " görme engelli, görmez, gözsüz, kör"
  },
  {
    "x": "eman kerdene",
    "y": " bezmek, bezginlik getirmek, bıkmak¸ bizar olmak, usanmak"
  },
  {
    "x": "emanet (m)",
    "y": " b. emanete (m)"
  },
  {
    "x": "emanete (m)",
    "y": " emanet"
  },
  {
    "x": "emaneti (m)",
    "y": " b. emanete (m)"
  },
  {
    "x": "emanetxane (n)",
    "y": " emanet yeri"
  },
  {
    "x": "emani (m)",
    "y": " b. emanete (m)"
  },
  {
    "x": "emankerdiş (n)",
    "y": " bezme, bıkma¸ bizar olma, usanma"
  },
  {
    "x": "emaz, -i",
    "y": " b. embaz, -e "
  },
  {
    "x": "emazey (m)",
    "y": " b. embazîye (m)"
  },
  {
    "x": "embar (n)",
    "y": " ambar"
  },
  {
    "x": "embarger, -e",
    "y": " ambarcı"
  },
  {
    "x": "embarik",
    "y": " b. bimbarek, -e"
  },
  {
    "x": "embaz, -e",
    "y": " arkadaş"
  },
  {
    "x": "embazek (n)",
    "y": " döleşi (insanda), meşime "
  },
  {
    "x": "embazey (m)",
    "y": " b. embazîye (m) "
  },
  {
    "x": "embazi (m)",
    "y": " b. embaze (m)"
  },
  {
    "x": "embazî (m)",
    "y": " arkadaşlık "
  },
  {
    "x": "embazîye (m)",
    "y": " arkadaşlık"
  },
  {
    "x": "embazkar (n)",
    "y": " belirteç, zarf"
  },
  {
    "x": "embazname (n)",
    "y": " sıfat"
  },
  {
    "x": "embirîyan, -i",
    "y": " b. embiryan, -e"
  },
  {
    "x": "embirîyanîni (m)",
    "y": " b. embiryanîne (m)"
  },
  {
    "x": "embirîyaney (m)",
    "y": " b. embiryanîye (m), embiryanî (m)"
  },
  {
    "x": "embiryan, -e",
    "y": " komşu, mücavir"
  },
  {
    "x": "embiryanî (m)",
    "y": " komşuluk"
  },
  {
    "x": "embiryanîne (m)",
    "y": " komşuluk"
  },
  {
    "x": "embiryanîni (m)",
    "y": " b. embiryanîne (m)"
  },
  {
    "x": "embiryanîye (m)",
    "y": " komşu"
  },
  {
    "x": "embrîyan, -i",
    "y": " b. embiryan, -e"
  },
  {
    "x": "embrîyanîni (m)",
    "y": " b. embiryanîne (m)"
  },
  {
    "x": "embrîyolojî (n)",
    "y": " embriyoloji"
  },
  {
    "x": "emci (m)",
    "y": " b. emcinî (m) "
  },
  {
    "x": "emciki (m)",
    "y": " b. emcinî (m)"
  },
  {
    "x": "emcinî (m)",
    "y": " amca karısı, yenge (amca karısı)"
  },
  {
    "x": "eme (m)",
    "y": " hala, bibi"
  },
  {
    "x": "emê",
    "y": " hala (hitap hali) "
  },
  {
    "x": "emeg (n)",
    "y": " emek"
  },
  {
    "x": "emegdar, -e",
    "y": " emekçi"
  },
  {
    "x": "emegdari (m)",
    "y": " emegdar, -e"
  },
  {
    "x": "emel-I (n)",
    "y": " amel, ishal, ötürük, sürgün"
  },
  {
    "x": "emel bîyayene",
    "y": " b. emel bîyene"
  },
  {
    "x": "emel bîyene",
    "y": " ishal olmak"
  },
  {
    "x": "emel-II (n)",
    "y": " eylem"
  },
  {
    "x": "emel ... ardene",
    "y": " b. emel bi ... ardene"
  },
  {
    "x": "emel ... bîyayene",
    "y": " b. emel bi ... bîyene "
  },
  {
    "x": "emel ... bîyene",
    "y": " b. emel bi ... bîyene"
  },
  {
    "x": "emel ... kerdene",
    "y": " b. emel bi ... kerdene"
  },
  {
    "x": "emel bi ... ardene",
    "y": " -e güvenmek"
  },
  {
    "x": "emel bi ... bîyene",
    "y": " -e güvenilmek"
  },
  {
    "x": "emel bi ... kerdene",
    "y": " -e güvenmek"
  },
  {
    "x": "emela (m)",
    "y": " işçi, amele"
  },
  {
    "x": "emele (n)",
    "y": " işçi, amele"
  },
  {
    "x": "emelet (n)",
    "y": " b. emelîyet (n)"
  },
  {
    "x": "emeleyey (m)",
    "y": " emeleyî (m), emeleyîye (m)"
  },
  {
    "x": "emeleyê (m)",
    "y": " emeleyî (m), emeleyîye (m)"
  },
  {
    "x": "emeleyênî (m)",
    "y": " işçilik, amelelik"
  },
  {
    "x": "emeleyî (m)",
    "y": " işçilik, amelelik"
  },
  {
    "x": "emeleyîye (m)",
    "y": " işçilik, amelelik"
  },
  {
    "x": "emelî, -ye",
    "y": " tatbikî, uygulamalı, pratik"
  },
  {
    "x": "emelîyat (n)",
    "y": " b. emelîyet (n)"
  },
  {
    "x": "emelîyatxane (n)",
    "y": " b. emelîyetxane (n)"
  },
  {
    "x": "emelîyet (n)",
    "y": " ameliyat, operasyon"
  },
  {
    "x": "emelîyetê baypasî (n)",
    "y": " baypas ameliyatı"
  },
  {
    "x": "emelîyeto cerahî (n)",
    "y": " cerrahi müdahale"
  },
  {
    "x": "emelîyetxane (n)",
    "y": " ameliyathane"
  },
  {
    "x": "emenî (n)",
    "y": " b. yemenî (n)"
  },
  {
    "x": "emeret (n)",
    "y": " b. emelîyet (n)"
  },
  {
    "x": "emê",
    "y": " hala (hitap hali)"
  },
  {
    "x": "emike (m)",
    "y": " hala, bibi"
  },
  {
    "x": "emikê",
    "y": " hala (hitap hali) "
  },
  {
    "x": "emi (m)",
    "y": " b. eme (m) "
  },
  {
    "x": "emikê",
    "y": " hala (hitap hali)"
  },
  {
    "x": "emiki (m)",
    "y": " b. emike (m)"
  },
  {
    "x": "emin (n)",
    "y": " şey \r\n~Eminî bîyare. (Şeyi getir.)"
  },
  {
    "x": "eminû (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "eminûn (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "emir-I (n)",
    "y": " b. emr-I (n)"
  },
  {
    "x": "emir-II (n)",
    "y": " b. emr-II (n)"
  },
  {
    "x": "emiza (n)",
    "y": " hala oğlu"
  },
  {
    "x": "emîlat (n)",
    "y": " b. emelîyet (n)"
  },
  {
    "x": "emîsyon (n)",
    "y": " emisyon, sürüm (ekonomi için) "
  },
  {
    "x": "emîsyonê otomotîvî (zh)",
    "y": " otomotiv emisyonlar"
  },
  {
    "x": "emkeyna (m)",
    "y": " b. emkêna (m)"
  },
  {
    "x": "emkêna (m)",
    "y": " hala kızı "
  },
  {
    "x": "emlak (n)",
    "y": " emlak"
  },
  {
    "x": "Emman (n)",
    "y": " Amman"
  },
  {
    "x": "emna (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "emnan (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "emnîyet (n)",
    "y": " güvenlik (n), asayiş, emniyet"
  },
  {
    "x": "emnîyetê ganî/canî (n)",
    "y": " can güvenliği"
  },
  {
    "x": "emnîyetê xidayan (n)",
    "y": " gıda güvenliği"
  },
  {
    "x": "emno (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "emnon (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "emnû (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "emnûn (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "emon kerdene",
    "y": " b. eman kerdene"
  },
  {
    "x": "emonet (m)",
    "y": " b. emanete (m)"
  },
  {
    "x": "emoneti (m)",
    "y": " b. emanete (m)"
  },
  {
    "x": "emonkerdiş (n)",
    "y": " b. emankerdiş (n)"
  },
  {
    "x": "emr-I (n)",
    "y": " ömür, yaş\r\n~Emrê to çend o?/Emrê to çiqas o? (Kaç yaşındasın?)"
  },
  {
    "x": "emr-II (n)",
    "y": " 1)emir, buyruk, ferman\r\n~2)komut"
  },
  {
    "x": "emr dayene",
    "y": " buyurmak"
  },
  {
    "x": "emr kerdene",
    "y": " buyurmak"
  },
  {
    "x": "emrê heqdayîşî (n)",
    "y": " ödeme emri"
  },
  {
    "x": "emrîyan, -i",
    "y": " b. embiryan, -e"
  },
  {
    "x": "emrnameyê heqdayîşî (n)",
    "y": " ödeme emri"
  },
  {
    "x": "emroy (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "emsa",
    "y": " b. emser "
  },
  {
    "x": "emsal (n)",
    "y": " emsal (inşaat arsası için) "
  },
  {
    "x": "emsal, -e",
    "y": " b. hemserr, -e"
  },
  {
    "x": "emsar",
    "y": " b. emser "
  },
  {
    "x": "emsarr",
    "y": " b. emser "
  },
  {
    "x": "emse",
    "y": " b. emser"
  },
  {
    "x": "emser",
    "y": " bu yıl"
  },
  {
    "x": "emso",
    "y": " b. emşo"
  },
  {
    "x": "emsû",
    "y": " b. emşo"
  },
  {
    "x": "emş",
    "y": " b. emşo"
  },
  {
    "x": "emşi",
    "y": " b. emşo"
  },
  {
    "x": "emşo",
    "y": " bu gece "
  },
  {
    "x": "emşû",
    "y": " b. emşo"
  },
  {
    "x": "emûn kerdene",
    "y": " b. eman kerdene"
  },
  {
    "x": "emûnet (m)",
    "y": " b. emanete (m)"
  },
  {
    "x": "emûneti (m)",
    "y": " b. emanete (m)"
  },
  {
    "x": "emûnkerdiş (n)",
    "y": " b. emankerdiş (n)"
  },
  {
    "x": "emza (n)",
    "y": " b. emiza (n)"
  },
  {
    "x": "emzig (n)",
    "y": " b. emzik (n)"
  },
  {
    "x": "emzik (n)",
    "y": " emzik"
  },
  {
    "x": "en-I (n, z)",
    "y": " b. no (n, z) "
  },
  {
    "x": "en-II (n, s)",
    "y": " b. no (n, s)"
  },
  {
    "x": "en-III",
    "y": " en (b. tewr)"
  },
  {
    "x": "en peynî de",
    "y": " b. tewr peynîye de"
  },
  {
    "x": "en raver",
    "y": " b. tewr raver"
  },
  {
    "x": "en verê",
    "y": " b. tewr verê"
  },
  {
    "x": "en zaf",
    "y": " b. tewr zaf"
  },
  {
    "x": "en-IV (zh, z)",
    "y": " b. nê (zh, z)"
  },
  {
    "x": "en-V (zh, s)",
    "y": " b. nê (zh, s) "
  },
  {
    "x": "...en (sf)",
    "y": " 1)b. -ene (sf)\r\n~2)b. -ên (sf)\r\n~3)b. -in-I (sf)"
  },
  {
    "x": "ena-I (m, z)",
    "y": " 1)b. na-I (m, z)\r\n~2)b. naye (m, z)"
  },
  {
    "x": "ena-II (m, s)",
    "y": " b. na-I (m, s)"
  },
  {
    "x": "ena ray",
    "y": " b. na rey"
  },
  {
    "x": "ena rey",
    "y": " b. na rey "
  },
  {
    "x": "ena-III",
    "y": " b. wina"
  },
  {
    "x": "enaray",
    "y": " b. nara"
  },
  {
    "x": "enarey",
    "y": " b. nara"
  },
  {
    "x": "enasar",
    "y": " b. inasar"
  },
  {
    "x": "enasarên, -i",
    "y": " b. inasarên, -e; winasî, -ye"
  },
  {
    "x": "enawa",
    "y": " b. inahawa"
  },
  {
    "x": "enay (m, z)",
    "y": " b. naye (m, z)"
  },
  {
    "x": "enayên",
    "y": " b. winasî, -ye; inasarên, -e"
  },
  {
    "x": "enayi",
    "y": " b. winasî, inasarên"
  },
  {
    "x": "enayin",
    "y": " b. winasî, -ye; inasarên, -e"
  },
  {
    "x": "enayî",
    "y": " b. winasî, inasarên"
  },
  {
    "x": "enayîn",
    "y": " b. winasî, -ye; inasarên, -e"
  },
  {
    "x": "enbaz (n)",
    "y": " b. embaz (n)"
  },
  {
    "x": "enbirîyan, -i",
    "y": " b. embiryan, -e"
  },
  {
    "x": "enbirîyanîni (m)",
    "y": " b. embiryanîne (m)"
  },
  {
    "x": "enbiryan, -i",
    "y": " b. embiryan, -e"
  },
  {
    "x": "enbiryanîni (m)",
    "y": " b. embiryanîne (m)"
  },
  {
    "x": "enbriyan, -i",
    "y": " b. embiryan, -e"
  },
  {
    "x": "encax",
    "y": " b. ancax"
  },
  {
    "x": "enci (m)",
    "y": " b. emcinî (m)"
  },
  {
    "x": "encik (m)",
    "y": " b. emcinî (m)"
  },
  {
    "x": "enciki (m)",
    "y": " b. emcinî (m)"
  },
  {
    "x": "encil (m)",
    "y": " b. încîre (m), hêjîre (m)"
  },
  {
    "x": "encili (m)",
    "y": " b. încîre (m), hêjîre (m)"
  },
  {
    "x": "encilû (n)",
    "y": " b. anculî (n)"
  },
  {
    "x": "encîl (m)",
    "y": " b. încîre (m), hêjîre (m)"
  },
  {
    "x": "encîli (m)",
    "y": " b. încîre (m), hêjîre (m)"
  },
  {
    "x": "encîle (m)",
    "y": " b. încîre (m), hêjîre (m)"
  },
  {
    "x": "encîlêre (m)",
    "y": " b. încîrêre (m), hêjîrêre (m)"
  },
  {
    "x": "encîli (m)",
    "y": " b. încîre (m), hêjîre (m)"
  },
  {
    "x": "encîlî (n)",
    "y": " b. anculî (n)"
  },
  {
    "x": "encîr (n)",
    "y": " b. încîre (m), hêjîre (m)"
  },
  {
    "x": "enclur (m)",
    "y": " b. êlancuxa kere"
  },
  {
    "x": "enculu (n)",
    "y": " b. anculî (n)"
  },
  {
    "x": "encumen (n)",
    "y": " encümen"
  },
  {
    "x": "encumenê şaredarî/beledîya (n)",
    "y": " belediye encümeni"
  },
  {
    "x": "encumenê timî/timûtimî",
    "y": " daimi encümen"
  },
  {
    "x": "encumenê wezîran (n)",
    "y": " bakanlar kurulu, icra vekilleri heyeti"
  },
  {
    "x": "encumeno timin/timûtimin/daîmî",
    "y": " daimi encümen"
  },
  {
    "x": "encur (n)",
    "y": " b. ecur (n)"
  },
  {
    "x": "encûr (n)",
    "y": " b. ecur (n)"
  },
  {
    "x": "end",
    "y": " b. hende"
  },
  {
    "x": "enda",
    "y": " b. hendê"
  },
  {
    "x": "endam, -e",
    "y": " üye"
  },
  {
    "x": "endamê/a cunta",
    "y": " cuntacı"
  },
  {
    "x": "endamê taximî (zh)",
    "y": " takım üyeleri"
  },
  {
    "x": "endamî (m)",
    "y": " üyelik "
  },
  {
    "x": "endamîye (m)",
    "y": " üyelik "
  },
  {
    "x": "ende",
    "y": " b. hende"
  },
  {
    "x": "endeks (n)",
    "y": " dizin, endeks"
  },
  {
    "x": "endeksê hayleme û hûmaran (n)",
    "y": " gürültü ve sayı endeksi"
  },
  {
    "x": "endeksê hermele û hûmaran (n)",
    "y": " gürültü ve sayı endeksi"
  },
  {
    "x": "endeksê welwele û hûmaran (n)",
    "y": " gürültü ve sayı endeksi"
  },
  {
    "x": "endekso peyên (n)",
    "y": " son endeks"
  },
  {
    "x": "endê",
    "y": " b. hendê"
  },
  {
    "x": "endhay",
    "y": " b. hende"
  },
  {
    "x": "endhey",
    "y": " b. hende"
  },
  {
    "x": "endi",
    "y": " b. hende"
  },
  {
    "x": "endihay",
    "y": " b. hende"
  },
  {
    "x": "endihey",
    "y": " b. hende"
  },
  {
    "x": "endiheyra",
    "y": " b. hende "
  },
  {
    "x": "endî",
    "y": " b. êdî"
  },
  {
    "x": "endîşe (m)",
    "y": " endişe, kaygı, tasa, telaş"
  },
  {
    "x": "endîşe kerdene",
    "y": " endişelenmek, endişe etmek, kaygılanmak, tasalanmak"
  },
  {
    "x": "endîşekerdiş (n)",
    "y": " endişelenme, kaygılanma, tasalanma"
  },
  {
    "x": "endîşenak, -e",
    "y": " endişeli, kaygılı, tasalı, telaşlı"
  },
  {
    "x": "endustrî (n)",
    "y": " endüstri, sanayi"
  },
  {
    "x": "endustrîyê sînema (n)",
    "y": " sinema endüstrisi, sinema sanayii"
  },
  {
    "x": "endustrîyo giran (n)",
    "y": " ağır endüstri, ağır sanayi"
  },
  {
    "x": "endustrîkar, -e",
    "y": " sanayici, endüstrici"
  },
  {
    "x": "endustrîkarî (m)",
    "y": " sanayicilik"
  },
  {
    "x": "endustrîkarîye (m)",
    "y": " sanayicilik"
  },
  {
    "x": "endustrîyel, -e",
    "y": " endüstriyel, sınai"
  },
  {
    "x": "...ene (sf)",
    "y": " mastar eki"
  },
  {
    "x": "enerjî (m)",
    "y": " enerji"
  },
  {
    "x": "enewa",
    "y": " b. inahawa"
  },
  {
    "x": "eney (n, z)",
    "y": " b. ney (n, z)"
  },
  {
    "x": "enê-I (n, z)",
    "y": " b. ney (n, z)"
  },
  {
    "x": "enê-II (m, z)",
    "y": " b. naye (m, z)"
  },
  {
    "x": "enê-III (zh, z)",
    "y": " b. nê (zh, z)"
  },
  {
    "x": "enê-IV (zh, s)",
    "y": " b. nê (zh, s)"
  },
  {
    "x": "enê-V (zr)",
    "y": " b. enî-V (zr)"
  },
  {
    "x": "enê-VI (n, s)",
    "y": " b. nê-IV (n, s)"
  },
  {
    "x": "enflasyon (n)",
    "y": " enflasyon"
  },
  {
    "x": "enformatîk (n)",
    "y": " bilişim, enformatik"
  },
  {
    "x": "engaz (m)",
    "y": " b. hengazî (m)"
  },
  {
    "x": "engazî (m)",
    "y": " b. hengazî (m) "
  },
  {
    "x": "engela (m)",
    "y": " b. hingila (m)"
  },
  {
    "x": "engele (n)",
    "y": " 1)b. hingile-I (n)\r\n~2)b. hingilik (n)"
  },
  {
    "x": "engemên (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "engemî (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "engemîn (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "engile (n)",
    "y": " b. hingile-I (n) "
  },
  {
    "x": "engilisîyayene",
    "y": " uyuşmak"
  },
  {
    "x": "engilisîyayîş (n)",
    "y": " uyuşma"
  },
  {
    "x": "engilisnayene",
    "y": " uyuşturmak"
  },
  {
    "x": "engilisnayîş (n)",
    "y": " uyuşturma"
  },
  {
    "x": "engilisyayîş (n)",
    "y": " b. engilisîyayîş (n)"
  },
  {
    "x": "engimîn (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "engimye (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "engist (m)",
    "y": " b. engişte (m)"
  },
  {
    "x": "engişt (m)",
    "y": " b. engişte (m)"
  },
  {
    "x": "engiştane (n)",
    "y": " 1)yüksük\r\n~2)yüzük\r\n~3)orakla ekin biçerken sol ele geçirilen ve biçilen büyük ekin demetini elde tutup kontrol etmeyi sağlayan bir araç"
  },
  {
    "x": "engiştaneyê nîşanî (n)",
    "y": " alyans, nişan yüzüğü"
  },
  {
    "x": "engiştaneyo sêmên (n)",
    "y": " gümüş yüzük"
  },
  {
    "x": "engiştani (n)",
    "y": " b. engiştane (n)"
  },
  {
    "x": "engiştanî (n)",
    "y": " b. engiştane (n)"
  },
  {
    "x": "engişte-I (m)",
    "y": " parmak"
  },
  {
    "x": "engişta derge (m)",
    "y": " orta parmak"
  },
  {
    "x": "engişta destî (m)",
    "y": " el parmağı"
  },
  {
    "x": "engişta engiştaneyî (m)",
    "y": " yüzük parmağı"
  },
  {
    "x": "engişta eşhede (m)",
    "y": " işaret parmağı"
  },
  {
    "x": "engişta girde (m)",
    "y": " başparmak"
  },
  {
    "x": "engişta linge (m)",
    "y": " ayak parmağı"
  },
  {
    "x": "engişta malêbe (m)",
    "y": " yabanın dişlerinden her biri"
  },
  {
    "x": "engişta milêve (m)",
    "y": " b. engişta malêbe (m)"
  },
  {
    "x": "engişta mîyanêne (m)",
    "y": " orta parmak"
  },
  {
    "x": "engişta ortî (m)",
    "y": " orta parmak"
  },
  {
    "x": "engişta pîle (m)",
    "y": " başparmak"
  },
  {
    "x": "engişta pîlli (m)",
    "y": " b. engişta pîle (m)"
  },
  {
    "x": "engişta qole (m)",
    "y": " serçe parmak"
  },
  {
    "x": "engişta şade (m)",
    "y": " b. engişta şehadetî (m)"
  },
  {
    "x": "engişta şadetî (m)",
    "y": " b. engişta şehadetî (m)"
  },
  {
    "x": "engişta şadi (m)",
    "y": " b. engişta şehadetî (m)"
  },
  {
    "x": "engişta şehadetî (m)",
    "y": " işaret parmağı, şehadet parmağı"
  },
  {
    "x": "engişta xişne (m)",
    "y": " başparmak"
  },
  {
    "x": "Qilanc a (1), maymilanc a (2), kofiderg a (3), firaqlişt a (4), espijekişt a (5)",
    "y": " [Çocuk tekerlemesinde parmaklar] (1)Serçe parmak, (2)Yüzük parmağı, (3)Orta parmak, (4)İşaret "
  },
  {
    "x": "engişte dekutene",
    "y": " sabote etmek (mecazi), baltalamak (mecazi)"
  },
  {
    "x": "engişte-II (n)",
    "y": " b. engiştane (n)"
  },
  {
    "x": "engiştedekutiş (n)",
    "y": " sabote etme (mecazi), baltalama (mecazi), sabotaj, sabote"
  },
  {
    "x": "engiştere (n)",
    "y": " b. engiştane (n)"
  },
  {
    "x": "engişti (m)",
    "y": " b. engişte (m)"
  },
  {
    "x": "engiştiri (n)",
    "y": " b. engiştane (n)"
  },
  {
    "x": "engiştone (n)",
    "y": " b. engiştane (n)"
  },
  {
    "x": "engiştûne (n)",
    "y": " b. engiştane (n)"
  },
  {
    "x": "engîmîn (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "engînare (m)",
    "y": " enginar"
  },
  {
    "x": "engîşt (m)",
    "y": " b. engişte (m)"
  },
  {
    "x": "engîşti (m)",
    "y": " b. engişte (m)"
  },
  {
    "x": "engîşta şadeti (m)",
    "y": " b. engişta şehadetî (m)"
  },
  {
    "x": "engîştane (n)",
    "y": " b. engiştane (n)"
  },
  {
    "x": "engîştrane (n)",
    "y": " b. engiştane (n)"
  },
  {
    "x": "engola (m)",
    "y": " b. çinçolike (m), kengula (m)"
  },
  {
    "x": "engur (m)",
    "y": " b. engure (m)"
  },
  {
    "x": "engurê mîrçikûn (m)",
    "y": " b. engura mîlçikan (m)"
  },
  {
    "x": "engurê şîrî (m)",
    "y": " b. engura şîrî (m)"
  },
  {
    "x": "engure (m)",
    "y": " üzüm"
  },
  {
    "x": "engura balcanî (m)",
    "y": " bir üzüm çeşidi"
  },
  {
    "x": "engura balîcanî (m)",
    "y": " b. engura balcanî (m)"
  },
  {
    "x": "engure çînayene",
    "y": " üzüm koparmak"
  },
  {
    "x": "engura milçikan (m)",
    "y": " b. engura mîlçikan (m)"
  },
  {
    "x": "engura mîlçikan (m)",
    "y": " bir çeşit yabani üzüm"
  },
  {
    "x": "engura şîrî (m)",
    "y": " bir üzüm çeşidi\r\n~Engure belate bîya./Belate dekewto engure./Belate perayo engure. (Üzüm olgunlaşmaya başlamış.)"
  },
  {
    "x": "engure qerefnayîne",
    "y": " b. engure qerifnayene"
  },
  {
    "x": "engure qerifnayene",
    "y": " üzüm koparmak"
  },
  {
    "x": "engure qerfnayene",
    "y": " b. engure qerifnayene"
  },
  {
    "x": "engurey milçikon (m)",
    "y": " b. engura mîlçikan (m)"
  },
  {
    "x": "engurey mîrçikûn (m)",
    "y": " b. engura mîlçikan (m)"
  },
  {
    "x": "enguri (m)",
    "y": " b. engure (m)"
  },
  {
    "x": "engurî (m)",
    "y": " b. engure (m)"
  },
  {
    "x": "engûr (m)",
    "y": " b. engure (m)"
  },
  {
    "x": "engûri (m)",
    "y": " b. engure (m)"
  },
  {
    "x": "enhewa",
    "y": " b. inahawa"
  },
  {
    "x": "eni-I (n, z)",
    "y": " b. no (n, z)"
  },
  {
    "x": "eni-II (n, s)",
    "y": " b. no (n, s)"
  },
  {
    "x": "...eni (sf)",
    "y": " b. -ene (sf)"
  },
  {
    "x": "enika",
    "y": " b. nika"
  },
  {
    "x": "enim (n)",
    "y": " b. emin (n)"
  },
  {
    "x": "enî-I (n, z)",
    "y": " b. ney-II (n, z)"
  },
  {
    "x": "enî-II (n, s)",
    "y": " b. nê-IV (n, s) "
  },
  {
    "x": "enî-III (zh, z)",
    "y": " b. nê-I (zh, z)"
  },
  {
    "x": "enî-IV (zh, s)",
    "y": " b. nê-II (zh, s)"
  },
  {
    "x": "enî-V (zr)",
    "y": " güya"
  },
  {
    "x": "enîn (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "enîna (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "enînan (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "enîşk (n)",
    "y": " b. henişk (n)"
  },
  {
    "x": "enîya (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "enîyan (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "enîyo (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "enîyon (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "enîyû (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "enîyûn (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "enka",
    "y": " b. nika"
  },
  {
    "x": "enkayin, -i",
    "y": " b. nikayin, -e"
  },
  {
    "x": "enkê",
    "y": " b. nika "
  },
  {
    "x": "enkêyen",
    "y": " b. nikayin, -e"
  },
  {
    "x": "eno-I (n, z)",
    "y": " b. no-I (n, z) "
  },
  {
    "x": "eno-II (n, s)",
    "y": " b. no-II (n, s)"
  },
  {
    "x": "enstîtu (n)",
    "y": " enstitü"
  },
  {
    "x": "enstîtuyê sînema (n)",
    "y": " sinema enstitüsü"
  },
  {
    "x": "enstruman (n)",
    "y": " çalgı, enstrüman"
  },
  {
    "x": "enstruman cenayene/cinitene",
    "y": " çalgı çalmak"
  },
  {
    "x": "enstrumanê muzîkî (n)",
    "y": " çalgı aleti"
  },
  {
    "x": "enstrumancenayox, -e",
    "y": " çalgıcı, kerizci"
  },
  {
    "x": "enstrumancenayoxîye (m)",
    "y": " çalgıcılık"
  },
  {
    "x": "entegrasyon (n)",
    "y": " entegrasyon"
  },
  {
    "x": "entegre, -ye",
    "y": " entegre"
  },
  {
    "x": "enteresan, -e",
    "y": " ilginç, alakabahş, enteresan"
  },
  {
    "x": "enteresanîye (m)",
    "y": " ilginçlik, enteresanlık"
  },
  {
    "x": "enternasyonal, -e",
    "y": " uluslararası, milletlerarası, beynelmilel "
  },
  {
    "x": "entîk",
    "y": " antik"
  },
  {
    "x": "entîka (m)",
    "y": " antika"
  },
  {
    "x": "entîkdar, -e",
    "y": " antikacı"
  },
  {
    "x": "entîkdarî (m)",
    "y": " antikacılık"
  },
  {
    "x": "entîkdarîye (m)",
    "y": " antikacılık "
  },
  {
    "x": "entîke (n)",
    "y": " antika"
  },
  {
    "x": "entîkîte (m)",
    "y": " antikite, eskilik"
  },
  {
    "x": "Entîkîte (m)",
    "y": " Antik Çağ, Antikite"
  },
  {
    "x": "entîkroş, -e",
    "y": " antikacı"
  },
  {
    "x": "entîkroşî (m)",
    "y": " antikacılık"
  },
  {
    "x": "entîkroşîye (m)",
    "y": " antikacılık "
  },
  {
    "x": "enû-I (n, z)",
    "y": " b. no-I (n, z) "
  },
  {
    "x": "enû-II (n, s)",
    "y": " b. no-II (n, s)"
  },
  {
    "x": "enwa",
    "y": " b. inahawa"
  },
  {
    "x": "enyad (n)",
    "y": " b. înad (n)"
  },
  {
    "x": "enyadê (m)",
    "y": " b. înadî (m)"
  },
  {
    "x": "enyat (n)",
    "y": " b. înad (n)"
  },
  {
    "x": "enyatê (m)",
    "y": " b. înadî (m)"
  },
  {
    "x": "enyo (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "epe (m)",
    "y": " mama (çocuk dilinde), yiyecek (çocuk dilinde), yemek (çocuk dilinde)"
  },
  {
    "x": "epe kerdene",
    "y": " yemek (çocuk dilinde)"
  },
  {
    "x": "epe werdene",
    "y": " yemek (çocuk dilinde)"
  },
  {
    "x": "epey",
    "y": " epey, epeyce, hayli, bayağı"
  },
  {
    "x": "epeyî",
    "y": " b. epey"
  },
  {
    "x": "epêce",
    "y": " b. epey"
  },
  {
    "x": "epîdemî (m)",
    "y": " salgın, epidemi"
  },
  {
    "x": "epîdemîk, -e",
    "y": " epidemik"
  },
  {
    "x": "epîlasyon (n)",
    "y": " epilasyon"
  },
  {
    "x": "epîlepsî (m)",
    "y": " sara, epilepsi"
  },
  {
    "x": "epîlepsîyin, -e",
    "y": " saralı"
  },
  {
    "x": "epîya",
    "y": " b. epey"
  },
  {
    "x": "epo (n)",
    "y": " mama (çocuk dilinde), yemek (çocuk dilinde), yiyecek (çocuk dilinde)"
  },
  {
    "x": "eqaîd (n)",
    "y": " akait"
  },
  {
    "x": "eqîde (m)",
    "y": " akide"
  },
  {
    "x": "eqreba (n, m)",
    "y": " b. aqreba (n, m)"
  },
  {
    "x": "er-I (n)",
    "y": " b. erd (n)"
  },
  {
    "x": "er-II (n)",
    "y": " b. berey (n), erey (n)"
  },
  {
    "x": "er tîk tîk",
    "y": " koç veya tekenin toslamasını sağlamak için söylenen bir söz "
  },
  {
    "x": "era (vr)",
    "y": " b. ra (vr), ro (vr)"
  },
  {
    "x": "era ci estene",
    "y": " b. ra ci eştene"
  },
  {
    "x": "era ci kewtene",
    "y": " b. ro ... kewtene"
  },
  {
    "x": "era ci şîkîyayene",
    "y": " b. ra ... şîkîyayene"
  },
  {
    "x": "era dime kewtene",
    "y": " b. ra dim kewtene"
  },
  {
    "x": "era dime kotene",
    "y": " b. ra dim kewtene"
  },
  {
    "x": "era gilê bêçikan nayene",
    "y": " çimdiklemek, çimdik atmak, çimdik basmak"
  },
  {
    "x": "era gilê bêçikan nîyayene",
    "y": " çimdiklenmek, çimdik atılmak"
  },
  {
    "x": "era hewa fînîyayene",
    "y": " b. ra hewa fînîyayene"
  },
  {
    "x": "era hewa fîştene",
    "y": " b. ra hewa fîştene"
  },
  {
    "x": "era pey nayene",
    "y": " birisinin arkasına başkasını hayvana bindirmek"
  },
  {
    "x": "era raye fîstene",
    "y": " b. ra ray fîştene"
  },
  {
    "x": "era raye fîştene",
    "y": " b. ra ray fîştene"
  },
  {
    "x": "era ser deverdayene",
    "y": " üstüne dökmek, üzerine dökmek"
  },
  {
    "x": "era ser estene",
    "y": " b. eştene ser"
  },
  {
    "x": "era ser verdayene",
    "y": " üstüne dökmek, üzerine dökmek"
  },
  {
    "x": "era vayî ver kewtene",
    "y": " b. ra vayî ver kewtene"
  },
  {
    "x": "era vîrî ardene",
    "y": " b. ra vîr ardene"
  },
  {
    "x": "era xo aferîyayene",
    "y": " b. ra xo aferîyayene"
  },
  {
    "x": "era xo amayene",
    "y": " b. ro xo ameyene"
  },
  {
    "x": "era xo ameyîne",
    "y": " b. ro xo ameyene"
  },
  {
    "x": "era xo vîrî ardene",
    "y": " b. ra xo vîr ardene"
  },
  {
    "x": "eraba (m)",
    "y": " b. erebe (n)"
  },
  {
    "x": "eraciestiş (n)",
    "y": " b. racieştiş (n)"
  },
  {
    "x": "eracikewtiş (n)",
    "y": " b. ro ... kewtiş (n)"
  },
  {
    "x": "eracişîkîyayîs (n)",
    "y": " b. ra ... şîkîyayîş"
  },
  {
    "x": "eradimekewtiş (n)",
    "y": " b. radimkewtiş (n)"
  },
  {
    "x": "eradimekotiş (n)",
    "y": " b. radimkewtiş (n)"
  },
  {
    "x": "eragilêbêçikannayîş (n)",
    "y": " çimdikleme, çimdik atma, çimdik basma"
  },
  {
    "x": "eragilêbêçikannîyayîş (n)",
    "y": " çimdikleme, çimdik atma, çimdik basma"
  },
  {
    "x": "erahewafînîyayîş (n)",
    "y": " b. rahewafînîyayîş (n)"
  },
  {
    "x": "erahewafîştiş (n)",
    "y": " b. rahewafîştiş (n)"
  },
  {
    "x": "eraq-I (m)",
    "y": " b. reqî (m)"
  },
  {
    "x": "eraq-II (n)",
    "y": " b. ereq (m)"
  },
  {
    "x": "eraqwer, -e",
    "y": " b. reqîwer, -e"
  },
  {
    "x": "erarayefîstiş (n)",
    "y": " b. rarayfîştiş (n)"
  },
  {
    "x": "erarayefîştiş (n)",
    "y": " b. rarayfîştiş (n)"
  },
  {
    "x": "eravê",
    "y": " b. raver, aver"
  },
  {
    "x": "eravêşîyayîş (n)",
    "y": " b. raverşîyayîş (n)"
  },
  {
    "x": "eravîrîardiş (n)",
    "y": " b. ravîrardiş (n) "
  },
  {
    "x": "eraxovîrardiş (n)",
    "y": " b. raxovîrardiş (n)"
  },
  {
    "x": "erazî (m)",
    "y": " arazi"
  },
  {
    "x": "erazîya awî (m)",
    "y": " b. erazîya awîye (m)"
  },
  {
    "x": "erazîya awîye (m)",
    "y": " sulu arazi"
  },
  {
    "x": "erazîya bejîye (m)",
    "y": " kuru arazi"
  },
  {
    "x": "erazîyê nizmî (zh)",
    "y": " alçak araziler"
  },
  {
    "x": "erbêş (n)",
    "y": " b. hargûş, -e"
  },
  {
    "x": "erbêz (m)",
    "y": " b. arozî (m)"
  },
  {
    "x": "erbîş (n)",
    "y": " b. hargûş, -e"
  },
  {
    "x": "erc (m)",
    "y": " b. erje (m)"
  },
  {
    "x": "ercan, -i",
    "y": " b. erjan, -e "
  },
  {
    "x": "ercanê (m)",
    "y": " b. erjanîye (m)"
  },
  {
    "x": "ercanî (m)",
    "y": " b. erjanîye (m)"
  },
  {
    "x": "ercaya (m)",
    "y": " b. erjaya (m)"
  },
  {
    "x": "ercaye (n)",
    "y": " b. erjaye (n)"
  },
  {
    "x": "ercayi (n)",
    "y": " b. erjaye (n)"
  },
  {
    "x": "ercayîne",
    "y": " b. erjayene"
  },
  {
    "x": "ercayîş (n)",
    "y": " b. erjayîş (n)"
  },
  {
    "x": "erci (m)",
    "y": " b. erje (m)"
  },
  {
    "x": "ercîyaya (m)",
    "y": " b. erjaya (m)"
  },
  {
    "x": "ercîyaye (n)",
    "y": " b. erjaye (n)"
  },
  {
    "x": "ercon (n)",
    "y": " b. erjan"
  },
  {
    "x": "erconey (m)",
    "y": " b. erjanîye (m)"
  },
  {
    "x": "ercûn, -i",
    "y": " b. erjan, -e"
  },
  {
    "x": "ercûnê (m)",
    "y": " b. erjanîye (m)"
  },
  {
    "x": "ercûnî (m)",
    "y": " b. erjanîye (m)"
  },
  {
    "x": "erd (n)",
    "y": " 1)yer, arz\r\n~2)kara"
  },
  {
    "x": "erdê rezan (n)",
    "y": " bağlık"
  },
  {
    "x": "erdo mikineyin (n)",
    "y": " b. erdo nekmeyin (n)"
  },
  {
    "x": "erdo nekmeyin (n)",
    "y": " nemli arazi, rutubetli arazi"
  },
  {
    "x": "erdant (n)",
    "y": " yerçekimi"
  },
  {
    "x": "erdene",
    "y": " b. ardene"
  },
  {
    "x": "erdeş (n)",
    "y": " 1)kazık\r\n~2)ucu sivriltilmiş çubuk"
  },
  {
    "x": "erdeşî (zh)",
    "y": " kazık biçiminde ucu sivriltilmiş çubuklarla oynanan bir çocuk oyunu, Ma pîjî/pîjikî/pîjikekî/gulçî/gulçanî/mîyînî/erdeşî/pistî kay kerdîy."
  },
  {
    "x": "erdiş (n)",
    "y": " b. ardiş (n)"
  },
  {
    "x": "erdî",
    "y": " karasal"
  },
  {
    "x": "erdîs (m)",
    "y": " b. erdîşe (m)"
  },
  {
    "x": "erdîş (m)",
    "y": " b. erdîşe (m)"
  },
  {
    "x": "erdîşe (m)",
    "y": " sakal"
  },
  {
    "x": "erdîşi (m)",
    "y": " b. erdîşe (m)"
  },
  {
    "x": "erdîya (m)",
    "y": " ardiye"
  },
  {
    "x": "erdlerz (n)",
    "y": " deprem, zelzele "
  },
  {
    "x": "erdlerzo volkanîk (n)",
    "y": " yanardağ depremi"
  },
  {
    "x": "erdnas, -e",
    "y": " jeolog"
  },
  {
    "x": "erdnasî (m)",
    "y": " jeoloji"
  },
  {
    "x": "erdos (m)",
    "y": " b. herdose (m)"
  },
  {
    "x": "erdpeymî (m)",
    "y": " yerölçüm, jeodezi"
  },
  {
    "x": "erdpeymîye (m)",
    "y": " yerölçüm, jeodezi"
  },
  {
    "x": "ere-II (n)",
    "y": " b. berey (n), erey (n)"
  },
  {
    "x": "ere-I (vr)",
    "y": " b. ro (vr)"
  },
  {
    "x": "ere ci berdene",
    "y": " benzetmek"
  },
  {
    "x": "ere ci mendene",
    "y": " -e benzemek, çağrıştırmak (andırmak)"
  },
  {
    "x": "ere ci ginayene",
    "y": " değmek"
  },
  {
    "x": "ere ci şîyene",
    "y": " -e benzemek, çağrıştırmak (andırmak)"
  },
  {
    "x": "ere gayî amayene",
    "y": " b. ere gayî ameyene"
  },
  {
    "x": "ere gayî ameyene",
    "y": " kösnümek (inek için)"
  },
  {
    "x": "ere … ginayene",
    "y": " -e değmek, -e isabet etmek"
  },
  {
    "x": "ere kel amayene",
    "y": " b. ere kel ameyene"
  },
  {
    "x": "ere kel ameyene",
    "y": " kösnümek (keçi için)"
  },
  {
    "x": "ere … nîştene",
    "y": " b. ro … niştene"
  },
  {
    "x": "ere … nîştiş",
    "y": " b. ro … niştiş"
  },
  {
    "x": "ere rî vejîyayene",
    "y": " çemkirmek, sert cevap vermek"
  },
  {
    "x": "ere war ameyene",
    "y": " inmek\r\n~Xalê ey qatire ra ame ere war. (Dayısı katırdan indi.)"
  },
  {
    "x": "ere xo ameyene",
    "y": " b. ro xo ameyene"
  },
  {
    "x": "ereb, -e",
    "y": " Arap"
  },
  {
    "x": "ereb bîyene",
    "y": " Araplaşmak"
  },
  {
    "x": "ereb kerdene",
    "y": " Araplaştırmak"
  },
  {
    "x": "ereba (m)",
    "y": " b. erebe (n)"
  },
  {
    "x": "ereba destî (m)",
    "y": " b. erebeyê destî (n)"
  },
  {
    "x": "ereba gayan (m)",
    "y": " b. erebeyê gayan (n)"
  },
  {
    "x": "erebê destî (n)",
    "y": " b. erebeyê destî (n)"
  },
  {
    "x": "erebê gayan (n)",
    "y": " b. erebeyê gayan (n)"
  },
  {
    "x": "erebane (n)",
    "y": " kağnı, öküz arabası"
  },
  {
    "x": "erebe (n)",
    "y": " 1)araba\r\n~2)otomobil"
  },
  {
    "x": "erebeyê cigêrayîş û xelisnayîşî",
    "y": " arama kurtarma arabası"
  },
  {
    "x": "erebeyê destî (n)",
    "y": " el arabası"
  },
  {
    "x": "erebeyê gayan (n)",
    "y": " kağnı, öküz arabası"
  },
  {
    "x": "erebeyê şewatî (n)",
    "y": " yangın arabası"
  },
  {
    "x": "erebeyê vewre (n)",
    "y": " kar arabası"
  },
  {
    "x": "erebedar, -e",
    "y": " arabacı"
  },
  {
    "x": "erebedarîye (m)",
    "y": " arabacılık"
  },
  {
    "x": "erebekar, -e",
    "y": " arabacı"
  },
  {
    "x": "erebekarîye (m)",
    "y": " arabacılık"
  },
  {
    "x": "ereberoş, -e",
    "y": " arabacı"
  },
  {
    "x": "ereberoşîye (m)",
    "y": " arabacılık"
  },
  {
    "x": "erebesaz, -e",
    "y": " arabacı"
  },
  {
    "x": "erebesazîye (m)",
    "y": " arabacılık"
  },
  {
    "x": "erebesk (n)",
    "y": " arabesk"
  },
  {
    "x": "erebeşuw, -e",
    "y": " araba yıkayıcısı"
  },
  {
    "x": "Erebîstan (n)",
    "y": " Arabistan"
  },
  {
    "x": "Erebîstanê Seûdî (n)",
    "y": " Suudi Arabistan "
  },
  {
    "x": "erebkî (m)",
    "y": " Arapça"
  },
  {
    "x": "ereciberdiş (n)",
    "y": " benzetme"
  },
  {
    "x": "ereciginayîş (n)",
    "y": " değme-I"
  },
  {
    "x": "erecimendiş (n)",
    "y": " çağrıştırma (andırma)"
  },
  {
    "x": "erecimendîye (m)",
    "y": " benzer"
  },
  {
    "x": "erecişîyayîş (n)",
    "y": " çağrıştırma (andırma)"
  },
  {
    "x": "eregayîamayîş (n)",
    "y": " b. eregayîameyîş (n)"
  },
  {
    "x": "eregayîameyîş (n)",
    "y": " kösnüme (inek için)"
  },
  {
    "x": "erekelamayîş (n)",
    "y": " b. erekelameyîş (n)"
  },
  {
    "x": "erekelameyîş (n)",
    "y": " kösnüme (keçi için)"
  },
  {
    "x": "ereq (n)",
    "y": " ter"
  },
  {
    "x": "ereq ci vistene",
    "y": " terletmek"
  },
  {
    "x": "ereq dayene",
    "y": " terlemek"
  },
  {
    "x": "ereq eştene",
    "y": " terlemek"
  },
  {
    "x": "ereq rokerdene",
    "y": " ter dökmek "
  },
  {
    "x": "ereq rûkerdene",
    "y": " b. ereq rokerdene"
  },
  {
    "x": "ereq vetene",
    "y": " terlemek"
  },
  {
    "x": "ereqê çareyî (n)",
    "y": " alın teri"
  },
  {
    "x": "ereqê çareyî rişnayene",
    "y": " alın teri dökmek"
  },
  {
    "x": "ereqî (m)",
    "y": " b. reqî (m)"
  },
  {
    "x": "ereqîyayene",
    "y": " terlemek"
  },
  {
    "x": "ereqnayene",
    "y": " terletmek"
  },
  {
    "x": "ereqwer, -e",
    "y": " b. reqîwer, -e"
  },
  {
    "x": "ererîvejîyayîş (n)",
    "y": " çemkirme, çemkiriş "
  },
  {
    "x": "eresa (m)",
    "y": " b. ersa (m)"
  },
  {
    "x": "erev, -e",
    "y": " b. ereb, -e"
  },
  {
    "x": "Erevîstan (n)",
    "y": " b. Erebîstan (n)"
  },
  {
    "x": "erevkî (m)",
    "y": " b. erebkî (m)"
  },
  {
    "x": "erexoameyîş (n)",
    "y": " b. roxoameyîş (n)"
  },
  {
    "x": "erey (n)",
    "y": " geç, tehir"
  },
  {
    "x": "erey fîştene",
    "y": " geciktirmek, tehir etmek; ağırdan almak"
  },
  {
    "x": "erey kewtene",
    "y": " gecikmek, geç kalmak"
  },
  {
    "x": "erey kewteni",
    "y": " b. erey kewtene"
  },
  {
    "x": "erey mendene",
    "y": " gecikmek, geç kalmak"
  },
  {
    "x": "erey mendeni",
    "y": " b. erey mendene"
  },
  {
    "x": "erey vistene",
    "y": " b. erey fîştene"
  },
  {
    "x": "ereyfîştiş (n)",
    "y": " geciktirme, tehir etme"
  },
  {
    "x": "ereykewtiş (n)",
    "y": " gecikme, geç kalma"
  },
  {
    "x": "ereymenda (m)",
    "y": " gecikmiş [olan]"
  },
  {
    "x": "ereymende (n)",
    "y": " gecikmiş [olan]"
  },
  {
    "x": "ereymendê (m)",
    "y": " b. ereymenda (m)"
  },
  {
    "x": "ereymendî, -ye",
    "y": " gecikmiş [olan]"
  },
  {
    "x": "ereymendiş (n)",
    "y": " gecikme, geç kalma"
  },
  {
    "x": "ereyvistiş (n)",
    "y": " b. ereyfîştiş (n)"
  },
  {
    "x": "erê (n)",
    "y": " b. berey (n), erey (n)"
  },
  {
    "x": "erê kotene",
    "y": " b. erey kewtene"
  },
  {
    "x": "erêkotiş (n)",
    "y": " b. ereykewtiş (n)"
  },
  {
    "x": "erf û edet",
    "y": " b. urf û adet"
  },
  {
    "x": "erf û odet",
    "y": " b. urf û adet"
  },
  {
    "x": "ergonomî (m)",
    "y": " ergonomi"
  },
  {
    "x": "ergonomîk, -e",
    "y": " kullanışlı, ergonomik"
  },
  {
    "x": "eri (n)",
    "y": " b. urf û adet"
  },
  {
    "x": "erind (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "eriza (m)",
    "y": " b. arize (m)"
  },
  {
    "x": "erî (n)",
    "y": " b. berey (n), erey (n)"
  },
  {
    "x": "erî kûtene",
    "y": " b. erey kewtene"
  },
  {
    "x": "erîkotiş (n)",
    "y": " b. ereykewtiş (n)"
  },
  {
    "x": "erînayene",
    "y": " b. hêrînayene"
  },
  {
    "x": "erînayîş (n)",
    "y": " b. hêrînayîş (n)"
  },
  {
    "x": "erîş (m)",
    "y": " b. erdîşe (m)"
  },
  {
    "x": "erîşe (m)",
    "y": " b. erdîşe (m)"
  },
  {
    "x": "erîşi (m)",
    "y": " b. erdîşe (m)"
  },
  {
    "x": "erîşte (n)",
    "y": " erişte "
  },
  {
    "x": "Erître",
    "y": " Eritre"
  },
  {
    "x": "Erîwan (n)",
    "y": " Erivan"
  },
  {
    "x": "erj (m)",
    "y": " b. erje (m)"
  },
  {
    "x": "erjan, -e",
    "y": " ucuz"
  },
  {
    "x": "erjaney (m)",
    "y": " b. erjanîye (m)"
  },
  {
    "x": "erjanîye (m)",
    "y": " ucuzluk "
  },
  {
    "x": "erjaya (m)",
    "y": " değerli, kıymetli"
  },
  {
    "x": "erjaye (n)",
    "y": " değerli, kıymetli"
  },
  {
    "x": "erjaye kerdene",
    "y": " değerlendirmek, kıymetlendirmek"
  },
  {
    "x": "erjayekerdiş (n)",
    "y": " değerlendirme, kıymetlendirme"
  },
  {
    "x": "erjayene",
    "y": " değmek (herhangi bir nitelikte olmak)\r\n~Erjeno ke ti bivînî. (Görmene değer.)"
  },
  {
    "x": "erjayîş (n)",
    "y": " değme (herhangi bir nitelikte olma)"
  },
  {
    "x": "erje (m)",
    "y": " 1)değer (mat), kıymet\r\n~2)fiyat, paha, bedel"
  },
  {
    "x": "erje panayene",
    "y": " değerlendirmek, kıymetlendirmek"
  },
  {
    "x": "erja tewrkerdîye (m)",
    "y": " katma değer"
  },
  {
    "x": "erja xo vîndî kerdene",
    "y": " cılızlaşmak (mecazen değerini yitirmek)"
  },
  {
    "x": "erjel (n)",
    "y": " b. ecel (n)"
  },
  {
    "x": "erjelê ... ameyene",
    "y": " b. ecelê ... ameyene"
  },
  {
    "x": "erjepanayîş (n)",
    "y": " değerlendirme, kıymetlendirme"
  },
  {
    "x": "erji (m)",
    "y": " b. erje (m)"
  },
  {
    "x": "erjîj (n)",
    "y": " kurşun"
  },
  {
    "x": "erjîs (n)",
    "y": " b. erjîj (n)"
  },
  {
    "x": "erjîyaya (m)",
    "y": " b. erjaya (m)"
  },
  {
    "x": "erjîyaye (n)",
    "y": " b. erjaye (n)"
  },
  {
    "x": "erjîyayîne dari",
    "y": " b. ra dar erzîyayene"
  },
  {
    "x": "erjnayene",
    "y": " değerlendirmek, kıymetlendirmek"
  },
  {
    "x": "erjnayîş (n)",
    "y": " değerlendirme, kıymetlendirme"
  },
  {
    "x": "erjnayîşê çorşmeyî (n)",
    "y": " çevre değerlendirmesi"
  },
  {
    "x": "erjnayîşê dorûverî (n)",
    "y": " çevre değerlendirmesi"
  },
  {
    "x": "erjnayîşê kontrolî (n)",
    "y": " kontrol değerlendirmesi"
  },
  {
    "x": "erjnayîşê rîskî (n)",
    "y": " risk değerlendirmesi"
  },
  {
    "x": "erjnayîşê tesîrê dorûverîyî (n)",
    "y": " çevresel etki değerlendirmesi"
  },
  {
    "x": "erjnayîşê tesîrî (n)",
    "y": " etki değerlendirmesi"
  },
  {
    "x": "erjûn, -i",
    "y": " b. erjan, -e"
  },
  {
    "x": "erjûnê (m)",
    "y": " b. erjanîye (m)"
  },
  {
    "x": "erkanê pêroyî (n)",
    "y": " genel kurmay "
  },
  {
    "x": "erme (n)",
    "y": " b. harme (n)"
  },
  {
    "x": "ermi-I (n)",
    "y": " b. harme (n)"
  },
  {
    "x": "ermi-II (n)",
    "y": " b. emin (n), rim (n)"
  },
  {
    "x": "ermin (n)",
    "y": " b. emin (n), rim (n)"
  },
  {
    "x": "ernayîş (n)",
    "y": " b. hêrînayîş (n)"
  },
  {
    "x": "ernî (n)",
    "y": " b. armenî (n)"
  },
  {
    "x": "ero (vr)",
    "y": " b. ro (vr), ra (vr)"
  },
  {
    "x": "ero ... amayene",
    "y": " b. ro ... ameyene"
  },
  {
    "x": "ero ... amayêne",
    "y": " b. ro ... ameyene"
  },
  {
    "x": "ero ... amayîş (n)",
    "y": " b. ro ... ameyîş"
  },
  {
    "x": "ero ci berdene",
    "y": " b. ere ci berdene"
  },
  {
    "x": "ero ci cêrayene",
    "y": " b. ro ci gêrayene"
  },
  {
    "x": "ero ci eşeqîyene",
    "y": " b. ro ci eşeqîyene"
  },
  {
    "x": "ero ci fetelîyayene",
    "y": " b. ro ci fetilîyayene"
  },
  {
    "x": "ero ci ginayene",
    "y": " b. ere ci ginayene"
  },
  {
    "x": "ero ci mendene",
    "y": " b. ere ci mendene, ro ci mendene"
  },
  {
    "x": "ero ci şîyayene",
    "y": " b. ere ci şîyene, ro ci şîyene"
  },
  {
    "x": "ero ci şîyayêne",
    "y": " b. ere ci şîyene, ro ci şîyene"
  },
  {
    "x": "ero gay amayene",
    "y": " b. ere gayî ameyene"
  },
  {
    "x": "ero … ginayêne",
    "y": " b. ere … ginayene"
  },
  {
    "x": "ero ho nayene",
    "y": " b. ro xo nayene"
  },
  {
    "x": "ero kel amayene",
    "y": " b. ere kel ameyene"
  },
  {
    "x": "ero ser nayene",
    "y": " b. ser nayene "
  },
  {
    "x": "ero ser nîyayene",
    "y": " b. ser nîyayene"
  },
  {
    "x": "ero ... şîkîyayêne",
    "y": " b. ra ... şîkîyayene"
  },
  {
    "x": "ero ... şîkîyayîş n)",
    "y": " b. ra ... şîkîyayîş"
  },
  {
    "x": "ero war amayene",
    "y": " b. ere war ameyene"
  },
  {
    "x": "ero xo nayene",
    "y": " b. ro xo nayene"
  },
  {
    "x": "erociberdiş (n)",
    "y": " b. ereciberdiş (n)"
  },
  {
    "x": "erocicêrayîş (n)",
    "y": " b. rocigêrayîş (n)"
  },
  {
    "x": "erocifetelîyayîş (n)",
    "y": " b. rocifetilîyayîş (n)"
  },
  {
    "x": "erocimendiş (n)",
    "y": " b. erecimendiş (n)"
  },
  {
    "x": "erocimendîye (m)",
    "y": " b. erecimendîye (m)"
  },
  {
    "x": "erocişîyayîş (n)",
    "y": " erecişîyayîş (n)"
  },
  {
    "x": "erogayamayîş (n)",
    "y": " b. eregayîameyîş (n)"
  },
  {
    "x": "erokelamayîş (n)",
    "y": " b. erekelameyîş (n)"
  },
  {
    "x": "erosernîyayîş (n)",
    "y": " b. sernîyayîş (n)"
  },
  {
    "x": "erozyon (n)",
    "y": " erozyon, toprak aşınması, toprak erozyonu"
  },
  {
    "x": "erq (n)",
    "y": " b. ereq (n)"
  },
  {
    "x": "erq dayene",
    "y": " b. ereq dayene"
  },
  {
    "x": "erq rokerdene",
    "y": " b. ereq rokerdene"
  },
  {
    "x": "erq vetene",
    "y": " b. ereq vetene "
  },
  {
    "x": "erqî (m)",
    "y": " b. reqî (m)"
  },
  {
    "x": "erqîyayene",
    "y": " b. ereqîyayene"
  },
  {
    "x": "erqnayene",
    "y": " b. ereqnayene"
  },
  {
    "x": "err tîk tîk",
    "y": " b. er tîk tîk"
  },
  {
    "x": "ersa (m)",
    "y": " 1)arsa\r\n~2)hububat pazarı, tahıl pazarı"
  },
  {
    "x": "erş (n)",
    "y": " arş"
  },
  {
    "x": "erşa (n)",
    "y": " b. eşa (n)"
  },
  {
    "x": "erşawitene",
    "y": " b. şawitene"
  },
  {
    "x": "erşawitiş (n)",
    "y": " b. şawitiş (n)"
  },
  {
    "x": "erşin (n)",
    "y": " arşın"
  },
  {
    "x": "erşun (m)",
    "y": " b. erşin (n)"
  },
  {
    "x": "erugi (m)",
    "y": " êruge (m)"
  },
  {
    "x": "erun (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "erundi (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "erûndi (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "erûni (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "erûz (n)",
    "y": " aruz "
  },
  {
    "x": "erxe (n)",
    "y": " b. êrxe (n"
  },
  {
    "x": "Erxenî (n)",
    "y": " Ergani"
  },
  {
    "x": "erz (n)",
    "y": " sunu, arz"
  },
  {
    "x": "erz bîyayene",
    "y": " b. erz bîyene"
  },
  {
    "x": "erz bîyene",
    "y": " sunulmak"
  },
  {
    "x": "erz kerdene",
    "y": " sunmak"
  },
  {
    "x": "erz û eyal",
    "y": " çoluk çocuk"
  },
  {
    "x": "erzê ... ameyeneberdene",
    "y": " azarlanmak"
  },
  {
    "x": "erzê ... ameyeneşikitene",
    "y": " azarlanmak"
  },
  {
    "x": "erzê ... ameyîşberdiş",
    "y": " azarlanma"
  },
  {
    "x": "erzê ... ameyîşşikitiş",
    "y": " azarlanma"
  },
  {
    "x": "erzê ... berdene",
    "y": " azarlamak, paylamak, tekdir etmek"
  },
  {
    "x": "erzê ... berdiş",
    "y": " azarlama, melamet, paylama, tekdir etme"
  },
  {
    "x": "erzê ... şikitene",
    "y": " azarlamak, paylamak, tekdir etmek"
  },
  {
    "x": "erzê ... şikitiş",
    "y": " azarlama, melamet, paylama, tekdir etme"
  },
  {
    "x": "erzî .... ameyeneşikitene",
    "y": " b. erzê ... ameyeneşikitene "
  },
  {
    "x": "erzî .... ameyîşşikitiş",
    "y": " b. erzê ... ameyîşşikitiş "
  },
  {
    "x": "erzî .... şikitene",
    "y": " b. erzê ... şikitene"
  },
  {
    "x": "erzî .... şikitiş",
    "y": " b. erzê ... şikitiş"
  },
  {
    "x": "erzuhalnuştox, -e",
    "y": " arzuhâlci"
  },
  {
    "x": "erzuhalnuştoxîye (m)",
    "y": " arzuhâlcilik"
  },
  {
    "x": "erza (m)",
    "y": " b. arize (m)"
  },
  {
    "x": "erzan, -e",
    "y": " b. erjan, -e"
  },
  {
    "x": "erzanîye (m)",
    "y": " b. erjanîye (m)"
  },
  {
    "x": "erzbîyayîş (n)",
    "y": " sunulma"
  },
  {
    "x": "erzele-I (n)",
    "y": " b. arzêle-I (n)"
  },
  {
    "x": "erzele-II (n)",
    "y": " b. herzale-I (n)"
  },
  {
    "x": "Erzingan (n)",
    "y": " Erzincan "
  },
  {
    "x": "Erzirom (n)",
    "y": " Erzurum"
  },
  {
    "x": "Erziruem (n)",
    "y": " b. Erzirom (n)"
  },
  {
    "x": "erzîyayene",
    "y": " atılmak"
  },
  {
    "x": "erzîyayene kendîl",
    "y": " asılmak (idam edilmek)"
  },
  {
    "x": "erzîyayene ser",
    "y": " (boğa, koç, teke vb. erkek hayvanlar için) çiftleşmek"
  },
  {
    "x": "erzîyayîne dari",
    "y": " b. ra dar erzîyayene"
  },
  {
    "x": "erzîyayîne kendîlan",
    "y": " b. erzîyayene kendîl"
  },
  {
    "x": "erzîyayîş (n)",
    "y": " 1)atılma\r\n~2)atlama (uzun atlama)"
  },
  {
    "x": "erzkerdiş (n)",
    "y": " sunma"
  },
  {
    "x": "erzuhal (n)",
    "y": " dilekçe, arzuhâl"
  },
  {
    "x": "erzuhalê dewa (n)",
    "y": " dava dilekçesi, erzuhalo cewabî (n). cevap dilekçesi"
  },
  {
    "x": "erzuhalo cewabî (n). cevap dilekçesi"
  },
  {
    "x": "es û ’esbeti (m)",
    "y": " b. esl û esbete (m)"
  },
  {
    "x": "esa (m)",
    "y": " asa, baston"
  },
  {
    "x": "esabî, -ye",
    "y": " b. esebî, -ye"
  },
  {
    "x": "esahî kerdene",
    "y": " kontrol etmek, yoklama yapmak, yoklamak"
  },
  {
    "x": "esahîkerdiş (n)",
    "y": " kontrol, kontrol etme, yoklama"
  },
  {
    "x": "esalet (n)",
    "y": " asalet, asilzadelik, soyluluk"
  },
  {
    "x": "esas (n)",
    "y": " asıl, temel, esas"
  },
  {
    "x": "esbabê mucîbeyî (n)",
    "y": " gerekçe"
  },
  {
    "x": "esebî, -ye",
    "y": " asabi, sinirli"
  },
  {
    "x": "esebî bîyene",
    "y": " asabileşmek"
  },
  {
    "x": "esebîbîyayîş (n)",
    "y": " asabileşme"
  },
  {
    "x": "eser (n)",
    "y": " 1)ürün\r\n~2)eser, yapıt\r\n~esero edebî (n): edebi ürün, edebi eser"
  },
  {
    "x": "esero edebî (n)",
    "y": " edebi ürün, edebi eser"
  },
  {
    "x": "eserê tebîîyî (zh)",
    "y": " doğal ürünler"
  },
  {
    "x": "eserê xozayî (zh)",
    "y": " doğal ürünler"
  },
  {
    "x": "esernayene",
    "y": " b. esirnayene"
  },
  {
    "x": "esirnayene",
    "y": " ezmek (üzüm vb. için)"
  },
  {
    "x": "esirnayîş (n)",
    "y": " ezme"
  },
  {
    "x": "esîd (m)",
    "y": " b. esîre (m)"
  },
  {
    "x": "esîde (m)",
    "y": " b. esîre (m)"
  },
  {
    "x": "esîl, -e",
    "y": " asil, soylu"
  },
  {
    "x": "esîlî (m)",
    "y": " asalet, asilzadelik, soyluluk"
  },
  {
    "x": "esîlîye (m)",
    "y": " asalet, asilzadelik, soyluluk"
  },
  {
    "x": "esîlzada (m)",
    "y": " asilzade¸ soylu"
  },
  {
    "x": "esîlzade, -ye",
    "y": " asilzade, soylu"
  },
  {
    "x": "esîlzadeyî (m)",
    "y": " asalet, asilzadelik, soyluluk"
  },
  {
    "x": "esîlzadeyîye (m)",
    "y": " asalet, asilzadelik, soyluluk"
  },
  {
    "x": "esîre (m)",
    "y": " 1)pekmez ve undan yapılan tatlı bulamaç\r\n~2)pekmez veya şeker ile undan yapılan ve üzerine kızartılmış yağ dökülerek yenen tatlı bulamaç\r\n~3)su ve undan yapılan, içine yağ ve pekmez katılarak da yenebilen bulamaç"
  },
  {
    "x": "esîra tirênaya (m)",
    "y": " kavrulmuş un ile yağ, pekmez veya şekerden yapılan bir çeşit helva"
  },
  {
    "x": "esîra tirnaya (m)",
    "y": " b. esîra tirênaya (m)"
  },
  {
    "x": "esîra vêkan (m)",
    "y": " kavrulmuş un ile yağ, pekmez veya şekerden yapılan bir çeşit helva"
  },
  {
    "x": "esîra vêkon (m)",
    "y": " b. esîra vêkan (m)"
  },
  {
    "x": "esîri (m)",
    "y": " b. esîre (m)"
  },
  {
    "x": "eskava (n)",
    "y": " b. eşkeva (n)"
  },
  {
    "x": "eskef (n)",
    "y": " b. şikefte (m), eşkefte (m)"
  },
  {
    "x": "eskeft (n)",
    "y": " b. şikefte (m), eşkefte (m)"
  },
  {
    "x": "eskefte (m)",
    "y": " b. şikefte (m), eşkefte (m)"
  },
  {
    "x": "eskence (n)",
    "y": " b. îşkence (n)"
  },
  {
    "x": "esker, -e",
    "y": " asker"
  },
  {
    "x": "eskera (s)",
    "y": " b. eşkera (s)"
  },
  {
    "x": "eskera bîyene",
    "y": " b. eşkera bîyene"
  },
  {
    "x": "eskera kerdene",
    "y": " b. eşkera kerdene"
  },
  {
    "x": "eskerey (m)",
    "y": " b. eskerî (m), eskerîye (m)"
  },
  {
    "x": "eskerê (m)",
    "y": " b. eskerîye (m)"
  },
  {
    "x": "eskerî, -ye",
    "y": " askeri"
  },
  {
    "x": "eskerîye (m)",
    "y": " askerlik"
  },
  {
    "x": "eskerîyê (m)",
    "y": " b. eskerîye (m)"
  },
  {
    "x": "eskete (m)",
    "y": " b. şikefte (m), eşkefte (m)"
  },
  {
    "x": "eskici (m)",
    "y": " b. eskije (m) "
  },
  {
    "x": "eskije (m)",
    "y": " kuru üzüm"
  },
  {
    "x": "eskiji (m)",
    "y": " b. eskije (m)"
  },
  {
    "x": "eskince (n)",
    "y": " b. îşkence (n)"
  },
  {
    "x": "eskize (m)",
    "y": " b. eskije (m)"
  },
  {
    "x": "eskura (m)",
    "y": " b. uskura (m)"
  },
  {
    "x": "esl (n)",
    "y": " 1)asıl\r\n~2)soy"
  },
  {
    "x": "esl û esbete (m)",
    "y": " soy sop"
  },
  {
    "x": "esl û ezbete (m)",
    "y": " b. esl û esbete (m)"
  },
  {
    "x": "esl û fesl (n)",
    "y": " soy sop"
  },
  {
    "x": "esl û nesl (n)",
    "y": " soy sop"
  },
  {
    "x": "esla",
    "y": " asla, hiçbir zaman, hiç, katiyen"
  },
  {
    "x": "eslen",
    "y": " b. esla"
  },
  {
    "x": "esleten",
    "y": " b. esla"
  },
  {
    "x": "esmar",
    "y": " b. emser"
  },
  {
    "x": "esmer",
    "y": " b. emser "
  },
  {
    "x": "esmo",
    "y": " b. emşo "
  },
  {
    "x": "esmû",
    "y": " b. emşo"
  },
  {
    "x": "esnaf, -e",
    "y": " esnaf"
  },
  {
    "x": "esnawber, -e",
    "y": " b. asnawber, -e"
  },
  {
    "x": "esnawer, -i",
    "y": " b. asnawber, -e"
  },
  {
    "x": "esnawerey (m)",
    "y": " b. asnawberî (m)"
  },
  {
    "x": "esnawi (m)",
    "y": " b. asnawe (m)"
  },
  {
    "x": "esnawi kerdene",
    "y": " b. asnawe kerdene"
  },
  {
    "x": "esnawikerdiş (n)",
    "y": " b. asnawekerdiş (n)"
  },
  {
    "x": "esnayini",
    "y": " b. hesnayene, eşnawitene"
  },
  {
    "x": "espanax (n)",
    "y": " b. îspenax (n)"
  },
  {
    "x": "espar, -e",
    "y": " atlı, süvari"
  },
  {
    "x": "espar bîyene",
    "y": " binmek"
  },
  {
    "x": "espar kerdene",
    "y": " bindirmek"
  },
  {
    "x": "esparbîyayîş (n)",
    "y": " binme"
  },
  {
    "x": "esparkar, -e",
    "y": " cokey"
  },
  {
    "x": "esparkarî (m)",
    "y": " cokeylik"
  },
  {
    "x": "esparkarîye (m)",
    "y": " cokeylik"
  },
  {
    "x": "esparkerdiş (n)",
    "y": " bindirme"
  },
  {
    "x": "espe (n)",
    "y": " b. sipe (n)"
  },
  {
    "x": "espici (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "espicin, -e",
    "y": " b. espijin, -e"
  },
  {
    "x": "espicî (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "espije (m)",
    "y": " bit"
  },
  {
    "x": "espije pa bîyene",
    "y": " bitli olmak"
  },
  {
    "x": "espijin, -e",
    "y": " bitli"
  },
  {
    "x": "espijin bîyene",
    "y": " bitli olmak"
  },
  {
    "x": "espijini (m)",
    "y": " b. espijin, -e"
  },
  {
    "x": "espijî (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "espijî ci kewtene",
    "y": " bitlenmek"
  },
  {
    "x": "espijî dekewtene",
    "y": " bitlenmek"
  },
  {
    "x": "espijîcikewtiş (n)",
    "y": " bitlenme"
  },
  {
    "x": "espijîdekewtiş (n)",
    "y": " bitlenme"
  },
  {
    "x": "esq (n)",
    "y": " b. eşq (n)"
  },
  {
    "x": "esral (n)",
    "y": " b. esrar (n)"
  },
  {
    "x": "esrar (n)",
    "y": " hintkeneviri"
  },
  {
    "x": "esrî, -ye",
    "y": " çağdaş, asri, muasır, çağcıl, modern, muasır"
  },
  {
    "x": "esrî bîyene",
    "y": " çağcıllaşmak, çağdaşlaşmak, asrileşmek, modernleşmek, muasırlaşmak"
  },
  {
    "x": "esrî kerdene",
    "y": " çağcıllaştırmak, asrileştirmek, çağdaşlaştırmak, modernleştirmek, muasırlaştırmak"
  },
  {
    "x": "est (n, m)",
    "y": " var\r\n~Yew kênaya aye est a. (Onun bir kızı var.)\r\n~\t\r\n~Yew lajê aye est o. (Onun bir oğlu var.)"
  },
  {
    "x": "est bîyene",
    "y": " mevcut olmak, var olmak"
  },
  {
    "x": "esta (m)",
    "y": " mevcut"
  },
  {
    "x": "estaene",
    "y": " b. eştene"
  },
  {
    "x": "estaîs (n)",
    "y": " b. eştiş (n)"
  },
  {
    "x": "estanbarî (m)",
    "y": " b. asnawe (m)"
  },
  {
    "x": "estaneke (m)",
    "y": " masal "
  },
  {
    "x": "estaneki (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "estanekkî",
    "y": " masalımsı"
  },
  {
    "x": "estanik (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "estanike (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "estaniki (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "estar (n)",
    "y": " b. astar (n)"
  },
  {
    "x": "estare (n)",
    "y": " yıldız"
  },
  {
    "x": "estareke (m)",
    "y": " ateşböceği, ışılböcek, ışılböceği"
  },
  {
    "x": "estarelîlik (n)",
    "y": " ateşböceği"
  },
  {
    "x": "estarenas, -e",
    "y": " astrolog, müneccim"
  },
  {
    "x": "estarenasî (m)",
    "y": " astroloji"
  },
  {
    "x": "estarenasîye (m)",
    "y": " astroloji"
  },
  {
    "x": "estari (n)",
    "y": " b. estare (n)"
  },
  {
    "x": "estarin, -e",
    "y": " b. astarin, -e"
  },
  {
    "x": "estaritene",
    "y": " b. estiritene"
  },
  {
    "x": "estaritiş (n)",
    "y": " b. estiritiş (n)"
  },
  {
    "x": "estarkerda (m)",
    "y": " b. astarkerda (m)"
  },
  {
    "x": "estarkerde (n)",
    "y": " b. astarkerde (n)"
  },
  {
    "x": "estarkerdê (m)",
    "y": " b. astarkerda (m), astarkerdîye (m)"
  },
  {
    "x": "estarkerdi (n)",
    "y": " b. astarkerde (n)"
  },
  {
    "x": "estawrine (m)",
    "y": " b. estewrine (m)"
  },
  {
    "x": "estayene",
    "y": " b. eştene"
  },
  {
    "x": "estayîs (n)",
    "y": " b. eştiş (n)"
  },
  {
    "x": "estbîyayîş (n)",
    "y": " var olma, mevcut olma, varoluş"
  },
  {
    "x": "este-I (n)",
    "y": " kemik"
  },
  {
    "x": "este-I (n)",
    "y": " mevcut"
  },
  {
    "x": "este-III (n)",
    "y": " b. eşte (n)"
  },
  {
    "x": "estebîyayîş (n)",
    "y": " kemik üremesi"
  },
  {
    "x": "Estembul (n)",
    "y": " b. Îstanbul (n)"
  },
  {
    "x": "Estemol (n)",
    "y": " b. Îstanbul (n)"
  },
  {
    "x": "estene-I",
    "y": " var olmak, mevcut olmak\r\n~Uca darî est bîy. (Orada ağaçlar vardı.)\r\n~Uca engure est bî. (Orada üzüm vardı.)\r\n~Uca nan est bi. (Orada ekmek vardı.)"
  },
  {
    "x": "estene-II",
    "y": " b. eştene"
  },
  {
    "x": "estene qenter",
    "y": " b. eştene qenter"
  },
  {
    "x": "esterdene",
    "y": " b. estiritene"
  },
  {
    "x": "estere-I (n)",
    "y": " b. estare (n)"
  },
  {
    "x": "estere-II (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "estereyin",
    "y": " b. estrîyin, -e"
  },
  {
    "x": "esteri (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "esteri (n)",
    "y": " b. hesterî (n)"
  },
  {
    "x": "esteritene",
    "y": " b. estiritene"
  },
  {
    "x": "esteritiş (n)",
    "y": " b. estiritiş (n)"
  },
  {
    "x": "esteriyin",
    "y": " b. estrîyin, -e"
  },
  {
    "x": "esterî (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "esterî purye dayîne",
    "y": " b. estrî piro dayene"
  },
  {
    "x": "estertene",
    "y": " b. estiritene"
  },
  {
    "x": "estertiş (n)",
    "y": " b. estiritiş (n)"
  },
  {
    "x": "estetîk (n)",
    "y": " estetik"
  },
  {
    "x": "estetîkê belgeyîyî (sn)",
    "y": " belgesel estetiği"
  },
  {
    "x": "estetîkê dokumanterî (sn)",
    "y": " belgesel estetiği"
  },
  {
    "x": "estetîsyen, -e",
    "y": " estetikçi, estetisyen"
  },
  {
    "x": "estewre (m)",
    "y": " kısır (dişi hayvan için)"
  },
  {
    "x": "estewrin (m)",
    "y": " b. estewrine (m)"
  },
  {
    "x": "estewrine (m)",
    "y": " kısır (dişi hayvan için)"
  },
  {
    "x": "estewriney (m)",
    "y": " b. estewrinîye (m)"
  },
  {
    "x": "estewrinênî (m)",
    "y": " kısırlık (dişi hayvan için)"
  },
  {
    "x": "estewrini (m)",
    "y": " b. estewrine (m)"
  },
  {
    "x": "estewrinî (m)",
    "y": " kısırlık (dişi hayvan için)"
  },
  {
    "x": "estewrinîye (m)",
    "y": " kısırlık (dişi hayvan için)"
  },
  {
    "x": "estey (m)",
    "y": " b. estîye (m)"
  },
  {
    "x": "esteyên, -e",
    "y": " kemikten yapılma"
  },
  {
    "x": "esteyin, -e",
    "y": " kemikli"
  },
  {
    "x": "esti-I (n)",
    "y": " b. este-I (n)"
  },
  {
    "x": "esti-II (n)",
    "y": " b. astî (n)"
  },
  {
    "x": "estik (n)",
    "y": " b. este-I (n)"
  },
  {
    "x": "estire (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "estireyin",
    "y": " b. estrîyin, -e"
  },
  {
    "x": "estiri (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "estiritene",
    "y": " silmek"
  },
  {
    "x": "estiritiş (n)",
    "y": " silme"
  },
  {
    "x": "estiriyin",
    "y": " b. estrîyin, -e"
  },
  {
    "x": "estirî (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "estiş-I (n)",
    "y": " 1)var olma, mevcut olma, varoluş, mevcudiyet, egzistans\r\n~2)varlık"
  },
  {
    "x": "estiş-II (n)",
    "y": " b. eştiş (n)"
  },
  {
    "x": "estî-I (m)",
    "y": " mevcudiyet, varlık, egzistans"
  },
  {
    "x": "estî-II (n)",
    "y": " b. astî (n)"
  },
  {
    "x": "estîyê xo to kerdene",
    "y": " b. astîyê xo to kerdene"
  },
  {
    "x": "estîyî to kerdene",
    "y": " b. astîyî to kerdene"
  },
  {
    "x": "estîyo derg (n)",
    "y": " b. astîyo derg (n)"
  },
  {
    "x": "estîye-I (m)",
    "y": " varlık"
  },
  {
    "x": "estîye-II (n)",
    "y": " b. eştîye (m)"
  },
  {
    "x": "estoni (m)",
    "y": " b. estune (m)"
  },
  {
    "x": "estoneke (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "estonike (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "Estonya (m)",
    "y": " Estonya"
  },
  {
    "x": "estor, -e",
    "y": " at"
  },
  {
    "x": "estor/e bacnayene",
    "y": " atı çatlatmak (ölmesine neden olmak)"
  },
  {
    "x": "estor/e bojnayene",
    "y": " b. estor/e bacnayene"
  },
  {
    "x": "estor/e fetisnayene",
    "y": " atı çatlatmak (ölmesine neden olmak)"
  },
  {
    "x": "estor/e vazdaynayene",
    "y": " b. estor/e vazdnayene"
  },
  {
    "x": "estor/e vazdnayene",
    "y": " at koşturmak, yarıştırmak (at yarıştırmak)"
  },
  {
    "x": "estor/e vaznayene",
    "y": " b. estor/e vazdnayene"
  },
  {
    "x": "estor/e visnayene",
    "y": " atı çatlatmak (ölmesine neden olmak)"
  },
  {
    "x": "estor/e xar dayene",
    "y": " at koşturmak"
  },
  {
    "x": "estor/estori bajnayene",
    "y": " b. estor/e bacnayene"
  },
  {
    "x": "estorê deryayî (n)",
    "y": " denizatı"
  },
  {
    "x": "estorê nîlî (n)",
    "y": " suaygırı"
  },
  {
    "x": "estorê Troya (n) (TE)",
    "y": " Truva atı"
  },
  {
    "x": "estore (m)",
    "y": " 1)dişi at\r\n~2)kısrak"
  },
  {
    "x": "estori (m)",
    "y": " b. estore (m)"
  },
  {
    "x": "estowrin (m)",
    "y": " b. estewrine (m)"
  },
  {
    "x": "estowriney (m)",
    "y": " b. estewrinî (m)"
  },
  {
    "x": "estre (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "estreyin",
    "y": " b. estrîyin, -e"
  },
  {
    "x": "estrî (n)",
    "y": " boynuz"
  },
  {
    "x": "estrî kerdene",
    "y": " boynuzlamak, süsmek"
  },
  {
    "x": "estrî pede kerdene",
    "y": " boynuzlamak, süsmek"
  },
  {
    "x": "estrî piro dayene",
    "y": " boynuzlamak, süsmek"
  },
  {
    "x": "estrî re ci estene",
    "y": " b. estrî re ci eştene"
  },
  {
    "x": "estrî re ci eştene",
    "y": " boynuzlamak, süsmek"
  },
  {
    "x": "estrîyin, -e",
    "y": " boynuzlu"
  },
  {
    "x": "estuar",
    "y": " b. estor, -e "
  },
  {
    "x": "estuer",
    "y": " b. estor, -e "
  },
  {
    "x": "estuin (m)",
    "y": " b. estune (m)"
  },
  {
    "x": "estun (m)",
    "y": " b. estune (m)"
  },
  {
    "x": "estune (m)",
    "y": " 1)sütun, kolon, direk\r\n~2)kolon (basın için), sütun (basın için)"
  },
  {
    "x": "estunkarî (m)",
    "y": " kolonaj (basın için)"
  },
  {
    "x": "esturî (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "esturî kerdene",
    "y": " b. estrî kerdene"
  },
  {
    "x": "esturîyin",
    "y": " b. estrîyin, -e"
  },
  {
    "x": "estûn (m)",
    "y": " b. estune (m)"
  },
  {
    "x": "estûne (m)",
    "y": " b. estune (m)"
  },
  {
    "x": "esxerî",
    "y": " en az, asgari"
  },
  {
    "x": "eşa (n)",
    "y": " yatsı"
  },
  {
    "x": "eşaret (n)",
    "y": " b. îşaret (n)"
  },
  {
    "x": "eşaret bîyene",
    "y": " b. îşaret bîyene"
  },
  {
    "x": "eşaret kerdene",
    "y": " b. îşaret kerdene"
  },
  {
    "x": "eşbabî (m)",
    "y": " çir (kurutulmuş kayısı)"
  },
  {
    "x": "eşerî, -ye",
    "y": " b. heşerî, -ye"
  },
  {
    "x": "eşerî bîyayene",
    "y": " b. heşerî bîyene"
  },
  {
    "x": "eşêf (m)",
    "y": " b. eşêfe (m)"
  },
  {
    "x": "eşêfe (m)",
    "y": " tarla, bostan vb. yerlerdeki yabani otları ayıklama işi"
  },
  {
    "x": "eşêfe kerdene",
    "y": " tarla, bostan vb. yerlerdeki yabani otları ayıklamak"
  },
  {
    "x": "eşêfekerdiş (n)",
    "y": " tarla, bostan vb. yerlerdeki yabani otları ayıklama"
  },
  {
    "x": "eşêfi (m)",
    "y": " b. eşêfe (m)"
  },
  {
    "x": "eşêfi kardene",
    "y": " b. eşêfe kerdene"
  },
  {
    "x": "eşêfi kerdene",
    "y": " b. eşêfe kerdene"
  },
  {
    "x": "eşêfikerdiş (n)",
    "y": " b. eşêfekerdiş (n)"
  },
  {
    "x": "eşêfkardiş (n)",
    "y": " b. eşêfekerdiş (n)"
  },
  {
    "x": "eşfe (n)",
    "y": " çapa"
  },
  {
    "x": "eşiq (n)",
    "y": " b. aşiq (n)"
  },
  {
    "x": "eşiq bîyene",
    "y": " b. aşiq bîyene"
  },
  {
    "x": "eşiqîyayene ra ci",
    "y": " -e âşık olmak"
  },
  {
    "x": "eşîr (m)",
    "y": " b. eşîre (m)"
  },
  {
    "x": "eşîre (m)",
    "y": " aşiret "
  },
  {
    "x": "eşîret (m)",
    "y": " b. eşîre (m)"
  },
  {
    "x": "eşîreti (m)",
    "y": " b. eşîre (m)"
  },
  {
    "x": "eşîri (m)",
    "y": " b. eşîre (m)"
  },
  {
    "x": "eşkara (s)",
    "y": " b. eşkera (s)"
  },
  {
    "x": "eşkara bîyayene",
    "y": " b. eşkera bîyene"
  },
  {
    "x": "eşkara kerdene",
    "y": " b. eşkera kerdene"
  },
  {
    "x": "eşkayene",
    "y": " edebilmek, yapabilmek, muktedir olmak"
  },
  {
    "x": "eşkayîş (n)",
    "y": " edebilme, yapabilme, muktedir olma"
  },
  {
    "x": "eşkeba (n)",
    "y": " b. eşkeva (n)"
  },
  {
    "x": "eşkefte (m)",
    "y": " mağara"
  },
  {
    "x": "eşkera (s)",
    "y": " aşikâr, apaçık, açıkça, açıktan açığa; afişe, bariz, belirgin"
  },
  {
    "x": "eşkera bîyene",
    "y": " aşikâr olmak, belirginleşmek"
  },
  {
    "x": "eşkera kerdene",
    "y": " aşikâr etmek, afişe etmek"
  },
  {
    "x": "eşkerabîyayîş (n)",
    "y": " aşikâr olma, belirginleşme"
  },
  {
    "x": "eşkerakerdiş (n)",
    "y": " aşikâr etme, afişe etme"
  },
  {
    "x": "eşkerayî (m)",
    "y": " apaçıklık"
  },
  {
    "x": "eşkerayîye (m)",
    "y": " apaçıklık"
  },
  {
    "x": "eşkeva (n)",
    "y": " mayasız ekmek"
  },
  {
    "x": "eşkewa (n)",
    "y": " b. eşkeva (n)"
  },
  {
    "x": "eşkice (m)",
    "y": " b. eskije (m) "
  },
  {
    "x": "eşkici (m)",
    "y": " b. eskije (m) "
  },
  {
    "x": "eşkiji (m)",
    "y": " b. eskije (m) "
  },
  {
    "x": "eşkira (s)",
    "y": " b. eşkera (s)"
  },
  {
    "x": "eşkira bîyayene",
    "y": " b. eşkera bîyene"
  },
  {
    "x": "eşkira kerdene",
    "y": " b. eşkera kerdene"
  },
  {
    "x": "eşkuçi (m)",
    "y": " b. eskije (m)"
  },
  {
    "x": "eşmo",
    "y": " b. emşo"
  },
  {
    "x": "eşmû",
    "y": " b. emşo"
  },
  {
    "x": "eşnawitene",
    "y": " işitmek, duymak"
  },
  {
    "x": "to eşnawito/to hesnayo",
    "y": " sen işitmişsin, sen duymuşsun"
  },
  {
    "x": "eşnawitiş (n)",
    "y": " işitme, duyma"
  },
  {
    "x": "eşnawutene",
    "y": " b. eşnawitene"
  },
  {
    "x": "eşnawutiş (n)",
    "y": " b. eşnawitiş (n)"
  },
  {
    "x": "eşofman (n)",
    "y": " eşofman"
  },
  {
    "x": "eşpij (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "eşpiji (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "eşpijî ci kewtene",
    "y": " b. espijî ci kewtene"
  },
  {
    "x": "eşpijî dekewtene",
    "y": " b. espijî dekewtene"
  },
  {
    "x": "eşpijin, -i",
    "y": " b. espijin, -e"
  },
  {
    "x": "eşpijîcikewtiş (n)",
    "y": " b. espijîcikewtiş (n)"
  },
  {
    "x": "eşpijîdekewtiş (n)",
    "y": " b. espijîdekewtiş (n)"
  },
  {
    "x": "eşpiş (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "eşpişin, -i",
    "y": " b. espijin, -e"
  },
  {
    "x": "eşq (n)",
    "y": " aşk"
  },
  {
    "x": "Eşqabad (n)",
    "y": " Aşkabat"
  },
  {
    "x": "Eşqere (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "Eşqeri (m)",
    "y": " b. Eşqere (m)"
  },
  {
    "x": "eşqî (n, m)",
    "y": " b. eşqîya (n, m)"
  },
  {
    "x": "eşqîya (n, m)",
    "y": " eşkıya"
  },
  {
    "x": "eşrawitiş (n)",
    "y": " b. şawitiş (n)"
  },
  {
    "x": "eşta (m)",
    "y": " atık"
  },
  {
    "x": "eşte (n)",
    "y": " atık"
  },
  {
    "x": "eşteyê bazirganî (zh)",
    "y": " ticari atıklar"
  },
  {
    "x": "eşteyê bêtehlukey (zh)",
    "y": " tehlikesiz atıklar"
  },
  {
    "x": "eşteyê bêxeterî (zh)",
    "y": " tehlikesiz atıklar"
  },
  {
    "x": "eşteyê lojine (zh)",
    "y": " baca atıkları"
  },
  {
    "x": "eşteyê madenkarîye (zh)",
    "y": " madencilik atıkları"
  },
  {
    "x": "eşteyê pixêrî (zh)",
    "y": " baca atıkları"
  },
  {
    "x": "eşteyê tehlukedarî (zh)",
    "y": " tehlikeli atıklar"
  },
  {
    "x": "eşteyê tîcarî (zh)",
    "y": " ticari atıklar"
  },
  {
    "x": "eşteyê xeternakî (zh)",
    "y": " tehlikeli atıklar"
  },
  {
    "x": "eşteyo herikbar (n)",
    "y": " akışkan atık"
  },
  {
    "x": "eşteyo huşk (n)",
    "y": " katı atık"
  },
  {
    "x": "eşteyo radyoaktîf (n)",
    "y": " radyoaktif atık"
  },
  {
    "x": "eşteyo tibî (n)",
    "y": " tıbbi atık"
  },
  {
    "x": "eştene",
    "y": " 1)atmak\r\n~2)seğirmek (göz için)\r\n~3)doğmak (güneş, ay ve yıldızlar için)"
  },
  {
    "x": "eştene bazar",
    "y": " pazarlamak"
  },
  {
    "x": "eştene fekê tirpanan ver",
    "y": " tırpanlamak"
  },
  {
    "x": "eştene kendîl",
    "y": " asmak (idam etmek)"
  },
  {
    "x": "eştene kendîlan",
    "y": " b. eştene kendîl"
  },
  {
    "x": "eştene qenter",
    "y": " kantarlamak, kantara vurmak, kantara çekmek"
  },
  {
    "x": "eştene ser",
    "y": " (keçi, koyun, at, eşek için) çiftleştirmek"
  },
  {
    "x": "eştene ... ser",
    "y": " b. ser eştene"
  },
  {
    "x": "eşteni",
    "y": " b. eştene"
  },
  {
    "x": "eşteni dari",
    "y": " b. ra dar eştene"
  },
  {
    "x": "eştiş (n)",
    "y": " atma"
  },
  {
    "x": "eştişbazar (n)",
    "y": " pazarlama"
  },
  {
    "x": "eştişser (n)",
    "y": " b. sereştiş (n)"
  },
  {
    "x": "eştîye (m)",
    "y": " atık"
  },
  {
    "x": "eştîye (m)",
    "y": " atık"
  },
  {
    "x": "eşya (n)",
    "y": " eşya"
  },
  {
    "x": "eşyayê xemlî (n)",
    "y": " takı"
  },
  {
    "x": "eşyayê zîynetî (n)",
    "y": " takı"
  },
  {
    "x": "@  et (TE)",
    "y": " @  et"
  },
  {
    "x": "eta (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "etajer (n)",
    "y": " etajer"
  },
  {
    "x": "etap (n)",
    "y": " aşama"
  },
  {
    "x": "etapê projeyî (n)",
    "y": " proje aşaması"
  },
  {
    "x": "etapê prosesî (m)",
    "y": " proses aşaması"
  },
  {
    "x": "etay (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "ete (m)",
    "y": " abla"
  },
  {
    "x": "etê",
    "y": " 1)abla (hitap hali)\r\n~2)b. etêyî (m)"
  },
  {
    "x": "eteg (n)",
    "y": " etek (kadın eteği)"
  },
  {
    "x": "etego derg (n)",
    "y": " maksi etek"
  },
  {
    "x": "etego kilm (n)",
    "y": " mini etek"
  },
  {
    "x": "etego kirr (n)",
    "y": " mini etek"
  },
  {
    "x": "etego maksî (n)",
    "y": " maksi etek"
  },
  {
    "x": "etego mînî (n)",
    "y": " mini etek"
  },
  {
    "x": "etego pantolkî (n)",
    "y": " pantolon etek"
  },
  {
    "x": "etego teng (n)",
    "y": " dar etek"
  },
  {
    "x": "eteg (m)",
    "y": " b. eteg (n)"
  },
  {
    "x": "etega derg (m)",
    "y": " b. etego derg (n)"
  },
  {
    "x": "etega maksî (m)",
    "y": " b. etego maksî (n)"
  },
  {
    "x": "etega mînî (m)",
    "y": " b. etego mînî (n)"
  },
  {
    "x": "etega teng (m)",
    "y": " b. etego teng (n)"
  },
  {
    "x": "etek (n)",
    "y": " b. eteg (n)"
  },
  {
    "x": "etêyî (m)",
    "y": " ablalık"
  },
  {
    "x": "etf (n)",
    "y": " b. etif (n)"
  },
  {
    "x": "etf kerdene",
    "y": " b. etif kerdene"
  },
  {
    "x": "etfkerdiş (n)",
    "y": " b. etifkerdiş (n)"
  },
  {
    "x": "eti (m)",
    "y": " b. ete (m)"
  },
  {
    "x": "etif-I (n)",
    "y": " taraş (üzüm için)"
  },
  {
    "x": "etif kerdene",
    "y": " taraşlamak (üzüm için)"
  },
  {
    "x": "etif-II (n)",
    "y": " atıf"
  },
  {
    "x": "etifkerdiş (n)",
    "y": " taraşlama (üzüm için)"
  },
  {
    "x": "etike (m)",
    "y": " abla"
  },
  {
    "x": "etikey (m)",
    "y": " b. etikîye (m)"
  },
  {
    "x": "etikê-I",
    "y": " abla (hitap hali)"
  },
  {
    "x": "etikê-II (m)",
    "y": " b. etikîye (m)"
  },
  {
    "x": "etiki (m)",
    "y": " b. etike (m)"
  },
  {
    "x": "etikî (m)",
    "y": " ablalık"
  },
  {
    "x": "etikîye (m)",
    "y": " ablalık"
  },
  {
    "x": "etîk (n)",
    "y": " etik, ahlak bilimi, ahlakiyat, ilmiahlak"
  },
  {
    "x": "etîkê belgeyîyî (sn)",
    "y": " belgesel etiği"
  },
  {
    "x": "etîkê dokumanterî (sn)",
    "y": " belgesel etiği"
  },
  {
    "x": "etîka (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "etîket (n)",
    "y": " etîket"
  },
  {
    "x": "etîket bîyene",
    "y": " etiketlenmek"
  },
  {
    "x": "etîket kerdene",
    "y": " etiketlemek"
  },
  {
    "x": "etîket panayene",
    "y": " etiketlemek"
  },
  {
    "x": "etîket panîyayene",
    "y": " etiketlenmek"
  },
  {
    "x": "etîketbîyayîş (n)",
    "y": " etiketlenme"
  },
  {
    "x": "etîketkerdiş (n)",
    "y": " etiketleme"
  },
  {
    "x": "etîketpanayîş (n)",
    "y": " etiketleme"
  },
  {
    "x": "etîketpanîyayîş (n)",
    "y": " etiketlenme"
  },
  {
    "x": "etîmolog, -e",
    "y": " kökenbilimci, etimolog"
  },
  {
    "x": "etîmolojî (n)",
    "y": " kökenbilim, etimoloji"
  },
  {
    "x": "etîmolojîk, -e",
    "y": " kökenbilimsel, etimolojik"
  },
  {
    "x": "etîolojî (n)",
    "y": " nedenbilim, etioloji"
  },
  {
    "x": "etîya (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "etîye (m)",
    "y": " ablalık"
  },
  {
    "x": "etlas (n)",
    "y": " b. atlas (n)"
  },
  {
    "x": "etlasi (m)",
    "y": " b. atlase (m)"
  },
  {
    "x": "etles (n)",
    "y": " b. atlas (n)"
  },
  {
    "x": "etlesi (m)",
    "y": " b. atlase (m)"
  },
  {
    "x": "etnîk, -e",
    "y": " etnik, kavmi"
  },
  {
    "x": "etnolog, -e",
    "y": " budunbilimci, etnolog"
  },
  {
    "x": "etnolojî (n)",
    "y": " budunbilim, ırkiyat, etnoloji"
  },
  {
    "x": "etud (n)",
    "y": " inceleme, tetkik, etüt"
  },
  {
    "x": "etud kerdene",
    "y": " gözden geçirmek, incelemek"
  },
  {
    "x": "etudkerdiş (n)",
    "y": " gözden geçirme, inceleme"
  },
  {
    "x": "etudkerdox, -e",
    "y": " incelemeci"
  },
  {
    "x": "etya (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "Etyopya (m)",
    "y": " Etiyopya"
  },
  {
    "x": "evd, -e",
    "y": " köle, kul, abd"
  },
  {
    "x": "evdal (n)",
    "y": " 1)abdal\r\n~2)(mecazi) zavallı"
  },
  {
    "x": "evdaley (m)",
    "y": " (mecazi) evdalîye (m)"
  },
  {
    "x": "evdalê (m)",
    "y": " b. (mecazi) evdalî (m)"
  },
  {
    "x": "evdali (m)",
    "y": " b. (mecazi) evdal, -e"
  },
  {
    "x": "evdalî (m)",
    "y": " 1)abdallık\r\n~2)(mecazi) zavallılık"
  },
  {
    "x": "evdalîye (m)",
    "y": " 1)abdallık\r\n~2)(mecazi) zavallılık"
  },
  {
    "x": "evdel, -i",
    "y": " b. avzel, -e"
  },
  {
    "x": "evdî (m)",
    "y": " kölelik, kulluk"
  },
  {
    "x": "evdîye (m)",
    "y": " kölelik, kulluk"
  },
  {
    "x": "eve",
    "y": " b. bi"
  },
  {
    "x": "eve dore kerdene",
    "y": " b. bi dore kerdene"
  },
  {
    "x": "eve gayî bîyene",
    "y": " b. bi gayî bîyene"
  },
  {
    "x": "eve gayîbîyayîş (n)",
    "y": " b. bigayîbîyayîş (n)"
  },
  {
    "x": "eve hak bîyene",
    "y": " b. bi hak bîyene"
  },
  {
    "x": "eve kel bîyene",
    "y": " b. bi kel bîyene"
  },
  {
    "x": "eve kelbîyayîş (n)",
    "y": " b. bikelbîyayîş (n)"
  },
  {
    "x": "eve lingû",
    "y": " b. bi lingan"
  },
  {
    "x": "eve lingûn",
    "y": " b. bi lingan "
  },
  {
    "x": "eve nîwetî",
    "y": " b. bi nobete"
  },
  {
    "x": "eve sire",
    "y": " b. bi sira"
  },
  {
    "x": "eve ... ra",
    "y": " b. bi ... ra"
  },
  {
    "x": "eve ... ya",
    "y": " b. bi ... ya"
  },
  {
    "x": "eve zirçayîş",
    "y": " b. bi zirçayîş"
  },
  {
    "x": "eve zirçîye",
    "y": " b. bi zirçîye"
  },
  {
    "x": "evegaye (m)",
    "y": " b. bigaye (m)"
  },
  {
    "x": "evexaxu",
    "y": " b. biqerqot"
  },
  {
    "x": "evezor",
    "y": " b. bizor"
  },
  {
    "x": "evqat, -e",
    "y": " b. avûkat, -e"
  },
  {
    "x": "ew-I (n, z)",
    "y": " b. o (n, z)"
  },
  {
    "x": "ew vano",
    "y": " o söylüyor"
  },
  {
    "x": "ew-II (n, s)",
    "y": " b. o (n, s)"
  },
  {
    "x": "ew-III (ed)",
    "y": " b. û-III (ed)"
  },
  {
    "x": "ew-IV (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "ew-V (m)",
    "y": " b. hew (m)"
  },
  {
    "x": "ew-ew kerdene",
    "y": " b. hew-hew kerdene"
  },
  {
    "x": "ew-ewe (m)",
    "y": " b. hew-hewe (m)"
  },
  {
    "x": "ew-ewkerdiş (n)",
    "y": " b. hew-hewkerdiş"
  },
  {
    "x": "ew- (pr)",
    "y": " 1)“o” anlamı veren bir önek\r\n~ewca (n):ora, orası\r\n~2)“bu” anlamı veren bir önek\r\n~ewro: bugün\r\n~ewta: bura, burası"
  },
  {
    "x": "ewca (n)",
    "y": " ora, orası"
  },
  {
    "x": "ewro",
    "y": " bugün"
  },
  {
    "x": "ewta",
    "y": " bura, burası"
  },
  {
    "x": "ewa (m)",
    "y": " b. eba (m)"
  },
  {
    "x": "ewara (m)",
    "y": " avare, aylak"
  },
  {
    "x": "eware (n)",
    "y": " avare, aylak"
  },
  {
    "x": "eware bîyene",
    "y": " 1)avareleşmek\r\n~2)(gitmek veya gelmek suretiyle) zahmet etmek, zahmete girmek, zahmete katlanmak\r\n~Eware mebe. (Zahmet etme.)"
  },
  {
    "x": "eware kerdene",
    "y": " 1)avareleştirmek\r\n~2)(gitmesine veya gelmesine neden olmak suretiyle) zahmet vermek"
  },
  {
    "x": "ewarebîyayîş (n)",
    "y": " avareleşme"
  },
  {
    "x": "ewarekerdiş (n)",
    "y": " avareleştirme"
  },
  {
    "x": "ewareyey (m)",
    "y": " b. ewareyîye (m)"
  },
  {
    "x": "ewareyê",
    "y": " b. ewareyî (m)"
  },
  {
    "x": "ewareyî (m)",
    "y": " avarelik, aylaklık"
  },
  {
    "x": "ewareyî kerdene",
    "y": " aylaklık etmek"
  },
  {
    "x": "ewareyîkerdiş (n)",
    "y": " aylaklık etme"
  },
  {
    "x": "ewareyîye (m)",
    "y": " avarelik, aylaklık"
  },
  {
    "x": "ewayene",
    "y": " havlamak, ürümek, ulumak"
  },
  {
    "x": "ewayîş (n)",
    "y": " havlama, ürüme, uluma"
  },
  {
    "x": "ewca (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "ewe (n, z)",
    "y": " b. o (n, z)"
  },
  {
    "x": "ewe wo",
    "y": " odur"
  },
  {
    "x": "ewela",
    "y": " önce, ilkin, evvela, başta"
  },
  {
    "x": "ewi-I (n, z)",
    "y": " b. o-I (n, z)"
  },
  {
    "x": "ewi-II (n, s)",
    "y": " b. o-II (n, s)"
  },
  {
    "x": "ewik (n)",
    "y": " b. ewk-I (n)"
  },
  {
    "x": "ewilê ewilan",
    "y": " ilkönce, en önce"
  },
  {
    "x": "ewilê ewilon",
    "y": " b. ewilê ewilan"
  },
  {
    "x": "ewilê ewilûn",
    "y": " b. ewilê ewilan"
  },
  {
    "x": "ewilî",
    "y": " önce, ilkin, evvela, başta"
  },
  {
    "x": "ewiqas",
    "y": " b. ewqas"
  },
  {
    "x": "ewî (m)",
    "y": " b. hewî (m)"
  },
  {
    "x": "ewja (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "ewk-I (n)",
    "y": " şey\r\n~Ewkî bîyare. (Şeyi getir.)"
  },
  {
    "x": "ewki (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "ewlad, -e",
    "y": " evlat "
  },
  {
    "x": "ewladi (m)",
    "y": " b. ewlade (m)"
  },
  {
    "x": "ewleg (m)",
    "y": " b. ewlege (m)"
  },
  {
    "x": "ewlege (m)",
    "y": " evlek"
  },
  {
    "x": "ewlegi (m)",
    "y": " b. ewlege (m)"
  },
  {
    "x": "ewna",
    "y": " b. wina"
  },
  {
    "x": "ewnakî",
    "y": " b. wina"
  },
  {
    "x": "ewnayîne",
    "y": " b. ewnîyayene"
  },
  {
    "x": "ewnayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "ewneyîne",
    "y": " b. ewnîyayene"
  },
  {
    "x": "ewneyîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "ewniyayîş",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "ewnîrîyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "biewnîre",
    "y": " b. biewnî"
  },
  {
    "x": "bewnîre",
    "y": " b. biewnî"
  },
  {
    "x": "bonîr",
    "y": " b. biewnî"
  },
  {
    "x": "ewnîrîyayox, -e",
    "y": " b. ewnîyayox, -e"
  },
  {
    "x": "ewnîyayene",
    "y": " 1)bakmak, izlemek, seyretmek, temaşa etmek\r\n~2)bakmak (beslemek, gözetmek)"
  },
  {
    "x": "biewnî",
    "y": " bak"
  },
  {
    "x": "bewnî",
    "y": " b. biewnî"
  },
  {
    "x": "ewnîyayîne",
    "y": " b. ewnîyayene "
  },
  {
    "x": "ewnîyayîş (n)",
    "y": " 1)bakma, izleme, seyretme\r\n~2)bakma (besleme, gözetme), bakış, gözetme\r\n~3)gözetim"
  },
  {
    "x": "ewnîyayîşê çermî (n)",
    "y": " cilt bakım"
  },
  {
    "x": "ewnîyayîşê pîyaseyî (n)",
    "y": " piyasa gözetimi"
  },
  {
    "x": "ewnîyayox, -e",
    "y": " bakan, izleyici, seyirci"
  },
  {
    "x": "ewnîyeyîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "ewqas",
    "y": " o kadar"
  },
  {
    "x": "ewr (m)",
    "y": " b. awre (m)"
  },
  {
    "x": "ewra (n)",
    "y": " b. weyra (n)"
  },
  {
    "x": "ewraq (n)",
    "y": " b. ewraqe (m)"
  },
  {
    "x": "ewraqe (m)",
    "y": " evrak"
  },
  {
    "x": "ewraqa ameya (m)",
    "y": " gelen evrak"
  },
  {
    "x": "ewraqa ameyî (m)",
    "y": " gelen evrak"
  },
  {
    "x": "ewraqa şîyaya (m)",
    "y": " giden evrak"
  },
  {
    "x": "ewraqa şîyayî (m)",
    "y": " giden evrak"
  },
  {
    "x": "ewre (m)",
    "y": " b. awre (m)"
  },
  {
    "x": "ewrê (m)",
    "y": " b. (hayvan için) awrîye (m)"
  },
  {
    "x": "ewrêş (n)",
    "y": " b. hargûş, -e"
  },
  {
    "x": "ewri (m)",
    "y": " b. (hayvan için) awre (m)"
  },
  {
    "x": "ewri bîyayene",
    "y": " b. (hayvan için) awre bîyene"
  },
  {
    "x": "ewro",
    "y": " bugün"
  },
  {
    "x": "Ewropa (m)",
    "y": " Avrupa"
  },
  {
    "x": "ewroyên, -e",
    "y": " bugünkü"
  },
  {
    "x": "ewrû",
    "y": " b. ewro"
  },
  {
    "x": "ewta (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "ewtanayene",
    "y": " cesaret etmek, cüret etmek"
  },
  {
    "x": "ewtana",
    "y": " cesaret etti/korkmadı\r\n~Mi nêewtana vaja. (Söylemeye cesaret etmedim.)"
  },
  {
    "x": "ewtanayîş (n)",
    "y": " cesaret etme, cüret etme"
  },
  {
    "x": "ewtarayene",
    "y": " b. wetardene"
  },
  {
    "x": "ewtarayîne",
    "y": " b. wetardene"
  },
  {
    "x": "ewtarayîş (n)",
    "y": " b. wetardiş (n)"
  },
  {
    "x": "ewtayîne",
    "y": " b. ewtanayene"
  },
  {
    "x": "ewtayîş (n)",
    "y": " b. ewtanayîş (n)"
  },
  {
    "x": "ewterayîne",
    "y": " b. wetardene"
  },
  {
    "x": "ewterayîş (n)",
    "y": " b. wetardiş (n)"
  },
  {
    "x": "ewtîya (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "ewtonayîne",
    "y": " b. ewtanayene"
  },
  {
    "x": "ewtonayîş (n)",
    "y": " b. ewtanayîş (n)"
  },
  {
    "x": "ewtûnayîne",
    "y": " b. ewtanayene"
  },
  {
    "x": "ewtûnayîş (n)",
    "y": " b. ewtanayîş (n)"
  },
  {
    "x": "ewulî",
    "y": " b. ewilî"
  },
  {
    "x": "ewûqat, -e",
    "y": " b. avûkat, -e"
  },
  {
    "x": "ewwela",
    "y": " b. ewela"
  },
  {
    "x": "ewwilê ewwilan",
    "y": " b. ewilê ewilan"
  },
  {
    "x": "ewwilî",
    "y": " b. ewilî"
  },
  {
    "x": "ewwulî",
    "y": " b. ewilî"
  },
  {
    "x": "ex",
    "y": " b. ax"
  },
  {
    "x": "exlamor (n)",
    "y": " b. exlamur (n)"
  },
  {
    "x": "exlamur (n)",
    "y": " ıhlamur"
  },
  {
    "x": "exlamûr (n)",
    "y": " b. exlamur (n)"
  },
  {
    "x": "exlaq (n)",
    "y": " ahlak, aktöre, sağtöre"
  },
  {
    "x": "exlaqî ra teber",
    "y": " ahlak dışı"
  },
  {
    "x": "exlaqdar, -e",
    "y": " ahlaklı"
  },
  {
    "x": "exlaqen",
    "y": " ahlakça, ahlaken"
  },
  {
    "x": "exlaqî, -ye",
    "y": " ahlaki, aktöresel, sağtöresel"
  },
  {
    "x": "exlemur (n)",
    "y": " b. exlamur (n)"
  },
  {
    "x": "exmaq, -e",
    "y": " b. ehmeq, -e"
  },
  {
    "x": "exmaqî (m)",
    "y": " b. ehmeqî (m)"
  },
  {
    "x": "exmax, -e",
    "y": " b. ehmeq, -e"
  },
  {
    "x": "exret (n)",
    "y": " b. axret (n)"
  },
  {
    "x": "exro",
    "y": " b. oxro"
  },
  {
    "x": "extar, -i",
    "y": " b. extîyar, -e"
  },
  {
    "x": "exter, -i",
    "y": " b. extîyar, -e"
  },
  {
    "x": "exterey (m)",
    "y": " b. extîyarîye (m)"
  },
  {
    "x": "extîyar, -e",
    "y": " yaşlı, ihtiyar"
  },
  {
    "x": "extîyarênî (m)",
    "y": " yaşlılık, ihtiyarlık"
  },
  {
    "x": "extîyarî (m)",
    "y": " yaşlılık, ihtiyarlık"
  },
  {
    "x": "extîyarîye (m)",
    "y": " yaşlılık, ihtiyarlık"
  },
  {
    "x": "extîyer, -e",
    "y": " b. extîyar, -e"
  },
  {
    "x": "extonî (zh)",
    "y": " kadın şalvarı"
  },
  {
    "x": "ey-I (n, z)",
    "y": " o, onu"
  },
  {
    "x": "ey ra",
    "y": " ondan, ona"
  },
  {
    "x": "ey ra pey",
    "y": " ondan sonra"
  },
  {
    "x": "ey rê",
    "y": " ona"
  },
  {
    "x": "ey-II (zh, z)",
    "y": " b. ê-III (zh, z)"
  },
  {
    "x": "ey-III (n, s)",
    "y": " b. ê-II (n, s)"
  },
  {
    "x": "ey-IV (n, m, zh)",
    "y": " b. yê-VI (n, m, zh)"
  },
  {
    "x": "ey amnanî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "ey amnûnî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "ey hamnanî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "ey imnanî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "ey mewsimî (n, m)",
    "y": " b. yê mewsimî (n, m)"
  },
  {
    "x": "ey omnonî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "ey omnûnî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "ey ûmnûnî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "ey zimistanî (n, m)",
    "y": " b. yê zimistanî (n, m)"
  },
  {
    "x": "ey zimistonî (n, m)",
    "y": " b. yê zimistanî (n, m)"
  },
  {
    "x": "ey zimistûnî (n, m)",
    "y": " b. yê zimistanî (n, m)"
  },
  {
    "x": "ey zimûstûnî (n, m)",
    "y": " b. yê zimistanî (n, m)"
  },
  {
    "x": "ey zumustonî (n, m)",
    "y": " b. yê zimistanî (n, m)"
  },
  {
    "x": "ey zumustûnî (n, m)",
    "y": " b. yê zimistanî (n, m)"
  },
  {
    "x": "ey-V",
    "y": " keçiyi kovmak için kullanılan seslenme sözcüğü"
  },
  {
    "x": "ey ey",
    "y": " b. ey-V"
  },
  {
    "x": "ey-VI",
    "y": " kâfi, yeter"
  },
  {
    "x": "eya-I (zr)",
    "y": " evet"
  },
  {
    "x": "eya-II (m, s)",
    "y": " b. a-II (m, s)"
  },
  {
    "x": "eya-III (m, z)",
    "y": " b. aye (m, z)"
  },
  {
    "x": "eyan beyan",
    "y": " apaçık"
  },
  {
    "x": "eyam (n)",
    "y": " hava durumu"
  },
  {
    "x": "eyar (n)",
    "y": " ayar (akort)"
  },
  {
    "x": "eyar bîyene",
    "y": " ayarlanmak"
  },
  {
    "x": "eyar kerdene",
    "y": " ayarlamak"
  },
  {
    "x": "eyarê sipîyî (sn)",
    "y": " beyaz ayarı"
  },
  {
    "x": "eyarbîyayîş (n)",
    "y": " ayarlanma"
  },
  {
    "x": "eyarkerda (m)",
    "y": " ayarlı"
  },
  {
    "x": "eyarkerde (n)",
    "y": " ayarlı"
  },
  {
    "x": "eyarkerdiş (n)",
    "y": " ayarlama, kalibraj"
  },
  {
    "x": "eyarkerdî, -ye",
    "y": " ayarlı"
  },
  {
    "x": "eyb (n)",
    "y": " ayıp"
  },
  {
    "x": "eyb kerdene",
    "y": " utanmak"
  },
  {
    "x": "eyb vatiş yeno …",
    "y": " af buyurun, affedersiniz, övünmek gibi olmasın, söylemesi ayıp …"
  },
  {
    "x": "eybdar, -e",
    "y": " ayıplı, kusurlu"
  },
  {
    "x": "eybkerdiş (n)",
    "y": " utanma"
  },
  {
    "x": "eybûn (m)",
    "y": " b. eywane (m)"
  },
  {
    "x": "eyel, -e",
    "y": " çocuk"
  },
  {
    "x": "eyel-meyel",
    "y": " çoluk çocuk"
  },
  {
    "x": "eyê-I (m, z)",
    "y": " b. aye (m, z)"
  },
  {
    "x": "eyê ra",
    "y": " b. aye ra"
  },
  {
    "x": "eyê-II (n, z)",
    "y": " b. ey-I (n, z)"
  },
  {
    "x": "eyf (n)",
    "y": " b. heyf-II (n)"
  },
  {
    "x": "eyi (n, z)",
    "y": " b. ey-I (n, z)"
  },
  {
    "x": "eyî-I (n, z)",
    "y": " b. ey-I (n, z)"
  },
  {
    "x": "eyî-II (n, s)",
    "y": " b. ê-II (n, s)"
  },
  {
    "x": "eyî-III (zh, z)",
    "y": " b. ê-III (zh, z)"
  },
  {
    "x": "eyî-IV (zh, s)",
    "y": " b. ê-IV (zh, s)"
  },
  {
    "x": "eyîn (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "eyjdîha (n)",
    "y": " b. ejdeha (n)"
  },
  {
    "x": "eyl, -i",
    "y": " b. eyel, -e"
  },
  {
    "x": "eyla",
    "y": " b. la-III"
  },
  {
    "x": "eylule (m)",
    "y": " b. êlule (m)"
  },
  {
    "x": "eyluli (m)",
    "y": " b. êlule (m)"
  },
  {
    "x": "eyna-I (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "eyna-II (m)",
    "y": " b. eyne (n)"
  },
  {
    "x": "eynad (n)",
    "y": " b. înad (n)"
  },
  {
    "x": "eynad kerdene",
    "y": " b. înad kerdene"
  },
  {
    "x": "eynadey (m)",
    "y": " b. înadîye (m)"
  },
  {
    "x": "eynadey kerdene",
    "y": " b. înad kerdene"
  },
  {
    "x": "eynadê (m)",
    "y": " b. înadî (m)"
  },
  {
    "x": "eynadê kerdene",
    "y": " b. înad kerdene"
  },
  {
    "x": "eynadî (m)",
    "y": " b. înadî (m)"
  },
  {
    "x": "eynadî kerdene",
    "y": " b. înad kerdene"
  },
  {
    "x": "eynan (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "eynatîye (m)",
    "y": " b. înadî (m)"
  },
  {
    "x": "eyne (n)",
    "y": " ayna"
  },
  {
    "x": "eynelî (n)",
    "y": " bir tüfek çeşidi"
  },
  {
    "x": "eyneroş, -e",
    "y": " aynacı"
  },
  {
    "x": "eyneroşîye (m)",
    "y": " aynacılık"
  },
  {
    "x": "eyneviraştox, -e",
    "y": " aynacı"
  },
  {
    "x": "eyneviraştoxîye (m)",
    "y": " aynacılık"
  },
  {
    "x": "eyni-I (n)",
    "y": " b. hênî-I (n)"
  },
  {
    "x": "eyni-II (n)",
    "y": " b. eyne (n)"
  },
  {
    "x": "eynik (n)",
    "y": " ayna"
  },
  {
    "x": "eynike (m)",
    "y": " b. eynik (n)"
  },
  {
    "x": "eynî-I (n)",
    "y": " b. hênî-I (n)"
  },
  {
    "x": "eynî-II (s)",
    "y": " 1)aynı, özdeş\r\n~2)ayni (madde olarak verilen) (huq.)"
  },
  {
    "x": "eynî bîyene",
    "y": " özdeşleşmek"
  },
  {
    "x": "eynî kerdene",
    "y": " özdeşleştirmek"
  },
  {
    "x": "eynî-III (zr)",
    "y": " b. enî-V (zr)"
  },
  {
    "x": "eynîbîyayîş (n)",
    "y": " özdeşleşme"
  },
  {
    "x": "eynîkerdiş (n)",
    "y": " özdeşleştirme"
  },
  {
    "x": "eynîyîye (m)",
    "y": " özdeşlik"
  },
  {
    "x": "eyno (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "eynon (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "eynû (zh, zh)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "eynû (s)",
    "y": " b. eynî-II (s), eynûn (zh, z) b. înan (zh, z)"
  },
  {
    "x": "eynûn (zh, z) b. înan (zh, z)"
  },
  {
    "x": "eyo (n, s)",
    "y": " b. o (n, s)"
  },
  {
    "x": "eyom (n)",
    "y": " b. eyam (n)"
  },
  {
    "x": "eyon (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "eyr",
    "y": " b. ewro"
  },
  {
    "x": "eyro",
    "y": " b. ewro"
  },
  {
    "x": "eyroyên, -i",
    "y": " b. ewroyên, -e"
  },
  {
    "x": "eyroyîn, -e",
    "y": " b. ewroyên, -e"
  },
  {
    "x": "eyrue",
    "y": " b. ewro"
  },
  {
    "x": "eyrû",
    "y": " b. ewro"
  },
  {
    "x": "eyrûyên, -i",
    "y": " b. ewroyên, -e"
  },
  {
    "x": "eysayene",
    "y": " b. asayene"
  },
  {
    "x": "eysayîne",
    "y": " b. asayene"
  },
  {
    "x": "eysayîş (n)",
    "y": " b. asayîş-II (n)"
  },
  {
    "x": "eyşa (n)",
    "y": " b. eşa (n)"
  },
  {
    "x": "eyşi (m)",
    "y": " b. erdîşe (m)"
  },
  {
    "x": "eyştene",
    "y": " b. eştene"
  },
  {
    "x": "eyştene ... ser",
    "y": " b. ser eştene"
  },
  {
    "x": "eyştiş (n)",
    "y": " b. eştiş (n)"
  },
  {
    "x": "eyştişser (n)",
    "y": " b. sereştiş (n)"
  },
  {
    "x": "eyta (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "eytîya (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "eyûm (n)",
    "y": " b. eyam (n)"
  },
  {
    "x": "eyv (n)",
    "y": " b. eyb (n)"
  },
  {
    "x": "eywane (m)",
    "y": " salon, ayvan"
  },
  {
    "x": "eywon (m)",
    "y": " b. eywane (m)"
  },
  {
    "x": "eywûn (m)",
    "y": " b. eywane (m)"
  },
  {
    "x": "eyzimi (m)",
    "y": " b. îzime (m)"
  },
  {
    "x": "ez (z)",
    "y": " ben"
  },
  {
    "x": "eza (m)",
    "y": " yas, taziye"
  },
  {
    "x": "ezab (n)",
    "y": " azap, işkence"
  },
  {
    "x": "ezabê cehenemî (n)",
    "y": " cehennem azabı, (mecazen çok  büyük sıkıntı) cehennem azabı"
  },
  {
    "x": "ezabê dojeyî (n)",
    "y": " cehennem azabı, (mecazen çok  büyük sıkıntı) cehennem azabı"
  },
  {
    "x": "ezabê dozeyî (n)",
    "y": " cehennem azabı, (mecazen çok  büyük sıkıntı) cehennem azabı"
  },
  {
    "x": "ezabi (m)",
    "y": " b. ezab (n)"
  },
  {
    "x": "ezabker, -e",
    "y": " işkenceci"
  },
  {
    "x": "ezamî, -ye",
    "y": " en çok, azami"
  },
  {
    "x": "ezamîkerdiş (n)",
    "y": " azamileştirme"
  },
  {
    "x": "ezan (n)",
    "y": " ezan"
  },
  {
    "x": "ezan dayene",
    "y": " ezan okumak"
  },
  {
    "x": "ezan wendene",
    "y": " ezan okumak"
  },
  {
    "x": "ezanê mexrebî (n)",
    "y": " akşam ezanı"
  },
  {
    "x": "ezanê şanî (n)",
    "y": " akşam ezanı"
  },
  {
    "x": "ezandayîş (n)",
    "y": " ezan okuma"
  },
  {
    "x": "ezane (m)",
    "y": " b. ezan (n)"
  },
  {
    "x": "ezanwendiş (n)",
    "y": " ezan okuma"
  },
  {
    "x": "ezaxane (n)",
    "y": " taziye evi, yas evi"
  },
  {
    "x": "ezayî (m)",
    "y": " taziye"
  },
  {
    "x": "ezber (n)",
    "y": " ezber "
  },
  {
    "x": "ezber kerdene",
    "y": " ezberlemek"
  },
  {
    "x": "ezber ra",
    "y": " ezbere"
  },
  {
    "x": "ezber ra vatene",
    "y": " ezbere anlatmak"
  },
  {
    "x": "ezberkerdiş (n)",
    "y": " ezberleme"
  },
  {
    "x": "ezeb, -e",
    "y": " bekâr, bakir"
  },
  {
    "x": "ezebe (m)",
    "y": " genç kız; bakire, erden"
  },
  {
    "x": "ezebey (m)",
    "y": " b. ezebîye (m)"
  },
  {
    "x": "ezebê (m)",
    "y": " b. ezebî (m)"
  },
  {
    "x": "ezebi (m)",
    "y": " b. ezebe (m)"
  },
  {
    "x": "ezebî (m)",
    "y": " 1)bekârlık\r\n~2)genç kızlık"
  },
  {
    "x": "ezebîye (m)",
    "y": " 1)bekârlık\r\n~2)genç kızlık, bakirelik"
  },
  {
    "x": "ezele-I (n)",
    "y": " b. arzêle-I (n)"
  },
  {
    "x": "ezele-II (n)",
    "y": " b. herzale-I (n)"
  },
  {
    "x": "ezelî, -ye",
    "y": " ezeli"
  },
  {
    "x": "ezez, -e",
    "y": " bencil, egoist"
  },
  {
    "x": "ezez bîyene",
    "y": " bencil olmak, egoist olmak"
  },
  {
    "x": "ezezî (m)",
    "y": " bencillik, egoistlik"
  },
  {
    "x": "ezezîye (m)",
    "y": " bencillik, egoistlik"
  },
  {
    "x": "ezîyayene",
    "y": " b. ecizîyayene"
  },
  {
    "x": "ezîyayîş (n)",
    "y": " b. ecizîyayîş (n)"
  },
  {
    "x": "ezl (n)",
    "y": " azil"
  },
  {
    "x": "ezle-I (n)",
    "y": " b. arzêle-I (n)"
  },
  {
    "x": "ezle-I (n)",
    "y": " b. herzale-I (n)"
  },
  {
    "x": "ezman (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "ezmîn (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "ezmo (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "ezmon (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "ezmû (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "ezmûn (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "ezom (n)",
    "y": " b. ezan (n)"
  },
  {
    "x": "ezon (n)",
    "y": " b. ezan (n)"
  },
  {
    "x": "ezoterîk, -e",
    "y": " batıni, ezoterik, içrek"
  },
  {
    "x": "ezoterîkîye (m)",
    "y": " batinilik, içreklik"
  },
  {
    "x": "Ezraîl (n)",
    "y": " Azrail"
  },
  {
    "x": "Ezrayîl (n)",
    "y": " b. Gangêr (n), Ezraîl (n)"
  },
  {
    "x": "Ezurum (n)",
    "y": " b. Erzirom (n)"
  },
  {
    "x": "ezûm (n)",
    "y": " b. ezan (n)"
  },
  {
    "x": "ezûn (n)",
    "y": " b. ezan (n)"
  },
  {
    "x": "ezyayîş (n)",
    "y": " b. ecizîyayîş (n)"
  },
  {
    "x": "ÊÊÊ"
  },
  {
    "x": "ê-I (n, z)",
    "y": " b. ey (n, z)\r\n~Ti ê ra xelisîyêna. (Sen ondan kurtuluyorsun.)\r\n~\t\r\n~Ê anêt. (O baktı.)\r\n~\t\r\n~Ê persa. (O sordu.)"
  },
  {
    "x": "ê-II (n, s)",
    "y": " o"
  },
  {
    "x": "ê ... ra",
    "y": " ona, ondan\r\n~Ê lajekî ra vaje. (O oğlana söyle.)"
  },
  {
    "x": "ê rîwalî ra",
    "y": " o nedenle, o yüzden, ondan dolayı, ondan ötürü"
  },
  {
    "x": "ê sebebî ra",
    "y": " o nedenle, o yüzden, ondan dolayı, ondan ötürü"
  },
  {
    "x": "ê semedî ra",
    "y": " o nedenle, o yüzden, ondan dolayı, ondan ötürü"
  },
  {
    "x": "ê-III (zh, z)",
    "y": " onlar, onları"
  },
  {
    "x": "ê-IV (zh, s)",
    "y": " onlar\r\n~Ê kênekî vanê. (O kızlar söylüyorlar.)\r\n~\t\r\n~Ê lajekî vanê. (O oğlanlar söylüyorlar.)"
  },
  {
    "x": "ê bînî",
    "y": " başkaları, diğerleri, ağyar"
  },
  {
    "x": "ê ... ra (zh, s)",
    "y": " onlara\r\n~Ê kênekan ra vaje. (O kızlara söyle.) \r\n~\t\r\n~Ê lajekan ra vaje. (O oğlanlara söyle.) "
  },
  {
    "x": "ê-V (zr)",
    "y": " b. e-I (zr), ya-I (zr)"
  },
  {
    "x": "ê-VI (n, m, zh)",
    "y": " b. yê-VI (n, m, zh)"
  },
  {
    "x": "ê amnanî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "ê amnûnî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "ê mewsimî (n, m)",
    "y": " b. yê mewsimî (n, m)"
  },
  {
    "x": "ê omnonî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "ê omnûnî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "ê hamnanî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "ê imnanî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "ê ûmnûnî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "ê zimistanî (n, m)",
    "y": " b. yê zimistanî (n, m)"
  },
  {
    "x": "ê zimistonî (n, m)",
    "y": " b. yê zimistanî (n, m)"
  },
  {
    "x": "ê zimistûnî (n, m)",
    "y": " b. yê zimistanî (n, m)"
  },
  {
    "x": "ê zumustonî (n, m)",
    "y": " b. yê zimistanî (n, m)"
  },
  {
    "x": "ê zumustûnî (n, m)",
    "y": " b. yê zimistanî (n, m)"
  },
  {
    "x": "ê zimûstûnî (n, m)",
    "y": " b. yê zimistanî (n, m)"
  },
  {
    "x": "...ê (sf)",
    "y": " “bir” anlamı veren bir sonek"
  },
  {
    "x": "reyê",
    "y": " bir kez"
  },
  {
    "x": "rojê",
    "y": " bir gün"
  },
  {
    "x": "...ê mergî",
    "y": " ölümcül\r\n~“Huso, Mistê Bezi bêîman o,\r\n~\t\r\n~Tifingê mergî Husî ya nano.” (Deyîra Husê Hacî)"
  },
  {
    "x": "êdeg, -e",
    "y": " b. yêdek, -e"
  },
  {
    "x": "êdî",
    "y": " artık"
  },
  {
    "x": "êg",
    "y": " 1)b. eke-I\r\n~2)b. ke-I"
  },
  {
    "x": "êga (n)",
    "y": " b. hêga (n)"
  },
  {
    "x": "êge (n)",
    "y": " eğe, törpü"
  },
  {
    "x": "êge kerdene",
    "y": " eğelemek"
  },
  {
    "x": "êgmîn (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "êh!",
    "y": " b. êhe!"
  },
  {
    "x": "êhe!",
    "y": " keçiyi durdurmak için kullanılan bir sözcük"
  },
  {
    "x": "êhetî ra",
    "y": " öteden, o taraftan, karşı taraftan"
  },
  {
    "x": "êhlî sunet",
    "y": " b. ehlê sunetî"
  },
  {
    "x": "êjder (n)",
    "y": " b. ejderha, -ye"
  },
  {
    "x": "êjderha (n)",
    "y": " b. ejderha, -ye"
  },
  {
    "x": "êl (n)",
    "y": " el"
  },
  {
    "x": "êlancuxe (m)",
    "y": " yılancık (hastalığı), kızılyörük "
  },
  {
    "x": "êlancuxa kere (m)",
    "y": " kemik veremi, kemik tüberkülozu"
  },
  {
    "x": "êle (m)",
    "y": " aşiret"
  },
  {
    "x": "êleg (n)",
    "y": " b. yeleg (n)"
  },
  {
    "x": "êlege-I (m)",
    "y": " elek"
  },
  {
    "x": "êlege-II (m)",
    "y": " b. yeleg (n)"
  },
  {
    "x": "êlek (n)",
    "y": " b. yeleg (n)"
  },
  {
    "x": "êlemyon (n)",
    "y": " b. alumînyum (n)"
  },
  {
    "x": "êlim (n)",
    "y": " b. halîn (n)"
  },
  {
    "x": "Êlî",
    "y": " b. Alî"
  },
  {
    "x": "êlule (m)",
    "y": " eylül"
  },
  {
    "x": "êluli (m)",
    "y": " b. êlule (m)"
  },
  {
    "x": "êm (n)",
    "y": " yem"
  },
  {
    "x": "Ême (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "Êmi (m)",
    "y": " b. Ême (m)"
  },
  {
    "x": "êmîş (n)",
    "y": " meyve"
  },
  {
    "x": "êmlig (m)",
    "y": " b. êmlige (m)"
  },
  {
    "x": "êmlige (m)",
    "y": " yemlik (hayvanlara yem verilen yer)"
  },
  {
    "x": "êmlixe (m)",
    "y": " b. êmlige (m)"
  },
  {
    "x": "ên (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "...ên (sf)",
    "y": " “-den yapılma” anlamı veren bir sonek"
  },
  {
    "x": "esteyên, -e",
    "y": " kemikten yapılma"
  },
  {
    "x": "asinên, -e",
    "y": " demirden yapılma "
  },
  {
    "x": "êna-I",
    "y": " b. wina"
  },
  {
    "x": "êna-II (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "ênan (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "ênde",
    "y": " b. êdî"
  },
  {
    "x": "êndî",
    "y": " b. êdî"
  },
  {
    "x": "êne-I (n)",
    "y": " b. îne-I (n)"
  },
  {
    "x": "êne-II (n)",
    "y": " b. eyne (n)"
  },
  {
    "x": "êne-III (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "...êne (sf)",
    "y": " b. -ene (sf)"
  },
  {
    "x": "êni (n)",
    "y": " b. hênî-I (n)"
  },
  {
    "x": "ênî (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "êno (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "ênû",
    "y": " b. wina"
  },
  {
    "x": "êpêce",
    "y": " b. epey"
  },
  {
    "x": "êr-I (n)",
    "y": " b. ewro (n)"
  },
  {
    "x": "êr-II (m)",
    "y": " b. îre (m)"
  },
  {
    "x": "êr vetene",
    "y": " b. îre vetene"
  },
  {
    "x": "...êr",
    "y": " “daha” anlamı veren bir sonek\r\n~Ti ey ra zafêr zanî. (Sen ondan daha çok biliyorsun.)"
  },
  {
    "x": "êrdîşe (m)",
    "y": " b. erdîşe (m)"
  },
  {
    "x": "êre-I (n)",
    "y": " ikindi"
  },
  {
    "x": "êrewo teng (n)",
    "y": " b. êreyo teng (n)"
  },
  {
    "x": "êreyo teng (n)",
    "y": " ikindinin son anları"
  },
  {
    "x": "êre-II (n)",
    "y": " b. ewro (n)"
  },
  {
    "x": "êrey",
    "y": " b. êreyî"
  },
  {
    "x": "êreyê",
    "y": " b. êreyî"
  },
  {
    "x": "êreyî",
    "y": " ikindi vakti"
  },
  {
    "x": "êrgal (m)",
    "y": " b. egale (m)"
  },
  {
    "x": "êri (n)",
    "y": " b. ewro (n)"
  },
  {
    "x": "êrîşte (n)",
    "y": " b. erîşte (n)"
  },
  {
    "x": "êrmenî, -ye",
    "y": " b. armenî, -ye"
  },
  {
    "x": "êrmenîkî (m)",
    "y": " b. armenîkî (m)"
  },
  {
    "x": "êrmi (n)",
    "y": " b. harme (n)"
  },
  {
    "x": "êrnayene",
    "y": " b. hêrînayene"
  },
  {
    "x": "êrnayîş (n)",
    "y": " b. hêrînayîş (n)"
  },
  {
    "x": "êrnî (n)",
    "y": " b. armenî (n)"
  },
  {
    "x": "êro (n)",
    "y": " b. ewro (n)"
  },
  {
    "x": "êruge (m)",
    "y": " erik"
  },
  {
    "x": "êrû (n)",
    "y": " b. ewro (n)"
  },
  {
    "x": "êrxat, -e",
    "y": " ırgat, tarım işçisi, işçi"
  },
  {
    "x": "êrxatey (m)",
    "y": " b. êrxatî (m), êrxatîye (m)"
  },
  {
    "x": "êrxatênî (m)",
    "y": " ırgatlık, işçilik, rençperlik"
  },
  {
    "x": "êrxati (m)",
    "y": " b. êrxate (m)"
  },
  {
    "x": "êrxatî (m)",
    "y": " ırgatlık, işçilik, rençperlik"
  },
  {
    "x": "êrxatîye (m)",
    "y": " ırgatlık, işçilik, rençperlik "
  },
  {
    "x": "êrxe (n)",
    "y": " azıdişi, öğütücü diş"
  },
  {
    "x": "êrxuçe (m)",
    "y": " sertleşmiş kar"
  },
  {
    "x": "êrxuçebîyayîş (n)",
    "y": " kar donması"
  },
  {
    "x": "êsayene",
    "y": " b. asayene"
  },
  {
    "x": "êsayîne",
    "y": " b. asayene"
  },
  {
    "x": "êsayîş (n)",
    "y": " b. asayîş-II (n)"
  },
  {
    "x": "êstiri (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "êşin, -e",
    "y": " b. kesk, -e; hewz, -e-I"
  },
  {
    "x": "êşîn, -e",
    "y": " b. kesk, -e; hewz, -e-I"
  },
  {
    "x": "êşkayene",
    "y": " b. eşkayene"
  },
  {
    "x": "êşkayîş (n)",
    "y": " eşkayîş (n)"
  },
  {
    "x": "êşkence (n)",
    "y": " b. îşkence (n)"
  },
  {
    "x": "êşlik (n)",
    "y": " b. îşlig (n)"
  },
  {
    "x": "êştiş (n)",
    "y": " b. eştiş (n)"
  },
  {
    "x": "êta (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "êtîm, -e",
    "y": " b. yetîm, -e"
  },
  {
    "x": "êvar (n)",
    "y": " akşam\r\n~Êvarbaş. (İyi akşamlar.)"
  },
  {
    "x": "êve",
    "y": " b. bi"
  },
  {
    "x": "êwro",
    "y": " b. ewro"
  },
  {
    "x": "êx",
    "y": " b. nix"
  },
  {
    "x": "êx bîyayene",
    "y": " b. nix bîyene"
  },
  {
    "x": "êx kerdene",
    "y": " b. nix kerdene"
  },
  {
    "x": "êxlamur (n)",
    "y": " b. exlamur (n)"
  },
  {
    "x": "êxlamûr (n)",
    "y": " b. exlamur (n)"
  },
  {
    "x": "êxro",
    "y": " b. oxro"
  },
  {
    "x": "êxtîyar, -e",
    "y": " b. extîyar, -e"
  },
  {
    "x": "êxtîyarên (m)",
    "y": " b. extîyarênî (m)"
  },
  {
    "x": "êxtîyarênî (m)",
    "y": " b. extîyarênî (m)"
  },
  {
    "x": "êxtîyarîye (m)",
    "y": " b. extîyarîye (m)"
  },
  {
    "x": "êy-I (n, z)",
    "y": " b. ey-I (n, z)"
  },
  {
    "x": "êy-II (zr)",
    "y": " b. e-I (zr), ya-I (zr)"
  },
  {
    "x": "êye (zr)",
    "y": " b. e-I (zr), ya-I (zr)"
  },
  {
    "x": "êyî-I (n, z)",
    "y": " b. ey-I (n, z)"
  },
  {
    "x": "êyî-II (zh, z)",
    "y": " b. ê-III (zh, z)"
  },
  {
    "x": "êynî",
    "y": " b. eynî-II"
  },
  {
    "x": "êyrû",
    "y": " b. ewro"
  },
  {
    "x": "êzdî, -ye",
    "y": " b. êzidî, -ye"
  },
  {
    "x": "êzdîtîye (m)",
    "y": " b. êzidîtî (m)"
  },
  {
    "x": "êzdîyênî (m)",
    "y": " b. êzidîyênî (m)"
  },
  {
    "x": "êzdîyîye (m)",
    "y": " b. êzidîyîye (m)"
  },
  {
    "x": "êzidî, -ye",
    "y": " Yezidi"
  },
  {
    "x": "êzidîyênî (m)",
    "y": " Yezidilik"
  },
  {
    "x": "êzidîyey (m)",
    "y": " b. êzidîyîye (m)"
  },
  {
    "x": "êzidîyî (m)",
    "y": " Yezidilik"
  },
  {
    "x": "êzidîyîye (m)",
    "y": " Yezidilik"
  },
  {
    "x": "êzim (m)",
    "y": " b. îzime (m)"
  },
  {
    "x": "êzimi (m)",
    "y": " b. îzime (m)"
  },
  {
    "x": "êzîdî, -ye",
    "y": " b. êzidî, -ye"
  },
  {
    "x": "êzîdîyey (m)",
    "y": " b. êzidîyîye (m)"
  },
  {
    "x": "êzîdîyê (m)",
    "y": " b. êzidîyî (m)"
  },
  {
    "x": "êzîdîyîn (m)",
    "y": " b. êzidîyênî (m)"
  },
  {
    "x": "êzîdîyîne (m)",
    "y": " b. êzidîyênî (m)"
  },
  {
    "x": "FFF"
  },
  {
    "x": "fa (m)",
    "y": " b. fine (m)"
  },
  {
    "x": "na fa",
    "y": " b. na fine"
  },
  {
    "x": "faalîyet (n)",
    "y": " b. fealîyet (n)"
  },
  {
    "x": "fable (m)",
    "y": " fabl "
  },
  {
    "x": "fabrîka (m)",
    "y": " fabrika"
  },
  {
    "x": "fabrîkaya werdî (m)",
    "y": " yemek fabrikası"
  },
  {
    "x": "fabrîqa (m",
    "y": " b. fabrîka (m)"
  },
  {
    "x": "fae (m)",
    "y": " b. fine (m)"
  },
  {
    "x": "na fae",
    "y": " b. na fine"
  },
  {
    "x": "fahm (n)",
    "y": " b. fehm (n)"
  },
  {
    "x": "fahm bîyayene",
    "y": " b. fehm bîyene"
  },
  {
    "x": "fahm kerdene",
    "y": " b. fehm kerdene"
  },
  {
    "x": "fahmbîyayîş (n)",
    "y": " b. fehmbîyayîş (n)"
  },
  {
    "x": "fahmkerdiş (n)",
    "y": " b. fehmkerdiş (n)"
  },
  {
    "x": "faîz (n)",
    "y": " faiz"
  },
  {
    "x": "faîz girewtene",
    "y": " faiz almak"
  },
  {
    "x": "faîzwer, -e",
    "y": " faizci"
  },
  {
    "x": "faîzwerî (m)",
    "y": " faizcilik, tefecilik"
  },
  {
    "x": "faîzwerîye (m)",
    "y": " faizcilik, tefecilik"
  },
  {
    "x": "fakulte (m)",
    "y": " fakülte"
  },
  {
    "x": "fakulteya karnasî (m)",
    "y": " işletme fakültesi"
  },
  {
    "x": "fal (n)",
    "y": " fal "
  },
  {
    "x": "fal amyayîne",
    "y": " b. feal ameyene"
  },
  {
    "x": "falamyayîş (n)",
    "y": " b. fealameyîş (n)"
  },
  {
    "x": "faldar, -e",
    "y": " falcı"
  },
  {
    "x": "fale (m)",
    "y": " sabanın çift sürerken elle tutulan kısmından toprağa kadar uzanan parçası ile saban okuna geçirilen küçük parçasını birbirine bağlayan ağaç parçacığı"
  },
  {
    "x": "fali (m)",
    "y": " b. fale (m)"
  },
  {
    "x": "fam (n)",
    "y": " b. fehm (n)"
  },
  {
    "x": "fam bîyayene",
    "y": " b. fehm bîyene"
  },
  {
    "x": "fam bîyayeni",
    "y": " b. fehm bîyene"
  },
  {
    "x": "fam kerdene",
    "y": " b. fehm kerdene"
  },
  {
    "x": "fam kerdeni",
    "y": " b. fehm kerdene"
  },
  {
    "x": "fambîyayîş (n)",
    "y": " b. fehmbîyayîş (n)"
  },
  {
    "x": "famdar, -e",
    "y": " b. fehmdar, -e"
  },
  {
    "x": "famdarî (m)",
    "y": " b. fehmdarîye (m)"
  },
  {
    "x": "famîla (m)",
    "y": " b. famîlya (m)"
  },
  {
    "x": "famîle (m)",
    "y": " b. famîlya (m)"
  },
  {
    "x": "famîlya (m)",
    "y": " aile"
  },
  {
    "x": "famkerdiş (n)",
    "y": " b. fehmkerdiş (n)"
  },
  {
    "x": "famkor, -e",
    "y": " b. fehmkor, -e"
  },
  {
    "x": "famkorey (m)",
    "y": " b. fehmkorî (m), fehmkorîye (m)"
  },
  {
    "x": "famli (n)",
    "y": " b. fanîle (n)"
  },
  {
    "x": "fanatîk, -e",
    "y": " bağnaz, fanatik"
  },
  {
    "x": "fanatîk bîyene",
    "y": " bağnazlaşmak, fanatikleşmek"
  },
  {
    "x": "fanatîkbîyayîş (n)",
    "y": " fanatikleşme, bağnazlaşma"
  },
  {
    "x": "fanatîkîye (m)",
    "y": " bağnazlık, fanatiklik"
  },
  {
    "x": "fanatîzm (n)",
    "y": " fanatiklik, fanatizm, bağnazlık"
  },
  {
    "x": "fanelî (n)",
    "y": " b. fanîle (n)"
  },
  {
    "x": "fanî, -ye",
    "y": " fâni, ölümlü, gelip geçici, kalımsız; sonlu"
  },
  {
    "x": "fanîla (m)",
    "y": " b. fanîle (n)"
  },
  {
    "x": "fanîle (n)",
    "y": " fanila"
  },
  {
    "x": "fanîtî (m)",
    "y": " fânilik, sonluluk"
  },
  {
    "x": "fanîyey (m)",
    "y": " b. fanîyîye (m)"
  },
  {
    "x": "fanîyê (m)",
    "y": " b. fanîyî (m)"
  },
  {
    "x": "fanîyî (m)",
    "y": " fânilik, sonluluk"
  },
  {
    "x": "fanîyîye (m)",
    "y": " fânilik, sonluluk"
  },
  {
    "x": "fanla (m)",
    "y": " b. fanîle (n)"
  },
  {
    "x": "fanolîye (m)",
    "y": " b. fanîle (n)"
  },
  {
    "x": "fanorî (n)",
    "y": " b. fanîle (n)"
  },
  {
    "x": "fanose (m)",
    "y": " fanus"
  },
  {
    "x": "fantastîk, -e",
    "y": " fantastik"
  },
  {
    "x": "fantezî",
    "y": " fantezi, fantazya"
  },
  {
    "x": "fanûrî",
    "y": " b. fanîle (n)"
  },
  {
    "x": "fanûse (m)",
    "y": " b. fanose (m)"
  },
  {
    "x": "faq (n)",
    "y": " b. paq (n)"
  },
  {
    "x": "far (n)",
    "y": " far"
  },
  {
    "x": "farê mijî (n)",
    "y": " sis farı"
  },
  {
    "x": "faro derg (n)",
    "y": " uzun far"
  },
  {
    "x": "faro kilm (n)",
    "y": " kısa far"
  },
  {
    "x": "faran (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "faris, -e",
    "y": " Fars"
  },
  {
    "x": "fariskî (m)",
    "y": " Farsça "
  },
  {
    "x": "Faroarna (zh)",
    "y": " Far Öer Adaları"
  },
  {
    "x": "faron (n)",
    "y": " b. faran (n)"
  },
  {
    "x": "Fas (n)",
    "y": " Fas"
  },
  {
    "x": "fasal (m)",
    "y": " b. fesale (m)"
  },
  {
    "x": "fasal-fasal",
    "y": " b. fesal-fesal"
  },
  {
    "x": "fasalnayene",
    "y": " b. fesilnayene"
  },
  {
    "x": "fasalnayîş (n)",
    "y": " b. fesilnayîş (n)"
  },
  {
    "x": "fasalnayox, -e",
    "y": " b. fesilnayox, -e"
  },
  {
    "x": "fasila (m)",
    "y": " b. fasulya (m)"
  },
  {
    "x": "fasilnayene",
    "y": " b. fesilnayene"
  },
  {
    "x": "fasilnayîş (n)",
    "y": " b. fesilnayîş (n)"
  },
  {
    "x": "fasilnayox",
    "y": " b. fesilnayox, -e"
  },
  {
    "x": "fasîla (m)",
    "y": " b. fasulya (m)"
  },
  {
    "x": "fasla (m)",
    "y": " b. fasulya (m)"
  },
  {
    "x": "fasolya (m)",
    "y": " b. fasulya (m)"
  },
  {
    "x": "fason (n)",
    "y": " fasone, kumaş"
  },
  {
    "x": "fasono diver (n)",
    "y": " çift en kumaş"
  },
  {
    "x": "fasono kêmver/kilmever (n)",
    "y": " ensiz kumaş"
  },
  {
    "x": "fasono peşmên/rêsên (n)",
    "y": " yünden yapılmış kumaş (yün kumaş)"
  },
  {
    "x": "fasono peşmin/rêsin (n)",
    "y": " yünlü kumaş"
  },
  {
    "x": "fasula (m)",
    "y": " b. fasulya (m)"
  },
  {
    "x": "fasulya (m)",
    "y": " fasulye"
  },
  {
    "x": "fasulyayê zeytinî (zh)",
    "y": " zeytinyağlı fasulye"
  },
  {
    "x": "fasun (n)",
    "y": " b. fason (n)"
  },
  {
    "x": "faşîst, -e",
    "y": " faşist "
  },
  {
    "x": "faşîstîye (m)",
    "y": " faşistlik "
  },
  {
    "x": "faşîzm (n)",
    "y": " faşizm "
  },
  {
    "x": "faşla (m)",
    "y": " b. fasulya (m)"
  },
  {
    "x": "fatesnayîş (n)",
    "y": " b. fetisnayîş (n)"
  },
  {
    "x": "fatfatike (m)",
    "y": " kumru"
  },
  {
    "x": "fatiseyîne",
    "y": " b. fetisîyayene"
  },
  {
    "x": "fatisîyayîne",
    "y": " b. fetisîyayene"
  },
  {
    "x": "fatisîyayîş (n)",
    "y": " b. fetisîyayîş (n)"
  },
  {
    "x": "fatisnayene",
    "y": " b. fetisnayene"
  },
  {
    "x": "fatisnayîş (n)",
    "y": " b. fetisnayîş (n)"
  },
  {
    "x": "fatisnayîş (n)",
    "y": " b. fetisnayîş (n)"
  },
  {
    "x": "fatisyayîne",
    "y": " b. fetisîyayene"
  },
  {
    "x": "fatisyayîş (n)",
    "y": " b. fetisîyayîş (n)"
  },
  {
    "x": "fatul (n)",
    "y": " b. fatûl (n)"
  },
  {
    "x": "fatura (m)",
    "y": " fatura "
  },
  {
    "x": "fatûl (n)",
    "y": " toyaka"
  },
  {
    "x": "fay (m)",
    "y": " fay (jeoloji için)"
  },
  {
    "x": "fayde (n)",
    "y": " b. feyde (n)"
  },
  {
    "x": "faydelî, -ye",
    "y": " b. feydedar, -e"
  },
  {
    "x": "faye (m)",
    "y": " b. fine (m)"
  },
  {
    "x": "na faye",
    "y": " b. na fine"
  },
  {
    "x": "fayiz (n)",
    "y": " b. faîz (n)"
  },
  {
    "x": "fayîz (n)",
    "y": " b. faîz (n)"
  },
  {
    "x": "fayla (n)",
    "y": " b. fanîle (n)"
  },
  {
    "x": "fazîlet (n)",
    "y": " b. fezîlet (n)"
  },
  {
    "x": "fazîletdar, -e",
    "y": " b. fezîletdar, -e"
  },
  {
    "x": "feal, -e-I",
    "y": " aktif, faal"
  },
  {
    "x": "feal-II (m)",
    "y": " b. feale (m)"
  },
  {
    "x": "feal ameyene",
    "y": " kösnümek (at için)"
  },
  {
    "x": "feal bîyene",
    "y": " kösnümek (at için)  "
  },
  {
    "x": "feal çarnayene",
    "y": " kösnümek (at için)"
  },
  {
    "x": "fealameyîş (n)",
    "y": " kösnüme (at için)"
  },
  {
    "x": "fealbîyayîş (n)",
    "y": " kösnüme (at için)  "
  },
  {
    "x": "fealçarnayîş (n)",
    "y": " kösnüme (at için)"
  },
  {
    "x": "feale (m)",
    "y": " kösnük (at için)"
  },
  {
    "x": "fealî kerdene",
    "y": " kösnümek (at için)"
  },
  {
    "x": "fealîkerdiş (n)",
    "y": " kösnüme (at için)"
  },
  {
    "x": "fealîyet (n)",
    "y": " aktivite"
  },
  {
    "x": "fecir-I (n)",
    "y": " tan, fecir, şafak"
  },
  {
    "x": "fecir-II (n)",
    "y": " b. felc"
  },
  {
    "x": "fecir bîyayeni",
    "y": " b. felc bîyene"
  },
  {
    "x": "fecîr (n)",
    "y": " b. fecir (n)"
  },
  {
    "x": "feç (n)",
    "y": " b. fek (n)"
  },
  {
    "x": "federal, -e",
    "y": " federal"
  },
  {
    "x": "federasyon (n)",
    "y": " federasyon"
  },
  {
    "x": "federatîf, -e",
    "y": " federatif"
  },
  {
    "x": "feg (n)",
    "y": " b. fek (n)"
  },
  {
    "x": "fehm (n)",
    "y": " 1)anlayış\r\n~2)algı"
  },
  {
    "x": "fehm bîyene",
    "y": " anlaşılmak"
  },
  {
    "x": "fehm kerdene",
    "y": " anlamak, akıl erdirmek, aklı ermek; algılamak"
  },
  {
    "x": "fehm nêkerdene",
    "y": " akıl erdirememek, akıl ermemek"
  },
  {
    "x": "fehmbîyayîş (n)",
    "y": " anlaşılma"
  },
  {
    "x": "fehmdar, -e",
    "y": " anlayışlı"
  },
  {
    "x": "fehmdarênî (m)",
    "y": " anlayışlılık"
  },
  {
    "x": "fehmdarî (m)",
    "y": " anlayışlılık"
  },
  {
    "x": "fehmdarîye (m)",
    "y": " anlayışlılık"
  },
  {
    "x": "fehmkerdiş (n)",
    "y": " anlama, algılama"
  },
  {
    "x": "fehmkor, -e",
    "y": " anlayışsız"
  },
  {
    "x": "fehmkorênî (m)",
    "y": " anlayışsızlık"
  },
  {
    "x": "fehmkorî (m)",
    "y": " anlayışsızlık"
  },
  {
    "x": "fehmkorîye (m)",
    "y": " anlayışsızlık"
  },
  {
    "x": "fek (n)",
    "y": " ağız"
  },
  {
    "x": "fek a kerdene",
    "y": " b. fek ra kerdene"
  },
  {
    "x": "fek a nayene",
    "y": " b. fek ra nayene"
  },
  {
    "x": "fek a nîyayene",
    "y": " b. fek ra nîyayene"
  },
  {
    "x": "fek goşan ginayene",
    "y": " ağzı kulaklarına varmak"
  },
  {
    "x": "fek goşan resayene",
    "y": " ağzı kulaklarına varmak"
  },
  {
    "x": "fek kara xo ra veradayene",
    "y": " b. fek karê xo ra veradayene"
  },
  {
    "x": "fek karê xo ra veradayene",
    "y": " çıkarını tepmek"
  },
  {
    "x": "fek kerdene",
    "y": " b. fek ra kerdene"
  },
  {
    "x": "fek menfeetê xo ra veradayene",
    "y": " çıkarını tepmek"
  },
  {
    "x": "fek pê kerdene",
    "y": " ağız birliği etmek, söz birliği etmek\r\n~Ma fekê xo kerd pê. (Ağız birliği ettik."
  },
  {
    "x": "fek pê ra nênayene",
    "y": " çok konuşmak"
  },
  {
    "x": "fek ra",
    "y": " 1)ağızdan (sözlü olarak)\r\n~2)dopdolu, ağzına kadar"
  },
  {
    "x": "fek ra adir perayene",
    "y": " ateş püskürmek"
  },
  {
    "x": "fek ra adir varayene",
    "y": " ateş püskürmek"
  },
  {
    "x": "fek ra girewtene",
    "y": " ağzından almak"
  },
  {
    "x": "fek ra girotene",
    "y": " b. fek ra girewtene"
  },
  {
    "x": "fek ra girowtene",
    "y": " b. fek ra girewtene"
  },
  {
    "x": "fek ra guretene",
    "y": " b. fek ra girewtene"
  },
  {
    "x": "fek ra kerdene",
    "y": " ağzına koymak, yedirmek"
  },
  {
    "x": "fek ra nayene",
    "y": " ağzına koymak, yedirmek"
  },
  {
    "x": "fek ra nîyayene",
    "y": " ağzına konmak, yedirilmek"
  },
  {
    "x": "fek ra qal girotene",
    "y": " b. fek ra qale girewtene"
  },
  {
    "x": "fek ra qale girewtene",
    "y": " ağzından laf almak, ağzından laf çekmek, ağzından lakırtı almak, ağzından lakırtı çekmek"
  },
  {
    "x": "fek ra qale teber kerdene",
    "y": " ağzından laf almak, ağzından laf çekmek, ağzından lakırtı almak, ağzından lakırtı çekmek"
  },
  {
    "x": "fek ra qali girowtene",
    "y": " b. fek ra qale girewtene"
  },
  {
    "x": "fek ra qali tever kerdene",
    "y": " b. fek ra qale teber kerdene"
  },
  {
    "x": "fek ra qali tewer kerdene",
    "y": " b. fek ra qale teber kerdene"
  },
  {
    "x": "fek ra qese guretene",
    "y": " b. fek ra qisa girewtene"
  },
  {
    "x": "fek ra qisa girewtene",
    "y": " ağzından laf almak, ağzından laf çekmek, ağzından lakırtı almak, ağzından lakırtı çekmek"
  },
  {
    "x": "fek ra qisa guretene",
    "y": " b. fek ra qisa girewtene"
  },
  {
    "x": "fek ra teber kerdene",
    "y": " ağzından almak"
  },
  {
    "x": "fek ra tever kerdene",
    "y": " b. fek ra teber kerdene"
  },
  {
    "x": "fek ra tewer kerdene",
    "y": " b. fek ra teber kerdene"
  },
  {
    "x": "fek ... ra veradayene",
    "y": " caymak, vazgeçmek, vazgelmek"
  },
  {
    "x": "fek ... raveradayîş",
    "y": " cayma, vazgeçme, vazgelme"
  },
  {
    "x": "fek ra vetene",
    "y": " (söz vb. için) ağzından çıkarmak"
  },
  {
    "x": "fek ra xeber girotene",
    "y": " b. fek ra xebere girewtene"
  },
  {
    "x": "fek ra xebere girewtene",
    "y": " ağzından laf almak, ağzından laf çekmek, ağzından lakırtı almak, ağzından lakırtı çekmek"
  },
  {
    "x": "fek ra xeberi girowtene",
    "y": " b. fek ra xebere girewtene"
  },
  {
    "x": "fek şîyene pey goşan",
    "y": " ağzı kulaklarına varmak"
  },
  {
    "x": "fek têkerdene",
    "y": " ağız birliği etmek, söz birliği etmek"
  },
  {
    "x": "fekdê ... ra gêrayîş",
    "y": " b. fekê ... ra gêrayîş"
  },
  {
    "x": "fekê astî (n)",
    "y": " b. fekê astîyî (n)"
  },
  {
    "x": "fekê astîyî (n)",
    "y": " yenin bilek kısmı, elbise kolu ağzı, giysi kolu ağzı"
  },
  {
    "x": "fekê aştî (n)",
    "y": " b. fekê astîyî (n)"
  },
  {
    "x": "fekê çarçatî (n)",
    "y": " dörtyol ağzı"
  },
  {
    "x": "fekê estî (n)",
    "y": " b. fekê astîyî (n)"
  },
  {
    "x": "fekê gay (n)",
    "y": " b. fekê gayî (n)"
  },
  {
    "x": "fekê gayî (n)",
    "y": " döven sürerken ürünü yememesi için öküzün ağzına takılan ağızlık"
  },
  {
    "x": "fekê pê girewtene",
    "y": " ağız dalaşında bulunmak"
  },
  {
    "x": "fekê pê girewtene",
    "y": " ağız münakaşası yapmak"
  },
  {
    "x": "fekê ... ra gêrayene",
    "y": " ağzını aramak\r\n~Kajîn fekê mi ra gêra la mi nêva. (Kajîn ağzımı aradı ama söylemedim.)"
  },
  {
    "x": "fekê ... ra gêrayîş",
    "y": " ağzını arama, ağzını yoklama"
  },
  {
    "x": "fekê ... ra vatene",
    "y": " aktarmak (söz vb. için)"
  },
  {
    "x": "fekê ... ravatiş (n)",
    "y": " aktarma (söz vb. için)"
  },
  {
    "x": "fekê ... sayekerdene",
    "y": " ağzını aramak"
  },
  {
    "x": "fekê ... sayekerdiş",
    "y": " ağzını arama, ağzını yoklama"
  },
  {
    "x": "fekê ... sehnayene",
    "y": " ağzını aramak"
  },
  {
    "x": "fekê ... sehnayîş",
    "y": " ağzını arama, ağzını yoklama"
  },
  {
    "x": "fekê xo çewt nêkerdene",
    "y": " ağız eğmemek, minnet etmemek"
  },
  {
    "x": "fekê xo girewtene",
    "y": " ağzını kapatmak (hiç konuşmamak)"
  },
  {
    "x": "fekê xo hewa ra mendene (mecazi)",
    "y": " şaşakalmak, bakakalmak, ağzı açık kalmak, ağzı bir karış açık kalmak"
  },
  {
    "x": "fekê xo pa nênayene",
    "y": " ağzına ... koymamak"
  },
  {
    "x": "fekê xo pira nênayene",
    "y": " ağzına ... koymamak"
  },
  {
    "x": "fekê xwi girotene",
    "y": " b. fekê xo girewtene"
  },
  {
    "x": "fekî ver",
    "y": " dopdolu, ağzına kadar"
  },
  {
    "x": "fektê ... ra gêrayîş",
    "y": " b. fekê ... ra gêrayîş"
  },
  {
    "x": "fekafek",
    "y": " aşağı yukarı, yaklaşık, takriben, yaklaşık olarak, takribî"
  },
  {
    "x": "fekakerda (m)",
    "y": " açıkağızlı, ağzı açık"
  },
  {
    "x": "fekakerde (n)",
    "y": " açıkağızlı, ağzı açık"
  },
  {
    "x": "fekakerde mendene (mecazi)",
    "y": " şaşakalmak, bakakalmak, ağzı açık kalmak, ağzı bir karış açık kalmak"
  },
  {
    "x": "fekakerdemendiş (n) (mecazi)",
    "y": " şaşakalma, bakakalma, ağzı açık kalma, ağzı bir karış açık kalma"
  },
  {
    "x": "fekakerdiş (n)",
    "y": " b. fekrakerdiş (n)"
  },
  {
    "x": "fekakerdî, -ye",
    "y": " açıkağızlı, ağzı açık"
  },
  {
    "x": "fekanayîş (n)",
    "y": " b. fekranayîş (n)"
  },
  {
    "x": "fekanîyayîş (n)",
    "y": " b. fekranîyayîş (n)"
  },
  {
    "x": "fekbaz, -e",
    "y": " 1)geveze\r\n~2)çenebaz, çeneli"
  },
  {
    "x": "fekbazî (m)",
    "y": " 1)gevezelik\r\n~2)çenebazlık"
  },
  {
    "x": "fekbazî kerdene",
    "y": " gevezelik etmek"
  },
  {
    "x": "fekbazîkerdiş (n)",
    "y": " gevezelik etme"
  },
  {
    "x": "fekbazîye (m)",
    "y": " 1)gevezelik\r\n~2)çenebazlık"
  },
  {
    "x": "fekbazîye kerdene",
    "y": " gevezelik etmek"
  },
  {
    "x": "fekbazîyekerdiş (n)",
    "y": " gevezelik etme"
  },
  {
    "x": "fekcenawar, -e",
    "y": " b. fekcinawir, -e"
  },
  {
    "x": "fekcenawer, -e",
    "y": " b. fekcinawir, -e"
  },
  {
    "x": "fekcenawir, -e",
    "y": " b. fekcinawir, -e"
  },
  {
    "x": "fekcinawir, -e",
    "y": " ağzı biçimsiz olan"
  },
  {
    "x": "fekcinawur, -e",
    "y": " b. fekcinawir, -e"
  },
  {
    "x": "fekçarex, -e",
    "y": " b. fekçarix, -e"
  },
  {
    "x": "fekçarêx, -e",
    "y": " b. fekçarix, -e"
  },
  {
    "x": "fekçarix, -e",
    "y": " ağzı biçimsiz olan"
  },
  {
    "x": "fekçarnayîş (n)",
    "y": " dedikodu"
  },
  {
    "x": "fekçarox, -e",
    "y": " b. fekçarix, -e"
  },
  {
    "x": "fekdewl, -e",
    "y": " ağzı biçimsiz olan"
  },
  {
    "x": "fekdewlça (m)",
    "y": " ağzı biçimsiz olan"
  },
  {
    "x": "fekdewlçe (n)",
    "y": " ağzı biçimsiz olan"
  },
  {
    "x": "fekdewlçeye (m)",
    "y": " ağzı biçimsiz olan"
  },
  {
    "x": "fekdol, -i",
    "y": " b. fekdewl, -e"
  },
  {
    "x": "fekdolça (m)",
    "y": " b. fekdewlça (m)"
  },
  {
    "x": "fekdolçe (n)",
    "y": " b. fekdewlçe (n)"
  },
  {
    "x": "fekdolçeye (m)",
    "y": " b. fekdewlçeye (m)"
  },
  {
    "x": "fekdowl, -i",
    "y": " b. fekdewl, -e"
  },
  {
    "x": "fekdowlça (m)",
    "y": " b. fekdewlça (m)"
  },
  {
    "x": "fekdowlçe (n)",
    "y": " b. fekdewlçe (n)"
  },
  {
    "x": "fekdowlçeye (m)",
    "y": " b. fekdewlçeye (m)"
  },
  {
    "x": "fekecinawur, -e",
    "y": " b. fekcinawir, -e"
  },
  {
    "x": "fekeçarix, -e",
    "y": " b. fekçarix, -e"
  },
  {
    "x": "fekegale, -ye",
    "y": " b. fekgale, -ye"
  },
  {
    "x": "fekehîra, -ye",
    "y": " b. fekhîra, -ye"
  },
  {
    "x": "fekemeşk, -e",
    "y": " b. fekmeşk, -e"
  },
  {
    "x": "fekesorik, -e",
    "y": " b. fekşorik, -e"
  },
  {
    "x": "fekeşorik, -e",
    "y": " b. fekşorik, -e"
  },
  {
    "x": "fekeverg, -e",
    "y": " b. fekverg, -e"
  },
  {
    "x": "fekêpêgirewtiş (n)",
    "y": " 1)ağız dalaşı\r\n~2)ağız dalaşında bulunma, ağız münakaşası yapma"
  },
  {
    "x": "fekêxogirewtiş (n)",
    "y": " ağzını kapatma (hiç konuşmama)"
  },
  {
    "x": "fekêxoguretene",
    "y": " b. fekê xo girewtene"
  },
  {
    "x": "fekêxoguretiş (n)",
    "y": " b. fekêxogirewtiş (n)"
  },
  {
    "x": "fekêxohewaramendiş (n) (mecazi)",
    "y": " şaşakalma, bakakalma, ağzı açık kalma, ağzı bir karış açık kalma"
  },
  {
    "x": "fekêxwigirotiş (n)",
    "y": " b. fekêxogirewtiş (n)"
  },
  {
    "x": "fekfazil",
    "y": " b. fekfuzul, -e"
  },
  {
    "x": "fekfazilê (m)",
    "y": " b. fekfuzulî (m), fekfuzulîye (m)"
  },
  {
    "x": "fekfazilê kerdene",
    "y": " b. fekfuzulî kerdene, fekfuzulîye kerdene"
  },
  {
    "x": "fekfazilêkerdiş (n)",
    "y": " b. fekfuzulîkerdiş (n), fekfuzulîyekerdiş (n)"
  },
  {
    "x": "fekfazul",
    "y": " b. fekfuzul, -e"
  },
  {
    "x": "fekfizil",
    "y": " b. fekfuzul, -e"
  },
  {
    "x": "fekfizilê (m)",
    "y": " b. fekfuzulî (m), fekfuzulîye (m)"
  },
  {
    "x": "fekfizilê kerdene",
    "y": " b. fekfuzulî kerdene, fekfuzulîye kerdene"
  },
  {
    "x": "fekfizilêkerdiş (n)",
    "y": " b. fekfuzulîkerdiş (n), fekfuzulîyekerdiş (n)"
  },
  {
    "x": "fekfuzil",
    "y": " b. fekfuzul, -e"
  },
  {
    "x": "fekfuzilê (m)",
    "y": " b. fekfuzulî (m), fekfuzulîye (m)"
  },
  {
    "x": "fekfuzilê kerdene",
    "y": " b. fekfuzulî kerdene, fekfuzulîye kerdene"
  },
  {
    "x": "fekfuzilêkerdiş (n)",
    "y": " b. fekfuzulîkerdiş (n), fekfuzulîyekerdiş (n)"
  },
  {
    "x": "fekfuzul, -e",
    "y": " geveze"
  },
  {
    "x": "fekfuzulî (m)",
    "y": " gevezelik"
  },
  {
    "x": "fekfuzulî kerdene",
    "y": " gevezelik etmek"
  },
  {
    "x": "fekfuzulîkerdiş (n)",
    "y": " gevezelik etme"
  },
  {
    "x": "fekfuzulîye (m)",
    "y": " gevezelik"
  },
  {
    "x": "fekfuzulîye kerdene",
    "y": " gevezelik etmek"
  },
  {
    "x": "fekfuzulîyekerdiş (n)",
    "y": " gevezelik etme"
  },
  {
    "x": "fekfuzûl",
    "y": " b. fekfuzul, -e"
  },
  {
    "x": "fekfuzûlê (m)",
    "y": " b. fekfuzulî (m), fekfuzulîye (m)"
  },
  {
    "x": "fekfuzûlê kerdene",
    "y": " b. fekfuzulî kerdene, fekfuzulîye kerdene"
  },
  {
    "x": "fekfuzûlêkerdiş (n)",
    "y": " b. fekfuzulîkerdiş (n), fekfuzulîyekerdiş (n)"
  },
  {
    "x": "fekgale, -ye",
    "y": " ağzı biçimsiz olan, büyük ağızlı"
  },
  {
    "x": "fekgebol, -e",
    "y": " b. fekgewel, -e"
  },
  {
    "x": "fekgem (n)",
    "y": " döven sürerken ürünü yememesi için öküzün ağzına takılan ağızlık"
  },
  {
    "x": "fekgewel, -e",
    "y": " ağzı salyalı"
  },
  {
    "x": "fekgilêj, -e",
    "y": " b. fekgilêz, -e"
  },
  {
    "x": "fekgilêz, -e",
    "y": " ağzı salyalı"
  },
  {
    "x": "fekhera, -ye",
    "y": " b. fekhîra, -ye"
  },
  {
    "x": "fekhelesîyay (m)",
    "y": " b. fekhelisîyaya (m)"
  },
  {
    "x": "fekhelesîyaya (m)",
    "y": " b. fekhelisîyaya (m)"
  },
  {
    "x": "fekhelesîyaye (n)",
    "y": " b. fekhelisîyaye (n)"
  },
  {
    "x": "fekhelesîyayî, -ye",
    "y": " b. fekhelisîyayî, -ye"
  },
  {
    "x": "fekhelisîyaya (m)",
    "y": " ağzı yara bere içinde olan"
  },
  {
    "x": "fekhelisîyaye (n)",
    "y": " ağzı yara bere içinde olan"
  },
  {
    "x": "fekhelisîyayî, -ye",
    "y": " ağzı yara bere içinde olan"
  },
  {
    "x": "fekhera, -ye",
    "y": " b. fekhîra, -ye"
  },
  {
    "x": "fekhewş, -e (n)",
    "y": " ağzı biçimsiz olan"
  },
  {
    "x": "fekhîra, -ye",
    "y": " ağzı büyük olan, büyük ağızlı"
  },
  {
    "x": "fekhoş, -i",
    "y": " b. fekhewş, -e"
  },
  {
    "x": "fekhowş, -i",
    "y": " b. fekhewş, -e"
  },
  {
    "x": "fekî (n)",
    "y": " b. fêkî (n)"
  },
  {
    "x": "fekkî",
    "y": " sözlü "
  },
  {
    "x": "fekmalêz, -e",
    "y": " ağzı salyalı"
  },
  {
    "x": "fekmeşk, -e",
    "y": " ağzı biçimsiz olan, büyük ağızlı"
  },
  {
    "x": "fekpêranênayîş (n)",
    "y": " çok konuşma"
  },
  {
    "x": "fekpêt, -e",
    "y": " ağzı pek, ağzı sıkı, ketum"
  },
  {
    "x": "fekpêti (m)",
    "y": " b. fekpête (m)"
  },
  {
    "x": "fekpîs, -e",
    "y": " ağzı bozuk, ağzı pis"
  },
  {
    "x": "fekqayîm, -e",
    "y": " ağzı pek, ağzı sıkı, ketum"
  },
  {
    "x": "fekqeyîm, -e",
    "y": " b. fekqayîm, -e"
  },
  {
    "x": "fekragirewtiş (n)",
    "y": " ağzından alma"
  },
  {
    "x": "fekragirotiş (n)",
    "y": " b. fekragirewtiş (n)"
  },
  {
    "x": "fekragirowtiş (n)",
    "y": " b. fekragirewtiş (n)"
  },
  {
    "x": "fekraguretiş (n)",
    "y": " b. fekragirewtiş (n)"
  },
  {
    "x": "fekrakerde (n)",
    "y": " açıkağızlı, ağzı açık"
  },
  {
    "x": "fekrakerdiş (n)",
    "y": " yedirme"
  },
  {
    "x": "fekrakerdîye (m)",
    "y": " açıkağızlı, ağzı açık"
  },
  {
    "x": "fekranayîş (n)",
    "y": " yedirme"
  },
  {
    "x": "fekranîyayîş (n)",
    "y": " yedirilme"
  },
  {
    "x": "fekraqalegirewtiş (n)",
    "y": " ağzından laf alma, ağzından laf çekme, ağzından lakırtı alma, ağzından lakırtı çekme"
  },
  {
    "x": "fekraqaleteberkerdiş (n)",
    "y": " ağzından laf alma,ağzından laf çekme, ağzından lakırtı alma, ağzından lakırtı çekme"
  },
  {
    "x": "fekraqalgirotiş (n)",
    "y": " b. fekraqalegirewtiş (n)"
  },
  {
    "x": "fekraqaligirowtiş (n)",
    "y": " b. fekraqalegirewtiş (n)"
  },
  {
    "x": "fekraqaliteverkerdiş (n)",
    "y": " b. fekraqaleteberkerdiş (n)"
  },
  {
    "x": "fekraqalitewerkerdiş (n)",
    "y": " b. fekraqaleteberkerdiş (n)"
  },
  {
    "x": "fekraqeseguretiş (n)",
    "y": " b. fekraqisagirewtiş (n)"
  },
  {
    "x": "fekraqisagirewtiş (n)",
    "y": " ağzından laf alma, ağzından laf çekme, ağzından lakırtı alma, ağzından lakırtı çekme"
  },
  {
    "x": "fekraqisaguretiş (n)",
    "y": " b. fekraqisagirewtiş (n)"
  },
  {
    "x": "fekrateberkerdiş (n)",
    "y": " ağzından alma"
  },
  {
    "x": "fekrateverkerdiş (n)",
    "y": " b. fekrateberkerdiş (n)"
  },
  {
    "x": "fekratewerkerdiş (n)",
    "y": " b. fekrateberkerdiş (n)"
  },
  {
    "x": "fekravetiş (n)",
    "y": " (söz vb. için) ağzından çıkarma"
  },
  {
    "x": "fekraxeberegirewtiş (n)",
    "y": " ağzından laf alma, ağzından laf çekme, ağzından lakırtı alma, ağzından lakırtı çekme"
  },
  {
    "x": "fekraxebergirotiş (n)",
    "y": " b. fekraxeberegirewtiş (n)"
  },
  {
    "x": "fekraxeberigirowtiş (n)",
    "y": " b. fekraxeberegirewtiş (n)"
  },
  {
    "x": "feksist, -e",
    "y": " ağzı gevşek"
  },
  {
    "x": "fekşorik, -e",
    "y": " ağzı salyalı"
  },
  {
    "x": "fekvarg, -i",
    "y": " b. fekverg, -e"
  },
  {
    "x": "fekvaz, -e",
    "y": " b. fekbaz, -e"
  },
  {
    "x": "fekvazê (m)",
    "y": " b. fekbazî (m), fekbazîye (m)"
  },
  {
    "x": "fekvazê kerdene",
    "y": " b. fekbazî kerdene, fekbazîye kerdene "
  },
  {
    "x": "fekvazêkerdiş (n)",
    "y": " fekbazîkerdiş (n), fekbazîyekerdiş (n)"
  },
  {
    "x": "fekverg, -e",
    "y": " ağzı biçimsiz olan"
  },
  {
    "x": "fekvila, -ye",
    "y": " ağzı gevşek"
  },
  {
    "x": "fekwazan, -e",
    "y": " geveze"
  },
  {
    "x": "fekwazanî (m)",
    "y": " gevezelik"
  },
  {
    "x": "fekwazanî kerdene",
    "y": " gevezelik etmek"
  },
  {
    "x": "fekwazanîkerdiş (n)",
    "y": " gevezelik etme"
  },
  {
    "x": "fekwazanîye (m)",
    "y": " gevezelik"
  },
  {
    "x": "fekwazanîye kerdene",
    "y": " gevezelik etmek"
  },
  {
    "x": "fekwazanîyekerdiş (n)",
    "y": " gevezelik etme"
  },
  {
    "x": "fekwazûn (n)",
    "y": " b. fekwazan, -e"
  },
  {
    "x": "fekwazûnê (m)",
    "y": " fekwazanî (m), fekwazanîye (m)"
  },
  {
    "x": "fekwazûnê kerdene",
    "y": " b. fekwazanî kerdene, fekwazanîye kerdene"
  },
  {
    "x": "fekwazûnêkerdiş (n)",
    "y": " b. fekwazanîkerdiş (n), fekwazanîyekerdiş (n)"
  },
  {
    "x": "fekwezil",
    "y": " b. fekfuzul, -e"
  },
  {
    "x": "fekwezilê (m)",
    "y": " b. fekfuzulî (m), fekfuzulîye (m)"
  },
  {
    "x": "fekwezilê kerdene",
    "y": " b. fekfuzulî kerdene, fekfuzulîye kerdene"
  },
  {
    "x": "fekwezilêkerdiş (n)",
    "y": " b. fekfuzulîkerdiş (n), fekfuzulîyekerdiş (n)"
  },
  {
    "x": "fekxerepîyaya (m)",
    "y": " b. fekxeripîyaya (m)"
  },
  {
    "x": "fekxerepîyaye (n)",
    "y": " b. fekxeripîyaye (n)"
  },
  {
    "x": "fekxerepîyayî, -ye",
    "y": " b. fekxeripîyayî, -ye"
  },
  {
    "x": "fekxeripîyaya (m)",
    "y": " ağzı bozuk, ağzı pis"
  },
  {
    "x": "fekxeripîyaye (n)",
    "y": " ağzı bozuk, ağzı pis"
  },
  {
    "x": "fekxeripîyayî, -ye",
    "y": " ağzı bozuk"
  },
  {
    "x": "fekxirab, -e",
    "y": " ağzı bozuk, ağzı pis"
  },
  {
    "x": "felaket (n)",
    "y": " b. felakete (m)"
  },
  {
    "x": "felakete (m)",
    "y": " felaket, yıkım"
  },
  {
    "x": "felaketi (m)",
    "y": " b. felakete (m)"
  },
  {
    "x": "felan, -e",
    "y": " b. filan, -e-I"
  },
  {
    "x": "felankes, -e",
    "y": " b. filankes, -e"
  },
  {
    "x": "felat (n)",
    "y": " kurtuluş\r\n~Çimanê mi felatê welatî zî bidîyênê... (Gözüm ülkenin kurtuluşunu da görseydi...)"
  },
  {
    "x": "felc (n)",
    "y": " felç, inme, nüzul"
  },
  {
    "x": "felc bîyene",
    "y": " felç olmak"
  },
  {
    "x": "felcê domanan/qican/gedeyan (n)",
    "y": " çocuk felci"
  },
  {
    "x": "fele (m)",
    "y": " tarla sürerken karasabanın giremediği/girmediği ve dolayısıyla sürülemeyen, ancak kazma ile kazmak veya bellemek suretiyle ekin ekilebilen engebeli yer"
  },
  {
    "x": "fele ... mendiş",
    "y": " tarla sürerken karasabanın giremediği/girmediği engebeli yerin sürülemeden kalması"
  },
  {
    "x": "felek (n)",
    "y": " felek"
  },
  {
    "x": "feleke (m)",
    "y": " b. felek (n)"
  },
  {
    "x": "felekendiş (n)",
    "y": " tarla sürerken karasabanın giremediği/girmediği ve dolayısıyla sürülemeyen, ancak kazma ile kazmak veya bellemek suretiyle ekin ekilebilen engebeli yeri kazıp/belleyip ekme"
  },
  {
    "x": "feletîyayene",
    "y": " b. filitîyayene"
  },
  {
    "x": "feletîyayîş (n)",
    "y": " b. filitîyayîş (n)"
  },
  {
    "x": "feletnayene-I",
    "y": " b. filitnayene"
  },
  {
    "x": "feletnayene-II",
    "y": " b. fetilnayene"
  },
  {
    "x": "feletnayîş-I (n)",
    "y": " b. filitnayîş (n)"
  },
  {
    "x": "feletnayîş-II (n)",
    "y": " b. fetilnayîş (n)"
  },
  {
    "x": "feleverdayîş (n)",
    "y": " tarla sürerken karasabanın giremediği/girmediği sürülememiş yer bırakma"
  },
  {
    "x": "felhan (n)",
    "y": " b. filhan (n)"
  },
  {
    "x": "felhan kerdene",
    "y": " b. filhan kerdene"
  },
  {
    "x": "felhan verdayene",
    "y": " b. filhan verdayene"
  },
  {
    "x": "felhankerdiş (n)",
    "y": " b. filhankerdiş (n)"
  },
  {
    "x": "feli (m)",
    "y": " b. fele (m)"
  },
  {
    "x": "felitedemendiş (n)",
    "y": "b. tedefelemendiş (n)"
  },
  {
    "x": "felitedeverdayîş (n)",
    "y": " b. tedefeleverdayîş (n)"
  },
  {
    "x": "felih, -e",
    "y": " bedenen çok çalışan kişi, güçlü olup iyi çalışan kişi"
  },
  {
    "x": "felikendiş (n)",
    "y": " b. felekendiş (n)"
  },
  {
    "x": "felivardayîş (n)",
    "y": " b. feleverdayîş (n)"
  },
  {
    "x": "felk (m)",
    "y": " b. velke (m)"
  },
  {
    "x": "felki (m)",
    "y": " b. velke (m) "
  },
  {
    "x": "felli (m)",
    "y": " b. fele (m)"
  },
  {
    "x": "fellitedemendiş (n)",
    "y": " b. tedefelemendiş (n)"
  },
  {
    "x": "fellitedeverdayîş (n)",
    "y": " b. tedefeleverdayîş (n)"
  },
  {
    "x": "fellih, -e",
    "y": " b. felih, -e"
  },
  {
    "x": "fellihi (m)",
    "y": " b. felihe (m)"
  },
  {
    "x": "fellikendiş (n)",
    "y": " b. felekendiş (n)"
  },
  {
    "x": "felliverdayîş (n)",
    "y": " b. feleverdayîş (n)"
  },
  {
    "x": "felq (n)",
    "y": " b. felqe (n)"
  },
  {
    "x": "felq bîyayene",
    "y": " b. felqe bîyene"
  },
  {
    "x": "felq bîyene",
    "y": " b. felqe bîyene"
  },
  {
    "x": "felq kerdene",
    "y": " b. felqe kerdene"
  },
  {
    "x": "felqbîyayîş (n)",
    "y": " b. felqebîyayîş (n)"
  },
  {
    "x": "felqe (n)",
    "y": " 1)kesit\r\n~2)parça"
  },
  {
    "x": "felqe bîyene",
    "y": " parçalanmak, yarılmak"
  },
  {
    "x": "felqe kerdene",
    "y": " parçalamak, yarmak"
  },
  {
    "x": "felqebîyayîş (n)",
    "y": " parçalanma, yarılma"
  },
  {
    "x": "felqekerdiş (n)",
    "y": " parçalama, yarma"
  },
  {
    "x": "felqi (n)",
    "y": " b. felqe (n)"
  },
  {
    "x": "felqi bîyayene",
    "y": " b. felqe bîyene"
  },
  {
    "x": "felqi bîyene",
    "y": " b. felqe bîyene"
  },
  {
    "x": "felqi kerdene",
    "y": " b. felqe kerdene"
  },
  {
    "x": "felqibîyayîş (n)",
    "y": " b. felqebîyayîş (n)"
  },
  {
    "x": "felqikerdiş (n)",
    "y": " b. felqekerdiş (n)"
  },
  {
    "x": "felqkerdiş (n)",
    "y": " b. felqekerdiş (n)"
  },
  {
    "x": "felsefe (m)",
    "y": " felsefe"
  },
  {
    "x": "felsefeya huqûqî (m)",
    "y": " hukuk felsefesi"
  },
  {
    "x": "felsefeya zanistî (m)",
    "y": " bilim felsefesi"
  },
  {
    "x": "femînîst, -e",
    "y": " feminist"
  },
  {
    "x": "femînîzm (n)",
    "y": " feminizm"
  },
  {
    "x": "femkerdiş (n)",
    "y": " b. fehmkerdiş (n)"
  },
  {
    "x": "fen (n)",
    "y": " fen "
  },
  {
    "x": "fener (n)",
    "y": " b. fenere (m)"
  },
  {
    "x": "fenere (m)",
    "y": " fener"
  },
  {
    "x": "feneri (m)",
    "y": " b. fenere (m)"
  },
  {
    "x": "fenomen (n)",
    "y": " fenomen"
  },
  {
    "x": "fenus (m)",
    "y": " b. fanose (m)"
  },
  {
    "x": "feodal, -e",
    "y": " derebeyi, feodal"
  },
  {
    "x": "feodalî (m)",
    "y": " derebeylik, feodalite"
  },
  {
    "x": "feodalîte (m)",
    "y": " derebeylik, feodalite"
  },
  {
    "x": "feodalîzm (n)",
    "y": " feodalizm"
  },
  {
    "x": "feqat",
    "y": " b. labelê, la"
  },
  {
    "x": "feqe (m)",
    "y": " tuzak"
  },
  {
    "x": "feqet",
    "y": " b. labelê, la"
  },
  {
    "x": "feqi (m)",
    "y": " b. feqe (m)"
  },
  {
    "x": "feqîr, -e",
    "y": " 1)yoksul, fakir\r\n~2) (mecazi) zavallı\r\n~Feqîr wa se ko? (Zavallı ne yapsın?"
  },
  {
    "x": "feqîra meheyre (m)",
    "y": " zavallı"
  },
  {
    "x": "feqîra meheyri (m)",
    "y": " b. feqîra meheyre (m)"
  },
  {
    "x": "feqîra neçare (m)",
    "y": " zavallı"
  },
  {
    "x": "feqîro meheyr (n)",
    "y": " zavallı"
  },
  {
    "x": "feqîro neçar (n)",
    "y": " zavallı"
  },
  {
    "x": "feqîrey (m)",
    "y": " b. feqîrîye (m)"
  },
  {
    "x": "feqîrê (m)",
    "y": " b. feqîrî (m)"
  },
  {
    "x": "feqîrênî (m)",
    "y": " yoksulluk, yokluk, fakirlik"
  },
  {
    "x": "feqîri (m)",
    "y": " feqîr, -e"
  },
  {
    "x": "feqîrî (m)",
    "y": " yoksulluk, yokluk, fakirlik"
  },
  {
    "x": "feqîrîye (m)",
    "y": " yoksulluk, yokluk, fakirlik "
  },
  {
    "x": "feqîrte (m)",
    "y": " b. feqîrtî (m)"
  },
  {
    "x": "feqîrtê (m)",
    "y": " b. feqîrtî (m)"
  },
  {
    "x": "feqîrtî (m)",
    "y": " yoksulluk, yokluk, fakirlik"
  },
  {
    "x": "feraset (n)",
    "y": " anlayış"
  },
  {
    "x": "ferasetdar, -e",
    "y": " anlayışlı"
  },
  {
    "x": "ferasetdarênî (m)",
    "y": " anlayışlılık"
  },
  {
    "x": "ferasetdarî (m)",
    "y": " anlayışlılık"
  },
  {
    "x": "ferasetdarîye (m)",
    "y": " anlayışlılık"
  },
  {
    "x": "Ferat (n)",
    "y": " Fırat"
  },
  {
    "x": "ferayene",
    "y": " b. firayene"
  },
  {
    "x": "ferayîş (n)",
    "y": " b. firayîş (n)"
  },
  {
    "x": "ferazîye (m)",
    "y": " b. ferezîye (m)"
  },
  {
    "x": "ferc (n)",
    "y": " b. felc"
  },
  {
    "x": "ferc bîyayîne",
    "y": " b. felc bîyene"
  },
  {
    "x": "ferd, -e",
    "y": " fert, birey"
  },
  {
    "x": "ferdî, -ye",
    "y": " ferdi, bireysel"
  },
  {
    "x": "ferek (n)",
    "y": " b. ferik (n)"
  },
  {
    "x": "ferekê nanî (n)",
    "y": " b. ferikê nanî (n)"
  },
  {
    "x": "ferekî nûnî (n)",
    "y": " b. ferikê nanî (n)"
  },
  {
    "x": "fereknayene",
    "y": " b. feriknayene "
  },
  {
    "x": "fereknayîş (n)",
    "y": " b. feriknayîş (n)"
  },
  {
    "x": "fereset (n)",
    "y": " b. firsend (n)"
  },
  {
    "x": "ferezîye (m)",
    "y": " denence, faraziye, hipotez, varsayım"
  },
  {
    "x": "ferezîyeya cigêrayîşî (m)",
    "y": " araştırma denencesi, araştırma hipotezi"
  },
  {
    "x": "ferheng (n)",
    "y": " sözlük, lügat"
  },
  {
    "x": "ferhengê cêbe (m)",
    "y": " cep sözlüğü"
  },
  {
    "x": "ferhengê tûnike (m)",
    "y": " cep sözlüğü"
  },
  {
    "x": "ferhengek (n)",
    "y": " lügatçe, küçük sözlük"
  },
  {
    "x": "ferik (n)",
    "y": " kırıntı, parçacık"
  },
  {
    "x": "ferikê nanî (n)",
    "y": " ekmek kırıntısı"
  },
  {
    "x": "feriknayene",
    "y": " 1)doğramak, ufalamak (ekmek vb. için)\r\n~2)ovmak"
  },
  {
    "x": "feriknayîş (n)",
    "y": " 1)doğrama, ufalama (ekmek vb. için)\r\n~2)ovma"
  },
  {
    "x": "ferîbote (m)",
    "y": " feribot"
  },
  {
    "x": "ferk-I (n)",
    "y": " b. ferik (n)"
  },
  {
    "x": "ferkê nanî (n)",
    "y": " b. ferikê nanî (n)"
  },
  {
    "x": "ferkê nonî (n)",
    "y": " b. ferikê nanî (n)"
  },
  {
    "x": "ferk-II (m)",
    "y": " b. velke (m)"
  },
  {
    "x": "ferman (n)",
    "y": " 1)ferman, buyruk, emir, yarlık\r\n~2)buyruk (padişahın verdiği yazılı buyruk)\r\n~3)komut"
  },
  {
    "x": "ferman dayene",
    "y": " buyurmak"
  },
  {
    "x": "fermanê heqdayîşî (n)",
    "y": " ödeme emri"
  },
  {
    "x": "fermandar, -e",
    "y": " kumandan, komutan"
  },
  {
    "x": "fermandeyî (m)",
    "y": " komuta, kumanda"
  },
  {
    "x": "fermannameyê heqdayîşî (n)",
    "y": " ödeme emri"
  },
  {
    "x": "fermî, -ye",
    "y": " resmî"
  },
  {
    "x": "senedo fermî (n)",
    "y": " resmî senet"
  },
  {
    "x": "fermo",
    "y": " buyurunuz, buyur"
  },
  {
    "x": "fermuar (n)",
    "y": " fermuar"
  },
  {
    "x": "fernayene",
    "y": " b. firnayene"
  },
  {
    "x": "fernayîş (n)",
    "y": " b. firnayîş (n)"
  },
  {
    "x": "ferq (n)",
    "y": " ayrım, fark, fark (mat)"
  },
  {
    "x": "ferq bîyene",
    "y": " ayırt edilmek, tefrik edilmek"
  },
  {
    "x": "ferq kerdene",
    "y": " 1)ayırt etmek, ayrımında olmak, ayrımsamak, fark etmek,  tefrik etmek\r\n~2)hissetmek, sezinlemek, sezmek"
  },
  {
    "x": "ferqê muhletî (n)",
    "y": " vade farkı"
  },
  {
    "x": "ferqê wadeyî (n)",
    "y": " vade farkı"
  },
  {
    "x": "ferqbîyayîş (n)",
    "y": " ayırt edilme, tefrik edilme"
  },
  {
    "x": "ferqkerdiş (n)",
    "y": " 1)ayrımında olma, ayırt etme, fark etme, tefrik\r\n~2)hissetme, sezinleme, sezme"
  },
  {
    "x": "fersah (n)",
    "y": " b. fersex (n)"
  },
  {
    "x": "fersen (n)",
    "y": " b. firsend (n)"
  },
  {
    "x": "fersend (n)",
    "y": " b. firsend (n)"
  },
  {
    "x": "ferset (n)",
    "y": " b. firsend (n)"
  },
  {
    "x": "fersex (n)",
    "y": " fersah"
  },
  {
    "x": "fersûde (n)",
    "y": " fersude"
  },
  {
    "x": "ferş (n)",
    "y": " (et vb. için) parça"
  },
  {
    "x": "ferşik (n)",
    "y": " parçacık"
  },
  {
    "x": "ferşikîyayene",
    "y": " parçacıklara ayrılmak"
  },
  {
    "x": "ferşikîyayîş (n)",
    "y": " parçacıklara ayrılma"
  },
  {
    "x": "ferşiknayene",
    "y": " parçacıklara ayırmak"
  },
  {
    "x": "ferşiknayîş (n)",
    "y": " parçacıklara ayırma"
  },
  {
    "x": "fert",
    "y": " tek"
  },
  {
    "x": "fert-wehîd, -e",
    "y": " biricik"
  },
  {
    "x": "fes (m)",
    "y": " b. fese (m)"
  },
  {
    "x": "fesad, -e",
    "y": " ara bozucu, arabozan, fesat, fesatçı, fitne, fitneci, müfsit, nifakçı"
  },
  {
    "x": "fesal (m)",
    "y": " b. fesale (m)"
  },
  {
    "x": "fesal-fesal",
    "y": " giderek, gittikçe, yavaş yavaş"
  },
  {
    "x": "fesale (m)",
    "y": " 1)ahenk, uyum\r\n~2)yavaşça\r\n~3)fırsat"
  },
  {
    "x": "fesalek",
    "y": " b. fesaleke"
  },
  {
    "x": "fesalek-fesalek",
    "y": " b. fesal-fesal"
  },
  {
    "x": "fesaleke",
    "y": " yavaşça"
  },
  {
    "x": "fesaleki",
    "y": " b. fesaleke"
  },
  {
    "x": "fesali (m)",
    "y": " b. fesale (m)"
  },
  {
    "x": "fese (m)",
    "y": " 1)fes\r\n~2)ibik"
  },
  {
    "x": "feselnayene",
    "y": " b. fesilnayene"
  },
  {
    "x": "feselnayîş (n)",
    "y": " b. fesilnayîş (n)"
  },
  {
    "x": "feselnayox, -e",
    "y": " b. fesilnayox, -e"
  },
  {
    "x": "fesi (m)",
    "y": " b. fese (m)"
  },
  {
    "x": "fesilnayene",
    "y": " biçmek, biçki yapmak (dikiş için)"
  },
  {
    "x": "fesilnayîş (n)",
    "y": " fason, biçme (dikiş için)"
  },
  {
    "x": "fesilnayox, -e",
    "y": " biçkici"
  },
  {
    "x": "fesix (n)",
    "y": " fesih"
  },
  {
    "x": "fesîni (m)",
    "y": " b. sefîne (m)"
  },
  {
    "x": "fesla (m)",
    "y": " b. fasulya (m)"
  },
  {
    "x": "fesle-I (n)",
    "y": " ara, fasıla"
  },
  {
    "x": "fesle-II (n)",
    "y": " b. fasulya (m)"
  },
  {
    "x": "feslegen (n)",
    "y": " fesleğen"
  },
  {
    "x": "festîvale (m)",
    "y": " festival"
  },
  {
    "x": "Festîvala Fîlman ya Ankara ya Mîyanneteweyîye",
    "y": " Uluslararası Ankara Film Festivali"
  },
  {
    "x": "Festîvala Fîlman ya Cannesî [Kanî]",
    "y": " Cannes Film Festivali"
  },
  {
    "x": "Festîvala Fîlman ya Îstanbulî",
    "y": " İstanbul Film Festivali"
  },
  {
    "x": "Festîvala Fîlmanê Cihudan/Yahudîyan",
    "y": " Yahudi Film Festivali"
  },
  {
    "x": "Festîvala Fîlmanê Karkeran ya Dîyarbekirî ya Mîyanneteweyîya Yewine",
    "y": " Birinci Uluslararası Diyarbakır İşçi Filmleri Festivali"
  },
  {
    "x": "Festîvala Fîlmanê Karkeran ya Mîyanneteweyîye",
    "y": " Uluslararası İşçi Filmleri Festivali"
  },
  {
    "x": "Festîvala Fîlmanê Kurdan ya Londra",
    "y": " Londra Kürt Film Festivali"
  },
  {
    "x": "fesûlya (m)",
    "y": " b. fasulya (m)"
  },
  {
    "x": "fesx (n)",
    "y": " b. fesix (n)"
  },
  {
    "x": "feşelneyene",
    "y": " b. feşilneyene"
  },
  {
    "x": "feşilneyene",
    "y": " ağırdan almak"
  },
  {
    "x": "fetar (n)",
    "y": " 1)b. fitar (n)\r\n~2)b. fitar, -e"
  },
  {
    "x": "fetar bîyayene",
    "y": " b. fitar bîyene"
  },
  {
    "x": "fetar kerdene",
    "y": " b. fitar kerdene"
  },
  {
    "x": "fetare (m)",
    "y": " b. fitare (m)"
  },
  {
    "x": "fetare kerdene",
    "y": " b. fitar kerdene"
  },
  {
    "x": "fetelîyayene",
    "y": " b. fetilîyayene"
  },
  {
    "x": "fetelîyayêne",
    "y": " b. fetilîyayene "
  },
  {
    "x": "fetelîyayîş (n)",
    "y": " b. fetilîyayîş (n)"
  },
  {
    "x": "fetelnayene",
    "y": " b. fetilnayene"
  },
  {
    "x": "fetelnayêne",
    "y": " b. fetilnayene"
  },
  {
    "x": "fetelnayîş (n)",
    "y": " b. fetilnayîş (n)"
  },
  {
    "x": "fetelyayîş (n)",
    "y": " b. fetilîyayîş (n)"
  },
  {
    "x": "feteqayene",
    "y": " b. fetiqîyayene "
  },
  {
    "x": "feteqîyayene",
    "y": " b. fetiqîyayene"
  },
  {
    "x": "feteqîyayîş (n)",
    "y": " b. fetiqîyayîş (n)"
  },
  {
    "x": "feteqnayene",
    "y": " b. fetiqnayene"
  },
  {
    "x": "feteqyayîş (n)",
    "y": " b. fetiqîyayîş (n)"
  },
  {
    "x": "fetesîyayene",
    "y": " b. fetisîyayene"
  },
  {
    "x": "fetesîyayîş (n)",
    "y": " b. fetisîyayîş (n)"
  },
  {
    "x": "fetesnayene",
    "y": " b. fetisnayene"
  },
  {
    "x": "fetesnayîş (n)",
    "y": " b. fetisnayîş (n)"
  },
  {
    "x": "fetil (n)",
    "y": " döngü, loop"
  },
  {
    "x": "fetilê projeyî (n)",
    "y": " proje döngüsü"
  },
  {
    "x": "fetile (m)",
    "y": " b. fetiloke (m)"
  },
  {
    "x": "fetilîyayene",
    "y": " 1)gezmek, dolaşmak\r\n~2)rengi atmak, rengi kaçmak, rengi uçmak"
  },
  {
    "x": "ma bifetilîme",
    "y": " biz gezelim, biz dolaşalım"
  },
  {
    "x": "fetilîyayîş (n)",
    "y": " 1)gezme, dolaşma, seyahat\r\n~2)rengi atma, rengi kaçma, rengi uçma"
  },
  {
    "x": "fetilnayene",
    "y": " 1)çevirmek, döndürmek\r\n~2)gezdirmek, dolaştırmak \r\n~3)kovmak, kovalamak"
  },
  {
    "x": "fetilnayîş (n)",
    "y": " 1)çevirme, döndürme\r\n~2)gezdirme, dolaştırma\r\n~3)kovma, kovalama"
  },
  {
    "x": "fetiloke (m)",
    "y": " dönemeç, viraj"
  },
  {
    "x": "fetilşal",
    "y": " b. fetşal, -e"
  },
  {
    "x": "fetilşar",
    "y": " b. fetşal, -e"
  },
  {
    "x": "fetiq (n)",
    "y": " b. fitiq (n)"
  },
  {
    "x": "fetiqîyayene",
    "y": " çatlamak (deri vb. için), yarılmak (deri vb. için)"
  },
  {
    "x": "fetiqîyayîş (n)",
    "y": " 1)çatlama (deri vb. için), yarılma (deri vb. için)"
  },
  {
    "x": "fetiqnayene",
    "y": " çatlatmak (deri vb. için)"
  },
  {
    "x": "fetir (n)",
    "y": " b. patîre (n)"
  },
  {
    "x": "fetiseyîne",
    "y": " b. fetisîyayene"
  },
  {
    "x": "fetiseyîş (n)",
    "y": " b. fetisîyayîş (n)"
  },
  {
    "x": "fetisîyayene",
    "y": " boğulmak (soluk alamamaktan dolayı)"
  },
  {
    "x": "fetisîyayîş (n)",
    "y": " boğulma (soluk alamamaktan dolayı)"
  },
  {
    "x": "fetisnayene",
    "y": " boğmak (soluk aldırmamaktan dolayı)"
  },
  {
    "x": "fetisnayîş (n)",
    "y": " boğma (soluk aldırmamaktan dolayı)"
  },
  {
    "x": "fetisnayox, -e",
    "y": " boğucu"
  },
  {
    "x": "fetîş (n)",
    "y": " fetiş"
  },
  {
    "x": "fetl (n)",
    "y": " 1)b. fetiloke (m)\r\n~2)b. fetil (n)"
  },
  {
    "x": "fetşal, -e",
    "y": " donsuz"
  },
  {
    "x": "fewqelade",
    "y": " çılgın, olağanüstü"
  },
  {
    "x": "feyde (n)",
    "y": " çıkar, fayda, menfaat, yarar"
  },
  {
    "x": "feydeyê pêroyî (n)",
    "y": " kamu yararı, amme menfaati"
  },
  {
    "x": "feydeyê umûmîya (n)",
    "y": " kamu yararı, amme menfaati"
  },
  {
    "x": "feydedar, -e",
    "y": " faydalı, yararlı"
  },
  {
    "x": "feydi (n)",
    "y": " b. feyde (n)"
  },
  {
    "x": "feykî (n)",
    "y": " b. fêkî (n)"
  },
  {
    "x": "feza (m)",
    "y": " uzay"
  },
  {
    "x": "fezîlet (n)",
    "y": " erdem, fazilet"
  },
  {
    "x": "fezîletdar, -e",
    "y": " erdemli, faziletli"
  },
  {
    "x": "fêk (n)",
    "y": " b. fek (n)"
  },
  {
    "x": "fêkê aştî (n)",
    "y": " b. fekê astîyî (n)"
  },
  {
    "x": "fêkî (n)",
    "y": " meyve"
  },
  {
    "x": "fêkîroş, -e",
    "y": " manav"
  },
  {
    "x": "fêkîroşîye (m)",
    "y": " manavlık"
  },
  {
    "x": "fêlbaz, -e",
    "y": " hilekâr"
  },
  {
    "x": "fêlbazîye (m)",
    "y": " hilekârlık"
  },
  {
    "x": "fêm (n)",
    "y": " b. fehm (n)"
  },
  {
    "x": "fêm bîyayene",
    "y": " b. fehm bîyene"
  },
  {
    "x": "fêm kerdene",
    "y": " b. fehm kerdene"
  },
  {
    "x": "fêmbîyayîş (n)",
    "y": " b. fehmbîyayîş (n)"
  },
  {
    "x": "fêmdar, -e",
    "y": " b. fehmdar, -e"
  },
  {
    "x": "fêmdarê (m)",
    "y": " b. fehmdarîye (m)"
  },
  {
    "x": "fêmkerdiş (n)",
    "y": " b. fehmkerdiş (n)"
  },
  {
    "x": "fêştuwal",
    "y": " b. fetşal, -e"
  },
  {
    "x": "fêyat (n)",
    "y": " b. fîyet (n)"
  },
  {
    "x": "fêyde (n)",
    "y": " b. feyde (n)"
  },
  {
    "x": "fêz (n)",
    "y": " hayvanların geceleyin topluca bekletildiği açık arazi"
  },
  {
    "x": "fici (m)",
    "y": " b. fije (m)"
  },
  {
    "x": "fije (m)",
    "y": " hıyar vb. bitkilerin henüz yeni oluşmuş olan küçücük ürünü"
  },
  {
    "x": "fiji (m)",
    "y": " b. fije (m)"
  },
  {
    "x": "fik (n)",
    "y": " b. fek (n)"
  },
  {
    "x": "fikar (n)",
    "y": " endişe, kaygı, tasa, telaş"
  },
  {
    "x": "fikar kerdene",
    "y": " endişelenmek, endişe etmek, kaygılanmak, tasalanmak"
  },
  {
    "x": "fikarkerdiş (n)",
    "y": " endişelenme, kaygılanma, tasalanma"
  },
  {
    "x": "fikfikoke (m)",
    "y": " bir kuş çeşidi"
  },
  {
    "x": "fikir (n)",
    "y": " b. fikr (m)"
  },
  {
    "x": "fikir dayene",
    "y": " akıl yürütmek"
  },
  {
    "x": "fikirdayîş (n)",
    "y": " akıl yürütme"
  },
  {
    "x": "fikirîyayene",
    "y": " düşünmek"
  },
  {
    "x": "fikirîyayîş (n)",
    "y": " düşünme"
  },
  {
    "x": "fikirîyayîşo analîtîk (n)",
    "y": " analitik düşünme"
  },
  {
    "x": "fikirîyayîşo bianalîz (n)",
    "y": " analitik düşünme"
  },
  {
    "x": "fikr (n)",
    "y": " düşünce, fikir"
  },
  {
    "x": "fikrê Dewrê Mîyanênî",
    "y": " Orta Çağ kafası"
  },
  {
    "x": "fikro bingeyî (n)",
    "y": " ana düşünce, ana fikir"
  },
  {
    "x": "fikro îlmî (n)",
    "y": " bilimsel düşünce"
  },
  {
    "x": "fikro sabît (n)",
    "y": " fikrisabit, saplantı"
  },
  {
    "x": "fikrê sabîtî (zh)",
    "y": " saplantılar"
  },
  {
    "x": "fikro zanistî (n)",
    "y": " bilimsel düşünce"
  },
  {
    "x": "fikrêumûmîya (n)",
    "y": " kamuoyu"
  },
  {
    "x": "fila (m)",
    "y": " Hristiyan"
  },
  {
    "x": "filaket",
    "y": " b. felakete (m)"
  },
  {
    "x": "filan, -e-I",
    "y": " falan, falanca; filan, filanca"
  },
  {
    "x": "filan-bêban",
    "y": " b. filan-bêvan"
  },
  {
    "x": "filon-bêbon",
    "y": " b. filan-bêvan"
  },
  {
    "x": "filan-bêvan",
    "y": " falan filan"
  },
  {
    "x": "filan û bêban",
    "y": " b. filan-bêvan"
  },
  {
    "x": "filan û bêvan",
    "y": " b. filan-bêvan "
  },
  {
    "x": "filan-II (n)",
    "y": " b. filhan (n)"
  },
  {
    "x": "filan kerdene",
    "y": " b. filhan kerdene"
  },
  {
    "x": "filan verdayene",
    "y": " b. filhan verdayene"
  },
  {
    "x": "filankerdiş (n)",
    "y": " b. filhankerdiş (n)"
  },
  {
    "x": "filankes, -e",
    "y": " falan, falanca"
  },
  {
    "x": "file (n)",
    "y": " Hristiyan"
  },
  {
    "x": "fileyey",
    "y": " b. fileyîye (m), fileyî (m)"
  },
  {
    "x": "fileyê",
    "y": " b. fileyîye (m), fileyî (m)"
  },
  {
    "x": "fileyî (m)",
    "y": " Hristiyanlık"
  },
  {
    "x": "fileyîye (m)",
    "y": " Hristiyanlık"
  },
  {
    "x": "filêze (n)",
    "y": " b. firêze (n)"
  },
  {
    "x": "filfilik (n)",
    "y": " b. pelpelike (m)"
  },
  {
    "x": "filfilok (n)",
    "y": " b. pelpelike (m)"
  },
  {
    "x": "filhan (n)",
    "y": " herk "
  },
  {
    "x": "filhan kerdene",
    "y": " herk etmek, nadas etmek "
  },
  {
    "x": "filhan verdayene",
    "y": " nadasa bırakmak, nadasa yatırmak"
  },
  {
    "x": "filhankerdiş (n)",
    "y": " nadas"
  },
  {
    "x": "filhon (n)",
    "y": " b. filhan (n)"
  },
  {
    "x": "filhon kardene",
    "y": " b. filhan kerdene"
  },
  {
    "x": "filhon kerdene",
    "y": " b. filhan kerdene"
  },
  {
    "x": "filhon vardayene",
    "y": " b. filhan verdayene"
  },
  {
    "x": "filhon verdayene",
    "y": " b. filhan verdayene"
  },
  {
    "x": "filhonkardiş (n)",
    "y": " b. filhankerdiş (n)"
  },
  {
    "x": "filhonkerdiş (n)",
    "y": " b. filhankerdiş (n)"
  },
  {
    "x": "filhû (n)",
    "y": " b. filhan (n)"
  },
  {
    "x": "filhû kerdene",
    "y": " b. filhan kerdene"
  },
  {
    "x": "filhû verdayene",
    "y": " b. filhan verdayene"
  },
  {
    "x": "filhûkerdiş (n)",
    "y": " b. filhankerdiş (n)"
  },
  {
    "x": "filhûn (n)",
    "y": " b. filhan (n)"
  },
  {
    "x": "filhûn kerdene",
    "y": " b. filhan kerdene"
  },
  {
    "x": "filhûn verdayene",
    "y": " b. filhan verdayene"
  },
  {
    "x": "filhûnkerdiş (n)",
    "y": " b. filhankerdiş (n)"
  },
  {
    "x": "filitîyayene",
    "y": " kurtulmak"
  },
  {
    "x": "filitîyayîş (n)",
    "y": " kurtulma"
  },
  {
    "x": "filitnayene",
    "y": " kurtarmak"
  },
  {
    "x": "filitnayîş (n)",
    "y": " kurtarma"
  },
  {
    "x": "filîm (n)",
    "y": " b. fîlm (n)"
  },
  {
    "x": "filînta (m)",
    "y": " b. filîte (n)"
  },
  {
    "x": "filînte (n)",
    "y": " b. filîte (n)"
  },
  {
    "x": "filîte (n)",
    "y": " bir tüfek çeşidi, filinta"
  },
  {
    "x": "filla (m)",
    "y": " b. fila (m)"
  },
  {
    "x": "fille (n)",
    "y": " b. file (n)"
  },
  {
    "x": "fillik (n)",
    "y": " b. fîlik (n)"
  },
  {
    "x": "fillikin (n)",
    "y": " b. fîlikin (n)"
  },
  {
    "x": "filon-I (n)",
    "y": " b. filhan (n)"
  },
  {
    "x": "filon kerdene",
    "y": " b. filhan kerdene"
  },
  {
    "x": "filon verdayene",
    "y": " b. filhan verdayene"
  },
  {
    "x": "filon, -i-II",
    "y": " b. filan, -e"
  },
  {
    "x": "filon-bêvon",
    "y": " b. filan-bêvan"
  },
  {
    "x": "filonkerdiş (n)",
    "y": " b. filhankerdiş (n)"
  },
  {
    "x": "filonkes, -i",
    "y": " b. filankes, -e"
  },
  {
    "x": "filqe (n)",
    "y": " b. felqe (n)"
  },
  {
    "x": "filqe bîyayene",
    "y": " b. felqe bîyene"
  },
  {
    "x": "filqe bîyene",
    "y": " b. felqe bîyene"
  },
  {
    "x": "filqe kerdene",
    "y": " b. felqe kerdene"
  },
  {
    "x": "filqebîyayîş (n)",
    "y": " b. felqebîyayîş (n)"
  },
  {
    "x": "filqekerdiş (n)",
    "y": " b. felqekerdiş (n)"
  },
  {
    "x": "filqinos, -i",
    "y": " b. fiqonos, -e"
  },
  {
    "x": "filqonos, -e",
    "y": " 1)laçka\r\n~2)kaypak"
  },
  {
    "x": "filû (n)",
    "y": " b. filhan (n)"
  },
  {
    "x": "filû kerdene",
    "y": " b. filhan kerdene"
  },
  {
    "x": "filûkerdiş (n)",
    "y": " b. filhankerdiş (n)"
  },
  {
    "x": "filûn",
    "y": " b. filan, -e"
  },
  {
    "x": "filûn-bêvûn",
    "y": " b. filan-bêvan"
  },
  {
    "x": "filûne (n)",
    "y": " b. fanîle (n)"
  },
  {
    "x": "filûnkes",
    "y": " b. filankes, -e"
  },
  {
    "x": "fim-I (m)",
    "y": " b. fine (m)"
  },
  {
    "x": "fim-II (n)",
    "y": " b. fine (m)"
  },
  {
    "x": "fim-III (n)",
    "y": " b. fehm (n)"
  },
  {
    "x": "fim kerdene",
    "y": " b. fehm kerdene"
  },
  {
    "x": "fime (m)",
    "y": " b. fine (m)"
  },
  {
    "x": "fin-I (n)",
    "y": " b. fine (m)"
  },
  {
    "x": "fin-II (m)",
    "y": " b. fînî (m)"
  },
  {
    "x": "fina",
    "y": " yine, gene, tekrar"
  },
  {
    "x": "fincik eştene",
    "y": " zıplayıp koşarak tekme atmak (hayvan için)"
  },
  {
    "x": "findayeni",
    "y": " b. vindetene"
  },
  {
    "x": "findayêni",
    "y": " b. vindetene"
  },
  {
    "x": "Finde!",
    "y": " b. Vinde!"
  },
  {
    "x": "finde (m)",
    "y": " mum"
  },
  {
    "x": "findedane (m)",
    "y": " mumluk, şamdan, şamdanlık"
  },
  {
    "x": "findeta m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "findete (n)",
    "y": " b. vindete (n)"
  },
  {
    "x": "findetene",
    "y": " b. vindetene"
  },
  {
    "x": "findetê (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "findetiş (n)",
    "y": " b. vindetiş (n)"
  },
  {
    "x": "findetî (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "findetîye (m)",
    "y": " b. vindetîye (m)"
  },
  {
    "x": "findi (m)",
    "y": " b. finde (m)"
  },
  {
    "x": "findiq (m)",
    "y": " b. findiqe (m)"
  },
  {
    "x": "findiqe (m)",
    "y": " fındık "
  },
  {
    "x": "findiqêre (m)",
    "y": " fındık ağacı"
  },
  {
    "x": "findiqêri (m)",
    "y": " b. findiqêre (m)"
  },
  {
    "x": "findiqi (m)",
    "y": " b. findiqe (m)"
  },
  {
    "x": "findix (m)",
    "y": " b. findiqe (m)"
  },
  {
    "x": "findoqe (m)",
    "y": " b. findiqe (m)"
  },
  {
    "x": "fine (m)",
    "y": " kez, kere, defa, sefer"
  },
  {
    "x": "finê",
    "y": " bir kez"
  },
  {
    "x": "finêk",
    "y": " bir kez"
  },
  {
    "x": "fineta m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "finete (n)",
    "y": " b. vindete (n)"
  },
  {
    "x": "finetene",
    "y": " b. vindetene"
  },
  {
    "x": "finetê (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "finetiş",
    "y": " b. vindetiş"
  },
  {
    "x": "finetî (m)",
    "y": " b. vindetî (m), b. vindetîye (m)"
  },
  {
    "x": "fini (m)",
    "y": " b. fine (m)"
  },
  {
    "x": "finî",
    "y": " b. fînî (m)"
  },
  {
    "x": "finos (m)",
    "y": " b. fanose (m)"
  },
  {
    "x": "fiqara (m)",
    "y": " yoksul, fakir, zavallı"
  },
  {
    "x": "fiqare (n)",
    "y": " yoksul, fakir, zavallı"
  },
  {
    "x": "fiqarîye (m)",
    "y": " yoksul, fakir, zavallı"
  },
  {
    "x": "fir-I (m)",
    "y": " b. fine (m)"
  },
  {
    "x": "fir-II (m)",
    "y": " b. firte (m)"
  },
  {
    "x": "fir dayene",
    "y": " kanatlanmak, uçmak"
  },
  {
    "x": "fir kerdene",
    "y": " içmek (ayran, şerbet vb. için)"
  },
  {
    "x": "firaq (n)",
    "y": " kap (yemek kabı)"
  },
  {
    "x": "firaqê nêşuteyî (zh)",
    "y": " bulaşık"
  },
  {
    "x": "firaqe (m)",
    "y": " b. firaq (n)"
  },
  {
    "x": "firaqşî (m)",
    "y": " b. firaqşû (m)"
  },
  {
    "x": "firaqşo (n)",
    "y": " bulaşık yıkama yeri"
  },
  {
    "x": "firaqşuw, -e",
    "y": " bulaşıkçı"
  },
  {
    "x": "firaqşuy (m)",
    "y": " b. firaqşû (m)"
  },
  {
    "x": "firaqşû (m)",
    "y": " bulaşık suyu"
  },
  {
    "x": "Firat (n)",
    "y": " b. Ferat (n)"
  },
  {
    "x": "firax (n)",
    "y": " b. firaq (n)"
  },
  {
    "x": "firaya (m)",
    "y": " uçucu"
  },
  {
    "x": "firaye (n)",
    "y": " uçucu"
  },
  {
    "x": "firayene",
    "y": " kanatlanmak, uçmak"
  },
  {
    "x": "firayî, -ye",
    "y": " uçucu"
  },
  {
    "x": "firayîş (n)",
    "y": " kanatlanış, kanatlanma, uçma, uçuş"
  },
  {
    "x": "firçe (n)",
    "y": " fırça"
  },
  {
    "x": "firçeyê didanan (n)",
    "y": " diş fırçası"
  },
  {
    "x": "firçeyê dindanan (n)",
    "y": " b. firçeyê didanan (n)"
  },
  {
    "x": "firçeyê gijikî (n)",
    "y": " b. firçeyê porî (n)"
  },
  {
    "x": "firçeyê porî (n)",
    "y": " saç fırçası"
  },
  {
    "x": "firçeyê taştişî (n)",
    "y": " tıraş fırçası"
  },
  {
    "x": "firçeyê terdişî (n)",
    "y": " tıraş fırçası"
  },
  {
    "x": "firçeyê tiraşî (n)",
    "y": " tıraş fırçası"
  },
  {
    "x": "firçê gijîk (n)",
    "y": " b. firçeyê porî (n)"
  },
  {
    "x": "firçêy dindûnûn (n)",
    "y": " b. firçeyê didanan (n)"
  },
  {
    "x": "fird (n)",
    "y": " ekmek doğranmış ayran veya yoğurt"
  },
  {
    "x": "firdayîş (n)",
    "y": " kanatlanış, kanatlanma, uçma"
  },
  {
    "x": "firdoqî (m)",
    "y": " ekmek doğranmış ayran veya yoğurt"
  },
  {
    "x": "fire (m)",
    "y": " b. firte (m)"
  },
  {
    "x": "firengî (n)",
    "y": " frengi"
  },
  {
    "x": "firengzehmetî (n)",
    "y": " frengi "
  },
  {
    "x": "firez (n)",
    "y": "  b. firêze (n)"
  },
  {
    "x": "firêngî (m)",
    "y": " b. firingî (m)"
  },
  {
    "x": "firêze (n)",
    "y": " 1)anız, firez\r\n~2)(mecazi) yapılan iş, pratik"
  },
  {
    "x": "firfirik (n)",
    "y": " b. pelik (n)"
  },
  {
    "x": "firindar, -e",
    "y": " fırıncı"
  },
  {
    "x": "firine (m)",
    "y": " fırın"
  },
  {
    "x": "firina alatirkine (m)",
    "y": " b. firina elektrîkine (m)"
  },
  {
    "x": "firina elektrîkine (m)",
    "y": " elektrik fırını"
  },
  {
    "x": "firina gazine (m)",
    "y": " gazlı fırın"
  },
  {
    "x": "firine de huşk kerdene",
    "y": " fırınlamak"
  },
  {
    "x": "firine de huşkkerdiş",
    "y": " fırınlama"
  },
  {
    "x": "firine de pewtene",
    "y": " fırınlamak"
  },
  {
    "x": "firine de pewtiş",
    "y": " fırınlama"
  },
  {
    "x": "firing-I (m)",
    "y": " b. firingî (m)"
  },
  {
    "x": "firing-II (m)",
    "y": " b. firinge (m)"
  },
  {
    "x": "firinge (m)",
    "y": " çağla "
  },
  {
    "x": "firinga vame (m)",
    "y": " badem çağlası"
  },
  {
    "x": "firingê neha (n)",
    "y": " nohut firiği"
  },
  {
    "x": "firingi (m)",
    "y": " b. firinge (m)"
  },
  {
    "x": "firingî (m)",
    "y": " domates"
  },
  {
    "x": "firingzametî (n)",
    "y": " b. firengzehmetî (n)"
  },
  {
    "x": "firingzehmetî (n)",
    "y": " b. firengzehmetî (n)"
  },
  {
    "x": "firini (m)",
    "y": " b. firine (m)"
  },
  {
    "x": "firîgi (m)",
    "y": " b. firinge (m)"
  },
  {
    "x": "firîga vame (m)",
    "y": " b. firinga vame (m)"
  },
  {
    "x": "firîkete (m)",
    "y": " b. fîrkete (m)"
  },
  {
    "x": "firîng (m)",
    "y": " b. firinge (m)"
  },
  {
    "x": "firîngî (m)",
    "y": " b. firingî (m)"
  },
  {
    "x": "firk (m)",
    "y": " b. firke (n)"
  },
  {
    "x": "firke (n)",
    "y": " şiddetli sırt veya bel ağrısı"
  },
  {
    "x": "firnayene",
    "y": " 1)kanatlandırmak, uçurmak\r\n~2)kaçırmak (akıl vb. için)"
  },
  {
    "x": "firnayîş (n)",
    "y": " 1)kanatlandırma, uçurma\r\n~2)kaçırma (akıl vb. için)"
  },
  {
    "x": "firne (m)",
    "y": " burun deliği"
  },
  {
    "x": "firneberz, -e",
    "y": " kibirli, büyüklenen"
  },
  {
    "x": "firneberz bîyene",
    "y": " kibirlenmek"
  },
  {
    "x": "firneberzîye (m)",
    "y": " kibirlilik"
  },
  {
    "x": "firni (m)",
    "y": " b. firine (m)"
  },
  {
    "x": "firna eltîrikini (m)",
    "y": " b. firina elektrîkine (m)"
  },
  {
    "x": "firnike (m)",
    "y": " burun deliği"
  },
  {
    "x": "firnosa çarixî (m)",
    "y": " b. pirnosê çarixî (n)"
  },
  {
    "x": "firnosa solî (m)",
    "y": " b. pirnosê solî (n)"
  },
  {
    "x": "firong (m)",
    "y": " b. firingî (m)"
  },
  {
    "x": "firqate (m)",
    "y": " hayvana yüklenen odun vb. yükünde iki yandaki odun vb. dizilerinden her biri"
  },
  {
    "x": "firr dayene",
    "y": " b. fir dayene"
  },
  {
    "x": "firr kerdene",
    "y": " b. fir kerdene"
  },
  {
    "x": "firrayene",
    "y": " b. firayene"
  },
  {
    "x": "firrayîş (n)",
    "y": " b. firayîş (n)"
  },
  {
    "x": "firrdayîş (n)",
    "y": " b. firdayîş (n)"
  },
  {
    "x": "firri (m)",
    "y": " b. firte (m)"
  },
  {
    "x": "firrnayene",
    "y": " b. firnayene"
  },
  {
    "x": "firrnayîş (n) ",
    "y": " b. firnayîş (n)"
  },
  {
    "x": "firsat (n)",
    "y": " b. firsend (n)"
  },
  {
    "x": "firsend (n)",
    "y": " fırsat"
  },
  {
    "x": "firset",
    "y": " b. firsend (n)"
  },
  {
    "x": "firşik (n)",
    "y": " pankreas"
  },
  {
    "x": "firt (m)",
    "y": " b. firte (m)"
  },
  {
    "x": "firte (m)",
    "y": " yudum"
  },
  {
    "x": "firtine (n)",
    "y": " fırtına"
  },
  {
    "x": "firtineyê qumî (n)",
    "y": " kum fırtınası"
  },
  {
    "x": "firtineyê xîzî (n)",
    "y": " kum fırtınası"
  },
  {
    "x": "firtiqalêri (m)",
    "y": " b. porteqalêre (m)"
  },
  {
    "x": "firtiqali (m)",
    "y": " b. porteqale (m)"
  },
  {
    "x": "firtone (n)",
    "y": " b. firtine (n)"
  },
  {
    "x": "firûn (m)",
    "y": " b. firine (m)"
  },
  {
    "x": "firûni (m)",
    "y": " b. firine (m)"
  },
  {
    "x": "firze (n)",
    "y": "  b. firêze (n)"
  },
  {
    "x": "fise ci sikitene",
    "y": " b. fise şikitene …"
  },
  {
    "x": "fise ci verdayene",
    "y": " b. fise verdayene ..."
  },
  {
    "x": "fise piro kerdene",
    "y": " (erkek kuş, horoz vb. için) çiftleşmek "
  },
  {
    "x": "fise şikitene …",
    "y": "(erkek kuş, horoz vb. için) çiftleşmek"
  },
  {
    "x": "fise verdayene ...",
    "y": " (erkek kuş, horoz vb. için) çiftleşmek"
  },
  {
    "x": "fiskayene",
    "y": " b. fişkayene"
  },
  {
    "x": "fişkayîş (n)",
    "y": " hapşırma"
  },
  {
    "x": "fisqî (n)",
    "y": " b. fişqî (n)"
  },
  {
    "x": "fisqîya (m)",
    "y": " fıskıye"
  },
  {
    "x": "fisqîyayin, -e",
    "y": " fıskıyeli"
  },
  {
    "x": "fistan (n)",
    "y": " b. fîstan (n)"
  },
  {
    "x": "fistene",
    "y": " b. fîştene"
  },
  {
    "x": "fistiş (n)",
    "y": " b. fîştiş (n)"
  },
  {
    "x": "fistik (n)",
    "y": " keş eritilirken erimeyip geriye kalan parçacık"
  },
  {
    "x": "fistiq (m)",
    "y": " b. fistiqe (m)"
  },
  {
    "x": "fistiqe (m)",
    "y": " fıstık"
  },
  {
    "x": "fistiqêre (m)",
    "y": " fıstık ağacı "
  },
  {
    "x": "fistiqêri (m)",
    "y": " b. fistiqêre (m)"
  },
  {
    "x": "fistiqi (m)",
    "y": " b. fistiqe (m)"
  },
  {
    "x": "fistiqyer (m)",
    "y": " b. fistiqêre (m)"
  },
  {
    "x": "fistiş (n)",
    "y": " b. fîştiş (n)"
  },
  {
    "x": "fistix (m)",
    "y": " b. fistiqe (m)"
  },
  {
    "x": "fiston (n)",
    "y": " b. fîstan (n)"
  },
  {
    "x": "fistûn (n)",
    "y": " b. fîstan (n)"
  },
  {
    "x": "fistûni (m)",
    "y": " b. fîstan (n)"
  },
  {
    "x": "fişi (m)",
    "y": " b. pişike (m)"
  },
  {
    "x": "fişkayene",
    "y": " hapşırmak, aksırmak"
  },
  {
    "x": "fişkayîş (n)",
    "y": " aksırma, aksırış"
  },
  {
    "x": "fişqe (n)",
    "y": " b. fişqî (n)"
  },
  {
    "x": "fişqi (n)",
    "y": " b. fişqî (n)"
  },
  {
    "x": "fişqî (n)",
    "y": " fışkı"
  },
  {
    "x": "fişta (n)",
    "y": " b. fîstan (n)"
  },
  {
    "x": "fişte (n)",
    "y": " b. eşte (n)"
  },
  {
    "x": "fiştene",
    "y": " b. fîştene"
  },
  {
    "x": "fiştiş (n)",
    "y": " b. fîştiş (n)"
  },
  {
    "x": "fitar-I (n)",
    "y": " iftar"
  },
  {
    "x": "fitar kerdene",
    "y": " iftar etmek"
  },
  {
    "x": "fitar rakerdene",
    "y": " iftar etmek"
  },
  {
    "x": "fitar, -e-II",
    "y": " oruçsuz"
  },
  {
    "x": "fitar bîyene",
    "y": " oruçsuz olmak"
  },
  {
    "x": "fitara (m)",
    "y": " b. fitar (n)"
  },
  {
    "x": "fitara rakerdene",
    "y": " b. fitar rakerdene"
  },
  {
    "x": "fitare-I (m)",
    "y": " b. fitar (n)"
  },
  {
    "x": "fitare kerdene",
    "y": " b. fitar kerdene"
  },
  {
    "x": "fitare-II (m)",
    "y": " oruçsuz (kız veya kadın)"
  },
  {
    "x": "fitarî (m)",
    "y": " b. fitar (n)"
  },
  {
    "x": "fitarî kerdene",
    "y": " b. fitar kerdene"
  },
  {
    "x": "fîtene",
    "y": " b. fîştene"
  },
  {
    "x": "fites (n)",
    "y": " b. vitês (n)"
  },
  {
    "x": "fitêze (m)",
    "y": " b. vitês (n)"
  },
  {
    "x": "fitil (n)",
    "y": " b. fîtîl (n)"
  },
  {
    "x": "fitilê lamba (n)",
    "y": " b. fîtîlê lamba (n)"
  },
  {
    "x": "fitiq (n)",
    "y": " fıtık"
  },
  {
    "x": "fitir (n)",
    "y": " teneke veya tahtadan yapılan bir ölçüm aracı"
  },
  {
    "x": "fitî (m)",
    "y": " bir çeşit kuşak "
  },
  {
    "x": "fitîl (n)",
    "y": " b. patîre (n)"
  },
  {
    "x": "fitîle (m)",
    "y": " çile (iplik vb. çilesi)"
  },
  {
    "x": "fitîli-I (m)",
    "y": " b. fitîle (m)"
  },
  {
    "x": "fitîli-II (m)",
    "y": " b. fîtîle (n)"
  },
  {
    "x": "fitîla lamba (m)",
    "y": " b. fîtîlê lamba (n)"
  },
  {
    "x": "fitîr (n)",
    "y": " b. patîre (n)"
  },
  {
    "x": "fitna (m)",
    "y": " ara bozucu, arabozan, fesat, fesatçı, fitne, fitneci, müfsit¸ nifakçı"
  },
  {
    "x": "fitne, -ye",
    "y": " ara bozucu, arabozan, fesat, fesatçı, fitne, fitneci, müfsit, nifakçı"
  },
  {
    "x": "fitqali (m)",
    "y": " b. porteqale (m)"
  },
  {
    "x": "fitraf (n)",
    "y": " b. fotograf (n)"
  },
  {
    "x": "fitrî, -ye",
    "y": " doğuştan, fıtri¸ yaratılıştan"
  },
  {
    "x": "fituke (m)",
    "y": " bir çeşit kuşak"
  },
  {
    "x": "fizil, -e",
    "y": " ara bozucu, arabozan, fesat, fesatçı, fitne, fitneci, müfsit, nifakçı\r\n~2)b. fekfuzul, -e"
  },
  {
    "x": "fizilên (m)",
    "y": " b. fizilênî (m)"
  },
  {
    "x": "fizilênî (m)",
    "y": " dedikodu"
  },
  {
    "x": "fizilîye (m)",
    "y": " dedikodu"
  },
  {
    "x": "fîdar kerdene",
    "y": " b. fîrar kerdene"
  },
  {
    "x": "fîdarkerdiş (n)",
    "y": " b. fîrarkerdiş (n)"
  },
  {
    "x": "fîet (n)",
    "y": " b. fîyet (n)"
  },
  {
    "x": "fîgur (n)",
    "y": " figür"
  },
  {
    "x": "fîguran, -e",
    "y": " figüran"
  },
  {
    "x": "Fîjî",
    "y": " Fiji "
  },
  {
    "x": "fîkî (n)",
    "y": " b. fêkî (n)"
  },
  {
    "x": "fîksîyon (n)",
    "y": " kurmaca, yapıntı, fiksiyon"
  },
  {
    "x": "fîl, -e",
    "y": " fil"
  },
  {
    "x": "fîlar, -e",
    "y": " b. fîrar, -e"
  },
  {
    "x": "fîlar kerdene",
    "y": " b. fîrar kerdene"
  },
  {
    "x": "fîlarkerdiş (n)",
    "y": " b. fîrarkerdiş (n)"
  },
  {
    "x": "fîle (m)",
    "y": " file"
  },
  {
    "x": "fîlik (n)",
    "y": " tiftik"
  },
  {
    "x": "kilawa fîlikêne (m)",
    "y": " tiftik külah"
  },
  {
    "x": "kulika fîlikêne (m)",
    "y": " tiftik külah"
  },
  {
    "x": "fîlikin, -e",
    "y": " tiftikli"
  },
  {
    "x": "fîlim (n)",
    "y": " b. fîlm (n)"
  },
  {
    "x": "fîlîm (n)",
    "y": " b. fîlm (n)"
  },
  {
    "x": "Fîlîpîn (n)",
    "y": " Filipin "
  },
  {
    "x": "fîlkete (m)",
    "y": " b. fîrkete (m)"
  },
  {
    "x": "fîlketi (m)",
    "y": " b. fîrkete (m)"
  },
  {
    "x": "fîlm (n)",
    "y": " film"
  },
  {
    "x": "fîlm antene",
    "y": " film çekmek"
  },
  {
    "x": "(fîlm) kay dayene",
    "y": " (film) oynatmak"
  },
  {
    "x": "(fîlm) kay kerdene",
    "y": " (film) oynamak"
  },
  {
    "x": "fîlm ra kareyêk",
    "y": " filmden bir kare"
  },
  {
    "x": "fîlmê bazirganîye (n)",
    "y": " ticari film"
  },
  {
    "x": "fîlmê domanan/qican/tutan",
    "y": " çocuk filmi"
  },
  {
    "x": "fîlmê “DV-CAM”î",
    "y": " DV-CAM film"
  },
  {
    "x": "fîlmê DVDyî",
    "y": " DVD film"
  },
  {
    "x": "fîlmê ecnebîyan",
    "y": " yabancı film"
  },
  {
    "x": "fîlmê HDVyî",
    "y": " HDV film"
  },
  {
    "x": "fîlmê komedî",
    "y": " komedi filmi"
  },
  {
    "x": "fîlmê kowboyan (n)",
    "y": " kovboy filmi, western"
  },
  {
    "x": "filmê kultî (n)",
    "y": " kült film"
  },
  {
    "x": "fîlmê produksîyonê 1925î (n)",
    "y": " 1925 yapımı film"
  },
  {
    "x": "fîlmê sayinsfîkşinî",
    "y": " bilimkurgu film"
  },
  {
    "x": "fîlmê xerîban",
    "y": " yabancı film"
  },
  {
    "x": "fîlmo belgeyî (n)",
    "y": " belgesel film"
  },
  {
    "x": "fîlmo beyaxkî",
    "y": " yabancı film"
  },
  {
    "x": "fîlmo bêveng",
    "y": " sessiz film"
  },
  {
    "x": "fîlmo dokumanter (n)",
    "y": " belgesel film"
  },
  {
    "x": "fîlmo erotîk",
    "y": " açık film, erotik film, erosçu film, müstehcen film"
  },
  {
    "x": "fîlmo fîksîyonkî",
    "y": " kurmaca film"
  },
  {
    "x": "fîlmo hîrêdimensîyonin",
    "y": " üç boyutlu film"
  },
  {
    "x": "fîlmo hîrêrehendin",
    "y": " üç boyutlu film"
  },
  {
    "x": "fîlmo kilm",
    "y": " kısa film"
  },
  {
    "x": "fîlmo kirr",
    "y": " kısa film"
  },
  {
    "x": "fîlmo muzîkal (n)",
    "y": " müzikal film"
  },
  {
    "x": "fîlmo pornografîk (n)",
    "y": " pornografik film"
  },
  {
    "x": "fîlmo rengin",
    "y": " renkli film"
  },
  {
    "x": "fîlmo serrastîvîn/serraştîvîn (n)",
    "y": " gerçeküstücü film, sürrealist film"
  },
  {
    "x": "fîlmo surrealîst (n)",
    "y": " gerçeküstücü film, sürrealist film"
  },
  {
    "x": "fîlmo xoser (n)",
    "y": " bağımsız film"
  },
  {
    "x": "fîlmo tewr baş",
    "y": " en iyi film"
  },
  {
    "x": "fîlmo tewr başo beyaxkî/ecnebî",
    "y": " en iyi yabancı film"
  },
  {
    "x": "fîlmo tewr weş",
    "y": " en iyi film"
  },
  {
    "x": "fîlmo tewr weşo beyaxkî/ecnebî",
    "y": " en iyi yabancı film"
  },
  {
    "x": "rejîsora fîlmî (m)",
    "y": " film yönetmeni (kadın)"
  },
  {
    "x": "rejîsorê fîlmî (n)",
    "y": " film yönetmeni (erkek)"
  },
  {
    "x": "fîlmkêşî (m, sn)",
    "y": " çekim"
  },
  {
    "x": "fîlmname (n, sn)",
    "y": " senaryo"
  },
  {
    "x": "fîlmviraştox, -e",
    "y": " film yapımcısı"
  },
  {
    "x": "fîlmwendiş (n, sn)",
    "y": " film okuma"
  },
  {
    "x": "fîlozof, -e",
    "y": " filozof"
  },
  {
    "x": "fîltre (n)",
    "y": " süzgeç, süzek, filtre"
  },
  {
    "x": "fîltreyê gemarinîya hewayî (n)",
    "y": " hava kirliliği süzgeci"
  },
  {
    "x": "fîltreyê qumî (n)",
    "y": " kum filtresi"
  },
  {
    "x": "fînal (n)",
    "y": " bitiş, final"
  },
  {
    "x": "fînans (n)",
    "y": " finans"
  },
  {
    "x": "fînansker, -e",
    "y": " finansör"
  },
  {
    "x": "fînansor, -e",
    "y": " finansör"
  },
  {
    "x": "fîncan (n)",
    "y": " b. fîncane (m)"
  },
  {
    "x": "fîncane (m)",
    "y": " fincan"
  },
  {
    "x": "fîncana porselenêne (m)",
    "y": " porselen fincan"
  },
  {
    "x": "fîncike-I (m)",
    "y": " küçük tas"
  },
  {
    "x": "fîncike-II (m)",
    "y": " b. pîncike (m)"
  },
  {
    "x": "fîncike estene",
    "y": " b. pîncike eştene"
  },
  {
    "x": "fînconi (m)",
    "y": " fîncane (m)"
  },
  {
    "x": "fîncûn (m)",
    "y": " fîncane (m)"
  },
  {
    "x": "fînik (n)",
    "y": " yaşı altı aydan daha fazla olan köpek yavrusu "
  },
  {
    "x": "fînî (m)",
    "y": " fes"
  },
  {
    "x": "Fînlanda (m)",
    "y": " Finlandiya"
  },
  {
    "x": "fîrar, -e",
    "y": " firar, kaçak"
  },
  {
    "x": "firar kerdene",
    "y": " firar etmek, kaçmak, kaçıp kurtulmak"
  },
  {
    "x": "fîrari (m)",
    "y": " b. fîrar, -e"
  },
  {
    "x": "fîrarî, -ye",
    "y": " kaçak"
  },
  {
    "x": "fîrarkerdiş (n)",
    "y": " firar etme, kaçma, kaçıp kurtulma"
  },
  {
    "x": "fîraset (n)",
    "y": " b. feraset (n)"
  },
  {
    "x": "fîraz, -e",
    "y": " kutlu, mübarek, fetiş (uğurlu sayılan)\r\n~Roşanê to fîraz bo. (Bayramın kutlu olsun.)"
  },
  {
    "x": "fîraz kerdene",
    "y": " kutlamak"
  },
  {
    "x": "fîrazkerdiş (n)",
    "y": " kutlama"
  },
  {
    "x": "fîreze (n)",
    "y": " b. firêze (n)"
  },
  {
    "x": "fîrêzi (n)",
    "y": " b. firêze (n)"
  },
  {
    "x": "fîrket",
    "y": " b. fîrkete (m)"
  },
  {
    "x": "fîrketa (m)",
    "y": " b. fîrkete (m)"
  },
  {
    "x": "fîrketane (m)",
    "y": " b. fîrkete (m)"
  },
  {
    "x": "fîrkete (m)",
    "y": " çengelliiğne, firkete"
  },
  {
    "x": "fîrketi (m)",
    "y": " b. fîrkete (m)"
  },
  {
    "x": "fîrqefîrq",
    "y": " hüngür hüngür"
  },
  {
    "x": "fîrqefîrq a bermayene",
    "y": " hüngür hüngür ağlamak"
  },
  {
    "x": "fîrqefîrqabermayîş (n)",
    "y": " hüngür hüngür ağlama"
  },
  {
    "x": "fîryeze (n)",
    "y": " b. firêze (n)"
  },
  {
    "x": "fîseg (m)",
    "y": " b. fîşeke (m)"
  },
  {
    "x": "fîsege (m)",
    "y": " b. fîşeke (m)"
  },
  {
    "x": "fîsegi (m)",
    "y": " b. fîşeke (m)"
  },
  {
    "x": "fîseke (m)",
    "y": " b. fîşeke (m)"
  },
  {
    "x": "fîsk (m)",
    "y": " b. fîske (m)"
  },
  {
    "x": "fîske (m)",
    "y": " hıçkırık"
  },
  {
    "x": "fîsna (m)",
    "y": " b. wişna (m)"
  },
  {
    "x": "fîstan (n)",
    "y": " fistan"
  },
  {
    "x": "fîste (n)",
    "y": " b. eşte (n)"
  },
  {
    "x": "fîstene",
    "y": " b. fîştene"
  },
  {
    "x": "fîstiş (n)",
    "y": " b. fîştiş (n)"
  },
  {
    "x": "fîston (n)",
    "y": " b. fîstan (n)"
  },
  {
    "x": "fîstûn (m)",
    "y": " b. fîstan (n)"
  },
  {
    "x": "fîş (n)",
    "y": " b. fîşe (m)"
  },
  {
    "x": "fîşe (m)",
    "y": " fiş"
  },
  {
    "x": "fîşa muamela hesabdarîye (m)",
    "y": " muhasebe işlem fişi"
  },
  {
    "x": "fîşa muamela muhasebeyî (m)",
    "y": " muhasebe işlem fişi"
  },
  {
    "x": "fîşa waştişî (m)",
    "y": " talep fişi"
  },
  {
    "x": "fîşege (m)",
    "y": " b. fîşeke (m)"
  },
  {
    "x": "fîşegi (m)",
    "y": " b. fîşeke (m)"
  },
  {
    "x": "fîşeke (m)",
    "y": " fişek"
  },
  {
    "x": "fîşeki (m)",
    "y": " b. fîşeke (m)"
  },
  {
    "x": "fîşeng (m)",
    "y": " b. fîşeke (m)"
  },
  {
    "x": "fîşna (m)",
    "y": " b. wişna (m)"
  },
  {
    "x": "fîşon (n)",
    "y": " şîfon (n)"
  },
  {
    "x": "fîşone (m)",
    "y": " b. şîfone (m)"
  },
  {
    "x": "fîştaqe (m)",
    "y": " orospu, fahişe, kahpe, sürtük"
  },
  {
    "x": "fîştaqey (m)",
    "y": " b. fîştaqî (m), fîştaqîye (m)"
  },
  {
    "x": "fîştaqê (m)",
    "y": " b. fîştaqî (m), fîştaqîye (m)"
  },
  {
    "x": "fîştaqi (m)",
    "y": " b. fîştaqe (m)"
  },
  {
    "x": "fîştaqî (m)",
    "y": " orospuluk, fahişelik, kahpelik, sürtüklük"
  },
  {
    "x": "fîştaqîye (m)",
    "y": " orospuluk, fahişelik, kahpelik, sürtüklük"
  },
  {
    "x": "fîşte (n)",
    "y": " b. eşte (n)"
  },
  {
    "x": "fîştene",
    "y": " atmak"
  },
  {
    "x": "fîşteqey (m)",
    "y": " b. fîştaqî (m), fîştaqîye (m)"
  },
  {
    "x": "fîşteqê (m)",
    "y": " b. fîştaqî (m), fîştaqîye (m)"
  },
  {
    "x": "fîşteqi (m)",
    "y": " b. fîştaqe (m)"
  },
  {
    "x": "fîştik eştene",
    "y": " zıplayıp koşarak tekme atmak (hayvan için)"
  },
  {
    "x": "fîştiş (n)",
    "y": " atma"
  },
  {
    "x": "fîtes (n)",
    "y": " b. vitês (n)"
  },
  {
    "x": "fîtil (n)",
    "y": " b. fîtîl (n)"
  },
  {
    "x": "fîtilê lamba (n)",
    "y": " b. fîtîlê lamba (n)"
  },
  {
    "x": "fîtîl (n)",
    "y": " fitil"
  },
  {
    "x": "fîtîlê lamba (n)",
    "y": " lamba fitili"
  },
  {
    "x": "fîtîli (m)",
    "y": " b. fîtîl (n)"
  },
  {
    "x": "fîtîley lamba (m)",
    "y": " b. fîtîlê lamba (n)"
  },
  {
    "x": "fîtnes (n)",
    "y": " fitness"
  },
  {
    "x": "fîyaqa (m)",
    "y": " b. fîyaqe (n)"
  },
  {
    "x": "fîyaqe (n)",
    "y": " afi, caka, fiyaka, çalım, gösteriş"
  },
  {
    "x": "fîyaqe kerdene",
    "y": " afi kesmek, afi satmak, afi yapmak, caka satmak, caka yapmak, fiyaka satmak, çalım satmak, gösteriş yapmak"
  },
  {
    "x": "fîyat (n)",
    "y": " b. fîyet (n)"
  },
  {
    "x": "fîyek (n)",
    "y": " b. fêkî (n)"
  },
  {
    "x": "fîyekî (n)",
    "y": " b. fêkî (n)"
  },
  {
    "x": "fîyet (n)",
    "y": " 1)fiyat, paha\r\n~2)bedel"
  },
  {
    "x": "fîyetê erjnayîşê pêşnîyaze (zh)",
    "y": " teklif değerlendirme fiyatları"
  },
  {
    "x": "fîzîk (n)",
    "y": " fizik"
  },
  {
    "x": "fîzîkî, -ye",
    "y": " fiziki, fiziksel"
  },
  {
    "x": "fîzîknas, -e",
    "y": " fizikçi"
  },
  {
    "x": "fîzyolog, -e",
    "y": " fizyolog, fizyolojist"
  },
  {
    "x": "fîzyolojî (n)",
    "y": " fizyoloji"
  },
  {
    "x": "fîzyolojîk, -e",
    "y": " fizyolojik"
  },
  {
    "x": "fîzyolojîst, -e",
    "y": " fizyolog, fizyolojist"
  },
  {
    "x": "flamîngo (n)",
    "y": " flamingo, flaman kuşu"
  },
  {
    "x": "flanş (n)",
    "y": " flanş "
  },
  {
    "x": "flaşbek (n, sn)",
    "y": " geriye dönüş"
  },
  {
    "x": "floresan (n)",
    "y": " floresan, flüorışıl "
  },
  {
    "x": "flutbend, -e",
    "y": " flütçü"
  },
  {
    "x": "flute (m)",
    "y": " flüt"
  },
  {
    "x": "fobî (m)",
    "y": " fobi, yılgı"
  },
  {
    "x": "fobîyê sosyalî (zh)",
    "y": " sosyal fobi"
  },
  {
    "x": "fok (n)",
    "y": " fok "
  },
  {
    "x": "fokus (n)",
    "y": " odak"
  },
  {
    "x": "fokus bîyene",
    "y": " odaklanmak, odaklaşmak"
  },
  {
    "x": "fokusbîyayîş (n)",
    "y": " odaklanma, odaklaşma"
  },
  {
    "x": "folklor (n)",
    "y": " folklor, halkbilim"
  },
  {
    "x": "folklorîst, -e",
    "y": " folklorcu, halkbilimci"
  },
  {
    "x": "fom (n)",
    "y": " b. fehm (n)"
  },
  {
    "x": "fom bîyayene",
    "y": " b. fehm bîyene"
  },
  {
    "x": "fom  kerdene",
    "y": " b. fehm kerdene"
  },
  {
    "x": "fombîyayîş (n)",
    "y": " b. fehmbîyayîş (n)"
  },
  {
    "x": "fomdar, -e",
    "y": " b. fehmdar, -e"
  },
  {
    "x": "fomdarîye (m)",
    "y": " b. fehmdarîye (m)"
  },
  {
    "x": "fomkerdiş (n)",
    "y": " b. fehmkerdiş (n)"
  },
  {
    "x": "fon (n)",
    "y": " 1)fon\r\n~2)fön"
  },
  {
    "x": "fon kerdene",
    "y": " fön çekmek"
  },
  {
    "x": "fonîle (n)",
    "y": " b. fanîle (n)"
  },
  {
    "x": "fonksîyon (n)",
    "y": " fonksiyon, işlev"
  },
  {
    "x": "fonksîyonel, -e",
    "y": " işlevsel"
  },
  {
    "x": "fonos (n)",
    "y": " b. fanose (m)"
  },
  {
    "x": "fonosi (m)",
    "y": " b. fanose (m)"
  },
  {
    "x": "form (n)",
    "y": " biçim, şekil, form"
  },
  {
    "x": "form dayene",
    "y": " biçimlendirmek, şekillendirmek"
  },
  {
    "x": "form girewtene",
    "y": " biçimlenmek, şekillenmek"
  },
  {
    "x": "formê dîdarî (n)",
    "y": " görüşme formu"
  },
  {
    "x": "formê kontrolî (n)",
    "y": " kontrol formu"
  },
  {
    "x": "formê mulaqatî (n)",
    "y": " görüşme formu"
  },
  {
    "x": "formê muracatî (n)",
    "y": " başvuru formu"
  },
  {
    "x": "forma (m)",
    "y": " forma (giysi için)"
  },
  {
    "x": "formalîst, -e",
    "y": " biçimci, şekilci"
  },
  {
    "x": "formalîte (m)",
    "y": " formalite"
  },
  {
    "x": "formalîzm (n)",
    "y": " biçimcilik"
  },
  {
    "x": "formdayîş (n)",
    "y": " biçimlendirme, formdayîş (n)"
  },
  {
    "x": "formgirewtiş (n)",
    "y": " biçimlenme, şekillenme"
  },
  {
    "x": "formul (n)",
    "y": " formül"
  },
  {
    "x": "formule kerdene",
    "y": " formüle etmek"
  },
  {
    "x": "formulasyon (n)",
    "y": " formülasyon"
  },
  {
    "x": "formulasyonê projeyî (n)",
    "y": " proje formülasyonu"
  },
  {
    "x": "forq (n)",
    "y": " (mecazi) orospu, fahişe, kahpe, sürtük"
  },
  {
    "x": "forqe (m)",
    "y": " orospu, fahişe, kahpe, sürtük"
  },
  {
    "x": "forqî (m)",
    "y": " orospuluk, fahişelik, kahpelik, sürtüklük"
  },
  {
    "x": "forqîye (m)",
    "y": " orospuluk, fahişelik, kahpelik, sürtüklük"
  },
  {
    "x": "fors (n)",
    "y": " afi, caka, fiyaka, çalım, gösteriş, gösterişlilik, fors"
  },
  {
    "x": "fors kerdene",
    "y": " afi kesmek, afi satmak, afi yapmak, caka satmak, caka yapmak, fiyaka satmak, çalım satmak, gösteriş yapmak"
  },
  {
    "x": "fos, -e",
    "y": " bozuk"
  },
  {
    "x": "fose (m)",
    "y": " b. fese (m)"
  },
  {
    "x": "foseptîk (n)",
    "y": " fosseptik, lağım çukuru"
  },
  {
    "x": "foser (n)",
    "y": " b. efsûn (n)"
  },
  {
    "x": "fosî (m)",
    "y": " bozukluk"
  },
  {
    "x": "fosîl (n)",
    "y": " fosil, taşıl"
  },
  {
    "x": "fosîye (m)",
    "y": " bozukluk "
  },
  {
    "x": "fotêli (m)",
    "y": " b. fotêre (m)"
  },
  {
    "x": "fotêre (m)",
    "y": " fötr (fötr şapka)"
  },
  {
    "x": "fotixraf (n)",
    "y": " b. fotograf (n)"
  },
  {
    "x": "fotograf (n)",
    "y": " fotoğraf"
  },
  {
    "x": "fotografê belgeyan (n)",
    "y": " vesikalık fotoğraf"
  },
  {
    "x": "fotografê wesîqeyan (n)",
    "y": " vesikalık fotoğraf"
  },
  {
    "x": "fotografkêş, -e",
    "y": " fotoğrafçı"
  },
  {
    "x": "fotometre (n)",
    "y": " ışıkölçer, fotometre"
  },
  {
    "x": "fotoqiraf (n)",
    "y": " b. fotograf (n)"
  },
  {
    "x": "fotoraf (n)",
    "y": " b. fotograf (n)"
  },
  {
    "x": "fotoxraf (n)",
    "y": " b. fotograf (n)"
  },
  {
    "x": "fotqiraf (n)",
    "y": " b. fotograf (n)"
  },
  {
    "x": "fotqirav (n)",
    "y": " b. fotograf (n)"
  },
  {
    "x": "fotraf (n)",
    "y": " b. fotograf (n)"
  },
  {
    "x": "FR (Frekansê Radyoyî)",
    "y": " RF (Radyo Frekansı)"
  },
  {
    "x": "fragman (n, sn)",
    "y": " fragman, treyler"
  },
  {
    "x": "frak (n)",
    "y": " frak"
  },
  {
    "x": "fraksîyon (n)",
    "y": " fraksiyon, hizip (sosyolojide), klik"
  },
  {
    "x": "Fransa (m)",
    "y": " Fransa "
  },
  {
    "x": "fransiz, -e",
    "y": " Fransız"
  },
  {
    "x": "franskî (m)",
    "y": " Fransızca"
  },
  {
    "x": "frekans (n)",
    "y": " frekans "
  },
  {
    "x": "frekansê cewabî (n)",
    "y": " cevap frekansı"
  },
  {
    "x": "fren (n)",
    "y": " fren"
  },
  {
    "x": "fren kerdene",
    "y": " frenlemek"
  },
  {
    "x": "frenê destî (n)",
    "y": " el freni"
  },
  {
    "x": "frenê payî (n)",
    "y": " ayak freni"
  },
  {
    "x": "frengî (n)",
    "y": " b. firengî (n)"
  },
  {
    "x": "frên (m)",
    "y": " b. fren (n)"
  },
  {
    "x": "frêne (m)",
    "y": " b. fren (n)"
  },
  {
    "x": "frêni (m)",
    "y": " b. fren (n)"
  },
  {
    "x": "Frîtawn (n)",
    "y": " Freetown"
  },
  {
    "x": "frîtoz (n)",
    "y": " fritöz"
  },
  {
    "x": "fuar (n)",
    "y": " fuar"
  },
  {
    "x": "fuare (m)",
    "y": " b. fuar (n)"
  },
  {
    "x": "fulare (m)",
    "y": " fular"
  },
  {
    "x": "fumîgant (n)",
    "y": " fumigant"
  },
  {
    "x": "funduq (m)",
    "y": " b. findiqe (m)"
  },
  {
    "x": "funduqyer (m)",
    "y": " b. findiqêre (m)"
  },
  {
    "x": "fuqara (m)",
    "y": " b. fiqara (m)"
  },
  {
    "x": "fuqare (n)",
    "y": " b. fiqare (n)"
  },
  {
    "x": "futbol (n)",
    "y": " futbol"
  },
  {
    "x": "futbolbaz, -e",
    "y": " futbolcu"
  },
  {
    "x": "futurîst, -e",
    "y": " gelecekçi, fütürist, "
  },
  {
    "x": "futurîzm (n)",
    "y": " gelecekçilik, fütürizm "
  },
  {
    "x": "futurolojî (n)",
    "y": " gelecekbilimi, fütüroloji"
  },
  {
    "x": "fuzul",
    "y": " b. fekfuzul, -e"
  },
  {
    "x": "fuzûlî",
    "y": " abes"
  },
  {
    "x": "fuzûlî bîyene",
    "y": " abes kaçmak"
  },
  {
    "x": "fuzyometre (n)",
    "y": " füzyometre"
  },
  {
    "x": "fûm (n)",
    "y": " b. fehm (n)"
  },
  {
    "x": "fûm bîyayene",
    "y": " b. fehm bîyene"
  },
  {
    "x": "fûm kerdene",
    "y": " b. fehm kerdene"
  },
  {
    "x": "fûmbîyayîş (n)",
    "y": " b. fehmbîyayîş (n)"
  },
  {
    "x": "fûmkerdiş (n)",
    "y": " b. fehmkerdiş (n)"
  },
  {
    "x": "fûneli (n)",
    "y": " b. fanîle (n)"
  },
  {
    "x": "fûnelî (n)",
    "y": " b. fanîle (n)"
  },
  {
    "x": "fûnosi (m)",
    "y": " b. fanose (m)"
  },
  {
    "x": "fûnûsi (m)",
    "y": " b. fanose (m)"
  },
  {
    "x": "fût (n)",
    "y": " ayak (30,5 cm), fit (30,5 cm)"
  },
  {
    "x": "fûtixraf (n)",
    "y": " b. fotograf (n)"
  },
  {
    "x": "fûtraf (n",
    "y": " b. fotograf (n)"
  },
  {
    "x": "GGG"
  },
  {
    "x": "g’-I",
    "y": " b. ke"
  },
  {
    "x": "g’-II",
    "y": " b. do-II"
  },
  {
    "x": "ga-I (n)",
    "y": " öküz"
  },
  {
    "x": "ga amyayeni",
    "y": " b. gayî ameyene"
  },
  {
    "x": "gawo mazêr (n)",
    "y": " b. gayo mazêr (n)"
  },
  {
    "x": "gay ameyîne",
    "y": " b. gayî ameyene"
  },
  {
    "x": "gay bîyayîne",
    "y": " b. bi gayî bîyene"
  },
  {
    "x": "gay dayene",
    "y": " b. gayî dayene"
  },
  {
    "x": "gayî (zh)",
    "y": " sığır"
  },
  {
    "x": "gayî amayene",
    "y": " b. gayî ameyene"
  },
  {
    "x": "gayî ameyene",
    "y": " kösnümek (inek için)   "
  },
  {
    "x": "gayî ameyîne",
    "y": " b. gayî ameyene"
  },
  {
    "x": "gayî dayene",
    "y": " 1)(inek ve düve için) çiftleştirmek\r\n~2)rezil etmek (argo)"
  },
  {
    "x": "gayo mazêr",
    "y": " yaşlı öküz"
  },
  {
    "x": "mal û gayî",
    "y": " davar ve sığır"
  },
  {
    "x": "ga-II (n)",
    "y": " b. gan (n)"
  },
  {
    "x": "gaamyayîş (n)",
    "y": " b. gayîameyîş (n)"
  },
  {
    "x": "gaban-I (n)",
    "y": " b. gavan (n)"
  },
  {
    "x": "gaban, -e-II",
    "y": " b. gawan, -e"
  },
  {
    "x": "gabar (n)",
    "y": " b. gavar (n)"
  },
  {
    "x": "gabarî (m)",
    "y": " gabari"
  },
  {
    "x": "gabo (n)",
    "y": " b. gavan (n)"
  },
  {
    "x": "Gabon (n)",
    "y": " Gabon"
  },
  {
    "x": "Gaborone (m)",
    "y": " Gaborone"
  },
  {
    "x": "gac (m)",
    "y": " b. gaje (m)"
  },
  {
    "x": "gace (m)",
    "y": " b. gaje (m)"
  },
  {
    "x": "gaci (m)",
    "y": " b. gaje (m)"
  },
  {
    "x": "gacik (n)",
    "y": " yük bağlamakta kullanılan urganın ucuna bağlanan çatallı ağaç parçası "
  },
  {
    "x": "gaçik (n)",
    "y": " b. gacik (n)"
  },
  {
    "x": "gagole (m)",
    "y": " bokböceği, pislik böceği, hapböceği"
  },
  {
    "x": "gagoli (m)",
    "y": " b. gagole (m)"
  },
  {
    "x": "gaje (m)",
    "y": " çile (iplik vb. çilesi)"
  },
  {
    "x": "gaji (m)",
    "y": " b. gaje (m)"
  },
  {
    "x": "gakovî, -ye",
    "y": " geyik"
  },
  {
    "x": "gakwîvî (n, m)",
    "y": " b. gakovî, -ye"
  },
  {
    "x": "gal (m)",
    "y": " b. gale (m)"
  },
  {
    "x": "gal ... kerdene",
    "y": " b. gala ... kerdene"
  },
  {
    "x": "gala (m)",
    "y": " gala"
  },
  {
    "x": "gale-I (m)",
    "y": " akın, saldırı, hücum"
  },
  {
    "x": "gala ... kerdene",
    "y": " akın etmek, saldırmak, hücum etmek"
  },
  {
    "x": "gale-II (n)",
    "y": " 1)büyük heybe\r\n~2)heybenin iki tarafından her biri"
  },
  {
    "x": "galekar, -e",
    "y": " saldırgan, agresif"
  },
  {
    "x": "galekarî (m)",
    "y": " saldırganlık, agresiflik"
  },
  {
    "x": "galerî (m)",
    "y": " galeri"
  },
  {
    "x": "galerîdar, -e",
    "y": " galerici"
  },
  {
    "x": "galerîye (m)",
    "y": " galeri"
  },
  {
    "x": "galgale (m)",
    "y": " sohbet, söyleşi"
  },
  {
    "x": "galgale kerdene",
    "y": " sohbet etmek"
  },
  {
    "x": "galgalekerdiş (n)",
    "y": " sohbet etme"
  },
  {
    "x": "gali (n)",
    "y": " b. gale-II (n)"
  },
  {
    "x": "galim (n)",
    "y": " akın, saldırı, hücum"
  },
  {
    "x": "galim kerdene",
    "y": " akın etmek, saldırmak, hücum etmek"
  },
  {
    "x": "galim pira nayene",
    "y": " akın etmek, saldırmak, hücum etmek "
  },
  {
    "x": "galime",
    "y": " b. galim (n)"
  },
  {
    "x": "galime pira nayene",
    "y": " b. galim pira nayene"
  },
  {
    "x": "galimkar, -e",
    "y": " saldırgan, agresif"
  },
  {
    "x": "galimkarîye (m)",
    "y": " saldırganlık, agresiflik"
  },
  {
    "x": "gallon (n)",
    "y": " gallon"
  },
  {
    "x": "galme (n)",
    "y": " b. galim (n)"
  },
  {
    "x": "galme kerdene",
    "y": " b. galim kerdene"
  },
  {
    "x": "galme pira nayene",
    "y": " b. galim pira nayene"
  },
  {
    "x": "galon (n)",
    "y": " b. gallon (n)"
  },
  {
    "x": "galoş (n)",
    "y": " galoş"
  },
  {
    "x": "galvanometre (n)",
    "y": " galvanometre"
  },
  {
    "x": "gam (m)",
    "y": " b. game (m)"
  },
  {
    "x": "Gambîya (m)",
    "y": " Gambiya"
  },
  {
    "x": "game (m)",
    "y": " 1)adım\r\n~2)an, lahza"
  },
  {
    "x": "game bi game",
    "y": " adım adım"
  },
  {
    "x": "game eştene",
    "y": " adım atmak"
  },
  {
    "x": "game vistene",
    "y": " b. game eştene"
  },
  {
    "x": "gamê caardişî (zh)",
    "y": " uygulama adımları"
  },
  {
    "x": "gamî kerdene",
    "y": " adımlamak"
  },
  {
    "x": "gamêş, -e",
    "y": " manda, camız"
  },
  {
    "x": "gamêş ameyene",
    "y": " kösnümek (manda için)"
  },
  {
    "x": "gamêşameyîş (n)",
    "y": " kösnüme (manda için)"
  },
  {
    "x": "gamêşi (m)",
    "y": " b. gamêşe (m)"
  },
  {
    "x": "gamêşqiran (n)",
    "y": " camuskıran (Rumi takvime göre 7 Nisan) "
  },
  {
    "x": "gami (m)",
    "y": " b. game (m)"
  },
  {
    "x": "gamîkerdiş (n)",
    "y": " adımlama"
  },
  {
    "x": "gampole (m)",
    "y": " b. gopale (m)"
  },
  {
    "x": "gan (n)",
    "y": " can"
  },
  {
    "x": "gan dayene",
    "y": " can vermek"
  },
  {
    "x": "gan fîda",
    "y": " can kurban"
  },
  {
    "x": "ganan ver de",
    "y": " ölümcül (can çekişmekte olan)"
  },
  {
    "x": "ganan ver de bîyene",
    "y": " can çekişmek, ölüm döşeğinde olmak"
  },
  {
    "x": "ganan ver di beyene",
    "y": " b. ganan ver de bîyene"
  },
  {
    "x": "gandê ... kewtene",
    "y": " bezmek, bezginlik getirmek, bıkmak¸ bizar olmak, usanmak"
  },
  {
    "x": "gandê ... kewtiş",
    "y": " bezme, bıkma, bizar olma, usanma"
  },
  {
    "x": "gandê xo ra bêzar bîyayene",
    "y": " b. ganê xo ra bêzar bîyene"
  },
  {
    "x": "gandê... vistene",
    "y": " bezdirmek¸ bıktırmak¸ usandırmak, canına tak demek, canına tak etmek"
  },
  {
    "x": "gandê... vistiş",
    "y": " bezdirme¸ bıktırma¸ usandırma"
  },
  {
    "x": "ganê ... kewtene",
    "y": " b. gandê ... kewtene"
  },
  {
    "x": "ganê ... kewtiş",
    "y": " b. gandê ... kewtiş"
  },
  {
    "x": "ganê ... vistene",
    "y": " b. gandê... vistene"
  },
  {
    "x": "ganê... vistiş",
    "y": " b. gandê... vistiş"
  },
  {
    "x": "ganê... werdene",
    "y": " bezdirmek, bıktırmak, usandırmak"
  },
  {
    "x": "ganê... werdiş",
    "y": " bezdirme, bıktırma, usandırma"
  },
  {
    "x": "ganê xo ra bêzar bîyene",
    "y": " canından bezmek"
  },
  {
    "x": "ganî ver de bîyene",
    "y": " can çekişmek, ölüm döşeğinde olmak"
  },
  {
    "x": "ganî ver di beyene",
    "y": " b. ganî ver de bîyene"
  },
  {
    "x": "ganî ver o bîyayîne",
    "y": " b. ganî ver o bîyene"
  },
  {
    "x": "ganî ver o bîyene",
    "y": " can çekişmek, ölüm döşeğinde olmak"
  },
  {
    "x": "Gana (m)",
    "y": " Gana"
  },
  {
    "x": "gananverdebîyayîş (n)",
    "y": " can çekişme"
  },
  {
    "x": "ganawir, -e",
    "y": " 1)canavar, vahşi hayvan\r\n~2)kurt"
  },
  {
    "x": "ganbexş, -e",
    "y": " can veren"
  },
  {
    "x": "gandar, -e",
    "y": " canlı"
  },
  {
    "x": "gandayîş (n)",
    "y": " can verme"
  },
  {
    "x": "gandil (n)",
    "y": " b. guwandil (n)"
  },
  {
    "x": "gane",
    "y": " b. ganî, -ye"
  },
  {
    "x": "ganêr, -e",
    "y": " yiğit, gözü pek, acar (gözü pek)"
  },
  {
    "x": "ganêrîye (m)",
    "y": " gözü peklik"
  },
  {
    "x": "ganfîda, -ye",
    "y": " can feda (mecaz) "
  },
  {
    "x": "Gangêr (n)",
    "y": " Azrail"
  },
  {
    "x": "Gangêrî ver de",
    "y": " ölümcül (can çekişmekte olan)"
  },
  {
    "x": "Gangêrî ver de bîyene",
    "y": " can çekişmek"
  },
  {
    "x": "Gangêrîverdebîyayîş (n)",
    "y": " can çekişme"
  },
  {
    "x": "gangiran, -e",
    "y": " ağırcanlı, ağırkanlı"
  },
  {
    "x": "gangiranê (m)",
    "y": " b. gangiranîye (m)"
  },
  {
    "x": "gangiranîye (m)",
    "y": " ağırcanlılık, ağırkanlılık"
  },
  {
    "x": "Gangîr (n)",
    "y": " Gangêr (n)"
  },
  {
    "x": "gangoştin, -e",
    "y": " balıketinde, balıketi, dolgun"
  },
  {
    "x": "ganî, -ye-I",
    "y": " canlı, capcanlı (zr)"
  },
  {
    "x": "ganî kerdene",
    "y": " canlandırmak"
  },
  {
    "x": "ganî ver de",
    "y": " ölümcül (can çekişmekte olan)"
  },
  {
    "x": "ganî ver de bîyene",
    "y": " can çekişmek"
  },
  {
    "x": "ganî ver o bîyene",
    "y": " can çekişmek"
  },
  {
    "x": "ganî-II",
    "y": " gerek, icap\r\n~Eke ti wazenî ganî ti vajî.(Şayet istiyorsan söylemen gerekir.)"
  },
  {
    "x": "ganîker, -e",
    "y": " gerektiren, gerektirici, mucip"
  },
  {
    "x": "ganîkerê/a raywanîye",
    "y": " seyahat mucibi"
  },
  {
    "x": "ganîkerê/a rêwîtî",
    "y": " seyahat mucibi"
  },
  {
    "x": "ganîkerdiş (n)",
    "y": " canlandırma, animasyon"
  },
  {
    "x": "ganîverdebîyayîş (n)",
    "y": " can çekişme"
  },
  {
    "x": "ganîverobîyayîş (n)",
    "y": " can çekişme"
  },
  {
    "x": "ganîyîyê",
    "y": " gereklik"
  },
  {
    "x": "gankêş, -e",
    "y": " ölümcül (can çekişmekte olan), can çekişen"
  },
  {
    "x": "gankêş de bîyene",
    "y": " can çekişmek"
  },
  {
    "x": "gankêşdebîyayîş (n)",
    "y": " can çekişme"
  },
  {
    "x": "gankêşênî (m)",
    "y": " can çekişme"
  },
  {
    "x": "gankêşî (m)",
    "y": " can çekişme"
  },
  {
    "x": "gankêşîye (m)",
    "y": " can çekişme"
  },
  {
    "x": "gannazik, -e",
    "y": " canı tatlı"
  },
  {
    "x": "ganpole (m)",
    "y": " b. gopale (m)"
  },
  {
    "x": "gansivik, -e",
    "y": " atik, çevik, canlı"
  },
  {
    "x": "gansivikî (m)",
    "y": " atiklik, çeviklik"
  },
  {
    "x": "gansivikîye (m)",
    "y": " atiklik, çeviklik"
  },
  {
    "x": "ganşenik, -e",
    "y": " atik, çevik"
  },
  {
    "x": "ganşenikî (m)",
    "y": " atiklik, çeviklik"
  },
  {
    "x": "ganşenikîye (m)",
    "y": " atiklik, çeviklik"
  },
  {
    "x": "ganûgoştin, -e",
    "y": " 1)balıketinde, balıketi, dolgun\r\n~2)şişman"
  },
  {
    "x": "ganxelasker (n)",
    "y": " cankurtaran, ambülans"
  },
  {
    "x": "ganweşîye (m)",
    "y": " can sağlığı"
  },
  {
    "x": "gar (n)",
    "y": " gar "
  },
  {
    "x": "garaj (n)",
    "y": " garaj"
  },
  {
    "x": "garane (m)",
    "y": " sürü, sığır sürüsü"
  },
  {
    "x": "garantî (m)",
    "y": " garanti, teminat"
  },
  {
    "x": "garantî dayene",
    "y": " garanti vermek, teminat vermek"
  },
  {
    "x": "garantî kerdene",
    "y": " garanti etmek, garantilemek"
  },
  {
    "x": "gardirobe (m)",
    "y": " gardırop, elbise dolabı, giysi dolabı"
  },
  {
    "x": "gardîyan, -e",
    "y": " gardiyan"
  },
  {
    "x": "gardîyanîye (m)",
    "y": " gardiyanlık"
  },
  {
    "x": "gardolabe (m)",
    "y": " b. gardirobe (m)"
  },
  {
    "x": "gargar (n)",
    "y": " tartışma"
  },
  {
    "x": "gargar kerdene",
    "y": " atışmak, tartışmak"
  },
  {
    "x": "gargarkerdiş (n)",
    "y": " tartışma"
  },
  {
    "x": "gargaro (n)",
    "y": " b. garo (n)"
  },
  {
    "x": "garm (n)",
    "y": " b. germ (n)"
  },
  {
    "x": "garm bîyayene",
    "y": " b. germ bîyene"
  },
  {
    "x": "garm kardene",
    "y": " b. germ kerdene"
  },
  {
    "x": "garmi (m)",
    "y": " b. germe (m)"
  },
  {
    "x": "garmawi (m)",
    "y": " b. germawî (m)"
  },
  {
    "x": "garmbîyayîş (n)",
    "y": " b. germbîyayîş (n)"
  },
  {
    "x": "garmey (m)",
    "y": " b. germî-I (m), germîye (m)"
  },
  {
    "x": "garmê (m)",
    "y": " b. germî-I (m), germîye (m)"
  },
  {
    "x": "garmi (m)",
    "y": " germî-II (m)"
  },
  {
    "x": "garma duyeni (m)",
    "y": " b. germîya doyine (m)"
  },
  {
    "x": "garmey duyeni (m)",
    "y": " b. germîya doyine (m)"
  },
  {
    "x": "garmicayîş (n)",
    "y": " b. germijîyayîş (n)"
  },
  {
    "x": "garmicey (zh)",
    "y": " b. germij (n)"
  },
  {
    "x": "garmin, -i",
    "y": " b. germin, -e"
  },
  {
    "x": "garmin bîyayîne",
    "y": " b. germin bîyene"
  },
  {
    "x": "garmin kardene",
    "y": " b. germin kerdene"
  },
  {
    "x": "garminbîyayîş (n)",
    "y": " b. germinbîyayîş (n)"
  },
  {
    "x": "garminê (m)",
    "y": " b. germinî (m), germinîye (m)"
  },
  {
    "x": "garminkardiş (n)",
    "y": " b. germinkerdiş (n)"
  },
  {
    "x": "garmî (m)",
    "y": " germî-II (m)"
  },
  {
    "x": "garmkardiş (n)",
    "y": " b. germkerdiş (n)"
  },
  {
    "x": "garo (n)",
    "y": " bir kuş çeşidi"
  },
  {
    "x": "garrane (m)",
    "y": " b. garane (m)"
  },
  {
    "x": "garrani (m)",
    "y": " b. garane (m)"
  },
  {
    "x": "garson (n)",
    "y": " garson "
  },
  {
    "x": "gasar-I (n)",
    "y": " asma kütüğü, omça, omca"
  },
  {
    "x": "gasar-II (n)",
    "y": " b. gastar (n)"
  },
  {
    "x": "gasîlxane (n)",
    "y": " b. xesilxane (n)"
  },
  {
    "x": "gastar (n)",
    "y": " yük bağlamakta kullanılan urganın ucuna bağlanan çatallı ağaç parçası"
  },
  {
    "x": "gastare (n)",
    "y": " b. gastar (n)"
  },
  {
    "x": "gav (m)",
    "y": " b. game (m)"
  },
  {
    "x": "gavan (n)",
    "y": " geçilmesi güç olan geçit"
  },
  {
    "x": "gavar (n)",
    "y": " 1)akarsu kavşağı\r\n~2)arkların kesiştiği yer\r\n~3)karık (küçük ark)"
  },
  {
    "x": "gave-I (m)",
    "y": " kil"
  },
  {
    "x": "gave-II (m)",
    "y": " b. game (m)"
  },
  {
    "x": "gavi (m)",
    "y": " b. gave (m)"
  },
  {
    "x": "gavir (n)",
    "y": " b. gavar (n)"
  },
  {
    "x": "gawan, -e",
    "y": " sığırtmaç"
  },
  {
    "x": "gawanî (m)",
    "y": " sığırtmaçlık"
  },
  {
    "x": "gawanîn (m)",
    "y": " b. gawanî (m)"
  },
  {
    "x": "gawanîye (m)",
    "y": " sığırtmaçlık"
  },
  {
    "x": "gawantî (m)",
    "y": " b. gawanî (m)"
  },
  {
    "x": "gawir, -e",
    "y": " dinsiz, gâvur"
  },
  {
    "x": "gawirbav, -e",
    "y": " (babası gâvur olan) gâvur çocuğu"
  },
  {
    "x": "gawirey (m)",
    "y": " b. gawirî (m), b. gawirîye (m)"
  },
  {
    "x": "gawirê (m)",
    "y": " b. gawirî (m), gawirîye (m)"
  },
  {
    "x": "gawirên (m)",
    "y": " b. gawirênî (m)"
  },
  {
    "x": "gawirênî (m)",
    "y": " dinsizlik, gâvurluk"
  },
  {
    "x": "gawiri (m)",
    "y": " b. gawire (m)"
  },
  {
    "x": "gawirî (m)",
    "y": " dinsizlik, gâvurluk"
  },
  {
    "x": "gawirîye (m)",
    "y": " dinsizlik, gâvurluk"
  },
  {
    "x": "gawirkî",
    "y": " gâvurca"
  },
  {
    "x": "gawo (n)",
    "y": " b. gawan (n)"
  },
  {
    "x": "gawon, -i",
    "y": " b. gawan, -e"
  },
  {
    "x": "gawoney (m)",
    "y": " b. gawanîye (m), gawanî (m)"
  },
  {
    "x": "gawur, -e",
    "y": " b. gawir, -e"
  },
  {
    "x": "gawurbav, -e",
    "y": " b. gawirbav, -e"
  },
  {
    "x": "gawurey (m)",
    "y": " b. gawirî (m), gawirîye (m)"
  },
  {
    "x": "gawurê (m)",
    "y": " b. gawirî (m), gawirîye (m)"
  },
  {
    "x": "gawurên (m)",
    "y": " b. gawirênî (m)"
  },
  {
    "x": "gawurênî (m)",
    "y": " b. gawirênî (m)"
  },
  {
    "x": "gawuri (m)",
    "y": " b. gawire (m)"
  },
  {
    "x": "gawurî (m)",
    "y": " b. gawirî (m)"
  },
  {
    "x": "gawurîye (m)",
    "y": " b. gawirîye (m)"
  },
  {
    "x": "gawurkî",
    "y": " b. gawirkî"
  },
  {
    "x": "gawû (n)",
    "y": " b. gawan (n)"
  },
  {
    "x": "gawûn (n)",
    "y": " b. gawan, -e"
  },
  {
    "x": "gawûnê (m)",
    "y": " b. gawanîye (m), gawanî (m)"
  },
  {
    "x": "gawûnî (m)",
    "y": " b. gawanîye (m), gawanî (m)"
  },
  {
    "x": "gawûntî (m)",
    "y": " b. gawanîye (m), gawanî (m)"
  },
  {
    "x": "gaxan (n)",
    "y": " 1)yılbaşı\r\n~2)Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında oynanan geleneksel, teatral bir eğlence oyunu"
  },
  {
    "x": "gaxan arê dayêne",
    "y": " Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında evleri dolaşarak çerez, kuru yemiş vb. toplamak"
  },
  {
    "x": "gaxan arê kerdene",
    "y": " Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında evleri dolaşarak çerez, kuru yemiş vb. toplamak"
  },
  {
    "x": "gaxan top kerdene",
    "y": " Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında evleri dolaşarak çerez, kuru yemiş vb. toplamak"
  },
  {
    "x": "gaxan-gaxan",
    "y": " Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında oynanan geleneksel, teatral bir eğlence oyunu sırasında söylenen tekerleme"
  },
  {
    "x": "gaxand (n)",
    "y": " b. gaxan (n)"
  },
  {
    "x": "gaxandî (zh)",
    "y": " b. gaxanî (zh)"
  },
  {
    "x": "gaxanî (zh)",
    "y": " Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında çocukların evleri dolaşarak topladıkları yiyecek türünden hediyeler"
  },
  {
    "x": "gaxanî arê dayene",
    "y": " b. gaxan arê dayêne"
  },
  {
    "x": "gaxon (n)",
    "y": " b. gaxan (n)"
  },
  {
    "x": "gaxonî (zh)",
    "y": " b. gaxanî (zh)"
  },
  {
    "x": "gaxonî arê dayene",
    "y": " b. gaxan arê dayêne"
  },
  {
    "x": "gaxonî gêrayîne",
    "y": " b. gaxan arê dayêne"
  },
  {
    "x": "gaxûn (n)",
    "y": " b. gaxan (n)"
  },
  {
    "x": "gaxûn-gaxûn",
    "y": " b. gaxan-gaxan"
  },
  {
    "x": "gaxûnî (zh)",
    "y": " b. gaxanî (zh)"
  },
  {
    "x": "gay",
    "y": " b. ganî-II"
  },
  {
    "x": "gayameyîş (n)",
    "y": " b. gayîameyîş (n)"
  },
  {
    "x": "gaybîyayîş (n)",
    "y": " b. bigayîbîyayîş (n)"
  },
  {
    "x": "gayî (zh)",
    "y": " sığır"
  },
  {
    "x": "mal û gayî",
    "y": " davar ve sığır"
  },
  {
    "x": "gayîamayîş (n)",
    "y": " b. gayîameyîş (n)"
  },
  {
    "x": "gayîameyîş (n)",
    "y": " kösnüme (inek için)"
  },
  {
    "x": "gayîne (n)",
    "y": " büyükbaş [hayvanlar]"
  },
  {
    "x": "gaz (n)",
    "y": " gaz"
  },
  {
    "x": "gazê lojine (n)",
    "y": " baca gazı"
  },
  {
    "x": "gazê metanî (n)",
    "y": " metan gazı"
  },
  {
    "x": "gazê pixêrî (n)",
    "y": " baca gazı"
  },
  {
    "x": "gaze-I (m)",
    "y": " kerpeten"
  },
  {
    "x": "gaze-II (m)",
    "y": " b. gaz (n)"
  },
  {
    "x": "gazeta (m)",
    "y": " gazete"
  },
  {
    "x": "gazete (n)",
    "y": " b. gazeta (m)"
  },
  {
    "x": "gazi (m)",
    "y": " b. gaze-I (m)"
  },
  {
    "x": "gazî-I (m)",
    "y": " imdat çağrısı, yardım çağrısı"
  },
  {
    "x": "gazî-II (m)",
    "y": " b. gaze-I (m)"
  },
  {
    "x": "gazocaxî (m)",
    "y": " gaz ocağı"
  },
  {
    "x": "gazyaxî (n)",
    "y": " gazyağı"
  },
  {
    "x": "tenekeyê gazyaxî (m)",
    "y": " gazyağı tenekesi"
  },
  {
    "x": "ge-I",
    "y": " b. ke"
  },
  {
    "x": "ge-II",
    "y": " fiilin gelecek zamanında kullanılan bir partikel (b. ko-II, do-II)"
  },
  {
    "x": "ge-III",
    "y": " bazen"
  },
  {
    "x": "ge ... ge ...",
    "y": " bazen ... bazen …\r\n~Ge ez yena ge ti yenî. (Bazen ben gelirim, bazen sen gelirsin.)"
  },
  {
    "x": "ge-ga",
    "y": " b. ge-ge"
  },
  {
    "x": "ge-gane",
    "y": " ara sıra, arada sırada, bazen; vakit vakit, zaman zaman"
  },
  {
    "x": "ge-ge",
    "y": " ara sıra, arada sırada, bazen; vakit vakit, zaman zaman"
  },
  {
    "x": "ge-ge ... ge-ge ...",
    "y": " bazen ... bazen …\r\n~Ge-ge ez yena ge-ge ti yenî. (Bazen ben gelirim, bazen sen gelirsin.)"
  },
  {
    "x": "ge-gen",
    "y": " b. ge-gane"
  },
  {
    "x": "ge-gû",
    "y": " b. ge-gane"
  },
  {
    "x": "geber bîyayîne",
    "y": " b. geber bîyene"
  },
  {
    "x": "geber bîyene",
    "y": " gebermek"
  },
  {
    "x": "geber kardene",
    "y": " b. geber kerdene"
  },
  {
    "x": "geber kerdene",
    "y": " gebertmek"
  },
  {
    "x": "geberbîyayîş (n)",
    "y": " geberme"
  },
  {
    "x": "geberîyayene",
    "y": " gebermek"
  },
  {
    "x": "geberîyayîne",
    "y": " b. geberîyayene"
  },
  {
    "x": "geberkerdiş (n)",
    "y": " gebertme"
  },
  {
    "x": "gebernayene",
    "y": " gebertmek"
  },
  {
    "x": "gebernayîne",
    "y": " b. gebernayene"
  },
  {
    "x": "gebernayîş (n)",
    "y": " gebertme"
  },
  {
    "x": "gebre (n)",
    "y": " kefe (hayvanı tımar etmekte kullanılan kese) "
  },
  {
    "x": "gec",
    "y": " b. gêj, -e"
  },
  {
    "x": "gec bîyayene",
    "y": " b. gêj bîyene"
  },
  {
    "x": "geda (m)",
    "y": " çocuk, kız"
  },
  {
    "x": "gede (n)",
    "y": " çocuk"
  },
  {
    "x": "gede bîyene",
    "y": " çocuklaşmak"
  },
  {
    "x": "gede ver şîyayene",
    "y": " b. gede ver şîyene"
  },
  {
    "x": "gede ver şîyene",
    "y": " düşük yapmak"
  },
  {
    "x": "gede û gude",
    "y": " çoluk çocuk"
  },
  {
    "x": "gede-gude",
    "y": " çoluk çocuk"
  },
  {
    "x": "gedebîyayîş (n)",
    "y": " çocuklaşma"
  },
  {
    "x": "gedeg, -e",
    "y": " sütten kesilmiş olan manda yavrusu"
  },
  {
    "x": "gedegeh (n)",
    "y": " anaokulu, ana mektebi, çocuk yuvası, kreş"
  },
  {
    "x": "gedekî",
    "y": " çocukça"
  },
  {
    "x": "gedeyane",
    "y": " çocukça"
  },
  {
    "x": "gedî, -ye",
    "y": " b. kedî, -ye"
  },
  {
    "x": "gedî bîyene",
    "y": " b. kedî bîyene"
  },
  {
    "x": "gedî kerdene",
    "y": " b. kedî kerdene"
  },
  {
    "x": "gedîbîyayîş (n)",
    "y": " b. kedîbîyayîş (n)"
  },
  {
    "x": "gedîkerdiş (n)",
    "y": " b. kedîkerdiş (n)"
  },
  {
    "x": "geftar, -e",
    "y": " b. keftar, -e"
  },
  {
    "x": "ge-gane",
    "y": " ara sıra, arada sırada, bazen; vakit vakit, zaman zaman"
  },
  {
    "x": "ge-ge",
    "y": " ara sıra, arada sırada, bazen; vakit vakit, zaman zaman"
  },
  {
    "x": "geh ... geh ...",
    "y": " b. ge ... ge ..."
  },
  {
    "x": "gej",
    "y": " b. gêj, -e"
  },
  {
    "x": "geji (n)",
    "y": " b. gezî (n)"
  },
  {
    "x": "gejik (n)",
    "y": " b. gezî (n)"
  },
  {
    "x": "gejî (n)",
    "y": " b. gezî (n)"
  },
  {
    "x": "gejtî (m)",
    "y": " b. gêjî (m)"
  },
  {
    "x": "gelanke (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "gelanki (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "gelat, -e",
    "y": " b. gelax, -e"
  },
  {
    "x": "gelatey (m)",
    "y": " b. gelaxîye (m)"
  },
  {
    "x": "gelax, -e",
    "y": " bedavacı, beleşçi"
  },
  {
    "x": "gelaxîye (m)",
    "y": " bedavacılık"
  },
  {
    "x": "gele-I",
    "y": " b. gelek"
  },
  {
    "x": "gele-II",
    "y": " b. gelê"
  },
  {
    "x": "gelek",
    "y": " çok, epey, epeyce, hayli, birçok, ağız dolusu (birçok), bayağı"
  },
  {
    "x": "gelekî",
    "y": " b. gelek"
  },
  {
    "x": "gelê-I (çoğula hitap için)",
    "y": " ey\r\n~Gelê birayan! (Ey kardeşler!)"
  },
  {
    "x": "gelê-II",
    "y": " b. gelek"
  },
  {
    "x": "gelêk",
    "y": " b. gelek"
  },
  {
    "x": "gelêke",
    "y": " b. gelek"
  },
  {
    "x": "gelî-I (m)",
    "y": " vadi, koyak"
  },
  {
    "x": "gelî-II",
    "y": " b. gelê-I"
  },
  {
    "x": "gelîye (m)",
    "y": " vadi, koyak"
  },
  {
    "x": "gellat, -e",
    "y": " b. gelax, -e"
  },
  {
    "x": "gellatey (m)",
    "y": " b. gelaxîye (m)"
  },
  {
    "x": "gelle",
    "y": " b. gelê-II"
  },
  {
    "x": "gelo",
    "y": " acaba"
  },
  {
    "x": "gem-I (n)",
    "y": " gem"
  },
  {
    "x": "gem-II (m)",
    "y": " b. geme (m)"
  },
  {
    "x": "gemar-I (m)",
    "y": " b. gemare (m)"
  },
  {
    "x": "gemar-II (n)",
    "y": " b. gemare (m)"
  },
  {
    "x": "gemare (m)",
    "y": " kir"
  },
  {
    "x": "gemar û gemşike (m)",
    "y": " kir pas"
  },
  {
    "x": "gemarênî (m)",
    "y": " b. gemarinî (m)"
  },
  {
    "x": "gemari (m)",
    "y": " b. gemare (m)"
  },
  {
    "x": "gemarin, -i",
    "y": " b. gemarin, -e"
  },
  {
    "x": "gemarin, -e",
    "y": "kirli"
  },
  {
    "x": "gemarin bîyene",
    "y": " kirlenmek"
  },
  {
    "x": "gemarin kerdene",
    "y": " kirletmek"
  },
  {
    "x": "gemarinbîyayîş (n)",
    "y": " kirlenme"
  },
  {
    "x": "gemariney (m)",
    "y": " b. gemarinîye (m)"
  },
  {
    "x": "gemarinê (m)",
    "y": " b. gemarinî (m)"
  },
  {
    "x": "gemarinî (m)",
    "y": " kirlilik"
  },
  {
    "x": "gemarinîye (m)",
    "y": " kirlilik"
  },
  {
    "x": "gemarinîya asbestî (m)",
    "y": " asbest kirliliği"
  },
  {
    "x": "gemarinîya atmosferî (m)",
    "y": " atmosfer kirliliği"
  },
  {
    "x": "gemarinîya awa binerdî (m)",
    "y": " yer altı suyu kirliliği"
  },
  {
    "x": "gemarinîya awa germine (m)",
    "y": " sıcak su kirliliği"
  },
  {
    "x": "gemarinîya awe (n)",
    "y": " su kirliliği"
  },
  {
    "x": "gemarinîya germawe (m)",
    "y": " sıcak su kirliliği "
  },
  {
    "x": "gemarinîya haylemeyî (m)",
    "y": " gürültü kirliliği"
  },
  {
    "x": "gemarinîya hermeleyî (m)",
    "y": " gürültü kirliliği"
  },
  {
    "x": "gemarinîya hewayî (m)",
    "y": " hava kirliliği"
  },
  {
    "x": "gemarinîya kîmyayîye (m)",
    "y": " kimyasal kirlilik"
  },
  {
    "x": "gemarinîya petrolî (m)",
    "y": " petrol kirliliği"
  },
  {
    "x": "gemarinîya trafîkî (m)",
    "y": " trafik kirliliği"
  },
  {
    "x": "gemarinîya welweleyî (m)",
    "y": " gürültü kirliliği"
  },
  {
    "x": "gemarinker, -e",
    "y": " kirletici"
  },
  {
    "x": "gemarinkerê hewayî yê yewine (zh)",
    "y": " birincil hava kirleticiler"
  },
  {
    "x": "gemarinkerdiş (n)",
    "y": " kirletme"
  },
  {
    "x": "gemarrin",
    "y": " b. gemarin, -e"
  },
  {
    "x": "geme (m)",
    "y": " 1)kır, yaban\r\n~2)sık orman, ağaçlık"
  },
  {
    "x": "gemer (m)",
    "y": " b. gemare (m)"
  },
  {
    "x": "gemerin, -e",
    "y": " b. gemarin, -e"
  },
  {
    "x": "gemerîyayîş (n)",
    "y": " gemirîyayîş (n)"
  },
  {
    "x": "gemernayene",
    "y": " b. gemirnayene"
  },
  {
    "x": "gemernayîne",
    "y": " b. gemirnayene"
  },
  {
    "x": "gemernayîş (n)",
    "y": " b. gemirnayîş (n)"
  },
  {
    "x": "gemi-I (m)",
    "y": " b. geme (m)"
  },
  {
    "x": "gemi-II (m)",
    "y": " germî-II (m)"
  },
  {
    "x": "gema duyeni (m)",
    "y": " b. germîya doyine (m)"
  },
  {
    "x": "gema duyêni (m)",
    "y": " b. germîya doyine (m)"
  },
  {
    "x": "gemirîyayîş (n)",
    "y": " kirlenme"
  },
  {
    "x": "gemirîyayene",
    "y": " kirlenmek "
  },
  {
    "x": "gemirnayîş (n)",
    "y": " kirletme"
  },
  {
    "x": "genc, -e",
    "y": " genç"
  },
  {
    "x": "gencey (m)",
    "y": " b. gencîye (m)"
  },
  {
    "x": "gencê (m)",
    "y": " b. gencîye (m)"
  },
  {
    "x": "gencênî (m)",
    "y": " gençlik"
  },
  {
    "x": "genci (m)",
    "y": " b. genc, -e"
  },
  {
    "x": "gencî (m)",
    "y": " gençlik"
  },
  {
    "x": "gencîne (m)",
    "y": " hazine"
  },
  {
    "x": "gencîye (m)",
    "y": " gençlik"
  },
  {
    "x": "gend û gemar (n)",
    "y": " b. gend û gemare (m)"
  },
  {
    "x": "gend û gemare (m)",
    "y": " kir pas "
  },
  {
    "x": "gend û gemari",
    "y": " b. gend û gemare (m)"
  },
  {
    "x": "gendawe (m)",
    "y": " atık su"
  },
  {
    "x": "gendawa bankîye (m)",
    "y": " evsel atık su"
  },
  {
    "x": "gendawa endustrîyele (m)",
    "y": " endüstriyel atık su"
  },
  {
    "x": "gendim (n)",
    "y": " b. genim (n)"
  },
  {
    "x": "gene (n)",
    "y": " kene"
  },
  {
    "x": "general, -e",
    "y": " general"
  },
  {
    "x": "generalî (m)",
    "y": " generallik"
  },
  {
    "x": "generalîye (m)",
    "y": " generallik"
  },
  {
    "x": "genetîk, -e (nm, s)",
    "y": " kalıtımbilim, genetik"
  },
  {
    "x": "gengo (n)",
    "y": " b. kakile (m)"
  },
  {
    "x": "gengola (m)",
    "y": " b. kengula (m)"
  },
  {
    "x": "gengula (m)",
    "y": " b. kengula (m)"
  },
  {
    "x": "genim (n)",
    "y": " buğday"
  },
  {
    "x": "gening (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "genûng (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "geometrî (n)",
    "y": " geometri, hendese"
  },
  {
    "x": "gep (n)",
    "y": " lokma"
  },
  {
    "x": "gepçe (n)",
    "y": " kepçe (b. kondêz, çemçe)"
  },
  {
    "x": "gepe (m)",
    "y": " 1)avurt\r\n~2)yudum"
  },
  {
    "x": "gera",
    "y": " b. gerek, ganî-II"
  },
  {
    "x": "geraka",
    "y": " b. gerek, ganî-II"
  },
  {
    "x": "gerange (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "gerangena",
    "y": " b. gilangna"
  },
  {
    "x": "gerayene",
    "y": " b. gêrayene"
  },
  {
    "x": "gerayîş (n)",
    "y": " b. gêrayîş (n)"
  },
  {
    "x": "geraz (m)",
    "y": " b. gilyaze (m)"
  },
  {
    "x": "gerdanlix (n)",
    "y": " b. gerdenlix (n)"
  },
  {
    "x": "gerden (n)",
    "y": " gerdan"
  },
  {
    "x": "gerdenî (n)",
    "y": " gerdanlık"
  },
  {
    "x": "gerdenîyo zerrên (n)",
    "y": " altın gerdanlık"
  },
  {
    "x": "gerdenlix (n)",
    "y": " gerdanlık"
  },
  {
    "x": "gerdonlix (n)",
    "y": " b. gerdenlix (n)"
  },
  {
    "x": "gerdûnlix (n)",
    "y": " b. gerdenlix (n)"
  },
  {
    "x": "gere-I (n)",
    "y": " b. gerre (n)"
  },
  {
    "x": "gere-II",
    "y": " b. gerek, ganî-II"
  },
  {
    "x": "gereg",
    "y": " b. gerek, ganî-II"
  },
  {
    "x": "gerega",
    "y": " b. gerek, ganî-II"
  },
  {
    "x": "gerek",
    "y": " gerek, icap (b. ganî-II)"
  },
  {
    "x": "gereka",
    "y": " b. gerek, ganî-II"
  },
  {
    "x": "gerekana",
    "y": " b. gerek, ganî-II"
  },
  {
    "x": "gereke",
    "y": " b. gerek, ganî-II"
  },
  {
    "x": "gerenc (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "gerenda (m)",
    "y": " b. gêrenda (m) "
  },
  {
    "x": "gerende (n) ",
    "y": " b. gêrende (n)"
  },
  {
    "x": "gereng (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "gerenge (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "gerengêna (m)",
    "y": " b. gilangna (m)"
  },
  {
    "x": "gerê (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "gergedan (n)",
    "y": " gergedan "
  },
  {
    "x": "gergedon (n)",
    "y": " b. gergedan (n)"
  },
  {
    "x": "gergedûn (n)",
    "y": " b. gergedan (n)"
  },
  {
    "x": "gerguş (m)",
    "y": " b. derguşe (m)"
  },
  {
    "x": "gergûş (m)",
    "y": " b. derguşe (m)"
  },
  {
    "x": "geri (n)",
    "y": " b. gerre (n)"
  },
  {
    "x": "gering (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "geringna",
    "y": " b. gilangna"
  },
  {
    "x": "gerîneke (m)",
    "y": " topaç"
  },
  {
    "x": "germ-I (n)",
    "y": " ısı"
  },
  {
    "x": "germ bîyene",
    "y": " ısınmak"
  },
  {
    "x": "germ kerdene",
    "y": " ısıtmak"
  },
  {
    "x": "germ,-e-II",
    "y": " sıcak"
  },
  {
    "x": "germ-III (m)",
    "y": " germî-II (m)"
  },
  {
    "x": "germê dûyî (m)",
    "y": " b. germîya doyî (m)"
  },
  {
    "x": "germaw (m)",
    "y": " b. germawe-I (m)"
  },
  {
    "x": "germawe-I (m)",
    "y": " ılıca, kaplıca, kudret hamamı"
  },
  {
    "x": "germawe-II (m)",
    "y": " b. germawî (m)"
  },
  {
    "x": "germawi (m)",
    "y": "  b. germawî (m)"
  },
  {
    "x": "germawî (m)",
    "y": " bulgur ve yarma gibi yemeklik tahıl ürünlerinin genel adı"
  },
  {
    "x": "germaxe (m)",
    "y": " kir pas"
  },
  {
    "x": "germaxi (m)",
    "y": " b. germaxe (m)"
  },
  {
    "x": "germaxijiney (m)",
    "y": " b. germaxijinî (m)"
  },
  {
    "x": "germaxijinî (m)",
    "y": " kirlilik"
  },
  {
    "x": "germaxinênî (m)",
    "y": " kirlilik"
  },
  {
    "x": "germaxinî (m)",
    "y": " kirlilik"
  },
  {
    "x": "germaxiney (m)",
    "y": " b. germaxinî (m)"
  },
  {
    "x": "germaxinîye (m)",
    "y": " kirlilik"
  },
  {
    "x": "germbîyayîş (n)",
    "y": " ısınma"
  },
  {
    "x": "germe (m)",
    "y": " b. germî-II (m)"
  },
  {
    "x": "germesûn (n)",
    "y": " kızgın çuvaldız vb. bir şeyin batırılarak irin bağlamış çıbanın patlatılması işlemi"
  },
  {
    "x": "germesûn kerdene",
    "y": " kızgın çuvaldız vb. bir şeyi batırarak irin bağlamış çıbanı patlatmak"
  },
  {
    "x": "germey (m)",
    "y": " b. germî-I (m), germîye (m)"
  },
  {
    "x": "germê (m)",
    "y": " b. germî-I (m), germîye (m)"
  },
  {
    "x": "germênî (m)",
    "y": " ısı"
  },
  {
    "x": "germi (m)",
    "y": " germî-II (m)"
  },
  {
    "x": "germa duyeni (m)",
    "y": " b. germîya doyine (m)"
  },
  {
    "x": "germa duyêni (m)",
    "y": " b. germîya doyine (m)"
  },
  {
    "x": "germey doyini (m)",
    "y": " b. germîya doyine (m)"
  },
  {
    "x": "germey punî (m)",
    "y": " b. germîya punî (m)"
  },
  {
    "x": "germic (n)",
    "y": " b. germij (n)"
  },
  {
    "x": "germice (n)",
    "y": " b. germij (n)"
  },
  {
    "x": "germicîyayîş (n)",
    "y": " b. germijîyayîş (n)"
  },
  {
    "x": "germij (n)",
    "y": " 1)isilik, ısırgın\r\n~2)b. germike (m), germawe (m)"
  },
  {
    "x": "germijey (zh)",
    "y": " b. germij (n)"
  },
  {
    "x": "germijîyayîş (n)",
    "y": " isilik olma"
  },
  {
    "x": "germik (m)",
    "y": " germike (m)"
  },
  {
    "x": "germike (m)",
    "y": " ılıca, kaplıca, kudret hamamı"
  },
  {
    "x": "germin, -e",
    "y": " sıcak, ısınmış olan"
  },
  {
    "x": "germin bîyene",
    "y": " ısınmak"
  },
  {
    "x": "germin kerdene",
    "y": " ısıtmak"
  },
  {
    "x": "germinbîyayîş (n)",
    "y": " ısınma"
  },
  {
    "x": "germiney (m)",
    "y": " b. germinî (m), germinîye (m)"
  },
  {
    "x": "germinê (m)",
    "y": " b. germinî (m), germinîye (m)"
  },
  {
    "x": "germinênî (m)",
    "y": " sıcaklık"
  },
  {
    "x": "germini (m)",
    "y": " b. germine (m)"
  },
  {
    "x": "germinî (m)",
    "y": " sıcaklık"
  },
  {
    "x": "germinîye (m)",
    "y": " sıcaklık"
  },
  {
    "x": "germinkerdiş (n)",
    "y": " ısıtma"
  },
  {
    "x": "germix (m)",
    "y": " b. germij (n)"
  },
  {
    "x": "germix vetiş (n)",
    "y": " b. germijîyayîş (n)"
  },
  {
    "x": "germî-I (m)",
    "y": " 1)ısı\r\n~2)sıcaklık"
  },
  {
    "x": "germîya adirgirewtişî (m)",
    "y": " tutuşma sıcaklığı"
  },
  {
    "x": "germîya eşteyî (m)",
    "y": " atık ısısı"
  },
  {
    "x": "germî-II (m)",
    "y": " 1)çorba (tahıldan yapılan çorba), tahıldan yapılan sulu sıcak yemek\r\n~2)pilav"
  },
  {
    "x": "germa doyî (m)",
    "y": " b. germîya doyî (m)"
  },
  {
    "x": "germîya doyine (m)",
    "y": " ayran çorbası"
  },
  {
    "x": "germîya doyî (m)",
    "y": " ayran çorbası"
  },
  {
    "x": "germîya duyeni (m)",
    "y": " b. germîya doyine (m)"
  },
  {
    "x": "germîya duyêne (m)",
    "y": " b. germîya doyine (m)"
  },
  {
    "x": "germîya îmama (m)",
    "y": " b. germîya îmaman (m)"
  },
  {
    "x": "germîya îmaman (m)",
    "y": " aşure"
  },
  {
    "x": "germîya punî (m)",
    "y": " ayran çorbası"
  },
  {
    "x": "germîya tutmacî (m)",
    "y": " tutmaç"
  },
  {
    "x": "germîya tutmajî (m)",
    "y": " b. germîya tutmacî"
  },
  {
    "x": "germîye (m)",
    "y": " 1)ısı\r\n~2)sıcaklık"
  },
  {
    "x": "germker (n)",
    "y": " ısıtıcı"
  },
  {
    "x": "germker aktîf",
    "y": " ısıtıcı devrede"
  },
  {
    "x": "germker betal",
    "y": " ısıtıcı devre dışı"
  },
  {
    "x": "germkerdiş (n)",
    "y": " ısıtma"
  },
  {
    "x": "germkerdişo merkezî (n)",
    "y": " merkezi ısıtma"
  },
  {
    "x": "germpay kerdene",
    "y": " oyalanmak"
  },
  {
    "x": "germpaykerdiş (n)",
    "y": " oyalanma"
  },
  {
    "x": "germpeym (n)",
    "y": " sıcaklıkölçer, termometre"
  },
  {
    "x": "gernayene",
    "y": " b. gêrnayene"
  },
  {
    "x": "gernayîş (n)",
    "y": " b. gêrnayîş (n)"
  },
  {
    "x": "gerong (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "gerongêk",
    "y": " b. gilangêk"
  },
  {
    "x": "gerotiş (n)",
    "y": " b. girewtiş (n)"
  },
  {
    "x": "gerr (n)",
    "y": " uyuz"
  },
  {
    "x": "gerr vetene",
    "y": " uyuz olmak"
  },
  {
    "x": "gerre (n)",
    "y": " şikayet"
  },
  {
    "x": "gerre kerdene",
    "y": " şikayet etmek "
  },
  {
    "x": "gerrekerdox, -e",
    "y": " şikayetçi "
  },
  {
    "x": "gerri (n)",
    "y": " b. gerre (n)"
  },
  {
    "x": "gerrin, -e",
    "y": " uyuz (uyuz hastalığına tutulmuş olan)"
  },
  {
    "x": "gerrin bîyayene",
    "y": " b. gerrin bîyene"
  },
  {
    "x": "gerrin bîyene",
    "y": " uyuz olmak"
  },
  {
    "x": "gerrmesûn (n)",
    "y": " b. germesûn (n)"
  },
  {
    "x": "gerrmesûn kerdene",
    "y": " b. germesûn kerdene"
  },
  {
    "x": "ges (m)",
    "y": " b. gêste (m)"
  },
  {
    "x": "ges ra merdene",
    "y": " b. gêste ra merdene"
  },
  {
    "x": "ges te merdene",
    "y": " b. gêste de merdene"
  },
  {
    "x": "gest-I (m)",
    "y": " b. gêste (m)"
  },
  {
    "x": "gest de merdene",
    "y": " b. gêste de merdene"
  },
  {
    "x": "gest ra merdene",
    "y": " b. gêste ra merdene"
  },
  {
    "x": "gest ver merdene",
    "y": " b. gêste ver merdene"
  },
  {
    "x": "gest-II (n)",
    "y": " b. geşt (n)"
  },
  {
    "x": "gest arê dayene",
    "y": " b. geşt arê dayene"
  },
  {
    "x": "gestar (n)",
    "y": " b. gastar (n)"
  },
  {
    "x": "geste (m)",
    "y": " b. gêste (m)"
  },
  {
    "x": "geste de merdene",
    "y": " b. gêste de merdene"
  },
  {
    "x": "geste ra merdene",
    "y": " b. gêste ra merdene"
  },
  {
    "x": "geste ver merdene",
    "y": " b. gêste ver merdene"
  },
  {
    "x": "geşt (n)",
    "y": " bahşiş"
  },
  {
    "x": "geşt arê dayene",
    "y": " bahşiş toplamak"
  },
  {
    "x": "gever (n)",
    "y": " b. gavar (n)"
  },
  {
    "x": "gever bîyayîne",
    "y": " b. geber bîyene"
  },
  {
    "x": "gever kerdene",
    "y": " b. geber kerdene"
  },
  {
    "x": "geverbîyayîş (n)",
    "y": " b. geberbîyayîş (n)"
  },
  {
    "x": "geverkerdiş (n)",
    "y": " b. geberkerdiş (n)"
  },
  {
    "x": "geveza (m)",
    "y": " geveze"
  },
  {
    "x": "geveze (n)",
    "y": " geveze"
  },
  {
    "x": "gevezeyey (m)",
    "y": " b. gevezeyî (m), gevezeyîye (m)"
  },
  {
    "x": "gevezeyey kerdene",
    "y": " b. gevezeyî kerdene, gevezeyîye kerdene"
  },
  {
    "x": "gevezeyeykerdiş (n)",
    "y": " b. gevezeyîkerdiş (n), gevezeyîyekerdiş (n)"
  },
  {
    "x": "gevezeyî (m)",
    "y": " gevezelik"
  },
  {
    "x": "gevezeyî kerdene",
    "y": " gevezelik etmek"
  },
  {
    "x": "gevezeyîkerdiş (n)",
    "y": " gevezelik etme"
  },
  {
    "x": "gevezeyîye (m)",
    "y": " gevezelik"
  },
  {
    "x": "gevezeyîye kerdene",
    "y": " gevezelik etmek"
  },
  {
    "x": "gevezeyîyekerdiş (n)",
    "y": " gevezelik etme"
  },
  {
    "x": "gevezîye (m)",
    "y": " geveze"
  },
  {
    "x": "gevezîyayîne",
    "y": " b. gevizîyayene"
  },
  {
    "x": "gevezîyayîş (n)",
    "y": " b. gevizîyayîş (n)"
  },
  {
    "x": "gevizîyayene",
    "y": " ağınmak"
  },
  {
    "x": "gevizîyayîş (n)",
    "y": " ağınma"
  },
  {
    "x": "gevîj dayene",
    "y": " b. gevizîyayene"
  },
  {
    "x": "gevîjdaîs (n)",
    "y": " b. gevizîyayene"
  },
  {
    "x": "gevîjdayîs (n)",
    "y": " b. gevizîyayene"
  },
  {
    "x": "gew (m)",
    "y": " b. gewe (m)"
  },
  {
    "x": "gew ci nayene",
    "y": " b. gewe ci nayene"
  },
  {
    "x": "gew girotene",
    "y": " b. gewe girewtene"
  },
  {
    "x": "gewe (m)",
    "y": " ağıl, arkaç"
  },
  {
    "x": "gewe ci nayene",
    "y": " abluka etmek, ablukaya almak, kuşatmak, muhasara altına almak, muhasara etmek; çevrelemek, ihata etmek"
  },
  {
    "x": "gewe girewtene",
    "y": " abluka etmek, ablukaya almak¸ kuşatmak, muhasara altına almak, muhasara etmek;çevrelemek, ihata etmek"
  },
  {
    "x": "gewecinayîş (n)",
    "y": " abluka etme, çevreleme"
  },
  {
    "x": "gewegirewtiş (n)",
    "y": " abluka etme, çevreleme"
  },
  {
    "x": "gewel (m)",
    "y": " b. gewele (m)"
  },
  {
    "x": "gewele (m)",
    "y": " salya"
  },
  {
    "x": "gewele fek ra şîyene",
    "y": " ağzından salya akmak"
  },
  {
    "x": "gewelin, -e",
    "y": " salyalı"
  },
  {
    "x": "gewenda (m)",
    "y": " serseri"
  },
  {
    "x": "gewende, -ye",
    "y": " serseri"
  },
  {
    "x": "gewendeyey (m)",
    "y": " b. gewendeyîye (m)"
  },
  {
    "x": "gewendeyê (m)",
    "y": " b. gewendeyî (m)"
  },
  {
    "x": "gewendeyî (m)",
    "y": " serserilik"
  },
  {
    "x": "gewendeyîye (m)",
    "y": " serserilik"
  },
  {
    "x": "gewer bîyayîne",
    "y": " b. geber bîyene"
  },
  {
    "x": "gewer kerdene",
    "y": " b. geber kerdene"
  },
  {
    "x": "gewerbîyayîş (n)",
    "y": " b. geberbîyayîş (n)"
  },
  {
    "x": "geweza (m)",
    "y": " b. geveza (m)"
  },
  {
    "x": "geweze (n)",
    "y": " b. geveze (n)"
  },
  {
    "x": "gewezeyê (m)",
    "y": " b. gevezeyî (m), gevezeyîye (m)"
  },
  {
    "x": "gewezeyê kerdene",
    "y": " b. gevezeyî kerdene, gevezeyîye kerdene"
  },
  {
    "x": "gewezeyêkerdiş (n)",
    "y": " b. gevezeyîkerdiş (n), gevezeyîyekerdiş (n) "
  },
  {
    "x": "gewezeyî (m)",
    "y": " b. gevezeyî (m), gevezeyîye (m)"
  },
  {
    "x": "gewezeyî kerdene",
    "y": " b. gevezeyî kerdene, gevezeyîye kerdene"
  },
  {
    "x": "gewezeyîkerdiş (n)",
    "y": " b. gevezeyîkerdiş (n), gevezeyîyekerdiş (n)"
  },
  {
    "x": "gewezîye (m)",
    "y": " b. gevezîye (m)"
  },
  {
    "x": "gewi (m)",
    "y": " b. gewe (m)"
  },
  {
    "x": "gewr, -e",
    "y": " gri, kül rengi"
  },
  {
    "x": "gewre (n)",
    "y": " çorap"
  },
  {
    "x": "gewreyo naylonên (n)",
    "y": " naylon çorap"
  },
  {
    "x": "gewreyo peşmên (n)",
    "y": " yün çorap"
  },
  {
    "x": "gewreyo purtên (n)",
    "y": " yün çorap"
  },
  {
    "x": "gewreyo rêsên (n)",
    "y": " yün çorap"
  },
  {
    "x": "Gewre (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "Gewri (m)",
    "y": " b. Gewre (m)"
  },
  {
    "x": "gewş, -e",
    "y": " gevşek"
  },
  {
    "x": "gewşek, -e",
    "y": " gevşek"
  },
  {
    "x": "gewşeki (m)",
    "y": " b. gewşeke (m)"
  },
  {
    "x": "gewz, -e",
    "y": " dinç, zinde\r\n~Ez gewz a. (Ben iyiyim.)"
  },
  {
    "x": "gewz bîyene",
    "y": " dinç olmak, zinde olmak\r\n~Roşno kal bibi la gewz bi. (Roşno yaşlanmıştı ama dinçti.)"
  },
  {
    "x": "gey",
    "y": " b. ganî-II"
  },
  {
    "x": "geyîm (n)",
    "y": " b. gêyîm (n)"
  },
  {
    "x": "geyrayene",
    "y": " b. gêrayene"
  },
  {
    "x": "geyrayîş (n)",
    "y": " b. gêrayîş (n)"
  },
  {
    "x": "geyrnayene",
    "y": " b. gêrnayene"
  },
  {
    "x": "geyrnayîş (n)",
    "y": " b. gêrnayîş (n)"
  },
  {
    "x": "geyrayox, -e",
    "y": " b. gêrayox, -e "
  },
  {
    "x": "gez-I (n)",
    "y": " bir kumaş çeşidi"
  },
  {
    "x": "gez-II (n)",
    "y": " 1)b. gezî (n)\r\n~2)çalı süpürgesi"
  },
  {
    "x": "gez kerdene",
    "y": " b. gezî kerdene"
  },
  {
    "x": "gezbir (n)",
    "y": " çalı süpürgesi"
  },
  {
    "x": "gezi (n)",
    "y": " b. gezî (n) "
  },
  {
    "x": "gezi kerdene",
    "y": " b. gezî kerdene"
  },
  {
    "x": "gezik (n)",
    "y": " b. gezî (n)"
  },
  {
    "x": "gezikerdiş (n)",
    "y": " b. gezîkerdiş (n)"
  },
  {
    "x": "gezî (n)",
    "y": " süpürge"
  },
  {
    "x": "gezîyo elektrîkin (n)",
    "y": " elektrik süpürgesi"
  },
  {
    "x": "gezî kerdene",
    "y": " süpürmek"
  },
  {
    "x": "gezîkerdiş (n)",
    "y": " süpürme"
  },
  {
    "x": "gezkerdiş (n)",
    "y": " b. gezîkerdiş (n)"
  },
  {
    "x": "gê-I",
    "y": " b. ganî-II"
  },
  {
    "x": "gê-II",
    "y": " b. ge"
  },
  {
    "x": "gê ... gê ...",
    "y": " b. ge ... ge ..."
  },
  {
    "x": "gê-gane",
    "y": " b. ge-gane"
  },
  {
    "x": "gê-gê",
    "y": " b. ge-ge"
  },
  {
    "x": "ge-gû",
    "y": " b. ge-gane"
  },
  {
    "x": "gêc, -i",
    "y": " b. gêj, -e"
  },
  {
    "x": "gêc bîyayene",
    "y": " b. gêj bîyene"
  },
  {
    "x": "gêc bîyayîne",
    "y": " b. gêj bîyene"
  },
  {
    "x": "gêc kardene",
    "y": " b. gêj kerdene "
  },
  {
    "x": "gêcey (m)",
    "y": " b. gêjî (m)"
  },
  {
    "x": "gêcê (m)",
    "y": " b. gêjî (m)"
  },
  {
    "x": "gêcî (m)",
    "y": " b. gêjî (m)"
  },
  {
    "x": "gêj, -e",
    "y": " 1)sersem, alık, ahmak, aptal\r\n~2)deli, çılgın"
  },
  {
    "x": "gêj bîyene",
    "y": " 1)sersemleşmek, sersemlemek\r\n~2)ahmaklaşmak, aptallaşmak\r\n~3)“akli dengesi” bozulmak (hayvanlar için)\r\n~4)delirmek, çıldırmak, çılgına dönmek, çılgınlaşmak"
  },
  {
    "x": "gêj kerdene",
    "y": " 1)sersemletmek, sersem etmek\r\n~2)ahmaklaştırmak, aptallaştırmak\r\n~3)delirtmek, çıldırtmak"
  },
  {
    "x": "gêjane",
    "y": " 1)ahmakça, aptalca\r\n~2)delice, çılgınca"
  },
  {
    "x": "gêjbîyayîş (n)",
    "y": " 1)ahmaklaşma, aptallaşma\r\n~2)delirme, deliriş, çıldırma, çıldırış, çılgınlaşma"
  },
  {
    "x": "gêjkerdiş (n)",
    "y": " ahmaklaştırma, aptallaştırma"
  },
  {
    "x": "gêjey (m)",
    "y": " b. gêjî (m)"
  },
  {
    "x": "gêjê (m)",
    "y": " b. gêjî (m)"
  },
  {
    "x": "gêji (m)",
    "y": " b. gêje (m)"
  },
  {
    "x": "gêjî (m)",
    "y": " 1)sersemlik, alıklık, ahmaklık, aptallık, avanaklık, salaklık\r\n~2)“akli denge” bozukluğu (özellikle hayvanlar için)\r\n~3)delilik, çılgınlık"
  },
  {
    "x": "gêjîye (m)",
    "y": " 1)sersemlik, alıklık\r\n~2)“akli denge” bozukluğu (özellikle hayvanlar için)\r\n~3)delilik, çılgınlık"
  },
  {
    "x": "gêjîye kerdene",
    "y": " çılgınlık etmek"
  },
  {
    "x": "gêjkerdiş (n)",
    "y": " çıldırtma, delirtme"
  },
  {
    "x": "gêjo (n)",
    "y": " sersem, alık, ahmak, aptal, avanak, salak"
  },
  {
    "x": "gêjtî (m)",
    "y": " b. gêjî (m)"
  },
  {
    "x": "Gêl (n)",
    "y": " Eğil"
  },
  {
    "x": "gêle",
    "y": " b. gelek"
  },
  {
    "x": "gêle kerdene",
    "y": " döven sürmek, döven dövmek, harman dövmek"
  },
  {
    "x": "gênc, -e",
    "y": " b. genc, -e"
  },
  {
    "x": "gêncênî (m)",
    "y": " b. gencênî (m)"
  },
  {
    "x": "gêncîye (m)",
    "y": " b. gencîye (m)"
  },
  {
    "x": "gênd û gimarî",
    "y": " b. gend û gemare (m)"
  },
  {
    "x": "gênim (n)",
    "y": " b. genim (n)"
  },
  {
    "x": "gêpe (m)",
    "y": " b. gepe (m)"
  },
  {
    "x": "gêr bîyayene",
    "y": " b. gêr bîyene"
  },
  {
    "x": "gêr bîyene",
    "y": " yuvarlanmak"
  },
  {
    "x": "gêr dayene",
    "y": " yuvarlamak"
  },
  {
    "x": "gêr kerdene",
    "y": " yuvarlamak"
  },
  {
    "x": "gêrawe (m)",
    "y": " anafor, girdap, burgaç, çevri; eğrim"
  },
  {
    "x": "gêrayene",
    "y": " 1)gezmek, dolaşmak\r\n~ma bigeyrin/ma bigêrî/ma bigêrê: biz gezelim, biz dolaşalım\r\n~2)aramak (TE)\r\n~\t\r\n~Bigêre! (Ara!) (TE)\r\n~\t\r\n~3)rengi atmak, rengi kaçmak, rengi uçmak"
  },
  {
    "x": "ma bigeyrin/ma bigêrî/ma bigêrê",
    "y": " biz gezelim, biz dolaşalım"
  },
  {
    "x": "gêrayîş (n)",
    "y": " 1)gezme, dolaşma, seyahat\r\n~2)arama"
  },
  {
    "x": "gêrayox, -e",
    "y": " gezgin, seyyah"
  },
  {
    "x": "gêraz (m)",
    "y": " b. gilyaze (m)"
  },
  {
    "x": "gêrazêr (m)",
    "y": " b. gilyazêre (m)"
  },
  {
    "x": "gêre",
    "y": " b. gerek, ganî-II"
  },
  {
    "x": "gêrek",
    "y": " b. gerek, ganî-II"
  },
  {
    "x": "gêreke",
    "y": " b. gerek, ganî-II"
  },
  {
    "x": "gêrenda (m)",
    "y": " berduş"
  },
  {
    "x": "gêrende, -ye",
    "y": " 1)avare, aylak\r\n~2)berduş"
  },
  {
    "x": "gêrende bîyene",
    "y": " avareleşmek"
  },
  {
    "x": "gêrende kerdene",
    "y": " avareleştirmek"
  },
  {
    "x": "gêrendebîyayîş (n)",
    "y": " avareleşme"
  },
  {
    "x": "gêrendekerdiş (n)",
    "y": " avareleştirme"
  },
  {
    "x": "gêrendeyî (m)",
    "y": " avarelik, aylaklık"
  },
  {
    "x": "gêrendeyî kerdene",
    "y": " aylaklık etmek"
  },
  {
    "x": "gêrendeyîkerdiş (n)",
    "y": " aylaklık etme"
  },
  {
    "x": "gêrendeyîye (m)",
    "y": " avarelik, aylaklık"
  },
  {
    "x": "gêrgêl (n)",
    "y": " b. gilgil (n)"
  },
  {
    "x": "gêrîyayene",
    "y": " b. gîrîyayene"
  },
  {
    "x": "gêrm-I (n)",
    "y": " b. germ (n)"
  },
  {
    "x": "gêrm-II (m)",
    "y": " b. germî-II (m)"
  },
  {
    "x": "gêrma duye (m)",
    "y": " b. germîya doyî (m)"
  },
  {
    "x": "gêrma duyin (m)",
    "y": " b. germîya doyine (m)"
  },
  {
    "x": "gêrm, -e-III",
    "y": " b. germ,-e"
  },
  {
    "x": "gêrmawi (m)",
    "y": " b. germawî (m)"
  },
  {
    "x": "gêrme (m)",
    "y": " b. germî-II (m)"
  },
  {
    "x": "gêrmin, -e",
    "y": " b. germin, -e"
  },
  {
    "x": "gêrmin bîyene",
    "y": " b. germin bîyene"
  },
  {
    "x": "gêrmin kerdene",
    "y": " b. germin kerdene"
  },
  {
    "x": "gêrmî (m)",
    "y": " b. germî-II (m)"
  },
  {
    "x": "gêrmîya doy (m)",
    "y": " b. germîya doyî (m)"
  },
  {
    "x": "gêrnayene",
    "y": " 1)çevirmek, döndürmek\r\n~2)gezdirmek, dolaştırmak"
  },
  {
    "x": "gêrnayîş (n)",
    "y": " 1)çevirme, döndürme\r\n~2)gezdirme, dolaştırma"
  },
  {
    "x": "gêrnayîşê kamera (sn)",
    "y": " kamera gezdirme"
  },
  {
    "x": "gêrok, -e",
    "y": " gezici, seyyar, portatif"
  },
  {
    "x": "gêrrik (m)",
    "y": " b. gêrrike (m)"
  },
  {
    "x": "gêrrike (m)",
    "y": " girdap"
  },
  {
    "x": "gêrwe (n)",
    "y": " b. gewre (n)"
  },
  {
    "x": "gês (m)",
    "y": " b. gêste (m)"
  },
  {
    "x": "gês ra merdene",
    "y": " b. gêste ra merdene"
  },
  {
    "x": "gês te merdene",
    "y": " b. gêste de merdene"
  },
  {
    "x": "gês ti merdene",
    "y": " b. gêste de merdene"
  },
  {
    "x": "gêsk (n)",
    "y": " b. gîsk (n)"
  },
  {
    "x": "gêst-I (m)",
    "y": " b. gêste (m)"
  },
  {
    "x": "gêst de merdene",
    "y": " b. gêste de merdene"
  },
  {
    "x": "gêst ra merdene",
    "y": " b. gêste ra merdene"
  },
  {
    "x": "gêst ver merdene",
    "y": " b. gêste ver merdene"
  },
  {
    "x": "gêst-II (n)",
    "y": " b. geşt (n)"
  },
  {
    "x": "gêst are dayene",
    "y": " b. geşt arê dayene"
  },
  {
    "x": "gêste (m)",
    "y": " açlık"
  },
  {
    "x": "gêste de merdene",
    "y": " açlıktan ölmek, acından ölmek"
  },
  {
    "x": "gêste ra merdene",
    "y": " açlıktan ölmek, acından ölmek"
  },
  {
    "x": "gêste ver merdene",
    "y": " açlıktan ölmek, acından ölmek"
  },
  {
    "x": "gêver bîyayîne",
    "y": " b. geber bîyene"
  },
  {
    "x": "gêver kerdene",
    "y": " b. geber kerdene"
  },
  {
    "x": "gêverbîyayîs (n)",
    "y": " b. geberbîyayîş (n)"
  },
  {
    "x": "gêwe (m)",
    "y": " b. gewe (m)"
  },
  {
    "x": "gêwr, -e",
    "y": " b. gewr, -e"
  },
  {
    "x": "gêwre (n)",
    "y": " b. gewre (n)"
  },
  {
    "x": "Gêxî",
    "y": " Kiğı"
  },
  {
    "x": "gêy dayene",
    "y": " b. gayî dayene"
  },
  {
    "x": "gêyîm (n)",
    "y": " kez, kere, defa, sefer"
  },
  {
    "x": "gêyîmê",
    "y": " bir kez"
  },
  {
    "x": "gêyîmêk",
    "y": " bir kez"
  },
  {
    "x": "gêyrayene",
    "y": " b. gêrayene"
  },
  {
    "x": "gêyrayîş (n)",
    "y": " b. gêrayîş (n)"
  },
  {
    "x": "gêyrnayene",
    "y": " b. gêrnayene"
  },
  {
    "x": "gêyrnayîş (n)",
    "y": " b. gêrnayîş (n)"
  },
  {
    "x": "gêz, -e",
    "y": " b. gêj, -e"
  },
  {
    "x": "gêzer (n)",
    "y": " b. gizêr (n)"
  },
  {
    "x": "gi-I",
    "y": " b. ke-I"
  },
  {
    "x": "gi-II (n)",
    "y": " b. gî (n)"
  },
  {
    "x": "gicik (n)",
    "y": " b. gijik (n)"
  },
  {
    "x": "gidî, -ye-I",
    "y": " küçük, ufak"
  },
  {
    "x": "gidî-II",
    "y": " b. kidî "
  },
  {
    "x": "gidî gidî",
    "y": " b. kidê kidê"
  },
  {
    "x": "gierm (m)",
    "y": " b. germî-II (m)"
  },
  {
    "x": "gierzi (n)",
    "y": " b. girze (n)"
  },
  {
    "x": "gij!",
    "y": " çiftleşmesi için boğa vb. erkek hayvanları tahrik etmek amacıyla kullanılan bir sözcük"
  },
  {
    "x": "gij gij!",
    "y": " çiftleşmesi için boğa vb. erkek hayvanları tahrik etmek amacıyla kullanılan bir sözcük"
  },
  {
    "x": "gij kerdene",
    "y": " (boğa vb. için) çiftleşmesi için erkek hayvanları tahrik etmek"
  },
  {
    "x": "gijelek (m)",
    "y": " b. gijoleke (m)"
  },
  {
    "x": "gijgeleke (m)",
    "y": " b. gijoleke (m)"
  },
  {
    "x": "gijgêleke (m)",
    "y": " b. gijoleke (m)"
  },
  {
    "x": "gijgolek (m)",
    "y": " b. gijoleke (m)"
  },
  {
    "x": "gijik (n)",
    "y": " saç"
  },
  {
    "x": "gijikin, -e",
    "y": " karışık, karmakarışık (birbirine dolanmış olan)\r\n~Porê kêneke gijikin bî. (Kızın saçları karmakarışıktı.)"
  },
  {
    "x": "gijikinî (m)",
    "y": " karışıklık (birbirine dolanma durumu, saç vb. için)"
  },
  {
    "x": "gijikinîye (m)",
    "y": " karışıklık (birbirine dolanma durumu, saç vb. için)"
  },
  {
    "x": "gijlik (m)",
    "y": " b. gijoleke (m)"
  },
  {
    "x": "gijolek (m)",
    "y": " b. gijoleke (m)"
  },
  {
    "x": "gijoleke (m)",
    "y": " hortum"
  },
  {
    "x": "gijoleki (m)",
    "y": " b. gijoleke (m)"
  },
  {
    "x": "gijûgale (m)",
    "y": " çalı çırpı"
  },
  {
    "x": "gijûgali (m)",
    "y": " b. gijûgale (m)"
  },
  {
    "x": "gil-I (n)",
    "y": " tepe, doruk, şahika, zirve"
  },
  {
    "x": "gilê dare (n)",
    "y": " ağacın tepesi"
  },
  {
    "x": "gilê koy (n)",
    "y": " b. gilê koyî (n)"
  },
  {
    "x": "gilê koyî (n)",
    "y": " dağın tepesi"
  },
  {
    "x": "gil-II (n)",
    "y": " dal"
  },
  {
    "x": "gil kerdene",
    "y": " budamak (ağaç için)"
  },
  {
    "x": "gilê dare (n)",
    "y": " ağacın dalı"
  },
  {
    "x": "gilê qirrike (n)",
    "y": " âdemelması (gırtlak çıkıntısı)"
  },
  {
    "x": "gila",
    "y": " b. gilangna"
  },
  {
    "x": "gilal (m)",
    "y": " b. gûlale (m)"
  },
  {
    "x": "gilalek (m)",
    "y": " b. gûlaleke (m)"
  },
  {
    "x": "gilana",
    "y": " b. gilangna"
  },
  {
    "x": "gilanga",
    "y": " b. gilangan"
  },
  {
    "x": "gilanga morekan (m)",
    "y": " b. gilanga murayan (m)"
  },
  {
    "x": "gilanga murayan (m)",
    "y": " boncuk dizisi"
  },
  {
    "x": "gilangan",
    "y": " bazen"
  },
  {
    "x": "gilange (m)",
    "y": " kez, kere, defa, sefer"
  },
  {
    "x": "gilangê",
    "y": " bir kez"
  },
  {
    "x": "gilangêk",
    "y": " bir kez"
  },
  {
    "x": "gilangi (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "gilangî",
    "y": " b. gilangê"
  },
  {
    "x": "gilangna",
    "y": " yine, gene, tekrar"
  },
  {
    "x": "gilangon",
    "y": " b. gilangan"
  },
  {
    "x": "gilar (n)",
    "y": " 1)(bazı hayvanların boyunlarının iki yanından sarkan deri uzantısı) küpe\r\n~2)gerdan"
  },
  {
    "x": "gilare (n)",
    "y": " b. gilar (n)"
  },
  {
    "x": "gilarey muron (n)",
    "y": " b. gilareyê murayan (n)"
  },
  {
    "x": "gilareyê murayan (n)",
    "y": " boncuk dizisi"
  },
  {
    "x": "gilare (m)",
    "y": " b. gilar (n)"
  },
  {
    "x": "Gilare (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "Gilari (m)",
    "y": " b. Gilare (m)"
  },
  {
    "x": "gilazinge (m)",
    "y": " çakı, küçük bıçak"
  },
  {
    "x": "gilbe-I (m)",
    "y": " boğaz"
  },
  {
    "x": "gilbe-II (n)",
    "y": " kafile"
  },
  {
    "x": "gilbe-III (n)",
    "y": " salkım"
  },
  {
    "x": "gilbedeginayîş (n)",
    "y": " bademcik şişmesi"
  },
  {
    "x": "gilbi (m)",
    "y": " b. gilbe-I (m)"
  },
  {
    "x": "gilbideginayîş (n)",
    "y": " b. gilbedeginayîş (n)"
  },
  {
    "x": "gilçica (m)",
    "y": " b. gilçija (m)"
  },
  {
    "x": "gilçice (n)",
    "y": " b. gilçije (n) "
  },
  {
    "x": "gilçija (m)",
    "y": " ailede ilk kız çocuk"
  },
  {
    "x": "gilçije (n)",
    "y": " ailede ilk erkek çocuk"
  },
  {
    "x": "gilçik (n)",
    "y": " ağacın tepesindeki dal"
  },
  {
    "x": "gildane (m)",
    "y": " bir üzüm çeşidi"
  },
  {
    "x": "gildani (m)",
    "y": " b. gildane (m)"
  },
  {
    "x": "gilej (n)",
    "y": " b. gilêze (m)"
  },
  {
    "x": "gilejige (m)",
    "y": " b. gilêze (m)"
  },
  {
    "x": "gilejin, -e",
    "y": " b. gilêzin, -e"
  },
  {
    "x": "gilejîge (m)",
    "y": " b. gilêze (m)"
  },
  {
    "x": "gilendike (m)",
    "y": " b. gindêlike (m)"
  },
  {
    "x": "gilenjike (m)",
    "y": " b. gilêze (m)"
  },
  {
    "x": "gilenjikin, -e",
    "y": " b. gilêzin, -e"
  },
  {
    "x": "gileyî (zh)",
    "y": " yele"
  },
  {
    "x": "gilê",
    "y": " b. kilê, dakilê"
  },
  {
    "x": "gilê (zh)",
    "y": " b. gileyî (zh)"
  },
  {
    "x": "gilê kile (n)",
    "y": " alev dili (itfaiye terimi)"
  },
  {
    "x": "gilêjgi (m)",
    "y": " b. gilêze (m)"
  },
  {
    "x": "gilêjgin, -e",
    "y": " b. gilêzin, -e"
  },
  {
    "x": "gilêjgî (m)",
    "y": " b. gilêze (m)"
  },
  {
    "x": "gilêjin, -e",
    "y": " b. gilêzin, -e"
  },
  {
    "x": "gilêjî",
    "y": " b. gilêze (m)"
  },
  {
    "x": "gilêjk (m)",
    "y": " b. gilêze (m)"
  },
  {
    "x": "gilêjkin, -e",
    "y": " b. gilêzin, -e"
  },
  {
    "x": "gilêndike (m)",
    "y": " b. gindêlike (m)"
  },
  {
    "x": "gilêrî bîyayîne",
    "y": " b. gilêrî bîyene"
  },
  {
    "x": "gilêrî bîyene",
    "y": " yuvarlanmak"
  },
  {
    "x": "gilêrî kerdene",
    "y": " yuvarlamak"
  },
  {
    "x": "gilêsk (m)",
    "y": " b. gilêze (m)"
  },
  {
    "x": "gilêskin, -e",
    "y": " b. gilêzin, -e"
  },
  {
    "x": "gilêskî (m)",
    "y": " b. gilêze (m)"
  },
  {
    "x": "gilêşa xo to kerdene",
    "y": " b. gilêşê xo to kerdene"
  },
  {
    "x": "gilêşê xo to kerdene",
    "y": " eteğini toplamak "
  },
  {
    "x": "gilêşk (m)",
    "y": " b. gilêze (m)"
  },
  {
    "x": "gilêşki (m)",
    "y": " b. gilêze (m)"
  },
  {
    "x": "gilêşkin, -e",
    "y": " b. gilêzin, -e"
  },
  {
    "x": "gilêşkî (m)",
    "y": " b. gilêze (m)"
  },
  {
    "x": "gilêz (m)",
    "y": " b. gilêze (m)"
  },
  {
    "x": "gilêze (m)",
    "y": " salya"
  },
  {
    "x": "gilêze fek ra şîyene",
    "y": " ağzından salya akmak"
  },
  {
    "x": "gilêzge (m)",
    "y": " b. gilêze (m)"
  },
  {
    "x": "gilêzgin, -e",
    "y": " b. gilêzin, -e"
  },
  {
    "x": "gilêzgî (m)",
    "y": " b. gilêze (m)"
  },
  {
    "x": "gilêzin, -e",
    "y": " salyalı"
  },
  {
    "x": "gilêzî (m)",
    "y": " b. gilêze (m)"
  },
  {
    "x": "gilgil (n)",
    "y": " 1)mısır\r\n~2)darı, akdarı"
  },
  {
    "x": "gilgilê kabîrî (n)",
    "y": " mısır"
  },
  {
    "x": "gilig (n)",
    "y": " b. gilik (n)"
  },
  {
    "x": "gilik (n)",
    "y": " bızır, klitoris"
  },
  {
    "x": "gilingi (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "gilingî",
    "y": " b. gilangê"
  },
  {
    "x": "gilî (m)",
    "y": " b. gillî (m)"
  },
  {
    "x": "gilî dekewtene",
    "y": " b. gillî dekewtene"
  },
  {
    "x": "gilî kerdene",
    "y": " b. gillî kerdene "
  },
  {
    "x": "gilîyeki kerdene",
    "y": " b. gillîyeke kerdene"
  },
  {
    "x": "gilî ra",
    "y": " dopdolu, ağzına kadar"
  },
  {
    "x": "gilîye (m)",
    "y": " b. gillî (m)-1"
  },
  {
    "x": "gilîyejg (m)",
    "y": " b. gilêze (m)"
  },
  {
    "x": "gilîyejgin",
    "y": " b. gilêzin, -e"
  },
  {
    "x": "gilkerdiş (n)",
    "y": " budama (ağaç için)"
  },
  {
    "x": "gillik (n)",
    "y": " b. gilik (n)"
  },
  {
    "x": "gillî (m)",
    "y": " 1)göz yuvarlağı\r\n~2)üzüm tanesi"
  },
  {
    "x": "gillî dekewtene",
    "y": " üzüm tanelerinin tek tük olgunlaşmaya başlaması"
  },
  {
    "x": "gillî kerdene",
    "y": " üzüm yerken salkımdan sadece bazı taneleri yemek veya bazı üzüm tanelerini salkımdan koparmak"
  },
  {
    "x": "gillîyeke kerdene",
    "y": " salkımı dalından koparmadan üzüm tanelerinin bazılarını koparıp yemek"
  },
  {
    "x": "gillî ra",
    "y": " b. gilî ra"
  },
  {
    "x": "gillîkerdiş (n)",
    "y": " üzüm tanelerini salkımdan koparma"
  },
  {
    "x": "giloka hakî (m)",
    "y": " yumurta sarısı"
  },
  {
    "x": "gilolika hakî (m)",
    "y": " yumurta sarısı"
  },
  {
    "x": "gilolikê hakî (m)",
    "y": " b. gilolika hakî (m)"
  },
  {
    "x": "gilolo bîyayîş",
    "y": " b. gilêrî bîyene"
  },
  {
    "x": "gilolo kerdiş",
    "y": " b. gilêrî kerdene"
  },
  {
    "x": "gilona",
    "y": " b. gilangna"
  },
  {
    "x": "gilonc (n)",
    "y": " kısa urgan"
  },
  {
    "x": "gilong (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "gilongê",
    "y": " b. gilangê"
  },
  {
    "x": "gilongi (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "gilongî",
    "y": " b. gilangê"
  },
  {
    "x": "gilongon",
    "y": " b. gilangan"
  },
  {
    "x": "gilor, -e",
    "y": " b. gilover, -e"
  },
  {
    "x": "gilorike (m)",
    "y": " köfte (ince yarmadan veya kıymadan yapılan)"
  },
  {
    "x": "gilorin, -e",
    "y": " yuvarlak, toparlak"
  },
  {
    "x": "gilover, -e",
    "y": " yuvarlak, toparlak"
  },
  {
    "x": "gilovera trafîkî (m)",
    "y": " trafik göbeği"
  },
  {
    "x": "giluer",
    "y": " b. gilover (n)"
  },
  {
    "x": "gilûng (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "gilûngê",
    "y": " b. gilangê"
  },
  {
    "x": "gilûngî",
    "y": " b. gilangê"
  },
  {
    "x": "gilyas (m)",
    "y": " b. gilyaze (m)"
  },
  {
    "x": "gilyasêr (m)",
    "y": " b. gilyazêre (m)"
  },
  {
    "x": "gilyaze (m)",
    "y": " kiraz"
  },
  {
    "x": "gilyazêr (m)",
    "y": " b. gilyazêre (m)"
  },
  {
    "x": "gilyazêre (m)",
    "y": " kiraz ağacı"
  },
  {
    "x": "gilyazêri (m)",
    "y": " b. gilyazêre (m)"
  },
  {
    "x": "gilyazi (m)",
    "y": " b. gilyaze (m)"
  },
  {
    "x": "gilyone (n)",
    "y": " b. girane (n)"
  },
  {
    "x": "gilze (n)",
    "y": " b. girze (n)"
  },
  {
    "x": "gimar (m)",
    "y": " b. gemare (m)"
  },
  {
    "x": "gimarênîye (m)",
    "y": " gemarênî (m)"
  },
  {
    "x": "gimarin, -e",
    "y": " b. gemarin, -e"
  },
  {
    "x": "gimarin bîyayene",
    "y": " b. gemarin bîyene "
  },
  {
    "x": "gimarinbîyayîş (n)",
    "y": " b. gemarinbîyayîş (n)"
  },
  {
    "x": "gimarinîye (m)",
    "y": " b. gemarinîye (m)"
  },
  {
    "x": "gimarinkerdiş (n)",
    "y": " b. gemarinkerdiş (n)"
  },
  {
    "x": "gimarî (m)",
    "y": " b. gemare (m)"
  },
  {
    "x": "gimayîş (n)",
    "y": " ağız kapalı olarak ses çıkarma"
  },
  {
    "x": "Gimgim (n)",
    "y": " Varto"
  },
  {
    "x": "gina",
    "y": " b. gilangna"
  },
  {
    "x": "ginana",
    "y": " b. gilangna"
  },
  {
    "x": "ginayene",
    "y": " 1)dokunmak (yiyecek vb.)\r\n~2)dokunmak (söz vb. için), ağırına gitmek\r\n~Zaf îsot werdo, bide ginayo/ciginayo. (Çok biber yemiş, dokunmuş.)\r\n~\t\r\n~A qala to zaf bi mi ginaye. (O sözün bana çok dokundu.)\r\n~\t\r\n~Qisaya ey mi ginaye. (Onun sözü ağırıma gitti.)"
  },
  {
    "x": "ginayîş (n)",
    "y": " 1)dokunma (yiyecek vb.)\r\n~2)dokunma (söz vb. için)"
  },
  {
    "x": "ginc (n)",
    "y": " b. kinc (n)"
  },
  {
    "x": "ginda (m)",
    "y": " yumak"
  },
  {
    "x": "gindelîke (m)",
    "y": " b. gindêlike (m)"
  },
  {
    "x": "gindêlike (m)",
    "y": " yumak "
  },
  {
    "x": "gindilor, -e",
    "y": " yuvarlak, toparlak"
  },
  {
    "x": "gindir bîyayîne",
    "y": " b. gindir bîyene"
  },
  {
    "x": "gindir bîyene",
    "y": " yuvarlanmak"
  },
  {
    "x": "gindir dayene",
    "y": " yuvarlamak"
  },
  {
    "x": "gindir dîyayîne",
    "y": " yuvarlanmak"
  },
  {
    "x": "gindir kerdene",
    "y": " yuvarlamak"
  },
  {
    "x": "gindirîyayene",
    "y": " yuvarlanmak"
  },
  {
    "x": "gindirnayene",
    "y": " yuvarlamak"
  },
  {
    "x": "gindirrîyayîne",
    "y": " b. gindirîyayene"
  },
  {
    "x": "Gindo (n)",
    "y": " köpek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "gindore (m)",
    "y": " yuvak, loğ, yuvgu"
  },
  {
    "x": "gindori (m)",
    "y": " b. gindore (m)"
  },
  {
    "x": "ginî",
    "y": " b. ganî-II"
  },
  {
    "x": "gipik (n)",
    "y": " gonca, konca, tomurcuk"
  },
  {
    "x": "gir (n)",
    "y": " tepe"
  },
  {
    "x": "girê koyî (n)",
    "y": " dağın tepesi"
  },
  {
    "x": "gir bîyene",
    "y": " b. xir bîyene"
  },
  {
    "x": "gir dayene",
    "y": " b. gêr dayene"
  },
  {
    "x": "gir kerdene",
    "y": " b. xir kerdene"
  },
  {
    "x": "gira-I (m)",
    "y": " b. girê (n)"
  },
  {
    "x": "gira kore (m)",
    "y": " b. girêyo kor (n)"
  },
  {
    "x": "gira-II (n)",
    "y": " b. giran (n)"
  },
  {
    "x": "gira bîyayene",
    "y": " b. giran bîyene"
  },
  {
    "x": "gira-gira",
    "y": " b. giran-giran"
  },
  {
    "x": "giran, -e-I",
    "y": " 1)ağır\r\n~2)ağırbaşlı, ciddi"
  },
  {
    "x": "giran ameyene",
    "y": " ağır gelmek, ağır kaçmak"
  },
  {
    "x": "giran bîyayîne",
    "y": " b. giran bîyene"
  },
  {
    "x": "giran bîyene",
    "y": " 1)ağırlaşmak, ağır olmak\r\n~2)kötüleşmek (hastalık için)\r\n~3)ciddi olmak"
  },
  {
    "x": "giran kerdene",
    "y": " ağırlaştırmak"
  },
  {
    "x": "giran nêweş bîyene",
    "y": " ağır hasta olmak"
  },
  {
    "x": "giran-giran",
    "y": " 1)ağır ağır, yavaş yavaş\r\n~2)giderek, gittikçe\r\n~“Hey hey Çemî Pîran\r\n~\t\r\n~Hêdî şin, giran-giran” (Remezan Oruç, Vate, Nr. 31)"
  },
  {
    "x": "giran-II (n)",
    "y": " balyoz"
  },
  {
    "x": "girana",
    "y": " b. gilangna"
  },
  {
    "x": "giranameyîş (n)",
    "y": " ağır gelme"
  },
  {
    "x": "giranbîyayîş (n)",
    "y": " ağırlaşma"
  },
  {
    "x": "girane-I (n)",
    "y": " yaka"
  },
  {
    "x": "giraneyê çakêtî (n)",
    "y": " ceket yakası"
  },
  {
    "x": "giraneyê setreyî (n)",
    "y": " ceket yakası"
  },
  {
    "x": "giraneyê şakî (n)",
    "y": " ceket yakası"
  },
  {
    "x": "giraneyê yewbînî girewtene",
    "y": " boğuşmak"
  },
  {
    "x": "giraneyê yewbînî tepîştene",
    "y": " boğuşmak"
  },
  {
    "x": "giraneyî ver de bîyene",
    "y": " can çekişmek, ölüm döşeğinde olmak"
  },
  {
    "x": "giraneyo gilover (n)",
    "y": " yuvarlak yaka"
  },
  {
    "x": "giraneyo tîk (n)",
    "y": " dik yaka (kazak vb. için)"
  },
  {
    "x": "giranî ver de bîyene",
    "y": " b. giraneyî ver de bîyene"
  },
  {
    "x": "girane-II (m)",
    "y": " b. giranî (m)"
  },
  {
    "x": "giraney (m)",
    "y": " b. giranîye (m), giranî (m)"
  },
  {
    "x": "giraneyêyewbînîgirewtiş (n)",
    "y": " boğuşma"
  },
  {
    "x": "giraneyêyewbînîtepîştiş (n)",
    "y": " boğuşma"
  },
  {
    "x": "giraneyîverdebîyayîş (n)",
    "y": " can çekişme"
  },
  {
    "x": "giranê (m)",
    "y": " b. giranî (m)"
  },
  {
    "x": "giranênî (m)",
    "y": " ağırlık"
  },
  {
    "x": "girang (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "girangi (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "giranî (m)",
    "y": " 1)ağırlık\r\n~2)ağırbaşlılık, ciddilik, ciddiyet\r\n~3)pahalılık\r\n~4)tartı"
  },
  {
    "x": "giranîya aksî (m)",
    "y": " dingil ağırlığı"
  },
  {
    "x": "giranîya dingilî (m)",
    "y": " dingil ağırlığı"
  },
  {
    "x": "giranîya tewr vêşî (m)",
    "y": " azami ağırlık"
  },
  {
    "x": "giranîya tewr zafe (m)",
    "y": " azami ağırlık"
  },
  {
    "x": "giranîye (m)",
    "y": " 1)ağırlık\r\n~2)ağırbaşlılık, ciddilik, ciddiyet\r\n~3)pahalılık\r\n~4)tartı"
  },
  {
    "x": "girankerdiş (n)",
    "y": " ağırlaştırma"
  },
  {
    "x": "girawe (m)",
    "y": " 1)ada\r\n~2)burgaç, girdap; eğrim"
  },
  {
    "x": "girawa awanî (m)",
    "y": " imar adası"
  },
  {
    "x": "Girawê Falklandî (zh)",
    "y": " Falkland Adaları"
  },
  {
    "x": "Girawê Marşalî (zh)",
    "y": " Marshall Adaları"
  },
  {
    "x": "Girawê Solomonî (zh)",
    "y": " Solomon Adaları"
  },
  {
    "x": "giraweke (m)",
    "y": " adacık"
  },
  {
    "x": "girawij, -e",
    "y": " adalı"
  },
  {
    "x": "girçike (n)",
    "y": " budak"
  },
  {
    "x": "girçikin, -e",
    "y": " budaklı"
  },
  {
    "x": "gird, -e",
    "y": " büyük"
  },
  {
    "x": "gird kerdene",
    "y": " büyütmek"
  },
  {
    "x": "girda (m)",
    "y": " delikli köfte (ince yarmadan yapılan)"
  },
  {
    "x": "girdey (m)",
    "y": " b. girdîye (m)"
  },
  {
    "x": "girdê (m)",
    "y": " b. girdîye (m)"
  },
  {
    "x": "girdîye (m)",
    "y": " büyüklük"
  },
  {
    "x": "girdos (m)",
    "y": " b. herdose (m)"
  },
  {
    "x": "gire (n)",
    "y": " b. girê (n)"
  },
  {
    "x": "gire dayene",
    "y": " b. girê dayene"
  },
  {
    "x": "gireyê dare (n)",
    "y": " b. girêyê dare (n)"
  },
  {
    "x": "gireyo kor (n)",
    "y": " b. girêyo kor (n)"
  },
  {
    "x": "giredayîş (n)",
    "y": " b. girêdayîş (n)"
  },
  {
    "x": "girek-I (n)",
    "y": " 1)b. girêk (n)\r\n~2)b. girêkê dare (n)"
  },
  {
    "x": "gireko kor",
    "y": " b. girêko kor (n)"
  },
  {
    "x": "girek-II",
    "y": " b. gerek, ganî-II"
  },
  {
    "x": "girekor (n)",
    "y": " b. girêkor (n)"
  },
  {
    "x": "girenayîş (n)",
    "y": " b. girênayîş (n)"
  },
  {
    "x": "giretene",
    "y": " b. girewtene"
  },
  {
    "x": "giretiş (n)",
    "y": " b. girewtiş (n)"
  },
  {
    "x": "girewta (m)",
    "y": " kapalı"
  },
  {
    "x": "girewte (n)",
    "y": " kapalı"
  },
  {
    "x": "girewtek (n)",
    "y": " alıcı"
  },
  {
    "x": "girewtene",
    "y": " 1)almak\r\n~2)satın almak\r\n~3)kapatmak"
  },
  {
    "x": "girewtene fekê xo",
    "y": " anmak\r\n~Nameyê ey megîre fekê xo. (Onun adını anma.)"
  },
  {
    "x": "girewtene fîlm",
    "y": " filme almak"
  },
  {
    "x": "girewtene xo ser",
    "y": " üstlenmek"
  },
  {
    "x": "gojage/make/bişkoje/qumça/sedefe/zirare girewtene",
    "y": " düğme iliklemek"
  },
  {
    "x": "girewteni",
    "y": " b. girewtene"
  },
  {
    "x": "girewtiş (n)",
    "y": " 1)alma\r\n~2)alım, alış\r\n~3)satın alma\r\n~4)kapatma"
  },
  {
    "x": "girewtişê karmusan (n)",
    "y": " stajyer alımı"
  },
  {
    "x": "girewtişê stajyeran (n)",
    "y": " stajyer alımı"
  },
  {
    "x": "girewtişfekêxo",
    "y": " anma"
  },
  {
    "x": "girewtî, -ye",
    "y": " kapalı"
  },
  {
    "x": "girewtox, -e",
    "y": " alıcı"
  },
  {
    "x": "girey (m)",
    "y": " b. girê (n)"
  },
  {
    "x": "girey dayene",
    "y": " b. girê dayene"
  },
  {
    "x": "gireya dare (m)",
    "y": " b. girêyê dare (n)"
  },
  {
    "x": "gireya kore (m)",
    "y": " b. girêyo kor (n)"
  },
  {
    "x": "gireya zuqlike (n)",
    "y": " b. girêyê zuqlike (n)"
  },
  {
    "x": "gireydaye (n)",
    "y": " b. girêdaye (n)"
  },
  {
    "x": "gireydayeyey (m)",
    "y": " b. girêdayeyîye (m)"
  },
  {
    "x": "gireydayîş (n)",
    "y": " b. girêdayîş (n)"
  },
  {
    "x": "gireynaya (m)",
    "y": " girênaya (m)"
  },
  {
    "x": "gireynaye (n)",
    "y": " girênaye (n)"
  },
  {
    "x": "gireynayene",
    "y": " b. girênayene"
  },
  {
    "x": "gireynayîş (n)",
    "y": " b. girênayîş (n)"
  },
  {
    "x": "girê (n)",
    "y": " 1)düğüm\r\n~2)boğum (ağaç için)"
  },
  {
    "x": "girê dayene",
    "y": " 1)bağlamak\r\n~2)düğümlemek\r\n~3)kuşanmak (kuşak, fişeklik vb. için)"
  },
  {
    "x": "girê dîyayene",
    "y": " bağlanmak"
  },
  {
    "x": "girêyê dare (n)",
    "y": " ağaç boğumu"
  },
  {
    "x": "girêyê zuqlike (n)",
    "y": " âdemelması (gırtlak çıkıntısı)"
  },
  {
    "x": "girêyo kor (n)",
    "y": " kördüğüm"
  },
  {
    "x": "girêdaya (m)",
    "y": " bağlı, bağımlı"
  },
  {
    "x": "girêdaye (n)",
    "y": " 1)bağlı, bağımlı\r\n~2)çevrimiçi (TE)"
  },
  {
    "x": "girêdaye bîyene",
    "y": " bağımlılaşmak"
  },
  {
    "x": "girêdayebîyayîş (n)",
    "y": " bağımlılaşma"
  },
  {
    "x": "girêdayeyî (m)",
    "y": " bağlılık"
  },
  {
    "x": "girêdayeyîye (m)",
    "y": " bağlılık, bağımlılık"
  },
  {
    "x": "girêdayê (m)",
    "y": " b. girêdaya (m)"
  },
  {
    "x": "girêdayî, -ye",
    "y": " bağlı, bağımlı"
  },
  {
    "x": "girêdayî bîyene",
    "y": " bağımlılaşmak"
  },
  {
    "x": "girêdayîbîyayîş (n)",
    "y": " bağımlılaşma"
  },
  {
    "x": "girêdayîş (n)",
    "y": " 1)bağlama\r\n~2)ilişki (TE), irtibat (TE)\r\n~3)düğümleme\r\n~4)kuşanma (kuşak, fişeklik vb. için)"
  },
  {
    "x": "girêdayîyî (m)",
    "y": " b girêdayeyî (m)"
  },
  {
    "x": "girêdayîyîye (m)",
    "y": " bağlılık, bağımlılık"
  },
  {
    "x": "girêdayox, -e",
    "y": " bağlayıcı"
  },
  {
    "x": "girêdayoxîye (m)",
    "y": " bağlayıcılık"
  },
  {
    "x": "girêdîyayîş (n)",
    "y": " bağlanma"
  },
  {
    "x": "girêk (n)",
    "y": " 1)boğum (ağaç için)\r\n~2)düğüm"
  },
  {
    "x": "girêkê dare (n)",
    "y": " ağaç boğumu"
  },
  {
    "x": "girêko kor (n)",
    "y": " kördüğüm"
  },
  {
    "x": "girêkor (n)",
    "y": " kördüğüm"
  },
  {
    "x": "girênaya (m)",
    "y": " 1)kaynatılmış olan\r\n~2)çok sıcak olan (yemek, içecek vb.)"
  },
  {
    "x": "girênaye (n)",
    "y": " kaynatılmış olan"
  },
  {
    "x": "girênaye bîyene",
    "y": " çok sıcak olmak (yemek, içecek vb. için)"
  },
  {
    "x": "girênayek (n)",
    "y": " kaynatıcı"
  },
  {
    "x": "girênayekê awe (n)",
    "y": " su kaynatıcı"
  },
  {
    "x": "girênayene",
    "y": " kaynatmak"
  },
  {
    "x": "girênayîş (n)",
    "y": " kaynatma"
  },
  {
    "x": "girênayîye (m)",
    "y": " 1)kaynatılmış olan\r\n~2)çok sıcak olan (yemek, içecek vb.)"
  },
  {
    "x": "girênêdaye (n) (TE)",
    "y": " çevrimdışı"
  },
  {
    "x": "girênîyayene",
    "y": " kaynatılmak"
  },
  {
    "x": "girênîyayîş (n)",
    "y": " kaynatılma"
  },
  {
    "x": "girêş (n)",
    "y": " b. girrêş (n)"
  },
  {
    "x": "girêşê gedan (n)",
    "y": " b. girrêşê gedeyan (n)"
  },
  {
    "x": "girêyaya (m)",
    "y": " kaynamış olan"
  },
  {
    "x": "girêyaye (n)",
    "y": " kaynamış olan"
  },
  {
    "x": "girêyayene",
    "y": " 1)kaynamak\r\n~2)çağlamak (mecazî)"
  },
  {
    "x": "girêyayîş (n)",
    "y": " 1)kaynama\r\n~2)çağlama (mecazî)"
  },
  {
    "x": "girêyayîye (m)",
    "y": " kaynamış olan"
  },
  {
    "x": "girgil (n)",
    "y": " b. gilgil (n)"
  },
  {
    "x": "girgir (n)",
    "y": " gırgır (süpürge türü)"
  },
  {
    "x": "girgoçîne (m)",
    "y": " b. gogerçîne (m)"
  },
  {
    "x": "girgudike (m)",
    "y": " köfte (ince yarmadan veya kıymadan yapılan)"
  },
  {
    "x": "giri (n)",
    "y": " b. girê (n)"
  },
  {
    "x": "giri dayene",
    "y": " b. girê dayene"
  },
  {
    "x": "giro kor (n)",
    "y": " b. girêyo kor (n)"
  },
  {
    "x": "giridayîş (n)",
    "y": " b. girêdayîş (n)"
  },
  {
    "x": "girike (m)",
    "y": " 1)topaç\r\n~2)köfte (ince yarmadan veya kıymadan yapılan)"
  },
  {
    "x": "giriki (m)",
    "y": " b. girike (m)"
  },
  {
    "x": "giriş (n)",
    "y": " b. girrêş (n)"
  },
  {
    "x": "girişî firaqûn (n)",
    "y": " b. girrêşê firaqan (m)"
  },
  {
    "x": "girişî qicûn (n)",
    "y": " b. girrêşê qican (n)"
  },
  {
    "x": "giriz (n)",
    "y": " pürüz"
  },
  {
    "x": "girizin, -e",
    "y": " pürüzlü"
  },
  {
    "x": "girînaya (m)",
    "y": " b. girênaya (m), girênayîye (m)"
  },
  {
    "x": "girînaye (n)",
    "y": " b. girênaye (n)"
  },
  {
    "x": "girînayene",
    "y": " b. girênayene"
  },
  {
    "x": "girînayîş (n)",
    "y": " b. girênayîş (n)"
  },
  {
    "x": "girîng, -e",
    "y": " önemli, belli başlı"
  },
  {
    "x": "karêko girîng/muhîm kerdene",
    "y": " önemli bir iş yapmak"
  },
  {
    "x": "girîngî (m)",
    "y": " önem"
  },
  {
    "x": "girîngîya cigêrayîşî (m)",
    "y": " araştırmanın önemi"
  },
  {
    "x": "girîngîye (m)",
    "y": " önem"
  },
  {
    "x": "girîngîye/girîngî dayene",
    "y": " önem vermek"
  },
  {
    "x": "girînîyayene",
    "y": " b. girênîyayene"
  },
  {
    "x": "girînîyayîş (n)",
    "y": " b. girênîyayîş (n)"
  },
  {
    "x": "girîs (n)",
    "y": " b. girrêş (n)"
  },
  {
    "x": "girîş (n)",
    "y": " b. girrêş (n)"
  },
  {
    "x": "girîşî pitikû (n)",
    "y": " b. girrêşê pitikan (n)"
  },
  {
    "x": "girîyaya (m)",
    "y": " b girêyaya (m)"
  },
  {
    "x": "girîyaye (n)",
    "y": " b. b. girêyaye (n)"
  },
  {
    "x": "girîyayene",
    "y": " b. girêyayene"
  },
  {
    "x": "girîyayîş (n)",
    "y": " b. b. girêyayîş (n)"
  },
  {
    "x": "girîyayîye (m)",
    "y": " b. girêyayîye (m)"
  },
  {
    "x": "girmik (m)",
    "y": " b. girmike (m)"
  },
  {
    "x": "girmike (m)",
    "y": " yumruk"
  },
  {
    "x": "girmikî eştene",
    "y": " yumruk atmak, yumruk indirmek"
  },
  {
    "x": "girmikî kerdene",
    "y": " yumruklamak"
  },
  {
    "x": "girmikî piro dayene",
    "y": " yumruk atmak, yumruk indirmek"
  },
  {
    "x": "girmikî piro ginayene",
    "y": " yumruklanmak"
  },
  {
    "x": "girmikî werdene",
    "y": " yumruklanmak"
  },
  {
    "x": "girmiki (m)",
    "y": " b. girmike (m)"
  },
  {
    "x": "girmikîeştiş (n)",
    "y": " yumruk atma"
  },
  {
    "x": "girmikîkerdiş (n)",
    "y": " yumruklama"
  },
  {
    "x": "girmikîpirodayîş (n)",
    "y": " yumruk atma"
  },
  {
    "x": "girmikîpiroginayîş (n)",
    "y": " yumruklanma"
  },
  {
    "x": "girmikîwerdiş (n)",
    "y": " yumruklanma"
  },
  {
    "x": "girnaw (m)",
    "y": " b. girnawî (m)"
  },
  {
    "x": "girnawî (m)",
    "y": " kösnük (kedi için)"
  },
  {
    "x": "girnawîyayene",
    "y": " kösnümek (kedi için)  "
  },
  {
    "x": "girnawîyayîş (n)",
    "y": " kösnüme (kedi için)"
  },
  {
    "x": "girnawû (m)",
    "y": " b. girnawî (m)"
  },
  {
    "x": "girnawû kerdene",
    "y": " b. qirnaw kerdene "
  },
  {
    "x": "girnawûkerdiş (n)",
    "y": " b. qirnawkerdiş (n)"
  },
  {
    "x": "girnaya (m)",
    "y": " b. girênaya (m), girênayîye (m)"
  },
  {
    "x": "girnaye (n)",
    "y": " b. girênaye (n)"
  },
  {
    "x": "girnayene-I",
    "y": " ağıt yakmak, ağıt düzmek, ağıt tutturmak"
  },
  {
    "x": "girnayene-II",
    "y": " b. girênayene"
  },
  {
    "x": "girnayîş (n)",
    "y": " b. girênayîş (n)"
  },
  {
    "x": "girne (n)",
    "y": " b. girane (n)"
  },
  {
    "x": "girnîyaw (m)",
    "y": " b. girnawî (m)"
  },
  {
    "x": "girnîyawîyayîş (n)",
    "y": " b. girnawîyayîş (n)"
  },
  {
    "x": "girnîyawîyene",
    "y": " b. girnawîyayene"
  },
  {
    "x": "girnîyayene",
    "y": " b. girênîyayene"
  },
  {
    "x": "girnîyayîş (n)",
    "y": " b. girênîyayîş (n)"
  },
  {
    "x": "giro",
    "y": " b. giran, -e"
  },
  {
    "x": "giro bîyayene",
    "y": " b. giran bîyene"
  },
  {
    "x": "giro-giro",
    "y": " b. giran-giran"
  },
  {
    "x": "giron, -i",
    "y": " b. giran, -e"
  },
  {
    "x": "giron bîyayene",
    "y": " b. giran bîyene"
  },
  {
    "x": "giron-giron",
    "y": " b. giran-giran"
  },
  {
    "x": "girone (n)",
    "y": " b. girane (n)"
  },
  {
    "x": "gironey (m)",
    "y": " b. giranîye (m), giranî (m)"
  },
  {
    "x": "gironê (m)",
    "y": " b. giranî (m), giranîye (m)"
  },
  {
    "x": "giroten",
    "y": " b. girewtene"
  },
  {
    "x": "girotene",
    "y": " b. girewtene"
  },
  {
    "x": "girotiş (n)",
    "y": " b. girewtiş (n)"
  },
  {
    "x": "girotiş verika xo",
    "y": " b. xo verike girewtene"
  },
  {
    "x": "girowtene",
    "y": " b. girewtene"
  },
  {
    "x": "girowtiş (n)",
    "y": " b. girewtiş (n)"
  },
  {
    "x": "girr bîyene",
    "y": " b. xir bîyene"
  },
  {
    "x": "girr kerdene",
    "y": " b. xir kerdene"
  },
  {
    "x": "girran (n)",
    "y": " b. giran-II (n)"
  },
  {
    "x": "girrês (n)",
    "y": " b. girrêş"
  },
  {
    "x": "girrêş (n)",
    "y": " 1)elbise, giyecek, giysi, kılık\r\n~2)bez parçası, çaput, paçavra\r\n~3)çocuk bezi"
  },
  {
    "x": "girrêşê firaqan (m)",
    "y": " bulaşık bezi"
  },
  {
    "x": "girrêşê gedeyan (n)",
    "y": " çocuk bezi, etek bezi"
  },
  {
    "x": "girrêşê pitikan (n)",
    "y": " çocuk bezi, etek bezi"
  },
  {
    "x": "girrêşê qican (n)",
    "y": " çocuk bezi, etek bezi"
  },
  {
    "x": "girris (n)",
    "y": " b. girrêş (n)"
  },
  {
    "x": "girriş n)",
    "y": " b. girrêş (n)"
  },
  {
    "x": "girrîş (n)",
    "y": " b. girrêş (n)"
  },
  {
    "x": "girrîşî qicûn",
    "y": " b. girrêşê qican (n)"
  },
  {
    "x": "girrnaw (m)",
    "y": " b. girnawî (m)"
  },
  {
    "x": "girrnawene",
    "y": " b. girnawîyayene"
  },
  {
    "x": "girrnawîş (n)",
    "y": " b. girnawîyayîş (n)"
  },
  {
    "x": "girrnawû (m)",
    "y": " b. girnawî (m)"
  },
  {
    "x": "girs, -e",
    "y": " büyük"
  },
  {
    "x": "girs kerdene",
    "y": " büyütmek"
  },
  {
    "x": "girsag (n)",
    "y": " b. girşag (n)"
  },
  {
    "x": "girse (n)",
    "y": " kitle, kütle"
  },
  {
    "x": "girseyo hedef (n)",
    "y": " hedef kitle"
  },
  {
    "x": "girsey (m)",
    "y": " b. girsîye"
  },
  {
    "x": "girsê (m)",
    "y": " b. girsîye"
  },
  {
    "x": "girsîye (m)",
    "y": " büyüklük"
  },
  {
    "x": "girşag (n)",
    "y": " binanın çatısını taşıması için tavanda yatay olarak dizilen kalın direklerin üzerine dizilen kalas"
  },
  {
    "x": "girû (n)",
    "y": " b. giran (n)"
  },
  {
    "x": "girû bîyayene",
    "y": " b. giran bîyene"
  },
  {
    "x": "girû-girû",
    "y": " b. giran-giran"
  },
  {
    "x": "girûn, -i",
    "y": " b. giran, -e"
  },
  {
    "x": "girûn bîyayene",
    "y": " b. giran bîyene"
  },
  {
    "x": "girûn niweş bîyene",
    "y": " b. giran nêweş bîyene"
  },
  {
    "x": "girûn-girûn",
    "y": " b. giran-giran"
  },
  {
    "x": "girûne (n)",
    "y": " b. girane (n)"
  },
  {
    "x": "girûney (m)",
    "y": " b. giranîye (m), giranî (m)"
  },
  {
    "x": "girûnê (m)",
    "y": " b. giranîye (m), giranî (m)"
  },
  {
    "x": "girûng (m)",
    "y": " b. gilange (m)"
  },
  {
    "x": "girûngêk",
    "y": " b. gilangêk"
  },
  {
    "x": "girûni (n)",
    "y": " b. girane (n)"
  },
  {
    "x": "girwe (n)",
    "y": " b. gure (n)"
  },
  {
    "x": "girweyene",
    "y": " b. gureyayene"
  },
  {
    "x": "girweyîne",
    "y": " b. gureyayene"
  },
  {
    "x": "girweyayîş (n)",
    "y": " b. gureyayîş (n)"
  },
  {
    "x": "girweyîş (n)",
    "y": " b. gureyayîş (n)"
  },
  {
    "x": "girweynayîne",
    "y": " b. gurenayene"
  },
  {
    "x": "girweynayîş (n)",
    "y": " b. gurenayîş (n)"
  },
  {
    "x": "giryane (n)",
    "y": " b. girane (n)"
  },
  {
    "x": "giryaya (m)",
    "y": " b girêyaya (m), girêyayîye (m)"
  },
  {
    "x": "giryaye (n)",
    "y": " b. girêyaye (n)"
  },
  {
    "x": "giryayene",
    "y": " b. girêyayene"
  },
  {
    "x": "giryayîş (n)",
    "y": " b. girêyayîş (n)"
  },
  {
    "x": "girz, -e",
    "y": " b. girs, -e"
  },
  {
    "x": "girze (n)",
    "y": " demet"
  },
  {
    "x": "girzi (n)",
    "y": " b. girze (n)"
  },
  {
    "x": "gisik (n)",
    "y": " b. gezî (n)"
  },
  {
    "x": "gislavet (n)",
    "y": " bir çeşit lastik ayakkabı"
  },
  {
    "x": "gist (m)",
    "y": " b. engişte (m) "
  },
  {
    "x": "gişt (m)",
    "y": " b. engişte (m)"
  },
  {
    "x": "gişta derg",
    "y": " b. engişta derge (m), bêçika derge (m)"
  },
  {
    "x": "gişta dergi (m)",
    "y": " b. engişta derge (m), bêçika derge (m)"
  },
  {
    "x": "gişta şed (m)",
    "y": " b. engişta şehadetî (m), bêçika şadênî (m)"
  },
  {
    "x": "giştê engiştûnî (m)",
    "y": " b. engişta engiştaneyî (m)"
  },
  {
    "x": "giştê qele (m)",
    "y": " b. engişta qole (m)"
  },
  {
    "x": "giştane (n)",
    "y": " 1)yüksük\r\n~2)b. giştire (n)\r\n~3)b. engiştane (n)-3"
  },
  {
    "x": "giştaxopanayîş (n)",
    "y": " imza atma"
  },
  {
    "x": "giştaxwipanayîş (n)",
    "y": " b. giştaxopanayîş (n)"
  },
  {
    "x": "gişte (m)",
    "y": " b. engişte (m)"
  },
  {
    "x": "gişta dergi (m)",
    "y": " b. engişta derge (m) "
  },
  {
    "x": "gişta destî (m)",
    "y": " b. engişta destî (m) "
  },
  {
    "x": "gişta eşede (m)",
    "y": " b. engişta eşhede (m)"
  },
  {
    "x": "gişta eşhede (m)",
    "y": " b. engişta eşhede (m)"
  },
  {
    "x": "gişta girdi (m)",
    "y": " b. engişta girde"
  },
  {
    "x": "gişta giştanî (m)",
    "y": " b. engişta engiştaneyî (m)"
  },
  {
    "x": "gişta mîyanêne (m)",
    "y": " b. engişta mîyanêne (m)"
  },
  {
    "x": "gişta mîyanêni (m)",
    "y": " b. engişta mîyanêne (m)"
  },
  {
    "x": "gişta mîyanîne (m)",
    "y": " b. engişta mîyanêne (m)"
  },
  {
    "x": "gişta ningi (m)",
    "y": " b. engişta linge (m)"
  },
  {
    "x": "gişta ortî (m)",
    "y": " b. engişta ortî (m)"
  },
  {
    "x": "gişta pîle (m)",
    "y": " b. engişta pîle (m)"
  },
  {
    "x": "gişta pîlli (m)",
    "y": " b. engişta pîle (m)"
  },
  {
    "x": "gişta qole (m)",
    "y": " b. engişta qole (m)"
  },
  {
    "x": "gişta quele (m)",
    "y": " b. engişta qole (m)"
  },
  {
    "x": "gişta şadetî (m)",
    "y": " b. engişta şehadetî (m)"
  },
  {
    "x": "gişta şadi (m)",
    "y": " b. engişta şehadetî (m)"
  },
  {
    "x": "gişta şed (m)",
    "y": " b. engişta şehadetî (m)"
  },
  {
    "x": "gişta şede (m)",
    "y": " b. engişta şehadetî (m)"
  },
  {
    "x": "gişta wertî (m)",
    "y": " b. engişta ortî (m)"
  },
  {
    "x": "gişta xo pa nayene",
    "y": " imza atmak"
  },
  {
    "x": "gişta xwi pa nayene",
    "y": " b. gişta xo pa nayene"
  },
  {
    "x": "gişte pa nayene",
    "y": " imza atmak"
  },
  {
    "x": "gişte ser nayene",
    "y": " basmak"
  },
  {
    "x": "giştepanayîş (n)",
    "y": " imza atma"
  },
  {
    "x": "gişti (m)",
    "y": " b. engişte (m)"
  },
  {
    "x": "gişti ser nayene",
    "y": " b. gişte ser nayene"
  },
  {
    "x": "gişti pa nayene",
    "y": " b. gişte pa nayene"
  },
  {
    "x": "giştipanayîş (n)",
    "y": " b. giştepanayîş (n)"
  },
  {
    "x": "giştire (n)",
    "y": " yüzük"
  },
  {
    "x": "giştireyo sêmên (n)",
    "y": " gümüş yüzük"
  },
  {
    "x": "giştiri (n)",
    "y": " b. giştire (n)"
  },
  {
    "x": "giştirî (n)",
    "y": " b. giştire (n)"
  },
  {
    "x": "giştone (n)",
    "y": " b. giştane (n)"
  },
  {
    "x": "giştûne (n)",
    "y": " b. giştane (n)"
  },
  {
    "x": "giştûni (n)",
    "y": " b. giştane (n)"
  },
  {
    "x": "git, -i",
    "y": " b. xînt, -e"
  },
  {
    "x": "givêje (m)",
    "y": " alıç"
  },
  {
    "x": "givisnayene",
    "y": " b. givişnayene"
  },
  {
    "x": "givisnayêne",
    "y": " b. givişnayene"
  },
  {
    "x": "givisnayîs (n)",
    "y": " b. givişnayîş (n)"
  },
  {
    "x": "givisnayîş (n)",
    "y": " b. givişnayîş (n)"
  },
  {
    "x": "givişnayene",
    "y": " sıkmak (elle sıkmak)"
  },
  {
    "x": "givişnayîş (n)",
    "y": " sıkma (elle sıkma)"
  },
  {
    "x": "giwend (n)",
    "y": " b. guwend (n)"
  },
  {
    "x": "giwûn (n)",
    "y": " b. guwan (n)"
  },
  {
    "x": "giz, -e",
    "y": " b. guz, -e"
  },
  {
    "x": "gizeke (m)",
    "y": " b. gozeke (m)"
  },
  {
    "x": "gizêr (n)",
    "y": " havuç"
  },
  {
    "x": "gizgalik (n)",
    "y": " b. gizgarik (n)"
  },
  {
    "x": "gizgarik (n)",
    "y": " kıvılcım"
  },
  {
    "x": "gizik (n)",
    "y": " b. gijik (n)"
  },
  {
    "x": "gizin, -e",
    "y": " b. guzin, -e"
  },
  {
    "x": "gizinê (m)",
    "y": " b. guzinî (m), guzinîye (m)"
  },
  {
    "x": "gizinî (m)",
    "y": " b. guzinî (m), guzinîye (m)"
  },
  {
    "x": "gizuz (n)",
    "y": " güve"
  },
  {
    "x": "gizuzî piro dayene",
    "y": " güve yemek (güvenin yemesi)"
  },
  {
    "x": "gizuzî werdene",
    "y": " güve yemek (güvenin yemesi)"
  },
  {
    "x": "gizûn, -e",
    "y": " b. guzin, -e"
  },
  {
    "x": "gizûnê (m)",
    "y": " b. guzinî (m), guzinîye (m)"
  },
  {
    "x": "gizûnî (m)",
    "y": " b. guzinî (m), guzinîye (m)"
  },
  {
    "x": "gî (n)",
    "y": " bok, dışkı; kaka (çocuk dilinde)"
  },
  {
    "x": "gî estarî (n)",
    "y": " b. gîyê estareyî (n)"
  },
  {
    "x": "gîy astereyî (n)",
    "y": " b. gîyê estareyî (n)"
  },
  {
    "x": "gîy dar (n)",
    "y": " b. gîyê dare (n)"
  },
  {
    "x": "gîy dari (n)",
    "y": " b. gîyê dare (n)"
  },
  {
    "x": "gîy estarî (n)",
    "y": " b. gîyê estareyî (n)"
  },
  {
    "x": "gîy vamêri (n)",
    "y": " b. gîyê vamêre (n)"
  },
  {
    "x": "gîy vomêri (n)",
    "y": " b. gîyê vamêre (n)"
  },
  {
    "x": "gîyê asinî (n)",
    "y": " cüruf, demir boku"
  },
  {
    "x": "gîyê dare (n)",
    "y": " ağaç sakızı, reçine"
  },
  {
    "x": "gîyê estareyî (n)",
    "y": " ateşböceği, yıldız böceği"
  },
  {
    "x": "gîyê paxirî (n)",
    "y": " cüruf, demir boku"
  },
  {
    "x": "gîyê vamêre (n)",
    "y": " ağaç sakızı (badem ağacı salgısı), reçine, zamk"
  },
  {
    "x": "gîec",
    "y": " b. gêj, -e"
  },
  {
    "x": "gîec bîyayîne",
    "y": " b. gêj bîyene "
  },
  {
    "x": "gîec kerdene",
    "y": " b. gêj kerdene"
  },
  {
    "x": "gîecê (m)",
    "y": " b. gêjîye (m)"
  },
  {
    "x": "gîlyas (m)",
    "y": " b. gilyaze (m)"
  },
  {
    "x": "Gîne (m)",
    "y": " Gine"
  },
  {
    "x": "Gîneya Bîssaoyî (m)",
    "y": " Gine-Bissao"
  },
  {
    "x": "Gîneya Ekvatore (m)",
    "y": " Ekvator Ginesi"
  },
  {
    "x": "gîrane (n)",
    "y": " b. girane (n)"
  },
  {
    "x": "gîrayene",
    "y": " b. girêyayene"
  },
  {
    "x": "gîrayîs (n)",
    "y": " b. girêyayîş (n)"
  },
  {
    "x": "gîrenaya (m)",
    "y": " b. girênaya (m), girênayîye (m)"
  },
  {
    "x": "gîrenaye (n)",
    "y": " b. girênaye (n)"
  },
  {
    "x": "gîrenayene",
    "y": " b. girênayene"
  },
  {
    "x": "gîrenayîs (n)",
    "y": " b. girênayîş (n)"
  },
  {
    "x": "gîrenîyayene",
    "y": " b. girênîyayene"
  },
  {
    "x": "gîrenîyayîs (n)",
    "y": " b. girênîyayîş (n)"
  },
  {
    "x": "gîrênaya (m)",
    "y": " b. girênaya (m), girênayîye (m)"
  },
  {
    "x": "gîrênaye (n)",
    "y": " b. girênaye (n)"
  },
  {
    "x": "gîrênayene",
    "y": " b. girênayene"
  },
  {
    "x": "gîrênayîş (n)",
    "y": " b. girênayîş (n)"
  },
  {
    "x": "gîrênîyayene",
    "y": " b. girênîyayene"
  },
  {
    "x": "gîrênîyayîş (n)",
    "y": " b. girênîyayîş (n)"
  },
  {
    "x": "gîrîyaya (m)",
    "y": " b girêyaya (m), girêyayîye (m)"
  },
  {
    "x": "gîrîyaye (n)",
    "y": " b. girêyaye (n)"
  },
  {
    "x": "gîrîyayene-I",
    "y": " 1)alınmak\r\n~2)tutulmak (el, ayak vb. için)"
  },
  {
    "x": "gîrîyayene-II",
    "y": " b. girêyayene"
  },
  {
    "x": "gîrîyayîş (n)",
    "y": " b. girêyayîş (n)"
  },
  {
    "x": "gîrnayene",
    "y": " b. girênayene"
  },
  {
    "x": "gîrnayîş (n)",
    "y": " b. girênayîş (n)"
  },
  {
    "x": "gîsk (n)",
    "y": " bir-iki yaşındaki keçi yavrusu, çepiç, çebiç"
  },
  {
    "x": "gîşe (n)",
    "y": " gişe"
  },
  {
    "x": "gîşt (m)",
    "y": " b. engişte (m)"
  },
  {
    "x": "gîşta mîyonen (m)",
    "y": " b. engişta mîyanêne (m)"
  },
  {
    "x": "gîştey milêvi (m)",
    "y": " b. engişta malêbe (m)"
  },
  {
    "x": "gîştana (m)",
    "y": " b. giştane (n)"
  },
  {
    "x": "gîştane (n)",
    "y": " b. giştane (n), giştire (n)"
  },
  {
    "x": "gîşte (m)",
    "y": " b. engişte (m) "
  },
  {
    "x": "gîşti (m)",
    "y": " b. engişte (m)"
  },
  {
    "x": "gîştidekutiş (n)",
    "y": " b. engiştedekutiş (n)"
  },
  {
    "x": "gîştone (n)",
    "y": " b. giştane (n), giştire (n)"
  },
  {
    "x": "gîştrane (n)",
    "y": " b. giştire (n)"
  },
  {
    "x": "gîştûni (n)",
    "y": " b. giştane (n), giştire (n)"
  },
  {
    "x": "gîtarcen, -e",
    "y": " gitarist"
  },
  {
    "x": "gîtare (m)",
    "y": " gitar"
  },
  {
    "x": "gîtarîst, -e",
    "y": " gitarist"
  },
  {
    "x": "gîyare (n)",
    "y": " b. gerre (n)"
  },
  {
    "x": "gîyarr (n)",
    "y": " b. gerr (n)"
  },
  {
    "x": "gîyarr vetene",
    "y": " b. gerr vetene"
  },
  {
    "x": "gîyarrin, -e",
    "y": " b. gerrin, -e"
  },
  {
    "x": "gîyrîyayene",
    "y": " b. gîrîyayene"
  },
  {
    "x": "gîzme (n)",
    "y": " b. cizme (n)"
  },
  {
    "x": "gladyator, -e",
    "y": " gladyatör"
  },
  {
    "x": "gladyatorî (m)",
    "y": " gladyatörlük"
  },
  {
    "x": "gladyatorîye (m)",
    "y": " gladyatörlük"
  },
  {
    "x": "go-I",
    "y": " b. ke-I"
  },
  {
    "x": "go-II",
    "y": " fiilin gelecek zamanında kullanılan bir partikel (b. do-II)"
  },
  {
    "x": "go-III (n)",
    "y": " b. gan (n)"
  },
  {
    "x": "go-IV (m)",
    "y": " b. gewe (m)"
  },
  {
    "x": "goaz (m)",
    "y": " b. goze (m)"
  },
  {
    "x": "goc (n)",
    "y": " ağız (ilk süt)"
  },
  {
    "x": "gocag (m)",
    "y": " b. gojage (m)"
  },
  {
    "x": "gocage (m)",
    "y": " b. gojage (m)"
  },
  {
    "x": "gocak (m)",
    "y": " b. gojage (m)"
  },
  {
    "x": "gocake (m)",
    "y": " b. gojage (m)"
  },
  {
    "x": "gocege (m)",
    "y": " b. gojage (m)"
  },
  {
    "x": "gocî kerdene",
    "y": " b. gojî kerdene "
  },
  {
    "x": "gocîkerdiş (n)",
    "y": " b. gojîkerdiş (n)"
  },
  {
    "x": "gocux (n)",
    "y": " b. qocix (n)"
  },
  {
    "x": "goç (n)",
    "y": " b. koç (n)"
  },
  {
    "x": "goç kerdene",
    "y": " b. koç kerdene"
  },
  {
    "x": "goçe (m)",
    "y": " b. koç (n)"
  },
  {
    "x": "goçe kerdene",
    "y": " b. koç kerdene"
  },
  {
    "x": "goçerî",
    "y": " b. koçer, -e; koçerî, -ye"
  },
  {
    "x": "goçerîye (m)",
    "y": " b. koçerîye (m)"
  },
  {
    "x": "goçên (m)",
    "y": " b. goçîne (m) "
  },
  {
    "x": "goçênî (m)",
    "y": " b. goçîne (m) "
  },
  {
    "x": "goçîn (m)",
    "y": " b. goçîne (m)"
  },
  {
    "x": "goçîne (m)",
    "y": " çuvaldız"
  },
  {
    "x": "goçîni (m)",
    "y": " b. goçîne (m)"
  },
  {
    "x": "goçkar, -e",
    "y": " b. koşkar, -e"
  },
  {
    "x": "goçkarê (m)",
    "y": " b. koşkarîye (m), koşkarî (m)"
  },
  {
    "x": "goçkarênî (m)",
    "y": " b. koşkarênî (m)"
  },
  {
    "x": "goçkarî (m)",
    "y": " b. koşkarîye (m), koşkarî (m)"
  },
  {
    "x": "goçkerdiş (n)",
    "y": " b. koçkerdiş (n)"
  },
  {
    "x": "Godthab (n)",
    "y": " Godthab"
  },
  {
    "x": "goge (m)",
    "y": " tomak, top (ağaç veya taştan yapılmış oyun topu)"
  },
  {
    "x": "gogebaxan (n)",
    "y": " ayçiçeği, gündöndü, günebakan (b. vila rojî (m), verroj (n)"
  },
  {
    "x": "goger (n)",
    "y": " tekerlek, teker"
  },
  {
    "x": "gogero cagir (n)",
    "y": " yedek teker, stepne"
  },
  {
    "x": "gogercîni (m)",
    "y": " b. gogerçîne (m)"
  },
  {
    "x": "gogerçîne (m)",
    "y": " güvercin (b. borane (m)"
  },
  {
    "x": "gogerçîni (m)",
    "y": " b. gogerçîne (m)"
  },
  {
    "x": "gogi (m)",
    "y": " b. goge (m)"
  },
  {
    "x": "Gogsî",
    "y": " Karayazı "
  },
  {
    "x": "gohan (n)",
    "y": " b. guhan (n)"
  },
  {
    "x": "goj (n)",
    "y": " b. goc (n)"
  },
  {
    "x": "gojage (m)",
    "y": " 1)düğme (giysi için)\r\n~2)düğme (makine vb. için)"
  },
  {
    "x": "gojage/make/bişkoje/qumça/sedefe/zirare devistene",
    "y": " düğme iliklemek"
  },
  {
    "x": "gojage/make/bişkoje/qumça/sedefe/zirare girewtene",
    "y": " düğme iliklemek"
  },
  {
    "x": "gojage/make/bişkoje/qumça/sedefe/zirare qefilnayene",
    "y": " düğme iliklemek"
  },
  {
    "x": "gojage/make/bişkoje/qumça/sedefe/zirare tavistene",
    "y": " düğme iliklemek"
  },
  {
    "x": "gojagi (m)",
    "y": " b. gojage (m)"
  },
  {
    "x": "gojege (m)",
    "y": " b. gojage (m)"
  },
  {
    "x": "gojek (m)",
    "y": " b. gojage (m)"
  },
  {
    "x": "gojî kerdene",
    "y": " yeni doğan yavruyu (insan ve hayvan) süt emmeye alıştırmak"
  },
  {
    "x": "gojîkerdiş (n)",
    "y": " yeni doğan yavruyu (insan ve hayvan) süt emmeye alıştırma"
  },
  {
    "x": "gok, -e",
    "y": " b. golik, -e"
  },
  {
    "x": "gol-I (n)",
    "y": " 1)gölcük, gölet, gölek\r\n~2)b. gole-I (m)"
  },
  {
    "x": "gol-II (m)",
    "y": " b. gole-II (m)"
  },
  {
    "x": "gol-III (m)",
    "y": " b. gûlale (m)"
  },
  {
    "x": "gol-IV (n)",
    "y": " gol "
  },
  {
    "x": "golaleki (m)",
    "y": " b. gûlaleke (m)"
  },
  {
    "x": "golali (m)",
    "y": " b. gûlale (m)"
  },
  {
    "x": "golala gî (m)",
    "y": " b. gûlala gîyî (m)"
  },
  {
    "x": "golala gîy (m)",
    "y": " b. gûlala gîyî (m)"
  },
  {
    "x": "golala gîyî (m)",
    "y": " b. gûlala gîyî (m)"
  },
  {
    "x": "golala rêxe",
    "y": " b. gûlala rêxe"
  },
  {
    "x": "golaley gîy (m)",
    "y": " b. gûlala gîyî (m)"
  },
  {
    "x": "golbaxey muron (n)",
    "y": " b. gulbaxeyê murayan (n)"
  },
  {
    "x": "gole-I (m)",
    "y": " göl"
  },
  {
    "x": "gola soline (m)",
    "y": " tuzlu göl"
  },
  {
    "x": "gola şorre (m)",
    "y": " tuzlu göl"
  },
  {
    "x": "gole-II (m)",
    "y": " kiler"
  },
  {
    "x": "golek (n)",
    "y": " gölcük, gölet, gölek"
  },
  {
    "x": "goleki (m)",
    "y": " b. golek (n)"
  },
  {
    "x": "golf (n)",
    "y": " golf"
  },
  {
    "x": "goli (m)",
    "y": " b. gole-I (m)"
  },
  {
    "x": "golig, -e",
    "y": " b. golîg, -e"
  },
  {
    "x": "golik, -e-I",
    "y": " buzağı (inek yavrusu)"
  },
  {
    "x": "golik ardene",
    "y": " buzağılamak"
  },
  {
    "x": "golik eştene",
    "y": " (inek için) düşük yapmak"
  },
  {
    "x": "golik-II (n)",
    "y": " b. golek (n)"
  },
  {
    "x": "goliki (m)",
    "y": " b. golike (m)"
  },
  {
    "x": "golikwan, -e",
    "y": " buzağı çobanı"
  },
  {
    "x": "golikwanî (m)",
    "y": " buzağı çobanlığı"
  },
  {
    "x": "golikwanîye (m)",
    "y": " buzağı çobanlığı"
  },
  {
    "x": "golikwûn",
    "y": " b. golikwan, -e"
  },
  {
    "x": "golikwûnê (m)",
    "y": " b. golikwanî (m)"
  },
  {
    "x": "golim (n)",
    "y": " kasık"
  },
  {
    "x": "golîg, -e",
    "y": " yük hayvanı (at, katır, eşek) "
  },
  {
    "x": "golmaste (m)",
    "y": " b. gormaste (m)"
  },
  {
    "x": "golme (n)",
    "y": " b. golim (n)"
  },
  {
    "x": "golmoştî (m)",
    "y": " b. gormaste (m)"
  },
  {
    "x": "gom (m)",
    "y": " b. game (m)"
  },
  {
    "x": "gome (n)",
    "y": " kom (içinde genellikle tarım ve hayvancılıkla uğraşanların barındığı dağ başlarındaki veya kırlardaki tek ev)"
  },
  {
    "x": "gomêş, -i",
    "y": " b. gamêş, -e"
  },
  {
    "x": "gomi (m)",
    "y": " b. game (m)"
  },
  {
    "x": "gomîyeşqiro (n)",
    "y": " b. gamêşqiran (n)"
  },
  {
    "x": "gomîyeşqiron (n)",
    "y": " b. gamêşqiran (n)"
  },
  {
    "x": "gomleg (n)",
    "y": " gömlek, mintan"
  },
  {
    "x": "gomlege (m)",
    "y": " b. gomleg (n)"
  },
  {
    "x": "gomlek (n)",
    "y": " b. gomleg (n)"
  },
  {
    "x": "gomyeş, -i",
    "y": " b. gamêş, -e"
  },
  {
    "x": "gomyeşqiron (n)",
    "y": " b. gamêşqiran (n)"
  },
  {
    "x": "gomyêş, -i",
    "y": " b. gamêş, -e"
  },
  {
    "x": "gon-I (n)",
    "y": " b. gan (n)"
  },
  {
    "x": "gon fîda",
    "y": " b. gan fîda"
  },
  {
    "x": "gon ver di bîyayîne",
    "y": " b. ganî ver de bîyene"
  },
  {
    "x": "gon-II (n)",
    "y": " b. guwan (n)"
  },
  {
    "x": "gon-III",
    "y": " b. ganî-II"
  },
  {
    "x": "gon werdene",
    "y": " (inek ve düve için) çiftleşmek "
  },
  {
    "x": "gondil (n)",
    "y": " b. guwandil (n)"
  },
  {
    "x": "gondîl (n)",
    "y": " b. guwandil (n)"
  },
  {
    "x": "gone",
    "y": " b. ganî-II"
  },
  {
    "x": "gonewer, -e",
    "y": " b. gonîwer, -e"
  },
  {
    "x": "gonfîda, -ye",
    "y": " b. ganfîda, -ye"
  },
  {
    "x": "gonga (m)",
    "y": " b. kakile (m)"
  },
  {
    "x": "Gongêr (n)",
    "y": " b. Gangêr (n)"
  },
  {
    "x": "gongiro (n)",
    "y": " b. gangiran, -e"
  },
  {
    "x": "gongiron, -i",
    "y": " b. gangiran, -e"
  },
  {
    "x": "gongironey (m)",
    "y": " b. gangiranîye (m)"
  },
  {
    "x": "gonî-I",
    "y": " b. ganî, -ye-I"
  },
  {
    "x": "gonî-II (m)",
    "y": " kan"
  },
  {
    "x": "gonî bîyene",
    "y": " kanamak"
  },
  {
    "x": "gonî ci kewtene",
    "y": " kanlanmak, kan yürümek"
  },
  {
    "x": "gonî dayene",
    "y": " kan vermek"
  },
  {
    "x": "gonî dekewtene",
    "y": " kanlanmak, kan yürümek"
  },
  {
    "x": "gonî deqesîyayene",
    "y": " kan oturmak, kan yürümek"
  },
  {
    "x": "gonî kerdene",
    "y": " kanatmak"
  },
  {
    "x": "gonî qelibnayene",
    "y": " kan kusmak"
  },
  {
    "x": "gonî qesîyayene",
    "y": " kan oturmak, kan yürümek"
  },
  {
    "x": "gonî raverdayene",
    "y": " kanı dışarı akıtmak (hayvan için)"
  },
  {
    "x": "gonî raviretene",
    "y": " kan kusmak"
  },
  {
    "x": "gonî ravirêtene",
    "y": " b. gonî raviretene"
  },
  {
    "x": "gonî ravirtene",
    "y": " b. gonî raviretene"
  },
  {
    "x": "gonî top bîyene",
    "y": " kan birikmek"
  },
  {
    "x": "gonî verdayene",
    "y": " kanı dışarı akıtmak (hayvan için)"
  },
  {
    "x": "gonî verişnayene",
    "y": " b. gonî verişnayene"
  },
  {
    "x": "gonî verişnayene",
    "y": " kan kusmak"
  },
  {
    "x": "gonî veritine",
    "y": " b. gonî viritene"
  },
  {
    "x": "gonî veradayene",
    "y": " kanı dışarı akıtmak (hayvan için)"
  },
  {
    "x": "gonî vireznayini",
    "y": " b. gonî verişnayene"
  },
  {
    "x": "gonî viritene",
    "y": " kan kusmak"
  },
  {
    "x": "gonî viritini",
    "y": " b. gonî viritene"
  },
  {
    "x": "gonîya ... veradayene",
    "y": " kanını aldırmak (hayvan için)"
  },
  {
    "x": "... de gonî top bîyene",
    "y": " kan oturmak, kan yürümek"
  },
  {
    "x": "... ra gonî ameyene",
    "y": " kanamak"
  },
  {
    "x": "... ra gonî ardene",
    "y": " kanatmak"
  },
  {
    "x": "gonî-III",
    "y": " 1)b. ganî-II"
  },
  {
    "x": "gonî-IV (n)",
    "y": " b. guwan (n)"
  },
  {
    "x": "gonîameyîş (n)",
    "y": " kanama"
  },
  {
    "x": "gonîbîyayîş (n)",
    "y": " kanama"
  },
  {
    "x": "gonîgerm, -e",
    "y": " cana yakın"
  },
  {
    "x": "gonîşîrin, -e",
    "y": " cana yakın"
  },
  {
    "x": "gonîwer, -e",
    "y": " kan emici"
  },
  {
    "x": "gonsivik, -i",
    "y": " b. gansivik, -e"
  },
  {
    "x": "gonsivikey (m)",
    "y": " b. gansivikîye (m)"
  },
  {
    "x": "gonsivikê (m)",
    "y": " b. gansivikî (m)"
  },
  {
    "x": "gonşenik, -i",
    "y": " b. ganşenik, -e"
  },
  {
    "x": "gonşenikey (m)",
    "y": " b. ganşenikîye (m)"
  },
  {
    "x": "gonşenikê (m)",
    "y": " b. ganşenikî (m)"
  },
  {
    "x": "gonûgoştin, -i",
    "y": " b. ganûgoştin, -e"
  },
  {
    "x": "gonverdibîyayîş (n)",
    "y": " b. ganîverdebîyayîş (n)"
  },
  {
    "x": "gonya (m)",
    "y": " b. gonye (m)"
  },
  {
    "x": "gonye (m)",
    "y": " gönye"
  },
  {
    "x": "gopale (m)",
    "y": " baston"
  },
  {
    "x": "gopole (m)",
    "y": " b. gopale (m)"
  },
  {
    "x": "gopoli (m)",
    "y": " b. gopale (m)"
  },
  {
    "x": "gor-I (n)",
    "y": " mezar"
  },
  {
    "x": "gor-II (n)",
    "y": " b. guhere (m)"
  },
  {
    "x": "gor bestene",
    "y": " b. guhere bestene"
  },
  {
    "x": "goranê ...",
    "y": " b. goreyê ..."
  },
  {
    "x": "gorbe (n)",
    "y": " b. gore-I (n)"
  },
  {
    "x": "gorcale (m)",
    "y": " b. gorjale (m)"
  },
  {
    "x": "gorcali (m)",
    "y": " b. gorjale (m)"
  },
  {
    "x": "gord (n)",
    "y": " kılıç"
  },
  {
    "x": "gorde (n)",
    "y": " b. gord (n)"
  },
  {
    "x": "gore-I (n)",
    "y": " çorap"
  },
  {
    "x": "gore riştene",
    "y": " çorap örmek"
  },
  {
    "x": "gore-II (m)",
    "y": " b. gor-I (n)"
  },
  {
    "x": "gore-III",
    "y": " göre, bakımından (-e göre)"
  },
  {
    "x": "gore bi",
    "y": " göre (-e göre)"
  },
  {
    "x": "goreyê ...",
    "y": " göre (-e göre), bakımınan (-e göre)"
  },
  {
    "x": "goreyê aqilê xo",
    "y": " aklınca, aklı sıra"
  },
  {
    "x": "goreyê mi",
    "y": " bana göre, bence"
  },
  {
    "x": "goreyê ... ra",
    "y": " göre (-e göre), bakımından (-e göre)"
  },
  {
    "x": "goreyê mi ra",
    "y": " bana göre, bence"
  },
  {
    "x": "goreyê qafê xo",
    "y": " b. goreyê qafika xo"
  },
  {
    "x": "goreyê qafika xo",
    "y": " aklınca, aklı sıra\r\n~Goreyê qafika xo pê ma kay kena. (Aklınca bizimle dalga geçiyor.)"
  },
  {
    "x": "goreyê texmînî",
    "y": " 1)tahmine göre\r\n~2)tahminen "
  },
  {
    "x": "mi gore",
    "y": " b. goreyê mi"
  },
  {
    "x": "mi ra gore",
    "y": " bana göre, bence"
  },
  {
    "x": "gore-IV (m)",
    "y": " b. guhere (m)"
  },
  {
    "x": "gore bestene",
    "y": " b. guhere bestene"
  },
  {
    "x": "goreba ...",
    "y": " göre, bakımından (-e göre)"
  },
  {
    "x": "goreba mi",
    "y": " bana göre, bence"
  },
  {
    "x": "gorebê ...",
    "y": " b. goreba ..."
  },
  {
    "x": "goreroş, -e",
    "y": " çorapçı"
  },
  {
    "x": "goreviraştox, -e",
    "y": " çorapçı"
  },
  {
    "x": "gorge (m)",
    "y": " b. goge (m)"
  },
  {
    "x": "gorgeçîne (m)",
    "y": " b. gogerçîne (m)"
  },
  {
    "x": "gorgoçîni (m)",
    "y": " b. gogerçîne (m)"
  },
  {
    "x": "gorime (m)",
    "y": " görümce "
  },
  {
    "x": "gorimi (m)",
    "y": " b. gorime (m)"
  },
  {
    "x": "goristan (n)",
    "y": " mezarlık"
  },
  {
    "x": "goristanwan, -e",
    "y": " mezarcı (mezarlığa bakan)"
  },
  {
    "x": "goriston (n)",
    "y": " b. goristan (n)"
  },
  {
    "x": "goristûn (n)",
    "y": " b. goristan (n)"
  },
  {
    "x": "gorîl, -e",
    "y": " goril"
  },
  {
    "x": "gorjale (m)",
    "y": " pıtrak"
  },
  {
    "x": "gorjali (m)",
    "y": " b. gorjale (m)"
  },
  {
    "x": "gorken, -e",
    "y": " mezarcı (mezar kazan)"
  },
  {
    "x": "gormari (m)",
    "y": " b. koremare (m)"
  },
  {
    "x": "gormaste (m)",
    "y": " kusulan kan "
  },
  {
    "x": "gormaste teber kerdene",
    "y": " kan kusmak\r\n~O gormaste teber keno. (O kan kusuyor.)"
  },
  {
    "x": "gormastî (m)",
    "y": " b. gormaste (m)"
  },
  {
    "x": "gormore (n)",
    "y": " kargaşa"
  },
  {
    "x": "gormorre (n)",
    "y": " b. gormore (n)"
  },
  {
    "x": "gorn (n)",
    "y": " b. gor-I (n)"
  },
  {
    "x": "gornistan (n)",
    "y": " b. goristan (n)"
  },
  {
    "x": "gorristan (n)",
    "y": " b. goristan (n)"
  },
  {
    "x": "gorr-I (n)",
    "y": " b. gor-I (n)"
  },
  {
    "x": "gorr-II (n)",
    "y": " b. guhere (m)"
  },
  {
    "x": "gorr bestene",
    "y": " b. guhere bestene"
  },
  {
    "x": "gorre (m)",
    "y": " b. gor-I (n)"
  },
  {
    "x": "gorristan (n)",
    "y": " b. goristan (n)"
  },
  {
    "x": "gorristanwan, -e",
    "y": " b. goristanwan, -e "
  },
  {
    "x": "gorriston (n)",
    "y": " b. goristan (n)"
  },
  {
    "x": "gorristûn (n)",
    "y": " b. goristan (n)"
  },
  {
    "x": "gorrn (n)",
    "y": " b. gor (n)"
  },
  {
    "x": "gorrnistan (n)",
    "y": " b. goristan (n)"
  },
  {
    "x": "gort (n)",
    "y": " ormanda veya bayırda oluşturulan tarla"
  },
  {
    "x": "gort wekerdene",
    "y": " ormanda veya bayırda tarla oluşturmak"
  },
  {
    "x": "gos (n)",
    "y": " b. goş (n)"
  },
  {
    "x": "gos dayene",
    "y": " b. goş dayene"
  },
  {
    "x": "gos ... dayene",
    "y": " b. goş ... dayene"
  },
  {
    "x": "gos ... dayîş",
    "y": " b. goş ... dayîş"
  },
  {
    "x": "gos ... nayene",
    "y": " b. goş ... nayene"
  },
  {
    "x": "gos re ... ser nayene",
    "y": " b. goş re ... ser nayene"
  },
  {
    "x": "gos ... ser nayene",
    "y": " b. goş ... ser nayene"
  },
  {
    "x": "gosê xo bel kerdene",
    "y": " b. goşê xo bel kerdene"
  },
  {
    "x": "gosare (n)",
    "y": " b. goşare (n)"
  },
  {
    "x": "gosdar, -e",
    "y": " b. goşdar, -e"
  },
  {
    "x": "gosdarênî (m)",
    "y": " b. goşdarênî (m)"
  },
  {
    "x": "gosdarî kerdene",
    "y": " b. goşdarî kerdene"
  },
  {
    "x": "gosdarîkerdiş (n)",
    "y": " b. goşdarîkerdiş (n)"
  },
  {
    "x": "gosdarîye (m)",
    "y": " b. goşdarîye (m)"
  },
  {
    "x": "gosdayîş (n)",
    "y": " b. goşdayîş (n)"
  },
  {
    "x": "gose (n)",
    "y": " b. gûşe-II (n)"
  },
  {
    "x": "gosebel, -e",
    "y": " b. goşbel, -e"
  },
  {
    "x": "gosedarênî (m)",
    "y": " b. goşdarîye (m)"
  },
  {
    "x": "gosedarîye (m)",
    "y": " b. goşdarîye (m)"
  },
  {
    "x": "gosedarîye kerdene",
    "y": " b. goşdarîye kerdene"
  },
  {
    "x": "gosedarîyekerdiş (n)",
    "y": " b. goşdarîyekerdiş (n)"
  },
  {
    "x": "gosevît, -e",
    "y": " b. goşvît, -e"
  },
  {
    "x": "gosexul, -e",
    "y": " b. goşxul, -e"
  },
  {
    "x": "gosgiran, -e",
    "y": " b. goşgiran, -e"
  },
  {
    "x": "gosirmete (m)",
    "y": " skandal"
  },
  {
    "x": "gosirmeti (m)",
    "y": " b. gosirmete (m)"
  },
  {
    "x": "goskar, -e",
    "y": " b. koşkar, -e"
  },
  {
    "x": "goskarênî (m)",
    "y": " b. koşkarênî (m)"
  },
  {
    "x": "goskarîye (m)",
    "y": " b. koşkarîye (m), koşkarî (m)"
  },
  {
    "x": "gosre (n)",
    "y": " bileğitaşı"
  },
  {
    "x": "gosre kerdene",
    "y": " bilemek"
  },
  {
    "x": "gost (n)",
    "y": " b. goşt (n)"
  },
  {
    "x": "gostarmayêne (m)",
    "y": " b. gostarmênî (m)"
  },
  {
    "x": "gostarme (n)",
    "y": " afişe"
  },
  {
    "x": "gostarme kerdene",
    "y": " afişe etmek"
  },
  {
    "x": "gostarmekerdiş (n)",
    "y": " afişe etme"
  },
  {
    "x": "gostarmênî (m)",
    "y": " skandal"
  },
  {
    "x": "gostarmîye (m)",
    "y": " afişe"
  },
  {
    "x": "gostarmîyenî (m)",
    "y": " b. gostarmênî (m)"
  },
  {
    "x": "goş (n)",
    "y": " 1)kulak\r\n~2)yabanın dişlerinden her biri"
  },
  {
    "x": "goş a vistene",
    "y": " çaktırmak, sezdirmek, sezindirmek"
  },
  {
    "x": "goş dayene",
    "y": " dinlemek"
  },
  {
    "x": "goş ... dayene",
    "y": " dinlemek"
  },
  {
    "x": "goş ... dayîş",
    "y": " dinleme"
  },
  {
    "x": "goş deginayene",
    "y": " kabakulak olmak"
  },
  {
    "x": "goş ... nayene",
    "y": " dinlemek, -e uymak, itaat etmek"
  },
  {
    "x": "goş ... nayîş (n)",
    "y": " dinleme, itaat etme"
  },
  {
    "x": "goş re ser nayene",
    "y": " dinlemek"
  },
  {
    "x": "goş re ... ser nayene",
    "y": " -e uymak"
  },
  {
    "x": "goş ro ser nayene",
    "y": " b. goş re ser nayene"
  },
  {
    "x": "goş ser nayene",
    "y": " dinlemek"
  },
  {
    "x": "goş ... ser nayene",
    "y": " -e uymak"
  },
  {
    "x": "goş ser o bîyene",
    "y": " dinlemek"
  },
  {
    "x": "goşa xo ... daritene",
    "y": " b. goşê xo ... daritene"
  },
  {
    "x": "goşa xo ... daritiş",
    "y": " b. goşê xo ... daritiş"
  },
  {
    "x": "goşa xwi ... nayene",
    "y": " b. goşê xo ... nayene"
  },
  {
    "x": "goşa xwi ... nayîş",
    "y": " b. goşê xo ... nayîş"
  },
  {
    "x": "goşan ro dayene",
    "y": " çaktırmak, sezdirmek, sezindirmek"
  },
  {
    "x": "goşê xo bel kerdene",
    "y": " (hayvan için) (kendi) kulaklarını dikleştirmek"
  },
  {
    "x": "goşê xo ... daritene",
    "y": " dinlemek"
  },
  {
    "x": "goşê xo ... daritiş",
    "y": " dinleme"
  },
  {
    "x": "goşê xo dayene ser",
    "y": " dinlemek"
  },
  {
    "x": "goşê xo mûç kerdene",
    "y": " (hayvan için) (kendi) kulaklarını dikleştirmek"
  },
  {
    "x": "goşê xo ... nayene",
    "y": " dinlemek"
  },
  {
    "x": "goşê xo ... nayîş",
    "y": " dinleme"
  },
  {
    "x": "goşê xo ser nayene",
    "y": " dinlemek"
  },
  {
    "x": "goşê xo vît kerdene",
    "y": " (hayvan için) (kendi) kulaklarını dikleştirme"
  },
  {
    "x": "goşê xwi ser nayene",
    "y": " b. goşê xo ser nayene"
  },
  {
    "x": "goşî bel kerdene",
    "y": " 1)dikkatle dinlemek\r\n~2) (hayvan için)kulakları dikleştirmek"
  },
  {
    "x": "goşî çeqayene",
    "y": " kulakları çınlamak\r\n~Goşê mi çeqenî/reqenî/zingenî/vingenî. (Kulaklarım çınlıyor.)"
  },
  {
    "x": "goşî mûç kerdene",
    "y": " 1)dikkatle dinlemek\r\n~2) (hayvan için) kulakları dikleştirmek"
  },
  {
    "x": "goşî reqayene",
    "y": " kulakları çınlamak\r\n~Wa goşê ey/aye bireqê. (Kulakları çınlasın.)"
  },
  {
    "x": "goşî ronayene",
    "y": " kulak kabartmak"
  },
  {
    "x": "goşî vingayene",
    "y": " kulakları çınlamak\r\n~Goşê mi vingenî. (Kulaklarım çınlıyor.)"
  },
  {
    "x": "goşî vît kerdene",
    "y": " 1)dikkatle dinlemek\r\n~2) (hayvan için)kulakları dikleştirmek"
  },
  {
    "x": "goşî zingayene",
    "y": " kulakları çınlamak"
  },
  {
    "x": "goşare (n)",
    "y": " küpe"
  },
  {
    "x": "goşbel, -e",
    "y": " (hayvan)dik kulaklı"
  },
  {
    "x": "goşdar, -e",
    "y": " dinleyici"
  },
  {
    "x": "goşdarey (m)",
    "y": " b. goşdarîye (m)"
  },
  {
    "x": "goşdarey kerdene",
    "y": " b. goşdarîye kerdene"
  },
  {
    "x": "goşdareykerdiş (n)",
    "y": " b. goşdarîyekerdiş (n) "
  },
  {
    "x": "goşdarê (m)",
    "y": " b. goşdarî (m)"
  },
  {
    "x": "goşdarênî (m)",
    "y": " dinleyicilik"
  },
  {
    "x": "goşdaritene",
    "y": " dinlemek, itaat etmek"
  },
  {
    "x": "... goşdaritene",
    "y": " -e uymak"
  },
  {
    "x": "goşdaritiş (n)",
    "y": " dinleme, itaat etme"
  },
  {
    "x": "goşdaritox, -e",
    "y": " dinleyici"
  },
  {
    "x": "goşdarî (m)",
    "y": " 1)dinleme\r\n~2)dinleyicilik"
  },
  {
    "x": "goşdarî kerdene",
    "y": " dinlemek"
  },
  {
    "x": "goşdarîkerdiş (n)",
    "y": " dinleme"
  },
  {
    "x": "goşdarîye (m)",
    "y": " dinleyicilik"
  },
  {
    "x": "goşdarîye kerdene",
    "y": " dinlemek"
  },
  {
    "x": "goşdarîyekerdiş (n)",
    "y": " dinleme"
  },
  {
    "x": "goşdayîş (n)",
    "y": " dinleme"
  },
  {
    "x": "goşdayox, -e",
    "y": " dinleyici"
  },
  {
    "x": "goşe-I (n)",
    "y": " köşe"
  },
  {
    "x": "goşe-II (n)",
    "y": " b. gûşe-I (n)"
  },
  {
    "x": "goşe-III (m)",
    "y": " b. goş (n) "
  },
  {
    "x": "goşere (n)",
    "y": " b. goşare (n)"
  },
  {
    "x": "goşêxodayîşser (n)",
    "y": " dinleme"
  },
  {
    "x": "goşêxosernayîş (n)",
    "y": " dinleme"
  },
  {
    "x": "goşêxwisernayîş (n)",
    "y": " b. goşêxosernayîş (n)"
  },
  {
    "x": "goşgiran, -e",
    "y": " işitme engelli, az işiten, sağır"
  },
  {
    "x": "goşgiran bîyene",
    "y": " 1)az işitmek, ağır duymak, ağır işitmek\r\n~2)sağır olmak"
  },
  {
    "x": "goşgiranbîyayîş (n)",
    "y": " az işitme, sağır olma"
  },
  {
    "x": "goşgiraney (m)",
    "y": " b. goşgiranîye (m)"
  },
  {
    "x": "goşgiranê (m)",
    "y": " b. goşgiranîye (m)"
  },
  {
    "x": "goşgiranî (m)",
    "y": " az işitme durumu, sağırlık"
  },
  {
    "x": "goşgiranîye (m)",
    "y": " az işitme durumu, sağırlık"
  },
  {
    "x": "goşik (n)",
    "y": " yabanın dişlerinden her biri"
  },
  {
    "x": "goşine (m)",
    "y": " tırmık "
  },
  {
    "x": "goşkar, -e",
    "y": " b. koşkar, -e"
  },
  {
    "x": "goşkarey (m)",
    "y": " b. koşkarî (m), koşkarîye (m)"
  },
  {
    "x": "goşkarê (m)",
    "y": " b. koşkarî (m), koşkarîye (m)"
  },
  {
    "x": "goşkarênî (m)",
    "y": " b. koşkarênî (m)"
  },
  {
    "x": "goşkarî (m)",
    "y": " b. koşkarî (m), koşkarîye (m)"
  },
  {
    "x": "goşkarîye (m)",
    "y": " b. koşkarî (m), koşkarîye (m)"
  },
  {
    "x": "goşkirr, -e",
    "y": " kısa kulaklı (daha çok hayvan için)"
  },
  {
    "x": "goşkirri (m)",
    "y": " b. goşkirre (m)"
  },
  {
    "x": "goşle (n)",
    "y": " gûşle (n)"
  },
  {
    "x": "goşmasê (m)",
    "y": " b. goşmasî"
  },
  {
    "x": "goşmasî (n)",
    "y": " midye"
  },
  {
    "x": "goşmasîroş, -e",
    "y": " midyeci (midye satan)"
  },
  {
    "x": "goşresernayîş (n)",
    "y": " dinleme"
  },
  {
    "x": "goşrosernayîş (n)",
    "y": " b. goşresernayîş (n)"
  },
  {
    "x": "goşrosernayox,-e",
    "y": " b. goşdayox, -e"
  },
  {
    "x": "goşsernayîş (n)",
    "y": " dinleme"
  },
  {
    "x": "goşserobîyayîş (n)",
    "y": " dinleme"
  },
  {
    "x": "goşt (n)",
    "y": " et"
  },
  {
    "x": "goşt girewtene",
    "y": " şişmanlamak"
  },
  {
    "x": "goştê didanî (n)",
    "y": " dişeti "
  },
  {
    "x": "goştar",
    "y": " b. goşdar, -e"
  },
  {
    "x": "goştardox, -e",
    "y": " b. goşdaritox, -e"
  },
  {
    "x": "goştarey (m)",
    "y": " b. goşdarîye (m)"
  },
  {
    "x": "goştarey kerdene",
    "y": " b. goşdarîye kerdene"
  },
  {
    "x": "goştareykerdiş (n)",
    "y": " b. goşdarîyekerdiş (n)"
  },
  {
    "x": "goştaritene",
    "y": " b. goşdaritene"
  },
  {
    "x": "... goştaritene",
    "y": " b. ... goşdaritene"
  },
  {
    "x": "goştaritiş (n)",
    "y": " b. goşdaritiş (n)"
  },
  {
    "x": "goştarî (m)",
    "y": " b. goşdarî (m)"
  },
  {
    "x": "goştarî kerdene",
    "y": " b. goşdarî kerdene"
  },
  {
    "x": "goştarîkerdiş (n)",
    "y": " b. goşdarîkerdiş (n)"
  },
  {
    "x": "goşterî (m)",
    "y": " b. goşdarî (m)"
  },
  {
    "x": "goştin, -e",
    "y": " etli, şişman"
  },
  {
    "x": "goştroş, -e",
    "y": " kasap"
  },
  {
    "x": "goşvît, -e",
    "y": " (hayvan) dik kulaklı"
  },
  {
    "x": "goşxul, -e",
    "y": " kısa kulaklı (daha çok hayvan için)"
  },
  {
    "x": "gota silî (m)",
    "y": " b. guda silî (m)"
  },
  {
    "x": "govde (n)",
    "y": " beden, gövde"
  },
  {
    "x": "gove (m)",
    "y": " b. guvike (m)"
  },
  {
    "x": "govend (m)",
    "y": " b. govende (m)"
  },
  {
    "x": "govende (m)",
    "y": " halay"
  },
  {
    "x": "govendi (m)",
    "y": " b. govende (m)"
  },
  {
    "x": "goviki (m)",
    "y": " b. guvike (m)"
  },
  {
    "x": "gow (m)",
    "y": " b. gewe (m)"
  },
  {
    "x": "gowr, -e",
    "y": " b. gewr, -e"
  },
  {
    "x": "gowşek, -i",
    "y": " b. gewşek, -e"
  },
  {
    "x": "goya (zr)",
    "y": " güya"
  },
  {
    "x": "goyend (n)",
    "y": " b. guwend (n)"
  },
  {
    "x": "goyn (m)",
    "y": " b. gonî-II (m)"
  },
  {
    "x": "goynayene",
    "y": " övmek, ballandırmak"
  },
  {
    "x": "goynayîş (n)",
    "y": " 1)övgü\r\n~2)övme, ballandırma"
  },
  {
    "x": "goynî (m)",
    "y": " b. gonî-II (m)"
  },
  {
    "x": "goynwer, -e",
    "y": " b. gonîwer, -e"
  },
  {
    "x": "goz (m)",
    "y": " b. goze (m)"
  },
  {
    "x": "gozaçix, -e",
    "y": " açıkgöz, cingöz"
  },
  {
    "x": "gozaçixey (m)",
    "y": " b. gozaçixîye (m)"
  },
  {
    "x": "gozaçixîye (m)",
    "y": " açıkgözlülük, açıkgözlük, cingözlük"
  },
  {
    "x": "gozage (m)",
    "y": " b. gojage (m)"
  },
  {
    "x": "gozagi (m)",
    "y": " b. gojage (m)"
  },
  {
    "x": "goze (m)",
    "y": " ceviz"
  },
  {
    "x": "goza Hindîstanî (m)",
    "y": " hindistancevizi "
  },
  {
    "x": "gozege-I (m)",
    "y": " b. gozeke (m)"
  },
  {
    "x": "gozege-II (m)",
    "y": " b. gojage (m)"
  },
  {
    "x": "gozeke (m)",
    "y": " aşık (aşık kemiği), bilek çıkıntısı (kemiği)"
  },
  {
    "x": "gozeka destî (m)",
    "y": " bilek çıkıntısı (kemiği) "
  },
  {
    "x": "gozeka linge (m)",
    "y": " aşık (aşık kemiği), bilek çıkıntısı (kemiği)"
  },
  {
    "x": "gozeki (m)",
    "y": " b. gozeke (m) "
  },
  {
    "x": "gozer, -e",
    "y": " alaca (siyah-beyaz renkli)"
  },
  {
    "x": "gozerin, -e",
    "y": " alaca (siyah-beyaz renkli)"
  },
  {
    "x": "gozêre (m)",
    "y": " ceviz ağacı"
  },
  {
    "x": "gozêri (m)",
    "y": " b. gozêre (m)"
  },
  {
    "x": "gozgire",
    "y": " b. girêkor (n)"
  },
  {
    "x": "gozi (m)",
    "y": " b. goze (m)"
  },
  {
    "x": "gozligî (zh)",
    "y": " gözlük"
  },
  {
    "x": "gozlikî",
    "y": " b. gozligî (zh)"
  },
  {
    "x": "gozvece (m)",
    "y": " b. gozveje (m)"
  },
  {
    "x": "gozveje (m)",
    "y": " ceviz içini kabuğundan çıkarmak için kullanılan bir bıçak çeşidi"
  },
  {
    "x": "GPRS (General Packet Radio Service)",
    "y": " GPRS "
  },
  {
    "x": "GPRS modem (n) (TE)",
    "y": " GPRS modem "
  },
  {
    "x": "grafîk (n)",
    "y": " grafik"
  },
  {
    "x": "grafîkkar, -e",
    "y": " grafikçi, grafiker"
  },
  {
    "x": "grafometre (n)",
    "y": " grafometre"
  },
  {
    "x": "gram (n)",
    "y": " gram"
  },
  {
    "x": "gramafon (n)",
    "y": " b. gramofon (n)"
  },
  {
    "x": "gramafone (m)",
    "y": " b. gramofon (n)"
  },
  {
    "x": "gramaj (n)",
    "y": " gramaj"
  },
  {
    "x": "gramofon (n)",
    "y": " gramofon"
  },
  {
    "x": "gramer (n)",
    "y": " dilbilgisi, gramer"
  },
  {
    "x": "pispora gramerî (m)",
    "y": " dilbilgisi uzmanı, gramer uzmanı"
  },
  {
    "x": "pisporê gramerî (n)",
    "y": " dilbilgisi uzmanı, gramer uzmanı"
  },
  {
    "x": "grev (n)",
    "y": " grev "
  },
  {
    "x": "greyder (n)",
    "y": " b. greydere (m)"
  },
  {
    "x": "greydere (m)",
    "y": " greyder"
  },
  {
    "x": "greyfrute (m)",
    "y": " altıntop, greyfrut, kızmemesi"
  },
  {
    "x": "greyfrutêre (m)",
    "y": " altıntop ağacı, greyfrut ağacı, kızmemesi ağacı"
  },
  {
    "x": "grê (n)",
    "y": " b. girê (n)"
  },
  {
    "x": "grê dayene",
    "y": " b. girê dayene"
  },
  {
    "x": "girêdayîş (n)",
    "y": " b. girêdayîş (n)"
  },
  {
    "x": "grîbe (m)",
    "y": " b. grîp (n)"
  },
  {
    "x": "grîp (n)",
    "y": " grip"
  },
  {
    "x": "grîzû (n)",
    "y": " grizu"
  },
  {
    "x": "grîzûmetre (n)",
    "y": " grizuölçer, grizumetre "
  },
  {
    "x": "grîzûpeym (n)",
    "y": " grizuölçer, grizumetre"
  },
  {
    "x": "Gronlanda (m)",
    "y": " Grönland "
  },
  {
    "x": "Groznî",
    "y": " Grozni"
  },
  {
    "x": "grûbe (m)",
    "y": " grup"
  },
  {
    "x": "grûba fişarî (m)",
    "y": " baskı grubu"
  },
  {
    "x": "grûba hedefe (m)",
    "y": " hedef grup"
  },
  {
    "x": "grûba mudaxeleyî (m)",
    "y": " müdahale grubu"
  },
  {
    "x": "grûba xebate (m)",
    "y": " çalışma grubu"
  },
  {
    "x": "grûbê feydevînayoxî (zh)",
    "y": " fayda sağlayan gruplar"
  },
  {
    "x": "GSM (Global System for Mobile Communications)",
    "y": " GSM"
  },
  {
    "x": "GSM modem (n) (TE)",
    "y": " GSM modem"
  },
  {
    "x": "gual (m)",
    "y": " b. gole-I (m)"
  },
  {
    "x": "guar (n)",
    "y": " b. gor-I (n) "
  },
  {
    "x": "guaş (n)",
    "y": " b. goş (n) "
  },
  {
    "x": "Guatemala (m)",
    "y": " Guatemala (devlet)"
  },
  {
    "x": "Guatemala City (m)",
    "y": " Guatemala"
  },
  {
    "x": "guatr (n)",
    "y": " guatr"
  },
  {
    "x": "guaz (m)",
    "y": " b. goze (m)"
  },
  {
    "x": "guazvec (m)",
    "y": " b. gozveje (m)"
  },
  {
    "x": "guazyer (m)",
    "y": " b. gozêre (m)"
  },
  {
    "x": "gubre (n)",
    "y": " gübre (kimyasal gübre) "
  },
  {
    "x": "gubre dekerdene",
    "y": " gübrelemek"
  },
  {
    "x": "gubre kerdene",
    "y": " gübrelemek"
  },
  {
    "x": "gubredekerdiş (n)",
    "y": " gübreleme"
  },
  {
    "x": "gubrekerda (m)",
    "y": " gübreli"
  },
  {
    "x": "gubrekerde (n)",
    "y": " gübreli"
  },
  {
    "x": "gubrekerdiş (n)",
    "y": " gübreleme"
  },
  {
    "x": "gubrekerdîye (m)",
    "y": " gübreli"
  },
  {
    "x": "gubreyin, -e",
    "y": " gübreli"
  },
  {
    "x": "gucak (m)",
    "y": " b. gojage (m)"
  },
  {
    "x": "gucig (m)",
    "y": " b. gucige (m)"
  },
  {
    "x": "gucige (m)",
    "y": " şubat "
  },
  {
    "x": "gucigi (m)",
    "y": " b. gucige (m)"
  },
  {
    "x": "gucîge (m)",
    "y": " b. gucige (m)"
  },
  {
    "x": "guçe (m)",
    "y": " abla"
  },
  {
    "x": "guçê",
    "y": " abla (hitap hali)"
  },
  {
    "x": "guçey (m)",
    "y": " b. guçêyî (m)"
  },
  {
    "x": "guçê (m)",
    "y": " b. guçêyî (m)"
  },
  {
    "x": "guçêyey (m)",
    "y": " b. guçêyî (m)"
  },
  {
    "x": "guçêyî (m)",
    "y": " ablalık"
  },
  {
    "x": "guçi (m)",
    "y": " b. guçe (m)"
  },
  {
    "x": "guçik (n)",
    "y": " b. kunc (n)"
  },
  {
    "x": "guçikê hêgayî (n)",
    "y": " tarla veya arazinin başka tarla veya araziler arasında kalan küçük ve dar bölümü"
  },
  {
    "x": "gud (m)",
    "y": " b. gude (m)"
  },
  {
    "x": "gudê layî nak (m)",
    "y": " b. guda layê nake (m)"
  },
  {
    "x": "gude (m)",
    "y": " 1)topak\r\n~2)top (oyunda kullanılan top) \r\n~3)köfte (ince yarmadan veya kıymadan yapılan) \r\n~4)balyoz"
  },
  {
    "x": "guda layê nake (m)",
    "y": " kuka, dantel ipliği yumağı"
  },
  {
    "x": "guda mîrî (m)",
    "y": " hamur topağı"
  },
  {
    "x": "guda silî (m)",
    "y": " büyükbaş hayvanın bir defada yaptığı dışkı topağı, tezek"
  },
  {
    "x": "gudê silî (m)",
    "y": " b. guda silî (m), "
  },
  {
    "x": "gude piro dayene",
    "y": " balyozlamak"
  },
  {
    "x": "gudelinge (m)",
    "y": " b. gudlinge (m)"
  },
  {
    "x": "gudepirodayîş (n)",
    "y": " balyozlama"
  },
  {
    "x": "gudevewre (m)",
    "y": " b. gudvewre (m)"
  },
  {
    "x": "gudevore (m)",
    "y": " b. gudvewre (m)"
  },
  {
    "x": "gudi (m)",
    "y": " b. gude (m)"
  },
  {
    "x": "gudik (m)",
    "y": " b. gudike (m)"
  },
  {
    "x": "gudike (m)",
    "y": " köfte (ince yarmadan veya kıymadan yapılan)"
  },
  {
    "x": "gudika zuray (m)",
    "y": " b. gudika zuraye (m)"
  },
  {
    "x": "gudika zuraye (m)",
    "y": " delikli köfte (ince yarmadan yapılan)"
  },
  {
    "x": "gudiki (m)",
    "y": " b. gudike (m)"
  },
  {
    "x": "gudiki (n)",
    "y": " b. gudikin (n)"
  },
  {
    "x": "gudikin, -e",
    "y": " yuvarlak, toparlak"
  },
  {
    "x": "gudliki (n)",
    "y": " b. gudikin, -e"
  },
  {
    "x": "gudlikin, -i",
    "y": " b. gudikin, -e"
  },
  {
    "x": "gudlinge (m)",
    "y": " futbol"
  },
  {
    "x": "gudvare (m)",
    "y": " b. gudvewre (m)"
  },
  {
    "x": "gudvewre (m)",
    "y": " kar topu"
  },
  {
    "x": "gudvewri (m)",
    "y": " b. gudvewre (m)"
  },
  {
    "x": "gudvore (m)",
    "y": " b. gudvewre (m)"
  },
  {
    "x": "guec (n)",
    "y": " b. goc (n)"
  },
  {
    "x": "guecak (m)",
    "y": " b. gojage (m)"
  },
  {
    "x": "gueçin (m)",
    "y": " b. goşine (m)"
  },
  {
    "x": "gueçîn (m)",
    "y": " b. goçîne (m)"
  },
  {
    "x": "guejak (m)",
    "y": " b. gojage (m)"
  },
  {
    "x": "guel-I (n)",
    "y": " b. gol (n), gole-I (m)"
  },
  {
    "x": "guel-II (m)",
    "y": " b. gole-II (m)"
  },
  {
    "x": "guela sualin (m)",
    "y": " b. gola soline (m)"
  },
  {
    "x": "guela suelini (m)",
    "y": " b. gola soline (m)"
  },
  {
    "x": "guela şuarr (m)",
    "y": " b. gola şorre"
  },
  {
    "x": "guele (m)",
    "y": " b. gole-I (m)"
  },
  {
    "x": "guelek (n)",
    "y": " b. golek (n)"
  },
  {
    "x": "guelik",
    "y": " b. golik, -e"
  },
  {
    "x": "guelik ardene",
    "y": " b. golik ardene"
  },
  {
    "x": "guelik eştene",
    "y": " b. golik eştene"
  },
  {
    "x": "gueme (n)",
    "y": " b. gome (n)"
  },
  {
    "x": "guemi (n)",
    "y": " b. gome (n)"
  },
  {
    "x": "guepal (m)",
    "y": " b. gopale (m)"
  },
  {
    "x": "guer-I (n)",
    "y": " b. gor (n)"
  },
  {
    "x": "guer-II (n)",
    "y": " b. guhere (m)"
  },
  {
    "x": "guere",
    "y": " b. gore-III"
  },
  {
    "x": "gueri",
    "y": " b. gore-III"
  },
  {
    "x": "guerim (m)",
    "y": " b. gorime (m)"
  },
  {
    "x": "gueristûn (n)",
    "y": " b. goristan (n)"
  },
  {
    "x": "guermal (m)",
    "y": " b. koremare (m)"
  },
  {
    "x": "guerr -I (n)",
    "y": " b. gor (n)"
  },
  {
    "x": "guerr-II (n)",
    "y": " b. guhere (m)"
  },
  {
    "x": "guert (n)",
    "y": " b. gort (n)"
  },
  {
    "x": "guert wekerden",
    "y": " b. gort wekerdene"
  },
  {
    "x": "gueş (n)",
    "y": " b. goş (n) "
  },
  {
    "x": "gueşare (n)",
    "y": " b. goşare (n)"
  },
  {
    "x": "gueşari (n)",
    "y": " b. goşare (n)"
  },
  {
    "x": "gueşdar",
    "y": " b. goşdar, -e"
  },
  {
    "x": "gueşdarê (m)",
    "y": " b. goşdarîye (m)"
  },
  {
    "x": "gueşdarî (m)",
    "y": " b. goşdarî (m)"
  },
  {
    "x": "gueşdayox, -e",
    "y": " goşdayox, -e"
  },
  {
    "x": "gueşe-I (n)",
    "y": " b. goşe-I (n)"
  },
  {
    "x": "gueşe-II (n)",
    "y": " b. gûşe-I (n)"
  },
  {
    "x": "gueşgirûn (n)",
    "y": " b. goşgiran (n)"
  },
  {
    "x": "gueşin (m)",
    "y": " b. goşine (m)"
  },
  {
    "x": "gueşt (n)",
    "y": " b. goşt (n) "
  },
  {
    "x": "gueştar",
    "y": " b. goşdar, -e"
  },
  {
    "x": "gueştarê (m)",
    "y": " b. goşdarî (m)"
  },
  {
    "x": "gueştarî (m)",
    "y": " b. goşdarî (m)"
  },
  {
    "x": "gueştarî kerdene",
    "y": " b. goşdarî kerdene"
  },
  {
    "x": "gueştarîkerdiş (n)",
    "y": " b. goşdarîkerdiş (n)"
  },
  {
    "x": "gueştin (n)",
    "y": " b. goştin"
  },
  {
    "x": "guevde (n)",
    "y": " b. govde (n)"
  },
  {
    "x": "guevend (m)",
    "y": " b. govende (m)"
  },
  {
    "x": "gueyn (m)",
    "y": " b. gonî-II (m)"
  },
  {
    "x": "gueyn dekotene",
    "y": " b. gonî dekewtene"
  },
  {
    "x": "gueyn qelebnayene",
    "y": " b. gonî qelibnayene"
  },
  {
    "x": "gueynwer",
    "y": " b. gonîwer, -e"
  },
  {
    "x": "guez (m)",
    "y": " b. goze (m)"
  },
  {
    "x": "guezek (m)",
    "y": " b. gozeke (m)"
  },
  {
    "x": "guezvec (m)",
    "y": " b. gozveje (m)"
  },
  {
    "x": "guên (m)",
    "y": " b. gonî-II (m)"
  },
  {
    "x": "gufte (n)",
    "y": " güfte"
  },
  {
    "x": "guftenus, -e",
    "y": " güfteci"
  },
  {
    "x": "gugerçîne (m)",
    "y": " güvercin (b. borane (m)"
  },
  {
    "x": "gugird (n)",
    "y": " b. kukirt (n)"
  },
  {
    "x": "guhan (n)",
    "y": " meme (küçükbaş ve büyükbaş hayvanlar için)"
  },
  {
    "x": "guher (n)",
    "y": " b. guhere (m)"
  },
  {
    "x": "guher bestene",
    "y": " b. guhere bestene"
  },
  {
    "x": "guher dayene",
    "y": " b. guhere dayene"
  },
  {
    "x": "guhere (m)",
    "y": " hayvanların geceleyin topluca bekletildiği açık arazi"
  },
  {
    "x": "guhere bestene",
    "y": " hayvanları geceleyin topluca açık arazide bekletmek"
  },
  {
    "x": "guhere dayene",
    "y": " hayvanları geceleyin topluca açık arazide bekletmek"
  },
  {
    "x": "guherr (n)",
    "y": " b. guhere (m)"
  },
  {
    "x": "guhîn (n)",
    "y": " b. guhan (n)"
  },
  {
    "x": "guicak (m)",
    "y": " b. gojage (m)"
  },
  {
    "x": "guid (m)",
    "y": " b. gude (m)"
  },
  {
    "x": "guidikin",
    "y": " b. gudikin, -e"
  },
  {
    "x": "guin (m)",
    "y": " b. gonî-II (m)"
  },
  {
    "x": "guinc (m)",
    "y": " b. gunce (m)"
  },
  {
    "x": "guire (n)",
    "y": " b. gure (n)"
  },
  {
    "x": "guiz",
    "y": " b. kuz, -e"
  },
  {
    "x": "guiz bîyayene",
    "y": " b. kuz bîyene "
  },
  {
    "x": "guizê (m)",
    "y": " b. kuzîye (m)"
  },
  {
    "x": "guîn (m)",
    "y": " b. gonî-II (m)"
  },
  {
    "x": "guînc (m)",
    "y": " b. gunce (m)"
  },
  {
    "x": "gujak (m)",
    "y": " b. gojage (m)"
  },
  {
    "x": "gujbin, -e",
    "y": " altına işeyen"
  },
  {
    "x": "gujeleke (m)",
    "y": " b. gijoleke (m)"
  },
  {
    "x": "gujgeleke (m)",
    "y": " b. gijoleke (m)"
  },
  {
    "x": "gujige (m)",
    "y": " b. gucige (m)"
  },
  {
    "x": "gujîge (m)",
    "y": " b. gucige (m)"
  },
  {
    "x": "gujîye (m)",
    "y": " b. gucige (m)"
  },
  {
    "x": "guk, -e",
    "y": " buzağı (inek yavrusu)"
  },
  {
    "x": "guk ardene",
    "y": " b. golik ardene"
  },
  {
    "x": "guk estene",
    "y": " b. golik eştene"
  },
  {
    "x": "gul (m)",
    "y": " b. gule (m)"
  },
  {
    "x": "gulalikê hakî (m)",
    "y": " b. gilolika hakî (m)"
  },
  {
    "x": "gulane (m)",
    "y": " mayıs "
  },
  {
    "x": "gulani (m)",
    "y": " b. gulane (m)"
  },
  {
    "x": "gulase (m)",
    "y": " b. gulaş (n)"
  },
  {
    "x": "gulase guretene",
    "y": " b. gulaş girewtene"
  },
  {
    "x": "gulaş (n)",
    "y": " güreş"
  },
  {
    "x": "gulaş girewtene",
    "y": " güreşmek, güreş tutmak"
  },
  {
    "x": "gulaş kerdene",
    "y": " güreşmek, güreş tutmak "
  },
  {
    "x": "gulaş kerdeni",
    "y": " b. gulaş kerdene "
  },
  {
    "x": "gulaşe (m)",
    "y": " b. gulaş (n)"
  },
  {
    "x": "gulaşe guretene",
    "y": " b. gulaş girewtene"
  },
  {
    "x": "gulaşgir, -e",
    "y": " güreşçi"
  },
  {
    "x": "gulbang (n)",
    "y": " 1)yankı\r\n~2)gülbank"
  },
  {
    "x": "gulbaxe (n)",
    "y": " işlenmemiş 12 adet boncuk dizisinden oluşan bağlam"
  },
  {
    "x": "gulbaxey muran (n)",
    "y": " b. gulbaxeyê murayan (n)"
  },
  {
    "x": "gulbaxey murûn (n)",
    "y": " b. gulbaxeyê murayan (n)"
  },
  {
    "x": "gulbaxeyê murayan (n)",
    "y": " işlenmemiş 12 adet boncuk dizisinden oluşan bağlam"
  },
  {
    "x": "gulç (n)",
    "y": " kazık"
  },
  {
    "x": "gulçan (n)",
    "y": " 1)kazık\r\n~2)ucu sivriltilmiş çubuk"
  },
  {
    "x": "gulçanî (zh)",
    "y": " kazık biçiminde ucu sivriltilmiş çubuklarla oynanan bir çocuk oyunu"
  },
  {
    "x": "gulçik (m)",
    "y": " b. gurçike (m)"
  },
  {
    "x": "gulçî (zh)",
    "y": " kazık biçiminde ucu sivriltilmiş çubuklarla oynanan bir çocuk oyunu"
  },
  {
    "x": "gule-I (m)",
    "y": " boğaz"
  },
  {
    "x": "gule-II (m)",
    "y": " gül"
  },
  {
    "x": "gula zuray (m)",
    "y": " b. gula zuraye (m)"
  },
  {
    "x": "gula ververojî (m)",
    "y": " ayçiçeği, gündöndü, günebakan"
  },
  {
    "x": "gula zuraye (m)",
    "y": " yabangülü"
  },
  {
    "x": "gule-III (n)",
    "y": " 1)mermi, gülle, kurşun\r\n~2)bilye"
  },
  {
    "x": "Gule (m)",
    "y": " inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "guleamayîş (n)",
    "y": " b. guledeginayîş (n)"
  },
  {
    "x": "guledeginayîş (n)",
    "y": " bademcik şişmesi"
  },
  {
    "x": "guledest (n)",
    "y": " 1)(atletizmde atılan elde yer alabilecek büyüklükteki gülle) gülle\r\n~2)gülle atma sporu"
  },
  {
    "x": "guledest eştene",
    "y": " (elde yer alabilecek büyüklükte gülle atmak) gülle atmak"
  },
  {
    "x": "guleherçe (m)",
    "y": " b. gulheşe (m)"
  },
  {
    "x": "guler (n)",
    "y": " b. gilover, -e"
  },
  {
    "x": "gulere (n)",
    "y": " 1)(atletizmde atılan elde yer alabilecek büyüklükteki gülle) gülle\r\n~2)gülle atma sporu "
  },
  {
    "x": "gulere estene",
    "y": " b. gulere eştene"
  },
  {
    "x": "gulere eştene",
    "y": " (elde yer alabilecek büyüklükte gülle atmak) gülle atmak"
  },
  {
    "x": "gulerin (n)",
    "y": " b. gilorin, -e"
  },
  {
    "x": "gulerkin (n)",
    "y": " b. gilorin, -e"
  },
  {
    "x": "gulêr-I (m)",
    "y": " b. gulêre (m)"
  },
  {
    "x": "gulêr-II (n)",
    "y": " b. gilover (n)"
  },
  {
    "x": "gulêre (m)",
    "y": " gül ağacı "
  },
  {
    "x": "gulêra zuray (m)",
    "y": " b. gulêra zuraye (m)"
  },
  {
    "x": "gulêra zuraye (m)",
    "y": " kuşburnu ağacı, yabangülü ağacı"
  },
  {
    "x": "gulêri (m)",
    "y": " b. gulêre (m)"
  },
  {
    "x": "gulha (m)",
    "y": " badem çağlası"
  },
  {
    "x": "gulheşe (m)",
    "y": " şakayık"
  },
  {
    "x": "guli-I (m)",
    "y": " b. gule-II (m)"
  },
  {
    "x": "guli-II (n)",
    "y": " b. gule-III (n)"
  },
  {
    "x": "Guli (m)",
    "y": " b. Gule (m)"
  },
  {
    "x": "gulik-I (m)",
    "y": " b. gillî (m)-1"
  },
  {
    "x": "gulik-II (m)",
    "y": " b. gulike (m)"
  },
  {
    "x": "gulik-III (n)",
    "y": " 1)püskül\r\n~2)(bazı hayvanların boyunlarının iki yanından sarkan deri uzantısı) küpe"
  },
  {
    "x": "gulikê fese (n)",
    "y": " fes püskülü"
  },
  {
    "x": "gulike (m)",
    "y": " çirişotu"
  },
  {
    "x": "gulikê hakî (m)",
    "y": " b. giloka hakî (m)"
  },
  {
    "x": "gulikin,-e",
    "y": " püsküllü"
  },
  {
    "x": "gulisk (n)",
    "y": " b. bilusk (n)"
  },
  {
    "x": "gulî (m)",
    "y": " b. gillî (m)"
  },
  {
    "x": "gulîlik (m)",
    "y": " b. gulîlike (m)"
  },
  {
    "x": "gulîlike (m)",
    "y": " göz yuvarlağı"
  },
  {
    "x": "gulkelem (n)",
    "y": " karnabahar"
  },
  {
    "x": "gulle (n)",
    "y": " b. gule-III (n)"
  },
  {
    "x": "gulmi (m)",
    "y": " b. qilme (m)"
  },
  {
    "x": "Gulo (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "gulolikê hakî (m)",
    "y": " b. gilolika hakî (m)"
  },
  {
    "x": "guloni (m)",
    "y": " b. gulane (m)"
  },
  {
    "x": "gulover, -e",
    "y": " b. gilover, -e"
  },
  {
    "x": "gulpîye (m)",
    "y": " b. çarpıntı"
  },
  {
    "x": "gulûn (m)",
    "y": " b. gulane (m)"
  },
  {
    "x": "gulvang (n)",
    "y": " b. gulbang (n)"
  },
  {
    "x": "gulyer (m)",
    "y": " b. gulêre (m)"
  },
  {
    "x": "gum (m)",
    "y": " b. gume (m)"
  },
  {
    "x": "guma (n)",
    "y": " b. guman (n)"
  },
  {
    "x": "guma kerdene",
    "y": " b. guman kerdene"
  },
  {
    "x": "guman (n)",
    "y": " şüphe, kuşku"
  },
  {
    "x": "guman kerdene",
    "y": " şüphelenmek, kuşkulanmak"
  },
  {
    "x": "gumankerda (m)",
    "y": " şüpheli, kuşkulu"
  },
  {
    "x": "gumankerde (n)",
    "y": " şüpheli, kuşkulu"
  },
  {
    "x": "gumankerdî, -ye",
    "y": " şüpheli, kuşkulu"
  },
  {
    "x": "gumayene",
    "y": " çağlamak"
  },
  {
    "x": "gumayîş-I (n)",
    "y": " çağlama"
  },
  {
    "x": "gumayîş-II (n)",
    "y": " b. gimayîş (n)"
  },
  {
    "x": "gume (m)",
    "y": " 1)avurt, yanak\r\n~2)yudum"
  },
  {
    "x": "gumgumike (m)",
    "y": " bir cins semender"
  },
  {
    "x": "gumgumiki (m)",
    "y": " b. gumgumike (m)"
  },
  {
    "x": "gumgumok, -e",
    "y": " b. gumgumike (m)"
  },
  {
    "x": "gumi (m)",
    "y": " b. gume (m)"
  },
  {
    "x": "gumik (m)",
    "y": " b. gume (m)"
  },
  {
    "x": "gumra",
    "y": " çok fazla, diz boyu (çok miktarda)"
  },
  {
    "x": "gumrig (n)",
    "y": " b. gumrik (n)"
  },
  {
    "x": "gumrik (n)",
    "y": " gümrük"
  },
  {
    "x": "gumrikkar, -e",
    "y": " gümrükçü"
  },
  {
    "x": "gumrug (n)",
    "y": " b. gumrik (n)"
  },
  {
    "x": "gun-I (n)",
    "y": " haya (erkeklik bezi), taşak, erbezi, husye"
  },
  {
    "x": "gun-II",
    "y": " b. ganî-II"
  },
  {
    "x": "guna (m)",
    "y": " b. gune-I (n)"
  },
  {
    "x": "guna ... pê ameyene",
    "y": " b. guneyê ... pê ameyene"
  },
  {
    "x": "guna ... pêameyîş",
    "y": " b. guneyê ... pêameyîş"
  },
  {
    "x": "gunah (m)",
    "y": " b. gune-I (n)"
  },
  {
    "x": "gunayene",
    "y": " b. ginayene"
  },
  {
    "x": "gunayîş (n)",
    "y": " b. ginayîş (n)"
  },
  {
    "x": "gunce (m)",
    "y": " alıcın bir çeşidi"
  },
  {
    "x": "guncî (n)",
    "y": " b. kuncî (n)"
  },
  {
    "x": "gund-I (m)",
    "y": " tekerlek, teker"
  },
  {
    "x": "gund-II (n)",
    "y": " b. guwend (n)"
  },
  {
    "x": "gundor bîyayîne",
    "y": " b. gindir bîyene"
  },
  {
    "x": "gundor dayîne",
    "y": " b. gindir dayene"
  },
  {
    "x": "gundor deyîş",
    "y": " b. gindir dîyayîne"
  },
  {
    "x": "gundorîyayene",
    "y": " b. gindirîyayene"
  },
  {
    "x": "gundornayîne",
    "y": " b. gindirnayene"
  },
  {
    "x": "gune-I (n)",
    "y": " günah, suç"
  },
  {
    "x": "guneyê ... bi ... ameyene ...",
    "y": " acımak\r\n~Guneyê mi bi Polatî ame. (Polat’a acıdım.)"
  },
  {
    "x": "guneyê ... bi ... ameyîş",
    "y": " acıma"
  },
  {
    "x": "guneyê ... pey ameyene",
    "y": " b. guneyê ... pê ameyene"
  },
  {
    "x": "guneyê ... peyameyîş",
    "y": " b. guneyê ... pêameyîş"
  },
  {
    "x": "guneyê ... pê ameyene",
    "y": " acımak\r\n~Guneyê mi pê ame. (Ona acıdım.)"
  },
  {
    "x": "guneyê ... pêameyîş",
    "y": " acıma"
  },
  {
    "x": "gune-II (m)",
    "y": " b. gonî-II (m)"
  },
  {
    "x": "gune-III",
    "y": " b. ganî-II"
  },
  {
    "x": "gunekar, -e",
    "y": " 1)günahkâr\r\n~2)suçlu, kriminel "
  },
  {
    "x": "gunekar kerdene",
    "y": " suçlamak "
  },
  {
    "x": "gunekarîye (m)",
    "y": " gunahkârlık, suçluluk"
  },
  {
    "x": "gunekarkerda (m)",
    "y": " suçlanan"
  },
  {
    "x": "gunekarkerde (n)",
    "y": " suçlanan "
  },
  {
    "x": "gunekarkerdox, -e",
    "y": " suçlayan "
  },
  {
    "x": "gunê",
    "y": " b. ganî-II"
  },
  {
    "x": "gunguela (m)",
    "y": " b. kengula (m)"
  },
  {
    "x": "gunî-I",
    "y": " b. ganî-II"
  },
  {
    "x": "gunî-II (m)",
    "y": " b. gonî-II (m)"
  },
  {
    "x": "gur kerdene",
    "y": " (mecazen kavga vs. için) alevlendirmek"
  },
  {
    "x": "guraş (n)",
    "y": " b. gulaş (n)"
  },
  {
    "x": "guraş girewtene",
    "y": " b. gulaş girewtene"
  },
  {
    "x": "Gurcîstan (n)",
    "y": " Gürcistan"
  },
  {
    "x": "gurçik (m)",
    "y": " b. gurçike (m)"
  },
  {
    "x": "gurçike (m)",
    "y": " böbrek"
  },
  {
    "x": "gure (n)",
    "y": " 1)iş\r\n~2)meslek"
  },
  {
    "x": "gure kerdene",
    "y": " çalışmak, iş yapmak"
  },
  {
    "x": "gureyê gedeyan/domanan/qeçekan/qican (n)",
    "y": " çocuk işi"
  },
  {
    "x": "gureyo pîl (n)",
    "y": " cüzzam, miskin hastalığı, miskin illeti"
  },
  {
    "x": "guro pîl (n)",
    "y": " b. gureyo pîl (n)"
  },
  {
    "x": "gureayîş (n)",
    "y": " çalışma"
  },
  {
    "x": "gurediş (n)",
    "y": " b. girewtiş (n)"
  },
  {
    "x": "gurenayene",
    "y": " 1)çalıştırmak\r\n~2)işletmek\r\n~3)kullanmak"
  },
  {
    "x": "gurenayîne",
    "y": " b. gurenayene"
  },
  {
    "x": "gurenayîş (n)",
    "y": " 1)çalıştırma, çalıştırış\r\n~2)işletme\r\n~3)kullanma"
  },
  {
    "x": "gurenayox, -e",
    "y": " işleten"
  },
  {
    "x": "gurenîyayîş (n)",
    "y": " kullanım"
  },
  {
    "x": "guretene",
    "y": " b. girewtene"
  },
  {
    "x": "guretis (n)",
    "y": " b. girewtiş (n)"
  },
  {
    "x": "guretiş (n)",
    "y": " b. girewtiş (n)"
  },
  {
    "x": "gurewtiş (n)",
    "y": " b. girewtiş (n)"
  },
  {
    "x": "gurex (m)",
    "y": " b. kurihe (m)"
  },
  {
    "x": "gureyayene",
    "y": " çalışmak"
  },
  {
    "x": "gureyayêne",
    "y": " b. gureyayene"
  },
  {
    "x": "gureyayîne",
    "y": " b. gureyayene"
  },
  {
    "x": "gureyayîş (n)",
    "y": " çalışma"
  },
  {
    "x": "gureyene",
    "y": " b. gureyayene"
  },
  {
    "x": "gureyîne",
    "y": " b. gureyayene"
  },
  {
    "x": "gureyîş (n)",
    "y": " b. gureyayîş (n)"
  },
  {
    "x": "gureynayene",
    "y": " b. gurenayene"
  },
  {
    "x": "gureynayîne",
    "y": " b. gurenayene"
  },
  {
    "x": "gureynayîş (n)",
    "y": " b. gurenayîş (n)"
  },
  {
    "x": "gurênayene",
    "y": " b. gurenayene"
  },
  {
    "x": "gurênayîne",
    "y": " b. gurenayene"
  },
  {
    "x": "gurênayîş (n)",
    "y": " b. gurenayîş (n)"
  },
  {
    "x": "Gurêx (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "gurêyayene",
    "y": " b. gureyayene "
  },
  {
    "x": "gurêyayîne",
    "y": " b. gureyayene"
  },
  {
    "x": "gurêyayîş (n)",
    "y": " b. gureyayîş (n)"
  },
  {
    "x": "gurgecîne (m)",
    "y": " b. gogerçîne (m)"
  },
  {
    "x": "gurgocîne (m)",
    "y": " b. gogerçîne (m)"
  },
  {
    "x": "gurgoçîne (m)",
    "y": " b. gogerçîne (m)"
  },
  {
    "x": "guri (n)",
    "y": " b. gure (n)"
  },
  {
    "x": "gurisnayene",
    "y": " içmek (sigara vb. için)"
  },
  {
    "x": "guritene",
    "y": " b. girewtene"
  },
  {
    "x": "guritiş (n)",
    "y": " b. girewtiş (n)"
  },
  {
    "x": "guriz (n)",
    "y": " pürüz"
  },
  {
    "x": "gurizin, -e",
    "y": " pürüzlü"
  },
  {
    "x": "gurînayene",
    "y": " b. gurenayene"
  },
  {
    "x": "gurînayîne",
    "y": " b. gurenayene"
  },
  {
    "x": "gurînayîş (n)",
    "y": " b. gurenayîş (n)"
  },
  {
    "x": "gurîyayene",
    "y": " b. gureyayene"
  },
  {
    "x": "gurîyayîne",
    "y": " b. gureyayene"
  },
  {
    "x": "gurîyayîş (n)",
    "y": " b. gureyayîş (n)"
  },
  {
    "x": "gurkerdiş (n)",
    "y": " (mecazen kavga vs. için) alevlendirme"
  },
  {
    "x": "gurme-gurme",
    "y": " gümbürtü"
  },
  {
    "x": "gurmiki (m)",
    "y": " b. girmike (m)"
  },
  {
    "x": "gurmiki eşteni",
    "y": " b. girmikî eştene"
  },
  {
    "x": "gurmikî kerdeni",
    "y": " b. girmikî kerdene"
  },
  {
    "x": "gurmikî piro ginayene",
    "y": " b. girmikî piro ginayene"
  },
  {
    "x": "gurmikî werdeni",
    "y": " b. girmikî werdene"
  },
  {
    "x": "gurmikieştiş (n)",
    "y": " b. girmikîeştiş (n)"
  },
  {
    "x": "gurmikîkerdiş (n)",
    "y": " b. girmikîkerdiş (n)"
  },
  {
    "x": "gurmikîpiroginayîş (n)",
    "y": " b. girmikîpiroginayîş (n)"
  },
  {
    "x": "gurmikîwerdiş (n)",
    "y": " b. girmikîwerdiş (n)"
  },
  {
    "x": "gurnig (m)",
    "y": " b. gurnîge (m)"
  },
  {
    "x": "gurnî (n)",
    "y": " b. gurnîge (m)"
  },
  {
    "x": "gurnîg (n)",
    "y": " b. gurnîge (m)"
  },
  {
    "x": "gurnîge (m)",
    "y": " pıtrak"
  },
  {
    "x": "gurnîgi (m)",
    "y": " b. gurnîge (m)"
  },
  {
    "x": "gurnîk (m)",
    "y": " b. gurnîge (m)"
  },
  {
    "x": "gurotene",
    "y": " b. girewtene "
  },
  {
    "x": "gurotiş (n)",
    "y": " b. girewtiş (n)"
  },
  {
    "x": "gurr-gurr",
    "y": " 1)gümbür gümbür\r\n~2)hüngür hüngür"
  },
  {
    "x": "gurrayene",
    "y": " gürlemek"
  },
  {
    "x": "gurrayêne",
    "y": " b. gurrayene"
  },
  {
    "x": "gurrayîş (n)",
    "y": " gürleme"
  },
  {
    "x": "gurrayîşê hewrî (n)",
    "y": " gök gürlemesi"
  },
  {
    "x": "gurrayîşî horî (n)",
    "y": " b. gurrayîşê hewrî (n)"
  },
  {
    "x": "gurre-gurre",
    "y": " gümbürtü"
  },
  {
    "x": "gurrgurr",
    "y": " hüngür hüngür"
  },
  {
    "x": "gurrgurr bermayene",
    "y": " hüngür hüngür ağlamak"
  },
  {
    "x": "gurrgurrbermayîş (n)",
    "y": " hüngür hüngür ağlama"
  },
  {
    "x": "gurrî-I (m)",
    "y": " gürleme, gürleme sesi"
  },
  {
    "x": "gurrîya hewrî (m)",
    "y": " gök gürlemesi"
  },
  {
    "x": "gurrîyê horî (m)",
    "y": " b. gurrîya hewrî (m)"
  },
  {
    "x": "gurrî, -ye-II",
    "y": " kel"
  },
  {
    "x": "gurrîyey (m)",
    "y": " b. gurrîyîye (m)"
  },
  {
    "x": "gurrîyîye (m)",
    "y": " kellik"
  },
  {
    "x": "gurwe (n)",
    "y": " b. gure (n)"
  },
  {
    "x": "gurweyayene",
    "y": " b. gureyayene"
  },
  {
    "x": "gurweyayîş (n)",
    "y": " b. gureyayîş (n)"
  },
  {
    "x": "gurweyîne",
    "y": " b. gureyayene"
  },
  {
    "x": "gurweyîş (n)",
    "y": " b. gureyayîş (n)"
  },
  {
    "x": "gurweynayîne",
    "y": " b. gurenayene"
  },
  {
    "x": "gurweynayîş (n)",
    "y": " b. gurenayîş (n)"
  },
  {
    "x": "gustire (n)",
    "y": " b. giştire (n)"
  },
  {
    "x": "guşt (m)",
    "y": " b. engişte (m)"
  },
  {
    "x": "guvde (n)",
    "y": " b. govde (n)"
  },
  {
    "x": "guvelek (n)",
    "y": " b. guvelik (n)"
  },
  {
    "x": "guvelik (n)",
    "y": " salyangoz"
  },
  {
    "x": "guvik (m)",
    "y": " b. guvike (m)"
  },
  {
    "x": "guvike (m)",
    "y": " topaç"
  },
  {
    "x": "guviki (m)",
    "y": " b. guvike (m)"
  },
  {
    "x": "guvişnayene",
    "y": " b. givişnayene"
  },
  {
    "x": "guvişnayîş (n)",
    "y": " b. givişnayîş (n)"
  },
  {
    "x": "guvîc (m)",
    "y": " b. givêje (m)"
  },
  {
    "x": "guvîci (m)",
    "y": " b. givêje (m)"
  },
  {
    "x": "guvuşnayene",
    "y": " b. givişnayene"
  },
  {
    "x": "guvuşnayîş (n)",
    "y": " b. givişnayîş (n)"
  },
  {
    "x": "guwan (n)",
    "y": " meme (küçükbaş ve büyükbaş hayvanlar için)"
  },
  {
    "x": "guwandil (n)",
    "y": " (küçükbaş ve büyükbaş hayvanlar için) meme ucu"
  },
  {
    "x": "guwane (n)",
    "y": " b. guwan (n)"
  },
  {
    "x": "guwaş (n)",
    "y": " b. goş (n)"
  },
  {
    "x": "guwaz (m)",
    "y": " b. goze (m) "
  },
  {
    "x": "guwazêr (m)",
    "y": " b. gozêre (m)"
  },
  {
    "x": "guweçîn (m)",
    "y": " b. goçîne (m)"
  },
  {
    "x": "guweg (m)",
    "y": " b. goge (m)"
  },
  {
    "x": "guwegi (m)",
    "y": " b. goge (m)"
  },
  {
    "x": "guwend (n)",
    "y": " 1)kısa urgan\r\n~2)dövenden boyunduruğa kadar uzanan direği dövene bağlayan bağ"
  },
  {
    "x": "guwepal (m)",
    "y": " b. gopale (m)"
  },
  {
    "x": "guweri (m)",
    "y": " b. guhere (m)"
  },
  {
    "x": "guweri bestene",
    "y": " b. guhere bestene"
  },
  {
    "x": "guweş (n)",
    "y": " b. goş (n) "
  },
  {
    "x": "guweşgirûn (n)",
    "y": " b. goşgiran (n)"
  },
  {
    "x": "guweştarî kerdene",
    "y": " b. goşdarî kerdene"
  },
  {
    "x": "guweştarîkerdiş (n)",
    "y": " b. goşdarîkerdiş (n)"
  },
  {
    "x": "guwevend (m)",
    "y": " b. govende (m)"
  },
  {
    "x": "guwez (m)",
    "y": " b. goze (m)"
  },
  {
    "x": "guwîn (m)",
    "y": " b. gonî-II (m)"
  },
  {
    "x": "guwîn dekotene",
    "y": " b. gonî dekewtene"
  },
  {
    "x": "guwînwer",
    "y": " b. gonîwer, -e"
  },
  {
    "x": "guwnayene",
    "y": " b. ginayene"
  },
  {
    "x": "guwnayîş (n)",
    "y": " b. ginayîş (n)"
  },
  {
    "x": "Guyana (m)",
    "y": " Guyana"
  },
  {
    "x": "Guyanaya Fransa (m)",
    "y": " Fransız Guyanası "
  },
  {
    "x": "guyen (n)",
    "y": " b. guwan (n)"
  },
  {
    "x": "guyn",
    "y": " b. gonî-II (m)"
  },
  {
    "x": "guyni",
    "y": " b. gonî-II (m)"
  },
  {
    "x": "guz, -e-I",
    "y": " acı"
  },
  {
    "x": "guz-II (n)",
    "y": " b. hêz (n)"
  },
  {
    "x": "guzergah (n)",
    "y": " güzergâh"
  },
  {
    "x": "guzêke (m)",
    "y": " b. gozeke (m) "
  },
  {
    "x": "guzike-I (m)",
    "y": " b. gozeke (m)"
  },
  {
    "x": "guzike-II (m)",
    "y": " b. kuçike (m)"
  },
  {
    "x": "guzin, -e",
    "y": " acılı, acı"
  },
  {
    "x": "guziney (m)",
    "y": " b. guzinî (m)"
  },
  {
    "x": "guzinê (m)",
    "y": " b. guzinî (m)"
  },
  {
    "x": "guzinî (m)",
    "y": " acılık"
  },
  {
    "x": "guzinîye (m)",
    "y": " acılık"
  },
  {
    "x": "guziz (n)",
    "y": " b. gizuz (n)"
  },
  {
    "x": "guzîz (n)",
    "y": " b. gizuz (n)"
  },
  {
    "x": "guzligî (zh)",
    "y": " b. gozligî (zh)"
  },
  {
    "x": "guzun, -e",
    "y": " b. guzin, -e"
  },
  {
    "x": "guzunê (m)",
    "y": " b. guzinî (m), guzinîye (m)"
  },
  {
    "x": "guzunî (m)",
    "y": " b. guzinî (m), guzinîye (m)"
  },
  {
    "x": "gû (n)",
    "y": " b. gan (n)"
  },
  {
    "x": "gûîn (m)",
    "y": " b. gonî-II (m)"
  },
  {
    "x": "gûîn dekotene",
    "y": " b. gonî dekewtene"
  },
  {
    "x": "gûîn qelebnayene",
    "y": " b. gonî qelibnayene"
  },
  {
    "x": "gûînwer",
    "y": " b. gonîwer, -e"
  },
  {
    "x": "gûl (m)",
    "y": " b. gole-I (m)"
  },
  {
    "x": "gûlale (m)",
    "y": " bokböceği, pislik böceği, hapböceği"
  },
  {
    "x": "gûlala gîyî (m)",
    "y": " bokböceği, pislik böceği, hapböceği"
  },
  {
    "x": "gûlala rêxe",
    "y": " bokböceği, pislik böceği, hapböceği"
  },
  {
    "x": "gûlala silî (m)",
    "y": " bokböceği, pislik böceği, hapböceği"
  },
  {
    "x": "gûlaleke (m)",
    "y": " küçük bokböceği, küçük pislik böceği, küçük hapböceği"
  },
  {
    "x": "gûlaleki (m)",
    "y": " b. gûlaleke (m)"
  },
  {
    "x": "gûli (m)",
    "y": " b. gole-I (m)"
  },
  {
    "x": "gûlik, -i",
    "y": " b. golik, -e"
  },
  {
    "x": "gûlik ardene",
    "y": " b. golik ardene"
  },
  {
    "x": "gûm (n)",
    "y": " b. game (m)"
  },
  {
    "x": "gûme (n)",
    "y": " b. gome (n)"
  },
  {
    "x": "gûmeş",
    "y": " b. gamêş, -e"
  },
  {
    "x": "gûmêş, -i",
    "y": " b. gamêş, -e"
  },
  {
    "x": "gûmi-I (m)",
    "y": " b. game (m)"
  },
  {
    "x": "gûmi-II (n)",
    "y": " b. gome (n)"
  },
  {
    "x": "gûmîeş",
    "y": " b. gamêş, -e"
  },
  {
    "x": "gûmîyeş",
    "y": " b. gamêş, -e"
  },
  {
    "x": "gûmîyeş ûmeyîne",
    "y": " b. gamêş ameyene"
  },
  {
    "x": "gûmîyeşûmeyîş (n)",
    "y": " b. gamêşameyîş (n)"
  },
  {
    "x": "gûmîyêş",
    "y": " b. gamêş, -e"
  },
  {
    "x": "gûmpal (m)",
    "y": " b. gopale (m)"
  },
  {
    "x": "gûmyeş",
    "y": " b. gamêş, -e"
  },
  {
    "x": "gûmyêş",
    "y": " b. gamêş, -e"
  },
  {
    "x": "gûn-I (n)",
    "y": " b. gan (n)"
  },
  {
    "x": "gûn fîda",
    "y": " b. gan fîda"
  },
  {
    "x": "gûnî ver di bîyayîne",
    "y": " b. ganî ver de bîyene"
  },
  {
    "x": "gûnû ver di bîyayîne",
    "y": " b. ganan ver de bîyene"
  },
  {
    "x": "gûn-II",
    "y": " b. ganî-II"
  },
  {
    "x": "gûn-III (m)",
    "y": " b. gonî-II (m)"
  },
  {
    "x": "gûn-IV (n)",
    "y": " b. guwan (n)"
  },
  {
    "x": "gûnc (m)",
    "y": " b. gunce (m)"
  },
  {
    "x": "gûne (m)",
    "y": " b. gonî-II (m)"
  },
  {
    "x": "gûnfîda, -ye",
    "y": " b. ganfîda, -ye"
  },
  {
    "x": "Gûnger (n)",
    "y": " b. Gangêr (n)"
  },
  {
    "x": "Gûngêr (n)",
    "y": " b. Gangêr (n)"
  },
  {
    "x": "gûngirû (n)",
    "y": " b. gangiran, -e"
  },
  {
    "x": "gûngirûn, -i",
    "y": " b. gangiran, -e"
  },
  {
    "x": "gûngirûnî (m)",
    "y": " b. gangiranîye (m)"
  },
  {
    "x": "gûngualê (m)",
    "y": " b. kengula (m)"
  },
  {
    "x": "gûnî-I",
    "y": " b. ganî, -ye-I"
  },
  {
    "x": "gûnî-II (m)",
    "y": " b. gonî-II (m)"
  },
  {
    "x": "gûnî bîyayene",
    "y": " b. gonî bîyene"
  },
  {
    "x": "gûnî ci kewtene",
    "y": " b. gonî ci kewtene"
  },
  {
    "x": "gûnî dekewtene",
    "y": " b. gonî dekewtene"
  },
  {
    "x": "gûnî dekowtene",
    "y": " b. gonî dekewtene"
  },
  {
    "x": "gûnî deqesîyayene",
    "y": " b. gonî deqesîyayene"
  },
  {
    "x": "gûnî kerdene",
    "y": " b. gonî kerdene"
  },
  {
    "x": "gûnî qelebnayene",
    "y": " b. gonî qelibnayene"
  },
  {
    "x": "gûnî qelevnayene",
    "y": " b. gonî qelibnayene"
  },
  {
    "x": "gûnî top bîyayene",
    "y": " b. gonî top bîyene"
  },
  {
    "x": "... de gûnî top bîyayene",
    "y": " b. ... de gonî top bîyene"
  },
  {
    "x": "... ra gûnî amayene",
    "y": " b. ... ra gonî ameyene"
  },
  {
    "x": "... ra gûnî ardene",
    "y": " b. ... ra gonî ardene"
  },
  {
    "x": "gûnî-III",
    "y": " b. ganî-II"
  },
  {
    "x": "gûnî-IV (n)",
    "y": " b. guwan (n)"
  },
  {
    "x": "gûnîamayîş (n)",
    "y": " b. gonîameyîş (n)"
  },
  {
    "x": "gûnîbîyayîş (n)",
    "y": " b. gonîbîyayîş (n)"
  },
  {
    "x": "gûnîwer",
    "y": " b. gonîwer, -e"
  },
  {
    "x": "gûnsivik, -i",
    "y": " b. gansivik, -e"
  },
  {
    "x": "gûnsivikey (m)",
    "y": " b. gansivikîye (m)"
  },
  {
    "x": "gûnsivikê (m)",
    "y": " b. gansivikî (m)"
  },
  {
    "x": "gûnşenik, -i",
    "y": " b. ganşenik, -e"
  },
  {
    "x": "gûrnig (m)",
    "y": " b. gurnîge (m)"
  },
  {
    "x": "gûş (n)",
    "y": " b. goş (n)"
  },
  {
    "x": "gûş degunayene",
    "y": " b. goş deginayene"
  },
  {
    "x": "gûşa xwi ... daritene",
    "y": " b. goşê xo ... daritene"
  },
  {
    "x": "gûşa xwi ... daritiş",
    "y": " b. goşê xo ... daritiş"
  },
  {
    "x": "gûşa xwi ... nayene",
    "y": " b. goşê xo ... nayene"
  },
  {
    "x": "gûşa xwi ... nayîş",
    "y": " b. goşê xo ... nayîş"
  },
  {
    "x": "gûşê xwi ser nayene",
    "y": " b. goşê xo ser nayene"
  },
  {
    "x": "gûşare (n)",
    "y": " b. goşare (n"
  },
  {
    "x": "gûşdar, -e",
    "y": " b. goşdar, -e"
  },
  {
    "x": "gûşdarey (m)",
    "y": " b. goşdarîye (m)"
  },
  {
    "x": "gûşdaritene",
    "y": " b. goşdaritene"
  },
  {
    "x": "gûşdaritiş (n)",
    "y": " b. goşdaritiş (n)"
  },
  {
    "x": "gûşdarî (m)",
    "y": " b. goşdarî (m)"
  },
  {
    "x": "gûşdarî kerdene",
    "y": " b. goşdarî kerdene"
  },
  {
    "x": "gûşdarîkerdiş (n)",
    "y": " b. goşdarîkerdiş (n)"
  },
  {
    "x": "gûşe-I (n)",
    "y": " salkım"
  },
  {
    "x": "gûşe-II (n)",
    "y": " b. goşe-I (n)"
  },
  {
    "x": "gûşêxwisernayîş (n)",
    "y": " b. goşêxosernayîş (n)"
  },
  {
    "x": "gûşî (n)",
    "y": " b. gûşe-I (n)"
  },
  {
    "x": "gûşle (n)",
    "y": " üzümsüz salkım çöpü (üzümü alınmış salkım artığı)"
  },
  {
    "x": "gûşt (n)",
    "y": " b. goşt (n)"
  },
  {
    "x": "gûşt dindûnî (n)",
    "y": " b. goştê didanî (n)"
  },
  {
    "x": "gûştar",
    "y": " b. goşdar, -e"
  },
  {
    "x": "gûştarey (m)",
    "y": " b. goşdarîye (m)"
  },
  {
    "x": "gûştaritene",
    "y": " b. goşdaritene"
  },
  {
    "x": "gûştaritiş (n)",
    "y": " b. goşdaritiş (n)"
  },
  {
    "x": "gûştaritox, -i",
    "y": " b. goşdaritox, -e"
  },
  {
    "x": "gûştarî (m)",
    "y": " b. goşdarî (m)"
  },
  {
    "x": "gûştarî kerdene",
    "y": " b. goşdarî kerdene"
  },
  {
    "x": "gûştarîkerdiş (n)",
    "y": " b. goşdarîkerdiş (n)"
  },
  {
    "x": "gûta silî (m)",
    "y": " b. guda silî (m)"
  },
  {
    "x": "gûta sillî (m)",
    "y": " b. guda silî (m)"
  },
  {
    "x": "gûyend (n",
    "y": ") b. guwend (n)"
  },
  {
    "x": "gûz (m)",
    "y": " b. goze (m)"
  },
  {
    "x": "gûzi (m)",
    "y": " b. goze (m)"
  },
  {
    "x": "gwelik",
    "y": " b. golik, -e"
  },
  {
    "x": "gweme (n)",
    "y": " b. gome (n)"
  },
  {
    "x": "gwend (n)",
    "y": " b. guwend (n)"
  },
  {
    "x": "gwer (n)",
    "y": " b. gor (n)"
  },
  {
    "x": "gweş (n)",
    "y": " b. goş (n) "
  },
  {
    "x": "gweşt (n)",
    "y": " b. goşt (n)"
  },
  {
    "x": "gweştarî kerdene",
    "y": " b. goşdarî kerdene"
  },
  {
    "x": "gwêzer (n)",
    "y": " b. gizêr (n)"
  },
  {
    "x": "gwil (m)",
    "y": " b. gule-II (m)"
  },
  {
    "x": "gwîn (m)",
    "y": " b. gonî-II (m)"
  },
  {
    "x": "gwîni (m)",
    "y": " b. gonî-II (m)"
  },
  {
    "x": "gwîni bîyayîne",
    "y": " b. gonî bîyene"
  },
  {
    "x": "gwîni dekewtene",
    "y": " b. gonî dekewtene"
  },
  {
    "x": "gwîni kerdene",
    "y": " b. gonî kerdene"
  },
  {
    "x": "... ra gwîni ameyîne",
    "y": " b. ... ra gonî ameyene"
  },
  {
    "x": "... ra gwîni ardene",
    "y": " b. ... ra gonî ardene"
  },
  {
    "x": "gwîniameyîş (n)",
    "y": " b. gonîameyîş (n)"
  },
  {
    "x": "gwînibîyayîş (n)",
    "y": " b. gonîbîyayîş (n)"
  },
  {
    "x": "gwîniwer",
    "y": " b. gonîwer, -e"
  },
  {
    "x": "gwînî (m)",
    "y": " b. gonî-II (m)"
  },
  {
    "x": "gyec",
    "y": " b. gêj, -e"
  },
  {
    "x": "HHH"
  },
  {
    "x": "ha-I",
    "y": " 1)fiilin şimdiki zamanında kullanılan bir partikel\r\n~Ez ha nusena. (Ben yazıyorum.)"
  },
  {
    "x": "ha-II",
    "y": " 1)b. Ya\r\n~2)b. yê\r\n~3)b. yo"
  },
  {
    "x": "ha-III",
    "y": " ha"
  },
  {
    "x": "ha... ha...",
    "y": " ha ... ha ..., ister ... ister …\r\n~Ha ez ha ti, ferq nêkeno. (Ha ben, ha sen; farketmez./İster ben, ister sen; farketmez.)"
  },
  {
    "x": "ha-IV (m, s)",
    "y": " b. a (m, s)"
  },
  {
    "x": "ha w’",
    "y": " b. ha ya, ha yo"
  },
  {
    "x": "ha wa",
    "y": " b. ha ya"
  },
  {
    "x": "ha wo",
    "y": " b. ha yo"
  },
  {
    "x": "ha y’",
    "y": " b. ha ya, ha yo"
  },
  {
    "x": "ha ya",
    "y": " odur, işte"
  },
  {
    "x": "ha yo",
    "y": " odur, işte"
  },
  {
    "x": "hac (n)",
    "y": " b. hec (n)"
  },
  {
    "x": "haca (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "hacacî (zh)",
    "y": " b. hecacî (zh)"
  },
  {
    "x": "hacet (n)",
    "y": " sik, penis, kamış"
  },
  {
    "x": "hacete (m)",
    "y": " alet"
  },
  {
    "x": "haceta sentişî (m)",
    "y": " çeki (tartı)"
  },
  {
    "x": "hacetî (zh)",
    "y": " araç gereç"
  },
  {
    "x": "hacetî gurenayene",
    "y": " araç gereç kullanmak"
  },
  {
    "x": "hacetî şuxulnayene",
    "y": " araç gereç kullanmak"
  },
  {
    "x": "hacetî xebitnayene",
    "y": " araç gereç kullanmak"
  },
  {
    "x": "hacêr",
    "y": " b. acêr"
  },
  {
    "x": "hacî, -ya",
    "y": " b. hecî, -ya"
  },
  {
    "x": "hacîya (m)",
    "y": " b. hecîya (m)"
  },
  {
    "x": "hacuc, -e",
    "y": " b.  hecuc, -e"
  },
  {
    "x": "hacuc macuc",
    "y": " b. hecuc mecuc"
  },
  {
    "x": "hacucîye (m)",
    "y": " b. hecucîye (m)"
  },
  {
    "x": "haderê",
    "y": " b. hadirê"
  },
  {
    "x": "hadê",
    "y": " haydi"
  },
  {
    "x": "hadira (m)",
    "y": " b. hazire (m)"
  },
  {
    "x": "hadire (n)",
    "y": " b. hazir (n)"
  },
  {
    "x": "hadirê",
    "y": " haydi"
  },
  {
    "x": "hadra (m)",
    "y": " b. hazire (m)"
  },
  {
    "x": "hadre (n)",
    "y": " b. hazir (n)"
  },
  {
    "x": "hadre bîyene",
    "y": " b. hazir bîyene"
  },
  {
    "x": "hadre kerdene",
    "y": " b. hazir kerdene"
  },
  {
    "x": "hadri (n)",
    "y": " b. hazir (n)"
  },
  {
    "x": "hadri bîyene",
    "y": " b. hazir bîyene"
  },
  {
    "x": "hadri kerdene",
    "y": " b. hazir kerdene"
  },
  {
    "x": "hafiz, -e",
    "y": " görme engelli¸ görmez, gözsüz, kör"
  },
  {
    "x": "hagir (n)",
    "y": " b. adir (n)"
  },
  {
    "x": "hagî (m)",
    "y": " b. hayî (m)"
  },
  {
    "x": "hagî ... bîyayîş",
    "y": " b. hayî ... bîyayîş"
  },
  {
    "x": "hagî ... bîyene",
    "y": " b. hayî ... bîyene"
  },
  {
    "x": "hagî pey bîyene",
    "y": " b. hayî pê bîyene"
  },
  {
    "x": "hagîdar, -i",
    "y": " b. haydar, -e"
  },
  {
    "x": "hagîdar, -e",
    "y": " b. haydar, -e"
  },
  {
    "x": "hagîpeybîyayîş (n)",
    "y": " b. hayîpêbîyayîş (n)"
  },
  {
    "x": "hagose (m)",
    "y": " çizi (saban ya da pulluğun açtığı iz), karık (toprakta sabanla açılan çizi)"
  },
  {
    "x": "hagosi (m)",
    "y": " b. hagose (m)"
  },
  {
    "x": "haham (n)",
    "y": " b. haxam (n)"
  },
  {
    "x": "Haîtî",
    "y": " Haiti"
  },
  {
    "x": "haj-I (n)",
    "y": " b. hec (n)"
  },
  {
    "x": "haj-II (n)",
    "y": " b. az (n)"
  },
  {
    "x": "haja (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "hajet (n)",
    "y": " b. hacet (n)"
  },
  {
    "x": "hajete (m)",
    "y": " b. hacete (m)"
  },
  {
    "x": "hajhajik (m)",
    "y": " b. hechecike (m)"
  },
  {
    "x": "hajî, -ya",
    "y": " b. hecî, -ya"
  },
  {
    "x": "hajîya (m)",
    "y": " b. hecîya (m)"
  },
  {
    "x": "hak (n)",
    "y": " 1)yumurta\r\n~2)taşak, haya (erkeklik bezi), erbezi, husye"
  },
  {
    "x": "hak a bîyayîne",
    "y": " b. hak ra bîyene"
  },
  {
    "x": "hak kerdene",
    "y": " yumurtlamak"
  },
  {
    "x": "hak ra bîyene",
    "y": " tavuk vb. kümes hayvanının yumurtlayacak durumda olması"
  },
  {
    "x": "hakê maseyan (zh)",
    "y": " balık yumurtası, havyar"
  },
  {
    "x": "hakem, -e",
    "y": " b. hekem, -e"
  },
  {
    "x": "hakim, -e",
    "y": " hâkim, yargıç"
  },
  {
    "x": "hakimê îfadegirewtişî (n)",
    "y": " sorgu hâkimi "
  },
  {
    "x": "hakîmey (m)",
    "y": " b. hakimîye (m)"
  },
  {
    "x": "hakîmê (m)",
    "y": " b. hakimîye (m)"
  },
  {
    "x": "hakimî (m)",
    "y": " hâkimlik, yargıçlık"
  },
  {
    "x": "hakimîye (m)",
    "y": " hâkimlik, yargıçlık"
  },
  {
    "x": "hakîm, -e",
    "y": " b. hakim, -e"
  },
  {
    "x": "hakîmîye (m)",
    "y": " b. hakimîye (m)"
  },
  {
    "x": "hakkerdiş (n)",
    "y": " yumurtlama"
  },
  {
    "x": "hakkere (m)",
    "y": " yumurtlama dönemindeki tavuk"
  },
  {
    "x": "hal-I (n)",
    "y": " 1)durum, hal, vaziyet\r\n~2)pozisyon"
  },
  {
    "x": "hal ra kewtene",
    "y": " bitkin düşmek, mecalsiz düşmek"
  },
  {
    "x": "halê wayîrîye (n)",
    "y": " mülkiyet durumu"
  },
  {
    "x": "halo îstîsnayî (n)",
    "y": " olağanüstü durum, olağanüstü hal "
  },
  {
    "x": "hal-II (n)",
    "y": " tifüs, lekelihumma"
  },
  {
    "x": "hal-III (n)",
    "y": " b. hel (n)"
  },
  {
    "x": "hal kerdene",
    "y": " b. hel kerdene"
  },
  {
    "x": "hal-IV (n) ",
    "y": "hal (genellikle üstü kapalı, sebze, meyve vb. satılan, sürekliliği olan pazar yeri)"
  },
  {
    "x": "hala",
    "y": " b. hela"
  },
  {
    "x": "hala hala!",
    "y": " Allah Allah!"
  },
  {
    "x": "halahala (m)",
    "y": " b. helahela (m)"
  },
  {
    "x": "halaw (m)",
    "y": " b. helwa (m)"
  },
  {
    "x": "halawe (m)",
    "y": " b. helwa (m)"
  },
  {
    "x": "halayîye (m)",
    "y": " b. alaye (m)"
  },
  {
    "x": "halbik",
    "y": " b. halbike"
  },
  {
    "x": "halbike",
    "y": " halbuki, oysa, oysaki"
  },
  {
    "x": "halbiki",
    "y": " b. halbike"
  },
  {
    "x": "halbiko",
    "y": " b. halbike"
  },
  {
    "x": "halbuka",
    "y": " b. halbike"
  },
  {
    "x": "halbukî",
    "y": " b. halbike"
  },
  {
    "x": "halbuko",
    "y": " b. halbike"
  },
  {
    "x": "halbûkî",
    "y": " b. halbike"
  },
  {
    "x": "halen (n)",
    "y": " b. halîn (n)"
  },
  {
    "x": "halen bîyayene",
    "y": " b. halên bîyene"
  },
  {
    "x": "halet (m)",
    "y": " b. alete (m)"
  },
  {
    "x": "halete (m)",
    "y": " b. xelate (m)"
  },
  {
    "x": "haleti (m)",
    "y": " b. alete (m)"
  },
  {
    "x": "halên-I (n)",
    "y": " b. halîn (n)"
  },
  {
    "x": "halên-II (m)",
    "y": " b. hêlaneke (m) "
  },
  {
    "x": "halên bîyayêne",
    "y": " b. halên bîyene"
  },
  {
    "x": "halên bîyene",
    "y": " kamaşmak (diş için)\r\n~Didanê mi bîyê halên. (Dişlerim kamaşmış.)"
  },
  {
    "x": "halênek (n)",
    "y": " b. hêlaneke (m)"
  },
  {
    "x": "halêt (m)",
    "y": " b. xelate (m)"
  },
  {
    "x": "halête (m)",
    "y": " b. xelate (m)"
  },
  {
    "x": "halêtî (m)",
    "y": " b. xelate (m)"
  },
  {
    "x": "halîn (n)",
    "y": " yuva"
  },
  {
    "x": "halînê zerence (n)",
    "y": " keklik yuvası "
  },
  {
    "x": "halîng (n)",
    "y": " b. halîn (n)"
  },
  {
    "x": "halkerdiş (n)",
    "y": " b. helkerdiş (n)"
  },
  {
    "x": "halorî (m)",
    "y": " b. helasorî, -ye"
  },
  {
    "x": "halorî bîyayene",
    "y": " b. helasorî bîyene"
  },
  {
    "x": "halorîyî (m)",
    "y": " b. helasorîyîye (m)"
  },
  {
    "x": "halrakewtiş (n)",
    "y": " mecalsiz düşme"
  },
  {
    "x": "haltenik, -e",
    "y": " yoksul, fakir"
  },
  {
    "x": "haltenikîye (m)",
    "y": " yoksulluk, yokluk, fakirlik"
  },
  {
    "x": "halûni (m)",
    "y": " b. halîn (n)"
  },
  {
    "x": "halvoz, -e",
    "y": " b. embaz, -e"
  },
  {
    "x": "halyen (n)",
    "y": " b. halîn (n)"
  },
  {
    "x": "ham û ta",
    "y": " b. ham û tam"
  },
  {
    "x": "ham û tam",
    "y": " habersiz, bihaber"
  },
  {
    "x": "hama-I",
    "y": " b. hema-I"
  },
  {
    "x": "hama ra",
    "y": " b. hema-1"
  },
  {
    "x": "hama-hama",
    "y": " b. hema-hema"
  },
  {
    "x": "hama-II",
    "y": " b. labelê, la"
  },
  {
    "x": "hamal, -e",
    "y": " b. hemal, -e"
  },
  {
    "x": "hamam (n)",
    "y": " b. hemam (n)"
  },
  {
    "x": "hamburger (n)",
    "y": " hamburger"
  },
  {
    "x": "hameyîne",
    "y": " b. ameyene"
  },
  {
    "x": "hameyîne deyene",
    "y": " b. ameyene dîyene"
  },
  {
    "x": "hameyîne kijjikkerdene",
    "y": " b. ameyene kijikekerdene"
  },
  {
    "x": "hameyîne panayene",
    "y": " b. ameyene panayene"
  },
  {
    "x": "hameyîne perzimkerdene ",
    "y": " b. ameyene parzunkerdene"
  },
  {
    "x": "hameyîne powtene",
    "y": " b. ameyene pewtene"
  },
  {
    "x": "hameyîne solikerdene",
    "y": " b. ameyene solekerdene"
  },
  {
    "x": "hameyîne veşnayîne",
    "y": " b. ameyene veşnayene"
  },
  {
    "x": "hameyîne veynayene",
    "y": " b. ameyene vînayene"
  },
  {
    "x": "hameyîne werdene",
    "y": " b. ameyene werdene"
  },
  {
    "x": "hameyîş (n)",
    "y": " b. ameyîş (n)"
  },
  {
    "x": "hameyîşkijjikkerdiş (n)",
    "y": " b. ameyîşkijikekerdiş (n)"
  },
  {
    "x": "hameyîşpanayîş (n)",
    "y": " b. ameyîşpanayîş (n)"
  },
  {
    "x": "hameyîşperzimkerdiş (n)",
    "y": " b. ameyîşparzunkerdiş (n)"
  },
  {
    "x": "hameyîşpowtiş (n)",
    "y": " b. ameyîşpewtiş (n)"
  },
  {
    "x": "hameyîşsolikerdiş (n)",
    "y": " b. ameyîşsolekerdiş (n)"
  },
  {
    "x": "hameyîşveşnayîş (n)",
    "y": " b. ameyîşveşnayîş (n)"
  },
  {
    "x": "hami (n)",
    "y": " b. hemam (n)"
  },
  {
    "x": "haminan (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "hamnan (n)",
    "y": " yaz"
  },
  {
    "x": "hamnano peyên",
    "y": " yazın son dönemi"
  },
  {
    "x": "hamnano verên",
    "y": " yazın ilk dönemi"
  },
  {
    "x": "hamnana (m)",
    "y": " yazlık"
  },
  {
    "x": "hamnane (n)",
    "y": " yazlık"
  },
  {
    "x": "hamnanên, -e",
    "y": " yazlık"
  },
  {
    "x": "hamnanî-I",
    "y": " yazın"
  },
  {
    "x": "hamnanî-II (n, m)",
    "y": " yazlık"
  },
  {
    "x": "hamnanîye (m)",
    "y": " yazlık"
  },
  {
    "x": "hamver, -i",
    "y": " b. hember, -e;hemver, -e"
  },
  {
    "x": "han-I (n)",
    "y": " özendirme, teşvik"
  },
  {
    "x": "han bîyene",
    "y": " özendirilmek, teşvik edilmek"
  },
  {
    "x": "han kerdene",
    "y": " özendirmek, teşvik etmek"
  },
  {
    "x": "han-II (n)",
    "y": " b. hewn (n), xow (n)"
  },
  {
    "x": "hana-I",
    "y": " b. wina"
  },
  {
    "x": "hana-II",
    "y": " b. hîna-I"
  },
  {
    "x": "hanasî, -ye",
    "y": " b. winasî, -ye"
  },
  {
    "x": "hanasîn, -e",
    "y": " b. winasî, -ye"
  },
  {
    "x": "hanayîn, -e",
    "y": " b. winasî, -ye; inasarên, -e"
  },
  {
    "x": "hanbîyayîş (n)",
    "y": " özendirilme, teşvik edilme"
  },
  {
    "x": "hanc",
    "y": " b. ancî"
  },
  {
    "x": "hanca",
    "y": " b. ancîna"
  },
  {
    "x": "hancî",
    "y": " b. ancî"
  },
  {
    "x": "hand",
    "y": " b. hende"
  },
  {
    "x": "handay",
    "y": " b. hende"
  },
  {
    "x": "handayê",
    "y": " b. hende"
  },
  {
    "x": "hande",
    "y": " b. hende"
  },
  {
    "x": "handey",
    "y": " b. hendê"
  },
  {
    "x": "handê",
    "y": " b. hendê"
  },
  {
    "x": "handi",
    "y": " b. hende"
  },
  {
    "x": "hangaje (m)",
    "y": " b. hengazî (m)"
  },
  {
    "x": "tîra hangaje (m)",
    "y": " b. tîra hengazî (m)"
  },
  {
    "x": "hani (n)",
    "y": " b. hênî-I (n)"
  },
  {
    "x": "hanîyayîne",
    "y": " b. ewnîyayene"
  },
  {
    "x": "hanîyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "hankerdene",
    "y": " yapmak"
  },
  {
    "x": "hankerdiş-I (n)",
    "y": " yapma"
  },
  {
    "x": "hankerdiş-II (n)",
    "y": " özendirme, teşvik etme"
  },
  {
    "x": "Hanoî",
    "y": " Hanoy"
  },
  {
    "x": "hanqar (n)",
    "y": " çevre"
  },
  {
    "x": "Hanqara (n)",
    "y": " b. Anqara (m)"
  },
  {
    "x": "Hanqera (n)",
    "y": " b. Anqara (m)"
  },
  {
    "x": "hanya",
    "y": " b. wina"
  },
  {
    "x": "hanyayîne",
    "y": " b. ewnîyayene"
  },
  {
    "x": "hanyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "hanzar",
    "y": " b. hezar"
  },
  {
    "x": "hapa (n)",
    "y": " b. epe (m), epo (n)"
  },
  {
    "x": "haq-I (n)",
    "y": " b. heq-I (n)"
  },
  {
    "x": "Haq-II (n)",
    "y": " b. Heq-II (n)"
  },
  {
    "x": "(Eve) Destê Haqî",
    "y": " b. (Bi) Destê Heqî"
  },
  {
    "x": "Eve Haqî vo ke",
    "y": " b. Bi Heqî bo ke"
  },
  {
    "x": "Haq bijêdno",
    "y": " b. Heq bizêdno."
  },
  {
    "x": "Haq bîyaro werê",
    "y": " b. Heq werê bîyaro"
  },
  {
    "x": "(Ti) Haq kena/î",
    "y": " b. (Ti) Heqî kena/î"
  },
  {
    "x": "Haq nêkero",
    "y": " b. Heq nêkero."
  },
  {
    "x": "Haq tala",
    "y": " b. Heq teala"
  },
  {
    "x": "Haq vazo heya.",
    "y": " Heq vajo heya"
  },
  {
    "x": "Haq ve jêde kero",
    "y": " b. Heq zîyade kero."
  },
  {
    "x": "Haq zaneno.",
    "y": " b. Heq zaneno."
  },
  {
    "x": "Haq zoneno",
    "y": " b. Heq zaneno."
  },
  {
    "x": "Haq zono.",
    "y": " b. Heq zaneno."
  },
  {
    "x": "Haqî ra ayan o",
    "y": " b. Heqî ra eyan o."
  },
  {
    "x": "(Ti) Haqî sînenî/a",
    "y": " b. (Ti) Heqî sînenî/a"
  },
  {
    "x": "Haqo tala",
    "y": " b. Heq teala"
  },
  {
    "x": "Haqo tela",
    "y": " b. Heq teala"
  },
  {
    "x": "Ma kêmî kerd, Haq ve kêmi nêkero",
    "y": " b. Ma kêmî kerd, Heq kêmî nêkero."
  },
  {
    "x": "(Ti) ve Haq kena/î",
    "y": " b. (Ti) bi Heqî kena/î"
  },
  {
    "x": "haqe (m)",
    "y": " b. he-I (n)"
  },
  {
    "x": "haqîve (n)",
    "y": " b. heqîbe (n)"
  },
  {
    "x": "haqve (n)",
    "y": " b. heqîbe (n)"
  },
  {
    "x": "har, -e-I",
    "y": " 1)kuduz (kudurmuş olan)\r\n~2)azgın"
  },
  {
    "x": "har bîyayene",
    "y": " b. har bîyene"
  },
  {
    "x": "har bîyene",
    "y": " 1)kudurmak\r\n~2)azgınlaşmak, azmak; azmak (yara vb. için)\r\n~3)çılgına dönmek (mecazî)\r\n~4)kamaşmak (diş için)\r\n~Didanê mi har bîyê. (Dişlerim kamaşmış.)"
  },
  {
    "x": "har-II (n)",
    "y": " kuduz (hastalığı)"
  },
  {
    "x": "har-III (m)",
    "y": " b. herre (m)"
  },
  {
    "x": "har-IV (m)",
    "y": " b. arre (m)"
  },
  {
    "x": "haramî, -ye",
    "y": " b. heramî, -ye"
  },
  {
    "x": "haranîye (m)",
    "y": " 1)kudurganlık\r\n~2)azgınlık"
  },
  {
    "x": "haranîye era ci kewtene",
    "y": " kudurmak"
  },
  {
    "x": "haranîye era ser kewtene",
    "y": " kudurmak"
  },
  {
    "x": "haranîye kewtene",
    "y": " 1)kudurmak\r\n~2)azgınlaşmak, azmak (hayvan için cinsel anlamda)"
  },
  {
    "x": "haranîye ra ser kewtene",
    "y": " b. haranîye kewtene"
  },
  {
    "x": "haranîyekewtiş (n)",
    "y": " azgınlaşma (hayvan için cinsel anlamda)"
  },
  {
    "x": "Harare",
    "y": " Harare"
  },
  {
    "x": "harbeyîş (n)",
    "y": " harbîyayîş (n)"
  },
  {
    "x": "harbêş (n)",
    "y": " b. hargûş (n)"
  },
  {
    "x": "harbîyaya (m)",
    "y": " kuduz (kudurmuş olan)"
  },
  {
    "x": "harbîyaye (n)",
    "y": " kuduz (kudurmuş olan)"
  },
  {
    "x": "harbîyayîş (n)",
    "y": " azma (yara vb. için), azgınlaşma  "
  },
  {
    "x": "harbîyayîye (m)",
    "y": " kuduz (kudurmuş olan)"
  },
  {
    "x": "hard (n)",
    "y": " b. erd (n)"
  },
  {
    "x": "hardlerz (n)",
    "y": " b. erdlerz (n)"
  },
  {
    "x": "hare-I (n)",
    "y": " filiz, sürgün (bitki için)"
  },
  {
    "x": "hare dayene",
    "y": " filizlenmek, filiz vermek"
  },
  {
    "x": "hare-II (m)",
    "y": " b. afire (m)"
  },
  {
    "x": "haredîyaya (m)",
    "y": " b. heridîyaya (m)"
  },
  {
    "x": "haredîyaye (n)",
    "y": " b. heridîyaye (n)"
  },
  {
    "x": "haredîyayê (m)",
    "y": " b. heridîyayîye (m)"
  },
  {
    "x": "haredîyayi (n)",
    "y": " b. heridîyaye (n)"
  },
  {
    "x": "haredîyayîye (m)",
    "y": " b. heridîyayîye (m)"
  },
  {
    "x": "harednayene",
    "y": " b. heridnayene"
  },
  {
    "x": "harednayîş (n)",
    "y": " b. heridnayîş (n)"
  },
  {
    "x": "haredyayene",
    "y": " b. heridîyayene"
  },
  {
    "x": "haredyayîş (n)",
    "y": " b. heridîyayîş (n)"
  },
  {
    "x": "hareket (n)",
    "y": " b. hereket (n)"
  },
  {
    "x": "harenîye (m)",
    "y": " b. haranîye (m)"
  },
  {
    "x": "harenîye era ci kewtene",
    "y": " b. haranîye era ci kewtene"
  },
  {
    "x": "harenîye era ser kewtene",
    "y": " b. haranîye era ser kewtene"
  },
  {
    "x": "harenîye kewtene",
    "y": " b. haranîye kewtene"
  },
  {
    "x": "harewan (n)",
    "y": " b. arêwan, -e"
  },
  {
    "x": "harewêş, -e",
    "y": " b. hargûş, -e"
  },
  {
    "x": "harey (m)",
    "y": " b. harîye (m)"
  },
  {
    "x": "hareye (n)",
    "y": " b. arê (n)"
  },
  {
    "x": "harê (m)",
    "y": " b. harîye (m)"
  },
  {
    "x": "harên (m)",
    "y": " b. haranîye (m)"
  },
  {
    "x": "harên era ci kewtene",
    "y": " b. haranîye era ci kewtene"
  },
  {
    "x": "harên era ser kewtene",
    "y": " b. haranîye era ser kewtene"
  },
  {
    "x": "harên kewtene",
    "y": " b. haranîye kewtene"
  },
  {
    "x": "harênî (m)",
    "y": " 1)kudurganlık\r\n~2)azgınlık"
  },
  {
    "x": "harênî era ci kewtene",
    "y": " b. haranîye era ci kewtene"
  },
  {
    "x": "harênî era ser kewtene",
    "y": " b. haranîye era ser kewtene"
  },
  {
    "x": "harênî kewtene",
    "y": " b. haranîye kewtene"
  },
  {
    "x": "harêwan (n)",
    "y": " b. arêwan, -e"
  },
  {
    "x": "harêweş, -e",
    "y": " b. hargûş, -e"
  },
  {
    "x": "hargûş, -e",
    "y": " tavşan"
  },
  {
    "x": "hari (n)",
    "y": " b. hare-I (n)"
  },
  {
    "x": "hari dayene",
    "y": " b. hare dayene"
  },
  {
    "x": "harî (m)",
    "y": " 1)kudurganlık, kudurma durumu\r\n~2)azgınlık"
  },
  {
    "x": "harîge (m)",
    "y": " nezle"
  },
  {
    "x": "harîqulade",
    "y": " olağanüstü, çılgın"
  },
  {
    "x": "harîye (m)",
    "y": " 1)kudurganlık, kudurma durumu\r\n~2)azgınlık"
  },
  {
    "x": "harme (n)",
    "y": " kol"
  },
  {
    "x": "harmeyo barî (n)",
    "y": " önkol"
  },
  {
    "x": "harmeyo qalind (n)",
    "y": " üstkol"
  },
  {
    "x": "harnak, -e",
    "y": " azgın"
  },
  {
    "x": "harnakî (m)",
    "y": " azgınlık"
  },
  {
    "x": "harolîg (n)",
    "y": " kösnük (koç, teke, öküz vb. erkek hayvanlar için)"
  },
  {
    "x": "harolîg bîyene",
    "y": " kösnümek (koç, teke vb. erkek hayvanlar için)  "
  },
  {
    "x": "harolîgbîyayîş (n)",
    "y": " kösnüme (koç, teke vb. erkek hayvanlar için)"
  },
  {
    "x": "harr (m)",
    "y": " b. arre (m)"
  },
  {
    "x": "harri (n)",
    "y": " b. hare-I (n)"
  },
  {
    "x": "harri dayene",
    "y": " b. hare dayene"
  },
  {
    "x": "harsime (m)",
    "y": " nezle"
  },
  {
    "x": "harûş, -e",
    "y": " b. hargûş, -e"
  },
  {
    "x": "hasa",
    "y": " b. heşa"
  },
  {
    "x": "hasa sima ra",
    "y": " b. heşa şima ra"
  },
  {
    "x": "has (n)",
    "y": " b. hes-I (n)"
  },
  {
    "x": "has kerdene",
    "y": " b. hes kerdene"
  },
  {
    "x": "hasab (n)",
    "y": " b. hesab (n)"
  },
  {
    "x": "hasad (n)",
    "y": " b. hesad (n)"
  },
  {
    "x": "hasayene",
    "y": " b. asayene"
  },
  {
    "x": "hasayîş (n)",
    "y": " b. asayîş-II (n)"
  },
  {
    "x": "hasil kerdene",
    "y": " 1)üretmek, imal etmek\r\n~2)sepilemek, tabaklamak"
  },
  {
    "x": "hasilat (n)",
    "y": " gelir, hâsılat, varidat"
  },
  {
    "x": "hasilato rojane",
    "y": " günlük gelir"
  },
  {
    "x": "hasilker, -e",
    "y": " üretici"
  },
  {
    "x": "hasilkerdiş (n)",
    "y": " 1)üretme, imal etme\r\n~2)sepileme"
  },
  {
    "x": "hasir (n)",
    "y": " b. hesîre (m)"
  },
  {
    "x": "haska (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "haskerdiş (n)",
    "y": " b. heskerdiş (n)"
  },
  {
    "x": "haskerdox, -e",
    "y": " b. heskerdox, -e "
  },
  {
    "x": "hast, -e",
    "y": " b. aşt, -e-II"
  },
  {
    "x": "hast bîyayêne",
    "y": " b. aşt bîyene"
  },
  {
    "x": "hast kerdene",
    "y": " b. aşt kerdene"
  },
  {
    "x": "hastbîyayîş (n)",
    "y": " b. aştbîyayîş (n)"
  },
  {
    "x": "hastî (m)",
    "y": " b. aştîye (m)"
  },
  {
    "x": "hastîye (m)",
    "y": " b. aştîye (m)"
  },
  {
    "x": "hastkerdiş (n)",
    "y": " b. aştkerdiş (n)"
  },
  {
    "x": "hasud, -e",
    "y": " b. hesûd, -e"
  },
  {
    "x": "hasudê (m)",
    "y": " b. hesûdî (m), hesûdîye (m)"
  },
  {
    "x": "hasudê kerdene",
    "y": " b. hesûdî kerdene"
  },
  {
    "x": "hasudî (m)",
    "y": " b. hesûdî (m), hesûdîye (m)"
  },
  {
    "x": "hasudî kerdene",
    "y": " b. hesûdî kerdene"
  },
  {
    "x": "haşme (m)",
    "y": " b. aşme (m)"
  },
  {
    "x": "haşt, -e",
    "y": " b. aşt, -e-II"
  },
  {
    "x": "haşt bîyayêne",
    "y": " b. aşt bîyene"
  },
  {
    "x": "haşt kerdene",
    "y": " b. aşt kerdene"
  },
  {
    "x": "haştbîyayîş (n)",
    "y": " b. aştbîyayîş (n)"
  },
  {
    "x": "haştîye (m)",
    "y": " b. aştîye (m)"
  },
  {
    "x": "haştkerdiş (n)",
    "y": " b. aştkerdiş (n)"
  },
  {
    "x": "hat (n)",
    "y": " b. het (n)"
  },
  {
    "x": "hata",
    "y": " b. heta"
  },
  {
    "x": "hatan",
    "y": " b. hetanî"
  },
  {
    "x": "hatike",
    "y": " belki"
  },
  {
    "x": "hatiki",
    "y": " b. hatike"
  },
  {
    "x": "haton",
    "y": " b. hetanî"
  },
  {
    "x": "haval, -e",
    "y": " b. heval, -e"
  },
  {
    "x": "Havana",
    "y": " Havana"
  },
  {
    "x": "avar de",
    "y": " b. vor de"
  },
  {
    "x": "havêlik (n)",
    "y": " süpürge"
  },
  {
    "x": "havîlik (n)",
    "y": " b. havêlik (n)"
  },
  {
    "x": "havîlîk (n)",
    "y": " b. havêlik (n)"
  },
  {
    "x": "havor de",
    "y": " b. vor de"
  },
  {
    "x": "havor ro",
    "y": " b. vor ro"
  },
  {
    "x": "haw-I (m, s)",
    "y": " b. a (m, s)"
  },
  {
    "x": "haw-II (n, s & z)",
    "y": " b. o (n, s & z) "
  },
  {
    "x": "haw-III",
    "y": " b. ha-I"
  },
  {
    "x": "haw-IV (m)",
    "y": " b. hew (m)"
  },
  {
    "x": "haw-haw (m)",
    "y": " b. hew-hewe (m)"
  },
  {
    "x": "hawa-I (n)",
    "y": " çeşit"
  },
  {
    "x": "hawayê ewnîyayîşî (n)",
    "y": " yaklaşım (ele alış veya bakış biçimi)"
  },
  {
    "x": "hawayê ewnîyayîşî yo dadmend (n)",
    "y": " adil yaklaşım"
  },
  {
    "x": "hawayê nîyadayîşî (n)",
    "y": " yaklaşım (ele alış veya bakış biçimi)"
  },
  {
    "x": "hawayê ... kerdene",
    "y": " taklit etmek"
  },
  {
    "x": "hawayê ... kerdiş",
    "y": " taklit etme"
  },
  {
    "x": "hawa-II (n)",
    "y": " b. hewa-I (n)"
  },
  {
    "x": "hawa dayene",
    "y": " b. hewa dayene "
  },
  {
    "x": "hawa nayene",
    "y": " b. hewa nayene"
  },
  {
    "x": "hawa-III",
    "y": " b. ha-I"
  },
  {
    "x": "hawadayîş (n)",
    "y": " b. hewadayîş (n)"
  },
  {
    "x": "hawar (m)",
    "y": " b. haware (m)"
  },
  {
    "x": "haware (m)",
    "y": " imdat, imdat çağrısı, medet, yardım çağrısı\r\n~“Çewlîg cayî nîyarûn o\r\n~\t\r\n~Derdo, derdo, hawar derdo” (Huseyîn Burke, Vate, Nr. 3\r\n~4)"
  },
  {
    "x": "hey hawar!",
    "y": " imdat, medet"
  },
  {
    "x": "hawayin, -i",
    "y": " b. hewayin, -e"
  },
  {
    "x": "hawayin bîyayene",
    "y": " b. hewayin bîyene"
  },
  {
    "x": "hawe (n)",
    "y": " b. hawa (n)"
  },
  {
    "x": "Hawêl (n)",
    "y": " b. Hewêl (n)"
  },
  {
    "x": "hawiz (m)",
    "y": " b. hewze (m)"
  },
  {
    "x": "hawl, -e",
    "y": " b. hol, -e"
  },
  {
    "x": "hawl bîyene",
    "y": " b. hol bîyene"
  },
  {
    "x": "hawlbîyayîş (n) ",
    "y": " b. holbîyayîş (n)"
  },
  {
    "x": "hawlkerdiş (n) ",
    "y": " b. holkerdiş-I (n)"
  },
  {
    "x": "hawn (n)",
    "y": " b. hewn (n)"
  },
  {
    "x": "hawnîyayîne",
    "y": " b. ewnîyayene"
  },
  {
    "x": "hawo-I",
    "y": " b. ha-I"
  },
  {
    "x": "hawo-II (n, s)",
    "y": " b. o (n, s)"
  },
  {
    "x": "hawt",
    "y": " b. hewt "
  },
  {
    "x": "hawtay",
    "y": " b. hewtay"
  },
  {
    "x": "hawte",
    "y": " b. hewtay"
  },
  {
    "x": "hawtê",
    "y": " b. hewtay"
  },
  {
    "x": "hawti (n)",
    "y": " b. hewte (n)"
  },
  {
    "x": "hawtin, -e",
    "y": " b. hewtin, -e"
  },
  {
    "x": "hawza (m)",
    "y": " b. hewza (m)"
  },
  {
    "x": "Hax (n)",
    "y": " b. Heq-II (n)"
  },
  {
    "x": "haxam (n)",
    "y": " haham"
  },
  {
    "x": "hay-I",
    "y": " 1)b. ha-I\r\n~2)yê-V"
  },
  {
    "x": "hay-II (zh, z)",
    "y": " b. ê-III (zh, z)"
  },
  {
    "x": "hay-III (n)",
    "y": " b. hing-I (n)"
  },
  {
    "x": "hay û heşeme",
    "y": " kargaşa"
  },
  {
    "x": "hay-huy (n)",
    "y": " gürültü patırtı, hengâme, şamata"
  },
  {
    "x": "haya-I",
    "y": " b. ha-I"
  },
  {
    "x": "haya-II (m, n)",
    "y": " b. hayîg, -e"
  },
  {
    "x": "haya bîyene",
    "y": " b. hayîg bîyene"
  },
  {
    "x": "hayabîyayîş (n)",
    "y": " b. hayîgbîyayîş (n)"
  },
  {
    "x": "haydar, -e",
    "y": " 1)haberdar\r\n~2)uyanık"
  },
  {
    "x": "haydar bîyene",
    "y": " haberdar olmak"
  },
  {
    "x": "haydar kerdene",
    "y": " 1)haberdar etmek, haber vermek\r\n~2)bildirmek, tebliğ etmek"
  },
  {
    "x": "haydarbîyayîş (n) ",
    "y": "farkında olma"
  },
  {
    "x": "haydarbîyene",
    "y": " farkında olmak"
  },
  {
    "x": "haydarî (m)",
    "y": " farkındalık"
  },
  {
    "x": "haydarîye (m)",
    "y": " farkındalık"
  },
  {
    "x": "haydarkerdiş (n)",
    "y": " 1)haberdar etme, haber verme\r\n~2)bildirme, bildirim, tebliğ, tebligat"
  },
  {
    "x": "hayderê",
    "y": " b. hadirê"
  },
  {
    "x": "haydê",
    "y": " b. hadê"
  },
  {
    "x": "haydî",
    "y": " b. hadê"
  },
  {
    "x": "haye (n)",
    "y": " b. hayîg, -e"
  },
  {
    "x": "haye bîyene",
    "y": " b. hayîg bîyene"
  },
  {
    "x": "hayebîyayîş (n)",
    "y": " b. hayîgbîyayîş (n)"
  },
  {
    "x": "hayedar, -i",
    "y": " b. haydar, -e"
  },
  {
    "x": "hayêdar, -e",
    "y": " b. haydar, -e"
  },
  {
    "x": "hayê",
    "y": " 1)b. ha-I\r\n~2)yê-V"
  },
  {
    "x": "hayêdar, -e",
    "y": " b. haydar, -e"
  },
  {
    "x": "hayig, -e",
    "y": " b. hayîg, -e"
  },
  {
    "x": "hayig bîyene",
    "y": " b. hayîg bîyene"
  },
  {
    "x": "hayigbîyayîş (n)",
    "y": " b. hayîgbîyayîş (n)"
  },
  {
    "x": "hayik, -e",
    "y": " b. hayîg, -e"
  },
  {
    "x": "hayik bîyene",
    "y": " b. hayîg bîyene"
  },
  {
    "x": "hayikbîyayîş (n)",
    "y": " b. hayîgbîyayîş (n)"
  },
  {
    "x": "hayî-I (m)",
    "y": " 1)haber\r\n~2)b. hayîg, -e"
  },
  {
    "x": "hayî ... bîyayîş",
    "y": " farkında olma"
  },
  {
    "x": "hayî ... bîyene",
    "y": " farkında olmak"
  },
  {
    "x": "hayî pê bîyene",
    "y": " farkında olmak"
  },
  {
    "x": "hayî bîyene",
    "y": " b. hayîg bîyene"
  },
  {
    "x": "hayî-II",
    "y": " 1)b. ha-I\r\n~2)yê-V"
  },
  {
    "x": "hayîbîyayîş (n)",
    "y": " b. hayîgbîyayîş (n)"
  },
  {
    "x": "hayîdar, -e",
    "y": " b. haydar, -e"
  },
  {
    "x": "hayîg, -e",
    "y": " 1)bidar, uyanık (uyumamış olan, sak)\r\n~2)b. haydar, -e"
  },
  {
    "x": "hayîg bîyene",
    "y": " uyanık olmak"
  },
  {
    "x": "hayîgbîyayîş (n)",
    "y": " uyanık olma"
  },
  {
    "x": "hayîgdar, -i",
    "y": " b. haydar, -e"
  },
  {
    "x": "hayîpêbîyayîş (n) ",
    "y": " farkında olma"
  },
  {
    "x": "hayîz (n)",
    "y": " aybaşı (kadın için), hayız"
  },
  {
    "x": "hayleme (n)",
    "y": " b. haylemin (n)"
  },
  {
    "x": "hayleme kerdene",
    "y": " b. haylemin kerdene"
  },
  {
    "x": "haylemê (n)",
    "y": " b. haylemin (n)"
  },
  {
    "x": "haylemin (n)",
    "y": " gürültü, gürültü patırtı, hengâme, kargaşa, şamata"
  },
  {
    "x": "haylemin kerdene",
    "y": " gürültü etmek"
  },
  {
    "x": "hayne (n)",
    "y": " b. eyne (n)"
  },
  {
    "x": "haynik (n)",
    "y": " b. eyne (n)"
  },
  {
    "x": "hayneyî (zh)",
    "y": " gözlük"
  },
  {
    "x": "hayo",
    "y": " fiilin şimdiki zamanında kullanılan bir partikel"
  },
  {
    "x": "hayon",
    "y": " b. hetanî"
  },
  {
    "x": "hayonî",
    "y": " b. hetanî"
  },
  {
    "x": "hayran, -e",
    "y": " b. heyran, -e"
  },
  {
    "x": "hayûhuy (n)",
    "y": " gürültü patırtı, hengâme, şamata"
  },
  {
    "x": "haywan, -e",
    "y": " b. heywan, -e"
  },
  {
    "x": "haz (n)",
    "y": " b. hes-I (n)"
  },
  {
    "x": "haz kerdene",
    "y": " b. hes kerdene"
  },
  {
    "x": "haza (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "hazar",
    "y": " b. hezar "
  },
  {
    "x": "hazare (n)",
    "y": " b. hezare (n)"
  },
  {
    "x": "hazet (n)",
    "y": " 1)b. hacet (n)\r\n~2)b. hacete (m)"
  },
  {
    "x": "hazir, -e",
    "y": " hazır, amade"
  },
  {
    "x": "hazir bîyene",
    "y": " hazır olmak"
  },
  {
    "x": "hazir kerdene",
    "y": " hazırlamak, düzenlemek, tertip etmek, tertiplemek"
  },
  {
    "x": "hazirî (m)",
    "y": " hazırlık"
  },
  {
    "x": "hazirkar, -e",
    "y": " düzenleyici, tertipleyici"
  },
  {
    "x": "hazirkerdiş (n)",
    "y": " hazırlama, düzenleme, tertip etme, tertipleme"
  },
  {
    "x": "hazirkerdox, -e",
    "y": " hazırlayan"
  },
  {
    "x": "hazor",
    "y": " b. hezar"
  },
  {
    "x": "hazot (n)",
    "y": " 1)b. hacet (n)\r\n~2)b. hacete (m)"
  },
  {
    "x": "hazret",
    "y": " b. hezret"
  },
  {
    "x": "he",
    "y": " b. hela"
  },
  {
    "x": "he w’",
    "y": " b. ha ya, ha yo"
  },
  {
    "x": "he y’",
    "y": " b. ha ya, ha yo"
  },
  {
    "x": "heb-I (n)",
    "y": " 1)tahıl, hububat\r\n~2)dövme (dövülerek kabuğu çıkarılmış buğday)"
  },
  {
    "x": "heb-II (m)",
    "y": " b. hebe-I (m)"
  },
  {
    "x": "hebe-I (m)",
    "y": " tane, adet"
  },
  {
    "x": "hebe bi hebe",
    "y": " tane tane"
  },
  {
    "x": "hebek, -e",
    "y": " b. hebêk, -e"
  },
  {
    "x": "hebê",
    "y": " 1)bir tane\r\n~2)biraz\r\n~3) b. tayê"
  },
  {
    "x": "hebêk, -e",
    "y": " bir tane"
  },
  {
    "x": "hebe-II",
    "y": " b. bi"
  },
  {
    "x": "hebey",
    "y": " b. hebê"
  },
  {
    "x": "hebê",
    "y": " 1)bir tane\r\n~2)biraz\r\n~3)b. tayê"
  },
  {
    "x": "hebi (m)",
    "y": " b. hebe-I (m)"
  },
  {
    "x": "hebikî (zh)",
    "y": " 1)hedik\r\n~2)yenmekte veya bulgur yapmakta kullanılan kaynatılmış buğday"
  },
  {
    "x": "hebîk (zh)",
    "y": " b. hebikî (zh)"
  },
  {
    "x": "hebsîya (n)",
    "y": " çörek otu, çöre otu"
  },
  {
    "x": "hebûbe (m)",
    "y": " üzüm tanesi"
  },
  {
    "x": "hec (n)",
    "y": " hac"
  },
  {
    "x": "heca (m)",
    "y": " b. hece (m)"
  },
  {
    "x": "hecacî (zh)",
    "y": " hacac"
  },
  {
    "x": "hece (m)",
    "y": " hece"
  },
  {
    "x": "hecete (m)",
    "y": " b. hacete (m)"
  },
  {
    "x": "hechecike (m)",
    "y": " kırlangıç"
  },
  {
    "x": "heciz (n)",
    "y": " haciz"
  },
  {
    "x": "hecî, -ya",
    "y": " hacı"
  },
  {
    "x": "hecîre (m)",
    "y": " b. hêjîre (m)"
  },
  {
    "x": "hecîya (m)",
    "y": " hacı"
  },
  {
    "x": "hecm (n)",
    "y": " hacim"
  },
  {
    "x": "hecmê depokerdişî (n)",
    "y": " depolama hacmi"
  },
  {
    "x": "hecmê pêserkerdişî (n)",
    "y": " depolama hacmi"
  },
  {
    "x": "hecuc, -e",
    "y": " cüce"
  },
  {
    "x": "hecuc mecuc",
    "y": " cüceler"
  },
  {
    "x": "hecucîye (m)",
    "y": " cücelik"
  },
  {
    "x": "hecûn",
    "y": " öyle"
  },
  {
    "x": "hecz (n)",
    "y": " b. heciz (n)"
  },
  {
    "x": "heçe (m)",
    "y": " b. hîç"
  },
  {
    "x": "hed-I (n)",
    "y": " sınır"
  },
  {
    "x": "hed-II (n)",
    "y": " mezar taşı"
  },
  {
    "x": "hedd (n)",
    "y": " b. hed-I (n)"
  },
  {
    "x": "hedef (n)",
    "y": " hedef, amaç, erek"
  },
  {
    "x": "hedefê projeyî (n)",
    "y": " proje hedefi"
  },
  {
    "x": "hedel (n)",
    "y": " b. atile (n)"
  },
  {
    "x": "hedele (m)",
    "y": " b. atile (n)"
  },
  {
    "x": "hedelîyayîş (n)",
    "y": " b. edilîyayîş (n)"
  },
  {
    "x": "hedernayene",
    "y": " b. hazir kerdene"
  },
  {
    "x": "hedernayîş (n)",
    "y": " b. hazirkerdiş (n)"
  },
  {
    "x": "hedênayene",
    "y": " bastırmak (dindirmek)"
  },
  {
    "x": "hedênayîş (n)",
    "y": " bastırma (dindirme)"
  },
  {
    "x": "hedikî (zh)",
    "y": " 1)hedik\r\n~2)yenmekte veya bulgur yapmakta kullanılan kaynatılmış buğday"
  },
  {
    "x": "hedil (n)",
    "y": " b. atile (n)"
  },
  {
    "x": "hedile-I (m)",
    "y": " b. atile (n) "
  },
  {
    "x": "hedile-II (n)",
    "y": " b. atile (n)"
  },
  {
    "x": "hedir, -e",
    "y": " b. hazir, -e"
  },
  {
    "x": "hedire (m)",
    "y": " dışlık"
  },
  {
    "x": "hedira ... ameyene",
    "y": " dışlığı gelmek"
  },
  {
    "x": "hedirî (m)",
    "y": " b. hazirî (m)"
  },
  {
    "x": "hedirnayene",
    "y": " b. hazir kerdene"
  },
  {
    "x": "hedirnayîş (n)",
    "y": " b. hazirkerdiş (n)"
  },
  {
    "x": "hedî-hedî",
    "y": " b. hêdî-hêdî"
  },
  {
    "x": "hedîgar, -e",
    "y": " b. yadîgar, -e"
  },
  {
    "x": "hedîsa (m)",
    "y": " b. hedîse (n)"
  },
  {
    "x": "hedîse (n)",
    "y": " olay, hadise, fenomen"
  },
  {
    "x": "hedîseyê gemarinîya hewayî (n)",
    "y": " hava kirliliği olayı"
  },
  {
    "x": "hedîseyê hewanasîye (zh)",
    "y": " meteorolojik olaylar"
  },
  {
    "x": "hedîseyê meteorolojîkî (zh)",
    "y": " meteorolojik olaylar"
  },
  {
    "x": "hedîyayene",
    "y": " (ağrı vb. için) durmak"
  },
  {
    "x": "hedîyayîş (n)",
    "y": " (ağrı vb. için) durma"
  },
  {
    "x": "hedra (n)",
    "y": " b. hazire (m)"
  },
  {
    "x": "hedravîyarta (m)",
    "y": " 1)aşırı\r\n~2)çılgın"
  },
  {
    "x": "hedravîyarte (n)",
    "y": "1)aşırı\r\n~2)çılgın"
  },
  {
    "x": "hedravîyarteyîye (m)",
    "y": " çılgınlık"
  },
  {
    "x": "hedravîyartîye (m)",
    "y": "1)aşırı\r\n~2)çılgın"
  },
  {
    "x": "hedre (n)",
    "y": " b. hazir (n)"
  },
  {
    "x": "hedre bîyene",
    "y": " b. hazir bîyene"
  },
  {
    "x": "hedre kerdene",
    "y": " b. hazir kerdene"
  },
  {
    "x": "hedri (n)",
    "y": " b. hazir (n)"
  },
  {
    "x": "hedri bîyene",
    "y": " b. hazir bîyene"
  },
  {
    "x": "hedri kerdene",
    "y": " b. hazir kerdene"
  },
  {
    "x": "hedure (m)",
    "y": " b. hedire"
  },
  {
    "x": "hedura ... ameyîne",
    "y": " b. hedira ... ameyene"
  },
  {
    "x": "heewş",
    "y": " b. hooş"
  },
  {
    "x": "hefeknayîne",
    "y": " b. hefiknayene"
  },
  {
    "x": "hefiknayene",
    "y": " ezmek (üzüm vb. için)"
  },
  {
    "x": "hefiknayîş (n)",
    "y": " ezme"
  },
  {
    "x": "hefil (n)",
    "y": " küspe"
  },
  {
    "x": "hefilnayene",
    "y": " ezmek (üzüm vb. için)"
  },
  {
    "x": "hefilnayîş (n)",
    "y": " ezme"
  },
  {
    "x": "hefirnayîne",
    "y": " b. hefilnayene"
  },
  {
    "x": "hefis (n)",
    "y": " b. hepis (n)"
  },
  {
    "x": "hefisxane (n)",
    "y": " b. hepisxane (n)"
  },
  {
    "x": "hefl (n)",
    "y": " b. hefil (n)"
  },
  {
    "x": "hefle (n)",
    "y": " b. hefil (n)"
  },
  {
    "x": "hefrîyat (n)",
    "y": " hafriyat, kazı"
  },
  {
    "x": "hefs (n)",
    "y": " b. hepis (n)"
  },
  {
    "x": "hefsar (n)",
    "y": " b. hevsar (n)"
  },
  {
    "x": "hefsî",
    "y": " b. hepsî, -ye"
  },
  {
    "x": "hefsîya (n)",
    "y": " b. hebsîya (n)"
  },
  {
    "x": "hefsxane (n)",
    "y": " b. hepisxane (n)"
  },
  {
    "x": "hefte (n)",
    "y": " b. hewte (n)"
  },
  {
    "x": "hefter, -e",
    "y": " yorgun"
  },
  {
    "x": "hefter bîyayene",
    "y": " b. hefter bîyene"
  },
  {
    "x": "hefter bîyene",
    "y": " yorulmak"
  },
  {
    "x": "hefter kerdene",
    "y": " yormak"
  },
  {
    "x": "hefterbîyayîş (n)",
    "y": " yorulma"
  },
  {
    "x": "hefterî (m)",
    "y": " yorgunluk"
  },
  {
    "x": "hefterîye (m)",
    "y": " yorgunluk"
  },
  {
    "x": "hefterkerdiş (n)",
    "y": " yorma"
  },
  {
    "x": "hege",
    "y": " b. eger"
  },
  {
    "x": "heger",
    "y": " b. eger"
  },
  {
    "x": "hegmên (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "hej (n)",
    "y": " b. hec (n)"
  },
  {
    "x": "heje (m)",
    "y": " çalı veya dal parçası (kesilmiş ve yapraksız)"
  },
  {
    "x": "hejhejik (m)",
    "y": " b. hechecike (m)"
  },
  {
    "x": "hejhejiki (m)",
    "y": " b. hechecike (m)"
  },
  {
    "x": "hejike (m)",
    "y": " çalı veya dal parçası (kesilmiş ve yapraksız)"
  },
  {
    "x": "hejikî (zh)",
    "y": " çalı çırpı"
  },
  {
    "x": "hejîre (m)",
    "y": " b. hêjîre (m)"
  },
  {
    "x": "hek (m)",
    "y": " b. heke (m)"
  },
  {
    "x": "hekat (m)",
    "y": " b. hîkaye (m)"
  },
  {
    "x": "heke-I (n)",
    "y": " 1)erkek keklik\r\n~2)horoz"
  },
  {
    "x": "heke-II (m)",
    "y": " abla"
  },
  {
    "x": "hekê",
    "y": " abla (hitap hali)"
  },
  {
    "x": "hekem, -e",
    "y": " hakem, yargıcı"
  },
  {
    "x": "hekema kîşte (m)",
    "y": " yan hakem"
  },
  {
    "x": "hekemê kîşte (n)",
    "y": " yan hakem"
  },
  {
    "x": "hekey (m)",
    "y": " b. hekêyî (m)"
  },
  {
    "x": "hekê (m)",
    "y": " b. hekêyî (m)"
  },
  {
    "x": "hekêyî (m)",
    "y": " ablalık"
  },
  {
    "x": "heki-I (n)",
    "y": " b. heke (n)"
  },
  {
    "x": "heki-II",
    "y": " b. eke-I"
  },
  {
    "x": "hekin (n)",
    "y": " b. ekin (n)"
  },
  {
    "x": "hekîm, -e",
    "y": " doktor, hekim, tabip, sağaltman"
  },
  {
    "x": "hekîma keyeyî (m)",
    "y": " aile doktoru, aile hekimi"
  },
  {
    "x": "hekîmê keyeyî (n)",
    "y": " aile doktoru, aile hekimi"
  },
  {
    "x": "hekmete",
    "y": " çok fazla, diz boyu (çok miktarda)"
  },
  {
    "x": "hekmete kewtene",
    "y": " şaşakalmak, bakakalmak, ağzı açık kalmak, ağzı bir karış açık kalmak"
  },
  {
    "x": "hekmete kotene",
    "y": " b. hekmete kewtene"
  },
  {
    "x": "yew hekmete",
    "y": " bir hayli"
  },
  {
    "x": "hekmetekewtiş (n)",
    "y": " şaşakalma, bakakalma"
  },
  {
    "x": "hekmeti",
    "y": " b. hekmete"
  },
  {
    "x": "hektar (n)",
    "y": " hektar"
  },
  {
    "x": "hektolître (n)",
    "y": " hektolitre"
  },
  {
    "x": "hektometre (n)",
    "y": " hektometre"
  },
  {
    "x": "hektometrekare (n)",
    "y": " hektometrekare"
  },
  {
    "x": "hektometremîkab (n)",
    "y": " hektometreküp"
  },
  {
    "x": "hel-I (n)",
    "y": " çözüm, hal"
  },
  {
    "x": "hel bîyene",
    "y": " çözülmek (sorun vb. için)"
  },
  {
    "x": "hel kerdene",
    "y": " 1)çözmek (sorun vb. için), halletmek\r\n~2)becermek, üstesinden gelmek"
  },
  {
    "x": "hel-II",
    "y": " (tavuk veya kuşları kovmak için) kış"
  },
  {
    "x": "hel hel",
    "y": " (tavuk veya kuşları kovmal için) kış"
  },
  {
    "x": "hel-III (m)",
    "y": " hele-I (m)"
  },
  {
    "x": "hel-IV (m)",
    "y": " hele-II (m)"
  },
  {
    "x": "hel di",
    "y": " b. hele de"
  },
  {
    "x": "helê sibay (m)",
    "y": " b. hela sibayî (m)"
  },
  {
    "x": "helê şûnî (m)",
    "y": " b. hela şanî (m)"
  },
  {
    "x": "hela",
    "y": " hele\r\n~Hela bê tîya. (Hele buraya gel.)"
  },
  {
    "x": "helahela (m)",
    "y": " gürültü patırtı, hengâme, şamata, cümbüş (gürültüsü patırtısı olan)"
  },
  {
    "x": "helak bîyayîne",
    "y": " b. helak bîyene"
  },
  {
    "x": "helak bîyene",
    "y": " ağır hasta olmak"
  },
  {
    "x": "helal, -e",
    "y": " helal"
  },
  {
    "x": "helalê/a canî",
    "y": " can dostu, can arkadaşı"
  },
  {
    "x": "helalê/a ganî",
    "y": " can dostu, can arkadaşı\r\n~“Wilay şinî dayk û bawkê ma ra vonî\r\n~\t\r\n~“Yew verên o, di peyen î\r\n~\t\r\n~Ayo verên helalê gandê min o” (Deyîra “Xerîbê”)"
  },
  {
    "x": "helasorênî (m)",
    "y": " azgınlık (hayvan için cinsel anlamda)"
  },
  {
    "x": "helasorî, -ye",
    "y": " (hayvan için cinsel anlamda) azgın  "
  },
  {
    "x": "helasorî bîyene",
    "y": " azgınlaşmak, azmak (hayvan için cinsel anlamda) "
  },
  {
    "x": "helasorîbîyayîş (n)",
    "y": " azgınlaşma (hayvan için cinsel anlamda)"
  },
  {
    "x": "helasorîyîye (m)",
    "y": " azgınlık (hayvan için cinsel anlamda)"
  },
  {
    "x": "helaw (m)",
    "y": " 1)b. helwa (m)\r\n~2)b. helawî (m)\r\n~3)b. helawe (m)"
  },
  {
    "x": "helawê dimsî (m)",
    "y": " b. helawa dimsî (m)"
  },
  {
    "x": "helawe (m)",
    "y": " 1)b. helwa (m)\r\n~2)pekmez"
  },
  {
    "x": "helawa difsî (m)",
    "y": " b. helawa dimsî (m)"
  },
  {
    "x": "helawa dimsî (m)",
    "y": " kavrulmuş un ile yağ ve pekmezden yapılan bir çeşit helva"
  },
  {
    "x": "helawa huşke (m)",
    "y": " çalma (koyu pekmez)"
  },
  {
    "x": "helawa ribî (m)",
    "y": " kavrulmuş un ile yağ ve pekmezden yapılan bir çeşit helva"
  },
  {
    "x": "helawa wişki (m)",
    "y": " b. helawa huşke (m)"
  },
  {
    "x": "helawi (m)",
    "y": " 1)b. helwa (m)\r\n~2)b. helawî (m)\r\n~3)b. helawe (m)"
  },
  {
    "x": "helawin, -e (s)",
    "y": " 1)tatlı\r\n~2)pekmezli"
  },
  {
    "x": "helawî (m)",
    "y": " 1)pekmez ve undan yapılan bir çeşit helva\r\n~2)kavrulmuş un ile yağ, pekmez veya şekerden yapılan bir çeşit helva"
  },
  {
    "x": "helayîş (n)",
    "y": " b. helîyayîş (n)"
  },
  {
    "x": "helayîye (m)",
    "y": " b. alaye (m)"
  },
  {
    "x": "helba (m)",
    "y": " b. helwa (m)"
  },
  {
    "x": "helbe (n)",
    "y": " b. elbe (m)"
  },
  {
    "x": "helbet",
    "y": " elbet, elbette"
  },
  {
    "x": "helbike (m)",
    "y": " b. elbike (m)"
  },
  {
    "x": "helbisim (n)",
    "y": " b. birîsim (n)"
  },
  {
    "x": "helbisimên, -i",
    "y": " b. birîsimên, -e"
  },
  {
    "x": "helbisimin, -i",
    "y": " b. birîsimin, -e"
  },
  {
    "x": "helbismin, -i",
    "y": " b. birîsimin, -e"
  },
  {
    "x": "helbî (n)",
    "y": " b. helebî (n)"
  },
  {
    "x": "helbîyayîş (n)",
    "y": " çözülme (sorun vb. için)"
  },
  {
    "x": "hele-I (m)",
    "y": " an, lahza"
  },
  {
    "x": "hele-II (m)",
    "y": " öğle"
  },
  {
    "x": "hela êreyî (m)",
    "y": " öğleden sonra"
  },
  {
    "x": "hela nimajî (m)",
    "y": " öğleden önce"
  },
  {
    "x": "hela sibayî (m)",
    "y": " öğleden önce"
  },
  {
    "x": "hela şanî (m)",
    "y": " öğleden sonra"
  },
  {
    "x": "hela şewray (m)",
    "y": " b. hela şewrayî (m)"
  },
  {
    "x": "hela şewrayî (m)",
    "y": " öğleden önce"
  },
  {
    "x": "hela yerî (m)",
    "y": " b. hela êreyî (m)"
  },
  {
    "x": "hele de",
    "y": " öğleyin (öğle vakti)"
  },
  {
    "x": "hele-III (n)",
    "y": " tünek"
  },
  {
    "x": "hele-IV",
    "y": " b. hela"
  },
  {
    "x": "helebî (n)",
    "y": " halebi (70 santimetrelik ölçü birimi)"
  },
  {
    "x": "helenco (n)",
    "y": " b. hêlaneke (m)"
  },
  {
    "x": "heleqa (m)",
    "y": " b. helqa (m)"
  },
  {
    "x": "helesîyaya (m)",
    "y": " b. helisîyaya (m)"
  },
  {
    "x": "helesîyaye (n)",
    "y": " b. helisîyaye (n)"
  },
  {
    "x": "helesîyayene",
    "y": " b. helisîyayene"
  },
  {
    "x": "helesîyayeyî (m)",
    "y": " helisîyayeyî (m)"
  },
  {
    "x": "helesîyayê (m)",
    "y": " b. helisîyayî, -ye"
  },
  {
    "x": "helesîyayî, -ye",
    "y": " b. helisîyayî, -ye"
  },
  {
    "x": "helesîyayîş (n)",
    "y": " b. helisîyayîş (n)"
  },
  {
    "x": "helesnayene",
    "y": " b. helisnayene"
  },
  {
    "x": "helesnayîş (n)",
    "y": " b. helisnayîş (n)"
  },
  {
    "x": "helet (m)",
    "y": " b. alete (m)"
  },
  {
    "x": "helewî (m)",
    "y": " b. helawî (m)"
  },
  {
    "x": "heleyayene",
    "y": " b. helîyayene"
  },
  {
    "x": "heleyayîş (n)",
    "y": " b. helîyayîş (n)"
  },
  {
    "x": "heleyîş (n)",
    "y": " b. helîyayîş (n)"
  },
  {
    "x": "helênayene",
    "y": " eritmek"
  },
  {
    "x": "helênayîş (n)",
    "y": " eritme"
  },
  {
    "x": "helfe (n)",
    "y": " b. hefil (n)"
  },
  {
    "x": "helhele (n)",
    "y": " kötü koku"
  },
  {
    "x": "heli-I (m)",
    "y": " b. hele-I (m)"
  },
  {
    "x": "heli-II (m)",
    "y": " b. hele-II (m)"
  },
  {
    "x": "heley nimacî (m)",
    "y": " b. hela nimajî (m)"
  },
  {
    "x": "heley sibay (m)",
    "y": " b. hela sibayî (m)"
  },
  {
    "x": "heley şonî (m)",
    "y": " b. hela şanî (m)"
  },
  {
    "x": "heley şûnî (m)",
    "y": " b. hela şanî (m)"
  },
  {
    "x": "heley yerî (m)",
    "y": " b. hela êreyî (m)"
  },
  {
    "x": "heli di",
    "y": " b. hele de"
  },
  {
    "x": "heli-III (n)",
    "y": " b. hele-III (n)"
  },
  {
    "x": "helige (m)",
    "y": " b. hêluge (m)"
  },
  {
    "x": "helik (n)",
    "y": " b. hêlik (n)"
  },
  {
    "x": "helind (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "helisîyaya (m)",
    "y": " çürük (sebze vb. için)"
  },
  {
    "x": "helisîyaye (n)",
    "y": " b. helesîyaya (m)"
  },
  {
    "x": "helisîyayene",
    "y": " çürümek (sebze vb. için)"
  },
  {
    "x": "helisîyayeyî (m)",
    "y": " çürüklük (sebze vb. için)"
  },
  {
    "x": "helisîyayî, -ye",
    "y": " çürük (sebze vb. için)"
  },
  {
    "x": "helisîyayîş (n)",
    "y": " çürüme (sebze vb. için)"
  },
  {
    "x": "helisnayene",
    "y": " çürütmek (sebze vb. için)"
  },
  {
    "x": "helisnayîş (n)",
    "y": " çürütme (sebze vb. için)"
  },
  {
    "x": "helî (n)",
    "y": " kartal"
  },
  {
    "x": "helîyo çal (n)",
    "y": " şahin"
  },
  {
    "x": "helîyo çol (n)",
    "y": " b. helîyo çal (n)"
  },
  {
    "x": "helîkoptere (m)",
    "y": " helikopter"
  },
  {
    "x": "helîkopteri (m)",
    "y": " b. helîkoptere (m)"
  },
  {
    "x": "helîle (m)",
    "y": " b. herîre (m)"
  },
  {
    "x": "helîli (m)",
    "y": " b. herîre (m)"
  },
  {
    "x": "helîm-I (n)",
    "y": " b. halîn (n)"
  },
  {
    "x": "helîm-II (n)",
    "y": " kaymak"
  },
  {
    "x": "helîqoptere (m)",
    "y": " b. helîkoptere (m)"
  },
  {
    "x": "helîqopteri (m)",
    "y": " b. helîkoptere (m)"
  },
  {
    "x": "helîr (m)",
    "y": " b. herîre (m)"
  },
  {
    "x": "helîre (m)",
    "y": " b. herîre (m)"
  },
  {
    "x": "helîri (m)",
    "y": " b. herîre (m)"
  },
  {
    "x": "helîs (m)",
    "y": " b. helîsa (m)"
  },
  {
    "x": "helîsa (m)",
    "y": " 1)közlenip soyulmuş patlıcan(ın) ezmesinin yağda kızartılmış yumurta ve ezilmiş sarmısağa karıştırılmasıyla yapılan bir yemek\r\n~2)su ve undan yapılan, içine yağ ve pekmez katılarak da yenebilen bulamaç"
  },
  {
    "x": "helîse (m)",
    "y": " b. helîsa (m)"
  },
  {
    "x": "helîyayene",
    "y": " erimek"
  },
  {
    "x": "helîyayîş (n)",
    "y": " erime"
  },
  {
    "x": "helke (n)",
    "y": " hilke (n)"
  },
  {
    "x": "helkerdiş (n)",
    "y": " 1)çözme (sorun vb. için), hal\r\n~2)becerme, üstesinden gelme"
  },
  {
    "x": "helkerdişê çermî (n)",
    "y": " soyum, peeling"
  },
  {
    "x": "hella hella!",
    "y": " Allah Allah!"
  },
  {
    "x": "hellahella (m)",
    "y": " b. helahela (m)"
  },
  {
    "x": "helle helle!",
    "y": " b. hella hella!"
  },
  {
    "x": "helm (n)",
    "y": " 1)koku\r\n~2)nefes, soluk"
  },
  {
    "x": "helmele (n)",
    "y": " b. hermele (n)"
  },
  {
    "x": "helmele kerdene",
    "y": " b. hermele kerdene"
  },
  {
    "x": "helnayene",
    "y": " b. helênayene"
  },
  {
    "x": "helnayîş (n)",
    "y": " b. helênayîş (n) "
  },
  {
    "x": "helo (m)",
    "y": " b. helawe (m)"
  },
  {
    "x": "helow (m)",
    "y": " b. helawe (m)"
  },
  {
    "x": "helpez (n)",
    "y": " 1)dalak\r\n~2)etene, meşime, son "
  },
  {
    "x": "helq (n)",
    "y": " kök, ağaç kökü"
  },
  {
    "x": "helqa (m)",
    "y": " 1)halka\r\n~2)nişan yüzüğü, alyans"
  },
  {
    "x": "helqaya xelaskere (m)",
    "y": " cankurtaran simidi, can simidi"
  },
  {
    "x": "helquşte (n)",
    "y": " tahterevalli, çöğüncek"
  },
  {
    "x": "Helsînkî",
    "y": " Helsinki"
  },
  {
    "x": "heluge (m)",
    "y": " b. hêluge (m)"
  },
  {
    "x": "helvet",
    "y": " b. helbet"
  },
  {
    "x": "helwa (m)",
    "y": " helva"
  },
  {
    "x": "helwaya tahînî (m)",
    "y": " tahin helvası"
  },
  {
    "x": "helwêst (n)",
    "y": " tutum"
  },
  {
    "x": "helyayene",
    "y": " b. helîyayene"
  },
  {
    "x": "helyayîş (n)",
    "y": " b. helîyayîş (n)"
  },
  {
    "x": "helyayîş (n)",
    "y": " b. helîyayîş (n)"
  },
  {
    "x": "hem-I",
    "y": " hem"
  },
  {
    "x": "hem ... hem ...",
    "y": " hem ... hem …\r\n~Hem şina hem bermena. (Hem gider, hem ağlar.)\r\n~"
  },
  {
    "x": "hem-II (m)",
    "y": " b. heme-II (m)"
  },
  {
    "x": "hem kerdene",
    "y": " b. heme kerdene"
  },
  {
    "x": "hem-III",
    "y": " b. hema-I"
  },
  {
    "x": "hema-I",
    "y": " 1)henüz, hâlâ\r\n~2)hemen"
  },
  {
    "x": "hema ra",
    "y": " b. hema-1"
  },
  {
    "x": "hema-hema",
    "y": " 1)aşağı yukarı, yaklaşık, takriben, yaklaşık olarak, takribî, hemen hemen\r\n~2)bayağı (neredeyse)"
  },
  {
    "x": "hema-hema ke",
    "y": " aşağı yukarı, yaklaşık, takriben, yaklaşık olarak, takribî, hemen hemen"
  },
  {
    "x": "hema-II",
    "y": " b. labelê, la"
  },
  {
    "x": "hemahengî (m)",
    "y": " eşgüdüm, koordinasyon"
  },
  {
    "x": "hemal, -e",
    "y": " hamal"
  },
  {
    "x": "hemali (n)",
    "y": " b. hemalî (n)"
  },
  {
    "x": "hemalî (n)",
    "y": " hamaylı, hamail"
  },
  {
    "x": "hemam (n)",
    "y": " hamam"
  },
  {
    "x": "hemamdar, -e",
    "y": " hamamcı"
  },
  {
    "x": "hemame (m)",
    "y": " b. hemam (n)"
  },
  {
    "x": "hemamek (n)",
    "y": " banyo (banyo yeri)"
  },
  {
    "x": "hemami (m)",
    "y": " b. hemam (n)"
  },
  {
    "x": "hembaz",
    "y": " b. embaz"
  },
  {
    "x": "hember, -e",
    "y": " 1)denk\r\n~2)yaşıt\r\n~3)karşı "
  },
  {
    "x": "hemberê ... de",
    "y": " karşısında"
  },
  {
    "x": "hemberî (m)",
    "y": " çekişme, rekabet"
  },
  {
    "x": "hemberî kerdene",
    "y": " çekişmek, rekabet etmek"
  },
  {
    "x": "hemberîkerdiş (n)",
    "y": " çekişme, rekabet etme"
  },
  {
    "x": "hemberîye (m)",
    "y": " denklik"
  },
  {
    "x": "hemdem, -e",
    "y": " çağdaş, asri, muasır, çağcıl, modern, muasır"
  },
  {
    "x": "hemdem bîyene",
    "y": " çağcıllaşmak, çağdaşlaşmak, asrileşmek, muasırlaşmak, modernleşmek"
  },
  {
    "x": "hemdem kerdene",
    "y": " çağcıllaştırmak, asrileştirmek, çağdaşlaştırmak, modernleştirmek, muasırlaştırmak"
  },
  {
    "x": "hemdemî (m)",
    "y": " çağcıllık, çağdaşlık, asrilik, modernlik, modernizm, muasırlık"
  },
  {
    "x": "hemdemîye (m)",
    "y": " çağcıllık, çağdaşlık, asrilik, modernlik, modernizm, muasırlık"
  },
  {
    "x": "hemdîn, -e",
    "y": " dindaş"
  },
  {
    "x": "hemdînî (m)",
    "y": " dindaşlık"
  },
  {
    "x": "hemdînîye (m)",
    "y": " dindaşlık"
  },
  {
    "x": "heme-I",
    "y": " 1)bütün, hep, hepsi, tüm, tümü\r\n~2)her"
  },
  {
    "x": "heme çar",
    "y": " her dört"
  },
  {
    "x": "heme çarine",
    "y": " her dördü"
  },
  {
    "x": "heme çaxan de",
    "y": " çağlar boyu"
  },
  {
    "x": "heme dewran de",
    "y": " çağlar boyu"
  },
  {
    "x": "heme grafîkî (zh)",
    "y": " tüm grafikler"
  },
  {
    "x": "heme hedîseyî",
    "y": " tüm olaylar"
  },
  {
    "x": "heme hîrê",
    "y": " her üç"
  },
  {
    "x": "heme hîrine",
    "y": " her üçü"
  },
  {
    "x": "heme kes",
    "y": " herkes"
  },
  {
    "x": "heme panc",
    "y": " her beş"
  },
  {
    "x": "heme pancine",
    "y": " her beşi"
  },
  {
    "x": "heme pîya",
    "y": " hep birlikte, tümü birlikte"
  },
  {
    "x": "heme serebûtî",
    "y": " tüm olaylar"
  },
  {
    "x": "heme têreyde",
    "y": " hep birlikte, tümü birlikte"
  },
  {
    "x": "heme têreydi",
    "y": " b. heme têreyde"
  },
  {
    "x": "heme têreyra",
    "y": " hep birlikte, tümü birlikte"
  },
  {
    "x": "heme-II (m) (çocuk dilinde)",
    "y": " ham, mama, yemek, yiyecek"
  },
  {
    "x": "heme kerdene (çocuk dilinde)",
    "y": " ham etmek, yemek"
  },
  {
    "x": "hemeg (n)",
    "y": " emeg (n)"
  },
  {
    "x": "hemegdar, -e",
    "y": " emegdar, -e"
  },
  {
    "x": "hemekî",
    "y": " toptan"
  },
  {
    "x": "hemelnayene",
    "y": " b. hemilnayene"
  },
  {
    "x": "hemelnayîş (n)",
    "y": " b. hemilnayîş (n)"
  },
  {
    "x": "hemerje (m)",
    "y": " eşdeğer, muadil"
  },
  {
    "x": "hemerjin, -e",
    "y": " eşdeğerli"
  },
  {
    "x": "hemerjîye (m)",
    "y": " eşdeğerlik, muadelet"
  },
  {
    "x": "hemet bîyene",
    "y": " korunmak  "
  },
  {
    "x": "hemet kerdene",
    "y": " korumak, muhafaza etmek"
  },
  {
    "x": "hemetbîyayîş (n)",
    "y": " korunma"
  },
  {
    "x": "hemetkerdiş (n)",
    "y": " 1)koruma, muhafaza etme\r\n~2)gözetim"
  },
  {
    "x": "hemeyî",
    "y": " genel, umumi"
  },
  {
    "x": "hemêg (n)",
    "y": " emeg (n)"
  },
  {
    "x": "hemêze (m)",
    "y": " kucak"
  },
  {
    "x": "hemêze kerdene",
    "y": " kucaklamak\r\n~Ey hemêze bike! (Onu kucakla!)\t"
  },
  {
    "x": "hemêzekerdiş (n)",
    "y": " kucaklama"
  },
  {
    "x": "hemêzi (m)",
    "y": " b. hemêze (m) "
  },
  {
    "x": "hemêzi kerdene",
    "y": " b. hemêze kerdene"
  },
  {
    "x": "hemêzikerdiş (n)",
    "y": " b. hemêzekerdiş (n)"
  },
  {
    "x": "hemgemî (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "hemgen (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "hemgê (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "hemgên (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "hemgî (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "hemgîn (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "hemi-I",
    "y": " b. heme-I"
  },
  {
    "x": "hemi pîya",
    "y": " b. heme pîya"
  },
  {
    "x": "hemi-II (m)",
    "y": " b. heme-II (m)"
  },
  {
    "x": "hemi kerdene",
    "y": " b. heme kerdene"
  },
  {
    "x": "hemi-III",
    "y": " b. hem-I"
  },
  {
    "x": "Hemilka",
    "y": " b. Amerîka (m)"
  },
  {
    "x": "hemilkan",
    "y": " Amerikan"
  },
  {
    "x": "hemilnayene",
    "y": " sindirmek"
  },
  {
    "x": "hemilnayîş (n)",
    "y": " sindirme"
  },
  {
    "x": "hemilnayîşê lince (n)",
    "y": " çamur sindirimi"
  },
  {
    "x": "hemin",
    "y": " b. hemîne"
  },
  {
    "x": "hemina",
    "y": " b. hemîne"
  },
  {
    "x": "heminan",
    "y": " b. hemîne"
  },
  {
    "x": "hemine",
    "y": " b. hemîne"
  },
  {
    "x": "hemîla (m)",
    "y": " gebe, iki canlı, hamile"
  },
  {
    "x": "hemîla bîyene",
    "y": " gebe olmak, hamile olmak, iki canlı olmak"
  },
  {
    "x": "hemîlayîye (m)",
    "y": " gebelik¸ hamilelik"
  },
  {
    "x": "hemîleyey (m)",
    "y": " b. hemîlayîye (m)"
  },
  {
    "x": "hemîleyê (m)",
    "y": " b. hemîlayîye (m)"
  },
  {
    "x": "hemîlê (m)",
    "y": " b. hemîla (m)"
  },
  {
    "x": "hemîlê bîyayene",
    "y": " b. hemîla bîyene"
  },
  {
    "x": "hemîna",
    "y": " b. hemîne"
  },
  {
    "x": "hemîne",
    "y": " hepsi, tümü; hepsini, tümünü"
  },
  {
    "x": "hemînî",
    "y": " b. hemîne"
  },
  {
    "x": "hemîyayene",
    "y": " aşınmak"
  },
  {
    "x": "hemîyayîş (n)",
    "y": " aşınma"
  },
  {
    "x": "hemîyî",
    "y": " b. hemeyî"
  },
  {
    "x": "hemkar, -e",
    "y": " işbirlikçi "
  },
  {
    "x": "hemkarî (m)",
    "y": " işbirliği"
  },
  {
    "x": "hemkarîye (m)",
    "y": " işbirliği"
  },
  {
    "x": "hemle (m)",
    "y": " b. hemîla (m)"
  },
  {
    "x": "hemle bîyayene",
    "y": " b. hemîla bîyene"
  },
  {
    "x": "hemleyî (m)",
    "y": " b. hemîlayîye (m)"
  },
  {
    "x": "hemlik, -e",
    "y": " (oğlak ve kuzu için) besili, semiz"
  },
  {
    "x": "hemmana, -ye",
    "y": " eş anlamlı, anlamdaş"
  },
  {
    "x": "hemmeslek, -e",
    "y": " meslektaş"
  },
  {
    "x": "hemname (n)",
    "y": " adaş"
  },
  {
    "x": "hemnameyî (m)",
    "y": " adaşlık"
  },
  {
    "x": "hemnon (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "hemnonî",
    "y": "b.hamnanî"
  },
  {
    "x": "hemnûn (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "hemnûnî",
    "y": "b.hamnanî "
  },
  {
    "x": "hemo",
    "y": " b. hema-I"
  },
  {
    "x": "hemo-hemo",
    "y": " b. hema-hema"
  },
  {
    "x": "hemofilî (m)",
    "y": " hemofili"
  },
  {
    "x": "hemom (n)",
    "y": " b. hemam (n)"
  },
  {
    "x": "hemomek (n)",
    "y": " b. hemamek (n)"
  },
  {
    "x": "hempar, -e",
    "y": " ortak, müşterek"
  },
  {
    "x": "hempar bîyene",
    "y": " ortak olmak"
  },
  {
    "x": "hemproduksîyon (n, sn)",
    "y": " ortakyapım, koprodüksiyon"
  },
  {
    "x": "hemrayîr, -e",
    "y": " b. yoldaş"
  },
  {
    "x": "hemsal, -i",
    "y": " b. hemserr, -e"
  },
  {
    "x": "hemsencî (m)",
    "y": " denge, balans"
  },
  {
    "x": "hemsencîya bîyolojîke (m)",
    "y": " biyolojik denge, dirim bilimsel denge"
  },
  {
    "x": "hemsencîya sipîyî (sn)",
    "y": " beyaz dengesi"
  },
  {
    "x": "hemserek, -e",
    "y": " eş başkan"
  },
  {
    "x": "hemserekîye (m)",
    "y": " eş başkanlık"
  },
  {
    "x": "hemserr, -e",
    "y": " yaşıt"
  },
  {
    "x": "hemsî (n)",
    "y": " hamsi"
  },
  {
    "x": "hemşehrî, -ye",
    "y": " hemşehri"
  },
  {
    "x": "hemşehrîtî (m)",
    "y": " hemşehrilik"
  },
  {
    "x": "hemşehrîyîye (m)",
    "y": " hemşehrilik"
  },
  {
    "x": "hemşîra (m)",
    "y": " hemşire"
  },
  {
    "x": "hemşîre (m)",
    "y": " b. hemşîra (m)"
  },
  {
    "x": "hemtî (m)",
    "y": " dövme"
  },
  {
    "x": "hemû-I",
    "y": " b. heme-I"
  },
  {
    "x": "hemû kes",
    "y": " b. heme kes"
  },
  {
    "x": "hemû-II",
    "y": " b. hema-I"
  },
  {
    "x": "hemûm (n)",
    "y": " b. hemam (n)"
  },
  {
    "x": "hemûmek (n)",
    "y": " b. hemamek (n)"
  },
  {
    "x": "hemûn",
    "y": " b. hemîne"
  },
  {
    "x": "hemveng, -e",
    "y": " eş sesli"
  },
  {
    "x": "hemver, -e",
    "y": " 1)denk\r\n~2)yaşıt, akran, böğür, taydaş, öğür\r\n~3)karşı, karşıtçı, karşı olan, aleyhtar"
  },
  {
    "x": "hemverê ci",
    "y": " bilmukabele"
  },
  {
    "x": "hemverê ... de",
    "y": " karşısında"
  },
  {
    "x": "hemverîye (m)",
    "y": " denklik"
  },
  {
    "x": "hemwelatî, -ye",
    "y": " vatandaş, yurttaş"
  },
  {
    "x": "hemwelatîye (m)",
    "y": " vatandaşlık, yurttaşlık"
  },
  {
    "x": "hemwelatîyî (m)",
    "y": " vatandaşlık, yurttaşlık"
  },
  {
    "x": "hemwelatîyîye (m)",
    "y": " vatandaşlık, yurttaşlık"
  },
  {
    "x": "hemyayene",
    "y": " b. hemîyayene "
  },
  {
    "x": "hemyayîş (n)",
    "y": " b. hemîyayîş (n)"
  },
  {
    "x": "hemzale-I (n)",
    "y": " b. arzêle-I (n)"
  },
  {
    "x": "hemzale-II (n)",
    "y": " b. herzale-I (n)"
  },
  {
    "x": "hemzar",
    "y": " b. hezar"
  },
  {
    "x": "hemzemîn, -e",
    "y": " hemzemin"
  },
  {
    "x": "hemzik (n)",
    "y": " b. emzik (n)"
  },
  {
    "x": "hen",
    "y": " b. winî, ayhawa"
  },
  {
    "x": "hena-I (m, z)",
    "y": " 1)b. na-I (m, z)\r\n~2)b. naye (m, z) "
  },
  {
    "x": "hena-II (m, s)",
    "y": " b. na-I (m, s)"
  },
  {
    "x": "hena-III",
    "y": " b. winî, ayhawa"
  },
  {
    "x": "henar (m)",
    "y": " b. henare (m)"
  },
  {
    "x": "henare (m)",
    "y": " nar"
  },
  {
    "x": "henarêre (m)",
    "y": " nar ağacı"
  },
  {
    "x": "henarêri (m)",
    "y": " b. henarêre (m)"
  },
  {
    "x": "henari (m)",
    "y": " b. henare (m)"
  },
  {
    "x": "henaryer (m)",
    "y": " b. henarêre (m)"
  },
  {
    "x": "hend-I",
    "y": " b. hende"
  },
  {
    "x": "hend-II",
    "y": " b. wina"
  },
  {
    "x": "henda",
    "y": " b. hendê"
  },
  {
    "x": "henday",
    "y": " b. hende"
  },
  {
    "x": "hendayê",
    "y": " b. hende"
  },
  {
    "x": "hendayên",
    "y": " b. hende"
  },
  {
    "x": "hendbol (n)",
    "y": " hentbol"
  },
  {
    "x": "hende",
    "y": " 1)bunca, bu kadar\r\n~Ez hende ser o vindeta, reyna zî keda mi veng ra şî. (Üzerinde bunca durdum, yine de emeğim boşa gitti.)\r\n~\t\r\n~2)onca, o kadar\r\n~\t\r\n~Hende vewre varaya reyna zî rayîrî nêgîrîyayê. (Onca kar yağmış yine de yollar kapanmamış.)"
  },
  {
    "x": "hende ke",
    "y": " 1)o kadar ki\r\n~2)yeter ki"
  },
  {
    "x": "hendeheyra",
    "y": " b. hende"
  },
  {
    "x": "hendê...-I",
    "y": " 1)kadar\r\n~2)b. hende"
  },
  {
    "x": "hendêy",
    "y": " b. hendê-1"
  },
  {
    "x": "hendê-II",
    "y": " b. hende"
  },
  {
    "x": "hendhay",
    "y": " b. hende"
  },
  {
    "x": "hendhey",
    "y": " b. hende"
  },
  {
    "x": "hendi",
    "y": " b. hende"
  },
  {
    "x": "hendihey",
    "y": " b. hende"
  },
  {
    "x": "hendî-I",
    "y": " b. êdî"
  },
  {
    "x": "hendî-II",
    "y": " b. hende"
  },
  {
    "x": "hendî-III (m)",
    "y": " b. hindî-I (m)"
  },
  {
    "x": "hendîna",
    "y": " b. êdî"
  },
  {
    "x": "hendo",
    "y": " b. hende"
  },
  {
    "x": "hene (n)",
    "y": " b. hine (n)"
  },
  {
    "x": "hene bîyene",
    "y": " b. hine bîyene "
  },
  {
    "x": "hene desawitene",
    "y": " b. hine desawitene"
  },
  {
    "x": "hene desawîyayene",
    "y": " b. hine desawîyayene"
  },
  {
    "x": "hene kardene",
    "y": " b. hine kerdene"
  },
  {
    "x": "hene kerdene",
    "y": " b. hine kerdene"
  },
  {
    "x": "hene ... sawitene",
    "y": " b. hine ... sawitene"
  },
  {
    "x": "hene ... sawîyayene",
    "y": " b. hine ... sawîyayene"
  },
  {
    "x": "Hene (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "henebîyayîş (n)",
    "y": " b. hinebîyayîş (n)"
  },
  {
    "x": "henedesawitiş (n)",
    "y": " b. hinedesawitiş (n)"
  },
  {
    "x": "henedesawîyayîş (n)",
    "y": " b. hinedesawîyayîş (n)"
  },
  {
    "x": "henefî, -ye",
    "y": " Hanefi"
  },
  {
    "x": "henefîtî (m)",
    "y": " Hanefilik"
  },
  {
    "x": "henefîyey (m)",
    "y": " b. henefîyîye (m)"
  },
  {
    "x": "henefîyê (m)",
    "y": " b. henefîyî (m)"
  },
  {
    "x": "henefîyî (m)",
    "y": " Hanefilik"
  },
  {
    "x": "henefîyîye (m)",
    "y": " Hanefilik"
  },
  {
    "x": "henek (n)",
    "y": " b. henik (n)"
  },
  {
    "x": "henekarda (m)",
    "y": " b. hinekerda (m)"
  },
  {
    "x": "henekarde (n)",
    "y": " b. hinekerde (n)"
  },
  {
    "x": "henekardiş (n)",
    "y": " b. hinekerdiş (n)"
  },
  {
    "x": "henekçî, -ye",
    "y": " şakacı"
  },
  {
    "x": "heneke-I (m)",
    "y": " şaka"
  },
  {
    "x": "heneke kerdene",
    "y": " şaka yapmak\r\n~Heneke  meke! (Şaka yapma!)"
  },
  {
    "x": "henekê … kerdene",
    "y": " (ile) alay etmek, dalga geçmek\r\n~Henekê mêrikî meke! (Herifle dalga geçme!)"
  },
  {
    "x": "henekê xo bi ... kerdene",
    "y": " ... ile dalga geçmek"
  },
  {
    "x": "henekî bi … kerdene",
    "y": " (ile) alay etmek\r\n~Henekanê xo bi kesî meke! (Kimseyle dalga geçme! / Kimseyle alay etme!)"
  },
  {
    "x": "henekî kerdene",
    "y": " şaka yapmak\r\n~Henekî meke! (Şaka yapma!)"
  },
  {
    "x": "heneke-II (m)",
    "y": " b. henik (n)"
  },
  {
    "x": "henekekerdiş (n)",
    "y": " şaka yapma  "
  },
  {
    "x": "henekerda (m)",
    "y": " b. hinekerda (m)"
  },
  {
    "x": "henekerde (n)",
    "y": " b. hinekerde (n)"
  },
  {
    "x": "henekerdiş (n)",
    "y": " b. hinekerdiş (n)"
  },
  {
    "x": "henekerdîye (m)",
    "y": " b. hinekerdîye (m)"
  },
  {
    "x": "heneki (m)",
    "y": " b. heneke (m)"
  },
  {
    "x": "heneki kerdene",
    "y": " b. henekî kerdene"
  },
  {
    "x": "henekikerdiş (n)",
    "y": " b. henekîkerdiş (n)"
  },
  {
    "x": "henekîkerdiş (n)",
    "y": " şaka yapma"
  },
  {
    "x": "henekker, -e",
    "y": " şakacı"
  },
  {
    "x": "heneyin, -e",
    "y": " b. hineyin, -e"
  },
  {
    "x": "henê",
    "y": " 1)b. winî, ayhawa\r\n~2)b. henên"
  },
  {
    "x": "henêke",
    "y": " b. hinîke"
  },
  {
    "x": "henên, -e",
    "y": " öylesi"
  },
  {
    "x": "hengaj (m)",
    "y": " b. hengazî (m)"
  },
  {
    "x": "hengajî (m)",
    "y": " b. hengazî (m)"
  },
  {
    "x": "tîra hengajî (m)",
    "y": " b. tîra hengazî (m)"
  },
  {
    "x": "hengazî (m)",
    "y": " saban, karasaban"
  },
  {
    "x": "tîra hengazî (m)",
    "y": " saban oku, karasaban oku"
  },
  {
    "x": "henge (m)",
    "y": " b. hinge (m)"
  },
  {
    "x": "hengemîn (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "hengi (m)",
    "y": " b. hinge (m)"
  },
  {
    "x": "hengile (m)",
    "y": " (hayvan için) meme"
  },
  {
    "x": "hengilik (n)",
    "y": " b. hingilik (n)"
  },
  {
    "x": "hengimên (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "hengî",
    "y": " b. hinîke"
  },
  {
    "x": "hengmên (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "hengola (m)",
    "y": " b. kengula (m)"
  },
  {
    "x": "hengur (m)",
    "y": " b. engure (m)"
  },
  {
    "x": "hengure (m)",
    "y": " b. engure (m)"
  },
  {
    "x": "henguri (m)",
    "y": " b. engure (m)"
  },
  {
    "x": "hengurî (m)",
    "y": " b. engure (m)"
  },
  {
    "x": "heni (n)",
    "y": " b. hine (n)"
  },
  {
    "x": "heni panayene",
    "y": " b. hine panayene"
  },
  {
    "x": "heni panîyayene",
    "y": " b. hine panîyayene"
  },
  {
    "x": "Heni (m)",
    "y": " b. Hene (m)"
  },
  {
    "x": "henifî, -ye",
    "y": " b. henefî, -ye"
  },
  {
    "x": "henik (n)",
    "y": " çene"
  },
  {
    "x": "henipanayîş (n)",
    "y": " b. hinepanayîş (n)"
  },
  {
    "x": "henipanîyayîş (n)",
    "y": " b. hinepanîyayîş (n)"
  },
  {
    "x": "henişk (n)",
    "y": " çene "
  },
  {
    "x": "henî-I",
    "y": " b. hinî-IV, hinîke"
  },
  {
    "x": "henî-II (n, z)",
    "y": " b. ney (n, z)"
  },
  {
    "x": "henî-III (zh, z)",
    "y": " b. nê (zh, z)"
  },
  {
    "x": "henî-IV",
    "y": " b. winî, ayhawa"
  },
  {
    "x": "henî-V",
    "y": " b. hinî-III"
  },
  {
    "x": "henî-VI (n)",
    "y": " b. hênî-I (n)"
  },
  {
    "x": "henîn",
    "y": " b. henên"
  },
  {
    "x": "henkî",
    "y": " b. hinîke"
  },
  {
    "x": "henne (n)",
    "y": " b. hine (n)"
  },
  {
    "x": "henneyin, -e",
    "y": " b. hineyin, -e"
  },
  {
    "x": "henni (n)",
    "y": " b. hine (n)"
  },
  {
    "x": "henniyin, -e",
    "y": " b. hineyin, -e"
  },
  {
    "x": "hennî (n)",
    "y": " b. hine (n)"
  },
  {
    "x": "heno (n, z)",
    "y": " b. no (n, z)"
  },
  {
    "x": "hente",
    "y": " b. hende"
  },
  {
    "x": "hentê...",
    "y": " b. hendê...-I"
  },
  {
    "x": "henzar",
    "y": " b. hezar"
  },
  {
    "x": "hepa (n)",
    "y": " b. epe (m), epo (n)"
  },
  {
    "x": "hepa werdene",
    "y": " b. epe werdene"
  },
  {
    "x": "hepatît (n)",
    "y": " hepatit"
  },
  {
    "x": "hepatît B (n)",
    "y": " hepatit B"
  },
  {
    "x": "hepe (m)",
    "y": " b. epe (m), epo (n)"
  },
  {
    "x": "hepe kerdene",
    "y": " b. epe kerdene"
  },
  {
    "x": "hepe werdene",
    "y": " b. epe werdene"
  },
  {
    "x": "hepi (m)",
    "y": " b. epe (m), epo (n)"
  },
  {
    "x": "hepi kerdene",
    "y": " b. epe kerdene"
  },
  {
    "x": "hepi werdene",
    "y": " b. epe werdene"
  },
  {
    "x": "hepis (n)",
    "y": " hapis"
  },
  {
    "x": "hepisxane (n)",
    "y": " cezaevi, hapishane, tutukevi, tevkifhane, tevkifevi, mahpushane; dam (argo)"
  },
  {
    "x": "hepisxaneyo akerde (n)",
    "y": " açık cezaevi"
  },
  {
    "x": "hepisxaneyo nêmakerde (n)",
    "y": " yarı açık cezaevi"
  },
  {
    "x": "heppa (m)",
    "y": " b. epe (m), epo (n)"
  },
  {
    "x": "heppa kerdene",
    "y": " b. epe kerdene"
  },
  {
    "x": "heppa werdene",
    "y": " b. epe werdene"
  },
  {
    "x": "heps (n)",
    "y": " b. hepis (n)"
  },
  {
    "x": "hepsi (m)",
    "y": " b. hepis (n)"
  },
  {
    "x": "hepsî, -ye",
    "y": " tutuklu, mahpus"
  },
  {
    "x": "hepsxane (n)",
    "y": " b. hepisxane"
  },
  {
    "x": "heq-I (n)",
    "y": " 1)hak\r\n~2)ücret\r\n~3)fiyat, paha"
  },
  {
    "x": "heq dayene",
    "y": " ödemek"
  },
  {
    "x": "heq kerdene",
    "y": " hak etmek"
  },
  {
    "x": "heqê arizî (zh)",
    "y": " özel haklar"
  },
  {
    "x": "heqê barutî (n)",
    "y": " barut hakkı"
  },
  {
    "x": "heqê bextwaştişî (n)",
    "y": " iltica hakkı, sığınma hakkı"
  },
  {
    "x": "heqê cewabî (n)",
    "y": " cevap hakkı, yanıt hakkı"
  },
  {
    "x": "heqê çarenuştişê xo",
    "y": " kendi kaderini tayin hakkı"
  },
  {
    "x": "heqê derbazbîyayîşî (n)",
    "y": " geçiş hakkı"
  },
  {
    "x": "heqê dersa îlaweyî (n)",
    "y": " ek ders ücreti"
  },
  {
    "x": "heqê eynîyî (zh) (huq.)",
    "y": " ayni haklar"
  },
  {
    "x": "heqê îltîca (n)",
    "y": " iltica hakkı, sığınma hakkı"
  },
  {
    "x": "heqê îrtîfaqî (n) (huq.)",
    "y": " irtifak hakkı"
  },
  {
    "x": "heqê îstifadekerdişî (n)",
    "y": " intifa hakkı"
  },
  {
    "x": "heqê kesî (zh)",
    "y": " kişisel haklar"
  },
  {
    "x": "heqê medenî (zh)",
    "y": " medeni haklar "
  },
  {
    "x": "heqê merdiman (zh)",
    "y": " insan hakları"
  },
  {
    "x": "heqê pampole (n)",
    "y": " b. heqê papûre (n)"
  },
  {
    "x": "heqê papore (n)",
    "y": " b. heqê papûre (n)"
  },
  {
    "x": "heqê papori (n)",
    "y": " b. heqê papûre (n)"
  },
  {
    "x": "heqê papûre (n)",
    "y": " şose vergisi (eskiden)"
  },
  {
    "x": "heqê pêroyî (zh)",
    "y": " kamu hakları"
  },
  {
    "x": "heqê şexsî (zh)",
    "y": " kişisel haklar"
  },
  {
    "x": "heqê tayînkerdişê qederê xo",
    "y": " kendi kaderini tayin hakkı"
  },
  {
    "x": "heqê umûmîya (zh)",
    "y": " kamu hakları"
  },
  {
    "x": "heqê vîyartişî (n)",
    "y": " geçiş hakkı"
  },
  {
    "x": "heqê wayîrîye (n)",
    "y": " mülkiyet hakkı"
  },
  {
    "x": "heqo rojane (n)",
    "y": " gündelik, yevmiye"
  },
  {
    "x": "Heq-II (n)",
    "y": " Tanrı, Allh, ilah"
  },
  {
    "x": "Bi Heqî bo ke",
    "y": " Allah'a yemin ederim ki"
  },
  {
    "x": "(Ti) bi Heqî kena/î",
    "y": " Allahını seversen, Allah aşkına"
  },
  {
    "x": "(Bi) Destê Heqî",
    "y": " Allah izin verirse"
  },
  {
    "x": "Heq bisevekno.",
    "y": " Allah korusun"
  },
  {
    "x": "Heq bizêdno",
    "y": "Tanrı ziyade etsin/artırsın."
  },
  {
    "x": "Heq bîyaro werê",
    "y": " b. Heq werê bîyaro"
  },
  {
    "x": "Heq nêkero",
    "y": " Allah göstermesin"
  },
  {
    "x": "Heq rehetîye bido",
    "y": " İyi geceler."
  },
  {
    "x": "Heq şîfa bido",
    "y": " Tanrı şifa versin./Geçmiş olsun."
  },
  {
    "x": "Heq tala (n)",
    "y": " Hakteala, Allahuteala"
  },
  {
    "x": "Heq teala (n)",
    "y": " Hakteala, Allahuteala"
  },
  {
    "x": "Heq vajo heya",
    "y": " Allah izin verirse"
  },
  {
    "x": "Heq werê bîyaro",
    "y": " Allah izin verirse"
  },
  {
    "x": "Heq zaneno.",
    "y": " Allah bilir, Allahualem"
  },
  {
    "x": "Heq zîyade biko",
    "y": " Tanrı ziyade etsin/artırsın."
  },
  {
    "x": "Heq zoneno.",
    "y": " b. Heq zaneno."
  },
  {
    "x": "(Ti) Heqî kena/î",
    "y": " Allahını seversen, Allah aşkına"
  },
  {
    "x": "Heqî ra eyan o. ",
    "y": " Allah bilir."
  },
  {
    "x": "(Ti) Heqî sînenî/a",
    "y": " Allahını seversen, Allah aşkına"
  },
  {
    "x": "Heqo teala (n)",
    "y": " b. Heq teala (n)"
  },
  {
    "x": "Ma kerd kêm, Heq hurendî pirr kero.",
    "y": " b. Ma kêmî kerd, Heq herinde pirr biko/kero."
  },
  {
    "x": "Ma kêmî kerd, Heq herinde pirr biko/kero. ",
    "y": " Biz eksilttik, Tanrı yerini doldursun."
  },
  {
    "x": "Ma kêmî kerd, Heq kêmî nêkero.",
    "y": " Biz eksilttik, Tanrı ziyade etsin/artırsın."
  },
  {
    "x": "heq kerdene",
    "y": " defnetmek\r\n~“... Ma heq kirî.” (Defnedelim. (W. K. Merdimîn, Vate, Nr. 16)"
  },
  {
    "x": "heqbe (n)",
    "y": " b. heqîbe (n)"
  },
  {
    "x": "heqdar, -e",
    "y": " haklı"
  },
  {
    "x": "heqdayîş (n)",
    "y": " ödeme"
  },
  {
    "x": "heqe (m)",
    "y": " b. heq-I (n)"
  },
  {
    "x": "heqîbe (n)",
    "y": " heybe "
  },
  {
    "x": "heqîqete (m)",
    "y": " gerçek, gerçeklik, hakikat, realite"
  },
  {
    "x": "heqîqeten",
    "y": " gerçekten, hakikaten "
  },
  {
    "x": "heqîqî, -ye",
    "y": " gerçek, hakikat, hakiki"
  },
  {
    "x": "heqîve (n)",
    "y": " b. heqîbe (n) "
  },
  {
    "x": "heqkerda (m)",
    "y": " hak ediş"
  },
  {
    "x": "heqkerde (n)",
    "y": " hak ediş"
  },
  {
    "x": "heqkerdiş-I (n)",
    "y": " hak etme"
  },
  {
    "x": "heqkerdiş-II (n)",
    "y": " defnetme"
  },
  {
    "x": "heqkerdî (m)",
    "y": " hak ediş"
  },
  {
    "x": "heqlew (n)",
    "y": " b. aqreb (n)"
  },
  {
    "x": "Heqq (n)",
    "y": " Heq-II (n)"
  },
  {
    "x": "her, -e-I",
    "y": " 1)eşek\r\n~2)aşık kemiğiyle oynanan bir oyunda bu kemiğin eni dar olan iki yan tarafından girintili olanı\r\n~3)kibirli (argo), büyüklenen (argo), şımarık (argo)"
  },
  {
    "x": "hera/ê Misirî",
    "y": " beyaz renkli ve büyük bir eşek cinsi"
  },
  {
    "x": "hera/herê Şamî",
    "y": " beyaz renkli ve büyük bir eşek cinsi"
  },
  {
    "x": "herî Misrî (n)",
    "y": " b. hera/ê Misirî"
  },
  {
    "x": "herî Şamî (n)",
    "y": " b. hera/herê Şamî"
  },
  {
    "x": "her bîyayene",
    "y": " b. her bîyene"
  },
  {
    "x": "her bîyene",
    "y": " kibirlenmek (argo), şımarmak (argo)"
  },
  {
    "x": "her kerdene",
    "y": " şımartmak (argo)"
  },
  {
    "x": "her-herane",
    "y": " 1)eşekçe\r\n~2)şımarıkça (argo)"
  },
  {
    "x": "her-II",
    "y": " her"
  },
  {
    "x": "her bîyayene",
    "y": " b. her bîyene"
  },
  {
    "x": "her çar",
    "y": " her dört, her dördü"
  },
  {
    "x": "her çarine",
    "y": " her dördü, her dördünü"
  },
  {
    "x": "her di",
    "y": " her iki, her ikisi\r\n~Her di nusenî. (Her ikisi yazıyor.)"
  },
  {
    "x": "her dimîna",
    "y": " b. her dîyan, her dîyine "
  },
  {
    "x": "her dîyan",
    "y": " her ikisi\r\n~Her dîyan wend. (Her ikisi okudu.)"
  },
  {
    "x": "her dîyine",
    "y": " her iki, her ikisi"
  },
  {
    "x": "her g’",
    "y": " b. her yew"
  },
  {
    "x": "her g’ jew",
    "y": " b. her yew"
  },
  {
    "x": "her ge jew",
    "y": " b. her yew"
  },
  {
    "x": "her gi",
    "y": " b. her yew"
  },
  {
    "x": "her go",
    "y": " b. her yew"
  },
  {
    "x": "her go yew",
    "y": " b. her yew"
  },
  {
    "x": "her gu",
    "y": " b. her yew"
  },
  {
    "x": "her gu yew",
    "y": " b. her yew "
  },
  {
    "x": "her gû",
    "y": " b. her yew"
  },
  {
    "x": "her gû jo",
    "y": " b. her yew"
  },
  {
    "x": "her gû yew",
    "y": " b. her yew "
  },
  {
    "x": "her jew",
    "y": " b. her yew "
  },
  {
    "x": "her ju",
    "y": " b. her yew"
  },
  {
    "x": "her ke şino",
    "y": " giderek, gittikçe, yavaş yavaş"
  },
  {
    "x": "her kes",
    "y": " herkes"
  },
  {
    "x": "her roje",
    "y": " her gün"
  },
  {
    "x": "her serre",
    "y": " her yıl"
  },
  {
    "x": "her yew",
    "y": " her bir, her biri"
  },
  {
    "x": "her zu",
    "y": " b. her yew "
  },
  {
    "x": "her zû",
    "y": " b. her yew "
  },
  {
    "x": "her-III (m)",
    "y": " b. herre (m)"
  },
  {
    "x": "hera (n)",
    "y": " b. hîra (n)"
  },
  {
    "x": "heraj (n)",
    "y": " 1)kavga hevesi\r\n~2)meydan okuma sözcüğü"
  },
  {
    "x": "heraj waştene",
    "y": " 1)kabadayılık taslamak\r\n~2)meydan okumak"
  },
  {
    "x": "herajwaştiş (n)",
    "y": " 1)kabadayılık taslama\r\n~2)meydan okuma"
  },
  {
    "x": "heram, -e",
    "y": " haram"
  },
  {
    "x": "heramî, -ye-I",
    "y": " haydut"
  },
  {
    "x": "heramî-II (m)",
    "y": " b. hêramî (n)"
  },
  {
    "x": "herane",
    "y": " 1)eşekçe\r\n~2)şımarıkça (argo)"
  },
  {
    "x": "herane kerdene",
    "y": " şımarmak (argo)"
  },
  {
    "x": "herane xo nayene",
    "y": " şımarmak (argo)"
  },
  {
    "x": "herane xwi nayîne",
    "y": " b. herane xo nayene (argo)"
  },
  {
    "x": "heray (m)",
    "y": " b. hîraye (m)"
  },
  {
    "x": "herayey (m)",
    "y": " b. hîrayîye (m)"
  },
  {
    "x": "herayê (m)",
    "y": " b. hîrayî (m)"
  },
  {
    "x": "herb (n)",
    "y": " savaş, harp"
  },
  {
    "x": "herbo psîkolojîk (n)",
    "y": " psikolojik savaş"
  },
  {
    "x": "herbêş (n)",
    "y": " b. hargûş (n)"
  },
  {
    "x": "herbîş (n)",
    "y": " b. hargûş (n)"
  },
  {
    "x": "herçene (n)",
    "y": " 1)dirsek\r\n~2)çene"
  },
  {
    "x": "herçeni (n)",
    "y": " b. herçene (n)"
  },
  {
    "x": "herçini (n)",
    "y": " b. herçene (n)"
  },
  {
    "x": "herçî ke şino",
    "y": " giderek, gittikçe, yavaş yavaş"
  },
  {
    "x": "herd (n)",
    "y": " b. erd (n)"
  },
  {
    "x": "herdîs (m)",
    "y": " b. erdîşe (m)"
  },
  {
    "x": "herdîse (m)",
    "y": " b. erdîşe (m)"
  },
  {
    "x": "herdîş (m)",
    "y": " b. erdîşe (m)"
  },
  {
    "x": "herdîşe (m)",
    "y": " b. erdîşe (m)"
  },
  {
    "x": "herdlerz (n)",
    "y": " b. erdlerz (n)"
  },
  {
    "x": "herdos (m)",
    "y": " b. herdose (m)"
  },
  {
    "x": "herdose (m)",
    "y": " iki elin bitiştirilmesi suretiyle oluşturulan avuç"
  },
  {
    "x": "here-I (m)",
    "y": " dişi eşek"
  },
  {
    "x": "here-II (m)",
    "y": " b. herre (m)"
  },
  {
    "x": "hered (n)",
    "y": " b. herid (n)"
  },
  {
    "x": "heredan, -e",
    "y": " b. heridan, -e"
  },
  {
    "x": "heredîyaya (m)",
    "y": " b. heridîyaya (m)"
  },
  {
    "x": "heredîyaye (n)",
    "y": " b. heridîyaye (n)"
  },
  {
    "x": "heredîyayene",
    "y": " b. heridîyayene"
  },
  {
    "x": "heredîyayê (m)",
    "y": " b. heridîyayîye (m)"
  },
  {
    "x": "heredîyayêne",
    "y": " b. heridîyayene"
  },
  {
    "x": "heredîyayi (n)",
    "y": " b. heridîyaye (n)"
  },
  {
    "x": "heredîyayîne",
    "y": " b. heridîyayene"
  },
  {
    "x": "heredîyayîs (n)",
    "y": " b. heridîyayîş (n)"
  },
  {
    "x": "heredîyayîş (n)",
    "y": " b. heridîyayîş (n)"
  },
  {
    "x": "heredîyayîye (m)",
    "y": " b. heridîyayîye (m)"
  },
  {
    "x": "herednayene",
    "y": " b. heridnayene"
  },
  {
    "x": "herednayêne",
    "y": " b. heridnayene"
  },
  {
    "x": "herednayîne",
    "y": " b. heridnayene"
  },
  {
    "x": "herednayîs (n)",
    "y": " b. heridnayîş (n)"
  },
  {
    "x": "herednayîş (n)",
    "y": " b. heridnayîş (n)"
  },
  {
    "x": "heredyayîne",
    "y": " b. heridîyayene"
  },
  {
    "x": "heredyayîş (n)",
    "y": " b. heridîyayîş (n)"
  },
  {
    "x": "hereket (n)",
    "y": " 1)hareket\r\n~2)akım"
  },
  {
    "x": "hereket kerdene",
    "y": " hareket etmek"
  },
  {
    "x": "hereketo milî (n)",
    "y": " ulusal hareket"
  },
  {
    "x": "hereketo neteweyî (n)",
    "y": " ulusal hareket"
  },
  {
    "x": "hereketkerdiş (n)",
    "y": " hareket etme"
  },
  {
    "x": "herekîyayene",
    "y": " b. herikîyayene"
  },
  {
    "x": "herekîyayîne",
    "y": " b. herikîyayene"
  },
  {
    "x": "herekîyayîş (n)",
    "y": " b. herikîyayîş (n)"
  },
  {
    "x": "heremîyaya (m)",
    "y": " b. herimîyaya (m)"
  },
  {
    "x": "heremîyaye (n)",
    "y": " b. herimîyayî, -ye"
  },
  {
    "x": "heremîyayene",
    "y": " b. herimîyayene"
  },
  {
    "x": "heremîyayî, -ye",
    "y": " b. herimîyayî, -ye"
  },
  {
    "x": "heremîyayîş (n)",
    "y": " b. herimîyayîş (n)"
  },
  {
    "x": "heremnayene",
    "y": " b. herimnayene"
  },
  {
    "x": "heremnayîş (n)",
    "y": " b. herimnayîş (n)"
  },
  {
    "x": "heremusik (n)",
    "y": " b. hermuşk (n)"
  },
  {
    "x": "herese (n)",
    "y": " b. hereşe (n)"
  },
  {
    "x": "herese kerdene",
    "y": " b. hereşe kerdene"
  },
  {
    "x": "heresekerdiş (n)",
    "y": " b. hereşekerdiş (n)"
  },
  {
    "x": "hereşanî kerdene",
    "y": " b. hereşe kerdene"
  },
  {
    "x": "hereşanîkerdiş (n)",
    "y": " b. hereşekerdiş (n)"
  },
  {
    "x": "hereşe (n)",
    "y": " 1)meydan okuma sözcüğü\r\n~2)öküz veya boğanın ayaklarını ve boynuzlarını yere sürterek ve böğürmesi"
  },
  {
    "x": "hereşe kerdene",
    "y": " 1)kabadayılık taslamak\r\n~2)meydan okumak  "
  },
  {
    "x": "hereşekerdiş (n)",
    "y": " 1)kabadayılık taslama\r\n~2)meydan okuma"
  },
  {
    "x": "hereşoney kerdene",
    "y": " b. hereşe kerdene"
  },
  {
    "x": "hereşoneykerdiş (n)",
    "y": " b. hereşekerdiş (n)"
  },
  {
    "x": "hereşonî kerdene",
    "y": " b. hereşe kerdene"
  },
  {
    "x": "hereşonîkerdiş (n)",
    "y": " b. hereşekerdiş (n)"
  },
  {
    "x": "herevnayene",
    "y": " b. herimnayene"
  },
  {
    "x": "herey-I (n)",
    "y": " b. berey (n), erey (n)"
  },
  {
    "x": "herey fîstene",
    "y": " b. berey fîştene"
  },
  {
    "x": "herey kêwtene",
    "y": " b. berey kewtene"
  },
  {
    "x": "herey kotene",
    "y": " b. berey kewtene"
  },
  {
    "x": "herey mendene",
    "y": " b. erey mendene"
  },
  {
    "x": "herey-II (m)",
    "y": " b. herî (m), herîye (m)"
  },
  {
    "x": "hereyfîstiş (n)",
    "y": " b. bereyfîştiş (n)"
  },
  {
    "x": "hereykêwtis (n)",
    "y": " b. bereykewtiş (n)"
  },
  {
    "x": "hereykotiş (n)",
    "y": " b. bereykewtiş (n)"
  },
  {
    "x": "hereymendis (n)",
    "y": " b. ereymendiş (n)"
  },
  {
    "x": "herê-I (n)",
    "y": " b. berey (n), erey (n)"
  },
  {
    "x": "herê fîstene",
    "y": " b. berey fîştene"
  },
  {
    "x": "herê-II (m)",
    "y": " b. herî (m), herîye (m)"
  },
  {
    "x": "herêfîstiş (n)",
    "y": " b. bereyfîştiş (n)"
  },
  {
    "x": "herême (m)",
    "y": " bölge, yöre, mıntıka, mahal; mevzi"
  },
  {
    "x": "herêma peyeyan (m)",
    "y": " yaya bölgesi"
  },
  {
    "x": "herêma şewatî (m)",
    "y": " yangın bölgesi, yangın zonu"
  },
  {
    "x": "herêma tampone (m)",
    "y": " tampon bölge"
  },
  {
    "x": "oyo/aya ke herême de",
    "y": " mücavir"
  },
  {
    "x": "herêmekerdiş (n)",
    "y": " bölgeleme"
  },
  {
    "x": "herêmî, -ye",
    "y": " yerel, yöresel, mahalli"
  },
  {
    "x": "herêmî, -ye",
    "y": " bölgesel"
  },
  {
    "x": "herêy (n)",
    "y": " b. berey (n), erey (n)"
  },
  {
    "x": "herêy fîstene",
    "y": " b. berey fîştene"
  },
  {
    "x": "herêy kotene",
    "y": " b. berey kewtene"
  },
  {
    "x": "herêyfîstiş (n)",
    "y": " b. bereyfîştiş (n)"
  },
  {
    "x": "herêykotiş (n)",
    "y": " b. bereykewtiş (n)"
  },
  {
    "x": "herfe (m)",
    "y": " harf "
  },
  {
    "x": "herfa girde (m)",
    "y": " büyük harf "
  },
  {
    "x": "herfa hurdîye (m)",
    "y": " küçük harf"
  },
  {
    "x": "herfa nevengine (m)",
    "y": " sessiz harf, ünsüz harf "
  },
  {
    "x": "herfa pêrabestene (m)",
    "y": " kaynaştırma harfi"
  },
  {
    "x": "herfa pêragirêdayene (m)",
    "y": " kaynaştırma harfi"
  },
  {
    "x": "herfa vengine (m)",
    "y": " sesli harf, ünlü harf"
  },
  {
    "x": "herfrêz, -e",
    "y": " dizgici"
  },
  {
    "x": "herfrêzî (m)",
    "y": " dizgi"
  },
  {
    "x": "heri-I (m)",
    "y": " b. here-I (m)"
  },
  {
    "x": "heri-II (m)",
    "y": " b. herre (m)"
  },
  {
    "x": "herid (n)",
    "y": " 1)dargınlık, küskünlük\r\n~2)burukluk"
  },
  {
    "x": "heridan, -e",
    "y": " küskün, küs, dargın"
  },
  {
    "x": "heridîyaya (m)",
    "y": " dargın, küskün, küs"
  },
  {
    "x": "heridîyaye (n)",
    "y": " dargın, küskün, küs"
  },
  {
    "x": "heridîyayene",
    "y": " darılmak, küsmek"
  },
  {
    "x": "heridîyayê (m)",
    "y": " b. heridîyayîye (m)"
  },
  {
    "x": "heridîyayi (n)",
    "y": " b. heridîyaye (n)"
  },
  {
    "x": "heridîyayîş (n)",
    "y": " küsme"
  },
  {
    "x": "heridîyayîye (m)",
    "y": " dargın, küskün, küs"
  },
  {
    "x": "heridnayene",
    "y": " darıltmak, küstürmek"
  },
  {
    "x": "heridnayîş (n)",
    "y": " darıltma, küstürme"
  },
  {
    "x": "herig (m)",
    "y": " b. êruge (m)"
  },
  {
    "x": "herikbar, -e",
    "y": " akıcı, akışkan"
  },
  {
    "x": "herikbarîye (m)",
    "y": " akıcılık"
  },
  {
    "x": "herikîyayene",
    "y": " 1)akmak (akarsu vb. için)\r\n~2)kaymak"
  },
  {
    "x": "herikîyayîş (n)",
    "y": " 1)akış\r\n~2)akma (akarsu vb. için)\r\n~3)kayma"
  },
  {
    "x": "herikîyayîşê dayeyan (n)",
    "y": " veri akışı"
  },
  {
    "x": "herikîyayîşê doneyan (n)",
    "y": " veri akışı"
  },
  {
    "x": "herikîyayî, -ye",
    "y": " cari"
  },
  {
    "x": "heriknayene",
    "y": " kaydırmak"
  },
  {
    "x": "heriknayîş (n)",
    "y": " kaydırma"
  },
  {
    "x": "herikyayene",
    "y": " b. herikîyayene"
  },
  {
    "x": "herikyayîş (n)",
    "y": " b. herikîyayîş (n)"
  },
  {
    "x": "herimîyaya/e",
    "y": " bozuk¸ bozulmuş olan"
  },
  {
    "x": "herimîyaye (n)",
    "y": " bozuk"
  },
  {
    "x": "herimîyayeyî (m)",
    "y": " bozukluk"
  },
  {
    "x": "herimîyayeyîya vengî (m)",
    "y": " ses bozukluğu"
  },
  {
    "x": "herimîyayene",
    "y": " 1)bozulmak\r\n~2)kirlenmek\r\n~3)başkalaşmak (mecazi)"
  },
  {
    "x": "herimîyayeyîye (m)",
    "y": " bozukluk"
  },
  {
    "x": "herimîyayi (n)",
    "y": " b. herimîyayî, -ye"
  },
  {
    "x": "herimîyayî, -ye",
    "y": " bozuk"
  },
  {
    "x": "herimîyayîş (n)",
    "y": " 1)bozulma\r\n~2)kirlenme\r\n~3)başkalaşma (mecazi)"
  },
  {
    "x": "herimnayene",
    "y": " 1)bozmak, kötüleştirmek\r\n~2)(namus) kirletmek, tecavüz etmek "
  },
  {
    "x": "herimnayîş (n)",
    "y": " 1)bozma, kötüleştirme, kirletme\r\n~2)(namus) kirletme, tecavüz etme"
  },
  {
    "x": "herimnayox, -e",
    "y": " kirletici, kirletici (namus vb. için)"
  },
  {
    "x": "herind (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "herinde (m)",
    "y": " 1)iz\r\n~2)yer"
  },
  {
    "x": "herinda peynî de",
    "y": " sonuç yerine"
  },
  {
    "x": "herinda peynîye de",
    "y": " sonuç yerine"
  },
  {
    "x": "herindi (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "heriş (n)",
    "y": " kaynatılmış ağızdan yapılan bir yiyecek"
  },
  {
    "x": "herî-I (m)",
    "y": " 1)kibirlilik (argo)\r\n~2)şımarıklık (argo)"
  },
  {
    "x": "herî-II (m)",
    "y": " b. herre (m)"
  },
  {
    "x": "herînayene",
    "y": " b. hêrînayene"
  },
  {
    "x": "herîni (m)",
    "y": " b. herî (m), herîye (m)"
  },
  {
    "x": "herîre (m)",
    "y": " 1)bulamaç (sulu, cıvık hamur)\r\n~2)su ve undan yapılan, içine yağ ve pekmez katılarak da yenebilen bulamaç\r\n~3)pekmez ve undan yapılan tatlı bulamaç \r\n~4)pekmez veya şeker ile undan yapılan ve üzerine kızartılmış yağ dökülerek yenen tatlı bulamaç"
  },
  {
    "x": "herîri (m)",
    "y": " b. herîre (m)"
  },
  {
    "x": "herîş (n)",
    "y": " b. heriş (n)"
  },
  {
    "x": "herîtî",
    "y": " şımarıklık (argo)"
  },
  {
    "x": "herîye (m)",
    "y": " 1)kibirlilik (argo)\r\n~2)şımarıklık (argo)"
  },
  {
    "x": "herkî",
    "y": " 1)eşekçe\r\n~2)şımarıkça (argo)"
  },
  {
    "x": "herme (n)",
    "y": " b. harme (n)"
  },
  {
    "x": "hermele (n)",
    "y": " gürültü, gürültü patırtı, hengâme, kargaşa, şamata"
  },
  {
    "x": "hermele kerdene",
    "y": " gürültü etmek"
  },
  {
    "x": "hermeleyê zemîne (n)",
    "y": " ortam gürültüsü"
  },
  {
    "x": "hermenî, -ye",
    "y": " b. armenî, -ye"
  },
  {
    "x": "hermenîkî (m)",
    "y": " b. armenîkî (m)"
  },
  {
    "x": "Hermenîstan (n)",
    "y": " b. Armenîstan (n)"
  },
  {
    "x": "hermenkî (m)",
    "y": " b. armenîkî (m)"
  },
  {
    "x": "hermet (m)",
    "y": " b. hermete (m)"
  },
  {
    "x": "hermete (m)",
    "y": " bayan"
  },
  {
    "x": "hermîn kerdene",
    "y": " 1)çalmak\r\n~2)araklamak (argo), aşırmak (argo) "
  },
  {
    "x": "hermînkerdiş (n)",
    "y": " çalma"
  },
  {
    "x": "hermînkerdî, -ye",
    "y": " çalıntı"
  },
  {
    "x": "hermuşk (n)",
    "y": " köstebek "
  },
  {
    "x": "herom, -i",
    "y": " b. heram, -e"
  },
  {
    "x": "herone",
    "y": " b. herane"
  },
  {
    "x": "herone kerdene",
    "y": " b. herane kerdene"
  },
  {
    "x": "herone xwi nayîne",
    "y": " b. herane xo nayene"
  },
  {
    "x": "heroş (n)",
    "y": " b. hargûş (n)"
  },
  {
    "x": "heroşe",
    "y": " b. hargûş, -e"
  },
  {
    "x": "herqele (n)",
    "y": " b. herrqele (n)"
  },
  {
    "x": "herqole (n)",
    "y": " b. herrqele (n)"
  },
  {
    "x": "herr (m)",
    "y": " b. herre (m)"
  },
  {
    "x": "herr û weli",
    "y": " b. herr û wele"
  },
  {
    "x": "herr û welli",
    "y": " b. herr û wele"
  },
  {
    "x": "herr û wele",
    "y": " toz toprak"
  },
  {
    "x": "herraj (n)",
    "y": " b. heraj (n)"
  },
  {
    "x": "herraj waştene",
    "y": " b. heraj waştene"
  },
  {
    "x": "herrajwaştiş (n)",
    "y": " b. herajwaştiş (n)"
  },
  {
    "x": "herre (m)",
    "y": " toprak"
  },
  {
    "x": "herre û wele xo ser ro kerdene",
    "y": " pişmanlık duyup yakınmak"
  },
  {
    "x": "herrekîyayene",
    "y": " b. herikîyayene"
  },
  {
    "x": "herrekîyayîş (n)",
    "y": " b. herikîyayîş (n)"
  },
  {
    "x": "herremişk (n)",
    "y": " b. hermuşk (n)"
  },
  {
    "x": "herrese",
    "y": " b. hereşe (n)"
  },
  {
    "x": "herre û wele xoserrokerdiş (n)",
    "y": " (mecazi) pişmanlık duyup yakınma"
  },
  {
    "x": "herrên, -e",
    "y": " topraktan yapılma"
  },
  {
    "x": "herri (m)",
    "y": " b. herre (m)"
  },
  {
    "x": "herrikîyayîne",
    "y": " b. herikîyayene"
  },
  {
    "x": "herrikîyayîş (n)",
    "y": " b. herikîyayîş (n)"
  },
  {
    "x": "herrin, -e",
    "y": " topraklı"
  },
  {
    "x": "herriş (n)",
    "y": " b. heriş (n)"
  },
  {
    "x": "herrîn, -e",
    "y": " b. herrên, -e; herrin, -e"
  },
  {
    "x": "herrqele (n)",
    "y": " kesek"
  },
  {
    "x": "herrukîyayene",
    "y": " b. herikîyayene"
  },
  {
    "x": "hers-I (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hers-II (n)",
    "y": " b. hêrs (n)"
  },
  {
    "x": "hersi (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hertim",
    "y": " sürekli, devamlı"
  },
  {
    "x": "hertim bîyene",
    "y": " süreklileşmek"
  },
  {
    "x": "hertim kerdene",
    "y": " süreklileştirmek"
  },
  {
    "x": "hertimbîyayîş (n)",
    "y": " süreklileşme"
  },
  {
    "x": "hertimî (m)",
    "y": " devamlılık, süreklilik "
  },
  {
    "x": "hertimkerdiş (n)",
    "y": " süreklileştirme"
  },
  {
    "x": "hertînî",
    "y": " şımarıklık (argo)"
  },
  {
    "x": "hertot (n)",
    "y": " asma veya üzüm teveğinin kesilmiş olan ince, kuru dalları"
  },
  {
    "x": "heruge (m)",
    "y": " b. êruge (m)"
  },
  {
    "x": "herun (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "herunde (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "herundi (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "herûm",
    "y": " b. heram, -e"
  },
  {
    "x": "herûn (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "herûndi (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "herûne",
    "y": " b. herane"
  },
  {
    "x": "herûne kerdene",
    "y": " b. herane kerdene"
  },
  {
    "x": "herûni (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "herv (n)",
    "y": " b. herb (n)"
  },
  {
    "x": "herz (n)",
    "y": " haya (erkeklik bezi), taşak, erbezi, husye"
  },
  {
    "x": "herzale-I (n)",
    "y": " çardak (ağaç üzerinde veya damlarda yapılan)"
  },
  {
    "x": "herzale-II (n)",
    "y": " b. arzêle-I (n)"
  },
  {
    "x": "herzali (n)",
    "y": " b. herzale-I (n)"
  },
  {
    "x": "herzel-I (n)",
    "y": " b. herzale-I n)"
  },
  {
    "x": "herzel-II (n)",
    "y": " b. arzêle-I (n)"
  },
  {
    "x": "hes-I (n)",
    "y": " sevgi"
  },
  {
    "x": "hes kerdene",
    "y": " sevmek, âşık olmak"
  },
  {
    "x": "hes, -e-II",
    "y": " b. heş, -e-I"
  },
  {
    "x": "hes-III (n)",
    "y": " b. hiş (n)"
  },
  {
    "x": "hes kotene",
    "y": " b. hiş kewtene"
  },
  {
    "x": "hes pira ginayene",
    "y": " yellenmek, hava kaçırmak, osurmak"
  },
  {
    "x": "hesab (n)",
    "y": " hesap"
  },
  {
    "x": "hesabê serayinî",
    "y": " yüzde hesapları (mat)"
  },
  {
    "x": "hesab kerdene",
    "y": " hesaplamak"
  },
  {
    "x": "hesabo carî (n)",
    "y": " cari hesap"
  },
  {
    "x": "hesabdar, -e",
    "y": " muhasebeci, sayman, muhasip"
  },
  {
    "x": "hesabdarîye (m)",
    "y": " muhasebe; muhasiplik saymanlık"
  },
  {
    "x": "hesabîyene",
    "y": " b. hesibîyayene"
  },
  {
    "x": "hesabkerdiş (n)",
    "y": " hesaplama"
  },
  {
    "x": "hesad (n)",
    "y": " hasat"
  },
  {
    "x": "hesan (n)",
    "y": " bileğitaşı"
  },
  {
    "x": "hesan bîyayîne",
    "y": " b. hesan bîyene"
  },
  {
    "x": "hesan bîyene",
    "y": " bilenmek"
  },
  {
    "x": "hesan kerdene",
    "y": " bilemek"
  },
  {
    "x": "hesanik (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "hesar, -e",
    "y": " b. hişyar, -e"
  },
  {
    "x": "hesas, -e",
    "y": " duyarlı, duygulu, hassas, duygun"
  },
  {
    "x": "hesasênî (m)",
    "y": " duygululuk, duygunluk"
  },
  {
    "x": "hesasî (m)",
    "y": " duygululuk, duygunluk"
  },
  {
    "x": "hesasîye (m)",
    "y": " duygululuk, duygunluk"
  },
  {
    "x": "hesasîyet (n)",
    "y": " duyarlık, duygululuk, hassaslık, hassasiyet, duygunluk"
  },
  {
    "x": "hesav (n)",
    "y": " b. hesab (n)"
  },
  {
    "x": "hesaw (n)",
    "y": " b. hesab (n)"
  },
  {
    "x": "hesebnayene",
    "y": " b. hesibnayene"
  },
  {
    "x": "hesebnayîş (n)",
    "y": " b. hesibnayîş (n)"
  },
  {
    "x": "hesedîyayene",
    "y": " b. hesûdîyayene"
  },
  {
    "x": "hesednayene",
    "y": " b. hesûdnayene"
  },
  {
    "x": "hesemete (m)",
    "y": " b. heşamete (m)"
  },
  {
    "x": "hesenî (m)",
    "y": " bir üzüm çeşidi"
  },
  {
    "x": "Hesenqela",
    "y": " Pasinler "
  },
  {
    "x": "hesibîyayene",
    "y": " 1)sayılmak\r\n~2)sayılmak (oyunda kabul edilmek)"
  },
  {
    "x": "hesibnayene",
    "y": " saymak"
  },
  {
    "x": "hesibnayîş (n)",
    "y": " sayma"
  },
  {
    "x": "hesidîyayene",
    "y": " b. hesûdîyayene"
  },
  {
    "x": "hesidnayene",
    "y": " b. hesûdnayene"
  },
  {
    "x": "hesil (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hesir-I (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hesir-II (m)",
    "y": " b. hesîre (m)"
  },
  {
    "x": "hesire (m)",
    "y": " gözyaşı"
  },
  {
    "x": "hesîniki (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "hesîre (m)",
    "y": " hasır"
  },
  {
    "x": "hesîri (m)",
    "y": " b. hesîre (m)"
  },
  {
    "x": "heskerda (m)",
    "y": " sevgili, sevilen"
  },
  {
    "x": "heskerde (n)",
    "y": " sevgili, sevilen"
  },
  {
    "x": "heskerdiş (n)",
    "y": " 1)sevme, âşık olma\r\n~2)sevgi"
  },
  {
    "x": "heskerdîye (m)",
    "y": " sevgili, sevilen"
  },
  {
    "x": "heskerdox, -e",
    "y": " seven, âşık"
  },
  {
    "x": "heskewtiş (n)",
    "y": " b. hişkewtiş (n)"
  },
  {
    "x": "hesnayene",
    "y": " işitmek, duymak"
  },
  {
    "x": "hesnayêne",
    "y": " b. hesnayene"
  },
  {
    "x": "hesnayine",
    "y": " b. hesnayene"
  },
  {
    "x": "hesnayîne",
    "y": " b. hesnayene"
  },
  {
    "x": "hesnayîş (n)",
    "y": " işitme, duyma"
  },
  {
    "x": "hesp, -e",
    "y": " at\r\n~Hesp de dew d’ o. (At köydedir.)"
  },
  {
    "x": "hespe (m)",
    "y": " dişi at, kısrak"
  },
  {
    "x": "hespi (m)",
    "y": " b. hespe (m)\r\n~Hespi ra bibe peya. (Attan in.)"
  },
  {
    "x": "hespiraginayîş (n)",
    "y": " yellenme, hava kaçırma, osurma"
  },
  {
    "x": "hesr-I (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hesr-II (m)",
    "y": " b. hesre (m)"
  },
  {
    "x": "hesrê ... ûntene",
    "y": " b. hesra ... antene"
  },
  {
    "x": "hesrê ... ûntiş",
    "y": " b. hesra ... antiş"
  },
  {
    "x": "hesre-I (m)",
    "y": " hasret"
  },
  {
    "x": "hesra ... antene",
    "y": " hasretini çekmek, özlemini çekmek"
  },
  {
    "x": "hesra ... antiş",
    "y": " hasretini çekme, özlemini çekme"
  },
  {
    "x": "hesre-II (m)",
    "y": " hıçkırık"
  },
  {
    "x": "hesre piro ginayene",
    "y": " hıçkırmak"
  },
  {
    "x": "hesrepiroginayîş (n)",
    "y": " hıçkırma"
  },
  {
    "x": "hesret (m)",
    "y": " b. hesrete (m)"
  },
  {
    "x": "hesretê ... ûntene",
    "y": " b. hesreta ... antene"
  },
  {
    "x": "hesretê ... ûntiş",
    "y": " b. hesreta ... antiş"
  },
  {
    "x": "hesrete (m)",
    "y": " hasret"
  },
  {
    "x": "hesreta ... antene",
    "y": " hasretini çekmek, özlemini çekmek"
  },
  {
    "x": "hesreta ... antiş",
    "y": " hasretini çekme, özlemini çekme "
  },
  {
    "x": "hesreti (m)",
    "y": " b. hesrete (m)"
  },
  {
    "x": "hesretey ... ontene",
    "y": " b. hesreta ... antene"
  },
  {
    "x": "hesretey ... ontiş",
    "y": " b. hesreta ... antiş"
  },
  {
    "x": "hesri-I (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hesrey ... ontene",
    "y": " b. hesra ... antene"
  },
  {
    "x": "hesrey ... ontiş",
    "y": " b. hesra ... antiş"
  },
  {
    "x": "hesri-II (m)",
    "y": " b. hesre-II (m)"
  },
  {
    "x": "hesri piro ginayene",
    "y": " b. hesre piro ginayene"
  },
  {
    "x": "hesripiroginayîş (n)",
    "y": " b. hesrepiroginayîş (n)"
  },
  {
    "x": "hessas, -e",
    "y": " b. hesas, -e"
  },
  {
    "x": "hessasênî (m)",
    "y": " b. hesasênî (m)"
  },
  {
    "x": "hessasî (m)",
    "y": " b. hesasî (m), hesasîye (m)"
  },
  {
    "x": "hessasîye (m)",
    "y": " b. hesasî (m), hesasîye (m)"
  },
  {
    "x": "hessasîyet (n)",
    "y": " b. hesasîyet (n)"
  },
  {
    "x": "hest, -e-I",
    "y": " koyu (sıvı için), yoğun (sıvı için)"
  },
  {
    "x": "hest bîyene",
    "y": " yoğunlaşmak (sıvı için)"
  },
  {
    "x": "hest kerdene",
    "y": " yoğunlaştırmak (sıvı için)"
  },
  {
    "x": "hest-II",
    "y": " b. heşt"
  },
  {
    "x": "hestbîyayîş (n)",
    "y": " yoğunlaşma (sıvı için)"
  },
  {
    "x": "heste (n)",
    "y": " b. este-I (n)"
  },
  {
    "x": "hesteri (n)",
    "y": " b. hesterî (n)"
  },
  {
    "x": "hesterî (n)",
    "y": " katır"
  },
  {
    "x": "hesti (n)",
    "y": " b. este-I (n)"
  },
  {
    "x": "hestir (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hestire (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hestî-I (n)",
    "y": " b. este-I (n)"
  },
  {
    "x": "hestî-II (m)",
    "y": " yoğunluk (sıvı için)"
  },
  {
    "x": "hestîye (m)",
    "y": " yoğunluk (sıvı için)"
  },
  {
    "x": "hestkerdiş (n)",
    "y": " yoğunlaştırma (sıvı için)"
  },
  {
    "x": "hestor, -i",
    "y": " b. estor,-e"
  },
  {
    "x": "hesud, -i",
    "y": " b. hesûd, -e"
  },
  {
    "x": "hesudê (m)",
    "y": " b. hesûdî (m), hesûdîye (m)"
  },
  {
    "x": "hesudê kerdene",
    "y": " b. hesûdî kerdene"
  },
  {
    "x": "hesudî (m)",
    "y": " b. hesûdî (m), hesûdîye (m)"
  },
  {
    "x": "hesudî kerdene",
    "y": " b. hesûdî kerdene"
  },
  {
    "x": "hesudîye (m)",
    "y": " b. hesûdî (m), hesûdîye (m)"
  },
  {
    "x": "hesudîye kerdene",
    "y": " b. hesûdîye kerdene"
  },
  {
    "x": "hesudnayene",
    "y": " b. hesûdnayene"
  },
  {
    "x": "hesûd, -e",
    "y": " kıskanç, günücü, hasetçi (başkasını çekemeyen)"
  },
  {
    "x": "hesûdey (m)",
    "y": " b. hesûdî (m), hesûdîye (m)"
  },
  {
    "x": "hesûdey kerdene",
    "y": " b. hesûdîye kerdene"
  },
  {
    "x": "hesûdê (m)",
    "y": " b. hesûdî (m), hesûdîye (m)"
  },
  {
    "x": "hesûdê kerdene",
    "y": " b. hesûdî kerdene"
  },
  {
    "x": "hesûdî (m)",
    "y": " kıskançlık, günü, günücülük, hasetlik (çekemezlik), çekememezlik"
  },
  {
    "x": "hesûdî kerdene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "hesûdîyayene",
    "y": " kıskanmak, günülemek, haset etmek, çekememek"
  },
  {
    "x": "hesûdîye (m)",
    "y": " kıskançlık, günü, günücülük, hasetlik (çekemezlik), çekememezlik"
  },
  {
    "x": "hesûdîye kerdene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "hesûdîyene",
    "y": " b. hesûdîyayene"
  },
  {
    "x": "hesûdnayene",
    "y": " kıskandırmak"
  },
  {
    "x": "hesûniki (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "heş, -e-I",
    "y": " ayı"
  },
  {
    "x": "heşa cemedî (m)",
    "y": " kutup ayısı"
  },
  {
    "x": "heşê cemedî (n)",
    "y": " kutup ayısı "
  },
  {
    "x": "heş-II",
    "y": " b. heşt"
  },
  {
    "x": "heş-III (n)",
    "y": " b. hiş (n)"
  },
  {
    "x": "heş kewtene",
    "y": " b. hiş kewtene"
  },
  {
    "x": "heşê ... ser şîyayene",
    "y": " b. hişê ... ser şîyene"
  },
  {
    "x": "heşê ... serşîyayîş",
    "y": " b. hişê ... serşîyayîş"
  },
  {
    "x": "heşa",
    "y": " hâşâ"
  },
  {
    "x": "heşa cematî ra",
    "y": " 1)hâşâ huzurdan\r\n~2)sizi tenzih ederim"
  },
  {
    "x": "heşa goşdaran ra",
    "y": " hâşâ huzurdan"
  },
  {
    "x": "heşa goştaran ra",
    "y": " b. heşa goşdaran ra"
  },
  {
    "x": "heşa huzurî ra",
    "y": " hâşâ huzurdan"
  },
  {
    "x": "heşa şima ra",
    "y": " 1)hâşâ huzurdan\r\n~2)sizi tenzih ederim"
  },
  {
    "x": "heşa to ra",
    "y": " 1)hâşâ huzurdan\r\n~2)seni tenzih ederim"
  },
  {
    "x": "heşada to ra",
    "y": " b. heşa to ra"
  },
  {
    "x": "heşadey to ra",
    "y": " b. heşa to ra"
  },
  {
    "x": "heşamete (m)",
    "y": " gürültü"
  },
  {
    "x": "heşar, -e",
    "y": " b. hişyar, -e"
  },
  {
    "x": "heşe (m)-I",
    "y": " b. hiş (n)"
  },
  {
    "x": "heşe kewtene",
    "y": " b. hiş kewtene"
  },
  {
    "x": "Heşe (m)-II",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "heşekewtiş (n)",
    "y": " b. hişkewtiş (n)"
  },
  {
    "x": "heşerî, -ye",
    "y": " cinsel anlamda azgınca davranan hayvan"
  },
  {
    "x": "heşerî bîyene",
    "y": " cinsel anlamda azgınca davranmak (hayvan için)"
  },
  {
    "x": "heşi (m)",
    "y": " b. heşe (m)-I"
  },
  {
    "x": "Heşi (m)",
    "y": " b. Heşe (m)"
  },
  {
    "x": "heşkewtiş (n)",
    "y": " b. hişkewtiş (n)"
  },
  {
    "x": "heşmetar mendene",
    "y": " b. heşmetkar mendene"
  },
  {
    "x": "heşmetkar mendene",
    "y": " afallamak"
  },
  {
    "x": "heşnayene",
    "y": " b. hesnayene"
  },
  {
    "x": "heşnayîş (n)",
    "y": " b. hesnayîş (n)"
  },
  {
    "x": "heşt",
    "y": " sekiz "
  },
  {
    "x": "heşt rê se",
    "y": " b. heşt sey"
  },
  {
    "x": "heşt sey",
    "y": " sekiz yüz "
  },
  {
    "x": "heştay",
    "y": " seksen"
  },
  {
    "x": "heştayin, -e",
    "y": " sekseninci "
  },
  {
    "x": "heşte-I",
    "y": " b. heştay"
  },
  {
    "x": "heşte-II (n)",
    "y": " b. hewte (n)"
  },
  {
    "x": "heştes",
    "y": " b. heştês"
  },
  {
    "x": "heştey",
    "y": " b. heştay"
  },
  {
    "x": "heştê",
    "y": " b. heştay"
  },
  {
    "x": "heştês",
    "y": " on sekiz "
  },
  {
    "x": "heştin, -e",
    "y": " sekizinci"
  },
  {
    "x": "heştire (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "heştîyes",
    "y": " b. heştês"
  },
  {
    "x": "heştyes",
    "y": " b. heştês"
  },
  {
    "x": "heşvil (m)",
    "y": " b. heşvile (m)"
  },
  {
    "x": "heşvile (m)",
    "y": " şakayık"
  },
  {
    "x": "heşvilike (m)",
    "y": " şakayık"
  },
  {
    "x": "heşviliki (m)",
    "y": " b. heşvilike (m)"
  },
  {
    "x": "het (n)",
    "y": " yön, yan, taraf, cihet"
  },
  {
    "x": "het kerdene",
    "y": " desteklemek"
  },
  {
    "x": "het şîyene",
    "y": " (kadınla) cinsel ilişkide bulunmak"
  },
  {
    "x": "hetê berzînî (n)",
    "y": " baş ucu"
  },
  {
    "x": "hetê bingeyênî (zh)",
    "y": " ana yönler"
  },
  {
    "x": "hetê çorşmeyî ra waro hesas",
    "y": " çevre yönünden duyarlı alan"
  },
  {
    "x": "hetê dorûverî ra waro hîsgêr",
    "y": " çevre yönünden duyarlı alan"
  },
  {
    "x": "hetê ehlaqî ra",
    "y": " b. hetê exlaqî ra"
  },
  {
    "x": "hetê exlaqî ra",
    "y": " ahlakça, ahlaken"
  },
  {
    "x": "hetê mîyanênî (zh)",
    "y": " ara yönler"
  },
  {
    "x": "hetê ra",
    "y": " bir taraftan, bir yandan"
  },
  {
    "x": "hetê ... ra",
    "y": " 1) -e doğru \r\n~2)-e göre, göre, bakımından"
  },
  {
    "x": "hetê ... ser",
    "y": " -e doğru"
  },
  {
    "x": "hetê verî",
    "y": " cephe (yapılar için)"
  },
  {
    "x": "hetê verî yê parselî (n)",
    "y": " parsel cephesi"
  },
  {
    "x": "heto bîn",
    "y": " diğer taraf"
  },
  {
    "x": "heto bîn ra",
    "y": " diğer taraftan, diğer yandan"
  },
  {
    "x": "heto çep (n)",
    "y": " sol taraf"
  },
  {
    "x": "heto ju ra",
    "y": " b. heto yew ra"
  },
  {
    "x": "heto rast/raşt (n)",
    "y": " sağ taraf"
  },
  {
    "x": "heto yew ra",
    "y": " bir taraftan, bir yandan"
  },
  {
    "x": "ohet (n)",
    "y": " o taraf, öte"
  },
  {
    "x": "ohet ra",
    "y": " o taraftan, o tarafta, o tarafa"
  },
  {
    "x": "heta",
    "y": " 1)dek (-e dek), kadar\r\n~Heta dewe şo. (Köye dek/kadar git.)\r\n~\t\r\n~Heta şan vinde. (Akşama dek/kadar dur.)\r\n~2)hatta\r\n~Heta o zî ame. (Hatta o da geldi.)"
  },
  {
    "x": "heta çaqe",
    "y": " diz boyu (dize kadar olan)"
  },
  {
    "x": "heta çok",
    "y": " diz boyu (dize kadar olan)"
  },
  {
    "x": "heta fek",
    "y": " dopdolu, ağzına kadar"
  },
  {
    "x": "heta qirrike",
    "y": " diz boyu(çok miktarda)"
  },
  {
    "x": "heta saqe",
    "y": " diz boyu (dize kadar olan)"
  },
  {
    "x": "heta zanî",
    "y": " diz boyu (dize kadar olan)"
  },
  {
    "x": "heta zoni",
    "y": " b. heta zanî"
  },
  {
    "x": "heta zûnî",
    "y": " b. heta zanî, "
  },
  {
    "x": "hetahet (n)",
    "y": " çevre"
  },
  {
    "x": "hetahet girewtene",
    "y": " abluka etmek, çevrelemek, ihata etmek"
  },
  {
    "x": "hetahetgirewtiş (n)",
    "y": " abluka etme, çevreleme"
  },
  {
    "x": "hetana",
    "y": " b. hetanî"
  },
  {
    "x": "hetanê",
    "y": " b. hetanî"
  },
  {
    "x": "hetanî",
    "y": " dek (-e dek), kadar\r\n~Hetanî dewe şo. (Köye dek/kadar git.)\r\n~\t\r\n~Hetanî şan vinde. (Akşama dek/kadar dur.)"
  },
  {
    "x": "hetanî çaqe",
    "y": " diz boyu (dize kadar olan)"
  },
  {
    "x": "hetanî çok",
    "y": " diz boyu (dize kadar olan)"
  },
  {
    "x": "hetanî fek",
    "y": " dopdolu, ağzına kadar"
  },
  {
    "x": "hetanî qirrike",
    "y": " diz boyu(çok miktarda)"
  },
  {
    "x": "hetanî saqe",
    "y": " diz boyu (dize kadar olan)"
  },
  {
    "x": "hetanî verê fekî",
    "y": " dopdolu, ağzına kadar"
  },
  {
    "x": "hetanî zanî",
    "y": " diz boyu (dize kadar olan)"
  },
  {
    "x": "hetat (n)",
    "y": " b. hetahet (n)"
  },
  {
    "x": "hete (m)",
    "y": " b. het (n)"
  },
  {
    "x": "heta bîni ra",
    "y": " b. heto bîn ra"
  },
  {
    "x": "heta ju ra",
    "y": " b. heto yew ra"
  },
  {
    "x": "heta jû ra",
    "y": " b. heto yew ra"
  },
  {
    "x": "heta yew ra",
    "y": " b. heto yew ra "
  },
  {
    "x": "heta yewi ra",
    "y": " b. heto yew ra "
  },
  {
    "x": "heta zu ra",
    "y": " b. heto yew ra"
  },
  {
    "x": "heta zû ra",
    "y": " b. heto yew ra"
  },
  {
    "x": "heta ... ra",
    "y": " b. hetê ... ra"
  },
  {
    "x": "heta ... ser",
    "y": " b. hetê ... ser"
  },
  {
    "x": "hetek (n)",
    "y": " b. het (n)"
  },
  {
    "x": "hetekîyayîş (n)",
    "y": " hetikîyayîş (n)"
  },
  {
    "x": "heteknayene",
    "y": " b. hetiknayene "
  },
  {
    "x": "heteknayîş (n)",
    "y": " b. hetiknayîş (n)"
  },
  {
    "x": "hetey (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "hetê ... kewtene",
    "y": " dalga geçmek\r\n~Hetê mêrikî mekewe. (Herifle dalga geçme.)"
  },
  {
    "x": "hetî ... kotene",
    "y": " b. hetê ... kewtene"
  },
  {
    "x": "hetêyewbînankerdiş (n)",
    "y": " dayanışma"
  },
  {
    "x": "heti (m)",
    "y": " b. het (n)"
  },
  {
    "x": "aheti",
    "y": " b. ohet"
  },
  {
    "x": "aheti ra",
    "y": " b. êhetî ra, wet ra, ohet ra"
  },
  {
    "x": "hetikîyayîş (n)",
    "y": " kirlenme "
  },
  {
    "x": "hetikîyayene",
    "y": " kirlenmek"
  },
  {
    "x": "hetiknayîş (n)",
    "y": " kirletme"
  },
  {
    "x": "hetiknayox, -e",
    "y": " kirletici"
  },
  {
    "x": "hetin, -e",
    "y": " cepheli"
  },
  {
    "x": "hetîya (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "hetkar, -e",
    "y": " yardımcı, asistan"
  },
  {
    "x": "hetkara/ê kameramane",
    "y": " kameraman yardımcısı"
  },
  {
    "x": "hetkara/ê kameramanî",
    "y": " kameraman yardımcısı"
  },
  {
    "x": "hetkara mudira hunerî/senetî (m, sn)",
    "y": " sanat yönetmeni asistanı"
  },
  {
    "x": "hetkara mudirê hunerî/senetî (m, sn)",
    "y": " sanat yönetmeni asistanı"
  },
  {
    "x": "hetkara/hetkarê mudira pêroyîye",
    "y": " genel müdür yardımcısı (müdire için)"
  },
  {
    "x": "hetkara/hetkarê mudirê pêroyî",
    "y": " genel müdür yardımcısı"
  },
  {
    "x": "hetkara/hetkarê rejîsore (sn)",
    "y": " yönetmen yardımcısı"
  },
  {
    "x": "hetkara/hetkarê rejîsorî (sn)",
    "y": " yönetmen yardımcısı"
  },
  {
    "x": "hetkara/hetkarê sekretera pêroyîye",
    "y": " genel sekreter yardımcısı (kadın sekreter için)"
  },
  {
    "x": "hetkara/hetkarê sekreterê pêroyî",
    "y": " genel sekreter yardımcısı"
  },
  {
    "x": "hetkara/hetkarê sereka şaredarî",
    "y": " belediye başkan yardımcısı (kadın başkan için)"
  },
  {
    "x": "hetkara/hetkarê sereka beledîya",
    "y": " belediye başkan yardımcısı (kadın başkan için)"
  },
  {
    "x": "hetkara/hetkarê sereke",
    "y": " başkan yardımcısı (kadın başkan için)"
  },
  {
    "x": "hetkara/hetkarê serekê şaredarî",
    "y": " belediye başkan yardımcısı"
  },
  {
    "x": "hetkara/hetkarê serekê beledîya",
    "y": " belediye başkan yardımcısı"
  },
  {
    "x": "hetkara/ê serekî",
    "y": " başkan yardımcısı"
  },
  {
    "x": "hetkarê mudira hunerî/senetî (n, sn)",
    "y": " sanat yönetmeni asistanı"
  },
  {
    "x": "hetkarê mudirê hunerî/senetî (n, sn)",
    "y": " sanat yönetmeni asistanı"
  },
  {
    "x": "karo hetkar (n)",
    "y": " yardımcı fiil"
  },
  {
    "x": "hetkarî (m)",
    "y": " yardım"
  },
  {
    "x": "hetkarîye (m)",
    "y": " 1)yardım\r\n~2)asistanlık"
  },
  {
    "x": "hetkarîye kerdene",
    "y": " yardım etmek"
  },
  {
    "x": "hetkarîya edlîye (m)",
    "y": " adlî yardım"
  },
  {
    "x": "hetkarîya eynîye (m)",
    "y": " ayni katkı"
  },
  {
    "x": "hetkarîya lezkanîye (m)",
    "y": " ilk yardım"
  },
  {
    "x": "hetkerdiş (n)",
    "y": " destekleme"
  },
  {
    "x": "heto",
    "y": " b. heta"
  },
  {
    "x": "heto qirike",
    "y": " b. heta qirrike"
  },
  {
    "x": "heton",
    "y": " b. hetanî"
  },
  {
    "x": "hetona",
    "y": " b. hetanî"
  },
  {
    "x": "hetonî",
    "y": " b. hetanî"
  },
  {
    "x": "hetonî verî fekî",
    "y": " b. hetanî verê fekî"
  },
  {
    "x": "hetonî qirike",
    "y": " b. hetanî qirrike"
  },
  {
    "x": "hetşîyayîş (n)",
    "y": " (kadınla) cinsel ilişkide bulunma"
  },
  {
    "x": "hetta",
    "y": " b. heta"
  },
  {
    "x": "hetû",
    "y": " b. heta"
  },
  {
    "x": "hetûn",
    "y": " b. heta"
  },
  {
    "x": "hetûna",
    "y": " b. hetanî"
  },
  {
    "x": "hetûnî",
    "y": " b. hetanî"
  },
  {
    "x": "hetûnî qirike",
    "y": " b. hetanî qirrike"
  },
  {
    "x": "hetûnî verî fekî",
    "y": " b. hetanî verê fekî"
  },
  {
    "x": "hetya (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "hev-I (n)",
    "y": " b. heb-I (n)"
  },
  {
    "x": "hev-II (m)",
    "y": " b. hebe-I (m)"
  },
  {
    "x": "heval, -e",
    "y": " arkadaş"
  },
  {
    "x": "hevala (m)",
    "y": " düğün sırasında bir direk veya çubuğun ucuna bağlanarak düğün evinin yüksekçe bir yerine dikilen veya gelin götürme sırasında -çubuğa bağlı halde- elde taşınan bez parçası"
  },
  {
    "x": "hevalcêvî (n)",
    "y": " b. hevalcêwî (n)"
  },
  {
    "x": "hevalcêwî (n)",
    "y": " ikiz"
  },
  {
    "x": "hevalco (n)",
    "y": " döleşi (insanda), meşime "
  },
  {
    "x": "hevalîye (m)",
    "y": " arkadaşlık"
  },
  {
    "x": "hevaltî (m)",
    "y": " arkadaşlık"
  },
  {
    "x": "hevalzava (n)",
    "y": " bacanak"
  },
  {
    "x": "hevalzavayîye (m)",
    "y": " bacanaklık"
  },
  {
    "x": "hevalzilf (n)",
    "y": " b. hevalzava (n)"
  },
  {
    "x": "heve (m)",
    "y": " b. hebe-I (m) "
  },
  {
    "x": "heve ve heve",
    "y": " b. hebe bi hebe"
  },
  {
    "x": "hevey",
    "y": " b. hebê"
  },
  {
    "x": "hevê",
    "y": " b. hebê"
  },
  {
    "x": "hevlege (m)",
    "y": " b. ewlege (m)"
  },
  {
    "x": "hevring (n)",
    "y": " kırkı, kırklık"
  },
  {
    "x": "hevring kerdene",
    "y": " 1)(davar için) kırkmak\r\n~2)(mecazi) saçı kötü tıraş etmek"
  },
  {
    "x": "hevringkerdiş (n)",
    "y": " kırkma (davar için)"
  },
  {
    "x": "hevrîng (n)",
    "y": " b. hevring (n)"
  },
  {
    "x": "hevrîng kerdene",
    "y": " b. hevring kerdene"
  },
  {
    "x": "hevrîşim (n)",
    "y": " b. birîsim (n)"
  },
  {
    "x": "hevrîşimên, -i",
    "y": " b. birîsimên, -e"
  },
  {
    "x": "hevrîşimin, -i",
    "y": " b. birîsimin, -e"
  },
  {
    "x": "hevsar (n)",
    "y": " yular"
  },
  {
    "x": "hevur (n)",
    "y": " b. hevûr (n)"
  },
  {
    "x": "hevûr (n)",
    "y": " teke, erkeç"
  },
  {
    "x": "hew-I (n, z)",
    "y": " b. o (n, z) "
  },
  {
    "x": "hew-II (n, s)",
    "y": " b. o (n, s)"
  },
  {
    "x": "hew-III (m)",
    "y": " b. hebe-I (m)"
  },
  {
    "x": "hewê",
    "y": " b. hebê"
  },
  {
    "x": "hew-V (n)",
    "y": " b. heb-I (n)"
  },
  {
    "x": "hew-VI (m)",
    "y": " b. hewe-I (m)"
  },
  {
    "x": "hew-VII (m)",
    "y": " (köpeğin çıkardığı ses) hav"
  },
  {
    "x": "hew-hew kerdene",
    "y": " havlamak, ürümek"
  },
  {
    "x": "hew-hewe (m)",
    "y": " (köpeğin çıkardığı ses) hav"
  },
  {
    "x": "hew-hewkerdiş (n)",
    "y": " havlama"
  },
  {
    "x": "hew-VIII (n)",
    "y": " b. hewa-I (n)"
  },
  {
    "x": "hew nayene",
    "y": " b. hewa nayene"
  },
  {
    "x": "hew-IX (n)",
    "y": " b. hewn (n)"
  },
  {
    "x": "hewa-I (n)",
    "y": " 1)hava, iklim\r\n~2)rüzgâr, yel\r\n~3)romatizma\r\n~Hewa yeno. (Rûzgâr esiyor.) "
  },
  {
    "x": "hewa dayene",
    "y": " 1)kaldırmak, havalandırmak\r\n~2)savurmak\r\n~3)defnetmek, saklamak"
  },
  {
    "x": "hewa dekewtene",
    "y": " romatizmalı olmak"
  },
  {
    "x": "hewa dîyayene",
    "y": " kaldırılmak"
  },
  {
    "x": "hewa fînîyayene",
    "y": " kaldırılmak"
  },
  {
    "x": "hewa fîştene",
    "y": " kaldırmak, havalandırmak"
  },
  {
    "x": "hewa nayene",
    "y": " 1)kaldırmak, havalandırmak\r\n~2)defnetmek, saklamak"
  },
  {
    "x": "hewa nîyayene",
    "y": " kaldırılmak"
  },
  {
    "x": "hewa vistene",
    "y": " b. hewa fîştene"
  },
  {
    "x": "hewayê ... girewtene",
    "y": " havasını almak"
  },
  {
    "x": "hewayê ... veradayene",
    "y": " havasını almak"
  },
  {
    "x": "hewayê zemîne (n)",
    "y": " ortam havası"
  },
  {
    "x": "hewa-II (n)",
    "y": " b. hawa-I (n)"
  },
  {
    "x": "hewayê ... kerdene",
    "y": " b. hawayê ... kerdene"
  },
  {
    "x": "hewayê ... kerdiş",
    "y": " b. hawayê ... kerdiş"
  },
  {
    "x": "hewadar kerdene",
    "y": " havalandırmak "
  },
  {
    "x": "hewadarkerdiş (n)",
    "y": " havalandırma"
  },
  {
    "x": "hewadayîş (n)",
    "y": " 1)kaldırma, havalandırma\r\n~2)defnetme, saklama"
  },
  {
    "x": "hewadîyayîş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "hewafînîyayîş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "hewafîştiş (n)",
    "y": " kaldırma, havalandırma"
  },
  {
    "x": "hewale (n)",
    "y": " 1)havale\r\n~2)havale (tıpta)"
  },
  {
    "x": "hewale kerdene",
    "y": " havale etmek, \t"
  },
  {
    "x": "hewale vîyarnayene",
    "y": " havale geçirmek (tıpta)"
  },
  {
    "x": "hewalêyin, -e",
    "y": " b. awleyin, -e"
  },
  {
    "x": "hewalêyî (zh)",
    "y": " b. awleyî (zh)"
  },
  {
    "x": "hewalêyî vetene",
    "y": " b. awleyî vetene"
  },
  {
    "x": "hewanas, -e",
    "y": " meteorolog"
  },
  {
    "x": "hewanasî (m)",
    "y": " meteoroloji"
  },
  {
    "x": "hewanasîye (m)",
    "y": " meteoroloji"
  },
  {
    "x": "hewanayîş (n)",
    "y": " 1)kaldırma, havalandırma\r\n~2)defnetme, saklama"
  },
  {
    "x": "hewane (m)",
    "y": " 1)dibek (içinde bulgur vb. dövülen büyük havan), soku\r\n~2)havan"
  },
  {
    "x": "hewani (m)",
    "y": " b. hewane (m)"
  },
  {
    "x": "hewanîyayîş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "hewar (m)",
    "y": " b. haware (m)"
  },
  {
    "x": "hey hewar!",
    "y": " b. hey hawar!"
  },
  {
    "x": "hewarayîr (n)",
    "y": " havayolu"
  },
  {
    "x": "heware bîyayene",
    "y": " b. eware bîyene"
  },
  {
    "x": "heware kerdene",
    "y": " b. eware kerdene"
  },
  {
    "x": "hewarî (m)",
    "y": " yas"
  },
  {
    "x": "hewavistiş (n)",
    "y": " b. hewafîştiş (n)"
  },
  {
    "x": "hewayene-I",
    "y": " havlamak, ürümek"
  },
  {
    "x": "hewayene-II",
    "y": " b. huyayene"
  },
  {
    "x": "hewayêne",
    "y": " b. hewayene-I"
  },
  {
    "x": "hewayin, -e",
    "y": " romatizmalı"
  },
  {
    "x": "hewayin bîyene",
    "y": " romatizmalı olmak"
  },
  {
    "x": "hewayîş-I (n)",
    "y": " havlama, ürüme"
  },
  {
    "x": "hewayîş-II (n)",
    "y": " b. huyayîş (n)"
  },
  {
    "x": "hewcedar, -e",
    "y": " muhtaç"
  },
  {
    "x": "hewcedarî (m)",
    "y": " ihtiyaç, gereksinim"
  },
  {
    "x": "hewcedarîye (m)",
    "y": " ihtiyaç, gereksinim"
  },
  {
    "x": "hewe-I (m)",
    "y": " kez, kere, defa, sefer\r\n~“Hewe peyê hewan de est a.” (vateyê verênan)"
  },
  {
    "x": "hewê",
    "y": " bir kez"
  },
  {
    "x": "hewêk",
    "y": " bir kez"
  },
  {
    "x": "hewî",
    "y": " b. hewê"
  },
  {
    "x": "hewe-II (n, z)",
    "y": " b. o (n, z)\r\n~hewe amo: o gelmiş\r\n~Hewe şiyo be koy? (O nereye gitmiş?)"
  },
  {
    "x": "hewe-III",
    "y": " b. hewa-I (n)"
  },
  {
    "x": "hewe nayene",
    "y": " b. hewa nayene"
  },
  {
    "x": "hewe nîyayene",
    "y": " b. hewa nîyayene"
  },
  {
    "x": "hewe-IV (n)",
    "y": " b. huye-I (n)"
  },
  {
    "x": "hewelîyayene",
    "y": " b. hewilîyayene"
  },
  {
    "x": "hewelîyayîş (n)",
    "y": " b. hewilîyayîş (n)"
  },
  {
    "x": "hewelnayene",
    "y": " b. hewilnayene"
  },
  {
    "x": "hewelnayîş (n)",
    "y": " b. hewilnayîş (n)"
  },
  {
    "x": "hewen (m)",
    "y": " b. hewane (m)"
  },
  {
    "x": "Hewenc (n)",
    "y": " Bozova"
  },
  {
    "x": "heweyayene",
    "y": " b. hewîyayene "
  },
  {
    "x": "heweyayîş (n)",
    "y": " b. hewîyayîş (n)"
  },
  {
    "x": "heweyene",
    "y": " b. hewîyayene"
  },
  {
    "x": "heweyîş (n)",
    "y": " b. hewîyayîş (n)"
  },
  {
    "x": "heweynayene",
    "y": " b. hewênayene"
  },
  {
    "x": "heweynayîş (n)",
    "y": " b. hewênayîş (n)"
  },
  {
    "x": "hewê",
    "y": " b. hebê"
  },
  {
    "x": "hewêk",
    "y": " b. hebêk"
  },
  {
    "x": "Hewêl (n)",
    "y": " Baykan"
  },
  {
    "x": "hewênayene",
    "y": " 1)ağırlamak\r\n~2)barındırmak"
  },
  {
    "x": "hewênayîş",
    "y": " 1)ağırlama\r\n~2)barındırma"
  },
  {
    "x": "hewi-I (m)",
    "y": " b. hewe-I (m)"
  },
  {
    "x": "hewi-II (n)",
    "y": " b. huye-II (n)"
  },
  {
    "x": "hewi-III (n)",
    "y": " b. hewa-I (n)"
  },
  {
    "x": "hewic (m)",
    "y": " b. hewice (m)"
  },
  {
    "x": "hewice (m)",
    "y": " havuç"
  },
  {
    "x": "hewici (n)",
    "y": " b. hewice (m)"
  },
  {
    "x": "hewikî (zh)",
    "y": " b. hebikî (zh)"
  },
  {
    "x": "hewilîyayene",
    "y": " yeri değişmek "
  },
  {
    "x": "hewilîyayîş (n)",
    "y": " yeri değişme"
  },
  {
    "x": "hewilnayene",
    "y": " yerini değiştirmek"
  },
  {
    "x": "hewilnayîş (n)",
    "y": " yerini değiştirme"
  },
  {
    "x": "hewilnayîşê gemarinîye (n)",
    "y": " kirliliğin kaydırılması"
  },
  {
    "x": "hewirs (n)",
    "y": " b. wers-I (n)"
  },
  {
    "x": "hewî-I (m)",
    "y": " kuma "
  },
  {
    "x": "hewî-II (m)",
    "y": " havlama"
  },
  {
    "x": "hewîk (zh)",
    "y": " b. hebikî (zh)"
  },
  {
    "x": "hewînayene (mêmûn)",
    "y": " b. hewênayene (mêman)"
  },
  {
    "x": "hewînayîş (mêmûn) (n)",
    "y": " b. hewênayîş (mêman) (n)"
  },
  {
    "x": "hewîyayene",
    "y": " barınmak"
  },
  {
    "x": "hewîyayîş (n)",
    "y": " barınma"
  },
  {
    "x": "hewîye (m)",
    "y": " kuma"
  },
  {
    "x": "hewl, -e",
    "y": " b. hol, -e"
  },
  {
    "x": "hewl bîyene",
    "y": " b. hol bîyene"
  },
  {
    "x": "hewl kerdene",
    "y": " b. hol kerdene"
  },
  {
    "x": "hewla (m)",
    "y": " b. helwa (m)"
  },
  {
    "x": "hewlbîyayîş (n) ",
    "y": " b. holbîyayîş (n)"
  },
  {
    "x": "hewley (m)",
    "y": " b. holîye (m)"
  },
  {
    "x": "hewlêy (zh)",
    "y": " b. awleyî (zh)"
  },
  {
    "x": "hewli (m)",
    "y": " b. hole (m)"
  },
  {
    "x": "hewlî (m)",
    "y": " b. holî (m)"
  },
  {
    "x": "hewlîni (m)",
    "y": " b. holî (m)"
  },
  {
    "x": "hewlîye (m)",
    "y": " b. holîye (m)"
  },
  {
    "x": "hewlkerdiş (n) ",
    "y": " b. holkerdiş-I (n)"
  },
  {
    "x": "hewn (n)",
    "y": " 1)uyku\r\n~2)düş, rüya"
  },
  {
    "x": "hewn a kerdene",
    "y": " 1)uyutmak\r\n~2)söndürmek"
  },
  {
    "x": "hewn a şîyene",
    "y": " 1)uyumak\r\n~2)batmak (yıldız için)"
  },
  {
    "x": "hewnê mergî (n)",
    "y": " ölüm uykusu"
  },
  {
    "x": "hewno giran (n)",
    "y": " ağır uyku"
  },
  {
    "x": "hewna-I",
    "y": " yine, gene, tekrar"
  },
  {
    "x": "hewna-II",
    "y": " b. hîna-I"
  },
  {
    "x": "hewnakerdiş (n)",
    "y": " 1)uyutma\r\n~2)söndürme"
  },
  {
    "x": "hewnakerê şewatî (n)",
    "y": " yangın söndürücü"
  },
  {
    "x": "hewnaşîyayîş (n)",
    "y": " 1)uyuma\r\n~2)batma (yıldız için)"
  },
  {
    "x": "hewnayene",
    "y": " b. huynayene"
  },
  {
    "x": "hewneyîne",
    "y": " b. ewnîyayene"
  },
  {
    "x": "hewnayîş-I (n)",
    "y": " b. huynayîş (n)"
  },
  {
    "x": "hewnayîş-II (n)",
    "y": " b. hewanayîş (n)"
  },
  {
    "x": "hewneyîne",
    "y": " b. ewnîyayene"
  },
  {
    "x": "hewneyîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "hewniyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "hewnîyayîne",
    "y": " b. ewnîyayene"
  },
  {
    "x": "hewnîyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "hewo (n, s)",
    "y": " b. o (n, s)"
  },
  {
    "x": "hewr (n)",
    "y": " bulut"
  },
  {
    "x": "hewro sîya (n)",
    "y": " kara bulut"
  },
  {
    "x": "hewro tarî (n)",
    "y": " kara bulut"
  },
  {
    "x": "talpê hewrî (n)",
    "y": " b. tilpê hewrî (n)"
  },
  {
    "x": "tilpê hewrî (n)",
    "y": " bulut kümesi "
  },
  {
    "x": "tulpê hewrî (n)",
    "y": " b. tilpê hewrî (n)"
  },
  {
    "x": "tulpê horî (n)",
    "y": " b. tilpê hewrî (n)"
  },
  {
    "x": "hewra (s)",
    "y": " bulutlu"
  },
  {
    "x": "hewraî (m)",
    "y": " b. hewrayî (m)"
  },
  {
    "x": "hewrayê (m)",
    "y": " b. hewrayî (m)"
  },
  {
    "x": "hewrayî (m)",
    "y": " bulutluluk"
  },
  {
    "x": "hewrêr (m)",
    "y": " b. hewrêre (m)"
  },
  {
    "x": "hewrêre (m)",
    "y": " kavak"
  },
  {
    "x": "hewrêri (m)",
    "y": " b. hewrêre (m)"
  },
  {
    "x": "hewrês (n)",
    "y": " b. vewrês (n)"
  },
  {
    "x": "hewrin (n)",
    "y": " bulutlu "
  },
  {
    "x": "hewriney (m)",
    "y": " b. hewrinîye (m)"
  },
  {
    "x": "hewring (n)",
    "y": " b. hevring (n)"
  },
  {
    "x": "hewrinîye (m)",
    "y": " bulutluluk"
  },
  {
    "x": "hewrs (n)",
    "y": " b. wers-I (n)"
  },
  {
    "x": "hewryes (n)",
    "y": " b. vewrês (n)"
  },
  {
    "x": "hews (n)",
    "y": " b. hepis (n)"
  },
  {
    "x": "hewsar (n)",
    "y": " b. hevsar (n)"
  },
  {
    "x": "hewş-I (n)",
    "y": " avlu"
  },
  {
    "x": "hewş-II",
    "y": " b. hoş-I"
  },
  {
    "x": "hewş kerdene",
    "y": " b. hoş kerdene"
  },
  {
    "x": "hewşe (m)",
    "y": " b. hewş-I (n)"
  },
  {
    "x": "hewşe (m)",
    "y": " b. hewşo (n)"
  },
  {
    "x": "hewşo (n)",
    "y": " ağıl, arkaç"
  },
  {
    "x": "hewt",
    "y": " yedi"
  },
  {
    "x": "hewt sey",
    "y": " yedi yüz "
  },
  {
    "x": "hewt, -i",
    "y": " b. hut, -e"
  },
  {
    "x": "hewtanayîne",
    "y": " b. ewtanayene"
  },
  {
    "x": "hewtanayîş (n)",
    "y": " b. ewtanayîş (n)"
  },
  {
    "x": "hewtay",
    "y": " yetmiş"
  },
  {
    "x": "hewtayin, -e",
    "y": " yetmişinci "
  },
  {
    "x": "hewte (n)",
    "y": " hafta"
  },
  {
    "x": "hewteyê îtfaîya (n)",
    "y": " itfaiye haftası"
  },
  {
    "x": "hewtes",
    "y": " b. hewtês"
  },
  {
    "x": "hewtês",
    "y": " on yedi "
  },
  {
    "x": "hewtin, -e",
    "y": " yedinci "
  },
  {
    "x": "hewtîyes",
    "y": " b. hewtês"
  },
  {
    "x": "hewtyes",
    "y": " b. hewtês"
  },
  {
    "x": "hewuye (m)",
    "y": " b. hewîye (m)"
  },
  {
    "x": "hewz, -e-I",
    "y": " yeşil"
  },
  {
    "x": "hewz-II (m)",
    "y": " b. hewze (m)"
  },
  {
    "x": "hewza (m)",
    "y": " havza"
  },
  {
    "x": "hewze (m)",
    "y": " havuz"
  },
  {
    "x": "hewza denişnayîşî (m)",
    "y": " çöktürme havuzu (itfaiye için)"
  },
  {
    "x": "hewzi (m)",
    "y": " b. hewze (m)"
  },
  {
    "x": "Hex (n)",
    "y": " b. Heq-II (n)"
  },
  {
    "x": "hey-I",
    "y": " hele\r\n~Hey bê tîya. (Hele buraya gel.)"
  },
  {
    "x": "hey-II",
    "y": " b. hewê"
  },
  {
    "x": "hey-III",
    "y": " 1)b. hebe-I (m)\r\n~2)b. hebê"
  },
  {
    "x": "heyk",
    "y": " b. hebêk, -e"
  },
  {
    "x": "heyke",
    "y": " b. hebêk, -e"
  },
  {
    "x": "hey-IV",
    "y": " hey!"
  },
  {
    "x": "hey hawar!",
    "y": " imdat, medet"
  },
  {
    "x": "hey hewar!",
    "y": " b. hey hawar!"
  },
  {
    "x": "hey-V",
    "y": " ah\r\n~Hey gidî! (Ah be!)"
  },
  {
    "x": "heya-I (zr)",
    "y": " evet"
  },
  {
    "x": "heya-II",
    "y": " b. heta"
  },
  {
    "x": "heya-III (m, z)",
    "y": " b. a (m, z)\r\n~Heya şîya be koy? (O nereye gitmiş?)"
  },
  {
    "x": "heya-IV (m)",
    "y": " ar, utanma, utanç"
  },
  {
    "x": "heya kerdene",
    "y": " utanmak"
  },
  {
    "x": "heyakerdiş (n)",
    "y": " utanma"
  },
  {
    "x": "heyan",
    "y": " b. hetanî"
  },
  {
    "x": "heyana",
    "y": " b. hetanî"
  },
  {
    "x": "heyanî",
    "y": " b. hetanî"
  },
  {
    "x": "heyat (n)",
    "y": " hayat, yaşam"
  },
  {
    "x": "heyatê karî (m)",
    "y": " çalışma hayatı, iş hayatı"
  },
  {
    "x": "heyatê xebate (n)",
    "y": " çalışma hayatı, iş hayatı"
  },
  {
    "x": "heyate (m)",
    "y": " salon, ayvan"
  },
  {
    "x": "heyata sînema (m)",
    "y": " sinema salonu"
  },
  {
    "x": "heyati (m)",
    "y": " b. heyate (m)"
  },
  {
    "x": "heyba (n)",
    "y": " b. heywan (n)"
  },
  {
    "x": "heyban, -i",
    "y": " b. heywan, -e"
  },
  {
    "x": "heydut, -e",
    "y": " haydut"
  },
  {
    "x": "heyecan (n)",
    "y": " coşku"
  },
  {
    "x": "heyelan (n)",
    "y": " heyelan, göçü, kayşa, toprak kayması "
  },
  {
    "x": "heyelûn (n)",
    "y": " b. heyelan (n)"
  },
  {
    "x": "heyet (n)",
    "y": " kurul, heyet"
  },
  {
    "x": "heyetê etîkî (n)",
    "y": " etik kurul"
  },
  {
    "x": "heyetê hemahengî û kontrolî (n)",
    "y": " eşgüdüm ve denetleme kurulu"
  },
  {
    "x": "heyetê herême yê pawitişê zengînîyanê kulturîyan (n)",
    "y": " kültürel varlıkları koruma bölge kurulu"
  },
  {
    "x": "heyetê herême yê seveknayîşê dewlemendîyanê kulturîyan (n)",
    "y": " kültürel varlıkları koruma bölge kurulu"
  },
  {
    "x": "heyetê îdareyî",
    "y": " yönetim kurulu"
  },
  {
    "x": "heyetê kontrolî (n)",
    "y": " denetleme kurulu"
  },
  {
    "x": "heyetê koordînasyon û teftîşî (n)",
    "y": " eşgüdüm ve denetleme kurulu"
  },
  {
    "x": "heyetê mişewreyî (n)",
    "y": " danışma kurulu"
  },
  {
    "x": "heyeto pêroyî",
    "y": " genel kurul"
  },
  {
    "x": "heyetê taqîbkerdiş-nirxnayîşî (n)",
    "y": " izleme-değerlendirme kurulu"
  },
  {
    "x": "heyetê teftîşî (n)",
    "y": " denetleme kurulu, teftiş kurulu"
  },
  {
    "x": "heyetê wezîran (n)",
    "y": " bakanlar kurulu, icra vekilleri heyeti"
  },
  {
    "x": "heyey (zh, z)",
    "y": " b. ê-III (zh, z)"
  },
  {
    "x": "heyf-I (n)",
    "y": " öç, intikam"
  },
  {
    "x": "heyf girewtene",
    "y": " 1)öç almak, intikam almak\r\n~2)acısını çıkarmak"
  },
  {
    "x": "heyfê ... girewtene",
    "y": " acısını çıkarmak"
  },
  {
    "x": "heyf-II (n)",
    "y": " yazık"
  },
  {
    "x": "heyf-III (m)",
    "y": " b. hewe-I (m)"
  },
  {
    "x": "heyfgirewtiş (n)",
    "y": " öç alma, intikam alma"
  },
  {
    "x": "heyî-I (zh, z)",
    "y": " b. ê-III (zh, z)"
  },
  {
    "x": "heyî-II (zh, s)",
    "y": " b. ê (zh, s)"
  },
  {
    "x": "heykel (n)",
    "y": " heykel"
  },
  {
    "x": "heykeltiraş, -e",
    "y": " heykeltıraş"
  },
  {
    "x": "heyli (m)",
    "y": " b. hêle-I (m)"
  },
  {
    "x": "heyn",
    "y": " b. winî, ayhawa"
  },
  {
    "x": "heyna-I",
    "y": " b. hewna-I"
  },
  {
    "x": "heyna-II",
    "y": " b. hîna-I"
  },
  {
    "x": "heyni (n)",
    "y": " b. hênî-I (n)"
  },
  {
    "x": "heynî",
    "y": " b. winî, ayhawa"
  },
  {
    "x": "heyo",
    "y": " b. heta"
  },
  {
    "x": "heyon",
    "y": " b. hetanî"
  },
  {
    "x": "heyona",
    "y": " b. hetanî"
  },
  {
    "x": "heyonî",
    "y": " b. hetanî"
  },
  {
    "x": "heyra",
    "y": " b. heyran, -e"
  },
  {
    "x": "heyran, -e",
    "y": " hayran"
  },
  {
    "x": "heyran û heşmetkar bîyene",
    "y": " hayran kalmak, çok beğenmek, hayran olmak"
  },
  {
    "x": "heyranûheşmetkarbîyayîş (n)",
    "y": " hayran kalma, çok beğenme, hayran olma"
  },
  {
    "x": "heyro",
    "y": " b. heyran, -e"
  },
  {
    "x": "heyron, -i",
    "y": " b. heyran, -e"
  },
  {
    "x": "heyron û heşmetkar bîyayene",
    "y": " b. heyran û heşmetkar bîyene"
  },
  {
    "x": "heyronûheşmetkarbîyayîş (n)",
    "y": " b. heyranûheşmetkarbîyayîş (n)"
  },
  {
    "x": "heyrû",
    "y": " b. heyran, -e"
  },
  {
    "x": "heyrûn, -i",
    "y": " b. heyran, -e"
  },
  {
    "x": "heyrûn û heşmetkar bîyayene",
    "y": " b. heyran û heşmetkar bîyene"
  },
  {
    "x": "heyrûnûheşmetkarbîyayîş (n)",
    "y": " b. heyranûheşmetkarbîyayîş (n)"
  },
  {
    "x": "heyşt",
    "y": " b. heşt"
  },
  {
    "x": "heyşt sey",
    "y": " b. heşt sey "
  },
  {
    "x": "heyştay",
    "y": " b. heştay"
  },
  {
    "x": "heyştayin, -i",
    "y": " b. heştayin, -e"
  },
  {
    "x": "heyştês",
    "y": " b. heştês "
  },
  {
    "x": "heyştin, -i",
    "y": " b. heştin, -e"
  },
  {
    "x": "heytheytik, -e",
    "y": " ciddiyetsiz, gayriciddi"
  },
  {
    "x": "heyû",
    "y": " b. heta"
  },
  {
    "x": "heyûn",
    "y": " b. hetanî"
  },
  {
    "x": "heyûna",
    "y": " b. hetanî"
  },
  {
    "x": "heyûnî",
    "y": " b. hetanî"
  },
  {
    "x": "heywa-I (m)",
    "y": " ayva"
  },
  {
    "x": "heywa-II (n)",
    "y": " b. heywan (n) "
  },
  {
    "x": "heywan, -e",
    "y": " hayvan"
  },
  {
    "x": "heywan raverdayene",
    "y": " otlaması için hayvanı araziye başıboş salmak"
  },
  {
    "x": "heywan raverdayêne",
    "y": " b. heywan raverdayene"
  },
  {
    "x": "heywana awîye (m)",
    "y": " su hayvanı"
  },
  {
    "x": "heywana/ê barî",
    "y": " yük hayvanı (at, katır, eşek)"
  },
  {
    "x": "heywana kedîye (m)",
    "y": " evcil hayvan"
  },
  {
    "x": "heywana kovîye (m)",
    "y": " vahşi hayvan"
  },
  {
    "x": "heywana koyîye (m)",
    "y": " vahşi hayvan"
  },
  {
    "x": "heywana oyîye (m)",
    "y": " b. heywana awîye (m)"
  },
  {
    "x": "heywana wehşîye (m)",
    "y": " vahşi hayvan"
  },
  {
    "x": "heywanê çêyî (zh)",
    "y": " b. heywanê keyeyî (zh)"
  },
  {
    "x": "heywanê kedî (zh)",
    "y": " evcil hayvanlar"
  },
  {
    "x": "heywanê kedîkerdeyî (zh)",
    "y": " evcilleştirilmiş hayvanlar"
  },
  {
    "x": "heywanê keyeyî (zh)",
    "y": " ev hayvanları"
  },
  {
    "x": "heywanê keyî (zh)",
    "y": " b. heywanê keyeyî"
  },
  {
    "x": "heywanê nêçîrî (zh)",
    "y": " av hayvanları"
  },
  {
    "x": "heywanê seydî (zh)",
    "y": " av hayvanları"
  },
  {
    "x": "heywano awî (n)",
    "y": " su hayvanı"
  },
  {
    "x": "heywano kedî (n)",
    "y": " evcil hayvan"
  },
  {
    "x": "heywano kovî (n)",
    "y": " vahşi hayvan"
  },
  {
    "x": "heywano koyî (n)",
    "y": " vahşi hayvan"
  },
  {
    "x": "heywano oyî (n)",
    "y": " b. heywano awî (n)"
  },
  {
    "x": "heywano wehşî (n)",
    "y": " vahşi hayvan"
  },
  {
    "x": "heywanmiqatkerdox, -e",
    "y": " hayvan bakıcısı"
  },
  {
    "x": "heywanqayîtkerdox, -e",
    "y": " hayvan bakıcısı"
  },
  {
    "x": "heywanraverdayîs (n)",
    "y": " b. heywanraverdayîş (n)"
  },
  {
    "x": "heywanraverdayîş (n)",
    "y": " otlaması için hayvanı araziye başıboş salma"
  },
  {
    "x": "heywanveradayîş (n)",
    "y": " otlaması için hayvanı araziye başıboş salma"
  },
  {
    "x": "heywayêre (m)",
    "y": " ayva ağacı"
  },
  {
    "x": "heywe (n)",
    "y": " b. huye-I (n)"
  },
  {
    "x": "heywêr (m)",
    "y": " b. heywayêre (m)"
  },
  {
    "x": "heywo (n)",
    "y": " b. heywan (n)"
  },
  {
    "x": "heywon, -i",
    "y": " b. heywan, -e"
  },
  {
    "x": "heywona/ê barî",
    "y": " b. heywana/ê barî"
  },
  {
    "x": "heywonê keyî (zh)",
    "y": " b. heywanê keyeyî (zh)"
  },
  {
    "x": "heywonê seydî (zh)",
    "y": " b. heywanê seydî (zh)"
  },
  {
    "x": "heywû (n)",
    "y": " b. heywan (n)"
  },
  {
    "x": "heywûn, -i",
    "y": " b. heywan, -e"
  },
  {
    "x": "heywûnê/î bar",
    "y": " b. heywana/ê barî"
  },
  {
    "x": "heywûnê/î barî",
    "y": " b. heywana/ê barî"
  },
  {
    "x": "heywûnî keyî (zh)",
    "y": " b. heywanê keyeyî (zh)"
  },
  {
    "x": "heywûnî seydî (zh)",
    "y": " b. heywanê seydî (zh)"
  },
  {
    "x": "hez (n)",
    "y": " b. hes-I (n)"
  },
  {
    "x": "hez kerdene",
    "y": " b. hes kerdene"
  },
  {
    "x": "hezaj (n)",
    "y": " b. hezaz (n)"
  },
  {
    "x": "hezajî (m)",
    "y": " b. hezaz (n)"
  },
  {
    "x": "hezar",
    "y": " bin "
  },
  {
    "x": "hezaramper (n)",
    "y": " kiloamper"
  },
  {
    "x": "hezaran",
    "y": " binler (mat)"
  },
  {
    "x": "hezare (n)",
    "y": " safra kesesi "
  },
  {
    "x": "hezarin, -e",
    "y": " bininci "
  },
  {
    "x": "hezarî",
    "y": " binler (mat)"
  },
  {
    "x": "hezarlître (n)",
    "y": " kilolitre"
  },
  {
    "x": "hezarmetre (n)",
    "y": " kilometre"
  },
  {
    "x": "hezarmetrekare (n)",
    "y": " kilometrekare"
  },
  {
    "x": "hezarmetremîkab (n)",
    "y": " kilometreküp"
  },
  {
    "x": "hezartone (m)",
    "y": " kiloton"
  },
  {
    "x": "hezaz (n)",
    "y": " 1)heyelan, göçü, kayşa, toprak kayması\r\n~2)batak"
  },
  {
    "x": "hezaze (m)",
    "y": " b. hezaz (n)"
  },
  {
    "x": "hezber (n)",
    "y": " b. ezber (n)"
  },
  {
    "x": "hezber kerdene",
    "y": " b. ezber kerdene"
  },
  {
    "x": "heze-I (m)",
    "y": " batak"
  },
  {
    "x": "heze-II (m)",
    "y": " b. heje (m)"
  },
  {
    "x": "heze-III",
    "y": " b. se-III"
  },
  {
    "x": "hezek",
    "y": " b. seke"
  },
  {
    "x": "hezeki",
    "y": " b. seke"
  },
  {
    "x": "hezey",
    "y": " b. sey-I"
  },
  {
    "x": "hezê",
    "y": " b. sey-I"
  },
  {
    "x": "hezhezîyayene",
    "y": " çürüyüp dökülmek"
  },
  {
    "x": "hezhezîyayîş (n)",
    "y": " çürüyüp dökülme"
  },
  {
    "x": "hezike (m)",
    "y": " b. hejike (m)"
  },
  {
    "x": "hezikî (zh)",
    "y": " b. hejikî (zh)"
  },
  {
    "x": "hezîmet (n)",
    "y": " bozgun, hezimet, yenilgi"
  },
  {
    "x": "hezîrane (m)",
    "y": " haziran"
  },
  {
    "x": "hezîrani (m)",
    "y": " b. hezîrane (m)"
  },
  {
    "x": "hezîroni (m)",
    "y": " b. hezîrane (m)"
  },
  {
    "x": "hezîrûn (m)",
    "y": " b. hezîrane (m)"
  },
  {
    "x": "hezkerdiş (n)",
    "y": " b. heskerdiş (n)"
  },
  {
    "x": "hezkerdox, -e",
    "y": " b. heskerdox, -e "
  },
  {
    "x": "hezm kerdene",
    "y": " sindirmek"
  },
  {
    "x": "hezmkerdiş (n)",
    "y": " sindirme"
  },
  {
    "x": "hezoke (m)",
    "y": " bataklık"
  },
  {
    "x": "hezret",
    "y": " hazret"
  },
  {
    "x": "Hezret Elî",
    "y": " b. Hezretî Elî"
  },
  {
    "x": "Hezret Mehemed",
    "y": " b. Hezretî Mihemed"
  },
  {
    "x": "Hezretê Alî",
    "y": " b. Hezretî Alî"
  },
  {
    "x": "Hezretê Elî",
    "y": " b. Hezretî Elî"
  },
  {
    "x": "Hezretê Meheme",
    "y": " b. Hezretî Mihemed"
  },
  {
    "x": "Hezretî Alî",
    "y": " Hazreti Ali"
  },
  {
    "x": "Hezretî Elî",
    "y": " Hazreti Ali"
  },
  {
    "x": "Hezretî Îsa",
    "y": " Hazreti İsa"
  },
  {
    "x": "Hezretî Meheme",
    "y": " b. Hezretî Mihemed"
  },
  {
    "x": "Hezretî Miheme",
    "y": " b. Hezretî Mihemed"
  },
  {
    "x": "Hezretî Mihemed",
    "y": " Hazreti Muhammed"
  },
  {
    "x": "Hezretî Olî",
    "y": " b. Hezretî Elî, Hezretî Alî"
  },
  {
    "x": "Hezro (n)",
    "y": " Hazro"
  },
  {
    "x": "hezver (n)",
    "y": " b. ezber (n)"
  },
  {
    "x": "hê-I",
    "y": " 1)b. hey-I\r\n~2)b. hîna"
  },
  {
    "x": "hê-II",
    "y": " b. yê-V"
  },
  {
    "x": "hê-III (m)",
    "y": " b. hewe-I (m)"
  },
  {
    "x": "hê-IV",
    "y": " gelmesi için keçiye seslenirken kullanılan sözcük"
  },
  {
    "x": "hê hê",
    "y": " gelmesi için keçiye seslenirken kullanılan sözcük"
  },
  {
    "x": "hêca, -ye",
    "y": " değerli, kıymetli"
  },
  {
    "x": "hêca kerdene",
    "y": " değerlendirmek, kıymetlendirmek"
  },
  {
    "x": "hêcakerdiş (n)",
    "y": " değerlendirme, kıymetlendirme"
  },
  {
    "x": "hêç, -e-I",
    "y": " (hayvan için cinsel anlamda) azgın"
  },
  {
    "x": "hêç bîyayene",
    "y": " b. hêç bîyene"
  },
  {
    "x": "hêç bîyene",
    "y": " azgınlaşmak, azmak (hayvan için cinsel anlamda)"
  },
  {
    "x": "hêç-II",
    "y": " b. hîç"
  },
  {
    "x": "hêç kerdene",
    "y": " b. hîç kerdene"
  },
  {
    "x": "hêçbîyayîş (n)",
    "y": " azgınlaşma (hayvan için cinsel anlamda)"
  },
  {
    "x": "hêçe (m)",
    "y": " b. hîç"
  },
  {
    "x": "hêçey-I (m)",
    "y": " b. hêçî (m)"
  },
  {
    "x": "hêçey-II (m)",
    "y": " b. hîçîye (m)"
  },
  {
    "x": "hêçê (m)",
    "y": " b. hêçî (m)"
  },
  {
    "x": "hêçi-I (m)",
    "y": " b. hêçe (m)"
  },
  {
    "x": "hêçi-II",
    "y": " b. hîç"
  },
  {
    "x": "hêçi kerdene",
    "y": " b. hîç kerdene"
  },
  {
    "x": "hêçikerdiş (n)",
    "y": " b. hîçkerdiş (n)"
  },
  {
    "x": "hêçî (m)",
    "y": " azgınlık (hayvan için cinsel anlamda)"
  },
  {
    "x": "hêçkerdiş (n)",
    "y": " b. hîçkerdiş (n)"
  },
  {
    "x": "hêdî-I",
    "y": " yavaş"
  },
  {
    "x": "hêdî-II",
    "y": " b. êdî"
  },
  {
    "x": "hêdî-hêdî",
    "y": " giderek, gittikçe, yavaş yavaş"
  },
  {
    "x": "hêdîg",
    "y": " b. hêdîka"
  },
  {
    "x": "hêdîk",
    "y": " b. hêdîka"
  },
  {
    "x": "hêdîka",
    "y": " yavaşça"
  },
  {
    "x": "hêdîke",
    "y": " b. hêdîka"
  },
  {
    "x": "hêdîkî",
    "y": " b. hêdîka"
  },
  {
    "x": "hêdîna",
    "y": " b. êdî"
  },
  {
    "x": "hêf-I (n)",
    "y": " b. heyf-I (n)"
  },
  {
    "x": "hêf girotene",
    "y": " b. heyf girewtene"
  },
  {
    "x": "hêf-II (n)",
    "y": " b. heyf-II (n)"
  },
  {
    "x": "hêf-III (m)",
    "y": " b. hewe-I (m)"
  },
  {
    "x": "hêfna",
    "y": " b. hewna-I"
  },
  {
    "x": "hêga (n)",
    "y": " tarla"
  },
  {
    "x": "hêga dêmdayene",
    "y": " anız bozmak"
  },
  {
    "x": "hêgayo awî (n)",
    "y": " sulak tarla"
  },
  {
    "x": "hêgawo axpîn (n)",
    "y": " b. hêgayo axpîn (n)"
  },
  {
    "x": "hêgawo axpîr (n)",
    "y": " hêgayo axpîn (n)"
  },
  {
    "x": "hêgayo axpîn (n)",
    "y": " verimli tarla"
  },
  {
    "x": "hêgayo filhan (n)",
    "y": " herk"
  },
  {
    "x": "hêge (n)",
    "y": " b. êge (n)"
  },
  {
    "x": "hêge kerdene",
    "y": " b. êge kerdene"
  },
  {
    "x": "hêgin, -e",
    "y": " becerikli, kabiliyetli, yetenekli"
  },
  {
    "x": "hêginî (m)",
    "y": " kabiliyet, yetenek"
  },
  {
    "x": "hêginîye (m)",
    "y": " beceriklilik"
  },
  {
    "x": "hêginîyî (m)",
    "y": " beceriklilik"
  },
  {
    "x": "hêh",
    "y": " b. hê"
  },
  {
    "x": "hêh hêh",
    "y": " b. hê hê"
  },
  {
    "x": "Hêjîd",
    "y": " b. Yezîd"
  },
  {
    "x": "hêjîre (m)",
    "y": " incir"
  },
  {
    "x": "hêjîrêre (m)",
    "y": " incir ağacı"
  },
  {
    "x": "hêkat (m)",
    "y": " b. hîkaye (m)"
  },
  {
    "x": "hêkate (m)",
    "y": " b. hîkaye (m)"
  },
  {
    "x": "hêkaya (m)",
    "y": " b. hîkaye (m)"
  },
  {
    "x": "hêl (m)",
    "y": " b. hêle-I (m)"
  },
  {
    "x": "hêl kerdene",
    "y": " b. hil kerdene"
  },
  {
    "x": "hêlaneke (m)",
    "y": " salıncak "
  },
  {
    "x": "hêle-I (m)",
    "y": " oyun merkezi (oyunda el değdirilmesi gereken direk, taş vb. veya içinden çıkılmaması gereken, sınırları belirlenmiş yer vb.)"
  },
  {
    "x": "hêle-II (m)",
    "y": " b. hele (m)"
  },
  {
    "x": "hêlekane (m)",
    "y": " b. hêlaneke (m)"
  },
  {
    "x": "hêli (m)",
    "y": " b. hêle-I (m)"
  },
  {
    "x": "hêlik (n)",
    "y": " haya (erkeklik bezi), taşak, erbezi, husye"
  },
  {
    "x": "hêlike (m)",
    "y": " b. hêlaneke (m) "
  },
  {
    "x": "hêliki (m)",
    "y": " b. hêlaneke (m)"
  },
  {
    "x": "hêlî (n)",
    "y": " b. helî (n)"
  },
  {
    "x": "hêlîyo çal (n)",
    "y": " b. helîyo çal (n)"
  },
  {
    "x": "hêlîyo çol (n)",
    "y": " b. helîyo çal (n)"
  },
  {
    "x": "hêlîn (n)",
    "y": " b. halîn (n)"
  },
  {
    "x": "hêlîsa (m)",
    "y": " b. helîsa (m)"
  },
  {
    "x": "hêlkerdiş (n)",
    "y": " b. hilkerdiş (n)"
  },
  {
    "x": "hêluge (m)",
    "y": " çirişotu"
  },
  {
    "x": "hêlun (m)",
    "y": " b. êlule (m)"
  },
  {
    "x": "hêm-I (n)",
    "y": " taraf, yan, yaka (kıyı)"
  },
  {
    "x": "hêm-II (n)",
    "y": " b. hîm (n)"
  },
  {
    "x": "hême (m)",
    "y": " an, lahza"
  },
  {
    "x": "hêmgîn (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "hêmi",
    "y": " b. heme-I"
  },
  {
    "x": "hêmû",
    "y": " b. heme-I"
  },
  {
    "x": "hemûyan",
    "y": " b. hemîne"
  },
  {
    "x": "hên-I",
    "y": " b. winî "
  },
  {
    "x": "hên-II",
    "y": " b. hinî-III"
  },
  {
    "x": "hên-III",
    "y": " b. ûne"
  },
  {
    "x": "hêna-I",
    "y": " b. hewna-I"
  },
  {
    "x": "hêna-II",
    "y": " b. winî"
  },
  {
    "x": "hêna-III",
    "y": " b. hîna-I"
  },
  {
    "x": "hêne-I (n)",
    "y": " b. hênî-I (n)"
  },
  {
    "x": "hêne-II (m)",
    "y": " b. hing-I (n)"
  },
  {
    "x": "hêne-III (n)",
    "y": " b. îne-I (n)"
  },
  {
    "x": "hêne-IV",
    "y": " b. hewna-I"
  },
  {
    "x": "hêneke",
    "y": " b. hinîke"
  },
  {
    "x": "Hêni (n)",
    "y": " Hani"
  },
  {
    "x": "hêni (n)",
    "y": " b. hênî-I (n)"
  },
  {
    "x": "hênî-I (n)",
    "y": " çeşme, pınar"
  },
  {
    "x": "hênî-II",
    "y": " b. winî"
  },
  {
    "x": "hêrame (n)",
    "y": " b. hêramî (n)"
  },
  {
    "x": "hêramî (n)",
    "y": " gelinlik (gelinin nikâh veya düğün sırasında giydiği giysi)"
  },
  {
    "x": "hêrayeni",
    "y": " b. hîrayene"
  },
  {
    "x": "hêrayîş (n)",
    "y": " b. hîrayîş (n)"
  },
  {
    "x": "hêrd (n)",
    "y": " b. erd (n)"
  },
  {
    "x": "hêrdîs (m)",
    "y": " b. erdîşe (m)"
  },
  {
    "x": "hêrdîse (m)",
    "y": " b. erdîşe (m)"
  },
  {
    "x": "hêrey (n)",
    "y": " b. berey (n), erey (n)"
  },
  {
    "x": "hêri ",
    "y": " b. hîrê"
  },
  {
    "x": "hêris (n)",
    "y": " b. heriş (n)"
  },
  {
    "x": "hêrise (m)",
    "y": " b. erdîşe (m)"
  },
  {
    "x": "hêrisi (m)",
    "y": " b. erdîşe (m)"
  },
  {
    "x": "hêriş (n)",
    "y": " akın, saldırı, hücum"
  },
  {
    "x": "hêriş kerdene",
    "y": " akın etmek, saldırmak, hücum etmek"
  },
  {
    "x": "hêrişkar, -e",
    "y": " saldırgan, agresif"
  },
  {
    "x": "hêrişkarîye (m)",
    "y": " saldırganlık, agresiflik"
  },
  {
    "x": "hêrînayene",
    "y": " satın almak"
  },
  {
    "x": "hêrînayîş (n)",
    "y": " satın alma"
  },
  {
    "x": "plankerdişê hêrînayîşî (n)",
    "y": " satın alma planlaması "
  },
  {
    "x": "hêrînayîş-rotiş (n)",
    "y": " alım satım, alışveriş"
  },
  {
    "x": "hêrîs (n)",
    "y": " b. heriş (n)"
  },
  {
    "x": "hêrnayene",
    "y": " b. hêrînayene"
  },
  {
    "x": "hêrnayêne",
    "y": " b. hêrînayene"
  },
  {
    "x": "hêrnayîş (n)",
    "y": " b. hêrînayîş (n)"
  },
  {
    "x": "hêrs (n)",
    "y": " dargınlık, küskünlük, kızgınlık, öfke; burukluk"
  },
  {
    "x": "hêrs bîyene",
    "y": " darılmak, küsmek, kızmak (öfkelenmek, sinirlenmek), asabileşmek"
  },
  {
    "x": "hêrs kerdene",
    "y": " darıltmak, kızdırmak, küstürmek, sinirlendirmek, öfkelendirmek"
  },
  {
    "x": "hêrs kewtene",
    "y": " öfkelenmek, sinirlenmek, asabileşmek"
  },
  {
    "x": "hêrs ra ser fîştene",
    "y": " sinirlendirmek"
  },
  {
    "x": "hêrsê ... ameyene",
    "y": " öfkelenmek, sinirlenmek, asabileşmek"
  },
  {
    "x": "hêrsê ... ameyîş",
    "y": " kızma (öfkelenme, sinirlenme), asabileşme"
  },
  {
    "x": "hêrsê ... ardene",
    "y": " öfkelendirmek, sinirlendirmek\r\n~Aşmîne reyna hêrsanê Zerrîweşî ana. (Aşmîne yine Zerrîweş’i sinirlendiriyor.)"
  },
  {
    "x": "hêrsê ... ardiş",
    "y": " kızdırma"
  },
  {
    "x": "zaf hêrs bîyene",
    "y": " ateş püskürmek"
  },
  {
    "x": "hêrsbîyaya (m)",
    "y": " kızgın (öfkeli), küskün, küs, dargın"
  },
  {
    "x": "hêrsbîyaye (n)",
    "y": " kızgın (öfkeli), küskün, küs, dargın"
  },
  {
    "x": "hêrsbîyayê (m)",
    "y": " b. hêrsbîyayîye (m)"
  },
  {
    "x": "hêrsbîyayîş (n)",
    "y": " kızma (öfkelenme, sinirlenme), asabileşme, küsme"
  },
  {
    "x": "hêrsbîyayîye (m)",
    "y": " kızgın (öfkeli), küskün, küs, dargın"
  },
  {
    "x": "hêrsin, -e",
    "y": " kızgın (öfkeli), sinirli"
  },
  {
    "x": "hêrskerdiş (n)",
    "y": " kızdırma, küstürme, darıltma"
  },
  {
    "x": "hêrskewtiş (n)",
    "y": " kızma (öfkelenme, sinirlenme), asabileşme"
  },
  {
    "x": "hêrsnik",
    "y": " b. hêrsin, -e; hêrsokin, -e"
  },
  {
    "x": "hêrsokin, -e",
    "y": " kızgın (öfkeli), sinirli"
  },
  {
    "x": "hêrş (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hêruge (m)",
    "y": " b. êruge (m)"
  },
  {
    "x": "hêrûm (n, m)",
    "y": " b. heram, -e"
  },
  {
    "x": "hêsir-I (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hêsir-II (m)",
    "y": " b. hesre-I (m)"
  },
  {
    "x": "hêsire (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hêsîr (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hêsîre (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hêstîre (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hêş (n)",
    "y": " b. hiş (n)"
  },
  {
    "x": "hêş kewtene",
    "y": " b. hiş kewtene"
  },
  {
    "x": "hêş kotene",
    "y": " b. hiş kewtene"
  },
  {
    "x": "hêşdê ... kewtene",
    "y": " b. hişê ... kewtene"
  },
  {
    "x": "hêşdê ... kewtiş",
    "y": " b. hişê ... kewtiş"
  },
  {
    "x": "hêşê ... kotiş",
    "y": " b. hişê ... kewtiş"
  },
  {
    "x": "hêşê ... ser şîyayene",
    "y": " b. hişê ... ser şîyene"
  },
  {
    "x": "hêşê ... serşîyayîş",
    "y": " b. hişê ... serşîyayîş"
  },
  {
    "x": "hêşê ... ver şîyayene",
    "y": " b. hişê ... ver şîyene"
  },
  {
    "x": "hêşê ... verşîyayîş",
    "y": " b. hişê ... verşîyayîş"
  },
  {
    "x": "hêşê xo vila kerdene",
    "y": " b. hişê xo vila kerdene"
  },
  {
    "x": "hêştê ... kewtene",
    "y": " b. hişê ... kewtene"
  },
  {
    "x": "hêştê ... kewtiş",
    "y": " b. hişê ... kewtiş"
  },
  {
    "x": "hêşar, -e",
    "y": " b. hişyar, -e"
  },
  {
    "x": "hêşayine",
    "y": " b. hîşayene"
  },
  {
    "x": "hêşayîş (n)",
    "y": " b. hîşayîş (n)"
  },
  {
    "x": "Hêşe (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "hêşin, -e",
    "y": " b. kesk, -e; hewz, -e-I"
  },
  {
    "x": "hêşîn, -e",
    "y": " b. kesk, -e; hewz, -e-I"
  },
  {
    "x": "hêşkewtiş (n)",
    "y": " b. hişkewtiş (n)"
  },
  {
    "x": "hêşkotiş (n)",
    "y": " b. hişkewtiş (n)"
  },
  {
    "x": "hêşt",
    "y": " b. heşt"
  },
  {
    "x": "hêşt sey",
    "y": " b. heşt sey"
  },
  {
    "x": "hêştay",
    "y": " b. heştay"
  },
  {
    "x": "hêştê (n)",
    "y": " b. hewte (n)"
  },
  {
    "x": "hêştire (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hêştîn, -e",
    "y": " b. heştin, -e"
  },
  {
    "x": "hêştîre (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hêşyar, -e",
    "y": " b. hişyar, -e"
  },
  {
    "x": "hêşyayîş (n)",
    "y": " b. hîşayîş (n)"
  },
  {
    "x": "hêt-I",
    "y": " b. het (n)"
  },
  {
    "x": "hêtî ... ser",
    "y": " b. hetê ... ser"
  },
  {
    "x": "hêt-II",
    "y": " b. hête (m)"
  },
  {
    "x": "hêt ... kotene",
    "y": " b. hetê ... kewtene"
  },
  {
    "x": "hête (m)",
    "y": " but, uyluk"
  },
  {
    "x": "hêtim",
    "y": " b. yetîm, -e"
  },
  {
    "x": "hêtîm, -i",
    "y": " b. yetîm, -e"
  },
  {
    "x": "hêtûna",
    "y": " b. hetanî"
  },
  {
    "x": "hêverê",
    "y": " ilkönce, en önce"
  },
  {
    "x": "hêverî",
    "y": " b. hêverê"
  },
  {
    "x": "hêvêr",
    "y": " b. hêverê"
  },
  {
    "x": "hêvî (m)",
    "y": " ümit, umut"
  },
  {
    "x": "hêvîya ... vindertene",
    "y": " b. hêvîya ... vindetene"
  },
  {
    "x": "hêvîya ... vindetene",
    "y": " -i beklemek"
  },
  {
    "x": "hêwn (n)",
    "y": " b. hewn (n)"
  },
  {
    "x": "hêwn a kerdene",
    "y": " b. hewn a kerdene"
  },
  {
    "x": "hêwn a şîyayene",
    "y": " b. hewn a şîyene"
  },
  {
    "x": "hêwn ra şîyayene",
    "y": " b. hewn a şîyene"
  },
  {
    "x": "hêwnakerdiş (n)",
    "y": " b. hewnakerdiş (n)"
  },
  {
    "x": "hêwnaşîyayîş (n)",
    "y": " b. hewnaşîyayîş (n)"
  },
  {
    "x": "hêy-I",
    "y": " b. hey-I"
  },
  {
    "x": "hêy-II",
    "y": " b. hê-IV"
  },
  {
    "x": "hêy hêy",
    "y": " b. hê hê"
  },
  {
    "x": "hêya-I (zr)",
    "y": " b. heya-I (zr)"
  },
  {
    "x": "hêya-II",
    "y": " b. heta"
  },
  {
    "x": "hêya-III (m)",
    "y": " b. heya (m)"
  },
  {
    "x": "hêya kerdene",
    "y": " b. heya kerdene"
  },
  {
    "x": "hêyakerdiş (n)",
    "y": " b. heyakerdiş (n)"
  },
  {
    "x": "hêyanî",
    "y": " b. hetanî"
  },
  {
    "x": "hêyat (n)",
    "y": " b. heyat (n)"
  },
  {
    "x": "hêyf (n)",
    "y": " b. heyf-I n)"
  },
  {
    "x": "hêyf guretene",
    "y": " b. heyf girewtene"
  },
  {
    "x": "hêyran, -e",
    "y": " b. heyran, -e"
  },
  {
    "x": "hêyşt",
    "y": " b. heşt"
  },
  {
    "x": "hêywan, -e",
    "y": " b. heywan, -e"
  },
  {
    "x": "hêywanê çêyî (zh)",
    "y": " b. heywanê keyeyî (zh)"
  },
  {
    "x": "hêz (n)",
    "y": " güç, kuvvet, çelim, dayanma gücü, mecal, takat"
  },
  {
    "x": "hêzê caardişî (n)",
    "y": " yaptırım, müeyyide"
  },
  {
    "x": "hêzê deryayîyî (zh)",
    "y": " bahriye (deniz kuvvetleri)"
  },
  {
    "x": "hêzdar, -e",
    "y": " güçlü, kuvvetli, çelimli"
  },
  {
    "x": "hêzdar kerdene",
    "y": " güçlendirmek"
  },
  {
    "x": "hevalkar (n)",
    "y": " belirteç, zarf"
  },
  {
    "x": "hevalname (n)",
    "y": " sıfat"
  },
  {
    "x": "hewî (m)",
    "y": " dışlık"
  },
  {
    "x": "hewînî (m)",
    "y": " dışlık"
  },
  {
    "x": "hewînîya ... ameyene",
    "y": " dışlığı gelmek "
  },
  {
    "x": "hewîya ... ameyene",
    "y": " dışlığı gelmek"
  },
  {
    "x": "hewnî (m)",
    "y": " b. hewînî (m)"
  },
  {
    "x": "hewnîya ... omeyîne",
    "y": " b. hewînîya ... ameyene"
  },
  {
    "x": "hêzdarkerdiş (n)",
    "y": " güçlendirme"
  },
  {
    "x": "hêze (m)",
    "y": " b. heje (m)"
  },
  {
    "x": "hêzike (m)",
    "y": " b. hejike (m)"
  },
  {
    "x": "hêzin, -e",
    "y": " güçlü, kuvvetli"
  },
  {
    "x": "Hêzîd",
    "y": " b. Yezîd"
  },
  {
    "x": "hêzîron (m)",
    "y": " b. hezîrane (m)"
  },
  {
    "x": "hêzkîlo (n)",
    "y": " kilogramağırlık, kilogramkuvvet"
  },
  {
    "x": "hêzpeym (n)",
    "y": " kuvvetölçer, dinamometre"
  },
  {
    "x": "hiçê (m)",
    "y": " b. hêçî (m)"
  },
  {
    "x": "hidud (n)",
    "y": " b. hudud (n)"
  },
  {
    "x": "hij (n)",
    "y": " b. hêriş (n)"
  },
  {
    "x": "hij kerdene",
    "y": " b. hêriş kerdene"
  },
  {
    "x": "hijdîa (n)",
    "y": " b. ejdeha (n)"
  },
  {
    "x": "hil kerdene",
    "y": " kaldırmak"
  },
  {
    "x": "hile (m)",
    "y": " b. hele-II (m)"
  },
  {
    "x": "hili-I (m)",
    "y": " b. hele-I (m)"
  },
  {
    "x": "hili-II (m)",
    "y": " b. hele-II (m)"
  },
  {
    "x": "hila êrî (m)",
    "y": " b. hela êreyî (m)"
  },
  {
    "x": "hila nimajî (m)",
    "y": " b. hela nimajî (m)"
  },
  {
    "x": "hili di",
    "y": " b. hele de"
  },
  {
    "x": "hilî-I (n, m)",
    "y": " üvey"
  },
  {
    "x": "hilî-II (n)",
    "y": " b. helî (n)"
  },
  {
    "x": "hilke (n)",
    "y": " kova"
  },
  {
    "x": "hilkeyê çopî (n)",
    "y": " çöp kovası"
  },
  {
    "x": "hilkerdiş (n)",
    "y": " kaldırma"
  },
  {
    "x": "hilpaene",
    "y": " b. hilpayene"
  },
  {
    "x": "hilpayene",
    "y": " 1)hıçkırmak\r\n~2)hüngür hüngür ağlamak"
  },
  {
    "x": "hilpayîs (n)",
    "y": " b. hilpayîş (n)"
  },
  {
    "x": "hilpayîş (n)",
    "y": " 1)hıçkırma\r\n~2)hüngür hüngür ağlama"
  },
  {
    "x": "hilphilp",
    "y": " hüngür hüngür"
  },
  {
    "x": "hilphilp bermayene",
    "y": " hüngür hüngür ağlamak"
  },
  {
    "x": "hilphilpbermayîş (n)",
    "y": " hüngür hüngür ağlama"
  },
  {
    "x": "hilpî (m)",
    "y": " hıçkırık"
  },
  {
    "x": "hilqik (m)",
    "y": " b. hilqike (m)"
  },
  {
    "x": "hilqike (m)",
    "y": " hıçkırık"
  },
  {
    "x": "him",
    "y": " b. hem-I"
  },
  {
    "x": "him ... him ...",
    "y": " b. hem ... hem ..."
  },
  {
    "x": "him ... himi ...",
    "y": " b. hem ... hem ..."
  },
  {
    "x": "hima-I",
    "y": " b. hema-I"
  },
  {
    "x": "hima ra",
    "y": " b. hema-1"
  },
  {
    "x": "hima-hima",
    "y": " b. hema-hema"
  },
  {
    "x": "hima-hima ki",
    "y": " b. hema-hema ke"
  },
  {
    "x": "hima-II",
    "y": " b. labelê, la"
  },
  {
    "x": "himbaz (n)",
    "y": " b. embaz (n)"
  },
  {
    "x": "himo",
    "y": " b. hema-I"
  },
  {
    "x": "himvar, -i",
    "y": " b. hemver, -e"
  },
  {
    "x": "himzar",
    "y": " b. hezar"
  },
  {
    "x": "hin-I",
    "y": " bazı"
  },
  {
    "x": "hin-II",
    "y": " 1)b. winî\r\n~2)b. wina\r\n~3)b. ûne"
  },
  {
    "x": "hin-III",
    "y": " b. hinî-III"
  },
  {
    "x": "hina-I",
    "y": " b. wina"
  },
  {
    "x": "hina-II",
    "y": " b. hîna"
  },
  {
    "x": "hinar (m)",
    "y": " b. henare (m)"
  },
  {
    "x": "hinare (m)",
    "y": " b. henare (m)"
  },
  {
    "x": "hinarêre (m)",
    "y": " b. henarêre (m)"
  },
  {
    "x": "hinarêri (m)",
    "y": " b. henarêre (m)"
  },
  {
    "x": "hinari (m)",
    "y": " b. henare (m)"
  },
  {
    "x": "hinasî, -ye",
    "y": " b. winasî, -ye"
  },
  {
    "x": "hinawlêri (m)",
    "y": " b. henarêre (m)"
  },
  {
    "x": "hincik (m)",
    "y": " b. nuçike (m)"
  },
  {
    "x": "hind (n)",
    "y": " beyaz renkli bez"
  },
  {
    "x": "hinda",
    "y": " b. hendê"
  },
  {
    "x": "hindasay",
    "y": " b. hende"
  },
  {
    "x": "hindasayî",
    "y": " b. hende"
  },
  {
    "x": "hindasî",
    "y": " b. hende"
  },
  {
    "x": "hinday",
    "y": " b. hendê"
  },
  {
    "x": "hinde",
    "y": " b. hende"
  },
  {
    "x": "hindey",
    "y": " b. hendê"
  },
  {
    "x": "hindê",
    "y": " b. hende"
  },
  {
    "x": "hindêk",
    "y": " b. hende"
  },
  {
    "x": "hindi",
    "y": " b. hende"
  },
  {
    "x": "hindî-I (m)",
    "y": " karpuz"
  },
  {
    "x": "hindî-II",
    "y": " b. êdî"
  },
  {
    "x": "hindîroş, -e",
    "y": " karpuzcu"
  },
  {
    "x": "Hindîstan (n)",
    "y": " Hindistan"
  },
  {
    "x": "hine (n)",
    "y": " kına"
  },
  {
    "x": "hine bîyene",
    "y": " kınalanmak, kına sürülmek, kına yakılmak, kınayla boyanmak"
  },
  {
    "x": "hine desawitene",
    "y": " kınalamak, kına sürmek, kına yakmak, kınayla boyamak"
  },
  {
    "x": "hine desawîyayene",
    "y": " kınalanmak, kına sürülmek, kına yakılmak, kınayla boyanmak"
  },
  {
    "x": "hine kerdene",
    "y": " kınalamak, kına sürmek, kına yakmak, kınayla boyamak"
  },
  {
    "x": "hine panayene",
    "y": " kınalamak, kına sürmek, kına yakmak, kınayla boyamak"
  },
  {
    "x": "hine panîyayene",
    "y": " kınalanmak, kına sürülmek, kına yakılmak, kınayla boyanmak"
  },
  {
    "x": "hine ... sawitene",
    "y": " kınalamak, kına sürmek, kına yakmak, kınayla boyamak"
  },
  {
    "x": "hine ... sawîyayene",
    "y": " kınalanmak, kına sürülmek, kına yakılmak, kınayla boyanmak"
  },
  {
    "x": "hinebîyayîş (n)",
    "y": " kınalanma"
  },
  {
    "x": "hinedesawitiş (n)",
    "y": " kınalama"
  },
  {
    "x": "hinedesawîyayîş (n)",
    "y": " kınalanma"
  },
  {
    "x": "hinekerda (m)",
    "y": " kınalı"
  },
  {
    "x": "hinekerde (n)",
    "y": " kınalı"
  },
  {
    "x": "hinekerdiş (n)",
    "y": " kınalama"
  },
  {
    "x": "hinekerdîye (m)",
    "y": " kınalı"
  },
  {
    "x": "hinepanayîş (n)",
    "y": " kınalama"
  },
  {
    "x": "hinepanîyayîş (n)",
    "y": " kınalanma"
  },
  {
    "x": "hineyin, -e",
    "y": " kınalı"
  },
  {
    "x": "hing-I (n)",
    "y": " 1)zaman, vakit, çağ\r\n~2)an, lahza"
  },
  {
    "x": "hing-II (m)",
    "y": " b. hinge (m)"
  },
  {
    "x": "hingazî (m)",
    "y": " b. hengazî (m)"
  },
  {
    "x": "hingdar, -e",
    "y": " arıcı"
  },
  {
    "x": "hinge (m)",
    "y": " arı, bal arısı"
  },
  {
    "x": "hingemîn (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "hingi (m)",
    "y": " b. hinge (m)"
  },
  {
    "x": "hingila (m)",
    "y": " yalın ayak"
  },
  {
    "x": "hingilan (n)",
    "y": " bal arısı kovanlarının istiflenerek konulduğu -evden uzak- yer"
  },
  {
    "x": "hingile-I (n)",
    "y": " 1)kol\r\n~2)elbise kolu, yen"
  },
  {
    "x": "hingile-II (n)",
    "y": " yalın ayak"
  },
  {
    "x": "hingilik (n)",
    "y": " emzik"
  },
  {
    "x": "hingilûnd (n)",
    "y": " b. hingilan (n)"
  },
  {
    "x": "hingimê (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "hingimên (n)",
    "y": " bal"
  },
  {
    "x": "hingimênroş, -e",
    "y": " balcı"
  },
  {
    "x": "hingimênroşîye (m)",
    "y": " balcılık"
  },
  {
    "x": "hingimîn (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "hingmên (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "hinik",
    "y": " b. honik, -e"
  },
  {
    "x": "hinî-I",
    "y": " b. winî"
  },
  {
    "x": "hinî-II (n, z)",
    "y": " b. ney-II (n, z)"
  },
  {
    "x": "hinî-III",
    "y": " artık"
  },
  {
    "x": "hinî-IV",
    "y": " 1)biraz\r\n~2)b. hin-I"
  },
  {
    "x": "hinîke",
    "y": " 1)bazı, bazıları\r\n~2)biraz"
  },
  {
    "x": "hinîya",
    "y": " b. hîna"
  },
  {
    "x": "hinka",
    "y": " b. nika"
  },
  {
    "x": "hinkerdene",
    "y": " b. hankerdene"
  },
  {
    "x": "hinkerdiş (n)",
    "y": " b. hankerdiş (n)"
  },
  {
    "x": "hinkê",
    "y": " b. nika"
  },
  {
    "x": "hinkî",
    "y": " b. hin-I"
  },
  {
    "x": "hinkuf, -e",
    "y": " 1)denk\r\n~2)taydaş, akran, böğür, öğür\r\n~Ti hinkufê ey nîyê. (Sen onun dengi değilsin.)"
  },
  {
    "x": "hinkufîye (m)",
    "y": " denklik"
  },
  {
    "x": "hinne (n)",
    "y": " b. hine (n)"
  },
  {
    "x": "Hinqara (n)",
    "y": " b. Anqara (m)"
  },
  {
    "x": "Hinqera (n)",
    "y": " b. Anqara (m)"
  },
  {
    "x": "hinya",
    "y": " b. hîna"
  },
  {
    "x": "hinzar",
    "y": " b. hezar"
  },
  {
    "x": "hip-huşk, -e",
    "y": " kupkuru"
  },
  {
    "x": "hiq kerdene",
    "y": " hık tutmak"
  },
  {
    "x": "hiqûq (n)",
    "y": " b. huqûq (n)"
  },
  {
    "x": "hir",
    "y": " b. her-II"
  },
  {
    "x": "hir g’",
    "y": " b. her yew"
  },
  {
    "x": "hir gi",
    "y": " b. her yew"
  },
  {
    "x": "hir kerdene",
    "y": " (eşek ve at için) çiftleşmesi için erkek hayvanları tahrik etmek"
  },
  {
    "x": "Hirço (n)",
    "y": " köpek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "hirdos (m)",
    "y": " b. herdose (m)"
  },
  {
    "x": "hirê",
    "y": " b. hîrê "
  },
  {
    "x": "hirês",
    "y": " b. hîris"
  },
  {
    "x": "hirî (m)",
    "y": " b. hîrî (m)"
  },
  {
    "x": "hirînî (m)",
    "y": " b. hîrênî (m)"
  },
  {
    "x": "hirye",
    "y": " b. hîrê "
  },
  {
    "x": "his kerdene",
    "y": " b. hîs kerdene"
  },
  {
    "x": "hiskerdiş (n)",
    "y": " b. hîskerdiş (n)"
  },
  {
    "x": "histire (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hiş (n)",
    "y": " bellek, hafıza\r\n~Ez kokim bîya, hinî hişê mino verên nêmendo. (Yaşlandım, artık eski belleğim kalmamış.)"
  },
  {
    "x": "hiş kewtene",
    "y": " aklına yatmak, akla uygun olmak, mantığa uygun olmak, anlamak"
  },
  {
    "x": "hiş nêkewtene",
    "y": " aklına yatmamak"
  },
  {
    "x": "hiş ser nêşîyene",
    "y": " akıl erdirememek, akıl ermemek"
  },
  {
    "x": "hiş ver nêşîyene",
    "y": " akıl erdirememek, akıl ermemek"
  },
  {
    "x": "hiş ver şîyene",
    "y": " akıl erdirmek"
  },
  {
    "x": "hişê ... ca de",
    "y": " aklı başında"
  },
  {
    "x": "hişê ... dayene ser",
    "y": " yoğunlaştırmak (bütün dikkatini bir konu üzerinde toplamak)"
  },
  {
    "x": "hişê ... dayîş ser",
    "y": " yoğunlaştırma (bütün dikkatini bir konu üzerinde toplama)"
  },
  {
    "x": "hişê ey cayêna de bîyene",
    "y": " aklı başka yerde olmak"
  },
  {
    "x": "hişê ey yewna ca de bîyene",
    "y": " aklı başka yerde olmak"
  },
  {
    "x": "hişê ... kewtene",
    "y": " aklına yatmak"
  },
  {
    "x": "hişê ... kewtiş",
    "y": " aklına yatma"
  },
  {
    "x": "hişê ... ser şîyene",
    "y": " aklı ermek, akıl erdirmek, anlamak"
  },
  {
    "x": "hişê ... sere de",
    "y": " aklı başında"
  },
  {
    "x": "hişê ... serşîyayîş",
    "y": " anlama"
  },
  {
    "x": "hişê ... ver şîyene",
    "y": " aklı ermek, anlamak"
  },
  {
    "x": "hişê ... verşîyayîş",
    "y": " anlama"
  },
  {
    "x": "hişê xo antene pêser",
    "y": " aklını başına almak, aklını başına toplamak, aklını başına devşirmek"
  },
  {
    "x": "hişê xo antene xo sere",
    "y": " aklını başına almak , aklını başına toplamak, aklını başına devşirmek"
  },
  {
    "x": "hişê xo dayene ser ...",
    "y": " yoğunlaşmak (bütün dikkatini bir konu üzerinde toplamak) "
  },
  {
    "x": "hişê xo vila kerdene",
    "y": " delirmek, çıldırmak"
  },
  {
    "x": "hiştê ... kewtene",
    "y": " b. hişê ... kewtene"
  },
  {
    "x": "hişar (n)",
    "y": " kayalık uçurum"
  },
  {
    "x": "hişayeni",
    "y": " b. hîşayene"
  },
  {
    "x": "hişayîş (n)",
    "y": " b. hîşayîş (n)"
  },
  {
    "x": "hişêxodayîşser ...",
    "y": " yoğunlaşma (bütün dikkatini bir konu üzerinde toplama)"
  },
  {
    "x": "hişêxovilakerdiş (n)",
    "y": " çıldırma, çıldırış, delirme, deliriş "
  },
  {
    "x": "hişkewtiş (n)",
    "y": " akla uygun olma, mantığa uygun olma, anlama"
  },
  {
    "x": "hişmend, -e",
    "y": " bilinçli, şuurlu"
  },
  {
    "x": "hişmend bîyene",
    "y": " bilinçli olmak, şuurlu olmak; bilinçlenmek"
  },
  {
    "x": "hişmend kerdene",
    "y": " bilinçlendirmek, şuurlandırmak"
  },
  {
    "x": "hişmendane",
    "y": " bilinçlice, bilinçli olarak, şuurluca, şuurlu olarak"
  },
  {
    "x": "hişmendbîyayîş (n)",
    "y": " bilinçlenme"
  },
  {
    "x": "hişmendî (m)",
    "y": " bilinç, şuur"
  },
  {
    "x": "hişmendî ra teber",
    "y": " bilinçdışı, şuurdışı"
  },
  {
    "x": "hişmendîtî (m)",
    "y": " bilinçlilik, şuurluluk"
  },
  {
    "x": "hişmendîyî (m)",
    "y": " bilinçlilik, şuurluluk"
  },
  {
    "x": "hişmendîyîye (m)",
    "y": " bilinçlilik, şuurluluk"
  },
  {
    "x": "hişyar, -e",
    "y": " uyanık (uyanmış)"
  },
  {
    "x": "hişyarê ... bîyayîş",
    "y": " farkında olma"
  },
  {
    "x": "hişyarê ... bîyene",
    "y": " farkında olmak"
  },
  {
    "x": "hişyarî (m)",
    "y": " farkındalık"
  },
  {
    "x": "hişyarîye (m)",
    "y": " farkındalık"
  },
  {
    "x": "hiwate (n)",
    "y": " b. huye-II (n)"
  },
  {
    "x": "hiwatene",
    "y": " b. huyayene"
  },
  {
    "x": "hiwateyîş (n)",
    "y": " b. huyayîş (n)"
  },
  {
    "x": "hiwatiş (n)",
    "y": " b. huyayîş (n)"
  },
  {
    "x": "hiwayene",
    "y": " b. huyayene"
  },
  {
    "x": "hiwayîş (n)",
    "y": " b. huyayîş (n)"
  },
  {
    "x": "hiwe-I (n)",
    "y": " b. huye-I (n)"
  },
  {
    "x": "hiwe-II (n)",
    "y": " b. huye-II (n)"
  },
  {
    "x": "hiwetene",
    "y": " b. huyayene"
  },
  {
    "x": "hiwetiş (n)",
    "y": " b. huyayîş (n)"
  },
  {
    "x": "hiwnayene",
    "y": " b. huynayene"
  },
  {
    "x": "hiwnayîş (n)",
    "y": " b. huynayîş (n)"
  },
  {
    "x": "hiwi (n)",
    "y": " b. huye-II (n)"
  },
  {
    "x": "hiwî (n)",
    "y": " b. huye-I (n)"
  },
  {
    "x": "hizirîyayene",
    "y": " düşünmek"
  },
  {
    "x": "hizirîyayîş (n)",
    "y": " düşünme"
  },
  {
    "x": "hî-I",
    "y": " b. hît, -e"
  },
  {
    "x": "hî bîyayene",
    "y": " b. hît bîyene"
  },
  {
    "x": "hî kerdene",
    "y": " b. hît kerdene"
  },
  {
    "x": "hî-ziwa",
    "y": " b. hît-ziwa"
  },
  {
    "x": "hî-ziwa kerdene",
    "y": " b. hît-ziwa kerdene"
  },
  {
    "x": "hî-II (n, z)",
    "y": " b. ey-II (n, z)"
  },
  {
    "x": "hî-III (zh, z)",
    "y": " b. ê (zh, z)"
  },
  {
    "x": "hîbîyayîş (n)",
    "y": " b. hîtbîyayîş (n)"
  },
  {
    "x": "hîç-I",
    "y": " hiç"
  },
  {
    "x": "hîç kerdene",
    "y": " hiçlemek, hiçleştirmek"
  },
  {
    "x": "hîç, -e-II",
    "y": " b. hêç, -e"
  },
  {
    "x": "hîç bîyayene",
    "y": " b. hêç bîyene"
  },
  {
    "x": "hîçey (m)",
    "y": " b. hîçîye (m)"
  },
  {
    "x": "hîçê-I (m)",
    "y": " b. hîçîye (m)"
  },
  {
    "x": "hîçê-II",
    "y": " b. piçe"
  },
  {
    "x": "hîçî-I (m)",
    "y": " hiçlik"
  },
  {
    "x": "hîçî-II (m)",
    "y": " b. hêçî (m)"
  },
  {
    "x": "hîçî-III",
    "y": " b. piçe"
  },
  {
    "x": "hîçîye (m)",
    "y": " hiçlik"
  },
  {
    "x": "hîçkerdiş (n)",
    "y": " hiçleştirme"
  },
  {
    "x": "hîdik",
    "y": " b. hêdîka"
  },
  {
    "x": "hîdikî",
    "y": " b. hêdîka"
  },
  {
    "x": "hîdî-I",
    "y": " b. hêdî-I"
  },
  {
    "x": "hîdî-II",
    "y": " b. êdî"
  },
  {
    "x": "hîdî-hîdî",
    "y": " b. hêdî-hêdî"
  },
  {
    "x": "hîdîkî",
    "y": " b. hêdîka"
  },
  {
    "x": "hîdrant (n)",
    "y": " hidrant"
  },
  {
    "x": "hîdrojene (m)",
    "y": " hidrojen"
  },
  {
    "x": "hîdrolîk (n)",
    "y": " hidrolik"
  },
  {
    "x": "hîdrometre (n)",
    "y": " suölçer, hidrometre"
  },
  {
    "x": "hîdrosfer (n)",
    "y": " hidrosfer"
  },
  {
    "x": "hîers (n)",
    "y": " b. hêrs (n)"
  },
  {
    "x": "hîers bîyayîş (n)",
    "y": " b. hêrsbîyayîş (n)"
  },
  {
    "x": "hîers bîyene",
    "y": " b. hêrs bîyene"
  },
  {
    "x": "hîers kerdene",
    "y": " b. hêrs kerdene"
  },
  {
    "x": "hîers kotene",
    "y": " b. hêrs kewtene"
  },
  {
    "x": "hîers ra ser vîştene",
    "y": " b. hêrs ra ser fîştene "
  },
  {
    "x": "hîersê ... ameyene",
    "y": " b. hêrsê ... ameyene"
  },
  {
    "x": "hîersê ... ardene",
    "y": " b. hêrsê ... ardene"
  },
  {
    "x": "hîersê ... ardiş",
    "y": " b. hêrsê ... ardiş"
  },
  {
    "x": "hîersî ... ardene",
    "y": " b. hêrsê ... ardene"
  },
  {
    "x": "hîersbîyaye (n)",
    "y": " b. hêrsbîyaye (n)"
  },
  {
    "x": "hîersbîyayîye (m)",
    "y": " b. hêrsbîyayîye (m)"
  },
  {
    "x": "hîersin, -e",
    "y": " b. hêrsin, -e"
  },
  {
    "x": "hîerskerdis (n)",
    "y": " b. hêrskerdiş (n)"
  },
  {
    "x": "hîerskotiş (n)",
    "y": " b. hêrskewtiş (n)"
  },
  {
    "x": "hîeze (m)",
    "y": " b. heje (m)"
  },
  {
    "x": "hîga (n)",
    "y": " b. hêga (n)"
  },
  {
    "x": "hîgrometre (n)",
    "y": " nemölçer, higrometre"
  },
  {
    "x": "hîiz (n)",
    "y": " b. hayîz (n)"
  },
  {
    "x": "hîjyen (n)",
    "y": " hijyen"
  },
  {
    "x": "hîjyenîk, -e",
    "y": " hijyenik"
  },
  {
    "x": "hîkaya (m)",
    "y": " b. hîkaye (m)"
  },
  {
    "x": "hîkaye (m)",
    "y": " öykü, hikâye"
  },
  {
    "x": "hîkaye de pêkewtiş",
    "y": " öyküde çatışma"
  },
  {
    "x": "hîkaye de tengijîyayîş",
    "y": " hikâyede gerilim, öyküde gerilim"
  },
  {
    "x": "hîkerdiş (n)",
    "y": " b. hîtkerdiş (n)"
  },
  {
    "x": "hîlar (n)",
    "y": " ışın"
  },
  {
    "x": "hîlarê rojî (n)",
    "y": " çok az (mecazi), zerre (mecazi)"
  },
  {
    "x": "hîle (n)",
    "y": " hile"
  },
  {
    "x": "hîlebaz, -e",
    "y": " hilekâr"
  },
  {
    "x": "hîlebazîye (m)",
    "y": " hilekârlık"
  },
  {
    "x": "hîlekar, -e",
    "y": " hilekâr"
  },
  {
    "x": "hîlekarî (m)",
    "y": " hilekârlık "
  },
  {
    "x": "hîlekarîye (m)",
    "y": " hilekârlık "
  },
  {
    "x": "hîlo (n)",
    "y": " b. hîro (n)"
  },
  {
    "x": "hîltî (n)",
    "y": " hilti"
  },
  {
    "x": "hîm (n)",
    "y": " temel, esas"
  },
  {
    "x": "hîme (m)",
    "y": " büyük taş"
  },
  {
    "x": "hîmi (m)",
    "y": " b. hîme (m)"
  },
  {
    "x": "hîn-I (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "hîn-II",
    "y": " b. hinî-III"
  },
  {
    "x": "hîn-III",
    "y": " b. hîna"
  },
  {
    "x": "hîna-I",
    "y": " 1)daha\r\n~Kêneke to ra hîna zaf zana. (Kız senden daha çok biliyor.)\r\n~\t\r\n~2)henüz, hâlâ\r\n~\t\r\n~Kêneke hîna nêşîya. (Kız henüz gitmemiş.)"
  },
  {
    "x": "hîna-II",
    "y": " b. hewna-I"
  },
  {
    "x": "hîna-III",
    "y": " b. wina"
  },
  {
    "x": "hînan-I (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "hîne-I (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "hîne-II (n)",
    "y": " b. hênî-I (n)"
  },
  {
    "x": "hînê-I",
    "y": " b. hîna-I"
  },
  {
    "x": "hînê-II",
    "y": " b. hinî-IV"
  },
  {
    "x": "hînge (m)",
    "y": " çelik çomak"
  },
  {
    "x": "hîngilan (n)",
    "y": " b. hingilan (n)"
  },
  {
    "x": "hîngilûn (n)",
    "y": " b. hingilan (n)"
  },
  {
    "x": "hîni (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "hînî-I",
    "y": " b. hinî-IV"
  },
  {
    "x": "hînî-II",
    "y": " b. hinî-III"
  },
  {
    "x": "hînî-III (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "hînî-IV (n)",
    "y": " b. hênî-I (n)"
  },
  {
    "x": "hînî-V",
    "y": " b. winî"
  },
  {
    "x": "hînîke",
    "y": " b. hinîke"
  },
  {
    "x": "hînke",
    "y": " b. hinîke"
  },
  {
    "x": "hînkî",
    "y": " b. hinîke"
  },
  {
    "x": "hînsan (n)",
    "y": " b. însan (n)"
  },
  {
    "x": "hîperaktîf, -e",
    "y": " hiperaktif"
  },
  {
    "x": "hîperaktîvîte (m)",
    "y": " hiperaktivite"
  },
  {
    "x": "hîpertansîyon (n)",
    "y": " yüksek tansiyon, hipertansiyon"
  },
  {
    "x": "hîpotansîyon (n)",
    "y": " düşük tansiyon, hipotansiyon"
  },
  {
    "x": "hîpotez (n)",
    "y": " denence, faraziye, hipotez, varsayım"
  },
  {
    "x": "hîpotezê cigêrayîşî (n)",
    "y": " araştırma denencesi, araştırma hipotezi"
  },
  {
    "x": "hîr-I (n)",
    "y": " bereket"
  },
  {
    "x": "hîr dekewtene",
    "y": " bereketlenmek"
  },
  {
    "x": "hîr devistene",
    "y": " bereketlendirmek"
  },
  {
    "x": "hîr-II (m)",
    "y": " b. îre"
  },
  {
    "x": "hîr vetene",
    "y": " b. îre vetene"
  },
  {
    "x": "hîr kîp",
    "y": " koç veya tekenin toslamasını sağlamak için söylenen bir söz"
  },
  {
    "x": "hîr-hîr (n)",
    "y": " kişneme"
  },
  {
    "x": "hîr-hîrkerdene",
    "y": " kişnemek"
  },
  {
    "x": "hîr-hîrkerdiş (n)",
    "y": " kişneme"
  },
  {
    "x": "hîra, -ye",
    "y": " geniş"
  },
  {
    "x": "hîrahoke (m)",
    "y": " geniş açı"
  },
  {
    "x": "hîrahokin, -e",
    "y": " geniş açılı"
  },
  {
    "x": "hîratî (m)",
    "y": " genişlik"
  },
  {
    "x": "hîrayene",
    "y": " kişnemek"
  },
  {
    "x": "hîrayeni",
    "y": " b. hîrayene"
  },
  {
    "x": "hîrayî (m)",
    "y": " genişlik"
  },
  {
    "x": "hîrayîya bende (m)",
    "y": " bent genişliği"
  },
  {
    "x": "hîrayîş (n)",
    "y": " kişneme"
  },
  {
    "x": "hîrayîye (m)",
    "y": " genişlik"
  },
  {
    "x": "hîrdekewtiş (n)",
    "y": " bereketlenme"
  },
  {
    "x": "hîrdevistiş (n)",
    "y": " bereketlendirme"
  },
  {
    "x": "hîre-I (n)",
    "y": " baş dönmesi"
  },
  {
    "x": "hîre şîyayene",
    "y": " b. hîre şîyene"
  },
  {
    "x": "hîre şîyene",
    "y": " başı dönmek"
  },
  {
    "x": "hîrê ... şîyayene",
    "y": " b. hîreyê ... şîyene"
  },
  {
    "x": "hîreyê ... şîyene",
    "y": " başı dönmek\r\n~Hîreyê şima şino. (Başınız döner.)"
  },
  {
    "x": "hîre-II",
    "y": " b. hîrê"
  },
  {
    "x": "hîre-III (m)",
    "y": " b. îre-II (m)"
  },
  {
    "x": "hîre-hîr (n)",
    "y": " kişneme"
  },
  {
    "x": "hîre-hîrekerdene",
    "y": " b. hîre-hîrkerdene"
  },
  {
    "x": "hîre-hîrekerdiş (n)",
    "y": " b. hîre-hîrkerdiş (n)"
  },
  {
    "x": "hîre-hîrkerdene",
    "y": " kişnemek"
  },
  {
    "x": "hîre-hîrkerdiş (n)",
    "y": " kişneme"
  },
  {
    "x": "hîremêna",
    "y": " b. hîrê hemîne"
  },
  {
    "x": "hîremîna",
    "y": " b. hîrê hemîne"
  },
  {
    "x": "hîremîne",
    "y": " b. hîrê hemîne"
  },
  {
    "x": "hîres",
    "y": " b. hîrês"
  },
  {
    "x": "hîreyîn, -e",
    "y": " b. hîrêyin, -e"
  },
  {
    "x": "hîreşîyayîş (n)",
    "y": " başı dönme"
  },
  {
    "x": "hîrê",
    "y": " üç"
  },
  {
    "x": "hîr eme",
    "y": " b. hîrê heme"
  },
  {
    "x": "hîr sey",
    "y": " b. hîrê sey"
  },
  {
    "x": "hîr sê",
    "y": " b. hîrê sey"
  },
  {
    "x": "hîre sey",
    "y": " b. hîrê sey"
  },
  {
    "x": "hîrê heme",
    "y": " her üç"
  },
  {
    "x": "hîrê hemîne",
    "y": " her üçü"
  },
  {
    "x": "hîrê kerreyê ... eştene",
    "y": " boşamak"
  },
  {
    "x": "hîrê kerreyê ... eştiş",
    "y": " boşama"
  },
  {
    "x": "hîrê kerreyê ... vistene",
    "y": " boşamak"
  },
  {
    "x": "hîrê kerreyê ... vistiş",
    "y": " boşama"
  },
  {
    "x": "hîrê kerreyî dest a nayene",
    "y": " (birisine) nikahı üzerine yemin içirmek"
  },
  {
    "x": "hîrê rê se",
    "y": " b. hîrê sey"
  },
  {
    "x": "hîrê sey",
    "y": " üç yüz "
  },
  {
    "x": "hîrê sê",
    "y": " b. hîrê sey"
  },
  {
    "x": "hîrê sêy",
    "y": " b. hîrê sey"
  },
  {
    "x": "hîrê sîyê ... eştene",
    "y": " boşamak"
  },
  {
    "x": "hîrê sîyê ... eştiş",
    "y": " boşama"
  },
  {
    "x": "hîrêdimensîyonin",
    "y": " üç boyutlu"
  },
  {
    "x": "hîrêgamî (zh)",
    "y": " üç adım atlama"
  },
  {
    "x": "hîrêgamî çingdayîş",
    "y": " üç adım atlama"
  },
  {
    "x": "hîrêgamî çîngdayîş",
    "y": " b. hîrêgamî çingdayîş"
  },
  {
    "x": "hîrêgamî perayîş (n)",
    "y": " üç adım atlama"
  },
  {
    "x": "hîrêgoşe-I (n)",
    "y": " üçgen (mat)"
  },
  {
    "x": "hîrêgoşeyo çeşîtkenar (n)",
    "y": " çeşitkenar üçgen"
  },
  {
    "x": "hîrêgoşeyo diletkenar (n)",
    "y": " ikizkenar üçgen"
  },
  {
    "x": "hîrêgoşeyo hîrahokin (n)",
    "y": " geniş açılı üçgen"
  },
  {
    "x": "hîrêgoşeyo seypêkenar (n)",
    "y": " eşkenar üçgen"
  },
  {
    "x": "hîrêgoşeyo tenghokin (n)",
    "y": " dar açılı üçgen"
  },
  {
    "x": "hîrêgoşeyo tîkhokin (n)",
    "y": " dik açılı üçgen"
  },
  {
    "x": "hîrêgoşe-II (m)",
    "y": " dirgen (üç dişli dirgen)"
  },
  {
    "x": "hîrêgoşî (m)",
    "y": " b. hîrêgoşe-II (m)"
  },
  {
    "x": "hîrêkî (m) (sn)",
    "y": " üçleme"
  },
  {
    "x": "hîrême",
    "y": " b. hîrê heme"
  },
  {
    "x": "hîrêmêna",
    "y": " b. hîrê hemîne"
  },
  {
    "x": "hîrêmîna",
    "y": " b. hîrê hemîne"
  },
  {
    "x": "hîrêmîne",
    "y": " b. hîrê hemîne"
  },
  {
    "x": "hîrên, -e",
    "y": " b. hîrêyin, -e"
  },
  {
    "x": "hîrênî (m)",
    "y": " kişneme"
  },
  {
    "x": "hîrêrehendin",
    "y": " üç boyutlu"
  },
  {
    "x": "hîrês",
    "y": " on üç "
  },
  {
    "x": "hîrêsibay (n)",
    "y": " bugünden sonraki üçüncü gün"
  },
  {
    "x": "hîrêsiway (n)",
    "y": " b. hîrêsibay (n)"
  },
  {
    "x": "hîrêsiwey (n)",
    "y": " b. hîrêsibay (n)"
  },
  {
    "x": "hîrêyin, -e",
    "y": " üçüncü"
  },
  {
    "x": "hîrêyine (m)",
    "y": " 1)üçüncü\r\n~2)üçtaş [oyunu]"
  },
  {
    "x": "hîrgueş (m)",
    "y": " b. hîrêgoşe-II (m)"
  },
  {
    "x": "hîrguweş (m)",
    "y": " b. hîrêgoşe-II (m)"
  },
  {
    "x": "hîri-I (m)",
    "y": " b. îre-II (m)"
  },
  {
    "x": "hîri vetene",
    "y": " b. îre vetene"
  },
  {
    "x": "hîri-II",
    "y": " b. hîrê"
  },
  {
    "x": "hîri kerrey ... eyştene",
    "y": " b. hîrê kerreyê ... eştene"
  },
  {
    "x": "hîri kerrey ... eyştiş",
    "y": " b. hîrê kerreyê ... eştiş"
  },
  {
    "x": "hîri kerrey ... vistene",
    "y": " b. hîrê kerreyê ... vistene"
  },
  {
    "x": "hîri kerrey ... vistiş",
    "y": " b. hîrê kerreyê ... vistiş"
  },
  {
    "x": "hîri kerrey dest a nayene",
    "y": " b. hîrê kerreyî dest a nayene"
  },
  {
    "x": "hîri sey",
    "y": " b. hîrê sey"
  },
  {
    "x": "hîri sîy ... eyştene",
    "y": " b. hîrê sîyê ... eştene"
  },
  {
    "x": "hîri sîy ... eyştiş",
    "y": " b. hîrê sîyê ... eştiş"
  },
  {
    "x": "hîri sîy ... vistene",
    "y": " b. hîrê sîyê ... eştene"
  },
  {
    "x": "hîri sîy ... vistiş",
    "y": " b. hîrê sîyê ... eştiş"
  },
  {
    "x": "hîri-hiri (m)",
    "y": " . hîre-hîr (n)"
  },
  {
    "x": "hîri-hirikerdeni",
    "y": " b. hîre-hîrkerdene"
  },
  {
    "x": "hîri-hirikerdiş (n)",
    "y": " b. hîre-hîrkerdiş (n)"
  },
  {
    "x": "hîrigamî (zh)",
    "y": " b. hîrêgamî (zh)"
  },
  {
    "x": "hîrigueş (m)",
    "y": " b. hîrêgoşe-II (m)"
  },
  {
    "x": "hîrin, -e",
    "y": " b. hîrêyin, -e"
  },
  {
    "x": "hîrini (m)",
    "y": " b. hîrêyine (m)"
  },
  {
    "x": "hîris",
    "y": " otuz "
  },
  {
    "x": "hîrisibay (n)",
    "y": " b. hîrêsibay (n)"
  },
  {
    "x": "hîrisin, -e",
    "y": " otuzuncu "
  },
  {
    "x": "hîriş",
    "y": " b. hîris"
  },
  {
    "x": "hîrî (m)",
    "y": " kişneme"
  },
  {
    "x": "hîrîn, -i",
    "y": " b. hîrêyin, -e"
  },
  {
    "x": "hîrmîna",
    "y": " b. hîrê hemîne"
  },
  {
    "x": "hîro (n)",
    "y": " hatmi, ağaçküpesi"
  },
  {
    "x": "hîroke (m)",
    "y": " üçtaş [oyunu]"
  },
  {
    "x": "hîrqehîrqe (m)",
    "y": " ağlama sesi"
  },
  {
    "x": "hîrqehîrqe kerdene",
    "y": " hıçkırmak"
  },
  {
    "x": "hîrqehîrqekerdiş (n)",
    "y": " hıçkırma"
  },
  {
    "x": "hîrr kîp",
    "y": " b. hîr kîp"
  },
  {
    "x": "hîrrayene",
    "y": " b. hîrayene"
  },
  {
    "x": "hîrrayîs (n)",
    "y": " b. hîrayîş (n)"
  },
  {
    "x": "hîrrayîş (n)",
    "y": " b. hîrayîş (n)"
  },
  {
    "x": "hîrre-hîrre (m)",
    "y": " b. hîre-hîr (n)"
  },
  {
    "x": "hîrre-hîrrekerdene",
    "y": " b. hîre-hîrkerdene"
  },
  {
    "x": "hîrre-hîrrekerdis (n)",
    "y": " b. hîre-hîrkerdiş (n)"
  },
  {
    "x": "hîrs (n)",
    "y": " b. hêrs (n)"
  },
  {
    "x": "hîrye",
    "y": " b. hîrê "
  },
  {
    "x": "hîryegueş (m)",
    "y": " b. hîrêgoşe-II (m)"
  },
  {
    "x": "hîryend (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "hîryes",
    "y": " b. hîrês"
  },
  {
    "x": "hîryeyin",
    "y": " b. hîrêyin, -e"
  },
  {
    "x": "hîryin",
    "y": " b. hîrêyin, -e"
  },
  {
    "x": "hîryîn",
    "y": " b. hîrêyin, -e"
  },
  {
    "x": "hîs (n)",
    "y": " 1)duygu, his\r\n~2)duyu"
  },
  {
    "x": "hîs kerdene",
    "y": " hissetmek, sezinlemek, sezmek"
  },
  {
    "x": "hîsê ginayîşî (n)",
    "y": " dokunma duyusu"
  },
  {
    "x": "hîsê panayîşî (n)",
    "y": " dokunma duyusu"
  },
  {
    "x": "hîso şeşin (n)",
    "y": " altıncı duyu, altıncı his, önsezi, hissikablelvuku"
  },
  {
    "x": "hîsab (n)",
    "y": " b. hesab (n)"
  },
  {
    "x": "hîsdar, -e",
    "y": " duygulu, duygun"
  },
  {
    "x": "hîsdar, -i",
    "y": " b. hîsedar, -e"
  },
  {
    "x": "hîsdarey (m)",
    "y": " b. hîsedarîye (m)"
  },
  {
    "x": "hîsdarênî (m)",
    "y": " duygululuk, duygunluk"
  },
  {
    "x": "hîsdarî (m)",
    "y": " duygululuk, duygunluk"
  },
  {
    "x": "hîsdarîye (m)",
    "y": " duygululuk, duygunluk"
  },
  {
    "x": "hîsdayox, -e",
    "y": " uyarıcı"
  },
  {
    "x": "hîse (n)",
    "y": " pay, hisse"
  },
  {
    "x": "hîsedar, -e",
    "y": " hissedar, ortak, paydaş"
  },
  {
    "x": "hîsedarîye (m)",
    "y": " ortaklık, paydaşlık"
  },
  {
    "x": "hîsgêr, -e",
    "y": " duyarlı, duygulu, hassas"
  },
  {
    "x": "hîsgêrênî (m)",
    "y": " duyarlık, duygululuk, hassaslık, hassasiyet"
  },
  {
    "x": "hîsgêrî (m)",
    "y": " duyarlık, duygululuk, hassaslık, hassasiyet"
  },
  {
    "x": "hîsgêrîye (m)",
    "y": " duyarlık, duygululuk, hassaslık, hassasiyet"
  },
  {
    "x": "hîsi (n)",
    "y": " hîse (n)"
  },
  {
    "x": "hîsidar, –e",
    "y": " b. hîsedar, -e"
  },
  {
    "x": "hîsidarê (m)",
    "y": " b. hîsedarîye (m)"
  },
  {
    "x": "hîsî, -ye",
    "y": " duygusal, hissi"
  },
  {
    "x": "hîsîbîyayîş (n)",
    "y": " duygusallık"
  },
  {
    "x": "hîsîtî (m)",
    "y": " duygusallık"
  },
  {
    "x": "hîsîyîye (m)",
    "y": " duygusallık"
  },
  {
    "x": "hîskerdiş (n)",
    "y": " 1)hissetme, sezinleme, sezme\r\n~2)duyum"
  },
  {
    "x": "hîsr (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hîsre (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "hîss (n)",
    "y": " b. hîs (n)"
  },
  {
    "x": "hîsse (n)",
    "y": " b. hîse (n)"
  },
  {
    "x": "hîssedar, -e",
    "y": " b. hîsedar, -e"
  },
  {
    "x": "hîssedarey (m)",
    "y": " b. hîsedarîye (m)"
  },
  {
    "x": "hîssî, -ye",
    "y": " b. hîsî, -ye"
  },
  {
    "x": "hîssîbîyayîş (n)",
    "y": " b. hîsîbîyayîş (n)"
  },
  {
    "x": "hîssîyîye (m)",
    "y": " b. hîsîyîye (m)"
  },
  {
    "x": "hîstenik, -e",
    "y": " duyarlı, duygulu, hassas"
  },
  {
    "x": "hîstenikênî (m)",
    "y": " duyarlık, duygululuk, hassaslık, hassasiyet"
  },
  {
    "x": "hîstenikî (m)",
    "y": " duyarlık, duygululuk, hassaslık, hassasiyet"
  },
  {
    "x": "hîstenikîye (m)",
    "y": " duyarlık, duygululuk, hassaslık, hassasiyet"
  },
  {
    "x": "hîstîre (m)",
    "y": " b. îstire (m)"
  },
  {
    "x": "hîsûn (n)",
    "y": " b. însan (n)"
  },
  {
    "x": "hîş (n)",
    "y": " b. hiş (n)"
  },
  {
    "x": "hîş kewtene",
    "y": " b. hiş kewtene"
  },
  {
    "x": "hîşî ... ser şîyene",
    "y": " b. hişê ... ser şîyene"
  },
  {
    "x": "hişî ... serşîyayîş",
    "y": " b. hişê ... serşîyayîş"
  },
  {
    "x": "hişî ... ver şîyene",
    "y": " b. hişê ... ver şîyene"
  },
  {
    "x": "hîşî xo vila kerdene",
    "y": " b. hişê xo vila kerdene"
  },
  {
    "x": "hîşar, -e",
    "y": " b. hişyar, -e"
  },
  {
    "x": "hîşayene",
    "y": " kişnemek"
  },
  {
    "x": "hîşayîş (n)",
    "y": " kişneme"
  },
  {
    "x": "hîşîyayîne",
    "y": " b. hîşayene"
  },
  {
    "x": "hîşîyayîş (n)",
    "y": " b. hîşayîş (n)"
  },
  {
    "x": "hîşyar, -e",
    "y": " b. hişyar, -e"
  },
  {
    "x": "hîşyarê ... bîyayîş",
    "y": " b. hişyarê ... bîyayîş"
  },
  {
    "x": "hîşyarî (m)",
    "y": " b. hişyarî (m)"
  },
  {
    "x": "hîşyarîye (m)",
    "y": " b. hişyarîye (m)"
  },
  {
    "x": "hît, -e-I",
    "y": " ıslak, yaş"
  },
  {
    "x": "hît bîyene",
    "y": " ıslak olmak, ıslanmak, yaş olmak"
  },
  {
    "x": "hît kerdene",
    "y": " ıslatmak"
  },
  {
    "x": "hît-II (n)",
    "y": " b. wit (n)"
  },
  {
    "x": "hît, -e-III",
    "y": " cömert"
  },
  {
    "x": "hît-ziwa",
    "y": " yazı tura"
  },
  {
    "x": "hît-ziwa kerdene",
    "y": " yazı tura atmak"
  },
  {
    "x": "hîtbîyayîş (n)",
    "y": " ıslanma"
  },
  {
    "x": "Hîte (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "hîtênî (m)",
    "y": " b. witinîye (m)"
  },
  {
    "x": "hîtik (n)",
    "y": " tiftik"
  },
  {
    "x": "kilawa hîtikêne (m)",
    "y": " tiftik külah"
  },
  {
    "x": "kulika hîtikêne (m)",
    "y": " tiftik külah"
  },
  {
    "x": "hîtike (m)",
    "y": " tiftik keçisi"
  },
  {
    "x": "hîtikin, -e",
    "y": " tiftikli"
  },
  {
    "x": "hîtim, -i",
    "y": " b. yetîm, -e"
  },
  {
    "x": "hîtin, -i",
    "y": " b. witin, -e"
  },
  {
    "x": "hîtîm, -i",
    "y": " b. yetîm, -e"
  },
  {
    "x": "hîtîye-I (m)",
    "y": " ıslaklık, yaşlık"
  },
  {
    "x": "hîtîye-II (m)",
    "y": " b. witinîye (m)"
  },
  {
    "x": "hîtîye-III (m)",
    "y": " cömertlik"
  },
  {
    "x": "hîtkerdiş (n)",
    "y": " ıslatma"
  },
  {
    "x": "hîttik (n)",
    "y": " b. hîtik (n)"
  },
  {
    "x": "hîttikin, -e",
    "y": " b. hîtikin, -e"
  },
  {
    "x": "hîvî (m)",
    "y": " b. hêvî (m)"
  },
  {
    "x": "hîwe (n)",
    "y": " b. huye-I (n)"
  },
  {
    "x": "hîy",
    "y": " b. hît, -e"
  },
  {
    "x": "hîy bîyayene",
    "y": " b. hît bîyene"
  },
  {
    "x": "hîy kerdene",
    "y": " b. hît kerdene"
  },
  {
    "x": "hîy-ziwa",
    "y": " b. hît-ziwa"
  },
  {
    "x": "hîy-ziwa kerdene",
    "y": " b. hît-ziwa kerdene"
  },
  {
    "x": "hîyate (m)",
    "y": " b. heyate (m)"
  },
  {
    "x": "hîybîyayîş (n)",
    "y": " b. hîtbîyayîş (n)"
  },
  {
    "x": "hîyega (n)",
    "y": " b. hêga (n)"
  },
  {
    "x": "hîyegin (n)",
    "y": " b. hêgin, -e"
  },
  {
    "x": "hîyey (m)",
    "y": " b. hîtîye-I (m)"
  },
  {
    "x": "hîyê (m)",
    "y": " b. hîtîye-I (m)"
  },
  {
    "x": "hîyêginî (m)",
    "y": " b. hêginîye (m), hêginî (m)"
  },
  {
    "x": "hîyiz (n)",
    "y": " b. hayîz (n)"
  },
  {
    "x": "hîykerdiş (n)",
    "y": " b. hîtkerdiş (n)"
  },
  {
    "x": "hîyşar (n)",
    "y": " b. hişar (n)"
  },
  {
    "x": "hîz (n)",
    "y": " b. hêz (n)"
  },
  {
    "x": "hîza (m)",
    "y": " hiza"
  },
  {
    "x": "hîzaya ... de",
    "y": " hizasında"
  },
  {
    "x": "hîzaya ... ra",
    "y": " hizasında"
  },
  {
    "x": "Hîzan (n)",
    "y": " Hizan"
  },
  {
    "x": "hîzare (m)",
    "y": " b. îzare (m)"
  },
  {
    "x": "hîzara lihêfî (m)",
    "y": " b. îzara lihêfî"
  },
  {
    "x": "hîzara masî (m)",
    "y": " b. îzara masa (m)"
  },
  {
    "x": "hîzara nimacî (m)",
    "y": " b. îzara nimajî (m)"
  },
  {
    "x": "hîzari (m)",
    "y": " b. îzare (m)"
  },
  {
    "x": "hîzik, -e",
    "y": " cinsel anlamda azgınca davranan hayvan"
  },
  {
    "x": "hîzik bîyayene",
    "y": " b. hîzik bîyene"
  },
  {
    "x": "hîzik bîyene",
    "y": " cinsel anlamda azgınca davranmak (hayvan için)"
  },
  {
    "x": "ho-I",
    "y": " b. ha-I"
  },
  {
    "x": "ho-II",
    "y": " 1)oha\r\n~2)sığırın yürümesi için veya kovarken söylenen bir söz"
  },
  {
    "x": "ho ho",
    "y": " sığırın yürümesi için veya kovarken söylenen bir söz"
  },
  {
    "x": "ho-III",
    "y": " b. xo-I"
  },
  {
    "x": "ho ver estene",
    "y": " b. xo ver eştene"
  },
  {
    "x": "ho vîr a kerdene",
    "y": " b. xo vîr ra kerdene"
  },
  {
    "x": "ho-IV (m)",
    "y": " b. hewe-I (m)"
  },
  {
    "x": "ho-V (n)",
    "y": " b. hewn (n), xow (n)"
  },
  {
    "x": "hogeç (n)",
    "y": " öveç, iki yaşında veya daha yaşlı olan koç "
  },
  {
    "x": "hohe",
    "y": " oha"
  },
  {
    "x": "hoke (m)",
    "y": " 1)dönemeç, viraj\r\n~2)açı\r\n~3)ilmek, ilmik"
  },
  {
    "x": "hoka ewnîyayîşî (sn)",
    "y": " bakış açısı"
  },
  {
    "x": "hoka nîyadayîşî (sn)",
    "y": " bakış açısı"
  },
  {
    "x": "hokeke kerdene",
    "y": " dolambaçlı bir yol takip etmek "
  },
  {
    "x": "hokekekerdiş (n)",
    "y": " dolambaçlı bir yol takip etme"
  },
  {
    "x": "hokeki kerdene",
    "y": " b. hokeke kerdene"
  },
  {
    "x": "hokekikerdiş (n)",
    "y": " b. hokekekerdiş (n)"
  },
  {
    "x": "hokepeym (n)",
    "y": " açıölçer, iletki"
  },
  {
    "x": "hoki (m)",
    "y": " b. hoke (m)"
  },
  {
    "x": "hokin, -e",
    "y": " açılı"
  },
  {
    "x": "hokin birnayene",
    "y": " açılı kesmek"
  },
  {
    "x": "hokmat (n)",
    "y": " b. hukmat (n)"
  },
  {
    "x": "hol, -e",
    "y": " iyi\r\n~Ez hol a. (Ben iyiyim.)"
  },
  {
    "x": "hol bîyene",
    "y": " 1)iyi olmak\r\n~2)iyileşmek"
  },
  {
    "x": "hol gurenayene",
    "y": " değerlendirmek, kıymetlendirmek"
  },
  {
    "x": "hol kerdene",
    "y": " 1)iyi etmek\r\n~2)iyileştirmek\r\n~3)ıslah etmek"
  },
  {
    "x": "hol şuxulnayene",
    "y": " değerlendirmek, kıymetlendirmek"
  },
  {
    "x": "hol xebitnayene",
    "y": " değerlendirmek, kıymetlendirmek"
  },
  {
    "x": "hol-xirab",
    "y": " iyi kötü, acı tatlı"
  },
  {
    "x": "hol kerdene",
    "y": " b. hil kerdene"
  },
  {
    "x": "holar (n)",
    "y": " b. wilar (n)"
  },
  {
    "x": "holbîyayîş (n)",
    "y": " 1)iyi olma\r\n~2)iyileşme, sağalma"
  },
  {
    "x": "holey (m)",
    "y": " b. holîye (m)"
  },
  {
    "x": "holê (m)",
    "y": " b. holî (m)"
  },
  {
    "x": "holêr (m)",
    "y": " b. hewrêre (m)"
  },
  {
    "x": "holgurenayîş (n)",
    "y": " değerlendirme, kıymetlendirme"
  },
  {
    "x": "holi (n)",
    "y": " b. howle (n)"
  },
  {
    "x": "holî (m)",
    "y": " iyilik"
  },
  {
    "x": "holîye (m)",
    "y": " iyilik"
  },
  {
    "x": "holkerdiş-I (n)",
    "y": " 1)iyi etme\r\n~2)iyileştirme\r\n~3)ıslah, ıslah etme"
  },
  {
    "x": "holkerdiş-II (n)",
    "y": " b. hilkerdiş (n)"
  },
  {
    "x": "Hollanda (m)",
    "y": " Hollanda"
  },
  {
    "x": "Hollywood (Holîwûd)",
    "y": " Hollywood"
  },
  {
    "x": "holşuxulnayîş (n)",
    "y": " değerlendirme,kıymetlendirme"
  },
  {
    "x": "holxebitnayîş (n)",
    "y": " değerlendirme, kıymetlendirme"
  },
  {
    "x": "Homa (n)",
    "y": " Tanrı, Allah, ilah"
  },
  {
    "x": "Homa bihefedno.",
    "y": " b. Homa bihefizno."
  },
  {
    "x": "Homa bihefizno. ",
    "y": " Allah korusun."
  },
  {
    "x": "Homa biko",
    "y": " Allah vere de, Allah vere"
  },
  {
    "x": "Homa bipawo.",
    "y": " Allah korusun"
  },
  {
    "x": "Homa bîyaro werê",
    "y": " b. Homa werê bîyaro"
  },
  {
    "x": "Homa cayê ey deko.",
    "y": " Tanrı yerini doldursun."
  },
  {
    "x": "Homa herinda ey deko. ",
    "y": " Tanrı yerini doldursun."
  },
  {
    "x": "Homa hes biko",
    "y": " Allah izin verirse"
  },
  {
    "x": "Homa hifz û muhafeze biko.",
    "y": " Allah korusun"
  },
  {
    "x": "Homa nêko",
    "y": " Allah etmesin, Allah göstermesin"
  },
  {
    "x": "Homa rehetîye bido. ",
    "y": " İyi geceler."
  },
  {
    "x": "Homa sitar biko.",
    "y": " Allah korusun"
  },
  {
    "x": "Homa şîfa bido. ",
    "y": " Tanrı/Allah şifa versin./Geçmiş olsun."
  },
  {
    "x": "Homa tala",
    "y": " b. Homa teala"
  },
  {
    "x": "Homa teala",
    "y": " Allahuteala"
  },
  {
    "x": "Homa vajo",
    "y": " Allah izin verirse"
  },
  {
    "x": "Homa vajo e",
    "y": " Allah izin verirse"
  },
  {
    "x": "Homa werê bîyaro",
    "y": " Allah izin verirse"
  },
  {
    "x": "Homa zano.",
    "y": " Allah bilir, Allahualem"
  },
  {
    "x": "Homa zono.",
    "y": " b. Homa zano."
  },
  {
    "x": "Homa zûnû.",
    "y": " b. Homa zano."
  },
  {
    "x": "Homa zîyade biko.",
    "y": " Tanrı ziyade etsin/artırsın."
  },
  {
    "x": "Homay (n)",
    "y": " b. Homa (n)"
  },
  {
    "x": "Homay biko",
    "y": " b. Homa biko"
  },
  {
    "x": "Homay hes biko",
    "y": " b. Homa hes biko"
  },
  {
    "x": "Homay nêko",
    "y": " b. Homa nêko"
  },
  {
    "x": "Homay ra eyan a.",
    "y": " b. Homayî ra eyan a."
  },
  {
    "x": "Homay vajo",
    "y": " b. Homa vajo"
  },
  {
    "x": "Homay vajo e",
    "y": " b. Homa vajo e"
  },
  {
    "x": "Homay zano.",
    "y": " b. Homa zano."
  },
  {
    "x": "Homay zono.",
    "y": " b. Homa zano."
  },
  {
    "x": "Homay zûnû.",
    "y": " b. Homa zano."
  },
  {
    "x": "(Ti) Homay kenî/a",
    "y": " b. (Ti) Homayî kenî/a"
  },
  {
    "x": "(Ti) Homay sînenî/a",
    "y": " b. (Ti) Homayî sînenî/a"
  },
  {
    "x": "(Ti) Homayî kenî/a",
    "y": " Allahını seversen, Allah aşkına"
  },
  {
    "x": "Homayî ra eyan a.",
    "y": " Allah bilir, Allahualem"
  },
  {
    "x": "(Ti) Homayî sînenî/a",
    "y": " Allahını seversen, Allah aşkına"
  },
  {
    "x": "Ma kêmî kerd, Homa bizêdno. ",
    "y": "Biz eksilttik, Tanrı ziyade etsin/artırsın."
  },
  {
    "x": "Ma kêmî kerd, Homa kêmî nêko.",
    "y": " Biz eksilttik, Tanrı eksiltmesin."
  },
  {
    "x": "Ma kêmî kerd, Homa vêşî biko. ",
    "y": " Biz eksilttik, Tanrı ziyade etsin/artırsın."
  },
  {
    "x": "Ma kêmî kerd, Homa zîyade biko. ",
    "y": "Biz eksilttik, Tanrı ziyade etsin/artırsın."
  },
  {
    "x": "homa",
    "y": " b. hema-I"
  },
  {
    "x": "homa ra",
    "y": " b. hema-1"
  },
  {
    "x": "homaygirota (m)",
    "y": " b. homayîgirewta (m)"
  },
  {
    "x": "homaygirote (n)",
    "y": " b. homayîgirewte (n)"
  },
  {
    "x": "homaygiroti (n)",
    "y": " b. homayîgirewte (n)"
  },
  {
    "x": "homayî, -ye",
    "y": " Tanrısal, ilahi"
  },
  {
    "x": "homayîgirewta (m)",
    "y": " Allah canını alasıca"
  },
  {
    "x": "homayîgirewte (n)",
    "y": " Allah canını alasıca"
  },
  {
    "x": "homete (m)",
    "y": " 1)herkes\r\n~2)Müslüman toplumu, ümmet\r\n~3)toplum"
  },
  {
    "x": "hometa Mihemedî (m)",
    "y": " Muhammed'in ümmeti, ümmet-i Muhammed"
  },
  {
    "x": "homeyîne",
    "y": " b. ameyene"
  },
  {
    "x": "homeyîne kîştene",
    "y": " b. ameyene kiştene"
  },
  {
    "x": "homeyîş (n)",
    "y": " ameyîş (n)"
  },
  {
    "x": "homeyîşkîştiş (n)",
    "y": " b. ameyîşkiştiş (n)"
  },
  {
    "x": "hon (n)",
    "y": " b. hewn (n), xow (n)"
  },
  {
    "x": "hon a kerdene",
    "y": " b. hewn a kerdene"
  },
  {
    "x": "hon a şîyayene",
    "y": " b. hewn a şîyene"
  },
  {
    "x": "hon a şîyene",
    "y": " b. hewn a şîyene"
  },
  {
    "x": "hona-I",
    "y": " b. winî"
  },
  {
    "x": "hona-II",
    "y": " b. hîna"
  },
  {
    "x": "hona-III",
    "y": " b. hewna-I"
  },
  {
    "x": "honakerdiş (n)",
    "y": " b. hewnakerdiş (n)"
  },
  {
    "x": "honaşeyîş (n)",
    "y": " b. hewnaşîyayîş (n)"
  },
  {
    "x": "honaşîyayîş (n)",
    "y": " b. hewnaşîyayîş (n)"
  },
  {
    "x": "honc",
    "y": " b. ancî"
  },
  {
    "x": "honcî",
    "y": " b. ancî"
  },
  {
    "x": "honday",
    "y": " b. hende"
  },
  {
    "x": "honde",
    "y": " b. hende"
  },
  {
    "x": "honde ke",
    "y": " b. hende ke"
  },
  {
    "x": "hondê",
    "y": " b. hendê … "
  },
  {
    "x": "Hondûras (n)",
    "y": " Honduras"
  },
  {
    "x": "honeyî",
    "y": " b. asarên, henên"
  },
  {
    "x": "honê",
    "y": " b. hewna-I"
  },
  {
    "x": "honik, -e",
    "y": " serin"
  },
  {
    "x": "honike (m)",
    "y": " b. xonçike (m)"
  },
  {
    "x": "honîyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "honqar (n)",
    "y": " b. hanqar (n)"
  },
  {
    "x": "honuk",
    "y": " b. honik, -e"
  },
  {
    "x": "Honyara (m)",
    "y": " Honiara"
  },
  {
    "x": "honzar",
    "y": " b. hezar "
  },
  {
    "x": "hooş",
    "y": " (eşeğin durması için) çüş "
  },
  {
    "x": "hooyş",
    "y": " b. hooş"
  },
  {
    "x": "hope (m)",
    "y": " havuz"
  },
  {
    "x": "hopi (m)",
    "y": " b. hope (m)"
  },
  {
    "x": "hophopik, -e",
    "y": " 1)ibibik kuşu, hüthüt\r\n~2)ciddiyetsiz, gayriciddi"
  },
  {
    "x": "hoqa (m)",
    "y": " b. weqa (m)"
  },
  {
    "x": "hoqebaz, -e",
    "y": " hokkabaz"
  },
  {
    "x": "hoqqebaz, -e",
    "y": " b. hoqebaz"
  },
  {
    "x": "hor (n)",
    "y": " b. hewr (n)"
  },
  {
    "x": "hor dina",
    "y": " 1)b. her dîyan, her dîyine\r\n~2)b. her di"
  },
  {
    "x": "hora-I (s)",
    "y": " b. hewra (s)"
  },
  {
    "x": "hora-II",
    "y": " b. xora"
  },
  {
    "x": "horayê (m)",
    "y": " b. hewrayî (m)"
  },
  {
    "x": "horayî (m)",
    "y": " b. hewrayî (m)"
  },
  {
    "x": "hordi",
    "y": " b. hurdî, -ye"
  },
  {
    "x": "hores (n)",
    "y": " b. vewrês (n)"
  },
  {
    "x": "horês (n)",
    "y": " b. vewrês (n)"
  },
  {
    "x": "horin (n)",
    "y": " b. hewrin (n)"
  },
  {
    "x": "horinê (m)",
    "y": " b. hewrinîye (m)"
  },
  {
    "x": "horîyes (n)",
    "y": " b. vewrês (n)"
  },
  {
    "x": "hors-I (n)",
    "y": " b. wers-I (n)"
  },
  {
    "x": "hors-II (n)",
    "y": " b. ors-II (n)"
  },
  {
    "x": "horte (n)",
    "y": " b. orte (n)"
  },
  {
    "x": "horyes (n)",
    "y": " b. vewrês (n)"
  },
  {
    "x": "hosta, -ye",
    "y": " b. westa, -ye"
  },
  {
    "x": "hoste (n)",
    "y": " b. westa (n)"
  },
  {
    "x": "hoş-I",
    "y": " (eşek için) deh, dah"
  },
  {
    "x": "hoş kerdene",
    "y": " (eşek için) dehlemek, dahlamek, deh demek, deh etmek"
  },
  {
    "x": "hoş-II (n)",
    "y": " b. hewş-I (n)"
  },
  {
    "x": "hoşt!",
    "y": " hoşt"
  },
  {
    "x": "hot",
    "y": " b. hewt"
  },
  {
    "x": "hot sey",
    "y": " b. hewt sey"
  },
  {
    "x": "hot sê",
    "y": " b. hewt sey"
  },
  {
    "x": "hotanayîne",
    "y": " b. ewtanayene"
  },
  {
    "x": "hotanayîş (n)",
    "y": " b. ewtanayîş (n)"
  },
  {
    "x": "hotay",
    "y": " b. hewtay "
  },
  {
    "x": "hote (n)",
    "y": " b. hewte (n)"
  },
  {
    "x": "hotês",
    "y": " b. hewtês"
  },
  {
    "x": "hotin, -e",
    "y": " hewtin, -e"
  },
  {
    "x": "hotîn, -e",
    "y": " hewtin, -e"
  },
  {
    "x": "hoto (n)",
    "y": " 1)başıboş\r\n~2)ciddiyetsiz, gayriciddi"
  },
  {
    "x": "hotoboz (n)",
    "y": " b. otobuse (m)"
  },
  {
    "x": "hotyes",
    "y": " b. hewtês"
  },
  {
    "x": "hovîrakerdiş (n)",
    "y": " b. xovîrrakerdiş (n)"
  },
  {
    "x": "hovnayêne",
    "y": " inlemek "
  },
  {
    "x": "hovnayîş (n)",
    "y": " inleme"
  },
  {
    "x": "how (m)",
    "y": " b. hewe-I (m)"
  },
  {
    "x": "howl, -e",
    "y": " b. hol, -e"
  },
  {
    "x": "howlbîyayîş (n) ",
    "y": " b. holbîyayîş (n)"
  },
  {
    "x": "howle (n)",
    "y": " örtme (bina için)"
  },
  {
    "x": "howley (m)",
    "y": " b. holîye (m)"
  },
  {
    "x": "howlê (m)",
    "y": " b. holî (m)"
  },
  {
    "x": "howli-I (m)",
    "y": " b. hole (m)"
  },
  {
    "x": "howli-II (n)",
    "y": " b. howle (n)"
  },
  {
    "x": "howlkerdiş (n)",
    "y": " b. holkerdiş-I (n)"
  },
  {
    "x": "hown (n)",
    "y": " b. hewn (n), xow (n)"
  },
  {
    "x": "hown a kardene",
    "y": " b. hewn a kerdene"
  },
  {
    "x": "hown a kerdene",
    "y": " b. hewn a kerdene"
  },
  {
    "x": "hown a şîyayene",
    "y": " b. hewn a şîyene"
  },
  {
    "x": "howna",
    "y": " b. hewna-I"
  },
  {
    "x": "hownakardiş (n)",
    "y": " b. hewnakerdiş (n)"
  },
  {
    "x": "hownakerdiş (n)",
    "y": " b. hewnakerdiş (n)"
  },
  {
    "x": "hownaşeyîş (n)",
    "y": " b. hewnaşîyayîş (n)"
  },
  {
    "x": "hownaşîyayîş (n)",
    "y": " b. hewnaşîyayîş (n)"
  },
  {
    "x": "howneyîne",
    "y": " b. ewnîyayene"
  },
  {
    "x": "howneyîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "hownîyayîne",
    "y": " b. ewnîyayene"
  },
  {
    "x": "hownîyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "howr (n)",
    "y": " b. hewr (n)"
  },
  {
    "x": "howra (s)",
    "y": " b. hewra (s)"
  },
  {
    "x": "howrês (n)",
    "y": " b. vewrês (n)"
  },
  {
    "x": "howrin (n)",
    "y": " b. hewrin (n)"
  },
  {
    "x": "howriney (m)",
    "y": " b. hewrinîye (m)"
  },
  {
    "x": "howş (n)",
    "y": " b. hewş-I (n)"
  },
  {
    "x": "howt",
    "y": " b. hewt "
  },
  {
    "x": "howt sey",
    "y": " b. hewt sey"
  },
  {
    "x": "howtanayîne",
    "y": " b. ewtanayene"
  },
  {
    "x": "howtanayîş (n)",
    "y": " b. ewtanayîş (n)"
  },
  {
    "x": "howtay",
    "y": " b. hewtay"
  },
  {
    "x": "howte (n)",
    "y": " b. hewte (n)"
  },
  {
    "x": "howtetîr (n)",
    "y": " tabanca"
  },
  {
    "x": "howtin",
    "y": " b. hewtin"
  },
  {
    "x": "howz",
    "y": " hewz, -e-I"
  },
  {
    "x": "howzi (m)",
    "y": " b. hewze (m)"
  },
  {
    "x": "hoybaşênî (m)",
    "y": " başorakçılık"
  },
  {
    "x": "hoybaşî, -ye",
    "y": " başorakçı, eğnelbaşı"
  },
  {
    "x": "hoybaşîtîye (m)",
    "y": " başorakçılık"
  },
  {
    "x": "hoye (m)",
    "y": " ekin veya ot biçerken biçmek için yönelinen ve hedeflenen mesafe"
  },
  {
    "x": "hoyn (n)",
    "y": " b. hewn (n), xow (n)"
  },
  {
    "x": "hoyna",
    "y": " b. hewna-I"
  },
  {
    "x": "hoyneyîne",
    "y": " b. ewnîyayene"
  },
  {
    "x": "hoyneyîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "hoynê",
    "y": " b. hewna-I"
  },
  {
    "x": "hoynîyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "bihoynî",
    "y": " b. biewnî"
  },
  {
    "x": "hoyş",
    "y": " b. hoş"
  },
  {
    "x": "hoyş kerdene",
    "y": " b. hoş kerdene"
  },
  {
    "x": "hoz",
    "y": " hewz, -e-I"
  },
  {
    "x": "hozan, -e",
    "y": " saz şairi"
  },
  {
    "x": "hozor",
    "y": " b. hezar"
  },
  {
    "x": "hu-I",
    "y": " b. xo-I"
  },
  {
    "x": "hu-II (n)",
    "y": " b. huye (n) "
  },
  {
    "x": "hudid (n)",
    "y": " b. hudud (n)"
  },
  {
    "x": "hudud (n)",
    "y": " sınır, hudut"
  },
  {
    "x": "hudûd (n)",
    "y": " b. hudud (n)"
  },
  {
    "x": "hukim (n)",
    "y": " b. hukm (n)"
  },
  {
    "x": "hukimat (n)",
    "y": " hükümet"
  },
  {
    "x": "hukm (n)",
    "y": " yargı, hüküm"
  },
  {
    "x": "hukmat (n)",
    "y": " hükümet"
  },
  {
    "x": "hukmat ronayene",
    "y": " hükümet kurmak"
  },
  {
    "x": "hukmî, -ye",
    "y": " tüzel"
  },
  {
    "x": "hukum (n)",
    "y": " b. hukm (n)"
  },
  {
    "x": "hukumat (n)",
    "y": " b. hukmat (n)"
  },
  {
    "x": "hule-I (n)",
    "y": " koni"
  },
  {
    "x": "hule-II (n)",
    "y": " aşık kemiği ile oynanan bir oyun"
  },
  {
    "x": "huli (m)",
    "y": " b. hele-II (m)"
  },
  {
    "x": "huli di",
    "y": " b. hele de"
  },
  {
    "x": "hula êrî (m)",
    "y": " b. hela êreyî (m)"
  },
  {
    "x": "hula nimajî (m)",
    "y": " b. hela nimajî (m)"
  },
  {
    "x": "hulikin, -e",
    "y": " konik"
  },
  {
    "x": "hulî",
    "y": " hindi"
  },
  {
    "x": "hulle (n)",
    "y": " b. hule-II (n)"
  },
  {
    "x": "hum",
    "y": " b. hem-I"
  },
  {
    "x": "hum ... hum ...",
    "y": " b. hem ... hem ..."
  },
  {
    "x": "huma",
    "y": " b. hema-I"
  },
  {
    "x": "huma ra",
    "y": " b. hema-I"
  },
  {
    "x": "humay",
    "y": " b. hema-I"
  },
  {
    "x": "hun",
    "y": " b. ûne"
  },
  {
    "x": "huna",
    "y": " b. wina"
  },
  {
    "x": "hunaz, -e",
    "y": " başorakçı, eğnelbaşı"
  },
  {
    "x": "hunazî (m)",
    "y": " başorakçılık"
  },
  {
    "x": "huncî",
    "y": " b. ancî"
  },
  {
    "x": "hunçila (m)",
    "y": " b. kengula (m)"
  },
  {
    "x": "hund",
    "y": " b. hende"
  },
  {
    "x": "hunda",
    "y": " b. hendê-1"
  },
  {
    "x": "hunde",
    "y": " b. hende"
  },
  {
    "x": "hundê",
    "y": " b. hendê-1"
  },
  {
    "x": "hune",
    "y": " b. ûne"
  },
  {
    "x": "huner (n)",
    "y": " beceri, hüner, sanat"
  },
  {
    "x": "hunerê sînema (n)",
    "y": " sinema sanatı"
  },
  {
    "x": "hunero edebî (n)",
    "y": " edebi beceri"
  },
  {
    "x": "hunermend, -e",
    "y": " sanatçı"
  },
  {
    "x": "hunermenda/hunermendê opera",
    "y": " opera sanatçısı"
  },
  {
    "x": "huney",
    "y": " b. hewna-I"
  },
  {
    "x": "hunê",
    "y": " b. hewna-I"
  },
  {
    "x": "hungir (m)",
    "y": " b. engure (m)"
  },
  {
    "x": "hunî",
    "y": " b. winî, ayhawa"
  },
  {
    "x": "hunîn",
    "y": " b. henên, asarên"
  },
  {
    "x": "hunîyayîş (n)",
    "y": " b. ewnîyayîş (n) "
  },
  {
    "x": "hunkuf, -i",
    "y": " b. hinkuf, -e"
  },
  {
    "x": "huno",
    "y": " b. wina"
  },
  {
    "x": "hunyayîş (n)",
    "y": " b. ewnîyayîş (n) "
  },
  {
    "x": "hunzar",
    "y": " b. hezar"
  },
  {
    "x": "huqa (m)",
    "y": " b. weqa (m)"
  },
  {
    "x": "huquq (n)",
    "y": " b. huqûq (n)"
  },
  {
    "x": "huqûq (n)",
    "y": " hukuk"
  },
  {
    "x": "huqûqê arizî yê deynan (n)",
    "y": " borçlar özel hukuku"
  },
  {
    "x": "huqûqê arizî yê mîyandewletan (n)",
    "y": " devletlerarası özel hukuk "
  },
  {
    "x": "huqûqê asayîşê cematkî (n)",
    "y": " sosyal güvenlik hukuku"
  },
  {
    "x": "huqûqê asayîşê sosyalî (n)",
    "y": " sosyal güvenlik hukuku"
  },
  {
    "x": "huqûqê bacî (n)",
    "y": " vergi hukuku "
  },
  {
    "x": "huqûqê bankaçîyîye (n)",
    "y": " bankacılık hukuku "
  },
  {
    "x": "huqûqê bazirganîye (n)",
    "y": " ticaret hukuku"
  },
  {
    "x": "huqûqê erazî (n)",
    "y": " arazi hukuku, toprak hukuku"
  },
  {
    "x": "huqûqê îcra-îflasî (n)",
    "y": " icra-iflas hukuku "
  },
  {
    "x": "huqûqê karî (n)",
    "y": " iş hukuku"
  },
  {
    "x": "huqûqê keyeyî (n)",
    "y": " aile hukuku "
  },
  {
    "x": "huqûqê malîye (n)",
    "y": " maliye hukuku "
  },
  {
    "x": "huqûqê mîrasî (n)",
    "y": " miras hukuku"
  },
  {
    "x": "huqûqê mîyandewletan (n)",
    "y": " devletlerarası hukuk"
  },
  {
    "x": "huqûqê pêroyî (n)",
    "y": " kamu hukuku, amme hukuku"
  },
  {
    "x": "huqûqê pêroyî yê dewletan (n)",
    "y": " devletler genel hukuku"
  },
  {
    "x": "huqûqê pêroyî yê deynan (n)",
    "y": " borçlar genel hukuku"
  },
  {
    "x": "huqûqê qanûnê bingeyî (n)",
    "y": " anayasa hukuku "
  },
  {
    "x": "huqûqê Roma (n)",
    "y": " Roma hukuku"
  },
  {
    "x": "huqûqê tîcaretî (n)",
    "y": " ticaret hukuku"
  },
  {
    "x": "huqûqê umûmîya (n)",
    "y": " kamu hukuku, amme hukuku"
  },
  {
    "x": "huqûqê ûsulê ceza (n)",
    "y": " ceza usul hukuku "
  },
  {
    "x": "huqûqê ûsulê medenî (n)",
    "y": " medeni usul hukuku"
  },
  {
    "x": "huqûqê wayîrîye (n)",
    "y": " mülkiyet hukuku"
  },
  {
    "x": "huqûqê Yewîya Ewropa (n)",
    "y": " Avrupa Birliği hukuku "
  },
  {
    "x": "huqûqo arizî (n)",
    "y": " özel hukuk, hususi hukuk"
  },
  {
    "x": "huqûqo medenî (n)",
    "y": " medeni hukuk "
  },
  {
    "x": "huqûqo pêroyî (n)",
    "y": " genel hukuk, umumi hukuk"
  },
  {
    "x": "huqûqo tebîî (n)",
    "y": " doğal hukuk, tabii hukuk"
  },
  {
    "x": "huqûqo xozayî (n)",
    "y": " doğal hukuk, tabii hukuk"
  },
  {
    "x": "huqûqî, -ye",
    "y": " tüzel, hukuksal, hukuki"
  },
  {
    "x": "huqûqnas, -e",
    "y": " hukukçu"
  },
  {
    "x": "hur-I",
    "y": " b. her"
  },
  {
    "x": "hur çar",
    "y": " b. her çar"
  },
  {
    "x": "hur demêna",
    "y": " b. her dîyan, her dîyine"
  },
  {
    "x": "hur dê",
    "y": " b. her di"
  },
  {
    "x": "hur dêmîna",
    "y": " b. her dîyan "
  },
  {
    "x": "hur dêna",
    "y": " b. her dîyan, her dîyine"
  },
  {
    "x": "hur di",
    "y": " b. her di"
  },
  {
    "x": "hur dima",
    "y": " b. her di, her dîyan"
  },
  {
    "x": "hur dimêna",
    "y": " b. her dîyan, her dîyine"
  },
  {
    "x": "hur dimîn",
    "y": " b. her dîyan, her dîyine"
  },
  {
    "x": "hur dimîna",
    "y": " b. her dîyan, her dîyine"
  },
  {
    "x": "hur dimîne",
    "y": " b. her dîyan, her dîyine "
  },
  {
    "x": "hur dina",
    "y": " b. her dîyan, her dîyine, her di"
  },
  {
    "x": "hur dî",
    "y": " b. her di "
  },
  {
    "x": "hur dîmena",
    "y": " b. her dîyan, her dîyine"
  },
  {
    "x": "hur dîmîne",
    "y": " b. her dîyan, her dîyine"
  },
  {
    "x": "hur dîna",
    "y": " b. her dîyan, her dîyine, her di"
  },
  {
    "x": "hur gi",
    "y": " b. her yew"
  },
  {
    "x": "hur ke",
    "y": " b. her yew"
  },
  {
    "x": "hur mîn",
    "y": " b. her dîyan"
  },
  {
    "x": "hur mînî",
    "y": " b. her dîyan"
  },
  {
    "x": "hur mêna",
    "y": " b. her di, her dîyan"
  },
  {
    "x": "hur mîna",
    "y": " b. her dîyan"
  },
  {
    "x": "hur-II (n)",
    "y": " b. wir (n)"
  },
  {
    "x": "hura",
    "y": " b. xora"
  },
  {
    "x": "hurak (n)",
    "y": " keser"
  },
  {
    "x": "hurdi, -ye",
    "y": " b. hurdî, -ye"
  },
  {
    "x": "hurdina",
    "y": " b. her dîyan, her dîyine"
  },
  {
    "x": "hurdinan",
    "y": " b. her dîyan, her dîyine"
  },
  {
    "x": "hurdî, -ye",
    "y": " küçük, ufak, kırıntı"
  },
  {
    "x": "hurdî kerdene",
    "y": " 1)doğramak (ekmek vb. için), ufalamak (ekmek vb. için)\r\n~2)bozmak (para için)"
  },
  {
    "x": "hurdîyê belxurî (n)",
    "y": " ince bulgur"
  },
  {
    "x": "hurdîyê nanî (n)",
    "y": " ekmek kırıntısı"
  },
  {
    "x": "hurdî-hurdî",
    "y": " 1)küçük küçük, ufak ufak, ince ince\r\n~2)giderek, gittikçe, yavaş yavaş"
  },
  {
    "x": "hurdî-hurdî varayene",
    "y": " çiselemek, "
  },
  {
    "x": "hurdî-hurdîvarayîş (n)",
    "y": " çiseleme, çiseme"
  },
  {
    "x": "hurdîkerdiş (n)",
    "y": " 1)doğrama (ekmek vb. için), ufalama (ekmek vb. için)\r\n~2)bozma (para için)"
  },
  {
    "x": "hurdîna",
    "y": " b. her dîyan, her dîyine"
  },
  {
    "x": "hurdînan",
    "y": " b. her dîyan, her dîyine"
  },
  {
    "x": "hure vîştene",
    "y": " b. werê fîştene"
  },
  {
    "x": "hurenayene",
    "y": " b. hurênayene"
  },
  {
    "x": "hurendî (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "hurê amayene",
    "y": " b. werê ameyene"
  },
  {
    "x": "hurê amayêne",
    "y": " b. werê ameyene"
  },
  {
    "x": "hurê ameyîne",
    "y": " b. werê ameyene"
  },
  {
    "x": "hurê ardene",
    "y": " b. werê ardene"
  },
  {
    "x": "hurê dayene",
    "y": " b. werê dayene"
  },
  {
    "x": "hurê dîyayene",
    "y": " b. werê dîyayene"
  },
  {
    "x": "hurê fînîyayene",
    "y": " b. werê fînîyayene"
  },
  {
    "x": "hurê fîstene",
    "y": " b. werê fîştene"
  },
  {
    "x": "hurêamayîş (n)",
    "y": " b. werêameyîş (n)"
  },
  {
    "x": "hurêardiş (n)",
    "y": " b. werêardiş (n)"
  },
  {
    "x": "hurêdayîş (n)",
    "y": " werêdayîş (n)"
  },
  {
    "x": "hurêdîyayîş (n)",
    "y": " b. werêdîyayîş (n)"
  },
  {
    "x": "hurêfînîyayîş (n)",
    "y": " b. werêfînîyayîş (n)"
  },
  {
    "x": "hurêfîstîş (n)",
    "y": " b. werêfîştiş (n)"
  },
  {
    "x": "hurênayene",
    "y": " sertçe tartışmak, ağız dalaşında bulunmak, ağız kavgası yapmak, ağız münakaşası yapmak "
  },
  {
    "x": "hurênayîs (n)",
    "y": " b. hurênayîş (n)"
  },
  {
    "x": "hurênayîş (n)",
    "y": " 1)sertçe tartışma, ağız dalaşı, ağız kavgası\r\n~2)ağız dalaşında bulunma, ağız kavgası yapma, ağız münakaşası yapma "
  },
  {
    "x": "hurênd (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "hurêndî (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "hurgit (n)",
    "y": " b. hurhurit (n)"
  },
  {
    "x": "hurhurit (n)",
    "y": " 1)ağız dalaşı, ağız kavgası \r\n~2)gürültü patırtı"
  },
  {
    "x": "hurinde (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "hurindi (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "hurine (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "huriştene",
    "y": " b. weriştene"
  },
  {
    "x": "huriştiş (n)",
    "y": " b. weriştiş (n)"
  },
  {
    "x": "hurînde (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "hurîndi (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "hurma (m)",
    "y": " b.hurme (m) "
  },
  {
    "x": "hurme (m)",
    "y": " bayan"
  },
  {
    "x": "hurmet (n)",
    "y": " saygı, ihtiram, hürmet"
  },
  {
    "x": "hurmete (m)",
    "y": " b. hurmet (n)"
  },
  {
    "x": "hurmetkar, -e",
    "y": " saygılı, hürmetkâr, hürmetli"
  },
  {
    "x": "hurmetkarey (m)",
    "y": " b. hurmetkarî (m), hurmetkarîye (m)"
  },
  {
    "x": "hurmetkarê (m)",
    "y": " b. hurmetkarî (m), hurmetkarîye (m)"
  },
  {
    "x": "hurmetkarênî (m)",
    "y": " saygılılık"
  },
  {
    "x": "hurmetkarî (m)",
    "y": " saygılılık"
  },
  {
    "x": "hurmetkarîye (m)",
    "y": " saygılılık"
  },
  {
    "x": "hurmi (m)",
    "y": " b.hurme (m)"
  },
  {
    "x": "hurna",
    "y": " b. her di, her dîyan, her dîyine"
  },
  {
    "x": "hurunde (m)",
    "y": " b. herinde (m)"
  },
  {
    "x": "husk, -e",
    "y": " b. huşk, -e"
  },
  {
    "x": "husk bîyene",
    "y": " b. huşk bîyene"
  },
  {
    "x": "husk kerdene",
    "y": " b. huşk kerdene"
  },
  {
    "x": "husk û biring, -e",
    "y": " b. huşk û biring, -e"
  },
  {
    "x": "huska (n)",
    "y": " b. huşka (n)"
  },
  {
    "x": "huskbîyayîş (n)",
    "y": " b. huşkbîyayîş (n)"
  },
  {
    "x": "huskênî (m)",
    "y": " b. huşkênî (m)"
  },
  {
    "x": "huskîye (m)",
    "y": " b. huşkîye (m), huşkî (m)"
  },
  {
    "x": "huskkerdiş (n)",
    "y": " b. huşkkerdiş (n)"
  },
  {
    "x": "hustor, -i",
    "y": " b. estor, -e"
  },
  {
    "x": "huşk, -e",
    "y": " 1)kuru\r\n~2)katı"
  },
  {
    "x": "huşk bîyene",
    "y": " kurumak"
  },
  {
    "x": "huşk kerdene",
    "y": " kurutmak"
  },
  {
    "x": "huşk û biring, -e",
    "y": " kupkuru"
  },
  {
    "x": "huşk û hol, -e",
    "y": " kupkuru, kaskatı"
  },
  {
    "x": "huşk û wad, -e",
    "y": " kupkuru"
  },
  {
    "x": "huşk û war, -e",
    "y": " kupkuru, kaskatı"
  },
  {
    "x": "huşk-biring, -e",
    "y": " kupkuru"
  },
  {
    "x": "huşka (n)",
    "y": " ayaz"
  },
  {
    "x": "huşkbîyayîş (n)",
    "y": " kuruma"
  },
  {
    "x": "huşkdês (n)",
    "y": " sadece taşlardan örülen harçsız duvar"
  },
  {
    "x": "huşkedîsk (n) (TE)",
    "y": " sabit disk"
  },
  {
    "x": "huşkênî (m)",
    "y": " kuruluk"
  },
  {
    "x": "huşkfêkî (n)",
    "y": " kuruyemiş"
  },
  {
    "x": "huşkfêkîroş, -e",
    "y": " kuruyemişçi"
  },
  {
    "x": "huşkî (m)",
    "y": " kuruluk"
  },
  {
    "x": "huşkîye (m)",
    "y": " kuruluk"
  },
  {
    "x": "huşkkerdiş (n)",
    "y": " kurutma"
  },
  {
    "x": "huşkmeywe (n)",
    "y": " kuruyemiş"
  },
  {
    "x": "huşkmeyweroş, -e",
    "y": " kuruyemişçi"
  },
  {
    "x": "hut, -e-I",
    "y": " balina"
  },
  {
    "x": "hut-II (n)",
    "y": " b. wit (n)"
  },
  {
    "x": "hut antene",
    "y": " b. wit antene"
  },
  {
    "x": "hut ontene",
    "y": " b. wit antene"
  },
  {
    "x": "hutin, -i",
    "y": " b. witin, -e"
  },
  {
    "x": "hutiney (m)",
    "y": " b. witinîye (m)"
  },
  {
    "x": "huwate (n)",
    "y": " b. huye-II (n)"
  },
  {
    "x": "huwatene",
    "y": " b. huyayene"
  },
  {
    "x": "huwatiş (n)",
    "y": " b. huyayîş (n)"
  },
  {
    "x": "huwayene",
    "y": " b. huyayene"
  },
  {
    "x": "huwayîş (n)",
    "y": " b. huyayîş (n)"
  },
  {
    "x": "huwe-I (n)",
    "y": " b. huye-I (n)"
  },
  {
    "x": "huwe-II (n)",
    "y": " b. huye-II (n)"
  },
  {
    "x": "huweyîş (n)",
    "y": " b. huyayîş (n)"
  },
  {
    "x": "huwi (n)",
    "y": " b. huye-II (n)"
  },
  {
    "x": "huwî (n)",
    "y": " b. huye-I (n) "
  },
  {
    "x": "huwnayene",
    "y": " b. huynayene"
  },
  {
    "x": "huwnayîş (n)",
    "y": " b. huynayîş (n)"
  },
  {
    "x": "huwt",
    "y": " b. hewt"
  },
  {
    "x": "huwt rê se",
    "y": " b. hewt sey"
  },
  {
    "x": "huwtê",
    "y": " b. hewtay"
  },
  {
    "x": "huy (n)",
    "y": " b. huye-I (n)"
  },
  {
    "x": "huyayene",
    "y": " gülmek"
  },
  {
    "x": "huyayêne",
    "y": " b. huyayene"
  },
  {
    "x": "huyayîs (n)",
    "y": " b. huyayîş (n)"
  },
  {
    "x": "huyayîş (n)",
    "y": " gülme, gülüş"
  },
  {
    "x": "huye-I (n)",
    "y": " kürek"
  },
  {
    "x": "huyey vewri (n)",
    "y": " b. huyeyê vewre (n)"
  },
  {
    "x": "huyeyê vewre (n)",
    "y": " sıyırga"
  },
  {
    "x": "huye-II (n)",
    "y": " gülme, gülüş"
  },
  {
    "x": "huyî (n)",
    "y": " b. huye-I (n)"
  },
  {
    "x": "huynayene",
    "y": " güldürmek"
  },
  {
    "x": "huynayêne",
    "y": " b. huynayene"
  },
  {
    "x": "huynayîş (n)",
    "y": " güldürme"
  },
  {
    "x": "huzur (n)",
    "y": " huzur"
  },
  {
    "x": "hû (n)",
    "y": " b. hewn (n), xow (n)"
  },
  {
    "x": "hûk (m)",
    "y": " b. hoke (m)"
  },
  {
    "x": "hûkeki kerdene",
    "y": " b. hokeke kerdene"
  },
  {
    "x": "hûkekikerdiş (n)",
    "y": " b. hokekekerdiş (n)"
  },
  {
    "x": "hûki (m)",
    "y": " b. hoke (m)"
  },
  {
    "x": "Hûma (n)",
    "y": " b. Homa (n)"
  },
  {
    "x": "Hûmay biko",
    "y": " b. Homa biko"
  },
  {
    "x": "Hûmay hes biko",
    "y": " b. Homa hes biko"
  },
  {
    "x": "Hûmay ra eyan a.",
    "y": " b. Homayî ra eyan a."
  },
  {
    "x": "Hûmay sînenî/a",
    "y": " b. (Ti) Homayî sînenî/a"
  },
  {
    "x": "Hûma şîfa bid’.",
    "y": " b. Homa şîfa bido."
  },
  {
    "x": "Hûma tala",
    "y": " b. Homa teala"
  },
  {
    "x": "Hûma tela",
    "y": " b. Homa teala"
  },
  {
    "x": "Hûma zîyed kir.",
    "y": " b. Homa zîyade biko."
  },
  {
    "x": "(Ti) Hûmay kenî/a",
    "y": " b. (Ti) Homayî kenî/a"
  },
  {
    "x": "Hûmay nêko",
    "y": " b. Homa nêko"
  },
  {
    "x": "Hûmay vajo e",
    "y": " b. Homa vajo e"
  },
  {
    "x": "Hûmay vajo",
    "y": " b. Homa vajo"
  },
  {
    "x": "Hûmay zano.",
    "y": " b. Homa zano."
  },
  {
    "x": "hûma",
    "y": " b. hema-I"
  },
  {
    "x": "hûma ra",
    "y": " b. hema-I"
  },
  {
    "x": "hûmar (m)",
    "y": " b. hûmare (m)"
  },
  {
    "x": "hûmare (m)",
    "y": " sayı, adet"
  },
  {
    "x": "hûmara desrayine",
    "y": " ondalık sayı (mat)"
  },
  {
    "x": "hûmara keyeyan (m)",
    "y": " hane sayısı"
  },
  {
    "x": "hûmara pî (m)",
    "y": " pi sayısı (mat)"
  },
  {
    "x": "hûmara vîjnayoxan (m)",
    "y": " seçmen sayısı"
  },
  {
    "x": "hûmara weçînitoxan (m)",
    "y": " seçmen sayısı"
  },
  {
    "x": "hûmarê desrayinî",
    "y": " ondalık sayılar (mat)"
  },
  {
    "x": "hûmaritene",
    "y": " saymak"
  },
  {
    "x": "hûmaritiş (n)",
    "y": " sayma"
  },
  {
    "x": "hûmaritişê embarî (n)",
    "y": " ambar sayımı"
  },
  {
    "x": "hûmarker (n)",
    "y": " sayaç"
  },
  {
    "x": "hûmarker pakerdiş",
    "y": " sayaç takma"
  },
  {
    "x": "hûmarker pirakerdiş",
    "y": " b. hûmarker pakerdiş"
  },
  {
    "x": "hûmarkerê awe (n)",
    "y": " su sayacı"
  },
  {
    "x": "hûmarkerê elektrîkî (n)",
    "y": " elektrik sayacı"
  },
  {
    "x": "hûmarkerê gaza hewayî (n)",
    "y": " havagazı sayacı"
  },
  {
    "x": "hûmarkero xirabe (n)",
    "y": " bozuk sayaç"
  },
  {
    "x": "hûmarkî",
    "y": " sayısal"
  },
  {
    "x": "hûmarname (n)",
    "y": " sayı sözcüğü"
  },
  {
    "x": "hûmarnameyê bingeyî (zh)",
    "y": " asıl sayılar"
  },
  {
    "x": "hûmarnameyê kesîrî (zh)",
    "y": " kesir sayılar "
  },
  {
    "x": "hûmarnameyê rêzkîyî (zh)",
    "y": " sıra sayılar "
  },
  {
    "x": "hûmarnameyê zerfkîyî (zh)",
    "y": " zarfsal sayılar"
  },
  {
    "x": "hûmarker (n)",
    "y": " sayaç"
  },
  {
    "x": "hûmartene",
    "y": " b. hûmaritene"
  },
  {
    "x": "hûmartiş (n)",
    "y": " b. hûmaritiş (n)"
  },
  {
    "x": "Hûmay (n)",
    "y": " b. Homa (n)"
  },
  {
    "x": "Hûmay rehetey bido.",
    "y": " b. Homa rehetîye bido."
  },
  {
    "x": "Hûmay skenî/a",
    "y": " b. (Ti) Homayî sînenî/a."
  },
  {
    "x": "Hûmay şîfa bido.",
    "y": " b. Homa şîfa bido."
  },
  {
    "x": "Hûmay teala",
    "y": " b. Homa teala"
  },
  {
    "x": "Hûmay zêde biko.",
    "y": " b. Homa zîyade biko."
  },
  {
    "x": "Hûmay zêde kero.",
    "y": " b. Homa zîyade biko."
  },
  {
    "x": "hûmay",
    "y": " b. hema-I"
  },
  {
    "x": "hûmaygirota (m)",
    "y": " b. homayîgirewta (m)"
  },
  {
    "x": "hûmaygirote (n)",
    "y": " b. homayîgirewte (n)"
  },
  {
    "x": "hûn (n)",
    "y": " b. hewn (n), xow (n)"
  },
  {
    "x": "hûn a kerdene",
    "y": " b. hewn a kerdene"
  },
  {
    "x": "hûn a şîyayene",
    "y": " b. hewn a şîyene"
  },
  {
    "x": "hûna-I",
    "y": " b. hewna"
  },
  {
    "x": "hûna-II",
    "y": " b. hîna"
  },
  {
    "x": "hûnakerdiş (n)",
    "y": " b. hewnakerdiş (n)"
  },
  {
    "x": "hûnaşîyayîş (n)",
    "y": " b. hewnaşîyayîş (n)"
  },
  {
    "x": "hûnc",
    "y": " b. ancî"
  },
  {
    "x": "hûncê",
    "y": " b. ancî"
  },
  {
    "x": "hûncî",
    "y": " b. ancî"
  },
  {
    "x": "hûney",
    "y": " b. hewna-I"
  },
  {
    "x": "hûnê",
    "y": " b. hewna-I"
  },
  {
    "x": "hûnik, -i",
    "y": " b. honik, -e"
  },
  {
    "x": "hûnzar",
    "y": " b. hezar"
  },
  {
    "x": "Hûoma",
    "y": " b. Homa (n)"
  },
  {
    "x": "hûr (n)",
    "y": " b. hurdî (n)"
  },
  {
    "x": "hûrê nanî (n)",
    "y": " b. hurdîyê nanî (n)"
  },
  {
    "x": "hûrdanik (n)",
    "y": " ince bulgur"
  },
  {
    "x": "hûrik (n)",
    "y": " çok ince bulgur"
  },
  {
    "x": "hûrikê belxurî (n)",
    "y": " çok ince bulgur"
  },
  {
    "x": "hût, -e",
    "y": " b. hut, -e"
  },
  {
    "x": "hût (n)",
    "y": " b. wit (n)"
  },
  {
    "x": "hûtin –i",
    "y": " b. witin, -e"
  },
  {
    "x": "hûtiney (m)",
    "y": " b. witinîye (m)"
  },
  {
    "x": "hyedîkî",
    "y": " b. hêdîka"
  },
  {
    "x": "hyega (n)",
    "y": " b. hêga (n)"
  },
  {
    "x": "hyegin (n)",
    "y": " b. hêgin, -e"
  },
  {
    "x": "hyeris",
    "y": " b. hîris"
  },
  {
    "x": "hyêginê (m)",
    "y": " b. hêginîye (m), hêginî (m)"
  },
  {
    "x": "III"
  },
  {
    "x": "...ic/ici (sf)",
    "y": " b. -ij/-ije (sf)"
  },
  {
    "x": "id (pd)",
    "y": " b. de (pd)"
  },
  {
    "x": "ig",
    "y": " b. ke-I"
  },
  {
    "x": "ihind",
    "y": " b. hende"
  },
  {
    "x": "ihindasayî",
    "y": " b. hende"
  },
  {
    "x": "ihindi",
    "y": " b. hende"
  },
  {
    "x": "ihna",
    "y": " b. wina"
  },
  {
    "x": "...ij/ije (sf)",
    "y": " -lı/-li (mensubiyet anlamı veren bir sonek)"
  },
  {
    "x": "çewlîgij, -e",
    "y": " Bingöllü"
  },
  {
    "x": "dêrsimij, -e",
    "y": " Dersimli"
  },
  {
    "x": "pîranij, -e",
    "y": " Piranlı"
  },
  {
    "x": "ijdirha (n)",
    "y": " b. ejdeha (n)"
  },
  {
    "x": "ijdîya (n)",
    "y": " b. ejdeha (n)"
  },
  {
    "x": "ijînayene",
    "y": " b. nijnayene"
  },
  {
    "x": "ijkiji (m)",
    "y": " b. eskije (m)"
  },
  {
    "x": "ik",
    "y": " b. ke-I"
  },
  {
    "x": "ika",
    "y": " b. nika"
  },
  {
    "x": "ikê",
    "y": " b. nika"
  },
  {
    "x": "ilaqe (n)",
    "y": " b. eleqe (n)"
  },
  {
    "x": "ilm (n)",
    "y": " b. îlim (n)"
  },
  {
    "x": "ilmdar, -e",
    "y": " b. îlimdar, -e"
  },
  {
    "x": "ilmdarî (m)",
    "y": " b. îlimdarî (m), îlimdarîye (m)"
  },
  {
    "x": "ilmî, -ye",
    "y": " b. îlmî, -ye"
  },
  {
    "x": "ilorî (m)",
    "y": " b. helasorî, -ye"
  },
  {
    "x": "ilorî bîyayene",
    "y": " b. helasorî bîyene"
  },
  {
    "x": "ilorîyî (m)",
    "y": " b. helasorîyîye (m)"
  },
  {
    "x": "ilxamur (n)",
    "y": " b. exlamur (n)"
  },
  {
    "x": "im- (pr)",
    "y": " b. em- (pr)"
  },
  {
    "x": "imser",
    "y": " b. emser"
  },
  {
    "x": "imşo",
    "y": " b. emşo"
  },
  {
    "x": "imbar (n)",
    "y": " b. embar (n)"
  },
  {
    "x": "imbarik",
    "y": " b. bimbarek, -e"
  },
  {
    "x": "imbarkên",
    "y": " b. bimbarek, -e"
  },
  {
    "x": "imaz, -i",
    "y": " b. embaz, -e"
  },
  {
    "x": "imazey (m)",
    "y": " b. embazîye (m) "
  },
  {
    "x": "imbaz, -i",
    "y": " b. embaz, -e"
  },
  {
    "x": "imbazey (m)",
    "y": " b. embazîye (m) "
  },
  {
    "x": "imbazê (m)",
    "y": " b. embazîye (m) "
  },
  {
    "x": "imbiryan, -i",
    "y": " b. embiryan, -e"
  },
  {
    "x": "imbiryanîni (m)",
    "y": " b. embiryanîne (m)"
  },
  {
    "x": "imbrîyan, -i",
    "y": " b. embiryan, -e"
  },
  {
    "x": "imbrîyanîni (m)",
    "y": " b. embiryanîne (m)"
  },
  {
    "x": "imeyîş (n)",
    "y": " b. ameyîş (n)"
  },
  {
    "x": "imi (m)",
    "y": " b. eme (m)"
  },
  {
    "x": "imin (n)",
    "y": " b. emin (n)"
  },
  {
    "x": "iminon (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "imir (n)",
    "y": " emr-I (n)"
  },
  {
    "x": "imiş",
    "y": " b. emşo"
  },
  {
    "x": "imiza (n)",
    "y": " b. emiza (n)"
  },
  {
    "x": "imnan (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "imnana (m)",
    "y": " b. hamnana (m)"
  },
  {
    "x": "imnane (n)",
    "y": " b. hamnane (n)"
  },
  {
    "x": "imnanên, -e",
    "y": " b. hamnanên, -e"
  },
  {
    "x": "imnanîn, -e",
    "y": " b. hamnanên, -e"
  },
  {
    "x": "imnanîye (m)",
    "y": " b. hamnanîye (m)"
  },
  {
    "x": "imno (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "imnon (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "imnûn (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "imr (n)",
    "y": " b. emr-I (n)"
  },
  {
    "x": "imsar",
    "y": " b. emser "
  },
  {
    "x": "imsarr",
    "y": " b. emser "
  },
  {
    "x": "imser",
    "y": " b. emser"
  },
  {
    "x": "imş",
    "y": " b. emşo"
  },
  {
    "x": "imşo",
    "y": " b. emşo"
  },
  {
    "x": "imşû",
    "y": " emşo"
  },
  {
    "x": "imza (n)",
    "y": " b. emiza (n)"
  },
  {
    "x": "in-I (n, z)",
    "y": " b. no-I (n, z)"
  },
  {
    "x": "inî (n, z)",
    "y": " b. ney-II (n, z)"
  },
  {
    "x": "in-II (n, s)",
    "y": " 1)b. no-II (n, s)\r\n~2)b. nê-IV (n, s)"
  },
  {
    "x": "in-III (zh, s)",
    "y": " b. nê-II (zh, s)"
  },
  {
    "x": "...in/-ine-I (sf)",
    "y": " “-lı”/“-li” anlamı veren bir sonek"
  },
  {
    "x": "esteyin, -e",
    "y": " kemikli"
  },
  {
    "x": "kateyin, -e",
    "y": " kemikli"
  },
  {
    "x": "solin, -e",
    "y": " tuzlu"
  },
  {
    "x": "...in-II (sf)",
    "y": " b. -ene (sf)"
  },
  {
    "x": "ina-I (m, z)",
    "y": " 1)b. na-I (m, z)\r\n~2)b. naye (m, z)"
  },
  {
    "x": "inay (m, z)",
    "y": " b. naye (m, z) "
  },
  {
    "x": "ina-II (m, s)",
    "y": " b. na-II (m, s)"
  },
  {
    "x": "ina ray",
    "y": " b. na rey"
  },
  {
    "x": "ina rey",
    "y": " b. na rey"
  },
  {
    "x": "ina rê",
    "y": " b. na rey "
  },
  {
    "x": "ina-III",
    "y": " b. wina"
  },
  {
    "x": "inahawa",
    "y": " 1)böyle\r\n~2)böylece, böylecene, böylelikle"
  },
  {
    "x": "inaheta",
    "y": " b. nata"
  },
  {
    "x": "inahewa",
    "y": " b. inahawa"
  },
  {
    "x": "inaray",
    "y": " b. nara"
  },
  {
    "x": "inarey",
    "y": " b. nara"
  },
  {
    "x": "inasar",
    "y": " böylesi"
  },
  {
    "x": "inasar kar",
    "y": " böylesi iş"
  },
  {
    "x": "inasarên, -e",
    "y": " böylesi, böylesine"
  },
  {
    "x": "karo inasarên (n)",
    "y": " böylesi iş"
  },
  {
    "x": "inasarêni (m)",
    "y": " b. inasarêne (m)"
  },
  {
    "x": "inawa",
    "y": " b. inahawa"
  },
  {
    "x": "inay (m, z)",
    "y": " b. naye (m, z) "
  },
  {
    "x": "inayin",
    "y": " b. inasar"
  },
  {
    "x": "inbaz",
    "y": " b. embaz, -e"
  },
  {
    "x": "inbiryan, -i",
    "y": " b. embiryan, -e"
  },
  {
    "x": "inbiryanîni (m)",
    "y": " b. embiryanîne (m)"
  },
  {
    "x": "inca (n)",
    "y": " b. noca (n)"
  },
  {
    "x": "incase (m)",
    "y": " b. încase (m)"
  },
  {
    "x": "incasêre (m)",
    "y": " b. încasêre (m)"
  },
  {
    "x": "ince (m)",
    "y": " b. emcinî (m)"
  },
  {
    "x": "inci (m)",
    "y": " b. emcinî (m)"
  },
  {
    "x": "incîli (m)",
    "y": " b. încîre (m), hêjîre (m)"
  },
  {
    "x": "incîmîn (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "inda",
    "y": " b. hendê"
  },
  {
    "x": "indasay",
    "y": " b. hende"
  },
  {
    "x": "inday",
    "y": " b. hende"
  },
  {
    "x": "indê",
    "y": " b. hendê"
  },
  {
    "x": "indhey",
    "y": " b. hende"
  },
  {
    "x": "indi",
    "y": " b. hende"
  },
  {
    "x": "indihey",
    "y": " b. hende"
  },
  {
    "x": "indiheyra",
    "y": " b. hende"
  },
  {
    "x": "ine (zr)",
    "y": " b. enî-V (zr)"
  },
  {
    "x": "...ine (sf)",
    "y": " b. -ene (sf)"
  },
  {
    "x": "inewa",
    "y": " b. inahawa"
  },
  {
    "x": "iney-I (m, z)",
    "y": " b. naye (m, z) "
  },
  {
    "x": "iney-II (n, z)",
    "y": " b. ney-II (n, z)"
  },
  {
    "x": "inê-I (m, z)",
    "y": " b. naye (m, z)"
  },
  {
    "x": "inê-II (n, z)",
    "y": " b. ney-II (n, z)"
  },
  {
    "x": "inê-III (zh, z)",
    "y": " b. nê-I (zh, z)"
  },
  {
    "x": "inê-IV (zh, s)",
    "y": " b. nê-II (zh, s)"
  },
  {
    "x": "inêni (zh, z)",
    "y": " b. nînan (zh, z) "
  },
  {
    "x": "ingile (n)",
    "y": " b. hingile-I (n)"
  },
  {
    "x": "ingilisîyayene",
    "y": " b. engilisîyayene"
  },
  {
    "x": "ingilisîyayîş (n)",
    "y": " b. engilisîyayîş (n)"
  },
  {
    "x": "ingilisnayene",
    "y": " b. engilisnayene"
  },
  {
    "x": "ingilisnayîş (n)",
    "y": " b. engilisnayîş (n)"
  },
  {
    "x": "ingiliz (n)",
    "y": " b. îngiliz (n)"
  },
  {
    "x": "ingimên (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "ingişt (m)",
    "y": " b. engişte (m)"
  },
  {
    "x": "ingiştaxwipanayîş (n)",
    "y": " b. giştaxopanayîş (n)"
  },
  {
    "x": "ingişti (m)",
    "y": " b. engişte (m) "
  },
  {
    "x": "ingişta malêvi (m)",
    "y": " b. engişta malêbe (m)"
  },
  {
    "x": "ingişta xwi pa nayene",
    "y": " b. gişta xo pa nayene"
  },
  {
    "x": "ingişti pa nayene",
    "y": " b. gişte pa nayene"
  },
  {
    "x": "ingişti ser nayene",
    "y": " b. gişte ser nayene"
  },
  {
    "x": "ingiştedekutiş (n)",
    "y": " b. engiştedekutiş (n)"
  },
  {
    "x": "ingiştidekutiş (n)",
    "y": " b. engiştedekutiş (n)"
  },
  {
    "x": "ingiştipanayîş (n)",
    "y": " b. giştepanayîş (n)"
  },
  {
    "x": "ingiştiri (n)",
    "y": " b. engiştane (n)"
  },
  {
    "x": "ingiştri (n)",
    "y": " b. engiştane (n)"
  },
  {
    "x": "ingîşt (m)",
    "y": " b. engişte (m)"
  },
  {
    "x": "ingîşti (m)",
    "y": " b. engişte (m)"
  },
  {
    "x": "ingîştey dest (m)",
    "y": " b. engişta destî (m)"
  },
  {
    "x": "ingîştey ling (m)",
    "y": " b. engişta linge (m)"
  },
  {
    "x": "ingîştey şadeti (m)",
    "y": " b. engişta şehadetî (m)"
  },
  {
    "x": "ingîştona (m)",
    "y": " b. engiştane (n)"
  },
  {
    "x": "ingle (n)",
    "y": " b. hingile-I (n)"
  },
  {
    "x": "inguri (m)",
    "y": " b. engure (m)"
  },
  {
    "x": "inhawa",
    "y": " b. inahawa"
  },
  {
    "x": "inhewa",
    "y": " b. inahawa"
  },
  {
    "x": "ini-I (n, z)",
    "y": " 1)b. no-I (n, z)\r\n~2)b. ney-II (n, z)"
  },
  {
    "x": "ini-II (n, s)",
    "y": " b. no-II (n, s)"
  },
  {
    "x": "inika",
    "y": " b. nika"
  },
  {
    "x": "inikay",
    "y": " b. nika"
  },
  {
    "x": "inî-I (n, z)",
    "y": " b. ney-II (n, z)"
  },
  {
    "x": "inî-II (n, s)",
    "y": " b. nê-IV (n, s) "
  },
  {
    "x": "inî-III (zh, z)",
    "y": " b. nê-I (zh, z)"
  },
  {
    "x": "inî-IV (zh, s)",
    "y": " b. nê-II (zh, s)"
  },
  {
    "x": "inîn (zh, z)",
    "y": " b. nînan (zh, z) "
  },
  {
    "x": "inîna (zh, z)",
    "y": " b. nînan (zh, z) "
  },
  {
    "x": "inîyan (zh, z)",
    "y": " b. nînan (zh, z) "
  },
  {
    "x": "inîyon (zh, z)",
    "y": " b. nînan (zh, z) "
  },
  {
    "x": "inîyûn (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "inka",
    "y": " b. nika "
  },
  {
    "x": "inkay",
    "y": " b. nika"
  },
  {
    "x": "inkayin, -i",
    "y": " b. nikayin, -e"
  },
  {
    "x": "inkey",
    "y": " b. nika"
  },
  {
    "x": "inkeyin, -i",
    "y": " b. nikayin, -e"
  },
  {
    "x": "inkê",
    "y": " b. nika"
  },
  {
    "x": "inkêyin, -i",
    "y": " b. nikayin, -e"
  },
  {
    "x": "ino-I (n, z)",
    "y": " b. no-I (n, z) "
  },
  {
    "x": "ino-II (n, s)",
    "y": " b. no-II (n, s)"
  },
  {
    "x": "ino-III (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "inohawa",
    "y": " b. inahawa"
  },
  {
    "x": "inohewa",
    "y": " b. inahawa"
  },
  {
    "x": "inon (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "inû-I (n, z)",
    "y": " b. no-I (n, z) "
  },
  {
    "x": "inû-II (n, s)",
    "y": " b. no-II (n, s)"
  },
  {
    "x": "inûn (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "ir (pd)",
    "y": " 1)b. rê-III (pd)\r\n~2)b. ro-VII (pd)"
  },
  {
    "x": "irayene",
    "y": " kükremek"
  },
  {
    "x": "irayîne",
    "y": " b. irayene"
  },
  {
    "x": "irayîş (n)",
    "y": " kükreme"
  },
  {
    "x": "ire-ir kerdene",
    "y": " kükremek"
  },
  {
    "x": "ire-ir kerdiş (n)",
    "y": " kükreme"
  },
  {
    "x": "irim (n)",
    "y": " b. rim (n)"
  },
  {
    "x": "iskence (n)",
    "y": " b. îskence (n)"
  },
  {
    "x": "iskerey (m)",
    "y": " b. eskerî (m), eskerîye (m)"
  },
  {
    "x": "iskura (m)",
    "y": " b. uskura (m)"
  },
  {
    "x": "ismar",
    "y": " b. emser"
  },
  {
    "x": "ismer",
    "y": " b. emser"
  },
  {
    "x": "ismo",
    "y": " b. emşo"
  },
  {
    "x": "isnaw (m)",
    "y": " b. asnawe (m)"
  },
  {
    "x": "isnawer, -e",
    "y": " b. asnawber, -e"
  },
  {
    "x": "isnawi (m)",
    "y": " b. asnawe (m)"
  },
  {
    "x": "isnawi kerdene",
    "y": " b. asnawe kerdene"
  },
  {
    "x": "isnawikerdiş (n)",
    "y": " b. asnawekerdiş (n)"
  },
  {
    "x": "isnawkerdiş (n)",
    "y": " b. asnawekerdiş (n)"
  },
  {
    "x": "ispar (n)",
    "y": " b. espar (n)"
  },
  {
    "x": "ispenax (n)",
    "y": " b. îspenax (n)"
  },
  {
    "x": "ispide (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "ispidin, -e",
    "y": " b. espijin, -e"
  },
  {
    "x": "ispij (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "ispiji (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "ispijin, -i",
    "y": " b. espijin, -e"
  },
  {
    "x": "ispiz (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "ispizin, -i",
    "y": " b. espijin, -e"
  },
  {
    "x": "israr (n)",
    "y": " ısrar"
  },
  {
    "x": "israr kerdene",
    "y": " ısrar etmek"
  },
  {
    "x": "israrkerdiş (n)",
    "y": " ısrar etme"
  },
  {
    "x": "istamî (m)",
    "y": "b. astamî (m) "
  },
  {
    "x": "istanik (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "istaniki (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "istare (n)",
    "y": " b. estare (n)"
  },
  {
    "x": "istere (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "istereyin",
    "y": " b. estiriyin, -e"
  },
  {
    "x": "istewr (m)",
    "y": " b. estewre (m) "
  },
  {
    "x": "istîrî (n)",
    "y": " b. estare (n)"
  },
  {
    "x": "istomî (m)",
    "y": " b. astamî (m) "
  },
  {
    "x": "istonik (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "istoniki (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "istor, -i",
    "y": " b. estor, -e"
  },
  {
    "x": "istre (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "istreyin",
    "y": " b. estiriyin, -e"
  },
  {
    "x": "istrî (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "istrîyin",
    "y": " b. estiriyin, -e"
  },
  {
    "x": "istun (m)",
    "y": " b. estune (m)"
  },
  {
    "x": "istuni (m)",
    "y": " b. estune (m)"
  },
  {
    "x": "istûnik (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "istûniki (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "işkayîş (n)",
    "y": " b. eşkayîş (n)"
  },
  {
    "x": "işkence (n)",
    "y": " b. îşkence (n)"
  },
  {
    "x": "işkij (m)",
    "y": " b. eskije (m)"
  },
  {
    "x": "işkiji (m)",
    "y": " b. eskije (m)"
  },
  {
    "x": "işm",
    "y": " b. emşo"
  },
  {
    "x": "işmo",
    "y": " b. emşo"
  },
  {
    "x": "işna (m)",
    "y": " b. wişna (m)"
  },
  {
    "x": "işnayêri (m)",
    "y": " b. wişnayêre (m)"
  },
  {
    "x": "işpij (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "işpiji (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "işpijin, -i",
    "y": " b. espijin, -e"
  },
  {
    "x": "iştirî (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "ita (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "itîya (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "itka (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "itya (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "ixlamur (n)",
    "y": " b. exlamur (n)"
  },
  {
    "x": "ixtîyar, -e",
    "y": " b. extîyar, -e"
  },
  {
    "x": "ixtîyarey (m)",
    "y": " b. extîyarîye (m)"
  },
  {
    "x": "ixtîyarê (m)",
    "y": " b. extîyarî (m)"
  },
  {
    "x": "iz (zr)",
    "y": " b. zî (zr)"
  },
  {
    "x": "...iz/ize (sf)",
    "y": " b. -ij/-ije (sf)"
  },
  {
    "x": "izdîha (n)",
    "y": " b. ejdeha (n)"
  },
  {
    "x": "izdîya (n)",
    "y": " b. ejdeha (n)"
  },
  {
    "x": "izxara (m)",
    "y": " ızgara"
  },
  {
    "x": "ÎÎÎ"
  },
  {
    "x": "î-I (zh, z)",
    "y": " b. ê-IV (zh, z)"
  },
  {
    "x": "î-II (zh, s)",
    "y": " b. ê-II (zh, s)"
  },
  {
    "x": "î-III (n, z)",
    "y": " b. ey-I (n, z)"
  },
  {
    "x": "î-IV (n, s)",
    "y": " b. ê-II (n, s)"
  },
  {
    "x": "î-V (zr)",
    "y": " b. e-I (zr), ya-I (zr)"
  },
  {
    "x": "î- (pr)",
    "y": " “Bu” anlamı veren bir önek."
  },
  {
    "x": "îro",
    "y": " b. ewro"
  },
  {
    "x": "...î (sf)",
    "y": " Türkçedeki “-lık”/“-lik” sonekinin karşılığı olan bir sonek"
  },
  {
    "x": "giranî (m)",
    "y": " ağırlık"
  },
  {
    "x": "îblîs (n)",
    "y": " şeytan, iblis"
  },
  {
    "x": "îblîsey (m)",
    "y": " b. îblîsî (m), îblîsîye (m)"
  },
  {
    "x": "îblîsê (m)",
    "y": " b. îblîsî (m), îblîsîye (m)"
  },
  {
    "x": "îblîsênî (m)",
    "y": " şeytanlık, iblislik"
  },
  {
    "x": "îblîsî (m)",
    "y": " şeytanlık, iblislik"
  },
  {
    "x": "îblîsîye (m)",
    "y": " şeytanlık, iblislik"
  },
  {
    "x": "îbne (n)",
    "y": " ibne (argo), götlek (argo), oğlan (edilgen eşcinsel erkek) (argo)"
  },
  {
    "x": "îbneyî (m)",
    "y": " ibnelik (argo), götleklik (argo), oğlanlık (argo)"
  },
  {
    "x": "îbneyîye (m)",
    "y": " ibnelik (argo), götleklik (argo), oğlanlık (argo)"
  },
  {
    "x": "îbriq (n)",
    "y": " ibrik"
  },
  {
    "x": "îbrişim (n)",
    "y": " b. birîsim (n)"
  },
  {
    "x": "îbrişimên, -e",
    "y": " b. birîsimên, -e"
  },
  {
    "x": "îbrişimin, -e",
    "y": " b. birîsimin, -e"
  },
  {
    "x": "îbtîdayî, -ye",
    "y": " barbar"
  },
  {
    "x": "îbtîdayîyîye",
    "y": " barbarlık"
  },
  {
    "x": "îca (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "îcab (n)",
    "y": " icap"
  },
  {
    "x": "îcad (n)",
    "y": " icat"
  },
  {
    "x": "îcad bîyene",
    "y": " bulunmak"
  },
  {
    "x": "îcad kerdene",
    "y": " bulmak, icat etmek"
  },
  {
    "x": "îcadkerdiş (n)",
    "y": " bulma, icat etme"
  },
  {
    "x": "îcadbîyayîş (n)",
    "y": " bulunma"
  },
  {
    "x": "îcav (n)",
    "y": " b. îcab (n)"
  },
  {
    "x": "îcdûn (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "îcra (m) (huq.)",
    "y": " icra"
  },
  {
    "x": "îcraya zorkanî (m)",
    "y": " cebri icra"
  },
  {
    "x": "îçilik (n)",
    "y": " b. îşlig (n)"
  },
  {
    "x": "îçlig (n)",
    "y": " b. îşlig (n)"
  },
  {
    "x": "îçlik (n)",
    "y": " b. îşlig (n)"
  },
  {
    "x": "îçtîhad (n)",
    "y": " içtihat"
  },
  {
    "x": "îdam bîyene",
    "y": " asılmak (idam edilmek)"
  },
  {
    "x": "îdam kerdene",
    "y": " asmak (idam etmek)"
  },
  {
    "x": "îdambîyayîş (n)",
    "y": " asılma (idam edilme)"
  },
  {
    "x": "îdamkerda (m)",
    "y": " asılı"
  },
  {
    "x": "îdamkerde (n)",
    "y": " asılı"
  },
  {
    "x": "îdamkerdiş (n)",
    "y": " asma (idam etme)"
  },
  {
    "x": "îdamkerdîye (m)",
    "y": " asılı"
  },
  {
    "x": "îdare (n)",
    "y": " yönetim, idare"
  },
  {
    "x": "îdarey xwi kerdene",
    "y": " b. îdareyê xo kerdene"
  },
  {
    "x": "îdareyê afate (n)",
    "y": " afet yönetimi"
  },
  {
    "x": "îdareyê awe û kanalîzasyonî",
    "y": " su ve kanalizasyon idaresi"
  },
  {
    "x": "îdareyê çerxê projeyî (n)",
    "y": " proje döngüsü yönetimi"
  },
  {
    "x": "îdareyê çimeyanê merdiman (n)",
    "y": " insan kaynakları yönetimi"
  },
  {
    "x": "îdareyê çorşmeyî (n)",
    "y": " çevre yönetimi"
  },
  {
    "x": "îdareyê demê projeyî (n)",
    "y": " proje zaman yönetimi"
  },
  {
    "x": "îdareyê fetilê projeyî (n)",
    "y": " proje döngüsü yönetimi"
  },
  {
    "x": "îdareyê doruverî (n)",
    "y": " çevre yönetimi"
  },
  {
    "x": "îdareyê gendawe (n)",
    "y": " atık su yönetimi"
  },
  {
    "x": "îdareyê herêmî (zh)",
    "y": " yerel yönetimler, mahalli idareler"
  },
  {
    "x": "îdareyê karîyerî (n)",
    "y": " kariyer yönetimi"
  },
  {
    "x": "îdareyê komunîkasyonê projeyî (n)",
    "y": " proje iletişim yönetimi"
  },
  {
    "x": "îdareyê kontratî (n)",
    "y": " sözleşme yönetimi"
  },
  {
    "x": "îdareyê malbîyayîşê projeyî (n)",
    "y": " proje maliyet yönetimi"
  },
  {
    "x": "îdareyê mehelî (zh)",
    "y": " yerel yönetimler, mahalli idareler"
  },
  {
    "x": "îdareyê muqaweleyî (n)",
    "y": " sözleşme yönetimi"
  },
  {
    "x": "îdareyê projeyê hemdemî (n)",
    "y": " modern proje yönetimi"
  },
  {
    "x": "îdareyê projeyê modernî (n)",
    "y": " modern proje yönetimi"
  },
  {
    "x": "îdareyê projeyî (n)",
    "y": " proje yönetimi"
  },
  {
    "x": "îdareyê rîskê projeyî (n)",
    "y": " proje risk yönetimi"
  },
  {
    "x": "îdareyê senînîya kome",
    "y": " toplam kalite yönetimi"
  },
  {
    "x": "îdareyê senînîya projeyî (n)",
    "y": " proje kalite yönetimi"
  },
  {
    "x": "îdareyê şumûlê projeyî (n)",
    "y": " proje kapsam yönetimi"
  },
  {
    "x": "îdareyê xo kerdene",
    "y": " geçinmek"
  },
  {
    "x": "îdareyê zemanê projeyî (n)",
    "y": " proje zaman yönetimi"
  },
  {
    "x": "îdareyo beşdar (n)",
    "y": " katılımcı yönetim"
  },
  {
    "x": "îdareyo orfî (n)",
    "y": " sıkıyönetim, örfi idare"
  },
  {
    "x": "îdareyo tewrbîyayox (n) ",
    "y": " katılımcı yönetim"
  },
  {
    "x": "îdarê xu kerdene",
    "y": " b. îdareyê xo kerdene"
  },
  {
    "x": "îdarekar, -e",
    "y": " yönetici, idareci"
  },
  {
    "x": "îdarekara apartmanî (m)",
    "y": " apartman yöneticisi"
  },
  {
    "x": "îdarekara berpirsîyare (m)",
    "y": " sorumlu yönetici"
  },
  {
    "x": "îdarekara karkerdîye (m)",
    "y": " işlevsel yönetici"
  },
  {
    "x": "îdarekara projeyî (m)",
    "y": " proje yöneticisi"
  },
  {
    "x": "îdarekara torre (m) (TE)",
    "y": " ağ yöneticisi"
  },
  {
    "x": "îdarekara sîstemî (m)",
    "y": " sistem yöneticisi"
  },
  {
    "x": "îdarekarê apartmanî (n)",
    "y": " apartman yöneticisi"
  },
  {
    "x": "îdarekarê projeyî (n)",
    "y": " proje yöneticisi"
  },
  {
    "x": "îdarekarê torre (n) (TE)",
    "y": " ağ yöneticisi "
  },
  {
    "x": "îdarekarê sîstemî (n)",
    "y": " sistem yöneticisi"
  },
  {
    "x": "îdarekaro berpirsîyar (n)",
    "y": " sorumlu yönetici"
  },
  {
    "x": "îdarekaro karkerdî (n)",
    "y": " işlevsel yönetici"
  },
  {
    "x": "îdarekerdox, -e",
    "y": " yöneten"
  },
  {
    "x": "îdareyêxokerdiş (n)",
    "y": " geçinme"
  },
  {
    "x": "îdareyxwikerdiş (n)",
    "y": " b. îdareyêxokerdiş (n)"
  },
  {
    "x": "îdarêxukerdiş (n)",
    "y": " b. îdareyêxokerdiş (n)"
  },
  {
    "x": "îdarî, -ye",
    "y": " idari, yönetimsel"
  },
  {
    "x": "îde",
    "y": " b. êdî"
  },
  {
    "x": "îdefîks (n)",
    "y": " fikrisabit, idefiks, saplantı"
  },
  {
    "x": "îdefîksî (zh)",
    "y": " saplantılar"
  },
  {
    "x": "îdî (m)",
    "y": " bayram"
  },
  {
    "x": "îdman (n)",
    "y": " idman"
  },
  {
    "x": "îdom bîyayîne",
    "y": " b. îdam bîyene"
  },
  {
    "x": "îdom kerdene",
    "y": " b. îdam kerdene"
  },
  {
    "x": "îdombîyayîş (n)",
    "y": " b. îdambîyayîş (n)"
  },
  {
    "x": "îdomkerdiş (n)",
    "y": " b. îdamkerdiş (n)"
  },
  {
    "x": "îdrak (n)",
    "y": " algı"
  },
  {
    "x": "îdrak kerdene",
    "y": " algılamak"
  },
  {
    "x": "îdrakkerdiş (n)",
    "y": " algılama"
  },
  {
    "x": "îdraq (n)",
    "y": " b. îdrak (n)"
  },
  {
    "x": "îdûm bîyayîne",
    "y": " b. îdam bîyene"
  },
  {
    "x": "îdûm kerdene",
    "y": " b. îdam kerdene"
  },
  {
    "x": "îdûmbîyayîş (n)",
    "y": " b. îdambîyayîş (n)"
  },
  {
    "x": "îdûmkerdiş (n)",
    "y": " b. îdamkerdiş (n)"
  },
  {
    "x": "îdxal (n)",
    "y": " ithal"
  },
  {
    "x": "îdxalat (n)",
    "y": " dışalım, ithalat"
  },
  {
    "x": "îdxalatkar, -e",
    "y": " dışalımcı, ithalatçı"
  },
  {
    "x": "îdyom (n)",
    "y": " deyim, tabir"
  },
  {
    "x": "îfada (m)",
    "y": " b. îfade (n)"
  },
  {
    "x": "îfade (n)",
    "y": " ifade, anlatım"
  },
  {
    "x": "îfade dayene",
    "y": " ifade vermek "
  },
  {
    "x": "îfade girewtene",
    "y": " ifade almak, sorgulamak"
  },
  {
    "x": "îfadegirewtiş (n)",
    "y": " sorgu, sorgulama"
  },
  {
    "x": "îfadekerdiş (n)",
    "y": " anlatım"
  },
  {
    "x": "îfadi (n)",
    "y": " b. îfade (n)"
  },
  {
    "x": "îflas (n)",
    "y": " iflas"
  },
  {
    "x": "îfraz (n)",
    "y": " ifraz"
  },
  {
    "x": "îhale (n)",
    "y": " ihale"
  },
  {
    "x": "îhdas (n) ",
    "y": " ihdas"
  },
  {
    "x": "îhdas kerdene",
    "y": " ihdas etmek"
  },
  {
    "x": "îhdasê kadroyî",
    "y": " kadro ihdası"
  },
  {
    "x": "îhmal (n)",
    "y": " ihmal"
  },
  {
    "x": "îhna",
    "y": " b. wina"
  },
  {
    "x": "îhnû (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "îhtîbar ... ardene",
    "y": " b. îtîbar bi ... ardene"
  },
  {
    "x": "îhtîbar bi ... ardene",
    "y": " b. îtîbar bi ... ardene"
  },
  {
    "x": "îhtîbar bi ... bîyayene",
    "y": " b. îtîbar bi ... bîyene"
  },
  {
    "x": "îhtîbar bi ... kerdene",
    "y": " b. îtîbar bi ... kerdene"
  },
  {
    "x": "îhtîram (n)",
    "y": " saygı, ihtiram, hürmet"
  },
  {
    "x": "îhtîyac (n)",
    "y": " ihtiyaç, gereksinim"
  },
  {
    "x": "îhtîyacê oksîjena kîmyayîye (m)",
    "y": " kimyasal oksijen ihtiyacı"
  },
  {
    "x": "îhtîyatî, -ye",
    "y": " ihtiyati"
  },
  {
    "x": "îhtîwakerdiş (n)",
    "y": " kapsama"
  },
  {
    "x": "îja (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "îjdîye (n)",
    "y": " b. ejdeha (n)"
  },
  {
    "x": "îk (zr)",
    "y": " b. zî (zr)"
  },
  {
    "x": "îkê",
    "y": " b. nika"
  },
  {
    "x": "îki",
    "y": " b. zî (zr)"
  },
  {
    "x": "îklîm (n)",
    "y": " iklim"
  },
  {
    "x": "îkmal (n)",
    "y": " bütünleme, ikmal"
  },
  {
    "x": "îkmal kerdene",
    "y": " bütünlemek, ikmal etmek, tamamlamak"
  },
  {
    "x": "îkmalkerdiş (n)",
    "y": " bütünleme, ikmal etme, tamamlama"
  },
  {
    "x": "îkra (n)",
    "y": " b. îkrah (n)"
  },
  {
    "x": "îkra ci ra ameyene",
    "y": " b. îkrah ci ra ameyene"
  },
  {
    "x": "îkrah (n)",
    "y": " nefret, ikrah, tiksinme, tiksinti"
  },
  {
    "x": "îkrah ci ra ameyene",
    "y": " nefret etmek, ikrah etmek, tiksinmek"
  },
  {
    "x": "îkrahciraameyîş (n)",
    "y": " nefret etme"
  },
  {
    "x": "îkram (n)",
    "y": " ikram"
  },
  {
    "x": "îkram kerdene",
    "y": " ikram etmek"
  },
  {
    "x": "îkramîya (m)",
    "y": " b. îkramîye"
  },
  {
    "x": "îkramîye (m)",
    "y": " ikramiye"
  },
  {
    "x": "îkramîyeya teqawutîye (m)",
    "y": " emeklilik ikramiyesi"
  },
  {
    "x": "îkramkerdiş (n)",
    "y": " ikram etme"
  },
  {
    "x": "îkreh (n)",
    "y": " b. îkrah (n)"
  },
  {
    "x": "îlac (n)",
    "y": " ilaç"
  },
  {
    "x": "îlah (n)",
    "y": " ilah, tanrı"
  },
  {
    "x": "îlaha (m)",
    "y": " b. ilahe"
  },
  {
    "x": "îlahe (m)",
    "y": " ilahe, tanrıça"
  },
  {
    "x": "îlahî, -ye-I",
    "y": " ilahi, Tanrısal"
  },
  {
    "x": "îlahî-II",
    "y": " b. îlle"
  },
  {
    "x": "îlahîyat (n)",
    "y": " ilahiyat, teoloji, Tanrıbilim"
  },
  {
    "x": "îlahîyatnas, -e",
    "y": " ilahiyatçı, teolog, Tanrıbilimci"
  },
  {
    "x": "îlaj (n)",
    "y": " b. îlac (n)"
  },
  {
    "x": "îlam-I (n) (huq.)",
    "y": " ilam"
  },
  {
    "x": "îlamê werasetî (n)",
    "y": " veraset ilamı"
  },
  {
    "x": "îlam-II",
    "y": " b. îlle"
  },
  {
    "x": "îlan (n)",
    "y": " duyuru, ilan"
  },
  {
    "x": "îlanê projeyî (n)",
    "y": " proje duyurusu, proje ilanı"
  },
  {
    "x": "îlankerdiş (n)",
    "y": " ilan etme, askıya çıkarma"
  },
  {
    "x": "îlancuxi (m)",
    "y": " b. êlancuxe (m)"
  },
  {
    "x": "îlaqe (n)",
    "y": " b. eleqe (n)"
  },
  {
    "x": "îlawe (n)",
    "y": " ek, ilave"
  },
  {
    "x": "îlawe kerdene",
    "y": " eklemek, katmak"
  },
  {
    "x": "îlawekerda (m)",
    "y": " katma"
  },
  {
    "x": "îlawekerde (n)",
    "y": " katma"
  },
  {
    "x": "îlawekerdiş (n)",
    "y": " ekleme, katma"
  },
  {
    "x": "îlawekerdîye (m)",
    "y": " katma"
  },
  {
    "x": "îlayî",
    "y": " b. îlle"
  },
  {
    "x": "îlaz (n)",
    "y": " b. îlac (n)"
  },
  {
    "x": "îleh",
    "y": " b. îlle"
  },
  {
    "x": "îlim (n)",
    "y": " bilim"
  },
  {
    "x": "îlimê exlaqî (n)",
    "y": " ahlak bilimi, ahlakiyat, ilmiahlak"
  },
  {
    "x": "îlimê fenî (n)",
    "y": " fen bilgisi"
  },
  {
    "x": "îlimê heyatî (n)",
    "y": " hayat bilgisi"
  },
  {
    "x": "îlimê sosyalî (zh)",
    "y": " sosyal bilgiler"
  },
  {
    "x": "îlimî ra teber",
    "y": " bilim dışı"
  },
  {
    "x": "îlimdar, -e",
    "y": " bilgin, âlim"
  },
  {
    "x": "îlimdarênî (m)",
    "y": " bilginlik, âlimlik"
  },
  {
    "x": "îlimdarî (m)",
    "y": " bilginlik, âlimlik"
  },
  {
    "x": "îlimdarîye (m)",
    "y": " bilginlik, âlimlik"
  },
  {
    "x": "Îlîç (n)",
    "y": " İliç"
  },
  {
    "x": "îlla",
    "y": " b. îlle"
  },
  {
    "x": "îllahî",
    "y": " b. îlle"
  },
  {
    "x": "îllamî",
    "y": " b. îlle"
  },
  {
    "x": "îllayî",
    "y": " b. îlle"
  },
  {
    "x": "îllayîm",
    "y": " b. îlle"
  },
  {
    "x": "îlle",
    "y": " özellikle, başta"
  },
  {
    "x": "îllegal, -e",
    "y": " 1) yasadışı\r\n~2)(mecazi) yeraltı"
  },
  {
    "x": "îlleh",
    "y": " b. îlle"
  },
  {
    "x": "îllehî",
    "y": " b. îlle"
  },
  {
    "x": "îllehîm",
    "y": " b. îlle"
  },
  {
    "x": "îllem",
    "y": " b. îlle"
  },
  {
    "x": "îlm (n)",
    "y": " b. îlim (n)"
  },
  {
    "x": "îlmdar, -e",
    "y": " b. îlimdar, -e"
  },
  {
    "x": "îlmdarî (m)",
    "y": " b. îlimdarî (m), îlimdarîye (m)"
  },
  {
    "x": "îlmî, -ye",
    "y": " bilimsel"
  },
  {
    "x": "îlmîtî (m)",
    "y": " bilimsellik"
  },
  {
    "x": "îlmîyîye (m)",
    "y": " bilimsellik"
  },
  {
    "x": "îltihab (n)",
    "y": " b. îltîhab (n)"
  },
  {
    "x": "îltîca (m)",
    "y": " iltica, sığınma"
  },
  {
    "x": "îltîca kerdene",
    "y": " iltica etmek, sığınmak"
  },
  {
    "x": "îltîhab (n)",
    "y": " iltihap, yangı"
  },
  {
    "x": "îltîhabin, -e",
    "y": " iltihaplı, yangılı "
  },
  {
    "x": "îluzyonîst, -e",
    "y": " gözbağcı, illüzyonist"
  },
  {
    "x": "îlxat",
    "y": " b. êrxat, -e"
  },
  {
    "x": "îma (n)",
    "y": " b. îman (n)"
  },
  {
    "x": "îma bîyayene",
    "y": " b. înan bîyene"
  },
  {
    "x": "îma kerdene",
    "y": " b. îman kerdene"
  },
  {
    "x": "îmaj (n)",
    "y": " imaj"
  },
  {
    "x": "îmakerdiş (n)",
    "y": " b. îmankerdiş (n)"
  },
  {
    "x": "îmal (n)",
    "y": " imal"
  },
  {
    "x": "îmal kerdene",
    "y": " imal etmek"
  },
  {
    "x": "îmalat (n)",
    "y": " imalat"
  },
  {
    "x": "îmalkerde (m)",
    "y": " mamul"
  },
  {
    "x": "îmalkerdiş (n)",
    "y": " imal etme"
  },
  {
    "x": "îmam (n)",
    "y": " imam"
  },
  {
    "x": "Îmam Alî",
    "y": " İmam Ali"
  },
  {
    "x": "Îmam Elî",
    "y": " İmam Ali"
  },
  {
    "x": "îman (n)",
    "y": " iman, inan, inanç, akide"
  },
  {
    "x": "îman ardene",
    "y": " inanmak"
  },
  {
    "x": "îman kerdene",
    "y": " inanmak"
  },
  {
    "x": "îmanî fek ra",
    "y": " dopdolu, ağzına kadar"
  },
  {
    "x": "îmanardiş (n)",
    "y": " inan, inanma"
  },
  {
    "x": "îmankerdiş (n)",
    "y": " inan, inanma"
  },
  {
    "x": "îmantikî ra",
    "y": " dopdolu, ağzına kadar"
  },
  {
    "x": "îmdad (n)",
    "y": " imdat, medet\r\n~Îmdad ya pîr! (İmdat ey pir!)"
  },
  {
    "x": "îmdat (n)",
    "y": " b. îmdad (n)"
  },
  {
    "x": "îmha (m)",
    "y": " imha"
  },
  {
    "x": "îmha kerdene",
    "y": " yok etmek, imha etmek"
  },
  {
    "x": "îmhakerdiş (n)",
    "y": " yok etme, imha etme"
  },
  {
    "x": "îmiş (n)",
    "y": " b. êmîş (n)"
  },
  {
    "x": "îmîsyon (n)",
    "y": " imisyon"
  },
  {
    "x": "îmîş (n)",
    "y": " b. ûmiş (n)"
  },
  {
    "x": "îmîş bîyayene",
    "y": " b. ûmiş bîyene"
  },
  {
    "x": "îmîtasyon (n)",
    "y": " taklit"
  },
  {
    "x": "îmkan (n)",
    "y": " olanak, imkân"
  },
  {
    "x": "îmkanêdo newe (n)",
    "y": " yeni bir olanak, yeni bir imkân"
  },
  {
    "x": "îmkanêko newe (n)",
    "y": " yeni bir olanak, yeni bir imkân"
  },
  {
    "x": "îmko (n)",
    "y": " b. îmkan (n) "
  },
  {
    "x": "îmkon (n)",
    "y": " b. îmkan (n) "
  },
  {
    "x": "îmkû (n)",
    "y": " b. îmkan (n) "
  },
  {
    "x": "îmkûn (n)",
    "y": " b. îmkan (n) "
  },
  {
    "x": "îmo (n)",
    "y": " b. îman"
  },
  {
    "x": "îmon (n)",
    "y": " b. îman"
  },
  {
    "x": "îmon ardene",
    "y": " b. îman ardene"
  },
  {
    "x": "îmon kerdene",
    "y": " b. îman kerdene"
  },
  {
    "x": "îmtahan (n)",
    "y": " b. îmtihan (n)"
  },
  {
    "x": "îmtan (n)",
    "y": " b. îmtihan (n)"
  },
  {
    "x": "îmtihan (n)",
    "y": " sınav, imtihan"
  },
  {
    "x": "îmtîhan (n)",
    "y": " b. îmtihan (n)"
  },
  {
    "x": "îmtîyan (n)",
    "y": " b. îmtihan (n)"
  },
  {
    "x": "îmtîyaz (n)",
    "y": " ayrıcalık, imtiyaz"
  },
  {
    "x": "îmtîyazdar, -e",
    "y": " ayrıcalıklı, imtiyazlı"
  },
  {
    "x": "îmû (n)",
    "y": " b. îman"
  },
  {
    "x": "îmûn (n)",
    "y": " b. îman"
  },
  {
    "x": "îmûn ardene",
    "y": " b. îman ardene"
  },
  {
    "x": "îmûn kerdene",
    "y": " b. îman kerdene"
  },
  {
    "x": "îmza (m)",
    "y": " imza"
  },
  {
    "x": "îmza dayene",
    "y": " imza vermek"
  },
  {
    "x": "îmza eştene",
    "y": " imza atmak"
  },
  {
    "x": "îmza kerdene",
    "y": " imzalamak"
  },
  {
    "x": "îmza vistene",
    "y": " b. îmza eştene"
  },
  {
    "x": "îmzadayîş (n)",
    "y": " imza verme"
  },
  {
    "x": "îmzaeştiş (n)",
    "y": " imza atma"
  },
  {
    "x": "îmzakerdiş (n)",
    "y": " imzalama"
  },
  {
    "x": "îmzavistiş (n)",
    "y": " b. îmzaeştiş (n)"
  },
  {
    "x": "în-I (zh, z)",
    "y": " 1)b. înan (zh, z)\r\n~2)b. nê-I (zh, z)"
  },
  {
    "x": "în-II (zh, s)",
    "y": " b. nê-II (zh, s)"
  },
  {
    "x": "în-III (n, z)",
    "y": " b. ney-II (n, z)"
  },
  {
    "x": "în-IV (n, s)",
    "y": " b. nê-IV (n, s)"
  },
  {
    "x": "în-V (zr)",
    "y": " b. enî-V (zr)"
  },
  {
    "x": "...în-I (sf)",
    "y": " b. -ên (sf), -in (sf)"
  },
  {
    "x": "...în-II (sf)",
    "y": " b. -înan (sf)"
  },
  {
    "x": "îna-I (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "îna-II",
    "y": " b. wina"
  },
  {
    "x": "...îna",
    "y": " b. -înan"
  },
  {
    "x": "îna kerdene",
    "y": " b. înan kerdene"
  },
  {
    "x": "înad-I (n)",
    "y": " inat"
  },
  {
    "x": "înad kerdene",
    "y": " inat etmek"
  },
  {
    "x": "înad, -e-II",
    "y": " inatçı, inat"
  },
  {
    "x": "înadcênî",
    "y": " b. înadçîyênî"
  },
  {
    "x": "înadçî, -ye",
    "y": " inatçı, inat"
  },
  {
    "x": "înadçîyey (m)",
    "y": " b. înadçîyî (m), înadçîyîye (m)"
  },
  {
    "x": "înadçîyê (m)",
    "y": " b. înadçîyî (m), înadçîyîye (m)"
  },
  {
    "x": "înadçîyênî",
    "y": " inatçılık"
  },
  {
    "x": "înadçîyî (m)",
    "y": " inatçılık"
  },
  {
    "x": "înadçîyîye (m)",
    "y": " inatçılık"
  },
  {
    "x": "înadey (m)",
    "y": " b. înadîye (m)"
  },
  {
    "x": "înadê (m)",
    "y": " b. înadî (m)"
  },
  {
    "x": "înadênî (m)",
    "y": " inatçılık"
  },
  {
    "x": "înadî (m)",
    "y": " inatçılık"
  },
  {
    "x": "înadîye (m)",
    "y": " inatçılık"
  },
  {
    "x": "înakerdiş (n)",
    "y": " b. înankerdiş (n)"
  },
  {
    "x": "înam bîyene",
    "y": " b. înan bîyene"
  },
  {
    "x": "înam kerdene",
    "y": " b. înan kerdene"
  },
  {
    "x": "înamkerdiş (n)",
    "y": " b. înankerdiş (n)"
  },
  {
    "x": "înan (zh, z)",
    "y": " onlar, onları"
  },
  {
    "x": "înan rê",
    "y": " onlara"
  },
  {
    "x": "înan bîyene",
    "y": " inanmak"
  },
  {
    "x": "înan kerdene",
    "y": " inanmak"
  },
  {
    "x": "...înan (sf)",
    "y": " “-gil, -giller” anlamı veren bir sonek"
  },
  {
    "x": "Hesenînan",
    "y": " Hasangil, Hasangiller"
  },
  {
    "x": "xaloyînan",
    "y": " dayıgil, dayigiller"
  },
  {
    "x": "înanbîyayîş (n)",
    "y": " inanma"
  },
  {
    "x": "înankerdiş (n)",
    "y": " inan, inanma"
  },
  {
    "x": "înat (n)",
    "y": " b. înad (n)"
  },
  {
    "x": "înat kerdene",
    "y": " b. înad kerdene"
  },
  {
    "x": "înatcî, -ye",
    "y": " b. înadçî, -ye"
  },
  {
    "x": "înatçî, -ye",
    "y": " b. înadçî, -ye"
  },
  {
    "x": "înatê (m)",
    "y": " b. înadî (m)"
  },
  {
    "x": "înayin",
    "y": " b. inasarên"
  },
  {
    "x": "încas (m)",
    "y": " b. încase (m)"
  },
  {
    "x": "încase (m)",
    "y": " kara erik"
  },
  {
    "x": "încasêre (m)",
    "y": " kara erik ağacı"
  },
  {
    "x": "încasêri (m)",
    "y": " b. încasêre (m)"
  },
  {
    "x": "Încil-I (n)",
    "y": " b. Încîl (n)"
  },
  {
    "x": "încil-II (m)",
    "y": " b. încîre (m), hêjîre (m)"
  },
  {
    "x": "încile (m)",
    "y": " b. încîre (m), hêjîre (m)"
  },
  {
    "x": "încilewr (n)",
    "y": " b. încîrêre (m), hêjîrêre (m)"
  },
  {
    "x": "încilêr (n)",
    "y": " b. încîrêre (m), hêjîrêre (m)"
  },
  {
    "x": "încili (m)",
    "y": " b. încîre (m), hêjîre (m)"
  },
  {
    "x": "încilor (n)",
    "y": " b. încîrêre (m), hêjîrêre (m)"
  },
  {
    "x": "încilowr (n)",
    "y": " b. încîrêre (m), hêjîrêre (m)"
  },
  {
    "x": "încilyer (m)",
    "y": " b. încîrêre (m), hêjîrêre (m)"
  },
  {
    "x": "încire (m)",
    "y": " b. încîre (m), hêjîre (m)"
  },
  {
    "x": "Încîl-I (n)",
    "y": " Ahd-i Cedit, İncil"
  },
  {
    "x": "încîl-II (m)",
    "y": " b. încîre (m), hêjîre (m)"
  },
  {
    "x": "încîlî",
    "y": " b. anculî"
  },
  {
    "x": "încîre (m)",
    "y": " incir"
  },
  {
    "x": "încîrêre (m)",
    "y": " incir ağacı"
  },
  {
    "x": "înç (n)",
    "y": " inç, parmak (2,54 cm), pus"
  },
  {
    "x": "îndî",
    "y": " b. êdî"
  },
  {
    "x": "îndîkator (n)",
    "y": " gösterge"
  },
  {
    "x": "îndîvîdualîst, -e",
    "y": " bireyci, ferdiyetçi, individüalist"
  },
  {
    "x": "îndîvîdualîstîye (m)",
    "y": " bireycilik"
  },
  {
    "x": "Îndonezya (m)",
    "y": " Endonezya"
  },
  {
    "x": "îne-I (n)",
    "y": " cuma"
  },
  {
    "x": "îne-II (n)",
    "y": " b. hênî-I (n)"
  },
  {
    "x": "îne-III (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "Îne (m)",
    "y": " inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "îney (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "înê-I (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "înê-II (zh, s)",
    "y": " b. nê-II (zh, s)"
  },
  {
    "x": "îngiliz, -e",
    "y": " 1)İngiliz\r\n~2)bir tüfek çeşidi"
  },
  {
    "x": "îngilizkî (m)",
    "y": " İngilizce"
  },
  {
    "x": "Îngilîstan (n)",
    "y": " İngiltere"
  },
  {
    "x": "îngilîz (n)",
    "y": " b. îngiliz (n)"
  },
  {
    "x": "îngîmîyen (n)",
    "y": " b. hingimên (n)"
  },
  {
    "x": "îngîşt (m)",
    "y": " b. engişte (m)"
  },
  {
    "x": "îngîşti (m)",
    "y": " b. engişte (m)"
  },
  {
    "x": "îngîştey destî (m)",
    "y": " b. engişta destî (m)"
  },
  {
    "x": "îngîştey lingi (m)",
    "y": " b. engişta linge (m)"
  },
  {
    "x": "îni (n)",
    "y": " b. hênî-I (n)"
  },
  {
    "x": "înî-I (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "înî-II (zh, s)",
    "y": " b. nê-II (zh, s)"
  },
  {
    "x": "înî-III (n, z)",
    "y": " b. ney-II (n, z)"
  },
  {
    "x": "înî-IV (n)",
    "y": " b. hênî-I (n)"
  },
  {
    "x": "...înî",
    "y": " b. -înan"
  },
  {
    "x": "înkan (n)",
    "y": " b. îmkan (n)"
  },
  {
    "x": "înkar (n)",
    "y": " yadsıma, inkâr"
  },
  {
    "x": "înkarkerdox, -e",
    "y": " münkir"
  },
  {
    "x": "înkey",
    "y": " b. nika "
  },
  {
    "x": "înkeyin, -i",
    "y": " b. nikayin, -e"
  },
  {
    "x": "înkê",
    "y": " b. nika "
  },
  {
    "x": "înko (n)",
    "y": " b. îmkan (n)"
  },
  {
    "x": "înkon (n)",
    "y": " b. îmkan (n) "
  },
  {
    "x": "înkû (n)",
    "y": " b. îmkan (n)"
  },
  {
    "x": "înkûn (n)",
    "y": " b. îmkan (n)"
  },
  {
    "x": "îno (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "îno bîyayene",
    "y": " b. înan bîyene"
  },
  {
    "x": "îno kerdene",
    "y": " b. înan kerdene"
  },
  {
    "x": "...îno",
    "y": " b. -înan (sf)"
  },
  {
    "x": "înon (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "înon kerdene",
    "y": " b. înan kerdene"
  },
  {
    "x": "...înon (sf)",
    "y": " b. -înan (sf)"
  },
  {
    "x": "înorganîk, -e",
    "y": " inorganik"
  },
  {
    "x": "însa (n)",
    "y": " b. însan (n)"
  },
  {
    "x": "însala",
    "y": " b. înşalah"
  },
  {
    "x": "însan, -e",
    "y": " insan, insanoğlu, adam, âdemoğlu, beşer, beniâdem, kul, abd"
  },
  {
    "x": "însanetey (m)",
    "y": " b. însanetî (m)"
  },
  {
    "x": "însanetî (m)",
    "y": " insanlık, adamlık "
  },
  {
    "x": "însanetîn (m)",
    "y": " b. însanetî (m)"
  },
  {
    "x": "însanetîye (m)",
    "y": " insanlık, adamlık "
  },
  {
    "x": "însaney (m)",
    "y": " b. însanîye (m)"
  },
  {
    "x": "însanîye (m)",
    "y": " insanlık, adamlık "
  },
  {
    "x": "înîsîyatîf (n)",
    "y": " inisiyatif"
  },
  {
    "x": "înso (n)",
    "y": " b. însan (n)"
  },
  {
    "x": "înson, -i",
    "y": " b. însan, -e"
  },
  {
    "x": "însonetey (m)",
    "y": " b. însanetî (m)"
  },
  {
    "x": "însonetê (m)",
    "y": " b. însanetî (m)"
  },
  {
    "x": "însoney (m)",
    "y": " b. însanîye (m)"
  },
  {
    "x": "însonê (m)",
    "y": " b. însanîye (m)"
  },
  {
    "x": "însû (n)",
    "y": " b. însan (n)"
  },
  {
    "x": "însûn, -i",
    "y": " b. însan, -e"
  },
  {
    "x": "însûnetey (m)",
    "y": " b. însanetî (m)"
  },
  {
    "x": "însûnetê (m)",
    "y": " b. însanetî (m)"
  },
  {
    "x": "însûney (m)",
    "y": " b. însanî (m)"
  },
  {
    "x": "însûnê (m)",
    "y": " b. însanî (m)"
  },
  {
    "x": "înşaat (n)",
    "y": " inşaat"
  },
  {
    "x": "înşaato betonarme (n)",
    "y": " betonarme inşaat"
  },
  {
    "x": "înşaatkar, -e",
    "y": " inşaatçı"
  },
  {
    "x": "înşala",
    "y": " b. înşalah"
  },
  {
    "x": "înşalah",
    "y": " inşallah, Allah vere de, Allah vere"
  },
  {
    "x": "înşalla",
    "y": " b. înşalah"
  },
  {
    "x": "înşallah",
    "y": " b. înşalah"
  },
  {
    "x": "înşallo",
    "y": " b. înşalah"
  },
  {
    "x": "înşallû",
    "y": " b. înşalah"
  },
  {
    "x": "înşalo",
    "y": " b. înşalah"
  },
  {
    "x": "înşalû",
    "y": " b. înşalah"
  },
  {
    "x": "înşeat (n)",
    "y": " b. înşaat (n)"
  },
  {
    "x": "înşela",
    "y": " b. înşalah"
  },
  {
    "x": "înşelah ",
    "y": " b. Înşalah"
  },
  {
    "x": "înşeleh ",
    "y": " b. Înşalah"
  },
  {
    "x": "înşella",
    "y": " b. înşalah"
  },
  {
    "x": "înşellah",
    "y": " b. înşalah"
  },
  {
    "x": "întensîf, -e",
    "y": " yoğun"
  },
  {
    "x": "întensîf bîyene",
    "y": " yoğunlaşmak (yoğun duruma gelmek)"
  },
  {
    "x": "întensîf kerdene",
    "y": " yoğunlaştırmak (yoğun duruma getirmek)"
  },
  {
    "x": "întensîfbîyayîş (n)",
    "y": " tekâsüf, yoğunlaşma (yoğun duruma gelme)"
  },
  {
    "x": "întensîfî (m)",
    "y": " yoğunluk"
  },
  {
    "x": "întensîfkerdiş (n)",
    "y": " yoğunlaştırma (yoğun duruma getirme)"
  },
  {
    "x": "înterjeksîyon (n)",
    "y": " ünlem"
  },
  {
    "x": "Înternet (n) (TE)",
    "y": " İnternet"
  },
  {
    "x": "întiham (n)",
    "y": " b. îmtihan (n)"
  },
  {
    "x": "întîhal kerdene",
    "y": " aşırmak"
  },
  {
    "x": "întîqal (n)",
    "y": " intikal (felsefede)"
  },
  {
    "x": "întîqalê mîrasî (n)",
    "y": " mirasın intikali"
  },
  {
    "x": "întîzamin, -e",
    "y": " düzenli, intizamlı, nizamlı "
  },
  {
    "x": "întransîtîf",
    "y": " geçişsiz "
  },
  {
    "x": "înû (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "înû bîyene",
    "y": " b. înan bîyene"
  },
  {
    "x": "înû kerdene",
    "y": " b. înan kerdene"
  },
  {
    "x": "...înû",
    "y": " b. -înan"
  },
  {
    "x": "înûn bîyene",
    "y": " b. înan bîyene"
  },
  {
    "x": "înûn kerdene",
    "y": " b. înan kerdene"
  },
  {
    "x": "...înûn",
    "y": " b. -înan"
  },
  {
    "x": "îpeg (n)",
    "y": " ipek"
  },
  {
    "x": "îpegên, -e",
    "y": " ipekten yapılma, ipekli"
  },
  {
    "x": "îpegin, -e",
    "y": " ipekli"
  },
  {
    "x": "îpek (n)",
    "y": " b. îpeg (n)"
  },
  {
    "x": "îpekin, -e",
    "y": " b. îpegin, -e"
  },
  {
    "x": "îpotek (n)",
    "y": " ipotek"
  },
  {
    "x": "îptal, -e",
    "y": " b. betal, -e"
  },
  {
    "x": "îptalkerdiş (n)",
    "y": " b. betalkerdiş (n)"
  },
  {
    "x": "îptalkerdişê aboneyan (n)",
    "y": " b. betalkerdişê aboneyan (n)"
  },
  {
    "x": "îptalkerdişê aboneyîye (n)",
    "y": " b. betalkerdişê aboneyîye (n)"
  },
  {
    "x": "îqaz (n)",
    "y": " uyarı, ikaz"
  },
  {
    "x": "îqaz kerdene",
    "y": " uyarmak, ikaz etmek "
  },
  {
    "x": "îqazkerdiş (n)",
    "y": " uyarma, ikaz etme"
  },
  {
    "x": "îqtîdar (n)",
    "y": " iktidar"
  },
  {
    "x": "îqtîbas (n)",
    "y": " aktarma, iktibas"
  },
  {
    "x": "îqtîbas kerdene",
    "y": " aktarmak, iktibas etmek"
  },
  {
    "x": "îqtîbaskerdiş (n)",
    "y": " aktarma"
  },
  {
    "x": "îr (n)",
    "y": " b. ewro (n)"
  },
  {
    "x": "Îran (n)",
    "y": " İran"
  },
  {
    "x": "Îraq (n)",
    "y": " Irak"
  },
  {
    "x": "îrbişim (n)",
    "y": " b. birîsim (n)"
  },
  {
    "x": "îrbişimên, -e",
    "y": " b. birîsimên, -e"
  },
  {
    "x": "îrbişimin, -e",
    "y": " b. birîsimin, -e"
  },
  {
    "x": "îre-I (m)",
    "y": " tırtıl"
  },
  {
    "x": "îre-II (m)",
    "y": " alerji (kaşıntılı alerji)"
  },
  {
    "x": "îre vetene",
    "y": " alerji olmak"
  },
  {
    "x": "îrekerdoxî (zh)",
    "y": " alerji yapıcılar"
  },
  {
    "x": "îri (m)",
    "y": " b. îre (m)"
  },
  {
    "x": "îri vetene",
    "y": " b. îre vetene"
  },
  {
    "x": "Îrlanda (m)",
    "y": " İrlanda"
  },
  {
    "x": "îro-I (n)",
    "y": " b. hîro (n)"
  },
  {
    "x": "îro-II",
    "y": " b. ewro"
  },
  {
    "x": "îrrasyonel, -e",
    "y": " akıl dışı, irrasyonel, us dışı"
  },
  {
    "x": "îrsalîye (m)",
    "y": " irsaliye"
  },
  {
    "x": "îrtîbat (n) (TE)",
    "y": " 1)bağlantı, irtibat, ilişki\r\n~2)bağıntı"
  },
  {
    "x": "îrtîbatê kanalî (n)",
    "y": " kanal bağlantısı"
  },
  {
    "x": "îrtîfaq (n)",
    "y": " irtifak"
  },
  {
    "x": "îrtîfaqê dayîreyî (n)",
    "y": " kat irtifakı"
  },
  {
    "x": "îs (n)",
    "y": " b. îş (n)"
  },
  {
    "x": "Îsa",
    "y": " İsa, Hz. İsa"
  },
  {
    "x": "Îsayî ra ver (ÎV)",
    "y": " Milattan Önce (MÖ)"
  },
  {
    "x": "Îsayî ra pey (ÎP)",
    "y": " Milattan Sonra (MS)"
  },
  {
    "x": "îsa (n)",
    "y": " b. însan (n)"
  },
  {
    "x": "îsala",
    "y": " b. înşalah"
  },
  {
    "x": "îsan, -e",
    "y": " b. însan, -e"
  },
  {
    "x": "îsanetey (m)",
    "y": " b. însanetî (m)"
  },
  {
    "x": "îsanetê (m)",
    "y": " b. însanetî (m)"
  },
  {
    "x": "îsaney (m)",
    "y": " b. însanî (m)"
  },
  {
    "x": "îsanê (m)",
    "y": " b. însanî (m)"
  },
  {
    "x": "îsanên (m)",
    "y": " b. însanî (m)"
  },
  {
    "x": "îsanênî (m)",
    "y": " b. însanî (m)"
  },
  {
    "x": "îsantîne (m)",
    "y": " b. însanetî (m)"
  },
  {
    "x": "îskan (n)",
    "y": " iskân"
  },
  {
    "x": "îskan kerdene",
    "y": " iskân etmek"
  },
  {
    "x": "îskankerdiş (n)",
    "y": " iskân etme"
  },
  {
    "x": "îskarpîn (n)",
    "y": " iskarpin"
  },
  {
    "x": "îske (m)",
    "y": " hıçkırık"
  },
  {
    "x": "îske kerdene",
    "y": " hıçkırmak"
  },
  {
    "x": "îskekerdiş (n)",
    "y": " hıçkırma"
  },
  {
    "x": "îskele (n)",
    "y": " büğet"
  },
  {
    "x": "îskelet (n)",
    "y": " iskelet"
  },
  {
    "x": "îskeletin, -e",
    "y": " iskeletli"
  },
  {
    "x": "îskence (n)",
    "y": " 1)b. îşkence (n)\r\n~2)işkence (bir tür sıkıştırma aracı)"
  },
  {
    "x": "îski (m)",
    "y": " b. îske (m)"
  },
  {
    "x": "îski kerdene",
    "y": " b. îske kerdene"
  },
  {
    "x": "îskikerdiş (n)",
    "y": " b. îskekerdiş (n)"
  },
  {
    "x": "îskonto (n)",
    "y": " iskonto"
  },
  {
    "x": "îsla (n)",
    "y": " b. îsleh (n)"
  },
  {
    "x": "îsla kerdene",
    "y": " b. îsleh kerdene"
  },
  {
    "x": "îslam, -e",
    "y": " İslam, Müslüman"
  },
  {
    "x": "Îslamabad (n)",
    "y": " İslamabad"
  },
  {
    "x": "îslamey (m)",
    "y": " b. îslamîye (m)"
  },
  {
    "x": "îslamê (m)",
    "y": " b. îslamîye (m)"
  },
  {
    "x": "îslamênî (m)",
    "y": " Müslümanlık"
  },
  {
    "x": "îslamî-I (m)",
    "y": " İslamiyet, İslamlık, Müslümanlık"
  },
  {
    "x": "îslamî, -ye-II",
    "y": " İslami"
  },
  {
    "x": "îslamîye (m)",
    "y": " İslamiyet, İslamlık, Müslümanlık"
  },
  {
    "x": "îslamîyet (n)",
    "y": " İslamiyet, Müslümanlık"
  },
  {
    "x": "Îslanda (m)",
    "y": " İzlanda"
  },
  {
    "x": "îsleh (n)",
    "y": " ıslah"
  },
  {
    "x": "îsleh kerdene",
    "y": " ıslah etmek"
  },
  {
    "x": "îslehkerdiş (n)",
    "y": " ıslah etme"
  },
  {
    "x": "îslehkerdişê çopî (n)",
    "y": " çöp ıslahı"
  },
  {
    "x": "îslehkerdişê herre (n)",
    "y": " toprak ıslahı"
  },
  {
    "x": "îslige (m)",
    "y": " b. îşlig (n)"
  },
  {
    "x": "îslih (n)",
    "y": " b. îsleh (n)"
  },
  {
    "x": "îslih kerdene",
    "y": " b. îsleh kerdene"
  },
  {
    "x": "îslihkerdiş (n)",
    "y": " b. îslehkerdiş"
  },
  {
    "x": "îslîmkar, -e",
    "y": " b. îstîmkar, -e"
  },
  {
    "x": "îslom, -i",
    "y": " b. îslam, -e"
  },
  {
    "x": "îslomey (m)",
    "y": " b. îslamîye (m)"
  },
  {
    "x": "îslomê (m)",
    "y": " b. îslamîye (m)"
  },
  {
    "x": "îslomî",
    "y": " b. îslamî, -ye"
  },
  {
    "x": "îslomîyet (n)",
    "y": " b. îslamîyet (n)"
  },
  {
    "x": "îslûm, -i",
    "y": " b. îslam, -e"
  },
  {
    "x": "îslûmê (m)",
    "y": " b. îslamîye (m)"
  },
  {
    "x": "îslûmî",
    "y": " b. îslamî, -ye"
  },
  {
    "x": "îslûmîyet (n)",
    "y": " b. îslamîyet (n)"
  },
  {
    "x": "îsnad (n)",
    "y": " isnat"
  },
  {
    "x": "îsnad kerdene",
    "y": " isnat etmek"
  },
  {
    "x": "îsnadkerda (m)",
    "y": " isnat edilen"
  },
  {
    "x": "îsnadkerde (n)",
    "y": " isnat edilen"
  },
  {
    "x": "îsnadkerdox, -e",
    "y": " isnat eden"
  },
  {
    "x": "îso (n)",
    "y": " b. însan (n)"
  },
  {
    "x": "îson, -e",
    "y": " b. însan, -e"
  },
  {
    "x": "îsonetey (m)",
    "y": " b. însanetî (m)"
  },
  {
    "x": "îsonetê (m)",
    "y": " b. însanetî (m) "
  },
  {
    "x": "îsoney (m)",
    "y": " b. însanî (m)"
  },
  {
    "x": "îsonê (m)",
    "y": " b. însanî (m)"
  },
  {
    "x": "îsonên (m)",
    "y": " b. însanî (m)"
  },
  {
    "x": "îsonênî (m)",
    "y": " b. însanî (m)"
  },
  {
    "x": "îsot (n)",
    "y": " biber"
  },
  {
    "x": "îsotê çarlîstonî (n)",
    "y": " çarliston biberi"
  },
  {
    "x": "îsoto derg (n)",
    "y": " sivri biber"
  },
  {
    "x": "îsoto guzin (n)",
    "y": " acı biber"
  },
  {
    "x": "îsoto hurdî (n)",
    "y": " toz biber "
  },
  {
    "x": "îsoto kulikin (n)",
    "y": " çarliston biberi"
  },
  {
    "x": "îsoto perik (n)",
    "y": " pul biber"
  },
  {
    "x": "îsoto sîya (n)",
    "y": " karabiber"
  },
  {
    "x": "îsoto tuj (n)",
    "y": " 1)acı biber\r\n~2)sivri biber"
  },
  {
    "x": "îsoto tun (n)",
    "y": " acı biber"
  },
  {
    "x": "îsoto wirdi (n)",
    "y": " b. îsoto hurdî (n)"
  },
  {
    "x": "îsoto wurdi (n)",
    "y": " b. îsoto hurdî (n)"
  },
  {
    "x": "îsotin, -e",
    "y": " biberli"
  },
  {
    "x": "îspanax (n)",
    "y": " b. îspenax (n)"
  },
  {
    "x": "îspat (n)",
    "y": " ispat"
  },
  {
    "x": "îspat kerdene",
    "y": " ispat etmek "
  },
  {
    "x": "îspenax (n)",
    "y": " ıspanak"
  },
  {
    "x": "Îspîr (n)",
    "y": " İspir"
  },
  {
    "x": "îspot (n)",
    "y": " b. îspat (n)"
  },
  {
    "x": "îspot kerdene",
    "y": " b. îspat kerdene"
  },
  {
    "x": "îsqal (n)",
    "y": " b. îşxal (n)"
  },
  {
    "x": "îsqarpîn (n)",
    "y": " b. îskarpîn (n)"
  },
  {
    "x": "Îsraîl (n)",
    "y": " İsrail "
  },
  {
    "x": "îstakoz (n)",
    "y": " istakoz"
  },
  {
    "x": "Îstambuel (n)",
    "y": " b. Îstanbul (n)"
  },
  {
    "x": "Îstambul (n)",
    "y": " b. Îstanbul (n)"
  },
  {
    "x": "Îstanbuel (n)",
    "y": " b. Îstanbul (n)"
  },
  {
    "x": "Îstanbul (n)",
    "y": " İstanbul"
  },
  {
    "x": "îstane (n)",
    "y": " b. îştane (n)"
  },
  {
    "x": "îstasyon (m)",
    "y": " b. îstasyone (m)"
  },
  {
    "x": "îstasyone (m)",
    "y": " istasyon"
  },
  {
    "x": "îstasyona bazî (m)",
    "y": " baz istasyonu"
  },
  {
    "x": "îstasyona benzînî (m)",
    "y": " benzin istasyonu"
  },
  {
    "x": "îstasyona karî (m)",
    "y": " iş istasyonu"
  },
  {
    "x": "îstasyona karî ya programkere (m)",
    "y": " programcı iş istasyonu"
  },
  {
    "x": "îstasyona kontrolî (m)",
    "y": " muayene istasyonu"
  },
  {
    "x": "îstasyona muayeneyî (m)",
    "y": " muayene istasyonu"
  },
  {
    "x": "îstasyona pompa (m)",
    "y": " pompa istasyonu"
  },
  {
    "x": "îstasyonê peymîye (zh)",
    "y": " ölçüm istasyonları"
  },
  {
    "x": "îstasyona sentişî ya wesayîtan",
    "y": " araç tartı istasyonu"
  },
  {
    "x": "îstasyona servîsî (m)",
    "y": " servis istasyonu"
  },
  {
    "x": "îstasyona trêne (m)",
    "y": " tren istasyonu"
  },
  {
    "x": "Îstembul (n)",
    "y": " b. Îstanbul (n)"
  },
  {
    "x": "îstesun (m)",
    "y": " b. îstasyone (m)"
  },
  {
    "x": "îstesuni (m)",
    "y": " b. îstasyone (m)"
  },
  {
    "x": "îstesyoni (m)",
    "y": " b. îstasyone (m)"
  },
  {
    "x": "îstifade kerdene",
    "y": " faydalanmak, yararlanmak, istifade etmek"
  },
  {
    "x": "îstifadekerdiş (n)",
    "y": " faydalanma, yararlanma, intifa, istifade etme, istifade"
  },
  {
    "x": "îstire-I (m)",
    "y": " gözyaşı"
  },
  {
    "x": "îstire-II (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "îstirî (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "îstirin, -e",
    "y": " b. estrîyin, -e"
  },
  {
    "x": "îstîdlal (n)",
    "y": " istidlâl (mantıkta), çıkarım (mantıkta)"
  },
  {
    "x": "îstîfa (m)",
    "y": " istifa"
  },
  {
    "x": "îstîfa kerdene",
    "y": " istifa etmek"
  },
  {
    "x": "îstîfakerdiş (n)",
    "y": " istifa etme"
  },
  {
    "x": "îstîfaname (n)",
    "y": " istifaname"
  },
  {
    "x": "îstîhkam (n)",
    "y": " istihkâm"
  },
  {
    "x": "îstîhkamkar, -e",
    "y": " istihkâmcı"
  },
  {
    "x": "îstîhqaq (n)",
    "y": " hakediş"
  },
  {
    "x": "îstîhsal (n)",
    "y": " üretim, üretme"
  },
  {
    "x": "îstîmkar, -e",
    "y": " kuru temizleyici"
  },
  {
    "x": "îstîmlak (n)",
    "y": " kamulaştırma, istimlak"
  },
  {
    "x": "îstînadgeh (n)",
    "y": " dayanak"
  },
  {
    "x": "îstîqaq (n)",
    "y": " b. îstîhqaq (n)"
  },
  {
    "x": "îstîqbal (n)",
    "y": " gelecek, istikbal"
  },
  {
    "x": "îstîre (m)",
    "y": " b. îstire (m)"
  },
  {
    "x": "îstîrî kerdene",
    "y": " b. estrî kerdene"
  },
  {
    "x": "îstîrî pede kerdene",
    "y": " b. estrî pede kerdene"
  },
  {
    "x": "îstîrî piro dayene",
    "y": " b. estrî piro dayene"
  },
  {
    "x": "îstîxrac (n)",
    "y": " çıkarsama (felsefede)"
  },
  {
    "x": "îstop",
    "y": " b. stop"
  },
  {
    "x": "îstop kerdene",
    "y": " b. stop kerdene"
  },
  {
    "x": "îsuat (n)",
    "y": " b. îsot (n)"
  },
  {
    "x": "îsuat wurdi (n)",
    "y": " b. îsoto hurdî (n)"
  },
  {
    "x": "îsû (n)",
    "y": " b. însan (n)"
  },
  {
    "x": "îsûn, -i",
    "y": " b. însan, -e"
  },
  {
    "x": "îsûney (m)",
    "y": " b. însanîye (m)"
  },
  {
    "x": "îsûnê (m)",
    "y": " b. însanîye (m)"
  },
  {
    "x": "îswat (n)",
    "y": " b. îsot (n)"
  },
  {
    "x": "îswato wirdi (n)",
    "y": " b. îsoto hurdî (n)"
  },
  {
    "x": "îsya (n)",
    "y": " b. îsyan (n)"
  },
  {
    "x": "îsya kerdene",
    "y": " b. îsyan kerdene"
  },
  {
    "x": "îsyan (n)",
    "y": " ayaklanma, başkaldırı, başkaldırma, isyan"
  },
  {
    "x": "îsyan kerdene",
    "y": " ayaklanmak, başkaldırmak, isyan etmek"
  },
  {
    "x": "îsyankerdiş (n)",
    "y": " ayaklanma, başkaldırı, başkaldırma, isyan etme"
  },
  {
    "x": "îsyo (n)",
    "y": " b. îsyan (n)"
  },
  {
    "x": "îsyo kerdene",
    "y": " b. îsyan kerdene"
  },
  {
    "x": "îsyon (n)",
    "y": " b. îsyan (n)"
  },
  {
    "x": "îsyon kerdene",
    "y": " b. îsyan kerdene"
  },
  {
    "x": "îsyonkerdiş (n)",
    "y": " b. îsyankerdiş (n)"
  },
  {
    "x": "îsyû (n)",
    "y": " b. îsyan (n)"
  },
  {
    "x": "îsyû kerdene",
    "y": " b. îsyan kerdene"
  },
  {
    "x": "îsyûn (n)",
    "y": " b. îsyan (n)"
  },
  {
    "x": "îsyûn kerdene",
    "y": " b. îsyan kerdene"
  },
  {
    "x": "îsyûnkerdiş (n)",
    "y": " b. îsyankerdiş (n)"
  },
  {
    "x": "îş (n)",
    "y": " b. kar (n), xebate (m), gure (n)"
  },
  {
    "x": "îşa (n)",
    "y": " b. eşa (n) "
  },
  {
    "x": "îşala",
    "y": " b. înşalah"
  },
  {
    "x": "îşalla",
    "y": " b. înşalah"
  },
  {
    "x": "îşallo",
    "y": " b. înşalah"
  },
  {
    "x": "îşallû",
    "y": " b. înşalah"
  },
  {
    "x": "îşalo",
    "y": " b. înşalah"
  },
  {
    "x": "îşalû",
    "y": " b. înşalah"
  },
  {
    "x": "îşaret (n)",
    "y": " im, işaret, belirti"
  },
  {
    "x": "îşaret bîyene",
    "y": " işaretlenmek, işaret edilmek"
  },
  {
    "x": "îşaret kerdene",
    "y": " imlemek, işaret etmek, işaretlemek"
  },
  {
    "x": "îşaretê rayîrî (zh)",
    "y": " yol işaretleri"
  },
  {
    "x": "îşaretê reykîkerdişî (n) (mat)",
    "y": " çarpma işareti"
  },
  {
    "x": "îşaretê trafîkî (n)",
    "y": " trafik işareti"
  },
  {
    "x": "îşaretê trafîkî (zh)",
    "y": " trafik işaretleri"
  },
  {
    "x": "îşaretbîyayîş (n)",
    "y": " işaretlenme"
  },
  {
    "x": "îşaretdayox, -e",
    "y": " işaretçi"
  },
  {
    "x": "îşaretkerda (m)",
    "y": " işaretli"
  },
  {
    "x": "îşaretkerde (n)",
    "y": " işaretli"
  },
  {
    "x": "îşaretkerdiş (n)",
    "y": " imleme, işaretleme"
  },
  {
    "x": "îşaretkerdîye (m)",
    "y": " işaretli"
  },
  {
    "x": "îşat (n)",
    "y": " b. înşaat (n)"
  },
  {
    "x": "îşela",
    "y": " b. înşalah"
  },
  {
    "x": "îşelah",
    "y": " b. înşalah"
  },
  {
    "x": "îşelay",
    "y": " b. înşalah"
  },
  {
    "x": "îşella",
    "y": " b. înşalah"
  },
  {
    "x": "îşellah b. înşalah"
  },
  {
    "x": "îşellay",
    "y": " b. înşalah"
  },
  {
    "x": "îşik (m)",
    "y": " b. îşike (m)"
  },
  {
    "x": "îşike (m)",
    "y": " oyuncak"
  },
  {
    "x": "îşika darêne (m)",
    "y": " ahşap oyuncak"
  },
  {
    "x": "îşiki (m)",
    "y": " b. îşike (m)"
  },
  {
    "x": "îşikkar, -e",
    "y": " oyuncakçı (oyuncak yapan)"
  },
  {
    "x": "îşikroş, -e",
    "y": " oyuncakçı (oyuncak satan)"
  },
  {
    "x": "îşkence (m)",
    "y": " b. îşkence (n)"
  },
  {
    "x": "îşkence (n)",
    "y": " işkence"
  },
  {
    "x": "îşkenceker, -e",
    "y": " işkenceci"
  },
  {
    "x": "îşlig (n)",
    "y": " gömlek, mintan"
  },
  {
    "x": "îşlige (m)",
    "y": " b. îşlig (n)"
  },
  {
    "x": "îşligi (m)",
    "y": " b. îşlig (n)"
  },
  {
    "x": "îşlik (n)",
    "y": " b. îşlig (n)"
  },
  {
    "x": "îşlîg (n)",
    "y": " b. îşlig (n)"
  },
  {
    "x": "îşlîge (m)",
    "y": " b. îşlig (n)"
  },
  {
    "x": "îşlîga fîstanî (m)",
    "y": " fistanın belden yukarı kısmı"
  },
  {
    "x": "îşlîgi (m)",
    "y": " b. îşlig (n)"
  },
  {
    "x": "îşporta (m)",
    "y": " işporta"
  },
  {
    "x": "îşportakar, -e",
    "y": " işportacı"
  },
  {
    "x": "îşta (m)",
    "y": " b. îştah (n)"
  },
  {
    "x": "îştah (n)",
    "y": " iştah"
  },
  {
    "x": "îştane (n)",
    "y": " yüzük"
  },
  {
    "x": "îştaneyo sêmên (n)",
    "y": " gümüş yüzük"
  },
  {
    "x": "îşte bîyayene",
    "y": " b. mîşte bîyene "
  },
  {
    "x": "îşte kerdene",
    "y": " b. mîşte kerdene"
  },
  {
    "x": "îştebîyayîş (n)",
    "y": " b. mîştebîyayîş (n)"
  },
  {
    "x": "îşteh (n)",
    "y": " b. îştah (n)"
  },
  {
    "x": "îştekerde (n)",
    "y": " b. mîştekerde (n)"
  },
  {
    "x": "îştekerdiş (n)",
    "y": " b. mîştekerdiş (n)"
  },
  {
    "x": "îştene",
    "y": " b. eştene"
  },
  {
    "x": "îştire (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "iştirin",
    "y": " b. estrîyin, -e"
  },
  {
    "x": "îştirî (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "iştirîyin",
    "y": " b. estrîyin, -e"
  },
  {
    "x": "îştiş (n)",
    "y": " b. eştiş (n)"
  },
  {
    "x": "îştîr (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "îştîre (m)",
    "y": " b. hesire (m)"
  },
  {
    "x": "îştîrin, -e",
    "y": " b. estrîyin, -e"
  },
  {
    "x": "îştîrî (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "îştîrî re ci estene",
    "y": " b. estrî re ci eştene"
  },
  {
    "x": "îştîrîyin, -e",
    "y": " b. estrîyin, -e"
  },
  {
    "x": "îşxal (n)",
    "y": " işgal"
  },
  {
    "x": "îşxalîye (m)",
    "y": " işgaliye"
  },
  {
    "x": "îta (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "îtaat (n)",
    "y": " b. îtaet (n)"
  },
  {
    "x": "îtaat kerdene",
    "y": " b. îtaet kerdene"
  },
  {
    "x": "îtaatkerdiş (n)",
    "y": " b. îtaetkerdiş (n)"
  },
  {
    "x": "îtaet (n)",
    "y": " itaat"
  },
  {
    "x": "îtaet kerdene",
    "y": " boyun eğmek, itaat etmek"
  },
  {
    "x": "îtaetkerdiş (n)",
    "y": " boyun eğme, itaat etme"
  },
  {
    "x": "Îtalya (m)",
    "y": " İtalya"
  },
  {
    "x": "îtam (n)",
    "y": " b. îmtihan (n)"
  },
  {
    "x": "îteat (n)",
    "y": " b. îtaet (n)"
  },
  {
    "x": "îteat kerdene",
    "y": " b. îtaet kerdene"
  },
  {
    "x": "îteatkerdiş (n)",
    "y": " b. îtaetkerdiş (n)"
  },
  {
    "x": "îteet (n)",
    "y": " b. îtaet (n)"
  },
  {
    "x": "îteet kerdene",
    "y": " b. îtaet kerdene"
  },
  {
    "x": "îteetkerdiş (n)",
    "y": " b. îtaetkerdiş (n)"
  },
  {
    "x": "îtfaîya (m)",
    "y": " itfaiye"
  },
  {
    "x": "îtfaîyakar, -e",
    "y": " itfaiyeci, yangıncı"
  },
  {
    "x": "îtfaîye (m)",
    "y": " b. îtfaîya (m)"
  },
  {
    "x": "îtfaîyekar, -e",
    "y": " b. îtfaîyakar, -e"
  },
  {
    "x": "îtfaya (m)",
    "y": " b. îtfaîya (m)"
  },
  {
    "x": "îtfaye (m)",
    "y": " b. îtfaîya (m)"
  },
  {
    "x": "îtham (n)",
    "y": " b. îmtihan (n)"
  },
  {
    "x": "îtîbar ... bîyene",
    "y": " b. îtîbar bi ... bîyene"
  },
  {
    "x": "îtîbar ... kerdene",
    "y": " b. îtîbar bi ... kerdene"
  },
  {
    "x": "îtîbar bi ... ardene",
    "y": " -e güvenmek"
  },
  {
    "x": "îtîbar bi ... bîyene",
    "y": " -e güvenilmek"
  },
  {
    "x": "îtîbar bi ... kerdene",
    "y": " -e güvenmek"
  },
  {
    "x": "îtîqad (n)",
    "y": " inanç, itikat, akide"
  },
  {
    "x": "îtîqat (n)",
    "y": " b. îtîqad (n)"
  },
  {
    "x": "îtîraf (n)",
    "y": " itiraf"
  },
  {
    "x": "îtîraf kerdene",
    "y": " itiraf etmek"
  },
  {
    "x": "îtîraz (n)",
    "y": " itiraz"
  },
  {
    "x": "îtîraz kerdene",
    "y": " itiraz etmek"
  },
  {
    "x": "îtîrazkerdiş (n)",
    "y": " itiraz etme"
  },
  {
    "x": "îtîvar ... kerdene",
    "y": " b. îtîbar bi ... kerdene"
  },
  {
    "x": "îtîya (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "îtka (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "îttîfaq (n)",
    "y": " bağlaşma, ittifak"
  },
  {
    "x": "îttîfaq kerdene",
    "y": " bağlaşmak"
  },
  {
    "x": "îtxalat (n)",
    "y": " b. îdxalat (n)"
  },
  {
    "x": "îtya (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "îvriq (n)",
    "y": " b. îbriq (n)"
  },
  {
    "x": "îvrisim (n)",
    "y": " b. birîsim (n)"
  },
  {
    "x": "îvrişim (n)",
    "y": " b. birîsim (n)"
  },
  {
    "x": "îvrîşim (n)",
    "y": " b. birîsim (n)"
  },
  {
    "x": "îvrîşimên, -i",
    "y": " b. birîsimên, -e"
  },
  {
    "x": "îvrîşimin, -i",
    "y": " b. birîsimin, -e"
  },
  {
    "x": "îwez (n) (huq.)",
    "y": " edim"
  },
  {
    "x": "îxbar (n)",
    "y": " ihbar"
  },
  {
    "x": "îxrac (n)",
    "y": " ihraç"
  },
  {
    "x": "îxracat (n)",
    "y": " dışsatım, ihracat"
  },
  {
    "x": "îxracatkar, -e",
    "y": " dışsatımcı, ihracatçı"
  },
  {
    "x": "îxtar (n)",
    "y": " ihtar"
  },
  {
    "x": "îxtîyar, -e",
    "y": " b. extîyar, -e"
  },
  {
    "x": "îxtîyarey (m)",
    "y": " b. extîyarîye (m)"
  },
  {
    "x": "îxtîyarê (m)",
    "y": " b. extîyarî (m)"
  },
  {
    "x": "îxtîyarên (m)",
    "y": " b. extîyarênî (m)"
  },
  {
    "x": "îy-I (zh, z)",
    "y": " b. ê-III (zh, z)"
  },
  {
    "x": "îy-II (zh, s)",
    "y": " b. ê-IV (zh, s)"
  },
  {
    "x": "îy-III (n, z)",
    "y": " b. ey-I (n, z)"
  },
  {
    "x": "îy-IV (n, s)",
    "y": " b. ê-II (n, s)"
  },
  {
    "x": "îya (zr)",
    "y": " b. e-I (zr), eya-I (zr)"
  },
  {
    "x": "...îye (sf)",
    "y": " Türkçedeki “-lık”/“-lik” sonekinin karşılığı olan bir sonek"
  },
  {
    "x": "giranîye (m)",
    "y": " ağırlık"
  },
  {
    "x": "îyê (zh, z)",
    "y": " b. ê-III (zh, z)"
  },
  {
    "x": "îyî (n, z)",
    "y": " b. ey-I (n, z)"
  },
  {
    "x": "îyja (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "îyr (m)",
    "y": " b. îre-II (m)"
  },
  {
    "x": "îyr vetene",
    "y": " b. îre vetene"
  },
  {
    "x": "îyta (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "îyûn (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "îz-I (z)",
    "y": " b. ez (z)"
  },
  {
    "x": "îz-II (zr)",
    "y": " b. zî (zr)"
  },
  {
    "x": "îzafe (m)",
    "y": " tamlama "
  },
  {
    "x": "îzafeya nameyî (m)",
    "y": " ad tamlaması, isim tamlaması "
  },
  {
    "x": "îzafeya nameyî ya dîyare",
    "y": " belirtili isim tamlaması "
  },
  {
    "x": "îzafeya nameyî ya nedîyare",
    "y": " belirtisiz isim tamlaması "
  },
  {
    "x": "îzafeya rêzilkî (m)",
    "y": " zincirleme tamlama"
  },
  {
    "x": "îzafeya sifetî (m)",
    "y": " sıfat tamlaması"
  },
  {
    "x": "îzafî, -ye",
    "y": " göreceli, nisbî, -ye; îzafî, -ye; rolatîf, -e"
  },
  {
    "x": "îzah (n)",
    "y": " açıklama, izah"
  },
  {
    "x": "îzah bîyene",
    "y": " açıklanmak"
  },
  {
    "x": "îzah kerdene",
    "y": " açıklamak\r\n~Nê karî îzah bike. (Bu işi açıkla.)"
  },
  {
    "x": "îzahê karî (n)",
    "y": " iş açıklaması"
  },
  {
    "x": "îzahat (n)",
    "y": " açıklama, izahat"
  },
  {
    "x": "îzahat dayene",
    "y": " açıklama yapmak\r\n~Îzahat da. (Açıklama yaptı.)"
  },
  {
    "x": "îzahatdayîş (n)",
    "y": " açıklama yapma"
  },
  {
    "x": "îzahker,-e",
    "y": " açıklayıcı"
  },
  {
    "x": "îzahkerdiş (n)",
    "y": " açıklama "
  },
  {
    "x": "îzaleyêşuyû (n)",
    "y": " izaleişüyu"
  },
  {
    "x": "îzar (m)",
    "y": " b. îzare (m)"
  },
  {
    "x": "îzare (m)",
    "y": " 1)örtü olarak kullanılan bez\r\n~2)üzerine pestil bulamacı sürülerek pestil yapımı için kullanılan bez parçası"
  },
  {
    "x": "îzara lihêfî (m)",
    "y": " yorganın iç tarafa bakan yüzü"
  },
  {
    "x": "îzara masa (m)",
    "y": " masa örtüsü"
  },
  {
    "x": "îzara nimajî (m)",
    "y": " namazlık"
  },
  {
    "x": "îzari (m)",
    "y": " b. îzare (m)"
  },
  {
    "x": "îzdî",
    "y": " b. êzidî, -ye"
  },
  {
    "x": "îzdîte (m)",
    "y": " b. êzdîtîye (m)"
  },
  {
    "x": "îzdîtî (m)",
    "y": " b. êzdîtîye (m)"
  },
  {
    "x": "îzdîyî (m)",
    "y": " b. êzidîyî (m)"
  },
  {
    "x": "îzeh (n)",
    "y": " b. îzah (n)"
  },
  {
    "x": "îzeh kerdene",
    "y": " b. îzah kerdene"
  },
  {
    "x": "îzehet (n)",
    "y": " b. îzahat (n)"
  },
  {
    "x": "îzehet dayene",
    "y": " b. îzahat dayene"
  },
  {
    "x": "îzehkerdiş (n)",
    "y": " b. îzahkerdiş (n)"
  },
  {
    "x": "îzidî",
    "y": " b. êzidî, -ye"
  },
  {
    "x": "îzimbir, -e",
    "y": " oduncu (odun kesen kimse)"
  },
  {
    "x": "îzimbirî (m)",
    "y": " odunculuk"
  },
  {
    "x": "îzimbirîye (m)",
    "y": " odunculuk"
  },
  {
    "x": "îzime (m)",
    "y": " odun"
  },
  {
    "x": "îzima mazêre (m)",
    "y": " meşe odunu"
  },
  {
    "x": "îzimi (m)",
    "y": " b. îzime (m)"
  },
  {
    "x": "îzimroş, -e",
    "y": " oduncu (odun satan kimse)"
  },
  {
    "x": "îzimroşî (m)",
    "y": " odunculuk"
  },
  {
    "x": "îzimroşîye (m)",
    "y": " odunculuk"
  },
  {
    "x": "îzin (n)",
    "y": " izin"
  },
  {
    "x": "îzinê şuxulnîyayîşê bîna (n)",
    "y": " yapı kullanım izni"
  },
  {
    "x": "îzine (m)",
    "y": " b. îzin (n)"
  },
  {
    "x": "îzîdî",
    "y": " b. êzidî, -ye"
  },
  {
    "x": "îzleme rijnayene",
    "y": " boşanmak, nikah bozmak"
  },
  {
    "x": "îzleme riznayene",
    "y": " b. îzleme rijnayene"
  },
  {
    "x": "îzlemerijnayîş (n)",
    "y": " boşanma"
  },
  {
    "x": "îzlemeriznayîs (n)",
    "y": " b. îzlemerijnayîş (n)"
  },
  {
    "x": "îzm (n)",
    "y": " b. îzin (n)"
  },
  {
    "x": "îzn (n)",
    "y": " b. îzin (n)"
  },
  {
    "x": "îzne (m)",
    "y": " b. îzin (n)"
  },
  {
    "x": "îzolasyon (n)",
    "y": " 1)izolasyon, yalıtım\r\n~2)tecrit"
  },
  {
    "x": "JJJ"
  },
  {
    "x": "ja-I (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "ja-II (n)",
    "y": " b. jan (n)"
  },
  {
    "x": "ja-III (n)",
    "y": " b. ca-I (n)"
  },
  {
    "x": "ja-IV (m, z)",
    "y": " 1)a-I (m, z)\r\n~2)b. aye (m, z)"
  },
  {
    "x": "ja ra",
    "y": " b. aye ra"
  },
  {
    "x": "ja-V (n)",
    "y": " b. ziwa (n)"
  },
  {
    "x": "jafete (m)",
    "y": " b. zîyafet (n)"
  },
  {
    "x": "jafete dayene",
    "y": " b. zîyafet dayene"
  },
  {
    "x": "jaguar (n)",
    "y": " jaguar "
  },
  {
    "x": "jahr (n)",
    "y": " b. jehr (n)"
  },
  {
    "x": "jahr werdene",
    "y": " b. jehr werdene"
  },
  {
    "x": "jahrî (n)",
    "y": " b. jehrî (m)"
  },
  {
    "x": "jahrî kewtene",
    "y": " b. jehrî kewtene"
  },
  {
    "x": "jahrî werdene",
    "y": " b. jehrî werdene"
  },
  {
    "x": "jahrîya marî (m)",
    "y": " b. jehrîya marî (m)"
  },
  {
    "x": "Jakarta (m)",
    "y": " Cakarta"
  },
  {
    "x": "jaketatay (n)",
    "y": " jaketatay"
  },
  {
    "x": "jaluzî (n)",
    "y": " jaluzi"
  },
  {
    "x": "Jamaîka (m)",
    "y": " Jamaika"
  },
  {
    "x": "jan-I (n)",
    "y": " 1)bulaşıcı hastalık\r\n~2)salgın hastalık, epidemi \r\n~3)sızı"
  },
  {
    "x": "jan dayene",
    "y": " sızlamak, ağrımak"
  },
  {
    "x": "jan dayêne",
    "y": " b. jan dayene"
  },
  {
    "x": "jan dekewtene",
    "y": " 1)sızlamak\r\n~2)fazla üşümekten dolayı aşırı sızlamak (el vb. için)\r\n~3)bulaşıcı hastalığa yakalanmak \r\n~4)salgın hastalığa yakalanmak"
  },
  {
    "x": "jan kerdene",
    "y": " sızlamak"
  },
  {
    "x": "jan vetene",
    "y": " 1)bulaşıcı hastalığa yakalanmak\r\n~2)salgın hastalığa yakalanmak"
  },
  {
    "x": "janê sereyî (n)",
    "y": " baş ağrısı"
  },
  {
    "x": "jan-II (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "jan têver û têpeyî estene",
    "y": " b. ziwan têver û têpeyî eştene"
  },
  {
    "x": "jan-III (n)",
    "y": " b. zîyan-II (n)"
  },
  {
    "x": "japone (n)",
    "y": " japone (kadın giysisi için)"
  },
  {
    "x": "Japonya (m)",
    "y": " Japonya"
  },
  {
    "x": "jar-I (n)",
    "y": " b. jehr (n)"
  },
  {
    "x": "jar, -e-II",
    "y": " b. zar, -e"
  },
  {
    "x": "jar bîyayene",
    "y": " b. zar bîyene"
  },
  {
    "x": "jar kerdene",
    "y": " b. zar kerdene "
  },
  {
    "x": "jarbîyayîş (n)",
    "y": " b. zarbîyayîş (n)"
  },
  {
    "x": "jarî-I (m)",
    "y": " b. jehrî (m)"
  },
  {
    "x": "jarî kotene",
    "y": " b. jehrî kewtene"
  },
  {
    "x": "jarî werdene",
    "y": " b. jehrî werdene"
  },
  {
    "x": "jarîya marî (m)",
    "y": " b. jehrîya marî (m)"
  },
  {
    "x": "jarî-II (m)",
    "y": " b. zarî-II (m)"
  },
  {
    "x": "jarkerdiş (n)",
    "y": " b. zarkerdiş (n)"
  },
  {
    "x": "jarse (n)",
    "y": " jarse "
  },
  {
    "x": "jartîyer (n)",
    "y": " jartiyer"
  },
  {
    "x": "je-I",
    "y": " b. se-III"
  },
  {
    "x": "je-II",
    "y": " b. yew"
  },
  {
    "x": "jede",
    "y": " b. zîyade"
  },
  {
    "x": "jehir (n)",
    "y": " b. jehr (n)"
  },
  {
    "x": "jehr (n)",
    "y": " zehir, toksin"
  },
  {
    "x": "jehr dayene",
    "y": " zehirlemek, ağılamak"
  },
  {
    "x": "jehr werdene",
    "y": " zehirlenmek"
  },
  {
    "x": "jehrdayîş (n)",
    "y": " zehirleme, ağılama"
  },
  {
    "x": "jehrikîyayene",
    "y": " zehirlenmek"
  },
  {
    "x": "jehrikîyayîş (n)",
    "y": " zehirlenme"
  },
  {
    "x": "jehrikîyayîşê karbonmonoksîtî (n)",
    "y": " karbonmonoksit zehirlenme"
  },
  {
    "x": "jehrin, -e",
    "y": " zehirli, toksik"
  },
  {
    "x": "jehrî (m)",
    "y": " zehir, toksin"
  },
  {
    "x": "jehrî dayene",
    "y": " zehirlemek, ağılamak"
  },
  {
    "x": "jehrî kewtene",
    "y": " zehirlenmek"
  },
  {
    "x": "jehrî werdene",
    "y": " zehirlenmek"
  },
  {
    "x": "jehrîya marî (m)",
    "y": " yılan zehiri"
  },
  {
    "x": "jehrîdayîş (n)",
    "y": " zehirleme, ağılama"
  },
  {
    "x": "jehrîkewtiş (n)",
    "y": " zehirlenme"
  },
  {
    "x": "jehrîkîyayene",
    "y": " zehirlenmek"
  },
  {
    "x": "jehrîkîyayîş (n)",
    "y": " zehirlenme"
  },
  {
    "x": "jehrîwerdiş (n)",
    "y": " zehirlenme"
  },
  {
    "x": "jehrîyin, -e",
    "y": " zehirli, toksik"
  },
  {
    "x": "jehrwerdiş (n)",
    "y": " zehirlenme"
  },
  {
    "x": "jehrwerdiş (n)",
    "y": " zehirlenme"
  },
  {
    "x": "jenerator (n)",
    "y": " jeneratör"
  },
  {
    "x": "jenerîk (n)",
    "y": " jenerik"
  },
  {
    "x": "jenge (m)",
    "y": " b. zenge (m)"
  },
  {
    "x": "jenge bestene",
    "y": " b. zenge bestene"
  },
  {
    "x": "jenosîd (n)",
    "y": " katliam, soykırım"
  },
  {
    "x": "jeodezî (n)",
    "y": " yerölçüm, jeodezi"
  },
  {
    "x": "karnasa/ê karanê îdarî",
    "y": " işletmeci"
  },
  {
    "x": "jeofîzîk (n)",
    "y": " jeofizik"
  },
  {
    "x": "jeolog, -e",
    "y": " jeolog"
  },
  {
    "x": "jeolojî (n)",
    "y": " jeoloji"
  },
  {
    "x": "jeomorfolog, -e",
    "y": " jeomorfolog"
  },
  {
    "x": "jeomorfolojî (n)",
    "y": " jeomorfoloji"
  },
  {
    "x": "jest (n)",
    "y": " jest"
  },
  {
    "x": "jew (n)",
    "y": " b. yew (n)"
  },
  {
    "x": "jew kerdene",
    "y": " b. yew kerdene"
  },
  {
    "x": "jewbîn",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "jewbînî",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "jewbînî kewteni",
    "y": " b. yewbînî kewtene"
  },
  {
    "x": "jewbînîkewtiş (n)",
    "y": " b. yewbînîkewtiş (n)"
  },
  {
    "x": "jewbîyaye (n)",
    "y": " b. yewbîyaye (n)"
  },
  {
    "x": "jewer, -e",
    "y": " b. yewer, -e"
  },
  {
    "x": "jewfek (n)",
    "y": " b. yewfek (n)"
  },
  {
    "x": "jewijîyayîş (n)",
    "y": " b. zewicîyayîş (n)"
  },
  {
    "x": "jewin, -i",
    "y": " b. yewin,-e"
  },
  {
    "x": "jewî (m)",
    "y": " b. yewî-I (m)"
  },
  {
    "x": "jewjîyayîş (n)",
    "y": " b. zewicîyayîş (n)"
  },
  {
    "x": "jewkerdiş (n)",
    "y": " b. yewkerdiş (n)"
  },
  {
    "x": "jewmbîna",
    "y": " b. yewbînan"
  },
  {
    "x": "jewmbînî",
    "y": " b. yewbînî"
  },
  {
    "x": "jewna",
    "y": " b. yewna-I"
  },
  {
    "x": "jewnce (m)",
    "y": " b. zewnce (m)"
  },
  {
    "x": "jey (n, z)",
    "y": " b. ey-I (n, z) "
  },
  {
    "x": "jey ra",
    "y": " b. ey ra"
  },
  {
    "x": "jê-I",
    "y": " b. sey-I"
  },
  {
    "x": "jê-II (n, z)",
    "y": " b. ey-I (n, z)"
  },
  {
    "x": "jê-III",
    "y": " b. yew, -e"
  },
  {
    "x": "jêç (n)",
    "y": " b. zîç (n)"
  },
  {
    "x": "jêde",
    "y": " b. zîyade"
  },
  {
    "x": "jêdi",
    "y": " b. zîyade"
  },
  {
    "x": "jêdnayene",
    "y": " b. zêdnayene"
  },
  {
    "x": "jêhat, -e",
    "y": " b. jêhatî, -ye"
  },
  {
    "x": "jêhatî, -ye",
    "y": " becerikli"
  },
  {
    "x": "jêhatîye (m)",
    "y": " beceriklilik"
  },
  {
    "x": "jêmistan (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "jên (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "jênî (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "jije (n)",
    "y": " b. jûje (n), dije (n)"
  },
  {
    "x": "jimêlî (zh)",
    "y": " b. zimbêlî (zh)"
  },
  {
    "x": "jiney (n)",
    "y": " b. yewna-I"
  },
  {
    "x": "jinî (zh, z)",
    "y": " b. înan (zh, z) "
  },
  {
    "x": "jirç (n)",
    "y": " b. zîrç (n)"
  },
  {
    "x": "jîrç kerdene",
    "y": " b. zîrç kerdene"
  },
  {
    "x": "jiwa",
    "y": " b. ziwa, -ye"
  },
  {
    "x": "jiwa bîyayene",
    "y": " b. ziwa bîyene"
  },
  {
    "x": "jiwa kerdene",
    "y": " b. ziwa kerdene"
  },
  {
    "x": "jiwabîyayîş (n)",
    "y": " b. ziwabîyayîş (n)"
  },
  {
    "x": "jiwakerdiş (n)",
    "y": " b. ziwakerdiş (n)"
  },
  {
    "x": "jiwayîye (m)",
    "y": " b. ziwayîye (m), ziwayî (m)"
  },
  {
    "x": "jiwer, -e",
    "y": " b. yewer, -e"
  },
  {
    "x": "jî-I (zr)",
    "y": " b. zî (zr)"
  },
  {
    "x": "jî-II",
    "y": " b. sey-I"
  },
  {
    "x": "jî-III",
    "y": " b. yew, -e"
  },
  {
    "x": "jîa (n)",
    "y": " b. ziwa (n)"
  },
  {
    "x": "jîan (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "jîgolo (n)",
    "y": " jigolo"
  },
  {
    "x": "jîhat, -e",
    "y": " b. jêhatî, -ye"
  },
  {
    "x": "jîlete (m)",
    "y": " b. cîlet (n)"
  },
  {
    "x": "jîmêlî (zh)",
    "y": " b. zimbêlî (zh)"
  },
  {
    "x": "jîmnastîk (n)",
    "y": " jimnastik, idman"
  },
  {
    "x": "jîmnastîkker, -e",
    "y": " jimnastikçi"
  },
  {
    "x": "jîn (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "jîndan (n)",
    "y": " b. zîndan (n)"
  },
  {
    "x": "jîne (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "jînekolog, -e",
    "y": " jinekolog. nisaiyeci"
  },
  {
    "x": "jînekolojî (n)",
    "y": " jinekoloji, nisaiye"
  },
  {
    "x": "jînî (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "jîr, -e",
    "y": " 1)zeki\r\n~2)çokbilmiş"
  },
  {
    "x": "jîrç (n)",
    "y": " b. zîç (n)"
  },
  {
    "x": "jîvayene",
    "y": " inlemek"
  },
  {
    "x": "jîvayêne",
    "y": " b. jîvayene"
  },
  {
    "x": "jîvayîş (n)",
    "y": " inleme"
  },
  {
    "x": "jîyan (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "jîyat (n)",
    "y": " b. jêhatî (n)"
  },
  {
    "x": "jîyatî",
    "y": " b. jêhatî, -ye"
  },
  {
    "x": "jo-I (n)",
    "y": " b. jan-I (n)"
  },
  {
    "x": "jo-II",
    "y": " b. yew"
  },
  {
    "x": "joa (n)",
    "y": " b. ziwa (n)"
  },
  {
    "x": "jobîn",
    "y": " b. yewbînî"
  },
  {
    "x": "jobînan",
    "y": " b. yewbînan"
  },
  {
    "x": "jobînan dîyayene",
    "y": " b. yewbînan dîyene"
  },
  {
    "x": "jobînandîyayîş (n)",
    "y": " b. yewbînandîyayîş (n)"
  },
  {
    "x": "jobînî",
    "y": " b. yewbînî"
  },
  {
    "x": "jobînî kotene",
    "y": " b. yewbînî kewtene"
  },
  {
    "x": "jobînîkotiş (n)",
    "y": " b. yewbînîkewtiş (n)"
  },
  {
    "x": "jobîyaye (n)",
    "y": " b. yewbîyaye (n) "
  },
  {
    "x": "jobîyayîş (n)",
    "y": " b. yewbîyayîş (n)"
  },
  {
    "x": "jokey, -e",
    "y": " b. cokey, -e"
  },
  {
    "x": "jon (n)",
    "y": " b. jan-I (n)"
  },
  {
    "x": "jon dayene",
    "y": " b. jan dayene"
  },
  {
    "x": "jona",
    "y": " b. yewna-I"
  },
  {
    "x": "jondes",
    "y": " b. yewendes"
  },
  {
    "x": "jondesin, -e",
    "y": " b. yewendesin, -e"
  },
  {
    "x": "joyî (m)",
    "y": " b. yewîye (m), yewî-I (m)"
  },
  {
    "x": "joyîn, -e",
    "y": " b. yewin, -e"
  },
  {
    "x": "ju, -ye",
    "y": " b. yew, -e"
  },
  {
    "x": "ju bîyayene",
    "y": " b. yew bîyayene "
  },
  {
    "x": "ju hete ra",
    "y": " b. yew het ra"
  },
  {
    "x": "ju kerdene",
    "y": " b. yew kerdene"
  },
  {
    "x": "jua (n)",
    "y": " b. ziwa (n)"
  },
  {
    "x": "juan (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "jubîn",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "jubîn de xêr kerdene",
    "y": " b. yewbînî de xeyr kerdene"
  },
  {
    "x": "jubîndexêrkerdiş (n)",
    "y": " b. yewbînîdexeyrkerdiş (n)"
  },
  {
    "x": "jubînî",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "jubînî dîyayene",
    "y": " b. yewbînî dîyene"
  },
  {
    "x": "jubînî guretene",
    "y": " b. yewbînî girewtene"
  },
  {
    "x": "jubînî kotene",
    "y": " b. yewbînî kewtene"
  },
  {
    "x": "jubînî ra nayene",
    "y": " b. yewbînî ra nayene"
  },
  {
    "x": "jubînîdîyayîş (n)",
    "y": " b. yewbînîdîyayîş (n)"
  },
  {
    "x": "jubînîguretiş (n)",
    "y": " b. yewbînîgirewtiş (n)"
  },
  {
    "x": "jubînîkotiş (n)",
    "y": " b. yewbînîkewtiş (n)"
  },
  {
    "x": "jubînîranayîş (n)",
    "y": " b. yewbînîranayîş (n)"
  },
  {
    "x": "jubîyaye (n)",
    "y": " b. yewbîyaye (n) "
  },
  {
    "x": "jubîyayene",
    "y": " b. yewbîyayene"
  },
  {
    "x": "jubîyayêne",
    "y": " b. yewbîyayene"
  },
  {
    "x": "jubîyayîş (n)",
    "y": " b. yewbîyayîş (n)"
  },
  {
    "x": "jubîyayîye (m)",
    "y": " b. yewbîyayîye (m)"
  },
  {
    "x": "juje (n)",
    "y": " b. jûje (n)"
  },
  {
    "x": "jukek, -e",
    "y": " b. yewek, -e"
  },
  {
    "x": "jukerdiş (n)",
    "y": " b. yewkerdiş (n)"
  },
  {
    "x": "jumîn",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "jumîn dîyayene",
    "y": " b. yewbînî dîyene"
  },
  {
    "x": "jumîn guretene",
    "y": " b. yewbînî girewtene"
  },
  {
    "x": "jumîn ra nayene",
    "y": " b. yewbînî ra nayene"
  },
  {
    "x": "jumîndîyayîş (n)",
    "y": " b. yewbînîdîyayîş (n)"
  },
  {
    "x": "jumînguretiş (n)",
    "y": " b. yewbînîgirewtiş (n)"
  },
  {
    "x": "jumînî",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "jumînranayîş (n)",
    "y": " b. yewbînîranayîş (n)"
  },
  {
    "x": "juna",
    "y": " b. yewna-I"
  },
  {
    "x": "jupon",
    "y": " jüpon"
  },
  {
    "x": "jur (n)",
    "y": " b. zure (m)"
  },
  {
    "x": "jurî kerdene",
    "y": " b. zurî kerdene"
  },
  {
    "x": "jureker, -e",
    "y": " b. zureker, -e"
  },
  {
    "x": "jurî (n)",
    "y": " jüri"
  },
  {
    "x": "jurîker, -e",
    "y": " b. zureker, -e"
  },
  {
    "x": "jurîkerdiş (n)",
    "y": " b. zurîkerdiş (n)"
  },
  {
    "x": "jut (n)",
    "y": " jüt"
  },
  {
    "x": "jutî (m)",
    "y": " b. yewîye (m), yewî-I (m)"
  },
  {
    "x": "juvîn",
    "y": " b. yewbînî, yewbînan "
  },
  {
    "x": "juvînî",
    "y": " b. yewbînî, yewbînan "
  },
  {
    "x": "juwa, -ye",
    "y": " b. ziwa, -ye"
  },
  {
    "x": "juwan (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "juwandes",
    "y": " b. yewendes "
  },
  {
    "x": "juwer, -e",
    "y": " b. yewer, -e"
  },
  {
    "x": "juwerî (n)",
    "y": " b. yewerî (n)"
  },
  {
    "x": "juwîn",
    "y": " b. yewbînî, yewbînan "
  },
  {
    "x": "juwînî",
    "y": " b. yewbînî, yewbînan "
  },
  {
    "x": "juya, -ye",
    "y": " b. ziwa, -ye"
  },
  {
    "x": "juya bîyayene",
    "y": " b. ziwa bîyene"
  },
  {
    "x": "juya kerdene",
    "y": " b. ziwa kerdene"
  },
  {
    "x": "juyabîyayîş (n)",
    "y": " b. ziwabîyayîş (n)"
  },
  {
    "x": "juyakerdiş (n)",
    "y": " b. ziwakerdiş (n)"
  },
  {
    "x": "juyan (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "juyayîye (m)",
    "y": " b. ziwayîye (m), ziwayî (m)"
  },
  {
    "x": "juye (m)",
    "y": " b. yewe (m)"
  },
  {
    "x": "juyênî (m)",
    "y": " b. yewîye (m), yewî (m)"
  },
  {
    "x": "juyin, -e",
    "y": " b. yewin, -e"
  },
  {
    "x": "juyî (m)",
    "y": " b. yewîye (m), yewî-I (m)"
  },
  {
    "x": "juyîn, -e",
    "y": " b. yewin, -e"
  },
  {
    "x": "juyîye (m)",
    "y": " b. yewîye (m), yewî (m)"
  },
  {
    "x": "jû-I (m)",
    "y": " b. yewe (m)"
  },
  {
    "x": "jû be jû",
    "y": " b. yew bi yew"
  },
  {
    "x": "jû hete ra",
    "y": " b. yew het ra"
  },
  {
    "x": "jû ve jû",
    "y": " b. yew bi yew"
  },
  {
    "x": "jû-II (n)",
    "y": " b. jan-I (n)"
  },
  {
    "x": "jûbîn",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "jûbîna",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "jûbîna dîyayene",
    "y": " b. yewbînan dîyene"
  },
  {
    "x": "jûbînadîyayîş (n)",
    "y": " b. yewbînandîyayîş (n)"
  },
  {
    "x": "jûbînî",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "jûbînî kotene",
    "y": " b. yewbînî kewtene"
  },
  {
    "x": "jûbîyaye (n)",
    "y": " b. yewbîyaye (n) "
  },
  {
    "x": "jûbîyayene",
    "y": " b. yewbîyayene"
  },
  {
    "x": "jûbîyayêne",
    "y": " b. yewbîyayene"
  },
  {
    "x": "jûbîyayîş (n)",
    "y": " b. yewbîyayîş (n)"
  },
  {
    "x": "jûbîyayîye (m)",
    "y": " b. yewbîyayîye (m)"
  },
  {
    "x": "jûfek (n)",
    "y": " b. yewfek (n)"
  },
  {
    "x": "jûfek veng kerdene",
    "y": " b. yewfek veng kerdene"
  },
  {
    "x": "jûje (n)",
    "y": " kirpi"
  },
  {
    "x": "jûmîn",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "jûmînî",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "jûjû (n)",
    "y": " b. jûje (n)"
  },
  {
    "x": "jûn (n)",
    "y": " b. jan-I (n)"
  },
  {
    "x": "jûn dayene",
    "y": " b. jan dayene"
  },
  {
    "x": "jûn dekotene",
    "y": " b. jan dekewtene"
  },
  {
    "x": "jûn kardene",
    "y": " b. jan kerdene"
  },
  {
    "x": "jûn kerdene",
    "y": " b. jan kerdene"
  },
  {
    "x": "jûn vetene",
    "y": " b. jan vetene"
  },
  {
    "x": "jûna",
    "y": " b. yewna-I"
  },
  {
    "x": "jûre (m)",
    "y": " koruk"
  },
  {
    "x": "jûrî (n)",
    "y": " b. jurî (n)"
  },
  {
    "x": "jûtî (m)",
    "y": " b. yewîye (m), yewî-I (m)"
  },
  {
    "x": "jûvin",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "jûvinî",
    "y": " b. yewbînî, yewbînan "
  },
  {
    "x": "jûvîn",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "jûvînî",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "jûvînî kotene",
    "y": " b. yewbînî kewtene"
  },
  {
    "x": "jûvînîkotiş (n)",
    "y": " b. yewbînîkewtiş (n)"
  },
  {
    "x": "jûwer, -e",
    "y": " b. yewer, -e"
  },
  {
    "x": "jûwerî (n)",
    "y": " b. yewerî (n)"
  },
  {
    "x": "jûwîn",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "jûwînî",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "jûye (m)",
    "y": " b. yewe (m)"
  },
  {
    "x": "jûyênî (m)",
    "y": " b. yewîye (m), yewî-I (m)"
  },
  {
    "x": "jûyin, -e",
    "y": " b. yewin, -e"
  },
  {
    "x": "jûyî (m)",
    "y": " b. yewîye (m), yewî-I (m)"
  },
  {
    "x": "jûyîn, -e",
    "y": " b. yewin, -e"
  },
  {
    "x": "KKK"
  },
  {
    "x": "ka",
    "y": " hele\r\n~Ka bê tîya. (Hele buraya gel.)"
  },
  {
    "x": "kab (n)",
    "y": " b. kabe (n)"
  },
  {
    "x": "kaba (m)",
    "y": " b. kabe (n)"
  },
  {
    "x": "kabe (n)",
    "y": " aşık (aşık kemiği)"
  },
  {
    "x": "kabe şakameyîş",
    "y": " aşık kemiğinin ön veya arka tarafı üzerinde dikey biçimde durması"
  },
  {
    "x": "kabe şakkewtiş",
    "y": " aşık kemiğinin ön veya arka tarafı üzerinde dikey biçimde durması   "
  },
  {
    "x": "kabe şakomeyîş",
    "y": " b. kabe şakameyîş"
  },
  {
    "x": "kabe şakûmeyîş",
    "y": " b. kabe şakameyîş"
  },
  {
    "x": "kabe şakweriştiş",
    "y": " aşık kemiğinin ön veya arka tarafı üzerinde dikey biçimde durması"
  },
  {
    "x": "kabe şekhameyîş",
    "y": " b. kabe şakameyîş"
  },
  {
    "x": "kabe şekkotiş",
    "y": " b. kabe şakkewtiş"
  },
  {
    "x": "kabe şekwarîştiş",
    "y": " b. kabe şakweriştiş"
  },
  {
    "x": "kabey ... şakomeyîş",
    "y": " b. kabeyê ... şakameyîş"
  },
  {
    "x": "kabey ... şekhameyîş",
    "y": " b. kabeyê ... şakameyîş"
  },
  {
    "x": "kabeyê ... şakameyîş",
    "y": " şansı yaver gitme (deyim), şanslı olma, talihi yaver gitme"
  },
  {
    "x": "kabeyê ... şakkewtiş",
    "y": " şansı yaver gitme (deyim), şanslı olma, talihi yaver gitme"
  },
  {
    "x": "kabeyê ... şakweriştiş",
    "y": " şansı yaver gitme (deyim), şanslı olma, talihi yaver gitme"
  },
  {
    "x": "kabeyê ... şekkotiş",
    "y": " b. kabeyê ... şakkewtiş"
  },
  {
    "x": "kabeyê ... şekwarîştiş",
    "y": " b. kabeyê ... şakweriştiş"
  },
  {
    "x": "kabê ... şakûmeyîş",
    "y": " b. kabeyê ... şakameyîş"
  },
  {
    "x": "kabekay (n)",
    "y": " aşık kemiği oyunu"
  },
  {
    "x": "kabey (zh)",
    "y": " b. kabeyî (zh)"
  },
  {
    "x": "kabeyî (zh)",
    "y": " aşık kemiği oyunu"
  },
  {
    "x": "kabîn (n)",
    "y": " kabin"
  },
  {
    "x": "kabînê weşî yê şaredarî/beledîya",
    "y": " belediye sağlık kabini"
  },
  {
    "x": "kabîne (m)",
    "y": " bakanlar kurulu, icra vekilleri heyeti"
  },
  {
    "x": "kablik, -e",
    "y": " cüce"
  },
  {
    "x": "kablikî (m)",
    "y": " cücelik"
  },
  {
    "x": "Kabul (n)",
    "y": " Kabil"
  },
  {
    "x": "kabûnî (m)",
    "y": " b. kebanî (m)"
  },
  {
    "x": "kace (m)",
    "y": " b. kaje (m)"
  },
  {
    "x": "kacêre (m)",
    "y": " b. kajêre (m)"
  },
  {
    "x": "kadastro (n)",
    "y": " kadastro "
  },
  {
    "x": "kadraj (n)",
    "y": " kadraj"
  },
  {
    "x": "kadro (n)",
    "y": " kadro"
  },
  {
    "x": "kadroyo norm (n)",
    "y": " norm kadro"
  },
  {
    "x": "kadro, -ye",
    "y": " kadro"
  },
  {
    "x": "kaf (n)",
    "y": " mağara"
  },
  {
    "x": "kafe (m)",
    "y": " b. kefe (m)"
  },
  {
    "x": "kafe û kitabxane",
    "y": " cafe ve kütüphane"
  },
  {
    "x": "kafik (n)",
    "y": " küçük mağara"
  },
  {
    "x": "kafir, -e",
    "y": " dinsiz, kâfir"
  },
  {
    "x": "kafirbab, -e",
    "y": " b. kafirbav, -e"
  },
  {
    "x": "kafirbav, -e",
    "y": " (babası kafir olan) kâfir çocuğu\r\n~“Zirav, Zirav, Ziravê; exşîkê, kafirbavê” (Deyîra Zirave ra)"
  },
  {
    "x": "kafirey (m)",
    "y": " b. kafirîye (m)"
  },
  {
    "x": "kafirê (m)",
    "y": " b. kafirî (m)"
  },
  {
    "x": "kafirên (m)",
    "y": " b. kafirênî (m)"
  },
  {
    "x": "kafirênî (m)",
    "y": " dinsizlik, kâfirlik"
  },
  {
    "x": "kafiri (m)",
    "y": " b. kafire (m)"
  },
  {
    "x": "kafirî (m)",
    "y": " dinsizlik, kâfirlik"
  },
  {
    "x": "kafirîye (m)",
    "y": " dinsizlik, kâfirlik"
  },
  {
    "x": "kafirkî",
    "y": " kâfirce"
  },
  {
    "x": "kafî",
    "y": " kâfi, kifayetli, yeter, yeterli"
  },
  {
    "x": "kafî bîyayene",
    "y": " b. kafî bîyene"
  },
  {
    "x": "kafî bîyene",
    "y": " yetmek, yeterli olmak"
  },
  {
    "x": "kafîbîyayîş (n)",
    "y": " yetme, yeterli olma"
  },
  {
    "x": "kafîyey (m)",
    "y": " b. kafîyîye (m)"
  },
  {
    "x": "kafîyîye (m)",
    "y": " kifayet, yeterlilik, yeterlik"
  },
  {
    "x": "kahan",
    "y": " b. kehen, -e; kan, -e"
  },
  {
    "x": "kahar (m)",
    "y": " b. karî (m)"
  },
  {
    "x": "kahrî (m)",
    "y": " b. karî (m)"
  },
  {
    "x": "kahrîpese (m)",
    "y": " iki yaşındaki dişi keçi yavrusu"
  },
  {
    "x": "kaînat (n)",
    "y": " âlem, cihan, kâinat, evren, kozmos"
  },
  {
    "x": "kaînatşumûl, -e",
    "y": " âlemşümul, cihanşümul, evrensel, üniversal"
  },
  {
    "x": "kaînatşumûlî (m)",
    "y": " âlemşümullük, cihanşümullük, evrensellik"
  },
  {
    "x": "kaînatşumûlîye (m)",
    "y": " âlemşümullük, cihanşümullük, evrensellik"
  },
  {
    "x": "kaj’ mar’ (n)",
    "y": " b. kajê marî (n)"
  },
  {
    "x": "kajê marî (n)",
    "y": " kav (yılan gömleği, yılan derisi)"
  },
  {
    "x": "kaje (m)",
    "y": " çam"
  },
  {
    "x": "kajêre (m)",
    "y": " çam ağacı"
  },
  {
    "x": "kaka (m)",
    "y": " b. keka"
  },
  {
    "x": "kakao (n)",
    "y": " hintbademi, kakao"
  },
  {
    "x": "kakat (m)",
    "y": " b. kakate (m)"
  },
  {
    "x": "kakati (m)",
    "y": " b. kakate (m)"
  },
  {
    "x": "kakate (m)",
    "y": " baş, kafa"
  },
  {
    "x": "kakaz, -e",
    "y": " b. kekej, -e"
  },
  {
    "x": "kakazîye (m)",
    "y": " b. kekejîye (m)"
  },
  {
    "x": "kakil (n)",
    "y": " b. kaykil (n)"
  },
  {
    "x": "kakile (m)",
    "y": " 1)sert kabuğuyla birlikte ceviz\r\n~2)bütün halindeki ceviz içi"
  },
  {
    "x": "kakila atome (m)",
    "y": " atom çekirdeği"
  },
  {
    "x": "kakila goze (m)",
    "y": " çiğe"
  },
  {
    "x": "kakuç (n)",
    "y": " b. çakuç (n)"
  },
  {
    "x": "kakut (m)",
    "y": " b. kakate (m)"
  },
  {
    "x": "kal-I (n)",
    "y": " 1)yaşlı, ihtiyar\r\n~2)dede, büyükbaba\r\n~3)ata\r\n~4)Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında oynanan geleneksel, teatral eğlence oyununda koca (erkek eş) rolündeki kişi \r\n~5)b. kalo (n)"
  },
  {
    "x": "kal’ gaxûn’ (n)",
    "y": " b. kalê gaxanî (n)"
  },
  {
    "x": "kal kay kerdene",
    "y": " Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında geleneksel, teatral “koseyî”/“kalo” oyunu oynamak"
  },
  {
    "x": "kal xemelnayene",
    "y": " b. kal xemilnayene"
  },
  {
    "x": "kal xemilnayene",
    "y": " Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında geleneksel, teatral “koseyî”/“kalo” oyunu için “kose”/“kalo”/“kalik” denilen erkek eş rolündeki kişiyi süslemek"
  },
  {
    "x": "kalê gaxanî (n)",
    "y": " 1)Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında oynanan geleneksel, teatral bir eğlence oyunu"
  },
  {
    "x": "kalo",
    "y": " dede (hitap hali)"
  },
  {
    "x": "kal, -e-II",
    "y": " ham, olmamış"
  },
  {
    "x": "kal-fatike",
    "y": " b. kalûfatike"
  },
  {
    "x": "kalan (n)",
    "y": " tarla, bostan vb. yerlerdeki yabani otları ayıklama işi"
  },
  {
    "x": "kalan kerdene",
    "y": " tarla, bostan vb. yerlerdeki yabani otları ayıklamak"
  },
  {
    "x": "kalanî (m)",
    "y": " kın"
  },
  {
    "x": "kalanî kerdene",
    "y": " b. kalan kerdene"
  },
  {
    "x": "kalanîkerdiş (n)",
    "y": " b. kalankerdiş (n)"
  },
  {
    "x": "kalankerdiş (n)",
    "y": " tarla, bostan vb. yerlerdeki yabani otları ayıklama"
  },
  {
    "x": "kalaşnîkof (n)",
    "y": " bir tüfek çeşidi"
  },
  {
    "x": "kalaw (m)",
    "y": " b. kilawe (m)"
  },
  {
    "x": "kalawi (m)",
    "y": " b. kilawe (m)"
  },
  {
    "x": "kalayene",
    "y": " melemek (koyun ve keçi için)"
  },
  {
    "x": "kalayîş (n)",
    "y": " meleme (koyun ve keçi için)"
  },
  {
    "x": "kalbestan (n)",
    "y": " b. qebrîstan (n)"
  },
  {
    "x": "Kaledonyaya Newîye (m)",
    "y": " Yeni Kaledonya"
  },
  {
    "x": "kalek-I (n)",
    "y": " 1)b. kalik (n)\r\n~2)b. kal-I (n)\r\n~3)b. kalo (n)"
  },
  {
    "x": "kalek kay kerdene",
    "y": " b. kal kay kerdene"
  },
  {
    "x": "kalek-II (m)",
    "y": " b. kaleke (m)"
  },
  {
    "x": "kaleke (m)",
    "y": " 1)böğür (boş böğür)\r\n~2)yan"
  },
  {
    "x": "kaleki (m)",
    "y": " b. kaleke (m)"
  },
  {
    "x": "kalenî (m)",
    "y": " b. kalanî (m)"
  },
  {
    "x": "kaley (m)",
    "y": " b. kalî (m)"
  },
  {
    "x": "kalê (m)",
    "y": " b. kalî (m)"
  },
  {
    "x": "kalênî (m)",
    "y": " yaşlılık, ihtiyarlık"
  },
  {
    "x": "kali (m)",
    "y": " b. kale (m)"
  },
  {
    "x": "kaliç (n)",
    "y": " b. kalik (n)"
  },
  {
    "x": "kalik (n)",
    "y": " 1)dede, büyükbaba\r\n~2)ata\r\n~3)Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında oynanan geleneksel, teatral eğlence oyununda koca (erkek eş) rolündeki kişi"
  },
  {
    "x": "kalik û pîrik",
    "y": " ata(lar), ecdat"
  },
  {
    "x": "kalî (m)",
    "y": " yaşlılık, ihtiyarlık"
  },
  {
    "x": "kalîbraj (n)",
    "y": " kalibraj"
  },
  {
    "x": "kalîbre (n)",
    "y": " kalibre"
  },
  {
    "x": "kalîtatîf, -e",
    "y": " nitel"
  },
  {
    "x": "kalîte (m)",
    "y": " nitelik"
  },
  {
    "x": "kalîye (m)",
    "y": " yaşlılık, ihtiyarlık"
  },
  {
    "x": "Kalkolîtîk (n)",
    "y": " Bakır Çağı, Bakır Devri (M. Ö. 5500-3300)"
  },
  {
    "x": "kalme (n)",
    "y": " 1)kılıç\r\n~2)b. kelame (n)"
  },
  {
    "x": "kalmi (n)",
    "y": " b. kalme (n)"
  },
  {
    "x": "kalnî (m)",
    "y": " b. kalanî (m)"
  },
  {
    "x": "kalo (n)",
    "y": " 1)Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında oynanan geleneksel, teatral bir eğlence oyunu\r\n~2)b. kal-I (n)"
  },
  {
    "x": "kalo kay kerdene",
    "y": " b. kal kay kerdene"
  },
  {
    "x": "kalolî (n)",
    "y": " b. karyola (m)"
  },
  {
    "x": "kalon (n)",
    "y": " b. kalan (n)"
  },
  {
    "x": "kalon kerdeni",
    "y": " b. kalan kerdene"
  },
  {
    "x": "kalonî (m)",
    "y": " b. kalanî (m)"
  },
  {
    "x": "kalorîfer (n)",
    "y": " kalorifer"
  },
  {
    "x": "kalorîfero elektrîkin (n)",
    "y": " elektrikli kalorifer"
  },
  {
    "x": "kalorîfer (m)",
    "y": " b. kalorîfer (n)"
  },
  {
    "x": "kalorîferkar, -e",
    "y": " kaloriferci"
  },
  {
    "x": "kalorîfersaz, -e",
    "y": " kaloriferci"
  },
  {
    "x": "kalorîmetre (n)",
    "y": " ısıölçer, kalorimetre"
  },
  {
    "x": "kalorîmetrî (n)",
    "y": " ısı ölçümü, kalorimetri"
  },
  {
    "x": "kalueli (n)",
    "y": " b. karyola (m)"
  },
  {
    "x": "kalûfatike",
    "y": " Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında oynanan geleneksel, teatral bir eğlence oyunu"
  },
  {
    "x": "kalule (n)",
    "y": " b. karyola (m)"
  },
  {
    "x": "kalû-mal",
    "y": " b. kel-mel"
  },
  {
    "x": "kalûn kerdene (DG)",
    "y": " b. kalan kerdene"
  },
  {
    "x": "kalûnkerdiş (n)",
    "y": " b. kalankerdiş (n)"
  },
  {
    "x": "kam, -e",
    "y": " 1)kim\r\n~Kam vano? (Kim söylüyor?) \r\n~2)hangi\r\n~Kam kitab yê to yo? (Hangi kitap senin?)"
  },
  {
    "x": "kam game",
    "y": " ne zaman, ne vakit\r\n~Ti kam game wazenî ma a game şêrî. (Ne zaman istiyorsan o zaman gidelim.)"
  },
  {
    "x": "kam hele",
    "y": " ne zaman, ne vakit"
  },
  {
    "x": "kam hili",
    "y": " b. kam hele"
  },
  {
    "x": "kam huli",
    "y": " b. kam hele"
  },
  {
    "x": "kamî",
    "y": " kim\r\n~Kamî vato? (Kim söylemiş?)"
  },
  {
    "x": "Kamboçya (m)",
    "y": " Kamboçya"
  },
  {
    "x": "kamca",
    "y": " nere\r\n~Ti kamca ra yî/ya? (Nerelisin?) "
  },
  {
    "x": "kamcî",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "kamcîn, -e",
    "y": " hangi, hangisi\r\n~Kamcîn yê to yo? (Hangisi senin?)\r\n~\t\r\n~Kamcîn kitab yê to yo? (Hangi kitap senin?)"
  },
  {
    "x": "kamco",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "kamcû",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "kamder, -e",
    "y": " hangisi\r\n~Kamder yê to yo? (Hangisi senin?)"
  },
  {
    "x": "kamecî",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "kameco",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "kamera (m)",
    "y": " kamera"
  },
  {
    "x": "kameraya dîyine (sn)",
    "y": " ikinci kamera"
  },
  {
    "x": "kameraya muşahide (sn)",
    "y": " gözlemci kamera"
  },
  {
    "x": "kameraya observatore (sn)",
    "y": " gözlemci kamera"
  },
  {
    "x": "kameraya sabîte (sn)",
    "y": " sabit kamera"
  },
  {
    "x": "kameraya termale (m)",
    "y": " termal kamera"
  },
  {
    "x": "kameraya vindeta/vindetîye (sn)",
    "y": " sabit kamera"
  },
  {
    "x": "kameraman, -e",
    "y": " kameraman"
  },
  {
    "x": "Kamerûn (n)",
    "y": " Kamerun "
  },
  {
    "x": "kami",
    "y": " b. kame"
  },
  {
    "x": "kamic",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "kamij",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "kamijî",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "kamik, -e",
    "y": " 1)hangisi\r\n~Kamik yê to yo? (Hangisi senin?) \r\n~2)kim"
  },
  {
    "x": "kamî",
    "y": " kim (bükünlü durumda)\r\n~Kamî vato? (Kim söylemiş?)"
  },
  {
    "x": "kamîcî",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "kamîj",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "kamji",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "kamjî",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "kamkes, -e",
    "y": " kim"
  },
  {
    "x": "kamon (n)",
    "y": " b. kamyone (m)"
  },
  {
    "x": "kamone (m)",
    "y": " b. kamyone (m)"
  },
  {
    "x": "kamos (n)",
    "y": " süzgeç"
  },
  {
    "x": "Kampala (m)",
    "y": " Kampala"
  },
  {
    "x": "kampanya (m)",
    "y": " kampanya"
  },
  {
    "x": "kamyon (n)",
    "y": " b. kamyone (m)"
  },
  {
    "x": "kamyon (m)",
    "y": " b. kamyone (m)"
  },
  {
    "x": "kamyondar, -e",
    "y": " kamyoncu"
  },
  {
    "x": "kamyone (m)",
    "y": " kamyon"
  },
  {
    "x": "kamyoneke (m)",
    "y": " kamyonet"
  },
  {
    "x": "kamyonete (m)",
    "y": " kamyonet"
  },
  {
    "x": "kamyonkar, -e",
    "y": " kamyoncu"
  },
  {
    "x": "kan, -e",
    "y": " eski; arkaik"
  },
  {
    "x": "kan bîyayene",
    "y": " b. kan bîyene"
  },
  {
    "x": "kan bîyene",
    "y": " çağı geçmek, eskimek"
  },
  {
    "x": "kan kerdene",
    "y": " eskitmek"
  },
  {
    "x": "kan û kun",
    "y": " eski püskü"
  },
  {
    "x": "kan-kort",
    "y": " eski püskü"
  },
  {
    "x": "Kanada (m)",
    "y": " Kanada"
  },
  {
    "x": "kanal (n)",
    "y": " kanal"
  },
  {
    "x": "kanalê awe (n)",
    "y": " su kanalı"
  },
  {
    "x": "kanalê awedane (n)",
    "y": " sulama kanalı"
  },
  {
    "x": "kanalê awedayîşî (n)",
    "y": " sulama kanalı"
  },
  {
    "x": "kanalê deşarjî (n)",
    "y": " deşarj kanalı"
  },
  {
    "x": "kanalo kiriştox (n)",
    "y": " taşıyıcı kanal"
  },
  {
    "x": "kanalo sereke (n)",
    "y": " ana kanal"
  },
  {
    "x": "kanalîzasyon (n)",
    "y": " kanalizasyon"
  },
  {
    "x": "kanalîze kerdene",
    "y": " yönlendirmek, kanalize etmek\r\n~Wendekarî uca ra kanalîze kerdî. (Öğrencileri oradan kanalize etti/ Öğrencileri oradan yönlendirdi.)"
  },
  {
    "x": "kanalîzekerdiş (n)",
    "y": " yönlendirme, kanalize etme, oryantasyon"
  },
  {
    "x": "kanarya (m)",
    "y": " kanarya"
  },
  {
    "x": "Kanberra (m)",
    "y": " Canberra"
  },
  {
    "x": "kanbîyayîş (n)",
    "y": " eskime"
  },
  {
    "x": "kanca",
    "y": " b. kamca"
  },
  {
    "x": "kancî",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "kancîn, -e",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "kançik (n)",
    "y": " küf"
  },
  {
    "x": "kançikî bîyene",
    "y": " küflenmek"
  },
  {
    "x": "kançikî kerdene",
    "y": " küflendirmek"
  },
  {
    "x": "kançikin, -e",
    "y": " küflü"
  },
  {
    "x": "kançikîbîyayîş (n)",
    "y": " küflenme"
  },
  {
    "x": "kançikîkerdiş (n)",
    "y": " küflendirme"
  },
  {
    "x": "kançikîyayene",
    "y": " küflenmek"
  },
  {
    "x": "kançikîyayîş (n)",
    "y": " küflenme"
  },
  {
    "x": "kançiknayene",
    "y": " küflendirmek"
  },
  {
    "x": "kançiknayîş (n)",
    "y": " küflendirme"
  },
  {
    "x": "kançiko bîyayene",
    "y": " b. kançikî bîyene"
  },
  {
    "x": "kançiko kerdene",
    "y": " b. kançikî kerdene"
  },
  {
    "x": "kançikobîyayîş (n)",
    "y": " b. kançikîbîyayîş (n) "
  },
  {
    "x": "kançikokerdiş (n)",
    "y": " b. kançikîkerdiş (n)"
  },
  {
    "x": "Kane (m)",
    "y": " inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "kaney (m)",
    "y": " b. kanî (m)"
  },
  {
    "x": "kanguru, -ye",
    "y": " kanguru"
  },
  {
    "x": "kanî (m)",
    "y": " eskilik"
  },
  {
    "x": "Kanîreş (n)",
    "y": " Karlıova"
  },
  {
    "x": "kankerdiş (n)",
    "y": " eskitme"
  },
  {
    "x": "kankil (n)",
    "y": " b. kakile (m)"
  },
  {
    "x": "kankile (m)",
    "y": " b. kakile (m)"
  },
  {
    "x": "kankili (m)",
    "y": " b. kakile (m)"
  },
  {
    "x": "kankolik (n)",
    "y": " bukle"
  },
  {
    "x": "kankolikin, -e",
    "y": " bukleli"
  },
  {
    "x": "kanperest, -e",
    "y": " gerici"
  },
  {
    "x": "kanroş, -e",
    "y": " eskici"
  },
  {
    "x": "kanser (n)",
    "y": " kanser"
  },
  {
    "x": "kanserker, -e",
    "y": " kanser yapıcı"
  },
  {
    "x": "kanserojen, -e",
    "y": " kanser yapıcı"
  },
  {
    "x": "kanta",
    "y": " b. kotî"
  },
  {
    "x": "kantîtatîf, -e",
    "y": " nicel"
  },
  {
    "x": "kantîte (m)",
    "y": " nicelik"
  },
  {
    "x": "kanun (m)",
    "y": " b. kanûne (m)"
  },
  {
    "x": "kanûn (m)",
    "y": " b. kanûne (m)"
  },
  {
    "x": "kanûne (m)",
    "y": " aralık"
  },
  {
    "x": "kanûni (m)",
    "y": " b. kanûne (m)"
  },
  {
    "x": "kanwaz, -e",
    "y": " gerici"
  },
  {
    "x": "kap (n)",
    "y": " b. kabe (n)"
  },
  {
    "x": "kapasîte (m)",
    "y": " kapasite"
  },
  {
    "x": "kapasîteya asîmîlasyonî (m)",
    "y": " özümleme kapasitesi"
  },
  {
    "x": "kapasîteya kome (m)",
    "y": " toplam kapasite"
  },
  {
    "x": "kapçike (m)",
    "y": " bilek"
  },
  {
    "x": "kapçika destî (m)",
    "y": " el bileği"
  },
  {
    "x": "kapçika linge (m)",
    "y": " ayak bileği"
  },
  {
    "x": "kapçike de çewtîye (m)",
    "y": " ayak bileğinde eğrilik"
  },
  {
    "x": "kapoke (n)",
    "y": " b. kabe (n)"
  },
  {
    "x": "kaporta (m)",
    "y": " kaporta "
  },
  {
    "x": "kaportasaz, -e",
    "y": " kaportacı"
  },
  {
    "x": "kaptan, -e",
    "y": " kaptan"
  },
  {
    "x": "kapuska (m)",
    "y": " kapuska"
  },
  {
    "x": "kapuşone (m)",
    "y": " kapüşon"
  },
  {
    "x": "kar-I (n)",
    "y": " 1)iş, meslek\r\n~2)fiil, eylem\r\n~3)kâr, kazanç, yarar, fayda; çıkar, menfaat"
  },
  {
    "x": "kar de cabedilnayîş",
    "y": " iş rotasyonu"
  },
  {
    "x": "kar de cavurnayîş",
    "y": " iş rotasyonu"
  },
  {
    "x": "kar kerdene",
    "y": " 1)çalışmak, iş yapmak\r\n~2)kazanmak\r\n~3)etkilemek\r\n~Halê ey zaf pêrîşan bî, mi de zaf kar kerd. (Durumu çok perişandı, beni çok etkiledi.)"
  },
  {
    "x": "kar kewtene",
    "y": " oluşmak\r\n~Nêweşîye kar kewte. (Hastalık oluştu.)"
  },
  {
    "x": "kar ra dûrî fîştene/vistene",
    "y": " açığa almak"
  },
  {
    "x": "kar ra eştene",
    "y": " işine son vermek"
  },
  {
    "x": "kar ra gîrîyayene",
    "y": " açığa alınmak"
  },
  {
    "x": "kar ra vetene",
    "y": " işten çıkarmak"
  },
  {
    "x": "karê aboneyîya awe",
    "y": " su abone işleri"
  },
  {
    "x": "karê awe",
    "y": " su işleri"
  },
  {
    "x": "karê domanan (n)",
    "y": " çocuk işi"
  },
  {
    "x": "karê doşirmeyî (n)",
    "y": " tefriş"
  },
  {
    "x": "karê mefrûşatî (n)",
    "y": " tefriş"
  },
  {
    "x": "karê gedeyan (n)",
    "y": " çocuk işi"
  },
  {
    "x": "karê nuşteyan (zh)",
    "y": " yazı işleri"
  },
  {
    "x": "karê nuştişî (n)",
    "y": " anlatım, anlatış"
  },
  {
    "x": "karê pêroyî (n)",
    "y": " kamu yararı, amme menfaati"
  },
  {
    "x": "karê qeçekan (n)",
    "y": " çocuk işi"
  },
  {
    "x": "karê qican (n)",
    "y": " çocuk işi"
  },
  {
    "x": "karê umûmîya (n)",
    "y": " kamu yararı, amme menfaati"
  },
  {
    "x": "karî rê girewtiş",
    "y": " işe alma"
  },
  {
    "x": "karo aktîf (n)",
    "y": " aktif fiil"
  },
  {
    "x": "karo asarên (n)",
    "y": " öylesi iş"
  },
  {
    "x": "karo henên (n)",
    "y": " öylesi iş"
  },
  {
    "x": "karo inasarên (n)",
    "y": " böylesi iş"
  },
  {
    "x": "karê vatişî (n)",
    "y": " anlatım, anlatış"
  },
  {
    "x": "karo winasî (n)",
    "y": " böylesi iş"
  },
  {
    "x": "karo aktîf (n)",
    "y": " etken fiil"
  },
  {
    "x": "karo ardimkerdox (n)",
    "y": " yardımcı fiil"
  },
  {
    "x": "karo bêqayde (n)",
    "y": " kuralsız fiil"
  },
  {
    "x": "karo giran (n)",
    "y": " ağır iş"
  },
  {
    "x": "karo hetkar (n)",
    "y": " yardımcı fiil"
  },
  {
    "x": "karo întransîtîf (n)",
    "y": " geçişsiz fiil"
  },
  {
    "x": "karo passîf (n)",
    "y": " edilgen fiil, pasif fiil"
  },
  {
    "x": "karo pêrabeste (n)",
    "y": " bileşik fiil"
  },
  {
    "x": "karo qaydeyin (n)",
    "y": " kurallı fiil"
  },
  {
    "x": "karo sade (n)",
    "y": " basit fiil"
  },
  {
    "x": "karo transîtîf (n)",
    "y": " geçişli fiil"
  },
  {
    "x": "karo viraşte (n)",
    "y": " türemiş fiil"
  },
  {
    "x": "kara xo ca verdayene",
    "y": " b. karê xo ca verdayene"
  },
  {
    "x": "kara xo de qaytkerdene",
    "y": " b. karê xo de qayîtkerdene"
  },
  {
    "x": "kara xo ra texelîyayene",
    "y": " b. karê xo ra texelîyayene"
  },
  {
    "x": "karê xo ca verdayene",
    "y": " çıkarını tepmek"
  },
  {
    "x": "karê xo de qayîtkerdene",
    "y": " çı karına bakmak, menfaatine bakmak, yararına bakmak"
  },
  {
    "x": "karê xo ewnîyayene",
    "y": " çı karına bakmak, menfaatine bakmak, yararına bakmak"
  },
  {
    "x": "karê xo ra texelîyayene",
    "y": " çıkarını tepmek"
  },
  {
    "x": "karo neheq (n)",
    "y": " haksız kazanç"
  },
  {
    "x": "kar-II (m)",
    "y": " b. karî (m)"
  },
  {
    "x": "kara (m)",
    "y": " b. kerra (m)"
  },
  {
    "x": "karay salmî (m)",
    "y": " b. kerraya salmeyî (m)"
  },
  {
    "x": "karay salmî eştene",
    "y": " b. kerraya salmeyî eştene"
  },
  {
    "x": "Karakas (n)",
    "y": " Caracas"
  },
  {
    "x": "karakter (n)",
    "y": " karakter"
  },
  {
    "x": "karamela (m)",
    "y": " karamela"
  },
  {
    "x": "karantîna (m)",
    "y": " karantina"
  },
  {
    "x": "karaz (n)",
    "y": " küp (büyük küp) "
  },
  {
    "x": "Karaz",
    "y": " Kocaköy "
  },
  {
    "x": "karban (n)",
    "y": " b. karwan (n) "
  },
  {
    "x": "karbançî",
    "y": " b. karwançî, -ye"
  },
  {
    "x": "karbançîyey (m)",
    "y": " b. karwançîyîye (m)"
  },
  {
    "x": "karbiz (m)",
    "y": " b. kahrîpese (m)"
  },
  {
    "x": "karbize (m)",
    "y": " b. kahrîpese (m)"
  },
  {
    "x": "karbon (n)",
    "y": " karbon"
  },
  {
    "x": "karbonmonoksît (n)",
    "y": " karbonmonoksit"
  },
  {
    "x": "karce (n)",
    "y": " b. kerje (n)"
  },
  {
    "x": "kard (m)",
    "y": " b. kardî (m)"
  },
  {
    "x": "karda (m)",
    "y": " b. kerda (m)"
  },
  {
    "x": "kardar, -e-I",
    "y": " faydalı, yararlı"
  },
  {
    "x": "kardar-II (n)",
    "y": " yüklem"
  },
  {
    "x": "kardayox, -e",
    "y": " çalıştıran, işveren, patron"
  },
  {
    "x": "karde (n)",
    "y": " b. kerde (n)"
  },
  {
    "x": "kardene",
    "y": " b. kerdene"
  },
  {
    "x": "kardiş (n)",
    "y": " b. kerdiş (n)"
  },
  {
    "x": "kardî (m)",
    "y": " bıçak, çakı"
  },
  {
    "x": "kardî kerdene",
    "y": " bıçaklamak"
  },
  {
    "x": "kardîya alafranga (m)",
    "y": " iki tarafı kesen bıçak"
  },
  {
    "x": "kardîya birrekine (m)",
    "y": " kesici tarafı testere gibi dişli olan bıçak"
  },
  {
    "x": "kardîya birrikine (m)",
    "y": " b. kardîya birrekine (m)"
  },
  {
    "x": "kardîya çerkeze (m)",
    "y": " bir çeşit kama"
  },
  {
    "x": "kardîya difeke (m)",
    "y": " iki tarafı kesen bıçak"
  },
  {
    "x": "kardîya elefiranxa (m)",
    "y": " b. kardîya alafranga (m)"
  },
  {
    "x": "kardîya fêkîyî (m)",
    "y": " meyve bıçağı"
  },
  {
    "x": "kardîya kalanî (m)",
    "y": " 1)ekmek bıçağı\r\n~2)kama"
  },
  {
    "x": "kardîya kalenî (m)",
    "y": " b. kardîya kalanî (m)"
  },
  {
    "x": "kardîya kalnî (m)",
    "y": " b. kardîya kalanî (m)"
  },
  {
    "x": "kardîya kalonî (m)",
    "y": " b. kardîya kalanî (m)"
  },
  {
    "x": "kardîya meyweyî (m)",
    "y": " meyve bıçağı"
  },
  {
    "x": "kardîya nanî (m)",
    "y": " ekmek bıçağı"
  },
  {
    "x": "kardîya qije (m)",
    "y": " küçük bıçak"
  },
  {
    "x": "kardîya verrokerdişî (m)",
    "y": " kama"
  },
  {
    "x": "kardîya xoverrokerdişî (m)",
    "y": " kama"
  },
  {
    "x": "kardîkerdiş (n)",
    "y": " bıçaklama"
  },
  {
    "x": "kardînal (n)",
    "y": " kardinal"
  },
  {
    "x": "kardîroş, -e",
    "y": " bıçakçı"
  },
  {
    "x": "kardîviraştox, -e",
    "y": " bıçakçı"
  },
  {
    "x": "kardîyek (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "kardîyolog, -e",
    "y": " kardiyolog"
  },
  {
    "x": "kardîyolojî (n)",
    "y": " kardiyoloji"
  },
  {
    "x": "kardox, -i",
    "y": " b. kerdox, -e"
  },
  {
    "x": "kardû (n)",
    "y": " yabanpancarı, yabaniıspanak, pazı "
  },
  {
    "x": "kare-I (n)",
    "y": " kare "
  },
  {
    "x": "kare-II (n)",
    "y": " b. kuware (n)"
  },
  {
    "x": "kare-III (m)",
    "y": " b. kar (n)-3"
  },
  {
    "x": "kare-IV (m)",
    "y": " b. karî (m)"
  },
  {
    "x": "kareba (n)",
    "y": " b. kehreba (n)"
  },
  {
    "x": "karge (m)",
    "y": " b. kerge (m)"
  },
  {
    "x": "karga sîyay (m)",
    "y": " b. kerga sîyaye (m)"
  },
  {
    "x": "kargeh (n)",
    "y": " atölye, atelye, işlik"
  },
  {
    "x": "kargehê camî (n)",
    "y": " cam atölyesi"
  },
  {
    "x": "kargehê lewhayanê trafîkî (n)",
    "y": " trafik levha atölyesi"
  },
  {
    "x": "kargehê sînema (n)",
    "y": " sinema atölyesi"
  },
  {
    "x": "kargehê tamîrî (n)",
    "y": " tamir atölyesi"
  },
  {
    "x": "kargi (m)",
    "y": " b. kerge (m)"
  },
  {
    "x": "karga sîyay (m)",
    "y": " b. kerga sîyaye (m)"
  },
  {
    "x": "kargîr, -e",
    "y": " kârgir, kâgir "
  },
  {
    "x": "kari (n)",
    "y": " b. kuware (n)"
  },
  {
    "x": "karitene",
    "y": " 1)dikmek (ağaç vb. için)\r\n~2)ekmek, tohum ekmek (sebze ve meyve için)\r\n~Cityerî/citkarî cew karito. (Çiftçi arpa ekmiş.)"
  },
  {
    "x": "kariteni",
    "y": " b. karitene"
  },
  {
    "x": "karitiş (n)",
    "y": " 1)ekme, ekiş, ekim\r\n~2)dikme"
  },
  {
    "x": "karî (m)",
    "y": " bir-iki yaşındaki dişi keçi yavrusu"
  },
  {
    "x": "karîbane (m)",
    "y": " b. kehreba (n)"
  },
  {
    "x": "karîd (m)",
    "y": " b. kardî (m)"
  },
  {
    "x": "karîdes (n)",
    "y": " karides"
  },
  {
    "x": "karîkature (m)",
    "y": " karikatür"
  },
  {
    "x": "karîkaturîst, -e",
    "y": " karikatürist"
  },
  {
    "x": "karîkaturkêş, -e",
    "y": " karikatürist"
  },
  {
    "x": "karîyayene",
    "y": " ekilmek\r\n~Hêga key karîya? (Tarla ne zaman ekildi?)"
  },
  {
    "x": "karîyayeni",
    "y": " b. karîyayene"
  },
  {
    "x": "karîyer (n)",
    "y": " kariyer"
  },
  {
    "x": "karkas (n)",
    "y": " karkas (et için)"
  },
  {
    "x": "karker, -e",
    "y": " işçi"
  },
  {
    "x": "karkera madenî (m)",
    "y": " maden işçisi"
  },
  {
    "x": "karkera mewsimana (m)",
    "y": " mevsimlik işçi"
  },
  {
    "x": "karkera mewsimanîye (m)",
    "y": " mevsimlik işçi"
  },
  {
    "x": "karkera pakî (m)",
    "y": " çöpçü, temizlik işçisi"
  },
  {
    "x": "karkera pakîye (m)",
    "y": " çöpçü, temizlik işçisi"
  },
  {
    "x": "karkera rojaneyî (m)",
    "y": " gündelikçi"
  },
  {
    "x": "karkera seksî",
    "y": " seks işçisi"
  },
  {
    "x": "karkera zîretî (m)",
    "y": " tarım işçisi"
  },
  {
    "x": "karkerê madenî (n)",
    "y": " maden işçisi"
  },
  {
    "x": "karkerê pakî (n)",
    "y": " çöpçü, temizlik işçisi"
  },
  {
    "x": "karkerê pakîye (m)",
    "y": " çöpçü, temizlik işçisi"
  },
  {
    "x": "karkerê rojaneyî (n)",
    "y": " gündelikçi"
  },
  {
    "x": "karkerê seksî",
    "y": " seks işçisi"
  },
  {
    "x": "karkerê zîretî (n)",
    "y": " tarım işçisi"
  },
  {
    "x": "karkero mewsimane (n)",
    "y": " mevsimlik işçi"
  },
  {
    "x": "karkerd (n)",
    "y": " fonksiyon, işlev"
  },
  {
    "x": "karkerdiş (n)",
    "y": " çalışma, mesai"
  },
  {
    "x": "karkerdî, -ye",
    "y": " işlevsel"
  },
  {
    "x": "karkerey (m)",
    "y": " b. karkerîye (m), karkerî (m)"
  },
  {
    "x": "karkerê (m)",
    "y": " b. karkerîye (m), karkerî (m)"
  },
  {
    "x": "karkerênî (m)",
    "y": " işçilik"
  },
  {
    "x": "karkerî (m)",
    "y": " işçilik"
  },
  {
    "x": "karkerîye (m)",
    "y": " işçilik"
  },
  {
    "x": "karkewtiş (n)",
    "y": " işe girme"
  },
  {
    "x": "karm (n)",
    "y": " b. kerm (n)"
  },
  {
    "x": "karm ci kewtene",
    "y": " b. kerm ci kewtene"
  },
  {
    "x": "karm û çes",
    "y": " b. kerm û kêz"
  },
  {
    "x": "karm û çês",
    "y": " b. kerm û kêz"
  },
  {
    "x": "karm û kêz",
    "y": " b. kerm û kêz"
  },
  {
    "x": "karmî piro dayene",
    "y": " b. kermî piro dayene"
  },
  {
    "x": "karmat",
    "y": " b. keremate"
  },
  {
    "x": "yew karmat",
    "y": " b. yew keremate"
  },
  {
    "x": "karmcikewtiş (n)",
    "y": " b. kermcikewtiş (n)"
  },
  {
    "x": "karmend, -e",
    "y": " memur"
  },
  {
    "x": "karmenda caardişî",
    "y": " icra memuru"
  },
  {
    "x": "karmenda îcrayî",
    "y": " icra memuru"
  },
  {
    "x": "karmenda resepsîyonî",
    "y": " resepsiyon memuru"
  },
  {
    "x": "karmenda santralî",
    "y": " santral memuru"
  },
  {
    "x": "karmenda selahîyetdare",
    "y": " yetkili memur (kadın)"
  },
  {
    "x": "karmenda sîcîlî",
    "y": " sicil memuru"
  },
  {
    "x": "karmendê caardişî",
    "y": " icra memuru"
  },
  {
    "x": "karmendê îcrayî",
    "y": " icra memuru"
  },
  {
    "x": "karmenda tapûyî (m)",
    "y": " tapu memuru"
  },
  {
    "x": "karmendê tapûyî (n)",
    "y": " tapu memuru"
  },
  {
    "x": "karmendê resepsîyonî",
    "y": " resepsiyon memuru"
  },
  {
    "x": "karmendê santralî",
    "y": " santral memuru"
  },
  {
    "x": "karmendê sîcîlî",
    "y": " sicil memuru"
  },
  {
    "x": "karmendo selahîyetdar",
    "y": " yetkili memur (erkek)"
  },
  {
    "x": "karmendî (m)",
    "y": " memurluk"
  },
  {
    "x": "karmendîya embarî (m)",
    "y": " ambar memurluğu"
  },
  {
    "x": "karmendîya zewicnayîşî (m)",
    "y": " evlendirme memurluğu"
  },
  {
    "x": "karmendîye (m)",
    "y": " memurluk"
  },
  {
    "x": "karmicin, -e",
    "y": " b. kermijin, -e"
  },
  {
    "x": "karmijin, -i",
    "y": " b. kermijin, -e"
  },
  {
    "x": "karmik (n)",
    "y": " b. kermik (n)"
  },
  {
    "x": "karmin, -i",
    "y": " b. kermin, -e"
  },
  {
    "x": "karmîpirodayîş (n)",
    "y": " kermîpirodayîş (n)"
  },
  {
    "x": "karmus, -e",
    "y": " stajyer"
  },
  {
    "x": "karmusîye (m)",
    "y": " stajyerlik"
  },
  {
    "x": "karnasî (m)",
    "y": " işletme (işletme yöntemi)"
  },
  {
    "x": "karnasîya karanê îdarî (m)",
    "y": " işletmecilik"
  },
  {
    "x": "karolî (n)",
    "y": " b. karyola (m)"
  },
  {
    "x": "karolîfer (n)",
    "y": " b. kalorîfer (n)"
  },
  {
    "x": "karoser (n)",
    "y": " karoser "
  },
  {
    "x": "karpese (m)",
    "y": " b. kahrîpese (m)"
  },
  {
    "x": "karra (m)",
    "y": " b. kerra (m)"
  },
  {
    "x": "kart-I (n)",
    "y": " çeyrek (fiziki şeyler için dörtte bir)"
  },
  {
    "x": "kart-II (n)",
    "y": " b. xart (n)"
  },
  {
    "x": "kart kerdene",
    "y": " b. xart kerdene"
  },
  {
    "x": "karte (m)",
    "y": " kart"
  },
  {
    "x": "karta bingeyîye (m) (TE)",
    "y": " ana kart"
  },
  {
    "x": "karta çapemenîye (m)",
    "y": " basın kartı"
  },
  {
    "x": "karta ekranî (m) (TE)",
    "y": " ekran kartı"
  },
  {
    "x": "karta grafîkî (m) (TE)",
    "y": " grafik kartı"
  },
  {
    "x": "karta vengî (m) (TE)",
    "y": " ses kartı"
  },
  {
    "x": "kartik (n)",
    "y": " b. xartîk (n)"
  },
  {
    "x": "kartik kerdene",
    "y": " b. xartîk kerdene"
  },
  {
    "x": "kartî, -ye",
    "y": " bayat"
  },
  {
    "x": "kartî bîyene",
    "y": " bayatlamak "
  },
  {
    "x": "kartî kerdene",
    "y": " bayatlatmak"
  },
  {
    "x": "kartîbîyayîş (n)",
    "y": " bayatlama"
  },
  {
    "x": "kartîkerdiş (n)",
    "y": " bayatlatma"
  },
  {
    "x": "kartograf, -e",
    "y": " haritacı"
  },
  {
    "x": "kartol (m)",
    "y": " b. kartole (m)"
  },
  {
    "x": "kartole (m)",
    "y": " patates"
  },
  {
    "x": "kartoli (m)",
    "y": " b. kartole (m)"
  },
  {
    "x": "kartuel (m)",
    "y": " b. kartole (m)"
  },
  {
    "x": "kartueli (m)",
    "y": " b. kartole (m)"
  },
  {
    "x": "karû (n)",
    "y": " b. kuware (n)"
  },
  {
    "x": "karvan (n)",
    "y": " b. karwan (n) "
  },
  {
    "x": "karvançî",
    "y": " b. karwançî, -ye"
  },
  {
    "x": "karwa (n)",
    "y": " b. karwan (n)"
  },
  {
    "x": "karwan (n)",
    "y": " kervan"
  },
  {
    "x": "karwançênî (m)",
    "y": " kervancılık"
  },
  {
    "x": "karwançî, -ye",
    "y": " kervancı"
  },
  {
    "x": "karwançîyey (m)",
    "y": " b. karwançîyîye (m)"
  },
  {
    "x": "karwançîyîye (m)",
    "y": " kervancılık"
  },
  {
    "x": "karwaştiş (n)",
    "y": " iş talebi"
  },
  {
    "x": "karwo (n)",
    "y": " b. karwan (n)"
  },
  {
    "x": "karwon (n)",
    "y": " b. karwan (n) "
  },
  {
    "x": "karwonçî",
    "y": " b. karwançî, -ye"
  },
  {
    "x": "karwonçîyey (m)",
    "y": " b. karwançîyîye (m)"
  },
  {
    "x": "karwû (n)",
    "y": " b. karwan (n)"
  },
  {
    "x": "karwûn",
    "y": " b. karwan (n) "
  },
  {
    "x": "karwûnçî",
    "y": " b. karwançî, -ye"
  },
  {
    "x": "karwûnçîyê (m)",
    "y": " b. karwançîyîye (m)"
  },
  {
    "x": "karxane (n)",
    "y": " fabrika"
  },
  {
    "x": "karyola (m)",
    "y": " karyola"
  },
  {
    "x": "karyole (n)",
    "y": " b. karyola (m)"
  },
  {
    "x": "kas (n)",
    "y": " b. kaş (n)"
  },
  {
    "x": "kas bîyayîne",
    "y": " b. kaş bîyene"
  },
  {
    "x": "kas kerdene",
    "y": " b. kaş kerdene"
  },
  {
    "x": "kasa (m)",
    "y": " kasa"
  },
  {
    "x": "kasaya poladêne/polayêne (m)",
    "y": " çelik kasa"
  },
  {
    "x": "kasadar, -e",
    "y": " kasadar, kasiyer"
  },
  {
    "x": "kasane (m)",
    "y": " b. kaşane-I (m)"
  },
  {
    "x": "kasbîyayîş (n)",
    "y": " b. kaşbîyayîş (n)"
  },
  {
    "x": "kaselês, -e",
    "y": " çanak yalayıcı, dalkavuk, yalaka, yaltak"
  },
  {
    "x": "kaselêsîye (m)",
    "y": " çanak yalayıcılık, dalkavukluk, yalakalık, yaltaklık"
  },
  {
    "x": "kaset (n)",
    "y": " bant, kaset"
  },
  {
    "x": "kasetê teyîbe (n)",
    "y": " teyp bandı, teyp kaseti"
  },
  {
    "x": "kasetê vîdeoyî (n)",
    "y": " video bandı, video kaseti"
  },
  {
    "x": "kasete (m)",
    "y": " b. kaset (n)"
  },
  {
    "x": "kasîs (n)",
    "y": " hız kesici, kasis"
  },
  {
    "x": "kask (n)",
    "y": " kask"
  },
  {
    "x": "kaska",
    "y": " b. kaşka"
  },
  {
    "x": "kaskena",
    "y": " b. kaşka"
  },
  {
    "x": "kaskerdiş (n)",
    "y": " b. kaşkerdiş (n)"
  },
  {
    "x": "kast (n, sn)",
    "y": " kast"
  },
  {
    "x": "kastîng (n, sn)",
    "y": " kasting"
  },
  {
    "x": "kaş (n)",
    "y": " yamaç, yokuş"
  },
  {
    "x": "kaşo qij (n)",
    "y": " bayır"
  },
  {
    "x": "kaş bîyayene",
    "y": " b. kaş bîyene"
  },
  {
    "x": "kaş bîyayîne",
    "y": " b. kaş bîyene"
  },
  {
    "x": "kaş bîyene",
    "y": " çekilmek"
  },
  {
    "x": "kaş kerdene",
    "y": " çekmek"
  },
  {
    "x": "kaşane-I (m)",
    "y": " 1)kızak\r\n~2)bir sırığa yüklenip çekilerek taşınan yük "
  },
  {
    "x": "kaşane-II (n)",
    "y": " sürgü (tarım aracı), tapan"
  },
  {
    "x": "kaşbîyayîş (n)",
    "y": " çekilme"
  },
  {
    "x": "kaşêl bîyayîne",
    "y": " b. kaş bîyene"
  },
  {
    "x": "kaşêl kerdene",
    "y": " b. kaş kerdene"
  },
  {
    "x": "kaşêlbîyayîş (n)",
    "y": " b. kaşbîyayîş (n)"
  },
  {
    "x": "kaşêlkerdiş (n)",
    "y": " b. kaşkerdiş (n)"
  },
  {
    "x": "kaşing (n)",
    "y": " kolan"
  },
  {
    "x": "kaşinge (m)",
    "y": " kuşak üzerine bağlanan bir çeşit kemer "
  },
  {
    "x": "kaşîng (n)",
    "y": " b. kaşinge (m)"
  },
  {
    "x": "kaşka",
    "y": " keşke"
  },
  {
    "x": "kaşkan",
    "y": " b. kaşka"
  },
  {
    "x": "kaşkarê",
    "y": " b. kaşka"
  },
  {
    "x": "kaşkerdiş (n)",
    "y": " 1)çekme\r\n~2)çekim"
  },
  {
    "x": "kaşkêna",
    "y": " b. kaşka"
  },
  {
    "x": "kaşkî",
    "y": " b. kaşka"
  },
  {
    "x": "kaşkîna",
    "y": " b. kaşka"
  },
  {
    "x": "kaşkîya",
    "y": " b. kaşka"
  },
  {
    "x": "kaşkole (m)",
    "y": " atkı, kaşkol"
  },
  {
    "x": "kaşkona",
    "y": " b. kaşka"
  },
  {
    "x": "kaşmîr",
    "y": " b. keşmîr (n)"
  },
  {
    "x": "kaşûne (m)",
    "y": " b. kaşane-I (m)"
  },
  {
    "x": "kat (m)",
    "y": " b. katike (m)"
  },
  {
    "x": "kata-I",
    "y": " b. kotî"
  },
  {
    "x": "kata-II (m)",
    "y": " b. keta (m)"
  },
  {
    "x": "kata arî (m)",
    "y": " b. ketaya arêyî (m)"
  },
  {
    "x": "katey arî (m)",
    "y": " b. ketaya arêyî (m)"
  },
  {
    "x": "katê arî (m)",
    "y": " b. ketaya arêyî (m)"
  },
  {
    "x": "katalît (n)",
    "y": " katalit"
  },
  {
    "x": "katalîto tubin (n)",
    "y": " tüplü katalit"
  },
  {
    "x": "katati (m)",
    "y": " b. katike (m)"
  },
  {
    "x": "kate-I (n)",
    "y": " kemik"
  },
  {
    "x": "kate-II (m)",
    "y": " b. keta (m)"
  },
  {
    "x": "katebîyayîş (n)",
    "y": " kemik üremesi"
  },
  {
    "x": "kateyên, -e",
    "y": " kemikten yapılma"
  },
  {
    "x": "kateyin, -e",
    "y": " kemikli"
  },
  {
    "x": "kati-I (n)",
    "y": " b. kate-I (n)"
  },
  {
    "x": "kati-II (m)",
    "y": " b. katike (m)"
  },
  {
    "x": "katib, -e",
    "y": " kâtip, sekreter, yazman"
  },
  {
    "x": "katibe (m)",
    "y": " kâtibe"
  },
  {
    "x": "katibê (m)",
    "y": " b. katibî (m), katibîye (m)"
  },
  {
    "x": "katibî (m)",
    "y": " yazmanlık, sekreterlik, kâtiplik"
  },
  {
    "x": "katibîya encumenî (m)",
    "y": " encümen kâtipliği"
  },
  {
    "x": "katibîye (m)",
    "y": " yazmanlık, sekreterlik, kâtiplik"
  },
  {
    "x": "katik (m)",
    "y": " b. katike (m)"
  },
  {
    "x": "katike (m)",
    "y": " baş, kafa "
  },
  {
    "x": "katiki (m)",
    "y": " b. katike (m)"
  },
  {
    "x": "katîb, -e",
    "y": " b. katib, -e"
  },
  {
    "x": "katîbe (m)",
    "y": " b. katibe (m)"
  },
  {
    "x": "katîbê (m)",
    "y": " b. katibî (m), katibîye (m)"
  },
  {
    "x": "Katmandû (n)",
    "y": " Katmandu"
  },
  {
    "x": "katuel (m)",
    "y": " b. kartole (m)"
  },
  {
    "x": "kav, -e",
    "y": " ahmak, aptal, avanak, salak, saf (kişi için)"
  },
  {
    "x": "kav bîyene",
    "y": " ahmaklaşmak, aptallaşmak"
  },
  {
    "x": "kav kerdene",
    "y": " ahmaklaştırmak, aptallaştırmak"
  },
  {
    "x": "kavane",
    "y": " ahmakça, aptalca"
  },
  {
    "x": "kavbîyayîş (n)",
    "y": " ahmaklaşma, aptallaşma"
  },
  {
    "x": "kavey (m)",
    "y": " b. kavîye (m)"
  },
  {
    "x": "kavê (m)",
    "y": " b. kavî (m)"
  },
  {
    "x": "kavik, -e",
    "y": " ahmak, aptal, avanak, salak, saf (kişi için)"
  },
  {
    "x": "kavik bîyene",
    "y": " ahmaklaşmak"
  },
  {
    "x": "kavik kerdene",
    "y": " ahmaklaştırmak"
  },
  {
    "x": "kavikbîyayîş (n)",
    "y": " ahmaklaşma"
  },
  {
    "x": "kavikey (m)",
    "y": " b. kavikîye (m)"
  },
  {
    "x": "kavikê (m)",
    "y": " b. kavikî (m)"
  },
  {
    "x": "kavikî (m)",
    "y": " ahmaklık, aptallık, avanaklık, salaklık, saflık (kişi için)"
  },
  {
    "x": "kavikîye (m)",
    "y": " ahmaklık, aptallık, avanaklık, salaklık, saflık (kişi için)"
  },
  {
    "x": "kavikkerdiş (n)",
    "y": " ahmaklaştırma"
  },
  {
    "x": "kavir, -e",
    "y": " 1)kuzu\r\n~2)toklu"
  },
  {
    "x": "kavir ardene",
    "y": " (koyun için) doğurmak, kuzulamak, kuzu doğurmak"
  },
  {
    "x": "kavir eştene",
    "y": " (koyun için) düşük yapmak"
  },
  {
    "x": "kaviri (m)",
    "y": " b. kavire (m)"
  },
  {
    "x": "kavî (m)",
    "y": " ahmaklık, aptallık, avanaklık, salaklık, saflık (kişi için)"
  },
  {
    "x": "kavîye (m)",
    "y": " ahmaklık, aptallık, avanaklık, salaklık, saflık (kişi için)"
  },
  {
    "x": "kavkerdiş (n)",
    "y": " ahmaklaştırma, aptallaştırma"
  },
  {
    "x": "kax (n)",
    "y": " tarla, bostan vb. yerlerdeki yabani otları ayıklama işi"
  },
  {
    "x": "kax kerdene",
    "y": " tarla, bostan vb. yerlerdeki yabani otları ayıklamak"
  },
  {
    "x": "kaxid (n)",
    "y": " b. kaxite (m)"
  },
  {
    "x": "kaxide (m)",
    "y": " b. kaxite (m)"
  },
  {
    "x": "kaxit (n)",
    "y": " b. kaxite (m)"
  },
  {
    "x": "kaxite (m)",
    "y": " 1)kâğıt\r\n~2)lira"
  },
  {
    "x": "kaxita awdestxaneyî (m)",
    "y": " tuvalet kâğıdı"
  },
  {
    "x": "kaxita biantet (n)",
    "y": " antetli kağıt"
  },
  {
    "x": "kaxita bisernuşte (n)",
    "y": " antetli kağıt"
  },
  {
    "x": "kaxita destawxaneyî (m)",
    "y": " tuvalet kâğıdı"
  },
  {
    "x": "kaxita destûrî (m)",
    "y": " izin kâğıdı"
  },
  {
    "x": "kaxita îzinî (m)",
    "y": " izin kâğıdı"
  },
  {
    "x": "kaxita kenefî (m)",
    "y": " tuvalet kâğıdı"
  },
  {
    "x": "kaxita tuwaletî (m)",
    "y": " tuvalet kâğıdı"
  },
  {
    "x": "kaxkerdiş (n)",
    "y": " tarla, bostan vb. yerlerdeki yabani otları ayıklama"
  },
  {
    "x": "kay (n)",
    "y": " 1)oyun, dans\r\n~2)oyun, piyes\r\n~3)alay"
  },
  {
    "x": "kay dayene",
    "y": " oynatmak"
  },
  {
    "x": "kay kerdene",
    "y": " oynamak"
  },
  {
    "x": "kayê aqilî (zh)",
    "y": " akıl oyunları"
  },
  {
    "x": "kayê domanan (n)",
    "y": " çocuk oyuncağı"
  },
  {
    "x": "kayê gedeyan (n)",
    "y": " çocuk oyuncağı"
  },
  {
    "x": "kayê qeçekan (n)",
    "y": " çocuk oyuncağı"
  },
  {
    "x": "kayê qican (n)",
    "y": " çocuk oyuncağı"
  },
  {
    "x": "kay-kelebut (n)",
    "y": " cümbüş"
  },
  {
    "x": "kay-kelebut kerdene",
    "y": " cümbüş yapmak"
  },
  {
    "x": "kay-kelevît",
    "y": " b. kay-kelebut (n)"
  },
  {
    "x": "kay-kelevît kerdene",
    "y": " b. kay-kelebut kerdene"
  },
  {
    "x": "kaybaz (n)",
    "y": " erkek oyuncu, aktör"
  },
  {
    "x": "kaybazê karakterî (n, sn)",
    "y": " karakter oyuncu"
  },
  {
    "x": "kaybazo hetkar (n)",
    "y": " yardımcı erkek oyuncu"
  },
  {
    "x": "kaybazo hetkaro tewr baş",
    "y": " en iyi yardımcı erkek oyuncu"
  },
  {
    "x": "kaybazo tewr baş",
    "y": " en iyi erkek oyuncu"
  },
  {
    "x": "kaybaze (m)",
    "y": " kadın oyuncu, aktris"
  },
  {
    "x": "kaybaza hetkare (m)",
    "y": " yardımcı kadın oyuncu "
  },
  {
    "x": "kaybaza hetkara tewr başe",
    "y": " en iyi yardımcı kadın oyuncu"
  },
  {
    "x": "kaybaza karakterî (m, sn)",
    "y": " karakter oyuncu"
  },
  {
    "x": "kaybaza tewr başe",
    "y": " en iyi kadın oyuncu"
  },
  {
    "x": "kaybazîye (m)",
    "y": " oyunculuk, aktörlük"
  },
  {
    "x": "kaydayîş (n)",
    "y": " oynatma"
  },
  {
    "x": "kaye (m)",
    "y": " b. kay (n) "
  },
  {
    "x": "kaye dayene",
    "y": " b. kay dayene"
  },
  {
    "x": "kaye kerdene",
    "y": " b. kay kerdene"
  },
  {
    "x": "kayedayîş (n)",
    "y": " b. kaydayîş (n)"
  },
  {
    "x": "kayekerdiş (n)",
    "y": " b. kaykerdiş (n)"
  },
  {
    "x": "Kayen (n)",
    "y": " Cayenne"
  },
  {
    "x": "kayker (n)",
    "y": " erkek dansçı, dansör"
  },
  {
    "x": "kaykerdiş (n)",
    "y": " oynama"
  },
  {
    "x": "kaykerdox, -e",
    "y": " oyuncu "
  },
  {
    "x": "kaykerdoxîye (m)",
    "y": " oyunculuk"
  },
  {
    "x": "kaykere (m)",
    "y": " kadın dansçı, dansöz"
  },
  {
    "x": "kaykil (n)",
    "y": " oyuncak"
  },
  {
    "x": "kaykilo darên (n)",
    "y": " ahşap oyuncak"
  },
  {
    "x": "kaykilkar, -e",
    "y": " oyuncakçı (oyuncak yapan)"
  },
  {
    "x": "kaykilroş, -e",
    "y": " oyuncakçı (oyuncak satan)"
  },
  {
    "x": "Kaylerî",
    "y": " Cagliari"
  },
  {
    "x": "kaynuştox, -e",
    "y": " oyun yazarı, piyes yazarı"
  },
  {
    "x": "kayûkelebut (n)",
    "y": " cümbüş"
  },
  {
    "x": "kayûkelebut kerdene",
    "y": " cümbüş yapmak"
  },
  {
    "x": "kayvate (n)",
    "y": " tekerleme"
  },
  {
    "x": "KBRN (kîmyayî bîyolojîk radyolojîk nukleer)",
    "y": " KBRN (kimyasal biyolojik radyolojik nükleer)"
  },
  {
    "x": "ke-I",
    "y": " ki\r\n~Şima vajêne ke ma zî vajî. (Siz söyleyiniz ki biz de söyleyelim.) "
  },
  {
    "x": "ke-II",
    "y": " b. kes, -e"
  },
  {
    "x": "ke-III (n)",
    "y": " b. keye-I (n)"
  },
  {
    "x": "ke-IV",
    "y": " b. ko-II"
  },
  {
    "x": "ke-V",
    "y": " b. kî-I"
  },
  {
    "x": "ke-VI",
    "y": " b. eke-I"
  },
  {
    "x": "...ke (sf)",
    "y": " “bir” anlamı veren bir sonek"
  },
  {
    "x": "reyke",
    "y": " bir kez"
  },
  {
    "x": "kean, -i",
    "y": " b. kehen, -e; kan, -e"
  },
  {
    "x": "keaney (m)",
    "y": " b. kehenî (m), kanî (m)"
  },
  {
    "x": "kebab (n)",
    "y": " kebap"
  },
  {
    "x": "kebabrotox, -e",
    "y": " kebapçı"
  },
  {
    "x": "kebabşîş (n)",
    "y": " şişkebabı"
  },
  {
    "x": "kebabviraştox, -e",
    "y": " kebapçı"
  },
  {
    "x": "Keban (n)",
    "y": " Keban"
  },
  {
    "x": "kebanî (m)",
    "y": " ev işlerinden sorumlu kadın\r\n~“Rojî bîy dergî, kebanî bîy kergî, pîzey bîy vergî.”"
  },
  {
    "x": "kebanîye (m)",
    "y": " ev işlerinden sorumlu kadın"
  },
  {
    "x": "keber (n)",
    "y": " b. keyber (n)"
  },
  {
    "x": "kebonî (m)",
    "y": " b. kebanî (m), kebanîye (m)"
  },
  {
    "x": "kec, -i",
    "y": " b. kej, -e "
  },
  {
    "x": "keç (n)",
    "y": " b. kes (n)"
  },
  {
    "x": "keçel, -e",
    "y": " kel"
  },
  {
    "x": "keçelek, -e",
    "y": " bir kuş çeşidi"
  },
  {
    "x": "keçeley (m)",
    "y": " b. keçelîye (m)"
  },
  {
    "x": "keçelê (m)",
    "y": " b. keçelîye (m)"
  },
  {
    "x": "keçelîye (m)",
    "y": " kellik"
  },
  {
    "x": "ked (m)",
    "y": " b. kede (m)"
  },
  {
    "x": "kede (m)",
    "y": " emek, alın teri"
  },
  {
    "x": "keder (n)",
    "y": " keder, üzüntü, üzünç"
  },
  {
    "x": "keder kewtene",
    "y": " kederlenmek"
  },
  {
    "x": "keder kotene",
    "y": " b. keder kewtene"
  },
  {
    "x": "keder kowtene",
    "y": " b. keder kewtene"
  },
  {
    "x": "kederî ... girewtene",
    "y": " kederlenmek\r\n~\tA roje kederî ez girewta. (O gün kederlendim.)"
  },
  {
    "x": "kederî ... girotene",
    "y": " b. kederî ... girewtene"
  },
  {
    "x": "kederî ... gurotene",
    "y": " b. kederî ... girewtene"
  },
  {
    "x": "kederdayox, -e",
    "y": " üzüntülü (üzen durum veya olay), üzücü (durum veya olay)"
  },
  {
    "x": "kederin, -e",
    "y": " üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü, kederli"
  },
  {
    "x": "kederinê (m)",
    "y": " b. kederinîye (m), kederinî (m)"
  },
  {
    "x": "kederinî (m)",
    "y": " üzüntülülük"
  },
  {
    "x": "kederinîye (m)",
    "y": " üzüntülülük"
  },
  {
    "x": "kedernak, -e",
    "y": " üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü, kederli"
  },
  {
    "x": "kedernak bîyene",
    "y": " kederlenmek"
  },
  {
    "x": "kedernakê (m)",
    "y": " b. kedernakîye (m), kedernakî (m)"
  },
  {
    "x": "kedernakî (m)",
    "y": " üzüntülülük"
  },
  {
    "x": "kedernakîye (m)",
    "y": " üzüntülülük"
  },
  {
    "x": "kedi (m)",
    "y": " b. kede (m)"
  },
  {
    "x": "kedike (m)",
    "y": " küçük tuluk"
  },
  {
    "x": "kediki (m)",
    "y": " b. kedike (m)"
  },
  {
    "x": "kedî, -ye",
    "y": " evcil"
  },
  {
    "x": "kedî bîyene",
    "y": " evcilleşmek"
  },
  {
    "x": "kedî kerdene",
    "y": " evcilleştirmek"
  },
  {
    "x": "kedîbîyayîş (n)",
    "y": " evcilleşme"
  },
  {
    "x": "kedîkerdiş (n)",
    "y": " b. evcilleştirme"
  },
  {
    "x": "kedkar, -e",
    "y": " emekçi"
  },
  {
    "x": "kedkari (m)",
    "y": " b. kedkare (m)"
  },
  {
    "x": "kedwerîye (m)",
    "y": " sömürü"
  },
  {
    "x": "kef (n)",
    "y": " köpük"
  },
  {
    "x": "kefal (n)",
    "y": " kefal "
  },
  {
    "x": "kefalet (n)",
    "y": " kefalet"
  },
  {
    "x": "kefaletname (n)",
    "y": " kefaletname"
  },
  {
    "x": "Kefaret bo.",
    "y": " Geçmiş olsun."
  },
  {
    "x": "Kefaretê axrete bo.",
    "y": " b. Kefaretê axretî bo."
  },
  {
    "x": "Kefaretê axretî bo.",
    "y": " Geçmiş olsun."
  },
  {
    "x": "Kefaretê gunan bo.",
    "y": " b. Kefaretê guneyan bo."
  },
  {
    "x": "Kefaretê guneyan bo. ",
    "y": "Geçmiş olsun."
  },
  {
    "x": "Kefaretî axretî bû.",
    "y": " b. Kefaretê axretî bo."
  },
  {
    "x": "Kefaretî gunûn bû.",
    "y": " b. Kefaretê guneyan bo."
  },
  {
    "x": "kefe (m)",
    "y": " aya"
  },
  {
    "x": "kefen (n)",
    "y": " kefen"
  },
  {
    "x": "kefgêr (n)",
    "y": " b. kefgîr (n)"
  },
  {
    "x": "kefgir (n)",
    "y": " b. kefgîr (n) "
  },
  {
    "x": "kefgîr (n)",
    "y": " 1)kevgir\r\n~2)süzgeç"
  },
  {
    "x": "kefin, -e",
    "y": " köpüklü"
  },
  {
    "x": "kefî (m)",
    "y": " kefiye, başörtü, başörtüsü"
  },
  {
    "x": "kefîl, -e",
    "y": " kefil"
  },
  {
    "x": "kefîlîye (m)",
    "y": " kefillik, kefalet"
  },
  {
    "x": "kefîr (n)",
    "y": " kefir"
  },
  {
    "x": "kefîya (m)",
    "y": " b. kefîye (m)"
  },
  {
    "x": "kefîye (m)",
    "y": " kefiye, başörtü, başörtüsü"
  },
  {
    "x": "kefsicûq (m)",
    "y": " b. kefsucuxe (m)"
  },
  {
    "x": "kefsicûqe (m)",
    "y": " b. kefsucuxe (m)"
  },
  {
    "x": "kefsicûqi (m)",
    "y": " b. kefsucuxe (m)"
  },
  {
    "x": "kefsucuxe (m)",
    "y": " sucuk (bandırma)"
  },
  {
    "x": "keftar, -e",
    "y": " sırtlan"
  },
  {
    "x": "kefya (m)",
    "y": " b. kefîye (m)"
  },
  {
    "x": "kehan",
    "y": " b. kehen, -e; kan, -e"
  },
  {
    "x": "kehar (m)",
    "y": " b. karî (m) "
  },
  {
    "x": "kehe (n)",
    "y": " b. keye-I (n)"
  },
  {
    "x": "kehelp, -e",
    "y": " b. kelp, -e"
  },
  {
    "x": "kehen, -e",
    "y": " eski, arkaik"
  },
  {
    "x": "kehen bîyene",
    "y": " eskimek, çağı geçmek"
  },
  {
    "x": "kehen kerdene",
    "y": " eskitmek"
  },
  {
    "x": "kehenbîyayîş (n)",
    "y": " eskime"
  },
  {
    "x": "kehenî (m)",
    "y": " eskilik"
  },
  {
    "x": "kehenîye (m)",
    "y": " eskilik"
  },
  {
    "x": "kehenkerdiş (n)",
    "y": " eskitme"
  },
  {
    "x": "kehenlet (n)",
    "y": " eski püskü"
  },
  {
    "x": "kehenroş, -e",
    "y": " eskici"
  },
  {
    "x": "kehên",
    "y": " b. kehen, -e; kan, -e"
  },
  {
    "x": "kehênî (m)",
    "y": " b. kehenî (m)"
  },
  {
    "x": "kehin",
    "y": " b. kehen, -e; kan, -e"
  },
  {
    "x": "kehinî (m)",
    "y": " b. kehenî (m)"
  },
  {
    "x": "kehîl (m)",
    "y": " b. kihêl, -e"
  },
  {
    "x": "kehîn",
    "y": " b. kehen, -e; kan, -e"
  },
  {
    "x": "kehînî (m)",
    "y": " b. kehenî (m)"
  },
  {
    "x": "kehreba (n)",
    "y": " kehribar"
  },
  {
    "x": "kehrîbar (n)",
    "y": " b. kehreba (n)"
  },
  {
    "x": "kehya (n)",
    "y": " b. keyxwa (n)"
  },
  {
    "x": "kej, -e",
    "y": " sarışın"
  },
  {
    "x": "Keje (m)",
    "y": " inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "Keji (m)",
    "y": " b. Keje (m)"
  },
  {
    "x": "Kejo (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "kek-I (n)",
    "y": " ağabey"
  },
  {
    "x": "kekew",
    "y": " b. keko"
  },
  {
    "x": "kekey",
    "y": " b. kekê"
  },
  {
    "x": "kekê",
    "y": " ağabey (hitap hali) "
  },
  {
    "x": "keko",
    "y": " 1)ağabey (hitap hali)\r\n~2)baba (hitap hali)"
  },
  {
    "x": "kekû",
    "y": " b. keko"
  },
  {
    "x": "kek-II (n)",
    "y": " kek"
  },
  {
    "x": "kek-III (m)",
    "y": " b. keke (m)"
  },
  {
    "x": "keka (m)",
    "y": " şeker (çocuk dilinde)"
  },
  {
    "x": "keke (m)",
    "y": " pire"
  },
  {
    "x": "kekec",
    "y": " b. kekej, -e"
  },
  {
    "x": "kekecê",
    "y": " b. kekejîye (m)"
  },
  {
    "x": "kekej, -e",
    "y": " kekeme, keke, kekeç"
  },
  {
    "x": "kekej, -i",
    "y": " b. kekej, -e"
  },
  {
    "x": "kekejey",
    "y": " b. kekejîye (m)"
  },
  {
    "x": "kekejîye (m)",
    "y": " kekemelik, kekelik"
  },
  {
    "x": "kekewas (n)",
    "y": " b. kekvaş (n)"
  },
  {
    "x": "kekez, -e",
    "y": " b. kekej, -e"
  },
  {
    "x": "kekezîye (m)",
    "y": " b. kekejîye (m)"
  },
  {
    "x": "kekênî (m)",
    "y": " ağabeylik"
  },
  {
    "x": "kekêtî (m)",
    "y": " ağabeylik"
  },
  {
    "x": "keki (m)",
    "y": " b. keke (m)"
  },
  {
    "x": "kekîye (m)",
    "y": " ağabeylik"
  },
  {
    "x": "kekko (n)",
    "y": " b. kekû, -ye"
  },
  {
    "x": "keko (n)",
    "y": " b. kekû, -ye"
  },
  {
    "x": "kekoyey (m)",
    "y": " b. kekoyîye (m)"
  },
  {
    "x": "kekoyê (m)",
    "y": " b. kekoyî (m)"
  },
  {
    "x": "kekoyî (m)",
    "y": " ağabeylik"
  },
  {
    "x": "kekoyîn (m)",
    "y": " b. kekoyîne (m)"
  },
  {
    "x": "kekoyîne (m)",
    "y": " ağabeylik"
  },
  {
    "x": "kekoyîye (m)",
    "y": " ağabeylik"
  },
  {
    "x": "kektî (m)",
    "y": " b. kekîye (m)"
  },
  {
    "x": "kekû, -ye",
    "y": " guguk kuşu"
  },
  {
    "x": "kekvaş (n)",
    "y": " papatya"
  },
  {
    "x": "kekvil (m)",
    "y": " b. kekvile (m)"
  },
  {
    "x": "kekvile (m)",
    "y": " papatya"
  },
  {
    "x": "kekvili (m)",
    "y": " b. kekvile (m)"
  },
  {
    "x": "kel-I (n)",
    "y": " teke, erkeç"
  },
  {
    "x": "kel amayene",
    "y": " b. kel ameyene"
  },
  {
    "x": "kel ameyene",
    "y": " kösnümek (keçi için)   "
  },
  {
    "x": "kel amyayeni",
    "y": " b. kel ameyene"
  },
  {
    "x": "kel bîyene",
    "y": " b. bi kel bîyene"
  },
  {
    "x": "kel dayene",
    "y": " (keçi için) çiftleştirmek"
  },
  {
    "x": "kel werdene",
    "y": " (keçi için) çiftleşmek"
  },
  {
    "x": "kel-II (m)",
    "y": " b. kele (m)"
  },
  {
    "x": "kel dayene",
    "y": " b. kele dayene"
  },
  {
    "x": "kel dîyayene",
    "y": " 1)b. kele dîyayene "
  },
  {
    "x": "kel kewtene",
    "y": " b. kele kewtene"
  },
  {
    "x": "kel kotene",
    "y": " b. kele kewtene"
  },
  {
    "x": "kel piro ameyene",
    "y": " b. kele piro ameyene"
  },
  {
    "x": "kel piro ginayene",
    "y": " b. kele piro ginayene"
  },
  {
    "x": "kel tiro ameyene",
    "y": " b. kele tiro ameyene"
  },
  {
    "x": "kel vistene",
    "y": " b. kele fîştene"
  },
  {
    "x": "kel wardene",
    "y": " b. kel werdene"
  },
  {
    "x": "kel-mel (n)",
    "y": " eşya"
  },
  {
    "x": "kela (m)",
    "y": " 1)kale\r\n~2)oyun merkezi (oyunda el değdirilmesi gereken direk, taş vb. veya içinden çıkılmaması gereken, sınırları belirlenmiş yer vb.)"
  },
  {
    "x": "kela-II (m)",
    "y": " b. kilawe-I (m)"
  },
  {
    "x": "Kela (m)",
    "y": " Hınıs"
  },
  {
    "x": "kelaberbî, -ye",
    "y": " b. kelebermî, -ye"
  },
  {
    "x": "kelaberbî fîstene",
    "y": " b. kelebermî fîştene"
  },
  {
    "x": "kelaberbîfîstiş (n)",
    "y": " b. kelebermîfîştiş (n)"
  },
  {
    "x": "kelacêrî, -ye",
    "y": " b. kelegirî, -ye"
  },
  {
    "x": "kelagêrî, -ye",
    "y": " b. kelegirî, -ye"
  },
  {
    "x": "kelagîr, -e",
    "y": " b. kelegirî, -ye"
  },
  {
    "x": "kelajêrî, -ye",
    "y": " b. kelegirî, -ye"
  },
  {
    "x": "kelakel, -i",
    "y": " b. kelekel, -e"
  },
  {
    "x": "Kelamê Ellahî (n)",
    "y": " Kelamullah"
  },
  {
    "x": "Kelamê Ellay (n)",
    "y": " b. Kelamê Ellahî (n)"
  },
  {
    "x": "Kelamê Heqî (n)",
    "y": " Kelamullah"
  },
  {
    "x": "Kelamê Homayî (n)",
    "y": " Kelamullah"
  },
  {
    "x": "Kelamê Hûmay (n)",
    "y": " b. Kelamê Homayî(n)"
  },
  {
    "x": "Kelamê Qedîm (n)",
    "y": " b. Kelamo Qedîm (n)"
  },
  {
    "x": "Kelamo Qedîm (n)",
    "y": " Kelam-ı Kadim "
  },
  {
    "x": "Kelamû Qedîm (n)",
    "y": " b. Kelamo Qedîm (n)"
  },
  {
    "x": "kelamayîş (n)",
    "y": " b. kelameyîş (n)"
  },
  {
    "x": "kelame (n)",
    "y": " boyundurukta bulunan ve öküzün boynuna geçirilip altta bağlanan iki küçük çubuktan her biri"
  },
  {
    "x": "kelame tadayene",
    "y": " (mecazi) çalışmak istememek, (mecazi) işten kaçmak, (mecazi) kaytarmak"
  },
  {
    "x": "kelametadayîş (n)",
    "y": " çift sürme veya döven sürme esnasında öküzün yürümek istememesi"
  },
  {
    "x": "kelameyîş (n)",
    "y": " kösnüme (keçi için)"
  },
  {
    "x": "kelamyayîş (n)",
    "y": " b. kelameyîş (n)"
  },
  {
    "x": "kelandiri (m)",
    "y": " b. kelendire (m)"
  },
  {
    "x": "kelarze (m)",
    "y": " b. kelaze (m)"
  },
  {
    "x": "kelasu (m)",
    "y": " b. kelazû (m)"
  },
  {
    "x": "kelaw-I (n)",
    "y": " diş"
  },
  {
    "x": "kelawî teber vetene",
    "y": " ağzı açık kalıp dişlerini göstermek"
  },
  {
    "x": "kelawî tebervetiş",
    "y": " ağzı açık kalıp dişlerini gösterme"
  },
  {
    "x": "kelaw-II (m)",
    "y": " b. kilawe-I (m)"
  },
  {
    "x": "kelawe (m)",
    "y": " b. kilawe-II (m)"
  },
  {
    "x": "kelawi (m)",
    "y": " b. kilawe-III (m)"
  },
  {
    "x": "kelax, -e",
    "y": " kuzgun"
  },
  {
    "x": "kelaze (m)",
    "y": " kösnük (inek için)"
  },
  {
    "x": "kelaze bîyene",
    "y": " kösnümek (inek için)  "
  },
  {
    "x": "kelazebîyayîş (n)",
    "y": " kösnüme (inek için)  "
  },
  {
    "x": "kelazi (m)",
    "y": " b. kelaze (m)"
  },
  {
    "x": "kelazi bîyayene",
    "y": " b. kelaze bîyene "
  },
  {
    "x": "kelazibîyayîş (n)",
    "y": " b. kelazebîyayîş (n) "
  },
  {
    "x": "kelazû (m)",
    "y": " kösnük (keçi için)"
  },
  {
    "x": "kelazû kerdene",
    "y": " kösnümek (keçi için)  "
  },
  {
    "x": "kelazûkerdiş (n)",
    "y": " kösnüme (keçi için)"
  },
  {
    "x": "kelbetane (m)",
    "y": " b. kelpetane (m)"
  },
  {
    "x": "kelbetin (n)",
    "y": " b. kulmetîn (n)"
  },
  {
    "x": "kelbîyayîş (n)",
    "y": " b. bikelbîyayîş (n)"
  },
  {
    "x": "kelce (n)",
    "y": " teke, erkeç"
  },
  {
    "x": "keldaya (m)",
    "y": " b. keledaya (m)"
  },
  {
    "x": "keldaye (n)",
    "y": " b. keledaye (n)"
  },
  {
    "x": "keldayek (n)",
    "y": " kaynatıcı"
  },
  {
    "x": "keldayekê awe (n)",
    "y": " su kaynatıcı"
  },
  {
    "x": "keldayîş (n)",
    "y": " b. keledayîş (n)"
  },
  {
    "x": "keldayîye (m)",
    "y": " b. keledayîye (m)"
  },
  {
    "x": "keldîyayîş (n)",
    "y": " b. keledîyayîş (n)"
  },
  {
    "x": "kele (m)",
    "y": " 1)aşırı sıcak\r\n~2)kaynama"
  },
  {
    "x": "kela ... weriştene",
    "y": " coşmak"
  },
  {
    "x": "kela ... weriştiş",
    "y": " coşma"
  },
  {
    "x": "kela ... weriznayene",
    "y": " coşturmak"
  },
  {
    "x": "kela ... weriznayîş",
    "y": " coşturma"
  },
  {
    "x": "kele dayene",
    "y": " kaynatmak"
  },
  {
    "x": "kele dîyayene",
    "y": " 1)kaynatılmak\r\n~2)kızgın olmak, ısısı fazla olmak"
  },
  {
    "x": "kele fînîyayene",
    "y": " kaynatılmak"
  },
  {
    "x": "kele fîştene",
    "y": " kaynatmak"
  },
  {
    "x": "kele kewtene",
    "y": " kaynamak"
  },
  {
    "x": "kele piro ameyene",
    "y": " kaynamak"
  },
  {
    "x": "kele piro ginayene",
    "y": " 1)kaynamak\r\n~2)çağlamak (mecazî)"
  },
  {
    "x": "kele tiro ameyene",
    "y": " kaynamak"
  },
  {
    "x": "kele-II (n)",
    "y": " tereyağı"
  },
  {
    "x": "kele-III (m)",
    "y": " mezar taşı"
  },
  {
    "x": "kele-IV (n)",
    "y": " b. kelle (n)"
  },
  {
    "x": "keleberb, -e",
    "y": " b. kelebermî, -ye"
  },
  {
    "x": "keleberb bîyayene",
    "y": " b. kelebermî bîyene"
  },
  {
    "x": "keleberbbîyayîş (n)",
    "y": " b. kelebermîbîyayîş (n)"
  },
  {
    "x": "keleberbî, -ye",
    "y": " b. kelebermî, -ye"
  },
  {
    "x": "keleberbî bîyayene",
    "y": " b. kelebermî bîyene"
  },
  {
    "x": "keleberbî fîstene",
    "y": " b. kelebermî fîştene"
  },
  {
    "x": "keleberbî fîştene",
    "y": " b. kelebermî fîştene"
  },
  {
    "x": "keleberbî kerdene",
    "y": " b. kelebermî kerdene"
  },
  {
    "x": "keleberbîbîyayîş (n)",
    "y": " b. kelebermîbîyayîş (n)"
  },
  {
    "x": "keleberbîfîstiş (n)",
    "y": " b. kelebermîfîştiş (n)"
  },
  {
    "x": "keleberbîfîştiş (n)",
    "y": " b. kelebermîfîştiş (n)"
  },
  {
    "x": "keleberbîkerdiş (n)",
    "y": " b. kelebermîkerdiş (n)"
  },
  {
    "x": "kelebermî, -ye",
    "y": " ağlamaklı"
  },
  {
    "x": "kelebermî bîyene",
    "y": " ağlamaklı olmak"
  },
  {
    "x": "kelebermî fîştene",
    "y": " ağlamaklı duruma getirmek"
  },
  {
    "x": "kelebermî kerdene",
    "y": " ağlamaklı duruma getirmek"
  },
  {
    "x": "kelebermîbîyayîş (n)",
    "y": " ağlamaklı olma, ağlamsı olma, ağlamsama, doluklama, dolukma"
  },
  {
    "x": "kelebermîfîştiş (n)",
    "y": " ağlamaklı duruma getirme"
  },
  {
    "x": "kelebermîkerdiş (n)",
    "y": " ağlamaklı duruma getirme"
  },
  {
    "x": "kelece (n)",
    "y": " b. kelce (n)"
  },
  {
    "x": "kelecêrî (n)",
    "y": " b. kelegirî, -ye"
  },
  {
    "x": "kelecêrî bîyayene",
    "y": " b. kelegirî bîyene"
  },
  {
    "x": "kelecêrî kerdene",
    "y": " b. kelegirî kerdene"
  },
  {
    "x": "kelecêrîbîyayîş (n)",
    "y": " b. kelegirîbîyayîş (n)"
  },
  {
    "x": "kelecêrîkerdiş (n)",
    "y": " b. kelegirîkerdiş (n)"
  },
  {
    "x": "keledaya (m)",
    "y": " 1)çok sıcak olan (yemek, içecek vb.), kızgın\r\n~2)kaynatılmış olan"
  },
  {
    "x": "keledaya bîyene",
    "y": " kızgın olmak"
  },
  {
    "x": "keledaye (n)",
    "y": " 1)çok sıcak olan (yemek, içecek vb.), kızgın\r\n~2)kaynatılmış olan"
  },
  {
    "x": "keledaye bîyene",
    "y": " çok sıcak olmak (yemek, içecek vb. için), kızgın olmak"
  },
  {
    "x": "keledayîş (n)",
    "y": " kaynatma"
  },
  {
    "x": "keledayîye (m)",
    "y": " 1)çok sıcak olan (yemek, içecek vb.), kızgın\r\n~2)kaynatılmış olan"
  },
  {
    "x": "keledayîye bîyene",
    "y": " kızgın olmak"
  },
  {
    "x": "keledîyayîş (n)",
    "y": " kaynatılma"
  },
  {
    "x": "kelefînîyayîş (n)",
    "y": " kaynatılma"
  },
  {
    "x": "kelefîşta (m)",
    "y": " kaynatılmış olan"
  },
  {
    "x": "kelefîşte (n)",
    "y": " kaynatılmış olan"
  },
  {
    "x": "kelefîştiş (n)",
    "y": " kaynatma"
  },
  {
    "x": "kelefîştîye (m)",
    "y": " kaynatılmış olan"
  },
  {
    "x": "kelegerm, -e",
    "y": " aşırı sıcak, çok sıcak olan (yemek, içecek vb.)"
  },
  {
    "x": "kelegerm bîyene",
    "y": " çok sıcak olmak (yemek, içecek vb. için)"
  },
  {
    "x": "kelegil, -e",
    "y": " b. kelegirî, -ye"
  },
  {
    "x": "kelegirî, -ye",
    "y": " ağlamaklı, ağlamsı"
  },
  {
    "x": "kelegirî bîyene",
    "y": " ağlamaklı olmak"
  },
  {
    "x": "kelegirî kerdene",
    "y": " ağlamaklı duruma getirmek"
  },
  {
    "x": "kelegirîbîyayîş (n)",
    "y": " ağlamaklı olma, ağlamsı olma, ağlamsama, doluklama, dolukma"
  },
  {
    "x": "kelegirîkerdiş (n)",
    "y": " ağlamaklı duruma getirme"
  },
  {
    "x": "kelegîr, -e",
    "y": " b. kelegirî, -ye"
  },
  {
    "x": "kelegîrî (n)",
    "y": " b. kelegirî, -ye"
  },
  {
    "x": "kelekel, -e",
    "y": " aşırı sıcak"
  },
  {
    "x": "kelekewta (m)",
    "y": " kaynamış olan"
  },
  {
    "x": "kelekewte (n)",
    "y": " kaynamış olan"
  },
  {
    "x": "kelekewtiş (n)",
    "y": " kaynama"
  },
  {
    "x": "kelekewtîye (m)",
    "y": " kaynamış olan"
  },
  {
    "x": "kelem (n)",
    "y": " lahana"
  },
  {
    "x": "kelemça (m)",
    "y": " b. kelemçe (n)"
  },
  {
    "x": "kelemçe (n)",
    "y": " kelepçe"
  },
  {
    "x": "keleme-I (n)",
    "y": " b. kelame (n)"
  },
  {
    "x": "keleme-II (m)",
    "y": " b. kelem (n)"
  },
  {
    "x": "kelenaya (m)",
    "y": " kaynatılmış olan"
  },
  {
    "x": "kelenaye (n)",
    "y": " kaynatılmış olan"
  },
  {
    "x": "kelenayene",
    "y": " kaynatmak"
  },
  {
    "x": "kelenayîş (n)",
    "y": " kaynatma"
  },
  {
    "x": "kelenayîye (m)",
    "y": " kaynatılmış olan"
  },
  {
    "x": "kelendire (m)",
    "y": " tırpan"
  },
  {
    "x": "kelendire kerdene",
    "y": " tırpanlamak"
  },
  {
    "x": "kelendire piro dayene",
    "y": " tırpanlamak"
  },
  {
    "x": "kelendiri (m)",
    "y": " b. kelendire (m)"
  },
  {
    "x": "kelendiri piro dayene",
    "y": " b. kelendire piro dayene"
  },
  {
    "x": "kelendirkêş, -e",
    "y": " tırpancı"
  },
  {
    "x": "kelendirkêşî (m)",
    "y": " tırpancılık"
  },
  {
    "x": "kelendirkêşîye (m)",
    "y": " tırpancılık"
  },
  {
    "x": "kelenîyayene",
    "y": " kaynatılmak"
  },
  {
    "x": "kelenîyayîş (n)",
    "y": " kaynatılma"
  },
  {
    "x": "kelepçe (n)",
    "y": " b. kelemçe (n)"
  },
  {
    "x": "kelepiroameya (m)",
    "y": " kaynamış olan"
  },
  {
    "x": "kelepiroameye (n)",
    "y": " kaynamış olan"
  },
  {
    "x": "kelepiroameyîş (n)",
    "y": " kaynama"
  },
  {
    "x": "kelepiroameyîye (m)",
    "y": " kaynamış olan"
  },
  {
    "x": "kelepiroginaya (m)",
    "y": " kaynamış olan"
  },
  {
    "x": "kelepiroginaye (m)",
    "y": " kaynamış olan"
  },
  {
    "x": "kelepiroginayîş (n)",
    "y": " 1)kaynama\r\n~2)çağlama (mecazî)"
  },
  {
    "x": "kelepiroginayîye (m)",
    "y": " kaynamış olan"
  },
  {
    "x": "keleş, -e-I",
    "y": " 1)keleş, yakışıklı\r\n~2)haydut"
  },
  {
    "x": "keleş bîyene",
    "y": " 1)yakışıklı olmak\r\n~2)haydut olmak"
  },
  {
    "x": "keleş-II (n)",
    "y": " b. kalaşnîkof (n)"
  },
  {
    "x": "keleşbîyayîş (n)",
    "y": " yakışıklı olma"
  },
  {
    "x": "keleşêr (n)",
    "y": " horoz"
  },
  {
    "x": "keleşi (m)",
    "y": " b. keleşe (m)"
  },
  {
    "x": "keleşînkof (n)",
    "y": " b. kalaşnîkof (n)"
  },
  {
    "x": "keleşkof (n)",
    "y": " b. kalaşnîkof (n)"
  },
  {
    "x": "keletiroameya (m)",
    "y": " kaynamış olan"
  },
  {
    "x": "keletiroameye (n)",
    "y": " kaynamış olan"
  },
  {
    "x": "keletiroameyîş (n)",
    "y": " kaynama"
  },
  {
    "x": "keletiroameyîye (m)",
    "y": " kaynamış olan"
  },
  {
    "x": "kelê xo ... ra birnayene",
    "y": " (daha çok dinsel kişi ve şeyler için) öpmek"
  },
  {
    "x": "kelênaya (m)",
    "y": " b. kelenaya (m), kelenayîye (m)"
  },
  {
    "x": "kelênaye (n)",
    "y": " b. kelenaye (n)"
  },
  {
    "x": "kelênayene",
    "y": " b. kelenayene"
  },
  {
    "x": "kelênayîş (n)",
    "y": " b. kelenayîş (n)"
  },
  {
    "x": "kelênîyayene",
    "y": " b. kelenîyayene"
  },
  {
    "x": "kelênîyayîş (n)",
    "y": " b. kelenîyayîş (n)"
  },
  {
    "x": "kelgirî, -ye",
    "y": " b. kelegirî, -ye"
  },
  {
    "x": "kelgîr, -e",
    "y": " b. kelegirî, -ye"
  },
  {
    "x": "keli-I (n)",
    "y": " tosun"
  },
  {
    "x": "keli-II (m)",
    "y": " b. kele-I (m)"
  },
  {
    "x": "keli dayene",
    "y": " b. kele dayene"
  },
  {
    "x": "keli dîyayene",
    "y": " b. kele dîyayene"
  },
  {
    "x": "keli dîyayîne",
    "y": " b. kele dîyayene"
  },
  {
    "x": "keli fîneyene",
    "y": " b. kele fînîyayene "
  },
  {
    "x": "keli kowtene",
    "y": " b. kele kewtene"
  },
  {
    "x": "keli vistene",
    "y": " b. kele fîştene"
  },
  {
    "x": "kelidaya (m)",
    "y": " b. keledaya (m)"
  },
  {
    "x": "kelidaye (n)",
    "y": " b. keledaye (n)"
  },
  {
    "x": "kelidayîş (n)",
    "y": " b. keledayîş (n)"
  },
  {
    "x": "kelidayîye (m)",
    "y": " b. keledayîye (m)"
  },
  {
    "x": "kelidîyayîş (n)",
    "y": " b. keledîyayîş (n)"
  },
  {
    "x": "kelifîneyîş (n)",
    "y": " b. kelefînîyayîş (n)"
  },
  {
    "x": "kelikowta (m)",
    "y": " b. kelekewta (m), kelekewtîye (m)"
  },
  {
    "x": "kelikowte (n)",
    "y": " b. kelekewte (n)"
  },
  {
    "x": "kelikowtiş (n)",
    "y": " b. kelekewtiş (n)"
  },
  {
    "x": "kelimçe (n)",
    "y": " b. kelemçe (n)"
  },
  {
    "x": "kelinaya (m)",
    "y": " b. kelenaya (m), kelenayîye (m)"
  },
  {
    "x": "kelinaye (n)",
    "y": " b. kelenaye (n)"
  },
  {
    "x": "kelinayene",
    "y": " b. kelenayene"
  },
  {
    "x": "kelinayîş (n)",
    "y": " b. kelenayîş (n)"
  },
  {
    "x": "kelince (n)",
    "y": " b. kelce (n)"
  },
  {
    "x": "kelinîyayene",
    "y": " b. kelenîyayene"
  },
  {
    "x": "kelinîyayîş (n)",
    "y": " b. kelenîyayîş (n)"
  },
  {
    "x": "kelivista (m)",
    "y": " b. kelefîştîye (m), kelefîşta (m)"
  },
  {
    "x": "keliviste (n)",
    "y": " b. kelefîşte (n)"
  },
  {
    "x": "kelivistiş (n)",
    "y": " b. kelefîştiş (n)"
  },
  {
    "x": "kelîce (n)",
    "y": " b. kelce (n) "
  },
  {
    "x": "kelîje (n)",
    "y": " b. kelce (n)"
  },
  {
    "x": "kelje (n)",
    "y": " b. kelce (n)"
  },
  {
    "x": "kelkewta (m)",
    "y": " b. kelekewta (m), kelekewtîye (m)"
  },
  {
    "x": "kelkewte (n)",
    "y": " b. kelekewte (n)"
  },
  {
    "x": "kelkota (m)",
    "y": " b. kelekewta (m), kelekewtîye (m)"
  },
  {
    "x": "kelkote (n)",
    "y": " b. kelekewte (n)"
  },
  {
    "x": "kelkotiş (n)",
    "y": " b. kelekewtiş (n)"
  },
  {
    "x": "kelle (n)",
    "y": " kelle-paça"
  },
  {
    "x": "kelli (n)",
    "y": " b. kelle (n)"
  },
  {
    "x": "kelme (n)",
    "y": " b. kelame (n)"
  },
  {
    "x": "kelmêşe (m)",
    "y": " b. kermêşe (m)"
  },
  {
    "x": "kelmit (n)",
    "y": " b. kermite (n)"
  },
  {
    "x": "kelmite (n)",
    "y": " b. kermite (n)"
  },
  {
    "x": "kelmîz, -e",
    "y": " idrarını tutamıyan, sidiğini tutamıyan"
  },
  {
    "x": "kelnaya (m)",
    "y": " b. kelenaya (m), kelenayîye (m)"
  },
  {
    "x": "kelnaye (n)",
    "y": " b. kelenaye (n)"
  },
  {
    "x": "kelnayek (n)",
    "y": " kaynatıcı"
  },
  {
    "x": "kelnayekê awe (n)",
    "y": " su kaynatıcı"
  },
  {
    "x": "kelnayene",
    "y": " b. kelenayene"
  },
  {
    "x": "kelnayîş (n)",
    "y": " b. kelenayîş (n)"
  },
  {
    "x": "kelnîyayene",
    "y": " b. kelenîyayene"
  },
  {
    "x": "kelnîyayîş (n)",
    "y": " b. kelenîyayîş (n)"
  },
  {
    "x": "kelobel, -e",
    "y": " çok sıcak olan (yemek, içecek vb.)"
  },
  {
    "x": "kelobel bîyene",
    "y": " çok sıcak olmak (yemek, içecek vb. için)"
  },
  {
    "x": "kelom (m)",
    "y": " b. kilame-I (m)"
  },
  {
    "x": "Kelom Ellay (n)",
    "y": " b. Kelamê Ellahî (n)"
  },
  {
    "x": "Kelom Homay (n)",
    "y": " b. Kelamê Homayî(n)"
  },
  {
    "x": "Kelomê Qedîm (n)",
    "y": " b. Kelamo Qedîm (n)"
  },
  {
    "x": "kelomçe (n)",
    "y": " b. kelemçe (n)"
  },
  {
    "x": "kelomçek (n)",
    "y": " b. kelemçe (n)"
  },
  {
    "x": "kelome (n)",
    "y": " b. kelame (n)"
  },
  {
    "x": "kelp, -e",
    "y": " köpek, it"
  },
  {
    "x": "kelpaça (m)",
    "y": " obur, pisboğaz"
  },
  {
    "x": "kelpaçe (n)",
    "y": " 1)kelle-paça\r\n~2)obur, pisboğaz"
  },
  {
    "x": "kelpe (n)",
    "y": " bir cins semender"
  },
  {
    "x": "kelpetane (m)",
    "y": " kerpeten"
  },
  {
    "x": "kelpetene (m)",
    "y": " b. kelpetane (m)"
  },
  {
    "x": "kelpetîn (m)",
    "y": " b. kelpetane (m)"
  },
  {
    "x": "kelpetîne (m)",
    "y": " b. kelpetane (m)"
  },
  {
    "x": "kelpey (m)",
    "y": " b. kelpîye (m), kelpî (m)"
  },
  {
    "x": "kelpê (m)",
    "y": " b. kelpîye (m), kelpî (m)"
  },
  {
    "x": "kelpênî (m)",
    "y": " köpeklik"
  },
  {
    "x": "kelpi (m)",
    "y": " b. kelpe (m)"
  },
  {
    "x": "kelpiroameya (m)",
    "y": " b. kelepiroameya (m), kelepiroameyîye (m)"
  },
  {
    "x": "kelpiroameye (n)",
    "y": " b. kelepiroameye (n)"
  },
  {
    "x": "kelpiroameyîş (n)",
    "y": " b. kelepiroameyîş (n)"
  },
  {
    "x": "kelpiroginaya (m)",
    "y": " b. kelepiroginaya (m), kelepiroginayîye (m)"
  },
  {
    "x": "kelpiroginaye (m)",
    "y": " b. kelepiroginaye (m)"
  },
  {
    "x": "kelpiroginayîş (n)",
    "y": " b. kelepiroginayîş (n)"
  },
  {
    "x": "kelpî (m)",
    "y": " köpeklik"
  },
  {
    "x": "kelpîç (n)",
    "y": " b. kerpîç (n)"
  },
  {
    "x": "kelpîye (m)",
    "y": " köpeklik"
  },
  {
    "x": "kelpkî",
    "y": " köpekçe"
  },
  {
    "x": "kelsu (m)",
    "y": " b. kelazû (m)"
  },
  {
    "x": "kelsuyî kerdene",
    "y": " b. kelazû kerdene"
  },
  {
    "x": "kelsuyîkerdiş (n)",
    "y": " b. kelazûkerdiş (n)"
  },
  {
    "x": "keltiroameya (m)",
    "y": " b. keletiroameya (m), keletiroameyîye (m)"
  },
  {
    "x": "keltiroameye (n)",
    "y": " b. keletiroameye (n)"
  },
  {
    "x": "keltiroameyîş (n)",
    "y": " b. keletiroameyîş (n)"
  },
  {
    "x": "kelûm (m)",
    "y": " b. kilame-I (m)"
  },
  {
    "x": "Kelûm Ellay (n)",
    "y": " b. Kelamê Ellahî (n)"
  },
  {
    "x": "Kelûm Hûmay (n)",
    "y": " b. Kelamê Homayî(n)"
  },
  {
    "x": "Kelûmê Qedîm (n)",
    "y": " b. Kelamo Qedîm (n)"
  },
  {
    "x": "kelûme (n)",
    "y": " b. kelame (n)"
  },
  {
    "x": "kelvetîn (m)",
    "y": " b. kelpetane (m)"
  },
  {
    "x": "kelvistiş (n)",
    "y": " b. kelefîştiş (n)"
  },
  {
    "x": "kemal (n)",
    "y": " önem"
  },
  {
    "x": "Kemalîye (m)",
    "y": " Kemaliye "
  },
  {
    "x": "keman (n)",
    "y": " yay"
  },
  {
    "x": "kemane-I (n)",
    "y": " keman"
  },
  {
    "x": "kemane-II (n)",
    "y": " b. kêmane (n)"
  },
  {
    "x": "kemanebend, -e",
    "y": " kemancı"
  },
  {
    "x": "kemanecen, -e",
    "y": " kemancı"
  },
  {
    "x": "kemaney (m)",
    "y": " kêmanî (m)"
  },
  {
    "x": "kemanî (n)",
    "y": " b. kêmanî (m)"
  },
  {
    "x": "Kemax (n)",
    "y": " Kemah "
  },
  {
    "x": "kember (n)",
    "y": " kemer (bel bağı)"
  },
  {
    "x": "kemberê emnîyetî (n)",
    "y": " emniyet kemeri"
  },
  {
    "x": "kemberê Swêdî (n)",
    "y": " İsveç oturağı"
  },
  {
    "x": "Kembere (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "Kemberi (m)",
    "y": " b. Kembere (m)"
  },
  {
    "x": "kemedîyayene",
    "y": " b. kemidîyayene"
  },
  {
    "x": "kemedîyayîş (n)",
    "y": " b. kemidîyayîş (n)"
  },
  {
    "x": "kemednayene",
    "y": " b. kemidnayene"
  },
  {
    "x": "kemednayîş (n)",
    "y": " b. kemidnayîş (n)"
  },
  {
    "x": "kemelnayene",
    "y": " b. kemidnayene"
  },
  {
    "x": "kemelnayîş (n)",
    "y": " b. kemidnayîş (n)"
  },
  {
    "x": "kemelnîyayene",
    "y": " b. kemidîyayene"
  },
  {
    "x": "kemelnîyayîş (n)",
    "y": " b. kemidîyayîş (n)"
  },
  {
    "x": "kemer-I (n)",
    "y": " kemer (bel bağı) "
  },
  {
    "x": "kemerê emnîyetî (n)",
    "y": " emniyet kemeri"
  },
  {
    "x": "kemer-II (n)",
    "y": " kaya"
  },
  {
    "x": "kemere (m)",
    "y": " taş"
  },
  {
    "x": "kemera/kerraya bazaltî (m)",
    "y": " bazalt taşı"
  },
  {
    "x": "kemera gucî (m)",
    "y": " 1)(atletizmde atılan) gülle\r\n~2)gülle atma sporu\r\n~3) (atletizmde atılan elde yer alabilecek büyüklükteki gülle) gülle"
  },
  {
    "x": "kemera gucî eştene",
    "y": " gülle atmak"
  },
  {
    "x": "kemera guzî (m)",
    "y": " b. kemera gucî (m)"
  },
  {
    "x": "kemera guzî estene",
    "y": " b. kemera gucî eştene"
  },
  {
    "x": "kemera kilîtine (m)",
    "y": " kilitli taş"
  },
  {
    "x": "kemera kîlometreyan (m)",
    "y": " kilometre taşı"
  },
  {
    "x": "kemera mezele (m)",
    "y": " mezar taşı"
  },
  {
    "x": "kemera misonî (m)",
    "y": " b. kemera muşeneyî (m)"
  },
  {
    "x": "kemera mişonî (m)",
    "y": " b. kemera muşeneyî (m)"
  },
  {
    "x": "kemera muşeneyî (m)",
    "y": " döven taşı, döven dişi"
  },
  {
    "x": "kemere ronayene (elewîyîye de)",
    "y": " düşkün ilan etmek (Alevilikte), aforoz etmek"
  },
  {
    "x": "kemereke (m)",
    "y": " beştaş [oyunu]"
  },
  {
    "x": "kemerekî (zh)",
    "y": " beştaş [oyunu]"
  },
  {
    "x": "kemereronayîs (elewîyîye de)  (n)",
    "y": " b. kemereronayîş (elewîyîye de) (n)"
  },
  {
    "x": "kemereronayîş (elewîyîye de) (n)",
    "y": " aforoz etme"
  },
  {
    "x": "kemerin-I (n)",
    "y": " kayalık"
  },
  {
    "x": "kemerin, -e-II",
    "y": " taşlı"
  },
  {
    "x": "kemernayene",
    "y": " b. kemidnayene"
  },
  {
    "x": "kemernayîş (n)",
    "y": " b. kemidnayîş (n)"
  },
  {
    "x": "kemernîyayene",
    "y": " b. kemidîyayene"
  },
  {
    "x": "kemernîyayîş (n)",
    "y": " b. kemidîyayîş (n)"
  },
  {
    "x": "kemerroş, -e",
    "y": " taşçı (taş satan)"
  },
  {
    "x": "kemerroşîye (m)",
    "y": " taşçılık"
  },
  {
    "x": "kemertaş, -e",
    "y": " taşçı (taş yontan)"
  },
  {
    "x": "kemertaşîye (m)",
    "y": " taşçılık"
  },
  {
    "x": "kemertiraş, -e",
    "y": " taşçı (taş yontan)"
  },
  {
    "x": "kemertiraşîye (m)",
    "y": " taşçılık"
  },
  {
    "x": "kemever, -e",
    "y": " b. kêmver, -e"
  },
  {
    "x": "kemey (m)",
    "y": " b. kêmîye (m)"
  },
  {
    "x": "kemeyîş (n)",
    "y": " b. kêmîyayîş (n)"
  },
  {
    "x": "kemênayene",
    "y": " b. kêmînayene"
  },
  {
    "x": "kemênayîş (n)",
    "y": " b. kêmînayîş (n)"
  },
  {
    "x": "kemênayox, -e",
    "y": " b. kêmînayox, -e"
  },
  {
    "x": "kemidîyayene",
    "y": " haşlanmak"
  },
  {
    "x": "kemidîyayîş (n)",
    "y": " haşlanma"
  },
  {
    "x": "kemidnayene",
    "y": " haşlamak"
  },
  {
    "x": "kemidnayîş (n)",
    "y": " haşlama"
  },
  {
    "x": "kemilte (n)",
    "y": " b. kermite (n)"
  },
  {
    "x": "kemî",
    "y": " 1. b. kêmî 2. b. kêmîye (m)"
  },
  {
    "x": "kemî-zêde",
    "y": " b. kêmî-zêde"
  },
  {
    "x": "kemî bîyene",
    "y": " b. kêmî bîyene"
  },
  {
    "x": "kemî kerdene",
    "y": " b. kêmî kerdene"
  },
  {
    "x": "kemîbîyaya (m)",
    "y": " b. kêmîbîyaya (m)"
  },
  {
    "x": "kemîbîyaye (n)",
    "y": " b. kêmîbîyaye (n)"
  },
  {
    "x": "kemîbîyayîş (n)",
    "y": " b. kêmîbîyayîş (n)"
  },
  {
    "x": "kemîkerdiş (n)",
    "y": " b. kêmîkerdiş (n)"
  },
  {
    "x": "kemîkerdox, -e",
    "y": " b. kêmîkerdox, -e"
  },
  {
    "x": "kemînayene",
    "y": " b. kêmînayene"
  },
  {
    "x": "kemînayîş (n)",
    "y": " b. kêmînayîş (n)"
  },
  {
    "x": "kemîyaya (m)",
    "y": " b. kêmîyaya (m)"
  },
  {
    "x": "kemîyaye (n)",
    "y": " b. kêmîyaye (n)"
  },
  {
    "x": "kemîyayene",
    "y": " b. kêmîyayene"
  },
  {
    "x": "kemîyayîş (n)",
    "y": " b. kêmîyayîş (n)"
  },
  {
    "x": "kemle (n)",
    "y": " b. kerme (n)"
  },
  {
    "x": "kemone (n)",
    "y": " b. kêmane (n)"
  },
  {
    "x": "kemur (n)",
    "y": " kemer (n)"
  },
  {
    "x": "kemûne (n)",
    "y": " b. kêmane (n)"
  },
  {
    "x": "kemver, -i",
    "y": " b. kêmver, -e"
  },
  {
    "x": "kena (m)",
    "y": " b. kêna (n)"
  },
  {
    "x": "kenar (n)",
    "y": " kenar, kıyı, sahil"
  },
  {
    "x": "kenare (n)",
    "y": " kıyı"
  },
  {
    "x": "kenareyê çemî (n)",
    "y": " akarsu kıyısı, nehir kıyısı"
  },
  {
    "x": "kenareyê robarî (n)",
    "y": " akarsu kıyısı, nehir kıyısı"
  },
  {
    "x": "kenareyê royî (n)",
    "y": " akarsu kıyısı, nehir kıyısı"
  },
  {
    "x": "kendawe (m)",
    "y": " körfez"
  },
  {
    "x": "kendaweke (m)",
    "y": " koy"
  },
  {
    "x": "kendekarî (m)",
    "y": " hafriyat, kazı"
  },
  {
    "x": "kendene",
    "y": " 1)kazmak\r\n~2)oymak"
  },
  {
    "x": "kendir (n)",
    "y": " 1)kendir, kenevir\r\n~2)halat"
  },
  {
    "x": "kendiş (n)",
    "y": " 1)kazma\r\n~2)oyma"
  },
  {
    "x": "kendîl (n)",
    "y": " b. kendir (n)"
  },
  {
    "x": "kendox, -e",
    "y": " kazıcı"
  },
  {
    "x": "kenef (n)",
    "y": " yüznumara, ayakyolu, abdesthane, apteshane, hela, kademhane, kenef, memişhane, tuvalet"
  },
  {
    "x": "kenew (n)",
    "y": " b. kenewîr (n)"
  },
  {
    "x": "kenewîr (n)",
    "y": " kendir, kenevir"
  },
  {
    "x": "kenfi (n)",
    "y": " b. kenef (n)"
  },
  {
    "x": "kengel (n)",
    "y": " b. kenger (n)"
  },
  {
    "x": "kenger (n)",
    "y": " kenger"
  },
  {
    "x": "kengola (m)",
    "y": " b. kengula (m)"
  },
  {
    "x": "kengula (m)",
    "y": " salıncak (içinde çocuk uyutulan salıncak) "
  },
  {
    "x": "kengulay (m)",
    "y": " b. kengula (m) "
  },
  {
    "x": "kenif (n)",
    "y": " b. kenef (n)"
  },
  {
    "x": "kenîyayene",
    "y": " kazılmak"
  },
  {
    "x": "kenîyayîş (n)",
    "y": " kazılma"
  },
  {
    "x": "kental (n)",
    "y": " kental"
  },
  {
    "x": "kenver",
    "y": " b. kêmver, -e"
  },
  {
    "x": "Kenya (m)",
    "y": " Kenya"
  },
  {
    "x": "kepçe (n)",
    "y": " b. gepçe (n)"
  },
  {
    "x": "kepe (m)",
    "y": " kep"
  },
  {
    "x": "kepe pafîştene",
    "y": " kucaklamak"
  },
  {
    "x": "kepe tira kerdene",
    "y": " kucaklamak"
  },
  {
    "x": "kepepafîştiş (n)",
    "y": " kucaklama"
  },
  {
    "x": "kepesnayîne",
    "y": " b. kepisnayene"
  },
  {
    "x": "kepesnayîş (n)",
    "y": " b. kepisnayîş (n)"
  },
  {
    "x": "kepetirakerdiş (n)",
    "y": " kucaklama"
  },
  {
    "x": "kepi (m)",
    "y": " b. kepe (m) "
  },
  {
    "x": "kepisnayene",
    "y": " sıkıştırmak"
  },
  {
    "x": "kepisnayîş (n)",
    "y": " sıkıştırma"
  },
  {
    "x": "kepuge (m)",
    "y": " burun"
  },
  {
    "x": "ker-mer (n)",
    "y": " b. kel-mel (n)"
  },
  {
    "x": "kera (m)",
    "y": " b. kerra (m)"
  },
  {
    "x": "kera seqay (m)",
    "y": " b. kerraya seqayî (m)"
  },
  {
    "x": "kerab kerdene",
    "y": " herk etmek, nadas etmek"
  },
  {
    "x": "keran (n)",
    "y": " b. kêran"
  },
  {
    "x": "kerancîyayene",
    "y": " b. kirancîyayene"
  },
  {
    "x": "kerancîyayîş (n)",
    "y": " b. kirancîyayîş (n)"
  },
  {
    "x": "kerandene",
    "y": " b. kirantene"
  },
  {
    "x": "kerandês (n)",
    "y": " kaba sıva"
  },
  {
    "x": "kerandiş (n)",
    "y": " b. kirantiş (n)"
  },
  {
    "x": "kerat (n)",
    "y": " kayalık uçurum"
  },
  {
    "x": "kerata",
    "y": " b. kerate (n)"
  },
  {
    "x": "kerate (n)",
    "y": " çekecek, kerata"
  },
  {
    "x": "kerb (n)",
    "y": " üzüntü, üzünç"
  },
  {
    "x": "kerba (n)",
    "y": " b. kerwa, -ye"
  },
  {
    "x": "kerbay (m)",
    "y": " b. kerwaye (m)"
  },
  {
    "x": "kerbetîn (m)",
    "y": " b. kelpetane (m)"
  },
  {
    "x": "kerbê (m)",
    "y": " b. kerwaye (m)"
  },
  {
    "x": "kerbin, -e",
    "y": " üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü"
  },
  {
    "x": "kerbinê (m)",
    "y": " b. kerbinî (m), kerbinîye (m)"
  },
  {
    "x": "kerbinî (m)",
    "y": " üzüntülülük"
  },
  {
    "x": "kerbinîye (m)",
    "y": " üzüntülülük"
  },
  {
    "x": "kerbizik (n)",
    "y": " üzüntü, üzünç"
  },
  {
    "x": "kerbizikin, -e",
    "y": " üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü"
  },
  {
    "x": "kerbizikinê (m)",
    "y": " b. kerbizikinî (m), kerbizikinîye (m)"
  },
  {
    "x": "kerbizikinî (m)",
    "y": " üzüntülülük"
  },
  {
    "x": "kerbizikinîye (m)",
    "y": " üzüntülülük"
  },
  {
    "x": "kerce (n)",
    "y": " b. kerje (n)"
  },
  {
    "x": "kerd (m)",
    "y": " b. kardî (m)"
  },
  {
    "x": "kerda (m)",
    "y": " yapılmış olan"
  },
  {
    "x": "kerde (n)",
    "y": " yapılmış olan, edim"
  },
  {
    "x": "kerdene",
    "y": " yapmak, etmek, gerçekleştirmek"
  },
  {
    "x": "kerdene xo pay",
    "y": " b. xo pay kerdene"
  },
  {
    "x": "kerdis (n)",
    "y": " b. kerdiş (n) "
  },
  {
    "x": "kerdiş (n)",
    "y": " yapma, etme, gerçekleştirme; eylem"
  },
  {
    "x": "kerdişo îdarî (n)",
    "y": " idari eylem"
  },
  {
    "x": "kerdişo neheq (n)",
    "y": " haksız fiil"
  },
  {
    "x": "kerdî, -ye-I",
    "y": " yapılmış olan"
  },
  {
    "x": "kerdî-II (m)",
    "y": " b. kardî (m) "
  },
  {
    "x": "kerdîs (n)",
    "y": " b. kerdiş (n) "
  },
  {
    "x": "kerdîye (m)",
    "y": " yapılmış olan"
  },
  {
    "x": "kerdox, -e",
    "y": " 1)yapan, yapıcı\r\n~2)özne, fail\r\n~kerdoxa nimitîye (m): gizli özne"
  },
  {
    "x": "kerdoxa nimitîye (m)",
    "y": " gizli özne"
  },
  {
    "x": "kerdoxo nimite (n)",
    "y": " gizli özne"
  },
  {
    "x": "kerduex",
    "y": " b. kerdox, -e"
  },
  {
    "x": "kere (n)",
    "y": " b. kele-II (n)"
  },
  {
    "x": "kere (n)",
    "y": " b. kerre (n)"
  },
  {
    "x": "kerebe (n)",
    "y": " b. kehreba (n)"
  },
  {
    "x": "kerebîyayene",
    "y": " b. keribîyayene"
  },
  {
    "x": "kerebnayene",
    "y": " b. keribnayene"
  },
  {
    "x": "kerebnayox, -e",
    "y": " b. keribnayox, -e"
  },
  {
    "x": "kerebyayene",
    "y": " b. keribîyayene"
  },
  {
    "x": "kerem bike",
    "y": " buyur, buyurunuz, buyurun"
  },
  {
    "x": "kerem bikîn",
    "y": " buyurunuz"
  },
  {
    "x": "kerem ke",
    "y": " buyur, buyurunuz, buyurun"
  },
  {
    "x": "kerem kerdene",
    "y": " buyurmak"
  },
  {
    "x": "keremê xo ra",
    "y": " lütfen"
  },
  {
    "x": "keremate",
    "y": " çok fazla, diz boyu (çok miktarda)"
  },
  {
    "x": "yew keremate",
    "y": " bir hayli"
  },
  {
    "x": "kereng (n)",
    "y": " b. kenger (n)"
  },
  {
    "x": "kerew kerdene",
    "y": " b. kerab kerdene"
  },
  {
    "x": "kerewîze (m)",
    "y": " kereviz"
  },
  {
    "x": "kerewz (m)",
    "y": " b. kerewîze (m)"
  },
  {
    "x": "kerg (m)",
    "y": " b. kerge (m)"
  },
  {
    "x": "kerga sîya (m)",
    "y": " b. kerga sîyaye (m)"
  },
  {
    "x": "kergê şûmî (m)",
    "y": " b. kerga şamî (m)"
  },
  {
    "x": "kergane (m)",
    "y": " 1)çıban, apse\r\n~2)şark çıbanı, Halep çıbanı, kara yara"
  },
  {
    "x": "kergdar, -e",
    "y": " tavukçu (tavuk yetiştiren)"
  },
  {
    "x": "kergdarî (m)",
    "y": " tavukçuluk"
  },
  {
    "x": "kergdarîye (m)",
    "y": " tavukçuluk"
  },
  {
    "x": "kerge-I (m)",
    "y": " tavuk"
  },
  {
    "x": "kerga misrî (m)",
    "y": " hindi"
  },
  {
    "x": "kerga sîyay (m)",
    "y": " b. kerga sîyaye (m)"
  },
  {
    "x": "kerga sîyaye (m)",
    "y": " kara tavuk (uğursuzluk sembolü)\r\n~“Binê Aldunî kerewo sîya wo.\r\n~\t\r\n~Kerga sîyay ma rî veyn dawo,\r\n~\t\r\n~Ocax ma rî tefênawo,\r\n~\t\r\n~Berê keydê ma qefelnawo.” (Deyîra “Mehmedê Aldunî”, Folklorê Ma ra Çend Numûney)"
  },
  {
    "x": "kerga şamî (m)",
    "y": " hindi"
  },
  {
    "x": "kerge-II (n)",
    "y": " üzümün sıkılıp şırasının çıkarıldığı yer"
  },
  {
    "x": "kergeyan ser ra ameyene war",
    "y": " bağbozumunun sona ermesi"
  },
  {
    "x": "kergey (zh)",
    "y": " b. kergeyî (zh)"
  },
  {
    "x": "kergeyî (zh)",
    "y": " bağbozumu"
  },
  {
    "x": "kergi (m)",
    "y": " b. kerge (m)"
  },
  {
    "x": "kergroş, -e",
    "y": " tavukçu (tavuk satan)"
  },
  {
    "x": "kergroşî (m)",
    "y": " tavukçuluk"
  },
  {
    "x": "kergroşîye (m)",
    "y": " tavukçuluk"
  },
  {
    "x": "kergsîya (m)",
    "y": " karatavuk (kuşu)"
  },
  {
    "x": "kergula (m)",
    "y": " b. kengula (m)"
  },
  {
    "x": "keri (n)",
    "y": " b. kerre (n)"
  },
  {
    "x": "keribîyaya (m)",
    "y": " üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü, üzgün"
  },
  {
    "x": "keribîyaye (n)",
    "y": " üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü, üzgün"
  },
  {
    "x": "keribîyaye bîyene",
    "y": " üzgün olmak"
  },
  {
    "x": "keribîyayene",
    "y": " üzülmek, kederlenmek"
  },
  {
    "x": "keribîyayeyey (m)",
    "y": " b. keribîyayeyî (m), keribîyayeyîye (m)"
  },
  {
    "x": "keribîyayeyê (m)",
    "y": " b. keribîyayeyî (m), keribîyayeyîye (m)"
  },
  {
    "x": "keribîyayeyênî (m)",
    "y": " üzüntülülük"
  },
  {
    "x": "keribîyayeyî (m)",
    "y": " üzgünlük, üzüntülülük"
  },
  {
    "x": "keribîyayeyîye (m)",
    "y": " üzüntülülük"
  },
  {
    "x": "keribîyayê (m)",
    "y": " b. keribîyayîye (m), keribîyaya (m)"
  },
  {
    "x": "keribîyayîye (m)",
    "y": " üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü, üzgün"
  },
  {
    "x": "keribnayene",
    "y": " üzmek"
  },
  {
    "x": "keribnayox, -e",
    "y": " üzücü (durum veya olay), üzüntülü (üzen durum veya olay)"
  },
  {
    "x": "kerik (m)",
    "y": " b. kerike (m)"
  },
  {
    "x": "kerike (m)",
    "y": " henüz olgunlaşmamış olan incir"
  },
  {
    "x": "kerî (n)",
    "y": " küçükbaş hayvan sürüsü"
  },
  {
    "x": "kerje (n)",
    "y": " kene"
  },
  {
    "x": "kerjik (n)",
    "y": " kene"
  },
  {
    "x": "kerk (m)",
    "y": " b. kerge (m)"
  },
  {
    "x": "kerkaş (m)",
    "y": " b. kerkaşî (m)"
  },
  {
    "x": "kerkaş (n)",
    "y": " b. kerkaşî (m)"
  },
  {
    "x": "kerkaşî (m)",
    "y": " yük hayvanıyla taş vb. şeyleri taşımak için kullanılan ve semerin iki tarafına sarkıtılan ağaç dalları veya tahtadan yapılma araç"
  },
  {
    "x": "kerkence (m)",
    "y": " b. kerkince (m)"
  },
  {
    "x": "kerkenci (m)",
    "y": " b. kerkince (m)"
  },
  {
    "x": "kerkencike (m)",
    "y": " b. kerkince (m)"
  },
  {
    "x": "kerkinc (m)",
    "y": " b. kerkince (m)"
  },
  {
    "x": "kerkince (m)",
    "y": " yengeç"
  },
  {
    "x": "kerkinci (m)",
    "y": " b. kerkince (m)"
  },
  {
    "x": "kerkon (n)",
    "y": " b. kergane (m)"
  },
  {
    "x": "kerkot (m)",
    "y": " b. kerkote (m)"
  },
  {
    "x": "kerkote (m)",
    "y": " yarma (iri ve gelişigüzel kırılmış tahıl)"
  },
  {
    "x": "kerkoti (m)",
    "y": " b. kerkote (m)"
  },
  {
    "x": "kerkotî (m)",
    "y": " b. kerkote (m)"
  },
  {
    "x": "kerkuet (m)",
    "y": " b. kerkote (m)"
  },
  {
    "x": "kerkut (n)",
    "y": " b. kukirt (n)"
  },
  {
    "x": "kerkûti (m)",
    "y": " b. kerkote (m)"
  },
  {
    "x": "kerm (n)",
    "y": " bağırsak kurdu, kurtçuk, kurt"
  },
  {
    "x": "kerm ci kewtene",
    "y": " çürümek (diş için)"
  },
  {
    "x": "kerm û kêz",
    "y": " börtü böcek"
  },
  {
    "x": "kermê dare (n)",
    "y": " ağaç kurdu"
  },
  {
    "x": "kermê pirnike (n)",
    "y": " sümük"
  },
  {
    "x": "kermî piro dayene",
    "y": " çürümek (diş için)"
  },
  {
    "x": "kermati",
    "y": " b. keremate"
  },
  {
    "x": "yew kermati",
    "y": " b. yew keremate"
  },
  {
    "x": "kermax (m)",
    "y": " b. kermaxe (m)"
  },
  {
    "x": "kermaxe (m)",
    "y": " kalbur ile gözer arası bir tür kalbur"
  },
  {
    "x": "kermcikewtiş (n)",
    "y": " çürüme (diş için)"
  },
  {
    "x": "kerme-I (n)",
    "y": " kesek, tezek"
  },
  {
    "x": "kerme-II (n) (elewîyîye de)",
    "y": " dini karar (Alevilikte)"
  },
  {
    "x": "kerme bîyene  (elewîyîye de)",
    "y": " dini karar verilmek (Alevilikte)"
  },
  {
    "x": "kerme kerdene (elewîyîye de)",
    "y": " dini karar vermek (Alevilikte)"
  },
  {
    "x": "kermecin, -e",
    "y": " b. kermijin, -e"
  },
  {
    "x": "kermejin, -i",
    "y": " b. kermijin, -e"
  },
  {
    "x": "kermele (n)",
    "y": " b. kerme (n)"
  },
  {
    "x": "kermezin, -e",
    "y": " b. kermijin, -e"
  },
  {
    "x": "kermêşe (m)",
    "y": " sivrisinek"
  },
  {
    "x": "kermi (n)",
    "y": " b. kerme (n)"
  },
  {
    "x": "kermicin, -e",
    "y": " b. kermijin, -e"
  },
  {
    "x": "kermicini (m)",
    "y": " b. kermijine (m)"
  },
  {
    "x": "kermijin, -e",
    "y": " kurtlu"
  },
  {
    "x": "kermijin, -i",
    "y": " b. kermijin, -e"
  },
  {
    "x": "kermik (n)",
    "y": " 1)(böcek, örümcek vb.) böcü\r\n~2)kurtçuk"
  },
  {
    "x": "kermin, -e",
    "y": " kurtlu"
  },
  {
    "x": "kermite (n)",
    "y": " kesek"
  },
  {
    "x": "kermîpirodayîş (n)",
    "y": " çürüme (diş için)"
  },
  {
    "x": "keron (n)",
    "y": " b. kêran (n)"
  },
  {
    "x": "kerpe-I (n)",
    "y": " daha çok eğimli arazide, taş ve/veya ağaçlardan oluşan bir tür set"
  },
  {
    "x": "kerpe-II (n)",
    "y": " b. kelpe (n)"
  },
  {
    "x": "kerpîç (n)",
    "y": " kerpiç"
  },
  {
    "x": "kerr, -e",
    "y": " sağır, işitme engelli"
  },
  {
    "x": "kerr bîyayene",
    "y": " b. kerr bîyene"
  },
  {
    "x": "kerr bîyene",
    "y": " sağır olmak, sağırlaşmak"
  },
  {
    "x": "kerr kerdene",
    "y": " sağırlaştırmak"
  },
  {
    "x": "kerr û lal",
    "y": " ahraz"
  },
  {
    "x": "kerra (m)",
    "y": " taş"
  },
  {
    "x": "kerra ardî (m)",
    "y": " b. kerraya erdî (m)"
  },
  {
    "x": "kerra muşanî (m)",
    "y": " b. kerraya muşeneyî (m)"
  },
  {
    "x": "kerra muşonî (m)",
    "y": " b. kerraya muşeneyî (m)"
  },
  {
    "x": "kerray qewetî eyştene",
    "y": " b. kerraya qewetî eştene"
  },
  {
    "x": "kerray quwêt (m)",
    "y": " b. kerraya qewetî (m)"
  },
  {
    "x": "kerray quwêt eştene",
    "y": " b. kerraya qewetî eştene"
  },
  {
    "x": "kerraya bazaltî (m)",
    "y": " bazalt taşı"
  },
  {
    "x": "kerraya erdî (m)",
    "y": " bir bölümü toprağa gömülü olan büyük taş"
  },
  {
    "x": "kerraya kilîtine (m)",
    "y": " kilitli taş"
  },
  {
    "x": "kerraya kîlometreyan (m)",
    "y": " kilometre taşı"
  },
  {
    "x": "kerraya mefşenî (m)",
    "y": " b. kerraya muşeneyî (m)"
  },
  {
    "x": "kerraya mezele (m)",
    "y": " mezar taşı"
  },
  {
    "x": "kerraya muşeneyî (m)",
    "y": " döven taşı, döven dişi"
  },
  {
    "x": "kerraya qewetî (m)",
    "y": " 1)(atletizmde atılan) gülle\r\n~2)gülle atma sporu"
  },
  {
    "x": "kerraya qewetî eştene",
    "y": " gülle atmak"
  },
  {
    "x": "kerraya salmeyî (m)",
    "y": " 1)(atletizmde atılan elde yer alabilecek büyüklükteki gülle) gülle\r\n~2)gülle atma sporu"
  },
  {
    "x": "kerraya salmeyî eştene",
    "y": " (elde yer alabilecek büyüklükte gülle atmak) gülle atmak"
  },
  {
    "x": "kerraya seqayî (m)",
    "y": " bileğitaşı"
  },
  {
    "x": "kerreyê ... eştene",
    "y": " boşamak"
  },
  {
    "x": "kerreyê ... eştiş",
    "y": " boşama"
  },
  {
    "x": "kerreyê ... vistene",
    "y": " b. kerreyê ... eştene"
  },
  {
    "x": "kerreyê ... vistiş",
    "y": " b. kerreyê ... eştiş"
  },
  {
    "x": "kerrê meşuenî (m)",
    "y": " b. kerraya muşeneyî (m)"
  },
  {
    "x": "kerrê meşuwenî (m)",
    "y": " b. kerraya muşeneyî (m)"
  },
  {
    "x": "kerrê mişuwenî (m)",
    "y": " b. kerraya muşeneyî (m)"
  },
  {
    "x": "kerrê muşenî (m)",
    "y": " b. kerraya muşeneyî (m)"
  },
  {
    "x": "kerran (n)",
    "y": " b. giran-II (n)"
  },
  {
    "x": "kerraroş, -e",
    "y": " taşçı (taş satan)"
  },
  {
    "x": "kerraroşî (m)",
    "y": " taşçılık"
  },
  {
    "x": "kerrataş, -e",
    "y": " taşçı (taş yontan)"
  },
  {
    "x": "kerrataşîye (m)",
    "y": " taşçılık"
  },
  {
    "x": "kerratiraş, -e",
    "y": " taşçı (taş yontan)"
  },
  {
    "x": "kerratiraşîye (m)",
    "y": " taşçılık"
  },
  {
    "x": "kerrayin, -e",
    "y": " taşlı"
  },
  {
    "x": "kerrbîyayîş (n)",
    "y": " sağır olma, sağırlaşma"
  },
  {
    "x": "kerre-I (n)",
    "y": " kaya"
  },
  {
    "x": "kerre-II (m)",
    "y": " pas"
  },
  {
    "x": "kerre girewtene",
    "y": " paslanmak"
  },
  {
    "x": "kerregirewtiş (n)",
    "y": " paslanma"
  },
  {
    "x": "kerrexane (n)",
    "y": " taşlık, taşocağı"
  },
  {
    "x": "kerrê (m)",
    "y": " b. kerrî (m)"
  },
  {
    "x": "kerreyin (n)",
    "y": " kayalık"
  },
  {
    "x": "kerrê (m)",
    "y": " b. kerrî (m)"
  },
  {
    "x": "kerri (n)",
    "y": " b. kerre (n)"
  },
  {
    "x": "kerrike (m)",
    "y": " b. kerike (m)"
  },
  {
    "x": "kerrî (m)",
    "y": " sağırlık"
  },
  {
    "x": "kerrîye (m)",
    "y": " sağırlık"
  },
  {
    "x": "kerrkerdiş (n)",
    "y": " sağırlaştırma"
  },
  {
    "x": "kerseg (n)",
    "y": " kesek"
  },
  {
    "x": "kersele (n)",
    "y": " kesek"
  },
  {
    "x": "kert-I (n)",
    "y": " geçit, derbent"
  },
  {
    "x": "kert-II (n)",
    "y": " b. kart-I (n)"
  },
  {
    "x": "kertik (n)",
    "y": " b. xartîk (n)"
  },
  {
    "x": "kertik kerdene",
    "y": " b. xartîk kerdene"
  },
  {
    "x": "kertol (m)",
    "y": " b. kartole (m)"
  },
  {
    "x": "kervan (n)",
    "y": " b. karwan (n) "
  },
  {
    "x": "kervançî",
    "y": " b. karwançî, -ye"
  },
  {
    "x": "kerwa, -ye",
    "y": " kirve"
  },
  {
    "x": "kerwan (n)",
    "y": " b. karwan (n) "
  },
  {
    "x": "kerwançênî (m)",
    "y": " karwançênî (m)"
  },
  {
    "x": "kerwançî, -ye",
    "y": " b. karwançî, -ye"
  },
  {
    "x": "kerway (m)",
    "y": " b. kerwaye (m)"
  },
  {
    "x": "kerwi (n)",
    "y": " b. kerwa (n)"
  },
  {
    "x": "kerxane-I (n)",
    "y": " genelev, kerhane"
  },
  {
    "x": "kerxane-II (n)",
    "y": " b. kerrexane (n)"
  },
  {
    "x": "kerzik (n)",
    "y": " b. kerjik (n)"
  },
  {
    "x": "kes, -e",
    "y": " kişi, şahıs, kimse"
  },
  {
    "x": "kes vano",
    "y": " b. kes vano qey"
  },
  {
    "x": "kes vano qay",
    "y": " b. kes vano qey"
  },
  {
    "x": "kes vano qey",
    "y": " sanki"
  },
  {
    "x": "kesa gerrekerdoxe (m)",
    "y": " şikayetçi kişi"
  },
  {
    "x": "kesa waştoxe (m)",
    "y": " talep eden kişi"
  },
  {
    "x": "keso gerrekerdox (n)",
    "y": " şikayetçi kişi"
  },
  {
    "x": "keso waştox (n)",
    "y": " talep eden kişi"
  },
  {
    "x": "keso/a huqûqî",
    "y": " tüzel kişi"
  },
  {
    "x": "keso/kesa ke îstirî çim de yê",
    "y": " sulu göz, hemen ağlayan"
  },
  {
    "x": "keso/kesa ke îştirî çim der ê",
    "y": " b. keso/kesa ke îstirî çim de yê"
  },
  {
    "x": "kesa (m)",
    "y": " kaplumbağa, tosbağa"
  },
  {
    "x": "kesa awî (m)",
    "y": " b. kesaya awîye (m)"
  },
  {
    "x": "kesaya awîye (m)",
    "y": " sukaplumbağası "
  },
  {
    "x": "kesafet (n)",
    "y": " yoğunluk"
  },
  {
    "x": "kesafetpeym (n)",
    "y": " yoğunlukölçer, dansimetre"
  },
  {
    "x": "kesareqe (m)",
    "y": " sukaplumbağası"
  },
  {
    "x": "kesaxur, -e",
    "y": " b. kesexur, -e"
  },
  {
    "x": "kese-I (n)",
    "y": " kaplumbağa, tosbağa"
  },
  {
    "x": "kese-II (n)",
    "y": " kese (yıkanmak için)"
  },
  {
    "x": "kese-III (m)",
    "y": " b. keşe (m)"
  },
  {
    "x": "kesega (n)",
    "y": " b. kesegan, -e"
  },
  {
    "x": "kesegan, -e",
    "y": " domuz"
  },
  {
    "x": "kesek (n)",
    "y": " b. kerseg (n)"
  },
  {
    "x": "keseke-I (m)",
    "y": " kilit (asma kilit)"
  },
  {
    "x": "keseke-II (n)",
    "y": " b. kerseg (n)"
  },
  {
    "x": "keseki (m)",
    "y": " b. keseke-I (m)"
  },
  {
    "x": "kesexur, -e",
    "y": " akbaba"
  },
  {
    "x": "kesha (m)",
    "y": " b. kixsa (m)"
  },
  {
    "x": "keshe (m)",
    "y": " b. kixsa (m)"
  },
  {
    "x": "kesik-I (m)",
    "y": " b. keseke-I (m)"
  },
  {
    "x": "kesik-II (n)",
    "y": " b. kese-I (n) "
  },
  {
    "x": "kesike-I (m)",
    "y": " b. keseke-I (m)"
  },
  {
    "x": "kesike-II (m)",
    "y": " b. kesa (m)"
  },
  {
    "x": "kesî, -ye",
    "y": " özel, hususi"
  },
  {
    "x": "kesîya huqûqîye ya pêroyîye (m)",
    "y": " kamu tüzel kişiliği"
  },
  {
    "x": "kesîya huqûqîye ya umûmî (m)",
    "y": " kamu tüzel kişiliği"
  },
  {
    "x": "kesîf, -e",
    "y": " yoğun"
  },
  {
    "x": "kesîr (n)",
    "y": " kesir"
  },
  {
    "x": "kesîrê desrayinî",
    "y": " ondalık kesirler (mat)"
  },
  {
    "x": "kesîro desrayin",
    "y": " ondalık kesir (mat)"
  },
  {
    "x": "kesîro sade (n)",
    "y": " bayağı kesir (mat)"
  },
  {
    "x": "kesîtî (m)",
    "y": " kişilik, benlik, şahsiyet"
  },
  {
    "x": "kesîye (m)",
    "y": " kişilik"
  },
  {
    "x": "kesk, -e",
    "y": " yeşil "
  },
  {
    "x": "keska (m)",
    "y": " b. keşka (m)"
  },
  {
    "x": "keskena",
    "y": " b. kaşka"
  },
  {
    "x": "keskesûre (m)",
    "y": " b. keskûsûre (m)"
  },
  {
    "x": "keskgeh (n)",
    "y": " yeşil alan"
  },
  {
    "x": "keskîsore (m)",
    "y": " b. keskûsûre (m)"
  },
  {
    "x": "keskosure (m)",
    "y": " b. keskûsûre (m)"
  },
  {
    "x": "keskûsûre (m)",
    "y": " gökkuşağı, alkım, ebekuşağı, ebemkuşağı, eleğimsağma, hacılarkuşağı, yağmurkuşağı, alaimisema"
  },
  {
    "x": "keslan, -e",
    "y": " tembel"
  },
  {
    "x": "keslanîye (m)",
    "y": " tembellik"
  },
  {
    "x": "kesleg (n)",
    "y": " b. kerseg (n)"
  },
  {
    "x": "kesma (m)",
    "y": " b. kesme (n)"
  },
  {
    "x": "kesmase (n)",
    "y": " kalkanbalığı "
  },
  {
    "x": "kesmasi (n)",
    "y": " b. kesmase (n)"
  },
  {
    "x": "kesme (n)",
    "y": " pekmez ile undan yapılan tatlı ve katı bir yiyecek"
  },
  {
    "x": "kesmose (n)",
    "y": " b. kesmase (n)"
  },
  {
    "x": "kestane (n)",
    "y": " kestane "
  },
  {
    "x": "keş",
    "y": " b. kes, -e"
  },
  {
    "x": "keşane (m)",
    "y": " halay (serçe parmakla tutuşarak oynanan)"
  },
  {
    "x": "keşe (m)",
    "y": " 1)apışlık, ağ\r\n~2)kucak\r\n~3)çok (mecazi)\r\n~4)büyük baş dişi hayvanların üreme organı"
  },
  {
    "x": "keşf (n)",
    "y": " b. kifş (n)"
  },
  {
    "x": "keşf bîyayene",
    "y": " b. kifş bîyene"
  },
  {
    "x": "keşf kerdene",
    "y": " b. kifş kerdene"
  },
  {
    "x": "keşfbîyayîş (n)",
    "y": " b. kifşbîyayîş (n)"
  },
  {
    "x": "keşfkerdiş (n)",
    "y": " b. kifşkerdiş (n)"
  },
  {
    "x": "keşi (m)",
    "y": " b. keşe (m)"
  },
  {
    "x": "keşif (n)",
    "y": " b. kifş (n)"
  },
  {
    "x": "keşif bîyayene",
    "y": " b. kifş bîyene"
  },
  {
    "x": "keşif kerdene",
    "y": " b. kifş kerdene"
  },
  {
    "x": "keşifbîyayîş (n)",
    "y": " b. kifşbîyayîş (n)"
  },
  {
    "x": "keşifîyayene",
    "y": " gülümsemek"
  },
  {
    "x": "keşifîyayîş (n)",
    "y": " gülümseme"
  },
  {
    "x": "keşifkerdiş (n)",
    "y": " b. kifşkerdiş (n)"
  },
  {
    "x": "keşî (zh)",
    "y": " apış arası kılları, etek kılları"
  },
  {
    "x": "keşîfîyayîş (n)",
    "y": " b. keşifîyayîş (n)"
  },
  {
    "x": "keşîş (n)",
    "y": " keşiş, rahip"
  },
  {
    "x": "keşk (n)",
    "y": " bir çeşit tarhana çorbası, tarhanayemek"
  },
  {
    "x": "keşka (m)",
    "y": ". 1)b. keşk (n)\r\n~2)dövme, ayran ve kızartılmış yağdan yapılan bir yemek"
  },
  {
    "x": "keşkek (n)",
    "y": " bir çeşit tarhana çorbası"
  },
  {
    "x": "keşkera (m)",
    "y": " b. keşkere (n)"
  },
  {
    "x": "keşkere (n)",
    "y": " yük hayvanıyla taş vb. şeyleri taşımak için kullanılan ve semerin iki tarafına sarkıtılan ağaç dalları veya tahtadan yapılma araç"
  },
  {
    "x": "keşkul (n)",
    "y": " keşkül"
  },
  {
    "x": "keşkwar (n)",
    "y": " b. koşkar-II (n)"
  },
  {
    "x": "keşmîr (n)",
    "y": " kaşmir, keşmir"
  },
  {
    "x": "keştî (m)",
    "y": " gemi"
  },
  {
    "x": "keştîdar, -e",
    "y": " armatör, gemici"
  },
  {
    "x": "keştîkar, -e",
    "y": " gemici"
  },
  {
    "x": "keta (m)",
    "y": " 1)kete\r\n~2)bir çeşit börek"
  },
  {
    "x": "ketaya arêyî (m)",
    "y": " kömbe (küle gömülerek pişirilen mayasız çörek)"
  },
  {
    "x": "kete (n)",
    "y": " b. keta (m)"
  },
  {
    "x": "keten (n)",
    "y": " keten"
  },
  {
    "x": "kevanî (m)",
    "y": " b. kebanî (m), kebanîye (m)"
  },
  {
    "x": "kevav (n)",
    "y": " b. kebab (n)"
  },
  {
    "x": "kevgîr (n)",
    "y": " b. kefgîr (n)"
  },
  {
    "x": "kevnar, -e",
    "y": " arkaik"
  },
  {
    "x": "kevok, -e",
    "y": " güvercin"
  },
  {
    "x": "kevonî (m)",
    "y": " b. kebanî (m), kebanîye (m)"
  },
  {
    "x": "kevra (n)",
    "y": " b. kerwa (n)"
  },
  {
    "x": "kew, -e-I",
    "y": " gri"
  },
  {
    "x": "kew-II (n)",
    "y": " b. kef (n)"
  },
  {
    "x": "kewanî (m)",
    "y": " b. kebanî (m), kebanîye (m)"
  },
  {
    "x": "kewanîye (m)",
    "y": " b. kebanî (m), kebanîye (m)"
  },
  {
    "x": "keware-I (n)",
    "y": " b. kuware (n)"
  },
  {
    "x": "keware-II (m)",
    "y": " b. kuware (n)"
  },
  {
    "x": "kewara mêşanê hemgênî (n)",
    "y": " b. kuwareyê mêsanê hingimênî (n)"
  },
  {
    "x": "kewase (n)",
    "y": " b. kewaşe (n)"
  },
  {
    "x": "kewaş (n)",
    "y": " tütün bulunamayınca onun yerine içilen kurumuş ağaç/bitki yaprağı"
  },
  {
    "x": "kewaşe (n)",
    "y": " 1)gazel (yaprak için)\r\n~2)binanın çatısını kaplamakta kullanılan yaprak, sap, ot vb."
  },
  {
    "x": "kewaw (n)",
    "y": " b. kebab (n)"
  },
  {
    "x": "kewçik (m)",
    "y": " b. koçike (m)"
  },
  {
    "x": "kewçike (m)",
    "y": " b. koçike (m)"
  },
  {
    "x": "kewçika darêne (m)",
    "y": " b. koçika darêne (m)"
  },
  {
    "x": "kewçika sîwaxî (m)",
    "y": " b. koçika siwaxî (m)"
  },
  {
    "x": "kewçike kerdene",
    "y": " b. koçike kerdene"
  },
  {
    "x": "kewçikekerdiş (n)",
    "y": " b. koçikekerdiş (n)"
  },
  {
    "x": "kewçiki (m)",
    "y": " b. koçike (m)"
  },
  {
    "x": "kewe, -ye-I",
    "y": " mavi"
  },
  {
    "x": "kewe-II (m)",
    "y": " dişi keklik"
  },
  {
    "x": "kewa gozele (m)",
    "y": " kınalı keklik"
  },
  {
    "x": "Kewe (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "kewer (n)",
    "y": " b. keyber (n)"
  },
  {
    "x": "kewi",
    "y": " b. kewe, -ye-I"
  },
  {
    "x": "Kewi (m)",
    "y": " Kewe (m)"
  },
  {
    "x": "kewin, -i",
    "y": " b. kefin, -e"
  },
  {
    "x": "kewîye (m)",
    "y": " mavi"
  },
  {
    "x": "kewndêz (n)",
    "y": " b. kondêz (n) "
  },
  {
    "x": "kewnêr (n)",
    "y": " erkek keklik"
  },
  {
    "x": "kewo (n)",
    "y": " b. kewe, -ye-I"
  },
  {
    "x": "kewonî (m)",
    "y": " b. kebanî (m), kebanîye (m)"
  },
  {
    "x": "kewose (n)",
    "y": " b. kewaşe (n)"
  },
  {
    "x": "kewoye (m)",
    "y": " b. kewîye (m)"
  },
  {
    "x": "kewra, -ye",
    "y": " b. kerwa, -ye"
  },
  {
    "x": "kewran (n)",
    "y": " b. karwan (n)"
  },
  {
    "x": "kewrançî",
    "y": " b. karwançî, -ye"
  },
  {
    "x": "kewrî (n)",
    "y": " b. kuware (n)"
  },
  {
    "x": "kewş (n)",
    "y": " b. kewaşe (n)"
  },
  {
    "x": "kewte (n)",
    "y": " kısık (ses için), basık (ses için) "
  },
  {
    "x": "kewtene",
    "y": " 1)düşmek\r\n~a kewena: o düşüyor\r\n~o keweno: o düşüyor\r\n~2)(kar için) yağmak"
  },
  {
    "x": "a kewena",
    "y": " o düşüyor"
  },
  {
    "x": "a kewna",
    "y": " b. a kewena"
  },
  {
    "x": "o keweno",
    "y": " o düşüyor"
  },
  {
    "x": "o kewno",
    "y": " b. o keweno"
  },
  {
    "x": "kewtene derdê ...",
    "y": " derdine düşmek\r\n~O kewto derdê ganê xo. (O canının derdine düşmüş.)"
  },
  {
    "x": "kewtene etira ...",
    "y": " derdine düşmek\r\n~Ti kewta etira domananê/qicanê xo. (Sen çocuklarının derdine düşmüşsün.)"
  },
  {
    "x": "kewtene vayî ver",
    "y": " b. vayî ver kewtene"
  },
  {
    "x": "kewteyîye (m)",
    "y": " basıklık (ses için) "
  },
  {
    "x": "kewtiş (n)",
    "y": " 1)düşme\r\n~2)(kar için) yağma"
  },
  {
    "x": "kewtiş vayî ver",
    "y": " b. vayîverkewtiş (n)"
  },
  {
    "x": "kewûnî (m)",
    "y": " b. kebanî (m), kebanîye (m)"
  },
  {
    "x": "key-I",
    "y": " ne zaman, ne vakit"
  },
  {
    "x": "key-II (n)",
    "y": " b. kay (n)"
  },
  {
    "x": "key dayene",
    "y": " b. kay dayene"
  },
  {
    "x": "key kerdene",
    "y": " b. kay kerdene"
  },
  {
    "x": "keya, -ye",
    "y": " muhtar"
  },
  {
    "x": "keyawaney (m)",
    "y": " b. keyeawanîye (m)"
  },
  {
    "x": "keyawoney (m)",
    "y": " b. keyeawanîye (m)"
  },
  {
    "x": "keyawûney (m)",
    "y": " b. keyeawanîye (m)"
  },
  {
    "x": "keyawûnê (m)",
    "y": " b. keyeawanîye (m)"
  },
  {
    "x": "keyayîye (m)",
    "y": " muhtarlık"
  },
  {
    "x": "keyber (n)",
    "y": " 1)evin önü\r\n~2)ev önündeki bahçe veya bostan\r\n~3)kapı\r\n~4)bap"
  },
  {
    "x": "keyberê vejîyayîşê acîlî (n)",
    "y": " acil çıkış kapısı"
  },
  {
    "x": "keyberê vejîyayîşê lezabezî (n)",
    "y": " acil çıkış kapısı"
  },
  {
    "x": "keyberê vejîyayîşê lezkanî (n)",
    "y": " acil çıkış kapısı"
  },
  {
    "x": "keydayîş (n)",
    "y": " b. kaydayîş (n)"
  },
  {
    "x": "keye-I (n)",
    "y": " 1)ev, hane\r\n~2)aile"
  },
  {
    "x": "keye û kuflet",
    "y": " b. keye û kulfet"
  },
  {
    "x": "keye û kulfet",
    "y": " çoluk çocuk"
  },
  {
    "x": "keye w kulfet",
    "y": " b. keye û kulfet"
  },
  {
    "x": "Keyeyê to awan bo. ",
    "y": "Teşekkür ederim."
  },
  {
    "x": "Keyeyê to şên bo. ",
    "y": "Teşekkür ederim."
  },
  {
    "x": "keyeyê xo ardene ...",
    "y": " taşınmak (başka bir yere gitmek, göçmek)"
  },
  {
    "x": "keyeyê xo ardiş ...",
    "y": " taşınma (başka bir yere gitme, göçme)"
  },
  {
    "x": "keyeyê xo berdene ...",
    "y": " taşınmak (başka bir yere gitmek, göçmek)\r\n~Mi keyeyê xo berd Dêrsim. (Dêrsim’e taşındım.)"
  },
  {
    "x": "keyeyê xo berdiş ...",
    "y": " taşınma (başka bir yere gitme, göçme)"
  },
  {
    "x": "keyeyê xo kiriştene ...",
    "y": " taşınmak (başka bir yere gitmek, göçmek)\r\n~Mi keyeyê xo kirişt Dêrsim. (Dêrsim’e taşındım.)"
  },
  {
    "x": "keyeyê xo kiriştiş ...",
    "y": " taşınma (başka bir yere gitme, göçme)"
  },
  {
    "x": "keye-II (n)",
    "y": " b. kehen (n), kan (n)"
  },
  {
    "x": "keyeawanîye (m)",
    "y": " teşekkür"
  },
  {
    "x": "keyedestekê perwerdeyî",
    "y": " eğitim destekevi"
  },
  {
    "x": "keyeker, -e",
    "y": " evcimen, hamarat "
  },
  {
    "x": "keyekeri (m)",
    "y": " b. keyekere (m)"
  },
  {
    "x": "keyen, -i",
    "y": " b. kehen, -e; kan, -e"
  },
  {
    "x": "keyeney (m)",
    "y": " b. kehenîye (m)"
  },
  {
    "x": "keyepel (n) (TE)",
    "y": " ana sayfa"
  },
  {
    "x": "keyer (m)",
    "y": " b. karî (m)"
  },
  {
    "x": "keyeûkulfet (n)",
    "y": " cümbür cemaat "
  },
  {
    "x": "keyewkulfet (n)",
    "y": " b. keyeûkulfet (n)"
  },
  {
    "x": "keyf (n)",
    "y": " keyif, neşe, sevinç"
  },
  {
    "x": "keyf kerdene",
    "y": " 1)neşelenmek, keyfetmek, keyiflenmek, sevinmek, memnun kalmak, memnun olmak \r\n~Werfe senî ke maya xo dîya keyf kerdo. (Werfe annesini görür görmez sevinmiş.)\r\n~2)eğlenmek"
  },
  {
    "x": "keyf têameyene",
    "y": " beğenmek"
  },
  {
    "x": "keyf û şayî kerdene",
    "y": " eğlenmek"
  },
  {
    "x": "keyfê ... ameyene",
    "y": " neşelenmek, keyiflenmek, sevinmek, memnun kalmak, memnun olmak\r\n~Zaf keyfê mi ame. (Çok sevindim.)"
  },
  {
    "x": "keyfê ... ardene",
    "y": " neşelendirmek, keyiflendirmek, sevindirmek, memnun etmek\r\n~To ewro keyfê mi ard. (Bugün beni sevindirdin.)"
  },
  {
    "x": "keyfê xo pê ameyene",
    "y": " beğenmek"
  },
  {
    "x": "keyfî ... ameyîne",
    "y": " b. keyfê ... ameyene"
  },
  {
    "x": "keyfî ... ardene",
    "y": " b. keyfê ... ardene"
  },
  {
    "x": "keyfêxopêameyîş (n)",
    "y": " beğenme"
  },
  {
    "x": "keyfin, -e",
    "y": " keyifli"
  },
  {
    "x": "keyfkerdiş (n)",
    "y": " eğlenme"
  },
  {
    "x": "keyflî, -ye",
    "y": " keyifli"
  },
  {
    "x": "keyfûşayî (m)",
    "y": " neşe, sevinç"
  },
  {
    "x": "keyfûşayîkerdiş (n)",
    "y": " eğlenme"
  },
  {
    "x": "keyfweş, -e",
    "y": " neşeli, şen, sevinçli, keyifli"
  },
  {
    "x": "keyfweş bîyene",
    "y": " neşelenmek, keyiflenmek, sevinmek, memnun kalmak, memnun olmak"
  },
  {
    "x": "keyfweş kerdene",
    "y": " neşelendirmek, keyiflendirmek, sevindirmek, memnun etmek"
  },
  {
    "x": "keyfweşî (m)",
    "y": " neşe, sevinç, şenlik (şen olma durumu)"
  },
  {
    "x": "keyfweşîye (m)",
    "y": " neşe, sevinç, şenlik (şen olma durumu)"
  },
  {
    "x": "keyftêameyîş (n)",
    "y": " beğenme"
  },
  {
    "x": "keyi (n)",
    "y": " b. keye-I (n)"
  },
  {
    "x": "keyîf (n)",
    "y": " b. keyf (n)"
  },
  {
    "x": "keykerdiş (n)",
    "y": " b. kaykerdiş (n)"
  },
  {
    "x": "keykerdox, -e",
    "y": " b. kaykerdox, -e"
  },
  {
    "x": "keyna (m)",
    "y": " b. kêna (n)"
  },
  {
    "x": "keyna emiki (m)",
    "y": " b. kêna emike (m) "
  },
  {
    "x": "keyna torne (m)",
    "y": " b. kêna torne (m) "
  },
  {
    "x": "keyna tornî (m)",
    "y": " b. kêna tornî (m) "
  },
  {
    "x": "keyna tuerni (m)",
    "y": " b. kêna torne (m)"
  },
  {
    "x": "keyna tuernî (m)",
    "y": " b. kêna tornî (m)"
  },
  {
    "x": "keyna xali (m)",
    "y": " b. kêna xale (m) "
  },
  {
    "x": "keyna xalî (m)",
    "y": " b. kêna xalî (m)"
  },
  {
    "x": "keyna yayki (m)",
    "y": " b. yaykêna (m)"
  },
  {
    "x": "keyna yeyki (m)",
    "y": " b. yaykêna (m)"
  },
  {
    "x": "keynay xalî (m)",
    "y": " b. kêna xalî (m)"
  },
  {
    "x": "keyney xalî (m)",
    "y": " b. kêna xalî (m)"
  },
  {
    "x": "keynek (m)",
    "y": " b. kêneke (m)"
  },
  {
    "x": "keyneke (m)",
    "y": " b. kêneke (m) "
  },
  {
    "x": "keyneki (m)",
    "y": " b. kêneke (m) "
  },
  {
    "x": "keyrd (m)",
    "y": " b. kardî (m)"
  },
  {
    "x": "keyri (m)",
    "y": " b. karî (m)"
  },
  {
    "x": "keys (n)",
    "y": " fırsat"
  },
  {
    "x": "keyver (n)",
    "y": " b. keyber (n)"
  },
  {
    "x": "keyveşaya (m)",
    "y": " evi yanasıca"
  },
  {
    "x": "keyveşaye (n)",
    "y": " evi yanasıca"
  },
  {
    "x": "keyxwa (n)",
    "y": " kâhya"
  },
  {
    "x": "kez, -e",
    "y": " b. kej, -e"
  },
  {
    "x": "kezeb (m)",
    "y": " b. kezebe (m)"
  },
  {
    "x": "kezebe (m)",
    "y": " karaciğer"
  },
  {
    "x": "kezeba ... rê socîyayene",
    "y": " çok acımak"
  },
  {
    "x": "kezeba ... rêsocîyayîş",
    "y": " çok acıma"
  },
  {
    "x": "kezebpêt, -e",
    "y": " cesaretli, cesur, yürekli"
  },
  {
    "x": "kezebpêt bîyene",
    "y": " cesaretlenmek"
  },
  {
    "x": "kezebpêtbîyayîş (n)",
    "y": " cesaretlenme"
  },
  {
    "x": "kezebpêtê (m)",
    "y": " b. kezebpêtî (m)"
  },
  {
    "x": "kezebpêti (m)",
    "y": " b. kezebpête (m)"
  },
  {
    "x": "kezebpêtî (m)",
    "y": " cesaretlilik, cesurluk, yüreklilik"
  },
  {
    "x": "kezgere (n)",
    "y": " b. keşkere (n)"
  },
  {
    "x": "kê-I",
    "y": " kim"
  },
  {
    "x": "kê-II (n)",
    "y": " b. keye (n)"
  },
  {
    "x": "Kê to awon bo. ",
    "y": "b. Keyeyê to awan bo"
  },
  {
    "x": "Kê to şên bo. ",
    "y": "b. Keyeyê to şên bo."
  },
  {
    "x": "Kê tu awûn bû. ",
    "y": "b. Keyeyê to awan bo."
  },
  {
    "x": "Kê tu şên bû. ",
    "y": "b. Keyeyê to şên bo."
  },
  {
    "x": "kê-III",
    "y": " kes, -e"
  },
  {
    "x": "kê vanê qay",
    "y": " b. kes vano qey"
  },
  {
    "x": "kê vanê qey",
    "y": " b. kes vano qey"
  },
  {
    "x": "kê vanî qay",
    "y": " b. kes vano qey"
  },
  {
    "x": "kê vanî qey",
    "y": " b. kes vano qey"
  },
  {
    "x": "kê vano qay",
    "y": " b. kes vano qey"
  },
  {
    "x": "kê vano qey",
    "y": " b. kes vano qey"
  },
  {
    "x": "kê-IV",
    "y": " b. key-I"
  },
  {
    "x": "kêbanî (m)",
    "y": " b. kebanî (m), kebanîye (m)"
  },
  {
    "x": "kêbanîye (m)",
    "y": " b. kebanî (m), kebanîye (m)"
  },
  {
    "x": "kêber (n)",
    "y": " b. keyber (n)"
  },
  {
    "x": "kêf (n)",
    "y": " b. keyf (n)"
  },
  {
    "x": "kêf kerdene",
    "y": " b. keyf kerdene"
  },
  {
    "x": "kêf û şayî kerdene",
    "y": " b. keyf û şayî kerdene"
  },
  {
    "x": "kêfê ... ardene",
    "y": " b. keyfê ... ardene"
  },
  {
    "x": "kêfê xo pê amayene",
    "y": " b. keyfê xo pê ameyene"
  },
  {
    "x": "kêfêxopêamayîş (n)",
    "y": " keyfêxopêameyîş (n)"
  },
  {
    "x": "kêfin, -e",
    "y": " b. keyfin, -e"
  },
  {
    "x": "kêfini (m)",
    "y": " b. keyfine (m)"
  },
  {
    "x": "kêfîl, -e",
    "y": " b. kefîl, -e"
  },
  {
    "x": "kêfkerdiş (n)",
    "y": " b. keyfkerdiş (n)"
  },
  {
    "x": "kêflî",
    "y": " b. keyflî, -ye"
  },
  {
    "x": "kêfûşayîkerdiş (n)",
    "y": " b. keyfûşayîkerdiş (n)"
  },
  {
    "x": "kêfweşî (m)",
    "y": " b. keyfweşîye (m), keyfweşî (m)"
  },
  {
    "x": "kêfweşîye (m)",
    "y": " b. keyfweşîye (m), keyfweşî (m)"
  },
  {
    "x": "kêhar (m)",
    "y": " b. karî (m)"
  },
  {
    "x": "kêk (m)",
    "y": " b. keke (m)"
  },
  {
    "x": "kêko",
    "y": " b. keko"
  },
  {
    "x": "kêl-I (m)",
    "y": " b. kêle (m)"
  },
  {
    "x": "kêl kerdene",
    "y": " b. kêle kerdene"
  },
  {
    "x": "kêl-II (n)",
    "y": " b. kel-I (n)"
  },
  {
    "x": "kêla binê adirî (m)",
    "y": " kömbe (küle gömülerek pişirilen mayasız çörek)"
  },
  {
    "x": "kêla binî adirî (m)",
    "y": " b. kêla binê adirî (m)"
  },
  {
    "x": "kêlame (n)",
    "y": " b. kelame (n)"
  },
  {
    "x": "kêle (m)",
    "y": " teyel, teğel"
  },
  {
    "x": "kêle kerdene",
    "y": " teyel yapmak, teyel atmak, teyellemek"
  },
  {
    "x": "kêlek (m)",
    "y": " b. kaleke (m)"
  },
  {
    "x": "kêlendur (m)",
    "y": " b. kelendire (m)"
  },
  {
    "x": "kêlendûre (m)",
    "y": " b. kelendire (m)"
  },
  {
    "x": "kêlendûre kerdene",
    "y": " b. kelendire kerdene"
  },
  {
    "x": "kêleze (n)",
    "y": " b. kelce (n)"
  },
  {
    "x": "kêlindkêş, -e",
    "y": " b. kelendirkêş, -e"
  },
  {
    "x": "kêlindkêşî",
    "y": " b. kelendirkêşî (m)"
  },
  {
    "x": "kêlindkêşîye (m)",
    "y": " b. kelendirkêşîye (m)"
  },
  {
    "x": "kêlinte (m)",
    "y": " b. kelendire (m)"
  },
  {
    "x": "kêlinti (m)",
    "y": " b. kelendire (m)"
  },
  {
    "x": "kêlî (n)",
    "y": " b. kolî (n)"
  },
  {
    "x": "kêlîce (n)",
    "y": " b. kelce (n) "
  },
  {
    "x": "kêlkerda (m)",
    "y": " teyelli"
  },
  {
    "x": "kêlkerde (n)",
    "y": " teyelli"
  },
  {
    "x": "kêlkerdîye (m)",
    "y": " teyelli"
  },
  {
    "x": "kêlp, -e",
    "y": " b. kelp, -e"
  },
  {
    "x": "kêlpenî",
    "y": " b. kelpênî (m)"
  },
  {
    "x": "kêlpîç (n)",
    "y": " b. kerpîç (n)"
  },
  {
    "x": "kêm",
    "y": " b. kêmî"
  },
  {
    "x": "kêm kerdene",
    "y": " b. kêmî kerdene"
  },
  {
    "x": "kêmane (n)",
    "y": " 1)eksiklik\r\n~2)kusur"
  },
  {
    "x": "kêmanîya aqilî (m)",
    "y": " akıl zayıflığı"
  },
  {
    "x": "kêmanê (m)",
    "y": " b. kêmîye (m)"
  },
  {
    "x": "kêmanî (m)",
    "y": " 1)eksiklik\r\n~2)kusur"
  },
  {
    "x": "kêmaqilî (m)",
    "y": " akıl zayıflığı"
  },
  {
    "x": "kêmek (n)",
    "y": " eksi (mat)"
  },
  {
    "x": "kêmere (m)",
    "y": " b. kemere (m)"
  },
  {
    "x": "kêmê (m)",
    "y": " b. kêmîye (m)"
  },
  {
    "x": "kêmênayene",
    "y": " b. kêmînayene"
  },
  {
    "x": "kêmênayîş (n)",
    "y": " b. kêmînayîş (n)"
  },
  {
    "x": "kêmênî (m) (MÇ)",
    "y": " kêmanî (m)"
  },
  {
    "x": "kêmgonîye (m)",
    "y": " kansızlık (tıpta), anemi"
  },
  {
    "x": "kêmgonîya Deryayê Sipî (m)",
    "y": " Akdeniz anemisi"
  },
  {
    "x": "kêmî",
    "y": " 1)az\r\n~2)eksik\r\n~3)cılız"
  },
  {
    "x": "kêmî bîyene",
    "y": " azalmak"
  },
  {
    "x": "kêmî kerdene",
    "y": " 1)azaltmak\r\n~2)kısmak (ses, ışık vb. için)\r\n~3)büzmek"
  },
  {
    "x": "kêmê kêman",
    "y": " en az, asgari"
  },
  {
    "x": "kêmî-zêde",
    "y": " aşağı yukarı, yaklaşık, takriben, yaklaşık olarak, takribî"
  },
  {
    "x": "kêmîbîyaya (m)",
    "y": " azalmış olan"
  },
  {
    "x": "kêmîbîyaye (n)",
    "y": " azalmış olan"
  },
  {
    "x": "kêmîbîyayîş (n)",
    "y": " azalma, "
  },
  {
    "x": "kêmîkerda (m) ",
    "y": "kısık (ses, ışık vb. için)"
  },
  {
    "x": "kêmîkerde (n) ",
    "y": "kısık (ses, ışık vb. için)"
  },
  {
    "x": "kêmîkerdiş (n)",
    "y": " 1)azaltma\r\n~2)kısma (ses, ışık vb. için)\r\n~3)indirim, iskonto, tenzilat\r\n~4)büzme"
  },
  {
    "x": "kêmîkerdî, -ye",
    "y": "  kısık (ses, ışık, haklar vb. için)"
  },
  {
    "x": "kêmîkerdox, -e",
    "y": " azaltan"
  },
  {
    "x": "kêmînayene",
    "y": "azaltmak"
  },
  {
    "x": "kêmînayîş (n)",
    "y": " azaltma"
  },
  {
    "x": "kêmînayox, -e",
    "y": " azaltan"
  },
  {
    "x": "kêmîvejîyayîşê butçe (n)",
    "y": " bütçe açığı"
  },
  {
    "x": "kêmîyaya (m)",
    "y": " azalmış olan"
  },
  {
    "x": "kêmîyaye (n)",
    "y": " azalmış olan"
  },
  {
    "x": "kêmîyayene",
    "y": " azalmak"
  },
  {
    "x": "kêmîyayîş (n)",
    "y": " azalma"
  },
  {
    "x": "kêmîye (m)",
    "y": " eksiklik"
  },
  {
    "x": "kêmîya baldarî (m)",
    "y": " dikkat eksikliği"
  },
  {
    "x": "kêmîya bale (m)",
    "y": " dikkat eksikliği"
  },
  {
    "x": "kêmkerdiş (n)",
    "y": " b. kêmîkerdiş (n)"
  },
  {
    "x": "kêmkerdox, -e",
    "y": " b. kêmîkerdox, -e"
  },
  {
    "x": "kêmnayox, -e",
    "y": " b. kêmînayox, -e"
  },
  {
    "x": "kêmver, -e",
    "y": " ensiz, dar"
  },
  {
    "x": "kêna (m)",
    "y": " kız"
  },
  {
    "x": "kêna apî (m)",
    "y": " amca kızı, amcazade"
  },
  {
    "x": "kêna eme (m)",
    "y": " hala kızı"
  },
  {
    "x": "kêna emike (m)",
    "y": " hala kızı"
  },
  {
    "x": "kêna torne (m)",
    "y": " kız torunun kızı"
  },
  {
    "x": "kêna tornî (m)",
    "y": " erkek torunun kızı"
  },
  {
    "x": "kêna xale (m)",
    "y": " teyze kızı, teyzezade"
  },
  {
    "x": "kêna xalî (m)",
    "y": " dayı kızı, dayızade"
  },
  {
    "x": "kênapî (m)",
    "y": " amca kızı, amcazade"
  },
  {
    "x": "kênat (n)",
    "y": " b. kîn (n)"
  },
  {
    "x": "kênek (m)",
    "y": " b. kêneke (m)"
  },
  {
    "x": "kêneke (m)",
    "y": " kız\r\n~Kêneke uca ya/weyra ya. (Kız oradadır.) "
  },
  {
    "x": "kêneki (m)",
    "y": " b. kêneke (m)"
  },
  {
    "x": "kêr (m)",
    "y": " b. karî (m)"
  },
  {
    "x": "kêr, -e",
    "y": " 1)yiğit, alp, kahraman\r\n~2)cesaretli, cesur, yürekli"
  },
  {
    "x": "kêr bîyene",
    "y": " cesaretlenmek"
  },
  {
    "x": "kêran (n)",
    "y": " 1)kiriş, binanın çatısını taşıması için tavanda yatay olarak kullanılan çok kalın direk\r\n~2)taş duvara yatay olarak boydan boya yerleştirilmiş kalın direk"
  },
  {
    "x": "kêrang (n)",
    "y": " b. kêran (n)"
  },
  {
    "x": "kêranîye (m)",
    "y": " babayiğitlik (mecazi)"
  },
  {
    "x": "kêrbîyayîş (n)",
    "y": " cesaretlenme"
  },
  {
    "x": "kêrdî (m)",
    "y": " b. kardî (m)"
  },
  {
    "x": "kêrge (m)",
    "y": " b. kerge-I (m)"
  },
  {
    "x": "kêri (m)",
    "y": " b. karî (m)"
  },
  {
    "x": "kêrm (n)",
    "y": " b. kerm (n)"
  },
  {
    "x": "kêrm û çes",
    "y": " b. kerm û kêz"
  },
  {
    "x": "kêrm û çês",
    "y": " b. kerm û kêz"
  },
  {
    "x": "kêrme (n) (elewîyîye de)",
    "y": " b. kerme (n) (elewîyîye de)"
  },
  {
    "x": "kêrme bîyayene (elewîyîye de)",
    "y": " b. kerme bîyene  (elewîyîye de)"
  },
  {
    "x": "kêrmezin, -e",
    "y": " b. kermijin, -e"
  },
  {
    "x": "kêrmin, -e",
    "y": " b. kermin, -e"
  },
  {
    "x": "kêro (n)",
    "y": " b. kêran (n)"
  },
  {
    "x": "kêron (n)",
    "y": " b. kêran (n)"
  },
  {
    "x": "kêrxane (n)",
    "y": " b. kerxane-I (n)"
  },
  {
    "x": "kês-I (n)",
    "y": " iri saman, ot samanı"
  },
  {
    "x": "kês, -e",
    "y": " iyi\r\n~Ez bikês o./Ez kês a. (Ben iyiyim.)"
  },
  {
    "x": "kês-III (n)",
    "y": " b. kes (n)"
  },
  {
    "x": "kês-IV (n)",
    "y": " b. kîse (n)"
  },
  {
    "x": "kêsegan, -e",
    "y": " b. kesegan, -e"
  },
  {
    "x": "kêsik-I (n)",
    "y": " b. kîsik (n)"
  },
  {
    "x": "kêsik-II (n)",
    "y": " b. kese-I (n)"
  },
  {
    "x": "kêsike (m)",
    "y": " b. kesa (m)"
  },
  {
    "x": "kêş-I",
    "y": " b. kes, -e"
  },
  {
    "x": "kêş-II (m)",
    "y": " b. kêşe (m)"
  },
  {
    "x": "kêş kerdene",
    "y": " b. kêşe kerdene"
  },
  {
    "x": "kêşe (m)",
    "y": " 1)istif, yığın\r\n~2)stok (yığılım, yığımlık)"
  },
  {
    "x": "kêşe kerdene",
    "y": " istiflemek, istif etmek, yığmak, loda etmek"
  },
  {
    "x": "kêşfîyayîş (n)",
    "y": " b. keşifîyayîş (n)"
  },
  {
    "x": "kêşi (m)",
    "y": " b. kêşe (m)"
  },
  {
    "x": "kêşi kardene",
    "y": " b. kêşe kerdene"
  },
  {
    "x": "kêşifîyayîş (n)",
    "y": " b. keşifîyayîş (n)"
  },
  {
    "x": "kêşme (n)",
    "y": " yüznumara, ayakyolu, abdesthane, apteshane, hela, kademhane, kenef, memişhane, tuvalet"
  },
  {
    "x": "kête (n)",
    "y": " b. keta (m)"
  },
  {
    "x": "kêvanî (m)",
    "y": " b. kebanî (m), kebanîye (m)"
  },
  {
    "x": "kêvanîye (m)",
    "y": " b. kebanî (m), kebanîye (m)"
  },
  {
    "x": "kêver (n)",
    "y": " b. keyber (n)"
  },
  {
    "x": "kêveşaya (m)",
    "y": " b. keyveşaya (m)"
  },
  {
    "x": "kêveşaye (n)",
    "y": " b. keyveşaye (n)"
  },
  {
    "x": "kêwa, -ye",
    "y": " b. keya, -ye"
  },
  {
    "x": "kêwanîye (m)",
    "y": " b. kebanî (m), kebanîye (m)"
  },
  {
    "x": "kêwase (n)",
    "y": " b. kewaşe (n)"
  },
  {
    "x": "kêwe (n)",
    "y": " b. kewe, -ye-I"
  },
  {
    "x": "kêwer (n)",
    "y": " b. keyber (n)"
  },
  {
    "x": "kêwîye (m)",
    "y": " mavi"
  },
  {
    "x": "kêwra , -ye",
    "y": " b. kerwa, -ye"
  },
  {
    "x": "kêwtene",
    "y": " b. kewtene"
  },
  {
    "x": "kêwtiş (n)",
    "y": " b. kewtiş (n)"
  },
  {
    "x": "kêy (n)",
    "y": " b. kay (n)"
  },
  {
    "x": "kêy dayene",
    "y": " b. kay dayene"
  },
  {
    "x": "kêy kerdene",
    "y": " b. kay kerdene"
  },
  {
    "x": "kêya (n)",
    "y": " b. keyxwa (n), keya (n)"
  },
  {
    "x": "kêyber (n)",
    "y": " b. keyber (n)"
  },
  {
    "x": "kêydayîş (n)",
    "y": " b. kaydayîş (n)"
  },
  {
    "x": "kêye (n)",
    "y": " b. keye-I (n)"
  },
  {
    "x": "kêykerdiş (n)",
    "y": " b. kaykerdiş (n)"
  },
  {
    "x": "kêyna (m)",
    "y": " b. kêna (n)"
  },
  {
    "x": "kêz, -e",
    "y": " b. kej, -e "
  },
  {
    "x": "kêze (m)",
    "y": " böcek"
  },
  {
    "x": "kêzike (m)",
    "y": " böcek"
  },
  {
    "x": "kêzgere (n)",
    "y": " b. keşkere (n)"
  },
  {
    "x": "kheyd",
    "y": " b. kedî, -ye"
  },
  {
    "x": "kheydkerdîs (n)",
    "y": " b. kedîkerdiş (n)"
  },
  {
    "x": "ki-I",
    "y": " b. ke-I"
  },
  {
    "x": "ki-II",
    "y": " b. eke-I"
  },
  {
    "x": "kibab (n)",
    "y": " b. kebab (n)"
  },
  {
    "x": "kibrît (n)",
    "y": " kibrit"
  },
  {
    "x": "kic (m)",
    "y": " b. kaje (m)"
  },
  {
    "x": "kiç",
    "y": " b. kes, -e"
  },
  {
    "x": "kiçî",
    "y": " b. kes, -e"
  },
  {
    "x": "kidê kidê",
    "y": " gelmesi için oğlağı çağırma sırasında kullanılan sözcükler "
  },
  {
    "x": "kidi kidi",
    "y": " b. kidê kidê"
  },
  {
    "x": "kidî",
    "y": " oğlağı kovmak için kullanılan bir sözcük"
  },
  {
    "x": "kidî kidî",
    "y": " b. kidê kidê"
  },
  {
    "x": "kifik (n)",
    "y": " b. kufike (m)"
  },
  {
    "x": "kifikîyayene",
    "y": " b. kufikîyayene"
  },
  {
    "x": "kifikîyayîş (n)",
    "y": " b. kufikîyayîş (n)"
  },
  {
    "x": "kifiknayene",
    "y": " b. kufiknayene"
  },
  {
    "x": "kifiknayîş (n)",
    "y": " b. kufiknayîş (n)"
  },
  {
    "x": "kifir (n)",
    "y": " sövgü, küfür"
  },
  {
    "x": "kifir kerdene",
    "y": " sövmek, küfretmek"
  },
  {
    "x": "kifirbaz, -e",
    "y": " küfürbaz, ağzı bozuk, ağzı pis"
  },
  {
    "x": "kifirkerdiş (n)",
    "y": " sövme, küfretme"
  },
  {
    "x": "kifkarik (m)",
    "y": " b. kufkarike (m)"
  },
  {
    "x": "kifkin, -e",
    "y": " b. kufikin, -e"
  },
  {
    "x": "kifr (n)",
    "y": " b. kifir (n)"
  },
  {
    "x": "kifr kerdene",
    "y": " b. kifir kerdene"
  },
  {
    "x": "kifrî kerdene",
    "y": " b. kifir kerdene"
  },
  {
    "x": "kifrîkerdiş (n)",
    "y": " b. kifirkerdiş (n)"
  },
  {
    "x": "kifrît (n)",
    "y": " b. kibrît (n)"
  },
  {
    "x": "kifrkerdiş (n)",
    "y": " b. kifirkerdiş (n)"
  },
  {
    "x": "kifş, -e",
    "y": " 1)belli\r\n~2)keşif"
  },
  {
    "x": "kifş bîyayîne",
    "y": " b. kifş bîyene"
  },
  {
    "x": "kifş bîyene",
    "y": " 1)belli olmak, belirginleşmek\r\n~2)bulunmak"
  },
  {
    "x": "kifş kerdene",
    "y": " 1)belirlemek, belli etmek, belirtmek\r\n~2)bulmak"
  },
  {
    "x": "kifşbîyayîş (n)",
    "y": " 1)belirginleşme\r\n~2)bulunma"
  },
  {
    "x": "kifşe",
    "y": " b. kifş"
  },
  {
    "x": "kifşe bîyayene",
    "y": " b. kifş bîyene"
  },
  {
    "x": "kifşe kerdene",
    "y": " b. kifş kerdene"
  },
  {
    "x": "kifşebîyayîş (n)",
    "y": " b. kifşbîyayîş (n)"
  },
  {
    "x": "kifşekerdiş (n)",
    "y": " b. kifşkerdiş (n)"
  },
  {
    "x": "kifşkerdiş (n)",
    "y": "1)belirleme, belli etme, belirtme\r\n~2)bulma"
  },
  {
    "x": "kift (n)",
    "y": " omuz"
  },
  {
    "x": "kifta (m)",
    "y": " b. kufta (m)"
  },
  {
    "x": "kiftike (m)",
    "y": " b. kuftike (m)"
  },
  {
    "x": "kihan",
    "y": " b. kehen, -e; kan, -e"
  },
  {
    "x": "kihaney (m)",
    "y": " b. kehenîye (m)"
  },
  {
    "x": "kihar (m)",
    "y": " b. karî (m)"
  },
  {
    "x": "kihari (m)",
    "y": " b. karî (m)"
  },
  {
    "x": "kiharî (m)",
    "y": " b. karî (m)"
  },
  {
    "x": "kihêl, -e",
    "y": " (soylu Arap atı) küheylan"
  },
  {
    "x": "kihêli (m)",
    "y": " b. kihêl, -e"
  },
  {
    "x": "kiho (n)",
    "y": " b. kewe, -ye-I"
  },
  {
    "x": "kihon",
    "y": " b. kehen, -e; kan, -e"
  },
  {
    "x": "kihonê (m)",
    "y": " b. kehenî (m)"
  },
  {
    "x": "kihoy (m)",
    "y": " b. kewîye (m)"
  },
  {
    "x": "kihoye (m)",
    "y": " b. kewîye (m)"
  },
  {
    "x": "kihû-I (n)",
    "y": " b. kewe, -ye-I"
  },
  {
    "x": "kihû-II",
    "y": " b. kehen, -e; kan, -e"
  },
  {
    "x": "kihûn",
    "y": " b. kehen, -e; kan, -e"
  },
  {
    "x": "kihûn bîyayene",
    "y": " b. kehen bîyene"
  },
  {
    "x": "kihûn kerdene",
    "y": " b. kehen kerdene"
  },
  {
    "x": "kihûnbîyayîş (n)",
    "y": " b. kehenbîyayîş (n)"
  },
  {
    "x": "kihûnê (m)",
    "y": " b. kehenî (m)"
  },
  {
    "x": "kihûnkerdiş (n)",
    "y": " b. kehenkerdiş (n)"
  },
  {
    "x": "kihûy (m)",
    "y": " b. kewîye (m)"
  },
  {
    "x": "kij, -e-II",
    "y": " yanık"
  },
  {
    "x": "kij bîyene",
    "y": " yanmak"
  },
  {
    "x": "kij kerdene",
    "y": " yakmak, yandırmak"
  },
  {
    "x": "kij (n)-II",
    "y": " b. kîşte (m)"
  },
  {
    "x": "kijbîyayîş (n)",
    "y": " yanma"
  },
  {
    "x": "kijik, -e",
    "y": " yanık"
  },
  {
    "x": "kijik bîyene",
    "y": " b. kijike bîyene"
  },
  {
    "x": "kijik kerdene",
    "y": " b. kijike kerdene"
  },
  {
    "x": "kijike bîyene",
    "y": " yanmak"
  },
  {
    "x": "kijike kerdene",
    "y": " yakmak, yandırmak"
  },
  {
    "x": "kijikbîyayîş (n)",
    "y": " b. kijikebîyayîş (n)"
  },
  {
    "x": "kijikebîyayîş (n)",
    "y": " yanma"
  },
  {
    "x": "kijikekerda (m)",
    "y": " yakılmış olan"
  },
  {
    "x": "kijikekerde (n)",
    "y": " yakılmış olan"
  },
  {
    "x": "kijikekerdiş (n)",
    "y": " yakma, yandırma"
  },
  {
    "x": "kijikekerdîye (m)",
    "y": " yakılmış olan"
  },
  {
    "x": "kijiki (m)",
    "y": " b. kijike (m)"
  },
  {
    "x": "kijiki bîyene",
    "y": " b. kijike bîyene"
  },
  {
    "x": "kijiki kerdene",
    "y": " b. kijike kerdene"
  },
  {
    "x": "kijikibîyayîş (n)",
    "y": " b. kijikebîyayîş (n)"
  },
  {
    "x": "kijikikerda (m)",
    "y": " b. kijikekerda (m), kijikekerdîye (m)"
  },
  {
    "x": "kijikikerde (n)",
    "y": " b. kijikekerde (n)"
  },
  {
    "x": "kijikikerdiş (n)",
    "y": " b. kijikekerdiş (n)"
  },
  {
    "x": "kijikkerde (n)",
    "y": " b. kijikekerde (n)"
  },
  {
    "x": "kijikkerdê (m)",
    "y": " b. kijikekerda (m), kijikekerdîye (m)"
  },
  {
    "x": "kijikkerdiş (n)",
    "y": " b. kijikekerdiş (n)"
  },
  {
    "x": "kijjik, -e",
    "y": " b. kijik, -e"
  },
  {
    "x": "kijjik bîyene",
    "y": " b. kijike bîyene"
  },
  {
    "x": "kijjik kerdene",
    "y": " b. kijike kerdene"
  },
  {
    "x": "kijjikbîyayîş (n)",
    "y": " b. kijikebîyayîş (n)"
  },
  {
    "x": "kijjikkerda (m)",
    "y": " b. kijikekerda (m), kijikekerdîye (m)"
  },
  {
    "x": "kijjikkerde (n)",
    "y": " b. kijikekerde (n)"
  },
  {
    "x": "kijjikkerdiş (n)",
    "y": " b. kijikekerdiş (n)"
  },
  {
    "x": "kijkerdiş (n)",
    "y": " yakma; yandırma"
  },
  {
    "x": "kijrikî (zh)",
    "y": " içyağının kızartılmasıyla meydana gelen bir yiyecek"
  },
  {
    "x": "kikurt (n)",
    "y": " b. kukirt (n)"
  },
  {
    "x": "kil-I (m)",
    "y": " b. kile-I (m)"
  },
  {
    "x": "kil-II (n)",
    "y": " b. kile (m)"
  },
  {
    "x": "kila ardene",
    "y": " yarışta birinci olmak (at yarışı için)"
  },
  {
    "x": "kila eştene",
    "y": " yarıştırmak (at yarıştırmak)"
  },
  {
    "x": "kilae (m)",
    "y": " b. kilawe-I (m)"
  },
  {
    "x": "kilame-I (m)",
    "y": " şarkı, türkü"
  },
  {
    "x": "kilame-II (n)",
    "y": " b. kelame (n)"
  },
  {
    "x": "kilarz (m)",
    "y": " b. kelaze (m) "
  },
  {
    "x": "kilaw (m)",
    "y": " b. kilawe-I (m)"
  },
  {
    "x": "kilawe-I (m)",
    "y": " külah, başlık, takke"
  },
  {
    "x": "kilawe-II (m)",
    "y": " alev"
  },
  {
    "x": "kilawek (m)",
    "y": " b. kilaweke-II (m)"
  },
  {
    "x": "kilaweke-I (m)",
    "y": " ibik"
  },
  {
    "x": "kilaweke-II (m)",
    "y": " mantar (bitkisi)"
  },
  {
    "x": "kilawi (m)",
    "y": " b. kilawe-I (m)"
  },
  {
    "x": "kilawpor (n)",
    "y": " peruk, peruka"
  },
  {
    "x": "kilaye (m)",
    "y": " kilawe-I (m)"
  },
  {
    "x": "kilbe (n)",
    "y": " b. kelame (n)"
  },
  {
    "x": "kildane (m)",
    "y": " atlama (uzun atlama)"
  },
  {
    "x": "kildar, -e",
    "y": " sürmeli"
  },
  {
    "x": "kile-I (m)",
    "y": " alev"
  },
  {
    "x": "kile ci kewtene",
    "y": " alev almak"
  },
  {
    "x": "kile ci vistene",
    "y": " alevlendirmek"
  },
  {
    "x": "kile dekewtene",
    "y": " alev almak"
  },
  {
    "x": "kile devistene",
    "y": " alevlendirmek"
  },
  {
    "x": "kile eştene",
    "y": " alev sıçramak"
  },
  {
    "x": "kile girewtene",
    "y": " alev almak, alevlenmek"
  },
  {
    "x": "kile kerdene",
    "y": " alevlendirmek"
  },
  {
    "x": "kile kewtene",
    "y": " alevlenmek"
  },
  {
    "x": "kile perayene",
    "y": " alev sıçramak"
  },
  {
    "x": "kile wazdayene",
    "y": " alev sıçramak"
  },
  {
    "x": "kile-II (n)",
    "y": " cemre"
  },
  {
    "x": "kila aw (m)",
    "y": " b. kila awe (m)"
  },
  {
    "x": "kila awe (m)",
    "y": " su cemresi (Rumi takvime göre 14-15 Şubat gecesi başlar.)"
  },
  {
    "x": "kila awki (m)",
    "y": " b. kila awe (m)"
  },
  {
    "x": "kila binê ardî (m)",
    "y": " b. kila binê erdî (m)"
  },
  {
    "x": "kila binê erdî (m)",
    "y": " toprak cemresi (Rumi takvime göre 22-23 Şubat gecesi başlar.)"
  },
  {
    "x": "kila hewayî (m)",
    "y": " hava cemresi (Rumi takvime göre 6-7 Şubat gecesi başlar.)"
  },
  {
    "x": "kile-III (m)",
    "y": " solucan, yersolucanı"
  },
  {
    "x": "kila binê ardî (m)",
    "y": " b. kila binê erdî (m)"
  },
  {
    "x": "kila binê erdî (m)",
    "y": " solucan, yersolucanı"
  },
  {
    "x": "kile-IV (m)",
    "y": " sürme (kirpik diplerine sürülen)"
  },
  {
    "x": "kile-V (m)",
    "y": " b. dakile (m)"
  },
  {
    "x": "kilê",
    "y": " b. dakilê"
  },
  {
    "x": "Kile (m)",
    "y": " inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "kilecikewtiş (n)",
    "y": " alev alma"
  },
  {
    "x": "kileciperayîş (n)",
    "y": " parlama"
  },
  {
    "x": "kilecivistiş (n)",
    "y": " alevlendirme"
  },
  {
    "x": "kiledar, -e",
    "y": " alevli"
  },
  {
    "x": "kiledekewtiş (n)",
    "y": " alev alma"
  },
  {
    "x": "kiledevistiş (n)",
    "y": " alevlendirme"
  },
  {
    "x": "kilegirewtiş (n)",
    "y": " alevlenme, alev alma"
  },
  {
    "x": "kilekerdiş (n)",
    "y": " alevlendirme"
  },
  {
    "x": "kilekewtiş (n)",
    "y": " alevlenme"
  },
  {
    "x": "kilênca (m)",
    "y": " b. kilînca (m)"
  },
  {
    "x": "kilhayene",
    "y": " b. kulhayene"
  },
  {
    "x": "kilhayîş (n)",
    "y": " b. kulhayîş (n)"
  },
  {
    "x": "kilhi (n)",
    "y": " b. kulhe (n)"
  },
  {
    "x": "kili (m)",
    "y": " b. kile-I (m)"
  },
  {
    "x": "Kili (m)",
    "y": " b. Kile (m)"
  },
  {
    "x": "kilikê harmeyî (n)",
    "y": " omuz başı "
  },
  {
    "x": "kilikê hermî (n)",
    "y": " b. kilikê harmeyî (n)"
  },
  {
    "x": "kilin, -e",
    "y": " sürmeli"
  },
  {
    "x": "kilinca (m)",
    "y": " b. kilînca (m)"
  },
  {
    "x": "kilince (m)",
    "y": " b. kilînca (m)"
  },
  {
    "x": "kilise (n)",
    "y": " b. kilîse (n)"
  },
  {
    "x": "kilit (n)",
    "y": " b. kilît (n)"
  },
  {
    "x": "kilîd (n)",
    "y": " b. kilît (n)"
  },
  {
    "x": "kilîme (m)",
    "y": " kilim"
  },
  {
    "x": "kilîmi (m)",
    "y": " b. kilîme (m)"
  },
  {
    "x": "kilînca (m)",
    "y": " çörek"
  },
  {
    "x": "kilînce (n)",
    "y": " b. kelce (n)"
  },
  {
    "x": "kilînt (n)",
    "y": " b. kilît (n)"
  },
  {
    "x": "kilîse (n)",
    "y": " kilise"
  },
  {
    "x": "kilît (n)",
    "y": " 1)anahtar\r\n~2)kilit (asma kilit)"
  },
  {
    "x": "topa kilîtî (m)",
    "y": " kilit (asma kilit)"
  },
  {
    "x": "kilîtçekuye (m)",
    "y": " anahtar kelime, anahtar sözcük"
  },
  {
    "x": "kilîtin, -e",
    "y": " kilitli"
  },
  {
    "x": "kilîtkerdî, -ye",
    "y": " kilitli"
  },
  {
    "x": "kilîtroş, -e",
    "y": " anahtarcı"
  },
  {
    "x": "kilîtsaz, -e",
    "y": " anahtarcı"
  },
  {
    "x": "kilîtviraştox, -e",
    "y": " anahtarcı"
  },
  {
    "x": "kilk (n)",
    "y": " yün, yapak, yapağı"
  },
  {
    "x": "kilkerda (m)",
    "y": " sürmeli"
  },
  {
    "x": "kilkerde (n)",
    "y": " sürmeli"
  },
  {
    "x": "killi (m)",
    "y": " b. kile-III (m)"
  },
  {
    "x": "killa binê ardî (m)",
    "y": " b. kila binê erdî (m)"
  },
  {
    "x": "kilm, -e",
    "y": " kısa"
  },
  {
    "x": "kilmever, -e",
    "y": " b. kilmver, -e"
  },
  {
    "x": "kilmey (m)",
    "y": " b. kilmîye (m), kilmî (m)"
  },
  {
    "x": "kilmê (m)",
    "y": " b. kilmîye (m), kilmî (m)"
  },
  {
    "x": "kilmête (n)",
    "y": " b. kermite (n)"
  },
  {
    "x": "kilmite (n)",
    "y": " b. kermite (n)"
  },
  {
    "x": "kilmî (m)",
    "y": " kısalık"
  },
  {
    "x": "kilmîye (m)",
    "y": " kısalık"
  },
  {
    "x": "kilmkî (m)",
    "y": " özetle"
  },
  {
    "x": "kilmnus (n)",
    "y": " özet (yazılı özet)"
  },
  {
    "x": "kilmnuso hîrakerdî (n)",
    "y": " genişletilmiş özet"
  },
  {
    "x": "kilmnuşte (n)",
    "y": " kısaltma"
  },
  {
    "x": "kilmnuşteyî (zh)",
    "y": " kısaltmalar"
  },
  {
    "x": "kilmvate (n)",
    "y": " özet"
  },
  {
    "x": "kilmver, -e",
    "y": " ensiz"
  },
  {
    "x": "Kilo (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "kilora bindê adirî (m)",
    "y": " b. kilora binê adirî (m)"
  },
  {
    "x": "kilora binê adirî (m)",
    "y": " kömbe (küle gömülerek pişirilen mayasız çörek)"
  },
  {
    "x": "kilse (n)",
    "y": " b. kursî (n) "
  },
  {
    "x": "kilsi (n)",
    "y": " b. kursî (n) "
  },
  {
    "x": "kilwaze (m)",
    "y": " alev yalaması (itfaiye terimi)"
  },
  {
    "x": "kilwe (m)",
    "y": " b. kilawe-II (m)"
  },
  {
    "x": "kilxayene",
    "y": " b. kulxayene"
  },
  {
    "x": "kilxî (m)",
    "y": " b. kulxî (m)"
  },
  {
    "x": "kimêt, -e",
    "y": " b. kumeyt, -e"
  },
  {
    "x": "kin (n)",
    "y": " tuluk, tulum"
  },
  {
    "x": "kinar (n)",
    "y": " b. kenar (n)"
  },
  {
    "x": "kinare (n)",
    "y": " b. kenar (n)"
  },
  {
    "x": "kinc (n)",
    "y": " 1)elbise, giyecek, giysi\r\n~Kincê/cilê to şîkîyê ra to. (Giyisilerin sana yakıştı.)\r\n~ 2)bez, çaput, paçavra"
  },
  {
    "x": "kinc bedilnayene",
    "y": " elbise değiştirmek, giysi değiştirmek"
  },
  {
    "x": "kinc vetene",
    "y": " elbise çıkarmak, giysi çıkarmak"
  },
  {
    "x": "kinc vurnayene",
    "y": " elbise değiştirmek, giysi değiştirmek"
  },
  {
    "x": "kinc û kisvet",
    "y": " giyim kuşam, kılık kıyafet"
  },
  {
    "x": "kinc û kol",
    "y": " pılı pırtı"
  },
  {
    "x": "kinc û kon",
    "y": " b. kinc û kol"
  },
  {
    "x": "kinc û kuel",
    "y": " b. kinc û kol"
  },
  {
    "x": "kincan ro ginayene",
    "y": " âdet görmek, (kadın) aybaşı olmak"
  },
  {
    "x": "kincê firaqan (n)",
    "y": " bulaşık bezi"
  },
  {
    "x": "kincê karî (zh)",
    "y": " iş elbisesi, iş giysisi"
  },
  {
    "x": "kincê qican (n)",
    "y": " çocuk bezi, etek bezi"
  },
  {
    "x": "kincê sunetî (zh)",
    "y": " sünnet elbisesi/giysisi"
  },
  {
    "x": "kincê şewe (zh)",
    "y": " gecelik"
  },
  {
    "x": "kincê xo ameyene",
    "y": " âdet görmek, (kadın) aybaşı olmak"
  },
  {
    "x": "kincê xo vetene",
    "y": " soyunmak"
  },
  {
    "x": "kincê ... ... ra vetene",
    "y": " soymak (giysisini çıkarmak, elbisesini çıkarmak)"
  },
  {
    "x": "kincê ...  ... ravetiş",
    "y": " soyma (giysisini çıkarma)"
  },
  {
    "x": "kincê ... ser de kerdene",
    "y": " soymak (giysisini çıkarmak, elbisesini çıkarmak)"
  },
  {
    "x": "kincê ... serdekerdiş",
    "y": " soyma (giysisini çıkarma)"
  },
  {
    "x": "kincê ... vetene",
    "y": " soymak (giysisini çıkarmak, elbisesini çıkarmak)"
  },
  {
    "x": "kincê ... vetiş",
    "y": " soyma (giysisini çıkarma)"
  },
  {
    "x": "kincî dîyene",
    "y": " âdet görmek, (kadın) aybaşı olmak"
  },
  {
    "x": "kincî firaqûn (n)",
    "y": " b. kincê firaqan (n)"
  },
  {
    "x": "kincî qicûn",
    "y": " b. kincê qican (n)"
  },
  {
    "x": "kincî xu vetene",
    "y": " b. kincê xo vetene"
  },
  {
    "x": "kincî ... ... ra vetene",
    "y": " b. kincê ...  ... ra vetene"
  },
  {
    "x": "kincî ...  ... ravetiş",
    "y": " b. kincê ...  ... ravetiş"
  },
  {
    "x": "kincî ... vetene",
    "y": " b. kincê ... vetene"
  },
  {
    "x": "kincî ... vetiş",
    "y": " b. kincê ... vetiş"
  },
  {
    "x": "kinco kan-kort (n)",
    "y": " alık (eskimiş giysi), eski püskü giysi"
  },
  {
    "x": "kinc (m)",
    "y": " b. kinc (n)"
  },
  {
    "x": "kinc-kol",
    "y": " pılı pırtı"
  },
  {
    "x": "kinc-kole",
    "y": " b. kinc-kol"
  },
  {
    "x": "kinc-kuel",
    "y": " b. kinc-kol"
  },
  {
    "x": "kincanroginayîş (n)",
    "y": " âdet görme, (kadın) aybaşı olma"
  },
  {
    "x": "kince (m)",
    "y": " b. kinc (n)"
  },
  {
    "x": "kinca kan-korte (m)",
    "y": " b. kinco kan-kort (n)"
  },
  {
    "x": "kince-kole",
    "y": " b. kinc-kol"
  },
  {
    "x": "kincêxoameyîş (n)",
    "y": " âdet görme, (kadın) aybaşı olma"
  },
  {
    "x": "kincêxovetiş (n)",
    "y": " soyunma"
  },
  {
    "x": "kincî-I (zh)",
    "y": " üst baş (giysiler)"
  },
  {
    "x": "kincî dayene serdekerdene",
    "y": " soydurmak (giysisini çıkartmak, elbisesini çıkartmak)"
  },
  {
    "x": "kincî dayene vetene",
    "y": " soydurmak (giysisini çıkartmak, elbisesini çıkartmak)"
  },
  {
    "x": "kincî xo ra vetene",
    "y": " soyunmak"
  },
  {
    "x": "kincî xo ser de kerdene",
    "y": " soyunmak"
  },
  {
    "x": "kincî-II (n)",
    "y": " b. kuncî (n)"
  },
  {
    "x": "kincîdayîşserdekerdiş (n)",
    "y": " soydurma (giysisini çıkartma)"
  },
  {
    "x": "kincîdayîşvetiş (n)",
    "y": " soydurma (giysisini çıkartma)"
  },
  {
    "x": "kincîdîyayîş (n)",
    "y": " âdet görme, (kadın) aybaşı olma"
  },
  {
    "x": "kincîxoravetiş (n)",
    "y": " soyunma"
  },
  {
    "x": "kincîxoserdekerdiş (n)",
    "y": " soyunma"
  },
  {
    "x": "kincîxuvetiş (n)",
    "y": " b. kincêxovetiş (n)"
  },
  {
    "x": "kincnêşuta (m)",
    "y": " çamaşır"
  },
  {
    "x": "kincserdekerda (m)",
    "y": " soyunmuş olan"
  },
  {
    "x": "kincserdekerde (n)",
    "y": " soyunmuş olan"
  },
  {
    "x": "kincserdekerdîye (m)",
    "y": " soyunmuş olan"
  },
  {
    "x": "kincşuw, -e",
    "y": " çamaşırcı"
  },
  {
    "x": "kincveta (m)",
    "y": " soyunmuş olan"
  },
  {
    "x": "kincvete (n)",
    "y": " soyunmuş olan"
  },
  {
    "x": "kincveti (n)",
    "y": " b. kincvete (n)"
  },
  {
    "x": "kincvetîye (m)",
    "y": " soyunmuş olan"
  },
  {
    "x": "kind (n)",
    "y": " b. kin (n)"
  },
  {
    "x": "kindir (n)",
    "y": " b. kendir (n)"
  },
  {
    "x": "kindirîyayene",
    "y": " b. gindirîyayene"
  },
  {
    "x": "kindirnayîne",
    "y": " b. gindirnayene"
  },
  {
    "x": "kindîr (n)",
    "y": " b. kendir (n)"
  },
  {
    "x": "kinger (n)",
    "y": " b. kenger (n)"
  },
  {
    "x": "kinitaene",
    "y": " b. kinitene"
  },
  {
    "x": "kinitayene",
    "y": " b. kinitene"
  },
  {
    "x": "kinitene",
    "y": " 1)kazmak\r\n~2)oymak"
  },
  {
    "x": "kinitiş (n)",
    "y": " 1)kazma\r\n~2)oyma"
  },
  {
    "x": "kinitox, -e",
    "y": " b. kendox, -e"
  },
  {
    "x": "kinîyayene",
    "y": " kazılmak"
  },
  {
    "x": "kinîyayêne",
    "y": " b. kinîyayene"
  },
  {
    "x": "kinîyayîş (n)",
    "y": " kazılma"
  },
  {
    "x": "kino (n)",
    "y": " b. kenewîr (n)"
  },
  {
    "x": "kir- (n)-I",
    "y": " sik, penis, kamış"
  },
  {
    "x": "kir, -e-II",
    "y": " b. kirr, -e "
  },
  {
    "x": "kirabe kerdene",
    "y": " b. kerab kerdene"
  },
  {
    "x": "kirancîyayene",
    "y": " çekilmek"
  },
  {
    "x": "kirancîyayîş (n)",
    "y": " çekilme"
  },
  {
    "x": "kirantene",
    "y": " çekmek"
  },
  {
    "x": "kirantiş (n)",
    "y": " çekme, çekim"
  },
  {
    "x": "kirarî (m)",
    "y": " işlem (mat)"
  },
  {
    "x": "kirarîya arîtmetîkî (m)",
    "y": " aritmetik işlemi (mat)"
  },
  {
    "x": "kirarîya parekerdişî (m)",
    "y": " bölme işlemi (mat)"
  },
  {
    "x": "kiras (n)",
    "y": " b. kirbas (n)"
  },
  {
    "x": "kirbas (n)",
    "y": " beyaz renkli bez"
  },
  {
    "x": "kirbît (n)",
    "y": " b. kibrît (n)"
  },
  {
    "x": "kird, -e",
    "y": " b. kirmanc, -e "
  },
  {
    "x": "kirdan (n)",
    "y": " yaşlı erkek manda "
  },
  {
    "x": "kirdas, -e",
    "y": " Kurmanc"
  },
  {
    "x": "kirdasî, -ye-I",
    "y": " b. kirdas, -e"
  },
  {
    "x": "kirdasî-II (m)",
    "y": " b. kirdaskî (m)"
  },
  {
    "x": "kirdaskî (m)",
    "y": " Kurmancca"
  },
  {
    "x": "kirdaşçî (m)",
    "y": " b. kirdaskî (m)"
  },
  {
    "x": "kirdaşkî (m)",
    "y": " b. kirdaskî (m)"
  },
  {
    "x": "kirdkî (m)",
    "y": " b. kirmanckî (m)"
  },
  {
    "x": "kirê-I (n)",
    "y": " b. kîrye (n)"
  },
  {
    "x": "kirê-II (m)",
    "y": " kira"
  },
  {
    "x": "kirêşîyayene",
    "y": " b. kirişîyayene"
  },
  {
    "x": "kirêşîyayîş (n)",
    "y": " b. kirişîyayîş (n)"
  },
  {
    "x": "kirêştene",
    "y": " b. kiriştene"
  },
  {
    "x": "kirêştiş (n)",
    "y": " b. kiriştiş (n)"
  },
  {
    "x": "kiri vistene",
    "y": " b. kurrî eştene"
  },
  {
    "x": "kiri vistene",
    "y": " b. kurrî eştene"
  },
  {
    "x": "kirisnayene",
    "y": " b. kiriştene"
  },
  {
    "x": "kirisnayine",
    "y": " b. kiriştene"
  },
  {
    "x": "kirisnayîş (n)",
    "y": " b. kiriştiş (n)"
  },
  {
    "x": "kiristene",
    "y": " b. kiriştene"
  },
  {
    "x": "kiristiş (n)",
    "y": " b. kiriştiş (n)"
  },
  {
    "x": "kirisyayine",
    "y": " b. kirişîyayene"
  },
  {
    "x": "kirisyayîs (n)",
    "y": " b. kirişîyayîş (n)"
  },
  {
    "x": "kirişîyaya (m)",
    "y": " taşınan (taşınmış olan)"
  },
  {
    "x": "kirişîyaye (n)",
    "y": " taşınan (taşınmış olan)"
  },
  {
    "x": "kirişîyayene",
    "y": " taşınmak\r\n~Kitabî kirişîyayê. (Kitaplar taşınmış.)"
  },
  {
    "x": "kirişîyayêne",
    "y": " b. kirişîyayene"
  },
  {
    "x": "kirişîyayîs (n)",
    "y": " b. kirişîyayîş (n)"
  },
  {
    "x": "kirişîyayîş (n)",
    "y": " taşınma"
  },
  {
    "x": "kirişîyayîşê eşteyî",
    "y": " atık taşınması"
  },
  {
    "x": "kirişîyayîye (m)",
    "y": " taşınan (taşınmış olan)"
  },
  {
    "x": "kirişnayene",
    "y": " b. kiriştene"
  },
  {
    "x": "kirişta (m)",
    "y": " taşınan (taşınmış olan)"
  },
  {
    "x": "kiriştbar, -e",
    "y": " menkul, taşınır (taşınabilen, götürülebilen)"
  },
  {
    "x": "kiriştbaro pêt (n)",
    "y": " dayanıklı taşınır"
  },
  {
    "x": "kiriştayîye (m)",
    "y": " taşınan (taşınmış olan)"
  },
  {
    "x": "kirişte (n)",
    "y": " taşınan (taşınmış olan)"
  },
  {
    "x": "kiriştene",
    "y": " taşımak"
  },
  {
    "x": "kiriştiş (n)",
    "y": " taşıma"
  },
  {
    "x": "kiriştox, -e",
    "y": " 1)taşıyıcı\r\n~2)taşımacı, nakliyeci"
  },
  {
    "x": "kiriştoxê/a malzemeyî",
    "y": " malzeme taşıyıcı"
  },
  {
    "x": "kiriştoxênî (m)",
    "y": " taşımacılık"
  },
  {
    "x": "kiriştoxî (m)",
    "y": " nakliyat, taşımacılık"
  },
  {
    "x": "kiriştoxîye (m)",
    "y": " nakliyat, taşımacılık"
  },
  {
    "x": "kitabê trafîkî (n)",
    "y": " trafik kitabı"
  },
  {
    "x": "kirî (n)",
    "y": " b. kîrye (n)"
  },
  {
    "x": "kirîş (n)",
    "y": " kîrîş (n)"
  },
  {
    "x": "kirkinci (m)",
    "y": " b. kerkince (m)"
  },
  {
    "x": "kirkirik (n)",
    "y": " kenevir, kendir"
  },
  {
    "x": "kirlente (m)",
    "y": " kırlent (küçük yastık)"
  },
  {
    "x": "kirmanc, -e",
    "y": " Kırmanc, Zaza "
  },
  {
    "x": "kirmancî (n)",
    "y": " b. kurmancî (n)"
  },
  {
    "x": "kirmanckî (m)",
    "y": " Kırmancca, Zazaca"
  },
  {
    "x": "kirnî, -ye",
    "y": " cüce"
  },
  {
    "x": "kirnîyî (m)",
    "y": " cücelik"
  },
  {
    "x": "kironcîyayîne",
    "y": " b. kirancîyayene"
  },
  {
    "x": "kironcîyayîş (n)",
    "y": " b. kirancîyayîş (n)"
  },
  {
    "x": "kirontene",
    "y": " b. kirantene"
  },
  {
    "x": "kirontiş (n)",
    "y": " b. kirantiş (n)"
  },
  {
    "x": "kirr, -e-I",
    "y": " kel"
  },
  {
    "x": "kirr, -e-II",
    "y": " 1)kısa\r\n~2)kısa kulaklı (daha çok hayvan için)  "
  },
  {
    "x": "kirr-III (m)",
    "y": " b. kirre (m)"
  },
  {
    "x": "kirrancîyayene",
    "y": " b. kirancîyayene"
  },
  {
    "x": "kirrancîyayîş (n)",
    "y": " b. kirancîyayîş (n)"
  },
  {
    "x": "kirrantene",
    "y": " b. kirantene"
  },
  {
    "x": "kirrant (n)",
    "y": " yabani sarmısak"
  },
  {
    "x": "kirrantiş (n)",
    "y": " b. kirantiş (n)"
  },
  {
    "x": "kirre-I (m)",
    "y": " kayalık"
  },
  {
    "x": "Kirre-II (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "kirrey (m)",
    "y": " b. kirrîye (m), kirrî (m)"
  },
  {
    "x": "kirrêk, -i",
    "y": " b. kurrî, -ye"
  },
  {
    "x": "kirrênî (m)",
    "y": " kellik"
  },
  {
    "x": "kirrêşîyayene",
    "y": " b. kirişîyayene"
  },
  {
    "x": "kirrêşîyayîş (n)",
    "y": " b. kirişîyayîş (n)"
  },
  {
    "x": "kirrêştene",
    "y": " b. kiriştene"
  },
  {
    "x": "kirrêştiş (n)",
    "y": " b. kiriştiş (n)"
  },
  {
    "x": "kirri-I (m)",
    "y": " b. kirre-I (m)"
  },
  {
    "x": "kirri-II (n)",
    "y": " b. kurrî (n)"
  },
  {
    "x": "kirri eştene",
    "y": " b. kurrî eştene"
  },
  {
    "x": "kirri vistene",
    "y": " b. kurrî eştene"
  },
  {
    "x": "Kirri (m)",
    "y": " b. Kirre (m)"
  },
  {
    "x": "kirrim bîyene",
    "y": " biçilircesine dipten kesilmek veya koparılmak"
  },
  {
    "x": "kirrim kerdene",
    "y": " biçercesine dipten kesmek veya koparmak"
  },
  {
    "x": "kirrimbîyayîş (n)",
    "y": " biçilircesine dipten kesilme veya koparılma"
  },
  {
    "x": "kirrimkerdiş (n)",
    "y": " biçercesine dipten kesme veya koparma "
  },
  {
    "x": "kirriştene",
    "y": " b. kiriştene"
  },
  {
    "x": "kirriştiş (n)",
    "y": " b. kiriştiş (n)"
  },
  {
    "x": "kirrî-I (m)",
    "y": " kısalık"
  },
  {
    "x": "kirrî-II (m)",
    "y": " b. kurrîye (m)"
  },
  {
    "x": "kirrîye-I (m)",
    "y": " kellik"
  },
  {
    "x": "kirrîye-II (m)",
    "y": " kısalık "
  },
  {
    "x": "kirroncîyayîne",
    "y": " b. kirancîyayene"
  },
  {
    "x": "kirroncîyayîş (n)",
    "y": " b. kirancîyayîş (n)"
  },
  {
    "x": "kirrontene",
    "y": " b. kirantene"
  },
  {
    "x": "kirrontiş (n)",
    "y": " b. kirantiş (n)"
  },
  {
    "x": "kirrûncîyayîne",
    "y": " b. kirancîyayene"
  },
  {
    "x": "kirrûncîyayîş (n)",
    "y": " b. kirancîyayîş (n)"
  },
  {
    "x": "kirrûntene",
    "y": " b. kirantene"
  },
  {
    "x": "kirrûntiş (n)",
    "y": " b. kirantiş (n)"
  },
  {
    "x": "kirşî (m)",
    "y": " b. kursî (n)"
  },
  {
    "x": "kirta (m)",
    "y": " enik, encik, encek, köpek yavrusu, kurt yavrusu"
  },
  {
    "x": "kirte (n)",
    "y": " enik, encik, encek, köpek yavrusu, kurt yavrusu"
  },
  {
    "x": "kirte ardene",
    "y": " (köpek için) doğurmak, eniklemek"
  },
  {
    "x": "kirtik-I (n)",
    "y": " kıkırdak"
  },
  {
    "x": "kirtik-II (n)",
    "y": " karın sertleşmiş üst tabakası"
  },
  {
    "x": "kirtik-III (n)",
    "y": " b. xartîk (n)"
  },
  {
    "x": "kirtik kerdene",
    "y": " b. xartîk kerdene"
  },
  {
    "x": "kirûncîyayîne",
    "y": " b. kirancîyayene"
  },
  {
    "x": "kirûncîyayîş (n)",
    "y": " b. kirancîyayîş (n)"
  },
  {
    "x": "kirûntene",
    "y": " b. kirantene"
  },
  {
    "x": "kirûntiş (n)",
    "y": " b. kirantiş (n)"
  },
  {
    "x": "kirvas (n)",
    "y": " b. kirbas (n)"
  },
  {
    "x": "kirwa (n)",
    "y": " b. kerwa (n)"
  },
  {
    "x": "kirwas (n)",
    "y": " b. kirbas (n)"
  },
  {
    "x": "kirye (n)",
    "y": " b. kîrye (n)"
  },
  {
    "x": "kiryeştene",
    "y": " b. kiriştene"
  },
  {
    "x": "kiryeştiş (n)",
    "y": " b. kiriştiş (n)"
  },
  {
    "x": "kiryeşyayene",
    "y": " b. kirişîyayene"
  },
  {
    "x": "kiryeşyayîş (n)",
    "y": " b. kirişîyayîş (n)"
  },
  {
    "x": "kis (m)",
    "y": " b. kisî (m)"
  },
  {
    "x": "kisha (m)",
    "y": " b. kixsa (m)"
  },
  {
    "x": "kisi (m)",
    "y": " b. kisî (m)"
  },
  {
    "x": "kisî (m)",
    "y": " am, ferç, vulva"
  },
  {
    "x": "kislon (n)",
    "y": " b. keslan, -e"
  },
  {
    "x": "kisloney (m)",
    "y": " b. keslanîye (m)"
  },
  {
    "x": "kislonê (m)",
    "y": " b. keslanîye (m)"
  },
  {
    "x": "kislûn (n)",
    "y": " b. keslan, -e"
  },
  {
    "x": "kislûnê (m)",
    "y": " b. keslanîye (m)"
  },
  {
    "x": "kist (m)",
    "y": " b. kîşte (m)"
  },
  {
    "x": "kistene",
    "y": " b. kiştene"
  },
  {
    "x": "kisti (m)",
    "y": " b. kîşte (m)"
  },
  {
    "x": "kistine",
    "y": " b. kiştene"
  },
  {
    "x": "kistîs",
    "y": " b. kiştiş (n)"
  },
  {
    "x": "kistkî",
    "y": " b. kîştkî"
  },
  {
    "x": "kisvet (n)",
    "y": " kıyafet, kılık, giyim, kisve"
  },
  {
    "x": "kisvetî (zh)",
    "y": " kispet"
  },
  {
    "x": "kisxa (m)",
    "y": " b. kixsa (m)"
  },
  {
    "x": "kisxe (m)",
    "y": " b. kixsa (m)"
  },
  {
    "x": "kiş-I",
    "y": " 1)(tavuk veya kuşlar için) kış"
  },
  {
    "x": "kiş kardene",
    "y": " b. kiş kerdene"
  },
  {
    "x": "kiş kerdene",
    "y": " (tavuk veya kuşları kovmak) kışkışlamak, kışlamak"
  },
  {
    "x": "kiş kerdeni",
    "y": " b. kiş kerdene"
  },
  {
    "x": "kiş kiş",
    "y": " 1)kış, tavuk veya kuşları kovmak için kullanılan bir sözcük\r\n~2)oğlağı kovmak için kullanılan bir sözcük"
  },
  {
    "x": "kiş kiş kardene",
    "y": " b. kiş kerdene"
  },
  {
    "x": "kiş kiş kerdene",
    "y": " b. kiş kerdene"
  },
  {
    "x": "kiş kiş kerdeni",
    "y": " b. kiş kerdene"
  },
  {
    "x": "kiş-kişkardiş (n)",
    "y": " b. kişkerdiş (n)"
  },
  {
    "x": "kiş-kişkerdiş (n)",
    "y": " b. kişkerdiş (n)"
  },
  {
    "x": "kiş-II (m)",
    "y": " b. kisî (m)"
  },
  {
    "x": "kişik (n)",
    "y": " b. kişike (m)"
  },
  {
    "x": "kişike (m)",
    "y": " satranç"
  },
  {
    "x": "kişî (m)",
    "y": " b. kisî (m)"
  },
  {
    "x": "kişîyayene",
    "y": " öldürülmek"
  },
  {
    "x": "kişîyayîş (n)",
    "y": " öldürülme "
  },
  {
    "x": "kişkardiş (n)",
    "y": " b. kişkerdiş (n)"
  },
  {
    "x": "kişkerdiş (n)",
    "y": " (tavuk veya kuşları kovma) kışkışlama, kışlama "
  },
  {
    "x": "kişlon (n)",
    "y": " b. kuçelan (n)"
  },
  {
    "x": "kişn (m)",
    "y": " b. kişne (m)"
  },
  {
    "x": "kişne (m)",
    "y": " burçak, küşne"
  },
  {
    "x": "kişni (m)",
    "y": " b. kişne (m)"
  },
  {
    "x": "kişt-I",
    "y": " keçiyi kovmak için kullanılan seslenme sözcüğü"
  },
  {
    "x": "kişt-II (m)",
    "y": " b. kîşte (m)"
  },
  {
    "x": "kiştakewtiş (n)",
    "y": " b. kîştakewtiş (n)"
  },
  {
    "x": "kiştavistiş (n)",
    "y": " b. kîştavistiş (n)"
  },
  {
    "x": "kişte-I (n)",
    "y": " maktul"
  },
  {
    "x": "kişte-II (m)",
    "y": " b. kîşte (m)"
  },
  {
    "x": "kiştene",
    "y": " öldürmek, cinayet işlemek"
  },
  {
    "x": "kişti (m)",
    "y": " b. kîşte (m)"
  },
  {
    "x": "kişt a kewtene",
    "y": " b. kîşt a kewtene"
  },
  {
    "x": "kişt a vistene",
    "y": " b. kîşt a vistene"
  },
  {
    "x": "kiştiş (n)",
    "y": " öldürme, cinayet, kıya"
  },
  {
    "x": "kiştî, -ye",
    "y": " maktul"
  },
  {
    "x": "kiştkanî",
    "y": " b. kîştkanî"
  },
  {
    "x": "kiştkanî şîyayox, -e",
    "y": " b. kîştkanî şîyayox, -e"
  },
  {
    "x": "kiştkî",
    "y": " b. kîştkî"
  },
  {
    "x": "kiştox, -e",
    "y": " katil, cani, kıyıcı"
  },
  {
    "x": "kiştoxane",
    "y": " canice, caniyane"
  },
  {
    "x": "kiştoxîye (m)",
    "y": " canilik, kıyıcılık"
  },
  {
    "x": "kitab (n)",
    "y": " kitap"
  },
  {
    "x": "kitabê qaydeyanê dînî (n)",
    "y": " akait"
  },
  {
    "x": "Kitabo Kan (n)",
    "y": " Ahd-i Atik"
  },
  {
    "x": "Kitabo Kehen (n)",
    "y": " Ahd-i Atik"
  },
  {
    "x": "Kitabo Newe (n)",
    "y": " Ahd-i Cedit"
  },
  {
    "x": "kitabe-I (m)",
    "y": " b. kitab (n)"
  },
  {
    "x": "kitabe-II (m)",
    "y": " kitabe"
  },
  {
    "x": "kitabek (n)",
    "y": " broşür, risale, kitapçık"
  },
  {
    "x": "kitabgeh (n)",
    "y": " kitaplık"
  },
  {
    "x": "kitabi (m)",
    "y": " b. kitab (n)"
  },
  {
    "x": "kitabroş, -e",
    "y": " kitapçı (kitap satan)"
  },
  {
    "x": "kitabxane (n)",
    "y": " kütüphane"
  },
  {
    "x": "kitabxaneger, -e",
    "y": " kütüphaneci"
  },
  {
    "x": "kitabxanegerî (m)",
    "y": " kütüphanecilik"
  },
  {
    "x": "kitabxanegerîye (m)",
    "y": " kütüphanecilik"
  },
  {
    "x": "kitabxanekar, -e",
    "y": " kütüphaneci"
  },
  {
    "x": "kitabxanekarî (m)",
    "y": " kütüphanecilik"
  },
  {
    "x": "kitabxanekarîye (m)",
    "y": " kütüphanecilik"
  },
  {
    "x": "kitano belaş (n)",
    "y": " bir çeşit bez"
  },
  {
    "x": "kitav (n)",
    "y": " b. kitab (n)"
  },
  {
    "x": "kivar, -e",
    "y": " b. kîbar, -e"
  },
  {
    "x": "kivarî (m)",
    "y": " b. kîbarî (m)"
  },
  {
    "x": "kivarîye (m)",
    "y": " b. kîbarîye (m), kîbarî (m)"
  },
  {
    "x": "kivş, -e",
    "y": " kifş, -e"
  },
  {
    "x": "kivş bîyayene",
    "y": " b. kifş bîyene"
  },
  {
    "x": "kivş bîyene",
    "y": " b. kifş bîyene"
  },
  {
    "x": "kivş kerdene",
    "y": " b. kifş kerdene"
  },
  {
    "x": "kivşbîyayîş (n)",
    "y": " b. kifşbîyayîş (n)"
  },
  {
    "x": "kivşe",
    "y": " b. kifş"
  },
  {
    "x": "kivşe bîyayene",
    "y": " b. kifş bîyene"
  },
  {
    "x": "kivşe kerdene",
    "y": " b. kifş kerdene"
  },
  {
    "x": "kivşebîyayîş (n)",
    "y": " b. kifşbîyayîş (n)"
  },
  {
    "x": "kivşekerdiş (n)",
    "y": " b. kifşkerdiş (n)"
  },
  {
    "x": "kivşkerdiş (n)",
    "y": " b. kifşkerdiş (n)"
  },
  {
    "x": "kix, -e",
    "y": " kaka (çocuk dilinde), kıh (çocuk dilinde)"
  },
  {
    "x": "kixayene",
    "y": " b. kuxayene"
  },
  {
    "x": "kixi (m)",
    "y": " b. kixe (m)"
  },
  {
    "x": "kixik (n)",
    "y": " b. kuxike (m)"
  },
  {
    "x": "kixs (m)",
    "y": " b. kixsa (m)"
  },
  {
    "x": "kixsa (m)",
    "y": " arpacık soğanı"
  },
  {
    "x": "kixse (m)",
    "y": " b. kixsa (m)"
  },
  {
    "x": "kizik, -e",
    "y": " b. kijik, -e"
  },
  {
    "x": "kizik bîyene",
    "y": " b. kijike bîyene"
  },
  {
    "x": "kizik kerdene",
    "y": " b. kijike kerdene"
  },
  {
    "x": "kizikbîyayîş (n)",
    "y": " b. kijikebîyayîş (n)"
  },
  {
    "x": "kizikkerde (n)",
    "y": " b. kijikekerde (n)"
  },
  {
    "x": "kizikkerdiş (n)",
    "y": " b. kijikekerdiş (n)"
  },
  {
    "x": "kizikkerdîye (m)",
    "y": " b. kijikekerda (m), kijikekerdîye (m)"
  },
  {
    "x": "kî-I (zr)",
    "y": " de [b. zî (zr)]\r\n~Ez kî wena ti kî. (Ben de yiyorum sen de.) "
  },
  {
    "x": "kî-II",
    "y": " b. kes, -e"
  },
  {
    "x": "kî vanî qey",
    "y": " b. kes vano qey"
  },
  {
    "x": "kî vûnî dirê",
    "y": " b. kes vano qey"
  },
  {
    "x": "kî vûnî qê",
    "y": " b. kes vano qey"
  },
  {
    "x": "kî-III (n)",
    "y": " b. keye-I (n)"
  },
  {
    "x": "kîbar, -e",
    "y": " ince, kibar; centilmen"
  },
  {
    "x": "kîbarane",
    "y": " centilmence"
  },
  {
    "x": "kîbarey (m)",
    "y": " b. kîbarî (m), kîbarîye (m)"
  },
  {
    "x": "kîbarê (m)",
    "y": " b. kîbarî (m), kîbarîye (m)"
  },
  {
    "x": "kîbarî (m)",
    "y": " incelik, kibarlık"
  },
  {
    "x": "kîbarîye (m)",
    "y": " incelik, kibarlık; centilmenlik"
  },
  {
    "x": "kîbebimbar (n)",
    "y": " b. kîpebumbar (n)"
  },
  {
    "x": "kîber (n)",
    "y": " b. keyber (n)"
  },
  {
    "x": "kîbrit (n)",
    "y": " b. kibrît (n)"
  },
  {
    "x": "kîbrît (n)",
    "y": " b. kibrît (n)"
  },
  {
    "x": "kîbrîte (m)",
    "y": " b. kibrît (n)"
  },
  {
    "x": "kîdas (m)",
    "y": " b. kîdase (m)"
  },
  {
    "x": "kîdase (m)",
    "y": " hayvanlara kışın yedirilmek üzere kesilip ağacın çatallı ana dalları arasına dizilen yaprak yığını"
  },
  {
    "x": "Kîev (n)",
    "y": " Kiev"
  },
  {
    "x": "kîfayet (n)",
    "y": " kifayet, yeterlilik, yeterlik, yetme, yeterli olma"
  },
  {
    "x": "Kîgalî",
    "y": " Kigali"
  },
  {
    "x": "kîlca (n)",
    "y": " killi toprağın bulunduğu yer"
  },
  {
    "x": "kîle (m)",
    "y": " kil"
  },
  {
    "x": "kîler (n)",
    "y": " kiler"
  },
  {
    "x": "kîlere (m)",
    "y": " b. kîler (n)"
  },
  {
    "x": "kîlime (m)",
    "y": " b. kilîme (m)"
  },
  {
    "x": "kîlin, -e",
    "y": " killi"
  },
  {
    "x": "kîlisa (m)",
    "y": " b. kilîse (n)"
  },
  {
    "x": "kîlise (m)",
    "y": " b. kilîse (n)"
  },
  {
    "x": "kîlit (n)",
    "y": " b. kilît (n)"
  },
  {
    "x": "kîlî (m)",
    "y": " b. gillî (m)-1"
  },
  {
    "x": "kîlîme (m)",
    "y": " b. kilîme (m)"
  },
  {
    "x": "kîlîsa (m)",
    "y": " b. kilîse (n)"
  },
  {
    "x": "kîlîse (m)",
    "y": " b. kilîse (n)"
  },
  {
    "x": "kîlît (n)",
    "y": " b. kilît (n)"
  },
  {
    "x": "kîllî (m)",
    "y": " b. gillî (m)-1"
  },
  {
    "x": "kîlo (n)",
    "y": " 1)kilogram, kilo\r\n~2)el kantarı"
  },
  {
    "x": "kîloamper (n)",
    "y": " kiloamper"
  },
  {
    "x": "kîlogram (n)",
    "y": " kilo, kilogram "
  },
  {
    "x": "kîlogrammetre (n)",
    "y": " kilogrammetre"
  },
  {
    "x": "kîlohertz (n)",
    "y": " kilohertz"
  },
  {
    "x": "kîlolître (n)",
    "y": " kilolitre"
  },
  {
    "x": "kîlometre (n)",
    "y": " kilometre"
  },
  {
    "x": "kerraya kîlometreyan (m)",
    "y": " kilometre taşı"
  },
  {
    "x": "kemera kîlometreyan (m)",
    "y": " kilometre taşı"
  },
  {
    "x": "kîlometrekare (n)",
    "y": " kilometrekare"
  },
  {
    "x": "kîlometremîkab (n)",
    "y": " kilometreküp"
  },
  {
    "x": "kîlotone (m)",
    "y": " kiloton"
  },
  {
    "x": "kîloyî (s)",
    "y": " kiloluk"
  },
  {
    "x": "baro vîst kîloyî",
    "y": " yirmi kiloluk yük"
  },
  {
    "x": "kîlsa (m)",
    "y": " b. kilîse (n)"
  },
  {
    "x": "kîmya (m)",
    "y": " kimya "
  },
  {
    "x": "kîmyager, -e",
    "y": " kimyacı, kimyager"
  },
  {
    "x": "kîmyanas, -e",
    "y": " kimyacı, kimyager"
  },
  {
    "x": "kîmyayî, -ye",
    "y": " kimyasal"
  },
  {
    "x": "kîmyon (n)",
    "y": " kimyon "
  },
  {
    "x": "kîn (n)",
    "y": " kin, garaz, garez"
  },
  {
    "x": "kîndar, -e",
    "y": " kinci, kindar"
  },
  {
    "x": "kînger (n)",
    "y": " b. kenger (n)"
  },
  {
    "x": "Kîngston (n)",
    "y": " Kingston"
  },
  {
    "x": "Kînşasa (m)",
    "y": " Kinshasa"
  },
  {
    "x": "kîp, -e",
    "y": " 1)dopdolu\r\n~2)süratli"
  },
  {
    "x": "kîpebimbar (n)",
    "y": " b. kîpebumbar (n)"
  },
  {
    "x": "kîpebumbar (n)",
    "y": " içine pirinç, bulgur vb. doldurulan işkembenin dikilip pişirilmesiyle yapılan yemek"
  },
  {
    "x": "kîpî (m)",
    "y": " b. kupî (m)"
  },
  {
    "x": "kîr (n)",
    "y": " b. kir (n)"
  },
  {
    "x": "kîra (m)",
    "y": " kira"
  },
  {
    "x": "kîradar, -e",
    "y": " kiracı (kiralayan) "
  },
  {
    "x": "kîran (n)",
    "y": " b. kêran (n)"
  },
  {
    "x": "kîraze (m)",
    "y": " b. gilyaze (m)"
  },
  {
    "x": "kîrazêri (m)",
    "y": " b. gilyazêre (m)"
  },
  {
    "x": "kîrbît (n)",
    "y": " b. kibrît (n)"
  },
  {
    "x": "kîre (n)",
    "y": " b. kîrye (n)"
  },
  {
    "x": "kîri (n)",
    "y": " b. kîrye (n)"
  },
  {
    "x": "kîrî (n)",
    "y": " b. kîrye (n)"
  },
  {
    "x": "Kîrîbatî",
    "y": " Kiribati "
  },
  {
    "x": "kîrîş (n)",
    "y": " kiriş (duvar için)"
  },
  {
    "x": "kîrko (n)",
    "y": " b. krîko (n)"
  },
  {
    "x": "kîrûn (n)",
    "y": " b. kêran (n)"
  },
  {
    "x": "kîrûney (m)",
    "y": " b. kêranîye (m)"
  },
  {
    "x": "kîrûnê (m)",
    "y": " b. kêranîye (m)"
  },
  {
    "x": "kîrvite (m)",
    "y": " b. kibrît (n)"
  },
  {
    "x": "kîrye (n)",
    "y": " pazar (b. yewşeme)"
  },
  {
    "x": "kîse (n)",
    "y": " kese, torba, bez torba"
  },
  {
    "x": "kîsew kaxityen (n)",
    "y": " b. kîseyo kaxitên (n)"
  },
  {
    "x": "kîseyo kaxitên (n)",
    "y": " kağıt torba"
  },
  {
    "x": "kîseyo naylonên (n)",
    "y": " naylon torba"
  },
  {
    "x": "kîsi (n)",
    "y": " b. kîse (n)"
  },
  {
    "x": "kîsik (n)",
    "y": " kese, torba, bez torba "
  },
  {
    "x": "kîsve (n)",
    "y": " b. kisvet (n)"
  },
  {
    "x": "kîsvi (n)",
    "y": " b. kisvet (n)"
  },
  {
    "x": "kîswetî (zh)",
    "y": " b. kisvetî (zh)"
  },
  {
    "x": "Kîşînev (n)",
    "y": " Kişinev"
  },
  {
    "x": "kîşîyayene",
    "y": " b. kişîyayene"
  },
  {
    "x": "kîşîyayîş (n)",
    "y": " b. kişîyayîş (n)"
  },
  {
    "x": "kîşîyene",
    "y": " b. kişîyayene"
  },
  {
    "x": "kîşnîş (n)",
    "y": " kişniş"
  },
  {
    "x": "kîşt (m)",
    "y": " b. kîşte (m) "
  },
  {
    "x": "kîştakewtiş (n)",
    "y": " uyuma"
  },
  {
    "x": "kîştavistiş (n)",
    "y": " uyutma"
  },
  {
    "x": "kîşte (m)",
    "y": " 1)böğür (boş böğür)\r\n~2)yan"
  },
  {
    "x": "kîşt a kewtene",
    "y": " uyumak"
  },
  {
    "x": "kîşt a vistene",
    "y": " uyutmak"
  },
  {
    "x": "kîştene",
    "y": " b. kiştene"
  },
  {
    "x": "kîşti (m)",
    "y": " b. kîşte (m)"
  },
  {
    "x": "kîştiş (n)",
    "y": " b. kiştiş (n)"
  },
  {
    "x": "kîştkanî",
    "y": " yan yan"
  },
  {
    "x": "kîştkanî şîyayox, -e",
    "y": " çalık"
  },
  {
    "x": "kîştkî",
    "y": " yan yan "
  },
  {
    "x": "kîştkonî",
    "y": " b. kîştkanî"
  },
  {
    "x": "kîştkûnî",
    "y": " b. kîştkanî"
  },
  {
    "x": "kîtab (n)",
    "y": " b. kitab (n)"
  },
  {
    "x": "kîvar, -e",
    "y": " b. kîbar, -e"
  },
  {
    "x": "kîvarî (m)",
    "y": " b. kîbarî (m)"
  },
  {
    "x": "kîvarîye (m)",
    "y": " b. kîbarîye (m), kîbarî (m)"
  },
  {
    "x": "kîvrite (m)",
    "y": " b. kibrît (n)"
  },
  {
    "x": "kîwî (m)",
    "y": " kivi"
  },
  {
    "x": "kîya (n)",
    "y": " b. keyxwa (n), keya (n)"
  },
  {
    "x": "kîye (n)",
    "y": " b. keye-I (n)"
  },
  {
    "x": "kîyneki (m)",
    "y": " b. kêneke (m)"
  },
  {
    "x": "klakson (n)",
    "y": " klakson, korna"
  },
  {
    "x": "klasîfîkasyon (n)",
    "y": " bölümleme, klasifikasyon, sınıflandırma, tasnif"
  },
  {
    "x": "klasîk, -e",
    "y": " klasik"
  },
  {
    "x": "klasor (n)",
    "y": " klasör"
  },
  {
    "x": "klavye (n)",
    "y": " klavye"
  },
  {
    "x": "klementîne (m)",
    "y": " klementin"
  },
  {
    "x": "klîent (n) (TE)",
    "y": " istemci"
  },
  {
    "x": "klîk (n)",
    "y": " fraksiyon, hizip (sosyolojide), klik"
  },
  {
    "x": "klînometre (n)",
    "y": " eğimölçer"
  },
  {
    "x": "klor (n)",
    "y": " klor "
  },
  {
    "x": "klorpeym (n)",
    "y": " klorölçer"
  },
  {
    "x": "ko-I (n)",
    "y": " dağ"
  },
  {
    "x": "koyê cemedî (n)",
    "y": " buzdağı"
  },
  {
    "x": "koyo berz (n)",
    "y": " yüksek dağ"
  },
  {
    "x": "sîlsîla koyan (m)",
    "y": " b. sîlsîlaya koyan (m)"
  },
  {
    "x": "sîlsîlaya koyan (m)",
    "y": " sıradağlar"
  },
  {
    "x": "sîlsîlê kueyûn (m)",
    "y": " b. sîlsîlaya koyan (m)"
  },
  {
    "x": "ko-II",
    "y": " fiilin gelecek zamanında kullanılan bir partikel\r\n~Ti ko binusî. (Sen yazacaksın.)"
  },
  {
    "x": "ko-III",
    "y": " b. ke-I"
  },
  {
    "x": "ko-IV",
    "y": " b. xow (n)"
  },
  {
    "x": "ko ra şîyayene",
    "y": " b. xow ra şîyene"
  },
  {
    "x": "ko re şîyayene",
    "y": " b. xow ra şîyene"
  },
  {
    "x": "ko rê şîyayene",
    "y": " b. xow ra şîyene"
  },
  {
    "x": "ko ro şîyene",
    "y": " b. xow ra şîyene"
  },
  {
    "x": "Roj ko ro şi",
    "y": " b. Roj xow ra şi."
  },
  {
    "x": "Tîje ko ro şîye",
    "y": " b. Tîje xow ra şîye."
  },
  {
    "x": "ko-V",
    "y": " b. ku-I"
  },
  {
    "x": "ko-VI (n)",
    "y": " b. kef (n)"
  },
  {
    "x": "koar",
    "y": " b. kor, -e"
  },
  {
    "x": "koarbilîyes (n)",
    "y": " b. korbilîyes (n) "
  },
  {
    "x": "kobe (n)",
    "y": " 1)kıyı, sahil\r\n~kobeyê çemî (n): akarsu kıyısı, nehir kıyısı\r\n~2)tiriz"
  },
  {
    "x": "kobeyê çemî (n)",
    "y": " akarsu kıyısı, nehir kıyısı"
  },
  {
    "x": "kobeyê robarî (n)",
    "y": " akarsu kıyısı, nehir kıyısı"
  },
  {
    "x": "kobeyê royî (n)",
    "y": " akarsu kıyısı, nehir kıyısı\r\n~2)tiriz"
  },
  {
    "x": "kobeyê kincan/cilan (n)",
    "y": " tiriz"
  },
  {
    "x": "kobra, -ye",
    "y": " kobra yılanı "
  },
  {
    "x": "koc, -e",
    "y": " manda yavrusu, malak"
  },
  {
    "x": "koca",
    "y": " b. kamca"
  },
  {
    "x": "koç (n)",
    "y": " göç, göçme"
  },
  {
    "x": "koç kerdene",
    "y": " göç etmek, göçmek"
  },
  {
    "x": "koçê mezgan (n)",
    "y": " beyin göçü"
  },
  {
    "x": "koçer, -e",
    "y": " göçebe, göçer"
  },
  {
    "x": "koçerey (m)",
    "y": " b. koçerîye (m)"
  },
  {
    "x": "koçerî, -ye",
    "y": " göçeri"
  },
  {
    "x": "koçerîye (m)",
    "y": " göçebelik"
  },
  {
    "x": "koçik (m)",
    "y": " b. koçike (m)"
  },
  {
    "x": "koçik kerdene",
    "y": " b. koçike kerdene"
  },
  {
    "x": "koçikê dêsan (m)",
    "y": " b. koçika dêsan (m)"
  },
  {
    "x": "koçike (m)",
    "y": " 1)kaşık\r\n~koçika çay (m): çay kaşığı\r\n~koçika darêne (m): tahta kaşık\r\n~2)mala"
  },
  {
    "x": "koçika çay (m)",
    "y": " çay kaşığı"
  },
  {
    "x": "koçika çayî (m)",
    "y": " b. koçika çay (m)"
  },
  {
    "x": "koçika darêne (m)",
    "y": " tahta kaşık"
  },
  {
    "x": "koçikey çay (m)",
    "y": " b. koçika çay (m)"
  },
  {
    "x": "koçika dêsan (m)",
    "y": " mala"
  },
  {
    "x": "koçika siwaxî (m)",
    "y": " mala"
  },
  {
    "x": "koçika sîwaxî (m)",
    "y": " b. koçika siwaxî (m)"
  },
  {
    "x": "koçike kerdene",
    "y": " malalamak"
  },
  {
    "x": "koçikekerdiş (n)",
    "y": " malalama"
  },
  {
    "x": "koçiki (m)",
    "y": " b. koçike (m)"
  },
  {
    "x": "koçikkerdiş (n)",
    "y": " b. koçikekerdiş (n)"
  },
  {
    "x": "koçikter (n)",
    "y": " ivgi"
  },
  {
    "x": "koçkerdiş (n)",
    "y": " göç, göç etme, göçme"
  },
  {
    "x": "koçmek (n)",
    "y": " erkek keklik"
  },
  {
    "x": "kod-I (n)",
    "y": " 1)tahtadan yapılmış derin kap\r\n~2)tahıl vb. şeyleri ölçmek için kullanılan tahtadan yapılmış büyük kap, ölçek, şinik, kile"
  },
  {
    "x": "kod-II (n)",
    "y": " kod"
  },
  {
    "x": "kodik (n)",
    "y": " b. kod-I (n)"
  },
  {
    "x": "koefî (m)",
    "y": " b. kofî (m)"
  },
  {
    "x": "kofî (m)",
    "y": " hotoz"
  },
  {
    "x": "kofîye (m)",
    "y": " hotoz"
  },
  {
    "x": "koft (n)",
    "y": " b. kift (n)"
  },
  {
    "x": "koho (n)",
    "y": " b. kewe, -ye-I"
  },
  {
    "x": "kohon (n)",
    "y": " b. kehen (n)"
  },
  {
    "x": "kohonê (m)",
    "y": " b. kehenî (m)"
  },
  {
    "x": "koj (n)",
    "y": " iplik makarası"
  },
  {
    "x": "kok-I (n)",
    "y": " 1)kök, kök (mat)\r\n~2)ağaç kökü"
  },
  {
    "x": "kok ra",
    "y": " baştan sona (zr)"
  },
  {
    "x": "kokê dare (n)",
    "y": " ağaç kökü"
  },
  {
    "x": "kok-II (n)",
    "y": " akort, ayar"
  },
  {
    "x": "kok bîyene",
    "y": " ayarlanmak"
  },
  {
    "x": "kok kerdene",
    "y": " akortlamak, akort etmek, ayarlamak"
  },
  {
    "x": "koka-I (m)",
    "y": " kuka, dantel ipliği yumağı"
  },
  {
    "x": "koka-II (m)",
    "y": " koka"
  },
  {
    "x": "kokaîn (n)",
    "y": " kokain"
  },
  {
    "x": "kokbîyayiş (n)",
    "y": " ayarlanma"
  },
  {
    "x": "koke (m)",
    "y": " 1)b. kok-I (n)\r\n~2)sap (bitki için)"
  },
  {
    "x": "koka dare (m)",
    "y": " b. kokê dare (n)"
  },
  {
    "x": "koke ra",
    "y": " b. kok ra"
  },
  {
    "x": "kokim, -e",
    "y": " yaşlı, ihtiyar"
  },
  {
    "x": "kokimên (m)",
    "y": " b. kokimênî (m) "
  },
  {
    "x": "kokimênî (m)",
    "y": " yaşlılık, ihtiyarlık"
  },
  {
    "x": "kokimî (m)",
    "y": " yaşlılık, ihtiyarlık"
  },
  {
    "x": "kokimîye (m)",
    "y": " yaşlılık, ihtiyarlık"
  },
  {
    "x": "kokkare (n)",
    "y": " karekök"
  },
  {
    "x": "kokkerda (m)",
    "y": " ayarlı, akortlu"
  },
  {
    "x": "kokkerde (n)",
    "y": " ayarlı, akortlu"
  },
  {
    "x": "kokkerdiş (n)",
    "y": " akortlama, akort etme, ayarlama, kalibraj"
  },
  {
    "x": "kokkerdişê sipîyî (sn)",
    "y": " beyaz ayarı"
  },
  {
    "x": "kokkerdî, -ye",
    "y": " ayarlı, akortlu"
  },
  {
    "x": "kokmew (n)",
    "y": " b. kokmêw (n)"
  },
  {
    "x": "kokmêw (n)",
    "y": " asma kütüğü, omça, omca"
  },
  {
    "x": "koknêkerda (m)",
    "y": " ayasız, akortsuz"
  },
  {
    "x": "koknêkerde (n)",
    "y": " ayarsız, akortsuz"
  },
  {
    "x": "koknêkerdeyîye (m)",
    "y": " ayarsızlık, akortsuzluk"
  },
  {
    "x": "koknêkerdî, -ye",
    "y": " ayarsız, akortsuz"
  },
  {
    "x": "kokum, -e",
    "y": " b. kokim, -e"
  },
  {
    "x": "kokumî (m)",
    "y": " b. kokimî (m), kokimîye (m)"
  },
  {
    "x": "kokumîye (m)",
    "y": " b. kokimî (m), kokimîye (m)"
  },
  {
    "x": "kol, -e",
    "y": " 1)kör (keskinliği yeterli olmayan)\r\n~2)boynuzsuz"
  },
  {
    "x": "kola-I (m)",
    "y": " köle, kul, abd"
  },
  {
    "x": "kola to",
    "y": " b. koleya to"
  },
  {
    "x": "kola-II (m)",
    "y": " kola"
  },
  {
    "x": "kola kerdene",
    "y": " kolalamak"
  },
  {
    "x": "kolakerdiş (n)",
    "y": " kolalama"
  },
  {
    "x": "kolane (m)",
    "y": " omuzun boyuna yakın kısmı"
  },
  {
    "x": "kole-I (n)",
    "y": " köle, kul, abd"
  },
  {
    "x": "koleya to (m)",
    "y": " cariyeleri, cariyeniz"
  },
  {
    "x": "Kole-II (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "kolek (n)",
    "y": " b. kolik (n)"
  },
  {
    "x": "koleksîyon (n)",
    "y": " koleksiyon "
  },
  {
    "x": "koleksîyondar, -e",
    "y": " koleksiyoncu"
  },
  {
    "x": "kolektîf, -e",
    "y": " kolektif"
  },
  {
    "x": "kolera (m)",
    "y": " kolera "
  },
  {
    "x": "koletî (m)",
    "y": " kölelik, kulluk"
  },
  {
    "x": "koley (m)",
    "y": " b. kolî-I (m)"
  },
  {
    "x": "koleye (m)",
    "y": " köle, kul, abd"
  },
  {
    "x": "koleyey (m)",
    "y": " b. koleyî (m), b. koletî (m)"
  },
  {
    "x": "koleyî (m)",
    "y": " kölelik, kulluk"
  },
  {
    "x": "kolênî (m)",
    "y": " boynuzsuzluk"
  },
  {
    "x": "koli-I (n)",
    "y": " b. kolî-II (n)"
  },
  {
    "x": "koli-II (m)",
    "y": " b. kole (m)"
  },
  {
    "x": "Koli (m)",
    "y": " b. Kole (m)"
  },
  {
    "x": "kolik (n)",
    "y": " omuzun boyuna yakın kısmı"
  },
  {
    "x": "Kolik (n)",
    "y": " Kâhta"
  },
  {
    "x": "kolinî (m)",
    "y": " b. kolênî (m)"
  },
  {
    "x": "kolî-I (m)",
    "y": " boynuzsuzluk"
  },
  {
    "x": "kolî-II (n)",
    "y": " odun"
  },
  {
    "x": "kolîyê mazêre (n)",
    "y": " meşe odunu"
  },
  {
    "x": "kolî-III (m)",
    "y": " koli"
  },
  {
    "x": "kolîbir, -e",
    "y": " oduncu (odun kesen kimse)"
  },
  {
    "x": "kolîbirî (m)",
    "y": " odunculuk"
  },
  {
    "x": "kolîbirîye (m)",
    "y": " odunculuk"
  },
  {
    "x": "kolîroş, -e",
    "y": " oduncu (odun satan kimse)"
  },
  {
    "x": "kolîroşî (m)",
    "y": " odunculuk"
  },
  {
    "x": "kolîroşîye (m)",
    "y": " odunculuk"
  },
  {
    "x": "kolîye (m)",
    "y": " boynuzsuzluk"
  },
  {
    "x": "koll, -i",
    "y": " b. kol, -e"
  },
  {
    "x": "kollektor (n)",
    "y": " kolektör"
  },
  {
    "x": "kollektorê gendawe (n)",
    "y": " atık su kolektörü"
  },
  {
    "x": "Kolombîya (m)",
    "y": " Kolombiya "
  },
  {
    "x": "Kolombo (n)",
    "y": " Kolombo"
  },
  {
    "x": "kolonaj (n)",
    "y": " kolonaj (basın için)"
  },
  {
    "x": "kolonî (m)",
    "y": " sömürge"
  },
  {
    "x": "kolonyalîst, -e",
    "y": " sömürgeci"
  },
  {
    "x": "kolonyalîstîye (m)",
    "y": " sömürgecilik "
  },
  {
    "x": "kolonyalîzm (n)",
    "y": " sömürgecilik"
  },
  {
    "x": "kolorîmetre (n)",
    "y": " renkölçer, kolorimetre"
  },
  {
    "x": "kolose (m)",
    "y": " b. koloze (m)"
  },
  {
    "x": "koloz (m)",
    "y": " b. koloze (m)"
  },
  {
    "x": "koloze (m)",
    "y": " külah, başlık"
  },
  {
    "x": "kolozi (m)",
    "y": " b. koloze (m)"
  },
  {
    "x": "kolye (m)",
    "y": " kolye\r\n~kolyeya zerrnêne (m): altın kolye"
  },
  {
    "x": "kolyeya zerrêne (m)",
    "y": " altın kolye"
  },
  {
    "x": "kolyeya zerrnêne (m)",
    "y": " altın kolye"
  },
  {
    "x": "kom, -i",
    "y": " b. kam, -e"
  },
  {
    "x": "kom hel",
    "y": " b. kam hele"
  },
  {
    "x": "kom bîyayene",
    "y": " b. kom bîyene"
  },
  {
    "x": "kom bîyene",
    "y": " 1)toplanmak (mat)\r\n~2)birikmek"
  },
  {
    "x": "kom kerdene",
    "y": " 1)toplamak (mat)\r\n~2)biriktirmek"
  },
  {
    "x": "koma (m)",
    "y": " koma "
  },
  {
    "x": "koma de bîyene",
    "y": " komada olmak"
  },
  {
    "x": "komare (m)",
    "y": " cumhuriyet "
  },
  {
    "x": "komarperwer, -e",
    "y": " cumhuriyetçi"
  },
  {
    "x": "komarperwerîye (m)",
    "y": " cumhuriyetçilik, cumhuriyetperver"
  },
  {
    "x": "komarwaz, -e",
    "y": " cumhuriyetçi, cumhuriyetperver"
  },
  {
    "x": "komarwazîye (m)",
    "y": " cumhuriyetçilik"
  },
  {
    "x": "kombanî (zh)",
    "y": " toplu konut"
  },
  {
    "x": "kombînayî (zh)",
    "y": " toplu konut"
  },
  {
    "x": "kombînezon (n)",
    "y": " kombinezon (giysi için)"
  },
  {
    "x": "kombîyaye (n)",
    "y": " toplanan (mat)"
  },
  {
    "x": "kombîyayîş (n)",
    "y": " 1)toplanma\r\n~2)birikme\r\n~3)toplantı\r\n~kombîyayîşê banî (n): bina toplantısı\r\n~kombîyayîşê çapemenîye (n): basın toplantısı"
  },
  {
    "x": "kombîyayîşê banî (n)",
    "y": " bina toplantısı"
  },
  {
    "x": "kombîyayîşê bîna (n)",
    "y": " bina toplantısı"
  },
  {
    "x": "kombîyayîşê çapemenîye (n)",
    "y": " basın toplantısı"
  },
  {
    "x": "kombîyayîşê şarî (n)",
    "y": " halk toplantısı"
  },
  {
    "x": "kombîyayîşê taxe (n)",
    "y": " mahalle toplantısı"
  },
  {
    "x": "komca",
    "y": " b. kamca"
  },
  {
    "x": "komcî",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "komcîn",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "komça (m)",
    "y": " ağaç topluluğu"
  },
  {
    "x": "komçe (n)",
    "y": " küme"
  },
  {
    "x": "komder, -i",
    "y": " b. kamder, -e"
  },
  {
    "x": "kome-I (m)",
    "y": " 1)küme, grup, takım, ekip\r\n~koma akerdiş û birnayîşî (m): açma kesme takımı\r\n~koma rewşa lezabezîye (m): acil durum ekibi\r\n~2)toplam (mat)"
  },
  {
    "x": "koma akerdiş û birnayîşî (m)",
    "y": " açma kesme takımı"
  },
  {
    "x": "koma hedefe (m)",
    "y": " hedef grup"
  },
  {
    "x": "koma îtfaîya (m)",
    "y": " itfaiye ekibi"
  },
  {
    "x": "koma mudaxeleyî (m)",
    "y": " müdahale grubu"
  },
  {
    "x": "koma rewşa lezabezî (m)",
    "y": " acil durum ekibi"
  },
  {
    "x": "koma rewşa lezabezîye (m)",
    "y": " acil durum ekibi"
  },
  {
    "x": "koma wekerdiş û birnayîşî (m)",
    "y": " açma kesme takımı"
  },
  {
    "x": "koma xebate (m)",
    "y": " çalışma grubu"
  },
  {
    "x": "komê feydevînayoxî (zh)",
    "y": " fayda sağlayan gruplar"
  },
  {
    "x": "kome-II (n)",
    "y": " sucuk (bandırma)"
  },
  {
    "x": "komedî (m)",
    "y": " komedi"
  },
  {
    "x": "komedyen, -e",
    "y": " komedyen "
  },
  {
    "x": "komedyenîye (m)",
    "y": " komedyenlik "
  },
  {
    "x": "komekar (n)",
    "y": " toplayan (mat)"
  },
  {
    "x": "komel (n)",
    "y": " toplum"
  },
  {
    "x": "komelê zanayîşî (n)",
    "y": " bilgi toplumu"
  },
  {
    "x": "komele (m)",
    "y": " dernek"
  },
  {
    "x": "Komela Huneranê Televîzyon û Sînema ya Îngilîstanî",
    "y": " İngiltere Televizyon ve Sinema Sanatları Derneği"
  },
  {
    "x": "komelkî, -ye",
    "y": " toplumsal, sosyal, içtimai"
  },
  {
    "x": "komelnas, -e",
    "y": " sosyolog"
  },
  {
    "x": "komelnasî (m)",
    "y": " sosyoloji"
  },
  {
    "x": "komelnasîya huqûqî (m)",
    "y": " hukuk sosyolojisi"
  },
  {
    "x": "komelnaskî, -ye",
    "y": " sosyolojik"
  },
  {
    "x": "komgirawî (zh)",
    "y": " takımadalar"
  },
  {
    "x": "komi-I (m)",
    "y": " b. kome-I (m)"
  },
  {
    "x": "komi-II",
    "y": " b. kame"
  },
  {
    "x": "komik",
    "y": " b. kamik, -e"
  },
  {
    "x": "komike (m)",
    "y": " grup"
  },
  {
    "x": "komir (n)",
    "y": " kömür"
  },
  {
    "x": "komî",
    "y": " b. kamî"
  },
  {
    "x": "komîdîn (n)",
    "y": " b. komodîne (m)"
  },
  {
    "x": "komîsyon (n)",
    "y": " 1)komisyon (altkurul)\r\n~Komîsyonê Çorşme û Averşîyayîşî yê Cîhanî/Dinya: Dünya Çevre ve Gelişme Komisyonu\r\n~2)komîsyon (n), sîmsarîye (m), delalîye (m)"
  },
  {
    "x": "komîsyonê awanî (n)",
    "y": " imar komisyonu"
  },
  {
    "x": "Komîsyonê Çorşme û Averşîyayîşî yê Cîhanî/Dinya",
    "y": " Dünya Çevre ve Gelişme Komisyonu"
  },
  {
    "x": "Komîsyonê Dorûver û Averşîyayîşî yê Cîhanî/Dinya",
    "y": " Dünya Çevre ve Gelişme Komisyonu"
  },
  {
    "x": "komîte (m)",
    "y": " komite"
  },
  {
    "x": "komîteya zanistî ya problemanê doruverî",
    "y": " çevre sorunları bilimsel komitesi"
  },
  {
    "x": "komjû",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "komkerdiş (n)",
    "y": " 1)toplama (mat)\r\n~2)biriktirme"
  },
  {
    "x": "kombîyayîşê dayîyan",
    "y": " verilerin toplanması"
  },
  {
    "x": "kombîyayîşê doneyan",
    "y": " verilerin toplanması"
  },
  {
    "x": "komkontrat (n)",
    "y": " toplu sözleşme"
  },
  {
    "x": "kommuqawele (n)",
    "y": " toplu sözleşme"
  },
  {
    "x": "komnimûne (m)",
    "y": " örneklem (araştırma için)"
  },
  {
    "x": "komodîn (n)",
    "y": " b. komodîne (m)"
  },
  {
    "x": "komodîne (m)",
    "y": " komodin, komot"
  },
  {
    "x": "komos (n)",
    "y": " b. kamos (n)"
  },
  {
    "x": "kompîtor (n)",
    "y": " b. komputure (m)"
  },
  {
    "x": "kompleks (n)",
    "y": " kompleks "
  },
  {
    "x": "komkpleksê xokêmîdîyayîşî (n)",
    "y": " aşağılık kompleksi"
  },
  {
    "x": "kompozîsyon (n)",
    "y": " kompozisyon"
  },
  {
    "x": "kompozîsyon viraştene",
    "y": " kompozisyon kurmak"
  },
  {
    "x": "kompozîsyonviraştiş (n)",
    "y": " kompozisyon kurma"
  },
  {
    "x": "kompozîsyono edebî (n)",
    "y": " edebi kompozisyon"
  },
  {
    "x": "kompozîtor, -e",
    "y": " besteci, bestekâr, kompozitör"
  },
  {
    "x": "kompresor (n)",
    "y": " kompresör, sıkmaç"
  },
  {
    "x": "kompresorê gazî (n)",
    "y": " gaz kompresörü"
  },
  {
    "x": "komputer (n)",
    "y": " b. komputure (m)"
  },
  {
    "x": "komputor (n)",
    "y": " b. komputure (m)"
  },
  {
    "x": "komputure (m)",
    "y": " bilgisayar, kompütür"
  },
  {
    "x": "çîyhuşkê komputure (zh) (TE)",
    "y": " donanım"
  },
  {
    "x": "çîynermê komputure (zh) (TE)",
    "y": " yazılım"
  },
  {
    "x": "komputura kesî (m) (TE)",
    "y": " kişisel bilgisayar"
  },
  {
    "x": "komputura şexsî (m) (TE)",
    "y": " kişisel bilgisayar"
  },
  {
    "x": "programviraştoxa komputure (m)",
    "y": " bilgisayar programcısı"
  },
  {
    "x": "programviraştoxê komputure (n)",
    "y": " bilgisayar programcısı"
  },
  {
    "x": "komresî (m)",
    "y": " toplu ulaşım"
  },
  {
    "x": "komresîye (m)",
    "y": " toplu ulaşım"
  },
  {
    "x": "komserf (n)",
    "y": " toplu tüketim"
  },
  {
    "x": "komunîkasyon (n)",
    "y": " iletişim"
  },
  {
    "x": "komunîkasyono dînamîk (n)",
    "y": " etkin iletişim"
  },
  {
    "x": "komunîkasyono efektîf (n)",
    "y": " etkin iletişim "
  },
  {
    "x": "komunîkasyono tesîrdar (n)",
    "y": " etkin iletişim"
  },
  {
    "x": "komut (n)",
    "y": " komut"
  },
  {
    "x": "komutan, -e",
    "y": " kumandan, komutan"
  },
  {
    "x": "komûkulfet (n)",
    "y": " cümbür cemaat"
  },
  {
    "x": "komxebate (m)",
    "y": " çalıştay"
  },
  {
    "x": "kon-I",
    "y": " b. kan, -e; kehen, -e"
  },
  {
    "x": "kon-II (n)",
    "y": " b. kone-II (n)"
  },
  {
    "x": "kon-III (m)",
    "y": " b. kone-III (m)"
  },
  {
    "x": "Konakrî",
    "y": " Konakri"
  },
  {
    "x": "konca",
    "y": " b. kamca"
  },
  {
    "x": "konci",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "koncî",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "koncu",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "kondês (n)",
    "y": " b. kondêz (n)"
  },
  {
    "x": "kondêz (n)",
    "y": " kepçe"
  },
  {
    "x": "kone-I (n)",
    "y": " 1)tokaç\r\n~2)tokmak\r\n~koneyê cirne (n): havaneli, soku tokmağı, dibek tokmağı "
  },
  {
    "x": "koneyê cirne (n)",
    "y": " havaneli, soku tokmağı, dibek tokmağı "
  },
  {
    "x": "kone-II (n)",
    "y": " kör (keskinliği yeterli olmayan)"
  },
  {
    "x": "kone-III (m)",
    "y": " çadır"
  },
  {
    "x": "kone-IV (n)",
    "y": " b. konî (n)"
  },
  {
    "x": "konekerdiş (n)",
    "y": " tahıl ürünlerini tokaçla döverek sapından ayırma"
  },
  {
    "x": "konê (m)",
    "y": " b. kanî (m)"
  },
  {
    "x": "konferans (n)",
    "y": " konferans (n)"
  },
  {
    "x": "Konferansê Sînemaya Kurdan",
    "y": " Kürt Sineması Konferansı"
  },
  {
    "x": "konfîgurasyon (n)",
    "y": " konfigürasyon, yapılandırma"
  },
  {
    "x": "konfor (n)",
    "y": " konfor"
  },
  {
    "x": "konforo termal (n)",
    "y": " termal konfor"
  },
  {
    "x": "Kongo (n)",
    "y": " Kongo, Zaire"
  },
  {
    "x": "Kongo-Brazzavîlle",
    "y": " Kongo-Brazzaville "
  },
  {
    "x": "kongra (m)",
    "y": " b. kongre (m)"
  },
  {
    "x": "kongre (m)",
    "y": " kongre"
  },
  {
    "x": "kongreya awarteye (m)",
    "y": " olağanüstü kongre"
  },
  {
    "x": "koni (n)",
    "y": " b. kone-I (n)"
  },
  {
    "x": "konî cirni (n)",
    "y": " b. koneyê cirne (n)"
  },
  {
    "x": "konîy cirni (n)",
    "y": " b. koneyê cirne (n)"
  },
  {
    "x": "konikerdiş (n)",
    "y": " b. konekerdiş (n)"
  },
  {
    "x": "konî-I (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "konî-II (n)",
    "y": " 1)koni\r\n~2)koni (mat)"
  },
  {
    "x": "konîk, -e",
    "y": " 1)konik\r\n~2)konik (mat)"
  },
  {
    "x": "konîye (m)",
    "y": " kör (keskinliği yeterli olmayan)"
  },
  {
    "x": "konkav",
    "y": " içbükey"
  },
  {
    "x": "konkil (n)",
    "y": " b. kakile (m)"
  },
  {
    "x": "konkili (m)",
    "y": " b. kakile (m)"
  },
  {
    "x": "konkret, -e",
    "y": " somut, müşahhas"
  },
  {
    "x": "konsantra (m)",
    "y": " yoğun"
  },
  {
    "x": "konsantrasyon (n)",
    "y": " derişim, konsantrasyon, yoğunlaşma (bütün dikkatini bir konu üzerinde toplama)"
  },
  {
    "x": "konsantre (n)",
    "y": " yoğun"
  },
  {
    "x": "konsantre bîyene",
    "y": " yoğunlaşmak (bütün dikkatini bir konu üzerinde toplamak)"
  },
  {
    "x": "konsantre kerdene",
    "y": " yoğunlaştırmak (bütün dikkatini bir konu üzerinde toplamak)"
  },
  {
    "x": "konsantrebîyayîş (n)",
    "y": " yoğunlaşma (bütün dikkatini bir konu üzerinde toplama)"
  },
  {
    "x": "konsantrekerdiş (n)",
    "y": " yoğunlaştırma (bütün dikkatini bir konu üzerinde toplama)"
  },
  {
    "x": "konsept (n)",
    "y": " kavram, konsept"
  },
  {
    "x": "konseptkî, -ye",
    "y": " kavramsal"
  },
  {
    "x": "konservatîf, -e",
    "y": " muhafazakâr "
  },
  {
    "x": "konservatîfîye (m)",
    "y": " muhafazakârlık"
  },
  {
    "x": "konservatuwar (n)",
    "y": " konservatuvar"
  },
  {
    "x": "konserve (n)",
    "y": " konserve"
  },
  {
    "x": "konseye (m)",
    "y": " konsey"
  },
  {
    "x": "konseya bajarî/şaristanî ya şaredarî",
    "y": " belediye kent konseyi"
  },
  {
    "x": "konsolos, -e",
    "y": " konsolos"
  },
  {
    "x": "konsomatrîse (m)",
    "y": " konsomatris"
  },
  {
    "x": "konsonante (m)",
    "y": " sessiz, sessiz harf; ünsüz, ünsüz harf"
  },
  {
    "x": "konstruksîyon (n)",
    "y": " konstrüksiyon"
  },
  {
    "x": "kontak (n)",
    "y": " b. kontakt (n)"
  },
  {
    "x": "kontakt (n)",
    "y": " kontak, temas"
  },
  {
    "x": "kontaktê elektrîkî (n)",
    "y": " elektrik kontağı"
  },
  {
    "x": "kontekst (n)",
    "y": " bağlam"
  },
  {
    "x": "konteyner (n)",
    "y": " taşımalık, konteyner"
  },
  {
    "x": "kontrat (n)",
    "y": " sözleşme, kontrat, akit, mukavele"
  },
  {
    "x": "kontratê komkarî (n)",
    "y": " toplu iş sözleşmesi"
  },
  {
    "x": "kontratê wekaletî (n)",
    "y": " vekâlet sözleşmesi"
  },
  {
    "x": "kontratê wekîlî (n)",
    "y": " vekâlet sözleşmesi"
  },
  {
    "x": "kontratê xizmetî (n)",
    "y": " hizmet sözleşmesi, hizmet mukavelesi"
  },
  {
    "x": "kontrol (n)",
    "y": " denetleme, denetim, kontrol, muayene, yoklama"
  },
  {
    "x": "kontrol bîyene",
    "y": " denetlenmek"
  },
  {
    "x": "kontrol kerdene",
    "y": " denetlemek, kontrol etmek, yoklamak, yoklama yapmak"
  },
  {
    "x": "kontrol û analîz",
    "y": " muayene ve analiz"
  },
  {
    "x": "kontrolê alkolî (n)",
    "y": " alkol kontrolü"
  },
  {
    "x": "kontrolê gemarinîya hewayî (n)",
    "y": " hava kirliliği denetimi"
  },
  {
    "x": "kontrolê germîye (n)",
    "y": " sıcaklık kontrolü"
  },
  {
    "x": "kontrolê senînîye (n)",
    "y": " kalite kontrolü"
  },
  {
    "x": "kontrolê xomîyanî",
    "y": " iç denetim"
  },
  {
    "x": "kontrolbîyayîş (n)",
    "y": " denetlenme"
  },
  {
    "x": "kontrolker, -e",
    "y": " denetçi, denetleyici, kontrolör"
  },
  {
    "x": "kontrolkera/ê xidayan",
    "y": " gıda denetçisi"
  },
  {
    "x": "kontrolkerdiş (n)",
    "y": " denetleme, kontrol, kontrol etme"
  },
  {
    "x": "kontur (n)",
    "y": " kontur (resim, makyaj vb. için)"
  },
  {
    "x": "konveks",
    "y": " dışbükey"
  },
  {
    "x": "konvertîsor (n)",
    "y": " değiştirgeç, konvertisör, röle"
  },
  {
    "x": "konvertisorê tanî/germî (n)",
    "y": " ısı değiştirgeci"
  },
  {
    "x": "konyak (n)",
    "y": " konyak"
  },
  {
    "x": "koon, -e",
    "y": " b. kehen, -e; kan, -e"
  },
  {
    "x": "koonê (m)",
    "y": " b. kanî (m)"
  },
  {
    "x": "koordînasyon (n)",
    "y": " eşgüdüm, koordinasyon"
  },
  {
    "x": "koordînat (n)",
    "y": " koordinat"
  },
  {
    "x": "koordînator, -e",
    "y": " eşgüdümcü, koordinatör"
  },
  {
    "x": "koordîne, -ye",
    "y": " eşgüdümlü, koordine"
  },
  {
    "x": "koordîne kerdene",
    "y": " eşgüdümlemek, koordine etmek"
  },
  {
    "x": "koordînekerdiş (n)",
    "y": " 1)eşgüdüm, koordinasyon\r\n~2)eşgüdümleme, koordine etme"
  },
  {
    "x": "kop (n)",
    "y": " yığın "
  },
  {
    "x": "kop kerdene",
    "y": " yığmak, loda etmek"
  },
  {
    "x": "Kop (n)",
    "y": " Bulanık "
  },
  {
    "x": "kopek, -e",
    "y": " (hakaret ifadesi olarak) köpek"
  },
  {
    "x": "kopekbav, -e",
    "y": " (sövgü sözcüğü) köpoğlu"
  },
  {
    "x": "kopekza, -ye",
    "y": " (sövgü sözcüğü) köpoğlu"
  },
  {
    "x": "kopekzay, -i",
    "y": " b. kopekza, -ye"
  },
  {
    "x": "Kopenhag (n)",
    "y": " Kopenhag"
  },
  {
    "x": "koperatîfe (m)",
    "y": " kooperatif"
  },
  {
    "x": "koperatîfi (m)",
    "y": " koperatîfe (m)"
  },
  {
    "x": "kopînayene",
    "y": " b. dekupnayene"
  },
  {
    "x": "kopînayîs (n)",
    "y": " b. dekupnayîş (n)"
  },
  {
    "x": "kopîyayene",
    "y": " b. dekupîyayene"
  },
  {
    "x": "kopîyayîs (n)",
    "y": " b. dekupîyayîş (n)"
  },
  {
    "x": "kopnayene",
    "y": " b. dekupnayene"
  },
  {
    "x": "koproduksîyon (n, sn)",
    "y": " ortakyapım, koprodüksiyon"
  },
  {
    "x": "kopya (m)",
    "y": " kopya"
  },
  {
    "x": "kopya kerdene",
    "y": " kopyalamak\r\n~Kopya bike! (Kopyala!)"
  },
  {
    "x": "kopyaya pawitişî (m) (TE)",
    "y": " yedekleme"
  },
  {
    "x": "kor, -e",
    "y": " görme engelli¸ görmez, gözsüz, kör"
  },
  {
    "x": "kori (m)",
    "y": " b. kore (m)"
  },
  {
    "x": "kora",
    "y": " b. kure-II"
  },
  {
    "x": "koraşîyayîş (n)",
    "y": " b. xowraşîyayîş (n)"
  },
  {
    "x": "korbeşuk (n)",
    "y": " b. kurbeşik (n) "
  },
  {
    "x": "korbilîyes (n)",
    "y": " salyangoz"
  },
  {
    "x": "korbir (n)",
    "y": " b. korbirr (n)"
  },
  {
    "x": "korbirr (n)",
    "y": " sık ağaçlık"
  },
  {
    "x": "korca (m)",
    "y": " b. korja (m)"
  },
  {
    "x": "kordon (n)",
    "y": " kordon"
  },
  {
    "x": "kore (n)",
    "y": " b. kuware (n)"
  },
  {
    "x": "Kore (m)",
    "y": " Kore"
  },
  {
    "x": "Koreya Başûrî (m)",
    "y": " Güney Kore"
  },
  {
    "x": "Koreya Vakurî (m)",
    "y": " Kuzey Kore "
  },
  {
    "x": "korek (n)",
    "y": " darı"
  },
  {
    "x": "koreko sipî (n)",
    "y": " akdarı, bir çeşit darı"
  },
  {
    "x": "koreko sîya (n)",
    "y": " bir çeşit darı"
  },
  {
    "x": "koreko sûr (n)",
    "y": " bir çeşit darı"
  },
  {
    "x": "korelasyon (n)",
    "y": " bağıntı"
  },
  {
    "x": "koremare (m)",
    "y": " köryılan"
  },
  {
    "x": "koremişk, -e",
    "y": " körfare"
  },
  {
    "x": "koremore (m)",
    "y": " b. koremare (m)"
  },
  {
    "x": "koremuş, -e",
    "y": " b. koremişk, -e"
  },
  {
    "x": "koreograf, -e",
    "y": " koreograf"
  },
  {
    "x": "koreografî (m)",
    "y": " koreografi"
  },
  {
    "x": "koreşîyayîş (n)",
    "y": " b. xowraşîyayîş (n)"
  },
  {
    "x": "korêk (n)",
    "y": " b. korek (n)"
  },
  {
    "x": "korêşîyayîş (n)",
    "y": " b. xowraşîyayîş (n)"
  },
  {
    "x": "korfahm, -e",
    "y": " b. korfehm, -e"
  },
  {
    "x": "korfam, -e",
    "y": " b. korfehm, -e"
  },
  {
    "x": "korfamey (m)",
    "y": " b. korfehmî (m), korfehmîye (m)"
  },
  {
    "x": "korfamê (m)",
    "y": " b. korfehmî (m), korfehmîye (m)"
  },
  {
    "x": "korfehm, -e",
    "y": " anlayışsız"
  },
  {
    "x": "korfehmênî (m)",
    "y": " anlayışsızlık"
  },
  {
    "x": "korfehmî (m)",
    "y": " anlayışsızlık"
  },
  {
    "x": "korfehmîye (m)",
    "y": " anlayışsızlık"
  },
  {
    "x": "korfêm, -e",
    "y": " b. korfehm, -e"
  },
  {
    "x": "korfêmey (m)",
    "y": " b. korfehmî (m), korfehmîye (m)"
  },
  {
    "x": "korfêmî (m)",
    "y": " b. korfehmî (m), korfehmîye (m)"
  },
  {
    "x": "korgire (n)",
    "y": " b. girêkor (n)"
  },
  {
    "x": "kori-I (n)",
    "y": " b. kuware (n)"
  },
  {
    "x": "kori-II (m)",
    "y": " b. kore (m)"
  },
  {
    "x": "korî (m)",
    "y": " körlük"
  },
  {
    "x": "korîya rengan (m)",
    "y": " renk körlüğü"
  },
  {
    "x": "korîye (m)",
    "y": " körlük"
  },
  {
    "x": "korja (m)",
    "y": " nazar boncuğu, nazarlık"
  },
  {
    "x": "korkanî",
    "y": " tahmini"
  },
  {
    "x": "korkuçe (n)",
    "y": " çıkmaz sokak"
  },
  {
    "x": "kormar (m)",
    "y": " b. koremare (m)"
  },
  {
    "x": "kormuşk (n)",
    "y": " koremişk (n)"
  },
  {
    "x": "korna (m)",
    "y": " klakson, korna"
  },
  {
    "x": "korneş (n)",
    "y": " b. kornîş (n)"
  },
  {
    "x": "kornîş (n)",
    "y": " korniş"
  },
  {
    "x": "korona (m)",
    "y": " korona"
  },
  {
    "x": "koroşîyayîş (n)",
    "y": " b. xowraşîyayîş (n)"
  },
  {
    "x": "korpişik (n)",
    "y": " b. kurbeşik (n)"
  },
  {
    "x": "korsan, -e",
    "y": " korsan"
  },
  {
    "x": "kors kerdene",
    "y": " örmek"
  },
  {
    "x": "korse (n)",
    "y": " korsa, korse"
  },
  {
    "x": "korsele (n)",
    "y": " b. kosele (n)"
  },
  {
    "x": "korseli (n)",
    "y": " b. kosele (n)"
  },
  {
    "x": "Korsîka (m)",
    "y": " Korsika"
  },
  {
    "x": "korskerdiş (n)",
    "y": " örme"
  },
  {
    "x": "kort-I (n)",
    "y": " çaput, paçavra"
  },
  {
    "x": "kort-II (n)",
    "y": " kort (tenis için)"
  },
  {
    "x": "kortê tenîsî (n)",
    "y": " tenis kortu"
  },
  {
    "x": "kortawix (m)",
    "y": " b. kortawixe (m)"
  },
  {
    "x": "kortawixe (m)",
    "y": " tavukkarası, gece körlüğü"
  },
  {
    "x": "korte (m)",
    "y": " çukur"
  },
  {
    "x": "korta çimî (m)",
    "y": " göz çukuru "
  },
  {
    "x": "korta milî (m)",
    "y": " ense"
  },
  {
    "x": "korte bîyene",
    "y": " çukurlaşmak"
  },
  {
    "x": "kortebîyayîş (n)",
    "y": " çukurlaşma"
  },
  {
    "x": "korteki (m)",
    "y": " b. kortike (m)"
  },
  {
    "x": "korti (m)",
    "y": " b. korte (m)"
  },
  {
    "x": "kortik (n)",
    "y": " b. kort (n)"
  },
  {
    "x": "kortike (m)",
    "y": " 1)küçük çukur\r\n~2)ense"
  },
  {
    "x": "kortiki (m)",
    "y": " b. kortike (m)"
  },
  {
    "x": "kortikin, -e",
    "y": " b. kortkin, -e "
  },
  {
    "x": "kortkin, -e",
    "y": " eski püskü giysili"
  },
  {
    "x": "korz (n)",
    "y": " asma kilit, köç"
  },
  {
    "x": "korzik (n)",
    "y": " pipo"
  },
  {
    "x": "kosadir (n)",
    "y": " köseği (bir ucu yanıp ateş haline gelmiş olan odun/ağaç parçası)"
  },
  {
    "x": "kose-I (n)",
    "y": " birdirbir"
  },
  {
    "x": "kose-II (n)",
    "y": " 1)köse\r\n~2)Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında oynanan geleneksel, teatral eğlence oyununda koca (erkek eş) rolündeki kişi"
  },
  {
    "x": "kose çarnayene",
    "y": " 1)Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında geleneksel, teatral “koseyî”/“kalo” oyunu oynamak\r\n~2)Noel Baba 2)Yılbaşı Dedesi"
  },
  {
    "x": "kose xemelnayene",
    "y": " b. kose xemilnayene"
  },
  {
    "x": "kose xemilnayene",
    "y": " 1)gayri ciddi iş yapmak (mecazi), gizli kapaklı iş yapmak (mecazi), hileli iş yapmak (mecazi), şaka yapmak (mecazi)\r\n~2)Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında geleneksel, teatral “koseyî”/“kalo” oyunu için “kose”/“kalo”/“kalik” denilen erkek eş rolündeki kişiyi süslemek, şaka yapmak (mecazi)"
  },
  {
    "x": "kose-III (n)",
    "y": " b. goşe-I (n)"
  },
  {
    "x": "kosebî (n)",
    "y": " köseği (bir ucu yanıp ateş haline gelmiş olan odun/ağaç parçası)"
  },
  {
    "x": "kosegelî (n)",
    "y": " Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında oynanan geleneksel, teatral bir eğlence oyunu"
  },
  {
    "x": "kosele (n)",
    "y": " kösele"
  },
  {
    "x": "kosevî (n)",
    "y": " b. kosebî (n)"
  },
  {
    "x": "koseyey (m)",
    "y": " b. koseyî (m)"
  },
  {
    "x": "koseyey kerdene",
    "y": " b. koseyî kerdene"
  },
  {
    "x": "koseyî (m)",
    "y": " Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında oynanan geleneksel, teatral bir eğlence oyunu"
  },
  {
    "x": "koseyî kerdene",
    "y": " 1)gayri ciddi iş yapmak (mecazi), şaka yapmak (mecazi)\r\n~2)Rumi takvime göre, 13 Ocak’a rastlayan yılbaşında geleneksel, teatral “koseyî”/“kalo” oyunu oynamak, şaka yapmak (mecazi)"
  },
  {
    "x": "koskar, -e",
    "y": " b. koşkar, -e"
  },
  {
    "x": "koskarênî (m)",
    "y": " b. koşkarênî (m)"
  },
  {
    "x": "koskarîye (m)",
    "y": " b. koşkarîye (m), koşkarî (m)"
  },
  {
    "x": "Kosta Rîka (m)",
    "y": " Kostarika"
  },
  {
    "x": "kostege (m)",
    "y": " cep saati zinciri"
  },
  {
    "x": "kosteke (m)",
    "y": " çöpçatan (başkasına kız ayarlayan kadın) "
  },
  {
    "x": "kostekey (m)",
    "y": " b. kostekîye (m)"
  },
  {
    "x": "kostekê (m)",
    "y": " b. kostekîye (m)"
  },
  {
    "x": "kosteki (m)",
    "y": " kosteke (m)"
  },
  {
    "x": "kostekîye (m)",
    "y": " çöpçatanlık"
  },
  {
    "x": "kostum (n)",
    "y": " kostüm, kostüm (sinema ve tiyatro için)"
  },
  {
    "x": "koşe (n)",
    "y": " b. goşe-I (n)"
  },
  {
    "x": "koşk (n)",
    "y": " b. koşke (m)"
  },
  {
    "x": "koşkar, -e-I",
    "y": " 1)ayakkabı yapımcısı (ayakkabı diken)\r\n~2)ayakkabıcı (ayakkabı yapan ya da satan), pabuççu, başmakçı\r\n~3)ayakkabı tamircisi, ayakkabı onarımcısı, yamacı (ayakkabı onaran)"
  },
  {
    "x": "koşkar-II (n)",
    "y": " uğurböceği"
  },
  {
    "x": "koşkarey (m)",
    "y": "b. koşkarîye (m), koşkarî (m)"
  },
  {
    "x": "koşkarê (m)",
    "y": " b. koşkarîye (m), koşkarî (m)"
  },
  {
    "x": "koşkarênî (m)",
    "y": " ayakkabıcılık, pabuççuluk, yamacılık (ayakkabı onarımcılığı)"
  },
  {
    "x": "koşkarî (m)",
    "y": " ayakkabıcılık, pabuççuluk, yamacılık (ayakkabı onarımcılığı)"
  },
  {
    "x": "koşkarîye (m)",
    "y": " ayakkabıcılık, pabuççuluk, yamacılık (ayakkabı onarımcılığı)"
  },
  {
    "x": "koşke (m)",
    "y": " köşk"
  },
  {
    "x": "koşki (m)",
    "y": " b. koşke (m)"
  },
  {
    "x": "kot-I (n)",
    "y": " kot"
  },
  {
    "x": "pantolê kotî (zh)",
    "y": " kot pantolon"
  },
  {
    "x": "kot-II (n)",
    "y": " 1)kot (mimarlık için) "
  },
  {
    "x": "kot-III",
    "y": " b. kotî"
  },
  {
    "x": "Kotan (n)",
    "y": " köpek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "kotane (m)",
    "y": " pulluk"
  },
  {
    "x": "kotat, -e",
    "y": " çolak, eli veya kolu sakat olan kişi"
  },
  {
    "x": "kotatey (m)",
    "y": " b. kotatî (m)"
  },
  {
    "x": "kotatê (m)",
    "y": " b. kotatî (m)"
  },
  {
    "x": "kotatî (m)",
    "y": " çolaklık"
  },
  {
    "x": "kotene",
    "y": " b. kewtene"
  },
  {
    "x": "o kono",
    "y": " b. o keweno"
  },
  {
    "x": "o kuno",
    "y": " b. o keweno"
  },
  {
    "x": "kotene derdê ...",
    "y": " b. kewtene derdê ..."
  },
  {
    "x": "kotene etrê ...",
    "y": " b. kewtene etira ..."
  },
  {
    "x": "koter (m)",
    "y": " b. kotere (m)"
  },
  {
    "x": "kotere (m)",
    "y": " üveyik"
  },
  {
    "x": "koteri (m)",
    "y": " b. kotere (m)"
  },
  {
    "x": "kotilme (n)",
    "y": " insan kümesi (insan grubu), küme"
  },
  {
    "x": "kotiş (n)",
    "y": " 1)b. kewtiş (n)\r\n~2)b. rakewtiş (n)"
  },
  {
    "x": "kotî",
    "y": " nere \r\n~Kitab kotî yo? (Kitap nerededir?)\r\n~\t\r\n~Ti kotî ra yî/ya? (Nerelisin?) "
  },
  {
    "x": "kovare (m)",
    "y": " dergi "
  },
  {
    "x": "kovboy (n)",
    "y": " b. kowboy (n)"
  },
  {
    "x": "kovik (n)",
    "y": " 1)huni\r\n~2)koni"
  },
  {
    "x": "kovikin -e",
    "y": " konik"
  },
  {
    "x": "kovî, -ye",
    "y": " 1)dağlı\r\n~2)yabani, yabanıl, vahşi"
  },
  {
    "x": "kow (n)",
    "y": " b. kef (n)"
  },
  {
    "x": "kowari (n)",
    "y": " b. kuware (n)"
  },
  {
    "x": "kowarî (n)",
    "y": " b. kuware (n)"
  },
  {
    "x": "kowboy (n)",
    "y": " kovboy"
  },
  {
    "x": "kowçiki (m)",
    "y": " b. koçike (m)"
  },
  {
    "x": "kowin",
    "y": " b. kefin, -e"
  },
  {
    "x": "kowndêz (n)",
    "y": " b. kondêz (n)"
  },
  {
    "x": "kowni (n)",
    "y": " b. kone-I (n)"
  },
  {
    "x": "kownikerdiş (n)",
    "y": " b. konekerdiş (n)"
  },
  {
    "x": "kowtene",
    "y": " b. kewtene"
  },
  {
    "x": "o kuweno",
    "y": " b. o keweno"
  },
  {
    "x": "o kweno",
    "y": " b. o keweno"
  },
  {
    "x": "kowtene derdî ...",
    "y": " b. kewtene derdê ..."
  },
  {
    "x": "kowtiş (n)",
    "y": " b. kewtiş (n)"
  },
  {
    "x": "kox (n)",
    "y": " kümes"
  },
  {
    "x": "koxban (n)",
    "y": " gecekondu"
  },
  {
    "x": "koxe (m)",
    "y": " kulübe"
  },
  {
    "x": "koxik (n)",
    "y": " küçük kümes"
  },
  {
    "x": "koy",
    "y": " b. ku-I\r\n~Hewe şîyo be koy? (O nereye gitmiş.)"
  },
  {
    "x": "koye (n)",
    "y": " küme, yığın"
  },
  {
    "x": "koye kerdene",
    "y": " yığmak, loda etmek"
  },
  {
    "x": "koyî, -ye",
    "y": " 1)dağlı\r\n~2)yabani, yabanıl, vahşi"
  },
  {
    "x": "koylî (n)",
    "y": " b. kolî (n)"
  },
  {
    "x": "koyt",
    "y": " b. kotî"
  },
  {
    "x": "koytiş n)",
    "y": " b. kewtiş (n)"
  },
  {
    "x": "koytî",
    "y": " b. kotî"
  },
  {
    "x": "koz-I (n)",
    "y": " 1)kümes\r\n~2)yeni doğan oğlak, kuzu veya buzağının içine konduğu özel bölme"
  },
  {
    "x": "koz-II (n)",
    "y": " cemre\r\n~kozê awe (n): su cemresi (Rumi takvime göre 14-15 Şubat gecesi başlar.)\r\n~kozê erdî (n): toprak cemresi (Rumi takvime göre 22-23 Şubat gecesi başlar.)\r\n~ hava cemresi (Rumi takvime göre 6-7 Şubat gecesi başlar.)"
  },
  {
    "x": "kozê aw (n)",
    "y": " b. kozê awe (n)"
  },
  {
    "x": "kozê awe (n)",
    "y": " su cemresi (Rumi takvime göre 14-15 Şubat gecesi başlar.)"
  },
  {
    "x": "kozê erdî (n)",
    "y": " toprak cemresi (Rumi takvime göre 22-23 Şubat gecesi başlar.)"
  },
  {
    "x": "kozê hewayî (n)",
    "y": " hava cemresi (Rumi takvime göre 6-7 Şubat gecesi başlar.)"
  },
  {
    "x": "koz-III (n)",
    "y": " b. kozire-I (n)"
  },
  {
    "x": "kozik (n)",
    "y": " 1)evcik (küçücük ev)\r\n~2)kümes\r\n~kozikê kergan (n): kümes\r\n~3)yeni doğan oğlak, kuzu veya buzağının içine konduğu özel bölme"
  },
  {
    "x": "kozikê kergan (n)",
    "y": " kümes"
  },
  {
    "x": "kozikî (zh)",
    "y": " evcilik"
  },
  {
    "x": "kozikî kay kerdene",
    "y": " evcilik oynamak"
  },
  {
    "x": "kozire-I (n)",
    "y": " kor"
  },
  {
    "x": "kozire-II (n)",
    "y": " harmanda arpa ve buğdayın kalburlanmasından sonra artakalan başaklar "
  },
  {
    "x": "kozmos (n)",
    "y": " âlem, cihan, kâinat, evren, kozmos"
  },
  {
    "x": "kramp (n)",
    "y": " kramp, kasınç"
  },
  {
    "x": "krampê madeyî (n)",
    "y": " mide krampı "
  },
  {
    "x": "krampê madî (n)",
    "y": " b. krampê madeyî (n)"
  },
  {
    "x": "kravate (m)",
    "y": " boyunbağı, kravat"
  },
  {
    "x": "krem (n)",
    "y": " krem"
  },
  {
    "x": "kremê çiman (n) ",
    "y": " göz kremi"
  },
  {
    "x": "kremê tiraşî (n)",
    "y": " tıraş kremi"
  },
  {
    "x": "krema (m)",
    "y": " krema"
  },
  {
    "x": "krep (n)",
    "y": " krep"
  },
  {
    "x": "kreş (n)",
    "y": " anaokulu, ana mektebi, çocuk yuvası, kreş"
  },
  {
    "x": "krîko (n)",
    "y": " kriko"
  },
  {
    "x": "krîmînel, -e",
    "y": " kriminel"
  },
  {
    "x": "krîmînolojî (n)",
    "y": " kriminoloji"
  },
  {
    "x": "krîstal, -e",
    "y": " billur, kristal"
  },
  {
    "x": "krîter (n)",
    "y": " 1)ölçüt, kıstas, kriter\r\n~2)norm"
  },
  {
    "x": "krîtîk (n)",
    "y": " eleştiri, tenkit, kritik\r\n~krîtîkê sînema (n)"
  },
  {
    "x": "krîtîk kerdene",
    "y": " eleştirmek, tenkit etmek, kritik etmek: sinema eleştirisi"
  },
  {
    "x": "krîtîkê sînema (n)",
    "y": " sinema eleştirisi"
  },
  {
    "x": "krîtîk, -e",
    "y": " kritik (nazik)"
  },
  {
    "x": "krîtîkkerdiş (n)",
    "y": " eleştirme, tenkit etme"
  },
  {
    "x": "krîz (n)",
    "y": " bunalım, buhran, kriz"
  },
  {
    "x": "krîzo psîkolojîk (n)",
    "y": " ruhsal kriz "
  },
  {
    "x": "krîzo ruhî (n)",
    "y": " ruhsal kriz, depresyon"
  },
  {
    "x": "krokî (m)",
    "y": " kroki"
  },
  {
    "x": "krokîya biroper",
    "y": " röperli kroki"
  },
  {
    "x": "krokodîl, -e",
    "y": " timsah"
  },
  {
    "x": "kronometre (n)",
    "y": " süreölçer, kronometre"
  },
  {
    "x": "ku-I",
    "y": " nere\r\n~Kutik ku yo? (Köpek nerededir?)"
  },
  {
    "x": "ku-II",
    "y": " b. ke-I"
  },
  {
    "x": "ku-III",
    "y": " b. eke-I"
  },
  {
    "x": "ku-IV (m)",
    "y": " b. kuy (m)"
  },
  {
    "x": "kuafor, -e",
    "y": " kuaför"
  },
  {
    "x": "kuaforey (m)",
    "y": " b. kuaforîye (m)"
  },
  {
    "x": "kuaforê (m)",
    "y": " b. kuaforîye (m)"
  },
  {
    "x": "kuaforîye (m)",
    "y": " kuaförlük"
  },
  {
    "x": "kual, -i",
    "y": " b. kol, -e"
  },
  {
    "x": "Kuala Lumpur",
    "y": " Kuala Lumpur"
  },
  {
    "x": "kuar",
    "y": " b. kor, -e"
  },
  {
    "x": "kuarbiliz (n)",
    "y": " b. korbilîyes (n)"
  },
  {
    "x": "kuare (n)",
    "y": " b. kuware (n)"
  },
  {
    "x": "kuarfehm",
    "y": " b. korfehm, -e"
  },
  {
    "x": "kuarfehmê (m)",
    "y": " b. korfehmî (m), korfehmîye (m)"
  },
  {
    "x": "kuarfûm",
    "y": " b. korfehm, -e"
  },
  {
    "x": "kuarfûmê (m)",
    "y": " b. korfehmî (m), korfehmîye (m)"
  },
  {
    "x": "kuari (n)",
    "y": " b. kuware (n)"
  },
  {
    "x": "kuarî (n)",
    "y": " b. kuware (n)"
  },
  {
    "x": "kuarmişk (n)",
    "y": " b. kurbeşik (n)"
  },
  {
    "x": "kuarpişik (n)",
    "y": " b. kurbeşik (n)"
  },
  {
    "x": "kuarwicax",
    "y": " b. ocaxkor, -e"
  },
  {
    "x": "kuax (n)",
    "y": " b. kox (n)"
  },
  {
    "x": "Kuba (m)",
    "y": " Küba"
  },
  {
    "x": "kubaj (n)",
    "y": " kübaj"
  },
  {
    "x": "kubar, -e",
    "y": " b. kîbar, -e"
  },
  {
    "x": "kubarî (m)",
    "y": " b. kîbarî (m)"
  },
  {
    "x": "kubarîye (m)",
    "y": " b. kîbarîye (m), kîbarî (m)"
  },
  {
    "x": "kubîk, -e",
    "y": " kübik (mat)"
  },
  {
    "x": "kuc (n)",
    "y": " b. kunc (n)"
  },
  {
    "x": "kuca",
    "y": " b. kamca"
  },
  {
    "x": "kuça (m)",
    "y": " b. kuçe-I (n)"
  },
  {
    "x": "kuçanî  (n)",
    "y": " b. kuçelan (n)"
  },
  {
    "x": "kuçe-I (n)",
    "y": " sokak"
  },
  {
    "x": "kuçeyo teng (n)",
    "y": " dar sokak"
  },
  {
    "x": "kuçeyo tengalek (n)",
    "y": " daracık sokak"
  },
  {
    "x": "kuçe-II (m)",
    "y": " taş"
  },
  {
    "x": "kuçeçiqmez (n)",
    "y": " çıkmaz sokak"
  },
  {
    "x": "kuçegezûn, -i",
    "y": " b. kuçgezan, -e"
  },
  {
    "x": "kuçekor (n)",
    "y": " çıkmaz sokak"
  },
  {
    "x": "kuçelan (n)",
    "y": " ocak (ateş yakılan veya gazla yakılan)"
  },
  {
    "x": "kuçeran (n)",
    "y": " b. kuçelan (n)"
  },
  {
    "x": "kuçgezan, -e",
    "y": " kaldırım mühendisi"
  },
  {
    "x": "kuçgezon, -i",
    "y": " b. kuçgezan, -e"
  },
  {
    "x": "kuçgezûn, -i",
    "y": " b. kuçgezan, -e"
  },
  {
    "x": "kuçgêr, -e",
    "y": " kaldırım mühendisi"
  },
  {
    "x": "kuçi-I (n)",
    "y": " b. kuçe-I (n)"
  },
  {
    "x": "kuçi-II (m)",
    "y": " b. kuçe-II (m)"
  },
  {
    "x": "kuçik (m)",
    "y": " b. kuçike (m)"
  },
  {
    "x": "kuçike (m)",
    "y": " ocak (ateş yakılan veya gazla yakılan)\r\n~Kuçike de adir weke. (Ocakta ateş yak.)"
  },
  {
    "x": "kuçlan (n)",
    "y": " b. kuçelan (n)"
  },
  {
    "x": "kuçlik (m)",
    "y": " b. kuçike (m)"
  },
  {
    "x": "kuçlon (n)",
    "y": " b. kuçelan (n)"
  },
  {
    "x": "kuçlûn (n)",
    "y": " b. kuçelan (n)"
  },
  {
    "x": "kuço",
    "y": " 1)(çocuk dilinde) köpek\r\n~2)(köpekleri çağırırken söylenen sözcük) kuçu kuçu"
  },
  {
    "x": "kuçran (n)",
    "y": " b. kuçelan (n)"
  },
  {
    "x": "kuçu!",
    "y": " b. kuço!"
  },
  {
    "x": "kuço kuço!",
    "y": " (köpekleri çağırırken söylenen sözcük) kuçu kuçu"
  },
  {
    "x": "kuçu kuçu!",
    "y": " b. kuço kuço!"
  },
  {
    "x": "kud, -e-I",
    "y": " eli veya ayağı sakat olan"
  },
  {
    "x": "kud-II",
    "y": " b. kudik (n)"
  },
  {
    "x": "kudik (n)",
    "y": " 1)enik, encik, encek, köpek yavrusu\r\n~2)ayı ve kurt yavrusu"
  },
  {
    "x": "kudikê kutikî (n)",
    "y": " enik, encik, encek, köpek yavrusu"
  },
  {
    "x": "kudî, -ye",
    "y": " enik, encik, encek, köpek yavrusu"
  },
  {
    "x": "kue (n)",
    "y": " b. ko-I (n)"
  },
  {
    "x": "kue ri şîyayene",
    "y": " b. xow ra şîyene"
  },
  {
    "x": "kue ro şîyayene",
    "y": " b. xow ra şîyene"
  },
  {
    "x": "kueç (n)",
    "y": " b. koç (n)"
  },
  {
    "x": "kueç kerdene",
    "y": " b. koç kerdene"
  },
  {
    "x": "kueçer",
    "y": " b. koçer, -e"
  },
  {
    "x": "kueçerê (m)",
    "y": " b. koçerîye (m)"
  },
  {
    "x": "kueçerî",
    "y": " b. koçerî, -ye"
  },
  {
    "x": "kueçik (m)",
    "y": " b. koçike (m)"
  },
  {
    "x": "kueçika darîn (m)",
    "y": " b. koçika darêne (m)"
  },
  {
    "x": "kueçikê çay (m)",
    "y": " b. koçika çay (m)"
  },
  {
    "x": "kueçikter (n)",
    "y": " b. koçikter (n)"
  },
  {
    "x": "kueçkerdiş (n)",
    "y": " b. koçkerdiş (n)"
  },
  {
    "x": "kued (n)",
    "y": " b. kod-I (n)"
  },
  {
    "x": "kuedik (n)",
    "y": " kodik (n)"
  },
  {
    "x": "kuefî (m)",
    "y": " b. kofî (m)"
  },
  {
    "x": "kuek (n)",
    "y": " b. kok (n)"
  },
  {
    "x": "kueka (m)",
    "y": " b. koka-I (m)"
  },
  {
    "x": "kuekim, -i",
    "y": " b. kokim, -e"
  },
  {
    "x": "kuekimê (m)",
    "y": " b. kokimî (m), kokimîye (m)"
  },
  {
    "x": "kuekimî (m)",
    "y": " b. kokimî (m), kokimîye (m) "
  },
  {
    "x": "Kuel (m)",
    "y": " b. Kole (m)"
  },
  {
    "x": "kuel, -i",
    "y": " b. kol, -e"
  },
  {
    "x": "kuela (m)",
    "y": " b. kola (m), b. koleye (m)"
  },
  {
    "x": "kuele (n)",
    "y": " b. kole (n)"
  },
  {
    "x": "kueleyî (m)",
    "y": " b. koletî (m)"
  },
  {
    "x": "kueli-I (n)",
    "y": " b. kolî-II (n)"
  },
  {
    "x": "kuelî mazîyer (n)",
    "y": " b. kolîyê mazêre (n)"
  },
  {
    "x": "kueli-II (n)",
    "y": " b. kole (n)"
  },
  {
    "x": "kuelik (n)",
    "y": " b. kolik (n)"
  },
  {
    "x": "kuelî-I (m)",
    "y": " b. kolî-I (m)"
  },
  {
    "x": "kuelî-II (n)",
    "y": " b. kolî-II (n)"
  },
  {
    "x": "kuem bîyayîne",
    "y": " b. kom bîyene"
  },
  {
    "x": "kuem kerdene",
    "y": " b. pêresnayene"
  },
  {
    "x": "kuembîyayîş (n)",
    "y": " b. kombîyayîş (n)"
  },
  {
    "x": "kuemir (n)",
    "y": " b. komir (n)"
  },
  {
    "x": "kuemkerdiş (n)",
    "y": " b. komkerdiş (n)"
  },
  {
    "x": "kuene (n)",
    "y": " b. kone-I (n)"
  },
  {
    "x": "kueni (n)",
    "y": " b. kone-I (n)"
  },
  {
    "x": "kuenikerdiş (n)",
    "y": " b. konekerdiş (n)"
  },
  {
    "x": "kuep (n)",
    "y": " b. kop (n)"
  },
  {
    "x": "kuer",
    "y": " b. kor, -e"
  },
  {
    "x": "kuerca (m)",
    "y": " b. korja (m)"
  },
  {
    "x": "kuerişîyayîş (n)",
    "y": " b. xowraşîyayîş (n)"
  },
  {
    "x": "kuerja (m)",
    "y": " b. korja (m)"
  },
  {
    "x": "kueroşîyayîş (n)",
    "y": " b. xowraşîyayîş (n)"
  },
  {
    "x": "kuerseli (n)",
    "y": " b. kosele (n)"
  },
  {
    "x": "kueşe (n)",
    "y": " b. goşe-I (n)"
  },
  {
    "x": "kueşi (n)",
    "y": " b. goşe-I (n)"
  },
  {
    "x": "kueşk (m)",
    "y": " b. koşke (m)"
  },
  {
    "x": "kueşkar, -e",
    "y": " b. koşkar, -e"
  },
  {
    "x": "kueter (m)",
    "y": " b. kotere (m)"
  },
  {
    "x": "kuevik (n)",
    "y": " b. kovik (n)"
  },
  {
    "x": "kueyî",
    "y": " b. koyî, -ye, kovî, -ye"
  },
  {
    "x": "kuez (n)",
    "y": " b. koz (n)"
  },
  {
    "x": "kuezik (n)",
    "y": " b. kozik (n)"
  },
  {
    "x": "kuezle (n)",
    "y": " b. kozire (n)"
  },
  {
    "x": "kuezme (n)",
    "y": " b. kozire (n)"
  },
  {
    "x": "kuêrîyek (n)",
    "y": " korek (n)"
  },
  {
    "x": "kuf (m)",
    "y": " b. kufe (m)"
  },
  {
    "x": "kufa (m)",
    "y": " b. qufa (m)"
  },
  {
    "x": "kufayene-I",
    "y": " homurdanmak"
  },
  {
    "x": "kufayene-II",
    "y": " “pof” diye ses çıkarmak "
  },
  {
    "x": "kufayene-III",
    "y": " oflayıp puflamak"
  },
  {
    "x": "kufayîş (n)",
    "y": " yılan, kaplumbağa gibi hayvanların ses çıkarması"
  },
  {
    "x": "kufe (m)",
    "y": " küf"
  },
  {
    "x": "kufik (n)",
    "y": " b. kufike (m)"
  },
  {
    "x": "kufik guretene",
    "y": " b. kufike girewtene"
  },
  {
    "x": "kufike (m)",
    "y": " küf"
  },
  {
    "x": "kufike bestene",
    "y": " küflenmek"
  },
  {
    "x": "kufike girewtene",
    "y": " küflenmek"
  },
  {
    "x": "kufike perayene ...",
    "y": " küflenmek"
  },
  {
    "x": "kufike pernayene ...",
    "y": " küflendirmek"
  },
  {
    "x": "kufike perrayene ...",
    "y": " b. kufike perayene ..."
  },
  {
    "x": "kufike perrnayene ...",
    "y": " b. kufike pernayene ..."
  },
  {
    "x": "kufike piro ginayene",
    "y": " küflenmek"
  },
  {
    "x": "kufikebestiş (n)",
    "y": " küflenme"
  },
  {
    "x": "kufikegirewtiş (n)",
    "y": " küflenme"
  },
  {
    "x": "kufikeperayîş ...",
    "y": " küflenme"
  },
  {
    "x": "kufikepernayîş ...",
    "y": " küflendirme"
  },
  {
    "x": "kufikeperrayîş ...",
    "y": " b. kufikeperayîş ..."
  },
  {
    "x": "kufikeperrnayîş ...",
    "y": " b. kufikepernayîş ..."
  },
  {
    "x": "kufikepiroginayîş (n)",
    "y": " küflenme"
  },
  {
    "x": "kufikguretiş (n)",
    "y": " b. kufikegirewtiş (n)"
  },
  {
    "x": "kufiki (m)",
    "y": " b. kufike (m)"
  },
  {
    "x": "kufiki gurotene",
    "y": " b. kufike girewtene"
  },
  {
    "x": "kufikigurotiş (n)",
    "y": " b. kufikegirewtiş (n)"
  },
  {
    "x": "kufikin, -e",
    "y": " küflü"
  },
  {
    "x": "kufikin kerdene",
    "y": " küflendirmek"
  },
  {
    "x": "kufikinkerdiş (n)",
    "y": " küflendirme"
  },
  {
    "x": "kufikîyayene",
    "y": " küflenmek"
  },
  {
    "x": "kufikîyayîş (n)",
    "y": " küflenme"
  },
  {
    "x": "kufiknayene",
    "y": " küflendirmek"
  },
  {
    "x": "kufiknayîş (n)",
    "y": " küflendirme"
  },
  {
    "x": "kufikyayene",
    "y": " b. kufikîyayene"
  },
  {
    "x": "kufikyayîş (n)",
    "y": " b. kufikîyayîş (n)"
  },
  {
    "x": "kufir (n)",
    "y": " 1)dinsizlik\r\n~2)kâfirlik\r\n~3)b. kifir (n)"
  },
  {
    "x": "kufir kerdene",
    "y": " b. kifir kerdene"
  },
  {
    "x": "kufirbaz, -e",
    "y": " b. kifirbaz, -e"
  },
  {
    "x": "kufirkerdiş (n)",
    "y": " b. kifirkerdiş (n)"
  },
  {
    "x": "kufkarik (m)",
    "y": " b. kufkarike (m)"
  },
  {
    "x": "kufkarike (m)",
    "y": " mantar (bitkisi)"
  },
  {
    "x": "kufkariki (m)",
    "y": " b. kufkarike (m)"
  },
  {
    "x": "kufkin, -e",
    "y": " b. kufikin, -e"
  },
  {
    "x": "kufkin kerdene",
    "y": " b. kufikin kerdene"
  },
  {
    "x": "kufkinkerdiş (n)",
    "y": " b. kufikinkerdiş (n)"
  },
  {
    "x": "kufr (n)",
    "y": " 1)b. kufir (n)\r\n~2)b. kifir (n)"
  },
  {
    "x": "kufr kerdene",
    "y": " b. kifir kerdene"
  },
  {
    "x": "kufrbaz, -e",
    "y": " b. kifirbaz, -e"
  },
  {
    "x": "kufrî kerdene",
    "y": " b. kifir kerdene"
  },
  {
    "x": "kufrîkerdiş (n)",
    "y": " b. kifirkerdiş (n)"
  },
  {
    "x": "kufrkerdiş (n)",
    "y": " b. kifirkerdiş (n)"
  },
  {
    "x": "kuft (n)",
    "y": " b. kift (n)"
  },
  {
    "x": "kufta (m)",
    "y": " köfte (ince yarmadan veya kıymadan yapılan)"
  },
  {
    "x": "kufta dekerda (m)",
    "y": " b. kuftaya dekerda (m)"
  },
  {
    "x": "kufta dekerdîye (m)",
    "y": " b. kuftaya dekerdîye (m)"
  },
  {
    "x": "kufta mastî (m)",
    "y": " b. kuftaya mastî (m)"
  },
  {
    "x": "kufta qatixî (m)",
    "y": " b. kuftaya qatixî (m)"
  },
  {
    "x": "kufta qatixini (m)",
    "y": " b. kuftaya qatixî (m)"
  },
  {
    "x": "kufta qulikeri (m)",
    "y": " b. kuftaya qulikere (m)"
  },
  {
    "x": "kufta zerî (m)",
    "y": " b. kuftaya zerî (m)"
  },
  {
    "x": "kufta zurayini (m)",
    "y": " b. kuftaya zurayine (m)"
  },
  {
    "x": "kuftaya dekerda (m)",
    "y": " içli köfte"
  },
  {
    "x": "kuftaya dekerdîye (m)",
    "y": " içli köfte"
  },
  {
    "x": "kuftaya kartolan (m)",
    "y": " patates köfte"
  },
  {
    "x": "kuftaya mastî (m)",
    "y": " yoğurtlu köfte (ince yarma, yoğurt ve yağdan yapılan)"
  },
  {
    "x": "kuftaya qatixî (m)",
    "y": " yoğurtlu köfte (ince yarma, yoğurt ve yağdan yapılan)"
  },
  {
    "x": "kuftaya qulikere (m)",
    "y": " delikli köfte (ince yarmadan yapılan)"
  },
  {
    "x": "kuftaya yalançî (m)",
    "y": " delikli köfte (ince yarmadan yapılan)"
  },
  {
    "x": "kuftaya zerî (m)",
    "y": " içli köfte"
  },
  {
    "x": "kuftaya zurayine (m)",
    "y": " delikli köfte (ince yarmadan yapılan)"
  },
  {
    "x": "kuftakar, -e",
    "y": " köfteci"
  },
  {
    "x": "kuftaroş, -e",
    "y": " köfteci"
  },
  {
    "x": "kufte (m)",
    "y": " b. kufta (m))"
  },
  {
    "x": "kufteya yalancî (m)",
    "y": "b. kuftaya yalançî (m)"
  },
  {
    "x": "kuftê kartuelûn (m)",
    "y": "b. kuftaya kartolan (m)"
  },
  {
    "x": "kuftik (n)",
    "y": " b. kuftike (m)"
  },
  {
    "x": "kuftike (m)",
    "y": " köfte (ince yarmadan veya kıymadan yapılan)"
  },
  {
    "x": "kufur (n)",
    "y": " b. kifir (n)"
  },
  {
    "x": "kufur kerdene",
    "y": " b. kifir kerdene"
  },
  {
    "x": "kufurkerdiş (n)",
    "y": " b. kifirkerdiş (n)"
  },
  {
    "x": "kuhe (n)",
    "y": " b. kewe, -ye-I"
  },
  {
    "x": "kuhn",
    "y": " b. kehen, -e; kan, -e"
  },
  {
    "x": "kuhnî (m)",
    "y": " b. kehenî (m)"
  },
  {
    "x": "kuho-I (n)",
    "y": " b. ko-I (n)"
  },
  {
    "x": "kuho-II (n)",
    "y": " b. kewe, -ye-I"
  },
  {
    "x": "kuhoy (m)",
    "y": " b. kewîye (m)"
  },
  {
    "x": "kuhu (n)",
    "y": " b. kewe, -ye-I"
  },
  {
    "x": "kuhû (n)",
    "y": " b. kewe, -ye-I"
  },
  {
    "x": "kuhûn",
    "y": " b. kehen, -e"
  },
  {
    "x": "kuhûnê (m)",
    "y": " b. kehenî (m)"
  },
  {
    "x": "kuhûy (m)",
    "y": " b. kewîye (m)"
  },
  {
    "x": "kuiç (m)",
    "y": " b. kuçe-II (m)"
  },
  {
    "x": "kuiçe (n)",
    "y": " b. kuçe-I (n)"
  },
  {
    "x": "kuiçlûn (n)",
    "y": " b. kuçelan (n)"
  },
  {
    "x": "kuifir (n)",
    "y": " b. kifir (n)"
  },
  {
    "x": "kuifir kerdene",
    "y": " b. kifir kerdene"
  },
  {
    "x": "kuifirbaz, -e",
    "y": " b. kifirbaz, -e"
  },
  {
    "x": "kuifirkerdiş (n)",
    "y": " b. kifirkerdiş (n)"
  },
  {
    "x": "kuiling",
    "y": " b. kuling (n)"
  },
  {
    "x": "kuinc (n)",
    "y": " b. kunc (n)"
  },
  {
    "x": "kuirn (m)",
    "y": " b. kurne (m)"
  },
  {
    "x": "kuirtûn (m)",
    "y": " kurtane (m) "
  },
  {
    "x": "kuite (n)",
    "y": " b. qit (n)"
  },
  {
    "x": "kuiz",
    "y": " b. kuz, -e"
  },
  {
    "x": "kuiz bîyayene",
    "y": " b. kuz bîyene"
  },
  {
    "x": "kuizey (m)",
    "y": " b. kuzîye (m)"
  },
  {
    "x": "Kuîto (n)",
    "y": " Quito"
  },
  {
    "x": "kuj (n)",
    "y": " b. kunc (n)"
  },
  {
    "x": "kuja",
    "y": " b. kamca"
  },
  {
    "x": "kuk, -e",
    "y": " kekeme, keke, kekeç"
  },
  {
    "x": "kuka (m)",
    "y": " b. koka-I (m)"
  },
  {
    "x": "kukirt (n)",
    "y": " kükürt"
  },
  {
    "x": "kukîye (m)",
    "y": " kekemelik, kekelik"
  },
  {
    "x": "kuklî (n)",
    "y": " b. kukulî (n)"
  },
  {
    "x": "kukulî (n)",
    "y": " saklambaç (oyunu)"
  },
  {
    "x": "kukulîku (n)",
    "y": " saklambaç (oyunu)"
  },
  {
    "x": "kukurd (n)",
    "y": " b. kukirt (n)"
  },
  {
    "x": "kul-I (n)",
    "y": " gam, dert"
  },
  {
    "x": "kul kerdene",
    "y": " yürek paralamak"
  },
  {
    "x": "kul-II (m)",
    "y": " b. kule-II (m)"
  },
  {
    "x": "kulav (n)",
    "y": " keçe"
  },
  {
    "x": "kulawi (m)",
    "y": " b. kilawe-I (m)"
  },
  {
    "x": "kulbe (n)",
    "y": " çapa"
  },
  {
    "x": "kulbe kerdene",
    "y": " çapalamak; tarla, bostan vb. yerlerdeki yabani otları çapayla temizlemek"
  },
  {
    "x": "kulbekerdiş (n)",
    "y": " çapalama"
  },
  {
    "x": "kulbi (n)",
    "y": " b. kulbe (n)"
  },
  {
    "x": "kulbi kerdene",
    "y": " b. kulbe kerdene"
  },
  {
    "x": "kule-I (n)",
    "y": " kalça"
  },
  {
    "x": "kule-II (m)",
    "y": " 1)yara, çıban, apse\r\n~2)kul-I (n)"
  },
  {
    "x": "kuleng (n)",
    "y": " b. kulenge-I (m)"
  },
  {
    "x": "kulenge-I (m)",
    "y": " gerdel, tahta kova"
  },
  {
    "x": "kulenge-II (m)",
    "y": " b. kulinge (m)"
  },
  {
    "x": "kulevete (n)",
    "y": " kalçası çıkmış olan"
  },
  {
    "x": "kulevetî, -ye",
    "y": " kalçası çıkmış olan"
  },
  {
    "x": "kulêrê dare (n)",
    "y": " ağaçta kurtçukların bulunduğu yuva"
  },
  {
    "x": "kulhayene",
    "y": " öksürmek"
  },
  {
    "x": "kulhayîş (n)",
    "y": " öksürme"
  },
  {
    "x": "kulhe (n)",
    "y": " öksürük"
  },
  {
    "x": "kulhi (n)",
    "y": " b. kulhe (n)"
  },
  {
    "x": "kuli-I (n)",
    "y": " b. kule-I (n)"
  },
  {
    "x": "kuli-II (m)",
    "y": " b. kule-II (m)"
  },
  {
    "x": "kuliç (m)",
    "y": " b. kilînca (m)"
  },
  {
    "x": "kulik-I (n)",
    "y": " çöplük"
  },
  {
    "x": "kulik-II (n)",
    "y": " harman savrulurken biriken çok ince saman"
  },
  {
    "x": "kulike (m)",
    "y": " 1)külah, başlık, takke\r\n~2)ibik\r\n~3)nohut kabuğu (dış kabuğu)"
  },
  {
    "x": "kuliki (m)",
    "y": " b. kulike (m)"
  },
  {
    "x": "kulil (n)",
    "y": " omuzun boyuna yakın kısmı"
  },
  {
    "x": "kulin, -e",
    "y": " yaralı"
  },
  {
    "x": "kulinçi (m)",
    "y": " b. kilînca (m)"
  },
  {
    "x": "kulinde (m)",
    "y": " mutfak"
  },
  {
    "x": "kuling-I (n)",
    "y": " bir tarafı sivri bir tarafı yassı olan kesici ve kazıcı bir alet"
  },
  {
    "x": "kuling-II (n)",
    "y": " b. kolik (n)"
  },
  {
    "x": "kulinge (m)",
    "y": " sivrisinek"
  },
  {
    "x": "kulî-I (n)",
    "y": " çekirge"
  },
  {
    "x": "kulî-II",
    "y": " hepsi, tüm, tümü"
  },
  {
    "x": "kulî pîya",
    "y": " hep birlikte, tümü birlikte"
  },
  {
    "x": "kulîç (m)",
    "y": " b. kilînca (m)"
  },
  {
    "x": "kulîne-I (m)",
    "y": " yüklük, yük"
  },
  {
    "x": "kulîne-II",
    "y": " hepsi, tümü; tümünü, hepsini"
  },
  {
    "x": "kulîng (n)",
    "y": " b. kuling (n)"
  },
  {
    "x": "kulk (n)",
    "y": " b. kilk (n)"
  },
  {
    "x": "kulkerdiş (n)",
    "y": " yürek paralama "
  },
  {
    "x": "kulkerdox",
    "y": " canhıraş"
  },
  {
    "x": "kullî",
    "y": " b. kulî-II"
  },
  {
    "x": "kulm (m)",
    "y": " b. kulme (m)"
  },
  {
    "x": "kulme (m)",
    "y": " 1)yumruk\r\n~2)avuç"
  },
  {
    "x": "kulmî eştene",
    "y": " yumruk atmak, yumruk indirmek"
  },
  {
    "x": "kulmî kerdene",
    "y": " yumruklamak"
  },
  {
    "x": "kulmî piro dayene",
    "y": " yumruk atmak, yumruk indirmek"
  },
  {
    "x": "kulmî piro ginayene",
    "y": " yumruklanmak"
  },
  {
    "x": "kulmî werdene",
    "y": " yumruklanmak"
  },
  {
    "x": "kulmetîn (n)",
    "y": " kazgıç, ucu sivriltilip daha çok yeraltından bir şey çıkarmakta kullanılan çubuk"
  },
  {
    "x": "kulmik (m)",
    "y": " b. kulme (m)"
  },
  {
    "x": "kulmike (m)",
    "y": " yumruk"
  },
  {
    "x": "kulmikî eştene",
    "y": " yumruk atmak, yumruk indirmek"
  },
  {
    "x": "kulmikî kerdene",
    "y": " yumruklamak"
  },
  {
    "x": "kulmikî piro dayene",
    "y": " yumruk atmak, yumruk indirmek"
  },
  {
    "x": "kulmikî piro ginayene",
    "y": " yumruklanmak"
  },
  {
    "x": "kulmikî werdene",
    "y": " yumruklanmak"
  },
  {
    "x": "kulmiki (m)",
    "y": " b. kulme (m)"
  },
  {
    "x": "kulmikîeştiş (n)",
    "y": " yumruk atma"
  },
  {
    "x": "kulmikîkerdiş (n)",
    "y": " yumruklama"
  },
  {
    "x": "kulmikîpirodayîş (n)",
    "y": " yumruk atma"
  },
  {
    "x": "kulmikîpiroginayîş (n)",
    "y": " yumruklanma"
  },
  {
    "x": "kulmikîwerdiş (n)",
    "y": " yumruklanma"
  },
  {
    "x": "kulmîeştiş (n)",
    "y": " yumruk atma"
  },
  {
    "x": "kulmîkerdiş (n)",
    "y": " yumruklama"
  },
  {
    "x": "kulmîpirodayîş (n)",
    "y": " yumruk atma"
  },
  {
    "x": "kulmîpiroginayîş (n)",
    "y": " yumruklanma"
  },
  {
    "x": "kulmîwerdiş (n)",
    "y": " yumruklanma"
  },
  {
    "x": "kulpe (n)",
    "y": " b. kulbe (n)"
  },
  {
    "x": "kulpe kerdene",
    "y": " b. kulbe kerdene"
  },
  {
    "x": "kulpişik (n)",
    "y": " b. kurbeşik (n)"
  },
  {
    "x": "kulse (n)",
    "y": " b. kursî (n)"
  },
  {
    "x": "kulsi (n)",
    "y": " b. kursî (n)"
  },
  {
    "x": "kulsî (n)",
    "y": " b. kursî (n)"
  },
  {
    "x": "kult (n)",
    "y": " kült"
  },
  {
    "x": "kultir (n)",
    "y": " b. kultur (n)"
  },
  {
    "x": "kultur (n)",
    "y": " kültür"
  },
  {
    "x": "kulturê dezgehî (n)",
    "y": " kurum kültürü"
  },
  {
    "x": "kulturê muesseseyî (n)",
    "y": " kurum kültürü"
  },
  {
    "x": "kulturî, -ye",
    "y": " kültürel"
  },
  {
    "x": "kulu",
    "y": " b. kulî-II"
  },
  {
    "x": "kulve (n)",
    "y": " b. kulbe (n)"
  },
  {
    "x": "kulve kerdene",
    "y": " b. kulbe kerdene"
  },
  {
    "x": "kulvekerdiş (n)",
    "y": " b. kulbekerdiş (n)"
  },
  {
    "x": "kulvik (n)",
    "y": " b. kulbe (n)"
  },
  {
    "x": "kulxan (n)",
    "y": " külhan, çöplük, küllük"
  },
  {
    "x": "kulxayene",
    "y": " balgamlı öksürmek"
  },
  {
    "x": "kulxî (m)",
    "y": " balgamlı öksürük"
  },
  {
    "x": "kumanda (m)",
    "y": " komuta, kumanda"
  },
  {
    "x": "kumandan, -e",
    "y": " kumandan, komutan"
  },
  {
    "x": "kumete (m)",
    "y": " kümbet"
  },
  {
    "x": "kumeyt, -e",
    "y": " doru, (soylu Arap atı) küheylan"
  },
  {
    "x": "kumkomek (m)",
    "y": " b. gumgumike (m)"
  },
  {
    "x": "kumkomok (m)",
    "y": " b. gumgumike (m)"
  },
  {
    "x": "kun (n)",
    "y": " b. kin (n)"
  },
  {
    "x": "kunc (n)",
    "y": " köşe, iç köşe"
  },
  {
    "x": "kunce (n)",
    "y": " b. kuncî (n)"
  },
  {
    "x": "kunci (n)",
    "y": " b. kuncî (n)"
  },
  {
    "x": "kuncî (n)",
    "y": " susam, küncü"
  },
  {
    "x": "kuncnuştox, -e",
    "y": " köşe yazarı"
  },
  {
    "x": "kund (n)",
    "y": " baykuş"
  },
  {
    "x": "kundês (n)",
    "y": " b. kondêz (n) "
  },
  {
    "x": "kundêz (n)",
    "y": " b. kondêz (n) "
  },
  {
    "x": "kundire-I (m)",
    "y": " kabak"
  },
  {
    "x": "kundira çaçanan (m)",
    "y": " bir kabak çeşidi"
  },
  {
    "x": "kundire-II (n)",
    "y": " b. kundure-I (n)"
  },
  {
    "x": "kundîyez (n)",
    "y": " b. kondêz (n)"
  },
  {
    "x": "kundure-I (n)",
    "y": " kundura"
  },
  {
    "x": "kundure-II (m)",
    "y": " b. kundire-I (m)"
  },
  {
    "x": "kunduz (n)",
    "y": " b. kondêz (n) "
  },
  {
    "x": "kunêre (m)",
    "y": " çıban, apse"
  },
  {
    "x": "kunêz (n)",
    "y": " b. kondêz (n)"
  },
  {
    "x": "kung, -e",
    "y": " uyuşuk"
  },
  {
    "x": "kung bîyayîne",
    "y": " b. kung bîyene"
  },
  {
    "x": "kung bîyene",
    "y": " uyuşmak"
  },
  {
    "x": "kung kardene",
    "y": " b. kung kerdene"
  },
  {
    "x": "kung kerdene",
    "y": " uyuşturmak"
  },
  {
    "x": "kungbîyayîş (n)",
    "y": " uyuşma "
  },
  {
    "x": "kungey (m)",
    "y": " b. kungîye (m)"
  },
  {
    "x": "kungênî (m)",
    "y": " uyuşukluk"
  },
  {
    "x": "kungîye (m)",
    "y": " uyuşukluk"
  },
  {
    "x": "kungkardiş (n)",
    "y": " b. kungkerdiş (n)"
  },
  {
    "x": "kungkerdiş (n)",
    "y": " uyuşturma"
  },
  {
    "x": "kunif-I (n)",
    "y": " b. kenewîr (n)"
  },
  {
    "x": "kunif-II (n)",
    "y": " b. kenef (n)"
  },
  {
    "x": "kunîyez (n)",
    "y": " b. kondêz (n) "
  },
  {
    "x": "kunj (n)",
    "y": " b. kunc (n)"
  },
  {
    "x": "kunya (m)",
    "y": " künye"
  },
  {
    "x": "kup (n)",
    "y": " b. kupe (m)"
  },
  {
    "x": "kupayîs (n)",
    "y": " b. dekupîyayîş (n)"
  },
  {
    "x": "kupayîş (n)",
    "y": " b. dekupîyayîş (n)"
  },
  {
    "x": "kupe (m)",
    "y": " küp (büyük küp)"
  },
  {
    "x": "kupa aqilî (m)",
    "y": " akıl küpü"
  },
  {
    "x": "kupêre (m)",
    "y": " (ot ve yaprak için) istif "
  },
  {
    "x": "kupêre kerdene",
    "y": " istiflemek"
  },
  {
    "x": "kupi (m)",
    "y": " b. kupe (m)"
  },
  {
    "x": "kupî (m)",
    "y": " seksek [oyunu]"
  },
  {
    "x": "kupîyayene",
    "y": " b. dekupîyayene"
  },
  {
    "x": "kupnayene",
    "y": " b. dekupnayene"
  },
  {
    "x": "kupnayîş (n)",
    "y": " b. dekupnayîş (n)"
  },
  {
    "x": "kupur (n)",
    "y": " kupür"
  },
  {
    "x": "kur, -e-I",
    "y": " b. kûr, -e"
  },
  {
    "x": "kur, -e-II",
    "y": " b. kirr, -e-II"
  },
  {
    "x": "kur (n)-III",
    "y": " b. kir (n)"
  },
  {
    "x": "kur-IV (n) (ekonomî de)",
    "y": " kur"
  },
  {
    "x": "kuro çepraz (n)",
    "y": " çapraz  kur"
  },
  {
    "x": "kura",
    "y": " b. kure-II"
  },
  {
    "x": "kurabîye (m)",
    "y": " b. qurabîye (m)"
  },
  {
    "x": "kurane-I (m)",
    "y": " 1)tekne\r\n~2)yalak"
  },
  {
    "x": "kurane-II (m)",
    "y": " ocak (ateş ocağı)"
  },
  {
    "x": "kurani (m)",
    "y": " b. kurane-I (m), kurane-II (m)"
  },
  {
    "x": "kuras (n)",
    "y": " b. kirbas (n)"
  },
  {
    "x": "kurata (m)",
    "y": " şaka"
  },
  {
    "x": "kurbeş (n)",
    "y": " b. kurbeşik, -e"
  },
  {
    "x": "kurbeşik, -e",
    "y": " porsuk"
  },
  {
    "x": "kurbişik (n)",
    "y": " b. kurbeşik (n)"
  },
  {
    "x": "kurd, -e",
    "y": " Kürt "
  },
  {
    "x": "kurdele (n)",
    "y": " kurdele"
  },
  {
    "x": "kurdey (m)",
    "y": " b. kurdîye (m)"
  },
  {
    "x": "kurdê (m)",
    "y": " b. kurdîye (m)"
  },
  {
    "x": "Kurdistani (m)",
    "y": " b. Kurdîstan (n) "
  },
  {
    "x": "Kurdiston (n)",
    "y": " b. Kurdîstan (n) "
  },
  {
    "x": "Kurdîstan (n)",
    "y": " Kürdistan"
  },
  {
    "x": "Kurdîstanê Başûrî (n)",
    "y": " Güney Kürdistan"
  },
  {
    "x": "Kurdîstanê Vakurî (n)",
    "y": " Kuzey Kürdistan"
  },
  {
    "x": "Kurdîston (n)",
    "y": " b. Kurdîstan (n)"
  },
  {
    "x": "Kurdîstûn (m)",
    "y": " b. Kurdîstan (n)"
  },
  {
    "x": "kurdîtîye (m)",
    "y": " Kürtlük "
  },
  {
    "x": "kurdîye (m)",
    "y": " Kürtlük "
  },
  {
    "x": "kurdkî (m)",
    "y": " Kürtçe"
  },
  {
    "x": "kurdperest, -e",
    "y": " Kürtçü"
  },
  {
    "x": "kurdperestîye (m)",
    "y": " Kürtçülük "
  },
  {
    "x": "kurdperwer, -e",
    "y": " Kürtçü"
  },
  {
    "x": "kurdperwerîye (m)",
    "y": " Kürtçülük"
  },
  {
    "x": "kure-I (n)",
    "y": " 1)demirci vb. ocağı\r\n~2)(mecazi) çok ve sürekli yalan söyleyen"
  },
  {
    "x": "kure-II",
    "y": " nere\r\n~Defter kure yo? (Defter nerede?)\r\n~\t\r\n~Ti kure ra yî/ya? (Nerelisin?) "
  },
  {
    "x": "kure-III (m)",
    "y": " kurre (m)"
  },
  {
    "x": "kurebesik (n)",
    "y": " b. kurbeşik (n) "
  },
  {
    "x": "kurebeşik (n)",
    "y": " b. kurbeşik (n) "
  },
  {
    "x": "kurege (m)",
    "y": " kürek"
  },
  {
    "x": "kurega silî (m)",
    "y": " döven sürerken öküzün dışkısının tahıl içine düşmemesi için arkasına tutulan kürekçik"
  },
  {
    "x": "kurek (n)",
    "y": " b. kurege (m)"
  },
  {
    "x": "kurêbesik (n)",
    "y": " b. kurbeşik (n) "
  },
  {
    "x": "kurêk (n)",
    "y": " korek (n)"
  },
  {
    "x": "kurih (m)",
    "y": " b. kurihe (m)"
  },
  {
    "x": "kurihe (m)",
    "y": " badem çağlası"
  },
  {
    "x": "kurik-I (n)",
    "y": " çorap"
  },
  {
    "x": "kurik riştene",
    "y": " çorap örmek\r\n~Kênaya mi kurik rişt. (Kızım çorap ördü.)"
  },
  {
    "x": "kurik viraştene",
    "y": " çorap örmek\r\n~Kênaya mi kurik rişt. (Kızım çorap ördü.)"
  },
  {
    "x": "kuriko naylonên (n)",
    "y": " naylon çorap"
  },
  {
    "x": "kuriko peşmên (n)",
    "y": " yün çorap"
  },
  {
    "x": "kuriko purtên (n)",
    "y": " yün çorap"
  },
  {
    "x": "kuriko rêsên (n)",
    "y": " yün çorap"
  },
  {
    "x": "kurik, -e-II",
    "y": " b. kurrî, -ye"
  },
  {
    "x": "kurî, -ye-I",
    "y": " b. kurrî"
  },
  {
    "x": "kurî eştene",
    "y": " b. kurrî eştene"
  },
  {
    "x": "kurî eştene",
    "y": " b. kurrî eştene"
  },
  {
    "x": "kurî-II (n)",
    "y": " b. kîrye (n)"
  },
  {
    "x": "kurîye-I (m)",
    "y": " b. kûrîye (m)"
  },
  {
    "x": "kurîye-II (n)",
    "y": " b. kurye (n)"
  },
  {
    "x": "kurîyek (n)",
    "y": " korek (n)"
  },
  {
    "x": "kurk-I (n)",
    "y": " b. kurke (m)"
  },
  {
    "x": "kurko samur (n)",
    "y": " b. kurka samure (m)"
  },
  {
    "x": "kurk-II (m)",
    "y": " b. kurke (m)"
  },
  {
    "x": "kurkderz, -e",
    "y": " kürkçü"
  },
  {
    "x": "kurkderzî (m)",
    "y": " kürkçülük"
  },
  {
    "x": "kurkderzîye (m)",
    "y": " kürkçülük"
  },
  {
    "x": "kurke (m)",
    "y": " kürk"
  },
  {
    "x": "kurka hewranî (m)",
    "y": " bir kürk çeşidi"
  },
  {
    "x": "kurka samure (m)",
    "y": " samur kürk"
  },
  {
    "x": "kurkroş, -e",
    "y": " kürkçü"
  },
  {
    "x": "kurkroşî (m)",
    "y": " kürkçülük"
  },
  {
    "x": "kurkroşîye (m)",
    "y": " kürkçülük"
  },
  {
    "x": "kurkurik-I (m)",
    "y": " b. kurkurike (m)"
  },
  {
    "x": "kurkurik-II (n)",
    "y": " b. qurqurik (n)"
  },
  {
    "x": "kurkurike (m)",
    "y": " güvercin"
  },
  {
    "x": "kurmanc, -e",
    "y": " Kurmanc"
  },
  {
    "x": "kurmanci (m)",
    "y": " b. kurmance (m)"
  },
  {
    "x": "kurmancî-I (m)",
    "y": " b. kurmanckî (m)"
  },
  {
    "x": "kurmancî-II (n)",
    "y": " bir tüfek çeşidi"
  },
  {
    "x": "kurmanckî (m)",
    "y": " Kurmancca"
  },
  {
    "x": "kurmonc, -i",
    "y": " b. kurmanc, -e"
  },
  {
    "x": "kurmoncî-I (m)",
    "y": " b. kurmanckî (m)"
  },
  {
    "x": "kurmoncî-II (n)",
    "y": " b. kurmancî-II (n)"
  },
  {
    "x": "kurmonckî (m)",
    "y": " b. kurmanckî (m)"
  },
  {
    "x": "kurmuşik (n)",
    "y": " b. kurbeşik (n) "
  },
  {
    "x": "kurmûnc",
    "y": " b. kurmanc, -e"
  },
  {
    "x": "kurmûncî-I (m)",
    "y": " b. kurmanckî (m)"
  },
  {
    "x": "kurmûncî-II (n)",
    "y": " b. kurmancî-II (n)"
  },
  {
    "x": "kurmûnckî (m)",
    "y": " b. kurmanckî (m)"
  },
  {
    "x": "kurna (m)",
    "y": " b. kurne (m)"
  },
  {
    "x": "kurne (m)",
    "y": " 1)kurna\r\n~2)tekne\r\n~3)yalak"
  },
  {
    "x": "kurna banyoyî (m)",
    "y": " küvet"
  },
  {
    "x": "kurni (m)",
    "y": " b. kurne (m)"
  },
  {
    "x": "kurnike (m)",
    "y": " 1)kurna\r\n~2)yalak"
  },
  {
    "x": "kurpişik (n)",
    "y": " b. kurbeşik (n)"
  },
  {
    "x": "kurr, -e-II",
    "y": " b. kirr, -e-II"
  },
  {
    "x": "kurre (m)",
    "y": " hayvanlara kışın yedirilmek üzere kesilip ağacın çatallı ana dalları arasına dizilen yaprak yığını"
  },
  {
    "x": "kurrebesik (n)",
    "y": " b. kurbeşik (n)"
  },
  {
    "x": "kurri-I (n)",
    "y": " b. kurrî, -ye"
  },
  {
    "x": "kurri eştene",
    "y": " b. kurrî eştene"
  },
  {
    "x": "kurri eştene",
    "y": " b. kurrî eştene"
  },
  {
    "x": "kurri-II (m)",
    "y": " b. kirre (m)"
  },
  {
    "x": "kurri-III (m)",
    "y": " b. kurre (m)"
  },
  {
    "x": "kurrik, -e-I",
    "y": " b. kurrî, -ye"
  },
  {
    "x": "kurrik-II (n)",
    "y": " b. kurik-I (n)"
  },
  {
    "x": "kurrî, -ye",
    "y": " 1)sıpa\r\n~2)tay"
  },
  {
    "x": "kurrî eştene",
    "y": " 1)(dişi eşek için) düşük yapmak\r\n~2)(mecazi) bekleyemeksizin hemen yapmak istemek, sabredememek\r\n~3)nefsine hâkim olamayıp yemek istemek (argo)"
  },
  {
    "x": "kurrîk, -e",
    "y": " b. kurrî, -ye"
  },
  {
    "x": "kurrne (m)",
    "y": " b. kurne (m)"
  },
  {
    "x": "kurs (n)",
    "y": " kurs\r\n~kursê ramitoxênî (n): sürücülük kursu\r\n~kursê ramitoxî (n): sürücülük kursu"
  },
  {
    "x": "kursê ramitoxênî (n)",
    "y": " sürücülük kursu"
  },
  {
    "x": "kursê ramitoxî (n)",
    "y": " sürücülük kursu"
  },
  {
    "x": "kursê ramitoxîye (n)",
    "y": " sürücülük kursu"
  },
  {
    "x": "kursê trafîkî (n)",
    "y": " trafik kursu"
  },
  {
    "x": "kurse (m)",
    "y": " b. kurs (n)"
  },
  {
    "x": "kursi (n)",
    "y": " b. kursî (n)"
  },
  {
    "x": "kursî (n)",
    "y": " kürsü"
  },
  {
    "x": "kursîyê awkeyî (n)",
    "y": " banyo kürsüsü"
  },
  {
    "x": "kursîyê banyoyî (n)",
    "y": " banyo kürsüsü"
  },
  {
    "x": "kursîyê çale (m)",
    "y": " banyo kürsüsü"
  },
  {
    "x": "kursîyê çerxî (n)",
    "y": " banyo kürsüsü"
  },
  {
    "x": "kursîyê hemamekî (n)",
    "y": " banyo kürsüsü"
  },
  {
    "x": "kursî (m)",
    "y": " b. kursî (n)"
  },
  {
    "x": "kurşe (m)",
    "y": " b. qurçî (m)"
  },
  {
    "x": "kurşî (m)",
    "y": " b. kursî (n)"
  },
  {
    "x": "kurt bîyene",
    "y": " kuluçkaya yatmak, gurka yatmak"
  },
  {
    "x": "kurtane (m)",
    "y": " palan "
  },
  {
    "x": "kurtane ronayene",
    "y": " hayvanın palanını indirmek"
  },
  {
    "x": "kurtane ser kerdene",
    "y": " hayvanın palanını indirmek"
  },
  {
    "x": "kurte (m)",
    "y": " kuluçka, gurk"
  },
  {
    "x": "kurtepis (n)",
    "y": " dedikodu"
  },
  {
    "x": "kurtik (n)",
    "y": " kapîtone ceket, ceket"
  },
  {
    "x": "kurtnayene",
    "y": " gıdaklamak"
  },
  {
    "x": "kurtnayîş (n)",
    "y": " gıdaklama"
  },
  {
    "x": "kurtûn (m)",
    "y": " kurtane (m) "
  },
  {
    "x": "kuruk (n)",
    "y": " b. kurik-I (n)"
  },
  {
    "x": "kurûpisîye (m)",
    "y": " b. kurtepis (n)"
  },
  {
    "x": "kurya (m)",
    "y": " kurye"
  },
  {
    "x": "kurye (n)",
    "y": " kurye"
  },
  {
    "x": "kuryek (n)",
    "y": " b. korek (n)"
  },
  {
    "x": "kuryeyî (m)",
    "y": " kuryelik"
  },
  {
    "x": "kuryeyîye (m)",
    "y": " kuryelik"
  },
  {
    "x": "kuse (m)",
    "y": " oyun merkezi (oyunda el değdirilmesi gereken direk, taş vb. veya içinden çıkılmaması gereken, sınırları belirlenmiş yer vb.)"
  },
  {
    "x": "kusne (m)",
    "y": " b. kişne (m)"
  },
  {
    "x": "kuşad (n)",
    "y": " b. kuşat"
  },
  {
    "x": "kuşad kerdene",
    "y": " b. kuşat kerdene"
  },
  {
    "x": "kuşadkerdiş (n)",
    "y": " b. kuşatkerdiş (n)"
  },
  {
    "x": "kuşat (n)",
    "y": " şaka"
  },
  {
    "x": "kuşat kerdene",
    "y": " şaka yapmak\r\n~Kuşat meke! (Şaka yapma!)\t"
  },
  {
    "x": "kuşatkerdiş (n)",
    "y": " şaka yapma"
  },
  {
    "x": "kuşlon (n)",
    "y": " b. kuçelan (n)"
  },
  {
    "x": "kuşna (m)",
    "y": " b. kişne (m)"
  },
  {
    "x": "kuşne (m)",
    "y": " b. kişne (m)"
  },
  {
    "x": "kuşnî (m)",
    "y": " b. kişne (m)"
  },
  {
    "x": "kuşt (m)",
    "y": " b. kîşte (m) "
  },
  {
    "x": "kuşt a kewtene",
    "y": " b. kîşt a kewtene"
  },
  {
    "x": "kuşt a vistene",
    "y": " b. kîşt a vistene"
  },
  {
    "x": "kuştakewtiş (n)",
    "y": " b. kîştakewtiş (n)"
  },
  {
    "x": "kuştakoytiş (n)",
    "y": " b. kîştakewtiş (n)"
  },
  {
    "x": "kuştavistiş(n)",
    "y": " b. kîştavistiş (n)"
  },
  {
    "x": "kuştiş (n)",
    "y": " b. kiştiş (n)"
  },
  {
    "x": "kut, -e",
    "y": " b. kud, -e"
  },
  {
    "x": "kuta (m)",
    "y": " çakılı"
  },
  {
    "x": "kute-I (n)",
    "y": " çakılı"
  },
  {
    "x": "kute-II (n)",
    "y": " b. qit (n)"
  },
  {
    "x": "kutene",
    "y": " dövmek"
  },
  {
    "x": "kutik, -e",
    "y": " 1)köpek, it\r\n~2)(hakaret ifadesi olarak) köpek\r\n~3)b. kudik"
  },
  {
    "x": "kutika awî (m)",
    "y": " b. kutika awîye (m)"
  },
  {
    "x": "kutika awîye (m)",
    "y": " su samuru"
  },
  {
    "x": "kutikê/a nêçîrî",
    "y": " av köpeği"
  },
  {
    "x": "kutikê/a seydî",
    "y": " av köpeği"
  },
  {
    "x": "kutiko awî (n)",
    "y": " su samuru"
  },
  {
    "x": "xaşxaşika kutikan (m)",
    "y": " gelincik"
  },
  {
    "x": "kutikane",
    "y": " 1)köpekçe\r\n~2)(mecazi) binbir güçlükle, (mecazi) çok kötü koşullarda"
  },
  {
    "x": "kutikaneyî reyde",
    "y": " 1)köpekçe\r\n~2)(mecazi) binbir güçlükle, (mecazi) çok kötü koşullarda"
  },
  {
    "x": "kutikbab, -e",
    "y": " (sövgü sözcüğü) köpoğlu"
  },
  {
    "x": "kutikbav, -e",
    "y": " (sövgü sözcüğü) köpoğlu"
  },
  {
    "x": "kutikbaw, -e",
    "y": " b. kutikbav, -e"
  },
  {
    "x": "kutikey (m)",
    "y": " b. kutikî (m), kutikîye (m)"
  },
  {
    "x": "kutikey reydi",
    "y": " b. kutikî reyde"
  },
  {
    "x": "kutikênî (m)",
    "y": " 1)köpeklik\r\n~2)(mecazi) binbir güçlük, (mecazi) çok kötü koşullar "
  },
  {
    "x": "kutiki (m)",
    "y": " b. kutike (m)"
  },
  {
    "x": "kutikî (m)",
    "y": " 1)köpeklik\r\n~2)(mecazi) binbir güçlük, (mecazi) çok kötü koşullar"
  },
  {
    "x": "kutikî reyde",
    "y": " 1)köpekçe\r\n~2)(mecazi) binbir güçlükle, (mecazi) çok kötü koşullarda "
  },
  {
    "x": "kutikîye (m)",
    "y": " 1)köpeklik\r\n~2)(mecazi) binbir güçlük, (mecazi) çok kötü koşullar "
  },
  {
    "x": "kutikkî",
    "y": " köpekçe"
  },
  {
    "x": "kutikmase (n)",
    "y": " köpekbalığı "
  },
  {
    "x": "kutikmose (n)",
    "y": " b. kutikmase (n)"
  },
  {
    "x": "kutikone",
    "y": " b. kutikane"
  },
  {
    "x": "kutikoneyî reydi",
    "y": " b. kutikaneyî reyde"
  },
  {
    "x": "kutikpêr, -e",
    "y": " (sövgü sözcüğü) köpoğlu "
  },
  {
    "x": "kutikûne",
    "y": " b. kutikane"
  },
  {
    "x": "kutikza, -ye",
    "y": " (sövgü sözcüğü) köpoğlu"
  },
  {
    "x": "kutikzay, -i",
    "y": " b. kutikza, -ye"
  },
  {
    "x": "kutis (n)",
    "y": " b. kutiş (n)"
  },
  {
    "x": "kutiş (n)",
    "y": " dövme"
  },
  {
    "x": "kutkey (m)",
    "y": " b. kutikî (m)"
  },
  {
    "x": "kutkey reydi",
    "y": " b. kutikî reyde"
  },
  {
    "x": "kutkî",
    "y": " b. kutikkî"
  },
  {
    "x": "kutkûne",
    "y": " b. kutikane"
  },
  {
    "x": "kutkûneyî reydi",
    "y": " b. kutikaneyî reyde"
  },
  {
    "x": "kutuk (n)",
    "y": " b. kutik (n)"
  },
  {
    "x": "kuv (m)",
    "y": " b. kufe (m)"
  },
  {
    "x": "kuvalek (n)",
    "y": " b. guvelik (n)"
  },
  {
    "x": "kuvalêk (n)",
    "y": " b. guvelik (n)"
  },
  {
    "x": "kuvarik (n)",
    "y": " b. guvelik (n)"
  },
  {
    "x": "kuvarîk (n)",
    "y": " b. guvelik (n)"
  },
  {
    "x": "kuvet (n)",
    "y": " küvet"
  },
  {
    "x": "kuvete (m)",
    "y": " b. kuvet (n)"
  },
  {
    "x": "kuvî, -ye",
    "y": " b. kovî, -ye, koyî, -ye"
  },
  {
    "x": "kuwab (n)",
    "y": " b. kebab (n)"
  },
  {
    "x": "kuware (n)",
    "y": " 1)ambar\r\n~2)kovan (arı kovanı)"
  },
  {
    "x": "kuwareyê hingan (n)",
    "y": " kovan (arı kovanı)"
  },
  {
    "x": "kuwareyê mêsanê hingimênî (n)",
    "y": " kovan (arı kovanı)"
  },
  {
    "x": "kuwarî mîyesûn (n)",
    "y": " b. kuwareyê mêsanê hingimênî (n)"
  },
  {
    "x": "kuwarîyê hingan (n)",
    "y": " b. kuwareyê hingan (n)"
  },
  {
    "x": "kuwari (n)",
    "y": " b. kuware (n)"
  },
  {
    "x": "kuwarik (m)",
    "y": " b. kufkarike (m)"
  },
  {
    "x": "kuwarî (n)",
    "y": " b. kuware (n)"
  },
  {
    "x": "kuwaya (m)",
    "y": " çakılı"
  },
  {
    "x": "kuwaye (n)",
    "y": " çakılı"
  },
  {
    "x": "kuwayene",
    "y": " dövmek"
  },
  {
    "x": "kuwayeni",
    "y": " b. kuwayene"
  },
  {
    "x": "kuwayî, -ye",
    "y": " çakılı"
  },
  {
    "x": "kuwayîş (n)",
    "y": " dövme"
  },
  {
    "x": "kuwe (n)",
    "y": " b. ko-I (n)"
  },
  {
    "x": "kuwele",
    "y": " b. kole-I (n)"
  },
  {
    "x": "kuweni (n)",
    "y": " b. kone-I (n)"
  },
  {
    "x": "kuwenikerdiş (n)",
    "y": " b. konekerdiş (n)"
  },
  {
    "x": "kuwese (n)",
    "y": " b. kose (n)"
  },
  {
    "x": "kuwesi (n)",
    "y": " b. kose (n)"
  },
  {
    "x": "kuwet (n)",
    "y": " b. kuvet (n)"
  },
  {
    "x": "kuwete (m)",
    "y": " b. kuvet (n)"
  },
  {
    "x": "kuweter (m)",
    "y": " b. kotere (m)"
  },
  {
    "x": "Kuweyt (n)",
    "y": " Kuveyt"
  },
  {
    "x": "kuwêyayene",
    "y": " b. kuwîyayene"
  },
  {
    "x": "kuwêyayîş (n)",
    "y": " b. kuwîyayîş (n)"
  },
  {
    "x": "kuwi (n)",
    "y": " b. ko-I (n)"
  },
  {
    "x": "kuwîyayene",
    "y": " batmak (sivri şeyin batması)"
  },
  {
    "x": "kuwîyayîş (n)",
    "y": " batma (sivri şeyin batması)"
  },
  {
    "x": "kuxayene",
    "y": " öksürmek"
  },
  {
    "x": "kuxayêne",
    "y": " b. kuxayene"
  },
  {
    "x": "kuxayîş (n)",
    "y": " öksürme"
  },
  {
    "x": "kuxike (m)",
    "y": " öksürük "
  },
  {
    "x": "kuxika sîyaye (m)",
    "y": " boğmaca"
  },
  {
    "x": "kuxika şîyaye (m)",
    "y": " b. kuxika sîyaye (m)"
  },
  {
    "x": "kuxî (m)",
    "y": " b. kuxike (m)"
  },
  {
    "x": "kuy (m)",
    "y": " kabak"
  },
  {
    "x": "kuya awîye (m)",
    "y": " bir kabak çeşidi"
  },
  {
    "x": "kuya şêxanî (m)",
    "y": " bir kabak çeşidi "
  },
  {
    "x": "kuya şêxî (m)",
    "y": " bir kabak çeşidi"
  },
  {
    "x": "kuya zimistanî (m)",
    "y": " bir kabak çeşidi"
  },
  {
    "x": "kuyaye (n)",
    "y": " b. kuwaye (n)"
  },
  {
    "x": "kuyayene",
    "y": " b. kuwayene"
  },
  {
    "x": "kuyayêne",
    "y": " b. kuwayene"
  },
  {
    "x": "kuyayî, -ye",
    "y": " b. kuwayî, -ye"
  },
  {
    "x": "kuyayîş (n)",
    "y": " b. kuwayîş (n)"
  },
  {
    "x": "kuyayîye (m)",
    "y": " b. kuwaya (m)"
  },
  {
    "x": "kuye (m)",
    "y": " b. kuy (m)"
  },
  {
    "x": "kuyene",
    "y": " b. kuwayene"
  },
  {
    "x": "kuyi (m)",
    "y": " b. kuy (m)"
  },
  {
    "x": "kuz (n)-I",
    "y": " testi"
  },
  {
    "x": "kuz, -e-II",
    "y": " kambur"
  },
  {
    "x": "kuz bîyene",
    "y": " kamburlaşmak"
  },
  {
    "x": "kuz-III (n)",
    "y": " b. kunc (n)"
  },
  {
    "x": "kuzayene",
    "y": " 1)(kedi, köpek vb. için) inlemek\r\n~2)(mecazi) ağlamak"
  },
  {
    "x": "kuzayiş (n)",
    "y": " b. kuzayîş (n)"
  },
  {
    "x": "kuzayîs (n)",
    "y": " b. kuzayîş (n)"
  },
  {
    "x": "kuzayîş (n)",
    "y": " 1)(kedi, köpek vb. için) inleme\r\n~2)(mecazi) ağlama"
  },
  {
    "x": "kuze-I (n)",
    "y": " b. kuz-I (n)"
  },
  {
    "x": "kuze-II (n)",
    "y": " sansar"
  },
  {
    "x": "kuzê (m)",
    "y": " b. kuzîye (m)"
  },
  {
    "x": "kuzik-I (n)",
    "y": " küçük testi"
  },
  {
    "x": "kuzik-II (n)",
    "y": " b. kunc (n)"
  },
  {
    "x": "kuzî (m)",
    "y": " kamburluk"
  },
  {
    "x": "kuzîna (m)",
    "y": " kuzine, mutfak sobası"
  },
  {
    "x": "kuzîne (m)",
    "y": " b. kuzîna (m)"
  },
  {
    "x": "kuzîye (m)",
    "y": " kamburluk"
  },
  {
    "x": "kû-I",
    "y": " b. ke-I"
  },
  {
    "x": "kû-II (n)",
    "y": " b. ko-I (n)"
  },
  {
    "x": "kû-III (m)",
    "y": " b. kuy (m)"
  },
  {
    "x": "kû-IV",
    "y": " b. ko-II"
  },
  {
    "x": "kû-V",
    "y": " b. ku-I"
  },
  {
    "x": "kûarbûlîs (n)",
    "y": " b. korbilîyes (n)"
  },
  {
    "x": "kûca",
    "y": " b. kamca"
  },
  {
    "x": "kûç-I (n)",
    "y": " b. koç (n)"
  },
  {
    "x": "kûç-II (m)",
    "y": " b. kuçe-II (m)"
  },
  {
    "x": "kûçe (m)",
    "y": " b. kuçe-II (m)"
  },
  {
    "x": "kûçerey (m)",
    "y": " b. koçerîye (m)"
  },
  {
    "x": "kûçike (m)",
    "y": " b. koçike (m)"
  },
  {
    "x": "kûçiki (m)",
    "y": " b. koçike (m)"
  },
  {
    "x": "kûfî (m)",
    "y": " b. kofî (m)"
  },
  {
    "x": "kûh (n)",
    "y": " b. ko-I (n)"
  },
  {
    "x": "kûhe (n)",
    "y": " b. kewe, -ye-I"
  },
  {
    "x": "kûî (m)",
    "y": " b. kuy (m)"
  },
  {
    "x": "kûm, -i",
    "y": " b. kam, -e"
  },
  {
    "x": "kûma hel",
    "y": " b. kam hele"
  },
  {
    "x": "kûmca",
    "y": " b. kamca"
  },
  {
    "x": "kûmcî",
    "y": " b. kamcîn, -e"
  },
  {
    "x": "kûmder, -i",
    "y": " b. kamder, -e"
  },
  {
    "x": "kûmir (n)",
    "y": " b. komir (n)"
  },
  {
    "x": "kûmî",
    "y": " b. kamî"
  },
  {
    "x": "Kûn (m)",
    "y": " b. Kane (m)"
  },
  {
    "x": "kûnca",
    "y": " b. kamca"
  },
  {
    "x": "kûndêz (n)",
    "y": " b. kondêz (n) "
  },
  {
    "x": "kûni (n)",
    "y": " b. kone-I (n)"
  },
  {
    "x": "kûnikerdiş (n)",
    "y": " b. konekerdiş (n)"
  },
  {
    "x": "kûnkil (n)",
    "y": " b. kakile (m)"
  },
  {
    "x": "kûnkili (m)",
    "y": " b. kakile (m)"
  },
  {
    "x": "kûr, -e",
    "y": " derin"
  },
  {
    "x": "kûr, -i",
    "y": " b. kor, -e"
  },
  {
    "x": "kûrî (m)",
    "y": " derinlik"
  },
  {
    "x": "kûrîye (m)",
    "y": " derinlik"
  },
  {
    "x": "kûrîya krîtîke (m)",
    "y": " kritik derinlik (su derinliği için)"
  },
  {
    "x": "kûrîya parselî (m)",
    "y": " parsel derinliği"
  },
  {
    "x": "kûrîya warî (sn)",
    "y": " alan derinliği"
  },
  {
    "x": "kûrt (m)",
    "y": " b. korte (m)"
  },
  {
    "x": "kûrte (m)",
    "y": " b. kurte (m) "
  },
  {
    "x": "kûrteki (m)",
    "y": " b. kortike (m)"
  },
  {
    "x": "kûrti (m)",
    "y": " b. korte (m)"
  },
  {
    "x": "kûrtiki (m)",
    "y": " b. kortike (m)"
  },
  {
    "x": "kûryek (n)",
    "y": " korek (n)"
  },
  {
    "x": "kûseyî (m)",
    "y": " b. koseyî (m)"
  },
  {
    "x": "kûşki (m)",
    "y": " b. koşke (m)"
  },
  {
    "x": "kûvî, -ye",
    "y": " b. kovî, -ye; koyî, -ye"
  },
  {
    "x": "kûwûn",
    "y": " b. kehen, -e; kan, -e"
  },
  {
    "x": "kûwûnê (m)",
    "y": " b. kehenî (m)"
  },
  {
    "x": "kûyî",
    "y": " b. kovî, -ye; koyî, -ye"
  },
  {
    "x": "kûze-III (n)",
    "y": " b. kunc (n)"
  },
  {
    "x": "kûzik-II (n)",
    "y": " b. kunc (n)"
  },
  {
    "x": "kwari (n)",
    "y": " b. kuware (n)"
  },
  {
    "x": "kwele",
    "y": " b. kole-I (n)"
  },
  {
    "x": "LLL"
  },
  {
    "x": "la-I (n)",
    "y": " ip, iplik, tire"
  },
  {
    "x": "lawo birîsmên (n)",
    "y": " b. layo birîsimên (n)"
  },
  {
    "x": "lawo birsimên (n)",
    "y": " b. layo birîsimên (n)"
  },
  {
    "x": "lawo elbisimên (n)",
    "y": " b. layo birîsimên (n)"
  },
  {
    "x": "lawo helbisimên (n)",
    "y": " b. layo birîsimên (n)"
  },
  {
    "x": "lawo hevrîşimên (n)",
    "y": " b. layo birîsimên (n)"
  },
  {
    "x": "lawo îbrişimên (n)",
    "y": " b. layo birîsimên (n)"
  },
  {
    "x": "lawo îrbişimên (n)",
    "y": " b. layo birîsimên (n)"
  },
  {
    "x": "lawo îvrîşimên (n)",
    "y": " b. layo birîsimên (n)"
  },
  {
    "x": "lay leheyfon (n)",
    "y": " b. layê lihêfan (n)"
  },
  {
    "x": "layê gewreyan (n)",
    "y": " çorap bağı"
  },
  {
    "x": "layê kurikan (n)",
    "y": " çorap bağı"
  },
  {
    "x": "layê liheyfan (n)",
    "y": " b. layê lihêfan (n)"
  },
  {
    "x": "layê lihêfan (n)",
    "y": " yorgan ipliği"
  },
  {
    "x": "layê panceyî (n)",
    "y": " paça ağzını bağlamakta kullanılan bağ"
  },
  {
    "x": "layê puçan (n)",
    "y": " çorap bağı"
  },
  {
    "x": "layê puncan (n)",
    "y": " çorap bağı"
  },
  {
    "x": "layî lehêfûn (n)",
    "y": " b. layê lihêfan (n)"
  },
  {
    "x": "layo birîsimên (n)",
    "y": " ibrişim"
  },
  {
    "x": "la-II (m)",
    "y": " b. lay-I (m)"
  },
  {
    "x": "la-III",
    "y": " ama, fakat; ancak, yalnız; lakin"
  },
  {
    "x": "laak (n)",
    "y": " b. lawik (n)"
  },
  {
    "x": "labaş (m)",
    "y": " b. lawaşe (m)"
  },
  {
    "x": "labaşê binî adirî (m)",
    "y": " b. lawaşa binê adirî (m)"
  },
  {
    "x": "labaşe (m)",
    "y": " b. lawaşe (m)"
  },
  {
    "x": "labat (m)",
    "y": " b. lebate (m)"
  },
  {
    "x": "labat kerdene",
    "y": " b. lebate kerdene"
  },
  {
    "x": "labatkerdiş (n)",
    "y": " b. lebatekerdiş (n)"
  },
  {
    "x": "labelê",
    "y": " ama, fakat; ancak, yalnız; lakin"
  },
  {
    "x": "laberê",
    "y": " b. labelê"
  },
  {
    "x": "labirê",
    "y": " b. labelê"
  },
  {
    "x": "lablê",
    "y": " b. labelê"
  },
  {
    "x": "labnê",
    "y": " b. labelê"
  },
  {
    "x": "laborant, -e",
    "y": " laborant"
  },
  {
    "x": "laboratuwar (n)",
    "y": " laboratuvar"
  },
  {
    "x": "laboratuwaro fermî (n)",
    "y": " resmî laboratuvar"
  },
  {
    "x": "laboratuwaro resmî (n)",
    "y": " resmî laboratuvar"
  },
  {
    "x": "laboratuwaro taybetî (n)",
    "y": " özel laboratuvar"
  },
  {
    "x": "laboratuwaro xususî (n)",
    "y": " özel laboratuvar"
  },
  {
    "x": "labrey",
    "y": " b. labelê"
  },
  {
    "x": "labrê",
    "y": " b. labelê"
  },
  {
    "x": "labut (n)",
    "y": " b. lewde (n)"
  },
  {
    "x": "labût (n)",
    "y": " b. lewde (n)"
  },
  {
    "x": "lac (n)",
    "y": " b. laj (n)"
  },
  {
    "x": "lac’ xalî (n)",
    "y": " b. lajê xalî (n)"
  },
  {
    "x": "lacê apî (n)",
    "y": " b. lajê apî (n)"
  },
  {
    "x": "lacê em (n)",
    "y": " b. lajê eme (n)"
  },
  {
    "x": "lacê emi (n)",
    "y": " b. lajê eme (n)"
  },
  {
    "x": "lacê emiki (n)",
    "y": " b. lajê emike (n)"
  },
  {
    "x": "lacê torni (n)",
    "y": " b. lajê torne (n)"
  },
  {
    "x": "lacê tornî (n)",
    "y": " b. lajê tornî (n)"
  },
  {
    "x": "lacê xali (n)",
    "y": " b. lajê xale (n)"
  },
  {
    "x": "lacê xaliki (n)",
    "y": " b. lajê xalike (n) "
  },
  {
    "x": "lacê xalî (n)",
    "y": " b. lajê xalî (n)"
  },
  {
    "x": "lacê yeyki (n)",
    "y": " b. yaykeza (n) "
  },
  {
    "x": "lacî tuerni (n)",
    "y": " b. lajê torne (n)"
  },
  {
    "x": "lacî tuernî (n)",
    "y": " b. lajê tornî (n)"
  },
  {
    "x": "lacî xali (n)",
    "y": " b. lajê xale (n)"
  },
  {
    "x": "lacî xaliki (n)",
    "y": " b. lajê xalike (n)"
  },
  {
    "x": "lacek (n)",
    "y": " b. lajek (n)"
  },
  {
    "x": "lacey (m)",
    "y": " b. lajîye (m)"
  },
  {
    "x": "lacê (m)",
    "y": " b. lajî (m)"
  },
  {
    "x": "lacik (n)",
    "y": " b. lajek (n)"
  },
  {
    "x": "laçeke (m)",
    "y": " b. leçege (m)"
  },
  {
    "x": "laçik (m)",
    "y": " b. leçege (m)"
  },
  {
    "x": "laçka, -ye",
    "y": " laçka"
  },
  {
    "x": "lahak (n)",
    "y": " b. lajek (n)"
  },
  {
    "x": "lahane (n)",
    "y": " b. lehne (n), kelem (n)"
  },
  {
    "x": "Laheye (m)",
    "y": " Lahey "
  },
  {
    "x": "lahmacunpewj, -e",
    "y": " b. lehmecunpewj, -e"
  },
  {
    "x": "lahmecunpewj, -e",
    "y": " b. lehmecunpewj, -e"
  },
  {
    "x": "lahne (n)",
    "y": " b. lehne (n), kelem (n)"
  },
  {
    "x": "laîk (n)",
    "y": " b. lawik (n)"
  },
  {
    "x": "laj (n)",
    "y": " oğul, erkek çocuk"
  },
  {
    "x": "lajê apî (n)",
    "y": " amca oğlu, amcazade"
  },
  {
    "x": "lajê begî (n)",
    "y": " bey oğlu, beyzade¸ mirza"
  },
  {
    "x": "lajê eme (n)",
    "y": " hala oğlu"
  },
  {
    "x": "lajê emike (n)",
    "y": " hala oğlu"
  },
  {
    "x": "lajê mîreyî (n)",
    "y": " bey oğlu, beyzade¸ mirza"
  },
  {
    "x": "lajê mîrî (n)",
    "y": " b. lajê mîreyî (n)"
  },
  {
    "x": "lajê torne (n)",
    "y": " kız torunun oğlu "
  },
  {
    "x": "lajê tornî (n)",
    "y": " erkek torunun oğlu"
  },
  {
    "x": "lajê xale (n)",
    "y": " teyze oğlu, teyzezade "
  },
  {
    "x": "lajê xalike (n)",
    "y": " teyze oğlu, teyzezade "
  },
  {
    "x": "lajê xalî (n)",
    "y": " dayı oğlu, dayızade"
  },
  {
    "x": "lajê yeyki (n)",
    "y": " b. yaykeza (n) "
  },
  {
    "x": "lajek (n)",
    "y": " erkek çocuk, oğlan"
  },
  {
    "x": "lajey (m)",
    "y": " b. lajîye (m)"
  },
  {
    "x": "lajê (m)",
    "y": " b. lajî (m)"
  },
  {
    "x": "lajî (m)",
    "y": " oğulluk"
  },
  {
    "x": "lajîye (m)",
    "y": " oğulluk"
  },
  {
    "x": "lak (n)",
    "y": " b. la-I (n)"
  },
  {
    "x": "lakan (n)",
    "y": " b. lekan (n)"
  },
  {
    "x": "lakanî (zh)",
    "y": " b. lekanî (zh)"
  },
  {
    "x": "lakim",
    "y": " b. lakîn"
  },
  {
    "x": "lakîm",
    "y": " b. lakîn"
  },
  {
    "x": "lakîn",
    "y": " lakin"
  },
  {
    "x": "lal, -e",
    "y": " dilsiz"
  },
  {
    "x": "lalek, -e",
    "y": " kekeme, keke, kekeç"
  },
  {
    "x": "lalek, -i",
    "y": " b. lalek, -e"
  },
  {
    "x": "lalekey (m)",
    "y": " b. lalekîye (m)"
  },
  {
    "x": "lalekîye (m)",
    "y": " kekemelik, kekelik"
  },
  {
    "x": "laley (m)",
    "y": " b. lalî (m)"
  },
  {
    "x": "lali (m)",
    "y": " b. lal, -e"
  },
  {
    "x": "lalî (m)",
    "y": " kekemelik, kekelik"
  },
  {
    "x": "lalîye (m)",
    "y": " kekemelik, kekelik"
  },
  {
    "x": "lalo (n)",
    "y": " kekeme, keke, kekeç"
  },
  {
    "x": "lalûte (n)",
    "y": " kekeme, keke, kekeç"
  },
  {
    "x": "lalûtî (m)",
    "y": " kekemelik, kekelik"
  },
  {
    "x": "lama, -ye-I",
    "y": " lama"
  },
  {
    "x": "lama-II (m)",
    "y": " b. lamba (m)"
  },
  {
    "x": "lamê qazî (m)",
    "y": " b. lambaya gazyaxî (m)"
  },
  {
    "x": "lamat (m)",
    "y": " b. lamate (m)"
  },
  {
    "x": "lamat werdene",
    "y": " b. lamate werdene"
  },
  {
    "x": "lamate (m)",
    "y": " tokat, sille"
  },
  {
    "x": "lamate werdene",
    "y": " tokat yemek"
  },
  {
    "x": "lamatî kerdene",
    "y": " tokatlamak"
  },
  {
    "x": "lamatî pavistene",
    "y": " tokatlamak"
  },
  {
    "x": "lamatewerdiş (n)",
    "y": " tokat yeme"
  },
  {
    "x": "lamati (m)",
    "y": " b. lamate (m)"
  },
  {
    "x": "lamati werdene",
    "y": " b. lamate werdene"
  },
  {
    "x": "lamatiwerdiş (n)",
    "y": " b. lamatewerdiş (n)"
  },
  {
    "x": "lamatîkerdiş (n)",
    "y": " tokatlama"
  },
  {
    "x": "lamatîpavistiş (n)",
    "y": " tokatlama"
  },
  {
    "x": "lamatwerdiş (n)",
    "y": " b. lamatewerdiş (n)"
  },
  {
    "x": "lamba (m)",
    "y": " lamba"
  },
  {
    "x": "lamba qazexî (m)",
    "y": " b. lambaya gazyaxî (m)"
  },
  {
    "x": "lambaya floresanine (m)",
    "y": " floresan lamba"
  },
  {
    "x": "lambaya gazyaxî (m)",
    "y": " gaz lambası"
  },
  {
    "x": "lambaya keske (m)",
    "y": " yeşil lamba"
  },
  {
    "x": "lambaya qazaxî (m)",
    "y": " b. lambaya gazyaxî (m)"
  },
  {
    "x": "lambê qazî (m)",
    "y": " b. lambaya gazyaxî (m)"
  },
  {
    "x": "lambaya sûre (m)",
    "y": " kırmızı lamba"
  },
  {
    "x": "lambaya trafîkî (m)",
    "y": " trafik lambası"
  },
  {
    "x": "lambaya zerde (m)",
    "y": " sarı lamba"
  },
  {
    "x": "lambe (n)",
    "y": " b. lamba (m)"
  },
  {
    "x": "lambo (n)",
    "y": " b. lamba (m)"
  },
  {
    "x": "lan (m)",
    "y": " b. lane-I (m)"
  },
  {
    "x": "lane-I (m)",
    "y": " delik"
  },
  {
    "x": "lana darloxî (m)",
    "y": " yuvağı çekmek için kullanılan çatallı aracın uçlarının girdiği  yuvağın iki yan tarafındaki girinti"
  },
  {
    "x": "lana gojage/make/bişkoje/qumça/sedefe/zirare (m)",
    "y": " ilik (düğme deliği)"
  },
  {
    "x": "lane ra",
    "y": " anal"
  },
  {
    "x": "lane-II (n)",
    "y": " b. lehne (n), kelem (n)"
  },
  {
    "x": "lani (m)",
    "y": " b. lane (m)"
  },
  {
    "x": "lanike (m)",
    "y": " küçük delik"
  },
  {
    "x": "laniki (m)",
    "y": " b. lanike (m)"
  },
  {
    "x": "lani ra",
    "y": " b. lane ra"
  },
  {
    "x": "lanî, -ye",
    "y": " anal"
  },
  {
    "x": "Laos (n)",
    "y": " Laos"
  },
  {
    "x": "lap (n)",
    "y": " b. lep (n)"
  },
  {
    "x": "lapa (m)",
    "y": " b. lepe (m)"
  },
  {
    "x": "lapat (m)",
    "y": " b. lapate (m)"
  },
  {
    "x": "lapat werdene",
    "y": " b. lapate werdene"
  },
  {
    "x": "lapate (m)",
    "y": " tokat, sille"
  },
  {
    "x": "lapate werdene",
    "y": " tokat yemek"
  },
  {
    "x": "lapatî kerdene",
    "y": " tokatlamak"
  },
  {
    "x": "lapatî pavistene",
    "y": " tokatlamak"
  },
  {
    "x": "lapatî piro dayene",
    "y": " tokatlamak"
  },
  {
    "x": "lapatewerdiş (n)",
    "y": " tokat yeme"
  },
  {
    "x": "lapati (m)",
    "y": " b. lapate (m)"
  },
  {
    "x": "lapati werdene",
    "y": " b. lapate werdene"
  },
  {
    "x": "lapatiwerdiş (n)",
    "y": " b. lapatewerdiş (n)"
  },
  {
    "x": "lapatîkerdiş (n)",
    "y": " tokatlama"
  },
  {
    "x": "lapatîpavistiş (n)",
    "y": " tokatlama"
  },
  {
    "x": "lapatîpirodayîş (n)",
    "y": " tokatlama"
  },
  {
    "x": "lapatwerdiş (n)",
    "y": " b. lapatewerdiş (n)"
  },
  {
    "x": "lapaw (m)",
    "y": " b. lepawe (m)"
  },
  {
    "x": "lape-I (m)",
    "y": " b. lep-I (n)"
  },
  {
    "x": "lape-II (m)",
    "y": " b. lepe (m)"
  },
  {
    "x": "lapi (m)",
    "y": " b. lep (n)"
  },
  {
    "x": "lapik (n)",
    "y": " b. lepik (n)"
  },
  {
    "x": "lapiko pirçî (n)",
    "y": " b. lepiko pirçên (n)"
  },
  {
    "x": "lapîder (n)",
    "y": " lapider (basın için)"
  },
  {
    "x": "laptop (n) (TE)",
    "y": " dizüstü bilgisayar"
  },
  {
    "x": "lapuk (n)",
    "y": " b. lepik (n)"
  },
  {
    "x": "laq (m)",
    "y": " b. lexî (zh)"
  },
  {
    "x": "laq kerdene",
    "y": " b. lexî kerdene"
  },
  {
    "x": "laqçî (n)",
    "y": " b. lexçî, -ye"
  },
  {
    "x": "laqirdî (m)",
    "y": " dedikodu, lakırtı"
  },
  {
    "x": "laqî (zh)",
    "y": " b. lexî (zh)"
  },
  {
    "x": "laqî kerdene",
    "y": " b. lexî kerdene"
  },
  {
    "x": "laqîkerdiş (n)",
    "y": " b. lexîkerdiş (n)"
  },
  {
    "x": "laqkerdiş (n)",
    "y": " b. lexîkerdiş (n)"
  },
  {
    "x": "laroş, -e",
    "y": " ipçi, iplikçi"
  },
  {
    "x": "laser (n)",
    "y": " sel"
  },
  {
    "x": "lasêr (n)",
    "y": " b. laser (n)"
  },
  {
    "x": "lastig (n)",
    "y": " b. lastik (n)"
  },
  {
    "x": "lastik (n)",
    "y": " 1)lastik\r\n~2)lastik ayakkabı"
  },
  {
    "x": "lastikê Anqara (n)",
    "y": " lastik ayakkabının bir çeşidi"
  },
  {
    "x": "lastikê Erzinganî (n)",
    "y": " lastik ayakkabının bir çeşidi"
  },
  {
    "x": "lastikê Palî (n)",
    "y": " lastik ayakkabının bir çeşidi"
  },
  {
    "x": "lastikê paykerdişî (n)",
    "y": " lastik ayakkabı"
  },
  {
    "x": "lastikê Trabzonî (n)",
    "y": " lastik ayakkabının bir çeşidi"
  },
  {
    "x": "lastîgê Trabzonî (n)",
    "y": " b. lastikê Trabzonî (n)"
  },
  {
    "x": "lastîgî Palî (n)",
    "y": " b. lastikê Palî (n)"
  },
  {
    "x": "lastîkê Erzinganî (n)",
    "y": " b. lastikê Erzinganî (n)"
  },
  {
    "x": "lastikên, -e",
    "y": " lastikten yapılma"
  },
  {
    "x": "lastikin, -e",
    "y": " lastikli"
  },
  {
    "x": "lastîg (n)",
    "y": " b. lastik (n)"
  },
  {
    "x": "lastîgê Hanqera (n)",
    "y": " b. lastikê Anqara (n)"
  },
  {
    "x": "lastîk (n)",
    "y": " b. lastik (n)"
  },
  {
    "x": "lastkotîn (n)",
    "y": " bir çeşit bez"
  },
  {
    "x": "laş (m)",
    "y": " b. sale (m) (sala destî)"
  },
  {
    "x": "laşa (m)",
    "y": " b. sale (m) (sala destî)"
  },
  {
    "x": "laşer (n)",
    "y": " b. laser (n)"
  },
  {
    "x": "laşêr (n)",
    "y": " b. laser (n)"
  },
  {
    "x": "laşka, -ye",
    "y": " b. laçka, -ye"
  },
  {
    "x": "laşwer, -e",
    "y": " b. leşwer, -e"
  },
  {
    "x": "latîn, -e",
    "y": " Latin"
  },
  {
    "x": "latînkî (m)",
    "y": " Latince"
  },
  {
    "x": "lav (n)",
    "y": " lav "
  },
  {
    "x": "lavabo (n)",
    "y": " b. lawabo (n)"
  },
  {
    "x": "lavaşi (m)",
    "y": " b. lawaşe (m)"
  },
  {
    "x": "laviraştox, -e",
    "y": " ipçi, iplikçi"
  },
  {
    "x": "lavrê",
    "y": " b. labelê"
  },
  {
    "x": "law (n)",
    "y": " oğul "
  },
  {
    "x": "lawo",
    "y": " oğul (hitap hali), oğlan (hitap hali)\r\n~“Him derg a him barî ya\r\n~\t\r\n~Selîmo lawo yarî ya” (Deyîra “Selîmo Lawo”yî ra)"
  },
  {
    "x": "lawabo (n)",
    "y": " lavabo"
  },
  {
    "x": "lawaş (n)",
    "y": " b. lawaşe (m)"
  },
  {
    "x": "lawaşe (m)",
    "y": " lavaş, bazlama"
  },
  {
    "x": "lawaşa binê adirî (m)",
    "y": " bir çeşit kömbe"
  },
  {
    "x": "lawayene",
    "y": " havlamak, ürümek"
  },
  {
    "x": "lawayêne",
    "y": " b. lawayene"
  },
  {
    "x": "lawayîne",
    "y": " b. lawayene"
  },
  {
    "x": "lawayîs (n)",
    "y": " b. lawayîş (n)"
  },
  {
    "x": "lawayîş (n)",
    "y": " havlama"
  },
  {
    "x": "lawik (n)",
    "y": " oğlan\r\n~“Wey la, şar vono lawkê tû nêweş o\r\n~\t\r\n~Wilay ez nêweşê beşna weş o” (Deyîra “Zerencay Mi” ra)"
  },
  {
    "x": "lawko",
    "y": " oğlan (hitap hali), oğul (hitap hali)"
  },
  {
    "x": "lawike (m)",
    "y": " şarkı, türkü"
  },
  {
    "x": "lawitîyayîş (n)",
    "y": " b. lebitîyayîş (n)"
  },
  {
    "x": "lawitnayene",
    "y": " b. lebitnayene"
  },
  {
    "x": "lawitnayîş (n)",
    "y": " b. lebitnayîş (n)"
  },
  {
    "x": "lawnayene",
    "y": " emzirmek"
  },
  {
    "x": "lawnayîş (n)",
    "y": " emzirme"
  },
  {
    "x": "lawnîyayene",
    "y": " emzirilmek"
  },
  {
    "x": "lawnîyayîş (n)",
    "y": " emzirilme"
  },
  {
    "x": "lawuke (m)",
    "y": " b. lawike (m)"
  },
  {
    "x": "lawutiş (n)",
    "y": " b. lebitnayîş (n)"
  },
  {
    "x": "lawutîyayîş (n)",
    "y": " b. lebitîyayîş (n)"
  },
  {
    "x": "lawutnayene",
    "y": " b. lebitnayene"
  },
  {
    "x": "lawutnayîne",
    "y": " b. lebitnayene"
  },
  {
    "x": "lawutnayîş (n)",
    "y": " b. lebitnayîş (n)"
  },
  {
    "x": "lax-I (n)",
    "y": " hayvan ölüsü, leş (kokuşmuş hayvan ölüsü)"
  },
  {
    "x": "lax-II (m)",
    "y": " b. lexî (zh)"
  },
  {
    "x": "lax kerdene",
    "y": " b. lexî kerdene"
  },
  {
    "x": "laxçî, -ye",
    "y": " b. lexçî, -ye"
  },
  {
    "x": "laxî (zh)",
    "y": " b. lexî (zh)"
  },
  {
    "x": "laxî kerdene",
    "y": " b. lexî kerdene"
  },
  {
    "x": "laxîkerdiş (n)",
    "y": " b. lexîkerdiş (n)"
  },
  {
    "x": "laxkerdiş (n)",
    "y": " b. lexîkerdiş (n)"
  },
  {
    "x": "laxwer, -e",
    "y": " leşçil"
  },
  {
    "x": "lay-I (m)",
    "y": " çay, akarsu"
  },
  {
    "x": "laya juyaye (m)",
    "y": " b. laya ziwaye (m)"
  },
  {
    "x": "laya ziwaye (m)",
    "y": " kuruyan çay, kurumuş çay"
  },
  {
    "x": "laya zuway (m)",
    "y": " b. laya ziwaye (m)"
  },
  {
    "x": "laya zuyay (m)",
    "y": " b. laya ziwaye (m)"
  },
  {
    "x": "lay-II (n)",
    "y": " b. law (n)"
  },
  {
    "x": "layê torne (n)",
    "y": " b. lajê torne (n) "
  },
  {
    "x": "layê tornî (n)",
    "y": " b. lajê tornî (n) "
  },
  {
    "x": "layê xale (n)",
    "y": " b. lajê xale (n)"
  },
  {
    "x": "layê xalike (n)",
    "y": " b. lajê xalike (n) "
  },
  {
    "x": "laya (m)",
    "y": " b. lay-I (m)"
  },
  {
    "x": "layek (n)",
    "y": " b. lawik (n)"
  },
  {
    "x": "layi (m)",
    "y": " b. lay-I (m)"
  },
  {
    "x": "layik (n)",
    "y": " b. lawik (n)"
  },
  {
    "x": "layîk (n)",
    "y": " b. lawik (n)"
  },
  {
    "x": "layl",
    "y": " b. lêl, -e"
  },
  {
    "x": "layla",
    "y": " b. lêl, -e"
  },
  {
    "x": "laym (n)",
    "y": " b. lêm (n)"
  },
  {
    "x": "layş (n)",
    "y": " b. lêş (n)"
  },
  {
    "x": "laz (n)",
    "y": " b. laj (n) "
  },
  {
    "x": "lazê amike (n)",
    "y": " b. lajê emike (n) "
  },
  {
    "x": "lazê apî (n)",
    "y": " b. lajê apî (n)"
  },
  {
    "x": "lazê torne (n)",
    "y": " b. lajê torne (n) "
  },
  {
    "x": "lazê tornî (n)",
    "y": " b. lajê tornî (n) "
  },
  {
    "x": "lazê xale (n)",
    "y": " b. lajê xale (n)"
  },
  {
    "x": "lazê xalike (n)",
    "y": " b. lajê xalike (n)"
  },
  {
    "x": "lazê xalî (n)",
    "y": " b. lajê xalî (n)"
  },
  {
    "x": "lazeç (n)",
    "y": " b. lajek (n) "
  },
  {
    "x": "lazek (n)",
    "y": " b. lajek (n) "
  },
  {
    "x": "lazim, -e",
    "y": " lazım"
  },
  {
    "x": "lazud (n)",
    "y": " b. lazut (n)"
  },
  {
    "x": "lazut (n)",
    "y": " mısır"
  },
  {
    "x": "lazût (n)",
    "y": " b. lazut (n)"
  },
  {
    "x": "le (m)",
    "y": " b. leze (m)"
  },
  {
    "x": "leb (n)",
    "y": " b. lep (n)"
  },
  {
    "x": "lebaş (m)",
    "y": " b. lawaşe (m)"
  },
  {
    "x": "lebaşe (m)",
    "y": " b. lawaşe (m)"
  },
  {
    "x": "lebaşa bindê adirî (m)",
    "y": " b. lawaşa binê adirî (m)"
  },
  {
    "x": "lebaşi (m)",
    "y": " b. lawaşe (m)"
  },
  {
    "x": "lebat-I (n)",
    "y": " hareket"
  },
  {
    "x": "lebat-II (m)",
    "y": " b. lebate (m)"
  },
  {
    "x": "lebat kerdene",
    "y": " b. lebate kerdene"
  },
  {
    "x": "lebatdar, -e",
    "y": " hareketli"
  },
  {
    "x": "lebatdarîye (m)",
    "y": " hareketlilik"
  },
  {
    "x": "lebate (m)",
    "y": " ceht, çaba, gayret"
  },
  {
    "x": "lebate bîyene",
    "y": " çabalanmak"
  },
  {
    "x": "lebate kerdene",
    "y": " çabalamak, çaba göstermek, çaba harcamak, cehdetmek, gayret etmek, gayret göstermek"
  },
  {
    "x": "lebatebîyayîş (n)",
    "y": " çabalanma"
  },
  {
    "x": "lebatekerdiş (n)",
    "y": " cehdetme, çabalama, gayret etme"
  },
  {
    "x": "lebati (m)",
    "y": " b. lebate (m)"
  },
  {
    "x": "lebati kerdene",
    "y": " b. lebate kerdene"
  },
  {
    "x": "lebatikerdiş (n)",
    "y": " b. lebatekerdiş (n)"
  },
  {
    "x": "lebatkerdiş (n)",
    "y": " b. lebatekerdiş (n)"
  },
  {
    "x": "lebetîyayîş (n)",
    "y": " b. lebitîyayîş (n)"
  },
  {
    "x": "lebetnayene",
    "y": " b. lebitnayene"
  },
  {
    "x": "lebetnayîş (n)",
    "y": " b. lebitnayîş (n)"
  },
  {
    "x": "lebitîyayene",
    "y": " kirlenmek"
  },
  {
    "x": "lebitîyayîş (n)",
    "y": " kirlenme"
  },
  {
    "x": "lebitnayîş (n)",
    "y": " kirletme"
  },
  {
    "x": "lebitnayox, -e",
    "y": " kirletici"
  },
  {
    "x": "leblebî (m)",
    "y": " leblebi"
  },
  {
    "x": "leblebîye (m)",
    "y": " leblebi"
  },
  {
    "x": "lebleboy (m)",
    "y": " b. leblebî (m)"
  },
  {
    "x": "leblebuy (m)",
    "y": " b. leblebî (m)"
  },
  {
    "x": "leblebûy (m)",
    "y": " b. leblebî (m)"
  },
  {
    "x": "lec (n)",
    "y": " b. lej (n)"
  },
  {
    "x": "lec kerdene",
    "y": " b. lej kerdene"
  },
  {
    "x": "leckerdiş (n)",
    "y": " b. lejkerdiş (n)"
  },
  {
    "x": "leçege (m)",
    "y": " başörtü, başörtüsü, tülbent, yazma"
  },
  {
    "x": "lee (m)",
    "y": " b. leye (m)"
  },
  {
    "x": "leê (m)",
    "y": " b. leye (m)"
  },
  {
    "x": "leê ronayene",
    "y": " b. leye ronayene"
  },
  {
    "x": "Lefkoşe (m)",
    "y": " Lefkoşe"
  },
  {
    "x": "legan-I (n)",
    "y": " leğen"
  },
  {
    "x": "leganê cilan (n)",
    "y": " çamaşır leğeni"
  },
  {
    "x": "leganê kincan (n)",
    "y": " çamaşır leğeni"
  },
  {
    "x": "legan-II (n)",
    "y": " bir tabanca çeşidi"
  },
  {
    "x": "legane (m)",
    "y": " b. legan (n)"
  },
  {
    "x": "legana kincan (m)",
    "y": " b. leganê kincan (n)"
  },
  {
    "x": "legen (n)",
    "y": " b. legan (n)"
  },
  {
    "x": "legenê cilon (n)",
    "y": " b. leganê cilan (n)"
  },
  {
    "x": "legenî kincûn (n)",
    "y": " b. leganê kincan (n)"
  },
  {
    "x": "legene (m)",
    "y": " b. legan (n)"
  },
  {
    "x": "legena kincan (m)",
    "y": " b. leganê kincan (n)"
  },
  {
    "x": "legleg (m)",
    "y": " b. leglege (m)"
  },
  {
    "x": "leglege (m)",
    "y": " leylek"
  },
  {
    "x": "leglegi (m)",
    "y": " b. leglege (m)"
  },
  {
    "x": "lehça (m)",
    "y": " b. lehçe (m)"
  },
  {
    "x": "lehçe (m)",
    "y": " lehçe"
  },
  {
    "x": "lehef (n)",
    "y": " b. lihêf (n)"
  },
  {
    "x": "lehen (n)",
    "y": " b. lehne (n), kelem (n)"
  },
  {
    "x": "leheyf (n)",
    "y": " b. lihêf (n)"
  },
  {
    "x": "leheyfpirodayîş (n)",
    "y": " b. lihêfpirodayîş (n)"
  },
  {
    "x": "lehêf (n)",
    "y": " b. lihêf (n)"
  },
  {
    "x": "lehî (n)",
    "y": " sel"
  },
  {
    "x": "lehîf (n)",
    "y": " b. lihêf (n)"
  },
  {
    "x": "lehîm (n)",
    "y": " lehim"
  },
  {
    "x": "lehîmcî, -ye",
    "y": " lehimci"
  },
  {
    "x": "lehîmkar, -e",
    "y": " lehimci"
  },
  {
    "x": "lehmecun (n)",
    "y": " lahmacun"
  },
  {
    "x": "lehmecunpewj, -e",
    "y": " lahmacuncu"
  },
  {
    "x": "lehne (n)",
    "y": " lahana"
  },
  {
    "x": "lej-I (n)",
    "y": " kavga, dövüş"
  },
  {
    "x": "lej kerdene",
    "y": " kavga etmek, dövüşmek, boğuşmak"
  },
  {
    "x": "lej-II (m)",
    "y": " b. leze (m)"
  },
  {
    "x": "lejand (n)",
    "y": " efsane"
  },
  {
    "x": "lejant (n)",
    "y": " lejant"
  },
  {
    "x": "lejg (n)",
    "y": " dal"
  },
  {
    "x": "lejge (m)",
    "y": " çatal (dal için)"
  },
  {
    "x": "lejgi (m)",
    "y": " b. lejge (m)"
  },
  {
    "x": "leji (m)",
    "y": " b. leze (m)"
  },
  {
    "x": "lejik (n)",
    "y": " b. lejge (m)"
  },
  {
    "x": "lejkerdiş (n)",
    "y": " kavga etme, dövüşme, boğuşma"
  },
  {
    "x": "lekaf (n)",
    "y": " yük hayvanıyla taş vb. şeyleri taşımak için kullanılan ve semerin iki tarafına sarkıtılan ağaç dalları veya tahtadan yapılma araç"
  },
  {
    "x": "lekan (n)",
    "y": " leken"
  },
  {
    "x": "lekanî (zh)",
    "y": " leken(ler)"
  },
  {
    "x": "lekas (n)",
    "y": " b. lekaş (n)"
  },
  {
    "x": "lekaş (n)",
    "y": " çok eskimiş ayakkabı"
  },
  {
    "x": "lekate (n)",
    "y": " taraş (başak için)"
  },
  {
    "x": "lekate kerdene",
    "y": " taraşlamak, başak toplamak (başak için)"
  },
  {
    "x": "lekatekerdiş (n)",
    "y": " taraşlama (başak için)"
  },
  {
    "x": "lekatekerdox, -e",
    "y": " başakçı"
  },
  {
    "x": "lekete (n)",
    "y": " b. lekate (n)"
  },
  {
    "x": "lekete kerdene",
    "y": " b. lekate kerdene"
  },
  {
    "x": "leketekerdiş (n)",
    "y": " b. lekatekerdiş (n)"
  },
  {
    "x": "lekmate (m)",
    "y": " tokat, sille"
  },
  {
    "x": "lekmate werdene",
    "y": " tokat yemek"
  },
  {
    "x": "lekmatî kerdene",
    "y": " tokatlamak"
  },
  {
    "x": "lekmatî pavistene",
    "y": " tokatlamak"
  },
  {
    "x": "lekmatî piro dayene",
    "y": " tokatlamak"
  },
  {
    "x": "lekmatewerdiş (n)",
    "y": " tokat yeme"
  },
  {
    "x": "lekmati (m)",
    "y": " b. lekmate (m)"
  },
  {
    "x": "lekmati werdene",
    "y": " b. lekmate werdene"
  },
  {
    "x": "lekmatiwerdiş (n)",
    "y": " b. lekmatewerdiş (n)"
  },
  {
    "x": "lekmatîkerdiş (n)",
    "y": " tokatlama"
  },
  {
    "x": "lekmatîpavistiş (n)",
    "y": " tokatlama"
  },
  {
    "x": "lekmatîpirodayîş (n)",
    "y": " tokatlama"
  },
  {
    "x": "lekme (m)",
    "y": " tarla veya arazinin başka tarla veya araziler arasında kalan küçük ve dar bölümü"
  },
  {
    "x": "lekmi (m)",
    "y": " b. lekme (m)"
  },
  {
    "x": "lekon (n)",
    "y": " b. lekan (n)"
  },
  {
    "x": "lekonî (zh)",
    "y": " b. lekanî (zh)"
  },
  {
    "x": "lektor, -e",
    "y": " okutman, lektör"
  },
  {
    "x": "lektorî (m)",
    "y": " okutmanlık, lektörlük"
  },
  {
    "x": "lektorîye (m)",
    "y": " okutmanlık, lektörlük"
  },
  {
    "x": "lekûn (n)",
    "y": " b. lekan (n)"
  },
  {
    "x": "lekûnî (zh)",
    "y": " b. lekanî (zh)"
  },
  {
    "x": "lele",
    "y": " zincir (cezalandırmak amacıyla kişinin boynuna takılan kalın zincir) "
  },
  {
    "x": "lem-I (n)",
    "y": " kaymak"
  },
  {
    "x": "lem-II (m)",
    "y": " b. leme (m)"
  },
  {
    "x": "lemati (m)",
    "y": " b. lamate (m)"
  },
  {
    "x": "lemati werdene",
    "y": " b. lamate werdene"
  },
  {
    "x": "lematî kerdene",
    "y": " b. lamatî kerdene"
  },
  {
    "x": "lematî pavistene",
    "y": " b. lamatî pavistene"
  },
  {
    "x": "lematî piro dayene",
    "y": " b. lamatî pavistene"
  },
  {
    "x": "lematiwerdiş (n)",
    "y": " b. lamatewerdiş (n)"
  },
  {
    "x": "lematîkerdiş (n)",
    "y": " b. lamatîkerdiş (n)"
  },
  {
    "x": "lematîpavistiş (n)",
    "y": " b. lamatîpavistiş (n)"
  },
  {
    "x": "lematîpirodayîş (n)",
    "y": " b. lamatîpavistiş (n)"
  },
  {
    "x": "lemba (m)",
    "y": " b. lamba (m)"
  },
  {
    "x": "leme (m)",
    "y": " 1)çalı\r\n~2)sebze bitkisi\r\n~3) köken (bazı bitkilerin dalları)"
  },
  {
    "x": "lema çîlege (m)",
    "y": " çilek bitkisi"
  },
  {
    "x": "lemeke (m)",
    "y": " 1)çalıcık\r\n~2)sebze bitkisi"
  },
  {
    "x": "lemeki (m)",
    "y": " b. lemeke (m)"
  },
  {
    "x": "lemet (n)",
    "y": " b. zîmet (n)"
  },
  {
    "x": "lemetê ... kewtene",
    "y": " b. zîmetê ... kewtene"
  },
  {
    "x": "lemi (m)",
    "y": " b. leme (m)"
  },
  {
    "x": "lemey çîlegi (m)",
    "y": " b. lema çîlege (m)"
  },
  {
    "x": "lenf (n)",
    "y": " lenf, lenfa, ak kan"
  },
  {
    "x": "leng, -e-I",
    "y": " aksak, topal"
  },
  {
    "x": "leng-lengo (n)",
    "y": " tek ayak üzerinde sekerek yürüme veya koşma"
  },
  {
    "x": "leng-II (m)",
    "y": " b. nenge (m)"
  },
  {
    "x": "leng çînayeni",
    "y": " b. nengî çînayene"
  },
  {
    "x": "lengan (n)",
    "y": " b. legan (n)"
  },
  {
    "x": "lenganê cilan (n)",
    "y": " b. leganê cilan (n)"
  },
  {
    "x": "lengaya (m)",
    "y": " b. lenge (m)"
  },
  {
    "x": "lengaye (n)",
    "y": " b. leng (n)"
  },
  {
    "x": "lengayene",
    "y": " aksamak, topallamak"
  },
  {
    "x": "lengayîs (n)",
    "y": " b. lengayîş (n)"
  },
  {
    "x": "lengayîş (n)",
    "y": " aksama, topallama"
  },
  {
    "x": "lengçînayîş (n)",
    "y": " b. nengîçînayîş (n)"
  },
  {
    "x": "lenge (m)",
    "y": " b. nenge (m)"
  },
  {
    "x": "lengî çînayeni",
    "y": " b. nengî çînayene"
  },
  {
    "x": "lengi (m)",
    "y": " b. nenge (m)"
  },
  {
    "x": "lenger (m)",
    "y": " b. lengerî (m), lengerîye (m)"
  },
  {
    "x": "lengere (n)",
    "y": " b. lengerî (m), lengerîye (m)"
  },
  {
    "x": "lengerî (m)",
    "y": " 1)lenger (kap için)\r\n~2)fötr (fötr şapka)"
  },
  {
    "x": "lengerîye (m)",
    "y": " 1)lenger (kap için)\r\n~2)fötr (fötr şapka)"
  },
  {
    "x": "lengey (m)",
    "y": " b. lengîye (m) "
  },
  {
    "x": "lengê (m)",
    "y": " b. lengîye (m)"
  },
  {
    "x": "lengên (m)",
    "y": " b. lengênî (m)"
  },
  {
    "x": "lengênî (m)",
    "y": " aksaklık, topallık"
  },
  {
    "x": "lengi (m)",
    "y": " b. lenge (m)"
  },
  {
    "x": "lengirî (m)",
    "y": " b. lengerî (m), lengerîye (m)"
  },
  {
    "x": "lengî-I (m)",
    "y": " b. lengîye (m)"
  },
  {
    "x": "lengî-II (n)",
    "y": " b. nenûg (n)"
  },
  {
    "x": "lengîçînayîş (n)",
    "y": " b. nengîçînayîş (n)"
  },
  {
    "x": "lengîyayîş (n)",
    "y": " b. lengayîş (n)"
  },
  {
    "x": "lengîye (m)",
    "y": " aksaklık, topallık"
  },
  {
    "x": "lenglengi (m)",
    "y": " b. leglege (m)"
  },
  {
    "x": "lengrî (m)",
    "y": " b. lengerî (m), lengerîye (m)"
  },
  {
    "x": "leopar, -e",
    "y": " pars"
  },
  {
    "x": "lep-I (n)",
    "y": " apaz, avuç, hapaz, koşam"
  },
  {
    "x": "lep-II (n)",
    "y": " b. lepe (m)"
  },
  {
    "x": "lepat (m)",
    "y": " b. lapate (m)"
  },
  {
    "x": "lepat werdene",
    "y": " b. lapate werdene"
  },
  {
    "x": "lepatî kerdene",
    "y": " b. lapatî kerdene"
  },
  {
    "x": "lepatî pavistene",
    "y": " b. lapatî pavistene"
  },
  {
    "x": "lepatî piro dayene",
    "y": " b. lapatî piro dayene"
  },
  {
    "x": "lepati (m)",
    "y": " b. lapate (m)"
  },
  {
    "x": "lepati werdene",
    "y": " b. lapate werdene"
  },
  {
    "x": "lepatiwerdiş (n)",
    "y": " b. lapatewerdiş (n)"
  },
  {
    "x": "lepatîkerdiş (n)",
    "y": " b. lapatîkerdiş"
  },
  {
    "x": "lepatîpavistiş (n)",
    "y": " b. lapatîpavistiş (n)"
  },
  {
    "x": "lepatîpirodayîş (n)",
    "y": " b. lapatîpirodayîş (n)"
  },
  {
    "x": "lepatwerdiş (n)",
    "y": " b. lapatewerdiş (n)"
  },
  {
    "x": "lepaw (n)",
    "y": " b. lepawe (m)"
  },
  {
    "x": "lepawe (m)",
    "y": " lapa"
  },
  {
    "x": "lepawi (m)",
    "y": " b. lepawe (m)"
  },
  {
    "x": "lepe (m)",
    "y": " lapa"
  },
  {
    "x": "lepik (n)",
    "y": " eldiven"
  },
  {
    "x": "lepikê firine (m)",
    "y": " fırın eldiveni"
  },
  {
    "x": "lepiko çermên (n)",
    "y": " deri eldiven"
  },
  {
    "x": "lepiko peşmên (n)",
    "y": " yün eldiven"
  },
  {
    "x": "lepiko pirçên (n)",
    "y": " yün eldiven"
  },
  {
    "x": "lepiko purtên (n)",
    "y": " yün eldiven"
  },
  {
    "x": "lepiko rêsên (n)",
    "y": " yün eldiven"
  },
  {
    "x": "lepiko sextîyanên (n)",
    "y": " deri eldiven"
  },
  {
    "x": "lepokî (m)",
    "y": " mil (kum ve toprak karışımı)"
  },
  {
    "x": "lepuk (n)",
    "y": " b. lepik (n)"
  },
  {
    "x": "leq (m)",
    "y": " b. lexî (zh)"
  },
  {
    "x": "leq kerdene",
    "y": " b. lexî kerdene"
  },
  {
    "x": "leqatçî, -ye",
    "y": " başakçı"
  },
  {
    "x": "leqate (n)",
    "y": " b. lekate (n)"
  },
  {
    "x": "leqate kerdene",
    "y": " b. lekate kerdene"
  },
  {
    "x": "leqatekerdiş (n)",
    "y": " b. lekatekerdiş (n)"
  },
  {
    "x": "leqçî, -ye",
    "y": " b. lexçî, -ye"
  },
  {
    "x": "leqirdî (n)",
    "y": " b. laqirdî (m)"
  },
  {
    "x": "leqî (zh)",
    "y": " b. lexî (zh)"
  },
  {
    "x": "leqî kerdene",
    "y": " b. lexî kerdene"
  },
  {
    "x": "leqîkerdiş (n)",
    "y": " b. lexîkerdiş (n)"
  },
  {
    "x": "leqkerdiş (n)",
    "y": " b. lexîkerdiş (n)"
  },
  {
    "x": "leqleqo, -ye",
    "y": " geveze; çenebaz, çeneli"
  },
  {
    "x": "leqleqoyê (m)",
    "y": " b. leqleqoyî (m), leqleqoyîye"
  },
  {
    "x": "leqleqoyê kerdene",
    "y": " b. leqleqoyî kerdene"
  },
  {
    "x": "leqleqoyêkerdiş (n)",
    "y": " b. leqleqoyîkerdiş (n)"
  },
  {
    "x": "leqleqoyî (m)",
    "y": " gevezelik, çenebazlık"
  },
  {
    "x": "leqleqoyî kerdene",
    "y": " gevezelik etmek"
  },
  {
    "x": "leqleqoyîkerdiş (n)",
    "y": " gevezelik etme"
  },
  {
    "x": "leqleqoyîye (m)",
    "y": " gevezelik, çenebazlık"
  },
  {
    "x": "leqnaya (m)",
    "y": " asılı"
  },
  {
    "x": "leqnaye (n)",
    "y": " asılı"
  },
  {
    "x": "leqnayene",
    "y": " 1)tepletmek, yerinden oynatmak\r\n~2)sallamak\r\n~3)asmak, idam etmek"
  },
  {
    "x": "leqnayîş (n)",
    "y": " 1)tepletme, yerinden oynatma\r\n~2)sallama\r\n~3)asma, idam etme"
  },
  {
    "x": "leqnayîye (m)",
    "y": " asılı"
  },
  {
    "x": "ler bîyene",
    "y": " b. lêr bîyene"
  },
  {
    "x": "ler kerdene",
    "y": " b. lêr kerdene"
  },
  {
    "x": "lerî bîyene",
    "y": " b. lêr bîyene"
  },
  {
    "x": "lerî kerdene",
    "y": " b. lêr kerdene"
  },
  {
    "x": "lerm, -e",
    "y": " b. nerm, -e "
  },
  {
    "x": "lerm bîyayene",
    "y": " b. nerm bîyene"
  },
  {
    "x": "lerm kerdene",
    "y": " b. nerm kerdene"
  },
  {
    "x": "lermbîyayîş (n)",
    "y": " b. nermbîyayîş (n)"
  },
  {
    "x": "lermkerdiş (n)",
    "y": " b. nermkerdiş (n)"
  },
  {
    "x": "lero",
    "y": " b. leyro"
  },
  {
    "x": "lerz-I (m)",
    "y": " b. lerze (m)"
  },
  {
    "x": "lerz dekotene",
    "y": " b. lerze dekewtene"
  },
  {
    "x": "lerz-II (m)",
    "y": " b. leze (m)"
  },
  {
    "x": "lerzayene",
    "y": " b. lerzîyayene"
  },
  {
    "x": "lerzayeni",
    "y": " b. lerzîyayene"
  },
  {
    "x": "lerzayêne",
    "y": " b. lerzîyayene"
  },
  {
    "x": "lerzayîş (n)",
    "y": " b. lerzîyayîş (n)"
  },
  {
    "x": "lerzaynayene",
    "y": " b. lerznayene"
  },
  {
    "x": "lerze-I (m)",
    "y": " 1)titreme\r\n~2)deprem, zelzele \r\n~3)sıtma nöbeti "
  },
  {
    "x": "lerze dekewtene",
    "y": " titreme tutmak, titreşmek"
  },
  {
    "x": "lerze girewtene",
    "y": " sıtma nöbetine tutulmak"
  },
  {
    "x": "lerze guretene",
    "y": " b. lerze girewtene "
  },
  {
    "x": "lerze-II (m)",
    "y": " b. leze (m)"
  },
  {
    "x": "lerzi (m)",
    "y": " b. lerze-I (m)"
  },
  {
    "x": "lerzi dekewtene",
    "y": " b. lerze dekewtene"
  },
  {
    "x": "lerzi girotene",
    "y": " b. lerze girewtene"
  },
  {
    "x": "lerzîyayene",
    "y": " titremek"
  },
  {
    "x": "lerzîyayîş (n)",
    "y": " titreme, titreşim"
  },
  {
    "x": "lerzîye (m)",
    "y": " b. lezîye (m) "
  },
  {
    "x": "lerznayene",
    "y": " titretmek"
  },
  {
    "x": "lerznayeni",
    "y": " b. lerznayene"
  },
  {
    "x": "lerznayêne",
    "y": " b. lerznayene"
  },
  {
    "x": "lese (m)",
    "y": " b. leşe (m)"
  },
  {
    "x": "leş (m)",
    "y": " b. leşe (m)"
  },
  {
    "x": "leşe (m)",
    "y": " 1)leş\r\n~2)vücut\r\n~leşa goştî (m): karkas (et için)"
  },
  {
    "x": "leşa goştî (m)",
    "y": " karkas (et için)"
  },
  {
    "x": "leşi (m)",
    "y": " b. leşe (m)"
  },
  {
    "x": "leşker, -e",
    "y": " asker"
  },
  {
    "x": "leşkerey (m)",
    "y": " b. leşkerîye (m)"
  },
  {
    "x": "leşkerê (m)",
    "y": " b. leşkerîye (m)"
  },
  {
    "x": "leşkerî, -ye",
    "y": " askeri"
  },
  {
    "x": "leşkerîye (m)",
    "y": " askerlik\r\n~leşkerîya bi pereyan: bedelli askerlik"
  },
  {
    "x": "leşkerîya bi pereyan",
    "y": " bedelli askerlik"
  },
  {
    "x": "leşkerîya bibedêle",
    "y": " bedelli askerlik"
  },
  {
    "x": "leşwer, -e",
    "y": " leşçil"
  },
  {
    "x": "lete (n)",
    "y": " dilim, parça"
  },
  {
    "x": "lete bîyene",
    "y": " parçalanmak"
  },
  {
    "x": "lete kerdene",
    "y": " parçalamak"
  },
  {
    "x": "letey kerdene",
    "y": " b. leteyî kerdene"
  },
  {
    "x": "letey nanî (n)",
    "y": " b. leteyê nanî (n)"
  },
  {
    "x": "letey nonî (n)",
    "y": " b. leteyê nanî (n)"
  },
  {
    "x": "letey nûnî (n)",
    "y": " b. leteyê nanî (n)"
  },
  {
    "x": "leteyê cîgere (n)",
    "y": " ciğerpare"
  },
  {
    "x": "leteyê nanî (n)",
    "y": " ekmek parçası"
  },
  {
    "x": "leteyî kerdene",
    "y": " doğramak (ekmek vb. için), ufalamak (ekmek vb. için)"
  },
  {
    "x": "letê cîgere (n)",
    "y": " b. leteyê cîgere (n)"
  },
  {
    "x": "letebîyayîş (n)",
    "y": " parçalanma"
  },
  {
    "x": "letekerdiş (n)",
    "y": " parçalama"
  },
  {
    "x": "leteyîkerdiş (n)",
    "y": " doğrama (ekmek vb. için), ufalama (ekmek vb. için)"
  },
  {
    "x": "leteykerdiş (n)",
    "y": " b. leteyîkerdiş (n)"
  },
  {
    "x": "leti (n)",
    "y": " b. lete (n)"
  },
  {
    "x": "leti bîyene",
    "y": " b. lete bîyene"
  },
  {
    "x": "leti kerdene",
    "y": " b. lete kerdene"
  },
  {
    "x": "letibîyayîş (n)",
    "y": " b. letebîyayîş (n)"
  },
  {
    "x": "letik (n)",
    "y": " parçacık"
  },
  {
    "x": "letik bîyene",
    "y": " parçacıklara ayrılmak"
  },
  {
    "x": "letik kerdene",
    "y": " parçacıklara ayırmak"
  },
  {
    "x": "letikbîyayîş (n)",
    "y": " parçacıklara ayrılma"
  },
  {
    "x": "letikerdiş (n)",
    "y": " b. letekerdiş (n)"
  },
  {
    "x": "letikkerdiş (n)",
    "y": " parçacıklara ayırma"
  },
  {
    "x": "Letonya (m)",
    "y": " Letonya"
  },
  {
    "x": "levaş (m)",
    "y": " b. lawaşe (m)"
  },
  {
    "x": "levaşê binî adirî (m)",
    "y": " b. lawaşa binê adirî (m)"
  },
  {
    "x": "levrek (n)",
    "y": " levrek "
  },
  {
    "x": "levya (m)",
    "y": " levye"
  },
  {
    "x": "lew-I (n)",
    "y": " dudak"
  },
  {
    "x": "lew kerdayene",
    "y": " b. lewî kerdene"
  },
  {
    "x": "lew kerdene",
    "y": " b. lewî kerdene"
  },
  {
    "x": "lew nayene …",
    "y": " b. lewe … ra nayene"
  },
  {
    "x": "lew padayene",
    "y": " b. lewe padayene"
  },
  {
    "x": "lew panayene",
    "y": " b. lewe panayene"
  },
  {
    "x": "lew pira nayene",
    "y": " b. lewe pira nayene"
  },
  {
    "x": "lew … ra dayene",
    "y": " b. lewe … ra dayene"
  },
  {
    "x": "lewê binî (n)",
    "y": " b. lewo binên (n)"
  },
  {
    "x": "lewê gapûre (n)",
    "y": " yanağın ağız boşluğuna bakan iç tarafı "
  },
  {
    "x": "lewê serî (n)",
    "y": " b. lewo serên (n)"
  },
  {
    "x": "lewî kerdene",
    "y": " (ağlamaklı olanın) dudakları hareket etmek"
  },
  {
    "x": "lewî pêra nayene",
    "y": " ağzını kapatmak (hiç konuşmamak)"
  },
  {
    "x": "lewî pîçiknayene",
    "y": " (ağlamaklı olanın) dudakları hareket etmek"
  },
  {
    "x": "lewî pîçkerdene",
    "y": " (ağlamaklı olanın) dudakları hareket etmek"
  },
  {
    "x": "lewî recefîyayene",
    "y": " b. lewî recifîyayene"
  },
  {
    "x": "lewî recifîyayene",
    "y": " (ağlamaklı olanın) dudakları hareket etmek"
  },
  {
    "x": "lewî refşîyayene",
    "y": " b. lewî recifîyayene"
  },
  {
    "x": "lewî repşîyayene",
    "y": " b. lewî recifîyayene"
  },
  {
    "x": "lewo binên (n)",
    "y": " alt dudak"
  },
  {
    "x": "lewo serên (n)",
    "y": " üst dudak"
  },
  {
    "x": "lew-II (m)",
    "y": " b. lewe-I (m)"
  },
  {
    "x": "lewabo (n)",
    "y": " b. lawabo (n)"
  },
  {
    "x": "lewalew, -e",
    "y": " dopdolu, ağzına kadar dolu"
  },
  {
    "x": "lewaş (m)",
    "y": " b. lawaşe (m)"
  },
  {
    "x": "lewaşê binî adirî (m)",
    "y": " b. lawaşa binê adirî (m)"
  },
  {
    "x": "lewazim (n)",
    "y": " levazım"
  },
  {
    "x": "lewazimkar, -e",
    "y": " levazımcı"
  },
  {
    "x": "lewazimkarênî (m)",
    "y": " levazımcılık"
  },
  {
    "x": "lewazimkarî (m)",
    "y": " levazımcılık"
  },
  {
    "x": "lewazimkarîye (m)",
    "y": " levazımcılık"
  },
  {
    "x": "lewde (n)",
    "y": " çekel (üvendirenin ucundaki yassı demir)"
  },
  {
    "x": "lewe-I (m)",
    "y": " öpücük\r\n~Yew maçî/paçî/lewe bide. (Bir öpücük ver!)"
  },
  {
    "x": "lewe padayene",
    "y": " öpmek"
  },
  {
    "x": "lewe panayene",
    "y": " öpmek"
  },
  {
    "x": "lewe pira nayene",
    "y": " öpmek"
  },
  {
    "x": "lewe … ra dayene",
    "y": " öpmek"
  },
  {
    "x": "lewe … ra nayene",
    "y": " öpmek"
  },
  {
    "x": "lewî kerdene",
    "y": " öpmek"
  },
  {
    "x": "lewe-II (n)",
    "y": " tepe"
  },
  {
    "x": "lewe-III (n)",
    "y": " b. ley-I (n)"
  },
  {
    "x": "lewede (n)",
    "y": " b. lewde (n)"
  },
  {
    "x": "lewha (m)",
    "y": " levha"
  },
  {
    "x": "lewhaya balantişî (m)",
    "y": " uyarı levhası"
  },
  {
    "x": "lewhaya îşaretî (m)",
    "y": " işaret levhası"
  },
  {
    "x": "lewhaya nîşanî (m)",
    "y": " işaret levhası"
  },
  {
    "x": "lewhaya rayîrî (m)",
    "y": " yol levhası"
  },
  {
    "x": "lewhe (n)",
    "y": " b. lewha (m)"
  },
  {
    "x": "lewi-I (m)",
    "y": " b. lewe-I (m)"
  },
  {
    "x": "lewi kerdene",
    "y": " b. lewî kerdene"
  },
  {
    "x": "lewi padayene",
    "y": " b. lewe padayene"
  },
  {
    "x": "lewi panayene",
    "y": " b. lewe panayene"
  },
  {
    "x": "lewi … ra dayene",
    "y": " b. lewe … ra dayene"
  },
  {
    "x": "lewi-II (n)",
    "y": " b. lewe-II (n)"
  },
  {
    "x": "lewîkerdiş (n)",
    "y": " (ağlamaklı olanın) dudakları hareket etme"
  },
  {
    "x": "lewîpêranayîş (n)",
    "y": " (hiç konuşmama) ağzını kapatma"
  },
  {
    "x": "lewîpîçiknayîş (n)",
    "y": " (ağlamaklı olanın) dudakları hareket etme"
  },
  {
    "x": "lewîpîçkerdiş (n)",
    "y": " (ağlamaklı olanın) dudakları hareket etme"
  },
  {
    "x": "lewîrecefîyayîş (n)",
    "y": " b. lewîrecifîyayîş (n)"
  },
  {
    "x": "lewîrecifîyayîş (n)",
    "y": " (ağlamaklı olanın) dudakları hareket etme"
  },
  {
    "x": "lewîrefşîyayîş (n)",
    "y": " b. lewîrecifîyayîş (n)"
  },
  {
    "x": "lewîrepşîyayîş (n)",
    "y": " b. lewîrecifîyayîş (n)"
  },
  {
    "x": "lewîyayene",
    "y": " hareket etmek"
  },
  {
    "x": "lewîyayêne",
    "y": " b. lewîyayene"
  },
  {
    "x": "lewîyayîş (n)",
    "y": " hareket etme"
  },
  {
    "x": "lewone (n)",
    "y": " b. lehne (n), kelem (n)"
  },
  {
    "x": "lewtene",
    "y": " b. litene"
  },
  {
    "x": "lewtik (n)",
    "y": " 1)emzik\r\n~2)oğlak ve kuzu gibi hayvanların annelerinin sütünü emmesini engellemek amacıyla ağızlarına takılan ağızlık"
  },
  {
    "x": "lewuye (n)",
    "y": " b. liv (n)"
  },
  {
    "x": "lewveradaya (m)",
    "y": " asık suratlı, abus"
  },
  {
    "x": "lewveradaye (n)",
    "y": " asık suratlı, abus"
  },
  {
    "x": "lewveradayîye (m)",
    "y": " asık suratlı, abus"
  },
  {
    "x": "lex (m)",
    "y": " b. lexî (zh)"
  },
  {
    "x": "lex kerdene",
    "y": " b. lexî kerdene"
  },
  {
    "x": "lexçî, -ye",
    "y": " şakacı"
  },
  {
    "x": "lexim (n)",
    "y": " lağım"
  },
  {
    "x": "lexî (zh)",
    "y": " şaka"
  },
  {
    "x": "lexî kerdene",
    "y": " şaka yapmak\r\n~Lexî meke! (Şaka yapma!)"
  },
  {
    "x": "lexîkerdiş (n)",
    "y": " şaka yapma"
  },
  {
    "x": "lexkerdiş (n)",
    "y": " b. lexîkerdiş (n)"
  },
  {
    "x": "ley-I (n)",
    "y": " yan\r\n~“Leyê nameyê ê bînan de... ” (Munzur Çem, Vate, Nr. 23, r. 63) (Diğerlerinin adının yanında...)"
  },
  {
    "x": "ley-II (m)",
    "y": " b. leye-I (m)"
  },
  {
    "x": "leye-I (m)",
    "y": " fidan, çalı"
  },
  {
    "x": "leye denayene",
    "y": " fidan dikmek"
  },
  {
    "x": "leye ronayene",
    "y": " fidan dikmek"
  },
  {
    "x": "leye-II (n)",
    "y": " b. ley-I (n)"
  },
  {
    "x": "leye-III (m)",
    "y": " b. legan (n)"
  },
  {
    "x": "leyegeh (n)",
    "y": " fidanlık"
  },
  {
    "x": "leyek (m)",
    "y": " b. leyeke (m)"
  },
  {
    "x": "leyeke (m)",
    "y": " fidan"
  },
  {
    "x": "leyêke (m)",
    "y": " b. leyeke (m)"
  },
  {
    "x": "leyir (n)",
    "y": " b. leyîr (n)"
  },
  {
    "x": "leyîf (n)",
    "y": " b. lihêf (n)"
  },
  {
    "x": "leyîm (n)",
    "y": " b. lehîm (n)"
  },
  {
    "x": "leyîr, -e",
    "y": " 1)yavru (hayvan için), civciv\r\n~2)çocuk"
  },
  {
    "x": "leyîr ardene",
    "y": " 1)(köpek, kedi gibi bazı hayvanlar için) doğurmak, eniklemek, yavrulamak"
  },
  {
    "x": "leyîr bîyene",
    "y": " çocuklaşmak"
  },
  {
    "x": "leyîr vetene",
    "y": " yavrulamak (yavrusu yumurtadan çıkan hayvanlar için)"
  },
  {
    "x": "leyîrê kutikî (n)",
    "y": " enik, encik, encek, köpek yavrusu"
  },
  {
    "x": "leyîr ver şîyene",
    "y": " düşük yapmak"
  },
  {
    "x": "leyîro",
    "y": " b. leyro"
  },
  {
    "x": "leyro",
    "y": " oğul (hitap hali), yavrum (hitap hali)"
  },
  {
    "x": "leyîrbîyayîş (n)",
    "y": " çocuklaşma"
  },
  {
    "x": "leyîri (m)",
    "y": " b. leyîre (m)"
  },
  {
    "x": "leyîrzerenc, -e",
    "y": " yavru keklik"
  },
  {
    "x": "leyl (n)",
    "y": " lêl, -e-I"
  },
  {
    "x": "leylege (m)",
    "y": " b. leglege (m)"
  },
  {
    "x": "leylek, -i",
    "y": " b. leyrek, -e"
  },
  {
    "x": "leyleke (m)",
    "y": " belin (bel aracının) üzerine ayak konan parçası"
  },
  {
    "x": "leym-I (n)",
    "y": " b. rêm (n)"
  },
  {
    "x": "leym bestene",
    "y": " b. rêm bestene"
  },
  {
    "x": "leym dekewtene",
    "y": " b. rêm dekewtene"
  },
  {
    "x": "leym-II (n)",
    "y": " b. lêm (n)"
  },
  {
    "x": "leym û lêşiki (m)",
    "y": " b. lêm û lêşike (m)"
  },
  {
    "x": "leymenduzî (n)",
    "y": " b. leymunduzî (n)"
  },
  {
    "x": "leymi (n)",
    "y": " b. lêmin, -e"
  },
  {
    "x": "leymi bîyayîne",
    "y": " b. lêmin bîyene"
  },
  {
    "x": "leymi kerdene",
    "y": " b. lêmin kerdene"
  },
  {
    "x": "leymibîyayîş (n)",
    "y": " b. lêminbîyayîş (n)"
  },
  {
    "x": "leymikerdiş (n)",
    "y": " b. lêminkerdiş (n)"
  },
  {
    "x": "leymin, -i-I",
    "y": " b. rêmin, -e"
  },
  {
    "x": "leymin, -i-II",
    "y": " b. lêmin, -e"
  },
  {
    "x": "leymin bîyayene",
    "y": " b. lêmin bîyene"
  },
  {
    "x": "leymin kerdene",
    "y": " b. lêmin kerdene"
  },
  {
    "x": "leymin û lêşikin, -i",
    "y": " b. lêmin û lêşikin, -e"
  },
  {
    "x": "leymin û lêşin, -i",
    "y": " b. lêmin û lêşin, -e"
  },
  {
    "x": "leyminbîyayîş (n)",
    "y": " b. lêminbîyayîş (n)  "
  },
  {
    "x": "leyminey (m)",
    "y": " b. lêminî (m)"
  },
  {
    "x": "leyminkerdiş (n)",
    "y": " b. lêminkerdiş (n)"
  },
  {
    "x": "leymunata (m)",
    "y": " limonata"
  },
  {
    "x": "leymundozî (n)",
    "y": " b. leymunduzî (n)"
  },
  {
    "x": "leymunduzî (n)",
    "y": " limontuzu (sitrik asit)"
  },
  {
    "x": "leymundûzî (n)",
    "y": " b. leymunduzî (n)"
  },
  {
    "x": "leymune (m)",
    "y": " limon"
  },
  {
    "x": "leymunêre (m)",
    "y": " limon ağacı"
  },
  {
    "x": "leymuni (m)",
    "y": " b. leymune (m)"
  },
  {
    "x": "leymûndûzî (n)",
    "y": " b. leymunduzî (n)"
  },
  {
    "x": "leymûni (m)",
    "y": " b. leymune (m)"
  },
  {
    "x": "leyr, -i",
    "y": " b. leyîr, -e"
  },
  {
    "x": "leyr ardiş",
    "y": " b. leyîr ardene "
  },
  {
    "x": "leyr vetene",
    "y": " b. leyîr vetene"
  },
  {
    "x": "leyrek, -e",
    "y": " yavru (hayvan için)"
  },
  {
    "x": "leyrek ardene",
    "y": " 1)(köpek, kedi gibi bazı hayvanlar için) doğurmak, eniklemek, yavrulamak"
  },
  {
    "x": "leyrek kerdene",
    "y": " yavrulamak (yavrusu yumurtadan çıkan hayvanlar için)"
  },
  {
    "x": "leyrek vetene",
    "y": " yavrulamak (yavrusu yumurtadan çıkan hayvanlar için)"
  },
  {
    "x": "leyrekê kutikî (n)",
    "y": " enik, encik, encek, köpek yavrusu"
  },
  {
    "x": "leyr bîyene",
    "y": " b. lêr bîyene"
  },
  {
    "x": "leeyr kerdeni",
    "y": " b. lêr kerdene"
  },
  {
    "x": "leyreki (m)",
    "y": " b. leyreke (m)"
  },
  {
    "x": "leyrik, -i",
    "y": " b. leyrek, -e"
  },
  {
    "x": "leyrî bîyene",
    "y": " b. lêr bîyene"
  },
  {
    "x": "leyrî kerdene",
    "y": " b. lêr kerdene"
  },
  {
    "x": "leyro",
    "y": " oğul (hitap hali), yavrum (hitap hali)"
  },
  {
    "x": "leyrzarej",
    "y": " b. leyîrzerenc, -e"
  },
  {
    "x": "lez-I (n)",
    "y": " b. lej-I (n)"
  },
  {
    "x": "lez kerdene",
    "y": " b. lej kerdene"
  },
  {
    "x": "lez-II (m)",
    "y": " b. leze (m)"
  },
  {
    "x": "lezabez, -e",
    "y": " acil, ivedi, acele acele"
  },
  {
    "x": "lezabez bîyene",
    "y": " acil olmak, ivedi olmak"
  },
  {
    "x": "lezabez ra kerdene",
    "y": " aceleye getirmek"
  },
  {
    "x": "lezabez ra viraştene",
    "y": " aceleye getirmek"
  },
  {
    "x": "lezabezî (m)",
    "y": " acillik, ivedilik"
  },
  {
    "x": "lezabezîye (m)",
    "y": " acillik, ivedilik"
  },
  {
    "x": "lezabezkî",
    "y": " acilen, çabucak"
  },
  {
    "x": "lezard (n)",
    "y": " lezard (basın için)"
  },
  {
    "x": "lezdar, -e",
    "y": " süratli"
  },
  {
    "x": "leze (m)",
    "y": " çabuk, tez, hızlı, süratli, acele"
  },
  {
    "x": "Leza to ya?",
    "y": " “Acelen ne?”, “Senin sıran mı?” anlamında zamansız bir şey yapana hitaben söylenen bir söz"
  },
  {
    "x": "leze kerdene",
    "y": " acele etmek"
  },
  {
    "x": "lezet (n)",
    "y": " tat, lezzet"
  },
  {
    "x": "lezetin, -e",
    "y": " lezzetli"
  },
  {
    "x": "lezetinî (m)",
    "y": " lezzetlilik"
  },
  {
    "x": "lezetinîye (m)",
    "y": " lezzetlilik"
  },
  {
    "x": "lezey (m)",
    "y": " b. lezîye (m), lezî (m)"
  },
  {
    "x": "lezê (m)",
    "y": " b. lezîye (m), lezî (m)"
  },
  {
    "x": "lezge-I (m)",
    "y": " b. lejg (n)"
  },
  {
    "x": "lezge-II (n)",
    "y": " b. lejg (n)"
  },
  {
    "x": "lezi (m)",
    "y": " b. leze (m)"
  },
  {
    "x": "lezî (m)",
    "y": " 1)hız, sürat\r\n~lezîya hedravîyartîye: aşırı hız, aşırı sürat\r\n~lezîya tewr kême: asgari hız, asgari sürat\r\n~2)hızlılık, çabukluk, tezlik"
  },
  {
    "x": "lezîya awe (m)",
    "y": " su hızı"
  },
  {
    "x": "lezîya ekstreme",
    "y": " aşırı hız, aşırı sürat"
  },
  {
    "x": "lezîya hedravîyarta",
    "y": " aşırı hız, aşırı sürat"
  },
  {
    "x": "lezîya hedravîyartîye",
    "y": " aşırı hız, aşırı sürat"
  },
  {
    "x": "lezîya roşna (m)",
    "y": " ışık hızı"
  },
  {
    "x": "lezîya roştî (m)",
    "y": " ışık hızı"
  },
  {
    "x": "lezîya tewr kême",
    "y": " asgari hız, asgari sürat"
  },
  {
    "x": "lezîya tewr zafe",
    "y": " azami hız, azami sürat"
  },
  {
    "x": "lezîbir (n)",
    "y": " hız kesici, kasis"
  },
  {
    "x": "lezîpeym (n)",
    "y": " hızölçer, takigraf, takograf, takometre"
  },
  {
    "x": "lezîye (m)",
    "y": " 1)hız, sürat\r\n~2)hızlılık, çabukluk, tezlik"
  },
  {
    "x": "lezkanî",
    "y": " hızlıca, acele ile"
  },
  {
    "x": "lezkerdiş (n)",
    "y": " b. lejkerdiş (n)"
  },
  {
    "x": "lezok, -e",
    "y": " aceleci, telaşlı"
  },
  {
    "x": "lezokî (m)",
    "y": " 1)acelecilik \r\n~2)telaş"
  },
  {
    "x": "lezokîye (m)",
    "y": " telaş"
  },
  {
    "x": "lezûbez, -e",
    "y": " b. lezabez, -e"
  },
  {
    "x": "lezzet (n)",
    "y": " b. lezet (n)"
  },
  {
    "x": "lezzetin, -e",
    "y": " b. lezetin, -e"
  },
  {
    "x": "lezzetiney (m)",
    "y": " b. lezetinîye (m)"
  },
  {
    "x": "lezzetinê (m)",
    "y": " b. lezetinî (m)"
  },
  {
    "x": "lê-I",
    "y": " b. la-III, labelê"
  },
  {
    "x": "lê-II (n)",
    "y": " b. lên (n)"
  },
  {
    "x": "lê-III (n)",
    "y": " b. ley-I (n)"
  },
  {
    "x": "lêçege (m)",
    "y": " b. leçege (m)"
  },
  {
    "x": "lêçek (m)",
    "y": " b. leçege (m)"
  },
  {
    "x": "lêhîm (n)",
    "y": " b. lehîm (n)"
  },
  {
    "x": "lêhîmcî, -ye",
    "y": " b. lehîmcî, -ye"
  },
  {
    "x": "lêhm (n)",
    "y": " b. lehîm (n)"
  },
  {
    "x": "lêhmcî, -ye",
    "y": " b. lehîmcî, -ye"
  },
  {
    "x": "lêj (n)",
    "y": " b. rêj (n)"
  },
  {
    "x": "lêl, -e",
    "y": " 1)bulanık\r\n~2)alacakaranlık\r\n~lêlê şodirî (n): gün ağarması"
  },
  {
    "x": "lêlê nimajî (n)",
    "y": " gün ağarması"
  },
  {
    "x": "lêlê sibayî (n)",
    "y": " gün ağarması"
  },
  {
    "x": "lêlê sipêdeyî (n)",
    "y": " gün ağarması"
  },
  {
    "x": "lêlê şewdirî (n)",
    "y": " gün ağarması"
  },
  {
    "x": "lêlê şodirî (n)",
    "y": " gün ağarması"
  },
  {
    "x": "lêlaw (m)",
    "y": " b. lêlawe (m)"
  },
  {
    "x": "lêlawê çimû (m)",
    "y": " b. lêlawa çiman (m)"
  },
  {
    "x": "lêlawê çimûn (m)",
    "y": " b. lêlawa çiman (m)"
  },
  {
    "x": "lêlawe (m)",
    "y": " rahatsızlıktan dolayı akan gözyaşı"
  },
  {
    "x": "lêlawa çiman (m)",
    "y": " rahatsızlıktan dolayı akan gözyaşı"
  },
  {
    "x": "lêlawî (m)",
    "y": " bulanık su"
  },
  {
    "x": "lêlîye (m)",
    "y": " bulanıklık"
  },
  {
    "x": "lêm-I(n)",
    "y": " kir"
  },
  {
    "x": "lêm û lêş",
    "y": " b. lêm û lêşe "
  },
  {
    "x": "lêm û lêşe",
    "y": " kir pas"
  },
  {
    "x": "lêm û lêşike (m)",
    "y": " kir pas"
  },
  {
    "x": "lêm û lext (n)",
    "y": " b. lêm û lexte (n)"
  },
  {
    "x": "lêm û lexte (n)",
    "y": " kir pas"
  },
  {
    "x": "lêmê sibayî (n)",
    "y": " alacakaranlık"
  },
  {
    "x": "lêm-II (n)",
    "y": " b. rêm (n)"
  },
  {
    "x": "lêmin, -e",
    "y": " kirli"
  },
  {
    "x": "lêmin bîyene",
    "y": " kirlenmek"
  },
  {
    "x": "lêmin kerdene",
    "y": " kirletmek"
  },
  {
    "x": "lêmin û lêşikin, -e",
    "y": " kirli"
  },
  {
    "x": "lêmin û lêşin, -e",
    "y": " kirli"
  },
  {
    "x": "lêminbîyayîş (n)",
    "y": " kirlenme"
  },
  {
    "x": "lêminey (m)",
    "y": " b. lêminî (m)"
  },
  {
    "x": "lêminê (m)",
    "y": " b. lêminî (m)"
  },
  {
    "x": "lêminî (m)",
    "y": " kirlilik"
  },
  {
    "x": "lêminkerdis (n)",
    "y": " b. lêminkerdiş (n)"
  },
  {
    "x": "lêminkerdiş (n)",
    "y": " kirletme"
  },
  {
    "x": "lêmun (m)",
    "y": " b. leymune (m)"
  },
  {
    "x": "lêmuni (m)",
    "y": " b. leymune (m)"
  },
  {
    "x": "lêmûn (m)",
    "y": " b. leymune (m)"
  },
  {
    "x": "lêmûr (n)",
    "y": " çözülmüş kir (yıkanan şeyin kiri)"
  },
  {
    "x": "lên (n)",
    "y": " 1)kazan\r\n~2)pekmez dolusu kazan (bir pekmez ölçüsü)"
  },
  {
    "x": "lêr (n)",
    "y": " b. leyîr (n)"
  },
  {
    "x": "lêro",
    "y": " b. leyro"
  },
  {
    "x": "lêr bîyayîne",
    "y": " b. lêr bîyene"
  },
  {
    "x": "lêr bîyene",
    "y": " yuvarlanmak"
  },
  {
    "x": "lêr kerdene",
    "y": " yuvarlamak"
  },
  {
    "x": "lêrek, -i",
    "y": " b. leyrek, -e"
  },
  {
    "x": "lêş (n)",
    "y": " kir"
  },
  {
    "x": "lêşikin, -e",
    "y": " kirli"
  },
  {
    "x": "lêşin, -e",
    "y": " kirli"
  },
  {
    "x": "lêşin bîyene",
    "y": " kirlenmek"
  },
  {
    "x": "lêşin kerdene",
    "y": " kirletmek"
  },
  {
    "x": "lêşinbîyayîş  (n)",
    "y": " kirlenme "
  },
  {
    "x": "lêşiney (m)",
    "y": " b. lêşinî (m)"
  },
  {
    "x": "lêşinî (m)",
    "y": " kirlilik "
  },
  {
    "x": "lêşinkerdiş (n)",
    "y": " kirletme"
  },
  {
    "x": "lêşker, -e",
    "y": " kirletici"
  },
  {
    "x": "lêşkin, -e",
    "y": " salyalı"
  },
  {
    "x": "lêwe-I (n)",
    "y": " b. lewe-II(n)"
  },
  {
    "x": "lêwe-II (n)",
    "y": " b. ley-I (n)"
  },
  {
    "x": "lêwîyayêne",
    "y": " b. lewîyayene"
  },
  {
    "x": "lêwîyayîş (n)",
    "y": " b. lewîyayîş (n)"
  },
  {
    "x": "lêy (m)",
    "y": " b. leye (m)"
  },
  {
    "x": "lêy rûnayîne",
    "y": " b. leye ronayene"
  },
  {
    "x": "lêyer, -i",
    "y": " b. leyîr, -e"
  },
  {
    "x": "lêyerek, -i",
    "y": " b. leyrek, -e"
  },
  {
    "x": "lêyêr, -i",
    "y": " b. leyîr, -e"
  },
  {
    "x": "lêyêrek, -i",
    "y": " b. leyrek, -e"
  },
  {
    "x": "lêyîm (n)",
    "y": " b. lehîm (n)"
  },
  {
    "x": "lêyîmçî, -ye",
    "y": " b. lehîmcî, -ye"
  },
  {
    "x": "lêyîr, -e",
    "y": " b. leyîr, -e"
  },
  {
    "x": "lêym (n)",
    "y": " b. lêm (n)"
  },
  {
    "x": "lêymê sibay (n)",
    "y": " lêmê sibayî (n"
  },
  {
    "x": "lêymê siway",
    "y": " lêmê sibayî (n"
  },
  {
    "x": "lêyrik, -i",
    "y": " b. leyrek, -e"
  },
  {
    "x": "lêyro",
    "y": " b. leyro "
  },
  {
    "x": "lêyş (n)",
    "y": " b. lêş (n)"
  },
  {
    "x": "lêyşin",
    "y": " b. lêşin"
  },
  {
    "x": "lêyşin bîyayene",
    "y": " b. lêşin bîyene"
  },
  {
    "x": "lêyşin kerdene",
    "y": " b. lêşin kerdene"
  },
  {
    "x": "lêyşinbîyayîş  (n)",
    "y": " lêşinbîyayîş  (n) "
  },
  {
    "x": "lêyşinkerdiş (n)",
    "y": " b. lêşinkerdiş (n)"
  },
  {
    "x": "lêzdik (n)",
    "y": " balçık"
  },
  {
    "x": "lêzike (m)",
    "y": " bitkilerin salgıladığı kaygan bir madde, salgı (bitki salgısı)"
  },
  {
    "x": "lêziki-I (m)",
    "y": " b. lêzdik (n)"
  },
  {
    "x": "lêziki-II (m)",
    "y": " b. lêzike (m)"
  },
  {
    "x": "lêzikin, -e",
    "y": " 1)salgılı (bitki için)\r\n~2)cıvık"
  },
  {
    "x": "li (vr)",
    "y": " 1)b. rê-III (pd)\r\n~Ey xo li min nêkerd wayîr./Ey xo mi rê nêkerd wayîr. (O bana sahip çıkmadı.)\r\n~2)b. ro-VII (pd)"
  },
  {
    "x": "libawlib",
    "y": " lewalew, -e"
  },
  {
    "x": "libdi (n)",
    "y": " b. lewde (n)"
  },
  {
    "x": "Licey (m)",
    "y": " b. Licê (m)"
  },
  {
    "x": "Licê (m)",
    "y": " Lice"
  },
  {
    "x": "licini (m)",
    "y": " b. lojine (m)"
  },
  {
    "x": "liheyf (n)",
    "y": " b. lihêf (n)"
  },
  {
    "x": "lihêf (n)",
    "y": " yorgan"
  },
  {
    "x": "lihêf ci kerdene",
    "y": " yorgan dikmek"
  },
  {
    "x": "lihêf degirewtene",
    "y": " yorgan dikmek"
  },
  {
    "x": "lihêf degirotene",
    "y": " b. lihêf degirewtene"
  },
  {
    "x": "lihêf dekerdene",
    "y": " yorgan dikmek"
  },
  {
    "x": "lihêf deştene",
    "y": " yorgan dikmek"
  },
  {
    "x": "lihêf piro dayene",
    "y": " yorgan dikmek"
  },
  {
    "x": "lihêfcikerdiş (n)",
    "y": " yorgan dikme"
  },
  {
    "x": "lihêfdegirewtiş (n)",
    "y": " yorgan dikme"
  },
  {
    "x": "lihêfdegirotiş (n)",
    "y": " b. lihêfdegirewtiş (n)"
  },
  {
    "x": "lihêfdekerdiş (n)",
    "y": " yorgan dikme"
  },
  {
    "x": "lihêfderz, -e",
    "y": " yorgancı"
  },
  {
    "x": "lihêfderzênî (m)",
    "y": " yorgancılık"
  },
  {
    "x": "lihêfderzî (m)",
    "y": " yorgancılık"
  },
  {
    "x": "lihêfderzîye (m)",
    "y": " yorgancılık"
  },
  {
    "x": "lihêfdeştiş (n)",
    "y": " yorgan dikme"
  },
  {
    "x": "lihêfpirodayîş (n)",
    "y": " yorgan dikme   "
  },
  {
    "x": "lihêfroş, -e",
    "y": " yorgancı"
  },
  {
    "x": "lihêfroşênî (m)",
    "y": " yorgancılık"
  },
  {
    "x": "lihêfroşî (m)",
    "y": " yorgancılık"
  },
  {
    "x": "lihêfroşîye (m)",
    "y": " yorgancılık"
  },
  {
    "x": "lihîm (n)",
    "y": " b. lehîm (n)"
  },
  {
    "x": "lihîmcî, -ye",
    "y": " b. lehîmcî, -ye"
  },
  {
    "x": "lihîr (n)",
    "y": " b. leyîr (n)"
  },
  {
    "x": "lihîv (n)",
    "y": " b. lihêf (n)"
  },
  {
    "x": "liîm (n)",
    "y": " b. lehîm (n)"
  },
  {
    "x": "lijina (m)",
    "y": " b. lijna (m)"
  },
  {
    "x": "lijna (m)",
    "y": " evlek "
  },
  {
    "x": "likose (m)",
    "y": " b. lukse (m)"
  },
  {
    "x": "likumîyayene",
    "y": " aksamak, topallamak"
  },
  {
    "x": "likumîyayîş (n)",
    "y": " aksama, topallama"
  },
  {
    "x": "lil (n)",
    "y": " b. lile (m)"
  },
  {
    "x": "lile (m)",
    "y": " oyuncak"
  },
  {
    "x": "lilî (m)",
    "y": " b. lulî (m)"
  },
  {
    "x": "lilîbend, -i",
    "y": " b. lulîbend, -e"
  },
  {
    "x": "limac (n)",
    "y": " b. nimaj (n) "
  },
  {
    "x": "limba (m)",
    "y": " b. lamba (m)"
  },
  {
    "x": "limita (m)",
    "y": " b. nimita (m)"
  },
  {
    "x": "limite (n)",
    "y": " b. nimite (n)"
  },
  {
    "x": "limitene",
    "y": " b. nimitene"
  },
  {
    "x": "limitikî",
    "y": " b. nimitikî"
  },
  {
    "x": "limitiş (n)",
    "y": " b. nimitiş (n)"
  },
  {
    "x": "limitî (m)",
    "y": " b. nimitî (m)"
  },
  {
    "x": "limitîye (m)",
    "y": " b. nimitîye (m)"
  },
  {
    "x": "limnayene",
    "y": " b. nimnayene"
  },
  {
    "x": "limnayîş (n)",
    "y": " b. nimnayîş (n)"
  },
  {
    "x": "limtiş (n)",
    "y": " b. nimitiş (n)"
  },
  {
    "x": "linc (n)",
    "y": " b. lince (m)"
  },
  {
    "x": "lince (m)",
    "y": " çamur"
  },
  {
    "x": "linc û lêz (m)",
    "y": " b. linc û lêze (m)"
  },
  {
    "x": "linc û lêze (m)",
    "y": " cıvık çamur"
  },
  {
    "x": "linc û lêzi (m)",
    "y": " b. linc û lêze (m)"
  },
  {
    "x": "linca vêşî (m)",
    "y": " fazla çamur"
  },
  {
    "x": "linca xame (m)",
    "y": " ham çamur"
  },
  {
    "x": "linci (m)",
    "y": " b. lince (m)"
  },
  {
    "x": "lincî (m)",
    "y": " b. lince (m)"
  },
  {
    "x": "lindan (n)",
    "y": " yüklük, yük"
  },
  {
    "x": "lindon (n)",
    "y": " b. lindan (n)"
  },
  {
    "x": "ling (m)",
    "y": " b. linge (m)"
  },
  {
    "x": "lingon a",
    "y": " b. lingan a"
  },
  {
    "x": "lingûn a",
    "y": " b. lingan a"
  },
  {
    "x": "lingbazin, -e",
    "y": " alaca ayaklı hayvan"
  },
  {
    "x": "linge-I (m)",
    "y": " 1)ayak\r\n~Linga mi mi bin ra şîye. (Ayağım burkuldu.)\r\n~lingan ser o: ayaküstü, ayaküzeri\r\n~2)basamak\r\n~linga nêrdîwane (m):  merdiven basamağı\r\n~3)an, lahza"
  },
  {
    "x": "binraşîyayîşê linge",
    "y": " ayak burkulması"
  },
  {
    "x": "linga estune (m)",
    "y": " sütünun üstüne oturduğu taştan payanda"
  },
  {
    "x": "linga nêrdîwane (m)",
    "y": " merdiven basamağı"
  },
  {
    "x": "linga nêrdîwanî (m)",
    "y": " b. linga nêrdîwane (m)"
  },
  {
    "x": "linga ustine (m)",
    "y": " b. linga estune (m)"
  },
  {
    "x": "lingan a",
    "y": " yürüyerek, yayan"
  },
  {
    "x": "lingan ser o",
    "y": " ayaküstü, ayaküzeri"
  },
  {
    "x": "linge estene ve ...ver",
    "y": " b. linge eştene bi ...ver"
  },
  {
    "x": "linge estene ver",
    "y": " b. linge vistene ver"
  },
  {
    "x": "linge eştene bi ...ver",
    "y": " çelme atmak, çelme takmak "
  },
  {
    "x": "linge eştene era ...ver",
    "y": " çelme atmak, çelme takmak"
  },
  {
    "x": "linge eştene ver",
    "y": " çelme atmak, çelme takmak"
  },
  {
    "x": "linge vistene ver",
    "y": " çelme atmak , çelme takmak"
  },
  {
    "x": "linge şîyene",
    "y": " ayağına kapanmak, ayaklarına kapanmak"
  },
  {
    "x": "lingo ser o",
    "y": " b. lingan ser o"
  },
  {
    "x": "lingon ser o",
    "y": " b. lingan ser o"
  },
  {
    "x": "lingûn ser o",
    "y": " b. lingan ser o"
  },
  {
    "x": "pê lingan",
    "y": " yayan"
  },
  {
    "x": "linge-II (m)",
    "y": " b. nenge (m)"
  },
  {
    "x": "lingegozer, -e",
    "y": " b. linggozer, -e"
  },
  {
    "x": "linggozer, -e",
    "y": " alaca ayaklı hayvan"
  },
  {
    "x": "lingi (m)",
    "y": " b. linge (m)"
  },
  {
    "x": "lingtaban, -e",
    "y": " düztaban"
  },
  {
    "x": "lingtapan, -e ",
    "y": "b. Lingtaban"
  },
  {
    "x": "liqat (n)",
    "y": " b. lekate (n)"
  },
  {
    "x": "liqat kerdene",
    "y": " b. lekate kerdene"
  },
  {
    "x": "liqatkerdiş (n)",
    "y": " b. lekatekerdiş (n)"
  },
  {
    "x": "lisim (n)",
    "y": " (sabanda) saban demirinin içine giren bölüm "
  },
  {
    "x": "liske (m)",
    "y": " b. alişke (m)"
  },
  {
    "x": "liştok, -e",
    "y": " çanak yalayıcı, dalkavuk, yalaka, yaltak"
  },
  {
    "x": "liştokîye (m)",
    "y": " çanak yalayıcılık¸ dalkavukluk, yalakalık¸ yaltaklık"
  },
  {
    "x": "litene",
    "y": " süt emmek"
  },
  {
    "x": "liteni",
    "y": " b. litene"
  },
  {
    "x": "liv (n)",
    "y": " hareket"
  },
  {
    "x": "liva (m)",
    "y": " kuzu yünü"
  },
  {
    "x": "livdar, -e",
    "y": " hareketli"
  },
  {
    "x": "livdarîye (m)",
    "y": " hareketlilik, mobilite"
  },
  {
    "x": "livike (m)",
    "y": " kuzu yünü"
  },
  {
    "x": "livîn (n)",
    "y": " b. nivîne (m)"
  },
  {
    "x": "livîni (m)",
    "y": " b. nivîne (m)"
  },
  {
    "x": "liw (m)",
    "y": " b. luwî (m)"
  },
  {
    "x": "liwa (m)",
    "y": " b. luwa (m)"
  },
  {
    "x": "liwe (m)",
    "y": " b. luwî (m)"
  },
  {
    "x": "liwîyayene",
    "y": " b. Lewîyayene"
  },
  {
    "x": "liwîyayîş (n)",
    "y": " b. lewîyayîş (n)"
  },
  {
    "x": "lixe (n)",
    "y": " lığ, alüvyon"
  },
  {
    "x": "liyîr (n)",
    "y": " b. leyîr (n)"
  },
  {
    "x": "lizg (n)",
    "y": " b. lejge (m)"
  },
  {
    "x": "lizge-I (m)",
    "y": " b. lejge (m)"
  },
  {
    "x": "lizge-II (m)",
    "y": " b. lejg (n)"
  },
  {
    "x": "lizge-III (n)",
    "y": " b. lejg (n)"
  },
  {
    "x": "lizgi (m)",
    "y": " b. lejge (m)"
  },
  {
    "x": "lizna (m)",
    "y": " b. lijna (m)"
  },
  {
    "x": "lî-I (n)",
    "y": " b. lên (n)"
  },
  {
    "x": "lî-II (n)",
    "y": " b. ley-I (n)"
  },
  {
    "x": "lîbas (n)",
    "y": " elbise, giyecek, giysi, kılık"
  },
  {
    "x": "lîbasî (zh)",
    "y": " üst baş (giysiler)"
  },
  {
    "x": "lîbase (m)",
    "y": " b. lîbas (n) "
  },
  {
    "x": "lîberal, -e",
    "y": " liberal"
  },
  {
    "x": "lîberalîst, -e",
    "y": " liberalist"
  },
  {
    "x": "lîberalîstîye (m)",
    "y": " liberalistlik "
  },
  {
    "x": "lîberalîzm (n)",
    "y": " liberalizm"
  },
  {
    "x": "Lîberya (m)",
    "y": " Liberya"
  },
  {
    "x": "Lîbrvîl (n)",
    "y": " Libreville"
  },
  {
    "x": "Lîbya (m)",
    "y": " Libya"
  },
  {
    "x": "lîcnayene",
    "y": " b. nijnayene"
  },
  {
    "x": "lîç (n)",
    "y": " çapak"
  },
  {
    "x": "lîç kerdene",
    "y": " çapaklanmak"
  },
  {
    "x": "lîçe (m)",
    "y": " havuz"
  },
  {
    "x": "lîçik, -e",
    "y": " civciv"
  },
  {
    "x": "lîçik ardene",
    "y": " yavrulamak (yavrusu yumurtadan çıkan hayvanlar için)"
  },
  {
    "x": "lîçik vetene",
    "y": " yavrulamak (yavrusu yumurtadan çıkan hayvanlar için)"
  },
  {
    "x": "lîçiki (m)",
    "y": " b. lîçike (m)"
  },
  {
    "x": "lîçkerdiş (n)",
    "y": " çapaklanma, çapaklanış"
  },
  {
    "x": "lîder, -e",
    "y": " lider, önder"
  },
  {
    "x": "lîf (n)",
    "y": " lif"
  },
  {
    "x": "lîhîr (n)",
    "y": " b. leyîr (n)"
  },
  {
    "x": "lîk (m)",
    "y": " b. lîke (m)"
  },
  {
    "x": "lîke (m)",
    "y": " sümük"
  },
  {
    "x": "lîki (m)",
    "y": " b. lîke (m)"
  },
  {
    "x": "lîkin, -e",
    "y": " sümüklü"
  },
  {
    "x": "lîkor (n)",
    "y": " likör"
  },
  {
    "x": "lîl (n)",
    "y": " b. lêl-II (n)"
  },
  {
    "x": "lîlaw (m)",
    "y": " b. lêlawe (m)"
  },
  {
    "x": "lîlawey çimo (m)",
    "y": " b. lêlawa çiman (m)"
  },
  {
    "x": "lîlawey çimon (m)",
    "y": " b. lêlawa çiman (m)"
  },
  {
    "x": "lîlik-I (n)",
    "y": " sır (eşyanın ön veya arkasına sürülen ince tabaka)\r\n~2)b. neynike (m)"
  },
  {
    "x": "lîlikê astarî (n)",
    "y": " b. lîlikê estareyî (n)"
  },
  {
    "x": "lîlikê estareyî (n)",
    "y": " ateşböceği"
  },
  {
    "x": "lîlik-II (m)",
    "y": " b. lîlike (m)"
  },
  {
    "x": "lîlike (m)",
    "y": " gözbebeği"
  },
  {
    "x": "lîliknayîş (n)",
    "y": " sırlama (sır sürme) "
  },
  {
    "x": "lîlî (m)",
    "y": " b. lulî (m)"
  },
  {
    "x": "Lîlongwe",
    "y": " Lilongwe"
  },
  {
    "x": "lîm-I (n)",
    "y": " b. rêm (n)"
  },
  {
    "x": "lîm bestene",
    "y": " b. rêm bestene"
  },
  {
    "x": "lîm dekewtene",
    "y": " b. rêm dekewtene"
  },
  {
    "x": "lîm-II (n)",
    "y": " b. lêm (n)"
  },
  {
    "x": "lîm û lext (n)",
    "y": " b. lêm û lexte (n)"
  },
  {
    "x": "Lîma (m)",
    "y": " Lima"
  },
  {
    "x": "lîman (n)",
    "y": " liman"
  },
  {
    "x": "lîmin-I (n)",
    "y": " b. rêmin (n)"
  },
  {
    "x": "lîmin-II",
    "y": " b. lêmin, -e"
  },
  {
    "x": "lîmin bîyayene",
    "y": " b. lêmin bîyene"
  },
  {
    "x": "lîmin kerdene",
    "y": " b. lêmin kerdene"
  },
  {
    "x": "lîminbîyayîş (n)",
    "y": " b. lêminbîyayîş (n) "
  },
  {
    "x": "lîminkerdiş (n)",
    "y": " b. lêminkerdiş (n)"
  },
  {
    "x": "lîmît (n)",
    "y": " limit"
  },
  {
    "x": "lîmon (m)",
    "y": " b. leymune (m)"
  },
  {
    "x": "lîmone (m)",
    "y": " b. leymune (m)"
  },
  {
    "x": "lî (n)",
    "y": " b. lên (n)"
  },
  {
    "x": "lînk (n) (TE)",
    "y": " bağ, bağlantı"
  },
  {
    "x": "lîntozik eştene",
    "y": " zıplayıp koşarak tekme atmak (hayvan için)"
  },
  {
    "x": "lîq (n)",
    "y": " 1)sağı, kuş dışkısı, kuş tersi\r\n~2)tavuk dışkısı"
  },
  {
    "x": "lîq kerdene",
    "y": " (kuş, tavuk vb. için) dışkı yapmak"
  },
  {
    "x": "lîr, -i",
    "y": " b. leyîr, -e"
  },
  {
    "x": "lîr vetene",
    "y": " b. leyîr vetene"
  },
  {
    "x": "lîr kerdene",
    "y": " b. lêr kerdene"
  },
  {
    "x": "lîra (m)",
    "y": " lira"
  },
  {
    "x": "lîs (n)",
    "y": " tünek"
  },
  {
    "x": "lîs bîyene",
    "y": " tüneklemek, tünemek "
  },
  {
    "x": "lîsans (n) (TE)",
    "y": " lisans"
  },
  {
    "x": "lîsbîyayîş (n)",
    "y": " tünekleme, tüneme"
  },
  {
    "x": "lîse (m)",
    "y": " lise"
  },
  {
    "x": "lîste (m)",
    "y": " liste"
  },
  {
    "x": "lîsteya fîyetî ya otêle (m)",
    "y": " otel fiyat listesi"
  },
  {
    "x": "Lîsteya Mîrasî ya Cîhanî (m)",
    "y": " Dünya Miras Listesi"
  },
  {
    "x": "Lîsteya Mîrasî ya Dinya (m)",
    "y": " Dünya Miras Listesi"
  },
  {
    "x": "lîsteya şawitîyan (m)",
    "y": " irsaliye"
  },
  {
    "x": "lîsteya vaya ya otêle (m)",
    "y": " otel fiyat listesi"
  },
  {
    "x": "lîş-I (n)",
    "y": " b. lîç (n)"
  },
  {
    "x": "lîş kerdene",
    "y": " b. lîç kerdene"
  },
  {
    "x": "lîş-II (n)",
    "y": " b. lêş (n)"
  },
  {
    "x": "lîşi",
    "y": " b. lêşin"
  },
  {
    "x": "lîşin, -e",
    "y": " b. lêşin"
  },
  {
    "x": "lîşin bîyayene",
    "y": " b. lêşin bîyene"
  },
  {
    "x": "lîşin kerdene",
    "y": " b. lêşin kerdene"
  },
  {
    "x": "lîşinbîyayîş  (n)",
    "y": " lêşinbîyayîş  (n)  "
  },
  {
    "x": "lîşinî (m)",
    "y": " b. lêşinî (m)"
  },
  {
    "x": "lîşinkerdiş (n)",
    "y": " b. lêşinkerdiş (n"
  },
  {
    "x": "lîtene",
    "y": " b. litene"
  },
  {
    "x": "lîter (n)",
    "y": " 1)sekiz okka\r\n~2)b. lître (n)"
  },
  {
    "x": "lîteratur (n)",
    "y": " alanyazın"
  },
  {
    "x": "lîtosfer (n)",
    "y": " litosfer"
  },
  {
    "x": "lîtozik (n)",
    "y": " b. loqtîzik (n)"
  },
  {
    "x": "lître (n)",
    "y": " litre"
  },
  {
    "x": "lîtreyî (s)",
    "y": " litrelik"
  },
  {
    "x": "bîdono şeş lîtreyî",
    "y": " altı litrelik bidon"
  },
  {
    "x": "lîtro (n)",
    "y": " b. lître (n)"
  },
  {
    "x": "Lîtvanya (m)",
    "y": " Litvanya"
  },
  {
    "x": "Lîxtinştayn (n)",
    "y": " Liechtenstein "
  },
  {
    "x": "lîye (n)",
    "y": " b. lên (n)"
  },
  {
    "x": "lîyeçeg (m)",
    "y": " b. leçege (m)"
  },
  {
    "x": "lîyen (n)",
    "y": " b. lên (n)"
  },
  {
    "x": "lîyir (n)",
    "y": " b. leyîr, -e"
  },
  {
    "x": "lîz (n)",
    "y": " b. zîl (n)"
  },
  {
    "x": "lîz dayene",
    "y": " b. zîl dayene"
  },
  {
    "x": "Lîzbon (n)",
    "y": " Lizbon"
  },
  {
    "x": "lo (n)",
    "y": " b. lew (n)"
  },
  {
    "x": "lobet (n)",
    "y": " oyuncak"
  },
  {
    "x": "lobî-I (m)",
    "y": " fasulye"
  },
  {
    "x": "lobî-II (m)",
    "y": " lobi"
  },
  {
    "x": "lobîst, -e",
    "y": " lobici"
  },
  {
    "x": "lobîye (m)",
    "y": " b. lobî (m)"
  },
  {
    "x": "locine (m)",
    "y": " b. lojine (m)"
  },
  {
    "x": "locini (m)",
    "y": " b. lojine (m)"
  },
  {
    "x": "lod (m)",
    "y": " b. lode (m)"
  },
  {
    "x": "lod kerdene",
    "y": " b. lode kerdene"
  },
  {
    "x": "loda (m)",
    "y": " b. lode (m)"
  },
  {
    "x": "loda kerdene",
    "y": " b. lode kerdene"
  },
  {
    "x": "loday vaş (m)",
    "y": " b. loda vaşî (m)"
  },
  {
    "x": "loday velg (m)",
    "y": " b. loda velgî (m)"
  },
  {
    "x": "lode-I (m)",
    "y": " istif, yığın, loda, stok (yığılım, yığımlık)"
  },
  {
    "x": "loda vaşî (m)",
    "y": " hayvanlara kışın yedirilmek üzere kesilip üst üste dizilen büyük ot yığını"
  },
  {
    "x": "loda velgî (m)",
    "y": " hayvanlara kışın yedirilmek üzere kesilip üst üste dizilen büyük yaprak yığını"
  },
  {
    "x": "loda velgî ronayene",
    "y": " hayvanların yemesi için kışlık yaprak istiflemek"
  },
  {
    "x": "lode kerdene",
    "y": " istiflemek, istif etmek, yığmak, loda etmek"
  },
  {
    "x": "lodê vaşî (m)",
    "y": " b. loda vaşî (m)"
  },
  {
    "x": "lodê velgî (m)",
    "y": " b. loda velgî (m)"
  },
  {
    "x": "lode-II (n)",
    "y": " b. lewde (n)"
  },
  {
    "x": "lodi (m)",
    "y": " b. lode (m)"
  },
  {
    "x": "lodike (m)",
    "y": " istif, yığın"
  },
  {
    "x": "lojine (m)",
    "y": " baca, (baca ile birlikte) ocak"
  },
  {
    "x": "lojini (m)",
    "y": " b. lojine (m)"
  },
  {
    "x": "lok-I (n)",
    "y": " erkek deve, lök, buğra, buğur"
  },
  {
    "x": "lok-II (n)",
    "y": " naylon"
  },
  {
    "x": "Lok (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "lokawt (n)",
    "y": " iş bıraktırımı, lokavt"
  },
  {
    "x": "loke-I (m)",
    "y": " tarla veya arazinin başka tarla veya araziler arasında kalan küçük ve dar bölümü"
  },
  {
    "x": "loke-II (n)",
    "y": " b. lok-I (n)"
  },
  {
    "x": "loki (m)",
    "y": " b. loke-I (m)"
  },
  {
    "x": "lokise (m)",
    "y": " b. lukse (m)"
  },
  {
    "x": "lokize (m)",
    "y": " b. lukse (m)"
  },
  {
    "x": "lokomotîfe (m)",
    "y": " lokomotif"
  },
  {
    "x": "lokose (m)",
    "y": " b. lukse (m)"
  },
  {
    "x": "lola binê adirî (m)",
    "y": " kömbe (küle gömülerek pişirilen mayasız çörek)"
  },
  {
    "x": "lola tepsî (m)",
    "y": " bir çeşit börek"
  },
  {
    "x": "lola tewqe (m)",
    "y": " gözleme, bazlama (bir hamur işi)"
  },
  {
    "x": "lola zerî (m)",
    "y": " bir çeşit kömbe"
  },
  {
    "x": "lolê tepsî (m)",
    "y": " b. lola tepsî (m)"
  },
  {
    "x": "lolik (n)",
    "y": " b. lulik (n)"
  },
  {
    "x": "lolike (m)",
    "y": " domates"
  },
  {
    "x": "lom (n)",
    "y": " b. mîl-I (n)"
  },
  {
    "x": "lomba (m)",
    "y": " b. lamba (m)"
  },
  {
    "x": "Lome",
    "y": " Lomé"
  },
  {
    "x": "lompa (m)",
    "y": " b. lamba (m)"
  },
  {
    "x": "Londra (m)",
    "y": " Londra"
  },
  {
    "x": "londe (m)",
    "y": " b. lode (m)"
  },
  {
    "x": "londe kerdene",
    "y": " b. lode kerdene"
  },
  {
    "x": "londê vaşî (m)",
    "y": " b. loda vaşî (m)"
  },
  {
    "x": "londê velgî (m)",
    "y": " b. loda velgî (m)"
  },
  {
    "x": "lone-I (n)",
    "y": " b. lehne (n), kelem (n)"
  },
  {
    "x": "lone-II (m)",
    "y": " b. lane (m)"
  },
  {
    "x": "lona darloxî (m)",
    "y": " b. lana darloxî (m)"
  },
  {
    "x": "loneki (m)",
    "y": " b. lanike (m)"
  },
  {
    "x": "loni (m)",
    "y": " b. lane-I (m)"
  },
  {
    "x": "loni ra",
    "y": " b. lane ra"
  },
  {
    "x": "lonike (m)",
    "y": " b. lanike (m)"
  },
  {
    "x": "lonî, -ye",
    "y": " b. lanî, -ye"
  },
  {
    "x": "loop (n)",
    "y": " loop"
  },
  {
    "x": "lop (n)",
    "y": " b. lepe (m)"
  },
  {
    "x": "lope (m)",
    "y": " b. lep (n)"
  },
  {
    "x": "lopik (n)",
    "y": " b. lepik (n)"
  },
  {
    "x": "loq-I (n)",
    "y": " çifte (at, eşek vb. tekmesi)"
  },
  {
    "x": "loq eştene",
    "y": " çifte atmak"
  },
  {
    "x": "loq eyştene",
    "y": " b. loq eştene"
  },
  {
    "x": "loq vistene",
    "y": " b. loq eştene"
  },
  {
    "x": "loq-loq vazdayene",
    "y": " (binek hayvanı için) dörtnala olmayan biçimde koşmak"
  },
  {
    "x": "loq-loq xar dayene",
    "y": " (binek hayvanı için) dörtnala olmayan biçimde koşturmak"
  },
  {
    "x": "loq-loqvazdayîş (n)",
    "y": " (binek hayvanı için) dörtnala olmayan biçimde koşma"
  },
  {
    "x": "loq-II (m)",
    "y": " b. loxe (m)"
  },
  {
    "x": "loqanta (m)",
    "y": " b. loqante (n)"
  },
  {
    "x": "loqante (n)",
    "y": " lokanta, restoran, aşevi, aşhane"
  },
  {
    "x": "loqantedar, -e",
    "y": " lokantacı"
  },
  {
    "x": "loqantedarî (m)",
    "y": " lokantacılık"
  },
  {
    "x": "loqantedarîye (m)",
    "y": " lokantacılık"
  },
  {
    "x": "loqela (m)",
    "y": " b. roqila (m) "
  },
  {
    "x": "loqela barîye (m)",
    "y": " b. roqilaya barîye (m)"
  },
  {
    "x": "loqelaya nakî (n)",
    "y": " b. roqilaya nakî (m)"
  },
  {
    "x": "loqente (n)",
    "y": " b. loqante (n)"
  },
  {
    "x": "loqera (m)",
    "y": " b. roqila (m)"
  },
  {
    "x": "loqeraya nakî (n)",
    "y": " b. roqilaya nakî (m)"
  },
  {
    "x": "loqerey dekerdey (zh)",
    "y": " b. roqileyê dekerdeyî (zh)"
  },
  {
    "x": "loqila (m)",
    "y": " b. roqila (m) "
  },
  {
    "x": "loqim (n)",
    "y": " b. loqum (n)"
  },
  {
    "x": "loqimotîfi (m)",
    "y": " b. lokomotîfe (m)"
  },
  {
    "x": "loqimte (n)",
    "y": " b. loqante (n)"
  },
  {
    "x": "loqla (m)",
    "y": " b. roqila (m) "
  },
  {
    "x": "loqlaya nakî (n)",
    "y": " b. roqilaya nakî (m)"
  },
  {
    "x": "loqle (m)",
    "y": " b. roqila (m) "
  },
  {
    "x": "loqley dekerdey (zh)",
    "y": " b. roqileyê dekerdeyî (zh)"
  },
  {
    "x": "loqma (m)",
    "y": " b. loqme (n)"
  },
  {
    "x": "loqme (n)",
    "y": " lokma"
  },
  {
    "x": "loqman dima bîyene",
    "y": " yemek peşinde olmak (çıkar peşinde olmak)"
  },
  {
    "x": "loqman gêrayene",
    "y": " yemek peşinde olmak (çıkar peşinde olmak)"
  },
  {
    "x": "loqmey geyrayene",
    "y": " b. loqman gêrayene"
  },
  {
    "x": "loqmon geyrayene",
    "y": " b. loqman gêrayene"
  },
  {
    "x": "loqmûn dima bîyayene",
    "y": " b. loqman dima bîyene"
  },
  {
    "x": "loqomotîfe (m)",
    "y": " b. lokomotîfe (m)"
  },
  {
    "x": "loqomotîfi (m)",
    "y": " b. lokomotîfe (m)"
  },
  {
    "x": "loqra (m)",
    "y": " b. roqila (m)"
  },
  {
    "x": "loqraya nakî (n)",
    "y": " b. roqilaya nakî (m)"
  },
  {
    "x": "loqrey dekerdey (zh)",
    "y": " b. roqileyê dekerdeyî (zh)"
  },
  {
    "x": "loqtîzek (n)",
    "y": " b. loqtîzik (n)"
  },
  {
    "x": "loqtîzek eyştiş",
    "y": " b. loqtîzik eştene"
  },
  {
    "x": "loqtîzik (n)",
    "y": " hayvanın zıplayıp koşarak attığı tekme"
  },
  {
    "x": "loqtîzik eştene",
    "y": " zıplayıp koşarak tekme atmak (hayvan için)  "
  },
  {
    "x": "loqtîzik eyştene",
    "y": " b. loqtîzik eştene"
  },
  {
    "x": "loqtîzik vistene",
    "y": " b. loqtîzik eştene"
  },
  {
    "x": "loqum (n)",
    "y": " lokum"
  },
  {
    "x": "loqûnte (n)",
    "y": " b. loqante (n)"
  },
  {
    "x": "lore-I (m)",
    "y": " tarlafaresi"
  },
  {
    "x": "lore-II (m)",
    "y": " topaç"
  },
  {
    "x": "lore-III",
    "y": " b. leyro"
  },
  {
    "x": "lorek, -e",
    "y": " b. lorik, -e"
  },
  {
    "x": "lori-I (m)",
    "y": " b. lore-I (m)"
  },
  {
    "x": "lori-II (m)",
    "y": " b. lore-II (m)"
  },
  {
    "x": "lorik, -e",
    "y": " çocuk"
  },
  {
    "x": "lorik bîyene",
    "y": " çocuklaşmak"
  },
  {
    "x": "lorikbîyayîş (n)",
    "y": " çocuklaşma"
  },
  {
    "x": "lorike (m)",
    "y": " ağıt"
  },
  {
    "x": "lorikvaj, -e",
    "y": " ağıtçı, ağlayıcı, sagucu"
  },
  {
    "x": "lornayene",
    "y": " ağıt yakmak, ağıt düzmek, ağıt tutturmak"
  },
  {
    "x": "lorna",
    "y": " ağıt yaktı"
  },
  {
    "x": "lortaqe (m)",
    "y": " bir çeşit gözleme"
  },
  {
    "x": "lortik",
    "y": " b. lortike (m)"
  },
  {
    "x": "lortik eştene",
    "y": " b. lortike eştene"
  },
  {
    "x": "lortike (m)",
    "y": " hayvanın zıplayıp koşarak attığı tekme"
  },
  {
    "x": "lortike eştene",
    "y": " zıplayıp koşarak tekme atmak (hayvan için)  "
  },
  {
    "x": "lost kerdene",
    "y": " başıboş dolaşmak"
  },
  {
    "x": "lot kerdene",
    "y": " b. lebitnayene"
  },
  {
    "x": "lot kerdiş (n)",
    "y": " b. lebitnayîş (n)"
  },
  {
    "x": "lotik eştene",
    "y": " zıplayıp koşarak tekme atmak (hayvan için)  "
  },
  {
    "x": "lotîzik (n)",
    "y": " b. loqtîzik (n)"
  },
  {
    "x": "lotîzik eştene",
    "y": " b. loqtîzik eştene"
  },
  {
    "x": "loverradaya (m)",
    "y": " b. lewveradaya (m)"
  },
  {
    "x": "loverradaye (n)",
    "y": " b. lewveradaye (n)"
  },
  {
    "x": "loverradayîye (m)",
    "y": " b. lewveradayîye (m)"
  },
  {
    "x": "lovik (m)",
    "y": " b. lobî (m)"
  },
  {
    "x": "lovlova (m)",
    "y": " b. leblebî (m)"
  },
  {
    "x": "low-I (n)",
    "y": " b. lew (n)"
  },
  {
    "x": "low-II (n)",
    "y": " b. ley-I (n)"
  },
  {
    "x": "lowayene",
    "y": " b. lawayene"
  },
  {
    "x": "lowayêne",
    "y": " b. lawayene"
  },
  {
    "x": "lowayîne",
    "y": " b. lawayene"
  },
  {
    "x": "lowayîs (n)",
    "y": " b. lawayîş (n)"
  },
  {
    "x": "lowayîş (n)",
    "y": " b. lawayîş (n)"
  },
  {
    "x": "lowi (n)",
    "y": " b. ley-I (n)"
  },
  {
    "x": "lowik (m)",
    "y": " b. lobî (m)"
  },
  {
    "x": "lowike-I (m)",
    "y": " b. lobî (m)"
  },
  {
    "x": "lowike-II (m)",
    "y": " b. lawike (m)"
  },
  {
    "x": "lowî (m)",
    "y": " b. lobî (m)"
  },
  {
    "x": "lownayîne",
    "y": " b. lawnayene"
  },
  {
    "x": "lownayîş (n)",
    "y": " b. lawnayîş (n)"
  },
  {
    "x": "lowtene",
    "y": " b. litene"
  },
  {
    "x": "lowverradaya (m)",
    "y": " b. lewveradaya (m)"
  },
  {
    "x": "lowverradaye (n)",
    "y": " b. lewveradaye (n)"
  },
  {
    "x": "lowverradayîye (m)",
    "y": " b. lewveradayîye (m)"
  },
  {
    "x": "lox (m)",
    "y": " b. loxe (m)"
  },
  {
    "x": "loxe-I (m)",
    "y": " yuvak, loğ, yuvgu"
  },
  {
    "x": "loxa cî (m)",
    "y": " b. loxa gîyî (m)"
  },
  {
    "x": "loxa gîyî (m)",
    "y": " bokböceği, pislik böceği, hapböceği"
  },
  {
    "x": "loxe-II (m)",
    "y": " tarlafaresi"
  },
  {
    "x": "loxa kore (m)",
    "y": " körfare"
  },
  {
    "x": "loxi (m)",
    "y": " b. loxe (m) "
  },
  {
    "x": "loxlî (n)",
    "y": " bir tabanca çeşidi"
  },
  {
    "x": "loxme (n)",
    "y": " b. loqme (n)"
  },
  {
    "x": "loxman dima bîyayene",
    "y": " b. loqman dima bîyene"
  },
  {
    "x": "loy-I (n)",
    "y": " b. ley-I (n)"
  },
  {
    "x": "loy-I (m)",
    "y": " b. lewe-I (m)"
  },
  {
    "x": "lozine (m)",
    "y": " b. lojine (m)"
  },
  {
    "x": "lozing (m)",
    "y": " b. lojine (m)"
  },
  {
    "x": "lozinge (m)",
    "y": " b. lojine (m)"
  },
  {
    "x": "lu (m)",
    "y": " b. luwî (m)"
  },
  {
    "x": "luacin (m)",
    "y": " b. lojine (m) "
  },
  {
    "x": "luap (m)",
    "y": " b. lep (n)"
  },
  {
    "x": "Lubnan (n)",
    "y": " Lübnan "
  },
  {
    "x": "lucina (m)",
    "y": " b. lijna (m)"
  },
  {
    "x": "lue (m)",
    "y": " b. luwî (m)"
  },
  {
    "x": "luebî (m)",
    "y": " b. lobî (m)"
  },
  {
    "x": "luecin (m)",
    "y": " b. lojine (m) "
  },
  {
    "x": "luejin (m)",
    "y": " b. lojine (m) "
  },
  {
    "x": "luek (n)",
    "y": " b. loke-I (m)"
  },
  {
    "x": "luela binî adirî (m)",
    "y": " b. lola binê adirî (m)"
  },
  {
    "x": "luela toqi (m)",
    "y": " b. lola tewqe (m)"
  },
  {
    "x": "luelê binî adirî (m)",
    "y": " b. lola binê adirî (m)"
  },
  {
    "x": "luelê tepsî (m)",
    "y": " b. lola tepsî (m)"
  },
  {
    "x": "luelê zerî (m)",
    "y": " b. lola zerî (m)"
  },
  {
    "x": "lueq (n)",
    "y": " b. loq (n)"
  },
  {
    "x": "lueq-lueq vazdayene",
    "y": " b. loq-loq vazdayene"
  },
  {
    "x": "lueq-lueqvazdayîş (n)",
    "y": " b. loq-loqvazdayîş (n)"
  },
  {
    "x": "lueqila (m)",
    "y": " b. roqila (m) "
  },
  {
    "x": "lueqla (m)",
    "y": " b. roqila (m) "
  },
  {
    "x": "lueqma (m)",
    "y": " b. loqme (n)"
  },
  {
    "x": "lueqmi (n)",
    "y": " b. loqme (n)"
  },
  {
    "x": "luex (m)",
    "y": " b. loxe (m)"
  },
  {
    "x": "luêbî (m)",
    "y": " b. lobî (m)"
  },
  {
    "x": "luh (n)",
    "y": " bir bölümü toprağa gömülü olan büyük taş"
  },
  {
    "x": "lujina (m)",
    "y": " b. lijna (m)"
  },
  {
    "x": "lujini (m)",
    "y": " b. lojine (m)"
  },
  {
    "x": "lujna (m)",
    "y": " b. lijna (m)"
  },
  {
    "x": "luk (n)",
    "y": " b. lok (n)"
  },
  {
    "x": "lukis (n)",
    "y": " b. lukse (m)"
  },
  {
    "x": "lukise (m)",
    "y": " b. lukse (m)"
  },
  {
    "x": "lukse (m)",
    "y": " lüks (lamba)"
  },
  {
    "x": "Luksembûrg (n)",
    "y": " Lüksemburg"
  },
  {
    "x": "lukus (n)",
    "y": " b. lukse (m)"
  },
  {
    "x": "lul (n)",
    "y": " aşı (bitki için)"
  },
  {
    "x": "lul kerdene",
    "y": " aşılamak (bitki için)"
  },
  {
    "x": "lula (m)",
    "y": " b. lulî (m)"
  },
  {
    "x": "lulbend, -i",
    "y": " b. lulîbend, -e"
  },
  {
    "x": "lulbîyayîş (n)",
    "y": " aşılanma (bitki için)"
  },
  {
    "x": "lule (n)",
    "y": " b. lulî-1 (m)"
  },
  {
    "x": "luleyê qalorîferî (n)",
    "y": " b. lulîya kalorîferî (m)"
  },
  {
    "x": "lulê binî adirî (m)",
    "y": " b. lola binê adirî (m)"
  },
  {
    "x": "lulê toqi (m)",
    "y": " b. lola tewqe (m)"
  },
  {
    "x": "lulibend, -i",
    "y": " b. lulîbend, -e"
  },
  {
    "x": "lulik (n)",
    "y": " 1)böcek\r\n~2)bir tırtıl çeşidi"
  },
  {
    "x": "lulik û tulik",
    "y": " börtü böcek"
  },
  {
    "x": "lulikê cixara (n)",
    "y": " ağızlık"
  },
  {
    "x": "lulikê oxirî (n)",
    "y": " uğurböceği"
  },
  {
    "x": "lulikê silî (n)",
    "y": " bokböceği, pislik böceği, hapböceği"
  },
  {
    "x": "lulî (m)",
    "y": " 1)boru\r\n~2)kaval"
  },
  {
    "x": "lulîya awa pake (m)",
    "y": " temiz su borusu"
  },
  {
    "x": "lulîya awa pîse (m)",
    "y": " pis su borusu"
  },
  {
    "x": "lulîya kalorîferî (m)",
    "y": " kalorifer borusu"
  },
  {
    "x": "lulîya qalorîferî (m)",
    "y": " b. lulîya kalorîferî (m)"
  },
  {
    "x": "lulîya soba (m)",
    "y": " soba borusu"
  },
  {
    "x": "lulîya zendine (m)",
    "y": " dirsekli boru"
  },
  {
    "x": "lulîbend, -e",
    "y": " kavalcı (kaval çalan)"
  },
  {
    "x": "lulîye (m)",
    "y": " boru"
  },
  {
    "x": "lulkerda",
    "y": " aşılı (bitki için)"
  },
  {
    "x": "lulkerdiş (n)",
    "y": " aşılama (bitki için)"
  },
  {
    "x": "lulkerdî (m)",
    "y": " aşılı (bitki için)"
  },
  {
    "x": "lulkerdîye (m)",
    "y": " aşılı (bitki için)"
  },
  {
    "x": "lulu (m)",
    "y": " (çocuk dilinde) böcek"
  },
  {
    "x": "luluk (n)",
    "y": " b. lulik (n)"
  },
  {
    "x": "lumpen (n)",
    "y": " ayaktakımı, lümpen, parya"
  },
  {
    "x": "lur-I (n)",
    "y": " b. leyîr (n)"
  },
  {
    "x": "lur-II (n)",
    "y": " b. rulo (n)"
  },
  {
    "x": "lur kerdene",
    "y": " b. rulo kerdene"
  },
  {
    "x": "lutene",
    "y": " b. litene"
  },
  {
    "x": "lutfen",
    "y": " lütfen"
  },
  {
    "x": "luw (m)",
    "y": " b. luwî (m)"
  },
  {
    "x": "luwa (m)",
    "y": " başörtü, başörtüsü"
  },
  {
    "x": "luwayeni",
    "y": " b. lewîyayene"
  },
  {
    "x": "luwayîş (n)",
    "y": " b. lewîyayîş (n)"
  },
  {
    "x": "luwe (m)",
    "y": " b. luwî (m)"
  },
  {
    "x": "luwela toqi (m)",
    "y": " b. lola tewqe (m)"
  },
  {
    "x": "luwelê binî adirî (m)",
    "y": " b. lola binê adirî (m)"
  },
  {
    "x": "luwelê tepsî (m)",
    "y": " b. lola tepsî (m)"
  },
  {
    "x": "luwelê toq (m)",
    "y": " b. lola tewqe (m)"
  },
  {
    "x": "luwelê zerî (m)",
    "y": " b. lola zerî (m)"
  },
  {
    "x": "luwî, -ye",
    "y": " tilki"
  },
  {
    "x": "luy (m)",
    "y": " b. luwî (m)"
  },
  {
    "x": "luye (m)",
    "y": " b. luwî (m)"
  },
  {
    "x": "luyi (m)",
    "y": " b. luwî (m)"
  },
  {
    "x": "luzum (n)",
    "y": " icap"
  },
  {
    "x": "lû (m)",
    "y": " b. luwî (m)"
  },
  {
    "x": "Lûanda (m)",
    "y": " Luanda"
  },
  {
    "x": "lûbî (m)",
    "y": " b. lobî (m)"
  },
  {
    "x": "lûe (m)",
    "y": " b. luwî (m)"
  },
  {
    "x": "lûfik (n)",
    "y": " b. lîf (n)"
  },
  {
    "x": "lûk-I (n)",
    "y": " b. lok-I (n)"
  },
  {
    "x": "lûk-II (n)",
    "y": " b. lok-II (n)"
  },
  {
    "x": "lûlbend, -i",
    "y": " b. lulîbend, -e"
  },
  {
    "x": "lûle (n)",
    "y": " b. lulî-1 (m)"
  },
  {
    "x": "lûleyê qalorîferî (n)",
    "y": " b. lulîya kalorîferî (m)"
  },
  {
    "x": "lûlî (m)",
    "y": " b. lulî (m)"
  },
  {
    "x": "lûmba (m)",
    "y": " b. lamba (m)"
  },
  {
    "x": "lûmbe (n)",
    "y": " b. lamba (m)"
  },
  {
    "x": "lûn (m)",
    "y": " b. lane-I (m)"
  },
  {
    "x": "lûn ra",
    "y": " b. lane ra"
  },
  {
    "x": "lûne (n)",
    "y": " b. lehne (n), kelem (n)"
  },
  {
    "x": "lûnik (m)",
    "y": " b. lanike (m)"
  },
  {
    "x": "lûnî, -ye",
    "y": " b. lanî, -ye"
  },
  {
    "x": "lûqimte (n)",
    "y": " b. loqante (n)"
  },
  {
    "x": "lûqme (n)",
    "y": " b. loqme (n)"
  },
  {
    "x": "lûqûnte (n)",
    "y": " b. loqante (n)"
  },
  {
    "x": "lûqra (m)",
    "y": " b. roqila (m)"
  },
  {
    "x": "Lûsaka (m)",
    "y": " Lusaka"
  },
  {
    "x": "lûw (m)",
    "y": " b. luwî (m)"
  },
  {
    "x": "lûwî (m)",
    "y": " b. luwî (m)"
  },
  {
    "x": "lûxme (n)",
    "y": " b. loqme (n)"
  },
  {
    "x": "lûxmey geyrayene",
    "y": " b. loqman gêrayene"
  },
  {
    "x": "lûxmûn geyrayene",
    "y": " b. loqman gêrayene"
  },
  {
    "x": "lûye (m)",
    "y": " b. luwî (m)"
  },
  {
    "x": "lwî (m)",
    "y": " b. luwî (m)"
  },
  {
    "x": "Lyublyana (m)",
    "y": " Ljubljana"
  },
  {
    "x": "lyulî (m)",
    "y": " b. lulî (m)"
  },
  {
    "x": "lyulîbend (n)",
    "y": " b. lulîbend, -e"
  },
  {
    "x": "MMM"
  },
  {
    "x": "ma-I (zh, z)",
    "y": " biz"
  },
  {
    "x": "ma ra başe (m)",
    "y": " cin"
  },
  {
    "x": "ma ra başî (zh)",
    "y": " cinler"
  },
  {
    "x": "ma-II",
    "y": " Soru formunda kullanılırken fiilin başına gelen bir soru sözcüğü."
  },
  {
    "x": "ma-III (m)",
    "y": " b. maye-I (m)"
  },
  {
    "x": "ma û pî",
    "y": " b. maye û pî "
  },
  {
    "x": "ma wû pî",
    "y": " b. maye û pî"
  },
  {
    "x": "mamaran (m)",
    "y": " b. maymaran (m)"
  },
  {
    "x": "ma-IV (m)",
    "y": " ay, yılın on iki bölümünden her biri\r\n~Yew ma mende. (Bir ay kaldı.) \r\n~\t\r\n~Yû ma wo ez nêşîyane. (Ben gitmeyeli bir ay oldu.)"
  },
  {
    "x": "ma bixeyr dî",
    "y": " merhaba"
  },
  {
    "x": "ma bixêr",
    "y": " b. ma bixeyr dî"
  },
  {
    "x": "ma bixêr dî",
    "y": " b. ma bixeyr dî"
  },
  {
    "x": "ma vexer",
    "y": " b. ma bixeyr dî"
  },
  {
    "x": "ma vexer dî",
    "y": " b. ma bixeyr dî"
  },
  {
    "x": "ma vexêr dî",
    "y": " b. ma bixeyr dî"
  },
  {
    "x": "maaşe (m)",
    "y": " b. meaş (n)"
  },
  {
    "x": "maaşi (m)",
    "y": " b. meaş (n)"
  },
  {
    "x": "maben (n)",
    "y": " b. mabên (n)"
  },
  {
    "x": "mabeyn (n)",
    "y": " b. mabên (n)"
  },
  {
    "x": "mabeyn herimîyayene",
    "y": " b. mabên herimîyayene"
  },
  {
    "x": "mabeyn xeripîyayene",
    "y": " b. mabên xeripîyayene"
  },
  {
    "x": "mabeyn xirab bîyene",
    "y": " b. mabên xirab bîyene"
  },
  {
    "x": "mabeynherimîyayîş (n)",
    "y": " b. mabênherimîyayîş (n)"
  },
  {
    "x": "mabeynxeripîyayîş (n)",
    "y": " b. mabênxeripîyayîş (n)"
  },
  {
    "x": "mabeynxirabbîyayîş (n)",
    "y": " b. mabênxirabbîyayîş (n)"
  },
  {
    "x": "mabên (n)",
    "y": " ara, aralık, mola, teneffüs\r\n~Mabênê Zerweş û Roşanî weş o. (Zerweş ile Roşan’ın arası iyidir.)"
  },
  {
    "x": "mabên dayene",
    "y": " ara vermek, teneffüse çıkmak"
  },
  {
    "x": "mabên heremîyayene",
    "y": " b. mabên herimîyayene"
  },
  {
    "x": "mabên herimîyayene",
    "y": " bozuşmak, araları açılmak"
  },
  {
    "x": "mabên herimnayene",
    "y": " arayı açmak (görüşmemek)"
  },
  {
    "x": "mabên xerepîyayene",
    "y": " b. mabên xeripîyayene"
  },
  {
    "x": "mabên xeripîyayene",
    "y": " bozuşmak, araları açılmak"
  },
  {
    "x": "mabên xirab bîyene",
    "y": " bozuşmak, araları açılmak"
  },
  {
    "x": "mabên xirab kerdene",
    "y": " arayı açmak (görüşmemek)"
  },
  {
    "x": "mabênçî, -ye",
    "y": " 1)aracı, arabulucu\r\n~2)çöpçatan (evlenmeler için)"
  },
  {
    "x": "mabênçîyîye (m)",
    "y": " 1)arabuluculuk\r\n~2)çöpçatanlık"
  },
  {
    "x": "mabênheremîyayîş (n)",
    "y": " b. mabênherimîyayîş (n)"
  },
  {
    "x": "mabênherimîyayîş (n)",
    "y": " bozuşma"
  },
  {
    "x": "mabênherimnayox, -e",
    "y": " ara bozucu, arabozan, fesat, fesatçı, fitne, fitneci¸ müfsit¸ nifakçı"
  },
  {
    "x": "mabênkar, -e",
    "y": " aracı"
  },
  {
    "x": "mabênkarîye (m)",
    "y": " aracılık"
  },
  {
    "x": "mabênxerepîyayîş (n)",
    "y": " b. mabênxeripîyayîş (n)"
  },
  {
    "x": "mabênxeripîyayîş (n)",
    "y": " bozuşma"
  },
  {
    "x": "mabênxeripnayox, -e",
    "y": " ara bozucu, arabozan, fesat, fesatçı, fitne, fitneci, müfsit, nifakçı"
  },
  {
    "x": "mabênxirabbîyayîş (n)",
    "y": " bozuşma"
  },
  {
    "x": "mabîn (n)",
    "y": " b. mabên (n)"
  },
  {
    "x": "Macarîstan (n)",
    "y": " Macaristan"
  },
  {
    "x": "macir, -e",
    "y": " göçmen, muhacir"
  },
  {
    "x": "macirey (m)",
    "y": " b. macirîye (m)"
  },
  {
    "x": "macirê (m)",
    "y": " b. macirîye (m)"
  },
  {
    "x": "macirîye (m)",
    "y": " göçmenlik, muhacirlik"
  },
  {
    "x": "macîr, -i",
    "y": " b. macir, -e"
  },
  {
    "x": "macîrey (m)",
    "y": " b. macirîye (m)"
  },
  {
    "x": "macîrê (m)",
    "y": " b. macirîye (m)"
  },
  {
    "x": "macîrîye (m)",
    "y": " b. macirîye (m)"
  },
  {
    "x": "macun (n)",
    "y": " macun"
  },
  {
    "x": "macunê didanan (n)",
    "y": " diş macunu"
  },
  {
    "x": "macunê dindanan (n)",
    "y": " b. macunê didanan (n)"
  },
  {
    "x": "macûnî dindûnûn (n)",
    "y": " b. macunê didanan (n)"
  },
  {
    "x": "macur, -e",
    "y": " b. macir, -e"
  },
  {
    "x": "macûn (n)",
    "y": " b. macun (n)"
  },
  {
    "x": "maç-I (n)",
    "y": " sabanın saban demirine yakın kısmı ile saban okunu birbirine bağlayan küçük parçası"
  },
  {
    "x": "maç-II (m)",
    "y": " b. maçî (m)"
  },
  {
    "x": "maç kerdene",
    "y": " b. maçî kerdene"
  },
  {
    "x": "maç-III (n)",
    "y": " maç"
  },
  {
    "x": "maçî (m)",
    "y": " öpücük"
  },
  {
    "x": "maçî kerdene",
    "y": " öpmek"
  },
  {
    "x": "maçîna (m)",
    "y": " b. makîna (m)"
  },
  {
    "x": "mad (n)",
    "y": " b. made (n)"
  },
  {
    "x": "mad birîyayene",
    "y": " b. made birîyayene"
  },
  {
    "x": "mad gîrîyayene",
    "y": " b. made gîrîyayene"
  },
  {
    "x": "mad gîyrîyayene",
    "y": " b. made gîrîyayene"
  },
  {
    "x": "mad qelbîyayeni",
    "y": " b. made qelibîyayene"
  },
  {
    "x": "mad qelbnayeni",
    "y": " b. made qelibnayene"
  },
  {
    "x": "madê ... birîyayene",
    "y": " b. madeyê ... birîyayene"
  },
  {
    "x": "madê ... gîrîyayene",
    "y": " b. madeyê ... gîrîyayene"
  },
  {
    "x": "madê ... gîyrîyayene",
    "y": " b. madeyê ... gîrîyayene"
  },
  {
    "x": "madag (m)",
    "y": " b. medege (m)"
  },
  {
    "x": "Madagaskar (n)",
    "y": " Madagaskar"
  },
  {
    "x": "madak (m)",
    "y": " b. medege (m)"
  },
  {
    "x": "madalyon (n)",
    "y": " madalyon "
  },
  {
    "x": "madda (m)",
    "y": " madde (bölüm)"
  },
  {
    "x": "madde (m)",
    "y": " b. madda (m)"
  },
  {
    "x": "made-I (n)",
    "y": " madde (nesne)"
  },
  {
    "x": "madeyê toksîkî (zh)",
    "y": " toksik maddeler"
  },
  {
    "x": "madeyê weşaynayîşî (n)",
    "y": " söndürme maddesi"
  },
  {
    "x": "madeyê xidayan (n)",
    "y": " gıda maddesi"
  },
  {
    "x": "madeyo beyaxkî (n)",
    "y": " yabancı madde"
  },
  {
    "x": "madeyo feydedar (n)",
    "y": " faydalı madde, yararlı madde"
  },
  {
    "x": "madeyê hewnakerdişî (n)",
    "y": " söndürme maddesi"
  },
  {
    "x": "madeyo huşk (n)",
    "y": " katı madde, kuru madde"
  },
  {
    "x": "madeyo kardar (n)",
    "y": " faydalı madde, yararlı madde"
  },
  {
    "x": "madeyê mirênayîşî (n)",
    "y": " söndürme maddesi"
  },
  {
    "x": "madeyo organîk (n)",
    "y": " organik madde"
  },
  {
    "x": "madeyo tehlukeyin (n)",
    "y": " tehlikeli madde"
  },
  {
    "x": "madeyo teqebar (n)",
    "y": " patlayıcı madde"
  },
  {
    "x": "madeyo xerîb (n)",
    "y": " yabancı madde"
  },
  {
    "x": "madeyo xeternak (n)",
    "y": " tehlikeli madde"
  },
  {
    "x": "madeyo zerardar (n)",
    "y": " zararlı madde"
  },
  {
    "x": "madeyo zîyandar (n)",
    "y": " zararlı madde"
  },
  {
    "x": "made-II (n)",
    "y": " 1)mide\r\n~2)iştah\r\n~Madeyê mi çin o.(İştahım yok.) \r\n~\t\r\n~Madeyê mi ha ca de yo. (İştahım yerinde.)"
  },
  {
    "x": "made birîyayene",
    "y": " iştah kapanmak"
  },
  {
    "x": "made gîrîyayene",
    "y": " iştah kapanmak"
  },
  {
    "x": "made qelbnayeni",
    "y": " b. made qelibnayene"
  },
  {
    "x": "made qelbyayeni",
    "y": " b. made qelibîyayene"
  },
  {
    "x": "made qelebîyayene",
    "y": " b. made qelibîyayene"
  },
  {
    "x": "made qelebnayene",
    "y": " b. made qelibnayene"
  },
  {
    "x": "made qelibîyayene",
    "y": " mide(si) bulanmak "
  },
  {
    "x": "made qelibîyayeni",
    "y": " b. made qelibîyayene"
  },
  {
    "x": "made qelibnayene",
    "y": " mide bulandırmak"
  },
  {
    "x": "made qelibnayeni",
    "y": " b. made qelibnayene"
  },
  {
    "x": "made qelivnayene",
    "y": " b. made qelibnayene"
  },
  {
    "x": "made têdayene",
    "y": " b. made têwdayene"
  },
  {
    "x": "made têdîyayene",
    "y": " b. made têwdîyayene"
  },
  {
    "x": "made têwdayene",
    "y": " mide bulandırmak"
  },
  {
    "x": "made têwdîyayene",
    "y": " mide(si) bulanmak"
  },
  {
    "x": "madeyê ... birîyayene",
    "y": " iştahı kapanmak, iştahı kesilmek"
  },
  {
    "x": "madeyê ... gîrîyayene",
    "y": " iştahı kapanmak, iştahı kesilmek"
  },
  {
    "x": "madeyê ... şîyene",
    "y": " canı istemek, canı çekmek"
  },
  {
    "x": "madeyê ...  ... waştene",
    "y": " canı istemek, canı çekmek"
  },
  {
    "x": "made-III (m)",
    "y": " b. madda (m)"
  },
  {
    "x": "maden (n)",
    "y": " 1)maden\r\n~2)metal"
  },
  {
    "x": "Maden (n)",
    "y": " Maden"
  },
  {
    "x": "madendar, -e",
    "y": " madenci (maden işleten, madencilikle uğraşan kimse)"
  },
  {
    "x": "madendarî (m)",
    "y": " madencilik"
  },
  {
    "x": "madendarîye (m)",
    "y": " madencilik"
  },
  {
    "x": "madenî, -ye",
    "y": " 1)madeni, madensel\r\n~2)metalik"
  },
  {
    "x": "madenkar, -e",
    "y": " madenci (maden ocaklarında çalışan işçi)"
  },
  {
    "x": "madenkarî (m)",
    "y": " madencilik"
  },
  {
    "x": "madenkarîye (m)",
    "y": " madencilik"
  },
  {
    "x": "madeqelbnayîş (n)",
    "y": " b. madeqelibnayîş (n)"
  },
  {
    "x": "madeqelbyayîş (n)",
    "y": " b. madeqelibîyayîş (n)"
  },
  {
    "x": "madeqelebîyayîş (n)",
    "y": " b. madeqelibîyayîş (n)"
  },
  {
    "x": "madeqelebnayîş (n)",
    "y": " b. madeqelibnayîş (n)"
  },
  {
    "x": "madeqelibîyayîş (n)",
    "y": " mide(si) bulanma"
  },
  {
    "x": "madeqelibnayîş (n)",
    "y": " mide bulandırma"
  },
  {
    "x": "madeqelivnayîş (n)",
    "y": " b. madeqelibnayîş (n)"
  },
  {
    "x": "maderşahî, -ye",
    "y": " anaerkil"
  },
  {
    "x": "maderşahîye (m)",
    "y": " anaerkillik"
  },
  {
    "x": "madetêdayîş (n)",
    "y": " b. madetêwdayîş (n)"
  },
  {
    "x": "madetêdîyayîş (n)",
    "y": " b. madetêwdîyayîş (n)"
  },
  {
    "x": "madetêwdayîş (n)",
    "y": " mide bulandırma"
  },
  {
    "x": "madetêwdîyayîş (n)",
    "y": " mide(si) bulanma, bulantı"
  },
  {
    "x": "madqelbîyayîş (n)",
    "y": " b. madeqelibîyayîş (n)"
  },
  {
    "x": "madqelbnayîş (n)",
    "y": " b. madeqelibnayîş (n)"
  },
  {
    "x": "Madrîd (n)",
    "y": " Madrid"
  },
  {
    "x": "maeş (m)",
    "y": " b. meaş (n)"
  },
  {
    "x": "mafa (m)",
    "y": " tabut"
  },
  {
    "x": "mafaza kerdene",
    "y": " b. muhafeze kerdene"
  },
  {
    "x": "mafazakerdiş (n)",
    "y": " muhafezekerdiş (n)"
  },
  {
    "x": "mafîat (n)",
    "y": " b. menfeet (n)"
  },
  {
    "x": "mahdeqelibîyayîş (n)",
    "y": " b. madeqelibîyayîş (n)"
  },
  {
    "x": "mahdenqelibîyayîş (n)",
    "y": " b. madeqelibîyayîş (n)"
  },
  {
    "x": "mahfeze kerdene",
    "y": " b. muhafeze kerdene"
  },
  {
    "x": "mahfezekerdiş (n)",
    "y": " muhafezekerdiş (n)"
  },
  {
    "x": "mahfize kerdene",
    "y": " b. muhafeze kerdene"
  },
  {
    "x": "mahfizekerdiş (n)",
    "y": " muhafezekerdiş (n)"
  },
  {
    "x": "mahîne (m)",
    "y": " dişi at, kısrak"
  },
  {
    "x": "mahîni (m)",
    "y": " b. mahîne (m)"
  },
  {
    "x": "mahkama (m)",
    "y": " b. mehkeme (m)"
  },
  {
    "x": "mahkima (m)",
    "y": " b. mehkeme (m)"
  },
  {
    "x": "mahlem (m)",
    "y": " b. malêbe (m)"
  },
  {
    "x": "mahre (n)",
    "y": " b. mare (n)"
  },
  {
    "x": "mahre betal bîyayene",
    "y": " b. mare betal bîyene"
  },
  {
    "x": "mahre betal kerdene",
    "y": " b. mare betal kerdene"
  },
  {
    "x": "mahre birnayene",
    "y": " b. mare birnayene"
  },
  {
    "x": "mahre bîyayene",
    "y": " b. mare bîyene"
  },
  {
    "x": "mahre eyştene ser",
    "y": " (kadın nikahlamak) mare eştene ser"
  },
  {
    "x": "mahre kerdene",
    "y": " b. mare kerdene"
  },
  {
    "x": "mahre ra",
    "y": " b. mare ra"
  },
  {
    "x": "mahrebir, -e",
    "y": " b. marebir, -e"
  },
  {
    "x": "mahrebîyayîş (n)",
    "y": " b. marebîyayîş (n)"
  },
  {
    "x": "mahrekerdiş (n)",
    "y": " b. marekerdiş (n)"
  },
  {
    "x": "mahresereyştiş (n)",
    "y": " b. (kadın nikahlama) maresereştiş (n)"
  },
  {
    "x": "mahri (n)",
    "y": " b. mare (n)"
  },
  {
    "x": "mahri birnayene",
    "y": " b. mare birnayene"
  },
  {
    "x": "mahrikerdiş (n)",
    "y": " b. marekerdiş (n)"
  },
  {
    "x": "mahser (n)",
    "y": " b. mahsere (n)"
  },
  {
    "x": "mahsere (n)",
    "y": " 1)üzümün sıkılıp şırasının çıkarıldığı yer\r\n~2)pekmez dolusu kazan (bir pekmez ölçüsü)"
  },
  {
    "x": "mahseran ra ameyene war",
    "y": " bağbozumunun sona ermesi"
  },
  {
    "x": "mahseran ser ra ameyene war",
    "y": " bağbozumunun sona ermesi"
  },
  {
    "x": "mahserey (zh)",
    "y": " b. mahsereyî (zh)"
  },
  {
    "x": "mahsereyî (zh)",
    "y": " bağbozumu"
  },
  {
    "x": "mahsire (n)",
    "y": " b. mahsere (n)"
  },
  {
    "x": "mahsul (n)",
    "y": " b. mehsul (n)"
  },
  {
    "x": "mahşi (m)",
    "y": " b. meaş (n)"
  },
  {
    "x": "mahşlêre (m)",
    "y": " dalları çardak üzerine yayılan asma "
  },
  {
    "x": "mahşlêri (m)",
    "y": " b. mahşlêre (m)"
  },
  {
    "x": "mahteb (n)",
    "y": " b. mifte (n)"
  },
  {
    "x": "mahtef (n)",
    "y": " b. mifte (n)"
  },
  {
    "x": "maj (n)",
    "y": " b. maç (n)"
  },
  {
    "x": "mak (m)",
    "y": " b. make-II (m)"
  },
  {
    "x": "make-I (m)",
    "y": " 1)ana arı\r\n~2)ebe (oyunda ebe olan kişi)\r\n~3)b. makî (m)"
  },
  {
    "x": "make-II (m)",
    "y": " düğme (giysi için)"
  },
  {
    "x": "make devistene/girewtene/qefilnayene/tavistene",
    "y": " düğme iliklemek"
  },
  {
    "x": "Makedonya (m)",
    "y": " Makedonya"
  },
  {
    "x": "makeme (n)",
    "y": " b. mehkeme (m)"
  },
  {
    "x": "maker (m)",
    "y": " b. mankere (m)"
  },
  {
    "x": "makere (m)",
    "y": " b. mankere (m)"
  },
  {
    "x": "maket (n)",
    "y": " maket"
  },
  {
    "x": "maketîst, -e",
    "y": " maketist"
  },
  {
    "x": "maketviraştox, -e",
    "y": " maketist"
  },
  {
    "x": "maki-I (m)",
    "y": " b. make-I (m) "
  },
  {
    "x": "maki-II (m)",
    "y": " b. make-II (m)"
  },
  {
    "x": "makima (m)",
    "y": " b. mehkeme (m)"
  },
  {
    "x": "makî (m)",
    "y": " 1)dişi\r\n~2)dişil"
  },
  {
    "x": "makîna (m)",
    "y": " makine\r\n~makînaya çekemî (m): ağda makinası"
  },
  {
    "x": "makînaya axda (m)",
    "y": " ağda makinası"
  },
  {
    "x": "makînaya cilşutişî (m)",
    "y": " çamaşır makinesi"
  },
  {
    "x": "makînaya cuwenî (m)",
    "y": " harman makinesi, batöz"
  },
  {
    "x": "makînaya çekemî (m)",
    "y": " ağda makinası"
  },
  {
    "x": "makînaya deştene (m)",
    "y": " dikiş makinesi"
  },
  {
    "x": "makînaya deştişî (m)",
    "y": " dikiş makinesi"
  },
  {
    "x": "makînaya firaqan (m)",
    "y": " bulaşık makinesi"
  },
  {
    "x": "makînaya gijikziwakerdişî (m)",
    "y": " saç kurutma makinesi"
  },
  {
    "x": "makînaya hesabî (m)",
    "y": " hesap makinesi"
  },
  {
    "x": "makînaya karî (m)",
    "y": " iş makinesi"
  },
  {
    "x": "makînaya kincşutişî (m)",
    "y": " çamaşır makinesi"
  },
  {
    "x": "makînaya porziwakerdişî (m)",
    "y": " saç kurutma makinesi"
  },
  {
    "x": "makînaya posî (m)",
    "y": " pos makinası"
  },
  {
    "x": "makînaya qeweyî (m)",
    "y": " kahve makinesi"
  },
  {
    "x": "makînaya taştişî (m)",
    "y": " tıraş makinesi"
  },
  {
    "x": "makînaya terdişî (m)",
    "y": " tıraş makinesi"
  },
  {
    "x": "makînaya tiraşî (m)",
    "y": " tıraş makinesi"
  },
  {
    "x": "makînaya tostî (m)",
    "y": " tost makinesi"
  },
  {
    "x": "makînê ciwenî (m)",
    "y": " b. makînaya cuwenî (m)"
  },
  {
    "x": "makîne (n)",
    "y": " b. makîna (m)"
  },
  {
    "x": "makînîst, -e",
    "y": " makinist"
  },
  {
    "x": "makînîstî (m)",
    "y": " makinistlik"
  },
  {
    "x": "makînîstîye (m)",
    "y": " makinistlik"
  },
  {
    "x": "makyaj (n)",
    "y": " makyaj "
  },
  {
    "x": "makyaj kerdene",
    "y": " makyaj yapmak"
  },
  {
    "x": "makyajin, -e",
    "y": " makyajlı"
  },
  {
    "x": "makyajker (n)",
    "y": " makyör"
  },
  {
    "x": "makyajkerda (m)",
    "y": " makyajlı"
  },
  {
    "x": "makyajkerde (n)",
    "y": " makyajlı"
  },
  {
    "x": "makyajkerdiş (n)",
    "y": " makyaj yapma"
  },
  {
    "x": "makyajkerdîye (m)",
    "y": " makyajlı"
  },
  {
    "x": "makyajkerdox, -e",
    "y": " makyajcı"
  },
  {
    "x": "makyajkere (m)",
    "y": " makyöz"
  },
  {
    "x": "makyor (n)",
    "y": " makyör"
  },
  {
    "x": "makyoze (m)",
    "y": " makyöz"
  },
  {
    "x": "mal (n)",
    "y": " 1)mal \r\n~2)davar, küçükbaş \r\n~3)küçükbaş hayvan sürüsü"
  },
  {
    "x": "mal û melal",
    "y": " b. mal û milal"
  },
  {
    "x": "mal û milal",
    "y": " 1)çoluk çocuk\r\n~2)mal mülk"
  },
  {
    "x": "mal û milk",
    "y": " mal mülk"
  },
  {
    "x": "mal û mul",
    "y": " b. mal û milk"
  },
  {
    "x": "malê pêroyîyî (zh)",
    "y": " kamu malları"
  },
  {
    "x": "malê umûmîya (zh)",
    "y": " kamu malları"
  },
  {
    "x": "malê xezîna (m)",
    "y": " hazine malı"
  },
  {
    "x": "mal-melal (n)",
    "y": " b. mal-milal (n)"
  },
  {
    "x": "mal-milal (n)",
    "y": " mal mülk"
  },
  {
    "x": "mal-milk (n)",
    "y": " mal mülk"
  },
  {
    "x": "mal û ga",
    "y": " b. mal û gayî"
  },
  {
    "x": "mal û gayî",
    "y": " davar ve sığır"
  },
  {
    "x": "malo berdbar (n)",
    "y": " taşınır mal, menkul mal"
  },
  {
    "x": "malo kiriştbar (n)",
    "y": " taşınır mal, menkul mal"
  },
  {
    "x": "malo neberdbar (n)",
    "y": " gayrimenkul mal, taşınmaz mal"
  },
  {
    "x": "malo nekiriştbar (n)",
    "y": " gayrimenkul mal, taşınmaz mal"
  },
  {
    "x": "malo pes (n)",
    "y": " küçükbaş hayvan sürüsü"
  },
  {
    "x": "malo peskofî (n)",
    "y": " b. malo pezkovî (n)"
  },
  {
    "x": "malo peskovî (n)",
    "y": " b. malo pezkovî (n)"
  },
  {
    "x": "malo peskûfî (n)",
    "y": " b. malo pezkovî (n)"
  },
  {
    "x": "malo pez (n)",
    "y": " b. malo pes (n)"
  },
  {
    "x": "malo pezkovî (n)",
    "y": " yabani küçükbaş hayvanlar (yabankeçileri vb.)"
  },
  {
    "x": "malo sîya (n)",
    "y": " küçükbaş hayvan sürüsü"
  },
  {
    "x": "malo şa (n)",
    "y": " b. malo sîya (n)"
  },
  {
    "x": "malo şîya (n)",
    "y": " b. malo sîya (n)"
  },
  {
    "x": "mal-ga",
    "y": " b. mal-gayî"
  },
  {
    "x": "mal-gayî",
    "y": " b. mal û gayî"
  },
  {
    "x": "mala-I (n)",
    "y": " b. mela-I (n)"
  },
  {
    "x": "mala-II",
    "y": " b. mela-II"
  },
  {
    "x": "mala-III (m)",
    "y": " b. mele (n)"
  },
  {
    "x": "mala-IV (m)",
    "y": " b. taxe (m)"
  },
  {
    "x": "Malabo (n)",
    "y": " Malabo"
  },
  {
    "x": "Malawî",
    "y": " Malawi "
  },
  {
    "x": "malaxme (n)",
    "y": " malama, tınaz"
  },
  {
    "x": "malba (m)",
    "y": " b. marula (m)"
  },
  {
    "x": "malbîyayîş (n)",
    "y": " maliyet"
  },
  {
    "x": "malbîyayîşê karê caardeyî (n)",
    "y": " gerçekleştirilen işin maliyeti"
  },
  {
    "x": "malbîyayîşê senînîye (n)",
    "y": " kalitenin maliyeti"
  },
  {
    "x": "malbîyayîşê xebitnayîşî (n)",
    "y": " işletme maliyeti"
  },
  {
    "x": "malbîyayîşo devadev (n)",
    "y": " yaklaşık maliyet"
  },
  {
    "x": "malbîyayîşo fekafek (n)",
    "y": " yaklaşık maliyet"
  },
  {
    "x": "malbîyayîşo teqrîbî (n)",
    "y": " yaklaşık maliyet"
  },
  {
    "x": "maldar, -e",
    "y": " zengin, varlıklı\r\n~Ez ne deyndar a ne maldar a. (Ne borçluyum ne zengin.)"
  },
  {
    "x": "maldarey (m)",
    "y": " b. maldarî (m), maldarîye (m)"
  },
  {
    "x": "maldarê (m)",
    "y": " b. maldarî (m), maldarîye (m)"
  },
  {
    "x": "maldarî (m)",
    "y": " zenginlik, varlık, varlıklılık"
  },
  {
    "x": "maldarîye (m)",
    "y": " zenginlik, varlık, varlıklılık"
  },
  {
    "x": "Maldîv (n)",
    "y": " Maldiv Adaları"
  },
  {
    "x": "male-I (m)",
    "y": " b. mele (n)"
  },
  {
    "x": "male-II (n)",
    "y": " b. mela-I (n)"
  },
  {
    "x": "Male",
    "y": " Male"
  },
  {
    "x": "malejî (m)",
    "y": " b. malêze (m)"
  },
  {
    "x": "malem, -i",
    "y": " b. malim, -e"
  },
  {
    "x": "malemey (m)",
    "y": " b. malimîye (m)"
  },
  {
    "x": "malewî (m)",
    "y": " b. marula (m)"
  },
  {
    "x": "Malezya (m)",
    "y": " Malezya"
  },
  {
    "x": "malêbe (m)",
    "y": " yaba"
  },
  {
    "x": "malêbi (m)",
    "y": " b. malêbe (m)"
  },
  {
    "x": "malêjî (m)",
    "y": " b. malêze (m)"
  },
  {
    "x": "malêve (m)",
    "y": " b. malêbe (m)"
  },
  {
    "x": "malêvi (m)",
    "y": " b. malêbe (m)"
  },
  {
    "x": "malêvî (m)",
    "y": " b. malêbe (m)"
  },
  {
    "x": "malêz (m)",
    "y": " b. malêze (m)"
  },
  {
    "x": "malêze (m)",
    "y": " 1)bulamaç (sulu, cıvık hamur)\r\n~2)salya"
  },
  {
    "x": "malêze fek ra şîyene",
    "y": " ağzından salya akmak"
  },
  {
    "x": "malêzi (m)",
    "y": " b. malêze (m)"
  },
  {
    "x": "malêzin, -e",
    "y": " salyalı"
  },
  {
    "x": "malfet (n)",
    "y": " b. merîfet (n)"
  },
  {
    "x": "malik (n)",
    "y": " solucan, yersolucanı, bağırsak solucanı"
  },
  {
    "x": "malim, -e",
    "y": " öğretmen, muallim, hoca"
  },
  {
    "x": "malima dînî (m)",
    "y": " din hocası"
  },
  {
    "x": "malimê dînî (n)",
    "y": " din hocası"
  },
  {
    "x": "malimîye (m)",
    "y": " öğretmenlik, muallimlik"
  },
  {
    "x": "malî",
    "y": " mali"
  },
  {
    "x": "Malî",
    "y": " Mali"
  },
  {
    "x": "malîm, -e",
    "y": " b. malim, -e"
  },
  {
    "x": "malîya (m)",
    "y": " b. malîye (m)"
  },
  {
    "x": "malîye (m)",
    "y": " maliye"
  },
  {
    "x": "malîyem (m)",
    "y": " b. malêbe (m)"
  },
  {
    "x": "malîyet (n)",
    "y": " maliyet"
  },
  {
    "x": "malîyetê karê caardeyî (n)",
    "y": " gerçekleştirilen işin maliyeti"
  },
  {
    "x": "malîyetê senînîye (n)",
    "y": " kalitenin maliyeti"
  },
  {
    "x": "malîyez (m)",
    "y": " b. malêze (m)"
  },
  {
    "x": "malla (n)",
    "y": " b. mela-I (n)"
  },
  {
    "x": "malmalik (m)",
    "y": " b. marula (m) "
  },
  {
    "x": "malmaliki (m)",
    "y": " b. marula (m)"
  },
  {
    "x": "malmalok (m)",
    "y": " b. marula (m)"
  },
  {
    "x": "malmêrat, -e",
    "y": " meret"
  },
  {
    "x": "malmîrat, -i",
    "y": " b. malmêrat, -e"
  },
  {
    "x": "malmolik (m)",
    "y": " b. marula (m) "
  },
  {
    "x": "malole (m)",
    "y": " delikli köfte (ince yarmadan yapılan)"
  },
  {
    "x": "malolike (m)",
    "y": " yoğurtlu köfte (ince yarma, yoğurt ve yağdan yapılan)"
  },
  {
    "x": "malrotiş bi teber",
    "y": " ihraç"
  },
  {
    "x": "Malta (m)",
    "y": " Malta"
  },
  {
    "x": "maltiz (n)",
    "y": " maltız"
  },
  {
    "x": "maluel (n)",
    "y": " b. malole (m)"
  },
  {
    "x": "maluelik (n)",
    "y": " b. malolike (m)"
  },
  {
    "x": "malum, -i",
    "y": " b. malim, -e"
  },
  {
    "x": "malumey (m)",
    "y": " b. malimîye (m)"
  },
  {
    "x": "malumat (n)",
    "y": " b. melumat (n)"
  },
  {
    "x": "malwa (m)",
    "y": " b. marula (m)"
  },
  {
    "x": "malyaxme (n)",
    "y": " b. malaxme (n)"
  },
  {
    "x": "malyez (m)",
    "y": " b. malêze (m)"
  },
  {
    "x": "malzeme (n)",
    "y": " malzeme, gereç, materyal"
  },
  {
    "x": "malzemeyê îzolasyonî (n)",
    "y": " izolasyon malzemesi"
  },
  {
    "x": "malzemeyê makyajî (n)",
    "y": " makyaj malzemesi"
  },
  {
    "x": "malzemeyê serfî (n)",
    "y": " tüketim malzemesi"
  },
  {
    "x": "malzemeyo arêdaye (n)",
    "y": " devşirme malzeme, devşirme malzeme"
  },
  {
    "x": "malzemeyo dîyayîşkî (n)",
    "y": " görsel materyal"
  },
  {
    "x": "malzemeyo lîfin (n)",
    "y": " lifli malzeme"
  },
  {
    "x": "malzemeyo pêserarde (n)",
    "y": " devşirme malzeme"
  },
  {
    "x": "malzemeyo pêt (n)",
    "y": " dayanıklı malzeme"
  },
  {
    "x": "mamaran (m)",
    "y": " b. maymaran (m)"
  },
  {
    "x": "Mamekîye (m)",
    "y": " Tunceli "
  },
  {
    "x": "mamela (m)",
    "y": " b. muamele (m)"
  },
  {
    "x": "mamele (n)",
    "y": " b. muamele (m)"
  },
  {
    "x": "mamile (n)",
    "y": " b. muamele (m)"
  },
  {
    "x": "mamosta (n, m)",
    "y": " öğretmen, muallim, hoca"
  },
  {
    "x": "mamostaya dînî (m)",
    "y": " din hocası"
  },
  {
    "x": "mamostaya kîmya (m)",
    "y": " kimyacı (kimya öğretmeni), kimyager"
  },
  {
    "x": "mamostaya matematîkî (m)",
    "y": " matematikçi (matematik öğretmeni)"
  },
  {
    "x": "mamostaya tarîxî (m)",
    "y": " tarihçi (tarih öğretmeni)"
  },
  {
    "x": "mamostayê dînî (n)",
    "y": " din hocası"
  },
  {
    "x": "mamostayê kîmya (n)",
    "y": " kimyacı (kimya öğretmeni), kimyager"
  },
  {
    "x": "mamostayê matematîkî (n)",
    "y": " matematikçi (matematik öğretmeni)"
  },
  {
    "x": "mamostayê tarîxî (n)",
    "y": " tarihçi (tarih öğretmeni)"
  },
  {
    "x": "mamostayîye (m)",
    "y": " öğretmenlik, muallimlik"
  },
  {
    "x": "mamostayîya tarîxî (m)",
    "y": " tarihçilik (tarih öğretmenliği)"
  },
  {
    "x": "mamul (n)",
    "y": " mamul"
  },
  {
    "x": "mamur, -e",
    "y": " memur"
  },
  {
    "x": "mamura tapûyî (m)",
    "y": " tapu memuru"
  },
  {
    "x": "mamurê tapûyî (n)",
    "y": " tapu memuru"
  },
  {
    "x": "mamurey (m)",
    "y": " b. mamurîye (m)"
  },
  {
    "x": "mamurê (m)",
    "y": " b. mamurîye (m)"
  },
  {
    "x": "mamurî (m)",
    "y": " memurluk"
  },
  {
    "x": "mamurîye (m)",
    "y": " memurluk"
  },
  {
    "x": "mamut, -e",
    "y": " mamut"
  },
  {
    "x": "mamût, -e",
    "y": " b. mamut, -e"
  },
  {
    "x": "man-I (n)",
    "y": " b. mîyan (n)"
  },
  {
    "x": "man-II (zh, z)",
    "y": " b. ma-I (zh, z)"
  },
  {
    "x": "man-",
    "y": " dişi (bazı hayvanlar için)"
  },
  {
    "x": "mankere (m)",
    "y": " dişi eşek "
  },
  {
    "x": "mana (m)",
    "y": " anlam, mana"
  },
  {
    "x": "mana dayene",
    "y": " anlamlandırmak"
  },
  {
    "x": "mana kerdene",
    "y": " anlamlandırmak"
  },
  {
    "x": "manadar, -e",
    "y": " anlamlı"
  },
  {
    "x": "Managua (m)",
    "y": " Managua"
  },
  {
    "x": "Manama (m)",
    "y": " Manama"
  },
  {
    "x": "mananas, -e",
    "y": " anlam bilimci"
  },
  {
    "x": "mananasî (m)",
    "y": " anlam bilimi"
  },
  {
    "x": "mananaskî, -ye",
    "y": " anlam bilimsel"
  },
  {
    "x": "manav, -e",
    "y": " b. manaw, -e"
  },
  {
    "x": "manaw, -e",
    "y": " manav"
  },
  {
    "x": "manawî (m)",
    "y": " manavlık"
  },
  {
    "x": "manawîye (m)",
    "y": " manavlık"
  },
  {
    "x": "manayî, -ye",
    "y": " anlamsal"
  },
  {
    "x": "manç (n)",
    "y": " b. maç-I (n)"
  },
  {
    "x": "mandalîna (m)",
    "y": " mandalina"
  },
  {
    "x": "mandalînayêre (m)",
    "y": " mandalina ağacı"
  },
  {
    "x": "mande (n)",
    "y": " b. mende (n)"
  },
  {
    "x": "mandelîna (m)",
    "y": " b. mandalîna (m)"
  },
  {
    "x": "mandene",
    "y": " b. mendene"
  },
  {
    "x": "mandiş (n)",
    "y": " b. mendiş (n)"
  },
  {
    "x": "mandîs (n)",
    "y": " b. mendiş (n)"
  },
  {
    "x": "mane-I (n)",
    "y": " b. mana (m)"
  },
  {
    "x": "mane-II (n)",
    "y": " b. mîyane (n) "
  },
  {
    "x": "mane-III (m)",
    "y": " b. mana (m)"
  },
  {
    "x": "mane-IV (n)",
    "y": " bahane"
  },
  {
    "x": "maneyî fetelîyayene",
    "y": " b. maneyî fetilîyayene"
  },
  {
    "x": "mane kerdene",
    "y": " bahane etmek"
  },
  {
    "x": "maneyî fetilîyayene",
    "y": " bahane aramak"
  },
  {
    "x": "maneyî gêrayene",
    "y": " bahane aramak"
  },
  {
    "x": "manela (m)",
    "y": " b. manîla (m)"
  },
  {
    "x": "manelî (m)",
    "y": " b. manîla (m)"
  },
  {
    "x": "manêla (m)",
    "y": " b. manîla (m)"
  },
  {
    "x": "manêlya (m)",
    "y": " b. manîla (m)"
  },
  {
    "x": "manga (m)",
    "y": " inek "
  },
  {
    "x": "mange (m)",
    "y": " b. manga (m)"
  },
  {
    "x": "manike (m)",
    "y": " koyun veya keçi derisinden yapılan torba"
  },
  {
    "x": "maniki (m)",
    "y": " b. manike (m)"
  },
  {
    "x": "manis (n)",
    "y": " b. mansî (zh)"
  },
  {
    "x": "manî (m)",
    "y": " engel, mâni"
  },
  {
    "x": "manî bîyayene",
    "y": " b. manî bîyene "
  },
  {
    "x": "manî bîyene",
    "y": " engel olmak, engellemek, mâni olmak, önlemek"
  },
  {
    "x": "manîbeyîş (n)",
    "y": " b. manîbîyayîş (n)"
  },
  {
    "x": "manîbîyayîş (n)",
    "y": " engel olma, engelleme, mâni olma, önleme"
  },
  {
    "x": "manîfatura (m)",
    "y": " manifatura"
  },
  {
    "x": "manîfaturaçî, -ye",
    "y": " manifaturacı"
  },
  {
    "x": "manîfaturacîyey (m)",
    "y": " b. manîfaturaçîyîye (m), manîfaturaçîyî (m)"
  },
  {
    "x": "manîfaturacîyê (m)",
    "y": " b. manîfaturaçîyîye (m), manîfaturaçîyî (m)"
  },
  {
    "x": "manîfaturaçîyênî (m)",
    "y": " manifaturacılık"
  },
  {
    "x": "manîfaturaçîyî (m)",
    "y": " manifaturacılık"
  },
  {
    "x": "manîfaturaçîyîye (m)",
    "y": " manifaturacılık"
  },
  {
    "x": "manîkur (n)",
    "y": " manikür"
  },
  {
    "x": "manîkurkar, -e",
    "y": " manikürcü"
  },
  {
    "x": "manîla (m)",
    "y": " kaldıraç (demirden olanı), manivela"
  },
  {
    "x": "Manîla (m)",
    "y": " Manila "
  },
  {
    "x": "manîvela (m)",
    "y": " b. manîla (m)"
  },
  {
    "x": "manke (m)",
    "y": " b. manike (m)"
  },
  {
    "x": "manken, -e",
    "y": " manken"
  },
  {
    "x": "mankenî (m)",
    "y": " mankenlik"
  },
  {
    "x": "mankenîye (m)",
    "y": " mankenlik"
  },
  {
    "x": "manker (m)",
    "y": " b. mankere (m) "
  },
  {
    "x": "mankere (m)",
    "y": " dişi eşek "
  },
  {
    "x": "mankeri (m)",
    "y": " b. mankere (m) "
  },
  {
    "x": "manolî (n)",
    "y": " b. manîla (m)"
  },
  {
    "x": "manometre (n)",
    "y": " basıölçer, manometre "
  },
  {
    "x": "manqal (n)",
    "y": " b. manqale (m)"
  },
  {
    "x": "manqale (m)",
    "y": " mangal"
  },
  {
    "x": "mans (n)",
    "y": " b. mansî (zh)"
  },
  {
    "x": "mansî (zh)",
    "y": " don (uzun kadın donu)"
  },
  {
    "x": "manşet (n)",
    "y": " manşet (gazete için)"
  },
  {
    "x": "mantêz (n)",
    "y": " b. maltiz (n)"
  },
  {
    "x": "mantiq (n)",
    "y": " b. mentiq (n)"
  },
  {
    "x": "mantiqî, -ye",
    "y": " b. mentiqî, -ye"
  },
  {
    "x": "mantî (m)",
    "y": " mantı"
  },
  {
    "x": "manto (n)",
    "y": " manto"
  },
  {
    "x": "mantore (n)",
    "y": " b. mature (n)"
  },
  {
    "x": "mantoreyê (m)",
    "y": " b. matureyîye (m)"
  },
  {
    "x": "manuel, -e",
    "y": " manuel"
  },
  {
    "x": "manuel xebitîyayîş",
    "y": " manuel [olarak] çalışma"
  },
  {
    "x": "manyetîk, -e",
    "y": " manyetik"
  },
  {
    "x": "Mapûto (n)",
    "y": " Maputo"
  },
  {
    "x": "maqarna (m)",
    "y": " b. meqerne (n)"
  },
  {
    "x": "maqsat (n)",
    "y": " b. meqsed (n)"
  },
  {
    "x": "maqul, -e",
    "y": " akıllıca, akilane, akla uygun, akli, rasyonel"
  },
  {
    "x": "maqyaj (n)",
    "y": " b. makyaj (n)"
  },
  {
    "x": "mar, -e-I",
    "y": " 1)yılan\r\n~2)çıyan (mecazî)"
  },
  {
    "x": "marê şilîye (n)",
    "y": " solucan, yersolucanı"
  },
  {
    "x": "marê zereyî (n)",
    "y": " şerit, tenya, abdestbozan"
  },
  {
    "x": "marî zerî (n)",
    "y": " b. marê zereyî (n)"
  },
  {
    "x": "marî zerrî (n)",
    "y": " b. marê zereyî (n)"
  },
  {
    "x": "maro awî (n)",
    "y": " suyılanı "
  },
  {
    "x": "mar-II (m)",
    "y": " b. mare (m)"
  },
  {
    "x": "maraton (n)",
    "y": " maraton"
  },
  {
    "x": "marayene",
    "y": " melemek (koyun için)"
  },
  {
    "x": "marayîş (n)",
    "y": " meleme (koyun için)"
  },
  {
    "x": "marcî (m)",
    "y": " b. mercû (m)"
  },
  {
    "x": "marcû (m)",
    "y": " b. mercû (m)"
  },
  {
    "x": "marda (m)",
    "y": " b. merda (m)"
  },
  {
    "x": "mardak (n)",
    "y": " b. martag (n)"
  },
  {
    "x": "marde (n)",
    "y": " b. merde (n)"
  },
  {
    "x": "mardene-I",
    "y": " b. hûmaritene"
  },
  {
    "x": "mardene-II",
    "y": " b. merdene"
  },
  {
    "x": "mardiş-I (n)",
    "y": " b. hûmaritiş (n)"
  },
  {
    "x": "mardiş-II (n)",
    "y": " b. merdiş (n)"
  },
  {
    "x": "mardum (n)",
    "y": " b. merdim (n)"
  },
  {
    "x": "mare-I (m)",
    "y": " anne, ana"
  },
  {
    "x": "marda xo ra bîyene",
    "y": " doğmak"
  },
  {
    "x": "marda xo ra kewtene",
    "y": " doğmak (argo)"
  },
  {
    "x": "marda xu ra bîyayene",
    "y": " b. marda xo ra bîyene"
  },
  {
    "x": "marda xu ra kotene",
    "y": " b. marda xo ra kewtene"
  },
  {
    "x": "mardey xu ra kowtene",
    "y": " b. marda xo ra kewtene"
  },
  {
    "x": "mardê xwe ra kotene",
    "y": " b. marda xo ra kewtene"
  },
  {
    "x": "mare-II (n)",
    "y": " nikâh"
  },
  {
    "x": "mare betal bîyene",
    "y": " nikah bozulmak"
  },
  {
    "x": "mare betal kerdene",
    "y": " nikah bozmak"
  },
  {
    "x": "mare birnayene",
    "y": " nikah kıymak"
  },
  {
    "x": "mare bîyene",
    "y": " nikahlanmak (kadın için)"
  },
  {
    "x": "mare eştene ser",
    "y": " nikâhlamak (kadın nikahlamak)"
  },
  {
    "x": "mare kerdene",
    "y": " nikâhlamak"
  },
  {
    "x": "mare ra",
    "y": " nikahlı"
  },
  {
    "x": "mareyî ser o sonddayene",
    "y": " (birisine) nikahı üzerine yemin içirmek"
  },
  {
    "x": "marî ser o sonddayene",
    "y": " b. mareyî ser o sonddayene"
  },
  {
    "x": "marebir, -e",
    "y": " nikah kıyan, nikah memuru"
  },
  {
    "x": "marebîyayîş (n)",
    "y": " nikahlanma (kadın için) "
  },
  {
    "x": "marekerda (m)",
    "y": " nikahlı"
  },
  {
    "x": "marekerde (n)",
    "y": " nikahlı"
  },
  {
    "x": "marekerdê (m)",
    "y": " b. marekerda (m)"
  },
  {
    "x": "marekerdi (n)",
    "y": " b. marekerde (n)"
  },
  {
    "x": "marekerdî, -ye",
    "y": " nikahlı"
  },
  {
    "x": "marekerdiş (n)",
    "y": " nikâhlama"
  },
  {
    "x": "maresereştiş (n)",
    "y": " nikâhlama (kadın nikahlama)"
  },
  {
    "x": "marey (m)",
    "y": " b. marî (m)"
  },
  {
    "x": "marg (n)",
    "y": " b. merg-I (n)"
  },
  {
    "x": "margê ... omeyene",
    "y": " b. mergê ... ameyene"
  },
  {
    "x": "mari (m)",
    "y": " b. mare-I (m)"
  },
  {
    "x": "mari (n)",
    "y": " b. mare-II (n)"
  },
  {
    "x": "mari betal bîyayene",
    "y": " b. mare betal bîyene"
  },
  {
    "x": "mari betal kerdene",
    "y": " b. mare betal kerdene"
  },
  {
    "x": "mari bîyayene",
    "y": " b. mare bîyene"
  },
  {
    "x": "mari kerdene",
    "y": " b. mare kerdene"
  },
  {
    "x": "mari ra",
    "y": " b. mare ra"
  },
  {
    "x": "mari vistene ser",
    "y": " b. (kadın nikahlamak) mare eştene ser"
  },
  {
    "x": "maribir, -i",
    "y": " b. marebir, -e"
  },
  {
    "x": "maribîyayîş",
    "y": " b. marebîyayîş (n)"
  },
  {
    "x": "marik (n)",
    "y": " böcek"
  },
  {
    "x": "marikerdê (m)",
    "y": " b. marekerda (m)"
  },
  {
    "x": "marikerdi (n)",
    "y": " b. marekerde (n)"
  },
  {
    "x": "marikerdis (n)",
    "y": " b. marekerdiş (n)"
  },
  {
    "x": "marikerdî, -ye",
    "y": " b. marekerdî, -ye"
  },
  {
    "x": "mariservistiş (n)",
    "y": " b. (kadın nikahlama) maresereştiş (n)"
  },
  {
    "x": "maritene",
    "y": " b. hûmaritene"
  },
  {
    "x": "maritiş (n)",
    "y": " b. hûmaritiş (n)"
  },
  {
    "x": "marî-I (m)",
    "y": " analık (ana olma durumu)"
  },
  {
    "x": "marî-II (m)",
    "y": " dişi kuş, dişi keklik"
  },
  {
    "x": "marîye (m)",
    "y": " analık"
  },
  {
    "x": "marj (n)",
    "y": " marj (yazılı sayfa için)"
  },
  {
    "x": "marjî (m)",
    "y": " b. mercû (m)"
  },
  {
    "x": "marjînal, -e",
    "y": " aykırı"
  },
  {
    "x": "marjînalîye (m)",
    "y": " aykırılık"
  },
  {
    "x": "marka (m)",
    "y": " marka"
  },
  {
    "x": "market (m)",
    "y": " b. markete (m)"
  },
  {
    "x": "market (n)",
    "y": " b. markete (m)"
  },
  {
    "x": "markete (m)",
    "y": " market"
  },
  {
    "x": "marketi (m)",
    "y": " b. markete (m)"
  },
  {
    "x": "marmalûk (m)",
    "y": " b. marula (m)"
  },
  {
    "x": "marmaloke (m)",
    "y": " b. marula (m) "
  },
  {
    "x": "marmaloki (m)",
    "y": " b. marula (m) "
  },
  {
    "x": "marmaran (m)",
    "y": " b. maymaran (m) "
  },
  {
    "x": "marmarî (m)",
    "y": " b. maymaran (m) "
  },
  {
    "x": "marmarok (m)",
    "y": " b. maymaran (m)"
  },
  {
    "x": "marmasa (n)",
    "y": " b. marmase (n)"
  },
  {
    "x": "marmase (n)",
    "y": " yılanbalığı"
  },
  {
    "x": "marmasi (n)",
    "y": " b. marmase (n)"
  },
  {
    "x": "marmelad (n)",
    "y": " marmelat"
  },
  {
    "x": "Marok (n)",
    "y": " Fas"
  },
  {
    "x": "marol (n)",
    "y": " b. marul (n)"
  },
  {
    "x": "marqa (m)",
    "y": " b. marka (m)"
  },
  {
    "x": "marqe (n)",
    "y": " b. marka (m)"
  },
  {
    "x": "marrayene",
    "y": " b. marayene"
  },
  {
    "x": "marrayîş (n)",
    "y": " b. marayîş (n)"
  },
  {
    "x": "...marrî",
    "y": " üvey"
  },
  {
    "x": "marsink (n)",
    "y": " b. marşinge (m)"
  },
  {
    "x": "marşing (m)",
    "y": " b. marşinge (m)"
  },
  {
    "x": "marşinge (m)",
    "y": " bir çeşit yelmük (bitkisi)"
  },
  {
    "x": "martag (n)",
    "y": " binanın çatısını kaplamakta kullanılan ince ve kısa ağaç parçaları"
  },
  {
    "x": "martage (m)",
    "y": " mertek, sırık"
  },
  {
    "x": "martal (n)",
    "y": " b. martag (n)"
  },
  {
    "x": "martî, -ye",
    "y": " martı"
  },
  {
    "x": "martîn (n)",
    "y": " b. martînî (n) "
  },
  {
    "x": "martînî (n)",
    "y": " bir tüfek çeşidi"
  },
  {
    "x": "marul (n)",
    "y": " marul"
  },
  {
    "x": "marula (m)",
    "y": " kertenkele"
  },
  {
    "x": "marûmilawinî (zh)",
    "y": " sürüngenler"
  },
  {
    "x": "marûmur (zh)",
    "y": " b. marûmûrî (zh)"
  },
  {
    "x": "marûmûrî (zh)",
    "y": " sürüngenler"
  },
  {
    "x": "marxulî (m)",
    "y": " b. marula (m)"
  },
  {
    "x": "masa-I (m)",
    "y": " masa "
  },
  {
    "x": "masaya werdî (m)",
    "y": " yemek masası"
  },
  {
    "x": "masey werî (n)",
    "y": " b. masaya werdî (m)"
  },
  {
    "x": "masa-II (n)",
    "y": " b. mase-I (n)"
  },
  {
    "x": "masaj (n)",
    "y": " masaj"
  },
  {
    "x": "masajker (n)",
    "y": " masör"
  },
  {
    "x": "masajkere (m)",
    "y": " masöz"
  },
  {
    "x": "masala",
    "y": " b. maşela"
  },
  {
    "x": "masas (m)",
    "y": " b. misasî (m)"
  },
  {
    "x": "masayene",
    "y": " şişmek"
  },
  {
    "x": "mase-I (n)",
    "y": " balık"
  },
  {
    "x": "maseyê heran (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "maseyê pisîngan (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "masê herûn (n)",
    "y": " b. maseyê heran (n)"
  },
  {
    "x": "masê pîsîngûn",
    "y": " b. maseyê pisîngan (n)"
  },
  {
    "x": "mase-II (n)",
    "y": " b. masa-I (m)"
  },
  {
    "x": "masegir, -e",
    "y": " balıkçı"
  },
  {
    "x": "masegirîye (m)",
    "y": " balıkçılık"
  },
  {
    "x": "masere (n)",
    "y": " b. mahsere (n)"
  },
  {
    "x": "maseran ra ameyîne war",
    "y": " b. mahseran ra ameyene war"
  },
  {
    "x": "maseran ser ra ameyîne war",
    "y": " b. mahseran ser ra ameyene war"
  },
  {
    "x": "maseroş, -e",
    "y": " balıkçı"
  },
  {
    "x": "maseroşîye (m)",
    "y": " balıkçılık"
  },
  {
    "x": "maseşimşêr (n)",
    "y": " kılıçbalığı"
  },
  {
    "x": "masevûn (n)",
    "y": " b. masewan, -e"
  },
  {
    "x": "masewan, -e",
    "y": " balıkçı"
  },
  {
    "x": "masewanîye (m)",
    "y": " balıkçılık"
  },
  {
    "x": "masewere (m)",
    "y": " balıkçıl kuşu"
  },
  {
    "x": "maseweri (m)",
    "y": " b. masewere (m)"
  },
  {
    "x": "masexane (n)",
    "y": " balıkhane"
  },
  {
    "x": "masi (n)",
    "y": " b. mase-I (n)"
  },
  {
    "x": "masire (n)",
    "y": " b. mahsere (n)"
  },
  {
    "x": "masî (n)",
    "y": " b. mase-I (n)"
  },
  {
    "x": "masîla (m)",
    "y": " basur"
  },
  {
    "x": "masîla bîyene",
    "y": " basuru olmak"
  },
  {
    "x": "maske (n)",
    "y": " maske, yüz maskesi"
  },
  {
    "x": "masnayene",
    "y": " şişirmek "
  },
  {
    "x": "masor (n)",
    "y": " masör"
  },
  {
    "x": "masoze (m)",
    "y": " masöz"
  },
  {
    "x": "masre (n)",
    "y": " b. mahsere (n)"
  },
  {
    "x": "masref (n)",
    "y": " b. mesref (n)"
  },
  {
    "x": "mast (n)",
    "y": " yoğurt"
  },
  {
    "x": "mastê avrencî (n)",
    "y": " süzme yoğurt"
  },
  {
    "x": "mastê parzunî (n)",
    "y": " süzme yoğurt"
  },
  {
    "x": "mastê pêlekî (n)",
    "y": " süzme yoğurt"
  },
  {
    "x": "mastî pêlekî (n)",
    "y": " b. mastê pêlekî (n)"
  },
  {
    "x": "masto pêlekdekerde (n)",
    "y": " süzme yoğurt"
  },
  {
    "x": "mastawa (m)",
    "y": " b. mastiwa (m)"
  },
  {
    "x": "mastba (m)",
    "y": " b. mastiwa (m)"
  },
  {
    "x": "master (n)",
    "y": " master, yüksek lisans"
  },
  {
    "x": "mastewa (m)",
    "y": " b. mastiwa (m)"
  },
  {
    "x": "mastêr (n)",
    "y": " huni"
  },
  {
    "x": "mastêreke (m)",
    "y": " sarmaşık"
  },
  {
    "x": "mastik (n)",
    "y": " b. mustik (n)"
  },
  {
    "x": "mastiwa (m)",
    "y": " dövme buğday veya darı ile ayran ve kızartılmış yağdan yapılan bir yemek"
  },
  {
    "x": "mastîyer (n)",
    "y": " b. mastêr (n)"
  },
  {
    "x": "mastroş, -e",
    "y": " yoğurtçu "
  },
  {
    "x": "mastroşênî (m)",
    "y": " yoğurtçuluk"
  },
  {
    "x": "mastroşî (m)",
    "y": " yoğurtçuluk"
  },
  {
    "x": "mastroşîye (m)",
    "y": " yoğurtçuluk"
  },
  {
    "x": "mastuare (n)",
    "y": " b. masura (m)"
  },
  {
    "x": "mastuwa",
    "y": " b. mastiwa (m)"
  },
  {
    "x": "mastûyer (n)",
    "y": " b. mastêr (n)"
  },
  {
    "x": "mastyer (n)",
    "y": " b. mastêr (n)"
  },
  {
    "x": "masul (n)",
    "y": " b. mehsul (n)"
  },
  {
    "x": "masulkey (zh)",
    "y": " b. masûlkeyî (zh)"
  },
  {
    "x": "masura (m)",
    "y": " kabartı (vücutta)"
  },
  {
    "x": "masûlkeyî (zh)",
    "y": " kas(lar), adale(ler)"
  },
  {
    "x": "masvûn (n)",
    "y": " b. masewan, -e"
  },
  {
    "x": "maşa (m)",
    "y": " maşa"
  },
  {
    "x": "maşaya şekerî (m)",
    "y": " şeker maşası"
  },
  {
    "x": "maşala",
    "y": " b. maşela"
  },
  {
    "x": "maşale",
    "y": " b. înşalah"
  },
  {
    "x": "maşalê",
    "y": " b. înşalah"
  },
  {
    "x": "maşalla",
    "y": " b. maşela"
  },
  {
    "x": "maşayeke (m)",
    "y": " çift"
  },
  {
    "x": "maşayeka saetkaran (m)",
    "y": " saatçi çifti"
  },
  {
    "x": "maşayeka saetsazan (m)",
    "y": " saatçi çifti"
  },
  {
    "x": "maşayeka zîvkeran (m)",
    "y": " kuyumcu çifti"
  },
  {
    "x": "maşe (n)",
    "y": " b. maşa (m)"
  },
  {
    "x": "maşey şekerî (n)",
    "y": " b. maşaya şekerî (m)"
  },
  {
    "x": "maşela",
    "y": " 1)maşallah\r\n~2)(n, nm) maşallah (bir tür nazarlık)"
  },
  {
    "x": "maşella",
    "y": " b. maşela"
  },
  {
    "x": "maşêle (m)",
    "y": " b. mahşlêre (m)"
  },
  {
    "x": "maşilêri (m)",
    "y": " b. mahşlêre (m)"
  },
  {
    "x": "maşla (m)",
    "y": " çile (iplik vb. çilesi)"
  },
  {
    "x": "maşlêri (m)",
    "y": " b. mahşlêre (m)"
  },
  {
    "x": "mat bîyayene",
    "y": " b. mat bîyene"
  },
  {
    "x": "mat bîyayêne",
    "y": " b. mat bîyene"
  },
  {
    "x": "mat bîyene",
    "y": " 1)afallamak, şaşakalmak, ağzı açık kalmak (mecazi), ağzı bir karış açık kalmak (mecazi)\r\n~2)mat olmak (satranç vb. için)"
  },
  {
    "x": "mat kerdene",
    "y": " mat etmek (satranç vb. için)"
  },
  {
    "x": "mat mendene",
    "y": " afallamak, şaşakalmak, bakakalmak, ağzı açık kalmak (mecazi), ağzı bir karış açık kalmak (mecazi)"
  },
  {
    "x": "matac, -i",
    "y": " b. muhtac, -e"
  },
  {
    "x": "matador, -e",
    "y": " matador"
  },
  {
    "x": "matadorî (m)",
    "y": " matadorluk"
  },
  {
    "x": "matadorîye (m)",
    "y": " matadorluk"
  },
  {
    "x": "mataj, -i",
    "y": " b. muhtac, -e"
  },
  {
    "x": "matbîyayîş (n)",
    "y": " mat olma (satranç vb. için)"
  },
  {
    "x": "mateb (n)",
    "y": " b. mifte (n)"
  },
  {
    "x": "matef (n)",
    "y": " b. mifte (n)"
  },
  {
    "x": "matematîk (n)",
    "y": " matematik"
  },
  {
    "x": "matematîkzan, -e",
    "y": " matematikçi"
  },
  {
    "x": "materyal (n)",
    "y": " gereç, malzeme, materyal"
  },
  {
    "x": "matîne (m, sn)",
    "y": " matine"
  },
  {
    "x": "matkerdiş (n)",
    "y": " mat etme (satranç vb. için)"
  },
  {
    "x": "matmendiş (n)",
    "y": " şaşakalma, bakakalma"
  },
  {
    "x": "matbîyayîş (n)",
    "y": " şaşakalma"
  },
  {
    "x": "matqab (n)",
    "y": " b. metqeb (n)"
  },
  {
    "x": "matrîarkal, -e",
    "y": " anaerkil"
  },
  {
    "x": "matrîarkalîye (m)",
    "y": " anaerkillik"
  },
  {
    "x": "matura (m)",
    "y": " tembel"
  },
  {
    "x": "mature (n)",
    "y": " tembel"
  },
  {
    "x": "matureyîye (m)",
    "y": " tembellik"
  },
  {
    "x": "mavajî",
    "y": " mesela, örneğin"
  },
  {
    "x": "mavajîme",
    "y": " mesela, örneğin"
  },
  {
    "x": "mavên (n)",
    "y": " b. mabên (n)"
  },
  {
    "x": "mavên heremîyayene",
    "y": " b. mabên herimîyayene"
  },
  {
    "x": "mavên xerepîyayene",
    "y": " b. mabên xeripîyayene"
  },
  {
    "x": "mavên xirab bîyene",
    "y": " b. mabên xirab bîyene"
  },
  {
    "x": "mavên xirave kerdene",
    "y": " b. mabên xirab kerdene"
  },
  {
    "x": "mavênçî, -ye",
    "y": " b. mabênçî, -ye"
  },
  {
    "x": "mavênçîyîye",
    "y": " b. mabênçîyîye (m)"
  },
  {
    "x": "mavênheremîyayîş (n)",
    "y": " b. mabênherimîyayîş (n)"
  },
  {
    "x": "mavênxerepîyayîş (n)",
    "y": " b. mabênxeripîyayîş (n)"
  },
  {
    "x": "mavênxirabbîyayîş (n)",
    "y": " b. mabênxirabbîyayîş (n)"
  },
  {
    "x": "mawerde (m)",
    "y": " gül"
  },
  {
    "x": "mawerdi (m)",
    "y": " b. mawerde (m)"
  },
  {
    "x": "mawên (n)",
    "y": " b. mabên (n)"
  },
  {
    "x": "mawên heremîyayene",
    "y": " b. mabên herimîyayene"
  },
  {
    "x": "mawên xerepîyayene",
    "y": " b. mabên xeripîyayene"
  },
  {
    "x": "mawên xirab bîyene",
    "y": " b. mabên xirab bîyene"
  },
  {
    "x": "mawênheremîyayîş (n)",
    "y": " b. mabênherimîyayîş (n)"
  },
  {
    "x": "mawênxerepîyayîş (n)",
    "y": " b. mabênxeripîyayîş (n)"
  },
  {
    "x": "mawênxirabbîyayîş (n)",
    "y": " b. mabênxirabbîyayîş (n)"
  },
  {
    "x": "mawî (m)",
    "y": " b. mayî (m)"
  },
  {
    "x": "mawîn (n)",
    "y": " b. mabên (n)"
  },
  {
    "x": "mawzer (n)",
    "y": " b. mewzer (n)"
  },
  {
    "x": "mawzêr (n)",
    "y": " b. mewzer (n)"
  },
  {
    "x": "max (m)",
    "y": " b. maxe-II (m)"
  },
  {
    "x": "max kerdene",
    "y": " b. maxe kerdene"
  },
  {
    "x": "maxara (m)",
    "y": " b. mixara (m)"
  },
  {
    "x": "maxe-I (m)",
    "y": " binanın çatısını taşıması için tavanda yatay olarak kullanılan kalın direk"
  },
  {
    "x": "maxe-II (m)",
    "y": " istif, yığın"
  },
  {
    "x": "maxa velgî (m)",
    "y": " hayvanlara kışın yedirilmek üzere kesilip üst üste dizilen büyük yaprak yığını"
  },
  {
    "x": "maxa velgî ronayene",
    "y": " hayvanların yemesi için kışlık yaprak istiflemek"
  },
  {
    "x": "maxe kerdene",
    "y": " istiflemek, istif etmek, yığmak, loda etmek"
  },
  {
    "x": "maxe ronayene",
    "y": " yığmak, loda etmek"
  },
  {
    "x": "maxi (m)",
    "y": " maxe-I (m)"
  },
  {
    "x": "maxlub bîyene",
    "y": " b. mexlûb bîyene"
  },
  {
    "x": "may-I (m)",
    "y": " b. maye-I (m)"
  },
  {
    "x": "may-II (m)",
    "y": " b. makî (m)"
  },
  {
    "x": "mayaxorabîyayîş (n)",
    "y": " doğum"
  },
  {
    "x": "mayaxorabîyayîşê Îsayî",
    "y": " İsa’nın doğumu, milat"
  },
  {
    "x": "mayaxwerabeyîş (n)",
    "y": " b. mayaxorabîyayîş (n)"
  },
  {
    "x": "maydanoz (n)",
    "y": " maydanoz"
  },
  {
    "x": "maydenoz (n)",
    "y": " b. maydanoz (n)"
  },
  {
    "x": "maye-I (m)",
    "y": " anne, ana"
  },
  {
    "x": "maye û pî",
    "y": " ebeveyn"
  },
  {
    "x": "maya xo ra bîyene",
    "y": " doğmak"
  },
  {
    "x": "maya xo ra kewtene",
    "y": " doğmak (argo)"
  },
  {
    "x": "maye-II (m)",
    "y": " b. makî (m)"
  },
  {
    "x": "mayek (m)",
    "y": " b. mayeke (m)"
  },
  {
    "x": "mayekakerdiş n)",
    "y": " ilikleme"
  },
  {
    "x": "mayeke (m)",
    "y": " ilik (düğme deliği)"
  },
  {
    "x": "mayek a kerdene",
    "y": " iliklemek"
  },
  {
    "x": "mayeki (m)",
    "y": " b. mayeke (m)"
  },
  {
    "x": "mayene (n)",
    "y": " b. muayene (n)"
  },
  {
    "x": "mayene kerdene",
    "y": " b. muayene kerdene"
  },
  {
    "x": "mayey (m)",
    "y": " b. mayîye (m)"
  },
  {
    "x": "mayê (m)",
    "y": " b. mayîye (m)"
  },
  {
    "x": "mayike (m)",
    "y": " b. mahîne (m) "
  },
  {
    "x": "mayîni (m)",
    "y": " b. mahîne (m) "
  },
  {
    "x": "mayişe (m)",
    "y": " b. meaş (n)"
  },
  {
    "x": "mayî (m)",
    "y": " ağız (ilk süt)"
  },
  {
    "x": "mayî (m)",
    "y": " b. makî (m)"
  },
  {
    "x": "mayîke (m)",
    "y": " b. makî (m)"
  },
  {
    "x": "mayîn (m)",
    "y": " b. mahîne (m)"
  },
  {
    "x": "mayîne-I (m)",
    "y": " b. mahîne (m) "
  },
  {
    "x": "mayîne-II (n)",
    "y": " b. muayene (n)"
  },
  {
    "x": "mayîne kerdene",
    "y": " b. muayene kerdene "
  },
  {
    "x": "mayîni (m)",
    "y": " b. mahîne (m)"
  },
  {
    "x": "mayîş (n)",
    "y": " b. meaş (n)"
  },
  {
    "x": "mayîye (m)",
    "y": " analık"
  },
  {
    "x": "mayke (m)",
    "y": " b. makî (m)"
  },
  {
    "x": "maykek (m)",
    "y": " b. makî (m)"
  },
  {
    "x": "maykeke (m)",
    "y": " b. makî (m)"
  },
  {
    "x": "mayki (m)",
    "y": " b. makî (m)"
  },
  {
    "x": "maykî (m)",
    "y": " b. makî (m)"
  },
  {
    "x": "maymaran (m)",
    "y": " bir cins semender"
  },
  {
    "x": "maymari (m)",
    "y": " b. maymaran (m)"
  },
  {
    "x": "maymaro (m)",
    "y": " b. maymaran (m) "
  },
  {
    "x": "maymaron (m)",
    "y": " b. maymaran (m) "
  },
  {
    "x": "maymarû (m)",
    "y": " b. maymaran (m) "
  },
  {
    "x": "maymarûn (m)",
    "y": " b. maymaran (m) "
  },
  {
    "x": "mayo (n)",
    "y": " mayo"
  },
  {
    "x": "mayşahî, -ye",
    "y": " anaerkil"
  },
  {
    "x": "mayşahîye (m)",
    "y": " anaerkillik"
  },
  {
    "x": "mayven (n)",
    "y": " b. mabên (n)"
  },
  {
    "x": "mayxoş, -e",
    "y": " b. meyxoş, -e"
  },
  {
    "x": "mayxoşîye (m)",
    "y": " b. meyxoşî (m), meyxoşîye (m)"
  },
  {
    "x": "mazal (n)",
    "y": " b. mecal (n)"
  },
  {
    "x": "mazat (n)",
    "y": " b. mezat (n)"
  },
  {
    "x": "mazbut, -e",
    "y": " b. mezbut, -e"
  },
  {
    "x": "mazbut bîyayene",
    "y": " b. mezbut bîyene"
  },
  {
    "x": "mazbut kerdene",
    "y": " b. mezbut kerdene"
  },
  {
    "x": "mazbutbîyayîş (n)",
    "y": " b. mezbutbîyayîş (n)"
  },
  {
    "x": "mazbutey (m)",
    "y": " b. mezbutî (m)"
  },
  {
    "x": "mazbutkerdiş (n)",
    "y": " b. mezbutkerdiş (n)"
  },
  {
    "x": "Mazgêrd (n)",
    "y": " Mazgirt"
  },
  {
    "x": "mazilma (m)",
    "y": " b. mazirma (m)"
  },
  {
    "x": "mazirma (m)",
    "y": " bir üzüm çeşidi"
  },
  {
    "x": "mazot (n)",
    "y": " mazot "
  },
  {
    "x": "mazra (m)",
    "y": " b. mezra (m)"
  },
  {
    "x": "mazrê (m)",
    "y": " b. mezra (m)"
  },
  {
    "x": "me-me kerdene",
    "y": " b. be-be kerdene "
  },
  {
    "x": "me-mekerdiş (n)",
    "y": " b. be-bekerdiş (n)"
  },
  {
    "x": "meaş (n)",
    "y": " aylık, maaş"
  },
  {
    "x": "meaşê karmusî/e",
    "y": " stajyer maaşı"
  },
  {
    "x": "meaşê stajyerî/e",
    "y": " stajyer maaşı"
  },
  {
    "x": "meaşi (m)",
    "y": " b. meaş (n)"
  },
  {
    "x": "mebus, -e",
    "y": " mebus, milletvekili, parlamenter "
  },
  {
    "x": "mebusîye (m)",
    "y": " milletvekilliği, parlamenterlik"
  },
  {
    "x": "mecal (n)",
    "y": " 1)mecal, takat\r\n~2)fırsat"
  },
  {
    "x": "mecbur",
    "y": " mecbur, -e"
  },
  {
    "x": "mecbur kerdene",
    "y": " zorlamak"
  },
  {
    "x": "mecbur verdayene",
    "y": " zorlamak"
  },
  {
    "x": "mecburê/mecbura kerdişî",
    "y": " yükümlü, mükellef"
  },
  {
    "x": "mecburkerdiş (n)",
    "y": " zorlama, zorunda bırakma, icbar"
  },
  {
    "x": "mecburverdayîş (n)",
    "y": " zorlama, zorunda bırakma, icbar"
  },
  {
    "x": "mecbûr, -e",
    "y": " b. mecbur, -e"
  },
  {
    "x": "mecilfe (n)",
    "y": " b. mecrefe (m)"
  },
  {
    "x": "mecirfa (m)",
    "y": " b. mecrefe (m)"
  },
  {
    "x": "mecirfe (n)",
    "y": " b. mecrefe (m)"
  },
  {
    "x": "mecî (m)",
    "y": " b. mercû (m)"
  },
  {
    "x": "meclis (n)",
    "y": " b. meclîs (n)"
  },
  {
    "x": "meclîs (n)",
    "y": " meclis"
  },
  {
    "x": "meclîsê beledîya (n)",
    "y": " belediye meclisi"
  },
  {
    "x": "meclîsê beledîyaya şaristanê girdî",
    "y": " anakent belediye meclisi, büyükşehir belediye meclisi"
  },
  {
    "x": "meclîsê cinîyan (n)",
    "y": " kadın meclisi"
  },
  {
    "x": "meclîsê ciwanan (n)",
    "y": " gençlik meclisi"
  },
  {
    "x": "meclîsê şaredarî (n)",
    "y": " belediye meclisi"
  },
  {
    "x": "meclîsê şaredarîya şaristanê girdî",
    "y": " anakent belediye meclisi, büyükşehir belediye meclisi"
  },
  {
    "x": "mecmur, -e",
    "y": " b. mecbur, -e"
  },
  {
    "x": "mecnayîş (n)",
    "y": " b. mojnayîş (n)"
  },
  {
    "x": "mecra (m)",
    "y": " mecra, yatak"
  },
  {
    "x": "mecrefe (m)",
    "y": " sıyırga"
  },
  {
    "x": "mecrife (n)",
    "y": " b. mecrefe (m)"
  },
  {
    "x": "mecun (n)",
    "y": " b. macun (n)"
  },
  {
    "x": "mecunê didanan (n)",
    "y": " b. macunê didanan (n) "
  },
  {
    "x": "med (n)",
    "y": " b. made (n)"
  },
  {
    "x": "med birîyayene",
    "y": " b. made birîyayene"
  },
  {
    "x": "med gîrîyayene",
    "y": " b. made gîrîyayene"
  },
  {
    "x": "medê ... birîyayene",
    "y": " b. madeyê ... birîyayene"
  },
  {
    "x": "medê ... gîrîyayene",
    "y": " b. madeyê ... gîrîyayene"
  },
  {
    "x": "meddûcezîr (n)",
    "y": " gelgit, meddücezir"
  },
  {
    "x": "mede-I (n)",
    "y": " b. made (n)"
  },
  {
    "x": "mede-II (n)",
    "y": " b. mudet (n)"
  },
  {
    "x": "meded (n)",
    "y": " imdat"
  },
  {
    "x": "medege (m)",
    "y": " dişi manda "
  },
  {
    "x": "medenî, -ye",
    "y": " uygar, medeni"
  },
  {
    "x": "medenîyet (n)",
    "y": " uygarlık, medeniyet"
  },
  {
    "x": "mederîyayîş (n)",
    "y": " medirîyayîş (n)"
  },
  {
    "x": "medernayene",
    "y": " b. medirnayene "
  },
  {
    "x": "medernayîş (n)",
    "y": " b. medirnayîş (n)"
  },
  {
    "x": "medet (n)",
    "y": " b. meded (n)"
  },
  {
    "x": "medirîyayîş (n)",
    "y": " kirlenme"
  },
  {
    "x": "medirîyayene",
    "y": " kirlenmek"
  },
  {
    "x": "medirnayîş (n)",
    "y": " kirletme"
  },
  {
    "x": "medirnayox, -e",
    "y": " kirletici"
  },
  {
    "x": "medîma (m)",
    "y": " uğurböceği"
  },
  {
    "x": "meeş (n)",
    "y": " b. meaş (n)"
  },
  {
    "x": "mefet (n)",
    "y": " b. menfeet (n)"
  },
  {
    "x": "mefhûm (n)",
    "y": " kavram, konsept"
  },
  {
    "x": "mefhûmkî, -ye",
    "y": " kavramsal"
  },
  {
    "x": "meflonî (m)",
    "y": " egzama, mayasıl"
  },
  {
    "x": "mefrûşat (n)",
    "y": " döşeme (ev döşeme eşyası)"
  },
  {
    "x": "mefrûşat kerdene",
    "y": " döşemek, tefriş etmek"
  },
  {
    "x": "mefrûşatkar, -e",
    "y": " döşemeci"
  },
  {
    "x": "mefrûşatkarî (m)",
    "y": " döşemecilik"
  },
  {
    "x": "mefrûşatkarîye (m)",
    "y": " döşemecilik"
  },
  {
    "x": "mefrûşatkerdiş (n)",
    "y": " döşeme, tefriş etme"
  },
  {
    "x": "mefrûşatroş, -e",
    "y": " döşemeci"
  },
  {
    "x": "mefrûşatroşî (m)",
    "y": " döşemecilik"
  },
  {
    "x": "mefrûşatroşîye (m)",
    "y": " döşemecilik"
  },
  {
    "x": "mefşen (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "mefte (n)",
    "y": " b. mifte (n)"
  },
  {
    "x": "megabajar (n)",
    "y": " megakent, megaşehir"
  },
  {
    "x": "megalopol (n)",
    "y": " megalopolis, megapol"
  },
  {
    "x": "megalopolîs (n)",
    "y": " megalopolis, megapol"
  },
  {
    "x": "megaşaristan (n)",
    "y": " megakent, megaşehir"
  },
  {
    "x": "megatone (m)",
    "y": " megaton"
  },
  {
    "x": "megem",
    "y": " b. meke"
  },
  {
    "x": "mehal (n)",
    "y": " çağ, zaman, vakit"
  },
  {
    "x": "meharet (n)",
    "y": " beceri, maharet"
  },
  {
    "x": "mehde (n)",
    "y": " b. made-II (n)"
  },
  {
    "x": "mehde qelibîyayeni",
    "y": " b. made qelibîyayene"
  },
  {
    "x": "mehde qelibnayeni",
    "y": " b. made qelibnayene"
  },
  {
    "x": "mehden (n)",
    "y": " b. made-II (n)"
  },
  {
    "x": "mehden qelbinayeni",
    "y": " b. made qelibnayene"
  },
  {
    "x": "mehden qelibîyayeni",
    "y": " b. made qelibîyayene"
  },
  {
    "x": "mehdenqelbinayîş (n)",
    "y": " b. madeqelibnayîş (n)"
  },
  {
    "x": "mehdenqelibîyayîş (n)",
    "y": " b. madeqelibîyayîş (n)"
  },
  {
    "x": "mehdeqelibîyayîş (n)",
    "y": " b. madeqelibîyayîş (n)"
  },
  {
    "x": "mehdeqelibnayîş (n)",
    "y": " b. madeqelibnayîş (n)"
  },
  {
    "x": "mehdud, -e",
    "y": " sınırlı"
  },
  {
    "x": "mehdudî (m)",
    "y": " sınırlılık"
  },
  {
    "x": "mehdudîyet (n)",
    "y": " sınırlılık"
  },
  {
    "x": "mehel-I (n)",
    "y": " mahal, yöre"
  },
  {
    "x": "mehel-II (n)",
    "y": " b. mehal (n)"
  },
  {
    "x": "mehelî, -ye",
    "y": " yerel, yöresel, mahalli"
  },
  {
    "x": "mehese (m)",
    "y": " b. mêse (m)"
  },
  {
    "x": "meheyr, -e",
    "y": " zavallı"
  },
  {
    "x": "meheyrey (m)",
    "y": " b. meheyrî (m)"
  },
  {
    "x": "meheyri (m)",
    "y": " b. meheyre (m)"
  },
  {
    "x": "meheyrî (m)",
    "y": " zavallılık"
  },
  {
    "x": "mehîr, -i",
    "y": " b. meheyr, -e"
  },
  {
    "x": "mehîre (m)",
    "y": " ayran çorbası"
  },
  {
    "x": "mehîrey (m)",
    "y": " b. meheyrî (m)"
  },
  {
    "x": "mehîrê (m)",
    "y": " b. meheyrî (m)"
  },
  {
    "x": "mehîyrey (m)",
    "y": " b. meheyrî (m)"
  },
  {
    "x": "mehkem, -e",
    "y": " b. muhkem, -e"
  },
  {
    "x": "mehkem bîyayene",
    "y": " b. muhkem bîyene"
  },
  {
    "x": "mehkem kerdene",
    "y": " b. muhkem kerdene"
  },
  {
    "x": "mehkembîyayîş (n)",
    "y": " muhkembîyayîş (n)"
  },
  {
    "x": "mehkema (m)",
    "y": " b. mehkeme (m)"
  },
  {
    "x": "mehkeme-I (m)",
    "y": " mahkeme"
  },
  {
    "x": "mehkemeya berze ya temyîzî (m)",
    "y": " Yargıtay"
  },
  {
    "x": "mehkemeya ceza (m)",
    "y": " ceza mahkemesi"
  },
  {
    "x": "mehkemeya ceza ya pêardişî (m)",
    "y": " sulh ceza mahkemesi "
  },
  {
    "x": "mehkemeya ceza ya werêardişî (m)",
    "y": " sulh ceza mahkemesi"
  },
  {
    "x": "Mehkemeya Heqanê Merdiman ya Ewropa (MHME)",
    "y": " Avrupa İnsan Hakları Mahkemesi (AİHM)"
  },
  {
    "x": "mehkemeya huqûqî (m)",
    "y": " hukuk mahkemesi"
  },
  {
    "x": "mehkemeya huqûqî ya eslî (m)",
    "y": " asliye hukuk mahkemesi"
  },
  {
    "x": "mehkemeya huqûqî ya pêardişî (m)",
    "y": " sulh hukuk mahkemesi"
  },
  {
    "x": "mehkemeya huqûqî ya werêardişî (m)",
    "y": " sulh hukuk mahkemesi"
  },
  {
    "x": "mehkemeya îdarîye (m)",
    "y": " idari mahkeme"
  },
  {
    "x": "mehkemeya îdarîye ya berze (m)",
    "y": " danıştay"
  },
  {
    "x": "mehkemeya muhasebatî (m)",
    "y": " sayıştay"
  },
  {
    "x": "mehkemeya pêardişî (m)",
    "y": " sulh mahkemesi"
  },
  {
    "x": "mehkemeya temyîzî (m)",
    "y": " temyiz mahkemesi"
  },
  {
    "x": "mehkemeya werêardişî (m)",
    "y": " sulh mahkemesi"
  },
  {
    "x": "mehkeme-II (n)",
    "y": " b. mehkeme (m)"
  },
  {
    "x": "mehkemekerdiş (n)",
    "y": " muhakeme"
  },
  {
    "x": "mehkemê (m)",
    "y": " b. muhkemî (m), muhkemîye (m)"
  },
  {
    "x": "mehkemî (m)",
    "y": " b. muhkemî (m), muhkemîye (m)"
  },
  {
    "x": "mehkemkerdiş (n)",
    "y": " b. b. muhkemkerdiş (n)"
  },
  {
    "x": "mehkême (n)",
    "y": " b. mehkeme (m)"
  },
  {
    "x": "mehkima (m)",
    "y": " b. mehkeme (m)"
  },
  {
    "x": "mehla (m)",
    "y": " b. taxe (m)"
  },
  {
    "x": "mehlan (n)",
    "y": " b. mehal (n)"
  },
  {
    "x": "mehlem, -i",
    "y": " b. malim, -e"
  },
  {
    "x": "mehlim, -e",
    "y": " b. malim, -e"
  },
  {
    "x": "mehlîm, -e",
    "y": " b. malim, -e"
  },
  {
    "x": "mehlîyem (m)",
    "y": " b. malêbe (m)"
  },
  {
    "x": "mehne (n)",
    "y": " b. mane (n)"
  },
  {
    "x": "mehpus, -e",
    "y": " mahpus"
  },
  {
    "x": "mehpusxane (n)",
    "y": " mahpushane, mapusane"
  },
  {
    "x": "mehser (n)",
    "y": " b. mahsere (n)"
  },
  {
    "x": "mehsere (n)",
    "y": " b. mahsere (n)"
  },
  {
    "x": "mehsire (n)",
    "y": " b. mahsere (n)"
  },
  {
    "x": "mehsre (n)",
    "y": " b. mahsere (n)"
  },
  {
    "x": "mehsul (n)",
    "y": " ürün, mahsul"
  },
  {
    "x": "mehsul arê dayene",
    "y": " ürün toplamak"
  },
  {
    "x": "mehsul arê kerdene",
    "y": " ürün toplamak"
  },
  {
    "x": "mehsul pêser kerdene",
    "y": " ürün toplamak"
  },
  {
    "x": "mehsulê promosyonî (n)",
    "y": " promosyon ürünü"
  },
  {
    "x": "mehsuldar, -e",
    "y": " verimli"
  },
  {
    "x": "mehsulardiş (n)",
    "y": " üretim"
  },
  {
    "x": "mehsularêdayîş (n)",
    "y": " ürün toplama"
  },
  {
    "x": "mehsularêkerdiş (n)",
    "y": " ürün toplama"
  },
  {
    "x": "mehsulpêserkerdiş (n)",
    "y": " ürün toplama"
  },
  {
    "x": "mehsûl (n)",
    "y": " b. mehsul (n)"
  },
  {
    "x": "mehşele (m)",
    "y": " b. mahşlêre (m)"
  },
  {
    "x": "mehşêle (m)",
    "y": " b. mahşlêre (m)"
  },
  {
    "x": "mehşi (m)",
    "y": " b. meaş (n)"
  },
  {
    "x": "mehşîyer (m)",
    "y": " b. mahşlêre (m)"
  },
  {
    "x": "mehşlêr (m)",
    "y": " b. mahşlêre (m)"
  },
  {
    "x": "mehşlêre (m)",
    "y": " b. mahşlêre (m)"
  },
  {
    "x": "mehşyer (m)",
    "y": " b. mahşlêre (m)"
  },
  {
    "x": "mehtac, -e",
    "y": " b. muhtac, -e"
  },
  {
    "x": "mehtac, -i",
    "y": " b. muhtac, -e"
  },
  {
    "x": "mehte (n)",
    "y": " b. mifte (n)"
  },
  {
    "x": "mehteb (n)",
    "y": " b. mifte (n)"
  },
  {
    "x": "mehtef (n)",
    "y": " b. mifte (n)"
  },
  {
    "x": "meîne (n)",
    "y": " b. muayene (n)"
  },
  {
    "x": "meîne kerdene",
    "y": " b. muayene kerdene"
  },
  {
    "x": "mejbur, -i",
    "y": " b. mecbur, -e"
  },
  {
    "x": "mejirfe (n)",
    "y": " b. mecrefe (m)"
  },
  {
    "x": "mejî (m)",
    "y": " b. mercû (m)"
  },
  {
    "x": "mekan (n)",
    "y": " mekân"
  },
  {
    "x": "mekanîk, -e",
    "y": " mekanik"
  },
  {
    "x": "mekankî, -ye",
    "y": " mekânsal"
  },
  {
    "x": "meke",
    "y": " meğer, meğerse"
  },
  {
    "x": "mekebe (m)",
    "y": " b. mekube (m)"
  },
  {
    "x": "mekeme (n)",
    "y": " b. mehkeme (m)"
  },
  {
    "x": "mekênayene",
    "y": " cesaret etmek, cüret etmek\r\n~Ez nêmekênaya binusa. (Yazmaya cesaret etmedim.)"
  },
  {
    "x": "mekênayîş (n)",
    "y": " cesaret etme, cüret etme"
  },
  {
    "x": "meki",
    "y": " b. meke"
  },
  {
    "x": "Meksîka (m)",
    "y": " Meksika "
  },
  {
    "x": "Meksîko City",
    "y": " Meksiko"
  },
  {
    "x": "mekteb (n)",
    "y": " okul, mektep"
  },
  {
    "x": "mektebo mîyanên (n)",
    "y": " ortaokul"
  },
  {
    "x": "mektebe (m)",
    "y": " b. mekteb (n)"
  },
  {
    "x": "mektebi (m)",
    "y": " b. mekteb (n)"
  },
  {
    "x": "mektev (m)",
    "y": " b. mekteb (n)"
  },
  {
    "x": "mektev (n)",
    "y": " b. mekteb (n)"
  },
  {
    "x": "mektib (n)",
    "y": " b. mektube (m)"
  },
  {
    "x": "mektub (n)",
    "y": " b. mektube (m)"
  },
  {
    "x": "mektube (m)",
    "y": " mektup"
  },
  {
    "x": "mektuba akerda (m)",
    "y": " açık mektup"
  },
  {
    "x": "mektuba akerdîye (m)",
    "y": " açık mektup"
  },
  {
    "x": "mektuba eşkera (m)",
    "y": " açık mektup"
  },
  {
    "x": "mektuba pêşnîyaze (m)",
    "y": " teklif mektubu"
  },
  {
    "x": "mektuba tewsîye (m)",
    "y": " referans"
  },
  {
    "x": "mektuba wesî (m)",
    "y": " referans"
  },
  {
    "x": "mektuv (n)",
    "y": " b. mektube (m)"
  },
  {
    "x": "mektuve (m)",
    "y": " b. mektube (m)"
  },
  {
    "x": "mektûbe (m)",
    "y": " b. mektube (m)"
  },
  {
    "x": "mektûbi (m)",
    "y": " b. mektube (m)"
  },
  {
    "x": "mekube (m)",
    "y": " sele (büyük sele), altında yiyecek ve eşya saklamak için de kullanılan büyük yayvan sepet"
  },
  {
    "x": "mekubi (m)",
    "y": " b. mekube (m)"
  },
  {
    "x": "mela-I (n)",
    "y": " molla, din hocası"
  },
  {
    "x": "mela-II",
    "y": " 1)ancak (olsa olsa), yalnızca, yalnız, sadece\r\n~2)inşallah, Allah vere de, Allah vere"
  },
  {
    "x": "mela-III (m)",
    "y": " b. mele-I (n)"
  },
  {
    "x": "melaîke (m)",
    "y": " b. meleke (m), milakete (m)"
  },
  {
    "x": "melake (m)",
    "y": " b. meleke (m), milakete (m)"
  },
  {
    "x": "melaket (m)",
    "y": " b. milakete (m)"
  },
  {
    "x": "melal, -e",
    "y": " b. mindal, -e"
  },
  {
    "x": "melamet (n)",
    "y": " azarlama, melamet, paylama, tekdir etme"
  },
  {
    "x": "melametî vetene",
    "y": " azarlamak, paylamak, tekdir etmek"
  },
  {
    "x": "melametîvetiş (n)",
    "y": " azarlama, melamet, paylama, tekdir etme"
  },
  {
    "x": "melametîvetox, -e",
    "y": " azarlayan"
  },
  {
    "x": "melanjor (n)",
    "y": " melanjör"
  },
  {
    "x": "melaxme (n)",
    "y": " b. malaxme (n)"
  },
  {
    "x": "melayîke (m)",
    "y": " b. meleke (m), milakete (m)"
  },
  {
    "x": "melayîkîye (m)",
    "y": " b. meleke (m), milakete (m)"
  },
  {
    "x": "mele-I (n)",
    "y": " 1)çekirge\r\n~2)şerit, tenya, abdestbozan"
  },
  {
    "x": "mele-II (n)",
    "y": " mala"
  },
  {
    "x": "mele kerdene",
    "y": " malalamak"
  },
  {
    "x": "mele-III",
    "y": " b. mela-I (n)"
  },
  {
    "x": "mele-IV",
    "y": " b. mela-II"
  },
  {
    "x": "melebe (m)",
    "y": " b. malêbe (m)"
  },
  {
    "x": "melebî (m)",
    "y": " oyuncak"
  },
  {
    "x": "melekçî, -ye",
    "y": " başorakçı, eğnelbaşı"
  },
  {
    "x": "melekçîyey (m)",
    "y": " b. melekçîyî (m)"
  },
  {
    "x": "melekçîyî (m)",
    "y": " başorakçılık"
  },
  {
    "x": "meleke (m)",
    "y": " melek"
  },
  {
    "x": "melekerdiş (n)",
    "y": " malalama"
  },
  {
    "x": "melekete (m)",
    "y": " b. meleke (m), milakete (m)"
  },
  {
    "x": "meleme (n)",
    "y": " b. menemen (n) "
  },
  {
    "x": "melemen (n)",
    "y": " b. menemen (n)"
  },
  {
    "x": "meleqof (n)",
    "y": " kombinezon (giysi için)"
  },
  {
    "x": "meleşe (n)",
    "y": " b. melşe (n) "
  },
  {
    "x": "melet (m)",
    "y": " b. milet (n) "
  },
  {
    "x": "melêbe (m)",
    "y": " b. malêbe (m)"
  },
  {
    "x": "melês bîyayîne",
    "y": " b. melis bîyene"
  },
  {
    "x": "melêsbîyayîş (n)",
    "y": " b. melisbîyayîş (n)"
  },
  {
    "x": "melêve (m)",
    "y": " b. malêbe (m)"
  },
  {
    "x": "melfurê dare bîyene",
    "y": " arka ayakları üzerine kalkarak ön ayaklarını ağacın yüksekçe bir yerine dayamak (hayvan için)"
  },
  {
    "x": "meliçik (n)",
    "y": " b. mîlçik (n)"
  },
  {
    "x": "melik (n)",
    "y": " mala"
  },
  {
    "x": "melim, -e",
    "y": " b. malim, -e"
  },
  {
    "x": "melis bîyene",
    "y": " tüneklemek, tünemek"
  },
  {
    "x": "melisbîyayîş (n)",
    "y": " tünekleme, tüneme"
  },
  {
    "x": "melîfet (n)",
    "y": " b. merîfet (n)"
  },
  {
    "x": "mella (n)",
    "y": " b. mela-I (n)"
  },
  {
    "x": "melle (n)",
    "y": " b. mela-I (n)"
  },
  {
    "x": "melodram (n)",
    "y": " melodram"
  },
  {
    "x": "melqî (n)",
    "y": " b. milqî (n)"
  },
  {
    "x": "melqî kerdene",
    "y": " b. milqî kerdene"
  },
  {
    "x": "melşe (n)",
    "y": " sivrisinek"
  },
  {
    "x": "melşi (n)",
    "y": " b. melşe (n) "
  },
  {
    "x": "melul, -e",
    "y": " üzgün, melül"
  },
  {
    "x": "melul bîyene",
    "y": " üzgün olmak"
  },
  {
    "x": "meluley",
    "y": " b. melulî (m), melulîye (m)"
  },
  {
    "x": "melulê (m)",
    "y": " b. melulî (m), melulîye (m)"
  },
  {
    "x": "melulî (m)",
    "y": " üzgünlük"
  },
  {
    "x": "melulîye (m)",
    "y": " üzgünlük"
  },
  {
    "x": "melumat (n)",
    "y": " bilgi, malumat"
  },
  {
    "x": "melumato resmî (n)",
    "y": " resmî bilgi"
  },
  {
    "x": "melûl, -e",
    "y": " b. melul, -e"
  },
  {
    "x": "melûlê (m)",
    "y": " b. melulî (m), melulîye (m)"
  },
  {
    "x": "melwa (m)",
    "y": " b. marula (m)"
  },
  {
    "x": "melyêb (m)",
    "y": " b. malêbe (m)"
  },
  {
    "x": "memik (n)",
    "y": " 1)meme\r\n~2)emzik (çocuk dilinde)"
  },
  {
    "x": "memisxane (n)",
    "y": " b. memîşxane (n)"
  },
  {
    "x": "memîsxane (n)",
    "y": " b. memîşxane (n)"
  },
  {
    "x": "memîşxane (n)",
    "y": " yüznumara, ayakyolu, abdesthane, apteshane, hela, kademhane, kenef, memişhane, tuvalet"
  },
  {
    "x": "memnun, -e",
    "y": " hoşnut, memnun"
  },
  {
    "x": "memnun bîyene",
    "y": " hoşnut olmak"
  },
  {
    "x": "memnun kerdene",
    "y": " hoşnut etmek"
  },
  {
    "x": "memnuney (m)",
    "y": " b. memnunî (m), memnunîye (m)"
  },
  {
    "x": "memnunê (m)",
    "y": " b. memnunî (m), memnunîye (m)"
  },
  {
    "x": "memnunî (m)",
    "y": " hoşnutluk"
  },
  {
    "x": "memnunîye (m)",
    "y": " hoşnutluk"
  },
  {
    "x": "memnunîyet (n)",
    "y": " hoşnutluk"
  },
  {
    "x": "memur, -e",
    "y": " b. mamur, -e"
  },
  {
    "x": "memûr, -e",
    "y": " b. mamur, -e"
  },
  {
    "x": "memûrîye (m)",
    "y": " b. mamurîye (m)"
  },
  {
    "x": "mena (m)",
    "y": " b. mana (m)"
  },
  {
    "x": "menacer, -e",
    "y": " b. menajer, -e"
  },
  {
    "x": "menajer, -e",
    "y": " menajer"
  },
  {
    "x": "menajerî (m)",
    "y": " menajerlik"
  },
  {
    "x": "menajerîye (m)",
    "y": " menajerlik"
  },
  {
    "x": "menasib, -i",
    "y": " b. munasib, -e"
  },
  {
    "x": "menasib bîyayene",
    "y": " b. munasib bîyene"
  },
  {
    "x": "menasibbîyayîş (n)",
    "y": " b. munasibbîyayîş (n)"
  },
  {
    "x": "menasibê (m)",
    "y": " b. munasibî (m)"
  },
  {
    "x": "menda (m)",
    "y": " baki"
  },
  {
    "x": "mende (n)",
    "y": " 1)kalan, bakiye\r\n~2)baki"
  },
  {
    "x": "mendene",
    "y": " kalmak"
  },
  {
    "x": "... mendene",
    "y": " 1)benzemek\r\n~2)çağrıştırmak (andırmak)"
  },
  {
    "x": "mendiş (n)",
    "y": " kalma"
  },
  {
    "x": "... mendiş (n)",
    "y": " 1)benzeme\r\n~2)çağrıştırma (andırma)"
  },
  {
    "x": "mendiz (n)",
    "y": " b. muhendis, -e"
  },
  {
    "x": "mendizê (m)",
    "y": " b. muhendisî (m), muhendisîye (m)"
  },
  {
    "x": "mendî, -ye",
    "y": " 1)bakiye\r\n~2)baki"
  },
  {
    "x": "mendîle (m)",
    "y": " 1)başörtü, başörtüsü\r\n~2)tülbent"
  },
  {
    "x": "mendîs (n)",
    "y": " b. muhendis, -e"
  },
  {
    "x": "mendîsî (m)",
    "y": " b. muhendisî (m), muhendisîye (m)"
  },
  {
    "x": "mendox, -e",
    "y": " sakin"
  },
  {
    "x": "mendoxê/a bîna",
    "y": " bina sakini"
  },
  {
    "x": "mendoxê/a taxe",
    "y": " mahalle sakini"
  },
  {
    "x": "mene (n)",
    "y": " b. mîyane (n)"
  },
  {
    "x": "menecer, -e",
    "y": " b. menajer, -e"
  },
  {
    "x": "menemen (n)",
    "y": " menemen "
  },
  {
    "x": "menenjît (n)",
    "y": " menenjit "
  },
  {
    "x": "menfaet (n)",
    "y": " b. menfeet (n)"
  },
  {
    "x": "menfeet (n)",
    "y": " çıkar, menfaat, yarar"
  },
  {
    "x": "menfeetê xo ewnîyayene",
    "y": " çı karına bakmak, menfaatine bakmak, yararına bakmak"
  },
  {
    "x": "menfeetperest, -e",
    "y": " çıkarcı, menfaatçi"
  },
  {
    "x": "menfeetperestîye (m)",
    "y": " çıkarcılık, menfaatçilik"
  },
  {
    "x": "menfiet (n)",
    "y": " b. menfeet (n)"
  },
  {
    "x": "meng (m)",
    "y": " b. menge (m)"
  },
  {
    "x": "mengane (n)",
    "y": " aidat, ödenti"
  },
  {
    "x": "menge (m)",
    "y": " ay, yılın on iki bölümünden her biri"
  },
  {
    "x": "mengene (n)",
    "y": " mengene "
  },
  {
    "x": "mengi (m)",
    "y": " b. menge (m)"
  },
  {
    "x": "mengirî (m)",
    "y": " orta büyüklükte sini"
  },
  {
    "x": "menoşeke (m)",
    "y": " uğurböceği"
  },
  {
    "x": "menoşeki (m)",
    "y": " b. menoşeke (m)"
  },
  {
    "x": "mensûb, -e",
    "y": " ait"
  },
  {
    "x": "menşur, -e",
    "y": " b. meşhur, -e"
  },
  {
    "x": "menşuri (m)",
    "y": " b. meşhure (m)"
  },
  {
    "x": "menşûr, -e",
    "y": " b. meşhur, -e"
  },
  {
    "x": "mental, -e",
    "y": " mental"
  },
  {
    "x": "menteşa (m)",
    "y": " menteşe "
  },
  {
    "x": "menteşe (n)",
    "y": " b. menteşa (m)"
  },
  {
    "x": "menteşi (m)",
    "y": " b. menteşa (m)"
  },
  {
    "x": "mentiq (n)",
    "y": " mantık"
  },
  {
    "x": "mentiqî, -ye",
    "y": " mantıki, mantıklı, mantıksal"
  },
  {
    "x": "mentişe (n)",
    "y": " b. menteşa (m)"
  },
  {
    "x": "menzera (m)",
    "y": " görünüş"
  },
  {
    "x": "menzûm, -e",
    "y": " manzum"
  },
  {
    "x": "mepus, -e",
    "y": " b. mehpus, -e"
  },
  {
    "x": "mepis, -e",
    "y": " b. mehpus, -e"
  },
  {
    "x": "mepusxane (n)",
    "y": " b. mehpusxane (n)"
  },
  {
    "x": "meqale (m)",
    "y": " makale"
  },
  {
    "x": "meqaleya edebîye (m)",
    "y": " edebi makale"
  },
  {
    "x": "meqam (n)",
    "y": " merci"
  },
  {
    "x": "meqamê etudê caardişî (n)",
    "y": " icra tetkik mercii"
  },
  {
    "x": "meqara (m)",
    "y": " makara, cağ"
  },
  {
    "x": "meqaraya layî (m)",
    "y": " iplik makarası"
  },
  {
    "x": "meqber (m)",
    "y": " b. meqbere (m)"
  },
  {
    "x": "meqbere (m)",
    "y": " mezar"
  },
  {
    "x": "meqbuz (n)",
    "y": " alındı, makbuz"
  },
  {
    "x": "meqberi (m)",
    "y": " b. meqbere (m)"
  },
  {
    "x": "meqer (n)",
    "y": " b. miqar (n)"
  },
  {
    "x": "meqere (n)",
    "y": " b. meqara (m)"
  },
  {
    "x": "meqerne (n)",
    "y": " makarna"
  },
  {
    "x": "meqes (n)",
    "y": " makas"
  },
  {
    "x": "meqesê asinî (n)",
    "y": " demir makası "
  },
  {
    "x": "meqesasin (n)",
    "y": " demir makası"
  },
  {
    "x": "meqesdar, -e",
    "y": " makastar"
  },
  {
    "x": "meqeskar, -e",
    "y": " 1)makasçı (makas yapan kimse)\r\n~2)makasçı (demiryollarında görevli makasçı)"
  },
  {
    "x": "meqesroş, -e",
    "y": " makasçı (makas satan kimse)"
  },
  {
    "x": "meqeşer (n)",
    "y": " Kuruyunca siyahlaşan bir çeşit nohutun, kabuklarından arındırıldıktan sonra geriye kalan kısmı olup bulgur pilavı vb. yemeklere katılır."
  },
  {
    "x": "meqet (n)",
    "y": " kanepe"
  },
  {
    "x": "meqet bîyene",
    "y": " b. miqate bîyene"
  },
  {
    "x": "meqet kerdene",
    "y": " b. miqat kerdene"
  },
  {
    "x": "meqetê ... bîyene",
    "y": " b. miqatê ... bîyene"
  },
  {
    "x": "meqeyt bîyene",
    "y": " b. miqate bîyene"
  },
  {
    "x": "meqeyt kerdene",
    "y": " b. miqat kerdene"
  },
  {
    "x": "meqeytê ... bîyene",
    "y": " b. miqatê ... bîyene"
  },
  {
    "x": "meqeytkerdiş (n)",
    "y": " b. miqatkerdiş (n)"
  },
  {
    "x": "meqlê (n)",
    "y": " b. miqil (n)"
  },
  {
    "x": "meqlub, -e",
    "y": " ters\r\n~Mêrikî şelwarê xo meqlub pay kerdê. (Adam şalvarını ters [iç tarafı dışa gelecek biçimde] giymiş.)"
  },
  {
    "x": "meqlum, -i",
    "y": " b. meqlub, -e"
  },
  {
    "x": "meqom (m)",
    "y": " b. meqam"
  },
  {
    "x": "meqsed (n)",
    "y": " amaç, erek, maksat"
  },
  {
    "x": "meqûm (m)",
    "y": " b. meqam"
  },
  {
    "x": "meqyaj (m)",
    "y": " b. makyaj (n)"
  },
  {
    "x": "mer, -i-I",
    "y": " b. mar, -e"
  },
  {
    "x": "mer-II (n)",
    "y": " b. mere-I (m)"
  },
  {
    "x": "mer kerdene",
    "y": " b. mere kerdene"
  },
  {
    "x": "mera-I (m)",
    "y": " mera"
  },
  {
    "x": "mera-II",
    "y": " b. mela-II"
  },
  {
    "x": "merad (n)",
    "y": " b. mirad (n)"
  },
  {
    "x": "meramet (n)",
    "y": " b. merhemet"
  },
  {
    "x": "meramet kerdene",
    "y": " b. merhemet kerdene"
  },
  {
    "x": "merametkerdiş (n)",
    "y": " b. merhemetkerdiş (n)"
  },
  {
    "x": "meraq (n)",
    "y": " merak"
  },
  {
    "x": "meraq kerdene",
    "y": " meraklanmak, merak etmek"
  },
  {
    "x": "meraqkerdiş (n)",
    "y": " meraklanma, merak etme"
  },
  {
    "x": "merasim (n)",
    "y": " merasim, seremoni, tören"
  },
  {
    "x": "merasimê abîyayîşî (n)",
    "y": " açılış merasimi, açılış töreni"
  },
  {
    "x": "merasimê cenaza (n)",
    "y": " cenaze töreni, cenaze merasimi"
  },
  {
    "x": "merasimê yadkerdişî (n)",
    "y": " anma töreni"
  },
  {
    "x": "merava (m)",
    "y": " b. merheba (m)"
  },
  {
    "x": "merc (n)",
    "y": " koşul, şart"
  },
  {
    "x": "mercê ceza (n) (huq.)",
    "y": " ceza şartı"
  },
  {
    "x": "mercê hîjyenîkî (zh)",
    "y": " hijyenik koşullar, hijyenik şartlar"
  },
  {
    "x": "mercife (n)",
    "y": " b. mecrefe (m)"
  },
  {
    "x": "mercî (m)",
    "y": " b. mercû (m)"
  },
  {
    "x": "mercname (n)",
    "y": " şartname"
  },
  {
    "x": "mercnameyo teknîkî (n)",
    "y": " teknik şartname"
  },
  {
    "x": "mercuî (m)",
    "y": " b. mercû (m)"
  },
  {
    "x": "mercû (m)",
    "y": " mercimek"
  },
  {
    "x": "mercûw (m)",
    "y": " b. mercû (m)"
  },
  {
    "x": "merd (n)",
    "y": " cömert"
  },
  {
    "x": "merda (m)",
    "y": " ölü"
  },
  {
    "x": "merdal (n)",
    "y": " b. murdar, -e"
  },
  {
    "x": "merdal bîyayene",
    "y": " b. murdar bîyene"
  },
  {
    "x": "merdal kerdene",
    "y": " b. murdar kerdene"
  },
  {
    "x": "merdane (n)",
    "y": " merdane"
  },
  {
    "x": "merde (n)",
    "y": " ölü"
  },
  {
    "x": "merdene",
    "y": " ölmek, vefat etmek"
  },
  {
    "x": "merdey (m)",
    "y": " b. merdî (m)"
  },
  {
    "x": "merdim, -e",
    "y": " 1)insan, insanoğlu, adam, beniâdem, beşer\r\n~2)akraba"
  },
  {
    "x": "merdim kiştene",
    "y": " cinayet işlemek "
  },
  {
    "x": "merdim vano qey",
    "y": " sanki"
  },
  {
    "x": "merdima dînî (m)",
    "y": " din adamı"
  },
  {
    "x": "merdima îlimî (m)",
    "y": " bilim kadını, bilimci"
  },
  {
    "x": "merdimê (n)",
    "y": " adamın biri, bir adam"
  },
  {
    "x": "merdimê dînî (n)",
    "y": " din adamı"
  },
  {
    "x": "merdimê îlimî (n)",
    "y": " bilim adamı, bilimci"
  },
  {
    "x": "merdimêk (n)",
    "y": " adamın biri, bir adam"
  },
  {
    "x": "merdimatê (m)",
    "y": " b. merdimî (m)"
  },
  {
    "x": "merdimatî (m)",
    "y": " b. merdimî (m)"
  },
  {
    "x": "merdimey (m)",
    "y": " b. merdimîye (m)"
  },
  {
    "x": "merdimê (m)",
    "y": " b. merdimî (m)"
  },
  {
    "x": "merdimênî (m)",
    "y": " akrabalık"
  },
  {
    "x": "merdimênî kerdene",
    "y": " akrabalık kurmak"
  },
  {
    "x": "merdimênî pêra nayene",
    "y": " akrabalık kurmak"
  },
  {
    "x": "merdimênî ronayene",
    "y": " akrabalık kurmak"
  },
  {
    "x": "merdimi (m)",
    "y": " b. merdime (m)"
  },
  {
    "x": "merdimî (m)",
    "y": " 1)insanlık, adamlık\r\n~2)akrabalık"
  },
  {
    "x": "merdimî kerdene",
    "y": " akrabalık kurmak"
  },
  {
    "x": "merdimî ronayene",
    "y": " akrabalık kurmak"
  },
  {
    "x": "merdimîye (m)",
    "y": " 1)insanlık, adamlık\r\n~2)akrabalık"
  },
  {
    "x": "merdimîye kerdene",
    "y": " akrabalık kurmak"
  },
  {
    "x": "merdimîye pêra nayene",
    "y": " akrabalık kurmak"
  },
  {
    "x": "merdimîye ronayene",
    "y": " akrabalık kurmak"
  },
  {
    "x": "merdimkiştiş (n)",
    "y": " cinayet, kıya"
  },
  {
    "x": "merdimnas, -e",
    "y": " antropolog"
  },
  {
    "x": "merdimnasî (m)",
    "y": " antropoloji"
  },
  {
    "x": "merdimnasîye (m)",
    "y": " antropoloji"
  },
  {
    "x": "merdimnaskî, -ye",
    "y": " antropolojik"
  },
  {
    "x": "merdimûney (m)",
    "y": " b. merdimîye (m)"
  },
  {
    "x": "merdis (n)",
    "y": " b. merdiş (n)"
  },
  {
    "x": "merdiş (n)",
    "y": " ölme, ölüm; vefat, vefat etme"
  },
  {
    "x": "merdî (m)",
    "y": " cömertlik"
  },
  {
    "x": "merdîwani (m)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "merdîwûn (m)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "merdîye (m)",
    "y": " ölü"
  },
  {
    "x": "merdos (m)",
    "y": " b. herdose (m)"
  },
  {
    "x": "merdşo (n)",
    "y": " b. merduşû (n)"
  },
  {
    "x": "merdum, -i",
    "y": " b. merdim, -e"
  },
  {
    "x": "merdumey (m)",
    "y": " b. merdimîye (m)"
  },
  {
    "x": "merduşû (n)",
    "y": " teneşir, teneşir tahtası"
  },
  {
    "x": "mere-I (m)",
    "y": " bel"
  },
  {
    "x": "mere kerdene",
    "y": " bellemek"
  },
  {
    "x": "mere-II (n)",
    "y": " b. meru (n)"
  },
  {
    "x": "mere-III (n)",
    "y": " b. merre (n)"
  },
  {
    "x": "mereya awî (m)",
    "y": " b. merreya awîye (m)"
  },
  {
    "x": "mereyo awî (n)",
    "y": " b. merreyo awî (n)"
  },
  {
    "x": "mereyo kor (n)",
    "y": " b. merreyo kor (n)"
  },
  {
    "x": "mereba (m)",
    "y": " b. merheba (m)"
  },
  {
    "x": "merek (m)",
    "y": " b. mereke (m)"
  },
  {
    "x": "mereke (m)",
    "y": " samanlık"
  },
  {
    "x": "merekerdiş (n)",
    "y": " belleme"
  },
  {
    "x": "mereki (m)",
    "y": " b. mereke (m)"
  },
  {
    "x": "meremet (n)",
    "y": " b. merhemet"
  },
  {
    "x": "meremet kerdene",
    "y": " b. merhemet kerdene"
  },
  {
    "x": "meremetkerdiş (n)",
    "y": " b. merhemetkerdiş (n)"
  },
  {
    "x": "mereq (n)",
    "y": " b. meraq (n)"
  },
  {
    "x": "mereq kerdene",
    "y": " b. meraq kerdene"
  },
  {
    "x": "mereqkerdiş (n)",
    "y": " b. meraqkerdiş (n)"
  },
  {
    "x": "mereşe (n)",
    "y": " b. melşe (n)"
  },
  {
    "x": "mereva (m)",
    "y": " b. merheba (m)"
  },
  {
    "x": "merex (n)",
    "y": " b. meraq (n)"
  },
  {
    "x": "merex kerdene",
    "y": " b. meraq kerdene"
  },
  {
    "x": "merexkerdiş (n)",
    "y": " b. meraqkerdiş (n)"
  },
  {
    "x": "merg-I (n)",
    "y": " ölüm, vefat"
  },
  {
    "x": "mergê ... ameyene",
    "y": " eceli gelmek, vakti gelmek (eceli gelmek), zamanı gelmek (eceli gelmek)"
  },
  {
    "x": "merg-II (m)",
    "y": " b. merge (m)"
  },
  {
    "x": "mergdar, -e",
    "y": " ölümlü"
  },
  {
    "x": "merge (m)",
    "y": " otlak, çayır, mera"
  },
  {
    "x": "mergi (m)",
    "y": " b. merge (m)"
  },
  {
    "x": "merguze (n)",
    "y": " çayırlık, çimenlik"
  },
  {
    "x": "merhale (m)",
    "y": " b. merhele (m)"
  },
  {
    "x": "merheba (m)",
    "y": " merhaba "
  },
  {
    "x": "merhele (m)",
    "y": " aşama, merhale"
  },
  {
    "x": "merheleya projeyî (m)",
    "y": " proje aşaması"
  },
  {
    "x": "merhemet (n)",
    "y": " acıma, merhamet"
  },
  {
    "x": "merhemet kerdene",
    "y": " acımak, merhamet etmek\r\n~Zalimî kesî rê merhemet nêkerd. (Zalim kimseye acımadı.)"
  },
  {
    "x": "merhemetkerdiş (n)",
    "y": " acıma, merhamet etme"
  },
  {
    "x": "merheva (m)",
    "y": " b. merheba (m)"
  },
  {
    "x": "meri-I (n)",
    "y": " b. meru (n)"
  },
  {
    "x": "meri-II (n)",
    "y": " b. merre (n)"
  },
  {
    "x": "merî-I (m)",
    "y": " b. marî (m)"
  },
  {
    "x": "merî-II (m)",
    "y": " b. mere-I (m)"
  },
  {
    "x": "merî-III (n)",
    "y": " b. meru (n)"
  },
  {
    "x": "merîfet (n)",
    "y": " adabımuaşeret"
  },
  {
    "x": "merînayîş (n)",
    "y": " b. mirênayîş (n)"
  },
  {
    "x": "merjêlû (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "merjî (m)",
    "y": " b. mercû (m)"
  },
  {
    "x": "merjîr, -i",
    "y": " b. macir, -e"
  },
  {
    "x": "merjîrey (m)",
    "y": " b. macirîye (m)"
  },
  {
    "x": "merjueli (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "merjû (m)",
    "y": " b. mercû (m)"
  },
  {
    "x": "merkeba (m)",
    "y": " b. mekube (m),"
  },
  {
    "x": "merkerdiş (n)",
    "y": " b. merekerdiş (n)"
  },
  {
    "x": "merkez (n)",
    "y": " 1)merkez\r\n~merkezê dayîş-girewtişî (n): alışveriş merkezi\r\n~2)odak"
  },
  {
    "x": "merkezê binawanîye û koordînasyonî",
    "y": " altyapı ve koordinasyon merkezi"
  },
  {
    "x": "merkezê dayîş-girewtişî (n)",
    "y": " alışveriş merkezi"
  },
  {
    "x": "merkezê erdlerzî (n)",
    "y": " deprem merkezi, deprem ocağı"
  },
  {
    "x": "merkezê fermandeyîya îtfaîya (n)",
    "y": " itfaiye komuta merkezi"
  },
  {
    "x": "merkezê hêrînayîş-rotişî (n)",
    "y": " alışveriş merkezi"
  },
  {
    "x": "merkezê koordînasyonê resî",
    "y": " ulaşım koordinasyon merkezi"
  },
  {
    "x": "merkezê kultur û hunerî",
    "y": " kültür ve sanat merkezi"
  },
  {
    "x": "Merkezê Kultur û Hunerî yê Cegerxwînî",
    "y": " Cegerxwîn Kültür ve Sanat Merkezi"
  },
  {
    "x": "merkezê kumandaya îtfaîya (n)",
    "y": " itfaiye komuta merkezi"
  },
  {
    "x": "merkezê xebergirewtişî (n)",
    "y": " haber alma merkezi"
  },
  {
    "x": "merkezê zanakirarî",
    "y": " bilgiişlem merkezi"
  },
  {
    "x": "merkezî, -ye",
    "y": " 1)merkezi\r\n~2)santral"
  },
  {
    "x": "mermer (n)",
    "y": " mermer "
  },
  {
    "x": "mermertiraş, -e",
    "y": " mermerci"
  },
  {
    "x": "mermertiraşî (m)",
    "y": " mermercilik"
  },
  {
    "x": "mermertiraşîye (m)",
    "y": " mermercilik"
  },
  {
    "x": "mermî (m)",
    "y": " mermi, gülle, kurşun"
  },
  {
    "x": "mermîye (m)",
    "y": " b. mermî (m)"
  },
  {
    "x": "meroe (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "meroy (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "merr (n)",
    "y": " b. mere-I (m)"
  },
  {
    "x": "merre (n)",
    "y": " fare (TE), sıçan"
  },
  {
    "x": "merreya awî (m)",
    "y": " b. merreya awîye (m)"
  },
  {
    "x": "merreya awîye (m)",
    "y": " kunduz"
  },
  {
    "x": "mereyo awî (n)",
    "y": " b. merreyo awî (n)"
  },
  {
    "x": "merreyo awî (n)",
    "y": " kunduz"
  },
  {
    "x": "merreyo kor (n)",
    "y": " körfare"
  },
  {
    "x": "merri (n)",
    "y": " b. merre (n)"
  },
  {
    "x": "mersef (n)",
    "y": " b. meşrefe (n)"
  },
  {
    "x": "mersel (n)",
    "y": " b. mesele-II (n)"
  },
  {
    "x": "mersela (m)",
    "y": " b. mesela-II (m)"
  },
  {
    "x": "mersele (m)",
    "y": " b. mesela-II (m)"
  },
  {
    "x": "merserîze (n)",
    "y": " merserize"
  },
  {
    "x": "mersev (n)",
    "y": " b. meşrefe (n)"
  },
  {
    "x": "mersila",
    "y": " b. mesela-II (m)"
  },
  {
    "x": "merşa (m)",
    "y": " b. melşe (n)"
  },
  {
    "x": "merşe (n)",
    "y": " b. melşe (n)"
  },
  {
    "x": "merşû (n)",
    "y": " b. merduşû (n)"
  },
  {
    "x": "mertag (m)",
    "y": " b. martage (m)"
  },
  {
    "x": "mertal-I (n)",
    "y": " kalkan"
  },
  {
    "x": "mertal-II (n)",
    "y": " bilmece"
  },
  {
    "x": "merteg (n)",
    "y": " b. martag (n)"
  },
  {
    "x": "mertege (m)",
    "y": " b. martage (m)"
  },
  {
    "x": "mertel (n)",
    "y": " b. mertal-II (n)"
  },
  {
    "x": "meru (n)",
    "y": " testi"
  },
  {
    "x": "merx (n)",
    "y": " ardıç"
  },
  {
    "x": "merxe (m)",
    "y": " b. merx (n)"
  },
  {
    "x": "meryemek",
    "y": " yazın sonu ve sonbaharın başlangıcı varsayılan gün (miladi takvime göre 13 Eylül)"
  },
  {
    "x": "mes (n)",
    "y": " b. mest"
  },
  {
    "x": "mesafe (m)",
    "y": " b. mesafe (n)"
  },
  {
    "x": "mesafe (n)",
    "y": " mesafe"
  },
  {
    "x": "mesaî (m)",
    "y": " mesai"
  },
  {
    "x": "mesaîya vêşî (m)",
    "y": " fazla mesai"
  },
  {
    "x": "mesaj (n)",
    "y": " mesaj "
  },
  {
    "x": "mesas (m)",
    "y": " misasî (m)"
  },
  {
    "x": "mesasî (m)",
    "y": " b. misasî (m)"
  },
  {
    "x": "mesat (n)",
    "y": " kesici araçları bilemeye yarayan demirden yapılma araç"
  },
  {
    "x": "mesat bîyayene",
    "y": " b. mesat bîyene"
  },
  {
    "x": "mesat bîyene",
    "y": " bilenmek"
  },
  {
    "x": "mesat kerdene",
    "y": " bilemek"
  },
  {
    "x": "mesayî (m)",
    "y": " b. mesaî (m)"
  },
  {
    "x": "mescîd (n)",
    "y": " mescit"
  },
  {
    "x": "mesde (n)",
    "y": " b. meşte (n)"
  },
  {
    "x": "mesder (n)",
    "y": " mastar "
  },
  {
    "x": "meseb (n)",
    "y": " b. mezheb (n)"
  },
  {
    "x": "mesef (n)",
    "y": " b. meşrefe (n)"
  },
  {
    "x": "mesela-I (zr)",
    "y": " mesela, örneğin"
  },
  {
    "x": "mesela-II (m)",
    "y": " 1)mesel (kısa öykü)\r\n~2)b. mesele-II (n) "
  },
  {
    "x": "mesele-I (n)",
    "y": " b. mesela-II (m)"
  },
  {
    "x": "mesele-II (n)",
    "y": " sorun, mesele, problem"
  },
  {
    "x": "meser (n)",
    "y": " b. muhasere (n)"
  },
  {
    "x": "meser de bîyayene",
    "y": " b. muhasere bîyene"
  },
  {
    "x": "meser de mendene",
    "y": " b. muhasere de mendene"
  },
  {
    "x": "meser domnayene",
    "y": " b. muhasere domnayene"
  },
  {
    "x": "meser şeqnayene",
    "y": " b. muhasere şeqnayene"
  },
  {
    "x": "meser ver kerdene",
    "y": " b. muhasere ver kerdene"
  },
  {
    "x": "meser wedardayene",
    "y": " b. abluka wedardayene"
  },
  {
    "x": "mesev (n)",
    "y": " b. mezheb (n)"
  },
  {
    "x": "mesêr (n)",
    "y": " b. muhasere (n)"
  },
  {
    "x": "mesêr bîyene",
    "y": " b. muhasere bîyene"
  },
  {
    "x": "mesêr de mendayene",
    "y": " b. muhasere de mendene"
  },
  {
    "x": "mesêr domnayene",
    "y": " b. muhasere domnayene"
  },
  {
    "x": "mesêr şeqnayene",
    "y": " b. muhasere şeqnayene"
  },
  {
    "x": "mesêr ver kerdene",
    "y": " b. muhasere ver kerdene"
  },
  {
    "x": "mesêr wedardayene",
    "y": " b. abluka wedardayene"
  },
  {
    "x": "mesêv (n)",
    "y": " b. mezheb (n)"
  },
  {
    "x": "mesila (m)",
    "y": " b. mesela-II"
  },
  {
    "x": "mesîn (m)",
    "y": " b. mesîne (m) "
  },
  {
    "x": "mesîne (m)",
    "y": " ibrik"
  },
  {
    "x": "mesîni (m)",
    "y": " b. mesîne (m)"
  },
  {
    "x": "meske (m)",
    "y": " b. meşke (m)"
  },
  {
    "x": "meşke sanayene",
    "y": " b. meşke şanayene"
  },
  {
    "x": "meske sanitene",
    "y": " b. meşke şanitene"
  },
  {
    "x": "mesken (n)",
    "y": " konut"
  },
  {
    "x": "meskûn, -e",
    "y": " yerleşik"
  },
  {
    "x": "mesleg (n)",
    "y": " b. meslek (n)"
  },
  {
    "x": "meslek (n)",
    "y": " meslek"
  },
  {
    "x": "meslekî, -ye",
    "y": " mesleki, mesleksel"
  },
  {
    "x": "perwerdeyo meslekî (n)",
    "y": " mesleki eğitim"
  },
  {
    "x": "mesned (n)",
    "y": " dayanak"
  },
  {
    "x": "Mesqet (n)",
    "y": " Maskat"
  },
  {
    "x": "mesrafa (n)",
    "y": " b. meşrefe (n)"
  },
  {
    "x": "mesref (n)",
    "y": " gider, masraf, harç, sarfiyat"
  },
  {
    "x": "mesref kerdene",
    "y": " harcamak"
  },
  {
    "x": "mesrefê hewênayîşî (zh) ",
    "y": "ağırlama giderleri"
  },
  {
    "x": "mesrefê rojêk",
    "y": " bir günlük gider"
  },
  {
    "x": "mesrefê serrawextî",
    "y": " yıllık gider"
  },
  {
    "x": "mesrefê serrewextî",
    "y": " b. mesrefê serrawextî"
  },
  {
    "x": "mesrefê yew roje",
    "y": " bir günlük gider"
  },
  {
    "x": "mesrefo carî (n)",
    "y": " cari masraf"
  },
  {
    "x": "mesrefo rojane",
    "y": " günlük gider"
  },
  {
    "x": "mesrefo serrane",
    "y": " yıllık gider"
  },
  {
    "x": "mesrefkerdiş (n)",
    "y": " harcama"
  },
  {
    "x": "mest-I (n)",
    "y": " b. meşte (n)"
  },
  {
    "x": "mest-II (n)",
    "y": " mest "
  },
  {
    "x": "meste (n)",
    "y": " b. meşte (n) "
  },
  {
    "x": "mesud, -e",
    "y": " mutlu, bahtiyar"
  },
  {
    "x": "mesudey (m)",
    "y": " b. mesudîye (m)"
  },
  {
    "x": "mesudê (m)",
    "y": " b. mesudî (m)"
  },
  {
    "x": "mesudênî (m)",
    "y": " b. mesudîye (m)"
  },
  {
    "x": "mesudî (m)",
    "y": " mutluluk, bahtiyarlık"
  },
  {
    "x": "mesudîye (m)",
    "y": " mutluluk, bahtiyarlık"
  },
  {
    "x": "meş (n)",
    "y": " b. meaş (n)"
  },
  {
    "x": "meşar (n)",
    "y": " b. mişar (n)"
  },
  {
    "x": "meşela (m)",
    "y": " b. mişare (m)"
  },
  {
    "x": "meşde (n)",
    "y": " b. meşte (n)"
  },
  {
    "x": "meşefe (n)",
    "y": " b. mecrefe (m)"
  },
  {
    "x": "meşene (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "meşfe (n)",
    "y": " b. meşrefe (n)"
  },
  {
    "x": "meşhur, -e",
    "y": " tanınmış, ünlü"
  },
  {
    "x": "meşk (m)",
    "y": " b. meşke (m)"
  },
  {
    "x": "meşk şûnayîne",
    "y": " b. meşke şanayene"
  },
  {
    "x": "meşke (m)",
    "y": " 1)tuluk, tulum, kırba, yayık\r\n~2)göbek (şişkin karın)"
  },
  {
    "x": "meşke nêritene",
    "y": " b. meşke nîritene"
  },
  {
    "x": "meşke nîrdene",
    "y": " b. meşke nîritene"
  },
  {
    "x": "meşke nîridene",
    "y": " b. meşke nîritene"
  },
  {
    "x": "meşke nîritene",
    "y": " yayık yaymak"
  },
  {
    "x": "meşke rasanayene",
    "y": " b. meşke şanayene"
  },
  {
    "x": "meşke şanayene",
    "y": " yayık yaymak"
  },
  {
    "x": "meşke şanitene",
    "y": " yayık yaymak"
  },
  {
    "x": "meşki (m)",
    "y": " b. meşke (m)"
  },
  {
    "x": "meşlule (m)",
    "y": " b. meşlûre (m)"
  },
  {
    "x": "meşlûl (m)",
    "y": " b. meşlûre (m)"
  },
  {
    "x": "meşlûli (m)",
    "y": " b. meşlûre (m)"
  },
  {
    "x": "meşlûr (m)",
    "y": " b. meşlûre (m)"
  },
  {
    "x": "meşlûre (m)",
    "y": " sucuk (bandırma)"
  },
  {
    "x": "meşmul (m)",
    "y": " b. meşlûre (m)"
  },
  {
    "x": "meşone (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "meşone çarnayîne",
    "y": " b. muşene çarnayene"
  },
  {
    "x": "meşrefe (n)",
    "y": " maşrapa"
  },
  {
    "x": "meşrubat (n)",
    "y": " içecek, meşrubat"
  },
  {
    "x": "meşrû",
    "y": " meşru"
  },
  {
    "x": "meşrûîyet (n)",
    "y": " meşruiyet"
  },
  {
    "x": "meşt (n)",
    "y": " b. meşte (n)"
  },
  {
    "x": "meşte (n)",
    "y": " 1)yarın\r\n~2)(mecazi) gelecek, istikbal"
  },
  {
    "x": "meştê (n)",
    "y": " b. meşte (n)"
  },
  {
    "x": "meştêrî (n)",
    "y": " ertesi gün (geçmişte)\r\n~Ez 15ê aşma gulane de şîya Dêrsim, meştêrî ez uca ra şîya Dîyarbekir. (Mayıs ayının 15’inde Dêrsim’e gittim, ertesi gün oradan Diyarbekir’e gittim.)"
  },
  {
    "x": "meşti (n)",
    "y": " b. meşte (n)"
  },
  {
    "x": "meşuene (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "meşueni (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "meşur, -i",
    "y": " b. meşhur, -e"
  },
  {
    "x": "meşuwen (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "meşuwene (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "meşûwene (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "metabolîzma (m)",
    "y": " metabolizma"
  },
  {
    "x": "metal (n)",
    "y": " metal"
  },
  {
    "x": "metalê giranî (zh)",
    "y": " ağır metaller"
  },
  {
    "x": "metalîk, -e",
    "y": " metalik"
  },
  {
    "x": "metan (n)",
    "y": " metan, bataklık gazı"
  },
  {
    "x": "metbu, -ye",
    "y": " metbû, -ye"
  },
  {
    "x": "metbû, -ye",
    "y": " basılı"
  },
  {
    "x": "meteorolog, -e",
    "y": " meteorolog"
  },
  {
    "x": "meteorolojî (n)",
    "y": " meteoroloji"
  },
  {
    "x": "meteorolojîk, -e",
    "y": " meteorolojik"
  },
  {
    "x": "metirxe (n)",
    "y": " belin (bel aracının) üzerine ayak konan parçası"
  },
  {
    "x": "metn (n)",
    "y": " metin, tekst"
  },
  {
    "x": "metod (n)",
    "y": " 1)metot, yöntem\r\n~2)usul, yöntem"
  },
  {
    "x": "metodê analîzî (n)",
    "y": " analiz metodu"
  },
  {
    "x": "metodê gureyayîşî (n)",
    "y": " çalışma yöntemi"
  },
  {
    "x": "metodê xebate (n)",
    "y": " çalışma yöntemi"
  },
  {
    "x": "metodo îlmî (n)",
    "y": " bilimsel yöntem"
  },
  {
    "x": "metodo zanistî (n)",
    "y": " bilimsel yöntem"
  },
  {
    "x": "metqab (n)",
    "y": " b. metqeb (n)"
  },
  {
    "x": "metqap (n)",
    "y": " b. metqeb (n)"
  },
  {
    "x": "metqeb (n)",
    "y": " matkap"
  },
  {
    "x": "metqep (n)",
    "y": " b. metqeb (n)"
  },
  {
    "x": "metod (n)",
    "y": " metot, usul, yöntem"
  },
  {
    "x": "metodolojî (n)",
    "y": " metodoloji"
  },
  {
    "x": "metraj (n)",
    "y": " metraj"
  },
  {
    "x": "metrajin (n)",
    "y": " metrajlı"
  },
  {
    "x": "metran (n)",
    "y": " piskopos"
  },
  {
    "x": "metre (n)",
    "y": " metre"
  },
  {
    "x": "metreh (n)",
    "y": " matrah (ekonomide)"
  },
  {
    "x": "metrekare (n)",
    "y": " metrekare"
  },
  {
    "x": "metremîkab (n)",
    "y": " metreküp"
  },
  {
    "x": "metreyî (s)",
    "y": " metrelik"
  },
  {
    "x": "çîto panc metreyî",
    "y": " beş metrelik bez"
  },
  {
    "x": "metri (n)",
    "y": " b. metre (n)"
  },
  {
    "x": "metrîk, -e (s)",
    "y": " ölçümlü, metrik"
  },
  {
    "x": "metro-I (n)",
    "y": " metro"
  },
  {
    "x": "metro-II (n)",
    "y": " b. metre (n)"
  },
  {
    "x": "metropol (n)",
    "y": " büyükşehir, büyükkent, metropol"
  },
  {
    "x": "metroşerît (n)",
    "y": " şerit metre"
  },
  {
    "x": "mevac ki",
    "y": " b. mevaje ke"
  },
  {
    "x": "mevace ke",
    "y": " b. mevaje ke"
  },
  {
    "x": "mevaje ke",
    "y": " meğer, meğerse"
  },
  {
    "x": "mevaze ke",
    "y": " b. mevaje ke"
  },
  {
    "x": "mevûndî (m)",
    "y": " b. mûndî (m)"
  },
  {
    "x": "mevûnda Eyşo-Fatma (m)",
    "y": " b. mûndîya Eyşo-Fatma (m)"
  },
  {
    "x": "mew-I (n)",
    "y": " b. mewe-I (m)"
  },
  {
    "x": "mew-II (m)",
    "y": " b. mêwe-I (m)"
  },
  {
    "x": "mewcûd, -e",
    "y": " mevcut"
  },
  {
    "x": "mewcûd bîyene",
    "y": " mevcut olmak, var olmak"
  },
  {
    "x": "mewcûdbîyayîş (n)",
    "y": " var olma, mevcut olma, varoluş"
  },
  {
    "x": "mewcûdey (m)",
    "y": " b. mewcûdîye (m)"
  },
  {
    "x": "mewcûdê (m)",
    "y": " b. mewcûdîye (m)"
  },
  {
    "x": "mewcûdîye (m)",
    "y": " varlık"
  },
  {
    "x": "mewcûdîyet (n)",
    "y": " varlık, mevcudiyet, egzistans"
  },
  {
    "x": "mewe-I (m)",
    "y": " köken (bazı bitkilerin dalları)"
  },
  {
    "x": "mewe-II (m)",
    "y": " b. mêwe-I (m)"
  },
  {
    "x": "mewi (m)",
    "y": " b. mêwe-I (m)"
  },
  {
    "x": "mewsim (n)",
    "y": " mevsim"
  },
  {
    "x": "mewsimana (m)",
    "y": " mevsimlik"
  },
  {
    "x": "mewsimane (n)",
    "y": " mevsimlik"
  },
  {
    "x": "mewsimanîye (m)",
    "y": " mevsimlik"
  },
  {
    "x": "... mewsimî (n, m)",
    "y": " mevsimlik"
  },
  {
    "x": "mewsîm (n)",
    "y": " b. mevsim (n)"
  },
  {
    "x": "mewşen (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "mewşene (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "mewşêr (m)",
    "y": " b. mahşlêre (m)"
  },
  {
    "x": "mewşêre (m)",
    "y": " b. mahşlêre (m)"
  },
  {
    "x": "mewşêri (m)",
    "y": " b. mahşlêre (m)"
  },
  {
    "x": "mewze (m)",
    "y": " b. mêwe-I (m)"
  },
  {
    "x": "mewzer (n)",
    "y": " bir tüfek çeşidi"
  },
  {
    "x": "mewzî (n)",
    "y": " mevzi"
  },
  {
    "x": "mewzu (n)",
    "y": " b. mewzû"
  },
  {
    "x": "mewzû (n)",
    "y": " konu, mevzu"
  },
  {
    "x": "mewzûat (n)",
    "y": " mevzuat"
  },
  {
    "x": "mewzûatê xidayan (n)",
    "y": " gıda mevzuatı"
  },
  {
    "x": "mexara (m)",
    "y": " b. mixara (m)"
  },
  {
    "x": "mexdenos (n)",
    "y": " b. maydanoz (n)"
  },
  {
    "x": "mexdenoz (n)",
    "y": " b. maydanoz (n)"
  },
  {
    "x": "mexdur, -e",
    "y": " mağdur"
  },
  {
    "x": "mexel-I (n)",
    "y": " hayvanların yere çöküp dinlenmesi durumu"
  },
  {
    "x": "mexel bîyene",
    "y": " hayvanların yere çöküp oturması"
  },
  {
    "x": "mexel kerdene",
    "y": " hayvanları bir yerde topluca bekletip dinlenmelerini sağlamak"
  },
  {
    "x": "mexel kewtene",
    "y": " hayvanların yere çöküp oturması"
  },
  {
    "x": "mexel-II (n)",
    "y": " b. mexl (n)"
  },
  {
    "x": "mexel dayene",
    "y": " b. mexl dayene"
  },
  {
    "x": "mexel rotene",
    "y": " b. mexl rotene"
  },
  {
    "x": "mexelê rezî (m)",
    "y": " b. mexlê rezî (n)"
  },
  {
    "x": "mexelca (n)",
    "y": " hayvanların topluca bekletilip dinlendirildiği yer"
  },
  {
    "x": "mexeli (m)",
    "y": " b. mexel (n)"
  },
  {
    "x": "mexeli kewteni",
    "y": " b. mexel kewtene"
  },
  {
    "x": "mexeli bîyayîne",
    "y": " b. mexel bîyene"
  },
  {
    "x": "mexeli kerdeni",
    "y": " b. mexel kerdene"
  },
  {
    "x": "mexer (n)",
    "y": " düz ve verimli tarla/arazi"
  },
  {
    "x": "mexera (m)",
    "y": " b. mixara (m)"
  },
  {
    "x": "mexl (n)",
    "y": " yıllık ürün"
  },
  {
    "x": "mexl dayene",
    "y": " yıllık ürünü götürü fiyatla (toptan) satmak"
  },
  {
    "x": "mexl rotene",
    "y": " yıllık ürünü götürü fiyatla (toptan) satmak"
  },
  {
    "x": "mexlê rezî (n)",
    "y": " bağdaki yıllık üzümün hepsi"
  },
  {
    "x": "mexlane",
    "y": " toptan (ticaret için)"
  },
  {
    "x": "mexlub bîyene",
    "y": " b. mexlûb bîyene"
  },
  {
    "x": "mexlûb bîyene",
    "y": " yenilmek, yenilgiye uğramak, bozguna uğramak, bozguna vermek, hezimete uğramak"
  },
  {
    "x": "mexlûbîye (m)",
    "y": " yenilgi, bozgun, hezimet"
  },
  {
    "x": "mexlûbîyet (n)",
    "y": " yenilgi, bozgun, hezimet"
  },
  {
    "x": "mexmel (m)",
    "y": " b. mexmele (m)"
  },
  {
    "x": "mexmele (m)",
    "y": " başörtü, başörtüsü, kıvrak (başörtü), tülbent"
  },
  {
    "x": "mexmeli (m)",
    "y": " b. mexmele (m)"
  },
  {
    "x": "mexreb (n)",
    "y": " akşam"
  },
  {
    "x": "mexsed (n)",
    "y": " b. meqsed (n) "
  },
  {
    "x": "mexsus",
    "y": " 1)mahsus, özellikle\r\n~2)şakadan\r\n~3)yalancıktan, yalandan"
  },
  {
    "x": "mexsus",
    "y": " başta"
  },
  {
    "x": "mexsûs",
    "y": " b. mexsus"
  },
  {
    "x": "mexzuz",
    "y": " b. mexsus"
  },
  {
    "x": "mey-I (m)",
    "y": " şarap"
  },
  {
    "x": "mey-II (m)",
    "y": " b. mî (m)"
  },
  {
    "x": "mey-III (m)",
    "y": " b. maye-II (m)"
  },
  {
    "x": "mey-IV (n)",
    "y": " b. mîyane (n)"
  },
  {
    "x": "meyandis (n)",
    "y": " b. muhendis, -e"
  },
  {
    "x": "meyandisê (m)",
    "y": " b. muhendisî (m), muhendisîye (m)"
  },
  {
    "x": "meyandûz (n)",
    "y": " b. muhendis, -e"
  },
  {
    "x": "meyandûzî (m)",
    "y": " b. muhendisî (m), muhendisîye (m)"
  },
  {
    "x": "meyasîr (n)",
    "y": " egzama, mayasıl"
  },
  {
    "x": "meybi (m)",
    "y": " b. mêwe-I (m)"
  },
  {
    "x": "(meybi) piştiş",
    "y": " b. (mêwe) pîştene"
  },
  {
    "x": "meydan (n)",
    "y": " meydan"
  },
  {
    "x": "meydan waştene",
    "y": " meydan okumak"
  },
  {
    "x": "meydanê teyara (n)",
    "y": " havaalanı, uçak alanı, tayyare meydanı"
  },
  {
    "x": "meydanvetiş (n)",
    "y": " afişe etme"
  },
  {
    "x": "meydanwaştiş (n)",
    "y": " meydan okuma"
  },
  {
    "x": "meydo (n)",
    "y": " b. meydan (n) "
  },
  {
    "x": "meydon (n)",
    "y": " b. meydan (n)"
  },
  {
    "x": "meydon waştene",
    "y": " b. meydan waştene"
  },
  {
    "x": "meydonwaştiş (n)",
    "y": " b. meydanwaştiş (n)"
  },
  {
    "x": "meydû (n)",
    "y": " b. meydan (n) "
  },
  {
    "x": "meydûn (n)",
    "y": " b. meydan (n) "
  },
  {
    "x": "meydûn waştene",
    "y": " b. meydan waştene"
  },
  {
    "x": "meydûnî teyara (n)",
    "y": " b. meydanê teyara (n)"
  },
  {
    "x": "meydûnwaştiş (n)",
    "y": " b. meydanwaştiş (n)"
  },
  {
    "x": "meyeli (m)",
    "y": " b. mexel (n)"
  },
  {
    "x": "meyeli bîyayîne",
    "y": " b. mexel bîyene"
  },
  {
    "x": "meyeli kerdeni",
    "y": " b. mexel kerdene"
  },
  {
    "x": "meyeli kewtene",
    "y": " b. mexel kewtene"
  },
  {
    "x": "meyene (n)",
    "y": " b. muayene (n)"
  },
  {
    "x": "meyene kerdene",
    "y": " b. muayene kerdene"
  },
  {
    "x": "meyesi (m)",
    "y": " b. mêse (m) "
  },
  {
    "x": "meyi (m)",
    "y": " b. mî (m)"
  },
  {
    "x": "meyit, -e",
    "y": " b. meyît, -e"
  },
  {
    "x": "meyîl (n)",
    "y": " b. meyl (n)"
  },
  {
    "x": "meyîr (m)",
    "y": " b. mehîre (m)"
  },
  {
    "x": "meyîre (m)",
    "y": " b. mehîre (m)"
  },
  {
    "x": "meyît, -e-I",
    "y": " ölü"
  },
  {
    "x": "meyît-II (n)",
    "y": " cenaze"
  },
  {
    "x": "meyl (n)",
    "y": " 1)eğilim\r\n~2)eğim\r\n~meylo xeternak (n): tehlikeli eğim"
  },
  {
    "x": "meylê mecra (n)",
    "y": " mecra eğimi, yatak eğimi"
  },
  {
    "x": "meylo tehlukedar (n)",
    "y": " tehlikeli eğim"
  },
  {
    "x": "meylo xeternak (n)",
    "y": " tehlikeli eğim"
  },
  {
    "x": "meylpeym (n)",
    "y": " eğimölçer"
  },
  {
    "x": "meyman, -e",
    "y": " b. mêman, -e"
  },
  {
    "x": "meyman guretene",
    "y": " b. mêman girewtene"
  },
  {
    "x": "meyman hewênayene",
    "y": " b. mêman hewênayene"
  },
  {
    "x": "meyman kerdene",
    "y": " b. mêman kerdene"
  },
  {
    "x": "meymandar, -e",
    "y": " b. mêmandar, -e"
  },
  {
    "x": "meymandarî (m)",
    "y": " b. mêmandarî (m)"
  },
  {
    "x": "mêmandarî kerdene",
    "y": " ağırlamak"
  },
  {
    "x": "meymanê (m)",
    "y": " b. mêmanî (m)"
  },
  {
    "x": "meymanên (m)",
    "y": " b. mêmanênî (m)"
  },
  {
    "x": "meymanênî (m)",
    "y": " b. mêmanênî (m)"
  },
  {
    "x": "meymanguretiş (n)",
    "y": " b. mêmangirewtiş (n)"
  },
  {
    "x": "meymanhewênayîş (n)",
    "y": " b. mêmanhewênayîş (n)"
  },
  {
    "x": "meymanî (m)",
    "y": " b. mêmantî (m)"
  },
  {
    "x": "meymanîye (m)",
    "y": " b. mêmanîye (m)"
  },
  {
    "x": "meymankî (n)",
    "y": " b. mêmankî (n)"
  },
  {
    "x": "meymankî roniştene",
    "y": " b. mêmankî roniştene"
  },
  {
    "x": "meymankerdiş (n)",
    "y": " b. mêmankerdiş (n)"
  },
  {
    "x": "meymanperwer, -e",
    "y": " b. mêmanperwer, -e"
  },
  {
    "x": "meymantî (m)",
    "y": " b. mêmantî (m)"
  },
  {
    "x": "meymantîne (m)",
    "y": " b. mêmantîye (m)"
  },
  {
    "x": "meymantîye (m)",
    "y": " b. mêmantîye (m)"
  },
  {
    "x": "meymun, -e-I",
    "y": " maymun"
  },
  {
    "x": "meymune-II (m)",
    "y": " topaç"
  },
  {
    "x": "meymuni (m)",
    "y": " b. meymune (m)"
  },
  {
    "x": "meymûn-I (m)",
    "y": " b. meymune (m)"
  },
  {
    "x": "meymûn-II (n)",
    "y": " b. mêman (n)"
  },
  {
    "x": "meyne (n)",
    "y": " b. mîyane (n)"
  },
  {
    "x": "meyne guretene",
    "y": " b. mîyane girewtene"
  },
  {
    "x": "meyrr (m)",
    "y": " b. mere-I (m)"
  },
  {
    "x": "meys û mozi",
    "y": " b. mês û moze"
  },
  {
    "x": "meysi (m)",
    "y": " b. mêse (m) "
  },
  {
    "x": "meyşt (n)",
    "y": " b. meşte (n)"
  },
  {
    "x": "meyşti (n)",
    "y": " b. meşte (n)"
  },
  {
    "x": "meyt, -i",
    "y": " b. meyît, -e"
  },
  {
    "x": "meyve (m)",
    "y": " b. mêwe-I (m)"
  },
  {
    "x": "meyvi (m)",
    "y": "b. mêwe-I (m)"
  },
  {
    "x": "meywa (m)",
    "y": " b. meywe (n)"
  },
  {
    "x": "meywe (n)",
    "y": " meyve"
  },
  {
    "x": "meywer, -e",
    "y": " içici, içkici, şarap içen "
  },
  {
    "x": "meyweroş, -e",
    "y": " manav"
  },
  {
    "x": "meyweroşîye (m)",
    "y": " manavlık"
  },
  {
    "x": "meyxane (n)",
    "y": " meyhane"
  },
  {
    "x": "meyxanewo bisaz (n)",
    "y": " b. meyxaneyo bisaz (n)"
  },
  {
    "x": "meyxaneyo bisaz (n)",
    "y": " çalgılı meyhane"
  },
  {
    "x": "meyxano bisaz (n)",
    "y": " b. meyxaneyo bisaz (n)"
  },
  {
    "x": "meyxanedar, -e",
    "y": " meyhaneci"
  },
  {
    "x": "meyxoş, -e",
    "y": " mayhoş"
  },
  {
    "x": "meyxoşey (m)",
    "y": " b. meyxoşî (m), meyxoşîye (m)"
  },
  {
    "x": "meyxoşê (m)",
    "y": " b. meyxoşî (m), meyxoşîye (m)"
  },
  {
    "x": "meyxoşî (m)",
    "y": " mayhoşluk"
  },
  {
    "x": "meyxoşîye (m)",
    "y": " mayhoşluk"
  },
  {
    "x": "mezal (n)",
    "y": " b. mecal (n)"
  },
  {
    "x": "mezat (n)",
    "y": " 1)açık artırma, müzayede, mezat\r\n~2)hayvan pazarı"
  },
  {
    "x": "mezate (n)",
    "y": " b. mezat (n)"
  },
  {
    "x": "mezbeta (m)",
    "y": " tutanak, mazbata, zabıt"
  },
  {
    "x": "mezbete (n)",
    "y": " b. mezbeta (m)"
  },
  {
    "x": "mezbut, -e",
    "y": " sağlam"
  },
  {
    "x": "mezbut bîyene",
    "y": " sağlam olmak, sağlamlaşmak"
  },
  {
    "x": "mezbut kerdene",
    "y": " sağlamlaştırmak"
  },
  {
    "x": "mezbutbîyayîş (n)",
    "y": " sağlam olma, sağlamlaşma"
  },
  {
    "x": "mezbutkerdiş (n)",
    "y": " sağlamlaştırma"
  },
  {
    "x": "mezbutê (m)",
    "y": " b. mezbutî (m)"
  },
  {
    "x": "mezbutî (m)",
    "y": " sağlamlık"
  },
  {
    "x": "mezbutîye (m)",
    "y": " sağlamlık"
  },
  {
    "x": "mezbût, -i",
    "y": " b. mezbut, -e"
  },
  {
    "x": "mezbût bîyayene",
    "y": " b. mezbut bîyene"
  },
  {
    "x": "mezbût kerdene",
    "y": " b. mezbut kerdene"
  },
  {
    "x": "mezbûtbîyayîş (n)",
    "y": " b. mezbutbîyayîş (n)"
  },
  {
    "x": "mezbûtî (m)",
    "y": " b. mezbutî (m)"
  },
  {
    "x": "mezbûtkerdiş (n)",
    "y": " b. mezbutkerdiş (n)"
  },
  {
    "x": "mezeb (n)",
    "y": " b. mezheb (n)"
  },
  {
    "x": "mezel (n)",
    "y": " 1)b. mezelan (n)\r\n~2)b. mezele (m)"
  },
  {
    "x": "mezelan (n)",
    "y": " mezarlık"
  },
  {
    "x": "mezele (m)",
    "y": " mezar"
  },
  {
    "x": "mezelon (m)",
    "y": " b. mezelan (n)"
  },
  {
    "x": "mezelûn (m)",
    "y": " b. mezelan (n)"
  },
  {
    "x": "mezg (n)",
    "y": " 1)beyin\r\n~mezg şutene: beyin yıkamak\r\n~2)ilik"
  },
  {
    "x": "mezg şutene",
    "y": " beyin yıkamak"
  },
  {
    "x": "mezgê asteyî (n)",
    "y": " b. mezgê esteyî (n)"
  },
  {
    "x": "mezgê esteyî (n)",
    "y": " ilik"
  },
  {
    "x": "mezgê kateyî (n)",
    "y": " ilik"
  },
  {
    "x": "mezgê katî (n)",
    "y": " b. mezgê kateyî"
  },
  {
    "x": "mezgî astî (n)",
    "y": " b. mezgê esteyî (n)"
  },
  {
    "x": "mezgşutiş (n)",
    "y": " beyin yıkama"
  },
  {
    "x": "mezheb (n)",
    "y": " mezhep"
  },
  {
    "x": "mezhebe (m)",
    "y": " b. mezheb (n)"
  },
  {
    "x": "mezhep (n)",
    "y": " b. mezheb (n)"
  },
  {
    "x": "mezin (n)",
    "y": " b. muezîn (n)"
  },
  {
    "x": "mezinî (m)",
    "y": " b. muezînî (m)"
  },
  {
    "x": "mezîn (n)",
    "y": " b. mêzîn (n)"
  },
  {
    "x": "mezmur, -e",
    "y": " b. mecbur, -e"
  },
  {
    "x": "meznun, -e",
    "y": " maznun, sanık"
  },
  {
    "x": "Mezolîtîk (n)",
    "y": " Orta Taş Çağı, Orta Taş Devri (M. Ö. 12.000-9000)"
  },
  {
    "x": "mezra (m)",
    "y": " mezra"
  },
  {
    "x": "mezro (n)",
    "y": " mezura, mezür"
  },
  {
    "x": "mezronî (m)",
    "y": " b. mazirma (m)"
  },
  {
    "x": "mezun, -e",
    "y": " mezun"
  },
  {
    "x": "mezun bîyene",
    "y": " mezun olmak "
  },
  {
    "x": "mezun kerdene",
    "y": " mezun etmek "
  },
  {
    "x": "mezunîye (m)",
    "y": " mezuniyet"
  },
  {
    "x": "mezunîyet (n)",
    "y": " mezuniyet"
  },
  {
    "x": "mê (n)",
    "y": " b. mîyane (n)"
  },
  {
    "x": "mêdû (n)",
    "y": " b. meydan (n) "
  },
  {
    "x": "mêdûn (n)",
    "y": " b. meydan (n) "
  },
  {
    "x": "mêl-I (n)",
    "y": " b. meyl (n)"
  },
  {
    "x": "mêl-II (n)",
    "y": " b. mexel (n)"
  },
  {
    "x": "mêl bîyayene",
    "y": " b. mexel bîyene"
  },
  {
    "x": "mêl kerdene",
    "y": " b. mexel kerdene"
  },
  {
    "x": "mêl kotene",
    "y": " b. mexel kewtene"
  },
  {
    "x": "mêle (n)",
    "y": " b. mexel (n)"
  },
  {
    "x": "mêman, -e",
    "y": " konuk, misafir"
  },
  {
    "x": "mêman girewtene",
    "y": " konuk almak, misafir almak, konuk ağırlamak"
  },
  {
    "x": "mêman guretene",
    "y": " b. mêman girewtene"
  },
  {
    "x": "mêman hewênayene",
    "y": " konuk ağırlamak, misafir ağırlamak, konuk ağırlamak"
  },
  {
    "x": "mêman kerdene",
    "y": " konuk etmek, misafir etmek, konuk ağırlamak"
  },
  {
    "x": "mêman wedarnayene",
    "y": " konuk ağırlamak, misafir ağırlamak"
  },
  {
    "x": "mêman weriznayene",
    "y": " konuk ağırlamak, misafir ağırlamak"
  },
  {
    "x": "mêmandar, -e",
    "y": " konukçu, konuk ağırlayan, mihmandar, ev sahibi"
  },
  {
    "x": "mêmandarî (m)",
    "y": " konukluk, misafirlik"
  },
  {
    "x": "mêmandarîkerdiş (n)",
    "y": " ağırlama"
  },
  {
    "x": "mêmaney (m)",
    "y": " b. mêmanî (m)"
  },
  {
    "x": "mêmanênî (m)",
    "y": " konukluk, misafirlik"
  },
  {
    "x": "mêmangirewtiş (n)",
    "y": " konuk alma, misafir alma, konuk ağırlama"
  },
  {
    "x": "mêmanguretiş (n)",
    "y": " b. mêmangirewtiş (n)"
  },
  {
    "x": "mêmanhewênayîş (n)",
    "y": " konuk ağırlama, misafir ağırlama"
  },
  {
    "x": "mêmanî (m)",
    "y": " konukluk, misafirlik"
  },
  {
    "x": "mêmanîye (m)",
    "y": " konukluk, misafirlik"
  },
  {
    "x": "mêmankerdiş (n)",
    "y": " konuk etme, misafir etme, konuk ağırlama"
  },
  {
    "x": "mêmankî (n)",
    "y": " bağdaş"
  },
  {
    "x": "mêmankî roniştene",
    "y": " bağdaş kurmak"
  },
  {
    "x": "mêmanperwer, -e",
    "y": " konuksever, misafirperver"
  },
  {
    "x": "mêmantî (m)",
    "y": " konukluk, misafirlik"
  },
  {
    "x": "mêmantîye (m)",
    "y": " konukluk, misafirlik"
  },
  {
    "x": "mêmanwedarnayîş (n)",
    "y": " konuk ağırlama, misafir ağırlama"
  },
  {
    "x": "mêmanweriznayîş (n)",
    "y": " konuk ağırlama¸ misafir ağırlama"
  },
  {
    "x": "mêmo (n)",
    "y": " b. mêman (n)"
  },
  {
    "x": "mêmon, -i",
    "y": " b. mêman, -e"
  },
  {
    "x": "mêmon girewtene",
    "y": " b. mêman girewtene"
  },
  {
    "x": "mêmon heweynayene",
    "y": " b. mêman hewênayene"
  },
  {
    "x": "mêmon kerdene",
    "y": " b. mêman kerdene"
  },
  {
    "x": "mêmon wedarnayene",
    "y": " b. mêman wedarnayene"
  },
  {
    "x": "mêmon wereznayene",
    "y": " b. mêman weriznayene"
  },
  {
    "x": "mêmondar, -i",
    "y": " b. mêmandar, -e"
  },
  {
    "x": "mêmongirewtiş (n) ",
    "y": "b. mêmangirewtiş (n)"
  },
  {
    "x": "mêmonheweynayîş (n)",
    "y": " b. mêmanhewênayîş (n)"
  },
  {
    "x": "mêmonkerdiş (n)",
    "y": " b. mêmankerdiş (n)"
  },
  {
    "x": "mêmonwedarnayîş (n)",
    "y": " b. mêmanwedarnayîş (n)"
  },
  {
    "x": "mêmonwereznayîş (n)",
    "y": " b. mêmanweriznayîş (n)"
  },
  {
    "x": "mêmun, -i",
    "y": " b. meymun, -e"
  },
  {
    "x": "mêmû (n)",
    "y": " b. mêman (n)"
  },
  {
    "x": "mêmûn (n)",
    "y": " b. mêman (n)"
  },
  {
    "x": "mêmûn girotene",
    "y": " b. mêman girewtene"
  },
  {
    "x": "mêmûn hewînayene",
    "y": " b. mêman hewênayene"
  },
  {
    "x": "mêmûn kerdene",
    "y": " b. mêman kerdene"
  },
  {
    "x": "mêmûn wedarnayene",
    "y": " b. mêman wedarnayene"
  },
  {
    "x": "mêmûn wereznayene",
    "y": " b. mêman weriznayene"
  },
  {
    "x": "mêmûngirotiş (n)",
    "y": " b. mêmangirewtiş (n)"
  },
  {
    "x": "mêmûnhewînayîş (n)",
    "y": " b. mêmanhewênayîş (n)"
  },
  {
    "x": "mêmûnkerdiş (n)",
    "y": " b. mêmankerdiş (n)"
  },
  {
    "x": "mêmûnkî (n)",
    "y": " b. mêmankî (n)"
  },
  {
    "x": "mêmûnkî roniştene",
    "y": " b. mêmankî roniştene"
  },
  {
    "x": "mêmûnwedarnayîş (n)",
    "y": " b. mêmanwedarnayîş (n)"
  },
  {
    "x": "mêmûnwereznayîş (n)",
    "y": " b. mêmanweriznayîş (n)"
  },
  {
    "x": "mên (n)",
    "y": " b. mîyan (n)"
  },
  {
    "x": "mêndar (n)",
    "y": " b. mîyandar (n)"
  },
  {
    "x": "mêne (n)",
    "y": " b. mîyane (n) "
  },
  {
    "x": "mêne ronayene",
    "y": " b. mîyane ronayene"
  },
  {
    "x": "mêne rûnayene",
    "y": " b. mîyane ronayene"
  },
  {
    "x": "mêneb (n)",
    "y": " kiler"
  },
  {
    "x": "mênew (n)",
    "y": " b. mêneb (n)"
  },
  {
    "x": "mêr (m)",
    "y": " b. mere-I (m)"
  },
  {
    "x": "mêras (n)",
    "y": " b. mîras (n)"
  },
  {
    "x": "mêrat, -e",
    "y": " meret"
  },
  {
    "x": "mêrde (n)",
    "y": " 1)erkek, er\r\n~Orada bir erkek vardı. (Wide yo mêrde bî.)\r\n~\t\r\n~2)koca, erkek eş"
  },
  {
    "x": "mêrde û cinî",
    "y": " b. cinî û mêrde"
  },
  {
    "x": "mêrde-cinî",
    "y": " b. cinî-mêrde"
  },
  {
    "x": "mêrdeyê şewe (n)",
    "y": " gulyabani"
  },
  {
    "x": "mêrdek (n)",
    "y": " 1)adam, adamın biri\r\n~2)bir adam, bir insan "
  },
  {
    "x": "mêrdewan (n)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "mêrdi (n)",
    "y": " b. mêrde (n)"
  },
  {
    "x": "mêrdim, -i",
    "y": " b. merdim, -e"
  },
  {
    "x": "mêrdim vano qey",
    "y": " b. merdim vano qey"
  },
  {
    "x": "mêrdim vonû qey",
    "y": " b. merdim vano qey"
  },
  {
    "x": "mêrdimaney (m)",
    "y": " b. merdimîye (m) "
  },
  {
    "x": "mêrdimanî (m)",
    "y": " b. merdimîye (m) "
  },
  {
    "x": "mêrdimey (m)",
    "y": " b. merdimîye (m) "
  },
  {
    "x": "mêrdimî (m)",
    "y": " b. merdimîye (m) "
  },
  {
    "x": "mêrdîwan (n)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "mêrdîwûn (m)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "mêrdîyûn (m)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "mêrdum, -i",
    "y": " b. merdim, -e "
  },
  {
    "x": "mêre (m)",
    "y": " b. mere-I (m)"
  },
  {
    "x": "mêrek (n)",
    "y": " b. mêrik (n), mêrdek (n)"
  },
  {
    "x": "mêres (n)",
    "y": " b. mîras (n)"
  },
  {
    "x": "mêrg (m)",
    "y": " b. merge (m)"
  },
  {
    "x": "mêrgi (m)",
    "y": " b. merge (m)"
  },
  {
    "x": "mêrik (n)",
    "y": " 1)adam, adamın biri\r\n~2)bay"
  },
  {
    "x": "mêrkuj, -e",
    "y": " insan öldüren kimse, katil, cani, kıyıcı"
  },
  {
    "x": "mêrkujane",
    "y": " canice, caniyane"
  },
  {
    "x": "mêrkujîye (m)",
    "y": " canilik, kıyıcılık"
  },
  {
    "x": "mêrr (m)",
    "y": " b. mere-I (m)"
  },
  {
    "x": "mêrrî (m)",
    "y": " üvey anne, analık, annelik"
  },
  {
    "x": "...mêrrî",
    "y": " üvey"
  },
  {
    "x": "mêrtal (n)",
    "y": " b. martag (n)"
  },
  {
    "x": "mêrxas, -e",
    "y": " yiğit, alp, kahraman; acar (gözü pek)"
  },
  {
    "x": "mêrxasênî (m)",
    "y": " yiğitlik, alplık, kahramanlık; gözü peklik"
  },
  {
    "x": "mêrxasî (m)",
    "y": " yiğitlik, alplık, kahramanlık; gözü peklik"
  },
  {
    "x": "mêrxasîye (m)",
    "y": " yiğitlik, alplık, kahramanlık; gözü peklik"
  },
  {
    "x": "mêse (m)",
    "y": " 1)sinek, karasinek\r\n~2)b. mêsa hingimênî (m)"
  },
  {
    "x": "mês û mor",
    "y": " börtü böcek"
  },
  {
    "x": "mês û moze",
    "y": " börtü böcek"
  },
  {
    "x": "mêsa çeqere (m)",
    "y": " yabanarısı "
  },
  {
    "x": "mêsa engemênî (m)",
    "y": " b. mêsa hingimênî (m)"
  },
  {
    "x": "mêsa hemgên (m)",
    "y": " b. mêsa hingimênî (m)"
  },
  {
    "x": "mêsa hemgênî (m)",
    "y": " b. mêsa hingimênî (m)"
  },
  {
    "x": "mêsa hingan (m)",
    "y": " b. mêsa hingimênî (m)"
  },
  {
    "x": "mêsa hingimênî (m)",
    "y": " arı, bal arısı"
  },
  {
    "x": "mêsi (m)",
    "y": " b. mêse (m)"
  },
  {
    "x": "mêsi kerdeni",
    "y": " b. moze kerdene"
  },
  {
    "x": "mêsîn (m)",
    "y": " b. mesîne (m)"
  },
  {
    "x": "mêşe (m)",
    "y": " b. mêse (m) "
  },
  {
    "x": "mêşa çeqere (m)",
    "y": " b. mêsa çeqere (m)"
  },
  {
    "x": "mêşa hemgênî (m)",
    "y": " b. mêsa hingimênî (m)"
  },
  {
    "x": "mêşedîn (n)",
    "y": " arı kovanının konduğu yer"
  },
  {
    "x": "mêşi (m)",
    "y": " b. mêse (m)"
  },
  {
    "x": "mêşi kerdeni",
    "y": " b. moze kerdene"
  },
  {
    "x": "mêşina (m)",
    "y": " b. mêşna (m)"
  },
  {
    "x": "mêşine (m)",
    "y": " b. mêşna (m) "
  },
  {
    "x": "mêşîn (n)",
    "y": " meşin"
  },
  {
    "x": "mêşîne (m)",
    "y": " b. mêşna (m) "
  },
  {
    "x": "mêşna (m)",
    "y": " koyun"
  },
  {
    "x": "mêşt (n)",
    "y": " b. meşte (n) "
  },
  {
    "x": "mêşte (n)",
    "y": " b. meşte (n) "
  },
  {
    "x": "mêtro (n)",
    "y": " b. metre (n)"
  },
  {
    "x": "mêw (m)",
    "y": " b. mêwe-I (m)"
  },
  {
    "x": "mêwe-I (m)",
    "y": " asma, tevek"
  },
  {
    "x": "(mêwe) piro dayene",
    "y": " teveğin tutunması veya dik durması için dallarını hereklere sarmak"
  },
  {
    "x": "(mêwe) pîştene",
    "y": " teveğin tutunması veya dik durması için dallarını hereklere sarmak"
  },
  {
    "x": "mêwe-II (m)",
    "y": " b. meywe (n)\r\n~“Kundire mêwa cenetî ya.”"
  },
  {
    "x": "mêwe-III (n)",
    "y": " b. meywe (n)"
  },
  {
    "x": "mêwi (m)",
    "y": " b. mêwe-I (m)"
  },
  {
    "x": "mêwi (n)",
    "y": " b. meywe (n)"
  },
  {
    "x": "mêx (n)",
    "y": " b. mix (n)"
  },
  {
    "x": "mêx dayene ... ro",
    "y": " b. mix ... ro dayene"
  },
  {
    "x": "mêx kerdene",
    "y": " b. mix kerdene"
  },
  {
    "x": "mêx kutene",
    "y": " b. mix kutene"
  },
  {
    "x": "mêx piro dayene",
    "y": " b. mix ... ro dayene"
  },
  {
    "x": "mêx ... ro dayene",
    "y": " b. mix ... ro dayene"
  },
  {
    "x": "mêxoş, -e",
    "y": " b. meyxoş, -e"
  },
  {
    "x": "mêxoşê (m)",
    "y": " b. meyxoşî (m), meyxoşîye (m)"
  },
  {
    "x": "mêxş (m)",
    "y": " b. mêse (m)"
  },
  {
    "x": "mêydan (n)",
    "y": " b. meydan (n) "
  },
  {
    "x": "mêyit, -e",
    "y": " b. meyît, -e"
  },
  {
    "x": "mêyît, -e",
    "y": " b. meyît, -e"
  },
  {
    "x": "mêyman, -e",
    "y": " b. mêman, -e"
  },
  {
    "x": "mêymandar, -e",
    "y": " b. mêmandar, -e"
  },
  {
    "x": "mêymanên (m)",
    "y": " b. mêmanênî (m)"
  },
  {
    "x": "mêymantî (m)",
    "y": " b. mêmantî (m)"
  },
  {
    "x": "mêymantîne (m)",
    "y": " b. mêmantîye (m)"
  },
  {
    "x": "mêywe (n)",
    "y": " b. meywe (n)"
  },
  {
    "x": "mêzîn (n)",
    "y": " terazi, tartaç"
  },
  {
    "x": "mi",
    "y": " ben (bükünlü durumda)"
  },
  {
    "x": "mi çim de",
    "y": " bana göre (benim gözünde)"
  },
  {
    "x": "mic (n)",
    "y": " b. mij (n)"
  },
  {
    "x": "micewî (zh)",
    "y": " b. mujeyî (zh)"
  },
  {
    "x": "micey (zh)",
    "y": " b. mujeyî (zh)"
  },
  {
    "x": "micêc (n)",
    "y": " 1)canı çekme\r\n~2)konuşma isteği\r\n~\t\r\n~Micêcê ey abîyo, hinî nêvindeno. (Konuşma isteği açılmış, artık durmaz.)"
  },
  {
    "x": "micêcê ... birîyayene",
    "y": " canı çekmemek"
  },
  {
    "x": "micilfe (n)",
    "y": " b. mecrefe (m)"
  },
  {
    "x": "micirfe (n)",
    "y": " b. mecrefe (m)"
  },
  {
    "x": "miclewri (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "miclok (n)",
    "y": " b. mijlorek (n)"
  },
  {
    "x": "miclol (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "micloli (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "miclor (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "miclore (n)",
    "y": " b. mijlorek (n)"
  },
  {
    "x": "micmewl (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "micowher (n)",
    "y": " b. mucewher (n)"
  },
  {
    "x": "miç-I (m)",
    "y": " b. maçî (m)"
  },
  {
    "x": "miç-II (n)",
    "y": " b. maçî (m)"
  },
  {
    "x": "miç kerdene",
    "y": " b. maçî kerdene"
  },
  {
    "x": "miçi (m)",
    "y": " b. maçî (m)"
  },
  {
    "x": "miçi kerdene",
    "y": " b. maçî kerdene"
  },
  {
    "x": "miçî (m)",
    "y": " b. maçî (m)"
  },
  {
    "x": "miçî kerdene",
    "y": " b. maçî kerdene"
  },
  {
    "x": "midafa (m)",
    "y": " b. mudafa (m)"
  },
  {
    "x": "midafa tezî (m)",
    "y": " b. mudafaya tezî (m)"
  },
  {
    "x": "midafaya tezî (m)",
    "y": " b. mudafaya tezî (m)"
  },
  {
    "x": "midafe (m)",
    "y": " b. mudafa (m)"
  },
  {
    "x": "midaxele (n)",
    "y": " b. mudaxele (n)"
  },
  {
    "x": "mide (n)",
    "y": " b. mudet (n)"
  },
  {
    "x": "midexele (n)",
    "y": " b. mudaxele (n)"
  },
  {
    "x": "midur, -e",
    "y": " b. mudir, -e"
  },
  {
    "x": "midure (m)",
    "y": " b. mudire (m)"
  },
  {
    "x": "mifetîş, -e",
    "y": " b. mufetîş, -e"
  },
  {
    "x": "mifirik, -e",
    "y": " bir kuş çeşidi"
  },
  {
    "x": "mifte (n)",
    "y": " anahtar"
  },
  {
    "x": "miftûbelaş, -e",
    "y": " çok ucuz, yok pahasına, ölü fiyatına"
  },
  {
    "x": "mihafezekar, -e",
    "y": " b. muhafezekar, -e"
  },
  {
    "x": "mihafezekarîye (m)",
    "y": " b. muhafezekarîye (m)"
  },
  {
    "x": "mihafiz, -e",
    "y": " b. muhafiz, -e"
  },
  {
    "x": "mihal (n)",
    "y": " b. mehal (n)"
  },
  {
    "x": "mihendis (n)",
    "y": " b. muhendis, -e"
  },
  {
    "x": "mihendisey (m)",
    "y": " b. muhendisî (m), muhendisîye (m)"
  },
  {
    "x": "mihendîs, -i",
    "y": " b. muhendis, -e"
  },
  {
    "x": "mihendîsê (m)",
    "y": " b. muhendisî (m), muhendisîye (m)"
  },
  {
    "x": "mihendîsî (m)",
    "y": " b. muhendisî (m)"
  },
  {
    "x": "mihîm, -e",
    "y": " muhîm, -e"
  },
  {
    "x": "mihîn (n)",
    "y": " b. mîyîn (n)"
  },
  {
    "x": "mihrîcane (m)",
    "y": " festival"
  },
  {
    "x": "mihtac, -i",
    "y": " b. muhtac, -e"
  },
  {
    "x": "miîne (n)",
    "y": " b. muayene (n)"
  },
  {
    "x": "miîne kerdene",
    "y": " b. muayene kerdene"
  },
  {
    "x": "mij (n)",
    "y": " sis"
  },
  {
    "x": "mij kerdene",
    "y": " sislendirmek"
  },
  {
    "x": "mijo gemarin (n)",
    "y": " kirli sis"
  },
  {
    "x": "mija (m)",
    "y": " b. mujeyî (zh)"
  },
  {
    "x": "mijag (zh)",
    "y": " b. mujeyî (zh)"
  },
  {
    "x": "mijagî (zh)",
    "y": " b. mujeyî (zh) "
  },
  {
    "x": "mijangî (zh)",
    "y": " b. mujeyî (zh) "
  },
  {
    "x": "mije (m)",
    "y": " b. mujeyî (zh)"
  },
  {
    "x": "mijey (zh)",
    "y": " b. mujeyî (zh)"
  },
  {
    "x": "mijkerdiş (n)",
    "y": " sislendirme"
  },
  {
    "x": "mijlek (n)",
    "y": " b. mijlorek (n)"
  },
  {
    "x": "mijlewr (n)",
    "y": " b. mijlorek (n)"
  },
  {
    "x": "mijlewreki (m)",
    "y": " b. morcela (m) "
  },
  {
    "x": "mijlewri (m)",
    "y": " b. morcela (m) "
  },
  {
    "x": "mijlok (n)",
    "y": " b. mijlorek (n)"
  },
  {
    "x": "mijloki (m)",
    "y": " b. morcela (m) "
  },
  {
    "x": "mijloli (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "mijlor (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "mijlor (n)",
    "y": " b. mijlorek (n)"
  },
  {
    "x": "mijlorek (n)",
    "y": " temriye"
  },
  {
    "x": "mijloreki-I (m)",
    "y": " morcela (m)"
  },
  {
    "x": "mijloreki-II (m)",
    "y": " b. mijlorek (n)"
  },
  {
    "x": "mijlori (m)",
    "y": " b. morcela (m) "
  },
  {
    "x": "mijlowr (n)",
    "y": " b. mijlorek (n)"
  },
  {
    "x": "mijlowri (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "mijmewle (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "mijmewli (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "mijmol (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "mijonkî (zh)",
    "y": " b. mujeyî (zh)"
  },
  {
    "x": "mijuj (n)",
    "y": " b. micêc (n)"
  },
  {
    "x": "mijulîyayîş (n)",
    "y": " b. mijûlîyayîş (n)"
  },
  {
    "x": "mijûlîyayene",
    "y": " konuşmak"
  },
  {
    "x": "mijûlîyayîş (n)",
    "y": " konuşma"
  },
  {
    "x": "mijxele (n)",
    "y": " pus (hafif ve nemli sis)"
  },
  {
    "x": "mijxelî, -ye",
    "y": " bulanık"
  },
  {
    "x": "mijxelîyîye (m)",
    "y": " bulanıklık"
  },
  {
    "x": "mikine (n)",
    "y": " b. nekme (n)"
  },
  {
    "x": "mikine guretene",
    "y": " b. nekme girewtene"
  },
  {
    "x": "mikineyin, -e",
    "y": " b. nemnek, -e"
  },
  {
    "x": "mikne-mikne",
    "y": " 1)giderek, gittikçe, yavaş yavaş\r\n~2)hazmede ede, sindire sindire\r\n~Wa mikne-mikne bigirîyo. (Yavaş yavaş kaynasın.)"
  },
  {
    "x": "mikni-mikni",
    "y": " b. mikne-mikne"
  },
  {
    "x": "mil (n)",
    "y": " boyun"
  },
  {
    "x": "mil çewt kerdene",
    "y": " boyun bükmek"
  },
  {
    "x": "mil çot kerdeni",
    "y": " b. mil çewt kerdene"
  },
  {
    "x": "mil de mendene",
    "y": " boynunda kalmak"
  },
  {
    "x": "mil id mendeni",
    "y": " b. mil de mendene"
  },
  {
    "x": "mil ronayene",
    "y": " boyun eğmek, itaat etmek"
  },
  {
    "x": "mil ronayeni",
    "y": " b. mil ronayene"
  },
  {
    "x": "mil roverdayene",
    "y": " boyun bükmek, boyun eğmek, itaat etmek"
  },
  {
    "x": "mil rueverdayene",
    "y": " b. mil roverdayene"
  },
  {
    "x": "mil xo ver nayene",
    "y": " boyun bükmek"
  },
  {
    "x": "mil xwi ver nayene",
    "y": " b. mil xo ver nayene"
  },
  {
    "x": "milê cile/çinayî/kincî (n)",
    "y": " elbisenin boyna arkadan oturan bölümü, giysinin boyna arkadan oturan bölümü"
  },
  {
    "x": "milê xo çewt kerdene",
    "y": " boynunu bükmek"
  },
  {
    "x": "milê xo xo ver nayene",
    "y": " boynunu bükmek"
  },
  {
    "x": "milê xwi çot kerdeni",
    "y": " b. milê xo çewt kerdene"
  },
  {
    "x": "milê xwi xwi ver nayene",
    "y": " b. milê xo xo ver nayene"
  },
  {
    "x": "mila-I (n)",
    "y": " b. mela-I (n)"
  },
  {
    "x": "mila-II",
    "y": " b. mela-II"
  },
  {
    "x": "milaket (n)",
    "y": " b. milakete (m)"
  },
  {
    "x": "milakete (m)",
    "y": " melek"
  },
  {
    "x": "milawine (m)",
    "y": " b. marula (m) "
  },
  {
    "x": "milawini (m)",
    "y": " b. marula (m) "
  },
  {
    "x": "milawune (m)",
    "y": " b. marula (m)"
  },
  {
    "x": "milawûne (m)",
    "y": " b. marula (m) "
  },
  {
    "x": "Milazgir (n)",
    "y": " Malazgirt "
  },
  {
    "x": "milçewt, -e",
    "y": " boynu bükük"
  },
  {
    "x": "milçewtkerdiş (n)",
    "y": " boyun bükme"
  },
  {
    "x": "milçik, -e",
    "y": " b. mîlçik, -e "
  },
  {
    "x": "milçiki (m)",
    "y": " b. mîlçike (m)"
  },
  {
    "x": "milçîya fekî (m)",
    "y": " ağız şapırdaması"
  },
  {
    "x": "milçot, -i",
    "y": " b. milçewt, -e"
  },
  {
    "x": "milçotkerdiş (n)",
    "y": " b. milçewtkerdiş (n)"
  },
  {
    "x": "mildemendiş (n)",
    "y": " boynunda kalma"
  },
  {
    "x": "mile (n)",
    "y": " b. mela-I (n)"
  },
  {
    "x": "milet (n)",
    "y": " millet, ulus"
  },
  {
    "x": "miletê ma (n)",
    "y": " milletimiz, ulusumuz"
  },
  {
    "x": "Miletê Yewbîyayeyî (MY)",
    "y": " Birleşmiş Milletler (BM)"
  },
  {
    "x": "mileti (m)",
    "y": " b. milet (n) "
  },
  {
    "x": "miletperwer, -e",
    "y": " milliyetçi"
  },
  {
    "x": "milêt (n)",
    "y": " b. milet (n) "
  },
  {
    "x": "milêv (m)",
    "y": " b. malêbe (m)"
  },
  {
    "x": "milêve (m)",
    "y": " b. malêbe (m)"
  },
  {
    "x": "milêvi (m)",
    "y": " b. malêbe (m)"
  },
  {
    "x": "milêxoçewtkerdiş (n)",
    "y": " boynunu bükme"
  },
  {
    "x": "milêxoxovernayîş (n)",
    "y": " boynunu bükme"
  },
  {
    "x": "milêxwiçotkerdiş (n)",
    "y": " b. milêxoçewtkerdiş (n)"
  },
  {
    "x": "milêxwixwivernayîş (n)",
    "y": " b. milêxoxovernayîş (n)"
  },
  {
    "x": "milidmendiş (n)",
    "y": " b. mildemendiş (n)"
  },
  {
    "x": "milik (n)",
    "y": " boyunduruğun öküzün boynuna gelen kısmı"
  },
  {
    "x": "milî, -ye",
    "y": " milli, ulusal "
  },
  {
    "x": "milîçik, -i",
    "y": " b. mîlçik, -e "
  },
  {
    "x": "milk (n)",
    "y": " 1)mülk\r\n~milkêdewletekerdiş (n): kamulaştırma, istimlak\r\n~2)ait"
  },
  {
    "x": "milkê dewreyî (n)",
    "y": " devre mülk"
  },
  {
    "x": "milkê xezîna (n)",
    "y": " hazine mülkü"
  },
  {
    "x": "milkêdewletekerdiş (n)",
    "y": " kamulaştırma, istimlak"
  },
  {
    "x": "milla (n)",
    "y": " b. mela-I (n)"
  },
  {
    "x": "mille (n)",
    "y": " b. mela-I (n)"
  },
  {
    "x": "millete (m)",
    "y": " b. milet (n) "
  },
  {
    "x": "milleti (m)",
    "y": " b. milet (n)"
  },
  {
    "x": "millî",
    "y": " b. milî, -ye"
  },
  {
    "x": "milnayene",
    "y": " b. mirayene"
  },
  {
    "x": "milnayêne",
    "y": " b. mirayene"
  },
  {
    "x": "milnayîş (n)",
    "y": " b. mirayîş (n)"
  },
  {
    "x": "miloçik (n)",
    "y": " b. mîlçik (n)"
  },
  {
    "x": "miloçike-I (m)",
    "y": " egzama, mayasıl"
  },
  {
    "x": "miloçike-II (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "milorçike (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "milowine (m)",
    "y": " b. marula (m)"
  },
  {
    "x": "milpêşe (m)",
    "y": " atkı, kaşkol"
  },
  {
    "x": "milqebaz, -e",
    "y": " küfürbaz, ağzı bozuk, ağzı pis"
  },
  {
    "x": "milqî (n)",
    "y": " sövgü, küfür"
  },
  {
    "x": "milqî kerdene",
    "y": " sövmek, küfretmek\r\n~Mi ra milqî keno. (Bana küfür ediyor.)"
  },
  {
    "x": "milqîkerdis (n)",
    "y": " b. milqîkerdiş"
  },
  {
    "x": "milqîkerdiş (n)",
    "y": " sövme, küfretme"
  },
  {
    "x": "milqit (n)",
    "y": " b. milqî (n)"
  },
  {
    "x": "milqitî kerdene",
    "y": " b. milqî kerdene"
  },
  {
    "x": "milqitîkerdiş (n)",
    "y": " b. milqîkerdiş"
  },
  {
    "x": "milronayîş (n)",
    "y": " boyun eğme, itaat etme"
  },
  {
    "x": "milroverdaye (n)",
    "y": " boynu bükük"
  },
  {
    "x": "milroverdayîş (n)",
    "y": " boyun eğme, boyun bükme, itaat etme"
  },
  {
    "x": "milrueverdaye (n)",
    "y": " b. milroverdaye (n)"
  },
  {
    "x": "milrueverdayîş (n)",
    "y": " b. milroverdayîş (n)"
  },
  {
    "x": "milul, -e",
    "y": " b. melul, -e"
  },
  {
    "x": "miluley (m)",
    "y": " b. melulî (m), melulîye (m)"
  },
  {
    "x": "milulê (m)",
    "y": " b. melulî (m), melulîye (m)"
  },
  {
    "x": "milulîye (m)",
    "y": " b. melulî (m), melulîye (m)"
  },
  {
    "x": "milxî (n)",
    "y": " b. milqî (n)"
  },
  {
    "x": "milxovernayîş (n)",
    "y": " boyun bükme"
  },
  {
    "x": "milxwivernayîş (n)",
    "y": " b. milxovernayîş (n)"
  },
  {
    "x": "min",
    "y": " ben (bükünlü durumda)"
  },
  {
    "x": "minaqaşe (m)",
    "y": " b. munaqeşe (m)"
  },
  {
    "x": "minasib, -i",
    "y": " b. munasib, -e"
  },
  {
    "x": "minasib bîyayene",
    "y": " b. munasib bîyene"
  },
  {
    "x": "minasibbîyayîş (n)",
    "y": " b. munasibbîyayîş (n)"
  },
  {
    "x": "minasibî (m)",
    "y": " b. munasibî (m)"
  },
  {
    "x": "minasiv, -e",
    "y": " b. munasib, -e"
  },
  {
    "x": "minasiv bîyayene",
    "y": " b. munasib bîyene"
  },
  {
    "x": "minasivbîyayîş (n)",
    "y": " b. munasibbîyayîş (n)"
  },
  {
    "x": "minasivîye (m)",
    "y": " b. munasibîye (m)"
  },
  {
    "x": "mindal, -e",
    "y": " çocuk"
  },
  {
    "x": "mindî (m)",
    "y": " b. mûndî (m)"
  },
  {
    "x": "mineqaşe (m)",
    "y": " b. munaqeşe (m)"
  },
  {
    "x": "minet (n)",
    "y": " minnet"
  },
  {
    "x": "minet nêkerdene",
    "y": " minnet etmemek, ağız eğmemek"
  },
  {
    "x": "miney",
    "y": " b. biney"
  },
  {
    "x": "minminike (m)",
    "y": " kelebek"
  },
  {
    "x": "mintiqa (m)",
    "y": " bölge, mıntıka"
  },
  {
    "x": "mintiqaya otonome (m)",
    "y": " otonom bölge, özerk bölge, özerk mıntıka"
  },
  {
    "x": "mintiqaya şewatî (m)",
    "y": " yangın bölgesi, yangın zonu"
  },
  {
    "x": "mintiqaya tampone (m)",
    "y": " tampon bölge"
  },
  {
    "x": "mintîqa (m)",
    "y": " b. mintiqa (m)"
  },
  {
    "x": "mintîqe (n)",
    "y": " b. mintiqa (m)"
  },
  {
    "x": "miqar (n)",
    "y": " yontma kalemi"
  },
  {
    "x": "miqara (m)",
    "y": " b. meqara (m)"
  },
  {
    "x": "miqat bîyene",
    "y": " 1)bakmak\r\n~2)gözetmek\r\n~3)geçindirmek"
  },
  {
    "x": "miqatê ... bîyene",
    "y": " -e bakmak\r\n~Miqatê xo be. (Kendine bak.)"
  },
  {
    "x": "miqatî ... bîyene",
    "y": " b. miqatê ... bîyene"
  },
  {
    "x": "miqat kerdene",
    "y": " 1)bakmak\r\n~Hesen gedanê birayê xo miqat keno. (Hasan kardeşinin çocuklarına bakıyor.)\r\n~2)beslemek\r\n~3)geçindirmek\r\n~4)korumak, muhafaza etmek"
  },
  {
    "x": "miqatbîyayîş (n)",
    "y": " 1)bakma\r\n~2)gözetme\r\n~3)geçindirme"
  },
  {
    "x": "miqate bîyene",
    "y": " b. miqat bîyene"
  },
  {
    "x": "miqate kerdene",
    "y": " b. miqat kerdene"
  },
  {
    "x": "miqateyê ... bîyayene",
    "y": " b. miqatê ... bîyene"
  },
  {
    "x": "miqatebîyayîş (n)",
    "y": " b. miqatbîyayîş (n)"
  },
  {
    "x": "miqatekerdiş (n)",
    "y": " b. miqatkerdiş (n)"
  },
  {
    "x": "miqatkerda (m)",
    "y": " bakımlı"
  },
  {
    "x": "miqatkerde (n)",
    "y": " bakımlı"
  },
  {
    "x": "miqatkerdeyîye (m)",
    "y": " bakımlılık"
  },
  {
    "x": "miqatkerdiş (n)",
    "y": " 1)bakma\r\n~2)gözetim\r\n~3)besleme\r\n~4)geçindirme\r\n~5)koruma, muhafaza etme"
  },
  {
    "x": "miqatkerdî, -ye",
    "y": " bakımlı "
  },
  {
    "x": "miqatkerdîyîye (m)",
    "y": " bakımlılık"
  },
  {
    "x": "miqatkerdox, -e",
    "y": " bakıcı\r\n~miqatkerdoxê/a domanan: çocuk bakıcısı"
  },
  {
    "x": "miqatkerdoxê/a domanan",
    "y": " çocuk bakıcısı"
  },
  {
    "x": "miqatkerdoxê/a gedeyan ",
    "y": "çocuk bakıcısı"
  },
  {
    "x": "miqatkerdoxê/a qeçekan",
    "y": " çocuk bakıcısı"
  },
  {
    "x": "miqatkerdoxê/a qican",
    "y": " çocuk bakıcısı"
  },
  {
    "x": "miqatkerdoxîye (m)",
    "y": " bakıcılık"
  },
  {
    "x": "miqatxane (n)",
    "y": " bakım evi"
  },
  {
    "x": "miqayt bîyene",
    "y": " b. miqat bîyene"
  },
  {
    "x": "miqayt kerdene",
    "y": " b. miqat kerdene"
  },
  {
    "x": "miqaytê ... bîyene",
    "y": " b. miqatê ... bîyene"
  },
  {
    "x": "miqaytekerdiş (n)",
    "y": " b. miqatkerdiş (n)"
  },
  {
    "x": "miqaytkerdiş (n)",
    "y": " b. miqatkerdiş (n)"
  },
  {
    "x": "miqeşr (n)",
    "y": " b. meqeşer (n)"
  },
  {
    "x": "miqet bîyene",
    "y": " b. miqat bîyene"
  },
  {
    "x": "miqet kerdene",
    "y": " b. miqat kerdene"
  },
  {
    "x": "miqetbîyayîş (n)",
    "y": " b. miqatbîyayîş (n)"
  },
  {
    "x": "miqetkerdiş (n)",
    "y": " b. miqatkerdiş (n)"
  },
  {
    "x": "miqil (n)",
    "y": " tava"
  },
  {
    "x": "miqilik (n)",
    "y": " küçük tava"
  },
  {
    "x": "miqlê (n)",
    "y": " b. miqil (n)"
  },
  {
    "x": "miqlik (n)",
    "y": " b. miqilik (n)"
  },
  {
    "x": "mira-I (m)",
    "y": " b. mura-I (m)"
  },
  {
    "x": "mira nefesî",
    "y": " b. muraya nefesî (m)"
  },
  {
    "x": "mira-II",
    "y": " b. mela-II\r\n~Mira sarê yîn êsênî/asaynê. (Sadece/yalnızca başları görünüyordu.)"
  },
  {
    "x": "miraba (n, m)",
    "y": " b. mirêba, -ye"
  },
  {
    "x": "mirad (n)",
    "y": " murat"
  },
  {
    "x": "Mirad (n)",
    "y": " b. Murad (n)"
  },
  {
    "x": "miradîyaya (m)",
    "y": " dargın, küskün, küs"
  },
  {
    "x": "miradîyaye (n)",
    "y": " dargın, küskün, küs"
  },
  {
    "x": "miradîyayene",
    "y": " darılmak, küsmek"
  },
  {
    "x": "miradîyayeyê (m)",
    "y": " b. miradîyayeyî (m)"
  },
  {
    "x": "miradîyayeyî (m)",
    "y": " dargınlık, küskünlük; burukluk"
  },
  {
    "x": "miradîyayeyîye (m)",
    "y": " dargınlık, küskünlük; burukluk"
  },
  {
    "x": "miradîyayê (m)",
    "y": " b. miradîyayîye (m)"
  },
  {
    "x": "miradîyayi (n)",
    "y": " b. miradîyaye (n)"
  },
  {
    "x": "miradîyayîş (n)",
    "y": " küsme"
  },
  {
    "x": "miradîyayîye (m)",
    "y": " dargın, küskün, küs"
  },
  {
    "x": "miradîyene",
    "y": " b. miradîyayene"
  },
  {
    "x": "mirad‎‎îyîş (n)",
    "y": " b. miradîyayîş (n)"
  },
  {
    "x": "miradnayene",
    "y": " darıltmak, küstürmek"
  },
  {
    "x": "miradnayîş (n)",
    "y": " darıltma, küstürme"
  },
  {
    "x": "miradyaya (m)",
    "y": " b. miradîyaya (m)"
  },
  {
    "x": "miradyaye (n)",
    "y": " b. miradîyaye (n)"
  },
  {
    "x": "miradyayene",
    "y": " b. miradîyayene"
  },
  {
    "x": "miradyayeyê (m)",
    "y": " b. miradîyayeyî (m)"
  },
  {
    "x": "miradyayeyî (m)",
    "y": " b. miradîyayeyî (m)"
  },
  {
    "x": "miradyayeyîye (m)",
    "y": " b. miradîyayeyîye (m)"
  },
  {
    "x": "miradyayê (m)",
    "y": " b. miradîyayîye (m)"
  },
  {
    "x": "miradyayi (n)",
    "y": " b. miradîyaye (n)"
  },
  {
    "x": "miradyayîş (n)",
    "y": " b. miradîyayîş (n)"
  },
  {
    "x": "miradyayîye (m)",
    "y": " b. miradîyayîye (m)"
  },
  {
    "x": "miraq (n)",
    "y": " b. meraq (n)"
  },
  {
    "x": "miraq kerdene",
    "y": " b. meraq kerdene"
  },
  {
    "x": "miraqkerdiş (n)",
    "y": " b. meraqkerdiş (n)"
  },
  {
    "x": "mirava (n, m)",
    "y": " b. mirêba, -ye"
  },
  {
    "x": "mirayene",
    "y": " mırıldamak (kedi için)"
  },
  {
    "x": "mirayîş (n)",
    "y": " mırıldama (kedi için)"
  },
  {
    "x": "miraynayene",
    "y": " b. mirênayene"
  },
  {
    "x": "miraynayîş (n)",
    "y": " b. mirênayîş (n)"
  },
  {
    "x": "miraz (n)",
    "y": " b. mirad (n)"
  },
  {
    "x": "mircane (m)",
    "y": " mercan"
  },
  {
    "x": "mircolike (m)",
    "y": " morcela (m)"
  },
  {
    "x": "mircon (m)",
    "y": " b. mircane (m)"
  },
  {
    "x": "mirconi (m)",
    "y": " b. mircane (m)"
  },
  {
    "x": "mircûn (m)",
    "y": " b. mircane (m)"
  },
  {
    "x": "mirçik, -e",
    "y": " b. mîlçik, -e"
  },
  {
    "x": "mird, -e",
    "y": " tok"
  },
  {
    "x": "mirdal, -e",
    "y": " b. murdar, -e"
  },
  {
    "x": "mirdal bîyayene",
    "y": " b. murdar bîyene"
  },
  {
    "x": "mirdal kerdene",
    "y": " b. murdar kerdene"
  },
  {
    "x": "mirdaq (n)",
    "y": " b. martag (n)"
  },
  {
    "x": "mirdaqe (m)",
    "y": " b. martag (n)"
  },
  {
    "x": "mirdaqi (m)",
    "y": " b. martag (n)"
  },
  {
    "x": "mirdar, -e",
    "y": " b. murdar, -e"
  },
  {
    "x": "mirdar bîyayeni",
    "y": " b. murdar bîyene"
  },
  {
    "x": "mirdar bîyene",
    "y": " b. murdar bîyene"
  },
  {
    "x": "mirdar kerdene",
    "y": " b. murdar kerdene"
  },
  {
    "x": "mirdîyaq (n)",
    "y": " b. martag (n)"
  },
  {
    "x": "mirdos (m)",
    "y": " b. herdose (m)"
  },
  {
    "x": "mirdose (m)",
    "y": " b. herdose (m)"
  },
  {
    "x": "mirdyaq (m)",
    "y": " b. martag (n)"
  },
  {
    "x": "mirekeb (m)",
    "y": " b. murekeb (n)"
  },
  {
    "x": "miremir (n)",
    "y": " mırıltı (kedi için) "
  },
  {
    "x": "miremir kerdene",
    "y": " mırıldamak (kedi için) "
  },
  {
    "x": "miremirkerdiş (n)",
    "y": " mırıldama (kedi için)"
  },
  {
    "x": "mirêba, -ye",
    "y": " maraba"
  },
  {
    "x": "mirêbatî (m)",
    "y": " marabalık"
  },
  {
    "x": "mirêbatîye (m)",
    "y": " marabalık"
  },
  {
    "x": "mirêbayey (m)",
    "y": " b. mirêbayî (m)"
  },
  {
    "x": "mirêbayê (m)",
    "y": " b. mirêbayî (m)"
  },
  {
    "x": "mirêbayênî (m)",
    "y": " marabalık"
  },
  {
    "x": "mirêbayî (m)",
    "y": " marabalık"
  },
  {
    "x": "mirêbayîye (m)",
    "y": " marabalık"
  },
  {
    "x": "mirênayene",
    "y": " 1)öldürmek\r\n~2)söndürmek"
  },
  {
    "x": "mirênayîş (n)",
    "y": " 1)öldürme\r\n~2)söndürme"
  },
  {
    "x": "mirik, -e",
    "y": " cüce"
  },
  {
    "x": "mirikî bîyene",
    "y": " ölmek (çocuk dilinde)"
  },
  {
    "x": "mirikîbeyîş (n)",
    "y": " b. mirikîbîyayîş (n)"
  },
  {
    "x": "mirikîbîyayîş (n)",
    "y": " ölme (çocuk dilinde)"
  },
  {
    "x": "mirikîye (m)",
    "y": " cücelik"
  },
  {
    "x": "mirîçik, -e",
    "y": " b. mîlçik, -e"
  },
  {
    "x": "mirîçiki (m)",
    "y": " b. mîlçike (m)"
  },
  {
    "x": "mirjani (m)",
    "y": " b. mircane (m)"
  },
  {
    "x": "mirnayene-I",
    "y": " b. mirayene"
  },
  {
    "x": "mirnayene-II",
    "y": " b. mirênayene"
  },
  {
    "x": "mirnayêne",
    "y": " b. mirayene"
  },
  {
    "x": "mirnayîş-I (n)",
    "y": " b. mirênayîş (n)"
  },
  {
    "x": "mirnayîş-II (n)",
    "y": " b. mirayîş (n)"
  },
  {
    "x": "miro (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "mirod (n)",
    "y": " b. mirad (n)"
  },
  {
    "x": "miroj (n)",
    "y": " b. mirûz (n)"
  },
  {
    "x": "miroy (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "miroye (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "miroyêri (m) ",
    "y": "b. murîyêre (m)"
  },
  {
    "x": "miroz (n)",
    "y": " b. mirûz (n)"
  },
  {
    "x": "mirozcal, -e",
    "y": " b. mirûzcal, -e"
  },
  {
    "x": "mirozin, -e",
    "y": " b. mirûzin, -e"
  },
  {
    "x": "mirozîyaya (m)",
    "y": " b. mirûzîyaya (m), b. mirûzîyayîye (m)"
  },
  {
    "x": "mirozîyaye (n)",
    "y": " b. mirûzîyaye (n)"
  },
  {
    "x": "miroztal, -e",
    "y": " b. mirûztal, -e"
  },
  {
    "x": "miroztirş, -e",
    "y": " b. mirûztirş, -e"
  },
  {
    "x": "mirrik, -e",
    "y": " b. mirik, -e"
  },
  {
    "x": "mirrikîye (m)",
    "y": " b. mirikîye (m)"
  },
  {
    "x": "mirtal (n)",
    "y": " b. mertal-I"
  },
  {
    "x": "mirtar (n)",
    "y": " b. mertal-I"
  },
  {
    "x": "mirux (n)",
    "y": " asık surat, abus, çatık yüz, çatık surat, çatık çehre"
  },
  {
    "x": "miruxin, -e",
    "y": " asık suratlı, abus, somurtkan, somurtuk"
  },
  {
    "x": "miruz (n)",
    "y": " b. mirûz (n)"
  },
  {
    "x": "mirûz (n)",
    "y": " asık surat, abus, çatık yüz, çatık surat, çatık çehre"
  },
  {
    "x": "mirûzcal, -e",
    "y": " asık suratlı, abus, somurtkan, somurtuk"
  },
  {
    "x": "mirûzin, -e",
    "y": " asık suratlı, abus, somurtkan, somurtuk"
  },
  {
    "x": "mirûzîyaya (m)",
    "y": " asık suratlı, abus"
  },
  {
    "x": "mirûzîyaye (n)",
    "y": " asık suratlı, abus"
  },
  {
    "x": "mirûzîyayîye (m)",
    "y": " asık suratlı, abus"
  },
  {
    "x": "mirûztal, -e",
    "y": " asık suratlı, abus, somurtkan, somurtuk"
  },
  {
    "x": "mirûztirş, -e",
    "y": " asık suratlı, abus, somurtkan, somurtuk"
  },
  {
    "x": "mirvêri (m)",
    "y": " b. murîyêre (m)"
  },
  {
    "x": "mirvi (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "mirwa (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "mirwêri (m)",
    "y": " b. murîyêre (m)"
  },
  {
    "x": "mirwi (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "mirxib (n)",
    "y": " b. mexreb (n)"
  },
  {
    "x": "mirxik (n)",
    "y": " küçük bir karınca çeşidi"
  },
  {
    "x": "mirxub (n)",
    "y": " b. mexreb (n)"
  },
  {
    "x": "mirya (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "misag, -e",
    "y": " b. mişag, -e"
  },
  {
    "x": "misaîb (n)",
    "y": " b. misayîb (n)"
  },
  {
    "x": "misafir, -e",
    "y": " konuk, misafir"
  },
  {
    "x": "misafir girewtene",
    "y": " konuk almak, misafir almak"
  },
  {
    "x": "misafir girotene",
    "y": " b. misafir girewtene"
  },
  {
    "x": "misafir guretene",
    "y": " b. misafir girewtene"
  },
  {
    "x": "misafir heweynayene",
    "y": " b. misafir hewênayene"
  },
  {
    "x": "misafir hewênayene",
    "y": " konuk ağırlamak, misafir ağırlamak"
  },
  {
    "x": "misafir hewînayene",
    "y": " b. misafir hewênayene"
  },
  {
    "x": "misafir kerdene",
    "y": " konuk etmek, misafir etmek"
  },
  {
    "x": "misafirey (m)",
    "y": " b. misafirî (m), misafirîye (m)"
  },
  {
    "x": "misafirê (m)",
    "y": " b. misafirî (m), misafirîye (m)"
  },
  {
    "x": "misafirgirewtiş (n)",
    "y": " konuk alma, misafir alma"
  },
  {
    "x": "misafirgirotiş (n)",
    "y": " b. misafirgirewtiş (n)"
  },
  {
    "x": "misafirguretiş (n)",
    "y": " b. misafirgirewtiş (n)"
  },
  {
    "x": "misafirheweynayîş (n)",
    "y": " b. misafirhewênayîş (n)"
  },
  {
    "x": "misafirhewênayîş (n)",
    "y": " konuk ağırlama, misafir ağırlama"
  },
  {
    "x": "misafirhewînayîş (n)",
    "y": " b. misafirhewênayîş (n)"
  },
  {
    "x": "misafirî (m)",
    "y": " konukluk, misafirlik"
  },
  {
    "x": "misafirîye (m)",
    "y": " konukluk, misafirlik"
  },
  {
    "x": "misafirkerdiş (n)",
    "y": " konuk etme, misafir etme"
  },
  {
    "x": "misafirperwer, -e",
    "y": " konuksever, misafirperver"
  },
  {
    "x": "misas (m)",
    "y": " b. misasî (m)"
  },
  {
    "x": "misasi (m)",
    "y": " b. misasî (m)"
  },
  {
    "x": "misasî (m)",
    "y": " üvendire, övendire, meses"
  },
  {
    "x": "misayene",
    "y": " b. musayene "
  },
  {
    "x": "misayib (n)",
    "y": " b. misayîb (n)"
  },
  {
    "x": "misayîb (n)",
    "y": " ahiret kardeşi, ahret kardeşi, musahip"
  },
  {
    "x": "misayîbênî (m)",
    "y": " ahiret kardeşliği, ahret kardeşliği, musahiplik"
  },
  {
    "x": "misayîbîye (m)",
    "y": " ahiret kardeşliği, ahret kardeşliği, musahiplik"
  },
  {
    "x": "misayîş (n)",
    "y": " b. musayîş (n)"
  },
  {
    "x": "misayîv (n)",
    "y": " b. misayîb (n)"
  },
  {
    "x": "misayîvên (m)",
    "y": " b. misayîbênî (m)"
  },
  {
    "x": "misayîvênî (m)",
    "y": " b. misayîbênî (m)"
  },
  {
    "x": "misayîvîn (m)",
    "y": " b. misayîbênî (m)"
  },
  {
    "x": "misayîvîye (m)",
    "y": " b. misayîbîye (m)"
  },
  {
    "x": "misaz (m)",
    "y": " b. misasî (m)"
  },
  {
    "x": "Misef (n)",
    "y": " b. Mishef (n)"
  },
  {
    "x": "Misehef (n)",
    "y": " b. Mishef (n)"
  },
  {
    "x": "misembe (n)",
    "y": " b. muşeme (n)"
  },
  {
    "x": "miseyte (m)",
    "y": " 1)keş eritilirken erimeyip geriye kalan parçacık\r\n~2)daldaki salkımdan koparılmadan kurumuş olan üzüm tanesi"
  },
  {
    "x": "miseyti (m)",
    "y": " b. miseyte (m)"
  },
  {
    "x": "Mishaf (n)",
    "y": " b. Mishef (n)"
  },
  {
    "x": "Mishef (n)",
    "y": " Mushaf"
  },
  {
    "x": "misibet (m)",
    "y": " b. musîbete (m)"
  },
  {
    "x": "misilman, -e",
    "y": " b. musluman, -e"
  },
  {
    "x": "misilmaney (m)",
    "y": " b. muslumanîye (m), muslumanî (m)"
  },
  {
    "x": "misilmanê (m)",
    "y": " b. muslumanîye (m), muslumanî (m)"
  },
  {
    "x": "misilmanênî (m)",
    "y": " b. muslumanênî (m)"
  },
  {
    "x": "misilmanîye (m)",
    "y": " b. muslumanîye (m), muslumanî (m)"
  },
  {
    "x": "Misir (n)",
    "y": " Mısır"
  },
  {
    "x": "misliman, -e",
    "y": " b. musluman, -e"
  },
  {
    "x": "mislimaney (m)",
    "y": " b. muslumanîye (m), muslumanî (m)"
  },
  {
    "x": "misluman, -e",
    "y": " b. musluman, -e"
  },
  {
    "x": "mislumaney",
    "y": " b. muslumanîye (m), muslumanî (m)"
  },
  {
    "x": "mislumanê (m)",
    "y": " b. muslumanîye (m), muslumanî (m)"
  },
  {
    "x": "misnayene",
    "y": " b. musnayene"
  },
  {
    "x": "misnayêne",
    "y": " b. musnayene"
  },
  {
    "x": "misnayîş (n)",
    "y": " b. musnayîş (n)"
  },
  {
    "x": "misone (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "misqal (n)",
    "y": " miskal"
  },
  {
    "x": "misqalek",
    "y": " miskalle"
  },
  {
    "x": "Misriç (n)",
    "y": " Kurtalan"
  },
  {
    "x": "misrî (n)",
    "y": " mısır"
  },
  {
    "x": "mist (n)",
    "y": " avuç"
  },
  {
    "x": "miste (m)",
    "y": " aya"
  },
  {
    "x": "misubet (m)",
    "y": " b. musîbete (m)"
  },
  {
    "x": "misyel bîyayîne",
    "y": " b. misyel bîyene"
  },
  {
    "x": "misyel bîyene",
    "y": " fazla üşümekten dolayı aşırı sızlamak (el vb. için)"
  },
  {
    "x": "mişag, -e",
    "y": " işçi, bedenen çok çalışan (güçlü olup iyi çalışan)"
  },
  {
    "x": "mişagênî (m)",
    "y": " işçilik, amelelik"
  },
  {
    "x": "mişagî (m)",
    "y": " 1)işçilik, amelelik\r\n~2)imece (karşılıklı birbirinin işinde çalışma)"
  },
  {
    "x": "mişagîye (m)",
    "y": " imece (karşılıklı birbirinin işinde çalışma)"
  },
  {
    "x": "mişagtî (m)",
    "y": " işçilik, amelelik"
  },
  {
    "x": "mişak, -e",
    "y": " b. mişag, -e"
  },
  {
    "x": "mişane (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "mişar (n)",
    "y": " büyük bıçkı (iki kişi tarafından kullanılan)"
  },
  {
    "x": "mişare (m)",
    "y": " 1)arık\r\n~2)bağda üzüm teveği sıralarından her biri"
  },
  {
    "x": "mişawîr, -e",
    "y": " danışman, müşavir\r\n~mişawîra malî ya sondwerdîye (m): yeminli mali müşavir\r\n~mişawîro malî yo sondwerde (n): yeminli mali müşavir"
  },
  {
    "x": "mişawîra malî ya sondwerda (m)",
    "y": " yeminli mali müşavir"
  },
  {
    "x": "mişawîra malî ya sondwerdîye (m)",
    "y": " yeminli mali müşavir"
  },
  {
    "x": "mişawîro malî yo sondwerde (n)",
    "y": " yeminli mali müşavir"
  },
  {
    "x": "mişawîrî (m)",
    "y": " danışmanlık, müşavirlik"
  },
  {
    "x": "mişawîrîye (m)",
    "y": " danışmanlık, müşavirlik"
  },
  {
    "x": "mişele bîyayîne",
    "y": " b. misyel bîyene"
  },
  {
    "x": "mişembe (n)",
    "y": " b. muşeme (n)"
  },
  {
    "x": "mişeme (n)",
    "y": " b. muşeme (n)"
  },
  {
    "x": "mişemi (n)",
    "y": " b. muşeme (n)"
  },
  {
    "x": "mişeqet, -e",
    "y": " perişan"
  },
  {
    "x": "mişeqet bîyene",
    "y": " perişan olmak"
  },
  {
    "x": "mişeqet kerdene",
    "y": " perişan etmek"
  },
  {
    "x": "mişeqetbîyayîş (n)",
    "y": " perişan olma"
  },
  {
    "x": "mişeqetey (m)",
    "y": " b. mişeqetî (m)"
  },
  {
    "x": "mişeqetê (m)",
    "y": " b. mişeqetî (m)"
  },
  {
    "x": "mişeqetî (m)",
    "y": " perişanlık"
  },
  {
    "x": "mişeqetkerdiş (n)",
    "y": " perişan etme"
  },
  {
    "x": "mişere (m)",
    "y": " b. mişor (n)"
  },
  {
    "x": "mişere kerdene",
    "y": " b. mişor kerdene"
  },
  {
    "x": "mişewire (n)",
    "y": " b. mişewre (n)"
  },
  {
    "x": "mişewire kerdene",
    "y": " b. mişewre kerdene"
  },
  {
    "x": "mişewire viraştene",
    "y": " b. mişewre viraştene"
  },
  {
    "x": "mişewre (n)",
    "y": " danışma, müşavere"
  },
  {
    "x": "mişewre kerdene",
    "y": " danışmak"
  },
  {
    "x": "mişewre viraştene",
    "y": " danışma toplantısı yapmak"
  },
  {
    "x": "mişewşî (m)",
    "y": " mercimek çorbası"
  },
  {
    "x": "mişgone (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "mişlul (m)",
    "y": " b. meşlûre (m)"
  },
  {
    "x": "mişlûl (m)",
    "y": " b. meşlûre (m)"
  },
  {
    "x": "mişlûli (m)",
    "y": " b. meşlûre (m)"
  },
  {
    "x": "mişmiş (m)",
    "y": " b. mişmişe (m)"
  },
  {
    "x": "mişmişe (m)",
    "y": " kayısı"
  },
  {
    "x": "mişmişa huşkkerda (m)",
    "y": " çir (kurutulmuş kayısı)"
  },
  {
    "x": "mişmişa huşkkerdîye (m)",
    "y": " çir (kurutulmuş kayısı)"
  },
  {
    "x": "mişmişêr (m)",
    "y": " b. mişmişêre (m)"
  },
  {
    "x": "mişmişêre (m)",
    "y": " kayısı ağacı"
  },
  {
    "x": "mişmişêri (m)",
    "y": " b. mişmişêre (m)"
  },
  {
    "x": "mişmişi (m)",
    "y": " b. mişmişe (m)"
  },
  {
    "x": "mişmişa wişkkerda (m)",
    "y": " b. mişmişa huşkkerda (m)"
  },
  {
    "x": "mişmişa wişkkerdîye (m)",
    "y": " b. mişmişa huşkkerdîye (m)"
  },
  {
    "x": "mişmişyer (m)",
    "y": " b. mişmişêre (m)"
  },
  {
    "x": "mişmişyêr (m)",
    "y": " b. mişmişêre (m)"
  },
  {
    "x": "mişmûli (m)",
    "y": " b. meşlûre (m)"
  },
  {
    "x": "mişon (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "mişone (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "mişone kutene",
    "y": " b. muşene kutene"
  },
  {
    "x": "mişor (n)",
    "y": " geviş"
  },
  {
    "x": "mişor kerdene",
    "y": " geviş getirmek"
  },
  {
    "x": "mişoşî (m)",
    "y": " b.  mişewşî (m)"
  },
  {
    "x": "mişot (m)",
    "y": " b. mişote (m)"
  },
  {
    "x": "mişot kerdene",
    "y": " b. mişote kerdene"
  },
  {
    "x": "mişote (m)",
    "y": " geviş"
  },
  {
    "x": "mişote kerdene",
    "y": " geviş getirmek"
  },
  {
    "x": "mişoti (m)",
    "y": " b. mişote (m)"
  },
  {
    "x": "mişoti kerdene",
    "y": " b. mişote kerdene"
  },
  {
    "x": "miştalix (n)",
    "y": " b. miştaxe (n)"
  },
  {
    "x": "miştaxe (n)",
    "y": " kurutulma amacıyla meyve serilen yer"
  },
  {
    "x": "miştik (n)",
    "y": " asma veya üzüm teveğinin kesilmiş olan ince, kuru dalları"
  },
  {
    "x": "mişuer (n)",
    "y": " b. mişor (n)"
  },
  {
    "x": "mişuer kerdene",
    "y": " b. mişor kerdene"
  },
  {
    "x": "mişuere (n)",
    "y": " b. mişewre (n)"
  },
  {
    "x": "mişuwen (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "mişûne (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "mişûti-I (m)",
    "y": " b. mişote (m)"
  },
  {
    "x": "mişûti kerdene",
    "y": " b. mişote kerdene"
  },
  {
    "x": "mişûti-II (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "mişwere (n)",
    "y": " b. mişewre (n)"
  },
  {
    "x": "mişwere kerdene",
    "y": " b. mişewre kerdene"
  },
  {
    "x": "mitaf (n)",
    "y": " b. mifte (n)"
  },
  {
    "x": "mitbaxe (m)",
    "y": " mutfak"
  },
  {
    "x": "mitbaxi (m)",
    "y": " b. mitbaxe (m)"
  },
  {
    "x": "mitehît, -e",
    "y": " b. mutahîd, -e"
  },
  {
    "x": "mitehîtî (m)",
    "y": " b. mutahîdî (m), mutahîdîye (m)"
  },
  {
    "x": "miteît, -i",
    "y": " b. mutahîd, -e"
  },
  {
    "x": "miteîtey (m)",
    "y": " b. mutahîdî (m), mutahîdîye (m)"
  },
  {
    "x": "miterhe (m)",
    "y": " imece (karşılıklı birbirinin işinde çalışma) "
  },
  {
    "x": "miterhe kerdene",
    "y": " imece ile çalışmak"
  },
  {
    "x": "miterhi (m)",
    "y": " b. miterhe (m)"
  },
  {
    "x": "miterhi kerdene",
    "y": " b. miterhe kerdene "
  },
  {
    "x": "mitêl (n)",
    "y": " 1)mitil (beyaz kılıf)\r\n~2)yatak"
  },
  {
    "x": "mitfax (n)",
    "y": " b. mitbaxe (m)"
  },
  {
    "x": "mitfaxi (m)",
    "y": " b. mitbaxe (m)"
  },
  {
    "x": "mitîel (n)",
    "y": " b. mitêl (n)"
  },
  {
    "x": "mitîyel (n)",
    "y": " b. mitêl (n)"
  },
  {
    "x": "mitmûm",
    "y": " cenaze gibi (benzi sararmış)"
  },
  {
    "x": "mitor (m)",
    "y": " b. motore (m)"
  },
  {
    "x": "mitran (n)",
    "y": " b. metran (n)"
  },
  {
    "x": "mitur (m)",
    "y": " b. motore (m)"
  },
  {
    "x": "mitûr (m)",
    "y": " b. motore (m)"
  },
  {
    "x": "mix-I (n)",
    "y": " çivi"
  },
  {
    "x": "mix ... de kutene",
    "y": " çivi çakmak"
  },
  {
    "x": "mix ... de kuwayene",
    "y": " çivi çakmak"
  },
  {
    "x": "mix ... de kuyene",
    "y": " b. mix ... de kuwayene"
  },
  {
    "x": "mix kerdene",
    "y": " çivilemek"
  },
  {
    "x": "mix kutene",
    "y": " çivi çakmak"
  },
  {
    "x": "mix kuwayene",
    "y": " çivi çakmak "
  },
  {
    "x": "mix pa kuyayîne",
    "y": " b. mix pira kuwayene"
  },
  {
    "x": "mix pira kuwayene",
    "y": " çivi çakmak"
  },
  {
    "x": "mix pira kuyayene",
    "y": " b. mix pira kuwayene"
  },
  {
    "x": "mix piri dayîne",
    "y": " b. mix ... ro dayene"
  },
  {
    "x": "mix ... ro dayene",
    "y": " çivi çakmak"
  },
  {
    "x": "mix ... ro kuwayene",
    "y": " çivi çakmak"
  },
  {
    "x": "mix ... ro kuyene",
    "y": " b. mix ... ro kuwayene"
  },
  {
    "x": "mix tede kutene",
    "y": " b. mix ... de kutene"
  },
  {
    "x": "mix-II (n)",
    "y": " b. mezg (n)"
  },
  {
    "x": "mixara (m)",
    "y": " mağara"
  },
  {
    "x": "mixare (n)",
    "y": " b. mixara (m)"
  },
  {
    "x": "mixêr, -e",
    "y": " acayip, garip, gariplik"
  },
  {
    "x": "mixêr bîyene",
    "y": " acayipleşmek"
  },
  {
    "x": "mixêr kerdene",
    "y": " acayipleştirmek"
  },
  {
    "x": "mixêrbîyayîş (n)",
    "y": " acayipleşme"
  },
  {
    "x": "mixêrîye (m)",
    "y": " acayiplik"
  },
  {
    "x": "mixêrkerdiş (n)",
    "y": " acayipleştirme"
  },
  {
    "x": "mixtar (n)",
    "y": " b. muxtar (n)"
  },
  {
    "x": "mixtarîn (m)",
    "y": " muxtarênî (m)"
  },
  {
    "x": "mixurbe (m)",
    "y": " b. mexreb (n)"
  },
  {
    "x": "mixurve (m)",
    "y": " b. mexreb (n)"
  },
  {
    "x": "miyîn (n)",
    "y": " mîyîn (n)"
  },
  {
    "x": "miz, -e-I",
    "y": " mayhoş"
  },
  {
    "x": "miz-II (n)",
    "y": " b. mij (n)"
  },
  {
    "x": "mizafir",
    "y": " b. misafir, -e"
  },
  {
    "x": "mizafirî (m)",
    "y": " b. misafirî (m), misafirîye (m) "
  },
  {
    "x": "mize (n)",
    "y": " b. mujeyî (zh)"
  },
  {
    "x": "mizel (m)",
    "y": " b. mezele (m)"
  },
  {
    "x": "mizey (m)",
    "y": " b. mizî (m), mizîye (m)"
  },
  {
    "x": "mizê (m)",
    "y": " b. mizî (m), mizîye (m)"
  },
  {
    "x": "mizên (n)",
    "y": " b. mêzîn (n)"
  },
  {
    "x": "mizî (m)",
    "y": " mayhoşluk"
  },
  {
    "x": "mizîye (m)",
    "y": " mayhoşluk"
  },
  {
    "x": "mizmehal, -e",
    "y": " perişan"
  },
  {
    "x": "mizmehal bîyene",
    "y": " perişan olmak"
  },
  {
    "x": "mizmehal kerdene",
    "y": " perişan etmek"
  },
  {
    "x": "mizmehalbîyayîş (n)",
    "y": " perişan olma"
  },
  {
    "x": "mizmehalkerdiş (n)",
    "y": " perişan etme"
  },
  {
    "x": "mizmehl (n)",
    "y": " b. mizmehal, -e"
  },
  {
    "x": "mizmehl bîyayene",
    "y": " b. mizmehal bîyene"
  },
  {
    "x": "mizmehl kerdene",
    "y": " b. mizmehal kerdene"
  },
  {
    "x": "mizmehlbîyayîş (n)",
    "y": " b. mizmehalbîyayîş (n)"
  },
  {
    "x": "mizmehlkerdiş (n)",
    "y": " b. mizmehalkerdiş (n)"
  },
  {
    "x": "mizmiz kerdene",
    "y": " gevelemek (söz için)"
  },
  {
    "x": "mizmizîyayene",
    "y": " gevelemek (söz için)"
  },
  {
    "x": "mizmizîyayîş (n)",
    "y": " geveleme (söz için)"
  },
  {
    "x": "mizmizkerdiş (n)",
    "y": " geveleme (söz için)"
  },
  {
    "x": "mizmiznayene",
    "y": " gevelemek (söz için)"
  },
  {
    "x": "mizmiznayîş (n)",
    "y": " geveleme (söz için)"
  },
  {
    "x": "Mizur (n)",
    "y": " Munzur"
  },
  {
    "x": "mizxele (n)",
    "y": " b. mijxele (n)"
  },
  {
    "x": "mî (m)",
    "y": " koyun"
  },
  {
    "x": "mîçik, -i",
    "y": " b. mîlçik, -e"
  },
  {
    "x": "mîçî (m)",
    "y": " b. maçî (m)"
  },
  {
    "x": "mîçî kerdene",
    "y": " b. maçî kerdene"
  },
  {
    "x": "mîde (n)",
    "y": " b. made (n)"
  },
  {
    "x": "mîde tîodayene",
    "y": " b. made têwdayene"
  },
  {
    "x": "mîde tîodîyayene",
    "y": " b. made têwdîyayene"
  },
  {
    "x": "mîde tîyodîyayene",
    "y": " b. made têwdîyayene"
  },
  {
    "x": "mîdetîodayîş (n)",
    "y": " b. madetêwdayîş (n)"
  },
  {
    "x": "mîdetîodîyayîş (n)",
    "y": " b. madetêwdîyayîş (n)"
  },
  {
    "x": "mîdetîyodîyayîş (n)",
    "y": " b. madetêwdîyayîş (n)"
  },
  {
    "x": "mîez (m)",
    "y": " b. moze (m)"
  },
  {
    "x": "mîez kotene",
    "y": " b. moze kewtene"
  },
  {
    "x": "mîgren (n)",
    "y": " migren "
  },
  {
    "x": "mîhîn (n)",
    "y": " b. mîyîn (n)"
  },
  {
    "x": "mîhraq (n)",
    "y": " odak"
  },
  {
    "x": "mîîn (n)",
    "y": " b. mîyîn (n)"
  },
  {
    "x": "mîj (m)",
    "y": " b. mîze (m)"
  },
  {
    "x": "mîj kerdene",
    "y": " b. mîze kerdene"
  },
  {
    "x": "mîjî (m)",
    "y": " b. mîze (m)"
  },
  {
    "x": "mîjî kerdene",
    "y": " b. mîze kerdene"
  },
  {
    "x": "mîkab (n)",
    "y": " küp"
  },
  {
    "x": "mîkrob (n)",
    "y": " mikrop"
  },
  {
    "x": "mîkrobîyolog, -e",
    "y": " mikrobiyolog"
  },
  {
    "x": "mîkrobîyolojî (n)",
    "y": " mikrobiyoloji"
  },
  {
    "x": "mîkrobîyolojîk, -e",
    "y": " mikrobiyolojik"
  },
  {
    "x": "mîkroîklîm (n)",
    "y": " mikroiklim"
  },
  {
    "x": "mîkrometre (n)",
    "y": " mikrometre, mikron"
  },
  {
    "x": "mîkron (n)",
    "y": " mikrometre, mikron"
  },
  {
    "x": "Mîkronezya (m)",
    "y": " Mikronezya"
  },
  {
    "x": "mîkroorganîzmayî (zh)",
    "y": " mikroorganizmalar"
  },
  {
    "x": "mîkrop (n)",
    "y": " b. mîkrob (n)"
  },
  {
    "x": "mîkropêl (n)",
    "y": " mikrodalga"
  },
  {
    "x": "firina mîkropêlî (m)",
    "y": " mikrodalga fırını"
  },
  {
    "x": "mîkrosefalî (m)",
    "y": " mikrosefali"
  },
  {
    "x": "mîkroskop (n)",
    "y": " mikroskop"
  },
  {
    "x": "mîkrosqop (n)",
    "y": " b. mîkroskop (n)"
  },
  {
    "x": "mîkser (n)",
    "y": " mikser, karıştırıcı, karmaç"
  },
  {
    "x": "mîl-I (n)",
    "y": " 1)kaldıraç (demirden olanı), manivela\r\n~2)mil (makinede silindirsel eklenti)\r\n~3)mil (metal çubuk) "
  },
  {
    "x": "mîl-II (n)",
    "y": " mil (uzaklık ölçü birimi)"
  },
  {
    "x": "mîlê bejî (n)",
    "y": " kara mili"
  },
  {
    "x": "mîlê deryayî (n)",
    "y": " deniz mili"
  },
  {
    "x": "mîlê erdî (n)",
    "y": " kara mili"
  },
  {
    "x": "mîlê hewayî (n)",
    "y": " hava mili"
  },
  {
    "x": "mîl-III (n)",
    "y": " 1)mil (kum ve toprak karışımı)"
  },
  {
    "x": "mîlad (n)",
    "y": " milat, İsa’nın doğduğu gün"
  },
  {
    "x": "mîlar",
    "y": " b. mîlyar"
  },
  {
    "x": "mîlçik, -e",
    "y": " 1)kuş\r\n~2)serçe"
  },
  {
    "x": "mîlçiki (m)",
    "y": " b. mîlçike (m)"
  },
  {
    "x": "mîlet (n)",
    "y": " b. milet (n)"
  },
  {
    "x": "mîleti (m)",
    "y": " b. milet (n)"
  },
  {
    "x": "mîlîgram (n)",
    "y": " miligram"
  },
  {
    "x": "mîlîlître (n)",
    "y": " mililitre"
  },
  {
    "x": "mîlîm (n)",
    "y": " milim"
  },
  {
    "x": "mîlîmetre (n)",
    "y": " milimetre, milim"
  },
  {
    "x": "mîlîmetrekare (n)",
    "y": " milimetrekare"
  },
  {
    "x": "mîlîmetremîkab (n)",
    "y": " milimetreküp"
  },
  {
    "x": "mîlîmetreyî, -ye",
    "y": " milimetrik"
  },
  {
    "x": "mîlîmetrîk, -e",
    "y": " milimetrik"
  },
  {
    "x": "mîlîmîkron (n)",
    "y": " milimikron, nanometre"
  },
  {
    "x": "mîllî, -ye",
    "y": " b. milî, -ye"
  },
  {
    "x": "mîlon",
    "y": " b. mîlyon"
  },
  {
    "x": "mîlun",
    "y": " b. mîlyon"
  },
  {
    "x": "mîlyar",
    "y": " milyar"
  },
  {
    "x": "mîlyari",
    "y": " b. mîlyar"
  },
  {
    "x": "mîlyarin, -e",
    "y": " milyarıncı"
  },
  {
    "x": "mîlyon",
    "y": " milyon"
  },
  {
    "x": "mîlyoni",
    "y": " b. mîlyon"
  },
  {
    "x": "mîlyonin, -e",
    "y": " milyonuncu"
  },
  {
    "x": "mîlyontone (m)",
    "y": " megaton"
  },
  {
    "x": "mîmar, -e",
    "y": " mimar"
  },
  {
    "x": "mîmara zereyî (m)",
    "y": " içmimar"
  },
  {
    "x": "mîmarê zereyî (n)",
    "y": " içmimar"
  },
  {
    "x": "mîmarey (m)",
    "y": " b. mîmarîye (m)"
  },
  {
    "x": "mîmarê (n)",
    "y": " b. mîmarî"
  },
  {
    "x": "mîmarî, -ye",
    "y": " 1)mimari\r\n~2)mimarlık"
  },
  {
    "x": "mîmarîyo dînî (n)",
    "y": " dini mimari"
  },
  {
    "x": "mîmarîye (m)",
    "y": " 1)(mimar olma durumu) mimari\r\n~2) mimarlık"
  },
  {
    "x": "mîme (m)",
    "y": " 1)serçe parmak\r\n~2)halay (serçe parmakla tutuşarak oynanan)"
  },
  {
    "x": "mîmi (m)",
    "y": " b. mîme (m)"
  },
  {
    "x": "mîmîk (n)",
    "y": " mimik"
  },
  {
    "x": "mîn-I (n)",
    "y": " b. mîyan (n)"
  },
  {
    "x": "mîn-II (n)",
    "y": " b. mîyîn (n)"
  },
  {
    "x": "mînate (n)",
    "y": " b. beyntar (n)"
  },
  {
    "x": "mînder (n)",
    "y": " minder"
  },
  {
    "x": "mîndi (m)",
    "y": " b. mûndî (m)"
  },
  {
    "x": "mîne (n)",
    "y": " b. mîyane (n)"
  },
  {
    "x": "mînew (n)",
    "y": " b. mêneb (n)"
  },
  {
    "x": "mînîbuse (m)",
    "y": " minibüs, dolmuş"
  },
  {
    "x": "Mînsk (n)",
    "y": " Minsk"
  },
  {
    "x": "mîntiqe (m)",
    "y": " b. mintiqa (m)"
  },
  {
    "x": "mîqdar (n)",
    "y": " miktar"
  },
  {
    "x": "mîqdarê malzemeyî (n)",
    "y": " malzeme miktarı"
  },
  {
    "x": "mîqrop (n)",
    "y": " b. mîkrob (n)"
  },
  {
    "x": "mîqyas (n)",
    "y": " 1)ölçek, ölçü, mikyas\r\n~2)tartı"
  },
  {
    "x": "mîqyasê dîmenkêşîye (zh, sn)",
    "y": " çekim ölçekleri"
  },
  {
    "x": "mîqyasê fîlmkêşîye (zh, sn)",
    "y": " (film) çekim ölçekleri"
  },
  {
    "x": "mîr (n)",
    "y": " hamur"
  },
  {
    "x": "mîra (m)",
    "y": " mira (yön belirtmek için kullanılan)"
  },
  {
    "x": "mîralay, -e",
    "y": " albay"
  },
  {
    "x": "mîras (n)",
    "y": " miras"
  },
  {
    "x": "mîraso kulturîyo nekonkret (n)",
    "y": " somut olmayan kültürel miras"
  },
  {
    "x": "mîraso kulturîyo nemuşexes (n)",
    "y": " somut olmayan kültürel miras"
  },
  {
    "x": "mîraso kulturîyo neşênber (n)",
    "y": " somut olmayan kültürel miras"
  },
  {
    "x": "warê mîrasî (n)",
    "y": " miras alanı"
  },
  {
    "x": "mîrat, -i",
    "y": " b. mêrat, -e"
  },
  {
    "x": "mîraz (n)",
    "y": " maya (hamur için)"
  },
  {
    "x": "mîraz bîyayene",
    "y": " b. mîraz bîyene"
  },
  {
    "x": "mîraz bîyene",
    "y": " mayalanmak (hamur için)"
  },
  {
    "x": "mîraz kerdene",
    "y": " mayalamak (hamur için)"
  },
  {
    "x": "mîrazbîyayîş (n)",
    "y": " mayalanma (hamur için)"
  },
  {
    "x": "mîrazkerde (n)",
    "y": " mayalı (hamur için)"
  },
  {
    "x": "mîrazkerdi (n)",
    "y": " b. mîrazkerde (n)"
  },
  {
    "x": "mîrazkerdiş (n)",
    "y": " mayalama (hamur için)"
  },
  {
    "x": "mîrçik, -e",
    "y": " b. mîlçik, -e"
  },
  {
    "x": "mîrçîk, -e",
    "y": " b. mîlçik, -e"
  },
  {
    "x": "mîrde (n)",
    "y": " b. mêrde (n)"
  },
  {
    "x": "mîrdim (n)",
    "y": " b. merdim (n)"
  },
  {
    "x": "mîre (n)",
    "y": " 1)bey\r\n~Mîreyîyê Ereban (zh): Birleşik Arap Emirlikleri\r\n~2)dama oyununda, karşıdaki son sıraya ulaşıp oyunda en avantajlı duruma ulaşan taş\r\n~3)aşık kemiğiyle oynanan bir oyunda bu kemiğin eni dar olan iki yan tarafından kısmen düz olanı"
  },
  {
    "x": "mîreyî (n)",
    "y": " bir tüfek çeşidi"
  },
  {
    "x": "Mîreyîyê Ereban (zh)",
    "y": " Birleşik Arap Emirlikleri"
  },
  {
    "x": "mîrîçiki (m)",
    "y": " b. mîlçike (m)"
  },
  {
    "x": "mîrkut (n)",
    "y": " tokmak"
  },
  {
    "x": "mîrkutikî (zh)",
    "y": " suçiçeği"
  },
  {
    "x": "mîrkutikî vetene",
    "y": " suçiçeği çıkarmak"
  },
  {
    "x": "mîrxas, -e",
    "y": " b. mêrxas, -e"
  },
  {
    "x": "mîrxasê (m)",
    "y": " b. mêrxasî (m)"
  },
  {
    "x": "mîrxasî (m)",
    "y": " b. mêrxasî (m)"
  },
  {
    "x": "mîrza (n)",
    "y": " bey oğlu, beyzade, mirza"
  },
  {
    "x": "mîsag, -e",
    "y": " b. mişag, -e"
  },
  {
    "x": "mîsale (m)",
    "y": " misal, örnek"
  },
  {
    "x": "mîste (n)",
    "y": " b. mîşte (n)"
  },
  {
    "x": "mîste bîyene",
    "y": " b. mîşte bîyene"
  },
  {
    "x": "mîste kerdene",
    "y": " b. mîşte kerdene"
  },
  {
    "x": "mîsyel bîyene",
    "y": " b. misyel bîyene"
  },
  {
    "x": "mîsyon (n)",
    "y": " misyon"
  },
  {
    "x": "mîsyoner, -e",
    "y": " misyoner"
  },
  {
    "x": "mîsyonerî (m)",
    "y": " misyonerlik"
  },
  {
    "x": "mîsyonerîye (m)",
    "y": " misyonerlik"
  },
  {
    "x": "mîşele bîyayîne",
    "y": " b. misyel bîyene"
  },
  {
    "x": "mîşelle bîyayîne",
    "y": " b. misyel bîyene"
  },
  {
    "x": "mîşna (m)",
    "y": " b. mêşna (m)"
  },
  {
    "x": "mîşt dayene",
    "y": " okşamak, ovmak"
  },
  {
    "x": "mîştane (n)",
    "y": " b. giştane (n), engiştane (n)"
  },
  {
    "x": "mîştayene",
    "y": " b. mîşt dayene"
  },
  {
    "x": "mîştayîs (n)",
    "y": " b. mîştdayîş (n)"
  },
  {
    "x": "mîştayîş (n)",
    "y": " b. mîştdayîş (n)"
  },
  {
    "x": "mîştdayîş (n)",
    "y": " okşama, ovma"
  },
  {
    "x": "mîşte-I (n)",
    "y": " mayalı ekmek"
  },
  {
    "x": "mîşte bîyene",
    "y": " mayalanmak (hamur için)"
  },
  {
    "x": "mîşte kerdene",
    "y": " 1)mayalamak (hamur için)\r\n~2)yoğurmak "
  },
  {
    "x": "mîşte-II (m)",
    "y": " b. miste (m)"
  },
  {
    "x": "mîştebîyayîş (n)",
    "y": " mayalanma (hamur için)"
  },
  {
    "x": "mîştekerde (n)",
    "y": " mayalı (hamur için)"
  },
  {
    "x": "mîştekerdis (n)",
    "y": " b. mîştekerdiş (n)"
  },
  {
    "x": "mîştekerdiş (n)",
    "y": " 1)mayalama (hamur için)\r\n~2)yoğurma"
  },
  {
    "x": "mîtîng (n)",
    "y": " miting"
  },
  {
    "x": "mîtro (n)",
    "y": " b. metre (n)"
  },
  {
    "x": "mîx (n)",
    "y": " b. mix (n)"
  },
  {
    "x": "mîx kerdene",
    "y": " b. mix kerdene"
  },
  {
    "x": "mîy (m)",
    "y": " b. mî (m)"
  },
  {
    "x": "mîya (n)",
    "y": " b. mîyan (n)"
  },
  {
    "x": "mîyabeyn (n)",
    "y": " b. mabên (n)"
  },
  {
    "x": "mîyan (n)",
    "y": " iç, ara\r\n~Mîyanê di dewan de koyê est bi. (İki köyün arasında bir dağ vardı.)"
  },
  {
    "x": "mîyan bîyayene",
    "y": " b. mîyan bîyene"
  },
  {
    "x": "mîyan bîyene",
    "y": " karışmak (dahil olmak)"
  },
  {
    "x": "mîyan kerdene",
    "y": " içine katmak, karıştırmak (içine karıştırmak), karıştırmak\r\n~Mi meke mîyanê karanê xo. (Beni kendi işlerine karıştırma.)"
  },
  {
    "x": "mîyanê çengalekan de",
    "y": " tırnak içinde (yazı için)"
  },
  {
    "x": "mîyanê roje ra pey",
    "y": " öğleden sonra"
  },
  {
    "x": "mîyanê roje ra ver",
    "y": " öğleden önce"
  },
  {
    "x": "mîyanbaran (n)",
    "y": " bağda üzüm teveği sıraları arasındaki bölüm"
  },
  {
    "x": "mîyanbaran kerdene",
    "y": " bağ tevekleri hereklere sarıldıktan sonra bağı ikinci kez sürmek "
  },
  {
    "x": "mîyanbaron (n)",
    "y": " b. mîyanbaran (n)"
  },
  {
    "x": "mîyanbaron kardene",
    "y": " b. mîyanbaran kerdene"
  },
  {
    "x": "mîyanbîyayîş (n)",
    "y": " karışma (dahil olma)"
  },
  {
    "x": "mîyançî, -ye-I",
    "y": " aracı, arabulucu"
  },
  {
    "x": "mîyançî-II (n)",
    "y": " 1)ince bulgur\r\n~2)heybenin iki tarafı arasındaki bölüm"
  },
  {
    "x": "mîyançîyîye (m)",
    "y": " arabuluculuk"
  },
  {
    "x": "mîyandar (n)",
    "y": " ince bulgur, köfte vb. yapmakta kullanılan ince yarma"
  },
  {
    "x": "mîyandewletan",
    "y": " devletlerarası"
  },
  {
    "x": "mîyandoş (n)",
    "y": " omuzların arası"
  },
  {
    "x": "mîyandîs, -i",
    "y": " b. muhendis, -e"
  },
  {
    "x": "mîyandîsî (m)",
    "y": " b. muhendisî (m), muhendisîye (m)"
  },
  {
    "x": "mîyane-I (n)",
    "y": " 1)bel\r\n~2)güre"
  },
  {
    "x": "mîyane eştene (at için)",
    "y": " çatlamak (ölmek)"
  },
  {
    "x": "mîyane girewtene",
    "y": " güreşmek, güreş tutmak"
  },
  {
    "x": "mîyane ronayene",
    "y": " çömelmeden ve belini doğrultmadan, eğilmiş durumda ekin biçmekte yarışmak"
  },
  {
    "x": "mîyaneyê fîstanî (n)",
    "y": " fistanın bele oturan bölümü"
  },
  {
    "x": "mîyanê fîstanî (n)",
    "y": " b. mîyaneyê fîstanî (n)"
  },
  {
    "x": "mîyane-II (n)",
    "y": " b. muayene (n)"
  },
  {
    "x": "mîyane kerdene",
    "y": " b. muayene kerdene"
  },
  {
    "x": "mîyaneraantiş (sn)",
    "y": " bel çekimi (sn)"
  },
  {
    "x": "mîyanê (n)",
    "y": " b. mîyanên (n)"
  },
  {
    "x": "mîyanên, -e",
    "y": " 1. aradaki 2. orta"
  },
  {
    "x": "mîyanêni (m)",
    "y": " b. mîyanêne (m)"
  },
  {
    "x": "mîyangil (n)",
    "y": " ince bulgur, köfte vb. yapmakta kullanılan ince yarma"
  },
  {
    "x": "mîyanik a",
    "y": " b. mîyanîk a"
  },
  {
    "x": "mîyanikî",
    "y": " b. mîyanîkî"
  },
  {
    "x": "mîyanin, -e",
    "y": " b. mîyanên, -e"
  },
  {
    "x": "mîyanî-I (n)",
    "y": " b. mîyane (n)"
  },
  {
    "x": "mîyanî-II (n)",
    "y": " b. mîyanên (n)"
  },
  {
    "x": "mîyanî-III",
    "y": " gizli"
  },
  {
    "x": "mîyanîk a",
    "y": " gizlice"
  },
  {
    "x": "mîyanîkî",
    "y": " gizlice"
  },
  {
    "x": "mîyanîn, -e",
    "y": " b. mîyanên, -e"
  },
  {
    "x": "mîyankar, -e",
    "y": " aracı"
  },
  {
    "x": "mîyankarîye (m)",
    "y": " aracılık"
  },
  {
    "x": "mîyankerdiş (n)",
    "y": " karıştırma"
  },
  {
    "x": "mîyankî",
    "y": " b. mîyanîkî"
  },
  {
    "x": "mîyanneteweyî, -ye",
    "y": " uluslararası, milletlerarası, beynelmilel"
  },
  {
    "x": "mîyanpiştî (m)",
    "y": " omuzların arası"
  },
  {
    "x": "mîyanpolan (n)",
    "y": " omuzların arası"
  },
  {
    "x": "mîyanşewe (m)",
    "y": " gece yarısı"
  },
  {
    "x": "mîyanter (n)",
    "y": " b. beyntar (n)"
  },
  {
    "x": "Mîyaran (n)",
    "y": " Arıcak"
  },
  {
    "x": "Mîyarû (n)",
    "y": " b. Mîyaran (n)"
  },
  {
    "x": "Mîyarûn (n)",
    "y": " b. Mîyaran (n)"
  },
  {
    "x": "mîyaveyn (n)",
    "y": " b. mabên (n)"
  },
  {
    "x": "mîyaw",
    "y": " b. nîyaw"
  },
  {
    "x": "mîyawkayene",
    "y": " b. nîyawkayene"
  },
  {
    "x": "mîyawkayîş (n)",
    "y": " b. nîyawkayîş (n)"
  },
  {
    "x": "mîyaz (n)",
    "y": " b. nîyaz (n)"
  },
  {
    "x": "mîye-I (m)",
    "y": " 1)kuşak\r\n~2)(mecazi) göbek hizası)"
  },
  {
    "x": "mîya Ecemî (m)",
    "y": " Acem kuşağı"
  },
  {
    "x": "mîya hewze (m)",
    "y": " yeşil kuşak"
  },
  {
    "x": "mîya mişoneyî (m)",
    "y": " b. mîya muşeneyî (m)"
  },
  {
    "x": "mîya muşeneyî (m)",
    "y": " dövenin tahtalarını birbirine bağlayan ve onlara enine çakılmış olan çıta"
  },
  {
    "x": "mîye bestene",
    "y": " kuşak bağlamak"
  },
  {
    "x": "mîye girê dayene",
    "y": " kuşak bağlamak"
  },
  {
    "x": "mîye pîştene",
    "y": " kuşak bağlamak"
  },
  {
    "x": "mîye xo mîyane şanayene",
    "y": " kuşak bağlamak "
  },
  {
    "x": "mîye-II (m)",
    "y": " b. mî (m)"
  },
  {
    "x": "mîye-III (n)",
    "y": " b. mîyan (n)"
  },
  {
    "x": "mîyeman, -e",
    "y": " b. mêman, -e"
  },
  {
    "x": "mîyen (n)",
    "y": " b. mîyan (n)"
  },
  {
    "x": "mîyercila (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "mîyerde (n)",
    "y": " b. mêrde (n)"
  },
  {
    "x": "mîyerdi (n)",
    "y": " b. mêrde (n)"
  },
  {
    "x": "mîyerik (n)",
    "y": " b. mêrik (n)"
  },
  {
    "x": "mîyes (m)",
    "y": " 1)b. mêse (m)\r\n~2)b. mêsa hingimênî (m"
  },
  {
    "x": "mîyesê êgmîn (m)",
    "y": " b. mêsa hingimênî (m)"
  },
  {
    "x": "mîyeşna (m)",
    "y": " b. mêşna (m)"
  },
  {
    "x": "mîyezik (n)",
    "y": " b. mozik (n)"
  },
  {
    "x": "mîyêrik (n)",
    "y": " b. mêrik (n)"
  },
  {
    "x": "mîyin (n)",
    "y": " b. mîyîn (n)"
  },
  {
    "x": "mîyîn (n)",
    "y": " 1)kazık\r\n~2)ucu sivriltilmiş çubuk"
  },
  {
    "x": "mîyînî (zh)",
    "y": " kazık biçiminde ucu sivriltilmiş çubuklarla oynanan bir çocuk oyunu"
  },
  {
    "x": "mîyn (n)",
    "y": " b. mîyan (n)"
  },
  {
    "x": "mîyne (n)",
    "y": " b. mîyane (n)"
  },
  {
    "x": "mîyo (n)",
    "y": " b. mîyan (n)"
  },
  {
    "x": "mîyon (n)",
    "y": " b. mîyan (n)"
  },
  {
    "x": "mîyon bîyayene",
    "y": " b. mîyan bîyene"
  },
  {
    "x": "mîyonî rojî pey",
    "y": " b. mîyanê roje ra pey"
  },
  {
    "x": "mîyonî rojî ver",
    "y": " b. mîyanê roje ra ver"
  },
  {
    "x": "mîyonbîyayîş (n)",
    "y": " b. mîyanbîyayîş "
  },
  {
    "x": "mîyoncar (n)",
    "y": " b. mîyandar (n)"
  },
  {
    "x": "mîyondar (n)",
    "y": " b. mîyandar (n)"
  },
  {
    "x": "mîyondîs, -i",
    "y": " b. muhendis, -e"
  },
  {
    "x": "mîyondîsey (m)",
    "y": " b. muhendisî (m), muhendisîye (m)"
  },
  {
    "x": "mîyondîsê (m)",
    "y": " b. muhendisî (m), muhendisîye (m)"
  },
  {
    "x": "mîyone (n)",
    "y": " b. mîyane (n)"
  },
  {
    "x": "mîyonê (n)",
    "y": " b. mîyanên (n)"
  },
  {
    "x": "mîyonên, -i",
    "y": " b. mîyanên, -e"
  },
  {
    "x": "mîyongil (n)",
    "y": " b. mîyangil (n)"
  },
  {
    "x": "mîyoni (n)",
    "y": " b. mîyan (n)"
  },
  {
    "x": "mîyonî",
    "y": " b. mîyanî"
  },
  {
    "x": "mîyt, -e",
    "y": " b. meyît, -e"
  },
  {
    "x": "mîyurdum, -i",
    "y": " b. merdim, -e"
  },
  {
    "x": "mîyû (n)",
    "y": " b. mîyan (n)"
  },
  {
    "x": "mîyûn (n)",
    "y": " b. mîyan (n)"
  },
  {
    "x": "mîyûn bîyayene",
    "y": " b. mîyan bîyene"
  },
  {
    "x": "mîyûnî ruecî pey",
    "y": " b. mîyanê roje ra pey"
  },
  {
    "x": "mîyûnî ruecî ver",
    "y": " b. mîyanê roje ra ver"
  },
  {
    "x": "mîyûnbîyayîş (n)",
    "y": " b. mîyanbîyayîş (n)"
  },
  {
    "x": "mîyûncar (n)",
    "y": " b. mîyandar (n)"
  },
  {
    "x": "mîyûncî (n)",
    "y": " b. mîyançî (n)"
  },
  {
    "x": "mîyûnçî (n)",
    "y": " b. mîyançî (n)"
  },
  {
    "x": "mîyûndar (n)",
    "y": " b. mîyandar (n)"
  },
  {
    "x": "mîyûne (n)",
    "y": " b. mîyane (n)"
  },
  {
    "x": "mîyûni (n)",
    "y": " b. mîyane (n)"
  },
  {
    "x": "mîyûnik",
    "y": " b. mîyanîkî"
  },
  {
    "x": "mîyûnî-I",
    "y": " b. mîyanî-III"
  },
  {
    "x": "mîyûnî-II (n)",
    "y": " b. mîyanên (n)"
  },
  {
    "x": "mîyûnîn (n)",
    "y": " b. mîyanên (n)"
  },
  {
    "x": "mîyûnkî",
    "y": " b. mîyanîkî"
  },
  {
    "x": "mîz (m)",
    "y": " b. mîze (m)"
  },
  {
    "x": "mîz kerdene",
    "y": " b. mîze kerdene"
  },
  {
    "x": "mîzanpaj (n)",
    "y": " mizanpaj"
  },
  {
    "x": "mîzbi (n)",
    "y": " b. mîzbin (n)"
  },
  {
    "x": "mîzbin, -e",
    "y": " altına işeyen"
  },
  {
    "x": "mîze (m)",
    "y": " idrar, sidik, çiş"
  },
  {
    "x": "mîze kerdene",
    "y": " işemek"
  },
  {
    "x": "mîzeh (n)",
    "y": " mizah"
  },
  {
    "x": "mîzehî, -ye",
    "y": " mizahi"
  },
  {
    "x": "mîzi (m)",
    "y": " b. mîze (m)"
  },
  {
    "x": "mîzi kerdene",
    "y": " b. mîze kerdene"
  },
  {
    "x": "mîziker, -i",
    "y": " b. mîzker, -e"
  },
  {
    "x": "mîzî-I (m)",
    "y": " b. mîze (m)"
  },
  {
    "x": "mîzî kerdene",
    "y": " b. mîze kerdene"
  },
  {
    "x": "mîzî-II (n)",
    "y": " sik, penis, kamış"
  },
  {
    "x": "mîzîbir, -e",
    "y": " sünnetçi"
  },
  {
    "x": "mîzîbirî (m)",
    "y": " sünnetçilik"
  },
  {
    "x": "mîzîbirîye (m)",
    "y": " sünnetçilik"
  },
  {
    "x": "mîzker, -e",
    "y": " altına işeyen"
  },
  {
    "x": "mîzo",
    "y": " altına işeyen (kaba dilde)"
  },
  {
    "x": "mîzorik, -e",
    "y": " b. mîzoruk, -e-II"
  },
  {
    "x": "mîzoruk, -e-I",
    "y": " idrarını tutamıyan, sidiğini tutamıyan"
  },
  {
    "x": "mîzoruk-II (n)",
    "y": " sidik torbası, mesane"
  },
  {
    "x": "mîzurik, -e-I",
    "y": " b. mîzoruk, -e-I"
  },
  {
    "x": "mîzurik-II (n)",
    "y": " b. mîzoruk-II (n)"
  },
  {
    "x": "mo-I (m)",
    "y": " b. maye (m)"
  },
  {
    "x": "mowa ho ra bîyayene",
    "y": " b. maya xo ra bîyene"
  },
  {
    "x": "mowa xo ra kotene",
    "y": " b. maya xo ra kewtene"
  },
  {
    "x": "mo-II",
    "y": " b. ma-II"
  },
  {
    "x": "mobike (m)",
    "y": " 1)eklem\r\n~mobika destî (m): el veya parmak eklemi\r\n~2)boğum (ağaç için)\r\n~mobika dare (m): ağaç boğumu"
  },
  {
    "x": "mobika destî (m)",
    "y": " el veya parmak eklemi"
  },
  {
    "x": "mobika dare (m)",
    "y": " ağaç boğumu"
  },
  {
    "x": "mobîl, -e",
    "y": " hareketli, mobil"
  },
  {
    "x": "mobîlîte (m)",
    "y": " hareketlilik, mobilite"
  },
  {
    "x": "mobîlya (m)",
    "y": " mobilya"
  },
  {
    "x": "mobîlyakar, -e",
    "y": " mobilyacı"
  },
  {
    "x": "mobîlyakarî (m)",
    "y": " mobilyacılık"
  },
  {
    "x": "mobîlyakarîye (m)",
    "y": " mobilyacılık"
  },
  {
    "x": "mobîlyaroş, -e",
    "y": " mobilyacı"
  },
  {
    "x": "mobîlyaroşî (m)",
    "y": " mobilyacılık"
  },
  {
    "x": "mobîlyaroşîye (m)",
    "y": " mobilyacılık"
  },
  {
    "x": "mocla (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "mocle (n)",
    "y": " b. morcele (n)"
  },
  {
    "x": "mocnayene",
    "y": " b. mojnayene"
  },
  {
    "x": "mocnayîş (n)",
    "y": " b. mojnayîş (n)"
  },
  {
    "x": "moda (m)",
    "y": " moda"
  },
  {
    "x": "modakar, -e",
    "y": " modacı"
  },
  {
    "x": "modakarî (m)",
    "y": " modacılık"
  },
  {
    "x": "modakarîye (m)",
    "y": " modacılık"
  },
  {
    "x": "modalî (n)",
    "y": " b. modelî (n)"
  },
  {
    "x": "modan (n)",
    "y": " b. mode (n)"
  },
  {
    "x": "Modan (n)",
    "y": " Mutki"
  },
  {
    "x": "mode-I (n)",
    "y": " 1)bir çeşit toz boya\r\n~2)eskiden göz ilacı olarak kullanılan ve içine su katılınca kırmızılaşan bir toz"
  },
  {
    "x": "mode kerdene",
    "y": " başkasına ait hayvanlarla karıştırılmaması için hayvanın bir yerini boyamak"
  },
  {
    "x": "mode-II (n)",
    "y": " b. moda (m)"
  },
  {
    "x": "model, -e",
    "y": " model"
  },
  {
    "x": "modela ganîye (m)",
    "y": " canlı model"
  },
  {
    "x": "modelo ganî (n)",
    "y": " canlı model"
  },
  {
    "x": "modelî (n)",
    "y": " bir tüfek çeşidi"
  },
  {
    "x": "modelîst, -e",
    "y": " modelist"
  },
  {
    "x": "modem (n) (TE)",
    "y": " modem"
  },
  {
    "x": "modern, -e",
    "y": " çağdaş, asri, muasır, çağcıl, modern, muasır"
  },
  {
    "x": "modern bîyene",
    "y": " çağcıllaşmak, çağdaşlaşmak, asrileşmek, modernleşmek, muasırlaşmak"
  },
  {
    "x": "modern kerdene",
    "y": " çağcıllaştırmak, asrileştirmek, çağdaşlaştırmak, modernleştirmek, muasırlaştırmak"
  },
  {
    "x": "moderney (m)",
    "y": " b. modernîye (m)"
  },
  {
    "x": "modernê (m)",
    "y": " b. modernî (m)"
  },
  {
    "x": "modernî (m)",
    "y": " çağcıllık, çağdaşlık, asrilik, modernlik, modernizm, muasırlık"
  },
  {
    "x": "modernîte (m)",
    "y": " modernite"
  },
  {
    "x": "modernîye (m)",
    "y": " çağcıllık, çağdaşlık, asrilik, modernlik"
  },
  {
    "x": "modernîzm",
    "y": " modernizm"
  },
  {
    "x": "modik (n)",
    "y": " fol"
  },
  {
    "x": "modke (n)",
    "y": " b. mot (n)"
  },
  {
    "x": "modol (n)",
    "y": " b. modelî (n)"
  },
  {
    "x": "modul (n)",
    "y": " modül"
  },
  {
    "x": "modulê malî (n)",
    "y": " mallar modülü"
  },
  {
    "x": "Modulê Nasnameyê Aboneyî (SIM)",
    "y": " Abone Kimlik Modülü (SIM)"
  },
  {
    "x": "moga (m)",
    "y": " b. manga (m)"
  },
  {
    "x": "Mogadîşû (n)",
    "y": " Mogadişu"
  },
  {
    "x": "mojela (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "mojla (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "mojle (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "mojnayene",
    "y": " göstermek"
  },
  {
    "x": "mojnayîş (n)",
    "y": " gösterme"
  },
  {
    "x": "moker (m)",
    "y": " b. mankere (m)"
  },
  {
    "x": "molcela (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "molcila (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "Moldova (m)",
    "y": " Moldova"
  },
  {
    "x": "mole (m)",
    "y": " değnek; ince ağaç dalı, sepet örmekte kullanılan ince ağaç dalı"
  },
  {
    "x": "molet (m)",
    "y": " b. muhlet (n)"
  },
  {
    "x": "molet dayene",
    "y": " b. muhlet dayene"
  },
  {
    "x": "moletdayîş (n)",
    "y": " b. muhletdayîş (n)"
  },
  {
    "x": "molmoleke (m)",
    "y": " b. molmolike (m)"
  },
  {
    "x": "molmolike (m)",
    "y": " uğurböceği"
  },
  {
    "x": "momî (m)",
    "y": " 1)balmumu\r\n~2)b. mume (m)"
  },
  {
    "x": "momîya hemgênî (m)",
    "y": " b. momîya hingimênî (m)"
  },
  {
    "x": "momîya hingimênî (m)",
    "y": " balmumu"
  },
  {
    "x": "mome (m)",
    "y": " b. mume (m)"
  },
  {
    "x": "mon (n)",
    "y": " b. mîyan (n)"
  },
  {
    "x": "mon-",
    "y": " b. man-"
  },
  {
    "x": "Monako (n)",
    "y": " Monako"
  },
  {
    "x": "moncle (n)",
    "y": " b. morcele (n)"
  },
  {
    "x": "mondiş (n)",
    "y": " b. mûnitiş (n)"
  },
  {
    "x": "mone (n)",
    "y": " b. mîyane (n)"
  },
  {
    "x": "mone ronayene",
    "y": " b. mîyane ronayene"
  },
  {
    "x": "monga (m)",
    "y": " b. manga (m)"
  },
  {
    "x": "monikî",
    "y": " b. mîyanîkî"
  },
  {
    "x": "monîla (m)",
    "y": " b. manîla (m)"
  },
  {
    "x": "monîtor (n) (TE)",
    "y": " görüntüleyici, monitör"
  },
  {
    "x": "monker (m)",
    "y": " b. mankere (m)"
  },
  {
    "x": "monkeri (m)",
    "y": " b. mankere (m)"
  },
  {
    "x": "monoray (m)",
    "y": " monoray"
  },
  {
    "x": "monqal (n)",
    "y": " b. manqale (m)"
  },
  {
    "x": "Monrovya (m)",
    "y": " Monrovia"
  },
  {
    "x": "montaj (m, sn)",
    "y": " kurgu, montaj, monte"
  },
  {
    "x": "montajo sade (sn)",
    "y": " düz kurgu"
  },
  {
    "x": "montajo xam (sn)",
    "y": " kaba kurgu"
  },
  {
    "x": "montajkar, -e",
    "y": " montajcı"
  },
  {
    "x": "montenêkerde (n)",
    "y": " demonte"
  },
  {
    "x": "montenêkerdî, -ye",
    "y": " demonte"
  },
  {
    "x": "Montevîdeo (n)",
    "y": " Montevideo"
  },
  {
    "x": "monto (n)",
    "y": " b. manto (n)"
  },
  {
    "x": "montor, -e",
    "y": " montör"
  },
  {
    "x": "mor-I (n)",
    "y": " mühür"
  },
  {
    "x": "mor bîyayeni",
    "y": " b. mor bîyene"
  },
  {
    "x": "mor bîyene",
    "y": " mühürlenmek"
  },
  {
    "x": "mor kerdene",
    "y": " mühürlemek"
  },
  {
    "x": "mor panayene",
    "y": " mühürlemek"
  },
  {
    "x": "mor panîyayene",
    "y": " mühürlenmek"
  },
  {
    "x": "mor, -e-II",
    "y": " b. mar, -e"
  },
  {
    "x": "moro awî (n)",
    "y": " b. maro awî (n)"
  },
  {
    "x": "mora (m)",
    "y": " b. mura-I (m)"
  },
  {
    "x": "mora mîyanî (m)",
    "y": " b. muraya mîyanî (m)"
  },
  {
    "x": "moraya mîyanî (m)",
    "y": " b. muraya mîyanî (m)"
  },
  {
    "x": "moraba, -ye",
    "y": " b. mirêba, -ye"
  },
  {
    "x": "morabatî (m)",
    "y": " b. mirêbatî (m)"
  },
  {
    "x": "morabatîye (m)",
    "y": " b. mirêbatîye (m)"
  },
  {
    "x": "morasing (n)",
    "y": " b. marşinge (m)"
  },
  {
    "x": "morava, -ye",
    "y": " b. mirêba, -ye"
  },
  {
    "x": "moravayênî (m)",
    "y": " b. mirêbayênî (m)"
  },
  {
    "x": "moravayîye (m)",
    "y": " b. mirêbayîye (m)"
  },
  {
    "x": "morc (n)",
    "y": " yontma kalemi"
  },
  {
    "x": "morcela (m)",
    "y": " karınca"
  },
  {
    "x": "morcelaya devan (m) ",
    "y": "atlı karınca"
  },
  {
    "x": "morcelaya kutikan (m)",
    "y": " atlı karınca"
  },
  {
    "x": "morcelaya siwarî (m)",
    "y": " atlı karınca"
  },
  {
    "x": "morcele (n)",
    "y": " karınca"
  },
  {
    "x": "morceleyê devan (n)",
    "y": " atlı karınca"
  },
  {
    "x": "morceleyê kutikan (n)",
    "y": " atlı karınca"
  },
  {
    "x": "morceleyo siwarî (n)",
    "y": " atlı karınca"
  },
  {
    "x": "morceli (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "morcila (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "morcile (m)",
    "y": " b. morcele (m)"
  },
  {
    "x": "mordar, -i",
    "y": " b. murdar, -e"
  },
  {
    "x": "mordar bîyayeni",
    "y": " b. murdar bîyene"
  },
  {
    "x": "mordar kerdene",
    "y": " b. murdar kerdene"
  },
  {
    "x": "mordem, -e",
    "y": " b. merdim, -e"
  },
  {
    "x": "mordem kistene",
    "y": " b. merdim kiştene"
  },
  {
    "x": "mordemê (n)",
    "y": " b. merdimê (n)"
  },
  {
    "x": "mordemek (n)",
    "y": " b. mêrdek (n)"
  },
  {
    "x": "mordemîye (m)",
    "y": " b. merdimîye (m)"
  },
  {
    "x": "mordemkistis (n)",
    "y": " b. merdimkiştiş (n)"
  },
  {
    "x": "mordene",
    "y": " b. hûmaritene"
  },
  {
    "x": "mordeni",
    "y": " b. hûmaritene"
  },
  {
    "x": "mordim, -e",
    "y": " b. merdim, -e"
  },
  {
    "x": "mordiş (n)",
    "y": " b. hûmaritiş (n)"
  },
  {
    "x": "more-I (m)",
    "y": " b. mura (m)"
  },
  {
    "x": "more-II (m)",
    "y": " b. hûmare (m)"
  },
  {
    "x": "more-III (n)",
    "y": " b. mare (n)"
  },
  {
    "x": "more betal bîyayene",
    "y": " b. mare betal bîyene"
  },
  {
    "x": "more betal kerdene",
    "y": " b. mare betal kerdene"
  },
  {
    "x": "more birnawox (n)",
    "y": " b. marebir, -e"
  },
  {
    "x": "more birnayene",
    "y": " b. mare birnayene"
  },
  {
    "x": "more bîyayene",
    "y": " b. mare bîyene"
  },
  {
    "x": "more kerdene",
    "y": " b. mare kerdene"
  },
  {
    "x": "more ra",
    "y": " b. mare ra"
  },
  {
    "x": "moreba, -ye",
    "y": " b. mirêba, -ye"
  },
  {
    "x": "morebayênî (m)",
    "y": " b. mirêbayênî (m)"
  },
  {
    "x": "morebayîye (m)",
    "y": " b. mirêbayîye (m)"
  },
  {
    "x": "morebir, -e",
    "y": " b. marebir, -e"
  },
  {
    "x": "morebîyayîş (n)",
    "y": " b. marebîyayîş (n)"
  },
  {
    "x": "morek (m)",
    "y": " b. mura-I (m)"
  },
  {
    "x": "moreke (m)",
    "y": " b. mura-I (m)"
  },
  {
    "x": "moreka hurdîye (m)",
    "y": " b. muraya hurdî/hurdîye (m)"
  },
  {
    "x": "moreka nezerî (m)",
    "y": " b. muraya nezerî (m)"
  },
  {
    "x": "morekerdis (n)",
    "y": " b. marekerdiş (n)"
  },
  {
    "x": "morekerdî, -ye",
    "y": " b. marekerdî, -ye"
  },
  {
    "x": "moresing (n)",
    "y": " b. marşinge (m)"
  },
  {
    "x": "moreva, -ye",
    "y": " b. mirêba, -ye"
  },
  {
    "x": "morevaên (m)",
    "y": " b. mirêbayênî (m)"
  },
  {
    "x": "morevayênî (m)",
    "y": " b. mirêbayênî (m)"
  },
  {
    "x": "morevayîye (m)",
    "y": " b. mirêbayîye (m)"
  },
  {
    "x": "morfolojî (n)",
    "y": " biçim bilgisi, biçimbilim, morfoloji, şekil bilgisi, şekliyat"
  },
  {
    "x": "morg (n)",
    "y": " morg "
  },
  {
    "x": "morî (m)",
    "y": " b. marî (m)"
  },
  {
    "x": "Morîtanya (m)",
    "y": " Moritanya"
  },
  {
    "x": "morîye (m)",
    "y": " b. marî (m)"
  },
  {
    "x": "morje (n)",
    "y": " b. morc (n)"
  },
  {
    "x": "morjile (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "morkerda (m)",
    "y": " mühürlü"
  },
  {
    "x": "morkerde (n)",
    "y": " mühürlü"
  },
  {
    "x": "morkerdî (m)",
    "y": " mühürlü"
  },
  {
    "x": "morkerdîye (m)",
    "y": " mühürlü"
  },
  {
    "x": "mormek (n)",
    "y": " b. mêrdek (n)"
  },
  {
    "x": "mormose (n)",
    "y": " b. marmase (n)"
  },
  {
    "x": "mornayene",
    "y": " mühürlemek"
  },
  {
    "x": "mornayeni",
    "y": " b. mornayene"
  },
  {
    "x": "morûmilawinî (zh)",
    "y": " b. marûmilawinî (zh)"
  },
  {
    "x": "morz (n)",
    "y": " b. morc (n)"
  },
  {
    "x": "morzela (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "morzine (n)",
    "y": " b. morzîne (n)"
  },
  {
    "x": "morzîne (n)",
    "y": " bilezik (boru vb. için)"
  },
  {
    "x": "morzîneyin, -e",
    "y": " bilezikli (boru vb. için)"
  },
  {
    "x": "mosayene",
    "y": " b. masayene"
  },
  {
    "x": "mose (n)",
    "y": " b. mase-I (n)"
  },
  {
    "x": "Mose",
    "y": " Çayırlı"
  },
  {
    "x": "mosela (m)",
    "y": " saban oku, karasaban oku"
  },
  {
    "x": "moseşimşêr (n)",
    "y": " b. maseşimşêr (n)"
  },
  {
    "x": "mosewere (m)",
    "y": " b. masewere (m)"
  },
  {
    "x": "mosil (n)",
    "y": " b. mehsul (n)"
  },
  {
    "x": "Moskova (m)",
    "y": " Moskova"
  },
  {
    "x": "mosla (m)",
    "y": " b. mosela (m)"
  },
  {
    "x": "mosle (m)",
    "y": " b. mosela (m)"
  },
  {
    "x": "mosnayene",
    "y": " b. masnayene"
  },
  {
    "x": "most (n)",
    "y": " b. mast (n)"
  },
  {
    "x": "mostik (n)",
    "y": " b. mustik (n)"
  },
  {
    "x": "moşene (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "moşeni (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "moşt (n)",
    "y": " b. mast (n)"
  },
  {
    "x": "mot (n)",
    "y": " pekmez"
  },
  {
    "x": "mot mendene",
    "y": " b. mat mendene"
  },
  {
    "x": "motac, -e",
    "y": " b. muhtac, -e"
  },
  {
    "x": "motaz, -e",
    "y": " b. muhtac, -e"
  },
  {
    "x": "motin, -e (s)",
    "y": " pekmezli"
  },
  {
    "x": "motiş (n)",
    "y": " b. mojnayîş (n)"
  },
  {
    "x": "motîvasyon (n)",
    "y": " motivasyon"
  },
  {
    "x": "Motkan (n)",
    "y": " Mutki"
  },
  {
    "x": "motopomp (n)",
    "y": " motopomp"
  },
  {
    "x": "motordar, -e",
    "y": " motorcu (deniz motoru işleten)"
  },
  {
    "x": "motore (m)",
    "y": " 1)motor\r\n~2)traktör\r\n~3)motosiklet"
  },
  {
    "x": "motora cite (m)",
    "y": " traktör"
  },
  {
    "x": "motora elektrîkî (m)",
    "y": " elektrik motoru"
  },
  {
    "x": "motori (m)",
    "y": " b. motore (m)"
  },
  {
    "x": "motorsaz, -e",
    "y": " motor tamircisi"
  },
  {
    "x": "motosîklete (m)",
    "y": " motosiklet, motor"
  },
  {
    "x": "moture (n)",
    "y": " b. mature (n)"
  },
  {
    "x": "motureyî (m)",
    "y": " b. matureyîye (m)"
  },
  {
    "x": "motûr (m)",
    "y": " b. motore (m)"
  },
  {
    "x": "motûrpisqilêt (m)",
    "y": " b. motosîklete (m)"
  },
  {
    "x": "movike (m)",
    "y": " b. mobike (m)"
  },
  {
    "x": "movika dare (m)",
    "y": " b. mobika dare (m)"
  },
  {
    "x": "moviki (m)",
    "y": " b. mobike (m)"
  },
  {
    "x": "mowsim (n)",
    "y": " b. mewsim (n)"
  },
  {
    "x": "mowzer (n)",
    "y": " b. mewzer (n)"
  },
  {
    "x": "mowzi (n)",
    "y": " b. mewzî (n)"
  },
  {
    "x": "moxil (m)",
    "y": " b. moxle (m)"
  },
  {
    "x": "moxile (m)",
    "y": " b. moxle (m)"
  },
  {
    "x": "moxili (m)",
    "y": " b. moxle (m)"
  },
  {
    "x": "moxirbe (m)",
    "y": " b. mexreb (n)"
  },
  {
    "x": "moxle (m)",
    "y": " elek"
  },
  {
    "x": "moxli (m)",
    "y": " b. moxle (m)"
  },
  {
    "x": "Moxolîstan (n)",
    "y": " Moğolistan"
  },
  {
    "x": "Mozambîk (n)",
    "y": " Mozambik"
  },
  {
    "x": "moz (m)",
    "y": " b. moze (m)"
  },
  {
    "x": "moz kerdene",
    "y": " b. moze kerdene"
  },
  {
    "x": "moz kerdeni",
    "y": " b. moze kerdene"
  },
  {
    "x": "moze (m)",
    "y": " büğelek, bevelek, böğelek, büğe, bükelek, büve, büvelek, nokra sineği, iğrice, gübre sineği, güğüm sineği "
  },
  {
    "x": "moza gî (m)",
    "y": " b. moza gîyî (m)"
  },
  {
    "x": "moza gîyî (m)",
    "y": " bokböceği, pislik böceği, hapböceği"
  },
  {
    "x": "moza rêxe (m)",
    "y": " bokböceği, pislik böceği, hapböceği"
  },
  {
    "x": "moze kerdene",
    "y": " bükelemek, büğelemek (büğeleğin -özellikle baharın- sığırı sokmasıyla bu hayvanların alabildiğine koşması)"
  },
  {
    "x": "moze kewtene",
    "y": " bükelemek, büğelemek (büğeleğin -özellikle baharın- sığırı sokmasıyla bu hayvanların alabildiğine koşması)"
  },
  {
    "x": "mozi (m)",
    "y": " b. moze (m)"
  },
  {
    "x": "mozi kardene",
    "y": " b. moze kerdene"
  },
  {
    "x": "mozik, -e-I",
    "y": " dana"
  },
  {
    "x": "mozik-II (m)",
    "y": " b. mozike-II (m)"
  },
  {
    "x": "mozike-I (m)",
    "y": " düve"
  },
  {
    "x": "mozike-II (m)",
    "y": " topaç"
  },
  {
    "x": "mozin (n)",
    "y": " b. muezîn (n)"
  },
  {
    "x": "mozinê (m)",
    "y": " b. muezînî (m)"
  },
  {
    "x": "mozir, -i",
    "y": " b. mûzir, -e"
  },
  {
    "x": "mozirey (m)",
    "y": " b. mûzirî (m), mûzirîye (m)"
  },
  {
    "x": "mozirey kerdene",
    "y": " b. mûzirî kerdene, mûzirîye kerdene"
  },
  {
    "x": "mozole (m)",
    "y": " anıt mezar, mozole"
  },
  {
    "x": "MP3 (n) (TE)",
    "y": " MP3"
  },
  {
    "x": "mu (m)",
    "y": " maye-I (m)"
  },
  {
    "x": "muwa ho ra bîyene",
    "y": " b. maya xo ra bîyene"
  },
  {
    "x": "muaf, -e",
    "y": " muaf"
  },
  {
    "x": "muafî (m)",
    "y": " muafiyet"
  },
  {
    "x": "muafîye (m)",
    "y": " muafiyet"
  },
  {
    "x": "muafîyet (n)",
    "y": " muafiyet"
  },
  {
    "x": "mualîm, -e",
    "y": " b. malim, -e"
  },
  {
    "x": "muamela (m)",
    "y": " b. muamele (m)"
  },
  {
    "x": "muamele (m)",
    "y": " işlem, muamele"
  },
  {
    "x": "muamela huqûqîye (m)",
    "y": " hukuksal işlem, hukuki işlem"
  },
  {
    "x": "muamela kiriştişî (m)",
    "y": " taşıma işlemleri,"
  },
  {
    "x": "muamela neqlî (m)",
    "y": " nakil işlemleri"
  },
  {
    "x": "muameleyê îdarîyî (zh)",
    "y": " idari işlemler"
  },
  {
    "x": "muameleyê îdarîyî yê pergalkarî (zh)",
    "y": " düzenleyici idari işlemler"
  },
  {
    "x": "muameleyê îdarîyî yê tertîbkarî (zh)",
    "y": " düzenleyici idari işlemler"
  },
  {
    "x": "muamele (n)",
    "y": " b. muamele (m)"
  },
  {
    "x": "muane (n)",
    "y": " b. muayene (n)"
  },
  {
    "x": "muasir, -e",
    "y": " çağdaş"
  },
  {
    "x": "muasirî (m)",
    "y": " çağdaşlık"
  },
  {
    "x": "muasirîye (m)",
    "y": " çağdaşlık"
  },
  {
    "x": "muasla (m)",
    "y": " b. mosela (m)"
  },
  {
    "x": "muaven (n)",
    "y": " b. mabên (n)"
  },
  {
    "x": "muaye (m)",
    "y": " maye-I (m)"
  },
  {
    "x": "muayene (n)",
    "y": " kontrol, kontrol etme, muayene"
  },
  {
    "x": "muayene kerdene",
    "y": " kontrol etmek"
  },
  {
    "x": "muayene û analîz",
    "y": " muayene ve analiz"
  },
  {
    "x": "mubalaxa (m)",
    "y": " b. mubalexa (m)"
  },
  {
    "x": "mubalaxakerdiş (n)",
    "y": " b. mubalexakerdiş (n)"
  },
  {
    "x": "mubalexa (m)",
    "y": " abartı, mübalağa"
  },
  {
    "x": "mubalexa kerdene",
    "y": " abartmak, mübalağa etmek"
  },
  {
    "x": "mubalexaker, -e",
    "y": " abartıcı"
  },
  {
    "x": "mubalexakerdiş (n)",
    "y": " abartma, mübalağa etme"
  },
  {
    "x": "mubalexakerîye (m)",
    "y": " abartıcılık"
  },
  {
    "x": "mubalexe (m)",
    "y": " b. mubalexa (m)"
  },
  {
    "x": "mubalexekerdiş (n)",
    "y": " b. mubalexakerdiş (n)"
  },
  {
    "x": "mubar (n)",
    "y": " b. bumbar (n)"
  },
  {
    "x": "mubaşir, -e",
    "y": " mübaşir"
  },
  {
    "x": "mubaşirî (m)",
    "y": " mübaşirlik"
  },
  {
    "x": "mubaşirîye (m)",
    "y": " mübaşirlik"
  },
  {
    "x": "mubaşîr, -e",
    "y": " b. mubaşir, -e"
  },
  {
    "x": "mubaşîrî (m)",
    "y": " b. mubaşirî (m)"
  },
  {
    "x": "mubaşîrîye (m)",
    "y": " b. mubaşirîye (m)"
  },
  {
    "x": "mubtela, -ye",
    "y": " bağımlı"
  },
  {
    "x": "mubtela bîyene",
    "y": " bağımlılaşmak"
  },
  {
    "x": "mubtelabîyayîş (n)",
    "y": " bağımlılaşma"
  },
  {
    "x": "mubtelayîye (m)",
    "y": " bağımlılık"
  },
  {
    "x": "mucadele (n)",
    "y": " mücadele"
  },
  {
    "x": "mucadelekerdox, -e",
    "y": " mücadeleci"
  },
  {
    "x": "mucered, -e",
    "y": " abstre, mücerret, soyut"
  },
  {
    "x": "mucessem, -e",
    "y": " cisimlenmiş"
  },
  {
    "x": "mucewher (n)",
    "y": " mücevher, cevher, gevher"
  },
  {
    "x": "mucewherroş, -e",
    "y": " kuyumcu, mücevherci"
  },
  {
    "x": "mucewherroşî (m)",
    "y": " kuyumculuk, mücevhercilik"
  },
  {
    "x": "mucewherroşîye (m)",
    "y": " kuyumculuk, mücevhercilik"
  },
  {
    "x": "mucey (zh)",
    "y": " b. mujeyî (zh)"
  },
  {
    "x": "mucilîyayîş (n)",
    "y": " b. mijûlîyayîş (n)"
  },
  {
    "x": "muciltîne",
    "y": " b. mijûlîyayene"
  },
  {
    "x": "mucle (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "mucnayîş (n)",
    "y": " b. mojnayîş (n)"
  },
  {
    "x": "muçe (m)",
    "y": " b. maçî (m)"
  },
  {
    "x": "muçe kerdene",
    "y": " b. maçî kerdene"
  },
  {
    "x": "muçi (m)",
    "y": " b. maçî (m)"
  },
  {
    "x": "muçi kerdene",
    "y": " b. maçî kerdene"
  },
  {
    "x": "muçî (m)",
    "y": " b. maçî (m)"
  },
  {
    "x": "muçî kerdene",
    "y": " b. maçî kerdene"
  },
  {
    "x": "mudafa (m)",
    "y": " savunma"
  },
  {
    "x": "mudafa kerdene",
    "y": " savunmak"
  },
  {
    "x": "mudafaya tezî (m)",
    "y": " tez savunması"
  },
  {
    "x": "mudafî, -ye",
    "y": " müdafi"
  },
  {
    "x": "mudaxele (n)",
    "y": " müdahale"
  },
  {
    "x": "mudaxeleyo girewte (n)",
    "y": " kapalı müdahale (itfaiye vb. için)"
  },
  {
    "x": "mudaxeleyo lezkanî (n)",
    "y": " hızlı müdahale"
  },
  {
    "x": "mude (n)",
    "y": " b. mudet (n)"
  },
  {
    "x": "mudet (n)",
    "y": " müddet, süre"
  },
  {
    "x": "mudet tengkerdiş (n)",
    "y": " süre sıkıştırma"
  },
  {
    "x": "mudetê karkerdişî (n)",
    "y": " çalışma süresi"
  },
  {
    "x": "mudetê projeyî (n)",
    "y": " proje süresi"
  },
  {
    "x": "mudetê xebate (n)",
    "y": " çalışma süresi"
  },
  {
    "x": "mudeto mende (n)",
    "y": " kalan süre"
  },
  {
    "x": "mudexele (n)",
    "y": " b. mudaxele (n)"
  },
  {
    "x": "mudir, -e",
    "y": " müdür, yönetmen"
  },
  {
    "x": "mudira/ê beşî",
    "y": " şube müdürü"
  },
  {
    "x": "mudira/ê dîmenan (sn)",
    "y": " görüntü yönetmeni"
  },
  {
    "x": "mudira/ê goristanan",
    "y": " mezarlıklar müdürü"
  },
  {
    "x": "mudira/ê hunerî (sn)",
    "y": " sanat yönetmeni"
  },
  {
    "x": "mudira/ê îtfaîya",
    "y": " itfaiye müdürü"
  },
  {
    "x": "mudira/ê karanê awe",
    "y": " su işleri müdürü"
  },
  {
    "x": "mudira/ê karanê fenî",
    "y": " fen işleri müdürü"
  },
  {
    "x": "mudira/ê karanê pakîye",
    "y": " temizlik işleri müdürü"
  },
  {
    "x": "mudira/ê karê nuşteyan",
    "y": " yazı işleri müdürü"
  },
  {
    "x": "mudira pêroyîye (m)",
    "y": " genel müdür"
  },
  {
    "x": "mudira/ê senetî (sn)",
    "y": " sanat yönetmeni"
  },
  {
    "x": "mudira/ê xizmetanê baxçe û parkan",
    "y": " park ve bahçe hizmetleri müdürü"
  },
  {
    "x": "mudira/ê xizmetanê malîyan",
    "y": " mali hizmetler müdürü"
  },
  {
    "x": "mudiro pêroyî (n)",
    "y": " genel müdür"
  },
  {
    "x": "mudire (m)",
    "y": " müdire"
  },
  {
    "x": "mudiri (m)",
    "y": " b. mudire (m)"
  },
  {
    "x": "mudirî (m)",
    "y": " müdürlük, müdüriyet"
  },
  {
    "x": "mudirîye (m)",
    "y": " müdürlük, müdüriyet"
  },
  {
    "x": "mudirîya ameyeyan",
    "y": " gelirler müdürlüğü"
  },
  {
    "x": "mudirîya astengdaran",
    "y": " engelliler müdürlüğü"
  },
  {
    "x": "mudirîya awanî û şaristanawanîye",
    "y": " imar ve şehircilik müdürlüğü"
  },
  {
    "x": "mudirîya awe û kanalîzasyonî",
    "y": " su ve kanalizasyon müdürlüğü"
  },
  {
    "x": "mudirîya beşê arşîvî û karê nuşteyan",
    "y": " yazı işleri ve arşiv şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê astengdaran",
    "y": " engelli(ler) şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê awanî û şaristanawanîye",
    "y": " imar ve şehircilik şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê awguznayîşê awa xame",
    "y": " ham su arıtma şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê bordro û tehequqî",
    "y": " bordro ve tahakkuk şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê borsaya heywanan û selexaneyî",
    "y": " canlı hayvan borsası ve mezbaha şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê buroyê caardiş û kontrolî",
    "y": " uygulama ve denetim bürosu şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê butçe û fînansî",
    "y": " bütçe ve finans şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê çapemenîye û weşanî",
    "y": " basın yayın şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê çapemenîye-weşanî û şarî reyra têkilîye",
    "y": " basın yayın ve halkla ilişkiler şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê çimeyanê merdiman",
    "y": " insan kaynakları şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê destekê kiriştbaran/berdbaran û stokî",
    "y": " taşınır destek ve stok şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê dîzayn û vurîyayîşê şaristankî",
    "y": " kentsel tasarım ve dönüşüm şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê domanan/qican/tutan/gedeyan",
    "y": " çocuk şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê elektrîk û elektronîkî",
    "y": " elektrik ve elektronik şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê emlak û îstîmlakî",
    "y": " emlak ve kamulaştırma şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê eşteyanê huşkan",
    "y": " katı atık şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê hêrînayîşî",
    "y": " satın alma şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê hêrînayîşî û embaran",
    "y": " satın alma ve ambarlar şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê hesabdarîye",
    "y": " muhasebe şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê îdareyê projeyan",
    "y": " proje yönetim şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê îdareyê stratejîk û plankerdişî",
    "y": " stratejik yönetim ve planlama şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê îkmal ciewnîyayîş û tamîrê makînayan",
    "y": " makine ikmal bakım onarım şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê îkmalê makînayan",
    "y": " makine ikmal şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê karanê aboneyîye",
    "y": " abone işleri şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê karanê huqûqî",
    "y": " hukuk işleri şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê karanê hûmarkerî",
    "y": " sayaç işleri şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê karanê îdarî",
    "y": " idari işler şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê karanê perwerdeyî",
    "y": " eğitim işleri şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê karanê tehequqî",
    "y": " tahakkuk işleri şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê karanê weşîye",
    "y": " sağlık işleri şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê karê nuşte û biran",
    "y": " yazı işleri ve kararlar şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê keskgeh û leyegehan",
    "y": " yeşil alanlar ve fidanlıklar şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê kitabxaneyan",
    "y": " kütüphaneler şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê komresîye",
    "y": " toplu ulaşım şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê konservatuwarî",
    "y": " konservatuvar şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê kontrolê awanîyan",
    "y": " yapı kontrol şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê koordînosyonê binawanîye",
    "y": " altyapı koordinasyon şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê laboratuwaran",
    "y": " laboratuvarlar şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê mudaxeleyî yê îtfaîya",
    "y": " itfaiye müdahale şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê nuştegehê xususî",
    "y": " özel kalem şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê pakîya dorûverî",
    "y": " çevre temizliği şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê pawitiş/seveknayîş û asayîşî",
    "y": " koruma ve güvenlik şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê pawitiş/seveknayîş û kontrolê dorûverî",
    "y": " çevre koruma kontrol şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê pawitişê weşî û hewzaya awe",
    "y": " su sağlığı ve havza koruma şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê ruxsetî",
    "y": " ruhsat şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê sazgehê awguznayîşê gendawe",
    "y": " atık su arıtma tesisi şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê sazgehê awguznayîşê gendawe yê bîyolojîk yê averî",
    "y": " ileri biyolojik atık su arıtma tesisi şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê tehsîlatî",
    "y": " tahsilat şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê tîyatroyanê şaristanî û sînema",
    "y": " şehir tiyatroları ve sinema şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê trafîkî",
    "y": " trafik şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê vernîgirewtişê şewatî",
    "y": " yangın önleme şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê vernîgirewtişê vîndîbîyayîş û qaçaxîya awe",
    "y": " kayıp ve kaçak su önleme şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê verxebate-proje û viraştişî",
    "y": " etüt proje ve yapım şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê viraştiş û ciewnîyayîşî",
    "y": " yapım ve bakım şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê viraştişê kanalîzasyonî",
    "y": " kanalizasyon yapım şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê viraştişî yê awa şimitişî",
    "y": " içme suyu yapım şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê xebitnayîş, ciewnîyayîş/nîyadayîş û tamîrê kanalîzasyonî",
    "y": " kanalizasyon işletme, bakım ve onarım şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê xebitnayîş, ciewnîyayîş/nîyadayîş û tamîrî yê awa şimitişî",
    "y": " içme suyu işletme, bakım ve onarım şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê xebitnayîşê otobusan",
    "y": " otobüs işletme şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê xizmetanê teknîkîyan",
    "y": " teknik hizmetler şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê xurtkerdişê çimeyan û pardarîyan",
    "y": " kaynak geliştirme ve iştirakler şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê xurtkerdişê stratejî",
    "y": " strateji geliştirme şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê zabita",
    "y": " zabıta şube müdürlüğü"
  },
  {
    "x": "mudirîya beşê zanakirarî",
    "y": " bilgiişlem şube müdürlüğü"
  },
  {
    "x": "mudirîya cigêrayîş û xelisnayîşî",
    "y": " arama kurtarma müdürlüğü"
  },
  {
    "x": "mudirîya cinî û keyeyan",
    "y": " kadın ve aile müdürlüğü"
  },
  {
    "x": "mudirîya çapemenîye-weşanî û şarî reyra têkilîye",
    "y": " basın yayın ve halkla ilişkiler müdürlüğü"
  },
  {
    "x": "mudirîya çimeyanê merdiman û perwerdeyî",
    "y": " insan kaynakları ve eğitim müdürlüğü"
  },
  {
    "x": "mudirîya çimeyanê merdiman yê projeyî",
    "y": " proje insan kaynakları müdürlüğü"
  },
  {
    "x": "mudirîya emlakî",
    "y": " emlak müdürlüğü"
  },
  {
    "x": "mudirîya eşteyanê huşkan",
    "y": " katı atık müdürlüğü"
  },
  {
    "x": "mudirîya goristanan",
    "y": " mezarlıklar müdürlüğü"
  },
  {
    "x": "mudirîya halan",
    "y": " haller müdürlüğü"
  },
  {
    "x": "mudirîya herême ya weqifan (m)",
    "y": " vakıflar bölge müdürlüğü"
  },
  {
    "x": "mudirîya îdareyê zanakirarî û zanayîşî",
    "y": " bilgiişlem ve bilgi yönetimi müdürlüğü"
  },
  {
    "x": "mudirîya îkmalê makînayan",
    "y": " makine ikmal müdürlüğü"
  },
  {
    "x": "mudirîya îtfaîya",
    "y": " itfaiye müdürlüğü"
  },
  {
    "x": "mudirîya karanê fenî",
    "y": " fen işleri müdürlüğü"
  },
  {
    "x": "mudirîya karanê kulturî û sosyalan",
    "y": " kültür ve sosyal işler müdürlüğü"
  },
  {
    "x": "mudirîya karanê malîyan",
    "y": " mali işler müdürlüğü"
  },
  {
    "x": "mudirîya karanê veterînerî û kontrolê xidayan",
    "y": " veteriner işleri ve gıda kontrol müdürlüğü"
  },
  {
    "x": "mudirîya karanê xizmetê sosyalî û hetkarîye",
    "y": " sosyal hizmet ve yardım işleri müdürlüğü"
  },
  {
    "x": "mudirîya karê nuşteyan (m)",
    "y": " yazı işleri müdürlüğü"
  },
  {
    "x": "mudirîya kirarîyanê xizmethêrînayîşî",
    "y": " hizmet alımı işlemleri müdürlüğü"
  },
  {
    "x": "mudirîya komresîye",
    "y": " toplu ulaşım müdürlüğü"
  },
  {
    "x": "mudirîya kontrolê malîyî",
    "y": " mali kontrol müdürlüğü"
  },
  {
    "x": "mudirîya koordînasyonê resî",
    "y": " ulaşım koordinasyon müdürlüğü"
  },
  {
    "x": "mudirîya kultur û turîzmî",
    "y": " kültür ve turizm müdürlüğü"
  },
  {
    "x": "mudirîya meclîs û encumenî",
    "y": " meclis ve encümen müdürlüğü"
  },
  {
    "x": "mudirîya mintiqa ya weqifan (m)",
    "y": " vakıflar bölge müdürlüğü"
  },
  {
    "x": "mudirîya nuştegehê xususî (m)",
    "y": " özel kalem müdürlüğü"
  },
  {
    "x": "mudirîya otogar û otoparkan",
    "y": " otogar ve otoparklar müdürlüğü"
  },
  {
    "x": "mudirîya pêroyîye (m)",
    "y": " genel müdürlük"
  },
  {
    "x": "mudirîya pêroyîye ya îdareyê awe û kanalîzasyonî",
    "y": " su ve kanalizasyon idaresi genel müdürlüğü"
  },
  {
    "x": "mudirîya pêroyîye ya karanê awe û kanalîzasyonî",
    "y": " su ve kanalizasyon işleri genel müdürlüğü"
  },
  {
    "x": "mudirîya pêroyîye ya weqifan (m)",
    "y": " vakıflar genel müdürlüğü"
  },
  {
    "x": "mudirîya sazgehan",
    "y": " işletme müdürlüğü"
  },
  {
    "x": "mudirîya sazgehî",
    "y": " işletme müdürlüğü"
  },
  {
    "x": "mudirîya seqetan",
    "y": " engelliler müdürlüğü"
  },
  {
    "x": "mudirîya şarî reyra têkilîye",
    "y": " halkla ilişkiler müdürlüğü"
  },
  {
    "x": "mudirîya şûbeyê seqetan",
    "y": " engelli(ler) şube müdürlüğü"
  },
  {
    "x": "mudirîya xebitnayîşî",
    "y": " işletme müdürlüğü"
  },
  {
    "x": "mudirîya xizmetanê baxçe û parkan",
    "y": " park ve bahçe hizmetleri müdürlüğü"
  },
  {
    "x": "mudirîya xizmetanê domanan/gedeyan/qican",
    "y": " çocuk hizmetleri müdürlüğü"
  },
  {
    "x": "mudirîya xizmetanê malîyan",
    "y": " mali hizmetler müdürlüğü"
  },
  {
    "x": "mudirîya zanakirarî",
    "y": " bilgiişlem müdürlüğü"
  },
  {
    "x": "mudirîya zanayîşanê cografyayî û xerîta",
    "y": " harita ve coğrafi bilgiler müdürlüğü"
  },
  {
    "x": "mudirîya zabita",
    "y": " zabıta müdürlüğü"
  },
  {
    "x": "mudirîyet (n)",
    "y": " müdürlük, müdüriyet"
  },
  {
    "x": "mudî, -ye",
    "y": " b. mûdî, -ye"
  },
  {
    "x": "mudîyey (m)",
    "y": " mûdîyîye (m), mûdîyî (m)"
  },
  {
    "x": "mudîyey kerdene",
    "y": " b. mûdîyî kerdene"
  },
  {
    "x": "mudîyê (m)",
    "y": " mûdîyîye (m), mûdîyî (m)"
  },
  {
    "x": "mudîyê kerdene",
    "y": " b. mûdîyî kerdene"
  },
  {
    "x": "mudur, -e",
    "y": " b. mudir, -e"
  },
  {
    "x": "mudure (m)",
    "y": " b. mudire (m)"
  },
  {
    "x": "muebed, -e",
    "y": " müebbet, sonrasız"
  },
  {
    "x": "muebedey (m)",
    "y": " b. muebedîye (m)"
  },
  {
    "x": "muebedê (m)",
    "y": " b. muebedî (m)"
  },
  {
    "x": "muebedî (m)",
    "y": " sonrasızlık, sonsuzluk"
  },
  {
    "x": "muebedîye (m)",
    "y": " sonrasızlık, sonsuzluk"
  },
  {
    "x": "muecnayene",
    "y": " b. mojnayene"
  },
  {
    "x": "muecnayîş (n)",
    "y": " b. mojnayîş (n)"
  },
  {
    "x": "muejla (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "muejle (n)",
    "y": " b. morcele (n)"
  },
  {
    "x": "muejli (n)",
    "y": " b. morcele (n)"
  },
  {
    "x": "muejnayene",
    "y": " b. mojnayene"
  },
  {
    "x": "muejnayîş (n)",
    "y": " b. mojnayîş (n)"
  },
  {
    "x": "muene (n)",
    "y": " b. muayene (n)"
  },
  {
    "x": "muer (n)",
    "y": " b. mor (n)"
  },
  {
    "x": "muer kerdene",
    "y": " b. mor kerdene"
  },
  {
    "x": "muercela (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "muerceli (n)",
    "y": " b. morcele (n)"
  },
  {
    "x": "muerin (n)",
    "y": " b. morin, -e"
  },
  {
    "x": "muerkerde (n)",
    "y": " b. morkerde (n)"
  },
  {
    "x": "muerkerdî (m)",
    "y": " b. morkerdî (m), morkerdîye (m)"
  },
  {
    "x": "muesla (m)",
    "y": " b. mosela (m)"
  },
  {
    "x": "muessese (n)",
    "y": " kurum, müessese"
  },
  {
    "x": "muesseseyo mabênkar (n)",
    "y": " aracı kurum"
  },
  {
    "x": "muestik (n)",
    "y": " b. mustik (n)"
  },
  {
    "x": "muetiş (n)",
    "y": " b. mojnayîş (n)"
  },
  {
    "x": "muex (n)",
    "y": " b. muwex (n)"
  },
  {
    "x": "muexil (m)",
    "y": " b. moxle (m)"
  },
  {
    "x": "mueyene (n)",
    "y": " b. muayene (n)"
  },
  {
    "x": "muez (m)",
    "y": " b. moze (m)"
  },
  {
    "x": "muezê rîyex (m)",
    "y": " b. moza rêxe (m)"
  },
  {
    "x": "muezig (m)",
    "y": " b. mozike-II (m)"
  },
  {
    "x": "muezik-I (n)",
    "y": " b. mozik-I (n)"
  },
  {
    "x": "muezik-II (m)",
    "y": " b. mozike-II (m)"
  },
  {
    "x": "muezîn (n)",
    "y": " müezzin"
  },
  {
    "x": "muezînî (m)",
    "y": " müezzinlik"
  },
  {
    "x": "muezînîye (m)",
    "y": " müezzinlik"
  },
  {
    "x": "muezzîn (n)",
    "y": " b. muezîn (n)"
  },
  {
    "x": "muezzîney (m)",
    "y": " b. muezînî (m)"
  },
  {
    "x": "mufesîr, -e",
    "y": " yorumcu"
  },
  {
    "x": "mufesîrênî (m)",
    "y": " yorumculuk"
  },
  {
    "x": "mufesîrî (m)",
    "y": " yorumculuk"
  },
  {
    "x": "mufesîrîye (m)",
    "y": " yorumculuk"
  },
  {
    "x": "mufessîr, -e",
    "y": " b. mufesîr, -e"
  },
  {
    "x": "mufessîrey (m)",
    "y": " b. mufesîrî (m)"
  },
  {
    "x": "mufessîrê (m)",
    "y": " b. mufesîrî (m)"
  },
  {
    "x": "mufetîş, -e",
    "y": " denetmen, müfettiş"
  },
  {
    "x": "mufetîşa/ê bacî",
    "y": " vergi denetmeni"
  },
  {
    "x": "mufsîd",
    "y": " müfsit"
  },
  {
    "x": "muhacîr",
    "y": " b. macir, -e"
  },
  {
    "x": "muhacirîye (m)",
    "y": " b. macirîye (m)"
  },
  {
    "x": "muhafeze (n)",
    "y": " 1)koruma, muhafaza\r\n~2)gözetim"
  },
  {
    "x": "muhafeze bîyene",
    "y": " korunmak, muhafaza edilmek"
  },
  {
    "x": "muhafeze kerdene",
    "y": " korumak, muhafaza etmek"
  },
  {
    "x": "muhafezebîyayîş (n)",
    "y": " korunma"
  },
  {
    "x": "muhafezebîyayîşê herre (n)",
    "y": " toprak korunması"
  },
  {
    "x": "muhafezekar, -e",
    "y": " muhafazakâr"
  },
  {
    "x": "muhafezekarîye (m)",
    "y": " muhafazakârlık"
  },
  {
    "x": "muhafezekerdiş (n)",
    "y": " koruma, muhafaza, muhafaza etme"
  },
  {
    "x": "muhafiz, -e",
    "y": " koruyucu, muhafız"
  },
  {
    "x": "muhakeme kerdene",
    "y": " yargılamak"
  },
  {
    "x": "muhakemekerdiş (n)",
    "y": " yargılama"
  },
  {
    "x": "muhalebî (m)",
    "y": " muhallebi"
  },
  {
    "x": "muhaqîq, -e",
    "y": " b. muheqîq, -e"
  },
  {
    "x": "muhasebe (n)",
    "y": " muhasebe, saymanlık"
  },
  {
    "x": "muhasera (m)",
    "y": " b. muhasere (n)"
  },
  {
    "x": "muhasere (n)",
    "y": " abluka, kuşatma, muhasara"
  },
  {
    "x": "muhasere bîyene",
    "y": " abluka altında olmak, kuşatılmak, çevrelenmek"
  },
  {
    "x": "muhasere de mendene",
    "y": " abluka altında kalmak"
  },
  {
    "x": "muhasere domnayene",
    "y": " abluka altında tutmak, kuşatmayı sürdürmek"
  },
  {
    "x": "muhasere hewa dayene",
    "y": " ablukayı kaldırmak"
  },
  {
    "x": "muhasere hewa nayene",
    "y": " ablukayı kaldırmak"
  },
  {
    "x": "muhasere kerdene",
    "y": " abluka etmek, ablukaya almak, kuşatmak, muhasara altına almak, muhasara etmek, çevrelemek, ihata etmek"
  },
  {
    "x": "muhasere şeqnayene",
    "y": " ablukayı yarmak"
  },
  {
    "x": "muhasere ver kerdene",
    "y": " ablukayı yarmak"
  },
  {
    "x": "muhasere wedaritene",
    "y": " ablukayı kaldırmak"
  },
  {
    "x": "muhaserebîyayîş (n)",
    "y": " kuşatılma, çevrelenme, çevreleniş"
  },
  {
    "x": "muhaserekerdiş (n)",
    "y": " abluka, abluka etme, kuşatma, çevreleme"
  },
  {
    "x": "muhendis, -e",
    "y": " mühendis"
  },
  {
    "x": "muhendisa/muhendisê cugehî",
    "y": " çevre mühendisi"
  },
  {
    "x": "muhendisa/muhendisê daristanî",
    "y": " orman mühendisi"
  },
  {
    "x": "muhendisa/muhendisê elektrîk-elektronîkî",
    "y": " elektrik elektronik mühendisi"
  },
  {
    "x": "muhendisa/muhendisê elektrîkî",
    "y": " elektrik mühendisi"
  },
  {
    "x": "muhendisa/muhendisê feza",
    "y": " uzay mühendisi"
  },
  {
    "x": "muhendisa/muhendisê genetîkî",
    "y": " genetik mühendisi"
  },
  {
    "x": "muhendisa/muhendisê jeofîzîkî",
    "y": " jeofizik mühendisi"
  },
  {
    "x": "muhendisa/muhendisê karnasî",
    "y": " işletme mühendisi"
  },
  {
    "x": "muhendisa/muhendisê kîmya",
    "y": " kimya mühendisi"
  },
  {
    "x": "muhendisa/muhendisê komputure",
    "y": " bilgisayar mühendisi"
  },
  {
    "x": "muhendisa/muhendisê makîna",
    "y": " makine mühendisi"
  },
  {
    "x": "muhendisa/muhendisê xerîta",
    "y": " harita mühendisi"
  },
  {
    "x": "muhendisa/muhendisê xidayan",
    "y": " gıda mühendisi"
  },
  {
    "x": "muhendisî (m)",
    "y": " mühendislik"
  },
  {
    "x": "muhendisîya cugehî (m)",
    "y": " çevre mühendisliği"
  },
  {
    "x": "muhendisîya elektrîkî",
    "y": " elektrik mühendisliği"
  },
  {
    "x": "muhendisîya komputure",
    "y": " bilgisayar mühendisliği"
  },
  {
    "x": "muhendisîye (m)",
    "y": " mühendislik"
  },
  {
    "x": "muhendîs, -e",
    "y": " b. muhendis, -e"
  },
  {
    "x": "muhendîsê (m)",
    "y": " b. muhendisî (m), muhendisîye (m)"
  },
  {
    "x": "muhendîsî (m)",
    "y": " b. muhendisî (m), muhendisîye (m)"
  },
  {
    "x": "muhendîsîya elektrîkî",
    "y": " b. muhendisîya elektrîkî"
  },
  {
    "x": "muhendîsîye (m)",
    "y": " b. muhendisî (m), muhendisîye (m)"
  },
  {
    "x": "muheqîq, -e",
    "y": " soruşturmacı, muhakkik"
  },
  {
    "x": "muher (m)",
    "y": " b. mor (n)"
  },
  {
    "x": "muher kerdene",
    "y": " b. mor kerdene"
  },
  {
    "x": "muherin (n)",
    "y": " b. morin, -e"
  },
  {
    "x": "muherkerda (m)",
    "y": " b. morkerda (m)"
  },
  {
    "x": "muherkerde (n)",
    "y": " b. morkerde (n)"
  },
  {
    "x": "muherkerdî (m)",
    "y": " b. morkerdî (m), morkerdîye (m) "
  },
  {
    "x": "muhir (n)",
    "y": " b. mor (n)"
  },
  {
    "x": "muhir bîyayene",
    "y": " b. mor bîyene"
  },
  {
    "x": "muhir kerdene",
    "y": " b. mor kerdene"
  },
  {
    "x": "muhir wenayene",
    "y": " b. mor panayene"
  },
  {
    "x": "muhir wenîyayene",
    "y": " b. mor panîyayene"
  },
  {
    "x": "muhirin (n)",
    "y": " b. morin, -e"
  },
  {
    "x": "muhirkerda (m)",
    "y": " b. morkerda (m)"
  },
  {
    "x": "muhirkerde (n)",
    "y": " b. morkerde (n)"
  },
  {
    "x": "muhirkerdî (m)",
    "y": " b. morkerdî (m), morkerdîye (m)"
  },
  {
    "x": "muhîm, -e",
    "y": " önemli, mühim; belli başlı"
  },
  {
    "x": "muhîmî (m)",
    "y": " önem"
  },
  {
    "x": "muhîmîya cigêrayîşî (m)",
    "y": " araştırmanın önemi"
  },
  {
    "x": "muhîmîye (m)",
    "y": " önem"
  },
  {
    "x": "muhîmîya cigêrayîşî (m)",
    "y": " araştırmanın önemi"
  },
  {
    "x": "muhkem, -e",
    "y": " sağlam"
  },
  {
    "x": "muhkem bîyene",
    "y": " sağlam olmak, sağlamlaşmak"
  },
  {
    "x": "muhkem kerdene",
    "y": " sağlamlaştırmak"
  },
  {
    "x": "muhkembîyayîş (n)",
    "y": " sağlam olma, sağlamlaşma"
  },
  {
    "x": "muhkemey (m)",
    "y": " b. muhkemî (m), muhkemîye (m)"
  },
  {
    "x": "muhkemî (m)",
    "y": " sağlamlık"
  },
  {
    "x": "muhkemîye (m)",
    "y": " sağlamlık"
  },
  {
    "x": "muhkemkerdiş (n)",
    "y": " sağlamlaştırma"
  },
  {
    "x": "muhlet (n)",
    "y": " mühlet, vade"
  },
  {
    "x": "muhlet dayene",
    "y": " mühlet vermek"
  },
  {
    "x": "muhletdayîş (n)",
    "y": " mühlet verme"
  },
  {
    "x": "muhtac, -e",
    "y": " muhtaç"
  },
  {
    "x": "muhterem, -e",
    "y": " saygıdeğer, muhterem, sayın"
  },
  {
    "x": "muhtewa (m)",
    "y": " içerik, muhteva"
  },
  {
    "x": "muira (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "muj (n)",
    "y": " b. mij (n)"
  },
  {
    "x": "mujeyî (zh)",
    "y": " kirpik (kirpikler)"
  },
  {
    "x": "mujilîyayene",
    "y": " b. mijûlîyayene"
  },
  {
    "x": "mujilîyayîş (n)",
    "y": " b. mijûlîyayîş (n)"
  },
  {
    "x": "mojlek (n)",
    "y": " b. mijlorek (n)"
  },
  {
    "x": "mojlok (n)",
    "y": " b. mijlorek (n)"
  },
  {
    "x": "mujulîyayene",
    "y": " b. mijûlîyayene"
  },
  {
    "x": "mujulîyayîş (n)",
    "y": " b. mijûlîyayîş (n)"
  },
  {
    "x": "mukafat (n)",
    "y": " ödül, mükâfat, armağan"
  },
  {
    "x": "mukelef, -e",
    "y": " yükümlü, mükellef"
  },
  {
    "x": "mukelefî (m)",
    "y": " yükümlülük, mükellefiyet, mükelleflik"
  },
  {
    "x": "mukelefîye (m)",
    "y": " yükümlülük, mükellefiyet, mükelleflik"
  },
  {
    "x": "mukelefîyet (n)",
    "y": " yükümlülük, mükellefiyet, mükelleflik"
  },
  {
    "x": "mulaqat (n)",
    "y": " görüşme, mülakat"
  },
  {
    "x": "mulaqato fokusî (n)",
    "y": " odak görüşme"
  },
  {
    "x": "mulazim, -e",
    "y": " teğmen "
  },
  {
    "x": "mulazimênî (m)",
    "y": " teğmenlik"
  },
  {
    "x": "mulazimî (m)",
    "y": " teğmenlik"
  },
  {
    "x": "mulazimîye (m)",
    "y": " teğmenlik"
  },
  {
    "x": "mulçîya fekî (m)",
    "y": " b. milçîya fekî (m)"
  },
  {
    "x": "muleçike-I (m)",
    "y": " b. miloçike-I (m)"
  },
  {
    "x": "muleçike-II (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "muleçîke (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "mulet (n)",
    "y": " b. muhlet (n)"
  },
  {
    "x": "mulet dayene",
    "y": " b. muhlet dayene"
  },
  {
    "x": "muletdayîş (n)",
    "y": " b. muhletdayîş (n)"
  },
  {
    "x": "muloçike (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "muloçika kutikan (m)",
    "y": " b. morcelaya kutikan (m)"
  },
  {
    "x": "muloçikê kutikan (n)",
    "y": " b. morceleyê kutikan (n)"
  },
  {
    "x": "multecî, -ye",
    "y": " sığınık, mülteci"
  },
  {
    "x": "mum (m)",
    "y": " b. mume (m)"
  },
  {
    "x": "mumdane (m)",
    "y": " mumluk, şamdan, şamdanlık; çıralık (mum yakılan yer)"
  },
  {
    "x": "mume (m)",
    "y": " mum"
  },
  {
    "x": "mumi (m)",
    "y": " b. mume (m)"
  },
  {
    "x": "mumîn, -e",
    "y": " mümin"
  },
  {
    "x": "mumyaker, -e",
    "y": " mumyacı"
  },
  {
    "x": "mumyakerî (m)",
    "y": " mumyacılık"
  },
  {
    "x": "mumyakerîye (m)",
    "y": " mumyacılık"
  },
  {
    "x": "munaqeşe (m)",
    "y": " tartışma, münakaşa"
  },
  {
    "x": "mun (n)",
    "y": " mîyan (n)"
  },
  {
    "x": "munaqeşe (m)",
    "y": " tartışma"
  },
  {
    "x": "munaqeşe kerdene",
    "y": " atışmak, tartışmak"
  },
  {
    "x": "munaqeşekerdiş",
    "y": " tartışma"
  },
  {
    "x": "munasib, -e",
    "y": " münasip, uygun, yaraşır, yerinde"
  },
  {
    "x": "munasib bîyayene",
    "y": " b. munasib bîyene"
  },
  {
    "x": "munasib bîyene",
    "y": " uymak, uygun gelmek, uygun olmak, yakışmak, yaraşmak"
  },
  {
    "x": "munasib dîyene",
    "y": " uygun görmek"
  },
  {
    "x": "munasibbîyayîş (n)",
    "y": " uygun olma, uyma"
  },
  {
    "x": "munasibey (m)",
    "y": " b. munasibî (m), munasibîye (m)"
  },
  {
    "x": "munasibê (m)",
    "y": " b. munasibî (m), munasibîye (m)"
  },
  {
    "x": "munasibî (m)",
    "y": " uygunluk "
  },
  {
    "x": "munasibîye (m)",
    "y": " uygunluk"
  },
  {
    "x": "munasiv, -e",
    "y": " b. munasib, -e"
  },
  {
    "x": "munaşeqi (m)",
    "y": " b. munaqeşe (m)"
  },
  {
    "x": "mund (m)",
    "y": " mûndî (m)"
  },
  {
    "x": "munda Hezret Fatma (m)",
    "y": " b. mûndîya Hezretî Fatma (m)"
  },
  {
    "x": "mundey Fatma-Eyşûn (m)",
    "y": " b. mûndîya Fatma-Eyşane (m)"
  },
  {
    "x": "munde(m)",
    "y": " mûndî (m)"
  },
  {
    "x": "mundene",
    "y": " b. mûnitene"
  },
  {
    "x": "mundi (m)",
    "y": " b. mûndî (m)"
  },
  {
    "x": "mundiş (n)",
    "y": " b. mûnitiş (n)"
  },
  {
    "x": "mundî (m)",
    "y": " b. mûndî (m)"
  },
  {
    "x": "mundîya Lawuri (m)",
    "y": " b. mûndîya Lawure (m)"
  },
  {
    "x": "mundîka mişanî (m)",
    "y": " b. mûndika muşeneyî (m)"
  },
  {
    "x": "muneccîm, -e",
    "y": " b. munecîm, -e"
  },
  {
    "x": "munecîm, -e",
    "y": " astrolog, müneccim"
  },
  {
    "x": "munitene",
    "y": " b. mûnitene"
  },
  {
    "x": "munitiş (n)",
    "y": " b. mûnitiş (n)"
  },
  {
    "x": "munkir, -e",
    "y": " dinsel inançları inkar eden, münkir"
  },
  {
    "x": "munkîr, -e",
    "y": " b. munkir, -e"
  },
  {
    "x": "munkur, -e",
    "y": " b. munkir, -e"
  },
  {
    "x": "Munzur (n)",
    "y": " b. Mizur (n)"
  },
  {
    "x": "muptela, -ye",
    "y": " b. mubtela, -ye"
  },
  {
    "x": "muptela bîyayene",
    "y": " b. mubtela bîyene"
  },
  {
    "x": "muptelabîyayîş (n)",
    "y": " b. mubtelabîyayîş (n)"
  },
  {
    "x": "muqabil (n)",
    "y": " karşı"
  },
  {
    "x": "muqabilê ci",
    "y": " bilmukabele"
  },
  {
    "x": "muqaddes, -e",
    "y": " b. muqeddes, -e"
  },
  {
    "x": "muqara (m)",
    "y": " b. meqara (m)"
  },
  {
    "x": "muqawele (n)",
    "y": " sözleşme, kontrat, akit, mukavele,"
  },
  {
    "x": "muqaweleyê komkarî (n)",
    "y": " toplu iş sözleşmesi"
  },
  {
    "x": "muqaweleyê wekaletî (n)",
    "y": " vekâlet sözleşmesi"
  },
  {
    "x": "muqaweleyê wekîlî (n)",
    "y": " vekâlet sözleşmesi"
  },
  {
    "x": "muqaweleyê xizmetî (n)",
    "y": " hizmet sözleşmesi, hizmet mukavelesi"
  },
  {
    "x": "muqawelename (n)",
    "y": " kontrat, mukavele, mukavelename"
  },
  {
    "x": "muqawelenameyê xizmetî (n)",
    "y": " hizmet sözleşmesi, hizmet mukavelesi"
  },
  {
    "x": "muqayte bîyene",
    "y": " b. miqate bîyene"
  },
  {
    "x": "muqayte kerdene",
    "y": " b. miqat kerdene"
  },
  {
    "x": "muqaytey ... bîyene",
    "y": " b. miqatê ... bîyene"
  },
  {
    "x": "muqayteyê ... bîyene",
    "y": " b. miqatê ... bîyene"
  },
  {
    "x": "muqaytekerdiş (n)",
    "y": " b. miqatkerdiş (n)"
  },
  {
    "x": "muqayyed bîyene",
    "y": " b. miqate bîyene"
  },
  {
    "x": "muqayyed kerdene",
    "y": " b. miqat kerdene"
  },
  {
    "x": "muqayyedê ... bîyene",
    "y": " b. miqatê ... bîyene"
  },
  {
    "x": "muqayyedkerdiş (n)",
    "y": " b. miqatkerdiş (n)"
  },
  {
    "x": "muqeddes, -e",
    "y": " kutsal, mukaddes"
  },
  {
    "x": "mura-I (m)",
    "y": " boncuk"
  },
  {
    "x": "mura mênî (m)",
    "y": " b. muraya mîyanî (m)"
  },
  {
    "x": "mura wirdî (n)",
    "y": " b. muraya hurdî/hurdîye (m)"
  },
  {
    "x": "muray mûnî (m)",
    "y": " b. muraya mîyanî (m)"
  },
  {
    "x": "muray nefesî (m)",
    "y": " b. muraya nefesî (m)"
  },
  {
    "x": "muraya darêne (m)",
    "y": " tahta boncuk"
  },
  {
    "x": "muraya hurdî/hurdîye (m)",
    "y": " küçük boncuk"
  },
  {
    "x": "muraya kemerêne (m)",
    "y": " taş boncuk"
  },
  {
    "x": "muraya kerrayêne (m)",
    "y": " taş boncuk"
  },
  {
    "x": "muraya krîstale (m)",
    "y": " kristal boncuk"
  },
  {
    "x": "muraya mîyanî (m)",
    "y": " omurga"
  },
  {
    "x": "muraya nefesî (m)",
    "y": " nazar boncuğu, nazarlık"
  },
  {
    "x": "muraya nezerî (m)",
    "y": " nazar boncuğu, nazarlık"
  },
  {
    "x": "murê mîyonî (m)",
    "y": " b. muraya mîyanî (m)"
  },
  {
    "x": "murê mîyûnî (m)",
    "y": " b. muraya mîyanî (m)"
  },
  {
    "x": "murê nazêr (m)",
    "y": " b. muraya nezerî (m)"
  },
  {
    "x": "mura-II (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "muracat (n)",
    "y": " başvuru, müracaat"
  },
  {
    "x": "muracat kerdene",
    "y": " başvurmak"
  },
  {
    "x": "muracato ferdî (n)",
    "y": " bireysel başvuru, ferdi müracaat"
  },
  {
    "x": "muracato nuştekî (n)",
    "y": " yazılı başvuru"
  },
  {
    "x": "muracato yewî (n)",
    "y": " bireysel başvuru, ferdi müracaat"
  },
  {
    "x": "muracatker, -e",
    "y": " başvurucu, müracaatçı"
  },
  {
    "x": "muracatkerdiş (n)",
    "y": " başvurma"
  },
  {
    "x": "murad (n)",
    "y": " b. mirad (n)"
  },
  {
    "x": "Murad (n)",
    "y": " Murat"
  },
  {
    "x": "murc (n)",
    "y": " b. morc (n)"
  },
  {
    "x": "murdar, -e",
    "y": " murdar, mundar, kesilmeden ölmüş hayvan"
  },
  {
    "x": "murdar bîyene",
    "y": " murdar olmak, mundar olmak"
  },
  {
    "x": "murdar kerdene",
    "y": " murdar etmek, mundar etmek"
  },
  {
    "x": "murdose (m)",
    "y": " b. herdose (m)"
  },
  {
    "x": "murdum (n)",
    "y": " b. merdim (n)"
  },
  {
    "x": "murebîya (m)",
    "y": " murebîye (m)"
  },
  {
    "x": "murebîye (m)",
    "y": " mürebbiye"
  },
  {
    "x": "murebîyeyî (m)",
    "y": " mürebbiyelik"
  },
  {
    "x": "murebîyeyîye (m)",
    "y": " mürebbiyelik"
  },
  {
    "x": "murekeb (n)",
    "y": " mürekkep"
  },
  {
    "x": "mureki (m)",
    "y": " b. mura-I (m)"
  },
  {
    "x": "muretebat (n)",
    "y": " mürettebat (gemi ve uçak için)"
  },
  {
    "x": "muriz (n)",
    "y": " b. mirûz (n)"
  },
  {
    "x": "murî (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "murîye (m)",
    "y": " armut "
  },
  {
    "x": "murîya koyî (m)",
    "y": " ahlat"
  },
  {
    "x": "murîya qoqe (m)",
    "y": " ahlat"
  },
  {
    "x": "murîyêre (m)",
    "y": " armut ağacı"
  },
  {
    "x": "muroy (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "murr (n)",
    "y": " asık surat, abus, çatık yüz, çatık surat, çatık çehre"
  },
  {
    "x": "murrin (n)",
    "y": " 1)b. mirûzin (n)\r\n~2)b. mirûzîyaye (n)"
  },
  {
    "x": "murrine (m)",
    "y": " 1)b. mirûzine (m)\r\n~2)b. mirûzîyaya (m), b. mirûzîyayîye (m)"
  },
  {
    "x": "murtecî, -ye",
    "y": " gerici"
  },
  {
    "x": "murû (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "murvêri (m)",
    "y": " b. murîyêre (m)"
  },
  {
    "x": "murvi (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "murwêri (m)",
    "y": " b. murîyêre (m)"
  },
  {
    "x": "murx (n)",
    "y": " b. mirux (n)"
  },
  {
    "x": "murxin, -e",
    "y": " b. miruxin, -e"
  },
  {
    "x": "murxub (n)",
    "y": " b. mexreb (n)"
  },
  {
    "x": "musabeqa (m)",
    "y": " yarışma, müsabaka"
  },
  {
    "x": "musaîd, -e",
    "y": " elverişli, müsait, uygun, yaraşır"
  },
  {
    "x": "musaîd bîyayene",
    "y": " b. musaîd bîyene"
  },
  {
    "x": "musaîd bîyene",
    "y": " elverişli olmak, uygun düşmek, uygun gelmek, uygun olmak"
  },
  {
    "x": "musaîd dîyene",
    "y": " elverişli bulmak, uygun görmek"
  },
  {
    "x": "musaîdbîyayîş (n)",
    "y": " elverişli olma, uygun olma"
  },
  {
    "x": "musaîdî (m)",
    "y": " uygunluk"
  },
  {
    "x": "musaîdîye (m)",
    "y": " uygunluk"
  },
  {
    "x": "musaît, -e",
    "y": " b. musaîd, -e"
  },
  {
    "x": "musaît bîyayene",
    "y": " b. musaîd bîyene"
  },
  {
    "x": "musaît dîyene",
    "y": " b. musaîd dîyene"
  },
  {
    "x": "musaîtbîyayîş (n)",
    "y": " b. musaîdbîyayîş (n)"
  },
  {
    "x": "musaîtey (m)",
    "y": " b. musaîdîye (m)"
  },
  {
    "x": "musaîtê (m)",
    "y": " b. musaîdî (m)"
  },
  {
    "x": "musaya (m)",
    "y": " alışkan, alışkın"
  },
  {
    "x": "musaye (n)",
    "y": " alışkan, alışkın"
  },
  {
    "x": "musayene",
    "y": " 1)öğrenmek, bellemek\r\n~2)alışmak"
  },
  {
    "x": "musayeyîye (m)",
    "y": " alışkanlık, alışkınlık"
  },
  {
    "x": "musayî, -ye",
    "y": " alışkan, alışkın"
  },
  {
    "x": "musayîne",
    "y": " b. musayene"
  },
  {
    "x": "musayîş (n)",
    "y": " 1)öğrenme, belleme\r\n~2)alışma"
  },
  {
    "x": "musene (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "musibeti (m)",
    "y": " b. musîbete (m)"
  },
  {
    "x": "musilman, -e",
    "y": " b. musluman, -e"
  },
  {
    "x": "musîbete (m)",
    "y": " felaket, yıkım"
  },
  {
    "x": "musîbeti (m)",
    "y": " b. musîbete (m)"
  },
  {
    "x": "musliman, -e",
    "y": " b. musluman, -e"
  },
  {
    "x": "muslimaney",
    "y": " b. muslumanîye (m), muslumanî (m)"
  },
  {
    "x": "muslix (n)",
    "y": " b. musluxe (m)"
  },
  {
    "x": "musluman, -e",
    "y": " Müslüman, İslam"
  },
  {
    "x": "muslumaney (m)",
    "y": " b. muslumanîye (m), muslumanî (m)"
  },
  {
    "x": "muslumanê (m)",
    "y": " b. muslumanîye (m), muslumanî (m)"
  },
  {
    "x": "muslumanenî (m)",
    "y": " b. muslumanênî (m)"
  },
  {
    "x": "muslumanênî (m)",
    "y": " İslamiyet, İslamlık, Müslümanlık"
  },
  {
    "x": "muslumanî (m)",
    "y": " İslamiyet, İslam, İslamlık, Müslümanlık"
  },
  {
    "x": "muslumanîye (m)",
    "y": " İslamiyet, İslam, İslamlık, Müslümanlık"
  },
  {
    "x": "muslumon, -e",
    "y": " b. musluman, -e"
  },
  {
    "x": "muslumoney (m)",
    "y": " b. muslumanîye (m), muslumanî (m)"
  },
  {
    "x": "muslumonê (m)",
    "y": " b. muslumanîye (m), muslumanî (m)"
  },
  {
    "x": "muslumûn, -e",
    "y": " b. musluman, -e"
  },
  {
    "x": "muslumûney (m)",
    "y": " b. muslumanîye (m), muslumanî (m)"
  },
  {
    "x": "muslumûnê (m)",
    "y": " b. muslumanîye (m), muslumanî (m)"
  },
  {
    "x": "muslux (n)",
    "y": " b. musluxe (m)"
  },
  {
    "x": "musluxe (m)",
    "y": " musluk"
  },
  {
    "x": "musnayene",
    "y": " 1)göstermek\r\n~2)öğretmek\r\n~3)alıştırmak"
  },
  {
    "x": "musnayîş (n)",
    "y": " 1)gösterme, gösteri\r\n~2)öğretme\r\n~3)alıştırma"
  },
  {
    "x": "mustaqîl, -e",
    "y": " bağımsız, müstakil"
  },
  {
    "x": "mustesna, -ye",
    "y": " ayrık"
  },
  {
    "x": "musteşar, -e",
    "y": " müsteşar"
  },
  {
    "x": "musteşarî (m)",
    "y": " müsteşarlık"
  },
  {
    "x": "musteşarîye (m)",
    "y": " müsteşarlık"
  },
  {
    "x": "mustik (n)",
    "y": " emzik"
  },
  {
    "x": "musubet (m)",
    "y": " b. musîbete (m)"
  },
  {
    "x": "muswede (n)",
    "y": " yazı taslağı"
  },
  {
    "x": "muş (n)",
    "y": " köstebek"
  },
  {
    "x": "muşo kor (n)",
    "y": " körfare"
  },
  {
    "x": "muşag, -e",
    "y": " b. mişag, -e"
  },
  {
    "x": "muşahede (n)",
    "y": " gözlem, müşahede"
  },
  {
    "x": "muşahede kerdene",
    "y": " gözlemek, gözlemlemek"
  },
  {
    "x": "muşahedekerdiş (n)",
    "y": " gözleme, gözlemleme"
  },
  {
    "x": "muşahid, -e",
    "y": " gözlemci"
  },
  {
    "x": "muşane (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "muşane kutene",
    "y": " b. muşene kutene"
  },
  {
    "x": "muşani (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "muşavîr, -e",
    "y": " b. mişawîr, -e"
  },
  {
    "x": "muşembe (n)",
    "y": " b. muşeme (n)"
  },
  {
    "x": "muşeme (n)",
    "y": " muşamba"
  },
  {
    "x": "muşemeyê erdî (n)",
    "y": " yer muşambası"
  },
  {
    "x": "muşemeyê masa (n)",
    "y": " masa muşambası"
  },
  {
    "x": "muşemi (n)",
    "y": " b. muşeme (n)"
  },
  {
    "x": "muşene (n)",
    "y": " 1)döven, düven\r\n~2)teneşir, teneşir tahtası"
  },
  {
    "x": "muşene çarnayene",
    "y": " döven sürmek, döven dövmek, harman dövmek"
  },
  {
    "x": "muşene kutene",
    "y": " döven sürmek, döven dövmek, harman dövmek"
  },
  {
    "x": "muşeqetî (m)",
    "y": " b. mişeqetî (m)"
  },
  {
    "x": "muşewre (n)",
    "y": " b. mişewre (n)"
  },
  {
    "x": "muşewre kerdene",
    "y": " b. mişewre kerdene"
  },
  {
    "x": "muşewre viraştene",
    "y": " b. mişewre viraştene"
  },
  {
    "x": "muşexes, -e",
    "y": " somut, müşahhas"
  },
  {
    "x": "muşone (n)",
    "y": " b. muşene (n)"
  },
  {
    "x": "muşone çarnayîne",
    "y": " b. muşene çarnayene"
  },
  {
    "x": "muşone kutene",
    "y": " b. muşene kutene"
  },
  {
    "x": "mutahîd, -e",
    "y": " müteahhit, yüklenici"
  },
  {
    "x": "mutahîdî (m)",
    "y": " müteahhitlik"
  },
  {
    "x": "mutahîdîye (m)",
    "y": " müteahhitlik"
  },
  {
    "x": "mutahît, -e",
    "y": " b. mutahîd, -e"
  },
  {
    "x": "mutahîtê (m)",
    "y": " b. mutahîdî (m), mutahîdîye (m)"
  },
  {
    "x": "mutareke (m)",
    "y": " ateşkes, bırakışma, mütareke"
  },
  {
    "x": "mutbax (m)",
    "y": " b. mitbaxe (m)"
  },
  {
    "x": "mutbaxe (m)",
    "y": " b. mitbaxe (m)"
  },
  {
    "x": "mutbexe (m)",
    "y": " b. mitbaxe (m)"
  },
  {
    "x": "muteber, -e",
    "y": " 1)geçerli, meri\r\n~2)saygın"
  },
  {
    "x": "muteberey (m)",
    "y": " b. muteberîye (m), muteberî (m)"
  },
  {
    "x": "muteberê (m)",
    "y": " b. muteberîye (m), muteberî (m)"
  },
  {
    "x": "muteberî (m)",
    "y": " 1)geçerlilik\r\n~2)saygınlık"
  },
  {
    "x": "muteberîye (m)",
    "y": " 1)geçerlilik\r\n~2)saygınlık"
  },
  {
    "x": "muteber, -e",
    "y": " cari"
  },
  {
    "x": "muteesib, -e",
    "y": " bağnaz, fanatik"
  },
  {
    "x": "muteesib bîyene",
    "y": " bağnazlaşmak, fanatikleşmek"
  },
  {
    "x": "muteesibbîyayîş (n)",
    "y": " bağnazlaşma, fanatikleşme"
  },
  {
    "x": "muteesibîye (m)",
    "y": " bağnazlık, fanatizm, fanatiklik"
  },
  {
    "x": "muteessîr bîyene",
    "y": " etkilenmek, müteessir olmak"
  },
  {
    "x": "mutehesîs bîyene",
    "y": " duygulanmak, hislenmek"
  },
  {
    "x": "mutehesîs kerdene",
    "y": " duygulandırmak"
  },
  {
    "x": "mutehesîsbîyayîş (n)",
    "y": " duygulanma, hislenme"
  },
  {
    "x": "mutehesîskerdiş (n)",
    "y": " duygulandırma"
  },
  {
    "x": "mutehessîs bîyayene",
    "y": " b. mutehesîs bîyene"
  },
  {
    "x": "mutehessîs kerdene",
    "y": " b. mutehesîs kerdene"
  },
  {
    "x": "mutehessîsbîyayîş (n)",
    "y": " b. mutehesîsbîyayîş (n)"
  },
  {
    "x": "mutehessîskerdiş (n)",
    "y": " b. mutehesîskerdiş (n)"
  },
  {
    "x": "mutehîd, -e",
    "y": " b. mutahîd, -e"
  },
  {
    "x": "mutehîdê (m)",
    "y": " b. mutahîdî (m), mutahîdîye (m)"
  },
  {
    "x": "mutemadî, -ye",
    "y": " sürekli, devamlı"
  },
  {
    "x": "mutemadîyen",
    "y": " sürekli, devamlı olarak"
  },
  {
    "x": "mutenasib, -e",
    "y": " orantılı, mütenasip"
  },
  {
    "x": "mutenasibî (m)",
    "y": " orantılılık"
  },
  {
    "x": "mutewazî, -ye",
    "y": " alçakgönüllü, mütevazı"
  },
  {
    "x": "mutewazîyey (m)",
    "y": " b. mutewazîyî (m)"
  },
  {
    "x": "mutewazîyê (m)",
    "y": " b. mutewazîyî (m)"
  },
  {
    "x": "mutewazîyî (m)",
    "y": " alçakgönüllülük, mütevazılık"
  },
  {
    "x": "muttefîq, -e",
    "y": " bağlaşık"
  },
  {
    "x": "muttefîq bîyene",
    "y": " bağlaşmak"
  },
  {
    "x": "muwafeqet (n)",
    "y": " muvafakat"
  },
  {
    "x": "muwafiq, -e",
    "y": " muvafık, uygun, yaraşır"
  },
  {
    "x": "muwafiq bîyayene",
    "y": " b. muwafiq bîyene"
  },
  {
    "x": "muwafiq bîyene",
    "y": " 1)uymak, uygun gelmek, uygun olmak\r\n~2)yakışmak, yaraşmak\r\n~3)bağdaşmak"
  },
  {
    "x": "muwafiqbîyayîş (n)",
    "y": " 1)uygun olma, uyma\r\n~2)bağdaşma"
  },
  {
    "x": "muwafiqî (m)",
    "y": " uygunluk"
  },
  {
    "x": "muwafiqîye (m)",
    "y": " uygunluk"
  },
  {
    "x": "muwasla (m)",
    "y": " b. mosela (m)"
  },
  {
    "x": "muwazene (n)",
    "y": " denge, balans"
  },
  {
    "x": "muwazeneyê sipîyî (sn)",
    "y": " beyaz dengesi"
  },
  {
    "x": "muwazeneyo bîyolojîk (n)",
    "y": " biyolojik denge, dirim bilimsel denge"
  },
  {
    "x": "muwe (m)",
    "y": " b. maye-I (m)"
  },
  {
    "x": "muwekîl, -e",
    "y": " müvekkil"
  },
  {
    "x": "muweqet, -e",
    "y": " geçici"
  },
  {
    "x": "muwex (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "muxabir, -e",
    "y": " muhabir"
  },
  {
    "x": "muxabîrê/a fotografan",
    "y": " foto muhabiri"
  },
  {
    "x": "muxabîrê/a fotoyan",
    "y": " foto muhabiri"
  },
  {
    "x": "muxabirî (m)",
    "y": " muhabirlik"
  },
  {
    "x": "muxabirîye (m)",
    "y": " muhabirlik"
  },
  {
    "x": "muxabîr, -e",
    "y": " b. muxabir, -e"
  },
  {
    "x": "muxabîrî (m)",
    "y": " b. muxabirî (m)"
  },
  {
    "x": "muxabîrîye (m)",
    "y": " b. muxabirîye (m)"
  },
  {
    "x": "muxara (m)",
    "y": " b. mixara (m)"
  },
  {
    "x": "muxayîr, -e",
    "y": " aykırı"
  },
  {
    "x": "muxayîrîye (m)",
    "y": " aykırılık"
  },
  {
    "x": "muxbîr, -e",
    "y": " b. muhbir"
  },
  {
    "x": "muxbîrî (m)",
    "y": " muhbirlik"
  },
  {
    "x": "muxbîrîye (m)",
    "y": " muhbirlik"
  },
  {
    "x": "muxdar (n)",
    "y": " b. murdar, -e"
  },
  {
    "x": "muxirve (n)",
    "y": " b. mexreb (n)"
  },
  {
    "x": "muxrib (n)",
    "y": " b. mexreb (n)"
  },
  {
    "x": "muxrî (n)",
    "y": " b. nuxrî (n)"
  },
  {
    "x": "muxtar, -e",
    "y": " muhtar"
  },
  {
    "x": "muxtarênî (m)",
    "y": " muhtarlık"
  },
  {
    "x": "muxtarîn (m)",
    "y": " b. muxtarênî (m)"
  },
  {
    "x": "muxtarîye (m)",
    "y": " muhtarlık"
  },
  {
    "x": "muxtelîf (s)",
    "y": " çeşitli"
  },
  {
    "x": "muxtelîfîye (m)",
    "y": " çeşitlilik"
  },
  {
    "x": "muxurb (n)",
    "y": " b. mexreb (n)"
  },
  {
    "x": "muxurbe (n)",
    "y": " b. mexreb (n) "
  },
  {
    "x": "muxurbe (m)",
    "y": " b. mexreb (n) "
  },
  {
    "x": "muy (m)",
    "y": " b. muye (m)"
  },
  {
    "x": "muye (m)",
    "y": " kıl"
  },
  {
    "x": "muyî girewtiş",
    "y": " kıl alma"
  },
  {
    "x": "muyen, -e",
    "y": " b. muyên, -e"
  },
  {
    "x": "muyesîr (n)",
    "y": " b. masîla (m)"
  },
  {
    "x": "muyesîr bîyene",
    "y": " b. masîla bîyene"
  },
  {
    "x": "muyên, -e",
    "y": " kıldan yapılma"
  },
  {
    "x": "muyin, -e",
    "y": " kıllı"
  },
  {
    "x": "muyîn (n)",
    "y": " muyin (n)"
  },
  {
    "x": "muyndî (m)",
    "y": " b. mûndî (m)"
  },
  {
    "x": "muynda mişanî (m)",
    "y": " b. mûndîya muşeneyî (m)"
  },
  {
    "x": "muz (m)",
    "y": " b. muze (m)"
  },
  {
    "x": "muze-I (m)",
    "y": " müze"
  },
  {
    "x": "muze-II (m)",
    "y": " muz"
  },
  {
    "x": "muzekkere (n)",
    "y": " müzekkere"
  },
  {
    "x": "muzekkereyê tewqîfî (n)",
    "y": " tutuklama müzekkeresi, tevkif müzekkeresi"
  },
  {
    "x": "Muzir (n)",
    "y": " b. Mizur (n)"
  },
  {
    "x": "muzîk (n)",
    "y": " 1)müzik\r\n~2)çalgı"
  },
  {
    "x": "muzîkê fîlmî",
    "y": " film müziği"
  },
  {
    "x": "muzîko ganî (n)",
    "y": " canlı müzik"
  },
  {
    "x": "odaya muzîkî",
    "y": " müzik odası"
  },
  {
    "x": "setê muzîkî (n)",
    "y": " müzik seti"
  },
  {
    "x": "muzîkal, -e",
    "y": " müzikal"
  },
  {
    "x": "muzîsyen, -e",
    "y": " müzisyen"
  },
  {
    "x": "muzîsyenî (m)",
    "y": " müzisyenlik"
  },
  {
    "x": "muzîsyenîye (m)",
    "y": " müzisyenlik"
  },
  {
    "x": "muzroş, -e",
    "y": " muzcu"
  },
  {
    "x": "Muzur (n)",
    "y": " b. Mizur (n)"
  },
  {
    "x": "mû-I (m)",
    "y": " b. muye (m)"
  },
  {
    "x": "mû-II (n)",
    "y": " b. mîyan (n)"
  },
  {
    "x": "mûç",
    "y": " (dikleşmiş olan) dik"
  },
  {
    "x": "mûç kerdene",
    "y": " (hayvan kulağı vb. için) dikleştirmek, dikeltmek"
  },
  {
    "x": "mûçîne (m)",
    "y": " cımbız"
  },
  {
    "x": "mûde (n)",
    "y": " b. moda (m)"
  },
  {
    "x": "mûdim, -e",
    "y": " sürekli, devamlı"
  },
  {
    "x": "mûdimî (m)",
    "y": " devamlılık, süreklilik"
  },
  {
    "x": "mûdî, -ye",
    "y": " kıskanç, günücü, hasetçi (başkasını çekemeyen)"
  },
  {
    "x": "mûdîyê (m)",
    "y": " b. mûdîyîye (m), mûdîyî (m)"
  },
  {
    "x": "mûdîyê kerdene",
    "y": " b. mûdîyî kerdene"
  },
  {
    "x": "mûdîyî (m)",
    "y": " kıskançlık, günü, günücülük, hasetlik (çekemezlik), çekememezlik"
  },
  {
    "x": "mûdîyî kerdene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "mûdîyîye (m)",
    "y": " kıskançlık, günü, günücülük, hasetlik (çekemezlik), çekememezlik"
  },
  {
    "x": "mûga (m)",
    "y": " b. manga (m)"
  },
  {
    "x": "mûhkem, -e",
    "y": " b. muhkem, -e"
  },
  {
    "x": "mûhkem bîyayene",
    "y": " b. muhkem bîyene"
  },
  {
    "x": "mûhkem kerdene",
    "y": " b. muhkem kerdene"
  },
  {
    "x": "mûhkembîyayîş (n)",
    "y": " muhkembîyayîş (n)"
  },
  {
    "x": "mûhkemê (m)",
    "y": " b. muhkemî (m), muhkemîye (m)"
  },
  {
    "x": "mûhkemî (m)",
    "y": " b. muhkemî (m), muhkemîye (m)"
  },
  {
    "x": "mûhkemkerdiş (n)",
    "y": " b. muhkemkerdiş (n)"
  },
  {
    "x": "mûindene",
    "y": " b. mûnitene"
  },
  {
    "x": "mûindiş (n)",
    "y": " b. mûnitiş (n)"
  },
  {
    "x": "mûî (m)",
    "y": " b. muye (m)"
  },
  {
    "x": "mûja (m)",
    "y": " b. mujeyî (zh)"
  },
  {
    "x": "mûm (m)",
    "y": " b. mume (m)"
  },
  {
    "x": "mûmdane (m)",
    "y": " b. mumdane (m)"
  },
  {
    "x": "mûme (m)",
    "y": " b. mume (m)"
  },
  {
    "x": "mûn (n)",
    "y": " b. mîyan (n)"
  },
  {
    "x": "mûn-",
    "y": " b. man-"
  },
  {
    "x": "mûnayene",
    "y": " 1)örmek \r\n~2)örmek (saç vb. için)"
  },
  {
    "x": "mûnayîş (n)",
    "y": " 1)örme\r\n~2)örme (saç vb. için)"
  },
  {
    "x": "mûndar (n)",
    "y": " b. mîyandar (n)"
  },
  {
    "x": "mûnde (m)",
    "y": " b. mûndî (m)"
  },
  {
    "x": "mûndene",
    "y": " b. mûnitene"
  },
  {
    "x": "mûndi (m)",
    "y": " b. mûndî (m)"
  },
  {
    "x": "mûndika muşeneyî (m)",
    "y": " dövenin tahtalarını birbirine bağlayan ve onlara enine çakılmış olan çıta"
  },
  {
    "x": "mûndiş (n)",
    "y": " b. mûnitiş (n)"
  },
  {
    "x": "mûndî (m)",
    "y": " 1)kuşak\r\n~2)kiriş (duvar için)\r\n~3)(mecazi) göbek hizası"
  },
  {
    "x": "mûndî bestene",
    "y": " kuşak bağlamak"
  },
  {
    "x": "mûndî girê dayene",
    "y": " kuşak bağlamak"
  },
  {
    "x": "mûndî pîştene",
    "y": " kuşak bağlamak"
  },
  {
    "x": "mûndî xo mîyane ra bestene",
    "y": " kuşak bağlamak"
  },
  {
    "x": "mûndî xo mîyane ra dayene",
    "y": " kuşak bağlamak "
  },
  {
    "x": "mûndîya Ecemî (m)",
    "y": " Acem kuşağı"
  },
  {
    "x": "mûndîya Eyşo-Fatma (m)",
    "y": " gökkuşağı, alkım, ebekuşağı, ebemkuşağı, eleğimsağma, hacılarkuşağı, yağmurkuşağı, alaimisema"
  },
  {
    "x": "mûndîya Fatma-Eyşane (m)",
    "y": " gökkuşağı, alkım, ebekuşağı, ebemkuşağı, eleğimsağma, hacılarkuşağı, yağmurkuşağı, alaimisema"
  },
  {
    "x": "mûndîya Hezretî Fatma (m)",
    "y": " gökkuşağı, alkım, ebekuşağı, ebemkuşağı, eleğimsağma, hacılarkuşağı, yağmurkuşağı, alaimisema"
  },
  {
    "x": "mûndîya keske (m)",
    "y": " yeşil kuşak"
  },
  {
    "x": "mûndîya Lawure (m)",
    "y": " Lahor kuşağı"
  },
  {
    "x": "mûndîya muşanî (m)",
    "y": " b. mûndîya muşeneyî (m)"
  },
  {
    "x": "mûndîya muşeneyî (m)",
    "y": " dövenin tahtalarını birbirine bağlayan ve onlara enine çakılmış olan çıta"
  },
  {
    "x": "mûndîka muşanî m)",
    "y": " b. mûndika muşeneyî (m)"
  },
  {
    "x": "mûnga (m)",
    "y": " b. manga (m)"
  },
  {
    "x": "mûnite (n)",
    "y": " kurgu"
  },
  {
    "x": "mûniteyo sade (sn)",
    "y": " düz kurgu"
  },
  {
    "x": "mûniteyo xam (sn)",
    "y": " kaba kurgu"
  },
  {
    "x": "mûnitene",
    "y": " 1)örmek\r\n~2)örmek (saç vb. için)"
  },
  {
    "x": "mûnitiş (n)",
    "y": " 1)örme\r\n~2)örme (saç vb. için)"
  },
  {
    "x": "mûnitox, -e",
    "y": " örgücü"
  },
  {
    "x": "mûnî",
    "y": " b. mîyanî"
  },
  {
    "x": "mûnîk a",
    "y": " b. mîyanîk a"
  },
  {
    "x": "mûnîkî",
    "y": " b. mîyanîkî"
  },
  {
    "x": "mûnker (m)",
    "y": " b. mankere (m)"
  },
  {
    "x": "mûnkeri (m)",
    "y": " b. mankere (m)"
  },
  {
    "x": "mûnqal (m)",
    "y": " b. manqale (m)"
  },
  {
    "x": "mûntiş (n)",
    "y": " b. mûnitiş (n)"
  },
  {
    "x": "mûnto (n)",
    "y": " b. manto (n)"
  },
  {
    "x": "mûra-I (m)",
    "y": " b. mura-I (m)"
  },
  {
    "x": "mûra mîyanî (m)",
    "y": " b. muraya mîyanî (m)"
  },
  {
    "x": "mûrê nezerî (m)",
    "y": " b. muraya nezerî (m)"
  },
  {
    "x": "mûra-II (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "Mûrad (m)",
    "y": " b. Murad (n)"
  },
  {
    "x": "mûreki (m)",
    "y": " b. mura-I (m)"
  },
  {
    "x": "mûrêr (n) ",
    "y": "b. murîyêre (m)"
  },
  {
    "x": "mûrîye (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "mûrû (m)",
    "y": " b. murîye (m)"
  },
  {
    "x": "mûsayene",
    "y": " b. musayene"
  },
  {
    "x": "mûsayîş (n)",
    "y": " b. musayîş (n)"
  },
  {
    "x": "mûsnayene",
    "y": " b. musnayene"
  },
  {
    "x": "mûsnayîş (n)",
    "y": " b. musnayîş (n)"
  },
  {
    "x": "Mûş (n)",
    "y": " Muş"
  },
  {
    "x": "mûtori (m)",
    "y": " b. motore (m)"
  },
  {
    "x": "mûx (n)",
    "y": " harman savrulurken biriken çok ince saman"
  },
  {
    "x": "mûxara (m)",
    "y": " b. mixara (m)"
  },
  {
    "x": "mûy (m)",
    "y": " b. muye (m)"
  },
  {
    "x": "mûye (m)",
    "y": " b. muye (m)"
  },
  {
    "x": "mûyin, -e",
    "y": " b. muyin, -e"
  },
  {
    "x": "mûyî (m)",
    "y": " b. muye (m)"
  },
  {
    "x": "mûz (m)",
    "y": " b. muze (m)"
  },
  {
    "x": "mûzir, -e",
    "y": " kıskanç, günücü, hasetçi (başkasını çekemeyen)"
  },
  {
    "x": "mûzirey (m)",
    "y": " b. mûzirî (m), mûzirîye (m)"
  },
  {
    "x": "mûzirey kerdene",
    "y": " b. mûzirî kerdene, mûzirîye kerdene"
  },
  {
    "x": "mûzirê (m)",
    "y": " b. mûzirî (m), mûzirîye (m)"
  },
  {
    "x": "mûzirê kerdene",
    "y": " b. mûzirî kerdene, mûzirîye kerdene"
  },
  {
    "x": "mûziri (m)",
    "y": " b. mûzire (m)"
  },
  {
    "x": "mûzirî (m)",
    "y": " kıskançlık, günü, günücülük, hasetlik (çekemezlik), çekememezlik"
  },
  {
    "x": "mûzirî kerdene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "mûzirîye (m)",
    "y": " kıskançlık, günü, günücülük, hasetlik (çekemezlik), çekememezlik"
  },
  {
    "x": "mûzirîye kerdene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "mycle (m)",
    "y": " b. morcela (m)"
  },
  {
    "x": "myecnayîş (n)",
    "y": " b. mojnayîş (n)"
  },
  {
    "x": "myerde (n)",
    "y": " b. mêrde (n)"
  },
  {
    "x": "myerdi (n)",
    "y": " b. mêrde (n)"
  },
  {
    "x": "myerik (n)",
    "y": " b. mêrik (n)"
  },
  {
    "x": "myeşna (m)",
    "y": " b. mêşna (m)"
  },
  {
    "x": "myurdum, -i",
    "y": " b. merdim, -e"
  },
  {
    "x": "myûn",
    "y": " b. mîyan"
  },
  {
    "x": "NNN"
  },
  {
    "x": "na-I (m, z)",
    "y": " bu"
  },
  {
    "x": "na wa",
    "y": " b. na ya"
  },
  {
    "x": "na wo",
    "y": " b. no yo"
  },
  {
    "x": "na ya",
    "y": " budur, işte"
  },
  {
    "x": "naye (m, z)",
    "y": " buna, bunu"
  },
  {
    "x": "na-II (m, s)",
    "y": " bu, bunu\r\n~Na kêneke vana. (Bu kız söylüyor.)"
  },
  {
    "x": "na fa",
    "y": " b. na fine"
  },
  {
    "x": "na fae",
    "y": " b. na fine"
  },
  {
    "x": "na faye",
    "y": " b. na fine"
  },
  {
    "x": "na fine",
    "y": " bu kez"
  },
  {
    "x": "na rey",
    "y": " bu kez, bu kere, bu defa, bu sefer"
  },
  {
    "x": "na rê",
    "y": " b. na rey"
  },
  {
    "x": "na ... ra",
    "y": " buna\r\n~Na kêneke ra vaje. (Bu kıza söyle.)"
  },
  {
    "x": "na tore",
    "y": " b. no tewir"
  },
  {
    "x": "na-III (n, s)",
    "y": " 1)b. no (n, s)\r\n~2)b. nê (n, s)"
  },
  {
    "x": "na-IV (zh, s)",
    "y": " b. nê (zh, s)"
  },
  {
    "x": "na-V (n)",
    "y": " b. nan (n)"
  },
  {
    "x": "na-VI",
    "y": " b. nê-VI"
  },
  {
    "x": "na-VII",
    "y": " b. nê-VII"
  },
  {
    "x": "na ... na ...",
    "y": " b. nê ... nê ..."
  },
  {
    "x": "...na-I (sf)",
    "y": " “-den beri” anlamı veren bir sonek\r\n~parna: geçen yıldan beri\r\n~vizêrna:  dünden beri"
  },
  {
    "x": "parna",
    "y": " geçen yıldan beri"
  },
  {
    "x": "vizêrna",
    "y": " dünden beri"
  },
  {
    "x": "...na-II (sf)",
    "y": " “daha” anlamı veren bir sonek\r\n~Rojêna tîya bimane. (Bir gün daha burada kal.)"
  },
  {
    "x": "nabeyn (n)",
    "y": " b. mabên (n)"
  },
  {
    "x": "nabên (n)",
    "y": " b. mabên (n)"
  },
  {
    "x": "nabîn (n)",
    "y": " b. mabên (n)"
  },
  {
    "x": "nac (n)",
    "y": " b. nacnîye (m), nacnî (m)"
  },
  {
    "x": "naca (n)",
    "y": " b. noca (n)"
  },
  {
    "x": "nacar, -e",
    "y": " b. necar, -e"
  },
  {
    "x": "nace (m)",
    "y": " b. nacnîye (m), nacnî (m)"
  },
  {
    "x": "naciki (m)",
    "y": " b. nacnî (m)"
  },
  {
    "x": "naciq (n)",
    "y": " b. nalçix (n)"
  },
  {
    "x": "nacki (m)",
    "y": " b. nacnî (m)"
  },
  {
    "x": "nacnî (m)",
    "y": " amca karısı, yenge (amca karısı)"
  },
  {
    "x": "nacnîye (m)",
    "y": " amca karısı, yenge (amca karısı)"
  },
  {
    "x": "naçar",
    "y": " b. neçar, -e"
  },
  {
    "x": "naçarê (m)",
    "y": " b. neçarî (m)"
  },
  {
    "x": "naçiq (n)",
    "y": " b. nalçix (n)"
  },
  {
    "x": "nadayene",
    "y": " b. nîyadayene"
  },
  {
    "x": "nae (m, z)",
    "y": " b. naye (m, z)"
  },
  {
    "x": "naf (n)",
    "y": " b. menfeet (n)"
  },
  {
    "x": "nahal (m)",
    "y": " b. nale-I (m)"
  },
  {
    "x": "nahali (m)",
    "y": " b. nale-I (m)"
  },
  {
    "x": "nahet-dohet",
    "y": " b. nohet-ohet"
  },
  {
    "x": "naheti (m)",
    "y": " b. nohet (n)"
  },
  {
    "x": "nahêm (n)",
    "y": " b. nohêm (n)"
  },
  {
    "x": "nahêm-dohêm",
    "y": " b. nohêm-ohêm"
  },
  {
    "x": "nahîye (m)",
    "y": " bucak, nahiye"
  },
  {
    "x": "nahye (m)",
    "y": " b. nahîye (m)"
  },
  {
    "x": "Naîrobî",
    "y": " Nairobi"
  },
  {
    "x": "naj (m)",
    "y": " b. nacnîye (m), nacnî (m)"
  },
  {
    "x": "naja (n)",
    "y": " b. noca (n)"
  },
  {
    "x": "naje (m)",
    "y": " b. nacnîye (m), nacnî (m)"
  },
  {
    "x": "naji (m)",
    "y": " b. nacnîye (m), nacnî (m)"
  },
  {
    "x": "najiki (m)",
    "y": " b. nacnîye (m), nacnî (m)"
  },
  {
    "x": "najnî (m)",
    "y": " b. nacnîye (m), nacnî (m)"
  },
  {
    "x": "najnîye (m) ",
    "y": " b. nacnîye (m), nacnî (m)"
  },
  {
    "x": "nak-I (n)",
    "y": " göbek"
  },
  {
    "x": "nak-II (m)",
    "y": " b. nake (m)"
  },
  {
    "x": "nake (m)",
    "y": " tığ, dantel iğnesi"
  },
  {
    "x": "naki (m)",
    "y": " b. nak-I (n)"
  },
  {
    "x": "nakîdebîyayîş (n)",
    "y": " b. nekîdebîyayîş (n)"
  },
  {
    "x": "nakokî (m)",
    "y": " çelişki"
  },
  {
    "x": "nal (m)",
    "y": " b. nale-I (m)"
  },
  {
    "x": "nal (n)",
    "y": " b. nale-II (n)"
  },
  {
    "x": "nalayene",
    "y": " inlemek"
  },
  {
    "x": "nalayêne",
    "y": " b. nalayene"
  },
  {
    "x": "nalayîne",
    "y": " b. nalayene"
  },
  {
    "x": "nalayîş (n)",
    "y": " inleme"
  },
  {
    "x": "nalbend, -e",
    "y": " nalbant"
  },
  {
    "x": "nalbendî (m)",
    "y": " nalbantlık"
  },
  {
    "x": "nalbendîye (m)",
    "y": " nalbantlık"
  },
  {
    "x": "nalbênd, -e",
    "y": " b. nalbend, -e"
  },
  {
    "x": "nalbêri (m)",
    "y": " b. henarêre (m)"
  },
  {
    "x": "nalbur, -e",
    "y": " nalbur"
  },
  {
    "x": "nalcix (n)",
    "y": " b. nalçix (n)"
  },
  {
    "x": "nalçe (n)",
    "y": " nalça"
  },
  {
    "x": "nalçix (n)",
    "y": " nacak (küçük balta), baltacık"
  },
  {
    "x": "nalçixê dewrêşan (n)",
    "y": " kesici tarafı yarım daire biçiminde olan bir savaş aleti"
  },
  {
    "x": "nalçixê dewrêşû (n)",
    "y": " b. nalçixê dewrêşan (n)"
  },
  {
    "x": "nale-I (m)",
    "y": " düve"
  },
  {
    "x": "nale-II (n)",
    "y": " nal"
  },
  {
    "x": "nalek (m)",
    "y": " b. nale-I (m)"
  },
  {
    "x": "naleki (m)",
    "y": " b. nale-I (m)"
  },
  {
    "x": "nalên (n)",
    "y": " nalın"
  },
  {
    "x": "nalênkar, -e",
    "y": " nalıncı (nalın yapan)"
  },
  {
    "x": "nalênroş, -e",
    "y": " nalıncı (nalın satan)"
  },
  {
    "x": "nali-I (m)",
    "y": " b. nale-I (m)"
  },
  {
    "x": "nali-II (n)",
    "y": " b. nale-II (n)"
  },
  {
    "x": "nalike (m)",
    "y": " b. nale-I (m)"
  },
  {
    "x": "naliki (m)",
    "y": " b. nale-I (m)"
  },
  {
    "x": "nalî (m)",
    "y": " inleyiş"
  },
  {
    "x": "nalîne (m)",
    "y": " inleyiş"
  },
  {
    "x": "nalîni (m)",
    "y": " b. nalîne (m)"
  },
  {
    "x": "nalîye (m)",
    "y": " inleyiş"
  },
  {
    "x": "nalkî (m)",
    "y": " b. nalmikî (m)"
  },
  {
    "x": "nalmanga (m)",
    "y": " genç inek"
  },
  {
    "x": "nalmikî (m)",
    "y": " tabak"
  },
  {
    "x": "nam (n)",
    "y": " b. nohêm (n)"
  },
  {
    "x": "namaz (n)",
    "y": " b. nimaj-1 (n)"
  },
  {
    "x": "namdar, -e",
    "y": " tanınmış, ünlü, adlı"
  },
  {
    "x": "name (n)",
    "y": " ad, isim"
  },
  {
    "x": "nameyê xebitnayoxe (n) (TE)",
    "y": " kullanıcı adı"
  },
  {
    "x": "nameyê xebitnayoxî (n) (TE)",
    "y": " kullanıcı adı"
  },
  {
    "x": "nameyê yewînanê şaredarî/beledîya",
    "y": " belediye birim isimleri"
  },
  {
    "x": "nameyo ante (n)",
    "y": " bükünlü ad, bükünlü isim"
  },
  {
    "x": "nameyo anteyo makî (n)",
    "y": " bükünlü dişil isim"
  },
  {
    "x": "nameyo anteyo nêrî (n)",
    "y": " bükünlü eril isim"
  },
  {
    "x": "nameyo cins (n)",
    "y": " cins ad, cins isim"
  },
  {
    "x": "nameyo makî (n)",
    "y": " dişil ad, dişil isim"
  },
  {
    "x": "nameyo nêrî (n)",
    "y": " eril ad, eril isim"
  },
  {
    "x": "nameyo notr (n)",
    "y": " nötr ad, nötr isim"
  },
  {
    "x": "nameyo taybetî (n)",
    "y": " özel ad, özel isim"
  },
  {
    "x": "nameyo xoser (n)",
    "y": " yalın ad, yalın isim"
  },
  {
    "x": "nameyo yewhûmar (n)",
    "y": " tekil ad, tekil isim"
  },
  {
    "x": "nameyo zafhûmar (n)",
    "y": " çoğul ad, çoğul isim"
  },
  {
    "x": "namedar, -e",
    "y": " b. namdar, -e"
  },
  {
    "x": "namger, -e",
    "y": " sözlü"
  },
  {
    "x": "namilkî (m)",
    "y": " b. nalmikî (m)"
  },
  {
    "x": "Namîbya (m)",
    "y": " Namibya (Güneybatı Afrika)"
  },
  {
    "x": "namkî (m)",
    "y": " b. nalmikî (m)"
  },
  {
    "x": "namus (n)",
    "y": " b. namûs (n)"
  },
  {
    "x": "namûs (n)",
    "y": " namus"
  },
  {
    "x": "namzed, -e",
    "y": " aday, namzet"
  },
  {
    "x": "namzedê Oscarî (namzedê Oskarî)",
    "y": " Oscar adayları"
  },
  {
    "x": "namzedîye (m)",
    "y": " adaylık, namzetlik"
  },
  {
    "x": "nan (n)",
    "y": " 1)ekmek\r\n~2)yemek"
  },
  {
    "x": "nan dayene",
    "y": " yemek vermek"
  },
  {
    "x": "nan ra birîyayene",
    "y": " yemeden içmeden kesilmek, yiyemez olmak"
  },
  {
    "x": "nan û awe",
    "y": " yiyecek, yiyecek ve içecek (mecazi)"
  },
  {
    "x": "nan û sole",
    "y": " yiyecek, yiyecek ve içecek (mecazi)"
  },
  {
    "x": "nan û soli",
    "y": " b. nan û sole"
  },
  {
    "x": "nan vetene",
    "y": " yemek çıkarmak"
  },
  {
    "x": "nana binê adirî (m)",
    "y": " kömbe (küle gömülerek pişirilen mayasız çörek)"
  },
  {
    "x": "nanê belxulî (n)",
    "y": " b. nanê belxurî (n)"
  },
  {
    "x": "nanê belxurî (n)",
    "y": " bulgur ekmeği (bulgurdan yapılan ekmek)"
  },
  {
    "x": "nanê rûnî (n)",
    "y": " katmer (bir hamur işi)"
  },
  {
    "x": "nanê sacî (n)",
    "y": " yufka, yuka (ekmeği)"
  },
  {
    "x": "nanê socî (n)",
    "y": " b. nanê sacî (n)"
  },
  {
    "x": "nanê şolmazî (n)",
    "y": " akıtma (cıvıkça hamurdan yapılan bir tür tatlı)"
  },
  {
    "x": "nanê taweyî (n)",
    "y": " pişi, yağ katılmış hamurun yine yağda kızartılmasıyla yapılan hamur işi"
  },
  {
    "x": "nanê tewqe (n)",
    "y": " yufka, yuka (ekmeği)"
  },
  {
    "x": "nanê tîr (n)",
    "y": " b. nanê tîrî (n)"
  },
  {
    "x": "nanê tîre (n)",
    "y": " b. nanê tîrî (n)"
  },
  {
    "x": "nanê tîrî (n)",
    "y": " yufka, yuka (ekmeği)"
  },
  {
    "x": "nanê veyveyî (n)",
    "y": " düğün yemeği (topluca yenir)"
  },
  {
    "x": "nanê veyvî (n)",
    "y": " b. nanê veyveyî (n)"
  },
  {
    "x": "nanê xo vetene",
    "y": " nafakasını çıkarmak"
  },
  {
    "x": "nanê ... vetene",
    "y": " nafakasını çıkarmak "
  },
  {
    "x": "nano anculî (n)",
    "y": " katıksız ekmek, kuru ekmek, yavan ekmek"
  },
  {
    "x": "nano eşkeba (n)",
    "y": " b. nano eşkeva (n)"
  },
  {
    "x": "nano eşkeva (n)",
    "y": " mayasız ekmek"
  },
  {
    "x": "nano fetir (n)",
    "y": " b. nano fetîr (n)"
  },
  {
    "x": "nano fetîr (n)",
    "y": " mayasız ekmek"
  },
  {
    "x": "nano fitîl (n)",
    "y": " b. nano fetîr (n)"
  },
  {
    "x": "nano fitîr (n)",
    "y": " b. nano fetîr (n)"
  },
  {
    "x": "nano huşk (n)",
    "y": " katıksız ekmek, kuru ekmek, yavan ekmek"
  },
  {
    "x": "nano îştekerde (n)",
    "y": " b. nano mîştekerde (n)"
  },
  {
    "x": "nano kartî (n)",
    "y": " bayat ekmek"
  },
  {
    "x": "nano labaşe (n)",
    "y": " b. nano lawaş (n)"
  },
  {
    "x": "nano lawaş (n)",
    "y": " yufka ekmeği"
  },
  {
    "x": "nano levaş (n)",
    "y": " b. nano lawaş (n)"
  },
  {
    "x": "nano lewaş (n)",
    "y": " b. nano lawaş (n)"
  },
  {
    "x": "nano mîrazkerde (n)",
    "y": " mayalı ekmek"
  },
  {
    "x": "nano mîşte (n)",
    "y": " mayalı ekmek"
  },
  {
    "x": "nano mîştekerde (n)",
    "y": " mayalı ekmek"
  },
  {
    "x": "nano onculî (n)",
    "y": " b. nano anculî (n)"
  },
  {
    "x": "nano patîle (n)",
    "y": " b. nano patîre (n)"
  },
  {
    "x": "nano patîre (n)",
    "y": " 1)lavaş, bazlama\r\n~2)mayasız ekmek"
  },
  {
    "x": "nano şikeva (n)",
    "y": " b. nano eşkeva (n)"
  },
  {
    "x": "nano wişk (n)",
    "y": " b. nano huşk"
  },
  {
    "x": "nano yewxe (n)",
    "y": " b. nano yowxe (n)"
  },
  {
    "x": "nano yowxe (n)",
    "y": " yufka, yuka (ekmeği)"
  },
  {
    "x": "nano ziwa (n)",
    "y": " katıksız ekmek, kuru ekmek, yavan ekmek"
  },
  {
    "x": "nano zuwa (n)",
    "y": " b. nano ziwa (n)"
  },
  {
    "x": "nan-awe",
    "y": " yiyecek, yiyecek ve içecek (mecazi)"
  },
  {
    "x": "nan-sole",
    "y": " yiyecek, yiyecek ve içecek (mecazi)"
  },
  {
    "x": "nandar, -e",
    "y": " konuksever, misafirperver"
  },
  {
    "x": "nane-I (m)",
    "y": " ekmek (ekmek tanesi)"
  },
  {
    "x": "nane-II (n)",
    "y": " nane"
  },
  {
    "x": "nanên, -e",
    "y": " b. winasî, -ye; inasarên, -e"
  },
  {
    "x": "nangiranî (m)",
    "y": " kıtlık"
  },
  {
    "x": "nani (m)",
    "y": " b. nane-I (m)"
  },
  {
    "x": "nanîn, -e",
    "y": " b. winasî, -ye; inasarên, -e"
  },
  {
    "x": "nanmikî (m)",
    "y": " b. nalmikî (m)"
  },
  {
    "x": "nar û wer",
    "y": " b. norî-orî"
  },
  {
    "x": "nar-wer",
    "y": " b. norî-orî"
  },
  {
    "x": "nara",
    "y": " 1)bu kez, bu kere, bu defa, bu sefer\r\n~De nara mi waştinî ke şêro, o nêşînî: Bu kez (şimdi de) ben gitmesini istiyordum, o gitmiyordu.\r\n~2)bir de\r\n~Nara mi nîyada ke vejîyayo şîyo. (Bir de baktım ki çıkmış gitmiş.)"
  },
  {
    "x": "naray",
    "y": " b. nara"
  },
  {
    "x": "narbe (n)",
    "y": " filiz, sürgün"
  },
  {
    "x": "narbe dayene",
    "y": " filizlenmek, filiz vermek"
  },
  {
    "x": "narbik (n)",
    "y": " filiz, sürgün"
  },
  {
    "x": "narbik dayene",
    "y": " filizlenmek, filiz vermek"
  },
  {
    "x": "nardani (m)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nardane (m)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nardîwani (m)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nardoni (m)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nare (m)",
    "y": " b. henare (m)"
  },
  {
    "x": "narey",
    "y": " b. nara"
  },
  {
    "x": "narî-dorî",
    "y": " b. norî-orî"
  },
  {
    "x": "Narman (n)",
    "y": " Narman"
  },
  {
    "x": "narre (n)",
    "y": " b. narbe (n)"
  },
  {
    "x": "narre dayene",
    "y": " b. narbe dayene"
  },
  {
    "x": "narve (n)",
    "y": " b. narbe (n)"
  },
  {
    "x": "narve dayene",
    "y": " b. narbe dayene"
  },
  {
    "x": "nas, -e-I",
    "y": " tanıdık, tanış"
  },
  {
    "x": "nas bîyayene",
    "y": " b. nas bîyene"
  },
  {
    "x": "nas bîyayîne",
    "y": " b. nas bîyene"
  },
  {
    "x": "nas bîyene",
    "y": " 1)tanıdık olmak, tanış olmak\r\n~2)tanınmak"
  },
  {
    "x": "nas kerdene",
    "y": " tanımak"
  },
  {
    "x": "nas-II (n)",
    "y": " b. nase (m)"
  },
  {
    "x": "nase (m)",
    "y": " dogma, nas"
  },
  {
    "x": "nasalîyayîne",
    "y": " b. nesilîyayene"
  },
  {
    "x": "nasalîyayîş (n)",
    "y": " b. nesilîyayîş (n)"
  },
  {
    "x": "nasalnayîne",
    "y": " b. nesilnayene"
  },
  {
    "x": "nasalnayîş (n)",
    "y": " b. nesilnayîş (n)"
  },
  {
    "x": "nasbîyaya (m)",
    "y": " tanınmış, ünlü"
  },
  {
    "x": "nasbîyaye (n)",
    "y": " tanınmış, ünlü"
  },
  {
    "x": "nasbîyayî, -ye",
    "y": " tanınmış, ünlü"
  },
  {
    "x": "nasbîyayîş (n)",
    "y": " tanınma"
  },
  {
    "x": "naser (n)",
    "y": " b. laser (n)"
  },
  {
    "x": "nasilîyayîne",
    "y": " b. nesilîyayene"
  },
  {
    "x": "nasilîyayîş (n)",
    "y": " b. nesilîyayîş (n)"
  },
  {
    "x": "nasilnayîne",
    "y": " b. nesilnayene"
  },
  {
    "x": "nasilnayîş (n)",
    "y": " b. nesilnayîş (n)"
  },
  {
    "x": "nasir (n)",
    "y": " nasır"
  },
  {
    "x": "nasir girê dayene",
    "y": " nasırlaşmak, nasırlanmak "
  },
  {
    "x": "naska (n)",
    "y": " b. noca (n)"
  },
  {
    "x": "naskerda (m)",
    "y": " tanınmış, ünlü"
  },
  {
    "x": "naskerde (n)",
    "y": " tanınmış, ünlü"
  },
  {
    "x": "naskerdiş (n)",
    "y": " tanıma"
  },
  {
    "x": "naskerdîye (m)",
    "y": " tanınmış, ünlü"
  },
  {
    "x": "nasname (n)",
    "y": " kimlik"
  },
  {
    "x": "nasyonalîst, -e",
    "y": " milliyetçi"
  },
  {
    "x": "naşir, -e",
    "y": " naşir, yayımcı"
  },
  {
    "x": "naşirênî (m)",
    "y": " naşirlik, yayımcılık"
  },
  {
    "x": "naşirî (m)",
    "y": " naşirlik, yayımcılık"
  },
  {
    "x": "naşirîye (m)",
    "y": " naşirlik, yayımcılık"
  },
  {
    "x": "naşî, -ye",
    "y": " toy"
  },
  {
    "x": "naşîr, -e",
    "y": " b. naşir"
  },
  {
    "x": "naşîrî (m)",
    "y": " b. naşirî (m)"
  },
  {
    "x": "naşîrîye (m)",
    "y": " b. naşirîye (m)"
  },
  {
    "x": "naşîyîye (m)",
    "y": " cahillik"
  },
  {
    "x": "naşîyîye kerdene",
    "y": " cahillik etmek"
  },
  {
    "x": "naşt (n)",
    "y": " b. nat (n)"
  },
  {
    "x": "naşt û aşt",
    "y": " b. nat û wet"
  },
  {
    "x": "naşt û başt",
    "y": " b. nat û wet"
  },
  {
    "x": "naşt û daşt",
    "y": " b. nat û wet"
  },
  {
    "x": "naşt û wat",
    "y": " b. nat û wet"
  },
  {
    "x": "naşt û wet",
    "y": " b. nat û wet"
  },
  {
    "x": "naşt-daşt",
    "y": " b. nat-wet"
  },
  {
    "x": "naşt-daşta",
    "y": " b. nata-weta"
  },
  {
    "x": "naşta",
    "y": " b. nata"
  },
  {
    "x": "naşta-başta",
    "y": " b. nata-weta"
  },
  {
    "x": "naşta-daşta",
    "y": " b. nata-weta"
  },
  {
    "x": "naşta-yaşta",
    "y": " b. nata-weta"
  },
  {
    "x": "naştên (n)",
    "y": " b. natên (n)"
  },
  {
    "x": "nat (n)",
    "y": " beri, bu taraf, bu yaka"
  },
  {
    "x": "nat a",
    "y": " b. nat ra"
  },
  {
    "x": "nat ra",
    "y": " beriden"
  },
  {
    "x": "nat û bot",
    "y": " bu taraf o taraf, öte beri"
  },
  {
    "x": "nat û dot",
    "y": " bu taraf o taraf, öte beri"
  },
  {
    "x": "nat û wat",
    "y": " b. nat û wet"
  },
  {
    "x": "nat û wet",
    "y": " bu taraf o taraf, öte beri"
  },
  {
    "x": "nat û wet kerdene",
    "y": " dolaşmak"
  },
  {
    "x": "nat-bota",
    "y": " b. nata-bota"
  },
  {
    "x": "nat-dot",
    "y": " bu taraf o taraf, öte beri"
  },
  {
    "x": "nat-wet",
    "y": " bu taraf o taraf, öte beri"
  },
  {
    "x": "nata",
    "y": " beriye, bu tarafa, bu yakaya"
  },
  {
    "x": "nata-bota",
    "y": " o yana bu yana"
  },
  {
    "x": "nata-dota",
    "y": " b. nata-bota"
  },
  {
    "x": "nata-wata",
    "y": " b. nata-weta"
  },
  {
    "x": "nata-weta",
    "y": " bu tarafta o tarafta, ötede beride, şurada burada"
  },
  {
    "x": "nate",
    "y": " b. nata"
  },
  {
    "x": "natên, -e",
    "y": " beriki, berideki"
  },
  {
    "x": "natike (m)",
    "y": " alın"
  },
  {
    "x": "nato",
    "y": " b. nata"
  },
  {
    "x": "nator, -e",
    "y": " 1)korucu (orman, kır ya da köy bekçisi)\r\n~2)bağban (bağı koruyan)"
  },
  {
    "x": "natorcî, -ye",
    "y": " b. nator, -e"
  },
  {
    "x": "natorçî, -ye",
    "y": " b. nator, -e"
  },
  {
    "x": "natorey (m)",
    "y": " b. natorvanî (m), natorvanîye (m)"
  },
  {
    "x": "natorvanê (m)",
    "y": " b. natorvanî (m), natorvanîye (m)"
  },
  {
    "x": "natorvanî (m)",
    "y": " bağbancılık"
  },
  {
    "x": "natorvanîye (m)",
    "y": " bağbancılık"
  },
  {
    "x": "natûwetkerdiş (n)",
    "y": " dolaşma"
  },
  {
    "x": "Nauru (n)",
    "y": " Nauru"
  },
  {
    "x": "naver (n)",
    "y": " b. nover (n)"
  },
  {
    "x": "naver-wuver",
    "y": " b. nover-wever"
  },
  {
    "x": "navên (n)",
    "y": " b. mabên (n)"
  },
  {
    "x": "navik (m)",
    "y": " b. nak-I (n)"
  },
  {
    "x": "navîkî (m)",
    "y": " b. nalmikî (m)"
  },
  {
    "x": "navkêl (n)",
    "y": " bel"
  },
  {
    "x": "navlêri (m)",
    "y": " b. henarêre (m)"
  },
  {
    "x": "navran (n)",
    "y": " apışlık, ağ"
  },
  {
    "x": "navroce (m)",
    "y": " b. nêmroje (m)"
  },
  {
    "x": "navroci (m)",
    "y": " b. nêmroje (m)"
  },
  {
    "x": "navteng (n)",
    "y": " kolan"
  },
  {
    "x": "naw",
    "y": " b. nîyaw"
  },
  {
    "x": "naway",
    "y": " b. neway"
  },
  {
    "x": "nawayene",
    "y": " b. nîyawayene"
  },
  {
    "x": "nawayin, -i",
    "y": " b. newayin, -e"
  },
  {
    "x": "nawayîş (n)",
    "y": " b. nîyawayîş (n)"
  },
  {
    "x": "nawitene",
    "y": " göstermek"
  },
  {
    "x": "nawitgeh (n)",
    "y": " sergi"
  },
  {
    "x": "nawitiş (n)",
    "y": " gösterme, gösteri"
  },
  {
    "x": "nawitox (n)",
    "y": " gösterge"
  },
  {
    "x": "nawîyayene-I",
    "y": " gösterilmek"
  },
  {
    "x": "nawîyayene-II",
    "y": " b. nîyawayene"
  },
  {
    "x": "nawîyayîş-I (n)",
    "y": " 1)gösterilme\r\n~2)(sn) gösterim, seans\r\n~nawîyayîşê fîlmî: film gösterimi"
  },
  {
    "x": "nawîyayîşê fîlmî",
    "y": " film gösterimi"
  },
  {
    "x": "nawîyayîş-II (n)",
    "y": " b. nîyawayîş (n)"
  },
  {
    "x": "nawnayene",
    "y": " göstermek"
  },
  {
    "x": "nawnayîş (n)",
    "y": " 1)gösterme\r\n~2)gösteri"
  },
  {
    "x": "nawtiş (n)",
    "y": " b. nawitiş (n)"
  },
  {
    "x": "naxir (n)",
    "y": " sığır sürüsü"
  },
  {
    "x": "nay (m, z)",
    "y": " b. naye (m, z)"
  },
  {
    "x": "naye-I (m, z)",
    "y": " buna, bunu"
  },
  {
    "x": "naye ra teber",
    "y": " ayrıca"
  },
  {
    "x": "naye-II (m)",
    "y": " b. nahîye (m)"
  },
  {
    "x": "nayel (m)",
    "y": " b. nale-I (m) "
  },
  {
    "x": "nayene xo ser",
    "y": " b. xo ser nayene"
  },
  {
    "x": "nayi (m, z)",
    "y": " b. naye (m, z)"
  },
  {
    "x": "nayîli (m)",
    "y": " b. nale-I (m)"
  },
  {
    "x": "nayîm (n)",
    "y": " b. nohêm (n)"
  },
  {
    "x": "nayîm-doyîm",
    "y": " b. nohêm-ohêm"
  },
  {
    "x": "nayîne (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "naylo (n)",
    "y": " b. naylon (n)"
  },
  {
    "x": "naylon (n)",
    "y": " naylon "
  },
  {
    "x": "naylu (n)",
    "y": " b. naylon (n)"
  },
  {
    "x": "naylun (n)",
    "y": " b. naylon (n)"
  },
  {
    "x": "naym (n)",
    "y": " b. nohêm (n)"
  },
  {
    "x": "naym-doym",
    "y": " b. nohêm-ohêm"
  },
  {
    "x": "nayno (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "naynon (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "nayşt (n)",
    "y": " b. nat (n)"
  },
  {
    "x": "nayşta-dayşta",
    "y": " b. nata-weta"
  },
  {
    "x": "nayşta-yayşta",
    "y": " b. nata-weta"
  },
  {
    "x": "nayştên (n)",
    "y": " b. natên (n)"
  },
  {
    "x": "naz (m)",
    "y": " b. nacnîye (m)¸ nacnî (m)"
  },
  {
    "x": "naza (n)",
    "y": " b. noca (n)"
  },
  {
    "x": "nazarî, -ye",
    "y": " b. nezerî, -ye"
  },
  {
    "x": "nazarîye (m)",
    "y": " b. nezerîye (m)"
  },
  {
    "x": "nazbalî (m)",
    "y": " b. nazbalîve (m)"
  },
  {
    "x": "nazbalîfe (m)",
    "y": " b. nazbalîve (m)"
  },
  {
    "x": "nazbalîve (m)",
    "y": " köşe yastığı"
  },
  {
    "x": "nazer (n)",
    "y": " b. nezer (n)"
  },
  {
    "x": "nazer bîyayene",
    "y": " b. nezer bîyene"
  },
  {
    "x": "nazer kerdene",
    "y": " b. nezer kerdene"
  },
  {
    "x": "nazim (n)",
    "y": " nâzım (edebiyatta)"
  },
  {
    "x": "nazîk, -e",
    "y": " b. nazik, -e"
  },
  {
    "x": "nazik, -e",
    "y": " 1)nazik\r\n~2)canı tatlı"
  },
  {
    "x": "nazikey (m)",
    "y": " b. nazikîye (m), nazikî (m)"
  },
  {
    "x": "nazikê (m)",
    "y": " b. nazikîye (m), nazikî (m)"
  },
  {
    "x": "nazikî (m)",
    "y": " naziklik"
  },
  {
    "x": "nazikîye (m)",
    "y": " naziklik"
  },
  {
    "x": "nazname (n)",
    "y": " takma ad, mahlas"
  },
  {
    "x": "nazo (n)",
    "y": " halay (serçe parmakla tutuşarak oynanan)"
  },
  {
    "x": "Ncamena (m)",
    "y": " N’Djamena"
  },
  {
    "x": "ne ... ne ...-I",
    "y": " ne ... ne …\r\n~Ne wendo ne nuşto. (Ne okumuş, ne yazmış.)"
  },
  {
    "x": "ne ... ne ...-II",
    "y": " b. nê ... nê ...-I"
  },
  {
    "x": "nebalix, -e",
    "y": " (kişi) ergen olmayan, ergenleşmemiş"
  },
  {
    "x": "nebalixey (m)",
    "y": " b. nebalixîye (m)"
  },
  {
    "x": "nebalixê (m)",
    "y": " b. nebalixî (m)"
  },
  {
    "x": "nebalixi (m)",
    "y": " b. nebalixe(m)"
  },
  {
    "x": "nebalixî (m)",
    "y": " ergen olmama durumu, ergenleşmeme durumu"
  },
  {
    "x": "nebalixîye (m)",
    "y": " ergen olmama durumu, ergenleşmeme durumu"
  },
  {
    "x": "nebaş, -e",
    "y": " kötü, fena"
  },
  {
    "x": "nebaşî (m)",
    "y": " kötülük, fenalık"
  },
  {
    "x": "nebaşîye (m)",
    "y": " kötülük, fenalık"
  },
  {
    "x": "nebat (n)",
    "y": " bitki, nebat"
  },
  {
    "x": "nebatkuj, -e",
    "y": " bitki öldürücü"
  },
  {
    "x": "neberdbar, -e",
    "y": " taşınmaz, gayrimenkul"
  },
  {
    "x": "nebere (m)",
    "y": " b. nûbere (m)"
  },
  {
    "x": "nebes, -e",
    "y": " yetersiz"
  },
  {
    "x": "nebesî (m)",
    "y": " yetmezlik"
  },
  {
    "x": "nebesîya azayî (m)",
    "y": " organ yetmezliği"
  },
  {
    "x": "nebesîya banderbîyayîşê zihnî (m)",
    "y": " zihinsel öğrenme yetersizliği"
  },
  {
    "x": "nebesîya musayîşê mentalî (m)",
    "y": " zihinsel öğrenme yetersizliği"
  },
  {
    "x": "nebesîya organî (m)",
    "y": " organ yetmezliği"
  },
  {
    "x": "nebesîye (m)",
    "y": " yetersizlik, yetmezlik"
  },
  {
    "x": "nebextewer, -e",
    "y": " mutsuz"
  },
  {
    "x": "nebextewerî (m)",
    "y": " mutsuzluk"
  },
  {
    "x": "nebextewerîye (m)",
    "y": " mutsuzluk"
  },
  {
    "x": "nebextîyar, -e",
    "y": " mutsuz"
  },
  {
    "x": "nebextîyarî (m)",
    "y": " mutsuzluk"
  },
  {
    "x": "nebextîyarîye (m)",
    "y": " mutsuzluk"
  },
  {
    "x": "nebîyes",
    "y": " b. newês"
  },
  {
    "x": "nebyes",
    "y": " b. newês"
  },
  {
    "x": "necar, -e",
    "y": " marangoz"
  },
  {
    "x": "necarê dare (n)",
    "y": " dip kurdu"
  },
  {
    "x": "necarîye (m)",
    "y": " marangozluk "
  },
  {
    "x": "necax (n)",
    "y": " 1)baltacık, nacak (küçük balta)\r\n~2)kesici tarafı yarım daire biçiminde olan bir savaş aleti"
  },
  {
    "x": "necaynayene",
    "y": " b. nijnayene"
  },
  {
    "x": "necidî, -ye",
    "y": " ciddiyetsiz, gayriciddi"
  },
  {
    "x": "necir (n)",
    "y": " b. nejir (n)"
  },
  {
    "x": "necirnaya (m)",
    "y": " b. nejirnaya (m) "
  },
  {
    "x": "necirnaye (n)",
    "y": " b. nejirnaye (n)"
  },
  {
    "x": "necirnayene",
    "y": " b. nejirnayene"
  },
  {
    "x": "necirnayîş (n)",
    "y": " b. nejirnayîş (n)"
  },
  {
    "x": "necirnayîye (m)",
    "y": " b. nejirnayîye (m)"
  },
  {
    "x": "necis (n)",
    "y": " insan dışkısı, bok"
  },
  {
    "x": "necîb, -e",
    "y": " soylu"
  },
  {
    "x": "necîbîye (m)",
    "y": " soyluluk"
  },
  {
    "x": "neçar, -e",
    "y": " 1)zavallı\r\n~2)(mecazi) yoksul, fakir"
  },
  {
    "x": "neçar bîyene",
    "y": " acze düşmek"
  },
  {
    "x": "neçarey (m)",
    "y": " b. neçarîye (m)"
  },
  {
    "x": "neçarênî (m)",
    "y": " zavallılık"
  },
  {
    "x": "neçarî (m)",
    "y": " zavallılık"
  },
  {
    "x": "neçarîye (m)",
    "y": " zavallılık"
  },
  {
    "x": "neçîr (n)",
    "y": " b. nêçîr (n)"
  },
  {
    "x": "neçîrvan, -e",
    "y": " b. nêçîrvan, -e"
  },
  {
    "x": "nedaîmî, -ye",
    "y": " 1)devamsız, süreksiz\r\n~2)devamsızlık, süreksizlik"
  },
  {
    "x": "nedîyar, -e",
    "y": " belirtisiz, belgisiz"
  },
  {
    "x": "neexlaqî, -ye",
    "y": " gayriahlaki"
  },
  {
    "x": "nef (n)",
    "y": " çıkar, menfaat, yarar"
  },
  {
    "x": "nefele (m)",
    "y": " yabanyoncası, yabaniyonca, üçgül, tirfil"
  },
  {
    "x": "nefeli (m)",
    "y": " b. nefele (m)"
  },
  {
    "x": "nefer (n)",
    "y": " er"
  },
  {
    "x": "nefeqa (m) (huq.)",
    "y": " nafaka"
  },
  {
    "x": "nefes (n)",
    "y": " 1)nefes, soluk\r\n~2)nazar"
  },
  {
    "x": "nefes birîyayene",
    "y": " nefesi kesilmek, nefesi tıkanmak, nefesi tutulmak, soluğu kesilmek, soluğu tıkanmak"
  },
  {
    "x": "nefes dayene",
    "y": " nefes vermek, soluk vermek"
  },
  {
    "x": "nefes girewtene",
    "y": " nefes almak, soluk almak"
  },
  {
    "x": "nefes girotene",
    "y": " b. nefes girewtene"
  },
  {
    "x": "nefes gîrîyayene",
    "y": " nefesi kesilmek, nefesi tıkanmak, nefesi tutulmak, soluğu kesilmek, soluğu tıkanmak"
  },
  {
    "x": "nefes guretene",
    "y": " b. nefes girewtene"
  },
  {
    "x": "nefes peysayene",
    "y": " nefesi kesilmek, nefesi tıkanmak, nefesi tutulmak, soluğu kesilmek, soluğu tıkanmak"
  },
  {
    "x": "nefes piro peysayene",
    "y": " nefesi kesilmek, nefesi tıkanmak, nefesi tutulmak, soluğu kesilmek, soluğu tıkanmak"
  },
  {
    "x": "nefes ro ... cêrîyayene",
    "y": " b. nefes ro ... gîrîyayene"
  },
  {
    "x": "nefes ro ... gîrîyayene",
    "y": " nefesi kesilmek, nefesi tıkanmak, nefesi tutulmak, soluğu kesilmek, soluğu tıkanmak"
  },
  {
    "x": "nefes bîyayene",
    "y": " b. nefes bîyene"
  },
  {
    "x": "nefes bîyene",
    "y": " nazar değmek, göz değmek, nazara gelmek"
  },
  {
    "x": "nefes kerdene",
    "y": " nazar etmek"
  },
  {
    "x": "nefesgirewtiş (n)",
    "y": " solunum, teneffüs"
  },
  {
    "x": "nefet (n)",
    "y": " çıkar, menfaat, yarar"
  },
  {
    "x": "nefilîyayîne",
    "y": " b. nefirîyayene "
  },
  {
    "x": "nefilîyayîş (n)",
    "y": " b. nefirîyayîş (n)"
  },
  {
    "x": "nefilyayîş (n)",
    "y": " b. nefirîyayîş (n)"
  },
  {
    "x": "nefir (n)",
    "y": " burkulma (el, ayak vb. için)"
  },
  {
    "x": "nefirîyayene",
    "y": " burkulmak (el, ayak vb. için)"
  },
  {
    "x": "nefirîyayîş (n)",
    "y": " burkulma (el, ayak vb. için)"
  },
  {
    "x": "nefirîyayîşê destî",
    "y": " el burkulması"
  },
  {
    "x": "nefirîyayîşê linge",
    "y": " ayak burkulması"
  },
  {
    "x": "nefis (n)",
    "y": " b. nefs (n)"
  },
  {
    "x": "nefî (n)",
    "y": " sürgün"
  },
  {
    "x": "nefî bîyayîne",
    "y": " b. nefî bîyene"
  },
  {
    "x": "nefî bîyene",
    "y": " sürgün edilmek"
  },
  {
    "x": "nefî kerdene",
    "y": " sürgün etmek"
  },
  {
    "x": "nefli (m)",
    "y": " b. nefele (m)"
  },
  {
    "x": "nefret (n)",
    "y": " nefret, ikrah, tiksinme, tiksinti"
  },
  {
    "x": "nefret kerdene",
    "y": " nefret etmek, ikrah etmek, tiksinmek"
  },
  {
    "x": "nefretkerdiş (n)",
    "y": " nefret etme"
  },
  {
    "x": "nefs (n)",
    "y": " nefis"
  },
  {
    "x": "nefsist, -e",
    "y": " b. nefssist, -e"
  },
  {
    "x": "nefsqic, -e",
    "y": " b. nefsqij, -e"
  },
  {
    "x": "nefsqicê (m)",
    "y": " b. nefsqijî (m)"
  },
  {
    "x": "nefsqicî (m)",
    "y": " b. nefsqijî (m)"
  },
  {
    "x": "nefsqij, -e",
    "y": " alçakgönüllü, mütevazı"
  },
  {
    "x": "nefsqijey (m)",
    "y": " nefsqijîye (m)"
  },
  {
    "x": "nefsqijî (m)",
    "y": " alçakgönüllülük,"
  },
  {
    "x": "nefsqijîye (m)",
    "y": " alçakgönüllülük, mütevazılık"
  },
  {
    "x": "nefspêt, -e",
    "y": " nefsine hâkim"
  },
  {
    "x": "nefssist, -e",
    "y": " nefsine hâkim olamayan, ekti (her yiyeceği canı çeken)"
  },
  {
    "x": "nefssisti (m)",
    "y": " b. nefssiste (m)"
  },
  {
    "x": "nefstenik, -e",
    "y": " nefsine hâkim olamayan, ekti (her yiyeceği canı çeken)"
  },
  {
    "x": "negatîf, -e",
    "y": " olumsuz"
  },
  {
    "x": "negi (n)",
    "y": " b. nenge (m)"
  },
  {
    "x": "negî çînayeni",
    "y": " b. nengî çînayene"
  },
  {
    "x": "negirîng, -e",
    "y": " önemsiz"
  },
  {
    "x": "negîçînayîş (n)",
    "y": " b. nengîçînayîş (n)"
  },
  {
    "x": "neha-I (m)",
    "y": " nohut"
  },
  {
    "x": "nehaya pirênaya (m)",
    "y": " kavrulmuş nohut, leblebi"
  },
  {
    "x": "nehaya pirênayîye (m)",
    "y": " kavrulmuş nohut, leblebi"
  },
  {
    "x": "nehaya sîyaye (m)",
    "y": " kuruyunca siyahlaşan bir çeşit nohut"
  },
  {
    "x": "nehaya tirênaya (m)",
    "y": " kavrulmuş nohut, leblebi"
  },
  {
    "x": "nehaya tirênayîye (m)",
    "y": " kavrulmuş nohut, leblebi"
  },
  {
    "x": "neha-II (n)",
    "y": " şey\r\n~Nehayî bîyare. (Şeyi getir.)"
  },
  {
    "x": "nehe (m)",
    "y": " b. neha-I (m), nuke (m)"
  },
  {
    "x": "nehemdem, -e",
    "y": " çağdışı"
  },
  {
    "x": "nehemdemî (m)",
    "y": " çağdışılık"
  },
  {
    "x": "nehemdemîye (m)",
    "y": " çağdışılık"
  },
  {
    "x": "neheq, -e",
    "y": " haksız"
  },
  {
    "x": "neheqênî (m)",
    "y": " gadir, kıygı, haksızlık"
  },
  {
    "x": "neheqênî kerdene",
    "y": " gadretmek, haksızlık etmek"
  },
  {
    "x": "neheqi (m)",
    "y": " b. neheqe (m)"
  },
  {
    "x": "neheqî (m)",
    "y": " gadir, kıygı, haksızlık"
  },
  {
    "x": "neheqî kerdene",
    "y": " gadretmek, haksızlık etmek"
  },
  {
    "x": "neheqîye (m)",
    "y": " gadir, kıygı, haksızlık"
  },
  {
    "x": "neheqîye kerdene",
    "y": " gadretmek, haksızlık etmek"
  },
  {
    "x": "nehertim",
    "y": " devamsız, süreksiz"
  },
  {
    "x": "nehertimî (m)",
    "y": " devamsızlık, süreksizlik"
  },
  {
    "x": "nehey (m)",
    "y": " b. neha-I (m), nuke (m)"
  },
  {
    "x": "neheya sîyay (m)",
    "y": " b. nehaya sîyaye (m)"
  },
  {
    "x": "nehê (m)",
    "y": " b. neha-I (m), nuke (m)"
  },
  {
    "x": "nehişmend, -e",
    "y": " bilinçsiz, şuursuz"
  },
  {
    "x": "nehişmendane",
    "y": " bilinçsizce, şuursuzca"
  },
  {
    "x": "nehişmendîyî (m)",
    "y": " bilinçsizlik, şuursuzluk"
  },
  {
    "x": "nehişmendîyîye (m)",
    "y": " bilinçsizlik, şuursuzluk"
  },
  {
    "x": "nehîye (m)",
    "y": " b. nahîye (m)"
  },
  {
    "x": "nehne (n)",
    "y": " b. nane-II (n)"
  },
  {
    "x": "nehşî, -ye",
    "y": " b. naşî, -ye"
  },
  {
    "x": "nej (m)",
    "y": " b. nacnîye (m), nacnî (m)"
  },
  {
    "x": "nejdî, -ye",
    "y": " b. nêzdî, -ye"
  },
  {
    "x": "nejir (n)",
    "y": " işleme"
  },
  {
    "x": "nejirnaya (m)",
    "y": " işlemeli, nakışlı"
  },
  {
    "x": "nejirnaye (n)",
    "y": " işlemeli, nakışlı"
  },
  {
    "x": "nejirnayene",
    "y": " işlemek, nakışlamak, nakış yapmak"
  },
  {
    "x": "nejirnayîş (n)",
    "y": " işleme (işlemek eylemi), nakışlama, nakış yapma, dikişle süsleme"
  },
  {
    "x": "nejirnayîye (m)",
    "y": " işlemeli, nakışlı"
  },
  {
    "x": "nek (n)",
    "y": " diş"
  },
  {
    "x": "nekê xo qîç kerdene",
    "y": " ağzı açık kalıp dişlerini göstermek"
  },
  {
    "x": "nekê xo qîçkerdiş",
    "y": " ağzı açık kalıp dişlerini gösterme"
  },
  {
    "x": "nekê xwi qîç kerdene",
    "y": " b. nekê xo qîç kerdene"
  },
  {
    "x": "nekê xwi qîçkerdiş",
    "y": " b. nekê xo qîçkerdiş"
  },
  {
    "x": "neka",
    "y": " b. nika"
  },
  {
    "x": "nekay",
    "y": " b. nika"
  },
  {
    "x": "nekeh (n)",
    "y": " b. nîkih (n)"
  },
  {
    "x": "nekeh betal bîyayene",
    "y": " b. nîkih betal bîyene"
  },
  {
    "x": "nekeh betal kerdene",
    "y": " b. nîkih betal kerdene"
  },
  {
    "x": "neki",
    "y": " b. nêke"
  },
  {
    "x": "nekiriştbar, -e",
    "y": " taşınmaz, gayrimenkul"
  },
  {
    "x": "nekîdebîyayîş (n)",
    "y": " bademcik şişmesi"
  },
  {
    "x": "nekîdekewtiş (n)",
    "y": " bademcik şişmesi"
  },
  {
    "x": "nekme (n)",
    "y": " nem, rutubet, gönen"
  },
  {
    "x": "nekme girewtene",
    "y": " nemlenmek, rutubetlenmek"
  },
  {
    "x": "nekmepeym (n)",
    "y": " nemölçer, higrometre"
  },
  {
    "x": "nekmeyin, -e",
    "y": " nemli, rutubetli"
  },
  {
    "x": "nekmeyinîye (m)",
    "y": " nemlilik, rutubetlilik"
  },
  {
    "x": "neks (m)",
    "y": " b. nekse (m)"
  },
  {
    "x": "nekse (m)",
    "y": " şiddetli sırt veya bel ağrısı "
  },
  {
    "x": "neksi (m)",
    "y": " b. nekse (m)"
  },
  {
    "x": "nektarîne (m)",
    "y": " nektarin"
  },
  {
    "x": "nektarînêre (m)",
    "y": " nektarin ağacı"
  },
  {
    "x": "nekuk (n)",
    "y": " b. nenûg (n)"
  },
  {
    "x": "nem (n)",
    "y": " nem, rutubet, gönen"
  },
  {
    "x": "nem girewtene",
    "y": " nemlenmek, rutubetlenmek"
  },
  {
    "x": "nemac (n)",
    "y": " b. nimaj (n)"
  },
  {
    "x": "nemacî",
    "y": " b. nimajê "
  },
  {
    "x": "nemaj (n)",
    "y": " b. nimaj (n)"
  },
  {
    "x": "nemajî",
    "y": " b. nimajê "
  },
  {
    "x": "nemaz (n)",
    "y": " b. nimaj (n) "
  },
  {
    "x": "nememnun, -e",
    "y": " hoşnutsuz"
  },
  {
    "x": "nememnuney (m)",
    "y": " b. nememnunî (m), nememnunîye (m)"
  },
  {
    "x": "nememnunê (m)",
    "y": " b. nememnunî (m), nememnunîye (m)"
  },
  {
    "x": "nememnunî (m)",
    "y": " hoşnutsuzluk"
  },
  {
    "x": "nememnunîye (m)",
    "y": " hoşnutsuzluk"
  },
  {
    "x": "nememnunîyet (n)",
    "y": " hoşnutsuzluk"
  },
  {
    "x": "nemerkezî (m)",
    "y": " ademimerkeziyet, yerinden yönetim"
  },
  {
    "x": "nemerkezîtî (m)",
    "y": " ademimerkeziyetçilik, yerinden yönetimcilik"
  },
  {
    "x": "nemerkezîyî (m)",
    "y": " ademimerkeziyetçilik, yerinden yönetimcilik"
  },
  {
    "x": "nemeşrû",
    "y": " gayri meşru"
  },
  {
    "x": "nemin -e",
    "y": " nemli, rutubetli"
  },
  {
    "x": "neminê (m)",
    "y": " b. neminîye (m)"
  },
  {
    "x": "neminîye (m)",
    "y": " nemlilik, rutubetlilik"
  },
  {
    "x": "nemir, -i",
    "y": " b. nerm, -e"
  },
  {
    "x": "nemnek, -e",
    "y": " nemli, rutubetli"
  },
  {
    "x": "nemnekîye (m)",
    "y": " nemlilik, rutubetlilik"
  },
  {
    "x": "nemodern, -e",
    "y": " çağdışı"
  },
  {
    "x": "nemodernî (m)",
    "y": " çağdışılık"
  },
  {
    "x": "nemodernîye (m)",
    "y": " çağdışılık"
  },
  {
    "x": "nemr, -i",
    "y": " b. nerm, -e"
  },
  {
    "x": "nemr bîyayene",
    "y": " b. nerm bîyene"
  },
  {
    "x": "nemr kerdene",
    "y": " b. nerm kerdene"
  },
  {
    "x": "nemrbîyayîş (n)",
    "y": " b. nermbîyayîş (n)"
  },
  {
    "x": "nemrkerdiş (n)",
    "y": " b. nermkerdiş (n)"
  },
  {
    "x": "nems (n)",
    "y": " b. nefs (n)"
  },
  {
    "x": "nemuwafiq, -e",
    "y": " bağdaşmaz"
  },
  {
    "x": "nene (n)",
    "y": " b. nane-II (n)"
  },
  {
    "x": "neng (m)",
    "y": " b. nenge (m)"
  },
  {
    "x": "neng çînayîne",
    "y": " b. nengî çînayene"
  },
  {
    "x": "neng û nengaşuer",
    "y": " b. neng û nengşore"
  },
  {
    "x": "neng û nengaşur",
    "y": " b. neng û nengşore"
  },
  {
    "x": "neng û nengaşur çînayene",
    "y": " b. neng û nengşore çînayene"
  },
  {
    "x": "neng û nengaşur çînayîş",
    "y": " b. neng û nengşore çînayîş"
  },
  {
    "x": "neng û nengşore",
    "y": " sövgü, küfür"
  },
  {
    "x": "neng û nengşore çînayene",
    "y": " sövüp saymak\r\n~ Ey, neng û nengşore mêrikî çînay. (O, adama sövüp saydı.)"
  },
  {
    "x": "neng û nengşore çînayîş",
    "y": " sövüp sayma"
  },
  {
    "x": "neng û nengşori çînayene",
    "y": " b. neng û nengşore çînayene"
  },
  {
    "x": "neng û nengşori çînayîş",
    "y": " b. neng û nengşore çînayîş"
  },
  {
    "x": "neng û nengşuri",
    "y": " b. neng û nengşore"
  },
  {
    "x": "nengçînayîş (n)",
    "y": " b. nengîçînayîş (n)"
  },
  {
    "x": "nenge (m)",
    "y": " sövgü, küfür"
  },
  {
    "x": "nengî çînayene",
    "y": " sövmek, küfretmek\r\n~Ey nengî şima çînay. / Ey şima ra nengî çînay. (O size sövdü.)"
  },
  {
    "x": "nengi (m)",
    "y": " b. nenge (m)"
  },
  {
    "x": "nengîçîn, -e",
    "y": " küfürbaz, ağzı bozuk, ağzı pis"
  },
  {
    "x": "nengîçînayîş (n)",
    "y": " sövme, küfretme"
  },
  {
    "x": "nengu (n)",
    "y": " b. nenûg (n)"
  },
  {
    "x": "nengû (n)",
    "y": " b. nenûg (n) "
  },
  {
    "x": "nenig (n)",
    "y": " b. nenûg (n)"
  },
  {
    "x": "nenik (n)",
    "y": " b. nenûg (n)"
  },
  {
    "x": "nenik (m)",
    "y": " b. neynike (m)"
  },
  {
    "x": "nenormal, -e",
    "y": " anormal"
  },
  {
    "x": "nenormalîye (m)",
    "y": " anormallik"
  },
  {
    "x": "nenug (n)",
    "y": " b. nenûg (n)"
  },
  {
    "x": "nenûg (n)",
    "y": " tırnak"
  },
  {
    "x": "Neolîtîk (n)",
    "y": " Cilalı Taş Çağı, Cilalı Taş Devri (M. Ö. 9000-5500)"
  },
  {
    "x": "neo-realîzm (n)",
    "y": " yeni gerçekçilik, yeni realizm"
  },
  {
    "x": "Nepal (n)",
    "y": " Nepal"
  },
  {
    "x": "nepexîyayene",
    "y": " b. nepixîyayene"
  },
  {
    "x": "nepexnayene",
    "y": " b. nepixnayene"
  },
  {
    "x": "nepixîyayene",
    "y": " şişmek"
  },
  {
    "x": "nepixnayene",
    "y": " şişirmek"
  },
  {
    "x": "nepolîtîk, -e",
    "y": " apolitik"
  },
  {
    "x": "neq, -e",
    "y": " b. neheq, -e"
  },
  {
    "x": "neqeb (n)",
    "y": " 1)geçit, derbent, boğaz\r\n~2)çit kapısı, çeper kapısı"
  },
  {
    "x": "neqernayene",
    "y": " b. neqirnayene"
  },
  {
    "x": "neqernayîş (n)",
    "y": " b. neqirnayîş (n)"
  },
  {
    "x": "neqernayox, -e",
    "y": " b. neqirnayox, -e"
  },
  {
    "x": "neqernayoxî (m)",
    "y": " b. neqirnayoxî (m), neqirnayoxîye (m)"
  },
  {
    "x": "neqeskerde (n)",
    "y": " b. neqişkerde (n)"
  },
  {
    "x": "neqeskerdîye (m)",
    "y": " b. neqişkerdîye (m)"
  },
  {
    "x": "neqesnaye (n)",
    "y": " b. neqişnaye (n)"
  },
  {
    "x": "neqesnayene",
    "y": " b. neqişnayene"
  },
  {
    "x": "neqesnayêne",
    "y": " b. neqişnayene"
  },
  {
    "x": "neqesnayîs",
    "y": " b. neqişnayîş (n)"
  },
  {
    "x": "neqesnayîş (n)",
    "y": " b. neqişnayîş (n)"
  },
  {
    "x": "neqesnayîye (m)",
    "y": " b. neqişnayî (m), neqişnayîye (m)"
  },
  {
    "x": "neqeşnayene",
    "y": " b. neqişnayene"
  },
  {
    "x": "neqeşnayêne",
    "y": " b. neqişnayene"
  },
  {
    "x": "neqeşnayîş (n)",
    "y": " b. neqişnayîş (n)"
  },
  {
    "x": "neqeşnayîye (m)",
    "y": " b. neqişnayîye (m)"
  },
  {
    "x": "neqev (n)",
    "y": " b. neqeb (n)"
  },
  {
    "x": "neqew (n)",
    "y": " b. neqeb (n)"
  },
  {
    "x": "neqên-I (m)",
    "y": " b. neheqênî (m)"
  },
  {
    "x": "neqên-II (n)",
    "y": " b. neheqênî (m)"
  },
  {
    "x": "neqên kerdene",
    "y": " b. neheqênî kerdene"
  },
  {
    "x": "neqênî (n)",
    "y": " b. neheqênî (m)"
  },
  {
    "x": "neqênî kerdene",
    "y": " b. neheqênî kerdene"
  },
  {
    "x": "neqêskerde (n)",
    "y": " b. neqişkerde (n)"
  },
  {
    "x": "neqêskerdîye (m)",
    "y": " b. neqişkerdîye (m)"
  },
  {
    "x": "neqêsnaye (n)",
    "y": " b. neqişnaye (n)"
  },
  {
    "x": "neqêsnayîye (m)",
    "y": " b. neqişnayî (m), neqişnayîye (m)"
  },
  {
    "x": "neqil (m)",
    "y": " b. neqre (m)"
  },
  {
    "x": "neqile (m)",
    "y": " kez, kere, defa, sefer"
  },
  {
    "x": "neqilê",
    "y": " bir kez"
  },
  {
    "x": "neqilêk",
    "y": " bir kez"
  },
  {
    "x": "neqilna",
    "y": " yine, gene, tekrar"
  },
  {
    "x": "neqirnayene",
    "y": " oymak (taş vb. için)"
  },
  {
    "x": "neqirnayîş (n)",
    "y": " oyma (taş vb. için)"
  },
  {
    "x": "neqirnayox, -e",
    "y": " oymacı (taş vb. için)"
  },
  {
    "x": "neqirnayoxî (m)",
    "y": " oymacılık"
  },
  {
    "x": "neqirnayoxîye (m)",
    "y": " oymacılık"
  },
  {
    "x": "neqis (n)",
    "y": " b. neqiş (n)"
  },
  {
    "x": "neqis kerdene",
    "y": " b. neqiş kerdene"
  },
  {
    "x": "neqisin (n)",
    "y": " b. neqişin, -e"
  },
  {
    "x": "neqiskerde (n)",
    "y": " b. neqişkerde (n)"
  },
  {
    "x": "neqiskerdiş (n)",
    "y": " b. neqişkerdiş (n)"
  },
  {
    "x": "neqiskerdîye (m)",
    "y": " b. neqişkerdîye (m)"
  },
  {
    "x": "neqisnaye (n)",
    "y": " b. neqişnaye (n)"
  },
  {
    "x": "neqisnayene",
    "y": " b. neqişnayene"
  },
  {
    "x": "neqisnayêne",
    "y": " b. neqişnayene"
  },
  {
    "x": "neqisnayîş (n)",
    "y": " b. neqişnayîş (n)"
  },
  {
    "x": "neqisnayîye (m)",
    "y": " b. neqişnayî (m), neqişnayîye (m)"
  },
  {
    "x": "neqisnayîye (m)",
    "y": " b. neqişnayîye (m)"
  },
  {
    "x": "neqiş (n)",
    "y": " işleme, nakış"
  },
  {
    "x": "neqiş kerdene",
    "y": " nakış işlemek"
  },
  {
    "x": "neqişên, -e",
    "y": " b. neqişin, -e"
  },
  {
    "x": "neqişin, -e",
    "y": " işlemeli, nakışlı"
  },
  {
    "x": "neqişîn, -e",
    "y": " b. neqişin, -e"
  },
  {
    "x": "neqişkarda (m)",
    "y": " b. neqişkerda (m)"
  },
  {
    "x": "neqişkarde (m)",
    "y": " b. neqişkerde (n)"
  },
  {
    "x": "neqişker, -e",
    "y": " nakışçı"
  },
  {
    "x": "neqişkerda (m)",
    "y": " işlemeli, nakışlı"
  },
  {
    "x": "neqişkerde (n)",
    "y": " işlemeli, nakışlı"
  },
  {
    "x": "neqişkerdi (n)",
    "y": " b. neqişkerde (n)"
  },
  {
    "x": "neqişkerdiş (n)",
    "y": " nakış işleme"
  },
  {
    "x": "neqişkerdî (m)",
    "y": " işlemeli, nakışlı"
  },
  {
    "x": "neqişkerdîye (m)",
    "y": " işlemeli, nakışlı"
  },
  {
    "x": "neqişnaya (m)",
    "y": " işlemeli, nakışlı"
  },
  {
    "x": "neqişnaye (n)",
    "y": " işlemeli, nakışlı"
  },
  {
    "x": "neqişnayene",
    "y": " işlemek, nakışlamak, nakış yapmak"
  },
  {
    "x": "neqişnayi (n)",
    "y": " b. neqişnaye (n)"
  },
  {
    "x": "neqişnayî (m)",
    "y": " işlemeli, nakışlı"
  },
  {
    "x": "neqişnayîş (n)",
    "y": " 1)işleme (işlemek eylemi), nakışlama, nakış yapma\r\n~2)dikişle süsleme"
  },
  {
    "x": "neqişnayîye (m)",
    "y": " işlemeli, nakışlı"
  },
  {
    "x": "neqî (n)",
    "y": " b. neheqî (m), neheqîye (m)"
  },
  {
    "x": "neqî kerdene",
    "y": " b. neheqî kerdene"
  },
  {
    "x": "neqîye (n)",
    "y": " b. neheqîye (m)"
  },
  {
    "x": "neqîye kerdene",
    "y": " b. neheqîye kerdene"
  },
  {
    "x": "neql (n)",
    "y": " aktarım, nakil"
  },
  {
    "x": "neql bîyene",
    "y": " aktarılmak, nakledilmek"
  },
  {
    "x": "neql kerdene",
    "y": " aktarmak, nakletmek; iktibas etmek"
  },
  {
    "x": "neqlbîyayîş (n)",
    "y": " aktarılma, nakledilme, intikal"
  },
  {
    "x": "neqlbîyayîşê mîrasî (n)",
    "y": " mirasın intikali"
  },
  {
    "x": "neqle (m)",
    "y": " b. neqile (m)"
  },
  {
    "x": "neqlê",
    "y": " b. neqilê"
  },
  {
    "x": "neqlêk",
    "y": " b. neqilêk"
  },
  {
    "x": "neqli (m)",
    "y": " b. neqre (m)"
  },
  {
    "x": "neqlikî vero ameyene",
    "y": " hık tutmak"
  },
  {
    "x": "neqlikî vero ameyîne",
    "y": " b. neqlikî vero ameyene"
  },
  {
    "x": "neqlîya (m)",
    "y": " nakliye "
  },
  {
    "x": "neqlîyakar, -e",
    "y": " nakliyeci, taşımacı"
  },
  {
    "x": "neqlîyakarî (m)",
    "y": " nakliyecilik"
  },
  {
    "x": "neqlîyakarîye (m)",
    "y": " nakliyecilik"
  },
  {
    "x": "neqlîyat (n)",
    "y": " nakliyat, taşımacılık"
  },
  {
    "x": "neqlîye (m)",
    "y": " b. neqlîya (m)"
  },
  {
    "x": "neqlîyekar, -e",
    "y": " b. neqlîyakar, -e"
  },
  {
    "x": "neqlîyekarî (m)",
    "y": " b. neqlîyakarî (m)"
  },
  {
    "x": "neqlîyekarîye (m)",
    "y": " b. neqlîyakarîye (m)"
  },
  {
    "x": "neqlkerde (n)",
    "y": " aktarma, iktibas"
  },
  {
    "x": "neqlkerdiş (n)",
    "y": " aktarma, nakletme, nakil"
  },
  {
    "x": "neqlkerdişê dîmenî (sn)",
    "y": " görüntü aktarımı"
  },
  {
    "x": "neqlna",
    "y": " b. neqilna"
  },
  {
    "x": "neqra (m)",
    "y": " davul"
  },
  {
    "x": "neqre (m)",
    "y": " çok büyük kazan"
  },
  {
    "x": "neqs (n)",
    "y": " b. neqiş (n)"
  },
  {
    "x": "neqs kerdene",
    "y": " b. neqiş kerdene"
  },
  {
    "x": "neqsin (n)",
    "y": " b. neqişin, -e"
  },
  {
    "x": "neqskerde (n)",
    "y": " b. neqişkerde (n)"
  },
  {
    "x": "neqskerdiş (n)",
    "y": " b. neqişkerdiş (n)"
  },
  {
    "x": "neqskerdîye (m)",
    "y": " b. neqişkerdîye (m)"
  },
  {
    "x": "neqş (n)",
    "y": " b. neqiş (n)"
  },
  {
    "x": "neqş kerdene",
    "y": " b. neqiş kerdene"
  },
  {
    "x": "neqşên, -e",
    "y": " b. neqişin, -e"
  },
  {
    "x": "neqşi (n)",
    "y": " b. neqiş (n)"
  },
  {
    "x": "neqşin, -e",
    "y": " b. neqişin, -e"
  },
  {
    "x": "neqşîbendî, -ye",
    "y": " Nakşibendi"
  },
  {
    "x": "neqşîbendîtê (m)",
    "y": " b. neqşîbendîtî (m)"
  },
  {
    "x": "neqşîbendîtî (m)",
    "y": " Nakşibendilik"
  },
  {
    "x": "neqşîbendîyey (m)",
    "y": " b. neqşîbendîyîye (m), neqşîbendîyî (m)"
  },
  {
    "x": "neqşîbendîyê (m)",
    "y": " b. neqşîbendîyîye (m), neqşîbendîyî (m)"
  },
  {
    "x": "neqşîbendîyî (m)",
    "y": " Nakşibendilik"
  },
  {
    "x": "neqşîbendîyîye (m)",
    "y": " Nakşibendilik"
  },
  {
    "x": "neqşîn, -e",
    "y": " b. neqişin, -e"
  },
  {
    "x": "neqşkerdiş (n)",
    "y": " b. neqişkerdiş (n)"
  },
  {
    "x": "neqtikî vero ameyene",
    "y": " b. nuqtikî vero ameyene"
  },
  {
    "x": "nequs (n)",
    "y": " çimdik"
  },
  {
    "x": "nequs bîyene",
    "y": " çimdiklenmek, çimdik atılmak"
  },
  {
    "x": "nequs kerdene",
    "y": " çimdiklemek, çimdik atmak, çimdik basmak"
  },
  {
    "x": "nequs tede tadayene",
    "y": " çimdiklemek, çimdik atmak, çimdik basmak"
  },
  {
    "x": "nequs tede tadîyayene",
    "y": " çimdiklenmek, çimdik atılmak"
  },
  {
    "x": "nequsbîyayîş (n)",
    "y": " çimdiklenme"
  },
  {
    "x": "nequsikî (zh)",
    "y": " çimdik"
  },
  {
    "x": "nequsikî tede tadayene",
    "y": " çimdiklemek, çimdik atmak, çimdik basmak"
  },
  {
    "x": "nequsikî tede tadîyayene",
    "y": " çimdiklenmek, çimdik atılmak"
  },
  {
    "x": "nequsikîtedetadayîş (n)",
    "y": " çimdikleme, çimdik atma, çimdik basma"
  },
  {
    "x": "nequsikîtedetadîyayîş (n)",
    "y": " çimdiklenme"
  },
  {
    "x": "nequsî (zh)",
    "y": " çimdik"
  },
  {
    "x": "nequsî bîyene",
    "y": " çimdiklenmek, çimdik atılmak"
  },
  {
    "x": "nequsî kerdene",
    "y": " çimdiklemek, çimdik atmak, çimdik basmak"
  },
  {
    "x": "nequsî tede tadayene",
    "y": " çimdiklemek, çimdik atmak, çimdik basmak"
  },
  {
    "x": "nequsî tede tadîyayene",
    "y": " çimdiklenmek, çimdik atılmak"
  },
  {
    "x": "nequsîbîyayîş (n)",
    "y": " çimdiklenme"
  },
  {
    "x": "nequsîkerdiş (n)",
    "y": " çimdikleme, çimdik atma, çimdik basma"
  },
  {
    "x": "nequsîtedetadayîş (n)",
    "y": " çimdikleme, çimdik atma, çimdik basma"
  },
  {
    "x": "nequsîtedetadîyayîş (n)",
    "y": " çimdiklenme"
  },
  {
    "x": "nequskerdiş (n)",
    "y": " çimdikleme, çimdik atma, çimdik basma"
  },
  {
    "x": "nequsnayene",
    "y": " çimdiklemek"
  },
  {
    "x": "nequsnayîş (n)",
    "y": " çimdikleme"
  },
  {
    "x": "nequstedetadayîş (n)",
    "y": " çimdikleme, çimdik atma, çimdik basma"
  },
  {
    "x": "nequstedetadîyayîş (n)",
    "y": " çimdiklenme"
  },
  {
    "x": "ner (n)",
    "y": " b. nêrî (n)"
  },
  {
    "x": "nerazî, -ye",
    "y": " hoşnutsuz"
  },
  {
    "x": "nerazîyey (m)",
    "y": " b. nerazîyî (m), nerazîyîye (m)"
  },
  {
    "x": "nerazîyê (m)",
    "y": " b. nerazîyî (m), nerazîyîye (m)"
  },
  {
    "x": "nerazîyî (m)",
    "y": " hoşnutsuzluk"
  },
  {
    "x": "nerazîyîye (m)",
    "y": " hoşnutsuzluk"
  },
  {
    "x": "nerb (n)",
    "y": " b. narbe (n)"
  },
  {
    "x": "nerb dayene",
    "y": " b. narbe dayene"
  },
  {
    "x": "nerbe (n)",
    "y": " b. narbe (n)"
  },
  {
    "x": "nerbe dayene",
    "y": " b. narbe dayene"
  },
  {
    "x": "nerbik (n)",
    "y": " b. narbik (n)"
  },
  {
    "x": "nerbik dayene",
    "y": " b. narbik dayene"
  },
  {
    "x": "nerdewoni (m)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nerdgan (n)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nerdigan (n)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nerdiwûn (m)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nerdîwan (n)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nerdîwon (n)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nerdîwûn (n)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nerdugan (n)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nerehet, -e",
    "y": " rahatsız"
  },
  {
    "x": "nerehet bîyene",
    "y": " rahatsız olmak"
  },
  {
    "x": "nerehet kerdene",
    "y": " rahatsız etmek"
  },
  {
    "x": "nerehetbîyayîş (n)",
    "y": " rahatsız olma"
  },
  {
    "x": "nerehetkerdiş (n)",
    "y": " rahatsız etme"
  },
  {
    "x": "nergon (m)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "neri (n)",
    "y": " 1)b. nêrî (n)\r\n~2)b. nerî-II (n)"
  },
  {
    "x": "nerik (n)",
    "y": " b. nêrik (n)"
  },
  {
    "x": "nerî-I (n)",
    "y": " b. nêrî (n)"
  },
  {
    "x": "nerî-II (n)",
    "y": " bızır, klitoris"
  },
  {
    "x": "nerm, -e",
    "y": " yumuşak, ılık"
  },
  {
    "x": "nerm bîyene",
    "y": " yumuşamak, ılıklaşmak"
  },
  {
    "x": "nerm kerdene",
    "y": " yumuşatmak, ılıklaştırmak"
  },
  {
    "x": "nermbîyayîş (n)",
    "y": " yumuşama, ılıklaşma"
  },
  {
    "x": "nermkerdiş (n)",
    "y": " yumuşatma, ılıklaştırma"
  },
  {
    "x": "neru (n)",
    "y": " b. nêrî (n)-2"
  },
  {
    "x": "neruyê vaşî (n)",
    "y": " b. nêrîyê vaşî (n)"
  },
  {
    "x": "nesalîyayene",
    "y": " b. nesilîyayene"
  },
  {
    "x": "nesalîyayîş (n)",
    "y": " b. nesilîyayîş (n)"
  },
  {
    "x": "nesalnayene",
    "y": " b. nesilnayene"
  },
  {
    "x": "nesalnayîş (n)",
    "y": " b. nesilnayîş (n)"
  },
  {
    "x": "neseb (n)",
    "y": " soy"
  },
  {
    "x": "neselîyayene",
    "y": " b. nesilîyayene"
  },
  {
    "x": "neselîyayîş (n)",
    "y": " b. nesilîyayîş (n)"
  },
  {
    "x": "neselnayene",
    "y": " b. nesilnayene"
  },
  {
    "x": "neselnayîş (n)",
    "y": " b. nesilnayîş (n)"
  },
  {
    "x": "nesentralî (m)",
    "y": " ademimerkeziyet, yerinden yönetim"
  },
  {
    "x": "nesentralîtî (m)",
    "y": " ademimerkeziyetçilik, yerinden yönetimcilik"
  },
  {
    "x": "nesentralîyî (m)",
    "y": " ademimerkeziyetçilik, yerinden yönetimcilik"
  },
  {
    "x": "nesil (n)",
    "y": " nesil, soy"
  },
  {
    "x": "nesilîyayene",
    "y": " süzülmek (sıvı için)"
  },
  {
    "x": "nesilîyayîş (n)",
    "y": " süzülme (sıvı için)"
  },
  {
    "x": "nesilnayene",
    "y": " süzmek (sıvı için)"
  },
  {
    "x": "nesilnayîş (n)",
    "y": " süzme (sıvı için)"
  },
  {
    "x": "nesîl (n)",
    "y": " b. nesil (n)"
  },
  {
    "x": "nesîlke (n)",
    "y": " teskere (gereç taşımakta kullanılan)"
  },
  {
    "x": "nesl (n)",
    "y": " b. nesil (n)"
  },
  {
    "x": "nesli (m)",
    "y": " b. nesil (n)"
  },
  {
    "x": "nesosyal, -e",
    "y": " asosyal"
  },
  {
    "x": "nesosyalîye (m)",
    "y": " asosyallik"
  },
  {
    "x": "nesr, -e",
    "y": " nesir "
  },
  {
    "x": "neşa, -ye",
    "y": " neşesiz"
  },
  {
    "x": "neşayî (m)",
    "y": " neşesizlik"
  },
  {
    "x": "neşayîye (m)",
    "y": " neşesizlik"
  },
  {
    "x": "neşên, -e",
    "y": " neşesiz"
  },
  {
    "x": "neşênî (m)",
    "y": " neşesizlik"
  },
  {
    "x": "neşênîye (m)",
    "y": " neşesizlik"
  },
  {
    "x": "neşr (n)",
    "y": " yayım, neşir"
  },
  {
    "x": "neşr bîyene",
    "y": " yayımlanmak, neşredilmek"
  },
  {
    "x": "neşr kerdene",
    "y": " yayımlamak, neşretmek"
  },
  {
    "x": "neşrbîyayîş (n)",
    "y": " yayımlanma, neşredilme"
  },
  {
    "x": "neşrîyat (n)",
    "y": " yayın, neşriyat"
  },
  {
    "x": "neşrkerdiş (n)",
    "y": " yayımlama, neşretme"
  },
  {
    "x": "net, -e",
    "y": " net"
  },
  {
    "x": "netebîî, -ye",
    "y": " anormal, anormallik"
  },
  {
    "x": "netede",
    "y": " hariç"
  },
  {
    "x": "netewe (m)",
    "y": " millet, ulus"
  },
  {
    "x": "Neteweyê Yewbîyayeyî (NY)",
    "y": " Birleşmiş Milletler (BM)"
  },
  {
    "x": "neteweperwer, -e",
    "y": " milliyetçi"
  },
  {
    "x": "neteweyî, -ye",
    "y": " milli, ulusal "
  },
  {
    "x": "netêda",
    "y": " hariç\r\n~Ti netêda her kes amebî. (Sen hariç herkes gelmişti.)"
  },
  {
    "x": "netim",
    "y": " devamsız, süreksiz"
  },
  {
    "x": "netimin,-e",
    "y": " devamsız, süreksiz"
  },
  {
    "x": "netimî (m)",
    "y": " devamsızlık, süreksizlik"
  },
  {
    "x": "netimûtim",
    "y": " devamsız, süreksiz"
  },
  {
    "x": "netimûtimin, -e",
    "y": " devamsız, süreksiz"
  },
  {
    "x": "netimûtimî (m)",
    "y": " devamsızlık, süreksizlik"
  },
  {
    "x": "netî (m)",
    "y": " netlik"
  },
  {
    "x": "netîca (m)",
    "y": " b. netîce (m)"
  },
  {
    "x": "netîce (m, mat)",
    "y": " sonuç, netice\r\n~Netîceya îmtihanî bellî/kifş nêbîya. (Sınavın sonucu belli olmamış.)"
  },
  {
    "x": "netîceya zanistîye (m)",
    "y": " bulgu (bilimsel sonuç)"
  },
  {
    "x": "netîceyê analîzî (zh)",
    "y": " analiz sonuçları"
  },
  {
    "x": "netîceyê kombîyayîşî (zh)",
    "y": " toplantı sonuçları"
  },
  {
    "x": "netîceyê kontrolî (zh)",
    "y": " kontrol sonuçları"
  },
  {
    "x": "netîceyê zanistîyî (zh)",
    "y": " bulgular"
  },
  {
    "x": "netîceyê zanistîyî û şirove",
    "y": " bulgular ve yorum"
  },
  {
    "x": "netîye (m)",
    "y": " netlik"
  },
  {
    "x": "nevengine (m)",
    "y": " sessiz harf, ünsüz harf"
  },
  {
    "x": "herfa nevengine (m)",
    "y": " sessiz harf, ünsüz harf "
  },
  {
    "x": "nevîn (n)",
    "y": " b. nivîne (m)"
  },
  {
    "x": "nevîyartbar, -e",
    "y": " geçersiz"
  },
  {
    "x": "nevîyartbarî (m)",
    "y": " geçersizlik"
  },
  {
    "x": "new",
    "y": " dokuz "
  },
  {
    "x": "new se",
    "y": " b. new sey"
  },
  {
    "x": "new sey",
    "y": " dokuz yüz "
  },
  {
    "x": "newê martî (n)",
    "y": " mart dokuzu (baharın başlangıcı)"
  },
  {
    "x": "newa (m)",
    "y": " yeni"
  },
  {
    "x": "newaê",
    "y": " b. neway"
  },
  {
    "x": "newal (m)",
    "y": " b. newale (m)"
  },
  {
    "x": "newale (m)",
    "y": " vadi, koyak"
  },
  {
    "x": "newali (m)",
    "y": " b. newale (m)"
  },
  {
    "x": "neway",
    "y": " doksan"
  },
  {
    "x": "newayin, -e",
    "y": " doksanıncı "
  },
  {
    "x": "newbe (m)",
    "y": " b. nobe (m)"
  },
  {
    "x": "newbeçî (n, m)",
    "y": " b. nobeçî, -ye"
  },
  {
    "x": "newbeçîyê (m)",
    "y": " b. nobeçîyî (m)"
  },
  {
    "x": "newbedar, -e",
    "y": " b. nobedar, -e"
  },
  {
    "x": "newbedar, -i",
    "y": " b. nobedar, -e"
  },
  {
    "x": "newbedarey (m)",
    "y": " b. nobedarî (m)"
  },
  {
    "x": "newbet (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "newbetçî, -ye",
    "y": " b. nobeçî, -ye"
  },
  {
    "x": "newbetçîyey (m)",
    "y": " b. nobetçîyî (m)"
  },
  {
    "x": "newbetçîyê (m)",
    "y": " b. nobetçîyî (m)"
  },
  {
    "x": "newbeti (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "newbeti reydi",
    "y": " b. nobete reyde"
  },
  {
    "x": "newbeti reyra",
    "y": " b. nobete reyra"
  },
  {
    "x": "newbi (m)",
    "y": " b. nobe (m)"
  },
  {
    "x": "newbi reydi",
    "y": " b. nobe reyde"
  },
  {
    "x": "newbi reyra",
    "y": " b. nobe reyra"
  },
  {
    "x": "newde",
    "y": " b. newede ra"
  },
  {
    "x": "newe (n)",
    "y": " yeni"
  },
  {
    "x": "newe bîyene",
    "y": " yenilenmek"
  },
  {
    "x": "newe kerdene",
    "y": " yenilemek"
  },
  {
    "x": "newe ra",
    "y": " yeniden, baştan (zr), yine, gene, tekrar"
  },
  {
    "x": "newe ra ewnîyayene",
    "y": " gözden geçirmek"
  },
  {
    "x": "newebar, -e",
    "y": " yenilenebilir (yeni duruma getirilebilir)"
  },
  {
    "x": "newebîyayîş (n)",
    "y": " yenilenme"
  },
  {
    "x": "newede",
    "y": " b. newede ra"
  },
  {
    "x": "newede ra",
    "y": " yeniden, yine, gene, tekrar"
  },
  {
    "x": "newedî",
    "y": " b. newede ra"
  },
  {
    "x": "newedî la",
    "y": " b. newede ra"
  },
  {
    "x": "newekerdiş (n)",
    "y": " yenileme"
  },
  {
    "x": "newekerdişo bajarkî (n)",
    "y": " kentsel yenileme"
  },
  {
    "x": "newekerdişo şaristankî (n)",
    "y": " kentsel yenileme"
  },
  {
    "x": "newekî (zh)",
    "y": " dokuztaş, dokurcun [oyunu]"
  },
  {
    "x": "newera dadgehkerdiş",
    "y": " iade-i muhakeme"
  },
  {
    "x": "newera mehkemekerdiş",
    "y": " iade-i muhakeme"
  },
  {
    "x": "neweradestpêkerdişdayîş (n) (TE)",
    "y": " yeniden başlatmak"
  },
  {
    "x": "neweraewnîyayîş (n)",
    "y": " gözden geçirme"
  },
  {
    "x": "neweragurenîyayîşê eşteyî (n)",
    "y": " atığın yeniden işlenmesi"
  },
  {
    "x": "newerna",
    "y": " b. newe ra"
  },
  {
    "x": "newernaya (m)",
    "y": " b. newirnaya (m)"
  },
  {
    "x": "newernaye (n)",
    "y": " b. newirnaye (n)"
  },
  {
    "x": "newernayene",
    "y": " b. newirnayene"
  },
  {
    "x": "newernayîş (n)",
    "y": " b. newirnayîş (n)"
  },
  {
    "x": "newernayîye (m)",
    "y": " b. newirnayîye (m)"
  },
  {
    "x": "newet (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "newete (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "neweti (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "newê-I (m)",
    "y": " b. newa (m), newîye (m)"
  },
  {
    "x": "newê-II",
    "y": " b. neway"
  },
  {
    "x": "newês",
    "y": " on dokuz "
  },
  {
    "x": "newi (n)",
    "y": " b. newe (n)"
  },
  {
    "x": "newi ra",
    "y": " b. newe ra"
  },
  {
    "x": "newide ra",
    "y": " b. newede ra"
  },
  {
    "x": "newike (m)",
    "y": "dokuztaş, dokurcun [oyunu]"
  },
  {
    "x": "newiki (m)",
    "y": " b. newike (m)"
  },
  {
    "x": "newin, -e",
    "y": " dokuzuncu"
  },
  {
    "x": "newine (m)",
    "y": " dokuztaş, dokurcun [oyunu]"
  },
  {
    "x": "newini (m)",
    "y": " b. newine (m) "
  },
  {
    "x": "newir (n)",
    "y": " işleme"
  },
  {
    "x": "newirnaya (m)",
    "y": " işlemeli"
  },
  {
    "x": "newirnaye (n)",
    "y": " işlemeli"
  },
  {
    "x": "newirnayene",
    "y": " 1)işlemek\r\n~2)süslü püslü anlatmak, detaylı anlatmak"
  },
  {
    "x": "newirnayîş (n)",
    "y": " 1)dikişle süsleme, işleme (işlemek eylemi)\r\n~2)süslü püslü anlatma, detaylı anlatma"
  },
  {
    "x": "newirnayîye (m)",
    "y": " b. newirnaya (m)"
  },
  {
    "x": "newî (m)",
    "y": " yeni"
  },
  {
    "x": "newîn",
    "y": " b. winasî, inasar"
  },
  {
    "x": "newîne",
    "y": " b. winasî, inasar"
  },
  {
    "x": "newîye (m)",
    "y": " yeni"
  },
  {
    "x": "newîyes",
    "y": " b. newês"
  },
  {
    "x": "newke",
    "y": " b. nika"
  },
  {
    "x": "newkeyên",
    "y": " b. nikayin"
  },
  {
    "x": "newoke (m)",
    "y": " b. newike (m)"
  },
  {
    "x": "newqe (m)",
    "y": " bel"
  },
  {
    "x": "newqerantiş (sn)",
    "y": " bel çekimi (sn)"
  },
  {
    "x": "newresîme (m)",
    "y": " nevresim"
  },
  {
    "x": "newroz (n)",
    "y": " b. newroze (m)"
  },
  {
    "x": "newroze (m)",
    "y": " nevruz"
  },
  {
    "x": "newsî-I (m)",
    "y": " dokuztaş, dokurcun [oyunu]  "
  },
  {
    "x": "newsî-II (m)",
    "y": " üzerine pestil bulamacı sürülerek pestil yapımı için kullanılan bez parçası"
  },
  {
    "x": "newsîyek (m)",
    "y": " b. newsîyeke (m)"
  },
  {
    "x": "newsîyeke (m)",
    "y": " dokuztaş, dokurcun [oyunu]"
  },
  {
    "x": "newu (n)",
    "y": " b. newe (n)"
  },
  {
    "x": "newu ra",
    "y": " b. newe ra"
  },
  {
    "x": "newuye (m)",
    "y": " b. newa (m), newîye (m)"
  },
  {
    "x": "newyes",
    "y": " b. newês"
  },
  {
    "x": "nexd (n)",
    "y": " döven ile boyunduruk arasındaki direk"
  },
  {
    "x": "nexefîyayene",
    "y": " b. nexifîyayene"
  },
  {
    "x": "nexefnayene",
    "y": " b. nexifnayene"
  },
  {
    "x": "nexeşnayene",
    "y": " b. neqişnayene"
  },
  {
    "x": "nexeşnayîş (n)",
    "y": " b. neqişnayîş (n)"
  },
  {
    "x": "nexeyr (zr)",
    "y": " b. nexêr (zr)"
  },
  {
    "x": "nexêr (zr)",
    "y": " hayır, yok"
  },
  {
    "x": "nexifîyayene",
    "y": " şişmek"
  },
  {
    "x": "nexifnayene",
    "y": " şişirmek"
  },
  {
    "x": "nexişên, -e",
    "y": " b. neqişin, -e"
  },
  {
    "x": "nexişin, -e",
    "y": " b. neqişin, -e"
  },
  {
    "x": "nexişîn, -e",
    "y": " b. neqişin, -e"
  },
  {
    "x": "nexişkerda (m)",
    "y": " b. neqişkerda (m)"
  },
  {
    "x": "nexişkerde (n)",
    "y": " b. neqişkerde (n)"
  },
  {
    "x": "nexişkerdî (m)",
    "y": " b. neqişkerdî (m)"
  },
  {
    "x": "nexişkerdîye (m)",
    "y": " b. neqişkerdîye (m)"
  },
  {
    "x": "nexişnaya (m)",
    "y": " b. neqişnaya (m)"
  },
  {
    "x": "nexişnaye (n)",
    "y": " b. neqişnaye (n)"
  },
  {
    "x": "nexişnayene",
    "y": " b. neqişnayene"
  },
  {
    "x": "nexişnayî (m)",
    "y": " b. neqişnayî (m), neqişnayîye (m)"
  },
  {
    "x": "nexişnayîş (n)",
    "y": " b. neqişnayîş (n)"
  },
  {
    "x": "nexişnayîye (m)",
    "y": " b. neqişnayî (m), neqişnayîye (m)"
  },
  {
    "x": "nexozayî, -ye",
    "y": " anormal, anormallik"
  },
  {
    "x": "nexs (n)",
    "y": " b. neqiş (n)"
  },
  {
    "x": "nexs kerdene",
    "y": " b. neqiş kerdene"
  },
  {
    "x": "nexsin (n)",
    "y": " b. neqişin, -e"
  },
  {
    "x": "nexskerde (n)",
    "y": " b. neqişkerde (n)"
  },
  {
    "x": "nexskerdiş (n)",
    "y": " b. neqişkerdiş (n)"
  },
  {
    "x": "nexskerdîye (m)",
    "y": " b. neqişkerdîye (m)"
  },
  {
    "x": "nexsnayene",
    "y": " b. neqişnayene"
  },
  {
    "x": "nexsnayêne",
    "y": " b. neqişnayene"
  },
  {
    "x": "nexsnayîş (n)",
    "y": " b. neqişnayîş (n)"
  },
  {
    "x": "nexş (n)",
    "y": " b. neqiş (n)"
  },
  {
    "x": "nexş kerdene",
    "y": " b. neqiş kerdene"
  },
  {
    "x": "nexşên, -e",
    "y": " b. neqişin, -e"
  },
  {
    "x": "nexşin, -e",
    "y": " b. neqişin, -e"
  },
  {
    "x": "nexşîn, -e",
    "y": " b. neqişin, -e"
  },
  {
    "x": "nexşkerdiş (n)",
    "y": " b. neqişkerdiş (n)"
  },
  {
    "x": "nextikî vero ameyene",
    "y": " b. nuqtikî vero ameyene"
  },
  {
    "x": "ney-I (zr)",
    "y": " hayır, yok"
  },
  {
    "x": "ney-II (n, z)",
    "y": " buna, bunu"
  },
  {
    "x": "ney ra teber",
    "y": " ayrıca"
  },
  {
    "x": "neya",
    "y": " b. nê-VI"
  },
  {
    "x": "neyar",
    "y": " düşman"
  },
  {
    "x": "neyarîye",
    "y": " düşmanlık"
  },
  {
    "x": "neyîli (m)",
    "y": " b. nale-I (m)"
  },
  {
    "x": "neyli (m)",
    "y": " b. nale-I (m) "
  },
  {
    "x": "neynik (m)",
    "y": " b. neynike (m)"
  },
  {
    "x": "neynike (m)",
    "y": " ayna"
  },
  {
    "x": "neynika peyvînî (m)",
    "y": " dikiz aynası (taşıtta)"
  },
  {
    "x": "neynikeraantiş (sn)",
    "y": " aynadan çekim"
  },
  {
    "x": "neynikeroş, -e",
    "y": " aynacı"
  },
  {
    "x": "neynikeroşîye (m)",
    "y": " aynacılık"
  },
  {
    "x": "neynikeviraştox, -e",
    "y": " aynacı"
  },
  {
    "x": "neynikeviraştoxîye (m)",
    "y": " aynacılık"
  },
  {
    "x": "neynûg (n)",
    "y": " b. nenûg (n)"
  },
  {
    "x": "neyr (n)",
    "y": " b. nêrî (n)"
  },
  {
    "x": "neyu (n)",
    "y": " b. newe (n)"
  },
  {
    "x": "nezan, -e",
    "y": " 1)bilgisiz, cahil\r\n~2)aymaz, gafil"
  },
  {
    "x": "nezan bîyene",
    "y": " aymazlaşmak"
  },
  {
    "x": "nezanane",
    "y": " bilgisizce, cahilce, cahilane"
  },
  {
    "x": "nezanbîyayîş (n)",
    "y": " aymazlaşma"
  },
  {
    "x": "nezaney (m)",
    "y": " b. nezanîye (m)"
  },
  {
    "x": "nezaney kerdene",
    "y": " b. nezanîye kerdene"
  },
  {
    "x": "nezanî (m)",
    "y": " b. nezanîye"
  },
  {
    "x": "nezanîye (m)",
    "y": " bilgisizlik, cahiliyet, cehalet, aymazlık"
  },
  {
    "x": "nezanîye kerdene",
    "y": " cahillik etmek "
  },
  {
    "x": "nezaret (n)",
    "y": " 1)gözaltı, nezaret\r\n~2)gözetim"
  },
  {
    "x": "nezaretxane (n)",
    "y": " gözaltı yeri (binası), nezarethane"
  },
  {
    "x": "nezdî",
    "y": " b. nêzdî, -ye"
  },
  {
    "x": "nezelîyayene",
    "y": " b. nesilîyayene"
  },
  {
    "x": "nezelîyayîş (n)",
    "y": " b. nesilîyayîş (n)"
  },
  {
    "x": "nezelnayene",
    "y": " b. nesilnayene"
  },
  {
    "x": "nezelnayîş (n)",
    "y": " b. nesilnayîş (n)"
  },
  {
    "x": "nezer (n)",
    "y": " nazar"
  },
  {
    "x": "nezer bîyene",
    "y": " nazar değmek, göz değmek, nazara gelmek"
  },
  {
    "x": "nezer kerdene",
    "y": " nazar etmek"
  },
  {
    "x": "nezeret (n)",
    "y": " b. nezaret (n)"
  },
  {
    "x": "nezeretxane (n)",
    "y": " b. nezaretxane (n)"
  },
  {
    "x": "nezerî, -ye",
    "y": " kuramsal, nazari, teorik"
  },
  {
    "x": "nezerîye (m)",
    "y": " kuram, nazariye, teori"
  },
  {
    "x": "nezih (n)",
    "y": " kaza"
  },
  {
    "x": "nezil (n)",
    "y": " mantar hastalığı"
  },
  {
    "x": "nezilê destan (n)",
    "y": " el mantarı"
  },
  {
    "x": "nezilê deston (n)",
    "y": " b. nezilê destan (n)"
  },
  {
    "x": "nezilê destûn (n)",
    "y": " b. nezilê destan (n)"
  },
  {
    "x": "nezilê lingan (n)",
    "y": " ayak mantarı"
  },
  {
    "x": "nezilê lingon (n)",
    "y": " b. nezilê lingan (n)"
  },
  {
    "x": "nezilê lingûn (n)",
    "y": " b. nezilê lingan (n)"
  },
  {
    "x": "nezilê sarî (n)",
    "y": " b. nezilê serî (n) "
  },
  {
    "x": "nezilê serî (n)",
    "y": " baş mantarı"
  },
  {
    "x": "nezle (n)",
    "y": " nezle"
  },
  {
    "x": "nezon, -i",
    "y": " b. nezan, -e"
  },
  {
    "x": "nezoney (m)",
    "y": " b. nezanîye (m) b. nezanî (m)"
  },
  {
    "x": "nezoney kardene",
    "y": " b. nezanîye kerdene"
  },
  {
    "x": "nezonê (m)",
    "y": " b. nezanî (m)"
  },
  {
    "x": "nezonone",
    "y": " b. nezanane"
  },
  {
    "x": "nezûn, -i",
    "y": " b. nezan, -e"
  },
  {
    "x": "nezûney (m)",
    "y": " b. nezanî (m)"
  },
  {
    "x": "nezûnê (m)",
    "y": " b. nezanîye (m), b. nezanî (m)"
  },
  {
    "x": "nezûnê kerdene",
    "y": " b. nezanîye kerdene"
  },
  {
    "x": "nezûnûne",
    "y": " b. nezanane"
  },
  {
    "x": "nê-I (zh, z)",
    "y": " bunlar"
  },
  {
    "x": "nê-II (zh, s)",
    "y": " bunlar\r\n~Nê kênekî vanê. (Bu kızlar söylüyorlar.)\r\n~\t\r\n~Nê lajekî vanê. (Bu oğlanlar söylüyorlar.)"
  },
  {
    "x": "nê ... ra",
    "y": " bunlara\r\n~Nê kênekan ra vaje. (Bu kızlara söyle.)\r\n~\t\r\n~Nê lajekan ra vaje. (Bu oğlanlara söyle.)"
  },
  {
    "x": "nê-III (n, z)",
    "y": " b. ney-II (n, z)"
  },
  {
    "x": "nê-IV (n, s)",
    "y": " bu\r\n~Nê lajekî va. (Bu oğlan söyledi.)"
  },
  {
    "x": "nê ... ra",
    "y": " buna"
  },
  {
    "x": "nê rîwalî ra",
    "y": " bu nedenle, bu yüzden, bundan dolayı, bundan ötürü"
  },
  {
    "x": "nê sebebî ra",
    "y": " bu nedenle, bu yüzden, bundan dolayı, bundan ötürü"
  },
  {
    "x": "nê semedî ra",
    "y": " bu nedenle, bu yüzden, bundan dolayı, bundan ötürü"
  },
  {
    "x": "nê-V (zr)",
    "y": " b. ney-I (zr)"
  },
  {
    "x": "nê-VI",
    "y": " yoksa\r\n~Ti yenî nê nînî? (Geliyor musun yoksa gelmiyor musun?)\r\n~\t\r\n~Winî ya nê winî nîya? (Öyle mi yoksa öyle değil mi?)"
  },
  {
    "x": "nê ney",
    "y": " b. nê-VI"
  },
  {
    "x": "nê-VII",
    "y": " ya"
  },
  {
    "x": "nê ... nê ...-I",
    "y": " ya ... ya …\r\n~Nê ez yena nê ti bê. (Ya ben gelirim ya sen gel.)"
  },
  {
    "x": "nê ... nê ...-II",
    "y": " b. ne ... ne ...-I"
  },
  {
    "x": "nêadayene",
    "y": " b. nîyadayene"
  },
  {
    "x": "nêbo",
    "y": " sakın"
  },
  {
    "x": "nêbo ke",
    "y": " b. nêbo"
  },
  {
    "x": "nêçar, -i",
    "y": " b. neçar, -e"
  },
  {
    "x": "nêçir (n)",
    "y": " b. nêçîr (n)"
  },
  {
    "x": "nêçirvan, -e",
    "y": " b. nêçîrvan, -e"
  },
  {
    "x": "nêçîr (n)",
    "y": " av"
  },
  {
    "x": "nêçîr kerdene",
    "y": " avlamak, avlanmak (ava gitmek, ava çıkmak)"
  },
  {
    "x": "nêçîr şîyene",
    "y": " avlanmak (ava gitmek, ava çıkmak)"
  },
  {
    "x": "nêçîrgeh (n)",
    "y": " avlak"
  },
  {
    "x": "nêçîrkerdiş (n)",
    "y": " avlama"
  },
  {
    "x": "nêçîrvan, -e",
    "y": " avcı"
  },
  {
    "x": "nêçîrvanî (m)",
    "y": " avcılık"
  },
  {
    "x": "nêçîrwan, -e",
    "y": " b. nêçîrvan, -e"
  },
  {
    "x": "nêmante (n)",
    "y": " ara, aralık"
  },
  {
    "x": "nêmendişê dejî (n)",
    "y": " analjezi"
  },
  {
    "x": "nêdar, -e",
    "y": " korucu (orman, kır ya da köy bekçisi)"
  },
  {
    "x": "nêdarsaya (m)",
    "y": " b. nêderesaya (m), nêderesayîye (m)"
  },
  {
    "x": "nêdarsaye (n)",
    "y": " b. nêderesaye (n)"
  },
  {
    "x": "nêderesaya (m)",
    "y": " ham, olmamış"
  },
  {
    "x": "nêderesaye (n)",
    "y": " ham, olmamış"
  },
  {
    "x": "nêderesayîye (m)",
    "y": " ham, olmamış"
  },
  {
    "x": "nêdersaya (m)",
    "y": " b. nêderesaya (m), nêderesayîye (m)"
  },
  {
    "x": "nêdersaye (n)",
    "y": " b. nêderesaye (n)"
  },
  {
    "x": "nêdeste (n)",
    "y": " b. nêdeşte (n)"
  },
  {
    "x": "nêdeşta (m)",
    "y": " dikişsiz, dikilmemiş"
  },
  {
    "x": "nêdeşte (n)",
    "y": " dikişsiz, dikilmemiş"
  },
  {
    "x": "nêdeşti (n)",
    "y": " b. nêdeşte (n)"
  },
  {
    "x": "nêdeştî (m)",
    "y": " b. nêdeştîye (m)"
  },
  {
    "x": "nêdeştîye (m)",
    "y": " dikişsiz, dikilmemiş"
  },
  {
    "x": "nêdêşta (m)",
    "y": " b. nêdeşta (m)"
  },
  {
    "x": "nêdêşti (n)",
    "y": " b. nêdeşte (n)"
  },
  {
    "x": "nêfirîyayêne",
    "y": " b. nefirîyayene"
  },
  {
    "x": "nêfirîyayîş (n)",
    "y": " b. nefirîyayîş (n)"
  },
  {
    "x": "nêfiryayene",
    "y": " b. nefirîyayene"
  },
  {
    "x": "nêfiryayîş (n)",
    "y": " b. nefirîyayîş (n)"
  },
  {
    "x": "nêgar bîyayene",
    "y": " b. nêgar bîyene"
  },
  {
    "x": "nêgar bîyene",
    "y": " görülmek"
  },
  {
    "x": "nêgra",
    "y": " b. nêkela"
  },
  {
    "x": "nêherê (zr)",
    "y": " hayır, yok"
  },
  {
    "x": "nêjdî, -ye",
    "y": " b. nêzdî, -ye"
  },
  {
    "x": "nêka-I",
    "y": " b. nika"
  },
  {
    "x": "nêka-II",
    "y": " b. nêke"
  },
  {
    "x": "nêke",
    "y": " aksi taktirde, aksi halde, yoksa\r\n~Eke ti wanenî bê, nêke mey. (Okuyacaksan gel, yoksa gelme.)\r\n~\t\r\n~Vateyê xo ser o vinde, nêke to ra zaf hêrs beno. (Sözünde dur, aksi taktirde sana çok kızar.)"
  },
  {
    "x": "nêkela",
    "y": " aksi taktirde, aksi halde, yoksa\r\n~Dêmek terseno, nêkela çira nîno? (Demek ki korkuyor, yoksa neden gelmiyor?)\r\n~\t\r\n~Seba ke nêweş bî nêame, nêkela çira nêro? (Hasta olduğu için gelmedi, aksi taktirde neden gelmesin."
  },
  {
    "x": "nêki",
    "y": " b. nêke"
  },
  {
    "x": "nêq, -e",
    "y": " b. neheq, -e"
  },
  {
    "x": "nêykna",
    "y": " b. nêkela"
  },
  {
    "x": "nêm",
    "y": " yarım"
  },
  {
    "x": "nêma (m)",
    "y": " ortak (ortak mal)"
  },
  {
    "x": "nêmante (n)",
    "y": " ara"
  },
  {
    "x": "nêmazet, -e",
    "y": " b. nêmcet, -e"
  },
  {
    "x": "nêmbask (n)",
    "y": " yarım kulaç"
  },
  {
    "x": "nêmbest (n)",
    "y": " b. nêmbeste (m)"
  },
  {
    "x": "nêmbeste (m)",
    "y": " yarım kulaç"
  },
  {
    "x": "nêmbesti (m)",
    "y": " b. nêmbeste (m)"
  },
  {
    "x": "nêmbirro (n)",
    "y": " bugünden sonraki ikinci gün"
  },
  {
    "x": "nêmbur (n)",
    "y": " b. nêmbirro (n)"
  },
  {
    "x": "nêmburro (n)",
    "y": " b. nêmbirro (n)"
  },
  {
    "x": "nêmcet, -e",
    "y": " eksik"
  },
  {
    "x": "nêmçape (m)",
    "y": " yarıçap (mat)"
  },
  {
    "x": "nême (n)",
    "y": " 1)yarı, yarım\r\n~2)ortak (ortak mal)\r\n~No rez nême yo. (Bu bağ -her yarısı bir tarafa ait olmak üzere- ortaktır.)"
  },
  {
    "x": "nêmecet, -e",
    "y": " b. nêmcet, -e"
  },
  {
    "x": "nêmedar, -e",
    "y": " ortak, ortakçı, hissedar, paydaş; yarıcı"
  },
  {
    "x": "nêmedar bîyene",
    "y": " ortak olmak, yarıcı olmak"
  },
  {
    "x": "nêmedarî (m)",
    "y": " ortakçılık, yarıcılık"
  },
  {
    "x": "nêmedarîye (m)",
    "y": " ortakçılık, yarıcılık"
  },
  {
    "x": "nêmejet, -e",
    "y": " b. nêmcet, -e"
  },
  {
    "x": "nêmeroj (n)",
    "y": " b. nêmroje (m)"
  },
  {
    "x": "nêmeyey (m)",
    "y": " b. nêmeyîye (m)"
  },
  {
    "x": "nêmeyî (m)",
    "y": " ortakçılık, ortaklık, paydaşlık"
  },
  {
    "x": "nêmeyîye (m)",
    "y": " ortakçılık, ortaklık, paydaşlık"
  },
  {
    "x": "nêmezet, -e",
    "y": " b. nêmcet, -e"
  },
  {
    "x": "nêmfelqe (n)",
    "y": " yarım parça"
  },
  {
    "x": "nêmfînal (n)",
    "y": " yarı final"
  },
  {
    "x": "nêmfînalîst, -e",
    "y": " yarı finalist"
  },
  {
    "x": "nêmgirawe (m)",
    "y": " yarımada"
  },
  {
    "x": "nêmjet, -i",
    "y": " b. nêmcet, -e"
  },
  {
    "x": "nêmkod (n)",
    "y": " tahıl vb. şeyleri ölçmek için kullanılan tahtadan yapılmış büyük kabın (“kod”un) yarısı"
  },
  {
    "x": "nêmkued (n)",
    "y": " b. nêmkod (n)"
  },
  {
    "x": "nêmlete (n)",
    "y": " yarım parça"
  },
  {
    "x": "nêmleti (n)",
    "y": " b. nêmlete (n) "
  },
  {
    "x": "nêmlên (n)",
    "y": " küçük kazan"
  },
  {
    "x": "nêmmesaî (m)",
    "y": " yarım mesai"
  },
  {
    "x": "nêmolçeg (m)",
    "y": " b. nêmwelçeke (m)"
  },
  {
    "x": "nêmonte (n)",
    "y": " nêmante (n)"
  },
  {
    "x": "nêmparçe (n)",
    "y": " yarım parça"
  },
  {
    "x": "nêmparçi (n)",
    "y": " b. nêmparçe (n)"
  },
  {
    "x": "nêmqolince (m)",
    "y": " b. nêmqulaç (n)"
  },
  {
    "x": "nêmqulaç (n)",
    "y": " yarım kulaç"
  },
  {
    "x": "nêmribik (n)",
    "y": " yarım kile, yarım ölçek (tahıl ölçmeye yarar kap), yarım şinik"
  },
  {
    "x": "nêmribîg (n)",
    "y": " b. nêmribik (n)"
  },
  {
    "x": "nêmroje (m)",
    "y": " öğle"
  },
  {
    "x": "nêmroje de",
    "y": " öğleyin (öğle vakti)"
  },
  {
    "x": "nêmroje ra pey",
    "y": " öğleden sonra"
  },
  {
    "x": "nêmroje ra ver",
    "y": " öğleden önce"
  },
  {
    "x": "nêmromork (n)",
    "y": " yarı römork"
  },
  {
    "x": "nêmrueci (m)",
    "y": " b. nêmroje (m)"
  },
  {
    "x": "nêmrueci di",
    "y": " b. nêmroje de"
  },
  {
    "x": "nêmrueci ra pê",
    "y": " b. nêmroje ra pey"
  },
  {
    "x": "nêmrueci ra ver",
    "y": " b. nêmroje ra ver"
  },
  {
    "x": "nêmruej (n)",
    "y": " b. nêmroje (m)"
  },
  {
    "x": "nêmruej di",
    "y": " b. nêmroje de"
  },
  {
    "x": "nêmruej ra pey",
    "y": " b. nêmroje ra pey"
  },
  {
    "x": "nêmruej ra ver",
    "y": " b. nêmroje ra ver"
  },
  {
    "x": "nêmserre (m)",
    "y": " dönem (eğitim-öğretim yılının yarısı), yarıyıl"
  },
  {
    "x": "nêmserra payîzî (m)",
    "y": " birinci yarıyıl (güz dönemi)"
  },
  {
    "x": "nêmserra wisarî (m)",
    "y": " ikinci yarıyıl (bahar dönemi)"
  },
  {
    "x": "nêmşewe (m)",
    "y": " gece yarısı"
  },
  {
    "x": "nêmtimin (n)",
    "y": " kile, ölçek (tahıl ölçmeye yarar kap), şinik"
  },
  {
    "x": "nêmtirro (n)",
    "y": " bugünden sonraki üçüncü gün"
  },
  {
    "x": "nêmturro (n)",
    "y": " b. nêmtirro (n)"
  },
  {
    "x": "nêmverare (m)",
    "y": " yarım kulaç"
  },
  {
    "x": "nêmvirare (m)",
    "y": " b. nêmverare (m)"
  },
  {
    "x": "nêmwelç (m)",
    "y": " b. nêmwelçeke (m)"
  },
  {
    "x": "nêmwelçeg (m)",
    "y": " b. nêmwelçeke (m)"
  },
  {
    "x": "nêmwelçegi (m)",
    "y": " b. nêmwelçeke (m)"
  },
  {
    "x": "nêmwelçek (m)",
    "y": " b. nêmwelçeke (m)"
  },
  {
    "x": "nêmwelçeke (m)",
    "y": " 21, 20 veya 10 kg ağırlığında bir tahıl ölçüsü"
  },
  {
    "x": "nêmwelçeki (m)",
    "y": " b. nêmwelçeke (m)"
  },
  {
    "x": "nêmwelçêg (m)",
    "y": " b. nêmwelçeke (m)"
  },
  {
    "x": "nêmxebate (m)",
    "y": " yarım mesai"
  },
  {
    "x": "nêney",
    "y": " b. nê-VI"
  },
  {
    "x": "nênê",
    "y": " b. nê-VI"
  },
  {
    "x": "nêng (m)",
    "y": " b. nenge (m)"
  },
  {
    "x": "nêng çînayene",
    "y": " b. nengî çînayene"
  },
  {
    "x": "nêngçînayîş (n)",
    "y": " b. nengîçînayîş (n)"
  },
  {
    "x": "nênik (m)",
    "y": " b. neynike (m)"
  },
  {
    "x": "nêrasaya (m)",
    "y": " b. nêresaya (m), nêresayîye (m)"
  },
  {
    "x": "nêrasayi (n)",
    "y": " b. nêresaye (n)"
  },
  {
    "x": "nêrdewani (m)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nêrdîwan (n)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nêrdîwane (m)",
    "y": " merdiven"
  },
  {
    "x": "nêrdîwana darêne (m)",
    "y": " ağaç veya tahtadan yapılmış merdiven"
  },
  {
    "x": "nêrdîwana gêroke (m)",
    "y": " seyyar merdiven"
  },
  {
    "x": "nêrdîwana seyare (m)",
    "y": " seyyar merdiven"
  },
  {
    "x": "nêrdîwana şewatî (m)",
    "y": " yangın merdiveni"
  },
  {
    "x": "nêrdiwûn (m)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nêrdîwûn (m)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nêrdwûn (m)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nêresaya (m)",
    "y": " ham, olmamış"
  },
  {
    "x": "nêresaye (n)",
    "y": " ham, olmamış"
  },
  {
    "x": "nêresayîye (m)",
    "y": " ham, olmamış"
  },
  {
    "x": "nêresta (m)",
    "y": " ham, olmamış"
  },
  {
    "x": "nêreste (n)",
    "y": " ham, olmamış"
  },
  {
    "x": "nêrestîye (m)",
    "y": " ham, olmamış"
  },
  {
    "x": "nêreşta (m)",
    "y": " b. nêresta (m), nêrestîye (m)"
  },
  {
    "x": "nêreşte (n)",
    "y": " b. nêreste (n)"
  },
  {
    "x": "nêreştîye (m)",
    "y": " b. nêresta (m), nêrestîye (m)"
  },
  {
    "x": "nêrik (n)",
    "y": " iğne (bitkilerde)"
  },
  {
    "x": "nêrisaya (m)",
    "y": " b. nêresaya (m), nêresayîye (m)"
  },
  {
    "x": "nêrisaye (n)",
    "y": " b. nêresaye (n)"
  },
  {
    "x": "nêrisayîye (m)",
    "y": " b. nêresaya (m), nêresayîye (m)"
  },
  {
    "x": "nêrî (n)",
    "y": " 1)eril\r\n~2)iğne (bitkilerde)\r\n~nêrîyê pîyazî (n): soğan iğnesi\r\n~nêrîyê qirrike (n): âdemelması (gırtlak çıkıntısı)"
  },
  {
    "x": "nêrîyê pîyazî (n)",
    "y": " soğan iğnesi"
  },
  {
    "x": "nêrîyê qirrike (n)",
    "y": " âdemelması (gırtlak çıkıntısı)"
  },
  {
    "x": "nêrîyê vaşî (n)",
    "y": " bitkinin tohum veren organı"
  },
  {
    "x": "nêşka",
    "y": " b. nişka"
  },
  {
    "x": "nêşka ve ra",
    "y": " b. nişka ve ra"
  },
  {
    "x": "nêverdayîş (n)",
    "y": " men"
  },
  {
    "x": "nêvîyarta (m)",
    "y": " geçersiz, hükümsüz, batıl"
  },
  {
    "x": "nevîyartbar, -e",
    "y": " geçersiz, batıl"
  },
  {
    "x": "nêvîyarte (n)",
    "y": " geçersiz, hükümsüz, batıl"
  },
  {
    "x": "nêvîyarteyî (m)",
    "y": " geçersizlik, hükümsüzlük"
  },
  {
    "x": "nêvîyartî (m)",
    "y": " geçersiz, hükümsüz, batıl"
  },
  {
    "x": "nêw",
    "y": " b. new"
  },
  {
    "x": "nêwe (n)",
    "y": " b. newe (n)"
  },
  {
    "x": "nêwes, -e",
    "y": " b. nêweş, -e"
  },
  {
    "x": "nêwes bîyayene",
    "y": " b. nêweş bîyene"
  },
  {
    "x": "nêwes kerdene",
    "y": " b. nêweş kerdene"
  },
  {
    "x": "nêwes kewtene",
    "y": " b. nêweş kewtene"
  },
  {
    "x": "nêwesa girane (m)",
    "y": " b. nêweşa girane (m)"
  },
  {
    "x": "nêweso giran (n)",
    "y": " b. nêweşo giran (n)"
  },
  {
    "x": "nêwesênî (m)",
    "y": " b. nêweşênî (m)"
  },
  {
    "x": "nêwesxane (n)",
    "y": " b. nêweşxane (n)"
  },
  {
    "x": "nêweş, -e",
    "y": " hasta, sayrı, sağlıksız, rahatsız"
  },
  {
    "x": "nêweş bîyene",
    "y": " hasta olmak, hastalanmak, rahatsız olmak"
  },
  {
    "x": "nêweş kardene",
    "y": " b. nêweş kerdene"
  },
  {
    "x": "nêweş kerdene",
    "y": " hasta etmek"
  },
  {
    "x": "nêweş kewtene",
    "y": " hasta düşmek, hastalanmak"
  },
  {
    "x": "nêweşa eyelî (m)",
    "y": " gebe¸ iki canlı, hamile"
  },
  {
    "x": "nêweşa gedeyî (m)",
    "y": " gebe, iki canlı, hamile"
  },
  {
    "x": "nêweşa gedeyî bîyene",
    "y": " gebe olmak, hamile olmak, iki canlı olmak"
  },
  {
    "x": "nêweşa gedî (m)",
    "y": " b. nêweşa gedeyî (m)"
  },
  {
    "x": "nêweşa gedî bîyayene",
    "y": " b. nêweşa gedeyî bîyene"
  },
  {
    "x": "nêweşa girane (m)",
    "y": " ağır hasta"
  },
  {
    "x": "nêweşa psîkolojîke (m)",
    "y": " akıl hastası, ruh hastası "
  },
  {
    "x": "nêweşa ruhîye (m)",
    "y": " akıl hastası, ruh hastası"
  },
  {
    "x": "nêweşa xidare (m)",
    "y": " ağır hasta"
  },
  {
    "x": "nêweşa tutî (m)",
    "y": " gebe, iki canlı, hamile"
  },
  {
    "x": "nêweşa tutî bîyene",
    "y": " gebe olmak, hamile olmak, iki canlı olmak"
  },
  {
    "x": "nêweşey eyelî (m)",
    "y": " b. nêweşa eyelî (m)"
  },
  {
    "x": "nêweşey eyelî bîyayene",
    "y": " b. nêweşa eyelî bîyene"
  },
  {
    "x": "nêweşey gedî (m)",
    "y": " b. nêweşa gedeyî (m)"
  },
  {
    "x": "nêweşey gedî bîyayene",
    "y": " b. nêweşa gedeyî bîyene"
  },
  {
    "x": "nêweşê tuît (m)",
    "y": " b. nêweşa tutî (m)"
  },
  {
    "x": "nêweşê tuît bîyayene",
    "y": " b. nêweşa tutî bîyene"
  },
  {
    "x": "nêweşo giran (n)",
    "y": " ağır hasta"
  },
  {
    "x": "nêweşo psîkolojîk (n)",
    "y": " akıl hastası, ruh hastası"
  },
  {
    "x": "nêweşo ruhî (n)",
    "y": " akıl hastası, ruh hastası"
  },
  {
    "x": "nêweşo xidar (n)",
    "y": " ağır hasta "
  },
  {
    "x": "nêweşaney (m)",
    "y": " b. nêweşênî (m)"
  },
  {
    "x": "nêweşanî (m)",
    "y": " b. nêweşênî (m)"
  },
  {
    "x": "nêweşbîyayîş (n)",
    "y": " hasta olma, rahatsız olma"
  },
  {
    "x": "nêweşey (m)",
    "y": " b. nêweşî (m), nêweşîye (m)"
  },
  {
    "x": "nêweşê (m)",
    "y": " b. nêweşî (m), nêweşîye (m)"
  },
  {
    "x": "nêweşên (m)",
    "y": " b. nêweşênî (m)"
  },
  {
    "x": "nêweşênî (m)",
    "y": " hastalık, sayrılık"
  },
  {
    "x": "nêweşî (m)",
    "y": " hastalık, sayrılık"
  },
  {
    "x": "nêweşîya albînoyî (m)",
    "y": " albino hastalığı"
  },
  {
    "x": "nêweşîya buergerî (m)",
    "y": " buerger hastalığı"
  },
  {
    "x": "nêweşîya fîlî (m)",
    "y": " fil hastalığı"
  },
  {
    "x": "nêweşîya kanavanî (canavanî) (m)",
    "y": " canavan (kanavan) hastalığı"
  },
  {
    "x": "nêweşîya psîkolojîke (m)",
    "y": " akıl hastalığı"
  },
  {
    "x": "nêweşîya ruhî (m)",
    "y": " akıl hastalığı"
  },
  {
    "x": "nêweşînas, -e",
    "y": " patolog"
  },
  {
    "x": "nêweşînasî (m)",
    "y": " hastalıkbilim, patoloji, sayrılıkbilim "
  },
  {
    "x": "nêweşînasîye (m)",
    "y": " hastalıkbilim, patoloji, sayrılıkbilim"
  },
  {
    "x": "nêweşîne (m)",
    "y": " b. nêweşênî (m)"
  },
  {
    "x": "nêweşîni (m)",
    "y": " b. nêweşênî (m)"
  },
  {
    "x": "nêweşînî (m)",
    "y": " b. nêweşênî (m)"
  },
  {
    "x": "nêweşîye (m)",
    "y": " hastalık, sayrılık"
  },
  {
    "x": "nêweşîya psîkolojîke (m)",
    "y": " ruh hastalığı"
  },
  {
    "x": "nêweşîya ruhî (m)",
    "y": " ruh hastalığı"
  },
  {
    "x": "nêweşmiqatkerdox, -e",
    "y": " hastabakıcı"
  },
  {
    "x": "nêweşqayîtkerdox, -e",
    "y": " hastabakıcı"
  },
  {
    "x": "nêweşxane (n)",
    "y": " hastane, hastahane"
  },
  {
    "x": "nêweşxaneyê nêweşîyanê psîkolojîkan (n)",
    "y": " akıl hastanesi"
  },
  {
    "x": "nêweşxaneyê nêweşîyanê ruhîyan (n)",
    "y": " akıl hastanesi"
  },
  {
    "x": "nêweşxûne (n)",
    "y": " b. nêweşxane (n)"
  },
  {
    "x": "nêwet (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "nêwetçenî (m)",
    "y": " b. nobetçênî (m)"
  },
  {
    "x": "nêwetçî, -ye",
    "y": " b. nobeçî, -ye"
  },
  {
    "x": "nêwete (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "nêweti (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "nêwi (n)",
    "y": " b. newe-I (n)"
  },
  {
    "x": "nêwin, -e",
    "y": " b. newin, -e"
  },
  {
    "x": "nêwî (m)",
    "y": " b. newî (m)"
  },
  {
    "x": "nêwîn-I",
    "y": " b. winasî, inasar"
  },
  {
    "x": "nêwîn, -e-II",
    "y": " b. newin, -e"
  },
  {
    "x": "nêwîne-III",
    "y": " b. winasî, inasar"
  },
  {
    "x": "nêwyes",
    "y": " b. newês"
  },
  {
    "x": "nêxeyr (zr)",
    "y": " b. nexêr (zr)"
  },
  {
    "x": "nêxêr (zr)",
    "y": " b. nexêr (zr)"
  },
  {
    "x": "nêzdî, -ye",
    "y": " yakın"
  },
  {
    "x": "nêzdî bîyene",
    "y": " 1)yaklaşmak\r\n~2)karışmak (dokunmak)\r\n~Nêzdîyê domanî mebe! (Çocuğa karışma!)"
  },
  {
    "x": "nêzdîyê ... bîyene",
    "y": " -e karışmak\r\n~Nêzdîyê ci mebe wa şiro. (Karışma gitsin.)"
  },
  {
    "x": "nêzdîbîyayîş (n)",
    "y": " 1)yaklaşım (yaklaşma işi), yaklaşma\r\n~2)karışma (dokunma)"
  },
  {
    "x": "nêzik",
    "y": " b. nêzdî, -ye"
  },
  {
    "x": "ni ... ni ...",
    "y": " b. ne ... ne ...-I"
  },
  {
    "x": "nibû",
    "y": " b. nêbo"
  },
  {
    "x": "nicaq (n)",
    "y": " b. necax (n)"
  },
  {
    "x": "nicar, -e",
    "y": " b. necar, -e"
  },
  {
    "x": "nicax (n)",
    "y": " b. necax (n)"
  },
  {
    "x": "nicnayene",
    "y": " b. nijnayene"
  },
  {
    "x": "niçuki (m)",
    "y": " b. nuçike (m)"
  },
  {
    "x": "niçûki (m)",
    "y": " b. nuçike (m)"
  },
  {
    "x": "nifis (n)",
    "y": " b. nufus (n)"
  },
  {
    "x": "nifte (m)",
    "y": " kibrit"
  },
  {
    "x": "nifus (n)",
    "y": " b. nufus (n)"
  },
  {
    "x": "nifûs (n)",
    "y": " b. nufus (n)"
  },
  {
    "x": "nig (m)",
    "y": " b. linge (m)"
  },
  {
    "x": "nige (m)",
    "y": " b. linge (m)"
  },
  {
    "x": "niha (m)",
    "y": " b. neha-I (m), nuke (m)"
  },
  {
    "x": "nihal (m)",
    "y": " b. nale (m)"
  },
  {
    "x": "nihe (m)",
    "y": " b. neha-I (m), nuke (m)"
  },
  {
    "x": "niheqê (m)",
    "y": " b. neheqî (m), neheqîye (m)"
  },
  {
    "x": "niheqê kerdene",
    "y": " b. neheqî kerdene"
  },
  {
    "x": "niheqî (m)",
    "y": " b. neheqî (m)"
  },
  {
    "x": "niheqîn (m)",
    "y": " b. neheqênî (m)"
  },
  {
    "x": "nihê (m)",
    "y": " b. neha-I (m), nuke (m)"
  },
  {
    "x": "nijdî, -ye",
    "y": " b. nêzdî, -ye"
  },
  {
    "x": "nijnayene",
    "y": " istiflemek"
  },
  {
    "x": "nika",
    "y": " şimdi"
  },
  {
    "x": "nikay",
    "y": " b. nika"
  },
  {
    "x": "nikayên, -e",
    "y": " b. nikayin, -e"
  },
  {
    "x": "nikayin, -e",
    "y": " şimdiki "
  },
  {
    "x": "nikayini (m)",
    "y": " b. nikayine (m)"
  },
  {
    "x": "nikeyene",
    "y": " aksamak, topallamak"
  },
  {
    "x": "nikeyîs (n)",
    "y": " b. nikeyîş (n)"
  },
  {
    "x": "nikeyîş (n)",
    "y": " aksama, topallama"
  },
  {
    "x": "nikê",
    "y": " b. nika"
  },
  {
    "x": "nikme (n)",
    "y": " b. nekme (n)"
  },
  {
    "x": "nikmeyin, -i",
    "y": " b. nekmeyin, -e"
  },
  {
    "x": "nikmeyinê (m)",
    "y": " b. nekmeyinîye (m)"
  },
  {
    "x": "nikotik (zh)",
    "y": " b. mîrkutikî (zh)"
  },
  {
    "x": "nikotik vetene",
    "y": " b. mîrkutikî vetene"
  },
  {
    "x": "nikuj (m)",
    "y": " b. nuçike (m)"
  },
  {
    "x": "nikurtikî (zh)",
    "y": " b. mîrkutikî (zh)"
  },
  {
    "x": "nikurtikî vetene",
    "y": " b. mîrkutikî vetene "
  },
  {
    "x": "nikuv (m)",
    "y": " b. mekube (m)"
  },
  {
    "x": "nim (n)",
    "y": " şey\r\n~Nimî bîyare. (Şeyi getir.)"
  },
  {
    "x": "nimac (n)",
    "y": " b. nimaj (n) "
  },
  {
    "x": "nimacê eşayî (n)",
    "y": " b. nimajê eşayî (n)"
  },
  {
    "x": "nimacî sibay (n)",
    "y": " b. nimajê sibayî (n)"
  },
  {
    "x": "nimacê şonî (n)",
    "y": " b. nimajê şanî (n)"
  },
  {
    "x": "nimacê şûnî (n)",
    "y": " b. nimajê şanî (n)"
  },
  {
    "x": "nimacê yerî (n)",
    "y": " b. nimajê êreyî (n)"
  },
  {
    "x": "nimacî şûn (n)",
    "y": " b. nimajê şanî (n)"
  },
  {
    "x": "nimacê",
    "y": " b. nimajê"
  },
  {
    "x": "nimacê der",
    "y": " b. nimajê der"
  },
  {
    "x": "nimacî",
    "y": " b. nimajê"
  },
  {
    "x": "nimaj (n)",
    "y": " 1)namaz\r\n~2)sabah\r\n~Nimaj bi, embazî şî. (Sabah oldu, arkadaşlar gittiler.)"
  },
  {
    "x": "nimajê cenaza (n)",
    "y": " cenaze namazı"
  },
  {
    "x": "nimaj eyşay (n)",
    "y": " b. nimajê eşayî (n)"
  },
  {
    "x": "nimajê dihîre (m)",
    "y": " b. nimajê teştareyî (n)"
  },
  {
    "x": "nimajê eşay (n)",
    "y": " b. nimajê eşayî (n) "
  },
  {
    "x": "nimajê eşayî (n)",
    "y": " yatsı namazı"
  },
  {
    "x": "nimajê êreyî (n)",
    "y": " ikindi namazı"
  },
  {
    "x": "nimajê êrî (n)",
    "y": " b. nimajê êreyî (n)"
  },
  {
    "x": "nimajê mexrebî (n)",
    "y": " akşam namazı"
  },
  {
    "x": "nimajê mirxubî (n)",
    "y": " b. nimajê mexrebî (n)"
  },
  {
    "x": "nimajê sibay (n)",
    "y": " b. nimajê sibayî (n)"
  },
  {
    "x": "nimajê sibayî (n)",
    "y": " sabah namazı"
  },
  {
    "x": "nimajê şanî (n)",
    "y": " akşam namazı"
  },
  {
    "x": "nimajê şewray (n)",
    "y": " b. nimajê şewrayî (n)"
  },
  {
    "x": "nimajê şewrayî (n)",
    "y": " sabah namazı"
  },
  {
    "x": "nimajê taşterî (n)",
    "y": " b. nimajê teştareyî (n) "
  },
  {
    "x": "nimajê teştareyî (n)",
    "y": " öğle namazı"
  },
  {
    "x": "nimajê teştarî (n)",
    "y": " b. nimajê teştareyî (n)"
  },
  {
    "x": "nimajê",
    "y": " sabahleyin"
  },
  {
    "x": "nimajê der",
    "y": " b. nimajê"
  },
  {
    "x": "nimajî",
    "y": " b. nimajê "
  },
  {
    "x": "nimayj",
    "y": " b. nimajê"
  },
  {
    "x": "nimaz (n)",
    "y": " b. nimaj-1 (n)"
  },
  {
    "x": "nimazê êrî (n)",
    "y": " b. nimajê êreyî (n)"
  },
  {
    "x": "nimazê dihîr (n)",
    "y": " b. nimajê teştareyî (n)"
  },
  {
    "x": "nimazê şewray (n)",
    "y": " b. nimajê şewrayî (n)"
  },
  {
    "x": "nimazê yasî (n)",
    "y": " b. nimajê eşayî (n)"
  },
  {
    "x": "nime (n)",
    "y": " b. nimaj-1 (n)"
  },
  {
    "x": "nimey (n)",
    "y": " b. nimaj-1 (n)"
  },
  {
    "x": "nimikî",
    "y": " b. nimitikî"
  },
  {
    "x": "nimita (m)",
    "y": " gizli"
  },
  {
    "x": "nimite (n)",
    "y": " gizli"
  },
  {
    "x": "nimitene",
    "y": " 1)gizlemek\r\n~2)defnetmek, saklamak"
  },
  {
    "x": "nimitik (m)",
    "y": " b. nimitike (m)"
  },
  {
    "x": "nimitike (m)",
    "y": " saklambaç (oyunu)"
  },
  {
    "x": "nimitikî",
    "y": " gizlice"
  },
  {
    "x": "nimitikkay (n)",
    "y": " saklambaç (oyunu)"
  },
  {
    "x": "nimitiş (n)",
    "y": " 1)gizleme\r\n~2)defnetme, saklama"
  },
  {
    "x": "nimitî (m)",
    "y": " gizli"
  },
  {
    "x": "nimitîye (m)",
    "y": " gizli"
  },
  {
    "x": "nimitkay (n)",
    "y": " b. nimitikkay (n)"
  },
  {
    "x": "nimitkî",
    "y": " b. nimitikî"
  },
  {
    "x": "nimiz, -i",
    "y": " b. nizm, -e"
  },
  {
    "x": "nimizê (m)",
    "y": " b. nizmî (m)"
  },
  {
    "x": "nimnayene",
    "y": " defnetmek"
  },
  {
    "x": "nimnayîş (n)",
    "y": " defnetme"
  },
  {
    "x": "nimta (m)",
    "y": " b. nimita (m)"
  },
  {
    "x": "nimte (n)",
    "y": " b. nimite (n)"
  },
  {
    "x": "nimtene",
    "y": " b. nimitene"
  },
  {
    "x": "nimtik (n)",
    "y": " b. nimitike (m)"
  },
  {
    "x": "nimtikî",
    "y": " b. nimitikî"
  },
  {
    "x": "nimtiş (n)",
    "y": " b. nimitiş (n)"
  },
  {
    "x": "nimtîye (m)",
    "y": " b. nimitîye (m)"
  },
  {
    "x": "nimûna (m)",
    "y": " b. nimûne (m)"
  },
  {
    "x": "nimûne (m)",
    "y": " 1)örnek, misal, numune \r\n~3)model"
  },
  {
    "x": "nimûna şahide (m)",
    "y": " şahit numune"
  },
  {
    "x": "nimûne girewtene",
    "y": " numune almak"
  },
  {
    "x": "nimûnegerî (m)",
    "y": " örnekleme (araştırma için)"
  },
  {
    "x": "nimûnegirewtiş (n)",
    "y": " numune alma"
  },
  {
    "x": "nimz, -e",
    "y": " b. nizm, -e"
  },
  {
    "x": "nimzey (m)",
    "y": " b. nizmîye (m)"
  },
  {
    "x": "nimzê (m)",
    "y": " b. nizmî (m)"
  },
  {
    "x": "ninc (m)",
    "y": " b. linge (m)"
  },
  {
    "x": "nincik (m)",
    "y": " b. nuçike (m)"
  },
  {
    "x": "ningi (m)",
    "y": " b. linge (m)"
  },
  {
    "x": "ninga nardoni (m)",
    "y": " b. linga nêrdîwane (m)"
  },
  {
    "x": "ningey nardoni (m)",
    "y": " b. linga nêrdîwane (m)"
  },
  {
    "x": "niq, -e",
    "y": " kekeme, keke, kekeç"
  },
  {
    "x": "niqikî poameyene",
    "y": " hık tutmak"
  },
  {
    "x": "niqikî poameyîne",
    "y": " b. niqikî poameyene"
  },
  {
    "x": "niqirnayene",
    "y": " b. neqirnayene"
  },
  {
    "x": "niqirnayox, -e",
    "y": " b. neqirnayox, -e"
  },
  {
    "x": "niqirnayoxey (m)",
    "y": " b. neqirnayoxî (m), neqirnayoxîye (m)"
  },
  {
    "x": "niqîye (m)",
    "y": " kekemelik, kekelik"
  },
  {
    "x": "niqri (m)",
    "y": " b. neqre (m)"
  },
  {
    "x": "niqut (n)",
    "y": " b. nutiq (n)"
  },
  {
    "x": "nirçik (m)",
    "y": " b. nîrçike (m)"
  },
  {
    "x": "nirçik kerdene",
    "y": " b. nîrçike kerdene"
  },
  {
    "x": "nirçik purye dayîne",
    "y": " b. nîrçike piro dayene"
  },
  {
    "x": "nirçikkerdiş (n)",
    "y": " b. nîrçikekerdiş (n)"
  },
  {
    "x": "nirçikpuryedayîş (n)",
    "y": " b. nîrçikepirodayîş (n)"
  },
  {
    "x": "nirnir (n)",
    "y": " b. miremir (n)"
  },
  {
    "x": "nirnir kerdene",
    "y": " b. miremir kerdene"
  },
  {
    "x": "nirnirkerdiş (n)",
    "y": " b. miremirkerdiş (n)"
  },
  {
    "x": "nirxnayene",
    "y": " değerlendirmek, kıymetlendirmek"
  },
  {
    "x": "nirxnayîş (n)",
    "y": " değerlendirme, kıymetlendirme"
  },
  {
    "x": "nirxnayîşê çorşmeyî (n)",
    "y": " çevre değerlendirmesi"
  },
  {
    "x": "nirxnayîşê dorûverî (n)",
    "y": " çevre değerlendirmesi"
  },
  {
    "x": "nirxnayîşê kontrolî (n)",
    "y": " kontrol değerlendirmesi"
  },
  {
    "x": "nirxnayîşê rîskî (n)",
    "y": " risk değerlendirmesi"
  },
  {
    "x": "nirxnayîşê tesîrê çorşmeyîyî (n)",
    "y": " çevresel etki değerlendirmesi"
  },
  {
    "x": "nirxnayîşê tesîrî (n)",
    "y": " etki değerlendirmesi"
  },
  {
    "x": "nisbet (n)",
    "y": " nispet, oran"
  },
  {
    "x": "nisbetê alkolî (n)",
    "y": " alkol nispeti, alkol oranı"
  },
  {
    "x": "nisbî, -ye",
    "y": " göreceli, orantısal, nispi"
  },
  {
    "x": "nistene",
    "y": " b. niştene"
  },
  {
    "x": "nisteni",
    "y": " b. niştene"
  },
  {
    "x": "… nişaynayene",
    "y": " b. … nişnayene"
  },
  {
    "x": "nişka",
    "y": " aniden, ansızın, birdenbire; ani"
  },
  {
    "x": "nişka merdene",
    "y": " aniden ölmek"
  },
  {
    "x": "nişka ra",
    "y": " b. nişka ve ra"
  },
  {
    "x": "nişka ra merdene",
    "y": " b. nişka ve ra merdene"
  },
  {
    "x": "nişka va",
    "y": " b. nişka ve ra"
  },
  {
    "x": "nişka ve ra",
    "y": " aniden, ansızın, birdenbire; ani"
  },
  {
    "x": "nişka ve ra merdene",
    "y": " aniden ölmek"
  },
  {
    "x": "nişkê",
    "y": " b. nişka"
  },
  {
    "x": "nişkê ra",
    "y": " b. nişka ve ra"
  },
  {
    "x": "nişkêk",
    "y": " b. nişka"
  },
  {
    "x": "nişkêk ra",
    "y": " b. nişka ve ra"
  },
  {
    "x": "nişnayene",
    "y": " bindirmek"
  },
  {
    "x": "… nişnayene",
    "y": " -e bindirmek"
  },
  {
    "x": "nişnayîş (n)",
    "y": " bindirme"
  },
  {
    "x": "niştayini",
    "y": " b. niştene"
  },
  {
    "x": "nişte (n)",
    "y": " b. nuşte (n)"
  },
  {
    "x": "niştene-I",
    "y": " 1)binmek\r\n~Lajek nişt ro qatire. (Oğlan katıra bindi.)\r\n~\t\r\n~2)inmek\r\n~\t\r\n~Teyara nişte. (Uçak indi.)"
  },
  {
    "x": "niştene-II",
    "y": " b. nuştene"
  },
  {
    "x": "niştêx (n)",
    "y": " b. nuştox (n)"
  },
  {
    "x": "niştiş-I (n)",
    "y": " binme"
  },
  {
    "x": "niştiş-II (n)",
    "y": " inme"
  },
  {
    "x": "niştiş-III (n)",
    "y": " b. nuştiş (n)"
  },
  {
    "x": "nişuer (n)",
    "y": " b. nîşor (n)"
  },
  {
    "x": "nişuer kerdene",
    "y": " b. nîşor kerdene"
  },
  {
    "x": "nivişt (n)",
    "y": " b. nuşte (n)"
  },
  {
    "x": "nivîne (m)",
    "y": " yatak\r\n~Nivîn xeyr. (Geçmiş olsun.)"
  },
  {
    "x": "nivîni (m)",
    "y": " b. nivîne (m)"
  },
  {
    "x": "nivîşte (n)",
    "y": " b. nuşte (n)"
  },
  {
    "x": "niw",
    "y": " b. new"
  },
  {
    "x": "niw rê se",
    "y": " b. new sey"
  },
  {
    "x": "niw sey",
    "y": " b. new sey"
  },
  {
    "x": "niweş (n)",
    "y": " b. nêweş (n)"
  },
  {
    "x": "niweş bîyayene",
    "y": " b. nêweş bîyene"
  },
  {
    "x": "niweş kotene",
    "y": " b. nêweş kewtene"
  },
  {
    "x": "niweş kerdene",
    "y": " b. nêweş kerdene"
  },
  {
    "x": "niweşo giran (n)",
    "y": " b. nêweşo giran (n)"
  },
  {
    "x": "niweşê (m)",
    "y": " b. nêweşî (m), nêweşîye (m)"
  },
  {
    "x": "niweşî (m)",
    "y": " b. nêweşî (m), nêweşîye (m)"
  },
  {
    "x": "niwet (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "niwete (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "niwê",
    "y": " b. neway"
  },
  {
    "x": "nix",
    "y": " (deveyi çöktürmek için çıkarılan ses) ıh"
  },
  {
    "x": "nix bîyene",
    "y": " çökmek (deve için), ıhmak (deve için)"
  },
  {
    "x": "nix kerdene",
    "y": " çöktürmek (deve için), ıhtırmak (deve için)"
  },
  {
    "x": "nixaj (n)",
    "y": " b. necax (n)"
  },
  {
    "x": "nixbîyayîş (n)",
    "y": " çökme (deve için), çöküş (deve için)"
  },
  {
    "x": "nixev (n)",
    "y": " b. lihêf (n)"
  },
  {
    "x": "nixkerdiş (n)",
    "y": " çöktürme (deve için)"
  },
  {
    "x": "nizdî",
    "y": " b. nêzdî, -ye"
  },
  {
    "x": "nizdîbîyayîş (n)",
    "y": " b. nêzdîbîyayîş (n)"
  },
  {
    "x": "nizdîk",
    "y": " b. nêzdî, -ye"
  },
  {
    "x": "nizdîy (m)",
    "y": " b. nêzdîye (m)"
  },
  {
    "x": "nizim, -e",
    "y": " b. nizm, -e"
  },
  {
    "x": "nizînayeni",
    "y": " b. nijnayene"
  },
  {
    "x": "nizm, -e",
    "y": " alçak, basık"
  },
  {
    "x": "nizm bîyene",
    "y": " alçaklaşmak, alçalmak"
  },
  {
    "x": "nizm kerdene",
    "y": " 1)alçaklaştırmak\r\n~2)kısmak (ses için) "
  },
  {
    "x": "nizmbîyayîş (n)",
    "y": " alçalma, alçaklaşma"
  },
  {
    "x": "nizmî (m)",
    "y": " alçaklık, basıklık"
  },
  {
    "x": "nizmîye (m)",
    "y": " alçaklık, basıklık"
  },
  {
    "x": "nizmkerdiş (n)",
    "y": " 1)alçaklaştırma\r\n~2)kısma (ses için)"
  },
  {
    "x": "niznayene",
    "y": " b. nijnayene"
  },
  {
    "x": "nî-I (zh, z)",
    "y": " b. nê-I (zh, z)"
  },
  {
    "x": "nî-II (zh, s)",
    "y": " b. nê-II (zh, s)"
  },
  {
    "x": "nî-III (n, s)",
    "y": " b. nê-IV (n, s)"
  },
  {
    "x": "nî-IV (zr)",
    "y": " b. ney-I (zr)"
  },
  {
    "x": "nî-V (n, z)",
    "y": " b. ney-II (n, z)"
  },
  {
    "x": "nî-VI",
    "y": " b. nê-VI"
  },
  {
    "x": "nî ... nî ...",
    "y": " b. ne ... ne ..."
  },
  {
    "x": "nî-VII",
    "y": " b. nê-VII"
  },
  {
    "x": "nî ... nî ...-I",
    "y": " b. nê ... nê ...-I"
  },
  {
    "x": "nî ... nî ...-II",
    "y": " b. ne ... ne ...-I"
  },
  {
    "x": "nîa",
    "y": " b. wina"
  },
  {
    "x": "nîaj (m)",
    "y": " b. nacnîye (m)¸ nacnî (m)"
  },
  {
    "x": "nîajin (m)",
    "y": " b. nacnîye (m)¸ nacnî (m)"
  },
  {
    "x": "nîaw",
    "y": " b. nîyaw"
  },
  {
    "x": "nîawayene",
    "y": " b. nîyawayene"
  },
  {
    "x": "nîawayîş (n)",
    "y": " b. nîyawayîş (n)"
  },
  {
    "x": "nîaznîe (m)",
    "y": " b. nacnîye (m), nacnî (m)"
  },
  {
    "x": "nîçik-I (n)",
    "y": " doruk, şahika, zirve"
  },
  {
    "x": "nîçik-II (n)",
    "y": " bızır, klitoris"
  },
  {
    "x": "nîçiki (m)",
    "y": " b. nuçike (m)"
  },
  {
    "x": "nîçilike (m)",
    "y": " b. nîçik-I (n)"
  },
  {
    "x": "nîerdwûn (m)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nîeweş, -e",
    "y": " b. nêweş, -e"
  },
  {
    "x": "nîeweşî (m)",
    "y": " b. nêweşî (m)"
  },
  {
    "x": "nîgar (n)",
    "y": " resim"
  },
  {
    "x": "nîgar viraştene",
    "y": " resim çizmek"
  },
  {
    "x": "nîgarkêş, -e",
    "y": " ressam"
  },
  {
    "x": "nîjad (n)",
    "y": " ırk "
  },
  {
    "x": "nîjadî",
    "y": " etnik, kavmi"
  },
  {
    "x": "nîjadnas, -e",
    "y": " budunbilimci, etnolog"
  },
  {
    "x": "nîjadnasî (m)",
    "y": " budunbilim, ırkiyat, etnoloji"
  },
  {
    "x": "nîjadnasîye (m)",
    "y": " budunbilim, ırkiyat, etnoloji"
  },
  {
    "x": "nîjadperest, -e",
    "y": " ırkçı"
  },
  {
    "x": "nîjadperestîye (m)",
    "y": " ırkçılık"
  },
  {
    "x": "nîjdî, -ye",
    "y": " b. nêzdî, -ye"
  },
  {
    "x": "Nîjer (n)",
    "y": " Nijer"
  },
  {
    "x": "Nîjerya (m)",
    "y": " Nijerya "
  },
  {
    "x": "nîjnayene",
    "y": " b. nijnayene"
  },
  {
    "x": "nîk (n)",
    "y": " kaldıraç (demirden olanı), manivela"
  },
  {
    "x": "Nîkaragua (m)",
    "y": " Nikaragua"
  },
  {
    "x": "nîkeh (n)",
    "y": " b. nîkih (n)"
  },
  {
    "x": "nîkih (n)",
    "y": " nikâh"
  },
  {
    "x": "nîkih betal bîyene",
    "y": " nikah bozulmak"
  },
  {
    "x": "nîkih betal kerdene",
    "y": " nikah bozmak"
  },
  {
    "x": "nîkihî ser o sonddayene",
    "y": " (birisine) nikahı üzerine yemin içirmek"
  },
  {
    "x": "nîkil (n)",
    "y": " zirve"
  },
  {
    "x": "nîkilê dare (n)",
    "y": " ağacın tepesi"
  },
  {
    "x": "nîkile (m)",
    "y": " b. b. nîkil (n)"
  },
  {
    "x": "nîkila dare (m)",
    "y": " b. nîkilê dare (n)"
  },
  {
    "x": "nîm",
    "y": " b. nêm"
  },
  {
    "x": "nîmcet, -e",
    "y": " b. nêmcet, -e"
  },
  {
    "x": "nîme (n)",
    "y": " b. nême (n)"
  },
  {
    "x": "nîmecet, -e",
    "y": " b. nêmcet, -e"
  },
  {
    "x": "nîmeroc (n)",
    "y": " b. nêmroje (m)"
  },
  {
    "x": "nîmeroc de",
    "y": " b. nêmroje de"
  },
  {
    "x": "nîmeroc ra pey",
    "y": " b. nêmroje ra pey"
  },
  {
    "x": "nîmeroc ra ver",
    "y": " b. nêmroje ra ver"
  },
  {
    "x": "nîmfelq (n)",
    "y": " b. nêmfelqe (n)"
  },
  {
    "x": "nîmfelqi (n)",
    "y": " b. nêmfelqe (n)"
  },
  {
    "x": "nîmfilqe (n)",
    "y": " b. nêmfelqe (n)"
  },
  {
    "x": "nîmjet, -i",
    "y": " b. nêmcet, -e"
  },
  {
    "x": "nîmlên (n)",
    "y": " b. nêmlên (n)"
  },
  {
    "x": "nîmolçeg (m)",
    "y": " b. nêmwelçeke (m)"
  },
  {
    "x": "nîmparçi (n)",
    "y": " b. nêmparçe (n)"
  },
  {
    "x": "nîmperçe (n)",
    "y": " b. nêmparçe (n)"
  },
  {
    "x": "nîmribîg (n)",
    "y": " b. nêmribik (n)"
  },
  {
    "x": "nîmroj (n)",
    "y": " b. nêmroje (m)"
  },
  {
    "x": "nîmroj di",
    "y": " b. nêmroje de"
  },
  {
    "x": "nîmroj ra pey",
    "y": " b. nêmroje ra pey"
  },
  {
    "x": "nîmroj ra ver",
    "y": " b. nêmroje ra ver"
  },
  {
    "x": "nîmroje (m)",
    "y": " b. nêmroje (m)"
  },
  {
    "x": "nîmrueci (m)",
    "y": " b. nêmroje (m)"
  },
  {
    "x": "nîmrueci di",
    "y": " b. nêmroje de"
  },
  {
    "x": "nîmrueci ra pê",
    "y": " b. nêmroje ra pey"
  },
  {
    "x": "nîmrueci ra ver",
    "y": " b. nêmroje ra ver"
  },
  {
    "x": "nîmruej (n)",
    "y": " b. nêmroje (m)"
  },
  {
    "x": "nîmşewe (m)",
    "y": " b. nêmşewe (m)"
  },
  {
    "x": "nîmşewi (m)",
    "y": " b. nêmşewe (m)"
  },
  {
    "x": "nîmtimin (n)",
    "y": " b. nêmtimin (n)"
  },
  {
    "x": "nîmwelç (m)",
    "y": " b. nêmwelçeke (m)"
  },
  {
    "x": "nîmwelçeg (m)",
    "y": " b. nêmwelçeke (m)"
  },
  {
    "x": "nîmwelçêg (m)",
    "y": " b. nêmwelçeke (m)"
  },
  {
    "x": "nîna (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "nînan (zh, z)",
    "y": " bunlar, bunları, bunlara"
  },
  {
    "x": "nîne (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "nînge (m)",
    "y": " b. linge (m)"
  },
  {
    "x": "nîni (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "nînî (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "nînû (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "nînûn (zh, z)",
    "y": " b. nînan (zh, z)"
  },
  {
    "x": "nîr (n)",
    "y": " b. nîre (n)"
  },
  {
    "x": "nîrbend (n)",
    "y": " boyunduruk çubuklarını birbirine bağlayan ip"
  },
  {
    "x": "nîrçik (m)",
    "y": " b. nîrçike (m)"
  },
  {
    "x": "nîrçik pavistene",
    "y": " b. nîrçike pavistene"
  },
  {
    "x": "nîrçike (m)",
    "y": " fiske"
  },
  {
    "x": "nîrçike kerdene",
    "y": " fiskelemek, fiske vurmak "
  },
  {
    "x": "nîrçike pavistene",
    "y": " fiskelemek, fiske vurmak"
  },
  {
    "x": "nîrçike piro dayene",
    "y": " fiskelemek, fiske vurmak "
  },
  {
    "x": "nîrçikekerdiş (n)",
    "y": " fiskeleme"
  },
  {
    "x": "nîrçikepavistiş (n)",
    "y": " fiskeleme"
  },
  {
    "x": "nîrçikepirodayîş (n)",
    "y": " fiskeleme"
  },
  {
    "x": "nîrçikpavistiş (n)",
    "y": " b. nîrçikepavistiş (n)"
  },
  {
    "x": "nîrdeni",
    "y": " b. nîritene"
  },
  {
    "x": "nîrdiş (n)",
    "y": " b. nîritiş (n)"
  },
  {
    "x": "nîre (n)",
    "y": " boyunduruk"
  },
  {
    "x": "nîre kerdene",
    "y": " çifte koşmak, koşmak"
  },
  {
    "x": "nîrengî (m)",
    "y": " nirengi"
  },
  {
    "x": "nîri (n)",
    "y": " b. nîre (n)"
  },
  {
    "x": "nîritene",
    "y": " yaymak (yayık vb. için)"
  },
  {
    "x": "nîriteni",
    "y": " b. nîritene"
  },
  {
    "x": "nîritiş (n)",
    "y": " yayma (yayık vb. için)"
  },
  {
    "x": "nîrîyayene",
    "y": " yayılmak (yayık vb. için)"
  },
  {
    "x": "nîrîyayîş (n)",
    "y": " yayılma (yayık vb. için)"
  },
  {
    "x": "nîrkutikî (zh)",
    "y": " b. mîrkutikî (zh)"
  },
  {
    "x": "nîrkutikî vetene",
    "y": " b. mîrkutikî vetene"
  },
  {
    "x": "nîsa (n)",
    "y": " b. nîşan (n)"
  },
  {
    "x": "nîsab (n)",
    "y": " nisap"
  },
  {
    "x": "nîsabê genimî (n)",
    "y": " buğday nisabı"
  },
  {
    "x": "nîsabê sêmî (n)",
    "y": " gümüş nisabı"
  },
  {
    "x": "nîsabê xeleyî (n)",
    "y": " buğday nisabı"
  },
  {
    "x": "nîsabê xelî (n)",
    "y": " b. nîsabê xeleyî (n)"
  },
  {
    "x": "nîsabê zerdî (n)",
    "y": " altın nisabı"
  },
  {
    "x": "nîsabê zerrnî (n)",
    "y": " altın nisabı"
  },
  {
    "x": "nîsabî genimî (n)",
    "y": " b. nîsabê genimî (n)"
  },
  {
    "x": "nîsabî sêmî (n)",
    "y": " b. nîsabê sêmî (n)"
  },
  {
    "x": "nîsabî sîmî (n)",
    "y": " b. nîsabê sêmî (n)"
  },
  {
    "x": "nîsabî xelî (n)",
    "y": " b. nîsabê xeleyî (n)"
  },
  {
    "x": "nîsabî zerdî (n)",
    "y": " b. nîsabê zerdî (n)"
  },
  {
    "x": "nîsabî zerrnî (n)",
    "y": " b. nîsabê zerrnî (n)"
  },
  {
    "x": "nîsan (n)",
    "y": " b. nîşan (n)"
  },
  {
    "x": "nîsane-I (m)",
    "y": " nisan "
  },
  {
    "x": "nîsane-II (m)",
    "y": " b. nîşan (n)"
  },
  {
    "x": "nîsani (m)",
    "y": " b. nîsane (m)"
  },
  {
    "x": "nîsbet (n)",
    "y": " b. nispet (n)"
  },
  {
    "x": "nîsbetî (m)",
    "y": " b. musîbete (m)"
  },
  {
    "x": "Nîse (m)",
    "y": " inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "Nîsi (m)",
    "y": " b. Nîse (m)"
  },
  {
    "x": "nîske (m)",
    "y": " mercimek"
  },
  {
    "x": "nîson (m)",
    "y": " b. nîsane (m)"
  },
  {
    "x": "nîsoni (m)",
    "y": " b. nîsane (m)"
  },
  {
    "x": "nîsor",
    "y": " b. nîşor (n)"
  },
  {
    "x": "nîsor kerdene",
    "y": " b. nîşor kerdene"
  },
  {
    "x": "nîstane (n)",
    "y": " b. nîştane (n)"
  },
  {
    "x": "nîsûn (m)",
    "y": " b. nîsane (m)"
  },
  {
    "x": "nîsûni (m)",
    "y": " b. nîsane (m)"
  },
  {
    "x": "nîşan (n)",
    "y": " 1)im, işaret\r\n~2)nişan, belirti\r\n~3)gösterge\r\n~nîşanê kêmekî (n): çıkarma işareti (mat), eksi işareti (mat)\r\n~nîşanê rayîrî (zh): yol işaretleri"
  },
  {
    "x": "nîşan bîyene",
    "y": " 1)işaretlenmek 2)işaret edilmek \r\n~3)nişanlanmak"
  },
  {
    "x": "nîşan dayene",
    "y": " göstermek"
  },
  {
    "x": "nîşan kerdene",
    "y": " 1)imlemek, işaretlemek\r\n~2)işaret etmek\r\n~3)nişanlamak"
  },
  {
    "x": "nîşanê kêmekî (n)",
    "y": " çıkarma işareti (mat), eksi işareti (mat)"
  },
  {
    "x": "nîşanê komkerdişî",
    "y": " toplama işareti (mat)\r\n~Des desna, kenê vîst. (10+10= 20) (On on daha yirmi eder.)"
  },
  {
    "x": "nîşanê parekî (n)",
    "y": " bölü işareti (mat)"
  },
  {
    "x": "nîşanê persî (n)",
    "y": " soru işareti"
  },
  {
    "x": "nîşanê rayîrî (zh)",
    "y": " yol işaretleri"
  },
  {
    "x": "nîşanê reykî (n)",
    "y": " çarpı işareti (mat), çarpma işareti (mat)"
  },
  {
    "x": "nîşanê roşnin/roştin û venginî (zh)",
    "y": " ışıklı ve sesli işaretler (trafik için)"
  },
  {
    "x": "nîşanê seyîye (n)",
    "y": " eşitlik işareti (mat)"
  },
  {
    "x": "nîşanê trafîkî (n)",
    "y": " trafik işareti"
  },
  {
    "x": "nîşanê trafîkî (zh)",
    "y": " trafik işaretleri"
  },
  {
    "x": "nîşanê vindetişî (n)",
    "y": " dur işareti"
  },
  {
    "x": "nîşanê zêdekî (n)",
    "y": " artı işareti (mat)"
  },
  {
    "x": "nîşanbîyaya (m)",
    "y": " nişanlı"
  },
  {
    "x": "nîşanbîyaye (n)",
    "y": " nişanlı"
  },
  {
    "x": "nîşanbîyayîye (m)",
    "y": " nişanlı"
  },
  {
    "x": "nîşanbîyayîş (n)",
    "y": " 1)işaretlenme\r\n~2)nişanlanma"
  },
  {
    "x": "nîşandar, -e",
    "y": " nişancı"
  },
  {
    "x": "nîşandayox (n)",
    "y": " gösterge"
  },
  {
    "x": "nîşankerda (m)",
    "y": " 1)işaretli\r\n~2)nişanlı"
  },
  {
    "x": "nîşankerde (n)",
    "y": " 1)işaretli\r\n~2)nişanlı"
  },
  {
    "x": "nîşankerdiş (n)",
    "y": " 1)imleme, işaretleme\r\n~2)nişanlama"
  },
  {
    "x": "nîşankerdîye (m)",
    "y": " 1)işaretli\r\n~2)nişanlı"
  },
  {
    "x": "nîşon (n)",
    "y": " b. nîşan (n)"
  },
  {
    "x": "nîşonbîyaya (m)",
    "y": " b. nîşanbîyaya (m)"
  },
  {
    "x": "nîşonbîyaye (n)",
    "y": " b. nîşanbîyaye (n)"
  },
  {
    "x": "nîşonkerda (m)",
    "y": " b. nîşankerda (m)"
  },
  {
    "x": "nîşonkerde (n)",
    "y": " b. nîşankerde (n)"
  },
  {
    "x": "nîşor (n)",
    "y": " geviş"
  },
  {
    "x": "nîşor kerdene",
    "y": " geviş getirmek"
  },
  {
    "x": "nîştane (n)",
    "y": " yüzük"
  },
  {
    "x": "nîştaneyê nîşanî (n)",
    "y": " alyans, nişan yüzüğü"
  },
  {
    "x": "nîştaneyo sêmên (n)",
    "y": " gümüş yüzük"
  },
  {
    "x": "nîşte (n)",
    "y": " b. nuşte (n)"
  },
  {
    "x": "nîştene",
    "y": " b. niştene-I"
  },
  {
    "x": "nîştêx (n)",
    "y": " b. nuştox (n)"
  },
  {
    "x": "nîştiş (n)",
    "y": " b. niştiş-I"
  },
  {
    "x": "nîştiş (n)",
    "y": " b. nuştiş (n)"
  },
  {
    "x": "nîşûn (n)",
    "y": " b. nîşan (n)"
  },
  {
    "x": "nîşûnbîyaya (m)",
    "y": " b. nîşanbîyaya (m)"
  },
  {
    "x": "nîşûnbîyaye (n)",
    "y": " b. nîşanbîyaye (n)"
  },
  {
    "x": "nîşûnkerda (m)",
    "y": " b. nîşankerda (m)"
  },
  {
    "x": "nîşûnkerde (n)",
    "y": " b. nîşankerde (n)"
  },
  {
    "x": "nîweş (n)",
    "y": " b. nêweş (n)"
  },
  {
    "x": "nîweşî (m)",
    "y": " b. nêweşî (m), nêweşîye (m)"
  },
  {
    "x": "nîwet (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "nîwet ra",
    "y": " b. nobete ra"
  },
  {
    "x": "nîwete (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "eve nîwete",
    "y": " b. bi nobete"
  },
  {
    "x": "nîwete ra",
    "y": " b. nobete ra"
  },
  {
    "x": "nîwki",
    "y": " b. nêke"
  },
  {
    "x": "nîy (n, z)",
    "y": " b. ney-II (n, z)"
  },
  {
    "x": "nîya-I",
    "y": " b. wina"
  },
  {
    "x": "nîya-II",
    "y": " b. nê-VI"
  },
  {
    "x": "nîya",
    "y": " b. nê-VII"
  },
  {
    "x": "nîya ...nîya ...",
    "y": " b. nê ... nê ..."
  },
  {
    "x": "nîyac (m)",
    "y": " b. nacnîye (m), nacnî (m)"
  },
  {
    "x": "nîyace (m)",
    "y": " b. nacnîye (m), nacnî (m)"
  },
  {
    "x": "nîyadawox, -e",
    "y": " b. nîyadayox, -e"
  },
  {
    "x": "nîyadayene",
    "y": " bakmak, izlemek, seyretmek, temaşa etmek"
  },
  {
    "x": "nîyade",
    "y": " bak"
  },
  {
    "x": "... de nîyadayene",
    "y": " -e bakmak"
  },
  {
    "x": "nîyadayîş (n)",
    "y": " 1)bakma, izleme, seyretme\r\n~2)bakış\r\n~3)bakım"
  },
  {
    "x": "nîyadayîşê çermî (n)",
    "y": " cilt bakım"
  },
  {
    "x": "nîyadayox, -e",
    "y": " bakan, izleyen, izleyici, seyirci"
  },
  {
    "x": "nîyaj (m)",
    "y": " b. nacnîye (m), nacnî (m)"
  },
  {
    "x": "nîyaje (m)",
    "y": " b. nacnîye (m), nacnî (m)"
  },
  {
    "x": "nîyaji (m)",
    "y": " b. nacnîye (m), nacnî (m)"
  },
  {
    "x": "nîyajinîye (m)",
    "y": " b. nacnîye (m)"
  },
  {
    "x": "nîyajnî (m)",
    "y": " b. nacnîye (m)"
  },
  {
    "x": "nîyajnîye (m)",
    "y": " b. nacnîye (m), nacnî (m)"
  },
  {
    "x": "nîyam (n)",
    "y": " b. mîyan (n)"
  },
  {
    "x": "Nîyamey",
    "y": " Niamey"
  },
  {
    "x": "nîyan",
    "y": " b. nê-VI"
  },
  {
    "x": "nîyan",
    "y": " b. nê-VII "
  },
  {
    "x": "nîyan ... nîyan",
    "y": " b. nê ... nê ..."
  },
  {
    "x": "nîyanê-I",
    "y": " b. winasî, -ye; inasarên, -e"
  },
  {
    "x": "nîyanê-II",
    "y": " b. nê-VI"
  },
  {
    "x": "nîyanê",
    "y": " b. winasî, inasarên"
  },
  {
    "x": "nîyanên, -e",
    "y": " b. winasî, -ye; inasarên, -e"
  },
  {
    "x": "nîyaw",
    "y": " miyav"
  },
  {
    "x": "nîyawayene",
    "y": " miyavlamak"
  },
  {
    "x": "nîyawayîş (n)",
    "y": " miyavlama"
  },
  {
    "x": "nîyawkayene",
    "y": " miyavlamak"
  },
  {
    "x": "nîyawkayîş (n)",
    "y": " miyavlama"
  },
  {
    "x": "nîyaz-I (n)",
    "y": " adak"
  },
  {
    "x": "nîyaz bîyene",
    "y": " adanmak"
  },
  {
    "x": "nîyaz kerdene",
    "y": " adamak"
  },
  {
    "x": "nîyaz-II (m)",
    "y": " b. nacnîye (m)"
  },
  {
    "x": "nîyazbîyayîş (n)",
    "y": " adanma"
  },
  {
    "x": "nîyaze (m)",
    "y": " b. nacnîye (m)¸ nacnî (m)"
  },
  {
    "x": "nîyazkerdiş (n)",
    "y": " adama"
  },
  {
    "x": "nîye-I (zr)",
    "y": " b. ney-I (zr)"
  },
  {
    "x": "nîye-II",
    "y": " b. nê-VI"
  },
  {
    "x": "nîye ... nîye ...-I",
    "y": " b. nê ... nê ...-I"
  },
  {
    "x": "nîye ... nîye ...-II",
    "y": " b. ne ... ne ...-I"
  },
  {
    "x": "nîyegi",
    "y": " b. nêke"
  },
  {
    "x": "nîyeki",
    "y": " b. nêke"
  },
  {
    "x": "nye ... nye ...",
    "y": " b. ne ... ne ...-I"
  },
  {
    "x": "nîyeman (n)",
    "y": " b. mêman, -e"
  },
  {
    "x": "nîyemandar (n)",
    "y": " b. mêmandar, -e"
  },
  {
    "x": "nîyemanperwer, -i",
    "y": " b. mêmanperwer, -e"
  },
  {
    "x": "nîyemon (n)",
    "y": " b. mêman, -e"
  },
  {
    "x": "nîyemondar (n)",
    "y": " b. mêmandar, -e"
  },
  {
    "x": "nîyemûn (n)",
    "y": " b. mêman, -e"
  },
  {
    "x": "nîyemûndar (n)",
    "y": " b. mêmandar, -e"
  },
  {
    "x": "nîyeweş, -e",
    "y": " b. nêweş, -e"
  },
  {
    "x": "nîyeweşî (m)",
    "y": " b. nêweşî (m)"
  },
  {
    "x": "nîyî (zh, z)",
    "y": " b. nê-I (zh, z)"
  },
  {
    "x": "nîyo",
    "y": " b. nê-VI"
  },
  {
    "x": "nîyoncik (m)",
    "y": " b. nuçike (m)"
  },
  {
    "x": "nîyonê",
    "y": " b. nê-VI"
  },
  {
    "x": "nîyonî",
    "y": " b. nê-VI"
  },
  {
    "x": "nîyû",
    "y": " b. nê-VI"
  },
  {
    "x": "nîyû nê",
    "y": " b. nê-VI"
  },
  {
    "x": "nîyûgi",
    "y": " b. nêke"
  },
  {
    "x": "nîzam (n)",
    "y": " düzen, nizam"
  },
  {
    "x": "nîzam awan kerdene",
    "y": " düzen kurmak"
  },
  {
    "x": "nîzam ronayene",
    "y": " düzen kurmak"
  },
  {
    "x": "nîzam tesîs kerdene",
    "y": " düzen kurmak"
  },
  {
    "x": "nîzamo blok (n)",
    "y": " blok nizam (imar için)"
  },
  {
    "x": "nîzamo cîya (n)",
    "y": " ayrık nizam (imar için)"
  },
  {
    "x": "nîzamo pêranîyaye (n)",
    "y": " (imar için) bitişik nizam"
  },
  {
    "x": "nîzamo pêroyî (n)",
    "y": " kamu düzeni"
  },
  {
    "x": "nîzamo umûmî (n)",
    "y": " kamu düzeni"
  },
  {
    "x": "nîzamin, -e",
    "y": " düzenli, intizamlı, nizamlı"
  },
  {
    "x": "nîzamname (n)",
    "y": " tüzük"
  },
  {
    "x": "nîzdî",
    "y": " b. nêzdî, -ye"
  },
  {
    "x": "nîzik, -e",
    "y": " b. nêzdî, -ye"
  },
  {
    "x": "nîznayêne",
    "y": " b. nijnayene"
  },
  {
    "x": "no-I (n, z)",
    "y": " bu"
  },
  {
    "x": "no wo",
    "y": " b. no yo"
  },
  {
    "x": "no yo",
    "y": " budur, işte"
  },
  {
    "x": "ney (n, z)",
    "y": " buna, bunu"
  },
  {
    "x": "no-II (n, s)",
    "y": " bu\r\n~No lajek vano. (Bu oğlan söylüyor.)"
  },
  {
    "x": "no rîbal ra",
    "y": " b. nê rîwalî ra"
  },
  {
    "x": "no sevev ra",
    "y": " b. nê sebebî ra"
  },
  {
    "x": "no sewet ra",
    "y": " b. nê semedî ra"
  },
  {
    "x": "no ter",
    "y": " b. no tewir"
  },
  {
    "x": "no tewir",
    "y": " 1)bu tür, böyle\r\n~2)böylece, böylecene, böylelikle"
  },
  {
    "x": "no tir",
    "y": " b. no tewir"
  },
  {
    "x": "no tore",
    "y": " b. no tewir"
  },
  {
    "x": "no tur",
    "y": " b. no tewir"
  },
  {
    "x": "no-III (s)",
    "y": " keskin"
  },
  {
    "x": "no bîyene ",
    "y": "keskinleşmek"
  },
  {
    "x": "no kerdene",
    "y": " keskinleştirmek, keskinletmek"
  },
  {
    "x": "no-IV (n)",
    "y": " b. nan (n)"
  },
  {
    "x": "no-V",
    "y": " b. new"
  },
  {
    "x": "no sey",
    "y": " b. new sey"
  },
  {
    "x": "nob (m)",
    "y": " b. nobe (m)"
  },
  {
    "x": "nob şîyayîne",
    "y": " b. nobe de şîyene"
  },
  {
    "x": "nobe (m)",
    "y": " nöbet"
  },
  {
    "x": "nobe de şîyene",
    "y": " hayvanları gütme sırası gelince hayvanları gütmek"
  },
  {
    "x": "nobe girewtene",
    "y": " nöbet tutmak"
  },
  {
    "x": "nobe kerdene",
    "y": " nöbetleşmek"
  },
  {
    "x": "nobe pawitene",
    "y": " nöbet beklemek"
  },
  {
    "x": "nobe reyde",
    "y": " nöbetleşe"
  },
  {
    "x": "nobe reyra",
    "y": " nöbetleşe"
  },
  {
    "x": "nobe tepîştene",
    "y": " nöbet tutmak"
  },
  {
    "x": "nobeçî, -ye",
    "y": " nöbetçi"
  },
  {
    "x": "nobeçîyî (m)",
    "y": " nöbetçilik"
  },
  {
    "x": "nobedar, -e",
    "y": " nöbetçi"
  },
  {
    "x": "nobedarey (m)",
    "y": " b. nobedarî (m)"
  },
  {
    "x": "nobedarênî (m)",
    "y": " nöbetçilik"
  },
  {
    "x": "nobedarî (m)",
    "y": " nöbetçilik"
  },
  {
    "x": "nobedarîye (m)",
    "y": " nöbetçilik"
  },
  {
    "x": "nobet (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "nobetçênî (m)",
    "y": " nöbetçilik"
  },
  {
    "x": "nobetçî, -ye",
    "y": " b. nobeçî, -ye"
  },
  {
    "x": "nobetçîtî (m)",
    "y": " nöbetçilik"
  },
  {
    "x": "nobetçîyey (m)",
    "y": " b. nobetçîyî (m)"
  },
  {
    "x": "nobetçîyî (m)",
    "y": " nöbetçilik"
  },
  {
    "x": "nobete (m)",
    "y": " nöbet"
  },
  {
    "x": "nobete de şîyene",
    "y": " hayvanları gütme sırası gelince hayvanları gütmek"
  },
  {
    "x": "nobete girewtene",
    "y": " nöbet tutmak"
  },
  {
    "x": "nobete kerdene",
    "y": " nöbetleşmek"
  },
  {
    "x": "nobete pawitene",
    "y": " nöbet beklemek"
  },
  {
    "x": "nobete ra",
    "y": " nöbetleşe"
  },
  {
    "x": "nobete reyde",
    "y": " nöbetleşe"
  },
  {
    "x": "nobete reyra",
    "y": " nöbetleşe"
  },
  {
    "x": "nobete tepîştene",
    "y": " nöbet tutmak"
  },
  {
    "x": "nobeti (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "nobi (m)",
    "y": " b. nobe (m)"
  },
  {
    "x": "nobîyayîş (n)",
    "y": " keskinleşme"
  },
  {
    "x": "noca (n)",
    "y": " bura, burası, burada"
  },
  {
    "x": "Noel (n)",
    "y": " 1)Noel Baba\r\n~2)Yılbaşı Dedesi"
  },
  {
    "x": "nofe (m)",
    "y": " kez, kere, defa, sefer\r\n~Çend nofî ame? (Kaç kez geldi?)"
  },
  {
    "x": "nofê",
    "y": " bir kez"
  },
  {
    "x": "nofêk",
    "y": " bir kez"
  },
  {
    "x": "nogine (m)",
    "y": " düve"
  },
  {
    "x": "nogini (m)",
    "y": " b. nogine (m)"
  },
  {
    "x": "nohet",
    "y": " bu taraf, bu yaka, beri"
  },
  {
    "x": "nohet-ohet",
    "y": " bu taraf o taraf, öte beri"
  },
  {
    "x": "nohêm (n)",
    "y": " beri, bu taraf, bu yaka"
  },
  {
    "x": "nohêm-ohêm",
    "y": " bu yaka karşı yaka, bu taraf o taraf, o yaka bu yaka"
  },
  {
    "x": "nokerdiş (n)",
    "y": " keskinleştirme"
  },
  {
    "x": "nomdar, -i",
    "y": " b. namdar, -e"
  },
  {
    "x": "nome (n)",
    "y": " b. name (n)"
  },
  {
    "x": "nomedar, -i",
    "y": " b. namdar, -e"
  },
  {
    "x": "nomi (n)",
    "y": " b. name (n) "
  },
  {
    "x": "nomis (n)",
    "y": " b. namûs (n)"
  },
  {
    "x": "nomus (n)",
    "y": " b. namûs (n)"
  },
  {
    "x": "nomûs (n)",
    "y": " b. namûs (n)"
  },
  {
    "x": "non-I (n)",
    "y": " b. nan (n)"
  },
  {
    "x": "non dayene",
    "y": " b. nan dayene"
  },
  {
    "x": "non ra birîyayene",
    "y": " b. nan ra birîyayene"
  },
  {
    "x": "non vetene",
    "y": " b. nan vetene"
  },
  {
    "x": "non û soli",
    "y": " b. nan û sole"
  },
  {
    "x": "nonê belxulî (n)",
    "y": " b. nanê belxurî (n)"
  },
  {
    "x": "nonê riwenî (n)",
    "y": " b. nanê rûnî (n)"
  },
  {
    "x": "nonê sacî (n)",
    "y": " b. nanê sacî (n)"
  },
  {
    "x": "nonê tawa (n)",
    "y": " b. nanê taweyî (n)"
  },
  {
    "x": "nonê tewa (n)",
    "y": " b. nanê taweyî (n)"
  },
  {
    "x": "nonê tîre (n)",
    "y": " b. nanê tîrî (n)"
  },
  {
    "x": "nonê tîri (n)",
    "y": " b. nanê tîrî (n)"
  },
  {
    "x": "nonê veyvî (n)",
    "y": " b. nanê veyveyî (n)"
  },
  {
    "x": "nonê xwi vetene",
    "y": " b. nanê xo vetene"
  },
  {
    "x": "nonê ... vetene",
    "y": " b. nanê ... vetene"
  },
  {
    "x": "nono anculî (n)",
    "y": " b. nano anculî (n)"
  },
  {
    "x": "nono eşkeva (n)",
    "y": " b. nano eşkeva (n)"
  },
  {
    "x": "nono eşkewa (n)",
    "y": " b. nano eşkeva (n)"
  },
  {
    "x": "nono husk (n)",
    "y": " b. nano huşk (n)"
  },
  {
    "x": "nono mîrazkerde (n)",
    "y": " b. nano mîrazkerde (n)"
  },
  {
    "x": "nono onculî (n)",
    "y": " b. nano anculî (n)"
  },
  {
    "x": "nono patîle (n)",
    "y": " b. nano patîre (n)"
  },
  {
    "x": "nono patîre (n)",
    "y": " b. nano patîre (n)"
  },
  {
    "x": "nono wişk (n)",
    "y": " b. nano huşk"
  },
  {
    "x": "nono yoxe (n)",
    "y": " b. nano yowxe (n)"
  },
  {
    "x": "nono ziwa (n)",
    "y": " b. nano ziwa (n)"
  },
  {
    "x": "non-II (m)",
    "y": " b. nane-I (m)"
  },
  {
    "x": "noncik (m)",
    "y": " b. nuçike (m)"
  },
  {
    "x": "nondar, -e",
    "y": " b. nandar, -e"
  },
  {
    "x": "noni (m)",
    "y": " b. nane-I (m)"
  },
  {
    "x": "noqta (m)",
    "y": " b. nuqta (m)"
  },
  {
    "x": "nordane (m)",
    "y": " b. nêrdîwane (m)"
  },
  {
    "x": "nor-dor",
    "y": " b. norî-orî"
  },
  {
    "x": "nor-dore",
    "y": " b. norî-orî"
  },
  {
    "x": "norî-orî",
    "y": " çevre, etraf"
  },
  {
    "x": "norûdorî",
    "y": " çevre, etraf"
  },
  {
    "x": "norm (n)",
    "y": " norm"
  },
  {
    "x": "normal, -e",
    "y": " olağan, normal"
  },
  {
    "x": "Norweç (n)",
    "y": " Norveç"
  },
  {
    "x": "nosyon (n)",
    "y": " kavram"
  },
  {
    "x": "nosyonkî, -ye",
    "y": " kavramsal"
  },
  {
    "x": "noşîyaene",
    "y": " b. nusîyayene"
  },
  {
    "x": "noşîyayene",
    "y": " b. nusîyayene"
  },
  {
    "x": "not (n)",
    "y": " not"
  },
  {
    "x": "notê planî (zh)",
    "y": " plan notları"
  },
  {
    "x": "nota (m)",
    "y": " nota (m)"
  },
  {
    "x": "notaya cewabîye (m)",
    "y": " cevabi nota"
  },
  {
    "x": "noter, -e",
    "y": " noter"
  },
  {
    "x": "noterîye (m)",
    "y": " noterlik "
  },
  {
    "x": "notr, -e",
    "y": " nötr "
  },
  {
    "x": "nova (m)",
    "y": " b. nobe (m)"
  },
  {
    "x": "nova şîyene",
    "y": " b. nobe de şîyene"
  },
  {
    "x": "nove (m)",
    "y": " b. nobe (m)"
  },
  {
    "x": "noveçî, -ye",
    "y": " b. nobeçî, -ye"
  },
  {
    "x": "novedar, -e",
    "y": " b. nobedar, -e"
  },
  {
    "x": "nover (n)",
    "y": " beri, bu taraf, bu yaka"
  },
  {
    "x": "nover-bover",
    "y": " bu taraf o taraf, bu yaka karşı yaka, öte beri"
  },
  {
    "x": "novera-bovera",
    "y": " bu tarafta o tarafta, ötede beride"
  },
  {
    "x": "nover-wever",
    "y": " bu taraf o taraf, bu yaka karşı yaka, öte beri"
  },
  {
    "x": "novera-wevera",
    "y": " bu tarafta o tarafta, ötede beride"
  },
  {
    "x": "noverên, -e",
    "y": " beriki, berideki"
  },
  {
    "x": "noverin, -e",
    "y": " b. noverên, -e"
  },
  {
    "x": "novete (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "noveti (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "novetî (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "now",
    "y": " b. new"
  },
  {
    "x": "now sey",
    "y": " b. new sey"
  },
  {
    "x": "noway",
    "y": " b. neway"
  },
  {
    "x": "nowbe (m)",
    "y": " b. nobe (m)"
  },
  {
    "x": "nowbeçî, -ye",
    "y": " b. nobeçî, -ye"
  },
  {
    "x": "nowbedar, -e",
    "y": " b. nobedar, -e"
  },
  {
    "x": "nowbetçî, -ye",
    "y": " b. nobeçî, -ye"
  },
  {
    "x": "nowbeti (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "nowbi (m)",
    "y": " b. nobe (m)"
  },
  {
    "x": "nowedar, -i",
    "y": " b. nobedar, -e"
  },
  {
    "x": "nowedarê (m)",
    "y": " b. nobedarî (m)"
  },
  {
    "x": "nowet (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "nowsî (m)",
    "y": " b. newsî-II (m)"
  },
  {
    "x": "noxe (n)",
    "y": " b. xone (n)"
  },
  {
    "x": "noxta (m)",
    "y": " b. nuqta (m)"
  },
  {
    "x": "noyênî (m)",
    "y": " b. noyîye (m)"
  },
  {
    "x": "noyin",
    "y": " b. newin, -e"
  },
  {
    "x": "noyî (m)",
    "y": " b. noyîye (m)"
  },
  {
    "x": "noyîye (m)",
    "y": " keskinlik"
  },
  {
    "x": "noyun",
    "y": " b. newin, -e"
  },
  {
    "x": "nu-I (n, z)",
    "y": " b. no-I (n, z)"
  },
  {
    "x": "nu-II (n, s)",
    "y": " b. no-II (n, s)"
  },
  {
    "x": "nuciki (m)",
    "y": " b. nuçike (m)"
  },
  {
    "x": "nuçike (m)",
    "y": " yumruk"
  },
  {
    "x": "nuçiki (m)",
    "y": " b. nuçike (m)"
  },
  {
    "x": "nuçikî eştene",
    "y": " yumruk atmak, yumruk indirmek "
  },
  {
    "x": "nuçikî kerdene",
    "y": " yumruklamak"
  },
  {
    "x": "nuçikî piro dayene",
    "y": " yumruk atmak, yumruk indirmek"
  },
  {
    "x": "nuçikî piro ginayene",
    "y": " yumruklanmak"
  },
  {
    "x": "nuçikî werdene",
    "y": " yumruklanmak"
  },
  {
    "x": "nuçikîeştiş (n)",
    "y": " yumruk atma"
  },
  {
    "x": "nuçikîkerdiş (n)",
    "y": " yumruklama"
  },
  {
    "x": "nuçikîpirodayîş (n)",
    "y": " yumruk atma"
  },
  {
    "x": "nuçikîpiroginayîş (n)",
    "y": " yumruklanma"
  },
  {
    "x": "nuçikîwerdiş (n)",
    "y": " yumruklanma"
  },
  {
    "x": "nuebet (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "nufisi (m)",
    "y": " b. nufus (n)"
  },
  {
    "x": "nufus (n)",
    "y": " nüfus"
  },
  {
    "x": "nufuso macir (n)",
    "y": " göçmen nüfus, muhacir nüfus"
  },
  {
    "x": "nufûs (n)",
    "y": " b. nufus (n)"
  },
  {
    "x": "nuke (m)",
    "y": " nohut"
  },
  {
    "x": "nuka sîyaye (m)",
    "y": " kuruyunca siyahlaşan bir çeşit nohut"
  },
  {
    "x": "nukleer, -e",
    "y": " nükleer"
  },
  {
    "x": "Nukualofa (m)",
    "y": " Nuku’alofa"
  },
  {
    "x": "numere (n)",
    "y": " b. numre (n)"
  },
  {
    "x": "numitene",
    "y": " b. nimitene"
  },
  {
    "x": "numitiş (n)",
    "y": " b. nimitiş (n)"
  },
  {
    "x": "numitkî",
    "y": " b. nimitikî"
  },
  {
    "x": "numre (n)",
    "y": " numara"
  },
  {
    "x": "numreyê berê teberî (n)",
    "y": " dış kapı numarası"
  },
  {
    "x": "numreyê berê zereyî (n)",
    "y": " iç kapı numarası"
  },
  {
    "x": "numreyê plaka (n)",
    "y": " plaka numarası"
  },
  {
    "x": "numreyê sîcîlî (n)",
    "y": " sicil numarası"
  },
  {
    "x": "numreyo rêzkî (n) (TE)",
    "y": " dizisel numara, seri numarası"
  },
  {
    "x": "numri (n)",
    "y": " b. numre (n)"
  },
  {
    "x": "numûne (m)",
    "y": " b. nimûne (m)"
  },
  {
    "x": "nuncik (m)",
    "y": " b. nuçike (m)"
  },
  {
    "x": "nunciki (m)",
    "y": " b. nuçike (m)"
  },
  {
    "x": "nuncikî kerdeni",
    "y": " b. nuçikî kerdene "
  },
  {
    "x": "nuncikî piro ginayene",
    "y": " b. nuçikî piro ginayene"
  },
  {
    "x": "nuncikî werdeni",
    "y": " b. nuçikî werdene"
  },
  {
    "x": "nuncikîkerdiş (n)",
    "y": " b. nuçikîkerdiş (n)"
  },
  {
    "x": "nuncikîpiroginayîş (n)",
    "y": " b. nuçikîpiroginayîş (n)"
  },
  {
    "x": "nuncikîpuryedayîş (n)",
    "y": " b. nuçikîpirodayîş (n)"
  },
  {
    "x": "nuncikîwerdiş (n)",
    "y": " b. nuçikîwerdiş (n)"
  },
  {
    "x": "nuqit (n)",
    "y": " b. nutiq (n)"
  },
  {
    "x": "nuqta (m)",
    "y": " nokta"
  },
  {
    "x": "nuqtaya merkezî",
    "y": " odak"
  },
  {
    "x": "nuqtaya nîrengî (m)",
    "y": " nirengi noktası"
  },
  {
    "x": "nuqtaya pabestişî (m)",
    "y": " bağlantı noktası"
  },
  {
    "x": "nuqtaya pagirêdayîşî (m)",
    "y": " bağlantı noktası"
  },
  {
    "x": "nuqtayê peymîye (zh)",
    "y": " ölçüm noktaları"
  },
  {
    "x": "nuqtaya pêrabestişî (m)",
    "y": " bağlantı noktası"
  },
  {
    "x": "nuqtaya pêragirêdayîşî (m)",
    "y": " bağlantı noktası"
  },
  {
    "x": "nuqta-vîrgul (n)",
    "y": " noktalı virgül"
  },
  {
    "x": "nuqtikî vero ameyene",
    "y": " hık tutmak"
  },
  {
    "x": "nuqurnayene",
    "y": " b. neqirnayene"
  },
  {
    "x": "nuqurnayox, -e",
    "y": " b. neqirnayox, -e"
  },
  {
    "x": "nuqurnayoxê (m)",
    "y": " b. neqirnayoxî (m), neqirnayoxîye (m)"
  },
  {
    "x": "nur (n)",
    "y": " ışık, nur "
  },
  {
    "x": "nurcik (m)",
    "y": " b. nuçike (m)"
  },
  {
    "x": "nurcikîpuryedayîş (n)",
    "y": " b. nuçikîpirodayîş (n)"
  },
  {
    "x": "nusîyayene",
    "y": " yazılmak"
  },
  {
    "x": "nusîyayîş (n)",
    "y": " yazılma"
  },
  {
    "x": "nustene",
    "y": " b. nuştene"
  },
  {
    "x": "nusxan, -e",
    "y": " b. nuxsan, -e"
  },
  {
    "x": "nusxan, -i",
    "y": " b. nuxsan, -e"
  },
  {
    "x": "nusxon, -i",
    "y": " b. nuxsan, -e"
  },
  {
    "x": "nuşka",
    "y": " b. nişka"
  },
  {
    "x": "nuşta (m)",
    "y": " yazılı "
  },
  {
    "x": "nuştarî (zh)",
    "y": " beştaş [oyunu]"
  },
  {
    "x": "nuştaye (n)",
    "y": " b. nuşte (n)"
  },
  {
    "x": "nuşte (n)",
    "y": " 1)yazı, yazılı\r\n~2)muska"
  },
  {
    "x": "nuşteyê şayî (n)",
    "y": " diktraksiyon"
  },
  {
    "x": "nuşteyê warî (zh)",
    "y": " alanyazın"
  },
  {
    "x": "nuşteyo cewabî (n)",
    "y": " cevabi yazı"
  },
  {
    "x": "nuşteyo fermî (n)",
    "y": " resmî yazı"
  },
  {
    "x": "nuşteyo resmî (n)",
    "y": " resmî yazı"
  },
  {
    "x": "nuştegeh (n)",
    "y": " 1)yazıhane\r\n~2)kalem (yazı işlerinin görüldüğü yer)"
  },
  {
    "x": "nuştegehê karê nuşteyan (n)",
    "y": " yazı işleri kalemi"
  },
  {
    "x": "nuştegeho xususî",
    "y": " özel kalem"
  },
  {
    "x": "nuşteke (m)",
    "y": " favori"
  },
  {
    "x": "nuştekî, -ye",
    "y": " yazılı\r\n~ziwano nuştekî (n): yazılı dil"
  },
  {
    "x": "ziwano nuştekî (n)",
    "y": " yazılı dil"
  },
  {
    "x": "nuştene",
    "y": " yazmak"
  },
  {
    "x": "nuştesî (m)",
    "y": " kitabe"
  },
  {
    "x": "nuştex (n)",
    "y": " b. nuştox (n)"
  },
  {
    "x": "nuştêx (n)",
    "y": " b. nuştox (n)"
  },
  {
    "x": "nuştiş (n)",
    "y": " 1)yazma\r\n~2)yazım (yazma işi)"
  },
  {
    "x": "ziwanê nuştişî (n)",
    "y": " yazı dili, 2)yazım (yazma işi)"
  },
  {
    "x": "nuştîye (m)",
    "y": " yazılı"
  },
  {
    "x": "nuştox, -e",
    "y": " yazar; yazan, kâtip\r\n~nuştoxa metnî: metin yazarı (kadın), tekst yazarı (kadın)"
  },
  {
    "x": "nuştoxa dramî (m)",
    "y": " dram yazarı"
  },
  {
    "x": "nuştoxa metnî",
    "y": " metin yazarı (kadın), tekst yazarı (kadın)"
  },
  {
    "x": "nuştoxê dramî (n)",
    "y": " dram yazarı"
  },
  {
    "x": "nuştoxê metnî",
    "y": " metin yazarı (erkek), tekst yazarı (erkek)"
  },
  {
    "x": "nuştoxe (m)",
    "y": " kadın yazar, kâtibe"
  },
  {
    "x": "nuştoxey (m)",
    "y": " b. nuştoxîye (m)"
  },
  {
    "x": "nuştoxê (m)",
    "y": " b. nuştoxîye (m)"
  },
  {
    "x": "nuştoxi (m)",
    "y": " b. nuştoxe (m)"
  },
  {
    "x": "nuştoxîye (m)",
    "y": " yazarlık"
  },
  {
    "x": "nuştuex",
    "y": " b. nuştox, -e"
  },
  {
    "x": "nut (m)",
    "y": " b. nute (m)"
  },
  {
    "x": "nute (m)",
    "y": " bit yavrusu, yavşak"
  },
  {
    "x": "nuti (m)",
    "y": " b. nute (m)"
  },
  {
    "x": "nutike (m)",
    "y": " bit yavrusu, yavşak"
  },
  {
    "x": "nutiki (m)",
    "y": " b. nutike (n)"
  },
  {
    "x": "nutiq (n)",
    "y": " nutuk"
  },
  {
    "x": "nutiq dayene",
    "y": " nutuk vermek "
  },
  {
    "x": "nutuq (n)",
    "y": " b. nutiq (n)"
  },
  {
    "x": "Nuuk (n)",
    "y": " b. Godthab (n)"
  },
  {
    "x": "nuweş (n)",
    "y": " b. nêweş (n)"
  },
  {
    "x": "nuwêt (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "nuxrî (n)",
    "y": " ailede ilk çocuk"
  },
  {
    "x": "nuxsan, -e",
    "y": " görme engelli¸ görmez, âmâ, gözsüz, kör"
  },
  {
    "x": "nuxta (m)",
    "y": " b. nuqta (m)"
  },
  {
    "x": "nuxtikî vero hameyîne",
    "y": " b. nuqtikî vero ameyene"
  },
  {
    "x": "nuzayene",
    "y": " hafifçe inlemek"
  },
  {
    "x": "nuzayîne",
    "y": " b. nuzayene"
  },
  {
    "x": "nuzayîş (n)",
    "y": " hafifçe inleme"
  },
  {
    "x": "nuzdî",
    "y": " b. nêzdî, -ye"
  },
  {
    "x": "nuzdîy (m)",
    "y": " b. nêzdîye (m)"
  },
  {
    "x": "nû-I (n, z)",
    "y": " b. no-I (n, z)"
  },
  {
    "x": "nû-II (n, s)",
    "y": " b. no-II (n, s)"
  },
  {
    "x": "nû-III (s)",
    "y": " b. no-III (s)"
  },
  {
    "x": "nû bîyayene",
    "y": " b. no bîyene"
  },
  {
    "x": "nû kerdene",
    "y": " b. no kerdene"
  },
  {
    "x": "nû-IV (n)",
    "y": " b. nan (n)"
  },
  {
    "x": "nû-V",
    "y": " b. nê-VI"
  },
  {
    "x": "nû-VI",
    "y": " b. nê-VII"
  },
  {
    "x": "nû ... nû ...",
    "y": " b. nê ... nê ..."
  },
  {
    "x": "Nûakşot (n)",
    "y": " Nouakchott"
  },
  {
    "x": "nûbere (m)",
    "y": " genç inek, genç manda "
  },
  {
    "x": "nûbîyayîş (n)",
    "y": " b. nobîyayîş (n)"
  },
  {
    "x": "nûciki (m)",
    "y": " b. nuçike (m)"
  },
  {
    "x": "nûjik (m)",
    "y": " b. nuçike (m)"
  },
  {
    "x": "nûk",
    "y": " b. nê-VI"
  },
  {
    "x": "nûke-I (m)",
    "y": " b. nuke (m)"
  },
  {
    "x": "nûke-II",
    "y": " b. nêke"
  },
  {
    "x": "nûkerdiş (n)",
    "y": " b. nokerdiş (n)"
  },
  {
    "x": "nûmdar",
    "y": " b. namdar, -e"
  },
  {
    "x": "nûme (n)",
    "y": " b. name (n)"
  },
  {
    "x": "Nûmeya (m)",
    "y": " Noumea"
  },
  {
    "x": "nûmger (n)",
    "y": " b. namger (n)"
  },
  {
    "x": "nûmgyer (m)",
    "y": " b. namgere (m)"
  },
  {
    "x": "nûmi (n)",
    "y": " b. name (n)"
  },
  {
    "x": "nûmis (n)",
    "y": " b. namûs (n)"
  },
  {
    "x": "nûn-I (n)",
    "y": " b. nan (n)"
  },
  {
    "x": "nûn dayene",
    "y": " b. nan dayene"
  },
  {
    "x": "nûn ra birîyayene",
    "y": " b. nan ra birîyayene"
  },
  {
    "x": "nûn û suali",
    "y": " b. nan û sole"
  },
  {
    "x": "nûn vetene",
    "y": " b. nan vetene"
  },
  {
    "x": "nûnê veywî (n)",
    "y": " b. nanê veyveyî (n)"
  },
  {
    "x": "nûnî rûnî (n)",
    "y": " b. nanê rûnî (n)"
  },
  {
    "x": "nûnî tîr (n)",
    "y": " b. nanê tîrî (n)"
  },
  {
    "x": "nûnî toq (n)",
    "y": " b. nanê tewqe (n)"
  },
  {
    "x": "nûnî toq (n)",
    "y": " b. nanê tewqe (n)"
  },
  {
    "x": "nûnî vêwî (m)",
    "y": " b. nanê veyveyî (n)"
  },
  {
    "x": "nûnî xu vetene",
    "y": " b. nanê xo vetene"
  },
  {
    "x": "nûnî ... vetene",
    "y": " b. nanê ... vetene "
  },
  {
    "x": "nûno mîrazkerdi (n)",
    "y": " b. nano mîrazkerde (n)"
  },
  {
    "x": "nûno ûnculî (n)",
    "y": " b. nano anculî (n)"
  },
  {
    "x": "nûno wişk (n)",
    "y": " b. nano huşk"
  },
  {
    "x": "nûno ziwa (n)",
    "y": " b. nano ziwa (n)"
  },
  {
    "x": "nûnû patîle (n)",
    "y": " b. nano patîre (n)"
  },
  {
    "x": "nûn-II (m)",
    "y": " b. nane-I (m)"
  },
  {
    "x": "nûn-III",
    "y": " b. nê-VI"
  },
  {
    "x": "nûncik (m)",
    "y": " b. nuçike (m)"
  },
  {
    "x": "nûnciki (m)",
    "y": " b. nuçike (m)"
  },
  {
    "x": "nûndar, -i",
    "y": " b. nandar, -e"
  },
  {
    "x": "nûngirûney (m)",
    "y": " b. nangiranî (m)"
  },
  {
    "x": "nûngirûnî (m)",
    "y": " b. nangiranî (m)"
  },
  {
    "x": "nûngû (n)",
    "y": " b. nenûg (n)"
  },
  {
    "x": "nûni-I (m)",
    "y": " b. nane-I (m)"
  },
  {
    "x": "nûni-II",
    "y": " b. nê-VI"
  },
  {
    "x": "nûnîye",
    "y": " b. nê-VI"
  },
  {
    "x": "nûr (n)",
    "y": " b. nur (n)"
  },
  {
    "x": "nûşte (n)",
    "y": " b. nuşte (n)"
  },
  {
    "x": "nûştiş (n)",
    "y": " b. nuştiş (n)"
  },
  {
    "x": "nût (m)",
    "y": " b. nute (m)"
  },
  {
    "x": "nûti (m)",
    "y": " b. nute (m)"
  },
  {
    "x": "nûwet (m)",
    "y": " b. nobete (m)"
  },
  {
    "x": "nûyê (m)",
    "y": " b. noyîye (m)"
  },
  {
    "x": "nûyî (m)",
    "y": " b. noyîye (m)"
  },
  {
    "x": "nya",
    "y": " b. nê-VII"
  },
  {
    "x": "nya ... nya ...",
    "y": " b. nê ... nê ..."
  },
  {
    "x": "nye",
    "y": " b. nê-VI"
  },
  {
    "x": "nye ... nye ...",
    "y": " b. ne ... ne ..."
  },
  {
    "x": "nyû",
    "y": " b. nê-VI"
  },
  {
    "x": "OOO"
  },
  {
    "x": "o-I (n, z)",
    "y": " o\r\n~O bi xo ame. (O bizzat geldi.)"
  },
  {
    "x": "o ke dima",
    "y": " sonraki"
  },
  {
    "x": "o ra têpya",
    "y": " b. ey ra pey"
  },
  {
    "x": "o wo",
    "y": " b. o yo"
  },
  {
    "x": "o wo no wo",
    "y": " b. o yo no yo"
  },
  {
    "x": "o yo",
    "y": " odur, işte"
  },
  {
    "x": "o yo no yo",
    "y": " -den beri"
  },
  {
    "x": "oyo/aya ke herême de",
    "y": " mücavir"
  },
  {
    "x": "o-II (n, s)",
    "y": " o\r\n~O lajek vano. (O oğlan söylüyor.)"
  },
  {
    "x": "o rewal ra",
    "y": " b. ê rîwalî ra"
  },
  {
    "x": "o rîbal ra",
    "y": " b. ê rîwalî ra"
  },
  {
    "x": "o sevev ra",
    "y": " b. ê sebebî ra"
  },
  {
    "x": "o sewet ra",
    "y": " b. ê semedî ra"
  },
  {
    "x": "o ter",
    "y": " b. o tewir"
  },
  {
    "x": "o tewir",
    "y": " o tür, öyle"
  },
  {
    "x": "o tir",
    "y": " b. o tewir"
  },
  {
    "x": "o tore",
    "y": " b. o tewir"
  },
  {
    "x": "o tur",
    "y": " b. o tewir"
  },
  {
    "x": "o-III (ed)",
    "y": " b. û-III (ed)"
  },
  {
    "x": "o-IV",
    "y": " b. ro"
  },
  {
    "x": "... o şîyene",
    "y": " b. ... ro şîyene"
  },
  {
    "x": "...o (sf)",
    "y": " b. -an (sf)"
  },
  {
    "x": "obje (n)",
    "y": " nesne"
  },
  {
    "x": "objektîf",
    "y": " 1) (nm) objektif\r\n~2)(s) nesnel, objektif"
  },
  {
    "x": "objektîfo çimmaseyin (sn)",
    "y": " balık gözü objektif"
  },
  {
    "x": "objektîfo hîrahokin (sn)",
    "y": " geniş açı objektif"
  },
  {
    "x": "objektîfo tenghokin (sn)",
    "y": " dar açı objektif"
  },
  {
    "x": "oblîk, -e",
    "y": " bükünlü"
  },
  {
    "x": "observasyon (n)",
    "y": " gözlem, müşahede"
  },
  {
    "x": "observator, -e",
    "y": " gözlemci"
  },
  {
    "x": "obsesyon (n)",
    "y": " obsesyon, takıntı"
  },
  {
    "x": "oca (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "ocax (n)",
    "y": " ocak (aile, soy)"
  },
  {
    "x": "ocaxe (m)",
    "y": " ocak (ateş yakılan veya gazla yakılan), şömine"
  },
  {
    "x": "ocaxa elektrîkine (m)",
    "y": " elektrik ocağı"
  },
  {
    "x": "ocaxkor, -e",
    "y": " çocuğu olmayan, körocak"
  },
  {
    "x": "ocaxkori (m)",
    "y": " b. ocaxkore (m)"
  },
  {
    "x": "od (n)",
    "y": " b. ehd (n)"
  },
  {
    "x": "od kerdene",
    "y": " b. ehd kerdene"
  },
  {
    "x": "oda-I (m)",
    "y": " oda"
  },
  {
    "x": "odaya çapemenîye",
    "y": " basın odası"
  },
  {
    "x": "odaya gureyayîşî (m)",
    "y": " çalışma odası"
  },
  {
    "x": "odaya îdareyî (m)",
    "y": " idare odası, yönetim odası"
  },
  {
    "x": "odaya îhaleyan (m)",
    "y": " ihale odası"
  },
  {
    "x": "odaya kombîyayîşî (m)",
    "y": " toplantı odası "
  },
  {
    "x": "odaya maliman",
    "y": " öğretmenler odası"
  },
  {
    "x": "odaya mamostayan",
    "y": " öğretmenler odası"
  },
  {
    "x": "odaya mêmanan (m)",
    "y": " konuk odası, misafir odası"
  },
  {
    "x": "odaya mîmaran (m)",
    "y": " mimarlar odası"
  },
  {
    "x": "odaya muzîkî",
    "y": " müzik odas"
  },
  {
    "x": "odaya otomasyonî (m)",
    "y": " otomasyon odası"
  },
  {
    "x": "odaya panoyê elektrîkî (m)",
    "y": " elektrik pano odası"
  },
  {
    "x": "odaya projeyanê YE (odaya projeyanê Yewîya Ewropa)",
    "y": " A. B. proje odası"
  },
  {
    "x": "odaya rakewtişî (m)",
    "y": " yatak odası"
  },
  {
    "x": "odaya roniştişî (m)",
    "y": " oturma odası"
  },
  {
    "x": "odaya santralî",
    "y": " santral odası"
  },
  {
    "x": "odaya terraqe (m) (TE)",
    "y": " söyleşi odası"
  },
  {
    "x": "odaya xebate (m)",
    "y": " çalışma odası"
  },
  {
    "x": "odaya vilakerdişî (m)",
    "y": " dağıtım odası"
  },
  {
    "x": "oda-II (m)",
    "y": " b. dadî (m)"
  },
  {
    "x": "ode (n)",
    "y": " b. oda-I (m)"
  },
  {
    "x": "odet (n)",
    "y": " b. adet (n)"
  },
  {
    "x": "odet û tore",
    "y": " b. adet û tore"
  },
  {
    "x": "odir (n)",
    "y": " b. adir (n)"
  },
  {
    "x": "odkerdiş (n)",
    "y": " b. ehdkerdiş (n)"
  },
  {
    "x": "ofîs (n)",
    "y": " ofis, büro"
  },
  {
    "x": "oftayîne",
    "y": " b. ewtanayene"
  },
  {
    "x": "oftayîş (n)",
    "y": " b. ewtanayîş (n)"
  },
  {
    "x": "oha",
    "y": " b. ohe"
  },
  {
    "x": "ohe",
    "y": " oha, höst"
  },
  {
    "x": "ohet (n)",
    "y": " o taraf, öte"
  },
  {
    "x": "ohet ra",
    "y": " o taraftan, o tarafta, o tarafa"
  },
  {
    "x": "ohêm (n)",
    "y": " öte, o taraf, karşı yaka"
  },
  {
    "x": "oho",
    "y": " b. ohe"
  },
  {
    "x": "oja (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "oje (n)",
    "y": " oje"
  },
  {
    "x": "ojêve",
    "y": " b. ecayîb, -e; b. ecêb, -e"
  },
  {
    "x": "ok (m)",
    "y": " b. awke (m)"
  },
  {
    "x": "okê bazilcûnû (m)",
    "y": " b. awa firingîyan (m)"
  },
  {
    "x": "okê hinarûn (m)",
    "y": " b. awa henaran (m)"
  },
  {
    "x": "oksîjene (m)",
    "y": " oksijen"
  },
  {
    "x": "oktobre (m)",
    "y": " b. teşrîna verêne (m)"
  },
  {
    "x": "okyanûs (n)",
    "y": " okyanus"
  },
  {
    "x": "Okyanûsya (m)",
    "y": " Okyanusya "
  },
  {
    "x": "olacax (n)",
    "y": " kaza-II"
  },
  {
    "x": "olad (n)",
    "y": " b. ewlad, -e"
  },
  {
    "x": "olandî",
    "y": " hiçbir"
  },
  {
    "x": "olboz, -e",
    "y": " b. embaz, -e "
  },
  {
    "x": "olbozîye (m)",
    "y": " b. embazîye (m) "
  },
  {
    "x": "olçeg (m)",
    "y": " b. welçeke (m)"
  },
  {
    "x": "olçek (m)",
    "y": " b. welçeke (m)"
  },
  {
    "x": "oldon (n)",
    "y": " b. andal (n)"
  },
  {
    "x": "olecax (n)",
    "y": " b. olacax (n)"
  },
  {
    "x": "oley (zh)",
    "y": " b. awleyî (zh)"
  },
  {
    "x": "oleyin",
    "y": " b. awleyin, -e"
  },
  {
    "x": "olê (zh)",
    "y": " b. awleyî (zh)"
  },
  {
    "x": "olê vetene",
    "y": " b. awleyî vetene"
  },
  {
    "x": "olêyin",
    "y": " b. awleyin, -e"
  },
  {
    "x": "Olî",
    "y": " 1)Allah, Tanrı, ilah (b. Elî, Alî)"
  },
  {
    "x": "Olî kena",
    "y": " Allahını seversen, Allah aşkına"
  },
  {
    "x": "olîye (m)",
    "y": " b. alî (m), alîye (m)"
  },
  {
    "x": "olmos (n)",
    "y": " b. elmas (n)"
  },
  {
    "x": "Oltî",
    "y": " Oltu"
  },
  {
    "x": "Olur (n)",
    "y": " Olur "
  },
  {
    "x": "olûndî",
    "y": " b. olandî"
  },
  {
    "x": "olvojîye (m)",
    "y": " b. embazîye (m)"
  },
  {
    "x": "olvoz, -e",
    "y": " b. embaz, -e"
  },
  {
    "x": "olvozîye (m)",
    "y": " b. embazîye (m)"
  },
  {
    "x": "oma (m)",
    "y": " b. yewmîye (n)"
  },
  {
    "x": "omar (m)",
    "y": " b. hûmare (m)"
  },
  {
    "x": "omaritiş (n)",
    "y": " b. hûmaritiş (n)"
  },
  {
    "x": "omartiş (n)",
    "y": " b. hûmaritiş (n)"
  },
  {
    "x": "ombarik",
    "y": " b. bimbarek, -e"
  },
  {
    "x": "ombaz, -i",
    "y": " b. embaz, -e"
  },
  {
    "x": "ombazey (m)",
    "y": " b. embazîye (m)"
  },
  {
    "x": "ombazê (m)",
    "y": " b. embazîye (m)"
  },
  {
    "x": "ombordayene",
    "y": " b. hûmaritene"
  },
  {
    "x": "ombordeni",
    "y": " b. hûmaritene"
  },
  {
    "x": "ombordiş (n)",
    "y": " b. hûmaritiş (n)"
  },
  {
    "x": "omete (m)",
    "y": " 1)herkes\r\n~2)Müslüman toplumu, ümmet\r\n~3)toplum"
  },
  {
    "x": "ometa Mihemedî (m)",
    "y": " Muhammed'in ümmeti, ümmet-i Muhammed"
  },
  {
    "x": "ometi (m)",
    "y": " b. omete (m)"
  },
  {
    "x": "omeyene",
    "y": " b. ameyene"
  },
  {
    "x": "omeyene beşkerdene",
    "y": " b. ameyene beşkerdene"
  },
  {
    "x": "omeyene tesnîfkerdene",
    "y": " b. ameyene tesnîfkerdene"
  },
  {
    "x": "omeyîne",
    "y": " b. ameyene"
  },
  {
    "x": "omeyîne cêmîyonvistene",
    "y": " b. ameyene cêmîyanvistene "
  },
  {
    "x": "omeyîne dîyayîne",
    "y": " b. ameyene dîyene"
  },
  {
    "x": "omeyîne famkerdene",
    "y": " b. ameyene fehmkerdene"
  },
  {
    "x": "omeyîne girênayene",
    "y": " b. ameyene girênayene"
  },
  {
    "x": "omeyîne pêser",
    "y": " b. ameyene pêser"
  },
  {
    "x": "omeyîne pêşonayene",
    "y": " b. ameyene pêşanayene"
  },
  {
    "x": "omeyîne ponayene",
    "y": " b. ameyene panayene"
  },
  {
    "x": "omeyîne powtene",
    "y": " b. ameyene pewtene"
  },
  {
    "x": "omeyîne qirbonkerdene",
    "y": " b. ameyene qurbankerdene"
  },
  {
    "x": "omeyîne romitene",
    "y": " b. ameyene ramitene"
  },
  {
    "x": "omeyîne sînayene",
    "y": " b. ameyene sînayene"
  },
  {
    "x": "omeyîne têkilkerdene",
    "y": " b. ameyene têkilkerdene"
  },
  {
    "x": "omeyîne vatene",
    "y": " b. ameyene vatene"
  },
  {
    "x": "omeyîne vînayîne",
    "y": " b. ameyene vînayene"
  },
  {
    "x": "omeyîne werdene",
    "y": " b. ameyene werdene"
  },
  {
    "x": "omeyîne xeneqnayîni",
    "y": " b. ameyene xeniqnayene"
  },
  {
    "x": "omeyîne yewca",
    "y": " b. ameyene yewca"
  },
  {
    "x": "... omeyîne",
    "y": " b. ... ameyene"
  },
  {
    "x": "omeyîş (n)",
    "y": " b. ameyîş (n)"
  },
  {
    "x": "... omeyîş",
    "y": " b. ... ameyîş"
  },
  {
    "x": "omeyîşbeşkerdiş (n)",
    "y": " b. ameyîşbeşkerdiş (n)"
  },
  {
    "x": "omeyîşcêmîyonvistiş (n)",
    "y": " b. ameyîşcêmîyanvistiş"
  },
  {
    "x": "omeyîşdeyştiş (n)",
    "y": " b. ameyîşdeştiş (n)"
  },
  {
    "x": "omeyîşdîyayîş (n)",
    "y": " b. ameyîşdîyayîş (n)"
  },
  {
    "x": "omeyîşfamkerdiş (n)",
    "y": " b. ameyîşfehmkerdiş (n)"
  },
  {
    "x": "omeyîşgirênayîş (n)",
    "y": " b. ameyîşgirênayîş (n)"
  },
  {
    "x": "omeyîşkijikikerdiş (n)",
    "y": " b. ameyîşkijikekerdiş (n)"
  },
  {
    "x": "omeyîşkîştiş (n)",
    "y": " b. ameyîşkiştiş (n)"
  },
  {
    "x": "omeyîşpaykardiş (n)",
    "y": " b. ameyîşpaykerdiş (n)"
  },
  {
    "x": "omeyîşpêser (n)",
    "y": " b. ameyîşpêser (n)"
  },
  {
    "x": "omeyîşpêşonayîş (n)",
    "y": " b. ameyîşpêşanayîş (n)"
  },
  {
    "x": "omeyîşpiragirewtiş (n)",
    "y": " b. ameyîşpiragirewtiş (n)"
  },
  {
    "x": "omeyîşponayîş (n)",
    "y": " b. ameyîşpanayîş (n)"
  },
  {
    "x": "omeyîşpowtiş (n)",
    "y": " b. ameyîşpewtiş (n)"
  },
  {
    "x": "omeyîşsînayîş (n)",
    "y": " b. ameyîşsînayîş (n)"
  },
  {
    "x": "omeyîştesnîfkerdiş (n)",
    "y": " b. ameyîştesnîfkerdiş (n)"
  },
  {
    "x": "omeyîştêkilkardiş (n)",
    "y": " b. ameyîştêkilkerdiş (n)"
  },
  {
    "x": "omeyîşvatiş (n)",
    "y": " b. ameyîşvatiş (n)"
  },
  {
    "x": "omeyîşwar (n)",
    "y": " b. warameyîş (n)"
  },
  {
    "x": "omeyîşxemelnayîş (n)",
    "y": " b. ameyîşxemilnayîş (n)"
  },
  {
    "x": "omeyîşyewca (n)",
    "y": " ameyîşyewca (n)"
  },
  {
    "x": "omeyn (n)",
    "y": " b. amên (n)"
  },
  {
    "x": "omeyn bîyayene",
    "y": " b. amên bîyene"
  },
  {
    "x": "omeyn kerdene",
    "y": " b. amên kerdene"
  },
  {
    "x": "omeynbîyayîş (n)",
    "y": " b. amênbîyayîş (n)"
  },
  {
    "x": "omeynkerde (n)",
    "y": " b. amênkerde (n)"
  },
  {
    "x": "omeynkerdiş (n)",
    "y": " b. amênkerdiş (n)"
  },
  {
    "x": "omên (n)",
    "y": " b. amên (n)"
  },
  {
    "x": "omên bîyayene",
    "y": " b. amên bîyene"
  },
  {
    "x": "omên kerdene",
    "y": " b. amên kerdene"
  },
  {
    "x": "omênbîyayîş (n)",
    "y": " b. amênbîyayîş (n)"
  },
  {
    "x": "omênkerde (n)",
    "y": " b. amênkerde (n)"
  },
  {
    "x": "omin (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "ominî ",
    "y": " b. hamnanî"
  },
  {
    "x": "omî (n)",
    "y": " b. amên (n)"
  },
  {
    "x": "omîn (n)",
    "y": " b. amên (n)"
  },
  {
    "x": "omîn bîyayene",
    "y": " b. amên bîyene"
  },
  {
    "x": "omîn kerdene",
    "y": " b. amên kerdene"
  },
  {
    "x": "omînbîyayîş (n)",
    "y": " b. amênbîyayîş (n)"
  },
  {
    "x": "omînkerde (n)",
    "y": " b. amênkerde (n)"
  },
  {
    "x": "omînkerdiş (n)",
    "y": " b. amênkerdiş (n)"
  },
  {
    "x": "omîs (n)",
    "y": " b. ûmiş (n)"
  },
  {
    "x": "omîs bîyayene",
    "y": " b. ûmiş bîyene"
  },
  {
    "x": "omno (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "omnon (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "omnono peye",
    "y": " b. hamnano peyên"
  },
  {
    "x": "omnono peyen",
    "y": " b. hamnano peyên"
  },
  {
    "x": "omnono verê",
    "y": " b. hamnano verên"
  },
  {
    "x": "omnono verên",
    "y": " b. hamnano verên"
  },
  {
    "x": "omnona (m)",
    "y": " b. hamnana (m)"
  },
  {
    "x": "omnone (n)",
    "y": " b. hamnane (n)"
  },
  {
    "x": "omnonê",
    "y": " b. hamnanî"
  },
  {
    "x": "omnonên, -e",
    "y": " b. hamnanên, -e"
  },
  {
    "x": "omnonî",
    "y": " b. hamnanî"
  },
  {
    "x": "omnonîn, -e",
    "y": " b. hamnanên, -e"
  },
  {
    "x": "omnonîye (m)",
    "y": " b. hamnanîye (m)"
  },
  {
    "x": "omnû (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "omnûn (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "omnûna (m)",
    "y": " b. hamnana (m)"
  },
  {
    "x": "omnûne (n)",
    "y": " b. hamnane (n)"
  },
  {
    "x": "omnûnîye (m)",
    "y": " b. hamnanîye (m)"
  },
  {
    "x": "omnûnyen",
    "y": " b. hamnanên, -e"
  },
  {
    "x": "omr (n)",
    "y": " b. emr-I (n)"
  },
  {
    "x": "omvar, -i",
    "y": " b. hember, -e; hemver, -e"
  },
  {
    "x": "omver, -i",
    "y": " b. hember, -e; hemver, -e"
  },
  {
    "x": "omza (n)",
    "y": " b. emiza (n)"
  },
  {
    "x": "...on (sf)",
    "y": " b. -an (sf)"
  },
  {
    "x": "ona",
    "y": " 1)b. wina\r\n~2)b. ûne"
  },
  {
    "x": "onaci (m)",
    "y": " b. anace (m)"
  },
  {
    "x": "onaji (m)",
    "y": " b. anace (m)"
  },
  {
    "x": "onasar",
    "y": " b. winasî, inasar"
  },
  {
    "x": "onasarên, -i",
    "y": " b. winasî, -ye; inasarên, -e"
  },
  {
    "x": "onawa",
    "y": " b. inahawa"
  },
  {
    "x": "onayin",
    "y": " b. winasî, inasar"
  },
  {
    "x": "onbaz, -i",
    "y": " b. embaz, -e"
  },
  {
    "x": "onc",
    "y": " b. ancî"
  },
  {
    "x": "onca",
    "y": " b. ancîna"
  },
  {
    "x": "oncax",
    "y": " b. ancax"
  },
  {
    "x": "oncil",
    "y": " b. anculî"
  },
  {
    "x": "oncilî, -ye",
    "y": " b. anculî, -ye"
  },
  {
    "x": "oncitene",
    "y": " b. antene"
  },
  {
    "x": "oncî",
    "y": " b. ancî"
  },
  {
    "x": "oncîna",
    "y": " b. ancîna"
  },
  {
    "x": "oncîya",
    "y": " b. ancîna"
  },
  {
    "x": "oncîyayene",
    "y": " b. ancîyayene"
  },
  {
    "x": "oncîyayîş (n)",
    "y": " b. ancîyayîş (n)"
  },
  {
    "x": "onculî, -ye",
    "y": " b. anculî, -ye"
  },
  {
    "x": "ondaz (n)",
    "y": " abdest, aptes"
  },
  {
    "x": "ondaz ra",
    "y": " abdestli, aptesli"
  },
  {
    "x": "onder, -e",
    "y": " b. ondêr"
  },
  {
    "x": "ondêr, -e",
    "y": " meret"
  },
  {
    "x": "ondol (n)",
    "y": " b. andal (n)"
  },
  {
    "x": "one",
    "y": " b. ûne"
  },
  {
    "x": "oneyîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "oni",
    "y": " b. ûne"
  },
  {
    "x": "onix (n)",
    "y": " b. anûx (n)"
  },
  {
    "x": "oniyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "onîyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "bionî",
    "y": " b. biewnî "
  },
  {
    "x": "bo",
    "y": " b. biewnî"
  },
  {
    "x": "bow",
    "y": " b. biewnî"
  },
  {
    "x": "bonî",
    "y": " b. biewnî"
  },
  {
    "x": "bonîr",
    "y": " b. biewnî"
  },
  {
    "x": "onkerdene",
    "y": " b. hankerdene"
  },
  {
    "x": "onkerdiş (n)",
    "y": " b. hankerdiş (n)"
  },
  {
    "x": "onlig (n)",
    "y": " önlük (öğrenci önlüğü)"
  },
  {
    "x": "onlige (m)",
    "y": " b. onlig (n)"
  },
  {
    "x": "ontayene",
    "y": " b. antene"
  },
  {
    "x": "ontene",
    "y": " b. antene"
  },
  {
    "x": "onteni",
    "y": " b. antene"
  },
  {
    "x": "ontiş (n)",
    "y": " b. antiş (n)"
  },
  {
    "x": "onvar",
    "y": " b. hember, -e; hemver, -e"
  },
  {
    "x": "opera (m)",
    "y": " opera"
  },
  {
    "x": "operasyon (n)",
    "y": " 1)ameliyat, operasyon\r\n~2)işlem (mat)"
  },
  {
    "x": "operasyonê arîtmetîkî (n)",
    "y": " aritmetik işlemi (mat)"
  },
  {
    "x": "operasyono cerahî (n)",
    "y": " cerrahi müdahale"
  },
  {
    "x": "operator, -e",
    "y": " 1)cerrah\r\n~2)operatör"
  },
  {
    "x": "operatora buldozere (m)",
    "y": " buldozer operatörü"
  },
  {
    "x": "operatora greydere (m)",
    "y": " greyder operatörü"
  },
  {
    "x": "operatora telefonî (m)",
    "y": " telefon operatörü"
  },
  {
    "x": "operatora vînçî (m)",
    "y": " vinç operatörü"
  },
  {
    "x": "operatorê buldozere (n)",
    "y": " buldozer operatörü"
  },
  {
    "x": "operatorê greydere (n)",
    "y": " greyder operatörü"
  },
  {
    "x": "operatorê telefonî (n)",
    "y": " telefon operatörü"
  },
  {
    "x": "operatorê vînçî (n)",
    "y": " vinç operatörü"
  },
  {
    "x": "opey",
    "y": " b. apey"
  },
  {
    "x": "opsîyon (n)",
    "y": " seçenek"
  },
  {
    "x": "optîmîst, -e",
    "y": " iyimser"
  },
  {
    "x": "optîmîstî (m)",
    "y": " iyimserlik"
  },
  {
    "x": "optîmîstîye (m)",
    "y": " iyimserlik"
  },
  {
    "x": "oqyanûs (n)",
    "y": " b. okyanûs (n)"
  },
  {
    "x": "or-I (m)",
    "y": " b. awre (m) (hayvan için)"
  },
  {
    "x": "or bîyayene",
    "y": " b. awre bîyene (hayvan için)"
  },
  {
    "x": "or-II",
    "y": " b. war-II, waro"
  },
  {
    "x": "or ameyene",
    "y": " b. war ameyene"
  },
  {
    "x": "or bîyayene",
    "y": " b. war bîyene"
  },
  {
    "x": "or kerdene",
    "y": " b. war kerdene"
  },
  {
    "x": "ora (n)",
    "y": " b. weyra (n)"
  },
  {
    "x": "oraqi (m)",
    "y": " b. ewraqe (m)"
  },
  {
    "x": "orayene",
    "y": " böğürmek"
  },
  {
    "x": "orayeni",
    "y": " b. orayene"
  },
  {
    "x": "orayîş (n)",
    "y": " böğürme"
  },
  {
    "x": "orbîyayîş (n)",
    "y": " b. warbîyayîş (n)"
  },
  {
    "x": "orcîk (m)",
    "y": " b. orcîke (m)"
  },
  {
    "x": "orcîke (m)",
    "y": " sucuk (bandırma)"
  },
  {
    "x": "ordege (m)",
    "y": " ördek"
  },
  {
    "x": "ordim (n)",
    "y": " b. ardim (n)"
  },
  {
    "x": "ordim kerdene",
    "y": " b. ardim kerdene"
  },
  {
    "x": "ordum (n)",
    "y": " b. ardim (n)"
  },
  {
    "x": "ordum kerdene",
    "y": " b. ardim kerdene"
  },
  {
    "x": "ore (n)",
    "y": " b. weyra (n)"
  },
  {
    "x": "ore-or",
    "y": " b. ore-ore"
  },
  {
    "x": "ore-or kardene",
    "y": " b. ore-ore kerdene"
  },
  {
    "x": "ore-ore",
    "y": " böğürtü"
  },
  {
    "x": "ore-ore kerdene",
    "y": " böğürmek"
  },
  {
    "x": "ore-orekerdiş (n)",
    "y": " böğürme"
  },
  {
    "x": "ore-orkerdiş (n)",
    "y": " b. ore-orekerdiş (n)"
  },
  {
    "x": "orebe (n)",
    "y": " b. erebe (n)"
  },
  {
    "x": "orê",
    "y": " b. awrîye (m) (hayvan için)"
  },
  {
    "x": "orês-I (n)",
    "y": " b. wers-I (n)"
  },
  {
    "x": "orês-II (n)",
    "y": " b. vewrês (n)"
  },
  {
    "x": "org (n)",
    "y": " org"
  },
  {
    "x": "organ (n)",
    "y": " organ, aza, uzuv"
  },
  {
    "x": "organîk, -e",
    "y": " organik"
  },
  {
    "x": "organîza (m)",
    "y": " organize, örgütlü"
  },
  {
    "x": "organîzasyon (n)",
    "y": " organizasyon, örgüt, teşkilat"
  },
  {
    "x": "organîzasyonê kombîyayîşî (n)",
    "y": " toplantı organizasyonu"
  },
  {
    "x": "organîzasyono caardox (n)",
    "y": " gerçekleştiren organizasyon"
  },
  {
    "x": "organîzasyono realîzekerdox (n)",
    "y": " gerçekleştiren organizasyon"
  },
  {
    "x": "organîzator, -e",
    "y": " düzenleyici, tertipleyici, organizatör"
  },
  {
    "x": "organîze (n)",
    "y": " organize, örgütlü"
  },
  {
    "x": "organîze bîyene",
    "y": " organize olmak, örgütlenmek"
  },
  {
    "x": "organîze kerdene",
    "y": " organize etmek, örgütlemek"
  },
  {
    "x": "organîzebîyayîş (n)",
    "y": " organize olma, örgütlenme "
  },
  {
    "x": "organîzekerda (m)",
    "y": " organize edilmiş olan, örgütlü"
  },
  {
    "x": "organîzekerde (n)",
    "y": " organize edilmiş olan, örgütlü"
  },
  {
    "x": "organîzekerdiş (n)",
    "y": " organize etme, örgütleme"
  },
  {
    "x": "organîzma (m)",
    "y": " uzviyet, organizma"
  },
  {
    "x": "organîzmayê necisî (zh)",
    "y": " dışkı organizmaları"
  },
  {
    "x": "organîzmayê pîsî (zh)",
    "y": " dışkı organizmaları"
  },
  {
    "x": "orgcen, -e",
    "y": " orgcu"
  },
  {
    "x": "ori (m)",
    "y": " b. awre (m) (hayvan için)"
  },
  {
    "x": "ori bîyayene",
    "y": " b. awre bîyene (hayvan için)"
  },
  {
    "x": "orisipî (m)",
    "y": " b. orispî (m)"
  },
  {
    "x": "orisipîyey (m)",
    "y": " b. orispîyî (m), orispîyîye (m)"
  },
  {
    "x": "orisipîyê (m)",
    "y": " b. orispîyî (m), orispîyîye (m)"
  },
  {
    "x": "orispî (m)",
    "y": " orospu, fahişe, kahpe, sürtük"
  },
  {
    "x": "orispî (n)",
    "y": " (mecazi) orospu, fahişe, kahpe, sürtük"
  },
  {
    "x": "orispîtî (m)",
    "y": " orospuluk, fahişelik, kahpelik, sürtüklük"
  },
  {
    "x": "orispîye",
    "y": " b. orispî (m)"
  },
  {
    "x": "orispîyey (m)",
    "y": " b. orispîyî (m), orispîyîye (m)"
  },
  {
    "x": "orispîyê (m)",
    "y": " b. orispîyî (m), orispîyîye (m)"
  },
  {
    "x": "orispîyî (m)",
    "y": " orospuluk, fahişelik, kahpelik, sürtüklük"
  },
  {
    "x": "orispîyîye (m)",
    "y": " orospuluk, fahişelik, kahpelik, sürtüklük"
  },
  {
    "x": "orî-I (m)",
    "y": " böğürtü"
  },
  {
    "x": "orî-II (m)",
    "y": " b. awrîye (m) (hayvan için)"
  },
  {
    "x": "orkerdiş (n)",
    "y": " b. warkerdiş (n)"
  },
  {
    "x": "orkînos (n)",
    "y": " orkinos balığı"
  },
  {
    "x": "orlon (n)",
    "y": " orlon"
  },
  {
    "x": "ornag (n)",
    "y": " b. nimûne (m)"
  },
  {
    "x": "ornage (m)",
    "y": " b. nimûne (m)"
  },
  {
    "x": "ornagi (m)",
    "y": " b. nimûne (m)"
  },
  {
    "x": "orojî (m)",
    "y": " b. veraj (n)"
  },
  {
    "x": "orozî (m)",
    "y": " b. veraj (n)"
  },
  {
    "x": "oroznayene",
    "y": " dayanmak (yaslanmak, kendini dayamak)"
  },
  {
    "x": "oroznayîs (n)",
    "y": " b. oroznayîş (n)"
  },
  {
    "x": "oroznayîş (n)",
    "y": " dayanma (yaslanma, kendini dayama)"
  },
  {
    "x": "orrayene",
    "y": " b. orayene"
  },
  {
    "x": "orrayîş (n)",
    "y": " b. orayîş (n)"
  },
  {
    "x": "orrî (m)",
    "y": " b. orî (m)"
  },
  {
    "x": "ors-I (n)",
    "y": " b. wers-I (n)"
  },
  {
    "x": "ors-II (n)",
    "y": " örs"
  },
  {
    "x": "ortaq, -e",
    "y": " b. ortax, -e"
  },
  {
    "x": "ortaqey (m)",
    "y": " b. ortaxîye (m)"
  },
  {
    "x": "ortaqi (m)",
    "y": " b. ortaxe (m)"
  },
  {
    "x": "ortax, -e",
    "y": " ortak, ortakçı, hissedar, paydaş"
  },
  {
    "x": "ortax bîyene",
    "y": " ortak olmak"
  },
  {
    "x": "ortaxey (m)",
    "y": " b. ortaxîye (m)"
  },
  {
    "x": "ortaxê (m)",
    "y": " b. ortaxî (m)"
  },
  {
    "x": "ortaxênî (m)",
    "y": " ortakçılık, ortaklık, paydaşlık"
  },
  {
    "x": "ortaxi (m)",
    "y": " b. ortaxe (m)"
  },
  {
    "x": "ortaxî (m)",
    "y": " ortakçılık, ortaklık, paydaşlık"
  },
  {
    "x": "ortaxîye (m)",
    "y": " ortakçılık, ortaklık, paydaşlık"
  },
  {
    "x": "orte (n)",
    "y": " orta, ara"
  },
  {
    "x": "ortî (zh)",
    "y": " yatak"
  },
  {
    "x": "Ortîlî",
    "y": " Şenkaya"
  },
  {
    "x": "ortme (n)",
    "y": " örtme (bina için)"
  },
  {
    "x": "ortografî (m)",
    "y": " yazım, imla"
  },
  {
    "x": "ortopedî (m)",
    "y": " ortopedî (m)"
  },
  {
    "x": "ortopedîk, -e",
    "y": " ortopedik"
  },
  {
    "x": "oru",
    "y": " b. ewro"
  },
  {
    "x": "orxan (n)",
    "y": " yorgan (b. lihêf)"
  },
  {
    "x": "orxanderz, -e",
    "y": " yorgancı (b. lihêfderz, -e)"
  },
  {
    "x": "orxane (m)",
    "y": " b. lihêf (n)"
  },
  {
    "x": "oryantasyon (n)",
    "y": " yönlendirme, kanalize etme, oryantasyon"
  },
  {
    "x": "oryes (n)",
    "y": " b. vewrês (n)"
  },
  {
    "x": "osayêne",
    "y": " b. asayene"
  },
  {
    "x": "osayîs (n)",
    "y": " b. asayîş-II (n)"
  },
  {
    "x": "Oscar (Oskar)",
    "y": " Oscar"
  },
  {
    "x": "osil (n)",
    "y": " b. ûsul (n)"
  },
  {
    "x": "osil-osil",
    "y": " b. ûsul-ûsul"
  },
  {
    "x": "osilî reydi",
    "y": " b. ûsulî reyde"
  },
  {
    "x": "osin (n)",
    "y": " b. asin (n)"
  },
  {
    "x": "Oslo (n)",
    "y": " Oslo"
  },
  {
    "x": "osme (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "osmê (n)",
    "y": " b. asmên (n)"
  },
  {
    "x": "oson, -e-I",
    "y": " b. asan, -e"
  },
  {
    "x": "oson-II (n)",
    "y": " b. însan (n)"
  },
  {
    "x": "ospar (n)",
    "y": " b. espar (n)"
  },
  {
    "x": "ospor (n)",
    "y": " b. espar (n)"
  },
  {
    "x": "oste (n)",
    "y": " b. este-I (n)"
  },
  {
    "x": "ostêmî (m)",
    "y": " b. astamî (m)"
  },
  {
    "x": "ostine (m)",
    "y": " b. estune (m)"
  },
  {
    "x": "ostomî (m)",
    "y": " b. astamî (m)"
  },
  {
    "x": "Ostomol (n)",
    "y": " b. Îstanbul (n)"
  },
  {
    "x": "ostor, -e",
    "y": " b. estor, -e"
  },
  {
    "x": "osû-I (n)",
    "y": " b. asan (n)"
  },
  {
    "x": "osû-II (n)",
    "y": " b. însan (n)"
  },
  {
    "x": "... oşîyayîş",
    "y": " b. ... roşîyayîş"
  },
  {
    "x": "oşt!",
    "y": " b. hoşt!"
  },
  {
    "x": "otapîze (m)",
    "y": " b. otobuse (m)"
  },
  {
    "x": "otapîzi (m)",
    "y": " b. otobuse (m)"
  },
  {
    "x": "oteldar, -e",
    "y": " b. otêldar, -e"
  },
  {
    "x": "oteldarî (m)",
    "y": " b. otêldarî (m)"
  },
  {
    "x": "oteldarîye (m)",
    "y": " b. otêldarîye (m)"
  },
  {
    "x": "otele (m)",
    "y": " b. otêle"
  },
  {
    "x": "otêldar, -e",
    "y": " otelci"
  },
  {
    "x": "otêldarî (m)",
    "y": " otelcilik"
  },
  {
    "x": "otêldarîye (m)",
    "y": " otelcilik"
  },
  {
    "x": "otêle",
    "y": " otel"
  },
  {
    "x": "otêli (m)",
    "y": " b. otêle"
  },
  {
    "x": "otîst, -e",
    "y": " otist"
  },
  {
    "x": "otîzm (n)",
    "y": " otizm"
  },
  {
    "x": "oto",
    "y": " oto, otomobil"
  },
  {
    "x": "otobozi (m)",
    "y": " b. otobuse (m)"
  },
  {
    "x": "otobro",
    "y": " gelmeleri için büyükbaş ve küçükbaş hayvanlara seslenirken kullanılan sözcükler (manda için) "
  },
  {
    "x": "otobuse (m)",
    "y": " otobüs"
  },
  {
    "x": "otogar (n)",
    "y": " otogar"
  },
  {
    "x": "otogarê bajaran (n)",
    "y": " il otogarı"
  },
  {
    "x": "otogarê mîyanbajaran yê şaredarî/beledîya",
    "y": " belediye şehirlerarası otogarı"
  },
  {
    "x": "otogarê qezayan (n)",
    "y": " ilçe otogarı"
  },
  {
    "x": "otogarê şaristanan (n)",
    "y": " il otogarı"
  },
  {
    "x": "otokrasî (n)",
    "y": " otokrasî"
  },
  {
    "x": "otokrat, -e",
    "y": " otokrat"
  },
  {
    "x": "otomasyon (n)",
    "y": " otomasyon"
  },
  {
    "x": "otomatîk, -e",
    "y": " otomatik"
  },
  {
    "x": "otomatîkê pişike (n)",
    "y": " akciğer otomatiği"
  },
  {
    "x": "otomatîkê qesebaya sipîye (n)",
    "y": " akciğer otomatiği"
  },
  {
    "x": "otomobîl (m)",
    "y": " b. otomobîle (m)"
  },
  {
    "x": "otomobîl (n)",
    "y": " b. otomobîle (m)"
  },
  {
    "x": "otomobîle (m)",
    "y": " oto, otomobil"
  },
  {
    "x": "otomobîla destê dîyinî (m)",
    "y": " ikinci el otomobil"
  },
  {
    "x": "otomobîli (m)",
    "y": " b. otomobîle (m)"
  },
  {
    "x": "otomotîv (n)",
    "y": " otomotiv"
  },
  {
    "x": "otonom, -e",
    "y": " özerk, otonom, muhtar"
  },
  {
    "x": "mintiqaya otonome (m)",
    "y": " özerk bölge, otonom bölge"
  },
  {
    "x": "otonomî (m)",
    "y": " özerklik, otonomi, muhtariyet"
  },
  {
    "x": "otonomîst, -e",
    "y": " özerklik yanlısı, otonomist"
  },
  {
    "x": "otopark (n)",
    "y": " b. otoparke (m)"
  },
  {
    "x": "otoparke (m)",
    "y": " otopark, park (motorlu taşıtlar için), park yeri"
  },
  {
    "x": "otorayîr (n)",
    "y": " otoban, otoyol"
  },
  {
    "x": "otorîte (m)",
    "y": " yetke, otorite"
  },
  {
    "x": "otorîter, -e",
    "y": " otoriter"
  },
  {
    "x": "otorîterîye (m)",
    "y": " otoriterlik"
  },
  {
    "x": "Ottawa (m)",
    "y": " Ottawa"
  },
  {
    "x": "over",
    "y": " b. aver (n)"
  },
  {
    "x": "over berdene",
    "y": " b. aver berdene"
  },
  {
    "x": "ow-I (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "ow-II (ed)",
    "y": " b. û-III (ed)"
  },
  {
    "x": "ow-III (n, s)",
    "y": " b. o-II (n, s)"
  },
  {
    "x": "ow tir",
    "y": " b. o tewir"
  },
  {
    "x": "owca (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "owdayîş (n)",
    "y": " b. awedayîş (n)"
  },
  {
    "x": "owdîyayîş (n)",
    "y": " b. awedîyayîş (n)"
  },
  {
    "x": "owe (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "owi-I (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "owi-II (n, z)",
    "y": " b. o-I (n, z)"
  },
  {
    "x": "owin, -e",
    "y": " b. awin, -e"
  },
  {
    "x": "owinîye (m)",
    "y": " b. awinîye (m)"
  },
  {
    "x": "owja (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "owk (m)",
    "y": " b. awke (m)"
  },
  {
    "x": "owk verradayene ... ser",
    "y": " b. awe veradayene ... ser"
  },
  {
    "x": "owkdayîş (n)",
    "y": " b. awedayîş (n)"
  },
  {
    "x": "owkdîyayîş (n)",
    "y": " b. awedîyayîş (n)"
  },
  {
    "x": "owke (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "owki (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "owlad, -i",
    "y": " b. ewlad, -e"
  },
  {
    "x": "owneyîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "bownî",
    "y": " b. biewnî"
  },
  {
    "x": "ownîyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "bownî",
    "y": " b. biewnî "
  },
  {
    "x": "owr (m)",
    "y": " b. awre (m) (hayvan için)"
  },
  {
    "x": "owr bîyayene",
    "y": " b. awre bîyene (hayvan için)"
  },
  {
    "x": "owra (n)",
    "y": " b. weyra (n)"
  },
  {
    "x": "owre (n)",
    "y": " b. weyra (n)"
  },
  {
    "x": "owres, -e",
    "y": " b. hargûş, -e"
  },
  {
    "x": "owrê (m)",
    "y": " b. awrîye (m) (hayvan için)"
  },
  {
    "x": "owrês, -e",
    "y": " b. hargûş, -e"
  },
  {
    "x": "owro",
    "y": " b. ewro"
  },
  {
    "x": "owta (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "owte (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "owtûnayîne",
    "y": " b. ewtanayene"
  },
  {
    "x": "owtûnayîş (n)",
    "y": " b. ewtanayîş (n)"
  },
  {
    "x": "owuke (m)",
    "y": " b. awe (m)"
  },
  {
    "x": "owun, -e",
    "y": " b. awin, -e"
  },
  {
    "x": "oxe (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "oxil",
    "y": " oğul (hitap hali)"
  },
  {
    "x": "oxillix (n)",
    "y": " b. oxullux (n)"
  },
  {
    "x": "oxir (n)",
    "y": " uğur \r\n~Oxir bo. (Uğurlar olsun./Güle güle./İyi yolculuklar.)\r\n~\t\r\n~Oxirê xeyrî bo. (Uğurlar olsun./Güle güle./İyi yolculuklar.)\r\n~\t\r\n~To rê oxir bo. (Uğurlar olsun./(Sana) İyi yolculuklar."
  },
  {
    "x": "oxlix (n)",
    "y": " b. oxullux (n)"
  },
  {
    "x": "oxma",
    "y": " b. oxmo"
  },
  {
    "x": "oxma ki",
    "y": " b. oxmo"
  },
  {
    "x": "oxmo",
    "y": " meğer, meğerse"
  },
  {
    "x": "oxmo ke",
    "y": " b. oxmo"
  },
  {
    "x": "oxmo ki",
    "y": " b. oxmo"
  },
  {
    "x": "oxmona",
    "y": " b. oxmo"
  },
  {
    "x": "oxmona ki",
    "y": " b. oxmo"
  },
  {
    "x": "oxra",
    "y": " b. oxro"
  },
  {
    "x": "oxro",
    "y": " meğer, meğerse"
  },
  {
    "x": "oxro ke",
    "y": " b. oxro"
  },
  {
    "x": "oxt (n)",
    "y": " b. wext (n)"
  },
  {
    "x": "oxullux (n)",
    "y": " oğulluk, üvey oğul"
  },
  {
    "x": "oxur (n)",
    "y": " b. oxir (n)"
  },
  {
    "x": "oxwe (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "oxwi (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "oyin, -e",
    "y": " b. awî, -ye -I"
  },
  {
    "x": "oyî, -ye",
    "y": " b. awî, -ye -I"
  },
  {
    "x": "oyk-I (n)",
    "y": " b. ewk-I (n)"
  },
  {
    "x": "oyk-II (m)",
    "y": " awe-I (m)"
  },
  {
    "x": "oyn (n)",
    "y": " oyun, hile, numara"
  },
  {
    "x": "oyneyîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "oyniker, -e",
    "y": " numaracı, kurnaz, hilekâr"
  },
  {
    "x": "oynikeri (m)",
    "y": " b. oynikere (m)"
  },
  {
    "x": "oynîyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "bioynî",
    "y": " b. biewnî"
  },
  {
    "x": "boynî",
    "y": " b. biewnî"
  },
  {
    "x": "oynyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "oynyeyîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "oyra (n)",
    "y": " b. weyra (n)"
  },
  {
    "x": "oyro (n)",
    "y": " b. ewro (n)"
  },
  {
    "x": "oyş",
    "y": " b. çoş"
  },
  {
    "x": "oyta (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "oyte (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "oz (z)",
    "y": " b. ez (z)"
  },
  {
    "x": "oza (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "ozev, -e",
    "y": " b. ezeb, -e"
  },
  {
    "x": "ozeve (m)",
    "y": " b. ezebe (m)"
  },
  {
    "x": "ozevenî (m)",
    "y": " b. ezebîye (m)"
  },
  {
    "x": "ozevîye (m)",
    "y": " b. ezebîye (m)"
  },
  {
    "x": "Ozbekîstan (n)",
    "y": " Özbekistan"
  },
  {
    "x": "ozon (n)",
    "y": " ozon"
  },
  {
    "x": "ozonosfer (n)",
    "y": " ozon tabakası, ozonosfer"
  },
  {
    "x": "ozorîyayîş (n)",
    "y": " b. azerîyayîş (n)"
  },
  {
    "x": "ozorîyene",
    "y": " b. azerîyayene"
  },
  {
    "x": "PPP"
  },
  {
    "x": "pa-I (n)",
    "y": " 1)ayak, kadem\r\n~2)ayak (30,5 cm), fit (30,5 cm), fut (30,5 cm)"
  },
  {
    "x": "pay bê xo",
    "y": " b. pay bi xo"
  },
  {
    "x": "pay bi xo",
    "y": " yayan"
  },
  {
    "x": "pay bîyene",
    "y": " giyilmek (ayakkabı, pantolon vb. için)"
  },
  {
    "x": "pay dayene",
    "y": " giydirmek (ayakkabı, pantolon vb. için)"
  },
  {
    "x": "pay dîyayene",
    "y": " giydirilmek (ayakkabı, pantolon vb. için)"
  },
  {
    "x": "pay kerdene",
    "y": " giymek (ayakkabı, pantolon vb. için)"
  },
  {
    "x": "pay nayene ...",
    "y": " basmak"
  },
  {
    "x": "pay ... nayene",
    "y": " basmak\r\n~Pay vaşî na. (Ota bastı.)"
  },
  {
    "x": "pay ... nayîş (n)",
    "y": " basma"
  },
  {
    "x": "pay pirodayene",
    "y": " bozmak (mecazi), sabote etmek (mecazi)\r\n~Ey pay da karê ma ro. (İşimizi o sabote etti.)"
  },
  {
    "x": "pay pirodîyayene",
    "y": " bozulmak"
  },
  {
    "x": "pay ra",
    "y": " ayaküstü, ayaküzeri"
  },
  {
    "x": "pay re",
    "y": " b. pay ra"
  },
  {
    "x": "pay şîyayene",
    "y": " b. pay şîyene"
  },
  {
    "x": "pay şîyayeni",
    "y": " b. pay şîyene"
  },
  {
    "x": "pay şîyene",
    "y": " yürümek"
  },
  {
    "x": "pa-II",
    "y": " 1)beraber, beraberinde, birlikte, ile\r\n~2)b. bi-1, bi-2"
  },
  {
    "x": "pa ... a",
    "y": " b. bi ... ra"
  },
  {
    "x": "pa mendene",
    "y": " bakakalmak, kararsız kalmak"
  },
  {
    "x": "pa ... ra",
    "y": " b. bi ... wa"
  },
  {
    "x": "pa ... wa",
    "y": " b. bi ... wa"
  },
  {
    "x": "pa ... ya",
    "y": " b. bi ... wa"
  },
  {
    "x": "pa, -ye",
    "y": " yapışık"
  },
  {
    "x": "paa (m)",
    "y": " b. baha (m)"
  },
  {
    "x": "pabe, -ye-I",
    "y": " bekleyen, bekleyici"
  },
  {
    "x": "pabeyê ... bîyene",
    "y": " -i beklemek"
  },
  {
    "x": "pabeyê ... vindertene",
    "y": " b. pabeyê ... vindetene"
  },
  {
    "x": "pabeyê ... vindetene",
    "y": " -i beklemek"
  },
  {
    "x": "pabeyê ... vinetene",
    "y": " b. pabeyê ... vindetene"
  },
  {
    "x": "pabe-II (n)",
    "y": " b. pabok (n)"
  },
  {
    "x": "pabest (n)",
    "y": " bağlam"
  },
  {
    "x": "pabesta (m)",
    "y": " bağımlı, bağlantılı"
  },
  {
    "x": "pabeste (n)",
    "y": " 1)bağımlı, bağlantılı\r\n~2)ek, ilave"
  },
  {
    "x": "pabeste bîyene",
    "y": " bağımlılaşmak"
  },
  {
    "x": "pabestebîyayîş (n)",
    "y": " bağımlılaşma"
  },
  {
    "x": "pabesteyîye (m)",
    "y": " bağımlılık"
  },
  {
    "x": "pabestiş (n)",
    "y": " bağlantı"
  },
  {
    "x": "pabestişo dûrî (n)",
    "y": " uzak bağlantı"
  },
  {
    "x": "pabestî, -ye",
    "y": " bağımlı, bağlantılı"
  },
  {
    "x": "pabestî bîyene",
    "y": " bağımlılaşmak"
  },
  {
    "x": "pabestîbîyayîş (n)",
    "y": " bağımlılaşma"
  },
  {
    "x": "pabestîyîye (m)",
    "y": " bağımlılık"
  },
  {
    "x": "pabi",
    "y": " b. pa-II"
  },
  {
    "x": "pabo, -ye-I",
    "y": " b. pabe, -ye"
  },
  {
    "x": "pabo-II (n)",
    "y": " b. pabok (n)"
  },
  {
    "x": "pabok, -e-I",
    "y": " b. pabe, -ye"
  },
  {
    "x": "pabok-II (n)",
    "y": " emanet"
  },
  {
    "x": "pabokxane (n)",
    "y": " emanet yeri"
  },
  {
    "x": "paca (m)",
    "y": " pencere"
  },
  {
    "x": "pace (m)",
    "y": " b. page (m)"
  },
  {
    "x": "paç-I (n)",
    "y": " 1)bez, çaput, paçavra\r\n~2)gömlek, mintan\r\n~Paçê xo pira gîre/xo ra de/xo ra gîre! (Gömleğini giy!)"
  },
  {
    "x": "paçê domanan (n)",
    "y": " çocuk bezi, etek bezi"
  },
  {
    "x": "paçê domûnû (n)",
    "y": " b. paçê domanan (n)"
  },
  {
    "x": "paço harmekilm/hingilekilm/polkilm/polkirr/qol (n)",
    "y": " kısa kollu gömlek"
  },
  {
    "x": "paç-II (m)",
    "y": " b. paçî (m)"
  },
  {
    "x": "paç kerdene",
    "y": " b. paçî kerdene"
  },
  {
    "x": "paç-III",
    "y": " b. pak, -e"
  },
  {
    "x": "paçe (m)",
    "y": " üçetek"
  },
  {
    "x": "paçi (m)",
    "y": " b. paçe (m)"
  },
  {
    "x": "paçik (n)",
    "y": " 1)bez, çaput, paçavra\r\n~2)bez veya kumaşın küçük parçası\r\n~3)sargı"
  },
  {
    "x": "paçikê firaqan (n)",
    "y": " bulaşık bezi"
  },
  {
    "x": "paçî (m)",
    "y": " öpücük"
  },
  {
    "x": "paçî kerdene",
    "y": " öpmek"
  },
  {
    "x": "paçol bîyene",
    "y": " ezilmek"
  },
  {
    "x": "paçol kerdene",
    "y": " ezmek"
  },
  {
    "x": "paçolkerdiş (n)",
    "y": " ezme"
  },
  {
    "x": "padayene",
    "y": " kapatmak"
  },
  {
    "x": "padayîş (n)",
    "y": " kapatma"
  },
  {
    "x": "padeyîş (n)",
    "y": " b. padîyayîş (n)"
  },
  {
    "x": "padisaye (n)",
    "y": " b. padusaye (n)"
  },
  {
    "x": "padisayene",
    "y": " b. padusayene"
  },
  {
    "x": "padisayî, -ye",
    "y": " b. padusayî, -ye"
  },
  {
    "x": "padisayîş (n)",
    "y": " b. padusayîş (n)"
  },
  {
    "x": "padiskinayene",
    "y": " b. paduskînayene"
  },
  {
    "x": "padiskinayîş (n)",
    "y": " b. paduskînayîş (n)"
  },
  {
    "x": "padiskînayene",
    "y": " b. paduskînayene"
  },
  {
    "x": "padiskînayîş (n)",
    "y": " paduskînayîş (n)"
  },
  {
    "x": "padiskîyaya (m)",
    "y": " b. paduskîyaya (m)"
  },
  {
    "x": "padiskîyaye (n)",
    "y": " b. paduskîyaye (n)"
  },
  {
    "x": "padiskîyayene",
    "y": " b. paduskîyayene"
  },
  {
    "x": "padiskîyayî, -ye",
    "y": " b. paduskîyayî, -ye"
  },
  {
    "x": "padiskîyayîş (n)",
    "y": " b. paduskîyayîş (n)"
  },
  {
    "x": "padisnayene",
    "y": " b. b. padusnayene"
  },
  {
    "x": "padisnayox, -e",
    "y": " b. padusnayox, -e"
  },
  {
    "x": "padisok, -e",
    "y": " b. padusok, -e"
  },
  {
    "x": "padîyayene",
    "y": " kapanmak"
  },
  {
    "x": "padîyayîş (n)",
    "y": " kapanma"
  },
  {
    "x": "padusaya (m)",
    "y": " yapışık"
  },
  {
    "x": "padusaye (n)",
    "y": " yapışık"
  },
  {
    "x": "padusayene",
    "y": " -e yapışmak"
  },
  {
    "x": "padusayî, -ye",
    "y": " yapışık"
  },
  {
    "x": "padusayîş (n)",
    "y": " -e yapışma"
  },
  {
    "x": "padusîyaya (m)",
    "y": " b. padusaya (m)"
  },
  {
    "x": "padusîyayene",
    "y": " b. padusayene"
  },
  {
    "x": "padusîyayî, -ye",
    "y": " b. padusayî, -ye"
  },
  {
    "x": "padusîyayîş (n)",
    "y": " b. padusayîş (n)"
  },
  {
    "x": "paduskînayene",
    "y": " -e yapıştırmak"
  },
  {
    "x": "paduskînayîş (n)",
    "y": " -e yapıştırma"
  },
  {
    "x": "paduskîyaya (m)",
    "y": " yapışık"
  },
  {
    "x": "paduskîyaye (n)",
    "y": " yapışık"
  },
  {
    "x": "paduskîyayene",
    "y": " -e yapışmak"
  },
  {
    "x": "paduskîyayî, -ye",
    "y": " yapışık"
  },
  {
    "x": "paduskîyayîş (n)",
    "y": " -e yapışma"
  },
  {
    "x": "padusnayene",
    "y": " -e yapıştırmak"
  },
  {
    "x": "padusnayîş (n)",
    "y": " -e yapıştırma"
  },
  {
    "x": "padusnayox, -e",
    "y": " yapıştırıcı"
  },
  {
    "x": "padusok, -e",
    "y": " yapışkan"
  },
  {
    "x": "pafînayene",
    "y": " yapıştırmak (tokat vb. için)"
  },
  {
    "x": "pafînayîş (n)",
    "y": " 1)yapıştırma (tokat vb. için)\r\n~2)b. pavistiş (n)"
  },
  {
    "x": "pafîneyene",
    "y": " b. pafînîyayene"
  },
  {
    "x": "pafîneyîş (n)",
    "y": " b. pafînîyayîş (n)"
  },
  {
    "x": "pafînîyayene",
    "y": " yapıştırılmak (tokat vb. için)"
  },
  {
    "x": "pafînîyayîş (n)",
    "y": " yapıştırılma (tokat vb. için)"
  },
  {
    "x": "pafîştene",
    "y": " yapıştırmak (tokat vb. için)\r\n~Yew sîle pafîşta. (Bir tokat yapıştırmış.)"
  },
  {
    "x": "pafîştiş (n)",
    "y": " yapıştırma (tokat vb. için)"
  },
  {
    "x": "pafta (m)",
    "y": " pafta"
  },
  {
    "x": "pag (m)",
    "y": " b. page (m)"
  },
  {
    "x": "page (m)",
    "y": " 1)örtme (bina için)\r\n~2)yıkıntı, enkaz; harabe"
  },
  {
    "x": "paga çimî (m)",
    "y": " göz çukuru"
  },
  {
    "x": "pagi (m)",
    "y": " b. page (m)"
  },
  {
    "x": "paginayene",
    "y": " değmek"
  },
  {
    "x": "paginayîş (n)",
    "y": " değme-I "
  },
  {
    "x": "pagirêdayî, -ye",
    "y": " bağlantılı"
  },
  {
    "x": "pagirêdayîş (n)",
    "y": " bağlantı"
  },
  {
    "x": "pagirêdayîşê aboneyan (n)",
    "y": " abone bağlantısı"
  },
  {
    "x": "pagirêdayîşê kanalî (n)",
    "y": " kanal bağlantısı"
  },
  {
    "x": "pagirêdayîşo dûrî (n)",
    "y": " uzak bağlantı"
  },
  {
    "x": "pagirênêdayeyîye (m)",
    "y": " bağlantısızlık"
  },
  {
    "x": "pagirênêdayî, -ye",
    "y": " bağlantısız"
  },
  {
    "x": "pagunayene",
    "y": " b. paginayene"
  },
  {
    "x": "pahn, -i",
    "y": " b. pan, -e-I"
  },
  {
    "x": "pahn bîyene",
    "y": " b. pan bîyene"
  },
  {
    "x": "pahn kerdene",
    "y": " b. pan kerdene"
  },
  {
    "x": "pahney (m)",
    "y": " b. panîye (m)"
  },
  {
    "x": "pahnê (m)",
    "y": " b. panîye (m)"
  },
  {
    "x": "pahnîye (m)",
    "y": " b. panîye (m)"
  },
  {
    "x": "paht, -e",
    "y": " basık"
  },
  {
    "x": "pahtikin, -e",
    "y": " basık"
  },
  {
    "x": "pahtikinîye (m)",
    "y": " basıklık"
  },
  {
    "x": "pahtîye (m)",
    "y": " basıklık"
  },
  {
    "x": "paj",
    "y": " b. panc "
  },
  {
    "x": "paja (m)",
    "y": " b. paca (m)"
  },
  {
    "x": "pak, -e",
    "y": " 1)temiz, safi\r\n~2)hijyenik"
  },
  {
    "x": "pak bîyene",
    "y": " arınmak, paklanmak, temizlenmek"
  },
  {
    "x": "pak kerdene",
    "y": " 1)temizlemek\r\n~2)silmek (silgi vb. ile)"
  },
  {
    "x": "pak kerdeni",
    "y": " b. pak kerdene"
  },
  {
    "x": "paka (s)",
    "y": " açık hava, bulutsuz hava\r\n~Paka yo. (Hava açıktır.)"
  },
  {
    "x": "pakbîyayîş (n)",
    "y": " arınma, paklanma, temizlenme"
  },
  {
    "x": "pakerdene",
    "y": " takmak"
  },
  {
    "x": "pakerdiş (n)",
    "y": " takma"
  },
  {
    "x": "paket (m)",
    "y": " b. pakête (m)"
  },
  {
    "x": "paket kerdene",
    "y": " b. pakêt kerdene"
  },
  {
    "x": "pakete (m)",
    "y": " b. pakête (m)"
  },
  {
    "x": "pakete kerdene",
    "y": " b. pakêt kerdene"
  },
  {
    "x": "paketekerdiş (n)",
    "y": " b. pakêtkerdiş (n)"
  },
  {
    "x": "paketkerdiş (n)",
    "y": " b. pakêtkerdiş (n)"
  },
  {
    "x": "pakewtene",
    "y": " değmek"
  },
  {
    "x": "pakewtiş (n)",
    "y": " değme-I"
  },
  {
    "x": "pakêt (m)",
    "y": " b. pakête (m)"
  },
  {
    "x": "pakêt kerdene",
    "y": " paketlemek"
  },
  {
    "x": "pakête (m)",
    "y": " paket, koli"
  },
  {
    "x": "pakêta posteyî (m)",
    "y": " koli"
  },
  {
    "x": "pakête kerdene",
    "y": " b. pakêt kerdene"
  },
  {
    "x": "pakêtekerdiş (n)",
    "y": " b. pakêtkerdiş (n)"
  },
  {
    "x": "pakêtker, -e",
    "y": " paketleyici"
  },
  {
    "x": "pakêtkerdiş (n)",
    "y": " paketleme"
  },
  {
    "x": "pakêtkerdişo yewreyî",
    "y": " tek yönlü paketleme"
  },
  {
    "x": "pakî (m)",
    "y": " temizlik"
  },
  {
    "x": "Pakîstan (n)",
    "y": " Pakistan "
  },
  {
    "x": "pakîye (m)",
    "y": " temizlik"
  },
  {
    "x": "pakkar, -e",
    "y": " temizlikçi"
  },
  {
    "x": "pakkarî (m)",
    "y": " temizlikçilik"
  },
  {
    "x": "pakkarîye (m)",
    "y": " temizlikçilik"
  },
  {
    "x": "pakker (n) (TE)",
    "y": " 1)temizleyici\r\n~2)silgi"
  },
  {
    "x": "pakkera lojine",
    "y": " ocakçı"
  },
  {
    "x": "pakkerê lojine",
    "y": " ocakçı"
  },
  {
    "x": "pakkerîya lojine (m)",
    "y": " ocakçılık"
  },
  {
    "x": "pakkera pixêrî",
    "y": " ocakçı"
  },
  {
    "x": "pakkerê pixêrî",
    "y": " ocakçı"
  },
  {
    "x": "pakkerîya pixêrî (m)",
    "y": " ocakçılık"
  },
  {
    "x": "pakkerdiş (n)",
    "y": " 1)temizleme\r\n~2)silme (silgi vb. ile)"
  },
  {
    "x": "pakkerdişê deynî",
    "y": " borç silme"
  },
  {
    "x": "pakuwayene",
    "y": " 1)sertçe vurmak\r\n~2)çakmak"
  },
  {
    "x": "pakuwayîş (n)",
    "y": " 1)sertçe vurma\r\n~2)çakma"
  },
  {
    "x": "pakuyayene",
    "y": " b. pakuwayene"
  },
  {
    "x": "pakuyayîş (n)",
    "y": " b. pakuwayîş (n)"
  },
  {
    "x": "pal (m)",
    "y": " b. pale-II (m)"
  },
  {
    "x": "pal dayene",
    "y": " dayanmak (bir şeyden güç almak)"
  },
  {
    "x": "pala xo dayene",
    "y": " dayanmak (bir şeyden güç almak"
  },
  {
    "x": "pala xo dayîş (n)",
    "y": " dayanma (bir şeyden güç alma)"
  },
  {
    "x": "pala (m)",
    "y": " 1)ırgat, tarım işçisi, rençper, işçi\r\n~2)orakçı"
  },
  {
    "x": "palane (m)",
    "y": " palan"
  },
  {
    "x": "palane ronayene",
    "y": " hayvanın palanını indirmek"
  },
  {
    "x": "palane ser kerdene",
    "y": " hayvanın palanını indirmek"
  },
  {
    "x": "palapuştike (m)",
    "y": " balon"
  },
  {
    "x": "palas (n)",
    "y": " 1)kıldan yapılan bir çeşit basit kilim\r\n~2)çul (palanın altında hayvanın sırtına örtülen çul) "
  },
  {
    "x": "palasqa (m)",
    "y": " palaska"
  },
  {
    "x": "palasqaya mêrî (m)",
    "y": " hakiki deriden yapılmış palaska"
  },
  {
    "x": "palasqe (n)",
    "y": " b. palasqa (m)"
  },
  {
    "x": "palasqi (n)",
    "y": " b. palasqa (m)"
  },
  {
    "x": "palax (m)",
    "y": " b. palaxe (m)"
  },
  {
    "x": "palax (n)",
    "y": " b. palaxe (m)"
  },
  {
    "x": "palaxe (m)",
    "y": " yerdeki kuru ot birikintisi"
  },
  {
    "x": "palaxi (m)",
    "y": " b. palaxe (m)"
  },
  {
    "x": "palaxûpuç (n)",
    "y": " b. puşûpalaxe (m)"
  },
  {
    "x": "palaxûpûç (n)",
    "y": " b. puşûpalaxe (m)"
  },
  {
    "x": "palaxûpus (n)",
    "y": " b. puşûpalaxe (m)"
  },
  {
    "x": "palaz (n)",
    "y": " b. palas (n)"
  },
  {
    "x": "palazi (n)",
    "y": " b. perazî (m)"
  },
  {
    "x": "paldayîş (n)",
    "y": " dayanma (bir şeyden güç alma)"
  },
  {
    "x": "paldim (n)",
    "y": " 1)kalçanın alt bölümü\r\n~2)kuskun, paldım"
  },
  {
    "x": "paldum (n)",
    "y": " b. paldim (n)"
  },
  {
    "x": "paldun (n)",
    "y": " b. paldim (n)"
  },
  {
    "x": "paldûm (n)",
    "y": " b. paldim (n)"
  },
  {
    "x": "pale-I (n)",
    "y": " 1)ırgat, tarım işçisi, rençper, işçi\r\n~2)orakçı"
  },
  {
    "x": "pale-II (m)",
    "y": " 1)bacak\r\n~2)yana doğru açılabilecek kadar açılan iki bacak arasındaki uzunluk (ölçüsü)"
  },
  {
    "x": "Paleolîtîk (n)",
    "y": " Eski Taş Çağı, Eski Taş Devri, Yontma Taş Çağı, Yontma Taş Devri, Paleolitik (M. Ö. 14 milyon-2,5 milyon) "
  },
  {
    "x": "Paleolîtîko Mîyanên (n)",
    "y": " Orta Paleolitik (M. Ö. 200 000-40 000)"
  },
  {
    "x": "Paleolîtîko Peyên (n)",
    "y": " Üst Paleolitik (M. Ö. 40.000-12.000) "
  },
  {
    "x": "Paleolîtîko Verên (n)",
    "y": " Alt Paleolitik (M. Ö. 2,5 milyon-200 000)"
  },
  {
    "x": "paleyey (m)",
    "y": " b. paleyî (m), paleyîye (m)"
  },
  {
    "x": "paleyênî (m)",
    "y": " ırgatlık, işçilik, rençperlik"
  },
  {
    "x": "paleyî (m)",
    "y": " ırgatlık, işçilik, rençperlik"
  },
  {
    "x": "paleyîye (m)",
    "y": " ırgatlık, işçilik, rençperlik"
  },
  {
    "x": "Pali (n)",
    "y": " Palu "
  },
  {
    "x": "palike-I (m)",
    "y": " basamak"
  },
  {
    "x": "palika nêrdîwane (m)",
    "y": " merdiven basamağı"
  },
  {
    "x": "palika nîreyî (m)",
    "y": " boyunduruğun öküzün boynuna gelen kısmı"
  },
  {
    "x": "palika nîrî (m)",
    "y": " b. palika nîreyî (m)"
  },
  {
    "x": "palike-II (m)",
    "y": " b. palane (m)"
  },
  {
    "x": "paliki (m)",
    "y": " b. palane (m)"
  },
  {
    "x": "palisqe (n)",
    "y": " b. palasqa (m)"
  },
  {
    "x": "palisqeyo mêri (n)",
    "y": " b. palasqaya mêrî (m)"
  },
  {
    "x": "palisqi (n)",
    "y": " b. palasqa (m)"
  },
  {
    "x": "palişna (m)",
    "y": " b. balişna (m)"
  },
  {
    "x": "paliwic, -i",
    "y": " b. palîyij, -e "
  },
  {
    "x": "palî (m)",
    "y": " b. paleyî (m), paleyîye (m)"
  },
  {
    "x": "Palîkîr (n)",
    "y": " Pelikir"
  },
  {
    "x": "palîsne (n)",
    "y": " uyluk "
  },
  {
    "x": "palîsqa (m)",
    "y": " b. palasqa (m)"
  },
  {
    "x": "palîsqaya mêrî (m)",
    "y": " b. palasqaya mêrî (m)"
  },
  {
    "x": "palîsqe (n)",
    "y": " b. palasqa (m)"
  },
  {
    "x": "palîyij, -e",
    "y": " Palulu "
  },
  {
    "x": "palîyiz, -e",
    "y": " b. palîyij, -e"
  },
  {
    "x": "palkursî (n)",
    "y": " koltuk"
  },
  {
    "x": "palmîye (m)",
    "y": " palmiye"
  },
  {
    "x": "palon (m)",
    "y": " b. palane (m) "
  },
  {
    "x": "palpat (m)",
    "y": " b. palpate (m)"
  },
  {
    "x": "palpatê sîl (m)",
    "y": " b. palpata silî (m)"
  },
  {
    "x": "palpate (m)",
    "y": " büyükbaş hayvanın bir defada yaptığı dışkı topağı, tezek"
  },
  {
    "x": "palpata silî (m)",
    "y": " büyükbaş hayvanın bir defada yaptığı dışkı topağı"
  },
  {
    "x": "palsna (m)",
    "y": " b. paşna (m)"
  },
  {
    "x": "palto (n)",
    "y": " palto"
  },
  {
    "x": "palûn (m)",
    "y": " b. palane (m)"
  },
  {
    "x": "palûnd (m)",
    "y": " b. palane (m)"
  },
  {
    "x": "palxaçi (m)",
    "y": " b. pelxaçe (m) "
  },
  {
    "x": "palyaço (n)",
    "y": " palyaço "
  },
  {
    "x": "palyaçoyî (m)",
    "y": " palyaçoluk"
  },
  {
    "x": "palyaçoyîye (m)",
    "y": " palyaçoluk"
  },
  {
    "x": "pamendene",
    "y": " bakmak\r\n~Ez saetêk menda pa la mi fehm nêkerd. (Bir saat baktım fakat anlamadım.)"
  },
  {
    "x": "pamendiş (n)",
    "y": " bakakalma, bakma"
  },
  {
    "x": "pampoli (m)",
    "y": " b. papûre (m)"
  },
  {
    "x": "pan, -e-I",
    "y": " 1)geniş\r\n~2)basık"
  },
  {
    "x": "pan bîyene",
    "y": " 1)geniş olmak, genişlemek\r\n~2)ezilmek "
  },
  {
    "x": "pan kerdene",
    "y": " 1)genişletmek\r\n~2)ezmek"
  },
  {
    "x": "pan-II",
    "y": " b. panc"
  },
  {
    "x": "pan sey",
    "y": " b. panc sey "
  },
  {
    "x": "Panama (m)",
    "y": " Panama"
  },
  {
    "x": "Panama City (m)",
    "y": " Panama"
  },
  {
    "x": "panaye (n)",
    "y": " basılı (peynir vb. için)"
  },
  {
    "x": "panayene",
    "y": " 1)yakmak, yandırmak\r\n~2)tutuşturmak\r\n~3)takmak (lakap vb.)\r\n~4)yapıştırmak\r\n~5)dokunmak\r\n~6)ateş etmek, sıkmak (silah vb. için)"
  },
  {
    "x": "panayîş (n)",
    "y": " 1)yakma, yandırma \r\n~2)tutuşturma \r\n~3)takma (lakap vb.) \r\n~4)yapıştırma \r\n~5)dokunma \r\n~6)ateş etme, sıkma (silah vb. için)"
  },
  {
    "x": "panayîşo çepraz (n)",
    "y": " çapraz ateş"
  },
  {
    "x": "panayox, -e",
    "y": " yapıştırıcı"
  },
  {
    "x": "panc",
    "y": " beş"
  },
  {
    "x": "panc heme",
    "y": " her beş"
  },
  {
    "x": "panc hemîne",
    "y": " her beşi, her beşini"
  },
  {
    "x": "panc sey",
    "y": " beş yüz"
  },
  {
    "x": "panc seyin, -e",
    "y": " beşyüzüncü"
  },
  {
    "x": "pancas",
    "y": " elli"
  },
  {
    "x": "pancasin, -e",
    "y": " ellinci "
  },
  {
    "x": "pance (n)",
    "y": " 1)paça \r\n~2)pantolon ve şalvar gibi giyeceklerin bacak kısmı \r\n~3)kırkılan keçinin sırtında ve arka bacaklarında kırkılmadan bırakılan kısım"
  },
  {
    "x": "panceyê xo to kerdene",
    "y": " paçaları sıvamak"
  },
  {
    "x": "panceyê xo to kerdene",
    "y": " paçalarını sıvamak"
  },
  {
    "x": "panceyî kerdene",
    "y": " keçiyi kırkarken arka kısmının ve arka bacaklarının kıllarını kırkmamak"
  },
  {
    "x": "pances",
    "y": " b. pancês"
  },
  {
    "x": "panceyîkerda (m)",
    "y": " diğer yerleri kırkılıp arka kısmı ve arka bacaklarının kılları kırkılmamış keçi"
  },
  {
    "x": "pancês",
    "y": " on beş "
  },
  {
    "x": "pancgoşe (n)",
    "y": " beşgen (mat)"
  },
  {
    "x": "panci (n)",
    "y": " b. pance (n)"
  },
  {
    "x": "pancik (n)",
    "y": " 1)paça\r\n~2)pantolon ve şalvar gibi giyeceklerin bacak kısmı"
  },
  {
    "x": "pancike (m)",
    "y": " beştaş [oyunu]"
  },
  {
    "x": "pancin, -e",
    "y": " beşinci"
  },
  {
    "x": "pancîs",
    "y": " b. pancês"
  },
  {
    "x": "pancîyes",
    "y": " b. pancês"
  },
  {
    "x": "pançe (n)",
    "y": " b. pance (n)"
  },
  {
    "x": "pançik (n)",
    "y": " b. pancik (n)"
  },
  {
    "x": "panda, -ye",
    "y": " panda"
  },
  {
    "x": "pandemî (m)",
    "y": " pandemi"
  },
  {
    "x": "pandemîk, -e",
    "y": " pandemik"
  },
  {
    "x": "pandomîm (n)",
    "y": " pantomim, pandomim"
  },
  {
    "x": "pandomîmkar, -e",
    "y": " pantomimci, pandomimci"
  },
  {
    "x": "pandusayene",
    "y": " b. pendusayene"
  },
  {
    "x": "pandusnayene",
    "y": " b. pendusnayene"
  },
  {
    "x": "panel (n)",
    "y": " açık oturum, panel"
  },
  {
    "x": "paney (m)",
    "y": " b. panîye (m)"
  },
  {
    "x": "paneyayene",
    "y": " b. panîyayene"
  },
  {
    "x": "paneyayîş (n)",
    "y": " b. panîyayîş (n)"
  },
  {
    "x": "panêbesta (m)",
    "y": " bağlantısız"
  },
  {
    "x": "panêbeste (n)",
    "y": " bağlantısız"
  },
  {
    "x": "panêbesteyîye (m)",
    "y": " bağlantısızlık"
  },
  {
    "x": "panêbestî, -ye",
    "y": " bağlantısız"
  },
  {
    "x": "panêbestîyîye (m)",
    "y": " bağlantısızlık"
  },
  {
    "x": "pangecî, -ye",
    "y": " b. bankaçî, -ye"
  },
  {
    "x": "pangilot (m)",
    "y": " b. pangnote (m)"
  },
  {
    "x": "pangnote (m)",
    "y": " banknot, lira"
  },
  {
    "x": "pangulete (m)",
    "y": " b. pangnote (m)"
  },
  {
    "x": "pangulête (m)",
    "y": " b. pangnote (m)"
  },
  {
    "x": "paniştene",
    "y": " 1)-e yapışmak\r\n~2)bulaşmak (hastalık için)"
  },
  {
    "x": "paniştiş (n)",
    "y": " 1)-e yapışma\r\n~2)bulaşma (hastalık için)"
  },
  {
    "x": "panî (m)",
    "y": " genişlik"
  },
  {
    "x": "panîstene",
    "y": " b. paniştene"
  },
  {
    "x": "panîstîs (n)",
    "y": " b. paniştiş (n)"
  },
  {
    "x": "panîştene",
    "y": " b. paniştene"
  },
  {
    "x": "panîştiş (n)",
    "y": " b. paniştiş (n)"
  },
  {
    "x": "panîyaya (m)",
    "y": " 1)yakılmış olan\r\n~2)yapışık"
  },
  {
    "x": "panîyaye (n)",
    "y": " 1)yakılmış olan\r\n~2)yapışık"
  },
  {
    "x": "panîyayene",
    "y": " 1)yakılmak, tutuşmak\r\n~2)-e yapışmak, yapışmak"
  },
  {
    "x": "panîyayîş (n)",
    "y": " 1)yakılma, tutuşma\r\n~2)-e yapışma, yapışma"
  },
  {
    "x": "panîyayî, -ye",
    "y": " 1)yakılmış olan\r\n~2)yapışık"
  },
  {
    "x": "panîye (m)",
    "y": " 1)genişlik\r\n~2)basıklık"
  },
  {
    "x": "panj",
    "y": " b. panc"
  },
  {
    "x": "panj sey",
    "y": " b. panc sey"
  },
  {
    "x": "panjin, -i",
    "y": " b. pancin, -e"
  },
  {
    "x": "pank, -i",
    "y": " b. pak, -e"
  },
  {
    "x": "pank kerdene",
    "y": " b. pak kerdene"
  },
  {
    "x": "panka-I (s)",
    "y": " b. paka (s)"
  },
  {
    "x": "panka-II (m)",
    "y": " b. banka (m)"
  },
  {
    "x": "pankerdiş (n)",
    "y": " ezme"
  },
  {
    "x": "pankey (m)",
    "y": " b. pakî (m), pakîye (m)"
  },
  {
    "x": "pankkerdiş (n)",
    "y": " b. pakkerdiş (n)"
  },
  {
    "x": "pankreas (n)",
    "y": " pankreas "
  },
  {
    "x": "pano (n)",
    "y": " pano"
  },
  {
    "x": "panoyê dayîş-girewtişê agahî",
    "y": " iletişim panosu"
  },
  {
    "x": "panoyê dayîş-girewtişê xeberan",
    "y": " iletişim panosu"
  },
  {
    "x": "panoyê komunîkasyonî (n)",
    "y": " iletişim panosu"
  },
  {
    "x": "panote (m)",
    "y": " b. pangnote (m)"
  },
  {
    "x": "pansî (zh)",
    "y": " beştaş [oyunu]"
  },
  {
    "x": "pansîyon (n)",
    "y": " pansiyon"
  },
  {
    "x": "pansîyondar, -e",
    "y": " pansiyoncu"
  },
  {
    "x": "pansuman (n)",
    "y": " pansuman "
  },
  {
    "x": "pansumanker, -e",
    "y": " pansumancı"
  },
  {
    "x": "panşeme (n)",
    "y": " perşembe"
  },
  {
    "x": "pante (m)",
    "y": " b. pata (m)"
  },
  {
    "x": "panter, -e",
    "y": " panter "
  },
  {
    "x": "pantîle (m)",
    "y": " b. patîle-I (m)"
  },
  {
    "x": "pantîli (m)",
    "y": " b. patîle-I (m)"
  },
  {
    "x": "pantolî (zh)",
    "y": " pantolon\r\n~Ey pantolê xo vetî. (O pantolonunu çıkardı.)"
  },
  {
    "x": "pantolê blûcînî (zh)",
    "y": " blucin pantolon"
  },
  {
    "x": "pantolê kilmî (zh)",
    "y": " kısa pantolon"
  },
  {
    "x": "pantolê kirrî (zh)",
    "y": " kısa pantolon"
  },
  {
    "x": "pantolê kotî (zh)",
    "y": " kot pantolon"
  },
  {
    "x": "pantolê qedîfeyênî (zh)",
    "y": " kadife pantolon"
  },
  {
    "x": "pantolê qolî (zh)",
    "y": " kısa pantolon"
  },
  {
    "x": "pantolê qotî (zh)",
    "y": " b. pantolê kotî (zh)"
  },
  {
    "x": "pantoloyn (zh)",
    "y": " b. pantolî (zh)"
  },
  {
    "x": "pantorî (zh)",
    "y": " b. pantolî (zh)"
  },
  {
    "x": "pantorrî (zh)",
    "y": " b. pantolî (zh)"
  },
  {
    "x": "pantoyl (zh)",
    "y": " b. pantolî (zh)"
  },
  {
    "x": "panz",
    "y": " b. panc "
  },
  {
    "x": "pap (n)",
    "y": " üzümsüz salkım çöpü (üzümü alınmış salkım artığı)"
  },
  {
    "x": "papa (n)",
    "y": " papa"
  },
  {
    "x": "papat-I (n)",
    "y": " b. palpate (m)"
  },
  {
    "x": "papat-II (m)",
    "y": " b. papatî (m)"
  },
  {
    "x": "papatî (m)",
    "y": " ekmeği tandıra yapıştırmak için kullanılan bir araç"
  },
  {
    "x": "papax (m)",
    "y": " b. papaxe (m) "
  },
  {
    "x": "papaxan, -e",
    "y": " papağan "
  },
  {
    "x": "papaxe (m)",
    "y": " papak"
  },
  {
    "x": "papayî (m)",
    "y": " papalık"
  },
  {
    "x": "papayîye (m)",
    "y": " papalık"
  },
  {
    "x": "papaz (n)",
    "y": " papaz"
  },
  {
    "x": "papazî (m)",
    "y": " papazlık"
  },
  {
    "x": "papazîye (m)",
    "y": " papazlık"
  },
  {
    "x": "papik (n)",
    "y": " patik (küçük çocuk ayakkabısı)"
  },
  {
    "x": "papizê xo bi ... kerdene",
    "y": " dalga geçmek\r\n~Papizê xo bi ey meke! (Onunla dalga geçme!)"
  },
  {
    "x": "papîn (n)",
    "y": " b. perpûn (n)"
  },
  {
    "x": "papori (m)",
    "y": " b. papûre (m)"
  },
  {
    "x": "Papua-Gîneya Newîye (m)",
    "y": " Papua-Yeni Gine "
  },
  {
    "x": "papuç (n)",
    "y": " patik (ev içinde terlik gibi giyilen kısa konçlu çorap)"
  },
  {
    "x": "papuiç (n)",
    "y": " b. papuç (n)"
  },
  {
    "x": "papure (n)",
    "y": " b. papûre (m)"
  },
  {
    "x": "papûre (m)",
    "y": " şose"
  },
  {
    "x": "papyone (m)",
    "y": " papyon, papyon kravat"
  },
  {
    "x": "paq (n)",
    "y": " bacak"
  },
  {
    "x": "paqe (m)",
    "y": " b. paq (n)"
  },
  {
    "x": "paqir (n)",
    "y": " b. paxir (n)"
  },
  {
    "x": "paqlawa (m)",
    "y": " b. baqlawa (m)"
  },
  {
    "x": "paqlewa (m)",
    "y": " b. baqlawa (m)"
  },
  {
    "x": "par-I (n)",
    "y": " geçen yıl, geçen sene"
  },
  {
    "x": "par-II (n)",
    "y": " 1)b. perr (n)\r\n~2)b. pel-I (n)"
  },
  {
    "x": "par-III (m)",
    "y": " b. pare-I (m)"
  },
  {
    "x": "parê qine (n)",
    "y": " kalçanın alt bölümü"
  },
  {
    "x": "para kerdeni",
    "y": " b. pare kerdene"
  },
  {
    "x": "paradoks (n)",
    "y": " çelişme"
  },
  {
    "x": "paragraf (n)",
    "y": " paragraf "
  },
  {
    "x": "Paragûay",
    "y": " Paraguay "
  },
  {
    "x": "parakerdiş (n)",
    "y": " b. parekerdiş (n)"
  },
  {
    "x": "paralel, -e",
    "y": " paralel"
  },
  {
    "x": "paralelkenar (n)",
    "y": " paralelkenar (mat)"
  },
  {
    "x": "Paramarîbo (n)",
    "y": " Paramaribo"
  },
  {
    "x": "parametre (n) (mat)",
    "y": " değişken, parametre"
  },
  {
    "x": "parametredar, -e",
    "y": " parametreli, parametrik"
  },
  {
    "x": "parametrîk, -e",
    "y": " parametreli, parametrik"
  },
  {
    "x": "paratoner (n)",
    "y": " yıldırımkıran, yıldırımsavar, yıldırımlık, paratoner"
  },
  {
    "x": "paravana (m)",
    "y": " paravan, paravana"
  },
  {
    "x": "parayîş (n)",
    "y": " b. perayîş (n)"
  },
  {
    "x": "parçe (n)",
    "y": " parça"
  },
  {
    "x": "parçe bîyene",
    "y": " parçalanmak"
  },
  {
    "x": "parçe kerdene",
    "y": " parçalamak"
  },
  {
    "x": "parçey goştî (n)",
    "y": " b. parçeyê goştî (n)"
  },
  {
    "x": "parçey nanî (n)",
    "y": " b. parçeyê nanî (n)"
  },
  {
    "x": "parçey nonî (n)",
    "y": " b. parçeyê nanî (n)"
  },
  {
    "x": "parçey nûnî (n)",
    "y": " b. parçeyê nanî (n)"
  },
  {
    "x": "parçeyê cîgere (n)",
    "y": " ciğerpare"
  },
  {
    "x": "parçeyê fîlmî (zh, sn)",
    "y": " treyler"
  },
  {
    "x": "parçeyê goştî (n)",
    "y": " et parçası"
  },
  {
    "x": "parçeyê nanî (n)",
    "y": " ekmek parçası"
  },
  {
    "x": "parçeyo edebî (n)",
    "y": " edebi parça"
  },
  {
    "x": "parçebîyayîş (n)",
    "y": " parçalanma"
  },
  {
    "x": "parçekerdiş (n)",
    "y": " parçalama"
  },
  {
    "x": "parçeyek (n)",
    "y": " parçacık"
  },
  {
    "x": "parçi (n)",
    "y": " b. parçe (n)"
  },
  {
    "x": "parçi bîyene",
    "y": " b. parçe bîyene"
  },
  {
    "x": "parçi kerdene",
    "y": " b. parçe kerdene"
  },
  {
    "x": "parçibîyayîş (n)",
    "y": " b. parçebîyayîş (n)"
  },
  {
    "x": "parçikerdiş (n)",
    "y": " b. parçekerdiş (n)"
  },
  {
    "x": "parçîn (n)",
    "y": " b. perçîn (n)"
  },
  {
    "x": "pardar, -e",
    "y": " hissedar, ortak, paydaş"
  },
  {
    "x": "pardarî (m)",
    "y": " iştirak, ortaklık, paydaşlık"
  },
  {
    "x": "pardarîye (m)",
    "y": " iştirak, ortaklık, paydaşlık"
  },
  {
    "x": "pardarîya anonîme (m)",
    "y": " anonim ortaklık"
  },
  {
    "x": "parde (n)",
    "y": " b. perde (n)"
  },
  {
    "x": "pardey çimî (n)",
    "y": " b. perdeyê çimî (n)"
  },
  {
    "x": "pardeso (n)",
    "y": " b. pardosî (n)"
  },
  {
    "x": "pardim (n)",
    "y": " b. paldim (n)"
  },
  {
    "x": "pardîso (n)",
    "y": " b. pardosî (n)"
  },
  {
    "x": "pardîsû (n)",
    "y": " b. pardosî (n)"
  },
  {
    "x": "pardon",
    "y": " pardon"
  },
  {
    "x": "pardosî (n)",
    "y": " pardösü"
  },
  {
    "x": "pardum (n)",
    "y": " b. paldim (n)"
  },
  {
    "x": "pardun (n)",
    "y": " b. paldim (n)"
  },
  {
    "x": "pare-I (m)",
    "y": " 1)pay, hisse \r\n~2)pay (mat) \r\n~3)bölüm (TE) (mat) \r\n~4)hayvana yüklenen çuval vb. yükünde yükün iki tarafından her biri \r\n~5)marj (ekonomi için)"
  },
  {
    "x": "para pardarîya pêroyîye (m)",
    "y": " kamu ortaklık payı"
  },
  {
    "x": "para pardarîya umûmîya (m)",
    "y": " kamu ortaklık payı"
  },
  {
    "x": "pare kerdene",
    "y": " pay etmek, taksim etmek, paylaşmak, bölmek, bölüşmek, üleşmek"
  },
  {
    "x": "pare-II (n)",
    "y": " heybenin iki tarafından her biri"
  },
  {
    "x": "parebîyaye (n)",
    "y": " bölünen"
  },
  {
    "x": "pareda (m)",
    "y": " payda (mat)"
  },
  {
    "x": "parek (n)",
    "y": " bölü (mat), taksim (mat)\r\n~Vîst parek çar, keno panc: 20:4= 5 (Yirmi bölü dört, eşittir beş.)\r\n~çewres parek çar: kırk taksim dört (40/\r\n~4)"
  },
  {
    "x": "pareker (n)",
    "y": " bölen (mat)"
  },
  {
    "x": "parekerdiş (n)",
    "y": " 1)pay etme, taksim etme\r\n~2)paylaşım, taksim, bölme, bölüşme, bölüşüm, üleşme"
  },
  {
    "x": "parên, -e",
    "y": " geçen yıldan kalma, geçen yılki"
  },
  {
    "x": "parêz (n)",
    "y": " diyet, perhiz"
  },
  {
    "x": "parêzzan, -e",
    "y": " diyetisyen"
  },
  {
    "x": "pari (m)",
    "y": " b. pare-I (m)"
  },
  {
    "x": "pariznayîn",
    "y": " b. parzunayene"
  },
  {
    "x": "pariznayîş (n)",
    "y": " b. parzunayîş (n)"
  },
  {
    "x": "pariznîyayîne",
    "y": " b. parzunîyayene"
  },
  {
    "x": "pariznîyayîş (n)",
    "y": " b. parzunîyayîş (n)"
  },
  {
    "x": "parî (n)",
    "y": " lokma"
  },
  {
    "x": "parîn, -e",
    "y": " b. parên, -e"
  },
  {
    "x": "Parîs (n)",
    "y": " Paris"
  },
  {
    "x": "parju (n)",
    "y": " b. parzun (n)"
  },
  {
    "x": "park (n)",
    "y": " 1)b. parke-I (m)\r\n~2) b. otoparke (m)"
  },
  {
    "x": "park kerdene",
    "y": " park etmek"
  },
  {
    "x": "park (m)",
    "y": " b. parke-I (m)"
  },
  {
    "x": "parka (m)",
    "y": " parka"
  },
  {
    "x": "parke-I (m)",
    "y": " 1)park (ağaçlandırılmış ve çiçeklendirilmiş geniş alan)\r\n~2)otopark, park (motorlu taşıtlar için)"
  },
  {
    "x": "parke-II (n)",
    "y": " parke"
  },
  {
    "x": "parki (n)",
    "y": " b. parke-II (n)"
  },
  {
    "x": "parlamenter, -e",
    "y": " mebus, milletvekili, parlamenter"
  },
  {
    "x": "parlamenterîye (m)",
    "y": " milletvekilliği, parlamenterlik"
  },
  {
    "x": "parlamento (n)",
    "y": " millet meclisi, parlamento "
  },
  {
    "x": "parlementer, -e",
    "y": " b. parlamenter, -e"
  },
  {
    "x": "parna",
    "y": " geçen yıldan beri"
  },
  {
    "x": "parone",
    "y": " bir yılını doldurmuş olan buzağı "
  },
  {
    "x": "paronge",
    "y": " b. parone"
  },
  {
    "x": "parpar (n)",
    "y": " b. pirpar (n)"
  },
  {
    "x": "parpişîyayene",
    "y": " böbürlenmek, koltukları kabarmak, övünç duymak"
  },
  {
    "x": "parq (n)",
    "y": " parke-I (m)"
  },
  {
    "x": "parqe (m)",
    "y": " parke-I (m)"
  },
  {
    "x": "parqi (m)",
    "y": " parke-I (m)"
  },
  {
    "x": "parrayîş (n)",
    "y": " b. perayîş (n)"
  },
  {
    "x": "pars, -e-I",
    "y": " pars"
  },
  {
    "x": "pars-II (n)",
    "y": " b. pers (n)"
  },
  {
    "x": "pars kerdene",
    "y": " b. pers kerdene"
  },
  {
    "x": "parsayene",
    "y": " b. persayene"
  },
  {
    "x": "parsayîş (n)",
    "y": " b. persayîş (n)"
  },
  {
    "x": "parsçî, -ye",
    "y": " b. parsekçî, -ye"
  },
  {
    "x": "parsecî, -ye",
    "y": " b. parsekçî, -ye"
  },
  {
    "x": "parsek, -e",
    "y": " dilenci"
  },
  {
    "x": "parsekcî (n, m)",
    "y": " b. parsekçî, -ye"
  },
  {
    "x": "parsekçî, -ye",
    "y": " dilenci"
  },
  {
    "x": "parseki (m)",
    "y": " b. parseke (m)"
  },
  {
    "x": "parsel (n)",
    "y": " parsel"
  },
  {
    "x": "parsel kerdene",
    "y": " parsellemek"
  },
  {
    "x": "parselê awanî (n)",
    "y": " imar parseli"
  },
  {
    "x": "parselê kadastroyî (n)",
    "y": " kadastro parseli"
  },
  {
    "x": "parselê zereyî (n)",
    "y": " içparsel"
  },
  {
    "x": "parselkerdiş (n)",
    "y": " parselleme, parselasyon"
  },
  {
    "x": "parsger, -e",
    "y": " b. parsker, -e"
  },
  {
    "x": "parsincî, -ye",
    "y": " dilenci"
  },
  {
    "x": "parsîye (m)",
    "y": " b. parsû (m)"
  },
  {
    "x": "parskardiş (n)",
    "y": " b. perskerdiş (n)"
  },
  {
    "x": "parsker, -e",
    "y": " dilenci"
  },
  {
    "x": "parsu (m)",
    "y": " b. parsû (m) "
  },
  {
    "x": "parsû (m)",
    "y": " kaburga"
  },
  {
    "x": "parşomen (n)",
    "y": " parşömen"
  },
  {
    "x": "parşu (m)",
    "y": " b. parsû (m)"
  },
  {
    "x": "partav kerdene",
    "y": " abartmak, mübalağa etmek"
  },
  {
    "x": "partavkerdiş (n)",
    "y": " abartı, mübalağa, abartma, mübalağa etme"
  },
  {
    "x": "partî (m)",
    "y": " parti"
  },
  {
    "x": "partîye (m)",
    "y": " parti"
  },
  {
    "x": "paruznayîne",
    "y": " b. parzunayene"
  },
  {
    "x": "paruznayîş (n)",
    "y": " b. parzunayîş (n)"
  },
  {
    "x": "paruznîyayîne",
    "y": " b. parzunîyayene"
  },
  {
    "x": "paruznîyayîş (n)",
    "y": " b. parzunîyayîş (n)"
  },
  {
    "x": "parxaç (m)",
    "y": " b. pelxaçe (m)"
  },
  {
    "x": "parxêle (m)",
    "y": " at arabası, kağnı, öküz arabası"
  },
  {
    "x": "parya (m)",
    "y": " ayaktakımı, lümpen, parya"
  },
  {
    "x": "paryen",
    "y": " b. parên, -e"
  },
  {
    "x": "paryez (n)",
    "y": " b. parêz (n)"
  },
  {
    "x": "parzemîn (n)",
    "y": " kıta, ana kara"
  },
  {
    "x": "parzim (n)",
    "y": " b. parzun (n)"
  },
  {
    "x": "parzim kerdene",
    "y": " b. parzun kerdene"
  },
  {
    "x": "parzimkerdiş (n)",
    "y": " b. parzunkerdiş (n)"
  },
  {
    "x": "parzin (n)",
    "y": " b. parzun (n)"
  },
  {
    "x": "parzo (n)",
    "y": " b. parzun (n)"
  },
  {
    "x": "parzon (n)",
    "y": " b. parzun (n)"
  },
  {
    "x": "parzu (n)",
    "y": " b. parzun (n)"
  },
  {
    "x": "parzum (n)",
    "y": " b. parzun (n)"
  },
  {
    "x": "parzum kerdene",
    "y": " b. parzun kerdene"
  },
  {
    "x": "parzumkerdiş (n)",
    "y": " b. parzunkerdiş (n)"
  },
  {
    "x": "parzun (n)",
    "y": " süzgeç, süzek, filtre"
  },
  {
    "x": "parzun bîyayene",
    "y": " b. parzun bîyene"
  },
  {
    "x": "parzun bîyene",
    "y": " süzülmek (sıvı için)"
  },
  {
    "x": "parzun kerdene",
    "y": " süzmek (sıvı için)"
  },
  {
    "x": "parzun ro dayene",
    "y": " süzmek (sıvı için)"
  },
  {
    "x": "parzun ro ginayene",
    "y": " süzülmek (sıvı için)"
  },
  {
    "x": "parzunê gemarinîya hewayî (n)",
    "y": " hava kirliliği süzgeci"
  },
  {
    "x": "parzunayene",
    "y": " süzmek (sıvı için)"
  },
  {
    "x": "parzunayîş (n)",
    "y": " süzme (sıvı için)"
  },
  {
    "x": "parzunbîyayîş (n)",
    "y": " süzülme (sıvı için)"
  },
  {
    "x": "parzunîyayene",
    "y": " süzülmek (sıvı için)"
  },
  {
    "x": "parzunîyayîş (n)",
    "y": " süzülme (sıvı için)"
  },
  {
    "x": "parzunkerdiş (n)",
    "y": " süzme (sıvı için)"
  },
  {
    "x": "parzunrodayîş (n)",
    "y": " süzme (sıvı için)"
  },
  {
    "x": "parzunroginayîş (n)",
    "y": " süzülme (sıvı için)"
  },
  {
    "x": "parzû (n)",
    "y": " b. parzun (n)"
  },
  {
    "x": "parzû kerdene",
    "y": " b. parzun kerdene"
  },
  {
    "x": "parzûkerdiş (n)",
    "y": " b. parzunkerdiş (n)"
  },
  {
    "x": "parzûn (n)",
    "y": " b. parzun (n)"
  },
  {
    "x": "parzûn kerdene",
    "y": " b. parzun kerdene"
  },
  {
    "x": "parzûnkerdiş (n)",
    "y": " b. parzunkerdiş (n)"
  },
  {
    "x": "pas-I (n)",
    "y": " pas (oyun için)"
  },
  {
    "x": "pas-II (n)",
    "y": " b. bask (n)"
  },
  {
    "x": "pas eştene",
    "y": " b. bask eştene"
  },
  {
    "x": "pasaport (n)",
    "y": " pasaport"
  },
  {
    "x": "pasaporte (m)",
    "y": " b. pasaport (n)"
  },
  {
    "x": "paseport (n)",
    "y": " b. pasaport (n)"
  },
  {
    "x": "paskil (n)",
    "y": " b. paşkul (n)"
  },
  {
    "x": "paskil estene",
    "y": " b. paşkul eştene"
  },
  {
    "x": "paskul (n)",
    "y": " b. paşkul (n)"
  },
  {
    "x": "paskul estene",
    "y": " b. paşkul eştene"
  },
  {
    "x": "paskul eştene",
    "y": " b. paşkul eştene"
  },
  {
    "x": "paskul vistene",
    "y": " b. paşkul eştene"
  },
  {
    "x": "paskule (m)",
    "y": " b. paşkul (n)"
  },
  {
    "x": "paskule estene",
    "y": " b. paşkul eştene"
  },
  {
    "x": "pasna (m)",
    "y": " b. paşna-I (m)"
  },
  {
    "x": "paspas (n)",
    "y": " paspas"
  },
  {
    "x": "pasport (n)",
    "y": " b. pasaport (n)"
  },
  {
    "x": "passîf, -e",
    "y": " edilgen, edilgin, pasif"
  },
  {
    "x": "pasta (m)",
    "y": " pasta\r\n~pastaya huşke: kuru pasta\r\n~pastaya nerme: yaş pasta"
  },
  {
    "x": "pastaya huşke",
    "y": " kuru pasta"
  },
  {
    "x": "pastaya nerme",
    "y": " yaş pasta"
  },
  {
    "x": "pastarotox, -e",
    "y": " pastacı"
  },
  {
    "x": "pastaviraştox, -e",
    "y": " pastacı"
  },
  {
    "x": "pastaxane (n)",
    "y": " pastaxane"
  },
  {
    "x": "pastêx (n)",
    "y": " b. bastêq (n)"
  },
  {
    "x": "pastikan (n)",
    "y": " keş"
  },
  {
    "x": "pastirme (n)",
    "y": " pastırma"
  },
  {
    "x": "pastkan (n)",
    "y": " b. pastikan (n)"
  },
  {
    "x": "Pasûr (n)",
    "y": " Kulp "
  },
  {
    "x": "paşax (n)",
    "y": " b. başax (n)"
  },
  {
    "x": "paşax kerdene",
    "y": " b. başax kerdene"
  },
  {
    "x": "paşaxkerdiş (n)",
    "y": " b. başaxkerdiş (n)"
  },
  {
    "x": "paşaxkerdox, -i",
    "y": " b. başaxkerdox, -e"
  },
  {
    "x": "paşeme (n)",
    "y": " b. panşeme (n)"
  },
  {
    "x": "paşemi (n)",
    "y": " b. panşeme (n)"
  },
  {
    "x": "paşeport (n)",
    "y": " b. pasaport (n)"
  },
  {
    "x": "paşêne (n)",
    "y": " b. şeme (n)"
  },
  {
    "x": "paşime (n)",
    "y": " b. panşeme (n)"
  },
  {
    "x": "paşipuert (n)",
    "y": " b. pasaport (n)"
  },
  {
    "x": "paşîne (n)",
    "y": " b. şeme (n)"
  },
  {
    "x": "paşîv (n)",
    "y": " b. paşîve (m)"
  },
  {
    "x": "paşîve (m)",
    "y": " 1)gece yemeği (gece geç vakit yenen yemek)\r\n~2)sahur"
  },
  {
    "x": "paşkil (n)",
    "y": " b. paşkul (n)"
  },
  {
    "x": "paşkil eştene",
    "y": " b. paşkul eştene"
  },
  {
    "x": "paşkil vistene",
    "y": " b. paşkul eştene"
  },
  {
    "x": "paşkul (n)",
    "y": " 1)(insan için) tekme, tepik\r\n~2)çifte (at, eşek vb. tekmesi)"
  },
  {
    "x": "paşkul eştene",
    "y": " 1)tekme atmak\r\n~2)çifte atmak"
  },
  {
    "x": "paşkule (m)",
    "y": " b. paşkul (n)"
  },
  {
    "x": "paşkule eştene",
    "y": " b. paşkul eştene"
  },
  {
    "x": "paşme (n)",
    "y": " b. panşeme (n)"
  },
  {
    "x": "paşna-I (m)",
    "y": " topuk "
  },
  {
    "x": "paşnaya solî/postalî (m)",
    "y": " ökçe, ayakkabı topuğu"
  },
  {
    "x": "paşnê solî (m)",
    "y": " b. paşnaya solî (m)"
  },
  {
    "x": "paşna-II (m)",
    "y": " b. balişna (m)"
  },
  {
    "x": "paşneki (m)",
    "y": " b. paşna-I (m) "
  },
  {
    "x": "paşnike (m)",
    "y": " b. paşna-I (m)"
  },
  {
    "x": "paşo (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "paşport (n)",
    "y": " b. pasaport (n)"
  },
  {
    "x": "paşt (m)",
    "y": " b. piştî (m) "
  },
  {
    "x": "paştdayîş (n)",
    "y": " b. piştîdayîş (n)"
  },
  {
    "x": "paşte-I (n)",
    "y": " insan sırtında taşınan yük"
  },
  {
    "x": "paşte-II (m)",
    "y": " b. piştî (m)"
  },
  {
    "x": "paştî (m)",
    "y": " b. piştî (m)"
  },
  {
    "x": "paştî bi ... bestene",
    "y": " b. piştî bi ... bestene"
  },
  {
    "x": "paştî bi ... bestîyayene",
    "y": " b. piştî bi ... bestîyayene"
  },
  {
    "x": "paştî bi ... girey dayene",
    "y": " b. piştî bi ... girê dayene"
  },
  {
    "x": "paştî bi ... girey dîyayene",
    "y": " b. piştî bi ... girê dîyayene"
  },
  {
    "x": "paştî dayene",
    "y": " b. piştî dayene"
  },
  {
    "x": "paştî germ kerdene",
    "y": " b. piştî germ kerdene"
  },
  {
    "x": "paştîya xwi dayene",
    "y": " b. piştîya xo dayene"
  },
  {
    "x": "paştîya xwi dayîş (n)",
    "y": " b. piştîya xo dayîş (n)"
  },
  {
    "x": "paştîdayîş (n)",
    "y": " b. piştîdayîş (n)"
  },
  {
    "x": "paştîgermkerdiş (n)",
    "y": " b. piştîgermkerdiş (n)"
  },
  {
    "x": "paştmaz (n)",
    "y": " bonfile"
  },
  {
    "x": "pat, -e",
    "y": " b. paht, -e"
  },
  {
    "x": "pata (m)",
    "y": " berabere kalma (oyun için), pata (oyun için)"
  },
  {
    "x": "patati (m)",
    "y": " b. patatîze (m)"
  },
  {
    "x": "patatîsi (m)",
    "y": " b. patatîze (m)"
  },
  {
    "x": "patatîze (m)",
    "y": " patates"
  },
  {
    "x": "patatîzi (m)",
    "y": " b. patatîze (m)"
  },
  {
    "x": "patene",
    "y": " kabuğunu soymak"
  },
  {
    "x": "patey (m)",
    "y": " b. pahtîye (m)"
  },
  {
    "x": "patik-I (n)",
    "y": " kepçe"
  },
  {
    "x": "patik-II (n)",
    "y": " 1)patik (küçük çocuk ayakkabısı)\r\n~2)patik (ev içinde terlik gibi giyilen kısa konçlu çorap)"
  },
  {
    "x": "patike (m)",
    "y": " mantar (bitkisi)"
  },
  {
    "x": "patiki (m)",
    "y": " b. patike (m)"
  },
  {
    "x": "patikin, -e",
    "y": " b. pahtikin, -e"
  },
  {
    "x": "patikiney (m)",
    "y": " b. pahtikinîye (m)"
  },
  {
    "x": "patire (m)",
    "y": " b. patîra (m)"
  },
  {
    "x": "patîl (m)",
    "y": " b. patîle-I (m)"
  },
  {
    "x": "patîla (m)",
    "y": " b. patîra (m)"
  },
  {
    "x": "patîla dekerda (m)",
    "y": " b. patîraya dekerda (m)"
  },
  {
    "x": "patîlay şekerî",
    "y": " b. patîraya şekerî"
  },
  {
    "x": "patîle-I (m)",
    "y": " burma (burulup kurutulan ot demeti)"
  },
  {
    "x": "patîle-II (n)",
    "y": " b. patîre (n)"
  },
  {
    "x": "patîli (n)",
    "y": " b. patîre (n)"
  },
  {
    "x": "patîra (m)",
    "y": " gözleme, bazlama (bir hamur işi)"
  },
  {
    "x": "patîra firni (m)",
    "y": " b. patîraya firine (m)"
  },
  {
    "x": "patîra pîyanzî",
    "y": " b. patîraya pîyazî"
  },
  {
    "x": "patîra şekerî",
    "y": " b. patîraya şekerî"
  },
  {
    "x": "patîra toraqî",
    "y": " b. patîraya toraqî"
  },
  {
    "x": "patîray firni (m)",
    "y": " b. patîraya firine (m)"
  },
  {
    "x": "patîray pîyonzî",
    "y": " b. patîraya pîyazî"
  },
  {
    "x": "patîray pîyûnzî",
    "y": " b. patîraya pîyazî"
  },
  {
    "x": "patîray şekerî",
    "y": " b. patîraya şekerî"
  },
  {
    "x": "patîray toraqî",
    "y": " b. patîraya toraqî"
  },
  {
    "x": "patîraya dekerda (m)",
    "y": " gözleme, bazlama (bir hamur işi)"
  },
  {
    "x": "patîraya firine (m)",
    "y": " bir çeşit börek"
  },
  {
    "x": "patîraya pîyazî",
    "y": " bir çeşit gözleme"
  },
  {
    "x": "patîraya şekerî",
    "y": " bir çeşit gözleme"
  },
  {
    "x": "patîraya toraqî",
    "y": " bir çeşit gözleme"
  },
  {
    "x": "patîre (n)",
    "y": " lavaş, bazlama, mayasız ekmek"
  },
  {
    "x": "patîska (m)",
    "y": " b. patîsqa (m)"
  },
  {
    "x": "patîsqa (m)",
    "y": " patiska, patis"
  },
  {
    "x": "patîsqa (n)",
    "y": " b. patîsqa (m)"
  },
  {
    "x": "patolog, -e",
    "y": " patolog"
  },
  {
    "x": "patolojî (n)",
    "y": " hastalıkbilim, patoloji, sayrılıkbilim"
  },
  {
    "x": "patolojîk, -e",
    "y": " patolojik"
  },
  {
    "x": "patos (n)",
    "y": " b. patose (m)"
  },
  {
    "x": "patose (m)",
    "y": " harman makinesi, batöz"
  },
  {
    "x": "patosi (m)",
    "y": " b. patose (m)"
  },
  {
    "x": "patoz (m)",
    "y": " b. patose (m)"
  },
  {
    "x": "patoze (m)",
    "y": " b. patose (m)"
  },
  {
    "x": "patrîarkal, -e",
    "y": " ataerkil"
  },
  {
    "x": "patrîarkalîye (m)",
    "y": " ataerkillik"
  },
  {
    "x": "patron, -e",
    "y": " işveren, patron"
  },
  {
    "x": "patu (n)",
    "y": " kabuk"
  },
  {
    "x": "pave, -ye-I",
    "y": " b. pabe, -ye"
  },
  {
    "x": "paveyê ... bîyene",
    "y": " b. pabeyê ... bîyene"
  },
  {
    "x": "paveyê ... vindertene",
    "y": " b. pabeyê ... vindetene"
  },
  {
    "x": "pavê ... vinderene",
    "y": " b. pabeyê ... vindetene"
  },
  {
    "x": "pave-II (n)",
    "y": " b. pabok (n)"
  },
  {
    "x": "pavistene",
    "y": " 1)sertçe vurmak\r\n~2)b. pafîştene"
  },
  {
    "x": "pavistiş (n)",
    "y": " 1)sertçe vurma\r\n~2)b. pafîştiş (n)"
  },
  {
    "x": "pawe, -ye-I",
    "y": " b. pabe, -ye"
  },
  {
    "x": "pawey ... vindertene",
    "y": " b. pabeyê ... vindetene"
  },
  {
    "x": "paweyê ... bîyene",
    "y": " b. pabeyê ... bîyene"
  },
  {
    "x": "paweyê ... vindertene",
    "y": " b. pabeyê ... vindetene"
  },
  {
    "x": "pawê ... vinderene",
    "y": " b. pabeyê ... vindetene"
  },
  {
    "x": "pawêbî ... vindertene",
    "y": " b. pabeyê ... vindetene"
  },
  {
    "x": "pawe-II (n)",
    "y": " b. pabok (n)"
  },
  {
    "x": "pawer (n)",
    "y": " b. pêwer (n)"
  },
  {
    "x": "pawibê ... bîyene",
    "y": " b. pabeyê ... bîyene"
  },
  {
    "x": "pawibê ... vindartene",
    "y": " b. pabeyê ... vindetene"
  },
  {
    "x": "pawibê ... vindertene",
    "y": " b. pabeyê ... vindetene"
  },
  {
    "x": "pawidê ... vindertene",
    "y": " b. pabeyê ... vindetene"
  },
  {
    "x": "pawig, -e-I",
    "y": " b. pabe, -ye"
  },
  {
    "x": "pawigê ... bîyene",
    "y": " b. bavokê ... bîyene"
  },
  {
    "x": "pawig-II (n)",
    "y": " b. pabok (n)"
  },
  {
    "x": "pawitene",
    "y": " 1)korumak, muhafaza etmek \r\n~2)savunmak, müdafaa etmek \r\n~3)beklemek"
  },
  {
    "x": "pawitiş (n)",
    "y": " 1)bekleme, bekleyiş \r\n~2)koruma, muhafaza \r\n~3)gözetim \r\n~4)savunma, müdafaa"
  },
  {
    "x": "pawitişê tezî (n)",
    "y": " tez savunması"
  },
  {
    "x": "pawitişê weşî (n)",
    "y": " hijyen"
  },
  {
    "x": "pawitişo meşrû (n)",
    "y": " meşru savunma, meşru müdafaa"
  },
  {
    "x": "pawitişo sîvîl (n)",
    "y": " sivil savunma"
  },
  {
    "x": "pawitox, -e",
    "y": " 1)bekçi\r\n~2)savunucu, muhafız, koruyucu; müdafi (huq.)\r\n~pawitoxa/pawitoxê qeraxî: sahil koruma"
  },
  {
    "x": "pawitoxa/pawitoxê qeraxî",
    "y": " sahil koruma"
  },
  {
    "x": "pawituex",
    "y": " b.  pawitox, -e"
  },
  {
    "x": "pawîyayene",
    "y": " korunmak"
  },
  {
    "x": "pawîyayîş (n)",
    "y": " korunma"
  },
  {
    "x": "pawîyayîşê herre (n)",
    "y": " toprak korunması"
  },
  {
    "x": "pawîyayîşê weşîya şarî (n)",
    "y": " halk sağlığının korunması"
  },
  {
    "x": "pawo pawo",
    "y": " sığırın yürümesi için veya kovarken söylenen bir söz (öküz için)"
  },
  {
    "x": "pawok, -e-I",
    "y": " b. pabe, -ye"
  },
  {
    "x": "pawok-II (n)",
    "y": " b. pabok (n)"
  },
  {
    "x": "pawutene",
    "y": " b. pawitene"
  },
  {
    "x": "pawutiş (n)",
    "y": " b. pawitiş (n)"
  },
  {
    "x": "pawutox, -e",
    "y": " b. pawitox, -e"
  },
  {
    "x": "paxil, -e",
    "y": " b. pexîl, -e"
  },
  {
    "x": "paxilî (m)",
    "y": " b. pexîlî (m), pexîlîye (m)"
  },
  {
    "x": "paxilî kerdene",
    "y": " b. pexîlî kerdene"
  },
  {
    "x": "paxir (n)",
    "y": " bakır"
  },
  {
    "x": "paxnoti (m)",
    "y": " b. pangnote (m)"
  },
  {
    "x": "pay (n)",
    "y": " çifte (at, eşek vb. tekmesi)"
  },
  {
    "x": "pay eştene",
    "y": " çifte atmak"
  },
  {
    "x": "pay bi xo",
    "y": " yürüyerek"
  },
  {
    "x": "pay pirodayene",
    "y": " baltalamak (mecazi)"
  },
  {
    "x": "pay ra",
    "y": " ayakta"
  },
  {
    "x": "pay ra tedawî",
    "y": " ayakta tedavi"
  },
  {
    "x": "paybîyayîş (n)",
    "y": " giyilme (ayakkabı, pantolon vb. için)"
  },
  {
    "x": "paydayîş (n)",
    "y": " giydirme (ayakkabı, pantolon vb. için)"
  },
  {
    "x": "paydîyayîş (n)",
    "y": " giydirilme (ayakkabı, pantolon vb. için)"
  },
  {
    "x": "paye (n)",
    "y": " 1)afi, caka, fiyaka, çalım, gösteriş\r\n~2)kibir, büyüklenme"
  },
  {
    "x": "paye kerdene",
    "y": " afi kesmek, afi satmak, afi yapmak, caka satmak, caka yapmak, fiyaka satmak, çalım satmak, gösteriş yapmak"
  },
  {
    "x": "paye kewtene",
    "y": " şımarmak"
  },
  {
    "x": "paye vistene",
    "y": " şımartmak"
  },
  {
    "x": "paye (n)",
    "y": " b. peye-III (n)"
  },
  {
    "x": "paye bîyene",
    "y": " b. peye bîyene"
  },
  {
    "x": "payedar, -e",
    "y": " kibirli, büyüklenen, gösterişli, afili"
  },
  {
    "x": "payedar bîyene",
    "y": " kibirlenmek"
  },
  {
    "x": "payedarey (m)",
    "y": " b. payedarî (m), payedarîye"
  },
  {
    "x": "payedari (m)",
    "y": " b. payedare (m)"
  },
  {
    "x": "payedarî (m)",
    "y": " kibirlilik"
  },
  {
    "x": "payedarîye (m)",
    "y": " kibirlilik"
  },
  {
    "x": "payeker, -e",
    "y": " kibirli, büyüklenen, gösteriş yapan, gösterişe meraklı kişi"
  },
  {
    "x": "payeker bîyene",
    "y": " kibirlenmek"
  },
  {
    "x": "payekerey (m)",
    "y": " b. payekerî (m), payekerîye (m)"
  },
  {
    "x": "payekeri (m)",
    "y": " b. payeker, -e"
  },
  {
    "x": "payekerî (m)",
    "y": " kibirlilik"
  },
  {
    "x": "payekerîye (m)",
    "y": " kibirlilik"
  },
  {
    "x": "payepîl, -e",
    "y": " kibirli, büyüklenen"
  },
  {
    "x": "payepîl bîyene",
    "y": " kibirlenmek"
  },
  {
    "x": "payepîley (m)",
    "y": " b. payepîlî (m), payepîlîye (m)"
  },
  {
    "x": "payepîlê (m)",
    "y": " b. payepîlî (m), payepîlîye (m)"
  },
  {
    "x": "payepîlî (m)",
    "y": " kibirlilik"
  },
  {
    "x": "payepîlîye (m)",
    "y": " kibirlilik"
  },
  {
    "x": "paygiran, -e",
    "y": " ağırcanlı"
  },
  {
    "x": "paygiranê (m)",
    "y": " b. paygiranîye (m)"
  },
  {
    "x": "paygiranîye (m)",
    "y": " ağırcanlılık, ağırkanlılık"
  },
  {
    "x": "paygiron, -i",
    "y": " b. paygiran, -e"
  },
  {
    "x": "paygironey (m)",
    "y": " b. paygiranîye (m)"
  },
  {
    "x": "paygirûn, -i",
    "y": " b. paygiran, -e"
  },
  {
    "x": "paygirûnî (m)",
    "y": " b. paygiranîye (m) "
  },
  {
    "x": "payiz (n)",
    "y": " b. payîz (n)"
  },
  {
    "x": "payiz pêyîn (n)",
    "y": " b. payîzo peyên (n)"
  },
  {
    "x": "payîjî",
    "y": " b. payîzî"
  },
  {
    "x": "payîz (n)",
    "y": " sonbahar"
  },
  {
    "x": "payîzo peyên (n)",
    "y": " sonbaharın sonları, songüz "
  },
  {
    "x": "payîzo peyin (n)",
    "y": " b. payîzo peyên (n)"
  },
  {
    "x": "payîzo verên (n)",
    "y": " ilkgüz, sonbaharın başları"
  },
  {
    "x": "payîzo verîn (n)",
    "y": " b. payîzo verên (n)"
  },
  {
    "x": "payîzo veryen (n)",
    "y": " b. payîzo verên (n)"
  },
  {
    "x": "payîza (m)",
    "y": " güzün doğan oğlak, kuzu vb. hayvan"
  },
  {
    "x": "payîze (n)",
    "y": " 1)güzün doğan oğlak, kuzu vb. hayvan\r\n~2)sonbaharda sürülen çift"
  },
  {
    "x": "payîzê-I",
    "y": " b. payîzî"
  },
  {
    "x": "payîzê-II (m)",
    "y": " b. payîzîye (m)"
  },
  {
    "x": "payîzî",
    "y": " güzün, sonbahar mevsiminde"
  },
  {
    "x": "payîzîye (m)",
    "y": " güzün doğan oğlak, kuzu vb. hayvan"
  },
  {
    "x": "paykerdiş (n)",
    "y": " giyme (ayakkabı, pantolon vb. için)"
  },
  {
    "x": "payna (m)",
    "y": " topuk "
  },
  {
    "x": "paynaya solî/postalî (m)",
    "y": " ökçe, ayakkabı topuğu"
  },
  {
    "x": "paynek (m)",
    "y": " b. payna (m)"
  },
  {
    "x": "paynik (m)",
    "y": " b. paynike (m)"
  },
  {
    "x": "paynikê mêrdîyûn (m)",
    "y": " b. paynika nêrdîwane (m)"
  },
  {
    "x": "paynike (m)",
    "y": " basamak"
  },
  {
    "x": "paynika nêrdîwane (m)",
    "y": " merdiven basamağı"
  },
  {
    "x": "paypirodayîş (n)",
    "y": " 1)bozma (mecazi), sabote \r\n~2)sabote etme (mecazi), baltalama (mecazi) \r\n~3)sabotaj"
  },
  {
    "x": "paypirodîyaya/e",
    "y": " bozuk¸ bozulmuş olan"
  },
  {
    "x": "paypirodîyayîş (n)",
    "y": " bozulma"
  },
  {
    "x": "payşayîş (n)",
    "y": " b. payşîyayîş (n)"
  },
  {
    "x": "payşeyîş (n)",
    "y": " b. payşîyayîş (n)"
  },
  {
    "x": "payşîyayîş (n)",
    "y": " yürüme"
  },
  {
    "x": "payşna (m)",
    "y": " b. paşna-I (m)"
  },
  {
    "x": "payşt (m)",
    "y": " b. piştî (m)"
  },
  {
    "x": "payştî (m)",
    "y": " b. piştî (m)"
  },
  {
    "x": "payştî bi ... bestene",
    "y": " b. piştî bi ... bestene"
  },
  {
    "x": "payştî bi ... bestîyayene",
    "y": " b. piştî bi ... bestîyayene"
  },
  {
    "x": "payştî bi ... girey dayene",
    "y": " b. piştî bi ... girê dayene"
  },
  {
    "x": "payştî bi ... girey dîyayene",
    "y": " b. piştî bi ... girê dîyayene"
  },
  {
    "x": "paytext (n)",
    "y": " başkent"
  },
  {
    "x": "pazeleqîyayene",
    "y": " b. pazeliqîyayene"
  },
  {
    "x": "pazeleqîyayîş (n)",
    "y": " b. pazeliqîyayîş (n)"
  },
  {
    "x": "pazeleqnayene",
    "y": " b. pazeliqnayene"
  },
  {
    "x": "pazeleqnayîş (n)",
    "y": " b. pazeliqnayîş (n)"
  },
  {
    "x": "pazeliqîyayene",
    "y": " -e yapışmak"
  },
  {
    "x": "pazeliqîyayîş (n)",
    "y": " -e yapışma"
  },
  {
    "x": "pazeliqnayene",
    "y": " -e yapıştırmak"
  },
  {
    "x": "pazeliqnayîş (n)",
    "y": " -e yapıştırma"
  },
  {
    "x": "pazên (n)",
    "y": " pazen"
  },
  {
    "x": "pazin (n)",
    "y": " b. pazên (n)"
  },
  {
    "x": "PÇÎ (Plankerdişê Çimeyanê Îmalatî)",
    "y": " MRP (Manufacturing Resource Planning)"
  },
  {
    "x": "pedagog, -e",
    "y": " eğitbilimci, eğitimci, pedagog"
  },
  {
    "x": "pedagojî (n)",
    "y": " eğitbilim, pedagoji"
  },
  {
    "x": "pedagojîk, -e",
    "y": " eğitbilimsel, eğitsel, pedagojik"
  },
  {
    "x": "pe-I",
    "y": " b. bi, pê "
  },
  {
    "x": "pe-II (nm, m)",
    "y": " b. pey-I (nm, m)"
  },
  {
    "x": "pede (n)",
    "y": " ekmek doğranmış ayran veya yoğurt"
  },
  {
    "x": "pede-pede",
    "y": " giderek, gittikçe, yavaş yavaş"
  },
  {
    "x": "pede gêrîyayene",
    "y": " b. pede gîrîyayene"
  },
  {
    "x": "pede ginayene",
    "y": " b. bide ginayene"
  },
  {
    "x": "pede gîrîyayene",
    "y": " tutulmak (el, ayak vb. için)"
  },
  {
    "x": "pede kerdene",
    "y": " 1)b.  ... de kerdene\r\n~2)içine doğramak (ekmek vb. için)"
  },
  {
    "x": "pede şîyaene",
    "y": " b. pede şîyene"
  },
  {
    "x": "pede şîyayene",
    "y": " b. ... de şîyene"
  },
  {
    "x": "pede şîyene",
    "y": " 1)sızdırmak\r\n~2)b. ... de şîyene"
  },
  {
    "x": "pede vajîyayene",
    "y": " azarlamak, paylamak, tekdir etmek"
  },
  {
    "x": "pedeginayîş (n)",
    "y": " b. bideginayîş (n)"
  },
  {
    "x": "pedekerde (n)",
    "y": " ekmek doğranmış ayran veya yoğurt"
  },
  {
    "x": "pedekerdiş (n)",
    "y": " 1)b. ... Dekerdiş\r\n~2)içine doğrama (ekmek vb. için)"
  },
  {
    "x": "pederşahî, -ye",
    "y": " ataerkil"
  },
  {
    "x": "pederşahîye (m)",
    "y": " ataerkillik"
  },
  {
    "x": "pedeşîyaîs (n)",
    "y": " b. pedeşîyayîş (n)"
  },
  {
    "x": "pedeşîyayîş (n)",
    "y": " sızdırma (bez, kumaş vb. için)"
  },
  {
    "x": "pedeşîyayîş (n)",
    "y": " b. ... deşîyayîş (n)"
  },
  {
    "x": "pedevajîyayîş (n)",
    "y": " azarlama, melamet, paylama, tekdir etme"
  },
  {
    "x": "pedi (n)",
    "y": " b. pede (n)"
  },
  {
    "x": "pedi kerdene",
    "y": " b. pede kerdene"
  },
  {
    "x": "pedikerdi (n)",
    "y": " b. pedekerde (n)"
  },
  {
    "x": "pedikerdiş (n)",
    "y": " b. pedekerdiş (n)"
  },
  {
    "x": "pedişîyayîş (n)",
    "y": " b. ... deşîyayîş (n)"
  },
  {
    "x": "pedîkur (n)",
    "y": " pedikür"
  },
  {
    "x": "pedkerde (n)",
    "y": " b. pedekerde (n)"
  },
  {
    "x": "peen, -e",
    "y": " b. peyên, -e"
  },
  {
    "x": "peg (m)",
    "y": " b. page (m)"
  },
  {
    "x": "pehin",
    "y": " b. pan, -e-I"
  },
  {
    "x": "pehinê (m)",
    "y": " b. panîye (m)"
  },
  {
    "x": "pehn, -i",
    "y": " b. pan, -e-I"
  },
  {
    "x": "pehney (m)",
    "y": " b. panîye (m)"
  },
  {
    "x": "pehnê (m)",
    "y": " b. panîye (m)"
  },
  {
    "x": "peht, -e",
    "y": " b. paht, -e"
  },
  {
    "x": "pehtey (m)",
    "y": " b. pahtîye (m)"
  },
  {
    "x": "pehtikin, -e",
    "y": " b. pahtikin, -e"
  },
  {
    "x": "pehtikinî (m)",
    "y": " b. pahtikinîye (m)"
  },
  {
    "x": "pehtîye (m)",
    "y": " b. pahtîye (m)"
  },
  {
    "x": "pej kerdene",
    "y": " budamak (ağaç için)"
  },
  {
    "x": "peje (m)",
    "y": " çırpı (kuru dal parçası)"
  },
  {
    "x": "pejike (m)",
    "y": " çırpı (kuru dal parçası)"
  },
  {
    "x": "pejkerdiş (n)",
    "y": " budama (ağaç için)"
  },
  {
    "x": "Pekîn (n)",
    "y": " Pekin"
  },
  {
    "x": "pel-I (n)",
    "y": " 1)yaprak (bitki için) \r\n~2)yaprak (defter vb. için) \r\n~3)sarma"
  },
  {
    "x": "pel akerdene",
    "y": " yapraklanmak"
  },
  {
    "x": "pel dayene",
    "y": " yapraklanmak"
  },
  {
    "x": "pel rakerdene",
    "y": " yapraklanmak"
  },
  {
    "x": "pelê dekerdey (zh)",
    "y": " b. pelê dekerdeyî (zh)"
  },
  {
    "x": "pelê dekerdeyî (zh)",
    "y": " sarma"
  },
  {
    "x": "pelê mewe (n)",
    "y": " b. pelê mêwe (n)"
  },
  {
    "x": "pelê meyvi (m)",
    "y": " b. pelê mêwe (n)"
  },
  {
    "x": "pelê mêwe (n)",
    "y": " asma yaprağı"
  },
  {
    "x": "pelê rezî (n)",
    "y": " asma yaprağı"
  },
  {
    "x": "pelî rêz (n)",
    "y": " b. pelê rezî (n)"
  },
  {
    "x": "pel-II (n)",
    "y": " 1)kadınların süs için boyunlarına veya başlıklarına taktıkları altın ve gümüş dışındaki madeni para\r\n~2)pul (kadın başörtüsü veya giysilerinde süs olarak kullanılan)"
  },
  {
    "x": "pel-III (n)",
    "y": " b. perr (n)-1"
  },
  {
    "x": "pel,-e-IV",
    "y": " kurnaz"
  },
  {
    "x": "pel-V (n)",
    "y": " b. pil-I (n)"
  },
  {
    "x": "pel û pot",
    "y": " eski püskü"
  },
  {
    "x": "pel û potî (zh)",
    "y": " eski püskü giysi(ler)"
  },
  {
    "x": "pel-port (n)",
    "y": " b. pil-purt (n)"
  },
  {
    "x": "pel-purt (n)",
    "y": " b. pil-purt (n)"
  },
  {
    "x": "pelas (n)",
    "y": " b. palas (n) "
  },
  {
    "x": "pelase (m)",
    "y": " b. palas (n) "
  },
  {
    "x": "pelax (n)",
    "y": " b. palaxe (m)"
  },
  {
    "x": "pelaxi (m)",
    "y": " b. palaxe (m)"
  },
  {
    "x": "pelaxnayêne",
    "y": " b. pelixnayene"
  },
  {
    "x": "pelaxûpuş (n)",
    "y": " b. puşûpalaxe (m)"
  },
  {
    "x": "pelçiqîyayene",
    "y": " ezilmek"
  },
  {
    "x": "pelçiqnayene",
    "y": " ezmek"
  },
  {
    "x": "pelçiqnayîş (n)",
    "y": " ezme"
  },
  {
    "x": "pelçixîyayene",
    "y": " b. pelçiqîyayene"
  },
  {
    "x": "pelçixnayene",
    "y": " b. pelçiqnayene"
  },
  {
    "x": "pelçixnayîş (n)",
    "y": " b. pelçiqnayîş (n)"
  },
  {
    "x": "pelçîn (n)",
    "y": " b. perçîn (n)"
  },
  {
    "x": "pele-I (m)",
    "y": " tarla sürerken karasabanın giremediği/girmediği ve dolayısıyla sürülemeyen, ancak kazma ile kazmak veya bellemek suretiyle ekin ekilebilen engebeli yer"
  },
  {
    "x": "pele ... mendiş",
    "y": " tarla sürerken karasabanın giremediği/girmediği engebeli yerin sürülemeden kalması"
  },
  {
    "x": "pele-II (m)",
    "y": " 1)b. perr (n)\r\n~2)b. pel-I (n)"
  },
  {
    "x": "pela mewze (m)",
    "y": " b. pelê mêwe (n)"
  },
  {
    "x": "Pele-III (m)",
    "y": " inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "pelekendiş (n)",
    "y": " tarla sürerken karasabanın giremediği/girmediği ve dolayısıyla sürülemeyen, ancak kazma ile kazmak veya bellemek suretiyle ekin ekilebilen engebeli yeri kazıp/belleyip ekme"
  },
  {
    "x": "pelel (n)",
    "y": " b. pilar (n)"
  },
  {
    "x": "peleng, -e-I",
    "y": " b. piling, -e-I"
  },
  {
    "x": "pelerîn (n)",
    "y": " pelerin, harmani, harmaniye"
  },
  {
    "x": "pelesqa (m)",
    "y": " b. palasqa (m)"
  },
  {
    "x": "peleverdayîş (n)",
    "y": " tarla sürerken karasabanın giremediği/girmediği sürülememiş yer bırakma"
  },
  {
    "x": "pelexnayene",
    "y": " b. pelixnayene"
  },
  {
    "x": "pelexnayîne",
    "y": " b. Pelixnayene"
  },
  {
    "x": "pelexnayîş (n) ",
    "y": " b. pelixnayîş (n)"
  },
  {
    "x": "peleze (n)",
    "y": " b. perazî (m)"
  },
  {
    "x": "pelezîyayene",
    "y": " b. pilozîyayene"
  },
  {
    "x": "peleznayene",
    "y": " b. piloznayene"
  },
  {
    "x": "peleznayîne",
    "y": " b. piloznayene"
  },
  {
    "x": "peleznayîş (n)",
    "y": " piloznayîş (n)"
  },
  {
    "x": "pelezone (n)",
    "y": " b. perazî (m)"
  },
  {
    "x": "pelêr (n)",
    "y": " b. pilar (n) "
  },
  {
    "x": "pelg (n)",
    "y": " b. pel-I (n)"
  },
  {
    "x": "pelge (m)",
    "y": " b. pel-I (n), velg-I (n)"
  },
  {
    "x": "pelhel (n)",
    "y": " b. pilole (m)"
  },
  {
    "x": "pelik (n)",
    "y": " 1)kadınların süs için boyunlarına veya başlıklarına taktıkları altın ve gümüş dışındaki madeni para \r\n~2)pul (kadın başörtüsü veya giysilerinde süs olarak kullanılan) \r\n~3)b. peltik (n)"
  },
  {
    "x": "pelike (m)",
    "y": " b. pêlek (n)"
  },
  {
    "x": "pelin, -e",
    "y": " yapraklı"
  },
  {
    "x": "pelini (m)",
    "y": " b. peline (m)"
  },
  {
    "x": "peliqnayene",
    "y": " b. pelixnayene"
  },
  {
    "x": "peliqnayîş (n)",
    "y": " b. pelixnayîş (n)"
  },
  {
    "x": "pelixîyayene",
    "y": " ezilmek"
  },
  {
    "x": "pelixnayene",
    "y": " ezmek (üzüm vb. için)"
  },
  {
    "x": "pelixnayîş (n)",
    "y": " ezme"
  },
  {
    "x": "pelîkane (m)",
    "y": " pelikan"
  },
  {
    "x": "pelmase (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "pelmasi (n)",
    "y": " b. pelmase (n)"
  },
  {
    "x": "pelpelik (n)",
    "y": " b. pelik (n)"
  },
  {
    "x": "pelpelike (m)",
    "y": " kelebek"
  },
  {
    "x": "pelqike (m)",
    "y": " kabarcık (tıpta)"
  },
  {
    "x": "pelqike bîyene",
    "y": " kabarcık oluşmak (tıpta), şıklak olmak"
  },
  {
    "x": "pelqike kerdene",
    "y": " kabarcık oluşturmak (tıpta)"
  },
  {
    "x": "peltik (n)",
    "y": " siperlik (şapka için), siper (şapka için)"
  },
  {
    "x": "peltikê şewqa (n)",
    "y": " şapka siperi, şapka siperliği"
  },
  {
    "x": "peluş (n)",
    "y": " pelüş"
  },
  {
    "x": "pelxaçe (m)",
    "y": " kömbe (küle gömülerek pişirilen mayasız çörek)"
  },
  {
    "x": "pelxaça dekerda (m)",
    "y": " bir çeşit kömbe"
  },
  {
    "x": "pelxaçêl bîyene",
    "y": " ezilmek"
  },
  {
    "x": "pelxaçêl kerdene",
    "y": " ezmek"
  },
  {
    "x": "pelxaçêlkardiş (n)",
    "y": " b. pelxaçêlkerdiş (n)"
  },
  {
    "x": "pelxaçêlkerdiş (n)",
    "y": " ezme"
  },
  {
    "x": "pelxaçi (m)",
    "y": " b. pelxaçe (m) "
  },
  {
    "x": "pelxeçîyayene",
    "y": " b. pelçiqîyayene"
  },
  {
    "x": "pelxeçnayene",
    "y": " b. pelçiqnayene"
  },
  {
    "x": "pelxeçnayîş (n)",
    "y": " b. pelçiqnayîş (n)"
  },
  {
    "x": "pelxiçîyayene",
    "y": " b. pelçiqîyayene"
  },
  {
    "x": "pelxiçnayene",
    "y": " b. pelçiqnayene"
  },
  {
    "x": "pelxiçnayîş (n)",
    "y": " b. pelçiqnayîş (n)"
  },
  {
    "x": "peme (n)",
    "y": " pamuk"
  },
  {
    "x": "pemeyên, -e-I",
    "y": " pamuklu, pamuktan yapılma"
  },
  {
    "x": "pemeyên-II (n)",
    "y": " pamuklu (pamuk ipliğiyle dokunmuş kumaş)"
  },
  {
    "x": "pemeyin, -e",
    "y": " pamuklu"
  },
  {
    "x": "pemeyîn, -e",
    "y": " 1)b. pemeyin, -e\r\n~2)pemeyên, -e-I"
  },
  {
    "x": "pemi (n)",
    "y": " b. peme (n)"
  },
  {
    "x": "penageh (n)",
    "y": " barınak"
  },
  {
    "x": "penaltî (m)",
    "y": " ceza atışı, ceza vuruşu (özellikle futbolda)"
  },
  {
    "x": "penas (m)",
    "y": " b. penese (m)"
  },
  {
    "x": "penc (n)",
    "y": " b. pence-I (n)"
  },
  {
    "x": "penc kerdene",
    "y": " b. pence kerdene "
  },
  {
    "x": "pence-I (n)",
    "y": " 1)pençe (hayvan pençesi)\r\n~2)b. pençe (n)"
  },
  {
    "x": "pence kerdene",
    "y": " 1)pençelemek (hayvan pençesiyle)\r\n~2)b. pençe kerdene"
  },
  {
    "x": "pence piro dayene",
    "y": " pençe vurmak"
  },
  {
    "x": "pence ra ... estene",
    "y": " b. pence ra ... eştene"
  },
  {
    "x": "pence ra ... eştene",
    "y": " pençe atmak"
  },
  {
    "x": "pencî kerdene",
    "y": " pençelemek (hayvan pençesiyle)"
  },
  {
    "x": "pencî piro bîyene",
    "y": " pençelemek"
  },
  {
    "x": "pence-II (n)",
    "y": " kök"
  },
  {
    "x": "pencekerdiş (n)",
    "y": " 1)pençeleme (hayvan pençesiyle)\r\n~2)b. pençekerdiş (n)"
  },
  {
    "x": "pencera (m)",
    "y": " pencere"
  },
  {
    "x": "pencere (n)",
    "y": " b. pencera (m)"
  },
  {
    "x": "penci (n)",
    "y": " b. pence (n)"
  },
  {
    "x": "pencik-I (n)",
    "y": " pençe (hayvan pençesi)"
  },
  {
    "x": "pencik estene",
    "y": " b. pencik eştene"
  },
  {
    "x": "pencik eştene",
    "y": " pençe atmak"
  },
  {
    "x": "pencik kerdene",
    "y": " pençelemek (hayvan pençesiyle)"
  },
  {
    "x": "pencik ra ... estene",
    "y": " b. pencik ra ... eştene"
  },
  {
    "x": "pencik ra ... eştene",
    "y": " pençe atmak"
  },
  {
    "x": "pencik-II (n)",
    "y": " kök"
  },
  {
    "x": "pencikkerdiş (n)",
    "y": " pençeleme (hayvan pençesiyle)"
  },
  {
    "x": "penciknayene",
    "y": " pençelemek (hayvan pençesiyle)"
  },
  {
    "x": "penciknayîş (n)",
    "y": " pençeleme (hayvan pençesiyle)"
  },
  {
    "x": "pencira (m)",
    "y": " b. pencera (m)"
  },
  {
    "x": "pencîkerdiş (n)",
    "y": " pençeleme (hayvan pençesiyle)"
  },
  {
    "x": "pencîpirobîyayîş (n)",
    "y": " pençeleme"
  },
  {
    "x": "penckerdiş (n)",
    "y": " b. pencekerdiş (n)"
  },
  {
    "x": "pencur (n)",
    "y": " pençe (hayvan pençesi)"
  },
  {
    "x": "pencurî kerdene",
    "y": " pençelemek (hayvan pençesiyle)"
  },
  {
    "x": "pencurik (n)",
    "y": " pençe (hayvan pençesi)"
  },
  {
    "x": "pencurik ci estene",
    "y": " b. pencurik ... eştene"
  },
  {
    "x": "pencurik estene",
    "y": " b. pencurik eştene"
  },
  {
    "x": "pencurik eştene",
    "y": " pençe atmak"
  },
  {
    "x": "pencurik kerdene",
    "y": " pençelemek (hayvan pençesiyle)"
  },
  {
    "x": "pencurik ... eştene",
    "y": " pençe atmak"
  },
  {
    "x": "pencurikkerdiş (n)",
    "y": " pençeleme (hayvan pençesiyle)"
  },
  {
    "x": "pencurîkerdiş (n)",
    "y": " pençeleme (hayvan pençesiyle)"
  },
  {
    "x": "pencurr (n)",
    "y": " b. pencur (n)"
  },
  {
    "x": "pencurrî kerdene",
    "y": " b. pencurî kerdene"
  },
  {
    "x": "pencurrik (n)",
    "y": " b. pencurik (n) "
  },
  {
    "x": "pencurrik ci eştene",
    "y": " b. pencurik ... eştene"
  },
  {
    "x": "pencurrik eştene",
    "y": " b. pencurik eştene"
  },
  {
    "x": "pencurrik kerdene",
    "y": " b. pencurik kerdene"
  },
  {
    "x": "pencurrikkerdiş (n)",
    "y": " b. pencurikkerdiş (n)"
  },
  {
    "x": "pencurrîkerdiş (n)",
    "y": " b. pencurîkerdiş (n)"
  },
  {
    "x": "pençe (n)",
    "y": " 1)b. pence-I (n)\r\n~2)pençe (ayakkabı pençesi)"
  },
  {
    "x": "pençe kerdene",
    "y": " pençelemek, pençe çakmak, pençe yapmak (ayakkabı için)"
  },
  {
    "x": "pençe ... sanayene",
    "y": " b. pençe ... şanayene"
  },
  {
    "x": "pençe ... sanayîş",
    "y": " b. pençe ... şanayîş"
  },
  {
    "x": "pençe ... şanayene",
    "y": " pençelemek, pençe çakmak, pençe yapmak (ayakkabı için)"
  },
  {
    "x": "pençe ... şanayîş",
    "y": " pençeleme (ayakkabı için)"
  },
  {
    "x": "pençî kerdene",
    "y": " b. pencî kerdene"
  },
  {
    "x": "pençeçî, -ye",
    "y": " ayakkabı tamircisi, ayakkabı onarımcısı, ayakkabıcı (onarımcı), yamacı (ayakkabı onaran)"
  },
  {
    "x": "pençeçîyênî (m)",
    "y": " yamacılık (ayakkabı onarımcılığı)"
  },
  {
    "x": "pençeçîyî (m)",
    "y": " yamacılık (ayakkabı onarımcılığı)"
  },
  {
    "x": "pençekerdiş (n)",
    "y": " pençeleme (ayakkabı için)"
  },
  {
    "x": "pençere (n)",
    "y": " b. pencera (m)"
  },
  {
    "x": "pençeri (n)",
    "y": " b. pencera (m)"
  },
  {
    "x": "pençîkerdiş (n)",
    "y": " b. pencîkerdiş (n)"
  },
  {
    "x": "pendariti (m)",
    "y": " b. penderite (m)"
  },
  {
    "x": "penderite (m)",
    "y": " dövme, ayran ve kızartılmış yağdan yapılan bir yemek"
  },
  {
    "x": "penderiti (m)",
    "y": " b. penderite (m)"
  },
  {
    "x": "penderuti (m)",
    "y": " b. penderite (m)"
  },
  {
    "x": "pendir (n)",
    "y": " b. penîr (n)"
  },
  {
    "x": "pendiriti (m)",
    "y": " b. penderite (m)"
  },
  {
    "x": "pendîr (n)",
    "y": " b. penîr (n)"
  },
  {
    "x": "pendosîyayene",
    "y": " b. pendusayene"
  },
  {
    "x": "pendosyayene",
    "y": " b. pendusayene"
  },
  {
    "x": "penduriti (m)",
    "y": " b. penderite (m)"
  },
  {
    "x": "penduruti (m)",
    "y": " b. penderite (m)"
  },
  {
    "x": "pendurriti (m)",
    "y": " b. penderite (m)"
  },
  {
    "x": "pendusayene",
    "y": " şişmek"
  },
  {
    "x": "pendusnayene",
    "y": " şişirmek"
  },
  {
    "x": "penes (m)",
    "y": " b. penese (m)"
  },
  {
    "x": "penes (n)",
    "y": " b. penese (m)"
  },
  {
    "x": "penese (m)",
    "y": " 1)kadınların süs için boyunlarına veya başlıklarına taktıkları altın ve gümüş dışındaki madeni para \r\n~2)penes \r\n~3)pul (kadın başörtüsü veya giysilerinde süs olarak kullanılan)"
  },
  {
    "x": "penesik (n)",
    "y": " hızma"
  },
  {
    "x": "penezik (n)",
    "y": " b. penesik (n)"
  },
  {
    "x": "penguen, -e",
    "y": " penguen "
  },
  {
    "x": "penîr (n)",
    "y": " peynir"
  },
  {
    "x": "penîrê şewaqan (n)",
    "y": " bir çeşit peynir"
  },
  {
    "x": "penîro helênaye (n)",
    "y": " eritilmiş peynir"
  },
  {
    "x": "penîro helnaye (n)",
    "y": " b. penîro helênaye (n)"
  },
  {
    "x": "penîro munde (n)",
    "y": " b. penîro mûnite (n) "
  },
  {
    "x": "penîro mûnite (n)",
    "y": " bir çeşit peynir"
  },
  {
    "x": "pens (n)",
    "y": " pens "
  },
  {
    "x": "pense (n)",
    "y": " b. penese (m)"
  },
  {
    "x": "pensi (n)",
    "y": " b. penese (m)"
  },
  {
    "x": "penye (n)",
    "y": " penye"
  },
  {
    "x": "penzere (n)",
    "y": " b. pencera (m)"
  },
  {
    "x": "pepaz (n)",
    "y": " b. papaz (n)"
  },
  {
    "x": "pepazê (m)",
    "y": " b. papazî (m)"
  },
  {
    "x": "pepazî (m)",
    "y": " b. papazî (m)"
  },
  {
    "x": "pepik (n)",
    "y": " şerit, tenya, abdestbozan"
  },
  {
    "x": "pepî kerdene",
    "y": " giymek (ayakkabı, pantolon vb. için) (çocuk dilinde)"
  },
  {
    "x": "pepîkerdiş (n)",
    "y": " giyme (ayakkabı, pantolon vb. için) (çocuk dilinde)"
  },
  {
    "x": "peppa (n)",
    "y": " giyme (ayakkabı, pantolon vb. için) (çocuk dilinde)"
  },
  {
    "x": "pepug (n)",
    "y": " b. pepûk, -e"
  },
  {
    "x": "pepuk, -e",
    "y": " b. pepûk, -e"
  },
  {
    "x": "pepukey (m)",
    "y": " b. pepûkîye (m)"
  },
  {
    "x": "pepukê (m)",
    "y": " b. pepûkî (m)"
  },
  {
    "x": "pepuki (m)",
    "y": " b. pepûk, -e"
  },
  {
    "x": "pepukî (m)",
    "y": " b. pepûkî (m)"
  },
  {
    "x": "pepukîye (m)",
    "y": " b. pepûkîye (m)"
  },
  {
    "x": "pepû (n)",
    "y": " b. pepûk, -e"
  },
  {
    "x": "pepûg (n)",
    "y": " b. pepûk, -e"
  },
  {
    "x": "pepûk, -e",
    "y": " 1)guguk kuşu\r\n~2)zavallı"
  },
  {
    "x": "pepûkê (m)",
    "y": " b. pepûkî (m)"
  },
  {
    "x": "pepûkî (m)",
    "y": " zavallılık"
  },
  {
    "x": "pepûkîye (m)",
    "y": " zavallılık"
  },
  {
    "x": "peqlewa (m)",
    "y": " b. baqlawa (m)"
  },
  {
    "x": "peqlewe (n)",
    "y": " b. baqlawa (m)"
  },
  {
    "x": "peqpeqok (n)",
    "y": " çekirge"
  },
  {
    "x": "per (n)",
    "y": " 1)b. perr (n)\r\n~2)b. pel-I (n)"
  },
  {
    "x": "per û bask",
    "y": " b. perr û bask"
  },
  {
    "x": "perê mewe (n)",
    "y": " b. pelê mêwe (n)"
  },
  {
    "x": "perê meybi (n)",
    "y": " b. pelê mêwe (n)"
  },
  {
    "x": "pera dayene",
    "y": " b. pira dayene"
  },
  {
    "x": "pera dîyayene",
    "y": " b. pira dîyayene"
  },
  {
    "x": "pera gîrîyayene",
    "y": " b. pira gîrîyayene"
  },
  {
    "x": "pera guretene",
    "y": " b. pira girewtene"
  },
  {
    "x": "peradayîş (n)",
    "y": " b. piradayîş (n)"
  },
  {
    "x": "peradîyayîş (n)",
    "y": " b. piradîyayîş (n)"
  },
  {
    "x": "peragêrîyayîş (n)",
    "y": " b. piragîrîyayîş (n)"
  },
  {
    "x": "peragîrîyayîş (n)",
    "y": " b. piragîrîyayîş (n)"
  },
  {
    "x": "peraguretiş (n)",
    "y": " b. piragirewtiş (n)"
  },
  {
    "x": "perakende",
    "y": " perakende (ticaret için)"
  },
  {
    "x": "perarey (n)",
    "y": " bugünden önceki üçüncü gün"
  },
  {
    "x": "perarê (n)",
    "y": " b. perarey (n)"
  },
  {
    "x": "perarêy (n)",
    "y": " b. perarey (n)"
  },
  {
    "x": "peraya (m)",
    "y": " kaçak"
  },
  {
    "x": "peraye (n)",
    "y": " kaçak"
  },
  {
    "x": "perayene",
    "y": " 1)fırlamak \r\n~2)kanatlanmak, uçmak \r\n~3)sıçramak \r\n~4)akmak (yıldız akmak), kaymak (yıldız kaymak) \r\n~5)(hayvan için) ürküp sıçramak\r\n~Emşo estareyêk pera/rapera/qilîya/rijîya. (Bu gece bir yıldız kaydı.)"
  },
  {
    "x": "perayene ser",
    "y": " (boğa, koç, teke vb. erkek hayvanlar için) çiftleşmek"
  },
  {
    "x": "perayî, -ye",
    "y": " kaçak"
  },
  {
    "x": "perayîş (n)",
    "y": " 1)fırlama \r\n~2)kanatlanış, kanatlanma, uçma, uçuş 2)sıçrama \r\n~3)atlama (uzun atlama) \r\n~5)kayma (yıldız kayması)"
  },
  {
    "x": "perayîşo berzkî (n)",
    "y": " yüksek atlama"
  },
  {
    "x": "perazi (n)",
    "y": " b. perazî (m)"
  },
  {
    "x": "perazî (m)",
    "y": " kuş veya tavuk kanadı"
  },
  {
    "x": "percîn (n)",
    "y": " b. perçîn (n)"
  },
  {
    "x": "percîne (m)",
    "y": " çalı parçası (küçük ağaç parçası)"
  },
  {
    "x": "percîyen (n)",
    "y": " b. perçîn (n)"
  },
  {
    "x": "percîyeni (n)",
    "y": " b. perçîn (n)"
  },
  {
    "x": "perçe (n)",
    "y": " b. parçe (n)"
  },
  {
    "x": "perçe bîyene",
    "y": " b. parçe bîyene"
  },
  {
    "x": "perçe kerdene",
    "y": " b. parçe kerdene"
  },
  {
    "x": "perçebîyayîş (n)",
    "y": " b. parçebîyayîş (n)"
  },
  {
    "x": "perçekerdiş (n)",
    "y": " b. parçekerdiş (n)"
  },
  {
    "x": "perçîn (n)",
    "y": " çit, çeper"
  },
  {
    "x": "perda (m)",
    "y": " b. perde (n)"
  },
  {
    "x": "perda çimî (n)",
    "y": " b. perdeyê çimî (n)"
  },
  {
    "x": "perdayîş (n)",
    "y": " b. pirodayîş (n)"
  },
  {
    "x": "perde (n)",
    "y": " perde"
  },
  {
    "x": "perdeyê çimî (n)",
    "y": " gözdeki saydam tabaka"
  },
  {
    "x": "perdeyo sipî (n)",
    "y": " beyaz perde"
  },
  {
    "x": "perdeyo tulin (n)",
    "y": " tül perde"
  },
  {
    "x": "perdim (n)",
    "y": " b. paldim (n)"
  },
  {
    "x": "pere (n)",
    "y": " para"
  },
  {
    "x": "pereyan reyra",
    "y": " bedelli"
  },
  {
    "x": "pereyan reyra leşkerîye",
    "y": " bedelli askerlik"
  },
  {
    "x": "pereyo asinên (n)",
    "y": " akçe"
  },
  {
    "x": "pereyo carî (n)",
    "y": " cari para"
  },
  {
    "x": "pereyo qelp (n)",
    "y": " sahte para"
  },
  {
    "x": "pereyo sexte (n)",
    "y": " sahte para"
  },
  {
    "x": "pereyo sêmên (n)",
    "y": " akçe"
  },
  {
    "x": "pereyo vîyartbar (n)",
    "y": " cari para"
  },
  {
    "x": "pereyo yatme (n)",
    "y": " sahte para"
  },
  {
    "x": "pereên, -e",
    "y": " b. pereyên, -e"
  },
  {
    "x": "pereşnayene",
    "y": " serpmek"
  },
  {
    "x": "pereşnayîş (n)",
    "y": " serpme"
  },
  {
    "x": "perey (n)",
    "y": " bugünden önceki ikinci gün (önceki gün, evvelki gün)"
  },
  {
    "x": "pereyên, -e",
    "y": " önceki günkü, önceki günden kalma "
  },
  {
    "x": "pereyin, -e",
    "y": " b. pereyên, -e"
  },
  {
    "x": "pereyîn (n)",
    "y": " b. pereyên (n)"
  },
  {
    "x": "perê (n) ",
    "y": " b. perey (n)"
  },
  {
    "x": "perên (n)",
    "y": " b. pirên (n)"
  },
  {
    "x": "perêyen (n)",
    "y": " b. pereyên (n)"
  },
  {
    "x": "performans (n)",
    "y": " performans"
  },
  {
    "x": "pergal (n)",
    "y": " düzen, nizam, sistem"
  },
  {
    "x": "pergal kerdene",
    "y": " düzenlemek, tanzim etrmek; tertiplemek, tertip etmek"
  },
  {
    "x": "pergalê awe (n)",
    "y": " su sistemi"
  },
  {
    "x": "pergalê kontrolê trafîkî (n)",
    "y": " trafik kontrol sistemi"
  },
  {
    "x": "pergalê zanayîşî yê şaristanî (n)",
    "y": " kent bilgi sistemi"
  },
  {
    "x": "pergalo asinrayîrin (n)",
    "y": " raylı sistem"
  },
  {
    "x": "pergalo rayin (n)",
    "y": " raylı sistem"
  },
  {
    "x": "pergalo têmîyanek (n)",
    "y": " karma düzen"
  },
  {
    "x": "pergalkar, -e",
    "y": " düzenleyici, nâzım, tertipleyici, organizatör"
  },
  {
    "x": "pergalkerdiş (n)",
    "y": " düzenleme, düzeltme, tanzim, tanzim etme; tertip etme, tertipleme"
  },
  {
    "x": "pergel (n)",
    "y": " pergel"
  },
  {
    "x": "pergeli (m)",
    "y": " b. pergal (n)"
  },
  {
    "x": "pergenda (m)",
    "y": " perakende, perişan"
  },
  {
    "x": "pergende (n)",
    "y": " perakende, perişan"
  },
  {
    "x": "pergende bîyene",
    "y": " perişan olmak"
  },
  {
    "x": "pergende kerdene",
    "y": " perişan etmek"
  },
  {
    "x": "pergendebîyayîş (n)",
    "y": " perişan olma"
  },
  {
    "x": "pergendekerdiş (n)",
    "y": " perişan etme"
  },
  {
    "x": "pergendeyey (m)",
    "y": " b. pergendeyî (m)"
  },
  {
    "x": "pergendeyî (m)",
    "y": " perişanlık"
  },
  {
    "x": "pergendi (n)",
    "y": " b. pergende (n)"
  },
  {
    "x": "pergendi bîyayene",
    "y": " b. pergende bîyene"
  },
  {
    "x": "pergendi kerdene",
    "y": " b. pergende kerdene"
  },
  {
    "x": "pergendibîyayîş (n)",
    "y": " b. pergendebîyayîş (n)"
  },
  {
    "x": "pergendikerdiş (n)",
    "y": " b. pergendekerdiş (n)"
  },
  {
    "x": "pergendîyê (m)",
    "y": " b. pergendeyî (m)"
  },
  {
    "x": "perginda (m)",
    "y": " b. pergenda (m)"
  },
  {
    "x": "perginde (n)",
    "y": " b. pergende (n)"
  },
  {
    "x": "perginde bîyayene",
    "y": " b. pergende bîyene"
  },
  {
    "x": "perginde kerdene",
    "y": " b. pergende kerdene"
  },
  {
    "x": "pergindebîyayîş (n)",
    "y": " b. pergendebîyayîş (n)"
  },
  {
    "x": "pergindekerdiş (n)",
    "y": " b. pergendekerdiş (n)"
  },
  {
    "x": "pergindeyey (m)",
    "y": " b. pergendeyî (m)"
  },
  {
    "x": "perik (n)",
    "y": " b. pelik (n)"
  },
  {
    "x": "perin, -e-I",
    "y": " b. pelin, -e"
  },
  {
    "x": "perin-II (n)",
    "y": " b. pirên (n)"
  },
  {
    "x": "perin-tumanî (zh)",
    "y": " b. pirên-tumanî (zh)"
  },
  {
    "x": "perîn (n)",
    "y": " b. pirên (n)"
  },
  {
    "x": "perîn kotene",
    "y": " b. pirên kewtene"
  },
  {
    "x": "perînkotiş (n)",
    "y": " b. pirênkewtiş (n)"
  },
  {
    "x": "perîsan, -e",
    "y": " b. perîşan, -e"
  },
  {
    "x": "perîşa",
    "y": " b. perîşan, -e"
  },
  {
    "x": "perîşan, -e",
    "y": " 1) perişan\r\n~2) görme engelli¸ görmez, gözsüz, kör"
  },
  {
    "x": "perîşan bîyayene",
    "y": " b. perîşan bîyene"
  },
  {
    "x": "perîşan bîyene",
    "y": " perişan olmak"
  },
  {
    "x": "perîşan kerdene",
    "y": " perişan etmek"
  },
  {
    "x": "perîşanbîyayîş (n)",
    "y": " perişan olma"
  },
  {
    "x": "perîşaney (m)",
    "y": " b. perîşanî (m)"
  },
  {
    "x": "perîşanênî (m)",
    "y": " perişanlık"
  },
  {
    "x": "perîşani (m)",
    "y": " b. perîşan, -e"
  },
  {
    "x": "perîşanî (m)",
    "y": " perişanlık"
  },
  {
    "x": "perîşanîye (m)",
    "y": " perişanlık"
  },
  {
    "x": "perîşankerdiş (n)",
    "y": " perişan etme"
  },
  {
    "x": "perîşo",
    "y": " b. perîşan, -e"
  },
  {
    "x": "perîşon",
    "y": " b. perîşan, -e"
  },
  {
    "x": "perîşoney (m)",
    "y": " b. perîşanî (m)"
  },
  {
    "x": "perîşû",
    "y": " b. perîşan, -e"
  },
  {
    "x": "perîşûn",
    "y": " b. perîşan, -e"
  },
  {
    "x": "perîyodîk, -e",
    "y": " süreli"
  },
  {
    "x": "perjîn (n)",
    "y": " b. perçîn (n)"
  },
  {
    "x": "perjun (m)",
    "y": " b. pirojine (m)"
  },
  {
    "x": "perkayîne",
    "y": " b. perayene"
  },
  {
    "x": "pernayene",
    "y": " 1)fırlatmak \r\n~2)kanatlandırmak, uçurmak \r\n~3)sıçratmak \r\n~4)kaçırmak (akıl vb. için)"
  },
  {
    "x": "pernayene ser",
    "y": " azarlamak, paylamak, tekdir etmek"
  },
  {
    "x": "pernayîs (n)",
    "y": " b. pernayîş (n)"
  },
  {
    "x": "pernayîş (n)",
    "y": " 1) fırlatma \r\n~2)kanatlandırma, uçurma \r\n~3)sıçratma \r\n~4)kaçırma (akıl vb. için)"
  },
  {
    "x": "pernayîşser (n)",
    "y": " azarlama, melamet, paylama, tekdir etme"
  },
  {
    "x": "pernîyayene ser",
    "y": " azarlanmak"
  },
  {
    "x": "pernîyayîşser (n)",
    "y": " azarlanma"
  },
  {
    "x": "pero cenayene",
    "y": " b. ... ro cenayene"
  },
  {
    "x": "per dayîne",
    "y": " b. piro dayene"
  },
  {
    "x": "pero dayene",
    "y": " b. piro dayene"
  },
  {
    "x": "pero dayîne",
    "y": " b. piro dayene"
  },
  {
    "x": "pero ginayene",
    "y": " b. piro ginayene"
  },
  {
    "x": "pero ginayîne",
    "y": " b. piro ginayene"
  },
  {
    "x": "perodayîş (n)",
    "y": " b. pirodayîş (n)"
  },
  {
    "x": "peroginayîş (n)",
    "y": " b. piroginayîş (n)"
  },
  {
    "x": "peroj (n)",
    "y": " 1)öğle, öğleyin (öğle vakti)\r\n~2)gündüzün"
  },
  {
    "x": "peroj ra dime",
    "y": " b. perojî ra dima"
  },
  {
    "x": "peroj û peşewe",
    "y": " gece gündüz"
  },
  {
    "x": "perojî ra aver",
    "y": " öğleden önce"
  },
  {
    "x": "perojî ra avê",
    "y": " b. perojî ra aver"
  },
  {
    "x": "perojî ra dima",
    "y": " öğleden sonra"
  },
  {
    "x": "perojî ra dime",
    "y": " b. perojî ra dime"
  },
  {
    "x": "perojî ra tepîya",
    "y": " öğleden sonra"
  },
  {
    "x": "perojî ra tepya",
    "y": " b. perojî ra tepîya"
  },
  {
    "x": "peyê perojî (n)",
    "y": " öğleden sonra"
  },
  {
    "x": "peroj-peşewe",
    "y": " gece gündüz"
  },
  {
    "x": "perojîye (m)",
    "y": " öğle yemeği"
  },
  {
    "x": "peron (n)",
    "y": " peron "
  },
  {
    "x": "peroz (n)",
    "y": " b. peroj (n) "
  },
  {
    "x": "peroz û pesewe",
    "y": " b. peroj û peşewe"
  },
  {
    "x": "peroz-pesewe",
    "y": " b. peroj-peşewe"
  },
  {
    "x": "perozîye (m)",
    "y": " b. perojîye (m)"
  },
  {
    "x": "perpal (n)",
    "y": " b. pirpar (n)"
  },
  {
    "x": "perpar (n)",
    "y": " b. pirpar (n)"
  },
  {
    "x": "perperik-I (n)",
    "y": " b. pelik (n)"
  },
  {
    "x": "perperik-II (n)",
    "y": " b. perperike (m)"
  },
  {
    "x": "perperike (m)",
    "y": " kelebek"
  },
  {
    "x": "perpişîyayene",
    "y": " b. parpişîyayene "
  },
  {
    "x": "perpûn (n)",
    "y": " aşı (tıpta)"
  },
  {
    "x": "perpûn bîyene",
    "y": " aşılanmak (tıpta)"
  },
  {
    "x": "perpûn kerdene",
    "y": " aşılamak (tıpta)"
  },
  {
    "x": "perpûnkerdiş (n)",
    "y": " aşılama (tıpta)"
  },
  {
    "x": "perpûnbîyayîş (n)",
    "y": " aşılanma (tıpta)"
  },
  {
    "x": "perr (n)",
    "y": " 1)kanat, kol, taraf, hayvana yüklenen odun vb. yükünde yükün iki tarafından her biri\r\n~2)b. pel-I (n)"
  },
  {
    "x": "perr û bask",
    "y": " kol kanat"
  },
  {
    "x": "perro çep (n)",
    "y": " sol kol, sol kanat, sol taraf"
  },
  {
    "x": "perro rast/raşt (n)",
    "y": " sağ kol, sağ kanat, sağ taraf\r\n~2)b. pel-I (n)"
  },
  {
    "x": "perr dayene",
    "y": " b. pel dayene"
  },
  {
    "x": "perr rakerdene",
    "y": " b. pel rakerdene"
  },
  {
    "x": "perrayene",
    "y": " b. perayene"
  },
  {
    "x": "perrayîş (n)",
    "y": " b. perayîş (n)"
  },
  {
    "x": "perrazi (n)",
    "y": " b. perazî (m)"
  },
  {
    "x": "perre (m)",
    "y": " 1)b. perr (n)\r\n~2)b. pel-I (n)"
  },
  {
    "x": "perra mewze (m)",
    "y": " b. pelê mêwe (n)"
  },
  {
    "x": "perrik (n)",
    "y": " b. pelik (n)"
  },
  {
    "x": "perrin, -i",
    "y": " b. pelin, -e"
  },
  {
    "x": "perrnayene",
    "y": " b. pernayene"
  },
  {
    "x": "perrnayîş (n)",
    "y": " b. pernayîş (n)"
  },
  {
    "x": "perroc (n)",
    "y": " b. peroj (n)"
  },
  {
    "x": "perrocî (m)",
    "y": " b. perojîye (m)"
  },
  {
    "x": "perroj (n)",
    "y": " b. peroj (n)"
  },
  {
    "x": "perrojîye (m)",
    "y": " b. perojîye (m)"
  },
  {
    "x": "perroz (n)",
    "y": " b. peroj (n)"
  },
  {
    "x": "perrozîye (m)",
    "y": " b. perojîye (m)"
  },
  {
    "x": "pers-I (n)",
    "y": " soru, sual"
  },
  {
    "x": "pers kerdene",
    "y": " sormak, sual etmek"
  },
  {
    "x": "persê saxkerdişê rayapêroyî (zh)",
    "y": " kamuoyu yoklaması soruları"
  },
  {
    "x": "persê sayekerdişê rayapêroyî (zh)",
    "y": " kamuoyu yoklaması soruları"
  },
  {
    "x": "persê xo jû kerdene",
    "y": " b. persê xo yew kerdene"
  },
  {
    "x": "persê xo yew kerdene",
    "y": " ağız birliği etmek, söz birliği etmek"
  },
  {
    "x": "persê xo zû kerdene",
    "y": " b. persê xo yew kerdene"
  },
  {
    "x": "perso negatîf (n)",
    "y": " olumsuz soru"
  },
  {
    "x": "perso pozîtîf (n)",
    "y": " olumlu soru"
  },
  {
    "x": "pers-II (m)",
    "y": " b. perse (m)"
  },
  {
    "x": "persanênî (m)",
    "y": " b. perîşanênî (m)"
  },
  {
    "x": "persanîye (m)",
    "y": " b. perîşanîye (m)"
  },
  {
    "x": "persayene",
    "y": " 1)sormak, sual etmek\r\n~2)sorgulamak"
  },
  {
    "x": "persayîş (n)",
    "y": " 1)sorma, sual etme\r\n~2)sorgu, sorgulama"
  },
  {
    "x": "perse (m)",
    "y": " 1)mesele (sorun), sorun, problem\r\n~2)söz"
  },
  {
    "x": "perse dayene",
    "y": " söz vermek"
  },
  {
    "x": "perskerdiş (n)",
    "y": " sorma, sual etme"
  },
  {
    "x": "persname (n)",
    "y": " anket (yazılı anket)"
  },
  {
    "x": "persû (m)",
    "y": " b. parsû (m)"
  },
  {
    "x": "pert (n)",
    "y": " b. purt (n)"
  },
  {
    "x": "pertal (n)",
    "y": " alık (eskimiş giysi), eski püskü giysi"
  },
  {
    "x": "pertalin, -e",
    "y": " eski püskü giysili"
  },
  {
    "x": "Perû (n)",
    "y": " Peru"
  },
  {
    "x": "perû dayene",
    "y": " b. piro dayene"
  },
  {
    "x": "perûdayîş (n)",
    "y": " b. pirodayîş (n)"
  },
  {
    "x": "perûke (m)",
    "y": " peruk, peruka"
  },
  {
    "x": "perûkkar, -e",
    "y": " perukçu"
  },
  {
    "x": "perûkkarî (m)",
    "y": " perukçuluk"
  },
  {
    "x": "perûkkarîye (m)",
    "y": " perukçuluk"
  },
  {
    "x": "perûkroş, -e",
    "y": " perukçu"
  },
  {
    "x": "perûkroşî (m)",
    "y": " perukçuluk"
  },
  {
    "x": "perûkroşîye (m)",
    "y": " perukçuluk"
  },
  {
    "x": "pervaz (n)",
    "y": " b. perwaz (n)"
  },
  {
    "x": "perwaz (n)",
    "y": " pervaz"
  },
  {
    "x": "perwaze (n)",
    "y": " b. perwaz (n)"
  },
  {
    "x": "perwazi (m)",
    "y": " b. perwaz (n)"
  },
  {
    "x": "perwerde (n)",
    "y": " eğitim, öğrenim, tahsil"
  },
  {
    "x": "perwerde bîyene",
    "y": " eğitilmek"
  },
  {
    "x": "perwerde kerdene",
    "y": " eğitmek"
  },
  {
    "x": "perwerdeyê bedenî (n)",
    "y": " beden eğitimi"
  },
  {
    "x": "perwerdeyê teknîkî (n)",
    "y": " teknik eğitim"
  },
  {
    "x": "perwerdeyê trafîkî (n)",
    "y": " trafik eğitimi"
  },
  {
    "x": "perwerdeyê xurtkerdişê meharetî/hunerî (n)",
    "y": " beceri geliştirme eğitimi"
  },
  {
    "x": "perwerdeyo meslekî (n)",
    "y": " mesleki eğitim"
  },
  {
    "x": "wezaretê perwerdeyî (n)",
    "y": " eğitim bakanlığı"
  },
  {
    "x": "perwerdebîyayîş (n)",
    "y": " eğitilme"
  },
  {
    "x": "perwerdekar, -e",
    "y": " 1)eğitbilimci, eğitimci, pedagog\r\n~2)eğitmen"
  },
  {
    "x": "perwerdekerdiş (n)",
    "y": " eğitme"
  },
  {
    "x": "perxeçîyayene",
    "y": " b. pelçiqîyayene"
  },
  {
    "x": "perxeçnayene",
    "y": " b. pelçiqnayene"
  },
  {
    "x": "perxeçnayîş (n)",
    "y": " b. pelçiqnayîş (n)"
  },
  {
    "x": "perxîl (n)",
    "y": " b. parxêle (m)"
  },
  {
    "x": "perzim (n)",
    "y": " b. parzun (n)"
  },
  {
    "x": "perzim kerdene",
    "y": " b. parzun kerdene"
  },
  {
    "x": "perzimkerdiş (n)",
    "y": " b. parzunkerdiş (n)"
  },
  {
    "x": "perzun (n)",
    "y": " b. parzun (n)"
  },
  {
    "x": "perzun kerdene",
    "y": " b. parzun kerdene"
  },
  {
    "x": "perzunkerdiş (n)",
    "y": " b. parzunkerdiş (n)"
  },
  {
    "x": "perzûn (n)",
    "y": " b. parzun (n)"
  },
  {
    "x": "perzûn kerdene",
    "y": " b. parzun kerdene"
  },
  {
    "x": "perzûn ro dayene",
    "y": " b. parzun ro dayene"
  },
  {
    "x": "perzûn ro gunayene",
    "y": " b. parzun ro ginayene "
  },
  {
    "x": "perzûnkerdiş (n)",
    "y": " b. parzunkerdiş (n)"
  },
  {
    "x": "perzûnrodayîş (n)",
    "y": " b. parzunrodayîş (n) "
  },
  {
    "x": "perzûnrogunayîş (n)",
    "y": " b. parzunroginayîş (n)"
  },
  {
    "x": "pes-I (n)",
    "y": " 1)davar, küçükbaş\r\n~2)küçükbaş hayvan sürüsü"
  },
  {
    "x": "pes-II (n)",
    "y": " b. pêş (n)"
  },
  {
    "x": "pesew û peroz",
    "y": " b. peroj û peşewe"
  },
  {
    "x": "pesewe",
    "y": " b. peşewe"
  },
  {
    "x": "pesênayene",
    "y": " övmek"
  },
  {
    "x": "pesênayîş (n)",
    "y": " övgü, övme"
  },
  {
    "x": "pesîmîst, -e",
    "y": " kötümser, bedbin"
  },
  {
    "x": "pesîmîst bîyene",
    "y": " kötümserleşmek, bedbinleşmek"
  },
  {
    "x": "pesîmîstbîyayîş (n)",
    "y": " kötümserleşme, bedbinleşme"
  },
  {
    "x": "pesîmîstî (m)",
    "y": " kötümserlik, bedbinlik"
  },
  {
    "x": "pesîmîstîye (m)",
    "y": " kötümserlik, bedbinlik"
  },
  {
    "x": "pesîng, -i",
    "y": " b. pisîng, -e"
  },
  {
    "x": "peskofî (m)",
    "y": " b. pezkovîye (m)"
  },
  {
    "x": "peskovî (m)",
    "y": " b. pezkovîye (m)"
  },
  {
    "x": "peskûfî (m)",
    "y": " b. pezkovîye (m)"
  },
  {
    "x": "peskûvî (m)",
    "y": " b. pezkovîye (m)"
  },
  {
    "x": "pesn (n)",
    "y": " övgü"
  },
  {
    "x": "pesnî dayene",
    "y": " övmek"
  },
  {
    "x": "pesnîdayîş (n)",
    "y": " övgü, övme"
  },
  {
    "x": "pesoy",
    "y": " b. peşewe"
  },
  {
    "x": "pestîsîd (n)",
    "y": " pestisit"
  },
  {
    "x": "peşeme (n)",
    "y": " b. panşeme (n)"
  },
  {
    "x": "peşerîyayene",
    "y": " b. beşirîyayene"
  },
  {
    "x": "peşerîyayîş (n)",
    "y": " b. beşirîyayîş (n)"
  },
  {
    "x": "peşewe",
    "y": " geceleyin"
  },
  {
    "x": "peşgîre (m)",
    "y": " b. pêşgîre (m)"
  },
  {
    "x": "peşime (n)",
    "y": " b. panşeme (n)"
  },
  {
    "x": "peşm (n)",
    "y": " yün, yapak, yapağı"
  },
  {
    "x": "peşme (n)",
    "y": " b. panşeme (n)"
  },
  {
    "x": "peşmên, -e",
    "y": " yünlü (yünden yapılmış olan)"
  },
  {
    "x": "peşmin, -e",
    "y": " yünlü (içinde yün de bulunan)"
  },
  {
    "x": "peşmireyîş (n)",
    "y": " b. peşmirîyayîş (n)"
  },
  {
    "x": "peşmirîyayene",
    "y": " gülümsemek"
  },
  {
    "x": "peşmirîyayîş (n)",
    "y": " gülümseme"
  },
  {
    "x": "peşmî (m)",
    "y": " b. peşm (n)"
  },
  {
    "x": "peşmyen",
    "y": " b. peşmên, -e, peşmin, -e"
  },
  {
    "x": "peşt (m)",
    "y": " b. piştî (m)"
  },
  {
    "x": "peştî (m)",
    "y": " b. piştî (m)"
  },
  {
    "x": "petikin, -e",
    "y": " b. pahtikin, -e"
  },
  {
    "x": "petikinê (m)",
    "y": " b. pahtikinîye (m)"
  },
  {
    "x": "petrol (n)",
    "y": " petrol"
  },
  {
    "x": "pewcîyaya (m)",
    "y": " b. pewjîyaya (m)"
  },
  {
    "x": "pewcîyaye (n)",
    "y": " b. pewjîyaye (n)"
  },
  {
    "x": "pewcîyayene",
    "y": " b. pewjîyayene"
  },
  {
    "x": "pewcîyayîş (n)",
    "y": " b. pewjîyayîş (n)"
  },
  {
    "x": "pewcnayene",
    "y": " b. pewjnayene"
  },
  {
    "x": "pewcnayîş (n)",
    "y": " b. pewjnayîş (n)"
  },
  {
    "x": "pewjîyaya (m)",
    "y": " pişkin"
  },
  {
    "x": "pewjîyaye (n)",
    "y": " pişkin"
  },
  {
    "x": "pewjîyayene",
    "y": " pişmek\r\n~Nan pewjîya. (Ekmek pişti.)"
  },
  {
    "x": "pewjîyayîş (n)",
    "y": " pişme"
  },
  {
    "x": "pewjîyayîye (m)",
    "y": " pişkin"
  },
  {
    "x": "pewjnayene",
    "y": " pişirmek"
  },
  {
    "x": "pewjnayîş (n)",
    "y": " pişirme"
  },
  {
    "x": "pewraz (n)",
    "y": " b. perwaz (n)"
  },
  {
    "x": "pewta (m)",
    "y": " pişirilmiş olan, pişkin"
  },
  {
    "x": "pewte (n)",
    "y": " pişirilmiş olan, pişkin"
  },
  {
    "x": "pewtene",
    "y": " pişirmek\r\n~Ma nan pewt. (Ekmek pişirdik.)"
  },
  {
    "x": "pewtini",
    "y": " b. pewtene"
  },
  {
    "x": "pewtiş (n)",
    "y": " pişirme"
  },
  {
    "x": "pewtîye (m)",
    "y": " pişirilmiş olan, pişkin"
  },
  {
    "x": "pexel, -e",
    "y": " b. pexîl, -e"
  },
  {
    "x": "pexêl, -e",
    "y": " b. pexîl, -e"
  },
  {
    "x": "pexêlê (m)",
    "y": " b. pexîlî (m), pexîlîye (m)"
  },
  {
    "x": "pexêlê kerdene",
    "y": " b. pexîlî kerdene"
  },
  {
    "x": "pexêlî (m)",
    "y": " b. pexîlî (m), pexîlîye (m)"
  },
  {
    "x": "pexêlî kerdene",
    "y": " b. pexîlî kerdene"
  },
  {
    "x": "pexîl, -e",
    "y": " 1)kıskanç, günücü, hasetçi (başkasını çekemeyen)\r\n~2)cimri"
  },
  {
    "x": "pexîlênî (m)",
    "y": " cimrilik"
  },
  {
    "x": "pexîlî (m)",
    "y": " 1)kıskançlık, günü, günücülük, hasetlik (çekemezlik), çekememezlik\r\n~2)cimrilik"
  },
  {
    "x": "pexîlî kerdene",
    "y": " 1)kıskanmak, günülemek, haset etmek (çekememek)\r\n~2)cimrilik etmek"
  },
  {
    "x": "pexîlîye (m)",
    "y": " 1)kıskançlık, günü, günücülük, hasetlik (çekemezlik), çekememezlik\r\n~2)cimrilik"
  },
  {
    "x": "pexîlîye kerdene",
    "y": " 1)kıskanmak, günülemek, haset etmek (çekememek)\r\n~2)cimrilik etemek"
  },
  {
    "x": "pey-I (nm, n)",
    "y": " arka"
  },
  {
    "x": "pey a nayene",
    "y": " b. era pey nayene"
  },
  {
    "x": "pey a niştene",
    "y": " b. pey de niştene"
  },
  {
    "x": "pey de",
    "y": " geride, arkada"
  },
  {
    "x": "pey de espar bîyene",
    "y": " hayvanın üzerindeki birisinin arkasında binmek"
  },
  {
    "x": "pey de espar kerdene",
    "y": " birisinin arkasına başkasını hayvana bindirmek"
  },
  {
    "x": "pey de eştene",
    "y": " ertelemek, talik etmek"
  },
  {
    "x": "pey de nişnayene",
    "y": " birisinin arkasına başkasını hayvana bindirmek"
  },
  {
    "x": "pey de niştene",
    "y": " hayvanın üzerindeki birisinin arkasında binmek"
  },
  {
    "x": "pey de suwar bîyene",
    "y": " hayvanın üzerindeki birisinin arkasında binmek"
  },
  {
    "x": "pey de suwar kerdene",
    "y": " birisinin arkasına başkasını hayvana bindirmek"
  },
  {
    "x": "pey de wenayene",
    "y": " birisinin arkasına başkasını hayvana bindirmek"
  },
  {
    "x": "pey de weniştene",
    "y": " hayvanın üzerindeki birisinin arkasında binmek"
  },
  {
    "x": "pey ... de nişnayene",
    "y": " birisinin arkasına başkasını hayvana bindirmek"
  },
  {
    "x": "pey ... de niştene",
    "y": " hayvanın üzerindeki birisinin arkasında binmek"
  },
  {
    "x": "pey di",
    "y": " b. pey de"
  },
  {
    "x": "... pey estene",
    "y": " b. ... pey eştene"
  },
  {
    "x": "... pey eştene",
    "y": " birisinin arkasına başkasını hayvana bindirmek"
  },
  {
    "x": "pey kamera (sn)",
    "y": " kamera arkası"
  },
  {
    "x": "pey nayene",
    "y": " b. era pey nayene"
  },
  {
    "x": "pey ... nişnayene",
    "y": " b. pey ... de nişnayene"
  },
  {
    "x": "pey niştene",
    "y": " b. pey de niştene"
  },
  {
    "x": "pey ra",
    "y": " arkadan, arkasından; sonradan, bilahare"
  },
  {
    "x": "pey ... ra",
    "y": " b. "
  },
  {
    "x": "peyê ... de",
    "y": " arkasında"
  },
  {
    "x": "peyê ... di",
    "y": " b. peyê ... de"
  },
  {
    "x": "peyê ... ra",
    "y": " arkasından\r\n~Mêrdek peyê berî ra vejîya. (Adam kapının arkasından çıktı.)"
  },
  {
    "x": "pey-II (nm & s, n)",
    "y": " art"
  },
  {
    "x": "pey beran (n)",
    "y": " b. peyê beran (n)"
  },
  {
    "x": "pey beron (n)",
    "y": " b. peyê beran (n)"
  },
  {
    "x": "pey berûn (n)",
    "y": " b. peyê beran (n)"
  },
  {
    "x": "pey ca",
    "y": " b. peyê cû"
  },
  {
    "x": "pey cepheyî (n)",
    "y": " cephe gerisi"
  },
  {
    "x": "pey co",
    "y": " b. peyê cû"
  },
  {
    "x": "pey coy",
    "y": " b. peyê cû"
  },
  {
    "x": "pey cû",
    "y": " b. peyê cû"
  },
  {
    "x": "pey çêberan (n)",
    "y": " b. peyê beran (n)"
  },
  {
    "x": "pey çêveran (n)",
    "y": " b. peyê beran (n)"
  },
  {
    "x": "pey do",
    "y": " b. peyê cû"
  },
  {
    "x": "pey dû",
    "y": " b. peyê cû"
  },
  {
    "x": "pey keyberan (n)",
    "y": " b. peyê beran (n)"
  },
  {
    "x": "peyê beran (n)",
    "y": " cezaevi"
  },
  {
    "x": "peyê co",
    "y": " b. peyê cû"
  },
  {
    "x": "peyê con",
    "y": " b. peyê cû"
  },
  {
    "x": "peyê conî",
    "y": " b. peyê cû "
  },
  {
    "x": "peyê coy",
    "y": " b. peyê cû "
  },
  {
    "x": "peyê cû",
    "y": " ondan sonra, bilahare"
  },
  {
    "x": "peyê cûn",
    "y": " b. peyê cû"
  },
  {
    "x": "peyê cûnî",
    "y": " b. peyê cû"
  },
  {
    "x": "peyê perojî (n)",
    "y": " öğleden sonra"
  },
  {
    "x": "pey-III",
    "y": " 1)b. bi, b. Pê\r\n~2)b. Pê"
  },
  {
    "x": "pey aqilîyayene",
    "y": " b. pê arqilîyayene"
  },
  {
    "x": "pey aqilnayene",
    "y": " b. pê arqilnayene"
  },
  {
    "x": "pey dejayene",
    "y": " b. pê dejayene"
  },
  {
    "x": "pey lingan",
    "y": " b. pê lingan"
  },
  {
    "x": "pey qapan setene",
    "y": " b. bi qapan sentene"
  },
  {
    "x": "pey qapûn setene",
    "y": " b. bi qapan sentene"
  },
  {
    "x": "pey ... a",
    "y": " b. bi ... ra"
  },
  {
    "x": "pey ... ya",
    "y": " b. bi ... wa"
  },
  {
    "x": "pey yarî kerdene",
    "y": " b. pê yarî kerdene"
  },
  {
    "x": "peyzanayene",
    "y": " b. pêzanayene"
  },
  {
    "x": "peya-I (n)",
    "y": " adam"
  },
  {
    "x": "peya-II (m)",
    "y": " yaya, yayan"
  },
  {
    "x": "peya-III (n)",
    "y": " b. peye-III (n)"
  },
  {
    "x": "peya bîyayene",
    "y": " b. peye bîyene"
  },
  {
    "x": "peya bîyene",
    "y": " b. peye bîyene"
  },
  {
    "x": "peyaqilîyayîş (n)",
    "y": " b. pêarqilîyayîş (n)"
  },
  {
    "x": "peyaqilnayîş (n)",
    "y": " b. pêarqilnayîş (n)"
  },
  {
    "x": "peyarayîr (n)",
    "y": " b. peyerayîr (n)"
  },
  {
    "x": "peyatî",
    "y": " yürüyerek"
  },
  {
    "x": "peyatîye (m)",
    "y": " adamlık"
  },
  {
    "x": "peyaye (m)",
    "y": " yaya, yayan"
  },
  {
    "x": "peyayîye (m)",
    "y": " adamlık"
  },
  {
    "x": "peyda bîyene",
    "y": " bulunmak\r\n~Zimistanî dewa ma de engure peyda nêbena. (Kışın köyümüzde üzüm bulunmaz.)"
  },
  {
    "x": "peyda kerdene",
    "y": " 1)sağlamak, temin etmek\r\n~2)bulmak"
  },
  {
    "x": "peydabîyayîş (n)",
    "y": " bulunma"
  },
  {
    "x": "peydakerdiş (n)",
    "y": " 1)sağlama, temin etme\r\n~2)bulma"
  },
  {
    "x": "peydedayîş (n)",
    "y": " iade"
  },
  {
    "x": "peydeeştiş (n)",
    "y": " erteleme, talik"
  },
  {
    "x": "peye-I (n)",
    "y": " b. peya-I (n)"
  },
  {
    "x": "peye-II (n)",
    "y": " b. peyên (n)"
  },
  {
    "x": "peye-III (n)",
    "y": " yaya, yayan"
  },
  {
    "x": "peye bîyayene",
    "y": " b. peye bîyene"
  },
  {
    "x": "peye bîyene",
    "y": " inmek"
  },
  {
    "x": "peyebîyayîş (n)",
    "y": " inme"
  },
  {
    "x": "peyedat (n)",
    "y": " 1) artedat\r\n~2) edat, ilgeç"
  },
  {
    "x": "peyek (n)",
    "y": " b. peyike (m)"
  },
  {
    "x": "peyeki (m)",
    "y": " b. peyîke (m)"
  },
  {
    "x": "peyen, -i",
    "y": " b. peyên, -e "
  },
  {
    "x": "peyene",
    "y": " b. peyêne (m)"
  },
  {
    "x": "peyeni",
    "y": " b. peyêne (m)"
  },
  {
    "x": "peyerayîr (n)",
    "y": " 1)yaya yolu\r\n~2)kaldırım, yaya kaldırımı"
  },
  {
    "x": "peyetî (m)",
    "y": " yaya olma durumu"
  },
  {
    "x": "peyevîyer (n)",
    "y": " yaya geçidi"
  },
  {
    "x": "peyeyî (m)",
    "y": " yaya olma durumu"
  },
  {
    "x": "peyên, -e",
    "y": " 1)son, ahir \r\n~2)sondaki, sonuncu \r\n~3)final\r\n~Na reya peyên a. (Bu son kezdir.)"
  },
  {
    "x": "peyêne (m)",
    "y": " nihayet, nihayetinde, sonunda (nihayetinde)"
  },
  {
    "x": "peygir (n)",
    "y": " sonek"
  },
  {
    "x": "peygoşî (m)",
    "y": " ihmal"
  },
  {
    "x": "peygoşîye (m)",
    "y": " ihmal"
  },
  {
    "x": "peyhesnayene",
    "y": " b. pêhesnayene"
  },
  {
    "x": "peyhesnayîş (n)",
    "y": " b. pêhesnayîş (n)"
  },
  {
    "x": "peyig (m)",
    "y": " b. peyike (m)"
  },
  {
    "x": "peyigi (m)",
    "y": " b. peyike (m)"
  },
  {
    "x": "peyike (m)",
    "y": " şalvarın iki bacak arasında sarkan arka bölümü"
  },
  {
    "x": "peyiki (m)",
    "y": " b. peyike (m)"
  },
  {
    "x": "peyin, -e",
    "y": " b. peyên, -e"
  },
  {
    "x": "peyîg (m)",
    "y": " b. peyike (m)"
  },
  {
    "x": "peyîgi (m)",
    "y": " b. peyike (m)"
  },
  {
    "x": "peyîk (m)",
    "y": " b. peyike (m)"
  },
  {
    "x": "peyîke (m)",
    "y": " 1)b. peyike (m)\r\n~2)baldır, incik"
  },
  {
    "x": "peyîn, -e",
    "y": " b. peyên, -e"
  },
  {
    "x": "peyk (n)",
    "y": " uydu"
  },
  {
    "x": "peykanî",
    "y": " geri geri"
  },
  {
    "x": "peykanî şîyene",
    "y": " geri geri gitmek, arka arka gitmek "
  },
  {
    "x": "peyker (n)",
    "y": " heykel"
  },
  {
    "x": "peykertiraş, -e",
    "y": " heykeltıraş"
  },
  {
    "x": "...peym",
    "y": " ölçer (bileşik sözcükler için)"
  },
  {
    "x": "peymane (m)",
    "y": " antlaşma"
  },
  {
    "x": "peymana enternasyonale (m)",
    "y": " uluslararası antlaşma"
  },
  {
    "x": "peymana mîyanneteweyîye (m)",
    "y": " uluslararası antlaşma"
  },
  {
    "x": "peymane mor kerdene",
    "y": " antlaşma yapmak, antlaşmak"
  },
  {
    "x": "peymane viraştene",
    "y": " antlaşma yapmak, antlaşmak"
  },
  {
    "x": "peymawitene",
    "y": " b. peymitene"
  },
  {
    "x": "peymawitiş (n)",
    "y": " b. peymitiş (n)"
  },
  {
    "x": "peymawîyayene",
    "y": " b. peymîyayene"
  },
  {
    "x": "peymawîyayîş (n)",
    "y": " b. peymîyayîş (n)"
  },
  {
    "x": "peymayîş (n)",
    "y": " b. peymitiş (n)"
  },
  {
    "x": "peyme (n)",
    "y": " 1)ölçü, mikyas\r\n~2)vezin\r\n~3)tartı"
  },
  {
    "x": "peyme girewtene",
    "y": " ölçü almak"
  },
  {
    "x": "peymeyê dergîye",
    "y": " uzunluk ölçüleri"
  },
  {
    "x": "peymeyê giranîye",
    "y": " ağırlık ölçüleri"
  },
  {
    "x": "peymeyê rîverîye",
    "y": " yüzey ölçüleri (mat)"
  },
  {
    "x": "peymeyê erûzî (n)",
    "y": " aruz vezni"
  },
  {
    "x": "peymegirewtiş (n)",
    "y": " ölçü alma"
  },
  {
    "x": "peymitene",
    "y": " ölçmek"
  },
  {
    "x": "peymitiş (n)",
    "y": " ölçüm, ölçme"
  },
  {
    "x": "peymitişê alkolî (n)",
    "y": " alkol ölçümü"
  },
  {
    "x": "peymî (m)",
    "y": " ölçüm"
  },
  {
    "x": "peymîya gazî (m)",
    "y": " gaz ölçüm"
  },
  {
    "x": "peymîya lerzîyayîşî (m)",
    "y": " titreşim ölçümü"
  },
  {
    "x": "peymîya senînîye (m)",
    "y": " kalite ölçümü"
  },
  {
    "x": "peymîyayene",
    "y": " ölçülmek"
  },
  {
    "x": "peymîyayîş (n)",
    "y": " ölçülme"
  },
  {
    "x": "peymîye (m)",
    "y": " ölçüm"
  },
  {
    "x": "peymker (n)",
    "y": " ölçer (ölçü yapan aygıt)"
  },
  {
    "x": "peyname (n)",
    "y": " soyadı"
  },
  {
    "x": "peyneser",
    "y": " b. peyser"
  },
  {
    "x": "peynî (m)",
    "y": " 1)arka, art, nihayet, bitiş, son, sonuç, netice \r\n~Peynîya nê merdimî se bena? (Bu adamın sonu ne olacak?)\r\n~2)final \r\n~3)makat, göt"
  },
  {
    "x": "peynî ardene",
    "y": " sonuçlandırmak, bitirmek"
  },
  {
    "x": "peynî de",
    "y": " ahir, nihayet, sonunda, ahiren (son olarak)"
  },
  {
    "x": "peynî di",
    "y": " b. peynî de"
  },
  {
    "x": "peynî piro ameyene",
    "y": " bitmek"
  },
  {
    "x": "peynî piro ardene",
    "y": " sonuçlandırmak, bitirmek"
  },
  {
    "x": "peynî piro omeyîne",
    "y": " b. peynî piro ameyene"
  },
  {
    "x": "peynî ra",
    "y": " anal"
  },
  {
    "x": "peynîya ... ameyîş",
    "y": " bitme, bitiş"
  },
  {
    "x": "peynîya peynîyan",
    "y": " ahir, nihayet, sonunda"
  },
  {
    "x": "peynîyê analîzî (zh)",
    "y": " analiz sonuçları"
  },
  {
    "x": "peynîyê kombîyayîşî (zh)",
    "y": " toplantı sonuçları"
  },
  {
    "x": "peynîyê kontrolî (zh)",
    "y": " kontrol sonuçları"
  },
  {
    "x": "peynîardiş (n)",
    "y": " bitirme, sonuçlandırma"
  },
  {
    "x": "peynîpiroardiş (n)",
    "y": " bitirme, sonuçlandırma"
  },
  {
    "x": "peynîye (m)",
    "y": " 1)arka, art, nihayet, bitiş, son, sonuç, netice \r\n~2)final \r\n~3)makat, göt"
  },
  {
    "x": "peynîya ... ameyene",
    "y": " bitmek"
  },
  {
    "x": "peynîya ... hameyîne",
    "y": " b. peynîya ... ameyene"
  },
  {
    "x": "peynîya ... omeyîne",
    "y": " b. peynîya ... ameyene"
  },
  {
    "x": "peynîye ardene",
    "y": " sonuçlandırmak, bitirmek"
  },
  {
    "x": "peynîye de",
    "y": " ahir, nihayet. sonunda, ahiren (son olarak)"
  },
  {
    "x": "peynîye piro ameyene",
    "y": " bitmek"
  },
  {
    "x": "peynîye piro ardene",
    "y": " sonuçlandırmak, bitirmek"
  },
  {
    "x": "peynîyê kombîyayîşî (zh)",
    "y": " toplantı sonuçları"
  },
  {
    "x": "peynîyeardiş (n)",
    "y": " bitirme, sonuçlandırma"
  },
  {
    "x": "peynîyepiroardiş (n)",
    "y": " bitirme, sonuçlandırma"
  },
  {
    "x": "peynîyeser şîyene",
    "y": " geri geri gitmek, arka arka gitmek "
  },
  {
    "x": "peypeykî",
    "y": " geri geri"
  },
  {
    "x": "peypeykî şîyene",
    "y": " geri geri gitmek, arka arka gitmek "
  },
  {
    "x": "peyqeyl bîyayene",
    "y": " b. pêqayîl bîyene"
  },
  {
    "x": "peyqeylbîyayîş (n)",
    "y": " b. pêqayîlbîyayîş (n)"
  },
  {
    "x": "peyridîyayîne",
    "y": " b. pêridîyayene"
  },
  {
    "x": "peyridîyayîş (n)",
    "y": " b. pêridîyayîş (n)"
  },
  {
    "x": "peysayene",
    "y": " kurumak"
  },
  {
    "x": "peysayîş (n)",
    "y": " kuruma"
  },
  {
    "x": "peyser",
    "y": " geri, geri geri, gerisingeri, gerisingeriye"
  },
  {
    "x": "peyser de",
    "y": " geri, gerisingeri, gerisingeriye"
  },
  {
    "x": "peyser di",
    "y": " b. peyser de"
  },
  {
    "x": "peyser şîyene",
    "y": " geri geri gitmek, arka arka gitmek "
  },
  {
    "x": "peyserşîyayîş (n, sn)",
    "y": " geriye dönüş"
  },
  {
    "x": "peysnayene",
    "y": " kurutmak"
  },
  {
    "x": "peysnayîş (n)",
    "y": " kurutma"
  },
  {
    "x": "peyşayene",
    "y": " b. pêşayene"
  },
  {
    "x": "peyşayîş (n)",
    "y": " b. pêşayîş (n)"
  },
  {
    "x": "peyşîyayîne",
    "y": " b. pêşîyene"
  },
  {
    "x": "peyşîyayîş (n)",
    "y": " b. pêşîyayîş (n)"
  },
  {
    "x": "peyşnayene",
    "y": " b. pêşnayene"
  },
  {
    "x": "peyşnayîş (n)",
    "y": " b. pêşnayîş (n)"
  },
  {
    "x": "peyt, -i",
    "y": " b. pêt, -e"
  },
  {
    "x": "peyt bîyayene",
    "y": " b. pêt bîyene"
  },
  {
    "x": "peyt kerdene",
    "y": " b. pêt kerdene"
  },
  {
    "x": "peytbîyayîş (n)",
    "y": " b. pêtbîyayîş (n)"
  },
  {
    "x": "peytê (m)",
    "y": " b. pêtî (m)"
  },
  {
    "x": "peytkerdiş (n)",
    "y": " b. pêtkerdiş (n)"
  },
  {
    "x": "peywendî (m)",
    "y": " bağlantı"
  },
  {
    "x": "peywendîdar, -e",
    "y": " bağlantılı"
  },
  {
    "x": "peyxember (n)",
    "y": " b. pêxamber (n)"
  },
  {
    "x": "peyzaj (n)",
    "y": " peyzaj"
  },
  {
    "x": "peyzajo kulturî (n)",
    "y": " kültürel peyzaj"
  },
  {
    "x": "pez (n)",
    "y": " b. pes (n)"
  },
  {
    "x": "pezbend (n)",
    "y": " b. bazbend (n)"
  },
  {
    "x": "pezkofî",
    "y": " b. pezkovî"
  },
  {
    "x": "pezkovî, -ye",
    "y": " yabankeçisi"
  },
  {
    "x": "pezkûfî",
    "y": " b. pezkovî, -ye"
  },
  {
    "x": "pezkûvî",
    "y": " b. pezkovî, -ye"
  },
  {
    "x": "pezn (m)",
    "y": " b. bejne (m)"
  },
  {
    "x": "peznderg, -e",
    "y": " b. bejnderg, -e"
  },
  {
    "x": "pezne (m)",
    "y": " b. bejne (m)"
  },
  {
    "x": "pê-I",
    "y": " ile, vasıtasıyla\r\n~Pê tercumanî îfade da. (Tercüman vasıtasıyla ifade verdi.)"
  },
  {
    "x": "pê ... a",
    "y": " b. bi ... ra"
  },
  {
    "x": "pê birnayîşî vîyartiş (sn)",
    "y": " kesme ile geçiş"
  },
  {
    "x": "pê ... henekî kerdene",
    "y": " dalga geçmek\r\n~pê ... henekî kerdene: dalga geçmek"
  },
  {
    "x": "pê ... kay kerdene",
    "y": " dalga geçmek\r\n~\tPê mi kay meke! (Benimle dalga geçme!)"
  },
  {
    "x": "pê lingan",
    "y": " yayan, yürüyerek"
  },
  {
    "x": "pê lingon",
    "y": " b. pê lingan"
  },
  {
    "x": "pê lingûn",
    "y": " b. pê lingan"
  },
  {
    "x": "pê mendene",
    "y": " gebe kalmak, hamile kalmak"
  },
  {
    "x": "pê sabir",
    "y": " b. pê sebir"
  },
  {
    "x": "pê sebir",
    "y": " sabırla"
  },
  {
    "x": "pê ... tinaz kerdene",
    "y": " dalga geçmek\r\n~Pê kêna/kêneke tinaz meke! (Kızla dalga geçme!)"
  },
  {
    "x": "pê ... ya",
    "y": " b. bi ... wa"
  },
  {
    "x": "pê yarî kerdene",
    "y": " (ile) alay etmek"
  },
  {
    "x": "pê-II (nm & s, n)",
    "y": " 1)b. pey-I (nm, n)\r\n~2)b. pey-II (nm & s, n)"
  },
  {
    "x": "pê cû",
    "y": " b. peyê cû"
  },
  {
    "x": "pêê cû",
    "y": " b. peyê cû"
  },
  {
    "x": "pê-III",
    "y": " birbiri"
  },
  {
    "x": "pê ameyene",
    "y": " 1)anlaşmak, barışmak\r\n~2)bağdaşmak"
  },
  {
    "x": "pê ardene",
    "y": " 1)barıştırmak \r\n~2)oluşturmak \r\n~3)uydurmak"
  },
  {
    "x": "pê dîyayene",
    "y": " b. pê dîyene"
  },
  {
    "x": "pê dîyayeni",
    "y": " b. pê dîyene"
  },
  {
    "x": "pê dîyene",
    "y": " buluşmak"
  },
  {
    "x": "pê hameyene",
    "y": " b. pê ameyene"
  },
  {
    "x": "pê jumînî guretene",
    "y": " b. yewbînî girewtene"
  },
  {
    "x": "pê kerdene",
    "y": " 1)anlaşmak, geçinmek (başkasıyla geçinmek)\r\n~2)ağız birliği etmek, söz birliği etmek\r\n~3)bağdaşmak"
  },
  {
    "x": "pê kewtene",
    "y": " çatışmak"
  },
  {
    "x": "pê rê nuştene",
    "y": " yazışmak"
  },
  {
    "x": "pê veradayene",
    "y": " boşanmak"
  },
  {
    "x": "pê verdayene",
    "y": " boşanmak"
  },
  {
    "x": "pê verdayeni",
    "y": " 1)b. pê verdayene\r\n~2)b. pêw verdayene"
  },
  {
    "x": "pê viradayeni",
    "y": " b. pê veradayene"
  },
  {
    "x": "pê-IV",
    "y": " -e"
  },
  {
    "x": "pê aqilîyayene",
    "y": " b. pê arqilîyayene"
  },
  {
    "x": "pê arqilîyayene",
    "y": " ayrımında olmak, ayrımsamak, fark etmek, farkına varmak"
  },
  {
    "x": "pê arqilnayene",
    "y": " farkına vardırmak"
  },
  {
    "x": "pê dejayene",
    "y": " -e üzülmek"
  },
  {
    "x": "pê tal bîyene",
    "y": " -e üzülmek"
  },
  {
    "x": "pêameyîş (n)",
    "y": " 1)anlaşma, barışma\r\n~2)bağdaşma"
  },
  {
    "x": "pêameyîşê hemverê qatî (n)",
    "y": " kat karşılığı anlaşması"
  },
  {
    "x": "pêaqilîyayîş (n)",
    "y": " b. pêarqilîyayîş (n)"
  },
  {
    "x": "pêaqilnayene",
    "y": " b. pê arqilnayene"
  },
  {
    "x": "pêaqilnayîş (n)",
    "y": " b. pêarqilnayîş (n)"
  },
  {
    "x": "pêarde (n, sn)",
    "y": " kurgu, montaj"
  },
  {
    "x": "pêardiş (n)",
    "y": " 1)barıştırma \r\n~2)oluşturma \r\n~3)uydurma"
  },
  {
    "x": "pêarqilîyayîş (n)",
    "y": " ayrımında olma, fark etme, farkına varma"
  },
  {
    "x": "pêarqilnayîş (n)",
    "y": " farkına vardırma"
  },
  {
    "x": "pêbin de",
    "y": " alt alta"
  },
  {
    "x": "pêç (m)",
    "y": " b. pêçe (m)"
  },
  {
    "x": "pêçe (m)",
    "y": " toyaka"
  },
  {
    "x": "pêçe tede tadayene",
    "y": " (mecazi) eziyet etmek"
  },
  {
    "x": "pêçeke (m)",
    "y": " sarmaşık"
  },
  {
    "x": "pêçeki (m)",
    "y": " b. pêçeke (m)"
  },
  {
    "x": "pêçi (m)",
    "y": " b. pêçe (m)"
  },
  {
    "x": "pêçi tede tadayîne",
    "y": " b. pêçe tede tadayene"
  },
  {
    "x": "pêçike (m)",
    "y": " b. bêçike (m)"
  },
  {
    "x": "pêçika derge (m)",
    "y": " b. bêçika derge (m)"
  },
  {
    "x": "pêçika ortî (m)",
    "y": " b. bêçika ortî (m)"
  },
  {
    "x": "pêçika pîle",
    "y": " b. bêçika pîle (m)"
  },
  {
    "x": "pêda kerdene",
    "y": " b. peyda kerdene"
  },
  {
    "x": "pêdakerdiş (n)",
    "y": " b. peydakerdiş (n)"
  },
  {
    "x": "pêdest",
    "y": " elbirliği"
  },
  {
    "x": "pêdest de antene",
    "y": " çekiştirmek"
  },
  {
    "x": "pêdest de kaş kerdene",
    "y": " çekiştirmek"
  },
  {
    "x": "pêdesta",
    "y": " elbirliğiyle, elbirliği"
  },
  {
    "x": "pêdestdeantiş (n)",
    "y": " çekiştirme"
  },
  {
    "x": "pêdestdekaşkerdiş (n)",
    "y": " çekiştirme"
  },
  {
    "x": "pêdesti antene",
    "y": " b. têdest de antene"
  },
  {
    "x": "pêdima",
    "y": " arka arkaya, art arda"
  },
  {
    "x": "pêdime",
    "y": " arka arkaya, art arda"
  },
  {
    "x": "pêdîyayîş (n)",
    "y": " buluşma"
  },
  {
    "x": "pêen, -e",
    "y": " b. peyên, -e"
  },
  {
    "x": "pêeysnayene",
    "y": " b. pêhesnayene"
  },
  {
    "x": "pêeysnayîş (n)",
    "y": " b. pêhesnayîş (n)"
  },
  {
    "x": "pêên, -e",
    "y": " b. peyên, -e"
  },
  {
    "x": "pêfekî kewtene",
    "y": " sertçe tartışmak, ağız kavgası yapmak"
  },
  {
    "x": "pêfekîkewtiş (n)",
    "y": " sertçe tartışma, ağız kavgası yapma"
  },
  {
    "x": "pêfeko perayene",
    "y": " sertçe tartışmak, ağız kavgası yapmak"
  },
  {
    "x": "pêfekoperayîş (n)",
    "y": " sertçe tartışma, ağız kavgası yapma"
  },
  {
    "x": "pêgur antene",
    "y": " çömelmeden ve belini doğrultmadan, eğilmiş durumda ekin biçmekte yarışmak"
  },
  {
    "x": "pêahmeyîş (n)",
    "y": " b. pêameyîş (n)"
  },
  {
    "x": "pêhesnayene",
    "y": " 1)uyarmak, ikaz etmek\r\n~2)afişe etmek"
  },
  {
    "x": "pêhesnayîş (n)",
    "y": " 1)uyarma, ikaz etme \r\n~2)duyurum \r\n~3)afişe etme"
  },
  {
    "x": "pêhet de",
    "y": " bir arada\r\n~Şima key pêhet de bîy? (Ne zaman bir aradaydınız?)"
  },
  {
    "x": "pêhet şîyene",
    "y": " 1)birbirinin yanına gitmek\r\n~2)(birbiriyle) cinsel ilişkide bulunmak"
  },
  {
    "x": "pêhetşîyayîş (n)",
    "y": " 1)birbirinin yanına gitme\r\n~2)(birbiriyle) cinsel ilişkide bulunma"
  },
  {
    "x": "pêhêsnayîş (n)",
    "y": " b. pêhesnayîş (n)"
  },
  {
    "x": "pêj (n)",
    "y": " pîj (n)"
  },
  {
    "x": "pêjik (n)",
    "y": " pîjik (n)"
  },
  {
    "x": "pêjîkekî (zh)",
    "y": " b. pîjikekî (zh)"
  },
  {
    "x": "pêkeçel (n)",
    "y": " b. pîrkeçel (n)"
  },
  {
    "x": "pêkerdiş (n)",
    "y": " 1)ahenk, uyum, anlaşma, geçinme (başkasıyla geçinme)\r\n~2)bağdaşma"
  },
  {
    "x": "pêkewtiş (n)",
    "y": " çatışma"
  },
  {
    "x": "pêkirancîyayene",
    "y": " çekişmek (dövüşmek için çekişmek)"
  },
  {
    "x": "pêkirancîyayîş (n)",
    "y": " çekişme (dövüşmek için çekişme)"
  },
  {
    "x": "pêl (n)",
    "y": " dalga"
  },
  {
    "x": "pêlê vengî (n)",
    "y": " ses dalgası"
  },
  {
    "x": "pêlê erdlerzî (n)",
    "y": " deprem dalgası"
  },
  {
    "x": "pêlo newe",
    "y": " yeni dalga"
  },
  {
    "x": "pêlav (n)",
    "y": " ayakkabı"
  },
  {
    "x": "pêle (m)",
    "y": " b. pêl (n)"
  },
  {
    "x": "pêlek (n)",
    "y": " bez torba, torba"
  },
  {
    "x": "pêlekê çimî (n)",
    "y": " gözün aşağısındaki deri"
  },
  {
    "x": "pêlekê çopî (n)",
    "y": " çöp torbası"
  },
  {
    "x": "pêleke (n)",
    "y": " b. pêlek (n)"
  },
  {
    "x": "pêlike (n)",
    "y": " b. pêlek (n)"
  },
  {
    "x": "pêmawitene",
    "y": " b. peymitene"
  },
  {
    "x": "pêmawitiş (n)",
    "y": " b. peymitiş (n)"
  },
  {
    "x": "pêmawîyayene",
    "y": " b. peymîyayene"
  },
  {
    "x": "pêmawîyayîş (n)",
    "y": " b. peymîyayîş (n)"
  },
  {
    "x": "pême (n) ",
    "y": " b. peyme (n)"
  },
  {
    "x": "pêmendênî (m)",
    "y": " gebelik, hamilelik"
  },
  {
    "x": "pêmendiş (n)",
    "y": " benzeşim, benzeşme, analoji"
  },
  {
    "x": "pêmendîye (m) ",
    "y": " gebe, iki canlı, hamile"
  },
  {
    "x": "pêmendîye bîyene",
    "y": " gebe olmak, hamile olmak, iki canlı olmak"
  },
  {
    "x": "pêmitene",
    "y": " b. peymitene"
  },
  {
    "x": "pêmiteni",
    "y": " b. peymitene"
  },
  {
    "x": "pêmitiş (n)",
    "y": " b. peymitiş (n)"
  },
  {
    "x": "pêmîyayene",
    "y": " b. peymîyayene"
  },
  {
    "x": "pêmîyayêne",
    "y": " b. peymîyayene"
  },
  {
    "x": "pêmîyayîş (n)",
    "y": " b. peymîyayîş (n)"
  },
  {
    "x": "pênas (n)",
    "y": " tanım, tarif"
  },
  {
    "x": "pênas bîyene",
    "y": " tanımlanmak, tarif edilmek"
  },
  {
    "x": "pênas kerdene",
    "y": " tanımlamak, tarif etmek"
  },
  {
    "x": "pênasê karî (n)",
    "y": " iş tanımı"
  },
  {
    "x": "pênasê wezîfeyî (n)",
    "y": " görev tanımı"
  },
  {
    "x": "pênasê xebate (n)",
    "y": " iş tanımı"
  },
  {
    "x": "pênasbîyayîş (n)",
    "y": " tarif edilme"
  },
  {
    "x": "pênasbîyayîşê projeyî (n)",
    "y": " projenin tanımlanması, projenin tarif edilmesi"
  },
  {
    "x": "pênaskerdiş (n)",
    "y": " tanımlama, tarif etme"
  },
  {
    "x": "pêncere (n)",
    "y": " b. pencera (m)"
  },
  {
    "x": "pêndir (n)",
    "y": " b. penîr (n)"
  },
  {
    "x": "pêndîr (n)",
    "y": " b. penîr (n)"
  },
  {
    "x": "pênêameya (m)",
    "y": " bağdaşmaz"
  },
  {
    "x": "pênêameye (m)",
    "y": " bağdaşmaz"
  },
  {
    "x": "pênêameyî, -ye",
    "y": " bağdaşmaz"
  },
  {
    "x": "pênêkerda (m)",
    "y": " bağdaşmaz"
  },
  {
    "x": "pênêkerde (m)",
    "y": " bağdaşmaz"
  },
  {
    "x": "pênî (m)",
    "y": " b. peynî (m)"
  },
  {
    "x": "pênî ardene",
    "y": " b. peynî ardene"
  },
  {
    "x": "pênî di",
    "y": " b. peynî de"
  },
  {
    "x": "pênî piro amayene",
    "y": " b. peynî piro ameyene"
  },
  {
    "x": "pênî piro ameyîne",
    "y": " b. peynî piro ameyene"
  },
  {
    "x": "pênî piro hameyîne",
    "y": " b. peynî piro ameyene"
  },
  {
    "x": "pênî ra",
    "y": " b. peynî ra"
  },
  {
    "x": "pênîr (n)",
    "y": " b. penîr (n)"
  },
  {
    "x": "pênîye (m)",
    "y": " b. peynîye (m)"
  },
  {
    "x": "pênîya ... amayîne",
    "y": " b. peynîya ... ameyene"
  },
  {
    "x": "pênîye ardene",
    "y": " b. peynîye ardene"
  },
  {
    "x": "pênîye de",
    "y": " b. peynîye de"
  },
  {
    "x": "pênîye piro amayene",
    "y": " b. peynîye piro ameyene"
  },
  {
    "x": "pênîye piro ameyîne",
    "y": " b. peynîye piro ameyene"
  },
  {
    "x": "pêpersayîş (n)",
    "y": " birbirinin hal hatırını sorma"
  },
  {
    "x": "pêpey de wenayene",
    "y": " (birkaç kişiyi) arka arkaya bindirmek"
  },
  {
    "x": "pêpey de weniştene",
    "y": " (birkaç kişi) arka arkaya binmek"
  },
  {
    "x": "pêpey de wenîştene",
    "y": " b. pêpey de weniştene"
  },
  {
    "x": "pêpey ginayene",
    "y": " birikmek"
  },
  {
    "x": "pêpey gunayîne",
    "y": " b. pêpey ginayene"
  },
  {
    "x": "pêpey ra",
    "y": " arka arkaya, art arda"
  },
  {
    "x": "pêpeyginayîş (n)",
    "y": " birikme"
  },
  {
    "x": "pêpeygunayîş (n)",
    "y": " b. pêpeyginayîş (n)"
  },
  {
    "x": "pêpî",
    "y": " b. pîpî"
  },
  {
    "x": "pêpug (n)",
    "y": " b. pepûk, -e"
  },
  {
    "x": "pêpûg (n)",
    "y": " b. pepûk, -e"
  },
  {
    "x": "pêqayîl bîyayene",
    "y": " b. pêqayîl bîyene"
  },
  {
    "x": "pêqayîl bîyene",
    "y": " beğenmek"
  },
  {
    "x": "pêqayîlbîyayîş (n)",
    "y": " beğenme"
  },
  {
    "x": "pêr-I (n)",
    "y": " baba\r\n~Pêrê mi mi ra va. (Babam bana söyledi.)"
  },
  {
    "x": "pêr-II (n)",
    "y": " b. perr (n)"
  },
  {
    "x": "pêr-III",
    "y": " b. pêro"
  },
  {
    "x": "pêr dayîne",
    "y": " b. pêro dayene"
  },
  {
    "x": "pêra cîyabîyene",
    "y": " ayrışmak"
  },
  {
    "x": "pêra cîyakerdene",
    "y": " ayrıştırmak"
  },
  {
    "x": "pêra dayene",
    "y": " b. pêro dayene"
  },
  {
    "x": "pêra disayene",
    "y": " b. pêra dusayene"
  },
  {
    "x": "pêra diskinayene",
    "y": " b. pêra duskînayene"
  },
  {
    "x": "pêra diskîyayene",
    "y": " b. pêra duskîyayene"
  },
  {
    "x": "pêra disknayene",
    "y": " b. pêra duskînayene"
  },
  {
    "x": "pêra disnayene",
    "y": " b. pêra dusnayene"
  },
  {
    "x": "pêra dusayene",
    "y": " birbirine yapışmak"
  },
  {
    "x": "pêra dusîyayene",
    "y": " b. pêra dusayene"
  },
  {
    "x": "pêra duskînayene",
    "y": " birbirine yapıştırmak"
  },
  {
    "x": "pêra duskîyayene",
    "y": " birbirine yapışmak"
  },
  {
    "x": "pêra dusnayene",
    "y": " birbirine yapıştırmak"
  },
  {
    "x": "pêra ewnîyayene",
    "y": " bakışmak"
  },
  {
    "x": "pêra girê dayena sehneyan (sn)",
    "y": " sahneleri birbirine bağlamak"
  },
  {
    "x": "pêra nayene",
    "y": " 1)birbirine yapıştırmak\r\n~2)bitiştirmek, birleştirmek"
  },
  {
    "x": "pêra neyayene",
    "y": " b. pêra nîyayene"
  },
  {
    "x": "pêra niştene",
    "y": " bitişmek"
  },
  {
    "x": "pêra nîştene",
    "y": " b. pêra niştene"
  },
  {
    "x": "pêra nîyayene",
    "y": " 1)birbirine yapışmak\r\n~2)bitişmek"
  },
  {
    "x": "pêra qemetîyayene",
    "y": " b. pêra qemitîyayene"
  },
  {
    "x": "pêra qemetnayene",
    "y": " b. pêra qemitnayene"
  },
  {
    "x": "pêra qemitîyayene",
    "y": " birbirine yapışmak"
  },
  {
    "x": "pêra qemitnayene",
    "y": " birbirine yapıştırmak"
  },
  {
    "x": "pêra temeqîyayene",
    "y": " b. pêra temiqîyayene"
  },
  {
    "x": "pêra temeqnayene",
    "y": " b. pêra qemitnayene"
  },
  {
    "x": "pêra temiqîyayene",
    "y": " b. pêra qemitîyayene"
  },
  {
    "x": "pêra temiqnayene",
    "y": " b. pêra qemitnayene"
  },
  {
    "x": "pêra vistene",
    "y": " çarpıştırmak"
  },
  {
    "x": "pêra zeleqîyayene",
    "y": "  b. pêra zeliqîyayene"
  },
  {
    "x": "pêra zeleqnayene",
    "y": " b. pêra zeliqnayene"
  },
  {
    "x": "pêra zeliqîyayene",
    "y": " birbirine yapışmak"
  },
  {
    "x": "pêra zeliqnayene",
    "y": " birbirine yapıştırmak"
  },
  {
    "x": "pêrabeste (n)",
    "y": " bileşik"
  },
  {
    "x": "pêrabestîye (m)",
    "y": " bileşik"
  },
  {
    "x": "pêrabestox, -e",
    "y": " bağlayıcı"
  },
  {
    "x": "pêrabestoxîye (m)",
    "y": " bağlayıcılık"
  },
  {
    "x": "pêracîyabîyayîş (n)",
    "y": " ayrışma"
  },
  {
    "x": "pêracîyabîyayîşo aerobîk (n)",
    "y": " aerobik ayrışma"
  },
  {
    "x": "pêracîyaker, -e",
    "y": " ayrıştırıcı"
  },
  {
    "x": "pêracîyakerdiş (n)",
    "y": " ayrıştırma"
  },
  {
    "x": "pêracîyakerdox, -e",
    "y": " ayrıştırıcı"
  },
  {
    "x": "pêradisayîş (n)",
    "y": " b. pêradusayîş (n)"
  },
  {
    "x": "pêradiskinayîş (n)",
    "y": " b. pêraduskînayîş (n)"
  },
  {
    "x": "pêradiskîyayîş (n)",
    "y": " b. pêraduskîyayîş (n)"
  },
  {
    "x": "pêradisknayîş (n)",
    "y": " b. pêraduskînayîş (n)"
  },
  {
    "x": "pêradisnayîş (n)",
    "y": " b. pêradusnayîş (n)"
  },
  {
    "x": "pêradusayîş (n)",
    "y": " birbirine yapışma"
  },
  {
    "x": "pêradusîyayîş (n)",
    "y": " b. pêradusayîş (n)"
  },
  {
    "x": "pêraduskînayîş (n)",
    "y": " birbirine yapıştırma"
  },
  {
    "x": "pêraduskîyayîş (n)",
    "y": " birbirine yapışma"
  },
  {
    "x": "pêradusnayîş (n)",
    "y": " birbirine yapıştırma"
  },
  {
    "x": "pêraewnîyayîş (n)",
    "y": " bakışma"
  },
  {
    "x": "pêramûn (n)",
    "y": " b. pîramûn (n)"
  },
  {
    "x": "pêranayîş (n)",
    "y": " 1)birbirine yapıştırma\r\n~2)bitiştirme, birleştirme"
  },
  {
    "x": "pêraneyayîş (n)",
    "y": " b. pêranîyayîş (n)"
  },
  {
    "x": "pêranişta (m)",
    "y": " bitişik"
  },
  {
    "x": "pêranişte (n)",
    "y": " bitişik"
  },
  {
    "x": "pêraniştiş (n)",
    "y": " bitişme"
  },
  {
    "x": "pêraniştî, -ye",
    "y": " bitişik"
  },
  {
    "x": "pêranîşta (m)",
    "y": " b. pêranişta (m)"
  },
  {
    "x": "pêranîşte (n)",
    "y": " b. pêranişte (n)"
  },
  {
    "x": "pêranîştiş (n)",
    "y": " b. pêraniştiş (n)"
  },
  {
    "x": "pêranîştî, -ye",
    "y": " b. pêraniştî, -ye"
  },
  {
    "x": "pêranîyaya (m)",
    "y": " bitişik"
  },
  {
    "x": "pêranîyaye (n)",
    "y": " bitişik"
  },
  {
    "x": "pêranîyayî, -ye",
    "y": " bitişik"
  },
  {
    "x": "pêranîyayîş (n)",
    "y": " birbirine yapışma, bitişme"
  },
  {
    "x": "pêraqemetîyayîş (n)",
    "y": " b. pêraqemitîyayîş (n)"
  },
  {
    "x": "pêraqemetnayîş (n)",
    "y": " b. pêraqemitnayîş (n)"
  },
  {
    "x": "pêraqemitîyayîş (n)",
    "y": " birbirine yapışma"
  },
  {
    "x": "pêraqemitnayîş (n)",
    "y": " birbirine yapıştırma"
  },
  {
    "x": "pêrar (n)",
    "y": " önceki yıl (geçen yıldan önceki yıl)"
  },
  {
    "x": "pêrare (m)",
    "y": " patika"
  },
  {
    "x": "pêrarey (n)",
    "y": " b. perarey (n)"
  },
  {
    "x": "pêrari (m)",
    "y": " b. pêrare (m)"
  },
  {
    "x": "pêratemeqîyayîş (n)",
    "y": " b. pêraqemitîyayîş (n)"
  },
  {
    "x": "pêratemeqnayîş (n)",
    "y": " b. pêraqemitnayîş (n)"
  },
  {
    "x": "pêratemiqîyayîş (n)",
    "y": " b. pêraqemitîyayîş (n)"
  },
  {
    "x": "pêratemiqnayîş (n)",
    "y": " b. pêraqemitnayîş (n)"
  },
  {
    "x": "pêravistiş (n)",
    "y": " çarpıştırma"
  },
  {
    "x": "pêrazeleqîyayîş (n)",
    "y": " b. pêrazeliqîyayîş (n)"
  },
  {
    "x": "pêrazeleqnayîş (n)",
    "y": " b. pêrazeliqnayîş (n)"
  },
  {
    "x": "pêrazeliqîyayîş (n)",
    "y": " birbirine yapışma"
  },
  {
    "x": "pêrazeliqnayîş (n)",
    "y": " birbirine yapıştırma"
  },
  {
    "x": "pêrdayîş (n)",
    "y": " b. pêrodayîş (n)"
  },
  {
    "x": "pêre",
    "y": " b. pêro"
  },
  {
    "x": "pêrepûn (n)",
    "y": " b. pîramûn (n)"
  },
  {
    "x": "pêresayene",
    "y": " birikmek"
  },
  {
    "x": "pêresayîş (n)",
    "y": " birikme"
  },
  {
    "x": "pêresnayene",
    "y": " 1)biriktirmek\r\n~2)(keçi, koyun, at, eşek için) çiftleştirmek"
  },
  {
    "x": "pêresnayîş (n)",
    "y": " biriktirme"
  },
  {
    "x": "pêrestene",
    "y": " b. pêresayene"
  },
  {
    "x": "pêreşteni",
    "y": " b. pêresayene"
  },
  {
    "x": "pêrey (m)",
    "y": " b. pêrî (m)"
  },
  {
    "x": "pêrênuştiş (n)",
    "y": " yazışma"
  },
  {
    "x": "pêri",
    "y": " b. pêro"
  },
  {
    "x": "pêri pîya",
    "y": " b. pêro pîya"
  },
  {
    "x": "pêri ameyene",
    "y": " b. pêrî ameyene"
  },
  {
    "x": "pêri ardene",
    "y": " b. pêrî ardene"
  },
  {
    "x": "pêriameyîş (n)",
    "y": " b. pêrîameyîş (n)"
  },
  {
    "x": "pêriardiş (n)",
    "y": " b. pêrîardiş (n)"
  },
  {
    "x": "pêridîyayene",
    "y": " beğenmek"
  },
  {
    "x": "pêridîyayîş (n)",
    "y": " beğenme"
  },
  {
    "x": "pêrige (m)",
    "y": " paravan, paravana"
  },
  {
    "x": "pêrikan",
    "y": " b. pêrune"
  },
  {
    "x": "pêriko",
    "y": " 1)b. pêro\r\n~2)b. pêrune"
  },
  {
    "x": "pêrikon",
    "y": " b. pêrune"
  },
  {
    "x": "pêrin-I (n)",
    "y": " b. pirên (n)"
  },
  {
    "x": "pêrin-II",
    "y": " b. pêrune"
  },
  {
    "x": "pêrine",
    "y": " b. pêrune"
  },
  {
    "x": "pêrini",
    "y": " b. pêrune"
  },
  {
    "x": "pêrinî",
    "y": " b. pêrune"
  },
  {
    "x": "pêrî (m)",
    "y": " babalık"
  },
  {
    "x": "pêrî ameyene",
    "y": " yüzleşmek"
  },
  {
    "x": "pêrî ardene",
    "y": " yüzleştirmek\r\n~Ez şima ana pêrî. (Sizi yüzleştiriyorum.)"
  },
  {
    "x": "pêrîameyîş (n)",
    "y": " yüzleşme"
  },
  {
    "x": "pêrîardiş (n)",
    "y": " yüzleştirme"
  },
  {
    "x": "pêrîn-I (n)",
    "y": " b. pirên (n)"
  },
  {
    "x": "pêrîn-timonî (zh)",
    "y": " b. pirên-tumanî (zh)"
  },
  {
    "x": "pêrîn-II",
    "y": " b. pêrune"
  },
  {
    "x": "pêrîne",
    "y": " b. pêrune"
  },
  {
    "x": "pêrînî",
    "y": " b. pêrune"
  },
  {
    "x": "pêrîsan, -e",
    "y": " b. perîşan, -e"
  },
  {
    "x": "pêrîşan, -e",
    "y": " b. perîşan, -e"
  },
  {
    "x": "pêrîşanê (m)",
    "y": " b. perîşanî (m)"
  },
  {
    "x": "pêrîşû",
    "y": " b. perîşan, -e"
  },
  {
    "x": "pêrîşûn",
    "y": " b. perîşan, -e"
  },
  {
    "x": "pêrîşûnê (m)",
    "y": " b. perîşanî (m)"
  },
  {
    "x": "pêrîşûnî (m)",
    "y": " b. perîşanî (m)"
  },
  {
    "x": "pêrîye (m)",
    "y": " babalık"
  },
  {
    "x": "pêrîyûne",
    "y": " b. pêrune"
  },
  {
    "x": "pêrkin",
    "y": " b. pêrune"
  },
  {
    "x": "pêrkina",
    "y": " b. pêrune"
  },
  {
    "x": "pêrkinan",
    "y": " b. pêrune"
  },
  {
    "x": "pêrko",
    "y": " b. pêro"
  },
  {
    "x": "pêrna",
    "y": " b. pêrune"
  },
  {
    "x": "pêro",
    "y": " 1)bütün, hep, hepsi, tüm, tümü\r\n~2)baştan başa"
  },
  {
    "x": "pêro barrayene",
    "y": " bağrışmak"
  },
  {
    "x": "pêro berizîyayene",
    "y": " bağrışmak"
  },
  {
    "x": "pêro birrayene",
    "y": " bağrışmak"
  },
  {
    "x": "pêro dayene",
    "y": " 1)dövüşmek, çarpışmak, boğuşmak \r\n~2)çatışmak \r\n~3)çarpıştırmak\r\n~Hesenî her di girewtî day pêro. (Hasan ikisini alıp çarpıştırdı./Hasan ikisini alıp birbirine çarptı.)"
  },
  {
    "x": "pêro dayêne",
    "y": " b. pêro dayene"
  },
  {
    "x": "pêro ginayene",
    "y": " çarpışmak"
  },
  {
    "x": "pêro gunayene",
    "y": " b. pêro ginayene"
  },
  {
    "x": "pêro pîya",
    "y": " hep birlikte, tümü birlikte"
  },
  {
    "x": "pêro qarayene",
    "y": " bağrışmak"
  },
  {
    "x": "pêro qîjayene",
    "y": " bağrışmak"
  },
  {
    "x": "pêro qîrayene",
    "y": " bağrışmak"
  },
  {
    "x": "pêro zirçayene",
    "y": " bağrışmak"
  },
  {
    "x": "pêrobarrayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "pêroberizîyayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "pêrobirrayîş (n)",
    "y": " bağrışma "
  },
  {
    "x": "pêrodayîs (n)",
    "y": " b. pêrodayîş (n)"
  },
  {
    "x": "pêrodayîş (n)",
    "y": " 1)dövüş, dövüşme, boğuşma, çarpışma\r\n~2)çatışma\r\n~3)çarpıştırma"
  },
  {
    "x": "pêroginayîş (n)",
    "y": " çarpışma"
  },
  {
    "x": "pêrogunayîş (n)",
    "y": " b. pêroginayîş (n)"
  },
  {
    "x": "pêrokî",
    "y": " toptan"
  },
  {
    "x": "pêroqarayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "pêroqîjayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "pêroqîrayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "pêroyên",
    "y": " b. pêrune"
  },
  {
    "x": "pêroyî, -ye",
    "y": " 1)genel, umumi\r\n~2)kamu, amme"
  },
  {
    "x": "pêroyîye rê cadeverdayîş",
    "y": " kamuya terk, yola terk"
  },
  {
    "x": "pêroyîye rê terk",
    "y": " kamuya terk, yola terk"
  },
  {
    "x": "pêroyîne",
    "y": " b. pêrune"
  },
  {
    "x": "pêrozirçayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "pêrr (n)",
    "y": " b. perr (n)"
  },
  {
    "x": "pêrsan, -e",
    "y": " b. perîşan, -e"
  },
  {
    "x": "pêrsanênî (m)",
    "y": " b. perîşanênî (m)"
  },
  {
    "x": "pêrsanîye (m)",
    "y": " b. perîşanîye (m)"
  },
  {
    "x": "pêrsayîş (n)",
    "y": " b. persayîş (n)"
  },
  {
    "x": "pêrşahî, -ye",
    "y": " ataerkil"
  },
  {
    "x": "pêrşahîye (m)",
    "y": " ataerkillik"
  },
  {
    "x": "Pêrtage (m)",
    "y": " Pertek"
  },
  {
    "x": "pêru",
    "y": " b. pêro"
  },
  {
    "x": "pêrune",
    "y": " hepsi, tümü; hepsini, tümünü"
  },
  {
    "x": "pêruni",
    "y": " b. pêrune"
  },
  {
    "x": "pêrunî",
    "y": " b. pêrune"
  },
  {
    "x": "pêrû",
    "y": " b. pêro"
  },
  {
    "x": "pêrû dayîne",
    "y": " b. pêro dayene"
  },
  {
    "x": "pêrûdayîş (n)",
    "y": " b. pêrodayîş (n)"
  },
  {
    "x": "pês (n)",
    "y": " b. pêş (n)"
  },
  {
    "x": "pêsê xo arî dayene",
    "y": " b. pêşê xo arê dayene"
  },
  {
    "x": "pêsaye (n)",
    "y": " b. pêşaye (n)"
  },
  {
    "x": "pêsayene-I",
    "y": " b. pêşayene"
  },
  {
    "x": "pêsayene-II",
    "y": " b. peysayene"
  },
  {
    "x": "pêsayêne",
    "y": " b. pêşayene"
  },
  {
    "x": "pêsayîş-I (n)",
    "y": " b. pêşayîş (n)"
  },
  {
    "x": "pêsayîş-II (n)",
    "y": " b. peysayîş (n)"
  },
  {
    "x": "pêsayîye (m)",
    "y": " b. pêşayîye (m)"
  },
  {
    "x": "pêse-I (m)",
    "y": " b. pêşe-I (m)"
  },
  {
    "x": "pêse-II (m)",
    "y": " b. pêş (n)"
  },
  {
    "x": "pêsa xo arê dayene",
    "y": " b. pêşê xo arê dayene"
  },
  {
    "x": "pêsa xo top kerdene",
    "y": " b. pêşê xo top kerdene"
  },
  {
    "x": "pêse, -ye-III",
    "y": " b. pêşe, -ye-II"
  },
  {
    "x": "pêsekerdiş (n)",
    "y": " b. pêserkerdiş (n)"
  },
  {
    "x": "pêser amayene",
    "y": " b. pêser ameyene"
  },
  {
    "x": "pêser ameyene",
    "y": " birikmek"
  },
  {
    "x": "pêser ardene",
    "y": " biriktirmek, devşirmek"
  },
  {
    "x": "pêser bîyayene",
    "y": " b. pêser bîyene"
  },
  {
    "x": "pêser bîyene",
    "y": " toplanmak, birikmek, devşirilmek"
  },
  {
    "x": "pêser kerdene",
    "y": " toplamak, biriktirmek, devşirmek; depolamak, ambarlamak"
  },
  {
    "x": "pêseramayîş (n)",
    "y": " b. pêserameyîş (n)"
  },
  {
    "x": "pêserameyîş (n)",
    "y": " birikme"
  },
  {
    "x": "pêserarde (n)",
    "y": " devşirme"
  },
  {
    "x": "pêserardiş (n)",
    "y": " biriktirme, devşirme"
  },
  {
    "x": "pêserbîyayîş (n)",
    "y": " toplanma, birikme, devşirilme"
  },
  {
    "x": "pêserî (m)",
    "y": " birikim"
  },
  {
    "x": "pêserkerdiş (n)",
    "y": " toplama, biriktirme, devşirme; depolama, ambarlama"
  },
  {
    "x": "pêsero",
    "y": " toptan (ticaret için)"
  },
  {
    "x": "pêserra kewtene",
    "y": " (birbiriyle) cinsel ilişkide bulunmak"
  },
  {
    "x": "pêserrakewtiş (n)",
    "y": " (birbiriyle) cinsel ilişkide bulunma"
  },
  {
    "x": "pêsewe û peroz",
    "y": " b. peroj û peşewe"
  },
  {
    "x": "pêsir (n)",
    "y": " etek (kadın eteği), fistan eteği"
  },
  {
    "x": "pêsîr (n)",
    "y": " 1)yaka\r\n~pêsîro gilover (n): yuvarlak yaka\r\n~2)b. pêsir (n)"
  },
  {
    "x": "pêsîrê çakêtî/setreyî/şakî (n)",
    "y": " ceket yakası"
  },
  {
    "x": "pêsîro gilover (n)",
    "y": " yuvarlak yaka"
  },
  {
    "x": "pêsîro tîk (n)",
    "y": " dik yaka (kazak vb. için)"
  },
  {
    "x": "pêsîye (m)",
    "y": " b. pêşeye"
  },
  {
    "x": "pêskar bîyayene",
    "y": " b. pêşkar bîyene"
  },
  {
    "x": "pêskarê … bîyayene",
    "y": " b. pêşkarê ... bîyene"
  },
  {
    "x": "pêskar bîyayêne",
    "y": " b. pêşkar bîyene"
  },
  {
    "x": "pêskarbîyayîş (n)",
    "y": " b. pêşkarbîyayîş (n)"
  },
  {
    "x": "pêskês (n)",
    "y": " b. pêşkêş (n)"
  },
  {
    "x": "pêskure (m)",
    "y": " b. pêşgîre (m)"
  },
  {
    "x": "pêsmale (m)",
    "y": " b. pêşmale (m)"
  },
  {
    "x": "pêsnayene-I",
    "y": " b. pêşnayene"
  },
  {
    "x": "pêsnayene-II",
    "y": " b. peysnayene"
  },
  {
    "x": "pêsnayêne",
    "y": " b. pêşnayene"
  },
  {
    "x": "pêsnayîs (n)",
    "y": " b. pêşnayîş (n)"
  },
  {
    "x": "pêsnayîş-I (n)",
    "y": " b. pêşnayîş (n)"
  },
  {
    "x": "pêsnayîş-II (n)",
    "y": " b. peysnayîş (n)"
  },
  {
    "x": "pêsor",
    "y": " b. pêser"
  },
  {
    "x": "pêstemale (m)",
    "y": " b. pêştemale (m)"
  },
  {
    "x": "pêş (n)",
    "y": " etek"
  },
  {
    "x": "pêşê fîstanî (n)",
    "y": " fistan eteği"
  },
  {
    "x": "pêşê xo arê dayene",
    "y": " eteğini toplamak "
  },
  {
    "x": "pêşê xo to kerdene",
    "y": " eteğini toplamak "
  },
  {
    "x": "pêşê xo top kerdene",
    "y": " eteğini toplamak "
  },
  {
    "x": "pêşanaya (m)",
    "y": " karışık"
  },
  {
    "x": "pêşanaye (n)",
    "y": " karışık\r\n~Nê ciwalî de xele/genim û cew pêşanaye yê. (Bu çuvalda buğday ve arpa karışıktır.)"
  },
  {
    "x": "pêşanayene",
    "y": " 1)karıştırmak (birbirine katmak), birbirine karıştırmak\r\n~Xele/genim û cewî pêşanîn. (Buğdayla arpayı (birbirine) karıştırınız.)\r\n~2)bitiştirmek"
  },
  {
    "x": "pêşanayîş (n)",
    "y": " 1)karıştırma (birbirine karıştırma)\r\n~2)bitiştirme"
  },
  {
    "x": "pêşanayîye (m)",
    "y": " karışık"
  },
  {
    "x": "pêşanîyayene",
    "y": " 1)karışmak (birbirine karışmak)\r\n~2)karıştırılmak (birbirine karıştırılmak)"
  },
  {
    "x": "pêşanîyayîş (n)",
    "y": " 1)karışma (birbirine karışma)\r\n~2)karıştırılma (birbirine karıştırılma)"
  },
  {
    "x": "pêşaya (m)",
    "y": " pişkin"
  },
  {
    "x": "pêşaye (n)",
    "y": " pişkin"
  },
  {
    "x": "pêşayene",
    "y": " pişmek"
  },
  {
    "x": "pêşayîş (n)",
    "y": " pişme"
  },
  {
    "x": "pêşayîye (m)",
    "y": " pişkin"
  },
  {
    "x": "pêşe-I (m)",
    "y": " 1)ağ, apışlık\r\n~2)b. pêş (n)"
  },
  {
    "x": "pêşe, -ye-II",
    "y": " yabancı, bigâne\r\n~No xort pêşe yo. (Bu delikanlı yabancıdır.)"
  },
  {
    "x": "pêşeng, -e",
    "y": " öncü, avangart"
  },
  {
    "x": "pêşewe û peroz",
    "y": " b. peroj û peşewe"
  },
  {
    "x": "pêşgîre (m)",
    "y": " mendil"
  },
  {
    "x": "pêşîyaye (çîyo) (n)",
    "y": " sızıntı (sızan şey)"
  },
  {
    "x": "pêşîyayîne",
    "y": " b. pêşîyene"
  },
  {
    "x": "pêşîyayîs (n)",
    "y": " b. pêşîyayîş (n)"
  },
  {
    "x": "pêşîyayîş (n)",
    "y": " sızdırma (tulum, testi, kova vb. için), -den akma (kova, tulum vb. için)"
  },
  {
    "x": "pêşîyene",
    "y": " sızdırmak (tulum, testi, kova vb. için), -den akmak (kova, tulum vb. için)\r\n~Na meşke pêşona. (Bu tulum [sıvı] sızdırıyor.)"
  },
  {
    "x": "pêşkar bîyene",
    "y": " karışmak (dokunmak)"
  },
  {
    "x": "pêşkarê ... bîyene",
    "y": " -e karışmak\r\n~Pêşkarê domanî mebe! (Çocuğa karışma!)"
  },
  {
    "x": "pêşkarbîyayîş (n)",
    "y": " karışma (dokunma)"
  },
  {
    "x": "pêşkêş (n)",
    "y": " sunum, sunu, arz"
  },
  {
    "x": "pêşkêş bîyayene",
    "y": " b. pêşkêş bîyene"
  },
  {
    "x": "pêşkêş bîyene",
    "y": " sunulmak"
  },
  {
    "x": "pêşkêş kerdene",
    "y": " sunmak"
  },
  {
    "x": "pêşkêşê rotişî bîyayîş",
    "y": " satışa sunulma"
  },
  {
    "x": "pêşkêşbîyayîş (n)",
    "y": " sunulma"
  },
  {
    "x": "pêşkêşkar, -e",
    "y": " sunucu, sunucu (TE)"
  },
  {
    "x": "pêşkêşkare (m)",
    "y": " kadın/kız sunucu"
  },
  {
    "x": "pêşkêşkerdiş (n)",
    "y": " sunma"
  },
  {
    "x": "pêşkîre (m)",
    "y": " b. pêşgîre (m)"
  },
  {
    "x": "pêşm (m)",
    "y": " b. peşm (n)"
  },
  {
    "x": "pêşmale (m)",
    "y": " peştamal"
  },
  {
    "x": "pêşmali (m)",
    "y": " b. pêşmale (m)"
  },
  {
    "x": "pêşmin, -e",
    "y": " b. peşmin, -e"
  },
  {
    "x": "pêşmyen",
    "y": " b. peşmên, -e, peşmin, -e"
  },
  {
    "x": "pêşnayene",
    "y": " pişirmek"
  },
  {
    "x": "pêşnayîş (n)",
    "y": " pişirme"
  },
  {
    "x": "pêşnîyaze (m)",
    "y": " öneri, teklif"
  },
  {
    "x": "pêşnîyaza qanûnî (m)",
    "y": " yasa önerisi, kanun teklifi"
  },
  {
    "x": "pêşnîyaza tezî (m)",
    "y": " tez önerisi"
  },
  {
    "x": "pêşonaya (m)",
    "y": " b. pêşanaya (m)"
  },
  {
    "x": "pêşonaye (n)",
    "y": " b. pêşanaye (n)"
  },
  {
    "x": "pêşonayene",
    "y": " b. pêşanayene"
  },
  {
    "x": "pêşonayîş (n)",
    "y": " b. pêşanayîş (n)"
  },
  {
    "x": "pêşonîyayene",
    "y": " b. pêşanîyayene"
  },
  {
    "x": "pêşonîyayîş (n)",
    "y": " b. pêşanîyayîş (n)"
  },
  {
    "x": "pêştemale (m)",
    "y": " peştamal"
  },
  {
    "x": "pêştene",
    "y": " b. pîştene"
  },
  {
    "x": "pêşûnaya (m)",
    "y": " b. pêşanaya (m)"
  },
  {
    "x": "pêşûnaye (n)",
    "y": " b. pêşanaye (n)"
  },
  {
    "x": "pêşûnayene",
    "y": " b. pêşanayene"
  },
  {
    "x": "pêşûnayîş (n)",
    "y": " b. pêşanayîş (n)"
  },
  {
    "x": "pêşûnîyayene",
    "y": " b. pêşanîyayene"
  },
  {
    "x": "pêşûnîyayîş (n)",
    "y": " b. pêşanîyayîş (n)"
  },
  {
    "x": "pêşyayîne",
    "y": " b. pêşîyene"
  },
  {
    "x": "pêşyayîş (n)",
    "y": " b. pêşîyayîş (n)"
  },
  {
    "x": "pêt, -e",
    "y": " 1)katı, berk, dayanıklı\r\n~2)canı pek\r\n~3)kıskıvrak, sıkı, sağlam\r\n~4)dinç, zinde\r\n~5)zengin, varlıklı\r\n~6)hızlı, süratli"
  },
  {
    "x": "pêt bîyayene",
    "y": " b. pêt bîyene"
  },
  {
    "x": "pêt bîyene",
    "y": " 1)sağlam olmak, sağlamlaşmak\r\n~2)dinç olmak, zinde olmak\r\n~Şarîbane pîr bîbî la hema pêt bî. (Şarîbane yaşlanmıştı ama henüz dinçti.)"
  },
  {
    "x": "pêt kerdene",
    "y": " sağlamlaştırmak, sıkılaştırmak, sıkıştırmak"
  },
  {
    "x": "pêtag (m)",
    "y": " b. pêtage (m)"
  },
  {
    "x": "pêtage (m)",
    "y": " kovan (arı kovanı)"
  },
  {
    "x": "pêtaga hemgênî (m)",
    "y": " b. pêtaga hingimênî (m)"
  },
  {
    "x": "pêtaga hingimênî (m)",
    "y": " kovan (arı kovanı)"
  },
  {
    "x": "pêtbîyayîş (n)",
    "y": " sağlam olma, sağlamlaşma"
  },
  {
    "x": "pêtey (m)",
    "y": " b. pêtî (m), pêtîye (m)"
  },
  {
    "x": "pêti (m)",
    "y": " b. pête (m)"
  },
  {
    "x": "pêtî (m)",
    "y": " 1)sağlamlık\r\n~2)zenginlik, varlık, varlıklılık\r\n~3)hızlılık"
  },
  {
    "x": "pêtîye (m)",
    "y": " 1)sağlamlık\r\n~2)zenginlik, varlık, varlıklılık\r\n~3)hızlılık"
  },
  {
    "x": "pêtkerdiş (n)",
    "y": " sağlamlaştırma, sıkılaştırma, sıkıştırma"
  },
  {
    "x": "pêtkerdişê çermî (n)",
    "y": " cildi sıkılaştırma"
  },
  {
    "x": "pêveradayîş (n)",
    "y": " boşanma"
  },
  {
    "x": "pêverare",
    "y": " kucak kucağa"
  },
  {
    "x": "pêverdayîş (n)",
    "y": " 1)boşanma\r\n~2)b. pêwverdayîş (n)"
  },
  {
    "x": "pêvereşte (n)",
    "y": " karışım (birden fazla yiyecek maddesinin karıştırılıp birlikte pişirilmesiyle hazırlanan yemek)"
  },
  {
    "x": "pêveronayîş (n)",
    "y": " karşılaştırma, mukayese"
  },
  {
    "x": "pêverûnayîş (n)",
    "y": " b. pêveronayîş (n)"
  },
  {
    "x": "pêviradayîş (n)",
    "y": " b. pêveradayîş (n)"
  },
  {
    "x": "pêvirare",
    "y": " b. pêverare"
  },
  {
    "x": "pêvirari",
    "y": " b. pêverare"
  },
  {
    "x": "pêvirîn (n)",
    "y": " b. pêvurîn (n)"
  },
  {
    "x": "pêvurîn (n)",
    "y": " değiş tokuş, değişim, mübadele, takas, trampa"
  },
  {
    "x": "pêw",
    "y": " b. pêwa"
  },
  {
    "x": "pêw verdayene",
    "y": " çarpıştırmak"
  },
  {
    "x": "pêwa",
    "y": " hepsi"
  },
  {
    "x": "pêwer (n)",
    "y": " katık"
  },
  {
    "x": "pêwi",
    "y": " b. pêwa"
  },
  {
    "x": "pêwverdayîş (n)",
    "y": " çarpıştırma"
  },
  {
    "x": "pêxamber (n)",
    "y": " peygamber"
  },
  {
    "x": "pêxamberê (m)",
    "y": " b. pêxamberîye (m), pêxamberî (m)"
  },
  {
    "x": "pêxamberî (m)",
    "y": " peygamberlik"
  },
  {
    "x": "pêxamberîye (m)",
    "y": " peygamberlik"
  },
  {
    "x": "pêxember (n)",
    "y": " b. pêxamber (n)"
  },
  {
    "x": "pêxemberey (m)",
    "y": " b. pêxamberîye (m), pêxamberî (m)"
  },
  {
    "x": "pêxemberê (m)",
    "y": " b. pêxamberîye (m), pêxamberî (m)"
  },
  {
    "x": "pêxemberî (m)",
    "y": " b. pêxamberîye (m), pêxamberî (m)"
  },
  {
    "x": "pêxmer (n)",
    "y": " b. pêxamber (n)"
  },
  {
    "x": "pêxmerî (m)",
    "y": " b. pêxamberîye (m), pêxamberî (m)"
  },
  {
    "x": "pêy-I (nm, m)",
    "y": " b. pey-I (nm, m)"
  },
  {
    "x": "pêy de",
    "y": " b. pey de"
  },
  {
    "x": "pêy ra",
    "y": " b. pey ra"
  },
  {
    "x": "pêy ... de",
    "y": " b. pey ... de"
  },
  {
    "x": "pêy-II (nm & s, n)",
    "y": " b. pey-II (nm & s, n)"
  },
  {
    "x": "pêy co",
    "y": " b. peyê cû"
  },
  {
    "x": "pêy do",
    "y": " b. peyê cû"
  },
  {
    "x": "pêyê conî",
    "y": " b. peyê cû"
  },
  {
    "x": "pêyê coy",
    "y": " b. peyê cû"
  },
  {
    "x": "pêyê coyî",
    "y": " b. peyê cû"
  },
  {
    "x": "pêyê cû",
    "y": " b. peyê cû "
  },
  {
    "x": "pêyê cûnî",
    "y": " b. peyê cû"
  },
  {
    "x": "pêya aşt bîyene",
    "y": " (birbiriyle) cinsel ilişkide bulunmak, sevişmek"
  },
  {
    "x": "pêya haşt bîyayene",
    "y": " b. pêya aşt bîyene"
  },
  {
    "x": "pêya nayene",
    "y": " bitiştirme"
  },
  {
    "x": "pêya neyayene",
    "y": " b. pêya nîyayene"
  },
  {
    "x": "pêya neyayeni",
    "y": " b. pêya nîyayene"
  },
  {
    "x": "pêya nîyayene",
    "y": " bitişmek"
  },
  {
    "x": "pêyaaştbîyayîş (n)",
    "y": " (birbiriyle) cinsel ilişkide bulunma, sevişme"
  },
  {
    "x": "pêyanayîş (n)",
    "y": " bitiştirme"
  },
  {
    "x": "pêyaneyayî, -ye",
    "y": " b. pêyanîyayî, -ye"
  },
  {
    "x": "pêyaneyayîş (n)",
    "y": " b. pêyanîyayîş (n)"
  },
  {
    "x": "pêyanîyaya (m)",
    "y": " bitişik"
  },
  {
    "x": "pêyanîyaye (n)",
    "y": " bitişik"
  },
  {
    "x": "pêyanîyayî, -ye",
    "y": " bitişik"
  },
  {
    "x": "pêyanîyayîş (n)",
    "y": " bitişme"
  },
  {
    "x": "pêyda kerdene",
    "y": " b. peyda kerdene"
  },
  {
    "x": "pêydakerdiş (n)",
    "y": " b. peydakerdiş (n)"
  },
  {
    "x": "pêye (n)",
    "y": " 1. b. peye (n) 2. b. pîyade (n)"
  },
  {
    "x": "pêyeke (m)",
    "y": " b. peyîke (m)"
  },
  {
    "x": "pêyen, -e",
    "y": " b. peyên, -e"
  },
  {
    "x": "pêyê (n)",
    "y": " b. peyên (n)"
  },
  {
    "x": "pêyên, -e",
    "y": " b. peyên, -e"
  },
  {
    "x": "pêyi (n)",
    "y": " b. peye (n)"
  },
  {
    "x": "pêyike (m)",
    "y": " b. peyîke (m)"
  },
  {
    "x": "pêyin (n)",
    "y": " b. peyên (n)"
  },
  {
    "x": "pêyîke (m)",
    "y": " b. peyîke (m)"
  },
  {
    "x": "pêyîn (n)",
    "y": " b. peyên (n)"
  },
  {
    "x": "pêynî (m)",
    "y": " b. peynî (m)"
  },
  {
    "x": "pêynî ardene",
    "y": " b. peynî ardene"
  },
  {
    "x": "pêynîye (m)",
    "y": " b. peynîye (m) "
  },
  {
    "x": "pêynîye ardene",
    "y": " b. peynîye ardene "
  },
  {
    "x": "pêyser",
    "y": " b. peyser"
  },
  {
    "x": "pêyser de",
    "y": " b. peyser de"
  },
  {
    "x": "pêyser şîyayêne",
    "y": " b. peyser şîyene"
  },
  {
    "x": "pêyxember (n)",
    "y": " b. pêxamber (n)"
  },
  {
    "x": "pêz (n)",
    "y": " b. bêz (n)"
  },
  {
    "x": "pêz kerdene",
    "y": " b. bêz kerdene"
  },
  {
    "x": "pêzanayene",
    "y": " haberdar olmak"
  },
  {
    "x": "pêzkerdiş (n)",
    "y": " b. bêzkerdiş (n)"
  },
  {
    "x": "PH metre (Power of Hidrojen meter)",
    "y": " PH metre"
  },
  {
    "x": "PH-peym",
    "y": " PH ölçer"
  },
  {
    "x": "Phnom Penh (n)",
    "y": " Pnom Penh"
  },
  {
    "x": "pi kerdeni",
    "y": " b. pu kerdene"
  },
  {
    "x": "piçe",
    "y": " biraz "
  },
  {
    "x": "piçê",
    "y": " b. piçe"
  },
  {
    "x": "piçêke",
    "y": " b. piçe"
  },
  {
    "x": "pidî (m)",
    "y": " b. pudîye (m)"
  },
  {
    "x": "pihan, -e",
    "y": " b. pan, -e"
  },
  {
    "x": "pihanîye (m)",
    "y": " b. panîye (m)"
  },
  {
    "x": "pihêt, -i",
    "y": " b. pêt, -e"
  },
  {
    "x": "pihêt bîyayene",
    "y": " b. pêt bîyene"
  },
  {
    "x": "pihêt kerdene",
    "y": " b. pêt kerdene"
  },
  {
    "x": "pihon, -i",
    "y": " b. pan, -e"
  },
  {
    "x": "pihonê (m)",
    "y": " b. panîye (m)"
  },
  {
    "x": "pihonî (m)",
    "y": " b. panîye (m)"
  },
  {
    "x": "pihûn, -i",
    "y": " b. pan, -e"
  },
  {
    "x": "pihûnê (m)",
    "y": " b. panîye (m)"
  },
  {
    "x": "pihûnî (m)",
    "y": " b. panîye (m)"
  },
  {
    "x": "pijik (m)",
    "y": " b. pizrike (m)"
  },
  {
    "x": "pijiqîyayene",
    "y": " püskürmek"
  },
  {
    "x": "pijiqîyayîş (n)",
    "y": " püskürme"
  },
  {
    "x": "pijiqîyayîşê lavî (n)",
    "y": " lav püskürmesi"
  },
  {
    "x": "pijiqîyayîşê volkanî (n)",
    "y": " yanardağ püskürmesi"
  },
  {
    "x": "pijiqnayene",
    "y": " püskürtmek "
  },
  {
    "x": "pijiqnayîş (n)",
    "y": " püskürtme"
  },
  {
    "x": "pijrûgî (zh)",
    "y": " içyağının kızartılmasıyla meydana gelen bir yiyecek"
  },
  {
    "x": "pikerdiş (n)",
    "y": " b. pukerdiş (n)"
  },
  {
    "x": "pil-I (n)",
    "y": " köprü"
  },
  {
    "x": "pil-II (n)",
    "y": " b. pêl (n)"
  },
  {
    "x": "pil û purt",
    "y": " eşya"
  },
  {
    "x": "pil-purt (n)",
    "y": " eşya"
  },
  {
    "x": "pilar (n)",
    "y": " etene, meşime, son"
  },
  {
    "x": "pilarin, -e",
    "y": " beceriksiz"
  },
  {
    "x": "pilarinênî (m)",
    "y": " beceriksizlik"
  },
  {
    "x": "pilarinîye (m)",
    "y": " beceriksizlik"
  },
  {
    "x": "pilas (n)",
    "y": " b. palas (n)"
  },
  {
    "x": "pilaxnayîne",
    "y": " b. pelixnayene"
  },
  {
    "x": "pilaxnayîş (n) ",
    "y": " b. pelixnayîş (n)"
  },
  {
    "x": "pilayene",
    "y": " gevelemek (söz için)"
  },
  {
    "x": "pilayîş (n)",
    "y": " geveleme (söz için)"
  },
  {
    "x": "pile (m)",
    "y": " çelik çomak"
  },
  {
    "x": "Pilemurîye (m)",
    "y": " Pülümür"
  },
  {
    "x": "pilepile kerdene",
    "y": " b. pilpil kerdene"
  },
  {
    "x": "pilesik (n)",
    "y": " b. bilusk (n)"
  },
  {
    "x": "pilêş (n)",
    "y": " 1)b. pêş (n)\r\n~2)pêsir (n)"
  },
  {
    "x": "pilêşê xo to kerdene",
    "y": " b. pêşê xo to kerdene"
  },
  {
    "x": "pilik-I (n)",
    "y": " çok ince bulgur"
  },
  {
    "x": "pilik-II (n)",
    "y": " b. peltik (n)"
  },
  {
    "x": "pilikê şowqî (m)",
    "y": " b. peltikê şewqa (n)"
  },
  {
    "x": "pilik-III (m)",
    "y": " b. pilike"
  },
  {
    "x": "pilik bîyayene",
    "y": " b. pilike bîyene"
  },
  {
    "x": "pilik dayene",
    "y": " b. pilike dayene"
  },
  {
    "x": "pilik kerdene",
    "y": " b. pilike kerdene"
  },
  {
    "x": "pilike (m)",
    "y": " 1)ibik\r\n~2)kabarcık (tıpta)"
  },
  {
    "x": "pilike bîyayene",
    "y": " b. pilike bîyene"
  },
  {
    "x": "pilike bîyene",
    "y": " kabarcık oluşmak (tıpta), şıklak olmak"
  },
  {
    "x": "pilike dayene",
    "y": " kabarcık oluşmak (tıpta), şıklak olmak"
  },
  {
    "x": "pilike kerdene",
    "y": " kabarcık oluşturmak (tıpta)"
  },
  {
    "x": "piliki (m)",
    "y": " b. peltik (n)"
  },
  {
    "x": "pilikey şewqa (m)",
    "y": " b. peltikê şewqa (n)"
  },
  {
    "x": "piling, -e-I",
    "y": " kaplan "
  },
  {
    "x": "piling-II (n)",
    "y": " kor"
  },
  {
    "x": "pilint, -e",
    "y": " pis, kirli"
  },
  {
    "x": "pilintey (m)",
    "y": " b. pilintî (m)"
  },
  {
    "x": "pilinti (m)",
    "y": " b. pilinte (m)"
  },
  {
    "x": "pilintî (m)",
    "y": " pislik (pis olma durumu), kirlilik"
  },
  {
    "x": "pilis, -e",
    "y": " b. polîs, -e"
  },
  {
    "x": "pilixnayîne",
    "y": " b. pelixnayene"
  },
  {
    "x": "piliznayîne",
    "y": " b. piloznayene"
  },
  {
    "x": "pilîş-I (n)",
    "y": " b. pêş (n), pêsir (n)"
  },
  {
    "x": "pilîş-II (n)",
    "y": " b. pilîşk (n)"
  },
  {
    "x": "pilîşk (n)",
    "y": " çapak"
  },
  {
    "x": "pilîşk girewtene",
    "y": " çapaklanmak"
  },
  {
    "x": "pilîşk guretene",
    "y": " b. pilîşk girewtene "
  },
  {
    "x": "pilîşkgirewtiş (n)",
    "y": " çapaklanış, çapaklanma"
  },
  {
    "x": "pilîxayene",
    "y": " b. pelixîyayene"
  },
  {
    "x": "pilîxnayene",
    "y": " b. pelixnayene"
  },
  {
    "x": "pilîxnayîş (n)",
    "y": " b. pelixnayîş (n)"
  },
  {
    "x": "pilîyayene",
    "y": " homurdanmak"
  },
  {
    "x": "pillik (n)",
    "y": " b. peltik (n)"
  },
  {
    "x": "pillikê şewqî (n)",
    "y": " b. peltikê şewqa (n)"
  },
  {
    "x": "pillik (m)",
    "y": " b. pilike (m)"
  },
  {
    "x": "pillik bîyayene",
    "y": " b. pilike bîyene"
  },
  {
    "x": "pillik dayene",
    "y": " b. pilike dayene"
  },
  {
    "x": "pillik kerdene",
    "y": " b. pilike kerdene "
  },
  {
    "x": "pilol (m)",
    "y": " b. pilole (m)"
  },
  {
    "x": "pilole (m)",
    "y": " bulamaç (sulu, cıvık hamur)\r\n~“Siûdê kesî [ke] çin bi, dindanê kesî pilole di şikîyênê.” (vateyê verênan) (Şanssız kişinin dişleri bulamaç yerken [bile] kırılır.) (atasözü)"
  },
  {
    "x": "pilola sûre (m)",
    "y": " su ve undan yapılan, içine yağ ve pekmez katılarak da yenebilen bulamaç"
  },
  {
    "x": "pilolo (n)",
    "y": " saklambaç (oyunu)"
  },
  {
    "x": "pilor-I (n)",
    "y": " b. pilole (m)"
  },
  {
    "x": "pilor-II (n)",
    "y": " b. pilar (n)"
  },
  {
    "x": "pilorin, -e",
    "y": " b. pilarin, -e"
  },
  {
    "x": "pilorinênî (m)",
    "y": " b. pilarinênî (m)"
  },
  {
    "x": "pilorinîye (m)",
    "y": " b. pilarinîye (m)"
  },
  {
    "x": "pilozîyayene",
    "y": " ezilmek (üzüm vb. için)"
  },
  {
    "x": "piloznayene",
    "y": " 1)ezmek (üzüm vb. için)\r\n~2)basmak"
  },
  {
    "x": "piloznayîş (n)",
    "y": " ezme "
  },
  {
    "x": "pilpil kerdene",
    "y": " homurdanmak"
  },
  {
    "x": "pilpilîyayene",
    "y": " gevelemek (söz için)"
  },
  {
    "x": "pilpilîyayîş (n)",
    "y": " geveleme (söz için)"
  },
  {
    "x": "pilpilok (n)",
    "y": " b. pelpelike (m)"
  },
  {
    "x": "pilqik (m)",
    "y": " b. pelqike (m)"
  },
  {
    "x": "pilqik bîyayene",
    "y": " b. pelqike bîyene"
  },
  {
    "x": "pilqik kerdene",
    "y": " b. pelqike kerdene"
  },
  {
    "x": "pilx (m)",
    "y": " b. pilxe (m)"
  },
  {
    "x": "pilxe (m)",
    "y": " sümük"
  },
  {
    "x": "pimpar (n)",
    "y": " b. pirpar (n)"
  },
  {
    "x": "pin (n)",
    "y": " b. pîn (n)"
  },
  {
    "x": "pinanikî",
    "y": " gizlice"
  },
  {
    "x": "pinanî",
    "y": " gizli, gizlice"
  },
  {
    "x": "pincar (n)",
    "y": " yenilebilen semizotu vb. otsu bitkilerin genel adı"
  },
  {
    "x": "pincike (m)",
    "y": " b. pirnike (m)"
  },
  {
    "x": "pincirare (m)",
    "y": " b. pincirarî (m)"
  },
  {
    "x": "pincirarî (m)",
    "y": " yorgan iğnesi"
  },
  {
    "x": "pind (n)",
    "y": " gonca, konca, tomurcuk"
  },
  {
    "x": "pind dayene",
    "y": " tomurcuklanmak"
  },
  {
    "x": "pindik (n)",
    "y": " gonca, konca, tomurcuk"
  },
  {
    "x": "pindosîyene",
    "y": " b. pendusayene"
  },
  {
    "x": "pindosnayene",
    "y": " b. pendusnayene"
  },
  {
    "x": "pingil (n)",
    "y": " b. piling-II (n)"
  },
  {
    "x": "pinik (n)",
    "y": " b. pînik (n)"
  },
  {
    "x": "pinonikî",
    "y": " b. pinanikî"
  },
  {
    "x": "pinonî",
    "y": " b. pinanî"
  },
  {
    "x": "pinpinik (n)",
    "y": " b. pinpinîke (m)"
  },
  {
    "x": "pinpinîke (m)",
    "y": " kelebek"
  },
  {
    "x": "pintî",
    "y": " b. pintîn, -e"
  },
  {
    "x": "pintîn, -e",
    "y": " pis, kirli"
  },
  {
    "x": "pintîney (m)",
    "y": " b. pintînî (m) "
  },
  {
    "x": "pintînî (m)",
    "y": " pislik (pis olma durumu), kirlilik"
  },
  {
    "x": "pir (n)",
    "y": " b. pird (n)"
  },
  {
    "x": "pir, -i",
    "y": " b. pirr, -e"
  },
  {
    "x": "pir kerdene",
    "y": " b. pirr kerdene"
  },
  {
    "x": "pira cêrîyayene",
    "y": " b. pira gîrîyayene"
  },
  {
    "x": "pira dayene",
    "y": " 1)üstüne örtmek\r\n~2)giydirmek (giysi için)"
  },
  {
    "x": "pira dîyayene",
    "y": " giydirilmek (giysi için)"
  },
  {
    "x": "pira gêrîyayene",
    "y": " b. pira gîrîyayene"
  },
  {
    "x": "pira girewtene",
    "y": " giymek (giysi için)"
  },
  {
    "x": "pira girotene",
    "y": " b. pira girewtene"
  },
  {
    "x": "pira gîrîyayene",
    "y": " giyilmek (giysi için)\r\n~Roşan de kincê/cilê neweyî pira gîrîyênê. (Bayramda yeni giysiler giyilir.)"
  },
  {
    "x": "pira guretene",
    "y": " b. pira girewtene"
  },
  {
    "x": "pira kerdene",
    "y": " 1)-e sürmek\r\n~2)b. pakerdene"
  },
  {
    "x": "pira merdene",
    "y": " çok yakışmak (mecazi)\r\n~\tPira merd. (Çok yakıştı.)"
  },
  {
    "x": "pira nayene",
    "y": " 1)-e yapıştırmak\r\n~2)b. panayene (n)"
  },
  {
    "x": "pira niştene",
    "y": " -e yapışmak"
  },
  {
    "x": "pira nîştene",
    "y": " b. pira niştene"
  },
  {
    "x": "pira nîyayene",
    "y": " -e yapışmak"
  },
  {
    "x": "pira qemetîyayene",
    "y": " b. pira qemitîyayene"
  },
  {
    "x": "pira qemetnayene",
    "y": " b. pira qemitnayene"
  },
  {
    "x": "pira qemitîyayene",
    "y": " -e yapışmak"
  },
  {
    "x": "pira qemitnayene",
    "y": " -e yapıştırmak"
  },
  {
    "x": "pira soynayene",
    "y": " -e sürmek"
  },
  {
    "x": "pira soynîyayene",
    "y": " -e sürülmek"
  },
  {
    "x": "pira şanayene",
    "y": " -e serpmek"
  },
  {
    "x": "pira şîyayene",
    "y": " b. ... ra şîyene"
  },
  {
    "x": "pira şonayîne",
    "y": " b. pira şanayene"
  },
  {
    "x": "pira temeqîyayene",
    "y": " b. pira qemitîyayene"
  },
  {
    "x": "pira temeqnayene",
    "y": " b. pira qemitnayene"
  },
  {
    "x": "pira temiqîyayene",
    "y": " b. pira qemitîyayene"
  },
  {
    "x": "pira temiqnayene",
    "y": " b. pira qemitnayene"
  },
  {
    "x": "pira zeleqîyayene",
    "y": " b. pira zeliqîyayene"
  },
  {
    "x": "pira zeleqnayene",
    "y": " b. pira zeliqnayene"
  },
  {
    "x": "pira zeliqîyayene",
    "y": " -e yapışmak"
  },
  {
    "x": "pira zeliqnayene",
    "y": " -e yapıştırmak"
  },
  {
    "x": "pira-pira",
    "y": " giderek, gittikçe, yavaş yavaş"
  },
  {
    "x": "piracêrîyayîş (n)",
    "y": " b. piragîrîyayîş (n)"
  },
  {
    "x": "piradayîş (n)",
    "y": " 1)üstüne örtme\r\n~2)giydirme (giysi için)"
  },
  {
    "x": "piradîyayîş (n)",
    "y": " giydirilme (giysi için)"
  },
  {
    "x": "piragêrîyayîş (n)",
    "y": " b. piragîrîyayîş (n)"
  },
  {
    "x": "piragirewtiş (n)",
    "y": " giyme (giysi için)"
  },
  {
    "x": "piragirotiş (n)",
    "y": " b. piragirewtiş (n)"
  },
  {
    "x": "piragîrîyayîş (n)",
    "y": " giyilme (giysi için)"
  },
  {
    "x": "piraguretiş (n)",
    "y": " b. piragirewtiş (n)"
  },
  {
    "x": "piragurotiş (n)",
    "y": " b. piragirewtiş (n)"
  },
  {
    "x": "pirakerde (n)",
    "y": " sürülü"
  },
  {
    "x": "pirakerdiş (n)",
    "y": " b. pakerdiş (n)"
  },
  {
    "x": "pirakerdîye (m)",
    "y": " sürülü"
  },
  {
    "x": "piramûd (n)",
    "y": " b. pîramûn (n)"
  },
  {
    "x": "piran (n)",
    "y": " lokma"
  },
  {
    "x": "piranan dima bîyene",
    "y": " yemek peşinde olmak (çıkar peşinde olmak)"
  },
  {
    "x": "piranayîş (n)",
    "y": " 1)-e yapıştırma\r\n~2)b. panayîş"
  },
  {
    "x": "piraniştiş (n)",
    "y": " -e yapışma"
  },
  {
    "x": "piranîştiş (n)",
    "y": " b. piraniştiş (n)"
  },
  {
    "x": "piranîyayîş (n)",
    "y": " -e yapışma"
  },
  {
    "x": "piraqemetîyayîş (n)",
    "y": " b. piraqemitîyayîş (n)"
  },
  {
    "x": "piraqemetnayîş (n)",
    "y": " b. piraqemitnayîş (n)"
  },
  {
    "x": "piraqemitîyayîş (n)",
    "y": " -e yapışma"
  },
  {
    "x": "piraqemitnayîş (n)",
    "y": " -e yapıştırma"
  },
  {
    "x": "pirarê (n)",
    "y": " b. perarey (n)"
  },
  {
    "x": "pirasa (n)",
    "y": " b. pirase (n) "
  },
  {
    "x": "pirase (n)",
    "y": " pırasa "
  },
  {
    "x": "piraşanayîş (n)",
    "y": " -e serpme"
  },
  {
    "x": "piraşîyayîş (n)",
    "y": " b. ... raşîyayîş"
  },
  {
    "x": "piraynaya (m)",
    "y": " b. pirênaya (m), pirênayîye (m)"
  },
  {
    "x": "piraynaye (n)",
    "y": " b. pirênaye (n)"
  },
  {
    "x": "piraşonayîş (n)",
    "y": " b. piraşanayîş (n)"
  },
  {
    "x": "piratemeqîyayîş (n)",
    "y": " b. piraqemitîyayîş (n)"
  },
  {
    "x": "piratemeqnayîş (n)",
    "y": " b. piraqemitnayîş (n)"
  },
  {
    "x": "piratemiqîyayîş (n)",
    "y": " b. piraqemitîyayîş (n)"
  },
  {
    "x": "piratemiqnayîş (n)",
    "y": " b. piraqemitnayîş (n)"
  },
  {
    "x": "piraynayene",
    "y": " b. pirênayene"
  },
  {
    "x": "piraynayîş (n)",
    "y": " b. pirênayîş (n)"
  },
  {
    "x": "pirazeleqîyayîş (n)",
    "y": " b. pirazeliqîyayîş (n)"
  },
  {
    "x": "pirazeleqnayîş (n)",
    "y": " b. pirazeliqnayîş (n)"
  },
  {
    "x": "pirazeliqîyayîş (n)",
    "y": " -e yapışma"
  },
  {
    "x": "pirazeliqnayîş (n)",
    "y": " -e yapıştırma"
  },
  {
    "x": "pircin (m)",
    "y": " b. pirojine (m)"
  },
  {
    "x": "pircini (m)",
    "y": " b. pirojine (m) "
  },
  {
    "x": "pircûn (m)",
    "y": " b. pirojine (m)"
  },
  {
    "x": "pirç (n)",
    "y": " yün, yapak, yapağı"
  },
  {
    "x": "pirç girewtiş",
    "y": " kıl alma"
  },
  {
    "x": "pirçên, -e",
    "y": " yünlü (yünden yapılmış olan)"
  },
  {
    "x": "pirçikin (m)",
    "y": " b. pirçikine (m)"
  },
  {
    "x": "pirçikine (m)",
    "y": " şeftali"
  },
  {
    "x": "pirçiknayene",
    "y": " 1)yolmak (tüy vb. için)\r\n~2)tiftiklemek"
  },
  {
    "x": "pirçiknayêne",
    "y": " b. pirçiknayene"
  },
  {
    "x": "pirçiknayîş (n)",
    "y": " yolma (tüy vb. için)"
  },
  {
    "x": "pirçin, -e",
    "y": " yünlü (içinde yün de bulunan)"
  },
  {
    "x": "pird (n)",
    "y": " köprü"
  },
  {
    "x": "pird (m)",
    "y": " b. pirde-I (m)"
  },
  {
    "x": "pirde-I (m)",
    "y": " binanın çatısını taşıması için tavanda yatay olarak kullanılan kalın direk"
  },
  {
    "x": "pirda muşeneyî (m)",
    "y": " dövenin tahtalarını birbirine bağlayan ve onlara enine çakılmış olan çıta"
  },
  {
    "x": "pirdê muşeneyî (m)",
    "y": " b. pirda muşeneyî (m)"
  },
  {
    "x": "pirde-II",
    "y": " hemen, anında, birdenbire"
  },
  {
    "x": "pirde merdene",
    "y": " hemen ölmek, anında ölmek"
  },
  {
    "x": "pirdi-I (m)",
    "y": " b. pirde-I (m)"
  },
  {
    "x": "pirdi-II",
    "y": " b. pirde-II"
  },
  {
    "x": "pirdi merdene",
    "y": " b. pirde merdene"
  },
  {
    "x": "pirêdayîş (n)",
    "y": " b. pirodayîş (n)"
  },
  {
    "x": "pirên (n)",
    "y": " entari (gecelik entari)"
  },
  {
    "x": "pirên kewtene",
    "y": " âdet görmek, (kadın) aybaşı olmak"
  },
  {
    "x": "pirênê domanî (n)",
    "y": " döleşi, etene, plasenta"
  },
  {
    "x": "pirênê marî (m)",
    "y": " kav (yılan gömleği, yılan derisi)"
  },
  {
    "x": "pirênî (zh)",
    "y": " çamaşır"
  },
  {
    "x": "pirên-pirênbazî (zh)",
    "y": " gecelik (kadın için), pijama (kadın için)"
  },
  {
    "x": "pirên-tumanî (zh)",
    "y": " gecelik (erkek için), pijama (erkek için)"
  },
  {
    "x": "pirênaya (m)",
    "y": " kavrulmuş olan (nohut vb. için)"
  },
  {
    "x": "pirênaye (n)",
    "y": " kavrulmuş olan (nohut vb. için)"
  },
  {
    "x": "pirênayene",
    "y": " kavurmak, kızartmak (ekmek vb. için)"
  },
  {
    "x": "pirênayîş (n)",
    "y": " kavurma, kızartma (ekmek vb. için)"
  },
  {
    "x": "pirênayîye (m)",
    "y": " kavrulmuş olan (nohut vb. için), kızartılmış olan (ekmek vb. için)"
  },
  {
    "x": "pirênbazî (zh)",
    "y": " don (uzun kadın donu)"
  },
  {
    "x": "pirênî (zh)",
    "y": " çamaşır"
  },
  {
    "x": "pirênkewtiş (n)",
    "y": " âdet görme, (kadın) aybaşı olma"
  },
  {
    "x": "pirêntaq, -e",
    "y": " üzerinde sadece entari (gece entarisi) bulunan"
  },
  {
    "x": "pirêntaqi (m)",
    "y": " b. pirêntaqe (m)"
  },
  {
    "x": "pirêze (n)",
    "y": " b. firêze (n)"
  },
  {
    "x": "piri dayene",
    "y": " b. piro dayene"
  },
  {
    "x": "piridayîş (n)",
    "y": " b. pirodayîş (n)"
  },
  {
    "x": "pirin, -e",
    "y": " b. pirûzin, -e"
  },
  {
    "x": "pirinaya (m)",
    "y": " b. pirênaya (m)"
  },
  {
    "x": "pirinaye (n)",
    "y": " b. pirênaye (n)"
  },
  {
    "x": "pirinayene",
    "y": " b. pirênayene"
  },
  {
    "x": "pirinayîş (n)",
    "y": " b. pirênayîş (n)"
  },
  {
    "x": "pirişyayîş (n)",
    "y": " b. piroşanayîş (n)"
  },
  {
    "x": "pirî dayene",
    "y": " b. piro dayene"
  },
  {
    "x": "pirîdayîş (n)",
    "y": " b. pirodayîş (n)"
  },
  {
    "x": "pirîn (n)",
    "y": " b. pirên (n)"
  },
  {
    "x": "pirîn-timonî (zh)",
    "y": " b. pirên-tumanî (zh)"
  },
  {
    "x": "pirînaya (m)",
    "y": " b. pirênaya (m)"
  },
  {
    "x": "pirînaye (n)",
    "y": " b. pirênaye (n)"
  },
  {
    "x": "pirînayene",
    "y": " b. pirênayene"
  },
  {
    "x": "pirînayîş (n)",
    "y": " b. pirênayîş (n)"
  },
  {
    "x": "pirjine (m)",
    "y": " b. pirojine (m)"
  },
  {
    "x": "pirjine ro gunayîne",
    "y": " b. pirojine ro ginayene"
  },
  {
    "x": "pirjini (m)",
    "y": " b. pirojine (m)"
  },
  {
    "x": "pirjini ro dayîne",
    "y": " b. pirojine ro dayene"
  },
  {
    "x": "pirkerdiş (n)",
    "y": " b. pirrkerdiş (n)"
  },
  {
    "x": "pirkîye piro ginayene",
    "y": " ağlamasını tutamayıp aniden ağlamak"
  },
  {
    "x": "pirkîyepiroginayîş (n)",
    "y": " ağlamasını tutamayıp aniden ağlama"
  },
  {
    "x": "pirnaya (m)",
    "y": " b. pirênayîye (m), pirênaya (m)"
  },
  {
    "x": "pirnaye (n)",
    "y": " b. pirênaye (n)"
  },
  {
    "x": "pirnayene",
    "y": " b. pirênayene"
  },
  {
    "x": "pirnayîş (n)",
    "y": " b. pirênayîş (n)"
  },
  {
    "x": "pirnikberz, -e",
    "y": " kibirli, büyüklenen"
  },
  {
    "x": "pirnikberz bîyene",
    "y": " kibirlenmek"
  },
  {
    "x": "pirnikberzey (m)",
    "y": " b. pirnikberzî (m), pirnikeberzîye (m)"
  },
  {
    "x": "pirnikberzî (m)",
    "y": " kibirlilik"
  },
  {
    "x": "pirnikberzîye (m)",
    "y": " kibirlilik"
  },
  {
    "x": "pirnike (m)",
    "y": " burun"
  },
  {
    "x": "pirnika ... veşayene",
    "y": " utanmak (mecazi)"
  },
  {
    "x": "pirnika ... veşayîş",
    "y": " utanma (mecazi)"
  },
  {
    "x": "qula pirnike (m)",
    "y": " burun deliği"
  },
  {
    "x": "pirnikeberz, -e",
    "y": " b. pirnikberz, -e"
  },
  {
    "x": "pirnikeberz bîyene",
    "y": " b. pirnikberz bîyene"
  },
  {
    "x": "pirnikeberzî (m)",
    "y": " b. pirnikberzî (m), pirnikeberzîye (m)"
  },
  {
    "x": "pirnikeberzîye (m)",
    "y": " b. pirnikberzîye (m)"
  },
  {
    "x": "pirnikiberz bîyene",
    "y": " b. pirnikberz bîyene"
  },
  {
    "x": "pirniki (m)",
    "y": " b. pirnike (m)"
  },
  {
    "x": "pirnî, -ye",
    "y": " cüce"
  },
  {
    "x": "pirnîyî (m)",
    "y": " cücelik"
  },
  {
    "x": "pirnosê çarixî (n)",
    "y": " çarık burnu"
  },
  {
    "x": "pirnosê solî (n)",
    "y": " ayakkabı burnu"
  },
  {
    "x": "piro alizîyayene",
    "y": " tırmanmak"
  },
  {
    "x": "piro cenayene",
    "y": " 1)bandırmak\r\n~2)b. ... ro cenayene"
  },
  {
    "x": "piro dayene",
    "y": " 1)vurmak\r\n~2)çakmak\r\n~3)çalmak (zil vs. için)"
  },
  {
    "x": "piro ginayene",
    "y": " 1)değmek, -e değmek, -e isabet etmek\r\n~2)çarpmak\r\n~3)farkına varmak\r\n~4)çökmek (üzerine çökmek)"
  },
  {
    "x": "piro gunayene",
    "y": " b. piro ginayene"
  },
  {
    "x": "piro gunayîne",
    "y": " b. piro ginayene"
  },
  {
    "x": "piro kerdene-I",
    "y": " 1)b. ... ro kerdene\r\n~2)giydirmek (pantolon vb. için)\r\n~3)üstüne dökmek, üzerine dökmek"
  },
  {
    "x": "piro nayene",
    "y": " -e bindirmek"
  },
  {
    "x": "piro niştene",
    "y": " -e binmek"
  },
  {
    "x": "piro ozîyene",
    "y": " b. piro alizîyayene"
  },
  {
    "x": "piro rînayene",
    "y": " bandırmak"
  },
  {
    "x": "piro sanayene",
    "y": " b. piro şanayene"
  },
  {
    "x": "piro sanayêne",
    "y": " b. piro şanayene"
  },
  {
    "x": "piro şanayene",
    "y": " -e serpmek"
  },
  {
    "x": "piro şidênayene",
    "y": " bastırmak"
  },
  {
    "x": "piro şîyayene",
    "y": " 1)b. piro şîyene\r\n~2)b. ... ro şîyene"
  },
  {
    "x": "piro şîyayêne",
    "y": " b. piro şîyene"
  },
  {
    "x": "piro şîyene",
    "y": " 1)-e benzemek\r\n~2)çağrıştırmak (andırmak)"
  },
  {
    "x": "piro vejîyayene",
    "y": " tırmanmak"
  },
  {
    "x": "piro vejyene",
    "y": " b. piro vejîyayene"
  },
  {
    "x": "piro war kerdene",
    "y": " b. ... ro war kerdene"
  },
  {
    "x": "piroalizîyayîş (n)",
    "y": " tırmanma"
  },
  {
    "x": "pirobedilîyayîş (n)",
    "y": " tek kişiye karşı birkaç kişinin nöbetleşe bir şeyi yapması\r\n~Zirave û Vilnaze, karê rezî de Werfike ro bedilîyay la a reyna zî hendê înan şîkayet nêkerd. (Zırave ile Vılnaze, bağ işinde Werfıke’ye karşı nöbetleşe çalıştılar ama o yine de onlar kadar şikayetçi olmadı.)"
  },
  {
    "x": "pirocenayîş (n)",
    "y": " 1)b. ... rocenayîş\r\n~2)bandırma"
  },
  {
    "x": "pirocine (m)",
    "y": " b. pirojine (m)"
  },
  {
    "x": "pirocini (m)",
    "y": " b. pirojine (m)"
  },
  {
    "x": "pirodayîş (n)",
    "y": " 1)vurma\r\n~2)çakma\r\n~3)çalma (zil vs.)"
  },
  {
    "x": "pirodayîşê ceza (n)",
    "y": " ceza atışı (özellikle futbolda)"
  },
  {
    "x": "piroginaye (n)",
    "y": " göçük"
  },
  {
    "x": "piroginayîş (n)",
    "y": " 1)değme-I\r\n~2)çarpma\r\n~3)farkına varma\r\n~4)çökme (üzerine çökme), çöküş"
  },
  {
    "x": "piroginayîşê erdî (n)",
    "y": " toprak çökmesi"
  },
  {
    "x": "pirogunayîş (n)",
    "y": " b. piroginayîş (n)"
  },
  {
    "x": "pirojine (m)",
    "y": " kalbur"
  },
  {
    "x": "pirojine kerdene",
    "y": " kalburlamak, çalkalamak, kalburdan geçirmek"
  },
  {
    "x": "pirojine ro dayene",
    "y": " kalburlamak, çalkalamak, kalburdan geçirmek"
  },
  {
    "x": "pirojine ro ginayene",
    "y": " kalburlanmak, çalkalanmak, kalburdan geçirilmek"
  },
  {
    "x": "pirojini (m)",
    "y": " b. pirojine (m)"
  },
  {
    "x": "pirokerdiş (n)",
    "y": " b. 1)... rokerdiş\r\n~2)giydirme (pantolon vb. için)"
  },
  {
    "x": "piron (n)",
    "y": " b. piran (n)"
  },
  {
    "x": "pironon dima bîyayene",
    "y": " b. piranan dima bîyene"
  },
  {
    "x": "piroozîyayîş (n)",
    "y": " b. piroalizîyayîş (n)"
  },
  {
    "x": "pirorînayîş (n)",
    "y": " bandırma"
  },
  {
    "x": "pirosanayîs (n)",
    "y": " b. piroşanayîş (n)"
  },
  {
    "x": "pirosnayene",
    "y": " b. piroşnayene"
  },
  {
    "x": "pirosnayîş (n)",
    "y": " b. piroşnayîş (n)"
  },
  {
    "x": "piroşanayîş (n)",
    "y": " -e serpme"
  },
  {
    "x": "piroşidênayîş (n)",
    "y": " bastırma"
  },
  {
    "x": "piroşîyayîş (n)",
    "y": " 1)çağrıştırma (andırma)\r\n~2)b. ... roşîyayîş"
  },
  {
    "x": "piroşnayene",
    "y": " doğramak (ekmek vb. için), ufalamak (ekmek vb. için)"
  },
  {
    "x": "piroşnayîş (n)",
    "y": " doğrama (ekmek vb. için), ufalama (ekmek vb. için)"
  },
  {
    "x": "pirovejîyayîş (n)",
    "y": " tırmanma"
  },
  {
    "x": "pirovejyayîş (n) ",
    "y": " b. pirovejîyayîş (n)"
  },
  {
    "x": "pirowarkerdiş (n)",
    "y": " b. ... rowarkerdiş (n)"
  },
  {
    "x": "pirozin (m)",
    "y": " b. pirojine (m)"
  },
  {
    "x": "pirozine (m)",
    "y": " b. pirojine (m)"
  },
  {
    "x": "pirozine kerdene",
    "y": " b. pirojine kerdene"
  },
  {
    "x": "pirpar (n)",
    "y": " semizotu, pirpirim"
  },
  {
    "x": "pirpir (n)",
    "y": " b. pirpar (n)"
  },
  {
    "x": "pirr, -e-I",
    "y": " dolu"
  },
  {
    "x": "pirr bîyayene",
    "y": " b. pirr bîyene"
  },
  {
    "x": "pirr bîyene",
    "y": " 1)dolmak\r\n~2)çözülmek (bulmaca vb. için)"
  },
  {
    "x": "pirr kerdene",
    "y": " 1)doldurmak\r\n~2)çözmek (bulmaca vb. için)"
  },
  {
    "x": "pirr-II (n)",
    "y": " b. bire-II (m)"
  },
  {
    "x": "pirrbeyîş (n)",
    "y": " b. pirrbîyayîş (n)"
  },
  {
    "x": "pirrbîyayîş (n)",
    "y": " 1)dolma\r\n~2)çözülme (bulmaca vb. için)"
  },
  {
    "x": "pirri (m)",
    "y": " b. pirre (m)"
  },
  {
    "x": "pirrkerdiş (n)",
    "y": " 1)doldurma\r\n~2)çözme (bulmaca vb. için)"
  },
  {
    "x": "pirşu (n)",
    "y": " parçacık"
  },
  {
    "x": "pirşuy (n)",
    "y": " b. pirşu (n)"
  },
  {
    "x": "pirt",
    "y": " b. pitir, bitir"
  },
  {
    "x": "pirt pêrar (n)",
    "y": " b. bitir pêrar (n)"
  },
  {
    "x": "pirtaqale (m)",
    "y": " b. porteqale (m)"
  },
  {
    "x": "pirteqal (m)",
    "y": " b. porteqale (m)"
  },
  {
    "x": "pirteqalêri (m)",
    "y": " b. porteqalêre (m)"
  },
  {
    "x": "pirteqali (m)",
    "y": " b. porteqale (m)"
  },
  {
    "x": "pirtik-I (n)",
    "y": " parçacık"
  },
  {
    "x": "pirtikî bîyene",
    "y": " parçacıklara ayrılmak"
  },
  {
    "x": "pirtikî kerdene",
    "y": " parçacıklara ayırmak"
  },
  {
    "x": "pirtik-II (n)",
    "y": " b. purtik (n)"
  },
  {
    "x": "pirtik sero kerdene",
    "y": " b. purtik sero kerdene"
  },
  {
    "x": "pirtikin, -e",
    "y": " b. purtikin, -e"
  },
  {
    "x": "pirtikîbîyayîş (n)",
    "y": " parçacıklara ayrılma"
  },
  {
    "x": "pirtikîkerdiş (n)",
    "y": " parçacıklara ayırma"
  },
  {
    "x": "pirtiqal (m)",
    "y": " b. porteqale (m)"
  },
  {
    "x": "pirtî (n)",
    "y": " b. pirtû (n)"
  },
  {
    "x": "pirto",
    "y": " b. pitir, bitir"
  },
  {
    "x": "pirto perarey (n)",
    "y": " b. pitir perarey (n)"
  },
  {
    "x": "pirto perey (n)",
    "y": " b. pitir perey (n)"
  },
  {
    "x": "pirto pêrar (n)",
    "y": " b. bitir pêrar (n)"
  },
  {
    "x": "pirtqal (m)",
    "y": " b. porteqale (m)"
  },
  {
    "x": "pirtu (n)",
    "y": " b. pirtû (n)"
  },
  {
    "x": "pirtû (n)",
    "y": " giysilik kumaşların genel adı"
  },
  {
    "x": "pirtûroş, -e",
    "y": " manifaturacı"
  },
  {
    "x": "pirtûroşî (m)",
    "y": " manifaturacılık"
  },
  {
    "x": "pirtûroşîye (m)",
    "y": " manifaturacılık"
  },
  {
    "x": "pirue ginayene",
    "y": " b. piro ginayene"
  },
  {
    "x": "pirue ginayîne",
    "y": " b. piro ginayene"
  },
  {
    "x": "piruedayîş (n)",
    "y": " b. pirodayîş (n)"
  },
  {
    "x": "pirueginayîş (n)",
    "y": " b. piroginayîş (n)"
  },
  {
    "x": "pirun (n)",
    "y": " b. pirên (n)"
  },
  {
    "x": "piruz (n)",
    "y": " b. pirûz (n)"
  },
  {
    "x": "piruzin, -e",
    "y": " b. pirûzin, -e"
  },
  {
    "x": "pirû dayene",
    "y": " b. piro dayene"
  },
  {
    "x": "pirûdayîş (n)",
    "y": " b. pirodayîş (n)"
  },
  {
    "x": "pirûn (n)",
    "y": " b. piran (n)"
  },
  {
    "x": "pirûnûn dima bîyayene",
    "y": " b. piranan dima bîyene"
  },
  {
    "x": "pirûz (n)",
    "y": " asık surat, abus, çatık yüz, çatık surat, çatık çehre"
  },
  {
    "x": "pirûzin, -e",
    "y": " asık suratlı, abus, somurtkan, somurtuk"
  },
  {
    "x": "piryedayîş (n)",
    "y": " b. pirodayîş (n)"
  },
  {
    "x": "piryen (n)",
    "y": " b. pirên (n)"
  },
  {
    "x": "piryentaq",
    "y": " b. pirêntaq, -e"
  },
  {
    "x": "pis-I (m)",
    "y": " b. pisa (m)"
  },
  {
    "x": "pis-II",
    "y": " b. pişt"
  },
  {
    "x": "pis pis",
    "y": " b. pişê pişê"
  },
  {
    "x": "pisa (m)",
    "y": " 1)ağ, apışlık\r\n~2)uçkurluk"
  },
  {
    "x": "pise (m)",
    "y": " b. pişê (m)"
  },
  {
    "x": "piseke (m)",
    "y": " ağ, apışlık"
  },
  {
    "x": "piseki (m)",
    "y": " b. piseke (m)"
  },
  {
    "x": "pisê (m)",
    "y": " b. pişê (m)"
  },
  {
    "x": "pisgul (n)",
    "y": " b. piskile (m)"
  },
  {
    "x": "pisgulin, -e",
    "y": " b. piskilin, -e"
  },
  {
    "x": "pisi (m)",
    "y": " b. pişê (m)"
  },
  {
    "x": "pisi pisi",
    "y": " b. pişê pişê"
  },
  {
    "x": "pisike-I (m)",
    "y": " (çocuk dilinde kedi) pisi, pisipisi"
  },
  {
    "x": "pisike-II (m)",
    "y": " b. pişike (m)"
  },
  {
    "x": "pisiki-I (m)",
    "y": " b. pisike-I (m)"
  },
  {
    "x": "pisiki-II (m)",
    "y": " b. pisike-II (m)"
  },
  {
    "x": "pisiklet (m)",
    "y": " b. bîsîklete (m)"
  },
  {
    "x": "pising, -e",
    "y": " b. pisîng, -e"
  },
  {
    "x": "pisîk, -e",
    "y": " b. pisîng, -e"
  },
  {
    "x": "pisîng, -e",
    "y": " kedi, pisik\r\n~Pisînge nîyawena/nîyawkena. (Kedi miyavlıyor.)"
  },
  {
    "x": "pisîngi (m)",
    "y": " b. pisînge (m)"
  },
  {
    "x": "pisk",
    "y": " b. pişk"
  },
  {
    "x": "piskar bîyayene",
    "y": " b. pêşkar bîyene"
  },
  {
    "x": "piskarê … bîyayene",
    "y": " b. pêşkarê ... bîyene"
  },
  {
    "x": "piskar bîyayêne",
    "y": " b. pêşkar bîyene"
  },
  {
    "x": "piskayêne",
    "y": " b. pişkayene"
  },
  {
    "x": "piske-I (m)",
    "y": " b. biske (m)"
  },
  {
    "x": "piske-II (m)",
    "y": " b. pişke (m)"
  },
  {
    "x": "piskele (n)",
    "y": " küçük köz"
  },
  {
    "x": "piskene (n)",
    "y": " b. piskone (n)"
  },
  {
    "x": "piski (m)",
    "y": " b. biske (m)"
  },
  {
    "x": "piskil (m)",
    "y": " b. piskile (m)"
  },
  {
    "x": "piskilê murûn (m)",
    "y": " b. piskila murayan (m)"
  },
  {
    "x": "piskile (m)",
    "y": " püskül"
  },
  {
    "x": "piskila murayan (m)",
    "y": " işlenmemiş çok sayıda boncuk dizisinden oluşan bağlam"
  },
  {
    "x": "piskili (m)",
    "y": " b. piskile (m)"
  },
  {
    "x": "piskiley muron (m)",
    "y": " b. piskila murayan (m)"
  },
  {
    "x": "piskilin, -e",
    "y": " püsküllü"
  },
  {
    "x": "piskivît (n)",
    "y": " b. bîskuvît (n)"
  },
  {
    "x": "piskivîte (m)",
    "y": " b. bîskuvît (n)"
  },
  {
    "x": "piskivîti (m)",
    "y": " b. bîskuvît (n)"
  },
  {
    "x": "piskî (m)",
    "y": " b. pişkî (m)"
  },
  {
    "x": "piskone (n)",
    "y": " köseği (bir ucu yanıp ateş haline gelmiş olan odun/ağaç parçası)"
  },
  {
    "x": "piskoni (n)",
    "y": " b. piskone (n)"
  },
  {
    "x": "piskul (n)",
    "y": " b. piskile (m)"
  },
  {
    "x": "piskula muran (m)",
    "y": " b. piskila murayan (m)"
  },
  {
    "x": "piskulet (m)",
    "y": " b. bîsîklete (m)"
  },
  {
    "x": "piskuli (m)",
    "y": " b. piskile (m)"
  },
  {
    "x": "piskulin, -e",
    "y": " b. piskilin, -e"
  },
  {
    "x": "piskuwî (m)",
    "y": " b. bîskuvît (n)"
  },
  {
    "x": "piso-I (n)",
    "y": " b. pişo (n)"
  },
  {
    "x": "piso-II",
    "y": " b. pişo"
  },
  {
    "x": "pisondî (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "pisotî (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "pisperike (m)",
    "y": " kelebek "
  },
  {
    "x": "pispor, -e",
    "y": " uzman"
  },
  {
    "x": "pispora anestezî (m)",
    "y": " anestezi uzmanı"
  },
  {
    "x": "pispora butçe (m)",
    "y": " bütçe uzmanı "
  },
  {
    "x": "pispora çimeyanê merdimî (m)",
    "y": " insan kaynakları uzmanı"
  },
  {
    "x": "pispora gramerî (m)",
    "y": " dilbilgisi uzmanı, gramer uzmanı"
  },
  {
    "x": "pispora hesabî (m)",
    "y": " hesap uzmanı"
  },
  {
    "x": "pispora kulturî (m)",
    "y": " kültür ataşesi"
  },
  {
    "x": "pispora leşkerîye (m)",
    "y": " askeri ataşe"
  },
  {
    "x": "pispora nebatanê baxçeyî (m)",
    "y": " bahçe bitkileri uzmanı"
  },
  {
    "x": "pispora rindî/rindîye (m)",
    "y": " güzellik uzmanı"
  },
  {
    "x": "pispora teknolojîyî (m)",
    "y": " teknoloji uzmanı"
  },
  {
    "x": "pisporê anestezî (n)",
    "y": " anestezi uzmanı"
  },
  {
    "x": "pisporê butçe (n)",
    "y": " bütçe uzmanı"
  },
  {
    "x": "pisporê çimeyanê merdimî (n)",
    "y": " insan kaynakları uzmanı"
  },
  {
    "x": "pisporê gramerî (n)",
    "y": " dilbilgisi uzmanı, gramer uzmanı"
  },
  {
    "x": "pisporê hesabî (n)",
    "y": " hesap uzmanı"
  },
  {
    "x": "pisporê nebatanê baxçeyî (n)",
    "y": " bahçe bitkileri uzmanı"
  },
  {
    "x": "pisporê kulturî (n)",
    "y": " kültür ataşesi"
  },
  {
    "x": "pisporê rindî/rindîye (n)",
    "y": " güzellik uzmanı"
  },
  {
    "x": "pisporê teknolojîyî (n)",
    "y": " teknoloji uzmanı"
  },
  {
    "x": "pisporo leşkerî (n)",
    "y": " askeri ataşe"
  },
  {
    "x": "pisporî (m)",
    "y": " uzmanlık"
  },
  {
    "x": "pisporîya pawitişê sivîlî (m)",
    "y": " sivil savunma uzmanlığı"
  },
  {
    "x": "pisporîye (m)",
    "y": " uzmanlık"
  },
  {
    "x": "pisqayene",
    "y": " b. pijiqîyayene"
  },
  {
    "x": "pisqilet (m)",
    "y": " b. bîsîklete (m)"
  },
  {
    "x": "pisqilêt (m)",
    "y": " b. bîsîklete (m)"
  },
  {
    "x": "pisqilêti (m)",
    "y": " b. bîsîklete (m)"
  },
  {
    "x": "pisqilîti (m)",
    "y": " b. bîsîklete (m)"
  },
  {
    "x": "pisqinayene",
    "y": " b. pijiqnayene"
  },
  {
    "x": "pisqinayîş (n)",
    "y": " b. pijiqnayîş (n)"
  },
  {
    "x": "pisqîyayene",
    "y": " b. pijiqîyayene"
  },
  {
    "x": "pisqîyayîş (n)",
    "y": " b. pijiqîyayîş (n)"
  },
  {
    "x": "pisqnayene",
    "y": " b. pijiqnayene"
  },
  {
    "x": "pisqnayîş (n)",
    "y": " b. pijiqnayîş (n)"
  },
  {
    "x": "pisqule (m)",
    "y": " b. pişkile (m)"
  },
  {
    "x": "pisqule kerdene",
    "y": " b. pişkile kerdene"
  },
  {
    "x": "pisquli (m)",
    "y": " b. pişkile (m)"
  },
  {
    "x": "pisrug (n)",
    "y": " b. pişrûg (n)"
  },
  {
    "x": "pisrugê nanî (n)",
    "y": " b. pişrûgê nanî (n)"
  },
  {
    "x": "pisruge-I (m)",
    "y": " b. pişrûge (m)"
  },
  {
    "x": "pisruge-II (m)",
    "y": " b. pizrike (m)"
  },
  {
    "x": "pist-I (n)",
    "y": " kazık"
  },
  {
    "x": "pist-II",
    "y": " b. pişt"
  },
  {
    "x": "pistan (n)",
    "y": " koyun (göğüs ile giysi arası)"
  },
  {
    "x": "pisti-I (m)",
    "y": " b. pistî (m)"
  },
  {
    "x": "pisti-II (n)",
    "y": " b. pistan (n)"
  },
  {
    "x": "pistin (n)",
    "y": " b. pistan (n)"
  },
  {
    "x": "pistine (m)",
    "y": " b. pistan (n)"
  },
  {
    "x": "pistî-I (m)",
    "y": " gelberi, köseği, evirgeç"
  },
  {
    "x": "pistîya misasî (m)",
    "y": " çekel (üvendirenin ucundaki yassı demir)"
  },
  {
    "x": "pistî-II (zh)",
    "y": " kazık biçiminde ucu sivriltilmiş çubuklarla oynanan bir çocuk oyunu"
  },
  {
    "x": "piston (n)",
    "y": " b. pistan (n)"
  },
  {
    "x": "pistûn (n)",
    "y": " b. pistan (n)"
  },
  {
    "x": "pisuge (m)",
    "y": " b. pizrike (m)"
  },
  {
    "x": "piş (m)",
    "y": " b. pişike (m)"
  },
  {
    "x": "piş-I (n)",
    "y": " b. puş (n)"
  },
  {
    "x": "pişê pîyanzî (n)",
    "y": " b. puşê pîyazî (n)"
  },
  {
    "x": "piş-II",
    "y": " b. pişt"
  },
  {
    "x": "piş piş",
    "y": " b. pişê pişê"
  },
  {
    "x": "pişe-I (m)",
    "y": " b. pişê-I (m)"
  },
  {
    "x": "pişe-II (m)",
    "y": " b. pişike (m)"
  },
  {
    "x": "pişê-I (m)",
    "y": " (çocuk dilinde kedi) pisi, pisipisi"
  },
  {
    "x": "pişê-II",
    "y": " kediyi çağırırken kullanılan sözcük"
  },
  {
    "x": "pişê pişê",
    "y": " kediyi çağırırken kullanılan sözcük"
  },
  {
    "x": "pişêrd (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "pişi (m)",
    "y": " b. pişike (m)"
  },
  {
    "x": "pişi pişi",
    "y": " b. pişê pişê"
  },
  {
    "x": "pişike (m)",
    "y": " akciğer"
  },
  {
    "x": "pişikexişn, -e",
    "y": " b. pişikxişn, -e"
  },
  {
    "x": "pişikexişn biyene",
    "y": " b. pişikxişn bîyene"
  },
  {
    "x": "pişikexişnê (m)",
    "y": " pişikxişnî (m)"
  },
  {
    "x": "pişikxişn, -e",
    "y": " kibirli, büyüklenen"
  },
  {
    "x": "pişikxişn bîyene",
    "y": " kibirlenmek"
  },
  {
    "x": "pişikxişney (m)",
    "y": " pişikxişnî (m)"
  },
  {
    "x": "pişikxişnî (m)",
    "y": " kibirlilik"
  },
  {
    "x": "pişî (m)",
    "y": " b. pişike (m)"
  },
  {
    "x": "pişk-I",
    "y": " hapşırma sesi"
  },
  {
    "x": "pişk-II (m)",
    "y": " b. pişke (m)"
  },
  {
    "x": "pişkayene",
    "y": " hapşırmak, aksırmak"
  },
  {
    "x": "pişkayîş (n)",
    "y": " hapşırma, aksırma, aksırış"
  },
  {
    "x": "pişke-I (m)",
    "y": " eylem"
  },
  {
    "x": "pişke-II (m)",
    "y": " gübre (keçi ve koyun gübresi), kemre (hayvan gübresi)"
  },
  {
    "x": "pişke-III (m)",
    "y": " kura"
  },
  {
    "x": "pişke eştene",
    "y": " kura çekmek"
  },
  {
    "x": "pişki (m)",
    "y": " b. pişke (m)"
  },
  {
    "x": "pişki eştene",
    "y": " b. pişke eştene"
  },
  {
    "x": "pişkil (m)",
    "y": " b. pişkile (m)"
  },
  {
    "x": "pişkil kerdene",
    "y": " b. pişkile kerdene"
  },
  {
    "x": "pişkile (m)",
    "y": " kığ, kığı"
  },
  {
    "x": "pişkile kerdene",
    "y": " kığılamak "
  },
  {
    "x": "pişkili (m)",
    "y": " b. pişkile (m)"
  },
  {
    "x": "pişkili kerdene",
    "y": " b. pişkile kerdene"
  },
  {
    "x": "pişkî (m)",
    "y": " hapşırık, aksırık"
  },
  {
    "x": "pişkî eştene",
    "y": " çiselemek, çisemek"
  },
  {
    "x": "pişkîeştiş (n)",
    "y": " çiseleme, çiseme"
  },
  {
    "x": "pişkove (m)",
    "y": " b. bişkoje (m)"
  },
  {
    "x": "pişo-I (n)",
    "y": " (çocuk dilinde kedi) pisi, pisipisi"
  },
  {
    "x": "pişo-II",
    "y": " kediyi çağırırken kullanılan sözcük"
  },
  {
    "x": "pişo pişo",
    "y": " kediyi çağırırken kullanılan sözcük"
  },
  {
    "x": "pişondî (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "pişortî (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "pişoti (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "pişotî (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "pişqlet (n)",
    "y": " b. bîsîklete (m)"
  },
  {
    "x": "pişqnayene",
    "y": " b. pijiqnayene"
  },
  {
    "x": "pişqul (m)",
    "y": " b. pişkile (m)"
  },
  {
    "x": "pişqul kerdene",
    "y": " b. pişkile kerdene"
  },
  {
    "x": "pişqule (m)",
    "y": " b. pişkile (m)"
  },
  {
    "x": "pişqule kerdene",
    "y": " b. pişkile kerdene"
  },
  {
    "x": "pişruge (m)",
    "y": " b. pişrûge (m)"
  },
  {
    "x": "pişrugi (m)",
    "y": " b. pişrûge (m)"
  },
  {
    "x": "pişrûg (n)",
    "y": " kırıntı"
  },
  {
    "x": "pişrûgê nanî (n)",
    "y": " ekmek kırıntısı"
  },
  {
    "x": "pişrûge (m)",
    "y": " su ve undan yapılan, içine yağ ve pekmez katılarak da yenebilen bulamaç"
  },
  {
    "x": "pişt",
    "y": " (kediyi kovmak için söylenen bir sözcük) pist"
  },
  {
    "x": "pişt pişt",
    "y": " b. pişt"
  },
  {
    "x": "piştene",
    "y": " b. pîştene"
  },
  {
    "x": "pişti",
    "y": " b. pişt"
  },
  {
    "x": "piştin (n)",
    "y": " b. pistan (n)"
  },
  {
    "x": "piştiş (n)",
    "y": " b. pîştiş (n)"
  },
  {
    "x": "piştî-I (m)",
    "y": " 1)sırt\r\n~2)dayanak"
  },
  {
    "x": "piştî bi ... bestene",
    "y": " -e güvenmek"
  },
  {
    "x": "piştî bi ... bestîyayene",
    "y": " -e güvenilmek"
  },
  {
    "x": "piştî bi ... girê dayene",
    "y": " -e güvenmek"
  },
  {
    "x": "piştî bi ... girê dîyayene",
    "y": " -e güvenilmek"
  },
  {
    "x": "piştî dayene",
    "y": " 1)desteklemek\r\n~2)dayanmak (bir şeyden güç almak)"
  },
  {
    "x": "piştî germ kerdene",
    "y": " cinsel ilişkide bulunmak (erkeğin cinsel ilişkide bulunması)"
  },
  {
    "x": "piştî kerdene",
    "y": " yüklenmek"
  },
  {
    "x": "piştîya xo dayene",
    "y": " dayanmak (bir şeyden güç almak)\r\n~Piştîya xo daye dêsî. (Sırtını duvara dayadı.)"
  },
  {
    "x": "piştîya xo dayîş (n)",
    "y": " dayanma (bir şeyden güç alma)"
  },
  {
    "x": "piştî-II",
    "y": " b. pişt"
  },
  {
    "x": "piştîdayîş (n)",
    "y": " 1)destekleme\r\n~2)dayanma (bir şeyden güç alma)"
  },
  {
    "x": "piştîdayîşyewbînan",
    "y": " dayanışma"
  },
  {
    "x": "piştîgermkerdiş (n)",
    "y": " cinsel ilişkide bulunma (erkeğin cinsel ilişkide bulunması)"
  },
  {
    "x": "piştîkerdiş (n)",
    "y": " yüklenme"
  },
  {
    "x": "piştîwan (n)",
    "y": " dayanak"
  },
  {
    "x": "piştîyayewbînangirewtiş (n)",
    "y": " dayanışma"
  },
  {
    "x": "piştol (n)",
    "y": " tabanca, pistol"
  },
  {
    "x": "piştwer (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "piştwerdî (m)",
    "y": " artık (yiyecek veya içecek artığı)"
  },
  {
    "x": "pişundî (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "pişûre (n)",
    "y": " b. pişrûg (n)"
  },
  {
    "x": "pişûreyê nanî (n)",
    "y": " b. pişrûgê nanî (n)"
  },
  {
    "x": "pişûti (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "pişwer (m)",
    "y": " 1)bedavacı, beleşçi\r\n~2)b. piştwerdî (m)"
  },
  {
    "x": "pişwerd (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "pişwerde (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "pişwerdi (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "pişwerîye (m)",
    "y": " bedavacılık"
  },
  {
    "x": "pişwêr (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "pişyerd (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "pit, -e-I",
    "y": " b. put, -e"
  },
  {
    "x": "pit-II",
    "y": " (kediyi kovmak için söylenen bir sözcük) pist"
  },
  {
    "x": "pit pit",
    "y": " b. pit-II"
  },
  {
    "x": "pitepit kerdene",
    "y": " b. pitpit kerdene"
  },
  {
    "x": "pitepitkerdiş (n)",
    "y": " b. pitpitkerdiş (n) "
  },
  {
    "x": "pitik",
    "y": " b. pitir"
  },
  {
    "x": "pitik perarey (n)",
    "y": " b. pitir perarey (n)"
  },
  {
    "x": "pitir",
    "y": " önceki, evvelki"
  },
  {
    "x": "pitir perarey (n)",
    "y": " bugünden önceki dördüncü gün"
  },
  {
    "x": "pitir perarê (n)",
    "y": " b. pitir perarey (n)"
  },
  {
    "x": "pitir perey (n)",
    "y": " bugünden önceki üçüncü gün"
  },
  {
    "x": "pitir perê (n)",
    "y": " b. pitir perey (n)"
  },
  {
    "x": "pitir pêrar (n)",
    "y": " b. bitir pêrar (n)"
  },
  {
    "x": "pitir pêrarey (n)",
    "y": " b. pitir perarey (n)"
  },
  {
    "x": "pitir pîrar (n)",
    "y": " b. bitir pêrar (n)"
  },
  {
    "x": "pitî (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "pitpit kerdene",
    "y": " söylenmek (kendi kendine konuşmak)"
  },
  {
    "x": "pitpitîyayene",
    "y": " söylenmek (kendi kendine konuşmak)"
  },
  {
    "x": "pitpitîyayîş (n)",
    "y": " söylenme (kendi kendine konuşma)"
  },
  {
    "x": "pitpitkerdiş (n)",
    "y": " söylenme (kendi kendine konuşma)"
  },
  {
    "x": "pix kerdene",
    "y": " (hayvan veya insan için) ürkütmek "
  },
  {
    "x": "pixawî (zh)",
    "y": " pranga"
  },
  {
    "x": "pixêrî (m)",
    "y": " 1)baca\r\n~2)(baca ile birlikte) ocak\r\n~3)şömine"
  },
  {
    "x": "pixok, -e",
    "y": " (mecazi) sulu göz, sulu gözlü, hemen ağlayan"
  },
  {
    "x": "piya",
    "y": " b. pîya-I"
  },
  {
    "x": "pizik (m)",
    "y": " b. pizrike (m)"
  },
  {
    "x": "piziqîyayene",
    "y": " b. pijiqîyayene"
  },
  {
    "x": "piziqîyayîş (n)",
    "y": " b. pijiqîyayîş (n)"
  },
  {
    "x": "piziqnayene",
    "y": " b. pijiqnayene"
  },
  {
    "x": "piziqnayîş (n)",
    "y": " b. pijiqnayîş (n)"
  },
  {
    "x": "pizote (n)",
    "y": " b. bizote (n)"
  },
  {
    "x": "pizrike (m)",
    "y": " sivilce"
  },
  {
    "x": "pizruge (m)",
    "y": " b. pizrike (m)"
  },
  {
    "x": "pî-I (n)",
    "y": " baba"
  },
  {
    "x": "pî-II (m) (mat)",
    "y": " pi (mat)"
  },
  {
    "x": "pî-III",
    "y": " b. pê-I, pê-III"
  },
  {
    "x": "pî ameyene",
    "y": " b. pê ameyene"
  },
  {
    "x": "pî ardene",
    "y": " b. pê ardene"
  },
  {
    "x": "pî kerdene",
    "y": " b. pê kerdene"
  },
  {
    "x": "pî kerdeni",
    "y": " b. pu kerdene"
  },
  {
    "x": "pîameyîş (n)",
    "y": " b. pêameyîş (n)"
  },
  {
    "x": "pîarde (n, sn)",
    "y": " b. pîarde (n, sn)"
  },
  {
    "x": "pîardiş (n)",
    "y": " b. pêardiş (n)"
  },
  {
    "x": "pîc (n)",
    "y": " b. pîj (n)"
  },
  {
    "x": "pîcameyî (zh)",
    "y": " b. pîjemeyî (zh)"
  },
  {
    "x": "pîcemeyî (zh)",
    "y": " b. pîjemeyî (zh)"
  },
  {
    "x": "pîcemê (zh)",
    "y": " b. pîjemeyî (zh)"
  },
  {
    "x": "pîde (m)",
    "y": " pide"
  },
  {
    "x": "pîdepewj, -e",
    "y": " pideci (pide yapan)"
  },
  {
    "x": "pîderoş, -e",
    "y": " pideci (pide satan)"
  },
  {
    "x": "pîeç (m)",
    "y": " b. pêçe (m)"
  },
  {
    "x": "pîer",
    "y": " b. pêro"
  },
  {
    "x": "pîer pîya",
    "y": " b. pêro pîya "
  },
  {
    "x": "pîer dayîne",
    "y": " b. pêro dayene"
  },
  {
    "x": "pîercen (n)",
    "y": " b. perçîn (n)"
  },
  {
    "x": "pîerdayîş (n)",
    "y": " b. pêrodayîş (n)"
  },
  {
    "x": "pîerê (m)",
    "y": " b. pîyarê (m)"
  },
  {
    "x": "pîeri",
    "y": " b. pêro"
  },
  {
    "x": "pîerrê (m)",
    "y": " b. pîyarê (m)"
  },
  {
    "x": "pîeser ardene",
    "y": " b. pêser ardene"
  },
  {
    "x": "pîeş (n)",
    "y": " b. pêş (n)"
  },
  {
    "x": "pîeşmal (m)",
    "y": " b. pêşmale (m)"
  },
  {
    "x": "pîeştimal (m)",
    "y": " b. pêştemale (m)"
  },
  {
    "x": "pîexmer (n)",
    "y": " b. pêxamber (n)"
  },
  {
    "x": "pîhet de",
    "y": " b. pêhet de"
  },
  {
    "x": "pîj, -e-I",
    "y": " sivri"
  },
  {
    "x": "pîj bîyene",
    "y": " sivrilmek"
  },
  {
    "x": "pîj kerdene",
    "y": " sivriltmek"
  },
  {
    "x": "pîj-II (n)",
    "y": " 1)kazık\r\n~2)ucu sivriltilmiş çubuk\r\n~3)siğil (odunları yarmakta yararlanılan), kıskı"
  },
  {
    "x": "pîj ci şanayene",
    "y": " ucu çivi biçiminde sivriltilmiş ağaç/tahta parçası ile çivilemek"
  },
  {
    "x": "pîj deşanayene",
    "y": " ucu çivi biçiminde sivriltilmiş ağaç/tahta parçası ile çivilemek"
  },
  {
    "x": "pîj kerdene",
    "y": " ucu çivi biçiminde sivriltilmiş ağaç/tahta parçası ile çivilemek"
  },
  {
    "x": "pîjê darloxî (n)",
    "y": " yuvağı çekmek için kullanılan çatallı aracın yuvağın girintili yerine geçen ucu"
  },
  {
    "x": "pîjameyî (zh)",
    "y": " b. pîjemeyî (zh)"
  },
  {
    "x": "pîjbîyayîş (n)",
    "y": " sivrilme "
  },
  {
    "x": "pîjemeyî (zh)",
    "y": " pijama"
  },
  {
    "x": "pîjik (n)",
    "y": " 1)kazık\r\n~2)ucu sivriltilmiş çubuk"
  },
  {
    "x": "pîjikekî (zh)",
    "y": " kazık biçiminde ucu sivriltilmiş çubuklarla oynanan bir çocuk oyunu"
  },
  {
    "x": "pîjikî (zh)",
    "y": " kazık biçiminde ucu sivriltilmiş çubuklarla oynanan bir çocuk oyunu"
  },
  {
    "x": "pîjî (zh)",
    "y": " kazık biçiminde ucu sivriltilmiş çubuklarla oynanan bir çocuk oyunu"
  },
  {
    "x": "pîjkerdiş (n)",
    "y": " sivriltme"
  },
  {
    "x": "pîk (n)",
    "y": " pik (dökme demir)"
  },
  {
    "x": "pîkab (n)",
    "y": " b. pîkabe (m)"
  },
  {
    "x": "pîkabe (m)",
    "y": " 1)pikap\r\n~2)pikap (plak dinlemekte kullanılan araç)"
  },
  {
    "x": "pîke (n)",
    "y": " pike"
  },
  {
    "x": "pîkerdiş-I (n)",
    "y": " b. pêkerdiş (n)"
  },
  {
    "x": "pîkerdiş-II (n)",
    "y": " b. pukerdiş (n)"
  },
  {
    "x": "pîl, -e",
    "y": " (yaşça veya manevi olarak) büyük"
  },
  {
    "x": "pîl kardene",
    "y": " b. pîl kerdene"
  },
  {
    "x": "pîl kerdene",
    "y": " 1)büyütmek\r\n~Na cinîke ez pîl kerda. (Bu kadın beni büyüttü.)\r\n~2)abartmak, mübalağa etmek\r\n~\t\r\n~No mêrik her çîyî keno pîl. (Bu herif her şeyi abartıyor.)"
  },
  {
    "x": "pîl û qic",
    "y": " b. pîl û qij"
  },
  {
    "x": "pîl û qij",
    "y": " büyük küçük (herkes)"
  },
  {
    "x": "pîl û qiz",
    "y": " b. pîl û qij"
  },
  {
    "x": "pîl û şenik",
    "y": " büyük küçük (herkes)"
  },
  {
    "x": "pîla çeyî (m)",
    "y": " b. pîla keyeyî (m) "
  },
  {
    "x": "pîla çêyî (m)",
    "y": " b. pîla keyeyî (m)"
  },
  {
    "x": "pîla keyeyî (m)",
    "y": " aile büyüğü "
  },
  {
    "x": "pîley kîyî (m)",
    "y": "b. pîla keyeyî (m) "
  },
  {
    "x": "pîlê çeyî (n)",
    "y": " b. pîlê keyeyî (n) "
  },
  {
    "x": "pîlê çêyî (n)",
    "y": " b. pîlê keyeyî (n)"
  },
  {
    "x": "pîlê keyeyî (n)",
    "y": " aile büyüğü "
  },
  {
    "x": "pîlê kîyî (m)",
    "y": " b. pîla keyeyî (m) "
  },
  {
    "x": "pîlî kîyî (n)",
    "y": " b. pîlê keyeyî (n)"
  },
  {
    "x": "pîlakî (n)",
    "y": " pilaki"
  },
  {
    "x": "pîlan (n)",
    "y": " b. plan (n)"
  },
  {
    "x": "pîlan kerdene",
    "y": " b. plan kerdene"
  },
  {
    "x": "pîlankerdiş (n)",
    "y": " b. plankerdiş (n)"
  },
  {
    "x": "pîlaqa (m)",
    "y": " 1)b. pîkabe (m)\r\n~2)b. plaka (m)"
  },
  {
    "x": "pîlaqe (m)",
    "y": " 1)b. pîkabe (m)\r\n~2)b. plaka (m)"
  },
  {
    "x": "pîlav (n)",
    "y": " b. pîlaw (n) "
  },
  {
    "x": "pîlaw (n)",
    "y": " pilav"
  },
  {
    "x": "pîlawpewj, -e",
    "y": " pilavcı (pilav pişiren)"
  },
  {
    "x": "pîlawroş, -e",
    "y": " pilavcı (pilav satan)"
  },
  {
    "x": "pîletî (m)",
    "y": " büyüklük"
  },
  {
    "x": "pîley (m)",
    "y": " b. pîlîye (m)"
  },
  {
    "x": "pîlê (m)",
    "y": " b. pîlîye (m)"
  },
  {
    "x": "pîlîye (m)",
    "y": " büyüklük"
  },
  {
    "x": "pîlker, -e",
    "y": " abartıcı"
  },
  {
    "x": "pîlkerdiş (n)",
    "y": " abartı, mübalağa, abartma, mübalağa etme"
  },
  {
    "x": "pîlon (n)",
    "y": " b. plan (n)"
  },
  {
    "x": "pîloni (m)",
    "y": " b. plan (n)"
  },
  {
    "x": "pîlonkardiş (n)",
    "y": " b. plankerdiş (n)"
  },
  {
    "x": "pîlot, -e",
    "y": " pilot"
  },
  {
    "x": "pîlotî (m)",
    "y": " pilotluk"
  },
  {
    "x": "pîlotîye (m)",
    "y": " pilotluk"
  },
  {
    "x": "pîltan (m)",
    "y": " b. pîltane (m)"
  },
  {
    "x": "pîltane (m)",
    "y": " ölmez çiçek, altın otu, mayasıl otu, sarıçiçek, yaylagülü, yaylaçiçeği"
  },
  {
    "x": "pîltûn (m)",
    "y": " b. pîltane (m)"
  },
  {
    "x": "pîlûn (n)",
    "y": " b. plan (n)"
  },
  {
    "x": "pîlûn kerdene",
    "y": " b. plan kerdene"
  },
  {
    "x": "pîlûnkerdiş (n) ",
    "y": " b. plankerdiş (n)"
  },
  {
    "x": "pîlûx (m)",
    "y": " b. puluxe (m)"
  },
  {
    "x": "pîmarî (n)",
    "y": " üvey baba, babalık"
  },
  {
    "x": "pîn-I (n)",
    "y": " kümes"
  },
  {
    "x": "pîn-II (n)",
    "y": " b. pîne (m)"
  },
  {
    "x": "pîn eştene (m)",
    "y": " b. pîne eştene"
  },
  {
    "x": "pîncike (m)",
    "y": " çifte (at, eşek vb. tekmesi)"
  },
  {
    "x": "pîncike eştene",
    "y": " çifte atmak"
  },
  {
    "x": "pîncî",
    "y": " b. pîneçî, -ye"
  },
  {
    "x": "pîncokî (zh)",
    "y": " beştaş [oyunu]"
  },
  {
    "x": "pîne-I (n)",
    "y": " 1)yama\r\n~2)pençe (ayakkabı pençesi)"
  },
  {
    "x": "pîne bîyene",
    "y": " yamalanmak, yamanmak"
  },
  {
    "x": "pîne kerdene",
    "y": " 1)yamalamak, yamamak\r\n~2)pençelemek, pençe çakmak, pençe yapmak (ayakkabı için)"
  },
  {
    "x": "pîne padayene",
    "y": " yamalamak, yamamak"
  },
  {
    "x": "pîne padîyayene",
    "y": " yamalanmak, yamanmak"
  },
  {
    "x": "pîne-II (m)",
    "y": " çifte (at, eşek vb. tekmesi)"
  },
  {
    "x": "pîne eştene",
    "y": " çifte atmak"
  },
  {
    "x": "pînebîyaya (m)",
    "y": " yamalı"
  },
  {
    "x": "pînebîyaye (n)",
    "y": " yamalı"
  },
  {
    "x": "pînebîyayîş (n)",
    "y": " yamalanma, yamanma"
  },
  {
    "x": "pînebîyayîye (m)",
    "y": " yamalı"
  },
  {
    "x": "pînecî, -ye",
    "y": " b. pîneçî, -ye"
  },
  {
    "x": "pînecîni (m)",
    "y": " b. pîneçînî (m)"
  },
  {
    "x": "pînecînî (m)",
    "y": " b. pîneçînî (m)"
  },
  {
    "x": "pînecîyênî (m)",
    "y": " b. pîneçîyênî (m)"
  },
  {
    "x": "pîneçî, -ye",
    "y": " ayakkabı tamircisi, ayakkabı onarımcısı, ayakkabıcı (onarımcı), yamacı (ayakkabı onaran)"
  },
  {
    "x": "pîneçîyey (m)",
    "y": " b. pîneçîyî (m), pîneçîyîye (m)"
  },
  {
    "x": "pîneçînî (m)",
    "y": " ayakkabıcılık, pabuççuluk, yamacılık (ayakkabı onarımcılığı)"
  },
  {
    "x": "pîneçîyê (m)",
    "y": " b. pîneçîyî (m), pîneçîyîye (m)"
  },
  {
    "x": "pîneçîyênî (m)",
    "y": " ayakkabıcılık, pabuççuluk, yamacılık (ayakkabı onarımcılığı)"
  },
  {
    "x": "pîneçîyî (m)",
    "y": " ayakkabıcılık, pabuççuluk, yamacılık (ayakkabı onarımcılığı)"
  },
  {
    "x": "pîneçîyîye (m)",
    "y": " ayakkabıcılık, pabuççuluk, yamacılık (ayakkabı onarımcılığı)"
  },
  {
    "x": "pîneker, -e",
    "y": " ayakkabı tamircisi, ayakkabı onarımcısı, ayakkabıcı (onarımcı), yamacı (ayakkabı onaran)"
  },
  {
    "x": "pînekerda (m)",
    "y": " yamalı"
  },
  {
    "x": "pînekerde (n)",
    "y": " yamalı"
  },
  {
    "x": "pînekerdiş (n)",
    "y": " 1)yamalama\r\n~2)pençeleme (ayakkabı için)"
  },
  {
    "x": "pînekerdîye (m)",
    "y": " yamalı"
  },
  {
    "x": "pînekerdox, -e",
    "y": " ayakkabı tamircisi, ayakkabı onarımcısı, ayakkabıcı (onarımcı), yamacı (ayakkabı onaran)"
  },
  {
    "x": "pînekerduex",
    "y": " b. pînekerdox, -e"
  },
  {
    "x": "pînekerî (m)",
    "y": " ayakkabıcılık, pabuççuluk, yamacılık (ayakkabı onarımcılığı)"
  },
  {
    "x": "pînekerîye (m)",
    "y": " ayakkabıcılık, pabuççuluk, yamacılık (ayakkabı onarımcılığı)"
  },
  {
    "x": "pînepadayîş (n)",
    "y": " yamalama"
  },
  {
    "x": "pînepadîyayîş (n)",
    "y": " yamalanma "
  },
  {
    "x": "pîneyin, -e",
    "y": " yamalı"
  },
  {
    "x": "pînge (m)",
    "y": " sivrisinek"
  },
  {
    "x": "pîni (n)",
    "y": " b. pîne (n)"
  },
  {
    "x": "pînik-I(n)",
    "y": " küçük kümes"
  },
  {
    "x": "pînik-II (n)",
    "y": " çaput, paçavra"
  },
  {
    "x": "pînîtene",
    "y": " beklemek"
  },
  {
    "x": "pînîtiş (n)",
    "y": " bekleme, bekleyiş"
  },
  {
    "x": "pîniyin (n)",
    "y": " b. pîneyin (n)"
  },
  {
    "x": "pînpon (n)",
    "y": " masa tenisi, pinpon"
  },
  {
    "x": "pîor",
    "y": " b. pêro"
  },
  {
    "x": "pîpike (m)",
    "y": " b. bîbike (m)"
  },
  {
    "x": "pîpî",
    "y": " hindi"
  },
  {
    "x": "pîqab (m)",
    "y": " b. pîkabe (m)"
  },
  {
    "x": "pîqabe (m)",
    "y": " b. pîkabe (m)"
  },
  {
    "x": "pîqabi (m)",
    "y": " b. pîkabe (m)"
  },
  {
    "x": "pîqap (m)",
    "y": " b. pîkabe (m)"
  },
  {
    "x": "pîr, -e-I",
    "y": " 1)yaşlı, ihtiyar\r\n~2)pir (Alevilikte) "
  },
  {
    "x": "pîr-II (n)",
    "y": " b. pêr-I (n)"
  },
  {
    "x": "pîr-III (m)",
    "y": " b. pîre (m)"
  },
  {
    "x": "pîra",
    "y": " b. pêra"
  },
  {
    "x": "pîramîd (n)",
    "y": " piramit"
  },
  {
    "x": "Pîran (n)",
    "y": " Dicle"
  },
  {
    "x": "pîrar (n)",
    "y": " b. pêrar (n)"
  },
  {
    "x": "pîrbab (n)",
    "y": " dede, ata, büyükbaba, ecdat"
  },
  {
    "x": "pîrbav (n)",
    "y": " dede, ata, büyükbaba, ecdat"
  },
  {
    "x": "pîre-I (m)",
    "y": " yaşlı kadın, ihtiyar kadın, kocakarı"
  },
  {
    "x": "pîre-II (m)",
    "y": " cadaloz, cadı"
  },
  {
    "x": "pîre bîyene",
    "y": " cadılaşmak"
  },
  {
    "x": "pîra cadikere (m)",
    "y": " cadaloz, cadı"
  },
  {
    "x": "pîra cadikeri (m)",
    "y": " b. pîra cadikere (m)"
  },
  {
    "x": "pîra cazuye (m)",
    "y": " b. pîra cazûye (m)"
  },
  {
    "x": "pîra cazûye (m)",
    "y": " cadı"
  },
  {
    "x": "pîramûn (n)",
    "y": " çevre"
  },
  {
    "x": "pîramûn girewtene",
    "y": " abluka etmek, çevrelemek, ihata etmek"
  },
  {
    "x": "pîramûngirewtiş (n)",
    "y": " abluka etme, çevreleme"
  },
  {
    "x": "pîrebîyayîş (n)",
    "y": " cadılaşma"
  },
  {
    "x": "pîreboke (m)",
    "y": " örümcek"
  },
  {
    "x": "pîrek-I (m)",
    "y": " b. pûkê pîre (n)"
  },
  {
    "x": "pîrek-II (m)",
    "y": " b. pîrike-II (m), pîreboke (m)"
  },
  {
    "x": "pîreke (m)",
    "y": " b. pûkê pîre (n)"
  },
  {
    "x": "pîremon (n)",
    "y": " b. pîramûn (n)"
  },
  {
    "x": "pîrepite (m)",
    "y": " b. pîrpite (m)"
  },
  {
    "x": "pîresayîne",
    "y": " b. pêresayene"
  },
  {
    "x": "pîresayîş (n)",
    "y": " b. pêresayîş (n)"
  },
  {
    "x": "pîresnayîne",
    "y": " b. pêresnayene"
  },
  {
    "x": "pîresnayîş (n)",
    "y": " b. pêresnayîş (n)"
  },
  {
    "x": "pîrey (m)",
    "y": " b. pîrîye (m), pîrî (m)"
  },
  {
    "x": "pîrê (m)",
    "y": " b. pîrîye (m), pîrî (m)"
  },
  {
    "x": "pîrên (n)",
    "y": " b. pirên (n)"
  },
  {
    "x": "pîrênî (m)",
    "y": " 1)yaşlılık, ihtiyarlık\r\n~2)pirlik (Alevilikte)"
  },
  {
    "x": "pîri-I (m)",
    "y": " b. pîre (m)"
  },
  {
    "x": "pîri-II (m)",
    "y": " b. pîrike-II (m), pîreboke"
  },
  {
    "x": "pîri bîyayene",
    "y": " b. pîre bîyene"
  },
  {
    "x": "pîribîyayîş (n)",
    "y": " b. pîrebîyayîş (n)"
  },
  {
    "x": "pîrik (n)",
    "y": " dede, ata, büyükbaba, ecdat"
  },
  {
    "x": "pîrike-I (m)",
    "y": " 1)nine, babaanne, anneanne, büyükana, büyükanne\r\n~2)ebe"
  },
  {
    "x": "pîrike-II (m)",
    "y": " örümcek"
  },
  {
    "x": "pîrika şitî (m)",
    "y": " kaymak"
  },
  {
    "x": "pîrike-III (m)",
    "y": " saklambaç (oyunu)"
  },
  {
    "x": "pîriki (m)",
    "y": " b. pîrike (m)"
  },
  {
    "x": "pîrikin, -e",
    "y": " uyuşuk"
  },
  {
    "x": "pîrikin bîyayene",
    "y": " b. pîrikin bîyene"
  },
  {
    "x": "pîrikin bîyene",
    "y": " uyuşmak"
  },
  {
    "x": "pîrikin kerdene",
    "y": " uyuşturmak"
  },
  {
    "x": "pîrikinbîyayîş (n)",
    "y": " uyuşma"
  },
  {
    "x": "pîrikinênî (m)",
    "y": " uyuşukluk"
  },
  {
    "x": "pîrikinîye (m)",
    "y": " uyuşukluk"
  },
  {
    "x": "pîrikinkerdiş (n)",
    "y": " uyuşturma"
  },
  {
    "x": "pîrikîyayene",
    "y": " büzülmek"
  },
  {
    "x": "pîrikîyayîş (n)",
    "y": " büzülme"
  },
  {
    "x": "pîrini",
    "y": " b. pêrune"
  },
  {
    "x": "pîrî-I (m)",
    "y": " 1)yaşlılık, ihtiyarlık\r\n~2)pirlik (Alevilikte)"
  },
  {
    "x": "pîrî-II (zh)",
    "y": " b. pûkê pîre (n)"
  },
  {
    "x": "pîrîk (n)",
    "y": " b. pîrik (n)"
  },
  {
    "x": "pîrîke (m)",
    "y": " b. pîrike (m)"
  },
  {
    "x": "pîrîket (n)",
    "y": " briket"
  },
  {
    "x": "pîrîn (n)",
    "y": " b. pirên (n)"
  },
  {
    "x": "pîrîye (m)",
    "y": " 1)yaşlılık, ihtiyarlık\r\n~2)pirlik (Alevilikte) "
  },
  {
    "x": "pîrkeçel (n)",
    "y": " saklambaç (oyunu)"
  },
  {
    "x": "pîrkeçeleki (m)",
    "y": " b. pîrkeçel (n)"
  },
  {
    "x": "pîrket (n)",
    "y": " b. pîrîket (n)"
  },
  {
    "x": "pîrnaya (m)",
    "y": " b. pirênaya (m), pirênayîye (m)"
  },
  {
    "x": "pîrnaye (n)",
    "y": " b. pirênaye (n)"
  },
  {
    "x": "pîrnayene",
    "y": " b. pirênayene"
  },
  {
    "x": "pîrnayîş (n)",
    "y": " b. pirênayîş (n)"
  },
  {
    "x": "pîro",
    "y": " b. pêro"
  },
  {
    "x": "pîro dayene",
    "y": " b. pêro dayene"
  },
  {
    "x": "Pîro (n)",
    "y": " b. Pîran (n)"
  },
  {
    "x": "pîrodayîş (n)",
    "y": " b. pêrodayîş (n)"
  },
  {
    "x": "Pîron (n)",
    "y": " b. Pîran (n)"
  },
  {
    "x": "pîroz, -e",
    "y": " kutsal, kutlu, mukaddes, mübarek, fetiş (uğurlu sayılan)\r\n~Roşanê to pîroz bo. (Bayramın kutlu olsun.)"
  },
  {
    "x": "pîroz kerdene",
    "y": " kutlamak\r\n~Ez to pîroz kena. (Seni kutlarım.)"
  },
  {
    "x": "pîrozkerdiş (n)",
    "y": " kutlama"
  },
  {
    "x": "pîrpindike (m)",
    "y": " dövme, ayran ve kızartılmış yağdan yapılan bir yemek"
  },
  {
    "x": "pîrpite (m)",
    "y": " körebe"
  },
  {
    "x": "pîrpitik (n)",
    "y": " b. pîrpitike (m)"
  },
  {
    "x": "pîrpitike (m)",
    "y": " körebe"
  },
  {
    "x": "pîrpitiki (m)",
    "y": " b. pîrpitike (m)"
  },
  {
    "x": "pîrtî (m)",
    "y": " 1)yaşlılık, ihtiyarlık\r\n~2)pirlik (Alevilikte) "
  },
  {
    "x": "pîrtîye (m)",
    "y": " 1)yaşlılık, ihtiyarlık\r\n~2)pirlik (Alevilikte) "
  },
  {
    "x": "pîrû",
    "y": " b. pêro"
  },
  {
    "x": "Pîrû (n)",
    "y": " b. Pîran (n)"
  },
  {
    "x": "Pîrûn (n)",
    "y": " b. Pîran (n)"
  },
  {
    "x": "pîrvav (n)",
    "y": " b. pîrbab (n), pîrbav (n)"
  },
  {
    "x": "pîrzola (m)",
    "y": " pirzola"
  },
  {
    "x": "pîs, -e",
    "y": " 1)pis, kirli\r\n~2)çirkin"
  },
  {
    "x": "pîs bîyene",
    "y": " kirlenmek "
  },
  {
    "x": "pîs kerdene",
    "y": " kirletmek"
  },
  {
    "x": "pîsbîyayîş (n)",
    "y": " kirlenme "
  },
  {
    "x": "pîser bîyayene",
    "y": " b. pêser bîyene"
  },
  {
    "x": "pîser kerdene",
    "y": " b. pêser kerdene"
  },
  {
    "x": "pîserbîyayîş (n)",
    "y": " b. pêserbîyayîş (n)"
  },
  {
    "x": "pîserkerdiş (n)",
    "y": " b. pêserkerdiş (n)"
  },
  {
    "x": "pîsey (m)",
    "y": " b. pîsî (m)"
  },
  {
    "x": "pîsê (m)",
    "y": " b. pîsî (m)"
  },
  {
    "x": "pîsênî (m)",
    "y": " kirlilik "
  },
  {
    "x": "pîsing, -e",
    "y": " b. pisîng, -e"
  },
  {
    "x": "pîsî (m)",
    "y": " kirlilik"
  },
  {
    "x": "pîsîng, -e",
    "y": " b. pisîng, -e"
  },
  {
    "x": "pîsîye (m)",
    "y": " kirlilik"
  },
  {
    "x": "pîskerdiş (n)",
    "y": " kirletme "
  },
  {
    "x": "pîskopos (n)",
    "y": " piskopos"
  },
  {
    "x": "pîskuvît (n)",
    "y": " b. bîskuvît (n)"
  },
  {
    "x": "pîst (n)",
    "y": " pist"
  },
  {
    "x": "pîstê maratonî (n)",
    "y": " maraton pisti"
  },
  {
    "x": "pîstene",
    "y": " b. pîştene"
  },
  {
    "x": "pîsti (m)",
    "y": " b. pistî (m)"
  },
  {
    "x": "pîstey misasî (m)",
    "y": " b. pistîya misasî (m)"
  },
  {
    "x": "pîstiş (n)",
    "y": " b. pîştiş (n)"
  },
  {
    "x": "pîstol (n)",
    "y": " b. piştol (n)"
  },
  {
    "x": "pîş (n)",
    "y": " b. pêş (n)"
  },
  {
    "x": "pîş pîş",
    "y": " b. pişê pişê"
  },
  {
    "x": "pîşi (m)",
    "y": " b. pişike (m)"
  },
  {
    "x": "pîştene",
    "y": " 1)sarmak\r\n~2)kuşanmak (kuşak, fişeklik vb. için)"
  },
  {
    "x": "pîşti",
    "y": " b. pişt"
  },
  {
    "x": "pîştik (n)",
    "y": " sargı"
  },
  {
    "x": "pîştiş (n)",
    "y": " 1)sarma\r\n~2)kuşanma (kuşak, fişeklik vb. için)"
  },
  {
    "x": "pîştî (m)",
    "y": " b. piştî (m)"
  },
  {
    "x": "pîştî dayene",
    "y": " b. piştî dayene"
  },
  {
    "x": "pît, -e",
    "y": " b. pêt, -e"
  },
  {
    "x": "pît bîyayene",
    "y": " b. pêt bîyene"
  },
  {
    "x": "pît kerdene",
    "y": " b. pêt kerdene"
  },
  {
    "x": "pîtbîyayîş (n)",
    "y": " b. pêtbîyayîş (n)"
  },
  {
    "x": "pîtey (m)",
    "y": " b. pêtîye (m)"
  },
  {
    "x": "pîtê (m)",
    "y": " b. pêtîye (m)"
  },
  {
    "x": "pîti (m)",
    "y": " b. pête (m)"
  },
  {
    "x": "pîtî (m)",
    "y": " b. pêtîye (m)"
  },
  {
    "x": "pîtîye (m)",
    "y": " b. pêtîye (m)"
  },
  {
    "x": "pîtkerdiş (n)",
    "y": " b. pêtkerdiş (n)"
  },
  {
    "x": "pîvot, -e",
    "y": " pivot"
  },
  {
    "x": "pîy (n)",
    "y": " b. pî-I (n)"
  },
  {
    "x": "pîya-I",
    "y": " 1)beraber, birlikte\r\n~2)baş başa"
  },
  {
    "x": "pîya barrayene",
    "y": " 1)bağrışmak\r\n~2)ağlaşmak"
  },
  {
    "x": "pîya berizîyayene",
    "y": " bağrışmak"
  },
  {
    "x": "pîya bermayene",
    "y": " ağlaşmak"
  },
  {
    "x": "pîya birrayene",
    "y": " bağrışmak"
  },
  {
    "x": "pîya mendene",
    "y": " beraber kalmak"
  },
  {
    "x": "pîya qarayene",
    "y": " bağrışmak"
  },
  {
    "x": "pîya qîjayene",
    "y": " bağrışmak"
  },
  {
    "x": "pîya qîrayene",
    "y": " bağrışmak"
  },
  {
    "x": "pîya tena mendene",
    "y": " baş başa kalmak"
  },
  {
    "x": "pîya zirçayene",
    "y": " bağrışmak"
  },
  {
    "x": "pîya-II (n)",
    "y": " b. peya-I (n)"
  },
  {
    "x": "pîya-III (m)",
    "y": " b. peya-II (m)"
  },
  {
    "x": "pîya-IV",
    "y": " b. pê"
  },
  {
    "x": "pîya haşt bîyayene",
    "y": " b. pêya aşt bîyene"
  },
  {
    "x": "pîya kerdene",
    "y": " b. pê kerdene"
  },
  {
    "x": "pîyabarrayîş (n)",
    "y": " 1)bağrışma\r\n~2)ağlaşma"
  },
  {
    "x": "pîyaberizîyayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "pîyabermayîş (n)",
    "y": " ağlaşma"
  },
  {
    "x": "pîyabirrayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "pîyada (m)",
    "y": " piyade"
  },
  {
    "x": "pîyade (n)",
    "y": " piyade"
  },
  {
    "x": "pîyadeyî (m)",
    "y": " piyadelik"
  },
  {
    "x": "pîyahaştbîyayîş (n)",
    "y": " b. pêyaaştbîyayîş (n)"
  },
  {
    "x": "pîyakerdiş (n)",
    "y": " b. pêkerdiş (n)"
  },
  {
    "x": "pîyamendiş (n)",
    "y": " 1)beraber kalma\r\n~2)berabere kalma (oyunda), pata"
  },
  {
    "x": "pîyanîst, -e",
    "y": " piyanist"
  },
  {
    "x": "pîyano (n)",
    "y": " piyano"
  },
  {
    "x": "pîyanocen, -e",
    "y": " piyanist"
  },
  {
    "x": "pîyanz (n)",
    "y": " b. pîyaz (n)"
  },
  {
    "x": "pîyanzo teze",
    "y": " b. pîyazo teze (n)"
  },
  {
    "x": "pîyaqarayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "pîyaqîjayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "pîyaqîrayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "pîyar (n)",
    "y": " b. pîyare (n)"
  },
  {
    "x": "pîyare (n)",
    "y": " üvey baba, babalık"
  },
  {
    "x": "pîyarê (m)",
    "y": " patika"
  },
  {
    "x": "pîyase (n)",
    "y": " piyasa"
  },
  {
    "x": "pîyase kerdene",
    "y": " piyasa etmek, dolaşmak"
  },
  {
    "x": "pîyasekerdiş (n)",
    "y": " piyasa etme, dolaşma"
  },
  {
    "x": "pîyatesfîyekerdiş (n)",
    "y": " birleşik tasfiye"
  },
  {
    "x": "pîyatîye (m)",
    "y": " b. peyatîye (m)"
  },
  {
    "x": "pîyaye (m)",
    "y": " b. peyaye (m)"
  },
  {
    "x": "pîyayene",
    "y": " b. pînîtene"
  },
  {
    "x": "pîyayîş (n)",
    "y": " b. pînîtiş (n)"
  },
  {
    "x": "pîyayîye (m)",
    "y": " b. peyayîye (m)"
  },
  {
    "x": "pîyaz (n)",
    "y": " 1)soğan\r\n~2)piyaz"
  },
  {
    "x": "pîyazo qeysî (n)",
    "y": " yeşil soğan"
  },
  {
    "x": "pîyazo teze (n)",
    "y": " yeşil soğan"
  },
  {
    "x": "pîyazirçayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "pîye-I",
    "y": " b. pê"
  },
  {
    "x": "pîye ardene",
    "y": " b. pê ardene"
  },
  {
    "x": "pîye kerdene",
    "y": " b. pê kerdene"
  },
  {
    "x": "pîye-II (n)",
    "y": " b. pî-I (n)"
  },
  {
    "x": "pîye-III (n)",
    "y": " b. peye-III (n)"
  },
  {
    "x": "pîyeardiş (n)",
    "y": " b. pêardiş (n)"
  },
  {
    "x": "pîyeç (m)",
    "y": " b. pêçe (m)"
  },
  {
    "x": "pîyegur ûntene",
    "y": " b. pêgur antene"
  },
  {
    "x": "pîyehet de",
    "y": " b. pêhet de"
  },
  {
    "x": "pîyekerdiş (n)",
    "y": " b. pêkerdiş (n)"
  },
  {
    "x": "pîyelek (n)",
    "y": " b. pêlek (n)"
  },
  {
    "x": "pîyer-I",
    "y": " b. pêro"
  },
  {
    "x": "pîyer dayîne",
    "y": " b. pêro dayene"
  },
  {
    "x": "pîyer pîya",
    "y": " b. pêro pîya "
  },
  {
    "x": "pîyer-II (n)",
    "y": " b. por-I (n)"
  },
  {
    "x": "pîyerabeste (n)",
    "y": " b. pêrabeste (n)"
  },
  {
    "x": "pîyerabesti (n)",
    "y": " b. pêrabeste (n)"
  },
  {
    "x": "pîyercen (n)",
    "y": " b. perçîn (n)"
  },
  {
    "x": "pîyerdayîş (n)",
    "y": " b. pêrodayîş (n)"
  },
  {
    "x": "pîyeresayîne",
    "y": " b. pêresayene"
  },
  {
    "x": "pîyeresayîş (n)",
    "y": " b. pêresayîş (n)"
  },
  {
    "x": "pîyeresnayîne",
    "y": " b. pêresnayene"
  },
  {
    "x": "pîyeresnayîş (n)",
    "y": " b. pêresnayîş (n)"
  },
  {
    "x": "pîyerê (m)",
    "y": " b. pîyarê (m)"
  },
  {
    "x": "pîyeri",
    "y": " b. pêro"
  },
  {
    "x": "pîyeri pîya",
    "y": " b. pêro pîya "
  },
  {
    "x": "pîyerin",
    "y": " b. pêrune"
  },
  {
    "x": "pîyerini",
    "y": " b. pêrune"
  },
  {
    "x": "pîyero",
    "y": " b. pêro"
  },
  {
    "x": "pîyero pîya",
    "y": " b. pêro pîya"
  },
  {
    "x": "pîyero dayîne",
    "y": " b. pêro dayene"
  },
  {
    "x": "pîyerodayîş (n)",
    "y": " b. pêrodayîş (n)"
  },
  {
    "x": "pîyerrê (m)",
    "y": " b. pîyarê (m)"
  },
  {
    "x": "pîyerû",
    "y": " b. pêro"
  },
  {
    "x": "pîyerû dayîne",
    "y": " b. pêro dayene"
  },
  {
    "x": "pîyerûdayîş (n)",
    "y": " b. pêrodayîş (n)"
  },
  {
    "x": "pîyes (n)",
    "y": " piyes, oyun"
  },
  {
    "x": "pîyeser ardene",
    "y": " b. pêser ardene"
  },
  {
    "x": "pîyeser bîyayene",
    "y": " b. pêser bîyene"
  },
  {
    "x": "pîyeser bîyene",
    "y": " b. pêser bîyene"
  },
  {
    "x": "pîyeser kerdene",
    "y": " b. pêser kerdene"
  },
  {
    "x": "pîyeser ûmeyîne",
    "y": " b. pêser ameyene"
  },
  {
    "x": "pîyeserardiş (n)",
    "y": "b. pêserardiş (n)"
  },
  {
    "x": "pîyeserbîyayîş (n)",
    "y": " b. pêserbîyayîş (n)"
  },
  {
    "x": "pîyeserkerdiş (n)",
    "y": " b. pêserkerdiş (n)"
  },
  {
    "x": "pîyeserûmeyîş (n)",
    "y": " b. pêserameyîş (n) "
  },
  {
    "x": "pîyesnuştox, -e",
    "y": " oyun yazarı, piyes yazarı"
  },
  {
    "x": "pîyeş (n)",
    "y": " b. pêş (n)"
  },
  {
    "x": "pîyeşmal (m)",
    "y": " b. pêşmale (m) "
  },
  {
    "x": "pîyexmer (n)",
    "y": " b. pêxamber (n)"
  },
  {
    "x": "pîyey (m)",
    "y": " b. pîyîye"
  },
  {
    "x": "pîyê (m)",
    "y": " b. pîyîye"
  },
  {
    "x": "pîyêr",
    "y": " b. pêro"
  },
  {
    "x": "pîyêro",
    "y": " b. pêro"
  },
  {
    "x": "pîyin",
    "y": " b. peyên, -e "
  },
  {
    "x": "pîyîn",
    "y": " b. peyên, -e"
  },
  {
    "x": "pîyîye (m)",
    "y": " babalık"
  },
  {
    "x": "pîyl, -i",
    "y": " b. pîl, -e"
  },
  {
    "x": "Pîyongyang (n)",
    "y": " Pyöngyang"
  },
  {
    "x": "pîyonz (n)",
    "y": " b. pîyaz (n)"
  },
  {
    "x": "pîyor",
    "y": " b. pêro "
  },
  {
    "x": "pîyor pîya",
    "y": " b. pêro pîya "
  },
  {
    "x": "pîyore",
    "y": " b. pêro"
  },
  {
    "x": "pîyoreyî",
    "y": " b. pêro"
  },
  {
    "x": "pîyorêyî",
    "y": " b. pêro"
  },
  {
    "x": "pîyori",
    "y": " b. pêro "
  },
  {
    "x": "pîyori pîya",
    "y": " b. pêro pîya"
  },
  {
    "x": "pîyorin",
    "y": " b. pêrune"
  },
  {
    "x": "pîyr",
    "y": " b. pêro "
  },
  {
    "x": "pîyûnz (n)",
    "y": " b. pîyaz (n)"
  },
  {
    "x": "pîyûnzo tezi (n)",
    "y": " b. pîyazo teze (n)"
  },
  {
    "x": "pîyûr",
    "y": " b. pêro "
  },
  {
    "x": "pîyûz (n)",
    "y": " b. pîyaz (n)"
  },
  {
    "x": "pîyze (n)",
    "y": " b. pîze (n)"
  },
  {
    "x": "pîyze ro ginayene",
    "y": " b. pîze ro ginayene"
  },
  {
    "x": "pîyze şîyayene",
    "y": " b. pîze şîyene"
  },
  {
    "x": "pîyzeroginayîş (n)",
    "y": " b. pîzeroginayîş (n)"
  },
  {
    "x": "pîyzeşîyayîş (n)",
    "y": " b. pîzeşîyayîş (n)"
  },
  {
    "x": "pîz, -e-I",
    "y": " b. pîj, -e-I"
  },
  {
    "x": "pîz bîyayene",
    "y": " b. pîj bîyene"
  },
  {
    "x": "pîz ci sanayene",
    "y": " b. pîj ci şanayene"
  },
  {
    "x": "pîz kerdene",
    "y": " b. pîj kerdene"
  },
  {
    "x": "pîz-II (n)",
    "y": " b. pîj-II (n)"
  },
  {
    "x": "pîzan (m)",
    "y": " b. pîzange (m)"
  },
  {
    "x": "pîzang (m)",
    "y": " b. pîzange (m)"
  },
  {
    "x": "pîzange (m)",
    "y": " yabanarısı "
  },
  {
    "x": "pîzbend (n)",
    "y": " b. pîzebend (n)"
  },
  {
    "x": "pîzbendik (n)",
    "y": " b. pîzebendik (n)"
  },
  {
    "x": "pîzbîyayîş (n)",
    "y": " b. pîjbîyayîş (n)"
  },
  {
    "x": "pîze (n)",
    "y": " karın"
  },
  {
    "x": "pîze bi ... veşnayene",
    "y": " acımak"
  },
  {
    "x": "pîze bi ... veşnayîş",
    "y": " acıma\r\n~Vewrike pîze bi kesî nêveşnena. (Vewrike kimseye acımıyor.)"
  },
  {
    "x": "pîze decayene",
    "y": " b. pîze dejayene"
  },
  {
    "x": "pîze dejayene",
    "y": " karnı ağrımak"
  },
  {
    "x": "pîze dezayene",
    "y": " b. pîze dejayene"
  },
  {
    "x": "pîze girewtene",
    "y": " karnı ağrımak (karnı ağrımaya başlamak)"
  },
  {
    "x": "pîze ra",
    "y": " canıgönülden, canla başla"
  },
  {
    "x": "pîze ra şîyene",
    "y": " (hayvan için) düşük yapmak"
  },
  {
    "x": "pîze ro ginayene",
    "y": " ishal olmak"
  },
  {
    "x": "pîze ro gunayene",
    "y": " b. pîze ro ginayene"
  },
  {
    "x": "pîze şîyayêne",
    "y": " b. pîze şîyene"
  },
  {
    "x": "pîze şîyene",
    "y": " ishal olmak"
  },
  {
    "x": "pîze ... veşnayene",
    "y": " b. pîze bi ... veşnayene"
  },
  {
    "x": "pîze ... veşnayîş",
    "y": " b. pîze bi ... veşnayîş"
  },
  {
    "x": "pîzew şenik (n)",
    "y": " b. pîzeyo şenik (n)"
  },
  {
    "x": "pîzewo şenik (n)",
    "y": " b. pîzeyo şenik (n)"
  },
  {
    "x": "pîzewo verên (n)",
    "y": " b. pîzeyo verên (n)"
  },
  {
    "x": "pîzey ... dekerdene",
    "y": " b. pîzeyê ... dekerdene"
  },
  {
    "x": "pîzeyê ... dekerdene",
    "y": " karnını doldurmak"
  },
  {
    "x": "pîzeyê xo de vatene",
    "y": " düşünmek"
  },
  {
    "x": "pîzeyê ... xo veşnayene",
    "y": " acındırmak\r\n~Gulnaze pîzeyê şarî xo veşna. (Gulnaze eli kendisine acındırdı.)"
  },
  {
    "x": "pîzeyê ... xoveşnayîş",
    "y": " acındırma"
  },
  {
    "x": "pîzeyo safî ra",
    "y": " canıgönülden "
  },
  {
    "x": "pîzeyo şenik (n)",
    "y": " insan vücudunun göbek ile cinsel organ arasındaki bölümü"
  },
  {
    "x": "pîzeyo verên (n)",
    "y": " ailede ilk çocuk, ilk doğum"
  },
  {
    "x": "pîzo şenik (n)",
    "y": " b. pîzeyo şenik (n)"
  },
  {
    "x": "pîzo verên (n)",
    "y": " b. pîzeyo verên (n)"
  },
  {
    "x": "pîzebend (n)",
    "y": " kolan"
  },
  {
    "x": "pîzebendik (n)",
    "y": " kolan"
  },
  {
    "x": "pîzedec (n)",
    "y": " b. pîzedej (n)"
  },
  {
    "x": "pîzedej (n)",
    "y": " karın ağrısı"
  },
  {
    "x": "pîzedez (n)",
    "y": " b. pîzedej (n)"
  },
  {
    "x": "pîzegird, -e",
    "y": " obur"
  },
  {
    "x": "pîzepîl, -e",
    "y": " obur"
  },
  {
    "x": "pîzeroginayîş (n)",
    "y": " amel, ishal, ötürük, sürgün"
  },
  {
    "x": "pîzerogunayîş (n)",
    "y": " b. pîzeroginayîş (n)"
  },
  {
    "x": "pîzeşeyîş (n)",
    "y": " b. pîzeşîyayîş (n)"
  },
  {
    "x": "pîzeşîyayîş (n)",
    "y": " amel, ishal, ötürük, sürgün"
  },
  {
    "x": "pîzeveşn, -e",
    "y": " merhametli"
  },
  {
    "x": "pîzeveşn bîyene",
    "y": " merhametli olmak"
  },
  {
    "x": "pîzeveşnênî (m)",
    "y": " merhametlilik"
  },
  {
    "x": "pîzeveşnî (m)",
    "y": " merhametlilik"
  },
  {
    "x": "pîzeveşnîye (m)",
    "y": " merhametlilik"
  },
  {
    "x": "pîzexişin, -i",
    "y": " b. pîzexişn, -e"
  },
  {
    "x": "pîzexişn, -e",
    "y": " obur"
  },
  {
    "x": "pîzeyêxodevatiş (n)",
    "y": " düşünme"
  },
  {
    "x": "pîzi (n)",
    "y": " b. pîze (n)"
  },
  {
    "x": "pîzigird, -i",
    "y": " b. pîzegird"
  },
  {
    "x": "pîziveşn, -i",
    "y": " b. pîzeveşn, -e"
  },
  {
    "x": "pîziveşn bîyene",
    "y": " b. pîzeveşn bîyene"
  },
  {
    "x": "pîziveşnê (m)",
    "y": " b. pîzeveşnîye (m), pîzeveşnî (m)"
  },
  {
    "x": "pîzî (zh)",
    "y": " b. pîjî (zh)"
  },
  {
    "x": "pîzkerdiş (n)",
    "y": " b. pîjkerdiş (n)"
  },
  {
    "x": "pîzza (m)",
    "y": " piza"
  },
  {
    "x": "pîzzarotox, -e",
    "y": " pizacı"
  },
  {
    "x": "pîzzaviraştox, -e",
    "y": " pizacı"
  },
  {
    "x": "pîzze (n)",
    "y": " b. pîze (n)"
  },
  {
    "x": "pîzze ra",
    "y": " b. pîze ra"
  },
  {
    "x": "pîzzedo safî ra",
    "y": " b. pîzeyo safî ra"
  },
  {
    "x": "pîzzew safî ra",
    "y": " b. pîzeyo safî ra "
  },
  {
    "x": "pîzzepîl, -i",
    "y": " b. pîzepîl, -e"
  },
  {
    "x": "pîzzeveşn, -i",
    "y": " b. pîzeveşn, -e"
  },
  {
    "x": "pîzzeveşn bîyene",
    "y": " b. pîzeveşn bîyene"
  },
  {
    "x": "pîzzeveşney (m)",
    "y": " b. pîzeveşnîye (m)"
  },
  {
    "x": "pîzzexişn, -i",
    "y": " b. pîzexişn, -e"
  },
  {
    "x": "plaj (n)",
    "y": " plaj"
  },
  {
    "x": "plaka (m)",
    "y": " plaka"
  },
  {
    "x": "plakaya erebeyî (m)",
    "y": " oto plakası, araba plakası"
  },
  {
    "x": "plakaya otomobîle (m)",
    "y": " oto plakası"
  },
  {
    "x": "plakaya taksî (m)",
    "y": " oto plakası"
  },
  {
    "x": "plakaya wasita (m)",
    "y": " araç plakası"
  },
  {
    "x": "plakaya wesayîtî (n)",
    "y": " araç plakası"
  },
  {
    "x": "plakayê bazirganî (zh)",
    "y": " ticari plakalar"
  },
  {
    "x": "plakayê tîcarî (zh)",
    "y": " ticari plakalar"
  },
  {
    "x": "plan (n)",
    "y": " plan"
  },
  {
    "x": "plan kerdene",
    "y": " planlamak"
  },
  {
    "x": "planê aksîyonî (n)",
    "y": " eylem planı"
  },
  {
    "x": "planê awanî (n)",
    "y": " imar planı"
  },
  {
    "x": "planê awanî yê caardişî (n)",
    "y": " uygulama imar planı"
  },
  {
    "x": "planê awanî yê seba pawitişî (n)",
    "y": " koruma amaçlı imar planı"
  },
  {
    "x": "planê awanî yê seba seveknayîşî (n)",
    "y": " koruma amaçlı imar planı"
  },
  {
    "x": "planê awanîya cayî (n)",
    "y": " mevzi imar planı"
  },
  {
    "x": "planê bingeyên yê resî (n)",
    "y": " ulaşım ana planı"
  },
  {
    "x": "planê cayî (n)",
    "y": " konum planı"
  },
  {
    "x": "planê çalakî (n)",
    "y": " eylem planı"
  },
  {
    "x": "planê hesabî (n)",
    "y": " hesap planı"
  },
  {
    "x": "planê karî (n)",
    "y": " iş planı"
  },
  {
    "x": "planê mekankîyî (zh)",
    "y": " mekânsal planlar"
  },
  {
    "x": "planê nîzamê doruverî (n)",
    "y": " çevre düzeni planı"
  },
  {
    "x": "planê parselkerdişî (n)",
    "y": " parselasyon planı"
  },
  {
    "x": "planê pergalê çorşmeyî (n)",
    "y": " çevre düzeni planı"
  },
  {
    "x": "planê pergalkar yê awanî (n)",
    "y": " imar nâzım planı"
  },
  {
    "x": "planê projeyî (n)",
    "y": " proje planı"
  },
  {
    "x": "planê rewşa lezabezî (n)",
    "y": " acil durum planı"
  },
  {
    "x": "planê rewşa lezabezîye (n)",
    "y": " acil durum planı"
  },
  {
    "x": "planê wezîyetî (n)",
    "y": " konum planı, vaziyet planı"
  },
  {
    "x": "planê xebate (n)",
    "y": " çalışma planı"
  },
  {
    "x": "planê zemanî (n)",
    "y": " zaman planı"
  },
  {
    "x": "plano bejnekî (sn)",
    "y": " boy plan"
  },
  {
    "x": "plano doşkî (sn)",
    "y": " omuz plan"
  },
  {
    "x": "plano hemeyî (sn)",
    "y": " genel plan"
  },
  {
    "x": "plano herêmî (n)",
    "y": " bölgesel plan"
  },
  {
    "x": "plano kiftkî (sn)",
    "y": " omuz plan"
  },
  {
    "x": "plano mîyanekî (sn)",
    "y": " bel plan"
  },
  {
    "x": "plano nêzdîkî (sn)",
    "y": " yakın plan"
  },
  {
    "x": "plano pergalkar (n)",
    "y": " nâzım planı"
  },
  {
    "x": "plano pêroyî (sn)",
    "y": " genel plan"
  },
  {
    "x": "plano qamekî (sn)",
    "y": " boy plan"
  },
  {
    "x": "plano sênekî (sn)",
    "y": " göğüs plan"
  },
  {
    "x": "planker, -e",
    "y": " plancı"
  },
  {
    "x": "plankera/ê bajarî",
    "y": " şehir plancısı"
  },
  {
    "x": "plankera/ê şaristanî",
    "y": " şehir plancısı"
  },
  {
    "x": "plankerda (m)",
    "y": " planlanan"
  },
  {
    "x": "plankerde (n)",
    "y": " planlanan"
  },
  {
    "x": "plankerdiş (n)",
    "y": " planlama"
  },
  {
    "x": "plankerdişê aîle (m)",
    "y": " aile planlaması"
  },
  {
    "x": "plankerdişê çimeyanê îmalatî (n)",
    "y": " imalat kaynak planlaması"
  },
  {
    "x": "plankerdişê herême (n)",
    "y": " bölge planlaması"
  },
  {
    "x": "plankerdişê hêrînayîşî (n)",
    "y": " satın alma planlaması"
  },
  {
    "x": "plankerdişê îhaleyî (n)",
    "y": " ihale planlaması"
  },
  {
    "x": "plankerdişê keyeyî (m)",
    "y": " aile planlaması"
  },
  {
    "x": "plankerdişê projeyî (n)",
    "y": " proje planlama"
  },
  {
    "x": "plankerdişo bajarkî (n)",
    "y": " kentsel planlama"
  },
  {
    "x": "plankerdişo şaristankî (n)",
    "y": " kentsel planlama"
  },
  {
    "x": "plankerdî, -ye",
    "y": " planlanan"
  },
  {
    "x": "plaqa (m)",
    "y": " b. plaka (m)"
  },
  {
    "x": "plaqe (m)",
    "y": " b. plaka (m)"
  },
  {
    "x": "plastîk (n)",
    "y": " plastik"
  },
  {
    "x": "platform (n)",
    "y": " platform"
  },
  {
    "x": "PLC (Programmable Logic Controller)",
    "y": " PLC"
  },
  {
    "x": "pluralîst, -e",
    "y": " çoğulcu, plüralist"
  },
  {
    "x": "pluralîzm (n)",
    "y": " çoğulculuk, plüralizm"
  },
  {
    "x": "pluvîyometre (n)",
    "y": " plüviyometre, yağışölçer"
  },
  {
    "x": "po ginayene",
    "y": " b. piro ginayene"
  },
  {
    "x": "poalizîyayene",
    "y": " tırmanmak"
  },
  {
    "x": "poalizîyayîş (n)",
    "y": " tırmanma"
  },
  {
    "x": "poazîyayene",
    "y": " b. poalizîyayene"
  },
  {
    "x": "poazîyayîş (n)",
    "y": " b. poalizîyayîş (n)"
  },
  {
    "x": "pobîyayîş (n)",
    "y": " dama, satranç vb. oyunlarda rakibin taşını ütebilecek durumda olma"
  },
  {
    "x": "pocîyayene",
    "y": " b. pewjîyayene"
  },
  {
    "x": "pocîyayîş (n)",
    "y": " b. pewjîyayîş (n)"
  },
  {
    "x": "poç (m)",
    "y": " b. boçe (m)"
  },
  {
    "x": "poç (n)",
    "y": " b. boçe (m)"
  },
  {
    "x": "poçax (n)",
    "y": " (alna düşen kısa kesilmiş saç) perçem, kâkül"
  },
  {
    "x": "poçe (m)",
    "y": " b. boçe (m)"
  },
  {
    "x": "poçi (m)",
    "y": " b. boçe (m)"
  },
  {
    "x": "poçik (n)",
    "y": " b. boçik (n)"
  },
  {
    "x": "poginayîş (n)",
    "y": " b. piroginayîş (n)"
  },
  {
    "x": "poj",
    "y": " b. panc"
  },
  {
    "x": "poj sey",
    "y": " b. panc sey"
  },
  {
    "x": "pojîyaya (m)",
    "y": " b. pewjîyaya (m)"
  },
  {
    "x": "pojîyaye (n)",
    "y": " b. pewjîyaye (n)"
  },
  {
    "x": "pojîyayene",
    "y": " b. pewjîyayene"
  },
  {
    "x": "pojîyayêne",
    "y": " b. pewjîyayene"
  },
  {
    "x": "pojîyayîş (n)",
    "y": " b. pewjîyayîş (n)"
  },
  {
    "x": "pojîyayîye (m)",
    "y": " b. pewjîyayîye (m)"
  },
  {
    "x": "pojnayene",
    "y": " b. pewjnayene"
  },
  {
    "x": "pojnayîş (n)",
    "y": " b. pewjnayîş (n)"
  },
  {
    "x": "pojşeme (n)",
    "y": " b. panşeme (n)"
  },
  {
    "x": "pokerdene",
    "y": " takmak (-e takmak)"
  },
  {
    "x": "pokerdiş (n)",
    "y": " takma (-e takmak)"
  },
  {
    "x": "pol-I (n)",
    "y": " 1)kol\r\n~2)omuz\r\n~3)kanat, taraf\r\n~4)hayvana yüklenen odun vb. yükünde yükün iki tarafından her biri"
  },
  {
    "x": "pol û bask",
    "y": " kol kanat"
  },
  {
    "x": "polê xo to kerdene",
    "y": " kollarını çemremek¸ kollarını sıvamak"
  },
  {
    "x": "polî to kerdene",
    "y": " kolları çemremek, kolları sıvamak"
  },
  {
    "x": "polo çep (n)",
    "y": " sol kol, sol kanat, sol taraf"
  },
  {
    "x": "polo rast/raşt (n)",
    "y": " sağ kol, sağ kanat, sağ taraf"
  },
  {
    "x": "pol-II (n)",
    "y": " 1)bez\r\n~2)yama"
  },
  {
    "x": "pol bîyene",
    "y": " yamalanmak, yamanmak"
  },
  {
    "x": "pol kerdene",
    "y": " yamalamak, yamamak"
  },
  {
    "x": "polê nimajî (n)",
    "y": " namazlık"
  },
  {
    "x": "pol-III (n)",
    "y": " b. pele (m)"
  },
  {
    "x": "pol ... mendiş",
    "y": " b. pele ... mendiş"
  },
  {
    "x": "pol-IV (n)",
    "y": " b. pil (n)"
  },
  {
    "x": "pola (n)",
    "y": " çelik, polat"
  },
  {
    "x": "polad (n)",
    "y": " çelik, polat"
  },
  {
    "x": "poladên, -e",
    "y": " çelikten (çelikten yapılmış)"
  },
  {
    "x": "poladin, -e",
    "y": " çelikli"
  },
  {
    "x": "polane (m)",
    "y": " b. palane (m)"
  },
  {
    "x": "polarîte (m)",
    "y": " polarite"
  },
  {
    "x": "polat (n)",
    "y": " b. polad (n)"
  },
  {
    "x": "polate (m)",
    "y": " mermi, gülle, kurşun\r\n~Polate ro ci ginaya. (Mermi ona isabet etmiş.)"
  },
  {
    "x": "polatên, -e",
    "y": " b. poladên, -e"
  },
  {
    "x": "polayên, -e",
    "y": " çelikten (çelikten yapılmış)"
  },
  {
    "x": "polayin, -e",
    "y": " çelikli"
  },
  {
    "x": "polbîyaya (m)",
    "y": " yamalı"
  },
  {
    "x": "polbîyaye (n)",
    "y": " yamalı"
  },
  {
    "x": "polbîyayîş (n)",
    "y": " yamalanma"
  },
  {
    "x": "polbîyayîye (m)",
    "y": " yamalı"
  },
  {
    "x": "poldim (n)",
    "y": " b. paldim (n)"
  },
  {
    "x": "pole (m)",
    "y": " b. pale-II (m)"
  },
  {
    "x": "polek (n)",
    "y": " b. pelik (n)"
  },
  {
    "x": "polêfîyayene",
    "y": " tırmanmak"
  },
  {
    "x": "polêfîyayîş (n)",
    "y": " tırmanma"
  },
  {
    "x": "polês, -e",
    "y": " b. polîs, -e"
  },
  {
    "x": "polik (n)",
    "y": " b. pelik (n)"
  },
  {
    "x": "polin, -e",
    "y": " yamalı"
  },
  {
    "x": "polis, -e",
    "y": " b. polîs, -e"
  },
  {
    "x": "polîgon (n)",
    "y": " silah atış yeri, atış yeri, poligon"
  },
  {
    "x": "polîmer (n)",
    "y": " polimer (kimyada)"
  },
  {
    "x": "polîs, -e",
    "y": " polis, aynasız (argo)"
  },
  {
    "x": "polîsaj (n)",
    "y": " polisaj"
  },
  {
    "x": "polîsajker, -e",
    "y": " polisajcı"
  },
  {
    "x": "polîsey (m)",
    "y": " b. polîsî (m)"
  },
  {
    "x": "polîsê (m)",
    "y": " b. polîsî (m)"
  },
  {
    "x": "polîsî (m)",
    "y": " polislik"
  },
  {
    "x": "polîsîye (m)",
    "y": " polislik"
  },
  {
    "x": "polîtîk, -e",
    "y": " politik"
  },
  {
    "x": "polîtîka (m)",
    "y": " politika"
  },
  {
    "x": "polîtîkayê bingeyênî (zh)",
    "y": " temel politikalar"
  },
  {
    "x": "polîtîkkar, -e",
    "y": " siyasacı, siyasetçi, politikacı"
  },
  {
    "x": "polîtîkkarî (m)",
    "y": " siyasacılık, siyasetçilik, politikacılık"
  },
  {
    "x": "polîtîkkarîye (m)",
    "y": " siyasacılık, siyasetçilik, politikacılık"
  },
  {
    "x": "polîyefîyayîne",
    "y": " b. polêfîyayene"
  },
  {
    "x": "polîyefîyayîş",
    "y": " b. polêfîyayîş (n)"
  },
  {
    "x": "polkerda (m)",
    "y": " yamalı"
  },
  {
    "x": "polkerde (n)",
    "y": " yamalı"
  },
  {
    "x": "polkerdiş (n)",
    "y": " yamalama"
  },
  {
    "x": "polkerdîye (m)",
    "y": " yamalı"
  },
  {
    "x": "Polonya (n)",
    "y": " Polonya "
  },
  {
    "x": "polos (n)",
    "y": " b. palas (n)"
  },
  {
    "x": "polos, -e",
    "y": " b. polîs, -e"
  },
  {
    "x": "polverdayîş (n)",
    "y": " b. peleverdayîş (n)"
  },
  {
    "x": "pompa (m)",
    "y": " pompa"
  },
  {
    "x": "pompaya awbaze (m)",
    "y": " dalgıç pompa"
  },
  {
    "x": "pompaya bi mîlê verardkî (m)",
    "y": " yatay milli pompa"
  },
  {
    "x": "pompaya çamure (m)",
    "y": " çamur pompası"
  },
  {
    "x": "pompaya lince (m)",
    "y": " çamur pompası"
  },
  {
    "x": "pompaya qumî (m)",
    "y": " kum pompası"
  },
  {
    "x": "pompaya rûnî (n)",
    "y": " yağ pompası"
  },
  {
    "x": "pompaya santrîfuje (m)",
    "y": " santrifüj pompa"
  },
  {
    "x": "pompaya xîzî (m)",
    "y": " kum pompası"
  },
  {
    "x": "pompoli (m)",
    "y": " b. papûre (m)"
  },
  {
    "x": "pon, -e-I",
    "y": " b. pan, -e-I"
  },
  {
    "x": "pon-II",
    "y": " b. panc "
  },
  {
    "x": "pon sey",
    "y": " b. panc sey"
  },
  {
    "x": "ponayene",
    "y": " b. panayene"
  },
  {
    "x": "ponayîş (n)",
    "y": " b. panayîş (n)"
  },
  {
    "x": "ponc",
    "y": " b. panc "
  },
  {
    "x": "ponc sey",
    "y": " b. panc sey "
  },
  {
    "x": "poncas",
    "y": " b. pancas "
  },
  {
    "x": "ponce (n)",
    "y": " b. pance (n)"
  },
  {
    "x": "poncemêna",
    "y": " b. panc hemîne"
  },
  {
    "x": "poncetîr (n)",
    "y": " bir tüfek çeşidi"
  },
  {
    "x": "poncês",
    "y": " b. pancês "
  },
  {
    "x": "poncin, -e",
    "y": " b. pancin, -e"
  },
  {
    "x": "poncîn, -e",
    "y": " b. pancin, -e"
  },
  {
    "x": "ponç",
    "y": " b. panc "
  },
  {
    "x": "ponçe (n)",
    "y": " b. pance (n)"
  },
  {
    "x": "ponçik (n)",
    "y": " b. pancik (n)"
  },
  {
    "x": "ponit (n)",
    "y": " b. pangnote (m)"
  },
  {
    "x": "ponîyaya (m)",
    "y": " b. panîyaya (m), panîyayîye (m)"
  },
  {
    "x": "ponîyaye (n)",
    "y": " b. panîyaye (n)"
  },
  {
    "x": "ponîyayene",
    "y": " b. panîyayene"
  },
  {
    "x": "ponîyayîş (n)",
    "y": " b. panîyayîş (n)"
  },
  {
    "x": "ponîye (m)",
    "y": " b. panîye (m)"
  },
  {
    "x": "ponj",
    "y": " b. panc"
  },
  {
    "x": "ponk, -i",
    "y": " b. pak, -e"
  },
  {
    "x": "ponk kerdene",
    "y": " b. pak kerdene"
  },
  {
    "x": "ponka (s)",
    "y": " b. paka (s)"
  },
  {
    "x": "ponkerdiş (n)",
    "y": " b. pakkerdiş (n)"
  },
  {
    "x": "pontîle (m)",
    "y": " b. patîle-I (m)"
  },
  {
    "x": "pontîli (m)",
    "y": " b. patîle-I (m)"
  },
  {
    "x": "pontolî (zh)",
    "y": " b. pantolî (zh)"
  },
  {
    "x": "pontorî (zh)",
    "y": " b. pantolî (zh)"
  },
  {
    "x": "pontorrî (zh)",
    "y": " b. pantolî (zh)"
  },
  {
    "x": "ponz",
    "y": " b. panc"
  },
  {
    "x": "populasyon (n)",
    "y": " evren (araştırma için)"
  },
  {
    "x": "populasyon û komnimûne",
    "y": " evren ve örneklem (araştırma için)"
  },
  {
    "x": "populasyon û nimûnegerî",
    "y": " evren ve örnekleme (araştırma için)"
  },
  {
    "x": "por-I (n)",
    "y": " saç"
  },
  {
    "x": "por-II",
    "y": " b. pêro"
  },
  {
    "x": "porcini (m)",
    "y": " b. pirojine (m)"
  },
  {
    "x": "pornografî (m)",
    "y": " pornografi "
  },
  {
    "x": "pornografîk (s)",
    "y": " pornografik"
  },
  {
    "x": "porr (n)",
    "y": " b. por-I (n)"
  },
  {
    "x": "porselen (n)",
    "y": " porselen"
  },
  {
    "x": "port (n) (TE)",
    "y": " kapı"
  },
  {
    "x": "porto paralel (n) (TE)",
    "y": " paralel kapı"
  },
  {
    "x": "porto rêzkî (n) (TE)",
    "y": " dizisel kapı"
  },
  {
    "x": "Port Moresbî",
    "y": " Port Moresby"
  },
  {
    "x": "Port Vîla (m)",
    "y": " Port Vila"
  },
  {
    "x": "Port-o-Prens (n)",
    "y": " Port-au-Prince"
  },
  {
    "x": "portal (n)",
    "y": " portal"
  },
  {
    "x": "portatîf, -e",
    "y": " portatif"
  },
  {
    "x": "porteqale (m)",
    "y": " portakal"
  },
  {
    "x": "porteqalêre (m)",
    "y": " portakal ağacı"
  },
  {
    "x": "Porto-Novo (n)",
    "y": " Porto-Novo"
  },
  {
    "x": "portre (n)",
    "y": " portre "
  },
  {
    "x": "Portugal (n)",
    "y": " Portekiz "
  },
  {
    "x": "pos (n)",
    "y": " pos "
  },
  {
    "x": "poseme (n)",
    "y": " b. panşeme (n)"
  },
  {
    "x": "posin,-e",
    "y": " pasaklı"
  },
  {
    "x": "posinênî (m)",
    "y": " pasaklılık"
  },
  {
    "x": "posinî (m)",
    "y": " pasaklılık"
  },
  {
    "x": "posinîye (m)",
    "y": " pasaklılık"
  },
  {
    "x": "posîye (m)",
    "y": " b. puşîye (m)"
  },
  {
    "x": "post-I (n)",
    "y": " b. poste-I (m)"
  },
  {
    "x": "post-II (m)",
    "y": " b. piştî (m)"
  },
  {
    "x": "post produksîyon (sn)",
    "y": " post prodüksiyon"
  },
  {
    "x": "postal (n)",
    "y": " ayakkabı, pabuç, başmak"
  },
  {
    "x": "postalê mewsimî (zh)",
    "y": " mevsimlik ayakkabı"
  },
  {
    "x": "postalboyaxker, -e",
    "y": " ayakkabı boyacısı"
  },
  {
    "x": "postalcîni (m)",
    "y": " b. postalçîyênî (m)"
  },
  {
    "x": "postalcîyênî (m)",
    "y": " b. postalçîyênî (m)"
  },
  {
    "x": "postalçî, -ye",
    "y": " ayakkabıcı (yapan ya da satan), pabuççu, başmakçı"
  },
  {
    "x": "postalçînî (m)",
    "y": " ayakkabıcılık"
  },
  {
    "x": "postalçîyênî (m)",
    "y": " ayakkabıcılık"
  },
  {
    "x": "poste-I (n)",
    "y": " 1)deri\r\n~2)kabuk, nohut kabuğu"
  },
  {
    "x": "posteyê mêşna/mî/mîye (n)",
    "y": " meşin"
  },
  {
    "x": "posteyê nimajî (n)",
    "y": " namazlık"
  },
  {
    "x": "poste sero kerdene",
    "y": " kabuk soymak"
  },
  {
    "x": "poste sero şîyayene",
    "y": " b. poste sero şîyene"
  },
  {
    "x": "poste sero şîyene",
    "y": " kavlamak, kabuğu kabarıp dökülmek, kabuğu kabarıp soyulmak"
  },
  {
    "x": "poste-II (n)",
    "y": " posta"
  },
  {
    "x": "posteber, -e",
    "y": " postacı"
  },
  {
    "x": "posteberî (m)",
    "y": " postacılık"
  },
  {
    "x": "posteberîye (m)",
    "y": " postacılık"
  },
  {
    "x": "posteseroşîyayîş (n)",
    "y": " kavlama"
  },
  {
    "x": "postexane (n)",
    "y": " postahane"
  },
  {
    "x": "postexone (n)",
    "y": " b. postexane (n)"
  },
  {
    "x": "postexûne (n)",
    "y": " b. postexane (n)"
  },
  {
    "x": "posti (n)",
    "y": " b. poste (n)"
  },
  {
    "x": "postik (n)",
    "y": " deri"
  },
  {
    "x": "postî (m)",
    "y": " b. piştî (m)"
  },
  {
    "x": "postmodern, -e",
    "y": " postmodern "
  },
  {
    "x": "postmodernîzm (n)",
    "y": " postmodernizm"
  },
  {
    "x": "poşayene",
    "y": " b. poşîyayene"
  },
  {
    "x": "poşayîş (n)",
    "y": " b. poşîyayîş (n)"
  },
  {
    "x": "poşî (m)",
    "y": " b. puşî (m)"
  },
  {
    "x": "poşît (m)",
    "y": " b. piştî (m)"
  },
  {
    "x": "poşîyayene",
    "y": " tırmanmak"
  },
  {
    "x": "poşîyayîş (n)",
    "y": " tırmanma"
  },
  {
    "x": "poşîye (m)",
    "y": " b. puşîye (m)"
  },
  {
    "x": "poşna (m)",
    "y": " b. paşna-I (m)"
  },
  {
    "x": "poşte (m)",
    "y": " b. piştî (m)"
  },
  {
    "x": "poştî (m)",
    "y": " b. piştî (m)"
  },
  {
    "x": "poştî dayene",
    "y": " b. piştî dayene"
  },
  {
    "x": "poştîya xo dayene",
    "y": " b. piştîya xo dayene"
  },
  {
    "x": "poştîya xo dayîş (n)",
    "y": " b. piştîya xo dayîş (n)"
  },
  {
    "x": "poştîdayîş (n)",
    "y": " b. piştîdayîş (n)"
  },
  {
    "x": "pot-I (n)",
    "y": " bez, çaput, paçavra"
  },
  {
    "x": "pot û pertalî (zh)",
    "y": " eski püskü giysi(ler)"
  },
  {
    "x": "potê tutan (n)",
    "y": " çocuk bezi, etek bezi"
  },
  {
    "x": "potê tûtan (n)",
    "y": " b. potê tutan (n) "
  },
  {
    "x": "pot-II (n)",
    "y": " pot "
  },
  {
    "x": "pot-II (n)",
    "y": " pile, pli"
  },
  {
    "x": "pot kerdene",
    "y": " pilelemek"
  },
  {
    "x": "pota (m)",
    "y": " b. pewta (m)"
  },
  {
    "x": "pote-I (m)",
    "y": " elbise, giyecek, giysi, kılık"
  },
  {
    "x": "pote-II (n)",
    "y": " b. pewte (n)"
  },
  {
    "x": "potene",
    "y": " b. pewtene"
  },
  {
    "x": "poti (n)",
    "y": " b. pewte (n)"
  },
  {
    "x": "potik (n)",
    "y": " bez, çaput, paçavra"
  },
  {
    "x": "potikin, -e",
    "y": " 1)büzük (büzülmüş)\r\n~2)pileli"
  },
  {
    "x": "potis (n)",
    "y": " b. pewtiş (n)"
  },
  {
    "x": "potiş (n)",
    "y": " b. pewtiş (n)"
  },
  {
    "x": "potî (zh)",
    "y": " üst baş (giysiler)"
  },
  {
    "x": "potîl (m)",
    "y": " b. patîle-I (m)"
  },
  {
    "x": "potîli (m)",
    "y": " b. patîle-I (m)"
  },
  {
    "x": "potîn (n)",
    "y": " postal, potin"
  },
  {
    "x": "potîne (m)",
    "y": " b. potîn (n)"
  },
  {
    "x": "potîye (m)",
    "y": " b. pewtîye (m)"
  },
  {
    "x": "potkerdiş (n)",
    "y": " pileleme"
  },
  {
    "x": "povejayene",
    "y": " b. povejîyayene"
  },
  {
    "x": "povejayîş (n)",
    "y": " b. povejîyayîş (n)"
  },
  {
    "x": "povejîyayene",
    "y": " tırmanmak"
  },
  {
    "x": "povejîyayîş (n)",
    "y": " tırmanma"
  },
  {
    "x": "povetene",
    "y": " hissetmek, sezinlemek, sezmek"
  },
  {
    "x": "povetiş (n)",
    "y": " hissetme, sezinleme, sezme"
  },
  {
    "x": "powjîyaya (m)",
    "y": " b. pewjîyaya (m)"
  },
  {
    "x": "powjîyaye (n)",
    "y": " b. pewjîyaye (n)"
  },
  {
    "x": "powjîyayene",
    "y": " b. pewjîyayene"
  },
  {
    "x": "powjîyayîş (n)",
    "y": " b. pewjîyayîş (n)"
  },
  {
    "x": "powjnayene",
    "y": " b. pewjnayene"
  },
  {
    "x": "powjnayîş (n)",
    "y": " b. pewjnayîş (n)"
  },
  {
    "x": "powta (m)",
    "y": " b. pewta (m)"
  },
  {
    "x": "powte (n)",
    "y": " b. pewte (n)"
  },
  {
    "x": "powtene",
    "y": " b. pewtene"
  },
  {
    "x": "powtiş (n)",
    "y": " b. pewtiş (n)"
  },
  {
    "x": "powuteni",
    "y": " b. pawitene"
  },
  {
    "x": "powutiş (n)",
    "y": " b. pawitiş (n)"
  },
  {
    "x": "poxmag (n)",
    "y": " harman savrulurken biriken çok ince saman"
  },
  {
    "x": "poxnoti (m)",
    "y": " b. pangnote (m)"
  },
  {
    "x": "poyaye (n)",
    "y": " çürük"
  },
  {
    "x": "poyayene",
    "y": " çürümek"
  },
  {
    "x": "poyayeyî (m)",
    "y": " çürüklük"
  },
  {
    "x": "poyayêne",
    "y": " b. poyayene"
  },
  {
    "x": "poyayênî (m)",
    "y": " çürüklük"
  },
  {
    "x": "poyayîne",
    "y": " b. poyayene"
  },
  {
    "x": "poyayîş (n)",
    "y": " çürüme"
  },
  {
    "x": "poyayî (n)",
    "y": " b. boyaye (n)"
  },
  {
    "x": "poyayîye (m)",
    "y": " çürük"
  },
  {
    "x": "poyînaîs (n)",
    "y": " b. poynayîş (n)"
  },
  {
    "x": "poyînayîs (n)",
    "y": " b. poynayîş (n)"
  },
  {
    "x": "poyînayne",
    "y": " b. poynayene"
  },
  {
    "x": "poyîyaîs (n)",
    "y": " b. poyayîş (n)"
  },
  {
    "x": "poyîyayene",
    "y": " b. poyayene"
  },
  {
    "x": "poyîyayîs (n)",
    "y": " b. poyayîş (n)"
  },
  {
    "x": "poynayene",
    "y": " çürütmek"
  },
  {
    "x": "poynayîş (n)",
    "y": " çürütme"
  },
  {
    "x": "pozgurê dare bîyene",
    "y": " arka ayakları üzerine kalkarak ön ayaklarını ağacın yüksekçe bir yerine dayamak (hayvan için)"
  },
  {
    "x": "pozik (n)",
    "y": " döven sürerken ürünü yememesi için öküzün ağzına takılan ağızlık"
  },
  {
    "x": "pozikê çarixî (n)",
    "y": " çarık burnu"
  },
  {
    "x": "pozikê solî (n)",
    "y": " ayakkabı burnu"
  },
  {
    "x": "pozîtîf, -e",
    "y": " olumlu, pozitif"
  },
  {
    "x": "pozîsyon (n)",
    "y": " 1)vaziyet\r\n~2)pozisyon\r\n~3)konum"
  },
  {
    "x": "pozmik (n)",
    "y": " hızma"
  },
  {
    "x": "pozmikine (m)",
    "y": " hızmalı"
  },
  {
    "x": "Prag (n)",
    "y": " Prag"
  },
  {
    "x": "pratîk, -e",
    "y": " tatbikî, uygulamalı, pratik"
  },
  {
    "x": "prefîks (n)",
    "y": " önek"
  },
  {
    "x": "prensîp (n)",
    "y": " ilke, prensip"
  },
  {
    "x": "press",
    "y": " basınç"
  },
  {
    "x": "pressin, -e",
    "y": " basınçlı"
  },
  {
    "x": "Pretorya (m)",
    "y": " Pretoria"
  },
  {
    "x": "prifesor, -e",
    "y": " profesör"
  },
  {
    "x": "prîm (n)",
    "y": " prim"
  },
  {
    "x": "prîmê sîgorta (n)",
    "y": " sigorta primi"
  },
  {
    "x": "prîmîtîf, e",
    "y": " barbar"
  },
  {
    "x": "prîmîtîfîye (m)",
    "y": " barbarlık"
  },
  {
    "x": "prîz (n)",
    "y": " priz"
  },
  {
    "x": "prîzê elektrîkî (n)",
    "y": " elektrik prizi"
  },
  {
    "x": "prîze (m)",
    "y": " b. prîz (n) "
  },
  {
    "x": "prîza alatirkî (m)",
    "y": " b. prîzê elektrîkî (n)"
  },
  {
    "x": "prîzma (m)",
    "y": " prizma"
  },
  {
    "x": "problem (n)",
    "y": " mesele, sorun, problem (mat)"
  },
  {
    "x": "problemê hewnî (n)",
    "y": " uyku problemi"
  },
  {
    "x": "produksîyon (n, sn)",
    "y": " 1)prodüksiyon, yapım\r\n~2)üretim"
  },
  {
    "x": "produksîyono yewbîyaye (n)",
    "y": " birleşik üretim"
  },
  {
    "x": "produktor, -e",
    "y": " 1)yapımcı, film yapımcısı, prodüktör\r\n~2)üretici"
  },
  {
    "x": "produktora/produktorê îdarî (sn)",
    "y": " idari yapımcı"
  },
  {
    "x": "profesor, -e",
    "y": " profesör"
  },
  {
    "x": "profesyonel, -e",
    "y": " profesyonel"
  },
  {
    "x": "program (n)",
    "y": " program"
  },
  {
    "x": "programê antîvîrusî (n) (TE)",
    "y": " antivirüs programı"
  },
  {
    "x": "programê perwerdeyî (n)",
    "y": " eğitim programı"
  },
  {
    "x": "programê projeyî (n)",
    "y": " proje programı"
  },
  {
    "x": "programo bingeyî (n)",
    "y": " ana program"
  },
  {
    "x": "programo hedef (n)",
    "y": " hedef program"
  },
  {
    "x": "programker, -e",
    "y": " programcı"
  },
  {
    "x": "îstasyona karî ya programkere (m)",
    "y": " programcı iş istasyonu"
  },
  {
    "x": "programviraştiş (n) (TE)",
    "y": " programlama"
  },
  {
    "x": "programviraştox, -e (TE)",
    "y": " programcı"
  },
  {
    "x": "programviraştoxa komputure (m)",
    "y": " bilgisayar programcısı"
  },
  {
    "x": "programviraştoxê komputure (n)",
    "y": " bilgisayar programcısı"
  },
  {
    "x": "proje (n)",
    "y": " tasarı, proje"
  },
  {
    "x": "projeyê bedilnayîşî (n)",
    "y": " tadilat projesi"
  },
  {
    "x": "projeyê caardişî (n)",
    "y": " uygulama projesi"
  },
  {
    "x": "projeyê fikrî (n)",
    "y": " fikir projesi"
  },
  {
    "x": "projeyê karê mefrûşatî (n)",
    "y": " tefriş projesi"
  },
  {
    "x": "projeyê konseptî (n)",
    "y": " konsept projesi"
  },
  {
    "x": "projeyê restorasyonî (n)",
    "y": " restorasyon projesi"
  },
  {
    "x": "projeyê roloveyî (n)",
    "y": " rölöve projesi"
  },
  {
    "x": "projeyê tezî (n)",
    "y": " tez projesi"
  },
  {
    "x": "projeyê vurnayîşî (n)",
    "y": " tadilat projesi "
  },
  {
    "x": "projeyo qetî (n)",
    "y": " kesin proje"
  },
  {
    "x": "projektor (n)",
    "y": " projektör"
  },
  {
    "x": "proktolog, -e",
    "y": " proktolog"
  },
  {
    "x": "promîyer (n, sn)",
    "y": " prömiyer"
  },
  {
    "x": "promosyon (n)",
    "y": " promosyon"
  },
  {
    "x": "propaganda (m)",
    "y": " propaganda"
  },
  {
    "x": "prosedur (n)",
    "y": " prosedür"
  },
  {
    "x": "prosedurê kontrolî (n)",
    "y": " kontrol prosedürü"
  },
  {
    "x": "proses (n)",
    "y": " 1)süreç\r\n~2)proses (TE)"
  },
  {
    "x": "prosesor (n) (TE)",
    "y": " işlemci"
  },
  {
    "x": "protokol (n)",
    "y": " protokol"
  },
  {
    "x": "provokasyon (n)",
    "y": " provokasyon, kışkırtma"
  },
  {
    "x": "provokator, -e",
    "y": " provokatör"
  },
  {
    "x": "proxram (n)",
    "y": " b. program (n)"
  },
  {
    "x": "psing, -e",
    "y": " b. pisîng, -e"
  },
  {
    "x": "psîkîyatr, -e",
    "y": " akıl doktoru, ruh doktoru, ruh hekimi, psikiyatr, psikiyatrist"
  },
  {
    "x": "psîkîyatrî (n)",
    "y": " psikiyatri"
  },
  {
    "x": "psîkîyatrîst, -e",
    "y": " akıl doktoru, psikiyatr, psikiyatrist"
  },
  {
    "x": "psîkolog, -e",
    "y": " psikolog, ruhbilimci"
  },
  {
    "x": "psîkolojî (n)",
    "y": " psikoloji, ruhbilim, ruhiyat"
  },
  {
    "x": "psîkolojîyo edlî (n)",
    "y": " adli psikoloji"
  },
  {
    "x": "psîkolojîyê xebate (n)",
    "y": " çalışma psikolojisi"
  },
  {
    "x": "psîkolojîk, -e",
    "y": " ruhsal, psikolojik"
  },
  {
    "x": "psîng, -e",
    "y": " b. pisîng, -e"
  },
  {
    "x": "pu (n)",
    "y": " b. bum (n)"
  },
  {
    "x": "puyo kor (n)",
    "y": " baykuş"
  },
  {
    "x": "pu kerdene",
    "y": " tükürmek"
  },
  {
    "x": "puan (n)",
    "y": " puan"
  },
  {
    "x": "puantaj (n)",
    "y": " puantaj"
  },
  {
    "x": "puantajê meaşî (n)",
    "y": " maaş puantajı"
  },
  {
    "x": "puantajê mesaî (n)",
    "y": " mesai puantajı"
  },
  {
    "x": "puç, -e-I",
    "y": " 1)çürük\r\n~2)batıl"
  },
  {
    "x": "puç bîyene",
    "y": " çürümek (ceviz vb. için)"
  },
  {
    "x": "puç kerdene",
    "y": " çürütmek"
  },
  {
    "x": "puç-II (n)",
    "y": " çorap"
  },
  {
    "x": "puç ratenitene",
    "y": " çorap örmek\r\n~Kênaya mi puç tenit ra. (Kızım çorap ördü.)"
  },
  {
    "x": "puço naylonên (n)",
    "y": " naylon çorap"
  },
  {
    "x": "puço peşmên (n)",
    "y": " yün çorap"
  },
  {
    "x": "puço purtên (n)",
    "y": " yün çorap"
  },
  {
    "x": "puço rêsên (n)",
    "y": " yün çorap"
  },
  {
    "x": "puçbîyayîş (n)",
    "y": " çürüme"
  },
  {
    "x": "puçey (m)",
    "y": " b. puçîye (m)"
  },
  {
    "x": "puçê (m)",
    "y": " b. puçî (m)"
  },
  {
    "x": "puçik (n)",
    "y": " b. puç (n)"
  },
  {
    "x": "puçî (m)",
    "y": " çürüklük"
  },
  {
    "x": "puçîye (m)",
    "y": " çürüklük"
  },
  {
    "x": "puçker, -e",
    "y": " çürütücü"
  },
  {
    "x": "puçkerdiş (n)",
    "y": " çürütme"
  },
  {
    "x": "puçroş, -e",
    "y": " çorapçı"
  },
  {
    "x": "puçviraştox, -e",
    "y": " çorapçı"
  },
  {
    "x": "pudî (m)",
    "y": " b. pudîye (m)"
  },
  {
    "x": "pudîye (m)",
    "y": " dişeti "
  },
  {
    "x": "pudû (m)",
    "y": " b. pudîye (m)"
  },
  {
    "x": "puel (n)",
    "y": " 1)b. pel (n)\r\n~2)b. pol-II (n)\r\n~3)b. pul (n)"
  },
  {
    "x": "puelî nimacî (n)",
    "y": " b. polê nimajî (n)"
  },
  {
    "x": "puer (n)",
    "y": " b. por-I (n)"
  },
  {
    "x": "puerr (n)",
    "y": " b. por-I (n)"
  },
  {
    "x": "pueste (n)",
    "y": " b. poste-I (n)"
  },
  {
    "x": "puestê nimacî (n)",
    "y": " b. posteyê nimajî (n)"
  },
  {
    "x": "pueşî (m)",
    "y": " b. puşî (m)"
  },
  {
    "x": "puet (n)",
    "y": " 1)b. pot (n)\r\n~2)b. pote-I (m)"
  },
  {
    "x": "puetîn (n)",
    "y": " b. potîn (n)"
  },
  {
    "x": "pufayene",
    "y": " 1)“pof” diye ses çıkarmak\r\n~2)oflayıp puflamak\r\n~3)homurdanmak"
  },
  {
    "x": "puinc (n)",
    "y": " b. punce (m)"
  },
  {
    "x": "puirt (n)",
    "y": " b. purt (n)"
  },
  {
    "x": "puirtik (n)",
    "y": " b. purtik (n)"
  },
  {
    "x": "puirtikin, -e",
    "y": " b. purtikin, -e"
  },
  {
    "x": "puîne (n)",
    "y": " b. pîne (n)"
  },
  {
    "x": "puîne kerdene",
    "y": " b. pîne kerdene"
  },
  {
    "x": "puînekerdiş (n)",
    "y": " b. pînekerdiş (n)"
  },
  {
    "x": "puk (n)",
    "y": " tipi, kar fırtınası"
  },
  {
    "x": "pukê pîre (n)",
    "y": " kocakarı soğuğu, berdel acuz (Rumi takvime göre mart ayının son üç ve nisan ayının ilk üç günü)"
  },
  {
    "x": "pukerdiş (n)",
    "y": " tükürme"
  },
  {
    "x": "pul-I (n)",
    "y": " tepe"
  },
  {
    "x": "pulê daran (n)",
    "y": " ağaç topluluğu"
  },
  {
    "x": "pul-II (n)",
    "y": " pul (posta pulu)"
  },
  {
    "x": "puleg,-e",
    "y": " b. pulek,-e"
  },
  {
    "x": "pulek,-e",
    "y": " kurnaz"
  },
  {
    "x": "pulik (n)",
    "y": " 1)küçük tepe, tepecik, tümsek\r\n~2)(alna düşen kısa kesilmiş saç) perçem, kâkül"
  },
  {
    "x": "pulis, -e",
    "y": " b. polîs, -e"
  },
  {
    "x": "pulisey (m)",
    "y": " b. polîsî (m)"
  },
  {
    "x": "pulisê (m)",
    "y": " b. polîsî (m)"
  },
  {
    "x": "pullixe (m)",
    "y": " b. puluxe (m)"
  },
  {
    "x": "pullux (n)",
    "y": " b. puluxe (m)"
  },
  {
    "x": "pulluxe (m)",
    "y": " b. puluxe (m)"
  },
  {
    "x": "pulluxi (m)",
    "y": " b. puluxe (m)"
  },
  {
    "x": "pulor (n)",
    "y": " b. pilar (n)"
  },
  {
    "x": "Pulur (n)",
    "y": " Ovacık"
  },
  {
    "x": "puluxe (m)",
    "y": " pulluk"
  },
  {
    "x": "puluznayîne",
    "y": " b. piloznayene"
  },
  {
    "x": "puluznayîş (n)",
    "y": " piloznayîş (n)"
  },
  {
    "x": "pum (n)",
    "y": " b. bum (n) "
  },
  {
    "x": "puma, -ye",
    "y": " puma"
  },
  {
    "x": "pumpa (m)",
    "y": " b. pompa (m)"
  },
  {
    "x": "pun (n)",
    "y": " b. pîn (n)"
  },
  {
    "x": "punasek, -e",
    "y": " zavallı"
  },
  {
    "x": "punasekê (m)",
    "y": " b. punasekî (m)"
  },
  {
    "x": "punasekî (m)",
    "y": " zavallılık"
  },
  {
    "x": "punc-I (n)",
    "y": " çorap"
  },
  {
    "x": "punco naylonên (n)",
    "y": " naylon çorap"
  },
  {
    "x": "punco peşmên (n)",
    "y": " yün çorap"
  },
  {
    "x": "punco purtên (n)",
    "y": " yün çorap"
  },
  {
    "x": "punco rêsên (n)",
    "y": " yün çorap"
  },
  {
    "x": "punc-II (n)",
    "y": " b. punce (m)"
  },
  {
    "x": "punce (m)",
    "y": " ot ve otsu bitkilerin bir tanesi"
  },
  {
    "x": "punci-I (m)",
    "y": " b. punce (m)"
  },
  {
    "x": "punci-II (n)",
    "y": " b. pance (n)"
  },
  {
    "x": "pund (n)",
    "y": " b. pind (n)"
  },
  {
    "x": "pund dayene",
    "y": " b. pind dayene"
  },
  {
    "x": "pune (n)",
    "y": " yarpuz"
  },
  {
    "x": "pung (n)",
    "y": " b. pîn (n)"
  },
  {
    "x": "pungal (n)",
    "y": " folluk (tavuğun yumurtladığı veya kuluçkaya yattığı yer)\r\n~Kerge ere pungal fetelîna. (Tavuk folluk arıyor.)"
  },
  {
    "x": "pungal kewtene",
    "y": " kuluçkaya yatmak, gurka yatmak\r\n~Kerge kewta pungal. (Tavuk kuluçkaya yatmış.)"
  },
  {
    "x": "pungal kotene",
    "y": " b. pungal kewtene"
  },
  {
    "x": "puni (n)",
    "y": " b. pune (n)"
  },
  {
    "x": "punik (n)",
    "y": " küçük kümes"
  },
  {
    "x": "puntûr (zh)",
    "y": " b. pantolî (zh)"
  },
  {
    "x": "pur-I (n)",
    "y": " kabuk"
  },
  {
    "x": "pur kerdene",
    "y": " kabuğunu soymak"
  },
  {
    "x": "purê pîyazî (n)",
    "y": " kuru soğan kabuğu"
  },
  {
    "x": "purî pîyûnzî (n)",
    "y": " b. purê pîyazî (n)"
  },
  {
    "x": "pur-II (n)",
    "y": " b. pel-I (n)"
  },
  {
    "x": "purcini (m)",
    "y": " b. pirojine (m)"
  },
  {
    "x": "pure",
    "y": " b. piro"
  },
  {
    "x": "pure ceneyîne",
    "y": " b. piro cenayene"
  },
  {
    "x": "pure dayene",
    "y": " b. piro dayene"
  },
  {
    "x": "pure ginayîne",
    "y": " b. piro ginayene"
  },
  {
    "x": "pure rînayene",
    "y": " b. piro rînayene"
  },
  {
    "x": "pureceneyîş (n)",
    "y": " b. pirocenayîş (n)"
  },
  {
    "x": "puredayîş (n)",
    "y": " b. pirodayîş (n)"
  },
  {
    "x": "pureginayîş (n)",
    "y": " b. piroginayîş (n)"
  },
  {
    "x": "purerînayîş (n)",
    "y": " b. pirorînayîş (n)"
  },
  {
    "x": "puri",
    "y": " b. piro"
  },
  {
    "x": "puri şonayîne",
    "y": " b. piro şanayene"
  },
  {
    "x": "puridayîş (n)",
    "y": " b. pirodayîş (n)"
  },
  {
    "x": "purişonayîş (n)",
    "y": " b. piroşanayîş (n)"
  },
  {
    "x": "purî",
    "y": " b. piro"
  },
  {
    "x": "purîdayîş (n)",
    "y": " b. pirodayîş (n)"
  },
  {
    "x": "purîkerdiş (n)",
    "y": " b. pirokerdiş (n)"
  },
  {
    "x": "purn (m)",
    "y": " b. firine (m)"
  },
  {
    "x": "purnagîye (m)",
    "y": " imece (karşılıklı birbirinin işinde çalışma)"
  },
  {
    "x": "purnagîye kerdene",
    "y": " imece ile çalışmak"
  },
  {
    "x": "purni (m)",
    "y": " b. firine (m)"
  },
  {
    "x": "purna elektrîkin (m)",
    "y": " b. firina elektrîkine (m)"
  },
  {
    "x": "puro dayene",
    "y": " b. piro dayene"
  },
  {
    "x": "puro şonayîne",
    "y": " b. piro şanayene"
  },
  {
    "x": "purodayîş (n)",
    "y": " b. pirodayîş (n)"
  },
  {
    "x": "puroşonayîş (n)",
    "y": " b. piroşanayîş (n)"
  },
  {
    "x": "purozine (m)",
    "y": " b. pirojine (m)"
  },
  {
    "x": "purozini (m)",
    "y": " b. pirojine (m)"
  },
  {
    "x": "purr (n)",
    "y": " 1)çapul, plaçka, talan, yağma\r\n~2)b. pur-I (n)"
  },
  {
    "x": "purr kerdene",
    "y": " yağmalamak, talanlamak, talan etmek, yağma etmek\r\n~2)b. pur kerdene"
  },
  {
    "x": "purrkerdayîs (n)",
    "y": " b. purrkerdiş (n)"
  },
  {
    "x": "purrkerdiş (n)",
    "y": " yağmalama, talanlama, talan etme, yağma etme"
  },
  {
    "x": "purrkerdîs (n)",
    "y": " b. purrkerdiş (n)"
  },
  {
    "x": "purşe (n)",
    "y": " b. pişrûg (n)"
  },
  {
    "x": "purşê nanî (n)",
    "y": " b. pişrûgê nanî (n)"
  },
  {
    "x": "purt (n)",
    "y": " 1)tüy\r\n~2)yün, yapak, yapağı\r\n~3)b. purr (n)"
  },
  {
    "x": "purt kerdene",
    "y": " 1)yolmak (tüy vb. için)\r\n~2)b. purr kerdene"
  },
  {
    "x": "purt û pil",
    "y": " b. pil û purt"
  },
  {
    "x": "purtên, -e",
    "y": " yünlü (yünden yapılmış olan)"
  },
  {
    "x": "purtik (n)",
    "y": " 1)hav, tüy\r\n~2)kabuk, nohut kabuğu"
  },
  {
    "x": "purtik sero kerdene",
    "y": " kabuk soymak"
  },
  {
    "x": "purtikin, -e",
    "y": " havlı, tüylü"
  },
  {
    "x": "purtikyen",
    "y": " b. purtikin, -e"
  },
  {
    "x": "purtin, -e",
    "y": " 1)tüylü\r\n~2)yünlü (içinde yün de bulunan)"
  },
  {
    "x": "purtî (n)",
    "y": " b. pirtû (n)"
  },
  {
    "x": "purtkerdayîs (n)",
    "y": " b. purrkerdiş (n)"
  },
  {
    "x": "purtkerdiş (n)",
    "y": " yolma (tüy vb. için)"
  },
  {
    "x": "purtkerdîs (n)",
    "y": " b. purrkerdiş (n)"
  },
  {
    "x": "purtkin, -e",
    "y": " b. purtikin, -e"
  },
  {
    "x": "purtqal (m)",
    "y": " b. porteqale (m)"
  },
  {
    "x": "purtyen (n)",
    "y": " b. purtên (n)"
  },
  {
    "x": "pure",
    "y": " b. piro"
  },
  {
    "x": "puruedayîş (n)",
    "y": " b. pirodayîş (n)"
  },
  {
    "x": "purye cenayîne",
    "y": " b. piro cenayene"
  },
  {
    "x": "puryecenayîş (n)",
    "y": " b. pirocenayîş (n)"
  },
  {
    "x": "puryedayîş (n)",
    "y": " b. pirodayîş (n)"
  },
  {
    "x": "pus (n)",
    "y": " b. puş (n)"
  },
  {
    "x": "pusik (m)",
    "y": " b. pizrike (m)"
  },
  {
    "x": "puskul (m)",
    "y": " b. piskile (m)"
  },
  {
    "x": "puskule (m)",
    "y": " b. piskile (m)"
  },
  {
    "x": "puskuley murûn (m)",
    "y": " b. piskila murayan (m)"
  },
  {
    "x": "puskulin, -e",
    "y": " b. piskilin, -e"
  },
  {
    "x": "pusqile (m)",
    "y": " b. pişkile (m)"
  },
  {
    "x": "pusqile kerdene",
    "y": " b. pişkile kerdene"
  },
  {
    "x": "pusula (m)",
    "y": " pusula"
  },
  {
    "x": "pusulaya rêze (m)",
    "y": " dizi pusulası"
  },
  {
    "x": "puş (n)",
    "y": " kuru yaprak, kavrak, haşiş"
  },
  {
    "x": "puşê pîyanzî (n)",
    "y": " b. puşê pîyazî (n)"
  },
  {
    "x": "puşê pîyazî (n)",
    "y": " 1)kuru soğan kabuğu\r\n~2)yeşil soğan"
  },
  {
    "x": "puşê pîyonzî (n)",
    "y": " b. puşê pîyazî (n)"
  },
  {
    "x": "puşîy piyûnz (n)",
    "y": " b. puşê pîyazî (n)"
  },
  {
    "x": "puş-palaxe (m)",
    "y": " yerdeki kuru ot ve yaprak birikintisi"
  },
  {
    "x": "puş-pelaxi (m)",
    "y": " b. puş-palaxe (m)"
  },
  {
    "x": "puşendî (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "puşî (m)",
    "y": " başörtü, başörtüsü, poşu, poçu"
  },
  {
    "x": "puşîye (m)",
    "y": " başörtü, başörtüsü, poşu, poçu"
  },
  {
    "x": "puşoti (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "puşotî (m)",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "puşrigi (m)",
    "y": " b. pişrûge (m)"
  },
  {
    "x": "puşrugi (m)",
    "y": " b. pişrûge (m)"
  },
  {
    "x": "puşrû",
    "y": " b. piştwerdî (m)"
  },
  {
    "x": "puşt (m)",
    "y": " b. piştî (m) "
  },
  {
    "x": "puşûpalax (m)",
    "y": " b. puşûpalaxe (m)"
  },
  {
    "x": "puşûpalaxe (m)",
    "y": " yerdeki kuru ot ve yaprak birikintisi"
  },
  {
    "x": "puşûpalaxi (m)",
    "y": " b. puşûpalaxe (m)"
  },
  {
    "x": "puşûpelax (m)",
    "y": " b. puşûpalaxe (m)"
  },
  {
    "x": "puşûpelax (n)",
    "y": " b. puşûpalaxe (m)"
  },
  {
    "x": "puşûpelaxe (m)",
    "y": " b. puşûpalaxe (m)"
  },
  {
    "x": "puşûpelaxi (m)",
    "y": " b. puşûpalaxe (m)"
  },
  {
    "x": "put, -e-I",
    "y": " çocuk"
  },
  {
    "x": "put bîyene",
    "y": " çocuklaşmak"
  },
  {
    "x": "put ver şîyayene",
    "y": " b. put ver şîyene"
  },
  {
    "x": "put ver şîyene",
    "y": " düşük yapmak"
  },
  {
    "x": "put-II (n)",
    "y": " fetiş, tapıncak, put"
  },
  {
    "x": "putbîyayîş (n)",
    "y": " çocuklaşma"
  },
  {
    "x": "putek, -e",
    "y": " b. put, -e"
  },
  {
    "x": "puwr (n)",
    "y": " b. pur (n)"
  },
  {
    "x": "puwr kerdene",
    "y": " b. pur kerdene"
  },
  {
    "x": "puxir (n)",
    "y": " b. buxar (n)"
  },
  {
    "x": "puxirin (n)",
    "y": " b. buxarin (n)"
  },
  {
    "x": "puxur (n)",
    "y": " b. buxar (n)"
  },
  {
    "x": "puxur bîyene",
    "y": " b. buxar bîyene"
  },
  {
    "x": "puxur kerdene",
    "y": " b. buxar kerdene"
  },
  {
    "x": "puxurbîyayîş (n)",
    "y": " b. buxarbîyayîş (n)"
  },
  {
    "x": "puxurin (n)",
    "y": " b. buxarin (n)"
  },
  {
    "x": "puxurkerdiş (n)",
    "y": " b. buxarkerdiş (n)"
  },
  {
    "x": "puye (m)",
    "y": " çıralık (tutuşturmak için kullanılan çıra)"
  },
  {
    "x": "pû (n)",
    "y": " b. bum (n)"
  },
  {
    "x": "pûyo kor (n)",
    "y": " b. puyo kor (n)"
  },
  {
    "x": "pû kerdeni-II",
    "y": " b. pu kerdene"
  },
  {
    "x": "pûç (n)",
    "y": " b. puç (n)"
  },
  {
    "x": "pûç, -i",
    "y": " b. puç, -e"
  },
  {
    "x": "pûç bîyayene",
    "y": " b. puç bîyene"
  },
  {
    "x": "pûç kerdene",
    "y": " b. puç kerdene"
  },
  {
    "x": "pûçbîyayîş (n)",
    "y": " b. puçbîyayîş (n)"
  },
  {
    "x": "pûçey (m)",
    "y": " b. puçîye (m)"
  },
  {
    "x": "pûçik (n)",
    "y": " b. puç (n)"
  },
  {
    "x": "pûçî (m)",
    "y": " b. puçî (m)"
  },
  {
    "x": "pûçkerdiş (n)",
    "y": " b. puçkerdiş (n)"
  },
  {
    "x": "pûiç (n)",
    "y": " b. puç (n)"
  },
  {
    "x": "pûik (n)",
    "y": " b. puk (n)"
  },
  {
    "x": "pûk (n)",
    "y": " b. puk (n)"
  },
  {
    "x": "pûkê pîre (n)",
    "y": " b. pukê pîre (n)"
  },
  {
    "x": "pûkerdene",
    "y": " b. pokerdene"
  },
  {
    "x": "pûkerdeni",
    "y": " b. pokerdene"
  },
  {
    "x": "pûkerdiş-I (n)",
    "y": " b. pokerdiş (n)"
  },
  {
    "x": "pûkerdiş-II (n)",
    "y": " b. pukerdiş (n)"
  },
  {
    "x": "pûl (n)",
    "y": " b. pol-I (n)"
  },
  {
    "x": "pûlik (n)",
    "y": " b. pulik (n)"
  },
  {
    "x": "pûmpûli (m)",
    "y": " b. papûre (m)"
  },
  {
    "x": "pûn-I (n)",
    "y": " b. pîn (n)"
  },
  {
    "x": "pûn-II",
    "y": " b. panc"
  },
  {
    "x": "pûn rê se",
    "y": " b. panc sey"
  },
  {
    "x": "pûn sey",
    "y": " b. panc sey"
  },
  {
    "x": "pûnayene",
    "y": " b. panayene"
  },
  {
    "x": "pûnayîş (n)",
    "y": " b. panayîş (n)"
  },
  {
    "x": "pûnc",
    "y": " b. panc"
  },
  {
    "x": "pûnc sey",
    "y": " b. panc sey"
  },
  {
    "x": "pûnc (n)",
    "y": " b. punc-I (n)"
  },
  {
    "x": "pûncas",
    "y": " b. pancas"
  },
  {
    "x": "pûnce (n)",
    "y": " b. pance (n)"
  },
  {
    "x": "pûncês",
    "y": " b. pancês"
  },
  {
    "x": "pûnci-I (m)",
    "y": " b. punce (m)"
  },
  {
    "x": "pûnci-II (n)",
    "y": " b. pance (n)"
  },
  {
    "x": "pûne (n)",
    "y": " b. pune (n)"
  },
  {
    "x": "pûnek (n)",
    "y": " b. punik (n)"
  },
  {
    "x": "pûnet (m)",
    "y": " b. pangnote (m)"
  },
  {
    "x": "pûnik (n)",
    "y": " b. punik (n)"
  },
  {
    "x": "pûnîyaye (n)",
    "y": " b. panîyaye (n)"
  },
  {
    "x": "pûnîyayene",
    "y": " b. panîyayene"
  },
  {
    "x": "pûnîyayê (m)",
    "y": " b. panîyaya (m), panîyayîye (m)"
  },
  {
    "x": "pûnîyayîş (n)",
    "y": " b. panîyayîş (n)"
  },
  {
    "x": "pûnk, -i",
    "y": " b. pak, -e"
  },
  {
    "x": "pûnk kerdene",
    "y": " b. pak kerdene"
  },
  {
    "x": "pûnka (s)",
    "y": " paka (s)"
  },
  {
    "x": "pûnkey (m)",
    "y": " b. pakî (m), pakîye (m)"
  },
  {
    "x": "pûnkê (m)",
    "y": " b. pakî (m), pakîye (m)"
  },
  {
    "x": "pûnkkerdiş (n)",
    "y": " b. pakkerdiş (n)"
  },
  {
    "x": "pûntîli (m)",
    "y": " b. patîle-I (m)"
  },
  {
    "x": "pûntûlî (zh)",
    "y": " b. pantolî (zh)"
  },
  {
    "x": "pûntûrî (zh)",
    "y": " b. pantolî (zh)"
  },
  {
    "x": "pûntûrrî (zh)",
    "y": " b. pantolî (zh)"
  },
  {
    "x": "pûnz (n)",
    "y": " b. pîyaz (n)"
  },
  {
    "x": "pûr-I (n)",
    "y": " b. por-I (n)"
  },
  {
    "x": "pûr-II (n)",
    "y": "b. pur-I (n)"
  },
  {
    "x": "pûr kerdene",
    "y": " b. pur kerdene"
  },
  {
    "x": "pûrt (n)",
    "y": " purt (n)"
  },
  {
    "x": "pûrt kerdene",
    "y": " b. purt kerdene"
  },
  {
    "x": "pûrteqalêre (m)",
    "y": " b. porteqalêre (m)"
  },
  {
    "x": "pûrteqali (m)",
    "y": " b. porteqale (m)"
  },
  {
    "x": "pûrtik (n)",
    "y": " purtik (n)"
  },
  {
    "x": "pûrtkerdiş (n)",
    "y": " b. purtkerdiş (n)"
  },
  {
    "x": "pûryedayîş (n)",
    "y": " b. pirodayîş (n)"
  },
  {
    "x": "pûste (n)",
    "y": " b. Poste (n)"
  },
  {
    "x": "pûstexone (n)",
    "y": " b. postexane (n)"
  },
  {
    "x": "pûstexûne (n)",
    "y": " b. postexane (n)"
  },
  {
    "x": "pûş (n)",
    "y": "  b. puş (n)"
  },
  {
    "x": "pûşê pîyazî (n)",
    "y": " b. puşê pîyazî (n)"
  },
  {
    "x": "pûşî (m)",
    "y": " b. puşî (m)"
  },
  {
    "x": "pûşûpalaxi (m)",
    "y": " b. puşûpalaxe (m)"
  },
  {
    "x": "pût (n)",
    "y": " b. put-II (n)"
  },
  {
    "x": "pûvetene",
    "y": " b. povetene"
  },
  {
    "x": "pûvetiş (n)",
    "y": " b. povetiş (n)"
  },
  {
    "x": "pûxnûti (m)",
    "y": " b. pangnote (m)"
  },
  {
    "x": "pûyaye (n)",
    "y": " b. poyaye (n)"
  },
  {
    "x": "pûyayene",
    "y": " b. poyayene"
  },
  {
    "x": "pûyayeyî (m)",
    "y": " b. poyayeyî (m)"
  },
  {
    "x": "pûyayîne",
    "y": " b. poyayene"
  },
  {
    "x": "pûyayîş (n)",
    "y": " b. poyayîş (n)"
  },
  {
    "x": "pûyayî (n)",
    "y": " b. boyaye (n)"
  },
  {
    "x": "pûyayîye (m)",
    "y": " b. poyayîye (m)"
  },
  {
    "x": "pûynayene",
    "y": " b. poynayene"
  },
  {
    "x": "pûynayîş (n)",
    "y": " b. poynayîş (n)"
  },
  {
    "x": "pwer (n)",
    "y": " b. por-I (n)"
  },
  {
    "x": "pyor",
    "y": " b. pêro"
  },
  {
    "x": "pyûr",
    "y": " b. pêro"
  },
  {
    "x": "QQQ"
  },
  {
    "x": "qa",
    "y": " b. ka"
  },
  {
    "x": "qab (n)",
    "y": " 1)kap (yemek kabı), çanak (yayvan kap)\r\n~2)kapak (kitab vb. için)"
  },
  {
    "x": "qab-qacaxê nêşuteyî (zh)",
    "y": " b. qabê nêşuteyî (zh)"
  },
  {
    "x": "qabê nêşuteyî (zh)",
    "y": " bulaşık"
  },
  {
    "x": "qabe (n)",
    "y": " b. qewe (n)"
  },
  {
    "x": "qabê",
    "y": " b. qandê"
  },
  {
    "x": "qabê ki",
    "y": " b. qando ke"
  },
  {
    "x": "qabi",
    "y": " b. qandê"
  },
  {
    "x": "qabi ki",
    "y": " b. qando ke"
  },
  {
    "x": "qabirxa (m)",
    "y": " kaburga"
  },
  {
    "x": "qabîlîyet (n)",
    "y": " kabiliyet, yetenek"
  },
  {
    "x": "qabîlîyetdar, -e",
    "y": " kabiliyetli, yetenekli"
  },
  {
    "x": "qabo",
    "y": " b. qandê"
  },
  {
    "x": "qabo ke",
    "y": " b. qando ke"
  },
  {
    "x": "qabo ki",
    "y": " b. qando ke"
  },
  {
    "x": "qabqab (n)",
    "y": " b. qapqapik (n)"
  },
  {
    "x": "qabqabik (n)",
    "y": " b. qapqapik (n)"
  },
  {
    "x": "qabqaline (m)",
    "y": " bir üzüm çeşidi"
  },
  {
    "x": "qabqalini (m)",
    "y": " b. qabqaline (m)"
  },
  {
    "x": "qabqap (n)",
    "y": " b. qapqapik (n)"
  },
  {
    "x": "qabul (n)",
    "y": " b. qebul (n)"
  },
  {
    "x": "qabûl (n)",
    "y": " b. qebul (n)"
  },
  {
    "x": "qaçax, -e",
    "y": " kaçak"
  },
  {
    "x": "qaçaxê/a eskerîya",
    "y": " asker kaçağı"
  },
  {
    "x": "qaçaxê/a eskerîye",
    "y": " asker kaçağı"
  },
  {
    "x": "qaçaxçî, -ye",
    "y": " kaçakçı"
  },
  {
    "x": "qaçaxçîtî (m)",
    "y": " kaçakçılık"
  },
  {
    "x": "qaçaxçîyey (m)",
    "y": " b. qaçaxçîyîye (m)"
  },
  {
    "x": "qaçaxçîyê (m)",
    "y": " b. qaçaxçîyî (m)"
  },
  {
    "x": "qaçaxçîyênî (m)",
    "y": " kaçakçılık"
  },
  {
    "x": "qaçaxçîyî (m)",
    "y": " kaçakçılık"
  },
  {
    "x": "qaçaxçîyîye (m)",
    "y": " kaçakçılık"
  },
  {
    "x": "qad (n)",
    "y": " meydan"
  },
  {
    "x": "qada (n)",
    "y": " b. qeza-II (n)"
  },
  {
    "x": "qadayîf (n)",
    "y": " b. qedayîf (n)"
  },
  {
    "x": "qadehi (m)",
    "y": " b. qedehe (m)"
  },
  {
    "x": "qadirî, -ye",
    "y": " Kadiri"
  },
  {
    "x": "qadirîtî (m)",
    "y": " Kadirilik"
  },
  {
    "x": "qadirîyey (m)",
    "y": " b. qadirîyîye (m)"
  },
  {
    "x": "qadirîyê (m)",
    "y": " b. qadirîyî"
  },
  {
    "x": "qadirîyî (m)",
    "y": " Kadirilik"
  },
  {
    "x": "qadirîyîye (m)",
    "y": " Kadirilik"
  },
  {
    "x": "qaf-I (n)",
    "y": " kafatası "
  },
  {
    "x": "qaf-II (n)",
    "y": " hurda"
  },
  {
    "x": "qafo madenî (n)",
    "y": " metalik hurda"
  },
  {
    "x": "qaf-III (n)",
    "y": " b. qab (n)"
  },
  {
    "x": "qafa (m)",
    "y": " b. qafike (m)"
  },
  {
    "x": "qafek (n)",
    "y": " hurda"
  },
  {
    "x": "qafeko madenî (n)",
    "y": " metalik hurda"
  },
  {
    "x": "qafik-I (n)",
    "y": " kabuk, ağaç kabuğu, yara kabuğu"
  },
  {
    "x": "qafik girey dayene",
    "y": " b. qafik girê dayene"
  },
  {
    "x": "qafik girê dayene",
    "y": " kabuk bağlamak (yara için)"
  },
  {
    "x": "qafik sero kerdene",
    "y": " kabuk soymak"
  },
  {
    "x": "qafik-II (n)",
    "y": " qafek (n)"
  },
  {
    "x": "qafike (m)",
    "y": " baş, kafa"
  },
  {
    "x": "qafile (m)",
    "y": " b. xafile (m)"
  },
  {
    "x": "qafîye (m)",
    "y": " uyak, kafiye"
  },
  {
    "x": "qafîyeya çepraze (m)",
    "y": " çapraz uyak, çapraz kafiye"
  },
  {
    "x": "qafke (m)",
    "y": " b. qafike (m)"
  },
  {
    "x": "qaflet (n)",
    "y": " hurda"
  },
  {
    "x": "qaharîyayene",
    "y": " b. qehirîyayene"
  },
  {
    "x": "qaharîyayîş (n)",
    "y": " b. qehirîyayîş (n)"
  },
  {
    "x": "qaharnayene",
    "y": " b. qehirnayene"
  },
  {
    "x": "qaharnayîş (n)",
    "y": " b. qehirnayîş (n)"
  },
  {
    "x": "qaharnayox, -e",
    "y": " b. qehirnayox, -e"
  },
  {
    "x": "qahir (n)",
    "y": " b. qehir (n)"
  },
  {
    "x": "Qahîre (m)",
    "y": " Kahire"
  },
  {
    "x": "qahpe (m)",
    "y": " b. qehpe (m)"
  },
  {
    "x": "qahpeyey (m)",
    "y": " b. qehpeyî (m), qehpeyîye (m)"
  },
  {
    "x": "qahpeyê (m)",
    "y": " b. qehpeyî (m), qehpeyîye (m)"
  },
  {
    "x": "qahpi (m)",
    "y": " b. qehpe (m)"
  },
  {
    "x": "qahpikey (m)",
    "y": " b. qehpikî (m), qehpikîye (m)"
  },
  {
    "x": "qahpikê (m)",
    "y": " b. qehpikî (m), qehpikîye (m)"
  },
  {
    "x": "qahpiki (m)",
    "y": " b. qehpike (m)"
  },
  {
    "x": "qahr (n)",
    "y": " b. qehir (n)"
  },
  {
    "x": "qahrîyaya (m)",
    "y": " b. qehirîyaya (m)"
  },
  {
    "x": "qahrîyaye (n)",
    "y": " b. qehirîyaye (n)"
  },
  {
    "x": "qahrîyayene",
    "y": " b. qehirîyayene"
  },
  {
    "x": "qahrîyayeyê (m)",
    "y": " b. qehirîyayeyî (m), qehirîyayeyîye (m)"
  },
  {
    "x": "qahrîyayeyênî (m)",
    "y": " b. qehirîyayeyênî (m)"
  },
  {
    "x": "qahrîyayeyî (m)",
    "y": " b. qehirîyayeyî (m)"
  },
  {
    "x": "qahrîyayeyîye (m)",
    "y": " b. qehirîyayeyîye (m)"
  },
  {
    "x": "qahrîyayê (m)",
    "y": " b. qehirîyayîye (m)"
  },
  {
    "x": "qahrîyayîş (n)",
    "y": " b. qehirîyayîş (n)"
  },
  {
    "x": "qahrîyayîye (m)",
    "y": " b. qehirîyayîye (m)"
  },
  {
    "x": "qahrnayene",
    "y": " b. qehirnayene"
  },
  {
    "x": "qahrnayîş (n)",
    "y": " b. qehirnayîş (n)"
  },
  {
    "x": "qahrnayox, -e",
    "y": " b. qehirnayox, -e"
  },
  {
    "x": "qahwe (n)",
    "y": " b. qewe (n)"
  },
  {
    "x": "qahwexane (n)",
    "y": " b. qewexane (n)"
  },
  {
    "x": "qahwexûne (n)",
    "y": " b. qewexane (n)"
  },
  {
    "x": "qal (m)",
    "y": " b. qale (m)"
  },
  {
    "x": "qal kerdene",
    "y": " bahsetmek, sözünü etmek\r\n~Qal kenî, vanî rewşa welatî zaf xirab a. (Bahsediyorlar, ülkenin durumunun çok kötü olduğunu söylüyorlar.)"
  },
  {
    "x": "qalê ... kerdene",
    "y": " bahsetmek, sözünü etmek"
  },
  {
    "x": "qalê ... kerdiş",
    "y": " -den bahsetme\r\n~Paksar qalê Dêrsimî keno. (Paksar Dêrsim’den bahsediyor.)\r\n~\t\r\n~Herçî ti qalê xo meke. (Bir kere sen kendinden bahsetme!/Sen hiç kendinden bahsetme!)"
  },
  {
    "x": "qal û qirfî",
    "y": " cadı kazanı"
  },
  {
    "x": "qal-qir (n)",
    "y": " dedikodu"
  },
  {
    "x": "qal-qirr (n)",
    "y": " b. qal-qir (n)"
  },
  {
    "x": "qala (m)",
    "y": " b. kela (m)"
  },
  {
    "x": "qalas (n)",
    "y": " b. qelas (n)"
  },
  {
    "x": "qalaşnayene",
    "y": " b. qelişnayene"
  },
  {
    "x": "qalayene",
    "y": " b. kalayene"
  },
  {
    "x": "qalayîş (n)",
    "y": " b. kalayîş (n)"
  },
  {
    "x": "qalçarnayîş (n)",
    "y": " b. qaleçarnayîş (n)"
  },
  {
    "x": "qale-I (m)",
    "y": " söz, laf"
  },
  {
    "x": "qala ... birnayene",
    "y": " sözünü kesmek"
  },
  {
    "x": "qala cale (m)",
    "y": " acı söz"
  },
  {
    "x": "qala dejdayoxe (m)",
    "y": " acı söz"
  },
  {
    "x": "qala mi cemat ra teber",
    "y": " sözüm meclisten dışarı, sözüm yabana"
  },
  {
    "x": "qala tale (m)",
    "y": " acı söz"
  },
  {
    "x": "qala tuje (m)",
    "y": " incitici söz"
  },
  {
    "x": "qale-II (m)",
    "y": " çelik çomak "
  },
  {
    "x": "qale-III (m)",
    "y": " b. qalibe (m)"
  },
  {
    "x": "qaleçarnayîş (n)",
    "y": " dedikodu"
  },
  {
    "x": "qalek, -e",
    "y": " konuşkan"
  },
  {
    "x": "qalezaf, -e",
    "y": " geveze, boşboğaz; çenebaz, çeneli"
  },
  {
    "x": "qalezafî (m)",
    "y": " gevezelik"
  },
  {
    "x": "qalezafî kerdene",
    "y": " gevezelik etmek"
  },
  {
    "x": "qalezafîkerdiş (n)",
    "y": " gevezelik etme"
  },
  {
    "x": "qalezafîye (m)",
    "y": " gevezelik,çenebazlık"
  },
  {
    "x": "qalezafîye kerdene",
    "y": " gevezelik etmek"
  },
  {
    "x": "qalezafîyekerdiş (n)",
    "y": " gevezelik etme"
  },
  {
    "x": "qalfa-I (m)",
    "y": " b. qelfa (m)"
  },
  {
    "x": "qalfa-II (n)",
    "y": " b. qelfe-I (n)"
  },
  {
    "x": "qali-I (m)",
    "y": " b. qale (m)"
  },
  {
    "x": "qali-II (m)",
    "y": " b. qalibe (m)"
  },
  {
    "x": "qali kerdene",
    "y": " b. qalibe … kerdene"
  },
  {
    "x": "qalib-I (n)",
    "y": " kalıp (bir şeye belirli bir biçim vermek için kullanılan)"
  },
  {
    "x": "qalib ro dayene",
    "y": " kalıba çekmek, kalıba vurmak "
  },
  {
    "x": "qalib-II (m)",
    "y": " b. qalibe (m)"
  },
  {
    "x": "qalib kerdene",
    "y": " b. qalibe kerdene"
  },
  {
    "x": "qalibe-I (m)",
    "y": " kalıp (bir kalıba dökülerek yapılmış olan) "
  },
  {
    "x": "qaliba sabunî (m)",
    "y": " sabun kalıbı "
  },
  {
    "x": "qalibe kerdene",
    "y": " kalıplamak"
  },
  {
    "x": "qalibey sabunî (m)",
    "y": " b. qaliba sabunî (m)"
  },
  {
    "x": "qalibê sawunî (m)",
    "y": " b. qaliba sabunî (m)"
  },
  {
    "x": "qalibe-II (m)",
    "y": " kama, oyunda galibiyetlerden her biri, oyunda kazanılan partilerden her biri"
  },
  {
    "x": "qalibe … kerdene",
    "y": " oyunun bir partisini kazanıp muhatabını yenmek, kama sokmak\r\n~Ma ewro kay kerd, mi di qalibî ey kerdî. (Bugün oynadık, ona [karşı] iki galibiyet kazandım.)"
  },
  {
    "x": "qalibi-I (m)",
    "y": " b. qalibe-I (m)"
  },
  {
    "x": "qalibi kerdene",
    "y": " b. qalibe kerdene"
  },
  {
    "x": "qalibi-II (m)",
    "y": " b. qalibe-II (m)"
  },
  {
    "x": "qalibi ... kerdene",
    "y": " b. qalibe … kerdene"
  },
  {
    "x": "qalibkar, -e",
    "y": " kalıpçı"
  },
  {
    "x": "qalibroş, -e",
    "y": " kalıpçı"
  },
  {
    "x": "qaliçarnayîş (n)",
    "y": " qaleçarnayîş (n)"
  },
  {
    "x": "qalik (n)",
    "y": " kabuk"
  },
  {
    "x": "qalik sero kerdene",
    "y": " kabuk soymak"
  },
  {
    "x": "qalikê guvelikî (n)",
    "y": " salyangoz kabuğu"
  },
  {
    "x": "qalikê şeytanî (n)",
    "y": " b. qalikê şeytanokî (n)"
  },
  {
    "x": "qalikê şeytanokî (n)",
    "y": " salyangoz kabuğu "
  },
  {
    "x": "qalik-I (m)",
    "y": " b. qalik-I (n)"
  },
  {
    "x": "qaliki (m)",
    "y": " b. qalik (n)"
  },
  {
    "x": "qaliki sero kerdene",
    "y": " b. qalik sero kerdene"
  },
  {
    "x": "qalin, -e",
    "y": " b. qalind, -e"
  },
  {
    "x": "qalin (n)",
    "y": " b. qalind (n)"
  },
  {
    "x": "qalinçi (m)",
    "y": " b. qaluçe (m)"
  },
  {
    "x": "qalind, -e-I",
    "y": " 1)kalın\r\n~2)koyu (sıvı için)"
  },
  {
    "x": "qalind (n)-II",
    "y": " başlık"
  },
  {
    "x": "qalindîye (m)",
    "y": " kalınlık"
  },
  {
    "x": "qaliney (m)",
    "y": " b. qalindîye"
  },
  {
    "x": "qalinê (m)",
    "y": " b. qalindîye"
  },
  {
    "x": "qalini (m)",
    "y": " b. qalinde (m)"
  },
  {
    "x": "qalip (n)",
    "y": " b. qalib (n)"
  },
  {
    "x": "qaliv-I (n)",
    "y": " b. qalib-I (n)"
  },
  {
    "x": "qaliv ro dayene",
    "y": " b. qalib ro dayene"
  },
  {
    "x": "qaliv-II (m)",
    "y": " b. qalibe (m)"
  },
  {
    "x": "qaliv kerdene",
    "y": " b. qalibe kerdene"
  },
  {
    "x": "qalizav, -i",
    "y": " b. qalezaf, -e"
  },
  {
    "x": "qalizavey (m)",
    "y": " b. qalezafî (m), qalezafîye (m)"
  },
  {
    "x": "qalizavey kerdene",
    "y": " b. qalezafî kerdene, qalezafîye kerdene"
  },
  {
    "x": "qalizaveykerdiş (n)",
    "y": " b. qalezafîkerdiş (n), qalezafîyekerdiş (n)"
  },
  {
    "x": "qalî kerdene",
    "y": " konuşmak"
  },
  {
    "x": "qalîkerdiş (n)",
    "y": " konuşma"
  },
  {
    "x": "qalîkerdişê abîyayîşî (n)",
    "y": " açılış konuşması"
  },
  {
    "x": "qalîkerdişê akerdişî (n)",
    "y": " açış konuşması"
  },
  {
    "x": "qalîkerdox, -e",
    "y": " konuşmacı"
  },
  {
    "x": "qalkerdiş (n)",
    "y": " bahsetme"
  },
  {
    "x": "qalmîşbîyayîş (n)",
    "y": " b. qarişbîyayîş (n)"
  },
  {
    "x": "qalorîfer (n)",
    "y": " b. kalorîfer (n)"
  },
  {
    "x": "qalpax (m)",
    "y": " b. qalpaxe (m)"
  },
  {
    "x": "qalpaxe (m)",
    "y": " kalpak"
  },
  {
    "x": "qalpaxi (m)",
    "y": " qalpaxe (m)"
  },
  {
    "x": "qaltax (m)",
    "y": " b. qaltaxe (m)"
  },
  {
    "x": "qaltaxe (m)",
    "y": " kaltak"
  },
  {
    "x": "qaltaxey (m)",
    "y": " b. qaltaxî (m), qaltaxîye (m)"
  },
  {
    "x": "qaltaxê (m)",
    "y": " b. qaltaxî (m), qaltaxîye (m)"
  },
  {
    "x": "qaltaxi (m)",
    "y": " b. qaltaxe (m)"
  },
  {
    "x": "qaltaxî (m)",
    "y": " kaltaklık"
  },
  {
    "x": "qaltaxîye (m)",
    "y": " kaltaklık"
  },
  {
    "x": "qaluçe (m)",
    "y": " bağ budama vb. işlerde kullanılan, orağa benzer bir çeşit küçük tahra, orak"
  },
  {
    "x": "qaluçî (m)",
    "y": " b. qaluçe (m)"
  },
  {
    "x": "qalunce (m)",
    "y": " b. qaluçe (m)"
  },
  {
    "x": "qalunçi (m)",
    "y": " b. qaluçe (m)"
  },
  {
    "x": "qalund, -e",
    "y": " b. qalind, -e"
  },
  {
    "x": "qalune (m)",
    "y": " b. qelûne (m)"
  },
  {
    "x": "qaluni (m)",
    "y": " b. qelûne (m)"
  },
  {
    "x": "qalûç-I (m)",
    "y": " b. qaluçe (m)"
  },
  {
    "x": "qalûç-II (m)",
    "y": " b. qilance (m)"
  },
  {
    "x": "qalûçi (m)",
    "y": " b. qaluçe (m)"
  },
  {
    "x": "qalûnçe (m)",
    "y": " b. qaluçe (m)"
  },
  {
    "x": "qalûqir (n)",
    "y": " dedikodu"
  },
  {
    "x": "qalûqirfî (zh)",
    "y": "  dedikodu"
  },
  {
    "x": "qalûqirr (n)",
    "y": " b. qalûqir (n)"
  },
  {
    "x": "qalxan (n)",
    "y": " kalkan"
  },
  {
    "x": "qalzav, -i",
    "y": " b. qalezaf, -e"
  },
  {
    "x": "qalzavey (m)",
    "y": " b. qalezafî (m), qalezafîye (m)"
  },
  {
    "x": "qalzavey kerdene",
    "y": " b. qalezafî kerdene, qalezafîye kerdene"
  },
  {
    "x": "qalzaveykerdiş (n)",
    "y": " b. qalezafîkerdiş (n), qalezafîyekerdiş (n)"
  },
  {
    "x": "qam (m)",
    "y": " b. qame (m)"
  },
  {
    "x": "qama (m)",
    "y": " kama"
  },
  {
    "x": "qamçî (m)",
    "y": " kamçı "
  },
  {
    "x": "qamçî kerdene",
    "y": " kamçılamak"
  },
  {
    "x": "qamçîyan ver şanayene",
    "y": " kamçılamak"
  },
  {
    "x": "qamçîyûn ver sanayene",
    "y": " b. qamçîyan ver şanayene"
  },
  {
    "x": "qamçî (n)",
    "y": " b. qamçî (m)"
  },
  {
    "x": "qamçu (m)",
    "y": " b. qamçî (m)"
  },
  {
    "x": "qamderg, -e",
    "y": " boylu"
  },
  {
    "x": "qame (m)",
    "y": " 1)boy\r\n~2)heykel"
  },
  {
    "x": "qama fistanî (m)",
    "y": " fistanın belden yukarı kısmı"
  },
  {
    "x": "qamera (m)",
    "y": " b. kamera (m)"
  },
  {
    "x": "qameraantiş (sn)",
    "y": " boy çekimi"
  },
  {
    "x": "qametiraş, -e",
    "y": " heykeltıraş"
  },
  {
    "x": "qami (m)",
    "y": " b. qame (m)"
  },
  {
    "x": "qamir (n)",
    "y": " saz (kamış, hasırotu vb.)"
  },
  {
    "x": "qamis (n)",
    "y": " b. qamîş (n)"
  },
  {
    "x": "qamîs (n)",
    "y": " b. qamîş (n)"
  },
  {
    "x": "qamîş (n)",
    "y": " kamış"
  },
  {
    "x": "qamon (n)",
    "y": " b. kamyone (m)"
  },
  {
    "x": "qampîreke (m)",
    "y": " mantar (bitkisi)"
  },
  {
    "x": "qampîreki (m)",
    "y": " b. qampîreke (m)"
  },
  {
    "x": "qampîrike (m)",
    "y": " b. qampîreke (m)"
  },
  {
    "x": "qampîriki (m)",
    "y": " b. qampîreke (m)"
  },
  {
    "x": "qamun (m)",
    "y": " b. kamyone (m)"
  },
  {
    "x": "qamur (n)",
    "y": " b. qamir (n)"
  },
  {
    "x": "qamyon (m)",
    "y": " b. kamyone (m)"
  },
  {
    "x": "qamyone (m)",
    "y": " b. kamyone (m)"
  },
  {
    "x": "qamyonet (m)",
    "y": " b. kamyonete (m)"
  },
  {
    "x": "qamyonete (m)",
    "y": " b. kamyonete (m)"
  },
  {
    "x": "qamyoneti (m)",
    "y": " b. kamyonete (m)"
  },
  {
    "x": "qamyoni (m)",
    "y": " b. kamyone (m)"
  },
  {
    "x": "qanat (n)",
    "y": " kat"
  },
  {
    "x": "qanca (m)",
    "y": " kanca"
  },
  {
    "x": "qançîli (m)",
    "y": " b. qamçî (m)"
  },
  {
    "x": "qandê",
    "y": " için"
  },
  {
    "x": "qandê coy",
    "y": " b. qandê cû"
  },
  {
    "x": "qandê cû",
    "y": " onun için"
  },
  {
    "x": "qandê Ellahî",
    "y": " Allah için"
  },
  {
    "x": "qandê Ellay",
    "y": " b. qandê Ellahî"
  },
  {
    "x": "qandê Homayî",
    "y": " Allah için"
  },
  {
    "x": "qandê ... rê",
    "y": " için"
  },
  {
    "x": "qando ke",
    "y": " için"
  },
  {
    "x": "qanik (n)",
    "y": " sakız, ciklet, çiklet"
  },
  {
    "x": "qanike (m)",
    "y": " b. qanik (n)"
  },
  {
    "x": "qaniki (m)",
    "y": " b. qanik (n)"
  },
  {
    "x": "qanîfer (n)",
    "y": " b. kalorîfer (n)"
  },
  {
    "x": "qanqile (n)",
    "y": " çıngıl, cıngıl, şıngıl (salkım parçası, küçük salkım)"
  },
  {
    "x": "qanqole (n)",
    "y": " b. qanqile (n)"
  },
  {
    "x": "qanser (n)",
    "y": " b. kanser (n)"
  },
  {
    "x": "qantar (n)",
    "y": " b. qenter (n)"
  },
  {
    "x": "qantarî destû (n)",
    "y": " b. qenterê destan (n)"
  },
  {
    "x": "qantarî destûn (n)",
    "y": " b. qenterê destan (n)"
  },
  {
    "x": "qantir (n)",
    "y": " katır"
  },
  {
    "x": "qantire (m)",
    "y": " b. qantir (n)"
  },
  {
    "x": "qantiri (m)",
    "y": " b. qantir (n)"
  },
  {
    "x": "qantirleyîr (n)",
    "y": " yavru katır"
  },
  {
    "x": "qanun (n)",
    "y": " b. qanûn (n)"
  },
  {
    "x": "qanûn (n)",
    "y": " yasa, kanun"
  },
  {
    "x": "qanûnê awanî (n)",
    "y": " imar kanunu"
  },
  {
    "x": "qanûnê cugehî (n)",
    "y": " çevre kanunu"
  },
  {
    "x": "qanûnê muhafezekerdişê herre (n)",
    "y": " toprak koruma kanunu"
  },
  {
    "x": "qanûnê pawitişê herre (n)",
    "y": " toprak koruma kanunu"
  },
  {
    "x": "qanûnê seveknayîşê herre (n)",
    "y": " toprak koruma kanunu"
  },
  {
    "x": "qanûnê sînema (n)",
    "y": " sinema yasası"
  },
  {
    "x": "qanûno bingeyî (n)",
    "y": " anayasa "
  },
  {
    "x": "qanûnî",
    "y": " yasal, kanuni"
  },
  {
    "x": "qanze (m)",
    "y": " b. qaze (m)"
  },
  {
    "x": "qanzi (m)",
    "y": " b. qaze (m)"
  },
  {
    "x": "qanzik (n)",
    "y": " tava"
  },
  {
    "x": "qapan (n)",
    "y": " kantar, kapan"
  },
  {
    "x": "qapan ro dayene",
    "y": " kantarlamak, kantara vurmak, kantara çekmek"
  },
  {
    "x": "qapanê destan (n)",
    "y": " el kantarı"
  },
  {
    "x": "qapanî (m)",
    "y": " b. qapan (n)"
  },
  {
    "x": "qapasîte (n)",
    "y": " b. kapasîte (n)"
  },
  {
    "x": "qapax (n)",
    "y": " kapak"
  },
  {
    "x": "qapaxê çimî (n)",
    "y": " göz kapağı"
  },
  {
    "x": "qapaxo betonên (n)",
    "y": " beton kapak"
  },
  {
    "x": "qapaxo pîkên (n)",
    "y": " pik kapak"
  },
  {
    "x": "qapê (m)",
    "y": " b. qehpeyî (m), qehpeyîye (m)"
  },
  {
    "x": "qapi (m)",
    "y": " b. qehpe (m)"
  },
  {
    "x": "qapikey (m)",
    "y": " b. qehpikî (m), qehpikîye (m)"
  },
  {
    "x": "qapikê (m)",
    "y": " b. qehpikî (m), qehpikîye (m)"
  },
  {
    "x": "qapiki (m)",
    "y": " b. qehpike (m)"
  },
  {
    "x": "qapîrik (m)",
    "y": " qampîreke (m)"
  },
  {
    "x": "qapon (n)",
    "y": " b. qapan (n)"
  },
  {
    "x": "qapon ro dayene",
    "y": " b. qapan ro dayene"
  },
  {
    "x": "qaponê desto (n)",
    "y": " b. qapanê destan (n)"
  },
  {
    "x": "qaponê deston (n)",
    "y": " b. qapanê destan (n)"
  },
  {
    "x": "qapqabik (n)",
    "y": " b. qapqapik (n)"
  },
  {
    "x": "qapqapik (n)",
    "y": " nalın, takunya"
  },
  {
    "x": "qaput (n)",
    "y": " kaput"
  },
  {
    "x": "qapûn (n)",
    "y": " b. qapan (n)"
  },
  {
    "x": "qapûnî destû (n)",
    "y": " b. qapanê destan (n)"
  },
  {
    "x": "qapûnî destûn (n)",
    "y": " b. qapanê destan (n)"
  },
  {
    "x": "qapûni (n)",
    "y": " b. qapan (n)"
  },
  {
    "x": "qapûni ri dayîne",
    "y": " b. qapan ro dayene"
  },
  {
    "x": "qapût (n)",
    "y": " b. qaput"
  },
  {
    "x": "qaq-I (n)",
    "y": " b. qaxe (m)"
  },
  {
    "x": "qaq-II (m)",
    "y": " b. qaqe (m)"
  },
  {
    "x": "qaqe (m)",
    "y": " topaç"
  },
  {
    "x": "qaqibnayene",
    "y": " 1)ötmek (keklik için)\r\n~2)meydan okumak\r\n~3)(mecazi) seri ve düzgün konuşmak"
  },
  {
    "x": "qaqibnayîş (n)",
    "y": " 1)ötme (keklik için)\r\n~2)meydan okuma"
  },
  {
    "x": "qaqibo (n)",
    "y": " 1)keklik ötüşü\r\n~2)hodri meydan, meydan okuma sözcüğü"
  },
  {
    "x": "qaqijikî (zh)",
    "y": " içyağının kızartılmasıyla meydana gelen bir yiyecek"
  },
  {
    "x": "qaqlîbaz, -e",
    "y": " martı"
  },
  {
    "x": "qaqnayene",
    "y": " b. qaqibnayene"
  },
  {
    "x": "qaqnayîş (n)",
    "y": " b. qaqibnayîş (n)"
  },
  {
    "x": "qaqote (m)",
    "y": " b. kakate (m) "
  },
  {
    "x": "qaqubnayene",
    "y": " b. qaqibnayene"
  },
  {
    "x": "qaqubnayîş (n)",
    "y": " b. qaqibnayîş (n)"
  },
  {
    "x": "qaqubo (n)",
    "y": " b. qaqibo (n)"
  },
  {
    "x": "qaqusuz, -e",
    "y": " b. bêqaxû, -ye"
  },
  {
    "x": "qar (n)",
    "y": " b. qehir (n)"
  },
  {
    "x": "qarakter (n)",
    "y": " b. karakter (n)"
  },
  {
    "x": "qaral (n)",
    "y": " b. qiral"
  },
  {
    "x": "qarangê çimî (n)",
    "y": " gözün aşağısındaki deri"
  },
  {
    "x": "qarayene-I",
    "y": " 1)melemek (koyun ve keçi için)\r\n~2)bağırmak"
  },
  {
    "x": "qarayene-II",
    "y": " b. qehirîyayene"
  },
  {
    "x": "qarayîs (n)",
    "y": " b. qehirîyayîş (n)"
  },
  {
    "x": "qarayîş-I (n)",
    "y": " 1)meleme (koyun ve keçi için)\r\n~2)bağırma"
  },
  {
    "x": "qarayîş-II (n)",
    "y": " b. qehirîyayîş (n)"
  },
  {
    "x": "qarbixa (m)",
    "y": " b. qabirxa (m)"
  },
  {
    "x": "qard (m)",
    "y": " b. qarde (m)"
  },
  {
    "x": "qard dayene",
    "y": " b. qarde dayene"
  },
  {
    "x": "qard kerdene",
    "y": " b. qarde kerdene"
  },
  {
    "x": "qardan, -i",
    "y": " b. gardîyan, -e"
  },
  {
    "x": "qardaney (m)",
    "y": " b. gardîyanîye (m)"
  },
  {
    "x": "qarde (m)",
    "y": " ödünç"
  },
  {
    "x": "qarde dayene",
    "y": " ödünç vermek"
  },
  {
    "x": "qarde kerdene",
    "y": " ödünç almak"
  },
  {
    "x": "qardedar, -e",
    "y": " verecekli"
  },
  {
    "x": "qardedayox, -e",
    "y": " alacaklı"
  },
  {
    "x": "qardedayoxîye (m)",
    "y": " alacaklılık"
  },
  {
    "x": "qardi (m)",
    "y": " b. qarde (m)"
  },
  {
    "x": "qardi dayene",
    "y": " b. qarde dayene"
  },
  {
    "x": "qardi kerdene",
    "y": " b. qarde kerdene"
  },
  {
    "x": "qardîyan, -i",
    "y": " b. gardîyan, -e"
  },
  {
    "x": "qardîyaney (m)",
    "y": " b. gardîyanîye (m)"
  },
  {
    "x": "qardîyon, -i",
    "y": " b. gardîyan, -e"
  },
  {
    "x": "qardîyoney (m)",
    "y": " b. gardîyanîye (m)"
  },
  {
    "x": "qardulabi (m)",
    "y": " b. gardirobe (m)"
  },
  {
    "x": "qarekter (n)",
    "y": " b. karakter (n)"
  },
  {
    "x": "qareman, -e",
    "y": " b. qehreman, -e"
  },
  {
    "x": "qaremaney (m)",
    "y": " b. qehremanî (m)"
  },
  {
    "x": "qaremanî (m)",
    "y": " b. qehremanî (m)"
  },
  {
    "x": "qareyene",
    "y": " b. qehirîyayene"
  },
  {
    "x": "qareyîne",
    "y": " b. qehirîyayene"
  },
  {
    "x": "qareyîş (n)",
    "y": " b. qehirîyayîş (n)"
  },
  {
    "x": "qaris bîyayene",
    "y": " b. qariş bîyene"
  },
  {
    "x": "qarisê … bîyene",
    "y": " b. qarişê … bîyene"
  },
  {
    "x": "qarisbîyayîş (n)",
    "y": " b. qarişbîyayîş (n)"
  },
  {
    "x": "qariste (n)",
    "y": " b. qerase (n)"
  },
  {
    "x": "qariş bîyene",
    "y": " karışmak (dokunmak)"
  },
  {
    "x": "qarişê … bîyene",
    "y": " -e karışmak"
  },
  {
    "x": "qarişbîyayîş (n)",
    "y": " karışma (dokunma)"
  },
  {
    "x": "qarişmîş bîyayene",
    "y": " b. qariş bîyene"
  },
  {
    "x": "qarişmîşê ... bîyayene",
    "y": " b. qarişê … bîyene"
  },
  {
    "x": "qarişmîşbîyayîş (n)",
    "y": " b. qarişbîyayîş (n)"
  },
  {
    "x": "qarix (n)",
    "y": " b. qarixe (m)"
  },
  {
    "x": "qarixe (m)",
    "y": " karık (küçük ark)"
  },
  {
    "x": "qarîyaya (m)",
    "y": " b. qehirîyaya (m)"
  },
  {
    "x": "qarîyaye (n)",
    "y": " b. qehirîyaye (n)"
  },
  {
    "x": "qarîyayene",
    "y": " b. qehirîyayene"
  },
  {
    "x": "qarîyayeyê (m)",
    "y": " b. qehirîyayeyî (m), qehirîyayeyîye (m)"
  },
  {
    "x": "qarîyayeyênî (m)",
    "y": " b. qehirîyayeyênî (m)"
  },
  {
    "x": "qarîyayeyî (m)",
    "y": " b. qehirîyayeyî (m), qehirîyayeyîye (m)"
  },
  {
    "x": "qarîyayeyîye (m)",
    "y": " b. qehirîyayeyî (m), qehirîyayeyîye (m)"
  },
  {
    "x": "qarîyayê (m)",
    "y": " b. qehirîyayîye (m)"
  },
  {
    "x": "qarîyayêne",
    "y": " b. qehirîyayene"
  },
  {
    "x": "qarîyayîs (n)",
    "y": " b. qehirîyayîş (n)"
  },
  {
    "x": "qarîyayîş (n)",
    "y": " b. qehirîyayîş (n)"
  },
  {
    "x": "qarîyayîye (m)",
    "y": " b. qehirîyayîye (m)"
  },
  {
    "x": "qarîyene",
    "y": " b. qehirîyayene"
  },
  {
    "x": "qarîyer (n)",
    "y": " b. karîyer"
  },
  {
    "x": "qarme (n)",
    "y": " b. qawirme (n)"
  },
  {
    "x": "qarmiş bîyayene",
    "y": " b. qariş bîyene"
  },
  {
    "x": "qarmişbîyayîş (n)",
    "y": " b. qarişbîyayîş (n)"
  },
  {
    "x": "qarmîs bîyayene",
    "y": " b. qariş bîyene"
  },
  {
    "x": "qarmîsê … bîyayene",
    "y": " b. qarişê … bîyene"
  },
  {
    "x": "qarmîsbîyayîş (n)",
    "y": " b. qarişbîyayîş (n)"
  },
  {
    "x": "qarmîş bîyayene",
    "y": " b. qariş bîyene"
  },
  {
    "x": "qarmîşê ... bîyayene",
    "y": " b. qarişê … bîyene"
  },
  {
    "x": "qarmîşbîyayîş (n)",
    "y": " b. qarişbîyayîş (n)"
  },
  {
    "x": "qarna (m)",
    "y": " b. qarne (m)"
  },
  {
    "x": "qarnayene",
    "y": " b. qehirnayene"
  },
  {
    "x": "qarnayêne",
    "y": " b. qehirnayene"
  },
  {
    "x": "qarnayîne",
    "y": " b. qehirnayene"
  },
  {
    "x": "qarnayîş (n)",
    "y": " b. qehirnayîş (n)"
  },
  {
    "x": "qarnayox, -e",
    "y": " b. qehirnayox, -e"
  },
  {
    "x": "qarne (m)",
    "y": " karne "
  },
  {
    "x": "qarosere (m)",
    "y": " b. karoser (n)"
  },
  {
    "x": "qarrayene",
    "y": " 1)b. qarayene-I"
  },
  {
    "x": "qarrayîş (n)",
    "y": " b. qarayîş-I (n)"
  },
  {
    "x": "qarse (n)",
    "y": " 1) kazgıç, ucu sivriltilip daha çok yeraltından bir şey çıkarmakta kullanılan çubuk\r\n~2)b. qerase (n)"
  },
  {
    "x": "qerfî bi ... kerdene",
    "y": " (ile) alay etmek"
  },
  {
    "x": "qergund (n)",
    "y": " b. qergundî (m)"
  },
  {
    "x": "qergundî (m)",
    "y": " kargaşa"
  },
  {
    "x": "qarse dekewtene",
    "y": " qerse dekewtene"
  },
  {
    "x": "qarse girowtene",
    "y": " b. qerse girewtene"
  },
  {
    "x": "qarsedekewtiş (n)",
    "y": " b. qersedekewtiş (n)"
  },
  {
    "x": "qarsegirowtiş (n)",
    "y": " b. qersegirewtiş(n)"
  },
  {
    "x": "qarsê … bîyene",
    "y": " b. qarişê … bîyene"
  },
  {
    "x": "qart-I (n)",
    "y": " b. karte (m)"
  },
  {
    "x": "qart, -e-II",
    "y": " b. qert, -e"
  },
  {
    "x": "qartapoz (n)",
    "y": " b. qetrepoz"
  },
  {
    "x": "qarte (m)",
    "y": " b. karte (m)"
  },
  {
    "x": "qarti (m)",
    "y": " b. karte (m)"
  },
  {
    "x": "qarula (m)",
    "y": " b. karyola (m)"
  },
  {
    "x": "qarût (n)",
    "y": " sap (bitki için)"
  },
  {
    "x": "qarxa (m)",
    "y": " b. qabirxa (m)"
  },
  {
    "x": "qaryayîş (n)",
    "y": " b. qehirîyayîş (n)"
  },
  {
    "x": "qaryola (n)",
    "y": " b. karyola (m)"
  },
  {
    "x": "qas",
    "y": " kadar"
  },
  {
    "x": "qasê ... ",
    "y": " ... kadar"
  },
  {
    "x": "qasa (m)",
    "y": " b. kasa (m)"
  },
  {
    "x": "qasadar, -e",
    "y": " b. kasadar, -e"
  },
  {
    "x": "qaset (n)",
    "y": " b. kaset (n)"
  },
  {
    "x": "qasete (m)",
    "y": " b. kaset (n)"
  },
  {
    "x": "qasid, -e",
    "y": " haberci, elçi"
  },
  {
    "x": "qasidî, -ye",
    "y": " sağlam"
  },
  {
    "x": "qasidî bîyayene",
    "y": " b. qasidî bîyene"
  },
  {
    "x": "qasidî bîyene",
    "y": " sağlam olmak, sağlamlaşmak"
  },
  {
    "x": "qasidî kerdene",
    "y": " sağlamlaştırmak"
  },
  {
    "x": "qasidîbîyayîş (n)",
    "y": " sağlam olma, sağlamlaşma"
  },
  {
    "x": "qasidîkerdiş (n)",
    "y": " sağlamlaştırma"
  },
  {
    "x": "qasidîtî (m)",
    "y": " sağlamlık"
  },
  {
    "x": "qasidîyey (m)",
    "y": " b. qasidîyî (m), qasidîyîye (m)"
  },
  {
    "x": "qasidîyê (m)",
    "y": " b. qasidîyî (m), qasidîyîye (m)"
  },
  {
    "x": "qasidîyî (m)",
    "y": " sağlamlık"
  },
  {
    "x": "qasidîyîye (m)",
    "y": " sağlamlık"
  },
  {
    "x": "qasik (n)",
    "y": " b. qaşik (n)"
  },
  {
    "x": "qasî",
    "y": " b. qasê"
  },
  {
    "x": "qaso",
    "y": " b. qasê"
  },
  {
    "x": "qastî",
    "y": " b. qestî-II"
  },
  {
    "x": "qasud, -e",
    "y": " b. qasid, -e"
  },
  {
    "x": "qaş-I (n)",
    "y": " kabuk"
  },
  {
    "x": "qaş kerdene",
    "y": " kabuğunu soymak"
  },
  {
    "x": "qaş, -e-II",
    "y": " beyaz renkli küçükbaş veya büyükbaş hayvan için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "qaşaxo (n)",
    "y": " b. qeşawî (n)"
  },
  {
    "x": "qaşik (n)",
    "y": " kabuk"
  },
  {
    "x": "qaşikê guvelikî (n)",
    "y": " salyangoz kabuğu"
  },
  {
    "x": "qaşikê pîyazî (n)",
    "y": " kuru soğan kabuğu"
  },
  {
    "x": "qaşikê şeytanî (n)",
    "y": " b. qaşikê şeytanokî (n)"
  },
  {
    "x": "qaşikê şeytanokî (n)",
    "y": " salyangoz kabuğu "
  },
  {
    "x": "qaşîk pîyûnzî (n)",
    "y": " b. qaşikê pîyazî (n)"
  },
  {
    "x": "qaşil (n)",
    "y": " kabuk"
  },
  {
    "x": "qaşo (zr)",
    "y": " güya"
  },
  {
    "x": "qaşû (zr)",
    "y": " b. qaşo (zr)"
  },
  {
    "x": "qaşû ka",
    "y": " b. qaşo (zr)"
  },
  {
    "x": "qat-I (n)",
    "y": " 1)kat\r\n~2)tabaka\r\n~3)takım (giysiler için)"
  },
  {
    "x": "qat bîyene (n)",
    "y": " katlanmak"
  },
  {
    "x": "qat kerdene (n)",
    "y": " katlamak"
  },
  {
    "x": "qatê binî (n)",
    "y": " alt kat"
  },
  {
    "x": "qatê dîyine (n)",
    "y": " ikinci kat"
  },
  {
    "x": "qatê erdî (n)",
    "y": " yerkatı, zemin katı"
  },
  {
    "x": "qatê serbanî (n)",
    "y": " çatı katı"
  },
  {
    "x": "qatê serekî (n)",
    "y": " başkanlık katı"
  },
  {
    "x": "qatê serekîye (n)",
    "y": " başkanlık katı"
  },
  {
    "x": "qatê serî (n)",
    "y": " üst kat"
  },
  {
    "x": "qatê zemînî (n)",
    "y": " yerkatı, zemin katı"
  },
  {
    "x": "qato binên (n)",
    "y": " alt kat"
  },
  {
    "x": "qato serên (n)",
    "y": " üst kat"
  },
  {
    "x": "qat bi qat",
    "y": " tabaka tabaka"
  },
  {
    "x": "qatê cilan (n)",
    "y": " 1-elbise takımı, giysi takımı, kostüm 2-yatak takımı (döşek, yorgan ve yastıktan oluşan bütün)"
  },
  {
    "x": "qatê çinayan (n)",
    "y": " elbise takımı, giysi takımı, kostüm"
  },
  {
    "x": "qatê kincan (n)",
    "y": " elbise takımı, giysi takımı, kostüm"
  },
  {
    "x": "qatê nivîne (n)",
    "y": " yatak takımı (döşek, yorgan ve yastıktan oluşan bütün)"
  },
  {
    "x": "qat-II (n)",
    "y": " b. qînate (m)"
  },
  {
    "x": "qatbîyaya (m)",
    "y": " katlı"
  },
  {
    "x": "qatbîyaye (n)",
    "y": " katlı"
  },
  {
    "x": "qatbîyayîş (n)",
    "y": " katlanma"
  },
  {
    "x": "qatbîyayîye (m)",
    "y": " katlı"
  },
  {
    "x": "qate (m)",
    "y": " b. qat-1 (n)"
  },
  {
    "x": "qata dîyine (m)",
    "y": " b. qatê dîyine (n)"
  },
  {
    "x": "qathûmare (m)",
    "y": " katsayı"
  },
  {
    "x": "qathûmara warê zemînî (m)",
    "y": " taban alanı katsayısı"
  },
  {
    "x": "qatin, -e",
    "y": " katlı"
  },
  {
    "x": "qatir (n)",
    "y": " b. qantir (n)"
  },
  {
    "x": "qatirleyr (n)",
    "y": " b. qantirleyîr (n)"
  },
  {
    "x": "qatix (n)",
    "y": " 1)süt ürünleri\r\n~2)yoğurt"
  },
  {
    "x": "qatixê pêlekî (n)",
    "y": " süzme yoğurt"
  },
  {
    "x": "qatixo pêlekdekerde (n)",
    "y": " süzme yoğurt"
  },
  {
    "x": "qatîl, -e",
    "y": " katil"
  },
  {
    "x": "qatkerda (m)",
    "y": " katlı"
  },
  {
    "x": "qatkerde (n)",
    "y": " katlı"
  },
  {
    "x": "qatkerdiş (n)",
    "y": " katlama"
  },
  {
    "x": "qatkerdîye (m)",
    "y": " katlı"
  },
  {
    "x": "qatlix (n)",
    "y": " elbise takımı, giysi takımı, kostüm"
  },
  {
    "x": "qatma (m)",
    "y": " gözleme, bazlama (bir hamur işi)"
  },
  {
    "x": "qatma toraqî",
    "y": " b. qatmaya toraqî"
  },
  {
    "x": "qatmaya toraqî",
    "y": " bir çeşit gözleme"
  },
  {
    "x": "qatqatî (m)",
    "y": " katmer (bir hamur işi)"
  },
  {
    "x": "qav (n)",
    "y": " b. qab (n)"
  },
  {
    "x": "qavute (m)",
    "y": " b. qawite (m)"
  },
  {
    "x": "qawa (m)",
    "y": " b. qewe (n)"
  },
  {
    "x": "qawanoz (n)",
    "y": " kavanoz"
  },
  {
    "x": "qawanoze (m)",
    "y": " b. qawanoz (n)"
  },
  {
    "x": "qawanozi (m)",
    "y": " b. qawanoz (n)"
  },
  {
    "x": "qawaxane (n)",
    "y": " b. qewexane (n)"
  },
  {
    "x": "qawax (n)",
    "y": " b. qawaxe (m)"
  },
  {
    "x": "qawaxo bez (n)",
    "y": " b. qewaxa bejî (m)"
  },
  {
    "x": "qawaxe (m)",
    "y": " b. qewaxe (m)"
  },
  {
    "x": "qawaxone (m)",
    "y": " b. qewexane (n)"
  },
  {
    "x": "qawaxûne (m)",
    "y": " b. qewexane (n)"
  },
  {
    "x": "qawe (m)",
    "y": " b. qewe (n)"
  },
  {
    "x": "qawexane (n)",
    "y": " b. qewexane (n)"
  },
  {
    "x": "qawexûne (m)",
    "y": " b. qewexane (n)"
  },
  {
    "x": "qawil (n)",
    "y": " b. qebul (n)"
  },
  {
    "x": "qawin (m)",
    "y": " b. qawune (m)"
  },
  {
    "x": "qawine (m)",
    "y": " b. qawune (m)"
  },
  {
    "x": "qawirme (n)",
    "y": " kavurma (et için)"
  },
  {
    "x": "qawisiz, -e",
    "y": " b. bêqaxû, -ye"
  },
  {
    "x": "qawite (m)",
    "y": " kavut"
  },
  {
    "x": "qawiti (m)",
    "y": " b. qawite (m)"
  },
  {
    "x": "qawu (n)",
    "y": " b. qaxû (n)"
  },
  {
    "x": "qawun (m)",
    "y": " b. qawune (m)"
  },
  {
    "x": "qawune (m)",
    "y": " kavun"
  },
  {
    "x": "qawunês (n)",
    "y": " b. qawanoz (n)"
  },
  {
    "x": "qawunêz (n)",
    "y": " b. qawanoz (n)"
  },
  {
    "x": "qawuni (m)",
    "y": " b. qawune (m)"
  },
  {
    "x": "qawurme (n)",
    "y": " qawirme (n)"
  },
  {
    "x": "qawurt (m)",
    "y": " b. qawite (m)"
  },
  {
    "x": "qawurti (m)",
    "y": " b. qawite (m)"
  },
  {
    "x": "qawusiz, -e",
    "y": " b. bêqaxû, -ye"
  },
  {
    "x": "qawute (m)",
    "y": " b. qawite (m)"
  },
  {
    "x": "qax (m)",
    "y": " b. qaxe (m)"
  },
  {
    "x": "qaxe (m)",
    "y": " kurutulmuş armut"
  },
  {
    "x": "qaxid (n)",
    "y": " b. kaxite (m)"
  },
  {
    "x": "qaxidi (m)",
    "y": " b. kaxite (m)"
  },
  {
    "x": "qaxit (n)",
    "y": " b. kaxite (m)"
  },
  {
    "x": "qaxu (n)",
    "y": " b. qaxû (n)"
  },
  {
    "x": "qaxusiz, -e",
    "y": " b. bêqaxû, -ye"
  },
  {
    "x": "qaxû (n)",
    "y": " endişe, kaygı, tasa, telaş"
  },
  {
    "x": "qaxû kerdene",
    "y": " endişelenmek, endişe etmek, kaygılanmak, tasalanmak"
  },
  {
    "x": "qaxûkerdiş (n)",
    "y": " endişelenme, kaygılanma, tasalanma"
  },
  {
    "x": "qaxûsiz, -e",
    "y": " b. bêqaxû, -ye"
  },
  {
    "x": "qay-I",
    "y": " b. qey-I"
  },
  {
    "x": "qay-II",
    "y": " b. qandê "
  },
  {
    "x": "qay ... a",
    "y": " b. qandê"
  },
  {
    "x": "qay Allay",
    "y": " b. qandê Ellahî"
  },
  {
    "x": "qay Ellay",
    "y": " b. qandê Ellahî"
  },
  {
    "x": "qay Homay",
    "y": " b. qandê Homayî"
  },
  {
    "x": "qay ... rê",
    "y": " b. qandê ... rê"
  },
  {
    "x": "qay ... rî",
    "y": " b. qandê ... rê"
  },
  {
    "x": "qay ... wa",
    "y": " b. qandê"
  },
  {
    "x": "qay ... ya",
    "y": " b. qandê"
  },
  {
    "x": "qaybê",
    "y": " b. qandê"
  },
  {
    "x": "qaybê ki",
    "y": " b. qando ke"
  },
  {
    "x": "qayde (n)",
    "y": " kural, kaide"
  },
  {
    "x": "qayde kerdene",
    "y": " taklit etmek"
  },
  {
    "x": "qayde piro dayene",
    "y": " taklit etmek"
  },
  {
    "x": "qaydeyê dînî (zh)",
    "y": " akait"
  },
  {
    "x": "qaydeyê hîjyenî (zh)",
    "y": " hijyen kuralları"
  },
  {
    "x": "qaydeyê trafîkî (zh)",
    "y": " trafik kuralları"
  },
  {
    "x": "qaydekerdiş (n)",
    "y": " taklit etme"
  },
  {
    "x": "qaydepirodayîş (n)",
    "y": " taklit etme"
  },
  {
    "x": "qaydeyin, -e",
    "y": " kurallı, kaideli"
  },
  {
    "x": "qaydê",
    "y": " b. qandê"
  },
  {
    "x": "qaydê ... rê",
    "y": " b. qandê ... rê"
  },
  {
    "x": "qaydê ... rî",
    "y": " b. qandê ... rê"
  },
  {
    "x": "qayiqe (m)",
    "y": " b. qeyaxe (m)"
  },
  {
    "x": "qayit kerdene",
    "y": " b. qayît kerdene"
  },
  {
    "x": "qayitê ... bîyayene",
    "y": " b. qayîtê ... bîyene"
  },
  {
    "x": "qayitê ... kerdene",
    "y": " b. qayîtê ... kerdene"
  },
  {
    "x": "qayîm, -e",
    "y": " sağlam"
  },
  {
    "x": "qayîm bîyayene",
    "y": " b. qayîm bîyene"
  },
  {
    "x": "qayîm bîyene",
    "y": " sağlam olmak, sağlamlaşmak"
  },
  {
    "x": "qayîm kerdene",
    "y": " sağlamlaştırmak"
  },
  {
    "x": "qayîmbîyayîş (n)",
    "y": " sağlam olma, sağlamlaşma"
  },
  {
    "x": "qayîmê (m)",
    "y": " b. qayîmî (m), qayîmîye"
  },
  {
    "x": "qayîmî (m)",
    "y": " sağlamlık"
  },
  {
    "x": "qayîmîye",
    "y": " sağlamlık"
  },
  {
    "x": "qayîmkerdiş (n)",
    "y": " sağlamlaştırma"
  },
  {
    "x": "qayîqe (m)",
    "y": " b. qeyaxe (m)"
  },
  {
    "x": "qayîs (n)",
    "y": " b. qayîş (n)"
  },
  {
    "x": "qayîş (n)",
    "y": " kayış "
  },
  {
    "x": "qayîşe (m)",
    "y": " b. qayîş (n)"
  },
  {
    "x": "qayît kerdene",
    "y": " 1)bakmak, gözetmek, izlemek, seyretmek, temaşa etmek\r\n~2)bakmak, beslemek, gözetmek, geçindirmek"
  },
  {
    "x": "qayîtê ... bîyene",
    "y": " -e bakmak"
  },
  {
    "x": "qayîtê ... kerdene",
    "y": " -e bakmak"
  },
  {
    "x": "qayîtbar, -e",
    "y": " izlenebilir (olan)"
  },
  {
    "x": "qayîtbarîye (m)",
    "y": " izlenebilirlik"
  },
  {
    "x": "qayîtkerda (m)",
    "y": " bakımlı"
  },
  {
    "x": "qayîtkerde (n)",
    "y": " bakımlı"
  },
  {
    "x": "qayîtkerdeyîye (m)",
    "y": " bakımlılık"
  },
  {
    "x": "qayîtkerdiş (n)",
    "y": " 1)bakma, gözetme, izleme, seyretme, temaşa etme\r\n~2)gözetim\r\n~3)bakma, besleme, geçindirme\r\n~4)koruma, muhafaza etme"
  },
  {
    "x": "qayîtkerdişê pîyaseyî (n)",
    "y": " piyasa gözetimi"
  },
  {
    "x": "qayîtkerdî, -ye",
    "y": " bakımlı"
  },
  {
    "x": "qayîtkerdîyîye (m)",
    "y": " bakımlılık"
  },
  {
    "x": "qayîtkerdox, -e",
    "y": " bakıcı"
  },
  {
    "x": "qayîtkerdoxa gedeyan/domanan/qeçekan/qican (m)",
    "y": " çocuk bakıcısı"
  },
  {
    "x": "qayîtkerdoxê gedeyan/domanan/qeçekan/qican (n)",
    "y": " çocuk bakıcısı"
  },
  {
    "x": "qayîtkerdoxîye (m)",
    "y": " bakıcılık"
  },
  {
    "x": "qayîtxane (m)",
    "y": " bakım evi"
  },
  {
    "x": "qaymaq (n)",
    "y": " b. qaymax (n)"
  },
  {
    "x": "qaymaqam, -e",
    "y": " b. qaymeqam, -e"
  },
  {
    "x": "qaymax (n)",
    "y": " kaymak"
  },
  {
    "x": "qayme (n)",
    "y": " b. qîyame (n)"
  },
  {
    "x": "qaymewo qij (n)",
    "y": " b. qîyameyo qij (n)"
  },
  {
    "x": "qaymeqam, -e",
    "y": " kaymakam "
  },
  {
    "x": "qaymi (n)",
    "y": " b. qîyame (n)"
  },
  {
    "x": "qayt kerdene",
    "y": " b. qayît kerdene"
  },
  {
    "x": "qaytê ... bîyayene",
    "y": " b. qayîtê ... bîyene"
  },
  {
    "x": "qaytê ... kerdene",
    "y": " b. qayîtê ... kerdene"
  },
  {
    "x": "qayta kerdene",
    "y": " b. qayît kerdene"
  },
  {
    "x": "qaytakerdiş (n)",
    "y": " b. qayîtkerdiş (n)"
  },
  {
    "x": "qayte bîyayene",
    "y": " b. qayîtê ... bîyene"
  },
  {
    "x": "qayte kerden",
    "y": " b. qayît kerdene"
  },
  {
    "x": "qaytekerdiş (n)",
    "y": " b. qayîtkerdiş (n)"
  },
  {
    "x": "qaytkerdiş (n)",
    "y": " b. qayîtkerdiş (n)"
  },
  {
    "x": "qaz (n)",
    "y": " 1)b. gaz (n)\r\n~2)b. gazyaxî (n)"
  },
  {
    "x": "qazan (n)",
    "y": " kazan"
  },
  {
    "x": "qazanê awkeyî (n)",
    "y": " banyo kazanı"
  },
  {
    "x": "qazanê banyoyî (n)",
    "y": " banyo kazanı"
  },
  {
    "x": "qazanê çale (m)",
    "y": " banyo kazanı"
  },
  {
    "x": "qazanê çerxî (n)",
    "y": " banyo kazanı"
  },
  {
    "x": "qazanê hemamekî (n)",
    "y": " banyo kazanı"
  },
  {
    "x": "qazanta (m)",
    "y": " b. gazeta (m)"
  },
  {
    "x": "qazanxane (n)",
    "y": " kazan dairesi"
  },
  {
    "x": "qazaq (n)",
    "y": " b. qazax (n)"
  },
  {
    "x": "qazaqo orlonên (n)",
    "y": " b. qazaxo orlonên (n)"
  },
  {
    "x": "qazax (n)",
    "y": " kazak"
  },
  {
    "x": "qazaxo orlonên (n)",
    "y": " orlon kazak"
  },
  {
    "x": "qazaxe (m)",
    "y": " b. qazax (n)"
  },
  {
    "x": "qazaxa orlonêne (m)",
    "y": " b. qazaxo orlonên (n)"
  },
  {
    "x": "qazaxî (m)",
    "y": " b. gazyaxî (n)"
  },
  {
    "x": "Qazaxîstan (n)",
    "y": " Kazakistan"
  },
  {
    "x": "qaze (m)",
    "y": " 1)kaz\r\n~2)hindi"
  },
  {
    "x": "qazemîş kerdene",
    "y": " b. qezenmîş kerdene"
  },
  {
    "x": "qazexî (m)",
    "y": " b. gazyaxî (n)"
  },
  {
    "x": "qazi (m)",
    "y": " b. qaze (m)"
  },
  {
    "x": "qazik (n)",
    "y": " 1)bakraç\r\n~2)kazan"
  },
  {
    "x": "qazî, -ye",
    "y": " hâkim, yargıç, kadı"
  },
  {
    "x": "qazîyîye (m)",
    "y": " hâkimlik, yargıçlık, kadılık"
  },
  {
    "x": "qaznayene",
    "y": " (tavuğun yumurtladıktan sonra ses çıkarması) gıdaklamak"
  },
  {
    "x": "qaznayîş (n)",
    "y": " (tavuğun yumurtladıktan sonra ses çıkarması) gıdaklama"
  },
  {
    "x": "qazocaxî (m)",
    "y": " b. gazocaxî (m)"
  },
  {
    "x": "qazon (n)",
    "y": " b. qazan (n)"
  },
  {
    "x": "qazûn (n)",
    "y": " b. qazan (n)"
  },
  {
    "x": "qazyaxî (n)",
    "y": " b. gazyaxî (n)"
  },
  {
    "x": "qe-I",
    "y": " b. qet"
  },
  {
    "x": "qe çiyê",
    "y": " b. qet çîyê"
  },
  {
    "x": "qe çîke",
    "y": " b. qet çîke"
  },
  {
    "x": "qe finê",
    "y": " b. qet finê"
  },
  {
    "x": "qe jû",
    "y": " b. qet yew"
  },
  {
    "x": "qe mebo",
    "y": " b. qet nêbo"
  },
  {
    "x": "qe nê",
    "y": " b. qet nêbo"
  },
  {
    "x": "qe nêbo",
    "y": " b. qet nêbo"
  },
  {
    "x": "qe nekê",
    "y": " b. qet neke"
  },
  {
    "x": "qe nî",
    "y": " b. qet nêbo"
  },
  {
    "x": "qe nîga",
    "y": " b. qet neke"
  },
  {
    "x": "qe rey",
    "y": " b. qet reyê"
  },
  {
    "x": "qe tewa",
    "y": " b. qet teba"
  },
  {
    "x": "qe-II",
    "y": " b. qandê"
  },
  {
    "x": "qebahet (n)",
    "y": " b. qebehet (n)"
  },
  {
    "x": "qebala (n)",
    "y": " b. qebale (n)"
  },
  {
    "x": "qebale (n)",
    "y": " götürü, kabala "
  },
  {
    "x": "qebale girewtene",
    "y": " 1)götürü iş almak, götürü usülü iş yapmak\r\n~2)(mecazi) durmaksızın çok çalışmak"
  },
  {
    "x": "qebale girowtene",
    "y": " b. qebale girewtene"
  },
  {
    "x": "qebale kerdene",
    "y": " götürü usülü iş yapmak"
  },
  {
    "x": "qebale xebitîyayene",
    "y": " götürü usülü iş yapmak"
  },
  {
    "x": "qebaleyî reyde xebitîyayene",
    "y": " götürü usülü iş yapmak"
  },
  {
    "x": "qebalî reydi xebetîyayene",
    "y": " b. qebaleyî reyde xebitîyayene"
  },
  {
    "x": "qebali (n)",
    "y": " b. qebale (n)"
  },
  {
    "x": "qebardîn (n)",
    "y": " gabardin"
  },
  {
    "x": "qebax (m)",
    "y": " b. qewaxe (m)"
  },
  {
    "x": "qebaxa buerî (m)",
    "y": " b. qewaxa borî (m)"
  },
  {
    "x": "qebaxi (m)",
    "y": " b. qewaxe (m)"
  },
  {
    "x": "qebaxêri (m)",
    "y": " b. qewaxêre (m)"
  },
  {
    "x": "qebehet (n)",
    "y": " kabahat"
  },
  {
    "x": "qebehet ... kerdene",
    "y": " -nin ırzına geçmek, -e tecavüz etmek"
  },
  {
    "x": "qebehet ... kerdiş (n)",
    "y": " -nin ırzına geçme, -e tecavüz etme"
  },
  {
    "x": "qebhet (n)",
    "y": " b. b. qebehet (n)"
  },
  {
    "x": "qebhet ... kerdene",
    "y": " b. qebehet ... kerdene"
  },
  {
    "x": "qebhet ... kerdiş (n)",
    "y": " b. qebehet ... kerdiş (n)"
  },
  {
    "x": "qebet (n)",
    "y": " b. b. qebehet (n)"
  },
  {
    "x": "qebet ... kerdene",
    "y": " b. qebehet ... kerdene"
  },
  {
    "x": "qebet ... kerdiş (n)",
    "y": " b. qebehet ... kerdiş (n)"
  },
  {
    "x": "qebir (m)",
    "y": " b. qebre (m)"
  },
  {
    "x": "qebirîstûn (n)",
    "y": " b. qebrîstan (n)"
  },
  {
    "x": "qebiz (n)",
    "y": " b. qebz (n)"
  },
  {
    "x": "qebizênî (m)",
    "y": " b. qebzênî (m)"
  },
  {
    "x": "qebizîye (m)",
    "y": " b. qebzîye (m)"
  },
  {
    "x": "qebîla (m)",
    "y": " kabile"
  },
  {
    "x": "qebîlîyet (n)",
    "y": " b. qabîlîyet (n)"
  },
  {
    "x": "qebra (n)",
    "y": " b. kerwa (n)"
  },
  {
    "x": "qebre (m)",
    "y": " mezar"
  },
  {
    "x": "qebri (m)",
    "y": " b. qebre (m)"
  },
  {
    "x": "qebristan (n)",
    "y": " b. qebrîstan (n)"
  },
  {
    "x": "qebriston (n)",
    "y": " b. qebrîstan (n)"
  },
  {
    "x": "qebristûn (n)",
    "y": " b. qebrîstan (n)"
  },
  {
    "x": "qebrîstan (n)",
    "y": " mezarlık"
  },
  {
    "x": "qebrîstanwan, -e",
    "y": " mezarcı (mezarlığa bakan)"
  },
  {
    "x": "qebrîstûn (n)",
    "y": " b. qebrîstan (n)"
  },
  {
    "x": "qebul (n)",
    "y": " kabul, akseptans"
  },
  {
    "x": "qebul girewtene",
    "y": " kabul almak (üniversite vb. için)"
  },
  {
    "x": "qebul kerdene",
    "y": " kabul etmek, benimsemek, kabullenmek"
  },
  {
    "x": "qebulbîyaye/a",
    "y": " akredite"
  },
  {
    "x": "qebulkerdiş (n)",
    "y": " benimseme, muvafakat"
  },
  {
    "x": "qebûl (n)",
    "y": " b. qebul (n)"
  },
  {
    "x": "qebz (n)",
    "y": " kabız, peklik"
  },
  {
    "x": "qebza (m)",
    "y": " deste, kabza"
  },
  {
    "x": "qebzey (m)",
    "y": " b. qebzîye (m)"
  },
  {
    "x": "qebzê (m)",
    "y": " b. qebzîye (m)"
  },
  {
    "x": "qebzênî (m)",
    "y": " kabızlık"
  },
  {
    "x": "qebzîye (m)",
    "y": " kabızlık "
  },
  {
    "x": "qeç, -i",
    "y": " b. qic, -e-I; qeçek, -e"
  },
  {
    "x": "qeç û qûl",
    "y": " çoluk çocuk"
  },
  {
    "x": "qeç-qûl",
    "y": " çoluk çocuk"
  },
  {
    "x": "qecek, -e",
    "y": " b.qeçek, -e"
  },
  {
    "x": "qeçek, -e",
    "y": " çocuk"
  },
  {
    "x": "qeçek bîyene",
    "y": " çocuklaşmak"
  },
  {
    "x": "qeçek ver şîyayene",
    "y": " b. qeçek ver şîyene"
  },
  {
    "x": "qeçek ver şîyene",
    "y": " düşük yapmak"
  },
  {
    "x": "qeçekane",
    "y": " çocukça"
  },
  {
    "x": "qeçekbîyayîş (n)",
    "y": " çocuklaşma"
  },
  {
    "x": "qeçeki (m)",
    "y": " b. qeçeke (m)"
  },
  {
    "x": "qeçekkî",
    "y": " çocukça"
  },
  {
    "x": "qeçik, -e",
    "y": " b. qeçek, -e"
  },
  {
    "x": "qed",
    "y": " b. qet"
  },
  {
    "x": "qeda (n)",
    "y": " 1)bela\r\n~2)b. qeza-II (n)"
  },
  {
    "x": "qeda û bela",
    "y": " kaza ve bela"
  },
  {
    "x": "qedar, -e",
    "y": " b. xedar, -e"
  },
  {
    "x": "qedayîf (n)",
    "y": " kadayıf"
  },
  {
    "x": "qedayîfe (m)",
    "y": " b. qedayîf (n)"
  },
  {
    "x": "qedayîfi (m)",
    "y": " b. qedayîf (n)"
  },
  {
    "x": "qedayîş (n)",
    "y": " b. qedênayîş (n)"
  },
  {
    "x": "qeddar, -e",
    "y": " b. xedar, -e"
  },
  {
    "x": "qede (n)",
    "y": " b. qayde (n)"
  },
  {
    "x": "qedeh (n)",
    "y": " b. qedehe (m)"
  },
  {
    "x": "qedehe (m)",
    "y": " kadeh"
  },
  {
    "x": "qedeha şimite (m)",
    "y": " içki kadehi"
  },
  {
    "x": "qedek (n)",
    "y": " b. qedik (n)"
  },
  {
    "x": "qedenayene",
    "y": " b. qedênayene"
  },
  {
    "x": "qedenayêne",
    "y": " b. qedênayene"
  },
  {
    "x": "qeder-I (n)",
    "y": " alın yazısı, yazgı, mukadderat, kader"
  },
  {
    "x": "qeder-II",
    "y": " hariç\r\n~Naye ra qederî baş bî. (Bu hariç iyiydi.)"
  },
  {
    "x": "qedexa (m)",
    "y": " yasak"
  },
  {
    "x": "qedexe (n)",
    "y": " yasak"
  },
  {
    "x": "qedexeyê çapemenîye (n)",
    "y": " basın yasağı"
  },
  {
    "x": "qedexekerdiş (n)",
    "y": " yasaklama, men"
  },
  {
    "x": "qedexîye (m)",
    "y": " yasak"
  },
  {
    "x": "qedeyayene",
    "y": " b. qedîyayene"
  },
  {
    "x": "qedeyîş (n)",
    "y": " b. qedîyayîş (n)"
  },
  {
    "x": "qedeynayene",
    "y": " b. qedênayene"
  },
  {
    "x": "qedeynayîş (n)",
    "y": " b. qedênayîş (n)"
  },
  {
    "x": "qedênayene",
    "y": " 1)bitirmek, sonuçlandırmak\r\n~2)kurtarmak\r\n~3)tüketmek"
  },
  {
    "x": "qedênayîş (n)",
    "y": " 1)bitirme, sonuçlandırma\r\n~2)kurtarma\r\n~3)tüketme"
  },
  {
    "x": "qedêk (n)",
    "y": " b. qedik (n)"
  },
  {
    "x": "qedêr",
    "y": " b. qeder-II"
  },
  {
    "x": "qedfe (n)",
    "y": " b. qedîfe (n)"
  },
  {
    "x": "qedihe (m)",
    "y": " b. qedehe (m)"
  },
  {
    "x": "qedihi (m)",
    "y": " b. qedehe (m)"
  },
  {
    "x": "qedik (n)",
    "y": " Amerikan bezi, kaputbezi, Amerikan; bir çeşit bez"
  },
  {
    "x": "qedim (n)",
    "y": " b. qedum (n)"
  },
  {
    "x": "qedinayene",
    "y": " b. qedênayene"
  },
  {
    "x": "qedir dayene",
    "y": " b. qedr dayene"
  },
  {
    "x": "qedîfe-I (n)",
    "y": " kadife"
  },
  {
    "x": "qedîfe-II (n)",
    "y": " b. qetîfe (n)"
  },
  {
    "x": "qedîfeyên, -e",
    "y": " kadifeli"
  },
  {
    "x": "şayaxê/pantolê/şapikê qedîfeyênî (zh)",
    "y": " kadife pantolon"
  },
  {
    "x": "qedîfeyin, -e",
    "y": " kadifeli (kadife ihtiva eden)"
  },
  {
    "x": "qedînayene",
    "y": " b. qedênayene"
  },
  {
    "x": "qedînayîş (n)",
    "y": " b. qedênayîş (n)"
  },
  {
    "x": "qedîyayene",
    "y": " 1)bitmek\r\n~2)kurtulmak"
  },
  {
    "x": "qedîyayêne",
    "y": " b. qedîyayene"
  },
  {
    "x": "qedîyayîş (n)",
    "y": " 1)bitme, bitiş\r\n~2)kurtulma"
  },
  {
    "x": "qednayene",
    "y": " b. qedênayene"
  },
  {
    "x": "qednayîş (n)",
    "y": " b. qedênayîş (n)"
  },
  {
    "x": "qedr dayene",
    "y": " saymak"
  },
  {
    "x": "qedr zanayene",
    "y": " saymak"
  },
  {
    "x": "qedrberz, -e",
    "y": " saygıdeğer, muhterem, sayın, saygın"
  },
  {
    "x": "qedrberzî (m)",
    "y": " saygınlık"
  },
  {
    "x": "qedrberzîye (m)",
    "y": " saygınlık"
  },
  {
    "x": "qedrdar, -e",
    "y": " saygılı, hürmetkâr, hürmetli"
  },
  {
    "x": "qedrdarî (m)",
    "y": " saygılılık"
  },
  {
    "x": "qedrdarîye (m)",
    "y": " saygılılık"
  },
  {
    "x": "qedrdayîş (n)",
    "y": " sayma"
  },
  {
    "x": "qedrzanayîş (n)",
    "y": " sayma"
  },
  {
    "x": "qedum (n)",
    "y": " keser"
  },
  {
    "x": "qedûk (n)",
    "y": " b. qedum (n)"
  },
  {
    "x": "qedûm (n)",
    "y": " b. qedum (n) "
  },
  {
    "x": "qedyayene",
    "y": " b. qedîyayene"
  },
  {
    "x": "qedyayîş (n)",
    "y": " b. qedîyayîş (n)"
  },
  {
    "x": "qeerî",
    "y": " b. xeyrê ..."
  },
  {
    "x": "qef (n)",
    "y": " akarsuyun sürüklediği ağaç parçası"
  },
  {
    "x": "qefalnayene",
    "y": " b. qefilnayene"
  },
  {
    "x": "qefalnayîş (n)",
    "y": " b. qefilnayîş (n)"
  },
  {
    "x": "qefalyayene",
    "y": " b. qefilîyayene"
  },
  {
    "x": "qefalyayîş (n)",
    "y": " b. qefilîyayîş (n)"
  },
  {
    "x": "qefçil, -e",
    "y": " çirkin, kirli"
  },
  {
    "x": "qefçil bîyene",
    "y": " kirlenmek "
  },
  {
    "x": "qefçil kerdene",
    "y": " kirletmek"
  },
  {
    "x": "qefçilbîyayîş (n)",
    "y": " kirlenme"
  },
  {
    "x": "qefçilênî (m)",
    "y": " kirlilik"
  },
  {
    "x": "qefçilî (m)",
    "y": " kirlilik"
  },
  {
    "x": "qefçilîye (m)",
    "y": " kirlilik"
  },
  {
    "x": "qefçilkerdiş (n)",
    "y": " kirletme"
  },
  {
    "x": "qefel (n)",
    "y": " b. qefil (n)"
  },
  {
    "x": "qefelîyaya (m)",
    "y": " b. qefilîyaya (m)"
  },
  {
    "x": "qefelîyaye (n)",
    "y": " b. qefilîyaye (n)"
  },
  {
    "x": "qefelîyayene",
    "y": " b. qefilîyayene"
  },
  {
    "x": "qefelîyayeyî (m)",
    "y": " b. qefilîyayeyî (m)"
  },
  {
    "x": "qefelîyayêne",
    "y": " b. qefilîyayene"
  },
  {
    "x": "qefelîyayine",
    "y": " b. qefilîyayene"
  },
  {
    "x": "qefelîyayîş (n)",
    "y": " b. qefilîyayîş (n)"
  },
  {
    "x": "qefelîyayîye (m)",
    "y": " b. qefilîyayîye (m)"
  },
  {
    "x": "qefelîyene",
    "y": " b. qefilîyayene"
  },
  {
    "x": "qefelnayene",
    "y": " b. qefilnayene"
  },
  {
    "x": "qefelnayêne",
    "y": " b. qefilnayene"
  },
  {
    "x": "qefelnayîş (n)",
    "y": " b. qefilnayîş (n)"
  },
  {
    "x": "qefelyaya (m)",
    "y": " b. qefilîyaya (m)"
  },
  {
    "x": "qefelyaye (n)",
    "y": " b. qefilîyaye (n)"
  },
  {
    "x": "qefelyayene",
    "y": " b. qefilîyayene"
  },
  {
    "x": "qefelyayeyî (m)",
    "y": " b. qefilîyayeyî (m)"
  },
  {
    "x": "qefelyayîş (n)",
    "y": " b. qefilîyayîş (n)"
  },
  {
    "x": "qefes (n)",
    "y": " kafes"
  },
  {
    "x": "qefes sînîy (n)",
    "y": " b. qefesê sênî (n)"
  },
  {
    "x": "qefesê sênî (n)",
    "y": " göğüs kafesi"
  },
  {
    "x": "qefil-I (n)",
    "y": " kilit (asma kilit)"
  },
  {
    "x": "qefil-II (n)",
    "y": " yorgunluk"
  },
  {
    "x": "qefilîyaya (m)",
    "y": " yorgun"
  },
  {
    "x": "qefilîyaye (n)",
    "y": " yorgun"
  },
  {
    "x": "qefilîyayene",
    "y": " 1)yorulmak\r\n~2)donmak"
  },
  {
    "x": "qefilîyayeyî (m)",
    "y": " yorgunluk"
  },
  {
    "x": "qefilîyayîş (n)",
    "y": " 1)yorulma\r\n~2)donma"
  },
  {
    "x": "qefilîyayîye (m)",
    "y": " yorgun"
  },
  {
    "x": "qefilnayene",
    "y": " yormak"
  },
  {
    "x": "qefilnayîş (n)",
    "y": " yorma"
  },
  {
    "x": "qefilyaya (m)",
    "y": " b. qefilîyaya (m)"
  },
  {
    "x": "qefilyaye (n)",
    "y": " b. qefilîyaye (n)"
  },
  {
    "x": "qefilyayene",
    "y": " b. qefilîyayene"
  },
  {
    "x": "qefilyayeyê (m)",
    "y": " b. qefilîyayeyî (m)"
  },
  {
    "x": "qefilyayîş (n)",
    "y": " b. qefilîyayîş (n)"
  },
  {
    "x": "qefîfe (n)",
    "y": " b. qetrîbe (n)"
  },
  {
    "x": "qefl (n)",
    "y": " b. qefil-I (n)"
  },
  {
    "x": "qeflayi (n)",
    "y": " b. qefilîyaye (n)"
  },
  {
    "x": "qefle (n)",
    "y": " kafile, insan veya hayvan kümesi, küme"
  },
  {
    "x": "qefle be qefle",
    "y": " b. qefle bi qefle"
  },
  {
    "x": "qefle bi qefle",
    "y": " akın akın"
  },
  {
    "x": "qefle qefle",
    "y": " akın akın"
  },
  {
    "x": "qeflik (n)",
    "y": " b. qefil-I (n)"
  },
  {
    "x": "qeflîyaya (m)",
    "y": " b. qefilîyaya (m)"
  },
  {
    "x": "qeflîyaye (n)",
    "y": " b. qefilîyaye (n)"
  },
  {
    "x": "qeflîyayeyî (m)",
    "y": " b. qefilîyayeyî (m)"
  },
  {
    "x": "qeflyayi (n)",
    "y": " b. qefilîyaye (n)"
  },
  {
    "x": "qeflyayîyê (m)",
    "y": " b. qefilîyayeyî (m)"
  },
  {
    "x": "qefsîng (n)",
    "y": " göğüs kafesi"
  },
  {
    "x": "qefş (m)",
    "y": " b. qevde (m)"
  },
  {
    "x": "qeft (m)",
    "y": " b. qevde (m)"
  },
  {
    "x": "qeher (n)",
    "y": " b. qehir (n)"
  },
  {
    "x": "qeherîyaya (m)",
    "y": " b. qehirîyaya (m)"
  },
  {
    "x": "qeherîyaye (n)",
    "y": " b. qehirîyaye (n)"
  },
  {
    "x": "qeherîyayene",
    "y": " b. qehirîyayene"
  },
  {
    "x": "qeherîyayeyê (m)",
    "y": " b. qehirîyayeyî (m), qehirîyayeyîye (m)"
  },
  {
    "x": "qeherîyayeyênî (m)",
    "y": " b. qehirîyayeyênî (m)"
  },
  {
    "x": "qeherîyayeyî (m)",
    "y": " b. qehirîyayeyî (m), qehirîyayeyîye (m)"
  },
  {
    "x": "qeherîyayeyîye (m)",
    "y": " b. qehirîyayeyî (m), qehirîyayeyîye (m)"
  },
  {
    "x": "qeherîyayê (m)",
    "y": " b. qehirîyayîye (m)"
  },
  {
    "x": "qeherîyayîş (n)",
    "y": " b. qehirîyayîş (n)"
  },
  {
    "x": "qeherîyayîye (m)",
    "y": " b. qehirîyayîye (m)"
  },
  {
    "x": "qehernayene",
    "y": " b. qehirnayene"
  },
  {
    "x": "qehernayîş (n)",
    "y": " b. qehirnayîş (n)"
  },
  {
    "x": "qehernayox, -e",
    "y": " b. qehirnayox, -e"
  },
  {
    "x": "qehir (n)",
    "y": " kızgınlık, kahır, öfke, derin üzüntü"
  },
  {
    "x": "qehirîyaya (m)",
    "y": " 1)kızgın (öfkeli)\r\n~2)küskün, küs, dargın\r\n~3)üzgün, üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü"
  },
  {
    "x": "qehirîyaye (n)",
    "y": " 1)kızgın (öfkeli)\r\n~2)küskün, küs, dargın\r\n~3)üzgün, üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü"
  },
  {
    "x": "qehirîyaye bîyene",
    "y": " 1)kızgın olmak\r\n~2)üzgün olmak"
  },
  {
    "x": "qehirîyayene",
    "y": " 1)öfkelenmek, sinirlenmek, kızmak, asabileşmek\r\n~2)küsmek, darılmak\r\n~3)kıskanmak, günülemek, haset etmek (çekememek)\r\n~4)gamlanmak"
  },
  {
    "x": "qehirîyayeyê (m)",
    "y": " b. qehirîyayeyî (m), qehirîyayeyîye (m)"
  },
  {
    "x": "qehirîyayeyênî (m)",
    "y": " üzgünlük, üzüntülülük"
  },
  {
    "x": "qehirîyayeyî (m)",
    "y": " üzgünlük, üzüntülülük"
  },
  {
    "x": "qehirîyayeyîye (m)",
    "y": " üzgünlük, üzüntülülük"
  },
  {
    "x": "qehirîyayê (m)",
    "y": " b. qehirîyayîye (m)"
  },
  {
    "x": "qehirîyayîş (n)",
    "y": " 1)gamlanma\r\n~2)küsme\r\n~3)kızma (öfkelenme, sinirlenme), asabileşme\r\n~4) kıskanma, günüleme, haset etme (çekememe)"
  },
  {
    "x": "qehirîyayîye (m)",
    "y": " 1)kızgın (öfkeli)\r\n~2)küskün, küs, dargın\r\n~3)üzgün, üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü"
  },
  {
    "x": "qehirnayene",
    "y": " 1)darıltmak, küstürmek\r\n~2)kıskandırmak\r\n~3)kızdırmak, öfkelendirmek, sinirlendirmek\r\n~4)üzmek"
  },
  {
    "x": "qehirnayîş (n)",
    "y": " 1)darıltma, küstürme\r\n~2)kızdırma "
  },
  {
    "x": "qehirnayox, -e",
    "y": " üzüntülü (üzen durum veya olay), üzücü (durum veya olay)"
  },
  {
    "x": "qehiryayîş (n)",
    "y": " b. qehirîyayîş (n)"
  },
  {
    "x": "qehp (m)",
    "y": " b. qehpe (m)"
  },
  {
    "x": "qehpe-I (m)",
    "y": " orospu, fahişe, kahpe, sürtük"
  },
  {
    "x": "qehpe-II (n)",
    "y": " 1)(mecazi) orospu, fahişe, kahpe, sürtük\r\n~2)(mecazi) dönek "
  },
  {
    "x": "qehpey (m)",
    "y": " b. qehpeyî (m), qehpeyîye (m)"
  },
  {
    "x": "qehpeyî (m)",
    "y": " 1)orospuluk, fahişelik, kahpelik, sürtüklük\r\n~2) (mecazi) döneklik"
  },
  {
    "x": "qehpeyîye (m)",
    "y": " 1)orospuluk, fahişelik, kahpelik, sürtüklük\r\n~2) (mecazi) döneklik "
  },
  {
    "x": "qehpê (m)",
    "y": " b. qehpeyî (m), qehpeyîye (m)"
  },
  {
    "x": "qehpi (m)",
    "y": " b. qehpe (m)"
  },
  {
    "x": "qehpik-I (m)",
    "y": " b. qehpike (m)"
  },
  {
    "x": "qehpik-II (n)",
    "y": " (mecazi) orospu, fahişe, kahpe, sürtük"
  },
  {
    "x": "qehpike (m)",
    "y": " orospu, fahişe, kahpe, sürtük"
  },
  {
    "x": "qehpikey (m)",
    "y": " b. qehpikî (m), qehpikîye (m)"
  },
  {
    "x": "qehpikê (m)",
    "y": " b. qehpikî (m), qehpikîye (m)"
  },
  {
    "x": "qehpiki (m)",
    "y": " b. qehpike (m)"
  },
  {
    "x": "qehpikî (m)",
    "y": " orospuluk, fahişelik, kahpelik, sürtüklük"
  },
  {
    "x": "qehpikîye (m)",
    "y": " orospuluk, fahişelik, kahpelik, sürtüklük"
  },
  {
    "x": "qehpî (m)",
    "y": " b. qehpeyî (m), qehpeyîye (m)"
  },
  {
    "x": "qehpîye (m)",
    "y": " b. qehpe (m)"
  },
  {
    "x": "qehr (n)",
    "y": " b. qehir (n)"
  },
  {
    "x": "qehreman, -e",
    "y": " yiğit, alp, kahraman"
  },
  {
    "x": "qehremanê (m)",
    "y": " b. qehremanîye (m)"
  },
  {
    "x": "qehremanênî (m)",
    "y": " yiğitlik, alplık, kahramanlık"
  },
  {
    "x": "qehremanî (m)",
    "y": " yiğitlik, alplık, kahramanlık"
  },
  {
    "x": "qehremanîye (m)",
    "y": " yiğitlik, alplık, kahramanlık"
  },
  {
    "x": "qehrîyaya (m)",
    "y": " b. qehirîyaya (m)"
  },
  {
    "x": "qehrîyaye (n)",
    "y": " b. qehirîyaye (n)"
  },
  {
    "x": "qehrîyayene",
    "y": " b. qehirîyayene"
  },
  {
    "x": "qehrîyayeyê (m)",
    "y": " b. qehirîyayeyî (m), qehirîyayeyîye (m)"
  },
  {
    "x": "qehrîyayeyênî (m)",
    "y": " b. qehirîyayeyênî (m)"
  },
  {
    "x": "qehrîyayeyî (m)",
    "y": " b. qehirîyayeyî (m), qehirîyayeyîye (m)"
  },
  {
    "x": "qehrîyayeyîye (m)",
    "y": " b. qehirîyayeyî (m), qehirîyayeyîye (m)"
  },
  {
    "x": "qehrîyayê (m)",
    "y": " b. qehirîyayîye (m)"
  },
  {
    "x": "qehrîyayîş (n)",
    "y": " b. qehirîyayîş (n)"
  },
  {
    "x": "qehrîyayîye (m)",
    "y": " b. qehirîyayîye (m)"
  },
  {
    "x": "qehrnayene",
    "y": " b. qehirnayene"
  },
  {
    "x": "qehrnayîş (n)",
    "y": " b. qehirnayîş (n)"
  },
  {
    "x": "qehrnayox, -e",
    "y": " b. qehirnayox, -e"
  },
  {
    "x": "qehwe (n)",
    "y": " 1)b. qewe (n)\r\n~2)b. qewexane (n)"
  },
  {
    "x": "qehwexane (n)",
    "y": " b. qewexane (n)"
  },
  {
    "x": "qehwexone (n)",
    "y": " b. qewexane (n)"
  },
  {
    "x": "qehwexûne (n)",
    "y": " b. qewexane (n)"
  },
  {
    "x": "qehwexûni (n)",
    "y": " b. qewexane (n)"
  },
  {
    "x": "qeîrî ...",
    "y": " b. xeyrê ..."
  },
  {
    "x": "qeja (n)",
    "y": " b. qeşa-I (n).buz"
  },
  {
    "x": "qeja bestene",
    "y": " b. qeşa bestene"
  },
  {
    "x": "qela-I (m)",
    "y": " karga"
  },
  {
    "x": "qela-II (m)",
    "y": " kalay "
  },
  {
    "x": "qela-III (n)",
    "y": " b. qele (n)"
  },
  {
    "x": "qela-IV (m)",
    "y": " b. kela (m)"
  },
  {
    "x": "qelacî-I (n)",
    "y": " b. qelaker, -e"
  },
  {
    "x": "qelaçî, -ye-II",
    "y": " b. qelaker, -e"
  },
  {
    "x": "qelaker, -e",
    "y": " kalaycı"
  },
  {
    "x": "qelancik, -e",
    "y": " b. qilancik, -e"
  },
  {
    "x": "qelanciki (m)",
    "y": " b. qilancike (m)"
  },
  {
    "x": "qelanqîz (n)",
    "y": " b. qelaqîze (m)"
  },
  {
    "x": "qelaqîze (m)",
    "y": " bir tür dönen tahterevalli"
  },
  {
    "x": "qelas (n)",
    "y": " kalas"
  },
  {
    "x": "qelaş (n)",
    "y": " yarık"
  },
  {
    "x": "qelişîyayene",
    "y": " yarılmak"
  },
  {
    "x": "qelişîyayîş (n)",
    "y": " yarılma"
  },
  {
    "x": "qelişnayene",
    "y": " yarmak"
  },
  {
    "x": "qelişnayîş (n)",
    "y": " yarma"
  },
  {
    "x": "qelaw, -e",
    "y": " b. qelew, -e"
  },
  {
    "x": "qelaw bîyayene",
    "y": " b. qelew bîyene"
  },
  {
    "x": "qelaycî, -ye",
    "y": " b. qelaker, -e"
  },
  {
    "x": "qelayçî, -ye",
    "y": " b. qelaker, -e"
  },
  {
    "x": "qelb-I (n)",
    "y": " kalp, yürek"
  },
  {
    "x": "qelb-II (n)",
    "y": " kusmuk [b. qeleb (n)]"
  },
  {
    "x": "qelbê ... ameyene",
    "y": " mide(si) bulanmak\r\n~Qelbê mi ame. (Midem bulandı.)"
  },
  {
    "x": "qelbê ... ardene",
    "y": " mide bulandırmak\r\n~Qelbê mi ard. (Midemi bulandırdı.)"
  },
  {
    "x": "qelbê ... hameyîne",
    "y": " b. qelbê ... ameyene"
  },
  {
    "x": "qelbê ... omeyene",
    "y": " b. qelbê ... ameyene"
  },
  {
    "x": "qelbê ... ûmeyene",
    "y": " b. qelbê ... ameyene"
  },
  {
    "x": "qelb, -i-III",
    "y": " b. qelp, -e"
  },
  {
    "x": "qelbameyîş (n)",
    "y": " bulantı"
  },
  {
    "x": "qelbapey",
    "y": " ters\r\n~Kêneke pirênê xo qelbapey xo ra dayo. (Kız entarisini ters giymiş.)"
  },
  {
    "x": "qelbdayîş (n)",
    "y": " b. qelbedayîş (n)"
  },
  {
    "x": "qelbe (m)",
    "y": " döven sürerken altta kalan dövülmemiş ekin saplarını üste çıkarmak için harman yığınını altüst etme işi "
  },
  {
    "x": "qelbedayîş (n)",
    "y": " döven sürerken altta kalan dövülmemiş ekin saplarını üste çıkarmak için harman yığınını altüst etme"
  },
  {
    "x": "qelbey",
    "y": " b. qelpîye (m)"
  },
  {
    "x": "qelbê (m)",
    "y": " b. qelpîye (m)"
  },
  {
    "x": "qelbê ... ameyîş",
    "y": " mide(si) bulanma"
  },
  {
    "x": "qelbê ... ardiş",
    "y": " mide bulandırma"
  },
  {
    "x": "qelbê ... omeyîş",
    "y": " b. qelbê ... ameyîş"
  },
  {
    "x": "qelbê ... ûmeyîş",
    "y": " b. qelbê ... ameyîş"
  },
  {
    "x": "qelbi-I (m)",
    "y": " b. qelbe (m)"
  },
  {
    "x": "qelbi-II (m)",
    "y": " b. qible (m)"
  },
  {
    "x": "qelbidayîş (n)",
    "y": " b. qelbedayîş (n)"
  },
  {
    "x": "qelbomeyîş (n)",
    "y": " b. qelbameyîş (n)"
  },
  {
    "x": "qelbûmeyîş (n)",
    "y": " b. qelbameyîş (n)"
  },
  {
    "x": "qeldapey",
    "y": " b. qelbapey"
  },
  {
    "x": "qeldapê",
    "y": " b. qelbapey"
  },
  {
    "x": "qeldapî (m, n)",
    "y": " b. qelibnaya (m)"
  },
  {
    "x": "qeldaya (m)",
    "y": " b. qelibnaya (m)"
  },
  {
    "x": "qeldaye (n)",
    "y": " b. qelibnaye (n)"
  },
  {
    "x": "qeldayene",
    "y": " b. qelibnayene"
  },
  {
    "x": "qeldayîş (n)",
    "y": " b. qelibnayîş (n)"
  },
  {
    "x": "qeldîyayene",
    "y": " b. qelibîyayene"
  },
  {
    "x": "qele (n)",
    "y": " kesek"
  },
  {
    "x": "qeleb (n)",
    "y": " kusmuk"
  },
  {
    "x": "qelebî ... ameyene",
    "y": " b. qelbê ... ameyene"
  },
  {
    "x": "qelebî ... ameyîş",
    "y": " b. qelbê ... ameyîş"
  },
  {
    "x": "qelebî ... ardene",
    "y": " b. qelbê ... ardene"
  },
  {
    "x": "qelebameyîş (n)",
    "y": " b. qelbameyîş (n)"
  },
  {
    "x": "qelebalix (n)",
    "y": " 1)gürültü\r\n~2) kalabalık, kalaba"
  },
  {
    "x": "qelebalix bîyene",
    "y": " kalabalıklaşmak"
  },
  {
    "x": "qelebalix kerdene",
    "y": " gürültü etmek"
  },
  {
    "x": "qelebalixbîyayîş (n)",
    "y": " kalabalıklaşma"
  },
  {
    "x": "qelebî ... ardiş",
    "y": " b. qelbê ... ardiş"
  },
  {
    "x": "qelebîyayene",
    "y": " b. qelibîyayene"
  },
  {
    "x": "qelebîyayîş (n)",
    "y": " b. qelibnayîş (n)"
  },
  {
    "x": "qelebnayene",
    "y": " b. qelibnayene"
  },
  {
    "x": "qelebnayîş (n)",
    "y": " b. qelibnayîş (n)"
  },
  {
    "x": "qeleçî (n)",
    "y": " b. qelaker, -e"
  },
  {
    "x": "qelem (m)",
    "y": " b. qeleme (m)"
  },
  {
    "x": "qeleme (m)",
    "y": " kalem"
  },
  {
    "x": "qelema boyaxî (m)",
    "y": " boya kalemi"
  },
  {
    "x": "qelema boyaxkerdişî (m)",
    "y": " b. qelema boyaxî (m)"
  },
  {
    "x": "qelema erjîjine (m)",
    "y": " kurşun kalem "
  },
  {
    "x": "qelema rengine (m)",
    "y": " renkli kalem"
  },
  {
    "x": "qelemêda sîyaye (m)",
    "y": " siyah bir kalem"
  },
  {
    "x": "qelemêka sîyaye (m)",
    "y": " siyah bir kalem"
  },
  {
    "x": "qelemi (m)",
    "y": " b. qeleme (m) "
  },
  {
    "x": "qelemtiraşe (m)",
    "y": " kalemtıraş"
  },
  {
    "x": "qelen (n)",
    "y": " b. qalind (n)-II"
  },
  {
    "x": "qelend (n)",
    "y": " b. qalind (n)-II"
  },
  {
    "x": "qeleşîyayene",
    "y": " b. qelişîyene"
  },
  {
    "x": "qeleşnayene",
    "y": " b. qelişnayene"
  },
  {
    "x": "qeleşyayîş (n)",
    "y": " b. qelişîyayîş (n)"
  },
  {
    "x": "qelev, -e",
    "y": " b. qelew, -e"
  },
  {
    "x": "qelevnayene",
    "y": " b. qelibnayene"
  },
  {
    "x": "qelevnayêne",
    "y": " b. qelibnayene"
  },
  {
    "x": "qelevnayîş (n)",
    "y": " b. qelibnayîş (n)"
  },
  {
    "x": "qelew, -e",
    "y": " 1)şişman\r\n~2)besili, semiz"
  },
  {
    "x": "qelew bîyene",
    "y": " şişmanlamak"
  },
  {
    "x": "qelew kerdene",
    "y": " şişmanlatmak"
  },
  {
    "x": "qelewîyayîş (n)",
    "y": " b. qelibnayîş (n)"
  },
  {
    "x": "qelewnayîş (n)",
    "y": " b. qelibnayîş (n)"
  },
  {
    "x": "qelênaya (m)",
    "y": " kızartılmış olan (yumurta vb. için), kızartma (yumurta vb. için), kavrulmuş olan (et vb. için)"
  },
  {
    "x": "qelênaye (n)",
    "y": " kızartılmış olan (yumurta vb. için), kızartma (yumurta vb. için), kavrulmuş olan (et vb. için)"
  },
  {
    "x": "qelênayene",
    "y": " kavurmak, kızartmak (yumurta vb. için)"
  },
  {
    "x": "qelênayîş (n)",
    "y": " kavurma, kızartma (yumurta vb. için)"
  },
  {
    "x": "qelênayîye (m)",
    "y": " kavrulmuş olan (et vb. için), kızartılmış olan (yumurta vb. için), kızartma (yumurta vb. için)"
  },
  {
    "x": "qelfa (m)",
    "y": " kalfa"
  },
  {
    "x": "qelfe-I (n)",
    "y": " kalfa"
  },
  {
    "x": "qelfe-II (n)",
    "y": " b. qefle (n)"
  },
  {
    "x": "qeli (n)",
    "y": " b. qele (n)"
  },
  {
    "x": "qelibîyayene",
    "y": " 1)çevrilmek, döndürülmek\r\n~2)devrilmek\r\n~3)yatmak\r\n~4)kusulmak"
  },
  {
    "x": "qelibnaya (m)",
    "y": " ters"
  },
  {
    "x": "qelibnaye (n)",
    "y": " ters\r\n~Mêrikî şelwarê xo qelibnaye pay kerdê. (Adam şalvarını ters [iç tarafı dışa gelecek biçimde] giymiş.)"
  },
  {
    "x": "qelibnayene",
    "y": " 1)çevirmek, döndürmek\r\n~2)devirmek\r\n~3)kusmak, istifrağ etmek"
  },
  {
    "x": "qelibnayîş (n)",
    "y": " 1)çevirme, döndürme\r\n~2)devirme\r\n~3)kusma, istifrağ etme"
  },
  {
    "x": "qelin (n)",
    "y": " b. qalind (n)"
  },
  {
    "x": "qelinaya (m)",
    "y": " b. qelênaya (m), qelênayîye (m)"
  },
  {
    "x": "qelinaye (n)",
    "y": " b. qelênaye (n)"
  },
  {
    "x": "qelinayene",
    "y": " b. qelênayene"
  },
  {
    "x": "qelinayîş (n)",
    "y": " b. qelênayîş (n)"
  },
  {
    "x": "qelind (n)",
    "y": " b. qalind (n)"
  },
  {
    "x": "qeliş (n)",
    "y": " yırtmaç"
  },
  {
    "x": "qelişin, -e",
    "y": " yırtmaçlı"
  },
  {
    "x": "qelişîyayene",
    "y": " b. qelişîyene"
  },
  {
    "x": "qelişîyayîş (n)",
    "y": " çatlama (deri vb. için), yarılma (deri vb. için)"
  },
  {
    "x": "qelişîyene",
    "y": " çatlamak (deri vb. için), yarılmak (deri vb. için)"
  },
  {
    "x": "qelişnayene",
    "y": " çatlatmak (deri vb. için), yarmak (deri vb. için)"
  },
  {
    "x": "qelişnayîne",
    "y": " b. qelişnayene"
  },
  {
    "x": "qelînaya (m)",
    "y": " b. qelênaya (m), qelênayîye (m)"
  },
  {
    "x": "qelînaye (n)",
    "y": " b. qelênaye (n)"
  },
  {
    "x": "qelînayene",
    "y": " b. qelênayene"
  },
  {
    "x": "qelînayîş (n)",
    "y": " b. qelênayîş (n)"
  },
  {
    "x": "qelîş (n)",
    "y": " b. qeliş (n)"
  },
  {
    "x": "qelm (n)",
    "y": " b. qalind (n)"
  },
  {
    "x": "qeln (n)",
    "y": " b. qalind (n)"
  },
  {
    "x": "qelow, -e",
    "y": " b. qelew, -e"
  },
  {
    "x": "qelp, -e",
    "y": " 1)kalp (para vb. için) geçersiz, geçersiz (para vb. için)\r\n~2)sahte\r\n~3)tembel, kalp (tembel kişi)"
  },
  {
    "x": "qelpey (m)",
    "y": " b. qelpîye (m)"
  },
  {
    "x": "qelpê (m)",
    "y": " b. qelpîye (m)"
  },
  {
    "x": "qelpî (m)",
    "y": " geçersizlik (para vb. için)"
  },
  {
    "x": "qelpîye (m)",
    "y": " 1)tembellik\r\n~2)geçersizlik (para vb. için)"
  },
  {
    "x": "qelqela (m)",
    "y": " dedikoducu"
  },
  {
    "x": "qelqele, -ye",
    "y": " dedikoducu"
  },
  {
    "x": "qelqelyayîne",
    "y": " b. qilqilîyayene"
  },
  {
    "x": "qelqelyayîş (n)",
    "y": " b. qilqilîyayîş (n)"
  },
  {
    "x": "qelsayîş",
    "y": " b. qelişîyayîş (n)"
  },
  {
    "x": "qelş (n)",
    "y": " b. qeliş (n)"
  },
  {
    "x": "qelun (m)",
    "y": " b. qelûne (m)"
  },
  {
    "x": "qelune (m)",
    "y": " b. qelûne (m)"
  },
  {
    "x": "qelûn (m)",
    "y": " b. qelûne (m)"
  },
  {
    "x": "qelûne (m)",
    "y": " pipoq"
  },
  {
    "x": "qelv (n)",
    "y": " b. qelb-I (n)"
  },
  {
    "x": "qelxan (n)",
    "y": " b. qalxan (n)"
  },
  {
    "x": "qemçe (m)",
    "y": " keçi kuyruğu"
  },
  {
    "x": "qemçik (m)",
    "y": " qemçike (m)"
  },
  {
    "x": "qemçike (m)",
    "y": " keçi kuyruğu, köpek kuyruğu\r\n~“Beluye da bizêke, dest eşt qemçike!” (Bir şey ikram ederek oyuna getirdi, tavladı.)"
  },
  {
    "x": "qemçî (m)",
    "y": " b. qamçî (m)"
  },
  {
    "x": "qemçî kerdene",
    "y": " b. qamçî kerdene"
  },
  {
    "x": "Qemere (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "Qemeri (m)",
    "y": " b. Qemere (m)"
  },
  {
    "x": "qemetîyaya (m)",
    "y": " b. qemitîyaya (m)"
  },
  {
    "x": "qemetîyaye (n)",
    "y": " b. qemitîyaye (n)"
  },
  {
    "x": "qemetîyayene",
    "y": " b. qemitîyayene"
  },
  {
    "x": "qemetîyayî, -ye",
    "y": " b. qemitîyayî, -ye"
  },
  {
    "x": "qemetîyayîş (n)",
    "y": " b. qemitîyayîş (n)"
  },
  {
    "x": "qemetnayene",
    "y": " b. qemitnayene"
  },
  {
    "x": "qemetnayîş (n)",
    "y": " b. qemitnayîş (n)"
  },
  {
    "x": "qemetnayox, -e",
    "y": " b. qemitnayox, -e"
  },
  {
    "x": "qemitîyaya (m)",
    "y": " yapışık"
  },
  {
    "x": "qemitîyaye (n)",
    "y": " yapışık"
  },
  {
    "x": "qemitîyayene",
    "y": " yapışmak"
  },
  {
    "x": "qemitîyayî, -ye",
    "y": " yapışık"
  },
  {
    "x": "qemitîyayîş (n)",
    "y": " yapışma"
  },
  {
    "x": "qemitnayene",
    "y": " 1)yapıştırmak\r\n~2)sıkıştırmak"
  },
  {
    "x": "qemitnayîş (n)",
    "y": " 1)yapıştırma\r\n~2)sıkıştırma"
  },
  {
    "x": "qemitnayox, -e",
    "y": " yapıştırıcı"
  },
  {
    "x": "qemîs (n)",
    "y": " gömlek, mintan\r\n~qemîso harmekilm/hingilekilm/polkilm/polkirr/qol (n): kısa kollu gömlek"
  },
  {
    "x": "qemîso harmekilm/hingilekilm/polkilm/polkirr/qol (n)",
    "y": " kısa kollu gömlek"
  },
  {
    "x": "qemt (m)",
    "y": " b. qemte (m)"
  },
  {
    "x": "qemte (m)",
    "y": " başörtü, başörtüsü, yazma"
  },
  {
    "x": "qemta kaxite (m)",
    "y": " yazma"
  },
  {
    "x": "qemta qaxitî (m)",
    "y": " b. qemta kaxite (m)"
  },
  {
    "x": "qemti (m)",
    "y": " b. qemte (m)"
  },
  {
    "x": "qen (m)",
    "y": " b. qine (m) "
  },
  {
    "x": "qenal (n)",
    "y": " b. kanal (n)"
  },
  {
    "x": "qenaste (n)",
    "y": " b. qineste (n)"
  },
  {
    "x": "qenca (m)",
    "y": " b. qanca (m)"
  },
  {
    "x": "qencele (n)",
    "y": " b. qirîncele (n) "
  },
  {
    "x": "qenda (n)",
    "y": " b. qinda (n)"
  },
  {
    "x": "qendayê (m)",
    "y": " b. qindayî (m), qindayîye (m)"
  },
  {
    "x": "qendayî (m)",
    "y": " b. qindayî (m), qindayîye (m)"
  },
  {
    "x": "qene (m)",
    "y": " b. qine (m) "
  },
  {
    "x": "qena dare (m)",
    "y": " b. qina dare (m)"
  },
  {
    "x": "qeneda (n)",
    "y": " b. qinda (n)"
  },
  {
    "x": "qenedayî (n)",
    "y": " b. qinda (n)"
  },
  {
    "x": "qenedayîye (m)",
    "y": " b. qindayî (m), qindayîye (m)"
  },
  {
    "x": "qenepa (m)",
    "y": " b. qenepe (n)"
  },
  {
    "x": "qenepe (n)",
    "y": " kanepe"
  },
  {
    "x": "qenepi (n)",
    "y": " b. qenepe (n)"
  },
  {
    "x": "qengiran, -e",
    "y": " b. qingiran, -e"
  },
  {
    "x": "qengirani (m)",
    "y": " b. qangirane (m)"
  },
  {
    "x": "qengiraney (m)",
    "y": " b. qingiranîye (m)"
  },
  {
    "x": "qengiranê (m)",
    "y": " b. qingiranîye (m)"
  },
  {
    "x": "qengiranîye (m)",
    "y": " b. qingiranîye (m)"
  },
  {
    "x": "qengiron, -i",
    "y": " b. qangiran, -e"
  },
  {
    "x": "qengironey (m)",
    "y": " b. qingiranîye (m)"
  },
  {
    "x": "qengironê (m)",
    "y": " b. qingiranîye (m)"
  },
  {
    "x": "qengirûn, -i-I",
    "y": " b. qingiran, -e"
  },
  {
    "x": "qengirûn, -i-II",
    "y": " b. qangiran, -e"
  },
  {
    "x": "qengirûney (m)",
    "y": " b. qingiranîye (m)"
  },
  {
    "x": "qengirûnê (m)",
    "y": " b. qingiranîye (m)"
  },
  {
    "x": "qeni (m)",
    "y": " b. qine (m)"
  },
  {
    "x": "qeni ra",
    "y": " b. qine ra"
  },
  {
    "x": "qent (m)",
    "y": " b. qemte (m)"
  },
  {
    "x": "qenter (n)",
    "y": " kantar"
  },
  {
    "x": "eştene qenter",
    "y": " kantarlamak, kantara vurmak, kantara çekmek"
  },
  {
    "x": "qenter estene",
    "y": " b. eştene qenter"
  },
  {
    "x": "qenterê destan (n)",
    "y": " el kantarı"
  },
  {
    "x": "qenterê desto (n)",
    "y": " b. qenterê destan (n)"
  },
  {
    "x": "qenterê deston (n)",
    "y": " b. qenterê destan (n)"
  },
  {
    "x": "qenterî destû (n)",
    "y": " b. qenterê destan (n)"
  },
  {
    "x": "qenterî destûn (n)",
    "y": " b. qenterê destan (n)"
  },
  {
    "x": "qenti (m)",
    "y": " b. qemte (m)"
  },
  {
    "x": "qepasîte (n)",
    "y": " b. kapasîte (n)"
  },
  {
    "x": "qepax (n)",
    "y": " b. qapax (n)"
  },
  {
    "x": "qeqebnayene",
    "y": " b. qaqibnayene"
  },
  {
    "x": "qeqebnayêne",
    "y": " b. qaqibnayene"
  },
  {
    "x": "qeqebnayîş (n)",
    "y": " b. qaqibnayîş (n)"
  },
  {
    "x": "qeqebo (n)",
    "y": " b. qaqibo (n)"
  },
  {
    "x": "qeqevnayene",
    "y": " b. qaqibnayene"
  },
  {
    "x": "qeqevnayêne",
    "y": " b. qaqibnayene"
  },
  {
    "x": "qeqevnayîş (n)",
    "y": " b. qaqibnayîş (n)"
  },
  {
    "x": "qeqevo (n)",
    "y": " b. qaqibo (n)"
  },
  {
    "x": "qeqibnayene",
    "y": " b. qaqibnayene"
  },
  {
    "x": "qeqibnayîş (n)",
    "y": " b. qaqibnayîş (n)"
  },
  {
    "x": "qeqibo (n)",
    "y": " b. qaqibo (n)"
  },
  {
    "x": "qeqivnayene",
    "y": " b. qaqibnayene"
  },
  {
    "x": "qeqivnayîş (n)",
    "y": " b. qaqibnayîş (n)"
  },
  {
    "x": "qeqivo (n)",
    "y": " b. qaqibo (n)"
  },
  {
    "x": "qequba (n)",
    "y": " b. qaqibo (n)"
  },
  {
    "x": "qequbnayene",
    "y": " b. qaqibnayene "
  },
  {
    "x": "qequbnayîş (n)",
    "y": " b. qaqibnayîş (n)"
  },
  {
    "x": "qequbo (n)",
    "y": " b. qaqibo (n)"
  },
  {
    "x": "qer, -e",
    "y": " kara, siyah "
  },
  {
    "x": "qerac (n)",
    "y": " 1)kıraç\r\n~2)b. qeraj-I (n)"
  },
  {
    "x": "qeraj-I (n)",
    "y": " yamaç"
  },
  {
    "x": "qeraj-II (n)",
    "y": " b. garaj (n)"
  },
  {
    "x": "qeranfîl (n)",
    "y": " b. qerenfîl (n)"
  },
  {
    "x": "qeraqole (m)",
    "y": " b. qereqol (n)"
  },
  {
    "x": "qerar (n)",
    "y": " karar"
  },
  {
    "x": "qerar dayene",
    "y": " karar vermek, karara bağlamak"
  },
  {
    "x": "qerar girewtene",
    "y": " karar almak"
  },
  {
    "x": "qerarê aremeyî (n)",
    "y": " arama kararı"
  },
  {
    "x": "qerarê aremî (n)",
    "y": " b. qerarê aremeyî (n)"
  },
  {
    "x": "qerarê encumenî (n)",
    "y": " encümen kararı"
  },
  {
    "x": "qerarê rocigêrayîşî (n)",
    "y": " arama kararı"
  },
  {
    "x": "qerarê sayekerdişî (n)",
    "y": " arama kararı"
  },
  {
    "x": "qerarê sehnayîşî (n)",
    "y": " arama kararı"
  },
  {
    "x": "qerarê tewqîfî (n)",
    "y": " tutuklama kararı, tevkif kararı"
  },
  {
    "x": "qerarê tewqîfî yo xîyabî (n)",
    "y": " gıyabi tutuklama kararı, gıyabi tevkif kararı"
  },
  {
    "x": "qerarê vindarnayîşê caardişî (n)",
    "y": " yürütmeyi durdurma kararı"
  },
  {
    "x": "qerardar, -e",
    "y": " kararlı"
  },
  {
    "x": "qerargehe (m)",
    "y": " karargâh "
  },
  {
    "x": "qerarname (n)",
    "y": " kararname"
  },
  {
    "x": "qerase (n)",
    "y": " kaldıraç (ağaçtan olanı), manivela"
  },
  {
    "x": "qeraş, -e",
    "y": " değirmenci"
  },
  {
    "x": "qerawati (m)",
    "y": " b. kravate (m)"
  },
  {
    "x": "qerawî (m)",
    "y": " kırağı"
  },
  {
    "x": "qerax (n)",
    "y": " kıyı, sahil"
  },
  {
    "x": "qerax kerdene",
    "y": " döven sürerken kenarlara kayan sapları kürek veya yabayla harmanın ortasına doğru kaydırmak"
  },
  {
    "x": "qeraxê çemî (n)",
    "y": " akarsu kıyısı, nehir kıyısı"
  },
  {
    "x": "qeraxê robarî (n)",
    "y": " akarsu kıyısı, nehir kıyısı"
  },
  {
    "x": "qeraxê royî (n)",
    "y": " akarsu kıyısı, nehir kıyısı"
  },
  {
    "x": "qerayîş (n)",
    "y": " b. qehirîyayîş (n)"
  },
  {
    "x": "qeraz (n)",
    "y": " b. qeraj-I (n)"
  },
  {
    "x": "qerbalix (n)",
    "y": " b. qelebalix (n)"
  },
  {
    "x": "qerbalix bîyayene",
    "y": " b. qelebalix bîyene"
  },
  {
    "x": "qerbalix kerdene",
    "y": " b. qelebalix kerdene"
  },
  {
    "x": "qerbalixbîyayîş (n)",
    "y": " b. qelebalixbîyayîş (n)"
  },
  {
    "x": "qerbaş (m)",
    "y": " b. qerwaşe (m)"
  },
  {
    "x": "qerbelek, -e",
    "y": " 1)alaca (iki veya daha çok renkli)\r\n~2)alaca (siyah-beyaz renkli)"
  },
  {
    "x": "Qere",
    "y": " koyun için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "qere çarşemeyê martî (n)",
    "y": " kocakarı soğuğu, berdel acuz (Rumi takvime göre mart ayının ilk çarşambası)"
  },
  {
    "x": "qere çarşemeyê zîpan (n)",
    "y": " kocakarı soğuğu, berdel acuz (Rumi takvime göre mart ayının ilk çarşambası)   "
  },
  {
    "x": "qere çarşemeyê zîpûn (n)",
    "y": " b. qere çarşemeyê zîpan (n)"
  },
  {
    "x": "qerebîne (n)",
    "y": " bir tüfek çeşidi"
  },
  {
    "x": "qerebafuni (m)",
    "y": " b. gramofon (n)"
  },
  {
    "x": "qerebalix (n)",
    "y": " b. qelebalix (n)"
  },
  {
    "x": "qerebalix bîyayene",
    "y": " b. qelebalix bîyene"
  },
  {
    "x": "qerebalix kerdene",
    "y": " b. qelebalix kerdene"
  },
  {
    "x": "qerebalixbîyayîş (n)",
    "y": " b. qelebalixbîyayîş (n)"
  },
  {
    "x": "qerec (n)",
    "y": " b. qeraj-I (n)"
  },
  {
    "x": "qeredonî (zh)",
    "y": " kadın şalvarı"
  },
  {
    "x": "qerefîyayene",
    "y": " b. qerifîyayene"
  },
  {
    "x": "qerefîyayîş (n)",
    "y": " b. qerifîyayîş (n)"
  },
  {
    "x": "qerefnayene",
    "y": " b. qerifnayene"
  },
  {
    "x": "qerefnayîne",
    "y": " b. qerifnayene"
  },
  {
    "x": "qerefnayîş (n)",
    "y": " b. qerifnayîş (n)"
  },
  {
    "x": "qereke (m)",
    "y": " bir üzüm çeşidi"
  },
  {
    "x": "qereki (m)",
    "y": " b. qereke (m)"
  },
  {
    "x": "qerekter (n)",
    "y": " b. karakter (n)"
  },
  {
    "x": "qereman, -e",
    "y": " b. qehreman, -e"
  },
  {
    "x": "qeremanîye (m)",
    "y": " b. qehremanîye (m)"
  },
  {
    "x": "qeremendû (n)",
    "y": " bir çeşit bez"
  },
  {
    "x": "qeremîyayîş (n)",
    "y": " b. qerimîyayîş (n)"
  },
  {
    "x": "qerenfîl (n)",
    "y": " karanfil (bir baharat)"
  },
  {
    "x": "qereqol (n)",
    "y": " karakol"
  },
  {
    "x": "qereqole (m)",
    "y": " b. qereqol (n)"
  },
  {
    "x": "qeresîyayene",
    "y": " b. qerisîyayene"
  },
  {
    "x": "qeresîyayîş (n)",
    "y": " b. qerisîyayîş (n)"
  },
  {
    "x": "qeresnayene",
    "y": " b. qerisnayene"
  },
  {
    "x": "qeresnayîş (n)",
    "y": " b. qerisnayîş (n)"
  },
  {
    "x": "qereste (n)",
    "y": " b. qerase (n)"
  },
  {
    "x": "qerewana (m)",
    "y": " karavana"
  },
  {
    "x": "qerewane (n)",
    "y": " b. qerewana (m) "
  },
  {
    "x": "qerewati (m)",
    "y": " b. kravate (m)"
  },
  {
    "x": "qerez (n)",
    "y": " b. xerez (n)"
  },
  {
    "x": "qerêyîş (n)",
    "y": " b. qehirîyayîş (n)"
  },
  {
    "x": "qerfinayîne",
    "y": " b. qerifnayene"
  },
  {
    "x": "qerfinayîş (n)",
    "y": " b. qerifnayîş (n)"
  },
  {
    "x": "qerfîyayîş (n)",
    "y": " b. qerifîyayîş (n)"
  },
  {
    "x": "qerfnayene",
    "y": " b. qerifnayene"
  },
  {
    "x": "qerfnayîş (n)",
    "y": " b. qerifnayîş (n)"
  },
  {
    "x": "qergundî (m)",
    "y": " gürültü patırtı, hengâme, şamata"
  },
  {
    "x": "Qeri (m)",
    "y": " b. Qere"
  },
  {
    "x": "qerifîyayene",
    "y": "1)koparılmak\r\n~2)akmak (yıldız akmak), kaymak (yıldız kaymak)"
  },
  {
    "x": "qerifîyayîş (n)",
    "y": " 1)koparılma\r\n~2)kayma (yıldız kayması)"
  },
  {
    "x": "qerifnayene",
    "y": " 1)koparmak\r\n~2)ürün toplamak (üzüm için)"
  },
  {
    "x": "qerifnayîş (n)",
    "y": " 1)koparma\r\n~2)ürün toplama (üzüm için)"
  },
  {
    "x": "qerimîyayîş (n)",
    "y": " kırıklık (vücutta), kırgınlık (vücutta)"
  },
  {
    "x": "qering",
    "y": " b. quling, -e"
  },
  {
    "x": "qerisîyayene",
    "y": " donmak (insan için)"
  },
  {
    "x": "qerisîyayîş (n)",
    "y": " donma (insan için)"
  },
  {
    "x": "qerisnayene",
    "y": " dondurmak (insan için)"
  },
  {
    "x": "qerisnayîş (n)",
    "y": " dondurma (insan için)"
  },
  {
    "x": "qerî (m)",
    "y": " bir üzüm çeşidi"
  },
  {
    "x": "qermeçîyaya (m)",
    "y": " b. qirmiçîyaya (m)"
  },
  {
    "x": "qermeçîyaye (n)",
    "y": " b. qirmiçîyaye (n)"
  },
  {
    "x": "qermeçîyayene",
    "y": " b. qirmiçîyayene"
  },
  {
    "x": "qermeçîyayê (m)",
    "y": " b. qirmiçîyayîye (m)"
  },
  {
    "x": "qermeçîyayîye (m)",
    "y": " b. qirmiçîyayîye (m)"
  },
  {
    "x": "qermeçuk, -e",
    "y": " b. qirmiçik, -e"
  },
  {
    "x": "qermeçukin, -e",
    "y": " b. qirmiçikin, -e"
  },
  {
    "x": "qermeçukinên (m)",
    "y": " b. qirmiçikinî (m)"
  },
  {
    "x": "qermeçukinênî (m)",
    "y": " b. qirmiçikinî (m)"
  },
  {
    "x": "qermiç (n)",
    "y": " b. qirmiç (n)"
  },
  {
    "x": "qermiçê (m)",
    "y": " b. qirmiçîye (m)"
  },
  {
    "x": "qermiçênî (m)",
    "y": " b. qirmiçênî (m)"
  },
  {
    "x": "qermiçik, -e",
    "y": " b. qirmiçik, -e"
  },
  {
    "x": "qermiçikê (m)",
    "y": " b. qirmiçikîye (m)"
  },
  {
    "x": "qermiçikênî (m)",
    "y": " b. qirmiçikênî (m)"
  },
  {
    "x": "qermiçikin, -e",
    "y": " b. qirmiçikin, -e"
  },
  {
    "x": "qermiçikinî (m)",
    "y": " b. qirmiçikinî (m)"
  },
  {
    "x": "qermiçikinîye (m)",
    "y": " b. qirmiçikinîye (m)"
  },
  {
    "x": "qermiçikîyayene",
    "y": " b. qirmiçikîyayene"
  },
  {
    "x": "qermiçikîyayîş (n)",
    "y": " b. qirmiçikîyayîş (n)"
  },
  {
    "x": "qermiçikîye (m)",
    "y": " b. qirmiçikîye (m)"
  },
  {
    "x": "qermiçîyaya (m)",
    "y": " b. qirmiçîyaya (m)"
  },
  {
    "x": "qermiçîyaye (n)",
    "y": " b. qirmiçîyaye (n)"
  },
  {
    "x": "qermiçîyayene",
    "y": " b. qirmiçîyayene"
  },
  {
    "x": "qermiçîyayeyî (m)",
    "y": " b. qirmiçîyayeyî (m)"
  },
  {
    "x": "qermiçîyayê (m)",
    "y": " b. qirmiçîyayîye (m)"
  },
  {
    "x": "qermiçîyayênî (m)",
    "y": " b. qirmiçîyayênî (m)"
  },
  {
    "x": "qermiçîyayîye (m)",
    "y": " b. qirmiçîyayîye (m)"
  },
  {
    "x": "qermiçîye (m)",
    "y": " b. qirmiçîye (m)"
  },
  {
    "x": "qermiçnaya (m)",
    "y": " b. qirmiçnaya (m)"
  },
  {
    "x": "qermiçnaye (n)",
    "y": " b. qirmiçnaye (n)"
  },
  {
    "x": "qermiçnayene",
    "y": " b. qirmiçnayene"
  },
  {
    "x": "qermiçnayeni",
    "y": " b. qirmiçnayene"
  },
  {
    "x": "qermiçnayeyî (m)",
    "y": " b. qirmiçnayeyî (m)"
  },
  {
    "x": "qermiçnayê (m)",
    "y": " b. qirmiçnayîye (m)"
  },
  {
    "x": "qermiçnayênî (m)",
    "y": " b. qirmiçnayênî (m)"
  },
  {
    "x": "qermiçnayîş (n)",
    "y": " b. qirmiçnayîş (n)"
  },
  {
    "x": "qermiçnayîye (m)",
    "y": " b. qirmiçnayîye (m)"
  },
  {
    "x": "qermoçîyayene",
    "y": " b. qirmiçîyayene"
  },
  {
    "x": "qernefîl (n)",
    "y": " b. qerenfîl (n)"
  },
  {
    "x": "Qero (n)",
    "y": " köpek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "qerpal (n)",
    "y": " alık (eskimiş giysi), eski püskü giysi"
  },
  {
    "x": "qerpalin, -e",
    "y": " eski püskü giysili"
  },
  {
    "x": "qerpelase (m)",
    "y": " karga"
  },
  {
    "x": "qerpilas (m)",
    "y": " b. qerpelase (m)"
  },
  {
    "x": "qerpuz (m)",
    "y": " b. qerpuze (m)"
  },
  {
    "x": "qerpuze (m)",
    "y": " karpuz"
  },
  {
    "x": "qerpuzroş, -e",
    "y": " karpuzcu"
  },
  {
    "x": "qerpûz (m)",
    "y": " b. qerpuze (m)"
  },
  {
    "x": "qerqatî (m)",
    "y": " yük hayvanıyla taş vb. şeyleri taşımak için kullanılan ve semerin iki tarafına sarkıtılan ağaç dalları veya tahtadan yapılma araç "
  },
  {
    "x": "qerqele (n)",
    "y": " iskelet"
  },
  {
    "x": "qerqeleyin, -e",
    "y": " iskeletli"
  },
  {
    "x": "qerqelyayîne",
    "y": " b. qilqilîyayene"
  },
  {
    "x": "qerqelyayîş (n)",
    "y": " b. qilqilîyayîş (n)"
  },
  {
    "x": "qerqeri (n)",
    "y": " b. qerqele (n)"
  },
  {
    "x": "qerqesun (n)",
    "y": " b. qerşune (m)"
  },
  {
    "x": "qerqesûne (m)",
    "y": " b. qerşune (m)"
  },
  {
    "x": "qerqeşun (n)",
    "y": " b. qerşune (m)"
  },
  {
    "x": "qerqeşune (m)",
    "y": " b. qerşune (m)"
  },
  {
    "x": "qerqilyayîne",
    "y": " b. qilqilîyayene"
  },
  {
    "x": "qerqilyayîş (n)",
    "y": " b. qilqilîyayîş (n)"
  },
  {
    "x": "qerqirîyayîne",
    "y": " b. qilqilîyayene"
  },
  {
    "x": "qerqirîyayîş (n)",
    "y": " b. qilqilîyayîş (n)"
  },
  {
    "x": "qerqot (n)",
    "y": " iskelet"
  },
  {
    "x": "qerqotin, -e",
    "y": " iskeletli"
  },
  {
    "x": "qerquet (n)",
    "y": " b. qerqot (n)"
  },
  {
    "x": "qerqurşin (n)",
    "y": " b. qerşune (m)"
  },
  {
    "x": "qerquşin (n)",
    "y": " b. qerşune (m)"
  },
  {
    "x": "qerqut (n)",
    "y": " b. qerqot (n)"
  },
  {
    "x": "qerraj (n)",
    "y": " b. qeraj-I (n)"
  },
  {
    "x": "qerraz (n)",
    "y": " b. qeraj-I (n)"
  },
  {
    "x": "qerse dekewtene",
    "y": " donmak (insan için)"
  },
  {
    "x": "qerse girewtene",
    "y": " donmak (insan için)"
  },
  {
    "x": "qerse girotene",
    "y": " b. qerse girewtene"
  },
  {
    "x": "qerse guretene",
    "y": " b. qerse girewtene"
  },
  {
    "x": "qersedekewtiş (n)",
    "y": " donma (insan için)"
  },
  {
    "x": "qersegirewtiş (n)",
    "y": " donma (insan için)"
  },
  {
    "x": "qersegirotiş (n)",
    "y": " b. qersegirewtiş (n)"
  },
  {
    "x": "qerseguretiş (n)",
    "y": " b. qersegirewtiş (n)"
  },
  {
    "x": "qersun (m)",
    "y": " b. qerşune (m)"
  },
  {
    "x": "qersune (m)",
    "y": " b. qerşune (m)"
  },
  {
    "x": "qersûn (n)",
    "y": " b. qerşune (m)"
  },
  {
    "x": "qersûne (m)",
    "y": " b. qerşune (m)"
  },
  {
    "x": "qerşun (n)",
    "y": " b. qerşune (m)"
  },
  {
    "x": "qerşune (m)",
    "y": " mermi, gülle, kurşun"
  },
  {
    "x": "qert, -e-I",
    "y": " kart (gençliği ve körpeliği kalmamış olan)"
  },
  {
    "x": "qert-II (n)",
    "y": " b. karte (m)"
  },
  {
    "x": "qertal (n)",
    "y": " kartal"
  },
  {
    "x": "qerte (m)",
    "y": " b. karte (m)"
  },
  {
    "x": "qertel (n)",
    "y": " b. qertal (n)"
  },
  {
    "x": "qertêl (n)",
    "y": " b. qertal (n)"
  },
  {
    "x": "qerti (m)",
    "y": " b. karte (m)"
  },
  {
    "x": "qertîme (n)",
    "y": " b. qetrîbe (n)"
  },
  {
    "x": "qertîş (n)",
    "y": " aşırı kir"
  },
  {
    "x": "qertîşin, -e",
    "y": " aşırı kirli"
  },
  {
    "x": "qerwaş (m)",
    "y": " b. qerwaşe (m)"
  },
  {
    "x": "qerwaşe (m)",
    "y": " kadın hizmetçi, cariye, halayık"
  },
  {
    "x": "qerwaşîye (m)",
    "y": " cariyelik, halayıklık"
  },
  {
    "x": "qerxin (m)",
    "y": " b. qerxine (m)"
  },
  {
    "x": "qerxine (m)",
    "y": " büyük çömlek"
  },
  {
    "x": "qerzax (n)",
    "y": " kuzgun"
  },
  {
    "x": "qes (m)",
    "y": " b. qeysî-II (zh)"
  },
  {
    "x": "qesa (m)",
    "y": " b. qisa (m)"
  },
  {
    "x": "qesa girçikine (m)",
    "y": " b. qisaya girçikine (m)"
  },
  {
    "x": "qesa mi cemat ra teber",
    "y": " qisaya mi cemat ra teber"
  },
  {
    "x": "qesaya ... birnayene",
    "y": " b. qesaya ... birnayene"
  },
  {
    "x": "qesê kerdene",
    "y": " b. qisey kerdene"
  },
  {
    "x": "qesab, -e",
    "y": " kasap"
  },
  {
    "x": "qesaba (m)",
    "y": " b. qeseba (m) "
  },
  {
    "x": "qesar",
    "y": " kastarcı (kastar işini yapan)"
  },
  {
    "x": "qesav, -e",
    "y": " b. qesab, -e"
  },
  {
    "x": "qesava (m)",
    "y": " b. qeseba (m)"
  },
  {
    "x": "qesawet (n)",
    "y": " 1)endişe, kaygı, tasa, telaş\r\n~2)üzüntü, üzünç"
  },
  {
    "x": "qesawet kerdene",
    "y": " endişelenmek, endişe etmek, kaygılanmak, tasalanmak"
  },
  {
    "x": "qesawetdayox, -e",
    "y": " üzüntülü (üzen durum veya olay), üzücü (durum veya olay)"
  },
  {
    "x": "qesawetin, -e",
    "y": " üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü"
  },
  {
    "x": "qesawetinê (m)",
    "y": " b. qesawetinî (m), qesawetinîye (m)"
  },
  {
    "x": "qesawetinî (m)",
    "y": " üzüntülülük"
  },
  {
    "x": "qesawetinîye (m)",
    "y": " üzüntülülük"
  },
  {
    "x": "qesawetkerdiş (n)",
    "y": " endişelenme, kaygılanma, tasalanma"
  },
  {
    "x": "qesba (m)",
    "y": " b. kezebe (m), qeseba (m) "
  },
  {
    "x": "qesba sipî (m)",
    "y": " b. qesebaya sipîye (m)"
  },
  {
    "x": "qesba sîyay (m)",
    "y": " b. qesebaya sîyaye (m) "
  },
  {
    "x": "qesbapêt, -i",
    "y": " b. qesebapêt, -e"
  },
  {
    "x": "qesbapêtey (m)",
    "y": " qesebapêtî (m)"
  },
  {
    "x": "qese (n)",
    "y": " b. qisa (m)"
  },
  {
    "x": "qese kerdene",
    "y": " b. qisey kerdene"
  },
  {
    "x": "qesey kerdene",
    "y": " b. qisey kerdene"
  },
  {
    "x": "qeseyo qirçikin (n)",
    "y": " b. qisaya girçikine (m)"
  },
  {
    "x": "qesê mi cemat ra teber",
    "y": " b. qisaya mi cemat ra teber"
  },
  {
    "x": "qesê kerdene",
    "y": " b. qisey kerdene"
  },
  {
    "x": "qesêy kerdene",
    "y": " b. qisey kerdene"
  },
  {
    "x": "qeseba (m)",
    "y": " karaciğer"
  },
  {
    "x": "qeseba çizayene",
    "y": " çok acımak"
  },
  {
    "x": "qeseba ... rê veşayene",
    "y": " çok acımak"
  },
  {
    "x": "qeseba ... rêveşayîş",
    "y": " çok acıma"
  },
  {
    "x": "qeseba veşayene",
    "y": " çok acımak"
  },
  {
    "x": "qesebaya sipîye (m)",
    "y": " akciğer"
  },
  {
    "x": "qesebaya sîyaye (m)",
    "y": " karaciğer"
  },
  {
    "x": "qesebaçizayîş (n)",
    "y": " çok acıma"
  },
  {
    "x": "qesebapêt, -e",
    "y": " cesaretli, cesur, yürekli"
  },
  {
    "x": "qesebapêt bîyene",
    "y": " cesaretlenmek"
  },
  {
    "x": "qesebapêtbîyayîş (n)",
    "y": " cesaretlenme"
  },
  {
    "x": "qesebapêtî (m)",
    "y": " cesaretlilik, cesurluk, yüreklilik"
  },
  {
    "x": "qesebat (n)",
    "y": " b. qesawet (n)"
  },
  {
    "x": "qesebaveşayîş (n)",
    "y": " çok acıma"
  },
  {
    "x": "qesele (n)",
    "y": " b. qisle (n)"
  },
  {
    "x": "Qesem bi Homay",
    "y": " b. Qesem bi Homayî"
  },
  {
    "x": "Qesem bi Homayî",
    "y": " Allah'a yemin ederim ki"
  },
  {
    "x": "Qesem bi Hûmay",
    "y": " b. Qesem bi Homayî"
  },
  {
    "x": "qeseva (m)",
    "y": " b. qeseba (m)"
  },
  {
    "x": "qesewa (m)",
    "y": " b. qeseba (m)"
  },
  {
    "x": "qeseyayene",
    "y": " b. qesîyayene"
  },
  {
    "x": "qeseyayîş (n)",
    "y": " b. qesîyayîş (n)"
  },
  {
    "x": "qeseyîş (n)",
    "y": " b. qesîyayîş (n)"
  },
  {
    "x": "qeseykerdox, -e",
    "y": " b. qiseykerdox, -e"
  },
  {
    "x": "qesêkerdiş (n)",
    "y": " b. qiseykerdiş (n)"
  },
  {
    "x": "qesi (n)",
    "y": " b. qisa (m)"
  },
  {
    "x": "qesi kerdene",
    "y": " b. qisey kerdene"
  },
  {
    "x": "qesiba (m)",
    "y": " b. qeseba (m)"
  },
  {
    "x": "qesî (n)",
    "y": " b. qisa (m)"
  },
  {
    "x": "qesî kerdene",
    "y": " b. qisey kerdene"
  },
  {
    "x": "qesîda (m)",
    "y": " b. qesîde (m)"
  },
  {
    "x": "qesîde (m)",
    "y": " kaside "
  },
  {
    "x": "qesîl (m)",
    "y": " b. hesîre (m)"
  },
  {
    "x": "qesîyayene",
    "y": " 1)kesilmek (makas vb. ile), kırkılmak, kırpılmak\r\n~2)kesilmek (süt vb. için)"
  },
  {
    "x": "qesîyayîş (n)",
    "y": " 1)kesilme (makas vb. ile), kırkılma, kırpılma\r\n~2)kesilme (süt vb. için)\r\n~3)kırıklık (vücutta), kırgınlık (vücutta)"
  },
  {
    "x": "qesîye (m)",
    "y": " b. qisa (m)"
  },
  {
    "x": "qesmir, -e",
    "y": " b. qeşmer, -e"
  },
  {
    "x": "qesmirenî (m)",
    "y": " b. qeşmerîye (m)"
  },
  {
    "x": "qesmirîye (m)",
    "y": " b. qeşmerîye (m)"
  },
  {
    "x": "qesnayene",
    "y": " 1)(makas vb. ile) kesmek, kırkmak, kırpmak\r\n~2)tıraş etmek"
  },
  {
    "x": "qesnayîşê gijikî",
    "y": " saç tıraşı"
  },
  {
    "x": "qesnayîşê porî",
    "y": " saç tıraşı"
  },
  {
    "x": "qesnayine",
    "y": " b. qesnayene"
  },
  {
    "x": "qesnayîş (n)",
    "y": " 1)(makas vb. ile) kesme, kırkma, kırpma\r\n~2)tıraş etme"
  },
  {
    "x": "qespa (m)",
    "y": " b. kezebe (m), qeseba (m) "
  },
  {
    "x": "qespapêt, -i",
    "y": " b. qesebapêt, -e"
  },
  {
    "x": "qespapêtey (m)",
    "y": " qesebapêtî (m)"
  },
  {
    "x": "qest (n)",
    "y": " parça (buz vb. için)"
  },
  {
    "x": "qesta (zr)",
    "y": " 1)güya\r\n~2)b. qestî"
  },
  {
    "x": "qestikên, -e",
    "y": " sahte"
  },
  {
    "x": "qestikêni (m)",
    "y": " b. qestikêne (m)"
  },
  {
    "x": "qestikî (zr)",
    "y": " güya"
  },
  {
    "x": "qestiko",
    "y": " b. qestîka"
  },
  {
    "x": "qestî-I (m)",
    "y": " şaka"
  },
  {
    "x": "qestî kerdene",
    "y": " şaka yapmak"
  },
  {
    "x": "qestî-II",
    "y": " 1)mahsus\r\n~2)şakadan\r\n~3)yalancıktan, yalandan"
  },
  {
    "x": "qestîka",
    "y": " 1)mahsus\r\n~2)şakadan\r\n~3)yalancıktan, yalandan"
  },
  {
    "x": "qestîkerdiş (n)",
    "y": " şaka yapma"
  },
  {
    "x": "qestîn a",
    "y": " şakadan"
  },
  {
    "x": "qestînek a",
    "y": " şakadan"
  },
  {
    "x": "qesto",
    "y": " b. qestî"
  },
  {
    "x": "qeş (n)",
    "y": " köpük"
  },
  {
    "x": "qeşa-I (n)",
    "y": " buz"
  },
  {
    "x": "qeşa bestene",
    "y": " buz bağlamak, buz tutmak, buzlanmak"
  },
  {
    "x": "qeşa bîyene",
    "y": " buzlaşmak"
  },
  {
    "x": "qeşa girewtene",
    "y": " donmak"
  },
  {
    "x": "qeşa girê dayene",
    "y": " buz bağlamak, buz tutmak, buzlanmak"
  },
  {
    "x": "qeşa girotene",
    "y": " b. qeşa girewtene"
  },
  {
    "x": "qeşa girowtene",
    "y": " b. qeşa girewtene"
  },
  {
    "x": "qeşa-II (n)",
    "y": " susuz yöre"
  },
  {
    "x": "qeşabestiş (n)",
    "y": " buzlanma"
  },
  {
    "x": "qeşabîyayîş (n)",
    "y": " buzlaşma "
  },
  {
    "x": "qeşabu (n)",
    "y": " b. qeşawî (n)"
  },
  {
    "x": "qeşabun (n)",
    "y": " b. qeşawî (n)"
  },
  {
    "x": "qeşagirewtiş (n)",
    "y": " donma"
  },
  {
    "x": "qeşagirêdayîş (n)",
    "y": " buzlanma"
  },
  {
    "x": "qeşagirotiş (n)",
    "y": " b. qeşagirewtiş (n)"
  },
  {
    "x": "qeşagirowtiş (n)",
    "y": " b. qeşagirewtiş (n)"
  },
  {
    "x": "qeşaguretiş (n)",
    "y": " b. qeşagirewtiş (n)"
  },
  {
    "x": "qeşaşit (n)",
    "y": " (yenen) dondurma \r\n~Ti wazenî qeşaşit biwerî? (Dondurma yemek ister misin?)"
  },
  {
    "x": "qeşaşitkar, -e",
    "y": " dondurmacı"
  },
  {
    "x": "qeşaşitroş, -e",
    "y": " dondurmacı"
  },
  {
    "x": "qeşawe (n)",
    "y": " b. qeşawî (n)"
  },
  {
    "x": "qeşawin (n)",
    "y": " b. qeşawî (n)"
  },
  {
    "x": "qeşawî (n)",
    "y": " kaşağı"
  },
  {
    "x": "qeşebu (n)",
    "y": " b. qeşawî (n)"
  },
  {
    "x": "qeşebun (n)",
    "y": " b. qeşawî (n)"
  },
  {
    "x": "qeşebû (n)",
    "y": " b. qeşawî (n)"
  },
  {
    "x": "qeşerîyayene",
    "y": " b. qeşirîyayene"
  },
  {
    "x": "qeşerîyayîş (n)",
    "y": " b. qeşirîyayîş (n)"
  },
  {
    "x": "qeşerîyene",
    "y": " b. qeşirîyayene"
  },
  {
    "x": "qeşernayene",
    "y": " b. qeşirnayene"
  },
  {
    "x": "qeşeryayîş (n)",
    "y": " b. qeşirîyayîş (n)"
  },
  {
    "x": "qeşê kerdene",
    "y": " b. qisey kerdene"
  },
  {
    "x": "qeşin, -e",
    "y": " köpüklü"
  },
  {
    "x": "qeşini (m)",
    "y": " b. qeşine (m)"
  },
  {
    "x": "qeşirîyayene",
    "y": " 1)kabuğu soyulmak\r\n~2)çatlamak (deri vb. için), yarılmak (deri vb. için)"
  },
  {
    "x": "qeşirîyayîş (n)",
    "y": " 1)kabuğu soyulma\r\n~2)çatlama (deri vb. için), yarılma"
  },
  {
    "x": "qeşirnayene",
    "y": " 1)kabuğunu soymak\r\n~2)çatlatmak (deri vb. için), yarmak (deri vb. için)"
  },
  {
    "x": "qeşî kerdene",
    "y": " b. qisey kerdene"
  },
  {
    "x": "qeşîyayene",
    "y": " b. qesîyayene"
  },
  {
    "x": "qeşîyayêne",
    "y": " b. qesîyayene"
  },
  {
    "x": "qeşîyayîş (n)",
    "y": " b. qesîyayîş (n)"
  },
  {
    "x": "qeşmer, -e-I",
    "y": " 1)maskara, soytarı\r\n~2)ciddiyetsiz, gayriciddi"
  },
  {
    "x": "qeşmereya xo ... kerdene",
    "y": " b. qeşmerîya xo bi ... kerdene"
  },
  {
    "x": "qeşmer-II (n)",
    "y": " bir çeşit kumaş"
  },
  {
    "x": "qeşmerî (m)",
    "y": " maskaralık, soytarılık"
  },
  {
    "x": "qeşmerîya xo bi ... kerdene",
    "y": " dalga geçmek\r\n~Qeşmerîya xo bi xortî meke! (Delikanlıyla dalga geçme!)"
  },
  {
    "x": "qeşmerîye (m)",
    "y": " maskaralık, soytarılık"
  },
  {
    "x": "qet-I",
    "y": " asla, hiçbir zaman, hiç, katiyen"
  },
  {
    "x": "qet çîke",
    "y": " hiçbir şey"
  },
  {
    "x": "qet çîkik",
    "y": " b. qet çîke"
  },
  {
    "x": "qet çîyê",
    "y": " hiçbir şey"
  },
  {
    "x": "qet finê",
    "y": " asla, hiçbir zaman, hiç, katiyen"
  },
  {
    "x": "qet ke ney",
    "y": " hiç olmazsa, hiç değilse, bari\r\n~Qet ke ney wa kitabanê mi bido. (Hiç olmazsa kitaplarımı versin.)"
  },
  {
    "x": "qet ke nê",
    "y": " b. qet ke ney"
  },
  {
    "x": "qet ke nêbo",
    "y": " b. qet ke ney"
  },
  {
    "x": "qet mebo",
    "y": " b. qet nêbo"
  },
  {
    "x": "qet neke",
    "y": " hiç olmazsa, hiç değilse, bari"
  },
  {
    "x": "qet nê",
    "y": " b. qet nêbo"
  },
  {
    "x": "qet nêbo",
    "y": " hiç olmazsa, hiç değilse, bari"
  },
  {
    "x": "qet nibû",
    "y": " b. qet nêbo"
  },
  {
    "x": "qet reyê",
    "y": " asla, hiçbir zaman, hiç, katiyen"
  },
  {
    "x": "qet teba",
    "y": " hiçbir şey"
  },
  {
    "x": "qet tiwa",
    "y": " b. qet teba"
  },
  {
    "x": "qet yew",
    "y": " hiçbir, tek"
  },
  {
    "x": "qet yew çî",
    "y": " hiçbir şey"
  },
  {
    "x": "qet-II",
    "y": " tek"
  },
  {
    "x": "qet-III (n)",
    "y": " parça"
  },
  {
    "x": "qet bîyene",
    "y": " parçalanmak"
  },
  {
    "x": "qet kerdene",
    "y": " parçalamak"
  },
  {
    "x": "qetaf (n)",
    "y": " b. qetîfe (n)"
  },
  {
    "x": "qetane",
    "y": " b. qetîyen"
  },
  {
    "x": "qetbîyayîş (n)",
    "y": " parçalanma"
  },
  {
    "x": "qete (n)",
    "y": " b. qethe (n)"
  },
  {
    "x": "qeten",
    "y": " b. qetîyen"
  },
  {
    "x": "Qeter (n)",
    "y": " Katar"
  },
  {
    "x": "qeterehm, -e",
    "y": " duygusuz, hissiz; katı yürekli"
  },
  {
    "x": "qeterehmênî (m)",
    "y": " duygusuzluk, hissizlik, katı yüreklilik"
  },
  {
    "x": "qeterehmî (m)",
    "y": " duygusuzluk, hissizlik, katı yüreklilik"
  },
  {
    "x": "qeterehmîye (m)",
    "y": " duygusuzluk, hissizlik, katı yüreklilik"
  },
  {
    "x": "qetfe (n)",
    "y": " b. qetîfe (n)"
  },
  {
    "x": "qethane",
    "y": " b. qetîyen"
  },
  {
    "x": "qethanî",
    "y": " b. qetîyen"
  },
  {
    "x": "qethe (n)",
    "y": " büyük bıçkı (iki kişi tarafından kullanılan)"
  },
  {
    "x": "qethen",
    "y": " b. qetîyen"
  },
  {
    "x": "qethî",
    "y": " b. qetî, -ye"
  },
  {
    "x": "qethîna",
    "y": " b. qetîyen"
  },
  {
    "x": "qetife (n)",
    "y": " b. qetîfe (n)"
  },
  {
    "x": "qetil (n)",
    "y": " cinayet, qetil"
  },
  {
    "x": "qetil kerdene",
    "y": " cinayet işlemek "
  },
  {
    "x": "qetilfe (n)",
    "y": " b. qetrîbe (n)"
  },
  {
    "x": "qetilkar, -e",
    "y": " katil"
  },
  {
    "x": "qetilpe (n)",
    "y": " b. qetrîbe (n)"
  },
  {
    "x": "qetilpi (n)",
    "y": " b. qetrîbe (n)"
  },
  {
    "x": "qetirbe (n)",
    "y": " b. qetrîbe (n)"
  },
  {
    "x": "qetî, -ye",
    "y": " kesin"
  },
  {
    "x": "qetî bîyene",
    "y": " kesinleşmek"
  },
  {
    "x": "qetî kerdene",
    "y": " kesinleştirmek"
  },
  {
    "x": "qetîbe (n)",
    "y": " b. qetîfe (n)"
  },
  {
    "x": "qetîbîyaya (m)",
    "y": " kesinleşmiş olan"
  },
  {
    "x": "qetîbîyaye (n)",
    "y": " kesinleşmiş olan"
  },
  {
    "x": "qetîbîyayîş (n)",
    "y": " kesinleşme "
  },
  {
    "x": "qetîbîyayîye (m)",
    "y": " kesinleşmiş olan"
  },
  {
    "x": "qetîfe (n)",
    "y": " saban okunun saban zıvanasından çıkmasını engellemeye yarayan küçük parça\r\n~“Luye va: Ez gêna qet û qet û qetîfe, ti bigîre sirsî û alete û nîre.”"
  },
  {
    "x": "qetîkerdiş (n)",
    "y": " kesinleştirme "
  },
  {
    "x": "qetîlfe (n)",
    "y": " b. qetrîbe (n)"
  },
  {
    "x": "qetîlpe (n)",
    "y": " b. qetrîbe (n)"
  },
  {
    "x": "qetîyan",
    "y": " b. qetîyen"
  },
  {
    "x": "qetîyen",
    "y": " asla, hiçbir zaman, hiç, katiyen"
  },
  {
    "x": "qetîyet (n)",
    "y": " kesinlik"
  },
  {
    "x": "qetîyîye (m)",
    "y": " kesinlik"
  },
  {
    "x": "qetkerdiş (n)",
    "y": " parçalama"
  },
  {
    "x": "qetl (n)",
    "y": " b. qetil (n) "
  },
  {
    "x": "qetl kerdene",
    "y": " b. qetil kerdene"
  },
  {
    "x": "qetlibe (n)",
    "y": " b. qetrîbe (n)"
  },
  {
    "x": "qetre (m)",
    "y": " sağanak"
  },
  {
    "x": "qetrehm, -e",
    "y": " b. qeterehm, -e"
  },
  {
    "x": "qetrepoz (n)",
    "y": " gulyabani"
  },
  {
    "x": "qetri (m)",
    "y": " b. qetre (m)"
  },
  {
    "x": "qetrîbe (n)",
    "y": " saban okunun saban zıvanasından çıkmasını engellemeye yarayan küçük parça"
  },
  {
    "x": "qetûn (n)",
    "y": " b. qeytan-I (n)"
  },
  {
    "x": "qeul (n)",
    "y": " b. qebul (n)"
  },
  {
    "x": "qeul kerdene",
    "y": " b. qebul kerdene"
  },
  {
    "x": "qeval (m)",
    "y": " b. qebale (n)"
  },
  {
    "x": "qeval guretene",
    "y": " b. qebale girewtene"
  },
  {
    "x": "qevale (n)",
    "y": " b. qebale (n)"
  },
  {
    "x": "qevale guretene",
    "y": " b. qebale girewtene"
  },
  {
    "x": "qevçil, -e",
    "y": " b. qefçil, -e"
  },
  {
    "x": "qevd (m)",
    "y": " b. qevde (m)"
  },
  {
    "x": "qevda (m)",
    "y": " b. qevde (m)"
  },
  {
    "x": "qevde (m)",
    "y": " 1)demet\r\n~2)deste, kabza, sap (araç için)\r\n~3)tutam"
  },
  {
    "x": "qevda şimşêrî",
    "y": " kılıç kabzası"
  },
  {
    "x": "qevda destî (m)",
    "y": " bilek (el bileği)"
  },
  {
    "x": "qevda vaşturî (m)",
    "y": " orak sapı"
  },
  {
    "x": "qevda vaşturîye (m)",
    "y": " b. qevda vaşturî (m)"
  },
  {
    "x": "qevdevil (n)",
    "y": " buket"
  },
  {
    "x": "qevdi (m)",
    "y": " b. qevde (m)"
  },
  {
    "x": "qevela (m)",
    "y": " b. qebale (n)"
  },
  {
    "x": "qevela guretene",
    "y": " b. qebale girewtene"
  },
  {
    "x": "qevîlîyet (n)",
    "y": " b. qabîlîyet (n)"
  },
  {
    "x": "qevsing (n)",
    "y": " b. qefsîng (n)"
  },
  {
    "x": "qevş (m)",
    "y": " b. qebza (m)"
  },
  {
    "x": "qevul (n)",
    "y": " b. qebul (n)"
  },
  {
    "x": "qevul kerdene",
    "y": " b. qebul kerdene"
  },
  {
    "x": "qevza (m)",
    "y": " b. qebza (m)"
  },
  {
    "x": "qewa (m)",
    "y": " b. qewe (n)"
  },
  {
    "x": "qewal (n)",
    "y": " b. qebale (n)"
  },
  {
    "x": "qewal guretene",
    "y": " b. qebale girewtene"
  },
  {
    "x": "qewale (n)",
    "y": " b. qebale (n)"
  },
  {
    "x": "qewali (n)",
    "y": " b. qebale (n)"
  },
  {
    "x": "qewali guretene",
    "y": " b. qebale girewtene"
  },
  {
    "x": "qewaxane (n)",
    "y": " b. qewexane (n)"
  },
  {
    "x": "qewaxe (m)",
    "y": " kavak"
  },
  {
    "x": "qewaxa bejî (m)",
    "y": " dağ kavağı, acı kavak, titrek kavak"
  },
  {
    "x": "qewaxa borî (m)",
    "y": " dağ kavağı, acı kavak, titrek kavak"
  },
  {
    "x": "qewaxêre (m)",
    "y": " kavak"
  },
  {
    "x": "qewaxêri (m)",
    "y": " b. qewaxêre (m)"
  },
  {
    "x": "qewaxîr (m)",
    "y": " b. b. qewaxêre (m)"
  },
  {
    "x": "qewe (n)",
    "y": " kahve "
  },
  {
    "x": "qeweroş, -e",
    "y": " kahveci"
  },
  {
    "x": "qewet (n)",
    "y": " güç, kuvvet, çelim, mecal, takat\r\n~Qewet bo. (Kolay gelsin.)"
  },
  {
    "x": "qewetê deryayîyî (zh)",
    "y": " bahriye (deniz kuvvetleri)"
  },
  {
    "x": "qewete (m)",
    "y": " b. qewet (n)"
  },
  {
    "x": "Qewete bo.",
    "y": " b. Qewet bo."
  },
  {
    "x": "qewetin, -e",
    "y": " güçlü, kuvvetli"
  },
  {
    "x": "qewex (n)",
    "y": " b. qewaxe (m)"
  },
  {
    "x": "qewexo bej (n)",
    "y": " b. qewaxa bejî (m)"
  },
  {
    "x": "qewexane (n)",
    "y": " kahvehane"
  },
  {
    "x": "qewexanedar, -e",
    "y": " kahveci"
  },
  {
    "x": "qewexe (m)",
    "y": " b. qewaxe (m)"
  },
  {
    "x": "qewexone (n)",
    "y": " b. qewexane (n)"
  },
  {
    "x": "qeweyî, -ye",
    "y": " kahverengi"
  },
  {
    "x": "qewil (n)",
    "y": " b. qebul (n)"
  },
  {
    "x": "qewil kerdene",
    "y": " b. qebul kerdene"
  },
  {
    "x": "qewirme (n)",
    "y": " b. qawirme (n)"
  },
  {
    "x": "qewî",
    "y": " b. qey-I"
  },
  {
    "x": "qewl (n)",
    "y": " 1)söz\r\n~2)deyim, tabir\r\n~bi qewlê verênan: eskilerin deyimiyle"
  },
  {
    "x": "qewl dayene",
    "y": " söz vermek"
  },
  {
    "x": "bi qewlê verênan",
    "y": " eskilerin deyimiyle"
  },
  {
    "x": "qewlike (m)",
    "y": " b. qewlix-I (n)"
  },
  {
    "x": "qewlix (n)",
    "y": " 1)cüzdan (para cüzdanı)\r\n~2)deri kova"
  },
  {
    "x": "qewm (n)",
    "y": " kavim"
  },
  {
    "x": "qewmi (n)",
    "y": " b. qewm (n)"
  },
  {
    "x": "qewmî",
    "y": " etnik, kavmi"
  },
  {
    "x": "qewş (m)",
    "y": " b. qebza (m)"
  },
  {
    "x": "qewul (n)",
    "y": " b. qebul (n)"
  },
  {
    "x": "qewul kerdene",
    "y": " b. qebul kerdene"
  },
  {
    "x": "qewune (m)",
    "y": " b. qawune (m)"
  },
  {
    "x": "qey-I",
    "y": " niçin, niye"
  },
  {
    "x": "qey-II",
    "y": " b. qandê"
  },
  {
    "x": "qey ... a",
    "y": " b. qandê"
  },
  {
    "x": "qey Ellay",
    "y": " b. qandê Ellahî"
  },
  {
    "x": "qey ... rê",
    "y": " b. qandê ... rê"
  },
  {
    "x": "qey ... rî",
    "y": " b. qandê ... rê"
  },
  {
    "x": "qey ... wa",
    "y": " b. qandê"
  },
  {
    "x": "qey ... ya",
    "y": " b. qandê"
  },
  {
    "x": "qeya (m)",
    "y": " b. xaye (n)"
  },
  {
    "x": "qeyax (m)",
    "y": " b. qeyaxe (m)"
  },
  {
    "x": "qeyaxe (m)",
    "y": " kayık"
  },
  {
    "x": "qeyaxedar, -e",
    "y": " kayıkçı"
  },
  {
    "x": "qeybet (n)",
    "y": " b. xîybet (n)"
  },
  {
    "x": "qeybê",
    "y": " b. qandê"
  },
  {
    "x": "qeybê ke",
    "y": " b. qando ke"
  },
  {
    "x": "qeybo ke",
    "y": " b. qando ke"
  },
  {
    "x": "qeybi",
    "y": " b. qandê "
  },
  {
    "x": "qeybî",
    "y": " b. qandê"
  },
  {
    "x": "qeyd-I (n)",
    "y": " 1)kayıt\r\n~2)tescil"
  },
  {
    "x": "qeyd kerdene",
    "y": " kaydetmek "
  },
  {
    "x": "qeyd viraştene",
    "y": " kayıt yaptırmak"
  },
  {
    "x": "qeydê tapûyî (n)",
    "y": " tapu kaydı"
  },
  {
    "x": "qeydê trafîkî (n)",
    "y": " trafik tescil"
  },
  {
    "x": "qeydê xo viraştene",
    "y": " kaydını yaptırmak "
  },
  {
    "x": "qeyd-II (zh)",
    "y": " b. qeydî (zh)"
  },
  {
    "x": "qeyde (n)",
    "y": " qayde (n)"
  },
  {
    "x": "qeyde kerdene",
    "y": " b. qayde kerdene"
  },
  {
    "x": "qeydekerdiş (n)",
    "y": " b. qaydekerdiş (n)"
  },
  {
    "x": "qeydi (n)",
    "y": " qayde (n)"
  },
  {
    "x": "qeydî (zh)",
    "y": " pranga"
  },
  {
    "x": "qeydîyayene",
    "y": " b. qedîyayene"
  },
  {
    "x": "qeydkerda (m)",
    "y": " kayıtlı, tescilli"
  },
  {
    "x": "qeydkerde (n)",
    "y": " kayıtlı, tescilli"
  },
  {
    "x": "qeydkerdiş (n)",
    "y": " 1)kaydetme\r\n~2)tescil"
  },
  {
    "x": "qeydkerdîye (m)",
    "y": " kayıtlı, tescilli"
  },
  {
    "x": "qeydnayene",
    "y": " b. qedênayene"
  },
  {
    "x": "qeyeke (m)",
    "y": " b. qeyaxe (m)"
  },
  {
    "x": "qeyeki (m)",
    "y": " b. qeyaxe (m)"
  },
  {
    "x": "qeyêrî",
    "y": " b. xeyrê ..."
  },
  {
    "x": "qeyir",
    "y": " b. xeyrê ..."
  },
  {
    "x": "qeyî",
    "y": " b. qey-I"
  },
  {
    "x": "qeyîm, -e",
    "y": " b. qayîm, -e"
  },
  {
    "x": "qeyîm bîyayene",
    "y": " b. qayîm bîyene"
  },
  {
    "x": "qeyîm kerdene",
    "y": " b. qayîm kerdene"
  },
  {
    "x": "qeyîmbîyayîş (n)",
    "y": " b. qayîmbîyayîş (n)"
  },
  {
    "x": "qeyîmey (m)",
    "y": " b. qayîmî (m), qayîmîye"
  },
  {
    "x": "qeyîmî (m)",
    "y": " b. qayîmî (m), qayîmîye"
  },
  {
    "x": "qeyîmkerdiş (n)",
    "y": " b. qayîmkerdiş (n)"
  },
  {
    "x": "qeyîrê ...",
    "y": " b. xeyrê ..."
  },
  {
    "x": "qeyîş (n)",
    "y": " b. qayîş (n)"
  },
  {
    "x": "qeymax (n)",
    "y": " b. qaymax (n)"
  },
  {
    "x": "qeyme (n)",
    "y": " b. qîyame (n)"
  },
  {
    "x": "qeymewo qij (n)",
    "y": " b. qîyameyo qij (n)"
  },
  {
    "x": "qeymeqam, -e",
    "y": " b. qaymeqam, -e"
  },
  {
    "x": "qeymeqom, -i",
    "y": " b. qaymeqam, -e"
  },
  {
    "x": "qeymeqûm, -i",
    "y": " b. qaymeqam, -e"
  },
  {
    "x": "qeynaq (n)",
    "y": " b. qeynax (n) "
  },
  {
    "x": "qeynax (n)",
    "y": " kaynak (metaller için)"
  },
  {
    "x": "qeynaxker, -e",
    "y": " kaynakçı"
  },
  {
    "x": "qeyrayene",
    "y": " b. qîrayene"
  },
  {
    "x": "qeyrayîş (n)",
    "y": " b. qîrayîş (n)"
  },
  {
    "x": "qeyret (n)",
    "y": " b. xîret (n)"
  },
  {
    "x": "qeyret kerdene",
    "y": " b. xîret kerdene"
  },
  {
    "x": "qeyretkerdiş (n)",
    "y": " b. xîretkerdiş (n)"
  },
  {
    "x": "qeyretkêş, -e",
    "y": " b. xîretkêş, -e"
  },
  {
    "x": "qeyretkêşîye (m)",
    "y": " b. xîretkêşî (m)"
  },
  {
    "x": "qeyrê ...",
    "y": " b. xeyrê ..."
  },
  {
    "x": "qeyrî ...",
    "y": " b. xeyrê ..."
  },
  {
    "x": "qeys (m)",
    "y": " b. qeysî-II (zh)"
  },
  {
    "x": "qeysan (zh)",
    "y": " b. qeysî-II (zh)"
  },
  {
    "x": "qeyse (m)",
    "y": " b. qeysî-II (zh)"
  },
  {
    "x": "qeysê pîyazî (n)",
    "y": " b. pîyazo qeysî (n)"
  },
  {
    "x": "qeysike (m)",
    "y": " b. qeysikî (zh)"
  },
  {
    "x": "qeysikî (zh)",
    "y": " yuvağı çekmek için kullanılan çatallı araç"
  },
  {
    "x": "qeysî-I (m)",
    "y": " kayısı"
  },
  {
    "x": "qeysî-II (zh)",
    "y": " yuvağı çekmek için kullanılan çatallı araç"
  },
  {
    "x": "qeysîye (m)",
    "y": " kayısı"
  },
  {
    "x": "qeysonî (zh)",
    "y": " b. qeysî-II (zh)"
  },
  {
    "x": "qeyta",
    "y": " b. qetîyen"
  },
  {
    "x": "qeyta kerdene",
    "y": " b. qayît kerdene"
  },
  {
    "x": "qeytakerdiş (n)",
    "y": " b. qayîtkerdiş (n)"
  },
  {
    "x": "qeytan-I (n)",
    "y": " kaytan"
  },
  {
    "x": "qeytanê solan (n)",
    "y": " ayakkabı bağı"
  },
  {
    "x": "qeytan-II",
    "y": " b. qetîyen"
  },
  {
    "x": "qeyte kerdene",
    "y": " b. qayît kerdene"
  },
  {
    "x": "qeytekerdiş (n)",
    "y": " b. qayîtkerdiş (n)"
  },
  {
    "x": "qeyton (n)",
    "y": " b. qeytan-I (n)"
  },
  {
    "x": "qeytûn (n)",
    "y": " b. qeytan-I (n)"
  },
  {
    "x": "qeza-I (m)",
    "y": " ilçe, kaza"
  },
  {
    "x": "qeza-II (n)",
    "y": " kaza"
  },
  {
    "x": "qezayê teyara (n)",
    "y": " uçak kazası"
  },
  {
    "x": "qezayê trafîkî (n)",
    "y": " trafik kazası"
  },
  {
    "x": "qezan (n)",
    "y": " b. qazan (n)"
  },
  {
    "x": "qezanta (m)",
    "y": " b. gazeta (m)"
  },
  {
    "x": "qezata (m)",
    "y": " b. gazeta (m)"
  },
  {
    "x": "qezate (m)",
    "y": " b. gazeta (m)"
  },
  {
    "x": "qezeçî (m)",
    "y": " b. qezekî (n)"
  },
  {
    "x": "qezekî (n)",
    "y": " cepken"
  },
  {
    "x": "qezenc (n)",
    "y": " kar-I (n)"
  },
  {
    "x": "qezenc kerdene",
    "y": " kazanmak, yenmek"
  },
  {
    "x": "qezenc kerdeni",
    "y": " b. qezenc kerdene"
  },
  {
    "x": "qezenç (n)",
    "y": " kar-I (n)"
  },
  {
    "x": "qezenç kerdeni",
    "y": " b. qezenc kerdene"
  },
  {
    "x": "qezenmîş kerdene",
    "y": " kazanmak, yenmek"
  },
  {
    "x": "qezeta (m)",
    "y": " b. gazeta (m)"
  },
  {
    "x": "qezete (n)",
    "y": " b. gazeta (m)"
  },
  {
    "x": "qê-I",
    "y": " b. qey-I"
  },
  {
    "x": "qê-II",
    "y": " b. qandê"
  },
  {
    "x": "qê ke",
    "y": " b. qando ke"
  },
  {
    "x": "qêbet (n)",
    "y": " b. xîybet (n)"
  },
  {
    "x": "qêde (n)",
    "y": " b. qayde (n)"
  },
  {
    "x": "qêdi (n)",
    "y": " b. qayde (n)"
  },
  {
    "x": "qêdînayene",
    "y": " b. qedênayene"
  },
  {
    "x": "qêdînayîş (n)",
    "y": " b. qedênayîş (n)"
  },
  {
    "x": "qêdîyayêne",
    "y": " b. qedîyayene "
  },
  {
    "x": "qêdîyayîş (n)",
    "y": " b. qedîyayîş (n)"
  },
  {
    "x": "qêdnayene",
    "y": " b. qedênayene"
  },
  {
    "x": "qêerî ... ",
    "y": " b. xeyrê ..."
  },
  {
    "x": "qêhwi (n)",
    "y": " 1)b. qewe (n)\r\n~2)b. qewexane (n)"
  },
  {
    "x": "qêl (n)",
    "y": " b. qil (n)"
  },
  {
    "x": "qêlik (m)",
    "y": " b. qêlike (m)"
  },
  {
    "x": "qêlike (m)",
    "y": " dağ kavağı, acı kavak, titrek kavak"
  },
  {
    "x": "qême (n)",
    "y": " b. qîyame (n)"
  },
  {
    "x": "qêrayene",
    "y": " b. qîrayene"
  },
  {
    "x": "qêrayîş (n)",
    "y": " b. qîrayîş (n)"
  },
  {
    "x": "qêret (n)",
    "y": " b. xîret (n)"
  },
  {
    "x": "qêret kerdene",
    "y": " b. xîret kerdene"
  },
  {
    "x": "qêretkerdiş (n)",
    "y": " b. xîretkerdiş (n)"
  },
  {
    "x": "qêretkêş, -e",
    "y": " b. xîretkêş, -e"
  },
  {
    "x": "qêretkêşî (m)",
    "y": " b. xîretkêşî (m)"
  },
  {
    "x": "qêretkêşîye (m)",
    "y": " b. xîretkêşîye (m)"
  },
  {
    "x": "qêrrayene",
    "y": " b. qîrayene"
  },
  {
    "x": "qêrrayîş (n)",
    "y": " b. qîrayîş (n)"
  },
  {
    "x": "qêrrî (n)",
    "y": " b. qîrî (m)"
  },
  {
    "x": "qêrrî dekotene",
    "y": " b. qîrî dekewtene"
  },
  {
    "x": "qêrrîdekotiş (n)",
    "y": " b. qîrîdekewtiş (n)"
  },
  {
    "x": "qêrrîyayene",
    "y": " b. qîrayene"
  },
  {
    "x": "qêrrîyayîş (n)",
    "y": " b. qîrayîş (n)"
  },
  {
    "x": "qês (m)",
    "y": " b. qeysî-II (zh)"
  },
  {
    "x": "qêsî (m)",
    "y": " b. qeysî-I (m), qeysîye (m)"
  },
  {
    "x": "qêşî kerdene",
    "y": " b. qisey kerdene"
  },
  {
    "x": "qêşîkerdiş (n)",
    "y": " b. qiseykerdiş (n)"
  },
  {
    "x": "qêt",
    "y": " b. qet-I"
  },
  {
    "x": "qêtîya",
    "y": " b. qetîyen"
  },
  {
    "x": "qêtûn (n)",
    "y": " b. qeytan-I (n)"
  },
  {
    "x": "qêy",
    "y": " b. qey-I"
  },
  {
    "x": "qêyd (n)",
    "y": " b. qeyd-I (n)"
  },
  {
    "x": "qêyd kerdene",
    "y": " b. qeyd kerdene"
  },
  {
    "x": "qêyd virastene",
    "y": " b. qeyd viraştene"
  },
  {
    "x": "qêydê xo virastene",
    "y": " b. qeydê xo viraştene"
  },
  {
    "x": "qêyde (n)",
    "y": " qayde (n)"
  },
  {
    "x": "qêyî",
    "y": " b. qey-I"
  },
  {
    "x": "qêyî ... rê",
    "y": " b. qandê ... rê"
  },
  {
    "x": "qêyîşîye (m)",
    "y": " b. qeysî-I (m), qeysîye (m)"
  },
  {
    "x": "qêymeqam, -e",
    "y": " b. qaymeqam, -e"
  },
  {
    "x": "qêytan (n)",
    "y": " b. qeytan-I (n)"
  },
  {
    "x": "qi",
    "y": " b. qet"
  },
  {
    "x": "qib (m)",
    "y": " b. qibe (m)"
  },
  {
    "x": "qibale (n)",
    "y": " b. qebale (n)"
  },
  {
    "x": "qibale girewtene",
    "y": " b. qebale girewtene"
  },
  {
    "x": "qibe (m)",
    "y": " kiler"
  },
  {
    "x": "qibet (n)",
    "y": " b. b. qebehet (n)"
  },
  {
    "x": "qibet ... kerdene",
    "y": " b. qebehet ... kerdene"
  },
  {
    "x": "qibet ... kerdiş (n)",
    "y": " b. qebehet ... kerdiş (n)"
  },
  {
    "x": "qibhet (n)",
    "y": " b. b. qebehet (n)"
  },
  {
    "x": "qibhet ... kerdene",
    "y": " b. qebehet ... kerdene"
  },
  {
    "x": "qibhet ... kerdiş (n)",
    "y": " b. qebehet ... kerdiş (n)"
  },
  {
    "x": "qible (n)",
    "y": " b. qible (m)"
  },
  {
    "x": "qible (m)",
    "y": " kıble"
  },
  {
    "x": "qibli (m)",
    "y": " b. qible (m)"
  },
  {
    "x": "qibr (m)",
    "y": " b. qebre (m)"
  },
  {
    "x": "qibri (m)",
    "y": " b. qebre (m)"
  },
  {
    "x": "Qibris (n)",
    "y": " Kıbrıs"
  },
  {
    "x": "qic, -e -I",
    "y": " çocuk"
  },
  {
    "x": "qic bîyene",
    "y": " çocuklaşmak"
  },
  {
    "x": "qic ra qedîyayene",
    "y": " doğurmak (insan için), doğum yapmak"
  },
  {
    "x": "qic ra qedîyene",
    "y": " b. qic ra qedîyayene"
  },
  {
    "x": "qic ver şîyayene",
    "y": " b. qic ver şîyene"
  },
  {
    "x": "qic ver şîyene",
    "y": " düşük yapmak"
  },
  {
    "x": "qic, -e -II",
    "y": " b. qij, -e -I"
  },
  {
    "x": "qicane",
    "y": " çocukça"
  },
  {
    "x": "qicayene",
    "y": " b. qîjayene"
  },
  {
    "x": "qicayîş (n)",
    "y": " b. qîjayîş (n)"
  },
  {
    "x": "qicbîyayîş (n)",
    "y": " çocuklaşma"
  },
  {
    "x": "qicek, -e",
    "y": " 1)b. qeçek, -e\r\n~2)b. qij, -e-I"
  },
  {
    "x": "qici (m)",
    "y": " b. qije (m)"
  },
  {
    "x": "qicik, -e-I",
    "y": " b. qic, -e-I"
  },
  {
    "x": "qicik, -e-II",
    "y": " b. qij, -e-I"
  },
  {
    "x": "qicike (m)",
    "y": " b. qijike (m)"
  },
  {
    "x": "qickî",
    "y": " çocukça"
  },
  {
    "x": "qiç, -e",
    "y": " b. qic, -e-I"
  },
  {
    "x": "qiçek, -e",
    "y": " b. qeçek, -e"
  },
  {
    "x": "qida (n)",
    "y": " b. qeza-II (n)"
  },
  {
    "x": "qide (n)",
    "y": " qayde (n)"
  },
  {
    "x": "qidênayîş (n)",
    "y": " b. qedênayîş (n)"
  },
  {
    "x": "qidqid (n)",
    "y": " b. qitqit (n)\r\n~“Qid, qid, qidîs\r\n~\t\r\n~Ma hakî kerdî telîs\r\n~Şirawitî Bedlîs”"
  },
  {
    "x": "qidqid kerdene",
    "y": " b. qitqit kerdene"
  },
  {
    "x": "qidqidkerdiş (n)",
    "y": " b. qitqitkerdiş (n)"
  },
  {
    "x": "qifil (n)",
    "y": " b. qefil-I (n)"
  },
  {
    "x": "qifle (n)",
    "y": " b. qefle (n)"
  },
  {
    "x": "qifle qifle",
    "y": " b. qefle qefle"
  },
  {
    "x": "qij, -e -I",
    "y": " küçük"
  },
  {
    "x": "qij dîyene",
    "y": " aşağılamak"
  },
  {
    "x": "qij vistene",
    "y": " aşağılamak"
  },
  {
    "x": "qij, -e -II",
    "y": " b. qic, -e -I"
  },
  {
    "x": "qij, -e -III",
    "y": " alaca (siyah-beyaz renkli)"
  },
  {
    "x": "qija (n)",
    "y": " 1)ayaz\r\n~2)b. qiş (n)"
  },
  {
    "x": "qijayene",
    "y": " b. qîjayene"
  },
  {
    "x": "qijayîş (n)",
    "y": " b. qîjayîş (n)"
  },
  {
    "x": "qijdike (m)",
    "y": " b. qijike (m)"
  },
  {
    "x": "qijdîyayîş (n)",
    "y": " aşağılama"
  },
  {
    "x": "qijek, -e-I",
    "y": " cırcır böceği, cırlak"
  },
  {
    "x": "qijek, -e-II",
    "y": " b. qeçek, -e"
  },
  {
    "x": "qijik, -i",
    "y": " b. qeçek, -e"
  },
  {
    "x": "qijike (m)",
    "y": " karga"
  },
  {
    "x": "qijilnaya (m)",
    "y": " kızartma (yumurta vb. için), kızartılmış olan (yumurta vb. için)"
  },
  {
    "x": "qijilnaye (n)",
    "y": " kızartılmış olan (yumurta vb. için), kızartma (yumurta vb. için)"
  },
  {
    "x": "qijilnayene",
    "y": " kızartmak (yumurta vb. için)"
  },
  {
    "x": "qijilnayîş (n)",
    "y": " kızartma (yumurta vb. için)"
  },
  {
    "x": "qijilnayîye (m)",
    "y": " kızartılmış olan (yumurta vb. için), kızartma (yumurta vb. için)"
  },
  {
    "x": "qijjayene",
    "y": " b. qîjayene"
  },
  {
    "x": "qijjayîş (n)",
    "y": " b. qîjayîş (n)"
  },
  {
    "x": "qijkek, -e",
    "y": " b. qij, -e -I"
  },
  {
    "x": "qijne (n)",
    "y": " kene"
  },
  {
    "x": "qijnik (n)",
    "y": " kene"
  },
  {
    "x": "qijnikî (zh)",
    "y": " içyağının kızartılmasıyla meydana gelen bir yiyecek"
  },
  {
    "x": "qijnîk (zh)",
    "y": " b. qijnikî (zh)"
  },
  {
    "x": "qijqijik (n)",
    "y": " b. qijqijok, -e"
  },
  {
    "x": "qijqijok, -e",
    "y": " cırcır böceği, cırlak"
  },
  {
    "x": "qijvistiş (n)",
    "y": " aşağılama"
  },
  {
    "x": "qil (n)",
    "y": " tepe"
  },
  {
    "x": "qila (n)",
    "y": " b. qele (n)"
  },
  {
    "x": "qilaçî (n)",
    "y": " b. qelaker, -e"
  },
  {
    "x": "qilanayene",
    "y": " b. qilaynayene"
  },
  {
    "x": "qilanayîş (n)",
    "y": " b. qilaynayîş (n)"
  },
  {
    "x": "qilanc (m)",
    "y": " b. qilance (m)"
  },
  {
    "x": "qilance (m)",
    "y": " 1)serçe parmak\r\n~2)halay (serçe parmakla tutuşarak oynanan)"
  },
  {
    "x": "qilanceke",
    "y": " b. qilancike (m)"
  },
  {
    "x": "qilanci (m)",
    "y": " b. qilance (m)"
  },
  {
    "x": "qilancik, -e",
    "y": " saksağan"
  },
  {
    "x": "qilancike (m)",
    "y": " 1)serçe parmak\r\n~2)halay (serçe parmakla tutuşarak oynanan)"
  },
  {
    "x": "qilanciki (m)",
    "y": " b. qilancike (m)"
  },
  {
    "x": "qilançik, -e",
    "y": " b. qilancik, -e"
  },
  {
    "x": "qilas (n)",
    "y": " b. qelaş (n)"
  },
  {
    "x": "qilasnayene",
    "y": " b. qelişnayene"
  },
  {
    "x": "qilasnayêne",
    "y": " b. qelişnayene"
  },
  {
    "x": "qilaş (n)",
    "y": " b. qelaş (n)"
  },
  {
    "x": "qilaşîyayene",
    "y": " b. qelişîyene"
  },
  {
    "x": "qilaşîyene",
    "y": " b. qelişîyene"
  },
  {
    "x": "qilaşnayene",
    "y": " b. qelişnayene"
  },
  {
    "x": "qilawin (n)",
    "y": " b. qelûne (m)"
  },
  {
    "x": "qilawine (m)",
    "y": " b. qelûne (m)"
  },
  {
    "x": "qilay (m)",
    "y": " b. qela-II (m)"
  },
  {
    "x": "qilaycî (n)",
    "y": " b. qelaker, -e"
  },
  {
    "x": "qilayîş (n)",
    "y": " b. qilîyayîş (n)"
  },
  {
    "x": "qilaynayene",
    "y": " temelinden koparmak (ağaç, dal vb. için), sökmek (ağaç, dal vb. için)"
  },
  {
    "x": "qilaynayîş (n)",
    "y": " temelinden koparma (ağaç, dal vb. için), sökme (ağaç, dal vb. için)"
  },
  {
    "x": "qilaynîyayene",
    "y": " temelinden koparılmak (ağaç, dal vb. için)"
  },
  {
    "x": "qilaynîyayîş (n)",
    "y": " temelinden koparılma (ağaç, dal vb. için) "
  },
  {
    "x": "qilba (m)",
    "y": " b. qible (m)"
  },
  {
    "x": "qilbdayîş (n)",
    "y": " b. qelbedayîş (n)"
  },
  {
    "x": "qilbe (m)",
    "y": " b. qible (m)"
  },
  {
    "x": "qilbi-I (m)",
    "y": " b. qible (m)"
  },
  {
    "x": "qilbi-II (m)",
    "y": " b. qelbe (m)"
  },
  {
    "x": "qilbidayîş (n)",
    "y": " b. qelbedayîş (n)"
  },
  {
    "x": "qilç-I (m)",
    "y": " b. qilçe-I (m)"
  },
  {
    "x": "qilç-II (m)",
    "y": " b. qilçe-II (m)"
  },
  {
    "x": "qilçê qarmî",
    "y": " b. qilça qawirmeyî"
  },
  {
    "x": "qilçê tontirî",
    "y": " b. qilça tantorî"
  },
  {
    "x": "qilçê toraqî",
    "y": " b. qilça toraqî"
  },
  {
    "x": "qilçe-I (m)",
    "y": " 1)bez veya kumaşın küçük parçası\r\n~2)tarla veya arazinin başka tarla veya araziler arasında kalan dar bölümü"
  },
  {
    "x": "qilçe-II (m)",
    "y": " gözleme, bazlama (bir hamur işi)"
  },
  {
    "x": "qilça qawirmeyî",
    "y": " bir çeşit gözleme"
  },
  {
    "x": "qilça tantorî",
    "y": " b. qilça tantûrî"
  },
  {
    "x": "qilça tantûrî",
    "y": " bir çeşit gözleme"
  },
  {
    "x": "qilça toraqî",
    "y": " bir çeşit gözleme"
  },
  {
    "x": "qilçek (n)",
    "y": " b. qilçik (n)"
  },
  {
    "x": "qilçeke (m)",
    "y": " 1)bez veya kumaşın küçük parçası\r\n~2)tarla veya arazinin başka tarla veya araziler arasında kalan küçük ve dar bölümü "
  },
  {
    "x": "qilçeki (m)",
    "y": " b. qilçeke (m)"
  },
  {
    "x": "qilçi (m)",
    "y": " b. qilçe (m)"
  },
  {
    "x": "qilçik (n)",
    "y": " çaput, paçavra"
  },
  {
    "x": "qilçike (m)",
    "y": " b. qilçeke (m)"
  },
  {
    "x": "qilçin (m)",
    "y": " b. qilçine (m)"
  },
  {
    "x": "qilçine (m)",
    "y": " gözleme, bazlama (bir hamur işi)"
  },
  {
    "x": "qilçix (n)",
    "y": " belirli bölümlerle çevrili olan küçük tarla parçası"
  },
  {
    "x": "qiler (n)",
    "y": " b. qilêr (n) "
  },
  {
    "x": "qilerin, -e",
    "y": " b. qilêrin, -e"
  },
  {
    "x": "qilerin bîyayene",
    "y": " b. qilêrin bîyene"
  },
  {
    "x": "qilerin kerdene",
    "y": " b. qilêrin kerdene"
  },
  {
    "x": "qilerinbîyayîş (n)",
    "y": " b. qilêrinbîyayîş (n)"
  },
  {
    "x": "qilerinîye (m)",
    "y": " b. qilêrinîye (m)"
  },
  {
    "x": "qilerinkerdiş (n)",
    "y": " b. qilêrinkerdiş (n)"
  },
  {
    "x": "qilerîye (m)",
    "y": " b. qilêrîye (m) "
  },
  {
    "x": "qileş (n)",
    "y": " b. kalaşnîkof (n)"
  },
  {
    "x": "qilew, -i",
    "y": " b. qelew, -e"
  },
  {
    "x": "qilew bîyayîne",
    "y": " b. qelew bîyene"
  },
  {
    "x": "qilew kerdene",
    "y": " b. qelew kerdene"
  },
  {
    "x": "qileyayene",
    "y": " b. qilîyayene"
  },
  {
    "x": "qileyayîş (n)",
    "y": " b. qilîyayîş (n)"
  },
  {
    "x": "qileynaya (m)",
    "y": " b. qelênaya (m), qelênayîye (m)"
  },
  {
    "x": "qileynaye (n)",
    "y": " b. qelênaye (n)"
  },
  {
    "x": "qileynayene-I",
    "y": " b. qelênayene"
  },
  {
    "x": "qileynayene-II",
    "y": " b. qilaynayene"
  },
  {
    "x": "qileynayîş-I (n)",
    "y": " b. qelênayîş (n)"
  },
  {
    "x": "qileynayîş-II (n)",
    "y": " b. qilaynayîş (n)"
  },
  {
    "x": "qileynîyayene",
    "y": " b. qilaynîyayene"
  },
  {
    "x": "qileynîyayîş (n)",
    "y": " b. qilaynîyayîş (n)"
  },
  {
    "x": "qilênaya (m)",
    "y": " b. qelênaya (m), qelênayîye (m)"
  },
  {
    "x": "qilênaye (n)",
    "y": " b. qelênaye (n)"
  },
  {
    "x": "qilênayene",
    "y": " b. qelênayene"
  },
  {
    "x": "qilênayîş (n)",
    "y": " b. qelênayîş (n)"
  },
  {
    "x": "qilêr (n)",
    "y": " kir"
  },
  {
    "x": "qilêrin, -e",
    "y": " kirli"
  },
  {
    "x": "qilêrin bîyene",
    "y": " kirlenmek"
  },
  {
    "x": "qilêrin kerdene",
    "y": " kirletmek "
  },
  {
    "x": "qilêrinbîyayîş (n)",
    "y": " kirlenme "
  },
  {
    "x": "qilêrinênî (m)",
    "y": " kirlilik "
  },
  {
    "x": "qilêrinî (m)",
    "y": " b. qilêrinîye (m)"
  },
  {
    "x": "qilêrinîye (m)",
    "y": " kirlilik "
  },
  {
    "x": "qilêrinkerdiş (n)",
    "y": " kirletme"
  },
  {
    "x": "qilêrîye (m)",
    "y": " kirlilik"
  },
  {
    "x": "qilêrker, -e",
    "y": " kirletici"
  },
  {
    "x": "qilincîyele (n)",
    "y": " b. qirîncele (n)"
  },
  {
    "x": "qilix (n)",
    "y": " kıyafet, kılık, giyim, kisve"
  },
  {
    "x": "qilîç-I (n)",
    "y": " sabanın çift sürerken elle tutulan kısmından toprağa kadar uzanan parçası"
  },
  {
    "x": "qilîç-II (m)",
    "y": " b. qilîçe-II (m)"
  },
  {
    "x": "qilîçe-I (m)",
    "y": " halay (serçe parmakla tutuşarak oynanan)"
  },
  {
    "x": "qilîçe-II (m)",
    "y": " b. qilance (m)"
  },
  {
    "x": "qilîk (m)",
    "y": " b. qêlike (m)"
  },
  {
    "x": "qilînaya (m)",
    "y": " b. qelênaya (m), qelênayîye (m)"
  },
  {
    "x": "qilînaye (n)",
    "y": " b. qelênaye (n)"
  },
  {
    "x": "qilînayene",
    "y": " b. qelênayene"
  },
  {
    "x": "qilînayîş (n)",
    "y": " b. qelênayîş (n)"
  },
  {
    "x": "qilînc (m)",
    "y": " b. qilance (m)"
  },
  {
    "x": "qilîncile (n)",
    "y": " b. qirîncele (n) "
  },
  {
    "x": "qilîncire (n)",
    "y": " b. qirîncele (n)"
  },
  {
    "x": "qilîncle (n)",
    "y": " b. qirîncele (n) "
  },
  {
    "x": "qilînç (m)",
    "y": " b. qilance (m)"
  },
  {
    "x": "qilînçe (m)",
    "y": " b. qilance (m)"
  },
  {
    "x": "qilîr (n)",
    "y": " b. qilêr (n) "
  },
  {
    "x": "qilîrin, -i",
    "y": " b. qilêrin, -e"
  },
  {
    "x": "qilîyayene",
    "y": " akmak (yıldız akmak), kaymak (yıldız kaymak)"
  },
  {
    "x": "qilîyayîş (n)",
    "y": " kayma (yıldız kayması)"
  },
  {
    "x": "qillîr (n)",
    "y": " b. qilêr (n) "
  },
  {
    "x": "qillîrin, -i",
    "y": " b. qilêrin, -e"
  },
  {
    "x": "qilme (m)",
    "y": " yudum"
  },
  {
    "x": "qilmi (m)",
    "y": " b. qilme (m)"
  },
  {
    "x": "qilmik (m)",
    "y": " b. qilmike (m)"
  },
  {
    "x": "qilmike (m)",
    "y": " yudum"
  },
  {
    "x": "qilnaya (m)",
    "y": " b. qelênaya (m), qelênayîye (m)"
  },
  {
    "x": "qilnaye (n)",
    "y": " b. qelênaye (n)"
  },
  {
    "x": "qilnayene",
    "y": " b. qelênayene"
  },
  {
    "x": "qilnayîş (n)",
    "y": " b. qelênayîş (n)"
  },
  {
    "x": "qiloç (n)",
    "y": " b. qoç (n)"
  },
  {
    "x": "qiloç piro dayene",
    "y": " b. qoç piro dayene"
  },
  {
    "x": "qiloçin, -e",
    "y": " b. qoçin, -e"
  },
  {
    "x": "qiloçî kerdene",
    "y": " b. qoç kerdene"
  },
  {
    "x": "qilonc (m)",
    "y": " b. qilance (m)"
  },
  {
    "x": "qilonceki (m)",
    "y": " b. qilancike (m)"
  },
  {
    "x": "qilonci (m)",
    "y": " b. qilance (m)"
  },
  {
    "x": "qiloncik-I(m)",
    "y": " b. qilancike (m)"
  },
  {
    "x": "qiloncik, -i-II (m)",
    "y": " b. qilancik, -e"
  },
  {
    "x": "qilonciki (m)",
    "y": " b. qilancike (m)"
  },
  {
    "x": "qilonç (m)",
    "y": " b. qilancike (m)"
  },
  {
    "x": "qilonçi (m)",
    "y": " b. qilance (m)"
  },
  {
    "x": "qilor, -e",
    "y": " b. gilover, -e"
  },
  {
    "x": "qilorin (n)",
    "y": " b. gilorin (n)"
  },
  {
    "x": "qilover",
    "y": " b. gilover, -e"
  },
  {
    "x": "qilow, -e",
    "y": " b. qelew, -e"
  },
  {
    "x": "qilow bîyayene",
    "y": " b. qelew bîyene"
  },
  {
    "x": "qiloxîr",
    "y": " b. gilover, -e"
  },
  {
    "x": "qiloxîrik",
    "y": " b. gilover, -e"
  },
  {
    "x": "qilp (n)",
    "y": " b. qîlp (n)"
  },
  {
    "x": "qilqe (n)",
    "y": " salkım"
  },
  {
    "x": "qilqilîyayene",
    "y": " endişelenmek, endişe etmek, kaygılanmak, tasalanmak"
  },
  {
    "x": "qilqilîyayîş (n)",
    "y": " endişelenme, kaygılanma, tasalanme"
  },
  {
    "x": "qilqueşmeyê darûn (n)",
    "y": " b. quelqeşmeyê daran (n)"
  },
  {
    "x": "qiltifong (n)",
    "y": " b. qultifing (n)"
  },
  {
    "x": "qilûnci (m)",
    "y": " b. qilance (m)"
  },
  {
    "x": "qilûncik (m)",
    "y": " b. qilancike (m)"
  },
  {
    "x": "qilyon (m)",
    "y": " b. qelûne (m)"
  },
  {
    "x": "qimti (m)",
    "y": " b. qemte (m)"
  },
  {
    "x": "qin (m)",
    "y": " b. qine (m)"
  },
  {
    "x": "qinaste (n)",
    "y": " b. qineste (n)"
  },
  {
    "x": "qinastik (n)",
    "y": " b. qineste (n)"
  },
  {
    "x": "qinat (m)",
    "y": " b. qînate (m)"
  },
  {
    "x": "qinceli (n)",
    "y": " b. qirîncele (n)"
  },
  {
    "x": "qincêl (n)",
    "y": " b. qirîncele (n)"
  },
  {
    "x": "qinda (n)",
    "y": " ibne (argo), götlek (argo), oğlan (edilgen eşcinsel erkek) (argo)"
  },
  {
    "x": "qindaye (n)",
    "y": " b. qinda (n)"
  },
  {
    "x": "qindayey (m)",
    "y": " b. qindayî (m), qindayîye (m)"
  },
  {
    "x": "qindayê (m)",
    "y": " b. qindayî (m), qindayîye (m)"
  },
  {
    "x": "qindayî (m)",
    "y": " ibnelik (argo), götleklik (argo), oğlanlık (argo)"
  },
  {
    "x": "qindayîye (m)",
    "y": " ibnelik (argo), götleklik (argo), oğlanlık (argo)"
  },
  {
    "x": "qine (m)",
    "y": " göt, kıç, makat, anüs, büzük"
  },
  {
    "x": "qina dare (m)",
    "y": " ağaç gövdesi"
  },
  {
    "x": "qine ra",
    "y": " anal"
  },
  {
    "x": "qine weestene",
    "y": " b. qine weeştene"
  },
  {
    "x": "qine weeştene",
    "y": " zıplayıp koşarak tekme atmak (hayvan için)"
  },
  {
    "x": "qineda (n)",
    "y": " b. qinda (n)"
  },
  {
    "x": "qineser şîyene",
    "y": " geri geri gitmek, arka arka gitmek"
  },
  {
    "x": "qineserkî şîyene",
    "y": " geri geri gitmek, arka arka gitmek "
  },
  {
    "x": "qineste (n)",
    "y": " kalça, sağrı "
  },
  {
    "x": "qinestevete (n)",
    "y": " kalçası çıkmış olan"
  },
  {
    "x": "qinestevetî, -ye",
    "y": " kalçası çıkmış olan"
  },
  {
    "x": "qingiran, -e",
    "y": " ağırcanlı (argo), ağırkanlı (argo), üşengeç (argo), üşengen (argo)"
  },
  {
    "x": "qingiraney (m)",
    "y": " b. qingiranîye (m)"
  },
  {
    "x": "qingiranê (m)",
    "y": " b. qingiranîye (m)"
  },
  {
    "x": "qingirani (m)",
    "y": " b. qingirane (m)"
  },
  {
    "x": "qingiranîye (m)",
    "y": " ağırcanlılık (argo), ağırkanlılık (argo), üşengeçlik (argo), üşengenlik (argo)"
  },
  {
    "x": "qingiron, -i",
    "y": " b. qangiran, -e"
  },
  {
    "x": "qingironey (m)",
    "y": " b. qingiranîye (m)"
  },
  {
    "x": "qingironê (m)",
    "y": " b. qingiranîye (m)"
  },
  {
    "x": "qengirûn, -i",
    "y": " b. qingiran, -e"
  },
  {
    "x": "qengirûney (m)",
    "y": " b. qingiranîye (m)"
  },
  {
    "x": "qengirûnê (m)",
    "y": " b. qingiranîye (m)"
  },
  {
    "x": "qineti (m)",
    "y": " b. qînate (m)"
  },
  {
    "x": "qinêre (n)",
    "y": " öğle yemeği ile akşam yemeği arasında yenen hafif yemek"
  },
  {
    "x": "qini (m)",
    "y": " b. qine (m) "
  },
  {
    "x": "qini ra",
    "y": " b. qine ra"
  },
  {
    "x": "qinnap (n)",
    "y": " kınnap, kırnap"
  },
  {
    "x": "qinti (m)",
    "y": " b. qemte (m)"
  },
  {
    "x": "qinyat (m)",
    "y": " b. qînate (m)"
  },
  {
    "x": "qinyeri (n)",
    "y": " b. qinêre (n)"
  },
  {
    "x": "qip kewtene",
    "y": " b. qirpe kewtene"
  },
  {
    "x": "qipe (m)",
    "y": " b. qirpe (m)"
  },
  {
    "x": "qir-I (n)",
    "y": " kırağı"
  },
  {
    "x": "qir-II (m)",
    "y": " b. qirrike (m)"
  },
  {
    "x": "qiral (n)",
    "y": " kral"
  },
  {
    "x": "qiralîça (m)",
    "y": " kraliçe "
  },
  {
    "x": "qiramafon (n)",
    "y": " b. gramofon (n)"
  },
  {
    "x": "qirar (n)",
    "y": " b. qerar (n)"
  },
  {
    "x": "qirawate (m)",
    "y": " b. kravate (m)"
  },
  {
    "x": "qirawati (m)",
    "y": " b. kravate (m)"
  },
  {
    "x": "qirawet (n)",
    "y": " b. kravate (m)"
  },
  {
    "x": "qirawil (m)",
    "y": " b. qirawile (m)"
  },
  {
    "x": "qirawile (m)",
    "y": " karga"
  },
  {
    "x": "qirawî (m)",
    "y": " b. qerawî (m)"
  },
  {
    "x": "qirawule-I (m)",
    "y": " kuzgun"
  },
  {
    "x": "qirawule-II (m)",
    "y": " b. qirawile (m)"
  },
  {
    "x": "qirayene",
    "y": " melemek (keçi için)  "
  },
  {
    "x": "qirayîş (n)",
    "y": " meleme (keçi için)  "
  },
  {
    "x": "qirba (n)",
    "y": " b. qurban (n)"
  },
  {
    "x": "qirban (n)",
    "y": " b. qurban (n)"
  },
  {
    "x": "qirbane (m)",
    "y": " b. qurban (n)"
  },
  {
    "x": "qirbelek, -e",
    "y": " b. qerbelek, -e"
  },
  {
    "x": "qirbo (n)",
    "y": " b. qurban (n)"
  },
  {
    "x": "qirbon (n)",
    "y": " b. qurban (n)"
  },
  {
    "x": "qirbon bîyayîne",
    "y": " b. qurban bîyene"
  },
  {
    "x": "qirbon kerdene",
    "y": " b. qurban kerdene"
  },
  {
    "x": "qirbû (n)",
    "y": " b. qurban (n)"
  },
  {
    "x": "qirbûn (n)",
    "y": " b. qurban (n)"
  },
  {
    "x": "qirbûn bîyayîne",
    "y": " b. qurban bîyene"
  },
  {
    "x": "qirbûn kerdene",
    "y": " b. qurban kerdene"
  },
  {
    "x": "qirç (m)",
    "y": " b. qirçe (m)"
  },
  {
    "x": "qirç-qale (m)",
    "y": " b. qirçûqale (m)"
  },
  {
    "x": "qirçayene",
    "y": " b. qîştene"
  },
  {
    "x": "qirçayîş (n)",
    "y": " b. qîştiş (n)"
  },
  {
    "x": "qirçe (m)",
    "y": " çırpı (kuru dal parçası)"
  },
  {
    "x": "qirçi (m)",
    "y": " b. qirçe (m)"
  },
  {
    "x": "qirçike-I (m)",
    "y": " çırpı (kuru dal parçası)"
  },
  {
    "x": "qirçike-II (m)",
    "y": " b. qirpiçike (m)"
  },
  {
    "x": "qirçiki (m)",
    "y": " b. qirçike-I (m)"
  },
  {
    "x": "qirçnayene",
    "y": " gıcırdatmak"
  },
  {
    "x": "qirçnayîs (n)",
    "y": " b. qirçnayîş (n)"
  },
  {
    "x": "qirçnayîş (n)",
    "y": " gıcırdatma"
  },
  {
    "x": "qirçûne (m)",
    "y": " b. qurçine (m)"
  },
  {
    "x": "qirçûqale (m)",
    "y": " çalı çırpı"
  },
  {
    "x": "qirencele (n)",
    "y": " b. qirîncele (n)"
  },
  {
    "x": "qiresnayene",
    "y": " b. qirçnayene"
  },
  {
    "x": "qiresnayîs (n)",
    "y": " b. qirçnayîş (n)"
  },
  {
    "x": "qireydere (n)",
    "y": " b. greydere (m)"
  },
  {
    "x": "qirêj (n)",
    "y": " kir "
  },
  {
    "x": "qirêj bîyene",
    "y": " kirlenmek"
  },
  {
    "x": "qirêj kerdene",
    "y": " kirletmek"
  },
  {
    "x": "qirêjbîyayîş (n)",
    "y": " kirlenme "
  },
  {
    "x": "qirêjênî (m)",
    "y": " kirlilik"
  },
  {
    "x": "qirêjin, -e",
    "y": " kirli "
  },
  {
    "x": "qirêjin bîyene",
    "y": " kirlenmek"
  },
  {
    "x": "qirêjinbîyayîş (n)",
    "y": " kirlenme "
  },
  {
    "x": "qirêjî (m)",
    "y": " kirlilik "
  },
  {
    "x": "qirêjîye (m)",
    "y": " kirlilik"
  },
  {
    "x": "qirêjker, -e",
    "y": " kirletici"
  },
  {
    "x": "qirêjkerdiş (n)",
    "y": " kirletme"
  },
  {
    "x": "qirêncle (n)",
    "y": " b. qirîncele (n)"
  },
  {
    "x": "qirf û qalî",
    "y": " b. qal û qirfî"
  },
  {
    "x": "qirfûqalî (zh)",
    "y": " b. qalûqirfî (zh)"
  },
  {
    "x": "qirik (m)",
    "y": " b. qirrike (m)"
  },
  {
    "x": "qirike (m)",
    "y": " b. qirrike (m)"
  },
  {
    "x": "qirikedekewtiş (n)",
    "y": " b. qirikedekewtiş (n)"
  },
  {
    "x": "qiriki (m)",
    "y": " b. qirrike (m)"
  },
  {
    "x": "qirim (m)",
    "y": " b. qirime (m)"
  },
  {
    "x": "qirim kerdene",
    "y": " tırpanlamak"
  },
  {
    "x": "qirimcî, -ye",
    "y": " b. qirimkêş, -e"
  },
  {
    "x": "qirimcîtî (m)",
    "y": " b. qirimçîtî (m)"
  },
  {
    "x": "qirimcîyê (m)",
    "y": " b. qirimkêşîye (m)"
  },
  {
    "x": "qirimçî, -ye",
    "y": " b. qirimkêş, -e"
  },
  {
    "x": "qirimçîtî (m)",
    "y": " tırpancılık"
  },
  {
    "x": "qirimçîyê (m)",
    "y": " b. qirimkêşîye (m)"
  },
  {
    "x": "qirime (m)",
    "y": " tırpan"
  },
  {
    "x": "qirimi (m)",
    "y": " b. qirime (m)"
  },
  {
    "x": "qirimkêş, -e",
    "y": " tırpancı"
  },
  {
    "x": "qirimkêşî (m)",
    "y": " tırpancılık"
  },
  {
    "x": "qirimkêşîye (m)",
    "y": " tırpancılık"
  },
  {
    "x": "qirincêle (n)",
    "y": " b. qirîncele (n)"
  },
  {
    "x": "qirincîyele (n)",
    "y": " b. qirîncele (n)"
  },
  {
    "x": "qiring",
    "y": " b. quling, -e"
  },
  {
    "x": "qiriqqale (n)",
    "y": " bir tüfek çeşidi"
  },
  {
    "x": "qiriqqela (n)",
    "y": " b. qiriqqale (n)"
  },
  {
    "x": "qirisnayene",
    "y": " b. qirçnayene"
  },
  {
    "x": "qirisnayîş (n)",
    "y": " b. qirçnayîş (n)"
  },
  {
    "x": "qirişnayene",
    "y": " b. qirçnayene"
  },
  {
    "x": "qirişnayîş (n)",
    "y": " b. qirçnayîş (n)"
  },
  {
    "x": "qirîçiknayene",
    "y": " b. qirçnayene"
  },
  {
    "x": "qirîçiknayîş (n)",
    "y": " b. qirçnayîş (n)"
  },
  {
    "x": "qirîncali (n)",
    "y": " b. qirîncele (n) "
  },
  {
    "x": "qirîncle (n)",
    "y": " b. qirîncele (n) "
  },
  {
    "x": "qirîncele (n)",
    "y": " kurbağa"
  },
  {
    "x": "qirîz (n)",
    "y": " b. krîz (n)"
  },
  {
    "x": "qirkerdiş (n)",
    "y": " katliam, soykırım"
  },
  {
    "x": "qirm (n)",
    "y": " 1)gövde, ağaç gövdesi\r\n~qirmê dare (n): ağaç gövdesi\r\n~2)kök \r\n~3)kütük (toprakta kalan kütük)"
  },
  {
    "x": "qirmê dare (n)",
    "y": " ağaç gövdesi"
  },
  {
    "x": "qirme (n)",
    "y": " kırma (tüfek çeşidi)"
  },
  {
    "x": "qirmi (n)",
    "y": " b. qirme (n)"
  },
  {
    "x": "qirmiç (n)",
    "y": " 1)kırışık (kırışıklık)\r\n~2)büzgü"
  },
  {
    "x": "qirmiçey (m)",
    "y": " b. qirmiçîye (m)"
  },
  {
    "x": "qirmiçê (m)",
    "y": " b. qirmiçîye (m)"
  },
  {
    "x": "qirmiçênî (m)",
    "y": " kırışıklık"
  },
  {
    "x": "qirmiçik, -e",
    "y": " 1)kırışık (kırışıklık)\r\n~2)buruşuk\r\n~3)büzük\r\n~4)büzgü"
  },
  {
    "x": "qirmiçikayene",
    "y": " b. qirmiçikîyayene"
  },
  {
    "x": "qirmiçikey (m)",
    "y": " b. qirmiçikîye (m)"
  },
  {
    "x": "qirmiçikê (m)",
    "y": " b. qirmiçikîye (m)"
  },
  {
    "x": "qirmiçikênî (m)",
    "y": " kırışıklık"
  },
  {
    "x": "qirmiçikin, -e",
    "y": " 1)kırışık (kırışmış olan)\r\n~2)büzük"
  },
  {
    "x": "qirmiçikinî (m)",
    "y": " kırışıklık"
  },
  {
    "x": "qirmiçikinîye (m)",
    "y": " kırışıklık"
  },
  {
    "x": "qirmiçikîyayene",
    "y": " 1)kırışmak\r\n~2)buruşmak\r\n~3)büzülmek"
  },
  {
    "x": "qirmiçikîyayîş (n)",
    "y": " 1)buruşma\r\n~2)büzülme"
  },
  {
    "x": "qirmiçikîye (m)",
    "y": " kırışıklık"
  },
  {
    "x": "qirmiçîyaya (m)",
    "y": " 1)kırışık (kırışmış olan)\r\n~2)buruşuk\r\n~3)büzük"
  },
  {
    "x": "qirmiçîyaye (n)",
    "y": " 1)kırışık (kırışmış olan)\r\n~2)buruşuk\r\n~3)büzük"
  },
  {
    "x": "qirmiçîyayene",
    "y": " 1)kırışmak\r\n~2)buruşmak\r\n~3)büzülmek"
  },
  {
    "x": "qirmiçîyayeyî (m)",
    "y": " kırışıklık"
  },
  {
    "x": "qirmiçîyayê (m)",
    "y": " b. qirmiçîyayîye (m)"
  },
  {
    "x": "qirmiçîyayênî (m)",
    "y": " kırışıklık"
  },
  {
    "x": "qirmiçîyayî (m)",
    "y": " b. qirmiçîyayîye (m)"
  },
  {
    "x": "qirmiçîyayî, -ye",
    "y": " 1)kırışık (kırışmış olan)\r\n~2)büzük"
  },
  {
    "x": "qirmiçîyayîş (n)",
    "y": " buruşma, büzülme"
  },
  {
    "x": "qirmiçîyayîye (m)",
    "y": " kırışık (kırışmış olan), buruşuk, büzük"
  },
  {
    "x": "qirmiçîye (m)",
    "y": " kırışıklık"
  },
  {
    "x": "qirmiçnaya (m)",
    "y": " kırışık (kırışmış olan)"
  },
  {
    "x": "qirmiçnaye (n)",
    "y": " kırışık (kırışmış olan)"
  },
  {
    "x": "qirmiçnayene",
    "y": " 1)kırıştırmak\r\n~2)buruşturmak\r\n~3)büzmek"
  },
  {
    "x": "qirmiçnayeni",
    "y": " b. qirmiçnayene"
  },
  {
    "x": "qirmiçnayeyî (m)",
    "y": " kırışıklık"
  },
  {
    "x": "qirmiçnayê (m)",
    "y": " b. qirmiçnayîye (m)"
  },
  {
    "x": "qirmiçnayênî (m)",
    "y": " kırışıklık"
  },
  {
    "x": "qirmiçnayîş (n)",
    "y": " büzme"
  },
  {
    "x": "qirmiçnayîye (m)",
    "y": " kırışık (kırışmış olan)"
  },
  {
    "x": "qirmizî (m)",
    "y": " bir üzüm çeşidi"
  },
  {
    "x": "qirmoçik, -e-I",
    "y": " b. qirmiçik, -e"
  },
  {
    "x": "qirmoçik bîyene",
    "y": " b. qirmiçîyayene"
  },
  {
    "x": "qirmoçik kerdene",
    "y": " b. qirmiçnayene"
  },
  {
    "x": "qirmoçik-II (n)",
    "y": " b. qirmûçik (n)"
  },
  {
    "x": "qirmoçik bîyene",
    "y": " b. qirmûçik bîyene"
  },
  {
    "x": "qirmoçik kerdene",
    "y": " b. qirmûçik kerdene"
  },
  {
    "x": "qirmoçikbîyayîş (n)",
    "y": " b. qirmûçikbîyayîş (n)"
  },
  {
    "x": "qirmoçikênî n)",
    "y": " b. qirmiçikênî (m)"
  },
  {
    "x": "qirmoçikin, -e",
    "y": " b. qirmiçikin, -e"
  },
  {
    "x": "qirmoçikinî (m)",
    "y": " b. qirmiçikinî (m)"
  },
  {
    "x": "qirmoçikinîye (m)",
    "y": " b. qirmiçikinîye (m)"
  },
  {
    "x": "qirmoçikkerdiş (n)",
    "y": " b. qirmûçikkerdiş (n)"
  },
  {
    "x": "qirmoçîyaya (m)",
    "y": " b. qirmiçîyaya (m)"
  },
  {
    "x": "qirmoçîyaye (n)",
    "y": " b. qirmiçîyaye (n)"
  },
  {
    "x": "qirmoçîyayene",
    "y": " b. qirmiçîyayene"
  },
  {
    "x": "qirmoçîyayeyî (m)",
    "y": " b. qirmiçîyayeyî (m)"
  },
  {
    "x": "qirmoçîyayê (m)",
    "y": " b. qirmiçîyayîye (m)"
  },
  {
    "x": "qirmoçîyayêne",
    "y": " b. qirmiçîyayene"
  },
  {
    "x": "qirmoçîyayênî (m)",
    "y": " b. qirmiçîyayênî (m)"
  },
  {
    "x": "qirmoçîyayîye (m)",
    "y": " b. qirmiçîyayîye (m)"
  },
  {
    "x": "qirmoçnaya (m)",
    "y": " b. qirmiçnaya (m)"
  },
  {
    "x": "qirmoçnaye (n)",
    "y": " b. qirmiçnaye (n)"
  },
  {
    "x": "qirmoçnayene",
    "y": " b. qirmiçnayene"
  },
  {
    "x": "qirmoçnayeyî (m)",
    "y": " b. qirmiçnayeyî (m)"
  },
  {
    "x": "qirmoçnayê (m)",
    "y": " b. qirmiçnayîye (m)"
  },
  {
    "x": "qirmoçnayênî (m)",
    "y": " b. qirmiçnayênî (m)"
  },
  {
    "x": "qirmoçnayîş (n)",
    "y": " b. qirmiçnayîş (n)"
  },
  {
    "x": "qirmoçnayîye (m)",
    "y": " b. qirmiçnayîye (m)"
  },
  {
    "x": "qirmoçyaya (m)",
    "y": " b. qirmiçîyaya (m)"
  },
  {
    "x": "qirmoçyaye (n)",
    "y": " b. qirmiçîyaye (n)"
  },
  {
    "x": "qirmoçyayene",
    "y": " b. qirmiçîyayene"
  },
  {
    "x": "qirmoçyayeyî (m)",
    "y": " b. qirmiçîyayeyî (m)"
  },
  {
    "x": "qirmoçyayê (m)",
    "y": " b. qirmiçîyayîye (m)"
  },
  {
    "x": "qirmoçyayênî (m)",
    "y": " b. qirmiçîyayênî (m)"
  },
  {
    "x": "qirmoçyayî, -ye",
    "y": " b. qirmiçîyayî, -ye"
  },
  {
    "x": "qirmoçyayîş (n)",
    "y": " b. qirmiçîyayîş (n)"
  },
  {
    "x": "qirmoçyayîye (m)",
    "y": " b. qirmiçîyayîye (m)"
  },
  {
    "x": "qirmûçik (n)",
    "y": " çimdik"
  },
  {
    "x": "qirmûçik bîyene",
    "y": " çimdiklenmek, çimdik atılmak"
  },
  {
    "x": "qirmûçik kerdene",
    "y": " çimdiklemek, çimdik atmak, çimdik basmak "
  },
  {
    "x": "qirmûçikbîyayîş (n)",
    "y": " çimdiklenme"
  },
  {
    "x": "qirmûçikkerdiş (n)",
    "y": " çimdikleme, çimdik atma, çimdik basma"
  },
  {
    "x": "qirnaqoçike (m)",
    "y": " tahterevalli, çöğüncek"
  },
  {
    "x": "qirnase (n)",
    "y": " b. qerase (n)"
  },
  {
    "x": "qirnata (n)",
    "y": " b. qirnete (n)"
  },
  {
    "x": "qirnate (n)",
    "y": " b. qirnete (n)"
  },
  {
    "x": "qirnaw kerdene",
    "y": " kösnümek (kedi için)  "
  },
  {
    "x": "qirnawe (m)",
    "y": " b. qirnawî (m)"
  },
  {
    "x": "qirnawî (m)",
    "y": " kösnük (kedi için)"
  },
  {
    "x": "qirnawkerdiş (n)",
    "y": " kösnüme (kedi için)"
  },
  {
    "x": "qirne (n)",
    "y": " köşe, iç köşe, dış köşe"
  },
  {
    "x": "qirneta (m)",
    "y": " b. qirnete (n)"
  },
  {
    "x": "qirnete (n)",
    "y": " klarnet"
  },
  {
    "x": "qirnetecen, -e",
    "y": " klarnetçi"
  },
  {
    "x": "qirni (n)",
    "y": " b. qirne (n)"
  },
  {
    "x": "qirnîk (n)",
    "y": " kene"
  },
  {
    "x": "qiro çing",
    "y": " bembeyaz, apak"
  },
  {
    "x": "qirp (m)",
    "y": " b. qirpe (m)"
  },
  {
    "x": "qirp kewteni",
    "y": " b. qirpe kewtene"
  },
  {
    "x": "qirp kotene",
    "y": " b. qirpe kewtene"
  },
  {
    "x": "qirpe (m)",
    "y": " kuluçka, gurk"
  },
  {
    "x": "qirpe kewtene",
    "y": " kuluçkaya yatmak, gurka yatmak"
  },
  {
    "x": "qirpi (m)",
    "y": " b. qirpe (m)"
  },
  {
    "x": "qirpi kewtene",
    "y": " b. qirpe kewtene"
  },
  {
    "x": "qirpiçike (m)",
    "y": " gırtlak"
  },
  {
    "x": "qirpikî ra",
    "y": " dopdolu, ağzına kadar"
  },
  {
    "x": "qirqir (n)",
    "y": " ağız dalaşı, ağız kavgası"
  },
  {
    "x": "qirqir kerdene",
    "y": " ağız dalaşında bulunmak, ağız kavgası yapmak, ağız münakaşası yapmak, sertçe tartışmak"
  },
  {
    "x": "qirqirîyayîne",
    "y": " b. qilqilîyayene"
  },
  {
    "x": "qirqirîyayîş (n)",
    "y": " b. qilqilîyayîş (n)"
  },
  {
    "x": "qirqirkerdiş (n)",
    "y": " ağız dalaşında bulunma, ağız kavgası yapma, ağız münakaşası yapma, sertçe tartışma"
  },
  {
    "x": "qirqişun (n)",
    "y": " b. qerşune (m)"
  },
  {
    "x": "qirquşin (n)",
    "y": " b. qerşune (m)"
  },
  {
    "x": "qirr (m)",
    "y": " b. qirrike (m)"
  },
  {
    "x": "qirrayene",
    "y": " b. qirayene"
  },
  {
    "x": "qirrayêne",
    "y": " b. qirayene"
  },
  {
    "x": "qirrayîş (n)",
    "y": " b. qirayîş (n)"
  },
  {
    "x": "qirri (m)",
    "y": " b. qirrike (m)"
  },
  {
    "x": "qirri dekewtene",
    "y": " b. qirrike dekewtene"
  },
  {
    "x": "qirridekewtiş (n)",
    "y": " b. qirrikedekewtiş (n)"
  },
  {
    "x": "qirrik (m)",
    "y": " b. qirrike (m)"
  },
  {
    "x": "qirrike (m)",
    "y": " boğaz"
  },
  {
    "x": "qirrika yewbînî girewtene",
    "y": " boğuşmak"
  },
  {
    "x": "qirrika yewbînî tepîştene",
    "y": " boğuşmak"
  },
  {
    "x": "qirrike de",
    "y": " diz boyu (çok miktarda)"
  },
  {
    "x": "qirrike dekewtene",
    "y": " sesi kısılmak"
  },
  {
    "x": "qirrike pirr berme bîyene",
    "y": " ağlamaklı olmak"
  },
  {
    "x": "qirrike pirr bermebîyayîş (n)",
    "y": " ağlamaklı olma, ağlamsı olma, ağlamsama, doluklama, dolukma"
  },
  {
    "x": "qirrikayewbînîgirewtiş (n)",
    "y": " boğuşma"
  },
  {
    "x": "qirrikayewbînîtepîştiş (n)",
    "y": " boğuşma"
  },
  {
    "x": "qirrikedekewtiş (n)",
    "y": " ses kısılması, ses kısıklığı"
  },
  {
    "x": "qirriki (m)",
    "y": " b. qirrike (m)"
  },
  {
    "x": "qirriki dekotene",
    "y": " b. qirrike dekewtene"
  },
  {
    "x": "qirriki pirr bermi bîyayene",
    "y": " b. qirrike pirr berme bîyene"
  },
  {
    "x": "qirriki pirr bermibîyayîş (n)",
    "y": " b. qirrike pirr bermebîyayîş (n)"
  },
  {
    "x": "qirrikidekotiş (n)",
    "y": " b. qirikedekewtiş (n)"
  },
  {
    "x": "qirrkerdiş (n)",
    "y": " b. qirkerdiş (n)"
  },
  {
    "x": "qirs (n)",
    "y": " b. qirş (n)"
  },
  {
    "x": "qirsûqal (m)",
    "y": " b.  qirşûqale (m)"
  },
  {
    "x": "qirş (n)",
    "y": " çöp"
  },
  {
    "x": "qirş-qal (m)",
    "y": " b. qirş-qale (m)"
  },
  {
    "x": "qirş-qale (m)",
    "y": " çerçöp"
  },
  {
    "x": "qirş-qol (m)",
    "y": " b. qirş-qale (m)"
  },
  {
    "x": "qirşayene",
    "y": " içmek (sigara vb. için)"
  },
  {
    "x": "qirşik (n)",
    "y": " çöp"
  },
  {
    "x": "qirşike (m)",
    "y": " b. qirçike-I (m)"
  },
  {
    "x": "qirşiki (m)",
    "y": " b. qirçike-I (m)"
  },
  {
    "x": "qirşûqal (m)",
    "y": " b.  qirşûqale (m)"
  },
  {
    "x": "qirşûqale (m)",
    "y": " çerçöp"
  },
  {
    "x": "qirşûqali (m)",
    "y": " b.  qirşûqale (m)"
  },
  {
    "x": "qirtasîyeroş, -e",
    "y": " kırtasiyeci"
  },
  {
    "x": "qirtike (m)",
    "y": " b. qirrike (m)"
  },
  {
    "x": "qirtiki (m)",
    "y": " b. qirrike (m)"
  },
  {
    "x": "qirtke (m)",
    "y": " b. qirrike (m)"
  },
  {
    "x": "qirtqirt (n)",
    "y": " ağız kavgası"
  },
  {
    "x": "qirva (n)",
    "y": " b. qurban (n)"
  },
  {
    "x": "qirvan (n)",
    "y": " b. qurban (n)"
  },
  {
    "x": "qirvan bîyayene",
    "y": " b. qurban bîyene"
  },
  {
    "x": "qirvan kerdene",
    "y": " b. qurban kerdene"
  },
  {
    "x": "qirvane (m)",
    "y": " b. qurban (n)"
  },
  {
    "x": "Qirxizîstan (n)",
    "y": " Kırgızistan"
  },
  {
    "x": "qis, -e",
    "y": " b. qij, -e-I"
  },
  {
    "x": "qisa (m)",
    "y": " söz, laf"
  },
  {
    "x": "qisaya ... birnayene",
    "y": " sözünü kesmek"
  },
  {
    "x": "qisaya dejdayoxe (m)",
    "y": " acı söz"
  },
  {
    "x": "qisaya girçikine (m)",
    "y": " incitici söz"
  },
  {
    "x": "qisaya mi cemat ra teber",
    "y": " sözüm meclisten dışarı, sözüm yabana"
  },
  {
    "x": "qisey kerdene",
    "y": " konuşmak"
  },
  {
    "x": "qisawet (n)",
    "y": " b. qesawet (n)"
  },
  {
    "x": "qisawetdayox, -e",
    "y": " b. qesawetdayox, -e"
  },
  {
    "x": "qisawete (m)",
    "y": " b. qesawet (n)"
  },
  {
    "x": "qisawete kerdene",
    "y": " b. qesawet kerdene"
  },
  {
    "x": "qisawetekerdiş (n)",
    "y": " b. qesawetkerdiş (n)"
  },
  {
    "x": "qisawetin, -e",
    "y": "b. qesawetin, -e"
  },
  {
    "x": "qisawetinê (m)",
    "y": " b. qesawetinî (m), qesawetinîye (m)"
  },
  {
    "x": "qisawetinî (m)",
    "y": " b. qesawetinî (m), qesawetinîye (m)"
  },
  {
    "x": "qisawetinîye (m)",
    "y": " b. qesawetinî (m), qesawetinîye (m)"
  },
  {
    "x": "qisawu (n)",
    "y": " b. qeşawî (n)"
  },
  {
    "x": "qisawul (n)",
    "y": " b. seqavêl (n)"
  },
  {
    "x": "qise (n)",
    "y": " b. qisa (m)"
  },
  {
    "x": "qise kerdene",
    "y": " b. qisey kerdene"
  },
  {
    "x": "qisê kerdene",
    "y": " b. qisey kerdene"
  },
  {
    "x": "qisekerdiş (n)",
    "y": " b. qiseykerdiş (n)"
  },
  {
    "x": "qiseykerdiş (n)",
    "y": " konuşma"
  },
  {
    "x": "qiseykerdişê abîyayîşî (n)",
    "y": " açılış konuşması"
  },
  {
    "x": "qiseykerdişê akerdişî (n)",
    "y": " açış konuşması"
  },
  {
    "x": "qiseykerdişê rakerdişî (n)",
    "y": " açış konuşması"
  },
  {
    "x": "qiseykerdox, -e",
    "y": " konuşmacı"
  },
  {
    "x": "qisêkerdiş (n)",
    "y": " b. qiseykerdiş (n)"
  },
  {
    "x": "qisi (n)",
    "y": " b. qisa (m)"
  },
  {
    "x": "qisik (n)",
    "y": " b. qirşik (n)"
  },
  {
    "x": "qisim (n)",
    "y": " bölüm, bap"
  },
  {
    "x": "qisimî (zh)",
    "y": " aksam, bölümler"
  },
  {
    "x": "qisir (n)",
    "y": " kısır (yiyecek)"
  },
  {
    "x": "qiskek, -e",
    "y": " b. qij, -e -I"
  },
  {
    "x": "qisle (n)",
    "y": " çelmik, kesmik (başakla karışık iri saman) "
  },
  {
    "x": "Qisle (n)",
    "y": " Nazımiye "
  },
  {
    "x": "qisli (n)",
    "y": " b. qisle (n)"
  },
  {
    "x": "qislik (n)",
    "y": " çelmik, kesmik (başakla karışık iri saman)"
  },
  {
    "x": "qism (n)",
    "y": " b. qisim (n)"
  },
  {
    "x": "qisqance (m)",
    "y": " cımbız"
  },
  {
    "x": "qisqonci (m)",
    "y": " b. qisqance (m)"
  },
  {
    "x": "qisqûnc (m)",
    "y": " b. qisqance (m)"
  },
  {
    "x": "qisre (n)",
    "y": " b. qisle (n)"
  },
  {
    "x": "qisrik (n)",
    "y": " b. qislik (n)"
  },
  {
    "x": "qiş (n)",
    "y": " kırağı\r\n~Qiş girê da. (Kırağı düştü./Kırağı yağdı.)\r\n~\t\r\n~Qişî da piro. (Kırağı çaldı./Kırağı vurdu.)"
  },
  {
    "x": "qişa (n)",
    "y": " b. qeşa-II (n)"
  },
  {
    "x": "qişawi (n)",
    "y": " b. qeşawî (n)"
  },
  {
    "x": "qişawî (n)",
    "y": " b. qeşawî (n)"
  },
  {
    "x": "qişawun (n)",
    "y": " b. qeşawî (n)"
  },
  {
    "x": "qişik (n)",
    "y": " b. qirşik (n)"
  },
  {
    "x": "qişkek, -e",
    "y": " b. qij, -e -I"
  },
  {
    "x": "qişqone (n)",
    "y": " b. quşxane (n)"
  },
  {
    "x": "qişqûne (n)",
    "y": " b. quşxane (n)"
  },
  {
    "x": "qiştayêne",
    "y": " b. qîştene"
  },
  {
    "x": "qiştayîş (n)",
    "y": " b. qîştiş (n)"
  },
  {
    "x": "qit (n)",
    "y": " kıti (hıyara benzeyen bir bitki ürünü), küte"
  },
  {
    "x": "qita (m)",
    "y": " kıta, ana kara"
  },
  {
    "x": "qite (m)",
    "y": " b. qita (m)"
  },
  {
    "x": "qiti (m)",
    "y": " b. qit (n)"
  },
  {
    "x": "qitik (n)",
    "y": " b. qit (n)"
  },
  {
    "x": "qitil (n)",
    "y": " b. qetil (n) "
  },
  {
    "x": "qitil kerdene",
    "y": " b. qetil kerdene"
  },
  {
    "x": "qitirbe (n)",
    "y": " b. qetrîbe (n)"
  },
  {
    "x": "qitirbil (n)",
    "y": " belin (bel aracının) üzerine ayak konan parçası"
  },
  {
    "x": "qitl (n)",
    "y": " b. qetil (n)"
  },
  {
    "x": "qitl kerdene",
    "y": " b. qetil kerdene"
  },
  {
    "x": "qitqit (n)",
    "y": " gıdaklama sesi"
  },
  {
    "x": "qitqit kerdene",
    "y": " gıdaklamak"
  },
  {
    "x": "qitqitkerdiş (n)",
    "y": " gıdaklama"
  },
  {
    "x": "qitrîbe (n)",
    "y": " b. qetrîbe (n)"
  },
  {
    "x": "qiv (m)",
    "y": " b. qibe (m)"
  },
  {
    "x": "qivi (m)",
    "y": " b. qibe (m)"
  },
  {
    "x": "qiwarme (n)",
    "y": " b. qawirme (n)"
  },
  {
    "x": "qiwet (n)",
    "y": " b. qewet (n)"
  },
  {
    "x": "Qiwet bo.",
    "y": " b. Qewet bo."
  },
  {
    "x": "qiz, -e",
    "y": " b. qij, -e-I"
  },
  {
    "x": "qizbane (m)",
    "y": " çitlembik, melengiç"
  },
  {
    "x": "qizbanêre (m)",
    "y": " çitlembik ağacı, melengiç ağacı"
  },
  {
    "x": "qizbanêri (m)",
    "y": " b. qizbanêre (m)"
  },
  {
    "x": "qizbani (m)",
    "y": " b. qizbane (m)"
  },
  {
    "x": "qizbanîr (m)",
    "y": " b. qizbanêre (m)"
  },
  {
    "x": "qizbonêri (m)",
    "y": " b. qizbanêre (m)"
  },
  {
    "x": "qizboni (m)",
    "y": " b. qizbane (m)"
  },
  {
    "x": "qizbûn (m)",
    "y": " b. qizbane (m)"
  },
  {
    "x": "qizbûnêri (m)",
    "y": " b. qizbanêre (m)"
  },
  {
    "x": "qizdane (m)",
    "y": " kösnük (dişi köpek için)  "
  },
  {
    "x": "qizdane bîyene",
    "y": " kösnümek (dişi köpek vb. için)"
  },
  {
    "x": "qizdanebîyayîş (n)",
    "y": " kösnüme (dişi köpek vb. için)"
  },
  {
    "x": "qizek",
    "y": " b. qij, -e-I"
  },
  {
    "x": "qizik (m)",
    "y": " b. qijike (m)"
  },
  {
    "x": "qizike (m)",
    "y": " b. qijike (m)"
  },
  {
    "x": "qizilbaş, -e",
    "y": " Alevi "
  },
  {
    "x": "qizilbaşênî (m)",
    "y": " Alevilik"
  },
  {
    "x": "qizilbaşîye (m)",
    "y": " Alevilik"
  },
  {
    "x": "qizilbaştîye (m)",
    "y": " Alevilik"
  },
  {
    "x": "qizilnaya (m)",
    "y": " b. qijilnaya (m)"
  },
  {
    "x": "qizilnaye (n)",
    "y": " b. qijilnaye (n) "
  },
  {
    "x": "qizilnayene",
    "y": " b. qijilnayene"
  },
  {
    "x": "qizilnayîş (n)",
    "y": " b. qijilnayîş (n)"
  },
  {
    "x": "qizilnayîye (m)",
    "y": " b. qijilnayîye (m)"
  },
  {
    "x": "qizle (n)",
    "y": " b. qisle (n)"
  },
  {
    "x": "qizli (n)",
    "y": " b. qisle (n)"
  },
  {
    "x": "qiznawi (m)",
    "y": " b. qizbane (m)"
  },
  {
    "x": "qiznawî (m)",
    "y": " b. qizbane (m)"
  },
  {
    "x": "qiznawêri (m)",
    "y": " b. qizbanêre (m)"
  },
  {
    "x": "qizvan-I (m)",
    "y": " b. qizbane (m)"
  },
  {
    "x": "qizvan-II (m)",
    "y": " b. qizdane (m)"
  },
  {
    "x": "qizvan bîyayene",
    "y": " b. qizdane bîyene"
  },
  {
    "x": "qizvane bîyayene",
    "y": " b. qizdane bîyene"
  },
  {
    "x": "qizvanbîyayîş (n)",
    "y": " b. qizdanebîyayîş (n)"
  },
  {
    "x": "qizvane-I (m)",
    "y": " b. qizbane (m)"
  },
  {
    "x": "qizvane-II (m)",
    "y": " b. qizdane (m)"
  },
  {
    "x": "qizvanebîyayîş (n)",
    "y": " b. qizdanebîyayîş (n)"
  },
  {
    "x": "qizvanêr (m)",
    "y": " b. qizbanêre (m)"
  },
  {
    "x": "qizvineri (m)",
    "y": " b. qizbanêre (m)"
  },
  {
    "x": "qizvinêri (m)",
    "y": " b. qizbanêre (m)"
  },
  {
    "x": "qizvûn (m)",
    "y": " b. qizbane (m)"
  },
  {
    "x": "qizvûnêr (m)",
    "y": " b. qizbanêre (m)"
  },
  {
    "x": "qizwan (m)",
    "y": " b. qizbane (m)"
  },
  {
    "x": "qizwane (m)",
    "y": " b. qizbane (m)"
  },
  {
    "x": "qizwanêr (m)",
    "y": " b. qizbanêre (m)"
  },
  {
    "x": "qizwonêr (m)",
    "y": " b. qizbanêre (m)"
  },
  {
    "x": "qizwoni (m)",
    "y": " b. qizbane (m)"
  },
  {
    "x": "qî (m)",
    "y": " b. qine (m)"
  },
  {
    "x": "qîya dare (m)",
    "y": " b. qina dare (m)"
  },
  {
    "x": "qîyey dari (m)",
    "y": " b. qina dare (m)"
  },
  {
    "x": "qî ra",
    "y": " b. qine ra"
  },
  {
    "x": "qî",
    "y": " b. qet"
  },
  {
    "x": "qî-qî kerdene",
    "y": " ötmek (horoz için) "
  },
  {
    "x": "qî-qîkerdiş (n)",
    "y": " ötme (horoz için) "
  },
  {
    "x": "qîble (n)",
    "y": " b. qible (m)"
  },
  {
    "x": "qîçik (n)",
    "y": " ot burma işinde kullanılan bir çeşit çengel "
  },
  {
    "x": "qîda (n)",
    "y": " b. qinda (n)"
  },
  {
    "x": "qîdayey (m)",
    "y": " b. qindayî (m), qindayîye (m)"
  },
  {
    "x": "qîf, -e",
    "y": " 1)sıkı\r\n~2)çabuk"
  },
  {
    "x": "qîjayene",
    "y": " çığlık atmak, çığlığı basmak, çığlık koparmak, bağırmak"
  },
  {
    "x": "qîjayîş (n)",
    "y": " çığlık atma, çığlığı basma, çığlık koparma, bağırma"
  },
  {
    "x": "qîjbeyîş (n)",
    "y": " b. qîşbîyayîş (n)"
  },
  {
    "x": "qîjbîyaya (m)",
    "y": " b. qîşbîyaya (m)"
  },
  {
    "x": "qîjbîyaye (n)",
    "y": " b. qîşbîyaye (n)"
  },
  {
    "x": "qîjbîyayene",
    "y": " b. qîşbîyene"
  },
  {
    "x": "qîjbîyayêne",
    "y": " b. qîşbîyene"
  },
  {
    "x": "qîjbîyayîş (n)",
    "y": " b. qîşbîyayîş (n)"
  },
  {
    "x": "qîjbîyayîye (m)",
    "y": " b. qîşbîyayîye (m)"
  },
  {
    "x": "qîjbîyene",
    "y": " b. qîşbîyene"
  },
  {
    "x": "qîjî (m)",
    "y": " bağırtı, bağırış, çığlık"
  },
  {
    "x": "qîjî dekewtene",
    "y": " çığlık atmak, çığlığı basmak, çığlık koparmak"
  },
  {
    "x": "qîjîdekewtiş (n)",
    "y": " çığlık atma, çığlığı basma, çığlık koparma"
  },
  {
    "x": "qîjjayene",
    "y": " b. qîjayene"
  },
  {
    "x": "qîjjayîş (n)",
    "y": " b. qîjayîş (n)"
  },
  {
    "x": "qîjjî (m)",
    "y": " b. qîjî (m)"
  },
  {
    "x": "qîjjî dekewtene",
    "y": " b. qîjî dekewtene"
  },
  {
    "x": "qîjjî dekotene",
    "y": " b. qîjî dekewtene"
  },
  {
    "x": "qîjjîdekewtiş (n)",
    "y": " b. qîjîdekewtiş (n)"
  },
  {
    "x": "qîjjîdekotiş (n)",
    "y": " b. qîjîdekewtiş (n)"
  },
  {
    "x": "qîjkerda (m)",
    "y": " b. qîşkerda (m)"
  },
  {
    "x": "qîjkerde (n)",
    "y": " b. qîşkerde (n)"
  },
  {
    "x": "qîjkerdene",
    "y": " b. qîşkerdene "
  },
  {
    "x": "qîjkerdiş (n)",
    "y": " b. qîşkerdiş (n)"
  },
  {
    "x": "qîjkerdîye (n)",
    "y": " b. qîşkerdîye (m)"
  },
  {
    "x": "qîjnîk (n)",
    "y": " b. qijnik (n)"
  },
  {
    "x": "qîjqîjik (n)",
    "y": " b. qijqijok, -e"
  },
  {
    "x": "qîl-I (n)",
    "y": " küçük arı kovanı"
  },
  {
    "x": "qîlê mêsa hingimênî (n)",
    "y": " küçük arı kovanı"
  },
  {
    "x": "qîlê mêsan (n)",
    "y": " b. qîlê mêsa hingimênî (m) "
  },
  {
    "x": "qîl-II (n)",
    "y": " bahane"
  },
  {
    "x": "qîl-III (n)",
    "y": " b. qil (n)"
  },
  {
    "x": "qîle-I (m)",
    "y": " kütük (toprakta kalan kütük)"
  },
  {
    "x": "qîle-II (n)",
    "y": " b. qîl-II (n)"
  },
  {
    "x": "qîlik (m)",
    "y": " b. qêlike (m)"
  },
  {
    "x": "qîlike (m)",
    "y": " serçe parmak"
  },
  {
    "x": "qîlnaya (m)",
    "y": " b. qelênaya (m), qelênayîye (m)"
  },
  {
    "x": "qîlnaye (n)",
    "y": " b. qelênaye (n)"
  },
  {
    "x": "qîlnayene",
    "y": " b. qelênayene"
  },
  {
    "x": "qîlnayi (n)",
    "y": " b. qelênaye (n)"
  },
  {
    "x": "qîlnayîş (n)",
    "y": " b. qelênayîş (n)"
  },
  {
    "x": "qîlp (n)",
    "y": " köpekdişi"
  },
  {
    "x": "qîmê sereyî (n)",
    "y": " baş belası, başağrısı"
  },
  {
    "x": "qîmê serî (n)",
    "y": " b. qîmê sereyî (n)"
  },
  {
    "x": "qîm kerdene",
    "y": " yetmek, yeterli olmak"
  },
  {
    "x": "qîmet (n)",
    "y": " b. qîymet (n)"
  },
  {
    "x": "qîmkerdiş (n)",
    "y": " yetme, yeterli olma"
  },
  {
    "x": "qînat (m)",
    "y": " b. qînate (m)"
  },
  {
    "x": "qînate (m)",
    "y": " kile, ölçek (tahıl ölçmeye yarar kap), şinik"
  },
  {
    "x": "qînati (m)",
    "y": " b. qînate (m)"
  },
  {
    "x": "qînore (m)",
    "y": " b. kunêre (m)"
  },
  {
    "x": "qînt (m)",
    "y": " b. qînte (m)"
  },
  {
    "x": "qînte (m)",
    "y": " oyun merkezi (oyunda el değdirilmesi gereken direk, taş vb. veya içinden çıkılmaması gereken, sınırları belirlenmiş yer vb.)"
  },
  {
    "x": "qînti (m)",
    "y": " b. qînte (m)"
  },
  {
    "x": "qîqayene",
    "y": " ötmek (horoz için)"
  },
  {
    "x": "qîqayîş (n)",
    "y": " ötme (horoz için)"
  },
  {
    "x": "qîr (n)",
    "y": " zift"
  },
  {
    "x": "qîr bîyene",
    "y": " asfaltlanmak"
  },
  {
    "x": "qîr kerdene",
    "y": " asfaltlamak"
  },
  {
    "x": "qîr-wîrî",
    "y": " çığlık çığlığa"
  },
  {
    "x": "qîrayene",
    "y": " çığlık atmak, çığlığı basmak, çığlık koparmak, bağırmak"
  },
  {
    "x": "qîrayîş (n)",
    "y": " çığlık atma, çığlığı basma, çığlık koparma, bağırma"
  },
  {
    "x": "qîrbîyayîş (n)",
    "y": " asfaltlanma"
  },
  {
    "x": "qîrçiknayene",
    "y": " b. qirçnayene"
  },
  {
    "x": "qîrçiknayîş (n)",
    "y": " b. qirçnayîş (n)"
  },
  {
    "x": "qîrçinayeni",
    "y": " b. qirçnayene"
  },
  {
    "x": "qîrçinayîş (n)",
    "y": " b. qirçnayîş (n)"
  },
  {
    "x": "qîrçnayene",
    "y": " b. qirçnayene"
  },
  {
    "x": "qîrçnayîş (n)",
    "y": " b. qirçnayîş (n)"
  },
  {
    "x": "qîrencele (n)",
    "y": " b. qirîncele (n)"
  },
  {
    "x": "qîreqîr (m)",
    "y": " b. qîreqîre (m)"
  },
  {
    "x": "qîreqîr kerdene",
    "y": " b. qîreqîre kerdene"
  },
  {
    "x": "qîreqîre (m)",
    "y": " bağırtı, bağırış"
  },
  {
    "x": "qîreqîre kerdene",
    "y": " bağırmak"
  },
  {
    "x": "qîreqîrekerdiş (n)",
    "y": " bağırma"
  },
  {
    "x": "qîreqîrkerdiş (n)",
    "y": " b. qîreqîrekerdiş (n)"
  },
  {
    "x": "qîret (n)",
    "y": " b. xîret (n)"
  },
  {
    "x": "qîret kerdene",
    "y": " b. xîret kerdene"
  },
  {
    "x": "qîretkerdiş (n)",
    "y": " b. xîretkerdiş (n)"
  },
  {
    "x": "qîretkêş, -e",
    "y": " b. xîretkêş, -e"
  },
  {
    "x": "qîretkêşî (m)",
    "y": " b. xîretkêşî (m)"
  },
  {
    "x": "qîretkêşîye (m)",
    "y": " b. xîretkêşîye (m)"
  },
  {
    "x": "qîrênî (m)",
    "y": " bağırtı, bağırış, çığlık"
  },
  {
    "x": "qîrî (m)",
    "y": " bağırtı, bağırış, çığlık"
  },
  {
    "x": "qîrî dekewtene",
    "y": " çığlık atmak, çığlığı basmak, çığlık koparmak"
  },
  {
    "x": "qîrîdekewtiş (n)",
    "y": " çığlık atma, çığlığı basma, çığlık koparma"
  },
  {
    "x": "qîrîye (m)",
    "y": " bağırtı, bağırış, çığlık"
  },
  {
    "x": "qîrkerde (n)",
    "y": " asfalt"
  },
  {
    "x": "qîrkerdiş (n)",
    "y": " asfaltlama"
  },
  {
    "x": "qîrrayene",
    "y": " b. qîrayene"
  },
  {
    "x": "qîrrayîş (n)",
    "y": " b. qîrayîş (n)"
  },
  {
    "x": "qîrrênî (m)",
    "y": " b. qîrênî (m)"
  },
  {
    "x": "qîrrî (m)",
    "y": " b. qîrî (m)"
  },
  {
    "x": "qîrrî dekewtene",
    "y": " b. qîrî dekewtene"
  },
  {
    "x": "qîrrîdekewtiş (n)",
    "y": " b. qîrîdekewtiş (n)"
  },
  {
    "x": "qîrrîye (m)",
    "y": " b. qîrîye (m)"
  },
  {
    "x": "qîs (n)",
    "y": " b. qîş-II (n)"
  },
  {
    "x": "qîsawetdayox, -e",
    "y": " b. qesawetdayox, -e"
  },
  {
    "x": "qîsawete (m)",
    "y": " b. qesawet (n)"
  },
  {
    "x": "qîsawetin, -e",
    "y": " b. qesawetin, -e"
  },
  {
    "x": "qîsawetinê (m)",
    "y": " b. qesawetinî (m), qesawetinîye (m)"
  },
  {
    "x": "qîsawetinî (m)",
    "y": " b. qesawetinî (m), qesawetinîye (m)"
  },
  {
    "x": "qîsawetinîye (m)",
    "y": " b. qesawetinî (m), qesawetinîye (m)"
  },
  {
    "x": "qîstayêne",
    "y": " b. qîştene"
  },
  {
    "x": "qîstayîş (n)",
    "y": " b. qîştiş (n)"
  },
  {
    "x": "qîstene",
    "y": " b. qîştene"
  },
  {
    "x": "qîstiş (n)",
    "y": " b. qîştiş (n)"
  },
  {
    "x": "qîş-I (n)",
    "y": " yırtmaç"
  },
  {
    "x": "qîş-II (n)",
    "y": " 1)çalı parçası (küçük ağaç parçası)\r\n~2)iri talaş"
  },
  {
    "x": "qîşbeyîş (n)",
    "y": " b. qîşbîyayîş (n)"
  },
  {
    "x": "qîşbîyaya (m)",
    "y": " yırtık"
  },
  {
    "x": "qîşbîyaye (n)",
    "y": " yırtık"
  },
  {
    "x": "qîşbîyayene",
    "y": " b. qîşbîyene"
  },
  {
    "x": "qîşbîyayêne",
    "y": " b. qîşbîyene"
  },
  {
    "x": "qîşbîyayîş (n)",
    "y": " yırtılma"
  },
  {
    "x": "qîşbîyayîye (m)",
    "y": " yırtık"
  },
  {
    "x": "qîşbîyene",
    "y": " yırtılmak"
  },
  {
    "x": "qîşik (n)",
    "y": " çalı parçası (küçük ağaç parçası)"
  },
  {
    "x": "qîşin, -e",
    "y": " yırtmaçlı"
  },
  {
    "x": "qîşîk (n)",
    "y": " b. qîşik (n)"
  },
  {
    "x": "qîşkerda (m)",
    "y": " yırtık"
  },
  {
    "x": "qîşkerde (n)",
    "y": " yırtık"
  },
  {
    "x": "qîşkerdene",
    "y": " yırtmak"
  },
  {
    "x": "qîşkerdiş (n)",
    "y": " yırtma"
  },
  {
    "x": "qîşkerdîye (m)",
    "y": " yırtık"
  },
  {
    "x": "qîştayene",
    "y": " b. qîştene"
  },
  {
    "x": "qîştayîş (n)",
    "y": " b. qîştiş (n)"
  },
  {
    "x": "qîştene",
    "y": " ötmek (karga, saksağan, tavuk vb. için)\r\n~Qilancike qîştena. (Saksağan ötüyor.)"
  },
  {
    "x": "qîştiş (n)",
    "y": " ötme (karga, saksağan vb. için)"
  },
  {
    "x": "qît, -e-I",
    "y": " küçük "
  },
  {
    "x": "qît-II (n)",
    "y": " 1)kütük (kesilmiş ağaç gövdesi)\r\n~2)budak"
  },
  {
    "x": "Qîte (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "qîti (m)",
    "y": " b. qîte (m)"
  },
  {
    "x": "Qîti (m)",
    "y": " b. Qîte (m)"
  },
  {
    "x": "qîtik (n)",
    "y": " budak"
  },
  {
    "x": "qîy-I (m)",
    "y": " b. qine (m)"
  },
  {
    "x": "qîy ra",
    "y": " b. qine ra"
  },
  {
    "x": "qîya dare (m)",
    "y": " b. qina dare (m)"
  },
  {
    "x": "qîyey dari (m)",
    "y": " b. qina dare (m)"
  },
  {
    "x": "qîy-II",
    "y": " b. qey-I"
  },
  {
    "x": "qîyam (n)",
    "y": " b. qîyame (n)"
  },
  {
    "x": "qîyame (n)",
    "y": " yokuş"
  },
  {
    "x": "qîyameyo qij (n)",
    "y": " bayır"
  },
  {
    "x": "qîyda (n)",
    "y": " b. qinda (n)"
  },
  {
    "x": "qîydayey (m)",
    "y": " b. qindayî (m), qindayîye (m)"
  },
  {
    "x": "qîym kerdene",
    "y": " b. qîm kerdene"
  },
  {
    "x": "qîym kerdiş (n)",
    "y": " b. qîmkerdiş (n)"
  },
  {
    "x": "qîyma (m)",
    "y": " b. qîyme (n)"
  },
  {
    "x": "qîyme (n)",
    "y": " kıyma"
  },
  {
    "x": "qîymet (n)",
    "y": " 1)değer, kıymet\r\n~2)bedel"
  },
  {
    "x": "qîymet panayene",
    "y": " değerlendirmek, kıymetlendirmek"
  },
  {
    "x": "qîymetê xo vîndî kerdene",
    "y": " 1)değerini yitirmek\r\n~2)cılızlaşmak"
  },
  {
    "x": "qîymetpanayîş (n)",
    "y": " değerlendirme, kıymetlendirme"
  },
  {
    "x": "qîyt, -e",
    "y": " b. qît, -e-I"
  },
  {
    "x": "qlor, -i",
    "y": " b. gilover, -e"
  },
  {
    "x": "qoat (n)",
    "y": " b. qewet (n)"
  },
  {
    "x": "qobil (m)",
    "y": " b. qible (m)"
  },
  {
    "x": "qoc (n)",
    "y": " b. qoj (n)"
  },
  {
    "x": "qocag (m)",
    "y": " b. gojage (m)"
  },
  {
    "x": "qocak (m)",
    "y": " b. gojage (m)"
  },
  {
    "x": "qocik (n)",
    "y": " b. qocix (n)"
  },
  {
    "x": "qocix (n)",
    "y": " gocuk"
  },
  {
    "x": "qoç (n)",
    "y": " boynuz"
  },
  {
    "x": "qoç kerdene",
    "y": " boynuzlamak, süsmek"
  },
  {
    "x": "qoç piro dayene",
    "y": " boynuzlamak, süsmek"
  },
  {
    "x": "qoçî kerdene",
    "y": " b. qoç kerdene"
  },
  {
    "x": "qoçikêri (m)",
    "y": " b. qorçikêre (m)"
  },
  {
    "x": "qoçiki (m)",
    "y": " b. qorçe (m)"
  },
  {
    "x": "qoçin, -e",
    "y": " boynuzlu"
  },
  {
    "x": "qoçkêri (m)",
    "y": " b. qorçikêre (m)"
  },
  {
    "x": "qoerçîer (m)",
    "y": " b. qorçikêre (m)"
  },
  {
    "x": "qofik (n)",
    "y": " b. qafik (n)"
  },
  {
    "x": "qofik sero kerdene",
    "y": " b. qafik sero kerdene"
  },
  {
    "x": "qoj (n)",
    "y": " kütük (kesilmiş ağaç gövdesi)"
  },
  {
    "x": "qol-I (n)",
    "y": " 1)ceket (kadın için)\r\n~2)b. qolike (m)"
  },
  {
    "x": "qol-II (n)",
    "y": " 1)kol\r\n~2)taraf"
  },
  {
    "x": "qol çemirnayene",
    "y": " kolları çemremek, kolları sıvamak"
  },
  {
    "x": "qol wesemernayene",
    "y": " kolları çemremek, kolları sıvamak "
  },
  {
    "x": "qol, -e-III",
    "y": " 1)ucu kesik olan\r\n~2)kuyruğu kesik hayvan"
  },
  {
    "x": "qol bîyene",
    "y": " bir kısmı koparılıp bir kısmı bırakılmak"
  },
  {
    "x": "qol kerdene",
    "y": " 1)bir kısmını koparıp bir kısmını bırakmak\r\n~2)kuyruğunu kesmek"
  },
  {
    "x": "qol-IV (n)",
    "y": " b. qewl (n)"
  },
  {
    "x": "qol-V (n)",
    "y": " silahlı grup"
  },
  {
    "x": "qolbîyayîş (n)",
    "y": " bir kısmı koparılıp bir kısmı bırakılma"
  },
  {
    "x": "qoli (m)",
    "y": " b. qole (m)"
  },
  {
    "x": "qolik (n)",
    "y": " ceket (kadın için)"
  },
  {
    "x": "qolike (m)",
    "y": " gömlek, mintan"
  },
  {
    "x": "qoliki (m)",
    "y": " b. qolike (m)"
  },
  {
    "x": "qolikî (zh)",
    "y": " külot, kısa don, şort"
  },
  {
    "x": "qolinc-I (n)",
    "y": " amel, ishal, ötürük, sürgün"
  },
  {
    "x": "qolinc-II (n)",
    "y": " kulunç"
  },
  {
    "x": "qolince (m)",
    "y": " b. qulaç (n)"
  },
  {
    "x": "qolind, -e",
    "y": " b. qalind, -e"
  },
  {
    "x": "qolind (n)",
    "y": " b. qalind (n)"
  },
  {
    "x": "qolindîye (m)",
    "y": " b. qalindîye"
  },
  {
    "x": "qolkerdiş (n)",
    "y": " bir kısmını koparıp bir kısmını bırakma"
  },
  {
    "x": "qolo (n)",
    "y": " ucu kesik olan"
  },
  {
    "x": "qoltix (n)",
    "y": " b. qoltuxe (m)"
  },
  {
    "x": "qoltixe (m)",
    "y": " b. qoltuxe (m)"
  },
  {
    "x": "qoltixi (m)",
    "y": " b. qoltuxe (m)"
  },
  {
    "x": "qoltuxe (m)",
    "y": " koltuk"
  },
  {
    "x": "qoltuxi (m)",
    "y": " b. qoltuxe (m)"
  },
  {
    "x": "qolung",
    "y": " b. quling, -e"
  },
  {
    "x": "qolya (m)",
    "y": " b. kolye (m)"
  },
  {
    "x": "qolyaya zerrêne (m)",
    "y": " b. kolyeya zerrêne/zerrnêne (m)"
  },
  {
    "x": "qom-I (m)",
    "y": " b. qame (m)"
  },
  {
    "x": "qom-II (n)",
    "y": " b. qewm (n)"
  },
  {
    "x": "qomçik (n)",
    "y": " elbise kolu ağzı, giysi kolu ağzı, yenin bilek kısmı"
  },
  {
    "x": "qomçî (m)",
    "y": " b. qamçî (m)"
  },
  {
    "x": "qomçî kerdene",
    "y": " b. qamçî kerdene"
  },
  {
    "x": "qomçîyon var şonayene",
    "y": " b. qamçîyan ver şanayene"
  },
  {
    "x": "qomdarg, -i",
    "y": " b. qamderg, -e"
  },
  {
    "x": "qomi (m)",
    "y": " b. qame (m)"
  },
  {
    "x": "qomidîne (m)",
    "y": " b. komodîne (m)"
  },
  {
    "x": "qomir (n)",
    "y": " b. qamir (n)"
  },
  {
    "x": "qomit (n)",
    "y": " b. komut (n)"
  },
  {
    "x": "qomitan, -e",
    "y": " b. komutan, -e"
  },
  {
    "x": "qomîş (n)",
    "y": " b. qamîş (n)"
  },
  {
    "x": "qomodîne (m)",
    "y": " b. komodîne (m)"
  },
  {
    "x": "qomut (n)",
    "y": " b. komut (n)"
  },
  {
    "x": "qomutan, -e",
    "y": " kumandan, komutan"
  },
  {
    "x": "qona",
    "y": " b. qorna"
  },
  {
    "x": "qonat (n)",
    "y": " b. qanat (n)"
  },
  {
    "x": "qonax (n)",
    "y": " konak"
  },
  {
    "x": "qonc (n)",
    "y": " b. qoj (n)"
  },
  {
    "x": "qonç (n)",
    "y": " b. qoç (n)"
  },
  {
    "x": "qonçin, -e",
    "y": " b. qoçin, -e"
  },
  {
    "x": "qondere (n)",
    "y": " b. kundure-I (n)"
  },
  {
    "x": "qondire (n)",
    "y": " b. kundure-I (n)"
  },
  {
    "x": "qonik (n)",
    "y": " b. qanik (n)"
  },
  {
    "x": "qonik (m)",
    "y": " b. qanik (n)"
  },
  {
    "x": "qoniki (m)",
    "y": " b. qanik (n)"
  },
  {
    "x": "qonqile (n)",
    "y": " b. qanqile (n)"
  },
  {
    "x": "qonserwe (n)",
    "y": " b. konserve (n)"
  },
  {
    "x": "qontax (n)",
    "y": " b. kontakt (n)"
  },
  {
    "x": "qontir (n)",
    "y": " b. qantir (n) "
  },
  {
    "x": "qontir (m)",
    "y": " b. qantir (n) "
  },
  {
    "x": "qontiri (m)",
    "y": " b. qantir (n)"
  },
  {
    "x": "qontirleyîr (n)",
    "y": " b. qantirleyîr (n) "
  },
  {
    "x": "qontîl (n)",
    "y": " b. qotîl (n)"
  },
  {
    "x": "qontrol (n)",
    "y": " b. kontrol (n)"
  },
  {
    "x": "qontrol kerdene",
    "y": " b. kontrol kerdene"
  },
  {
    "x": "qontrolkerdiş (n)",
    "y": " b. kontrolkerdiş (n)"
  },
  {
    "x": "qonzi (m)",
    "y": " b. qaze (m)"
  },
  {
    "x": "qonzik (n)",
    "y": " b. qanzik (n)"
  },
  {
    "x": "qop, -e",
    "y": " 1)ucu kesik olan\r\n~2)eli veya ayağı sakat olan"
  },
  {
    "x": "qop (n)",
    "y": " kesik el veya kol"
  },
  {
    "x": "qopal, -e",
    "y": " eli veya kolu kesik olan kişi"
  },
  {
    "x": "qopalek, -e",
    "y": " eli veya kolu biraz kesik olan kişi"
  },
  {
    "x": "qoperatîf (m)",
    "y": " b. koperatîfe (m)"
  },
  {
    "x": "qoperatîfi (m)",
    "y": " b. koperatîfe (m)"
  },
  {
    "x": "qopo (n)",
    "y": " ucu kesik olan"
  },
  {
    "x": "qopya",
    "y": " b. kopya (m)"
  },
  {
    "x": "qoq-I (m)",
    "y": " b. qoqe (m)"
  },
  {
    "x": "qoq-II (n)",
    "y": " b. kox (n)"
  },
  {
    "x": "qoqa (m)",
    "y": " b. koka-I (m)"
  },
  {
    "x": "qoqe (m)",
    "y": " 1)baş, kafa\r\n~2)b. goge (m)"
  },
  {
    "x": "qoqi-I (m)",
    "y": " b. qoqe (m)"
  },
  {
    "x": "qoqi-II (m)",
    "y": " b. xag, -e"
  },
  {
    "x": "qor-I (n)",
    "y": " kez, kere, defa, sefer"
  },
  {
    "x": "no qor",
    "y": " bu kez, bu kere, bu defa, bu sefer"
  },
  {
    "x": "no qra",
    "y": " b. no qor"
  },
  {
    "x": "qorê",
    "y": " bir kez"
  },
  {
    "x": "qorêk",
    "y": " bir kez"
  },
  {
    "x": "qra",
    "y": " b. qor-I"
  },
  {
    "x": "qor-II (n)",
    "y": " b. qorr-I (n)"
  },
  {
    "x": "qor-III (m)",
    "y": " b. qor-I (n)"
  },
  {
    "x": "qorayene",
    "y": " böğürmek"
  },
  {
    "x": "qorayîş (n)",
    "y": " böğürme"
  },
  {
    "x": "qorç (n)",
    "y": " b. qoç (n)"
  },
  {
    "x": "qorçe (m)",
    "y": " ahlat"
  },
  {
    "x": "qorçêre (m)",
    "y": " ahlat ağacı"
  },
  {
    "x": "qorçikêre (m)",
    "y": " ahlat ağacı"
  },
  {
    "x": "qorçin, -e",
    "y": " b. qoçin, -e"
  },
  {
    "x": "qordele (n)",
    "y": " b. kurdele (n)"
  },
  {
    "x": "qordoni (m)",
    "y": " b. kordon (n)"
  },
  {
    "x": "qore-qor",
    "y": " b. qore-qore"
  },
  {
    "x": "qore-qor kardene",
    "y": " b. qore-qore kerdene"
  },
  {
    "x": "qore-qore",
    "y": " böğürtü"
  },
  {
    "x": "qore-qore kerdene",
    "y": " böğürmek"
  },
  {
    "x": "qore-qorekerdiş (n)",
    "y": " böğürme"
  },
  {
    "x": "qore-qorkerdiş (n)",
    "y": " b. qore-qorekerdiş (n)"
  },
  {
    "x": "qorênî (m)",
    "y": " böğürtü"
  },
  {
    "x": "qoricî",
    "y": " b. qorîcî, -ye"
  },
  {
    "x": "qorik (n)",
    "y": " b. qorrik (n)"
  },
  {
    "x": "qorî (m)",
    "y": " böğürtü"
  },
  {
    "x": "qorî kerdene",
    "y": " korumak, muhafaza etmek"
  },
  {
    "x": "qorîcî, -ye",
    "y": " korucu (orman, kır ya da köy bekçisi)"
  },
  {
    "x": "qorîkerdiş (n)",
    "y": " koruma, muhafaza"
  },
  {
    "x": "qorîye (m)",
    "y": " böğürtü"
  },
  {
    "x": "qorme (n)",
    "y": " b. qawirme (n)"
  },
  {
    "x": "qormi (n)",
    "y": " b. qawirme (n)"
  },
  {
    "x": "qorna-I",
    "y": " yine, gene, tekrar"
  },
  {
    "x": "qorna-II (m)",
    "y": " b. korna (m)"
  },
  {
    "x": "qorr-I (n)",
    "y": " uyluk, bacağın kalçadan dize kadar olan bölümü"
  },
  {
    "x": "qorr-II (n)",
    "y": " b. qor-I (n)"
  },
  {
    "x": "qorrayene",
    "y": " b. qorayene"
  },
  {
    "x": "qorrayîş (n)",
    "y": " b. qorayîş (n)"
  },
  {
    "x": "qorrênî (m)",
    "y": " b. qorênî (m)"
  },
  {
    "x": "qorri (n)",
    "y": " b. qorrî (n)"
  },
  {
    "x": "qorrik (n)",
    "y": " 1)kalça\r\n~2)sırt"
  },
  {
    "x": "qorrî-I (n)",
    "y": " koru"
  },
  {
    "x": "qorrî-II (m)",
    "y": " b. qorî (m)"
  },
  {
    "x": "qorrîye (m)",
    "y": " b. qorîye (m)"
  },
  {
    "x": "qorsan, -e",
    "y": " b. korsan, -e"
  },
  {
    "x": "qort (n)",
    "y": " b. qorrik (n)"
  },
  {
    "x": "qosp (n)",
    "y": " kalın ağaç gövdesi (kütük)"
  },
  {
    "x": "qot-I (n)",
    "y": " küçük tepe, tümsek"
  },
  {
    "x": "qot, -e-II",
    "y": " 1)ucu kesik olan\r\n~2)çolak, eli veya ayağı kesik olan kişi\r\n~3)kütük (toprakta kalan kütük)\r\n~4)kalın ağaç gövdesi"
  },
  {
    "x": "qot bîyene",
    "y": " bir kısmı koparılıp bir kısmı bırakılmak"
  },
  {
    "x": "qot kerdene",
    "y": " bir kısmını koparıp bir kısmını bırakmak"
  },
  {
    "x": "qot-III (n)",
    "y": " b. kot (n)"
  },
  {
    "x": "pantolê qotî (zh)",
    "y": " b. pantolê kotî (zh)"
  },
  {
    "x": "qotbîyayîş (n)",
    "y": " bir kısmı koparılıp bir kısmı bırakılma"
  },
  {
    "x": "qote-I (m)",
    "y": " 1)üzerinde et dövülen kütük\r\n~2)b. qot-II (n)"
  },
  {
    "x": "qote-II (m)",
    "y": " b. qotike (m)"
  },
  {
    "x": "qote-III (m)",
    "y": " b. qewet (n)"
  },
  {
    "x": "qotek (n)",
    "y": " b. qot-I (n)"
  },
  {
    "x": "qotey (m)",
    "y": " b. qotî (m)"
  },
  {
    "x": "qotê (m)",
    "y": " b. qotî (m)"
  },
  {
    "x": "qoti (m)",
    "y": " b. qot, -e"
  },
  {
    "x": "qotik (n)",
    "y": " b. qot-I (n)"
  },
  {
    "x": "qotike (m)",
    "y": " baş, kafa"
  },
  {
    "x": "qotî (m)",
    "y": " çolaklık"
  },
  {
    "x": "qotîl (n)",
    "y": " kurumuş kütük parçası"
  },
  {
    "x": "qotkerdiş (n)",
    "y": " bir kısmını koparıp bir kısmını bırakma"
  },
  {
    "x": "qoto (n)",
    "y": " ucu kesik olan"
  },
  {
    "x": "qove (n)",
    "y": " kova (b. hilke [n], beroş [n])"
  },
  {
    "x": "Qowancîyan (n)",
    "y": " Kovancılar "
  },
  {
    "x": "qowboy (n)",
    "y": " b. kowboy (n)"
  },
  {
    "x": "qowil (n)",
    "y": " b. qebul (n)"
  },
  {
    "x": "qowil kerdene",
    "y": " b. qebul kerdene"
  },
  {
    "x": "qowl (n)",
    "y": " b. qewl (n)"
  },
  {
    "x": "qowlix (n)",
    "y": " b. qewlix-II (n)"
  },
  {
    "x": "qowm (n)",
    "y": " b. qewm (n)"
  },
  {
    "x": "qowxe (n)",
    "y": " deri kova"
  },
  {
    "x": "qoyi (n)",
    "y": " b. quye (m)"
  },
  {
    "x": "qoyî (m)",
    "y": " b. quye (m)"
  },
  {
    "x": "qral (n)",
    "y": " b. qiral (n)"
  },
  {
    "x": "qramafuni (m)",
    "y": " b. gramofon (n)"
  },
  {
    "x": "qramafûn (m)",
    "y": " b. gramofon (n)"
  },
  {
    "x": "qrêder (n)",
    "y": " b. greydere (m)"
  },
  {
    "x": "qrêdere (m)",
    "y": " b. greydere (m)"
  },
  {
    "x": "qrêncle (n)",
    "y": " b. qirîncele (n)"
  },
  {
    "x": "qrîstal, -e",
    "y": " b. krîstal, -e"
  },
  {
    "x": "qrîz (n)",
    "y": " b. krîz (n)"
  },
  {
    "x": "quc (m)",
    "y": " b. verike (m)"
  },
  {
    "x": "quç (m)",
    "y": " b. qûçe (m)"
  },
  {
    "x": "quç kerdene",
    "y": " b. qûçe kerdene"
  },
  {
    "x": "quçe (m)",
    "y": " b. qûçe (m)"
  },
  {
    "x": "quça kemeron (m)",
    "y": " b. qûça kemeran (m)"
  },
  {
    "x": "quça kemerûn (m)",
    "y": " b. qûça kemeran (m)"
  },
  {
    "x": "quça kerran (m)",
    "y": " b. qûça kerrayan (m)"
  },
  {
    "x": "quça velgî (m)",
    "y": " b. qûça velgî (m)"
  },
  {
    "x": "quçe kerdene",
    "y": " b. qûçe kerdene"
  },
  {
    "x": "quçey kerron (m)",
    "y": " b. qûça kerrayan (m)"
  },
  {
    "x": "quçey velgî (m)",
    "y": " b. qûça velgî (m)"
  },
  {
    "x": "quçê kerrû (m)",
    "y": " b. qûça kerrayan (m)"
  },
  {
    "x": "quçi (m)",
    "y": " b. qûçe (m)"
  },
  {
    "x": "quçi kerdene",
    "y": " b. qûçe kerdene"
  },
  {
    "x": "qudiz (n)",
    "y": " kuduz (hastalığı)"
  },
  {
    "x": "Quddus (n)",
    "y": " Kudüs"
  },
  {
    "x": "qudqud (n)",
    "y": " b. qitqit (n)"
  },
  {
    "x": "qudqud kerdene",
    "y": " b. qitqit kerdene"
  },
  {
    "x": "qudqudkerdiş (n)",
    "y": " b. qitqitkerdiş (n)"
  },
  {
    "x": "qudretê îcra (n)",
    "y": " yaptırım, müeyyide"
  },
  {
    "x": "queç kerdene",
    "y": " b. qoç kerdene"
  },
  {
    "x": "queç purye dayîne",
    "y": " b. qoç piro dayene"
  },
  {
    "x": "queçmeyê daran (n)",
    "y": " ağaç topluluğu"
  },
  {
    "x": "queçmeyê darûn (n)",
    "y": " b. queçmeyê daran (n)"
  },
  {
    "x": "quel, -i-I",
    "y": " b. qol, -e"
  },
  {
    "x": "quel bîyayene",
    "y": " b. qol bîyene"
  },
  {
    "x": "quel kerdene",
    "y": " b. qol kerdene"
  },
  {
    "x": "quel-II (m)",
    "y": " b. qalibe (m)"
  },
  {
    "x": "quel ... kerdene",
    "y": " b. qalibe … kerdene"
  },
  {
    "x": "quel-III (n)",
    "y": " b. qol-II (n)"
  },
  {
    "x": "quel çemirnayîne",
    "y": " b. qol çemirnayene"
  },
  {
    "x": "quel weşemernayîne",
    "y": " b. qol wesemernayene"
  },
  {
    "x": "quelbîyayîş (n)",
    "y": " b. qolbîyayîş (n)"
  },
  {
    "x": "quelo (n)",
    "y": " b. qolo (n)"
  },
  {
    "x": "quelqeşmeyê daran (n)",
    "y": " ağaç topluluğu"
  },
  {
    "x": "quelqeşmeyê darûn (n)",
    "y": " b. quelqeşmeyê daran (n)"
  },
  {
    "x": "querç (m)",
    "y": " b. qorçe (m)"
  },
  {
    "x": "querçikîyer (m)",
    "y": " b. qorçikêre (m)"
  },
  {
    "x": "querçikyer (m)",
    "y": " b. qorçikêre (m)"
  },
  {
    "x": "quet",
    "y": " b. qot, -e"
  },
  {
    "x": "quetbîyayîş (n)",
    "y": " b. qotbîyayîş (n) "
  },
  {
    "x": "quetê (m)",
    "y": " b. qotî (m)"
  },
  {
    "x": "quetkerdiş (n)",
    "y": " b. qotkerdiş (n)"
  },
  {
    "x": "queto (n)",
    "y": " b. qoto (n)"
  },
  {
    "x": "quêrayene",
    "y": " b. qirayene"
  },
  {
    "x": "quêrayîş (n)",
    "y": " b. qirayîş (n)"
  },
  {
    "x": "qufa (m)",
    "y": " 1)küçük sepet\r\n~2)küfe "
  },
  {
    "x": "qufe (n)",
    "y": " b. qufa (m) "
  },
  {
    "x": "qufi (n)",
    "y": " b. qufa (m)"
  },
  {
    "x": "qufik (m)",
    "y": " b. qufike (m)"
  },
  {
    "x": "qufike (m)",
    "y": " küçük sepet "
  },
  {
    "x": "qufiki (m)",
    "y": " b. qufike (m)"
  },
  {
    "x": "quiçê kerrûn (m)",
    "y": " b. qûça kerrayan (m)"
  },
  {
    "x": "qufle (n)",
    "y": " b. qefle (n)"
  },
  {
    "x": "qul, -e-I",
    "y": " köle, kul, abd"
  },
  {
    "x": "qul-II (m)",
    "y": " b. qule-I (m)"
  },
  {
    "x": "qulê loxe (m)",
    "y": " b. qula loxe (m)"
  },
  {
    "x": "qulaç (n)",
    "y": " kulaç"
  },
  {
    "x": "qulbe (m)",
    "y": " b. qible (m)"
  },
  {
    "x": "qulbe (n)",
    "y": " b. qible (m)"
  },
  {
    "x": "qule-I (m)",
    "y": " delik"
  },
  {
    "x": "qula bangêre (m)",
    "y": " yuvağı çekmek için kullanılan çatallı aracın uçlarının girdiği  yuvağın iki yan tarafındaki girinti"
  },
  {
    "x": "qula bargîni (m)",
    "y": " b. qula bangêre (m)"
  },
  {
    "x": "qula loxe (m)",
    "y": " yuvağı çekmek için kullanılan çatallı aracın uçlarının girdiği  yuvağın iki yan tarafındaki girinti"
  },
  {
    "x": "qula pirnike (m)",
    "y": " burun deliği"
  },
  {
    "x": "qula vinî (m)",
    "y": " burun deliği"
  },
  {
    "x": "qula zincî (m)",
    "y": " burun deliği"
  },
  {
    "x": "qule dekerdene",
    "y": " delmek"
  },
  {
    "x": "qule-II (m)",
    "y": " b. qule-III (n)"
  },
  {
    "x": "qule-III (n)",
    "y": " kule"
  },
  {
    "x": "quleyê zengî (n)",
    "y": " çan kulesi"
  },
  {
    "x": "quledekerdiş (n)",
    "y": " delme"
  },
  {
    "x": "quley (m)",
    "y": " b. qulîye (m)"
  },
  {
    "x": "quleyin, -e",
    "y": " kuleli"
  },
  {
    "x": "quli (m)",
    "y": " b. qule-I (m)"
  },
  {
    "x": "qulidekerdiş (n)",
    "y": " b. quledekerdiş (n)"
  },
  {
    "x": "qulik (m)",
    "y": " b. qulike-I (m)"
  },
  {
    "x": "qulikê loxe (m)",
    "y": " b. qula loxe (m)"
  },
  {
    "x": "qulike-I (m)",
    "y": " 1)küçük delik\r\n~2)cezaevi (argo), delik (cezaevi) \r\n~3)b. qulika gojage/make/bişkoje/qumça/sedefe/zirare (m)"
  },
  {
    "x": "qulika gojage/make/bişkoje/qumça/sedefe/zirare (m)",
    "y": " ilik (düğme deliği)"
  },
  {
    "x": "qulike-II (m)",
    "y": " b. qulix (n)"
  },
  {
    "x": "quliki-I (m)",
    "y": " b. qulike-I (m)"
  },
  {
    "x": "quliki-II (m)",
    "y": " b. qulix (n)"
  },
  {
    "x": "quling, -e",
    "y": " turna"
  },
  {
    "x": "qulix (n)",
    "y": " dam, hapishane"
  },
  {
    "x": "quliz, -e",
    "y": " kambur"
  },
  {
    "x": "quliz bîyayene",
    "y": " b. quliz bîyene"
  },
  {
    "x": "quliz bîyene",
    "y": " kamburlaşmak"
  },
  {
    "x": "qulizey (m)",
    "y": " b. qulizîye (m)"
  },
  {
    "x": "qulizî (m)",
    "y": " kamburluk "
  },
  {
    "x": "qulizîye (m)",
    "y": " kamburluk"
  },
  {
    "x": "qulî-I (m)",
    "y": " kölelik, kulluk"
  },
  {
    "x": "qulî-II",
    "y": " hindi"
  },
  {
    "x": "qulîye (m)",
    "y": " kölelik, kulluk"
  },
  {
    "x": "qulli (m)",
    "y": " b. qule-I (m)"
  },
  {
    "x": "qulp (n)",
    "y": " kulp"
  },
  {
    "x": "qulpe (m) ",
    "y": " b. qulpike (m)"
  },
  {
    "x": "qulpê peyî (n)",
    "y": " ailede son çocuk"
  },
  {
    "x": "qulpik (m)",
    "y": " b. qulpike (m)"
  },
  {
    "x": "qulpike (m)",
    "y": " ilik (düğme deliği)"
  },
  {
    "x": "qulpin, -e",
    "y": " kulplu"
  },
  {
    "x": "qulpini (m)",
    "y": " b. qulpine (m)"
  },
  {
    "x": "qult (m)",
    "y": " b. qulte (m)"
  },
  {
    "x": "qulte (m)",
    "y": " yudum"
  },
  {
    "x": "qulti (m)",
    "y": " b. qulte (m)"
  },
  {
    "x": "qultifing (n)",
    "y": " ağaç veya kamış gibi bitkilerin gövdesinden yapılan oyuncak tabanca"
  },
  {
    "x": "qultifong (n)",
    "y": " qultifing (n)"
  },
  {
    "x": "qultik (m)",
    "y": " b. qultike (m)"
  },
  {
    "x": "qultike-I (m)",
    "y": " yudum"
  },
  {
    "x": "qultike-II (m)",
    "y": " şamama"
  },
  {
    "x": "qultiki (m)",
    "y": " b. qultike-I (m)"
  },
  {
    "x": "qulva (m)",
    "y": " b. qible (m)"
  },
  {
    "x": "qum (n)",
    "y": " kum"
  },
  {
    "x": "qumandan, -e",
    "y": " kumandan, komutan"
  },
  {
    "x": "qumandar, -e",
    "y": " b. kumandan, -e"
  },
  {
    "x": "qumar-I (n)",
    "y": " kumar"
  },
  {
    "x": "Qumar-II (n)",
    "y": " köpek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "qumarbaz, -e",
    "y": " kumarbaz, kumarcı"
  },
  {
    "x": "qumare (m)",
    "y": " b. qumar (n)"
  },
  {
    "x": "qumas (n)",
    "y": " b. qumaş (n)"
  },
  {
    "x": "qumaş (n)",
    "y": " kumaş"
  },
  {
    "x": "qumaşo diver (n)",
    "y": " çift en kumaş"
  },
  {
    "x": "qumaşo kêmver/kilmever (n)",
    "y": " ensiz kumaş"
  },
  {
    "x": "qumaşo peşmên/rêsên (n)",
    "y": " yünden yapılmış kumaş (yün kumaş)\t"
  },
  {
    "x": "qumaşo peşmin/rêsin (n) ",
    "y": " yünlü kumaş"
  },
  {
    "x": "qumaşo verin (n)",
    "y": " enli kumaş"
  },
  {
    "x": "qumaşçî, -ye",
    "y": " b. qumaşroş, -e"
  },
  {
    "x": "Qumaşe (m)",
    "y": " inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "Qumaşi (m)",
    "y": " b. Qumaşe (m)"
  },
  {
    "x": "qumaşroş, -e",
    "y": " kumaşçı"
  },
  {
    "x": "qumaşroşî (m)",
    "y": " kumaşçılık"
  },
  {
    "x": "qumaşroşîye (m)",
    "y": " kumaşçılık"
  },
  {
    "x": "qumça (m)",
    "y": " 1)düğme (giysi için)\r\n~2)b. qupça (m)"
  },
  {
    "x": "qumça devistene/girewtene/qefilnayene/tavistene",
    "y": " düğme iliklemek"
  },
  {
    "x": "qumendan, -e",
    "y": " b. kumandan, -e"
  },
  {
    "x": "qumin, -e",
    "y": " kumlu, kumsal"
  },
  {
    "x": "qumitan, -e",
    "y": " b. komutan, -e"
  },
  {
    "x": "qumqumik (m)",
    "y": " b. qumqumike (m)"
  },
  {
    "x": "qumqumike (m)",
    "y": " bir cins semender"
  },
  {
    "x": "qumqumok, -e",
    "y": " b. qumqumike (m)"
  },
  {
    "x": "qumrî (m)",
    "y": " kumru"
  },
  {
    "x": "qumrîye (m)",
    "y": " kumru"
  },
  {
    "x": "qumroş, -e",
    "y": " kumcu"
  },
  {
    "x": "qumtan, -e",
    "y": " b. komutan, -e"
  },
  {
    "x": "qun (m)",
    "y": " b. qine (m) "
  },
  {
    "x": "qunax (n)",
    "y": " b. qonax (n)"
  },
  {
    "x": "qunçiluk",
    "y": " b. kakile (m)"
  },
  {
    "x": "qundaq (n)",
    "y": " b. qundax (n)"
  },
  {
    "x": "qundax (n)",
    "y": " 1)çatkı\r\n~2)kundak (bezi)"
  },
  {
    "x": "qundax bestene",
    "y": " çatkı bağlamak, çatkı çatmak"
  },
  {
    "x": "qunderi (n)",
    "y": " b. kundure-I (n)"
  },
  {
    "x": "qundire (n)",
    "y": " b. kundure-I (n)"
  },
  {
    "x": "qundirme (n)",
    "y": " b. kundure-I (n)"
  },
  {
    "x": "qundure (n)",
    "y": " b. kundure-I (n)"
  },
  {
    "x": "qunek (n)",
    "y": " b. qinda (n)"
  },
  {
    "x": "qunekî (m)",
    "y": " b. qindayî (m), qindayîye (m)"
  },
  {
    "x": "qunîte (m)",
    "y": " b. nutiq (n)"
  },
  {
    "x": "qunîti (m)",
    "y": " b. nutiq (n)"
  },
  {
    "x": "qup kewtene",
    "y": " b. qirpe kewtene"
  },
  {
    "x": "qupça (m)",
    "y": " kopça"
  },
  {
    "x": "qupe (m)",
    "y": " b. qirpe (m)"
  },
  {
    "x": "qura-I (m)",
    "y": " kibirli, büyüklenen"
  },
  {
    "x": "qura-II (m)",
    "y": " b. qure (n)"
  },
  {
    "x": "qura antene",
    "y": " b. qure antene"
  },
  {
    "x": "qurabî (m)",
    "y": " b. qurabîye (m)"
  },
  {
    "x": "qurabîye (m)",
    "y": " kurabiye"
  },
  {
    "x": "Quran (m)",
    "y": " b. Quran (n)"
  },
  {
    "x": "Quran (n)",
    "y": " Kur’an"
  },
  {
    "x": "Quranê Kerîm (n)",
    "y": " Kur’an-ı kerim"
  },
  {
    "x": "Quranî Kerîm (n)",
    "y": " b. Quranê Kerîm (n)"
  },
  {
    "x": "Quranî Kêrîm (n)",
    "y": " b. Quranê Kerîm (n)"
  },
  {
    "x": "Qurane (m)",
    "y": " b. Quran (n)"
  },
  {
    "x": "qurba (n)",
    "y": " b. qurban (n)"
  },
  {
    "x": "qurban (n)",
    "y": " kurban"
  },
  {
    "x": "qurban bîyene",
    "y": " kurban olmak"
  },
  {
    "x": "qurban kerdene",
    "y": " kurban etmek"
  },
  {
    "x": "qurbane (m)",
    "y": " b. qurban (n)"
  },
  {
    "x": "qurbet, -e",
    "y": " çok konuşan"
  },
  {
    "x": "qurbetênî kerdene",
    "y": " 1)çok konuşmak\r\n~2)b. gevezelik etmek"
  },
  {
    "x": "qurbetênîkerdiş (n)",
    "y": " 1)çok konuşma\r\n~2)b. gevezelik etme"
  },
  {
    "x": "qurbetîye",
    "y": " 1)çok konuşma\r\n~2)b. gevezelik"
  },
  {
    "x": "qurbetîye kerdene",
    "y": " 1)çok konuşmak\r\n~2)b. gevezelik etmek"
  },
  {
    "x": "qurbetîyekerdiş (n)",
    "y": " 1)çok konuşma\r\n~2)b. gevezelik etme"
  },
  {
    "x": "qurç-I (n)",
    "y": " çimdik"
  },
  {
    "x": "qurç bîyene",
    "y": " çimdiklenmek, çimdik atılmak"
  },
  {
    "x": "qurç kerdene",
    "y": " çimdiklemek, çimdik atmak, çimdik basmak"
  },
  {
    "x": "qurç-II (n)",
    "y": " b. qûçe (m)"
  },
  {
    "x": "qurçê kemerûn (n)",
    "y": " b. qûça kemeran (m)"
  },
  {
    "x": "qurçê kerrûn (n)",
    "y": " b. qûça kerrayan (m)"
  },
  {
    "x": "qurçbîyayîş (n)",
    "y": " çimdiklenme"
  },
  {
    "x": "qurçi (m)",
    "y": " b. qulte (m)"
  },
  {
    "x": "qurçik (n)",
    "y": " çimdik"
  },
  {
    "x": "qurçik bîyene",
    "y": " çimdiklenmek, çimdik atılmak"
  },
  {
    "x": "qurçik kerdene",
    "y": " çimdiklemek, çimdik atmak, çimdik basmak"
  },
  {
    "x": "qurçikbîyayîş (n)",
    "y": " çimdiklenme"
  },
  {
    "x": "qurçikkerdiş (n)",
    "y": " çimdikleme, çimdik atma, çimdik basma"
  },
  {
    "x": "qurçin (m)",
    "y": " b. qurçine (m)"
  },
  {
    "x": "qurçine (m)",
    "y": " çırpı (kuru dal parçası)"
  },
  {
    "x": "qurçî (m)",
    "y": " sertleşmiş kar"
  },
  {
    "x": "qurçkerdiş (n)",
    "y": " çimdikleme, çimdik atma, çimdik basma"
  },
  {
    "x": "qurdele (n)",
    "y": " b. kurdele (n)"
  },
  {
    "x": "qure-I (n)",
    "y": " kibirli, büyüklenen"
  },
  {
    "x": "qure bîyene",
    "y": " kibirlenmek"
  },
  {
    "x": "qure-II (n)",
    "y": " kura"
  },
  {
    "x": "qure antene",
    "y": " kura çekmek"
  },
  {
    "x": "quretî (m)",
    "y": " kibirlilik"
  },
  {
    "x": "qureyey (m)",
    "y": " b. qureyî (m)"
  },
  {
    "x": "qureyî (m)",
    "y": " kibir, büyüklenme, kibirlilik"
  },
  {
    "x": "qurfîyayene",
    "y": " b. qerifîyayene"
  },
  {
    "x": "qurfîyayîş (n)",
    "y": " b. qerifîyayîş (n)"
  },
  {
    "x": "Qurhan (n)",
    "y": " b. Quran (n)"
  },
  {
    "x": "quricî, -ye",
    "y": " b. qorîcî, -ye"
  },
  {
    "x": "quriçe (m)",
    "y": " b. qurçine (m)"
  },
  {
    "x": "qurinçe (m)",
    "y": " b. qurçine (m)"
  },
  {
    "x": "qurinçi (m)",
    "y": " b. qurçine (m)"
  },
  {
    "x": "quris (n)",
    "y": " b. quriş (n)"
  },
  {
    "x": "quriş (n)",
    "y": " kuruş"
  },
  {
    "x": "qurî-I (m)",
    "y": " b. qorî (m)"
  },
  {
    "x": "qurî-II (m)",
    "y": " b. qorî (m)"
  },
  {
    "x": "qurme (m)",
    "y": " b. qirm (n)"
  },
  {
    "x": "qurma dare (m)",
    "y": " b. qirmê dare (n)"
  },
  {
    "x": "qurmiçikin, -e",
    "y": " b. qirmiçikin, -e"
  },
  {
    "x": "qurmiçîyaya (m)",
    "y": " b. qirmiçîyaya (m)"
  },
  {
    "x": "qurmiçîyaye (n)",
    "y": " b. qirmiçîyaye (n)"
  },
  {
    "x": "qurmiçîyayeni",
    "y": " b. qirmiçîyayene"
  },
  {
    "x": "qurmiçîyayeyî (m)",
    "y": " b. qirmiçîyayeyî (m)"
  },
  {
    "x": "qurmiçîyayênî (m)",
    "y": " b. qirmiçîyayênî (m)"
  },
  {
    "x": "qurmiçnaya (m)",
    "y": " b. qirmiçnaya (m)"
  },
  {
    "x": "qurmiçnaye (n)",
    "y": " b. qirmiçnaye (n)"
  },
  {
    "x": "qurmiçnayene",
    "y": " b. qirmiçnayene"
  },
  {
    "x": "qurmiçnayeni",
    "y": " b. qirmiçnayene"
  },
  {
    "x": "qurmiçnayeyî (m)",
    "y": " b. qirmiçnayeyî (m)"
  },
  {
    "x": "qurmiçnayênî (m)",
    "y": " b. qirmiçnayênî (m)"
  },
  {
    "x": "qurmiçyayeni",
    "y": " b. qirmiçîyayene"
  },
  {
    "x": "qurmuçayene",
    "y": " b. qirmiçîyayene"
  },
  {
    "x": "qurnaz, -e",
    "y": " kurnaz"
  },
  {
    "x": "qurnazey (m)",
    "y": " b. qurnazî (m)"
  },
  {
    "x": "qurnazê (m)",
    "y": " b. qurnazî (m)"
  },
  {
    "x": "qurnazî (m)",
    "y": " kurnazlık"
  },
  {
    "x": "qurnazîne (m)",
    "y": " kurnazlık"
  },
  {
    "x": "qurnazîye (m)",
    "y": " kurnazlık"
  },
  {
    "x": "qurne (n)",
    "y": " b. qirne (n)"
  },
  {
    "x": "Quron (n)",
    "y": " b. Quran (n)"
  },
  {
    "x": "Quronî Kerîm (n)",
    "y": " b. Quranê Kerîm (n)"
  },
  {
    "x": "Quronî Kêrîm (n)",
    "y": " b. Quranê Kerîm (n)"
  },
  {
    "x": "qurqurik (n)",
    "y": " sıcak mevsimlerde suda cırlak bir ses çıkaran bir su hayvanı"
  },
  {
    "x": "qurra (m)",
    "y": " b. qura (m)"
  },
  {
    "x": "qurre (n)",
    "y": " b. qure (n)"
  },
  {
    "x": "qurre bîyene",
    "y": " b. qure bîyene"
  },
  {
    "x": "qurreyey (m)",
    "y": " b. qureyî (m)"
  },
  {
    "x": "qurreyî (m)",
    "y": " b. qureyî (m)"
  },
  {
    "x": "qurrinçi (m)",
    "y": " b. qurçine (m)"
  },
  {
    "x": "qurs (n)",
    "y": " b. kurs (n)"
  },
  {
    "x": "qurse (m)",
    "y": " b. kurs (n)"
  },
  {
    "x": "qursune (m)",
    "y": " b. qerşune (m)"
  },
  {
    "x": "qurşin (n)",
    "y": " b. qerşune (m)"
  },
  {
    "x": "qurşine (m)",
    "y": " b. qerşune (m)"
  },
  {
    "x": "qurt (m)",
    "y": " b. qulte (m)"
  },
  {
    "x": "qurtnayene",
    "y": " gıdaklamak"
  },
  {
    "x": "qurtnayîş (n)",
    "y": " gıdaklama"
  },
  {
    "x": "quruş (n)",
    "y": " b. quriş (n)"
  },
  {
    "x": "Qurûn (n)",
    "y": " b. Quran (n)"
  },
  {
    "x": "Qurûnî Kerîm (n)",
    "y": " b. Quranê Kerîm (n)"
  },
  {
    "x": "Qurûnî Kêrîm (n)",
    "y": " b. Quranê Kerîm (n)"
  },
  {
    "x": "qurva (n)",
    "y": " b. qurban (n)"
  },
  {
    "x": "qurvan (n)",
    "y": " b. qurban (n)"
  },
  {
    "x": "qurvet, -e",
    "y": " b. qurbet, -e"
  },
  {
    "x": "qurvetênî kerdene",
    "y": " b. qurbetênî kerdene"
  },
  {
    "x": "qurvetênîkerdiş (n)",
    "y": " b. qurbetênîkerdiş (n)"
  },
  {
    "x": "qurvetîye (m)",
    "y": " b. qurbetîye (m)"
  },
  {
    "x": "qurvetîye kerdene",
    "y": " b. qurbetîye kerdene"
  },
  {
    "x": "qurvetîyekerdiş (n)",
    "y": " b. qurbetîyekerdiş"
  },
  {
    "x": "qurvon (n)",
    "y": " b. qurban (n)"
  },
  {
    "x": "qurvû (n)",
    "y": " b. qurban (n)"
  },
  {
    "x": "qurvûn (n)",
    "y": " b. qurban (n)"
  },
  {
    "x": "qurya (m)",
    "y": " b. kurya (m)"
  },
  {
    "x": "qurye (n)",
    "y": " b. kurye (n)"
  },
  {
    "x": "quryeyî (m)",
    "y": " b. kuryeyî (m)"
  },
  {
    "x": "quryeyîye (m)",
    "y": " b. kuryeyîye (m)"
  },
  {
    "x": "qusir (n)",
    "y": " b. qusur (n)"
  },
  {
    "x": "qusir meewnî",
    "y": " b. qusurî meewnî"
  },
  {
    "x": "quskane (n)",
    "y": " b. quşxane (n)"
  },
  {
    "x": "qusqane (n)",
    "y": " b. quşxane (n)"
  },
  {
    "x": "qusur (n)",
    "y": " kusur"
  },
  {
    "x": "qusur de nîyamede",
    "y": " b. qusurî de nîyamede"
  },
  {
    "x": "qusur meewnî",
    "y": " b. qusurî meewnî"
  },
  {
    "x": "qusurî de nîyamede",
    "y": " kusura bakma"
  },
  {
    "x": "qusurî meewnî",
    "y": " kusura bakma"
  },
  {
    "x": "qusurdar, -e",
    "y": " ayıplı, kusurlu"
  },
  {
    "x": "qusûr (n)",
    "y": " b. qusur (n)"
  },
  {
    "x": "qusûr meewnî",
    "y": " b. qusurî meewnî"
  },
  {
    "x": "qusxane (n)",
    "y": " b. quşxane (n)"
  },
  {
    "x": "qusxani (n)",
    "y": " b. quşxane (n)"
  },
  {
    "x": "quşqana (m)",
    "y": " b. quşxane (n)"
  },
  {
    "x": "quşqane (n)",
    "y": " b. quşxane (n)"
  },
  {
    "x": "quşxana (m)",
    "y": " b. quşxane (n)"
  },
  {
    "x": "quşxane (n)",
    "y": " küçük tencere"
  },
  {
    "x": "quşxone (n)",
    "y": " b. quşxane (n)"
  },
  {
    "x": "quşxûne (n)",
    "y": " b. quşxane (n)"
  },
  {
    "x": "qut (n)",
    "y": " yem (tavuk, kuş vb. için)"
  },
  {
    "x": "qut bîyayêne",
    "y": " b. qut bîyene "
  },
  {
    "x": "qut bîyene",
    "y": " bitmek"
  },
  {
    "x": "qutare (m)",
    "y": " zahire"
  },
  {
    "x": "qutari (m)",
    "y": " b. qutare (m)"
  },
  {
    "x": "qutbîyayîş (n)",
    "y": " bitme, bitiş"
  },
  {
    "x": "qutê",
    "y": " b. aqît (n)"
  },
  {
    "x": "qutî (m)",
    "y": " kutu"
  },
  {
    "x": "qutîya çopî (m)",
    "y": " çöp kutusu"
  },
  {
    "x": "qutîya dayîş-girewtişê agahî",
    "y": " iletişim kutusu"
  },
  {
    "x": "qutîya dayîş-girewtişê xeberan",
    "y": " iletişim kutusu"
  },
  {
    "x": "qutîya komunîkasyonî",
    "y": " iletişim kutusu"
  },
  {
    "x": "qutîya posteyî (m)",
    "y": " posta kutusu"
  },
  {
    "x": "qutîya waştiş û gerreyan (m)",
    "y": " dilek ve şikayet kutusu"
  },
  {
    "x": "qutîye-I (m)",
    "y": " kutu"
  },
  {
    "x": "qutîye-II (m)",
    "y": " ayna"
  },
  {
    "x": "qutîyeroş, -e",
    "y": " aynacı"
  },
  {
    "x": "qutîyeroşîye (m)",
    "y": " aynacılık"
  },
  {
    "x": "qutîyeviraştox, -e",
    "y": " aynacı"
  },
  {
    "x": "qutîyeviraştoxîye (m)",
    "y": " aynacılık"
  },
  {
    "x": "qutmîn (n)",
    "y": " b. qutnî (n)"
  },
  {
    "x": "qutne (n)",
    "y": " b. qutnî (n)"
  },
  {
    "x": "qutnî (n)",
    "y": " pamuklu (pamuk ipliğiyle dokunmuş kumaş), kutnu"
  },
  {
    "x": "qutqut (n)",
    "y": " b. qitqit (n)"
  },
  {
    "x": "qutube (m)",
    "y": " kutup"
  },
  {
    "x": "qutuba başûrî (m)",
    "y": " güney kutbu"
  },
  {
    "x": "qutuba vakurî (m)",
    "y": " kuzey kutbu"
  },
  {
    "x": "quve (n)",
    "y": " qove (n)"
  },
  {
    "x": "quwarme (n)",
    "y": " b. qawirme (n)"
  },
  {
    "x": "quwet (n)",
    "y": " b. qewet (n)"
  },
  {
    "x": "Quwet bû.",
    "y": " b. Qewet bo."
  },
  {
    "x": "Quwet ib.",
    "y": " b. Qewet bo."
  },
  {
    "x": "quwete (m)",
    "y": " b. qewet (n)"
  },
  {
    "x": "Quwete bo.",
    "y": " b. Qewet bo."
  },
  {
    "x": "quye (m)",
    "y": " kuyu (b. bîr [n])"
  },
  {
    "x": "quyî (m)",
    "y": " b. quye (m)"
  },
  {
    "x": "quyîye (m)",
    "y": " b. quye (m)"
  },
  {
    "x": "quz (n)",
    "y": " sızıntılı yer"
  },
  {
    "x": "quzayene",
    "y": " b. kuzayene"
  },
  {
    "x": "quzayîş (n)",
    "y": " b. kuzayîş (n)"
  },
  {
    "x": "quzik (n)",
    "y": " sızıntılı yer"
  },
  {
    "x": "quzlax (n)",
    "y": " sızıntılı yer"
  },
  {
    "x": "qûç (m)",
    "y": " b. qûçe (m)"
  },
  {
    "x": "qûçe (m)",
    "y": " büyük yığın, loda, (taş, ot ve yaprak için) istif"
  },
  {
    "x": "qûça kemeran (m)",
    "y": " taş yığını"
  },
  {
    "x": "qûça kerrayan (m)",
    "y": " taş yığını"
  },
  {
    "x": "qûça velgî (m)",
    "y": " hayvanlara kışın yedirilmek üzere kesilip üst üste dizilen büyük yaprak yığını"
  },
  {
    "x": "qûça sîyan (m)",
    "y": " taş yığını"
  },
  {
    "x": "qûçe kerdene",
    "y": " yığmak, loda etmek, istiflemek"
  },
  {
    "x": "qûçi (m)",
    "y": " b. qûçe (m)"
  },
  {
    "x": "qûçi kerdeni",
    "y": " b. qûçe kerdene"
  },
  {
    "x": "qûl, -e",
    "y": " b. qul, -e"
  },
  {
    "x": "qûle (n)",
    "y": " b. qule-III (n)"
  },
  {
    "x": "qûm-I (n)",
    "y": " b. qewm (n)"
  },
  {
    "x": "qûm-II (n)",
    "y": " b. qum (n)"
  },
  {
    "x": "qûm-III (m)",
    "y": " b. qame (m)"
  },
  {
    "x": "qûma fîstanî (m)",
    "y": " b. qama fistanî (m)"
  },
  {
    "x": "qûmcî, -ye",
    "y": " b. qumroş, -e"
  },
  {
    "x": "qûmçî (m)",
    "y": " b. qamçî (m)"
  },
  {
    "x": "qûmçî kerdene",
    "y": " b. qamçî kerdene"
  },
  {
    "x": "qûmçîyûn ver şûnayene",
    "y": " b. qamçîyan ver şanayene"
  },
  {
    "x": "qûmderg, -i",
    "y": " b. qamderg, -e"
  },
  {
    "x": "qûmi (m)",
    "y": " b. qame (m)"
  },
  {
    "x": "qûmîş (n)",
    "y": " b. qamîş (n)"
  },
  {
    "x": "qûnca (m)",
    "y": " b. qanca (m)"
  },
  {
    "x": "qûnç",
    "y": " b. qûçe (m)"
  },
  {
    "x": "qûnçe (m)",
    "y": " b. qûçe (m)"
  },
  {
    "x": "qûnçi (m)",
    "y": " b. qûçe (m)"
  },
  {
    "x": "qûnçi kerdeni",
    "y": " b. qûçe kerdene"
  },
  {
    "x": "qûnek (n)",
    "y": " b. qinda (n)"
  },
  {
    "x": "qûnekî (m)",
    "y": " b. qindayî (m), qindayîye (m)"
  },
  {
    "x": "qûnik (m)",
    "y": " b. qanik (n)"
  },
  {
    "x": "qûntir (n)",
    "y": " b. qantir (n) "
  },
  {
    "x": "qûntirleyr (n)",
    "y": " b. qantirleyîr (n) "
  },
  {
    "x": "qûnz (m)",
    "y": " b. qaze (m)"
  },
  {
    "x": "qûnzi (m)",
    "y": " b. qaze (m)"
  },
  {
    "x": "qûnzik (n)",
    "y": " b. qanzik (n)"
  },
  {
    "x": "qût (n)",
    "y": " b. qut (n)"
  },
  {
    "x": "qûwm (n)",
    "y": " b. qewm (n)"
  },
  {
    "x": "qûyî (m)",
    "y": " b. quye (m)"
  },
  {
    "x": "RRR"
  },
  {
    "x": "r’ (pd)",
    "y": " b. rê-III (pd)"
  },
  {
    "x": "ra-I (vr)",
    "y": " bir önedat, -e, -i"
  },
  {
    "x": "ra awe ver kewtene",
    "y": " akıntıya kapılmak\r\n~Mence kewta ra awe ver. (Mence akıntıya kapılmış.)"
  },
  {
    "x": "ra ci arqilîyayene",
    "y": " farkına varmak"
  },
  {
    "x": "ra ci arqilnayene",
    "y": " farkına vardırmak"
  },
  {
    "x": "ra ci estene",
    "y": " b. ra ci eştene"
  },
  {
    "x": "ra ci eştene",
    "y": " -e yapıştırmak\r\n~Hesen qehirîya, şîlpaxê eşte ra kerika layekî. (Hasan kızdı, oğlana [kulağına] tokatı yapıştırdı.)"
  },
  {
    "x": "ra ciqemetnayene",
    "y": " b. ra ciqemitnayene"
  },
  {
    "x": "ra ciqemitnayene",
    "y": " -e yapıştırmak"
  },
  {
    "x": "ra cizeleqnayene",
    "y": " b. ra cizeliqnayene"
  },
  {
    "x": "ra cizeliqnayene",
    "y": " -e yapıştırmak"
  },
  {
    "x": "ra dar erzîyayene",
    "y": " asılmak (idam edilmek)"
  },
  {
    "x": "ra dar eştene",
    "y": " asmak (idam etmek)"
  },
  {
    "x": "ra dim kewtene",
    "y": " (kadın için) kaçmak"
  },
  {
    "x": "ra dim kotene",
    "y": " b. ra dim kewtene"
  },
  {
    "x": "ra gore",
    "y": " göre (-e göre), bakımından"
  },
  {
    "x": "ra hewa fînîyayene",
    "y": " kaldırılmak"
  },
  {
    "x": "ra hewa fîştene",
    "y": " kaldırmak"
  },
  {
    "x": "ra ray fîştene",
    "y": " yolcu etmek, geçirmek"
  },
  {
    "x": "ra raye kewtene",
    "y": " yola çıkmak, hareket etmek, yola düşmek, yola koyulmak"
  },
  {
    "x": "ra şaqul ardene",
    "y": " şakullemek, şavullamak"
  },
  {
    "x": "ra ... şîkîyayene",
    "y": " yakışmak, uygun düşmek, uygun gelmek, uygun olmak, uymak, yakışmak, yaraşmak"
  },
  {
    "x": "ra ... şîkîyayîş",
    "y": " yakışma"
  },
  {
    "x": "ra vayî ver kewtene-I",
    "y": " 1)rüzgâra kapılmak\r\n~2)berheva olmak, ziyan olmak "
  },
  {
    "x": "ra vîr ameyene",
    "y": " anımsamak, hatırlamak, aklına gelmek"
  },
  {
    "x": "ra vîr ardene",
    "y": " anımsatmak, hatırlatmak; çağrıştırmak (akla getirmek)"
  },
  {
    "x": "ra vîr kewtene",
    "y": " aklına gelmek"
  },
  {
    "x": "ra xo aferîyayene",
    "y": " afallamak"
  },
  {
    "x": "ra xo vîr ardene",
    "y": " anımsamak, hatırlamak"
  },
  {
    "x": "ra-II (pd)",
    "y": " 1)bir artedat, -den\r\n~Dewe ra ame. (Köyden geldi.)\r\n~2)bir artedat, -e\r\n~3)bir artedat, -de\r\n~“Na ko ra ma rê merden, \r\n~Hem çêf o hem saltenet o” (Bu dağda ölmek, bizim için hem keyif [verici] hem saltanattır.) (Derê Laçî türküsünden)"
  },
  {
    "x": "... ra bacîyayene",
    "y": " nefret etmek, ikrah etmek, tiksinmek"
  },
  {
    "x": "ra ber",
    "y": " hariç"
  },
  {
    "x": "ra dima",
    "y": " -den sonra, ardından"
  },
  {
    "x": "ra dime",
    "y": " b. ra dima"
  },
  {
    "x": "ra dot",
    "y": " b. ra wet"
  },
  {
    "x": "... ra girewtene",
    "y": " bulaşmak (hastalık için)"
  },
  {
    "x": "... ra mird bîyene",
    "y": " bezmek, bezginlik getirmek, bıkmak, bizar olmak, usanmak"
  },
  {
    "x": "ra pey",
    "y": " -den sonra, ardından"
  },
  {
    "x": "ra pêy",
    "y": " b. ra pey"
  },
  {
    "x": "ra qederî",
    "y": " -den başka, hariç\r\n~To ra qederî çi kes nêvano. (Senden başka hiç kimse söylemiyor.)"
  },
  {
    "x": "ra teber",
    "y": " -den başka, hariç\r\n~Nê karî ra teber zî karê ma est ê. (Bu işten başka da işlerimiz var.)"
  },
  {
    "x": "ra tepa",
    "y": " b. ra tepîya"
  },
  {
    "x": "ra tepîya",
    "y": " -den sonra, ardından"
  },
  {
    "x": "ra tepya",
    "y": " b. ra tepîya"
  },
  {
    "x": "ra tever",
    "y": " b. ra teber"
  },
  {
    "x": "ra tewer",
    "y": " b. ra teber"
  },
  {
    "x": "ra têpîya",
    "y": " b. ra tepîya"
  },
  {
    "x": "ra wet",
    "y": " -den başka, -den öte\r\n~Ey ra wet çi kes nêame. (Ondan başka hiç kimse gelmedi.)"
  },
  {
    "x": "... ra bacîyayene",
    "y": " nefret etmek"
  },
  {
    "x": "... ra berdene",
    "y": " ütmek, utmak (oyun için)"
  },
  {
    "x": "... ra girewtene",
    "y": " 1)-den almak\r\n~2)bulaşmak (hastalık için)"
  },
  {
    "x": "... ra hes bîyayene",
    "y": " b. ... ra hes bîyene"
  },
  {
    "x": "... ra hes bîyene",
    "y": " sevilmek"
  },
  {
    "x": "... ra muteessîr bîyene",
    "y": " -den etkilenmek, -den müteessir olmak"
  },
  {
    "x": "... ra naşta",
    "y": " b. ... ra nat"
  },
  {
    "x": "... ra nat",
    "y": " -den beri"
  },
  {
    "x": "... ra nata",
    "y": " b. ... ra nat"
  },
  {
    "x": "... ra werdene",
    "y": " -den ütmek, -den utmak (oyun için)"
  },
  {
    "x": "...  ra weş, -e",
    "y": " hoşnut, memnun\r\n~O halê to ra weş nîyo. (O senin durumundan hoşnut değil.)"
  },
  {
    "x": "... ra weş bîyene",
    "y": " -den hoşnut olmak"
  },
  {
    "x": "... ra weşnêbîyaya (m)",
    "y": " hoşnutsuz"
  },
  {
    "x": "... ra weşnêbîyaye (n)",
    "y": " hoşnutsuz"
  },
  {
    "x": "... ra weşnêbîyayîye (m)",
    "y": " hoşnutsuz"
  },
  {
    "x": "... ra celayene",
    "y": " b. ... ra celîyayene"
  },
  {
    "x": "... ra celîyayene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "... ra cellayene",
    "y": " b. ... ra celîyayene"
  },
  {
    "x": "... ra celleyene",
    "y": " b. ... ra celîyayene"
  },
  {
    "x": "... ra cellîyayene",
    "y": " b. ... ra celîyayene"
  },
  {
    "x": "... ra cigirîyayene",
    "y": " -e kızmak, küsmek, azarlamak, paylamak, tekdir etmek"
  },
  {
    "x": "... ra disayene",
    "y": " b. ... ra dusayene"
  },
  {
    "x": "... ra diskinayene",
    "y": " b. ... ra duskînayene"
  },
  {
    "x": "... ra diskînayene",
    "y": " b. ... ra duskînayene"
  },
  {
    "x": "... ra diskîyayene",
    "y": " b. ... ra duskîyayene"
  },
  {
    "x": "... ra disnayene",
    "y": " b. ... ra dusnayene"
  },
  {
    "x": "... ra dusayene",
    "y": " -e yapışmak"
  },
  {
    "x": "... ra dusîyayene",
    "y": " b. ... ra dusayene"
  },
  {
    "x": "... ra duskînayene",
    "y": " -e yapıştırmak"
  },
  {
    "x": "... ra duskîyayene",
    "y": " -e yapışmak"
  },
  {
    "x": "... ra dusnayene",
    "y": " -e yapıştırmak"
  },
  {
    "x": "... ra ewnîyayene",
    "y": " -e bakmak"
  },
  {
    "x": "... ra hêrs bîyayene",
    "y": " b. ... ra hêrs bîyene"
  },
  {
    "x": "... ra hêrs bîyene",
    "y": " -e kızmak, küsmek, azarlamak, paylamak, tekdir etmek"
  },
  {
    "x": "... ra kerdene",
    "y": " batırmak"
  },
  {
    "x": "... ra qehirîyayene",
    "y": " çekememek, küsmek"
  },
  {
    "x": "... ra şîyene",
    "y": " batmak (sivri şeyin batması)"
  },
  {
    "x": "... ra teqayene",
    "y": " kıskanmak, günülemek, haset etmek (çekememek)"
  },
  {
    "x": "... ra vatene",
    "y": " -e söylemek\r\n~Mi ra va. (Bana söyledi.)"
  },
  {
    "x": "... ra zeleqnayene",
    "y": " b. ... ra zeliqnayene"
  },
  {
    "x": "... ra zeliqnayene",
    "y": " -e yapıştırmak, \r\n~"
  },
  {
    "x": "ra-III (m)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "ra-IV",
    "y": " b. rey-I"
  },
  {
    "x": "ra-ray",
    "y": " b. rey-rey"
  },
  {
    "x": "raamaene",
    "y": " b. raameyene"
  },
  {
    "x": "raamayene",
    "y": " b. raameyene"
  },
  {
    "x": "raamayîş (n)",
    "y": " b. raameyîş (n)"
  },
  {
    "x": "raameye (n)",
    "y": " mayalı (hamur için)"
  },
  {
    "x": "raameyene",
    "y": " 1)oluşmak\r\n~Asmên ra hewrî ameyê ra. (Gökte bulutlar oluşmuş.)\r\n~2)mayalanmak (hamur için)\t\t"
  },
  {
    "x": "raameyîş (n)",
    "y": " 1)oluşma\r\n~2)oluşum\r\n~3)mayalanma (hamur için)"
  },
  {
    "x": "raar (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "raardene",
    "y": " keskinleştirme, keskinletmek"
  },
  {
    "x": "raardiş (n)",
    "y": " keskinleştirme, keskinletme"
  },
  {
    "x": "raarta (m)",
    "y": " evlek"
  },
  {
    "x": "Rab (n)",
    "y": " b. Reb (n), Rebî (n)"
  },
  {
    "x": "rabacîyayene",
    "y": " çatlamak (ölmek)"
  },
  {
    "x": "rabacîyayîş (n)",
    "y": " çatlama (ölme)"
  },
  {
    "x": "... rabacîyayîş",
    "y": " nefret etme"
  },
  {
    "x": "rabacnayene",
    "y": " çatlatmak (ölmesine neden olmak) "
  },
  {
    "x": "rabajîyayîş (n)",
    "y": " b. rabacîyayîş (n)"
  },
  {
    "x": "rabajîyene",
    "y": " b. rabacîyayene"
  },
  {
    "x": "rabajnayene",
    "y": " b. rabacnayene"
  },
  {
    "x": "Rabat (n)",
    "y": " Rabat"
  },
  {
    "x": "Rabb",
    "y": " b. Reb (n), Rebî (n)"
  },
  {
    "x": "rabilîyayene",
    "y": " goncanın açılmaya başlaması\r\n~Tolebişkî rabilîyayî. (Goncalar açılmaya başladı.)"
  },
  {
    "x": "rabirîyayene",
    "y": " 1)ayrılmak\r\n~2)boşanmak"
  },
  {
    "x": "rabirîyayîş (n)",
    "y": " 1)ayrılma\r\n~2)boşanma"
  },
  {
    "x": "rabirnayene",
    "y": " ayırmak"
  },
  {
    "x": "rabirnayîs (n)",
    "y": " b. rabirnayîş (n)"
  },
  {
    "x": "rabirnayîş (n)",
    "y": " ayırma"
  },
  {
    "x": "rabişkivîyene",
    "y": " yapraklanmak"
  },
  {
    "x": "rabişkîyayene",
    "y": " b. rabişkivîyene"
  },
  {
    "x": "rabita (m)",
    "y": " bağıntı"
  },
  {
    "x": "Rabî (n)",
    "y": " b. Reb (n), Rebî (n)"
  },
  {
    "x": "rabîyayîş (n)",
    "y": " çözülme (düğüm vb. için)"
  },
  {
    "x": "rabîyene",
    "y": " çözülmek (düğüm vb. için)"
  },
  {
    "x": "rabojîyayene",
    "y": " b. rabacîyayene"
  },
  {
    "x": "rabojîyayîş (n)",
    "y": " b. rabacîyayîş (n)"
  },
  {
    "x": "rabojnayene",
    "y": " b. rabacnayene"
  },
  {
    "x": "racêrayene",
    "y": " b. yacêrayene"
  },
  {
    "x": "racêrayêne",
    "y": " b. yacêrayene"
  },
  {
    "x": "racêrayîş (n)",
    "y": " b. yacêrayîş (n)"
  },
  {
    "x": "raciarqilîyayîş (n)",
    "y": " farkına varma"
  },
  {
    "x": "raciarqilnayîş (n)",
    "y": " farkına vardırma"
  },
  {
    "x": "raciestiş (n)",
    "y": " b. racieştiş (n)"
  },
  {
    "x": "racieştiş (n)",
    "y": " -e yapıştırma"
  },
  {
    "x": "... racigirîyayîş",
    "y": " azarlama, melamet, paylama, tekdir etme"
  },
  {
    "x": "...racigîrîyayox, -e",
    "y": " azarlayan"
  },
  {
    "x": "raciqemetnayîş (n)",
    "y": " b. raciqemitnayîş (n)"
  },
  {
    "x": "raciqemitnayîş (n)",
    "y": " -e yapıştırma"
  },
  {
    "x": "racizeleqnayîş (n)",
    "y": " b. racizeliqnayîş (n)"
  },
  {
    "x": "racizeliqnayîş (n)",
    "y": " -e yapıştırma"
  },
  {
    "x": "raçarnaye (n)",
    "y": " b. açarnayene (n)"
  },
  {
    "x": "raçarnayene",
    "y": " b. açarnayene"
  },
  {
    "x": "raçarnayîş (n)",
    "y": " b. açarnayîş (n)"
  },
  {
    "x": "raçînitene",
    "y": " gözemek, örmek (kumaş ve örgülerdeki delikleri elde iplikle besleyerek kapatmak)"
  },
  {
    "x": "raçînitiş (n)",
    "y": " gözeme, örme (kumaş ve örgülerdeki delikleri elde iplikle besleyerek kapatma)"
  },
  {
    "x": "radar (n)",
    "y": " radar"
  },
  {
    "x": "radarerzîyayîş (n)",
    "y": " asılma (idam edilme)"
  },
  {
    "x": "radareşta (m)",
    "y": " asılı"
  },
  {
    "x": "radareşte (n)",
    "y": " asılı"
  },
  {
    "x": "radareştiş (n)",
    "y": " asma (idam etme)"
  },
  {
    "x": "radareştîye (m)",
    "y": " asılı"
  },
  {
    "x": "radayene",
    "y": " terk etmek, çekip gitmek\r\n~“Şefeqê sibay piro dano\r\n~\tMihê mi keye ra radano.” (Deyîra Mehmedê Aldunî)"
  },
  {
    "x": "radayîş (n)",
    "y": " terk etme, çekip gitme"
  },
  {
    "x": "radimkewtiş (n)",
    "y": " (kadın için) kaçma"
  },
  {
    "x": "radimkotiş (n)",
    "y": " b. radimkewtiş (n)"
  },
  {
    "x": "... radisayîş",
    "y": " b. ... radusayîş"
  },
  {
    "x": "... radiskinayîş",
    "y": " b. ... raduskînayîş"
  },
  {
    "x": "... radiskînayîş",
    "y": " b. ... raduskînayîş"
  },
  {
    "x": "... radiskîyayîş",
    "y": " ... raduskîyayîş"
  },
  {
    "x": "... radisnayîş",
    "y": " b. ... radusnayîş"
  },
  {
    "x": "radîûn (m)",
    "y": " b. radyo (n)"
  },
  {
    "x": "radîyo (n)",
    "y": " b. radyo (n)"
  },
  {
    "x": "rado (n)",
    "y": " b. radyo (n)"
  },
  {
    "x": "radon (n)",
    "y": " b. radyo (n)"
  },
  {
    "x": "... radusayîş",
    "y": " -e yapışma"
  },
  {
    "x": "... radusîyayîş",
    "y": " b. ... radusayîş"
  },
  {
    "x": "... raduskînayîş",
    "y": " -e yapıştırma"
  },
  {
    "x": "... raduskîyayîş",
    "y": " -e yapışma"
  },
  {
    "x": "... radusnayîş",
    "y": " -e yapıştırma"
  },
  {
    "x": "radû (n)",
    "y": " b. radyo (n)"
  },
  {
    "x": "radûn (n)",
    "y": " b. radyo (n)"
  },
  {
    "x": "radyasyon (n)",
    "y": " ışınım, radyasyon "
  },
  {
    "x": "radyasyonpeym (n)",
    "y": " bolometre, ışınımölçer"
  },
  {
    "x": "radyator (n)",
    "y": " radyatör"
  },
  {
    "x": "radyo (n)",
    "y": " radyo"
  },
  {
    "x": "radyo modem (n)",
    "y": " radio modem"
  },
  {
    "x": "radyo (m)",
    "y": " b. radyo (n)"
  },
  {
    "x": "radyoaktîf, -e",
    "y": " radyoaktif"
  },
  {
    "x": "radyoaktîfî (m)",
    "y": " radyoaktivite"
  },
  {
    "x": "radyoaktîfîye (m)",
    "y": " radyoaktivite"
  },
  {
    "x": "radyoaktîvîte (m)",
    "y": " radyoaktivite"
  },
  {
    "x": "radyolog, -e",
    "y": " ışınbilimci, radyolog"
  },
  {
    "x": "radyolojî (n)",
    "y": " ışınbilim, radyoloji"
  },
  {
    "x": "radyolojîk, -e",
    "y": " radyolojik"
  },
  {
    "x": "radyometre (n)",
    "y": " ışınölçer, radyometre"
  },
  {
    "x": "radyometrî (n)",
    "y": " radyometri"
  },
  {
    "x": "radyometrîk, -e",
    "y": " radyometrik"
  },
  {
    "x": "radyoyi (m)",
    "y": " b. radyo (n)"
  },
  {
    "x": "rae-I (m)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "rae-II (m)",
    "y": " b. rey-I (m)"
  },
  {
    "x": "rafistene",
    "y": " b. rafîştene"
  },
  {
    "x": "rafistiş (n)",
    "y": " b. rafîştiş (n)"
  },
  {
    "x": "rafînaj (n)",
    "y": " arıtım, rafinaj"
  },
  {
    "x": "rafîstayêne",
    "y": " b. rafîştene"
  },
  {
    "x": "rafîstene",
    "y": " b. rafîştene"
  },
  {
    "x": "rafîstiş (n)",
    "y": " b. rafîştiş (n)"
  },
  {
    "x": "rafîştaene",
    "y": " b. rafîştene"
  },
  {
    "x": "rafîştayîs (n)",
    "y": " b. rafîştiş (n)"
  },
  {
    "x": "rafîşte (n)",
    "y": " serili"
  },
  {
    "x": "rafîştene",
    "y": " sermek (yere sermek)\r\n~Cinîke çarşefe arde û doşegî ser o fîşte ra. (Kadın çarşafı getirdi ve döşeğin üzerine serdi.)"
  },
  {
    "x": "rafîştiş (n)",
    "y": " serme (yere serme)"
  },
  {
    "x": "rafîştî, -ye",
    "y": " serili"
  },
  {
    "x": "rageyrayene",
    "y": " b. ragêrayene"
  },
  {
    "x": "rageyrayîş (n)",
    "y": " b. ragêrayîş (n)"
  },
  {
    "x": "ragêrayene",
    "y": " 1)terk etmek, çekip gitmek\r\n~“Hela day, mi serey xwi girot û ez ragêraynî\r\n~Hela day, ez dew ra vecîyaynî” (Deyîra Gidîyan)\r\n~2)b. agêrayene"
  },
  {
    "x": "ragêrayîş (n)",
    "y": " 1)terk etme, çekip gitme\r\n~2)b. agêrayene"
  },
  {
    "x": "... ragirewtiş (n)",
    "y": " bulaşma (hastalık için)"
  },
  {
    "x": "ragojîyayene",
    "y": " b. raguzîyayene"
  },
  {
    "x": "ragojîyayêne",
    "y": " b. raguzîyayene"
  },
  {
    "x": "ragojîyayîş (n)",
    "y": " b. raguzîyayîş (n)"
  },
  {
    "x": "ragojnayeni",
    "y": " b. raguznayene"
  },
  {
    "x": "ragojnayîş (n)",
    "y": " b. raguznayîş (n)"
  },
  {
    "x": "ragojyayeni",
    "y": " b. raguzîyayene"
  },
  {
    "x": "ragojyayîş (n)",
    "y": " b. raguzîyayîş (n)"
  },
  {
    "x": "ragozîyayeni",
    "y": " b. raguzîyayene"
  },
  {
    "x": "ragozîyayîş (n)",
    "y": " b. raguzîyayîş (n)"
  },
  {
    "x": "ragoznayeni",
    "y": " b. raguznayene"
  },
  {
    "x": "ragoznayîş (n)",
    "y": " b. raguznayîş (n)"
  },
  {
    "x": "ragunaîş (n)",
    "y": " b. ragunayîş (n)"
  },
  {
    "x": "ragunayene",
    "y": " yayılmak (koyun, keçi vb. için)"
  },
  {
    "x": "ragunayeni",
    "y": " b. ragunayene"
  },
  {
    "x": "ragunayiş (n)",
    "y": " b. ragunayîş (n)"
  },
  {
    "x": "ragunayîş (n)",
    "y": " yayılma (koyun, keçi vb. için)"
  },
  {
    "x": "raguzîyayene",
    "y": " süzülmek (sıvı için)"
  },
  {
    "x": "raguzîyayîş (n)",
    "y": " süzülme (sıvı için)"
  },
  {
    "x": "raguznayene",
    "y": " 1)süzmek (sıvı için)\r\n~2)arıtmak"
  },
  {
    "x": "raguznayîş (n)",
    "y": " 1)süzme (sıvı için) \r\n~2)arıtma\r\n~raguznayîşê awe (n): su arıtma\r\n~raguznayîşo bîyolojîk (n): biyolojik arıtma"
  },
  {
    "x": "raguznayîşê awe (n)",
    "y": " su arıtma"
  },
  {
    "x": "raguznayîşo bîyolojîk (n) ",
    "y": " biyolojik arıtma"
  },
  {
    "x": "rah (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "rahar (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "rahar ra şîyayîne",
    "y": " b. rayîr ra şîyene"
  },
  {
    "x": "raharraşîyayîş (n)",
    "y": " b. rayîrraşîyayîş (n)"
  },
  {
    "x": "... rahesbîyayîş (n)",
    "y": " sevilme"
  },
  {
    "x": "rahet, -e",
    "y": " b. rehet,-e"
  },
  {
    "x": "Rahet!",
    "y": " b. Rehet!"
  },
  {
    "x": "rahewa fîştene",
    "y": " havalandırmak (havaya kaldırmak)"
  },
  {
    "x": "rahewafînîyayîş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "rahewafîştiş (n)",
    "y": " kaldırma, havalandırma"
  },
  {
    "x": "... rahêrsbîyayîş",
    "y": " azarlama, melamet, paylama, tekdir etme"
  },
  {
    "x": "rahir (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "rahîb (n)",
    "y": " keşiş, rahip"
  },
  {
    "x": "rahîba (m)",
    "y": " b. rahîbe (m)"
  },
  {
    "x": "rahîbe (m)",
    "y": " rahibe"
  },
  {
    "x": "rahîr (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "rahme (m)",
    "y": " b. rehme (m)"
  },
  {
    "x": "rajane (n)",
    "y": " hiza"
  },
  {
    "x": "rajaneyê ... ra",
    "y": " hizasında"
  },
  {
    "x": "rajî, -ye",
    "y": " b. razî, -ye"
  },
  {
    "x": "rajî bîyayene",
    "y": " b. razî bîyene"
  },
  {
    "x": "rajî bîyene",
    "y": " b. razî bîyene"
  },
  {
    "x": "rajî kerdayene",
    "y": " b. razî kerdene"
  },
  {
    "x": "rajî kerdene",
    "y": " b. razî kerdene"
  },
  {
    "x": "rajîbîyayîs (n)",
    "y": " b. razîbîyayîş (n)"
  },
  {
    "x": "rajîbîyayîş (n)",
    "y": " b. razîbîyayîş (n)"
  },
  {
    "x": "rakerde (n)",
    "y": " 1)serili \r\n~2)açık, belirgin\r\n~3)açıktan açığa"
  },
  {
    "x": "rakerde bîyene",
    "y": " belirginleşmek"
  },
  {
    "x": "rakerdebîyayîş (n)",
    "y": " belirginleşme"
  },
  {
    "x": "rakerdek (n)",
    "y": " açacak"
  },
  {
    "x": "rakerdene",
    "y": " 1)sermek (yere sermek), döşemek, tefriş etmek\r\n~2)açmak\r\n~3)çözmek (düğüm vb. için)"
  },
  {
    "x": "rakerdeyîye (m)",
    "y": " açıklık"
  },
  {
    "x": "rakerdiş (n)",
    "y": " 1)serme (yere serme), döşeme, tefriş etme\r\n~2)döşeme (ev döşeme eşyası)\r\n~3)açma, açış\r\n~4)çözme (düğüm vb. için)"
  },
  {
    "x": "... rakerdiş (n)",
    "y": " batırma"
  },
  {
    "x": "rakerdî, -ye",
    "y": " 1)serili\r\n~2)açık, belirgin"
  },
  {
    "x": "rakerdox, -e",
    "y": " 1)seren kişi\r\n~2)açan"
  },
  {
    "x": "raket (n)",
    "y": " raket "
  },
  {
    "x": "rakewtene",
    "y": " yatmak"
  },
  {
    "x": "rakewtiş (n)",
    "y": " yatma"
  },
  {
    "x": "rakotene",
    "y": " b. rakewtene"
  },
  {
    "x": "rakotiş (n)",
    "y": " b. rakewtiş (n)"
  },
  {
    "x": "rakowtene",
    "y": " b. rakewtene"
  },
  {
    "x": "rakowtiş (n)",
    "y": " b. rakewtiş (n)"
  },
  {
    "x": "rakoytiş (n)",
    "y": " b. rakewtiş (n)"
  },
  {
    "x": "RAM (m) (TE)",
    "y": " Rastgele Erişimli Bellek"
  },
  {
    "x": "ramayene",
    "y": " b. remayene"
  },
  {
    "x": "ramayîş (n)",
    "y": " b. remayîş (n)"
  },
  {
    "x": "rame (m)",
    "y": " b. rehme (m)"
  },
  {
    "x": "rametî, -ye",
    "y": " b. rehmetî, -ye"
  },
  {
    "x": "rameyene",
    "y": " b. ramîyayene"
  },
  {
    "x": "raminayene",
    "y": " b. remnayene"
  },
  {
    "x": "raminayîş (n)",
    "y": " b. remnayîş (n)"
  },
  {
    "x": "ramita (m)",
    "y": " sürülü"
  },
  {
    "x": "ramitbar, -e",
    "y": " sürdürülebilir"
  },
  {
    "x": "ramitbarîye (m)",
    "y": " sürdürülebilirlik"
  },
  {
    "x": "... ramirdbîyayîş",
    "y": " bezme, bıkma, bizar olma, usanma"
  },
  {
    "x": "ramite (n)",
    "y": " sürülü"
  },
  {
    "x": "ramitene",
    "y": " 1)sürmek\r\n~2)ekmek, tohum ekmek (sebze ve meyve için)\r\n~3)devam etmek, sürdürmek"
  },
  {
    "x": "ramitena erebeyî (m)",
    "y": " araba kullanmak"
  },
  {
    "x": "ramitene ser",
    "y": " saldırmak, üzerine saldırmak, hücum etmek"
  },
  {
    "x": "ramitiş (n)",
    "y": " 1)sürme, dewam etme\r\n~2)sürdürme\r\n~3)ekme, ekiş, ekim\r\n~4)dikme"
  },
  {
    "x": "ramitîye (m)",
    "y": " sürülü"
  },
  {
    "x": "ramitox, -e",
    "y": " sürücü"
  },
  {
    "x": "ramitoxa alkolşimitoxe (m)",
    "y": " alkollü sürücü"
  },
  {
    "x": "ramitoxa serxoşe (m)",
    "y": " sarhoş sürücü"
  },
  {
    "x": "ramitoxa serxweşe (m)",
    "y": " sarhoş sürücü"
  },
  {
    "x": "ramitoxo alkolşimitox (n)",
    "y": " alkollü sürücü"
  },
  {
    "x": "ramitoxo serxoş (n)",
    "y": " sarhoş sürücü"
  },
  {
    "x": "ramitoxo serxweş (n)",
    "y": " sarhoş sürücü"
  },
  {
    "x": "ramitoxênî (m)",
    "y": " sürücülük"
  },
  {
    "x": "ramitoxî (m)",
    "y": " sürücülük"
  },
  {
    "x": "ramitoxîye (m)",
    "y": " sürücülük"
  },
  {
    "x": "ramî (zh)",
    "y": " apış arası kılları, etek kılları"
  },
  {
    "x": "ramîyayene",
    "y": " 1)sürülmek\r\n~2)ekilmek"
  },
  {
    "x": "ramnayene",
    "y": " b. remnayene"
  },
  {
    "x": "ramnayîş (n)",
    "y": " b. remnayîş (n)"
  },
  {
    "x": "ramtene",
    "y": " b. ramitene"
  },
  {
    "x": "ramuecnayîş (n)",
    "y": " b. mojnayîş (n)"
  },
  {
    "x": "ramutene",
    "y": " b. ramitene"
  },
  {
    "x": "ramutiş (n)",
    "y": " b. ramitiş (n)"
  },
  {
    "x": "ramyayene",
    "y": " b. ramîyayene"
  },
  {
    "x": "rana",
    "y": " b. reyna"
  },
  {
    "x": "ranayîş (n)",
    "y": " b. ronayîş (n)"
  },
  {
    "x": "randevu (n)",
    "y": " randevu "
  },
  {
    "x": "randevu dayene",
    "y": " randevu vermek"
  },
  {
    "x": "randevu girewtene",
    "y": " randevu almak"
  },
  {
    "x": "randevu pêdayene",
    "y": " randevulaşmak"
  },
  {
    "x": "randevu yewbînî dayene",
    "y": " randevulaşmak"
  },
  {
    "x": "randevuyê sereke",
    "y": " başkanın randevuları"
  },
  {
    "x": "randevuyê serekî",
    "y": " başkanın randevuları"
  },
  {
    "x": "randevudayîş (n)",
    "y": " randevu verme"
  },
  {
    "x": "randevugirewtiş (n)",
    "y": " randevu alma"
  },
  {
    "x": "randevupêdayîş (n)",
    "y": " randevulaşma"
  },
  {
    "x": "randevuyewbînîdayîş (n)",
    "y": " randevulaşma"
  },
  {
    "x": "Rangûn (n)",
    "y": " Rangun"
  },
  {
    "x": "ranza (m)",
    "y": " ranza "
  },
  {
    "x": "rapa-rut",
    "y": " b. rut-repal, -e"
  },
  {
    "x": "raperayene",
    "y": " 1)kanatlanmak, uçmak\r\n~2)akmak (yıldız akmak), kaymak (yıldız kaymak)"
  },
  {
    "x": "raperayî, -ye",
    "y": " uçucu"
  },
  {
    "x": "raperayîş (n)",
    "y": " 1)kanatlanış, kanatlanma, uçma\r\n~2)kayma (yıldız kayması)"
  },
  {
    "x": "rapernayene",
    "y": " kanatlandırmak, uçurmak"
  },
  {
    "x": "rapernayîş (n)",
    "y": " kanatlandırma, uçurma"
  },
  {
    "x": "raperrayene",
    "y": " b. raperayene"
  },
  {
    "x": "raperrayêne",
    "y": " b. raperayene"
  },
  {
    "x": "raperrayîş (n)",
    "y": " b. raperayîş (n)"
  },
  {
    "x": "raperrnayene",
    "y": " b. rapernayene"
  },
  {
    "x": "raperrnayîş (n)",
    "y": " b. rapernayîş (n)"
  },
  {
    "x": "rapor (n)",
    "y": " rapor"
  },
  {
    "x": "rapor kerdene",
    "y": " raporlamak"
  },
  {
    "x": "rapor nuştene",
    "y": " raporlamak"
  },
  {
    "x": "raporê ekspertî/e (n)",
    "y": " bilirkişi raporu"
  },
  {
    "x": "raporê îtfaîya (n)",
    "y": " itfaiye raporu"
  },
  {
    "x": "raporê kombîyayîşî (n)",
    "y": " toplantı raporu"
  },
  {
    "x": "raporê kontrol û analîzî (n)",
    "y": " muayene ve analiz raporu"
  },
  {
    "x": "raporê kontrolî (n)",
    "y": " muayene raporu"
  },
  {
    "x": "raporê muayene û analîzî (n)",
    "y": " muayene ve analiz raporu"
  },
  {
    "x": "raporê muayeneyî (n)",
    "y": " muayene raporu"
  },
  {
    "x": "raporê netîce (n)",
    "y": " sonuç raporu"
  },
  {
    "x": "raporê peynî (m)",
    "y": " sonuç raporu"
  },
  {
    "x": "raporê peynîye (m)",
    "y": " sonuç raporu"
  },
  {
    "x": "raporê pisporî/e (n)",
    "y": " bilirkişi raporu"
  },
  {
    "x": "raporê planî (n)",
    "y": " plan raporu"
  },
  {
    "x": "raporê şewatî (n)",
    "y": " yangın raporu"
  },
  {
    "x": "raporê tesîrê çorşmeyîyî (n) ",
    "y": "çevresel etki raporu"
  },
  {
    "x": "raporê tesîrê dorûverîyî (n)",
    "y": " çevresel etki raporu"
  },
  {
    "x": "raporê weşîye (n)",
    "y": " sağlık raporu"
  },
  {
    "x": "raporê xebate (n)",
    "y": " faaliyet raporu"
  },
  {
    "x": "raporo teknîkî (n)",
    "y": " teknik rapor"
  },
  {
    "x": "raporkerdiş (n)",
    "y": " raporlama"
  },
  {
    "x": "raporkerdişê performansî",
    "y": " performans raporlama"
  },
  {
    "x": "rapornuştişê performansî",
    "y": " performans raporlama"
  },
  {
    "x": "rapornuştiş (n)",
    "y": " raporlama"
  },
  {
    "x": "raqa (m)",
    "y": " b. reqî (m)"
  },
  {
    "x": "raqawer, -e",
    "y": " b. reqîwer, -e"
  },
  {
    "x": "raqilasîyayene",
    "y": " b. raqilaşîyayene"
  },
  {
    "x": "raqilasîyayîş (n)",
    "y": " b. raqilaşîyayîş"
  },
  {
    "x": "raqilasîyene",
    "y": " b. raqilaşîyayene"
  },
  {
    "x": "raqilasnayene",
    "y": " b. raqilaşnayene"
  },
  {
    "x": "raqilasnayêne",
    "y": " b. raqilaşnayene"
  },
  {
    "x": "raqilasnayîş (n)",
    "y": " b. raqilaşnayîş (n)"
  },
  {
    "x": "raqilasyayîş (n)",
    "y": " b. raqilaşîyayîş (n)"
  },
  {
    "x": "raqilaşayine",
    "y": " b. raqilaşîyayene"
  },
  {
    "x": "raqilaşîyayene",
    "y": " çatlamak (deri vb. için), yarılmak"
  },
  {
    "x": "raqilaşîyayêne",
    "y": " b. raqilaşîyayene"
  },
  {
    "x": "raqilaşîyayine",
    "y": " b. raqilaşîyayene"
  },
  {
    "x": "raqilaşîyayîş (n)",
    "y": " çatlama (deri vb. için), yarılma"
  },
  {
    "x": "raqilaşnayene",
    "y": " çatlatmak (deri vb. için), yarmak"
  },
  {
    "x": "raqilaşyayîş (n)",
    "y": " b. raqilaşîyayîş (n)"
  },
  {
    "x": "raqilaşnayene",
    "y": " yarmak"
  },
  {
    "x": "raqilaşnayîş (n)",
    "y": " yarma"
  },
  {
    "x": "raqilaynayene",
    "y": " sökmek"
  },
  {
    "x": "raqilaynayîş (n)",
    "y": " sökme, söküm\r\n~raqilaynayîşê hûmarkerî (n): sayaç sökümü"
  },
  {
    "x": "raqilaynayîşê hûmarkerî (n)",
    "y": " sayaç sökümü"
  },
  {
    "x": "raqilênayene",
    "y": " b. raqilaynayene"
  },
  {
    "x": "raqilênayîş (n)",
    "y": " b. raqilaynayîş (n)"
  },
  {
    "x": "raqî (m)",
    "y": " b. reqî (m)"
  },
  {
    "x": "rar (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "rar kewtene",
    "y": " b. rayîr kewtene"
  },
  {
    "x": "rar vistene",
    "y": " b. rayîr fîştene"
  },
  {
    "x": "rar a şîyayîne",
    "y": " b. rayîr ra şîyene"
  },
  {
    "x": "rar ra şîyayîne",
    "y": " b. rayîr ra şîyene"
  },
  {
    "x": "rarê pûmpûli (n)",
    "y": " b. rayîrê papûre (n)"
  },
  {
    "x": "raraşeyîş (n)",
    "y": " b. rayîrraşîyayîş (n)"
  },
  {
    "x": "rarayfîştiş (n)",
    "y": " geçirme, yolcu etme"
  },
  {
    "x": "rarayekewtiş (n)",
    "y": " yola çıkma, hareket etme, yola düşme, yola koyulma"
  },
  {
    "x": "rareyayîş (n)",
    "y": " kurtuluş"
  },
  {
    "x": "rareydayene",
    "y": " b. rarîdayene"
  },
  {
    "x": "rarêdayene",
    "y": " b. rarîdayene"
  },
  {
    "x": "rarêdayîs (n)",
    "y": " b. rarîdayîş (n)"
  },
  {
    "x": "rarêdayîş (n)",
    "y": " b. rarîdayîş (n)"
  },
  {
    "x": "rarêdnayene",
    "y": " b. rarîdayîş (n)"
  },
  {
    "x": "rarêdnayîs (n)",
    "y": " b. rarîdayîş (n)"
  },
  {
    "x": "rarêsikîyayene",
    "y": " kaymak\r\n~\tLajê mi rêsikîya ra, gina erd ro. (Oğlum kayıp düştü.)\r\n~ \t\t\r\n~Ez risken ra. (Ben kayıyorum"
  },
  {
    "x": "rarêsikîyayîş (n)",
    "y": " kayma"
  },
  {
    "x": "rarêsiknayene",
    "y": " kaydırmak"
  },
  {
    "x": "rarêsiknayîş (n)",
    "y": " kaydırma"
  },
  {
    "x": "rarêyayîş (n)",
    "y": " b. rareyayîş (n)"
  },
  {
    "x": "rariskîyayene",
    "y": " b. rarêsikîyayene "
  },
  {
    "x": "rariskîyayîş (n)",
    "y": " b. rarêsikîyayîş (n)"
  },
  {
    "x": "rarîdayene",
    "y": " öğütmek"
  },
  {
    "x": "rarîdayîs (n)",
    "y": " b. rarîdayîş (n)"
  },
  {
    "x": "rarîdayîş (n)",
    "y": " öğütme"
  },
  {
    "x": "rarîyayene",
    "y": " b. rarîdayene"
  },
  {
    "x": "rarîyayîş (n)",
    "y": " b. rarîdayîş (n)"
  },
  {
    "x": "rarkewtiş (n)",
    "y": " b. rayîrkewtiş (n)"
  },
  {
    "x": "rarraşeyîş (n)",
    "y": " b. rayîrraşîyayîş (n)"
  },
  {
    "x": "rarvistiş (n)",
    "y": " b. rayîrfîştiş (n)"
  },
  {
    "x": "rasanayene",
    "y": " b. raşanayene"
  },
  {
    "x": "rasanayîş (n)",
    "y": " b. raşanayîş (n)"
  },
  {
    "x": "rasaya (m)",
    "y": " b. resaya (m), resayîye (m)"
  },
  {
    "x": "rasayene",
    "y": " b. resayene"
  },
  {
    "x": "rasayi (n)",
    "y": " b. resaye (n)"
  },
  {
    "x": "rasayîş (n)",
    "y": " b. resayîş (n)"
  },
  {
    "x": "rasel (n)",
    "y": " b. laser (n)"
  },
  {
    "x": "rasimotîyayêne",
    "y": " b. raşemitîyayene"
  },
  {
    "x": "rasimotîyayîş (n)",
    "y": " b. raşemitîyayîş (n)"
  },
  {
    "x": "rasî (m)",
    "y": " tarla veya arazinin başka tarla veya araziler arasında kalan küçük ve dar bölümü"
  },
  {
    "x": "rasnayene",
    "y": " b. resnayene"
  },
  {
    "x": "rasnayîş (n)",
    "y": " b. resnayîş (n)"
  },
  {
    "x": "rasne (n)",
    "y": " b. resene (n)"
  },
  {
    "x": "rasni (n)",
    "y": " b. resene (n)"
  },
  {
    "x": "rast, -e-I",
    "y": " 1)doğru\r\n~2)gerçek, ciddi\r\n~3)sağ"
  },
  {
    "x": "rast bîyene",
    "y": " 1)doğru olmak\r\n~2)gerçek olmak, ciddi olmak"
  },
  {
    "x": "rast kerdene",
    "y": " 1)düzeltmek, ıslah etmek\r\n~2)geçirmek, uğurlamak (bir yere kadar birlikte yürüyerek uğurlamak)"
  },
  {
    "x": "rast, -e-II",
    "y": " düz"
  },
  {
    "x": "rastê ... ra",
    "y": " düzeyinde, hizasında, seviyesinde"
  },
  {
    "x": "rasta",
    "y": " doğrusu, gerçekten, hakikaten"
  },
  {
    "x": "rasta ci",
    "y": " b. rasta xo, raşta xo"
  },
  {
    "x": "rasta aye",
    "y": " doğrusu, açıkçası"
  },
  {
    "x": "rasta ey",
    "y": " doğrusu, açıkçası"
  },
  {
    "x": "rasta xo",
    "y": " doğrusu, açıkçası"
  },
  {
    "x": "rastarast",
    "y": " b. rasterast"
  },
  {
    "x": "raste (n)",
    "y": " düzlük, meydan"
  },
  {
    "x": "rasteyê teyara (n)",
    "y": " havaalanı, uçak alanı, tayyare meydanı"
  },
  {
    "x": "rasterast",
    "y": " açıkça"
  },
  {
    "x": "rastey (m)",
    "y": " b. rastîye (m)"
  },
  {
    "x": "rastgir, -e",
    "y": " sağcı"
  },
  {
    "x": "rastikên, -e",
    "y": " gerçek, hakiki"
  },
  {
    "x": "rastiko",
    "y": " doğrusu, gerçekten, hakikaten"
  },
  {
    "x": "rastî (m)",
    "y": " 1)doğruluk\r\n~2)gerçek, gerçeklik, realite\r\n~3)düzlük"
  },
  {
    "x": "rastîvîn, -e",
    "y": " gerçekçi, realist"
  },
  {
    "x": "rastîvînayox, -e",
    "y": " realist"
  },
  {
    "x": "rastîvînî (m)",
    "y": " gerçekçilik, realizm\r\n~rastîvînîya newîye (m): yeni gerçekçilik"
  },
  {
    "x": "rastîvînîya newa (m)",
    "y": " yeni gerçekçilik, yeni realizm"
  },
  {
    "x": "rastîvînîya newîye (m)",
    "y": " yeni gerçekçilik"
  },
  {
    "x": "rastîvînîya şaîrane (m)",
    "y": " şairane gerçekçilik"
  },
  {
    "x": "rastîvînîya şaîrkî (m)",
    "y": " şairane gerçekçilik, şairane realizm"
  },
  {
    "x": "rastîvînîya şîîrkî (m)",
    "y": " şiirsel gerçekçilik, şiirsel realizm"
  },
  {
    "x": "rastîye (m)",
    "y": " 1)doğruluk\r\n~2)gerçek, gerçeklik, realite\r\n~3)düzlük"
  },
  {
    "x": "rastkerdiş (n)",
    "y": " 1)düzelti, düzeltme, tashih; ıslah, ıslah etme\r\n~2)geçirme, uğurlama (bir yere kadar birlikte yürüyerek uğurlama)"
  },
  {
    "x": "rastkerdişê cinsî (n)",
    "y": " cins tashihi (taşınmaz mal için)"
  },
  {
    "x": "rastkerdişê îmajî (n)",
    "y": " imaj düzeltme"
  },
  {
    "x": "rastkên, -e",
    "y": " b. rastikên, -e"
  },
  {
    "x": "rastnuştiş (n)",
    "y": " yazım, imla"
  },
  {
    "x": "rastvaj, -e",
    "y": " açık sözlü"
  },
  {
    "x": "rastvajîye (m)",
    "y": " açık sözlülük"
  },
  {
    "x": "rastxêze (m)",
    "y": " doğru (mat)"
  },
  {
    "x": "rasyonalîst, -e",
    "y": " akılcı, usçu, rasyonalist"
  },
  {
    "x": "rasyonalîstî (m)",
    "y": " akılcılık, usçuluk, rasyonalizm"
  },
  {
    "x": "rasyonalîzm (n)",
    "y": " akılcılık, usçuluk, rasyonalizm"
  },
  {
    "x": "rasyonel, -e",
    "y": " akli, akılsal, akla uygun"
  },
  {
    "x": "raşanayene",
    "y": " 1)saçmak, serpmek\r\n~2)yıkmak\r\n~3)(mecazi) aşırı harcamak"
  },
  {
    "x": "raşanayîş (n)",
    "y": " 1)saçma, serpme\r\n~2)yıkım, yıkma\r\n~3)(mecazi) aşırı harcama"
  },
  {
    "x": "raşanitene",
    "y": " -e karıştırmak\r\n~Mi genim şanit ra cewî. (Buğdayı arpaya karıştırdım.)"
  },
  {
    "x": "raşanîyayene",
    "y": " (mecazi) aşırı harcanmak"
  },
  {
    "x": "raşanîyayîş (n)",
    "y": " (mecazi) aşırı harcanma"
  },
  {
    "x": "raşayîne",
    "y": " b. raşanayene"
  },
  {
    "x": "raşayîş (n)",
    "y": " b. raşanayîş (n)"
  },
  {
    "x": "raşemitîyayene",
    "y": " kaymak"
  },
  {
    "x": "raşemitîyayîş (n)",
    "y": " kayma"
  },
  {
    "x": "raşemitnayene",
    "y": " kaydırmak"
  },
  {
    "x": "raşemitnayîş (n)",
    "y": " kaydırma"
  },
  {
    "x": "... raşîyayîş (n)",
    "y": " batma (sivri şeyin batması)"
  },
  {
    "x": "raşn (n)",
    "y": " b. roşn (n)"
  },
  {
    "x": "raşonayene",
    "y": " b. raşanayene"
  },
  {
    "x": "raşonayîş (n)",
    "y": " b. raşanayîş (n)"
  },
  {
    "x": "raşt, -e-I",
    "y": " 1)doğru\r\n~2)gerçek, ciddi\r\n~3)sağ"
  },
  {
    "x": "raşt bîyayîne",
    "y": " b. raşt bîyene"
  },
  {
    "x": "raşt bîyene",
    "y": " 1)doğru olmak\r\n~2)gerçek olmak, ciddi olmak"
  },
  {
    "x": "raşt kerdene",
    "y": " 1)düzeltmek, ıslah etmek\r\n~2)geçirmek, uğurlamak (bir yere kadar birlikte yürüyerek uğurlamak)"
  },
  {
    "x": "raşt, -e-II",
    "y": " düz"
  },
  {
    "x": "raştê ... ra",
    "y": " düzeyinde, hizasında, seviyesinde"
  },
  {
    "x": "raşta",
    "y": " doğrusu, gerçekten, hakikaten"
  },
  {
    "x": "raşta aye",
    "y": " doğrusu, açıkçası"
  },
  {
    "x": "raşta ci",
    "y": " b. rasta xo, raşta xo"
  },
  {
    "x": "raşta ey",
    "y": " doğrusu, açıkçası"
  },
  {
    "x": "raşta xo",
    "y": " doğrusu, açıkçası"
  },
  {
    "x": "raşta yay",
    "y": " b. raşta aye"
  },
  {
    "x": "raşta yê",
    "y": " b. raşta ey"
  },
  {
    "x": "raştaraşt",
    "y": " b raşteraşt"
  },
  {
    "x": "raşte (n)",
    "y": " düzlük, meydan"
  },
  {
    "x": "raşteyê teyara (n)",
    "y": " havaalanı, uçak alanı, tayyare meydanı"
  },
  {
    "x": "raşteraşt",
    "y": " açıkça"
  },
  {
    "x": "raştey (m)",
    "y": " b. raştîye (m)"
  },
  {
    "x": "raştê (m)",
    "y": " b. raştîye (m)"
  },
  {
    "x": "raştgir, -e",
    "y": " sağcı"
  },
  {
    "x": "raşti-I (n)",
    "y": " b. raşte (n)"
  },
  {
    "x": "raşti-II (m)",
    "y": " b. raşte (m)"
  },
  {
    "x": "raştikên, -e",
    "y": " gerçek, hakiki"
  },
  {
    "x": "raştikî",
    "y": " b. raştikên, -e"
  },
  {
    "x": "raştikîn, -i",
    "y": " b. raştikên, -e"
  },
  {
    "x": "raştiko",
    "y": " doğrusu, gerçekten, hakikaten"
  },
  {
    "x": "raştî (m)",
    "y": " 1)doğruluk\r\n~2)gerçek, gerçeklik, realite\r\n~3)düzlük"
  },
  {
    "x": "raştîken, -e",
    "y": " b. raştikên, -e"
  },
  {
    "x": "raştîne (m)",
    "y": " b. raştîye (m)"
  },
  {
    "x": "raştîvîn, -e",
    "y": " gerçekçi, realist"
  },
  {
    "x": "raştîvînayox, -e",
    "y": " realist"
  },
  {
    "x": "raştîvînî (m)",
    "y": " gerçekçilik, realizm"
  },
  {
    "x": "raştîvînîya newa (m)",
    "y": " yeni gerçekçilik, yeni realizm"
  },
  {
    "x": "raştîvînîya newîye (m)",
    "y": " yeni gerçekçilik"
  },
  {
    "x": "raştîvînîya şaîrane (m)",
    "y": " şairane gerçekçilik"
  },
  {
    "x": "raştîvînîya şaîrkî (m)",
    "y": " şairane gerçekçilik, şairane realizm"
  },
  {
    "x": "raştîvînîya şîîrkî (m)",
    "y": " şiirsel gerçekçilik, şiirsel realizm"
  },
  {
    "x": "raştîya",
    "y": " b. raşta"
  },
  {
    "x": "raştîye (m)",
    "y": " 1)doğruluk\r\n~2)gerçek, gerçeklik, realite\r\n~3)düzlük"
  },
  {
    "x": "raştkerdiş (n)",
    "y": " 1)düzelti, düzeltme, tashih; ıslah, ıslah etme\r\n~2)geçirme, uğurlama (bir yere kadar birlikte yürüyerek uğurlama)"
  },
  {
    "x": "raştkerdişê cinsî (n)",
    "y": " cins tashihi (taşınmaz mal için)"
  },
  {
    "x": "raştkerdişê îmajî (n)",
    "y": " imaj düzeltme"
  },
  {
    "x": "raştkên, -e",
    "y": " b. raştikên, -e"
  },
  {
    "x": "raştnuştiş (n)",
    "y": " yazım, imla"
  },
  {
    "x": "raştvaj, -e",
    "y": " açık sözlü"
  },
  {
    "x": "raştvajî (m)",
    "y": " açık sözlülük"
  },
  {
    "x": "ratenitene",
    "y": " örmek\r\n~\tKênaya mi puç tenit ra. (Kızım çorap ördü.)"
  },
  {
    "x": "ratenitox, -e",
    "y": " b. riştox, -e"
  },
  {
    "x": "raurznayene",
    "y": " b. rawuriznayene"
  },
  {
    "x": "raurznayîs (n)",
    "y": " b. rawuriznayîş (n)"
  },
  {
    "x": "raurznayîş (n)",
    "y": " b. rawuriznayîş (n)"
  },
  {
    "x": "raustene",
    "y": " b. rawuştene"
  },
  {
    "x": "raustiş (n)",
    "y": " b. rawuştiş (n)"
  },
  {
    "x": "rauştene",
    "y": " b. rawuştene"
  },
  {
    "x": "rauştiş (n)",
    "y": " b. rawuştiş (n)"
  },
  {
    "x": "raûmeyi (n)",
    "y": " b. raameye (n)"
  },
  {
    "x": "raûmeyîne",
    "y": " b. raameyene"
  },
  {
    "x": "raûmeyîş (n)",
    "y": " b. raameyîş (n)"
  },
  {
    "x": "rave (n)",
    "y": " b. raver (n)"
  },
  {
    "x": "rave şîyayene",
    "y": " b. raver şîyene"
  },
  {
    "x": "raver (n)",
    "y": " ileri"
  },
  {
    "x": "raver berdene",
    "y": " ilerletmek, geliştirmek"
  },
  {
    "x": "raver şîyayene",
    "y": " b. raver şîyene"
  },
  {
    "x": "raver şîyene",
    "y": " ilerlemek, gelişmek"
  },
  {
    "x": "raverberdiş (n)",
    "y": " ilerletme, geliştirme"
  },
  {
    "x": "raverdaene",
    "y": " b. raverdayene"
  },
  {
    "x": "raverdaîs (n)",
    "y": " b. raverdayîş (n)"
  },
  {
    "x": "raverdayene",
    "y": " bırakmak, serbest bırakmak"
  },
  {
    "x": "heywan raverdayene",
    "y": " otlaması için hayvanı araziye başıboş salmak"
  },
  {
    "x": "raverdayêne",
    "y": " b. raverdayene"
  },
  {
    "x": "heywan raverdayêne",
    "y": " b. heywan raverdayene"
  },
  {
    "x": "raverdayîs (n)",
    "y": " b. raverdayîş (n)"
  },
  {
    "x": "raverdayîş (n)",
    "y": " bırakma, serbest bırakma"
  },
  {
    "x": "ravernayîş (n)",
    "y": " b. ravîyarnayîş (n)"
  },
  {
    "x": "raverşîyayîş (n)",
    "y": " 1)ilerleme\r\n~2)gelişme, gelişim"
  },
  {
    "x": "raverşîyayîşê kapasîte (n)",
    "y": " kapasite gelişimi"
  },
  {
    "x": "raverşîyayîşo ramitbar (n)",
    "y": " sürdürülebilir gelişme"
  },
  {
    "x": "ravestiş (n)",
    "y": " b. rawuştiş (n)"
  },
  {
    "x": "raveştene",
    "y": " b. Rawuştene\r\n~veşt ra: kalktı\r\n~ma ravezme: biz kalkalım\r\n~ravê: kalkınız\r\n~meveze ra: kalkma"
  },
  {
    "x": "veşt ra",
    "y": " kalktı"
  },
  {
    "x": "ma ravezme",
    "y": " biz kalkalım"
  },
  {
    "x": "ravê",
    "y": " kalkınız"
  },
  {
    "x": "meveze ra",
    "y": " kalkma"
  },
  {
    "x": "raveştiş (n)",
    "y": " b. rawuştiş (n)"
  },
  {
    "x": "ravey (n)",
    "y": " b. raver (n)"
  },
  {
    "x": "ravey berdene",
    "y": " b. raver berdene"
  },
  {
    "x": "ravey berdeni",
    "y": " b. raver berdene"
  },
  {
    "x": "ravey şîyayene",
    "y": " b. raver şîyene"
  },
  {
    "x": "raveyberdiş n)",
    "y": " b. raverberdiş (n)"
  },
  {
    "x": "raveyşîyayîş (n)",
    "y": " b. raverşîyayîş (n)"
  },
  {
    "x": "ravê (n)",
    "y": " b. raver (n)"
  },
  {
    "x": "ravê berdene",
    "y": " b. raver berdene"
  },
  {
    "x": "ravêr (n)",
    "y": " b. raver (n)"
  },
  {
    "x": "ravêr berdene",
    "y": " b. raver berdene"
  },
  {
    "x": "ravêrdene",
    "y": " b. ravîyartene"
  },
  {
    "x": "ravêrdeni",
    "y": " b. ravîyartene"
  },
  {
    "x": "ravêretene",
    "y": " b. ravîyartene"
  },
  {
    "x": "ravêrnayene",
    "y": " b. ravîyarnayene"
  },
  {
    "x": "ravêrnayîş (n)",
    "y": " b. ravîyarnayîş (n)"
  },
  {
    "x": "ravêrtene",
    "y": " b. ravîyartene"
  },
  {
    "x": "ravêy (n)",
    "y": " b. raver (n)"
  },
  {
    "x": "raviraznayene",
    "y": " b. ravirêjnayene "
  },
  {
    "x": "ravircîyayîne",
    "y": " b. ravirêjîyayene"
  },
  {
    "x": "ravirecayene",
    "y": " b. raviritene"
  },
  {
    "x": "ravirecayêne",
    "y": " b. raviritene"
  },
  {
    "x": "ravirecîyayene",
    "y": " b. ravirêjîyayene"
  },
  {
    "x": "ravirecîyayêne",
    "y": " b. ravirêjîyayene"
  },
  {
    "x": "raviretene",
    "y": " b. raviritene"
  },
  {
    "x": "ravireznayini",
    "y": " b. ravirêjnayene"
  },
  {
    "x": "ravirêcayêne",
    "y": " b. raviritene"
  },
  {
    "x": "ravirêcayîyêne",
    "y": " b. ravirêjîyayene"
  },
  {
    "x": "ravirêjayene",
    "y": " b. raviritene"
  },
  {
    "x": "ravirêjîyayene",
    "y": " kusulmak"
  },
  {
    "x": "ravirêjnayene",
    "y": " kusmak, istifrağ etmek\r\n~mi virêjna ra: ben kustum"
  },
  {
    "x": "mi virêjna ra",
    "y": " ben kustum"
  },
  {
    "x": "ravirêjnayîş (n)",
    "y": " kusma, istifrağ etme"
  },
  {
    "x": "ravirêtene",
    "y": " b. raviritene"
  },
  {
    "x": "raviritene",
    "y": " kusmak, istifrağ etmek\r\n~mi virit ra: ben kustum"
  },
  {
    "x": "mi virit ra",
    "y": " ben kustum"
  },
  {
    "x": "raviritiş (n)",
    "y": " kusma, istifrağ etme"
  },
  {
    "x": "ravirtene",
    "y": " b. raviritene"
  },
  {
    "x": "ravistene",
    "y": " b. rafîştene"
  },
  {
    "x": "ravistiş (n)",
    "y": " b. rafîştiş (n)"
  },
  {
    "x": "ravişkîyene",
    "y": " b. rabişkivîyene"
  },
  {
    "x": "ravîrameyîş (n)",
    "y": " anımsama, hatırlama"
  },
  {
    "x": "ravîrardiş (n)",
    "y": " 1)anımsatma, hatırlatma; çağrıştırma (akla getirme)\r\n~2)ihtar"
  },
  {
    "x": "ravîyarnayene",
    "y": " 1)geçirmek, uğurlamak (bir yere kadar birlikte yürüyerek uğurlamak)\r\n~2)geçinmek"
  },
  {
    "x": "ravîyarnayene ...",
    "y": " bulaştırmak (hastalık için)"
  },
  {
    "x": "ravîyarnayîş (n)",
    "y": " 1)geçirme, uğurlama (bir yere kadar birlikte yürüyerek uğurlama)\r\n~2)geçinme"
  },
  {
    "x": "ravîyarnayîş ...",
    "y": " bulaştırma (hastalık için) "
  },
  {
    "x": "ravîyartene",
    "y": " 1)geçmek\r\n~2)bulaşmak (hastalık için)\r\n~3)akmak (zaman için)"
  },
  {
    "x": "ravîyartiş (n)",
    "y": " 1)geçme\r\n~2)bulaşma (hastalık için)"
  },
  {
    "x": "ravîyartişê demî (n)",
    "y": " süre aşımı, zaman aşımı, müruruzaman"
  },
  {
    "x": "ravîyernayene",
    "y": " b. ravîyarnayene"
  },
  {
    "x": "ravîyernayîş (n)",
    "y": " b. ravîyarnayîş (n)"
  },
  {
    "x": "ravîyertene",
    "y": " b. ravîyartene"
  },
  {
    "x": "ravor de",
    "y": " b. vor de"
  },
  {
    "x": "ravurîyayene",
    "y": " dönüşmek"
  },
  {
    "x": "ravurîyayîş (n)",
    "y": " dönüşme, dönüşüm\r\n~ravurîyayîşo bajarkî (n): kentsel dönüşüm\r\n~ravurîyayîşo şaristankî (n): kentsel dönüşüm"
  },
  {
    "x": "ravurîyayîşo bajarkî (n)",
    "y": " kentsel dönüşüm"
  },
  {
    "x": "ravurîyayîşo şaristankî (n)",
    "y": " kentsel dönüşüm"
  },
  {
    "x": "ravurnayene",
    "y": " dönüştürmek"
  },
  {
    "x": "ravurnayîş (n)",
    "y": " dönüştürme"
  },
  {
    "x": "ravurrîyayêne",
    "y": " b. ravurîyayene"
  },
  {
    "x": "ravurrîyayîş (n)",
    "y": " b. ravurîyayîş (n)"
  },
  {
    "x": "ravyarnayîş (n)",
    "y": " b. ravîyarnayîş (n)"
  },
  {
    "x": "raw (n)",
    "y": " b. rewa (n)"
  },
  {
    "x": "rawe (m)",
    "y": " kip"
  },
  {
    "x": "raweya fermanî (m)",
    "y": " emir kipi"
  },
  {
    "x": "raweya ganîyîye (m)",
    "y": " gereklik kipi"
  },
  {
    "x": "raweya şertî (m)",
    "y": " şart kipi, dilek-şart kipi"
  },
  {
    "x": "raweya waştişî (m)",
    "y": " istek kipi"
  },
  {
    "x": "raweştayêne",
    "y": " b. rawuştene"
  },
  {
    "x": "raweştayîş (n)",
    "y": " b. rawuştiş (n)"
  },
  {
    "x": "raweştene",
    "y": " b. rawuştene"
  },
  {
    "x": "raweştiş (n)",
    "y": " b. rawuştiş (n)"
  },
  {
    "x": "rawirznayene",
    "y": " b. rawuriznayene"
  },
  {
    "x": "rawirznayîş (n)",
    "y": " b. rawuriznayîş (n)"
  },
  {
    "x": "rawistene",
    "y": " b. rawuştene"
  },
  {
    "x": "rawistiş (n)",
    "y": " b. rawuştiş (n)"
  },
  {
    "x": "rawiştene",
    "y": " b. rawuştene"
  },
  {
    "x": "rawiştiş (n)",
    "y": " b. rawuştiş (n)"
  },
  {
    "x": "rawkotiş (n)",
    "y": " b. rakewtiş (n)"
  },
  {
    "x": "rawuriznayene",
    "y": " kaldırmak"
  },
  {
    "x": "rawuriznayîş (n)",
    "y": " kaldırma"
  },
  {
    "x": "rawuriznîyayene",
    "y": " kaldırılmak"
  },
  {
    "x": "rawuriznîyayîş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "rawurznayene",
    "y": " b. rawuriznayene"
  },
  {
    "x": "rawurznayîş (n)",
    "y": " b. rawuriznayîş (n)"
  },
  {
    "x": "rawustaene",
    "y": " b. rawuştene"
  },
  {
    "x": "rawustayîş (n)",
    "y": " b. rawuştiş (n)"
  },
  {
    "x": "rawustene",
    "y": " b. rawuştene"
  },
  {
    "x": "rawustiş (n)",
    "y": " b. rawuştiş (n)"
  },
  {
    "x": "rawuştene",
    "y": " kalkmak"
  },
  {
    "x": "rawuştiş (n)",
    "y": " kalkma"
  },
  {
    "x": "raxelesayîş (n)",
    "y": " b. xelisîyayîş (n), raxelisîyayîş (n)"
  },
  {
    "x": "raxelesîyayene",
    "y": " b. raxelisîyayene"
  },
  {
    "x": "raxelesîyayîş (n)",
    "y": " b. xelisîyayîş (n), raxelisîyayîş (n)"
  },
  {
    "x": "raxelesîyene",
    "y": " b. xelisîyayene, raxelisîyayene"
  },
  {
    "x": "raxelesnayene",
    "y": " b. xelisnayene"
  },
  {
    "x": "raxelesnayêne",
    "y": " b. xelisnayene"
  },
  {
    "x": "raxeleşîyayene",
    "y": " b. xelisîyayene"
  },
  {
    "x": "raxeleşîyene",
    "y": " b. xelisîyayene"
  },
  {
    "x": "raxelisîyayene",
    "y": " kurtulmak"
  },
  {
    "x": "raxelisîyayîş (n)",
    "y": " kurtulma, kurtuluş"
  },
  {
    "x": "raxelisnayene",
    "y": " b. xelisnayene"
  },
  {
    "x": "raxovîrardiş (n)",
    "y": " anımsama, hatırlama"
  },
  {
    "x": "ray-I (m)",
    "y": " 1)oy\r\n~2)görüş"
  },
  {
    "x": "ray dayene",
    "y": " oy vermek, oylamak"
  },
  {
    "x": "ray-II (m)",
    "y": " b. rey-I (m)"
  },
  {
    "x": "ray-ray",
    "y": " b. rey-rey"
  },
  {
    "x": "rayê",
    "y": " b. reyê"
  },
  {
    "x": "rayê-di ray",
    "y": " b. reyê-di reyî"
  },
  {
    "x": "rayki",
    "y": " b. reyke"
  },
  {
    "x": "ray-III (m)",
    "y": " 1)b. rayîr (n)\r\n~2)ray"
  },
  {
    "x": "ray fîştene",
    "y": " yolcu etmek, geçirmek"
  },
  {
    "x": "ray kerdene",
    "y": " göndermek, yollamak"
  },
  {
    "x": "ray mendene",
    "y": " yola çıkmak, hareket etmek, yola düşmek, yola koyulmak\r\n~Ti key ray manenî? (Ne zaman yola çıkıyorsun?)"
  },
  {
    "x": "ray şîyayeni",
    "y": " b. ray şîyene"
  },
  {
    "x": "ray şîyene",
    "y": " yürümek"
  },
  {
    "x": "rayan, -i",
    "y": " b. raywan, -e"
  },
  {
    "x": "rayaney (n)",
    "y": " b. raywanîye (n)"
  },
  {
    "x": "rayanî (m)",
    "y": " b. raywanîye (n)"
  },
  {
    "x": "rayapêroyî (m)",
    "y": " kamuoyu"
  },
  {
    "x": "rayaumûmî (m)",
    "y": " kamuoyu"
  },
  {
    "x": "rayba (m)",
    "y": " b. rahîbe (m)"
  },
  {
    "x": "rayber, -e",
    "y": " 1)kılavuz, rehber\r\n~rayberê nuştişê tezî (n):  tez yazım kılavuzu, tez yazım rehberi\r\n~2)lider, önder "
  },
  {
    "x": "rayberê nuştişê tezî (n)",
    "y": " tez yazım kılavuzu, tez yazım rehberi"
  },
  {
    "x": "rayberî (m)",
    "y": " kılavuzluk, rehberlik"
  },
  {
    "x": "rayberîye (m)",
    "y": " kılavuzluk, rehberlik"
  },
  {
    "x": "raydayîş (n)",
    "y": " oylama"
  },
  {
    "x": "raydon (m)",
    "y": " b. radyo (n)"
  },
  {
    "x": "raydûn (m)",
    "y": " b. radyo (n)"
  },
  {
    "x": "raye-I (m)",
    "y": " b. rey-I (m)"
  },
  {
    "x": "raye-II (m)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "Raya to rakerdîye bo.",
    "y": " b. Rayîrê to akerde bo."
  },
  {
    "x": "Raya to akerdê bo.",
    "y": " b. Rayîrê to akerde bo."
  },
  {
    "x": "raye fîştene",
    "y": " b. ray fîştene"
  },
  {
    "x": "raye kerdene",
    "y": " b. ray kerdene"
  },
  {
    "x": "raye ra vejîyayene",
    "y": " (iş) çığırından çıkmak, yoldan çıkmak"
  },
  {
    "x": "raye ra waro ginayene (elewîyîye de)",
    "y": " düşkün olmak (Alevilikte)\r\n~Hesen raye ra gino waro. (Hasan düşkün ilan edilmiş.)"
  },
  {
    "x": "raye ra waroginaye (n) (elewîyîye de)",
    "y": " düşkün (Alevilikte)"
  },
  {
    "x": "raye ra waroginayîye (m) (elewîyîye de)",
    "y": " düşkün (Alevilikte)"
  },
  {
    "x": "raye ra warro ginayene (elewîyîye de)",
    "y": " b. raye ra waro ginayene (elewîyîye de)"
  },
  {
    "x": "raye ra warroginaye (n) (elewîyîye de)",
    "y": " b. raye ra waroginaye (n) (elewîyîye de)"
  },
  {
    "x": "raye ra warroginayîye (m) (elewîyîye de)",
    "y": " b. raye ra waroginayîye (m) (elewîyîye de)"
  },
  {
    "x": "rayefîştiş (n)",
    "y": " b. rayfîştiş (n)"
  },
  {
    "x": "rayekerdiş (n)",
    "y": " b. raykerdiş (n)"
  },
  {
    "x": "rayer (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "rayêna",
    "y": " b. reyna"
  },
  {
    "x": "rayêr (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "rayfîştiş (n)",
    "y": " yolcu etme, geçirme"
  },
  {
    "x": "rayir (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "rayîc (n)",
    "y": " rayiç"
  },
  {
    "x": "rayîr (n)",
    "y": " 1)yol, çığır\r\n~Rayîrê to akerde bo. (Yolun açık olsun.)\r\n~rayîr akerdene: yol açmak, çığır açmak\r\n~2)çözüm, hal; çare\r\n~rayîr dîyene:  çözüm bulmak\r\n~rayîr vînayene: çözüm bulmak"
  },
  {
    "x": "Rayîrê to akerde bo. ",
    "y": " Yolun açık olsun"
  },
  {
    "x": "rayîr akerdene",
    "y": " yol açmak, çığır açmak"
  },
  {
    "x": "rayîr fîştene",
    "y": " yolcu etmek, geçirmek"
  },
  {
    "x": "rayîr kewtene",
    "y": " yola çıkmak, hareket etmek, yola düşmek, yola koyulmak\r\n~Rayîr kewt şi. (Yola düşüp gitti.)"
  },
  {
    "x": "rayîr ra şîyayene",
    "y": " b. rayîr ra şîyene"
  },
  {
    "x": "rayîr ra şîyene",
    "y": " yürümek "
  },
  {
    "x": "rayîr ra vejîyayene",
    "y": " yoldan çıkmak, (iş) çığırından çıkmak"
  },
  {
    "x": "rayîr vistene",
    "y": " b. rayîr fîştene"
  },
  {
    "x": "rayîrê bîsîkletan (n)",
    "y": " bisiklet yolu"
  },
  {
    "x": "rayîrê çorşmeyî (n)",
    "y": " çevre yolu"
  },
  {
    "x": "rayîrê dereceya dîyine",
    "y": " tali yol"
  },
  {
    "x": "rayîrê doruverî (n)",
    "y": " çevre yolu"
  },
  {
    "x": "rayîrê eksprese (n)",
    "y": " ekspres yolu"
  },
  {
    "x": "rayîrê peyeyan (n)",
    "y": " yaya yolu"
  },
  {
    "x": "rayîrê pêrabestişî (n)",
    "y": " bağlantı yolu"
  },
  {
    "x": "rayîrê pêragirêdayîşî (n)",
    "y": " bağlantı yolu"
  },
  {
    "x": "rayîrê pampoli (n)",
    "y": " b. rayîrê papûre (n)"
  },
  {
    "x": "rayîrê papore (n)",
    "y": " b. rayîrê papûre (n)"
  },
  {
    "x": "rayîrê papûre (n)",
    "y": " şose"
  },
  {
    "x": "rayîrê pompoli (n)",
    "y": " b. rayîrê papûre (n)"
  },
  {
    "x": "rayîrê remî (n)",
    "y": " kaçış yolu"
  },
  {
    "x": "rayîrê vîyartişî (n)",
    "y": " geçiş yolu"
  },
  {
    "x": "rayîrê wesayîtan (n)",
    "y": " taşıt yolu"
  },
  {
    "x": "rayîro asfalt (n)",
    "y": " asfalt yol"
  },
  {
    "x": "rayîro pîl (n)",
    "y": " cadde, anayol"
  },
  {
    "x": "rayîro sereke (n)",
    "y": " cadde, anayol"
  },
  {
    "x": "rayîro stabîlîze (n)",
    "y": " stabilize yol"
  },
  {
    "x": "rayîro talî (n)",
    "y": " tali yol"
  },
  {
    "x": "rayîro tewr pîl",
    "y": " anacadde"
  },
  {
    "x": "rayîro zigarin (n)",
    "y": " çakıl yol, \t"
  },
  {
    "x": "rayîr dîyene",
    "y": " çözüm bulmak"
  },
  {
    "x": "rayîr vînayene",
    "y": " çözüm bulmak"
  },
  {
    "x": "rayîrakerdiş (n)",
    "y": " yol açma, çığır açma"
  },
  {
    "x": "rayîrfîştiş (n)",
    "y": " yolcu etme, geçirme"
  },
  {
    "x": "rayîrkewtiş (n)",
    "y": " yola çıkma, hareket etme, yola düşme, yola koyulma"
  },
  {
    "x": "rayîrraşayîş (n)",
    "y": " b. rayîrraşîyayîş (n)"
  },
  {
    "x": "rayîrraşeyîş (n)",
    "y": " b. rayîrraşîyayîş (n)"
  },
  {
    "x": "rayîrraşîyayîş (n)",
    "y": " yürüme"
  },
  {
    "x": "rayîrvistiş (n)",
    "y": " b. rayîrfîştiş (n)"
  },
  {
    "x": "rayîv (n)",
    "y": " b. rahîb (n)"
  },
  {
    "x": "raykerdiş (n)",
    "y": " gönderme, yollama"
  },
  {
    "x": "raymendiş (n)",
    "y": " yola çıkma, hareket etme, yola düşme, yola koyulma"
  },
  {
    "x": "rayna",
    "y": " b. reyna"
  },
  {
    "x": "raynêdayox, -e",
    "y": " çekimser"
  },
  {
    "x": "raynêdayoxîye (m)",
    "y": " çekimserlik"
  },
  {
    "x": "rayon, -i",
    "y": " b. raywan, -e"
  },
  {
    "x": "rayşîyayîş (n)",
    "y": " yürüme"
  },
  {
    "x": "rayşt, -i",
    "y": " b. raşt, -e"
  },
  {
    "x": "rayştey (m)",
    "y": " b. raştîye (m)"
  },
  {
    "x": "rayûn, -i",
    "y": " b. raywan, -e"
  },
  {
    "x": "rayver, -e",
    "y": " b. rayber, -e"
  },
  {
    "x": "rayverîye (m)",
    "y": " b. rayberî (m), rayberîye (m)"
  },
  {
    "x": "raywan, -e",
    "y": " yolcu"
  },
  {
    "x": "raywan kerdene",
    "y": " yolcu etmek, geçirmek"
  },
  {
    "x": "raywan neqlkerdiş",
    "y": " yolcu aktarma"
  },
  {
    "x": "raywanîye (n)",
    "y": " seyahat"
  },
  {
    "x": "raywankerdiş (n)",
    "y": " yolcu etme, geçirme"
  },
  {
    "x": "razber, -e",
    "y": " abstre, mücerret, soyut"
  },
  {
    "x": "... razeleqnayîş",
    "y": " b. ... razeliqnayîş"
  },
  {
    "x": "... razeliqnayîş",
    "y": " -e yapıştırma"
  },
  {
    "x": "razî, -ye",
    "y": " razı, hoşnut"
  },
  {
    "x": "razî bîyene",
    "y": " razı olmak, hoşnut olmak"
  },
  {
    "x": "razî kerdene",
    "y": " razı etmek, hoşnut etmek"
  },
  {
    "x": "razîbîyayîş (n)",
    "y": " razı olma"
  },
  {
    "x": "razîkerdiş (n)",
    "y": " razı etme"
  },
  {
    "x": "razîyey (m)",
    "y": " b. razîyî (m), razîyîye (m)"
  },
  {
    "x": "razîyê (m)",
    "y": " b. razîyî (m), razîyîye (m)"
  },
  {
    "x": "razîyî (m)",
    "y": " hoşnutluk"
  },
  {
    "x": "razîyîye (m)",
    "y": " hoşnutluk"
  },
  {
    "x": "re (pd)",
    "y": " 1)b. ra-II (pd)\r\n~2)b. ro-VII (pd)"
  },
  {
    "x": "re ser nayene",
    "y": " b. ser nayene"
  },
  {
    "x": "re-rey",
    "y": " b. rey-rey"
  },
  {
    "x": "reaksîyon (n)",
    "y": " tepki, reaksiyon"
  },
  {
    "x": "reaksîyono ekzotermîk (n)",
    "y": " ekzotermik reaksiyon"
  },
  {
    "x": "reaktîf, -e",
    "y": " reaktif "
  },
  {
    "x": "realîst, -e",
    "y": " gerçekçi, realist "
  },
  {
    "x": "realîte (m)",
    "y": " gerçeklik, hakikat, realite"
  },
  {
    "x": "realîze bîyene",
    "y": " gerçekleştirilmek"
  },
  {
    "x": "realîze kerdene",
    "y": " gerçekleştirmek"
  },
  {
    "x": "realîzebîyayîş (n)",
    "y": " gerçekleştirilme"
  },
  {
    "x": "realîzekerdiş (n)",
    "y": " gerçekleştirme"
  },
  {
    "x": "realîzm (n)",
    "y": " gerçekçilik, realizm"
  },
  {
    "x": "realîzmo newe (n)",
    "y": " yeni gerçekçilik, yeni realizm"
  },
  {
    "x": "realîzmo şaîrane (n)",
    "y": " şairane gerçekçilik, şairane realizm"
  },
  {
    "x": "realîzmo şaîrkî (n)",
    "y": " şairane gerçekçilik, şairane realizm"
  },
  {
    "x": "realîzmo şîîrkî (m)",
    "y": " şiirsel gerçekçilik, şiirsel realizm"
  },
  {
    "x": "rear-projection unit (n) (TE)",
    "y": " rear-projection unit"
  },
  {
    "x": "Reb (n)",
    "y": " Rab, Tanrı, ilah"
  },
  {
    "x": "Rebb (n)",
    "y": " b. Reb (n), Rebî (n)"
  },
  {
    "x": "Rebbî (n)",
    "y": " b. Rebî (n)"
  },
  {
    "x": "(Ti) Rebbî kenî/a",
    "y": " b. (Ti) Rebî kenî/a"
  },
  {
    "x": "Rebbî tala",
    "y": " b. Rebî teala"
  },
  {
    "x": "Rebbî teala",
    "y": " b. Rebî teala"
  },
  {
    "x": "reben, -e",
    "y": " zavallı"
  },
  {
    "x": "rebeney (m)",
    "y": " b. rebenîye (m)"
  },
  {
    "x": "rebenê (m)",
    "y": " rebenî (m)"
  },
  {
    "x": "rebeni m)",
    "y": " b. reben, -e"
  },
  {
    "x": "rebenî (m)",
    "y": " zavallılık"
  },
  {
    "x": "rebenîye (m)",
    "y": " zavallılık"
  },
  {
    "x": "Rebî (n)",
    "y": " Rab, Tanrı, ilah"
  },
  {
    "x": "(Ti) Rebî kenî/a",
    "y": " Allahını seversen, Allah aşkına"
  },
  {
    "x": "Rebî tala",
    "y": " b. Rebî teala"
  },
  {
    "x": "Rebî teala",
    "y": " Allahuteala, Hakteala"
  },
  {
    "x": "Rebî tela",
    "y": " b. Rebî teala"
  },
  {
    "x": "rebîgirewta (m)",
    "y": " Allah canını alasıca"
  },
  {
    "x": "rebîgirewte (n)",
    "y": " Allah canını alasıca"
  },
  {
    "x": "rebîgirota (m)",
    "y": " b. rebîgirewte (n)"
  },
  {
    "x": "rebîgirote (n)",
    "y": " b. rebîgirewte (n)"
  },
  {
    "x": "recef (n)",
    "y": " b. recif (n)"
  },
  {
    "x": "recef dekewtene",
    "y": " b. recif dekewtene"
  },
  {
    "x": "recef dekotene",
    "y": " b. recif dekewtene"
  },
  {
    "x": "recefîyayene",
    "y": " b. recifîyayene"
  },
  {
    "x": "recefîyayêne",
    "y": " b. recifîyayene"
  },
  {
    "x": "recefîyayîş (n)",
    "y": " b. recifîyayîş (n)"
  },
  {
    "x": "recefnayene",
    "y": " b. recifnayene"
  },
  {
    "x": "recfe (m)",
    "y": " b. recif (n)"
  },
  {
    "x": "recfi (m)",
    "y": " b. recif (n)"
  },
  {
    "x": "recfi dekewtene",
    "y": " b. recif dekewtene"
  },
  {
    "x": "recif (n)",
    "y": " titreme"
  },
  {
    "x": "recif dekewtene",
    "y": " titreme tutmak, titreşmek"
  },
  {
    "x": "recifîyayene",
    "y": " titremek"
  },
  {
    "x": "recifîyayîş (n)",
    "y": " titreme"
  },
  {
    "x": "recifnayene",
    "y": " titretmek"
  },
  {
    "x": "reçber, -e",
    "y": " b. rencber, -e"
  },
  {
    "x": "reçberenî (m)",
    "y": " b. rencberênî (m)"
  },
  {
    "x": "reçberîye (m)",
    "y": " b. rencberîye (m)"
  },
  {
    "x": "reçel (n)",
    "y": " b. rîçal (n)"
  },
  {
    "x": "redaktor, -e",
    "y": " redaktör"
  },
  {
    "x": "redîfe (m)",
    "y": " redif "
  },
  {
    "x": "redresor (n)",
    "y": " doğrultmaç, redresör"
  },
  {
    "x": "redundant server (n) (TE)",
    "y": " redundant server"
  },
  {
    "x": "reet, -e",
    "y": " b. rehet,-e"
  },
  {
    "x": "ref-I (n)",
    "y": " 1)kuş sürüsü\r\n~2)insan grubu, insan kümesi, kafile"
  },
  {
    "x": "ref-II (n)",
    "y": " raf"
  },
  {
    "x": "refê firaqan (n)",
    "y": " tabaklık"
  },
  {
    "x": "refê kitaban (n)",
    "y": " kitap rafı"
  },
  {
    "x": "refê kitabgehî (n)",
    "y": " kitaplık rafı"
  },
  {
    "x": "refê kitabon (n)",
    "y": " b. refê kitaban (n)"
  },
  {
    "x": "refê postalan (n)",
    "y": " ayakkabılık"
  },
  {
    "x": "refê solan (n)",
    "y": " ayakkabılık"
  },
  {
    "x": "refî solûn (n)",
    "y": " b. refê solan (n)"
  },
  {
    "x": "rêf kitabûn (n)",
    "y": " b. refê kitaban (n)"
  },
  {
    "x": "rêf solûn (n)",
    "y": " b. refê solan (n)"
  },
  {
    "x": "Refahîye",
    "y": " Refahiye"
  },
  {
    "x": "referandum (n)",
    "y": " referandum"
  },
  {
    "x": "referans (n)",
    "y": " referans"
  },
  {
    "x": "refike (m)",
    "y": " ekmeği tandıra yapıştırmak için kullanılan bir araç"
  },
  {
    "x": "refin girewtene",
    "y": " tüneklemek, tünemek"
  },
  {
    "x": "refin girotene",
    "y": " b. refin girewtene"
  },
  {
    "x": "refingirewtiş (n)",
    "y": " tünekleme, tüneme"
  },
  {
    "x": "refingirotiş (n)",
    "y": " b. refingirewtiş (n)"
  },
  {
    "x": "refişîyayîş (n)",
    "y": " b. recifîyayîş (n)"
  },
  {
    "x": "reform",
    "y": " düzeltme"
  },
  {
    "x": "refş (m)",
    "y": " b. recif (n)"
  },
  {
    "x": "refşeyîş (n)",
    "y": " b. recifîyayîş (n)"
  },
  {
    "x": "refşi (m)",
    "y": " b. recif (n)"
  },
  {
    "x": "refşi dekewtene",
    "y": " b. recif dekewtene"
  },
  {
    "x": "refşîyayîne",
    "y": " b. recifîyayene"
  },
  {
    "x": "refşîyayîş (n)",
    "y": " b. recifîyayîş (n)"
  },
  {
    "x": "refşnayîne",
    "y": " b. recifnayene"
  },
  {
    "x": "reftar (n)",
    "y": " tavır, tutum"
  },
  {
    "x": "regl (n)",
    "y": " aybaşı (kadın için)"
  },
  {
    "x": "rehan (n)",
    "y": " b. rihan (n)"
  },
  {
    "x": "rehar (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "rehat",
    "y": " b. rehet,-e"
  },
  {
    "x": "rehatey (m)",
    "y": " b. rehetî (m)"
  },
  {
    "x": "rehend (n)",
    "y": " boyut"
  },
  {
    "x": "rehet, -e",
    "y": " kolay, rahat\r\n~\tRehet! (Rahat!)"
  },
  {
    "x": "rehetey (m)",
    "y": " b. rehetî (m)"
  },
  {
    "x": "rehetî (m)",
    "y": " 1)kolaylık\r\n~2)rahatlık\r\n~3)konfor"
  },
  {
    "x": "rehîr (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "rehme (m)",
    "y": " acıma, merhamet"
  },
  {
    "x": "rehmet kerdene",
    "y": " b. şîyene rehmet"
  },
  {
    "x": "rehmet şîyayene",
    "y": " b. şîyene rehmet"
  },
  {
    "x": "rehmetî, -ye",
    "y": " merhum, merhume"
  },
  {
    "x": "rehmetkerdiş (n)",
    "y": " b. şîyayîşrehmet (n)"
  },
  {
    "x": "rehmetşîyayîş (n)",
    "y": " b. şîyayîşrehmet (n)"
  },
  {
    "x": "rehmi (m)",
    "y": " b. rehme (m)"
  },
  {
    "x": "rehn (n)",
    "y": " uyluk "
  },
  {
    "x": "rehwan",
    "y": " b. rewan, -e"
  },
  {
    "x": "rejef (n)",
    "y": " b. recif (n)"
  },
  {
    "x": "rejefîyayene",
    "y": " b. recifîyayene"
  },
  {
    "x": "rejefîyayîş (n)",
    "y": " b. recifîyayîş (n)"
  },
  {
    "x": "rejî (n)",
    "y": " reji (sinema, tiyatro vb. için)"
  },
  {
    "x": "rejîsor, -e",
    "y": " rejisör, yönetmen"
  },
  {
    "x": "rejîsora fîlmî (m)",
    "y": " film yönetmeni"
  },
  {
    "x": "rejîsora tewr başe",
    "y": " en iyi kadın yönetmen"
  },
  {
    "x": "rejîsorê fîlmî (n)",
    "y": " film yönetmeni"
  },
  {
    "x": "rejîsoro tewr baş",
    "y": " en iyi erkek yönetmen"
  },
  {
    "x": "reklam (n)",
    "y": " reklam "
  },
  {
    "x": "reklamkar, -e",
    "y": " reklamcı"
  },
  {
    "x": "reklamkarî (m)",
    "y": " reklamcılık"
  },
  {
    "x": "reklamkarîye (m)",
    "y": " reklamcılık"
  },
  {
    "x": "rektor, -e",
    "y": " rektör "
  },
  {
    "x": "rektorîye (m)",
    "y": " rektörlük "
  },
  {
    "x": "rem-I (n)",
    "y": " 1)kaçış, kaçma\r\n~2)firar 2)rahvan"
  },
  {
    "x": "remaya (m)",
    "y": " kaçan, kaçak"
  },
  {
    "x": "remaye (n)",
    "y": " kaçak, kaçan"
  },
  {
    "x": "remayeyê/a eskerîya",
    "y": " asker kaçağı"
  },
  {
    "x": "remayene",
    "y": " firar etmek, kaçmak"
  },
  {
    "x": "remayî, -ye",
    "y": " kaçak, kaçan"
  },
  {
    "x": "remayîş (n)",
    "y": " 1)firar etme, kaçma\r\n~2)koşma"
  },
  {
    "x": "remayox, -e",
    "y": " kaçan"
  },
  {
    "x": "remayoze (m)",
    "y": " remayöz"
  },
  {
    "x": "remil (n)",
    "y": " lığ, alüvyon; mil (kum ve toprak karışımı)"
  },
  {
    "x": "remildar, -e",
    "y": " falcı"
  },
  {
    "x": "remilîyayene",
    "y": " lığlanmak"
  },
  {
    "x": "remilîyayîş (n)",
    "y": " lığlanma"
  },
  {
    "x": "reml (n)",
    "y": " b. remil (n)"
  },
  {
    "x": "remnaya (m)",
    "y": " kaçırılan"
  },
  {
    "x": "remnaye (n)",
    "y": " kaçırılan"
  },
  {
    "x": "remnayene",
    "y": " kaçırmak"
  },
  {
    "x": "remnayîş (n)",
    "y": " kaçırma"
  },
  {
    "x": "remnayîye (m)",
    "y": " kaçırılan"
  },
  {
    "x": "remnayox, -e",
    "y": " kaçıran"
  },
  {
    "x": "remtut (m)",
    "y": " b. remtute (m)"
  },
  {
    "x": "remtute (m)",
    "y": " ekşi dut"
  },
  {
    "x": "remtutêre (m)",
    "y": " meyvesi ekşi olan dut ağacı"
  },
  {
    "x": "remtutêri (m)",
    "y": " b. remtutêre (m)"
  },
  {
    "x": "rena",
    "y": " b. reyna"
  },
  {
    "x": "rencan, -e",
    "y": " b. rincan, -e"
  },
  {
    "x": "rencan bîyene",
    "y": " b. rincan bîyene"
  },
  {
    "x": "rencan kerdene",
    "y": " b. rincan kerdene"
  },
  {
    "x": "rencanbîyayîş (n)",
    "y": " b. rincanbîyayîş (n)"
  },
  {
    "x": "rencanê (m)",
    "y": " b. rincanî (m)"
  },
  {
    "x": "rencankerdiş (n)",
    "y": " b. rincankerdiş (n)"
  },
  {
    "x": "rencber, -e",
    "y": " emekçi, rençper, ırgat, işçi"
  },
  {
    "x": "rencberey (m)",
    "y": " rencberî (m), rencberîye (m)"
  },
  {
    "x": "rencberê (m)",
    "y": " rencberî (m), rencberîye (m)"
  },
  {
    "x": "rencberênî (m)",
    "y": " ırgatlık, işçilik, rençperlik"
  },
  {
    "x": "rencberi (m)",
    "y": " b. rencber, -e"
  },
  {
    "x": "rencberî (m)",
    "y": " ırgatlık, işçilik, rençperlik"
  },
  {
    "x": "rencberîye (m)",
    "y": " ırgatlık, işçilik, rençperlik"
  },
  {
    "x": "rence (m)",
    "y": " emek"
  },
  {
    "x": "renci (m)",
    "y": " b. rence (m)"
  },
  {
    "x": "rencon, -e",
    "y": " b. rincan, -e"
  },
  {
    "x": "renconbîyayîş (n)",
    "y": " b. rincanbîyayîş (n)"
  },
  {
    "x": "renconkerdiş (n)",
    "y": " b. rincankerdiş (n)"
  },
  {
    "x": "rencwerîye (m)",
    "y": " sömürü"
  },
  {
    "x": "rende (n)",
    "y": " rende, planya"
  },
  {
    "x": "reng (n)",
    "y": " renk"
  },
  {
    "x": "reng bîyene",
    "y": " boyalanmak, boyanmak"
  },
  {
    "x": "reng kerdene",
    "y": " boyamak, boyalamak, renklendirmek"
  },
  {
    "x": "rengê qawî",
    "y": " b. qeweyî"
  },
  {
    "x": "rengê qehwî",
    "y": " b. qeweyî "
  },
  {
    "x": "rengbîyayîş (n)",
    "y": " boyalanma, boyanma"
  },
  {
    "x": "rengi (m)",
    "y": " b. reng (n)"
  },
  {
    "x": "rengin, -e",
    "y": " renkli"
  },
  {
    "x": "rengîn, -e",
    "y": " b. rengin, -e"
  },
  {
    "x": "rengkerdiş (n)",
    "y": " boyama, boyalama, renklendirme"
  },
  {
    "x": "rengnayene",
    "y": " boyamak, boyalamak, renklendirmek"
  },
  {
    "x": "rengnayîş (n)",
    "y": " boyama, boyalama, renklendirme"
  },
  {
    "x": "rengpeym (n)",
    "y": " renkölçer, kolorimetre"
  },
  {
    "x": "rep",
    "y": " (dikleşmiş olan) dik "
  },
  {
    "x": "rep kerdene",
    "y": " dikleştirmek, dikeltmek"
  },
  {
    "x": "rep-tep",
    "y": " b. rep-tepe (m)"
  },
  {
    "x": "rep-tepe (m)",
    "y": " gürültü patırtı, pat küt"
  },
  {
    "x": "replîk (n)",
    "y": " replik"
  },
  {
    "x": "repşeyîş (n)",
    "y": " b. recifîyayîş (n)"
  },
  {
    "x": "repşîyayîş (n)",
    "y": " b. recifîyayîş (n)"
  },
  {
    "x": "repûtep",
    "y": " b. repûtepe (m)"
  },
  {
    "x": "repûtepe (m)",
    "y": " gürültü patırtı, pat küt"
  },
  {
    "x": "repvaj, -e",
    "y": " rapçı"
  },
  {
    "x": "req-rut, -e",
    "y": " kupkuru"
  },
  {
    "x": "reqa (m)",
    "y": " b. reqî (m)"
  },
  {
    "x": "reqabet (n)",
    "y": " çekişme, rekabet"
  },
  {
    "x": "reqabet kerdene",
    "y": " çekişmek, rekabet etmek"
  },
  {
    "x": "reqabetkerdiş (n)",
    "y": " çekişme, rekabet etme"
  },
  {
    "x": "reqas (n)",
    "y": " dansör"
  },
  {
    "x": "reqase (m)",
    "y": " dansöz"
  },
  {
    "x": "reqawer, -e",
    "y": " b. reqîwer, -e"
  },
  {
    "x": "reqayene",
    "y": " çınlamak"
  },
  {
    "x": "reqayîş (n)",
    "y": " çınlama"
  },
  {
    "x": "reqe (m)",
    "y": " sukaplumbağası"
  },
  {
    "x": "reqeme (m)",
    "y": " rakam"
  },
  {
    "x": "reqemkî",
    "y": " rakamsal"
  },
  {
    "x": "reqes (n)",
    "y": " b. reqis (n)"
  },
  {
    "x": "reqi (m)",
    "y": " b. reqe (m)"
  },
  {
    "x": "reqis (n)",
    "y": " dans, oyun"
  },
  {
    "x": "reqî-I (m)",
    "y": " rakı"
  },
  {
    "x": "reqî-II (m)",
    "y": " çınlama"
  },
  {
    "x": "reqî (n)",
    "y": " b. reqî-I (m)"
  },
  {
    "x": "reqîwer, -e",
    "y": " rakı içen"
  },
  {
    "x": "reqîxor, -e",
    "y": " rakı içen"
  },
  {
    "x": "reqîye (m)",
    "y": " çınlama"
  },
  {
    "x": "reqnayene",
    "y": " çınlatmak"
  },
  {
    "x": "reqs (n)",
    "y": " b. reqis (n)"
  },
  {
    "x": "reqûrit, -e",
    "y": " b. reqûrut, -e"
  },
  {
    "x": "reqûrut, -e",
    "y": " kupkuru"
  },
  {
    "x": "reqûteq (m)",
    "y": " b. teqûreqe (m)"
  },
  {
    "x": "rer (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "res (n)",
    "y": " ulaşım"
  },
  {
    "x": "resam, -e",
    "y": " ressam"
  },
  {
    "x": "resamey (m)",
    "y": " b. resamî (m)"
  },
  {
    "x": "resamê (m)",
    "y": " b. resamî (m)"
  },
  {
    "x": "resamî (m)",
    "y": " ressamlık"
  },
  {
    "x": "resamîye (m)",
    "y": " ressamlık"
  },
  {
    "x": "resane (n)",
    "y": " b. resene (n)"
  },
  {
    "x": "resaya (m)",
    "y": " 1)yetişkin, ergin, reşit\r\n~2)olgun (meyve ve sebze için)"
  },
  {
    "x": "resaye (n)",
    "y": " 1)yetişkin, ergin, reşit\r\n~2)olgun (meyve ve sebze için)"
  },
  {
    "x": "resaye bîyene",
    "y": " erginleşmek, yetişkin olmak, reşit olmak"
  },
  {
    "x": "resayebîyayîş (n)",
    "y": " erginleşme, yetişkin olma, reşit olma"
  },
  {
    "x": "resayene",
    "y": " 1)olgunlaşmak (meyve vb. için)\r\n~2)dokunmak (iyilik vb. için)\r\n~Yew holîya Hişyarî resaye mi. (Hişyar’ın bir iyiliği bana dokundu.)"
  },
  {
    "x": "resayê (m)",
    "y": " b. resaya (m)"
  },
  {
    "x": "resayî, -ye",
    "y": " yetişkin, ergin, reşit"
  },
  {
    "x": "resayî bîyene",
    "y": " erginleşmek, yetişkin olmak, reşit olmak"
  },
  {
    "x": "resayîbîyayîş (n)",
    "y": " erginleşme, yetişkin olma, reşit olma"
  },
  {
    "x": "resayîş (n)",
    "y": " 1)olgunlaşma (meyve vb. için)\r\n~2)dokunma (iyilik vb. için)"
  },
  {
    "x": "resayîye (m)",
    "y": " olgun (meyve ve sebze için)"
  },
  {
    "x": "resayîyî (m)",
    "y": " erginlik, yetişkinlik, rüşt"
  },
  {
    "x": "resayîyîye (m)",
    "y": " erginlik, yetişkinlik, rüşt"
  },
  {
    "x": "resen-I (n)",
    "y": " b. resene (n)"
  },
  {
    "x": "resen-II",
    "y": " resen (kimseye bağlı olmaksızın)"
  },
  {
    "x": "resene (n)",
    "y": " urgan (kıldan örülen urgan)"
  },
  {
    "x": "resepsîyon (n)",
    "y": " resepsiyon"
  },
  {
    "x": "resepsîyonîst, -e",
    "y": " resepsiyon memuru"
  },
  {
    "x": "reset (n) (TE)",
    "y": " ilk duruma getirmek, sıfırlamak"
  },
  {
    "x": "resim (n)",
    "y": " resim"
  },
  {
    "x": "resim viraştene",
    "y": " resim çizmek"
  },
  {
    "x": "resimê ci viraştene",
    "y": " resmetmek"
  },
  {
    "x": "resimê dêsî (n)",
    "y": " duvar resmi"
  },
  {
    "x": "resimê dîwarî (n)",
    "y": " duvar resmi"
  },
  {
    "x": "resimê ... viraştene",
    "y": " resmetmek"
  },
  {
    "x": "resm (n)",
    "y": " b. resim (n)"
  },
  {
    "x": "resmî, -ye",
    "y": " resmî"
  },
  {
    "x": "senedo resmî (n)",
    "y": " resmî senet"
  },
  {
    "x": "resnayene",
    "y": " olgunlaştırmak (meyve vb. için)"
  },
  {
    "x": "resnayîş (n)",
    "y": " olgunlaştırma (meyve vb. için)"
  },
  {
    "x": "resne (n)",
    "y": " b. resene (n)"
  },
  {
    "x": "resni (n)",
    "y": " b. resene (n)"
  },
  {
    "x": "ressam, -e",
    "y": " b. resam, -e"
  },
  {
    "x": "ressamey (m)",
    "y": " b. resamî (m), resamîye (m)"
  },
  {
    "x": "ressamê (m)",
    "y": " b. resamî (m), resamîye (m)"
  },
  {
    "x": "resta (m)",
    "y": " olgun (meyve ve sebze için)"
  },
  {
    "x": "reste (n)",
    "y": " olgun (meyve ve sebze için)"
  },
  {
    "x": "restene",
    "y": " olgunlaşmak (meyve vb. için)"
  },
  {
    "x": "restiş (n)",
    "y": " olgunlaşma (meyve vb. için)"
  },
  {
    "x": "restîtusyon (n)",
    "y": " restitüsyon"
  },
  {
    "x": "restîye (m)",
    "y": " olgun (meyve ve sebze için)"
  },
  {
    "x": "restorasyon (n)",
    "y": " onarım, restorasyon"
  },
  {
    "x": "restorasyono şumûldar (n)",
    "y": " kapsamlı onarım"
  },
  {
    "x": "restore kerdene",
    "y": " onarmak, restore etmek"
  },
  {
    "x": "restorekerdiş (n)",
    "y": " onarma, restore etme"
  },
  {
    "x": "reşbelek (n)",
    "y": " alacalık (karlı yer için), karın erimesi sonucu yer yer toprağın görünmesi ve bazı yerlerin henüz karla kaplı olması durumu"
  },
  {
    "x": "reşbelek, -e",
    "y": " alaca (siyah-beyaz renkli)"
  },
  {
    "x": "reşber, -e",
    "y": " b. rencber, -e"
  },
  {
    "x": "reşeynayîne",
    "y": " b. raşanayene"
  },
  {
    "x": "reşeynayîş (n)",
    "y": " b. raşanayîş (n)"
  },
  {
    "x": "reşêle (n)",
    "y": " sığırcık"
  },
  {
    "x": "reşike (m)",
    "y": " balçık"
  },
  {
    "x": "reşle (n)",
    "y": " b. reşêle (n)"
  },
  {
    "x": "reşme (n)",
    "y": " 1)saçak (giyim eşyası vb. için)\r\n~2)yular"
  },
  {
    "x": "reşme veradayene",
    "y": " (mecazi) kabadayılık taslamak"
  },
  {
    "x": "reşmeveradayîş (n)",
    "y": " (mecazi) kabadayılık taslama"
  },
  {
    "x": "reşmi (n)",
    "y": " b. reşme (n)"
  },
  {
    "x": "reşper (n)",
    "y": " b. rencber (n)"
  },
  {
    "x": "reşt (n)",
    "y": " b. raste (n), raste (n)"
  },
  {
    "x": "reştî teyara (n)",
    "y": " b. rasteyê teyara (n), rasteyê teyara (n)"
  },
  {
    "x": "reşta (m)",
    "y": " b. resta (m)"
  },
  {
    "x": "reşte (n)",
    "y": " b. reste (n)"
  },
  {
    "x": "reştene",
    "y": " b. restene"
  },
  {
    "x": "reştiş (n)",
    "y": " b. restiş (n)"
  },
  {
    "x": "reştîye (m)",
    "y": " b. restîye (m)"
  },
  {
    "x": "ret, -e",
    "y": " b. rehet,-e"
  },
  {
    "x": "retrospektîf (n, sn)",
    "y": " retrospektif"
  },
  {
    "x": "reuke (m)",
    "y": " b. rêwike (m)"
  },
  {
    "x": "revan, -e",
    "y": " b. rewan, -e"
  },
  {
    "x": "revîzyon (n)",
    "y": " revizyon"
  },
  {
    "x": "rew",
    "y": " 1)erken\r\n~2)çabuk, hızlı"
  },
  {
    "x": "rew ra",
    "y": " 1)erkenden\r\n~2)çoktan, çoktan beri"
  },
  {
    "x": "rewa (n)",
    "y": " 1)nem, rutubet, gönen\r\n~2)şebnem, çiy"
  },
  {
    "x": "rewa girewtene",
    "y": " nemlenmek, rutubetlenmek"
  },
  {
    "x": "rewac (n)",
    "y": " sürüm (ticaret için)"
  },
  {
    "x": "rewan, -e",
    "y": " rahvan"
  },
  {
    "x": "rewapeym (n)",
    "y": " nemölçer, higrometre"
  },
  {
    "x": "rewayin, -e",
    "y": " nemli, rutubetli"
  },
  {
    "x": "rewayinîye (m)",
    "y": " nemlilik, rutubetlilik"
  },
  {
    "x": "rewike (m)",
    "y": " b. rêwike (m)"
  },
  {
    "x": "rexne (n)",
    "y": " kritik"
  },
  {
    "x": "rexne kerdene",
    "y": " kritik etmek"
  },
  {
    "x": "ruwîyayene",
    "y": " b. ruwayene"
  },
  {
    "x": "ruwîyayîş (n)",
    "y": " b. ruwayîş (n)"
  },
  {
    "x": "rewna",
    "y": " çoktan, çoktan beri"
  },
  {
    "x": "rewon (n)",
    "y": " b. rewan, -e"
  },
  {
    "x": "rewşe (m)",
    "y": " 1)durum, hal, wezîyet (n)\r\n~2)pozisyon"
  },
  {
    "x": "rewşa awanî (m)",
    "y": " imar durumu"
  },
  {
    "x": "rewşa cografîke (m)",
    "y": " konum"
  },
  {
    "x": "rewşa îstîsnayîye (m)",
    "y": " olağanüstü durum, olağanüstü hal"
  },
  {
    "x": "rewşa lezabezî (m)",
    "y": " b. rewşa lezabezîye (m)"
  },
  {
    "x": "rewşa lezabezîye (m)",
    "y": " acil durum"
  },
  {
    "x": "rewşa nikayine",
    "y": " halihazır"
  },
  {
    "x": "rewşa wayîrîye (m)",
    "y": " mülkiyet durumu"
  },
  {
    "x": "rewşe dayenebedilnayene",
    "y": " dönüştürmek"
  },
  {
    "x": "rewşe dayenevurnayene",
    "y": " dönüştürmek"
  },
  {
    "x": "rewşe vurnayene",
    "y": " dönüşmek"
  },
  {
    "x": "rewşedayîşbedilnayîş (n)",
    "y": " dönüştürme"
  },
  {
    "x": "rewşedayîşvurnayîş (n)",
    "y": " dönüştürme"
  },
  {
    "x": "rewşevurnayîş (n)",
    "y": " dönüşme"
  },
  {
    "x": "rewşî, -ye",
    "y": " durumsal"
  },
  {
    "x": "rewşîbîyayîş (n)",
    "y": " durumsallık"
  },
  {
    "x": "rewşîtî (m)",
    "y": " durumsallık"
  },
  {
    "x": "rewşîtîye (m)",
    "y": " durumsallık"
  },
  {
    "x": "rewt (m)",
    "y": " b. rewte (m)"
  },
  {
    "x": "rewte (m)",
    "y": " sırık"
  },
  {
    "x": "rewti (m)",
    "y": " b. rewte (m)"
  },
  {
    "x": "rewtike (m)",
    "y": " küçük sırık"
  },
  {
    "x": "rewtî (m)",
    "y": " b. rewte (m)"
  },
  {
    "x": "rewuke (m)",
    "y": " b. rêwike (m)"
  },
  {
    "x": "rewûte (m)",
    "y": " b. rewte (m)"
  },
  {
    "x": "rexdek, -e",
    "y": " gevşek"
  },
  {
    "x": "rexne (n)",
    "y": " eleştiri"
  },
  {
    "x": "rexne kerdene",
    "y": " eleştirmek"
  },
  {
    "x": "rexneyê sînema (n)",
    "y": " sinema eleştirisi"
  },
  {
    "x": "rexnegir, -e",
    "y": " eleştirmen"
  },
  {
    "x": "rexnegirane",
    "y": " eleştirel"
  },
  {
    "x": "rexnekerdiş (n)",
    "y": " eleştirme"
  },
  {
    "x": "rext (n)",
    "y": " fişeklik, kargılık"
  },
  {
    "x": "rey-I (m)",
    "y": " 1)kez, kere, defa, sefer\r\n~Di reyî ti şîy, na rey zî ez şona. (İki kez sen gittin, bu kez de ben gidiyorum.)\r\n~2)kere (mat)\r\n~Di rey di, keno çar. 2x2= 4 (İki kere iki dört eder.)"
  },
  {
    "x": "rey-rey",
    "y": " ara sıra, arada sırada, bazen"
  },
  {
    "x": "reyê",
    "y": " bir kez, bir kere, bir defa"
  },
  {
    "x": "reyê-di reyî",
    "y": " bir iki kez, bir iki kere, bir iki defa"
  },
  {
    "x": "reyêk",
    "y": " bir kez, bir kere, bir defa"
  },
  {
    "x": "reyêke",
    "y": " b. reyke"
  },
  {
    "x": "reyke",
    "y": " bir kez, bir kere, bir defa"
  },
  {
    "x": "rey-II (m)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "rey kerdene",
    "y": " b. ray kerdene"
  },
  {
    "x": "rey kewtene",
    "y": " kaçmak (kadın için)"
  },
  {
    "x": "rey kotene",
    "y": " b. rey kewtene"
  },
  {
    "x": "rey kowtene",
    "y": " b. rey kewtene"
  },
  {
    "x": "reya (m)",
    "y": " 1)kök, ağaç kökü\r\n~2)damar"
  },
  {
    "x": "reyaya dare (m)",
    "y": " ağaç kökü"
  },
  {
    "x": "reyar (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "reyaya (m)",
    "y": " çıkık"
  },
  {
    "x": "reyaye (n)",
    "y": " çıkık"
  },
  {
    "x": "reyayene",
    "y": " 1)çıkmak (kol, ayak vb. için)\r\n~2)beli gelmek, boşalmak, menisi gelmek"
  },
  {
    "x": "reyayeyîya qinesteyî",
    "y": " kalça çıkıklığı"
  },
  {
    "x": "reyayîne",
    "y": " b. reyayene"
  },
  {
    "x": "reyayîş (n)",
    "y": " 1)kurtuluş\r\n~Eke ti tîya ra bikewî ti ganî nêreyênî. (Burdan düşersen sağ kurtulamazsın.)\r\n~2)çıkma (kol, ayak vb. için), yerinden çıkma (kol ve ayak için) \r\n~3)beli gelme, boşalma (cinsel boşalma)"
  },
  {
    "x": "reyayîye (m)",
    "y": " çıkık"
  },
  {
    "x": "reyber",
    "y": " b. rayber, -e"
  },
  {
    "x": "reyberê (m)",
    "y": " b. rayberî (m), rayberîye (m)"
  },
  {
    "x": "reyda",
    "y": " b. reyde"
  },
  {
    "x": "reyde",
    "y": " beraber, beraberinde, birlikte, ile"
  },
  {
    "x": "reyde tena mendene",
    "y": " baş başa kalmak"
  },
  {
    "x": "reydi",
    "y": " b. reyde"
  },
  {
    "x": "reydir",
    "y": " b. reyde"
  },
  {
    "x": "reye-I (m)",
    "y": " b. rey-I (m)"
  },
  {
    "x": "reye-II (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "reye kerdene",
    "y": " b. ray kerdene"
  },
  {
    "x": "reye vistene",
    "y": " b. ray fîştene"
  },
  {
    "x": "reye-III (n)",
    "y": " b. reya (m)"
  },
  {
    "x": "reyekerdiş (n)",
    "y": " b. raykerdiş (n)"
  },
  {
    "x": "reyer (m)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "reyevistiş (n)",
    "y": " b. rayfîştiş (n)"
  },
  {
    "x": "reyeya (m)",
    "y": " reyaya (m)"
  },
  {
    "x": "reyeye (n)",
    "y": " b. reyaye (n)"
  },
  {
    "x": "reyeyîş (n)",
    "y": " b. reyayîş (n)"
  },
  {
    "x": "reyêna",
    "y": " b. reyna"
  },
  {
    "x": "reyêr (m)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "reyhan (m)",
    "y": " b. rihan (n)"
  },
  {
    "x": "reyhani (m)",
    "y": " b. rihan (n)"
  },
  {
    "x": "reyhon (n)",
    "y": " b. rihan (n)"
  },
  {
    "x": "reyi (m)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "reyir (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "reyîna",
    "y": " b. reyna"
  },
  {
    "x": "reyîr (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "reyîrê papure (n)",
    "y": " b. rayîrê papûre (n)"
  },
  {
    "x": "reykerdiş (n)",
    "y": " b. raykerdiş (n)"
  },
  {
    "x": "reykewta (m)",
    "y": " kaçan (kadın için)"
  },
  {
    "x": "reykewtiş (n)",
    "y": " kaçma (kadın için)"
  },
  {
    "x": "reykewtîye (m)",
    "y": " kaçan (kadın için)"
  },
  {
    "x": "reykî (n)",
    "y": " çarpı (mat)\r\n~nîşanê reykî (n): çarpı işareti (mat), çarpma işareti (mat)"
  },
  {
    "x": "nîşanê reykî (n)",
    "y": " çarpı işareti (mat), çarpma işareti (mat)"
  },
  {
    "x": "reykî kerdene",
    "y": " çarpmak (mat)"
  },
  {
    "x": "reykîbîyaye (n)",
    "y": " çarpılan (mat)"
  },
  {
    "x": "reykîker (n)",
    "y": " çarpan (mat)"
  },
  {
    "x": "reykîkerdiş (n)",
    "y": " çarpma (mat)"
  },
  {
    "x": "reykîye (m)",
    "y": " çarpım (mat)"
  },
  {
    "x": "reykotiş (n)",
    "y": " b. reykewtiş (n)"
  },
  {
    "x": "reykowtiş (n)",
    "y": " b. reykewtiş (n)"
  },
  {
    "x": "Reykyavîk (n)",
    "y": " Reykjavik"
  },
  {
    "x": "reym (n)",
    "y": " b. rêm (n)"
  },
  {
    "x": "reym bestene",
    "y": " b. rêm bestene"
  },
  {
    "x": "reym ci kotene",
    "y": " b. rêm ci kewtene"
  },
  {
    "x": "reym dekotene",
    "y": " b. rêm dekewtene"
  },
  {
    "x": "reym girotene",
    "y": " b. rêm girewtene "
  },
  {
    "x": "reymin (n)",
    "y": " b. rêmin, -e"
  },
  {
    "x": "reyna",
    "y": " tekrar, yine, gene, bir kez daha, bir kere daha, bir defa daha, bir sefer daha"
  },
  {
    "x": "reyna ke",
    "y": " meğer, meğerse"
  },
  {
    "x": "reyra",
    "y": " beraber, beraberinde, birlikte, ile"
  },
  {
    "x": "reyra tena mendene",
    "y": " baş başa kalmak"
  },
  {
    "x": "reyre",
    "y": " b. reyra"
  },
  {
    "x": "rey-rey",
    "y": " vakit vakit, zaman zaman"
  },
  {
    "x": "reyşn (n)",
    "y": " b. roşn (n)"
  },
  {
    "x": "reyşt, -i",
    "y": " b. raşt, -e"
  },
  {
    "x": "reyştê (m)",
    "y": " b. raştî (m)"
  },
  {
    "x": "reyûn, -i",
    "y": " b. raywan, -e"
  },
  {
    "x": "reyxo (n)",
    "y": " b. rêxo (n)"
  },
  {
    "x": "rez, -e-I",
    "y": " seçkin insan"
  },
  {
    "x": "rez-II (n)",
    "y": " bağ"
  },
  {
    "x": "rez birnayene",
    "y": " bağ budamak"
  },
  {
    "x": "rez pud kerdene",
    "y": " bağ budamak"
  },
  {
    "x": "rez rîçik kerdene",
    "y": " bağ budamak"
  },
  {
    "x": "rez û baxçeyî",
    "y": " bağlık bahçelik"
  },
  {
    "x": "rez veradayene",
    "y": " bağbozumundan sonra hayvanların vb. girmesine karşı bağı korumasız bırakmak"
  },
  {
    "x": "rez viraştene",
    "y": " bağ budamak"
  },
  {
    "x": "rezan ser ra ameyene war",
    "y": " bağbozumunun sona ermesi"
  },
  {
    "x": "reza (m)",
    "y": " b. riza (m)"
  },
  {
    "x": "rezae (n)",
    "y": " b. rêjîyaye (n)"
  },
  {
    "x": "rezaene",
    "y": " b. rêjîyayene"
  },
  {
    "x": "rezaîs (n)",
    "y": " b. rêjîyayîş"
  },
  {
    "x": "rezban, -e",
    "y": " bahçıvan, bahçeci, bağban"
  },
  {
    "x": "rezbanîye (m)",
    "y": " bağbancılık"
  },
  {
    "x": "rezbûn, -e",
    "y": " b. rezban, -e"
  },
  {
    "x": "rezbirînî (zh)",
    "y": " bağ budama zamanı"
  },
  {
    "x": "rezefîyayene",
    "y": " b. recifîyayene"
  },
  {
    "x": "rezefîyayêne",
    "y": " b. recifîyayene"
  },
  {
    "x": "rezerv (n)",
    "y": " çekince, rezerv"
  },
  {
    "x": "rezerv mojnayene",
    "y": " çekince koymak"
  },
  {
    "x": "rezerv musnayene",
    "y": " çekince koymak"
  },
  {
    "x": "rezerv nawitene",
    "y": " çekince koymak"
  },
  {
    "x": "rezervasyon (n)",
    "y": " rezervasyon"
  },
  {
    "x": "rezil (m)",
    "y": " b. rêzile (m)"
  },
  {
    "x": "rezîl, -e",
    "y": " rezil"
  },
  {
    "x": "rezîl kerdene",
    "y": " rezil etmek"
  },
  {
    "x": "rê-I (m)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "rê kerdene",
    "y": " b. ray kerdene"
  },
  {
    "x": "rê mendene",
    "y": " b. ray mendene"
  },
  {
    "x": "rê-II (m)",
    "y": " b. rey-I (m)"
  },
  {
    "x": "na rê",
    "y": " b. na rey "
  },
  {
    "x": "rê-rê",
    "y": " b. rey-rey"
  },
  {
    "x": "rêki",
    "y": " b. reyke"
  },
  {
    "x": "rêyî-di rêyî",
    "y": " b. reyê-di reyî"
  },
  {
    "x": "rê-III (pd)",
    "y": " 1)İsmin “-e” halini ifade eden bir artedat.\r\n~Hesenî rê ard. (Hasan’a getirdi.)\r\n~ 2)b. ra-II (pd)"
  },
  {
    "x": "... rê çetin ameyene",
    "y": " ağırına gitmek\r\n~Na qisaya to mi rê çetin ameye. (Bu sözün ağırıma gitti.)"
  },
  {
    "x": "... rê ecayîb ameyene",
    "y": " garipsemek"
  },
  {
    "x": "... rê ecayîbameyîş",
    "y": " garipseme"
  },
  {
    "x": "... rê ecêb ameyene",
    "y": " garipsemek, acayibine gitmek"
  },
  {
    "x": "... rê ecêbameyîş",
    "y": " garipseme"
  },
  {
    "x": "... rê ecêv amayene",
    "y": " b. ... rê ecêb ameyene"
  },
  {
    "x": "... rê ecêvamayîş",
    "y": " b. ... rê ecêb ameyîş"
  },
  {
    "x": "... rê ecîyb ameyîne",
    "y": " b. ... rê ecêb ameyene"
  },
  {
    "x": "... rê ecîybameyîş",
    "y": " b. ... rê ecêb ameyîş"
  },
  {
    "x": "... rê giran ameyene",
    "y": " ağırına gitmek\r\n~Na qisaya to mi rê giran ameye. (Bu sözün ağırıma gitti.)"
  },
  {
    "x": "... rê temaşe ameyene",
    "y": " acayibine gitmek"
  },
  {
    "x": "... rê tewş ameyene",
    "y": " acayibine gitmek"
  },
  {
    "x": "... rê xam ameyene",
    "y": " garipsemek, acayibine gitmek"
  },
  {
    "x": "... rê xamameyîş",
    "y": " garipseme"
  },
  {
    "x": "rê dayene",
    "y": " b. arê dayene"
  },
  {
    "x": "rê kerdene",
    "y": " b. arê kerdene"
  },
  {
    "x": "rêben, -e",
    "y": " b. reben, -e"
  },
  {
    "x": "rêbenîye (m)",
    "y": " b. rebenîye (m)"
  },
  {
    "x": "rêber, -e",
    "y": " kılavuz, rehber"
  },
  {
    "x": "rêberê nuştişê tezî (n)",
    "y": " tez yazım kılavuzu, tez yazım rehberi"
  },
  {
    "x": "rêberey (m)",
    "y": " b. rêberî (m), rêberîye (m)"
  },
  {
    "x": "rêberê (m)",
    "y": " b. rêberî (m), rêberîye (m)"
  },
  {
    "x": "rêberî (m)",
    "y": " kılavuzluk, rehberlik"
  },
  {
    "x": "rêberîye (m)",
    "y": " kılavuzluk, rehberlik"
  },
  {
    "x": "rêbîs (n)",
    "y": " b. ribêse (m)"
  },
  {
    "x": "rêcifnayene",
    "y": " b. recifnayene "
  },
  {
    "x": "rêcifyayene",
    "y": " b. recifîyayene"
  },
  {
    "x": "rêcifyayîş (n)",
    "y": " b. recifîyayîş (n)"
  },
  {
    "x": "rêç (n)",
    "y": " b. rîç (n)"
  },
  {
    "x": "rêç kerdene",
    "y": " sıyırmak"
  },
  {
    "x": "rêç kerdeni",
    "y": " b. rêç kerdene"
  },
  {
    "x": "rêçe (m)",
    "y": " belirti"
  },
  {
    "x": "rêçi (m)",
    "y": " b. rêçe (m)"
  },
  {
    "x": "rêçik (n)",
    "y": " rîçik-I (n)"
  },
  {
    "x": "rêçkerdiş (n)",
    "y": " sıyırma"
  },
  {
    "x": "rêdayene",
    "y": " b. rarîdayene"
  },
  {
    "x": "rêdayîş (n)",
    "y": " b. arêdayîş (n)"
  },
  {
    "x": "rêdnayîs (n)",
    "y": " b. rarîdayîş (n)"
  },
  {
    "x": "rêhane (m)",
    "y": " b. rihan (n)"
  },
  {
    "x": "rêhon (n)",
    "y": " b. rihan (n)"
  },
  {
    "x": "rêhûn (n)",
    "y": " b. rihan (n)"
  },
  {
    "x": "rêj (n)",
    "y": " sızıntı (sızan şey)"
  },
  {
    "x": "rêj bîyayeni",
    "y": " b. rêj bîyene"
  },
  {
    "x": "rêj bîyene",
    "y": " sızmak (sıvı için)"
  },
  {
    "x": "rêj dayene",
    "y": " sızdırmak (testi, toprak vb için)\r\n~No meru rêj dano. (Bu testi [sıvı] sızdırıyor.)"
  },
  {
    "x": "rêj kerdene",
    "y": " sızdırmak (testi, toprak vb için)"
  },
  {
    "x": "rêjê petrolî (n)",
    "y": " petrol sızıntısı"
  },
  {
    "x": "rêjo gemarker (n)",
    "y": " kirletici sızıntı"
  },
  {
    "x": "rêjbîyayîş (n)",
    "y": " sızma (sıvı için)"
  },
  {
    "x": "rêjdayîş (n)",
    "y": " sızdırma (testi, toprak vb için)"
  },
  {
    "x": "rêjîyaye (n)",
    "y": " sızıntı (sızan şey)"
  },
  {
    "x": "rêjîyayene",
    "y": " sızdırmak (testi, toprak vb için)\r\n~No meru/kuz rêjeno. (Bu testi [sıvı] sızdırıyor.)"
  },
  {
    "x": "rêjîyayeni",
    "y": " b. rêjîyayene"
  },
  {
    "x": "rêjîyayîş (n)",
    "y": " sızma (sıvı için), sızdırma (testi, toprak vb için)"
  },
  {
    "x": "rêjkerdiş (n)",
    "y": " sızdırma (testi, toprak vb için)"
  },
  {
    "x": "rêjnayene",
    "y": " sızdırmak (testi, toprak vb için)\r\n~No meru awe rêjneno. (Bu testi su sızdırıyor.)"
  },
  {
    "x": "rêjnayîş (n)",
    "y": " sızdırma (testi, toprak vb için)"
  },
  {
    "x": "rêkerdiş-I (n)",
    "y": " b. raykerdiş (n)"
  },
  {
    "x": "rêkerdiş-II (n)",
    "y": " b. arêkerdiş (n)"
  },
  {
    "x": "rêm (n)",
    "y": " irin, cerahat"
  },
  {
    "x": "rêm bestene",
    "y": " irin toplamak, cerahatlenmek"
  },
  {
    "x": "rêm ci kewtene",
    "y": " irin toplamak, cerahatlenmek"
  },
  {
    "x": "rêm dekewtene",
    "y": " irin toplamak, cerahatlenmek"
  },
  {
    "x": "rêm girewtene",
    "y": " irin toplamak, cerahatlenmek"
  },
  {
    "x": "rêm guretene",
    "y": " b. rêm girewtene"
  },
  {
    "x": "rêm guritene",
    "y": " b. rêm girewtene"
  },
  {
    "x": "rêmendiş (n)",
    "y": " b. raymendiş (n)"
  },
  {
    "x": "rêmin, -e",
    "y": " irinli, cerahatli"
  },
  {
    "x": "rêna",
    "y": " b. reyna"
  },
  {
    "x": "rêncber, -e",
    "y": " b. rencber, -e"
  },
  {
    "x": "rênê",
    "y": " b. reyna"
  },
  {
    "x": "rês (n)",
    "y": " eğirilmiş yün, yün iplik"
  },
  {
    "x": "rêsên, -e",
    "y": " yünlü (yünden yapılmış olan)"
  },
  {
    "x": "rêsin, -e",
    "y": " yünlü (içinde yün de bulunan)"
  },
  {
    "x": "rêsikîyayene",
    "y": " kaymak"
  },
  {
    "x": "rêsikîyayîş (n)",
    "y": " kayma"
  },
  {
    "x": "rêsiknayene",
    "y": " kaydırmak"
  },
  {
    "x": "rêsiknayîş (n)",
    "y": " kaydırma"
  },
  {
    "x": "rêsî (m)",
    "y": " burma (burulup kurutulan ot demeti)"
  },
  {
    "x": "rêsnik, -e",
    "y": " kaygan"
  },
  {
    "x": "rêstene",
    "y": " eğirmek\r\n~Kam peşm rêseno? (Kim yün eğiriyor?)"
  },
  {
    "x": "rêstike (m)",
    "y": " iğ, eğirmen, kirmen\r\n~\tBi rêstike/rêşta/taşî peşm rêsena. (İğ ile yün eğiriyor.)"
  },
  {
    "x": "rêstiş (n)",
    "y": " eğirme"
  },
  {
    "x": "rêş (m)",
    "y": " b. rîşe (m)"
  },
  {
    "x": "rêşawitene",
    "y": " b. şawitene"
  },
  {
    "x": "rêşi (m)",
    "y": " b. rîşe (m)"
  },
  {
    "x": "rêşa merdumweri (m)",
    "y": " b. rîşa merdimwere (m)"
  },
  {
    "x": "rêşa mêrdimweri (m)",
    "y": " b. rîşa merdimwere (m) "
  },
  {
    "x": "rêşa serri (m)",
    "y": " b. rîşa serre (m)"
  },
  {
    "x": "rêşa sîyay (m)",
    "y": " b. rîşa sîyaye (m)"
  },
  {
    "x": "rêşin, -i",
    "y": " b. rîşin, -e"
  },
  {
    "x": "rêşî (m)",
    "y": " b. rîşî (m)"
  },
  {
    "x": "rêşn (n)",
    "y": " b. roşn (n)"
  },
  {
    "x": "rêşt-I (n)",
    "y": " b. roşt (n)"
  },
  {
    "x": "rêşt-II (n)",
    "y": " b. rast, -e, raşt, -e"
  },
  {
    "x": "rêşta (m)",
    "y": " iğ, eğirmen, kirmen"
  },
  {
    "x": "rêştene",
    "y": " b. rêstene"
  },
  {
    "x": "rêştey (m)",
    "y": " b. roştîye (m)"
  },
  {
    "x": "rêştiş (n)",
    "y": " b. rêstiş (n)"
  },
  {
    "x": "rêtiki (m)",
    "y": " b. rîtike (m)"
  },
  {
    "x": "rêvas (n)",
    "y": " b. ribêse (m)"
  },
  {
    "x": "rêvaz (n)",
    "y": " b. ribêse (m)"
  },
  {
    "x": "rêw",
    "y": " b. rew"
  },
  {
    "x": "rêwas (n)",
    "y": " b. ribêse (m)"
  },
  {
    "x": "rêwaz (n)",
    "y": " b. ribêse (m)"
  },
  {
    "x": "rêwike (m)",
    "y": " küçük tuluk"
  },
  {
    "x": "rêwite (m)",
    "y": " b. rewte (m)"
  },
  {
    "x": "rêwî, -ye",
    "y": " yolcu"
  },
  {
    "x": "rêwî kerdene",
    "y": " geçirmek, yolcu etmek"
  },
  {
    "x": "rêwî neqlkerdiş",
    "y": " yolcu aktarma"
  },
  {
    "x": "rêwîkerdiş (n)",
    "y": " geçirme, yolcu etme"
  },
  {
    "x": "rêwîtî (m)",
    "y": " seyahat"
  },
  {
    "x": "rêwîyîye (m)",
    "y": " seyahat"
  },
  {
    "x": "rêwuke (m)",
    "y": " b. rêwike (m)"
  },
  {
    "x": "rêwûke (m)",
    "y": " b. rêwike (m)"
  },
  {
    "x": "rêwûte (m)",
    "y": " b. rewte (m)"
  },
  {
    "x": "rêxe (m)",
    "y": " insan veya hayvanın cıvık dışkısı"
  },
  {
    "x": "rêxe eştene",
    "y": " ishal olmak (kaba dilde)"
  },
  {
    "x": "rêxe ro ginayene",
    "y": " ishal olmak (kaba dilde)"
  },
  {
    "x": "rêxe ro gunayene",
    "y": " b. rêxe ro ginayene"
  },
  {
    "x": "rêxe ver xo dayene",
    "y": " ishal olmak (kaba dilde)"
  },
  {
    "x": "rêxeeştiş (n)",
    "y": " amel, ishal (kaba dilde), ötürük, sürgün"
  },
  {
    "x": "rêxeroginayîş (n)",
    "y": " amel, ishal (kaba dilde), ötürük, sürgün"
  },
  {
    "x": "rêxerogunayîş (n)",
    "y": " b. rêxeroginayîş (n)"
  },
  {
    "x": "rêxeverxodayîş (n)",
    "y": " amel, ishal (kaba dilde), ötürük, sürgün"
  },
  {
    "x": "rêxi (m)",
    "y": " b. rêxe (m)"
  },
  {
    "x": "rêxistin bîyene",
    "y": " örgütlenmek"
  },
  {
    "x": "rêxistin kerdene",
    "y": " örgütlemek"
  },
  {
    "x": "rêxistinbîyayîş (n)",
    "y": " örgütlenme"
  },
  {
    "x": "rêxistine (m)",
    "y": " örgüt, teşkilat"
  },
  {
    "x": "rêxistinkerdiş (n)",
    "y": " örgütleme"
  },
  {
    "x": "rêxo (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "rêxtike (m)",
    "y": " insan veya hayvanın cıvık dışkısı"
  },
  {
    "x": "rêxtiki (m)",
    "y": " b. rêxtike (m)"
  },
  {
    "x": "rêy (m)",
    "y": " b. rey-I (m)"
  },
  {
    "x": "rêy-rê",
    "y": " b. rey-rey"
  },
  {
    "x": "rêyê",
    "y": " b. reyê "
  },
  {
    "x": "rêyî",
    "y": " b. reyê"
  },
  {
    "x": "rêyna",
    "y": " b. reyna"
  },
  {
    "x": "rêyna ke",
    "y": " b. reyna ke"
  },
  {
    "x": "rêyra",
    "y": " b. reyra"
  },
  {
    "x": "rêz (n)-I",
    "y": " 1)saygı, ihtiram, hürmet"
  },
  {
    "x": "rêz, -e-II",
    "y": " asil, soylu"
  },
  {
    "x": "rêzdar, -e",
    "y": " saygılı, hürmetkâr, hürmetli"
  },
  {
    "x": "rêzdarênî (m)",
    "y": " saygılılık"
  },
  {
    "x": "rêzdarî (m)",
    "y": " saygılılık"
  },
  {
    "x": "rêzdarîye (m)",
    "y": " saygılılık"
  },
  {
    "x": "rêze (m)",
    "y": " 1)dizi, sıra\r\n~2)satır\r\n~3)hiza\r\n~4)katar"
  },
  {
    "x": "rêz kerdene",
    "y": " sıralamak"
  },
  {
    "x": "rêza arîtmetîkî (m)",
    "y": " aritmetik dizi (mat)"
  },
  {
    "x": "rêza ... de",
    "y": " hizasında"
  },
  {
    "x": "rêza ... ra",
    "y": " hizasında"
  },
  {
    "x": "rêza wesayîtan (m)",
    "y": " taşıt dizisi, taşıt katarı"
  },
  {
    "x": "rêzefîlm (n)",
    "y": " dizi film"
  },
  {
    "x": "rêzenuşte (n)",
    "y": " tefrika"
  },
  {
    "x": "rêzi",
    "y": " b. rêze (m)"
  },
  {
    "x": "rêzil (m)",
    "y": " b. rêzile (m)"
  },
  {
    "x": "rêzile (m)",
    "y": " 1)zincir \r\n~2)fermuar"
  },
  {
    "x": "rêzila erjan (m)",
    "y": " değer zinciri"
  },
  {
    "x": "rêzila qîymetan (m)",
    "y": " değer zinciri"
  },
  {
    "x": "rêzila zerrêne (m)",
    "y": " altın zincir"
  },
  {
    "x": "rêzila zerrnêne (m)",
    "y": " altın zincir"
  },
  {
    "x": "rêzilfîlm (n)",
    "y": " dizi film"
  },
  {
    "x": "rêzili (m)",
    "y": " b. rêzile (m)"
  },
  {
    "x": "rêzilkî",
    "y": " zincirleme"
  },
  {
    "x": "rêzî (m)",
    "y": " asalet"
  },
  {
    "x": "rêzîl, -e",
    "y": " b. rezîl, -e"
  },
  {
    "x": "rêzkerdiş (n)",
    "y": " sıralama"
  },
  {
    "x": "rêzkerdişo alfabetîk (n)",
    "y": " alfabetik sıralama"
  },
  {
    "x": "rêzkerdişo alfabeyî (n)",
    "y": " alfabetik sıralama"
  },
  {
    "x": "rêznezan, -e",
    "y": " saygısız, hürmetsiz"
  },
  {
    "x": "rêznezanî (m)",
    "y": " saygısızlık, hürmetsizlik"
  },
  {
    "x": "rêznezanîye (m)",
    "y": " saygısızlık, hürmetsizlik"
  },
  {
    "x": "RF modem (n) (TE)",
    "y": " FR modem"
  },
  {
    "x": "ri-I (n)",
    "y": " b. rî (n)"
  },
  {
    "x": "ri dayeni",
    "y": " b. rî dayene"
  },
  {
    "x": "ri ser nayene",
    "y": " b. rî ser nayene"
  },
  {
    "x": "ri vecîyayene",
    "y": " b. ere rî vejîyayene"
  },
  {
    "x": "ri vecîyayîş (n)",
    "y": " b. ererîvejîyayîş (n)"
  },
  {
    "x": "ridê ... ra",
    "y": " b. rîyê ... ra"
  },
  {
    "x": "ri-II (vr)",
    "y": " b. ro-II (vr)"
  },
  {
    "x": "ri xwi nayene",
    "y": " b. ro xo nayene"
  },
  {
    "x": "ri-III (pd)",
    "y": " b. ro-VII (pd)\r\n~Aw dan’ ber ri. (O kapıya vurur./O kapıyı çalar.)"
  },
  {
    "x": "ri-III (pd)",
    "y": " 1)b. rê-III (pd)\r\n~2)b. ro-VII (pd)"
  },
  {
    "x": "rib-I (n)",
    "y": " pekmez"
  },
  {
    "x": "rib-II (n)",
    "y": " çeyrek"
  },
  {
    "x": "ribes (n)",
    "y": " b. ribêse (m)"
  },
  {
    "x": "ribeş (n)",
    "y": " b. ribêse (m)"
  },
  {
    "x": "ribês (n)",
    "y": " b. ribêse (m)"
  },
  {
    "x": "ribêse (m)",
    "y": " ışkın, ravent"
  },
  {
    "x": "ribêş (n)",
    "y": " b. ribêse (m)"
  },
  {
    "x": "ribig (n)",
    "y": " b. ribik (n)"
  },
  {
    "x": "ribik (n)",
    "y": " kile, ölçek (tahıl ölçmeye yarar kap), şinik"
  },
  {
    "x": "ribin, -e (s)",
    "y": " pekmezli"
  },
  {
    "x": "ribîg (n)",
    "y": " b. ribik (n)"
  },
  {
    "x": "ricaw (n)",
    "y": " irin, cerahat"
  },
  {
    "x": "ricawin, -e",
    "y": " irinli, cerahatli"
  },
  {
    "x": "ricefîyayene",
    "y": " b. recifîyayene"
  },
  {
    "x": "ricefîyayîş (n)",
    "y": " b. recifîyayîş (n)"
  },
  {
    "x": "ricîyayene",
    "y": " 1)b. rijîyayene-I\r\n~2)b. rijîyayene-II"
  },
  {
    "x": "ricîyayîş (n)",
    "y": " 1)b. rijîyayîş-I (n)\r\n~2)b. rijîyayîş-II (n)"
  },
  {
    "x": "ricnayene",
    "y": " 1)b. rijnayene-I\r\n~2)b. rijnayene-II"
  },
  {
    "x": "ricnayîş (n)",
    "y": " 1)b. rijnayîş-I (n)\r\n~2)b. rijnayîş-I (n)"
  },
  {
    "x": "rid (n)",
    "y": " b. rî (n)"
  },
  {
    "x": "riêşta (m)",
    "y": " b. rêşta (m)"
  },
  {
    "x": "rigirewta (m)",
    "y": " b. rîgirewta (m)"
  },
  {
    "x": "rigirewtî (m)",
    "y": " b. rîgirewtî (m)"
  },
  {
    "x": "rigirewtîye (m)",
    "y": " b. rîgirewtîye (m)"
  },
  {
    "x": "rigureta (m)",
    "y": " b. rîgirewta (m)"
  },
  {
    "x": "rigurete (n)",
    "y": " b. rîgirewte (n)"
  },
  {
    "x": "rigureti (n)",
    "y": "  b. rîgirewte (n)"
  },
  {
    "x": "riguretî (m)",
    "y": " b. rîgirewtî (m)"
  },
  {
    "x": "riguretîye (m)",
    "y": " b. rîgirewtîye (m)"
  },
  {
    "x": "rih (n)",
    "y": " b. ruh (n)"
  },
  {
    "x": "Riha (m)",
    "y": " Urfa"
  },
  {
    "x": "rihal (n)",
    "y": " genç (delikanlı)\r\n~“Tawê şîyene de rihalan raştê verê zîyare de astorî vaznêne.” (Çeko, Vate, Nr. 1, r. 3\r\n~4)"
  },
  {
    "x": "rihalî (m)",
    "y": " gençlik"
  },
  {
    "x": "rihalîye (m)",
    "y": " gençlik"
  },
  {
    "x": "rihan (n)",
    "y": " reyhan"
  },
  {
    "x": "rihat, -e",
    "y": " b. rehet,-e"
  },
  {
    "x": "rihet, -e",
    "y": " b. rehet,-e"
  },
  {
    "x": "rihon (n)",
    "y": " b. rihan (n)"
  },
  {
    "x": "rijayene",
    "y": " b. rijîyayene"
  },
  {
    "x": "rijayîş (n)",
    "y": " b. rijîyayîş (n)"
  },
  {
    "x": "rijî, -ye",
    "y": " yağsız"
  },
  {
    "x": "rijîyaya/e",
    "y": " bozuk¸ bozulmuş olan"
  },
  {
    "x": "rijîyaye (n)",
    "y": " döküntü"
  },
  {
    "x": "rijîyayeyê petrolî (n)",
    "y": " petrol döküntüsü"
  },
  {
    "x": "rijîyayene-I",
    "y": " 1)yıkılmak\r\n~2)bozulmak"
  },
  {
    "x": "rijîyayene-II",
    "y": " 1)dökülmek\r\n~2)akmak (yıldız akmak), kaymak (yıldız kaymak)"
  },
  {
    "x": "rijîyayîş-I (n)",
    "y": " 1)yıkılma\r\n~2)çöküş, çöküş (devlet veya uygarlık için), inhitat, dekadans\r\n~3)bozulma"
  },
  {
    "x": "rijîyayîş-II (n)",
    "y": " 1)dökülme\r\n~2)akma (yıldız akması), kayma (yıldız kayması)"
  },
  {
    "x": "ritenik, -i",
    "y": " b. rîtenik, -e"
  },
  {
    "x": "ritenik bîyayene",
    "y": " b. rîtenik bîyene"
  },
  {
    "x": "ru de vinetene",
    "y": " b. ere rî vejîyayene"
  },
  {
    "x": "rudevinetiş (n)",
    "y": " b. rîdevindetiş (n)"
  },
  {
    "x": "rijnayene-I",
    "y": " 1)yıkmak\r\n~2)bozmak"
  },
  {
    "x": "rijnayene-II",
    "y": " dökmek"
  },
  {
    "x": "rijnayîş-I (n)",
    "y": " 1)yıkma, yıkım\r\n~2)bozma"
  },
  {
    "x": "rijnayîş-II (n)",
    "y": " dökme"
  },
  {
    "x": "rijyayene",
    "y": " b. rijîyayene"
  },
  {
    "x": "rijyayîş (n)",
    "y": " b. rijîyayîş (n)"
  },
  {
    "x": "rik (m)",
    "y": " b. rike (m)"
  },
  {
    "x": "rik kerdene",
    "y": " b. rike kerdene"
  },
  {
    "x": "rikdar, -e",
    "y": " kinci, kindar"
  },
  {
    "x": "rike (m)",
    "y": " 1)garaz, garez, kin\r\n~2)inat"
  },
  {
    "x": "rike çarnayene",
    "y": " inat etmek"
  },
  {
    "x": "rike kerdene",
    "y": " inat etmek"
  },
  {
    "x": "rikê (m)",
    "y": " b. rikinî (m), rikinîye (m)"
  },
  {
    "x": "rikêf (n)",
    "y": " üzengi"
  },
  {
    "x": "riki (m)",
    "y": " b. rike (m)"
  },
  {
    "x": "riki çarnayene",
    "y": " b. rike çarnayene"
  },
  {
    "x": "riki kerdene",
    "y": " b. rike kerdene"
  },
  {
    "x": "rikin, -e",
    "y": " inatçı, inat"
  },
  {
    "x": "rikiney (m)",
    "y": " b. rikinî (m), rikinîye (m)"
  },
  {
    "x": "rikinê (m)",
    "y": " b. rikinî (m), rikinîye (m)"
  },
  {
    "x": "rikini (m)",
    "y": " b. rikine (m)"
  },
  {
    "x": "rikinî (m)",
    "y": " inatçılık"
  },
  {
    "x": "rikinîye (m)",
    "y": " inatçılık"
  },
  {
    "x": "riko, -ye",
    "y": " inatçı, inat"
  },
  {
    "x": "rikoyey (m)",
    "y": " b. rikoyî (m), rikoyîye (m)"
  },
  {
    "x": "rikoyê (m)",
    "y": " b. rikoyî (m), rikoyîye (m)"
  },
  {
    "x": "rikoyî (m)",
    "y": " inatçılık"
  },
  {
    "x": "rikoyîye (m)",
    "y": " inatçılık"
  },
  {
    "x": "rim (n)",
    "y": " şey\r\n~Rimî bîyare. (Şeyi getir.)"
  },
  {
    "x": "rimbêz (n)",
    "y": " b. ribêse (m)"
  },
  {
    "x": "rincan, -e",
    "y": " yorgun"
  },
  {
    "x": "rincan bîyene",
    "y": " yorulmak"
  },
  {
    "x": "rincan kerdene",
    "y": " yormak"
  },
  {
    "x": "rincanbîyayîş (n)",
    "y": " yorulma, rincanê (m). b. rincanîye (m)"
  },
  {
    "x": "rincanê (m). b. rincanîye (m)"
  },
  {
    "x": "rincanî (m)",
    "y": " yorgunluk"
  },
  {
    "x": "rincanîye (m)",
    "y": " yorgunluk"
  },
  {
    "x": "rincankerdiş (n)",
    "y": " yorma"
  },
  {
    "x": "rind, -e-I",
    "y": " 1)güzel, yakışıklı, keleş\r\n~2)iyi\r\n~Ez rind a./ Ez rind ane./ Ez rind ûne. (1-Ben güzelim. 2-Ben iyiyim.)"
  },
  {
    "x": "rind-xirab",
    "y": " iyi kötü, acı tatlı"
  },
  {
    "x": "rinde-II (n)",
    "y": " b. rende (n)"
  },
  {
    "x": "rindek, -e",
    "y": " güzel, yakışıklı, keleş"
  },
  {
    "x": "rindekey (m)",
    "y": " b. rindekîye (m)"
  },
  {
    "x": "rindekê (m)",
    "y": " b. rindekî (m)"
  },
  {
    "x": "rindekênî (m)",
    "y": " güzellik"
  },
  {
    "x": "rindeki (m)",
    "y": " b. rindeke (m)"
  },
  {
    "x": "rindekî (m)",
    "y": " güzellik"
  },
  {
    "x": "rindekîne (m)",
    "y": " b. rindekênî (m)"
  },
  {
    "x": "rindekîye (m)",
    "y": " güzellik"
  },
  {
    "x": "rindey (m)",
    "y": " b. rindîye (m), rindî (m)"
  },
  {
    "x": "rindê (m)",
    "y": " b. rindî (m)"
  },
  {
    "x": "rindênî (m)",
    "y": " 1)güzellik\r\n~2)iyilik"
  },
  {
    "x": "rindi (m)",
    "y": " b. rinde (m)"
  },
  {
    "x": "rindi (n)",
    "y": " b. rende (n)"
  },
  {
    "x": "rindik, -i",
    "y": " b. rindek, -e"
  },
  {
    "x": "rindikê (m)",
    "y": " b. rindekî (m)"
  },
  {
    "x": "rindikî (m)",
    "y": " b. rindekî (m)"
  },
  {
    "x": "rindî (m)",
    "y": " 1)güzellik\r\n~2)iyilik"
  },
  {
    "x": "rindîne (m)",
    "y": " b. rindênî (m)"
  },
  {
    "x": "rindîye (m)",
    "y": " 1)güzellik\r\n~2)iyilik"
  },
  {
    "x": "rindo (n)",
    "y": " b. rende (n)"
  },
  {
    "x": "rioci (m)",
    "y": " b. roje-I (m)"
  },
  {
    "x": "ripel (n)",
    "y": " b. rîpel (n)"
  },
  {
    "x": "riper (n)",
    "y": " b. rîpel (n)"
  },
  {
    "x": "riperr (n)",
    "y": " b. rîpel (n)"
  },
  {
    "x": "riq-rut, -e",
    "y": " b. req-rut, -e"
  },
  {
    "x": "riq-rût, -e",
    "y": " b. req-rut, -e"
  },
  {
    "x": "riqûrut, -e",
    "y": " b. reqûrut, -e"
  },
  {
    "x": "ris-I (n)",
    "y": " b. rîç (n)"
  },
  {
    "x": "ris-II (n)",
    "y": " b. rîşe (m)"
  },
  {
    "x": "risaya (m)",
    "y": " b. resaya (m), resayîye (m)"
  },
  {
    "x": "risaye (n)",
    "y": " b. resaye (n)"
  },
  {
    "x": "risayene",
    "y": " b. resayene"
  },
  {
    "x": "risayîş (n)",
    "y": " b. resayîş (n)"
  },
  {
    "x": "risayîye (m)",
    "y": " b. resaya (m), resayîye (m)"
  },
  {
    "x": "risernayîş (n)",
    "y": " b. rîsernayîş (n)"
  },
  {
    "x": "risêney (m)",
    "y": " b. rîsîyayênî (m)"
  },
  {
    "x": "risim (n)",
    "y": " b. resim (n)"
  },
  {
    "x": "risipîyey (m)",
    "y": " b. rîsipîyîye (m)"
  },
  {
    "x": "risipîyê (m)",
    "y": " b. rîsipîyî (m)"
  },
  {
    "x": "risîyayey (m)",
    "y": " b. rîsîyayîye (m)"
  },
  {
    "x": "riske (m)",
    "y": " b. rişke"
  },
  {
    "x": "riskîyayene",
    "y": " b. rêsikîyayene"
  },
  {
    "x": "riskîyayîş (n)",
    "y": " b. rêsikîyayîş (n)"
  },
  {
    "x": "rism (n)",
    "y": " b. resim (n)"
  },
  {
    "x": "risnayene-I",
    "y": " b. rişnayene"
  },
  {
    "x": "risnayene-II",
    "y": " b. resnayene"
  },
  {
    "x": "risnayeni",
    "y": " b. rijnayene"
  },
  {
    "x": "risnayêne",
    "y": " b. rişnayene"
  },
  {
    "x": "risnayîne",
    "y": " b. rişnayene"
  },
  {
    "x": "risnayîş-I (n)",
    "y": " b. rijnayîş (n)"
  },
  {
    "x": "risnayîş-II (n)",
    "y": " b. rişnayîş (n)"
  },
  {
    "x": "risnayîş-III (n)",
    "y": " b. resnayîş (n)"
  },
  {
    "x": "rispêney (m)",
    "y": " b. rîsipênî (m)"
  },
  {
    "x": "ristem, -e",
    "y": " dayanıklı (insan için)"
  },
  {
    "x": "risteni",
    "y": " b. riştene"
  },
  {
    "x": "ristim (n)",
    "y": " kök"
  },
  {
    "x": "ristiş (n)",
    "y": " b. riştiş-I (n) "
  },
  {
    "x": "risvet (n)",
    "y": " b. rişwet (n)"
  },
  {
    "x": "rişayene",
    "y": " b. rişîyayene"
  },
  {
    "x": "rişayîş (n)",
    "y": "b. rişîyayîş (n)"
  },
  {
    "x": "rişdî (m)",
    "y": " b. riştî (m)"
  },
  {
    "x": "rişêş (n)",
    "y": " b. rişîş (n)"
  },
  {
    "x": "rişî-I (m)",
    "y": " b. rîşî (m)"
  },
  {
    "x": "rişî-II (n)",
    "y": " b. rêsî (m)"
  },
  {
    "x": "rişîş (n)",
    "y": " çisinti, ahmakıslatan, çisenti, çise "
  },
  {
    "x": "rişîyayene",
    "y": " dökülmek"
  },
  {
    "x": "rişîyayêne",
    "y": " b. rişîyayene"
  },
  {
    "x": "rişîyayîş (n)",
    "y": " dökülme "
  },
  {
    "x": "rişk (m)",
    "y": " b. rişke"
  },
  {
    "x": "rişke (m)",
    "y": " bit yumurtası, sirke"
  },
  {
    "x": "rişki (m)",
    "y": " b. rişke (m)"
  },
  {
    "x": "rişkin, -e",
    "y": " bitli"
  },
  {
    "x": "rişkini (m)",
    "y": " b. rişkin, -e"
  },
  {
    "x": "rişnayene",
    "y": " dökmek"
  },
  {
    "x": "rişnayeni",
    "y": " b. rijnayene-I"
  },
  {
    "x": "rişnayîş-I (n)",
    "y": " dökme"
  },
  {
    "x": "rişnayîş-II (n)",
    "y": " b. rijnayîş (n)"
  },
  {
    "x": "rişot (n)",
    "y": " b. rişwet (n)"
  },
  {
    "x": "rişt (m)",
    "y": " b. riştî (m)"
  },
  {
    "x": "rişta (m)",
    "y": " b. riştî (m)"
  },
  {
    "x": "rişte (n)",
    "y": " bağ (bağlamak için kullanılan)"
  },
  {
    "x": "rişte (m)",
    "y": " b. riştî (m)"
  },
  {
    "x": "riştele (n)",
    "y": " b. rîştele (n)"
  },
  {
    "x": "riştene-I",
    "y": " göndermek, yollamak"
  },
  {
    "x": "riştene-II",
    "y": " örmek"
  },
  {
    "x": "riştene-III",
    "y": " b. rêstene"
  },
  {
    "x": "rişteni",
    "y": " b. riştene-I"
  },
  {
    "x": "riştik (n)",
    "y": " bağ (bağlamak için kullanılan)"
  },
  {
    "x": "riştiş-I (n)",
    "y": " 1)örme\r\n~2)b. rêstiş (n)"
  },
  {
    "x": "riştiş-II (n)",
    "y": " gönderme, yollama"
  },
  {
    "x": "riştî (m)",
    "y": " saban zıvanasını boyunduruğa bağlamakta kullanılan kalın bağ"
  },
  {
    "x": "riştox, -e",
    "y": " örgücü (çorap vb. için)"
  },
  {
    "x": "rişût (n)",
    "y": " b. rişwet (n)"
  },
  {
    "x": "rişvet (n)",
    "y": " b. rişwet (n)"
  },
  {
    "x": "rişvet dayene",
    "y": " b. rişwet dayene"
  },
  {
    "x": "rişvet werdene",
    "y": " b. rişwet werdene"
  },
  {
    "x": "rişwet (n)",
    "y": " rüşvet"
  },
  {
    "x": "rişwet dayene",
    "y": " rüşvet vermek"
  },
  {
    "x": "rişwet dayene werdene",
    "y": " rüşvet yedirmek"
  },
  {
    "x": "rişwet werdene",
    "y": "  rüşvet yemek"
  },
  {
    "x": "rişyayene",
    "y": " b. rişîyayene"
  },
  {
    "x": "rişyayîş (n)",
    "y": " b. rişîyayîş (n)"
  },
  {
    "x": "rit, -e",
    "y": " b. rut, -e"
  },
  {
    "x": "ritene",
    "y": " b. rutene"
  },
  {
    "x": "ritirş, -e",
    "y": " b. rîtirş, -e"
  },
  {
    "x": "ritiş (n)",
    "y": " b. rutiş (n)"
  },
  {
    "x": "ritvet (n)",
    "y": " b. rutubet (n)"
  },
  {
    "x": "riv (n)",
    "y": " b. rib (n)"
  },
  {
    "x": "rives (n)",
    "y": " b. ribêse (m)"
  },
  {
    "x": "rivês (n)",
    "y": " b. ribêse (m)"
  },
  {
    "x": "rivîşîyayîş (n)",
    "y": " b. recifîyayîş (n)"
  },
  {
    "x": "riwe (n)",
    "y": " b. rûn (n)"
  },
  {
    "x": "riwen (n)",
    "y": " b. rûn (n)"
  },
  {
    "x": "riwenê zeytinan (n)",
    "y": " b. rûnê zeytunan (n)"
  },
  {
    "x": "riwenê zeytunan (n)",
    "y": " b. rûnê zeytunan (n)"
  },
  {
    "x": "riweno kele (n)",
    "y": " b. rûno kele (n)"
  },
  {
    "x": "riwenin, -i",
    "y": " b. rûnin, -e"
  },
  {
    "x": "rix (m)",
    "y": " b. rêxe (m)"
  },
  {
    "x": "rix (n)",
    "y": " b. rike (m)"
  },
  {
    "x": "rixe (m)",
    "y": " b. rêxe (m)"
  },
  {
    "x": "rixwinayîş (n)",
    "y": " itiraf"
  },
  {
    "x": "riz (n)",
    "y": " pirinç"
  },
  {
    "x": "riza (m)",
    "y": " rıza"
  },
  {
    "x": "rizaname (n)",
    "y": " bonservis"
  },
  {
    "x": "rizayene",
    "y": " çürüyüp dökülmek"
  },
  {
    "x": "rizayîş (n)",
    "y": " çürüyüp dökülme"
  },
  {
    "x": "rizgarî (m)",
    "y": " kurtuluş"
  },
  {
    "x": "rizgarîye (m)",
    "y": " kurtuluş"
  },
  {
    "x": "rizîyayene",
    "y": " b. rijîyayene-I"
  },
  {
    "x": "rizîyayîş (n)",
    "y": " b. rijîyayîş-I (n)"
  },
  {
    "x": "riznayene-I",
    "y": " b. rijnayene-I"
  },
  {
    "x": "riznayene-II",
    "y": " b. rişnayene"
  },
  {
    "x": "riznayêne",
    "y": " b. rijnayene-I"
  },
  {
    "x": "riznayine",
    "y": " b. rijnayene-I"
  },
  {
    "x": "riznayîş-I (n)",
    "y": " b. rijnayîş-I (n)"
  },
  {
    "x": "riznayîş-II (n)",
    "y": " b. rişnayîş-II (n)"
  },
  {
    "x": "rizwanik (n)",
    "y": " anason"
  },
  {
    "x": "rizwanikin, -e",
    "y": " anasonlu"
  },
  {
    "x": "rî-I (n)",
    "y": " 1)yüz, surat\r\n~2)kılıf (yastık vb. için)"
  },
  {
    "x": "rî dayene",
    "y": " şımartmak"
  },
  {
    "x": "rî de vindetene",
    "y": " çemkirmek, sert cevap vermek"
  },
  {
    "x": "rî girewtene",
    "y": " şımarmak"
  },
  {
    "x": "rî ... ra",
    "y": " b. rîyê ... ra"
  },
  {
    "x": "rî ro ci nayene",
    "y": " ısrar etmek"
  },
  {
    "x": "rî ser nayene",
    "y": " ısrar etmek, bastırmak, üstüne düşmek"
  },
  {
    "x": "rîy ardan (n)",
    "y": " b. rîyê erdan (n)"
  },
  {
    "x": "rîy ardî (n)",
    "y": " b. rîyê erdî (n)"
  },
  {
    "x": "rîy ... çin bîyayene",
    "y": " b. rîyê ... çin bîyene"
  },
  {
    "x": "rîy ... çinbîyayîş",
    "y": " b. rîyê ... çinbîyayîş"
  },
  {
    "x": "rîy ... decayeni",
    "y": " b. rîyê ... dejayene"
  },
  {
    "x": "rîy ... decayîş",
    "y": " b. rîyê ... dejayîş"
  },
  {
    "x": "rîy erdûn (n)",
    "y": " rîyê erdan (n)"
  },
  {
    "x": "rîy ... ra",
    "y": " b. rîyê ... ra"
  },
  {
    "x": "rîyê aye ra",
    "y": " onun yüzünden, ondan dolayı"
  },
  {
    "x": "rîyê balişna (n)",
    "y": " yastık kılıfı"
  },
  {
    "x": "rîyê ... çin bîyene",
    "y": " utanmak (mecazi)"
  },
  {
    "x": "rîyê ... çinbîyayîş",
    "y": " utanma (mecazi)"
  },
  {
    "x": "rîyê ... dejayene",
    "y": " utanmak (mecazi)"
  },
  {
    "x": "rîyê ... dejayîş",
    "y": " utanma (mecazi)"
  },
  {
    "x": "rîyê doşekî (n)",
    "y": " yatak yüzü"
  },
  {
    "x": "rîyê erdan (n)",
    "y": " yeryüzü, arz"
  },
  {
    "x": "rîyê erdî (n)",
    "y": " yeryüzü, arz"
  },
  {
    "x": "rîyê ey ra",
    "y": " onun yüzünden, ondan dolayı"
  },
  {
    "x": "rîyê hardan (n)",
    "y": " b. rîyê erdan (n)"
  },
  {
    "x": "rîyê hardî (n)",
    "y": " b. rîyê erdî (n)"
  },
  {
    "x": "rîyê herdan (n)",
    "y": " b. rîyê erdan (n)"
  },
  {
    "x": "rîyê herdî (n)",
    "y": " b. rîyê erdî (n)"
  },
  {
    "x": "rîyê lihêfî (n)",
    "y": " yorgan yüzü"
  },
  {
    "x": "rîyê ... ra",
    "y": " nedeniyle, sebebiyle, yüzünden, dolayı, ötürü"
  },
  {
    "x": "rîyê xo",
    "y": " yüzü"
  },
  {
    "x": "rî-II (pd)",
    "y": " b. rê-III (pd)"
  },
  {
    "x": "rîba (m)",
    "y": " faiz"
  },
  {
    "x": "rîba girewtene",
    "y": " faiz almak"
  },
  {
    "x": "rîbalê ... ra",
    "y": " b. rîwalê ... ra"
  },
  {
    "x": "rîbawer, -e",
    "y": " faizci"
  },
  {
    "x": "rîbawerî (m)",
    "y": " faizcilik, tefecilik"
  },
  {
    "x": "rîbawerîye (m)",
    "y": " faizcilik, tefecilik"
  },
  {
    "x": "rîbawerîye kerdene",
    "y": " tefecilik yapmak"
  },
  {
    "x": "rîç (n)",
    "y": " kök"
  },
  {
    "x": "rîçal (n)",
    "y": " reçel"
  },
  {
    "x": "rîçe (n)",
    "y": " b. rîç (n)"
  },
  {
    "x": "rîçik-I (n)",
    "y": " kök"
  },
  {
    "x": "rîçik-II (m)",
    "y": " b. rîçike (m)"
  },
  {
    "x": "rîçik kerdene",
    "y": " budamak (üzüm teveği vb. için)"
  },
  {
    "x": "rîçike (m)",
    "y": " bağ budamakta kullanılan bir tür bıçak"
  },
  {
    "x": "rîçikkerdiş (n)",
    "y": " budama (üzüm teveği vb. için)"
  },
  {
    "x": "rîdevindetiş (n)",
    "y": " çemkirme, çemkiriş "
  },
  {
    "x": "rîdnayene",
    "y": " b. rarîdayene"
  },
  {
    "x": "rîdnayîs (n)",
    "y": " b. rarîdayîş (n)"
  },
  {
    "x": "rîej (n)",
    "y": " b. rêj (n)"
  },
  {
    "x": "rîej dayene",
    "y": " b. rêj dayene"
  },
  {
    "x": "rîejdayiş (n)",
    "y": " b. rêjdayîş (n)"
  },
  {
    "x": "rîejnayene",
    "y": " b. rêjnayene"
  },
  {
    "x": "rîejnayiş (n)",
    "y": " b. rêjnayîş (n)"
  },
  {
    "x": "rîejyayi (n)",
    "y": " b. rêjîyaye (n)"
  },
  {
    "x": "rîem (n)",
    "y": " b. rêm (n)"
  },
  {
    "x": "rîem bestene",
    "y": " b. rêm bestene"
  },
  {
    "x": "rîem dekotene",
    "y": " b. rêm dekewtene"
  },
  {
    "x": "rîem girotene",
    "y": " b. rêm girewtene"
  },
  {
    "x": "rîemin (n)",
    "y": " b. rêmin, -e "
  },
  {
    "x": "rîer (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "rîes (n)",
    "y": " b. rês (n)"
  },
  {
    "x": "rîesin",
    "y": " b. rêsin, -e, rêsên, -e"
  },
  {
    "x": "rîeskayene",
    "y": " b. rêsikîyayene"
  },
  {
    "x": "rîeskayîş (n)",
    "y": " b. rêsikîyayîş (n)"
  },
  {
    "x": "rîeskîyayene",
    "y": " b. rêsikîyayene"
  },
  {
    "x": "rîeskîyayîş (n)",
    "y": " b. rêsikîyayîş (n)"
  },
  {
    "x": "rîeşmi (n)",
    "y": " b. reşme (n)"
  },
  {
    "x": "rîeşta (m)",
    "y": " b. rêşta (m)"
  },
  {
    "x": "rîex (m)",
    "y": " b. rêxe (m)"
  },
  {
    "x": "rîex eştene",
    "y": " b. rêxe eştene"
  },
  {
    "x": "rîexi ri ginayîne",
    "y": " b. rêxe ro ginayene"
  },
  {
    "x": "rîex ver xwe dayîne",
    "y": " b. rêxe ver xo dayene"
  },
  {
    "x": "rîexeştiş (n)",
    "y": " rêxeeştiş (n)"
  },
  {
    "x": "rîexroginayîş (n)",
    "y": " b. rêxeroginayîş (n)"
  },
  {
    "x": "rîexverxwedayîş (n)",
    "y": " b. rêxeverxodayîş (n)"
  },
  {
    "x": "rîezil (m)",
    "y": " b. rêzile (m)"
  },
  {
    "x": "Rîga (m)",
    "y": " Riga"
  },
  {
    "x": "rîgirewta (m)",
    "y": " şımarık"
  },
  {
    "x": "rîgirewte (n)",
    "y": " şımarık"
  },
  {
    "x": "rîgirewtênî (m)",
    "y": " şımarıklık"
  },
  {
    "x": "rîgirewtî (m)",
    "y": " şımarık"
  },
  {
    "x": "rîgirewtîye (m)",
    "y": " şımarık"
  },
  {
    "x": "rîgirewtîyîye (m)",
    "y": " şımarıklık"
  },
  {
    "x": "rîhan (n)",
    "y": " b. rihan (n)"
  },
  {
    "x": "rîhon (n)",
    "y": " b. rihan (n)"
  },
  {
    "x": "rîjîyayene",
    "y": " b. rijîyayene"
  },
  {
    "x": "rîjîyayîş (n)",
    "y": " b. rijîyayîş (n)"
  },
  {
    "x": "rîjnayene",
    "y": " b. rijnayene"
  },
  {
    "x": "rîjnayîş (n)",
    "y": " b. rijnayîş (n)"
  },
  {
    "x": "rîm (n)",
    "y": " b. rêm (n)"
  },
  {
    "x": "rîm bestene",
    "y": " b. rêm bestene"
  },
  {
    "x": "rîm ci kotene",
    "y": " b. rêm ci kewtene"
  },
  {
    "x": "rîm dekotene",
    "y": " b. rêm dekewtene"
  },
  {
    "x": "rîm gurotene",
    "y": " b. rêm girewtene"
  },
  {
    "x": "rîmin (n)",
    "y": " b. rêmin, -e"
  },
  {
    "x": "rîna",
    "y": " b. reyna"
  },
  {
    "x": "rîpel (n)",
    "y": " sayfa, sahife"
  },
  {
    "x": "rîpelê dewamî/domî (n)",
    "y": " devam sayfası, mabat, mabat sayfası"
  },
  {
    "x": "rîpelsazî (m)",
    "y": " mizanpaj"
  },
  {
    "x": "rîpêş (n)",
    "y": " 1)örtü\r\n~2)maske, yüz maskesi"
  },
  {
    "x": "rîpêşê cile (n)",
    "y": " yatak örtüsü"
  },
  {
    "x": "rîpêşê masa (n)",
    "y": " masa örtüsü"
  },
  {
    "x": "rîpêşê nivîne (n)",
    "y": " yatak örtüsü"
  },
  {
    "x": "rîqal (n)",
    "y": " huzur\r\n~Ey rîqalê walî de qisey kerd. (O valinin huzurunda konuştu.)"
  },
  {
    "x": "rîrocinayîş (n)",
    "y": " ısrar etme"
  },
  {
    "x": "rîs (n)",
    "y": " b. rês (n)"
  },
  {
    "x": "rîsale (n)",
    "y": " broşür, risale"
  },
  {
    "x": "rîsernayîş (n)",
    "y": " ısrar etme, bastırma, üstüne düşme"
  },
  {
    "x": "rîsipênî (m)",
    "y": " akyüzlülük"
  },
  {
    "x": "rîsipî, -ye",
    "y": " 1)ak saçlı, ak sakallı\r\n~2)akyüzlü (namuslu, doğru kişi)"
  },
  {
    "x": "rîsipî bîyene",
    "y": " aklanmak"
  },
  {
    "x": "rîsipî kerdene",
    "y": " aklamak"
  },
  {
    "x": "rîsipî vejîyayene",
    "y": " aklanmak"
  },
  {
    "x": "rîsipî vetene",
    "y": " aklamak"
  },
  {
    "x": "rîsipîbîyayîş (n)",
    "y": " aklanma"
  },
  {
    "x": "rîsipîvejîyayîş (n)",
    "y": " aklanma"
  },
  {
    "x": "rîsipîyî (m)",
    "y": " akyüzlülük"
  },
  {
    "x": "rîsipîyîye (m)",
    "y": " akyüzlülük"
  },
  {
    "x": "rîsîyayênî (m)",
    "y": " skandal"
  },
  {
    "x": "rîsîyayî (m)",
    "y": " b. rîsîyayîye (m)"
  },
  {
    "x": "rîsîyayîye (m)",
    "y": " skandal"
  },
  {
    "x": "rîsk (n)",
    "y": " risk, riziko"
  },
  {
    "x": "rîskê karî (n)",
    "y": " iş riski"
  },
  {
    "x": "rîskê weşîye (zh)",
    "y": " sağlık riskleri"
  },
  {
    "x": "rîskdar, -e",
    "y": " riskli"
  },
  {
    "x": "rîş-I (m)",
    "y": " b. rîşe (m)"
  },
  {
    "x": "rîş-II (n)",
    "y": " b. rîç (n)"
  },
  {
    "x": "rîşe (m)",
    "y": " 1)ur, tümör\r\n~2)yara"
  },
  {
    "x": "rîşa merdimwere (m)",
    "y": " kanser"
  },
  {
    "x": "rîşa merdimwerker, -e",
    "y": " kanser yapıcı"
  },
  {
    "x": "rîşa serre (m)",
    "y": " şark çıbanı, Halep çıbanı, kara yara"
  },
  {
    "x": "rîşa sey (m)",
    "y": " b. rîşa sîyaye (m)"
  },
  {
    "x": "rîşa sîyaye (m)",
    "y": " karakabarcık, karayanık, şarbon, yanıkara"
  },
  {
    "x": "rîşik (n)",
    "y": " saçak (giyim eşyası vb. için)"
  },
  {
    "x": "rîşin, -e",
    "y": " yaralı"
  },
  {
    "x": "rîşî (m)",
    "y": " saçak (giyim eşyası vb. için)"
  },
  {
    "x": "rîşt (m)",
    "y": " b. riştî (m)"
  },
  {
    "x": "rîşta (m)",
    "y": " 1)b. rêşta (m)\r\n~2)b. riştî (m)"
  },
  {
    "x": "rîştele (n)",
    "y": " kök"
  },
  {
    "x": "rîşti (m)",
    "y": " b. riştî (m)"
  },
  {
    "x": "rîştike (m)",
    "y": " b. rêstike"
  },
  {
    "x": "rîtenik, -e",
    "y": " çekingen, utangaç, sıkılgan, utangan"
  },
  {
    "x": "rîtenik bîyene",
    "y": " çekingenleşmek "
  },
  {
    "x": "rîtenikey (m)",
    "y": " b. rîtenikîye (m)"
  },
  {
    "x": "rîtenikê (m)",
    "y": " b. rîtenikîye (m)"
  },
  {
    "x": "rîtenikîye (m)",
    "y": " çekingenlik "
  },
  {
    "x": "rîtenikîye kerdene",
    "y": " çekingen davranmak"
  },
  {
    "x": "rîtike (m)",
    "y": " insan veya hayvanın cıvık dışkısı"
  },
  {
    "x": "rîtike eştene",
    "y": " ishal olmak (kaba dilde)"
  },
  {
    "x": "rîtike kewtene",
    "y": " ishal olmak (kaba dilde)"
  },
  {
    "x": "rîtirş, -e",
    "y": " asık suratlı, abus"
  },
  {
    "x": "rîtîke (m)",
    "y": " b. rîtike (m)"
  },
  {
    "x": "rîtîke estene",
    "y": " b. rîtike eştene"
  },
  {
    "x": "rîtîke eştene",
    "y": " b. rîtike eştene"
  },
  {
    "x": "rîtîke kewtene",
    "y": " b. rîtike kewtene"
  },
  {
    "x": "rîtîke kotene",
    "y": " b. rîtike kewtene"
  },
  {
    "x": "rîvatê ... ra",
    "y": " b. rîwalê ... ra"
  },
  {
    "x": "rîverîye (m)",
    "y": " yüzey (mat), satıh (mat)"
  },
  {
    "x": "rîveş (n)",
    "y": " b. ribêse (m)"
  },
  {
    "x": "rîwalê aye ra",
    "y": " onun yüzünden, ondan dolayı"
  },
  {
    "x": "rîwalê ey ra",
    "y": " onun yüzünden, ondan dolayı"
  },
  {
    "x": "rîwalê ... ra",
    "y": " nedeniyle, sebebiyle, yüzünden, dolayı, ötürü"
  },
  {
    "x": "rîwayete (m)",
    "y": " rivayet "
  },
  {
    "x": "rîy (m)",
    "y": " b. rêxe (m)"
  },
  {
    "x": "rîya (m)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "Rîyad (n)",
    "y": " Riyad"
  },
  {
    "x": "rîyan, -i",
    "y": " b. raywan, -e"
  },
  {
    "x": "rîyar (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "rîyayîne",
    "y": " b. robîyayene"
  },
  {
    "x": "rîye-I (m)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "Rîyê to akerde b’.",
    "y": " b. Rayîrê to akerde bo."
  },
  {
    "x": "rîye kotene",
    "y": " b. rayîr kewtene"
  },
  {
    "x": "rîye-II (m)",
    "y": " b. rêxe (m)"
  },
  {
    "x": "rîyej (n)",
    "y": " b. rêj (n)"
  },
  {
    "x": "rîyej kerdene",
    "y": " b. rêj kerdene"
  },
  {
    "x": "rîyejayiş (n)",
    "y": " b. rêjîyayîş"
  },
  {
    "x": "rîyejkerdiş (n)",
    "y": " b. rêjkerdiş (n)"
  },
  {
    "x": "rîyekotiş (n)",
    "y": " b. rayîrkewtiş (n)"
  },
  {
    "x": "rîyem (n)",
    "y": " b. rêm (n)"
  },
  {
    "x": "rîyem girotene",
    "y": " b. rêm girewtene"
  },
  {
    "x": "rîyer (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "rîyes (n)",
    "y": " b. rês (n)"
  },
  {
    "x": "rîyesin",
    "y": " b. rêsin, -e, rêsên, -e"
  },
  {
    "x": "rîyex (m)",
    "y": " b. rêxe (m)"
  },
  {
    "x": "rîyez (m)",
    "y": " b. rêze (m)"
  },
  {
    "x": "rîyezil (m)",
    "y": " b. rêzile (m)"
  },
  {
    "x": "rîyêşta (m)",
    "y": " b. rêşta (m)"
  },
  {
    "x": "rîyi (n)",
    "y": " b. rayîr (n)"
  },
  {
    "x": "rîyon, -i",
    "y": " b. raywan, -e"
  },
  {
    "x": "rîyş (m)",
    "y": " b. rîşe (m)"
  },
  {
    "x": "rîyûn, -i",
    "y": " b. raywan, -e"
  },
  {
    "x": "rîza (n)",
    "y": " b. riza (m)"
  },
  {
    "x": "rîzîko (n)",
    "y": " risk, riziko"
  },
  {
    "x": "ro-I (n)",
    "y": " ırmak, nehir, akarsu"
  },
  {
    "x": "ro-II (vr)",
    "y": " bir önedat, -e"
  },
  {
    "x": "ro ... amayene",
    "y": " b. ro ... ameyene"
  },
  {
    "x": "ro ... ameyene",
    "y": " 1)-e değmek, -e isabet etmek\r\n~2)yakışmak, uygun düşmek, uygun gelmek, uygun olmak, uymak, yaraşmak\r\n~No çakêt/şak/setre zaf ro to ame. (Bu ceket sana çok yakıştı.)"
  },
  {
    "x": "ro ... ameyîş",
    "y": " yakışma"
  },
  {
    "x": "ro ci eşeqîyene",
    "y": " -e âşık olmak"
  },
  {
    "x": "ro ci fetelîyayene",
    "y": " b. ro ci fetilîyayene"
  },
  {
    "x": "ro ci fetilîyayene",
    "y": " aramak"
  },
  {
    "x": "ro ci geyrayîne",
    "y": " b. ro ci gêrayene"
  },
  {
    "x": "ro ci gêrayene",
    "y": " 1)aramak\r\n~2)b. cigêrayene"
  },
  {
    "x": "ro ci kewtene",
    "y": " b. ro ... kewtene"
  },
  {
    "x": "ro ci mendene",
    "y": " -e benzemek"
  },
  {
    "x": "ro ci şîyene",
    "y": " -e benzemek"
  },
  {
    "x": "ro … ginayene",
    "y": " -e değmek, -e isabet etmek"
  },
  {
    "x": "ro … ginayêne",
    "y": " b. ro … ginayene"
  },
  {
    "x": "ro karguretiş",
    "y": " b. ro kargirewtiş"
  },
  {
    "x": "ro kargirewtiş",
    "y": " işe alma"
  },
  {
    "x": "ro karkewtiş",
    "y": " işe girme"
  },
  {
    "x": "ro ... kewtene",
    "y": " yakışmak, uygun düşmek, uygun gelmek, uygun olmak, uymak, yaraşmak"
  },
  {
    "x": "ro ... kewtiş",
    "y": " yakışma"
  },
  {
    "x": "ro ... niştiş",
    "y": " -e binme"
  },
  {
    "x": "ro … niştene",
    "y": " -e binmek"
  },
  {
    "x": "ro … nîstene",
    "y": " b. ro … niştene"
  },
  {
    "x": "ro … nîstis",
    "y": " b. ro … niştiş"
  },
  {
    "x": "ro … nîştiş",
    "y": " b. ro … niştiş"
  },
  {
    "x": "ro weşa ... şîyayene",
    "y": " b. ro weşa ... şîyene"
  },
  {
    "x": "ro weşa ... şîyene",
    "y": " beğenmek"
  },
  {
    "x": "ro xo ameyene",
    "y": " 1)iyileşmek\r\n~2)kendine gelmek"
  },
  {
    "x": "ro xo nayene",
    "y": " itiraf etmek\r\n~Ey na ro xo. (O itiraf etti.)"
  },
  {
    "x": "ro-III",
    "y": " fiilin gelecek zamanında kullanılan bir partikel"
  },
  {
    "x": "ro-IV",
    "y": " b. rew"
  },
  {
    "x": "ro-V (n)",
    "y": " b. ruh (n)"
  },
  {
    "x": "royî ver de bîyene",
    "y": " b. ruhî ver de bîyene"
  },
  {
    "x": "ro-VI (n)",
    "y": " b. rûn (n)"
  },
  {
    "x": "ro-VII (pd)",
    "y": " bir artedat, -den, -e"
  },
  {
    "x": "... ro alizîyayene",
    "y": " tırmanmak"
  },
  {
    "x": "... ro alizyayene",
    "y": " b. ... ro alizîyayene"
  },
  {
    "x": "... ro cenayene",
    "y": " batırmak\r\n~Kardî meşka heywanî ro cenaya. (Bıçağı hayvanın karnına batırmış.)"
  },
  {
    "x": "... ro kerdene",
    "y": " 1)batırmak\r\n~Kardî meşka heywanî ra/ro kerda. (Bıçağı hayvanın karnına batırmış.) \r\n~2)giydirmek (pantolon vb. için)\r\n~Pantolî bi leze kerdî lajekî ro û rusna mekteb. (Çocuğa çabucak pantolon giydirip okula gönderdi.)\r\n~ \r\n~3)-e dökmek\r\n~\t\t"
  },
  {
    "x": "... ro şîyene",
    "y": " 1)batmak (sivri şeyin batması) \r\n~2)batmak (suya, çamura vb. batmak)\r\n~\tAwe ro şi. (Suya battı.)\r\n~\t\t\r\n~Linga mi vewre ro şî./Linga mi vewre de şî. (Ayağım kara battı.) \r\n~3)girmek"
  },
  {
    "x": "... ro vejîyayene",
    "y": " tırmanmak"
  },
  {
    "x": "... ro war kerdene",
    "y": " batırmak"
  },
  {
    "x": "... roalizîyayîş (n)",
    "y": " tırmanma"
  },
  {
    "x": "... roalizyayîş (n)",
    "y": " b. ... roalizîyayîş (n)"
  },
  {
    "x": "roayene",
    "y": " b. ruwayene"
  },
  {
    "x": "robar (n)",
    "y": " 1)ırmak, nehir; akarsu\r\n~2)yıkanma ve çamaşır yıkama zamanı, yunak"
  },
  {
    "x": "... robedilîyayîş (n)",
    "y": " tek kişiye karşı birkaç kişinin nöbetleşe bir şeyi yapması"
  },
  {
    "x": "robijîyayene",
    "y": " çözülmek"
  },
  {
    "x": "robijîyayîş (n)",
    "y": " çözülme"
  },
  {
    "x": "robijnayene",
    "y": " çözmek"
  },
  {
    "x": "robijnayîş (n)",
    "y": " çözme"
  },
  {
    "x": "robîyayene",
    "y": " b. robîyene"
  },
  {
    "x": "robîyayîne",
    "y": " b. robîyayene"
  },
  {
    "x": "robîyayîş (n)",
    "y": " 1)inme\r\n~2)dökülme"
  },
  {
    "x": "robîyene",
    "y": " 1)inmek\r\n~2)dökülmek"
  },
  {
    "x": "robot (n)",
    "y": " robot"
  },
  {
    "x": "robotê mitbaxe (n)",
    "y": " mutfak robotu"
  },
  {
    "x": "roc (n)",
    "y": " b. roj (n)"
  },
  {
    "x": "roc bi roc",
    "y": " b. roje bi roje"
  },
  {
    "x": "roc ra roc",
    "y": " b. roje ra roje"
  },
  {
    "x": "rocêk",
    "y": " b. rojêk"
  },
  {
    "x": "rocîk",
    "y": " b. rojêk"
  },
  {
    "x": "roca (m)",
    "y": " b. roja (m), rojîye (m)"
  },
  {
    "x": "rocawon (n)",
    "y": " b. rojawan (n)"
  },
  {
    "x": "roce-I (m)",
    "y": " b. roje-I (m)"
  },
  {
    "x": "roce be roce",
    "y": " b. roje bi roje"
  },
  {
    "x": "roce-II (n)",
    "y": " b. roje-II (n) "
  },
  {
    "x": "roce bîyayene",
    "y": " b. roje bîyene"
  },
  {
    "x": "... rocenayîş",
    "y": " batırma"
  },
  {
    "x": "rochelat (n)",
    "y": " b. rojhelat (n)"
  },
  {
    "x": "roci (m)",
    "y": " b. roje-I (m)"
  },
  {
    "x": "roci (n)",
    "y": " b. roje-II (n)"
  },
  {
    "x": "rocifetelîyayîş (n)",
    "y": " b. rocifetilîyayîş (n)"
  },
  {
    "x": "rocifetilîyayîş (n)",
    "y": " arama"
  },
  {
    "x": "rocigeyrayîş (n)",
    "y": " b. rocigêrayîş (n)"
  },
  {
    "x": "rocigêrayîş (n)",
    "y": " 1)arama \r\n~2)b. cigêrayîş (n)"
  },
  {
    "x": "rocikewtis (n)",
    "y": " b. ro ... kewtiş (n)"
  },
  {
    "x": "rocikewtiş (n)",
    "y": " b. ro ... kewtiş (n)"
  },
  {
    "x": "... rocinayîş",
    "y": " b. ... rocenayîş"
  },
  {
    "x": "rocîye (m)",
    "y": " b. roja (m), rojîye (m)"
  },
  {
    "x": "rodaj (n)",
    "y": " rodaj "
  },
  {
    "x": "rodaj kerdene",
    "y": " rodajlamak"
  },
  {
    "x": "rodajkerdiş (n)",
    "y": " rodajlama"
  },
  {
    "x": "rodaye (çîyo rodaye) (n)",
    "y": " sızıntı (sızan şey)"
  },
  {
    "x": "rodayeyo gemarker (n)",
    "y": " kirletici sızıntı"
  },
  {
    "x": "rodayene",
    "y": " sızdırmak (bez, kumaş vb. için)\r\n~Na çadire nêrodana. (Bu çadır (sıvı) sızdırmıyor.)"
  },
  {
    "x": "rodayeni",
    "y": " b. rodayene"
  },
  {
    "x": "rodayîş (n)",
    "y": " sızdırma (bez, kumaş vb. için) "
  },
  {
    "x": "rofle (n)",
    "y": " röfle"
  },
  {
    "x": "rogar (n)",
    "y": " rögar"
  },
  {
    "x": "rogarê talkerdişî (n)",
    "y": " tahliye rögarı"
  },
  {
    "x": "rogarê vengkerdişî (n)",
    "y": " tahliye rögarı"
  },
  {
    "x": "roj (n)",
    "y": " 1)güneş\r\n~Roj akewt. (Güneş doğdu.)\r\n~\t\r\n~Roj şi awan./Roj xow ra şi.(Güneş battı.) \r\n~2)gündüz \r\n~3)b. roje-I (m)"
  },
  {
    "x": "roj bi roj",
    "y": " b. roje bi roje"
  },
  {
    "x": "roj ra roj",
    "y": " b. roje ra roje"
  },
  {
    "x": "rojê newrozî (n)",
    "y": " b. roja newroze (m)"
  },
  {
    "x": "rojo bîn (n)",
    "y": " b. roja bîne (m)"
  },
  {
    "x": "roja (m)",
    "y": " oruçlu (kız veya kadın)"
  },
  {
    "x": "rojane (n)",
    "y": " günce, günlük"
  },
  {
    "x": "rojawan (n)",
    "y": " batı, garp"
  },
  {
    "x": "rojawanij, -e",
    "y": " Batılı, Garplı"
  },
  {
    "x": "rojawanij bîyene",
    "y": " Batılılaşmak"
  },
  {
    "x": "rojawanijbîyayîş (n)",
    "y": " Batılılaşma"
  },
  {
    "x": "rojawanperwer, -e",
    "y": " Batıcı, Garpçı"
  },
  {
    "x": "rojawanperwerîye (m)",
    "y": " Batıcılık, Garpçılık"
  },
  {
    "x": "rojawanwaz, -e",
    "y": " Batıcı, Garpçı"
  },
  {
    "x": "rojawanwazîye (m)",
    "y": " Batıcılık, Garpçılık"
  },
  {
    "x": "rojawon (n)",
    "y": " b. rojawan (n)"
  },
  {
    "x": "rojbaş",
    "y": " günaydın\r\n~Rojbaş. (İyi günler.)"
  },
  {
    "x": "rojdehîre",
    "y": " b. rojdihîre"
  },
  {
    "x": "rojdeyîr",
    "y": " b. rojdihîre"
  },
  {
    "x": "rojdihîre",
    "y": " güpegündüz, dalgündüz"
  },
  {
    "x": "rojdihîri",
    "y": " b. rojdihîre"
  },
  {
    "x": "roje-I (m)",
    "y": " gün"
  },
  {
    "x": "roja aşûreyî (m)",
    "y": " aşure günü"
  },
  {
    "x": "roja bîne (m)",
    "y": " ertesi gün"
  },
  {
    "x": "roja cilan (m)",
    "y": " çamaşır günü"
  },
  {
    "x": "roja çinayan (m)",
    "y": " çamaşır günü"
  },
  {
    "x": "roja germîya îmaman (m)",
    "y": " aşure günü"
  },
  {
    "x": "roja karî (m)",
    "y": " iş günü"
  },
  {
    "x": "roja kincan (m)",
    "y": " çamaşır günü"
  },
  {
    "x": "roja mayaxorabîyayîşê Îsayî (m)",
    "y": " milat, İsa’nın doğduğu gün"
  },
  {
    "x": "roja newroze (m)",
    "y": " nevruz günü"
  },
  {
    "x": "roja newrozî (m)",
    "y": " b. roja newroze (m)"
  },
  {
    "x": "roja pirênan (m)",
    "y": " çamaşır günü"
  },
  {
    "x": "roja xebate (m)",
    "y": " iş günü"
  },
  {
    "x": "roje bi roje",
    "y": " 1)günü gününe\r\n~2)günden güne"
  },
  {
    "x": "roje ra roje",
    "y": " günden güne"
  },
  {
    "x": "roje ve roje",
    "y": " b. roje bi roje"
  },
  {
    "x": "rojê",
    "y": " bir gün"
  },
  {
    "x": "rojê fîlman",
    "y": " film günleri"
  },
  {
    "x": "Rojê Fîlmanê Belgeyîyan yê Dîyarbekirî",
    "y": " Diyarbakır Belgesel Film Günleri"
  },
  {
    "x": "Rojê Fîlmanê Dokumanteran yê Dîyarbekirî",
    "y": " Diyarbakır Belgesel Film Günleri"
  },
  {
    "x": "rojê sînema (zh, sn)",
    "y": " sinema günleri"
  },
  {
    "x": "Rojê Sînema yê Dîyarbekirî (zh, sn)",
    "y": " Diyarbakır Sinema Günleri"
  },
  {
    "x": "rojêk",
    "y": " bir gün"
  },
  {
    "x": "rojî",
    "y": " b. rojê"
  },
  {
    "x": "roje-II (n)",
    "y": " 1)oruç\r\n~2)oruçlu (erkek)"
  },
  {
    "x": "roje bîyene",
    "y": " oruçlu olmak"
  },
  {
    "x": "roje girewtene",
    "y": " oruç tutmak"
  },
  {
    "x": "roje girowtene",
    "y": " b. roje girewtene"
  },
  {
    "x": "roje şikitene",
    "y": " oruç bozmak"
  },
  {
    "x": "roje tebîştene",
    "y": " b. roje tepîştene"
  },
  {
    "x": "roje tepiştene",
    "y": " b. roje tepîştene"
  },
  {
    "x": "roje tepîştene",
    "y": " oruç tutmak"
  },
  {
    "x": "roje tewîştene",
    "y": " b. roje tepîştene"
  },
  {
    "x": "rojeve (m)",
    "y": " gündem"
  },
  {
    "x": "rojhawûn (n)",
    "y": " b. rojawan (n)"
  },
  {
    "x": "rojhelat (n)",
    "y": " doğu, şark"
  },
  {
    "x": "Rojhelato Mîyanên (n)",
    "y": " Ortadoğu\r\n~Rojhelato Mîyanên, cayê petrolî yo. (Ortadoğu petrol yeridir.)\r\n~\t\r\n~Şarê Rojhelatê Mîyanênî ceng nêwazenê. (Ortadoğu halkları savaş istemiyorlar.)"
  },
  {
    "x": "rojî (n)",
    "y": " b. roje-II (n) "
  },
  {
    "x": "rojîye (m)",
    "y": " oruçlu"
  },
  {
    "x": "rojname (n)",
    "y": " gazete"
  },
  {
    "x": "rojnameyê xeripîyayeyî (zh)",
    "y": " fersude"
  },
  {
    "x": "rojnameyo tabloîd (n)",
    "y": " tabloid gazete"
  },
  {
    "x": "rojnameger, -e",
    "y": " gazeteci"
  },
  {
    "x": "rojnamenus, -e",
    "y": " gazeteci"
  },
  {
    "x": "rojnameroş, -e",
    "y": " gazeteci"
  },
  {
    "x": "rojnuşte (n)",
    "y": " günce"
  },
  {
    "x": "rojteştare",
    "y": " güpegündüz, dalgündüz"
  },
  {
    "x": "roka (n)",
    "y": " roka"
  },
  {
    "x": "rokerde (n)",
    "y": " 1)döküntü\r\n~2)döküm"
  },
  {
    "x": "rokerdene",
    "y": " dökmek"
  },
  {
    "x": "rokerdiş (n)",
    "y": " dökme"
  },
  {
    "x": "... rokerdiş",
    "y": " 1)batırma\r\n~2)giydirme (pantolon vb. için)"
  },
  {
    "x": "rokerdox, -e",
    "y": " dökümcü, dökmeci"
  },
  {
    "x": "rokerdoxênî (m)",
    "y": " dökümcü, dökmecilik"
  },
  {
    "x": "rokerdoxî (m)",
    "y": " dökümcü, dökmecilik"
  },
  {
    "x": "rokerdoxîye (m)",
    "y": " dökümcü, dökmecilik"
  },
  {
    "x": "rokewtene",
    "y": " çökmek (at, inek vb. hayvanlar için), hayvanların gündüz yere çöküp oturması"
  },
  {
    "x": "rokewtiş-I (n)",
    "y": " çökme (at, inek vb. hayvanlar için)"
  },
  {
    "x": "rokewtiş-II (n)",
    "y": " yakışma"
  },
  {
    "x": "rol (n)",
    "y": " rol"
  },
  {
    "x": "rolatîf, -e",
    "y": " göreceli"
  },
  {
    "x": "rolove (n)",
    "y": " rölöve"
  },
  {
    "x": "rolyef (n)",
    "y": " rölyef, kabartma"
  },
  {
    "x": "rom, -e",
    "y": " eşkıya"
  },
  {
    "x": "Roma (m)",
    "y": " Roma"
  },
  {
    "x": "roman",
    "y": " roman (n)"
  },
  {
    "x": "romankî",
    "y": " romansal "
  },
  {
    "x": "romannuştox, -e",
    "y": " romancı "
  },
  {
    "x": "romantîk, -e",
    "y": " romantik "
  },
  {
    "x": "romantîzm (n)",
    "y": " romantizm"
  },
  {
    "x": "Romanya (m)",
    "y": " Romanya"
  },
  {
    "x": "romatîzma (n)",
    "y": " romatizma"
  },
  {
    "x": "romatîzmayin –e",
    "y": " romatizmalı"
  },
  {
    "x": "romatîzmayin bîyene",
    "y": " romatizmalı olmak"
  },
  {
    "x": "romirênayene",
    "y": " söndürmek"
  },
  {
    "x": "romirênayîş (n)",
    "y": " söndürme"
  },
  {
    "x": "romite (n)",
    "y": " b. ramite (n)"
  },
  {
    "x": "romitene",
    "y": " b. ramitene"
  },
  {
    "x": "romitiş (n)",
    "y": " b. ramitiş (n)"
  },
  {
    "x": "romitox, -i",
    "y": " b. ramitox, -e"
  },
  {
    "x": "romituex",
    "y": " b. ramitox, -e"
  },
  {
    "x": "romîyayene",
    "y": " b. ramîyayene"
  },
  {
    "x": "romîyayîne",
    "y": " b. ramîyayene"
  },
  {
    "x": "romork (n)",
    "y": " römork"
  },
  {
    "x": "ron (n)",
    "y": " b. rûn (n)"
  },
  {
    "x": "ronê zeytinan (n)",
    "y": " b. rûnê zeytunan (n)"
  },
  {
    "x": "rono kele (n)",
    "y": " b. rûno kele (n)"
  },
  {
    "x": "ronayene",
    "y": " 1)koymak, indirmek, yere bırakmak\r\n~2)dikmek (ağaç vb. için)\r\n~3)kurmak (parti, dernek, şirket, ortaklık vb. için)"
  },
  {
    "x": "ronayêne",
    "y": " b. ronayene"
  },
  {
    "x": "ronayêni",
    "y": " b. ronayene"
  },
  {
    "x": "ronayîs (n)",
    "y": " b. ronayîş (n)"
  },
  {
    "x": "ronayîş (n)",
    "y": " 1)koyma, indirme, yere bırakma\r\n~2)dikme (ağaç vb. için)"
  },
  {
    "x": "ronayox, -e",
    "y": " kurucu"
  },
  {
    "x": "ronên, -e",
    "y": " b. rûnin, -e"
  },
  {
    "x": "ronik (n)",
    "y": " sofra bezi"
  },
  {
    "x": "ronikê werdî (n)",
    "y": " sofra bezi"
  },
  {
    "x": "ronin, -e",
    "y": " b. rûnin, -e"
  },
  {
    "x": "ronistiş (n)",
    "y": " b. roniştiş (n)"
  },
  {
    "x": "ronistene",
    "y": " b. roniştene"
  },
  {
    "x": "roniştene",
    "y": " 1)oturmak\r\n~2)yerleşmek (bir yerde oturmak)"
  },
  {
    "x": "roniştiş (n)",
    "y": " 1)oturma\r\n~2)oturum, celse\r\n~3)yerleşme (bir yerde oturma), yerleşim (bir yerde oturma)"
  },
  {
    "x": "roniştox, -e",
    "y": " sakin"
  },
  {
    "x": "roniştoxê/a bîna",
    "y": " bina sakini"
  },
  {
    "x": "roniştoxê/a taxe",
    "y": " mahalle sakini"
  },
  {
    "x": "ronîstene",
    "y": " b. roniştene"
  },
  {
    "x": "ronîstiş (n)",
    "y": " b. roniştiş (n)"
  },
  {
    "x": "ronîştene",
    "y": " b. roniştene"
  },
  {
    "x": "ronîştiş (n)",
    "y": " b. roniştiş (n)"
  },
  {
    "x": "ronîştîş (n)",
    "y": " b. roniştiş (n)"
  },
  {
    "x": "roper (n)",
    "y": " röper"
  },
  {
    "x": "ropirojîyayene",
    "y": " b. ropirozîyayene"
  },
  {
    "x": "ropirojîyayêne",
    "y": " b. ropirozîyayene"
  },
  {
    "x": "ropirojîyayîş (n)",
    "y": " b. ropirozîyayîş (n)"
  },
  {
    "x": "ropirojnayene",
    "y": " b. ropiroznayene"
  },
  {
    "x": "ropirojnayîş (n)",
    "y": " b. ropiroznayîş (n)"
  },
  {
    "x": "ropirozîyayene",
    "y": " süzülmek (sıvı için)"
  },
  {
    "x": "ropirozîyayêne",
    "y": " b. ropirozîyayene"
  },
  {
    "x": "ropirozîyayîş (n)",
    "y": " süzülme (sıvı için)"
  },
  {
    "x": "ropiroznayene",
    "y": " süzmek (sıvı için)"
  },
  {
    "x": "ropiroznayêne",
    "y": " b. ropiroznayene"
  },
  {
    "x": "ropiroznayîş (n)",
    "y": " süzme (sıvı için)"
  },
  {
    "x": "ropiziknayene",
    "y": " budamak (ağaç için)"
  },
  {
    "x": "ropiziknayîş (n)",
    "y": " budama (ağaç için)"
  },
  {
    "x": "roportaj (n)",
    "y": " röportaj"
  },
  {
    "x": "roportajkerdox, -e",
    "y": " röportajcı"
  },
  {
    "x": "roqila (m)",
    "y": " bağırsak"
  },
  {
    "x": "roqilaya barîye (m)",
    "y": " incebağırsak"
  },
  {
    "x": "roqilaya girde (m)",
    "y": " kalınbağırsak"
  },
  {
    "x": "roqilaya kore (m)",
    "y": " körbağırsak"
  },
  {
    "x": "roqilaya nakî (m)",
    "y": " göbek bağı, göbek kordonu"
  },
  {
    "x": "roqilaya qine (n)",
    "y": " göden, rektum "
  },
  {
    "x": "roqileyê dekerdeyî (zh)",
    "y": " bumbar"
  },
  {
    "x": "roqla (m)",
    "y": " b. roqila (m) "
  },
  {
    "x": "rosn (n)",
    "y": " b. roşn (n)"
  },
  {
    "x": "rost (n)",
    "y": " b. roşt (n)"
  },
  {
    "x": "roste (m)",
    "y": " b. roştî (m)"
  },
  {
    "x": "rostî (m)",
    "y": " b. roştî (m)"
  },
  {
    "x": "rosto (n)",
    "y": " rosto "
  },
  {
    "x": "-roş, -e (sf)",
    "y": " satıcı"
  },
  {
    "x": "roşan (n)",
    "y": " bayram\r\n~Roşanê to pîroz/fîraz/bimbarek bo. (Bayramın kutlu olsun.)"
  },
  {
    "x": "roşanayene",
    "y": " 1)silkelemek (meyve ağacı vb. için)\r\n~2)ürün toplamak (dut, badem vb. için)"
  },
  {
    "x": "roşanayîş (n)",
    "y": " 1)silkeleme (meyve ağacı vb. için)\r\n~2)ürün toplama (dut, badem vb. için)"
  },
  {
    "x": "roşaynayîne",
    "y": " b. roşanayene"
  },
  {
    "x": "roşaynayîş (n)",
    "y": " b. roşanayîş (n)"
  },
  {
    "x": "roşin (n)",
    "y": " b. roşn (n)"
  },
  {
    "x": "... roşîyayîş",
    "y": " 1)batma (sivri şeyin batması)\r\n~2)batma (suya, çamura vb. batma)\r\n~3)girme"
  },
  {
    "x": "roşn (n)",
    "y": " ışık, nur; aydın, aydınlık"
  },
  {
    "x": "roşn bîyene",
    "y": " aydınlanmak\r\n~Çimê to roşn bî./Çimê to roşt bî. (Gözün aydın.)"
  },
  {
    "x": "roşn kerdene",
    "y": " belirtmek"
  },
  {
    "x": "roşnê aşme (n)",
    "y": " ay ışığı, mehtap"
  },
  {
    "x": "roşno germin (n, sn)",
    "y": " sıcak ışık"
  },
  {
    "x": "roşno peykî (n, sn)",
    "y": " ters ışık"
  },
  {
    "x": "roşno serdin (n, sn)",
    "y": " soğuk ışık"
  },
  {
    "x": "roşna (m)",
    "y": " ışık, nur"
  },
  {
    "x": "roşna çimî (m)",
    "y": " b. roşnaya çimî (m)"
  },
  {
    "x": "roşnay çimî (m)",
    "y": " b. roşnaya çimî (m)"
  },
  {
    "x": "roşnaya çimî (m)",
    "y": " göz nuru"
  },
  {
    "x": "roşnaya derge (m)",
    "y": " uzun far"
  },
  {
    "x": "roşnaya keske (m)",
    "y": " yeşil ışık"
  },
  {
    "x": "roşnaya kilme (m)",
    "y": " kısa far"
  },
  {
    "x": "roşnaya mijî (m)",
    "y": " sis farı"
  },
  {
    "x": "roşnaya sûre (m)",
    "y": " kırmızı ışık"
  },
  {
    "x": "roşnaya trafîkî (m)",
    "y": " trafik ışığı"
  },
  {
    "x": "roşnaya zerde (m)",
    "y": " sarı ışık"
  },
  {
    "x": "roşnapeym (n)",
    "y": " fotometre, ışıkölçer"
  },
  {
    "x": "roşnayî (m)",
    "y": " aydın, aydınlık"
  },
  {
    "x": "roşnbîyayîş (n)",
    "y": " aydınlanma"
  },
  {
    "x": "roşney (m)",
    "y": " b. roşnîye (m)"
  },
  {
    "x": "roşnî (m)",
    "y": " aydın, aydınlık"
  },
  {
    "x": "roşnîye (m)",
    "y": " aydın, aydınlık"
  },
  {
    "x": "roşnkar, -e",
    "y": " ışıkçı"
  },
  {
    "x": "roşnker, -e",
    "y": " aydınlatıcı"
  },
  {
    "x": "roşnkerdiş (n)",
    "y": " belirtme"
  },
  {
    "x": "roşnvîr, -e",
    "y": " aydın, münevver "
  },
  {
    "x": "roşon (n)",
    "y": " b. roşan (n)"
  },
  {
    "x": "roşt (n)",
    "y": " aydın, aydınlık; ışık, nur"
  },
  {
    "x": "roşt bîyene",
    "y": " aydınlanmak"
  },
  {
    "x": "roşt kerdene",
    "y": " belirtmek"
  },
  {
    "x": "roştbîyayîş (n)",
    "y": " aydınlanma"
  },
  {
    "x": "roşte (m)",
    "y": " b. roştî (m)"
  },
  {
    "x": "roştênî (m)",
    "y": " aydın, aydınlık"
  },
  {
    "x": "roştî (m)",
    "y": " ışık, nur"
  },
  {
    "x": "roştîya aşme (m)",
    "y": " ay ışığı, mehtap"
  },
  {
    "x": "roştîya çimî (m)",
    "y": " göz nuru"
  },
  {
    "x": "roştîya derge (m)",
    "y": " uzun far"
  },
  {
    "x": "roştîya keske (m)",
    "y": " yeşil ışık"
  },
  {
    "x": "roştîya kilme (m)",
    "y": " kısa far"
  },
  {
    "x": "roştîya mijî (m)",
    "y": " sis farı"
  },
  {
    "x": "roştîya sûre (m)",
    "y": " kırmızı ışık"
  },
  {
    "x": "roştîya trafîkî (m)",
    "y": " trafik ışığı"
  },
  {
    "x": "roştîya zerde (m)",
    "y": " sarı ışık"
  },
  {
    "x": "roştîne (m)",
    "y": " roştênî (m)"
  },
  {
    "x": "roştîpeym (n)",
    "y": " fotometre, ışıkölçer"
  },
  {
    "x": "roştîye (m)",
    "y": " aydın, aydınlık"
  },
  {
    "x": "roştker, -e",
    "y": " aydınlatıcı"
  },
  {
    "x": "roştkerdiş (n)",
    "y": " belirtme"
  },
  {
    "x": "roşûn (n)",
    "y": " b. roşan (n)"
  },
  {
    "x": "rot (m)",
    "y": " b. rewte (m)"
  },
  {
    "x": "rota (m)",
    "y": " rota"
  },
  {
    "x": "rotardasyono mental (n)",
    "y": " zekâ geriliği, mental retardasyon"
  },
  {
    "x": "rotasyon (n)",
    "y": " rotasyon"
  },
  {
    "x": "rotasyonê karî (n)",
    "y": " iş rotasyonu"
  },
  {
    "x": "rote (m)",
    "y": " b. rewte (m)"
  },
  {
    "x": "rotelnayene",
    "y": " b. roternayene"
  },
  {
    "x": "rotene",
    "y": " satmak"
  },
  {
    "x": "roternayene",
    "y": " budamak (ağaç için)"
  },
  {
    "x": "roternayîş (n)",
    "y": " budama (ağaç için)"
  },
  {
    "x": "rotik (m)",
    "y": " b. rewtike (m)"
  },
  {
    "x": "rotiş (n)",
    "y": " satış, satma, satım"
  },
  {
    "x": "rotişî rê erzbîyayîş (n)",
    "y": " satışa sunulma"
  },
  {
    "x": "rotişî rê pêşkêşbîyayîş (n)",
    "y": " satışa sunulma"
  },
  {
    "x": "rotox, -e",
    "y": " satıcı"
  },
  {
    "x": "rotoxa gêroke (m)",
    "y": " seyyar satıcı"
  },
  {
    "x": "rotoxa seyare (m)",
    "y": " seyyar satıcı"
  },
  {
    "x": "rotoxo gêrok (n)",
    "y": " seyyar satıcı"
  },
  {
    "x": "rotoxo seyar (n)",
    "y": " seyyar satıcı"
  },
  {
    "x": "roval (n) ",
    "y": " b. rihal (n)"
  },
  {
    "x": "rovalîye (m)",
    "y": " b. rihalîye (m)"
  },
  {
    "x": "rovar (n)",
    "y": " b. robar (n)"
  },
  {
    "x": "... rovejîyayîş (n)",
    "y": " tırmanma"
  },
  {
    "x": "roverdike (m)",
    "y": " ceviz vb. yuvarlanabilen şeyleri eğimli bir yerden bırakıp birbirine değdirmeye çalışmak suretiyle oynanan bir oyun"
  },
  {
    "x": "roverdiki (m)",
    "y": " b. roverdike (m) "
  },
  {
    "x": "roverdo (n)",
    "y": " ceviz vb. yuvarlanabilen şeyleri eğimli bir yerden bırakıp birbirine değdirmeye çalışmak suretiyle oynanan bir oyun"
  },
  {
    "x": "rovilêsnayene",
    "y": " b. rovilêşnayene"
  },
  {
    "x": "rovilêsnayîş (n)",
    "y": " b. rovilêşnayîş (n)"
  },
  {
    "x": "rovilêşîyayene",
    "y": " 1)erimek\r\n~2)çözülmek (sert şeyler için)"
  },
  {
    "x": "rovilêşîyayêne",
    "y": " b. rovilêşîyayene"
  },
  {
    "x": "rovilêşîyayîş (n)",
    "y": " 1)erime\r\n~2)çözülme (sert şeyler için)"
  },
  {
    "x": "rovilêşnayene",
    "y": " 1)eritmek\r\n~2)çözmek (sert şeyler için"
  },
  {
    "x": "rovilêşnayîş (n)",
    "y": " 1)eritme\r\n~2)çözme"
  },
  {
    "x": "rovitene",
    "y": " elemek"
  },
  {
    "x": "rovitiş (n)",
    "y": " eleme"
  },
  {
    "x": "rovîtene",
    "y": " b. rovitene"
  },
  {
    "x": "row",
    "y": " b. rew"
  },
  {
    "x": "row ra",
    "y": " b. rew ra"
  },
  {
    "x": "rowaene",
    "y": " b. ruwayene"
  },
  {
    "x": "rowalê ... ra",
    "y": " b. rîwalê ... ra"
  },
  {
    "x": "rowar (n)",
    "y": " b. robar (n)"
  },
  {
    "x": "... rowarkerdiş (n)",
    "y": " batırma"
  },
  {
    "x": "rowayene",
    "y": " b. ruwayene"
  },
  {
    "x": "rowayêne",
    "y": " b. ruwayene"
  },
  {
    "x": "rowayîş (n)",
    "y": " b. ruwayîş (n)"
  },
  {
    "x": "roweşa ... şîyayîş",
    "y": " beğenme"
  },
  {
    "x": "rowtî (m)",
    "y": " b. rewte (m)"
  },
  {
    "x": "rowualê ... ra",
    "y": " b. rîwalê ... ra"
  },
  {
    "x": "roxoameyîş (n)",
    "y": " iyileşme, sağalma"
  },
  {
    "x": "roxonayîş (n)",
    "y": " itiraf"
  },
  {
    "x": "roy",
    "y": " b. rew"
  },
  {
    "x": "roy ra",
    "y": " b. rew ra"
  },
  {
    "x": "royîverdebîyayîş (n)",
    "y": " b. ruhîverdebîyayîş (n)"
  },
  {
    "x": "royna",
    "y": " b. rewna"
  },
  {
    "x": "roz (n)",
    "y": " b. roj (n)"
  },
  {
    "x": "roz û sewe",
    "y": " b. şew û roj"
  },
  {
    "x": "roze-I (m)",
    "y": " b. roje-I (m)"
  },
  {
    "x": "roza aşûrî (m)",
    "y": " b. roja aşûreyî (m)"
  },
  {
    "x": "roza germîya îmamû (m)",
    "y": " b. roja germîya îmaman (m)"
  },
  {
    "x": "roze ra ve roze",
    "y": " b. roje ra roje"
  },
  {
    "x": "roze ve roze",
    "y": " b. roje bi roje"
  },
  {
    "x": "roze-II (n)",
    "y": " b. roje-II (n)"
  },
  {
    "x": "roze bîyayene",
    "y": " b. roje bîyene"
  },
  {
    "x": "roze guretene",
    "y": " b. roje girewtene"
  },
  {
    "x": "roze şikitene",
    "y": " b. roje şikitene"
  },
  {
    "x": "rozi (m)",
    "y": " b. roje-I (m)"
  },
  {
    "x": "rozname (n)",
    "y": " b. rojname (n)"
  },
  {
    "x": "RTU (Ramote Terminal Unit)",
    "y": " RTU (Ramote Terminal Ünite)"
  },
  {
    "x": "ru-I (n)",
    "y": " b. rî-I (n)"
  },
  {
    "x": "ru-II (n)",
    "y": " b. ruh (n)"
  },
  {
    "x": "ruac (m)",
    "y": " b. roje-I (m)"
  },
  {
    "x": "ruaj (n)",
    "y": " b. roj (n) "
  },
  {
    "x": "ruaniştiş (n)",
    "y": " b. roniştiş (n)"
  },
  {
    "x": "ruaşn (n)",
    "y": " b. roşn (n)"
  },
  {
    "x": "ruaşna (m)",
    "y": " b. roşna (m)"
  },
  {
    "x": "ruaşney çimî (m)",
    "y": " b. roşnaya çimî (m)"
  },
  {
    "x": "ruaşnê çimî (m)",
    "y": " b. roşnaya çimî (m)"
  },
  {
    "x": "ruaşnê (m)",
    "y": " b. roşnî (m)"
  },
  {
    "x": "ruaşûn (n)",
    "y": " b. roşan (n)"
  },
  {
    "x": "rubaî (m)",
    "y": " dörtlük, rübai"
  },
  {
    "x": "rubalê ... ra",
    "y": " b. rîwalê ... ra"
  },
  {
    "x": "rubês (n)",
    "y": " b. ribêse (m)"
  },
  {
    "x": "ruçiknayene",
    "y": " yolmak (tüy vb. için)"
  },
  {
    "x": "ruçiknayîş (n)",
    "y": " yolma (tüy vb. için)"
  },
  {
    "x": "ruçiksiz, -i",
    "y": " b. bêrûçik, -e"
  },
  {
    "x": "rue (n)",
    "y": " b. ro-I (n)"
  },
  {
    "x": "ruebîyayîş (n)",
    "y": " b. robîyayîş (n)"
  },
  {
    "x": "ruec-I (m)",
    "y": " b. roje-I (m) "
  },
  {
    "x": "ruec ib ruec",
    "y": " b. roje bi roje"
  },
  {
    "x": "ruec ra ruec",
    "y": " b. roje ra roje"
  },
  {
    "x": "ruec-II (n)",
    "y": " b. roj (n)"
  },
  {
    "x": "rueca (m)",
    "y": " b. roja (m), rojîye (m)"
  },
  {
    "x": "ruecawûn (n)",
    "y": " b. rojawan (n)"
  },
  {
    "x": "ruece (n)",
    "y": " b. roje-II (n)"
  },
  {
    "x": "ruece bîyayene",
    "y": " b. roje bîyene"
  },
  {
    "x": "ruece girotene",
    "y": " b. roje girewtene"
  },
  {
    "x": "ruece şiktene",
    "y": " b. roje şikitene"
  },
  {
    "x": "ruechilat (n)",
    "y": " b. rojhelat (n)"
  },
  {
    "x": "rueci (n)",
    "y": " b. roje-II (n)"
  },
  {
    "x": "ruej (m)",
    "y": " b. roje-I (m)"
  },
  {
    "x": "ruej bi ruej",
    "y": " b. roje bi roje"
  },
  {
    "x": "ruej û ruej",
    "y": " b. roje bi roje"
  },
  {
    "x": "rueji (n)",
    "y": " b. roje-II (n)"
  },
  {
    "x": "rueji guretene",
    "y": " b. roje girewtene"
  },
  {
    "x": "rueji şiktene",
    "y": " b. roje şikitene"
  },
  {
    "x": "ruekerdiş (n)",
    "y": " b. rokerdiş (n)"
  },
  {
    "x": "ruekotene",
    "y": " b. rokewtene"
  },
  {
    "x": "ruenayîş (n)",
    "y": " b. ronayîş (n)"
  },
  {
    "x": "rueniştiş (n)",
    "y": " b. roniştiş (n)"
  },
  {
    "x": "ruenîştiş (n)",
    "y": " b. roniştiş (n)"
  },
  {
    "x": "rueşn (n)",
    "y": " b. roşn (n)"
  },
  {
    "x": "rueşna (m)",
    "y": " b. roşna (m)"
  },
  {
    "x": "rueşnê çimî (m)",
    "y": " b. roşnaya çimî (m)"
  },
  {
    "x": "rueşnayî (m)",
    "y": " b. roşnayî (m)"
  },
  {
    "x": "rueşney (m)",
    "y": " b. roşnîye (m)"
  },
  {
    "x": "rueşt (n)",
    "y": " b. roşt (n)"
  },
  {
    "x": "rueştey (m)",
    "y": " b. roştîye (m)"
  },
  {
    "x": "rueştê (m)",
    "y": " b. roştî (m)"
  },
  {
    "x": "rueştî (m)",
    "y": " b. roştî (m)"
  },
  {
    "x": "rueşûn (n)",
    "y": " b. roşan (n)"
  },
  {
    "x": "ruetiş (n)",
    "y": " b. rotiş (n)"
  },
  {
    "x": "rugan (n)",
    "y": " rugan"
  },
  {
    "x": "ruh (n)",
    "y": " ruh"
  },
  {
    "x": "ruhî ver de bîyene",
    "y": " can çekişmek"
  },
  {
    "x": "Ruha (m)",
    "y": " b. Riha (m)"
  },
  {
    "x": "ruhî, -ye",
    "y": " ruhsal"
  },
  {
    "x": "ruhîverdebîyayîş (n)",
    "y": " can çekişme"
  },
  {
    "x": "ruiniştiş (n)",
    "y": " b. roniştiş (n)"
  },
  {
    "x": "ruinîştiş (n)",
    "y": " b. roniştiş (n)"
  },
  {
    "x": "rulo (n)",
    "y": " rulo"
  },
  {
    "x": "rulo kerdene",
    "y": " rulo yapmak"
  },
  {
    "x": "runiştiş (n)",
    "y": " b. roniştiş (n)"
  },
  {
    "x": "rupel (n)",
    "y": " b. rîpel (n)"
  },
  {
    "x": "rusnayene",
    "y": " göndermek, yollamak"
  },
  {
    "x": "rusnayeni",
    "y": " b. rusnayene"
  },
  {
    "x": "rusnayîne",
    "y": " b. rusnayene"
  },
  {
    "x": "rusnayîş (n)",
    "y": " gönderme, yollama"
  },
  {
    "x": "ruşnayene",
    "y": " b. rusnayene"
  },
  {
    "x": "ruşnayeni",
    "y": " b. rusnayene"
  },
  {
    "x": "ruşnayîş (n)",
    "y": " b. rusnayîş (n)"
  },
  {
    "x": "ruşot (n)",
    "y": " b. rişwet (n)"
  },
  {
    "x": "ruşot dayene",
    "y": " b. rişwet dayene"
  },
  {
    "x": "ruşot werdene",
    "y": " b. rişwet werdene"
  },
  {
    "x": "ruşt (n)",
    "y": " b. roşt (n)"
  },
  {
    "x": "ruşwet (n)",
    "y": " b. rişwet (n)"
  },
  {
    "x": "ruşwet dayene",
    "y": " b. rişwet dayene"
  },
  {
    "x": "ruşwet werdene",
    "y": " b. rişwet werdene"
  },
  {
    "x": "rut, -e",
    "y": " 1)çıplak\r\n~2)(mecazi) yoksul, fakir"
  },
  {
    "x": "rut kerdene",
    "y": " soymak (giysisini çıkarmak, elbisesini çıkarmak)"
  },
  {
    "x": "rut-repal, -e",
    "y": " çırılçıplak, çırçıplak"
  },
  {
    "x": "rut-rupal, -e",
    "y": " b. rut-repal, -e"
  },
  {
    "x": "rutane (n)",
    "y": " ağaçsız alan"
  },
  {
    "x": "rutbet (n)",
    "y": " b. rutubet (n)"
  },
  {
    "x": "rutbetin, -i",
    "y": " b. rutubetin, -e"
  },
  {
    "x": "rutbetinê (m)",
    "y": " b. rutubetinîye (m)"
  },
  {
    "x": "rutene",
    "y": " süpürmek"
  },
  {
    "x": "ruti (m)",
    "y": " rute (m)"
  },
  {
    "x": "rutiş (n)",
    "y": " süpürme"
  },
  {
    "x": "rutkerdiş (n)",
    "y": " soyma (giysisini çıkarma)"
  },
  {
    "x": "rutubet (n)",
    "y": " nem, rutubet, gönen"
  },
  {
    "x": "rutubet girewtene",
    "y": " nemlenmek, rutubetlenmek"
  },
  {
    "x": "rutubetin, -e",
    "y": " nemli, rutubetli"
  },
  {
    "x": "rutubetiney (m)",
    "y": " b. rutubetinîye (m)"
  },
  {
    "x": "rutubetinîye (m)",
    "y": " nemlilik, rutubetlilik"
  },
  {
    "x": "rutûrepal, -e",
    "y": " çırılçıplak, çırçıplak"
  },
  {
    "x": "rutûrepel, -e",
    "y": " b. rutûrepal, -e"
  },
  {
    "x": "rutûrupal, -e",
    "y": " b. rutûrepal, -e"
  },
  {
    "x": "rutûrûpal, -e",
    "y": " b. rutûrepal, -e"
  },
  {
    "x": "rutvet (n)",
    "y": " b. rutubet (n)"
  },
  {
    "x": "rutvetin, -e",
    "y": " b. rutubetin, -e"
  },
  {
    "x": "rutvetinîye (m)",
    "y": " b. rutubetinîye (m)"
  },
  {
    "x": "ruvalê ... ra",
    "y": " b. rîwalê ... ra"
  },
  {
    "x": "ruwalê ... ra",
    "y": " b. rîwalê ... ra"
  },
  {
    "x": "ruwayene",
    "y": " bitmek (bitki vb. için)"
  },
  {
    "x": "ruwayîş (n)",
    "y": " bitme (bitki vb. için)"
  },
  {
    "x": "ruwe-I (n)",
    "y": " b. ro-I (n)"
  },
  {
    "x": "ruwe-II (n)",
    "y": " b. rûn (n)"
  },
  {
    "x": "ruwej (m)",
    "y": " b. roje-I (m)"
  },
  {
    "x": "ruwen (n)",
    "y": " b. rûn (n)"
  },
  {
    "x": "ruwenê zeytinan (n)",
    "y": " b. rûnê zeytunan (n)"
  },
  {
    "x": "ruweno kele (n)",
    "y": " b. rûno kele (n)"
  },
  {
    "x": "ruwenin, -i",
    "y": " b. rûnin, -e"
  },
  {
    "x": "ruweniştene",
    "y": " b. roniştene"
  },
  {
    "x": "ruweniştiş (n)",
    "y": " b. roniştiş (n)"
  },
  {
    "x": "ruweşn (n)",
    "y": " b. roşn (n)"
  },
  {
    "x": "ruxsat (n)",
    "y": " b. ruxset (n)"
  },
  {
    "x": "ruxset (n)",
    "y": " ruhsat"
  },
  {
    "x": "ruxsetê akerdişê cayê karî (n)",
    "y": " işyeri açma ruhsatı"
  },
  {
    "x": "ruxsetê awanî (n)",
    "y": " yapı ruhsatı"
  },
  {
    "x": "ruxsetê bîna (n)",
    "y": " yapı ruhsatı"
  },
  {
    "x": "ruxsetê rakerdişê cayê karî (n)",
    "y": " işyeri açma ruhsatı"
  },
  {
    "x": "ruxsetê rijnayîşî (n)",
    "y": " yıkma ruhsatı"
  },
  {
    "x": "ruxsetê weşanayîşî (n)",
    "y": " yıkma ruhsatı"
  },
  {
    "x": "ruxsetê xilnayîşî (n)",
    "y": " yıkma ruhsatı"
  },
  {
    "x": "rû-I (n)",
    "y": " b. rî (n)"
  },
  {
    "x": "rûyê xwe",
    "y": " b. rîyê xo"
  },
  {
    "x": "rû-II (n)",
    "y": " b. ro-I (n)"
  },
  {
    "x": "rû-III (vr)",
    "y": " b. ro-II (vr)"
  },
  {
    "x": "rû-IV (n)",
    "y": " b. rûn (n)"
  },
  {
    "x": "rû-V (n)",
    "y": " b. ruh (n)"
  },
  {
    "x": "rûyî ver de bîyene",
    "y": " b. ruhî ver de bîyene"
  },
  {
    "x": "rû-VI (pd)",
    "y": " b. ro-VII (pd)"
  },
  {
    "x": "rûbar (n)",
    "y": " b. robar (n)"
  },
  {
    "x": "rûbîyayene",
    "y": " b. robîyene"
  },
  {
    "x": "rûbîyayîş (n)",
    "y": " b. robîyayîş (n)"
  },
  {
    "x": "rûc (n)",
    "y": " b. roj (n)"
  },
  {
    "x": "rûçiknayene",
    "y": " b. ruçiknayene"
  },
  {
    "x": "rûçiknayîş (n)",
    "y": " b. ruçiknayîş (n)"
  },
  {
    "x": "rûh (n)",
    "y": " b. ruh (n)"
  },
  {
    "x": "rûj (n)",
    "y": " b. roj (n), roje-I (m)"
  },
  {
    "x": "rûje (n)",
    "y": " b. roje-II (n)"
  },
  {
    "x": "rûjhelat (n)",
    "y": " b. rojhelat (n)"
  },
  {
    "x": "rûkerdene",
    "y": " b. rokerdene"
  },
  {
    "x": "rûkerdiş (n)",
    "y": " b. rokerdiş (n)"
  },
  {
    "x": "rûmet (n)",
    "y": " değer, kıymet"
  },
  {
    "x": "rûmetdar, -e",
    "y": " saygılı, hürmetkâr, hürmetli"
  },
  {
    "x": "rûmetdarî (m)",
    "y": " saygılılık"
  },
  {
    "x": "rûmetdarîye (m)",
    "y": " saygılılık"
  },
  {
    "x": "rûmetin, -e",
    "y": " değerli, kıymetli"
  },
  {
    "x": "rûmite (n)",
    "y": " b. ramite (n)"
  },
  {
    "x": "rûmitene",
    "y": " b. ramitene"
  },
  {
    "x": "rûmiti (n)",
    "y": " b. ramite (n)"
  },
  {
    "x": "rûmitiş (n)",
    "y": " b. ramitiş (n)"
  },
  {
    "x": "rûmitox, -i",
    "y": " b. ramitox, -e"
  },
  {
    "x": "rûmîyayîne",
    "y": " b. ramîyayene"
  },
  {
    "x": "rûmtene",
    "y": " b. ramitene"
  },
  {
    "x": "rûmtiş (n)",
    "y": " b. ramitiş (n)"
  },
  {
    "x": "rûn (n)",
    "y": " yağ"
  },
  {
    "x": "rûnê makîna (n)",
    "y": " makine yağı"
  },
  {
    "x": "rûnê maseyan (n)",
    "y": " balık yağı"
  },
  {
    "x": "rûnê verrojî (n)",
    "y": " ayçiçeği yağı"
  },
  {
    "x": "rûnê verrojikî (n)",
    "y": " ayçiçeği yağı"
  },
  {
    "x": "rûnê zeytunan (n)",
    "y": " zeytinyağı"
  },
  {
    "x": "rûnê zêtunûn (n)",
    "y": " b. rûnê zeytunan (n)"
  },
  {
    "x": "rûnî zêtunû",
    "y": " b. rûnê zeytunan (n)"
  },
  {
    "x": "rûno eşte (n)",
    "y": " atık yağ"
  },
  {
    "x": "rûno kele (n)",
    "y": " tereyağı"
  },
  {
    "x": "rûno keli (n)",
    "y": " b. rûno kele (n)"
  },
  {
    "x": "rûnayîş (n)",
    "y": " b. ronayîş (n)"
  },
  {
    "x": "rûncîyaker (n)",
    "y": " yağ tutucu"
  },
  {
    "x": "rûnik (n)",
    "y": " b. ronik (n)"
  },
  {
    "x": "rûnikê werî (n)",
    "y": " b. ronikê werdî (n)"
  },
  {
    "x": "rûnin, -e",
    "y": " yağlı"
  },
  {
    "x": "rûniştene",
    "y": " b. roniştene"
  },
  {
    "x": "rûniştiş (n)",
    "y": " b. roniştiş (n)"
  },
  {
    "x": "rûnîştene",
    "y": " b. roniştene"
  },
  {
    "x": "rûnîştiş (n)",
    "y": " b. roniştene"
  },
  {
    "x": "rûpele (m)",
    "y": " b. rîpel (n)"
  },
  {
    "x": "rûqal (n)",
    "y": " b. rîqal (n)"
  },
  {
    "x": "Rûsya (m)",
    "y": " Rusya"
  },
  {
    "x": "Rûsyaya Sipîye (m)",
    "y": " Beyaz Rusya "
  },
  {
    "x": "rûşn (n)",
    "y": " b. roşn (n)"
  },
  {
    "x": "rûşna (m)",
    "y": " b. roşna (m)"
  },
  {
    "x": "rûşna çimî (m)",
    "y": " b. roşnaya çimî (m)"
  },
  {
    "x": "rûşnayî (m)",
    "y": " b. roşnayî (m)"
  },
  {
    "x": "rûşney (m)",
    "y": " b. roşnîye (m)"
  },
  {
    "x": "rûşûn (n)",
    "y": " b. roşan (n)"
  },
  {
    "x": "rût, -e",
    "y": " b. rut, -e"
  },
  {
    "x": "rûtane (n)",
    "y": " b. rutane (n)"
  },
  {
    "x": "rûter (n) (TE)",
    "y": " yönlendirici "
  },
  {
    "x": "rûtirş, -e",
    "y": " b. rîtirş, -e"
  },
  {
    "x": "rûtkerdiş (n)",
    "y": " b. rutkerdiş (n)"
  },
  {
    "x": "rûtubet (n)",
    "y": " b. rutubet (n)"
  },
  {
    "x": "rûtubetin",
    "y": " b. rutubetin, -e"
  },
  {
    "x": "rûtûrepal, -e",
    "y": " b. rutûrepal, -e"
  },
  {
    "x": "rûtûrepel, -e",
    "y": " b. rutûrepal, -e"
  },
  {
    "x": "rûwen (n)",
    "y": " b. rûn (n)"
  },
  {
    "x": "rûwenin, -i",
    "y": " b. rûnin, -e"
  },
  {
    "x": "rûy (m)",
    "y": " b. roqilaya barîye (m)"
  },
  {
    "x": "rûyîverdebîyayîş (n)",
    "y": " b. ruhîverdebîyayîş (n)"
  },
  {
    "x": "Rwanda (m)",
    "y": " Ruanda"
  },
  {
    "x": "ryex (m)",
    "y": " b. rêxe (m)"
  },
  {
    "x": "SSS"
  },
  {
    "x": "sa-I (m)",
    "y": " b. saye-I"
  },
  {
    "x": "sa-II",
    "y": " b. se-II"
  },
  {
    "x": "sa, -ye-III (n)",
    "y": " b. şa, -ye-III"
  },
  {
    "x": "sa bîyayêne",
    "y": " b. şa bîyene"
  },
  {
    "x": "sa bîyene",
    "y": " b. şa bîyene"
  },
  {
    "x": "sa kerdene",
    "y": " b. şa kerdene"
  },
  {
    "x": "saad, -e",
    "y": " b. şahid, -e"
  },
  {
    "x": "saadênî (m)",
    "y": " b. şahidîye (m)"
  },
  {
    "x": "saate (m)",
    "y": " b. saete (m)"
  },
  {
    "x": "saati (m)",
    "y": " b. saete (m)"
  },
  {
    "x": "saba-I (m)",
    "y": " yeni doğan büyükbaş veya küçükbaş (hayvan)"
  },
  {
    "x": "saba-II",
    "y": " b. seba-I"
  },
  {
    "x": "sabane (m)",
    "y": " üzerine pestil bulamacı sürülerek pestil yapımı için kullanılan bez parçası "
  },
  {
    "x": "sabani (m)",
    "y": " b. sabane (m)"
  },
  {
    "x": "sabax (n)",
    "y": " b. siwax (n)"
  },
  {
    "x": "sabe (n)",
    "y": " yeni doğan büyükbaş veya küçükbaş (hayvan)"
  },
  {
    "x": "sabir (n)",
    "y": " b. sebir (n)"
  },
  {
    "x": "sabirteng, -e",
    "y": " b. sebirteng, -e"
  },
  {
    "x": "saboni (m)",
    "y": " b. sabane (m)"
  },
  {
    "x": "sabotaj (n)",
    "y": " sabotaj"
  },
  {
    "x": "sabotaj kerdene",
    "y": " sabotaj yapmak"
  },
  {
    "x": "sabote (n)",
    "y": " sabote"
  },
  {
    "x": "sabote kerdene",
    "y": " sabote etmek, baltalamak (mecazi)"
  },
  {
    "x": "saboteker, -e",
    "y": " sabotajcı"
  },
  {
    "x": "sabotekerdiş (n)",
    "y": " sabote etme, baltalama (mecazi)"
  },
  {
    "x": "sabun (n)",
    "y": " sabun"
  },
  {
    "x": "sabunê taştişî (n)",
    "y": " tıraş sabunu"
  },
  {
    "x": "sabunê terdişî (n)",
    "y": " tıraş sabunu"
  },
  {
    "x": "sabunê tiraşî (n)",
    "y": " tıraş sabunu"
  },
  {
    "x": "sabur (n)",
    "y": " keçiyi kırkarken her tarafını kırkma biçimi"
  },
  {
    "x": "sabur kerdene",
    "y": " keçiyi kırkarken her tarafını kırkmak"
  },
  {
    "x": "sabûn (n)",
    "y": " b. sabun (n)"
  },
  {
    "x": "sac-I (n)",
    "y": " sac"
  },
  {
    "x": "sac-II (m)",
    "y": " b. sacî (m)"
  },
  {
    "x": "sacî (m)",
    "y": " sac (“dışbükey pişirme aracı”)"
  },
  {
    "x": "sade, -ye",
    "y": " sade, basit, bayağı"
  },
  {
    "x": "sadênî (m)",
    "y": " b. şahidîye (m)"
  },
  {
    "x": "sadir (n)",
    "y": " fide"
  },
  {
    "x": "sadir ronayene",
    "y": " fidelemek, fide dikmek"
  },
  {
    "x": "sadî (n)",
    "y": " b. sadir (n)"
  },
  {
    "x": "sadîr (n)",
    "y": " b. sadir (n)"
  },
  {
    "x": "saet (m)",
    "y": " b. saete-2 (m)"
  },
  {
    "x": "saet (n)",
    "y": " b. saete-1 (m)"
  },
  {
    "x": "saete (m)",
    "y": " 1)saat (60 dakika)\r\n~2)saat (aygıt)"
  },
  {
    "x": "saeta cêbe (m)",
    "y": " cep saati"
  },
  {
    "x": "saeta destan (m)",
    "y": " kol saati"
  },
  {
    "x": "saeta dêsî (m)",
    "y": " duvar saati"
  },
  {
    "x": "saeta dîwarî (m)",
    "y": " duvar saati"
  },
  {
    "x": "saeta masa (m)",
    "y": " masa saati"
  },
  {
    "x": "saeta tûnike (m)",
    "y": " cep saati"
  },
  {
    "x": "saeta zengdare (m)",
    "y": " çalar saat"
  },
  {
    "x": "saetê karkerdişî (zh)",
    "y": " çalışma saatleri"
  },
  {
    "x": "saetê xebate (zh)",
    "y": " çalışma saatleri"
  },
  {
    "x": "saetkar, -e",
    "y": " saatçi (saat yapan) "
  },
  {
    "x": "saetkarî (m)",
    "y": " saatçilik"
  },
  {
    "x": "saetkarîye (m)",
    "y": " saatçilik"
  },
  {
    "x": "saetroş, -e",
    "y": " saatçi (saat satan)"
  },
  {
    "x": "saetroşî (m)",
    "y": " saatçilik"
  },
  {
    "x": "saetroşîye (m)",
    "y": " saatçilik"
  },
  {
    "x": "saetsaz, -e",
    "y": " saat tamircisi, saatçi (saat onaran) "
  },
  {
    "x": "saetsazî (m)",
    "y": " saatçilik"
  },
  {
    "x": "saetsazîye (m)",
    "y": " saatçilik"
  },
  {
    "x": "saf-I",
    "y": " salt, sırf, sadece"
  },
  {
    "x": "saf, -e-II",
    "y": " saf (kişi için)"
  },
  {
    "x": "safek, -e",
    "y": " saf (kişi için)"
  },
  {
    "x": "safekey (m)",
    "y": " b. safekîye (m)"
  },
  {
    "x": "safekê (m)",
    "y": " b. safekî (m)"
  },
  {
    "x": "safeki (m)",
    "y": " b. safek, -e"
  },
  {
    "x": "safekî (m)",
    "y": " saflık (kişi için)"
  },
  {
    "x": "safekîye (m)",
    "y": " saflık (kişi için)"
  },
  {
    "x": "safey (m)",
    "y": " b. safîye (m)"
  },
  {
    "x": "safê (m)",
    "y": " b. safî (m)"
  },
  {
    "x": "safi (m)",
    "y": " b. saf, -e"
  },
  {
    "x": "safî-I",
    "y": " sadece, safi, yalnız, yalnızca\r\n~Ez safî semedê to ra ameya. (Sadece senin için geldim.)"
  },
  {
    "x": "safî kerdene",
    "y": " halletmek"
  },
  {
    "x": "safî-II (m)",
    "y": " saflık (kişi için)"
  },
  {
    "x": "safîn (n)",
    "y": " b. sefîne (m)"
  },
  {
    "x": "safîye (m)",
    "y": " saflık (kişi için)"
  },
  {
    "x": "sagirt, -e",
    "y": " b. şagird, -e"
  },
  {
    "x": "sagul (n)",
    "y": " b. şaqul (n)"
  },
  {
    "x": "sahan (n)",
    "y": " b. şahîn (n)"
  },
  {
    "x": "sahat (m)",
    "y": " b. saete (m) "
  },
  {
    "x": "sahate (m)",
    "y": " b. saete (m)"
  },
  {
    "x": "sahnayîne",
    "y": " b. sehnayene"
  },
  {
    "x": "sahnayîş (n)",
    "y": " b. sehnayîş (n)"
  },
  {
    "x": "saîq (n)",
    "y": " güdü"
  },
  {
    "x": "saîqê serîberdişî (n)",
    "y": " başarı güdüsü"
  },
  {
    "x": "saîqê serkewtişî (n)",
    "y": " başarı güdüsü"
  },
  {
    "x": "saîr, -e",
    "y": " b. şaîr, -e"
  },
  {
    "x": "saj-I (n)",
    "y": " b. sac-I (n)"
  },
  {
    "x": "saj-II (m)",
    "y": " b. sacî (m)"
  },
  {
    "x": "sajî (zh)",
    "y": " b. sazî (zh)"
  },
  {
    "x": "sak (n)",
    "y": " tapan, sürgü"
  },
  {
    "x": "sak antiş bure",
    "y": " (mecazi) uyumsuzlaşmak, laf dinlemez olmak, geçimsizleşmek"
  },
  {
    "x": "sak kerdene",
    "y": " tapan çekmek, tapanlamak"
  },
  {
    "x": "sakil (n)",
    "y": " b. zanqile (n)"
  },
  {
    "x": "sakin, -e",
    "y": " durgun, sakin"
  },
  {
    "x": "sakîl (n)",
    "y": " b. şakil (n)"
  },
  {
    "x": "sakîn, -e",
    "y": " b. sakin, -e"
  },
  {
    "x": "sakkerdiş (n)",
    "y": " tapanlama"
  },
  {
    "x": "sako (n)",
    "y": " b. saqo (n)"
  },
  {
    "x": "sakoke (m)",
    "y": " b. şekoke (m)"
  },
  {
    "x": "sakolî (n)",
    "y": " b. saqo (n)"
  },
  {
    "x": "saksî (m)",
    "y": " saksı"
  },
  {
    "x": "saksofon (n)",
    "y": " saksofon"
  },
  {
    "x": "saksofoncen, -e",
    "y": " saksofoncu"
  },
  {
    "x": "saku (n)",
    "y": " b. saqo (n)"
  },
  {
    "x": "sakul (n)",
    "y": " b. şaqul (n)"
  },
  {
    "x": "sakû (n)",
    "y": " b. saqo (n)"
  },
  {
    "x": "sal (m)",
    "y": " b. serre (m)"
  },
  {
    "x": "sal (zh)",
    "y": " b. şalî-I (zh)"
  },
  {
    "x": "sal û sapik (zh)",
    "y": " b. şal û şapikî (zh)"
  },
  {
    "x": "sal û sapikî (zh)",
    "y": " b. şal û şapikî (zh)"
  },
  {
    "x": "sal-sapik (zh)",
    "y": " b. şal û şapikî (zh)"
  },
  {
    "x": "sala",
    "y": " b. înşalah"
  },
  {
    "x": "salam (n)",
    "y": " salam "
  },
  {
    "x": "salata (n)",
    "y": " b. salate (n)"
  },
  {
    "x": "salate (n)",
    "y": " salata"
  },
  {
    "x": "salaw (n)",
    "y": " b. seref (n)"
  },
  {
    "x": "salça (m)",
    "y": " salça"
  },
  {
    "x": "salçaya bîberan (m)",
    "y": " biber salçası"
  },
  {
    "x": "salçaya firingîyan (m)",
    "y": " domates salçası"
  },
  {
    "x": "salçayê bîbera (n)",
    "y": " b. salçaya bîberan (m)"
  },
  {
    "x": "salçê bazilcûnû (m)",
    "y": " b. salçaya firingîyan (m)"
  },
  {
    "x": "salçe (n)",
    "y": " b. salça (m)"
  },
  {
    "x": "salçeyê balcanan (n)",
    "y": " b. salçaya firingîyan (m)"
  },
  {
    "x": "salçeyê domatêza (n)",
    "y": " b. salçaya firingîyan (m)"
  },
  {
    "x": "sale-I (m)",
    "y": " 1)iki tarafı düz ve geniş olup kaldırılabilir büyüklükte olan taş\r\n~2)b. sala destî (m)"
  },
  {
    "x": "sala destî (m)",
    "y": " aya"
  },
  {
    "x": "sale-II (m)",
    "y": " b. şale (m)"
  },
  {
    "x": "saleb (n)",
    "y": " salep"
  },
  {
    "x": "salebroş, -e",
    "y": " salepçi"
  },
  {
    "x": "salebroşî (m)",
    "y": " salepçilik"
  },
  {
    "x": "salebroşîye (m)",
    "y": " salepçilik"
  },
  {
    "x": "saleke (m)",
    "y": " köfte (ince yarmadan veya kıymadan yapılan)"
  },
  {
    "x": "salekî (m)",
    "y": " seksek [oyunu]"
  },
  {
    "x": "saleşîye (m)",
    "y": " b. şaneşîye (m)"
  },
  {
    "x": "salê",
    "y": " b. înşalah"
  },
  {
    "x": "sali-I (m)",
    "y": " b. sale-I (m)"
  },
  {
    "x": "sali-II (m)",
    "y": " b. şale (m)"
  },
  {
    "x": "salinc (m)",
    "y": " b. salince (m)"
  },
  {
    "x": "salince (m)",
    "y": " kara erik"
  },
  {
    "x": "salincêre (m)",
    "y": " kara erik ağacı"
  },
  {
    "x": "salincêri (m)",
    "y": " b. salincêre (m)"
  },
  {
    "x": "salinci (m)",
    "y": " b. salince (m)"
  },
  {
    "x": "salî (zh)",
    "y": " b. şalî-I (zh)"
  },
  {
    "x": "sal û sapikî (zh)",
    "y": " b. şal û şapikî (zh)"
  },
  {
    "x": "salma (m)",
    "y": " başıboş"
  },
  {
    "x": "salme-I (n)",
    "y": " 1)başıboş\r\n~2)otlaması için hayvanı araziye başıboş salma"
  },
  {
    "x": "salme geyrayene",
    "y": " b. salme gêrayene"
  },
  {
    "x": "salme gêrayene",
    "y": " başıboş dolaşmak, başıboş gezmek"
  },
  {
    "x": "salme veradayene",
    "y": " 1) başıboş bırakmak\r\n~2)otlaması için hayvanı araziye başıboş salmak"
  },
  {
    "x": "salmekî geyrayene",
    "y": " b. salmekî gêrayene"
  },
  {
    "x": "salmekî gêrayene",
    "y": " başıboş dolaşmak, başıboş gezmek"
  },
  {
    "x": "salmekî veradayene",
    "y": " otlaması için hayvanı araziye başıboş salmak"
  },
  {
    "x": "salme-II (n)",
    "y": " b. sarme (n)"
  },
  {
    "x": "salmekîveradayîş (n)",
    "y": " otlaması için hayvanı araziye başıboş salma"
  },
  {
    "x": "salmeveradayîş (n)",
    "y": " otlaması için hayvanı araziye başıboş salma"
  },
  {
    "x": "salmi (n)",
    "y": " b. salme-I (n)"
  },
  {
    "x": "salon (n)",
    "y": " b. salone (m)"
  },
  {
    "x": "salone (m)",
    "y": " salon, ayvan"
  },
  {
    "x": "salona kombîyayîşê meclîsî",
    "y": " meclis toplantı salonu"
  },
  {
    "x": "salona kombîyayîşî",
    "y": " toplantı salonu"
  },
  {
    "x": "salona konferansî",
    "y": " konferans salonu"
  },
  {
    "x": "salona sînema (m)",
    "y": " sinema salonu"
  },
  {
    "x": "salsnayîş (n)",
    "y": " b. şinasnayîş (n)"
  },
  {
    "x": "Salvador (n)",
    "y": " El Salvador "
  },
  {
    "x": "salvarî (zh)",
    "y": " b. şelwarî (zh)"
  },
  {
    "x": "salwar (zh)",
    "y": " b. şelwarî (zh)"
  },
  {
    "x": "sama (m)",
    "y": " b. şama (m)"
  },
  {
    "x": "samata (m)",
    "y": " b. şemate (n)"
  },
  {
    "x": "same (m)",
    "y": " b. şamî (m)"
  },
  {
    "x": "samêrî (m)",
    "y": " b. simîrî (m)"
  },
  {
    "x": "samî (m)",
    "y": " b. şamî (m)"
  },
  {
    "x": "samîmî, -ye",
    "y": " b. semîmî, -ye"
  },
  {
    "x": "samîmîyet (n)",
    "y": " b. semîmîyet (n)"
  },
  {
    "x": "samîr (m)",
    "y": " b. şûmîla (m)"
  },
  {
    "x": "samîre (m)",
    "y": " b. simîrî (m)"
  },
  {
    "x": "Samoaya Rojawanî (m)",
    "y": " Batı Samoa"
  },
  {
    "x": "samur, -e",
    "y": " samur"
  },
  {
    "x": "san-I (n)",
    "y": " b. şan (n)"
  },
  {
    "x": "san de",
    "y": " b. şan de"
  },
  {
    "x": "san-II (n)",
    "y": " b. şahîn (n)"
  },
  {
    "x": "San Jose (n)",
    "y": " San José"
  },
  {
    "x": "San Marîno (n)",
    "y": " San Marino"
  },
  {
    "x": "San Salvador (n)",
    "y": " San Salvador"
  },
  {
    "x": "sanaene",
    "y": " b. şanayene"
  },
  {
    "x": "sanais (n)",
    "y": " b. şanayîş (n)"
  },
  {
    "x": "sanayene-I",
    "y": " 1)b. Şanayene\r\n~2)b. ci eştene"
  },
  {
    "x": "sanayene-II",
    "y": " b. sehnayene"
  },
  {
    "x": "sanayîne-I",
    "y": " b. şanayene"
  },
  {
    "x": "sanayîne-II",
    "y": " b. sehnayene"
  },
  {
    "x": "sanayîs (n)",
    "y": " b. şanayîş (n)"
  },
  {
    "x": "sanayîş-I (n)",
    "y": " b. şanayîş (n)"
  },
  {
    "x": "sanayîş-II (n)",
    "y": " b. sehnayîş (n)"
  },
  {
    "x": "sanci (n)",
    "y": " b. sancî (n)"
  },
  {
    "x": "sanci kerdene",
    "y": " b. sancî kerdene"
  },
  {
    "x": "sancî (n)",
    "y": " sancı"
  },
  {
    "x": "sancî girewtene",
    "y": " sancısı tutmak"
  },
  {
    "x": "sancî kerdene",
    "y": " sancı vermek, sancımak"
  },
  {
    "x": "sancu (n)",
    "y": " b. sancî (n)"
  },
  {
    "x": "sancu kerdene",
    "y": " b. sancî kerdene"
  },
  {
    "x": "sancû (n)",
    "y": " b. sancî (n)"
  },
  {
    "x": "sancûle (m)",
    "y": " tasma (hayvan için)"
  },
  {
    "x": "sançûle (m)",
    "y": " b. sancûle (m)"
  },
  {
    "x": "sandalet (n)",
    "y": " sandalet, sandal"
  },
  {
    "x": "sandalî (n)",
    "y": " sandalye"
  },
  {
    "x": "sandiqe (m)",
    "y": " b. sindoqe (m)"
  },
  {
    "x": "sandiqi (m)",
    "y": " b. sindoqe (m)"
  },
  {
    "x": "sanduq (m)",
    "y": " b. sindoqe (m)"
  },
  {
    "x": "sanduqe (m)",
    "y": " b. sindoqe (m)"
  },
  {
    "x": "sandwîç (n)",
    "y": " sandviç"
  },
  {
    "x": "sane-I (n)",
    "y": " bileğitaşı"
  },
  {
    "x": "sane bîyayene",
    "y": " b. sane bîyene"
  },
  {
    "x": "sane bîyene",
    "y": " bilenmek"
  },
  {
    "x": "sane kerdene",
    "y": " bilemek"
  },
  {
    "x": "sane pira bîyayene",
    "y": " b. sane pira bîyene"
  },
  {
    "x": "sane pira bîyene",
    "y": " bilenmek"
  },
  {
    "x": "sane pira kerdene",
    "y": " bilemek"
  },
  {
    "x": "sane-II (n)",
    "y": " b. şane-I (n)"
  },
  {
    "x": "sanê morî (n)",
    "y": " b. şaneyê marî (n)"
  },
  {
    "x": "saneso (n)",
    "y": " kesici araçları bilemek için kullanılan yağ"
  },
  {
    "x": "saneşîye (m)",
    "y": " b. şaneşîye (m)"
  },
  {
    "x": "sangar (n)",
    "y": " içine üzüm vb. şeyler konan derin ve büyük sepet "
  },
  {
    "x": "sangola (m)",
    "y": " başıboş (genç için) "
  },
  {
    "x": "sangole (n)",
    "y": " başıboş (genç için)"
  },
  {
    "x": "sanike-I (m)",
    "y": " masal"
  },
  {
    "x": "sanike-II (m)",
    "y": " b. şanike-I (m)"
  },
  {
    "x": "saniki (m)",
    "y": " b. sanike-I (m)"
  },
  {
    "x": "sanikker, -e",
    "y": " masalcı (masal anlatan) "
  },
  {
    "x": "sanikkî",
    "y": " masalımsı"
  },
  {
    "x": "sanîya (m)",
    "y": " b. sanîye (m)"
  },
  {
    "x": "sanîye (m)",
    "y": " saniye"
  },
  {
    "x": "sanka",
    "y": " b. sankî"
  },
  {
    "x": "sankera",
    "y": " b. sankî"
  },
  {
    "x": "sankî",
    "y": " sanki"
  },
  {
    "x": "sankîla",
    "y": " b. sankî"
  },
  {
    "x": "sansasyon (n)",
    "y": " sansasyon"
  },
  {
    "x": "sansasyonel, -e",
    "y": " sansasyonel\r\n~rojnameyo sansasyonel (n): sansasyonel gazete"
  },
  {
    "x": "rojnameyo sansasyonel (n)",
    "y": " sansasyonel gazete"
  },
  {
    "x": "sansur (n)",
    "y": " sansür"
  },
  {
    "x": "sansurker, -e",
    "y": " sansürcü"
  },
  {
    "x": "santîgram (n)",
    "y": " santigram"
  },
  {
    "x": "santîlître (n)",
    "y": " santilitre"
  },
  {
    "x": "santîm (n)",
    "y": " santim"
  },
  {
    "x": "santîme (m)",
    "y": " b. santîmetre (n)"
  },
  {
    "x": "santîmetre (n)",
    "y": " santimetre, santim"
  },
  {
    "x": "santîmetrekare (n)",
    "y": " santimetrekare"
  },
  {
    "x": "santîmetremîkab",
    "y": " santimetreküp"
  },
  {
    "x": "santîmi (m)",
    "y": " b. santîmetre (n)"
  },
  {
    "x": "Santîyago (n)",
    "y": " Santiago"
  },
  {
    "x": "Santo Domîngo (n)",
    "y": " Santo Domingo"
  },
  {
    "x": "santral (n)",
    "y": " santral"
  },
  {
    "x": "sanye (m)",
    "y": " b. sanîye (m)"
  },
  {
    "x": "sap (n)",
    "y": " sap (bitki için)"
  },
  {
    "x": "sapik (n)",
    "y": " sapçık"
  },
  {
    "x": "sapik (n)",
    "y": " b. şapik (n)"
  },
  {
    "x": "sal û sapik (zh)",
    "y": " b. şal û şapikî (zh)"
  },
  {
    "x": "sal û sapikî (zh)",
    "y": " b. şal û şapikî (zh)"
  },
  {
    "x": "saq (n)",
    "y": " 1)incik, baldır\r\n~2)b. saqe (n)"
  },
  {
    "x": "saqe (n)",
    "y": " 1)konç, baldıra geçirilen ve yünden örülmüş olan kışlık bir tür tozluk\r\n~2)diz boyu (dize kadar olan)"
  },
  {
    "x": "saqin",
    "y": " sakın"
  },
  {
    "x": "saqo (n)",
    "y": " sako, palto"
  },
  {
    "x": "saqol (n)",
    "y": " b. seqavêl (n)"
  },
  {
    "x": "saqsî (m)",
    "y": " b. saksî (m)"
  },
  {
    "x": "sar-I (m)",
    "y": " b. serre (m)"
  },
  {
    "x": "sar-II (n)",
    "y": " b. şar (n)"
  },
  {
    "x": "sarnayîş (n)",
    "y": " b. sernayîş (n)"
  },
  {
    "x": "sar kewtene",
    "y": " b. ser kewtene"
  },
  {
    "x": "sara-I (m)",
    "y": " sara, epilepsi"
  },
  {
    "x": "sara-II (n)",
    "y": " b. sere (n)"
  },
  {
    "x": "sara birnayene",
    "y": " b. sere birnayene"
  },
  {
    "x": "sarabirnayîş (n)",
    "y": " b. serebirnayîş (n)"
  },
  {
    "x": "saraç (n)",
    "y": " b. serac, -e"
  },
  {
    "x": "saradî (m)",
    "y": " b. seradî (m)"
  },
  {
    "x": "sarafser (n)",
    "y": " b. serhevsar (n)"
  },
  {
    "x": "sarastar (n)",
    "y": " b. serastar (n)"
  },
  {
    "x": "sarav (n)",
    "y": " b. şerab (n)"
  },
  {
    "x": "Sarayevo (n)",
    "y": " Saraybosna"
  },
  {
    "x": "sarayin, -e",
    "y": " saralı"
  },
  {
    "x": "sarbina (m)",
    "y": " b. serbina (m)"
  },
  {
    "x": "sarbirnayîş (n)",
    "y": " b. serebirnayîş (n)"
  },
  {
    "x": "sard (n)",
    "y": " b. serd (n)"
  },
  {
    "x": "sard girewtene",
    "y": " b. serd girewtene"
  },
  {
    "x": "sard kerdene",
    "y": " b. serd kerdene "
  },
  {
    "x": "sardeste (n)",
    "y": " değnek"
  },
  {
    "x": "sardgirewtiş (n)",
    "y": " b. serdgirewtiş (n)"
  },
  {
    "x": "sardin, -e",
    "y": " b. serdin, -e"
  },
  {
    "x": "sardin kerdene",
    "y": " b. serdin kerdene "
  },
  {
    "x": "Sardînya (m)",
    "y": " Sardinya"
  },
  {
    "x": "sare (n)",
    "y": " b. sere (n)"
  },
  {
    "x": "sare arê dayîne",
    "y": " b. sere arê dayene"
  },
  {
    "x": "sare birnayene",
    "y": " b. sere birnayene"
  },
  {
    "x": "Sare û çiman ser.",
    "y": " b. Serî-çiman ser."
  },
  {
    "x": "Sare û çimanê mi ser. ",
    "y": " b. Sere û çimanê mi ser."
  },
  {
    "x": "Sare û çimon ser.",
    "y": " b. Serî-çiman ser."
  },
  {
    "x": "Sare û çimonî mi ser. ",
    "y": " b. Sere û çimanê mi ser."
  },
  {
    "x": "Sare û çimûn mi ser. ",
    "y": " b. Sere û çimanê mi ser."
  },
  {
    "x": "Sare û çimûn ser.",
    "y": " b. Serî-çiman ser."
  },
  {
    "x": "Sare û çimûnî mi ser. ",
    "y": " b. Sere û çimanê mi ser."
  },
  {
    "x": "sare kewtene",
    "y": " b. sere kewtene"
  },
  {
    "x": "sare kowtene",
    "y": " b. sere kewtene"
  },
  {
    "x": "sare ranaene",
    "y": " b. sere ronayene"
  },
  {
    "x": "sare vera nayene",
    "y": " b. sere vera nayene"
  },
  {
    "x": "sare wedardene",
    "y": " b. sere wedaritene"
  },
  {
    "x": "sare wedaritene",
    "y": " b. sere wedaritene"
  },
  {
    "x": "sare wedartene",
    "y": " b. sere wedaritene"
  },
  {
    "x": "sare wederdayene",
    "y": " b. sere wedaritene"
  },
  {
    "x": "sare wederdene",
    "y": " b. sere wedaritene"
  },
  {
    "x": "sarey genimî (n)",
    "y": " b. sereyê genimî (n)"
  },
  {
    "x": "sarê xu ver di şutene",
    "y": " b. sereyê xo ver de şutene"
  },
  {
    "x": "sarê xwi şutene",
    "y": " b. sereyê xo şutene"
  },
  {
    "x": "Sarî-çiman ser.",
    "y": " b. Serî-çiman ser."
  },
  {
    "x": "Sarî-çimon ser.",
    "y": " b. Serî-çiman ser."
  },
  {
    "x": "Sarî-çimûn ser.",
    "y": " b. Serî-çiman ser."
  },
  {
    "x": "Sarî ser!",
    "y": " b. Serî ser!"
  },
  {
    "x": "sarearêdayîş (n)",
    "y": " b. serearêdayîş (n)"
  },
  {
    "x": "sarebirnayîş (n)",
    "y": " b. serebirnayîş (n)"
  },
  {
    "x": "saredez (n)",
    "y": " b. seredej (n)"
  },
  {
    "x": "sarehusk, -e",
    "y": " b. serehuşk, -e"
  },
  {
    "x": "sarehuskîye (m)",
    "y": " b. serehuşkî (m), serehuşkîye (m)"
  },
  {
    "x": "sarehuşkîye (m)",
    "y": " b. serehuşkî (m), serehuşkîye (m)"
  },
  {
    "x": "sarekewtiş (n)",
    "y": " b. serekewtiş (n)"
  },
  {
    "x": "sarekowtiş (n)",
    "y": " b. serekewtiş (n)"
  },
  {
    "x": "sareranaîs (n)",
    "y": " b. sereronayîş (n)"
  },
  {
    "x": "sareveranayîş (n)",
    "y": " b. sereveranayîş (n)"
  },
  {
    "x": "sareweşê (m)",
    "y": " b. serweşîye (m), serweşî (m)"
  },
  {
    "x": "sareweşîye (m)",
    "y": " b. serweşîye (m), serweşî (m) "
  },
  {
    "x": "sareyin (n)",
    "y": " b. sereyin (n)"
  },
  {
    "x": "sari (n)",
    "y": " b. sere (n)"
  },
  {
    "x": "sari birnayene",
    "y": " b. sere birnayene"
  },
  {
    "x": "sari kotene",
    "y": " b. sere kewtene"
  },
  {
    "x": "sari kowtene",
    "y": " b. sere kewtene"
  },
  {
    "x": "sari rûnayene",
    "y": " b. sere ronayene"
  },
  {
    "x": "sarikewtiş (n)",
    "y": " b. serekewtiş (n)"
  },
  {
    "x": "sarikotiş (n)",
    "y": " b. serekewtiş (n)"
  },
  {
    "x": "sarincêri (m)",
    "y": " b. salincêre (m)"
  },
  {
    "x": "sarinci (m)",
    "y": " b. salince (m)"
  },
  {
    "x": "sarirûnayîş (n)",
    "y": " b. sereronayîş (n)"
  },
  {
    "x": "sariştan (n)",
    "y": " b. şaristan (n)"
  },
  {
    "x": "sarî (n)",
    "y": " sari"
  },
  {
    "x": "sarma (m)",
    "y": " b. serbina (m)"
  },
  {
    "x": "sarme (n)",
    "y": " sarma"
  },
  {
    "x": "sarna (m)",
    "y": " b. serbina (m)"
  },
  {
    "x": "saropuye (n)",
    "y": " b. serûpa (n)"
  },
  {
    "x": "sarpune (n)",
    "y": " b. serbina (m)"
  },
  {
    "x": "sarr (m)",
    "y": " b. serre (m)"
  },
  {
    "x": "sarrane, -ye",
    "y": " b. serrane, -ye"
  },
  {
    "x": "sarre (m)",
    "y": " b. serre (m)"
  },
  {
    "x": "saruşme (n)",
    "y": " b. serreşme (n)"
  },
  {
    "x": "sarûpê (n)",
    "y": " b. serûpa (n)"
  },
  {
    "x": "sarvet (n)",
    "y": " b. şerbet (n)"
  },
  {
    "x": "sarvete (m)",
    "y": " b. şerbet (n)"
  },
  {
    "x": "sarvêt (n)",
    "y": " b. şerbet (n)"
  },
  {
    "x": "sas, -e",
    "y": " b. şaş, -e-I"
  },
  {
    "x": "sasadir (n)",
    "y": " b. şeşadir (n) "
  },
  {
    "x": "sate-I (n)",
    "y": " ağaç gövdesi"
  },
  {
    "x": "sate-II (m)",
    "y": " b. saete (m)"
  },
  {
    "x": "sate-III (m)",
    "y": " b. sate-I (n)"
  },
  {
    "x": "satelît (n)",
    "y": " uydu"
  },
  {
    "x": "sati (m)",
    "y": " b. saete (m)"
  },
  {
    "x": "satil (n)",
    "y": " b. sitil (n)"
  },
  {
    "x": "satilcan (n)",
    "y": " satlıcan, zatülcenp "
  },
  {
    "x": "satilcon (n)",
    "y": " b. satilcan (n)"
  },
  {
    "x": "satilcûn (n)",
    "y": " b. satilcan (n)"
  },
  {
    "x": "satire (m)",
    "y": " b. satore (m) "
  },
  {
    "x": "satirre (m)",
    "y": " b. satore (m) "
  },
  {
    "x": "satlecan (n)",
    "y": " b. satilcan (n)"
  },
  {
    "x": "satlîcan (n)",
    "y": " b. satilcan (n)"
  },
  {
    "x": "sator (n)",
    "y": " b. satore (m) "
  },
  {
    "x": "satore (m)",
    "y": " satır"
  },
  {
    "x": "satori (m)",
    "y": " b. satore (m)"
  },
  {
    "x": "satranc (n)",
    "y": " b. setrenc (n)"
  },
  {
    "x": "satranç (n)",
    "y": " b. setrenc (n)"
  },
  {
    "x": "satrecan (n)",
    "y": " b. satilcan (n)"
  },
  {
    "x": "satuar (m)",
    "y": " b. satore (m)"
  },
  {
    "x": "satuer (m)",
    "y": " b. satore (m)"
  },
  {
    "x": "satuwer (m)",
    "y": " b. satore (m)"
  },
  {
    "x": "sava",
    "y": " b. seba-I"
  },
  {
    "x": "savar (n)",
    "y": " bulgur"
  },
  {
    "x": "savil (n)",
    "y": " b. şavile (m)"
  },
  {
    "x": "savir (n)",
    "y": " b. sebir (n)"
  },
  {
    "x": "savir kerdene",
    "y": " b. sebir kerdene"
  },
  {
    "x": "savirkerdiş (n)",
    "y": " b. sebirkerdiş (n)"
  },
  {
    "x": "savoke (m)",
    "y": " ayak bileği"
  },
  {
    "x": "savoke de çewtîye (m)",
    "y": " ayak bileğinde eğrilik"
  },
  {
    "x": "savr (n)",
    "y": " b. sebir (n)"
  },
  {
    "x": "savul (n)",
    "y": " b. şavile (m)"
  },
  {
    "x": "savyar (n)",
    "y": " miktar"
  },
  {
    "x": "savyarê malzemeyî (n)",
    "y": " malzeme miktarı"
  },
  {
    "x": "saw (n)",
    "y": " fobi, yılgı"
  },
  {
    "x": "sawo komelkî (n)",
    "y": " sosyal fobi"
  },
  {
    "x": "sawa (m)",
    "y": " b. saba (m)"
  },
  {
    "x": "saway (m)",
    "y": " b. saba (m)"
  },
  {
    "x": "sawe (n)",
    "y": " sele (büyük sele), altında yiyecek ve eşya saklamak için de kullanılan büyük yayvan sepet"
  },
  {
    "x": "sawi (n)",
    "y": " b. sawe (n)"
  },
  {
    "x": "sawin (n)",
    "y": " b. sabun (n)"
  },
  {
    "x": "sawitene",
    "y": " 1)sürmek, sürtmek\r\n~2)bilemek"
  },
  {
    "x": "... sawitene",
    "y": " bulaştırmak (fiziki şeyler için)"
  },
  {
    "x": "sawitiş (n)",
    "y": " sürme"
  },
  {
    "x": "... sawitiş (n)",
    "y": " bulaştırma (fiziki şeyler için)"
  },
  {
    "x": "sawî (n)",
    "y": " b. sabe (n)"
  },
  {
    "x": "sawîyayene",
    "y": " 1)sürülmek, sürtülmek\r\n~2)bilenmek\r\n~3)bulaşmak"
  },
  {
    "x": "... sawîyayene",
    "y": " bulaşmak (fiziki şeyler için)"
  },
  {
    "x": "... sawîyayîş",
    "y": " bulaşma (fiziki şeyler için)"
  },
  {
    "x": "sawqa (m)",
    "y": " b. şewqa (m)"
  },
  {
    "x": "sawrî (n)",
    "y": " b. savyar (n)"
  },
  {
    "x": "sawun (n)",
    "y": " b. sabun (n)"
  },
  {
    "x": "sawutene",
    "y": " b. sawitene"
  },
  {
    "x": "... sawutene",
    "y": " b. ... sawitene"
  },
  {
    "x": "sawutiş (n)",
    "y": " b. sawitiş (n)"
  },
  {
    "x": "... sawutiş (n)",
    "y": " b. ... sawitiş (n)"
  },
  {
    "x": "sawuyayene",
    "y": " b. sawîyayene"
  },
  {
    "x": "... sawuyayene",
    "y": " b. ... sawîyayene"
  },
  {
    "x": "... sawuyayîş",
    "y": " b. ... sawîyayîş"
  },
  {
    "x": "sax, -e-I",
    "y": " saf (kişi için)"
  },
  {
    "x": "sax, -e-II",
    "y": " 1)sağlam (ceviz içi vb. için)\r\n~2)sağlam (çürük olmayan)"
  },
  {
    "x": "sax bîyayene",
    "y": " b. sax bîyene"
  },
  {
    "x": "sax bîyene",
    "y": " sağlam olmak, sağlamlaşmak"
  },
  {
    "x": "sax kerdene",
    "y": " yoklama yapmak, yoklamak"
  },
  {
    "x": "saxbîyayîş (n)",
    "y": " sağlam olma, sağlamlaşma"
  },
  {
    "x": "saxek, -e",
    "y": " saf (kişi için)"
  },
  {
    "x": "saxekey (m)",
    "y": " b. saxekîye (m)"
  },
  {
    "x": "saxekê (m)",
    "y": " b. saxekî (m)"
  },
  {
    "x": "saxeki (m)",
    "y": " b. saxek, -e"
  },
  {
    "x": "saxekî (m)",
    "y": " saflık (kişi için)"
  },
  {
    "x": "saxekîye (m)",
    "y": " saflık (kişi için)"
  },
  {
    "x": "saxey (m)",
    "y": " b. saxî (m), saxîye (m)"
  },
  {
    "x": "saxê (m)",
    "y": " b. saxî (m), saxîye (m)"
  },
  {
    "x": "saxi (m)",
    "y": " b. sax, -e"
  },
  {
    "x": "saxirsî, -ye",
    "y": " sapasağlam"
  },
  {
    "x": "saxî-I (m)",
    "y": " saflık (kişi için)"
  },
  {
    "x": "saxî-II (m)",
    "y": " sağlamlık (ceviz içi vb. için)"
  },
  {
    "x": "saxîye-I (m)",
    "y": " saflık (kişi için)"
  },
  {
    "x": "saxîye-II (m)",
    "y": " sağlamlık (ceviz içi vb. için)"
  },
  {
    "x": "saxkerdiş (n)",
    "y": " yoklama"
  },
  {
    "x": "saxkerdişê leşkerîye (n)",
    "y": " askerlik yoklaması"
  },
  {
    "x": "saxkerdişê rayapêroyî (n)",
    "y": " kamuoyu yoklaması"
  },
  {
    "x": "saxlam, -e",
    "y": " b. saxlem, -e"
  },
  {
    "x": "saxlem, -e",
    "y": " sağlam (ceviz içi vb. için), dayanıklı"
  },
  {
    "x": "saxlem bîyayene",
    "y": " b. saxlem bîyene"
  },
  {
    "x": "saxlem bîyene",
    "y": " sağlam olmak, sağlamlaşmak"
  },
  {
    "x": "saxlem kerdene",
    "y": " sağlamlaştırmak"
  },
  {
    "x": "saxlembîyayîş (n)",
    "y": " sağlam olma, sağlamlaşma"
  },
  {
    "x": "saxlemî (m)",
    "y": " sağlamlık"
  },
  {
    "x": "saxlemîye (m)",
    "y": " sağlamlık"
  },
  {
    "x": "saxlemkerdiş (n)",
    "y": " sağlamlaştırma"
  },
  {
    "x": "say (m)",
    "y": " b. saye-I (m)"
  },
  {
    "x": "say binard (m)",
    "y": " b. saya binerdî (m)"
  },
  {
    "x": "sayê bindî erdî (m)",
    "y": " b. saya binerdî (m)"
  },
  {
    "x": "sayê binerd (m)",
    "y": " b. saya binerdî (m)"
  },
  {
    "x": "say kerdene",
    "y": " saymak (b. hûmaritene)"
  },
  {
    "x": "sayd (n)",
    "y": " b. seyd (n)"
  },
  {
    "x": "saydar, -e",
    "y": " b. seyddar, -e"
  },
  {
    "x": "sayddar, -e",
    "y": " b. seyddar, -e"
  },
  {
    "x": "saydwûn (n)",
    "y": " b. seydwan, -e"
  },
  {
    "x": "saye-I (m)",
    "y": " elma"
  },
  {
    "x": "saya binerdî (m)",
    "y": " yerelması"
  },
  {
    "x": "saya gilbe (m)",
    "y": " âdemelması (gırtlak çıkıntısı)"
  },
  {
    "x": "Saya Xelatî (m)",
    "y": " Ahlat Elması"
  },
  {
    "x": "saye-II (m)",
    "y": " b. şaye (m)"
  },
  {
    "x": "saye kerdene",
    "y": " kontrol etmek, yoklama yapmak, yoklamak, aramak"
  },
  {
    "x": "sayeke-I (m)",
    "y": " bilek çıkıntısı (kemiği)"
  },
  {
    "x": "sayeke-II",
    "y": " b. sankî"
  },
  {
    "x": "sayekerdiş-I (n) (TE)",
    "y": " ilk duruma getirmek, sıfırlamak"
  },
  {
    "x": "sayekerdiş-II (n)",
    "y": " kontrol, kontrol etme, yoklama, arama"
  },
  {
    "x": "sayekerdişê rayapêroyî (n)",
    "y": " kamuoyu yoklaması"
  },
  {
    "x": "sayer (m)",
    "y": " b. sayêre (m)"
  },
  {
    "x": "sayeri (m)",
    "y": " b. sayêre (m)"
  },
  {
    "x": "sayêke",
    "y": " b. sankî"
  },
  {
    "x": "sayêki",
    "y": " b. sankî"
  },
  {
    "x": "sayêre (m)",
    "y": " elma ağacı"
  },
  {
    "x": "sayêri (m)",
    "y": " b. sayêre (m)"
  },
  {
    "x": "sayinsfîkşin (n)",
    "y": " bilimkurgu"
  },
  {
    "x": "sayir, -e",
    "y": " b. şaîr, -e"
  },
  {
    "x": "sayî-I (m)",
    "y": " açık hava, bulutsuz hava\r\n~Sayî ya. (Hava açıktır.)"
  },
  {
    "x": "sayî-II (m)",
    "y": " b. şayî (m)"
  },
  {
    "x": "sayî ci kotene",
    "y": " b. şayî dekewtene"
  },
  {
    "x": "sayîn",
    "y": " b. senîn"
  },
  {
    "x": "sayîr, -e",
    "y": " b. şaîr, -e"
  },
  {
    "x": "sayîye (m)",
    "y": " b. şayî (m), şayîye (m)"
  },
  {
    "x": "sayka",
    "y": " b. sankî"
  },
  {
    "x": "sayke",
    "y": " b. sankî"
  },
  {
    "x": "saykî",
    "y": " b. sankî"
  },
  {
    "x": "saykîya",
    "y": " b. sankî"
  },
  {
    "x": "saylêr (m)",
    "y": " b. sayêre (m)"
  },
  {
    "x": "sayn",
    "y": " b. senîn"
  },
  {
    "x": "saz (n)",
    "y": " 1)saz, çalgı, enstrüman\r\n~2)davul"
  },
  {
    "x": "saz cenayene",
    "y": " çalgı çalmak"
  },
  {
    "x": "saz cinitene",
    "y": " çalgı çalmak"
  },
  {
    "x": "saz kerdene (TE)",
    "y": " kurmak"
  },
  {
    "x": "saz û zirna",
    "y": " çalgı çağanak"
  },
  {
    "x": "saz û zurna",
    "y": " b. saz û zirna"
  },
  {
    "x": "sazê muzîkî (n)",
    "y": " çalgı aleti"
  },
  {
    "x": "saze (m)",
    "y": " b. saz (n)"
  },
  {
    "x": "sazgeh (n)",
    "y": " 1)kuruluş, tesis\r\n~2)işletme (işletme kuruluşu)"
  },
  {
    "x": "sazgehê awguznayîşê gendawe yo bîyolojîko aver",
    "y": " ileri biyolojik atık su arıtma tesisi"
  },
  {
    "x": "sazgehê awguznayîşî yê gendawa bankîye (n)",
    "y": " evsel atık su arıtma tesisi"
  },
  {
    "x": "sazgehê otobusan (n)",
    "y": " otobüs işletmesi"
  },
  {
    "x": "sazgehê pêroyî (zh)",
    "y": " kamu kuruluşları"
  },
  {
    "x": "sazgehê umûmîya (zh)",
    "y": " kamu kuruluşları"
  },
  {
    "x": "sazgehê weşîye (n)",
    "y": " sağlık tesisi"
  },
  {
    "x": "sazgeho îdarî (n)",
    "y": " idari tesis"
  },
  {
    "x": "sazgeho komelkî (n)",
    "y": " sosyal tesis"
  },
  {
    "x": "sazgeho sosyal (n)",
    "y": " sosyal tesis"
  },
  {
    "x": "sazgehdar, -e",
    "y": " işletmeci"
  },
  {
    "x": "sazgehdarî (m)",
    "y": " işletmecilik"
  },
  {
    "x": "sazgehdarîye (m)",
    "y": " işletmecilik"
  },
  {
    "x": "sazkerdiş (n) (TE)",
    "y": " kurma"
  },
  {
    "x": "sazî (zh)",
    "y": " çalgı çağanak"
  },
  {
    "x": "se-I",
    "y": " yüz"
  },
  {
    "x": "se-II",
    "y": " 1)ne\r\n~Ey va se? (O ne dedi?)\r\n~2)b. senî"
  },
  {
    "x": "se-III",
    "y": " gibi\r\n~Sipî yo se vewre. (Kar gibi beyazdır.)\r\n~\t\r\n~Hende nerm o ke, se peme. (O kadar yumuşak ki, pamuk gibi.)"
  },
  {
    "x": "se-IV (n)",
    "y": " b. ser (n)"
  },
  {
    "x": "se-V",
    "y": " b. seyr"
  },
  {
    "x": "se kerdene",
    "y": " b. seyr kerdene"
  },
  {
    "x": "se-VI",
    "y": " nere\r\n~Ti se ra şinî/şina? (Nereye gidiyorsun?)"
  },
  {
    "x": "se-VII",
    "y": " b. eke"
  },
  {
    "x": "se, -ye-VIII",
    "y": " b. sey, -e-II"
  },
  {
    "x": "seans (n, sn)",
    "y": " gösterim, seans"
  },
  {
    "x": "seat (m)",
    "y": " b. saete (m)"
  },
  {
    "x": "seate (m)",
    "y": " b. saete (m)"
  },
  {
    "x": "seba-I",
    "y": " için, yüzünden, dolayı, ötürü, nedeniyle, sebebiyle"
  },
  {
    "x": "seba aye",
    "y": " onun için"
  },
  {
    "x": "seba aye ra",
    "y": " o nedenle, o yüzden, ondan dolayı, ondan ötürü"
  },
  {
    "x": "seba",
    "y": " onun için"
  },
  {
    "x": "seba ey ra",
    "y": " o nedenle, o yüzden, ondan dolayı, ondan ötürü"
  },
  {
    "x": "seba ke",
    "y": " için"
  },
  {
    "x": "seba ... ra",
    "y": " nedeniyle, sebebiyle, yüzünden, dolayı, ötürü"
  },
  {
    "x": "seba-II (n)",
    "y": " b. siba (n)"
  },
  {
    "x": "ser sebay (n)",
    "y": " b. serê sibayî (n) "
  },
  {
    "x": "serê sebay (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "sebale (n)",
    "y": " biçilmiş ekini harman yerine taşıma işi"
  },
  {
    "x": "sebale kerdene",
    "y": " biçilmiş ekini harmana taşımak"
  },
  {
    "x": "sebat-I (n)",
    "y": " dayanma"
  },
  {
    "x": "sebat-II (m)",
    "y": " b. sibate (m)"
  },
  {
    "x": "sebateki (m)",
    "y": " b. sibateke (m)"
  },
  {
    "x": "sebateki kerdene",
    "y": " b. sibateke kerdene"
  },
  {
    "x": "sebatekikerdiş (n)",
    "y": " b. sibatekekerdiş (n) "
  },
  {
    "x": "sebati (m)",
    "y": " b. sibate (m)"
  },
  {
    "x": "sebax (n)",
    "y": " b. siwax (n)"
  },
  {
    "x": "sebax kerdene",
    "y": " b. siwax kerdene"
  },
  {
    "x": "sebaxkerdiş",
    "y": " b. siwaxkerdiş"
  },
  {
    "x": "sebeb (n)",
    "y": " 1)neden, sebep\r\n~2)güdü"
  },
  {
    "x": "sebebê lazimî yê projeyî (n)",
    "y": " proje gerekçesi"
  },
  {
    "x": "sebebo lazim (n)",
    "y": " gerekçe"
  },
  {
    "x": "sebet",
    "y": " b. semed (n)"
  },
  {
    "x": "sebeta",
    "y": " b. seba-I, semedê ..."
  },
  {
    "x": "sebetanê ... ra",
    "y": " b. semedê ... ra "
  },
  {
    "x": "sebetanê ...",
    "y": " b. semedê ..."
  },
  {
    "x": "sebeta ... ra",
    "y": " b. semedê ... ra"
  },
  {
    "x": "sebexnayene",
    "y": " b. sebixnayene"
  },
  {
    "x": "sebir (n)",
    "y": " sabır, dayanç, çıdam"
  },
  {
    "x": "sebir kerdene",
    "y": " dayanmak (tahammül etmek, sabretmek), sabretmek"
  },
  {
    "x": "sebirî reyde",
    "y": " sabırla"
  },
  {
    "x": "sebirî reyra",
    "y": " sabırla"
  },
  {
    "x": "sebirdar, -e",
    "y": " sabırlı"
  },
  {
    "x": "sebirîyayene",
    "y": " sabretmek"
  },
  {
    "x": "sebirîyayîş (n)",
    "y": " sabretme"
  },
  {
    "x": "sebirkerdiş (n)",
    "y": " dayanma (tahammül etme, sabretme), sabretme"
  },
  {
    "x": "sebirkerdox, -e",
    "y": " sabırlı"
  },
  {
    "x": "sebirteng, -e",
    "y": " sabırsız "
  },
  {
    "x": "sebixnayene",
    "y": " ağırdan almak"
  },
  {
    "x": "sebîlellah",
    "y": " başıboş (hayvan için)"
  },
  {
    "x": "sebînelala",
    "y": " b. sebîlellah (hayvan için)"
  },
  {
    "x": "sebkan, -e",
    "y": " b. sevkan, -e"
  },
  {
    "x": "sebkanîye (m)",
    "y": " b. sevkanîye (m)"
  },
  {
    "x": "sebox, -e",
    "y": " ağırcanlı, ağırkanlı"
  },
  {
    "x": "seboxey (m)",
    "y": " b. seboxî (m)"
  },
  {
    "x": "seboxê (m)",
    "y": " b. seboxî (m)"
  },
  {
    "x": "seboxî (m)",
    "y": " ağırcanlılık, ağırkanlılık"
  },
  {
    "x": "seboxnayîne",
    "y": " b. sebixnayene"
  },
  {
    "x": "sebr (n)",
    "y": " b. sebir (n)"
  },
  {
    "x": "sebr kerdene",
    "y": " b. sebir kerdene"
  },
  {
    "x": "sebrkerdiş (n)",
    "y": " b. sebirkerdiş (n)"
  },
  {
    "x": "sebtanê",
    "y": " b. seba-I, semedê ..."
  },
  {
    "x": "sebze (n)",
    "y": " b. sewzî (n)"
  },
  {
    "x": "secade (n)",
    "y": " seccade, namazlık"
  },
  {
    "x": "secadi (n)",
    "y": " b. secade (n)"
  },
  {
    "x": "secde (n)",
    "y": " b. secade (n)"
  },
  {
    "x": "seceri (m)",
    "y": " b. şecere (m)"
  },
  {
    "x": "seceri (n)",
    "y": " b. şecere (m)"
  },
  {
    "x": "sed",
    "y": " b. se-I"
  },
  {
    "x": "sede (n)",
    "y": " çift sürerken uzunlamasına sürülen tarla/arazi parçası"
  },
  {
    "x": "sedef (n)",
    "y": " sedef, sedef hastalığı "
  },
  {
    "x": "sedefe (m)",
    "y": " düğme (giysi için)"
  },
  {
    "x": "sedefe devistene/girewtene/qefilnayene/tavistene",
    "y": " düğme iliklemek"
  },
  {
    "x": "sedefi (m)",
    "y": " b. sedefe (m)"
  },
  {
    "x": "sedi (n)",
    "y": " b. sede (n)"
  },
  {
    "x": "sedir-I (n)",
    "y": " sedir"
  },
  {
    "x": "sedir-II (n)",
    "y": " b. sadir (n)"
  },
  {
    "x": "sedya (m)",
    "y": " sedye"
  },
  {
    "x": "seet-I (n)",
    "y": " b. saete-2 (m)"
  },
  {
    "x": "seet-II (n)",
    "y": " b. sihet (n)"
  },
  {
    "x": "seete (m)",
    "y": " b. saete-1 (m) "
  },
  {
    "x": "seeti (m)",
    "y": " b. saete-1 (m) "
  },
  {
    "x": "sefaq (n)",
    "y": " b. şefeq (n)"
  },
  {
    "x": "sefet (n)",
    "y": " b. sifet (n)"
  },
  {
    "x": "sefênayene",
    "y": " süzmek (sıvı için)"
  },
  {
    "x": "sefênayîş (n)",
    "y": " süzme (sıvı için)"
  },
  {
    "x": "sefênîyayene",
    "y": " süzülmek (sıvı için)"
  },
  {
    "x": "sefênîyayîş (n)",
    "y": " süzülme (sıvı için)"
  },
  {
    "x": "sefik (n)",
    "y": " b. sefok (n)"
  },
  {
    "x": "sefîl, -e",
    "y": " görme engelli, görmez, gözsüz, kör"
  },
  {
    "x": "sefîn (n)",
    "y": " b. sefîne (m)"
  },
  {
    "x": "sefîne (m)",
    "y": " 1)siğil (odunları yarmakta yararlanılan), kıskı\r\n~2)sabanın çift sürerken elle tutulan kısmından toprağa kadar uzanan parçası ile saban okuna geçirilen küçük parçasını birbirine bağlayan ağaç parçacığı"
  },
  {
    "x": "sefîni (m)",
    "y": " b. sefîne (m)"
  },
  {
    "x": "sefîr, -e",
    "y": " elçi"
  },
  {
    "x": "sefkan, -e",
    "y": " b. sevkan, -e"
  },
  {
    "x": "sefkanênî (m)",
    "y": " b. sevkanîye (m)"
  },
  {
    "x": "sefkanîye (m)",
    "y": " b. sevkanîye (m)"
  },
  {
    "x": "sefok (n)",
    "y": " süzgeç, süzek, filtre"
  },
  {
    "x": "seg",
    "y": " b. seke"
  },
  {
    "x": "segaw, -e",
    "y": " su samuru"
  },
  {
    "x": "segi",
    "y": " b. seke"
  },
  {
    "x": "segman, -e",
    "y": " b. sekman, -e"
  },
  {
    "x": "sego",
    "y": " b. seke"
  },
  {
    "x": "sehani (m)",
    "y": " b. sehene (m)"
  },
  {
    "x": "sehba (n)",
    "y": " b. siba (n)"
  },
  {
    "x": "ser sehbay (n)",
    "y": " b. serê sibayî (n) "
  },
  {
    "x": "serê sehbay (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "sehene (m)",
    "y": " sahan"
  },
  {
    "x": "seheni (m)",
    "y": " b. sehene (m)"
  },
  {
    "x": "sehet (n)",
    "y": " b. sihet (n)"
  },
  {
    "x": "sehîd, -e",
    "y": " b. şehîd, -e"
  },
  {
    "x": "sehîn",
    "y": " b. senîn"
  },
  {
    "x": "sehnayene",
    "y": " kontrol etmek, yoklama yapmak, yoklamak, aramak\r\n~Mi derzîne kerde vîndî, mi her cayê oda de saye kerde, mi nêdîye. (İğneyi kaybettim, odanın her yerinde aradım, bulamadım.)"
  },
  {
    "x": "sehnayîş (n)",
    "y": " kontrol, kontrol etme, yoklama, arama"
  },
  {
    "x": "sehne (n)",
    "y": " sahne "
  },
  {
    "x": "sehneyo peyên (n, sn)",
    "y": " final sahnesi"
  },
  {
    "x": "sehpa (m)",
    "y": " sehpa"
  },
  {
    "x": "Sehraya Rojawanî (m)",
    "y": " Batı Sahra"
  },
  {
    "x": "sehûn (m)",
    "y": " b. sehene (m)"
  },
  {
    "x": "seîrmûn (n)",
    "y": " b. sermîyan (n)"
  },
  {
    "x": "seîrmûndar (n)",
    "y": " b. sermîyandar (n)"
  },
  {
    "x": "sek",
    "y": " b. seke"
  },
  {
    "x": "sek û salwarî (zh)",
    "y": " b. şak û şelwarî (zh)"
  },
  {
    "x": "seke",
    "y": " 1)gibi\r\n~Seke ti zî zanî lajek ewro nêweş o. (Senin de bildiğin gibi oğlan bugün hasta.) \r\n~2)sanki"
  },
  {
    "x": "seker (n)",
    "y": " b. şeker (n)"
  },
  {
    "x": "sekerate (m)",
    "y": " can çekişme"
  },
  {
    "x": "sekerate de bîyene",
    "y": " can çekişmek, ölüm döşeğinde olmak"
  },
  {
    "x": "sekerate ver de bîyene",
    "y": " can çekişmek, ölüm döşeğinde olmak"
  },
  {
    "x": "sekeratedebîyayîş (n)",
    "y": " can çekişme"
  },
  {
    "x": "sekerateverdebîyayîş (n)",
    "y": " can çekişme"
  },
  {
    "x": "sekerati (m)",
    "y": " b. sekerate (m)"
  },
  {
    "x": "sekerati di bîyayîne",
    "y": " b. sekerate de bîyene"
  },
  {
    "x": "sekerati ver di bîyayîne",
    "y": " b. sekerate ver de bîyene"
  },
  {
    "x": "seket (n)",
    "y": " kabuk (yara kabuğu)"
  },
  {
    "x": "seket girey dayene",
    "y": " b. seket girê dayene"
  },
  {
    "x": "seket girê dayene",
    "y": " kabuk bağlamak (yara için)"
  },
  {
    "x": "sekêt (n)",
    "y": " b. seket (n)"
  },
  {
    "x": "seki",
    "y": " b. seke"
  },
  {
    "x": "sekîlo (n)",
    "y": " kental"
  },
  {
    "x": "sekman, -e",
    "y": " nişancı"
  },
  {
    "x": "sekmon, -i",
    "y": " b. sekman, -e"
  },
  {
    "x": "sekmûn, -i",
    "y": " b. sekman, -e"
  },
  {
    "x": "seko",
    "y": " b. seke"
  },
  {
    "x": "sekoke (m)",
    "y": " b. şekoke (m)"
  },
  {
    "x": "sekrat (m)",
    "y": " b. sekerate (m)"
  },
  {
    "x": "sekrati (m)",
    "y": " b. sekerate (m)"
  },
  {
    "x": "sekrati di bîyayîne",
    "y": " b. sekerate de bîyene"
  },
  {
    "x": "sekrati ver di bîyayîne",
    "y": " b. sekerate ver de bîyene"
  },
  {
    "x": "sekre (n)",
    "y": " b. sikre (n)"
  },
  {
    "x": "sekre bestene",
    "y": " b. sikre bestene"
  },
  {
    "x": "sekrey awe (n)",
    "y": " b. sikreyê awe (n)"
  },
  {
    "x": "sekreter, -e",
    "y": " sekreter, yazman"
  },
  {
    "x": "sekretera pêroyîye (m)",
    "y": " genel sekreter "
  },
  {
    "x": "sekretera teknîkîye (m)",
    "y": " teknik sekreter (gazetecilik vb. için)"
  },
  {
    "x": "sekretero pêroyî (n)",
    "y": " genel sekreter "
  },
  {
    "x": "sekretero teknîkî (n)",
    "y": " teknik sekreter (gazetecilik vb. için)"
  },
  {
    "x": "sekreterê (m)",
    "y": " b. sekreterîye (m), sekreterî (m)"
  },
  {
    "x": "sekreterî (m)",
    "y": " sekreterlik, yazmanlık"
  },
  {
    "x": "sekreterîya encumenî (m)",
    "y": " encümen kâtipliği"
  },
  {
    "x": "sekreterîya pêroyîye (m)",
    "y": " genel sekreterlik"
  },
  {
    "x": "sekreterîye (m)",
    "y": " sekreterlik, yazmanlık"
  },
  {
    "x": "seks (n)",
    "y": " seks "
  },
  {
    "x": "sekte (n)",
    "y": " kusur"
  },
  {
    "x": "sektor (n)",
    "y": " sektör"
  },
  {
    "x": "sektorel, -e",
    "y": " sektörel"
  },
  {
    "x": "sektorî, -ye",
    "y": " sektörel"
  },
  {
    "x": "sekwan (n)",
    "y": " b. sevkan, -e"
  },
  {
    "x": "sekwanîye (m)",
    "y": " b. sevkanîye (m)"
  },
  {
    "x": "sel (m)",
    "y": " b. sele (m)"
  },
  {
    "x": "selê firaqû (m)",
    "y": " b. sela firaqan (m)"
  },
  {
    "x": "selê firaqûn (m)",
    "y": " b. sela firaqan (m)"
  },
  {
    "x": "selage (m)",
    "y": " b. şalage (m)"
  },
  {
    "x": "selahîyet (n)",
    "y": " yetki, salahiyet"
  },
  {
    "x": "selahîyet dayene",
    "y": " yetki vermek, salahiyet vermek, yetkilendirmek"
  },
  {
    "x": "selahîyetdar, -e",
    "y": " yetkili, salahiyetli"
  },
  {
    "x": "selahîyetdayîş (n)",
    "y": " yetki verme, salahiyet verme, yetkilendirme"
  },
  {
    "x": "selahîyetgirewte/a",
    "y": " akredite"
  },
  {
    "x": "selam (n)",
    "y": " selam "
  },
  {
    "x": "selamaleykum",
    "y": " b. selamuneleykum"
  },
  {
    "x": "selamuneleykum",
    "y": " selamünaleyküm"
  },
  {
    "x": "selamûelekîm",
    "y": " b. selamuneleykum"
  },
  {
    "x": "selamûeleykum",
    "y": " b. selamuneleykum"
  },
  {
    "x": "selate (n)",
    "y": " b. salate (n)"
  },
  {
    "x": "sele (m)",
    "y": " sepet, küfe"
  },
  {
    "x": "sela cilan (m)",
    "y": " çamaşır sepeti"
  },
  {
    "x": "sela çopî (m)",
    "y": " çöp sepeti"
  },
  {
    "x": "sela firaqan (m)",
    "y": " bulaşık sepeti"
  },
  {
    "x": "sela kincan (n)",
    "y": " çamaşır sepeti"
  },
  {
    "x": "selate (n)",
    "y": " cacık"
  },
  {
    "x": "seleb (n)",
    "y": " b. seref (n)"
  },
  {
    "x": "selef (n)",
    "y": " faiz"
  },
  {
    "x": "selef girewtene",
    "y": " faiz almak"
  },
  {
    "x": "selefwer, -e",
    "y": " faizci, tefeci"
  },
  {
    "x": "selefwerî (m)",
    "y": " faizcilik, tefecilik"
  },
  {
    "x": "selefwerî kerdene",
    "y": " tefecilik yapmak"
  },
  {
    "x": "selefwerîye (m)",
    "y": " faizcilik, tefecilik"
  },
  {
    "x": "selefwerîye kerdene",
    "y": " tefecilik yapmak"
  },
  {
    "x": "seleke (m)",
    "y": " 1)küçük sepet\r\n~2)döven sürerken ürünü yememesi için öküzün ağzına takılan ağızlık (sepet biçiminde olanı)"
  },
  {
    "x": "seleki (m)",
    "y": " b. seleke (m)"
  },
  {
    "x": "seleqîyaye (n)",
    "y": " b. şeliqîyaye (n)"
  },
  {
    "x": "seleqîyayene",
    "y": " b. şeliqîyayene"
  },
  {
    "x": "seleqîyayêne",
    "y": " b. şeliqîyayene"
  },
  {
    "x": "seleqîyayîye (m)",
    "y": " b. şeliqîyaya (m), şeliqîyayîye (m)"
  },
  {
    "x": "selete (n)",
    "y": " b. salate (n)"
  },
  {
    "x": "selew (n)",
    "y": " b. seref (n)"
  },
  {
    "x": "selexana (m)",
    "y": " b. selixana (m)"
  },
  {
    "x": "selexane-I (n)",
    "y": " kesimevi, kanara, mezbaha, salhane"
  },
  {
    "x": "selexane, -ye-II",
    "y": " avare, aylak, başıboş"
  },
  {
    "x": "selexaneyî (m)",
    "y": " avarelik, aylaklık"
  },
  {
    "x": "selexaneyîye (m)",
    "y": " avarelik, aylaklık"
  },
  {
    "x": "selexanîye (m)",
    "y": " b. selixanîye (m)"
  },
  {
    "x": "selexîyayene",
    "y": " b. selixîyayene "
  },
  {
    "x": "selexnayene",
    "y": " b. selixnayene"
  },
  {
    "x": "selexona (m)",
    "y": " b. selixana (m)"
  },
  {
    "x": "selexone (n)",
    "y": " b. selixane (n)"
  },
  {
    "x": "selexûna (m)",
    "y": " b. selexana (m)"
  },
  {
    "x": "selexûne (n)",
    "y": " b. selexane (n)"
  },
  {
    "x": "seli (m)",
    "y": " b. sele (m) "
  },
  {
    "x": "seley cilon (m)",
    "y": " b. sela cilan (m)"
  },
  {
    "x": "seley firaqon (m)",
    "y": " b. sela firaqan (m)"
  },
  {
    "x": "selik (m)",
    "y": " b. seleke (m)"
  },
  {
    "x": "selike (m)",
    "y": " b. seleke (m) "
  },
  {
    "x": "seliki (m)",
    "y": " b. seleke (m)"
  },
  {
    "x": "seliqnayene",
    "y": " b. şeliqnayene"
  },
  {
    "x": "seliqnayîş (n)",
    "y": " b. şeliqnayîş (n)"
  },
  {
    "x": "seliqnîyayene",
    "y": " b. şeliqnîyayene"
  },
  {
    "x": "seliqnîyayîş (n)",
    "y": " b. şeliqnîyayîş (n)"
  },
  {
    "x": "selixana (m)",
    "y": " ağırcanlı, ağırkanlı"
  },
  {
    "x": "selixane, -ye",
    "y": " ağırcanlı, ağırkanlı"
  },
  {
    "x": "selixaneyey (m)",
    "y": " b. selixaneyî (m)"
  },
  {
    "x": "selixaneyî (m)",
    "y": " ağırcanlılık, ağırkanlılık"
  },
  {
    "x": "selixanîye (m)",
    "y": " ağırcanlı, ağırkanlı"
  },
  {
    "x": "selixîyayene",
    "y": " sıyrılmak (deri vb. için)"
  },
  {
    "x": "selixnayene",
    "y": " sıyırmak (deri vb. için)"
  },
  {
    "x": "selixnayîş (n)",
    "y": " sıyırma (deri vb. için)"
  },
  {
    "x": "selixona (m)",
    "y": " b. selixana (m)"
  },
  {
    "x": "selixone (n)",
    "y": " b. selixane (n)"
  },
  {
    "x": "selixoneyey (m)",
    "y": " b. selixaneyî (m)"
  },
  {
    "x": "selixoneyî (m)",
    "y": " b. selixaneyî (m)"
  },
  {
    "x": "selixûna (m)",
    "y": " b. selixana (m)"
  },
  {
    "x": "selixûne (n)",
    "y": " b. selixane (n)"
  },
  {
    "x": "selixyayene",
    "y": " b. selixîyayene"
  },
  {
    "x": "selître (n)",
    "y": " hektolitre"
  },
  {
    "x": "selkem, -e",
    "y": " yük taşıyacak yaşa gelmiş olan eşek"
  },
  {
    "x": "selme (m)",
    "y": " b. şelme (m)"
  },
  {
    "x": "selom (n)",
    "y": " b. selam (n)"
  },
  {
    "x": "selqnayene",
    "y": " b. şeliqnayene"
  },
  {
    "x": "selqnayîş (n)",
    "y": " b. şeliqnayîş (n)"
  },
  {
    "x": "selqnîyayene",
    "y": " b. şeliqnîyayene"
  },
  {
    "x": "selqnîyayîş (n)",
    "y": " b. şeliqnîyayîş (n)"
  },
  {
    "x": "selroş, -e",
    "y": " sepetçi (sepet satan)"
  },
  {
    "x": "selroşî (m)",
    "y": " sepetçilik"
  },
  {
    "x": "selroşîye (m)",
    "y": " sepetçilik"
  },
  {
    "x": "selsela (m)",
    "y": " soy sop"
  },
  {
    "x": "selviraştox, -e",
    "y": " sepetçi (sepet yapan)"
  },
  {
    "x": "selviraştoxî (m)",
    "y": " sepetçilik"
  },
  {
    "x": "selviraştoxîye (m)",
    "y": " sepetçilik"
  },
  {
    "x": "selx (n)",
    "y": " kuzu sürüsü"
  },
  {
    "x": "selxûna (m)",
    "y": " b. selixana (m)"
  },
  {
    "x": "selxûne (n)",
    "y": " b. selixane (n)"
  },
  {
    "x": "selxvan, -e",
    "y": " kuzu çobanı, oğlak çobanı "
  },
  {
    "x": "selxvanî (m)",
    "y": " kuzu çobanlığı, oğlak çobanlığı"
  },
  {
    "x": "selxvanîye (m)",
    "y": " kuzu çobanlığı, oğlak çobanlığı"
  },
  {
    "x": "semantîk (n)",
    "y": " anlam bilimi, semantik"
  },
  {
    "x": "semantîk, -e",
    "y": " anlam bilimsel, anlamsal, semantik"
  },
  {
    "x": "semawer (n)",
    "y": " b. semawere (m)"
  },
  {
    "x": "semawere (m)",
    "y": " semaver"
  },
  {
    "x": "semaweri (m)",
    "y": " b. semawere (m)"
  },
  {
    "x": "semayene",
    "y": " b. şermayene"
  },
  {
    "x": "semayîş (n)",
    "y": " b. şermayîş (n)"
  },
  {
    "x": "sembe (n)",
    "y": " b. şeme (n)"
  },
  {
    "x": "sembile (m)",
    "y": " b. zembîle (m)"
  },
  {
    "x": "sembîle (m)",
    "y": " b. zembîle (m)"
  },
  {
    "x": "sembole (m)",
    "y": " b. zembîle (m)"
  },
  {
    "x": "sembure (n)",
    "y": " b. simore (n) "
  },
  {
    "x": "seme-I (n)",
    "y": " b. şeme (n)"
  },
  {
    "x": "seme-II (n)",
    "y": " b. semed (n)"
  },
  {
    "x": "semey ... ",
    "y": " b. semedê ..."
  },
  {
    "x": "semê ... ",
    "y": " b. semedê ..."
  },
  {
    "x": "Seme (m)",
    "y": " inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "semed (n)",
    "y": " 1)neden, sebep\r\n~Nê semedî ra vano. (Bu nedenle söylüyor.) \r\n~2)güdü"
  },
  {
    "x": "semedê ...",
    "y": " için"
  },
  {
    "x": "semedê aye ra",
    "y": " o nedenle, o yüzden, ondan dolayı, ondan ötürü"
  },
  {
    "x": "semedê ey ra",
    "y": " o nedenle, o yüzden, ondan dolayı, ondan ötürü"
  },
  {
    "x": "semedê ... ra",
    "y": " nedeniyle, sebebiyle, yüzünden, dolayı, ötürü\r\n~O semedê to ra şi. (O senin yüzünden gitti.)\r\n~\t\r\n~Ez semedê şima ra ameya. (Ben sizin için geldim.)"
  },
  {
    "x": "semedê ... ya",
    "y": " b. semedê ... ra"
  },
  {
    "x": "semedo ke",
    "y": " için"
  },
  {
    "x": "semedî ...",
    "y": " b. semedê ..."
  },
  {
    "x": "semedî ay’ a",
    "y": " b. semedê aye ra"
  },
  {
    "x": "semedî aye",
    "y": " b. semedê aye ra"
  },
  {
    "x": "semedî aye ra",
    "y": " b. semedê aye ra"
  },
  {
    "x": "semedî ey",
    "y": " b. semedê ey ra"
  },
  {
    "x": "semedî ey a",
    "y": " b. semedê ey ra"
  },
  {
    "x": "semedî ey ra",
    "y": " b. semedê ey ra"
  },
  {
    "x": "semedî ... a",
    "y": " b. semedê ... ra"
  },
  {
    "x": "semedî ... ra",
    "y": " b. semedê ... ra"
  },
  {
    "x": "semedî ... ya",
    "y": " b. semedê ... ra"
  },
  {
    "x": "semer (m)",
    "y": " b. semere (m)"
  },
  {
    "x": "semere (m)",
    "y": " semer"
  },
  {
    "x": "semere ci ra kerdene",
    "y": " semerini indirmek"
  },
  {
    "x": "semere ronayene",
    "y": " semerini indirmek"
  },
  {
    "x": "semere ser kerdene",
    "y": " semerini indirmek"
  },
  {
    "x": "semeredar, -e",
    "y": " verimli"
  },
  {
    "x": "semernayene",
    "y": " çemremek (kol, paça, etek vb. için), sıvamak (kol, paça, etek vb. için)"
  },
  {
    "x": "semernayîne",
    "y": " b. semernayene"
  },
  {
    "x": "semernayîs (n)",
    "y": " b. semernayîş (n)"
  },
  {
    "x": "semernayîş (n)",
    "y": " çemreme (kol, paça, etek vb. için)"
  },
  {
    "x": "semeta",
    "y": " b. semedê ..."
  },
  {
    "x": "semetre (n)",
    "y": " hektometre"
  },
  {
    "x": "semetrekare (n)",
    "y": " hektometrekare"
  },
  {
    "x": "semetremîkab (n)",
    "y": " hektometreküp"
  },
  {
    "x": "semey ...",
    "y": " b. semedê ..."
  },
  {
    "x": "semê ...",
    "y": " b. semedê ..."
  },
  {
    "x": "semêd ...",
    "y": " b. semedê ..."
  },
  {
    "x": "semêd ay’ a",
    "y": " b. semedê aye ra"
  },
  {
    "x": "semêd aye",
    "y": " b. semedê aye ra"
  },
  {
    "x": "semêd aye ra",
    "y": " b. semedê aye ra"
  },
  {
    "x": "semêd ey",
    "y": " b. semedê ey ra"
  },
  {
    "x": "semêd ey a",
    "y": " b. semedê ey ra"
  },
  {
    "x": "semêd ey ra",
    "y": " b. semedê ey ra"
  },
  {
    "x": "semêd ... a",
    "y": " b. semedê ... ra"
  },
  {
    "x": "semêd ... ra",
    "y": " b. semedê ... ra"
  },
  {
    "x": "semêd ... ya",
    "y": " b. semedê ... ra"
  },
  {
    "x": "semige (m)",
    "y": " b. şêmûge (m)"
  },
  {
    "x": "semîge (m)",
    "y": " b. şêmûge (m)"
  },
  {
    "x": "semih",
    "y": " b. cömert"
  },
  {
    "x": "semîmî, -ye",
    "y": " açık kalpli, açık yürekli, samimi"
  },
  {
    "x": "semîmîyet (n)",
    "y": " açık kalplilik, açık yüreklilik, samimiyet"
  },
  {
    "x": "semîn (m)",
    "y": " b. semînî (m)"
  },
  {
    "x": "semîner (n)",
    "y": " seminer"
  },
  {
    "x": "semînî (m)",
    "y": " pahalılık"
  },
  {
    "x": "Semo (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "semoned (n)",
    "y": " b. semed (n)"
  },
  {
    "x": "sempozyum (n)",
    "y": " b. sempozyûm (n)"
  },
  {
    "x": "sempozyûm (n)",
    "y": " sempozyum"
  },
  {
    "x": "semptom (n)",
    "y": " belirti"
  },
  {
    "x": "Semsat (n)",
    "y": " Samsat"
  },
  {
    "x": "Semsûr (n)",
    "y": " Adıyaman"
  },
  {
    "x": "semt, -e-I",
    "y": " güzel, yakışıklı, keleş"
  },
  {
    "x": "semt bîyene",
    "y": " yakışıklı olmak"
  },
  {
    "x": "semt-II (n)",
    "y": " semt"
  },
  {
    "x": "semtbîyayîş (n)",
    "y": " yakışıklı olma"
  },
  {
    "x": "semtênî (m)",
    "y": " yakışıklılık, güzellik"
  },
  {
    "x": "semtîye (m)",
    "y": " yakışıklılık, güzellik"
  },
  {
    "x": "semuge (m)",
    "y": " b. şêmûge (m)"
  },
  {
    "x": "sen",
    "y": " b. Senîn\r\n~Ti sen a, rind a? (Nasılsın, iyi misin?)\r\n~\t\r\n~Ti sen a, weş a? (Nasılsın, iyi misin?)"
  },
  {
    "x": "Sena (m)",
    "y": " San’a"
  },
  {
    "x": "senacêr (n)",
    "y": " b. seracêr (n)"
  },
  {
    "x": "senarîst, -e (sn)",
    "y": " senaryo yazarı, senaryocu, senarist"
  },
  {
    "x": "senaryo (n, sn)",
    "y": " senaryo"
  },
  {
    "x": "senaryonuştox, -e (sn)",
    "y": " senaryo yazarı, senaryocu, senarist"
  },
  {
    "x": "senaryoyo adaptekerdeyo tewr baş (sn)",
    "y": " en iyi uyarlama senaryo"
  },
  {
    "x": "senaryoyo orîjînalo tewr baş",
    "y": " en iyi orijinal senaryo"
  },
  {
    "x": "senat (n)",
    "y": " b. senet (n)"
  },
  {
    "x": "senate (m)",
    "y": " b. senet (n)"
  },
  {
    "x": "senatkar (n)",
    "y": " b. senetkar (n)"
  },
  {
    "x": "senayîne",
    "y": " b. sehnayene"
  },
  {
    "x": "senayîş (n)",
    "y": " b. sehnayîş (n)"
  },
  {
    "x": "senceyene",
    "y": " b. sencîyayene"
  },
  {
    "x": "senceyîş (n)",
    "y": " b. sencîyayîş (n)"
  },
  {
    "x": "senciqi (m)",
    "y": " b. sucuxe (m)"
  },
  {
    "x": "sencîyayene",
    "y": " tartılmak"
  },
  {
    "x": "sencîyayêne",
    "y": " b. sencîyayene"
  },
  {
    "x": "sencîyayîş (n)",
    "y": " tartılma "
  },
  {
    "x": "sencuqi (m)",
    "y": " b. sucuxe (m)"
  },
  {
    "x": "sendali (n)",
    "y": " b. sandalî (n)"
  },
  {
    "x": "sendalî (n)",
    "y": " b. sandalî (n)"
  },
  {
    "x": "sendan (n)",
    "y": " b. sindan (n)"
  },
  {
    "x": "sendîka (m)",
    "y": " sendika"
  },
  {
    "x": "sendîkager, -e",
    "y": " sendikacı"
  },
  {
    "x": "sendîkagerî (m)",
    "y": " sendikacılık"
  },
  {
    "x": "sendîkagerîye (m)",
    "y": " sendikacılık"
  },
  {
    "x": "sendoqi (m)",
    "y": " b. sindoqe (m)"
  },
  {
    "x": "sendrom (n)",
    "y": " belirge, sendrom"
  },
  {
    "x": "sendromê aspergerî (n)",
    "y": " asperger sendromu, asperger"
  },
  {
    "x": "sendromê downî (n)",
    "y": " down sendromu"
  },
  {
    "x": "sendromê edwardsî (n)",
    "y": " edwards sendromu"
  },
  {
    "x": "sendromê turnerî (n)",
    "y": " turner sendromu"
  },
  {
    "x": "senduq (m)",
    "y": " b. sindoqe (m)"
  },
  {
    "x": "sendûn (n)",
    "y": " b. sindan (n)"
  },
  {
    "x": "seneat (n)",
    "y": " b. senet (n)"
  },
  {
    "x": "seneatkar (n)",
    "y": " b. senetkar (n)"
  },
  {
    "x": "sened (n)",
    "y": " senet, belge, vesika"
  },
  {
    "x": "senedê fermannameyê heqdayîşî (n)",
    "y": " ödeme emri belgesi"
  },
  {
    "x": "senedê îpotekî (n)",
    "y": " ipotek belgesi"
  },
  {
    "x": "senedê tapûyî (n)",
    "y": " tapu senedi"
  },
  {
    "x": "senedo fermî (n)",
    "y": " resmî senet"
  },
  {
    "x": "senedo resmî (n)",
    "y": " resmî senet"
  },
  {
    "x": "Senegal (n)",
    "y": " Senegal"
  },
  {
    "x": "senet (n)",
    "y": " sanat"
  },
  {
    "x": "senetkar, -e",
    "y": " sanatçı"
  },
  {
    "x": "senê",
    "y": " b. senî"
  },
  {
    "x": "senêhawa",
    "y": " b. senîhawa"
  },
  {
    "x": "senên",
    "y": " b. Senîn\r\n~Ti senên a, rind a? (Nasılsın, iyi misin?)"
  },
  {
    "x": "senêwa",
    "y": " b. senîhawa"
  },
  {
    "x": "Sengale (m)",
    "y": " kadın ve inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "senik, -e",
    "y": " b. şenik, -e"
  },
  {
    "x": "senikek, -e",
    "y": " b. şenikek, -e"
  },
  {
    "x": "senî",
    "y": " nasıl \r\n~Ti senî pîyê xo ra wina vanî? (Babana nasıl böyle dersin?)"
  },
  {
    "x": "senîn",
    "y": " nasıl\r\n~Ti senîn î, baş î?/ Ti senîn a, baş a? (Nasılsın, iyi misin?)\r\n~Ti senîn î, dînc î?/ Ti senîn a, dînc a? (Nasılsın, iyi misin?)\r\n~Ti senîn î, gewz î?/ Ti senîn a, gewz a? (Nasılsın, iyi misin?)\r\n~Ti senîn î, hol î?/ Ti senîn a, hol a? (Nasılsın, iyi misin?)\r\n~Ti senîn î, weş î?/ Ti senîn a, weş a? (Nasılsın, iyi misin?)"
  },
  {
    "x": "senînî (m)",
    "y": " nitel"
  },
  {
    "x": "senînîye (m)",
    "y": " nitelik, kalite"
  },
  {
    "x": "senînîya komirî (m)",
    "y": " kömür kalitesi"
  },
  {
    "x": "senîwa",
    "y": " b. senîhawa"
  },
  {
    "x": "senîye",
    "y": " b. senî"
  },
  {
    "x": "senkek, -e",
    "y": " b. şenikek, -e"
  },
  {
    "x": "senkêk, -e",
    "y": " b. şenikek, -e"
  },
  {
    "x": "sensor (n)",
    "y": " sensör"
  },
  {
    "x": "sensorê dumanî (n)",
    "y": " duman algılayıcı, duman sensörü"
  },
  {
    "x": "sensorê dûyî (n)",
    "y": " duman algılayıcı, duman sensörü"
  },
  {
    "x": "sensorê gazî (n)",
    "y": " gaz algılayıcı, gaz sensörü"
  },
  {
    "x": "sentetîk, -e",
    "y": " sentetik"
  },
  {
    "x": "sentene",
    "y": " tartmak\r\n~O çi senceno? (O ne tartıyor?)"
  },
  {
    "x": "sentiş (n)",
    "y": " tartma"
  },
  {
    "x": "senye",
    "y": " b. senî"
  },
  {
    "x": "separe (n)",
    "y": " b. sêpare (n)"
  },
  {
    "x": "sepet (n)",
    "y": " b. sepete (m)"
  },
  {
    "x": "sepete (m)",
    "y": " sepet"
  },
  {
    "x": "sepeta çopî (m)",
    "y": " çöp sepeti"
  },
  {
    "x": "sepeti (m)",
    "y": " b. sepete (m)"
  },
  {
    "x": "sepetroş, -e",
    "y": " sepetçi (sepet satan)"
  },
  {
    "x": "sepetroşî (m)",
    "y": " sepetçilik"
  },
  {
    "x": "sepetroşîye (m)",
    "y": " sepetçilik"
  },
  {
    "x": "sepetviraştox, -e",
    "y": " sepetçi (sepet yapan)"
  },
  {
    "x": "sepetviraştoxî (m)",
    "y": " sepetçilik"
  },
  {
    "x": "sepetviraştoxîye (m)",
    "y": " sepetçilik"
  },
  {
    "x": "seq bîyayîne",
    "y": " b. seqa bîyene"
  },
  {
    "x": "seqa bîyene",
    "y": " bilenmek"
  },
  {
    "x": "seqa kerdene",
    "y": " bilemek"
  },
  {
    "x": "seqanayîne",
    "y": " b. seqênayene"
  },
  {
    "x": "seqanîyayîne",
    "y": " b. seqênîyayene"
  },
  {
    "x": "seqarek (n)",
    "y": " döven ile boyunduruk arasındaki direk"
  },
  {
    "x": "seqavêl (n)",
    "y": " çalı süpürgesi"
  },
  {
    "x": "seqavîyel (n)",
    "y": " b. seqavêl (n)"
  },
  {
    "x": "seqaw kerdene",
    "y": " b. seqa kerdene"
  },
  {
    "x": "seqay kerdene",
    "y": " b. seqa kerdene"
  },
  {
    "x": "seqel (n)",
    "y": " b. seqil (n)"
  },
  {
    "x": "seqelnayox, -e",
    "y": " b. seqilnayox, -e"
  },
  {
    "x": "seqet, -e",
    "y": " engelli, sakat, mânialı"
  },
  {
    "x": "seqet bîyene",
    "y": " sakatlanmak"
  },
  {
    "x": "seqetbîyayîş (n)",
    "y": " sakatlanma"
  },
  {
    "x": "seqetey (m)",
    "y": " b. seqetî (m)"
  },
  {
    "x": "seqetê (m)",
    "y": " b. seqetî (m)"
  },
  {
    "x": "seqetî (m)",
    "y": " engellilik, sakatlık"
  },
  {
    "x": "seqetîye (m)",
    "y": " engellilik, sakatlık"
  },
  {
    "x": "seqeynayene",
    "y": " b. seqênayene"
  },
  {
    "x": "seqeynîyayene",
    "y": " b. seqênîyayene"
  },
  {
    "x": "seqênayene",
    "y": " bilemek"
  },
  {
    "x": "seqênayeni",
    "y": " b. şeqnayene"
  },
  {
    "x": "seqênîyayene",
    "y": " bilenmek"
  },
  {
    "x": "seqil (n)",
    "y": " cila"
  },
  {
    "x": "seqilnayox, -e",
    "y": " cilacı"
  },
  {
    "x": "seql (n)",
    "y": " b. seqil (n)"
  },
  {
    "x": "seqlnayox, -e",
    "y": " b. seqilnayox, -e"
  },
  {
    "x": "seqnayeni",
    "y": " b. şeqnayene"
  },
  {
    "x": "seqnayîne",
    "y": " b. seqênayene"
  },
  {
    "x": "seqnîyayîne",
    "y": " b. seqênîyayene"
  },
  {
    "x": "seqoqe (m)",
    "y": " b. şekoke (m)"
  },
  {
    "x": "seqsî (m)",
    "y": " b. saksî (m)"
  },
  {
    "x": "ser-I (n)",
    "y": " 1)üst, üzeri\r\n~2)kapak\r\n~3)yüz"
  },
  {
    "x": "ser a vindertene",
    "y": " b. ser a vindetene"
  },
  {
    "x": "ser a vindetene",
    "y": " üzerinde durmak"
  },
  {
    "x": "ser a vinetene",
    "y": " b. ser a vindetene"
  },
  {
    "x": "ser de bîyayene",
    "y": " b. ser de bîyene"
  },
  {
    "x": "ser de bîyene",
    "y": " sıyrılmak (deri vb. için)"
  },
  {
    "x": "ser de kerdene",
    "y": " 1)sıyırmak (deri vb. için)\r\n~2)soymak (birinin parasını vb. zorla almak)"
  },
  {
    "x": "ser de şîyayene",
    "y": " b. ser de şîyene"
  },
  {
    "x": "ser de şîyene",
    "y": " 1)taşmak\r\n~2)sıyrılmak (deri vb. için)"
  },
  {
    "x": "ser di şîyayene",
    "y": " b. ser de şîyene"
  },
  {
    "x": "ser estene",
    "y": " b. ser eştene"
  },
  {
    "x": "ser eştene",
    "y": " baskın vermek, baskın yapmak"
  },
  {
    "x": "ser îştene",
    "y": " b. ser eştene"
  },
  {
    "x": "ser kerdene",
    "y": " 1)katmak, sıkıştırmak (bir şeyi yapması için sıkıştırmak)\r\n~2)b. sero kerdene"
  },
  {
    "x": "ser kewtene",
    "y": " 1)alışmak\r\n~2)başarmak, üstesinden gelmek\r\n~3)kazanmak, yenmek"
  },
  {
    "x": "ser kotene",
    "y": " b. ser kewtene"
  },
  {
    "x": "ser o mendene",
    "y": " boynunda kalmak"
  },
  {
    "x": "ser nayene",
    "y": " 1)üzerine koymak\r\n~2)pişirmek (çorba vb. için)"
  },
  {
    "x": "ser nîyayene",
    "y": " 1)üzerine konulmak\r\n~2)pişirilmek (çorba vb. için)"
  },
  {
    "x": "ser o",
    "y": " 1-üzerinde\r\n~Qeleme kitabî ser o ya. (Kalem kitabın üzerindedir.)\r\n~2-hakkında"
  },
  {
    "x": "ser o can dayene",
    "y": " delice sevmek (mecazî)"
  },
  {
    "x": "ser o gan dayene",
    "y": " delice sevmek (mecazî)"
  },
  {
    "x": "ser o merdene",
    "y": " delice sevmek (mecazî)"
  },
  {
    "x": "ser o şahidî dayene",
    "y": " aleyhinde şahitlik yapmak"
  },
  {
    "x": "ser o şahidî kerdene",
    "y": " aleyhinde şahitlik yapmak"
  },
  {
    "x": "ser o tesîr kerdene",
    "y": " etkilemek, tesir etmek"
  },
  {
    "x": "ser o vindertene",
    "y": " b. ser o vindetene"
  },
  {
    "x": "ser o vindetene",
    "y": " üzerinde durmak"
  },
  {
    "x": "ser o vinetene",
    "y": " b. ser o vindetene"
  },
  {
    "x": "ser ra girewtene",
    "y": " ağzını aramak\r\n~Kajîn fekê mi ra gêra la mi nêva. (Kajîn ağzımı aradı ama söylemedim.)"
  },
  {
    "x": "ser ro",
    "y": " b. ser o"
  },
  {
    "x": "ser şîyayene",
    "y": " b. ser şîyene"
  },
  {
    "x": "... ser şîyayene",
    "y": " b. ... ser şîyene"
  },
  {
    "x": "ser şîyene",
    "y": " anlamak"
  },
  {
    "x": "... ser şîyene",
    "y": " benzemek; çağrıştırmak (andırmak)"
  },
  {
    "x": "ser û binê xo ra kerdene",
    "y": " (mecazi) aşırı harcamak"
  },
  {
    "x": "serê ardan (n)",
    "y": " b. serê erdan (n)"
  },
  {
    "x": "serê ardî (n)",
    "y": " b. serê erdî (n)"
  },
  {
    "x": "serê banî (n)",
    "y": " dam"
  },
  {
    "x": "serê deryayî (n)",
    "y": " denizin yüzü"
  },
  {
    "x": "serê erdan (n)",
    "y": " yeryüzü"
  },
  {
    "x": "serê erdî (n)",
    "y": " yeryüzü"
  },
  {
    "x": "serê hardan (n)",
    "y": " b. serê erdan (n)"
  },
  {
    "x": "serê hardî (n)",
    "y": " b. serê erdî (n)"
  },
  {
    "x": "serê herdan (n)",
    "y": " b. serê erdan (n)"
  },
  {
    "x": "serê herdî (n)",
    "y": " b. serê erdî (n)"
  },
  {
    "x": "serê kergeyan (n)",
    "y": " bağbozumu"
  },
  {
    "x": "serê mahseran (n)",
    "y": " bağbozumu"
  },
  {
    "x": "serê mastî (n)",
    "y": " kaymak"
  },
  {
    "x": "serê qatixî (n)",
    "y": " kaymak"
  },
  {
    "x": "serê rezan (n)",
    "y": " bağbozumu"
  },
  {
    "x": "serê sitî (n)",
    "y": " b. serê şitî (n)"
  },
  {
    "x": "serê şitî (n)",
    "y": " kaymak"
  },
  {
    "x": "serê zerrî (n)",
    "y": " canevi (mecazî)"
  },
  {
    "x": "serî erdî (n)",
    "y": " b. serê erdî (n)"
  },
  {
    "x": "serî erdûn (n)",
    "y": " b. serê erdan (n)"
  },
  {
    "x": "serî şitî (n)",
    "y": " b. serê şitî (n)"
  },
  {
    "x": "serî şît (n)",
    "y": " b. serê şitî (n)"
  },
  {
    "x": "sero kerdene",
    "y": " 1)soymak (kabuk vb. için), kabuğunu soymak\r\n~2)soymak (birinin parasını vb. zorla almak)\r\n~3)sıyırmak (deri vb. için)"
  },
  {
    "x": "sero şîyayene",
    "y": " b. sero şîyene"
  },
  {
    "x": "sero şîyene",
    "y": " sıyrılmak (deri vb. için), soyulmak (deri, kabuk vb. için)"
  },
  {
    "x": "sêr şit (n)",
    "y": " b. serê şitî (n)"
  },
  {
    "x": "sêr şît (n)",
    "y": " b. serê şitî (n)"
  },
  {
    "x": "ser-II (n)",
    "y": " b. şer-I (n)"
  },
  {
    "x": "sera-I",
    "y": " yüzde (mat)"
  },
  {
    "x": "sera-II (m)",
    "y": " sera"
  },
  {
    "x": "serac, -e",
    "y": " saraç"
  },
  {
    "x": "seracêr (n)",
    "y": " 1)aşağıya doğru, iniş, iniş aşağı, yokuş aşağı\r\n~2)baş aşağı"
  },
  {
    "x": "seraç (n)",
    "y": " b. serac, -e"
  },
  {
    "x": "seradî (m)",
    "y": " gözer"
  },
  {
    "x": "seraf, -e",
    "y": " sarraf, altıncı (altın alıp satan kimse)"
  },
  {
    "x": "serafî (m)",
    "y": " sarraflık"
  },
  {
    "x": "serafîye (m)",
    "y": " sarraflık"
  },
  {
    "x": "serafser (n)",
    "y": " b. serhevsar (n)"
  },
  {
    "x": "seramîk (n)",
    "y": " seramik"
  },
  {
    "x": "seranser",
    "y": " b. seraser"
  },
  {
    "x": "seraser",
    "y": " 1)baştan başa\r\n~2)baştan sona (zr)\r\n~“Pencerêy seraser î / Ay lo dilo, lo dilo” (Yew deyîra Palîyî ra)"
  },
  {
    "x": "seraserbedilnayîş (n)",
    "y": " değiş tokuş, değişim, mübadele, takas, trampa"
  },
  {
    "x": "seraservurnayîş (n)",
    "y": " değiş tokuş, değişim, mübadele, takas, trampa"
  },
  {
    "x": "serastar (n)",
    "y": " yular"
  },
  {
    "x": "seraşnayîne",
    "y": " b. şinasnayene"
  },
  {
    "x": "seraşnayîş (n)",
    "y": " b. şinasnayîş (n)"
  },
  {
    "x": "serav (n)",
    "y": " b. şerab (n)"
  },
  {
    "x": "seraw (m)",
    "y": " b. serawe (m)"
  },
  {
    "x": "serawanî (m)",
    "y": " üstyapı"
  },
  {
    "x": "serawanîye (m)",
    "y": " üstyapı"
  },
  {
    "x": "serawe (m)",
    "y": " su bırakma (tarla vb. için)"
  },
  {
    "x": "serb",
    "y": " b. seba-I"
  },
  {
    "x": "serba",
    "y": " b. seba-I"
  },
  {
    "x": "serbey ...",
    "y": " b. seba-I"
  },
  {
    "x": "serban (n)",
    "y": " çatı"
  },
  {
    "x": "serbankar, -e",
    "y": " çatıcı"
  },
  {
    "x": "serbar (n)",
    "y": " hayvan yükünün hayvanın sırtına rastlayan ortadaki kısmı"
  },
  {
    "x": "serber (n)",
    "y": " kapı kasasında (çerçevesinde) eşiğin karşısındaki üst kısım"
  },
  {
    "x": "serberadaye (n)",
    "y": " avare, aylak"
  },
  {
    "x": "serberadaye bîyene",
    "y": " avareleşmek"
  },
  {
    "x": "serberadaye kerdene",
    "y": " avareleştirmek"
  },
  {
    "x": "serberadayebîyayîş (n)",
    "y": " avareleşme"
  },
  {
    "x": "serberadayekerdiş (n)",
    "y": " avareleştirme"
  },
  {
    "x": "serberadayeyî (m)",
    "y": " avarelik, aylaklık"
  },
  {
    "x": "serberadayeyî kerdene",
    "y": " aylaklık etmek"
  },
  {
    "x": "serberadayeyîkerdiş (n)",
    "y": " aylaklık etme"
  },
  {
    "x": "serberadayeyîye (m)",
    "y": " avarelik, aylaklık"
  },
  {
    "x": "serberadayî (m)",
    "y": " avare, aylak"
  },
  {
    "x": "serberadayîye (m)",
    "y": " avare, aylak"
  },
  {
    "x": "serbest, -e",
    "y": " serbest"
  },
  {
    "x": "serbest veradayene",
    "y": " serbest bırakmak"
  },
  {
    "x": "serbest verdayene",
    "y": " serbest bırakmak "
  },
  {
    "x": "serbestey (m)",
    "y": " b. serbestîye (m)"
  },
  {
    "x": "serbestê (m)",
    "y": " b. serbestîye (m) "
  },
  {
    "x": "serbestî (m)",
    "y": " b. serbestîye (m)"
  },
  {
    "x": "serbestîye (m)",
    "y": " özgürlük, serbestlik, serbestiyet "
  },
  {
    "x": "serbestveradayîş (n)",
    "y": " serbest bırakma"
  },
  {
    "x": "serbestverdayîş (n)",
    "y": " serbest bırakma"
  },
  {
    "x": "serbeştî (m)",
    "y": " b. serbestîye (m)"
  },
  {
    "x": "serbeştîye (m)",
    "y": " b. serbestîye (m)"
  },
  {
    "x": "serbet (n)",
    "y": " b. şerbet (n)"
  },
  {
    "x": "serbina (m)",
    "y": " herek (tevek için) "
  },
  {
    "x": "serbuna (m)",
    "y": " b. serbina (m)"
  },
  {
    "x": "serbut (m)",
    "y": " b. şelbûte (m)"
  },
  {
    "x": "sercil (n)",
    "y": " çul (palanın altında hayvanın sırtına örtülen çul) "
  },
  {
    "x": "serconî (n)",
    "y": " tokmak"
  },
  {
    "x": "serçawiş, -e",
    "y": " başçavuş"
  },
  {
    "x": "serçawişî (m)",
    "y": " başçavuşluk"
  },
  {
    "x": "serçawişîye (m)",
    "y": " başçavuşluk"
  },
  {
    "x": "serçawîş, -e",
    "y": " b. serçawiş, -e"
  },
  {
    "x": "serçawuş, -e",
    "y": " b. serçawiş, -e"
  },
  {
    "x": "serçeqê dare (n)",
    "y": " b. serçiqê dare (n)"
  },
  {
    "x": "serçiqê dare (n)",
    "y": " ağacın tepesi"
  },
  {
    "x": "serd (n)",
    "y": " soğuk"
  },
  {
    "x": "ser’ de çîyayêne",
    "y": " b. serd de çîyayene"
  },
  {
    "x": "serd bîyene",
    "y": " soğumak"
  },
  {
    "x": "serd cazui (n)",
    "y": " b. serdê cazuye (n)"
  },
  {
    "x": "serd de çîyayene",
    "y": " üşütmek, soğuk almak"
  },
  {
    "x": "serd girewtene",
    "y": " üşütmek, soğuk almak"
  },
  {
    "x": "serd girotene",
    "y": " b. serd girewtene"
  },
  {
    "x": "serd guretene",
    "y": " b. serd girewtene"
  },
  {
    "x": "serd gurewtene",
    "y": " b. serd girewtene"
  },
  {
    "x": "serd gurotene",
    "y": " b. serd girewtene"
  },
  {
    "x": "serd kerdene",
    "y": " 1)soğutmak\r\n~2)soğuktan çabuk etkilenmek, çabuk üşümek"
  },
  {
    "x": "serd ra veşayene",
    "y": " fazla üşümekten dolayı aşırı sızlamak (el vb. için)"
  },
  {
    "x": "serd ra vêsayene",
    "y": " b. serd ra veşayene"
  },
  {
    "x": "serd ra vêşayene",
    "y": " b. serd ra veşayene"
  },
  {
    "x": "serdê cazuye (n)",
    "y": " kocakarı soğuğu, berdel acuz"
  },
  {
    "x": "serdar, -e",
    "y": " kumandan, komutan"
  },
  {
    "x": "serder bîyene",
    "y": " akıl erdirmek"
  },
  {
    "x": "serder nêbîyene",
    "y": " akıl erdirememek, akıl ermemek"
  },
  {
    "x": "serdayîş (n)",
    "y": " ziyaret"
  },
  {
    "x": "serddeçîyayîş (n)",
    "y": " üşütme"
  },
  {
    "x": "serdeçîyayîş (n)",
    "y": " b. serddeçîyayîş (n)"
  },
  {
    "x": "serdekerdiş (n)",
    "y": " 1)sıyırma (deri vb. için)\r\n~2)soyma (birinin parasını vb. zorla alma)"
  },
  {
    "x": "serdekerdox, -e",
    "y": " soyguncu"
  },
  {
    "x": "serdekerdoxî (m)",
    "y": " soygunculuk"
  },
  {
    "x": "serdekerdoxîye (m)",
    "y": " soygunculuk"
  },
  {
    "x": "serder bîyene",
    "y": " anlamak"
  },
  {
    "x": "serdeste (n)",
    "y": " b. sardeste (n)"
  },
  {
    "x": "serdeşîyaya (m)",
    "y": " taşkın (taşmış olan)"
  },
  {
    "x": "serdeşîyaye (n)",
    "y": " taşkın (taşmış olan)"
  },
  {
    "x": "serdeşîyayî, -ye",
    "y": " taşkın (taşmış olan)"
  },
  {
    "x": "serdeşîyayîş (n)",
    "y": " taşma"
  },
  {
    "x": "serdeşîyayîşê lavî (n)",
    "y": " lav taşması"
  },
  {
    "x": "serdgerm, -e",
    "y": " ılık"
  },
  {
    "x": "serdgerm bîyene",
    "y": " ılıklaşmak"
  },
  {
    "x": "serdgerm kerdene",
    "y": " ılıklaştırmak"
  },
  {
    "x": "serdgermbîyayîş (n)",
    "y": " ılıklaşma"
  },
  {
    "x": "serdgermkerdiş (n)",
    "y": " ılıklaştırma"
  },
  {
    "x": "serdgirewtiş (n)",
    "y": " üşütme"
  },
  {
    "x": "serdgirotiş (n)",
    "y": " b. serdgirewtiş (n)"
  },
  {
    "x": "serdguretis (n)",
    "y": " b. serdgirewtiş (n)"
  },
  {
    "x": "serdguretiş (n)",
    "y": " b. serdgirewtiş (n)"
  },
  {
    "x": "serdgurewtiş (n)",
    "y": " b. serdgirewtiş (n)"
  },
  {
    "x": "serdgurotiş (n)",
    "y": " b. serdgirewtiş (n)"
  },
  {
    "x": "serdin, -e",
    "y": " soğuk, soğumuş olan"
  },
  {
    "x": "serdin bîyene",
    "y": " soğumak"
  },
  {
    "x": "serdin kerdene",
    "y": " soğutmak"
  },
  {
    "x": "serdinkerdiş (n)",
    "y": " soğutma"
  },
  {
    "x": "serdişeyîş (n)",
    "y": " b. serdeşîyayîş (n)"
  },
  {
    "x": "serdişeyîşê lavî (n)",
    "y": " b. serdeşîyayîşê lavî (n)"
  },
  {
    "x": "serdişîyaya (m)",
    "y": " b. serdeşîyaya (m)"
  },
  {
    "x": "serdişîyaye (n)",
    "y": " b. serdeşîyaye (n)"
  },
  {
    "x": "serdişîyayî, -ye",
    "y": " b. serdeşîyayî, -ye"
  },
  {
    "x": "serdkerdiş (n)",
    "y": " 1)soğutma\r\n~2)soğuktan çabuk etkilenme, çabuk üşüme"
  },
  {
    "x": "serdoktor, -e",
    "y": " başdoktor, başhekim"
  },
  {
    "x": "serdoktorîye (m)",
    "y": " başdoktorluk, başhekimlik"
  },
  {
    "x": "serdxane (n)",
    "y": " soğuk hava deposu"
  },
  {
    "x": "sere (n)",
    "y": " 1)baş, kafa\r\n~Sereyê şima weş bo. (Başınız sağ olsun.)\r\n~Sereyê to weş bo. (Başın sağ olsun.)\r\n~ Serî ser! (baş aşağı)\r\n~Serî ser! (Baş üstüne!)\r\n~Serî-çiman ser. (Baş göz üstüne.)\t\r\n~Wa sereyê şima weş bo. (Başınız sağ olsun.)\r\n~Wa sereyê to weş bo. (Başın sağ olsun.)\r\n~2)başlangıç\r\n~3)uç\r\n~sereyê qeleme (m): kalemin ucu\r\n~4)başak\r\n~sere girewtene: başaklanmak\r\n~sereyê genimî (n):  buğday başağı\r\n~sereyê xeleyî (n):  buğday başağı\r\n~5)b. serek (n)"
  },
  {
    "x": "sere birnayene",
    "y": " kesmek (boğazlamak)"
  },
  {
    "x": "sere ci kerdene",
    "y": " kesmek (boğazlamak)"
  },
  {
    "x": "sere kewtene",
    "y": " aklına yatmak, anlamak"
  },
  {
    "x": "sere nêkewtene",
    "y": " aklına yatmamak"
  },
  {
    "x": "sere ronayene",
    "y": " 1)vefat etmek\r\n~2)boyun eğmek, itaat etmek"
  },
  {
    "x": "Sere û çiman ser.",
    "y": " b. Serî-çiman ser."
  },
  {
    "x": "Sere û çimanê mi ser. ",
    "y": "Başım gözüm üstüne."
  },
  {
    "x": "sere vera nayene",
    "y": " çemkirmek, sert cevap vermek"
  },
  {
    "x": "sere wedardayene",
    "y": " b. sere wedaritene"
  },
  {
    "x": "sere wedardene",
    "y": " b. sere wedaritene"
  },
  {
    "x": "sere wedaritene",
    "y": " ayaklanmak, başkaldırmak, isyan etmek\r\n~Şarî sere wedarito./Şarî sere darito we./Şarî îsyan kerdo. (Halk ayaklanmış.)"
  },
  {
    "x": "sere wedartene",
    "y": " b. sere wedaritene"
  },
  {
    "x": "serey xo xo ver cinayene",
    "y": " b. sereyê xo xo ver cenayene"
  },
  {
    "x": "serey xwi ver di şutene",
    "y": " b. sereyê xo ver de şutene"
  },
  {
    "x": "sereyê kergane (n)",
    "y": " çıbanbaşı"
  },
  {
    "x": "sereyê kule (n)",
    "y": " çıbanbaşı"
  },
  {
    "x": "sereyê kunêre (n)",
    "y": " çıbanbaşı"
  },
  {
    "x": "sereyê sûrbine (n)",
    "y": " çıbanbaşı "
  },
  {
    "x": "sereyê xo ronayene",
    "y": " vefat etmek"
  },
  {
    "x": "sereyê xo şutene",
    "y": " 1-(kendi) kafasını yıkamak 2-yıkanmak, banyo yapmak, banyo almak 3-gusül abdesti (aptesi) almak, boy abdesti (aptesi) almak"
  },
  {
    "x": "sereyê xo ver de şutene",
    "y": " (kendi) kafasını yıkamak"
  },
  {
    "x": "sereyê xo xo ver cenayene",
    "y": " utanmak (mecazi)"
  },
  {
    "x": "sereyê xo xo vernayene",
    "y": " utanmak (mecazi)"
  },
  {
    "x": "serê xu şitene",
    "y": " b. sereyê xo şutene"
  },
  {
    "x": "ser sebay (n)",
    "y": " b. serê sibayî (n) "
  },
  {
    "x": "ser sewê (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "ser sibay (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "ser siway (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "ser siwê (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "sere ra",
    "y": " baştan (zr)"
  },
  {
    "x": "serey aşm (n)",
    "y": " b. serê aşme (n)"
  },
  {
    "x": "serey aşmi (n)",
    "y": " b. serê aşme (n)"
  },
  {
    "x": "serey serri (n)",
    "y": " b. sereyê serre (n)"
  },
  {
    "x": "sereyê serre (n)",
    "y": " yılın başı"
  },
  {
    "x": "serê aşme (n)",
    "y": " aybaşı (kadın için), hayız"
  },
  {
    "x": "serê biske (n)",
    "y": " ayrıcalıklı (mecazi), imtiyazlı (mecazi)"
  },
  {
    "x": "serê erdan (n)",
    "y": " arz"
  },
  {
    "x": "serê erdî (n)",
    "y": " arz "
  },
  {
    "x": "serê piske (n)",
    "y": " b. serê biske (n)"
  },
  {
    "x": "serê sebay (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "serê serr (n)",
    "y": " b. sereyê serre (n)"
  },
  {
    "x": "serê serri (n)",
    "y": " b. sereyê serre (n)"
  },
  {
    "x": "serê sibay (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "serê sibayî (n)",
    "y": " sabahleyin "
  },
  {
    "x": "serê sibhay (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "serê siway (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "serê siwey (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "serê sodirî (n)",
    "y": " b. serê şewdirî (n)"
  },
  {
    "x": "serê şewdirî (n)",
    "y": " tan, fecir, şafak"
  },
  {
    "x": "serê şodirî (n)",
    "y": " b. serê şewdirî (n)"
  },
  {
    "x": "serî serri (n)",
    "y": " b. sereyê serre (n)"
  },
  {
    "x": "serî aşm (n)",
    "y": " b. serê aşme (n)"
  },
  {
    "x": "serî sibay (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "serî sibayî (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "serî sivay (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "serî siway (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "serî berdene",
    "y": " üstesinden gelmek"
  },
  {
    "x": "sere arê dayene",
    "y": " taraşlamak, başak toplamak (başak için)"
  },
  {
    "x": "sere girewtene",
    "y": " başaklanmak"
  },
  {
    "x": "sere guretene",
    "y": " b. sere girewtene"
  },
  {
    "x": "serey xeleyî (n)",
    "y": " b. sereyê xeleyî (n)"
  },
  {
    "x": "sereyê genimî (n)",
    "y": " buğday başağı"
  },
  {
    "x": "sereyê xeleyî (n)",
    "y": " buğday başağı"
  },
  {
    "x": "serearêdayîş (n)",
    "y": " taraşlama (başak için)"
  },
  {
    "x": "sereb (n)",
    "y": " b. seref (n)"
  },
  {
    "x": "serebirnayîş (n)",
    "y": " kesme, boğazlama"
  },
  {
    "x": "serebiser nayene",
    "y": " kovalamak"
  },
  {
    "x": "serebisernayîş (n)",
    "y": " kovalama"
  },
  {
    "x": "serebût (n)",
    "y": " olay, fenomen"
  },
  {
    "x": "serebûtê gemarinîya hewayî (n)",
    "y": " hava kirliliği olayı"
  },
  {
    "x": "serebûtê hewanasîye (zh)",
    "y": " meteorolojik olaylar"
  },
  {
    "x": "serebûtê meteorolojîkî (zh)",
    "y": " meteorolojik olaylar"
  },
  {
    "x": "serecêr (n)",
    "y": " b. seracêr (n)"
  },
  {
    "x": "serecikerdiş (n)",
    "y": " kesme, boğazlama"
  },
  {
    "x": "seredej (n)",
    "y": " 1)baş ağrısı\r\n~2)başağrısı, baş belası\r\n~Seredejê ma no xort o. (Bizim başağrımız bu delikanlıdır.)"
  },
  {
    "x": "seredîyayene-I",
    "y": " başaklanmak, başak bağlamak, başak düzmek"
  },
  {
    "x": "seredîyayene-II",
    "y": " kalburlanmak, çalkalanmak, kalburdan geçirilmek"
  },
  {
    "x": "serednayene",
    "y": " kalburlamak, çalkalamak, kalburdan geçirmek"
  },
  {
    "x": "seredyayene",
    "y": " b. seredîyayene"
  },
  {
    "x": "seref (n)",
    "y": " kanal"
  },
  {
    "x": "serefo kiriştox (n)",
    "y": " taşıyıcı kanal"
  },
  {
    "x": "seregirewta (m)",
    "y": " başı bağlı"
  },
  {
    "x": "seregirewtîye (m)",
    "y": " başı bağlı"
  },
  {
    "x": "serehuşk, -e",
    "y": " inatçı, inat"
  },
  {
    "x": "serehuşkî (m)",
    "y": " inatçılık, inat"
  },
  {
    "x": "serehuşkîye (m)",
    "y": " inatçılık, inat"
  },
  {
    "x": "serehuşkî kerdene",
    "y": " inat etmek"
  },
  {
    "x": "serehuşkîye kerdene",
    "y": " inat etmek"
  },
  {
    "x": "serek, -e",
    "y": " 1)başkan, reis, şef\r\n~sereka beledîya (m): belediye başkanı, belediye reisi\r\n~2)başat, baştaki, baskın (başta gelen)\r\n~serekê derdan (n):  dertlerin başta geleni/dertlerin en önemlisi"
  },
  {
    "x": "sereka beledîya (m)",
    "y": " belediye başkanı, belediye reisi"
  },
  {
    "x": "sereka dadgeha cezayanê giranan (m)",
    "y": " ağır ceza mahkemesinin başkanı"
  },
  {
    "x": "sereka dayîreyê îtfaîya",
    "y": " itfaiye daire başkanı"
  },
  {
    "x": "sereka dayîreyî",
    "y": " daire başkanı"
  },
  {
    "x": "sereka mehkemeya cezayanê giranan (m)",
    "y": " ağır ceza mahkemesinin başkanı"
  },
  {
    "x": "sereka pêroyîye (m)",
    "y": " genel başkan"
  },
  {
    "x": "sereka qeza",
    "y": " ilçe başkanı"
  },
  {
    "x": "sereka/serekê beledîyaya şaristanê girdî",
    "y": " anakent belediye başkanı, büyükşehir belediye başkanı"
  },
  {
    "x": "sereka/serekê şaredarîya şaristanê girdî",
    "y": " anakent belediye başkanı, büyükşehir belediye başkanı"
  },
  {
    "x": "sereka şaredarî (m)",
    "y": " belediye başkanı, belediye reisi"
  },
  {
    "x": "serekê beledîya (n)",
    "y": " belediye başkanı, belediye reisi"
  },
  {
    "x": "serekê dadgeha cezayanê giranan (n)",
    "y": " ağır ceza mahkemesinin başkanı"
  },
  {
    "x": "serekê dayîreyê îtfaîya",
    "y": " itfaiye daire başkanı"
  },
  {
    "x": "serekê dayîreyî",
    "y": " daire başkanı"
  },
  {
    "x": "serekê mehkemeya cezayanê giranan (n)",
    "y": " ağır ceza mahkemesinin başkanı"
  },
  {
    "x": "serekê qeza",
    "y": " ilçe başkanı"
  },
  {
    "x": "serekê şaredarî (n)",
    "y": " belediye başkanı, belediye reisi"
  },
  {
    "x": "sereko pêroyî (n)",
    "y": " genel başkan"
  },
  {
    "x": "serekê derdan (n)",
    "y": " dertlerin başta geleni/dertlerin en önemlisi"
  },
  {
    "x": "serekdewlet, -e",
    "y": " devlet başkanı"
  },
  {
    "x": "serekerkan (n)",
    "y": " kurmay başkanı"
  },
  {
    "x": "serekerkanîye (m)",
    "y": " kurmay başkanlığı"
  },
  {
    "x": "serekerkanîya pêroyîye (m)",
    "y": " genel kurmay başkanlığı"
  },
  {
    "x": "serekewtiş (n)",
    "y": " aklına yatma, anlama"
  },
  {
    "x": "serekîye (m)",
    "y": " başkanlık, şeflik"
  },
  {
    "x": "serekîya dayîreyê awa şimitişî",
    "y": " içme suyu dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê awanî û şaristanawanîye",
    "y": " imar ve şehircilik dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê çimeyanê merdiman",
    "y": " insan kaynakları daire başkanlığı"
  },
  {
    "x": "serekîya dayîreyê çimeyanê merdiman û perwerdeyî",
    "y": " insan kaynakları ve eğitim dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê çimeyanê merdiman û xizmetanê destekî",
    "y": " insan kaynakları ve destek hizmetleri dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê hêzdarkerdişî yê ekonomîya herêmî",
    "y": " yerel ekonomiyi güçlendirme daire başkanlığı"
  },
  {
    "x": "serekîya dayîreyê îkmalê malzemeyî û elektrîk-makîna",
    "y": " elektrik makina ve malzeme ikmal dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê îtfaîya",
    "y": " itfaiye dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê kanalîzasyonî",
    "y": " kanalizasyon dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê karanê aboneyîye",
    "y": " abone işleri daire başkanlığı"
  },
  {
    "x": "serekîya dayîreyê karanê fenî",
    "y": " fen işleri dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê karanê malîyan",
    "y": " mali işler dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê karanê weşîye",
    "y": " sağlık işleri dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê kultur û turîzmî",
    "y": " kültür ve turizm dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê nuşte û biran",
    "y": " yazı ve kararlar dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê pawitiş/seveknayîş û kontrolê dorûverî",
    "y": " çevre koruma ve kontrol dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê projeyan",
    "y": " projeler dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê resî",
    "y": " ulaşım dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê sazgeh-pardarîyan",
    "y": " işletme-iştirakler dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê sazgehanê awe",
    "y": " su tesisleri dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê xizmetanê destekî",
    "y": " destek hizmetleri dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê xizmetanê sosyalan",
    "y": " sosyal hizmetler dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê xurtkerdişê çimeyan",
    "y": " kaynak geliştirme dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê xurtkerdişê stratejî",
    "y": " strateji geliştirme dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê zabita",
    "y": " zabıta dairesi başkanlığı"
  },
  {
    "x": "serekîya dayîreyê zanakirarî",
    "y": " bilgiişlem dairesi başkanlığı"
  },
  {
    "x": "serekîya heyetê teftîşî",
    "y": " teftiş kurulu başkanlığı"
  },
  {
    "x": "serekîya komîsyonê îhaleyan",
    "y": " ihale komisyon başkanlığı"
  },
  {
    "x": "serekkomar, -e",
    "y": " cumhurbaşkanı, reisicumhur "
  },
  {
    "x": "serekkomarîye (m)",
    "y": " cumhurbaşkanlığı "
  },
  {
    "x": "serekran (n) (TE)",
    "y": " masaüstü"
  },
  {
    "x": "serekwezîr, -e",
    "y": " başbakan"
  },
  {
    "x": "seremonî (m)",
    "y": " merasim, seremoni, tören"
  },
  {
    "x": "seremonîya abîyayîşî (m)",
    "y": " açılış töreni"
  },
  {
    "x": "seremonîya cenaza (m)",
    "y": " cenaze töreni, cenaze merasimi"
  },
  {
    "x": "seremonîya yadkerdişî (m)",
    "y": " anma töreni"
  },
  {
    "x": "serepêşe (m)",
    "y": " başörtü, başörtüsü"
  },
  {
    "x": "serepîşta (m)",
    "y": " başı bağlı, nişanlı, evli"
  },
  {
    "x": "serepîştîye (m)",
    "y": " başı bağlı, nişanlı, evli"
  },
  {
    "x": "sereqijî (m)",
    "y": " mikrosefali"
  },
  {
    "x": "sereqijîye (m)",
    "y": " mikrosefali"
  },
  {
    "x": "sereqot, -e",
    "y": " b. serqot (n)"
  },
  {
    "x": "sereronayîş (n)",
    "y": " 1)vefat etme\r\n~2)boyun eğme, itaat etme"
  },
  {
    "x": "sereser (n)",
    "y": " başeser, şaheser"
  },
  {
    "x": "seresker, -e",
    "y": " başkumandan, başkomutan"
  },
  {
    "x": "serestiş (n)",
    "y": " b. sereştiş (n)"
  },
  {
    "x": "sereşîyayîne",
    "y": " b. şinasîyayene"
  },
  {
    "x": "sereşîyayîş (n)",
    "y": " b. şinasîyayîş (n)"
  },
  {
    "x": "sereşnaya (m)",
    "y": " b. şinasnaya (m)"
  },
  {
    "x": "sereşnaye (n)",
    "y": " b. şinasnaye (n)"
  },
  {
    "x": "sereşnayîne",
    "y": " b. şinasnayene"
  },
  {
    "x": "sereşnayîş (n)",
    "y": " b. şinasnayîş (n)"
  },
  {
    "x": "sereştiş (n)",
    "y": " baskın"
  },
  {
    "x": "sereûxaşî (zh)",
    "y": "b. serûxaşî (zh)"
  },
  {
    "x": "sereveranayîş (n)",
    "y": " çemkirme, çemkiriş"
  },
  {
    "x": "serewedaritiş (n)",
    "y": " ayaklanma, başkaldırı, başkaldırma, isyan etme"
  },
  {
    "x": "sereweşey (m)",
    "y": " b. serweşîye (m), serweşî (m)"
  },
  {
    "x": "sereweşî (m)",
    "y": " b. serweşîye (m), serweşî (m)"
  },
  {
    "x": "serewişk, -e",
    "y": " b. serehuşk, -e"
  },
  {
    "x": "serewişkey (m)",
    "y": " b. serehuşkî (m), serehuşkîye (m)"
  },
  {
    "x": "serewişkê (m)",
    "y": " b. serehuşkî (m), serehuşkîye (m)"
  },
  {
    "x": "serewişkî (m)",
    "y": " b. serehuşkî (m), serehuşkîye (m)"
  },
  {
    "x": "serewuşk, -i",
    "y": " b. serehuşk, -e"
  },
  {
    "x": "serewuşkê (m)",
    "y": " b. serehuşkî (m), serehuşkîye (m)"
  },
  {
    "x": "serewuşkî (m)",
    "y": " b. serehuşkî (m), serehuşkîye (m)"
  },
  {
    "x": "sereyêxoronayîş (n)",
    "y": " vefat etme"
  },
  {
    "x": "sereyêxoşutiş (n)",
    "y": " 1)yıkanma\r\n~2)(kendi) kafasını yıkama\r\n~3)gusül abesti (aptesi) alma, boy abdesti (aptesi) alma"
  },
  {
    "x": "sereyêxoxovercenayîş (n)",
    "y": " utanma (mecazi)"
  },
  {
    "x": "sereyêxoxovernayîş (n)",
    "y": " utanma (mecazi)"
  },
  {
    "x": "sereyin (n)",
    "y": " başaklı"
  },
  {
    "x": "sereyxoxovercinayîş (n)",
    "y": " b. sereyêxoxovercenayîş (n)"
  },
  {
    "x": "Serê Golan (n)",
    "y": " Gölbaşı"
  },
  {
    "x": "Serê Kanîyê (n)",
    "y": " Ceylanpınar"
  },
  {
    "x": "serên, -e",
    "y": " üstteki, üst taraftaki"
  },
  {
    "x": "serf (n)",
    "y": " sarf, harcama, tüketim"
  },
  {
    "x": "serf bîyene",
    "y": " harcanmak"
  },
  {
    "x": "serf kerdene",
    "y": " harcamak, tüketmek"
  },
  {
    "x": "serfîyat (n)",
    "y": " sarfiyat, harcamalar"
  },
  {
    "x": "serfîyatê elektrîkî (n)",
    "y": " elektrik sarfiyatı"
  },
  {
    "x": "serfbîyayîş (n)",
    "y": " harcanma"
  },
  {
    "x": "serfker, -e",
    "y": " tüketici"
  },
  {
    "x": "serfkerdiş (n)",
    "y": " harcama, tüketme"
  },
  {
    "x": "sergarson (n)",
    "y": " başgarson, şef garson"
  },
  {
    "x": "sergerm, -e",
    "y": " b. serdgerm, -e"
  },
  {
    "x": "sergerm bîyayene",
    "y": " b. serdgerm bîyene"
  },
  {
    "x": "sergerm kerdene",
    "y": " b. serdgerm kerdene"
  },
  {
    "x": "sergermbîyayîş (n)",
    "y": " b. serdgermbîyayîş (n)"
  },
  {
    "x": "sergermkerdiş (n)",
    "y": " b. serdgermkerdiş (n)"
  },
  {
    "x": "sergirewte (n)",
    "y": " zımnî"
  },
  {
    "x": "sergirewtî, -ye",
    "y": " zımnî"
  },
  {
    "x": "sergîn (n)",
    "y": " tezek"
  },
  {
    "x": "sergo (n)",
    "y": " b. silo (n)"
  },
  {
    "x": "sergord, -e",
    "y": " binbaşı"
  },
  {
    "x": "sergordî (m)",
    "y": " binbaşılık"
  },
  {
    "x": "sergordîye (m)",
    "y": " binbaşılık"
  },
  {
    "x": "serhaxam (n)",
    "y": " hahambaşı"
  },
  {
    "x": "serhekîm, -e",
    "y": " başdoktor, başhekim"
  },
  {
    "x": "serhekîmîye (m)",
    "y": " başdoktorluk, başhekimlik"
  },
  {
    "x": "serhemşîra (m)",
    "y": " başhemşire"
  },
  {
    "x": "serheng, -e",
    "y": " albay"
  },
  {
    "x": "serhevsar (n)",
    "y": " yular"
  },
  {
    "x": "serhoye (n, m)",
    "y": " başorakçı, eğnelbaşı"
  },
  {
    "x": "serhoyênî (m)",
    "y": " başorakçılık"
  },
  {
    "x": "serhoyîye (m)",
    "y": " başorakçılık"
  },
  {
    "x": "seri (n)",
    "y": " b. sere (n)"
  },
  {
    "x": "seri birnayene",
    "y": " b. sere birnayene"
  },
  {
    "x": "seridîyayene",
    "y": " b. seredîyayene"
  },
  {
    "x": "seridnayene",
    "y": " b. serednayene"
  },
  {
    "x": "serî berdene",
    "y": " başarmak"
  },
  {
    "x": "serîberda (m)",
    "y": " başarılı"
  },
  {
    "x": "serîberde (n)",
    "y": " başarılı"
  },
  {
    "x": "serîberdiş (n)",
    "y": " başarı, başarma, üstesinden gelme"
  },
  {
    "x": "serîn",
    "y": " b. serên, -e"
  },
  {
    "x": "serîştiş (n)",
    "y": " b. sereştiş (n)"
  },
  {
    "x": "serkar, -e",
    "y": " şef\r\n~serkara/ê roşnî (sn): ışık şefi"
  },
  {
    "x": "serkara/ê roşnî (sn)",
    "y": " ışık şefi"
  },
  {
    "x": "serkarîye (m)",
    "y": " şeflik"
  },
  {
    "x": "serkarîya ameyeyan",
    "y": " gelirler şefliği"
  },
  {
    "x": "serkarîya emlakî (m)",
    "y": " emlak şefliği"
  },
  {
    "x": "serkarîya karanê awe",
    "y": " su işleri şefliği"
  },
  {
    "x": "serkarîya protokolî (m)",
    "y": " protokol şefliği"
  },
  {
    "x": "serkarîya sîgorta",
    "y": " sigorta şefliği"
  },
  {
    "x": "serkarîya tehequqê aboneyîye",
    "y": " abone tahakkuk şefliği"
  },
  {
    "x": "serkarîya trafîkî",
    "y": " trafik şefliği"
  },
  {
    "x": "serkaybaz, -e",
    "y": " başoyuncu"
  },
  {
    "x": "serke (n)",
    "y": " tavan"
  },
  {
    "x": "serkerda (m)",
    "y": " katma"
  },
  {
    "x": "serkerde (n)",
    "y": " katma"
  },
  {
    "x": "serkerdiş-I (n)",
    "y": " katma, sıkıştırma (bir şeyi yapması için sıkıştırma) "
  },
  {
    "x": "serkerdiş-II (n)",
    "y": " b. serokerdiş (n)"
  },
  {
    "x": "serkerdîye (m)",
    "y": " katma"
  },
  {
    "x": "serkeşîş (n)",
    "y": " başrahip"
  },
  {
    "x": "serkewta (m)",
    "y": " başarılı"
  },
  {
    "x": "serkewte (n)",
    "y": " başarılı"
  },
  {
    "x": "serkewtê (m)",
    "y": " b. serkewtî (m)"
  },
  {
    "x": "serkewtiş (n)",
    "y": " 1)alışma\r\n~2)başarma, üstesinde gelme, başarı\r\n~3)utku, yengi, zafer\r\n~Ez to rê serkewtiş wazena. (Sana başarılar dilerim.)"
  },
  {
    "x": "serkewtî (m)",
    "y": " başarılı"
  },
  {
    "x": "serkewtîye (m)",
    "y": " başarılı"
  },
  {
    "x": "serkonsolos, -e",
    "y": " başkonsolos"
  },
  {
    "x": "serkonsolosîye (m)",
    "y": " başkonsolosluk"
  },
  {
    "x": "serkotê (m)",
    "y": " b. serkewtî (m)"
  },
  {
    "x": "serkoti (n)",
    "y": " b. serkewte (n)"
  },
  {
    "x": "serkotiş (n)",
    "y": " b. serkewtiş (n)"
  },
  {
    "x": "serkowtiş (n)",
    "y": " b. serkewtiş (n)"
  },
  {
    "x": "serkunc (n)",
    "y": " başköşe, tör"
  },
  {
    "x": "serm (n)",
    "y": " b. şerm (n)"
  },
  {
    "x": "serm kerdene",
    "y": " b. şerm kerdene"
  },
  {
    "x": "serman (n)",
    "y": " b. sermîyan (n)"
  },
  {
    "x": "sermanşet (n)",
    "y": " sürmanşet"
  },
  {
    "x": "sermayegozar, -e",
    "y": " yatırımcı"
  },
  {
    "x": "sermayegozarî (m)",
    "y": " yatırım"
  },
  {
    "x": "sermayene",
    "y": " b. şermayene"
  },
  {
    "x": "sermayîş (n)",
    "y": " b. şermayîş (n)"
  },
  {
    "x": "serme (n)",
    "y": " b. şerme (n)"
  },
  {
    "x": "sermetîyayene",
    "y": " b. sermitîyayene"
  },
  {
    "x": "sermetîyayîş (n)",
    "y": " b. sermitîyayîş (n)"
  },
  {
    "x": "sermetran (n)",
    "y": " başpiskopos"
  },
  {
    "x": "sermên, -i",
    "y": " b. sermîyan, -e"
  },
  {
    "x": "sermêna keyî (m)",
    "y": " b. sermîyana keyeyî (m) "
  },
  {
    "x": "sermênê keyî (n)",
    "y": " b. sermîyanê keyeyî (n)"
  },
  {
    "x": "sermêndar, -e",
    "y": " b. sermîyandar, -e"
  },
  {
    "x": "sermêndarey (m)",
    "y": " b. sermîyandarîye (m)"
  },
  {
    "x": "sermil (n)",
    "y": " boyunduruğun öküzün boynuna gelen kısmı"
  },
  {
    "x": "sermişawîr, -e",
    "y": " başdanışman"
  },
  {
    "x": "sermişawîrî (m)",
    "y": " başdanışmanlık"
  },
  {
    "x": "sermişawîrîye (m)",
    "y": " başdanışmanlık"
  },
  {
    "x": "sermitikin, -e",
    "y": " kaygan"
  },
  {
    "x": "sermitîyayene",
    "y": " kaymak"
  },
  {
    "x": "sermitîyayîş (n)",
    "y": " kayma"
  },
  {
    "x": "sermitnayene",
    "y": " kaydırmak"
  },
  {
    "x": "sermitnayîş (n)",
    "y": " kaydırma"
  },
  {
    "x": "sermitran (n)",
    "y": " b. sermetran (n)"
  },
  {
    "x": "sermîyan, -e-I",
    "y": " yönetici, idareci"
  },
  {
    "x": "sermîyana berpirsîyare (m)",
    "y": " sorumlu yönetici"
  },
  {
    "x": "sermîyana çeyî (m)",
    "y": " b. sermîyana keyeyî (m) "
  },
  {
    "x": "sermîyana fonksîyonele (m)",
    "y": " işlevsel yönetici"
  },
  {
    "x": "sermîyana keyeyî (m)",
    "y": " aile yöneticisi"
  },
  {
    "x": "sermîyana projeyî (m)",
    "y": " proje yöneticisi"
  },
  {
    "x": "sermîyanê çeyî (n)",
    "y": " b. sermîyanê keyeyî (n)"
  },
  {
    "x": "sermîyanê keyeyî (n)",
    "y": " aile yöneticisi"
  },
  {
    "x": "sermîyanê projeyî (n)",
    "y": " proje yöneticisi"
  },
  {
    "x": "sermîyano berpirsîyar (n)",
    "y": " sorumlu yönetici"
  },
  {
    "x": "sermîyano fonksîyonel (n)",
    "y": " işlevsel yönetici"
  },
  {
    "x": "sermîyan-II (n)",
    "y": " anamal, kapital, sermaye"
  },
  {
    "x": "sermîyandar, -e",
    "y": " anamal sahibi, anamalcı, kapitalist, sermayeci, sermayedar"
  },
  {
    "x": "sermîyandarîye (m)",
    "y": " anamalcılık, kapitalizm, sermayecilik"
  },
  {
    "x": "sermîyayeni",
    "y": " b. şermayene"
  },
  {
    "x": "sermîyayîş (n)",
    "y": " b. şermayîş (n)"
  },
  {
    "x": "sermîyo (n)",
    "y": " b. sermîyan (n)"
  },
  {
    "x": "sermîyon, -i",
    "y": " b. sermîyan, -e"
  },
  {
    "x": "sermîyonê kîyî (n)",
    "y": " b. sermîyanê keyeyî (n) "
  },
  {
    "x": "sermîyoney kîyî (m)",
    "y": " b. sermîyana keyeyî (m) "
  },
  {
    "x": "sermîyû (n)",
    "y": " b. sermîyan (n)"
  },
  {
    "x": "sermîyûn, -i",
    "y": " b. sermîyan, -e "
  },
  {
    "x": "sermîyûn keyî (n)",
    "y": " b. sermîyanê keyeyî (n)"
  },
  {
    "x": "sermîyûna çêyî (m)",
    "y": " b. sermîyana keyeyî (m) "
  },
  {
    "x": "sermîyûnê çêyî (n)",
    "y": " b. sermîyanê keyeyî (n)"
  },
  {
    "x": "sermkerdiş (n)",
    "y": " b. şermkerdiş (n)"
  },
  {
    "x": "sermon (n)",
    "y": " b. sermîyan (n)"
  },
  {
    "x": "sermonek, -e",
    "y": " b. şermonek, -e"
  },
  {
    "x": "sermonek bîyayene",
    "y": " b. şermonek bîyene"
  },
  {
    "x": "sermonekîye (m)",
    "y": " b. şermonekîye (m)"
  },
  {
    "x": "sermusteqîm, -e",
    "y": " vakitsiz (mecazi)"
  },
  {
    "x": "sermûn (n)",
    "y": " b. sermîyan (n)"
  },
  {
    "x": "sernayîş (n)",
    "y": " 1)üzerine koyma\r\n~2)pişirme (çorba vb. için)"
  },
  {
    "x": "sernewe (n)",
    "y": " b. serrnewe (n)"
  },
  {
    "x": "sernêkewta (m)",
    "y": " başarısız"
  },
  {
    "x": "sernêkewte (n)",
    "y": " başarısız"
  },
  {
    "x": "sernêkewtê (m)",
    "y": " b. sernêkewtî (m)"
  },
  {
    "x": "sernêkewtî (m)",
    "y": " başarısız"
  },
  {
    "x": "sernêkewtîye (m)",
    "y": " başarısız"
  },
  {
    "x": "sernêkotê (m)",
    "y": " b. sernêkewtî (m)"
  },
  {
    "x": "sernîyayîş (n)",
    "y": " pişirilme (çorba vb. için)"
  },
  {
    "x": "sernîyekoti (n)",
    "y": " b. sernêkewte (n)"
  },
  {
    "x": "sernuşte (n)",
    "y": " 1)başlık (yazı için), serlevha\r\n~2)antet"
  },
  {
    "x": "sernuşteyê dewamî/domî (n)",
    "y": " devam başlığı"
  },
  {
    "x": "sernuştik (n)",
    "y": " alt başlık"
  },
  {
    "x": "sernuştox, -e",
    "y": " başyazar, başmuharrir, sermuharrir"
  },
  {
    "x": "sernuştoxîye (m)",
    "y": " başyazarlık, başmuharrirlik, sermuharrirlik"
  },
  {
    "x": "sero g’",
    "y": " b. siro ke"
  },
  {
    "x": "sero ki",
    "y": " b. siro ke"
  },
  {
    "x": "sero kerdene",
    "y": " 1)soymak (birinin parasını vb. zorla almak)\r\n~2)soymak (kabuk vb. için), sıyırmak (deri vb. için)"
  },
  {
    "x": "serokerdiş (n)",
    "y": " 1)soyma (birinin parasını vb. zorla alma)\r\n~2)soyma (kabuk vb. için), sıyırma (deri vb. için)"
  },
  {
    "x": "serokerdişê çermî (n)",
    "y": " soyum, peeling"
  },
  {
    "x": "serokerdox, -e",
    "y": " soyguncu"
  },
  {
    "x": "serokerdoxî (m)",
    "y": " soygunculuk"
  },
  {
    "x": "serokerdoxîye (m)",
    "y": " soygunculuk"
  },
  {
    "x": "seromendiş (n)",
    "y": " boynunda kalma"
  },
  {
    "x": "serpala (m)",
    "y": " başorakçı, eğnelbaşı"
  },
  {
    "x": "serpale (n)",
    "y": " başorakçı, eğnelbaşı"
  },
  {
    "x": "serpaletî (m)",
    "y": " başorakçılık"
  },
  {
    "x": "serpaleyey (m)",
    "y": " b. serpaleyî (m)"
  },
  {
    "x": "serpaleyî (m)",
    "y": " başorakçılık"
  },
  {
    "x": "serpalî (m)",
    "y": " b. serpaleyî (m)"
  },
  {
    "x": "serpen (n)",
    "y": " ayakkabı veya çarık tabanına konulan yünden veya kıldan örülmüş parça"
  },
  {
    "x": "serpene (m)",
    "y": " b. serbina (m)"
  },
  {
    "x": "serpes (n)",
    "y": " b. serpez (n)"
  },
  {
    "x": "serpez (n)",
    "y": " dalak"
  },
  {
    "x": "serpên (n)",
    "y": " ayakkabıya içten konan yün, kıl ya da tiftikten yapılma altlık "
  },
  {
    "x": "serpîskopos (n)",
    "y": " başpiskopos"
  },
  {
    "x": "serpolîs, -e",
    "y": " polis şefi"
  },
  {
    "x": "serpund (n)",
    "y": " b. serebût (n)"
  },
  {
    "x": "serpune (n)",
    "y": " b. serbina (m)"
  },
  {
    "x": "serqot (n)",
    "y": " başı açık (kişi)"
  },
  {
    "x": "serr (n)",
    "y": " b. şer-I (n)"
  },
  {
    "x": "serr (m)",
    "y": " b. serre (m)"
  },
  {
    "x": "serra kewtene",
    "y": " (kadınla) cinsel ilişkide bulunmak"
  },
  {
    "x": "serrad (m)",
    "y": " b. seradî (m)"
  },
  {
    "x": "serradî (m)",
    "y": " b. seradî (m)"
  },
  {
    "x": "serraf, -e",
    "y": " b. seraf, -e"
  },
  {
    "x": "serrafey (m)",
    "y": " b. serafîye (m), serafî (m)"
  },
  {
    "x": "serrafê (m)",
    "y": " b. serafîye (m), serafî (m)"
  },
  {
    "x": "serrafî (m)",
    "y": " b. serafî (m)"
  },
  {
    "x": "serrafîye (m)",
    "y": " b. serafîye (m)"
  },
  {
    "x": "serragirewtiş (n)",
    "y": " ağzını arama, ağzını yoklama"
  },
  {
    "x": "serragirotiş (n)",
    "y": " b. serragirewtiş (n)"
  },
  {
    "x": "serrahîb (n)",
    "y": " başrahip"
  },
  {
    "x": "serrakewtiş (n)",
    "y": " (kadınla) cinsel ilişkide bulunma"
  },
  {
    "x": "serrane, -ye",
    "y": " yıllık, senelik, senevi"
  },
  {
    "x": "serrastîvîn, -e",
    "y": " gerçeküstücü, sürrealist"
  },
  {
    "x": "serrastîye (m)",
    "y": " gerçeküstü, sürrealite"
  },
  {
    "x": "serraştîvîn, -e",
    "y": " gerçeküstücü, sürrealist"
  },
  {
    "x": "serraştîye (m)",
    "y": " gerçeküstü, sürrealite"
  },
  {
    "x": "serre (m)",
    "y": " 1)yıl, sene\r\n~serra newîye (m): yeni yıl\r\n~serra derge (m): artık yıl, uzun yıl\r\n~serra 1925./serra hezar û new sey û vîst û pancine: 1925. yıl/bin dokuz yüz yirmi beşinci yıl\r\n~serra 1925î/serra hezar û new sey û vîst û pancî: 1925 yılı/bin dokuz yüz yirmi beş yılı\r\n~serra 1938./serra hezar û new sey û hîris û heştine: 1938. yıl/bin dokuz yüz otuz sekizinci yıl\r\n~serra 1938î/serra hezar û new sey û hîris û heştî: 1938 yılı/bin dokuz yüz otuz sekiz yılı\r\n~2)yaş\r\n~Ti çend serre yî/ya?/Serrê to çend î? (Kaç yaşındasın?)"
  },
  {
    "x": "serra derge (m)",
    "y": " artık yıl, uzun yıl"
  },
  {
    "x": "serra newa (m)",
    "y": " yeni yıl"
  },
  {
    "x": "serra newey (m)",
    "y": " b. serra newîye (m)"
  },
  {
    "x": "serra newê (m)",
    "y": " b. serra newîye (m)"
  },
  {
    "x": "serra newî (m)",
    "y": " b. serra newîye (m)"
  },
  {
    "x": "serra newîye (m)",
    "y": " yeni yıl"
  },
  {
    "x": "serredîyayene",
    "y": " b. seredîyayene"
  },
  {
    "x": "serrednayene",
    "y": " b. serednayene"
  },
  {
    "x": "serreşme (n)",
    "y": " dizgin"
  },
  {
    "x": "serrgêre (m)",
    "y": " yıldönümü\r\n~serrgêra bîyayîşî (m): doğum yıldönümü\r\n~serrgêra bîyayîşê Hesenî: Hasan’ın doğum yıldönümü"
  },
  {
    "x": "serrgêra bîyayîşî (m)",
    "y": " doğum yıldönümü"
  },
  {
    "x": "serrgêra bîyayîşê Hesenî",
    "y": " Hasan’ın doğum yıldönümü"
  },
  {
    "x": "serrgêra mayaxorabîyayîşî (m)",
    "y": " doğum yıldönümü"
  },
  {
    "x": "serrgêra mayaxorabîyayîşê Hesenî",
    "y": " Hasan’ın doğum yıldönümü"
  },
  {
    "x": "serrgêra mergê Hesenî",
    "y": " Hasan’ın ölüm yıldönümü"
  },
  {
    "x": "serri (m)",
    "y": " b. serre (m)"
  },
  {
    "x": "serridîyayene",
    "y": " b. seredîyayene"
  },
  {
    "x": "serridnayene",
    "y": " b. serednayene"
  },
  {
    "x": "serrname (n)",
    "y": " takvim"
  },
  {
    "x": "serrnewe (n)",
    "y": " yılbaşı"
  },
  {
    "x": "serrol (n)",
    "y": " başrol"
  },
  {
    "x": "sersal (n)",
    "y": " b. serrnewe (n)"
  },
  {
    "x": "sersali (m)",
    "y": " b. serrnewe (n)"
  },
  {
    "x": "sersefîr, -e",
    "y": " büyükelçi"
  },
  {
    "x": "serselag (n)",
    "y": " b. serşalage (m)"
  },
  {
    "x": "sersem, -e",
    "y": " sersem, alık"
  },
  {
    "x": "sersem bîyene",
    "y": " sersemleşmek, sersemlemek"
  },
  {
    "x": "sersem kerdene",
    "y": " sersemletmek, sersem etmek"
  },
  {
    "x": "sersemey (m)",
    "y": " b. sersemîye (m)"
  },
  {
    "x": "sersemê (m)",
    "y": " b. sersemî (m)"
  },
  {
    "x": "sersemî (m)",
    "y": " sersemlik, alıklık"
  },
  {
    "x": "sersemîye (m)",
    "y": " sersemlik, alıklık  "
  },
  {
    "x": "serserî, -ye",
    "y": " serseri, başıboş, berduş"
  },
  {
    "x": "serserîtê (m)",
    "y": " b. serserîtî (m)"
  },
  {
    "x": "serserîtî (m)",
    "y": " serserilik"
  },
  {
    "x": "serserîyey (m)",
    "y": " b. serserîyîye (m)"
  },
  {
    "x": "serserîyê (m)",
    "y": " b. serserîyî (m)"
  },
  {
    "x": "serserîyî (m)",
    "y": " serserilik"
  },
  {
    "x": "serserîyîye (m)",
    "y": " serserilik"
  },
  {
    "x": "serserkî",
    "y": " baş aşağı"
  },
  {
    "x": "sersey (m)",
    "y": " b. sersî (m)"
  },
  {
    "x": "sersêw (m)",
    "y": " b. sersî (m)"
  },
  {
    "x": "sersinife (m)",
    "y": " üst sınıf"
  },
  {
    "x": "sersî (m)",
    "y": " gölge"
  },
  {
    "x": "sersîya dare (m)",
    "y": " ağacın gölgesi"
  },
  {
    "x": "serşalage (m)",
    "y": " ağır yük"
  },
  {
    "x": "... serşeyîş (n)",
    "y": " b. ... serşîyayîş (n)"
  },
  {
    "x": "serşêwirmend, -e",
    "y": " başdanışman"
  },
  {
    "x": "serşêwirmendî (m)",
    "y": " başdanışmanlık"
  },
  {
    "x": "serşêwirmendîye (m)",
    "y": " başdanışmanlık"
  },
  {
    "x": "serşîyayîş-I (n)",
    "y": " anlama"
  },
  {
    "x": "... serşîyayîş-II (n)",
    "y": " benzeme; çağrıştırma (andırma)"
  },
  {
    "x": "sert (n)",
    "y": " b. şert (n)"
  },
  {
    "x": "sert cewab dayene",
    "y": " çemkirmek"
  },
  {
    "x": "sertcewabdayîş (n)",
    "y": " çemkirme, çemkiriş"
  },
  {
    "x": "serte (n)",
    "y": " b. şerte (n)"
  },
  {
    "x": "sertekerdiş (n)",
    "y": " b. şertekerdiş (n)"
  },
  {
    "x": "serûbin",
    "y": " allak bullak, altüst"
  },
  {
    "x": "serûbinêxorakerdiş (n)",
    "y": " (mecazi) aşırı harcama"
  },
  {
    "x": "serûbinî (zh)",
    "y": " havale (tıpta)"
  },
  {
    "x": "serûbinî kewtene",
    "y": " havale geçirmek"
  },
  {
    "x": "serûncêr (n)",
    "y": " b. seracêr (n)"
  },
  {
    "x": "serûpa (n)",
    "y": " kelle-paça"
  },
  {
    "x": "serûxaşî (zh)",
    "y": " kelle-paça"
  },
  {
    "x": "serva",
    "y": " b. seba-I, semedê ..."
  },
  {
    "x": "server-I (n)",
    "y": " b. serber (n)"
  },
  {
    "x": "server-II (n) (TE)",
    "y": " sunucu (TE), server (TE)"
  },
  {
    "x": "servet (n)",
    "y": " b. şerbet (n)"
  },
  {
    "x": "servîs (n)",
    "y": " servis"
  },
  {
    "x": "servîsê emlakî (n)",
    "y": " emlak servisi"
  },
  {
    "x": "servîsê hesabdarîye",
    "y": " muhasebe servisi"
  },
  {
    "x": "servîsê karanê awe",
    "y": " su işleri servisi"
  },
  {
    "x": "servîyer (n)",
    "y": " üst geçit"
  },
  {
    "x": "serwan, -e",
    "y": " yüzbaşı"
  },
  {
    "x": "serwanî (m)",
    "y": " yüzbaşılık"
  },
  {
    "x": "serwanîye (m)",
    "y": " yüzbaşılık"
  },
  {
    "x": "serwerdpewj, -e",
    "y": " aşçıbaşı"
  },
  {
    "x": "serwerdpewjîye (m)",
    "y": " aşçıbaşılık"
  },
  {
    "x": "serwesta, -ye",
    "y": " ustabaşı"
  },
  {
    "x": "serweşey (m)",
    "y": " b. serweşîye (m), serweşî (m)"
  },
  {
    "x": "serweşî (m)",
    "y": " başsağlığı"
  },
  {
    "x": "serweşîye (m)",
    "y": " başsağlığı"
  },
  {
    "x": "serxaşî (zh)",
    "y": " b. serûxaşî (zh)"
  },
  {
    "x": "serxos, -e",
    "y": " b. serxoş, e; serxweş, -e"
  },
  {
    "x": "serxoş, -e",
    "y": " sarhoş"
  },
  {
    "x": "serxoş bîyene",
    "y": " sarhoş olmak"
  },
  {
    "x": "serxoş kerdene",
    "y": " sarhoş etmek"
  },
  {
    "x": "serxoşênî (m)",
    "y": " sarhoşluk"
  },
  {
    "x": "serxoşî (m)",
    "y": " sarhoşluk"
  },
  {
    "x": "serxoşîye (m)",
    "y": " sarhoşluk"
  },
  {
    "x": "serxweş, -e",
    "y": " sarhoş"
  },
  {
    "x": "serxweş bîyene",
    "y": " sarhoş olmak"
  },
  {
    "x": "serxweş kerdene",
    "y": " sarhoş etmek"
  },
  {
    "x": "şeş rê se",
    "y": " b. şeş sey"
  },
  {
    "x": "serxweşênî (m)",
    "y": " sarhoşluk"
  },
  {
    "x": "serxweşî (m)",
    "y": " sarhoşluk"
  },
  {
    "x": "serxweşîye (m)",
    "y": " sarhoşluk"
  },
  {
    "x": "ses",
    "y": " b. şeş"
  },
  {
    "x": "ses sey",
    "y": " b. şeş sey"
  },
  {
    "x": "sesadir (n)",
    "y": " b. şeşadir (n)"
  },
  {
    "x": "seserr (m)",
    "y": " b. seserre (m)"
  },
  {
    "x": "seserra (m)",
    "y": " asırlık, yüzyıllık"
  },
  {
    "x": "seserre-I (m)",
    "y": " yüzyıl, asır"
  },
  {
    "x": "seserre-II (n)",
    "y": " asırlık, yüzyıllık"
  },
  {
    "x": "seserrî, -ye",
    "y": " asırlık, yüzyıllık"
  },
  {
    "x": "sest",
    "y": " b. şeştî"
  },
  {
    "x": "sestay",
    "y": " b. şeştî "
  },
  {
    "x": "seş",
    "y": " b. şeş"
  },
  {
    "x": "seşîn, -e",
    "y": " b. şeşin, -e"
  },
  {
    "x": "seştay",
    "y": " b. şeştî "
  },
  {
    "x": "seştî",
    "y": " b. şeştî "
  },
  {
    "x": "set",
    "y": " b. se-I "
  },
  {
    "x": "set (n, sn)",
    "y": " set"
  },
  {
    "x": "setê muzîkî (n)",
    "y": " müzik seti"
  },
  {
    "x": "setê balişnaya hewadare (n)",
    "y": " hava yastığı seti"
  },
  {
    "x": "setar (n)",
    "y": " sik, penis, kamış"
  },
  {
    "x": "setari",
    "y": " b. sitare (m)"
  },
  {
    "x": "setari kerdene",
    "y": " b. sitar kerdene"
  },
  {
    "x": "setarikerdiş (n)",
    "y": " b. sitarkerdiş (n)"
  },
  {
    "x": "setarnayene",
    "y": " b. setirnayene"
  },
  {
    "x": "setarnayîş (n)",
    "y": " b. setirnayîş (n)"
  },
  {
    "x": "seten (n)",
    "y": " saten"
  },
  {
    "x": "setene",
    "y": " b. sentene"
  },
  {
    "x": "seter (n)",
    "y": " b. setre (n)"
  },
  {
    "x": "setere (n)",
    "y": " b. setre (n)"
  },
  {
    "x": "seteri (n)",
    "y": " b. setre (n)"
  },
  {
    "x": "seterîyayene",
    "y": " b. setirîyayene"
  },
  {
    "x": "seterîyayîş (n)",
    "y": " b. setirîyayîş (n)"
  },
  {
    "x": "seternayene",
    "y": " b. setirnayene"
  },
  {
    "x": "seternayîş (n)",
    "y": " b. setirnayîş (n)"
  },
  {
    "x": "setirîyayene",
    "y": " barınmak"
  },
  {
    "x": "setirîyayîş (n)",
    "y": " barınma"
  },
  {
    "x": "setirnayene",
    "y": " barındırmak"
  },
  {
    "x": "setirnayîş (n)",
    "y": " barındırma"
  },
  {
    "x": "setiş (n)",
    "y": " b. sentiş"
  },
  {
    "x": "setr (n)",
    "y": " b. setre (n)"
  },
  {
    "x": "setre (n)",
    "y": " ceket, setre"
  },
  {
    "x": "setreyê hamnanî (n)",
    "y": " yazlık ceket"
  },
  {
    "x": "setrenc (n)",
    "y": " satranç"
  },
  {
    "x": "setri (n)",
    "y": " b. setre (n)"
  },
  {
    "x": "Seûl (n)",
    "y": " Seul"
  },
  {
    "x": "seva",
    "y": " b. seba-I, semedê ..."
  },
  {
    "x": "seva aye ra",
    "y": " b. seba aye ra"
  },
  {
    "x": "seva ey ra",
    "y": " b. seba ey ra"
  },
  {
    "x": "sevat (n)",
    "y": " b. sebat-I (n)"
  },
  {
    "x": "sevata",
    "y": " b. seba-I, semedê ..."
  },
  {
    "x": "sevcêr (n)",
    "y": " b. seracêr (n)"
  },
  {
    "x": "seveknayene",
    "y": " korumak, muhafaza etmek"
  },
  {
    "x": "seveknayîş (n)",
    "y": " 1)koruma, muhafaza etme\r\n~2)gözetim"
  },
  {
    "x": "seveknayox, -e",
    "y": " koruyucu, muhafız"
  },
  {
    "x": "seveknayoxa/seveknayoxê qeraxî",
    "y": " sahil koruma"
  },
  {
    "x": "seveknîyayene",
    "y": " korunmak"
  },
  {
    "x": "seveknîyayîş (n)",
    "y": " korunma"
  },
  {
    "x": "seveknîyayîşê herre (n)",
    "y": " toprak korunması"
  },
  {
    "x": "seveknîyayîşê weşîya şarî (n)",
    "y": " halk sağlığının korunması"
  },
  {
    "x": "sevet",
    "y": " b. semed (n)"
  },
  {
    "x": "seveta",
    "y": " b. seba-I, semedê ..."
  },
  {
    "x": "seveta aye ra",
    "y": " b. semedê aye ra"
  },
  {
    "x": "seveta ey ra",
    "y": " b. semedê ey ra"
  },
  {
    "x": "seveta ... ra",
    "y": " b. semedê ... ra"
  },
  {
    "x": "sevetanê",
    "y": " b. seba-I, semedê ..."
  },
  {
    "x": "sevetanê aye ra",
    "y": " b. semedê aye ra"
  },
  {
    "x": "sevetanê ey ra",
    "y": " b. semedê ey ra"
  },
  {
    "x": "sevetanê ... ra",
    "y": " b. semedê ... ra"
  },
  {
    "x": "sevev (n)",
    "y": " b. sebeb (n)"
  },
  {
    "x": "sevê",
    "y": " b. seba-I"
  },
  {
    "x": "sevêtanê",
    "y": " b. seba-I, semedê ..."
  },
  {
    "x": "sevêtanê aye ra",
    "y": " b. semedê aye ra"
  },
  {
    "x": "sevêtanê ey ra",
    "y": " b. semedê ey ra"
  },
  {
    "x": "sevkan, -e",
    "y": " 1)nişancı\r\n~2)cesaretli, cesur, yürekli, babayiğit"
  },
  {
    "x": "sevkan bîyene",
    "y": " cesaretlenmek"
  },
  {
    "x": "sevkanbîyayîş (n)",
    "y": " cesaretlenme"
  },
  {
    "x": "sevkanênî (m)",
    "y": " b. sevkanîye (m)"
  },
  {
    "x": "sevkanî (m)",
    "y": " cesaretlilik, cesurluk, yüreklilik"
  },
  {
    "x": "sevkanîye (m)",
    "y": " cesaretlilik, cesurluk, yüreklilik, babayiğitlik"
  },
  {
    "x": "sevtanê",
    "y": " b. seba-I, semedê ..."
  },
  {
    "x": "sevtanê aye ra",
    "y": " b. semedê aye ra"
  },
  {
    "x": "sevtanê ey ra",
    "y": " b. semedê ey ra"
  },
  {
    "x": "sevtanê ... ra",
    "y": " b. semedê ... ra "
  },
  {
    "x": "sew-I (m)",
    "y": " b. şewe (m)"
  },
  {
    "x": "sew û roz",
    "y": " b. şew û roj"
  },
  {
    "x": "sew-II",
    "y": " b. eke"
  },
  {
    "x": "sewa-I",
    "y": " b. seba-I, semedê ..."
  },
  {
    "x": "sewa aye ra",
    "y": " b. seba aye ra"
  },
  {
    "x": "sewa ey ra",
    "y": " b. seba ey ra"
  },
  {
    "x": "sewê",
    "y": " b. seba-I, semedê ..."
  },
  {
    "x": "sewê ay’ a",
    "y": " b. seba aye ra"
  },
  {
    "x": "sewê ey a",
    "y": " b. seba ey ra"
  },
  {
    "x": "sewa-II (n)",
    "y": " b. siba (n)"
  },
  {
    "x": "ser sewê (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "sewal (n)",
    "y": " b. sual (n)"
  },
  {
    "x": "sewal kerdene",
    "y": " b. sual kerdene"
  },
  {
    "x": "sewalkerdiş (n)",
    "y": " b. sualkerdiş (n)"
  },
  {
    "x": "sewat",
    "y": " b. semed (n)"
  },
  {
    "x": "sewata",
    "y": " b. seba-I, semedê ..."
  },
  {
    "x": "sewax (n)",
    "y": " b. siwax (n)"
  },
  {
    "x": "sewax kerdene",
    "y": " b. siwax kerdene"
  },
  {
    "x": "sewaxkerdiş",
    "y": " b. siwaxkerdiş"
  },
  {
    "x": "sewbî",
    "y": " b. sewbîn"
  },
  {
    "x": "sewbîn",
    "y": " başka, ayrıca"
  },
  {
    "x": "sewbîna",
    "y": " başka"
  },
  {
    "x": "sewbîna bîyene",
    "y": " başkalaşmak"
  },
  {
    "x": "sewbîna zî",
    "y": " ayrıca"
  },
  {
    "x": "sewbînabîyayîş (n)",
    "y": " başkalaşma"
  },
  {
    "x": "sewbna",
    "y": " b. sewbîna"
  },
  {
    "x": "sewcaxe (m)",
    "y": " savak (değirmen arkındaki fazla suyun akması için açılan ikinci su yolu) "
  },
  {
    "x": "sewe (m)",
    "y": " b. şewe (m)"
  },
  {
    "x": "Sewa to senike bo.",
    "y": " b. Şewa to şenike bo."
  },
  {
    "x": "sewelete (n)",
    "y": " şewlete (n)"
  },
  {
    "x": "sewet",
    "y": " b. semed (n)"
  },
  {
    "x": "seweta",
    "y": " b. seba-I, semedê ..."
  },
  {
    "x": "seweta aye ra",
    "y": " b. semedê aye ra"
  },
  {
    "x": "seweta ey ra",
    "y": " b. semedê ey ra"
  },
  {
    "x": "seweta ... ra",
    "y": " b. semedê ... ra"
  },
  {
    "x": "sewetanê aye ra",
    "y": " b. semedê aye ra"
  },
  {
    "x": "sewetanê ey ra",
    "y": " b. semedê ey ra"
  },
  {
    "x": "sewetanê ... ra",
    "y": " b. semedê ... ra "
  },
  {
    "x": "sewîya (m)",
    "y": " b. sewîye (m)"
  },
  {
    "x": "sewîye (m)",
    "y": " düzey, seviye"
  },
  {
    "x": "sewîyeya ... de",
    "y": " düzeyinde "
  },
  {
    "x": "sewîyeya ... ra",
    "y": " düzeyinde"
  },
  {
    "x": "sewîyepeym (n)",
    "y": " seviyeölçer"
  },
  {
    "x": "sewki",
    "y": " b. eke"
  },
  {
    "x": "sewl (n)",
    "y": " b. sol-I (n)"
  },
  {
    "x": "sewldarz,-i",
    "y": " b. solderz, -e"
  },
  {
    "x": "sewldarzey (m)",
    "y": " b. solderzîye (m), solderzî (m)"
  },
  {
    "x": "sewlderz,-e",
    "y": " b. solderz, -e"
  },
  {
    "x": "sewle (n)",
    "y": " b. şewle (n)"
  },
  {
    "x": "sewle dayene",
    "y": " b. şewle dayene"
  },
  {
    "x": "sewledayîş (n)",
    "y": " b. şewledayîş (n)"
  },
  {
    "x": "sewmîn (m)",
    "y": " b. semînî (m)"
  },
  {
    "x": "sewqa-I (m)",
    "y": " b. şewqa (m)"
  },
  {
    "x": "sewqa-II (m)",
    "y": " b. soqa (m)"
  },
  {
    "x": "sewqe (m)",
    "y": " b. soqa (m)"
  },
  {
    "x": "sewra (n)",
    "y": " b. şewdir (n)"
  },
  {
    "x": "sews bîyene",
    "y": " “akli dengesi” bozulmak (hayvanlar için)  "
  },
  {
    "x": "sewsî piro dayene",
    "y": " “akli dengesi” bozulmak (hayvanlar için)"
  },
  {
    "x": "sewsewike (m)",
    "y": " b. şewşewoke (m)"
  },
  {
    "x": "sewsewuke (m)",
    "y": " b. şewşewoke (m)"
  },
  {
    "x": "sewsey (m)",
    "y": " b. sewsî (m)"
  },
  {
    "x": "sewsî (m)",
    "y": " “akli denge” bozukluğu (özellikle hayvanlar için) "
  },
  {
    "x": "sewta",
    "y": " b. seba-I, semedê ..."
  },
  {
    "x": "sewterî, -ye",
    "y": " b. soterî, -ye"
  },
  {
    "x": "sewz, -e",
    "y": " b. hewz, -e-I"
  },
  {
    "x": "sewze (n)",
    "y": " b. sewzî (n)"
  },
  {
    "x": "sewzi (n)",
    "y": " b. sewzî (n)"
  },
  {
    "x": "sewzî (n)",
    "y": " 1)sebze, göveri, göverti\r\n~2)sebze yemeği"
  },
  {
    "x": "sexênayene",
    "y": " b. seqênayene"
  },
  {
    "x": "sexênayîne",
    "y": " b. xesênayene"
  },
  {
    "x": "sexênayîş (n)",
    "y": " b. xesênayîş (n)"
  },
  {
    "x": "sexênîyayene",
    "y": " b. seqênîyayene"
  },
  {
    "x": "sexênîyayîne",
    "y": " b. xesênîyayene"
  },
  {
    "x": "sexênîyayîş (n)",
    "y": " b. xesênîyayîş (n)"
  },
  {
    "x": "sexî, -ye",
    "y": " cömert"
  },
  {
    "x": "sexînayîne",
    "y": " b. xesênayene"
  },
  {
    "x": "sexînayîş (n)",
    "y": " b. xesênayîş (n)"
  },
  {
    "x": "sexînîyayîne",
    "y": " b. xesênîyayene"
  },
  {
    "x": "sexînîyayîş (n)",
    "y": " b. xesênîyayîş (n)"
  },
  {
    "x": "sexîyey (m)",
    "y": " b. sexîyî (m)"
  },
  {
    "x": "sexîyî (m)",
    "y": " cömert"
  },
  {
    "x": "sexlet (n)",
    "y": " b. sixlet (n)"
  },
  {
    "x": "sexlet bîyayene",
    "y": " b. sixlet bîyane"
  },
  {
    "x": "sexletbîyayîş (n)",
    "y": " b. sixletbîyayîş (n)"
  },
  {
    "x": "sexta (m)",
    "y": " sahte, çakma (argo)"
  },
  {
    "x": "sexte (n)",
    "y": " sahte, çakma (argo)"
  },
  {
    "x": "sextîyan (n)",
    "y": " sahtiyan"
  },
  {
    "x": "sextîyanên, -e",
    "y": " deriden yapılma"
  },
  {
    "x": "sextîyûn (n)",
    "y": " b. sextîyan (n)"
  },
  {
    "x": "sey-I",
    "y": " 1)gibi\r\n~A sey yew vila pulisîyaya bî. (O solgun bir çiçek gibiydi.) \r\n~Ez sey to nîya. (Ben senin gibi değilim.)\r\n~2)kadar \r\n~Ez sey to derg nîya. (Ben senin kadar uzun değilim.) \r\n~3)eşit (mat)"
  },
  {
    "x": "sey boman",
    "y": " delice, çılgınca"
  },
  {
    "x": "sey cewabî",
    "y": " cevaben"
  },
  {
    "x": "sey deluyan",
    "y": " delice, çılgınca"
  },
  {
    "x": "sey domanan",
    "y": " çocukça"
  },
  {
    "x": "sey gedeyan/",
    "y": " çocukça"
  },
  {
    "x": "sey gêjan",
    "y": " delice, çılgınca"
  },
  {
    "x": "sey marî",
    "y": " çıyan gibi (mecazî)"
  },
  {
    "x": "sey qeçekan/qican",
    "y": " çocukça"
  },
  {
    "x": "sey qican",
    "y": " çocukça"
  },
  {
    "x": "sey xêxan",
    "y": " delice, çılgınca"
  },
  {
    "x": "sey xîntan",
    "y": " delice, çılgınca"
  },
  {
    "x": "sey xo kerdene",
    "y": " 1)bildiği gibi yapmak, istediği gibi yapmak, isteğini gerçekleştirmek\r\n~2)(kadınla) cinsel ilişkide bulunmak"
  },
  {
    "x": "sey, -e-II",
    "y": " 1)babasız, yetim\r\n~2)öksüz"
  },
  {
    "x": "sey-III (m)",
    "y": " 1)b. sîye (m) "
  },
  {
    "x": "sey sere ra şîyayene",
    "y": " b. sey sere ra şîyene"
  },
  {
    "x": "sey sere ra şîyene",
    "y": " bayılmak"
  },
  {
    "x": "seya (m)",
    "y": " b. sîye (m)"
  },
  {
    "x": "seyah, -e",
    "y": " gezgin, seyyah"
  },
  {
    "x": "seyan",
    "y": " yüzler (mat) "
  },
  {
    "x": "seyar, -e",
    "y": " gezici, seyyar, portatif"
  },
  {
    "x": "seyd (n)",
    "y": " av"
  },
  {
    "x": "seyd kerdene",
    "y": " 1)avlamak\r\n~2)avlanmak (ava gitmek, ava çıkmak)"
  },
  {
    "x": "seyd şîyene",
    "y": " avlanmak (ava gitmek, ava çıkmak)"
  },
  {
    "x": "seyda (n)",
    "y": " 1)din hocası\r\n~2)hoca, üstat, müderris"
  },
  {
    "x": "seydar",
    "y": " b. seyddar, -e"
  },
  {
    "x": "seydbaz, -e",
    "y": " avcı"
  },
  {
    "x": "seyddar, -e",
    "y": " avcı"
  },
  {
    "x": "seydewan (n)",
    "y": " b. seydwan, -e"
  },
  {
    "x": "seydgeh (n)",
    "y": " avlak"
  },
  {
    "x": "seydkar, -e",
    "y": " avcı"
  },
  {
    "x": "seydkerdiş (n)",
    "y": " avlama"
  },
  {
    "x": "seydon (n)",
    "y": " b. seydwan, -e"
  },
  {
    "x": "seydonçî (n)",
    "y": " b. seydwan, -e"
  },
  {
    "x": "seydvon (n)",
    "y": " b. seydwan, -e"
  },
  {
    "x": "seydwan, -e",
    "y": " avcı"
  },
  {
    "x": "seydwanî (m)",
    "y": " avcılık"
  },
  {
    "x": "seydwon (n)",
    "y": " b. seydwan, -e"
  },
  {
    "x": "seydwûn (n)",
    "y": " b. seydwan, -e"
  },
  {
    "x": "seyek, -e-I",
    "y": " 1)babasız, yetim\r\n~2)öksüz"
  },
  {
    "x": "seyek û sêkur, -e",
    "y": " öksüz"
  },
  {
    "x": "seyek û sêkurîye (m)",
    "y": " öksüzlük"
  },
  {
    "x": "seyek-II (n)",
    "y": " eşitlik işareti (mat)"
  },
  {
    "x": "seyeki (m)",
    "y": " b. seyeke (m)"
  },
  {
    "x": "seyekîye (m)",
    "y": " 1)babasızlık, yetimlik\r\n~2)öksüzlük"
  },
  {
    "x": "seyi (m)",
    "y": " b. sîye (m)"
  },
  {
    "x": "seyin, -e-I",
    "y": " yüzüncü"
  },
  {
    "x": "seyin-II",
    "y": " b. senîn, senî"
  },
  {
    "x": "seyî-I",
    "y": " b. senî"
  },
  {
    "x": "seyî-II (zh)",
    "y": " yüzler (mat)"
  },
  {
    "x": "seyî-III (m)",
    "y": " b. saye-I (m)"
  },
  {
    "x": "seyîd, -e",
    "y": " b. şehîd, -e"
  },
  {
    "x": "seyîn",
    "y": " b. senîn, senî"
  },
  {
    "x": "seyîs, -e",
    "y": " seyis"
  },
  {
    "x": "seyîsî (m)",
    "y": " seyislik"
  },
  {
    "x": "seyîsîye (m)",
    "y": " seyislik"
  },
  {
    "x": "seyîye (m)",
    "y": " eşitlik (mat)"
  },
  {
    "x": "seykur, -i",
    "y": " b. sêkur, -e"
  },
  {
    "x": "seylaw (n)",
    "y": " seylap, feyezan, taşkın (su için)"
  },
  {
    "x": "seypê (m)",
    "y": " pata (oyun için)"
  },
  {
    "x": "seypê mendene",
    "y": " berabere kalmak (oyunda)"
  },
  {
    "x": "seypêkenar",
    "y": " eşkenar\r\n~hîrêgoşeyo seypêkenar (n): eşkenar üçgen"
  },
  {
    "x": "hîrêgoşeyo seypêkenar (n)",
    "y": " eşkenar üçgen"
  },
  {
    "x": "seypêmendiş (n)",
    "y": " berabere kalma (oyun için)"
  },
  {
    "x": "seyr",
    "y": " seyir, bakma"
  },
  {
    "x": "seyr kerdene",
    "y": " bakmak, izlemek, seyretmek, temaşa etmek"
  },
  {
    "x": "seyrbar, -e",
    "y": " izlenebilir (olan)"
  },
  {
    "x": "seyrbarîye (m)",
    "y": " izlenebilirlik"
  },
  {
    "x": "seyrkerdiş (n)",
    "y": " bakma, izleme, seyretme"
  },
  {
    "x": "seyrkerdox, -e",
    "y": " izleyici, seyirci"
  },
  {
    "x": "seysereraşeyîş (n)",
    "y": " b. seysereraşîyayîş (n)"
  },
  {
    "x": "seysereraşîyayîş (n)",
    "y": " bayılma"
  },
  {
    "x": "seyta (n)",
    "y": " b. şeytan (n)"
  },
  {
    "x": "seytan, -e",
    "y": " b. şeytan, -e"
  },
  {
    "x": "seytanî … xapitene",
    "y": " b. şeytanî … xapênayene"
  },
  {
    "x": "seytanî … xapnayene",
    "y": " b. şeytanî … xapênayene"
  },
  {
    "x": "seytanî … xapnayêne",
    "y": " b. şeytanî … xapênayene"
  },
  {
    "x": "seytanîye (m)",
    "y": " b. şeytanî (m), şeytanîye (m)"
  },
  {
    "x": "seyton, -e",
    "y": " b. şeytan, -e "
  },
  {
    "x": "seyxokerdiş (n)",
    "y": " 1)bildiği gibi yapma, istediği gibi yapma, isteğini gerçekleştirme\r\n~2)(kadınla) cinsel ilişkide bulunma"
  },
  {
    "x": "seyyar, -e",
    "y": " b. seyar, -e"
  },
  {
    "x": "seyyewbînan",
    "y": " eşit"
  },
  {
    "x": "seyyewbînanîye (m)",
    "y": " eşitlik"
  },
  {
    "x": "seyyewbînî",
    "y": " eşit"
  },
  {
    "x": "seyyewbînîye (m)",
    "y": " eşitlik"
  },
  {
    "x": "sê-I",
    "y": " b. sey-I, sey-III"
  },
  {
    "x": "sê-II",
    "y": " b. seyr"
  },
  {
    "x": "sê kerdene",
    "y": " b. seyr kerdene"
  },
  {
    "x": "sêbere (m)",
    "y": " üçtaş [oyunu]"
  },
  {
    "x": "sêberi (m)",
    "y": " b. sêbere (m)"
  },
  {
    "x": "sêbur",
    "y": " b. sêwir, -e"
  },
  {
    "x": "sêd (n)",
    "y": " b. seyd (n)"
  },
  {
    "x": "sêda (n)",
    "y": " b. seyda (n)"
  },
  {
    "x": "sêdar (n)",
    "y": " b. seyddar, -e"
  },
  {
    "x": "sêddar",
    "y": " b. seyddar, -e"
  },
  {
    "x": "sêdwon (n)",
    "y": " b. seydwan, -e"
  },
  {
    "x": "sêdwûn (n)",
    "y": " b. seydwan, -e"
  },
  {
    "x": "sêgamî (zh)",
    "y": " üç adım atlama"
  },
  {
    "x": "sêgamî erzîyayîş (n)",
    "y": " üç adım atlama"
  },
  {
    "x": "sêgamî perayîş (n)",
    "y": " üç adım atlama"
  },
  {
    "x": "sêgomî (zh)",
    "y": " b. sêgamî (zh)"
  },
  {
    "x": "sêgomî erzîyayîş (n)",
    "y": " b. sêgamî erzîyayîş (n)"
  },
  {
    "x": "sêgomî erzîyayîş (n)",
    "y": " b. sêgamî erzîyayîş (n)"
  },
  {
    "x": "sêgulî (m)",
    "y": " dirgen, diren (üç dişli dirgen)"
  },
  {
    "x": "sêhr (n)",
    "y": " büyü, sihir, efsun "
  },
  {
    "x": "sêhr kerdene",
    "y": " büyü yapmak, büyülemek, efsunlamak"
  },
  {
    "x": "sêhrbaz, -e",
    "y": " büyücü, sihirbaz, efsuncu, afsuncu"
  },
  {
    "x": "sêhrbazî (m)",
    "y": " büyücülük, efsunculuk, afsunculuk"
  },
  {
    "x": "sêhrbaztî (m)",
    "y": " b. sêhrbazî (m)"
  },
  {
    "x": "sêhrçî, -ye",
    "y": " b. sêhrbaz, -e"
  },
  {
    "x": "sêhrkar, -e",
    "y": " büyülü, efsunkâr"
  },
  {
    "x": "sêhrkerdiş (n)",
    "y": " büyüleme, efsunlama, afsunlama"
  },
  {
    "x": "sêkur, -e",
    "y": " 1)öksüz\r\n~2)babasız, yetim"
  },
  {
    "x": "sêkuri (m)",
    "y": " b. sêkure (m)"
  },
  {
    "x": "sêkurîye (m)",
    "y": " 1)öksüzlük\r\n~2)babasızlık"
  },
  {
    "x": "sêl (n)",
    "y": " sel"
  },
  {
    "x": "sêm (n)",
    "y": " gümüş"
  },
  {
    "x": "sêmê (n)",
    "y": " b. sêmên"
  },
  {
    "x": "sêmên, -e",
    "y": " gümüşten yapılma"
  },
  {
    "x": "sêmin, -e",
    "y": " gümüşlü, gümüşi"
  },
  {
    "x": "sêmîn (n)",
    "y": " b. sêmên"
  },
  {
    "x": "sêmuge (m)",
    "y": " b. şêmûge (m)"
  },
  {
    "x": "sêmzêri (m)",
    "y": " b. simzêre (m)"
  },
  {
    "x": "sêmzi (m)",
    "y": " b. simze (m)"
  },
  {
    "x": "sêne (n)",
    "y": " sine, göğüs"
  },
  {
    "x": "sêng (n)",
    "y": " b. sung (n)"
  },
  {
    "x": "sênî-I (m)",
    "y": " sini"
  },
  {
    "x": "sênîya dîwanî (m)",
    "y": " çok geniş olup üzerinde yemek yemek için de kullanılan bakır sini"
  },
  {
    "x": "sênî-II",
    "y": " b. senî "
  },
  {
    "x": "sênîke (m)",
    "y": " küçük sini"
  },
  {
    "x": "sênîn",
    "y": " b. senîn"
  },
  {
    "x": "sênîye (m)",
    "y": " sini"
  },
  {
    "x": "sênîyeke (m)",
    "y": " küçük sini"
  },
  {
    "x": "sêpare (n)",
    "y": " 1)kahvaltı (sabah yemeği)\r\n~2)öğle yemeği"
  },
  {
    "x": "sêpare werdene",
    "y": " 1)kahvaltı etmek (sabah yemeği yemek)\r\n~2)öğle yemeği yemek"
  },
  {
    "x": "sêpari (n)",
    "y": " b. sêpare (n)"
  },
  {
    "x": "sêpari werdene",
    "y": " b. sêpare werdene"
  },
  {
    "x": "sêpê (m)",
    "y": " b. sêpî (m)"
  },
  {
    "x": "sêpile (m)",
    "y": " üç adım atlama"
  },
  {
    "x": "sêpîyari (n)",
    "y": " b. sêpare (n)"
  },
  {
    "x": "sêpî (m)",
    "y": " sehpa"
  },
  {
    "x": "sêr, -e-I",
    "y": " b. şêr, -e-I"
  },
  {
    "x": "sêr-II",
    "y": " b. seyr"
  },
  {
    "x": "sêr kerdene",
    "y": " b. seyr kerdene"
  },
  {
    "x": "sêr-III",
    "y": " b. serê ... (b. ser [n])"
  },
  {
    "x": "sêr erdî (n)",
    "y": " b. serê erdî (n)"
  },
  {
    "x": "sêr erdûn (n)",
    "y": " b. serê erdan (n)"
  },
  {
    "x": "sêr-IV",
    "y": " b. serê ... (b. sere [n]-2)"
  },
  {
    "x": "sêr sibay (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "sêr sibayî (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "sêr sibey (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "sêr sibê (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "sêr-V (n)",
    "y": " b. sêhr (n)"
  },
  {
    "x": "sêrbaz, -e",
    "y": " b. sêhrbaz, -e"
  },
  {
    "x": "sêrçî (n, m)",
    "y": " b. sêhrbaz, -e"
  },
  {
    "x": "Sêregi (m)",
    "y": " b. Sêwregi (m)"
  },
  {
    "x": "sêrkerdox, -e",
    "y": " b. seyrkerdox, -e"
  },
  {
    "x": "Sêrt (n)",
    "y": " Siirt"
  },
  {
    "x": "sêseme (n)",
    "y": " b. sêşeme (n)"
  },
  {
    "x": "sêşeme (n)",
    "y": " salı "
  },
  {
    "x": "sêşemi (n)",
    "y": " b. sêşeme (n)"
  },
  {
    "x": "sêşime (n)",
    "y": " b. sêşeme (n)"
  },
  {
    "x": "sêvze (m)",
    "y": " b. simze (m)"
  },
  {
    "x": "sêvzêre (m)",
    "y": " b. simzêre (m)"
  },
  {
    "x": "sêwe",
    "y": " b. sêwî, -ye"
  },
  {
    "x": "sêwir, -e",
    "y": " 1)öksüz\r\n~2)basız, yetim"
  },
  {
    "x": "sêwirîye (m)",
    "y": " 1)öksüzlük\r\n~2)babasızlık"
  },
  {
    "x": "sêwî, -ye",
    "y": " 1)öksüz\r\n~2)babasız, yetim"
  },
  {
    "x": "sêwîr, -e",
    "y": " b. sêwir, -e"
  },
  {
    "x": "sêwîyîye (m)",
    "y": " 1)öksüzlük\r\n~2)babasızlık"
  },
  {
    "x": "Sêwregi (m)",
    "y": " Siverek"
  },
  {
    "x": "sêxe (m)",
    "y": " şiş"
  },
  {
    "x": "sêxa camêne (m)",
    "y": " cam şiş"
  },
  {
    "x": "sêxi (m)",
    "y": " b. sêxe (m)"
  },
  {
    "x": "sêyd (n)",
    "y": " b. seyd (n)"
  },
  {
    "x": "sêyda (n)",
    "y": " b. seyda (n)"
  },
  {
    "x": "sêyek, -e",
    "y": " b. seyek, -e"
  },
  {
    "x": "sêyr (n)",
    "y": " b. sêhr (n)"
  },
  {
    "x": "sêyrbaz (n, m)",
    "y": " b. sêhrbaz, -e"
  },
  {
    "x": "sêz (m)",
    "y": " b. sêze (m)"
  },
  {
    "x": "sêze (m)",
    "y": " alıç"
  },
  {
    "x": "sêzêr (m)",
    "y": " b. sêzêre (m)"
  },
  {
    "x": "sêzêre (m)",
    "y": " alıç ağacı"
  },
  {
    "x": "sêzêri (m)",
    "y": " b. sêzêre (m)"
  },
  {
    "x": "sêzi (m)",
    "y": " b. sêze (m)"
  },
  {
    "x": "si",
    "y": " b. senî"
  },
  {
    "x": "siba (n)",
    "y": " 1)sabah\r\n~Sibayê to xeyr bo. (Günaydın.)\r\n~2)yarın"
  },
  {
    "x": "serê sibayî (n)",
    "y": " sabahleyin"
  },
  {
    "x": "sêr sibay (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "sêr sibayî (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "sêr sibey (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "siba ra",
    "y": " sabahleyin"
  },
  {
    "x": "sibat (m)",
    "y": " b. sibate (m)"
  },
  {
    "x": "sibate (m)",
    "y": " şubat "
  },
  {
    "x": "sibateke (m)",
    "y": " kedilerin çiftleşme dönemi"
  },
  {
    "x": "sibateke kerdene",
    "y": " kösnümek (kedi için)"
  },
  {
    "x": "sibatekekerdiş (n)",
    "y": " kösnüme (kedi için)"
  },
  {
    "x": "sibateki (m)",
    "y": " b. sibateke (m)"
  },
  {
    "x": "sibateki kerdene",
    "y": " b. sibateke kerdene"
  },
  {
    "x": "sibatekikerdiş (n)",
    "y": " b. sibatekekerdiş (n)"
  },
  {
    "x": "sibati (m)",
    "y": " b. sibate (m)"
  },
  {
    "x": "sibatok (n)",
    "y": " b. sibateke (m)"
  },
  {
    "x": "sibatok kerdene",
    "y": " b. sibateke kerdene"
  },
  {
    "x": "sibatokkerdiş (n)",
    "y": " b. sibatekekerdiş (n)"
  },
  {
    "x": "sibde (n)",
    "y": " b. sipêde (n)"
  },
  {
    "x": "sibdi (n)",
    "y": " b. sipêde (n)"
  },
  {
    "x": "sibe (n)",
    "y": " b. sipe-I (n)"
  },
  {
    "x": "Sibe (m)",
    "y": " inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "sibêde (n)",
    "y": " b. sipêde (n)"
  },
  {
    "x": "sibha (n)",
    "y": " b. siba (n) "
  },
  {
    "x": "serê sibhay (n)",
    "y": " b. serê sibayî (n) "
  },
  {
    "x": "sibha ra",
    "y": " b. siba ra "
  },
  {
    "x": "sibice (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "sibicin, -e",
    "y": " b. espijin, -e"
  },
  {
    "x": "sibik (m)-II",
    "y": " b. şewte (m)"
  },
  {
    "x": "sic (m)",
    "y": " b. şijî (m)"
  },
  {
    "x": "sicî (m)",
    "y": " b. şijî (m)"
  },
  {
    "x": "sicoq (n)",
    "y": " b. sucuxe (m)"
  },
  {
    "x": "sicûqi (m)",
    "y": " b. sucuxe (m)"
  },
  {
    "x": "siçîyayîş",
    "y": " b. şayîş-I"
  },
  {
    "x": "sideynayîne",
    "y": " b. şidênayene"
  },
  {
    "x": "sideynayîş (n)",
    "y": " b. şidênayîş (n)"
  },
  {
    "x": "sidênayîne",
    "y": " b. şidênayene"
  },
  {
    "x": "sidênayîş (n)",
    "y": " b. şidênayîş (n)"
  },
  {
    "x": "sifaq (n)",
    "y": " b. şefeq (n)"
  },
  {
    "x": "sifarne (n)",
    "y": " b. sifirne (n)"
  },
  {
    "x": "sifarnek (n)",
    "y": " b. sifirne (n)"
  },
  {
    "x": "sifet (n)",
    "y": " sıfat"
  },
  {
    "x": "sifetê dîyarkerdene (n)",
    "y": " belirtme sıfatı "
  },
  {
    "x": "sifetê nedîyarîye (n)",
    "y": " belgisiz sıfat "
  },
  {
    "x": "sifetê nîşankerdene (n)",
    "y": " işaret sıfatı "
  },
  {
    "x": "sifetê persî (n)",
    "y": " soru sıfatı "
  },
  {
    "x": "sifetê pêveronayîşî (n)",
    "y": " karşılaştırma sıfatı"
  },
  {
    "x": "sifetê senînîye (n)",
    "y": " niteleme sıfatı "
  },
  {
    "x": "sifeto pêrabeste (n)",
    "y": " bileşik sıfat "
  },
  {
    "x": "sifeto sade (n)",
    "y": " basit sıfat "
  },
  {
    "x": "sifeto viraşte (n)",
    "y": " türemiş sıfat "
  },
  {
    "x": "sifir-I (n)",
    "y": " bakır"
  },
  {
    "x": "sifir-II (n)",
    "y": " sıfır"
  },
  {
    "x": "sifirên, -e",
    "y": " bakırdan yapılma"
  },
  {
    "x": "sifirin, -e",
    "y": " bakırlı"
  },
  {
    "x": "sifirkar, -e",
    "y": " bakırcı"
  },
  {
    "x": "sifirkarîye (m)",
    "y": " bakırcılık"
  },
  {
    "x": "sifirne (n)",
    "y": " saçak"
  },
  {
    "x": "sifirni (n)",
    "y": " b. sifirne (n)"
  },
  {
    "x": "sifirroş, -e",
    "y": " bakırcı (bakır satan)"
  },
  {
    "x": "sifirroşîye (m)",
    "y": " bakırcılık"
  },
  {
    "x": "sifr (n)",
    "y": " b. sifir-I (n)"
  },
  {
    "x": "sifra (m)",
    "y": " b. sifre (n)"
  },
  {
    "x": "sifre (n)",
    "y": " sofra"
  },
  {
    "x": "sifrên, -e",
    "y": " b. sifirên, -e"
  },
  {
    "x": "sifri (n)",
    "y": " b. sifre (n)"
  },
  {
    "x": "sifrin, -i",
    "y": " b. sifirin, -e"
  },
  {
    "x": "sifrîn, -e",
    "y": " b. sifirên, -e"
  },
  {
    "x": "sift (n)",
    "y": " cumartesi [b. şeme (n)]"
  },
  {
    "x": "sifte",
    "y": " ilkin, başta"
  },
  {
    "x": "sihet (n)",
    "y": " sağlık, sıhhat"
  },
  {
    "x": "sihetê doruverî (m)",
    "y": " çevre sağlığı"
  },
  {
    "x": "sihetî rê zîyandar",
    "y": " sağlığa zararlı"
  },
  {
    "x": "sihete (m)",
    "y": " b. saete (m)"
  },
  {
    "x": "sihhat (n)",
    "y": " b. sihet (n)"
  },
  {
    "x": "sihîr (n)",
    "y": " b. sêhr (n)"
  },
  {
    "x": "sihîrbaz, -e",
    "y": " b. sêhrbaz, -e"
  },
  {
    "x": "sihr (n)",
    "y": " b. sêhr (n)"
  },
  {
    "x": "sihrbaztî (m)",
    "y": " b. sêhrbazî (m "
  },
  {
    "x": "sihûr (n)",
    "y": " b. suhur (n)"
  },
  {
    "x": "sijî (m)",
    "y": " b. şijî (m)"
  },
  {
    "x": "sik (n)",
    "y": " b. şik (n)"
  },
  {
    "x": "sik kerdene",
    "y": " b. şik kerdene"
  },
  {
    "x": "sikite (n)",
    "y": " b. şikite-I (n)"
  },
  {
    "x": "sikitene (n)",
    "y": " b. şikitene"
  },
  {
    "x": "sikitîye (m)",
    "y": " b. şikita (m), şikitîye (m)"
  },
  {
    "x": "sikîyayene",
    "y": " b. şikîyayene"
  },
  {
    "x": "sikre (n)",
    "y": " bent (suyu biriktirmek için önüne yapılan set), set, büğet"
  },
  {
    "x": "sikre bestene",
    "y": " set çekmek"
  },
  {
    "x": "sikrey aw (n)",
    "y": " b. sikreyê awe (n)"
  },
  {
    "x": "sikreyê awe (n)",
    "y": " büğet"
  },
  {
    "x": "sikucî (m)",
    "y": " b. eskije (m)"
  },
  {
    "x": "sikûcî (m)",
    "y": " b. eskije (m)"
  },
  {
    "x": "sil (n)",
    "y": " 1)gübre, kemre (hayvan gübresi)\r\n~2)tezek"
  },
  {
    "x": "silam (n)",
    "y": " b. selam (n)"
  },
  {
    "x": "silamunaleykum",
    "y": " b. selamuneleykum"
  },
  {
    "x": "silamûeleykum",
    "y": " b. selamuneleykum"
  },
  {
    "x": "silape (n)",
    "y": " b. şilope (n)"
  },
  {
    "x": "silasî",
    "y": " b. şinasî, -ye"
  },
  {
    "x": "silasî bîyene",
    "y": " b. şinasî bîyene"
  },
  {
    "x": "silasîyayene",
    "y": " b. şinasîyayene"
  },
  {
    "x": "silasîyayîş (n)",
    "y": " b. şinasîyayîş (n)"
  },
  {
    "x": "silasnaya (m)",
    "y": " b. şinasnaya (m)"
  },
  {
    "x": "silasnaye (n)",
    "y": " b. şinasnaye (n)"
  },
  {
    "x": "silasnayen",
    "y": " b. şinasnayene"
  },
  {
    "x": "silasnayene",
    "y": " b. şinasnayene"
  },
  {
    "x": "silasnayeni",
    "y": " b. şinasnayene"
  },
  {
    "x": "silasnayîş (n)",
    "y": " b. şinasnayîş (n)"
  },
  {
    "x": "silasyayene",
    "y": " b. şinasîyayene"
  },
  {
    "x": "silasyayîş (n)",
    "y": " b. şinasîyayîş (n)"
  },
  {
    "x": "silayî kerdene",
    "y": " davet etmek"
  },
  {
    "x": "silayîbîyaye (n)",
    "y": " davetli"
  },
  {
    "x": "silayîbîyayîye (m)",
    "y": " davetli"
  },
  {
    "x": "silayîkerdiş (n)",
    "y": " davet etme"
  },
  {
    "x": "silayîname (n)",
    "y": " davetname, davetiye"
  },
  {
    "x": "silayîye (m)",
    "y": " davet"
  },
  {
    "x": "silevêre (n)",
    "y": " b. silvêre (n)"
  },
  {
    "x": "sileyine (m)",
    "y": " bulanık su"
  },
  {
    "x": "silêk (n)",
    "y": " döven sürerken öküzün dışkısının tahıl içine düşmemesi için arkasına takılan araç"
  },
  {
    "x": "silgil (n)",
    "y": " b. silgir (n)"
  },
  {
    "x": "silgir (n)",
    "y": " döven sürerken öküzün dışkısının tahıl içine düşmemesi için arkasına takılan araç"
  },
  {
    "x": "silgo (n)",
    "y": " b. silo (n)"
  },
  {
    "x": "silhe (m)",
    "y": " b. surehî (m)"
  },
  {
    "x": "silima (m)",
    "y": " b. silime (m)"
  },
  {
    "x": "silime (m)",
    "y": " 1)basamak\r\n~2)ağaç veya tahtadan yapılmış merdiven"
  },
  {
    "x": "silimi (m)",
    "y": " b. silime (m)"
  },
  {
    "x": "silin, -e",
    "y": " gübreli"
  },
  {
    "x": "silî (m)",
    "y": " b. şilî (m)"
  },
  {
    "x": "silîûsepelî (m)",
    "y": " b. şilîûşepelî (m)"
  },
  {
    "x": "silîûsepelîye (m)",
    "y": " b. şilîûşepelî (m)"
  },
  {
    "x": "silîûsepêl (m)",
    "y": " b. şilîûşepelî (m)"
  },
  {
    "x": "Silîvan (n)",
    "y": " Silvan"
  },
  {
    "x": "silîy (m)",
    "y": " b. şilîye (m)"
  },
  {
    "x": "silîye (m)",
    "y": " b. şilîye (m)"
  },
  {
    "x": "silîyin (n)",
    "y": " b. şilîyin (n)"
  },
  {
    "x": "silma (m)",
    "y": " b. silime (m)"
  },
  {
    "x": "silme",
    "y": " b. sîlme"
  },
  {
    "x": "silo (n)",
    "y": " gübrelik, çöplük"
  },
  {
    "x": "silom (n)",
    "y": " b. selam (n)"
  },
  {
    "x": "silomûeleyk",
    "y": " b. selamuneleykum"
  },
  {
    "x": "silomûeleykum",
    "y": " b. selamuneleykum"
  },
  {
    "x": "silond (n)",
    "y": " gübrelik"
  },
  {
    "x": "silondî (n)",
    "y": " b. silond (n)"
  },
  {
    "x": "silop (n)",
    "y": " büyükbaş hayvanın bir defada yaptığı dışkı topağı "
  },
  {
    "x": "silor (n)",
    "y": " b. şilor (n)"
  },
  {
    "x": "silor dayene",
    "y": " b. şilor dayene"
  },
  {
    "x": "silor girewtene",
    "y": " b. şilor girewtene"
  },
  {
    "x": "siloy (n)",
    "y": " b. silo (n)"
  },
  {
    "x": "silperz (n)",
    "y": " dalak"
  },
  {
    "x": "silq (n)",
    "y": " b. silqe (m)"
  },
  {
    "x": "silqe (m)",
    "y": " şeker pancarı"
  },
  {
    "x": "silqi (m)",
    "y": " b. silqe (m)"
  },
  {
    "x": "silsîle (m)",
    "y": " b. selsela (m)"
  },
  {
    "x": "silule (m)",
    "y": " bir çeşit yabani üzüm"
  },
  {
    "x": "silûm (n)",
    "y": " b. selam (n)"
  },
  {
    "x": "silûmeleyk",
    "y": " b. selamuneleykum"
  },
  {
    "x": "silûmûeleykum",
    "y": " b. selamuneleykum"
  },
  {
    "x": "silvêra (m)",
    "y": " pisboğaz"
  },
  {
    "x": "silvêre (n)",
    "y": " 1)hayvan işkembesi\r\n~2)pisboğaz"
  },
  {
    "x": "silxet (n)",
    "y": " b. sixlet (n)"
  },
  {
    "x": "silxet bîyayene",
    "y": " b. sixlet bîyane"
  },
  {
    "x": "silxetbîyayîş (n)",
    "y": " b. sixletbîyayîş (n)"
  },
  {
    "x": "sim (n)",
    "y": " toynak"
  },
  {
    "x": "SIM (Subscriber Identity Module), (Modulê Nasnameyê Aboneyî)",
    "y": " SIM (Subscriber Identity Module)"
  },
  {
    "x": "SIM-karte (m)",
    "y": " SIM kart"
  },
  {
    "x": "sima",
    "y": " b. şima"
  },
  {
    "x": "simaq (n)",
    "y": " sumak, somak"
  },
  {
    "x": "simatîyayene",
    "y": " b. şemitîyayene"
  },
  {
    "x": "simatîyayîş (n)",
    "y": " b. şemitîyayîş (n)"
  },
  {
    "x": "simax (n)",
    "y": " b. simaq (n)"
  },
  {
    "x": "simbole (n)",
    "y": " b. simore (n) "
  },
  {
    "x": "simbore (n)",
    "y": " b. simore (n) "
  },
  {
    "x": "simer (n)",
    "y": " saman"
  },
  {
    "x": "simerin, -e",
    "y": " samanlı"
  },
  {
    "x": "simerine (m)",
    "y": " samanlık"
  },
  {
    "x": "simeydî (m)",
    "y": " b. simîdî (m)"
  },
  {
    "x": "simêle (m)",
    "y": " b. şimêle (m)"
  },
  {
    "x": "simêlî (zh)",
    "y": " b. zimbêlî (zh)"
  },
  {
    "x": "simêrî (m)",
    "y": " b. simîrî (m)"
  },
  {
    "x": "simik-I (n)",
    "y": " toynak"
  },
  {
    "x": "simik-II (n)",
    "y": " b. şimik-I (n)"
  },
  {
    "x": "simitayini",
    "y": " b. şimitene"
  },
  {
    "x": "simitene",
    "y": " b. şimitene"
  },
  {
    "x": "simîdî (m)",
    "y": " 1)darıdan yapılan bir çeşit çorba veya tatlı\r\n~2)yarma (iri ve gelişigüzel kırılmış tahıl)"
  },
  {
    "x": "simîrî (m)",
    "y": " 1)çok ince bulgur\r\n~2)ince bulgurdan yapılan bir yemek\r\n~3)darıdan yapılan bir çeşit çorba veya tatlı\r\n~4)yarma (iri ve gelişigüzel kırılmış tahıl)\r\n~5)dövülmüş darı"
  },
  {
    "x": "simît (n)",
    "y": " simit"
  },
  {
    "x": "simîti (m)",
    "y": " b. simît (n)"
  },
  {
    "x": "simîtpewj, -e",
    "y": " simitçi (simit yapan)"
  },
  {
    "x": "simîtpewjî (m)",
    "y": " simitçilik"
  },
  {
    "x": "simîtpewjîye (m)",
    "y": " simitçilik"
  },
  {
    "x": "simîtroş, -e",
    "y": " simitçi (simit satan)"
  },
  {
    "x": "simîtroşî (m)",
    "y": " simitçilik"
  },
  {
    "x": "simîtroşîye (m)",
    "y": " simitçilik"
  },
  {
    "x": "simolek (n)",
    "y": " b. simore (n)"
  },
  {
    "x": "simondî (m)",
    "y": " b. şimûndî (m)"
  },
  {
    "x": "simore (n)",
    "y": " sincap "
  },
  {
    "x": "simorik (n)",
    "y": " b. simore (n) "
  },
  {
    "x": "simortkin, -e",
    "y": " b. sermitikin, -e"
  },
  {
    "x": "simotikin, -e",
    "y": " b. şemitikin, -e"
  },
  {
    "x": "simsipe (n)",
    "y": " b. simsipî, -ye"
  },
  {
    "x": "simsipê (m)",
    "y": " b. simsipîye (m)"
  },
  {
    "x": "simsipî, -ye",
    "y": " bembeyaz, apak"
  },
  {
    "x": "simsipîye (m)",
    "y": " bembeyaz, apak"
  },
  {
    "x": "simsipye (m, n)",
    "y": " b. simsipî, -ye"
  },
  {
    "x": "simşêr (n)",
    "y": " b. şimşêr (n)"
  },
  {
    "x": "simteraşe (m)",
    "y": " b. simtiraş (n)"
  },
  {
    "x": "simtiraş (n)",
    "y": " nalbant bıçağı, nalbant keskisi"
  },
  {
    "x": "simûsipî, -ye",
    "y": " bembeyaz, apak"
  },
  {
    "x": "simz (m)",
    "y": " b. simze (m)"
  },
  {
    "x": "simze (m)",
    "y": " alıç"
  },
  {
    "x": "simzêr (m)",
    "y": " b. simzêre (m)"
  },
  {
    "x": "simzêre (m)",
    "y": " alıç ağacı"
  },
  {
    "x": "simzêri (m)",
    "y": " b. simzêre (m)"
  },
  {
    "x": "simzi (m)",
    "y": " b. simze (m)"
  },
  {
    "x": "simzîyer (m)",
    "y": " b. simzêre (m)"
  },
  {
    "x": "simzore (m)",
    "y": " samanlık"
  },
  {
    "x": "simzûri (m)",
    "y": " b. simzore (m)"
  },
  {
    "x": "simzyer (m)",
    "y": " b. simzêre (m)"
  },
  {
    "x": "sinacêr (n)",
    "y": " b. seracêr (n)"
  },
  {
    "x": "sinacîer (n)",
    "y": " b. seracêr (n)"
  },
  {
    "x": "sinacîyer (n)",
    "y": " b. seracêr (n)"
  },
  {
    "x": "sinacyer (n)",
    "y": " b. seracêr (n)"
  },
  {
    "x": "sinasîyayîne",
    "y": " b. şinasîyayene"
  },
  {
    "x": "sinasîyayîş (n)",
    "y": " b. şinasîyayîş (n)"
  },
  {
    "x": "sinasnaya (m)",
    "y": " b. şinasnaya (m)"
  },
  {
    "x": "sinasnaye (n)",
    "y": " b. şinasnaye (n)"
  },
  {
    "x": "sinasnayen",
    "y": " b. şinasnayene"
  },
  {
    "x": "sinasnayîne",
    "y": " b. şinasnayene"
  },
  {
    "x": "sinasnayîş (n)",
    "y": " b. şinasnayîş (n)"
  },
  {
    "x": "sinasyayene",
    "y": " b. şinasîyayene"
  },
  {
    "x": "sinasyayîş (n)",
    "y": " b. şinasîyayîş (n)"
  },
  {
    "x": "sinaşî, -ye",
    "y": " b. şinasî, -ye"
  },
  {
    "x": "sinaşî bîyene",
    "y": " b. şinasî bîyene"
  },
  {
    "x": "sinaşîyayene",
    "y": " b. şinasîyayene"
  },
  {
    "x": "sinaşîyayîş (n)",
    "y": " b. şinasîyayîş (n)"
  },
  {
    "x": "sinaşnaya (m)",
    "y": " b. şinasnaya (m)"
  },
  {
    "x": "sinaşnaye (n)",
    "y": " b. şinasnaye (n)"
  },
  {
    "x": "sinaşnayene",
    "y": " b. şinasnayene"
  },
  {
    "x": "sinaşnayîş (n)",
    "y": " b. şinasnayîş (n)"
  },
  {
    "x": "sinate (m)",
    "y": " b. senet (n)"
  },
  {
    "x": "sinayene",
    "y": " b. şinayene"
  },
  {
    "x": "sinc (n)",
    "y": " yabani iğde ağacı"
  },
  {
    "x": "sincêr (n)",
    "y": " yabani iğde ağacı"
  },
  {
    "x": "sinciqi (m)",
    "y": " b. sucuxe (m)"
  },
  {
    "x": "sinciqê dekardey (zh)",
    "y": " b. sucuxê dekerdeyî (zh)"
  },
  {
    "x": "sincixi (m)",
    "y": " b. sucuxe (m)"
  },
  {
    "x": "sincî (m)",
    "y": " b. şijî (m)"
  },
  {
    "x": "sincoqe (m)",
    "y": " b. sucuxe (m)"
  },
  {
    "x": "sincoqi (m)",
    "y": " b. sucuxe (m)"
  },
  {
    "x": "sincuqi (m)",
    "y": " b. sucuxe (m)"
  },
  {
    "x": "sincûq (m)",
    "y": " b. sucuxe (m)"
  },
  {
    "x": "sincûqe (m)",
    "y": " b. sucuxe (m)"
  },
  {
    "x": "sincûqê dekerdey (zh)",
    "y": " b. sucuxê dekerdeyî (zh)"
  },
  {
    "x": "sincûqê dekerdeyî (zh)",
    "y": " b. sucuxê dekerdeyî (zh)"
  },
  {
    "x": "sincûqi (m)",
    "y": " b. sucuxe (m)"
  },
  {
    "x": "sindan (n)",
    "y": " örs"
  },
  {
    "x": "sindiq (m)",
    "y": " b. sindoqe (m)"
  },
  {
    "x": "sindon (n)",
    "y": " b. sindan (n)"
  },
  {
    "x": "sindoqe (m)",
    "y": " sandık"
  },
  {
    "x": "sindoqi (m)",
    "y": " b. sindoqe (m)"
  },
  {
    "x": "sinet (n)",
    "y": " b. sunet (n)"
  },
  {
    "x": "sinet bîyene",
    "y": " b. sunet bîyene"
  },
  {
    "x": "sinet kerdene",
    "y": " b. sunet kerdene"
  },
  {
    "x": "sinetbîyayi (n)",
    "y": " b. sunetbîyaye (n)"
  },
  {
    "x": "sinetçî, -ye",
    "y": " b. sunetçî, -ye"
  },
  {
    "x": "sinetçîyê (m)",
    "y": " b. sunetçîyî (m)"
  },
  {
    "x": "sinetkerdi (n)",
    "y": " b. sunetkerde (n)"
  },
  {
    "x": "sinetkerdox, -e",
    "y": " b. sunetkerdox, -e"
  },
  {
    "x": "sinetnêbîyayi (n)",
    "y": " sunetnêbîyaye (n)"
  },
  {
    "x": "sinetnêkerdi (n)",
    "y": "b. sunetnêkerde (n)"
  },
  {
    "x": "sing (n)",
    "y": " kazık"
  },
  {
    "x": "singe (m)",
    "y": " b. şinge (m)"
  },
  {
    "x": "singir (n)",
    "y": " b. sunger (n)"
  },
  {
    "x": "sinife (m)",
    "y": " sınıf"
  },
  {
    "x": "sinifa 1.",
    "y": " 1. sınıf"
  },
  {
    "x": "sınıfa binêne (m)",
    "y": " alt sınıf"
  },
  {
    "x": "sinifa burjuwayan (m)",
    "y": " burjuva sınıfı, kentsoylu sınıfı"
  },
  {
    "x": "sinifa cêrêne (m)",
    "y": " alt sınıf"
  },
  {
    "x": "sinifa karkeran (m)",
    "y": " işçi sınıfı"
  },
  {
    "x": "sinifa serêne (m)",
    "y": " üst sınıf"
  },
  {
    "x": "sinit (n)",
    "y": " eğir"
  },
  {
    "x": "sinî",
    "y": " b. senî "
  },
  {
    "x": "sinî, -ye",
    "y": " b. sunî, -ye"
  },
  {
    "x": "sinîn",
    "y": " b. senîn "
  },
  {
    "x": "sinîyê (m)",
    "y": " b. sunîyî (m)"
  },
  {
    "x": "sinye",
    "y": " b. senî "
  },
  {
    "x": "sinyehawa",
    "y": " b. senîhawa"
  },
  {
    "x": "sinyen",
    "y": " b. senîn"
  },
  {
    "x": "sinyewa",
    "y": " b. senîhawa"
  },
  {
    "x": "sinz (n)",
    "y": " b. simzêre (m)"
  },
  {
    "x": "sip û saxlem",
    "y": " sapasağlam"
  },
  {
    "x": "sipar (n)",
    "y": " b. espar (n)"
  },
  {
    "x": "sipas (n)",
    "y": " teşekkür "
  },
  {
    "x": "Ez sipas ken.",
    "y": " b. Ez sipas kena."
  },
  {
    "x": "Ez sipas kena. ",
    "y": "Teşekkür ederim. "
  },
  {
    "x": "Ez sipas keno.",
    "y": " b. Ez sipas kena."
  },
  {
    "x": "sipe, -ye-I",
    "y": " köpek"
  },
  {
    "x": "sipeya nêçîrî (m)",
    "y": " av köpeği"
  },
  {
    "x": "sipeyê nêçîrî (n)",
    "y": " av köpeği"
  },
  {
    "x": "sipeya seydî (m)",
    "y": " av köpeği"
  },
  {
    "x": "sipeyê seydî (n)",
    "y": " av köpeği"
  },
  {
    "x": "sipe-II",
    "y": " b. sipî, -ye-I"
  },
  {
    "x": "sipe jê pulîya vore",
    "y": " b. sipî sey pulîya vewre"
  },
  {
    "x": "sipe zey vawre",
    "y": " b. sipî sey vewre"
  },
  {
    "x": "siped (n)",
    "y": " b. sipêde (n)"
  },
  {
    "x": "sipede (n)",
    "y": " b. sipêde (n)"
  },
  {
    "x": "sipek, -i",
    "y": " b. sipîyek, -e"
  },
  {
    "x": "sipeloç (n)",
    "y": " b. sipîloç, -e"
  },
  {
    "x": "sipelok, -e",
    "y": " b. sipîlok, -e"
  },
  {
    "x": "sipey",
    "y": " b. sipî, -ye-I"
  },
  {
    "x": "sipê",
    "y": " b. sipî, -ye-I"
  },
  {
    "x": "sipêdar (m)",
    "y": " b. sipîdare (m)"
  },
  {
    "x": "sipêde (n)",
    "y": " 1)tan, fecir, şafak\r\n~2)sabah"
  },
  {
    "x": "sipêde ra",
    "y": " sabahleyin, sabahtan"
  },
  {
    "x": "sipêder (n)",
    "y": " b. sipêde (n)"
  },
  {
    "x": "sipêloç, -e",
    "y": " b. sipîloç, -e"
  },
  {
    "x": "sipêloçe (m)",
    "y": " beyazımsı, beyazımtırak"
  },
  {
    "x": "sipêloçek, -e",
    "y": " b. sipîloçek, -e"
  },
  {
    "x": "sipêloçeke (m)",
    "y": " beyazımsı, beyazımtırak"
  },
  {
    "x": "sipêloçeki (m)",
    "y": " b. sipêloçeke (m)"
  },
  {
    "x": "sipêloçi (m)",
    "y": " b. sipêloçe (m)"
  },
  {
    "x": "sipi",
    "y": " b. sipî "
  },
  {
    "x": "sipice (m)",
    "y": " b. espije (m)"
  },
  {
    "x": "sipicin, -e",
    "y": " b. espijin, -e"
  },
  {
    "x": "sipî, -ye-I",
    "y": " beyaz, ak"
  },
  {
    "x": "sipî bîyene",
    "y": " ağarmak, aklaşmak, beyazlaşmak"
  },
  {
    "x": "sipî çimî (m)",
    "y": " b. sipîyê çimî (m)"
  },
  {
    "x": "sipî hezey viliki",
    "y": " b. sipî sey vilike"
  },
  {
    "x": "sipî sey pulîya vewre",
    "y": " kar beyaz"
  },
  {
    "x": "sipî sey vewre",
    "y": " kar beyaz"
  },
  {
    "x": "sipî sey vilike",
    "y": " kar beyaz"
  },
  {
    "x": "sipî zey viliki",
    "y": " b. sipî sey vilike"
  },
  {
    "x": "sipîya vewrine (m)",
    "y": " kar beyaz"
  },
  {
    "x": "sipîyê çimî (m)",
    "y": " gözakı"
  },
  {
    "x": "sipîyê vorîn (m)",
    "y": " b. sipîya vewrine (m)"
  },
  {
    "x": "sipîyo vewrin (n)",
    "y": " kar beyaz"
  },
  {
    "x": "sipîyo vorîn (n)",
    "y": " b. sipîyo vewrin (n"
  },
  {
    "x": "sipî-II (n)",
    "y": " b. sipe-I (n)"
  },
  {
    "x": "sipîbîyayîş (n)",
    "y": " ağarma, aklaşma, beyazlaşma"
  },
  {
    "x": "sipîd",
    "y": " b. sipî"
  },
  {
    "x": "sipîdare (m)",
    "y": " kavak, kalın kavak direği"
  },
  {
    "x": "sipîloç, -e",
    "y": " beyazımsı, beyazımtırak"
  },
  {
    "x": "sipîloçek, -e",
    "y": " beyazımsı, beyazımtırak"
  },
  {
    "x": "sipîlok, -e",
    "y": " beyazımsı, beyazımtırak"
  },
  {
    "x": "sipîtî (m)",
    "y": " aklık"
  },
  {
    "x": "sipîye",
    "y": " b. sipî,-ye"
  },
  {
    "x": "sipîyek, -e",
    "y": " beyazımsı, beyazımtırak"
  },
  {
    "x": "sipîyîye (m)",
    "y": " aklık"
  },
  {
    "x": "siplînge (m)",
    "y": " yelmük (bitkisi)"
  },
  {
    "x": "siplînk (m)",
    "y": " b. siplînge (m)"
  },
  {
    "x": "siploç (n)",
    "y": " b. sipîloç, -e"
  },
  {
    "x": "sipsaxlam, -e",
    "y": " b. sipsaxlem, -e"
  },
  {
    "x": "sipsaxlem, -e",
    "y": " sapasağlam"
  },
  {
    "x": "sipsexlam, -e",
    "y": " b. sipsaxlem, -e"
  },
  {
    "x": "sipsipe (n)",
    "y": " b. sipsipî, -ye"
  },
  {
    "x": "sipsipê (m)",
    "y": " b. sipsipîye (m)"
  },
  {
    "x": "sipsipî, -ye",
    "y": " bembeyaz, apak"
  },
  {
    "x": "sipsipîye (m)",
    "y": " bembeyaz, apak"
  },
  {
    "x": "sipye",
    "y": " b. sipî"
  },
  {
    "x": "siqafil (n)",
    "y": " b. seqavêl (n)"
  },
  {
    "x": "siqbîel (n)",
    "y": " b. suxber (n)"
  },
  {
    "x": "siquling, -e",
    "y": " turna"
  },
  {
    "x": "sir (n)",
    "y": " b. ser (n)"
  },
  {
    "x": "sira (m)",
    "y": " sıra (üzerine oturulan)"
  },
  {
    "x": "sira ra",
    "y": " nöbetleşe, sırayla"
  },
  {
    "x": "sira ra kerdene",
    "y": " nöbetleşe yapmak"
  },
  {
    "x": "sira reyde",
    "y": " nöbetleşe, sırayla"
  },
  {
    "x": "sira reyde kerdene",
    "y": " nöbetleşe yapmak"
  },
  {
    "x": "sira reydi",
    "y": " b. sira reyde"
  },
  {
    "x": "sira reyra",
    "y": " nöbetleşe, sırayla"
  },
  {
    "x": "sira reyra kerdene",
    "y": " nöbetleşe yapmak"
  },
  {
    "x": "siradî (m)",
    "y": " b. seradî (m)"
  },
  {
    "x": "sirasîyayene",
    "y": " b. şinasîyayene"
  },
  {
    "x": "sirasîyayîş (n)",
    "y": " b. şinasîyayîş (n)"
  },
  {
    "x": "sirasnaya (m)",
    "y": " b. şinasnaya (m)"
  },
  {
    "x": "sirasnaye (n)",
    "y": " b. şinasnaye (n)"
  },
  {
    "x": "sirasnayene",
    "y": " b. şinasnayene"
  },
  {
    "x": "sirasnayîş (n)",
    "y": " b. şinasnayîş (n)"
  },
  {
    "x": "siraşîyayene",
    "y": " b. şinasîyayene"
  },
  {
    "x": "siraşîyayîş (n)",
    "y": " b. şinasîyayîş (n)"
  },
  {
    "x": "siraşnaya (m)",
    "y": " b. şinasnaya (m)"
  },
  {
    "x": "siraşnaye (n)",
    "y": " b. şinasnaye (n)"
  },
  {
    "x": "siraşnayene",
    "y": " b. şinasnayene"
  },
  {
    "x": "siraşnayîş (n)",
    "y": " b. şinasnayîş (n)"
  },
  {
    "x": "sire ra",
    "y": " b. sira ra"
  },
  {
    "x": "sire ra kerdene",
    "y": " b. sira ra kerdene"
  },
  {
    "x": "sirf",
    "y": " salt, sırf, sadece, yalnız, yalnızca\r\n~\tYê to sirf qal î, yew havila to ma rê çin a. (Seninki sadece laftır, bize bir yararın yok.)"
  },
  {
    "x": "sirf-safî",
    "y": " safi, salt, sırf, sadece, yalnızca"
  },
  {
    "x": "sirfe (n)",
    "y": " b. sifre (n)"
  },
  {
    "x": "sirfonî (n) ",
    "y": " b. sifirne (n)"
  },
  {
    "x": "sirgulêr (m)",
    "y": " b. sûrgulêre (m)"
  },
  {
    "x": "sirgum (n)",
    "y": " b. surgun (n)"
  },
  {
    "x": "sirgum bîyayene",
    "y": " b. surgun bîyene"
  },
  {
    "x": "sirgum kerdene",
    "y": " b. surgun kerdene"
  },
  {
    "x": "sirgun (n)",
    "y": " b. surgun (n)"
  },
  {
    "x": "sirgun bîyene",
    "y": " b. surgun bîyene"
  },
  {
    "x": "sirgun kardene",
    "y": " b. surgun kerdene"
  },
  {
    "x": "sirgun kerdene",
    "y": " b. surgun kerdene"
  },
  {
    "x": "siring (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "sirke (n)",
    "y": " b. sîrke (n)"
  },
  {
    "x": "sirke degirotene",
    "y": " b. sîrke degirewtene"
  },
  {
    "x": "sirme (n)",
    "y": " sırma"
  },
  {
    "x": "siro ke",
    "y": " -diği zaman, -diği vakit, ne zaman ki, vakta ki"
  },
  {
    "x": "sirot-I (n)",
    "y": " b. suret (n)"
  },
  {
    "x": "sirot-II (n)",
    "y": " b. surot (n)"
  },
  {
    "x": "sirote (m)",
    "y": " b. surote (m)"
  },
  {
    "x": "sirs (m)",
    "y": " b. sirsî (m)"
  },
  {
    "x": "sirsî (m)",
    "y": " saban demiri, karasaban demiri, saban kulağı"
  },
  {
    "x": "sirş (m)",
    "y": " b. sirsî (m)"
  },
  {
    "x": "sirşî (m)",
    "y": " b. sirsî (m)"
  },
  {
    "x": "sirunç (m)",
    "y": " b. surince (m)"
  },
  {
    "x": "sirunç vetene",
    "y": " b. surince vetene"
  },
  {
    "x": "sirutme (n)",
    "y": " b. suritme (n)"
  },
  {
    "x": "Sirûc (n)",
    "y": " Suruç"
  },
  {
    "x": "sisî (m)",
    "y": " b. sirsî (m)"
  },
  {
    "x": "siske (m)",
    "y": " sirke"
  },
  {
    "x": "siske degirewtene",
    "y": " sirke hazırlamak (sirke yapmak)"
  },
  {
    "x": "siski (m)",
    "y": " b. siske (m)"
  },
  {
    "x": "siski degirotene",
    "y": " b. siske degirewtene"
  },
  {
    "x": "sist, -e",
    "y": " 1)yoksul, fakir\r\n~2)cılız (ışık için), gevşek"
  },
  {
    "x": "sistek, -e",
    "y": " gevşek"
  },
  {
    "x": "sisti (m)",
    "y": " b. siste (m)"
  },
  {
    "x": "sisto (n)",
    "y": " gevşek"
  },
  {
    "x": "sit (n)",
    "y": " b. şit (n)"
  },
  {
    "x": "sitare (m)",
    "y": " barınak"
  },
  {
    "x": "sitar kerdene",
    "y": " barındırmak"
  },
  {
    "x": "sitargeh (n)",
    "y": " sığınma evi"
  },
  {
    "x": "sitargehê cinîyan yê şaredarî/beledîya",
    "y": " belediye kadın sığınma evi"
  },
  {
    "x": "sitarkerdiş (n)",
    "y": " barındırma"
  },
  {
    "x": "sitil (n)",
    "y": " kova"
  },
  {
    "x": "sitilê çopî (n)",
    "y": " çöp kovası"
  },
  {
    "x": "sitîl (n)",
    "y": " b. sitil (n)"
  },
  {
    "x": "sitl (n)",
    "y": " b. sitil (n)"
  },
  {
    "x": "siud (n)",
    "y": " b. siûd (n)"
  },
  {
    "x": "siûd (n)",
    "y": " şans, talih\r\n~“Siûdê kesî [ke] çin bi, dindanê kesî pilole di şikîyênê.” (vateyê verênan) (Şanssız kişinin dişleri bulamaç yerken [bile] kırılır.) (atasözü)"
  },
  {
    "x": "siv (m)",
    "y": " b. şewte (m)"
  },
  {
    "x": "siva (n)",
    "y": " b. siba (n)"
  },
  {
    "x": "serî sivay (n)",
    "y": " b. serê sibayî (n) "
  },
  {
    "x": "sivaq (n)",
    "y": " b. şefeq (n)"
  },
  {
    "x": "sivat (m)",
    "y": " b. sibate (m)"
  },
  {
    "x": "sivik, -e-I",
    "y": " 1)hafif\r\n~2)atik, çevik\r\n~3)hızlı, süratli\r\n~4)genç"
  },
  {
    "x": "sivik (m)-II",
    "y": " b. şewte (m)"
  },
  {
    "x": "sivikek, -e",
    "y": " hafifçe"
  },
  {
    "x": "sivikey (m)",
    "y": " b. sivikîye (m)"
  },
  {
    "x": "sivikê (m)",
    "y": " b. sivikî-I (m)"
  },
  {
    "x": "sivikî-I (m)",
    "y": " 1)hafiflik\r\n~2)atiklik, çeviklik\r\n~3)hızlılık"
  },
  {
    "x": "sivikî-II (m)",
    "y": " halay (serçe parmakla tutuşarak oynanan)"
  },
  {
    "x": "sivikîye (m)",
    "y": " 1)hafiflik\r\n~2)atiklik, çeviklik\r\n~3)hızlılık"
  },
  {
    "x": "siving (n)",
    "y": " saçak"
  },
  {
    "x": "sivirnik (n)",
    "y": " b. sifirne (n)"
  },
  {
    "x": "sivote (m)",
    "y": " b. sibate (m)"
  },
  {
    "x": "siwa (n)",
    "y": " b. siba (n)"
  },
  {
    "x": "ser siway (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "ser siwê (n)",
    "y": " b. serê sibayî (n) "
  },
  {
    "x": "serê siwey (n)",
    "y": " b. serê sibayî (n)"
  },
  {
    "x": "siwa ra",
    "y": " b. siba ra"
  },
  {
    "x": "Siwey tû xêr bû.",
    "y": " b. Sibayê to xeyr bo."
  },
  {
    "x": "Siwê tu xêri ib.",
    "y": " b. Sibayê to xeyr bo."
  },
  {
    "x": "siwal (n)",
    "y": " b. sual (n)"
  },
  {
    "x": "siwal kerdene",
    "y": " b. sual kerdene"
  },
  {
    "x": "siwar, -i",
    "y": " b. suwar, -e"
  },
  {
    "x": "siwar bîyayîne",
    "y": " b. suwar bîyene"
  },
  {
    "x": "Siwar (n)",
    "y": " köpek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "siwarbîyayîş (n)",
    "y": " b. suwarbîyayîş (n)"
  },
  {
    "x": "siwarî, -ye",
    "y": " b. suwarî, -ye"
  },
  {
    "x": "siwat (m)",
    "y": " b. sibate (m)"
  },
  {
    "x": "siwax (n)",
    "y": " sıva"
  },
  {
    "x": "siwax kerdene",
    "y": " sıvamak"
  },
  {
    "x": "siwaxkerdiş",
    "y": " sıvama"
  },
  {
    "x": "siwe (n)",
    "y": " buğday kabuğu"
  },
  {
    "x": "siwend (n)",
    "y": " b. sond (n)"
  },
  {
    "x": "sixlet (n)",
    "y": " kalabalık, kalaba"
  },
  {
    "x": "sixlet bîyane",
    "y": " kalabalıklaşmak"
  },
  {
    "x": "sixletbîyayîş (n)",
    "y": " kalabalıklaşma"
  },
  {
    "x": "sixre (n)",
    "y": " b. suxre (n)"
  },
  {
    "x": "sixrekêş, -i",
    "y": " b. suxrekêş, -e"
  },
  {
    "x": "siyar, -e",
    "y": " b. suwarî, -e"
  },
  {
    "x": "siyarî, -ye",
    "y": " b. suwarî, -ye"
  },
  {
    "x": "sizde (n)",
    "y": " b. secade (n)"
  },
  {
    "x": "sizeç (n)",
    "y": " b. suzgeç (n)"
  },
  {
    "x": "sizeynayeni",
    "y": " b. siznayene"
  },
  {
    "x": "sizeynayîş (n)",
    "y": " b. siznayîş (n)"
  },
  {
    "x": "sizêre (m)",
    "y": " b. sêzêre (m)"
  },
  {
    "x": "sizgeç (n)",
    "y": " b. suzgeç (n)"
  },
  {
    "x": "sizgêç (n)",
    "y": " b. suzgeç (n)"
  },
  {
    "x": "sizîyayene",
    "y": " süzülmek (sıvı için)"
  },
  {
    "x": "sizîyayîş (n)",
    "y": " süzülme (sıvı için)"
  },
  {
    "x": "siznayene",
    "y": " süzmek (sıvı için)"
  },
  {
    "x": "siznayeni",
    "y": " b. siznayene"
  },
  {
    "x": "siznayîş (n)",
    "y": " süzme (sıvı için)"
  },
  {
    "x": "sizyayeni",
    "y": " b. sizîyayene"
  },
  {
    "x": "sizyayîş (n)",
    "y": " b. sizîyayîş (n)"
  },
  {
    "x": "sî-I (m)",
    "y": " taş, kaya"
  },
  {
    "x": "sîya kîlometreyan (m)",
    "y": " kilometre taşı"
  },
  {
    "x": "sîya mezele (m)",
    "y": " mezar taşı"
  },
  {
    "x": "sîya mişanî (m)",
    "y": " b. sîya muşeneyî (m)"
  },
  {
    "x": "sîya mişonî (m)",
    "y": " b. sîya muşeneyî (m)"
  },
  {
    "x": "sîya muşeneyî (m)",
    "y": " döven taşı, döven dişi"
  },
  {
    "x": "sîya qewetî (m)",
    "y": " 1)(atletizmde atılan) gülle\r\n~2)gülle atma sporu"
  },
  {
    "x": "sîya qewetî eştene",
    "y": " gülle atmak"
  },
  {
    "x": "sîyê ... eştene",
    "y": " boşamak"
  },
  {
    "x": "sîyê ... eştiş",
    "y": " boşama"
  },
  {
    "x": "sîyê ... vistene",
    "y": " b. sîyê ... eştene"
  },
  {
    "x": "sîyê ... vistiş",
    "y": " b. sîyê ... eştiş"
  },
  {
    "x": "sî-II (m)",
    "y": " b. sîye (m)"
  },
  {
    "x": "sîbig (n)",
    "y": " b. siving (n)"
  },
  {
    "x": "sîcîl (n)",
    "y": " sicil"
  },
  {
    "x": "sîdar (m)",
    "y": " b. sîdare (m)"
  },
  {
    "x": "sîdare (m)",
    "y": " gölge"
  },
  {
    "x": "sîdara dare (m)",
    "y": " ağacın gölgesi"
  },
  {
    "x": "sîdarê dar (m)",
    "y": " b. sîdara dare (m)"
  },
  {
    "x": "sîef",
    "y": " b. sirf"
  },
  {
    "x": "sîemin (n)",
    "y": " b. sêmên"
  },
  {
    "x": "sîend (n)",
    "y": " b. sond (n)"
  },
  {
    "x": "sîef",
    "y": " b. sirf "
  },
  {
    "x": "sîepare (n)",
    "y": " b. sêpare (n)"
  },
  {
    "x": "sîepare werdene",
    "y": " b. sêpare werdene"
  },
  {
    "x": "sîepari (n)",
    "y": " b. sêpare (n)"
  },
  {
    "x": "sîepari werdene",
    "y": " b. sêpare werdene"
  },
  {
    "x": "sîermûn (n)",
    "y": " b. sermîyan (n)"
  },
  {
    "x": "sîermûndar (n)",
    "y": " b. sermîyandar (n)"
  },
  {
    "x": "sîeşembi (n)",
    "y": " b. sêşeme (n)"
  },
  {
    "x": "sîfet (n)",
    "y": " b. sifet (n)"
  },
  {
    "x": "sîgorta (m)",
    "y": " sigorta"
  },
  {
    "x": "sîgortakar, -e",
    "y": " sigortacı"
  },
  {
    "x": "sîgortakarî (m)",
    "y": " sigortacılık"
  },
  {
    "x": "sîgortakarîye (m)",
    "y": " sigortacılık"
  },
  {
    "x": "sîhir (n)",
    "y": " b. sêhr (n)"
  },
  {
    "x": "sîhirbaz, -e",
    "y": " b. sêhrbaz, -e"
  },
  {
    "x": "sîki (n)",
    "y": " b. sing (n)"
  },
  {
    "x": "sîla-I (m)",
    "y": " b. sîle (m)"
  },
  {
    "x": "sîla werdene",
    "y": " b. sîle werdene"
  },
  {
    "x": "sîla-II (m)",
    "y": " b. sîleh (n)"
  },
  {
    "x": "sîlah (m)",
    "y": " b. sîleh (n)"
  },
  {
    "x": "sîlawerdiş (n)",
    "y": " b. sîlewerdiş (n)"
  },
  {
    "x": "sîlbang (n)",
    "y": " b. siving (n)"
  },
  {
    "x": "sîle-I (m)",
    "y": " tokat, sille"
  },
  {
    "x": "sîle werdene",
    "y": " tokat yemek"
  },
  {
    "x": "sîley eştene",
    "y": " b. sîleyî eştene"
  },
  {
    "x": "sîley kerdene",
    "y": " b. sîleyî kerdene"
  },
  {
    "x": "sîley pavistene",
    "y": " b. sîleyî pavistene"
  },
  {
    "x": "sîley piro dayene",
    "y": " b. sîleyî piro dayene"
  },
  {
    "x": "sîleyî eştene",
    "y": " tokatlamak "
  },
  {
    "x": "sîleyî kerdene",
    "y": " tokatlamak"
  },
  {
    "x": "sîleyî pavistene",
    "y": " tokatlamak "
  },
  {
    "x": "sîleyî piro dayene",
    "y": " tokatlamak "
  },
  {
    "x": "sîle-II",
    "y": " b. sîlme"
  },
  {
    "x": "sîleh (n)",
    "y": " silah"
  },
  {
    "x": "sîlehdar, -e",
    "y": " silahlı"
  },
  {
    "x": "sîlewerdiş (n)",
    "y": " tokat yeme"
  },
  {
    "x": "sîleyeştiş (n)",
    "y": " b. sîleyîeştiş (n)"
  },
  {
    "x": "sîleyîeştiş (n)",
    "y": " tokatlama "
  },
  {
    "x": "sîleyîkerdiş (n)",
    "y": " tokatlama "
  },
  {
    "x": "sîleyîpavistiş (n)",
    "y": " tokatlama "
  },
  {
    "x": "sîleyîpirodayîş (n)",
    "y": " tokatlama "
  },
  {
    "x": "sîleykerdiş (n)",
    "y": " b. sîleyîkerdiş (n)"
  },
  {
    "x": "sîleypavistiş (n)",
    "y": " b. sîleyîpavistiş (n)"
  },
  {
    "x": "sîleypirodayîş (n)",
    "y": " b. sîleyîpirodayîş (n)"
  },
  {
    "x": "sîlih (n)",
    "y": " b. sîleh (n)"
  },
  {
    "x": "sîlîndîr (n)",
    "y": " silindir"
  },
  {
    "x": "sîlîûsepelîye (m)",
    "y": " b. şilîûşepelî (m)"
  },
  {
    "x": "sîlîûsipelî (m)",
    "y": " b. şilîûşepelî (m)"
  },
  {
    "x": "sîlîye (m)",
    "y": " b. şilîye (m)"
  },
  {
    "x": "sîlla (m)",
    "y": " b. sîle-I (m)"
  },
  {
    "x": "sîlla werdene",
    "y": " b. sîle werdene"
  },
  {
    "x": "sîllawerdiş (n)",
    "y": " b. sîlewerdiş (n)"
  },
  {
    "x": "sîlle (m)",
    "y": " b. sîle-I (m)"
  },
  {
    "x": "sîlme",
    "y": " dopdolu, ağzına kadar"
  },
  {
    "x": "sîlsîla koyan (m)",
    "y": " b. sîlsîlaya koyan (m)"
  },
  {
    "x": "sîlsîlaya koyan (m)",
    "y": " sıradağlar"
  },
  {
    "x": "sîlsîlê kueyûn (m)",
    "y": " b. sîlsîlaya koyan (m)"
  },
  {
    "x": "sîlvag (n)",
    "y": " b. siving (n)"
  },
  {
    "x": "sîm (n)",
    "y": " b. sêm (n)"
  },
  {
    "x": "sîmetrî (n)",
    "y": " bakışım, simetri"
  },
  {
    "x": "sîmetrîk, -e",
    "y": " bakışımlı, simetrik"
  },
  {
    "x": "sîmên, -e",
    "y": " b. sêmên, -e"
  },
  {
    "x": "sîmin, -e",
    "y": " b. sêmin, -e"
  },
  {
    "x": "Sîmir (n)",
    "y": " Anka kuşu, Zümrüdüanka, Simurg"
  },
  {
    "x": "sîmîdî (m)",
    "y": " b. simîdî (m)"
  },
  {
    "x": "sîmî (n)",
    "y": " b. sêmên"
  },
  {
    "x": "sîmîn (n)",
    "y": " b. sêmên"
  },
  {
    "x": "sîmît (n)",
    "y": " b. simît (n)"
  },
  {
    "x": "sîmîte (m)",
    "y": " b. simît (n)"
  },
  {
    "x": "sîmîti (m)",
    "y": " b. simît (n)"
  },
  {
    "x": "sîmonike (m)",
    "y": " bıldırcın"
  },
  {
    "x": "sîmsar, -e",
    "y": " 1)komisyoncu, kabzımal\r\n~2)simsar"
  },
  {
    "x": "sîmulasyon (n)",
    "y": " simülasyon, öğrence, benzetim"
  },
  {
    "x": "sîmya (m)",
    "y": " alşimi, simya"
  },
  {
    "x": "sîmyager, -e",
    "y": " simyacı, simyager, alşimist"
  },
  {
    "x": "sîmyagerî (m)",
    "y": " simyacılık, simyagerlik"
  },
  {
    "x": "sîmyagerîye (m)",
    "y": " simyacılık, simyagerlik"
  },
  {
    "x": "sîmzêr (m)",
    "y": " b. simzêre (m)"
  },
  {
    "x": "sînaya (m)",
    "y": " sevgili\r\n~waya mina sînaya: sevgili kız kardeşim"
  },
  {
    "x": "sînaye (n)",
    "y": " sevgili\r\n~birayê mino sînaye: sevgili kardeşim"
  },
  {
    "x": "sînayene",
    "y": " sevmek"
  },
  {
    "x": "sînayîş (n)",
    "y": " sevgi, sevme"
  },
  {
    "x": "sînayîye (m)",
    "y": " sevgili\r\n~waya mina sînayîye: sevgili kız kardeşim"
  },
  {
    "x": "sînayox, -e",
    "y": " seven"
  },
  {
    "x": "sînc (m)",
    "y": " b. şijî (m)"
  },
  {
    "x": "sîndor (n)",
    "y": " b. sînor (n)"
  },
  {
    "x": "sîndor bîyayene",
    "y": " b. sînor bîyene"
  },
  {
    "x": "sîndor kerdene",
    "y": " b. sînor kerdene"
  },
  {
    "x": "sîndoro viraşte (n)",
    "y": " b. sînoro viraşte (n)"
  },
  {
    "x": "sîndorbîyayîş (n)",
    "y": " b. sînorbîyayîş (n)"
  },
  {
    "x": "sîndorkerdiş (n)",
    "y": " b. sînorkerdiş (n)"
  },
  {
    "x": "sîndur (n)",
    "y": " b. sînor (n)"
  },
  {
    "x": "sîndûr (n)",
    "y": " b. sînor (n)"
  },
  {
    "x": "sîne (n)",
    "y": " b. sêne (n)"
  },
  {
    "x": "sînefîl, -e",
    "y": " sinema bağımlısı"
  },
  {
    "x": "sînema (m)",
    "y": " sinema"
  },
  {
    "x": "sînema û pêkewtiş",
    "y": " sinema ve çatışma"
  },
  {
    "x": "sînemabest, -e",
    "y": " sinema bağımlısı"
  },
  {
    "x": "sînemadar, -e",
    "y": " sinemacı (sinema işleten)"
  },
  {
    "x": "sînemadarî (m)",
    "y": " sinemacılık"
  },
  {
    "x": "sînemadarîye (m)",
    "y": " sinemacılık"
  },
  {
    "x": "sînemahes, -e",
    "y": " sinemasever"
  },
  {
    "x": "sînemakar, -e",
    "y": " sinemacı (uğraşı sinemacılık olan)"
  },
  {
    "x": "sînemakarî (m)",
    "y": " sinemacılık"
  },
  {
    "x": "sînemakarîye (m)",
    "y": " sinemacılık"
  },
  {
    "x": "sînematografî (m)",
    "y": " sinematografi"
  },
  {
    "x": "sînemaya almanan ya ekspresyonîste",
    "y": " dışavurumcu Alman sineması"
  },
  {
    "x": "sînemaya almanan ya teberdayoxe",
    "y": " dışavurumcu Alman sineması"
  },
  {
    "x": "sînemaya azade (m)",
    "y": " özgür sinema"
  },
  {
    "x": "sînemaya bêvenge (m)",
    "y": " sessiz sinema"
  },
  {
    "x": "sînemaya binerdî (m, sn)",
    "y": " yeraltı sineması"
  },
  {
    "x": "sînemaya pêlê neweyî (sn)",
    "y": " yeni dalga sineması"
  },
  {
    "x": "sînemaya qije (m)",
    "y": " cep sineması"
  },
  {
    "x": "sînemaya rastîvîne/raştîvîne (m)",
    "y": " gerçekçi sinema, realist sinema"
  },
  {
    "x": "sînemaya realîste (m)",
    "y": " gerçekçi sinema, realist sinema"
  },
  {
    "x": "sîneyeyîne",
    "y": " b. sînîyayene"
  },
  {
    "x": "sîneyeyîş (n)",
    "y": " b. sînîyayîş (n) "
  },
  {
    "x": "Sîngapûr (n)",
    "y": " Singapur"
  },
  {
    "x": "sînger (n)",
    "y": " b. sunger (n)"
  },
  {
    "x": "sîngile (m)",
    "y": " siğil"
  },
  {
    "x": "sînitene",
    "y": " sevmek"
  },
  {
    "x": "sînitox, -e",
    "y": " seven"
  },
  {
    "x": "sînî-I (m)",
    "y": " b. sênî-I (m), sênîye (m)"
  },
  {
    "x": "sînîya dîbanî (m)",
    "y": " b. sênîya dîwanî (m)"
  },
  {
    "x": "sînî-II",
    "y": " b. senî"
  },
  {
    "x": "sînîyayene",
    "y": " sevilmek"
  },
  {
    "x": "sînîyayîş (n)",
    "y": " sevilme"
  },
  {
    "x": "sînîye",
    "y": " b. senî"
  },
  {
    "x": "sînopsîs (n)",
    "y": " sinopsis"
  },
  {
    "x": "sînor (n)",
    "y": " sınır, hudut"
  },
  {
    "x": "sînor bîyene",
    "y": " çevrelenmek (mecazî)"
  },
  {
    "x": "sînor kerdene",
    "y": " sınırlamak, kısıtlamak, çevrelemek (mecazî), tahdit etmek"
  },
  {
    "x": "sînorê awanî (n)",
    "y": " imar hududu, imar sınırı"
  },
  {
    "x": "sînorê biserûberkerdişî (n)",
    "y": " düzenleme sınırı"
  },
  {
    "x": "sînorê feqîrîye (n)",
    "y": " yoksulluk sınırı"
  },
  {
    "x": "sînorê îskanî (n)",
    "y": " iskân sınırı"
  },
  {
    "x": "sînorê kiriştişî (n)",
    "y": " taşıma sınırı"
  },
  {
    "x": "sînorê pergalkerdişî (n)",
    "y": " düzenleme sınırı"
  },
  {
    "x": "sînorê teqayîşî yo berz (n) (STB)",
    "y": " üst patlama sınırı (ÜPS)"
  },
  {
    "x": "sînorê vêşanîye (n)",
    "y": " açlık sınırı"
  },
  {
    "x": "sînorê tertîbkerdişî (n)",
    "y": " düzenleme sınırı"
  },
  {
    "x": "sînorê warî (n)",
    "y": " alan sınırı"
  },
  {
    "x": "sînoro tebîî (n)",
    "y": " doğal sınır, tabii sınır"
  },
  {
    "x": "sînoro viraşte (n)",
    "y": " suni sınır, yapma sınır"
  },
  {
    "x": "sînorê xizanî (n)",
    "y": " yoksulluk sınırı"
  },
  {
    "x": "sînoro xozayî (n)",
    "y": " doğal sınır, tabii sınır"
  },
  {
    "x": "sînorbîyayîş (n)",
    "y": " çevrelenme, çevreleniş (mecazî)"
  },
  {
    "x": "sînorkerda (m)",
    "y": " sınırlı, kısıtlı"
  },
  {
    "x": "sînorkerde (n)",
    "y": " sınırlı, kısıtlı"
  },
  {
    "x": "sînorkerdeyî (m)",
    "y": " sınırlılık"
  },
  {
    "x": "sînorkerdê (m)",
    "y": " b. sînorkerda (m), sînorkerdîye (m)"
  },
  {
    "x": "sînorkerdiş (n)",
    "y": " sınırlama, kısıtlama; çevreleme (mecazî)"
  },
  {
    "x": "sînorkerdî, -ye",
    "y": " sınırlı, kısıtlı"
  },
  {
    "x": "sînorkerdîyî (m)",
    "y": " sınırlılık"
  },
  {
    "x": "sînuzît (n)",
    "y": " sinüzit "
  },
  {
    "x": "sînuzîtin, -e",
    "y": " sinüzitli"
  },
  {
    "x": "sînyal (n)",
    "y": " sinyal"
  },
  {
    "x": "sînyalîzasyon (n)",
    "y": " sinyalizasyon"
  },
  {
    "x": "sînz (n)",
    "y": " b. simze (m)"
  },
  {
    "x": "sînzyer (m)",
    "y": " b. simzêre (m)"
  },
  {
    "x": "sîpa (m)",
    "y": " sıpa"
  },
  {
    "x": "sîpe-I (n)",
    "y": " sıpa "
  },
  {
    "x": "sîpe-II (n)",
    "y": " b. sipe-I (n)"
  },
  {
    "x": "sîper (n)",
    "y": " siperlik (şapka için), siper (şapka için)"
  },
  {
    "x": "sîperê şewqa (n)",
    "y": " şapka siperi, şapka siperliği"
  },
  {
    "x": "sîpik (n)",
    "y": " b. sîpe-I (n)"
  },
  {
    "x": "sîpî",
    "y": " b. sipî "
  },
  {
    "x": "sîr (n)",
    "y": " sarmısak"
  },
  {
    "x": "sîr mergûn (n)",
    "y": " b. sîrê mergan (n)"
  },
  {
    "x": "sîrê mergan (n)",
    "y": " yabani sarmısak"
  },
  {
    "x": "sîran (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "sîrawe (m)",
    "y": " sarmısaktan yapılan bir çorba"
  },
  {
    "x": "sîrawi (m)",
    "y": "b. sîrawe (m)"
  },
  {
    "x": "sîrawî (m)",
    "y": " b. sîrawe (m)"
  },
  {
    "x": "sîrayet (n)",
    "y": " bulaşma (hastalık için)"
  },
  {
    "x": "sîrayet kerdene",
    "y": " bulaşmak (hastalık için)"
  },
  {
    "x": "sîrayetkerdiş (n)",
    "y": " bulaşma (hastalık için)"
  },
  {
    "x": "sîren (n)",
    "y": " siren"
  },
  {
    "x": "sîrez (n)",
    "y": " b. şîrêz (n)"
  },
  {
    "x": "sîrêz (n)",
    "y": " b. şîrêz (n)"
  },
  {
    "x": "sîrij (n)",
    "y": " b. şîrêz (n)"
  },
  {
    "x": "sîrim (n)",
    "y": " yabani sarmısak"
  },
  {
    "x": "sîrin, -e",
    "y": " sarmısaklı"
  },
  {
    "x": "sîrîng (n)",
    "y": " b. siring (n)"
  },
  {
    "x": "sîrke (n)",
    "y": " sirke"
  },
  {
    "x": "sîrke degirewtene",
    "y": " sirke hazırlamak (sirke yapmak)"
  },
  {
    "x": "sîrke (m)",
    "y": " b. sîrke (n)"
  },
  {
    "x": "sîrku (n)",
    "y": " sirkü"
  },
  {
    "x": "sîrkuyê îmza (n)",
    "y": " imza sirküsü"
  },
  {
    "x": "sîrkuler (n)",
    "y": " duyurum, genelge, tamim, sirküler"
  },
  {
    "x": "sîrkut (n)",
    "y": " sarmısak vb. yiyecek maddelerini ezmekte kullanılan küçük tokmak "
  },
  {
    "x": "sîrkutik (n)",
    "y": " sarmısak vb. yiyecek maddelerini ezmekte kullanılan küçük tokmak"
  },
  {
    "x": "sîrmok (n)",
    "y": " yabani sarmısak"
  },
  {
    "x": "sîroz (n)",
    "y": " siroz"
  },
  {
    "x": "sîrozin, –e",
    "y": " sirozlu"
  },
  {
    "x": "sîrs (m)",
    "y": " b. sirsî (m)"
  },
  {
    "x": "sîrsi (m)",
    "y": " b. sirsî (m)"
  },
  {
    "x": "sîrûn (n)",
    "y": " b. sîran (n)"
  },
  {
    "x": "sîs, -e",
    "y": " beyaz, ak; bembeyaz, apak"
  },
  {
    "x": "Sîs (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "Sîse (m)",
    "y": " inek ve keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "Sîsi (m)",
    "y": " b. Sîse (m)"
  },
  {
    "x": "sîsik, -e",
    "y": " bembeyaz, apak"
  },
  {
    "x": "sîsperike (m)",
    "y": " kelebek"
  },
  {
    "x": "sîstem (n)",
    "y": " düzen, sistem"
  },
  {
    "x": "sîstem konfîgurasyon",
    "y": " sistem konfigürasyon, sistem yapılandırması"
  },
  {
    "x": "sîstemê alarmê şewatî (n)",
    "y": " yangın alarm sistemi"
  },
  {
    "x": "sîstemê awe (n)",
    "y": " su sistemi"
  },
  {
    "x": "sîstemê borîya biawe (n)",
    "y": " sulu boru sistemi"
  },
  {
    "x": "sîstemê kontrolê trafîkî (n)",
    "y": " trafik kontrol sistemi"
  },
  {
    "x": "sîstemê kontrolî yê elektrîkinî (n)",
    "y": " elektrikli denetim sistemi"
  },
  {
    "x": "sîstemê lulîya biawe (n)",
    "y": " sulu boru sistemi"
  },
  {
    "x": "sîstemê serekîye (n)",
    "y": " başkanlık sistemi"
  },
  {
    "x": "sîstemê sprînklerê hîtî (n)",
    "y": " ıslak sprinkler sistemi (itfaiye için)"
  },
  {
    "x": "sîstemê zanayîşanê cografîkan",
    "y": " coğrafi bilgiler sistemi (n)"
  },
  {
    "x": "sîstemê zanayîşî yê bajarî (n)",
    "y": " kent bilgi sistemi"
  },
  {
    "x": "sîstemo asinrayîrin (n)",
    "y": " raylı sistem"
  },
  {
    "x": "sîstemo rayin (n)",
    "y": " raylı sistem"
  },
  {
    "x": "sîstemo têmîyanek (n)",
    "y": " karma düzen"
  },
  {
    "x": "sîstemîravejîyayîş (n)",
    "y": " sistemden çıkış"
  },
  {
    "x": "sîstre (m)",
    "y": " sistre"
  },
  {
    "x": "sît (n)",
    "y": " (kalıntı için) sit"
  },
  {
    "x": "sîto arkeolojîk (n)",
    "y": " arkeolojik sit"
  },
  {
    "x": "sîto şaristankî (n)",
    "y": " kentsel sit"
  },
  {
    "x": "sîto tarîxî (n)",
    "y": " tarihi sit"
  },
  {
    "x": "sîtil (n)",
    "y": " b. sitil (n)"
  },
  {
    "x": "sîvig (n)",
    "y": " b. siving (n)"
  },
  {
    "x": "sîving (n)",
    "y": " b. siving (n)"
  },
  {
    "x": "sîvîg (n)",
    "y": " b. siving (n)"
  },
  {
    "x": "sîvîl, -e",
    "y": " sivil "
  },
  {
    "x": "sîvîlîye (m)",
    "y": " sivillik "
  },
  {
    "x": "sîvîlîzasyon (n)",
    "y": " uygarlık, medeniyet"
  },
  {
    "x": "sîvoke (m)",
    "y": " ayak bileği"
  },
  {
    "x": "Sîvrîce",
    "y": " Sivrice "
  },
  {
    "x": "sîwane (m)",
    "y": " şemsiye"
  },
  {
    "x": "sîwanroş, -e",
    "y": " şemsiyeci"
  },
  {
    "x": "sîwax (n)",
    "y": " b. siwax (n)"
  },
  {
    "x": "sîwî",
    "y": " b. sêwî, -ye"
  },
  {
    "x": "sîxi (m)",
    "y": " b. xêze (m)"
  },
  {
    "x": "sîy-I (m)",
    "y": " b. sîye (m)"
  },
  {
    "x": "sîy-II (m)",
    "y": " b. sî-I (m)"
  },
  {
    "x": "sîya, -ye",
    "y": " siyah, kara"
  },
  {
    "x": "sîya û sipî",
    "y": " alaca (siyah-beyaz renkli)"
  },
  {
    "x": "sîyaboç (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "sîyadber (n)",
    "y": " b. seyddar, -e"
  },
  {
    "x": "sîyakerge (m)",
    "y": " karatavuk (kuşu) "
  },
  {
    "x": "sîyar, -e",
    "y": " b. suwarî, -e"
  },
  {
    "x": "sîyarî, -ye",
    "y": " b. suwarî, -ye"
  },
  {
    "x": "sîyaset (n)",
    "y": " politika, siyasa, siyaset"
  },
  {
    "x": "sîyasetê bingeyênî (zh)",
    "y": " temel politikalar"
  },
  {
    "x": "sîyasetmedar, -e",
    "y": " siyasacı, siyasetçi, politikacı"
  },
  {
    "x": "sîyasetmedarî (m)",
    "y": " siyasacılık, siyasetçilik, politikacılık"
  },
  {
    "x": "sîyasetmedarîye (m)",
    "y": " siyasacılık, siyasetçilik, politikacılık"
  },
  {
    "x": "sîyasî, -ye",
    "y": " politik, siyasi, siyasal"
  },
  {
    "x": "sîyd (n)",
    "y": " b. seyd (n)"
  },
  {
    "x": "sîye (m)",
    "y": " gölge"
  },
  {
    "x": "sîya dare (m)",
    "y": " ağacın gölgesi"
  },
  {
    "x": "sîyenayîş (n)",
    "y": " b. sernayîş (n)"
  },
  {
    "x": "sîyene (n)",
    "y": " b. sêne (n)"
  },
  {
    "x": "sîyer-I",
    "y": " b. seyr"
  },
  {
    "x": "sîyer-II (n)",
    "y": " b. sêhr (n)"
  },
  {
    "x": "sîyer-III (n)",
    "y": " b. ser-I (n)"
  },
  {
    "x": "sîyerbaz (n, m)",
    "y": " b. sêhrbaz, -e"
  },
  {
    "x": "sîyerkerdiş (n)",
    "y": " b. seyrkerdiş (n)"
  },
  {
    "x": "Sîyerra Leone (m)",
    "y": " Sierra Leone"
  },
  {
    "x": "sîyi (m)",
    "y": " b. sî-I (m)"
  },
  {
    "x": "sîyin, -e",
    "y": " taşlı"
  },
  {
    "x": "sîyoke (m)",
    "y": " böğürtlen"
  },
  {
    "x": "skandal (n)",
    "y": " skandal"
  },
  {
    "x": "skanner (n) (TE)",
    "y": " tarayıcı"
  },
  {
    "x": "skeç (n)",
    "y": " skeç "
  },
  {
    "x": "skî (m)",
    "y": " kayak, ski"
  },
  {
    "x": "skucî (m)",
    "y": " b. eskije (m)"
  },
  {
    "x": "skûcî (m)",
    "y": " b. eskije (m)"
  },
  {
    "x": "Slovakya (m)",
    "y": " Slovakya"
  },
  {
    "x": "Slovenya (m)",
    "y": " Slovenya"
  },
  {
    "x": "smokîn (n)",
    "y": " smokin"
  },
  {
    "x": "so kerdene",
    "y": " doğmak (yıldız için)\r\n~Estareyî so kerd. (Yıldız doğdu.)"
  },
  {
    "x": "soba (m)",
    "y": " soba\r\n~sobaya gazî (m): gaz sobası"
  },
  {
    "x": "sobaya gazî (m) ",
    "y": " gaz sobası"
  },
  {
    "x": "sobaya gazyaxî (m)",
    "y": " gaz sobası"
  },
  {
    "x": "sobaya komirî (m)",
    "y": " kömür sobası"
  },
  {
    "x": "sobe-I (n)",
    "y": " ebe (ebe oyunu) (m)"
  },
  {
    "x": "sobe-II (n)",
    "y": " b. soba (m)"
  },
  {
    "x": "sobeberî (m)",
    "y": " yüzme"
  },
  {
    "x": "sobeberî kerdene",
    "y": " yüzmek, çimmek"
  },
  {
    "x": "sobeberîkerdiş (n)",
    "y": " yüzme, çimme"
  },
  {
    "x": "sobî",
    "y": " b. sewbîn"
  },
  {
    "x": "sobîna",
    "y": " b. sewbîna"
  },
  {
    "x": "sobna",
    "y": " b. sewbîna"
  },
  {
    "x": "soci (m)",
    "y": " b. sacî (m)"
  },
  {
    "x": "socnayîş (n)",
    "y": " yakma"
  },
  {
    "x": "socnayene",
    "y": " yakmak"
  },
  {
    "x": "soder (n)",
    "y": " b. şewdir (n)"
  },
  {
    "x": "sodir (n)",
    "y": " b. şewdir (n)"
  },
  {
    "x": "serê sodirî (n)",
    "y": " b. serê şewdirî (n)"
  },
  {
    "x": "sodir a",
    "y": " b. şewdir ra "
  },
  {
    "x": "Sodir bixêr.",
    "y": " b. Şewdir bixeyr."
  },
  {
    "x": "soe-I (m)",
    "y": " b. saye (m)"
  },
  {
    "x": "soe-II (m)",
    "y": " b. şewe (m)"
  },
  {
    "x": "sofer (n)",
    "y": " b. şofor (n)"
  },
  {
    "x": "sofî, -ye",
    "y": " 1)sofu\r\n~2)aşık kemiğiyle oynanan bir oyunda bu kemiğin eni geniş olan iki tarafından kısmen düz olanı"
  },
  {
    "x": "sofîn (n)",
    "y": " b. sefîne (m)"
  },
  {
    "x": "sofre (n)",
    "y": " b. sifre (n)"
  },
  {
    "x": "Sofya (m)",
    "y": " Sofya"
  },
  {
    "x": "soj (m)",
    "y": " b. sacî (m)"
  },
  {
    "x": "sojî (m)",
    "y": " b. sacî (m)"
  },
  {
    "x": "sol-I (n)",
    "y": " ayakkabı, pabuç, başmak"
  },
  {
    "x": "solê mewsimî (zh)",
    "y": " mevsimlik ayakkabı"
  },
  {
    "x": "solê sporî (n)",
    "y": " spor ayakkabısı"
  },
  {
    "x": "sol-II (m)",
    "y": " b. sole-I (m)"
  },
  {
    "x": "sol bîyayene",
    "y": " b. sole bîyene"
  },
  {
    "x": "sol kerdene",
    "y": " b. sole kerdene"
  },
  {
    "x": "sol pira şanayene",
    "y": " b. sole pira şanayene"
  },
  {
    "x": "sol piro şanayene",
    "y": " b. sole pira şanayene"
  },
  {
    "x": "sola",
    "y": " b. înşalah"
  },
  {
    "x": "solav (n)",
    "y": " b. solawe (m)"
  },
  {
    "x": "solaw (m)",
    "y": " b. solawe (m)"
  },
  {
    "x": "solawe (m)",
    "y": " 1)tuzlu su\r\n~2)turşu"
  },
  {
    "x": "solawi (m)",
    "y": " b. solawe (m)"
  },
  {
    "x": "solawî (m)",
    "y": " b. solawe (m)"
  },
  {
    "x": "solbîyayîş (n)",
    "y": " b. solebîyayîş (n)"
  },
  {
    "x": "solboyaxker, -e",
    "y": " ayakkabı boyacısı"
  },
  {
    "x": "solca (n)",
    "y": " b. soleca (n)"
  },
  {
    "x": "soldane (m)",
    "y": " b. soledane (m)"
  },
  {
    "x": "soldarz, -i",
    "y": " b. solderz, -e"
  },
  {
    "x": "solderz, -e",
    "y": " 1)ayakkabı yapımcısı (ayakkabı diken)\r\n~2)ayakkabıcı (ayakkabı yapan ya da satan), pabuççu, başmakçı"
  },
  {
    "x": "solderzey (m)",
    "y": "b. solderzîye (m), solderzî (m)"
  },
  {
    "x": "solderzê (m)",
    "y": " b. solderzîye (m), solderzî (m)"
  },
  {
    "x": "solderzî (m)",
    "y": " ayakkabıcılık, pabuççuluk"
  },
  {
    "x": "solderzîye (m)",
    "y": " ayakkabıcılık, pabuççuluk"
  },
  {
    "x": "sole-I (m)",
    "y": " tuz"
  },
  {
    "x": "sola mergî (m)",
    "y": " aşırı tuzlu"
  },
  {
    "x": "sola sure (m)",
    "y": " b. sola sûre (m)"
  },
  {
    "x": "sola sûre (m)",
    "y": " aşırı tuzlu"
  },
  {
    "x": "sole bîyene",
    "y": " tuzlanmak"
  },
  {
    "x": "sole ci eştene",
    "y": " içine tuz atmak, tuz katmak"
  },
  {
    "x": "sole ci kerdene",
    "y": " tuz koymak"
  },
  {
    "x": "sole dayene",
    "y": " tuzlamak (hayvan için)"
  },
  {
    "x": "sole dekerdene",
    "y": " tuz koymak"
  },
  {
    "x": "sole devistene",
    "y": " içine tuz atmak, tuz katmak"
  },
  {
    "x": "sole eştene bede",
    "y": " b. sole eştene bide"
  },
  {
    "x": "sole eştene bere",
    "y": " b. sole eştene bide"
  },
  {
    "x": "sole eştene bide",
    "y": " içine tuz atmak, tuz katmak"
  },
  {
    "x": "sole kerdene",
    "y": " tuzlamak (tuza yatırmak ya da üstüne tuz serpmek)"
  },
  {
    "x": "sole pira şanayene",
    "y": " tuzlamak (üstüne tuz serpmek)"
  },
  {
    "x": "sole piro sanayene",
    "y": " b. sole piro şanayene"
  },
  {
    "x": "sole piro şanayene",
    "y": " tuzlamak (üstüne tuz serpmek)"
  },
  {
    "x": "sole vistene bede",
    "y": " b. sole eştene bide"
  },
  {
    "x": "sole vistene bere",
    "y": " b. sole eştene bide"
  },
  {
    "x": "sole vistene bide",
    "y": " b. sole eştene bide"
  },
  {
    "x": "sole-II (m)",
    "y": " b. sale-I (m)"
  },
  {
    "x": "solebîyayîş (n)",
    "y": " tuzlanma"
  },
  {
    "x": "soleca (n)",
    "y": " küçükbaş ve büyükbaş hayvanlara topluca tuz yedirilen yer"
  },
  {
    "x": "soledane (m)",
    "y": " tuzluk"
  },
  {
    "x": "solekî (m)",
    "y": " b. salekî (m)"
  },
  {
    "x": "solen, -e",
    "y": " b. solin, -e"
  },
  {
    "x": "soleroş, -e",
    "y": " tuzcu"
  },
  {
    "x": "soleroşî (m)",
    "y": " tuzculuk"
  },
  {
    "x": "soleroşîye (m)",
    "y": " tuzculuk"
  },
  {
    "x": "solesan (n)",
    "y": " b. solesen (n)"
  },
  {
    "x": "solesana (m)",
    "y": " b. solesen (n)"
  },
  {
    "x": "solesen (n)",
    "y": " küçükbaş ve büyükbaş hayvanlara topluca tuz yedirilen yer"
  },
  {
    "x": "solesê (n)",
    "y": " b. solesen (n)"
  },
  {
    "x": "solesûn (n)",
    "y": " b. efsûn (n)"
  },
  {
    "x": "solesûn kerdene",
    "y": " b. efsûn kerdene"
  },
  {
    "x": "soli (m)",
    "y": " b. sole-I (m)"
  },
  {
    "x": "soli bîyayîne",
    "y": " b. sole bîyene"
  },
  {
    "x": "soli kerdene",
    "y": " b. sole kerdene"
  },
  {
    "x": "soli pira şanayene",
    "y": " b. sole pira şanayene"
  },
  {
    "x": "soli piro şanayene",
    "y": " b. sole pira şanayene"
  },
  {
    "x": "solibîyayîş (n)",
    "y": " b. solebîyayîş (n)"
  },
  {
    "x": "solin, -e",
    "y": " tuzlu"
  },
  {
    "x": "solince (m)",
    "y": " b. salince (m)"
  },
  {
    "x": "soliney (m)",
    "y": " b. solinî (m), solinîye (m)"
  },
  {
    "x": "solinê (m)",
    "y": " b. solinî (m), solinîye (m)"
  },
  {
    "x": "solinî (m)",
    "y": " tuzluluk"
  },
  {
    "x": "solinîye (m)",
    "y": " tuzluluk"
  },
  {
    "x": "solix (n)",
    "y": " nefes, soluk"
  },
  {
    "x": "solix dayene",
    "y": " nefes vermek, soluk vermek"
  },
  {
    "x": "solix girewtene",
    "y": " nefes almak, soluk almak"
  },
  {
    "x": "solix guretene",
    "y": " b. solix girewtene"
  },
  {
    "x": "solla",
    "y": " b. înşalah"
  },
  {
    "x": "soloşe (n)",
    "y": " b. solesen (n)"
  },
  {
    "x": "solsî (m)",
    "y": " b. solesen (n)"
  },
  {
    "x": "Somalya (m)",
    "y": " Somali "
  },
  {
    "x": "sombuele (n)",
    "y": " b. simore (n) "
  },
  {
    "x": "somine (m)",
    "y": " b. somune (m)"
  },
  {
    "x": "somîd (m)",
    "y": " b. simîdî (m)"
  },
  {
    "x": "somîge (m)",
    "y": " b. şêmûge (m)"
  },
  {
    "x": "somîla (m)",
    "y": " b. şûmîla (m)"
  },
  {
    "x": "somîye (m)",
    "y": " b. somune (m)"
  },
  {
    "x": "somune (m)",
    "y": " somun"
  },
  {
    "x": "somya (m)",
    "y": " somya"
  },
  {
    "x": "son (n)",
    "y": " b. şan (n)"
  },
  {
    "x": "son de",
    "y": " b. şan de"
  },
  {
    "x": "Sonê to xeyr bo.",
    "y": " b. Şanê to xeyr bo."
  },
  {
    "x": "sonci (n)",
    "y": " b. sancî (n)"
  },
  {
    "x": "sonci kerdene",
    "y": " b. sancî kerdene"
  },
  {
    "x": "sond (n)",
    "y": " ant, yemin"
  },
  {
    "x": "sond wendene",
    "y": " ant içmek, yemin etmek "
  },
  {
    "x": "sond werdene",
    "y": " ant içmek, yemin etmek "
  },
  {
    "x": "sondwerda (m)",
    "y": " yeminli"
  },
  {
    "x": "sondwerde (n)",
    "y": " yeminli"
  },
  {
    "x": "sondwerdîye (m)",
    "y": " yeminli"
  },
  {
    "x": "sondalî (n)",
    "y": " b. sandalî (n)"
  },
  {
    "x": "sondiqi (m)",
    "y": " b. sindoqe (m)"
  },
  {
    "x": "sondîqî (m)",
    "y": " b. sindoqe (m)"
  },
  {
    "x": "sone (n)",
    "y": " b. sane (n)"
  },
  {
    "x": "sone bîyayêne",
    "y": " b. sane bîyene"
  },
  {
    "x": "sone bîyene",
    "y": " b. sane bîyene"
  },
  {
    "x": "sone kerdene",
    "y": " b. sane kerdene"
  },
  {
    "x": "songola (m)",
    "y": " b. sangola (m) (genç için)"
  },
  {
    "x": "songole (n)",
    "y": " b. sangole (n) (genç için)"
  },
  {
    "x": "sonik (m)",
    "y": " b. sanike-I (m)"
  },
  {
    "x": "sonike (m)",
    "y": " b. sanike-I (m)"
  },
  {
    "x": "sontîmi (m)",
    "y": " b. santîmetre (n)"
  },
  {
    "x": "sop (n)",
    "y": " b. sap (n)"
  },
  {
    "x": "soqa (m)",
    "y": " topaç"
  },
  {
    "x": "soqal, -e",
    "y": " eli veya ayağı sakat olan"
  },
  {
    "x": "soqî (m)",
    "y": " dibek (içinde bulgur vb. dövülen büyük havan), soku"
  },
  {
    "x": "soqîn (n)",
    "y": " b. soqî (m)"
  },
  {
    "x": "sor, -e",
    "y": " b. şorr, -e"
  },
  {
    "x": "sora (n)",
    "y": " b. şewdir (n)"
  },
  {
    "x": "sorba (m)",
    "y": " b. şorba (m)"
  },
  {
    "x": "Soreg (m)",
    "y": " b. Sêwregi (m)"
  },
  {
    "x": "sorênî (m)",
    "y": " b. şorrênî (m)"
  },
  {
    "x": "sorike-I (m)",
    "y": " b. şorike (m)"
  },
  {
    "x": "sorike-II (m)",
    "y": " b. şorbike (m)"
  },
  {
    "x": "soriki (m)",
    "y": " b. şorike (m)"
  },
  {
    "x": "sorikin, -e",
    "y": " b. şorikin, -e"
  },
  {
    "x": "sorin, -e",
    "y": " b. şorrin, -e"
  },
  {
    "x": "sorinci (m)",
    "y": " b. surince (m)"
  },
  {
    "x": "sorinci vetene",
    "y": " b. surince vetene"
  },
  {
    "x": "sorinênî (m)",
    "y": " b. şorrinênî (m)"
  },
  {
    "x": "sorinîye (m)",
    "y": " b. şorrinîye (m)"
  },
  {
    "x": "sorîye (m)",
    "y": " b. şorrî (m), şorrîye (m)"
  },
  {
    "x": "sorni (n)",
    "y": " b. sifirne (n)"
  },
  {
    "x": "sornîg (n)",
    "y": " b. sifirne (n)"
  },
  {
    "x": "sorr, -e",
    "y": " b. şorr, -e"
  },
  {
    "x": "sorrênî (m)",
    "y": " b. şorrênî (m)"
  },
  {
    "x": "sorrin, -e",
    "y": " b. şorrin, -e"
  },
  {
    "x": "sorrinênî (m)",
    "y": " b. şorrinênî (m)"
  },
  {
    "x": "sorrinîye (m)",
    "y": " b. şorrinîye (m)"
  },
  {
    "x": "sorrîye (m)",
    "y": " b. şorrî (m), şorrîye (m)"
  },
  {
    "x": "sorva (m)",
    "y": " b. şorba (m)"
  },
  {
    "x": "sorvike (m)",
    "y": " b. şorbike (m)"
  },
  {
    "x": "sorxaç (n)",
    "y": " sos"
  },
  {
    "x": "sorxas (n)",
    "y": " b. sorxaç (n)"
  },
  {
    "x": "sorxaz (n)",
    "y": " b. sorxaç (n)"
  },
  {
    "x": "sos (n)",
    "y": " sos"
  },
  {
    "x": "sos bîyayene",
    "y": " b. sews bîyene"
  },
  {
    "x": "sosê (m)",
    "y": " b. sewsî (m)"
  },
  {
    "x": "sosî (m)",
    "y": " b. sewsî (m)"
  },
  {
    "x": "sosîs (n)",
    "y": " sosis"
  },
  {
    "x": "sosrete (m)",
    "y": " skandal"
  },
  {
    "x": "sosyal, -e",
    "y": " toplumsal, sosyal, içtimai"
  },
  {
    "x": "sosyalîst, -e",
    "y": " sosyalist"
  },
  {
    "x": "sosyalîstîye (m)",
    "y": " sosyalistlik "
  },
  {
    "x": "sosyalîzm (n)",
    "y": " sosyalizm"
  },
  {
    "x": "sosyalîzmo îlmî (n)",
    "y": " bilimsel sosyalizm"
  },
  {
    "x": "sosyalîzmo zanistî (n)",
    "y": " bilimsel sosyalizm"
  },
  {
    "x": "sosyolog, -e",
    "y": " sosyolog"
  },
  {
    "x": "sosyolojî (n)",
    "y": " sosyoloji "
  },
  {
    "x": "sosyolojîyê huqûqî (n)",
    "y": " hukuk sosyolojisi"
  },
  {
    "x": "sosyolojîk, -e",
    "y": " sosyolojik"
  },
  {
    "x": "sota (m)",
    "y": " yanık"
  },
  {
    "x": "sote-I (n)",
    "y": " yanık"
  },
  {
    "x": "sote-II (n)",
    "y": " orman içindeki açıklık"
  },
  {
    "x": "soteyê birî",
    "y": " orman içindeki açıklık"
  },
  {
    "x": "sotemenî (m)",
    "y": " yakıt (sıvı ve gaz için)"
  },
  {
    "x": "sotemenîya awine (m)",
    "y": " akaryakıt"
  },
  {
    "x": "sotemenîya erebeyî (m)",
    "y": " araba yakıtı"
  },
  {
    "x": "sotemenîya fosîlî (m)",
    "y": " fosil yakıt"
  },
  {
    "x": "sotene",
    "y": " yakmak, yandırmak"
  },
  {
    "x": "soterî, -ye",
    "y": " maskara, soytarı"
  },
  {
    "x": "soterîyî (m)",
    "y": " maskaralık, soytarılık"
  },
  {
    "x": "soterîyîye (m)",
    "y": " maskaralık, soytarılık"
  },
  {
    "x": "sotê (m)",
    "y": " b. sota (m), sotîye (m)"
  },
  {
    "x": "sotiş (n)",
    "y": " yakma, yandırma"
  },
  {
    "x": "sotişê eşteyî (n)",
    "y": " atık yakımı"
  },
  {
    "x": "sotîye (m)",
    "y": " yanık"
  },
  {
    "x": "sova (m)",
    "y": " b. soba (m)"
  },
  {
    "x": "sovin (n)",
    "y": " b. sabun (n)"
  },
  {
    "x": "sovun (n)",
    "y": " b. sabun (n)"
  },
  {
    "x": "sow",
    "y": " b. eke-I"
  },
  {
    "x": "sowbî",
    "y": " b. sewbîn"
  },
  {
    "x": "sowbîna",
    "y": " b. sewbîna"
  },
  {
    "x": "sowend (n)",
    "y": " b. sond (n)"
  },
  {
    "x": "sowi (m)",
    "y": " b. şewe (m)"
  },
  {
    "x": "sowki",
    "y": " b. eke-I"
  },
  {
    "x": "sowl (n)",
    "y": " b. sol-I (n)"
  },
  {
    "x": "sowldarzey (m)",
    "y": " b. solderzîye (m), solderzî (m)"
  },
  {
    "x": "sowqe (m)",
    "y": " b. soqa (m)"
  },
  {
    "x": "Sowreg (m)",
    "y": " b. Sêwregi (m)"
  },
  {
    "x": "sows bîyayene",
    "y": " b. sews bîyene"
  },
  {
    "x": "sowsî (m)",
    "y": " b. sewsî (m)"
  },
  {
    "x": "sowzi (n)",
    "y": " b. sewzî (n)"
  },
  {
    "x": "soxraç (n)",
    "y": " b. sorxaç (n)"
  },
  {
    "x": "soxulcan (n)",
    "y": " solucan, yersolucanı (b. malik, kila binê erdî, marê şilîye)"
  },
  {
    "x": "soyaya (m)",
    "y": " yorgun"
  },
  {
    "x": "soyaye (n)",
    "y": " yorgun"
  },
  {
    "x": "soyayeyî (m)",
    "y": " yorgunluk"
  },
  {
    "x": "soyayîne",
    "y": " yorulmak\r\n~Ez soyane./Ez soyaya. (Ben yoruldum.)"
  },
  {
    "x": "soyayîş (n)",
    "y": " yorulma"
  },
  {
    "x": "soye-I (m)",
    "y": " b. saye-I (m)"
  },
  {
    "x": "soye-II (n)",
    "y": " b. sabe (n)"
  },
  {
    "x": "soyê saneyî (n)",
    "y": " kesici araçları bilemek için kullanılan yağ"
  },
  {
    "x": "soyîye (m)",
    "y": " b. saba (m)"
  },
  {
    "x": "soykek  (n)",
    "y": " b. sabe (n)"
  },
  {
    "x": "soykeke (m)",
    "y": " b. saba (m)"
  },
  {
    "x": "... soynaene",
    "y": " b. ... soynayene"
  },
  {
    "x": "... soynaîs (n)",
    "y": " b. ... soynayîş (n)"
  },
  {
    "x": "soynayene",
    "y": " 1)sürmek, sürtmek\r\n~2)bulaştırmak (fiziki şeyler için)\r\n~3)b. sawitene"
  },
  {
    "x": "soynayêne",
    "y": " b. soynayene"
  },
  {
    "x": "soynayîne",
    "y": " yormak"
  },
  {
    "x": "... soynayîs (n)",
    "y": " b. ... soynayîş (n)"
  },
  {
    "x": "soynayîş-I (n)",
    "y": " 1)sürme\r\n~2)bulaştırma (fiziki şeyler için)"
  },
  {
    "x": "soynayîş-II (n)",
    "y": " yorma"
  },
  {
    "x": "soynîyayene",
    "y": " 1)sürülmek, sürtülmek\r\n~2)bulaşmak (fiziki şeyler için)\r\n~3)b. sawîyayene"
  },
  {
    "x": "soynîyayêne",
    "y": " b. soynîyayene"
  },
  {
    "x": "... soynîyayîş (n)",
    "y": " bulaşma (fiziki şeyler için)"
  },
  {
    "x": "soyterî, -ye",
    "y": " b. soterî, -ye"
  },
  {
    "x": "soz-I (n)",
    "y": " söz"
  },
  {
    "x": "soz dayene",
    "y": " söz vermek"
  },
  {
    "x": "soz-II (m)",
    "y": " b. sacî (m)"
  },
  {
    "x": "sozdar, -e",
    "y": " sözlü"
  },
  {
    "x": "Spanya (m)",
    "y": " İspanya "
  },
  {
    "x": "spar (n)",
    "y": " b. espar (n), suwar (n)"
  },
  {
    "x": "spas (n)",
    "y": " sipas (n)"
  },
  {
    "x": "spastîk, -e",
    "y": " spastik"
  },
  {
    "x": "spektrum (n)",
    "y": " spektrum "
  },
  {
    "x": "sperma (m)",
    "y": " atmık, bel, belsuyu, ersuyu, sperma"
  },
  {
    "x": "spî (n)",
    "y": " b. sipe-I (n)"
  },
  {
    "x": "spîker, -e",
    "y": " spiker"
  },
  {
    "x": "spîkerî (m)",
    "y": " spikerlik"
  },
  {
    "x": "spîkerîye (m)",
    "y": " spikerlik"
  },
  {
    "x": "spîral, -e",
    "y": " spiral"
  },
  {
    "x": "spîralo birnayox (n)",
    "y": " kesici spiral [makinesi]"
  },
  {
    "x": "spor",
    "y": " beden eğitimi, spor"
  },
  {
    "x": "spot (n)",
    "y": " spot "
  },
  {
    "x": "sprînkler (n)",
    "y": " sprinkler (itfaiye için)"
  },
  {
    "x": "Srî Lanka (m)",
    "y": " Seylan, Sri Lanka"
  },
  {
    "x": "stabîlîze, -ye",
    "y": " stabilize"
  },
  {
    "x": "stadyûm (n)",
    "y": " stadyum, stat"
  },
  {
    "x": "staj (n)",
    "y": " staj "
  },
  {
    "x": "stajyer, -e",
    "y": " stajyer"
  },
  {
    "x": "stajyerîye (m)",
    "y": " stajyerlik"
  },
  {
    "x": "standard, -e (s)",
    "y": " standart"
  },
  {
    "x": "standard kerdene",
    "y": " standartlaştırmak, standardize etmek  "
  },
  {
    "x": "standardîzasyon(n)",
    "y": " ölçünleştirim, standartlaştırma, standardizasyon, standardize etme"
  },
  {
    "x": "standardîze kerdene",
    "y": " standartlaştırmak, standardize etmek "
  },
  {
    "x": "standardîzekerdiş (n)",
    "y": " ölçünleştirim, standartlaştırma, standardizasyon, standardize etme "
  },
  {
    "x": "standardkerdiş (n)",
    "y": " ölçünleştirim, standartlaştırma,  standardizasyon,  standardize etme"
  },
  {
    "x": "standart, -e (s)",
    "y": " b. standard, -e (s)"
  },
  {
    "x": "standart kerdene",
    "y": " b. standard kerdene"
  },
  {
    "x": "standartkerdiş (n)",
    "y": " b. standardkerdiş (n)"
  },
  {
    "x": "Stanley",
    "y": " Stanley"
  },
  {
    "x": "statîstîk (n)",
    "y": " istatistik"
  },
  {
    "x": "statîstîkkar, -e",
    "y": " istatistikçi"
  },
  {
    "x": "statu (n)",
    "y": " statü"
  },
  {
    "x": "stenograf, -e",
    "y": " stenograf"
  },
  {
    "x": "stenografî (m)",
    "y": " stenografi"
  },
  {
    "x": "stepne (n)",
    "y": " stepne, yedek teker"
  },
  {
    "x": "stîl (n)",
    "y": " stil, tarz, üslup"
  },
  {
    "x": "stîlîst, -e",
    "y": " stilist, üslupçu"
  },
  {
    "x": "stok (n)",
    "y": " stok (yığılım, yığımlık)"
  },
  {
    "x": "stokê meskenan (n)",
    "y": " konut stoku"
  },
  {
    "x": "Stokholm (n)",
    "y": " Stockholm"
  },
  {
    "x": "stop",
    "y": " stop, dur!"
  },
  {
    "x": "stop kerdene",
    "y": " stop etmek"
  },
  {
    "x": "stoppres (n)",
    "y": " stop press"
  },
  {
    "x": "stor (n)",
    "y": " b. estor (n)"
  },
  {
    "x": "stratejî (m)",
    "y": " strateji "
  },
  {
    "x": "stratejîk, -e",
    "y": " stratejik"
  },
  {
    "x": "stres (n)",
    "y": " stres"
  },
  {
    "x": "stresê karî (n)",
    "y": " iş stresi"
  },
  {
    "x": "strîptîz (n)",
    "y": " striptiz"
  },
  {
    "x": "strîptîzkare (m)",
    "y": " striptizci"
  },
  {
    "x": "studyo (n)",
    "y": " stüdyo"
  },
  {
    "x": "su bîyene",
    "y": " b. sû bîyene"
  },
  {
    "x": "suyê xo ardene",
    "y": " b. sûyê xo ardene"
  },
  {
    "x": "suyê ... ameyene",
    "y": " b. sûyê ... ameyene"
  },
  {
    "x": "suyê ... ameyîş",
    "y": " b. sûyê ... ameyîş"
  },
  {
    "x": "suyê ... ardene",
    "y": " b. sûyê ... ardene"
  },
  {
    "x": "suyê ... ardiş",
    "y": " b. sûyê ... ardiş"
  },
  {
    "x": "suaba (m)",
    "y": " b. soba (m)"
  },
  {
    "x": "suafî (n)",
    "y": " sofî (n)"
  },
  {
    "x": "sual-I (n)",
    "y": " soru, sual"
  },
  {
    "x": "sual kerdene",
    "y": " sormak, sual etmek"
  },
  {
    "x": "sual-II (m)",
    "y": " b. sole-I (m)"
  },
  {
    "x": "sual bîyayîne",
    "y": " b. sole bîyene"
  },
  {
    "x": "sual kerdene",
    "y": " b. sole kerdene"
  },
  {
    "x": "sual pira şanayene",
    "y": " b. sole pira şanayene"
  },
  {
    "x": "sual piro şanayene",
    "y": " b. sole pira şanayene"
  },
  {
    "x": "sualbîyayîş (n)",
    "y": " b. solebîyayîş (n)"
  },
  {
    "x": "sualca (n)",
    "y": " b. soleca (n)"
  },
  {
    "x": "sualin",
    "y": " b. solin, -e"
  },
  {
    "x": "sualinê (m)",
    "y": " b. solinî (m), solinîye (m)"
  },
  {
    "x": "sualinî (m)",
    "y": " b. solinî (m), solinîye (m)"
  },
  {
    "x": "sualkerdiş (n)",
    "y": " sorma, sual etme"
  },
  {
    "x": "suamûn (m)",
    "y": " b. somune (m)"
  },
  {
    "x": "suare (m)",
    "y": " b. şuware (m)"
  },
  {
    "x": "suarni (n)",
    "y": " b. sifirne (n)"
  },
  {
    "x": "sub (n)",
    "y": " grip"
  },
  {
    "x": "suba-I (m)",
    "y": " b. soba (m)"
  },
  {
    "x": "suba-II (n)",
    "y": " b. siba (n) "
  },
  {
    "x": "sube (n)",
    "y": " b. soba (m)"
  },
  {
    "x": "subîyaya (m)",
    "y": " b. sûbîyaya (m)"
  },
  {
    "x": "subîyaye (n)",
    "y": " b. sûbîyaye (n)"
  },
  {
    "x": "subîyayîş (n)",
    "y": " b. sûbîyayîş (n)"
  },
  {
    "x": "subîyayîye (m)",
    "y": " b. sûbîyayîye (m)"
  },
  {
    "x": "suc (n)",
    "y": " suç"
  },
  {
    "x": "suc kerdene",
    "y": " suç işlemek "
  },
  {
    "x": "sucdar, -e",
    "y": " suçlu, kriminel"
  },
  {
    "x": "sucdar kerdene",
    "y": " suçlamak "
  },
  {
    "x": "sucdarîye (m)",
    "y": " suçluluk "
  },
  {
    "x": "sucdarkerda (m)",
    "y": " suçlanan "
  },
  {
    "x": "sucdarkerde (n)",
    "y": " suçlanan"
  },
  {
    "x": "sucdarkerdox, -e",
    "y": " suçlayan "
  },
  {
    "x": "sucirik (n)",
    "y": " ayranın kaynatılmasıyla oluşan çökelek(leşme)"
  },
  {
    "x": "sucrik (n)",
    "y": " b. sucirik (n)"
  },
  {
    "x": "sucux (n)",
    "y": " b. sucuxe (m)"
  },
  {
    "x": "sucuxe (m)",
    "y": " 1)sucuk (kıyma ve baharattan yapılan)\r\n~2) sucuk (bandırma)"
  },
  {
    "x": "sucuxê dekerdeyî (zh)",
    "y": " bumbar"
  },
  {
    "x": "sueba (m)",
    "y": " b. soba (m)"
  },
  {
    "x": "suel (m)",
    "y": " b. sole-I (m)"
  },
  {
    "x": "suel bîyayîne",
    "y": " b. sole bîyene"
  },
  {
    "x": "suel kerdene",
    "y": " b. sole kerdene"
  },
  {
    "x": "suel pira şanayene",
    "y": " b. sole pira şanayene"
  },
  {
    "x": "suel piro şanayene",
    "y": " b. sole pira şanayene"
  },
  {
    "x": "suelaw (m)",
    "y": " b. solawe (m)"
  },
  {
    "x": "suelbîyayîş (n)",
    "y": " b. solebîyayîş (n)"
  },
  {
    "x": "suelin",
    "y": " b. solin, -e"
  },
  {
    "x": "suelinê (m)",
    "y": " b. solinî (m), solinîye (m)"
  },
  {
    "x": "suend (n)",
    "y": " b. sond (n)"
  },
  {
    "x": "suerni (n)",
    "y": " b. sifirne (n)"
  },
  {
    "x": "suet (n)",
    "y": " süet, podüsüet"
  },
  {
    "x": "suez (n)",
    "y": " b. soz (n)"
  },
  {
    "x": "suezdar (n)",
    "y": " b. sozdar (n)"
  },
  {
    "x": "suffîks (n)",
    "y": " sonek, takı"
  },
  {
    "x": "suflor (n)",
    "y": " suflör"
  },
  {
    "x": "sufloze (m)",
    "y": " suflöz"
  },
  {
    "x": "sufre (n)",
    "y": " b. sifre (n)"
  },
  {
    "x": "suhur (n)",
    "y": " sahur"
  },
  {
    "x": "suik (m)",
    "y": " b. sûke (m)"
  },
  {
    "x": "suing (n)",
    "y": " b. sung (n)"
  },
  {
    "x": "suirbin (m)",
    "y": " b. sûrbine (m)"
  },
  {
    "x": "suirç, -e",
    "y": " b. sûrex, -e"
  },
  {
    "x": "suirguil (m)",
    "y": " b. sûrgule (m)"
  },
  {
    "x": "suirinc (m)",
    "y": " b. surince (m)"
  },
  {
    "x": "suirinc vetene",
    "y": " b. surince vetene"
  },
  {
    "x": "sujî (n)",
    "y": " b. suzî (n)"
  },
  {
    "x": "sujrik (n)",
    "y": " b. sucirik (n)"
  },
  {
    "x": "suk (m)",
    "y": " b. sûke (m)"
  },
  {
    "x": "suke (m)",
    "y": " b. sûke (m)"
  },
  {
    "x": "suki (m)",
    "y": " b. sûke (m)"
  },
  {
    "x": "sukr (n)",
    "y": " b. sikre (n)"
  },
  {
    "x": "sukra (m)",
    "y": " b. uskura (m)"
  },
  {
    "x": "Sukre",
    "y": " Sucre"
  },
  {
    "x": "sulahî (m)",
    "y": " b. surehî (m)"
  },
  {
    "x": "sulala (m)",
    "y": " b. sulale (m)"
  },
  {
    "x": "sulale (m)",
    "y": " soy sop"
  },
  {
    "x": "suland (n)",
    "y": " b. silond (n)"
  },
  {
    "x": "sulasin (n)",
    "y": " b. solesen (n)"
  },
  {
    "x": "sule (n)",
    "y": " b. silo (n)"
  },
  {
    "x": "sulehî (m)",
    "y": " b. surehî (m)"
  },
  {
    "x": "suli (n)",
    "y": " b. silo (n)"
  },
  {
    "x": "sulimi (m)",
    "y": " b. silime (m)"
  },
  {
    "x": "sulqber (n)",
    "y": " b. suxber (n), zifqêre (m)"
  },
  {
    "x": "sulumi (m)",
    "y": " b. silime (m)"
  },
  {
    "x": "sumbelîye (n)",
    "y": " b. simore (n)"
  },
  {
    "x": "sumbueli (n)",
    "y": " b. simore (n)"
  },
  {
    "x": "sumen (n)",
    "y": " sümen"
  },
  {
    "x": "sumenê îmza (n)",
    "y": " imza sümeni"
  },
  {
    "x": "sumer (n)",
    "y": " b. simer (n)"
  },
  {
    "x": "sumerini (m)",
    "y": " b. simerine (m)"
  },
  {
    "x": "sumîr (m)",
    "y": " b. simîrî (m)"
  },
  {
    "x": "sumore (n)",
    "y": " b. simore (n) "
  },
  {
    "x": "sunbuele (n)",
    "y": " b. simore (n) "
  },
  {
    "x": "suncoqi (m)",
    "y": " b. sucuxe (m)"
  },
  {
    "x": "suncuqi (m)",
    "y": " b. sucuxe (m)"
  },
  {
    "x": "suncûqe (m)",
    "y": " b. sucuxe (m)"
  },
  {
    "x": "sundiq (m)",
    "y": " b. sindoqe (m)"
  },
  {
    "x": "sunet (n)",
    "y": " sünnet"
  },
  {
    "x": "sunet bîyene",
    "y": " sünnet edilmek, sünnet olmak"
  },
  {
    "x": "sunet kerdene",
    "y": " sünnet etmek"
  },
  {
    "x": "sunetbîyaya (m)",
    "y": " sünnetli"
  },
  {
    "x": "sunetbîyaye (n)",
    "y": " sünnetli"
  },
  {
    "x": "sunetbîyayîye (m)",
    "y": " sünnetli"
  },
  {
    "x": "sunetçî, -ye",
    "y": " sünnetçi"
  },
  {
    "x": "sunetçîyey (m)",
    "y": " b. sunetçîyîye (m)"
  },
  {
    "x": "sunetçîyê (m)",
    "y": " b. sunetçîyî (m)"
  },
  {
    "x": "sunetçîyênî (m)",
    "y": " sünnetçilik"
  },
  {
    "x": "sunetçîyî (m)",
    "y": " sünnetçilik"
  },
  {
    "x": "sunetçîyîye (m)",
    "y": " sünnetçilik"
  },
  {
    "x": "sunetkerda (m)",
    "y": " sünnetli"
  },
  {
    "x": "sunetkerde (n)",
    "y": " sünnetli"
  },
  {
    "x": "sunetkerdîye (m)",
    "y": " sünnetli"
  },
  {
    "x": "sunetkerdox, -e",
    "y": " sünnetçi"
  },
  {
    "x": "sunetkerdoxey (m)",
    "y": " b. sunetkerdoxîye (m)"
  },
  {
    "x": "sunetkerdoxê (m)",
    "y": " b. sunetkerdoxî (m)"
  },
  {
    "x": "sunetkerdoxî (m)",
    "y": " sünnetçilik"
  },
  {
    "x": "sunetkerdoxîye (m)",
    "y": " sünnetçilik"
  },
  {
    "x": "sunetnêbîyaya (m)",
    "y": " sünnetsiz"
  },
  {
    "x": "sunetnêbîyaye (n)",
    "y": " sünnetsiz"
  },
  {
    "x": "sunetnêbîyayîye (m)",
    "y": " sünnetsiz"
  },
  {
    "x": "sunetnêkerda (m)",
    "y": " sünnetsiz"
  },
  {
    "x": "sunetnêkerde (n)",
    "y": " sünnetsiz"
  },
  {
    "x": "sunetnêkerdîye (m)",
    "y": " sünnetsiz"
  },
  {
    "x": "sung (n)",
    "y": " mantar (bitkisi) "
  },
  {
    "x": "sunger (n)",
    "y": " sünger"
  },
  {
    "x": "suni (n)",
    "y": " b. sane-I (n)"
  },
  {
    "x": "sunix, -e",
    "y": " (mecazi) yoksul, fakir"
  },
  {
    "x": "sunixi (m)",
    "y": " b. sunixe"
  },
  {
    "x": "sunî, -ye",
    "y": " Sünni"
  },
  {
    "x": "sunîr (n)",
    "y": " b. şûmîla (m)"
  },
  {
    "x": "sunîtî (m)",
    "y": " Sünnilik"
  },
  {
    "x": "sunîtîye (m)",
    "y": " Sünnilik"
  },
  {
    "x": "sunîyey (m)",
    "y": " b. sunîyîye (m)"
  },
  {
    "x": "sunîyê (m)",
    "y": " b. sunîyî (m)"
  },
  {
    "x": "sunîyênî (m)",
    "y": " Sünnilik"
  },
  {
    "x": "sunîyî (m)",
    "y": " Sünnilik"
  },
  {
    "x": "sunîyîye (m)",
    "y": " Sünnilik"
  },
  {
    "x": "sunnet (n)",
    "y": " b. sunet (n)"
  },
  {
    "x": "sunnet bîyene",
    "y": " b. sunet bîyene"
  },
  {
    "x": "sunnet bîyene",
    "y": " b. sunet bîyene"
  },
  {
    "x": "sunnet kerdene",
    "y": " b. sunet kerdene"
  },
  {
    "x": "sunnetçî, -ye",
    "y": " b. sunetçî, -ye"
  },
  {
    "x": "sunnetkerdox, -e",
    "y": " b. sunetkerdox, -e"
  },
  {
    "x": "sunnî, -ye",
    "y": " b. sunî, -ye"
  },
  {
    "x": "sunnîyey (m)",
    "y": " b. sunîyîye (m)"
  },
  {
    "x": "sunnîyî (m)",
    "y": " b. sunîyî (m)"
  },
  {
    "x": "sunnîyîye (m)",
    "y": " b. sunîyîye (m)"
  },
  {
    "x": "sunzîyer (m)",
    "y": " b. simzêre (m)"
  },
  {
    "x": "supe",
    "y": " b. sipî, -ye-I"
  },
  {
    "x": "suqbe (n)",
    "y": " b. suxber (n)"
  },
  {
    "x": "suqber (n)",
    "y": " b. suxber (n)"
  },
  {
    "x": "suquling, -e",
    "y": " turna "
  },
  {
    "x": "sur, -e",
    "y": " b. sûr, -e"
  },
  {
    "x": "sur bîyayene",
    "y": " b. sûr bîyene"
  },
  {
    "x": "sur bîyene",
    "y": " b. sûr bîyene"
  },
  {
    "x": "sur kerdene",
    "y": " b. sûr kerdene"
  },
  {
    "x": "sur û sipî",
    "y": " b. sûr û sipî"
  },
  {
    "x": "surahî (m)",
    "y": " b. surehî (m)"
  },
  {
    "x": "surahî (n)",
    "y": " b. surehî (m)"
  },
  {
    "x": "surate (m)",
    "y": " b. surote (m)"
  },
  {
    "x": "suravêlik, -e",
    "y": " b. sûravêlk, -e"
  },
  {
    "x": "surax, -e",
    "y": " b. sûrex, -e"
  },
  {
    "x": "surayî (n)",
    "y": " b. surehî (m)"
  },
  {
    "x": "surbelek, -e",
    "y": " b. sûrbelek, -e"
  },
  {
    "x": "surbini (m)",
    "y": " b. sûrbine (m)"
  },
  {
    "x": "surbîyaya (m)",
    "y": " b. sûrbîyaya (m)"
  },
  {
    "x": "surbîyaye (n)",
    "y": " b. sûrbîyaye (n)"
  },
  {
    "x": "surbîyayi (n)",
    "y": " b. sûrbîyaye (n)"
  },
  {
    "x": "surbîyayîye (m)",
    "y": " b. sûrbîyayîye (m)"
  },
  {
    "x": "surbuni (m)",
    "y": " b. sûrbine (m)"
  },
  {
    "x": "surdim (n)",
    "y": " b. sûrdim (n)"
  },
  {
    "x": "sure (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "surehî (m)",
    "y": " sürahi"
  },
  {
    "x": "surek (n)",
    "y": " b. sûrek (n)"
  },
  {
    "x": "surekî (zh)",
    "y": " kızamık"
  },
  {
    "x": "surekî vetene",
    "y": " kızamığa yakalanmak, kızamık geçirmek"
  },
  {
    "x": "suret-II (n)",
    "y": " hız, sürat"
  },
  {
    "x": "sureto ekstrem",
    "y": " aşırı hız, aşırı sürat"
  },
  {
    "x": "sureto esxerî",
    "y": " asgari hız, asgari sürat"
  },
  {
    "x": "sureto ezamî",
    "y": " azami hız, azami sürat"
  },
  {
    "x": "sureto hedravîyarte",
    "y": " aşırı hız, aşırı sürat"
  },
  {
    "x": "suret-II (n)",
    "y": " biçim, şekil"
  },
  {
    "x": "surex, -e",
    "y": " b. sûrex, -e"
  },
  {
    "x": "surgêç (n)",
    "y": " b. suzgeç (n)"
  },
  {
    "x": "surgin (n)",
    "y": " b. surgun (n)"
  },
  {
    "x": "surgin bîyayene",
    "y": " b. surgun bîyene"
  },
  {
    "x": "surgin kerdene",
    "y": " b. surgun kerdene"
  },
  {
    "x": "surgî (n)",
    "y": " sürgü-kilit (sürgülü kapılarda) "
  },
  {
    "x": "surgul (m)",
    "y": " b. sûrgule (m)"
  },
  {
    "x": "surgulêr (m)",
    "y": " b. sûrgulêre (m)"
  },
  {
    "x": "surguli (m)",
    "y": " b. sûrgule (m)"
  },
  {
    "x": "surgulyer (m)",
    "y": " b. sûrgulêre (m)"
  },
  {
    "x": "surgum (n)",
    "y": " b. surgun (n)"
  },
  {
    "x": "surgum bîyayîne",
    "y": " b. surgun bîyene"
  },
  {
    "x": "surgum kerdene",
    "y": " b. surgun kerdene"
  },
  {
    "x": "surgun (n)",
    "y": " sürgün"
  },
  {
    "x": "surgun bîyene",
    "y": " sürgün edilmek"
  },
  {
    "x": "surgun kerdene",
    "y": " sürgün etmek"
  },
  {
    "x": "surhet (n)",
    "y": " b. suret (n)"
  },
  {
    "x": "surij (m)",
    "y": " b. surince (m)"
  },
  {
    "x": "surij vetene",
    "y": " b. surince vetene"
  },
  {
    "x": "surik (n)",
    "y": " bızır, klitoris"
  },
  {
    "x": "surikîyayene",
    "y": " kaymak"
  },
  {
    "x": "surikîyayîş (n)",
    "y": " kayma"
  },
  {
    "x": "suriknayene",
    "y": " kaydırmak"
  },
  {
    "x": "suriknayîş (n)",
    "y": " kaydırma"
  },
  {
    "x": "surinc (m)",
    "y": " b. surince (m)"
  },
  {
    "x": "surinc vetene",
    "y": " b. surince vetene"
  },
  {
    "x": "surince (m)",
    "y": " kızamık"
  },
  {
    "x": "surince vetene",
    "y": " kızamığa yakalanmak, kızamık geçirmek "
  },
  {
    "x": "surinci (m)",
    "y": " b. surince (m)"
  },
  {
    "x": "surinci vetene",
    "y": " b. surince vetene"
  },
  {
    "x": "suritme (n)",
    "y": " büyük sıyırga"
  },
  {
    "x": "surî (m)",
    "y": " b. sûrî (m)"
  },
  {
    "x": "Surînam (n)",
    "y": " Surinam"
  },
  {
    "x": "surîye-I (m)",
    "y": " b. sûrîye-I (m)"
  },
  {
    "x": "surîye-II (m)",
    "y": " sürü"
  },
  {
    "x": "surkerda (m)",
    "y": " b. sûrkerda (m)"
  },
  {
    "x": "surkerde (n)",
    "y": " b. sûrkerde (n)"
  },
  {
    "x": "surkerdi (n)",
    "y": " b. sûrkerde (n)"
  },
  {
    "x": "surkerdiş (n)",
    "y": " b. sûrkerdiş (n)"
  },
  {
    "x": "surkerdîye (m)",
    "y": " b. sûrkerdîye (m)"
  },
  {
    "x": "surme (n)",
    "y": " sürgü (silah sürgüsü) "
  },
  {
    "x": "surmelî, -ye",
    "y": " sürmeli"
  },
  {
    "x": "surmi (n)",
    "y": " b. surme (n)"
  },
  {
    "x": "surot (n)",
    "y": " surat, yüz"
  },
  {
    "x": "surote (m)",
    "y": " yanak"
  },
  {
    "x": "surrealîst, -e",
    "y": " gerçeküstücü, sürrealist"
  },
  {
    "x": "surrealîte (m)",
    "y": " gerçeküstü, sürrealite"
  },
  {
    "x": "sursurik (n)",
    "y": " b. sursuruk (n)"
  },
  {
    "x": "sursuruk (n)",
    "y": " anüs çıkıntısı"
  },
  {
    "x": "surtme (n)",
    "y": " b. suritme (n)"
  },
  {
    "x": "surveyan, -e",
    "y": " sürveyan"
  },
  {
    "x": "sus-I (n)",
    "y": " güve"
  },
  {
    "x": "sus kewtene …",
    "y": " güve yemek (güvenin yemesi)"
  },
  {
    "x": "susî werdene",
    "y": " güve yemek (güvenin yemesi)"
  },
  {
    "x": "sus-II (n)",
    "y": " b. sûs (n)"
  },
  {
    "x": "suse (n)",
    "y": " b. şuşe (n)"
  },
  {
    "x": "susey çay (n)",
    "y": " b. şuşeyê çay (n)"
  },
  {
    "x": "susiknaya (m)",
    "y": " b. sûsiknaya (m)"
  },
  {
    "x": "susiknaye (n)",
    "y": " b. sûsiknaye (n)"
  },
  {
    "x": "susiknayî (m)",
    "y": " b. sûsiknayîye (m)"
  },
  {
    "x": "susiknayîye (m)",
    "y": " b. sûsiknayîye (m)"
  },
  {
    "x": "sut (n)",
    "y": " b. sûtal, -e "
  },
  {
    "x": "sutal (n)",
    "y": " b. sûtal, -e "
  },
  {
    "x": "sutale (n)",
    "y": " b. sûtal, -e"
  },
  {
    "x": "sutaley (m)",
    "y": " b. sûtalîye (m)"
  },
  {
    "x": "sutalê (m)",
    "y": " b. sûtalî (m)"
  },
  {
    "x": "suterî, -ye",
    "y": " b. soterî, -ye"
  },
  {
    "x": "sutîyenî (zh)",
    "y": " sutyen"
  },
  {
    "x": "sutyenî (zh)",
    "y": " b. sutîyenî (zh)"
  },
  {
    "x": "suub (n)",
    "y": " b. sub (n)"
  },
  {
    "x": "suud (n)",
    "y": " b. siûd (n)"
  },
  {
    "x": "suur (n)",
    "y": " suhur (n)"
  },
  {
    "x": "suwa (m)",
    "y": " b. soba (m)"
  },
  {
    "x": "Suva (m)",
    "y": " Suva "
  },
  {
    "x": "suwal-I (m)",
    "y": " b. sole-I (m)"
  },
  {
    "x": "suwal-II (n)",
    "y": " b. sual (n)"
  },
  {
    "x": "suwal kerdene",
    "y": " b. sual kerdene"
  },
  {
    "x": "suwalin",
    "y": " b. solin, -e"
  },
  {
    "x": "suwar, -e",
    "y": " atlı, süvari"
  },
  {
    "x": "suwar bîyene",
    "y": " binmek"
  },
  {
    "x": "suwar kerdene",
    "y": " bindirmek"
  },
  {
    "x": "suwarbeyîş (n)",
    "y": " b. suwarbîyayîş (n)"
  },
  {
    "x": "suwarbîyayîş (n)",
    "y": " 1)binme\r\n~2)dama, satranç vb. oyunlarda rakibin taşını ütebilecek durumda olma"
  },
  {
    "x": "suwarî, -ye",
    "y": " atlı, süvari "
  },
  {
    "x": "suwarkar, -e",
    "y": " cokey"
  },
  {
    "x": "suwarkarî (m)",
    "y": " cokeylik"
  },
  {
    "x": "suwarkarîye (m)",
    "y": " cokeylik"
  },
  {
    "x": "suwarkerdiş (n)",
    "y": " bindirme"
  },
  {
    "x": "suwarni (n)",
    "y": " b. sifirne (n)"
  },
  {
    "x": "suwe (n)",
    "y": " b. siwe (n)"
  },
  {
    "x": "suwe (n)",
    "y": " b. siwe (n)"
  },
  {
    "x": "suwel (m)",
    "y": " b. sole-I (m)"
  },
  {
    "x": "suwel bîyayîne",
    "y": " b. sole bîyene"
  },
  {
    "x": "suwel kerdene",
    "y": " b. sole kerdene"
  },
  {
    "x": "suwelbîyayîş (n)",
    "y": " b. solebîyayîş (n)"
  },
  {
    "x": "suwelin",
    "y": " b. solin, -e"
  },
  {
    "x": "suwelinê (m)",
    "y": " b. solinî (m), solinîye (m)"
  },
  {
    "x": "suwend (n)",
    "y": " b. sond (n)"
  },
  {
    "x": "suwerni (n)",
    "y": " b. sifirne (n)"
  },
  {
    "x": "suxber (n)",
    "y": " eşik"
  },
  {
    "x": "suxre (n)",
    "y": " angarya"
  },
  {
    "x": "suxri (n)",
    "y": " b. suxre (n)"
  },
  {
    "x": "suxrekêş, -e",
    "y": " angaryacı"
  },
  {
    "x": "suxrekêşi (m)",
    "y": " b. suxrekêşe (m)"
  },
  {
    "x": "suxrekêşîye (m)",
    "y": " angaryacılık"
  },
  {
    "x": "suxri (n)",
    "y": " b. suxre (n)"
  },
  {
    "x": "suxta (m)",
    "y": " öğrenci, talebe"
  },
  {
    "x": "suxte (n)",
    "y": " öğrenci, talebe "
  },
  {
    "x": "suxteyîye (m)",
    "y": " öğrencilik, talebelik"
  },
  {
    "x": "suyar, -e",
    "y": " b. suwarî, -e"
  },
  {
    "x": "suyar bîyene",
    "y": " b. suwar bîyene"
  },
  {
    "x": "suyarbîyayîş (n)",
    "y": " b. suwarbîyayîş (n)"
  },
  {
    "x": "suyarî, -ye",
    "y": " b. suwarî, -ye"
  },
  {
    "x": "suyax (n)",
    "y": " b. siwax (n)"
  },
  {
    "x": "suyêxoardiş (n)",
    "y": " b. sûyêxoardiş"
  },
  {
    "x": "suzde (n)",
    "y": " b. secade (n)"
  },
  {
    "x": "suzgeç (n)",
    "y": " süzgeç, süzek, süzgü; filtre"
  },
  {
    "x": "suzgêç (n)",
    "y": " b. suzgeç (n)"
  },
  {
    "x": "suzî (n)",
    "y": " 1)romatizma\r\n~2)sızı"
  },
  {
    "x": "sû (n)",
    "y": " b. şan (n)"
  },
  {
    "x": "sû bîyene",
    "y": " kızmak (öfkelenmek, sinirlenmek), asabileşmek"
  },
  {
    "x": "sûyê ... ameyene",
    "y": " kızmak (öfkelenmek, sinirlenmek), asabileşmek\r\n~Sûyê Vewrike yeno. (Vewrike sinirleniyor.)"
  },
  {
    "x": "sûyê ... ameyîş",
    "y": " kızma (öfkelenme, sinirlenme), asabileşme"
  },
  {
    "x": "sûyê ... ardene",
    "y": " kızdırmak, sinirlendirmek\r\n~Menoşe sûyê Borane ana. (Menoşe Borane’yi sinirlendiriyor.)"
  },
  {
    "x": "sûyê ... ardiş",
    "y": " kızdırma"
  },
  {
    "x": "sûyê xo ardene",
    "y": " kızmak (öfkelenmek, sinirlenmek), asabileşmek\r\n~Vewrike sûyê xo ana. (Vewrike sinirleniyor.)"
  },
  {
    "x": "sûare (m, sn)",
    "y": " suare"
  },
  {
    "x": "sûbîna",
    "y": " b. sewbîna"
  },
  {
    "x": "sûbîyaya (m)",
    "y": " kızgın (öfkeli), sinirli"
  },
  {
    "x": "sûbîyaye (n)",
    "y": " kızgın (öfkeli), sinirli"
  },
  {
    "x": "sûbîyaye bîyene",
    "y": " kızgın olmak"
  },
  {
    "x": "sûbîyayê (m)",
    "y": " b. sûbîyayîye (m)"
  },
  {
    "x": "sûbîyayîş (n)",
    "y": " kızma (öfkelenme), sinirlenme, asabileşme"
  },
  {
    "x": "sûbîyayîye (m)",
    "y": " kızgın (öfkeli), sinirli"
  },
  {
    "x": "Sûdan (n)",
    "y": " Sudan"
  },
  {
    "x": "sûk (m)",
    "y": " b. sûke (m)"
  },
  {
    "x": "sûke (m)",
    "y": " çarşı"
  },
  {
    "x": "sûki (m)",
    "y": " b. sûke (m)"
  },
  {
    "x": "sûmîla (m)",
    "y": " b. şûmîla (m)"
  },
  {
    "x": "sûn-I (n)",
    "y": " b. şan (n)"
  },
  {
    "x": "sûn de",
    "y": " b. şan de"
  },
  {
    "x": "sûn-II (n)",
    "y": " b. sond (n)"
  },
  {
    "x": "sûnci (n)",
    "y": " b. sancî (n)"
  },
  {
    "x": "sûnci kerdene",
    "y": " b. sancî kerdene"
  },
  {
    "x": "sûnçi (n)",
    "y": " b. sancî (n)"
  },
  {
    "x": "sûnçi kerdene",
    "y": " b. sancî kerdene"
  },
  {
    "x": "sûnd (n)",
    "y": " b. sond (n)"
  },
  {
    "x": "sûndiq (m)",
    "y": " b. sindoqe (m)"
  },
  {
    "x": "sûne (n)",
    "y": " b. sane (n)"
  },
  {
    "x": "sûne bîyayene",
    "y": " b. sane bîyene"
  },
  {
    "x": "sûne kerdene",
    "y": " b. sane kerdene"
  },
  {
    "x": "sûng (n)",
    "y": " b. sung (n)"
  },
  {
    "x": "sûni (n)",
    "y": " b. sane-I (n)"
  },
  {
    "x": "sûnik (m)",
    "y": " b. sanike-I (m)"
  },
  {
    "x": "sûnike (m)",
    "y": " b. sanike-I (m)"
  },
  {
    "x": "sûntali (n)",
    "y": " b. sûntuwala (m)"
  },
  {
    "x": "sûntîm (m)",
    "y": " b. santîmetre (n)"
  },
  {
    "x": "sûntuwala (m)",
    "y": " kurutulmuş elma"
  },
  {
    "x": "sûnzîyer (m)",
    "y": " b. simzêre (m)"
  },
  {
    "x": "sûr, -e",
    "y": " 1)kırmızı, al\r\n~2)çok sıcak olan (yemek, içecek vb.), kızgın"
  },
  {
    "x": "sûr bîyene",
    "y": " 1)kırmızılaşmak, kızarmak, allaşmak\r\n~2)çok sıcak olmak (yemek, içecek vb. için), ısısı fazla olmak, kızgın olmak"
  },
  {
    "x": "sûr kerdene",
    "y": " 1)kırmızılaştırmak\r\n~2)kavurmak, kızartmak (et vb. için)"
  },
  {
    "x": "sûr û sipî",
    "y": " 1)kırmızı ve beyaz\r\n~2)alaca (kırmızı-beyaz renkli)"
  },
  {
    "x": "sûravêlk, -e",
    "y": " flamingo, flaman kuşu"
  },
  {
    "x": "sûrawe (m)",
    "y": " allık (al boya)"
  },
  {
    "x": "sûrbelek, -e",
    "y": " alaca (kırmızı-beyaz renkli)"
  },
  {
    "x": "sûrbine (m)",
    "y": " çıban, apse"
  },
  {
    "x": "sûrbîyaya (m)",
    "y": " kızgın"
  },
  {
    "x": "sûrbîyaya bîyene",
    "y": " kızgın olmak"
  },
  {
    "x": "sûrbîyaye (n)",
    "y": " kızgın"
  },
  {
    "x": "sûrbîyaye bîyene",
    "y": " kızgın olmak"
  },
  {
    "x": "sûrbîyayîye (m)",
    "y": " kızgın"
  },
  {
    "x": "sûrbîyayîye bîyene",
    "y": " kızgın olmak"
  },
  {
    "x": "sûrç, -e",
    "y": " b. sûrex, -e"
  },
  {
    "x": "sûrdim (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "sûrek (n)",
    "y": " bir çeşit darı"
  },
  {
    "x": "sûrete (m)",
    "y": " 1)b. suret (n)\r\n~2)b. surote (m)"
  },
  {
    "x": "sûrex, -e",
    "y": " kızıl"
  },
  {
    "x": "sûrgul (m)",
    "y": " b. sûrgule (m)"
  },
  {
    "x": "sûrgule (m)",
    "y": " yabangülü"
  },
  {
    "x": "sûrgulêre (m)",
    "y": " kuşburnu ağacı, yabangülü ağacı"
  },
  {
    "x": "sûrgulik (m)",
    "y": " b. sûrgule (m)"
  },
  {
    "x": "sûrince (m)",
    "y": " b. surince (m)"
  },
  {
    "x": "sûrinci (m)",
    "y": " b. surince (m)"
  },
  {
    "x": "sûrinci vetene",
    "y": " b. surince vetene"
  },
  {
    "x": "sûrî (m)",
    "y": " bir üzüm çeşidi"
  },
  {
    "x": "sûrîye-I (m)",
    "y": " kırmızılık, allık"
  },
  {
    "x": "sûrîye-II (m)",
    "y": " b. surîye-II (m)"
  },
  {
    "x": "Sûrîye (m)",
    "y": " Suriye"
  },
  {
    "x": "sûrkerda (m)",
    "y": " kavrulmuş olan (et vb. için), kızartılmış olan (et vb. için), kızartma (et vb. için)"
  },
  {
    "x": "sûrkerde (n)",
    "y": " kavrulmuş olan (et vb. için), kızartılmış olan (et vb. için), kızartma (et vb. için)"
  },
  {
    "x": "sûrkerdiş (n)",
    "y": " kavurma, kızartma (et vb. için)"
  },
  {
    "x": "sûrkerdîye (m)",
    "y": " kavrulmuş olan (et vb. için), kızartılmış olan(et vb. için), kızartma (et vb. için)"
  },
  {
    "x": "sûrni (n)",
    "y": " b. sifirne (n)"
  },
  {
    "x": "sûs (n)",
    "y": " meyankökü, meyan"
  },
  {
    "x": "sûsik (n)",
    "y": " meyankökü, meyan"
  },
  {
    "x": "sûsiknaya (m)",
    "y": " süslü"
  },
  {
    "x": "sûsiknaye (n)",
    "y": " süslü"
  },
  {
    "x": "sûsiknayî (m)",
    "y": " b. sûsiknayîye (m)"
  },
  {
    "x": "sûsiknayîye (m)",
    "y": " süslü"
  },
  {
    "x": "sûtal, -e",
    "y": " avare, aylak, serseri, ayaktakımı, lümpen, parya"
  },
  {
    "x": "sûtal bîyene",
    "y": " avareleşmek"
  },
  {
    "x": "sûtal kerdene",
    "y": " avareleştirmek"
  },
  {
    "x": "sûtalbîyayîş (n)",
    "y": " avareleşme"
  },
  {
    "x": "sûtaley (m)",
    "y": " b. sûtalîye (m)"
  },
  {
    "x": "sûtalê (m)",
    "y": " b. sûtalî (m)"
  },
  {
    "x": "sûtalî (m)",
    "y": " avarelik, aylaklık, serserilik"
  },
  {
    "x": "sûtalî kerdene",
    "y": " aylaklık etmek"
  },
  {
    "x": "sûtalîkerdiş (n)",
    "y": " aylaklık etme"
  },
  {
    "x": "sûtalîye (m)",
    "y": " avarelik, aylaklık, serserilik"
  },
  {
    "x": "sûtalkerdiş (n)",
    "y": " avareleştirme"
  },
  {
    "x": "sûterî, -ye",
    "y": " b. soterî, -ye"
  },
  {
    "x": "sûwax (n)",
    "y": " b. siwax (n)"
  },
  {
    "x": "sûyêxoardiş (n)",
    "y": " kızma (öfkelenme, sinirlenme), asabileşme"
  },
  {
    "x": "sweli (m)",
    "y": " b. sole-I (m)"
  },
  {
    "x": "swend (n)",
    "y": " b. sond (n)"
  },
  {
    "x": "Swêd (n)",
    "y": " İsveç"
  },
  {
    "x": "swêdkî (m)",
    "y": " İsveççe"
  },
  {
    "x": "Swîs (n)",
    "y": " İsviçre"
  },
  {
    "x": "ŞŞŞ"
  },
  {
    "x": "şa-I (n)",
    "y": " b. şan (n)"
  },
  {
    "x": "şa, -ye -II",
    "y": " b. sîya, -ye"
  },
  {
    "x": "şa, -ye -III",
    "y": " keyifli, sevinçli, şen, neşeli"
  },
  {
    "x": "şa bîyene",
    "y": " neşelenmek, sevinmek, memnun kalmak, memnun olmak"
  },
  {
    "x": "şa kerdene",
    "y": " neşelendirmek, sevindirmek, memnun etmek\r\n~\tTo ma şa kerdî, boka Homa zî to şa biko. (Sen bizi sevindirdin, inşallah Tanrı da seni sevindirir.)"
  },
  {
    "x": "şa-IV (n)",
    "y": " b. şah (n)"
  },
  {
    "x": "şa-V (zh, z)",
    "y": " b. şima-I (zh, z)"
  },
  {
    "x": "şaar (n)",
    "y": " b. şehîr (n)"
  },
  {
    "x": "şabalu (m)",
    "y": " b. şabelû (m)"
  },
  {
    "x": "şabaluye (m)",
    "y": " b. şabelû (m)"
  },
  {
    "x": "şabelî (m)",
    "y": " b. şabelû (m)"
  },
  {
    "x": "şabelû (m)",
    "y": " bir tür büyük palamut"
  },
  {
    "x": "şabelûyêre (m)",
    "y": " palamudu büyük bir tür meşe ağacı"
  },
  {
    "x": "şabey ... ser şîyayene",
    "y": " b. şabey ... ser şîyene"
  },
  {
    "x": "şabey ... ser şîyene",
    "y": " -e benzemek"
  },
  {
    "x": "şabey ta kewtene",
    "y": " -e benzemek"
  },
  {
    "x": "şabik (n)",
    "y": " b. şehbik (n)"
  },
  {
    "x": "şabilî (m)",
    "y": " b. şabelû (m)"
  },
  {
    "x": "şablêr (m)",
    "y": " b. şabelûyêre (m)"
  },
  {
    "x": "şabliwêr (m)",
    "y": " b. şabelûyêre (m)"
  },
  {
    "x": "şablowîr (m)",
    "y": " b. şabelûyêre (m)"
  },
  {
    "x": "şabot (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "şad, -e",
    "y": " b. şa, -ye-III"
  },
  {
    "x": "şada (m)",
    "y": " b. şahide (m)"
  },
  {
    "x": "şade (n)",
    "y": " b. şahid (n)"
  },
  {
    "x": "şadênî (m)",
    "y": " b. şahidîye (m)"
  },
  {
    "x": "şadi (m)",
    "y": " b. şaye (m)"
  },
  {
    "x": "şadî (m)",
    "y": " b. şayî (m), şayîye (m)"
  },
  {
    "x": "şaetnayene",
    "y": " b. şehtnayene"
  },
  {
    "x": "şaetnayîş (n)",
    "y": " b. şehtnayîş (n)"
  },
  {
    "x": "şaetyaye (n)",
    "y": " b. şehtîyaye (n)"
  },
  {
    "x": "şaetyayene",
    "y": " b. şehtîyayene"
  },
  {
    "x": "şaetyayê (m)",
    "y": " b. şehtîyaya (m), şehtîyayîye (m)"
  },
  {
    "x": "şaetyayîş (n)",
    "y": " b. şehtîyayîş (n)"
  },
  {
    "x": "şafî, -ye",
    "y": " Şafii"
  },
  {
    "x": "şafîtî (m)",
    "y": " Şafiilik"
  },
  {
    "x": "şafîyenî (m)",
    "y": " b. şafîyîye (m)"
  },
  {
    "x": "şafîyey (m)",
    "y": " b. şafîyîye (m)"
  },
  {
    "x": "şafîyê (m)",
    "y": " b. şafîyî (m)"
  },
  {
    "x": "şafîyî (m)",
    "y": " Şafiilik"
  },
  {
    "x": "şafîyîye (m)",
    "y": " Şafiilik"
  },
  {
    "x": "şagird, -e",
    "y": " çırak, çömez, şakirt, öğrenci, talebe"
  },
  {
    "x": "şagirdîye (m)",
    "y": " çıraklık, çömezlik, öğrencilik, talebelik"
  },
  {
    "x": "şagirt, -e",
    "y": " b. şagird, -e"
  },
  {
    "x": "şagul (n)",
    "y": " b. şaqul (n)"
  },
  {
    "x": "şagul pede verdayene",
    "y": " b. şaqul pede veradayene"
  },
  {
    "x": "şagul ro verdayene",
    "y": " b. şaqul pede veradayene"
  },
  {
    "x": "şah (n)",
    "y": " 1)şah\r\n~2)bir balık çeşidi"
  },
  {
    "x": "şahad",
    "y": " b. şahid, -e"
  },
  {
    "x": "şahar (n)",
    "y": " b. şehîr (n)"
  },
  {
    "x": "şahbeyte (m)",
    "y": " şahbeyit"
  },
  {
    "x": "şaheser (n)",
    "y": " başeser, şaheser"
  },
  {
    "x": "şahid, -e",
    "y": " tanık, şahit "
  },
  {
    "x": "şahida çiman (m)",
    "y": " görgü tanığı"
  },
  {
    "x": "şahidê çiman (n)",
    "y": " görgü tanığı"
  },
  {
    "x": "şahidîye (m)",
    "y": " tanıklık, şahitlik "
  },
  {
    "x": "şahîn (n)",
    "y": " şahin"
  },
  {
    "x": "şahîri (n)",
    "y": " b. şerîye (n)"
  },
  {
    "x": "şahre (n)",
    "y": " b. şerîye (n)"
  },
  {
    "x": "şahre (m)",
    "y": " b. şare (m)"
  },
  {
    "x": "şahrestan (n)",
    "y": " b. şaristan (n)"
  },
  {
    "x": "şahri (m)",
    "y": " b. şare (m)"
  },
  {
    "x": "şahristan (n)",
    "y": " b. şaristan (n)"
  },
  {
    "x": "şahtîyaya (m)",
    "y": " b. şehtîyaya (m)"
  },
  {
    "x": "şahtîyaye (n)",
    "y": " b. şehtîyaye (n)"
  },
  {
    "x": "şahtîyayene",
    "y": " b. şehtîyayene"
  },
  {
    "x": "şahtîyayîş (n)",
    "y": " b. şehtîyayîş (n)"
  },
  {
    "x": "şahtnayene",
    "y": " b. şehtnayene"
  },
  {
    "x": "şahtnayîş (n)",
    "y": " b. şehtnayîş (n)"
  },
  {
    "x": "şaîr, -e",
    "y": " 1)ozan, şair\r\n~2)ağıtçı, ağlayıcı, sagucu, şarkıcı, türkücü"
  },
  {
    "x": "şaîra sazbende",
    "y": " saz şairi"
  },
  {
    "x": "şaîro sazbend",
    "y": " saz şairi"
  },
  {
    "x": "şaîrane",
    "y": " şairane, şairce"
  },
  {
    "x": "şaîrkî",
    "y": " şairane, şairce"
  },
  {
    "x": "şak (n)",
    "y": " ceket"
  },
  {
    "x": "şakê hamnanî (n)",
    "y": " yazlık ceket"
  },
  {
    "x": "şak û şalwarî (zh)",
    "y": " b. şak û şelwarî (zh)"
  },
  {
    "x": "şak û şelwarî (zh)",
    "y": " ceket ve şalvar, kostüm"
  },
  {
    "x": "şakê kabeyî (n)",
    "y": " aşık kemiğinin ön veya arka tarafı"
  },
  {
    "x": "şakê kabî (n)",
    "y": " b. şakê kabeyî (n)"
  },
  {
    "x": "şakameyîş (n)",
    "y": " aşık kemiğinin ön veya arka tarafı üzerinde dikey biçimde durması"
  },
  {
    "x": "şakil (n)",
    "y": " kavrak, haşiş, kuru yaprak"
  },
  {
    "x": "şakîl (n)",
    "y": " b. şakil (n)"
  },
  {
    "x": "şakkewtiş (n)",
    "y": " aşık kemiğinin ön veya arka tarafı üzerinde dikey biçimde durması"
  },
  {
    "x": "şako (n)",
    "y": " b. saqo (n)"
  },
  {
    "x": "şakomeyîş (n)",
    "y": " b. şakameyîş (n)"
  },
  {
    "x": "şakul (n)",
    "y": " b. şaqul (n)"
  },
  {
    "x": "ra şakul ardene",
    "y": " b. ra şaqul ardene"
  },
  {
    "x": "şakul veradayene",
    "y": " b. şaqul veradayene"
  },
  {
    "x": "şakule (n)",
    "y": " b. şaqul (n)"
  },
  {
    "x": "şakûle (n)",
    "y": " b. şaqul (n)"
  },
  {
    "x": "şakûmeyîş (n)",
    "y": " b. şakameyîş (n)"
  },
  {
    "x": "şakweriştiş (n)",
    "y": " aşık kemiğinin ön veya arka tarafı üzerinde dikey biçimde durması"
  },
  {
    "x": "şal-I (n)",
    "y": " 1)şal (kumaş) \r\n~2)bulaşık bezi"
  },
  {
    "x": "şala xoeştişî (m)",
    "y": " atlama çarşafı"
  },
  {
    "x": "şal firaqûn (n)",
    "y": " b. şalê firaqan (n)"
  },
  {
    "x": "şalê firaqan (n)",
    "y": " bulaşık bezi"
  },
  {
    "x": "şal-II (m)",
    "y": " b. şale (m)"
  },
  {
    "x": "şal-III (zh)",
    "y": " b. şalî-I (zh)"
  },
  {
    "x": "şal û şapikî (zh)",
    "y": " ceket ve şalvar, kostüm"
  },
  {
    "x": "şal û şapîk (zh)",
    "y": " b. şal û şapikî (zh)"
  },
  {
    "x": "şalag (m)",
    "y": " b. şalage (m)"
  },
  {
    "x": "şalage (m)",
    "y": " insan sırtında taşınan yük"
  },
  {
    "x": "şalagi (m)",
    "y": " b. şalage (m)"
  },
  {
    "x": "şalakî (n)",
    "y": " şalaki (şal taklidi kumaş)"
  },
  {
    "x": "şalbarî (zh)",
    "y": " b. şelwarî (zh)"
  },
  {
    "x": "şalbat (m)",
    "y": " b. şelbûte (m)"
  },
  {
    "x": "şalbêr (m)",
    "y": " b. şabelûyêre (m)"
  },
  {
    "x": "şale (m)",
    "y": " 1)çarşaf\r\n~2)şal (kadınların, omuzlarını örtmek için kullandıkları)\r\n~3)örtü olarak kullanılan bez\r\n~4)kuşak"
  },
  {
    "x": "şala Ecemî (m)",
    "y": " Acem kuşağı"
  },
  {
    "x": "şale bestene",
    "y": " kuşak bağlamak"
  },
  {
    "x": "şale girê dayene",
    "y": " kuşak bağlamak"
  },
  {
    "x": "şale pîştene",
    "y": " kuşak bağlamak "
  },
  {
    "x": "şalên, -e",
    "y": " kahverengi"
  },
  {
    "x": "şalge (n)",
    "y": " b. şarge (n)"
  },
  {
    "x": "şalge kerdeni",
    "y": " b. şarge kerdene"
  },
  {
    "x": "şali (m)",
    "y": " b. şale (m)"
  },
  {
    "x": "şalike (m)",
    "y": " örtü olarak kullanılan bez"
  },
  {
    "x": "şalika nimajî (m)",
    "y": " namazlık"
  },
  {
    "x": "şalika nimacî (m)",
    "y": " b. şalika nimajî (m)"
  },
  {
    "x": "şalî-I (zh)",
    "y": " şalvar, çakşır"
  },
  {
    "x": "şal û şapikî (zh)",
    "y": " ceket ve şalvar, kostüm"
  },
  {
    "x": "şalî-II (n)",
    "y": " şali"
  },
  {
    "x": "şalpa (m)",
    "y": " b. şarpa (m)"
  },
  {
    "x": "şalpîya fekî (m)",
    "y": " b. şelpîya fekî (m)"
  },
  {
    "x": "şalul (n)",
    "y": " b. şalûl (n)"
  },
  {
    "x": "şalûl (n)",
    "y": " baştankara"
  },
  {
    "x": "şalvar (zh)",
    "y": " b. şelwarî (zh)"
  },
  {
    "x": "şalvêr-I (m)",
    "y": " b. şabelûyêre (m)"
  },
  {
    "x": "şalvêr-II (zh)",
    "y": " b. şelwarî (zh)"
  },
  {
    "x": "şalwalî (zh)",
    "y": " b. şelwarî (zh)"
  },
  {
    "x": "şalwar (zh)",
    "y": " b. şelwarî (zh)"
  },
  {
    "x": "şalwarî (zh)",
    "y": " b. şelwarî (zh)"
  },
  {
    "x": "şam (m)",
    "y": " b. şamî (m)"
  },
  {
    "x": "Şam (n)",
    "y": " Şam"
  },
  {
    "x": "şama (m)",
    "y": " başörtü, başörtüsü"
  },
  {
    "x": "şamata (m)",
    "y": " b. şemate (n)"
  },
  {
    "x": "şamdane (m)",
    "y": " mumluk, şamdan, şamdanlık"
  },
  {
    "x": "şamdani (m)",
    "y": " b. şamdane (m)"
  },
  {
    "x": "şame (m)",
    "y": " b. şamî (m)"
  },
  {
    "x": "şamek (m)",
    "y": " b. şanike-I (m)"
  },
  {
    "x": "şameki (m)",
    "y": " b. şanike-I (m)"
  },
  {
    "x": "şami (m)",
    "y": " b. şamî (m)"
  },
  {
    "x": "şamik (m)",
    "y": " b. şamike (m)"
  },
  {
    "x": "şamike (m)",
    "y": " domates"
  },
  {
    "x": "şamiki-I (m)",
    "y": " b. şamike (m)"
  },
  {
    "x": "şamiki-II (m)",
    "y": " b. şanike-I (m)"
  },
  {
    "x": "şamî (m)",
    "y": " akşam yemeği"
  },
  {
    "x": "şamîl bîyene",
    "y": " kapsamak"
  },
  {
    "x": "şamîlbîyayîş (n)",
    "y": " kapsama"
  },
  {
    "x": "şampanya (m)",
    "y": " şampanya "
  },
  {
    "x": "şampîyon, -e",
    "y": " şampiyon"
  },
  {
    "x": "şampuan (n)",
    "y": " şampuan "
  },
  {
    "x": "şan (n)",
    "y": " akşam\r\n~şan de: akşamleyin\r\n~Şanbaş. (İyi akşamlar.)\r\n~Şanê to xeyr bo. (İyi akşamlar)"
  },
  {
    "x": "şan de",
    "y": " akşamleyin)"
  },
  {
    "x": "şana (m)",
    "y": " b. şiwana (m)"
  },
  {
    "x": "şanayene",
    "y": " 1)dayamak (yaslamak)\r\n~2)yaymak\r\n~3)serpmek"
  },
  {
    "x": "şanayîş (n)",
    "y": " 1)dayama (yaslama)\r\n~2)yayma\r\n~3)serpme"
  },
  {
    "x": "şand (n)",
    "y": " b. şan (n)"
  },
  {
    "x": "şande (n)",
    "y": " b. şan (n)"
  },
  {
    "x": "şane-I (n)",
    "y": " tarak"
  },
  {
    "x": "şane kerdene",
    "y": " taramak"
  },
  {
    "x": "şanekê marî (n)",
    "y": " b. şaneyê marî (n)"
  },
  {
    "x": "şaney marî (n)",
    "y": " b. şaneyê marî (n)"
  },
  {
    "x": "şaneyê maran (n)",
    "y": " b. şaneyê marî (n)"
  },
  {
    "x": "şaneyê marî (n)",
    "y": " kırkayak"
  },
  {
    "x": "şanê marî (n)",
    "y": " b. şaneyê marî (n)"
  },
  {
    "x": "şane-II (n)",
    "y": " başak"
  },
  {
    "x": "şaneyê xeleyî (n)",
    "y": " buğday başağı"
  },
  {
    "x": "şaneyê xelî (n)",
    "y": " b. şaneyê xeleyî (n)"
  },
  {
    "x": "şane-III (n)",
    "y": " b. şiwane (n)"
  },
  {
    "x": "şanekerdiş",
    "y": " tarama"
  },
  {
    "x": "şaneşîye (m)",
    "y": " balkon, ayvan, eyvan"
  },
  {
    "x": "şaneyey (m)",
    "y": " b. şiwaneyî (m)"
  },
  {
    "x": "şaneyî (m)",
    "y": " b. şiwaneyî (m)"
  },
  {
    "x": "şani (n)",
    "y": " b. şane (n)"
  },
  {
    "x": "şanike-I (m)",
    "y": " ben"
  },
  {
    "x": "şanike-II (m)",
    "y": " b. sanike-I (m)"
  },
  {
    "x": "şanitiş (n)",
    "y": " yayma"
  },
  {
    "x": "şanîyayene",
    "y": " yayılmak"
  },
  {
    "x": "şanîyayîş (n)",
    "y": " yayılma"
  },
  {
    "x": "Şankuş (n)",
    "y": " Çüngüş"
  },
  {
    "x": "Şano (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "şans (n)",
    "y": " şans, talih"
  },
  {
    "x": "şantîya (m)",
    "y": " b. şantîye (m)"
  },
  {
    "x": "şantîye (m)",
    "y": " şantiye"
  },
  {
    "x": "şapik (n)",
    "y": " yünden örülen bir tür ceket (yünden örülme, boyu ve kolları kısa ceket)"
  },
  {
    "x": "şal û şapikî (zh)",
    "y": " ceket ve şalvar, kostüm"
  },
  {
    "x": "şapik (zh)",
    "y": " b. şapikî (zh)"
  },
  {
    "x": "şapikî (zh)",
    "y": " pantolon\r\n~Ey şapikê xo vetî. (O pantolonunu çıkardı.)"
  },
  {
    "x": "şapikê blûcînî (zh)",
    "y": " blucin pantolon"
  },
  {
    "x": "şapikê kilmî (zh)",
    "y": " kısa pantolon"
  },
  {
    "x": "şapikê kirrî (zh)",
    "y": " kısa pantolon"
  },
  {
    "x": "şapikê qedîfeyênî (zh)",
    "y": " kadife pantolon"
  },
  {
    "x": "şapikê qolî (zh)",
    "y": " kısa pantolon"
  },
  {
    "x": "şapîk (zh)",
    "y": " b. şapikî (zh)"
  },
  {
    "x": "şaplore (m)",
    "y": " alerji (yiyecekten olan alerji)"
  },
  {
    "x": "şaplore vetene",
    "y": " alerji olmak"
  },
  {
    "x": "şaplorekerdoxî (zh)",
    "y": " alerji yapıcılar"
  },
  {
    "x": "şapqa (m)",
    "y": " b. şewqa (m)"
  },
  {
    "x": "şapqe (n)",
    "y": " b. şewqa (m)"
  },
  {
    "x": "şaq (n)",
    "y": " b. şeqe (m)"
  },
  {
    "x": "şaqe (m)",
    "y": " b. şeqe (m)"
  },
  {
    "x": "şaqi (m)",
    "y": " b. şeqe (m)"
  },
  {
    "x": "şaqol (n)",
    "y": " b. şaqul (n)"
  },
  {
    "x": "şaqole (m)",
    "y": " b. şaqul (n)"
  },
  {
    "x": "şaqole piro veradayîne",
    "y": " b. şaqul pede veradayene"
  },
  {
    "x": "şaqolî (m)",
    "y": " b. şaqul (n)"
  },
  {
    "x": "şaqul (n)",
    "y": " şakul, çekül, şavul"
  },
  {
    "x": "ra şaqul ardene",
    "y": " şakullemek, şavullamak"
  },
  {
    "x": "şaqul kerdene",
    "y": " şakullemek, şavullamak"
  },
  {
    "x": "şaqul pede veradayene",
    "y": " şakullemek, şavullamak"
  },
  {
    "x": "şaqul ro dayene",
    "y": " şakullemek, şavullamak"
  },
  {
    "x": "şaqul ver ra nayene",
    "y": " b. şaqul pede veradayene"
  },
  {
    "x": "şaqul veradayene",
    "y": " şakullemek, şavullamak"
  },
  {
    "x": "şar-I (n)",
    "y": " 1)halk, cumhur\r\n~şarê keyeyî (n): hane halkı\r\n~şarî reyra têkilîye (m): halkla ilişki\r\n~şarî reyra têkilîyî (zh): halkla ilişkiler\r\n~2)el (yabancı)"
  },
  {
    "x": "şarê keyeyî (n)",
    "y": " hane halkı"
  },
  {
    "x": "şarî reyra têkilîye (m)",
    "y": " halkla ilişki"
  },
  {
    "x": "şarî reyra têkilîyî (zh)",
    "y": " halkla ilişkiler"
  },
  {
    "x": "şar-II (m)",
    "y": " b. şare (m)"
  },
  {
    "x": "şarab (n)",
    "y": " b. şerab (n)"
  },
  {
    "x": "şarb (n)",
    "y": " b. şerb (n)"
  },
  {
    "x": "şarbet (n)",
    "y": " b. şerbet (n)"
  },
  {
    "x": "şarbik-I (n)",
    "y": " küçük testi"
  },
  {
    "x": "şarbik-II (n)",
    "y": " b. şerbik-I (n)"
  },
  {
    "x": "şare (m)",
    "y": " 1)başörtü, başörtüsü, poşu, poçu\r\n~2)kuşak"
  },
  {
    "x": "şaredarî (m)",
    "y": " belediye\r\n~şaredarîya şaristanê girdî: büyükşehir belediyesi"
  },
  {
    "x": "şaredarîya şaristanê girdî",
    "y": " büyükşehir belediyesi"
  },
  {
    "x": "mîsyonê şaredarî",
    "y": " belediye misyonu"
  },
  {
    "x": "vîzyonê şaredarî",
    "y": " belediye vizyonu"
  },
  {
    "x": "şaresto (n)",
    "y": " b. şaristan (n)"
  },
  {
    "x": "şareston (n)",
    "y": " b. şaristan (n)"
  },
  {
    "x": "şarestû (n)",
    "y": " b. şaristan (n)"
  },
  {
    "x": "şarestûn (n)",
    "y": " b. şaristan (n)"
  },
  {
    "x": "şarge (n)",
    "y": " meyve veya sebze saklanan yer"
  },
  {
    "x": "şarge kerdene",
    "y": " (ev dışında) meyve veya sebze saklamak "
  },
  {
    "x": "şari (m)",
    "y": " b. şare (m)"
  },
  {
    "x": "şarike (m)",
    "y": " başörtü, başörtüsü"
  },
  {
    "x": "şarista (n)",
    "y": " b. şaristan (n)"
  },
  {
    "x": "şaristan (n)",
    "y": " il, vilayet, kent, şehir\r\n~Erzingan şaristanêko kan o. (Erzincan eski bir kenttir.)"
  },
  {
    "x": "şaristan bîyene",
    "y": " kentleşmek, şehirleşmek"
  },
  {
    "x": "şaristano baxçedar (n)",
    "y": " bahçeli kent"
  },
  {
    "x": "şaristano bibaxçe (n)",
    "y": " bahçeli kent"
  },
  {
    "x": "şaristano gird (n)",
    "y": " büyükşehir, büyükkent, metropol; anakent"
  },
  {
    "x": "şaristano kan/kehen (n)",
    "y": " eski kent, eski şehir"
  },
  {
    "x": "şaristano peyk (n)",
    "y": " uydu kent"
  },
  {
    "x": "şaristanawanî (m)",
    "y": " şehircilik"
  },
  {
    "x": "şaristanawanîye (m)",
    "y": " şehircilik"
  },
  {
    "x": "şaristanbîyayîş (n)",
    "y": " kentleşme, şehirleşme"
  },
  {
    "x": "şaristanek (n)",
    "y": " belde"
  },
  {
    "x": "şaristanij, -e",
    "y": " kentli, şehirli "
  },
  {
    "x": "şaristaniji (m)",
    "y": " b. şaristanije (m)"
  },
  {
    "x": "şaristankî, -ye",
    "y": " kentsel"
  },
  {
    "x": "şaristo (n)",
    "y": " b. şaristan (n)"
  },
  {
    "x": "şariston (n)",
    "y": " b. şaristan (n)"
  },
  {
    "x": "şaristonic, -i",
    "y": " b. şaristanij, -e"
  },
  {
    "x": "şaristû (n)",
    "y": " b. şaristan (n)"
  },
  {
    "x": "şaristûn (n)",
    "y": " b. şaristan (n)"
  },
  {
    "x": "şaristûnic, -i",
    "y": " b. şaristanij, -e"
  },
  {
    "x": "şariştan (n)",
    "y": " b. şaristan (n)"
  },
  {
    "x": "şarişton (n)",
    "y": " b. şaristan (n)"
  },
  {
    "x": "şarj (n)",
    "y": " şarj\r\n~Şarj nêbeno/a. / Bar nêbeno/a. (Şarj edilmiyor.)"
  },
  {
    "x": "şarm (n)",
    "y": " b. şerm (n)"
  },
  {
    "x": "şarm kerdene",
    "y": " b. şerm kerdene"
  },
  {
    "x": "şarmi (n)",
    "y": " b. şerme (n)"
  },
  {
    "x": "şarmîyayene",
    "y": " b. şermayene"
  },
  {
    "x": "şarmîyayîş (n)",
    "y": " b. şermayîş (n)"
  },
  {
    "x": "şarmkerdiş (n)",
    "y": " b. şermkerdiş (n)"
  },
  {
    "x": "şarnas, -e",
    "y": " halkbilimci, folklorcu"
  },
  {
    "x": "şarnasî (m)",
    "y": " halkbilim, folklor"
  },
  {
    "x": "şarnasîye (m)",
    "y": " halkbilim, folklor"
  },
  {
    "x": "şarp (m)",
    "y": " b. şarpa (m)"
  },
  {
    "x": "şarpa (m)",
    "y": " başörtü, başörtüsü"
  },
  {
    "x": "şarpî (m)",
    "y": " b. şarpa (m)"
  },
  {
    "x": "şarpîye (m)",
    "y": " b. şarpa (m)"
  },
  {
    "x": "şart (n)",
    "y": " b. şert (n)"
  },
  {
    "x": "şarwet (n)",
    "y": " b. şerbet (n)"
  },
  {
    "x": "şaryo (n, sn)",
    "y": " şaryo"
  },
  {
    "x": "şaş, -e-I",
    "y": " hatalı, yanlış"
  },
  {
    "x": "şaş mendene",
    "y": " afallamak, şaşakalmak, bakakalmak, ağzı açık kalmak (mecazi), ağzı bir karış açık kalmak (mecazi)"
  },
  {
    "x": "şaş-II (m)",
    "y": " b. şaşe (m)"
  },
  {
    "x": "şaşe (m)",
    "y": " sarık"
  },
  {
    "x": "şaşey (m)",
    "y": " b. şaşîye (m), şaşî (m)"
  },
  {
    "x": "şaşê (m)",
    "y": " b. şaşîye (m), şaşî (m)"
  },
  {
    "x": "şaşênî (m)",
    "y": " hata, yanlışlık"
  },
  {
    "x": "şaşi (m)",
    "y": " b. şaşe (m)"
  },
  {
    "x": "şaşike (m)",
    "y": " sarık"
  },
  {
    "x": "şaşiki (m)",
    "y": " b. şaşike (m)"
  },
  {
    "x": "şaşî (m)",
    "y": " hata, yanlışlık"
  },
  {
    "x": "şaşîye (m)",
    "y": " hata, yanlışlık"
  },
  {
    "x": "şaşmendiş (n)",
    "y": " afallama, şaşakalma, bakakalma, ağzı açık kalma (mecazi), ağzı bir karış açık kalma (mecazi)"
  },
  {
    "x": "şateyîş (n)",
    "y": " b. şehtîyayîş (n)"
  },
  {
    "x": "şatik (n)",
    "y": " ağaç gövdesi"
  },
  {
    "x": "şatike (m)",
    "y": " ense"
  },
  {
    "x": "şatîyaya (m)",
    "y": " b. şehtîyaya (m), şehtiyayîye (m)"
  },
  {
    "x": "şatîyaye (n)",
    "y": " b. şehtîyaye (n)"
  },
  {
    "x": "şatîyayene",
    "y": " b. şehtîyayene"
  },
  {
    "x": "şatîyayeyey (m)",
    "y": " b. şehtîyayeyî (m), şehtîyayeyîye (m)"
  },
  {
    "x": "şatîyayeyê (m)",
    "y": " b. şehtîyayeyî (m), şehtîyayeyîye (m)"
  },
  {
    "x": "şatîyayê (m)",
    "y": " b. şehtîyaya (m), şehtiyayîye (m)"
  },
  {
    "x": "şatîyayîş (n)",
    "y": " b. şehtîyayîş (n)"
  },
  {
    "x": "şatnayene",
    "y": " b. şehtnayene"
  },
  {
    "x": "şatnayîş (n)",
    "y": " b. şehtnayîş (n)"
  },
  {
    "x": "şatyaya (m)",
    "y": " b. şehtîyaya (m), şehtiyayîye (m)"
  },
  {
    "x": "şatyaye (n)",
    "y": " b. şehtîyaye (n)"
  },
  {
    "x": "şatyayene",
    "y": " b. şehtîyayene"
  },
  {
    "x": "şatyayeyey (m)",
    "y": " b. şehtîyayeyî (m), şehtîyayeyîye (m)"
  },
  {
    "x": "şatyayeyê (m)",
    "y": " b. şehtîyayeyî (m), şehtîyayeyîye (m)"
  },
  {
    "x": "şatyayîş (n)",
    "y": " b. şehtîyayîş (n)"
  },
  {
    "x": "şavaq (n)",
    "y": " b. şefeq (n)"
  },
  {
    "x": "şavile (m)",
    "y": " şakayık"
  },
  {
    "x": "şavlêri (m)",
    "y": " b. şabelûyêre (m)"
  },
  {
    "x": "şavlîr (m)",
    "y": " b. şabelûyêre (m)"
  },
  {
    "x": "şawite (banka, postexane ûsn.) (n)",
    "y": " havale"
  },
  {
    "x": "şawitene",
    "y": " göndermek, yollamak"
  },
  {
    "x": "şawitiş (n)",
    "y": " gönderme, yollama"
  },
  {
    "x": "şawni (n)",
    "y": " b. şiwane (n)"
  },
  {
    "x": "şawqe (n)",
    "y": " b. şewqa (m)"
  },
  {
    "x": "şawutene",
    "y": " b. şawitene"
  },
  {
    "x": "şawutiş (n)",
    "y": " b. şawitiş (n)"
  },
  {
    "x": "şax-I (n)",
    "y": " 1)dal, branş\r\n~2)çatal (dal için)"
  },
  {
    "x": "şax-II (n)",
    "y": " b. siwax (n)"
  },
  {
    "x": "şaxabî (m)",
    "y": " b. xeşebî (m)"
  },
  {
    "x": "şaxaf (m)",
    "y": " b. xaşabî (m)"
  },
  {
    "x": "şaxsî, -ye",
    "y": " özel, hususi"
  },
  {
    "x": "şayaxî (zh)",
    "y": " pantolon\r\n~\tEy şayaxê xo vetî. (O pantolonunu çıkardı.)"
  },
  {
    "x": "şayaxê blûcînî (zh)",
    "y": " blucin pantolon"
  },
  {
    "x": "şayaxê kilmî (zh)",
    "y": " kısa pantolon"
  },
  {
    "x": "şayaxê kirrî (zh)",
    "y": " kısa pantolon"
  },
  {
    "x": "şayaxê qedîfeyênî (zh)",
    "y": " kadife pantolon"
  },
  {
    "x": "şayaxê qolî (zh)",
    "y": " kısa pantolon"
  },
  {
    "x": "şaye (m)",
    "y": " keyifli, sevinçli, şen, neşeli"
  },
  {
    "x": "şayene-I",
    "y": " edebilmek, yapabilmek, muktedir olmak"
  },
  {
    "x": "şayene-II",
    "y": " b. şîyene"
  },
  {
    "x": "şayeni",
    "y": " b. şayene-I"
  },
  {
    "x": "şayey (m)",
    "y": " b. şayî (m), şayîye (m)"
  },
  {
    "x": "şayê (m)",
    "y": " b. şayî (m), şayîye (m)"
  },
  {
    "x": "şayî (m)",
    "y": " 1)neşe, sevinç, şenlik (şen olma durumu), eğlence, cümbüş\r\n~2)eğlenti, şenlik (belli günlerde yapılan şenlik)"
  },
  {
    "x": "şayî ci kotene",
    "y": " b. şayî dekewtene"
  },
  {
    "x": "şayî dekewtene",
    "y": " neşelenmek"
  },
  {
    "x": "şayî kerdene",
    "y": " eğlenmek"
  },
  {
    "x": "şayîkerdiş (n)",
    "y": " eğlenme"
  },
  {
    "x": "şayîr, -e",
    "y": " b. şaîr, -e"
  },
  {
    "x": "şayîş-I (n)",
    "y": " edebilme, yapabilme, muktedir olma"
  },
  {
    "x": "şayîş-II (n)",
    "y": " b. şîyayîş (n)"
  },
  {
    "x": "şayîye (m)",
    "y": " 1)neşe, sevinç, şenlik (şen olma durumu)\r\n~2)şenlik (belli günlerde yapılan şenlik)"
  },
  {
    "x": "şe-I",
    "y": " b. seyr"
  },
  {
    "x": "şe kerdene",
    "y": " b. seyr kerdene"
  },
  {
    "x": "şe-II",
    "y": " b. şa, -ye -III"
  },
  {
    "x": "şe kerdene-I",
    "y": " edebilmek, yapabilmek, muktedir olmak"
  },
  {
    "x": "şe kerdene-II",
    "y": " tanımak"
  },
  {
    "x": "şeb (n)",
    "y": " şap"
  },
  {
    "x": "şebab (m)",
    "y": " b. şebabe (m)"
  },
  {
    "x": "şebabe (m)",
    "y": " kaval"
  },
  {
    "x": "şebake (m)",
    "y": " b. şibake (m)"
  },
  {
    "x": "şebaki (m)",
    "y": " b. şibake (m)"
  },
  {
    "x": "şebek, -e",
    "y": " şebek"
  },
  {
    "x": "şebeynayen (m)",
    "y": " b. şebeynayene (m)"
  },
  {
    "x": "şebeynayene (m)",
    "y": " benzetmek"
  },
  {
    "x": "şebeynayîş (n)",
    "y": " benzetme"
  },
  {
    "x": "şecere (m)",
    "y": " soyağacı"
  },
  {
    "x": "şedênayîş (n)",
    "y": " b. şidênayîş (n)"
  },
  {
    "x": "şedinayîş (n)",
    "y": " b. şidênayîş (n)"
  },
  {
    "x": "şednayîne",
    "y": " b. şidênayene"
  },
  {
    "x": "şednayîş (n)",
    "y": " b. şidênayîş (n)"
  },
  {
    "x": "şef, -e",
    "y": " şef"
  },
  {
    "x": "şefaq (n)",
    "y": " b. şefeq (n) "
  },
  {
    "x": "şefeq (n)",
    "y": " tan, fecir, şafak"
  },
  {
    "x": "şefî-I (m)",
    "y": " şeflik"
  },
  {
    "x": "şefîya ameyeyan",
    "y": " gelirler şefliği"
  },
  {
    "x": "şefîya emlakî (m)",
    "y": " emlak şefliği"
  },
  {
    "x": "şefîya protokolî (m)",
    "y": " protokol şefliği"
  },
  {
    "x": "şefî, -ye-II",
    "y": " b. şafî, -ye"
  },
  {
    "x": "şefîl, -e",
    "y": " b. sefîl, -e"
  },
  {
    "x": "şefîtî (m)",
    "y": " b. şafîtî (m)"
  },
  {
    "x": "şefîye (m)",
    "y": " şeflik"
  },
  {
    "x": "şefîyê (m)",
    "y": " b. şafîyî (m)"
  },
  {
    "x": "şefkan, -e",
    "y": " b. sevkan, -e"
  },
  {
    "x": "şefkanênî (m)",
    "y": " b. sevkanîye (m)"
  },
  {
    "x": "şefkanîye (m)",
    "y": " b. sevkanîye (m)"
  },
  {
    "x": "şeftalîye (m)",
    "y": " şeftali"
  },
  {
    "x": "şeftalîyêre (m)",
    "y": " şeftali ağacı"
  },
  {
    "x": "şehadetname (n)",
    "y": " diploma"
  },
  {
    "x": "şehbik (n)",
    "y": " (alna düşen kısa kesilmiş saç) perçem, kâkül"
  },
  {
    "x": "şeher (n)",
    "y": " b. şehîr (n)"
  },
  {
    "x": "şeher (m)",
    "y": " b. şîîre (m)"
  },
  {
    "x": "şeheri (m)",
    "y": " b. şîîre (m)"
  },
  {
    "x": "şehîd, -e",
    "y": " şehit"
  },
  {
    "x": "şehîd bîyene",
    "y": " şehit düşmek, şehit olmak "
  },
  {
    "x": "şehîle (n)",
    "y": " b. şerîye (n)"
  },
  {
    "x": "şehbaz, -e",
    "y": " şahbaz (iri bir tür akdoğan)"
  },
  {
    "x": "şehîr (n)",
    "y": " şehir, kent"
  },
  {
    "x": "şehîro kan (n)",
    "y": " eski kent, eski şehir"
  },
  {
    "x": "şehîro kehen (n)",
    "y": " eski kent, eski şehir"
  },
  {
    "x": "şehîro peyk (n)",
    "y": " uydu kent"
  },
  {
    "x": "şehr (n)",
    "y": " b. şehîr (n)"
  },
  {
    "x": "şehr (m)",
    "y": " b. şare (m)"
  },
  {
    "x": "şehre (m)",
    "y": " b. şare (m)"
  },
  {
    "x": "şehrîya (m)",
    "y": " b. şerîye (n)"
  },
  {
    "x": "şehtayîş (n)",
    "y": " b. şehtîyayîş (n)"
  },
  {
    "x": "şehtîyaya (m)",
    "y": " 1)zayıf, nahif\r\n~2)bozuk"
  },
  {
    "x": "şehtîyaye (n)",
    "y": " 1)zayıf, nahif\r\n~2)bozuk"
  },
  {
    "x": "şehtîyayene",
    "y": " 1)bozulmak\r\n~2)zayıflamak"
  },
  {
    "x": "şehtîyayeyî (m)",
    "y": " zayıflık"
  },
  {
    "x": "şehtîyayeyîye (m)",
    "y": " zayıflık"
  },
  {
    "x": "şehtîyayîş (n)",
    "y": " 1)bozulma\r\n~2)zayıflama"
  },
  {
    "x": "şehtîyayî, -ye (m)",
    "y": " 1)zayıf, nahif\r\n~2)bozuk"
  },
  {
    "x": "şehtnayene",
    "y": " 1)bozmak\r\n~2)zayıflatmak"
  },
  {
    "x": "şehtnayîş (n)",
    "y": " 1)bozma\r\n~2)zayıflatma"
  },
  {
    "x": "şeîre-I (m)",
    "y": " b. şîîre (m)"
  },
  {
    "x": "şeîre-II (n)",
    "y": " b. şerîye (n)"
  },
  {
    "x": "şek, -e",
    "y": " 1)seçkin insan\r\n~2)yiğit"
  },
  {
    "x": "şekal (n)",
    "y": " b. lekaş (n), çekal (n)"
  },
  {
    "x": "şekê kabî (n)",
    "y": " b. şakê kabeyî (n)"
  },
  {
    "x": "şeker (n)",
    "y": " şeker"
  },
  {
    "x": "maşaya şekerî (m)",
    "y": " şeker maşası"
  },
  {
    "x": "şekero pemeyên (n)",
    "y": " pamuklu şeker"
  },
  {
    "x": "şekerdane (m)",
    "y": " şekerlik"
  },
  {
    "x": "şekerdiş-I (n)",
    "y": " edebilme, yapabilme, muktedir olma"
  },
  {
    "x": "şekerdiş-II (n)",
    "y": " tanıma"
  },
  {
    "x": "şekerdiş-III (n)",
    "y": " b. seyrkerdiş (n)"
  },
  {
    "x": "şekerek (n)",
    "y": " abdal, gezgin derviş"
  },
  {
    "x": "şekerin, -e",
    "y": " şekerli"
  },
  {
    "x": "şekerî (m)",
    "y": " bir üzüm çeşidi"
  },
  {
    "x": "şekerkar, -e",
    "y": " şekerci (şeker yapan) "
  },
  {
    "x": "şekerkarî (m)",
    "y": " şekercilik"
  },
  {
    "x": "şekerkarîye (m)",
    "y": " şekercilik"
  },
  {
    "x": "şekerloxme (n)",
    "y": " lokum"
  },
  {
    "x": "şekerpare (n)",
    "y": " şekerpare"
  },
  {
    "x": "şekerroş, -e",
    "y": " şekerci (şeker satan)"
  },
  {
    "x": "şekerroşî (m)",
    "y": " şekercilik"
  },
  {
    "x": "şekerroşîye (m)",
    "y": " şekercilik"
  },
  {
    "x": "şekhameyîş (n)",
    "y": " b. şakameyîş (n)"
  },
  {
    "x": "şekil (n)",
    "y": " biçim, şekil"
  },
  {
    "x": "şekil bedilnayene",
    "y": " dönüşmek"
  },
  {
    "x": "şekil dayene",
    "y": " biçimlendirmek, şekillendirmek"
  },
  {
    "x": "şekil dayenebedilnayene",
    "y": " dönüştürmek"
  },
  {
    "x": "şekil dayenevurnayene",
    "y": " dönüştürmek"
  },
  {
    "x": "şekil girewtene",
    "y": " biçimlenmek, şekillenmek"
  },
  {
    "x": "şekil vurnayene",
    "y": " dönüşmek"
  },
  {
    "x": "şekilbedilnayîş (n)",
    "y": " dönüşme"
  },
  {
    "x": "şekildayîş (n)",
    "y": " biçimlendirme, formdayîş (n)"
  },
  {
    "x": "şekildayîşbedilnayîş (n)",
    "y": " dönüştürme"
  },
  {
    "x": "şekildayîşvurnayîş (n)",
    "y": " dönüştürme"
  },
  {
    "x": "şekilgirewtiş (n)",
    "y": " biçimlenme, şekillenme"
  },
  {
    "x": "şekilvurnayîş (n)",
    "y": " dönüşme"
  },
  {
    "x": "şekir (n)",
    "y": " b. şeker (n)"
  },
  {
    "x": "şekirin, -e",
    "y": " b. şekerin, -e"
  },
  {
    "x": "şekîl (n)",
    "y": " b. şekil (n)"
  },
  {
    "x": "şekkotiş (n)",
    "y": " b. şakkewtiş (n)"
  },
  {
    "x": "şekl (n)",
    "y": " b. şekil (n)"
  },
  {
    "x": "şekoke (m)",
    "y": " ahlat"
  },
  {
    "x": "şekokêre (m)",
    "y": " ahlat ağacı"
  },
  {
    "x": "şekwarîştiş (n)",
    "y": " b. şakweriştiş (n)"
  },
  {
    "x": "şel-I (n)",
    "y": " kesek"
  },
  {
    "x": "şel-II (n)",
    "y": " büzgü (kumaş vb. için)"
  },
  {
    "x": "şel-III (m)",
    "y": " b. şeyle-II (m)"
  },
  {
    "x": "şel eştene",
    "y": " b. şeyle eştene"
  },
  {
    "x": "şela quwetî (m)",
    "y": " b. şeyla qewetî (m)"
  },
  {
    "x": "şelag (m)",
    "y": " b. şalage (m)"
  },
  {
    "x": "şelamundî (m)",
    "y": " b. şilabend (n)"
  },
  {
    "x": "şelbat (m)",
    "y": " b. şelbûte (m)"
  },
  {
    "x": "şelbûte (m)",
    "y": " değnek"
  },
  {
    "x": "şele-I (m)",
    "y": " ağaç topluluğu"
  },
  {
    "x": "şele-II (m)",
    "y": " teyel, teğel"
  },
  {
    "x": "şele-III (n)",
    "y": " b. cele (n)"
  },
  {
    "x": "şelenaya (m)",
    "y": " b. şelênaya (m)"
  },
  {
    "x": "şelenaye (n)",
    "y": " b. şelênaye (n)"
  },
  {
    "x": "şelenayene",
    "y": " b. şelênayene"
  },
  {
    "x": "şelenayîş (n)",
    "y": " b. şelênayîş (n)"
  },
  {
    "x": "şelenayîye (m)",
    "y": " b. şelênayîye (m)"
  },
  {
    "x": "şeleqîyaya (m)",
    "y": " b. şeliqîyaya (m), şeliqîyayîye (m)"
  },
  {
    "x": "şeleqîyaye (n)",
    "y": " b. şeliqîyaye (n)"
  },
  {
    "x": "şeleqîyayene",
    "y": " b. şeliqîyayene"
  },
  {
    "x": "şeleqnayene",
    "y": " b. şeliqnayene"
  },
  {
    "x": "şeleqnayîş (n)",
    "y": " b. şeliqnayîş (n)"
  },
  {
    "x": "şeleqnîyayene",
    "y": " b. şeliqnîyayene"
  },
  {
    "x": "şeleqnîyayîş (n)",
    "y": " b. şeliqnîyayîş (n)"
  },
  {
    "x": "şelênaya (m)",
    "y": " soyunmuş olan"
  },
  {
    "x": "şelênaye (n)",
    "y": " soyunmuş olan"
  },
  {
    "x": "şelênayene",
    "y": " 1)soymak (birinin parasını vb. zorla almak)\r\n~2)soymak (giysisini çıkarmak, elbisesini çıkarmak)"
  },
  {
    "x": "şelênayex (n)",
    "y": " b. şelênayox, -e"
  },
  {
    "x": "şelênayexê (m)",
    "y": " b. şelênayoxî (m)"
  },
  {
    "x": "şelênayîş (n)",
    "y": " 1)soyma (birinin parasını vb. zorla alma)\r\n~2)soyma (giysisini çıkarma)"
  },
  {
    "x": "şelênayîye (m)",
    "y": " soyunmuş olan"
  },
  {
    "x": "şelênayox, -e",
    "y": " soyguncu"
  },
  {
    "x": "şelênayoxî (m)",
    "y": " soygunculuk"
  },
  {
    "x": "şelênayoxîye (m)",
    "y": " soygunculuk"
  },
  {
    "x": "şeli (m)",
    "y": " b. şele (m)"
  },
  {
    "x": "şeliqîyaya (m)",
    "y": " pişik"
  },
  {
    "x": "şeliqîyaye (n)",
    "y": " pişik"
  },
  {
    "x": "şeliqîyayene",
    "y": " pişik olmak"
  },
  {
    "x": "şeliqîyayîye (m)",
    "y": " pişik"
  },
  {
    "x": "şeliqnayene",
    "y": " 1)haşlamak\r\n~2)nohut vb. yiyecekleri pişirmek"
  },
  {
    "x": "şeliqnayeni",
    "y": " b. şeliqnayene"
  },
  {
    "x": "şeliqnayîş (n)",
    "y": " haşlama"
  },
  {
    "x": "şeliqnîyayene",
    "y": " haşlanmak"
  },
  {
    "x": "şeliqnîyayeni",
    "y": " b. şeliqnîyayene"
  },
  {
    "x": "şeliqnîyayîş (n)",
    "y": " haşlanma"
  },
  {
    "x": "şelle (n)",
    "y": " b. cele (n)"
  },
  {
    "x": "şellênaya (m)",
    "y": " b. şelênaya (m)"
  },
  {
    "x": "şellênaye (n)",
    "y": " b. şelênaye (n)"
  },
  {
    "x": "şellênayene",
    "y": " b. şelênayene"
  },
  {
    "x": "şellênayîş (n)",
    "y": " b. şelênayîş (n)"
  },
  {
    "x": "şellênayîye (m)",
    "y": " b. şelênayîye (m)"
  },
  {
    "x": "şelliqnayene",
    "y": " b. şeliqnayene"
  },
  {
    "x": "şelme (m)",
    "y": " yüklük örtüsü"
  },
  {
    "x": "şelnaya (m)",
    "y": " teyelli"
  },
  {
    "x": "şelnaye (n)",
    "y": " teyelli"
  },
  {
    "x": "şelnayene",
    "y": " teyel yapmak, teyel atmak, teyellemek"
  },
  {
    "x": "şelnayene",
    "y": " b. şelênayene"
  },
  {
    "x": "şelnayîş (n)",
    "y": " b. şelênayîş (n)"
  },
  {
    "x": "şelnayîye (m)",
    "y": " teyelli"
  },
  {
    "x": "şelnayoxey (m)",
    "y": " b. şelênayoxî (m)"
  },
  {
    "x": "şelpe (n)",
    "y": " b. serpen (n)"
  },
  {
    "x": "şelpeze (n)",
    "y": " b. şerpize (n)"
  },
  {
    "x": "şelpiza (m)",
    "y": " b. şerpiza (m)"
  },
  {
    "x": "şelpize (n)",
    "y": " b. şerpize (n)"
  },
  {
    "x": "şelpize bîyayîş (n)",
    "y": " b. şerpizebîyayîş (n)"
  },
  {
    "x": "şelpize bîyene",
    "y": " b. şerpize bîyene"
  },
  {
    "x": "şelpize kerdene",
    "y": " b. şerpize kerdene"
  },
  {
    "x": "şelpizekerdiş (n)",
    "y": " b. şerpizekerdiş (n)"
  },
  {
    "x": "şelpizeyey (m)",
    "y": " b. şerpizeyî (m)"
  },
  {
    "x": "şelpizeyê (m)",
    "y": " b. şerpizeyî (m)"
  },
  {
    "x": "şelpizeyî (m)",
    "y": " b. şerpizeyî (m)"
  },
  {
    "x": "şelpîya fekî (m)",
    "y": " ağız şapırdaması"
  },
  {
    "x": "şelqinayeni",
    "y": " b. şeliqnayene"
  },
  {
    "x": "şelqinayîş (n)",
    "y": " b. şeliqnayîş (n)"
  },
  {
    "x": "şelqinîyayeni",
    "y": " b. şeliqnîyayene"
  },
  {
    "x": "şelqinîyayîş (n)",
    "y": " b. şeliqnîyayîş (n)"
  },
  {
    "x": "şelqnayeni",
    "y": " b. şeliqnayene"
  },
  {
    "x": "şelqnayîş (n)",
    "y": " b. şeliqnayîş (n)"
  },
  {
    "x": "şelqnîyayeni",
    "y": " b. şeliqnîyayene"
  },
  {
    "x": "şelqnîyayîş (n)",
    "y": " b. şeliqnîyayîş (n)"
  },
  {
    "x": "şelwalî (zh)",
    "y": " b. şelwarî (zh)"
  },
  {
    "x": "şelwar (zh)",
    "y": " b. şelwarî (zh)"
  },
  {
    "x": "şelwarî (zh)",
    "y": " şalvar"
  },
  {
    "x": "şelwel (zh)",
    "y": " b. şelwarî (zh)"
  },
  {
    "x": "şelxem (n)",
    "y": " şalgam"
  },
  {
    "x": "şem (n)",
    "y": " b. sêm (n)"
  },
  {
    "x": "şema-I (m)",
    "y": " balmumu"
  },
  {
    "x": "şema-II (m)",
    "y": " şema"
  },
  {
    "x": "şemaya îdareyî",
    "y": " yönetim şeması"
  },
  {
    "x": "şemaya îdareyî ya şaredarîya/beledîyaya şaristanê girdî",
    "y": " anakent belediyesi yönetim şeması, büyükşehir belediyesi yönetim şeması"
  },
  {
    "x": "şemaya organîzasyonî (m)",
    "y": " teşkilat şeması"
  },
  {
    "x": "şemaya rêxistine (m)",
    "y": " teşkilat şeması "
  },
  {
    "x": "şemamek (m)",
    "y": " b. şemamoke (m)"
  },
  {
    "x": "şemamoke (m)",
    "y": " şamama"
  },
  {
    "x": "şemamoki (m)",
    "y": " b. şemamoke (m)"
  },
  {
    "x": "şemata (m)",
    "y": " b. şemate (n)"
  },
  {
    "x": "şemate (n)",
    "y": " gürültü patırtı, hengâme, şamata"
  },
  {
    "x": "şemati (m)",
    "y": " b. şemate (n)"
  },
  {
    "x": "şembe (n)",
    "y": " b. şeme (n)"
  },
  {
    "x": "şeme (n)",
    "y": " cumartesi "
  },
  {
    "x": "Şeme(m)",
    "y": "inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "şemek (m)",
    "y": " b. şanike-I (m)"
  },
  {
    "x": "şemetîyayene",
    "y": " b. şemitîyayene"
  },
  {
    "x": "şemetîyayîş (n)",
    "y": " b. şemitîyayîş (n)"
  },
  {
    "x": "şemetok, -e",
    "y": " b. şemitok, -e"
  },
  {
    "x": "şemên, -e",
    "y": " b. sêmên, -e"
  },
  {
    "x": "şemi-I (n)",
    "y": " b. şeme (n)"
  },
  {
    "x": "şemi-II (m)",
    "y": " b. şema-I (m)"
  },
  {
    "x": "şemig (m)",
    "y": " b. şêmûge (m)"
  },
  {
    "x": "şemige (m)",
    "y": " b. şêmûge (m)"
  },
  {
    "x": "şemik (n)",
    "y": " b. şimik (n)"
  },
  {
    "x": "şemin, -e",
    "y": " b. sêmên, -e"
  },
  {
    "x": "şemitikin, -e",
    "y": " kaygan"
  },
  {
    "x": "şemitîyayene",
    "y": " kaymak, (yıldız) kaymak, akmak (yıldız akmak)"
  },
  {
    "x": "şemitîyayîş (n)",
    "y": " kayma, (yıldız) kayma"
  },
  {
    "x": "şemitnayene",
    "y": " kaydırmak"
  },
  {
    "x": "şemitnayîş (n)",
    "y": " kaydırma"
  },
  {
    "x": "şemitok, -e",
    "y": " kaygan"
  },
  {
    "x": "Şemo (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "şemomik (m)",
    "y": " b. şemamoke (m)"
  },
  {
    "x": "şempanze, -ye",
    "y": " şempanze "
  },
  {
    "x": "şemsî (m)",
    "y": " b. şemsîya (m)"
  },
  {
    "x": "şemsîya (m)",
    "y": " şemsiye"
  },
  {
    "x": "şemsîyaroş, -e",
    "y": " şemsiyeci"
  },
  {
    "x": "şemşa (m)",
    "y": " b. şemsîya (m)"
  },
  {
    "x": "şemşêr (n)",
    "y": " b. şimşêr (n)"
  },
  {
    "x": "şemşî (m)",
    "y": " b. şemsîya (m)"
  },
  {
    "x": "şemşîya (m)",
    "y": " b. şemsîya (m)"
  },
  {
    "x": "şemuge (m)",
    "y": " b. şêmûge (m)"
  },
  {
    "x": "şemugi (m)",
    "y": " b. şêmûge (m)"
  },
  {
    "x": "şemûk (m)",
    "y": " b. şanike-I (m)"
  },
  {
    "x": "şemûmik (m)",
    "y": " b. şemamoke (m)"
  },
  {
    "x": "şenasnayîş (n)",
    "y": " b. şinasnayîş (n)"
  },
  {
    "x": "şenayene",
    "y": " b. şayene-I"
  },
  {
    "x": "şenayîş (n)",
    "y": " b. şayîş-I (n)"
  },
  {
    "x": "şenbe (n)",
    "y": " b. şeme (n)"
  },
  {
    "x": "Şençûş (n)",
    "y": " b. Şankuş (n)"
  },
  {
    "x": "şene (n)",
    "y": " b. sêne (n)"
  },
  {
    "x": "şengebî (m)",
    "y": " salkımsöğüt"
  },
  {
    "x": "şengebîye (m)",
    "y": " salkımsöğüt"
  },
  {
    "x": "Şenguşi (m)",
    "y": " b. Şankuş (n)"
  },
  {
    "x": "şenik, -e",
    "y": " 1)hafif\r\n~2)küçük, yaşça küçük\r\n~3)az"
  },
  {
    "x": "şenik bîyene",
    "y": " azalmak"
  },
  {
    "x": "şenikê şenikan",
    "y": " 1)en az\r\n~2)en küçük"
  },
  {
    "x": "şenikbîyayîş (n)",
    "y": " azalma"
  },
  {
    "x": "şenikek, -e",
    "y": " 1)hafifçe\r\n~2)küçücük\r\n~3)azıcık, birazcık"
  },
  {
    "x": "şeniki (m)",
    "y": " b. şenike (m)"
  },
  {
    "x": "şenîye (m)",
    "y": " b. sênîye (m)"
  },
  {
    "x": "şepale (m)",
    "y": " dişi aslan"
  },
  {
    "x": "şepqa (m)",
    "y": " b. şewqa (m)"
  },
  {
    "x": "şeq (n)",
    "y": " yarık "
  },
  {
    "x": "şeqal (n)",
    "y": " yarık"
  },
  {
    "x": "şeqame (m)",
    "y": " tokat, sille"
  },
  {
    "x": "şeqame werdene",
    "y": " tokat yemek"
  },
  {
    "x": "şeqamî kerdene",
    "y": " tokatlamak"
  },
  {
    "x": "şeqamî piro dayene",
    "y": " tokatlamak"
  },
  {
    "x": "şeqamewerdiş (n)",
    "y": " tokat yeme"
  },
  {
    "x": "şeqamîkerdiş (n)",
    "y": " tokatlama"
  },
  {
    "x": "şeqamîpirodayîş (n)",
    "y": " tokatlama"
  },
  {
    "x": "şeqayene",
    "y": " b. şeqîyayene"
  },
  {
    "x": "şeqayîş (n)",
    "y": " b. şeqîyayîş (n)"
  },
  {
    "x": "şeqe (m)",
    "y": " bacak"
  },
  {
    "x": "şeqetîyayene",
    "y": " b. şeqitîyayene "
  },
  {
    "x": "şeqetîyayîş (n)",
    "y": " b. şeqitîyayîş (n)"
  },
  {
    "x": "şeqetnayene",
    "y": " b. şeqitnayene"
  },
  {
    "x": "şeqetnayîş (n)",
    "y": " b. şeqitnayîş (n)"
  },
  {
    "x": "şeqetok, -e",
    "y": " b. şeqitok, -e"
  },
  {
    "x": "şeqênayene",
    "y": " b. şeqînayene"
  },
  {
    "x": "şeqênayeni",
    "y": " b. şeqnayene"
  },
  {
    "x": "şeqênayîş (n)",
    "y": " b. şeqînayîş (n)"
  },
  {
    "x": "şeqi (m)",
    "y": " b. şeqe (m)"
  },
  {
    "x": "şeqitîyayene",
    "y": " kaymak"
  },
  {
    "x": "şeqitîyayîş (n)",
    "y": " kayma"
  },
  {
    "x": "şeqitnayene",
    "y": " kaydırmak"
  },
  {
    "x": "şeqitnayîş (n)",
    "y": " kaydırma"
  },
  {
    "x": "şeqitok, -e",
    "y": " kaygan"
  },
  {
    "x": "şeqînayene",
    "y": " fırlatmak"
  },
  {
    "x": "şeqînayîş (n)",
    "y": " fırlatma"
  },
  {
    "x": "şeqîyaya (m)",
    "y": " patlak"
  },
  {
    "x": "şeqîyaye (n)",
    "y": " patlak"
  },
  {
    "x": "şeqîyayene",
    "y": " 1)çatlamak (deri vb. için)\r\n~2)yarılmak"
  },
  {
    "x": "şeqîyayî, -ye",
    "y": " çatlak, patlak"
  },
  {
    "x": "şeqîyayîş (n)",
    "y": " 1)çatlama (deri vb. için)\r\n~2)yarılma"
  },
  {
    "x": "şeqnayene-I",
    "y": " 1)çatlatmak (deri vb. için)\r\n~2)yarmak"
  },
  {
    "x": "şeqnayene-II",
    "y": " saçmak, tohum serpmek (tahıl için), tohum ekmek (tahıl için)"
  },
  {
    "x": "şeqnayeni-I",
    "y": " b. şeqnayene"
  },
  {
    "x": "şeqnayeni-II",
    "y": " b. şeqînayene"
  },
  {
    "x": "şeqnayîş-I (n)",
    "y": " 1)çatlatma\r\n~2)yarma"
  },
  {
    "x": "şeqnayîş-II (n)",
    "y": " saçma, tohum serpme (tahıl için)"
  },
  {
    "x": "şeqnayîş-III (n)",
    "y": " b. şeqînayîş (n)"
  },
  {
    "x": "şer-I (n)",
    "y": " savaş"
  },
  {
    "x": "şero psîkolojîk (n)",
    "y": " psikolojik savaş"
  },
  {
    "x": "şer-II",
    "y": " b. seyr"
  },
  {
    "x": "şer kerdene",
    "y": " b. seyr kerdene"
  },
  {
    "x": "şerab (n)",
    "y": " şarap, bade"
  },
  {
    "x": "şerab (m)",
    "y": " b. şerab (n)"
  },
  {
    "x": "şerabi (m)",
    "y": " b. şerab (n)"
  },
  {
    "x": "şerabî (m)",
    "y": " bir üzüm çeşidi"
  },
  {
    "x": "şerabwer, -e",
    "y": " şarap içen"
  },
  {
    "x": "şerap (m)",
    "y": " b. şerab (n)"
  },
  {
    "x": "şerav (n)",
    "y": " b. şerab (n)"
  },
  {
    "x": "şerb (n)",
    "y": " maşrapa"
  },
  {
    "x": "şerbet (n)",
    "y": " şerbet"
  },
  {
    "x": "şerbik-I (n)",
    "y": " maşrapa"
  },
  {
    "x": "şerbik-II (n)",
    "y": " b. şarbik-I (n)"
  },
  {
    "x": "şeref (n)",
    "y": " onur, şeref"
  },
  {
    "x": "şerefdayîş (n)",
    "y": " teşrif"
  },
  {
    "x": "şerh (n)",
    "y": " şerh"
  },
  {
    "x": "şerîk, -i",
    "y": " b. şirîk, -e"
  },
  {
    "x": "şerîd (n)",
    "y": " b. şerît (n)"
  },
  {
    "x": "şerît-I (n)",
    "y": " 1)şerit (dar ve uzun kumaş parçası)\r\n~2)şerit, yol şeridi"
  },
  {
    "x": "şerîtê ramitişî (n)",
    "y": " sürme şeridi"
  },
  {
    "x": "şerîtê rayîrî (n)",
    "y": " yol şeridi, şerit"
  },
  {
    "x": "şerît-II (n)",
    "y": " b. şirît (n)"
  },
  {
    "x": "şerîye (n)",
    "y": " şehriye"
  },
  {
    "x": "şerm (n)",
    "y": " ar, utanma, utanç"
  },
  {
    "x": "şerm kerdene",
    "y": " utanmak"
  },
  {
    "x": "şermî reyde",
    "y": " çekine çekine, çekinerek"
  },
  {
    "x": "şermî reyra",
    "y": " çekine çekine, çekinerek"
  },
  {
    "x": "şermayene",
    "y": " utanmak"
  },
  {
    "x": "şermayeyîş (n)",
    "y": " b. şermayîş (n)"
  },
  {
    "x": "şermayîş (n)",
    "y": " utanma"
  },
  {
    "x": "şerme (n)",
    "y": " ar, utanma, utanç"
  },
  {
    "x": "şermi (n)",
    "y": " b. şerme (n)"
  },
  {
    "x": "şerminik (n)",
    "y": " b. şermonek, -e"
  },
  {
    "x": "şerminik bîyayene",
    "y": " b. şermonek bîyene"
  },
  {
    "x": "şerminikê (m)",
    "y": " b. şermonekîye (m)"
  },
  {
    "x": "şermîn, -e",
    "y": " çekingen, sıkılgan, utangaç, utangan"
  },
  {
    "x": "şermîn bîyene",
    "y": " çekingenleşmek"
  },
  {
    "x": "şermîney (m)",
    "y": " b. şermînîye (m)"
  },
  {
    "x": "şermînê (m)",
    "y": " b. şermînîye (m)"
  },
  {
    "x": "şermînîye (m)",
    "y": " çekingenlik"
  },
  {
    "x": "şermîyayeni",
    "y": " b. şermayene"
  },
  {
    "x": "şermîyayîş (n)",
    "y": " b. şermayîş (n)"
  },
  {
    "x": "şermkerdiş (n)",
    "y": " utanma"
  },
  {
    "x": "şermok, -e",
    "y": " çekingen, sıkılgan, utangaç, utangan"
  },
  {
    "x": "şermok bîyene",
    "y": " çekingenleşmek"
  },
  {
    "x": "şermokey (m)",
    "y": " b. şermokîye (m)"
  },
  {
    "x": "şermokê (m)",
    "y": " b. şermokîye (m)"
  },
  {
    "x": "şermoki (m)",
    "y": " b. şermok, -e"
  },
  {
    "x": "şermokin, -e",
    "y": " çekingen, sıkılgan, utangaç, utangan"
  },
  {
    "x": "şermokin bîyene",
    "y": " çekingenleşmek"
  },
  {
    "x": "şermokiney (m)",
    "y": " b. şermokinîye (m)"
  },
  {
    "x": "şermokinê (m)",
    "y": "b. şermokinîye (m)"
  },
  {
    "x": "şermokini (m)",
    "y": " şermokin, -e"
  },
  {
    "x": "şermokinîye (m)",
    "y": " çekingenlik "
  },
  {
    "x": "şermokinîye kerdene",
    "y": " çekingen davranmak"
  },
  {
    "x": "şermokîye (m)",
    "y": " çekingenlik "
  },
  {
    "x": "şermonek, -e",
    "y": " çekingen, sıkılgan, utangaç, utangan"
  },
  {
    "x": "şermonek bîyene",
    "y": " çekingenleşmek "
  },
  {
    "x": "şermonekey (m)",
    "y": " b. şermonekîye (m)"
  },
  {
    "x": "şermonekê (m)",
    "y": " b. şermonekîye (m)"
  },
  {
    "x": "şermonekîye (m)",
    "y": " çekingenlik "
  },
  {
    "x": "şermonekîye kerdene",
    "y": " çekingen davranmak"
  },
  {
    "x": "şermunek, -i",
    "y": " b. şermonek, -e"
  },
  {
    "x": "şermunek bîyayene",
    "y": " b. şermonek bîyene"
  },
  {
    "x": "şermunekê (m)",
    "y": " b. şermonekîye (m)"
  },
  {
    "x": "şermûnek, -i",
    "y": " b. şermonek, -e"
  },
  {
    "x": "şermûnek bîyayene",
    "y": " b. şermonek bîyene"
  },
  {
    "x": "şerpa (m)",
    "y": " b. şarpa (m)"
  },
  {
    "x": "şerpeze (n)",
    "y": " b. şerpize (n)"
  },
  {
    "x": "şerpeza (m)",
    "y": " b. şerpiza (m)"
  },
  {
    "x": "şerpiza (m)",
    "y": " perişan"
  },
  {
    "x": "şerpize (n)",
    "y": " perişan"
  },
  {
    "x": "şerpize bîyene",
    "y": " perişan olmak"
  },
  {
    "x": "şerpize kerdene",
    "y": " perişan etmek"
  },
  {
    "x": "şerpizebîyayîş (n)",
    "y": " perişan olma"
  },
  {
    "x": "şerpizekerdiş (n)",
    "y": " perişan etme"
  },
  {
    "x": "şerpizeyey (m)",
    "y": " b. şerpizeyî (m)"
  },
  {
    "x": "şerpizeyê (m)",
    "y": " b. şerpizeyî (m)"
  },
  {
    "x": "şerpizeyî (m)",
    "y": " perişanlık"
  },
  {
    "x": "şerqat (m)",
    "y": " b. şerqatî (m)"
  },
  {
    "x": "şerqatî (m)",
    "y": " değnek"
  },
  {
    "x": "Şerro (n)",
    "y": " köpek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "şert (n)",
    "y": " koşul, şart"
  },
  {
    "x": "şertê ceza (n) (huq.)",
    "y": " ceza şartı"
  },
  {
    "x": "şertê depokerdişî (zh)",
    "y": " depolama şartları"
  },
  {
    "x": "şertê hewadayîşî (zh)",
    "y": " saklama koşulları"
  },
  {
    "x": "şertê hewanayîşî (zh)",
    "y": " saklama koşulları"
  },
  {
    "x": "şertê hîjyenîkî (zh)",
    "y": " hijyenik koşullar, hijyenik şartlar"
  },
  {
    "x": "şertê pêserkerdişî (zh)",
    "y": " depolama şartları"
  },
  {
    "x": "şerte (n)",
    "y": " 1)yaş ağaç dallarıyla örülen ince duvar\r\n~2)çit kapısı, çeper kapısı, çit biçimindeki kapı"
  },
  {
    "x": "şertekerdiş (n)",
    "y": " yaş ağaç dallarıyla ince duvar örme"
  },
  {
    "x": "şerteronayîş (n)",
    "y": " yaş ağaç dallarıyla ince duvar örme"
  },
  {
    "x": "şerterûnayîş (n)",
    "y": " b. şerteronayîş (n)"
  },
  {
    "x": "şerti (n)",
    "y": " b. şerte (n)"
  },
  {
    "x": "şertik (n) ",
    "y": " çit biçimindeki kapı"
  },
  {
    "x": "şertikerdiş (n)",
    "y": " b. şertekerdiş (n)"
  },
  {
    "x": "şertin, -e",
    "y": " şartlı"
  },
  {
    "x": "şertname (n)",
    "y": " şartname"
  },
  {
    "x": "şertnameyo teknîkî (n)",
    "y": " teknik şartname"
  },
  {
    "x": "şes",
    "y": " b. şeş"
  },
  {
    "x": "şeseme (n)",
    "y": " b. sêşeme (n)"
  },
  {
    "x": "şest",
    "y": " b. şeştî "
  },
  {
    "x": "şestay",
    "y": " b. şeştî "
  },
  {
    "x": "şeş",
    "y": " altı"
  },
  {
    "x": "şeş rê se",
    "y": " b. şeş sey"
  },
  {
    "x": "şeş sey",
    "y": " altı yüz "
  },
  {
    "x": "şeşadir (n)",
    "y": " tabanca"
  },
  {
    "x": "şeşdarb (n)",
    "y": " b. şeşderb (n)"
  },
  {
    "x": "şeşderb (n)",
    "y": " tabanca"
  },
  {
    "x": "şeşderbi (m)",
    "y": " b. şeşderb (n)"
  },
  {
    "x": "şeşgoşe (n)",
    "y": " altıgen (mat)"
  },
  {
    "x": "şeşin, -e",
    "y": " altıncı"
  },
  {
    "x": "şeşt",
    "y": " b. şeştî "
  },
  {
    "x": "şeştê",
    "y": " b. şeştî"
  },
  {
    "x": "şeştî",
    "y": " altmış"
  },
  {
    "x": "şeştîyin, -e",
    "y": " altmışıncı"
  },
  {
    "x": "şetil (n)",
    "y": " b. şitil (n)"
  },
  {
    "x": "şetil rûnayîne",
    "y": " b. şitil ronayene"
  },
  {
    "x": "şetîyayîş (n)",
    "y": " b. şehtîyayîş (n)"
  },
  {
    "x": "şetrenc (n)",
    "y": " b. setrenc (n)"
  },
  {
    "x": "şetyayîş (n)",
    "y": " b. şehtîyayîş (n)"
  },
  {
    "x": "şevak (m)",
    "y": " b. şibake (m)"
  },
  {
    "x": "şeveknayene",
    "y": " b. seveknayene"
  },
  {
    "x": "şeveknayîş (n)",
    "y": " b. seveknayîş (n)"
  },
  {
    "x": "şeveq (n)",
    "y": " b. şefeq (n) "
  },
  {
    "x": "şevz (m)",
    "y": " b. simze (m)"
  },
  {
    "x": "şew (m)",
    "y": " b. şewe (m) "
  },
  {
    "x": "şew û roc",
    "y": " b. şew û roj"
  },
  {
    "x": "şew û roj",
    "y": " gece gündüz "
  },
  {
    "x": "şew û ruec",
    "y": " b. şew û roj "
  },
  {
    "x": "şew û ruej",
    "y": " b. şew û roj "
  },
  {
    "x": "şewat (n)",
    "y": " 1)yangın\r\n~2)yangın (hasta için), ateş (hastalıkta), humma"
  },
  {
    "x": "şewat (n) (sinifa A, B, C, D, E, Fyî)",
    "y": " yangın (A, B, C, D, E, F sınıfı)"
  },
  {
    "x": "şewatê nêweşî/e",
    "y": " hastanın ateşi"
  },
  {
    "x": "şewate (n)",
    "y": " 1) yakıt, yakacak\r\n~2)b. şewat (n)"
  },
  {
    "x": "şewatkar (n)",
    "y": " itfaiye eri"
  },
  {
    "x": "şewatkar, -e",
    "y": " yangıncı, itfaiyeci"
  },
  {
    "x": "şewatkara zerrîwaze (m)",
    "y": " gönüllü itfaiyeci"
  },
  {
    "x": "şewatkaro zerrîwaz (n)",
    "y": " gönüllü itfaiyeci"
  },
  {
    "x": "şewbnayene",
    "y": " b. şebeynayene (m)"
  },
  {
    "x": "şewbnayîş (n)",
    "y": " b. şebeynayîş (n)"
  },
  {
    "x": "şewdir (n)",
    "y": " sabah\r\n~Şewdir bixeyr. (Günaydın.)"
  },
  {
    "x": "serê şewdirî (n)",
    "y": " tan (tan vakti), fecir, şafak"
  },
  {
    "x": "şewdir a",
    "y": " b. şewdir ra"
  },
  {
    "x": "şewdir ra",
    "y": " sabahleyin"
  },
  {
    "x": "şewe (m)",
    "y": " gece"
  },
  {
    "x": "şew û roj",
    "y": " gece gündüz\r\n~Şewa Homayî rehmet. (İyi geceler.)\r\n~\t\r\n~Şewa to şenike bo. (İyi geceler.)\r\n~\t\r\n~Şewa to xeyr bo. (İyi geceler.)\r\n~\t\r\n~Şewbaş. (İyi geceler.) \r\n~\t\r\n~Şewe pîr bîbî. (Gece çok ilerlemişti./Gece çok geçmişti.)"
  },
  {
    "x": "şewelete (n)",
    "y": " şewlete (n)"
  },
  {
    "x": "şewenême (n)",
    "y": " gece yarısı"
  },
  {
    "x": "şewi (m)",
    "y": " b. şewe (m)"
  },
  {
    "x": "şewî (m)",
    "y": " b. şewe (m)"
  },
  {
    "x": "şewl (n)",
    "y": " b. şewle (n)"
  },
  {
    "x": "şewle (n)",
    "y": " ışık"
  },
  {
    "x": "şewle dayene",
    "y": " parlamak, ışıldamak, parıldamak"
  },
  {
    "x": "şewledayîş (n)",
    "y": " parlama, ışıldama, parıldama"
  },
  {
    "x": "şewlete (n)",
    "y": " gece yarısı"
  },
  {
    "x": "şewq (n)",
    "y": " ışık"
  },
  {
    "x": "şewq dayene",
    "y": " parlamak, ışıldamak, parıldamak"
  },
  {
    "x": "şewq û şema",
    "y": " b. şewq û şemale"
  },
  {
    "x": "şewq û şemale",
    "y": " ışık"
  },
  {
    "x": "şewqê aşme (n)",
    "y": " ay ışığı, mehtap"
  },
  {
    "x": "şewqa (m)",
    "y": " şapka, kasket"
  },
  {
    "x": "şewqaderz, -e",
    "y": " şapkacı (şapka diken) "
  },
  {
    "x": "şewqaroş, -e",
    "y": " şapkacı (şapka satan)"
  },
  {
    "x": "şewqdayîş (n)",
    "y": " parlama, ışıldama, parıldama"
  },
  {
    "x": "şewqe (n)",
    "y": " b. şewqa (m)"
  },
  {
    "x": "şewqî (m)",
    "y": " b. şewqa (m)"
  },
  {
    "x": "şewra (n)",
    "y": " b. şewdir (n)"
  },
  {
    "x": "şewşewiki (m)",
    "y": " b. şewşewoke (m)"
  },
  {
    "x": "şewşewoke (m)",
    "y": " yarasa"
  },
  {
    "x": "şewşi (m)",
    "y": " b. şewşî (m)"
  },
  {
    "x": "şewşî (m)",
    "y": " tavukkarası, gece körlüğü"
  },
  {
    "x": "şewşî bîyene",
    "y": " tavukkarası hastalığına yakalanmak"
  },
  {
    "x": "şewşî girewtene",
    "y": " tavukkarası hastalığına yakalanmak"
  },
  {
    "x": "şewte (m)",
    "y": " değnek"
  },
  {
    "x": "şewti (m)",
    "y": " b. şewte (m)"
  },
  {
    "x": "şex (n)",
    "y": " b. şêx (n)"
  },
  {
    "x": "şexabî (m)",
    "y": " b. xeşebî (m)"
  },
  {
    "x": "şexê (m)",
    "y": " b. şêxîye (m)"
  },
  {
    "x": "şexis, -e",
    "y": " b. şexs, -e"
  },
  {
    "x": "şexs, -e",
    "y": " kişi, şahıs, kimse"
  },
  {
    "x": "şexsî, -ye",
    "y": " özel, hususi"
  },
  {
    "x": "şexsîyet (n)",
    "y": " kişilik, şahsiyet, benlik"
  },
  {
    "x": "şextî (m)",
    "y": " b. şêxîye (m)"
  },
  {
    "x": "şeyene",
    "y": " b. şîyene"
  },
  {
    "x": "şeyîne",
    "y": " b. şîyene "
  },
  {
    "x": "şeyîne rehmet",
    "y": " b. şîyene rehmet"
  },
  {
    "x": "şeyîr (n)",
    "y": " b. şehîr (n)"
  },
  {
    "x": "şeyîş (n)",
    "y": " b. şîyayîş (n)"
  },
  {
    "x": "şeyîşrehmet (n)",
    "y": " b. şîyayîşrehmet (n)"
  },
  {
    "x": "şeyl (n)",
    "y": " b. şel (n)"
  },
  {
    "x": "şeyle-I (m)",
    "y": " pekmez dolusu kazan (bir pekmez ölçüsü)"
  },
  {
    "x": "şeyle-II (m)",
    "y": " 1)büyük taş\r\n~2)(atletizmde atılan) gülle"
  },
  {
    "x": "şeyla qewetî (m)",
    "y": " 1)(atletizmde atılan) gülle\r\n~2)gülle atma sporu"
  },
  {
    "x": "şeyla qewetî eştene",
    "y": " gülle atmak"
  },
  {
    "x": "şeyla qewetî vistene",
    "y": " b. şeyla qewetî eştene"
  },
  {
    "x": "şeyla qolî (m)",
    "y": " 1)(atletizmde atılan elde yer alabilecek büyüklükteki gülle) gülle\r\n~2)gülle atma sporu"
  },
  {
    "x": "şeyla qolî eştene",
    "y": " (elde yer alabilecek büyüklükte gülle atmak) gülle atmak"
  },
  {
    "x": "şeyle eştene",
    "y": " gülle atmak"
  },
  {
    "x": "şeyley qewetî eyştene",
    "y": " b. şeyla qewetî eştene"
  },
  {
    "x": "şeyli-I (m)",
    "y": " b. şeyle-I (m)"
  },
  {
    "x": "şeyli-II (m)",
    "y": " b. şeyle-II (m)"
  },
  {
    "x": "şeyli eyştene",
    "y": " b. şeyle eştene"
  },
  {
    "x": "şeyli vistene",
    "y": " b. şeyle eştene"
  },
  {
    "x": "şeyra (m)",
    "y": " b. şerîye (n)"
  },
  {
    "x": "şeyşt",
    "y": " b. şeştî"
  },
  {
    "x": "şeyşti",
    "y": " b. şeştî"
  },
  {
    "x": "şeyştin, -e",
    "y": " b. şeştîyin, -e"
  },
  {
    "x": "şeyştiyin, -e",
    "y": " b. şeştîyin, -e"
  },
  {
    "x": "şeyştî",
    "y": " b. şeştî"
  },
  {
    "x": "şeyştîyin, -e",
    "y": " b. şeştîyin, -e"
  },
  {
    "x": "şeyta (n)",
    "y": " b. şeytan (n)"
  },
  {
    "x": "şeytan, -e",
    "y": " 1)şeytan, iblis\r\n~2)b. şeytanok (n)"
  },
  {
    "x": "şeytan bi … huyayene",
    "y": " ihtilâm olmak, düş azmak\r\n~Emşo şeytan bi mi huya. (Bu gece ihtilâm oldum.)"
  },
  {
    "x": "şeytan pey … huyayene",
    "y": " b. şeytan bi … huyayene"
  },
  {
    "x": "şeytanî … xapeynayene",
    "y": " b. şeytanî … xapênayene"
  },
  {
    "x": "şeytanî … xapênayene",
    "y": " ihtilâm olmak, düş azmak\r\n~Emşo şeytanî ez xapênaya. (Bu gece ihtilâm oldum.)"
  },
  {
    "x": "şeytanî … xapînayene",
    "y": " b. şeytanî … xapênayene"
  },
  {
    "x": "şeytaney (m)",
    "y": " b. şeytanî (m), şeytanîye (m)"
  },
  {
    "x": "şeytanê (m)",
    "y": " b. şeytanî (m), şeytanîye (m)"
  },
  {
    "x": "şeytanênî (m)",
    "y": " şeytanlık, iblislik"
  },
  {
    "x": "şeytanî (m)",
    "y": " şeytanlık, iblislik"
  },
  {
    "x": "şeytanîye (m)",
    "y": " şeytanlık, iblislik"
  },
  {
    "x": "şeytanok (n)",
    "y": " salyangoz "
  },
  {
    "x": "şeytar (n)",
    "y": " b. şerte (n)"
  },
  {
    "x": "şeytarkardiş (n)",
    "y": " b. şertekerdiş (n)"
  },
  {
    "x": "şeytarkerdiş (n)",
    "y": " b. şertekerdiş (n)"
  },
  {
    "x": "şeyti (m)",
    "y": " b. şewte (m)"
  },
  {
    "x": "şeyto (n)",
    "y": " b. şeytan, -e"
  },
  {
    "x": "şeyton, -i",
    "y": " b. şeytan, -e"
  },
  {
    "x": "şeytonê (m)",
    "y": " b. şeytanî (m), şeytanîye (m)"
  },
  {
    "x": "şeytonik (n)",
    "y": " b. şeytanok (n)"
  },
  {
    "x": "şeytû (n)",
    "y": " b. şeytan, -e"
  },
  {
    "x": "şeytûn (n)",
    "y": " b. şeytan (n)"
  },
  {
    "x": "şeytûney",
    "y": " b. şeytanî (m), şeytanîye (m)"
  },
  {
    "x": "şeytûnê (m)",
    "y": " b. şeytanî (m), şeytanîye (m)"
  },
  {
    "x": "şê (m)",
    "y": " b. şaye (m)"
  },
  {
    "x": "şêdênayîne",
    "y": " b. şidênayene"
  },
  {
    "x": "şêdênayîş (n)",
    "y": " b. şidênayîş (n)"
  },
  {
    "x": "şêhîd, -e",
    "y": " b. şehîd, -e"
  },
  {
    "x": "şêhîr (n)",
    "y": " b. şehîr (n)"
  },
  {
    "x": "şêhre (n)",
    "y": " b. şerîye (n)"
  },
  {
    "x": "şêl (n)",
    "y": " b. sêl (n)"
  },
  {
    "x": "şêle (m)",
    "y": " tutum"
  },
  {
    "x": "şêli (m)",
    "y": " b. şeyle-II (m)"
  },
  {
    "x": "şêley quwetî (m)",
    "y": " b. şeyla qewetî (m)"
  },
  {
    "x": "şêley quwetî eştene",
    "y": " b. şeyla qewetî eştene"
  },
  {
    "x": "şêli eştene",
    "y": " b. şeyle eştene"
  },
  {
    "x": "şêlik (n)",
    "y": " geçim"
  },
  {
    "x": "şêm (n)",
    "y": " b. sêm (n)"
  },
  {
    "x": "şêmên, -e",
    "y": " b. sêmên, -e"
  },
  {
    "x": "şêmige (m)",
    "y": " b. şêmûge (m)"
  },
  {
    "x": "şêmin, -e",
    "y": " b. sêmên, -e"
  },
  {
    "x": "şêmuge (m)",
    "y": " b. şêmûge (m)"
  },
  {
    "x": "şêmûge (m)",
    "y": " eşik"
  },
  {
    "x": "şên, -e",
    "y": " neşeli, sevinçli, şen"
  },
  {
    "x": "şên bîyene",
    "y": " neşelenmek, sevinmek, memnun kalmak, memnun olmak"
  },
  {
    "x": "şên kerdene",
    "y": " neşelendirmek, sevindirmek, memnun etmek"
  },
  {
    "x": "şênayî (m)",
    "y": " neşe, sevinç, şenlik (şen olma durumu); eğlence, cümbüş"
  },
  {
    "x": "şênber, -e",
    "y": " somut, müşahhas"
  },
  {
    "x": "şêne-I (n)",
    "y": " terazi, tartaç"
  },
  {
    "x": "şêne-II (n)",
    "y": " b. sêne (n)"
  },
  {
    "x": "şêney (m)",
    "y": " b. şênîye (m), şênî (m)"
  },
  {
    "x": "şênê (m)",
    "y": " b. şênîye (m), şênî (m)"
  },
  {
    "x": "şêni (n)",
    "y": " b. şêne-I (n)"
  },
  {
    "x": "şênig (m)",
    "y": " b. şinike (m)"
  },
  {
    "x": "şênî (m)",
    "y": " neşe, sevinç, şenlik (şen olma durumu)"
  },
  {
    "x": "şênîye-I (m)",
    "y": " neşe, sevinç, şenlik (şen olma durumu)"
  },
  {
    "x": "şênîye-II (m)",
    "y": " b. sênîye (m)"
  },
  {
    "x": "şêr, -e-I",
    "y": " aslan"
  },
  {
    "x": "şêr-II",
    "y": " b. seyr"
  },
  {
    "x": "şêr kerdene",
    "y": " b. seyr kerdene"
  },
  {
    "x": "şêri-I (m)",
    "y": " b. şêre (m)"
  },
  {
    "x": "şêri-II (n)",
    "y": " b. şerîye (n)"
  },
  {
    "x": "şêrin, -e (s)",
    "y": " b. şîrin, -e (s)"
  },
  {
    "x": "şêrî (s)",
    "y": " b. şîrin, -e (s)"
  },
  {
    "x": "şêrîn, -e (s)",
    "y": " b. şîrin, -e (s)"
  },
  {
    "x": "şêrînîye (m)",
    "y": " b. şîrinî (m), şîrinîye (m)"
  },
  {
    "x": "şêrkerdox, -e",
    "y": " b. seyrkerdox, -e"
  },
  {
    "x": "şêrne (n)",
    "y": " b. şîrane (n)"
  },
  {
    "x": "Şêrvan (n)",
    "y": " Şirvan"
  },
  {
    "x": "şêseme (n)",
    "y": " b. sêşeme (n)"
  },
  {
    "x": "şêst",
    "y": " b. şeştî "
  },
  {
    "x": "şêşeme (n)",
    "y": " b. sêşeme (n)"
  },
  {
    "x": "şêşt",
    "y": " b. şeştî "
  },
  {
    "x": "şêştay",
    "y": " b. şeştî"
  },
  {
    "x": "şêştin, -e",
    "y": " b. şeştîyin, -e"
  },
  {
    "x": "şêştî",
    "y": " b. şeştî"
  },
  {
    "x": "şêto (n)",
    "y": " b. şeytan, -e"
  },
  {
    "x": "şêton (n)",
    "y": " b. şeytan, -e"
  },
  {
    "x": "şêtû (n)",
    "y": " b. şeytan, -e"
  },
  {
    "x": "şêtûn (n)",
    "y": " b. şeytan, -e"
  },
  {
    "x": "şêtûnê (m)",
    "y": " b. şeytanî (m), şeytanîye (m)"
  },
  {
    "x": "şêwirmend, -e",
    "y": " danışman, müşavir"
  },
  {
    "x": "şêwirmenda/ê projeyî",
    "y": " proje danışmanı"
  },
  {
    "x": "şêwirmendî (m)",
    "y": " danışmanlık, müşavirlik"
  },
  {
    "x": "şêwirmendîya huqûqî (m)",
    "y": " hukuk danışmanlığı, hukuk müşavirliği"
  },
  {
    "x": "şêwirmendîye (m)",
    "y": " danışmanlık, müşavirlik"
  },
  {
    "x": "şêx, -e",
    "y": " şeyh"
  },
  {
    "x": "şêxey (m)",
    "y": " b. şêxîye (m)"
  },
  {
    "x": "şêxê (m)",
    "y": " b. şêxîye (m)"
  },
  {
    "x": "şêxênî (m)",
    "y": " şeyhlik"
  },
  {
    "x": "şêxî (m)",
    "y": " şeyhlik"
  },
  {
    "x": "şêxîye (m)",
    "y": " şeyhlik"
  },
  {
    "x": "şêxtî (m)",
    "y": " b. şêxîye (m)"
  },
  {
    "x": "şib (n)",
    "y": " b. şeb (n) "
  },
  {
    "x": "şibak (m)",
    "y": " b. şibake (m)"
  },
  {
    "x": "şibake (m)",
    "y": " bina duvarının iç tarafında bulunan ve dışarıya açılmayan pencere biçimindeki yer"
  },
  {
    "x": "şibaki (m)",
    "y": " b. şibake (m)"
  },
  {
    "x": "şibênayîş (n)",
    "y": " b. şebeynayîş (n)"
  },
  {
    "x": "şibênayene",
    "y": " b. şebeynayene (m)"
  },
  {
    "x": "şibînayene",
    "y": " b. şebeynayene (m)"
  },
  {
    "x": "şibînayîş (n)",
    "y": " b. şebeynayîş (n)"
  },
  {
    "x": "şid (n)",
    "y": " b. şit (n)"
  },
  {
    "x": "şideynayîne",
    "y": " b. şidênayene"
  },
  {
    "x": "şideynayîş (n)",
    "y": " b. şidênayîş (n)"
  },
  {
    "x": "şidênaya (m)",
    "y": " sağlam"
  },
  {
    "x": "şidênaye (n)",
    "y": " sağlam"
  },
  {
    "x": "şidênayene",
    "y": " sıkıştırmak, sıkmak, sağlamlaştırmak"
  },
  {
    "x": "şidênayeyey (m)",
    "y": " b. şidênayeyî (m)"
  },
  {
    "x": "şidênayeyê (m)",
    "y": " b. şidênayeyî (m)"
  },
  {
    "x": "şidênayeyî (m)",
    "y": " sağlamlık"
  },
  {
    "x": "şidênayê (m)",
    "y": " b. şidênayî (m)"
  },
  {
    "x": "şidênayî (m)",
    "y": " sağlam"
  },
  {
    "x": "şidênayîş (n)",
    "y": " sıkıştırma, sıkma, sağlamlaştırma"
  },
  {
    "x": "şidênayîtî (m)",
    "y": " sağlamlık"
  },
  {
    "x": "şidênayîye (m)",
    "y": " sağlam"
  },
  {
    "x": "şidênayîyey (m)",
    "y": " b. şidênayîyî (m)"
  },
  {
    "x": "şidênayîyê (m)",
    "y": " b. şidênayîyî (m)"
  },
  {
    "x": "şidênayîyî (m)",
    "y": " sağlamlık"
  },
  {
    "x": "şidênayîyîye (m)",
    "y": " sağlamlık"
  },
  {
    "x": "şidnayene",
    "y": " b. şidênayene"
  },
  {
    "x": "şidnayene",
    "y": " b. şidênayene"
  },
  {
    "x": "şidnayîne",
    "y": " b. şidênayene"
  },
  {
    "x": "şidnayîş (n)",
    "y": " b. şidênayîş (n)"
  },
  {
    "x": "şier (m)",
    "y": " b. şîîre (m)"
  },
  {
    "x": "şiere (n)",
    "y": " b. şerîye (n)"
  },
  {
    "x": "şifaq (n)",
    "y": " b. şefeq (n)"
  },
  {
    "x": "şifer (n)",
    "y": " b. şofor, -e"
  },
  {
    "x": "şifil (n)",
    "y": " b. şilfe (m)"
  },
  {
    "x": "şifili (m)",
    "y": " b. şilfe (m)"
  },
  {
    "x": "şifl (n)",
    "y": " b. şilfe (m)"
  },
  {
    "x": "şifli (m)",
    "y": " b. şilfe (m)"
  },
  {
    "x": "şifti (m)",
    "y": " b. şewte (m)"
  },
  {
    "x": "şifur (n)",
    "y": " b. şofor, -e"
  },
  {
    "x": "şiî, -ye",
    "y": " b. şîî, -ye"
  },
  {
    "x": "şiîre-I (m)",
    "y": " b. şîîre (m)"
  },
  {
    "x": "şiîre-II (n)",
    "y": " b. şerîye (n)"
  },
  {
    "x": "şiîtî (m)",
    "y": " b. şîîtî (m)"
  },
  {
    "x": "şijî (m)",
    "y": " ayran veya yoğurdun üste çıkan çok sulu kısmı (ayranlı su)"
  },
  {
    "x": "şik (n)",
    "y": " kuşku, şüphe"
  },
  {
    "x": "şik kerdene",
    "y": " kuşkulanmak, şüphelenmek"
  },
  {
    "x": "şikeft (n)",
    "y": " b. şikefte (m)"
  },
  {
    "x": "şikefte (m)",
    "y": " mağara"
  },
  {
    "x": "şikefti (m)",
    "y": " b. şikefte (m)"
  },
  {
    "x": "şikeva (n)",
    "y": " b. eşkeva (n)"
  },
  {
    "x": "şikil (n)",
    "y": " b. şekil (n)"
  },
  {
    "x": "şikita (m)",
    "y": " 1)kırık\r\n~2)yaşlı (kadın), ihtiyar (kadın)"
  },
  {
    "x": "şikite-I (n)",
    "y": " 1)kırık\r\n~2)yaşlı (erkek), ihtiyar (erkek)"
  },
  {
    "x": "şikite-II (n)",
    "y": " kayalık"
  },
  {
    "x": "şikitene",
    "y": " kırmak"
  },
  {
    "x": "şikitiş (n)",
    "y": " kırma"
  },
  {
    "x": "şikitîye (m)",
    "y": " 1)kırık\r\n~2)yaşlı (kadın), ihtiyar (kadın)"
  },
  {
    "x": "şikîyaya (m)",
    "y": " kırık"
  },
  {
    "x": "şikîyaye (n)",
    "y": " kırık"
  },
  {
    "x": "şikîyayene",
    "y": " kırılmak"
  },
  {
    "x": "şikîyayîş (n)",
    "y": " kırılma"
  },
  {
    "x": "şikîyayîye (m)",
    "y": " kırık"
  },
  {
    "x": "şikkerda (m)",
    "y": " kuşkulu, şüpheli"
  },
  {
    "x": "şikkerde (n)",
    "y": " kuşkulu, şüpheli "
  },
  {
    "x": "şikl (n)",
    "y": " b. şekil (n)"
  },
  {
    "x": "şikta (m)",
    "y": " b. şikita (m), şikitîye (m)"
  },
  {
    "x": "şikte (n)",
    "y": " b. şikite (n)"
  },
  {
    "x": "şiktene",
    "y": " b. şikitene"
  },
  {
    "x": "şiktiş (n)",
    "y": " b. şikitiş (n)"
  },
  {
    "x": "şikuncî (m)",
    "y": " b. eskije (m)"
  },
  {
    "x": "şikyaya (m)",
    "y": " b. şikîyaya (m)"
  },
  {
    "x": "şikyaye (n)",
    "y": " b. şikîyaye (n)"
  },
  {
    "x": "şikyayene",
    "y": " b. şikîyayene"
  },
  {
    "x": "şikyayîş (n)",
    "y": " b. şikîyayîş (n)"
  },
  {
    "x": "şikyayîye (m)",
    "y": " b. şikîyayîye (m)"
  },
  {
    "x": "şilabend (n)",
    "y": " uçkur"
  },
  {
    "x": "şilamuînd (m)",
    "y": " b. şilabend (n)"
  },
  {
    "x": "şilamund (m)",
    "y": " b. şilabend (n) "
  },
  {
    "x": "şilamûnd (m)",
    "y": " b. şilabend (n)"
  },
  {
    "x": "şilasnayîş (n)",
    "y": " b. şinasnayîş (n)"
  },
  {
    "x": "şilawend (n)",
    "y": " b. şilabend (n) "
  },
  {
    "x": "şilawinde (m)",
    "y": " b. şilabend (n) "
  },
  {
    "x": "şilawînd (n)",
    "y": " b. şilabend (n) "
  },
  {
    "x": "şilawundî (m)",
    "y": " b. şilabend (n)"
  },
  {
    "x": "şileyl (n)",
    "y": " b. şilêl (n)"
  },
  {
    "x": "şilêl (n)",
    "y": " çavdar "
  },
  {
    "x": "şilêlin, -e",
    "y": " çavdarlı"
  },
  {
    "x": "şilênayîş (n)",
    "y": " b. şelênayîş (n)"
  },
  {
    "x": "şilf (n)",
    "y": " b. şilfe (m)"
  },
  {
    "x": "şilfe (m)",
    "y": " kabzasız bıçak, bıçağın kabza dışında kalan madeni kısmı"
  },
  {
    "x": "şilike (m)",
    "y": " ense"
  },
  {
    "x": "şilikî (m)",
    "y": " akıtma (cıvıkça hamurdan yapılan bir tür tatlı)"
  },
  {
    "x": "şilî (m)",
    "y": " 1)yağış\r\n~2)yağmur"
  },
  {
    "x": "şilî-şepelî (m)",
    "y": " b. şilîûşepelî (m)"
  },
  {
    "x": "şilîya sîyaye (n)",
    "y": " siyah yağmur"
  },
  {
    "x": "şilîpeym (n)",
    "y": " yağışölçer, plüviyometre"
  },
  {
    "x": "şilîûşelapî (m)",
    "y": " b. şilîûşepelî (m)"
  },
  {
    "x": "şilîûşepelî (m)",
    "y": " sulusepken kar, yağmurla karışık yağan kar"
  },
  {
    "x": "şilîûşeplî (m)",
    "y": " b. şilîûşepelî (m)"
  },
  {
    "x": "şilîye (m)",
    "y": " yağmur"
  },
  {
    "x": "şilîyin (n)",
    "y": " 1)yağışlı\r\n~2)yağmurlu"
  },
  {
    "x": "şilkî (m)",
    "y": " b. şilikî (m)"
  },
  {
    "x": "şillikî (m)",
    "y": " b. şilikî (m)"
  },
  {
    "x": "şillint (n)",
    "y": " b. şulint (n)"
  },
  {
    "x": "şilnaya (m)",
    "y": " b. şelênaya (m)"
  },
  {
    "x": "şilnaye (n)",
    "y": " b. şelênaye (n)"
  },
  {
    "x": "şilnayene",
    "y": " b. şelênayene"
  },
  {
    "x": "şilnayîş (n)",
    "y": " b. şelênayîş (n)"
  },
  {
    "x": "şilnayîye (m)",
    "y": " b. şelênayîye (m)"
  },
  {
    "x": "şilnayox (n)",
    "y": " b. şelênayox, -e"
  },
  {
    "x": "şilnayoxê (m)",
    "y": " b. şelênayoxî (m)"
  },
  {
    "x": "şilope (n)",
    "y": " sulusepken kar, yağmurla karışık yağan kar"
  },
  {
    "x": "şilor (n)",
    "y": " çapak"
  },
  {
    "x": "şilor dayene",
    "y": " çapaklanmak"
  },
  {
    "x": "şilor girewtene",
    "y": " çapaklanmak"
  },
  {
    "x": "şilordayîş (n)",
    "y": " çapaklanış, çapaklanma"
  },
  {
    "x": "şilorgirewtiş (n)",
    "y": " çapaklanma, çapaklanış"
  },
  {
    "x": "şilpî (m)",
    "y": " b. şîlpate (m)"
  },
  {
    "x": "şilpî piro dayene",
    "y": " b. şîlpatî piro dayene"
  },
  {
    "x": "şilpî wardene",
    "y": " b. şîlpate werdene"
  },
  {
    "x": "şilpîpirodayîş (n)",
    "y": " b. şîlpatîpirodayîş (n)"
  },
  {
    "x": "şilpîwardiş (n)",
    "y": " b. şîlpatewerdiş (n) "
  },
  {
    "x": "şilt (m)",
    "y": " şelbûte (m)"
  },
  {
    "x": "şiltike (m)",
    "y": " b. şilike (m)"
  },
  {
    "x": "şiluki (m)",
    "y": " b. şilikî (m)"
  },
  {
    "x": "şilukî (m)",
    "y": " b. şilikî (m)"
  },
  {
    "x": "şilumpe (n)",
    "y": " b. şilope (n)"
  },
  {
    "x": "şilûf (n)",
    "y": " ferik (erginleşmemiş horoz)"
  },
  {
    "x": "şilwalindî (m)",
    "y": " b. şilabend (n)"
  },
  {
    "x": "şilwalî (zh)",
    "y": " b. şelwarî (zh)"
  },
  {
    "x": "şilwar (zh)",
    "y": " b. şelwarî (zh)"
  },
  {
    "x": "şilwarî (zh)",
    "y": " b. şelwarî (zh)"
  },
  {
    "x": "şişa (m)",
    "y": " b. şuşe (n)"
  },
  {
    "x": "şima-I (zh, z)",
    "y": " siz"
  },
  {
    "x": "şima-II (m)",
    "y": " b. şema (m)"
  },
  {
    "x": "şimak (m)",
    "y": " b. şibake (m)"
  },
  {
    "x": "şimaki (m)",
    "y": " b. şibake (m)"
  },
  {
    "x": "şimalîyê eyelan (n)",
    "y": " çocuk bezi, etek bezi"
  },
  {
    "x": "şimalîyê eyelon (n)",
    "y": " b. şimalîyê eyelan (n)"
  },
  {
    "x": "şiman (zh, z)",
    "y": " b. şima-I (zh, z)"
  },
  {
    "x": "şimaqe (m)",
    "y": " b. şîmaqe (m)"
  },
  {
    "x": "şimay (m)",
    "y": " b. şema-I (m)"
  },
  {
    "x": "şimbêle (m)",
    "y": " b. şimêle (m)"
  },
  {
    "x": "şimbêli (m)",
    "y": " b. şimêle (m)"
  },
  {
    "x": "şimel (m)",
    "y": " b. şimêle (m)"
  },
  {
    "x": "şimêle (m)",
    "y": " biçilip yerde bırakılmış olan ekin dizisi"
  },
  {
    "x": "şimige (m)",
    "y": " b. şêmûge (m)"
  },
  {
    "x": "şimik-I (n)",
    "y": " terlik"
  },
  {
    "x": "şimik-II (n)",
    "y": " b. çimik (n)"
  },
  {
    "x": "şimike (m)",
    "y": " b. şimik-I (n)"
  },
  {
    "x": "şimişta (m)",
    "y": " asma veya üzüm teveğinin kesilmiş olan ince, kuru dalları "
  },
  {
    "x": "şimite (m)",
    "y": " içki"
  },
  {
    "x": "şimita alkoline (m)",
    "y": " alkollü içki"
  },
  {
    "x": "şimitene",
    "y": " içmek (su, içki, sigara vb. için)"
  },
  {
    "x": "şimitox, -e",
    "y": " içici, içkici"
  },
  {
    "x": "şimîyeli (m)",
    "y": " b. şimêle (m)"
  },
  {
    "x": "şimlik (m)",
    "y": " b. şimlike (m)"
  },
  {
    "x": "şimlike (m)",
    "y": " ölmez çiçek, altın otu, mayasıl otu, sarıçiçek, yaylagülü, yaylaçiçeği"
  },
  {
    "x": "şimondî (m)",
    "y": " b. şimûndî (m)"
  },
  {
    "x": "şimşêr (n)",
    "y": " kılıç"
  },
  {
    "x": "şimşîr (n)",
    "y": " b. şimşêr (n)"
  },
  {
    "x": "şimşîyer (n)",
    "y": " b. şimşêr (n)"
  },
  {
    "x": "şimşyer (n)",
    "y": " b. şimşêr (n)"
  },
  {
    "x": "şimûek (m)",
    "y": " b. şanike-I (m)"
  },
  {
    "x": "şimûînd (m)",
    "y": " b. şimûndî (m)"
  },
  {
    "x": "şimûnd (m)",
    "y": " b. şimûndî (m)"
  },
  {
    "x": "şimûndî (m)",
    "y": " çuvalın ağzını dikmek, çarık ve çorap bağlamak vb. için kullanılan kıldan yapılma ip"
  },
  {
    "x": "şinasî, -ye",
    "y": " tanıdık, tanış"
  },
  {
    "x": "şinasî bîyene",
    "y": " tanıdık olmak, tanış olmak, tanınmak"
  },
  {
    "x": "şinasîbîyayîş (n)",
    "y": " tanınma"
  },
  {
    "x": "şinasîyayene",
    "y": " tanınmak"
  },
  {
    "x": "şinasîyayîş (n)",
    "y": " tanınma"
  },
  {
    "x": "şinasnaya (m)",
    "y": " tanınmış, ünlü"
  },
  {
    "x": "şinasnaye (n)",
    "y": " tanınmış, ünlü"
  },
  {
    "x": "şinasnayene",
    "y": " tanımak "
  },
  {
    "x": "şinasnayîş (n)",
    "y": " tanıma"
  },
  {
    "x": "şinasnayîye (m)",
    "y": " tanınmış, ünlü"
  },
  {
    "x": "şinasyayîne",
    "y": " b. şinasîyayene"
  },
  {
    "x": "şinasyayîş (n)",
    "y": " b. şinasîyayîş (n)"
  },
  {
    "x": "şinaşî",
    "y": " b. şinasî, -ye"
  },
  {
    "x": "şinawitene",
    "y": " b. eşnawitene"
  },
  {
    "x": "şinawitiş (n)",
    "y": " b. eşnawitiş (n)"
  },
  {
    "x": "şinawutene",
    "y": " b. eşnawitene"
  },
  {
    "x": "şinawutiş (n)",
    "y": " b. eşnawitiş (n)"
  },
  {
    "x": "şinayene-I",
    "y": " hapşırmak, aksırmak"
  },
  {
    "x": "şinayene-II",
    "y": " b. eşnawitene-II"
  },
  {
    "x": "şinayêne",
    "y": " b. şinayene-I"
  },
  {
    "x": "şinayîş-I (n)",
    "y": " hapşırma, aksırma, aksırış"
  },
  {
    "x": "şinayîş-I (n)",
    "y": " b. eşnawitiş (n), hesnayîş (n)"
  },
  {
    "x": "şinbêl (m)",
    "y": " b. şimêle (m)"
  },
  {
    "x": "şing (m)",
    "y": " b. şinge (m)"
  },
  {
    "x": "şinge (m)",
    "y": " yelmük (bitkisi)\r\n~şinga heran (m): bir çeşit yelmük (bitkisi)\r\n~şinga xatunan (m): bir çeşit yelmük (bitkisi)"
  },
  {
    "x": "şinga heran (m)",
    "y": " bir çeşit yelmük (bitkisi)"
  },
  {
    "x": "şinga xatunan (m)",
    "y": " bir çeşit yelmük (bitkisi)"
  },
  {
    "x": "şinga xatunon (m)",
    "y": " b. şinga xatunan (m)"
  },
  {
    "x": "şinga xatunûn (m)",
    "y": " b. şinga xatunan (m)"
  },
  {
    "x": "şingey xatunon (m)",
    "y": " b. şinga xatunan (m)"
  },
  {
    "x": "şingi (m)",
    "y": " b. şinge (m)"
  },
  {
    "x": "şinik-I (n)",
    "y": " kile, ölçek (tahıl ölçmeye yarar kap), şinik "
  },
  {
    "x": "şinik, -e-II",
    "y": " b. şenik, -e"
  },
  {
    "x": "şinik-III (m)",
    "y": " b. şinike (m)"
  },
  {
    "x": "şinik, -i",
    "y": " b. şenik, -e"
  },
  {
    "x": "şinike (m)",
    "y": " el açıldığında baş parmağın ucu ile işaret parmağının ucu arasındaki uzunluk"
  },
  {
    "x": "şinikek, -i",
    "y": " b. şenikek, -e"
  },
  {
    "x": "şinîg (n)",
    "y": " b. şinik-I (n)"
  },
  {
    "x": "şip (m)",
    "y": " b. şîpe (m)"
  },
  {
    "x": "şipik (n)",
    "y": " b. sîpe-I (n)"
  },
  {
    "x": "şiplore (m)",
    "y": " b. şaplore (m)"
  },
  {
    "x": "şiplore vetene",
    "y": " b. şaplore vetene"
  },
  {
    "x": "şiq (m)",
    "y": " b. şiqe (m)"
  },
  {
    "x": "şiqe (m)",
    "y": " 1)parça\r\n~2)genişliği az olan tarla veya tarlanın bir bölümü\r\n~3)üzerine pestil bulamacı sürülerek pestil yapımı için kullanılan bez"
  },
  {
    "x": "şiqi (m)",
    "y": " b. şiqe (m)"
  },
  {
    "x": "şiqoqe (m)",
    "y": " b. şekoke (m)"
  },
  {
    "x": "şiqoqêre (m)",
    "y": " b. şekokêre (m)"
  },
  {
    "x": "şiqoqi (m)",
    "y": " b. şekoke (m)"
  },
  {
    "x": "şirasîyayene",
    "y": " b. şinasîyayene"
  },
  {
    "x": "şirasîyayîş (n)",
    "y": " b. şinasîyayîş (n)"
  },
  {
    "x": "şirasnaya (m)",
    "y": " b. şinasnaya (m)"
  },
  {
    "x": "şirasnaye (n)",
    "y": " b. şinasnaye (n)"
  },
  {
    "x": "şirasnayene",
    "y": " b. şinasnayene"
  },
  {
    "x": "şirasnayîş (n)",
    "y": " b. şinasnayîş (n)"
  },
  {
    "x": "şirawitene",
    "y": " b. şawitene"
  },
  {
    "x": "şirawitiş (n)",
    "y": " b. şawitiş (n)"
  },
  {
    "x": "şirawtene",
    "y": " b. şawitene"
  },
  {
    "x": "şirawtiş (n)",
    "y": " b. şawitiş (n)"
  },
  {
    "x": "şirika lewî (m)",
    "y": " alt dudağın ortasındaki ince girinti"
  },
  {
    "x": "şirîk, -e",
    "y": " hissedar, ortak, paydaş\r\n~“Xele bi firîk, mela bi şirîk.”"
  },
  {
    "x": "şirîk bîyayene",
    "y": " b. şirîk bîyene"
  },
  {
    "x": "şirîk bîyene",
    "y": " ortak olmak"
  },
  {
    "x": "şirîkatî (m)",
    "y": " ortakçılık"
  },
  {
    "x": "şirîkatîye (m)",
    "y": " ortakçılık"
  },
  {
    "x": "şirîkayî (m)",
    "y": " ortakçılık"
  },
  {
    "x": "şirîkey (m)",
    "y": " b. şirîkîye (m)"
  },
  {
    "x": "şirîkê (m)",
    "y": " b. şirîkî (m)"
  },
  {
    "x": "şirîkî (m)",
    "y": " ortakçılık"
  },
  {
    "x": "şirîkîye (m)",
    "y": " ortakçılık"
  },
  {
    "x": "şirîn, -e (s)",
    "y": " b. şîrin, -e (s)"
  },
  {
    "x": "şirît (n)",
    "y": " kiriş, binanın çatısını taşıması için tavanda yatay olarak kullanılan çok kalın direk"
  },
  {
    "x": "şirove (n)",
    "y": " yorum, tefsir, şerh"
  },
  {
    "x": "şirove kerdene",
    "y": " yorumlamak, yorum yapmak"
  },
  {
    "x": "şiroveker, -e",
    "y": " yorumcu"
  },
  {
    "x": "şirovekerênî (m)",
    "y": " yorumculuk"
  },
  {
    "x": "şirovekerî (m)",
    "y": " yorumculuk"
  },
  {
    "x": "şirovekerîye (m)",
    "y": " yorumculuk"
  },
  {
    "x": "şirt (n)",
    "y": " b. şert (n)"
  },
  {
    "x": "şirte (n)",
    "y": " b. şerte (n)"
  },
  {
    "x": "şirtîqer, -e",
    "y": " b. şîrteqer, -e"
  },
  {
    "x": "şirûb (n)",
    "y": " b. şurub (n)"
  },
  {
    "x": "şirwalî (zh)",
    "y": " b. şelwarî (zh)"
  },
  {
    "x": "şişt",
    "y": " b. şeştî "
  },
  {
    "x": "şit (n)",
    "y": " süt"
  },
  {
    "x": "şit dayene ...",
    "y": " 1)emzirmek\r\n~2)(hayvan) sağılma sırasında uslu durmak"
  },
  {
    "x": "şit dîyayene ...",
    "y": " emzirilmek"
  },
  {
    "x": "şit nêdayene",
    "y": " (hayvan) kendisini sağdırmamak"
  },
  {
    "x": "şit werdene",
    "y": " süt emmek"
  },
  {
    "x": "şitawe (m)",
    "y": " badana"
  },
  {
    "x": "şitawe kerdene",
    "y": " badanalamak"
  },
  {
    "x": "şitaweker, -e",
    "y": " badanacı"
  },
  {
    "x": "şitawekerdiş (n)",
    "y": " badanalama"
  },
  {
    "x": "şitawekerîye (m)",
    "y": " badanacılık"
  },
  {
    "x": "şitdadî (m)",
    "y": " sütanne, sütana"
  },
  {
    "x": "şitdayîke (m)",
    "y": " sütanne, sütana "
  },
  {
    "x": "şitdayîş ...",
    "y": " emzirme"
  },
  {
    "x": "şitdîyayîş ...",
    "y": " emzirilme"
  },
  {
    "x": "şitene",
    "y": " b. şutene"
  },
  {
    "x": "şitênî (m)",
    "y": " sütlaç"
  },
  {
    "x": "şitil (n)",
    "y": " fide"
  },
  {
    "x": "şitil pirodayene",
    "y": " fidelemek, fide dikmek"
  },
  {
    "x": "şitil ronayene",
    "y": " 1)fidelemek, fide dikmek\r\n~2)tohum ekmek"
  },
  {
    "x": "şitil ronayîne",
    "y": " b. şitil ronayene"
  },
  {
    "x": "şitile (n)",
    "y": " 1)kovan (arı kovanı)\r\n~2)b. şitil (n)"
  },
  {
    "x": "şitile ronayene",
    "y": " b. şitil ronayene"
  },
  {
    "x": "şitin, -e",
    "y": " sütlü"
  },
  {
    "x": "şitini (m)",
    "y": " b. şitine (m)"
  },
  {
    "x": "şitiş (n)",
    "y": " b. şutiş (n)"
  },
  {
    "x": "şitle-I (n)",
    "y": " b. şitil (n)"
  },
  {
    "x": "şitle pirodayîne",
    "y": " b. şitil pirodayene"
  },
  {
    "x": "şitle-II (n)",
    "y": " b. şitile (n)"
  },
  {
    "x": "şitroş, -e",
    "y": " sütçülük"
  },
  {
    "x": "şitroşî (m)",
    "y": " sütçülük"
  },
  {
    "x": "şitroşîye (m)",
    "y": " sütçülük"
  },
  {
    "x": "şiv (m)",
    "y": " b. şewte (m)"
  },
  {
    "x": "şive (m)",
    "y": " b. şewte (m)"
  },
  {
    "x": "şiverê (m)",
    "y": " patika"
  },
  {
    "x": "şivik, -e-I",
    "y": " b. sivik, -e"
  },
  {
    "x": "şivik, -e-II",
    "y": " b. şewte (m)"
  },
  {
    "x": "şiving (n)",
    "y": " b. siving (n)"
  },
  {
    "x": "şiwalendî (m)",
    "y": " b. şilabend (n)"
  },
  {
    "x": "şiwana (m)",
    "y": " çoban"
  },
  {
    "x": "şiwane (n)",
    "y": " çoban"
  },
  {
    "x": "şiwanetî (m)",
    "y": " çobanlık"
  },
  {
    "x": "şiwaneye (m)",
    "y": " b. şiwanîye (m)"
  },
  {
    "x": "şiwaneyî (m)",
    "y": "  çobanlık"
  },
  {
    "x": "şiwaneyîye (m)",
    "y": " çobanlık"
  },
  {
    "x": "şiwanên (m)",
    "y": " b. şiwanênî (m)"
  },
  {
    "x": "şiwanênî (m)",
    "y": " çobanlık"
  },
  {
    "x": "şiwanîn (m)",
    "y": " b. şiwanênî (m)"
  },
  {
    "x": "şiwanîye (m)",
    "y": " çoban"
  },
  {
    "x": "şiwar, -e",
    "y": " b. suwar, -e"
  },
  {
    "x": "şiware (m)",
    "y": " b. şuware (m)"
  },
  {
    "x": "şîn û şiware",
    "y": " b. şîn û şuware"
  },
  {
    "x": "şiwarî, -ye",
    "y": " b. suwarî, -ye"
  },
  {
    "x": "şiwax (n)",
    "y": " b. siwax (n)"
  },
  {
    "x": "şiwîyayene",
    "y": " b. şuwîyayene"
  },
  {
    "x": "şiwone (n)",
    "y": " b. şiwane (n)"
  },
  {
    "x": "şiwoneyê (m)",
    "y": " b. şiwaneyî (m)"
  },
  {
    "x": "şiwûne (n)",
    "y": " b. şiwane (n)"
  },
  {
    "x": "şiwûneyê (m)",
    "y": " b. şiwaneyî (m)"
  },
  {
    "x": "şix, -e",
    "y": " b. şêx, -e"
  },
  {
    "x": "şixulîyayene",
    "y": " b. şuxulîyayene"
  },
  {
    "x": "şixulîyayîş (n)",
    "y": " b. şuxulîyayîş (n)"
  },
  {
    "x": "şixulnayene",
    "y": " b. şuxulnayene"
  },
  {
    "x": "şixulnayîne",
    "y": " b. şuxulnayene"
  },
  {
    "x": "şixulnayîş (n)",
    "y": " b. şuxulnayîş (n)"
  },
  {
    "x": "şî (m)",
    "y": " b. sîye (m)"
  },
  {
    "x": "şîa (m)",
    "y": " Şiilik"
  },
  {
    "x": "şîa, -ye",
    "y": " b. sîya, -ye"
  },
  {
    "x": "şîelik (n)",
    "y": " b. şêlik (n)"
  },
  {
    "x": "şîfa (m)",
    "y": " şifa"
  },
  {
    "x": "şîfon (n)",
    "y": " şifon"
  },
  {
    "x": "şîfon (m)",
    "y": " b. şîfone (m)"
  },
  {
    "x": "şîfone (m)",
    "y": " başörtü, başörtüsü"
  },
  {
    "x": "şîfonîyer (n)",
    "y": " şifoniyer"
  },
  {
    "x": "şîfre (n)",
    "y": " şifre\r\n~Şîfre bivurne. / Şîfre bibedilne. (Şifre değiştir.)"
  },
  {
    "x": "şîî, -ye",
    "y": " Şii"
  },
  {
    "x": "şîîre (m)",
    "y": " şiir"
  },
  {
    "x": "şîîrkî",
    "y": " şiirsel"
  },
  {
    "x": "şîîtî (m)",
    "y": " Şiilik"
  },
  {
    "x": "şîîyey (m)",
    "y": " b. şîîyîye (m)"
  },
  {
    "x": "şîîyê (m)",
    "y": " b. şîîyîye (m)"
  },
  {
    "x": "şîîyîye (m)",
    "y": " Şiilik"
  },
  {
    "x": "şîkayene",
    "y": " b. şayene-I"
  },
  {
    "x": "şîkayêne",
    "y": " b. şayene-I"
  },
  {
    "x": "şîkefe (m)",
    "y": " b. şikefte (m)"
  },
  {
    "x": "şîkîyayene",
    "y": " b. şayene-I"
  },
  {
    "x": "şîkîyayîs (n)",
    "y": " b. şayîş-I"
  },
  {
    "x": "şîkîyayîş-I (n)",
    "y": " b. şayîş-I"
  },
  {
    "x": "şîkîyayîş-II (n)",
    "y": " b. şikîyayîş"
  },
  {
    "x": "şîkyaye (n)",
    "y": " b. şikîyaye (n)"
  },
  {
    "x": "şîl (n)",
    "y": " 1)parçacık\r\n~2)çalı parçası (küçük ağaç parçası)"
  },
  {
    "x": "şîl bîyene",
    "y": " parçacıklara ayrılmak"
  },
  {
    "x": "şîl kerdene",
    "y": " parçacıklara ayırmak"
  },
  {
    "x": "şîlane (m)",
    "y": " kuşburnu"
  },
  {
    "x": "şîlana heşan (m)",
    "y": " bir tür tüylü kuşburnu"
  },
  {
    "x": "şîlanêre (m)",
    "y": " kuşburnu ağacı, yabangülü ağacı"
  },
  {
    "x": "şîlax (m)",
    "y": " b. şîlpaxe (m)"
  },
  {
    "x": "şîlax kerdene",
    "y": " b. şîlpaxî kerdene"
  },
  {
    "x": "şîlax piro dayene",
    "y": " b. şîlpaxî piro dayene"
  },
  {
    "x": "şîlax werdene",
    "y": " b. şîlpaxe werdene"
  },
  {
    "x": "şîlaxkerdiş (n)",
    "y": " b. şîlpaxkerdiş (n)"
  },
  {
    "x": "şîlaxpirodayîş (n)",
    "y": " b. şîlpaxîpirodayîş (n)"
  },
  {
    "x": "şîlaxwerdiş (n)",
    "y": " b. şîlpaxewerdiş (n)"
  },
  {
    "x": "şîlbîyayîş (n)",
    "y": " parçacıklara ayrılma"
  },
  {
    "x": "şîle (n)",
    "y": " b. sîle (m)"
  },
  {
    "x": "şîle werdene",
    "y": " b. sîle werdene"
  },
  {
    "x": "şîlepo (n)",
    "y": " b. şilope (n)"
  },
  {
    "x": "şîlewerdiş (n)",
    "y": " b. sîlewerdiş (n)"
  },
  {
    "x": "şîlgerm, -e",
    "y": " b. şîrgerm, -e"
  },
  {
    "x": "şîlgerm bîyayene",
    "y": " b. şîrgerm bîyene"
  },
  {
    "x": "şîlgerm kerdene",
    "y": " b. şîrgerm kerdene"
  },
  {
    "x": "şîlgermbîyayîş (n)",
    "y": " b. şîrgermbîyayîş (n)"
  },
  {
    "x": "şîlgermkerdiş (n)",
    "y": " b. şîrgermkerdiş (n)"
  },
  {
    "x": "şîlik (n)",
    "y": " alın çatı, alnın ortası"
  },
  {
    "x": "şîlik (n)",
    "y": " b. şêlik (n)"
  },
  {
    "x": "şîlî (m)",
    "y": " b. şilî (m)"
  },
  {
    "x": "Şîlî (m)",
    "y": " Şili"
  },
  {
    "x": "şîlkerdiş (n)",
    "y": " parçacıklara ayırma"
  },
  {
    "x": "şîlkî (m)",
    "y": " b. şilikî (m)"
  },
  {
    "x": "şîlpate (m)",
    "y": " tokat, sille"
  },
  {
    "x": "şîlpate werdene",
    "y": " tokat yemek"
  },
  {
    "x": "şîlpatî kerdene",
    "y": " tokatlamak"
  },
  {
    "x": "şîlpatî pavistene",
    "y": " tokatlamak"
  },
  {
    "x": "şîlpatî piro dayene",
    "y": " tokatlamak"
  },
  {
    "x": "şîlpatewerdiş (n)",
    "y": " tokat yeme"
  },
  {
    "x": "şîlpati (m)",
    "y": " b. şîlpate (m)"
  },
  {
    "x": "şîlpati werdene",
    "y": " b. şîlpate werdene"
  },
  {
    "x": "şîlpatiwerdiş (n)",
    "y": " b. şîlpatewerdiş (n)"
  },
  {
    "x": "şîlpatîkerdiş (n)",
    "y": " tokatlama"
  },
  {
    "x": "şîlpatîpavistiş (n)",
    "y": " tokatlama"
  },
  {
    "x": "şîlpatîpirodayîş (n)",
    "y": " tokatlama"
  },
  {
    "x": "şîlpax (n)",
    "y": " b. şîlpaxe (m)"
  },
  {
    "x": "şîlpax kerdene",
    "y": " b. şîlpaxî kerdene"
  },
  {
    "x": "şîlpax piro dayene",
    "y": " b. şîlpaxî piro dayene"
  },
  {
    "x": "şîlpax werdene",
    "y": " b. şîlpaxe werdene"
  },
  {
    "x": "şîlpaxe (m)",
    "y": " tokat, sille"
  },
  {
    "x": "şîlpaxe werdene",
    "y": " tokat yemek"
  },
  {
    "x": "şîlpaxî kerdene",
    "y": " tokatlamak"
  },
  {
    "x": "şîlpaxî piro dayene",
    "y": " tokatlamak"
  },
  {
    "x": "şîlpaxewerdiş (n)",
    "y": " tokat yeme"
  },
  {
    "x": "şîlpaxi (m)",
    "y": " b. şîlpaxe (m)"
  },
  {
    "x": "şîlpaxi werdene",
    "y": " b. şîlpaxe werdene"
  },
  {
    "x": "şîlpaxiwerdiş (n)",
    "y": " b. şîlpaxewerdiş (n)"
  },
  {
    "x": "şîlpaxîkerdiş (n)",
    "y": " tokatlama"
  },
  {
    "x": "şîlpaxîpirodayîş (n)",
    "y": " tokatlama"
  },
  {
    "x": "şîlpaxkerdiş (n)",
    "y": " b. şîlpaxîkerdiş (n)"
  },
  {
    "x": "şîlpaxpirodayîş (n)",
    "y": " b. şîlpaxîpirodayîş (n)"
  },
  {
    "x": "şîlpaxwerdiş (n)",
    "y": " b. şîlpaxewerdiş (n)"
  },
  {
    "x": "şîmaqe (m)",
    "y": " tokat, sille"
  },
  {
    "x": "şîmaqe werdene",
    "y": " tokat yemek"
  },
  {
    "x": "şîmaqî eştene",
    "y": " tokatlamak"
  },
  {
    "x": "şîmaqî piro dayene",
    "y": " tokatlamak"
  },
  {
    "x": "şîmaqewerdiş (n)",
    "y": " tokat yeme"
  },
  {
    "x": "şîmaqîeştiş (n)",
    "y": " tokatlama"
  },
  {
    "x": "şîmaqîpirodayîş (n)",
    "y": " tokatlama"
  },
  {
    "x": "şîmel (m)",
    "y": " b. şimêle (m)"
  },
  {
    "x": "şîmige (m)",
    "y": " b. şêmûge (m)"
  },
  {
    "x": "şîmşer (n)",
    "y": " b. şimşêr (n)"
  },
  {
    "x": "şîmşêr (n)",
    "y": " b. şimşêr (n)"
  },
  {
    "x": "şîn-I (n)",
    "y": " yas"
  },
  {
    "x": "şîn û şiware",
    "y": " b. şîn û şuware"
  },
  {
    "x": "şîn û şuware",
    "y": " yas ve ağıt"
  },
  {
    "x": "şîn û şîwan",
    "y": " yas ve ağıt"
  },
  {
    "x": "şîn-II (n)",
    "y": " b. şêne-I (n)"
  },
  {
    "x": "şîn-III (n)",
    "y": " b. kewe, -ye-I"
  },
  {
    "x": "şînate (m)",
    "y": " b. senet (n)"
  },
  {
    "x": "şînatkar, -e",
    "y": " b. senetkar, -e"
  },
  {
    "x": "şînayene",
    "y": " b. şayene-I"
  },
  {
    "x": "şînayîş (n)",
    "y": " b. şayîş-I (n)"
  },
  {
    "x": "şîndor (n)",
    "y": " b. sînor (n)"
  },
  {
    "x": "şîndor kerdene",
    "y": " b. sînor kerdene"
  },
  {
    "x": "şîndoro viraşte (n)",
    "y": " b. sînoro viraşte (n)"
  },
  {
    "x": "şîneg (m)",
    "y": " b. şinike (m)"
  },
  {
    "x": "şîndor bîyene",
    "y": " b. sînor bîyene"
  },
  {
    "x": "şîndor kerdene",
    "y": " b. sînor kerdene"
  },
  {
    "x": "şîndorbîyayîş (n)",
    "y": " b. sînorbîyayîş (n)"
  },
  {
    "x": "şîndorkerdiş (n)",
    "y": " b. sînorkerdiş (n)"
  },
  {
    "x": "şînema (m)",
    "y": " b. sînema (m)"
  },
  {
    "x": "şînî (m)",
    "y": " b. sênî-I (m), sênîye (m)"
  },
  {
    "x": "şînxane (n)",
    "y": " taziye evi, yas evi"
  },
  {
    "x": "şîp-I (m)",
    "y": " b. şîpe (m)"
  },
  {
    "x": "şîp, -e-II",
    "y": " b. kîp, -e"
  },
  {
    "x": "şîpe (m)",
    "y": " burgaç, girdap, anafor, eğrim, çevri; hızı çok olan su akıntısı"
  },
  {
    "x": "şîpe ver kewtene",
    "y": " akıntıya kapılmak"
  },
  {
    "x": "şîpi (m)",
    "y": " b. şîpe (m)"
  },
  {
    "x": "şîplaxi (m)",
    "y": " b. şîlpaxe (m)"
  },
  {
    "x": "şîplaxi kerdene",
    "y": " b. şîlpaxî kerdene"
  },
  {
    "x": "şîplaxi piro dayene",
    "y": " b. şîlpaxî piro dayene"
  },
  {
    "x": "şîplaxi werdene",
    "y": " b. şîlpaxe werdene"
  },
  {
    "x": "şîplaxikerdiş (n)",
    "y": " b. şîlpaxkerdiş (n)"
  },
  {
    "x": "şîplaxipirodayîş (n)",
    "y": " b. şîlpaxîpirodayîş (n)"
  },
  {
    "x": "şîplaxiwerdiş (n)",
    "y": " b. şîlpaxewerdiş (n)"
  },
  {
    "x": "şîqayene",
    "y": " ötmek (karga, saksağan vb. için) "
  },
  {
    "x": "şîqayîş (n)",
    "y": " ötme (karga, saksağan vb. için) "
  },
  {
    "x": "şîr (n)",
    "y": " sîr (n)"
  },
  {
    "x": "şîrane (n)",
    "y": " bağ ürünü -pestil vb.- tatlı yiyeceklerin genel adı"
  },
  {
    "x": "şîrane kerdene",
    "y": " (bağ bozumu sırasında) bağ ürünü tatlılar hazırlamak"
  },
  {
    "x": "şîranî (nm, m)",
    "y": " tatlı (tatlı yiyecek)"
  },
  {
    "x": "şîranîrotox, -e",
    "y": " tatlıcı"
  },
  {
    "x": "şîranîviraştox, -e",
    "y": " tatlıcı"
  },
  {
    "x": "şîranîye (n, mm)",
    "y": " tatlı (tatlı yiyecek)"
  },
  {
    "x": "şîrayene",
    "y": " kişnemek"
  },
  {
    "x": "şîrayeni",
    "y": " b. şîrayene"
  },
  {
    "x": "şîrayîş (n)",
    "y": " kişneme"
  },
  {
    "x": "şîrbonî (m)",
    "y": " halay "
  },
  {
    "x": "şîre (n)",
    "y": " şıra"
  },
  {
    "x": "şîren, -e (s)",
    "y": " b. şîrin, -e (s)"
  },
  {
    "x": "şîrez (n)",
    "y": " b. şîrêz (n)"
  },
  {
    "x": "şîrê (n)",
    "y": " b. şîrin (n)"
  },
  {
    "x": "şîrên, -e (s)",
    "y": " b. şîrin, -e (s)"
  },
  {
    "x": "şîrênênî (m)",
    "y": " b. şîrinênî (m)"
  },
  {
    "x": "şîrênîye (m)",
    "y": " b. şîrinî (m), şîrinîye (m)"
  },
  {
    "x": "şîrêz (n)",
    "y": " 1)çiriş\r\n~2)yapıştırıcı"
  },
  {
    "x": "şîrgerm, -e",
    "y": " ılık"
  },
  {
    "x": "şîrgerm bîyene",
    "y": " ılıklaşmak"
  },
  {
    "x": "şîrgerm kerdene",
    "y": " ılıklaştırmak"
  },
  {
    "x": "şîrgermbîyayîş (n)",
    "y": " ılıklaşma"
  },
  {
    "x": "şîrgermkerdiş (n)",
    "y": " ılıklaştırma"
  },
  {
    "x": "şîrig, -e",
    "y": " b. şirîk, -e"
  },
  {
    "x": "şîrig bîyene",
    "y": " b. şirîk bîyene"
  },
  {
    "x": "şîrigatîye (m)",
    "y": " b. şirîkatîye (m)"
  },
  {
    "x": "şîrigîye (m)",
    "y": " b. şirîkîye (m)"
  },
  {
    "x": "şîrij (n)",
    "y": " b. şîrêz (n)"
  },
  {
    "x": "şîrik-I (n)",
    "y": " kenger"
  },
  {
    "x": "şîrik-II (n)",
    "y": " b. rîşik (n)"
  },
  {
    "x": "şîrik, -e-III",
    "y": " b. şirîk, -e"
  },
  {
    "x": "şîrik bîyayene",
    "y": " b. şirîk bîyene"
  },
  {
    "x": "şîrik, -e-IV",
    "y": " b. şîrgerm, -e"
  },
  {
    "x": "şîrikayî (m)",
    "y": " b. şirîkayî (m)"
  },
  {
    "x": "şîrin, -e (s)",
    "y": " şirin, tatlı"
  },
  {
    "x": "şîrine (n)",
    "y": " b. şîrane (n)"
  },
  {
    "x": "şîrine kerdene",
    "y": " b. şîrane kerdene"
  },
  {
    "x": "şîriney (m)",
    "y": " b. şîrinî (m), şîrinîye (m)"
  },
  {
    "x": "şîrinê (m)",
    "y": " b. şîrinî (m), şîrinîye (m)"
  },
  {
    "x": "şîrinênî (m)",
    "y": " şirinlik, tatlılık"
  },
  {
    "x": "şîrinî (m)",
    "y": " şirinlik, tatlılık"
  },
  {
    "x": "şîrinîye (m)",
    "y": "  tatlılık, şirinlik"
  },
  {
    "x": "şîrî (s)",
    "y": " b. şîrin, -e (s)"
  },
  {
    "x": "şîrîn-I (n)",
    "y": " kişneme"
  },
  {
    "x": "şîrîn-II (s)",
    "y": " b. şîrin, -e (s)"
  },
  {
    "x": "şîriz (n)",
    "y": " b. şîrêz (n)"
  },
  {
    "x": "şîrîn, -e (s)",
    "y": " b. şîrin, -e (s)"
  },
  {
    "x": "şîrîney (m)",
    "y": " b. şîrinî (m), şîrinîye (m)"
  },
  {
    "x": "şîrînê (m)",
    "y": " b. şîrinî (m), şîrinîye (m)"
  },
  {
    "x": "şîrînîye (m)",
    "y": " b. şîrinî (m), şîrinîye (m)"
  },
  {
    "x": "şîrket (m)",
    "y": " b. şîrkete (m)"
  },
  {
    "x": "şîrkete (m)",
    "y": " şirket, ortaklık"
  },
  {
    "x": "şîrketa anonîme (m)",
    "y": " anonim şirket"
  },
  {
    "x": "şîrketa kolektîfe (m)",
    "y": " kolektif şirket"
  },
  {
    "x": "şîrketa sadeye (m)",
    "y": " adi şirket"
  },
  {
    "x": "şîrketi (m)",
    "y": " b. şîrkete (m)"
  },
  {
    "x": "şîrne (n)",
    "y": " b. şîrane (n)"
  },
  {
    "x": "şîrne kerdene",
    "y": " b. şîrane kerdene"
  },
  {
    "x": "şîrove (n)",
    "y": " b. şirove (n)"
  },
  {
    "x": "şîrove kerdene",
    "y": " b. şirove kerdene"
  },
  {
    "x": "şîroz (n)",
    "y": " b. sîroz (n)"
  },
  {
    "x": "şîrozin, -e",
    "y": " b. sîrozin, –e "
  },
  {
    "x": "şîrpence (n)",
    "y": " şirpençe, kızılyara"
  },
  {
    "x": "şîrrayeni",
    "y": " b. şîrayene"
  },
  {
    "x": "şîrrayîş (n)",
    "y": " b. şîrayîş (n)"
  },
  {
    "x": "şîrrîn (n)",
    "y": " b. şîrîn (n)"
  },
  {
    "x": "şîrş (m)",
    "y": " b. sirsî (m)"
  },
  {
    "x": "şîrteqer, -e",
    "y": " 1)babayiğit\r\n~2)çalışkan"
  },
  {
    "x": "şîrteqerîye (m)",
    "y": " babayiğitlik"
  },
  {
    "x": "şîrtîqer, -e",
    "y": " b. şîrteqer, -e"
  },
  {
    "x": "şîs, -e",
    "y": " b. sîs, -e"
  },
  {
    "x": "şîsik, -e",
    "y": " b. sîsik, -e"
  },
  {
    "x": "şîş (m)",
    "y": " b. şîşe-I (m)"
  },
  {
    "x": "şîşe-I (m)",
    "y": " şiş"
  },
  {
    "x": "şîşa camêne (m)",
    "y": " cam şiş"
  },
  {
    "x": "şîşe-II (m)",
    "y": " b. şuşe (n)"
  },
  {
    "x": "şîşi (m)",
    "y": " b. şîşe-I (m)"
  },
  {
    "x": "şîşman, -e",
    "y": " şişman"
  },
  {
    "x": "şîşman bîyene",
    "y": " şişmanlamak"
  },
  {
    "x": "şîşman kerdene",
    "y": " şişmanlatmak"
  },
  {
    "x": "şît, -e",
    "y": " atik, çevik"
  },
  {
    "x": "şîtey (m)",
    "y": " b. şîtî (m)"
  },
  {
    "x": "şîtê (m)",
    "y": " b. şîtî (m)"
  },
  {
    "x": "şîtî (m)",
    "y": " atiklik, çeviklik"
  },
  {
    "x": "şîtîye (m)",
    "y": " atiklik, çeviklik"
  },
  {
    "x": "şîvig (n)",
    "y": " b. siving (n)"
  },
  {
    "x": "şîvîg (n)",
    "y": " b. siving (n)"
  },
  {
    "x": "şîvîng (n)",
    "y": " b. siving (n)"
  },
  {
    "x": "şîx, -e",
    "y": " b. şêx, -e"
  },
  {
    "x": "şîxîye (m)",
    "y": " b. şêxîye (m)"
  },
  {
    "x": "şîy (m)",
    "y": " b. sîye (m)"
  },
  {
    "x": "şîya, -ye-I",
    "y": " b. sîya, -ye"
  },
  {
    "x": "şîya-II (m)",
    "y": " b. şîa (m)"
  },
  {
    "x": "şîyaene",
    "y": " b. şîyene"
  },
  {
    "x": "şîyane",
    "y": " b. şîyene"
  },
  {
    "x": "şîyaset (n)",
    "y": " b. sîyaset (n)"
  },
  {
    "x": "şîyasî, -ye",
    "y": " b. sîyasî, -ye"
  },
  {
    "x": "şîyaye (n)",
    "y": " 1)gider, masraf\r\n~2)sonlu"
  },
  {
    "x": "şîyayeyê hewênayîşî (zh)",
    "y": " ağırlama giderleri"
  },
  {
    "x": "şîyayeyê rojêk",
    "y": " bir günlük gider"
  },
  {
    "x": "şîyayeyê serrawextî",
    "y": " yıllık gider"
  },
  {
    "x": "şîyayeyê serrewextî",
    "y": " b. şîyayeyê serrawextî"
  },
  {
    "x": "şîyayeyê yew roje",
    "y": " bir günlük gider"
  },
  {
    "x": "şîyayeyo rojane",
    "y": " günlük gider"
  },
  {
    "x": "şîyayeyo serrane",
    "y": " yıllık gider"
  },
  {
    "x": "şîyayene-I",
    "y": " b. şîyene"
  },
  {
    "x": "şîyayene awa",
    "y": " b. şîyene awan"
  },
  {
    "x": "şîyayene awo",
    "y": " b. şîyene awan"
  },
  {
    "x": "şîyayene awon",
    "y": " b. şîyene awan"
  },
  {
    "x": "şîyayene awû",
    "y": " b. şîyene awan"
  },
  {
    "x": "şîyayene awûn",
    "y": " b. şîyene awan"
  },
  {
    "x": "şîyayene maseran ser",
    "y": " b. şîyene mahseran ser"
  },
  {
    "x": "şîyayene ra dorî",
    "y": " b. şîyene awan"
  },
  {
    "x": "şîyayene rehmet",
    "y": " b. şîyene rehmet"
  },
  {
    "x": "şîyayene û amayene",
    "y": " b. şîyene û ameyene"
  },
  {
    "x": "şîyayene-II",
    "y": " b. şayene-II"
  },
  {
    "x": "şîyayeyey (m)",
    "y": " b. şîyayîyî (m)"
  },
  {
    "x": "şîyayeyê (m)",
    "y": " b. şîyayîyî (m)"
  },
  {
    "x": "şîyayeyî (m)",
    "y": " fânilik, sonluluk"
  },
  {
    "x": "şîyayêne",
    "y": " b. şîyene"
  },
  {
    "x": "şîyayî, -ye",
    "y": " sonlu"
  },
  {
    "x": "şîyayîne",
    "y": " b. şîyene"
  },
  {
    "x": "şîyayîne rehmet",
    "y": " b. şîyene rehmet"
  },
  {
    "x": "şîyayîs (n)",
    "y": " b. şîyayîş-I (n)"
  },
  {
    "x": "şîyayîş-I (n)",
    "y": " 1)gitme\r\n~2)gidiş\r\n~3)akma (akarsu vb. için)"
  },
  {
    "x": "... şîyayîş (n)",
    "y": " benzeme, çağrıştırma (andırma)"
  },
  {
    "x": "şîyayîş-II (n)",
    "y": " b. şayîş-I (n)"
  },
  {
    "x": "şîyayîşrehmet (n)",
    "y": " vefat etme"
  },
  {
    "x": "şîyayîşûameyîş (n)",
    "y": " dolaşma "
  },
  {
    "x": "şîyayîyî (m)",
    "y": " fânilik, sonluluk"
  },
  {
    "x": "şîye (m)",
    "y": " b. sîye (m)"
  },
  {
    "x": "şîyene",
    "y": " 1)gitmek\r\n~Ti şore!/Ti şo! (Sen git!) \r\n~2)akmak (akarsu vb. için)"
  },
  {
    "x": "şîyaye (n)",
    "y": " 1)fâni\r\n~2)gidici, ölmek üzere olan"
  },
  {
    "x": "şîyayî, -ye",
    "y": " 1)fâni\r\n~2)gidici, ölmek üzere olan"
  },
  {
    "x": "şîyene awan",
    "y": " batmak (güneş, ay vb. için)\r\n~Roj şi awan. (Güneş battı.)"
  },
  {
    "x": "şîyene kergeyan ser",
    "y": " bağbozumuna başlama"
  },
  {
    "x": "şîyene mahseran ser",
    "y": " bağbozumuna başlama"
  },
  {
    "x": "şîyene ra dorî",
    "y": " b. şîyene awan\r\n~\tRoz sono ra dorî. (Güneş batıyor.)"
  },
  {
    "x": "şîyene rehmet",
    "y": " vefat etmek"
  },
  {
    "x": "şîyene rezan ser",
    "y": " bağbozumuna başlama"
  },
  {
    "x": "şîyene û ameyene",
    "y": " dolaşmak"
  },
  {
    "x": "... şîyene",
    "y": " benzemek, çağrıştırmak (andırmak)"
  },
  {
    "x": "şîyer (n)",
    "y": " b. şar-I (n)"
  },
  {
    "x": "şîyerbonî (m)",
    "y": " b. şîrbonî (m)"
  },
  {
    "x": "şîyes",
    "y": " b. şîyês"
  },
  {
    "x": "şîyex (n)",
    "y": " b. şêx, -e"
  },
  {
    "x": "şîyexê (m)",
    "y": " b. şêxîye (m)"
  },
  {
    "x": "şîyeyîş (n)",
    "y": " b. şîyayîş (n)"
  },
  {
    "x": "şîyês",
    "y": " on altı "
  },
  {
    "x": "şîyis",
    "y": " b. şîyês"
  },
  {
    "x": "şîzikê vaşî (n)",
    "y": " bitkilerin salgıladığı kaygan bir madde, salgı (bitki salgısı)"
  },
  {
    "x": "şîzofren, -e",
    "y": " şizofren"
  },
  {
    "x": "şîzofrenî (m)",
    "y": " şizofreni"
  },
  {
    "x": "şnorkel (n)",
    "y": " şnorkel"
  },
  {
    "x": "şo-I (m)",
    "y": " b. şewe (m)"
  },
  {
    "x": "Şoyê tu xêri b’.",
    "y": " b. Şewa to xeyr bo."
  },
  {
    "x": "şo-II (n)",
    "y": " b. şan (n)"
  },
  {
    "x": "şodir (n)",
    "y": " b. şewdir (n)"
  },
  {
    "x": "serê şodirî (n)",
    "y": " b. serê şewdirî"
  },
  {
    "x": "şodir a",
    "y": " b. şewdir ra"
  },
  {
    "x": "Şodir bixeyr.",
    "y": " b. Şewdir bixeyr."
  },
  {
    "x": "şodir ra",
    "y": " b. şewdir ra"
  },
  {
    "x": "şofben (n)",
    "y": " şofben"
  },
  {
    "x": "şofer (n)",
    "y": " b. şofor (n)"
  },
  {
    "x": "şofêr, -e",
    "y": " b. şofor, -e"
  },
  {
    "x": "şofor, -e",
    "y": " şoför"
  },
  {
    "x": "şofora alkolşimitoxe (m)",
    "y": " alkollü şoför"
  },
  {
    "x": "şoforo alkolşimitox (n)",
    "y": " alkollü şoför"
  },
  {
    "x": "şofora ambulanse (m)",
    "y": " ambülans şoförü"
  },
  {
    "x": "şoforê ambulanse (n)",
    "y": " ambülans şoförü"
  },
  {
    "x": "şofur (n)",
    "y": " b. şofor (n)"
  },
  {
    "x": "şok (n)",
    "y": " şok"
  },
  {
    "x": "şoke bîyene",
    "y": " şoke olmak"
  },
  {
    "x": "şoke dayene",
    "y": " şok vermek"
  },
  {
    "x": "şoke kerdene",
    "y": " şoke etmek"
  },
  {
    "x": "şoknayîş (n)",
    "y": " şoklama"
  },
  {
    "x": "şokola (m)",
    "y": " şokola"
  },
  {
    "x": "şolik (n)",
    "y": " b. şilikî (m)"
  },
  {
    "x": "şolik (m)",
    "y": " b. şilikî (m)"
  },
  {
    "x": "şom (m)",
    "y": " b. şamî (m)"
  },
  {
    "x": "şomek (m)",
    "y": " b. şanike-I (m)"
  },
  {
    "x": "şomi (m)",
    "y": " b. şamî (m)"
  },
  {
    "x": "şomî (m)",
    "y": " b. şamî (m)"
  },
  {
    "x": "şomîna (m)",
    "y": " b. şomîne (n)"
  },
  {
    "x": "şomîne (n)",
    "y": " şömine"
  },
  {
    "x": "şomşîn (n)",
    "y": " b. şaneşîye (m)"
  },
  {
    "x": "şomyek (m)",
    "y": " b. şanike-I (m)"
  },
  {
    "x": "şon (n)",
    "y": " b. şan (n)"
  },
  {
    "x": "şon di",
    "y": " b. şan de"
  },
  {
    "x": "şonayene",
    "y": " b. şanayene"
  },
  {
    "x": "şonayeni",
    "y": " b. şanayene"
  },
  {
    "x": "şonayiş (n)",
    "y": " b. şanayîş (n)"
  },
  {
    "x": "şonayîne",
    "y": " b. ci eştene"
  },
  {
    "x": "şonayîş (n)",
    "y": " b. şanayîş (n)"
  },
  {
    "x": "şond (n)",
    "y": " b. şan (n)"
  },
  {
    "x": "şone-I (n)",
    "y": " b. şane (n)"
  },
  {
    "x": "şone-II (n)",
    "y": " b. şiwane (n)"
  },
  {
    "x": "şoneyey (m)",
    "y": " b. şiwaneyî (m)"
  },
  {
    "x": "şonik (n)",
    "y": " tokaç"
  },
  {
    "x": "şonike (m)",
    "y": " b. sanike-I (m)"
  },
  {
    "x": "şonkîl (n)",
    "y": " b. şakil (n)"
  },
  {
    "x": "şonpole (m)",
    "y": " püskül"
  },
  {
    "x": "şonpolin, -e",
    "y": " püsküllü"
  },
  {
    "x": "şontîya (m)",
    "y": " b. şantîye (m)"
  },
  {
    "x": "şop (m)",
    "y": " b. şope (m)"
  },
  {
    "x": "şope-I (m)",
    "y": " belirti"
  },
  {
    "x": "şope-II (m)",
    "y": " kez, kere, defa, sefer"
  },
  {
    "x": "şopê",
    "y": " bir kez\r\n~Şopê goş ra ey ser ne. (Onu bir kez dinle.)"
  },
  {
    "x": "şopêk",
    "y": " bir kez"
  },
  {
    "x": "şopi (m)",
    "y": " b. şope (m)"
  },
  {
    "x": "şopna",
    "y": " yine, gene, tekrar"
  },
  {
    "x": "şoq (n)",
    "y": " b. şewq (n)"
  },
  {
    "x": "şoq dayene",
    "y": " b. şewq dayene"
  },
  {
    "x": "şoqdayîş (n)",
    "y": " b. şewqdayîş (n)"
  },
  {
    "x": "şoqe (n)",
    "y": " b. şewqa (m)"
  },
  {
    "x": "şoqi (n)",
    "y": " b. şewqa (m)"
  },
  {
    "x": "şoqî (n)",
    "y": " b. şewqa (m)"
  },
  {
    "x": "şor (m)",
    "y": " b. şore (m)"
  },
  {
    "x": "şorax (n)",
    "y": " çorak"
  },
  {
    "x": "şorba (m)",
    "y": " çorba"
  },
  {
    "x": "şorba doy (m)",
    "y": " b. şorbaya doyî (m)"
  },
  {
    "x": "şorba vêrî (m)",
    "y": " b. şorbaya vêreyî (m)"
  },
  {
    "x": "şorbay doy (m)",
    "y": " b. şorbaya doyî (m)"
  },
  {
    "x": "şorbaya doyî (m)",
    "y": " ayran çorbası"
  },
  {
    "x": "şorbaya tutmacî (m)",
    "y": " tutmaç"
  },
  {
    "x": "şorbaya vêreyî (m)",
    "y": " işkembe çorbası"
  },
  {
    "x": "şorbê doyî (m)",
    "y": " b. şorbaya doyî (m)"
  },
  {
    "x": "şorbe (m)",
    "y": " b. şorba (m)"
  },
  {
    "x": "şorbeya tutmacî (m)",
    "y": " b. şorbaya tutmacî (m)"
  },
  {
    "x": "şorbedoye (m)",
    "y": " ayran çorbası"
  },
  {
    "x": "şorbike (m)",
    "y": " 1)çorba\r\n~2)düğün yemeği (topluca yenir)"
  },
  {
    "x": "şore (m)",
    "y": " orospu, fahişe, kahpe, sürtük"
  },
  {
    "x": "şoreş (n)",
    "y": " b. şoriş (n)"
  },
  {
    "x": "şoreşger, -e",
    "y": " b. şorişgêr, -e"
  },
  {
    "x": "şorey (m)",
    "y": " b. şorî (m), şorîye (m)"
  },
  {
    "x": "şorigi (m)",
    "y": " b. şorike (m)"
  },
  {
    "x": "şorigin, -e",
    "y": " b. şorikin, -e"
  },
  {
    "x": "şorik (m)",
    "y": " b. şorike (m)"
  },
  {
    "x": "şorike (m)",
    "y": " salya"
  },
  {
    "x": "şorike fek ra şîyene",
    "y": "  ağzından salya akmak"
  },
  {
    "x": "şoriki (m)",
    "y": " b. şorike (m)"
  },
  {
    "x": "şorikin, -e",
    "y": " salyalı"
  },
  {
    "x": "şoriş (n)",
    "y": " devrim"
  },
  {
    "x": "şorişgêr, -e",
    "y": " devrimci"
  },
  {
    "x": "şorişgêrî (m)",
    "y": " devrimcilik"
  },
  {
    "x": "şorişgêrîye (m)",
    "y": " devrimcilik"
  },
  {
    "x": "şorî (m)",
    "y": " orospuluk, fahişelik, kahpelik, sürtüklük"
  },
  {
    "x": "şorîye (m)",
    "y": " orospuluk, fahişelik, kahpelik, sürtüklük"
  },
  {
    "x": "şorr, -e",
    "y": " tuzlu"
  },
  {
    "x": "şorre (m)",
    "y": " b. şore (m)"
  },
  {
    "x": "şorrey-I (m)",
    "y": " b. şorî (m)"
  },
  {
    "x": "şorrey-II (m)",
    "y": " b. şorrî (m)"
  },
  {
    "x": "şorrê (m)",
    "y": " b. şorî (m)"
  },
  {
    "x": "şorrênî (m)",
    "y": " tuzluluk"
  },
  {
    "x": "şorri (m)",
    "y": " b. şore (m)"
  },
  {
    "x": "şorrin, -e",
    "y": " aşırı tuzlu"
  },
  {
    "x": "şorrinênî (m)",
    "y": " aşırı tuzluluk"
  },
  {
    "x": "şorrinî (m)",
    "y": " aşırı tuzluluk"
  },
  {
    "x": "şorrinîye (m)",
    "y": " aşırı tuzluluk"
  },
  {
    "x": "şorrî (m)",
    "y": " tuzluluk"
  },
  {
    "x": "şorrîye (m)",
    "y": " tuzluluk"
  },
  {
    "x": "şortî (zh)",
    "y": " şort"
  },
  {
    "x": "şorva (m)",
    "y": " b. şorba (m)"
  },
  {
    "x": "şorve (n)",
    "y": " b. şorba (m)"
  },
  {
    "x": "şorvike (m)",
    "y": " b. şorbike (m)"
  },
  {
    "x": "şoşman, -e",
    "y": " b. şîşman, -e"
  },
  {
    "x": "şoşman bîyayene",
    "y": " b. şîşman bîyene"
  },
  {
    "x": "şoşman kerdene",
    "y": " b. şîşman kerdene"
  },
  {
    "x": "şoti (m)",
    "y": " b. şewte (m)"
  },
  {
    "x": "şov (m)",
    "y": " b. şûve (m)"
  },
  {
    "x": "şove (m)",
    "y": " b. şûve (m)"
  },
  {
    "x": "şove kerdene",
    "y": " b. şûve kerdene"
  },
  {
    "x": "şove verdayîne",
    "y": " b. şûve verdayene"
  },
  {
    "x": "şow (m)",
    "y": " b. şewe (m)"
  },
  {
    "x": "şowane (n)",
    "y": " b. şiwane (n)"
  },
  {
    "x": "şowbnayene",
    "y": " b. şebeynayene (m)"
  },
  {
    "x": "şowbnayîş (n)",
    "y": " b. şebeynayîş (n)"
  },
  {
    "x": "şowq (n)",
    "y": " b. şewq (n)"
  },
  {
    "x": "şowq dayene",
    "y": " b. şewq dayene"
  },
  {
    "x": "şowq û şemal",
    "y": " b. şewq û şemale"
  },
  {
    "x": "şowqa (m)",
    "y": " b. şewqa (m)"
  },
  {
    "x": "şowqdayîş (n)",
    "y": " b. şewqdayîş (n)"
  },
  {
    "x": "şowqe (n)",
    "y": " b. şewqa (m)"
  },
  {
    "x": "şowqî (m)",
    "y": " b. şewqa (m)"
  },
  {
    "x": "şowş (m)",
    "y": " b. şewşî (m)"
  },
  {
    "x": "şowş bîyene",
    "y": " b. şewşî bîyene"
  },
  {
    "x": "şowşi (m)",
    "y": " b. şewşî (m)"
  },
  {
    "x": "şowşî (m)",
    "y": " b. şewşî (m)"
  },
  {
    "x": "şowşî bîyene",
    "y": " b. şewşî bîyene"
  },
  {
    "x": "şowti (m)",
    "y": " b. şewte (m)"
  },
  {
    "x": "ştirî (n)",
    "y": " b. estrî (n)"
  },
  {
    "x": "ştirîyin",
    "y": " b. estrîyin, -e"
  },
  {
    "x": "şoxilnayene",
    "y": " b. şuxulnayene"
  },
  {
    "x": "şoxilnayîş (n)",
    "y": " b. şuxulnayîş (n)"
  },
  {
    "x": "şuane (n)",
    "y": " b. şiwane (n)"
  },
  {
    "x": "şuaneyîye (m)",
    "y": " b. şiwaneyî (m)"
  },
  {
    "x": "suar, -e",
    "y": " b. suwarî, -e"
  },
  {
    "x": "şuarba (m)",
    "y": " b. şorba (m)"
  },
  {
    "x": "şuarbey duey (m)",
    "y": " b. şorbaya doyî (m)"
  },
  {
    "x": "şuare (m)",
    "y": " b. şuware (m)"
  },
  {
    "x": "şuarî, -ye",
    "y": " b. suwarî, -ye"
  },
  {
    "x": "şuarr",
    "y": " b. şorr, -e"
  },
  {
    "x": "şuax (n)",
    "y": " b. siwax (n)"
  },
  {
    "x": "şue (n)",
    "y": " b. şûy (n)"
  },
  {
    "x": "şuep (m)",
    "y": " b. şope (m)"
  },
  {
    "x": "şuepna",
    "y": " b. şopna"
  },
  {
    "x": "şufer (n)",
    "y": " b. şofor (n)"
  },
  {
    "x": "şufir (n)",
    "y": " b. şofor (n)"
  },
  {
    "x": "şufur (n)",
    "y": " b. şofor (n)"
  },
  {
    "x": "şuj (n)",
    "y": " b. suc (n)"
  },
  {
    "x": "şulikî (m)",
    "y": " b. şilikî (m)"
  },
  {
    "x": "şulimpe (n)",
    "y": " b. şilope (n)"
  },
  {
    "x": "şulint (n)",
    "y": " ağaç sakızı (çitlembik/melengiç ağacının salgısı, reçine  "
  },
  {
    "x": "şullikî (m)",
    "y": " b. şilikî (m)"
  },
  {
    "x": "şullimpe (n)",
    "y": " b. şilope (n)"
  },
  {
    "x": "şullint (n)",
    "y": " b. şulint (n)"
  },
  {
    "x": "şumûl (n)",
    "y": " kapsam, kapsama"
  },
  {
    "x": "şumûldar, -e",
    "y": " kapsamlı"
  },
  {
    "x": "şundor (n)",
    "y": " b. sînor (n)"
  },
  {
    "x": "şurib (n)",
    "y": " b. şurub (n)"
  },
  {
    "x": "şurt (m)",
    "y": " şelbûte (m)"
  },
  {
    "x": "şurub (n)",
    "y": " şurup"
  },
  {
    "x": "şuşa (m)",
    "y": " b. şuşe (n)"
  },
  {
    "x": "şuşe (n)",
    "y": " 1)şişe\r\n~2)bardak"
  },
  {
    "x": "şuşey çay (n)",
    "y": " b. şuşeyê çay (n)"
  },
  {
    "x": "şuşeyê awe (n)",
    "y": " su şişesi, su bardağı"
  },
  {
    "x": "şuşeyê çay (n)",
    "y": " çay bardağı"
  },
  {
    "x": "şuşê çay (n)",
    "y": " b. şuşeyê çay (n)"
  },
  {
    "x": "şuşi (n)",
    "y": " b. şuşe (n)"
  },
  {
    "x": "şuşmezin, -e",
    "y": " kibirli, büyüklenen"
  },
  {
    "x": "şuşmezin bîyene",
    "y": " kibirlenmek"
  },
  {
    "x": "şuşmezinî (m)",
    "y": " kibirlilik"
  },
  {
    "x": "şuşmezinîye (m)",
    "y": " kibirlilik"
  },
  {
    "x": "şuşt bîyayene",
    "y": " b. şuşt bîyene"
  },
  {
    "x": "şuşt bîyene",
    "y": " kaymak"
  },
  {
    "x": "şuşt kerdene",
    "y": " kaydırmak"
  },
  {
    "x": "şuştbîyayîş (n)",
    "y": " kayma"
  },
  {
    "x": "şuştikîyayene",
    "y": " kaymak"
  },
  {
    "x": "şuştikîyayîş (n)",
    "y": " kayma"
  },
  {
    "x": "şuştiknayene",
    "y": " kaydırmak"
  },
  {
    "x": "şuştiknayîş (n)",
    "y": " kaydırma"
  },
  {
    "x": "şuştkerdiş (n)",
    "y": " kaydırma"
  },
  {
    "x": "şutene",
    "y": " yıkamak"
  },
  {
    "x": "şutike (m)",
    "y": " 1)başörtü, başörtüsü (hem kadın hem erkek için)\r\n~2)çelgi"
  },
  {
    "x": "şutiki (m)",
    "y": " 1)b. şare (m)\r\n~2)b. şutike (m)"
  },
  {
    "x": "şutiş (n)",
    "y": " yıkama"
  },
  {
    "x": "şutker, -e",
    "y": " yıkayıcı"
  },
  {
    "x": "şutox, -e",
    "y": " yıkayıcı"
  },
  {
    "x": "şuve (m)",
    "y": " b. şûve (m)"
  },
  {
    "x": "şuve kerdene",
    "y": " b. şûve kerdene"
  },
  {
    "x": "şuve verdayîne",
    "y": " b. şûve verdayene"
  },
  {
    "x": "şuwal (m)",
    "y": " b. şalî-I (zh)"
  },
  {
    "x": "şuwalendî (m)",
    "y": " b. şilabend (n)"
  },
  {
    "x": "şuwalî (m)",
    "y": " b. şalî-I (zh)"
  },
  {
    "x": "şuwalendî (m)",
    "y": " b. şilabend (n)"
  },
  {
    "x": "şuwane (n)",
    "y": " b. şiwane (n)"
  },
  {
    "x": "şuwanetî (m)",
    "y": " b. şiwanetî (m)"
  },
  {
    "x": "şuwanetîye (m)",
    "y": " b. şiwanetî (m)"
  },
  {
    "x": "şuwaneyîye (m)",
    "y": " b. şiwaneyî (m)"
  },
  {
    "x": "şuwanên (m)",
    "y": " b. şiwanênî (m), şiwaneyî (m)"
  },
  {
    "x": "şuwanênî (m)",
    "y": " b. şiwanênî (m), şiwaneyî (m)"
  },
  {
    "x": "şuwanîne (m)",
    "y": " b. şiwanîye (m)"
  },
  {
    "x": "şuwanîye (m)",
    "y": " b. şiwanîye (m)"
  },
  {
    "x": "şuwar, -e",
    "y": " b. suwar, -e"
  },
  {
    "x": "şuware (m)",
    "y": " ağıt"
  },
  {
    "x": "şîn û şuware",
    "y": " yas ve ağıt"
  },
  {
    "x": "şuware dayene piro",
    "y": " ağıt yakmak, ağıt düzmek, ağıt tutturmak\r\n~Şuware daye piro. / Şuware vate. (Ağıt yaktı)"
  },
  {
    "x": "şuware vatene",
    "y": " ağıt yakmak, ağıt düzmek, ağıt tutturmak"
  },
  {
    "x": "şuwarî, -ye",
    "y": " b. suwarî, -ye"
  },
  {
    "x": "şuwarvaj, -e",
    "y": " ağıtçı, ağlayıcı, sagucu"
  },
  {
    "x": "şuwax (n)",
    "y": " b. siwax (n)"
  },
  {
    "x": "şuwiten",
    "y": " b. şutene"
  },
  {
    "x": "şuwîyayene",
    "y": " yıkanmak"
  },
  {
    "x": "şuwîyayîş (n)",
    "y": " yıkanma"
  },
  {
    "x": "şuwutene",
    "y": " b. şutene"
  },
  {
    "x": "şuxilîyayene",
    "y": " b. şuxulîyayene"
  },
  {
    "x": "şuxilîyayîş (n)",
    "y": " b. şuxulîyayîş (n)"
  },
  {
    "x": "şuxilnayene",
    "y": " b. şuxulnayene"
  },
  {
    "x": "şuxilnayîne",
    "y": " b. şuxulnayene"
  },
  {
    "x": "şuxilnayîş (n)",
    "y": " b. şuxulnayîş (n)"
  },
  {
    "x": "şuxulîyayene",
    "y": " çalışmak"
  },
  {
    "x": "şuxulîyayîş (n)",
    "y": " çalışma"
  },
  {
    "x": "şuxulnayene",
    "y": " 1)çalıştırmak\r\n~2)işletmek\r\n~3)kullanmak"
  },
  {
    "x": "şuxulnayîş (n)",
    "y": " 1)çalıştırma, çalıştırış\r\n~2)işletme\r\n~3)kullanma"
  },
  {
    "x": "şuxulnayox, -e",
    "y": " işleten"
  },
  {
    "x": "şuxulnîyayene",
    "y": " kullanılmak"
  },
  {
    "x": "şuxulnîyayîş (n)",
    "y": " kullanılma, kullanım"
  },
  {
    "x": "şuyalendî (m)",
    "y": " b. şilabend (n)"
  },
  {
    "x": "şuyane (n)",
    "y": " b. şiwane (n)"
  },
  {
    "x": "şuyaneyîye (m)",
    "y": " b. şiwaneyî (m)"
  },
  {
    "x": "şuyar, -e",
    "y": " b. suwar, -e"
  },
  {
    "x": "şuyarî, -ye",
    "y": " b. suwarî, -ye"
  },
  {
    "x": "şuye (n)",
    "y": " b. sîye (m)"
  },
  {
    "x": "şû-I (n)",
    "y": " b. şan (n)"
  },
  {
    "x": "şû-II (n)",
    "y": " b. merduşû (n)"
  },
  {
    "x": "şûbe-I (n)",
    "y": " şube"
  },
  {
    "x": "şûbeyê eskerîye (n)",
    "y": " askerlik şubesi"
  },
  {
    "x": "şûbeyê leşkerîye (n)",
    "y": " askerlik şubesi"
  },
  {
    "x": "şûbe-II (m)",
    "y": " b. şûve (m)"
  },
  {
    "x": "şûbe kerdene",
    "y": " b. şûve kerdene"
  },
  {
    "x": "şûbe verdayeni",
    "y": " b. şûve verdayene"
  },
  {
    "x": "şûm (m)",
    "y": " b. şamî (m)"
  },
  {
    "x": "şûmeki (m)",
    "y": " b. şanike-I (m)"
  },
  {
    "x": "şûmêl (m)",
    "y": " b. şimêle (m)"
  },
  {
    "x": "şûmî (m)",
    "y": " b. şamî (m)"
  },
  {
    "x": "şûmîla (m)",
    "y": " boyunduruk çubuklarını birbirine bağlayan ip"
  },
  {
    "x": "şûmşîn (n)",
    "y": " b. şûmşîne (m)"
  },
  {
    "x": "şûmşîne (m)",
    "y": " balkon, ayvan, eyvan"
  },
  {
    "x": "şûn (n)",
    "y": " b. şan (n) "
  },
  {
    "x": "şûn di",
    "y": " b. şan de "
  },
  {
    "x": "şûnayene",
    "y": " b. şanayene"
  },
  {
    "x": "şûnayîş (n)",
    "y": " 1)b. şanayîş (n)\r\n~2)b. weşanayîş (n)"
  },
  {
    "x": "şûnd (n)",
    "y": " b. şan (n) "
  },
  {
    "x": "Şûnd tu xêri b’.",
    "y": " b. Şanê to xeyr bo."
  },
  {
    "x": "şûne-I (n)",
    "y": " b. şane (n)"
  },
  {
    "x": "şûnê marûn (n)",
    "y": " b. şaneyê marî (n)"
  },
  {
    "x": "şûne-II (n)",
    "y": " b. şiwane (n)"
  },
  {
    "x": "şûneyê (m)",
    "y": " b. şiwaneyî (m)"
  },
  {
    "x": "şûneyî (m)",
    "y": " b. şiwaneyî (m)"
  },
  {
    "x": "şûni-I (n)",
    "y": " b. şane (n)"
  },
  {
    "x": "şûni-II (n)",
    "y": " b. şiwane (n)"
  },
  {
    "x": "şûnik (n)",
    "y": " b. şonik (n)"
  },
  {
    "x": "şûnîyayîne",
    "y": " b. şanîyayene"
  },
  {
    "x": "şûnîyayîş (n)",
    "y": " b. şanîyayîş (n)"
  },
  {
    "x": "şûntîya (m)",
    "y": " b. şantîye (m)"
  },
  {
    "x": "şûr (n)",
    "y": " kılıç"
  },
  {
    "x": "şûrik (n)",
    "y": " sabanın çift sürerken elle tutulan kısmından toprağa kadar uzanan parçası"
  },
  {
    "x": "şûşa (m)",
    "y": " b. şuşe (n)"
  },
  {
    "x": "şûşe (n)",
    "y": " b. şuşe (n)"
  },
  {
    "x": "şûv (m)",
    "y": " b. şûve (m)"
  },
  {
    "x": "şûv kerdene",
    "y": " b. şûve kerdene"
  },
  {
    "x": "şûv verdayene",
    "y": " b. şûve verdayene"
  },
  {
    "x": "şûve (m)",
    "y": " herk, nadas"
  },
  {
    "x": "şûve kerdene",
    "y": " herk etmek, nadas etmek"
  },
  {
    "x": "şûve verdayene",
    "y": " nadasa bırakmak, nadasa yatırmak"
  },
  {
    "x": "şûy (n)",
    "y": " yıkanma ve çamaşır yıkama zamanı, yunak"
  },
  {
    "x": "şware (m)",
    "y": " b. şuware (m)"
  },
  {
    "x": "TTT"
  },
  {
    "x": "ta-I (n)",
    "y": " sıtma, malarya"
  },
  {
    "x": "ta girewtene",
    "y": " sıtmaya yakalanmak, sıtma tutmak \r\n~\tEy ta girewto. (O sıtmaya yakalanmış.)"
  },
  {
    "x": "ta girotene",
    "y": " b. ta girewtene"
  },
  {
    "x": "ta gurotene",
    "y": " b. ta girewtene"
  },
  {
    "x": "ta-II",
    "y": " b. tîya"
  },
  {
    "x": "ta-III (m)",
    "y": " b. teye (m)"
  },
  {
    "x": "ta-IV (n)",
    "y": " sicim"
  },
  {
    "x": "ta re ci verdîyayene",
    "y": " burkulmak (el, ayak vb. için)"
  },
  {
    "x": "ta re ... verdîyayene",
    "y": " burkulmak (el, ayak vb. için)\r\n~Ta verdîno re linga to. (Senin ayağın burkulur.)\r\n~\t\r\n~Ta verdîya re linga aye. (Onun ayağı burkuldu.)"
  },
  {
    "x": "ta ro ci verdîyayene",
    "y": " b. ta re ci verdîyayene"
  },
  {
    "x": "ta ro ci vêrdîyayêne",
    "y": " b. ta re ci verdîyayene"
  },
  {
    "x": "ta ro ... verdîyayene",
    "y": " b. ta re ... verdîyayene"
  },
  {
    "x": "ta ro ... vêrdîyayêne",
    "y": " b. ta re ... verdîyayene"
  },
  {
    "x": "ta-tay",
    "y": " b. tayê-tayê"
  },
  {
    "x": "ta-tayê",
    "y": " b. tayê-tayê"
  },
  {
    "x": "taba (n)",
    "y": " b. teba (n)"
  },
  {
    "x": "tabayê",
    "y": " b. tebayê"
  },
  {
    "x": "taban (n)",
    "y": " 1)taban (bina, ayakkabı vb. tabanı)\r\n~2)taban (mat)"
  },
  {
    "x": "tabaqa (m)",
    "y": " b. tebeqa (m)"
  },
  {
    "x": "tabat (n)",
    "y": " b. tebat (n)"
  },
  {
    "x": "tabaxe (m)",
    "y": " b. tebaxe-II (m)"
  },
  {
    "x": "tabaxi (m)",
    "y": " b. tebaxe-I (m)"
  },
  {
    "x": "tabela (m)",
    "y": " tabela"
  },
  {
    "x": "tabelaya cîhetî (m)",
    "y": " yön tabelası"
  },
  {
    "x": "tabelaya hetî (m)",
    "y": " yön tabelası"
  },
  {
    "x": "tabelaya kuçeyî (m)",
    "y": " sokak tabelası"
  },
  {
    "x": "tabelanus, -e",
    "y": " tabelacı"
  },
  {
    "x": "tabelanusî (m)",
    "y": " tabelacılık"
  },
  {
    "x": "tabelanusîye (m)",
    "y": " tabelacılık"
  },
  {
    "x": "tabele (n)",
    "y": " b. tabela (m)"
  },
  {
    "x": "tabi bîyayene",
    "y": " b. taw bîyene"
  },
  {
    "x": "tabî-I (n)",
    "y": " düzey, seviye\r\n~Banê înan tabîyê banê ma ra yo. (Onların evi bizim evin düzeyindedir.)"
  },
  {
    "x": "tabî-II",
    "y": " elbet, elbette"
  },
  {
    "x": "tabîat (n)",
    "y": " b. tebîet (n)"
  },
  {
    "x": "tabîî, -ye",
    "y": " b. tebîî, -ye"
  },
  {
    "x": "tabîr-I (n)",
    "y": " 1)yorum (rüya için)\r\n~2)b. tebîr (n)"
  },
  {
    "x": "tabîr kerdene",
    "y": " yorum yapmak (rüya için), yorumlamak (rüya için)"
  },
  {
    "x": "tabîrker, -e",
    "y": " yorumcu (rüya için)"
  },
  {
    "x": "tabîrkerênî (m)",
    "y": " yorumculuk (rüya için)"
  },
  {
    "x": "tabîrkerî (m)",
    "y": " yorumculuk (rüya için)"
  },
  {
    "x": "tabîrkerîye (m)",
    "y": " yorumculuk (rüya için)"
  },
  {
    "x": "tablo (n)",
    "y": " tablo, çizelge\r\n~tabloyê reykîye (n): çarpım tablosu (mat)"
  },
  {
    "x": "tabloyê reykîye (n)",
    "y": " çarpım tablosu (mat)"
  },
  {
    "x": "tabloîd (n)",
    "y": " tabloid"
  },
  {
    "x": "tabura (m)",
    "y": " tabure"
  },
  {
    "x": "tabure (m)",
    "y": " b. tabura (m)"
  },
  {
    "x": "tabut-I (n)",
    "y": " tabut"
  },
  {
    "x": "tabut-II (n)",
    "y": " anüs çıkıntısı"
  },
  {
    "x": "tabute (m)",
    "y": " b. tabut-I (n)"
  },
  {
    "x": "tabûre (m)",
    "y": " b. tabura (m)"
  },
  {
    "x": "tac (n)",
    "y": " b. tace (m)"
  },
  {
    "x": "tace (m)",
    "y": " taç"
  },
  {
    "x": "taci (m)",
    "y": " b. tace (m)"
  },
  {
    "x": "Tacikîstan (n)",
    "y": " Tacikistan "
  },
  {
    "x": "tacir, -e",
    "y": " b. têcîr, -e"
  },
  {
    "x": "taçanayene",
    "y": " b. taçarnayene"
  },
  {
    "x": "taçanayîş (n)",
    "y": " b. taçarnayîş (n)"
  },
  {
    "x": "taçarnaya (m)",
    "y": " çalkalanmış olan"
  },
  {
    "x": "taçarnaye (n)",
    "y": " çalkalanmış olan"
  },
  {
    "x": "taçarnayene",
    "y": " çalkalamak"
  },
  {
    "x": "taçarnayîş (n)",
    "y": " çalkalama"
  },
  {
    "x": "taçarnayîye (m)",
    "y": " çalkalanmış olan"
  },
  {
    "x": "tada (m)",
    "y": " b. teda (m)"
  },
  {
    "x": "tadaya (m)",
    "y": " 1)çeviri, tercüme\r\n~2)buruk (burulmuş olan)"
  },
  {
    "x": "tadaye (n)",
    "y": " 1)çeviri, tercüme\r\n~2)buruk (burulmuş olan)\r\n~3)b. tadîyaye (n)"
  },
  {
    "x": "tadayene",
    "y": " 1)burmak, bükmek\r\n~2)çevirmek, döndürmek\r\n~3)çevirmek, tercüme etmek"
  },
  {
    "x": "tadayex",
    "y": " b. tadayox, -e"
  },
  {
    "x": "tadayî, -ye",
    "y": " buruk (burulmuş olan)"
  },
  {
    "x": "tadayîne",
    "y": " b. tadayene"
  },
  {
    "x": "tadayîş (n)",
    "y": " 1)burma, bükme\r\n~2)çevirme, döndürme\r\n~3)çevirme, tercüme etme"
  },
  {
    "x": "tadayîye (m)",
    "y": " çeviri, tercüme"
  },
  {
    "x": "tadayox, -e",
    "y": " çeviren, çevirmen, tercüme eden, mütercim, tercüman \r\n~tadayoxa sondwerda (m): yeminli tercüman\r\n~tadayoxa sondwerdîye (m): yeminli tercüman\r\n~tadayoxo sondwerde (n): yeminli tercüman"
  },
  {
    "x": "tadayoxa sondwerda (m)",
    "y": " yeminli tercüman"
  },
  {
    "x": "tadayoxa sondwerdîye (m)",
    "y": " yeminli tercüman"
  },
  {
    "x": "tadayoxo sondwerde (n)",
    "y": " yeminli tercüman"
  },
  {
    "x": "tadeyene",
    "y": " b. tadîyayene"
  },
  {
    "x": "tadeyîne",
    "y": " b. tadîyayene"
  },
  {
    "x": "tadeyîş (n)",
    "y": " b. tadîyayîş (n)"
  },
  {
    "x": "tadîyaye (n)",
    "y": " kıvrım"
  },
  {
    "x": "tadîyayene",
    "y": " 1)dönmek\r\n~a tadîyaye: o döndü\r\n~2)burulmak \r\n~3)burkulmak (el, ayak vb. için)\r\n~Linga mi tadîyaye/tadîyê. (Ayağım burkuldu.)"
  },
  {
    "x": "tadîyayîş (n)",
    "y": " 1)dönme, dönüş\r\n~2)burkulma (el, ayak vb. için)"
  },
  {
    "x": "tadîyayîşê destî",
    "y": " el burkulması"
  },
  {
    "x": "tadîyayîşê linge",
    "y": " ayak burkulması"
  },
  {
    "x": "tadyayene",
    "y": " b. tadîyayene"
  },
  {
    "x": "tadyayîş (n)",
    "y": " b. tadîyayîş (n)"
  },
  {
    "x": "taê",
    "y": " b. tayê "
  },
  {
    "x": "taf de",
    "y": " aniden, ansızın, birdenbire; ani"
  },
  {
    "x": "taf de merdene",
    "y": " aniden ölmek"
  },
  {
    "x": "taf te",
    "y": " b. taf de "
  },
  {
    "x": "taf te merdene",
    "y": " b. taf de merdene"
  },
  {
    "x": "taf ta",
    "y": " b. taf de"
  },
  {
    "x": "taf te",
    "y": " b. taf de"
  },
  {
    "x": "taf tê",
    "y": " b. taf de"
  },
  {
    "x": "taf-duman",
    "y": " b. tefûduman (n)"
  },
  {
    "x": "taf-tal",
    "y": " b. tef-tal"
  },
  {
    "x": "tafilê",
    "y": " b. tavilê"
  },
  {
    "x": "tafir-dumûn",
    "y": " b. tefûduman (n)"
  },
  {
    "x": "tafîl kerdene",
    "y": " b. tehfîl kerdene"
  },
  {
    "x": "tafîlkerdiş (n)",
    "y": " b. tehfîlkerdiş (n)"
  },
  {
    "x": "tafînayene",
    "y": " 1)tutuşturmak\r\n~2)b. tavistene"
  },
  {
    "x": "tafînayîş (n)",
    "y": " 1)tutuşturma\r\n~2)b. tavistiş (n)"
  },
  {
    "x": "tafînîyayene",
    "y": " tutuşmak"
  },
  {
    "x": "tafînîyayîş (n)",
    "y": " tutuşma"
  },
  {
    "x": "tafîştene",
    "y": " süpürmek"
  },
  {
    "x": "tafîştiş (n)",
    "y": " süpürme"
  },
  {
    "x": "tafûduman (n)",
    "y": " b. tefûduman (n)"
  },
  {
    "x": "tafûdumon (n)",
    "y": " b. tefûduman (n)"
  },
  {
    "x": "tafûdumûn (n)",
    "y": " b. tefûduman (n)"
  },
  {
    "x": "tageyrayîne",
    "y": " b. tagêrayene"
  },
  {
    "x": "tageyrayîş (n)",
    "y": " b. tagêrayîş (n)"
  },
  {
    "x": "tagêrayene",
    "y": " 1)kontrol etmek, yoklama yapmak; yoklamak, aramak\r\n~2)taramak (araştırma vb. için)"
  },
  {
    "x": "tagêrayîş (n)",
    "y": " 1)kontrol, kontrol etme; yoklama, arama\r\n~2)tarama (araştırma vb. için)"
  },
  {
    "x": "tahda (m)",
    "y": " b. teda (m)"
  },
  {
    "x": "tahfîl kerdene",
    "y": " b. tehfîl kerdene"
  },
  {
    "x": "tahfîlkerde (n)",
    "y": " b. tehfîlkerde (n)"
  },
  {
    "x": "tahfîlkerdiş (n)",
    "y": " b. tehfîlkerdiş (n)"
  },
  {
    "x": "tahfîlkerdîye (m)",
    "y": " b. tehfîlkerdîye (m)"
  },
  {
    "x": "tahîn (n)",
    "y": " tahin"
  },
  {
    "x": "tahjî, -ye",
    "y": " b. tajî, -ye-I"
  },
  {
    "x": "tahl, -e",
    "y": " b. tal, -e-I"
  },
  {
    "x": "tahleki (m)",
    "y": " b. tehlîyike (m)"
  },
  {
    "x": "tahlê (m)",
    "y": " b. talîye (m)"
  },
  {
    "x": "tahlî (m)",
    "y": " b. talîye (m)"
  },
  {
    "x": "tahlîl (n)",
    "y": " b. tehlîl (n)"
  },
  {
    "x": "tahlîyiki (m)",
    "y": " b. tehlîyike (m)"
  },
  {
    "x": "tahlûke (n)",
    "y": " b. tehluke (n)"
  },
  {
    "x": "tahlûkeyin",
    "y": " b. tehlukeyin, -e"
  },
  {
    "x": "tahm (n)",
    "y": " b. tehm-I (n)"
  },
  {
    "x": "tahm kerdene",
    "y": " b. tehm kerdene"
  },
  {
    "x": "tahmnayene",
    "y": " b. tehmnayene"
  },
  {
    "x": "tahnayîne",
    "y": " b. tehnayene"
  },
  {
    "x": "tahnayîş (n)",
    "y": " b. tehnayîş (n)"
  },
  {
    "x": "tahnebî (m)",
    "y": " b. tehnebî (m)"
  },
  {
    "x": "tahs-nahs bîyene",
    "y": " b. tahsûnahs bîyene"
  },
  {
    "x": "tahs-nahs kerdene",
    "y": " b. tahsûnahs kerdene "
  },
  {
    "x": "tahs-nahsbîyayîş (n)",
    "y": " b. tahsûnahsbîyayîş (n)"
  },
  {
    "x": "tahs-nahskerdiş (n)",
    "y": " b. tahsûnahskerdiş (n)"
  },
  {
    "x": "tahsîl kerdene",
    "y": " b. tehsîl kerdene"
  },
  {
    "x": "tahsîlat (n)",
    "y": " b. tehsîlat (n)"
  },
  {
    "x": "tahsîlkerdiş (n)",
    "y": " b. tehsîlkerdiş (n)"
  },
  {
    "x": "tahsûnahs bîyene",
    "y": " çarçur olmak, harcanmak (gereksiz yere harcanmak)"
  },
  {
    "x": "tahsûnahs kerdene",
    "y": " çarçur etmek, harcamak (gereksiz yere harcamak)"
  },
  {
    "x": "tahsûnahsbîyayîş (n)",
    "y": " çarçur olma, çarçur, harcanma (gereksiz yere harcanma)"
  },
  {
    "x": "tahsûnahskerdiş (n)",
    "y": " çarçur etme, çarçur, harcama"
  },
  {
    "x": "taht (m)",
    "y": " b. tehte (m)"
  },
  {
    "x": "tahta sole (m)",
    "y": " b. tehta sole (m)"
  },
  {
    "x": "tahtay çarî (m)",
    "y": " b. tehta çareyî (m)"
  },
  {
    "x": "tahtay sênî (m)",
    "y": " b. tehta sênî (m) "
  },
  {
    "x": "tahtê çarî (m)",
    "y": " b. tehta çareyî (m)"
  },
  {
    "x": "tahtê sênî (m)",
    "y": " b. tehta sênî (m)"
  },
  {
    "x": "tahti (m)",
    "y": " b. tehte (m)"
  },
  {
    "x": "tahzîl (n)",
    "y": " b. tezîr (n)"
  },
  {
    "x": "tahzîl dayene",
    "y": " b. tezîr dayene"
  },
  {
    "x": "tahzîl dîyayene",
    "y": " b. tezîr dîyayene"
  },
  {
    "x": "tahzîl werdene",
    "y": " b. tezîr werdene"
  },
  {
    "x": "tahzîldayoxe, -e",
    "y": " b. tezîrdayox, -e"
  },
  {
    "x": "tahzîldayîş (n)",
    "y": " b. tezîrdayîş (n)"
  },
  {
    "x": "tahzîldîyayîş (n)",
    "y": " b. tezîrdîyayîş (n)"
  },
  {
    "x": "tahzîlwerdiş (n)",
    "y": " b. tezîrwerdiş (n)"
  },
  {
    "x": "tahzîr (n)",
    "y": " b. tezîr (n)"
  },
  {
    "x": "tahzîr dayene",
    "y": " b. tezîr dayene"
  },
  {
    "x": "tahzîr dîyayîne",
    "y": " b. tezîr dîyayene"
  },
  {
    "x": "tahzîr werdene",
    "y": " b. tezîr werdene"
  },
  {
    "x": "tahzîrdayîş (n)",
    "y": " b. tezîrdayîş (n)"
  },
  {
    "x": "tahzîrdayox, -e",
    "y": " b. tezîrdayox, -e"
  },
  {
    "x": "tahzîrdîyayîş (n)",
    "y": " b. tezîrdîyayîş (n)"
  },
  {
    "x": "tahzîrwerdiş (n)",
    "y": " b. tezîrwerdiş (n)"
  },
  {
    "x": "taj (m)",
    "y": " b. tace (m)"
  },
  {
    "x": "taji (m)",
    "y": " b. tace (m)"
  },
  {
    "x": "tajir, -e",
    "y": " b. têcîr, -e"
  },
  {
    "x": "tajirey (m)",
    "y": " b. têcîrî (m)"
  },
  {
    "x": "tajirê (m)",
    "y": " b. têcîrî (m)"
  },
  {
    "x": "tajî, -ye-I",
    "y": " tazı"
  },
  {
    "x": "tajî, -ye-II",
    "y": " b. tazî, -ye-II"
  },
  {
    "x": "taket-naket kerdene",
    "y": " tereddüt etmek, bocalamak"
  },
  {
    "x": "taket-naketkerdiş (n)",
    "y": " tereddüt etme, bocalama"
  },
  {
    "x": "takewtene",
    "y": " değmek"
  },
  {
    "x": "takewtiş (n)",
    "y": " değme-I"
  },
  {
    "x": "takometre (n)",
    "y": " hızölçer, takigraf, takograf, takometre"
  },
  {
    "x": "takoz (n)",
    "y": " takoz"
  },
  {
    "x": "taksî (m)",
    "y": " 1)otomobil, oto\r\n~2)taksi (ticari otomobil)"
  },
  {
    "x": "taksîmetre (n)",
    "y": " taksimetre"
  },
  {
    "x": "taksîramitox, -e",
    "y": " taksici"
  },
  {
    "x": "taksîramitoxî (m)",
    "y": " taksicilik"
  },
  {
    "x": "taksîramitoxîye (m)",
    "y": " taksicilik"
  },
  {
    "x": "taktîk (n)",
    "y": " taktik"
  },
  {
    "x": "tal, -e-I",
    "y": " acı"
  },
  {
    "x": "tal û tirş, -e",
    "y": " aşırı ekşi"
  },
  {
    "x": "tal, -e-II",
    "y": " 1)boş\r\n~2)abes"
  },
  {
    "x": "tal bîyene",
    "y": " boşalmak, tahliye edilmek"
  },
  {
    "x": "tal kerdene",
    "y": " boşaltmak, tahliye etmek"
  },
  {
    "x": "tal-tal",
    "y": " abes"
  },
  {
    "x": "tal, -e-III",
    "y": " kekeme, keke, kekeç"
  },
  {
    "x": "tala",
    "y": " b. teala"
  },
  {
    "x": "talan (n)",
    "y": " çapul, plaçka, talan, yağma"
  },
  {
    "x": "talan kerdene",
    "y": " yağmalamak, talanlamak, talan etmek, yağma etmek "
  },
  {
    "x": "talançî, -ye",
    "y": " b. talanker, -e"
  },
  {
    "x": "talançîyîye (m)",
    "y": " b. yaxmekerdoxîye (m)"
  },
  {
    "x": "talanker, -e",
    "y": " çapulcu, talancı, yağmacı"
  },
  {
    "x": "talankerdiş (n)",
    "y": " yağmalama, talanlama, talan etme, yağma etme "
  },
  {
    "x": "talankerdox, -e",
    "y": " çapulcu, talancı, yağmacı"
  },
  {
    "x": "talankerdoxîye (m)",
    "y": " çapulculuk, talancılık, yağmacılık"
  },
  {
    "x": "talankerîye (m)",
    "y": " çapulculuk, talancılık, yağmacılık"
  },
  {
    "x": "talaş-I (n)",
    "y": " b. teraş-I (n)"
  },
  {
    "x": "talaş-II (n)",
    "y": " b. telaşe (n)"
  },
  {
    "x": "talaş eyştene",
    "y": " b. telaşe eştene"
  },
  {
    "x": "talaş kerdene",
    "y": " b. telaşe kerdene"
  },
  {
    "x": "talaş vistene",
    "y": " b. telaşe fîştene"
  },
  {
    "x": "talaşe (n)",
    "y": " b. telaşe (n)"
  },
  {
    "x": "talaşe eyştene",
    "y": " b. telaşe eştene"
  },
  {
    "x": "talaşe kerdene",
    "y": " b. telaşe kerdene"
  },
  {
    "x": "talaşe vistene",
    "y": " b. telaşe fîştene"
  },
  {
    "x": "talaşeeyştiş (n)",
    "y": " b. telaşeeştiş (n)"
  },
  {
    "x": "talaşevistiş (n)",
    "y": " b. telaşefîştiş (n)"
  },
  {
    "x": "talaşeyştiş (n)",
    "y": " b. telaşeeştiş (n)"
  },
  {
    "x": "talaşvistiş (n)",
    "y": "b. telaşefîştiş (n)"
  },
  {
    "x": "talaz (m)",
    "y": " b. talazike (m)"
  },
  {
    "x": "talazike (m)",
    "y": " hortum"
  },
  {
    "x": "talaziki (m)",
    "y": " b. talazike (m)"
  },
  {
    "x": "talbîyayîş (n)",
    "y": " boşalma, deşarj, tahliye edilme"
  },
  {
    "x": "tale (n)",
    "y": " b. talih (n)"
  },
  {
    "x": "talebe (n)",
    "y": " b. telebe (n)"
  },
  {
    "x": "taleh (n)",
    "y": " b. talih (n)"
  },
  {
    "x": "talek, -e",
    "y": " acımsı, acımtırak"
  },
  {
    "x": "taleve (n)",
    "y": " b. telebe (n)"
  },
  {
    "x": "talê (m)",
    "y": " b. talîye (m)"
  },
  {
    "x": "talênî (m)",
    "y": " acılık"
  },
  {
    "x": "talih (n)",
    "y": " talih, şans"
  },
  {
    "x": "talike (n)",
    "y": " b. tehluke (n)"
  },
  {
    "x": "talikeyin",
    "y": " b. tehlukeyin, -e"
  },
  {
    "x": "talin, -e",
    "y": " acılı"
  },
  {
    "x": "talinc",
    "y": " b. talinc, -e"
  },
  {
    "x": "talince, -e",
    "y": " acımtırak"
  },
  {
    "x": "talî-I (m)",
    "y": " acılık"
  },
  {
    "x": "talî-II (m)",
    "y": " boşluk"
  },
  {
    "x": "talî-III (n)",
    "y": " b. talih (n)"
  },
  {
    "x": "talîke (n)",
    "y": " b. tehluke (n)"
  },
  {
    "x": "talîkeyin",
    "y": " b. tehlukeyin, -e"
  },
  {
    "x": "talîmatname (n)",
    "y": " b. telîmatname (n)"
  },
  {
    "x": "talîye-I (m)",
    "y": " acılık"
  },
  {
    "x": "talîye-II (m)",
    "y": " boşluk\r\n~talîya hoka ewnîyayîşî (sn): bakış açısı boşluğu\r\n~talîya hoka nîyadayîşî (sn): bakış açısı boşluğu"
  },
  {
    "x": "talîya hoka ewnîyayîşî (sn)",
    "y": " bakış açısı boşluğu"
  },
  {
    "x": "talîya hoka nîyadayîşî (sn)",
    "y": " bakış açısı boşluğu"
  },
  {
    "x": "talîye-III (m)",
    "y": " kekemelik, kekelik"
  },
  {
    "x": "talke (n)",
    "y": " b. tehluke (n)"
  },
  {
    "x": "talker (n)",
    "y": " boşaltıcı"
  },
  {
    "x": "talkerdiş (n)",
    "y": " boşaltma, tahliye, tahliye etme, boşaltım"
  },
  {
    "x": "Tallîn (n)",
    "y": " Tallin"
  },
  {
    "x": "talon (n)",
    "y": " b. talan (n)"
  },
  {
    "x": "talon kerdene",
    "y": " b. talan kerdene"
  },
  {
    "x": "talonkerdiş (n)",
    "y": " b. talankerdiş (n)"
  },
  {
    "x": "taloq (n)",
    "y": " erteleme, talik"
  },
  {
    "x": "taloq eştene",
    "y": " ertelemek, talik etmek"
  },
  {
    "x": "taloq kerdene",
    "y": " ertelemek, talik etmek"
  },
  {
    "x": "talpê hewrî (n)",
    "y": " b. tilpê hewrî (n)"
  },
  {
    "x": "talsa (m)",
    "y": " kurutulmuş elma"
  },
  {
    "x": "talte (n)",
    "y": " kesek"
  },
  {
    "x": "taluka (m)",
    "y": " b. tehlukeyine (m)"
  },
  {
    "x": "taluke (n)",
    "y": " b. tehluke (n)"
  },
  {
    "x": "talukeyin",
    "y": " b. tehlukeyin, -e"
  },
  {
    "x": "taluki (n)",
    "y": " b. tehluke (n)"
  },
  {
    "x": "talukiyin",
    "y": " b. tehlukeyin, -e"
  },
  {
    "x": "talûke (n)",
    "y": " b. tehluke (n)"
  },
  {
    "x": "talûkeyin",
    "y": " b. tehlukeyin, -e"
  },
  {
    "x": "talûn (n)",
    "y": " b. talan (n)"
  },
  {
    "x": "talûn kerdene",
    "y": " b. talan kerdene"
  },
  {
    "x": "talûnkerdiş (n)",
    "y": " b. talankerdiş (n)"
  },
  {
    "x": "talwuşe (n)",
    "y": " b. tolebişk (n)"
  },
  {
    "x": "tam, -e-I",
    "y": " tam"
  },
  {
    "x": "tam-II (n)",
    "y": " b. tehm-I (n)"
  },
  {
    "x": "tam kerdene",
    "y": " b. tehm kerdene"
  },
  {
    "x": "tamam, -e",
    "y": " b. temam, -e"
  },
  {
    "x": "tamar (m)",
    "y": " b. damare (m)"
  },
  {
    "x": "tamare (m)",
    "y": " b. damare (m)"
  },
  {
    "x": "tamase (n)",
    "y": " b. temaşe (n)"
  },
  {
    "x": "tamîr (n)",
    "y": " onarım, tamir"
  },
  {
    "x": "tamîr bîyene",
    "y": " onarılmak"
  },
  {
    "x": "tamîr kardene",
    "y": " b. tamîr kerdene"
  },
  {
    "x": "tamîr kerdene",
    "y": " onarmak, tamir etmek"
  },
  {
    "x": "tamîro besît (n)",
    "y": " basit onarım"
  },
  {
    "x": "tamîro sade (n)",
    "y": " basit onarım"
  },
  {
    "x": "tamîrat (n)",
    "y": " onarım, tamir"
  },
  {
    "x": "tamîrbîyayîş (n)",
    "y": " onarılma"
  },
  {
    "x": "tamîrkar, -e",
    "y": " tamirci"
  },
  {
    "x": "tamîrkara/ê motore",
    "y": " motor tamircisi"
  },
  {
    "x": "tamîrkara/ê otoyan",
    "y": " oto tamircisi"
  },
  {
    "x": "tamîrkara/ê televîzyonî",
    "y": " televizyon tamircisi"
  },
  {
    "x": "tamîrkardiş (n)",
    "y": " b. tamîrkerdiş (n)"
  },
  {
    "x": "tamîrkarî (m)",
    "y": " tamircilik"
  },
  {
    "x": "tamîrkarîye (m)",
    "y": " tamircilik"
  },
  {
    "x": "tamîrkerdiş (n)",
    "y": " onarma, tamir etme"
  },
  {
    "x": "tammesaî (m)",
    "y": " tam mesai"
  },
  {
    "x": "tamnayîne",
    "y": " b. tehmnayene"
  },
  {
    "x": "tampon (n)",
    "y": " tampon"
  },
  {
    "x": "tamsar, -e",
    "y": " b. tehmsar, -e"
  },
  {
    "x": "tamsarî (m)",
    "y": " b. tehmsarî (m)"
  },
  {
    "x": "tamsarîye (m)",
    "y": " b. tehmsarîye (m)"
  },
  {
    "x": "tamul (n)",
    "y": " b. tehemul (n)"
  },
  {
    "x": "tamul kerdene",
    "y": " b. tehemul kerdene"
  },
  {
    "x": "tamuldar, -e",
    "y": " b. tehemuldar"
  },
  {
    "x": "tamulkerdox, -e",
    "y": " b. tehemulkerdox, -e"
  },
  {
    "x": "tamulkerdiş (n)",
    "y": " b. tehemulkerdiş (n)"
  },
  {
    "x": "tamûm",
    "y": " b. temam, -e"
  },
  {
    "x": "tamxebate (m)",
    "y": " tam mesai "
  },
  {
    "x": "tan",
    "y": " b. tayê"
  },
  {
    "x": "tanayene",
    "y": " yakmak (katı yakacak için)\r\n~Ma emser di tonî îzime/kolî tanay. (Bu yıl iki ton odun yaktık.)"
  },
  {
    "x": "tanayîne",
    "y": " b. tehnayene"
  },
  {
    "x": "tanayîş-I (n)",
    "y": " yakma (katı yakacak için)"
  },
  {
    "x": "tanayîş-II (n)",
    "y": " b. tehnayîş (n)"
  },
  {
    "x": "tane (m)",
    "y": " b. tanî-I (m)"
  },
  {
    "x": "tanebî (m)",
    "y": " b. tehnebî (m)"
  },
  {
    "x": "taneki (m)",
    "y": " b. tûnike (m)"
  },
  {
    "x": "tanê",
    "y": " 1)b. tay\r\n~2)b. tayê\r\n~3)b. tenê-I"
  },
  {
    "x": "tani (m)",
    "y": " b. tanî-I (m)"
  },
  {
    "x": "tanike (m)",
    "y": " b. tûnike (m)"
  },
  {
    "x": "tanî-I (m)",
    "y": " ısı"
  },
  {
    "x": "tanî ver şanayene",
    "y": " ısıtmak"
  },
  {
    "x": "tanî ver şanitene",
    "y": " ısıtmak"
  },
  {
    "x": "tanîya eşteyî (m)",
    "y": " atık ısısı"
  },
  {
    "x": "tanîya rengî (m, sn)",
    "y": " renk ısısı"
  },
  {
    "x": "tanî-II",
    "y": " 1)b. tay\r\n~2)b. tayê\r\n~3)b. tenê-I"
  },
  {
    "x": "tanîne",
    "y": " b. tayêne"
  },
  {
    "x": "tanînî",
    "y": " b. tayêne"
  },
  {
    "x": "tanîpeym (n)",
    "y": " kalorimetre, ısıölçer"
  },
  {
    "x": "tanîpeymî (m)",
    "y": " ısı ölçümü, kalorimetri"
  },
  {
    "x": "tanîverşanayîş (n)",
    "y": " ısıtma"
  },
  {
    "x": "tanîverşanitiş (n)",
    "y": " ısıtma"
  },
  {
    "x": "tankere (m)",
    "y": " tanker"
  },
  {
    "x": "tankî",
    "y": " 1)b. tayê-2\r\n~2)tenê-I"
  },
  {
    "x": "tanqe (m)",
    "y": " tank "
  },
  {
    "x": "tanqa awe (m)",
    "y": " su tankı"
  },
  {
    "x": "tanqa denişnayîşî (m)",
    "y": " çökeltme tankı"
  },
  {
    "x": "tanqê denişnayîşê peyênî (zh)",
    "y": " son çökeltme tankları"
  },
  {
    "x": "tansîyon (n)",
    "y": " 1)tansiyon\r\n~2)(mecazi) gerilim"
  },
  {
    "x": "tansîyono berz (n)",
    "y": " yüksek tansiyon, hipertansiyon"
  },
  {
    "x": "tansîyono kewte (n)",
    "y": " düşük tansiyon"
  },
  {
    "x": "tansîyono nimz (n)",
    "y": " b. tansîyono nizm (n)"
  },
  {
    "x": "tansîyono nizm (n)",
    "y": " düşük tansiyon, hipotansiyon"
  },
  {
    "x": "tantor (n)",
    "y": " b. tantûr (n)"
  },
  {
    "x": "tantur (n)",
    "y": " b. tantûr (n)"
  },
  {
    "x": "tantûr (n)",
    "y": " çökelek, tantûrin, -e. Çökelekli"
  },
  {
    "x": "tantûrin, -e. çökelekli"
  },
  {
    "x": "Tanzanya (m)",
    "y": " Tanzanya"
  },
  {
    "x": "tanzi (n)",
    "y": " b. tajî (n), tazî (n) "
  },
  {
    "x": "tanzî (m)",
    "y": " b. tajîye (m), tazîye (m) "
  },
  {
    "x": "tap (n)",
    "y": " doruk, şahika, zirve"
  },
  {
    "x": "tapa",
    "y": " b. tepîya"
  },
  {
    "x": "tapan (n)",
    "y": " tapan, sürgü"
  },
  {
    "x": "tapan kerdene",
    "y": " tapan çekmek, tapanlamak"
  },
  {
    "x": "tapankerdiş (n)",
    "y": " tapanlama"
  },
  {
    "x": "tapisnayîne",
    "y": " b. tepisnayene"
  },
  {
    "x": "tapisnayîş (n)",
    "y": " b. tepisnayîş (n)"
  },
  {
    "x": "tapiznayeni",
    "y": " b. tepisnayene"
  },
  {
    "x": "tapiznayîş (n)",
    "y": " b. tepisnayîş (n)"
  },
  {
    "x": "tapî (n)",
    "y": " b. tapû (n)"
  },
  {
    "x": "tapî kerdene",
    "y": " b. tapû kerdene"
  },
  {
    "x": "tapîkerda (m)",
    "y": " b. tapûkerda (m)"
  },
  {
    "x": "tapîkerde (n)",
    "y": " b. tapûkerde (n)"
  },
  {
    "x": "tapîkerdiş (n)",
    "y": " b. tapûkerdiş (n)"
  },
  {
    "x": "tapîkerdî, -ye",
    "y": " b. tapûkerdî, -ye"
  },
  {
    "x": "tapo (n)",
    "y": " b. tapû (n)"
  },
  {
    "x": "tapo kerdene",
    "y": " b. tapû kerdene"
  },
  {
    "x": "tapokerda (m)",
    "y": " b. tapûkerda (m)"
  },
  {
    "x": "tapokerde (n)",
    "y": " b. tapûkerde (n)"
  },
  {
    "x": "tapokerdiş (n)",
    "y": " b. tapûkerdiş (n)"
  },
  {
    "x": "tapokerdî, -ye",
    "y": " b. tapûkerdî, -ye"
  },
  {
    "x": "tapol (n)",
    "y": " b. tapole (m)"
  },
  {
    "x": "tapole (m)",
    "y": " 1)biçilip yerde bırakılmış olan ekin demeti\r\n~2)büyükbaş hayvanın bir defada yaptığı dışkı topağı\r\n~3)b. patîle-I (m)"
  },
  {
    "x": "tapual (m)",
    "y": " b. tapole (m)"
  },
  {
    "x": "tapuel (m)",
    "y": " b. tapole (m)"
  },
  {
    "x": "tapû (n)",
    "y": " tapu"
  },
  {
    "x": "tapû kerdene",
    "y": " tapulamak"
  },
  {
    "x": "tapûyo bihîse (n)",
    "y": " hisseli tapu"
  },
  {
    "x": "tapûyo xoser (n)",
    "y": " müstakil tapu"
  },
  {
    "x": "tapûkerda (m)",
    "y": " tapulanmış olan"
  },
  {
    "x": "tapûkerde (n)",
    "y": " tapulanmış olan"
  },
  {
    "x": "tapûkerdiş (n)",
    "y": " tapulama"
  },
  {
    "x": "tapûkerdî, -ye",
    "y": " tapulanmış olan"
  },
  {
    "x": "taq-raq",
    "y": " b. teq-reqe (m)"
  },
  {
    "x": "taqa (m)",
    "y": " bina duvarının iç tarafında bulunan ve dışarıya açılmayan pencere biçimindeki yer, taka"
  },
  {
    "x": "taqate (m)",
    "y": " b. taqet (n)"
  },
  {
    "x": "taqet (n)",
    "y": " mecal, takat"
  },
  {
    "x": "taqet ra kewtene",
    "y": " bitkin düşmek"
  },
  {
    "x": "taqim (n)",
    "y": " b. taxim-II (n)"
  },
  {
    "x": "taqimê cixara (n)",
    "y": " b. taximê cixara (n)"
  },
  {
    "x": "taqimê qoltuxan (n)",
    "y": " b. taximê qoltuxan (n)"
  },
  {
    "x": "taqî (m)",
    "y": " üçetek"
  },
  {
    "x": "taqîb (n)",
    "y": " takip\r\n~taqîbê îcra yo dadnamedar (n): ilamlı icra takibi\r\n~taqîbê waştişî (n): talep takibi"
  },
  {
    "x": "taqîb kerdene",
    "y": " izlemek, takip etmek, kovuşturmak"
  },
  {
    "x": "taqîbê îcra yo dadnamedar (n)",
    "y": " ilamlı icra takibi"
  },
  {
    "x": "taqîbê waştişî (n)",
    "y": " talep takibi"
  },
  {
    "x": "taqîbat (n)",
    "y": " kovuşturma, takibat "
  },
  {
    "x": "taqîbbar, -e",
    "y": " izlenebilir (olan)"
  },
  {
    "x": "taqîbbarîye (m)",
    "y": " izlenebilirlik"
  },
  {
    "x": "taqîbkerdiş (n)",
    "y": " izleme, takip etme, kovuşturma "
  },
  {
    "x": "taqîbkerdox, -e",
    "y": " izleyici"
  },
  {
    "x": "taqoz (n)",
    "y": " b. takoz (n)"
  },
  {
    "x": "taqrîben",
    "y": " b. teqrîben"
  },
  {
    "x": "tar-I (n)",
    "y": " b. tarr-I (n)"
  },
  {
    "x": "tar-II (m)",
    "y": " b. terhe (m)"
  },
  {
    "x": "tara (m)",
    "y": " b. terhe (m)"
  },
  {
    "x": "tarame (m)",
    "y": " b. tiramî (m)"
  },
  {
    "x": "taramî (m)",
    "y": " b. tiramî (m)"
  },
  {
    "x": "taraqeldayîş (n)",
    "y": " b. terheqelibnayîş (n)"
  },
  {
    "x": "taraşîyayene",
    "y": " b. terişîyayene"
  },
  {
    "x": "Tarawa (m)",
    "y": " Tarawa"
  },
  {
    "x": "tarayene",
    "y": " b. wetardene"
  },
  {
    "x": "tarayêne",
    "y": " b. wetardene"
  },
  {
    "x": "tarayîne",
    "y": " b. wetardene"
  },
  {
    "x": "tarayîş (n)",
    "y": " b. wetardiş (n)"
  },
  {
    "x": "tarbe (m)",
    "y": " b. derbe (m)"
  },
  {
    "x": "tarbi (m)",
    "y": " b. derbe (m)"
  },
  {
    "x": "tarçin (n)",
    "y": " b. darçîn (n)"
  },
  {
    "x": "tare (m)",
    "y": " tünek "
  },
  {
    "x": "tare ra bîyayene",
    "y": " tüneklemek, tünemek"
  },
  {
    "x": "tare ... verdîyayîş",
    "y": " burkulma (el, ayak vb. için)"
  },
  {
    "x": "tareciverdîyayîş (n)",
    "y": " burkulma (el, ayak vb. için)"
  },
  {
    "x": "taredestîverdîyayîş (n)",
    "y": " el burkulması"
  },
  {
    "x": "tarelingeverdîyayîş (n)",
    "y": " ayak burkulması"
  },
  {
    "x": "tarerabîyayîş (n)",
    "y": " tünekleme, tüneme"
  },
  {
    "x": "taretxane (n)",
    "y": " yüznumara, ayakyolu, abdesthane (apteshane), hela, kademhane, kenef, memişhane, tuvalet"
  },
  {
    "x": "tariq (n)",
    "y": " b. tarîx (n)"
  },
  {
    "x": "tariş (n)",
    "y": " b. terş (n)"
  },
  {
    "x": "tarix (n)",
    "y": " b. tarîx (n)"
  },
  {
    "x": "tarişyayene",
    "y": " b. terişîyayene"
  },
  {
    "x": "tarî, -ye",
    "y": " karanlık"
  },
  {
    "x": "tarîqat (n)",
    "y": " b. terîqet (n)"
  },
  {
    "x": "tarîqate (m)",
    "y": " b. terîqet (n)"
  },
  {
    "x": "tarîx (n)",
    "y": " tarih"
  },
  {
    "x": "tarîxê dayeyan (n)",
    "y": " veri tarihi"
  },
  {
    "x": "tarîxê destpêkerdişî (n)",
    "y": " başlama tarihi"
  },
  {
    "x": "tarîxê destpêkî (n)",
    "y": " başlangıç tarihi"
  },
  {
    "x": "tarîxê destpêkî yo bingeyî (n)",
    "y": " temel başlangıç tarihi"
  },
  {
    "x": "tarîxê doneyan (n)",
    "y": " veri tarihi"
  },
  {
    "x": "tarîxê qedîyayîşî (n)",
    "y": " bitiş tarihi"
  },
  {
    "x": "tarîxê qedîyayîşî yê plankerdeyî (n)",
    "y": " planlanan bitiş tarihi"
  },
  {
    "x": "tarîxê qedîyayîşî yo hedef (n)",
    "y": " hedef bitiş tarihi"
  },
  {
    "x": "tarîxê rewqedîyayîşî (n)",
    "y": " erken bitiş tarihi"
  },
  {
    "x": "tarîxê temamkerdişî yo hedef (n)",
    "y": " hedef tamamlama tarihi"
  },
  {
    "x": "tarîxî, -ye",
    "y": " tarihsel, tarihi"
  },
  {
    "x": "tarîxnas, -e",
    "y": " tarihçi"
  },
  {
    "x": "tarîxnasî (m)",
    "y": " tarihçilik"
  },
  {
    "x": "tarîxnasîye (m)",
    "y": " tarihçilik"
  },
  {
    "x": "tarnayene",
    "y": " b. tehnayene"
  },
  {
    "x": "tarnayîş (n)",
    "y": " b. tehnayîş (n)"
  },
  {
    "x": "taro ... verdîyayîş",
    "y": " b. tare ... verdîyayîş"
  },
  {
    "x": "taro ... vêrdîyayîş",
    "y": " b. tare ... verdîyayîş"
  },
  {
    "x": "tarociverdîyayîş (n)",
    "y": " b. tareciverdîyayîş (n)"
  },
  {
    "x": "tarocivêrdîyayîş (n)",
    "y": " b. tareciverdîyayîş (n)"
  },
  {
    "x": "tarqeldayîş (n)",
    "y": " b. terheqelibnayîş (n)"
  },
  {
    "x": "tarr-I (n)",
    "y": " yenilebilen semizotu vb. otsu bitkilerin genel adı"
  },
  {
    "x": "tarr-II (n)",
    "y": " b. tarre (m)"
  },
  {
    "x": "tarramî (m)",
    "y": " tınaz"
  },
  {
    "x": "tarre-I (m)",
    "y": " orakla ekin biçerken sol ele geçirilen ve biçilen büyük ekin demetini elde tutup kontrol etmeyi sağlayan bir araç"
  },
  {
    "x": "tarre-II (m)",
    "y": " b. tare (m) "
  },
  {
    "x": "tarre ra bîyayîne",
    "y": " b. tare ra bîyayene "
  },
  {
    "x": "tarrerabîyayîş (n)",
    "y": " b. tarerabîyayîş (n)"
  },
  {
    "x": "tarri (m)",
    "y": " b. tarre (m)"
  },
  {
    "x": "tarrûm (m)",
    "y": " b. tiramî (m)"
  },
  {
    "x": "tarrûmî (m)",
    "y": " b. tarramî (m)"
  },
  {
    "x": "tarrûtur (n)",
    "y": " sebze, göveri, göverti"
  },
  {
    "x": "tarrûturr (n)",
    "y": " b. tarrûtur (n)"
  },
  {
    "x": "tars (n)",
    "y": " b. ters (n)"
  },
  {
    "x": "tarsê gûnî (n)",
    "y": " b. tersê ganî"
  },
  {
    "x": "tarsê margî (n)",
    "y": " b. tersê mergî (n)"
  },
  {
    "x": "tarş (n)",
    "y": " b. terş (n)"
  },
  {
    "x": "tarux (n)",
    "y": " b. tarîx (n)"
  },
  {
    "x": "tarûtur (n)",
    "y": " b. tarrûtur (n)"
  },
  {
    "x": "tarve (m)",
    "y": " b. derbe (m)"
  },
  {
    "x": "tarvi (m)",
    "y": " b. derbe (m)"
  },
  {
    "x": "tas (m)",
    "y": " b. tase (m)"
  },
  {
    "x": "tasey hemomî (m)",
    "y": " b. tasa hemamî (m)"
  },
  {
    "x": "tasê hemûmî (m)",
    "y": " b. tasa hemamî (m)"
  },
  {
    "x": "tas û nas bîyayene",
    "y": " b. tahsûnahs bîyene"
  },
  {
    "x": "tas û nas kerdene",
    "y": " b. tahsûnahs kerdene"
  },
  {
    "x": "tas-nas bîyayene",
    "y": " b. tahsûnahs bîyene"
  },
  {
    "x": "tas-nas kerdene",
    "y": " b. tahsûnahs kerdene"
  },
  {
    "x": "tas-nasbîyayîş (n)",
    "y": " b. tahsûnahsbîyayîş (n)"
  },
  {
    "x": "tas-naskerdiş (n)",
    "y": " b. tahsûnahskerdiş (n)"
  },
  {
    "x": "tase (m)",
    "y": " tas"
  },
  {
    "x": "tasa hamamî (m)",
    "y": " b. tasa hemamî (m)"
  },
  {
    "x": "tasa hemamî (m)",
    "y": " hamam tası"
  },
  {
    "x": "tasela bîyayene",
    "y": " b. taşele bîyene"
  },
  {
    "x": "taselabîyayîş (n)",
    "y": " b. taşelebîyayîş (n)"
  },
  {
    "x": "tasfîye (m)",
    "y": " b. tesfîye (m)"
  },
  {
    "x": "tasi (m)",
    "y": " b. tase (m)"
  },
  {
    "x": "tasike (m)",
    "y": " küçük tas"
  },
  {
    "x": "tasiki (m)",
    "y": " b. tasike (m)"
  },
  {
    "x": "tasîldar, -e",
    "y": " b. tehsîldar, -e"
  },
  {
    "x": "tasîldarîye (m)",
    "y": " b. tehsîldarîye (m)"
  },
  {
    "x": "tasoke (m)",
    "y": " fes tahtası (fesin başa oturan kısmına tutturulan tahta)"
  },
  {
    "x": "tastene",
    "y": " b. taştene"
  },
  {
    "x": "tastî (m)",
    "y": " b. teşt (n)"
  },
  {
    "x": "tasûnas bîyayene",
    "y": " b. tahsûnahs bîyene"
  },
  {
    "x": "tasûnas kerdene",
    "y": " b. tahsûnahs kerdene"
  },
  {
    "x": "tasûnasbîyayîş (n)",
    "y": " b. tahsûnahsbîyayîş (n)"
  },
  {
    "x": "tasûnaskerdiş (n)",
    "y": " b. tahsûnahskerdiş (n)"
  },
  {
    "x": "taşdere (n)",
    "y": " b. teştare (n)"
  },
  {
    "x": "taşderey",
    "y": " b. teştareyî"
  },
  {
    "x": "taşele bîyaene",
    "y": " b. taşele bîyene"
  },
  {
    "x": "taşele bîyene",
    "y": " ağlamaktan perişan olmak"
  },
  {
    "x": "taşelebîyayîs (n)",
    "y": " b. taşelebîyayîş (n)"
  },
  {
    "x": "taşelebîyayîş (n)",
    "y": " ağlamaktan perişan olma"
  },
  {
    "x": "taşeron (n)",
    "y": " taşeron"
  },
  {
    "x": "taşitiş (n",
    "y": " b. taştiş (n)"
  },
  {
    "x": "taşî (m)",
    "y": " iğ, eğirmen, kirmen"
  },
  {
    "x": "Taşkent (n)",
    "y": " Taşkent"
  },
  {
    "x": "taşt-I (n)",
    "y": " b. teşt (n)"
  },
  {
    "x": "taşt-II (m)",
    "y": " b. taştî (m)"
  },
  {
    "x": "taştare (n)",
    "y": " b. teştare (n) "
  },
  {
    "x": "taştarey",
    "y": " b. teştareyî"
  },
  {
    "x": "taştareyê",
    "y": " b. teştareyî"
  },
  {
    "x": "taştarê",
    "y": " b. teştareyî"
  },
  {
    "x": "taştari (n)",
    "y": " b. teştare (n)"
  },
  {
    "x": "taştene",
    "y": " 1)kabuğunu soymak\r\n~2)tıraş etmek"
  },
  {
    "x": "taştere (n)",
    "y": " 1)kahvaltı ile öğle yemeği arasında yenen hafif yemek\r\n~2)b. teştare (n)"
  },
  {
    "x": "taştêre (n)",
    "y": " öğle yemeği ile akşam yemeği arasında yenen hafif yemek "
  },
  {
    "x": "taşti (m)",
    "y": " b. taştî (m)"
  },
  {
    "x": "taştiş (n)",
    "y": " tıraş, tıraş etme"
  },
  {
    "x": "taştişê erdîşe",
    "y": " sakal tıraşı"
  },
  {
    "x": "taştişê gijikî",
    "y": " saç tıraşı"
  },
  {
    "x": "taştişê porî",
    "y": " saç tıraşı"
  },
  {
    "x": "taştî (m)",
    "y": " öğle yemeği\r\n~Mi taştî werde. (Öğle yemeği yedim.)"
  },
  {
    "x": "taştîye (m)",
    "y": " b. taştî (m)"
  },
  {
    "x": "taştyeri (n)",
    "y": " b. taştêre (n)"
  },
  {
    "x": "tat (m)",
    "y": " b. tehte (m)"
  },
  {
    "x": "tat-mat mendene",
    "y": " bakakalmak"
  },
  {
    "x": "tat-matmendiş (n)",
    "y": " bakakalma"
  },
  {
    "x": "tati (m)",
    "y": " b. tehte (m)"
  },
  {
    "x": "tata çarî (m)",
    "y": " b. tehta çareyî (m) "
  },
  {
    "x": "tata sênî (m)",
    "y": " b. tehta sênî (m)"
  },
  {
    "x": "tatey çarî (m)",
    "y": " b. tehta çareyî (m)"
  },
  {
    "x": "tatey sênî (m)",
    "y": " b. tehta sênî (m) "
  },
  {
    "x": "tata soli (m)",
    "y": " b. tehta sole (m)"
  },
  {
    "x": "tatîl (n)",
    "y": " b. tehtîl (n)"
  },
  {
    "x": "tatîlo resmî (n)",
    "y": " b. tehtîlo resmî (n)"
  },
  {
    "x": "tat-mat mendene",
    "y": " şaşakalmak, bakakalmak"
  },
  {
    "x": "tat-matmendiş (n)",
    "y": " şaşakalma, bakakalma"
  },
  {
    "x": "Tatos (n)",
    "y": " Tekman"
  },
  {
    "x": "tatur (n)",
    "y": " b. tantûr (n)"
  },
  {
    "x": "tava (n)",
    "y": " b. teba (n)"
  },
  {
    "x": "tavaq-I (n)",
    "y": " b. tebaq (n)"
  },
  {
    "x": "tavaq-II (n)",
    "y": " b. tebaxe-I (m)"
  },
  {
    "x": "tavat (n)",
    "y": " b. tebat (n)"
  },
  {
    "x": "tavat ci kewtene",
    "y": " b. tebat ci kewtene"
  },
  {
    "x": "tavat kerdene",
    "y": " b. tebat kerdene"
  },
  {
    "x": "tavatcikewtiş (n)",
    "y": " b. tebatcikewtiş (n)"
  },
  {
    "x": "tavatkerdiş (n)",
    "y": " b. tebatkerdiş (n)"
  },
  {
    "x": "tavil",
    "y": " b. tavilê"
  },
  {
    "x": "tavilê",
    "y": " aniden, ansızın, birdenbire; ani"
  },
  {
    "x": "tavilê merdene",
    "y": " aniden ölmek"
  },
  {
    "x": "tavilî",
    "y": " b. tavilê"
  },
  {
    "x": "tavistene",
    "y": " iliklemek"
  },
  {
    "x": "gojage/make/bişkoje/qumça/sedefe/zirare tavistene",
    "y": " düğme iliklemek"
  },
  {
    "x": "tavistiş (n)",
    "y": " ilikleme"
  },
  {
    "x": "tavî",
    "y": " b. tabî-II"
  },
  {
    "x": "tavûs, -e",
    "y": " b. tawûs, -e"
  },
  {
    "x": "taw (n)",
    "y": " zaman, vakit, çağ"
  },
  {
    "x": "tawo ke",
    "y": " -diği zaman, -diği vakit, ne zaman ki, vakta ki"
  },
  {
    "x": "taw bîyene",
    "y": " geçerli olmak (oyunda), kabul edilmek (oyunda), sayılmak (oyunda kabul edilmek)"
  },
  {
    "x": "tawa-I (n)",
    "y": " b. tawe-I (n)"
  },
  {
    "x": "tawa-II (n)",
    "y": " b. teba (n)"
  },
  {
    "x": "taway",
    "y": " b. tebayê"
  },
  {
    "x": "tawey",
    "y": " b. tebayê"
  },
  {
    "x": "tawê",
    "y": " b. tebayê"
  },
  {
    "x": "tawat (n)",
    "y": " b. tebat (n)"
  },
  {
    "x": "tawat ci kewtene",
    "y": " b. tebat ci kewtene"
  },
  {
    "x": "tawat ci kotene",
    "y": " b. tebat ci kewtene"
  },
  {
    "x": "tawat kerdeni",
    "y": " b. tebat kerdene"
  },
  {
    "x": "tawatcikewtiş (n)",
    "y": " b. tebatcikewtiş (n)"
  },
  {
    "x": "tawatcikotiş (n)",
    "y": " b. tebatcikewtiş (n)"
  },
  {
    "x": "tawatkerdiş (n)",
    "y": " b. tebatkerdiş (n)"
  },
  {
    "x": "tawe-I (n)",
    "y": " tava"
  },
  {
    "x": "tawe-II (m)",
    "y": " tava (tavada pişmiş yemek)"
  },
  {
    "x": "tawig (n)",
    "y": " b. tawug (n)"
  },
  {
    "x": "tawik (n)",
    "y": " b. tawug (n)"
  },
  {
    "x": "tawike (m)",
    "y": " b. tawe-I (n)"
  },
  {
    "x": "tawin (n)",
    "y": " basur"
  },
  {
    "x": "tawin teberkerdene",
    "y": " basuru olmak"
  },
  {
    "x": "tawîyayene",
    "y": " 1)yakışmak, uygun düşmek, uygun gelmek, uygun olmak, uymak, yaraşmak\r\n~2)yakışıklı olmak\r\n~Ti pê nê çakêtî zaf tawîyê. (Bu ceketle çok yakışıklı oldun.)"
  },
  {
    "x": "tawîyayîş (n)",
    "y": " 1)yakışma\r\n~2)yakışıklı olma"
  },
  {
    "x": "tawla (m)",
    "y": " tavla (oyun için)"
  },
  {
    "x": "tawla (n)",
    "y": " b. tewle (n)"
  },
  {
    "x": "tawle (n)",
    "y": " b. tewle (n)"
  },
  {
    "x": "tawli (n)",
    "y": " b. tewle (n)"
  },
  {
    "x": "tawo (n)",
    "y": " b. teba (n)"
  },
  {
    "x": "tawrayene",
    "y": " b. wetardene"
  },
  {
    "x": "tawrayîş (n)",
    "y": " b. wetardiş (n)"
  },
  {
    "x": "tawug (n)",
    "y": " kızak"
  },
  {
    "x": "tawuke",
    "y": " b. tawug (n)"
  },
  {
    "x": "tawus, -e",
    "y": " b. tawûs, -e"
  },
  {
    "x": "tawusi (m)",
    "y": " b. tawûse (m)"
  },
  {
    "x": "tawute (m)",
    "y": " b. tabut (n)"
  },
  {
    "x": "tawûs, -e",
    "y": " tavus"
  },
  {
    "x": "tawyayene",
    "y": " b. tawîyayene"
  },
  {
    "x": "tawyayîş (n)",
    "y": " b. tawîyayîş (n)"
  },
  {
    "x": "taxe (m)",
    "y": " mahalle"
  },
  {
    "x": "taxim-I (n)",
    "y": " sınır, hudut\r\n~taximê awanî (n): imar hududu, imar sınırı\r\n~taximê îskanî (n): iskân sınırı\r\n~taximo viraşte (n): suni sınır, yapma sınır"
  },
  {
    "x": "taximê awanî (n)",
    "y": " imar hududu, imar sınırı"
  },
  {
    "x": "taximê îskanî (n)",
    "y": " iskân sınırı"
  },
  {
    "x": "taximo viraşte (n)",
    "y": " suni sınır, yapma sınır"
  },
  {
    "x": "taxim-II (n)",
    "y": " 1)kostüm, takım (giysiler için)\r\n~taximê cilan (n): elbise takımı, giysi takımı, kostüm\r\n~2)takım\r\n~taximê akerdiş û birnayîşî (n): açma kesme takımı\r\n~3)ağızlık, sigara ağızlığı, çubuk (uzun ağızlık)\r\n~taximê cixara (n): ağızlık, sigara ağızlığı"
  },
  {
    "x": "taximê cilan (n)",
    "y": " elbise takımı, giysi takımı, kostüm"
  },
  {
    "x": "taximê çinayan (n)",
    "y": " elbise takımı, giysi takımı, kostüm"
  },
  {
    "x": "taximê kincan (n)",
    "y": " elbise takımı, giysi takımı, kostüm"
  },
  {
    "x": "taximê palkursîyan (n)",
    "y": " koltuk takımı"
  },
  {
    "x": "taximê qoltixon (n)",
    "y": " b. taximê qoltuxan (n)"
  },
  {
    "x": "taximê qoltuxan (n)",
    "y": " koltuk takımı"
  },
  {
    "x": "taximê akerdiş û birnayîşî (n)",
    "y": " açma kesme takımı"
  },
  {
    "x": "taximê balişnaya hewadare (n)",
    "y": " hava yastığı seti"
  },
  {
    "x": "taximê cazî (n)",
    "y": " caz takımı"
  },
  {
    "x": "taximê wekerdiş û birnayîşî (n)",
    "y": " açma kesme takımı"
  },
  {
    "x": "taximê cixara (n)",
    "y": " ağızlık, sigara ağızlığı"
  },
  {
    "x": "taxime (m)",
    "y": " b. taxim-II (n)"
  },
  {
    "x": "taximroş, -e",
    "y": " ağızlıkçı"
  },
  {
    "x": "taximviraştox, -e",
    "y": " ağızlıkçı"
  },
  {
    "x": "taxlîya (m)",
    "y": " b. texlîya (m)"
  },
  {
    "x": "taxoke",
    "y": " b. tawug (n)"
  },
  {
    "x": "taxsî (m)",
    "y": " b. taksî (m)"
  },
  {
    "x": "tay, -e",
    "y": " 1)az\r\n~2)b. tayê\r\n~3)b. tenê-I"
  },
  {
    "x": "tay bîyene",
    "y": " azalmak"
  },
  {
    "x": "tay eşnawitene",
    "y": " ağır duymak, ağır işitmek"
  },
  {
    "x": "tayê tayan",
    "y": " en az, asgari"
  },
  {
    "x": "tay-tay",
    "y": " b. tayê-tayê"
  },
  {
    "x": "tay tay",
    "y": " sığırın yürümesi için veya kovarken söylenen bir söz (inek için)"
  },
  {
    "x": "taybetî, -ye",
    "y": " özel, hususi"
  },
  {
    "x": "taybetmendî (m)",
    "y": " özellik, hususiyet\r\n~Çi taybetmendîya nê xortî est a?/Çi xususîyetê nê xortî est o? (Bu delikanlının ne özelliği var?)\r\n~taybetmendîya vatişî (m): aksan"
  },
  {
    "x": "taybetmendîya vatişî (m)",
    "y": " aksan"
  },
  {
    "x": "taybetmendîye (m)",
    "y": " özellik, hususiyet"
  },
  {
    "x": "taybîyayîş (n)",
    "y": " azalma"
  },
  {
    "x": "taye",
    "y": " b. tayê"
  },
  {
    "x": "tayen",
    "y": " b. tayêne"
  },
  {
    "x": "tayê",
    "y": " 1)bazı, bazısı, bazıları\r\n~2)b. tenê-I"
  },
  {
    "x": "tayê-tayê",
    "y": " bazı"
  },
  {
    "x": "tayên",
    "y": " b. tayêne"
  },
  {
    "x": "tayêna-I",
    "y": " bazıları daha"
  },
  {
    "x": "tayêna-II",
    "y": " b. daha-3"
  },
  {
    "x": "tayêne",
    "y": " bazıları"
  },
  {
    "x": "tayfa (m)",
    "y": " tayfa (gemi tayfası)"
  },
  {
    "x": "tayfûn (n)",
    "y": " tayfun"
  },
  {
    "x": "tayin",
    "y": " b tayêne"
  },
  {
    "x": "tayin, -e",
    "y": " sıtmalı"
  },
  {
    "x": "tayî",
    "y": " b. tayê"
  },
  {
    "x": "tayîn-I (m)",
    "y": " b. tayîne-I (m)"
  },
  {
    "x": "tayîn-II (n)",
    "y": " tayin"
  },
  {
    "x": "tayîn bîyene",
    "y": " atanmak"
  },
  {
    "x": "tayîn kerdene",
    "y": " atamak"
  },
  {
    "x": "tayîn-III",
    "y": " b. tayêne"
  },
  {
    "x": "tayîna",
    "y": " b. daha-3"
  },
  {
    "x": "tayînbîyayîş (n)",
    "y": " atanma"
  },
  {
    "x": "tayîne-I (m)",
    "y": " tayın"
  },
  {
    "x": "tayîne-II",
    "y": " b. tayêne"
  },
  {
    "x": "tayîni-I (m)",
    "y": " b. tayîne-I (m)"
  },
  {
    "x": "tayîni-II",
    "y": " b. tayêne"
  },
  {
    "x": "tayînî",
    "y": " b. tayêne"
  },
  {
    "x": "tayînkerdiş (n)",
    "y": " atama"
  },
  {
    "x": "taykên",
    "y": " b. tayêne"
  },
  {
    "x": "taykine",
    "y": " b. tayêne"
  },
  {
    "x": "taykîne",
    "y": " b. tayêne"
  },
  {
    "x": "Tayland (n)",
    "y": " Tayland"
  },
  {
    "x": "tayn-I",
    "y": " 1)b. tay\r\n~2)b. tayê\r\n~3)b. tenê-I"
  },
  {
    "x": "tayn-II (m)",
    "y": " b. tanî-I (m)"
  },
  {
    "x": "tayna",
    "y": " az daha"
  },
  {
    "x": "taynan",
    "y": " b. tayêne"
  },
  {
    "x": "tayne",
    "y": " b. tayêne"
  },
  {
    "x": "taynî",
    "y": " b. tayê"
  },
  {
    "x": "taynînî",
    "y": " b. tayêne"
  },
  {
    "x": "Taypê",
    "y": " Taipei"
  },
  {
    "x": "tayr, -e",
    "y": " b. teyr, -e"
  },
  {
    "x": "Taywan (n)",
    "y": " Tayvan, Formoza"
  },
  {
    "x": "tayyor (n)",
    "y": " tayyör"
  },
  {
    "x": "tazî, -ye-I",
    "y": " tazı"
  },
  {
    "x": "tazî, -ye-II",
    "y": " 1)çıplak\r\n~2)(mecazi) yoksul, fakir"
  },
  {
    "x": "tazîl (n)",
    "y": " b. tezîr (n)"
  },
  {
    "x": "tazîl dayene",
    "y": " b. tezîr dayene"
  },
  {
    "x": "tazîl dîyayene",
    "y": " b. tezîr dîyayene"
  },
  {
    "x": "tazîl werdene",
    "y": " b. tezîr werdene"
  },
  {
    "x": "tazîldayîş (n)",
    "y": " b. tezîrdayîş (n)"
  },
  {
    "x": "tazîldayox, -e",
    "y": " b. tezîrdayox, -e"
  },
  {
    "x": "tazîldîyayîş (n)",
    "y": " b. tezîrdîyayîş (n)"
  },
  {
    "x": "tazîlwerdiş (n)",
    "y": " b. tezîrwerdiş (n)"
  },
  {
    "x": "tazîye (m)",
    "y": " b. tezîye-I (m)"
  },
  {
    "x": "te (m, n, z)",
    "y": " 1)b. ey (n, z)\r\n~2)b. aye (m, z)"
  },
  {
    "x": "te ra",
    "y": " b. ey ra, aye ra"
  },
  {
    "x": "te ra pey",
    "y": " b. ey ra pey, aye ra pey"
  },
  {
    "x": "te ra çîyê/çîyêk hêşkewtiş",
    "y": " b. ti ra çîyê/çîyêk hişkewtiş"
  },
  {
    "x": "te re",
    "y": " b. ey ra, aye ra"
  },
  {
    "x": "te ke",
    "y": " b. ter ke"
  },
  {
    "x": "teala",
    "y": " teala (yüksek olsun) (b. Ellah teala, Heq teala, Homa Teala, Rebî teala)"
  },
  {
    "x": "teba (n)",
    "y": " 1)nesne, şey\r\n~2)bir şey"
  },
  {
    "x": "tebayê",
    "y": " bir şey"
  },
  {
    "x": "tebayêk",
    "y": " bir şey"
  },
  {
    "x": "tebança (m)",
    "y": " b. dabançe (n)"
  },
  {
    "x": "tebaq (n)",
    "y": " şap hastalığı"
  },
  {
    "x": "tebat (n)",
    "y": " dayanma, dayanç, sabır, çıdam"
  },
  {
    "x": "tebat ci kewtene",
    "y": " dayanmak (tahammül etmek, sabretmek)"
  },
  {
    "x": "tebat dekewtene",
    "y": " dayanmak (tahammül etmek, sabretmek)"
  },
  {
    "x": "tebat dekotene",
    "y": " b. tebat dekewtene"
  },
  {
    "x": "tebat girewtene",
    "y": " b. tebat kerdene"
  },
  {
    "x": "tebat kerdene",
    "y": " dayanmak (tahammül etmek, sabretmek), tahammül etmek, durmak"
  },
  {
    "x": "tebatcikewtiş (n)",
    "y": " dayanma (tahammül etme, sabretme)"
  },
  {
    "x": "tebati (m)",
    "y": " b. tebat (n)"
  },
  {
    "x": "tebatdekewtiş (n)",
    "y": " dayanma (tahammül etme, sabretme)"
  },
  {
    "x": "tebatdekotiş (n)",
    "y": " b. tebatdekewtiş (n)"
  },
  {
    "x": "tebatkerdiş (n)",
    "y": " dayanma (tahammül etme, sabretme), tahammül etme, durma"
  },
  {
    "x": "tebatkerdox, -e",
    "y": " sabırlı"
  },
  {
    "x": "tebax-I (n)",
    "y": " b. tebaq (n)"
  },
  {
    "x": "tebax-II (n)",
    "y": " b. tebaxe-I (m)"
  },
  {
    "x": "tebaxça (m)",
    "y": " küçük tabak"
  },
  {
    "x": "tebaxe-I (m)",
    "y": " tabak"
  },
  {
    "x": "tebaxe-II (m)",
    "y": " ağustos"
  },
  {
    "x": "tebaxi (m)",
    "y": " b. tebaxe-I (m)"
  },
  {
    "x": "tebdîl (n)",
    "y": " dönüşüm\r\n~tebdîlo şaristankî (n): kentsel dönüşüm"
  },
  {
    "x": "tebdîl bîyene",
    "y": " dönüşmek"
  },
  {
    "x": "tebdîl kerdene",
    "y": " dönüştürmek"
  },
  {
    "x": "tebdîlbîyayîş (n)",
    "y": " dönüşme"
  },
  {
    "x": "tebdîlkerdiş (n)",
    "y": " dönüştürme"
  },
  {
    "x": "tebdîlkerdişê gemarinî",
    "y": " kirliliğin dönüştürülmesi"
  },
  {
    "x": "tebdîlo şaristankî (n)",
    "y": " kentsel dönüşüm"
  },
  {
    "x": "tebe (n)",
    "y": " b. teber (n)"
  },
  {
    "x": "tebelux (n)",
    "y": " tebellüğ (bildirimi alma)"
  },
  {
    "x": "tebeq-I (n)",
    "y": " 1)kat\r\n~2)tabaka"
  },
  {
    "x": "tebeqê binî (n)",
    "y": " alt kat"
  },
  {
    "x": "tebeqê dîyine (n)",
    "y": " ikinci kat"
  },
  {
    "x": "tebeqê ozonî (n)",
    "y": " ozon tabakası, ozonosfer"
  },
  {
    "x": "tebeqê serbanî (n)",
    "y": " çatı katı"
  },
  {
    "x": "tebeqê serî (n)",
    "y": " üst kat"
  },
  {
    "x": "tebeqo binên (n)",
    "y": " alt kat"
  },
  {
    "x": "tebeqo serên (n)",
    "y": " üst kat"
  },
  {
    "x": "tebeq-II (n)",
    "y": " b. tebaq (n)"
  },
  {
    "x": "tebeqa (m)",
    "y": " tabaka (tütün ya da sigara kutusu)"
  },
  {
    "x": "tebeqe-I (n)",
    "y": " b. tebeq (n)"
  },
  {
    "x": "tebeqe-II (m)",
    "y": " b. tebaxe-I (m)"
  },
  {
    "x": "tebeqin, -e",
    "y": " tabakalı"
  },
  {
    "x": "teber (n)",
    "y": " 1)dış, dışarı\r\n~berê teberî (n): dış kapı\r\n~2)hariç\r\n~To ra teber kesî nêva. (Sen hariç kimse söylemedi.)"
  },
  {
    "x": "berê teberî (n)",
    "y": " dış kapı"
  },
  {
    "x": "teber a",
    "y": " b. teber ra"
  },
  {
    "x": "teber a çoprokerdiş (n)",
    "y": " açığa çöp dökme"
  },
  {
    "x": "teber a çopveşnayîş",
    "y": " açık yakma"
  },
  {
    "x": "teber dayene",
    "y": " 1)dışa vurmak\r\n~2)uçuklamak "
  },
  {
    "x": "teber kerdene",
    "y": " 1)dışarıya çıkarmak\r\n~Keye ra teber ke! (Evden dışarıya çıkar!) \r\n~2)boşamak"
  },
  {
    "x": "teber kewtene",
    "y": " dışarı çıkmak"
  },
  {
    "x": "teber kêwtene",
    "y": " b. teber kewtene"
  },
  {
    "x": "teber kotene",
    "y": " b. teber kewtene"
  },
  {
    "x": "teber kowtene",
    "y": " b. teber kewtene"
  },
  {
    "x": "teber ra",
    "y": " dışarıda, dışarıdan"
  },
  {
    "x": "teber şîyene",
    "y": " tuvalet ihtiyacını gidermek"
  },
  {
    "x": "teber vecîyayene",
    "y": " b. teber vejîyayene"
  },
  {
    "x": "teber vecyayene",
    "y": " b. teber vejîyayene"
  },
  {
    "x": "teber vejîyayene",
    "y": " tuvalet ihtiyacını gidermek"
  },
  {
    "x": "teber vejyayene",
    "y": " b. teber vejîyayene"
  },
  {
    "x": "teber vicîyayene",
    "y": " b. teber vejîyayene"
  },
  {
    "x": "teber vicyayene",
    "y": " b. teber vejîyayene"
  },
  {
    "x": "teber vijîyayene",
    "y": " b. teber vijîyayene"
  },
  {
    "x": "teberê çaxî de bîyene",
    "y": " çağ dışı kalmak (askerlik için)"
  },
  {
    "x": "teberê çaxî de mende/a",
    "y": " çağ dışı"
  },
  {
    "x": "teberê çaxî de mendene",
    "y": " çağ dışı kalmak, çağ dışı olmak"
  },
  {
    "x": "teberê dewrî de mende/a",
    "y": " çağ dışı"
  },
  {
    "x": "teberê dewrî de mendene",
    "y": " çağ dışı kalmak, çağ dışı olmak"
  },
  {
    "x": "teberê teberan",
    "y": " en dış"
  },
  {
    "x": "tewr tebe",
    "y": " b. tewr teber"
  },
  {
    "x": "tewr teber",
    "y": " en dış"
  },
  {
    "x": "vetene teber",
    "y": " dışarıya çıkarmak"
  },
  {
    "x": "tebera, -ye",
    "y": " şımarık"
  },
  {
    "x": "teberayî (m)",
    "y": " şımarıklık"
  },
  {
    "x": "teberdayîş (n)",
    "y": " dışa vurma, dışavurum"
  },
  {
    "x": "teberdayox, -e",
    "y": " dışavurumcu"
  },
  {
    "x": "teberdayoxî (m)",
    "y": " dışavurumculuk"
  },
  {
    "x": "teberdayoxîye (m)",
    "y": " dışavurumculuk"
  },
  {
    "x": "tebereştiş (n)",
    "y": " ihraç"
  },
  {
    "x": "teberhoke (m)",
    "y": " dış açı"
  },
  {
    "x": "teberhokin, -e",
    "y": " dış açılı"
  },
  {
    "x": "teberkerdiş (n)",
    "y": " boşama"
  },
  {
    "x": "teberkî, -ye",
    "y": " abes (akla ve gerçeğe aykırı)"
  },
  {
    "x": "tebermekan (n)",
    "y": " dış mekân"
  },
  {
    "x": "teberra",
    "y": " b. tebera, -ye"
  },
  {
    "x": "teberşîyayîş (n)",
    "y": " tuvalet ihtiyacını giderme"
  },
  {
    "x": "tebervecyayîş (n)",
    "y": " b. tebervejîyayîş (n)"
  },
  {
    "x": "tebervejîyayîş (n)",
    "y": " tuvalet ihtiyacını giderme"
  },
  {
    "x": "tebervejyayîş (n)",
    "y": " b. tebervejîyayîş (n)"
  },
  {
    "x": "tebervicîyayîş (n)",
    "y": " b. tebervejîyayîş (n)"
  },
  {
    "x": "tebervicyayîş (n)",
    "y": " b. tebervejîyayîş (n)"
  },
  {
    "x": "tebervijîyayîş (n)",
    "y": " b. tebervejîyayîş (n)"
  },
  {
    "x": "tebervejyayîş (n)",
    "y": " b. tebervejîyayîş (n)"
  },
  {
    "x": "tebeşîr (n)",
    "y": " tebeşir"
  },
  {
    "x": "tebetîyayene",
    "y": " b. tebitîyayene"
  },
  {
    "x": "tebetîyayîş (n)",
    "y": " b. tebitîyayîş (n)"
  },
  {
    "x": "tebetnayene",
    "y": " b. tebitnayene"
  },
  {
    "x": "tebetnayîş (n)",
    "y": " b. tebitnayîş (n)"
  },
  {
    "x": "tebiq (n)",
    "y": " b. tebaq (n)"
  },
  {
    "x": "tebiştiş (n)",
    "y": " b. tepîştiş (n)"
  },
  {
    "x": "tebitîyayene",
    "y": " 1)dayanmak (tahammül etmek, sabretmek), durmak\r\n~2)barınmak"
  },
  {
    "x": "tebitîyayîş (n)",
    "y": " 1)dayanma (tahammül etme, sabretme), durma\r\n~2)barınma"
  },
  {
    "x": "tebitnayene",
    "y": " barındırmak"
  },
  {
    "x": "tebitnayîş (n)",
    "y": " barındırma"
  },
  {
    "x": "tebîet (n)",
    "y": " doğa, tabiat"
  },
  {
    "x": "tebîî, -ye",
    "y": " doğal, tabii"
  },
  {
    "x": "tebîr (n)",
    "y": " deyim, tabir"
  },
  {
    "x": "tebîştiş (n)",
    "y": " b. tepîştiş (n)"
  },
  {
    "x": "tebla (m)",
    "y": " b. tawla (m)"
  },
  {
    "x": "teblîx (n)",
    "y": " bildirme, haber verme, tebliğ"
  },
  {
    "x": "teblîx kerdene",
    "y": " bildirmek, haber vermek, tebliğ etmek"
  },
  {
    "x": "teblîxat (n)",
    "y": " bildirim, tebligat"
  },
  {
    "x": "teblîxatgirewtiş (n)",
    "y": " tebellüğ (bildirimi alma)"
  },
  {
    "x": "tebrayî (m)",
    "y": " b. teberayî (m)"
  },
  {
    "x": "techîzat (n)",
    "y": " donatı, teçhizat"
  },
  {
    "x": "tecîl (n)",
    "y": " tecil"
  },
  {
    "x": "tecîl kerdene",
    "y": " tecil etmek"
  },
  {
    "x": "tecîlkerdiş (n)",
    "y": " tecil etme"
  },
  {
    "x": "tecribe (n)",
    "y": " b. tecrube (n)"
  },
  {
    "x": "tecribe kerdene",
    "y": " b. tecrube kerdene"
  },
  {
    "x": "tecribekerdiş (n)",
    "y": " b. tecrubekerdiş (n)"
  },
  {
    "x": "tecrîd (n)",
    "y": " 1)izolasyon, yalıtım\r\n~2) tecrit"
  },
  {
    "x": "tecrube (n)",
    "y": " deneyim, tecrübe"
  },
  {
    "x": "tecrube kerdene",
    "y": " sınamak"
  },
  {
    "x": "tecrubekerdiş (n)",
    "y": " sınama"
  },
  {
    "x": "tecrubî, -ye",
    "y": " deneysel, tecrübi, ampirik"
  },
  {
    "x": "teda (m)",
    "y": " baskı, eziyet"
  },
  {
    "x": "tedaker, -e",
    "y": " baskıcı"
  },
  {
    "x": "tedawî (m)",
    "y": " sağaltım, tedavi"
  },
  {
    "x": "tedawîker, -e",
    "y": " sağaltıcı, sağaltımcı, terapist"
  },
  {
    "x": "tedawul (n)",
    "y": " sürüm (ekonomi için)"
  },
  {
    "x": "tedbîr (n)",
    "y": " önlem, tedbir"
  },
  {
    "x": "tedbîr girewtene",
    "y": " önlem almak, tedbir almak"
  },
  {
    "x": "tedbîrê îhtîyatî (n)",
    "y": " ihtiyati tedbir"
  },
  {
    "x": "tede",
    "y": " 1)içinde\r\n~2)beraber, birlikte"
  },
  {
    "x": "tede bîyene",
    "y": " (içinde) bulunmak\r\n~Bano ke ma par tede bîy, emser rijîya. (Geçen yıl içinde bulunduğumuz ev bu yıl yıkıldı.)\r\n~\t\r\n~Menga ke ma tede yê zaf serd o. (İçinde bulunduğumuz ay çok soğuk.)"
  },
  {
    "x": "tede dejayene",
    "y": " b. ... de dejayene"
  },
  {
    "x": "tede fele mendene",
    "y": " (mecazi) kusuru kalmak, eksiği kalmak"
  },
  {
    "x": "tede fele verdayene",
    "y": " (mecazi) kusurlu bırakmak, eksik bırakmak"
  },
  {
    "x": "tede hol bîyene",
    "y": " (kadınla) cinsel ilişkide bulunmak"
  },
  {
    "x": "tede kar kerdene",
    "y": " etkilemek, tesir etmek"
  },
  {
    "x": "tede kute/a",
    "y": " çakılı"
  },
  {
    "x": "tede kutene",
    "y": " çakmak"
  },
  {
    "x": "tede mendene",
    "y": " 1)içinde kalmak\r\n~2)tereddüt etmek"
  },
  {
    "x": "tede ra mendene",
    "y": " b. tede ray mendene"
  },
  {
    "x": "tede ray mendene",
    "y": " geçirmek, uğurlamak (bir yere kadar birlikte yürüyerek uğurlamak)\r\n~Çi kes tede ray nêmend. (Hiç kimse -onu- geçirmedi./Hiç kimse uğurlamadı.)"
  },
  {
    "x": "tede rê mendene",
    "y": " b. tede ray mendene"
  },
  {
    "x": "tede şa bîyene",
    "y": " 1)-de neşelenmek, -de mutlu olmak\r\n~2)(kadınla) cinsel ilişkide bulunmak"
  },
  {
    "x": "tede şîyayene (war)",
    "y": " b. ... de şîyene"
  },
  {
    "x": "tede tey vetene",
    "y": " hissetmek, sezinlemek, sezmek"
  },
  {
    "x": "tede vejîyayene",
    "y": " anlaşılmak"
  },
  {
    "x": "tede vetene",
    "y": " 1)hissetmek, sezinlemek, sezmek\r\n~2)anlamak"
  },
  {
    "x": "tedebîyayîş (n)",
    "y": " (içinde) bulunma"
  },
  {
    "x": "tedefelemendiş (n)",
    "y": " tarla sürerken karasabanın giremediği/girmediği engebeli yerin sürülemeden kalması"
  },
  {
    "x": "tedefeleverdayîş (n)",
    "y": " tarla sürerken karasabanın giremediği/girmediği sürülememiş yer bırakma"
  },
  {
    "x": "tedeholbîyayîş (n)",
    "y": " (kadınla) cinsel ilişkide bulunma"
  },
  {
    "x": "tedekutiş (n)",
    "y": " çakma"
  },
  {
    "x": "tedemendiş (n)",
    "y": " 1)içinde kalma\r\n~2)tereddüt etme"
  },
  {
    "x": "teder",
    "y": " b. tede"
  },
  {
    "x": "tederamendiş (n)",
    "y": " b. tederaymendiş (n)"
  },
  {
    "x": "tederaymendiş (n)",
    "y": " geçirme, uğurlama (bir yere kadar birlikte yürüyerek uğurlama)"
  },
  {
    "x": "tederêmendiş (n)",
    "y": " b. tederaymendiş (n)"
  },
  {
    "x": "tedeşabîyayîş (n)",
    "y": " 1)-de neşelenme, -de mutlu olma\r\n~2)(kadınla) cinsel ilişkide bulunma"
  },
  {
    "x": "tedeşîyayîş (war)",
    "y": " b. ... deşîyayîş"
  },
  {
    "x": "tedeteyvetiş (n)",
    "y": " hissetme, sezinleme, sezme"
  },
  {
    "x": "tedevejîyayîş (n)",
    "y": " anlaşılma"
  },
  {
    "x": "tedevetiş (n)",
    "y": " 1)hissetme, sezinleme, sezme\r\n~2)anlama"
  },
  {
    "x": "tedeyî (zh)",
    "y": " içindekiler (kitap vb. için), münderecat, mündericat"
  },
  {
    "x": "tedi",
    "y": " b. tede"
  },
  {
    "x": "tedir",
    "y": " b. tede"
  },
  {
    "x": "tedîlat (n)",
    "y": " tadilat"
  },
  {
    "x": "tedîlatê awanî (n)",
    "y": " imar tadilatı"
  },
  {
    "x": "teera (m)",
    "y": " b. teyara (m)"
  },
  {
    "x": "teesub (n)",
    "y": " bağnazlık, fanatizm, fanatiklik"
  },
  {
    "x": "tef û tal (n)",
    "y": " b. tef-tal (n)"
  },
  {
    "x": "tef-tal (n)",
    "y": " eşya"
  },
  {
    "x": "teehudname (n)",
    "y": " taahhütname"
  },
  {
    "x": "teferuat (n)",
    "y": " ayrıntı, detay, teferruat"
  },
  {
    "x": "tefênayene",
    "y": " söndürmek"
  },
  {
    "x": "tefênayîş (n)",
    "y": " söndürme"
  },
  {
    "x": "tefildimûn (n)",
    "y": " b. tefûduman (n)"
  },
  {
    "x": "tefinayene",
    "y": " b. tefênayene"
  },
  {
    "x": "tefinayîş (n)",
    "y": " b. tefênayîş (n)"
  },
  {
    "x": "tefînayene",
    "y": " b. tefênayene"
  },
  {
    "x": "tefînayîş (n)",
    "y": " b. tefênayîş (n)"
  },
  {
    "x": "tefrîqa (m)",
    "y": " tefrika"
  },
  {
    "x": "tefsîr (n)",
    "y": " yorum "
  },
  {
    "x": "tefsîr kerdene",
    "y": " yorum yapmak, yorumlamak"
  },
  {
    "x": "tefsîrker, -e",
    "y": " yorumcu"
  },
  {
    "x": "tefsîrkerênî (m)",
    "y": " yorumculuk"
  },
  {
    "x": "tefsîrkerî (m)",
    "y": " yorumculuk"
  },
  {
    "x": "tefsîrkerîye (m)",
    "y": " yorumculuk"
  },
  {
    "x": "teftîş (n)",
    "y": " denetleme, denetim, teftiş; kontrol"
  },
  {
    "x": "teftîş bîyene",
    "y": " denetlenmek"
  },
  {
    "x": "teftîş kerdene",
    "y": " denetlemek, teftiş etmek"
  },
  {
    "x": "teftîşê gemarinîya hewayî (n)",
    "y": " hava kirliliği denetimi"
  },
  {
    "x": "teftîşbîyayîş (n)",
    "y": " denetlenme"
  },
  {
    "x": "teftîşker, -e",
    "y": " denetleyici, denetçi "
  },
  {
    "x": "teftîşkerdiş (n)",
    "y": " denetleme"
  },
  {
    "x": "tefûduman (n)",
    "y": " fırtına"
  },
  {
    "x": "tefûdumon (n)",
    "y": " b. tefûduman (n)"
  },
  {
    "x": "tefûdumûn (n)",
    "y": " b. tefûduman (n)"
  },
  {
    "x": "tegel (n)",
    "y": " teyel, teğel"
  },
  {
    "x": "tegel kerdene",
    "y": " teyel yapmak, teyel atmak, teyellemek"
  },
  {
    "x": "tegelin, -e",
    "y": " teyelli"
  },
  {
    "x": "tegelkerda (m)",
    "y": " teyelli"
  },
  {
    "x": "tegelkerde (n)",
    "y": " teyelli"
  },
  {
    "x": "tegelkerdîye (m)",
    "y": " teyelli"
  },
  {
    "x": "Tegucîgalpa (m)",
    "y": " Tegucigalpa"
  },
  {
    "x": "tehda (m)",
    "y": " b. teda (m)"
  },
  {
    "x": "teheffuz (n)",
    "y": " çekince"
  },
  {
    "x": "teheffuz mojnayene",
    "y": " çekince koymak"
  },
  {
    "x": "teheffuz musnayene",
    "y": " çekince koymak"
  },
  {
    "x": "teheffuz nawitene",
    "y": " çekince koymak"
  },
  {
    "x": "tehemul (n)",
    "y": " tahammül"
  },
  {
    "x": "tehemul kerdene",
    "y": " dayanmak (tahammül etmek, sabretmek), tahammül etmek"
  },
  {
    "x": "tehemuldar, -e",
    "y": " tahammüllü"
  },
  {
    "x": "tehemulkerdiş (n)",
    "y": " dayanma (tahammül etme, sabretme), tahammül etme"
  },
  {
    "x": "tehemulkerdox, -e",
    "y": " tahammüllü"
  },
  {
    "x": "tehen (m)",
    "y": " b. tanî-I (m)"
  },
  {
    "x": "tehequq (n)",
    "y": " tahakkuk"
  },
  {
    "x": "tehernayîne",
    "y": " b. tehnayene"
  },
  {
    "x": "tehernayîş (n)",
    "y": " b. tehnayîş (n)"
  },
  {
    "x": "tehewul (n)",
    "y": " dönüşme"
  },
  {
    "x": "tehfîl kerdene",
    "y": " dezenfekte etmek"
  },
  {
    "x": "tehfîlker, -e",
    "y": " dezenfektan"
  },
  {
    "x": "tehfîlkero gazin (n)",
    "y": " gaz dezenfektan"
  },
  {
    "x": "tehfîlkerde (n)",
    "y": " dezenfekte"
  },
  {
    "x": "tehfîlkerdiş (n)",
    "y": " dezenfekte etme"
  },
  {
    "x": "tehfîlkerdîye (m)",
    "y": " dezenfekte"
  },
  {
    "x": "tehisiknayene",
    "y": " b. tehisnayene"
  },
  {
    "x": "tehisiknayîş (n)",
    "y": " b. tehisnayîş (n)"
  },
  {
    "x": "tehisnayene",
    "y": " kaydırmak"
  },
  {
    "x": "tehisnayîş (n)",
    "y": " kaydırma"
  },
  {
    "x": "tehîn (m)",
    "y": " b. tahîn (m)"
  },
  {
    "x": "tehkîye (m)",
    "y": " anlatı, öyküleme, tahkiye"
  },
  {
    "x": "tehl, -e",
    "y": " b. tal, -e-I"
  },
  {
    "x": "tehleki (m)",
    "y": " b. tehlîyike (m)"
  },
  {
    "x": "tehlike (n)",
    "y": " b. tehluke (n)"
  },
  {
    "x": "tehlikeyin",
    "y": " b. tehlukeyin, -e"
  },
  {
    "x": "tehlî (m)",
    "y": " b. talîye (m)"
  },
  {
    "x": "tehlîl (n)",
    "y": " analiz, çözümleme, tahlil"
  },
  {
    "x": "tehlîlê dayîyan",
    "y": " verilerin analizi"
  },
  {
    "x": "tehlîlê doneyan",
    "y": " verilerin analizi"
  },
  {
    "x": "tehlîlê fîlmî (n)",
    "y": " film analizi"
  },
  {
    "x": "tehlîlê halê mewcûdî (n)",
    "y": " mevcut durum analizi"
  },
  {
    "x": "tehlîlê karakteran (n)",
    "y": " karakter çözümlemeleri"
  },
  {
    "x": "tehlîlê programî (n)",
    "y": " program analizi"
  },
  {
    "x": "tehlîlo îqtîsadî (n)",
    "y": " ekonomik analiz, iktisadi analiz"
  },
  {
    "x": "tehlîyik (m)",
    "y": " b. tehlîyike (m)"
  },
  {
    "x": "tehlîyike (m)",
    "y": " bir üzüm çeşidi"
  },
  {
    "x": "tehluke (n)",
    "y": " tehlike"
  },
  {
    "x": "tehlukeyo kîmyayî (n)",
    "y": " kimyasal tehlike"
  },
  {
    "x": "tehlukeyo nukleer (n)",
    "y": " nükleer tehlike"
  },
  {
    "x": "tehlukeyo radyolojîk (n)",
    "y": " radyolojik tehlike"
  },
  {
    "x": "tehlukedar, -e",
    "y": " tehlikeli"
  },
  {
    "x": "tehlukeyin, -e",
    "y": " tehlikeli"
  },
  {
    "x": "tehlûke (n)",
    "y": " b. tehluke (n)"
  },
  {
    "x": "tehlûkeyin, -e",
    "y": " b. tehlukeyin, -e"
  },
  {
    "x": "tehm-I (n)",
    "y": " tat, lezzet"
  },
  {
    "x": "tehm kerdene",
    "y": " tatmak, tadına bakmak"
  },
  {
    "x": "tehm-II (n)",
    "y": " aşı (bitki için)"
  },
  {
    "x": "tehm pirodayene",
    "y": " aşılamak (bitki için)"
  },
  {
    "x": "tehm pirodîyayene",
    "y": " aşılanmak (bitki için)"
  },
  {
    "x": "tehmkor, -e",
    "y": " tatsız, lezzetsiz"
  },
  {
    "x": "tehmkorênî (m)",
    "y": " tatsızlık, lezzetsizlik"
  },
  {
    "x": "tehmkorî (m)",
    "y": " tatsızlık, lezzetsizlik"
  },
  {
    "x": "tehmkorîye (m)",
    "y": " tatsızlık, lezzetsizlik"
  },
  {
    "x": "tehmnayene-I",
    "y": " tatmak, tadına bakmak"
  },
  {
    "x": "tehmnayene-II",
    "y": " aşılamak (bitki için)"
  },
  {
    "x": "tehmnayîş (n)",
    "y": " aşılama (bitki için)"
  },
  {
    "x": "tehmnîyayene",
    "y": " aşılanmak (bitki için)"
  },
  {
    "x": "tehmnîyayîş (n)",
    "y": " aşılanma (bitki için)"
  },
  {
    "x": "tehmpirodayîş (n)",
    "y": " aşılama (bitki için)"
  },
  {
    "x": "tehmpirodîyayîş (n)",
    "y": " aşılanma (bitki için)"
  },
  {
    "x": "tehmsar, -e",
    "y": " tatsız, lezzetsiz"
  },
  {
    "x": "tehmsarî (m)",
    "y": " tatsızlık, lezzetsizlik"
  },
  {
    "x": "tehmsarîye (m)",
    "y": " tatsızlık, lezzetsizlik"
  },
  {
    "x": "tehmserd, -e",
    "y": " tatsız, lezzetsiz"
  },
  {
    "x": "tehmserdî (m)",
    "y": " tatsızlık, lezzetsizlik"
  },
  {
    "x": "tehmserdîye (m)",
    "y": " tatsızlık, lezzetsizlik"
  },
  {
    "x": "tehn (m)",
    "y": " b. tanî-I (m)"
  },
  {
    "x": "tehnayene",
    "y": " öğütmek"
  },
  {
    "x": "tehnayîne",
    "y": " b. tehnayene"
  },
  {
    "x": "tehnayîş (n)",
    "y": " öğütme"
  },
  {
    "x": "tehnebî (m)",
    "y": " tahannebi üzümü"
  },
  {
    "x": "tehnî (m)",
    "y": " b. tanî-I (m)"
  },
  {
    "x": "tehqîqat (n)",
    "y": " soruşturma, tahkik, tahkikat"
  },
  {
    "x": "tehqîqat kerdene",
    "y": " soruşturmak, tahkikat yapmak"
  },
  {
    "x": "tehqîqat viraştene",
    "y": " soruşturmak, tahkikat yapmak"
  },
  {
    "x": "tehqîqatkerdox, -e",
    "y": " soruşturmacı, muhakkik Tehran (n).Tahran"
  },
  {
    "x": "tehsiknayene",
    "y": " b. tehisnayene"
  },
  {
    "x": "tehsiknayîş (n)",
    "y": " b. tehisnayîş (n)"
  },
  {
    "x": "tehsinayene",
    "y": " b. tehisnayene"
  },
  {
    "x": "tehsinayîş (n)",
    "y": " b. tehisnayîş (n)"
  },
  {
    "x": "tehsîl kerdene",
    "y": " üretmek, imal etmek"
  },
  {
    "x": "tehsîlat (n)",
    "y": " tahsilat"
  },
  {
    "x": "tehsîlatê awe",
    "y": " su tahsilat"
  },
  {
    "x": "tehsîldar, -e",
    "y": " tahsildar"
  },
  {
    "x": "tehsîldarey (m)",
    "y": " b. tehsîldarî (m)"
  },
  {
    "x": "tehsîldarê (m)",
    "y": " b. tehsîldarî (m)"
  },
  {
    "x": "tehsîldarî (m)",
    "y": " tahsildarlık"
  },
  {
    "x": "tehsîldarîye (m)",
    "y": " tahsildarlık"
  },
  {
    "x": "tehsîlkerdiş (n)",
    "y": " 1)üretme, imal etme\r\n~2)tahsilat"
  },
  {
    "x": "tehsîlkerdişê awe",
    "y": " su tahsilat"
  },
  {
    "x": "teht (m)",
    "y": " b. tehte (m)"
  },
  {
    "x": "tehte (m)",
    "y": " üzeri düz olan büyük ve geniş taş"
  },
  {
    "x": "tehta çarî (m)",
    "y": " b. tehta çareyî (m)"
  },
  {
    "x": "tehta çareyî (m)",
    "y": " alın kemiği"
  },
  {
    "x": "tehta sênî (m)",
    "y": " göğüs kemiği"
  },
  {
    "x": "tehta sole (m)",
    "y": " üzerinde hayvanlara tuz yedirilen düz, büyük ve geniş taş"
  },
  {
    "x": "tehti (m)",
    "y": " b. tehte (m)"
  },
  {
    "x": "tehtîl (n)",
    "y": " tatil"
  },
  {
    "x": "tehtîlo resmî (n)",
    "y": " resmî tatil"
  },
  {
    "x": "tehwîl kerdene",
    "y": " dönüştürmek"
  },
  {
    "x": "tehwîlkerdiş (n)",
    "y": " dönüştürme"
  },
  {
    "x": "tehwîlkerdişê gemarinî",
    "y": " kirliliğin dönüştürülmesi"
  },
  {
    "x": "tejîye (m)",
    "y": " b. tezîye-I (m)"
  },
  {
    "x": "tejîr, -e",
    "y": " b. têcîr, -e"
  },
  {
    "x": "tek",
    "y": " 1)tek (mat)\r\n~2)salt\r\n~3)hayvana yüklenen odun vb. yükünde iki yandaki odun vb. dizilerinden her biri"
  },
  {
    "x": "tek û çift",
    "y": " b. tek û çut"
  },
  {
    "x": "tek û çit",
    "y": " b. tek û çut"
  },
  {
    "x": "tek û çut",
    "y": " tek ve çift (mat)"
  },
  {
    "x": "tek-çift",
    "y": " b. tek-çut"
  },
  {
    "x": "tek-çit",
    "y": " b. tek-çut"
  },
  {
    "x": "tek-çut",
    "y": " tek-çift"
  },
  {
    "x": "tek-tena",
    "y": " yapayalnız"
  },
  {
    "x": "tek-tenya",
    "y": " b. tek-tena"
  },
  {
    "x": "tek-teyna",
    "y": " b. tek-tena"
  },
  {
    "x": "tekasuf (n)",
    "y": " yoğunlaşma (yoğun duruma gelme), tekâsüf"
  },
  {
    "x": "tekasuf bîyene",
    "y": " yoğunlaşmak (yoğun duruma gelmek)"
  },
  {
    "x": "teke-I (m)",
    "y": " cüzdan (para cüzdanı)"
  },
  {
    "x": "teke-II (m)",
    "y": " b. tek-1"
  },
  {
    "x": "tekeleke (m)",
    "y": " tekerlek, teker"
  },
  {
    "x": "tekeleki (m)",
    "y": " b. tekeleke (m)"
  },
  {
    "x": "teker (m)",
    "y": " b. tekere (m) "
  },
  {
    "x": "tekere (m)",
    "y": " tekerlek, teker"
  },
  {
    "x": "tekera cagire (m)",
    "y": " yedek teker, stepne"
  },
  {
    "x": "tekeri (m)",
    "y": " b. tekere (m) "
  },
  {
    "x": "tekhûmare (m)",
    "y": " tek sayı (1, 3, 5...)"
  },
  {
    "x": "teki-I",
    "y": " b. tek-3"
  },
  {
    "x": "teki-II (m)",
    "y": " b. teke (m)"
  },
  {
    "x": "teklîf (n)",
    "y": " öneri, teklif"
  },
  {
    "x": "teklîfê qanûnî (n)",
    "y": " yasa önerisi, kanun teklifi"
  },
  {
    "x": "tekna (m)",
    "y": " tekne"
  },
  {
    "x": "teknike (m)",
    "y": " b. tekna (m)"
  },
  {
    "x": "teknîk (n) (nm)",
    "y": " teknik "
  },
  {
    "x": "teknîkê arêdayîşê dayîyan",
    "y": " veri toplama teknikleri"
  },
  {
    "x": "teknîkê arêdayîşê doneyan",
    "y": " veri toplama teknikleri"
  },
  {
    "x": "teknîker, -e",
    "y": " 1)teknikçi\r\n~2)tekniker"
  },
  {
    "x": "teknîkera/ê elektrîkî",
    "y": " elektrik teknikeri"
  },
  {
    "x": "teknîkera/ê makîna",
    "y": " makine teknikeri "
  },
  {
    "x": "teknîkerî (m)",
    "y": " teknikerlik"
  },
  {
    "x": "teknîkerîya xerîta û kadastroyî",
    "y": " harita kadastro teknikerliği"
  },
  {
    "x": "teknîkerîye (m)",
    "y": " teknikerlik"
  },
  {
    "x": "teknîkî, -ye (s)",
    "y": " teknik"
  },
  {
    "x": "teknîsyen, -e",
    "y": " 1)teknikçi\r\n~2)teknisyen"
  },
  {
    "x": "teknîsyena/ê elektrîkî",
    "y": " elektrik teknisyeni"
  },
  {
    "x": "teknîsyena/ê erdî (n)",
    "y": " yer teknisyeni (havayolu trafiği için)"
  },
  {
    "x": "teknîsyena/ê firayîşî (n)",
    "y": " uçuş teknisyeni"
  },
  {
    "x": "teknîsyena/ê makîna",
    "y": " makine teknisyeni"
  },
  {
    "x": "teknîsyena/ê vengî",
    "y": " ses teknisyeni"
  },
  {
    "x": "teknîsyenî (m)",
    "y": " teknisyenlik"
  },
  {
    "x": "teknîsyenîye (m)",
    "y": " teknisyenlik"
  },
  {
    "x": "teknolojî (n)",
    "y": " teknoloji"
  },
  {
    "x": "Teknolojîyê Enformasyonî (n)",
    "y": " Enformasyon Teknolojisi"
  },
  {
    "x": "teknolojîk, -e",
    "y": " teknolojik"
  },
  {
    "x": "tekrar",
    "y": " yine, gene, tekrar"
  },
  {
    "x": "teksîr kerdene",
    "y": " çoğaltmak, artırmak"
  },
  {
    "x": "teksîrbîyayîş (n)",
    "y": " çoğalma"
  },
  {
    "x": "teksîrker (n)",
    "y": " çoğaltıcı"
  },
  {
    "x": "teksîrkerdiş (n)",
    "y": " çoğaltma, artırma"
  },
  {
    "x": "tekst (n)",
    "y": " metin, tekst"
  },
  {
    "x": "tekstîl (n)",
    "y": " tekstil, mensucat"
  },
  {
    "x": "tektaş",
    "y": " ters\r\n~Lajekî solê xo tektaş pay kerdê. (Oğlan ayakkabılarını ters giymiş.)"
  },
  {
    "x": "tekûtena",
    "y": " yapayalnız"
  },
  {
    "x": "tekûtenya",
    "y": " b. tekûtena"
  },
  {
    "x": "tekûteyna",
    "y": " b. tekûtena"
  },
  {
    "x": "tekzîb kerdene",
    "y": " tekzip etmek"
  },
  {
    "x": "tekzîbkerdiş (n)",
    "y": " tekzip, yalanlama"
  },
  {
    "x": "tela",
    "y": " b. teala"
  },
  {
    "x": "telal, -e",
    "y": " b. delal, -e"
  },
  {
    "x": "telaq (n)",
    "y": " boşama, talak"
  },
  {
    "x": "telaqê ... eştene",
    "y": " boşamak"
  },
  {
    "x": "telaqê ... eştiş",
    "y": " boşama"
  },
  {
    "x": "telaqê ... vistene",
    "y": " b. telaqê ... eştene"
  },
  {
    "x": "telaqê ... vistiş",
    "y": " b. telaqê ... eştiş"
  },
  {
    "x": "telaqî ser o sonddayene",
    "y": " (birisine) nikahı üzerine yemin içirmek"
  },
  {
    "x": "telaşe (n)",
    "y": " kabuk"
  },
  {
    "x": "telaşe eştene",
    "y": " kavlamak, kabuğu kabarıp dökülmek, kabuğu kabarıp soyulmak"
  },
  {
    "x": "telaşe fîştene",
    "y": " kavlamak, kabuğu kabarıp dökülmek, kabuğu kabarıp soyulmak"
  },
  {
    "x": "telaşe kerdene",
    "y": " kabuğunu soymak"
  },
  {
    "x": "telaşeeştiş (n)",
    "y": " kavlama"
  },
  {
    "x": "telaşefîştiş (n)",
    "y": " kavlama"
  },
  {
    "x": "tele (m)",
    "y": " b. têle-I (m)"
  },
  {
    "x": "teleb (m)",
    "y": " b. telebe (m)"
  },
  {
    "x": "teleb ameyene",
    "y": " b. teleb bîyene"
  },
  {
    "x": "teleb bîyene",
    "y": " kösnümek (at için)"
  },
  {
    "x": "teleba (m)",
    "y": " öğrenci, talebe"
  },
  {
    "x": "telebbîyayîş (n)",
    "y": " kösnüme (at için)"
  },
  {
    "x": "telebe-I (n)",
    "y": " öğrenci, talebe"
  },
  {
    "x": "telebe-II (m)",
    "y": " kösnük (at için)"
  },
  {
    "x": "telebeyîye (m)",
    "y": " öğrencilik, talebelik"
  },
  {
    "x": "telebi (m)",
    "y": " b. telebe-II (m)"
  },
  {
    "x": "telebîye (m)",
    "y": " öğrenci, talebe"
  },
  {
    "x": "telebîzyon (n)",
    "y": " b. televîzyon (n)"
  },
  {
    "x": "telebzîyon (n)",
    "y": " b. televîzyon (n)"
  },
  {
    "x": "telef (n)",
    "y": " telef"
  },
  {
    "x": "telef bîyene",
    "y": " (boş yere harcanmak) harcanmak, telef olmak"
  },
  {
    "x": "telef kerdene",
    "y": " (boş yere harcamak) harcamak, telef etmek"
  },
  {
    "x": "telefbîyayîş (n)",
    "y": " (boş yere harcanma) harcanma, telef olma"
  },
  {
    "x": "telefin (n)",
    "y": " b. telefon (n)"
  },
  {
    "x": "telefîzon (n)",
    "y": " televizyon"
  },
  {
    "x": "telefkerdiş (n)",
    "y": " (boş yere harcama) harcama, telef etme"
  },
  {
    "x": "telefon (n)",
    "y": " telefon"
  },
  {
    "x": "telefon akerdene",
    "y": " telefon açmak"
  },
  {
    "x": "telefon kerdene",
    "y": " telefon etmek"
  },
  {
    "x": "telefon rakerdene",
    "y": " telefon açmak"
  },
  {
    "x": "telefonê destan (n)",
    "y": " cep telefonu"
  },
  {
    "x": "telefonî reyra muracat (n)",
    "y": " telefonla başvuru"
  },
  {
    "x": "telefonometre (n)",
    "y": " telefonometre"
  },
  {
    "x": "telefu (n)",
    "y": " b. telefon (n)"
  },
  {
    "x": "telefun (n)",
    "y": " b. telefon (n)"
  },
  {
    "x": "telefû (n)",
    "y": " b. telefon (n)"
  },
  {
    "x": "telefûn (n)",
    "y": " b. telefon (n)"
  },
  {
    "x": "telekêna (m)",
    "y": " telekız"
  },
  {
    "x": "telekomunîkasyon (n)",
    "y": " telekomünikasyon"
  },
  {
    "x": "teleqîyene",
    "y": " b. teliqîyene"
  },
  {
    "x": "televe (n)",
    "y": " b. telebe (n)"
  },
  {
    "x": "televênî (m)",
    "y": " b. telebeyîye (m)"
  },
  {
    "x": "televîye (m)",
    "y": " b. telebîye (m)"
  },
  {
    "x": "televizûn (n)",
    "y": " b. televîzyon (n)"
  },
  {
    "x": "televîzuni (m)",
    "y": " b. televîzyon (n)"
  },
  {
    "x": "televîzyon (n)",
    "y": " televizyon"
  },
  {
    "x": "televîzyoni (m)",
    "y": " b. televîzyon (n)"
  },
  {
    "x": "televîzyonsaz, -e",
    "y": " televizyon tamircisi"
  },
  {
    "x": "televzon (n)",
    "y": " b. televîzyon (n)"
  },
  {
    "x": "telgraf (n)",
    "y": " telgraf"
  },
  {
    "x": "telgraf antene",
    "y": " telgraf çekmek"
  },
  {
    "x": "teli (n)",
    "y": " b. telî (n)"
  },
  {
    "x": "telik (n)",
    "y": " b. terlik (n)"
  },
  {
    "x": "teliqîyayene",
    "y": " b. teliqîyene"
  },
  {
    "x": "teliqîyayeni",
    "y": " b. teliqîyene"
  },
  {
    "x": "teliqîyene",
    "y": " (dişi köpek için) doğurmak, eniklemek  "
  },
  {
    "x": "telî (n)",
    "y": " diken"
  },
  {
    "x": "telîy marî (n)",
    "y": " b. telîyê marî (n)"
  },
  {
    "x": "telîyê marî (n)",
    "y": " yılan iskeleti"
  },
  {
    "x": "telîyê masî (n)",
    "y": " çopra (balık kılçığı)"
  },
  {
    "x": "telîmat (n)",
    "y": " talimat"
  },
  {
    "x": "telîmatname (n)",
    "y": " talimatname, yönetmelik"
  },
  {
    "x": "telîmatnameyê awanî (n)",
    "y": " imar yönetmeliği"
  },
  {
    "x": "telîmatnameyê awguznayîşê gendawe yo şaristankî (n)",
    "y": " kentsel atık su arıtımı yönetmeliği"
  },
  {
    "x": "telîmatnameyê kontrolê gemarinîya awe (n)",
    "y": " su kirliliği kontrolü yönetmeliği"
  },
  {
    "x": "telîmatnameyê kontrolê gemarinîya herre (n)",
    "y": " toprak kirliliği kontrolü yönetmeliği"
  },
  {
    "x": "telîs (n)",
    "y": " b. telîse (m)"
  },
  {
    "x": "telîse (m)",
    "y": " telis"
  },
  {
    "x": "telîye (m)",
    "y": " b. terrîye (m)"
  },
  {
    "x": "telîyen, -i",
    "y": " b. telîyin, -e"
  },
  {
    "x": "telîyên, -i",
    "y": " b. telîyin, -e"
  },
  {
    "x": "telîyin, -e",
    "y": " dikenli"
  },
  {
    "x": "tellahî",
    "y": " b. tillayî"
  },
  {
    "x": "telle",
    "y": " b. tillayî"
  },
  {
    "x": "telleqîyayene",
    "y": " b. teliqîyene"
  },
  {
    "x": "telli (n)",
    "y": " b. telî (n)"
  },
  {
    "x": "telliqîyayene",
    "y": " b. teliqîyene"
  },
  {
    "x": "tellî (n)",
    "y": " b. telî (n)"
  },
  {
    "x": "tellu (n)",
    "y": " b. telî (n)"
  },
  {
    "x": "telluy marî (n)",
    "y": " b. telîyê marî (n)"
  },
  {
    "x": "tellû (n)",
    "y": " b. telî (n)"
  },
  {
    "x": "telpê daran (n)",
    "y": " b. tilpê daran (n)"
  },
  {
    "x": "telpê hewrî (n)",
    "y": " b. tilpê hewrî (n)"
  },
  {
    "x": "telqeraf (n)",
    "y": " b. telgraf (n)"
  },
  {
    "x": "telqiraf (n)",
    "y": " b. telgraf (n)"
  },
  {
    "x": "telxiraf (n)",
    "y": " b. telgraf (n)"
  },
  {
    "x": "teltur (n)",
    "y": " b. tertul (n)"
  },
  {
    "x": "telu (n)",
    "y": " b. telî (n)"
  },
  {
    "x": "teluy marî (n)",
    "y": " b. telîyê marî (n)"
  },
  {
    "x": "teluyên, -i",
    "y": " b. telîyin, -e"
  },
  {
    "x": "teluyin, -e",
    "y": " b. telîyin, -e"
  },
  {
    "x": "telû (n)",
    "y": " b. telî (n)"
  },
  {
    "x": "tem",
    "y": " b. tam, -e-I"
  },
  {
    "x": "temafîle (m)",
    "y": " b. otomobîle (m)"
  },
  {
    "x": "temafîli (m)",
    "y": " b. otomobîle (m)"
  },
  {
    "x": "temam, -e",
    "y": " tamam"
  },
  {
    "x": "temam bîyene",
    "y": " tamamlanmak"
  },
  {
    "x": "temam kerdene",
    "y": " bütünlemek, ikmal ekmek, tamamlamak"
  },
  {
    "x": "temambîyayîş (n)",
    "y": " tamamlanma"
  },
  {
    "x": "temamen",
    "y": " 1)başlı başına (zr)\r\n~2)baştan aşağı (zr)\r\n~3)baştan başa\r\n~4)baştan sona (zr)"
  },
  {
    "x": "temamî ra",
    "y": " 1)başlı başına (zr)\r\n~2)baştan aşağı (zr)\r\n~3)baştan başa\r\n~4)baştan sona (zr)"
  },
  {
    "x": "temamker (n)",
    "y": " tümleç"
  },
  {
    "x": "temamkerdiş (n)",
    "y": " bütünleme, ikmal, ikmal etme, tamamlama"
  },
  {
    "x": "temamnêbîyayîş (n)",
    "y": " akıl zayıflığı"
  },
  {
    "x": "temas (n)",
    "y": " 1)temas\r\n~2)bağlantı, kontakt"
  },
  {
    "x": "temas kerdene",
    "y": " dokunmak"
  },
  {
    "x": "temasê elektrîkî (n)",
    "y": " elektrik kontağı"
  },
  {
    "x": "temase (n)",
    "y": " b. temaşe (n)"
  },
  {
    "x": "temaskerdiş (n)",
    "y": " dokunma"
  },
  {
    "x": "temaşa (m)",
    "y": " 1)acayip, garip, gariplik\r\n~2)baş döndürücü (mecazi)"
  },
  {
    "x": "temaşe, -ye-I ",
    "y": " 1)acayip, garip, gariplik\r\n~2)baş döndürücü (mecazi)"
  },
  {
    "x": "temaşe bîyene",
    "y": " acayipleşmek"
  },
  {
    "x": "temaşe-II (n)",
    "y": " temaşa"
  },
  {
    "x": "temaşe kerdene",
    "y": " bakmak, izlemek, seyretmek, temaşa etmek"
  },
  {
    "x": "temaşey ... kerdene",
    "y": " b. temaşeyê ... kerdene"
  },
  {
    "x": "temaşeyê ... kerdene",
    "y": " -e bakmak"
  },
  {
    "x": "temaşê ... kerdene",
    "y": " b. temaşeyê ... kerdene"
  },
  {
    "x": "temaşebar, -e",
    "y": " izlenebilir (olan)"
  },
  {
    "x": "temaşebarîye (m)",
    "y": " izlenebilirlik"
  },
  {
    "x": "temaşebîyayîş (n)",
    "y": " acayipleşme"
  },
  {
    "x": "temaşekerdiş (n)",
    "y": " bakma, izleme, seyretme, temaşa etme"
  },
  {
    "x": "temaşekerdox, -e",
    "y": " izleyici, seyirci"
  },
  {
    "x": "temaşeyîye (m)",
    "y": " acayiplik"
  },
  {
    "x": "temaşir (n)",
    "y": " b. tebeşîr (n)"
  },
  {
    "x": "tembel, -e",
    "y": " tembel"
  },
  {
    "x": "tembeley (m)",
    "y": " b. tembelîye (m)"
  },
  {
    "x": "tembelê (m)",
    "y": " b. tembelîye (m)"
  },
  {
    "x": "tembelîye (m)",
    "y": " tembellik"
  },
  {
    "x": "tembur cenayene (mecazen)",
    "y": " gürültü etmek"
  },
  {
    "x": "temeqîyaya (m)",
    "y": " b. qemitîyaya (m)"
  },
  {
    "x": "temeqîyaye (n)",
    "y": " b. qemitîyaye (n)"
  },
  {
    "x": "temeqîyayene",
    "y": " b. qemitîyayene"
  },
  {
    "x": "temeqîyayî, -ye",
    "y": " b. qemitîyayî, -ye"
  },
  {
    "x": "temeqîyayîş (n)",
    "y": " b. qemitîyayîş (n)"
  },
  {
    "x": "temeqnayene",
    "y": " b. qemitnayene"
  },
  {
    "x": "temeqnayîş (n)",
    "y": " b. qemitnayîş (n)"
  },
  {
    "x": "temeqnayox, -e",
    "y": " b. qemitnayox, -e"
  },
  {
    "x": "temerkuz bîyene",
    "y": " odaklanmak, odaklaşmak"
  },
  {
    "x": "temerkuzbîyayîş (n)",
    "y": " odaklanma, odaklaşma"
  },
  {
    "x": "temernayene",
    "y": " gömmek, defnetmek"
  },
  {
    "x": "temernayîş (n)",
    "y": " gömme, defnetme"
  },
  {
    "x": "temernayîşê eşteyî (n)",
    "y": " atık gömme"
  },
  {
    "x": "temezî (m)",
    "y": " başörtü, başörtüsü, kıvrak (başörtü)"
  },
  {
    "x": "temezîye (m)",
    "y": " başörtü, başörtüsü, kıvrak (başörtü)"
  },
  {
    "x": "temiqîyaya (m)",
    "y": " b. qemitîyaya (m)"
  },
  {
    "x": "temiqîyaye (n)",
    "y": " b. qemitîyaye (n)"
  },
  {
    "x": "temiqîyayene",
    "y": " b. qemitîyayene"
  },
  {
    "x": "temiqîyayî, -ye",
    "y": " b. qemitîyayî, -ye"
  },
  {
    "x": "temiqîyayîş (n)",
    "y": " b. qemitîyayîş (n)"
  },
  {
    "x": "temiqnayene",
    "y": " b. qemitnayene"
  },
  {
    "x": "temiqnayîş (n)",
    "y": " b. qemitnayîş (n)"
  },
  {
    "x": "temiqnayox, -e",
    "y": " b. qemitnayox, -e"
  },
  {
    "x": "temirnayene",
    "y": " b. temernayene"
  },
  {
    "x": "temirnayîş (n)",
    "y": " b. temernayîş (n)"
  },
  {
    "x": "temîn kerdene",
    "y": " sağlamak, temin etmek"
  },
  {
    "x": "temînat (n)",
    "y": " güvence, garanti, teminat"
  },
  {
    "x": "temînat dayene",
    "y": " güvence vermek, garanti vermek, teminat vermek"
  },
  {
    "x": "temînkerdiş (n)",
    "y": " sağlama, temin etme"
  },
  {
    "x": "temîr (n)",
    "y": " b. tamîr (n)"
  },
  {
    "x": "temîrat (n)",
    "y": " b. tamîrat (n)"
  },
  {
    "x": "temîrkerdiş (n)",
    "y": " b. tamîrkerdiş (n)"
  },
  {
    "x": "temîz, -e",
    "y": " temiz"
  },
  {
    "x": "temîz bîyene",
    "y": " arınmak, paklanmak, temizlenmek"
  },
  {
    "x": "temîz kerdene",
    "y": " temizlemek"
  },
  {
    "x": "temîzbîyayîş (n)",
    "y": " arınma, paklanma, temizlenme"
  },
  {
    "x": "temîzey (m)",
    "y": " b. temîzîye (m), temîzî (m)"
  },
  {
    "x": "temîzê (m)",
    "y": " b. temîzîye (m), temîzî (m)"
  },
  {
    "x": "temîzî (m)",
    "y": " temizlik"
  },
  {
    "x": "temîzîye (m)",
    "y": " temizlik"
  },
  {
    "x": "temîzkerdiş (n)",
    "y": " temizleme"
  },
  {
    "x": "temmuz, -e-I",
    "y": " b. temuz, -e-I"
  },
  {
    "x": "temmuz-II (m)",
    "y": " b. temmuze (m)"
  },
  {
    "x": "temmuze (m)",
    "y": " temmuz"
  },
  {
    "x": "temmuzi (m)",
    "y": " b. temmuze (m)"
  },
  {
    "x": "temmuzî (m)",
    "y": " bir üzüm çeşidi"
  },
  {
    "x": "temmûz (m)",
    "y": " b. temmuze (m)"
  },
  {
    "x": "temmûzi (m)",
    "y": " b. temmuze (m)"
  },
  {
    "x": "temmûzî (m)",
    "y": " b. temmuzî (m)"
  },
  {
    "x": "temom",
    "y": " b. temam, -e"
  },
  {
    "x": "temrîn (n)",
    "y": " alıştırma, egzersiz, temrin"
  },
  {
    "x": "temsî (m)",
    "y": " b. tepsî (m)"
  },
  {
    "x": "temsîlkar, -e",
    "y": " temsilci"
  },
  {
    "x": "temsîlkarî (m)",
    "y": " temsilcilik"
  },
  {
    "x": "temsîlkarîya cayê karî (m)",
    "y": " işyeri temsilciliği"
  },
  {
    "x": "temsîlkarîye (m)",
    "y": " temsilcilik"
  },
  {
    "x": "temtene (n)",
    "y": " b. tentene (n)"
  },
  {
    "x": "temuz, -e-I",
    "y": " ağustos böceği, orak böceği"
  },
  {
    "x": "temuz-II (n)",
    "y": " b. temmuze (m)"
  },
  {
    "x": "temuze (m)",
    "y": " b. temmuze (m)"
  },
  {
    "x": "temuzî (m)",
    "y": " b. temmuzî (m)"
  },
  {
    "x": "temûm",
    "y": " b. temam, -e"
  },
  {
    "x": "temûz-I (m)",
    "y": " b. temmuze (m)"
  },
  {
    "x": "temûz, -e-II",
    "y": " b. temuz, -e-I"
  },
  {
    "x": "temûzi (m)",
    "y": " b. temmuzî (m)"
  },
  {
    "x": "temûzî-I (m)",
    "y": " b. temmuzî (m)"
  },
  {
    "x": "temûzî-II (m)",
    "y": " b. temezî (m)"
  },
  {
    "x": "temyîz (n) (huq.)",
    "y": " temyiz"
  },
  {
    "x": "temzî (m)",
    "y": " b. temezî (m)"
  },
  {
    "x": "ten",
    "y": " kişi"
  },
  {
    "x": "di tenî",
    "y": " iki kişi"
  },
  {
    "x": "tena",
    "y": " 1)ancak\r\n~2)sadece, sırf, salt, yalnızca, yalnız, safi\r\n~3)tek\r\n~\tKeye de tena o mend. (Evde sadece o kaldı.)\r\n~\t\t\r\n~Keye de tena verda. (Evde yalnız bıraktı.)\r\n~\t\t\r\n~Tena mi ra va. (Sadece bana söyledi.)\r\n~\t\t\r\n~Tena şo, qalî meke. (Sadece git, konuşma.)"
  },
  {
    "x": "tena bîyene",
    "y": " yalnızlaşmak"
  },
  {
    "x": "tena mendene",
    "y": " yalnız kalmak"
  },
  {
    "x": "tena verdayene",
    "y": " yalnız bırakmak, yalnızlaştırmak"
  },
  {
    "x": "tena verdîyayene",
    "y": " yalnız bırakılmak, yalnızlaştırılmak"
  },
  {
    "x": "tenabîyayîş (n)",
    "y": " yalnızlaşma"
  },
  {
    "x": "tenaquz (n)",
    "y": " çelişki, tenakuz, çelişme"
  },
  {
    "x": "tenaquz de bîyene",
    "y": " çelişmek, mütenakız olmak"
  },
  {
    "x": "tenaquzdebîyayîş (n)",
    "y": " çelişme"
  },
  {
    "x": "tenasub (n)",
    "y": " orantı, tenasüp"
  },
  {
    "x": "tenatî (m)",
    "y": " yalnızlık"
  },
  {
    "x": "tenaverdayîş (n)",
    "y": " yalnızlaştırma"
  },
  {
    "x": "tenaverdiş (n)",
    "y": " b. tenaverdayîş (n)"
  },
  {
    "x": "tenayey (m)",
    "y": " b. tenayîye (m)"
  },
  {
    "x": "tenayênî (m)",
    "y": " yalnızlık"
  },
  {
    "x": "tenayî (m)",
    "y": " yalnızlık"
  },
  {
    "x": "tenayîye (m)",
    "y": " yalnızlık"
  },
  {
    "x": "tencere (n)",
    "y": " tencere "
  },
  {
    "x": "tencik (n)",
    "y": " küçük tencere"
  },
  {
    "x": "tencira (m)",
    "y": " b. tencere (n)"
  },
  {
    "x": "tencire (n)",
    "y": " b. tencere (n)"
  },
  {
    "x": "tencî (m)",
    "y": " b. tajîye-I (m), tazîye-I (m) "
  },
  {
    "x": "tendens (n)",
    "y": " eğilim"
  },
  {
    "x": "tendire (m)",
    "y": " b. tenûre (m)"
  },
  {
    "x": "tendur (m)",
    "y": " b. tenûre (m)"
  },
  {
    "x": "tendurdîyot (n)",
    "y": " b. tenturdîyot (n)"
  },
  {
    "x": "tendure (m)",
    "y": " b. tenûre (m)"
  },
  {
    "x": "tendureke (m)",
    "y": " anafor, girdap, burgaç, çevri"
  },
  {
    "x": "tendûri (m)",
    "y": " b. tenûre (m)"
  },
  {
    "x": "tene-I (m)",
    "y": " tane, adet, sayı"
  },
  {
    "x": "tene bi tene",
    "y": " tane tane"
  },
  {
    "x": "teneyê",
    "y": " bir tane"
  },
  {
    "x": "teneyêk, -e",
    "y": " bir tane"
  },
  {
    "x": "tene-II (n)",
    "y": " 1)tahıl, hububat\r\n~2)ekin"
  },
  {
    "x": "tene-III",
    "y": " b. ten"
  },
  {
    "x": "tenefus (n)",
    "y": " b. teneffus (n)"
  },
  {
    "x": "teneffus (n)",
    "y": " teneffüs"
  },
  {
    "x": "teneke (n)",
    "y": " teneke"
  },
  {
    "x": "tenekey qazexî (n)",
    "y": " b. tenekeyê gazyaxî (m)"
  },
  {
    "x": "tenekeyê gazyaxî (m)",
    "y": " gazyağı tenekesi"
  },
  {
    "x": "tenekeyê qazaxî (n)",
    "y": " b. tenekeyê gazyaxî (m)"
  },
  {
    "x": "tenekê qazî (n)",
    "y": " b. tenekeyê gazyaxî (m)"
  },
  {
    "x": "teneşû (n)",
    "y": " b. tenşû (n)"
  },
  {
    "x": "teney (m)",
    "y": " b. tenî-I (m), tenîye (m)"
  },
  {
    "x": "teney",
    "y": " b. tenê-I"
  },
  {
    "x": "teneyê",
    "y": " b. tenê-I"
  },
  {
    "x": "teneyi (m)",
    "y": " b. tenî-I (m), tenîye (m)"
  },
  {
    "x": "tenê-I",
    "y": " biraz"
  },
  {
    "x": "tenê-II",
    "y": " b. tena"
  },
  {
    "x": "tenê verdayene",
    "y": " b. tena verdayene"
  },
  {
    "x": "tenê verdîyayene",
    "y": " b. tena verdîyayene"
  },
  {
    "x": "tenê-III",
    "y": " b. tayê"
  },
  {
    "x": "tenê-VI (m)",
    "y": " b. tenî-I (m), tenîye (m)"
  },
  {
    "x": "tenênî (m)",
    "y": " b. tenayênî (m)"
  },
  {
    "x": "tenêverdayîş (n)",
    "y": " b. tenaverdayîş (n)"
  },
  {
    "x": "teng, -e-I",
    "y": " dar"
  },
  {
    "x": "teng kerdene",
    "y": " 1)kısıtlamak, kısmak (haklar vb. için)\r\n~2)büzmek"
  },
  {
    "x": "teng-II (n)",
    "y": " kolan"
  },
  {
    "x": "tengalek, -e",
    "y": " daracık"
  },
  {
    "x": "tenge (n)",
    "y": " b. teng-II (n)"
  },
  {
    "x": "tengek, -e",
    "y": " daracık"
  },
  {
    "x": "tengezar bîyene",
    "y": " bunalmak"
  },
  {
    "x": "tengezarbîyayîş (n)",
    "y": " bunalma"
  },
  {
    "x": "tengeznayene",
    "y": " b. tengiznayene"
  },
  {
    "x": "tengeznayîş (n)",
    "y": " b. tengiznayîş (n)"
  },
  {
    "x": "tengêre (n)",
    "y": " ikindinin son anları"
  },
  {
    "x": "tenghoke (m)",
    "y": " dar açı"
  },
  {
    "x": "tenghokin, -e",
    "y": " dar açılı"
  },
  {
    "x": "tengijîyayene",
    "y": " gerilmek"
  },
  {
    "x": "tengijîyayîş (n)",
    "y": " gerilme, gerilim"
  },
  {
    "x": "tengijîyayîşê karî (n)",
    "y": " iş stresi"
  },
  {
    "x": "tengijîyayîşo psîkolojîk (n)",
    "y": " stres"
  },
  {
    "x": "tengijîyayîşo ruhî (n)",
    "y": " stres"
  },
  {
    "x": "tengiznayene",
    "y": " sıkıştırmak"
  },
  {
    "x": "tengiznayîş (n)",
    "y": " sıkıştırma"
  },
  {
    "x": "tengkerdiş (n)",
    "y": " 1)kısıtlama, kısma (haklar vb. için)\r\n~2)büzme"
  },
  {
    "x": "tengkuçe (n)",
    "y": " dar sokak"
  },
  {
    "x": "teni (n)",
    "y": " b. tene-II (n)"
  },
  {
    "x": "tenik, -e",
    "y": " ince"
  },
  {
    "x": "tenik bîyene",
    "y": " incelmek"
  },
  {
    "x": "tenik kerdene",
    "y": " inceltmek"
  },
  {
    "x": "tenik-tenik",
    "y": " ince ince"
  },
  {
    "x": "tenikbîyayîş (n)",
    "y": " incelme"
  },
  {
    "x": "tenike (n)",
    "y": " b. teneke (n)"
  },
  {
    "x": "tenikek, -e",
    "y": " incecik"
  },
  {
    "x": "tenikey (m)",
    "y": " b. tenikîye (m), tenikî (m)"
  },
  {
    "x": "tenikê (m)",
    "y": " b. tenikîye (m), tenikî (m)"
  },
  {
    "x": "tenikênî (m)",
    "y": " incelik"
  },
  {
    "x": "tenikî (m)",
    "y": " incelik"
  },
  {
    "x": "tenikîye (m)",
    "y": " incelik"
  },
  {
    "x": "tenikkerdiş (n)",
    "y": " inceltme"
  },
  {
    "x": "tenî-I (m)",
    "y": " is"
  },
  {
    "x": "tenî-II",
    "y": " b. tena"
  },
  {
    "x": "tenîs (n)",
    "y": " tenis"
  },
  {
    "x": "tenîsê masa (n)",
    "y": " masa tenisi, pinpon"
  },
  {
    "x": "tenîya",
    "y": " b. tena"
  },
  {
    "x": "tenîye (m)",
    "y": " is"
  },
  {
    "x": "tenke (n)",
    "y": " b. teneke (n)"
  },
  {
    "x": "tenkey qazaxî (n)",
    "y": " b. tenekeyê gazyaxî (m)"
  },
  {
    "x": "tennuri (m)",
    "y": " b. tenûre (m)"
  },
  {
    "x": "tenşi (n)",
    "y": " b. tenşû (n)"
  },
  {
    "x": "tenşû (n)",
    "y": " teneşir, teneşir tahtası"
  },
  {
    "x": "tentene (n)",
    "y": " dantel, dantela, tentene"
  },
  {
    "x": "tentirot (n)",
    "y": " b. tenturdîyot (n)"
  },
  {
    "x": "tentur-I (n)",
    "y": " b. tantûr (n)"
  },
  {
    "x": "tentur-II (n)",
    "y": " b. tertul (n)"
  },
  {
    "x": "tenturdîyot (n)",
    "y": " tentürdiyot "
  },
  {
    "x": "tenuri (m)",
    "y": " b. tenûre (m)"
  },
  {
    "x": "tenûre (m)",
    "y": " tandır"
  },
  {
    "x": "tenya",
    "y": " b. tena"
  },
  {
    "x": "tenyayî (m)",
    "y": " b. tenayî (m)"
  },
  {
    "x": "tenzî (m)",
    "y": " b. tajîye (m), tazîye (m)"
  },
  {
    "x": "tenzîlat (n)",
    "y": " indirim, iskonto, tenzilat"
  },
  {
    "x": "tenzîm kerdene",
    "y": " düzenlemek, tertip etmek, tertiplemek"
  },
  {
    "x": "tenzîmkar, -e",
    "y": " düzenleyici"
  },
  {
    "x": "tenzîmkerdiş (n)",
    "y": " düzenleme, tertip etme, tertipleme"
  },
  {
    "x": "teolog, -e",
    "y": " ilahiyatçı, teolog, Tanrıbilimci"
  },
  {
    "x": "teolojî (n)",
    "y": " ilahiyat, teoloji, Tanrıbilim"
  },
  {
    "x": "teorî (m)",
    "y": " kuram, teori, nazariye"
  },
  {
    "x": "teorîya fîlmî",
    "y": " film kuramı, film teorisi"
  },
  {
    "x": "teorîya sînema (m)",
    "y": " sinema kuramı, sinema teorisi"
  },
  {
    "x": "teorîk, -e",
    "y": " kuramsal, nazari, teorik"
  },
  {
    "x": "tepa",
    "y": " b. tepîya"
  },
  {
    "x": "tepa dayene",
    "y": " b. tepîya dayene"
  },
  {
    "x": "tepadayîş (n)",
    "y": " b. tepîyadayîş (n)"
  },
  {
    "x": "tepe (n)",
    "y": " tepe"
  },
  {
    "x": "teper (n)",
    "y": " b. teber (n)"
  },
  {
    "x": "teper a",
    "y": " b. teber ra"
  },
  {
    "x": "tepeşire (m)",
    "y": " b. tebeşîr (n)"
  },
  {
    "x": "tepêştene",
    "y": " b. tepîştene"
  },
  {
    "x": "tepêştiş (n)",
    "y": " b. tepîştiş (n)"
  },
  {
    "x": "tepik (m)",
    "y": " b. tepike (m)"
  },
  {
    "x": "tepike (m)",
    "y": " 1)köfte (ince yarmadan veya kıymadan yapılan)\r\n~2)büyükbaş hayvanın bir defada yaptığı dışkı topağı, tezek"
  },
  {
    "x": "tepika silî (m)",
    "y": " büyükbaş hayvanın bir defada yaptığı dışkı topağı, tezek"
  },
  {
    "x": "tepiki (m)",
    "y": " b. tepike (m)"
  },
  {
    "x": "tepikey silî (m)",
    "y": " b. tepika silî (m)"
  },
  {
    "x": "tepisnayene",
    "y": " sıkıştırmak"
  },
  {
    "x": "tepisnayîş (n)",
    "y": " sıkıştırma"
  },
  {
    "x": "tepiştene",
    "y": " b. tepîştene"
  },
  {
    "x": "tepiştiş (n)",
    "y": " b. tepîştiş (n)"
  },
  {
    "x": "tepixi (m)",
    "y": " b. tepike (m)"
  },
  {
    "x": "tepîşîyayene",
    "y": " 1)tutulmak, yakalanmak\r\n~2)avlanmak "
  },
  {
    "x": "tepîşîyayîş (n)",
    "y": " 1)tutulma, yakalanma\r\n~2)avlanma"
  },
  {
    "x": "tepîştene",
    "y": " 1)tutmak, yakalamak\r\n~2)avlamak"
  },
  {
    "x": "tepîştiş (n)",
    "y": " 1)tutma, yakalama\r\n~2)avlama"
  },
  {
    "x": "tepîya",
    "y": " 1)geri, gerisingeri, gerisingeriye\r\n~2)yine, gene, tekrar"
  },
  {
    "x": "... ra tepîya",
    "y": " -den sonra"
  },
  {
    "x": "tepîya dayene",
    "y": " esirgemek (vermekten veya yapmaktan kaçınmak) "
  },
  {
    "x": "tepîya mendene",
    "y": " geri kalmak"
  },
  {
    "x": "tepîyadayîş (n)",
    "y": " esirgeme (vermekten veya yapmaktan kaçınma)"
  },
  {
    "x": "tepîyamendiş (n)",
    "y": " geri kalma"
  },
  {
    "x": "tepîyamendişo zihnî (n)",
    "y": " zekâ geriliği, mental retardasyon"
  },
  {
    "x": "tepsî (m)",
    "y": " tepsi"
  },
  {
    "x": "tepsîye (m)",
    "y": " tepsi"
  },
  {
    "x": "tepş (n)",
    "y": " tavır, tutum"
  },
  {
    "x": "tepya",
    "y": " b. tepîya"
  },
  {
    "x": "teq ... kotene",
    "y": " b. teqê ... kewtene "
  },
  {
    "x": "teq ... vistene",
    "y": " b. teqê ... fîştene"
  },
  {
    "x": "teqê ... ameyene",
    "y": " öfkelenmek"
  },
  {
    "x": "teqê ... ardene",
    "y": " öfkelendirmek"
  },
  {
    "x": "teqê ... fîştene",
    "y": " öfkelendirmek"
  },
  {
    "x": "teqê ... kewtene",
    "y": " öfkelenmek"
  },
  {
    "x": "teqî ... ameyîne",
    "y": " b. teqê ... ameyene "
  },
  {
    "x": "teqî ... ardene",
    "y": " b. teqê ... ardene"
  },
  {
    "x": "teqa (m)",
    "y": " b. taqa (m)"
  },
  {
    "x": "teqawit, -i",
    "y": " b. teqawut, -e"
  },
  {
    "x": "teqawitê (m)",
    "y": " b. teqawutî (m)"
  },
  {
    "x": "teqawut, -e",
    "y": " emekli"
  },
  {
    "x": "teqawut bîyene",
    "y": " emekli olmak, emekliye ayrılmak, emekliye çıkmak"
  },
  {
    "x": "teqawutbîyayîş (n)",
    "y": " emekli olma, emekliye ayrılma, emekliye çıkma"
  },
  {
    "x": "teqawutey (m)",
    "y": " b. teqawutî (m)"
  },
  {
    "x": "teqawutê (m)",
    "y": " b. teqawutî (m)"
  },
  {
    "x": "teqawutî (m)",
    "y": " emeklilik"
  },
  {
    "x": "teqawutîye (m)",
    "y": " emeklilik"
  },
  {
    "x": "teqaya (m)",
    "y": " patlak"
  },
  {
    "x": "teqaye (n)",
    "y": " patlak"
  },
  {
    "x": "teqayene",
    "y": " 1)patlamak\r\n~2)kıskanmak, günülemek, haset etmek (çekememek)\r\n~3)öfkelenmek"
  },
  {
    "x": "teqayî, -ye",
    "y": " patlak"
  },
  {
    "x": "teqayîş (n)",
    "y": " 1)patlama\r\n~2)kıskanma, günüleme, haset etme (çekememe) \r\n~3)öfkelenme (aşırı ölçüde kızma)"
  },
  {
    "x": "teqayîşê dîmenî (sn)",
    "y": " görüntü patlaması"
  },
  {
    "x": "teqayîşê gazê metanî (zh)",
    "y": " metan gazı patlamaları"
  },
  {
    "x": "teqayîşê kîmyayî (zh)",
    "y": " kimyasal patlamalar"
  },
  {
    "x": "teqayîşê radyoaktîfî (zh)",
    "y": " radyoaktif patlamalar"
  },
  {
    "x": "teqayîşê vengî (sn)",
    "y": " ses patlaması"
  },
  {
    "x": "teqayîşê volkanî (n)",
    "y": " yanardağ patlaması"
  },
  {
    "x": "teqdîm bîyayene",
    "y": " b. teqdîm bîyene"
  },
  {
    "x": "teqdîm bîyene",
    "y": " sunulmak"
  },
  {
    "x": "teqdîm kerdene",
    "y": " sunmak"
  },
  {
    "x": "teqdîmbîyayîş (n)",
    "y": " sunulma"
  },
  {
    "x": "teqdîmkerdiş (n)",
    "y": " sunma"
  },
  {
    "x": "teqebar, -e",
    "y": " patlayıcı"
  },
  {
    "x": "teqet (n)",
    "y": " b. taqet (n)"
  },
  {
    "x": "teqeze (n)",
    "y": " acele"
  },
  {
    "x": "teqeze kerdene",
    "y": " acele etmek"
  },
  {
    "x": "teqezeker, -e",
    "y": " aceleci, telaşlı"
  },
  {
    "x": "teqil (n)",
    "y": " sıklet"
  },
  {
    "x": "teqilo giran (n)",
    "y": " ağır sıklet"
  },
  {
    "x": "teqîb (n)",
    "y": " b. taqîb (n)"
  },
  {
    "x": "teqîb kerdene",
    "y": " b. taqîb kerdene"
  },
  {
    "x": "teqîv (n)",
    "y": " b. taqîb (n)"
  },
  {
    "x": "teqîv kerdene",
    "y": " b. taqîb kerdene"
  },
  {
    "x": "teqîvkerdiş (n)",
    "y": " b. taqîbkerdiş (n)"
  },
  {
    "x": "teql (n)",
    "y": " b. teqil (n)"
  },
  {
    "x": "teqlo giran (n)",
    "y": " b. teqilo giran (n)"
  },
  {
    "x": "teqley bîyayîne",
    "y": " b. teqlêr bîyene"
  },
  {
    "x": "teqley dayene",
    "y": " b. teqlêr dayene"
  },
  {
    "x": "teqleyrî bîyayene",
    "y": " b. teqlêr bîyene "
  },
  {
    "x": "teqleyrî dayene",
    "y": " b. teqlêr dayene"
  },
  {
    "x": "teqlêr bîyayene",
    "y": " b. teqlêr bîyene"
  },
  {
    "x": "teqlêr bîyene",
    "y": " yuvarlanmak"
  },
  {
    "x": "teqlêr dayene",
    "y": " yuvarlamak"
  },
  {
    "x": "teqli bîyayene",
    "y": " b. teqlêr bîyene "
  },
  {
    "x": "teqli dayene",
    "y": " b. teqlêr dayene"
  },
  {
    "x": "teqlîd (n)",
    "y": " taklit"
  },
  {
    "x": "teqlîd kerdene",
    "y": " taklit etmek"
  },
  {
    "x": "teqlîdkerdiş (n)",
    "y": " taklit etme"
  },
  {
    "x": "teqnayene",
    "y": " 1)patlatmak\r\n~2)kıskandırmak\r\n~3)öfkelendirmek"
  },
  {
    "x": "teqnayîş (n)",
    "y": " 1)patlatma\r\n~2)kıskandırma\r\n~3)öfkelendirme (aşırı ölçüde kızdırma)"
  },
  {
    "x": "teq-req",
    "y": " b. teq-reqe (m)"
  },
  {
    "x": "teq-reqe (m)",
    "y": " pat küt, tak tuk, gürültü patırtı, hengâme, şamata"
  },
  {
    "x": "teqrîben",
    "y": " aşağı yukarı, yaklaşık, yaklaşık olarak, takriben, takribî"
  },
  {
    "x": "teqsî (m)",
    "y": " b. taksî (m)"
  },
  {
    "x": "teqsîm (n)",
    "y": " 1)bölme\r\n~2)bölüm (TE)\r\n~3)taksim"
  },
  {
    "x": "teqsîm kerdene",
    "y": " bölmek"
  },
  {
    "x": "teqsîmetre (n)",
    "y": " b. taksîmetre (n)"
  },
  {
    "x": "teqsîmkerdiş (n)",
    "y": " bölme, taksim"
  },
  {
    "x": "teqsîr kerdene",
    "y": " esirgemek (vermekten veya yapmaktan kaçınmak) "
  },
  {
    "x": "teqsîrkerdiş (n)",
    "y": " esirgeme (vermekten veya yapmaktan kaçınma)"
  },
  {
    "x": "tequş",
    "y": " koç veya tekenin toslamasını sağlamak için söylenen bir söz"
  },
  {
    "x": "teqûreq (m)",
    "y": " b. teqûreqe (m)"
  },
  {
    "x": "teqûreqe (m)",
    "y": " pat küt, tak tuk, gürültü patırtı, hengâme, şamata"
  },
  {
    "x": "teqûreqi (m)",
    "y": " b. teqûreqe (m)"
  },
  {
    "x": "teqwîm (n)",
    "y": " takvim"
  },
  {
    "x": "ter-I (n)",
    "y": " b. tewir (n)"
  },
  {
    "x": "ter-II (n)",
    "y": " b. tern, -e"
  },
  {
    "x": "...ter (sf)",
    "y": " b. -tir (sf)"
  },
  {
    "x": "seterna",
    "y": " bu yıldan sonra gelen ikinci yıl\r\n~Serna nê, seterna yeno. (Gelecek yıl değil, sonraki yıl gelecek.)"
  },
  {
    "x": "ter ke",
    "y": " yeter ki"
  },
  {
    "x": "ter ki",
    "y": " b. ter ke"
  },
  {
    "x": "tera-I (m)",
    "y": " b. terhe (m)"
  },
  {
    "x": "tera-II (m)",
    "y": " b. teyara (m)"
  },
  {
    "x": "tera bîyene",
    "y": " b. tira bîyene, cira bîyene"
  },
  {
    "x": "tera geyrayene",
    "y": " b. tira gêrayene"
  },
  {
    "x": "tera gêrayene",
    "y": " b. tira gêrayene"
  },
  {
    "x": "tera kerdene",
    "y": " b. tira kerdene, cira kerdene"
  },
  {
    "x": "terabîyayîş (n)",
    "y": " b. tirabîyayîş (n), cirabîyayîş (n)"
  },
  {
    "x": "terafi (m)",
    "y": " b. tirafe (m)"
  },
  {
    "x": "terageyrayîş (n)",
    "y": " b. tiragêrayîş (n)"
  },
  {
    "x": "teragêrayîş (n)",
    "y": " b. tiragêrayîş (n)"
  },
  {
    "x": "terakerdiş (n)",
    "y": " b. tirakerdiş (n), cirakerdiş (n)"
  },
  {
    "x": "teral, -e",
    "y": " b. tiral, -e"
  },
  {
    "x": "teralê (m)",
    "y": " b. tiralîye (m)"
  },
  {
    "x": "teralî (m)",
    "y": " b. tiralîye (m)"
  },
  {
    "x": "terampe (n)",
    "y": " değiş tokuş, değişim, mübadele, takas, trampa"
  },
  {
    "x": "terapî (m)",
    "y": " sağaltım, tedavi, terapi"
  },
  {
    "x": "terapîst, -e",
    "y": " sağaltıcı, sağaltımcı, terapist"
  },
  {
    "x": "teraqnayene",
    "y": " b. terraqnayene"
  },
  {
    "x": "teraqnayîş (n)",
    "y": " b. terraqnayîş (n)"
  },
  {
    "x": "teraş-I (n)",
    "y": " dik yamaç, yar, uçurum"
  },
  {
    "x": "teraş-II (n)",
    "y": " b. tiraş (n)"
  },
  {
    "x": "terawitene",
    "y": " b. tirawitene"
  },
  {
    "x": "terawitiş (n)",
    "y": " b. tirawitiş (n)"
  },
  {
    "x": "terazi (n)",
    "y": " b. terazî (n)"
  },
  {
    "x": "terazî (n)",
    "y": " terazi, tartaç"
  },
  {
    "x": "terazû (n)",
    "y": " b. terazî (n)"
  },
  {
    "x": "tercibe kerdene",
    "y": " b. tecrube kerdene"
  },
  {
    "x": "tercibekerdiş (n)",
    "y": " b. tecrubekerdiş (n)"
  },
  {
    "x": "terciman, -e",
    "y": " b. tercuman, -e"
  },
  {
    "x": "tercimûn, -i",
    "y": " b. tercuman, -e"
  },
  {
    "x": "tercîbûn, -i",
    "y": " b. tercuman, -e"
  },
  {
    "x": "tercube (n)",
    "y": " b. tecrube (n)"
  },
  {
    "x": "tercube kerdene",
    "y": " b. tecrube kerdene"
  },
  {
    "x": "tercuman, -e",
    "y": " dilmaç, tercüman"
  },
  {
    "x": "tercume (n)",
    "y": " çeviri, tercüme"
  },
  {
    "x": "tercume kerdene",
    "y": " çevirmek, tercüme etmek"
  },
  {
    "x": "tercumekerdiş (n)",
    "y": " çevirme, tercüme etme"
  },
  {
    "x": "tercumekerdox, -e",
    "y": " çeviren, çevirmen, tercüme eden, mütercim, tercüman"
  },
  {
    "x": "tercumûn, -i",
    "y": " b. tercuman, -e"
  },
  {
    "x": "terdene",
    "y": " 1)sıyırmak\r\n~2)tıraş etmek"
  },
  {
    "x": "terdeni",
    "y": " b. terdene"
  },
  {
    "x": "terdis (n)",
    "y": " b. terdiş (n)"
  },
  {
    "x": "terdiş (n)",
    "y": " 1)sıyırma\r\n~2)tıraş, tıraş etme"
  },
  {
    "x": "terdişê erdîşe",
    "y": " sakal tıraşı"
  },
  {
    "x": "terdişê gijikî",
    "y": " saç tıraşı"
  },
  {
    "x": "terdişê porî",
    "y": " saç tıraşı"
  },
  {
    "x": "teref (n)",
    "y": " taraf"
  },
  {
    "x": "terefdar, -e",
    "y": " 1)yandaş, taraftar\r\n~2)tarafgir"
  },
  {
    "x": "terefgir, -e",
    "y": " 1)yandaş, taraftar\r\n~2)tarafgir"
  },
  {
    "x": "terefin, -e",
    "y": " cepheli"
  },
  {
    "x": "tereg (n)",
    "y": " b. terek (n)"
  },
  {
    "x": "terek (n)",
    "y": " raf"
  },
  {
    "x": "terekîyayene",
    "y": " b. terikîyayene"
  },
  {
    "x": "terekîyayîne",
    "y": " b. terikîyayene"
  },
  {
    "x": "terekîyayîş (n)",
    "y": " b. terikîyayîş (n)"
  },
  {
    "x": "tereknayene",
    "y": " b. teriknayene"
  },
  {
    "x": "tereknayîş (n)",
    "y": " b. teriknayîş (n)"
  },
  {
    "x": "tereqîyayîne",
    "y": " b. terikîyayene"
  },
  {
    "x": "tereqnayene",
    "y": " b. terraqnayene"
  },
  {
    "x": "tereqnayîş (n)",
    "y": " b. terraqnayîş (n)"
  },
  {
    "x": "tereşîyayene",
    "y": " b. terişîyayene"
  },
  {
    "x": "tereşnayene",
    "y": " b. terişnayene"
  },
  {
    "x": "terevuzun (n)",
    "y": " b. televîzyon (n)"
  },
  {
    "x": "terezi (n)",
    "y": " b. terazî (n)"
  },
  {
    "x": "terezî (n)",
    "y": " b. terazî (n)"
  },
  {
    "x": "terfî (n)",
    "y": " terfi"
  },
  {
    "x": "terfî kerdene",
    "y": " terfi etmek"
  },
  {
    "x": "terfî pakerdene",
    "y": " terfi takmak"
  },
  {
    "x": "terfîkerdiş (n)",
    "y": " terfi etme"
  },
  {
    "x": "terfîpakerdiş (n)",
    "y": " terfi takma"
  },
  {
    "x": "terfîye (n)",
    "y": " b. terfî (n)"
  },
  {
    "x": "terhe (m)",
    "y": " dövenle dövülmek üzere harman yerinde bulunan ekin yığını/harman"
  },
  {
    "x": "terheqelibnayîş (n)",
    "y": " döven sürerken altta kalan dövülmemiş ekin saplarını üste çıkarmak için harman yığınını altüst etme"
  },
  {
    "x": "terhi (m)",
    "y": " b. terhe (m)"
  },
  {
    "x": "terhiqelebnayîş (n)",
    "y": " b. terheqelibnayîş (n)"
  },
  {
    "x": "terik-I (n)",
    "y": " odun parçası"
  },
  {
    "x": "terik-II (n)",
    "y": " b. terrik (n)"
  },
  {
    "x": "terik-III (n)",
    "y": " b. terk-I (n)"
  },
  {
    "x": "terikîyayene",
    "y": " 1)terk edilmek\r\n~2)çatlamak (deri vb. için), yarılmak"
  },
  {
    "x": "terikîyayîş (n)",
    "y": " 1)terk edilme\r\n~2)çatlama (deri vb. için), yarılma"
  },
  {
    "x": "teriknayene",
    "y": " 1)terk etmek\r\n~2)çatlatmak (deri vb. için), yarmak"
  },
  {
    "x": "teriknayîş (n)",
    "y": " 1)terk etme\r\n~2)yarma"
  },
  {
    "x": "tirim (m)",
    "y": " b. tiramî (m)"
  },
  {
    "x": "terine (n)",
    "y": " b. terne (n)"
  },
  {
    "x": "teriqayene",
    "y": " b. teriqîyayene"
  },
  {
    "x": "teriqîyayene",
    "y": " çatlamak (kemik vb. için) "
  },
  {
    "x": "teriqîyayîş-I (n)",
    "y": " çatlama (kemik vb. için)"
  },
  {
    "x": "teriqîyayîş-II (n)",
    "y": " kar sertleşmesi"
  },
  {
    "x": "teriqnayene-I",
    "y": " çatlatmak (kemik vb. için)"
  },
  {
    "x": "teriqnayene-II",
    "y": " b. terriqnayene "
  },
  {
    "x": "teriqnayîş-I (n)",
    "y": " çatlatma (kemik vb. için)"
  },
  {
    "x": "teriqnayîş-II (n)",
    "y": " b. terriqnayîş (n)"
  },
  {
    "x": "terişîyayene",
    "y": " sıyrılmak (deri vb. için), soyulmak (deri, kabuk vb. için)"
  },
  {
    "x": "terişnayene",
    "y": " 1)sıyırmak (deri vb. için)\r\n~2)budamak (ağaç için)"
  },
  {
    "x": "terişnayîş (n)",
    "y": " 1)sıyırma (deri vb. için)\r\n~2)budama (ağaç için)"
  },
  {
    "x": "teritene",
    "y": " sıyırmak"
  },
  {
    "x": "teritiş (n)",
    "y": " sıyırma"
  },
  {
    "x": "teritox, -e",
    "y": " sıyırıcı"
  },
  {
    "x": "terî (m)",
    "y": " b. tirrî (m)"
  },
  {
    "x": "terîf (n)",
    "y": " tanım, tarif"
  },
  {
    "x": "terîf bîyene",
    "y": " tanımlanmak, tarif edilmek"
  },
  {
    "x": "terîf kerdene",
    "y": " tarif etmek, tanımlamak"
  },
  {
    "x": "terîfê karî (n)",
    "y": " iş tanımı"
  },
  {
    "x": "terîfê wezîfeyî (n)",
    "y": " görev tanımı"
  },
  {
    "x": "terîfê xebate (n)",
    "y": " iş tanımı"
  },
  {
    "x": "terîfbîyayîş (n)",
    "y": " tanımlanma, tarif edilme"
  },
  {
    "x": "terîfbîyayîşê projeyî (n)",
    "y": " projenin tanımlanması, projenin tarif edilmesi"
  },
  {
    "x": "terîfkerdiş (n)",
    "y": " tanımlama, tarif etme"
  },
  {
    "x": "terîqa (m)",
    "y": " b. terîqet (n)"
  },
  {
    "x": "terîqe (m)",
    "y": " b. terîqet (n)"
  },
  {
    "x": "terîqet (n)",
    "y": " tarikat"
  },
  {
    "x": "terîqete (m)",
    "y": " b. terîqet (n)"
  },
  {
    "x": "terîye (m)",
    "y": " b. terrîye (m)"
  },
  {
    "x": "terjî, -ye",
    "y": " b. terzî, -ye"
  },
  {
    "x": "terk-I (n)",
    "y": " terk"
  },
  {
    "x": "terk bîyayîne",
    "y": " b. terk bîyene"
  },
  {
    "x": "terk bîyene",
    "y": " terk edilmek"
  },
  {
    "x": "terk kerdene",
    "y": " terk etmek"
  },
  {
    "x": "terk-II (n)",
    "y": " yarık"
  },
  {
    "x": "terkayene",
    "y": " b. terikîyayene"
  },
  {
    "x": "terkayîş (n)",
    "y": " b. terikîyayîş (n), teriqîyayîş-I (n)"
  },
  {
    "x": "terke (n)",
    "y": " ekin"
  },
  {
    "x": "terkîsela, -ye",
    "y": " serseri, başıboş"
  },
  {
    "x": "terkîselay (m)",
    "y": " b. terkîsela, -ye"
  },
  {
    "x": "terkîselayey (m)",
    "y": " b. terkîselayîye (m)"
  },
  {
    "x": "terkîselayê (m)",
    "y": " b. terkîselayî (m)"
  },
  {
    "x": "terkîselayî (m)",
    "y": " serserilik"
  },
  {
    "x": "terkîselayîye (m)",
    "y": " serserilik"
  },
  {
    "x": "terkkerdiş (n)",
    "y": " terk etme"
  },
  {
    "x": "terknayene",
    "y": " b. teriknayene"
  },
  {
    "x": "terlig (n)",
    "y": " b. terlik (n)"
  },
  {
    "x": "terlik (n)",
    "y": " terlik"
  },
  {
    "x": "term-I (n)",
    "y": " cenaze (daha çok öldürülmüş kişi için)"
  },
  {
    "x": "term-II (n)",
    "y": " terim"
  },
  {
    "x": "termê çapemenîye (m)",
    "y": " basın terimleri"
  },
  {
    "x": "termê huqûqî (n)",
    "y": " hukuk terimi"
  },
  {
    "x": "termê huqûqî (zh)",
    "y": " hukuk terimleri"
  },
  {
    "x": "termê îtfaîya (zh)",
    "y": " itfaiye terimleri"
  },
  {
    "x": "termê yewîna emlakî (zh)",
    "y": " emlak birimi terimleri"
  },
  {
    "x": "termal, -e",
    "y": " termal"
  },
  {
    "x": "termîn (n)",
    "y": " dönem (öğretim yılının yarısı), yarıyıl, sömestr\r\n~termînê payîzî (n): birinci dönem (güz dönemi), birinci sömestr\r\n~termînê wisarî (n): ikinci dönem, ikinci sömestr, bahar dönemi (eğitim öğretim için)"
  },
  {
    "x": "termînê payîzî (n)",
    "y": " birinci dönem (güz dönemi), birinci sömestr"
  },
  {
    "x": "termînê wisarî (n)",
    "y": " ikinci dönem, ikinci sömestr, bahar dönemi (eğitim öğretim için)"
  },
  {
    "x": "termînal (n)",
    "y": " terminal"
  },
  {
    "x": "termometre (n)",
    "y": " sıcaklıkölçer, termometre"
  },
  {
    "x": "termose (m)",
    "y": " termos"
  },
  {
    "x": "tern, -e",
    "y": " yaş"
  },
  {
    "x": "ternaye (n)",
    "y": " boşanmış kişi"
  },
  {
    "x": "ternayene-I",
    "y": " boşamak"
  },
  {
    "x": "ternayene-II",
    "y": " b. teriknayene"
  },
  {
    "x": "ternayîş (n)",
    "y": " boşama"
  },
  {
    "x": "terne (n)",
    "y": " keş"
  },
  {
    "x": "ternezayî (m)",
    "y": " b. ternza (m)"
  },
  {
    "x": "terni (m)",
    "y": " b. terne (m)"
  },
  {
    "x": "terni (n)",
    "y": " b. terne (n)"
  },
  {
    "x": "ternza (m)",
    "y": " yeni doğurmuş olan hayvan"
  },
  {
    "x": "terpin (n)",
    "y": " b. terpîn (n)"
  },
  {
    "x": "terpîn (n)",
    "y": " aşı (tıpta)"
  },
  {
    "x": "terpîn bîyene",
    "y": " aşılanmak (tıpta)"
  },
  {
    "x": "terpîn kerdene",
    "y": " aşılamak (tıpta)"
  },
  {
    "x": "terpînbîyayîş (n)",
    "y": " aşılanma (tıpta)"
  },
  {
    "x": "terpînkerdiş (n)",
    "y": " aşılama (tıpta)"
  },
  {
    "x": "terq (n)",
    "y": " terk (n)"
  },
  {
    "x": "terqayîne",
    "y": " b. terikîyayene"
  },
  {
    "x": "terrafi (m)",
    "y": " b. tirafe (m)"
  },
  {
    "x": "terraqe (m)",
    "y": " sohbet, söyleşi (TE)"
  },
  {
    "x": "terraqi (m)",
    "y": " b. terraqe (m)"
  },
  {
    "x": "terraqnayene",
    "y": " sohbet etmek"
  },
  {
    "x": "terraqnayîş (n)",
    "y": " sohbet etme"
  },
  {
    "x": "terraş (n)",
    "y": " b. tiraş (n)"
  },
  {
    "x": "terreqnayene",
    "y": " b. terraqnayene"
  },
  {
    "x": "terreqnayîş (n)",
    "y": " b. terraqnayîş (n)"
  },
  {
    "x": "terrik (n)",
    "y": " sopa"
  },
  {
    "x": "terrine (n)",
    "y": " b. terne (n)"
  },
  {
    "x": "terriqnaya (m)",
    "y": " kızartılmış olan (ekmek vb. için)"
  },
  {
    "x": "terriqnaye (n)",
    "y": " kızartılmış olan (ekmek vb. için)"
  },
  {
    "x": "terriqnayene-I",
    "y": " kızartmak (ekmek vb. için)"
  },
  {
    "x": "terriqnayene-II",
    "y": " b. terraqnayene"
  },
  {
    "x": "terriqnayîş (n)",
    "y": " kızartma (ekmek vb. için) "
  },
  {
    "x": "terriqnayîş (n)",
    "y": " b. terraqnayîş (n)"
  },
  {
    "x": "terriqnayîye (m)",
    "y": " kızartılmış olan (ekmek vb. için)"
  },
  {
    "x": "terrişnayene",
    "y": " b. terişnayene"
  },
  {
    "x": "terrî (m)",
    "y": " b. tirrî (m)"
  },
  {
    "x": "terrîye (m)",
    "y": " at kuyruğu"
  },
  {
    "x": "terrn, -e",
    "y": " b. tern, -e"
  },
  {
    "x": "terrne (n)",
    "y": " b. terne (n)"
  },
  {
    "x": "ters (n)",
    "y": " korku"
  },
  {
    "x": "tersê canî (n)",
    "y": " can korkusu "
  },
  {
    "x": "tersê ganî (n)",
    "y": " can korkusu "
  },
  {
    "x": "tersê mergî (n)",
    "y": " can korkusu "
  },
  {
    "x": "tersî cûnî (n)",
    "y": " b. tersê canî (n)"
  },
  {
    "x": "tersî reyde",
    "y": " çekine çekine, çekinerek"
  },
  {
    "x": "tersî reyra",
    "y": " çekine çekine, çekinerek"
  },
  {
    "x": "tersayene",
    "y": " korkmak"
  },
  {
    "x": "tersayîş (n)",
    "y": " korkma"
  },
  {
    "x": "tersnayene",
    "y": " korkutmak"
  },
  {
    "x": "tersnayîş (n)",
    "y": " korkutma"
  },
  {
    "x": "terş (n)",
    "y": " küçükbaş hayvan sürüsü"
  },
  {
    "x": "tertele (n)",
    "y": " çapul, plaçka, talan, yağma"
  },
  {
    "x": "tertele kerdene",
    "y": " yağmalamak, talanlamak, talan etmek, yağma etmek"
  },
  {
    "x": "terteleçênî (m)",
    "y": " b. yaxmekerdoxîye (m)"
  },
  {
    "x": "terteleçî, -ye",
    "y": " çapulcu¸ talancı, yağmacı"
  },
  {
    "x": "terteleçîyênî (m)",
    "y": " çapulculuk¸ talancılık, yağmacılık"
  },
  {
    "x": "terteleçîyîye (m)",
    "y": " çapulculuk, talancılık, yağmacılık"
  },
  {
    "x": "tertelekerdiş (n)",
    "y": " yağmalama, talanlama, talan etme, yağma etme"
  },
  {
    "x": "tertene",
    "y": " b. teritene"
  },
  {
    "x": "tertiş (n)",
    "y": " b. teritiş (n)"
  },
  {
    "x": "tertîb (n)",
    "y": " tertip"
  },
  {
    "x": "tertîb kerdene",
    "y": " düzenlemek, tanzim etmek; tertiplemek, tertip etmek"
  },
  {
    "x": "tertîbê butçe (n)",
    "y": " bütçe tertibi"
  },
  {
    "x": "tertîbkar, -e",
    "y": " düzenleyici, tertipleyici, organizatör"
  },
  {
    "x": "tertîbkerdiş (n)",
    "y": " düzenleme, düzeltme, tanzim, tanzim etme; tertip etme, tertipleme"
  },
  {
    "x": "tertul (n)",
    "y": " tırtıl"
  },
  {
    "x": "tertûr (n)",
    "y": " b. tertul (n)"
  },
  {
    "x": "terve (m)",
    "y": " b. derbe (m)"
  },
  {
    "x": "terzê nezerî (n)",
    "y": " yaklaşım (ele alış veya bakış biçimi)"
  },
  {
    "x": "terzênî (m)",
    "y": " b. terzîyênî (m)"
  },
  {
    "x": "terzî, -ye",
    "y": " terzi"
  },
  {
    "x": "terzîya cinîyan (m)",
    "y": " kadın terzisi"
  },
  {
    "x": "terzîyê cinîyan (n)",
    "y": " kadın terzisi"
  },
  {
    "x": "terzîtî (m)",
    "y": " terzilik"
  },
  {
    "x": "terzîxane (n)",
    "y": " terzihane"
  },
  {
    "x": "terzîxone (n)",
    "y": " b. terzîxane (n)"
  },
  {
    "x": "terzîxûne (n)",
    "y": " b. terzîxane (n)"
  },
  {
    "x": "terzîyey (m)",
    "y": " b. terzîyî (m), terzîyîye (m)"
  },
  {
    "x": "terzîyê (m)",
    "y": " b. terzîyî (m), terzîyîye (m)"
  },
  {
    "x": "terzîyênî (m)",
    "y": " terzilik"
  },
  {
    "x": "terzîyî (m)",
    "y": " terzilik"
  },
  {
    "x": "terzîyîye (m)",
    "y": " terzilik"
  },
  {
    "x": "tesbît (n)",
    "y": " saptama, tespit"
  },
  {
    "x": "tesbît kerdene",
    "y": " belirlemek, saptamak, tespit etmek"
  },
  {
    "x": "tesbîtkerdiş (n)",
    "y": " belirleme, saptama, tespit etme"
  },
  {
    "x": "tescîl (n)",
    "y": " tescil"
  },
  {
    "x": "tescîlê trafîkî (n)",
    "y": " trafik tescil"
  },
  {
    "x": "tescîlkerda (m)",
    "y": " tescilli"
  },
  {
    "x": "tescîlkerde (n)",
    "y": " tescilli"
  },
  {
    "x": "tescîlkerdîye (m)",
    "y": " tescilli"
  },
  {
    "x": "tesdîq (n)",
    "y": " onay, tasdik"
  },
  {
    "x": "belgeyê tesdîqî (n)",
    "y": " onay belgesi"
  },
  {
    "x": "tesdîq bîyene",
    "y": " onaylanmak, tasdiklenmek, tasdik edilmek"
  },
  {
    "x": "tesdîq kerdene",
    "y": " onaylamak, tasdik etmek"
  },
  {
    "x": "tesdîqê benoyî",
    "y": " olur onayı"
  },
  {
    "x": "tesdîqê \"e\"yî",
    "y": " olur onayı"
  },
  {
    "x": "tesdîqê \"ya\"yî",
    "y": " olur onayı"
  },
  {
    "x": "tesdîqbîyayîş (n)",
    "y": " onaylanma, tasdiklenme, tasdik edilme"
  },
  {
    "x": "tesdîqbîyayîşê projeyî (n)",
    "y": " projenin onaylanması, projenin tasdik edilmesi"
  },
  {
    "x": "tesdîqkerdiş (n)",
    "y": " 1)onaylama, tasdik etme\r\n~2)muvafakat"
  },
  {
    "x": "tesdîqkerdox, -e",
    "y": " tasdik eden"
  },
  {
    "x": "teselî (m)",
    "y": " b. teselîya ... kewtene, b. teselîya ... vistene"
  },
  {
    "x": "teselîya ... kewtene",
    "y": " caymak, vazgeçmek, vazgelmek"
  },
  {
    "x": "teselîya ... kewtiş",
    "y": " cayma, vazgeçme, vazgelme"
  },
  {
    "x": "teselîya ... vistene",
    "y": " caydırmak, vazgeçirmek"
  },
  {
    "x": "teselîya ... vistiş",
    "y": " caydırma, vazgeçirme "
  },
  {
    "x": "teselîvistox, -e",
    "y": " caydırıcı "
  },
  {
    "x": "teselîvistoxê (m)",
    "y": " b. teselîvistoxîye (m)"
  },
  {
    "x": "teselîvistoxîye (m)",
    "y": " caydırıcılık "
  },
  {
    "x": "tesewir (n)",
    "y": " b. tesewur (n)"
  },
  {
    "x": "tesewur (n)",
    "y": " tasarım, tasavvur"
  },
  {
    "x": "tesewur kerdene",
    "y": " tasavvur etmek"
  },
  {
    "x": "tesewurê vengî (sn)",
    "y": " ses tasarımı"
  },
  {
    "x": "tesewurî, -ye",
    "y": " sanal"
  },
  {
    "x": "tesewurkerdiş (n)",
    "y": " tasavvur etme"
  },
  {
    "x": "tesfîye (m)",
    "y": " tasfiye"
  },
  {
    "x": "tesfîyeyê çamure (m)",
    "y": " çamur tasfiyesi"
  },
  {
    "x": "tesfîyeyê lince (m)",
    "y": " çamur tasfiyesi"
  },
  {
    "x": "tesfîyeyo bişutiş (n)",
    "y": " yıkayarak tasfiye"
  },
  {
    "x": "tesil, -e",
    "y": " kesin"
  },
  {
    "x": "tesilî (m)",
    "y": " kesinlik"
  },
  {
    "x": "tesilîye (m)",
    "y": " kesinlik"
  },
  {
    "x": "tesîr (n)",
    "y": " etki, tesir"
  },
  {
    "x": "tesîr cidayene",
    "y": " etkilemek, tesir etmek"
  },
  {
    "x": "tesîr de mendene",
    "y": " etkilenmek, müteessir olmak"
  },
  {
    "x": "tesîr kerdene",
    "y": " etkilemek, tesir etmek"
  },
  {
    "x": "tesîrê lojine (n)",
    "y": " baca etkisi"
  },
  {
    "x": "tesîrê pixêrî (n)",
    "y": " baca etkisi"
  },
  {
    "x": "tesîro bolker (n)",
    "y": " çoğaltıcı etki"
  },
  {
    "x": "tesîro çorşmeyî (n)",
    "y": " çevresel etki"
  },
  {
    "x": "tesîro dorûverî (n)",
    "y": " çevresel etki"
  },
  {
    "x": "tesîro zafker (n)",
    "y": " çoğaltıcı etki"
  },
  {
    "x": "tesîro zîyadeker (n)",
    "y": " çoğaltıcı etki"
  },
  {
    "x": "tesîrdar, -e",
    "y": " etkili, etkin, faal"
  },
  {
    "x": "tesîrdarî (m)",
    "y": " etkinlik"
  },
  {
    "x": "tesîrdarîye (m)",
    "y": " etkinlik"
  },
  {
    "x": "tesîs (n)",
    "y": " kuruluş, tesis"
  },
  {
    "x": "tesîs kerdene",
    "y": " kurmak (parti, dernek, şirket, ortaklık, fabrika vb. için)"
  },
  {
    "x": "tesîsê sihetî (n)",
    "y": " sağlık tesisi"
  },
  {
    "x": "tesîso cematkî (n)",
    "y": " sosyal tesis"
  },
  {
    "x": "tesîso îdarî (n)",
    "y": " idari tesis"
  },
  {
    "x": "tesîsat (n)",
    "y": " tesisat, döşem; donanım"
  },
  {
    "x": "tesîsatê zereyî (n)",
    "y": " iç tesisat"
  },
  {
    "x": "tesîsato pawitox (n)",
    "y": " koruyucu donanım"
  },
  {
    "x": "tesîsato seveknayox (n)",
    "y": " koruyucu donanım"
  },
  {
    "x": "tesîsatkar, -e",
    "y": " tesisatçı, döşemci"
  },
  {
    "x": "tesîsatkara/tesîsatkarê awe",
    "y": " su tesisatçısı"
  },
  {
    "x": "tesîsatkara/tesîsatkarê elektrîkî",
    "y": " elektrik tesisatçısı"
  },
  {
    "x": "tesîsatkarî (m)",
    "y": " tesisatçılık, döşemcilik"
  },
  {
    "x": "tesîsatkarîye (m)",
    "y": " tesisatçılık, döşemcilik"
  },
  {
    "x": "tesîskerdox, -e",
    "y": " kurucu"
  },
  {
    "x": "teskera (m)",
    "y": " b. teşkere (n)"
  },
  {
    "x": "teskere (n)",
    "y": " b. teşkere (n)"
  },
  {
    "x": "teslî kewtene",
    "y": " b. teselîya ... kewtene"
  },
  {
    "x": "teslî vistene",
    "y": " b. teselîya ... vistene"
  },
  {
    "x": "teslîkewtiş (n)",
    "y": " b. teselîya ... kewtiş"
  },
  {
    "x": "teslîm (n)",
    "y": " teslim"
  },
  {
    "x": "teslîm bîyene",
    "y": " teslim olmak"
  },
  {
    "x": "teslîm girewtene",
    "y": " teslim almak"
  },
  {
    "x": "teslîm kerdene",
    "y": " teslim etmek"
  },
  {
    "x": "teslîmî rê amade",
    "y": " teslime hazır"
  },
  {
    "x": "teslîmbîyayîş (n)",
    "y": " teslim olma, teslimiyet"
  },
  {
    "x": "teslîmîyet (n)",
    "y": " teslim olma, teslimiyet"
  },
  {
    "x": "teslîmîyetkar, -e",
    "y": " teslimiyetçi"
  },
  {
    "x": "teslîvistiş (n)",
    "y": " b. teselîya ... vistiş"
  },
  {
    "x": "teslîvistox, -e",
    "y": " b. teselîvistox, -e"
  },
  {
    "x": "teslîvistoxey (m)",
    "y": " b. teselîvistoxîye (m)"
  },
  {
    "x": "tesmîl (n)",
    "y": " b. teslîm (n)"
  },
  {
    "x": "tesnîf (n)",
    "y": " bölümleme, klasifikasyon, sınıflandırma, tasnif"
  },
  {
    "x": "tesnîf kerdene",
    "y": " bölümlemek, sınıflandırmak, sınıflamak, tasnif etmek, tasniflemek"
  },
  {
    "x": "tesnîfkerdiş (n)",
    "y": " bölümleme, sınıflandırma, sınıflama, tasnif etme, tasnifleme"
  },
  {
    "x": "tespît (n)",
    "y": " b. tesbît (n)"
  },
  {
    "x": "tesrîh (n)",
    "y": " belirtme"
  },
  {
    "x": "tesrîh kerdene",
    "y": " belirtmek"
  },
  {
    "x": "test (n)",
    "y": " test"
  },
  {
    "x": "testê alkolî (n)",
    "y": " alkol testi"
  },
  {
    "x": "teswîye (n)",
    "y": " tesviye"
  },
  {
    "x": "teswîye kerdene",
    "y": " tesviye etmek"
  },
  {
    "x": "teswîyekar, -e",
    "y": " tesviyeci"
  },
  {
    "x": "teswîyekarî (m)",
    "y": " tesviyecilik"
  },
  {
    "x": "teswîyekarîye (m)",
    "y": " tesviyecilik"
  },
  {
    "x": "teşbîh (n)",
    "y": " benzetme (edebîyat de)"
  },
  {
    "x": "teşîr (n)",
    "y": " b. tesîr (n)"
  },
  {
    "x": "teşîye (m)",
    "y": " iğ, eğirmen, kirmen"
  },
  {
    "x": "teşkere (n)",
    "y": " teskere (gereç taşımakta kullanılan)"
  },
  {
    "x": "teşkeri (n)",
    "y": " b. teşkere (n)"
  },
  {
    "x": "teşkîl bîyene",
    "y": " oluşmak"
  },
  {
    "x": "teşkîl kerdene",
    "y": " oluşturmak"
  },
  {
    "x": "teşkîl bîyayene",
    "y": " b. teşkîl bîyene"
  },
  {
    "x": "teşne (m)",
    "y": " susuzluk (susamış olma durumu)\r\n~Ez bineyke teşna xo bişiknî. (Susuzluğumu biraz gidereyim.)"
  },
  {
    "x": "teşqele (n)",
    "y": " b. teşxele (n)"
  },
  {
    "x": "teşqeli (n)",
    "y": " b. teşxele (n)"
  },
  {
    "x": "teşrîf (n)",
    "y": " teşrif"
  },
  {
    "x": "teşrîfat (n)",
    "y": " teşrifat"
  },
  {
    "x": "teşrîfatkar, -e",
    "y": " yer gösterici, teşrifatçı"
  },
  {
    "x": "teşrîfatkarî (m)",
    "y": " teşrifatçılık"
  },
  {
    "x": "teşrîfatkarîye (m)",
    "y": " teşrifatçılık"
  },
  {
    "x": "teşrîn (m)",
    "y": " b. teşrîna peyêne (m), teşrîna verêne (m)"
  },
  {
    "x": "teşrîne (m)",
    "y": " b. teşrîna peyêne (m), teşrîna verêne (m)"
  },
  {
    "x": "teşrîna peyêne (m)",
    "y": " kasım "
  },
  {
    "x": "teşrîna verêne (m)",
    "y": " ekim"
  },
  {
    "x": "teşrîni (m)",
    "y": " b. teşrîna peyêne (m), teşrîna verêne (m)"
  },
  {
    "x": "teşt (n)",
    "y": " teşt "
  },
  {
    "x": "teştare (n)",
    "y": " öğle"
  },
  {
    "x": "teştareyî ra pey",
    "y": " öğleden sonra"
  },
  {
    "x": "teştareyî ra ver",
    "y": " öğleden önce"
  },
  {
    "x": "teştarî ra pey",
    "y": " b. teştareyî ra pey"
  },
  {
    "x": "teştarî ra ver",
    "y": " b. teştareyî ra ver"
  },
  {
    "x": "teştareyê",
    "y": " b. teştareyî"
  },
  {
    "x": "teştareyî",
    "y": " öğleyin (öğle vakti)"
  },
  {
    "x": "teştarê",
    "y": " b. teştareyî "
  },
  {
    "x": "teşte (m)",
    "y": " b. teşt (n)"
  },
  {
    "x": "teştî (m)",
    "y": " b. taştî (m)"
  },
  {
    "x": "teşwîq (n)",
    "y": " özendirme, teşvik"
  },
  {
    "x": "teşwîq bîyene",
    "y": " özendirilmek, teşvik edilmek"
  },
  {
    "x": "teşwîq kerdene",
    "y": " özendirmek, teşvik etmek"
  },
  {
    "x": "teşwîqbîyayîş (n)",
    "y": " özendirilme, teşvik edilme"
  },
  {
    "x": "teşwîqkerdiş (n)",
    "y": " özendirme, teşvik etme"
  },
  {
    "x": "teşxele (n)",
    "y": " kargaşa"
  },
  {
    "x": "tetanos (n)",
    "y": " tetanos, kazıklıhumma"
  },
  {
    "x": "tetbîq (n) (TE)",
    "y": " uygulama programı"
  },
  {
    "x": "tetbîqî, -ye",
    "y": " uygulamalı, pratik, amelî, tatbikî "
  },
  {
    "x": "teterxan bîyayene",
    "y": " b. tetirxan bîyene"
  },
  {
    "x": "teterxanî bîyayeni",
    "y": " b. tetirxan bîyene"
  },
  {
    "x": "teterxon bîyayîne",
    "y": " b. tetirxan bîyene"
  },
  {
    "x": "teterxûn bîyayîne",
    "y": " b. tetirxan bîyene"
  },
  {
    "x": "tetirxan bîyene",
    "y": " kahrından ölmek, üzüntüden ölmek"
  },
  {
    "x": "tetirxanî bîyayeni",
    "y": " b. tetirxan bîyene"
  },
  {
    "x": "tetirxanî bîyene",
    "y": " b. tetirxan bîyene"
  },
  {
    "x": "tetirxon bîyayîne",
    "y": " b. tetirxan bîyene"
  },
  {
    "x": "tetirxûn bîyayîne",
    "y": " b. tetirxan bîyene"
  },
  {
    "x": "tetîl (n)",
    "y": " b. tehtîl (n)"
  },
  {
    "x": "tetîlo resmî (n)",
    "y": " b. tehtîlo resmî (n)"
  },
  {
    "x": "tetu (n)",
    "y": " b. tutin (n)"
  },
  {
    "x": "tetun (n)",
    "y": " b. tutin (n)"
  },
  {
    "x": "tetûlik (n)",
    "y": " b. çeçûlik (n)"
  },
  {
    "x": "tevat (n)",
    "y": " b. tebat (n)"
  },
  {
    "x": "tevat ci kewtene",
    "y": " b. tebat ci kewtene"
  },
  {
    "x": "tevat kerdene",
    "y": " b. tebat kerdene"
  },
  {
    "x": "tevatcikewtiş (n)",
    "y": " b. tebatcikewtiş (n)"
  },
  {
    "x": "tevatîyayene",
    "y": " b. tebitîyayene"
  },
  {
    "x": "tevatîyayîş (n)",
    "y": " b. tebitîyayîş (n)"
  },
  {
    "x": "tevatkerdiş (n)",
    "y": " b. tebatkerdiş (n)"
  },
  {
    "x": "tevax (n)",
    "y": " b. tebaq (n)"
  },
  {
    "x": "tevdîr (n)",
    "y": " b. tedbîr (n)"
  },
  {
    "x": "tevdîre (m)",
    "y": " üstkol"
  },
  {
    "x": "tever (n)",
    "y": " b. teber (n)"
  },
  {
    "x": "tever a",
    "y": " b. teber ra"
  },
  {
    "x": "tever kerdene",
    "y": " b. teber kerdene"
  },
  {
    "x": "teverê teverî",
    "y": " b. teberê teberan"
  },
  {
    "x": "teverkerdiş (n)",
    "y": " b. teberkerdiş (n)"
  },
  {
    "x": "tevger (n)",
    "y": " hareket"
  },
  {
    "x": "tevgero milî (n)",
    "y": " ulusal hareket"
  },
  {
    "x": "tevgero neteweyî (n)",
    "y": " ulusal hareket "
  },
  {
    "x": "tevgerdar, -e",
    "y": " hareketli"
  },
  {
    "x": "tevgerdarîye (m)",
    "y": " hareketlilik, mobilite"
  },
  {
    "x": "tevrik (n)",
    "y": " çapa"
  },
  {
    "x": "tevza (m)",
    "y": " b. tezbîhe (m)"
  },
  {
    "x": "tew-I (m)",
    "y": " b. tewe-I (m)"
  },
  {
    "x": "tew-II (zr)",
    "y": " b. tewr-I (zr)"
  },
  {
    "x": "tew-III",
    "y": " b. tewlî"
  },
  {
    "x": "tewdê",
    "y": " b. tewlî"
  },
  {
    "x": "tewa (n)",
    "y": " b. teba (n)"
  },
  {
    "x": "teway",
    "y": " b. tebayê"
  },
  {
    "x": "tewey",
    "y": " b. tebayê"
  },
  {
    "x": "tewalet (n)",
    "y": " b. tuwalet (n)"
  },
  {
    "x": "tewayene",
    "y": " ağrımak"
  },
  {
    "x": "tewayîne",
    "y": " b. tewayene"
  },
  {
    "x": "tewayîş (n)",
    "y": " ağrıma"
  },
  {
    "x": "tewayîşê pîzî (n)",
    "y": " karın ağrısı"
  },
  {
    "x": "tewe-I (m)",
    "y": " 1)sancı\r\n~tewa sereyî (m): baş ağrısı\r\n~ 2)ateşli hastalık nöbeti, nöbet \r\n~3)sıtma nöbeti"
  },
  {
    "x": "tewa ... girewtene",
    "y": " derdine düşmek\r\n~\tEy tewa pereyan girewtibî. (O para derdine düşmüştü.)"
  },
  {
    "x": "tewa sarî (m)",
    "y": " b. tewa sereyî (m)"
  },
  {
    "x": "tewa sereyî (m)",
    "y": " baş ağrısı"
  },
  {
    "x": "tewa serî (m)",
    "y": " b. tewa sereyî (m)"
  },
  {
    "x": "tewe degirewtene",
    "y": " sıtma nöbetine tutulmak"
  },
  {
    "x": "tewe girewtene",
    "y": " sıtma nöbetine tutulmak"
  },
  {
    "x": "tewey ... girotene",
    "y": " b. tewa ... girewtene"
  },
  {
    "x": "tewe-II (n)",
    "y": " b. tawe-I (n)"
  },
  {
    "x": "tewer (n)",
    "y": " b. teber (n)"
  },
  {
    "x": "tewetîyayene",
    "y": " b. tebitîyayene"
  },
  {
    "x": "tewetîyayîş (n)",
    "y": " b. tebitîyayîş (n)"
  },
  {
    "x": "tewhîd (n)",
    "y": " tevhit"
  },
  {
    "x": "tewi (m)",
    "y": " b. tewe-I (m)"
  },
  {
    "x": "tewi girotene",
    "y": " b. tewe girewtene"
  },
  {
    "x": "tewig (n)",
    "y": " b. tawug (n)"
  },
  {
    "x": "tewik (n)",
    "y": " b. tawug (n)"
  },
  {
    "x": "tewir (n)",
    "y": " tür\r\n~No kar di tewirî beno. (Bu iş iki türlü olur.)\r\n~no tewir: bu tür, böyle\r\n~o tewir: o tür, öyle\r\n~tewirê fîlmî (n): filmin türü"
  },
  {
    "x": "tewir bi tewir",
    "y": " türlü türlü"
  },
  {
    "x": "tewirê fîlmî (n)",
    "y": " filmin türü"
  },
  {
    "x": "tewir-tewir",
    "y": " türlü türlü"
  },
  {
    "x": "tewirna",
    "y": " 1)değişik\r\n~2)apayrı, bambaşka"
  },
  {
    "x": "tewirna bîyene",
    "y": " başkalaşmak"
  },
  {
    "x": "tewirnabîyayîş (n)",
    "y": " başkalaşma"
  },
  {
    "x": "tewiştene",
    "y": " b. tepîştene"
  },
  {
    "x": "tewiştiş (n)",
    "y": " b. tepîştiş (n)"
  },
  {
    "x": "tewîl (n)",
    "y": " tevil, çevri"
  },
  {
    "x": "tewîştene",
    "y": " b. tepîştene"
  },
  {
    "x": "tewîştiş (n)",
    "y": " b. tepîştiş (n)"
  },
  {
    "x": "tewla-I (m)",
    "y": " b. tawla (m)"
  },
  {
    "x": "tewla-II (n)",
    "y": " b. tewle (n)"
  },
  {
    "x": "tewle (n)",
    "y": " tavla (at ahırı)"
  },
  {
    "x": "tewlê",
    "y": " b. tewlî"
  },
  {
    "x": "tewlî",
    "y": " birlikte"
  },
  {
    "x": "tewq-I (n)",
    "y": " tasma (demirden olanı)"
  },
  {
    "x": "tewq-II (m)",
    "y": " b. tewqe-I (m)"
  },
  {
    "x": "tewq-III (m)",
    "y": " b. dewqe (m)"
  },
  {
    "x": "tewqa ... şîyayene",
    "y": " b. dewqa ... şîyene"
  },
  {
    "x": "tewqa (m)",
    "y": " b. toqe (n)"
  },
  {
    "x": "tewqe-I (m)",
    "y": " 1)sac (dışbükey pişirme aracı)\r\n~2)çanak anten"
  },
  {
    "x": "tewqa satelîtî (m)",
    "y": " çanak anten"
  },
  {
    "x": "tewqe-II (m)",
    "y": " b. dewqe"
  },
  {
    "x": "tewqi-I (m)",
    "y": " b. tewqe-I (m)"
  },
  {
    "x": "tewqi-II (m)",
    "y": " b. dewqe (m)"
  },
  {
    "x": "tewqişîyayîş (n)",
    "y": " b. dewqeşîyayîş (n)"
  },
  {
    "x": "tewqîf (n)",
    "y": " tutuklama, tevkif "
  },
  {
    "x": "tewqîf kerdene",
    "y": " tutuklamak, tevkif etmek"
  },
  {
    "x": "tewqîfkerda (m)",
    "y": " tutuklu"
  },
  {
    "x": "tewqîfkerde (n)",
    "y": " tutuklu"
  },
  {
    "x": "tewqîfkerdiş (n)",
    "y": " tutuklama, tevkif etme, tevkif"
  },
  {
    "x": "tewqîfkerdîye (m)",
    "y": " tutuklu"
  },
  {
    "x": "tewr-I (zr)",
    "y": " 1)en\r\n~tewr tay: en az, asgari\r\n~2)hiç\r\n~3)hatta\r\n~Tewr zurî zî keno. (Hatta yalan da söyler.)"
  },
  {
    "x": "tewr bol",
    "y": " azami, en çok"
  },
  {
    "x": "tewr girîng, -e",
    "y": " başlıca (s)"
  },
  {
    "x": "tewr kêmî",
    "y": " en az, asgari"
  },
  {
    "x": "tewr muhîm, -e",
    "y": " başlıca (s)"
  },
  {
    "x": "tewr peynîye de",
    "y": " böylece, böylecene, böylelikle"
  },
  {
    "x": "tewr raver",
    "y": " ilkönce, en önce"
  },
  {
    "x": "tewr şenik",
    "y": " en az, asgari"
  },
  {
    "x": "tewr tay",
    "y": " en az, asgari"
  },
  {
    "x": "tewr tebe",
    "y": " b. tewr teber"
  },
  {
    "x": "tewr teber",
    "y": " en dış"
  },
  {
    "x": "tewr tîkê",
    "y": " en az, asgari"
  },
  {
    "x": "tewr verê",
    "y": " ilkönce, en önce"
  },
  {
    "x": "tewr verên, -e",
    "y": " başlıca (s)"
  },
  {
    "x": "tewr vêşî",
    "y": " en çok, azami"
  },
  {
    "x": "tewr zaf",
    "y": " en çok, azami"
  },
  {
    "x": "tewr zîyade",
    "y": " en çok, azami, en fazla"
  },
  {
    "x": "tewr-II",
    "y": " b. tewlî"
  },
  {
    "x": "tewr bîyayene",
    "y": " b. tewr bîyene"
  },
  {
    "x": "tewr bîyene",
    "y": " katılmak"
  },
  {
    "x": "tewr kerdene",
    "y": " katmak"
  },
  {
    "x": "tewrdê",
    "y": " b. tewlî"
  },
  {
    "x": "tewrî ... a",
    "y": " b. tewlî ... a"
  },
  {
    "x": "tewran, -e",
    "y": " sıtmalı"
  },
  {
    "x": "tewranîye (m)",
    "y": " sıtma, malarya"
  },
  {
    "x": "tewranîye girewtene",
    "y": " sıtmaya yakalanmak, sıtma tutmak\r\n~O sıtmaya yakalanmış. (Ey tewranîye girewta.)"
  },
  {
    "x": "tewranîye kewtene",
    "y": " sıtmaya yakalanmak, sıtma tutmak"
  },
  {
    "x": "tewranîye pira niştene",
    "y": " sıtmaya yakalanmak, sıtma tutmak"
  },
  {
    "x": "Tewrat (n)",
    "y": " Ahd-i Atik, Tevrat"
  },
  {
    "x": "tewrbîyayîş (n)",
    "y": " katılma, katılım"
  },
  {
    "x": "tewrbîyayox, -e",
    "y": " katılımcı"
  },
  {
    "x": "tewrbîyayoxî (m)",
    "y": " katılımcılık"
  },
  {
    "x": "tewre (n)",
    "y": " torba\r\n~tewreyê cendegî (n): ceset torbası\r\n~tewreyo kaxitên (n): kağıt torba"
  },
  {
    "x": "tewreyê aqilî (n)",
    "y": " akıl küpü"
  },
  {
    "x": "tewreyê cendegî (n)",
    "y": " ceset torbası"
  },
  {
    "x": "tewreyê çopî (n)",
    "y": " çöp torbası"
  },
  {
    "x": "tewreyo kaxitên (n)",
    "y": " kağıt torba"
  },
  {
    "x": "tewreyo naylonên (n)",
    "y": " naylon torba"
  },
  {
    "x": "tewrê",
    "y": " b. tewlî"
  },
  {
    "x": "tewrî",
    "y": " b. Tewlî\r\n~“Ey na lay de zaf rey tewrî kênayanî dew zitûbit asnaw kerdibî.” (Vate, hûmare 31, r. 107)"
  },
  {
    "x": "tewrkerda (m)",
    "y": " katma"
  },
  {
    "x": "tewrkerde (n)",
    "y": " katma"
  },
  {
    "x": "tewrkerdiş (n)",
    "y": " katma"
  },
  {
    "x": "tewrkerdîye (m)",
    "y": " katma"
  },
  {
    "x": "tewrûn, -e",
    "y": " b. tewran, -e"
  },
  {
    "x": "tewrzafkerdiş (n)",
    "y": " azamileştirme"
  },
  {
    "x": "tews, -e",
    "y": " b. tewş, -e"
  },
  {
    "x": "tews bîyene",
    "y": " b. tewş bîyene"
  },
  {
    "x": "tews kerdene",
    "y": " b. tewş kerdene"
  },
  {
    "x": "tewsîye (m)",
    "y": " referans, tavsiye"
  },
  {
    "x": "tewş, -e",
    "y": " 1)ters\r\n~2)acayip, garip, gariplik; abes (akla ve gerçeğe aykırı)\r\n~3)sersem, alık"
  },
  {
    "x": "tewş bîyene",
    "y": " sersemleşmek, sersemlemek; acayipleşmek"
  },
  {
    "x": "tewş kerdene",
    "y": " sersemletmek, sersem etmek; acayipleştirmek"
  },
  {
    "x": "tewşbîyayîş (n)",
    "y": " sersemleşme, acayipleşme"
  },
  {
    "x": "tewşey (m)",
    "y": " b. tewşîye (m)"
  },
  {
    "x": "tewşê (m)",
    "y": " b. tewşî (m)"
  },
  {
    "x": "tewşî (m)",
    "y": " sersemlik, alıklık; acayiplik"
  },
  {
    "x": "tewşîye (m)",
    "y": " sersemlik, alıklık; acayiplik"
  },
  {
    "x": "tewşkanî",
    "y": " 1)ters\r\n~2)acayip bir şekilde, acayip bir biçimde\r\n~No mêrdek verê mi ra ewnîya, dima tewşkanî qalî kerd. (Bu adam önce bana baktı, sonra ters [biçimde] konuşmaya başladı.)"
  },
  {
    "x": "tewşkerdiş (n)",
    "y": " sersemletme, acayipleştirme"
  },
  {
    "x": "tewşkî",
    "y": " acayip bir şekilde, acayip bir biçimde"
  },
  {
    "x": "tewur (n)",
    "y": " b. tewir (n)"
  },
  {
    "x": "tewzîh (n)",
    "y": " belirtme"
  },
  {
    "x": "tewzîh kerdene",
    "y": " belirtmek"
  },
  {
    "x": "texelayene",
    "y": " b. texelîyayene"
  },
  {
    "x": "texelîyaene",
    "y": " b. texelîyayene"
  },
  {
    "x": "texelîyaîs (n)",
    "y": " b. texelîyayîş (n)"
  },
  {
    "x": "texelîyayene",
    "y": " caymak, vazgeçmek¸ vazgelmek"
  },
  {
    "x": "texelîyayîş (n)",
    "y": " cayma, vazgeçme, vazgelme "
  },
  {
    "x": "texelnaene",
    "y": " b. texelnayene"
  },
  {
    "x": "texelnaîs (n)",
    "y": " b. texelnayîş (n)"
  },
  {
    "x": "texelnayene",
    "y": " caydırmak, vazgeçirmek"
  },
  {
    "x": "texelnayîş (n)",
    "y": " caydırma, vazgeçirme"
  },
  {
    "x": "texelnayox, -e",
    "y": " caydırıcı"
  },
  {
    "x": "texelnayoxîye (m)",
    "y": " caydırıcılık"
  },
  {
    "x": "texelyayene",
    "y": " b. texelîyayene"
  },
  {
    "x": "texelyayîş (n)",
    "y": " b. texelîyayîş (n)"
  },
  {
    "x": "texim (n)",
    "y": " b. taxim-I (n)"
  },
  {
    "x": "texlîya (m)",
    "y": " tahliye"
  },
  {
    "x": "texlîya bîyayene",
    "y": " b. texlîya bîyene"
  },
  {
    "x": "texlîya bîyene",
    "y": " tahliye edilmek"
  },
  {
    "x": "texlîya kerdene",
    "y": " tahliye etmek "
  },
  {
    "x": "texlîyaya şertine (m)",
    "y": " şartlı tahliye"
  },
  {
    "x": "texlîyabîyayîş (n)",
    "y": " tahliye edilme"
  },
  {
    "x": "texlîyakerdiş (n)",
    "y": " tahliye etme"
  },
  {
    "x": "texlîye (m)",
    "y": " b. texlîya (m)"
  },
  {
    "x": "texm (n)",
    "y": " b. taxim-I (n)"
  },
  {
    "x": "texmîn (n)",
    "y": " tahmin"
  },
  {
    "x": "texmîn bîyene",
    "y": " tahmin edilmek"
  },
  {
    "x": "texmîn kerdene",
    "y": " tahmin etmek, akıl yürütmek"
  },
  {
    "x": "texmînê butçe (m)",
    "y": " bütçe tahmini"
  },
  {
    "x": "texmînê ... kerdene",
    "y": " tahmin etmek"
  },
  {
    "x": "texmînê ... kerdiş",
    "y": " tahmin etme"
  },
  {
    "x": "texmînî reyde",
    "y": " tahmin ile, tahminen"
  },
  {
    "x": "texmînî reyra",
    "y": " tahmin ile, tahminen"
  },
  {
    "x": "texmînî",
    "y": " tahmini"
  },
  {
    "x": "texmînkerdiş (n)",
    "y": " tahmin etme, akıl yürütme"
  },
  {
    "x": "texmînkerdişê malbîyayîşî (n)",
    "y": " maliyet tahminleme"
  },
  {
    "x": "texmînkî",
    "y": " tahmini olarak"
  },
  {
    "x": "texsî (m)",
    "y": " b. taksî (m)"
  },
  {
    "x": "texsîmetre (n)",
    "y": " b. taksîmetre (n)"
  },
  {
    "x": "texsîr kerdene",
    "y": " b. teqsîr kerdene"
  },
  {
    "x": "texsîrkerdiş (n)",
    "y": " b. teqsîrkerdiş (n)"
  },
  {
    "x": "texsîs (n)",
    "y": " tahsis"
  },
  {
    "x": "texsîs kerdene",
    "y": " tahsis etmek"
  },
  {
    "x": "texsîskerdiş (n)",
    "y": " tahsis etme"
  },
  {
    "x": "texta (m)",
    "y": " b. texte (n)"
  },
  {
    "x": "texte (n)",
    "y": " tahta"
  },
  {
    "x": "texteyê nuştişî (n)",
    "y": " yazı tahtası, karatahta"
  },
  {
    "x": "texteyê soba (n)",
    "y": " soba altlığı"
  },
  {
    "x": "textebir, -e",
    "y": " doğramacı (tahta işleri için)"
  },
  {
    "x": "textebirênî (m)",
    "y": " doğramacılık (tahta işleri için)"
  },
  {
    "x": "textebirî (m)",
    "y": " doğramacılık (tahta işleri için)"
  },
  {
    "x": "textebirîye (m)",
    "y": " doğramacılık (tahta işleri için)"
  },
  {
    "x": "textekar, -e",
    "y": " doğramacı (tahta işleri için)"
  },
  {
    "x": "textekarênî (m)",
    "y": " doğramacılık (tahta işleri için)"
  },
  {
    "x": "textekarî (m)",
    "y": " doğramacılık (tahta işleri için)"
  },
  {
    "x": "textekarîye (m)",
    "y": " doğramacılık (tahta işleri için)"
  },
  {
    "x": "texteyên, -e",
    "y": " tahtadan yapılma"
  },
  {
    "x": "textik (n)",
    "y": " küçük tahta"
  },
  {
    "x": "textikê fese (n)",
    "y": " fes tahtası (fesin başa oturan kısmına tutturulan tahta)"
  },
  {
    "x": "textikê fose (n)",
    "y": " b. textikê fese (n)"
  },
  {
    "x": "textiki (m)",
    "y": " b. textik (n)"
  },
  {
    "x": "tey-I",
    "y": " 1)b. tenê-I\r\n~2)b. tay\r\n~3)b. tayê"
  },
  {
    "x": "tey-II",
    "y": " b. tede"
  },
  {
    "x": "tey bîyayene",
    "y": " tede bîyene"
  },
  {
    "x": "tey dejayene",
    "y": " b. ... de dejayene"
  },
  {
    "x": "tey hewl bîyene",
    "y": " b. tede hol bîyene"
  },
  {
    "x": "tey rakewtene",
    "y": " (kadınla) cinsel ilişkide bulunmak"
  },
  {
    "x": "tey rakotene",
    "y": " b. tey rakewtene"
  },
  {
    "x": "tey şa bîyene",
    "y": " b. tede şa bîyene"
  },
  {
    "x": "tey vajîyayene",
    "y": " azarlamak, paylamak, tekdir etmek"
  },
  {
    "x": "tey vecîyayene",
    "y": " b. tede vejîyayene"
  },
  {
    "x": "tey vetene",
    "y": " 1)hissetmek, sezinlemek, sezmek\r\n~2)b. tede vetene"
  },
  {
    "x": "tey-III (m)",
    "y": " b. teye (m)"
  },
  {
    "x": "teya",
    "y": " b. tîya"
  },
  {
    "x": "teyara (m)",
    "y": " uçak, tayyare"
  },
  {
    "x": "teyarageh (n)",
    "y": " havalimanı, havaalanı, uçak alanı, tayyare meydanı"
  },
  {
    "x": "teyare (n)",
    "y": " b. teyara (m)"
  },
  {
    "x": "teybe (m)",
    "y": " b. teyîbe (m)"
  },
  {
    "x": "teybîyayîş (n)",
    "y": " b. tedebîyayîş (n)"
  },
  {
    "x": "teye-I (m)",
    "y": " dardağan"
  },
  {
    "x": "teye-II (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "teyel (m)",
    "y": " b. tegel (n)"
  },
  {
    "x": "teyel kerdene",
    "y": " b. tegel kerdene"
  },
  {
    "x": "teyelin, -e",
    "y": " b. tegelin, -e"
  },
  {
    "x": "teyelkerda (m)",
    "y": " b. tegelkerda (m)"
  },
  {
    "x": "teyelkerde (n)",
    "y": " b. tegelkerde (n)"
  },
  {
    "x": "teyelkerdîye (m)",
    "y": " b. tegelkerdîye (m)"
  },
  {
    "x": "teyera (m)",
    "y": " b. teyara (m)"
  },
  {
    "x": "teyeri (m)",
    "y": " b. teyêre (m)"
  },
  {
    "x": "teyê",
    "y": " b. tayê"
  },
  {
    "x": "teyêre (m)",
    "y": " dardağan ağacı"
  },
  {
    "x": "teyhewlbîyayîş (n)",
    "y": " b. tedeholbîyayîş (n)"
  },
  {
    "x": "teyibi (m)",
    "y": " b. teyîbe (m)"
  },
  {
    "x": "teyic (m)",
    "y": " b. teyînce (m)"
  },
  {
    "x": "teyî",
    "y": " b. tede"
  },
  {
    "x": "teyîb (m)",
    "y": " b. teyîbe (m)"
  },
  {
    "x": "teyîbe (m)",
    "y": " teyp"
  },
  {
    "x": "teyîbi (m)",
    "y": " b. teyîbe (m)"
  },
  {
    "x": "teyîc (m)",
    "y": " b. teyînce (m)"
  },
  {
    "x": "teyîj (m)",
    "y": " b. teyînce (m)"
  },
  {
    "x": "teyîna",
    "y": " b. tena"
  },
  {
    "x": "teyînce (m)",
    "y": " koruk"
  },
  {
    "x": "teyînci (m)",
    "y": " b. teyînce (m)"
  },
  {
    "x": "teyk",
    "y": " b. tayê"
  },
  {
    "x": "teyke",
    "y": " b. tayêne"
  },
  {
    "x": "teyl-I (n)",
    "y": " 1)b. têl (n)\r\n~2)b. telgraf (n)"
  },
  {
    "x": "teyl-II (n)",
    "y": " b. tegel (n)"
  },
  {
    "x": "teyle (m)",
    "y": " dikiş"
  },
  {
    "x": "teylî piro dayene",
    "y": " dikiş atmak"
  },
  {
    "x": "teyli (m). ",
    "y": " b. teyle (m)"
  },
  {
    "x": "teylîpirodayîş (n)",
    "y": " dikiş atma"
  },
  {
    "x": "teyn",
    "y": " 1)b. tenê-I\r\n~2)b. tay\r\n~3)b. Tayê"
  },
  {
    "x": "teyna-I",
    "y": " b. tena"
  },
  {
    "x": "teyna-II",
    "y": " b. daha-3"
  },
  {
    "x": "teynayênî (m)",
    "y": " b. tenayênî (m)"
  },
  {
    "x": "teynayîye (m)",
    "y": " b. tenayîye (m)"
  },
  {
    "x": "teynci (m)",
    "y": " b. teyînce (m)"
  },
  {
    "x": "teynîya",
    "y": " b. tena"
  },
  {
    "x": "teyr, -e",
    "y": " kuş"
  },
  {
    "x": "teyr û tebul",
    "y": " b. teyr û tûr"
  },
  {
    "x": "teyr û tebur",
    "y": " b. teyr û tûr"
  },
  {
    "x": "teyr û tur",
    "y": " b. teyr û tûr"
  },
  {
    "x": "teyr û tûr",
    "y": " kuş türünden olan hayvanlar (kuşlar)"
  },
  {
    "x": "teyra tavusî (m)",
    "y": " b. teyra tawûsî (m)"
  },
  {
    "x": "teyra tavûse (m)",
    "y": " b. teyra tawûsî (m)"
  },
  {
    "x": "teyra tawûsî (m)",
    "y": " tavus kuşu"
  },
  {
    "x": "teyrê tawûsî (n)",
    "y": " tavus kuşu "
  },
  {
    "x": "teyr-tebur",
    "y": " b. teyr-tûr"
  },
  {
    "x": "teyr-tur",
    "y": " b. teyr-tûr"
  },
  {
    "x": "teyr-tûr",
    "y": " kuş türünden olan hayvanlar (kuşlar)"
  },
  {
    "x": "teyrakewtiş (n)",
    "y": " (kadınla) cinsel ilişkide bulunma"
  },
  {
    "x": "teyrakotiş (n)",
    "y": " b. teyrakewtiş (n)"
  },
  {
    "x": "teyrbaz, -e",
    "y": " kuşçu, kuşbaz"
  },
  {
    "x": "teyrbazî (m)",
    "y": " kuşçuluk"
  },
  {
    "x": "teyrbazîye (m)",
    "y": " kuşçuluk"
  },
  {
    "x": "teyri (m)",
    "y": " b. teyre (m)"
  },
  {
    "x": "teyş (n)",
    "y": " b. têş (n)"
  },
  {
    "x": "teyşabîyayîş (n)",
    "y": " b. tedeşabîyayîş (n)"
  },
  {
    "x": "teyşan, -i",
    "y": " b. têşan, -e"
  },
  {
    "x": "teyşan bîyayene",
    "y": " b. têşan bîyene"
  },
  {
    "x": "teyşanbîyayîş (n)",
    "y": " b. têşanbîyayîş (n)"
  },
  {
    "x": "teyşaney (m)",
    "y": " b. têşanî (m)"
  },
  {
    "x": "teyşanê (m)",
    "y": " b. têşanî (m)"
  },
  {
    "x": "teyşanîye (m)",
    "y": " b. têşanî (m)"
  },
  {
    "x": "teyşo (n)",
    "y": " b. têşan (n)"
  },
  {
    "x": "teyşon, -i",
    "y": " b. têşan, -e"
  },
  {
    "x": "teyşon bîyayene",
    "y": " b. têşan bîyene"
  },
  {
    "x": "teyşonbîyayîş (n)",
    "y": " b. têşanbîyayîş (n)"
  },
  {
    "x": "teyşoney (m)",
    "y": " b. têşanî (m)"
  },
  {
    "x": "teyşonê (m)",
    "y": " b. têşanî (m)"
  },
  {
    "x": "teyşt (n)",
    "y": " b. teşt (n)"
  },
  {
    "x": "teyştare (n)",
    "y": " b. teştare (n) "
  },
  {
    "x": "teyşûn, -i",
    "y": " b. têşan, -e"
  },
  {
    "x": "teyşûn bîyayene",
    "y": " b. têşan bîyene"
  },
  {
    "x": "teyşûnbîyayîş (n)",
    "y": " b. têşanbîyayîş (n)"
  },
  {
    "x": "teyşûnî (m)",
    "y": " b. têşanî (m)"
  },
  {
    "x": "teyvajîyayîş (n)",
    "y": " azarlama, melamet, paylama, tekdir etme"
  },
  {
    "x": "teyvecîyayîş (n)",
    "y": " b. tedevejîyayîş (n)"
  },
  {
    "x": "teyvetiş (n)",
    "y": " 1)hissetme, sezinleme, sezme\r\n~2)tedevetiş (n)"
  },
  {
    "x": "tez (n)",
    "y": " tez"
  },
  {
    "x": "teza (m)",
    "y": " yeni, taze"
  },
  {
    "x": "saya teza (m)",
    "y": " taze elma"
  },
  {
    "x": "tezad (n)",
    "y": " çelişki, çelişme"
  },
  {
    "x": "tezad de bîyene",
    "y": " çelişmek, mütenakız olmak"
  },
  {
    "x": "tezaddebîyayîş (n)",
    "y": " çelişme"
  },
  {
    "x": "tezayî (m)",
    "y": " b. tezîye-II (m)"
  },
  {
    "x": "tezba (m)",
    "y": " b. tezbîhe (m)"
  },
  {
    "x": "tezbî (m)",
    "y": " b. tezbîhe (m)"
  },
  {
    "x": "tezbîhe (m)",
    "y": " tespih\r\n~\tXal Kajîn tezbîhanê xo anceno. (Kajin Dayı tespihlerini çekiyor.)"
  },
  {
    "x": "tezbîhê kehrebayî (zh)",
    "y": " kehribar tespih(ler)"
  },
  {
    "x": "teze-I (n)",
    "y": " yeni, taze\r\n~nano teze (n): taze ekmek"
  },
  {
    "x": "teze-II (m)",
    "y": " genişliği az olan tarla veya tarlanın bir bölümü"
  },
  {
    "x": "tezek (n)",
    "y": " tezek"
  },
  {
    "x": "tezê (m)",
    "y": " b. teza (m), tezîye (m)"
  },
  {
    "x": "tezgahdar, -e",
    "y": " b. dezgehdar, -e"
  },
  {
    "x": "tezgahtar, -e",
    "y": " b. dezgehdar, -e"
  },
  {
    "x": "tezi (n)",
    "y": " b. teze-I (n)"
  },
  {
    "x": "tezî (m)",
    "y": " b. tezîye-I (m)"
  },
  {
    "x": "tezîr (n)",
    "y": " azarlama, melamet, paylama, tekdir etme"
  },
  {
    "x": "tezîr dayene",
    "y": " azarlamak, paylamak, tekdir etmek"
  },
  {
    "x": "tezîr dîyayene",
    "y": " azarlanmak"
  },
  {
    "x": "tezîr werdene",
    "y": " azarlanmak"
  },
  {
    "x": "tezîrdayîş (n)",
    "y": " azarlama, melamet, paylama, tekdir etme"
  },
  {
    "x": "tezîrdayox, -e",
    "y": " azarlayan"
  },
  {
    "x": "tezîrdîyayî, -ye",
    "y": " azarlanmış olan"
  },
  {
    "x": "tezîrdîyayîş (n)",
    "y": " azarlanma"
  },
  {
    "x": "tezîrwerdiş (n)",
    "y": " azarlanma"
  },
  {
    "x": "tezîrwerdox, -e",
    "y": " azarlanmış olan"
  },
  {
    "x": "tezîye-I (m)",
    "y": " yeni, taze\r\n~saya tezîye (m): taze elma"
  },
  {
    "x": "tezîye-II (m)",
    "y": " taziye"
  },
  {
    "x": "tezkere (n)",
    "y": " b. teşkere (n)"
  },
  {
    "x": "tezmînat (n) (huq.)",
    "y": " ödence, tazminat"
  },
  {
    "x": "tezva (m)",
    "y": " b. tezbîhe (m)"
  },
  {
    "x": "tezve (m)",
    "y": " b. tezbîhe (m)"
  },
  {
    "x": "tezyîq (n)",
    "y": " basınç"
  },
  {
    "x": "tezyîqin, -e",
    "y": " basınçlı"
  },
  {
    "x": "tezyîqpeym (n)",
    "y": " basınçölçer, barometre"
  },
  {
    "x": "tê-I (m)",
    "y": " b. teye (m)"
  },
  {
    "x": "tê-II",
    "y": " b. tede"
  },
  {
    "x": "tê-III",
    "y": " b. tîkê-I"
  },
  {
    "x": "tê-IV",
    "y": " b. tayê-1"
  },
  {
    "x": "tê kewtene",
    "y": " çatışmak"
  },
  {
    "x": "têara de",
    "y": " b. têare de"
  },
  {
    "x": "têare de",
    "y": " bir arada"
  },
  {
    "x": "têbesta (m)",
    "y": " giyinik"
  },
  {
    "x": "têbeste (n)",
    "y": " giyinik"
  },
  {
    "x": "têbestene",
    "y": " giydirmek (giysi için)"
  },
  {
    "x": "têbestiş (n)",
    "y": " giydirme (giysi için)"
  },
  {
    "x": "têbestîyayene",
    "y": " giydirilmek (giysi için) "
  },
  {
    "x": "têbestîyayîş (n)",
    "y": " giydirilme (giysi için)"
  },
  {
    "x": "têbestîye (m)",
    "y": " giyinik"
  },
  {
    "x": "têbin de",
    "y": " alt alta"
  },
  {
    "x": "têcir, -e",
    "y": " b. têcîr, -e"
  },
  {
    "x": "têcirî (m)",
    "y": " b. têcîrî (m)"
  },
  {
    "x": "têcîr, -e",
    "y": " 1) hayvan tüccarı\r\n~2) tüccar, bezirgân"
  },
  {
    "x": "têcîra/ê mexlî",
    "y": " toptancı"
  },
  {
    "x": "têcîrey (m)",
    "y": " b. têcîrî (m), têcîrîye (m)"
  },
  {
    "x": "têcîrî (m)",
    "y": " tüccarlık, bezirgânlık"
  },
  {
    "x": "têcîrîye (m)",
    "y": " tüccarlık, bezirgânlık"
  },
  {
    "x": "têdayene",
    "y": " b. têvdayene"
  },
  {
    "x": "têdayîş (n)",
    "y": " b. têvdayîş (n)"
  },
  {
    "x": "têde",
    "y": " bütün, hep, hepsi, tüm"
  },
  {
    "x": "têde pîya",
    "y": " hep birlikte, tümü birlikte"
  },
  {
    "x": "têdest",
    "y": " elbirliği"
  },
  {
    "x": "têdest de antene",
    "y": " çekiştirmek"
  },
  {
    "x": "têdest de kaş kerdene",
    "y": " çekiştirmek"
  },
  {
    "x": "têdesta",
    "y": " elbirliğiyle, elbirliği"
  },
  {
    "x": "têdesta antene",
    "y": " b. têdest de antene"
  },
  {
    "x": "têdestdeantiş (n)",
    "y": " çekiştirme"
  },
  {
    "x": "têdestdekaşkerdiş (n)",
    "y": " çekiştirme"
  },
  {
    "x": "têdesti antene",
    "y": " b. têdest de antene"
  },
  {
    "x": "têdi",
    "y": " b. têde"
  },
  {
    "x": "têdi pîya",
    "y": " b. têde pîya"
  },
  {
    "x": "têdima",
    "y": " art arda"
  },
  {
    "x": "têdine",
    "y": " b. têdîne"
  },
  {
    "x": "têdinî",
    "y": " b. têdîne"
  },
  {
    "x": "têdini",
    "y": " b. têdîne"
  },
  {
    "x": "têdir",
    "y": " b. têde"
  },
  {
    "x": "têdir pîya",
    "y": " b. têde pîya"
  },
  {
    "x": "têdîne",
    "y": " tümü, hepsi; tümünü, hepsini"
  },
  {
    "x": "têdînî",
    "y": " b. têdîne"
  },
  {
    "x": "têdîyayene",
    "y": " b. têvdîyayene"
  },
  {
    "x": "têdîyayîş (n)",
    "y": " b. têvdîyayîş (n)"
  },
  {
    "x": "têdust",
    "y": " b. têduşt"
  },
  {
    "x": "têdustîye (m)",
    "y": " b. têduştîye (m)"
  },
  {
    "x": "têduşt",
    "y": " eşit "
  },
  {
    "x": "têduştîye (m)",
    "y": " eşitlik"
  },
  {
    "x": "têgel (m)",
    "y": " b. tegel (n)"
  },
  {
    "x": "têgel kerdiş",
    "y": " b. tegel kerdene"
  },
  {
    "x": "têgelin, -e",
    "y": " b. tegelin, -e"
  },
  {
    "x": "têgelkerda (m)",
    "y": " b. tegelkerda (m)"
  },
  {
    "x": "têgelkerde (n)",
    "y": " b. tegelkerde (n)"
  },
  {
    "x": "têgelkerdîye (m)",
    "y": " b. tegelkerdîye (m)"
  },
  {
    "x": "têgeyrayîne",
    "y": " b. têwgêrayene"
  },
  {
    "x": "têgeyrayîş (n)",
    "y": " b. têwgêrayîş (n)"
  },
  {
    "x": "têgêrayîne",
    "y": " b. têwgêrayene"
  },
  {
    "x": "têgêrayîş (n)",
    "y": " b. têwgêrayîş (n)"
  },
  {
    "x": "têhet ameyene",
    "y": " buluşmak"
  },
  {
    "x": "têhet de",
    "y": " bir arada\r\n~Ma uca têhet de bîy. (Orada bir aradaydık.)"
  },
  {
    "x": "têhet omeyene",
    "y": " b. têhet ameyene"
  },
  {
    "x": "têhet ûmeyene",
    "y": " b. têhet ameyene"
  },
  {
    "x": "têhetameyîş (n)",
    "y": " buluşma"
  },
  {
    "x": "têheti di",
    "y": " b. têhet de"
  },
  {
    "x": "têhetomeyîş (n)",
    "y": " b. têhetameyîş (n)"
  },
  {
    "x": "têhetûmeyîş (n)",
    "y": " b. têhetameyîş (n)"
  },
  {
    "x": "têj, -i",
    "y": " b. tuj, -e"
  },
  {
    "x": "têj bîyayene",
    "y": " b. tuj bîyene"
  },
  {
    "x": "têj kerdene",
    "y": " b. tuj kerdene"
  },
  {
    "x": "têjbîyayîş (n)",
    "y": " b. tujbîyayîş (n)"
  },
  {
    "x": "têjey (m)",
    "y": " b. tujîye (m), tujî (m)"
  },
  {
    "x": "têjîr, -e",
    "y": " b. têcîr, -e"
  },
  {
    "x": "têjîrêy (m)",
    "y": " b. têcîrî (m)"
  },
  {
    "x": "tejîrîye (m)",
    "y": " b. têcîrîye (m)"
  },
  {
    "x": "têjkerdiş (n)",
    "y": " b. tujkerdiş (n)"
  },
  {
    "x": "têk",
    "y": " b. tayê"
  },
  {
    "x": "têkewtiş (n)",
    "y": " çatışma"
  },
  {
    "x": "têki",
    "y": " b. tayê"
  },
  {
    "x": "têkil (n)",
    "y": " karışım\r\n~têkilê komirî û awe (n): kömür-su karışımı"
  },
  {
    "x": "têkil bîyene",
    "y": " karışmak (dokunmak)\r\n~Têkilê karê mi mebe! (Benim işime karışma!)"
  },
  {
    "x": "têkil kerdene",
    "y": " karıştırmak\r\n~Mi têkilê nê karanê pîsan meke. (Beni bu pis işlere karıştırma.)"
  },
  {
    "x": "têkilê … bîyene",
    "y": " -e karışmak\r\n~Têkilê geda/gedeyî mebe! (Çocuğa karışma!)"
  },
  {
    "x": "têkilê komirî û awe (n)",
    "y": " kömür-su karışımı"
  },
  {
    "x": "têkilbîyayîş (n)",
    "y": " karışma (dokunma)"
  },
  {
    "x": "têkilî (m)",
    "y": " ilişki"
  },
  {
    "x": "têkilî ronayene",
    "y": " ilişki kurmak"
  },
  {
    "x": "têkilî viraştene",
    "y": " ilişki kurmak"
  },
  {
    "x": "têkilîya deynî (m)",
    "y": " borç ilişkisi"
  },
  {
    "x": "têkilîya mentiqîye (m)",
    "y": " mantıksal ilişki"
  },
  {
    "x": "têkilîye (m)",
    "y": " ilişki"
  },
  {
    "x": "têkilkerdiş (n)",
    "y": " karıştırma"
  },
  {
    "x": "têkoşer, -e",
    "y": " mücadeleci "
  },
  {
    "x": "têkoşîn (n)",
    "y": " mücadele"
  },
  {
    "x": "têl (n)",
    "y": " 1)tel\r\n~2)b. telgraf (n)"
  },
  {
    "x": "têlafûn (n)",
    "y": " b. telefon (n)"
  },
  {
    "x": "têle-I (m)",
    "y": " ot ve otsu bitkilerin bir tanesi"
  },
  {
    "x": "têla vaşî (m)",
    "y": " ot ve otsu bitkilerin bir tanesi"
  },
  {
    "x": "têle-II (m)",
    "y": " b. têl (n)"
  },
  {
    "x": "têlefun (n)",
    "y": " b. telefon (n)"
  },
  {
    "x": "têlewe de",
    "y": " b. têley de"
  },
  {
    "x": "têley de",
    "y": " bir arada\r\n~Nika ma têley de yîme. (Şimdi bir aradayız.)"
  },
  {
    "x": "têleye de",
    "y": " b. têley de"
  },
  {
    "x": "têli (n)",
    "y": " b. telî (n)"
  },
  {
    "x": "têlis (n)",
    "y": " b. telîse (m)"
  },
  {
    "x": "têlî (n)",
    "y": " b. telî (n)"
  },
  {
    "x": "têlîyin, -e",
    "y": " b. telîyin, -e"
  },
  {
    "x": "têlqeraf (n)",
    "y": " b. telgraf (n)"
  },
  {
    "x": "têlqiraf (n)",
    "y": " b. telgraf (n)"
  },
  {
    "x": "têlxiraf (n)",
    "y": " b. telgraf (n)"
  },
  {
    "x": "têman kerdene",
    "y": " b. têmîyan kerdene"
  },
  {
    "x": "têman kewtene",
    "y": " b. têmîyan kerdene"
  },
  {
    "x": "têman vistene",
    "y": " b. têmîyan vistene"
  },
  {
    "x": "têmankerdiş (n)",
    "y": " b. têmîyankerdiş (n)"
  },
  {
    "x": "têmankewtiş (n)",
    "y": " b. têmîyankewtiş (n)"
  },
  {
    "x": "têmanvista (m)",
    "y": "b. têmîyanvista (m)"
  },
  {
    "x": "têmanviste (n)",
    "y": " b. têmîyanviste (n)"
  },
  {
    "x": "têmanvistiş (n)",
    "y": " b. têmîyanvistiş (n)"
  },
  {
    "x": "têmaşîr (n)",
    "y": " b. tebeşîr (n)"
  },
  {
    "x": "têmîya kerdene",
    "y": " b. têmîyan kerdene"
  },
  {
    "x": "têmîyan bîyayene",
    "y": " b. têmîyan bîyene"
  },
  {
    "x": "têmîyan bîyene",
    "y": " karışmak (birbirine karışmak)\r\n~Xele/genim û cew têmîyan bîyê. (Buğday ile arpa karışmış.)\r\n~\t\r\n~Heme têmîyan bîyê. (Hepsi birbirine karışmış.)"
  },
  {
    "x": "têmîyan fînîyayene",
    "y": " karıştırılmak (birbirine karıştırılmak)"
  },
  {
    "x": "têmîyan ginayene",
    "y": " karışmak (birbirine karışmak)"
  },
  {
    "x": "têmîyan gunayene",
    "y": " b. têmîyan ginayene"
  },
  {
    "x": "têmîyan kerdene",
    "y": " birbirine karıştırmak, birbirine katmak, karıştırmak"
  },
  {
    "x": "têmîyan kewtene",
    "y": " karışmak (birbirine karışmak)\r\n~Pêro kewtê têmîyan. (Hepsi birbirine karışmış.)"
  },
  {
    "x": "têmîyan kotene",
    "y": " b. têmîyan kewtene"
  },
  {
    "x": "têmîyan ra vejîyayene",
    "y": " ayrışmak"
  },
  {
    "x": "têmîyan ra vetene",
    "y": " ayrıştırmak"
  },
  {
    "x": "têmîyan ro dayene",
    "y": " karıştırmak (birbirine karıştırmak, birbirine katmak)"
  },
  {
    "x": "têmîyan ro dîyayene",
    "y": " 1)karışmak (birbirine karışmak)\r\n~2)karıştırılmak (birbirine karıştırılmak)"
  },
  {
    "x": "têmîyan şanayene",
    "y": " karıştırmak (birbirine karıştırmak, birbirine katmak"
  },
  {
    "x": "têmîyan şanîyayene",
    "y": " 1)karışmak (birbirine karışmak)\r\n~2)karıştırılmak (birbirine karıştırılmak)"
  },
  {
    "x": "têmîyan vistene",
    "y": " karıştırmak (birbirine karıştırmak, birbirine katmak)"
  },
  {
    "x": "têmîyanbîyaya (m)",
    "y": " karışık"
  },
  {
    "x": "têmîyanbîyaye (n)",
    "y": " karışık"
  },
  {
    "x": "têmîyanbîyayîş (n)",
    "y": " karışma (birbirine karışma)"
  },
  {
    "x": "têmîyanbîyayîye (m)",
    "y": " karışık"
  },
  {
    "x": "têmîyane (n)",
    "y": " güreş"
  },
  {
    "x": "têmîyane girewtene",
    "y": " güreşmek, güreş tutmak"
  },
  {
    "x": "têmîyone girewtene",
    "y": " b. têmîyane girewtene"
  },
  {
    "x": "têmîyanek, -e",
    "y": " 1)karışık\r\n~2)karışık (kebap vb. için)\r\n~3)karma"
  },
  {
    "x": "têmîyanekî (m)",
    "y": " karışıklık"
  },
  {
    "x": "têmîyanekîye (m)",
    "y": " karışıklık"
  },
  {
    "x": "têmîyanfînîyayîş (n)",
    "y": " karıştırılma (birbirine karıştırılma)"
  },
  {
    "x": "têmîyanginaya (m)",
    "y": " karışık, karmakarışık (birbirine dolanmış olan)"
  },
  {
    "x": "têmîyanginaye (n)",
    "y": " karışık, karmakarışık (birbirine dolanmış olan)"
  },
  {
    "x": "têmîyanginayîş (n)",
    "y": " karışma (birbirine karışma)"
  },
  {
    "x": "têmîyanginayîye (m)",
    "y": " karışık (birbirine dolanmış olan)"
  },
  {
    "x": "têmîyangunaya (m)",
    "y": " b. têmîyanginaya (m)"
  },
  {
    "x": "têmîyangunaye (n)",
    "y": " b. têmîyanginaye (n)"
  },
  {
    "x": "têmîyangunayîş  (n)",
    "y": " b. têmîyanginayîş (n)"
  },
  {
    "x": "têmîyankerda (m)",
    "y": " karışık"
  },
  {
    "x": "têmîyankerde (n)",
    "y": " karışık"
  },
  {
    "x": "têmîyankerdiş (n)",
    "y": " karıştırma (birbirine karıştırma)   "
  },
  {
    "x": "têmîyankerdîye (m)",
    "y": " karışık"
  },
  {
    "x": "têmîyankewta (m)",
    "y": " 1)karışık\r\n~2)karmakarışık (birbirine dolanmış olan)  "
  },
  {
    "x": "têmîyankewte (n)",
    "y": " 1)karışık\r\n~2)karmakarışık (birbirine dolanmış olan)"
  },
  {
    "x": "têmîyankewteyî (m)",
    "y": " karışıklık (birbirine dolanma durumu, saç vb. için)"
  },
  {
    "x": "têmîyankewteyîye (m)",
    "y": " karışıklık (birbirine dolanma durumu)"
  },
  {
    "x": "têmîyankewtiş (n)",
    "y": " karışma (birbirine karışma)"
  },
  {
    "x": "têmîyankewtîye (m)",
    "y": " karışık, karmakarışık (birbirine dolanmış olan)"
  },
  {
    "x": "têmîyankotiş (n)",
    "y": " b. têmîyankewtiş (n)"
  },
  {
    "x": "têmîyanravejîyayîş (n)",
    "y": " ayrışma"
  },
  {
    "x": "têmîyanravetiş (n)",
    "y": " ayrıştırma"
  },
  {
    "x": "têmîyanrodaya (m)",
    "y": " karışık"
  },
  {
    "x": "têmîyanrodaye (n)",
    "y": " karışık"
  },
  {
    "x": "têmîyanrodayê (m)",
    "y": " b. têmîyanrodayîye (m)"
  },
  {
    "x": "têmîyanrodayîş (n)",
    "y": " karıştırma (birbirine karıştırma)"
  },
  {
    "x": "têmîyanrodayîye (m)",
    "y": " karışık"
  },
  {
    "x": "têmîyanrodîyayîş (n)",
    "y": " 1)karışma (birbirine karışma)\r\n~2)karıştırılma (birbirine karıştırılma)"
  },
  {
    "x": "têmîyanşanaya (m)",
    "y": " karışık"
  },
  {
    "x": "têmîyanşanaye (n)",
    "y": " karışık"
  },
  {
    "x": "têmîyanşanayîş (n)",
    "y": " karıştırma (birbirine karıştırma)"
  },
  {
    "x": "têmîyanşanayîye (m)",
    "y": " karışık"
  },
  {
    "x": "têmîyanşanîyayîş (n)",
    "y": " 1)karışma (birbirine karışma)\r\n~2)karıştırılma (birbirine karıştırılma)"
  },
  {
    "x": "têmîyanşaye (n)",
    "y": " b. têmîyanşanaye (n)"
  },
  {
    "x": "têmîyanşayî (m)",
    "y": " b. têmîyanşanayîye (m)"
  },
  {
    "x": "têmîyanşayî (n)",
    "y": " b. têmîyanşanaye (n)"
  },
  {
    "x": "têmîyanşayîş (n)",
    "y": " b. têmîyanşanayîş (n)"
  },
  {
    "x": "têmîyanvista (m)",
    "y": " karışık, karmakarışık (birbirine dolanmış olan)"
  },
  {
    "x": "têmîyanviste (n)",
    "y": " karışık, karmakarışık (birbirine dolanmış olan)"
  },
  {
    "x": "têmîyanvistiş (n)",
    "y": " karıştırma (birbirine karıştırma)"
  },
  {
    "x": "têmîyo kerdene",
    "y": " b. têmîyan kerdene"
  },
  {
    "x": "têmîyon fînîyayene",
    "y": " b. têmîyan fînîyayene"
  },
  {
    "x": "têmîyon kerdene",
    "y": " b. têmîyan kerdene "
  },
  {
    "x": "têmîyon ro dayene",
    "y": " b. têmîyan ro dayene"
  },
  {
    "x": "têmîyon ro dîyayene",
    "y": " b. têmîyan ro dîyayene"
  },
  {
    "x": "têmîyon şonîyayene",
    "y": " b. têmîyan şanîyayene"
  },
  {
    "x": "têmîyon vistene",
    "y": " b. têmîyan vistene "
  },
  {
    "x": "têmîyonbîyayîş (n)",
    "y": " b. têmîyanbîyayîş (n)"
  },
  {
    "x": "têmîyone (n)",
    "y": " b. têmîyane (n)"
  },
  {
    "x": "têmîyone girewtene",
    "y": " b. têmîyane girewtene"
  },
  {
    "x": "têmîyonfînîyayîş (n)",
    "y": " b. têmîyanfînîyayîş (n)"
  },
  {
    "x": "têmîyonkerdiş (n)",
    "y": " b. têmîyankerdiş (n)"
  },
  {
    "x": "têmîyonkewta (m)",
    "y": " b. têmîyankewta (m)"
  },
  {
    "x": "têmîyonkewte (n)",
    "y": " b. têmîyankewte (n)"
  },
  {
    "x": "têmîyonkewtiş (n)",
    "y": " b. têmîyankewtiş (n)"
  },
  {
    "x": "têmîyonrodayîş (n)",
    "y": " b. têmîyanrodayîş (n)"
  },
  {
    "x": "têmîyonrodîyayîş (n)",
    "y": " b. têmîyanrodîyayîş (n)"
  },
  {
    "x": "têmîyonşonîyayîş (n)",
    "y": " b. têmîyanşanîyayîş (n)"
  },
  {
    "x": "têmîyonvista (m)",
    "y": " b. têmîyanvista (m)"
  },
  {
    "x": "têmîyonviste (n)",
    "y": " b. têmîyanviste (n)"
  },
  {
    "x": "têmîyonvistiş (n)",
    "y": " b. têmîyanvistiş (n) "
  },
  {
    "x": "têmîyûn kerdene",
    "y": " b. têmîyan kerdene "
  },
  {
    "x": "têmîyûn ro dayene",
    "y": " b. têmîyan ro dayene"
  },
  {
    "x": "têmîyûn vistene",
    "y": " b. têmîyan vistene"
  },
  {
    "x": "têmîyûnkerdiş (n)",
    "y": " b. têmîyankerdiş (n)"
  },
  {
    "x": "têmîyûnkewte (n)",
    "y": " b. têmîyankewte (n)"
  },
  {
    "x": "têmîyûnkewtiş (n)",
    "y": " b. têmîyankewtiş (n)"
  },
  {
    "x": "têmîyûnkotiş (n)",
    "y": " b. têmîyankewtiş (n)"
  },
  {
    "x": "têmîyûnrodayîş (n)",
    "y": " b. têmîyanrodayîş (n)"
  },
  {
    "x": "têmîyûnrodîyayîş (n)",
    "y": " b. têmîyanrodîyayîş (n)"
  },
  {
    "x": "têmîyûnvista (m)",
    "y": " b. têmîyanvista (m)"
  },
  {
    "x": "têmîyûnviste (n)",
    "y": " b. têmîyanviste (n)"
  },
  {
    "x": "têmîyûnvistiş (n)",
    "y": " b. têmîyanvistiş (n)"
  },
  {
    "x": "têmon kerdene",
    "y": " b. têmîyan kerdene"
  },
  {
    "x": "têmon kewtene",
    "y": " b. têmîyan kerdene"
  },
  {
    "x": "têmon koytene",
    "y": " b. têmîyan kerdene"
  },
  {
    "x": "têmon vistene",
    "y": " b. têmîyan vistene"
  },
  {
    "x": "têmonbîyaya (m)",
    "y": " b. têmîyanbîyaya (m)"
  },
  {
    "x": "têmonbîyaye (n)",
    "y": " b. têmîyanbîyaye (n)"
  },
  {
    "x": "têmonbîyayi (n)",
    "y": " b. têmîyanbîyaye (n)"
  },
  {
    "x": "têmonbîyayîş (n)",
    "y": " b. têmîyanbîyayîş (n)"
  },
  {
    "x": "têmonek (n)",
    "y": " b. têmîyanek, -e"
  },
  {
    "x": "têmonkerde (n)",
    "y": " b. têmîyankerde (n)"
  },
  {
    "x": "têmonkerdi (n)",
    "y": " b. têmîyankerde (n)"
  },
  {
    "x": "têmonkerdiş (n)",
    "y": " b. têmîyankerdiş (n)"
  },
  {
    "x": "têmonkewte (n)",
    "y": " b. têmîyankewte (n)"
  },
  {
    "x": "têmonkewtiş (n)",
    "y": " b. têmîyankewtiş (n)"
  },
  {
    "x": "têmonkotiş (n)",
    "y": " b. têmîyankewtiş (n)"
  },
  {
    "x": "têmonkoytiş (n)",
    "y": " b. têmîyankewtiş (n)"
  },
  {
    "x": "têmonvista (m)",
    "y": " b. têmîyanvista (m)"
  },
  {
    "x": "têmonviste (n)",
    "y": " b. têmîyanviste (n)"
  },
  {
    "x": "têmonvistiş (n)",
    "y": " b. têmîyanvistiş (n)"
  },
  {
    "x": "têmzî (m)",
    "y": " b. temezî (m)"
  },
  {
    "x": "tên (n)",
    "y": " nem, rutubet, gönen"
  },
  {
    "x": "tên girewtene",
    "y": " nemlenmek, rutubetlenmek"
  },
  {
    "x": "tên",
    "y": " b. tay"
  },
  {
    "x": "têna",
    "y": " b. tena"
  },
  {
    "x": "têna bîyayene",
    "y": " b. tena bîyene"
  },
  {
    "x": "têna mendene",
    "y": " b. tena mendene"
  },
  {
    "x": "têna verdayene",
    "y": " b. tena verdayene"
  },
  {
    "x": "têna verdîyayene",
    "y": " b. tena verdîyayene"
  },
  {
    "x": "tênabîyayîş (n)",
    "y": " b. tenabîyayîş (n)"
  },
  {
    "x": "tênaverdayîş (n)",
    "y": " b. tenaverdayîş (n)"
  },
  {
    "x": "tênayê (m)",
    "y": " b. tenayîye (m)"
  },
  {
    "x": "tênayî (m)",
    "y": " b. tenayî (m)"
  },
  {
    "x": "têne",
    "y": " b. tenê-I"
  },
  {
    "x": "tênê",
    "y": " b. tena"
  },
  {
    "x": "tênê bîyayêne",
    "y": " b. tena bîyene"
  },
  {
    "x": "tênêbîyayîş (n)",
    "y": " b. tenabîyayîş (n)"
  },
  {
    "x": "tênin, -e",
    "y": " nemli, rutubetli"
  },
  {
    "x": "têninîye (m)",
    "y": " nemlilik, rutubetlilik "
  },
  {
    "x": "tênîye (m)",
    "y": " b. tenî (m), tenîye (m)"
  },
  {
    "x": "tênpeym (n)",
    "y": " nemölçer, higrometre"
  },
  {
    "x": "tênya",
    "y": " b. tena"
  },
  {
    "x": "tênyayî (m)",
    "y": " b. tenayî (m)"
  },
  {
    "x": "têpa",
    "y": " b. tepîya"
  },
  {
    "x": "têpey de",
    "y": " arka arkaya, art arda"
  },
  {
    "x": "têpey de wenayene",
    "y": " (birkaç kişiyi) arka arkaya bindirmek"
  },
  {
    "x": "têpey de weniştene",
    "y": " (birkaç kişi) arka arkaya binmek"
  },
  {
    "x": "têpey de wenîştene",
    "y": " b. têpey de weniştene"
  },
  {
    "x": "têpey di",
    "y": " b. têpey de"
  },
  {
    "x": "têpey ginayene",
    "y": " birikmek"
  },
  {
    "x": "têpey gunayîne",
    "y": " b. têpey ginayene"
  },
  {
    "x": "têpey ra",
    "y": " arka arkaya, art arda"
  },
  {
    "x": "têpêy de",
    "y": " b. têpey de"
  },
  {
    "x": "têpêy ra",
    "y": " b. têpey ra"
  },
  {
    "x": "têpeyginayîş (n)",
    "y": " birikme"
  },
  {
    "x": "têpeygunayîş (n)",
    "y": " b. têpeyginayîş (n)"
  },
  {
    "x": "têpîya",
    "y": " b. tepîya"
  },
  {
    "x": "têpya",
    "y": " b. tepîya"
  },
  {
    "x": "têr, -e",
    "y": " b. teyr, -e"
  },
  {
    "x": "têra şîyayene",
    "y": " b. têra şîyene "
  },
  {
    "x": "têra şîyene",
    "y": " güreşmek, güreş tutmak"
  },
  {
    "x": "têrapişta (m)",
    "y": " b. têrapîşta (m)"
  },
  {
    "x": "têrapişte (n)",
    "y": " b. têrapîşte (n)"
  },
  {
    "x": "têrapiştîye (m)",
    "y": " b. têrapîştîye (m)"
  },
  {
    "x": "têrapîste (n)",
    "y": " b. têrapîşte (n)"
  },
  {
    "x": "têrapîstîye (m)",
    "y": " b. têrapîştîye (m)"
  },
  {
    "x": "têrapîşta (m)",
    "y": " giyinik"
  },
  {
    "x": "têrapîşte (n)",
    "y": " giyinik"
  },
  {
    "x": "têrapîştîye (m)",
    "y": " giyinik"
  },
  {
    "x": "Têrcan (n)",
    "y": " Tercan"
  },
  {
    "x": "têrdiş (n)",
    "y": " b. terdiş (n)"
  },
  {
    "x": "têreyde bermayene",
    "y": " ağlaşmak"
  },
  {
    "x": "têreyde weriştene-roniştene",
    "y": " (birbiriyle) cinsel ilişkide bulunmak, sevişmek"
  },
  {
    "x": "têreydebermayîş (n)",
    "y": " ağlaşma"
  },
  {
    "x": "têreydeweriştiş-roniştiş",
    "y": " (birbiriyle) cinsel ilişkide bulunma, sevişme"
  },
  {
    "x": "têreyra bermayene",
    "y": " ağlaşmak"
  },
  {
    "x": "têreyrabermayîş (n)",
    "y": " ağlaşma"
  },
  {
    "x": "têrêj (m)",
    "y": " b. tîrêje (m)"
  },
  {
    "x": "têri ameyene",
    "y": " b. têrî ameyene"
  },
  {
    "x": "têri ardene",
    "y": " b. têrî ardene"
  },
  {
    "x": "têri kerdene",
    "y": " b. têrî kerdene"
  },
  {
    "x": "têri kewtene",
    "y": " b. têrî kewtene"
  },
  {
    "x": "têri vistene",
    "y": " b. têrî vistene"
  },
  {
    "x": "têriameyîş (n)",
    "y": " b. têrîameyîş (n)"
  },
  {
    "x": "têriardiş (n)",
    "y": " b. têrîardiş (n)"
  },
  {
    "x": "têrikerdiş (n)",
    "y": " b. têrîkerdiş (n)"
  },
  {
    "x": "têrikewtiş (n)",
    "y": " b. têrîkewtiş (n)"
  },
  {
    "x": "têrivistiş (n)",
    "y": " b. têrîvistiş (n)"
  },
  {
    "x": "têrî ameyene",
    "y": " yüzleşmek"
  },
  {
    "x": "têrî ardene",
    "y": " yüzleştirmek\r\n~Ez şima ana têrî. (Sizi yüzleştiriyorum.)"
  },
  {
    "x": "têrî kerdene",
    "y": " yüzleştirmek"
  },
  {
    "x": "têrî kewtene",
    "y": " yüzleşmek"
  },
  {
    "x": "têrî vistene",
    "y": " yüzleştirmek"
  },
  {
    "x": "têrîameyîş (n)",
    "y": " yüzleşme"
  },
  {
    "x": "têrîardiş (n)",
    "y": "yüzleştirme"
  },
  {
    "x": "têrîkerdiş (n)",
    "y": " yüzleştirme"
  },
  {
    "x": "têrîkewtiş (n)",
    "y": " yüzleşme"
  },
  {
    "x": "têrîqalî ameyene",
    "y": " yüzleşmek\r\n~Ti ey de têrîqalî bena. (Sen onunla yüzleşiyorsun.)"
  },
  {
    "x": "têrîqalî kerdene",
    "y": " yüzleştirmek\r\n~Ez şima têrîqalî kena. (Ben sizi yüzleştiriyorum.)"
  },
  {
    "x": "têrîqalîameyîş (n)",
    "y": " yüzleşme"
  },
  {
    "x": "têrîqalîkerdiş (n)",
    "y": " yüzleştirme"
  },
  {
    "x": "têrîvistiş (n)",
    "y": " yüzleştirme"
  },
  {
    "x": "têrn, -i",
    "y": " b. tern, -e"
  },
  {
    "x": "têrşîg (n)",
    "y": " binanın çatısını taşıması için tavanda yatay olarak kullanılan kalın direk"
  },
  {
    "x": "têrûqalî ameyene",
    "y": " b. têrîqalî ameyene"
  },
  {
    "x": "têrûqalî kerdene",
    "y": " b. têrîqalî kerdene"
  },
  {
    "x": "têrûqalîameyîş (n)",
    "y": " b. têrîqalîameyîş (n)"
  },
  {
    "x": "têrûqalîkerdiş (n)",
    "y": " b. têrîqalîkerdiş (n)"
  },
  {
    "x": "têrzênî (m)",
    "y": " b. terzîyênî (m)"
  },
  {
    "x": "têrzî, -ye",
    "y": " b. terzî, -ye"
  },
  {
    "x": "têrzîxane (n)",
    "y": " b. terzîxane (n)"
  },
  {
    "x": "tês (n)",
    "y": " b. têş (n)"
  },
  {
    "x": "têsa (n)",
    "y": " b. têşan, -e"
  },
  {
    "x": "têsa bîyayene",
    "y": " b. têşan bîyene"
  },
  {
    "x": "têsabîyayîş (n)",
    "y": " b. têşanbîyayîş (n)"
  },
  {
    "x": "têsan, -e",
    "y": " b. têşan, -e"
  },
  {
    "x": "têsan bîyayene",
    "y": " b. têşan bîyene"
  },
  {
    "x": "têsanbîyayîş (n)",
    "y": " b. têşanbîyayîş (n)"
  },
  {
    "x": "têsanên (m)",
    "y": " b. têşanênî (m)"
  },
  {
    "x": "têsanênî (m)",
    "y": " b. têşanênî (m)"
  },
  {
    "x": "têsanîye (m)",
    "y": " b. têşanî (m)"
  },
  {
    "x": "têser û têbin",
    "y": " altüst"
  },
  {
    "x": "têser û têbin bîyene",
    "y": " allak bullak olmak, altüst olmak"
  },
  {
    "x": "têser û têbin kerdene",
    "y": " allak bullak etmek, altüst etmek"
  },
  {
    "x": "têser û têbin ra",
    "y": " allak bullak"
  },
  {
    "x": "têserra kewtene",
    "y": " (birbiriyle) cinsel ilişkide bulunmak"
  },
  {
    "x": "têserrakewtiş (n)",
    "y": " (birbiriyle) cinsel ilişkide bulunma"
  },
  {
    "x": "têsîr (n)",
    "y": " b. tesîr (n)"
  },
  {
    "x": "têş (n)",
    "y": " susuzluk (susamış olma durumu)\r\n~Ma têş de merdî! (Susuzluktan öldük!)"
  },
  {
    "x": "têşan, -e",
    "y": " susak (susamış olan)"
  },
  {
    "x": "têşan bîyayene",
    "y": " b. têşan bîyene"
  },
  {
    "x": "têşan bîyene",
    "y": " susamak"
  },
  {
    "x": "têşanbîyayîş (n)",
    "y": " susama"
  },
  {
    "x": "têşaney (m)",
    "y": " b. têşanî (m)"
  },
  {
    "x": "têşanê (m)",
    "y": " b. têşanî (m)"
  },
  {
    "x": "têşanênî (m)",
    "y": " susuzluk (susamış olma durumu)"
  },
  {
    "x": "têşani (m)",
    "y": " b. têşane (m)"
  },
  {
    "x": "têşanî (m)",
    "y": " susuzluk (susamış olma durumu)"
  },
  {
    "x": "têşanîye (m)",
    "y": "  susuzluk (susamış olma durumu)"
  },
  {
    "x": "têşt (n)",
    "y": " b. teşt (n)"
  },
  {
    "x": "têştare (n)",
    "y": " b. teştare (n)"
  },
  {
    "x": "têştarî ra pey",
    "y": " b. teştareyî ra pey"
  },
  {
    "x": "têştarî ra ver",
    "y": " b. teştareyî ra ver"
  },
  {
    "x": "têştî (n)",
    "y": " b. teşt (n)"
  },
  {
    "x": "têşûn, -i",
    "y": " b. têşan, -e"
  },
  {
    "x": "têşûn bîyayene",
    "y": " b. têşan bîyene"
  },
  {
    "x": "têşûnbîyayîş (n)",
    "y": " b. têşanbîyayîş (n)"
  },
  {
    "x": "têşûnê (m)",
    "y": " b. têşanî (m)"
  },
  {
    "x": "têşûnî (m)",
    "y": " b. têşanî (m)"
  },
  {
    "x": "têtew",
    "y": " b. têvter"
  },
  {
    "x": "têtewr (n)",
    "y": " karışım\r\n~têtewrê komirî û awe (n): kömür-su karışımı"
  },
  {
    "x": "têtewrê komirî û awe (n)",
    "y": " kömür-su karışımı"
  },
  {
    "x": "têvdaker (n)",
    "y": " karıştırıcı, mikser"
  },
  {
    "x": "têvdayene",
    "y": " 1)karıştırmak (bir şeyle bir şeyin içini karıştırmak) \r\n~2)karışmak (dokunmak)\r\n~Ti têvmede! (Sen karışma!) \r\n~3)tepletmek"
  },
  {
    "x": "têvdayîni",
    "y": " b. têvdayene"
  },
  {
    "x": "têvdayîş (n)",
    "y": " 1)karıştırma (bir şeyle bir şeyin içini karıştırma)\r\n~2)karışma (dokunma)\r\n~3)tepletme"
  },
  {
    "x": "têvdayox, -e",
    "y": " karıştırıcı"
  },
  {
    "x": "têvdîyayene",
    "y": " karıştırılmak (bir şeyin içi veya içindeki karıştırılmak)\r\n~Germîya ke quşxane de, bi kondêz têvdîyêna. (Tenceredeki çorba kepçeyle karıştırılıyor.)"
  },
  {
    "x": "têvdîyayîş (n)",
    "y": " karıştırılma (bir şeyle bir şeyin içinin karıştırılması)"
  },
  {
    "x": "têver eştene",
    "y": " yarıştırmak"
  },
  {
    "x": "têver nayene",
    "y": " ağız dalaşında bulunmak, ağız münakaşası yapmak"
  },
  {
    "x": "têverade",
    "y": " b. têverardî"
  },
  {
    "x": "têveradi",
    "y": " b. têverardî"
  },
  {
    "x": "têveradî",
    "y": " b. têverardî"
  },
  {
    "x": "têverardî",
    "y": " kucak kucağa"
  },
  {
    "x": "têverare",
    "y": " kucak kucağa"
  },
  {
    "x": "têvereşte (n)",
    "y": " 1)karışım (birden fazla yiyecek maddesinin karıştırılıp birlikte pişirilmesiyle hazırlanan yemek)\r\n~ Ma goşt û balcanî eştî têver.\r\n~Ma şerîye eşto belxurî ver.\r\n~2)yarıştırılmış olan"
  },
  {
    "x": "têvereştiş (n)",
    "y": " yarıştırma"
  },
  {
    "x": "têvereştîye (m)",
    "y": " yarıştırılmış olan"
  },
  {
    "x": "têverike",
    "y": " kucak kucağa "
  },
  {
    "x": "têveriki",
    "y": " b. têverike"
  },
  {
    "x": "têvernayîş (n)",
    "y": " ağız dalaşı, ağız dalaşında bulunma, ağız münakaşası yapma, sertçe tartışma"
  },
  {
    "x": "têvirad",
    "y": " b. têverardî"
  },
  {
    "x": "têvirare",
    "y": " b. têverare"
  },
  {
    "x": "têvter",
    "y": " hepsi, tümü"
  },
  {
    "x": "têvter pîya",
    "y": " hep birlikte, tümü birlikte"
  },
  {
    "x": "têw (m)",
    "y": " b. tewe-I (m)"
  },
  {
    "x": "têwdayene",
    "y": " b. têvdayene"
  },
  {
    "x": "têwdayîş (n)",
    "y": " b. têvdayîş (n)"
  },
  {
    "x": "têwerte kerdene",
    "y": " b. têmîyan kerdene"
  },
  {
    "x": "têwertekerdiş (n)",
    "y": " b. têmîyankerdiş (n)"
  },
  {
    "x": "têwgêrayene",
    "y": " hareket etmek"
  },
  {
    "x": "têwgêrayîş (n)",
    "y": " hareket etme"
  },
  {
    "x": "têwir (n)",
    "y": " b. tewir (n)"
  },
  {
    "x": "têwodayene",
    "y": " b. têvdayene"
  },
  {
    "x": "têwodayîş (n)",
    "y": " b. têvdayîş (n)"
  },
  {
    "x": "têwr (zr)",
    "y": " b. tewr (zr)"
  },
  {
    "x": "têwran, -e",
    "y": " b. tewran, -e"
  },
  {
    "x": "têwranîye (m)",
    "y": " b. tewranîye (m)"
  },
  {
    "x": "têwranîye guretene",
    "y": " b. tewranîye girewtene"
  },
  {
    "x": "têwranîye kotene",
    "y": " b. tewranîye kewtene"
  },
  {
    "x": "têwranîye pira nîstene",
    "y": " b. tewranîye pira niştene "
  },
  {
    "x": "têy",
    "y": " b. tey-II, tede"
  },
  {
    "x": "têy vetene",
    "y": " b. tey vetene, tede vetene"
  },
  {
    "x": "têy vêjîyayene",
    "y": " b. tede vejîyayene"
  },
  {
    "x": "têyara (m)",
    "y": " b. teyara (m)"
  },
  {
    "x": "têye (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "têyemîyonbîyayîş (n)",
    "y": " b. têmîyanbîyayîş (n)"
  },
  {
    "x": "têyna",
    "y": " b. tena"
  },
  {
    "x": "têynatîye (m)",
    "y": " b. tenayî (m)"
  },
  {
    "x": "têyr, -e",
    "y": " b. teyr, -e"
  },
  {
    "x": "têysan, -i",
    "y": " b. têşan, -e"
  },
  {
    "x": "têysan bîyayene",
    "y": " b. têşan bîyene"
  },
  {
    "x": "têyşan, -i",
    "y": " b. têşan, -e"
  },
  {
    "x": "têyşan bîyayene",
    "y": " b. têşan bîyene"
  },
  {
    "x": "têyşanbîyayîş (n)",
    "y": " b. têşanbîyayîş (n)"
  },
  {
    "x": "têyşanê (m)",
    "y": " b. têşanî (m)"
  },
  {
    "x": "têyvêjîyayîş (n)",
    "y": " b. tedevejîyayîş (n)"
  },
  {
    "x": "têyvetiş (n)",
    "y": " b. tedevetiş (n)"
  },
  {
    "x": "Thîmbu (n)",
    "y": " Thimbu"
  },
  {
    "x": "ti-I (m, n, z)",
    "y": " 1)sen\r\n~ti nêvana ke: meğer, meğerse\r\n~ti nêvanî ke: meğer, meğerse\r\n~ti vaje: sen söyle\r\n~ti vana qey: sanki\r\n~ti vanî qey: sanki\r\n~2)b. to (m, n, z)"
  },
  {
    "x": "ti nêvana ke",
    "y": " meğer, meğerse"
  },
  {
    "x": "ti nêvanî ke",
    "y": " meğer, meğerse"
  },
  {
    "x": "ti vaje",
    "y": " sen söyle"
  },
  {
    "x": "ti vana qey",
    "y": " sanki"
  },
  {
    "x": "ti vanî qey",
    "y": " sanki"
  },
  {
    "x": "ti-II (m, n, z)",
    "y": " b. ey (n, z), aye (m, z)"
  },
  {
    "x": "ti ra",
    "y": " b. ey ra, aye ra"
  },
  {
    "x": "ti ra çîk hêşkewtiş",
    "y": " b. ti ra çîyêk hişkewtiş, ti ra çîyê hişkewtiş"
  },
  {
    "x": "ti ra çîyê hişkewtiş",
    "y": " bir şeye yarama, -den bir şey anlaşılma"
  },
  {
    "x": "ti ra çîyêk hişkewtiş",
    "y": " bir şeye yarama, -den bir şey anlaşılma"
  },
  {
    "x": "ti ra pey",
    "y": " b. ey ra pey, aye ra pey"
  },
  {
    "x": "ti-III",
    "y": " b. tu-II"
  },
  {
    "x": "tib (n)",
    "y": " tıp"
  },
  {
    "x": "tibo edlî (n)",
    "y": " adli tıp"
  },
  {
    "x": "tibet (n)",
    "y": " b. tebîet (n)"
  },
  {
    "x": "tibî, -ye",
    "y": " tıbbi"
  },
  {
    "x": "ticaret (n)",
    "y": " b. tîcaret (n)"
  },
  {
    "x": "ticarî, -ye",
    "y": " b. tîcarî, -ye"
  },
  {
    "x": "tide",
    "y": " b. tede"
  },
  {
    "x": "tif-I (n)",
    "y": " ocak (ateş yakılan veya gazla yakılan)"
  },
  {
    "x": "tifê adirî (n)",
    "y": " ocak (ateş yakılan veya gazla yakılan)"
  },
  {
    "x": "tif-II (n)",
    "y": " b. tu-III (n)"
  },
  {
    "x": "tif kerdene",
    "y": " b. tu kerdene"
  },
  {
    "x": "tif ... sawitene",
    "y": " b. tu ... sawitene"
  },
  {
    "x": "tif ... sawitiş",
    "y": " b. tu ... sawitiş"
  },
  {
    "x": "tif-tal",
    "y": " b. tef-tal"
  },
  {
    "x": "tifang (n)",
    "y": " b. tifing (n)"
  },
  {
    "x": "tifaye (m)",
    "y": " ocak (ateş yakılan veya gazla yakılan)"
  },
  {
    "x": "tifeng (n)",
    "y": " b. tifing (n)"
  },
  {
    "x": "tifik (n)",
    "y": " b. tiftik (n)"
  },
  {
    "x": "tifike (m)",
    "y": " baca, tifiki (m) b. tifike (m)"
  },
  {
    "x": "tifiki (m) b. tifike (m)"
  },
  {
    "x": "tifing (n)",
    "y": " tüfek"
  },
  {
    "x": "tifingi (m)",
    "y": " b. tifing (n)"
  },
  {
    "x": "tifî (n)",
    "y": " b. tu-III (n)"
  },
  {
    "x": "tifî kerdene",
    "y": " b. tu kerdene"
  },
  {
    "x": "tifî ... sawitene",
    "y": " b. tu ... sawitene"
  },
  {
    "x": "tifî ... sawitiş",
    "y": " b. tu ... sawitiş"
  },
  {
    "x": "tifîkerdiş (n)",
    "y": " b. tukerdiş (n)"
  },
  {
    "x": "tifkerdiş (n)",
    "y": " b. tukerdiş (n)"
  },
  {
    "x": "Tiflîs (n)",
    "y": " Tiflis"
  },
  {
    "x": "tifong (n)",
    "y": " b. tifing (n)"
  },
  {
    "x": "tiftik (n)",
    "y": " tiftik\r\n~kilawa/kulika tiftikêne (m): tiftik külah"
  },
  {
    "x": "tiftike (m)",
    "y": " 1)külah, başlık\r\n~2)b. tiftik (n)"
  },
  {
    "x": "tiftiki (m)",
    "y": " b. tiftike (m)"
  },
  {
    "x": "tiftikin, -e",
    "y": " tiftikli"
  },
  {
    "x": "tigê",
    "y": " b. tîkê-I"
  },
  {
    "x": "tihal, -e",
    "y": " b. tal, -e-I"
  },
  {
    "x": "tik-I (TE)",
    "y": " tık"
  },
  {
    "x": "tik-II",
    "y": " b. tek"
  },
  {
    "x": "tik-tena",
    "y": " b. tek-tena"
  },
  {
    "x": "tik-tenê",
    "y": " b. tek-tena"
  },
  {
    "x": "tik-tenya",
    "y": " b. tek-tena"
  },
  {
    "x": "tik-teyna",
    "y": " b. tek-tena"
  },
  {
    "x": "tik-têna",
    "y": " b. tek-tena"
  },
  {
    "x": "tik-têyna",
    "y": " b. tek-tena"
  },
  {
    "x": "tika (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "tikê",
    "y": " b. tîkê-I"
  },
  {
    "x": "tikî",
    "y": " b. tîkê-I"
  },
  {
    "x": "tikîe",
    "y": " b. tîkê-I"
  },
  {
    "x": "tikîn",
    "y": " b. tayêne"
  },
  {
    "x": "tikîtenya",
    "y": " b. tekûtena"
  },
  {
    "x": "tiknayene (TE)",
    "y": " tıklamak\r\n~Bitikne! (Tıkla!) (TE)"
  },
  {
    "x": "tikûtena",
    "y": " b. tekûtena"
  },
  {
    "x": "tikûtenê",
    "y": " b. tekûtena"
  },
  {
    "x": "tikûtenya",
    "y": " b. tekûtena"
  },
  {
    "x": "tikûteyna",
    "y": " b. tekûtena"
  },
  {
    "x": "tikûtêna",
    "y": " b. tekûtena"
  },
  {
    "x": "tikûtêyna",
    "y": " b. tekûtena"
  },
  {
    "x": "tikye",
    "y": " b. tîkê-I"
  },
  {
    "x": "til (n)",
    "y": " tepe"
  },
  {
    "x": "tilar, -e-I",
    "y": " b. tiral, -e"
  },
  {
    "x": "tilar-II (n)",
    "y": " b. pilar (n)"
  },
  {
    "x": "tilarey",
    "y": " b. tiralîye (m)"
  },
  {
    "x": "tilarê (m)",
    "y": " b. tiralîye (m)"
  },
  {
    "x": "tilay",
    "y": " b. tillayî"
  },
  {
    "x": "tilf (n)",
    "y": " b. tilif (n) "
  },
  {
    "x": "tilif (n)",
    "y": " küspe"
  },
  {
    "x": "tilla",
    "y": " b. tillayî"
  },
  {
    "x": "tillahî",
    "y": " b. tillayî"
  },
  {
    "x": "tillay",
    "y": " b. tillayî"
  },
  {
    "x": "tillayî",
    "y": " tallahi"
  },
  {
    "x": "tilley",
    "y": " b. tillayî"
  },
  {
    "x": "tilm (n)",
    "y": " çıngıl, cıngıl, şıngıl (salkım parçası, küçük salkım)"
  },
  {
    "x": "tilmik (n)",
    "y": " çıngıl, cıngıl, şıngıl (salkım parçası, küçük salkım)"
  },
  {
    "x": "tilpê daran (n)",
    "y": " ağaç topluluğu"
  },
  {
    "x": "tilpê hewrî (n)",
    "y": " bulut kümesi "
  },
  {
    "x": "tilpi (m)",
    "y": " b. tirewe (m)"
  },
  {
    "x": "tiltili (n)",
    "y": " b. tiltilik (n)"
  },
  {
    "x": "tiltilik (n)",
    "y": " doruk, şahika, zirve"
  },
  {
    "x": "tim",
    "y": " 1)sürekli, devamlı, daima, hep, her zaman\r\n~2)baştan sona (zr)"
  },
  {
    "x": "tim bîyene",
    "y": " süreklileşmek"
  },
  {
    "x": "tim kerdene",
    "y": " süreklileştirmek"
  },
  {
    "x": "tim-tim",
    "y": " b. timûtim"
  },
  {
    "x": "timafîl",
    "y": " b. otomobîle (m)"
  },
  {
    "x": "timanî (zh)",
    "y": " b. tumanî (zh)"
  },
  {
    "x": "timanê kirrî (zh)",
    "y": " b. tumanê kirrî (zh)"
  },
  {
    "x": "timanê qolî (zh)",
    "y": " b. tumanê qolî (zh)"
  },
  {
    "x": "timatim",
    "y": " b. timûtim"
  },
  {
    "x": "timbîyayîş (n)",
    "y": " süreklileşme"
  },
  {
    "x": "timi",
    "y": " b. tim "
  },
  {
    "x": "timin, -e-I",
    "y": " sürekli, devamlı, daimi"
  },
  {
    "x": "timin bîyene",
    "y": " süreklileşmek"
  },
  {
    "x": "timin kerdene",
    "y": " süreklileştirmek"
  },
  {
    "x": "timin-II (n)",
    "y": " 32 kg ağırlığında bir tahıl ölçüsü"
  },
  {
    "x": "timinbîyayîş (n)",
    "y": " süreklileşme"
  },
  {
    "x": "timinî (m)",
    "y": " devamlılık, süreklilik"
  },
  {
    "x": "timinkerdiş (n)",
    "y": " süreklileştirme"
  },
  {
    "x": "timî-I (m)",
    "y": " devamlılık, süreklilik"
  },
  {
    "x": "timî-II",
    "y": " b. tim "
  },
  {
    "x": "timkerdiş (n)",
    "y": " süreklileştirme"
  },
  {
    "x": "timo",
    "y": " b. tim"
  },
  {
    "x": "timonî (zh)",
    "y": " b. tumanî (zh)"
  },
  {
    "x": "timotim",
    "y": " b. timûtim"
  },
  {
    "x": "timû",
    "y": " b. tim "
  },
  {
    "x": "timûn (zh)",
    "y": " b. tumanî (zh)"
  },
  {
    "x": "timûnî quêl (zh)",
    "y": " b. tumanê qolî (zh)"
  },
  {
    "x": "timûnî (zh)",
    "y": " b. tumanî (zh)"
  },
  {
    "x": "timûtim",
    "y": " 1)sürekli, devamlı, daima, hep, her zaman\r\n~2)baştan sona (zr)"
  },
  {
    "x": "timûtim bîyene",
    "y": " süreklileşmek"
  },
  {
    "x": "timûtim kerdene",
    "y": " süreklileştirmek"
  },
  {
    "x": "timûtimbîyayîş (n)",
    "y": " süreklileşme"
  },
  {
    "x": "timûtimin, -e",
    "y": " sürekli, devamlı, daimi"
  },
  {
    "x": "timûtimin bîyene",
    "y": " süreklileşmek"
  },
  {
    "x": "timûtimin kerdene",
    "y": " süreklileştirmek"
  },
  {
    "x": "timûtiminbîyayîş (n)",
    "y": " süreklileşme"
  },
  {
    "x": "timûtiminî (m)",
    "y": " devamlılık, süreklilik"
  },
  {
    "x": "timûtiminkerdiş (n)",
    "y": " süreklileştirme"
  },
  {
    "x": "timûtimî (m)",
    "y": " devamlılık, süreklilik"
  },
  {
    "x": "timûtimkerdiş (n)",
    "y": " süreklileştirme"
  },
  {
    "x": "tina",
    "y": " b. tena"
  },
  {
    "x": "tinaz (n)",
    "y": " alay"
  },
  {
    "x": "... de tinaz kerdene",
    "y": " dalga geçmek\r\n~Mi de tinaz meke! (Benimle dalga geçme!)"
  },
  {
    "x": "pê ... tinaz kerdene",
    "y": " dalga geçmek\r\n~Pê kêna/kêneke tinaz meke! (Kızla dalga geçme!)"
  },
  {
    "x": "tinedî, -ye",
    "y": " b. tunedî, -ye"
  },
  {
    "x": "tinê",
    "y": " b. tena"
  },
  {
    "x": "tenîya",
    "y": " b. tena"
  },
  {
    "x": "tinya",
    "y": " b. tena"
  },
  {
    "x": "tip",
    "y": " b. top"
  },
  {
    "x": "tip pîya",
    "y": " b. top pîya"
  },
  {
    "x": "tip-tal, -e",
    "y": " bomboş"
  },
  {
    "x": "tip-tol, -e",
    "y": " b. tip-tal, -e"
  },
  {
    "x": "tipînî",
    "y": " b. topîne"
  },
  {
    "x": "tipûtal, -e",
    "y": " bomboş"
  },
  {
    "x": "tipûtol, -e",
    "y": " b. tipûtal, -e"
  },
  {
    "x": "tir (n)",
    "y": " b. tewir (n)"
  },
  {
    "x": "o tir",
    "y": " b. o tewir"
  },
  {
    "x": "...tir (sf)",
    "y": " “daha” anlamı veren bir sonek\r\n~Ti ey ra zaftir zanî. (Sen ondan daha çok biliyorsun.)"
  },
  {
    "x": "tir perarey (n)",
    "y": " bugünden önceki dördüncü gün"
  },
  {
    "x": "tira bîyene",
    "y": " koparılmak"
  },
  {
    "x": "tira dayene",
    "y": " -e sürmek"
  },
  {
    "x": "tira dîyayene",
    "y": " -e sürülmek"
  },
  {
    "x": "tira gêrayene",
    "y": " (kadınla) cinsel ilişkide bulunmak"
  },
  {
    "x": "tira kerdene-I",
    "y": " koparmak"
  },
  {
    "x": "tira kerdene-II",
    "y": " b. ... ra kerdene"
  },
  {
    "x": "tira sawitene",
    "y": " -e sürmek"
  },
  {
    "x": "tira sawîyayene",
    "y": " -e sürülmek"
  },
  {
    "x": "tira soynayene",
    "y": " -e sürmek"
  },
  {
    "x": "tira soynîyayene",
    "y": " -e sürülmek"
  },
  {
    "x": "tira şanayene",
    "y": " -e serpmek"
  },
  {
    "x": "tira şîyayene",
    "y": " b. ... ra şîyene"
  },
  {
    "x": "tira şonayîne",
    "y": " b. tira şanayene"
  },
  {
    "x": "tiraameya (m)",
    "y": " b. ciraameya (m), ciraameyîye (m)"
  },
  {
    "x": "tiraameye (n)",
    "y": " b. ciraameye (n)"
  },
  {
    "x": "tirabîyayîş (n)",
    "y": " koparılma"
  },
  {
    "x": "tirafe (m)",
    "y": " köz yığını"
  },
  {
    "x": "tiragêrayîş (n)",
    "y": " (kadınla) cinsel ilişkide bulunma"
  },
  {
    "x": "tirakerdiş-I (n)",
    "y": " koparma"
  },
  {
    "x": "tirakerdiş-II (n)",
    "y": " b. ... rakerdiş"
  },
  {
    "x": "tiral, -e",
    "y": " tembel"
  },
  {
    "x": "tiralîye (m)",
    "y": " tembellik"
  },
  {
    "x": "tiram",
    "y": " b. tirêm"
  },
  {
    "x": "tiramî (m)",
    "y": " köz yığını"
  },
  {
    "x": "tiraomeya (m)",
    "y": " b. ciraameya (m), ciraameyîye (m)"
  },
  {
    "x": "tiraomeye (n)",
    "y": " b. ciraameye (n)"
  },
  {
    "x": "tirasoynaye (n)",
    "y": " sürülü"
  },
  {
    "x": "tirasoynayîye (m)",
    "y": " sürülü"
  },
  {
    "x": "tiraş (n)",
    "y": " tıraş"
  },
  {
    "x": "tiraş kerdene",
    "y": " tıraş etmek"
  },
  {
    "x": "tiraşê erdîşe",
    "y": " sakal tıraşı"
  },
  {
    "x": "tiraşê porî/gijikî/sereyî",
    "y": " saç tıraşı"
  },
  {
    "x": "tiraşanayîş (n)",
    "y": " -e serpme"
  },
  {
    "x": "tiraşîyayîş (n)",
    "y": " b. ... raşîyayîş"
  },
  {
    "x": "tiraşkerdiş (n)",
    "y": " tıraş etme"
  },
  {
    "x": "tiraşonayîş (n)",
    "y": " b. tiraşanayîş (n)"
  },
  {
    "x": "tiravetiş (n)",
    "y": " çıkarım (mantıkta), istidlâl (mantıkta)"
  },
  {
    "x": "tirawitene",
    "y": " 1)çalmak\r\n~2)araklamak (argo), aşırmak (argo)"
  },
  {
    "x": "tirawite/a",
    "y": " çalıntı"
  },
  {
    "x": "tirawitî, -ye",
    "y": " çalıntı"
  },
  {
    "x": "tirawitiş (n)",
    "y": " çalma"
  },
  {
    "x": "tirawtene",
    "y": " b. tirawitene"
  },
  {
    "x": "tirawtiş (n)",
    "y": " b. tirawitiş (n)"
  },
  {
    "x": "tiraxtor (n)",
    "y": " b. traktore (m)"
  },
  {
    "x": "tiraynaya (m)",
    "y": " b. tirênayîye (m), tirênaya (m)"
  },
  {
    "x": "tiraynaye (n)",
    "y": " b. tirênaye (n)"
  },
  {
    "x": "tiraynayene",
    "y": " b. tirênayene"
  },
  {
    "x": "tiraynayîş (n)",
    "y": " b. tirênayîş (n)"
  },
  {
    "x": "tirb (m)",
    "y": " b. tirbe (m)"
  },
  {
    "x": "tirbe (m)",
    "y": " 1)mezar\r\n~2)türbe"
  },
  {
    "x": "tirbi (m)",
    "y": " b. tirbe (m)"
  },
  {
    "x": "tirçî (m)",
    "y": " b. tirkî (m)"
  },
  {
    "x": "tire (m)",
    "y": " tır (uzun kamyon)"
  },
  {
    "x": "tirew (m)",
    "y": " b. tirewe (m)"
  },
  {
    "x": "tirewe (m)",
    "y": " turp"
  },
  {
    "x": "tirewi (m)",
    "y": " b. tirewe (m)"
  },
  {
    "x": "tirewitene",
    "y": " b. tirawitene"
  },
  {
    "x": "tirewitiş (n)",
    "y": " b. tirawitiş (n) "
  },
  {
    "x": "tireynaya (m)",
    "y": " b. tirênayîye (m), tirênaya (m)"
  },
  {
    "x": "tireynaye (n)",
    "y": " b. tirênaye (n)"
  },
  {
    "x": "tireynayene",
    "y": " b. tirênayene"
  },
  {
    "x": "tireynayîş (n)",
    "y": " b. tirênayîş (n)"
  },
  {
    "x": "tirê",
    "y": " acaba"
  },
  {
    "x": "tirêm",
    "y": " acaba"
  },
  {
    "x": "tirênaya (m)",
    "y": " kavrulmuş olan (nohut vb. için)"
  },
  {
    "x": "tirênaye (n)",
    "y": " kavrulmuş olan (nohut vb. için)"
  },
  {
    "x": "tirênayene",
    "y": " kavurmak"
  },
  {
    "x": "tirênayîş (n)",
    "y": " kavurma"
  },
  {
    "x": "tirênayîye (m)",
    "y": " kavrulmuş olan (nohut vb. için)"
  },
  {
    "x": "tirêne-I (n)",
    "y": " b. terne (n)"
  },
  {
    "x": "tirêne-II (m)",
    "y": " b. trêne (m)"
  },
  {
    "x": "tirêqet (n)",
    "y": " b. terîqet (n)"
  },
  {
    "x": "tirêw (m)",
    "y": " b. tirewe (m)"
  },
  {
    "x": "tirêz (n)",
    "y": " dama, dokuztaş vb. oyunların genel adı"
  },
  {
    "x": "tirêze (m)",
    "y": " b. tirêz (n)"
  },
  {
    "x": "tiritene",
    "y": " b. tirawitene"
  },
  {
    "x": "tiritiş (n)",
    "y": " b. tirawitiş (n)"
  },
  {
    "x": "tirî-I (m)",
    "y": " üzüm"
  },
  {
    "x": "tirî-II (m)",
    "y": " b. tirrî (m)"
  },
  {
    "x": "tirî-III",
    "y": " b. tirê"
  },
  {
    "x": "tirîne (m)",
    "y": " b. trêne (m)"
  },
  {
    "x": "tirîni (m)",
    "y": " b. trêne (m)"
  },
  {
    "x": "tirîs (n)",
    "y": " b. tirêz (n)"
  },
  {
    "x": "tirîye-I (m)",
    "y": " b. tirî-I (m)"
  },
  {
    "x": "tirîye-II (m)",
    "y": " b. tirrîye (m)"
  },
  {
    "x": "tirk, -e-I",
    "y": " Türk"
  },
  {
    "x": "tirk-II (n)",
    "y": " terk (n)"
  },
  {
    "x": "tirkemal (n)",
    "y": " b. tîrkeman (n)"
  },
  {
    "x": "tirkî (m)",
    "y": " Türkçe"
  },
  {
    "x": "tirkî (n)",
    "y": " b. tirkî (m)"
  },
  {
    "x": "tirkîtîye (m)",
    "y": " Türklük "
  },
  {
    "x": "Tirkîya (m)",
    "y": " Türkiye"
  },
  {
    "x": "tirkîye (m)",
    "y": " Türklük "
  },
  {
    "x": "Tirkmenîstan (n)",
    "y": " Türkmenistan"
  },
  {
    "x": "tirkperest, -e",
    "y": " Türkçü "
  },
  {
    "x": "tirkperestîye (m)",
    "y": " Türkçülük"
  },
  {
    "x": "tirkperwer, -e",
    "y": " Türkçü "
  },
  {
    "x": "tirkperwerîye (m)",
    "y": " Türkçülük"
  },
  {
    "x": "tirm (n)",
    "y": " b. term-I (n)"
  },
  {
    "x": "tirmix (m)",
    "y": " b. tirmixe (m)"
  },
  {
    "x": "tirmixe (m)",
    "y": " tırmık "
  },
  {
    "x": "tirmixi (m)",
    "y": " b. tirmixe (m)"
  },
  {
    "x": "tirnawîda (m)",
    "y": " b. tornavîda (m)"
  },
  {
    "x": "tirnaya (m)",
    "y": " b. tirênayîye (m), tirênaya (m)"
  },
  {
    "x": "tirnaye (n)",
    "y": " b. tirênaye (n)"
  },
  {
    "x": "tirnayene",
    "y": " b. tirênayene"
  },
  {
    "x": "tirnayîş (n)",
    "y": " b. tirênayîş (n)"
  },
  {
    "x": "tirnefes (n)",
    "y": " nefes darlığı"
  },
  {
    "x": "tiro cenayene",
    "y": " 1)b. ... ro cenayene\r\n~2)bandırmak"
  },
  {
    "x": "tiro cenitene",
    "y": " bandırmak"
  },
  {
    "x": "tiro kerdene",
    "y": " 1)taramak\r\n~2)b. ... ro kerdene"
  },
  {
    "x": "tiro rînayene",
    "y": " bandırmak"
  },
  {
    "x": "tiro şîyayene",
    "y": " b. ... ro şîyene"
  },
  {
    "x": "tiro vejîyayene",
    "y": " tırmanmak"
  },
  {
    "x": "tiro vetene",
    "y": " bandırmak"
  },
  {
    "x": "tiro war kerdene",
    "y": " b. ... ro war kerdene"
  },
  {
    "x": "tirocenayîş (n)",
    "y": " 1)b. ... ro cenayîş\r\n~2)bandırma"
  },
  {
    "x": "tirocenitiş (n)",
    "y": " bandırma"
  },
  {
    "x": "tirokerdiş (n)",
    "y": " 1)tarama\r\n~2)b. ... rokerdiş"
  },
  {
    "x": "tirokewte (n)",
    "y": " göçük"
  },
  {
    "x": "tirorînayîş (n)",
    "y": " bandırma"
  },
  {
    "x": "tiroşîyayîş (n)",
    "y": " b. ... roşîyayîş"
  },
  {
    "x": "tirovejîyayîş (n)",
    "y": " tırmanma"
  },
  {
    "x": "tirovetiş (n)",
    "y": " bandırma"
  },
  {
    "x": "tirow (m)",
    "y": " b. tirewe (m)"
  },
  {
    "x": "tirowarkerdiş (n)",
    "y": " b. ... rowarkerdiş (n)"
  },
  {
    "x": "tirp-I (n)",
    "y": " tepe"
  },
  {
    "x": "tirp-II (m)",
    "y": " b. tirewe (m)"
  },
  {
    "x": "tirpan (m)",
    "y": " b. tirpane (m)"
  },
  {
    "x": "tirpan kerdene",
    "y": " tırpanlamak"
  },
  {
    "x": "tirpancênî (m)",
    "y": " b. tirpankêşî (m)"
  },
  {
    "x": "tirpancî, -ye",
    "y": " b. tirpankêş, -e"
  },
  {
    "x": "tirpancîtî (m)",
    "y": " b. tirpankêşî (m)"
  },
  {
    "x": "tirpancîtîye (m)",
    "y": " b. tirpankêşî (m)"
  },
  {
    "x": "tirpancîyê (m)",
    "y": " b. tirpankêşî (m)"
  },
  {
    "x": "tirpancîyîye (m)",
    "y": " b. tirpankêşî (m)"
  },
  {
    "x": "tirpançênî (m)",
    "y": " b. tirpankêşî (m)"
  },
  {
    "x": "tirpançî, -ye",
    "y": " b. tirpankêş, -e"
  },
  {
    "x": "tirpançîtî (m)",
    "y": " b. tirpankêşî (m)"
  },
  {
    "x": "tirpançîtîye (m)",
    "y": " b. tirpankêşî (m)"
  },
  {
    "x": "tirpane (m)",
    "y": " tırpan"
  },
  {
    "x": "tirpani (m)",
    "y": " b. tirpane (m)"
  },
  {
    "x": "tirpankêş, -e",
    "y": " tırpancı"
  },
  {
    "x": "tirpankêşî (m)",
    "y": " tırpancılık"
  },
  {
    "x": "tirpankêşîye (m)",
    "y": " tırpancılık"
  },
  {
    "x": "tirpe (m)",
    "y": " b. tirewe (m)"
  },
  {
    "x": "tirpi (m)",
    "y": " b. tirewe (m)"
  },
  {
    "x": "tirponi (m)",
    "y": " b. tirpane (m)"
  },
  {
    "x": "tirpûn (m)",
    "y": " b. tirpane (m)"
  },
  {
    "x": "tirpûnçî (n, m)",
    "y": " b. tirpankêş, -e"
  },
  {
    "x": "tirpûnçîyê (m)",
    "y": " b. tirpankêşî (m)"
  },
  {
    "x": "tirr (n)",
    "y": " dik yamaç, yar, uçurum"
  },
  {
    "x": "tirramî (m)",
    "y": " b. tiramî (m)"
  },
  {
    "x": "tirranî (m)",
    "y": " b. tiramî (m) "
  },
  {
    "x": "tirrêz (n)",
    "y": " b. tirêz (n)"
  },
  {
    "x": "tirrî (m)",
    "y": " koyun veya koç kuyruğu"
  },
  {
    "x": "tirrîs (n)",
    "y": " b. tirêz (n)"
  },
  {
    "x": "tirrîye (m)",
    "y": " koyun veya koç kuyruğu"
  },
  {
    "x": "tirs-I (n)",
    "y": " kalkan"
  },
  {
    "x": "tirs, -e-II",
    "y": " tirş, -e"
  },
  {
    "x": "tirsik (m)",
    "y": " b. tirşike (m)"
  },
  {
    "x": "tirsike (m)",
    "y": " b. tirşike (m)"
  },
  {
    "x": "tirş, -e",
    "y": " ekşi"
  },
  {
    "x": "tirş û tal, -e",
    "y": " aşırı ekşi"
  },
  {
    "x": "tirş û tiloq, -e",
    "y": " aşırı ekşi"
  },
  {
    "x": "tirş û tuloq, -e",
    "y": " b. tirş û tiloq, -e"
  },
  {
    "x": "tirş (m)",
    "y": " b. tirşe (m)"
  },
  {
    "x": "tirşe (m)",
    "y": " 1)ekşili bir yemek\r\n~2)düğün yemeği (topluca yenir)"
  },
  {
    "x": "tirşey (m)",
    "y": " b. tirşîye (m), tirşî (m)"
  },
  {
    "x": "tirşê (m)",
    "y": " b. tirşîye (m), tirşî (m)"
  },
  {
    "x": "tirşênî (m)",
    "y": " ekşilik"
  },
  {
    "x": "tirşi-I (m)",
    "y": " b. tirşe (m)"
  },
  {
    "x": "tirşi-II (n)",
    "y": " b. tirşî-2 (m)"
  },
  {
    "x": "tirşi degerotene",
    "y": " b. tirşî degirewtene"
  },
  {
    "x": "tirşik (m)",
    "y": " b. tirşike (m)"
  },
  {
    "x": "tirşike (m)",
    "y": " 1)ekşili bir yemek\r\n~2)düğün yemeği (topluca yenir)\r\n~3)kuzukulağı"
  },
  {
    "x": "tirşiki (m)",
    "y": " b. tirşike (m)"
  },
  {
    "x": "tirşing (m)",
    "y": " b. tirşike-3 (m)"
  },
  {
    "x": "tirşinge (m)",
    "y": " b. tirşike-3 (m)"
  },
  {
    "x": "tirşingi (m)",
    "y": " b. tirşike-3 (m)"
  },
  {
    "x": "tirşim",
    "y": " biraz"
  },
  {
    "x": "tirşî (m)",
    "y": " 1)ekşilik\r\n~2)turşu"
  },
  {
    "x": "tirşî degirewtene",
    "y": " turşu kurmak"
  },
  {
    "x": "tirşî deguretene",
    "y": " b. tirşî degirewtene"
  },
  {
    "x": "tirşî deguritene",
    "y": " b. tirşî degirewtene"
  },
  {
    "x": "tirşî degurotene",
    "y": " b. tirşî degirewtene"
  },
  {
    "x": "tirşî ronayene",
    "y": " turşu kurmak"
  },
  {
    "x": "tirşî virastene",
    "y": " b. tirşî viraştene "
  },
  {
    "x": "tirşî viraştene",
    "y": " turşu kurmak"
  },
  {
    "x": "tirşîdegirewtox, -e",
    "y": " turşucu (turşu yapan kimse)"
  },
  {
    "x": "tirşîroş, -e",
    "y": " turşucu (turşu satan kimse)"
  },
  {
    "x": "tirşîye (m)",
    "y": " ekşilik"
  },
  {
    "x": "tirşo (n)",
    "y": " b. tirşî-2 (m)"
  },
  {
    "x": "tirşo degirotene",
    "y": " b. tirşî degirewtene"
  },
  {
    "x": "tirşo degirowtene",
    "y": " b. tirşî degirewtene"
  },
  {
    "x": "tirşo ronayîne",
    "y": " b. tirşî ronayene"
  },
  {
    "x": "tirşok (n)",
    "y": " b. tirşike (m)"
  },
  {
    "x": "tirteli (n)",
    "y": " b. tiltili (n), tiltilik (n)"
  },
  {
    "x": "tirtene",
    "y": " b. tirawitene"
  },
  {
    "x": "tirtir (n)",
    "y": " b. tertul (n)"
  },
  {
    "x": "tirtiş (n)",
    "y": " b. tirawitiş (n)"
  },
  {
    "x": "tirûnc dayene",
    "y": " b. tuncî dayene"
  },
  {
    "x": "tirûncdayîş (n)",
    "y": " b. tuncîdayîş (n)"
  },
  {
    "x": "tirva (m)",
    "y": " b. tirbe (m)"
  },
  {
    "x": "tirve (n)",
    "y": " b. tirbe (m)"
  },
  {
    "x": "tişk, -e",
    "y": " b. tuşk, -e"
  },
  {
    "x": "tiştare (n)",
    "y": " b. teştare (n) "
  },
  {
    "x": "tiştarey",
    "y": " b. teştareyî "
  },
  {
    "x": "tiştîrî (n)",
    "y": " bir-iki yaşındaki keçi yavrusu, çebiç, çepiç"
  },
  {
    "x": "titik (n)",
    "y": " b. tîtik (n)"
  },
  {
    "x": "titûn (n)",
    "y": " b. tutin (n)"
  },
  {
    "x": "tivig (n)",
    "y": " b. tifing (n)"
  },
  {
    "x": "tiving (n)",
    "y": " b. tifing (n)"
  },
  {
    "x": "tivinge (m)",
    "y": " b. tifing (n)"
  },
  {
    "x": "tivingi (m)",
    "y": " b. tifing (n)"
  },
  {
    "x": "tivrik (n)",
    "y": " b. tevrik (n)"
  },
  {
    "x": "tiwa (n)",
    "y": " b. teba (n)"
  },
  {
    "x": "tiway",
    "y": " b. tebayê"
  },
  {
    "x": "tiwalet (n)",
    "y": " b. tuwalet (n)"
  },
  {
    "x": "tiwer (n)",
    "y": " b. teber (n)"
  },
  {
    "x": "tixbelek, -e",
    "y": " alaca (siyah-beyaz renkli)"
  },
  {
    "x": "tixt (m)",
    "y": " b. tixte (m)"
  },
  {
    "x": "tixt (n)",
    "y": " b. tixte (m)"
  },
  {
    "x": "tixte (m)",
    "y": " \"weqa\"nın dörtte biri ağırlığında olan bir ağırlık ölçüsü birimi"
  },
  {
    "x": "tixtor, -e",
    "y": " b. doktor, -e"
  },
  {
    "x": "tiya (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "tizba (m)",
    "y": " b. tezbîhe (m)"
  },
  {
    "x": "tizbe (m)",
    "y": " b. tezbîhe (m)"
  },
  {
    "x": "tizbêhe (m)",
    "y": " b. tezbîhe (m)"
  },
  {
    "x": "tizbêhi (m)",
    "y": " b. tezbîhe (m)"
  },
  {
    "x": "tizbihi (m)",
    "y": " b. tezbîhe (m)"
  },
  {
    "x": "tizike (m)",
    "y": " anüs çıkıntısı"
  },
  {
    "x": "tiznayene",
    "y": " sıkıştırmak"
  },
  {
    "x": "tiznayîne",
    "y": " b. tiznayene"
  },
  {
    "x": "tiznayîs (n)",
    "y": " b. tiznayîş (n)"
  },
  {
    "x": "tiznayîş (n)",
    "y": " sıkıştırma"
  },
  {
    "x": "tîc (m)",
    "y": " 1)b. tîje (m)\r\n~2)b. tîrêje (m)"
  },
  {
    "x": "tîcaret (n)",
    "y": " ticaret"
  },
  {
    "x": "tîcarî, -ye",
    "y": " ticari"
  },
  {
    "x": "tîce (m)",
    "y": " b. tîje (m)"
  },
  {
    "x": "tîci (m)",
    "y": " b. tîje (m)"
  },
  {
    "x": "tîci (n)",
    "y": " b. tîjin (n)"
  },
  {
    "x": "tîcin, -e",
    "y": " b. tîjin, -e"
  },
  {
    "x": "tîdir",
    "y": " b. têde"
  },
  {
    "x": "tîdir pîya",
    "y": " b. têde pîya"
  },
  {
    "x": "tîesa (n)",
    "y": " b. têşan, -e"
  },
  {
    "x": "tîesa bîyene",
    "y": " b. têşan bîyene"
  },
  {
    "x": "tîesabîyayîş (n)",
    "y": " b. têşanbîyayîş (n)"
  },
  {
    "x": "tîesanîye (m)",
    "y": " b. têşanî (m)"
  },
  {
    "x": "tîeşt (n)",
    "y": " b. teşt (n)"
  },
  {
    "x": "tîetor",
    "y": " b. têvter"
  },
  {
    "x": "tîfo (n)",
    "y": " tifo, karahumma"
  },
  {
    "x": "tîfus (n)",
    "y": " tifüs, lekelihumma"
  },
  {
    "x": "tîj-I",
    "y": " b. tuj, -e"
  },
  {
    "x": "tîj bîyayene",
    "y": " b. tuj bîyene"
  },
  {
    "x": "tîj kerdene",
    "y": " b. tuj kerdene"
  },
  {
    "x": "tîj-II (m)",
    "y": " 1)b. tîje (m)\r\n~2)b. tîrêje (m)"
  },
  {
    "x": "tîjbîyayîş (n)",
    "y": " b. tujbîyayîş (n)"
  },
  {
    "x": "tîje (m)",
    "y": " 1)güneş\r\n~Tîje akewte. (Güneş doğdu.)\r\n~Tîje eşt. (Güneş doğdu.) \r\n~2)güneş veya ay ışığı, mehtap\r\n~3)güneş ısısı"
  },
  {
    "x": "tîjê (m)",
    "y": " b. tujîye (m), tujî (m)"
  },
  {
    "x": "tîji (m)",
    "y": " b. tîje (m)"
  },
  {
    "x": "tîji (n)",
    "y": " b. tîjin (n)"
  },
  {
    "x": "tîjin, -e",
    "y": " güneşli"
  },
  {
    "x": "tîjî (m)",
    "y": " b. tîje (m)"
  },
  {
    "x": "tîjkerdiş (n)",
    "y": " b. tujkerdiş (n)"
  },
  {
    "x": "tîk, -e",
    "y": " dik"
  },
  {
    "x": "tîk kerdene",
    "y": " dikleştirmek, dikeltmek"
  },
  {
    "x": "tîkane, -ye",
    "y": " dikey, amudi"
  },
  {
    "x": "tîkey goştî (n)",
    "y": " b. tîkeyê goştî (n)"
  },
  {
    "x": "tîkeyê goştî (n)",
    "y": " et parçası, kuşbaşı et"
  },
  {
    "x": "tîkey (m)",
    "y": " b. tîkîye (m)"
  },
  {
    "x": "tîkê-I",
    "y": " biraz, az"
  },
  {
    "x": "tîkê bîyene",
    "y": " azalmak"
  },
  {
    "x": "tîkê-II (m)",
    "y": " b. tîkîye (m)"
  },
  {
    "x": "tîkêbîyayîş (n)",
    "y": " azalma"
  },
  {
    "x": "tîkgoşe (n)",
    "y": " dikdörtgen"
  },
  {
    "x": "tîkhoke (m)",
    "y": " dik açı"
  },
  {
    "x": "tîkhokin, -e",
    "y": " dik açılı"
  },
  {
    "x": "tîkil (n)",
    "y": " zirve\r\n~tîkilê dare (n): ağacın tepesi"
  },
  {
    "x": "tîkilê dare (n)",
    "y": " ağacın tepesi"
  },
  {
    "x": "tîkile (m)",
    "y": " b. tîkil (n)"
  },
  {
    "x": "tîkila dare (m)",
    "y": " b. tîkilê dare (n)"
  },
  {
    "x": "tîkî (m)",
    "y": " diklik"
  },
  {
    "x": "tîkîye (m)",
    "y": " diklik"
  },
  {
    "x": "tîllayî",
    "y": " b. tillayî"
  },
  {
    "x": "tîmar (n)",
    "y": " tımar (hayvan için)"
  },
  {
    "x": "tîmar kerdene",
    "y": " 1)tımar etmek 2(mecazi) dayak atmak, dövmek, kötek atmak"
  },
  {
    "x": "tîmarxane (n)",
    "y": " akıl hastanesi"
  },
  {
    "x": "tîmîûn bîyayene",
    "y": " b. têmîyan bîyene"
  },
  {
    "x": "tîmîyûn bîyayene",
    "y": " b. têmîyan bîyene"
  },
  {
    "x": "tîmsah, -e",
    "y": " timsah "
  },
  {
    "x": "tîmûn bîyayene",
    "y": " b. têmîyan bîyene"
  },
  {
    "x": "tîn, -e",
    "y": " sersem, alık"
  },
  {
    "x": "tîn bîyayîne",
    "y": " b. tîn bîyene"
  },
  {
    "x": "tîn bîyene",
    "y": " sersemleşmek, sersemlemek"
  },
  {
    "x": "tîn kerdene",
    "y": " sersemletmek, sersem etmek"
  },
  {
    "x": "tîna",
    "y": " b. tena"
  },
  {
    "x": "tînapeykî",
    "y": " ters\r\n~No mêrdek verê mi ra ewnîya, dima tînapeykî qalî kerd. (Bu adam önce bana baktı, sonra ters [biçimde] konuşmaya başladı"
  },
  {
    "x": "tînc (m)",
    "y": " b. tîje (m)"
  },
  {
    "x": "tînce (m)",
    "y": " b. tîje (m)"
  },
  {
    "x": "tînci (m)",
    "y": " b. tîje (m)"
  },
  {
    "x": "tînci (n)",
    "y": " b. tîjin (n)"
  },
  {
    "x": "tîncin, -e",
    "y": " b. tîjin, -e"
  },
  {
    "x": "tîner (n)",
    "y": " tiner"
  },
  {
    "x": "tînê (m)",
    "y": " b. tînî (m)"
  },
  {
    "x": "tînî (m)",
    "y": " sersemlik, alıklık"
  },
  {
    "x": "tîpa",
    "y": " b. tepîya "
  },
  {
    "x": "tîpîya",
    "y": " b. tepîya"
  },
  {
    "x": "tîr (m)",
    "y": " 1)b. tîre (m)\r\n~2)b. tîrî-I (m), tîrîya nanî (m)"
  },
  {
    "x": "tîr û keman",
    "y": " ok ve yay"
  },
  {
    "x": "tîraj (n)",
    "y": " tiraj"
  },
  {
    "x": "Tîrana (m)",
    "y": " Tiran"
  },
  {
    "x": "tîraqtori (m)",
    "y": " b. traktore (m)"
  },
  {
    "x": "tîre-I (m)",
    "y": " 1)ok\r\n~2)kiriş (bina için)\r\n~3)b. tîrî-I (m), tîrîya nanî (m)\r\n~4)b. tîrîye (m)"
  },
  {
    "x": "tîra alete (m)",
    "y": " saban oku, karasaban oku"
  },
  {
    "x": "tîra cite (m)",
    "y": " b. tîra alete (m)"
  },
  {
    "x": "tîra hangaje (m)",
    "y": " b. tîra hengazî (m)"
  },
  {
    "x": "tîra hengajî (m)",
    "y": " b. tîra hengazî (m)"
  },
  {
    "x": "tîra hengazî (m)",
    "y": " saban oku, karasaban oku"
  },
  {
    "x": "tîrê roşndarî (zh)",
    "y": " ışıklı oklar (trafik için)"
  },
  {
    "x": "tîre-II (m)",
    "y": " 1)kolik (tıpta)\r\n~2)sancı\r\n~3)sızı"
  },
  {
    "x": "tîra madeyî (m)",
    "y": " mide krampı"
  },
  {
    "x": "tîra madî (m)",
    "y": " b. tîra madeyî (m)"
  },
  {
    "x": "tîra mîyaneyî (m)",
    "y": " bel ağrısı"
  },
  {
    "x": "tîra mîyanî (m)",
    "y": " b. tîra mîyaneyî (m)"
  },
  {
    "x": "tîra paştî (n)",
    "y": " b. tîra piştî (m)"
  },
  {
    "x": "tîra piştî (m)",
    "y": " sırt ağrısı"
  },
  {
    "x": "tîre dekewtene",
    "y": " 1)sancılanmak\r\n~2)sızlamak"
  },
  {
    "x": "tîre kerdene",
    "y": " 1)sancı vermek, sancımak\r\n~2)sızlamak"
  },
  {
    "x": "tîremar, -e",
    "y": " b. tîrmar, -e"
  },
  {
    "x": "tîremor, -e",
    "y": " b. tîrmar, -e"
  },
  {
    "x": "tîrene (m)",
    "y": " b. trêne (m)"
  },
  {
    "x": "tîrêj (m)",
    "y": " b. tîrêje (m)"
  },
  {
    "x": "tîrêje (m)",
    "y": " ışın"
  },
  {
    "x": "tîrêja hewz û keske (m)",
    "y": " gökkuşağı, alkım, ebekuşağı, ebemkuşağı, eleğimsağma, hacılarkuşağı, yağmurkuşağı, alaimisema"
  },
  {
    "x": "tîrêji (m)",
    "y": " b. tîrêje (m)"
  },
  {
    "x": "tîrêjpeym (n)",
    "y": " ışınölçer, radyometre"
  },
  {
    "x": "tîrêjpeymî (m)",
    "y": " radyometri"
  },
  {
    "x": "tîri (m)",
    "y": " 1)b. tîre (m)\r\n~2)b. tîrî-I (m), tîrîya nanî (m)\r\n~3)tîrîye (m) "
  },
  {
    "x": "tîri dekewtene",
    "y": " b. tîre dekewtene"
  },
  {
    "x": "tîri dekotene",
    "y": " b. tîre dekewtene"
  },
  {
    "x": "tîri kardene",
    "y": " b. tîre kerdene"
  },
  {
    "x": "tîric (m)",
    "y": " b. tîrêje (m)"
  },
  {
    "x": "tîrij (m)",
    "y": " b. tîrêje (m)"
  },
  {
    "x": "tîrin (m)",
    "y": " b. trêne (m)"
  },
  {
    "x": "tîrinç (m)",
    "y": " b. tîrêje (m)"
  },
  {
    "x": "tîriz (m)",
    "y": " b. tîrêje (m)"
  },
  {
    "x": "tîrize (m)",
    "y": " b. tîrêje (m)"
  },
  {
    "x": "tîrî-I (m)",
    "y": " 1)oklava\r\n~2)merdane\r\n~3)örgü şişi"
  },
  {
    "x": "tîrîya nanî (m)",
    "y": " 1)oklava\r\n~2)merdane"
  },
  {
    "x": "tîrî-II (m)",
    "y": " b. tirî (m)"
  },
  {
    "x": "tîrîs (n)",
    "y": " b. tirêz (n)"
  },
  {
    "x": "tîrîye-I (m)",
    "y": " örgü şişi"
  },
  {
    "x": "tîrîye-II (m)",
    "y": " b. tirî (m)"
  },
  {
    "x": "tîrkeman (n)",
    "y": " 1)ok\r\n~2)yay"
  },
  {
    "x": "tîrkemon (n)",
    "y": " b. tîrkeman (n)"
  },
  {
    "x": "tîrkemûn (n)",
    "y": " b. tîrkeman (n)"
  },
  {
    "x": "tîrkeman (n)",
    "y": " b. tîrkeman (n)"
  },
  {
    "x": "tîrkêş, -e",
    "y": " okçu (okçuluk sporu yapan, ok atıcı)"
  },
  {
    "x": "tîrkêşî (m)",
    "y": " okçuluk"
  },
  {
    "x": "tîrkêşîye (m)",
    "y": " okçuluk"
  },
  {
    "x": "tîrkiman (n)",
    "y": " b. tîrkeman (n)"
  },
  {
    "x": "tîrkivan (n)",
    "y": " b. tîrkeman (n)"
  },
  {
    "x": "tîrkuvan (n)",
    "y": " b. tîrkeman (n)"
  },
  {
    "x": "tîrmar, -e",
    "y": " okyılanı  "
  },
  {
    "x": "tîroj (m)",
    "y": " b. tîrêje (m)"
  },
  {
    "x": "tîroni (m)",
    "y": " b. trêne (m)"
  },
  {
    "x": "tîrroş, -e",
    "y": " okçu (ok satan)"
  },
  {
    "x": "tîrroşî (m)",
    "y": " okçuluk"
  },
  {
    "x": "tîrroşîye (m)",
    "y": " okçuluk"
  },
  {
    "x": "tîrûn (m)",
    "y": " b. trêne (m)"
  },
  {
    "x": "tîrviraştox, -e (m)",
    "y": " okçu (ok yapan)"
  },
  {
    "x": "tîrviraştoxî (m)",
    "y": " okçuluk"
  },
  {
    "x": "tîrviraştoxîye (m)",
    "y": " okçuluk"
  },
  {
    "x": "tîryakî, -ye",
    "y": " bağımlı"
  },
  {
    "x": "tîryakî bîyene",
    "y": " bağımlılaşmak"
  },
  {
    "x": "tîryakîbîyayîş (n)",
    "y": " bağımlılaşma"
  },
  {
    "x": "tîryakîtîye (m)",
    "y": " bağımlılık"
  },
  {
    "x": "tîryakîyîye (m)",
    "y": " bağımlılık"
  },
  {
    "x": "tîşort (n)",
    "y": " tişört"
  },
  {
    "x": "tîşt",
    "y": " keçiyi kovmak için kullanılan seslenme sözcüğü"
  },
  {
    "x": "tîştîrî",
    "y": " b. tiştîrî"
  },
  {
    "x": "tît (n)",
    "y": " süs"
  },
  {
    "x": "tîtal (n)",
    "y": " yakışıklı, keleş"
  },
  {
    "x": "tîtal bîyene",
    "y": " yakışıklı olmak"
  },
  {
    "x": "tîtalbîyayîş (n)",
    "y": " yakışıklı olma"
  },
  {
    "x": "tîtevît bîyene",
    "y": " b. tîtûvît bîyene"
  },
  {
    "x": "tîtevît kerdene",
    "y": " b. tîtûvît kerdene"
  },
  {
    "x": "tîtevîtbîyayîş (n)",
    "y": " b. tîtûvîtbîyayîş (n)"
  },
  {
    "x": "tîtevîtin, -e",
    "y": " b. tîtûvîtin, -e"
  },
  {
    "x": "tîtevîtkerdiş (n)",
    "y": " b. tîtûvîtkerdiş (n)"
  },
  {
    "x": "tîtê (n)",
    "y": " b. tîtik-III (n)"
  },
  {
    "x": "tîtik-I (n)",
    "y": " doruk, şahika, zirve\r\n~tîtikê dare (n): ağacın tepesi"
  },
  {
    "x": "tîtikê dare (n)",
    "y": " ağacın tepesi"
  },
  {
    "x": "tîtik-II (n)",
    "y": " süs"
  },
  {
    "x": "tîtik-III (n)",
    "y": " oyuncak\r\n~tîtiko darên (n): ahşap oyuncak"
  },
  {
    "x": "tîtiko darên (n)",
    "y": " ahşap oyuncak"
  },
  {
    "x": "tîtika ser o roniştene",
    "y": " b. tîtikan ser o roniştene"
  },
  {
    "x": "tîtikan ser o roniştene",
    "y": " çömelmek, çökmek"
  },
  {
    "x": "tîtikanseroroniştiş",
    "y": " çömelme, çömeliş, çökme"
  },
  {
    "x": "tîtikaseroroniştiş",
    "y": " b. tîtikanseroroniştiş"
  },
  {
    "x": "tîtikin, -e",
    "y": " süslü, şık"
  },
  {
    "x": "tîtikin bîyene",
    "y": " şıklaşmak, şık olmak"
  },
  {
    "x": "tîtikin kerdene",
    "y": " şıklaştırmak"
  },
  {
    "x": "tîtikinbîyayîş (n)",
    "y": " şıklaşma"
  },
  {
    "x": "tîtikinkerdiş (n)",
    "y": " şıklaştırma"
  },
  {
    "x": "tîtikîyaya (m)",
    "y": " süslü"
  },
  {
    "x": "tîtikîyaye (n)",
    "y": " süslü"
  },
  {
    "x": "tîtikîyayene",
    "y": " süslenmek (başkası tarafından süslenmek)"
  },
  {
    "x": "tîtikîyayîş (n)",
    "y": " süslenme (başkası tarafından süslenme)"
  },
  {
    "x": "tîtikîyayîye (m)",
    "y": " süslü"
  },
  {
    "x": "tîtikkar, -e",
    "y": " oyuncakçı (oyuncak yapan)"
  },
  {
    "x": "tîtiknaya (m)",
    "y": " süslü"
  },
  {
    "x": "tîtiknaye (n)",
    "y": " süslü"
  },
  {
    "x": "tîtiknayene",
    "y": " süslemek"
  },
  {
    "x": "tîtiknayîş (n)",
    "y": " süsleme"
  },
  {
    "x": "tîtiknayîye (m)",
    "y": " süslü"
  },
  {
    "x": "tîtikroş, -e",
    "y": " oyuncakçı (oyuncak satan) "
  },
  {
    "x": "tîtovît (n)",
    "y": " b. tîtûvît (n)"
  },
  {
    "x": "tîtovît bîyene",
    "y": " b. tîtûvît bîyene"
  },
  {
    "x": "tîtovît kerdene",
    "y": " b. tîtûvît kerdene"
  },
  {
    "x": "tîtovîtbîyayîş (n)",
    "y": " b. tîtûvîtbîyayîş (n)"
  },
  {
    "x": "tîtovîtin, -e",
    "y": " b. tîtûvîtin, -e"
  },
  {
    "x": "tîtovîtkerdiş (n)",
    "y": " b. tîtûvîtkerdiş (n)"
  },
  {
    "x": "tîtûvît (n)",
    "y": " süs püs"
  },
  {
    "x": "tîtûvît bîyene",
    "y": " süslenmek (başkası tarafından süslenmek"
  },
  {
    "x": "tîtûvît kerdene",
    "y": " süslemek "
  },
  {
    "x": "tîtûvîtbîyayîş (n)",
    "y": " süslenme (başkası tarafından süslenme)"
  },
  {
    "x": "tîtûvîtin, -e",
    "y": " süslü püslü "
  },
  {
    "x": "tîtûvîtkerdiş (n)",
    "y": " süsleme"
  },
  {
    "x": "tîxe (m)",
    "y": " tınaz, malama"
  },
  {
    "x": "tîya (n)",
    "y": " bura, burası"
  },
  {
    "x": "tîya geyrayîne",
    "y": " b. têwgêrayene"
  },
  {
    "x": "tîyageyrayîş (n)",
    "y": " b. têwgêrayîş (n)"
  },
  {
    "x": "tîyara (m)",
    "y": " b. teyara (m)"
  },
  {
    "x": "tîyare (n)",
    "y": " b. teyara (m)"
  },
  {
    "x": "tîyatro (n)",
    "y": " tiyatro "
  },
  {
    "x": "tîye-I (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "tîye-II (m, n, z)",
    "y": " 1)b. to (m, n, z)\r\n~2)b. ti (m, n, z)"
  },
  {
    "x": "tîyedest",
    "y": " b. têdest"
  },
  {
    "x": "tîyedir",
    "y": " b. têde"
  },
  {
    "x": "tîyedire",
    "y": " b. têde"
  },
  {
    "x": "tîyel (n)",
    "y": " b. têl (n)"
  },
  {
    "x": "tîyemîyonbîyayîş (n)",
    "y": " b. têmîyanbîyayîş (n)"
  },
  {
    "x": "tîyemîyonek (n)",
    "y": " têmîyanek, -e"
  },
  {
    "x": "tîyemîyû kerdene",
    "y": " b. têmîyan kerdene"
  },
  {
    "x": "tîyemîyûn fînîyayene",
    "y": " b. têmîyan fînîyayene"
  },
  {
    "x": "tîyemîyûn kerdene",
    "y": " b. têmîyan kerdene"
  },
  {
    "x": "tîyemîyûn ro dîyayene",
    "y": " b. têmîyan ro dîyayene"
  },
  {
    "x": "tîyemîyûn şûnîyayene",
    "y": " b. têmîyan şanîyayene"
  },
  {
    "x": "tîyemîyûn vistene",
    "y": " b. têmîyan vistene"
  },
  {
    "x": "tîyemîyûnbîyayîş (n)",
    "y": " b. têmîyanbîyayîş (n)"
  },
  {
    "x": "tîyemîyûnfînîyayîş (n)",
    "y": " b. têmîyanfînîyayîş (n)"
  },
  {
    "x": "tîyemîyûnkerde (n)",
    "y": " b. têmîyankerde (n)"
  },
  {
    "x": "tîyemîyûnkerdi (n)",
    "y": " b. têmîyankerde (n)"
  },
  {
    "x": "tîyemîyûnkerdiş (n)",
    "y": " b. têmîyankerdiş (n)"
  },
  {
    "x": "tîyemîyûnkewte (n)",
    "y": " b. têmîyankewte (n)"
  },
  {
    "x": "tîyemîyûnkewti (n)",
    "y": " b. têmîyankewte (n)"
  },
  {
    "x": "tîyemîyûnkewtiş (n)",
    "y": " b. têmîyankewtiş (n)"
  },
  {
    "x": "tîyemîyûnkotiş (n)",
    "y": " b. têmîyankewtiş (n)"
  },
  {
    "x": "tîyemîyûnrodîyayîş (n)",
    "y": " b. têmîyanrodîyayîş (n)"
  },
  {
    "x": "tîyemîyûnşaye (n)",
    "y": " b. têmîyanşanaye (n)"
  },
  {
    "x": "tîyemîyûnşayi (n)",
    "y": " b. têmîyanşanaye (n)"
  },
  {
    "x": "tîyemîyûnşayî (m)",
    "y": " b. têmîyanşanayîye (m)"
  },
  {
    "x": "tîyemîyûnşayîş (n)",
    "y": " b. têmîyanşanayîş (n)"
  },
  {
    "x": "tîyemîyûnşûnîyayîş (n)",
    "y": " b. têmîyanşanîyayîş (n)"
  },
  {
    "x": "tîyemîyûnvistiş (n)",
    "y": " b. têmîyanvistiş (n)"
  },
  {
    "x": "tîyep (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "tîyeri (m)",
    "y": " b. tuyêre (m)"
  },
  {
    "x": "tîyere (m)",
    "y": " b. tuyêre (m)"
  },
  {
    "x": "tîyerapîşta (m)",
    "y": " b. têrapîşta (m)"
  },
  {
    "x": "tîyerapîşte (n)",
    "y": " têrapîşte (n)"
  },
  {
    "x": "tîyetor",
    "y": " b. têvter"
  },
  {
    "x": "tîyêşt (n)",
    "y": " b. teşt (n)"
  },
  {
    "x": "tîyo geyrayîne",
    "y": " b. têwgêrayene"
  },
  {
    "x": "tîyodayene",
    "y": " b. têvdayene"
  },
  {
    "x": "tîyodayîş (n)",
    "y": " b. têvdayîş (n)"
  },
  {
    "x": "tîyogeyrayîş (n)",
    "y": " b. têwgêrayîş (n)"
  },
  {
    "x": "tîyşan, -i",
    "y": " b. têşan, -e"
  },
  {
    "x": "tîyşan bîyayene",
    "y": " b. têşan bîyene"
  },
  {
    "x": "tîyşanbîyayîş (n)",
    "y": " b. têşanbîyayîş (n)"
  },
  {
    "x": "tîyşanê (m)",
    "y": " b. têşanî (m)"
  },
  {
    "x": "tîyşûn, -i",
    "y": " b. têşan, -e"
  },
  {
    "x": "tîzba (m)",
    "y": " b. tezbîhe (m)"
  },
  {
    "x": "tîzbe (m)",
    "y": " b. tezbîhe (m)"
  },
  {
    "x": "tîzbih (m)",
    "y": " b. tezbîhe (m)"
  },
  {
    "x": "tîze (m)",
    "y": " b. tîje (m)"
  },
  {
    "x": "tîzik eştene",
    "y": " zıplayıp koşarak tekme atmak (hayvan için)"
  },
  {
    "x": "tîzin, -e",
    "y": " b. tîjin, -e"
  },
  {
    "x": "tîzî (m)",
    "y": " 1)b. tîje (m)\r\n~2)b. tîrêje (m)"
  },
  {
    "x": "to-I (m, n, z)",
    "y": " 1)sen\r\n~2)b. ti-1 (m, n, z)"
  },
  {
    "x": "to-II (m, nm)",
    "y": " b. tewe-I (m)"
  },
  {
    "x": "to degirotene",
    "y": " b. tewe degirewtene"
  },
  {
    "x": "to girotene",
    "y": " b. tewe girewtene"
  },
  {
    "x": "to-III (n)",
    "y": " kaymak"
  },
  {
    "x": "to kerdene",
    "y": " çemremek (kol, paça, etek vb. için), sıvamak (kol, paça, etek vb. için)"
  },
  {
    "x": "to vetene",
    "y": " b. tiro vetene"
  },
  {
    "x": "toa (n)",
    "y": " b. teba (n)"
  },
  {
    "x": "Togo (n)",
    "y": " Togo"
  },
  {
    "x": "tohol, -e",
    "y": " b. tal, -e-II"
  },
  {
    "x": "tohol bîyayene",
    "y": " b. tal bîyene"
  },
  {
    "x": "tohol kerdene",
    "y": " b. tal kerdene"
  },
  {
    "x": "tohole (n)",
    "y": " b. tole-I (n)"
  },
  {
    "x": "toholbîyayîş (n)",
    "y": " b. talbîyayîş (n)"
  },
  {
    "x": "toholîye (m)",
    "y": " b. talîye (m)"
  },
  {
    "x": "toholkerdiş (n)",
    "y": " b. talkerdiş (n)"
  },
  {
    "x": "toke (m)",
    "y": " budak"
  },
  {
    "x": "toker (m)",
    "y": " b. tekere (m)"
  },
  {
    "x": "tokerdiş (n)",
    "y": " çemreme (kol, paça, etek vb. için)"
  },
  {
    "x": "toksîk, -e",
    "y": " toksik"
  },
  {
    "x": "toksîn (n)",
    "y": " toksin"
  },
  {
    "x": "Tokyo (n)",
    "y": " Tokyo"
  },
  {
    "x": "tol, -e-I",
    "y": " b. tal, -e-I"
  },
  {
    "x": "tol, -e-II",
    "y": " b. tal, -e-II"
  },
  {
    "x": "tol bîyayene",
    "y": " b. tal bîyene"
  },
  {
    "x": "tol kerdene",
    "y": " b. tal kerdene"
  },
  {
    "x": "tol",
    "y": " b. tewlî"
  },
  {
    "x": "tola (n)",
    "y": " b. tuxla (m)"
  },
  {
    "x": "tolaz (n)",
    "y": " 1)çapkın, hovarda\r\n~2)genç"
  },
  {
    "x": "tolazey (m)",
    "y": " b. tolazî (m)"
  },
  {
    "x": "tolazê (m)",
    "y": " b. tolazî (m)"
  },
  {
    "x": "tolazî (m)",
    "y": " çapkınlık, hovardalık"
  },
  {
    "x": "tolbîyayîş (n)",
    "y": " b. talbîyayîş (n)"
  },
  {
    "x": "toldir (n)",
    "y": " kalçanın alt bölümü"
  },
  {
    "x": "tole-I (n)",
    "y": " 1)kabuk\r\n~2)yara kabuğu\r\n~3)kaymak"
  },
  {
    "x": "tole eştene",
    "y": " kavlamak, kabuğu kabarıp dökülmek, kabuğu kabarıp soyulmak"
  },
  {
    "x": "tole kerdene",
    "y": " kabuğunu soymak"
  },
  {
    "x": "tole ser de kerdene",
    "y": " kabuk soymak"
  },
  {
    "x": "tole sero kerdene",
    "y": " kabuk soymak"
  },
  {
    "x": "toleyê pîyanzî (n)",
    "y": " b. toleyê pîyazî (n)"
  },
  {
    "x": "toleyê pîyazî (n)",
    "y": " kuru soğan kabuğu"
  },
  {
    "x": "tole-II (m)",
    "y": " çir (kurutulmuş kayısı)"
  },
  {
    "x": "toli (m)",
    "y": " b. dole (m)"
  },
  {
    "x": "tolebisk (n)",
    "y": " b. tolebişk (n)"
  },
  {
    "x": "tolebiske (n)",
    "y": " b. tolebişk (n)"
  },
  {
    "x": "tolebişk (n)",
    "y": " gonca, konca\r\n~Tolebişkî rabilîyayê. (Goncalar açılmış.)"
  },
  {
    "x": "toleeştiş (n)",
    "y": " kavlama"
  },
  {
    "x": "toleke (m)",
    "y": " b. tolike (m)"
  },
  {
    "x": "tolekerdişê çermî (n)",
    "y": " soyum, peeling"
  },
  {
    "x": "tolenî (m)",
    "y": " b. talênî (m)"
  },
  {
    "x": "tolêbisk (n)",
    "y": " b. tolebişk (n)"
  },
  {
    "x": "tolên (m)",
    "y": " b. talênî (m)"
  },
  {
    "x": "toli (n)",
    "y": " b. tewle (n)"
  },
  {
    "x": "tolif (n)",
    "y": " yara kabuğu"
  },
  {
    "x": "tolif girey dayene",
    "y": " b. tolif girê dayene"
  },
  {
    "x": "tolif girê dayene",
    "y": " kabuk bağlamak (yara için)"
  },
  {
    "x": "tolik-I (n)",
    "y": " kabuk"
  },
  {
    "x": "tolik eştene",
    "y": " kavlamak, kabuğu kabarıp dökülmek, kabuğu kabarıp soyulmak"
  },
  {
    "x": "tolik-II (m)",
    "y": " b. tolike (m)"
  },
  {
    "x": "tolike (m)",
    "y": " ebegümeci"
  },
  {
    "x": "tolikeştiş (n)",
    "y": " kavlama"
  },
  {
    "x": "toliki (m)",
    "y": " b. tolike (m)"
  },
  {
    "x": "tolîye (m)",
    "y": " b. talîye (m)"
  },
  {
    "x": "tolkerdis (n)",
    "y": " b. talkerdiş (n)"
  },
  {
    "x": "tolkerdiş (n)",
    "y": " b. talkerdiş (n)"
  },
  {
    "x": "tollik (m)",
    "y": " b. tolike (m)"
  },
  {
    "x": "tolobiske (n)",
    "y": " b. tolebişk (n)"
  },
  {
    "x": "toltaz (n)",
    "y": " b. tolaz (n)"
  },
  {
    "x": "tom-I",
    "y": " b. tam, -e-I"
  },
  {
    "x": "tom-II (n)",
    "y": " b. tehm-I (n)"
  },
  {
    "x": "tom kerdene",
    "y": " b. tehm kerdene"
  },
  {
    "x": "tomafîl (m)",
    "y": " b. otomobîle (m)"
  },
  {
    "x": "tomare (m)",
    "y": " b. damare (m)"
  },
  {
    "x": "tomatêse (m)",
    "y": " domates"
  },
  {
    "x": "tombala (m)",
    "y": " tombala "
  },
  {
    "x": "tombalakar, -e",
    "y": " tombalacı"
  },
  {
    "x": "tombalakarî (m)",
    "y": " tombalacılık"
  },
  {
    "x": "tombalakarîye (m)",
    "y": " tombalacılık"
  },
  {
    "x": "tomkor, -e",
    "y": " b. tehmkor, -e"
  },
  {
    "x": "tomkorên (m)",
    "y": " b. tehmkorênî (m) "
  },
  {
    "x": "tomkorî (m)",
    "y": " b. tehmkorî (m)"
  },
  {
    "x": "tomkorîye (m)",
    "y": " b. tehmkorîye (m)"
  },
  {
    "x": "tomnayene",
    "y": " b. tehmnayene"
  },
  {
    "x": "tomofîl (n)",
    "y": " b. otomobîle (m)"
  },
  {
    "x": "tomore (m)",
    "y": " b. damare (m)"
  },
  {
    "x": "tomserd, -e",
    "y": " b. tehmserd, -e"
  },
  {
    "x": "tomserdîye (m)",
    "y": " b. tehmserdîye (m)"
  },
  {
    "x": "ton-I (n)",
    "y": " b. don (n)"
  },
  {
    "x": "ton-II",
    "y": " b. tay, tayê"
  },
  {
    "x": "tonaj (n)",
    "y": " tonaj "
  },
  {
    "x": "tonayîş (n)",
    "y": " b. tanayîş-I (n)"
  },
  {
    "x": "tone (m)",
    "y": " ton"
  },
  {
    "x": "toneki (m)",
    "y": " b. tûnike (m)"
  },
  {
    "x": "toner (n) (TE)",
    "y": " karartıcı, toner, toz mürekkep"
  },
  {
    "x": "tonê",
    "y": " 1)b. tenê-I\r\n~2)b. tay\r\n~3)b. tayê"
  },
  {
    "x": "Tonga (m)",
    "y": " Tonga Adaları"
  },
  {
    "x": "toni (m)",
    "y": " b. tanî-I (m)"
  },
  {
    "x": "tonî-I",
    "y": " 1)b. tenê-I\r\n~2)b. tay, -e\r\n~3)b. tayê "
  },
  {
    "x": "tonî-II (s)",
    "y": " tonluk\r\n~kaxito ditonî: iki tonluk kağıt "
  },
  {
    "x": "kaxito ditonî",
    "y": " iki tonluk kağıt "
  },
  {
    "x": "tonîk (n)",
    "y": " tonik"
  },
  {
    "x": "tonînî",
    "y": " b. tayêne"
  },
  {
    "x": "tonqe (n)",
    "y": " b. toqe (n)"
  },
  {
    "x": "tontir (n)",
    "y": " b. tantûr (n)"
  },
  {
    "x": "tontirot (n)",
    "y": " b. tenturdîyot (n)"
  },
  {
    "x": "tontur-I (n)",
    "y": " b. tantûr (n)"
  },
  {
    "x": "tontur-II (n)",
    "y": " b. tenturdîyot (n)"
  },
  {
    "x": "tonturot (n)",
    "y": " b. tenturdîyot (n)"
  },
  {
    "x": "tonzi (n)",
    "y": " b. tajî (n), tazî (n)"
  },
  {
    "x": "tonzî (m)",
    "y": " b. tajîye (m), tazîye (m)"
  },
  {
    "x": "top-I",
    "y": " bütün, hep, hepsi, tüm, topu (tümü)"
  },
  {
    "x": "top bîyene",
    "y": " toplanmak, devşirilmek"
  },
  {
    "x": "top kerdene",
    "y": " toplamak, devşirmek"
  },
  {
    "x": "top pîya",
    "y": " hep birlikte, tümü birlikte"
  },
  {
    "x": "top-II (m)",
    "y": " b. tope (m)"
  },
  {
    "x": "top-III (n)",
    "y": " b. tope (m)"
  },
  {
    "x": "topa kilîtî (m)",
    "y": " kilit (asma kilit)"
  },
  {
    "x": "topa kîlitî (m)",
    "y": " b. topa kilîtî (m)"
  },
  {
    "x": "topaciki",
    "y": " b. topaçe (m)"
  },
  {
    "x": "topaçe (m)",
    "y": " topaç"
  },
  {
    "x": "topaçi (m)",
    "y": " b. topaçe (m)"
  },
  {
    "x": "topal, -e",
    "y": " aksak, topal"
  },
  {
    "x": "topaley (m)",
    "y": " b. topalîye (m)"
  },
  {
    "x": "topalê (m)",
    "y": " b. topalîye (m)"
  },
  {
    "x": "topali (m)",
    "y": " b. topal, e"
  },
  {
    "x": "topalî (m)",
    "y": " b. topalîye (m)"
  },
  {
    "x": "topalîye (m)",
    "y": " aksaklık, topallık"
  },
  {
    "x": "topbîyayîş (n)",
    "y": " toplanma, devşirilme"
  },
  {
    "x": "topçîn (n)",
    "y": " yedi adımlık uzunluk (ölçüsü)"
  },
  {
    "x": "tope (m)",
    "y": " 1)top (ateşli silahlardan)\r\n~2)top (kumaş vb. için)\r\n~3)top (oyunda kullanılan top)\r\n~4)balyoz"
  },
  {
    "x": "topa aqilî (m)",
    "y": " akıl küpü"
  },
  {
    "x": "tope eştene",
    "y": " 1)top atmak\r\n~2)iflas etmek"
  },
  {
    "x": "tope fîştene",
    "y": " 1)top atmak\r\n~2)iflas etmek"
  },
  {
    "x": "topê aqilî (n)",
    "y": " b. topa aqilî (m)"
  },
  {
    "x": "topeeştiş (n)",
    "y": " iflas"
  },
  {
    "x": "topi (m)",
    "y": " b. tope (m)"
  },
  {
    "x": "topi vistene",
    "y": " b. tope eştene"
  },
  {
    "x": "topike (m)",
    "y": " tekne"
  },
  {
    "x": "topîç (n)",
    "y": " tokaç"
  },
  {
    "x": "topîn",
    "y": " b. topîne"
  },
  {
    "x": "topîne",
    "y": " tümü, hepsi, topu; tümünü, hepsini"
  },
  {
    "x": "topînî",
    "y": " b. topîne"
  },
  {
    "x": "topkar, -e",
    "y": " topçu (asker sınıfı)"
  },
  {
    "x": "topkarî (m)",
    "y": " topçuluk"
  },
  {
    "x": "topkarîye (m)",
    "y": " topçuluk"
  },
  {
    "x": "topkerde (n)",
    "y": " devşirme"
  },
  {
    "x": "topkerdiş (n)",
    "y": " toplama, devşirme"
  },
  {
    "x": "topkî",
    "y": " toptan"
  },
  {
    "x": "topografya",
    "y": " topografya (m)"
  },
  {
    "x": "topuna",
    "y": " b. topîne"
  },
  {
    "x": "toq-I (m)",
    "y": " b. tewqe-I (m)"
  },
  {
    "x": "toq-II (n)",
    "y": " b. tewq-I (n)"
  },
  {
    "x": "toqa (m)",
    "y": " b. toqe (n)"
  },
  {
    "x": "toqe (n)",
    "y": " toka (saç için)"
  },
  {
    "x": "toqi (m)",
    "y": " b. tewqe-I (m)"
  },
  {
    "x": "toqif (n)",
    "y": " b. tewqîf (n)"
  },
  {
    "x": "toqmaq (n)",
    "y": " tokmak, havaneli, soku tokmağı, dibek tokmağı"
  },
  {
    "x": "tor-I (n)",
    "y": " b. torre (m)"
  },
  {
    "x": "tor-II (zr)",
    "y": " b. tewr (zr)"
  },
  {
    "x": "tor teber",
    "y": " b. tewr teber"
  },
  {
    "x": "tor verî",
    "y": " b. tewr verê"
  },
  {
    "x": "tor vêr",
    "y": " b. tewr verê"
  },
  {
    "x": "tor-III",
    "y": " b. tewlî"
  },
  {
    "x": "tor bîyayîne",
    "y": " b. tewr bîyene"
  },
  {
    "x": "tor kerdene",
    "y": " b. tewr kerdene"
  },
  {
    "x": "tor ... a",
    "y": " b. tewlî ... a"
  },
  {
    "x": "torî ... ya",
    "y": " b. tewlî ... a "
  },
  {
    "x": "torî",
    "y": " b. tewlî"
  },
  {
    "x": "toraq (n)",
    "y": " çökelek\r\n~toraqo kewik (n): küflü çökelek"
  },
  {
    "x": "toraqo kewik (n)",
    "y": " küflü çökelek"
  },
  {
    "x": "toraqin, -e",
    "y": " çökelekli"
  },
  {
    "x": "toraqine (m)",
    "y": " bir çeşit gözleme"
  },
  {
    "x": "toraqîn (m)",
    "y": " b. toraqine (m)"
  },
  {
    "x": "torayene",
    "y": " b. wetardene"
  },
  {
    "x": "torayêne",
    "y": " b. wetardene"
  },
  {
    "x": "torayîne",
    "y": " b. wetardene"
  },
  {
    "x": "torayîş (n)",
    "y": " b. wetardiş (n)"
  },
  {
    "x": "torbe (n)",
    "y": " b. tewre (n)"
  },
  {
    "x": "torbîyayîş (n)",
    "y": " b. tewrbîyayîş (n)"
  },
  {
    "x": "torcen (n)",
    "y": " b. tuwerzîn (n) "
  },
  {
    "x": "torç (n)",
    "y": " b. teraş-I (n)"
  },
  {
    "x": "tore-I (n)",
    "y": " balta (büyük balta) "
  },
  {
    "x": "tore-II (n)",
    "y": " âdet, görenek, töre"
  },
  {
    "x": "tore-III",
    "y": " b. tewir (n)"
  },
  {
    "x": "na tore",
    "y": " b. no tewir"
  },
  {
    "x": "o tore",
    "y": " b. o tewir"
  },
  {
    "x": "tore-IV (n)",
    "y": " b. tewre (n)"
  },
  {
    "x": "tore-V (m)",
    "y": " b. torre (m)"
  },
  {
    "x": "toren (n)",
    "y": " b. terne (n)"
  },
  {
    "x": "toreyek (n)",
    "y": " baltacık"
  },
  {
    "x": "torge (m)",
    "y": " dolu"
  },
  {
    "x": "torgi (m)",
    "y": " b. torge (m)"
  },
  {
    "x": "tori (n)",
    "y": " b. tewre (n)"
  },
  {
    "x": "tori (m)",
    "y": " b. torre (m)"
  },
  {
    "x": "torim, -e",
    "y": " deve yavrusu"
  },
  {
    "x": "torin, -e-I",
    "y": " 1)soylu\r\n~2)babayiğit"
  },
  {
    "x": "torin, -e-II",
    "y": " b. torn, -e"
  },
  {
    "x": "torînc a ronîştene",
    "y": " b. tuncî ra roniştene"
  },
  {
    "x": "torîncaronîştiş (n)",
    "y": " b. tuncîraroniştiş (n)"
  },
  {
    "x": "torinîye (m)",
    "y": " 1)soyluluk\r\n~2)babayiğitlik"
  },
  {
    "x": "torje (n)",
    "y": " b. tuwerzîn (n) "
  },
  {
    "x": "torjen (n)",
    "y": " b. tuwerzîn (n)"
  },
  {
    "x": "torjen kerdene",
    "y": " b. tuwerzîn kerdene"
  },
  {
    "x": "torjenkerdiş (n)",
    "y": " b. tuwerzînkerdiş (n)"
  },
  {
    "x": "torjîle (n)",
    "y": " b. torzîle (n)"
  },
  {
    "x": "torkerdiş (n)",
    "y": " b. tewrkerdiş (n)"
  },
  {
    "x": "torn, -e",
    "y": " torun"
  },
  {
    "x": "torna (m)",
    "y": " torna"
  },
  {
    "x": "tornageh (n)",
    "y": " tornacı (torna işi yapılan dükkân)"
  },
  {
    "x": "tornakar, -e",
    "y": " tornacı (torna işi yapan)"
  },
  {
    "x": "tornakarî (m)",
    "y": " tornacılık"
  },
  {
    "x": "tornakarîye (m)",
    "y": " tornacılık"
  },
  {
    "x": "tornavîda (m)",
    "y": " tornavida"
  },
  {
    "x": "tornawîda (m)",
    "y": " b. tornavîda (m)"
  },
  {
    "x": "torne (m)",
    "y": " b. torna (m)"
  },
  {
    "x": "tornevîda (m)",
    "y": " b. tornavîda (m)"
  },
  {
    "x": "tornevîde (m)",
    "y": " b. tornavîda (m)"
  },
  {
    "x": "torni (m)",
    "y": " b. torne (m)"
  },
  {
    "x": "torr (m)",
    "y": " b. torre (m)"
  },
  {
    "x": "torre (m)",
    "y": " 1)ağ \r\n~2)file \r\n~3)şebeke (TE)\r\n~torra awa şilîye (m): yağmur suyu şebekesi \r\n~4)süzgeç, süzek"
  },
  {
    "x": "torra awa şilîye (m)",
    "y": " yağmur suyu şebekesi"
  },
  {
    "x": "torra awa varanî (m)",
    "y": " yağmur suyu şebekesi"
  },
  {
    "x": "torra gendawe (m)",
    "y": " atık su şebekesi"
  },
  {
    "x": "torra kanalîzasyonî (m)",
    "y": " kanalizasyon şebekesi"
  },
  {
    "x": "torra varanî (m)",
    "y": " yağmur şebekesi"
  },
  {
    "x": "torri (m)",
    "y": " b. torre (m)"
  },
  {
    "x": "torsele (n)",
    "y": " dolu"
  },
  {
    "x": "torsle (n)",
    "y": " b. torsele (n)"
  },
  {
    "x": "torta (m)",
    "y": " turta"
  },
  {
    "x": "tortor (n)",
    "y": " b. doktor, -e"
  },
  {
    "x": "torun, -e",
    "y": " b. torn, -e"
  },
  {
    "x": "torve-I (n)",
    "y": " b. tewre (n)"
  },
  {
    "x": "torve-II (m)",
    "y": " b. derbe (m)"
  },
  {
    "x": "torzên (n)",
    "y": " b. tuwerzîn (n) "
  },
  {
    "x": "torzîle (n)",
    "y": " ufak taneli dolu"
  },
  {
    "x": "torzîn (n)",
    "y": " b. tuwerzîn (n)"
  },
  {
    "x": "tose (m)",
    "y": " b. tase (m)"
  },
  {
    "x": "tosa hamamî (m)",
    "y": " b. tasa hemamî (m)"
  },
  {
    "x": "tosi (m)",
    "y": " b. tase (m)"
  },
  {
    "x": "tosike (m)",
    "y": " b. tasike (m)"
  },
  {
    "x": "tosle (n)",
    "y": " b. torsele (n)"
  },
  {
    "x": "tost (n)",
    "y": " tost (n)"
  },
  {
    "x": "toş, -i",
    "y": " b. tewş, -e"
  },
  {
    "x": "toş bîyayîne",
    "y": " b. tewş bîyene"
  },
  {
    "x": "toş kerdene",
    "y": " b. tewş kerdene"
  },
  {
    "x": "toşe (n)",
    "y": " biçim, şekil"
  },
  {
    "x": "toşe dayenevurnayene",
    "y": " dönüştürmek"
  },
  {
    "x": "toşe vurnayene",
    "y": " dönüşmek"
  },
  {
    "x": "toşedayîşvurnayîş (n)",
    "y": " dönüştürme"
  },
  {
    "x": "toşevurnayîş (n)",
    "y": " dönüşme"
  },
  {
    "x": "toşey (m)",
    "y": " b. tewşîye (m)"
  },
  {
    "x": "toşê (m)",
    "y": " b. tewşî (m)"
  },
  {
    "x": "toşkî",
    "y": " b. tewşkî"
  },
  {
    "x": "toşkûnî",
    "y": " b. tewşkanî"
  },
  {
    "x": "toşme (n)",
    "y": " b. çorşme (n)"
  },
  {
    "x": "totalîter, -e",
    "y": " totaliter"
  },
  {
    "x": "tov (n)",
    "y": " tohum"
  },
  {
    "x": "tov eştene",
    "y": " tohum serpmek, tohum ekmek (tahıl için)"
  },
  {
    "x": "tov kerdene",
    "y": " tohum serpmek, tohum ekmek (tahıl için)"
  },
  {
    "x": "tovetiş (n)",
    "y": " b. tirovetiş (n)"
  },
  {
    "x": "tow (m)",
    "y": " b. tewe (m)"
  },
  {
    "x": "tow girotene",
    "y": " b. tewe girewtene"
  },
  {
    "x": "towê sarî (m)",
    "y": " b. tewa sereyî (m)"
  },
  {
    "x": "towa (n)",
    "y": " b. teba (n)"
  },
  {
    "x": "towik (n)",
    "y": " b. tawug (n)"
  },
  {
    "x": "towike (m)",
    "y": " b. tawe-I (n)"
  },
  {
    "x": "towle (n)",
    "y": " b. tewle (n)"
  },
  {
    "x": "towo (n)",
    "y": " b. teba (n)"
  },
  {
    "x": "towq (n)",
    "y": " b. tewq-I (n)"
  },
  {
    "x": "towq (m)",
    "y": " b. tewqe-I (m)"
  },
  {
    "x": "towqe (n)",
    "y": " b. toqe (n)"
  },
  {
    "x": "towqi (m)",
    "y": " b. dewqe (m)"
  },
  {
    "x": "towqa ... şîyayene",
    "y": " b. dewqa ... şîyene"
  },
  {
    "x": "towqif (n)",
    "y": " b. tewqîf (n)"
  },
  {
    "x": "towqişîyayîş (n)",
    "y": " b. dewqeşîyayîş (n)"
  },
  {
    "x": "towr-I (zr)",
    "y": " b. tewr (zr)"
  },
  {
    "x": "towr tebe",
    "y": " b. tewr teber"
  },
  {
    "x": "towr teber",
    "y": " b. tewr teber"
  },
  {
    "x": "towr-II",
    "y": " b. tewlî ... a"
  },
  {
    "x": "towrî ... a",
    "y": " b. tewlî ... A\r\n~“Mela Temur towrî min a wo” (Vate, hûmare 18, r. 83)"
  },
  {
    "x": "towrayene",
    "y": " b. wetardene"
  },
  {
    "x": "towrayîş (n)",
    "y": " b. wetardiş (n)"
  },
  {
    "x": "towre (n)",
    "y": " b. tewre (n)"
  },
  {
    "x": "towş, -i",
    "y": " b. tewş, -e"
  },
  {
    "x": "towş bîyayîne",
    "y": " b. tewş bîyene"
  },
  {
    "x": "towş kerdene",
    "y": " b. tewş kerdene"
  },
  {
    "x": "towşey (m)",
    "y": " b. tewşîye (m)"
  },
  {
    "x": "towşê (m)",
    "y": " b. tewşî (m)"
  },
  {
    "x": "towşkî",
    "y": " b. tewşkî"
  },
  {
    "x": "towşkonî",
    "y": " b. tewşkanî"
  },
  {
    "x": "towşkûnî",
    "y": " b. tewşkanî"
  },
  {
    "x": "toxim-I (n)",
    "y": " tohum"
  },
  {
    "x": "toxim denayene",
    "y": " tohum ekmek"
  },
  {
    "x": "toxim denayêne",
    "y": " b. toxim denayene"
  },
  {
    "x": "toxim estene",
    "y": " b. toxim eştene"
  },
  {
    "x": "toxim eştene",
    "y": " tohum serpmek, tohum ekmek (tahıl için)"
  },
  {
    "x": "toxim eşteni",
    "y": " b. toxim eştene"
  },
  {
    "x": "toxim eyşteni",
    "y": " b. toxim eştene"
  },
  {
    "x": "toxim kerdene",
    "y": " tohum serpmek, tohum ekmek (tahıl için)"
  },
  {
    "x": "toxim ronayene",
    "y": " tohum ekmek"
  },
  {
    "x": "toxim vistene",
    "y": " b. toxim eştene"
  },
  {
    "x": "toxim visteni",
    "y": " b. toxim eştene"
  },
  {
    "x": "toxim-II (n)",
    "y": " b. taxim-I (n)"
  },
  {
    "x": "toximo viraşte (n)",
    "y": " b. taximo viraşte (n)"
  },
  {
    "x": "toximdayîş (n)",
    "y": " yarıcılık sisteminde taraflardan birinin tohumu vermesi/karşılaması"
  },
  {
    "x": "toximek (n)",
    "y": " meni"
  },
  {
    "x": "toxlî, -ye",
    "y": " besili, semiz"
  },
  {
    "x": "toxme (n)",
    "y": " dokuma"
  },
  {
    "x": "toxtor (n)",
    "y": " b. doktor, -e"
  },
  {
    "x": "toxum (n)",
    "y": " b. toxim-I (n)"
  },
  {
    "x": "toy",
    "y": " 1)b. tay\r\n~2)b. tayê \r\n~3)b. tenê-I"
  },
  {
    "x": "toy-toy",
    "y": " b. tayê-tayê"
  },
  {
    "x": "toyê-I",
    "y": " b. tebayê, teba"
  },
  {
    "x": "toyê-II",
    "y": " 1)b. tayê\r\n~2)b. tenê-I"
  },
  {
    "x": "toyî",
    "y": " 1)b. tayê\r\n~2)b. tenê-I"
  },
  {
    "x": "toyînî",
    "y": " b. tayêne"
  },
  {
    "x": "toyn",
    "y": " 1)b. tay, -e\r\n~2)b. tenê-I\r\n~3)b. tayê"
  },
  {
    "x": "toynan",
    "y": " b. tayêne"
  },
  {
    "x": "toynî",
    "y": " b. tayêne"
  },
  {
    "x": "toyno",
    "y": " b. tayêne"
  },
  {
    "x": "toynon",
    "y": " b. tayêne"
  },
  {
    "x": "toz û tebaxe",
    "y": " toz toprak"
  },
  {
    "x": "toz û tebaxi",
    "y": " b. toz û tebaxe"
  },
  {
    "x": "tozike (m)",
    "y": " taneleri küçücük olan kar"
  },
  {
    "x": "Trablûsê Rojawanî (n)",
    "y": " Batı Trablus"
  },
  {
    "x": "tradîsyon (n)",
    "y": " gelenek"
  },
  {
    "x": "tradîsyonel, -e",
    "y": " geleneksel"
  },
  {
    "x": "trafîk (n)",
    "y": " trafik\r\n~gemarinîya trafîkî (m): trafik kirliliği\r\n~qezayê trafîkî (n): trafik kazası"
  },
  {
    "x": "trafîkîramen (n)",
    "y": " trafikten men"
  },
  {
    "x": "trafîkîramenkerdiş (n)",
    "y": " trafikten menetme, trafikten men"
  },
  {
    "x": "trafo (n)",
    "y": " trafo"
  },
  {
    "x": "trahom (n)",
    "y": " trahom"
  },
  {
    "x": "trajedî (m)",
    "y": " trajedi, ağlatı"
  },
  {
    "x": "traktor (n)",
    "y": " b. traktore (m)"
  },
  {
    "x": "traktore (m)",
    "y": " traktör"
  },
  {
    "x": "traktora bi gogeranê lastikênan (m)",
    "y": " lastik tekerlekli traktör"
  },
  {
    "x": "traktora bi tekeranê lastikênan (m)",
    "y": " lastik tekerlekli traktör"
  },
  {
    "x": "traktora tekerlastikêne (m)",
    "y": " lastik tekerlekli traktör"
  },
  {
    "x": "trampe (n)",
    "y": " b. terampe (n)"
  },
  {
    "x": "tramwaye (m)",
    "y": " tramvay"
  },
  {
    "x": "trane (m)",
    "y": " b. trêne (m)"
  },
  {
    "x": "transfer (n)",
    "y": " aktarma, transfer"
  },
  {
    "x": "transfer kerdene",
    "y": " aktarmak, transfer etmek"
  },
  {
    "x": "transferê nufusî (n)",
    "y": " nüfus transferi"
  },
  {
    "x": "transferkerdiş (n)",
    "y": " aktarma, transfer etme"
  },
  {
    "x": "transformasyon (n)",
    "y": " dönüşüm"
  },
  {
    "x": "transîtîf",
    "y": " geçişli"
  },
  {
    "x": "traqtore (m)",
    "y": " b. traktore (m)"
  },
  {
    "x": "traqtori (m)",
    "y": " b. traktore (m)"
  },
  {
    "x": "trawma (m)",
    "y": " travma"
  },
  {
    "x": "tren (m)",
    "y": " b. trêne (m)"
  },
  {
    "x": "trene (m)",
    "y": " b. trêne (m)"
  },
  {
    "x": "trêne (m)",
    "y": " tren"
  },
  {
    "x": "trêna banlîyoyî (m)",
    "y": " banliyö treni"
  },
  {
    "x": "trêni (m)",
    "y": " b. trêne (m)"
  },
  {
    "x": "trîgonometrî (n)",
    "y": " trigonometri"
  },
  {
    "x": "trîlogya (m)",
    "y": " üçleme"
  },
  {
    "x": "trîlojî (n)",
    "y": " üçleme"
  },
  {
    "x": "trîni (m)",
    "y": " b. trêne (m)"
  },
  {
    "x": "trîtmint (n)",
    "y": " geliştirim senaryosu"
  },
  {
    "x": "tu-I (m, n, z)",
    "y": " 1)b. to (m, n, z)\r\n~2)b. ti (m, n, z)"
  },
  {
    "x": "tu-II",
    "y": " hiç, hiçbir, tek\r\n~No tu aqil nîyo. (Bu hiç akıl/akıllıca değil.)"
  },
  {
    "x": "tu derbe",
    "y": " asla, hiçbir zaman; hiç, katiyen"
  },
  {
    "x": "tu derbi",
    "y": " b. tu derbe"
  },
  {
    "x": "tu kes",
    "y": " hiç kimse"
  },
  {
    "x": "tu-III (n)",
    "y": " tükürük"
  },
  {
    "x": "tu kerdene",
    "y": " tükürmek"
  },
  {
    "x": "tu ... sawitene",
    "y": " tükürüklemek"
  },
  {
    "x": "tu ... sawitiş",
    "y": " tükürükleme"
  },
  {
    "x": "tua (n)",
    "y": " b. teba (n)"
  },
  {
    "x": "tual-I (m)",
    "y": " b. tole-II (m)"
  },
  {
    "x": "tual-II (n)",
    "y": " b. tole-I (n)"
  },
  {
    "x": "tual eştene",
    "y": " b. tole eştene "
  },
  {
    "x": "tual kerdene",
    "y": " b. tole kerdene"
  },
  {
    "x": "tual sero kerdene",
    "y": " b. tole sero kerdene"
  },
  {
    "x": "tualaz (n)",
    "y": " b. tolaz (n)"
  },
  {
    "x": "tualazê (m)",
    "y": " b. tolazî (m)"
  },
  {
    "x": "tuale-I (m)",
    "y": " b. tole-II (m)"
  },
  {
    "x": "tuale-II (n)",
    "y": " b. tole-I (n)"
  },
  {
    "x": "tuale eştene",
    "y": " b. tole eştene "
  },
  {
    "x": "tuale kerdene",
    "y": " b. tole kerdene"
  },
  {
    "x": "tuale sero kerdene",
    "y": " b. tole sero kerdene"
  },
  {
    "x": "tualey pîyûnzî (m)",
    "y": " b. toleyê pîyazî (n)"
  },
  {
    "x": "tualeeştiş (n)",
    "y": " b. toleeştiş (n)"
  },
  {
    "x": "tualeştiş (n)",
    "y": " b. toleeştiş (n)"
  },
  {
    "x": "tuali (n)",
    "y": " b. tole-I (n)"
  },
  {
    "x": "tuali eştene",
    "y": " b. tole eştene "
  },
  {
    "x": "tuali kerdene",
    "y": " b. tole kerdene"
  },
  {
    "x": "tuali sero kerdene",
    "y": " b. tole sero kerdene"
  },
  {
    "x": "tualieştiş (n)",
    "y": " b. toleeştiş (n)"
  },
  {
    "x": "tuap (m)",
    "y": " b. tope (m)"
  },
  {
    "x": "tuapaç",
    "y": " b. topaçe (m)"
  },
  {
    "x": "tuarbe (n)",
    "y": " b. tewre (n)"
  },
  {
    "x": "tube (m)",
    "y": " tüp"
  },
  {
    "x": "tuba hewnakerdişê şewatî (m)",
    "y": " yangın söndürme tüpü"
  },
  {
    "x": "tuba hewnakerdişê şewatî (ya CO2)",
    "y": " yangın söndürme tüpü (CO2) "
  },
  {
    "x": "tuba hewnakerdişê şewatî ya kefine (m)",
    "y": " köpüklü yangın söndürme tüpü"
  },
  {
    "x": "tuba oksîjene (m)",
    "y": " oksijen tüpü"
  },
  {
    "x": "tuba şewatî (m)",
    "y": " yangın tüpü"
  },
  {
    "x": "tuba weşaynayîşê şewatî (m)",
    "y": " yangın söndürme tüpü"
  },
  {
    "x": "tuba weşaynayîşê şewatî (ya CO2)",
    "y": " yangın söndürme tüpü (CO2)"
  },
  {
    "x": "tuba weşaynayîşê şewatî ya kefine (m)",
    "y": " köpüklü yangın söndürme tüpü"
  },
  {
    "x": "tuberkuloz (n)",
    "y": " verem, tüberküloz"
  },
  {
    "x": "tubr tubr",
    "y": " gelmeleri için büyükbaş ve küçükbaş hayvanlara seslenirken kullanılan sözcükler (buzağı, koyun, kuzu ve koç için)"
  },
  {
    "x": "tubrşt tubrşt",
    "y": " b. tubr tubr"
  },
  {
    "x": "tucar, -e",
    "y": " b. têcîr, -e"
  },
  {
    "x": "tucarê (m)",
    "y": " b. têcîrî (m)"
  },
  {
    "x": "tucarî (m)",
    "y": " b. têcîrî (m)"
  },
  {
    "x": "tue (m, n, z)",
    "y": " b. to (m, n, z)"
  },
  {
    "x": "tuelaz (n)",
    "y": " b. tolaz (n)"
  },
  {
    "x": "tuelazê (m)",
    "y": " b. tolazî (m)"
  },
  {
    "x": "tuep (m)",
    "y": " b. tope (m)"
  },
  {
    "x": "tuer (m)",
    "y": " b. torre (m)"
  },
  {
    "x": "tueraq (n)",
    "y": " b. toraq (n)"
  },
  {
    "x": "tuerbe (n)",
    "y": " b. tewre (n)"
  },
  {
    "x": "tuerbı (n)",
    "y": " b. tewre (n)"
  },
  {
    "x": "tuerg (m)",
    "y": " b. torge (m)"
  },
  {
    "x": "tuern (n)",
    "y": " b. torn"
  },
  {
    "x": "tuerr (m)",
    "y": " b. torre (m)"
  },
  {
    "x": "tuersele (n)",
    "y": " b. torsele (n)"
  },
  {
    "x": "tuerzîn (n)",
    "y": " b. tuwerzîn (n)"
  },
  {
    "x": "tuexim (n)",
    "y": " b. toxim-I (n)"
  },
  {
    "x": "tuexim eştene",
    "y": " b. toxim eştene"
  },
  {
    "x": "tuey (n)",
    "y": " b. teba (n)"
  },
  {
    "x": "tuf (n)",
    "y": " b. tu-III (n)"
  },
  {
    "x": "tuf kerdene",
    "y": " b. tu kerdene"
  },
  {
    "x": "tuf ... sawitene",
    "y": " b. tu ... sawitene"
  },
  {
    "x": "tuf ... sawitiş",
    "y": " b. tu ... sawitiş"
  },
  {
    "x": "tufang (n)",
    "y": " b. tifing (n)"
  },
  {
    "x": "tufing (n)",
    "y": " b. tifing (n)"
  },
  {
    "x": "tufkerdiş (n)",
    "y": " b. tukerdiş (n)"
  },
  {
    "x": "tufong (n)",
    "y": " b. tifing (n)"
  },
  {
    "x": "tuhafîyeroş, -e",
    "y": " tuhafiyeci"
  },
  {
    "x": "tui (m, n, z)",
    "y": " b. to-1 (m, n, z)"
  },
  {
    "x": "tuî (m)",
    "y": " b. tuye (m)"
  },
  {
    "x": "tuîy (m)",
    "y": " b. tuye (m)"
  },
  {
    "x": "tuj, -e-I",
    "y": " 1)acı\r\n~2)keskin, sivri"
  },
  {
    "x": "tuj bîyene",
    "y": " keskinleşmek, sivrilmek"
  },
  {
    "x": "tuj kerdene",
    "y": " keskinleştirmek, keskinletmek, sivriltmek"
  },
  {
    "x": "tuj-II (n)",
    "y": " b. tunc (n)"
  },
  {
    "x": "tujar, -e",
    "y": " b. têcîr, -e"
  },
  {
    "x": "tujarê (m)",
    "y": " b. têcîrî (m)"
  },
  {
    "x": "tujbîyayîş (n)",
    "y": " keskinleşme, sivrilme"
  },
  {
    "x": "tujek, -e",
    "y": " acımsı, acımtırak"
  },
  {
    "x": "tujenî (m)",
    "y": " b. tujênî (m)"
  },
  {
    "x": "tujey (m)",
    "y": " b. tujîye (m), tujî (m)"
  },
  {
    "x": "tujê (m)",
    "y": " b. tujîye (m), tujî (m)"
  },
  {
    "x": "tujênî (m)",
    "y": " 1)keskinlik\r\n~2)acılık"
  },
  {
    "x": "tuji (m)",
    "y": " b. tuje (m)"
  },
  {
    "x": "tujikê çarixî (n)",
    "y": " çarık burnu"
  },
  {
    "x": "tujikê solî (n)",
    "y": " ayakkabı burnu"
  },
  {
    "x": "tujin, -e",
    "y": " acılı"
  },
  {
    "x": "tujî (m)",
    "y": " 1)keskinlik\r\n~2)acılık"
  },
  {
    "x": "tujîye (m)",
    "y": " 1)keskinlik\r\n~2)acılık"
  },
  {
    "x": "tujkerdiş (n)",
    "y": " keskinleştirme, sivriltme"
  },
  {
    "x": "tuk (n)",
    "y": " apış arası kılları, etek kılları"
  },
  {
    "x": "tuk-tena",
    "y": " b. tek-tena"
  },
  {
    "x": "tukan (n)",
    "y": " b. dikan (n)"
  },
  {
    "x": "tukerdiş (n)",
    "y": " tükürme"
  },
  {
    "x": "tukûtena",
    "y": " b. tekûtena"
  },
  {
    "x": "tukûtenya",
    "y": " b. tekûtena"
  },
  {
    "x": "tul (n)",
    "y": " tül"
  },
  {
    "x": "tule (n)",
    "y": " b. tuxla (m)"
  },
  {
    "x": "tuleg (n)",
    "y": " biçim, şekil"
  },
  {
    "x": "tulimpa (m)",
    "y": " b. tulumba"
  },
  {
    "x": "tuliz, -e",
    "y": " seçkin insan, yiğit"
  },
  {
    "x": "tulizê kabeyî (n)",
    "y": "aşık kemiğinin ön veya arka tarafı"
  },
  {
    "x": "tulizê kabî (n)",
    "y": " b. tulizê kabeyî (n)"
  },
  {
    "x": "tulpê hewrî (n)",
    "y": " b. tilpê hewrî (n)"
  },
  {
    "x": "tulpê horî (n)",
    "y": " b. tilpê hewrî (n)"
  },
  {
    "x": "tulpizike (m)",
    "y": " kabarcık (tıpta)"
  },
  {
    "x": "tulpizîyayene",
    "y": " kabarcık oluşmak (tıpta), şıklak olmak"
  },
  {
    "x": "tulpiznayene",
    "y": " kabarcık oluşturmak (tıpta)"
  },
  {
    "x": "tulumba (m)",
    "y": " tulumba"
  },
  {
    "x": "tulumbakar, -e",
    "y": " tulumbacı"
  },
  {
    "x": "tulume (m)",
    "y": " tulum (göğüs ve pantolon bölümü bitişik giysi)"
  },
  {
    "x": "tulumpa (m)",
    "y": " b. tulumba"
  },
  {
    "x": "tulxe (n)",
    "y": " b. tuxla (m)"
  },
  {
    "x": "tum-I (n)",
    "y": " tepe"
  },
  {
    "x": "tum-II",
    "y": " b. tim "
  },
  {
    "x": "tumangewreyî (zh)",
    "y": " külotlu çorap"
  },
  {
    "x": "tumanî (zh)",
    "y": " don (uzun don); külot, kısa don"
  },
  {
    "x": "tumanê kilmî (zh)",
    "y": " külot, kısa don"
  },
  {
    "x": "tumanê kirrî (zh)",
    "y": " külot, kısa don"
  },
  {
    "x": "tumanê qolî (zh)",
    "y": " külot, kısa don"
  },
  {
    "x": "tumayn (zh)",
    "y": " b. tumanî (zh)"
  },
  {
    "x": "tume (m)",
    "y": " ot ve otsu bitkilerin bir tanesi"
  },
  {
    "x": "tumi (m)",
    "y": " b. tume (m)"
  },
  {
    "x": "tumik (n)",
    "y": " tepecik"
  },
  {
    "x": "tumo",
    "y": " b. tim "
  },
  {
    "x": "tumonî (zh)",
    "y": " b. tumanî (zh)"
  },
  {
    "x": "tumonê kilmî (zh)",
    "y": " b. tumanê kilmî (zh)"
  },
  {
    "x": "tumonê qolî (zh)",
    "y": " b. tumanê qolî (zh)"
  },
  {
    "x": "tumor (n)",
    "y": " ur, tümör "
  },
  {
    "x": "tumû",
    "y": " b. tim "
  },
  {
    "x": "tumûn (zh)",
    "y": " b. tumanî (zh)"
  },
  {
    "x": "tumûn kîlm (zh)",
    "y": " b. tumanê kilmî (zh)"
  },
  {
    "x": "tumûnî kilmî (zh)",
    "y": " b. tumanê kilmî (zh)"
  },
  {
    "x": "tun, -e",
    "y": " acı"
  },
  {
    "x": "tunc (n)",
    "y": " bronz, tunç"
  },
  {
    "x": "tuncî dayene",
    "y": " çömelmek, çökmek"
  },
  {
    "x": "tuncî ra roniştene",
    "y": " çömelmek, çökmek"
  },
  {
    "x": "tuncîdayîş (n)",
    "y": " çömelme, çömeliş, çökme"
  },
  {
    "x": "tuncîraroniştiş (n)",
    "y": " çömelme, çömeliş, çökme"
  },
  {
    "x": "tunç (n)",
    "y": " b. tunc (n)"
  },
  {
    "x": "tunedî, -ye",
    "y": " sonradan görme"
  },
  {
    "x": "tunenedî, -ye",
    "y": " b. tunedî, -ye"
  },
  {
    "x": "tunel (m)",
    "y": " b. tunele (m)"
  },
  {
    "x": "tunele (m)",
    "y": " tünel"
  },
  {
    "x": "tuney (m)",
    "y": " b. tunî (m), tunîye (m)"
  },
  {
    "x": "tuni (m)",
    "y": " b. tune (m)"
  },
  {
    "x": "tuniki (m)",
    "y": " b. tûnike (m)"
  },
  {
    "x": "tunike (m)",
    "y": " b. tûnike (m)"
  },
  {
    "x": "tuniki (m)",
    "y": " b. tûnike (m)"
  },
  {
    "x": "tunin, -e",
    "y": " acılı"
  },
  {
    "x": "tunî (m)",
    "y": " acılık"
  },
  {
    "x": "tunîye (m)",
    "y": " acılık"
  },
  {
    "x": "tup (n)",
    "y": " b. tube (m)"
  },
  {
    "x": "tupe (m)",
    "y": " b. tube (m)"
  },
  {
    "x": "tur (n)",
    "y": " b. tewir (n)"
  },
  {
    "x": "o tur",
    "y": " b. o tewir"
  },
  {
    "x": "turaq (n)",
    "y": " b. toraq (n)"
  },
  {
    "x": "turbane (m)",
    "y": " türban"
  },
  {
    "x": "ture-I (m)",
    "y": " b. tûre (m)"
  },
  {
    "x": "tura meybe (m)",
    "y": " b. tûra mêwe (m)"
  },
  {
    "x": "tura meybi (m)",
    "y": " b. tûra mêwe (m)"
  },
  {
    "x": "turê meyvi (m)",
    "y": " b. tûra mêwe (m)"
  },
  {
    "x": "ture rînayene",
    "y": " b. tiro rînayene"
  },
  {
    "x": "turerînayîş (n)",
    "y": " b. tirorînayîş (n)"
  },
  {
    "x": "turi (m)",
    "y": " b. tûre (m)"
  },
  {
    "x": "turi cenayene",
    "y": " b. tiro cenayene"
  },
  {
    "x": "turi kerdene",
    "y": " b. tiro kerdene"
  },
  {
    "x": "turicenayîş (n)",
    "y": " b. tirocenayîş (n)"
  },
  {
    "x": "turik (n)",
    "y": " torba\r\n~turikê cendegî (n): ceset torbası"
  },
  {
    "x": "turikê cendegî (n)",
    "y": " ceset torbası"
  },
  {
    "x": "turikê çopî (n)",
    "y": " çöp torbası"
  },
  {
    "x": "turikerdiş (n)",
    "y": " b. tirokerdiş (n)"
  },
  {
    "x": "turî turî",
    "y": " b. tubr tubr"
  },
  {
    "x": "turîzm (n)",
    "y": " turizm"
  },
  {
    "x": "turnavîda (m)",
    "y": " b. tornavîda (m)"
  },
  {
    "x": "turr (n)",
    "y": " b. tirr (n)"
  },
  {
    "x": "turşi (n)",
    "y": " b. tirşî (m)"
  },
  {
    "x": "turşi degerotene",
    "y": " b. tirşî degirewtene"
  },
  {
    "x": "turşî (n)",
    "y": " b. tirşî (m)"
  },
  {
    "x": "turşu (n)",
    "y": " b. tirşî (m)"
  },
  {
    "x": "turturik (n)",
    "y": " anüs çıkıntısı"
  },
  {
    "x": "turva (m)",
    "y": " b. tirbe (m) "
  },
  {
    "x": "turve (n)",
    "y": " b. tirbe (m)"
  },
  {
    "x": "turye kerdene",
    "y": " 1)b. tiro kerdene"
  },
  {
    "x": "turye şayene",
    "y": " b. tiro şîyayene"
  },
  {
    "x": "turye war kerdene",
    "y": " b. tiro war kerdene"
  },
  {
    "x": "tusk, -e",
    "y": " b. tuşk, -e"
  },
  {
    "x": "tuşk, -e",
    "y": " bir-iki yaşındaki keçi yavrusu, çebiç, çepiç"
  },
  {
    "x": "tuşkek (n)",
    "y": " kocakarı soğuğu, berdel acuz (Rumi takvime göre 28 şubatı 1 marta bağlayan gece)"
  },
  {
    "x": "tuşkı (m)",
    "y": " b. tuşke (m)"
  },
  {
    "x": "tut, -e",
    "y": " çocuk"
  },
  {
    "x": "tut bîyene",
    "y": " çocuklaşmak"
  },
  {
    "x": "tut ver şîyayene",
    "y": " b. tut ver şîyene"
  },
  {
    "x": "tut ver şîyene",
    "y": " düşük yapmak"
  },
  {
    "x": "tutbîyayîş (n)",
    "y": " çocuklaşma"
  },
  {
    "x": "tutek, -e",
    "y": " b. tut, -e"
  },
  {
    "x": "tutin (n)",
    "y": " tütün"
  },
  {
    "x": "tutmac (n)",
    "y": " tutmaç"
  },
  {
    "x": "tutmaj (n)",
    "y": " b. tutmac (n)"
  },
  {
    "x": "tutun (n)",
    "y": " b. tutin (n)"
  },
  {
    "x": "tutûn (n)",
    "y": " b. tutin (n)"
  },
  {
    "x": "tuvalet (n)",
    "y": " b. tuwalet (n)"
  },
  {
    "x": "Tuvalu (n)",
    "y": " Tuvalu"
  },
  {
    "x": "tuwa (n)",
    "y": " b. teba (n)"
  },
  {
    "x": "tuwalet (n)",
    "y": " yüznumara, ayakyolu, abdesthane, apteshane, hela, kademhane, kenef, memişhane, tuvalet\r\n~tuwaleto adetî (n): alaturka tuvalet\r\n~tuwaleto alafranga (n): alafranga tuvalet"
  },
  {
    "x": "tuwaleto adetî (n)",
    "y": " alaturka tuvalet"
  },
  {
    "x": "tuwaleto alafranga (n)",
    "y": " alafranga tuvalet"
  },
  {
    "x": "tuwanek (m)",
    "y": " b. tûnike (m)"
  },
  {
    "x": "tuweraq (n)",
    "y": " b. toraq (n)"
  },
  {
    "x": "tuwerge (m)",
    "y": " b. torge (m)"
  },
  {
    "x": "tuweri (m)",
    "y": " b. tuyêre (m)"
  },
  {
    "x": "tuwersele (n)",
    "y": " b. torsele (n)"
  },
  {
    "x": "tuwerzi (n)",
    "y": " b. tuwerzîn (n)"
  },
  {
    "x": "tuwerzin (n)",
    "y": " b. tuwerzîn (n)"
  },
  {
    "x": "tuwerzî (n)",
    "y": " b. tuwerzîn (n)"
  },
  {
    "x": "tuwerzîn (n)",
    "y": " 1)balta (büyük balta)\r\n~2)kazma"
  },
  {
    "x": "tuwerzîn kerdene",
    "y": " baltalamak"
  },
  {
    "x": "tuwerzînek (n)",
    "y": " baltacık"
  },
  {
    "x": "tuwerzînkerdiş (n)",
    "y": " baltalama"
  },
  {
    "x": "tuwerzîyen (n)",
    "y": " b. tuwerzîn (n) "
  },
  {
    "x": "tuwerzyen (n)",
    "y": " b. tuwerzîn (n) "
  },
  {
    "x": "tuwexim (n)",
    "y": " b. toxim-I (n)"
  },
  {
    "x": "tuwe",
    "y": " b. teba"
  },
  {
    "x": "tuwey",
    "y": " b. tebayê"
  },
  {
    "x": "tuxla (m)",
    "y": " tuğla"
  },
  {
    "x": "tuxle (n)",
    "y": " b. tuxla (m)"
  },
  {
    "x": "tuxt (n)",
    "y": " b. tixte (m)"
  },
  {
    "x": "tuxum (n)",
    "y": " b. toxim-I (n)"
  },
  {
    "x": "tuy (m, nm)",
    "y": " b. tuye (m, nm)"
  },
  {
    "x": "tuya tirşi (m)",
    "y": " b. tuya tirşe (m)"
  },
  {
    "x": "tuy tuy",
    "y": " b. tû tû"
  },
  {
    "x": "tuye (m, nm)",
    "y": " dut"
  },
  {
    "x": "tuya tirşe (m)",
    "y": " ekşi dut"
  },
  {
    "x": "tuyer (m)",
    "y": " b. tuyêre (m)"
  },
  {
    "x": "tuyeri (m)",
    "y": " b. tuyêre (m)"
  },
  {
    "x": "tuyêre (m)",
    "y": " dut ağacı"
  },
  {
    "x": "tuyêri (m)",
    "y": " b. tuyêre (m)"
  },
  {
    "x": "tuyi (m, nm)",
    "y": " b. tuye (m, nm)"
  },
  {
    "x": "tuz, -e",
    "y": " b. tuj, -e"
  },
  {
    "x": "tuz bîyene",
    "y": " b. tuj bîyene"
  },
  {
    "x": "tuz kerdene",
    "y": " b. tuj bîyene"
  },
  {
    "x": "tuzba (m)",
    "y": " b. tezbîhe (m)"
  },
  {
    "x": "tuzbe (m)",
    "y": " b. tezbîhe (m)"
  },
  {
    "x": "tuzên (m)",
    "y": " b. tujênî (m)"
  },
  {
    "x": "tuzênî (m)",
    "y": " b. tujênî (m)"
  },
  {
    "x": "tuzî (m)",
    "y": " b. tujîye (m), tujî (m)"
  },
  {
    "x": "tuzîye (m)",
    "y": " b. tujîye (m), tujî (m)"
  },
  {
    "x": "tuzmurik (n)",
    "y": " kuyruksokumu"
  },
  {
    "x": "tû-I (z)",
    "y": " 1)b. to (z)\r\n~2)b. ti (z)"
  },
  {
    "x": "tû-II (m, nm)",
    "y": " b. tuye (m, nm)"
  },
  {
    "x": "tû-III (n, nm)",
    "y": " b. tu-III (n)"
  },
  {
    "x": "tû kerdene",
    "y": " b. tu kerdene"
  },
  {
    "x": "tû ... sawitene",
    "y": " b. tu ... sawitene"
  },
  {
    "x": "tû ... sawitiş",
    "y": " b. tu ... sawitiş"
  },
  {
    "x": "tû tû",
    "y": " geh geh (tavuk çağırma ünlemi)  "
  },
  {
    "x": "tûban (zh)",
    "y": " b. tûmanî (zh)"
  },
  {
    "x": "tûj, -e",
    "y": " b. tuj, -e"
  },
  {
    "x": "tûj bîyayene",
    "y": " b. tuj bîyene"
  },
  {
    "x": "tûj kerdene",
    "y": " b. tuj kerdene"
  },
  {
    "x": "tûjbîyayîş (n)",
    "y": " b. tujbîyayîş (n)"
  },
  {
    "x": "tûjikê çarixî",
    "y": " b. tujikê çarixî (n)"
  },
  {
    "x": "tûjikê solî (n)",
    "y": " b. tujikê solî (n)"
  },
  {
    "x": "tûjî (m)",
    "y": " b. tujîye (m), tujî (m)"
  },
  {
    "x": "tûjkerdiş (n)",
    "y": " b. tujkerdiş (n)"
  },
  {
    "x": "tûkerdiş (n)",
    "y": " b. tukerdiş (n)"
  },
  {
    "x": "tûm-I (n)",
    "y": " b. tehm-I (n)"
  },
  {
    "x": "tûm kerdene",
    "y": " b. tehm kerdene"
  },
  {
    "x": "tûm-II (n)",
    "y": " b. tum-I (n)"
  },
  {
    "x": "tûman (zh)",
    "y": " b. tumanî (zh)"
  },
  {
    "x": "tûmanî (zh)",
    "y": " b. tumanî (zh)"
  },
  {
    "x": "tûme (m)",
    "y": " b. tume (m)"
  },
  {
    "x": "tûmnayîne",
    "y": " b. tehmnayene"
  },
  {
    "x": "tûmûn (zh)",
    "y": " b. tumanî (zh)"
  },
  {
    "x": "tûn-I",
    "y": " 1)b. tenê-I\r\n~2)b. tay\r\n~3)b. tayê"
  },
  {
    "x": "tûn, -i-II",
    "y": " b. tun, -e"
  },
  {
    "x": "tûneki (m)",
    "y": " b. tûnike (m)"
  },
  {
    "x": "tûney (m)",
    "y": " b. tunî (m), tunîye (m)"
  },
  {
    "x": "tûnê",
    "y": " b. tayê"
  },
  {
    "x": "tûni (m)",
    "y": " b. tanî-I (m)"
  },
  {
    "x": "tûnike (m)",
    "y": " cep"
  },
  {
    "x": "tûniki (m)",
    "y": " b. tûnike (m)"
  },
  {
    "x": "Tûnis (n)",
    "y": " Tunus"
  },
  {
    "x": "tûnî-I",
    "y": " 1)b. tenê-I\r\n~2)b. tay\r\n~3)b. tayê"
  },
  {
    "x": "tûnî-II (m)",
    "y": " b. tunî (m), tunîye (m)"
  },
  {
    "x": "tûnînî",
    "y": " b. tayêne"
  },
  {
    "x": "tûnzi (n)",
    "y": " b. tajî (n), tazî (n)"
  },
  {
    "x": "tûnzî (m)",
    "y": " b. tajîye (m), tazîye (m)"
  },
  {
    "x": "tûraq (n)",
    "y": " b. toraq (n)"
  },
  {
    "x": "tûre (m)",
    "y": " 1)ince ağaç dalı, sepet örmekte kullanılan ince ağaç dalı\r\n~2)biçilmiş ekin demetini bağlamakta kullanılan ve otsu bitki veya ince ağaç dalından yapılmış olan bağ"
  },
  {
    "x": "tûra meybi (m)",
    "y": " b. tûra mêwe (m)"
  },
  {
    "x": "tûra mêwe (m)",
    "y": " tevek (asma teveği)"
  },
  {
    "x": "turê meyvi (m)",
    "y": " b. tûra mêwe (m)"
  },
  {
    "x": "tûrik (n)",
    "y": " b. turik (n) "
  },
  {
    "x": "tûrî kerdene",
    "y": " b. durî kerdene"
  },
  {
    "x": "tûrn, -i",
    "y": " b. torn, -e"
  },
  {
    "x": "tûrp (m)",
    "y": " b. tirewe (m)"
  },
  {
    "x": "tûrsele (n)",
    "y": " b. torsele (n)"
  },
  {
    "x": "tûrzîn (n)",
    "y": " b. tuwerzîn (n)"
  },
  {
    "x": "tûşk, -e",
    "y": " b. tuşk, -e"
  },
  {
    "x": "tût, -e",
    "y": " b. tut, -e"
  },
  {
    "x": "tûti (m)",
    "y": " b. tute (m)"
  },
  {
    "x": "tûtî, -ye",
    "y": " papağan"
  },
  {
    "x": "tûtîya terraqe (m)",
    "y": " muhabbet kuşu"
  },
  {
    "x": "tûtîya xosebare (m)",
    "y": " b. tûtîya xoşebere (m)"
  },
  {
    "x": "tûtîya xoşebere (m)",
    "y": " muhabbet kuşu "
  },
  {
    "x": "Tûx (n)",
    "y": " Tatvan"
  },
  {
    "x": "tûxim (n)",
    "y": " b. toxim-I (n)"
  },
  {
    "x": "tûxla (m)",
    "y": " b. tuxla (m)"
  },
  {
    "x": "tûy-I",
    "y": " 1)b. tenê-I\r\n~2)b. tay\r\n~3)b. tayê"
  },
  {
    "x": "tûy-II (m, nm)",
    "y": " b. tuye (m, nm)"
  },
  {
    "x": "tûy tûy",
    "y": " b. tû tû"
  },
  {
    "x": "tûye (m, nm)",
    "y": " b. tuye (m, nm)"
  },
  {
    "x": "tûyere (m)",
    "y": " b. tuyêre (m)"
  },
  {
    "x": "tûyî",
    "y": " b. tayê"
  },
  {
    "x": "tûyînî",
    "y": " b. tayêne"
  },
  {
    "x": "tûyn",
    "y": " 1)b. tenê-I\r\n~2)b. tay\r\n~3)b. tayê"
  },
  {
    "x": "tûz, -e",
    "y": " b. tuj, -e"
  },
  {
    "x": "tyodayîş (n)",
    "y": " b. têvdayîş (n)"
  },
  {
    "x": "UUU"
  },
  {
    "x": "u-I (n, z)",
    "y": " b. o-I (n, z)"
  },
  {
    "x": "u-II (n, s)",
    "y": " b. o-II (n, s)"
  },
  {
    "x": "uca (n)",
    "y": " ora, orası, orada\r\n~A uca ya. (O oradadır.)\r\n~\t\r\n~O uca yo. (O oradadır.)"
  },
  {
    "x": "ucdon (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "ucdûn (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "ucret (n)",
    "y": " ücret"
  },
  {
    "x": "ucretê dersa îlaweyî (n)",
    "y": " ek ders ücreti"
  },
  {
    "x": "ucreto bingeyên (n)",
    "y": " taban ücret"
  },
  {
    "x": "ucreto carî (n)",
    "y": " cari ücret"
  },
  {
    "x": "ucreto esasî (n)",
    "y": " taban ücret"
  },
  {
    "x": "ucreto rojane (n)",
    "y": " gündelik, yevmiye"
  },
  {
    "x": "ucreto tewr tay (n)",
    "y": " asgari ücret"
  },
  {
    "x": "ucreto tewr zaf (n)",
    "y": " tavan ücret"
  },
  {
    "x": "ucreto vîyartbar (n)",
    "y": " cari ücret"
  },
  {
    "x": "ucud (n)",
    "y": " b. wucud (n)"
  },
  {
    "x": "uçka (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "ufqî, -ye",
    "y": " yatay"
  },
  {
    "x": "ufûq (n)",
    "y": " ufuk"
  },
  {
    "x": "uja (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "ujdan (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "ujdîya (n)",
    "y": " b. ejdeha (n)"
  },
  {
    "x": "ujdon (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "ujdûn (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "ukêrî (m)",
    "y": " b. yuke (m)"
  },
  {
    "x": "Ukrayna (m)",
    "y": " Ukrayna"
  },
  {
    "x": "ulahî",
    "y": " b. willayî"
  },
  {
    "x": "ulayî",
    "y": " b. willayî"
  },
  {
    "x": "ule",
    "y": " b. willayî"
  },
  {
    "x": "ulidit (n)",
    "y": " b. hurhurit (n)"
  },
  {
    "x": "ullayî",
    "y": " b. willayî"
  },
  {
    "x": "ulle",
    "y": " b. willayî"
  },
  {
    "x": "ulser (n)",
    "y": " ülser"
  },
  {
    "x": "ultrasonîk (n) (nm)",
    "y": " ultrasonik "
  },
  {
    "x": "ultrasonîk, -e (s)",
    "y": " ultrasonik"
  },
  {
    "x": "ulûr (n)",
    "y": " b. helasorî, -ye"
  },
  {
    "x": "ulûrî (m)",
    "y": " b. helasorîyîye (m)"
  },
  {
    "x": "Uman (n)",
    "y": " Umman"
  },
  {
    "x": "umayîş (n)",
    "y": " b. gimayîş (n)"
  },
  {
    "x": "umbarik",
    "y": " b. bimbarek, -e"
  },
  {
    "x": "umbarkên",
    "y": " b. bimbarek, -e"
  },
  {
    "x": "umbaz, -i",
    "y": " b. embaz, -e"
  },
  {
    "x": "umbazey (m)",
    "y": " b. embazîye (m) "
  },
  {
    "x": "umir (n)",
    "y": " b. emr-I (n)"
  },
  {
    "x": "umiş",
    "y": " b. emşo"
  },
  {
    "x": "umnû (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "umnûn (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "umr (n)",
    "y": " b. emr-I (n)"
  },
  {
    "x": "umşo",
    "y": " b. emşo"
  },
  {
    "x": "umur (n)",
    "y": " b. emr-I (n)"
  },
  {
    "x": "umûmî",
    "y": " genel, umumi"
  },
  {
    "x": "umûmîya (m)",
    "y": " kamu, amme"
  },
  {
    "x": "umûmîya rê cadeverdayîş",
    "y": " kamuya terk, yola terk"
  },
  {
    "x": "umûmîya rê terk",
    "y": " kamuya terk, yola terk"
  },
  {
    "x": "una",
    "y": " b. wina"
  },
  {
    "x": "Unanîstan (n)",
    "y": " b. Yunanîstan (n)"
  },
  {
    "x": "unca-I (m)",
    "y": " yonca"
  },
  {
    "x": "unca-II",
    "y": " b. ancîna"
  },
  {
    "x": "unce (n)",
    "y": " b. unca-I (m)"
  },
  {
    "x": "uncil (n)",
    "y": " b. anculî (n)"
  },
  {
    "x": "uncilî (n)",
    "y": " b. anculî (n)"
  },
  {
    "x": "uncîlî (n)",
    "y": " b. anculî (n)"
  },
  {
    "x": "undêr, -e",
    "y": " b. ondêr, -e"
  },
  {
    "x": "undi",
    "y": " b. hende"
  },
  {
    "x": "ungur (m)",
    "y": " b. engure (m)"
  },
  {
    "x": "unguri (m)",
    "y": " b. engure (m)"
  },
  {
    "x": "ungura mîçikan (m)",
    "y": " b. engura mîlçikan (m)"
  },
  {
    "x": "unix (n)",
    "y": " b. anûx (n)"
  },
  {
    "x": "unîforma (m)",
    "y": " üniforma"
  },
  {
    "x": "unîversal, -e",
    "y": " âlemşümul, cihanşümul, evrensel, üniversal"
  },
  {
    "x": "unîversalî (m)",
    "y": " âlemşümullük, cihanşümullük, evrensellik"
  },
  {
    "x": "unîversalîye (m)",
    "y": " âlemşümullük, cihanşümullük, evrensellik"
  },
  {
    "x": "unîversîte (m)",
    "y": " üniversite"
  },
  {
    "x": "unîyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "uno (n, z)",
    "y": " b. no-I (n, z) "
  },
  {
    "x": "unu (n, z)",
    "y": " b. no-I (n, z) "
  },
  {
    "x": "unû (n, z)",
    "y": " b. no-I (n, z)"
  },
  {
    "x": "unwan (n)",
    "y": " san, unvan, titr"
  },
  {
    "x": "Urdun (n)",
    "y": " Ürdün"
  },
  {
    "x": "urf û adet",
    "y": " gelenek, örf âdet"
  },
  {
    "x": "urf û adetî, -ye",
    "y": " geleneksel"
  },
  {
    "x": "urolog, -e",
    "y": " bevliyeci, üroloji, ürolog"
  },
  {
    "x": "urologî (m)",
    "y": " bevliyecilik, ürologluk"
  },
  {
    "x": "urologîye (m)",
    "y": " bevliyecilik, ürologluk"
  },
  {
    "x": "urolojî (n)",
    "y": " bevliye"
  },
  {
    "x": "urs (n)",
    "y": " b. ors-II (n)"
  },
  {
    "x": "urzê .... ameyeneberdene",
    "y": " b. erzê ... ameyeneberdene"
  },
  {
    "x": "urzê .... ameyîşberdiş",
    "y": " b. erzê ... ameyîşberdiş "
  },
  {
    "x": "urzê .... berdene",
    "y": " b. erzê ... berdene"
  },
  {
    "x": "urzê .... berdiş",
    "y": " b. erzê ... berdiş"
  },
  {
    "x": "urznayene",
    "y": " b. weriznayene"
  },
  {
    "x": "urznayêne ra",
    "y": " b. rawuriznayene"
  },
  {
    "x": "urznayîne",
    "y": " b. weriznayene"
  },
  {
    "x": "urznayîş (n)",
    "y": " b. weriznayîş (n)"
  },
  {
    "x": "usar (n)",
    "y": " b. wisar (n)"
  },
  {
    "x": "usarî",
    "y": " b. wisarî"
  },
  {
    "x": "usarrî",
    "y": " b. wisarî"
  },
  {
    "x": "USB (m) (TE)",
    "y": " USB "
  },
  {
    "x": "uska (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "uskera (m)",
    "y": " b. uskura (m)"
  },
  {
    "x": "uskif (n)",
    "y": " uskîf (n)"
  },
  {
    "x": "uskira (m)",
    "y": " b. uskura (m)"
  },
  {
    "x": "uskîf (n)",
    "y": " yüksük"
  },
  {
    "x": "Uskup (n)",
    "y": " Üsküp"
  },
  {
    "x": "uskura (m)",
    "y": " tas"
  },
  {
    "x": "uslûb (n)",
    "y": " stil, tarz, üslup"
  },
  {
    "x": "usot (n)",
    "y": " b. îsot (n)"
  },
  {
    "x": "usta (n)",
    "y": " b. westa (n)"
  },
  {
    "x": "ustine (m)",
    "y": " b. estune (m)"
  },
  {
    "x": "ustini (m)",
    "y": " b. estune (m)"
  },
  {
    "x": "ustonik (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "ustor, -e",
    "y": " b. estor, -e "
  },
  {
    "x": "ustune (m)",
    "y": " b. estune (m)"
  },
  {
    "x": "ustûne (m)",
    "y": " b. estune (m)"
  },
  {
    "x": "uşîre (m)",
    "y": " 1)değnek, asa\r\n~2)çubuk (uzun ağızlık)"
  },
  {
    "x": "uşk, -i",
    "y": " b. huşk, -e"
  },
  {
    "x": "uşk bîyene",
    "y": " b. huşk bîyene"
  },
  {
    "x": "uşk kerdene",
    "y": " b. huşk kerdene"
  },
  {
    "x": "uşka (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "uşm",
    "y": " b. emşo"
  },
  {
    "x": "uşmo",
    "y": " b. emşo"
  },
  {
    "x": "uşna (m)",
    "y": " b. wişna (m)"
  },
  {
    "x": "uşnayêr (m)",
    "y": " b. wişnayêre (m)"
  },
  {
    "x": "uta (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "utêli (m)",
    "y": " b. otêle"
  },
  {
    "x": "utî (m)",
    "y": " ütü"
  },
  {
    "x": "utî bîyene",
    "y": " ütülenmek"
  },
  {
    "x": "utî kerdene",
    "y": " ütülemek"
  },
  {
    "x": "utîkar, -e",
    "y": " ütücü"
  },
  {
    "x": "utîkarênî (m)",
    "y": " ütücülük"
  },
  {
    "x": "utîkarî (m)",
    "y": " ütücülük"
  },
  {
    "x": "utîkarîye (m)",
    "y": " ütücülük"
  },
  {
    "x": "uw (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "uwdayîş (n)",
    "y": " b. awedayîş (n)"
  },
  {
    "x": "uwdîyayîş (n)",
    "y": " b. awedîyayîş (n)"
  },
  {
    "x": "uwe (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "uxwe (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "uza (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "uzaxe (m)",
    "y": " b. ocaxe (m)"
  },
  {
    "x": "uzaxkor, -e",
    "y": " b. ocaxkor, -e"
  },
  {
    "x": "uzdayene",
    "y": " b. kuzayene"
  },
  {
    "x": "uzdayîş (n)",
    "y": " b. kuzayîş (n)"
  },
  {
    "x": "uzir (n)",
    "y": " kusur"
  },
  {
    "x": "uzr (n)",
    "y": " b. uzir (n)"
  },
  {
    "x": "ÛÛÛ"
  },
  {
    "x": "û-I (n, z)",
    "y": " b. o-I (n, z)"
  },
  {
    "x": "û-II (n, s)",
    "y": " b. o-II (n, s)"
  },
  {
    "x": "û-III (ed)",
    "y": " ve, ile"
  },
  {
    "x": "...û (sf)",
    "y": " b. -an (sf)"
  },
  {
    "x": "ûca (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "ûcax (n)",
    "y": " b. ocax (n)"
  },
  {
    "x": "ûcaxi (m)",
    "y": " b. ocaxe (m)"
  },
  {
    "x": "ûçe (m)",
    "y": " abla"
  },
  {
    "x": "ûçê",
    "y": " abla (hitap hali)"
  },
  {
    "x": "ûçêyey (m)",
    "y": " b. ûçêyî (m)"
  },
  {
    "x": "ûçê (m)",
    "y": " b. ûçêyî (m)"
  },
  {
    "x": "ûçêyî (m)",
    "y": " ablalık"
  },
  {
    "x": "Ûganda (m)",
    "y": " Uganda"
  },
  {
    "x": "ûja (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "Ûlan Bator (n)",
    "y": " Ulan Bator"
  },
  {
    "x": "ûlandî",
    "y": " b. olandî"
  },
  {
    "x": "ûle",
    "y": " b. willayî"
  },
  {
    "x": "ûllayî",
    "y": " b. willayî"
  },
  {
    "x": "ûmar (m)",
    "y": " b. hûmare (m) "
  },
  {
    "x": "ûmare (m)",
    "y": " b. hûmare (m) "
  },
  {
    "x": "ûmari (m)",
    "y": " b. hûmare (m)"
  },
  {
    "x": "ûmaritene",
    "y": " b. hûmaritene"
  },
  {
    "x": "ûmaritiş (n)",
    "y": " b. hûmaritiş (n)"
  },
  {
    "x": "ûmartene",
    "y": " b. hûmaritene"
  },
  {
    "x": "ûmartiş (n)",
    "y": " b. hûmaritiş (n)"
  },
  {
    "x": "ûmayîne",
    "y": " b. ameyene"
  },
  {
    "x": "ûmayîne fahmkerdene",
    "y": " b. ameyene fehmkerdene"
  },
  {
    "x": "ûmayîne heskerdene",
    "y": " b. ameyene heskerdene"
  },
  {
    "x": "ûmayîne sînayene",
    "y": " b. ameyene sînayene"
  },
  {
    "x": "ûmayîne vatene",
    "y": " b. ameyene vatene"
  },
  {
    "x": "ûmayîşfahmkerdiş (n)",
    "y": " b. ameyîşfehmkerdiş (n)"
  },
  {
    "x": "ûmayîşheskerdiş (n)",
    "y": " b. ameyîşheskerdiş (n)"
  },
  {
    "x": "ûmayîşsînayîş (n)",
    "y": " b. ameyîşsînayîş (n)"
  },
  {
    "x": "ûmayîşvatiş (n)",
    "y": " b. ameyîşvatiş (n)"
  },
  {
    "x": "ûmbaz",
    "y": " b. embaz, -e"
  },
  {
    "x": "ûmbaztî (m)",
    "y": " b. embazîye (m)"
  },
  {
    "x": "umre (n)",
    "y": " umre "
  },
  {
    "x": "uzleme riznaene",
    "y": " b. îzleme rijnayene"
  },
  {
    "x": "uzlemeriznaîs (n)",
    "y": " b. îzlemerijnayîş (n)"
  },
  {
    "x": "ûmeyene",
    "y": " b. ameyene"
  },
  {
    "x": "ûmeyene beşkerdene",
    "y": " b. ameyene beşkerdene"
  },
  {
    "x": "ûmeyene tesnîfkerdene",
    "y": " b. ameyene tesnîfkerdene"
  },
  {
    "x": "ûmeyiş (n)",
    "y": " b. ameyîş (n)"
  },
  {
    "x": "ûmeyişkiştiş (n)",
    "y": " b. ameyîşkiştiş (n)"
  },
  {
    "x": "ûmeyîne",
    "y": " b. ameyene"
  },
  {
    "x": "ûmeyîne alaznayene",
    "y": " b. ameyene aloznayene"
  },
  {
    "x": "ûmeyîne aluznayene",
    "y": " b. ameyene aloznayene"
  },
  {
    "x": "ûmeyîne alzinayene",
    "y": " b. ameyene aloznayene"
  },
  {
    "x": "ûmeyîne cêmîyûnvistene",
    "y": " b. ameyene cêmîyanvistene"
  },
  {
    "x": "ûmeyîne fûmkerdene",
    "y": " b. ameyene fehmkerdene"
  },
  {
    "x": "ûmeyîne girnayene",
    "y": " b. ameyene girênayene"
  },
  {
    "x": "ûmeyîne paykerdene",
    "y": " b. ameyene paykerdene"
  },
  {
    "x": "ûmeyîne pêşûnayene",
    "y": " b. ameyene pêşanayene"
  },
  {
    "x": "ûmeyîne piraguretene",
    "y": " b. ameyene piragirewtene"
  },
  {
    "x": "ûmeyîne pînekerdene",
    "y": " b. ameyene pînekerdene"
  },
  {
    "x": "ûmeyîne pîyeser",
    "y": " b. ameyene pêser"
  },
  {
    "x": "ûmeyîne potene",
    "y": " b. ameyene pewtene"
  },
  {
    "x": "ûmeyîne pûnayene",
    "y": " b. ameyene panayene"
  },
  {
    "x": "ûmeyîne qirbûnkerdene",
    "y": " b. ameyene qurbankerdene"
  },
  {
    "x": "ûmeyîne rûmitene",
    "y": " b. ameyene ramitene"
  },
  {
    "x": "ûmeyîne sînayene",
    "y": " b. ameyene sînayene"
  },
  {
    "x": "ûmeyîne têkilkerdene",
    "y": " b. ameyene têkilkerdene"
  },
  {
    "x": "ûmeyîne utîkerdene",
    "y": " b. ameyene utîkerdene"
  },
  {
    "x": "ûmeyîne vatene",
    "y": " b. ameyene vatene"
  },
  {
    "x": "ûmeyîne werdene",
    "y": " b. ameyene werdene"
  },
  {
    "x": "ûmeyîne yoca",
    "y": " b. ameyene yewca"
  },
  {
    "x": "... ûmeyîne",
    "y": " b. ... ameyene"
  },
  {
    "x": "ûmeyîş (n)",
    "y": " b. ameyîş (n)"
  },
  {
    "x": "... ûmeyîş",
    "y": " b. ... ameyîş"
  },
  {
    "x": "ûmeyîşalaznayîş (n)",
    "y": " b. ameyîşaloznayîş (n)"
  },
  {
    "x": "ûmeyîşaluznayîş (n)",
    "y": " b. ameyîşaloznayîş (n)"
  },
  {
    "x": "ûmeyîşalzinayîş (n)",
    "y": " b. ameyîşaloznayîş (n)"
  },
  {
    "x": "ûmeyîşbeşkerdiş (n)",
    "y": " b. ameyîşbeşkerdiş (n)"
  },
  {
    "x": "ûmeyîşcêmîyûnvistiş (n)",
    "y": " b. ameyîşcêmîyanvistiş"
  },
  {
    "x": "ûmeyîşdeştiş (n)",
    "y": " b. ameyîşdeştiş (n)"
  },
  {
    "x": "ûmeyîşdêştiş (n)",
    "y": " b. ameyîşdeştiş (n)"
  },
  {
    "x": "ûmeyîşdîyayîş (n)",
    "y": " b. ameyîşdîyayîş (n)"
  },
  {
    "x": "ûmeyîşfûmkerdiş (n)",
    "y": " b. ameyîşfehmkerdiş (n)"
  },
  {
    "x": "ûmeyîşgirnayîş (n)",
    "y": " b. ameyîşgirênayîş (n)"
  },
  {
    "x": "ûmeyîşkijikkerdiş (n)",
    "y": " b. ameyîşkijikekerdiş (n)"
  },
  {
    "x": "ûmeyîşkijkerdiş (n)",
    "y": " b. ameyîşkijkerdiş (n)"
  },
  {
    "x": "ûmeyîşkiştiş (n)",
    "y": " b. ameyîşkiştiş (n)"
  },
  {
    "x": "ûmeyîşpaykerdiş (n)",
    "y": " b. ameyîşpaykerdiş (n)"
  },
  {
    "x": "ûmeyîşpêşûnayîş (n)",
    "y": " b. ameyîşpêşanayîş (n)"
  },
  {
    "x": "ûmeyîşpiraguretiş (n)",
    "y": " b. ameyîşpiragirewtiş (n)"
  },
  {
    "x": "ûmeyîşpînekerdiş (n)",
    "y": " b. ameyîşpînekerdiş (n)"
  },
  {
    "x": "ûmeyîşpîyeser (n)",
    "y": " b. ameyîşpêser (n)"
  },
  {
    "x": "ûmeyîşpotiş (n)",
    "y": " b. ameyîşpewtiş (n)"
  },
  {
    "x": "ûmeyîşpûnayîş (n)",
    "y": " b. ameyîşpanayîş (n)"
  },
  {
    "x": "ûmeyîşsînayîş (n)",
    "y": " b. ameyîşsînayîş (n)"
  },
  {
    "x": "ûmeyîşsotiş (n)",
    "y": " b. ameyîşsotiş (n)"
  },
  {
    "x": "ûmeyîştesnîfkerdiş (n)",
    "y": " b. ameyîştesnîfkerdiş (n)"
  },
  {
    "x": "ûmeyîştêkilkerdiş (n)",
    "y": " b. ameyîştêkilkerdiş (n)"
  },
  {
    "x": "ûmeyîşvatiş (n)",
    "y": " b. ameyîşvatiş (n)"
  },
  {
    "x": "ûmeyîşxemelnayîş (n)",
    "y": " b. ameyîşxemilnayîş (n)"
  },
  {
    "x": "ûmeyîşyoca (n)",
    "y": " ameyîşyewca (n)"
  },
  {
    "x": "ûmên (n)",
    "y": " b. amên (n)"
  },
  {
    "x": "ûmên bîyayene",
    "y": " b. amên bîyene"
  },
  {
    "x": "ûmên kerdene",
    "y": " b. amên kerdene"
  },
  {
    "x": "ûmênbîyayîş (n)",
    "y": " b. amênbîyayîş (n)"
  },
  {
    "x": "ûmênkerde (n)",
    "y": " b. amênkerde (n)"
  },
  {
    "x": "ûmênkerdiş (n)",
    "y": " b. amênkerdiş (n)"
  },
  {
    "x": "ûmîn (n)",
    "y": " b. amên (n)"
  },
  {
    "x": "ûmîn bîyayene",
    "y": " b. amên bîyene"
  },
  {
    "x": "ûmîn kerdene",
    "y": " b. amên kerdene"
  },
  {
    "x": "ûmînbîyayîş (n)",
    "y": " b. amênbîyayîş (n)"
  },
  {
    "x": "ûmînkerde (n)",
    "y": " b. amênkerde (n)"
  },
  {
    "x": "ûmînkerdi (n)",
    "y": " b. amênkerde (n)"
  },
  {
    "x": "ûmînkerdiş (n)",
    "y": " b. amênkerdiş (n)"
  },
  {
    "x": "ûmiş (n)",
    "y": " uyma"
  },
  {
    "x": "ûmiş bîyene",
    "y": " uymak, uygun gelmek, uygun olmak, yakışmak, yaraşmak"
  },
  {
    "x": "ûmnû (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "ûmnûn (n)",
    "y": " b. hamnan (n)"
  },
  {
    "x": "ûmnûno peyin",
    "y": " b. hamnano peyên"
  },
  {
    "x": "ûmnûno peyîn",
    "y": " b. hamnano peyên"
  },
  {
    "x": "ûmnûno verîn",
    "y": " b. hamnano verên"
  },
  {
    "x": "ûmnûno veryen",
    "y": " b. hamnano verên"
  },
  {
    "x": "ûmnûno vêrîn",
    "y": " b. hamnano verên"
  },
  {
    "x": "ûmnûno virye",
    "y": " b. hamnano verên"
  },
  {
    "x": "ûmnûno viryen",
    "y": " b. hamnano verên"
  },
  {
    "x": "ûmnûna (m)",
    "y": " b. hamnana (m)"
  },
  {
    "x": "ûmnûne (n)",
    "y": " b. hamnane (n)"
  },
  {
    "x": "ûmnûnên, -e",
    "y": " b. hamnanên, -e"
  },
  {
    "x": "ûmnûnîn, -e",
    "y": " b. hamnanên, -e"
  },
  {
    "x": "ûmnûnîye (m)",
    "y": " b. hamnanîye (m)"
  },
  {
    "x": "ûmvar",
    "y": " b. hember, -e; hemver, -e"
  },
  {
    "x": "ûmyayîş (n)",
    "y": " b. ameyîş (n)"
  },
  {
    "x": "...ûn (sf)",
    "y": " b. -an (sf)"
  },
  {
    "x": "ûna",
    "y": " 1)b. wina\r\n~2)b. ûne"
  },
  {
    "x": "ûnasar",
    "y": " b. winasî, inasar"
  },
  {
    "x": "ûnasarên, -i",
    "y": " b. winasî, -ye; inasarên, -e"
  },
  {
    "x": "ûnawa",
    "y": " b. inahawa"
  },
  {
    "x": "ûnayen",
    "y": " b. winasî, -ye; inasarên, -e; inasar"
  },
  {
    "x": "ûnayên",
    "y": " b. winasî, -ye; inasarên, -e; inasar"
  },
  {
    "x": "ûnayin",
    "y": " b. winasî, inasar"
  },
  {
    "x": "ûnîayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "ûnc",
    "y": " b. ancî"
  },
  {
    "x": "ûnca",
    "y": " b. ancîna"
  },
  {
    "x": "ûncax",
    "y": " b. ancax"
  },
  {
    "x": "ûncili (n)",
    "y": " b. anculî (n)"
  },
  {
    "x": "ûncilî, -ye",
    "y": " b. anculî, -ye"
  },
  {
    "x": "ûncî",
    "y": " b. ancî"
  },
  {
    "x": "ûncîa",
    "y": " b. ancîna"
  },
  {
    "x": "ûncîna",
    "y": " b. ancîna"
  },
  {
    "x": "ûncîya",
    "y": " b. ancîna"
  },
  {
    "x": "ûncîyayene",
    "y": " b. ancîyayene"
  },
  {
    "x": "ûncîyayîş (n)",
    "y": " b. ancîyayîş (n)"
  },
  {
    "x": "ûnculî, -ye",
    "y": " b. anculî, -ye"
  },
  {
    "x": "ûne",
    "y": " bedava, beleş"
  },
  {
    "x": "ûnewa",
    "y": " b. inahawa"
  },
  {
    "x": "ûni",
    "y": " b. ûne"
  },
  {
    "x": "ûnix (n)",
    "y": " b. anûx (n)"
  },
  {
    "x": "ûnîyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "biûnî",
    "y": " b. biewnî"
  },
  {
    "x": "bûnî",
    "y": " b. biewnî"
  },
  {
    "x": "ûntene",
    "y": " b. antene"
  },
  {
    "x": "ûntiş (n)",
    "y": " b. antiş (n)"
  },
  {
    "x": "ûnvar",
    "y": " b. hember, -e; hemver, -e"
  },
  {
    "x": "ûnyayîne",
    "y": " b. ewnîyayene"
  },
  {
    "x": "ûnyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "ûrs (n)",
    "y": " b. wers-I (n)"
  },
  {
    "x": "Ûrûgûay",
    "y": " Uruguay "
  },
  {
    "x": "ûsar (n)",
    "y": " b. wisar (n)"
  },
  {
    "x": "ûsil (n)",
    "y": " b. ûsul (n)"
  },
  {
    "x": "ûsil-ûsil",
    "y": " b. ûsul-ûsul"
  },
  {
    "x": "ûskûmrû (n)",
    "y": " uskumru"
  },
  {
    "x": "ûstine (m)",
    "y": " b. estune (m)"
  },
  {
    "x": "ûstini (m)",
    "y": " b. estune (m)"
  },
  {
    "x": "ûstor, -e",
    "y": " b. estor, -e"
  },
  {
    "x": "ûstune (m)",
    "y": " b. estune (m)"
  },
  {
    "x": "ûsul (n)",
    "y": " usul, yöntem\r\n~ûsulê gureyayîşî (n): çalışma yöntemi"
  },
  {
    "x": "ûsulê gureyayîşî (n)",
    "y": " çalışma yöntemi"
  },
  {
    "x": "ûsulê xebate (n)",
    "y": " çalışma yöntemi"
  },
  {
    "x": "ûsulî reyde",
    "y": " giderek, gittikçe, yavaş yavaş"
  },
  {
    "x": "ûsul-ûsul",
    "y": " giderek, gittikçe, yavaş yavaş"
  },
  {
    "x": "ûsûl (n)",
    "y": " b. ûsul (n)"
  },
  {
    "x": "ûşîri (m)",
    "y": " b. uşîre (m)"
  },
  {
    "x": "ût, -i",
    "y": " b. hut, -e"
  },
  {
    "x": "ûta (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "ûtîya (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "ûve (n)",
    "y": " b. aver (n)"
  },
  {
    "x": "ûver (n)",
    "y": " b. aver (n)"
  },
  {
    "x": "ûver berdene",
    "y": " b. aver berdene"
  },
  {
    "x": "ûwe (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "ûvertur (n)",
    "y": " uvertur"
  },
  {
    "x": "ûwneyîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "ûwnîyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "ûxe (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "ûza (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "VVV"
  },
  {
    "x": "va-I (n)",
    "y": " 1)rüzgâr, yel\r\n~2)romatizma"
  },
  {
    "x": "va bîyayene",
    "y": " b. va bîyene"
  },
  {
    "x": "va bîyene",
    "y": " şişmek"
  },
  {
    "x": "va ci kewtene",
    "y": " yellenmek, hava kaçırmak, osurmak"
  },
  {
    "x": "va ci kotene",
    "y": " b. va ci kewtene"
  },
  {
    "x": "va dayene",
    "y": " b. vay dayene"
  },
  {
    "x": "va kardene",
    "y": " b. va kerdene"
  },
  {
    "x": "va kerdene",
    "y": " şişirmek"
  },
  {
    "x": "va piro ginayene",
    "y": " yellenmek, hava kaçırmak, osurmak"
  },
  {
    "x": "va piro gunayene",
    "y": " b. va piro ginayene"
  },
  {
    "x": "va ver ro ginayene",
    "y": " yellenmek, hava kaçırmak, osurmak"
  },
  {
    "x": "vay dayene",
    "y": " savurmak"
  },
  {
    "x": "vay dayeni",
    "y": " b. vay dayene"
  },
  {
    "x": "vay dicna (n)",
    "y": " b. vayê dijna (n)"
  },
  {
    "x": "vay dijna (n)",
    "y": " b. vayê dijna (n)"
  },
  {
    "x": "vay dîyayene",
    "y": " savrulmak"
  },
  {
    "x": "vayê dijna (n)",
    "y": " bora "
  },
  {
    "x": "vayî ver kewtene",
    "y": " 1)savrulmak\r\n~2)(mecazi) havaya girmek, kibirlenmek\r\n~3)(mecazi) etkilenmek\r\n~4)kösnümek (dişi köpek vb. için)\r\n~5)cin çarpmak"
  },
  {
    "x": "va-II",
    "y": " b. vay, -e-I"
  },
  {
    "x": "va-III (m)",
    "y": " b. vaya-I (m)"
  },
  {
    "x": "vacikewtiş (n)",
    "y": " yellenme, hava kaçırma, osurma"
  },
  {
    "x": "vacikotiş (n)",
    "y": " b. vacikewtiş (n)"
  },
  {
    "x": "vacirîyayene",
    "y": " b. vajirîyayene"
  },
  {
    "x": "vacirîyayîş (n)",
    "y": " b. vajirîyayîş (n)"
  },
  {
    "x": "vaciryayene",
    "y": " b. vajirîyayene"
  },
  {
    "x": "vaciryayîş (n)",
    "y": " b. vajirîyayîş (n)"
  },
  {
    "x": "vacîyayene",
    "y": " b. vajîyayene"
  },
  {
    "x": "vacîyayîne",
    "y": " b. vajîyayene"
  },
  {
    "x": "vacîyayîş (n)",
    "y": " b. vajîyayîş (n)"
  },
  {
    "x": "vacor bîyayene",
    "y": " b. vajor bîyene"
  },
  {
    "x": "vacorbîyayîş (n)",
    "y": " b. vajorbîyayîş (n)"
  },
  {
    "x": "vacvacok (n)",
    "y": " tekerleme"
  },
  {
    "x": "Vaduz (n)",
    "y": " Vaduz"
  },
  {
    "x": "vahart (m)",
    "y": " b. varte (m)"
  },
  {
    "x": "vahî (m)",
    "y": " b. vaye (m)"
  },
  {
    "x": "vajerîyayene",
    "y": " b. vajirîyayene"
  },
  {
    "x": "vajerîyayîş (n)",
    "y": " b. vajirîyayîş (n)"
  },
  {
    "x": "vajêr bîyayene",
    "y": " b. vajor bîyene"
  },
  {
    "x": "vajêrbîyayîş (n)",
    "y": " b. vajorbîyayîş (n)"
  },
  {
    "x": "vajireyîş (n)",
    "y": " b. vajirîyayîş (n)"
  },
  {
    "x": "vajirîyayene",
    "y": " söylenmek (kendi kendine konuşmak)"
  },
  {
    "x": "vajirîyayîş (n)",
    "y": " söylenme (kendi kendine konuşma)"
  },
  {
    "x": "vajîyayene",
    "y": " anlatılmak, söylenmek\r\n~A hîkaye hema zî dewe de vajîyena. (O hikâye köyde hâlâ anlatılır.)"
  },
  {
    "x": "vajîyayîş (n)",
    "y": " anlatılma, söylenme"
  },
  {
    "x": "vajor bîyene",
    "y": " söylenmek (kendi kendine konuşmak)"
  },
  {
    "x": "vajor kerdene",
    "y": " (ile) konuşmak"
  },
  {
    "x": "vajorbîyayîş (n)",
    "y": " söylenme (kendi kendine konuşma)"
  },
  {
    "x": "vajorîyayene",
    "y": " b. vajirîyayene"
  },
  {
    "x": "vajorîyayîş (n)",
    "y": " b. vajirîyayîş (n)"
  },
  {
    "x": "vajorkerdiş (n)",
    "y": " (ile) konuşma"
  },
  {
    "x": "vajuer bîyayene",
    "y": " b. vajor bîyene"
  },
  {
    "x": "vajuerbîyayîş (n)",
    "y": " b. vajorbîyayîş (n)"
  },
  {
    "x": "vajureyîş (n)",
    "y": " b. vajirîyayîş (n)"
  },
  {
    "x": "vajurîyayîş (n)",
    "y": " b. vajirîyayîş (n)"
  },
  {
    "x": "vakir (n)",
    "y": " kuzey"
  },
  {
    "x": "vakum (n)",
    "y": " vakum"
  },
  {
    "x": "vakur (n)",
    "y": " kuzey\r\n~vakurê rojawanî (n): kuzeybatı\r\n~vakurê rojhelatî (n): kuzeydoğu"
  },
  {
    "x": "vakurê rojawanî (n)",
    "y": " kuzeybatı"
  },
  {
    "x": "vakurê rojhelatî (n)",
    "y": " kuzeydoğu "
  },
  {
    "x": "val (n)",
    "y": " b. vîyale (m)"
  },
  {
    "x": "vala (m)",
    "y": " 1)başörtü, başörtüsü, yazma\r\n~2)b. hevala (m)"
  },
  {
    "x": "valêr (m)",
    "y": " b. vîyale (m)"
  },
  {
    "x": "valêre (m)",
    "y": " b. vîyale (m)"
  },
  {
    "x": "valêri (m)",
    "y": " b. vîyale (m)"
  },
  {
    "x": "valî, -ye",
    "y": " b. walî, -ye"
  },
  {
    "x": "valîz (n)",
    "y": " b. valîze (m)"
  },
  {
    "x": "valîze (m)",
    "y": " valiz"
  },
  {
    "x": "Valletta (m)",
    "y": " Valletta"
  },
  {
    "x": "valuî (m)",
    "y": " b. vala (m)"
  },
  {
    "x": "valuy (m)",
    "y": " b. vala (m)"
  },
  {
    "x": "valuya (m)",
    "y": " b. vala (m)"
  },
  {
    "x": "valyer (m)",
    "y": " b. vîyale (m)"
  },
  {
    "x": "vame (m)",
    "y": " badem"
  },
  {
    "x": "vameke (m)",
    "y": " bademcik"
  },
  {
    "x": "vameki (m)",
    "y": " b. vameke (m)"
  },
  {
    "x": "vamekîdeginayîş (n)",
    "y": " bademcik şişmesi"
  },
  {
    "x": "vamêr (m)",
    "y": " b. vamêre (m)"
  },
  {
    "x": "vamêre (m)",
    "y": " badem ağacı"
  },
  {
    "x": "vamêri (m)",
    "y": " b. vamêre (m)"
  },
  {
    "x": "vami (m)",
    "y": " b. vame (m)"
  },
  {
    "x": "vana (m)",
    "y": " vana\r\n~vanaya perperike (m): kelebek vana\r\n~vanaya zerbeyine (m): sürgülü vana"
  },
  {
    "x": "vanaya perperike (m)",
    "y": " kelebek vana"
  },
  {
    "x": "vanaya zerbeyine (m)",
    "y": " sürgülü vana"
  },
  {
    "x": "vanîlya (m)",
    "y": " vanilya"
  },
  {
    "x": "Vanuatu (n)",
    "y": " Vanuatu"
  },
  {
    "x": "vapiroginayîş (n)",
    "y": " yellenme, hava kaçırma, osurma"
  },
  {
    "x": "vapirogunayîş (n)",
    "y": " b. vapiroginayîş (n)"
  },
  {
    "x": "var mosla dayene",
    "y": " b. ver mosla dayene"
  },
  {
    "x": "vara-I (m)",
    "y": " b. vereke (m)"
  },
  {
    "x": "vara-II (n)",
    "y": " b. varan (n)"
  },
  {
    "x": "vara ...-III",
    "y": " b. vera ..."
  },
  {
    "x": "varak, -e",
    "y": " b. verek, -e"
  },
  {
    "x": "varan (n)",
    "y": " yağmur"
  },
  {
    "x": "varano sîya (n)",
    "y": " siyah yağmur"
  },
  {
    "x": "varanin, -e",
    "y": " yağmurlu"
  },
  {
    "x": "varanî (m)",
    "y": " yağmurluk"
  },
  {
    "x": "varar (m)",
    "y": " b. verare (m)"
  },
  {
    "x": "varar tira kerdene",
    "y": " b. verare tira kerdene "
  },
  {
    "x": "varard (m)",
    "y": " b. verardî (m)"
  },
  {
    "x": "varartirakerdiş (n)",
    "y": " b. veraretirakerdiş (n)"
  },
  {
    "x": "varasû (n)",
    "y": " b. veraşan (n)"
  },
  {
    "x": "varayene",
    "y": " yağmak"
  },
  {
    "x": "varayîş (n)",
    "y": " yağma"
  },
  {
    "x": "varazî (n)",
    "y": " b. veraj (n)"
  },
  {
    "x": "varbelek",
    "y": " b. vewrbelek"
  },
  {
    "x": "varc (n)",
    "y": " b. verg (n)"
  },
  {
    "x": "vardîya (m)",
    "y": " vardiya"
  },
  {
    "x": "vare-I (m)",
    "y": " b. vewre (m)"
  },
  {
    "x": "vare-II (n)",
    "y": " b. verek (n)"
  },
  {
    "x": "varek, -e",
    "y": " b. verek, -e"
  },
  {
    "x": "vara ardene",
    "y": " b. verek ardene"
  },
  {
    "x": "vara eştene",
    "y": " b. verek eştene"
  },
  {
    "x": "varek ardene",
    "y": " b. verek ardene"
  },
  {
    "x": "varek eştene",
    "y": " b. verek eştene"
  },
  {
    "x": "varekwan, -e",
    "y": " b. verekwan, -e"
  },
  {
    "x": "varekwanîye (m)",
    "y": " b. verekwanî (m), verekwanîye (m)"
  },
  {
    "x": "varenayene",
    "y": " tiftiklemek"
  },
  {
    "x": "varete (m)",
    "y": " b. varte (m)"
  },
  {
    "x": "varewûn",
    "y": " b. verekwan, -e"
  },
  {
    "x": "varewûnê (m)",
    "y": " b. verekwanî (m), verekwanîye (m)"
  },
  {
    "x": "varg, -i",
    "y": " b. verg, -e"
  },
  {
    "x": "vargoz (n)",
    "y": " balyoz, varyos"
  },
  {
    "x": "vargoz piro dayene",
    "y": " balyozlamak"
  },
  {
    "x": "vargozpirodayîş (n)",
    "y": " balyozlama"
  },
  {
    "x": "varig (m)",
    "y": " b. varike (m)"
  },
  {
    "x": "varigi (m)",
    "y": " b. varike (m)"
  },
  {
    "x": "varik (m)",
    "y": " b. varike (m)"
  },
  {
    "x": "varike (m)",
    "y": " piliç, ferik"
  },
  {
    "x": "variş (n)",
    "y": " yağış"
  },
  {
    "x": "varişin (n)",
    "y": " yağışlı"
  },
  {
    "x": "varişpeym (n)",
    "y": " plüviyometre, yağışölçer"
  },
  {
    "x": "varit (m)",
    "y": " b. varte (m)"
  },
  {
    "x": "varite (m)",
    "y": " b. varte (m)"
  },
  {
    "x": "varitene",
    "y": " yağmak"
  },
  {
    "x": "variti (m)",
    "y": " b. varte (m)"
  },
  {
    "x": "varitis (n)",
    "y": " b. varitiş (n)"
  },
  {
    "x": "varitiş (n)",
    "y": " yağma"
  },
  {
    "x": "varitî (m)",
    "y": " b. varte (m)"
  },
  {
    "x": "varîl (n)",
    "y": " varil"
  },
  {
    "x": "varnaene",
    "y": " b. varnayene"
  },
  {
    "x": "varnaîs (n)",
    "y": " b. varnayîş (n)"
  },
  {
    "x": "varnayene",
    "y": " yağdırmak"
  },
  {
    "x": "varnayêne",
    "y": " b. varnayene"
  },
  {
    "x": "varnayîs (n)",
    "y": " b. varnayîş (n)"
  },
  {
    "x": "varnayîş (n)",
    "y": " yağdırma"
  },
  {
    "x": "varnî (m)",
    "y": " b. vernî (m)"
  },
  {
    "x": "varnî girewtene",
    "y": " b. vernî girewtene"
  },
  {
    "x": "varnîgirewtiş (n)",
    "y": " b. vernîgirewtiş (n)"
  },
  {
    "x": "varo (n)",
    "y": " b. varan (n)"
  },
  {
    "x": "varon (n)",
    "y": " b. varan (n)"
  },
  {
    "x": "varonin (n)",
    "y": " b. varanin (n)"
  },
  {
    "x": "varoş (n)",
    "y": " varoş"
  },
  {
    "x": "varova (n)",
    "y": " arıların kanatlarını yiyen bir tür parazit"
  },
  {
    "x": "vart (m)",
    "y": " b. varte (m)"
  },
  {
    "x": "varte (m)",
    "y": " sağanak"
  },
  {
    "x": "vartene",
    "y": " b. varitene, varayene"
  },
  {
    "x": "varti (m)",
    "y": " b. varte (m)"
  },
  {
    "x": "vartis (n)",
    "y": " b. varitiş (n)"
  },
  {
    "x": "vartiş (n)",
    "y": " b. varitiş (n)"
  },
  {
    "x": "vartî (m)",
    "y": " b. varte (m)"
  },
  {
    "x": "vartûvaran (n)",
    "y": " yağış"
  },
  {
    "x": "vartûvaranin (n)",
    "y": " yağışlı"
  },
  {
    "x": "varû (n)",
    "y": " b. varan (n)"
  },
  {
    "x": "varûn (n)",
    "y": " b. varan (n)"
  },
  {
    "x": "varûnin (n)",
    "y": " b. varanin (n)"
  },
  {
    "x": "varvare (n)",
    "y": " b. varova (n)"
  },
  {
    "x": "varyant (n)",
    "y": " varyant"
  },
  {
    "x": "varyet (n)",
    "y": " b. warîdat (n)"
  },
  {
    "x": "vas (n)",
    "y": " b. vaş"
  },
  {
    "x": "vasê kekû (n)",
    "y": " b. vaşê kekan (n)"
  },
  {
    "x": "vasîyîye (m)",
    "y": " b. wasîyîye (m)"
  },
  {
    "x": "vasturîye (m)",
    "y": " b. vaşturî (m)"
  },
  {
    "x": "vaş (n)",
    "y": " ot"
  },
  {
    "x": "vaşê kekan (n)",
    "y": " papatya"
  },
  {
    "x": "vaşêre (m)",
    "y": " hayvanlara kışın yedirilmek üzere kesilip ağacın çatallı ana dalları arasına dizilen ot yığını"
  },
  {
    "x": "vaştirî (m)",
    "y": " b. vaşturî (m)"
  },
  {
    "x": "vaştirû (m)",
    "y": " b. vaşturî (m)"
  },
  {
    "x": "vaştuirî (m)",
    "y": " b. vaşturî (m)"
  },
  {
    "x": "vaşturî (m)",
    "y": " orak"
  },
  {
    "x": "vaşturîye (m)",
    "y": " b. vaşturî (m)"
  },
  {
    "x": "vaşturû (m)",
    "y": " b. vaşturî (m)"
  },
  {
    "x": "vaştûrî (m)",
    "y": " b. vaşturî (m)"
  },
  {
    "x": "vate (n)",
    "y": " 1)söz\r\n~2)güfte"
  },
  {
    "x": "vateyê verênan (n)",
    "y": " atasözü"
  },
  {
    "x": "vateyo dejdayox (n)",
    "y": " acı söz"
  },
  {
    "x": "vateyo verên (n)",
    "y": " önsöz"
  },
  {
    "x": "vatene",
    "y": " 1)demek, söylemek \r\n~mevaje ke: meğer, meğerse\r\n~ti mevaje ke: meğer, meğerse\r\n~ti nêvana ke: meğer, meğerse\r\n~ti nêvanî ke: meğer, meğerse\r\n~2)beyan etmek \r\n~3)anlatmak"
  },
  {
    "x": "mevaje ke",
    "y": " meğer, meğerse"
  },
  {
    "x": "ti mevaje ke",
    "y": " meğer, meğerse"
  },
  {
    "x": "ti nêvana ke",
    "y": " meğer, meğerse"
  },
  {
    "x": "ti nêvanî ke",
    "y": " meğer, meğerse"
  },
  {
    "x": "ti vana",
    "y": " b. ti vana qey"
  },
  {
    "x": "ti vana qa",
    "y": " b. ti vana qey"
  },
  {
    "x": "ti vana qay",
    "y": " b. ti vana qey"
  },
  {
    "x": "ti vana qey",
    "y": " sanki"
  },
  {
    "x": "ti vanî",
    "y": " b. ti vanî qey"
  },
  {
    "x": "ti vanî qay",
    "y": " b. ti vanî qey"
  },
  {
    "x": "ti vanî qey",
    "y": " sanki"
  },
  {
    "x": "vatene qey",
    "y": " sanmak, zannetmek"
  },
  {
    "x": "vateni",
    "y": " b. vatene"
  },
  {
    "x": "vatenus, -e",
    "y": " güfteci"
  },
  {
    "x": "vatesenc, -e",
    "y": " eleştirmen"
  },
  {
    "x": "vati (n)",
    "y": " b. vate (n)"
  },
  {
    "x": "vatis (n)",
    "y": " b. vatiş (n)"
  },
  {
    "x": "vatiş (n)",
    "y": " 1)deme, söyleme\r\n~2)beyan, beyan etme\r\n~3)anlatma"
  },
  {
    "x": "vatişqey (n)",
    "y": " sanma"
  },
  {
    "x": "vatîs (n)",
    "y": " b. vatiş (n)"
  },
  {
    "x": "vatka (m)",
    "y": " vatka (giysi için)"
  },
  {
    "x": "vatovato (n)",
    "y": " b. vatvatik (n)"
  },
  {
    "x": "vatvatik (n)",
    "y": " dedikodu "
  },
  {
    "x": "vaûvirike (m)",
    "y": " fırtına"
  },
  {
    "x": "vaûvirrike (m)",
    "y": " b. vaûvirike (m)"
  },
  {
    "x": "vaûvirriki (m)",
    "y": " b. vaûvirike (m)"
  },
  {
    "x": "vaverroginayîş (n)",
    "y": " yellenme, hava kaçırma, osurma"
  },
  {
    "x": "vawetiş (n)",
    "y": " b. vawitiş (n)"
  },
  {
    "x": "vawitene",
    "y": " 1)örmek \r\n~\r\n~Gewreyî/kurikî/puçî vawitî. (Çoraplar örmüş.)\r\n~2)gözemek, örmek (kumaş ve örgülerdeki delikleri elde iplikle besleyerek kapatmak)"
  },
  {
    "x": "vawitiş (n)",
    "y": " 1)örme\r\n~2)gözeme, örme (kumaş ve örgülerdeki delikleri elde iplikle besleyerek kapatma)"
  },
  {
    "x": "vawitox, -e",
    "y": " örgücü"
  },
  {
    "x": "vawr (m)",
    "y": " b. vewre (m)"
  },
  {
    "x": "vawrbelek",
    "y": " b. vewrbelek"
  },
  {
    "x": "vawri (m)",
    "y": " b. vewre (m)"
  },
  {
    "x": "vawribe (m)",
    "y": " b. vewrribe (m)"
  },
  {
    "x": "vawribi (m)",
    "y": " b. vewrribe (m)"
  },
  {
    "x": "vawriki (m)",
    "y": " b. gudvewre (m), vewrgude (m)"
  },
  {
    "x": "vawrî (m)",
    "y": " b. vewre (m)"
  },
  {
    "x": "vawrîv (m)",
    "y": " b. vewrribe (m)"
  },
  {
    "x": "vay, -e-I",
    "y": " pahalı\r\n~Na saete vay a. (Bu saat pahalı.)\r\n~No paç zaf vay o. (Bu gömlek çok pahalı.)"
  },
  {
    "x": "vay-II (m)",
    "y": " b. vaya (m)"
  },
  {
    "x": "vay-III (m)",
    "y": " b. vaye (m)"
  },
  {
    "x": "vaya-I (m)",
    "y": " 1)fiyat, paha \r\n~Vayaya nê kitabî çend a? (Bu kitabın fiyatı ne kadar?)\r\n~vayayê erjnayîşê pêşnîyaze (zh): teklif değerlendirme fiyatları\r\n~2)bedel"
  },
  {
    "x": "vayayê erjnayîşê pêşnîyaze (zh)",
    "y": " teklif değerlendirme fiyatları"
  },
  {
    "x": "vaya-II (m)",
    "y": " b. vaye-I (m)"
  },
  {
    "x": "vaye-I (m)",
    "y": " ark, hark"
  },
  {
    "x": "vaye-II (m)",
    "y": " b. vaya-I (m)"
  },
  {
    "x": "vayeke (m)",
    "y": " karık (küçük ark)"
  },
  {
    "x": "vayeki (m)",
    "y": " b. vayeke"
  },
  {
    "x": "vayey (m)",
    "y": " b. vayîye (m)"
  },
  {
    "x": "vayê (m)",
    "y": " b. vayî (m)"
  },
  {
    "x": "vayêne (m)",
    "y": " b. vayênî (m)"
  },
  {
    "x": "vayênî (m)",
    "y": " pahalılık"
  },
  {
    "x": "vayi (m)",
    "y": " b. vaye (m)"
  },
  {
    "x": "vayin, -e",
    "y": " romatizmalı"
  },
  {
    "x": "vayin bîyene",
    "y": " romatizmalı olmak"
  },
  {
    "x": "vayî (m)",
    "y": " 1)b. vaya (m)\r\n~2) pahalılık"
  },
  {
    "x": "vayîverkewtiş (n)",
    "y": " kösnüme (dişi köpek vb. için)"
  },
  {
    "x": "vayîye (m)",
    "y": " pahalılık"
  },
  {
    "x": "vayret (n)",
    "y": " b. warîdat (n)"
  },
  {
    "x": "vayşture (m)",
    "y": " b. vaşturî (m)"
  },
  {
    "x": "vayşturî (m)",
    "y": " b. vaşturî (m)"
  },
  {
    "x": "vaz (n)",
    "y": " b. vezd (n)"
  },
  {
    "x": "vaz ameyîne",
    "y": " caymak, vazgeçmek, vazgelmek"
  },
  {
    "x": "vazameyîş (n)",
    "y": " cayma, vazgeçme, vazgelme"
  },
  {
    "x": "vazd-I (n)",
    "y": " koşma, koşu"
  },
  {
    "x": "vazdayene ser",
    "y": " (boğa, koç, teke vb. erkek hayvanlar için) çiftleşmek "
  },
  {
    "x": "vazd-II (n)",
    "y": " b. vezd (n)"
  },
  {
    "x": "vazdayene",
    "y": " koşmak"
  },
  {
    "x": "vazdayêne",
    "y": " b. vazdayene"
  },
  {
    "x": "vazdayine",
    "y": " b. vazdayene"
  },
  {
    "x": "vazdayîş (n)",
    "y": " koşma"
  },
  {
    "x": "vazdnayene",
    "y": " 1)koşturmak\r\n~2)binek hayvanına binmiş olarak onu koşturmak"
  },
  {
    "x": "vazdnayîş (n)",
    "y": " koşturmaven"
  },
  {
    "x": "vaznayene",
    "y": " b. vazdnayene"
  },
  {
    "x": "vaznayîş (n)",
    "y": " b. vazdnayîş (n)"
  },
  {
    "x": "vazo (n)",
    "y": " vazo"
  },
  {
    "x": "vazo (m)",
    "y": " b. vazo (n)"
  },
  {
    "x": "ve",
    "y": " b. bi"
  },
  {
    "x": "ve ... ra",
    "y": " b. bi ... ra"
  },
  {
    "x": "ve ... ya",
    "y": " b. bi ... wa"
  },
  {
    "x": "ve",
    "y": " b. ver-I, ver-III"
  },
  {
    "x": "veciqnayene",
    "y": " (hayvan veya insan için) ürkütmek"
  },
  {
    "x": "veciqnayeni",
    "y": " b. veciqnayene"
  },
  {
    "x": "veciqnîyayene",
    "y": " (hayvan veya insan için) ürkütülmek"
  },
  {
    "x": "veciqnîyayeni",
    "y": " b. veciqnîyayene"
  },
  {
    "x": "vecîyayene",
    "y": " b. vejîyayene"
  },
  {
    "x": "vecîyayêne",
    "y": " b. vejîyayene"
  },
  {
    "x": "vecîyayîş (n)",
    "y": " b. vejîyayîş (n)"
  },
  {
    "x": "vecyayîş (n)",
    "y": " b. vejîyayîş (n)"
  },
  {
    "x": "vejeyîş (n)",
    "y": " b. vejîyayîş (n)"
  },
  {
    "x": "vejîyaya (m)",
    "y": " çıktı (ticaret için)"
  },
  {
    "x": "vejîyaye (n)",
    "y": " 1)çıkan (mat)\r\n~2)çıktı (ticaret için)"
  },
  {
    "x": "vejîyayene",
    "y": " 1)çıkmak\r\n~2)doğmak (güneş, ay ve yıldızlar için)"
  },
  {
    "x": "vejîyayene ser",
    "y": " (boğa, koç, teke vb. erkek hayvanlar için) çiftleşmek"
  },
  {
    "x": "vejîyayene teneffus",
    "y": " teneffüse çıkmak"
  },
  {
    "x": "vejîyayêne",
    "y": " b. vejîyayene"
  },
  {
    "x": "vejîyayîş (n)",
    "y": " 1)çıkma\r\n~2)çıkış"
  },
  {
    "x": "vejîyayîye (m)",
    "y": " çıktı (ticaret için)"
  },
  {
    "x": "vejyayîş (n)",
    "y": " b. vejîyayîş (n)"
  },
  {
    "x": "vekgem (n)",
    "y": " b. fekgem (n)"
  },
  {
    "x": "vela bîyayene",
    "y": " b. vila bîyene"
  },
  {
    "x": "vela bîyene",
    "y": " b. vila bîyene"
  },
  {
    "x": "vela kerdene",
    "y": " b. vila kerdene"
  },
  {
    "x": "velabîyaya (m)",
    "y": " b. vilabîyaya (m)"
  },
  {
    "x": "velabîyaye (n)",
    "y": " b. vilabîyaye (n)"
  },
  {
    "x": "velabîyayê (m)",
    "y": " b. vilabîyayî (m)"
  },
  {
    "x": "velabîyayîş (n)",
    "y": " b. vilabîyayîş (n)"
  },
  {
    "x": "velakerda (m)",
    "y": " b. vilakerda (m)"
  },
  {
    "x": "velakerde (n)",
    "y": " b. vilakerde (n)"
  },
  {
    "x": "velakerdê (m)",
    "y": " b. vilakerdî (m)"
  },
  {
    "x": "velakewta (m)",
    "y": " b. vilakewta (m)"
  },
  {
    "x": "velakewte (n)",
    "y": " b. vilakewte (n)"
  },
  {
    "x": "velay bîyene",
    "y": " b. vila bîyene"
  },
  {
    "x": "velay kerdene",
    "y": " b. vila kerdene"
  },
  {
    "x": "velaybîyaya (m)",
    "y": " b. vilabîyaya (m)"
  },
  {
    "x": "velaybîyaye (n)",
    "y": " b. vilabîyaye (n)"
  },
  {
    "x": "velaybîyayîş (n)",
    "y": " b. vilabîyayîş (n)"
  },
  {
    "x": "velaykerda (m)",
    "y": " b. vilakerda (m)"
  },
  {
    "x": "velaykerde (n)",
    "y": " b. vilakerde (n)"
  },
  {
    "x": "velaykowta (m)",
    "y": " b. vilakewta (m)"
  },
  {
    "x": "velaykowte (n)",
    "y": " b. vilakewte (n)"
  },
  {
    "x": "velg-I (n)",
    "y": " 1)yapraklar (ağaç yaprakları)\r\n~2)keçi vb. hayvanların yemesi için kesilip kışa saklanan ağaç yaprakları"
  },
  {
    "x": "velg birnayene",
    "y": " hayvanların yemesi için kışlık yaprak kesmek"
  },
  {
    "x": "velg cendene",
    "y": " b. velg birnayene"
  },
  {
    "x": "velg dêze kerdene",
    "y": " hayvanların yemesi için kışlık yaprak istiflemek"
  },
  {
    "x": "velg lîcnayene",
    "y": " b. velg nijnayene"
  },
  {
    "x": "velg lode kerdene",
    "y": " hayvanların yemesi için kışlık yaprak istiflemek"
  },
  {
    "x": "velg maxe kerdene",
    "y": " hayvanların yemesi için kışlık yaprak istiflemek"
  },
  {
    "x": "velg necaynayene",
    "y": " b. velg nijnayene"
  },
  {
    "x": "velg nicnayene",
    "y": " b. velg nijnayene"
  },
  {
    "x": "velg nijînayene",
    "y": " b. velg nijnayene"
  },
  {
    "x": "velg nijnayene",
    "y": " hayvanların yemesi için kışlık yaprak istiflemek"
  },
  {
    "x": "velg niznayene",
    "y": " b. velg nijnayene"
  },
  {
    "x": "velg nîjnayene",
    "y": " b. velg nijnayene"
  },
  {
    "x": "velg nîznayêne",
    "y": " b. velg nijnayene"
  },
  {
    "x": "velg qor kerdene",
    "y": " hayvanların yemesi için kışlık yaprak istiflemek"
  },
  {
    "x": "velg ronayene",
    "y": " hayvanların yemesi için kışlık yaprak istiflemek"
  },
  {
    "x": "velg-II (m)",
    "y": " b. velke (m)"
  },
  {
    "x": "velgca (n)",
    "y": " kışın hayvanlara yedirilecek olan yaprağın kesildiği yer"
  },
  {
    "x": "velgdan (n)",
    "y": " kesilip kışın hayvanlara yedirilecek olan yaprağın bol olduğu ağaçlı yer"
  },
  {
    "x": "velgecen (n)",
    "y": " b. velgca (n)"
  },
  {
    "x": "velgedan (n)",
    "y": " b. velgdan (n)"
  },
  {
    "x": "velgedare (m)",
    "y": " hayvanlara kışın yedirilmek üzere kesilip ağacın çatallı ana dalları arasına dizilen yaprak yığını"
  },
  {
    "x": "velgêre (m)",
    "y": " hayvanlara kışın yedirilmek üzere kesilip ağacın çatallı ana dalları arasına dizilen yaprak yığını"
  },
  {
    "x": "velgin, -e",
    "y": " yapraklı"
  },
  {
    "x": "velgini (m)",
    "y": " b. velgine (m)"
  },
  {
    "x": "velik (n)",
    "y": " b. velke (m)"
  },
  {
    "x": "velikê peyîke (n)",
    "y": " baldırın arka kısmı"
  },
  {
    "x": "velikê qorrî (n)",
    "y": " uyluğun arka kısmı "
  },
  {
    "x": "velk (n)",
    "y": " b. velke (m)"
  },
  {
    "x": "velke (m)",
    "y": " böbrek"
  },
  {
    "x": "velki (m)",
    "y": " b. velke (m)"
  },
  {
    "x": "veluk (n)",
    "y": " b. velke (m)"
  },
  {
    "x": "velvele (n)",
    "y": " b. welwele (n)"
  },
  {
    "x": "ven (n, nm)",
    "y": " b. veng (n, nm)"
  },
  {
    "x": "ven dayene",
    "y": " b. veng dayene"
  },
  {
    "x": "vena (m)",
    "y": " yabani güvercin, gri renkli güvercin"
  },
  {
    "x": "venc (n, nm)",
    "y": " b. veng (n, nm)"
  },
  {
    "x": "vendayîş (n)",
    "y": " b. vengdayîş (n)"
  },
  {
    "x": "venderda (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "venderde (n )",
    "y": " b. vindete (n)"
  },
  {
    "x": "venderdeni",
    "y": " b. vindetene"
  },
  {
    "x": "venderdê (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "venderdiş (n)",
    "y": " b. vindetiş (n)"
  },
  {
    "x": "venderdî (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "venderdîye (m)",
    "y": " b. vindetîye (m)"
  },
  {
    "x": "venerta (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "venerte (n)",
    "y": " b. vindete (n)"
  },
  {
    "x": "venertene",
    "y": " b. vindetene"
  },
  {
    "x": "venertê (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "venertiş (n)",
    "y": " b. vindetiş (n)"
  },
  {
    "x": "venertî (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "venertîye (m)",
    "y": " b. vindetîye (m)"
  },
  {
    "x": "Venezuela (m)",
    "y": " Venezuela"
  },
  {
    "x": "veng-I (n, nm)",
    "y": " ses"
  },
  {
    "x": "veng dayene",
    "y": " 1)çağırmak\r\n~2)ötmek (horoz için)\r\n~3)yankı yapmak"
  },
  {
    "x": "veng kewtene",
    "y": " sesi kısılmak "
  },
  {
    "x": "veng kotene",
    "y": " b. veng kewtene"
  },
  {
    "x": "veng piro ginayene",
    "y": " yellenmek, hava kaçırmak, osurmak"
  },
  {
    "x": "veng ra ci kewtene",
    "y": " 1)ses çıkarmak\r\n~2)yellenmek, hava kaçırmak, osurmak"
  },
  {
    "x": "veng vetene",
    "y": " 1)ses çıkarmak\r\n~2)yellenmek, hava kaçırmak, osurmak"
  },
  {
    "x": "vengdayena melayî (m)",
    "y": " ezan"
  },
  {
    "x": "vengdayîşê melayî (n)",
    "y": " ezan okuma, ezan"
  },
  {
    "x": "veng-vaj (n)",
    "y": " b. vengûvaj (n)"
  },
  {
    "x": "veng, -e-II (s)",
    "y": " boş"
  },
  {
    "x": "veng bîyayîne",
    "y": " b. veng bîyene"
  },
  {
    "x": "veng bîyene",
    "y": " boşalmak, tahliye edilmek"
  },
  {
    "x": "veng kerdene",
    "y": " boşaltmak, tahliye etmek"
  },
  {
    "x": "veng ra",
    "y": " beyhude, abes"
  },
  {
    "x": "veng vetene",
    "y": " ağız kavgası yapmak"
  },
  {
    "x": "vengê barmî (n)",
    "y": " b. vengê bermî (n)"
  },
  {
    "x": "vengê bermî (n)",
    "y": " ağlama sesi"
  },
  {
    "x": "veng-vaj (n)",
    "y": " b. vengûvaj (n)"
  },
  {
    "x": "veng-varit, -e",
    "y": " bomboş"
  },
  {
    "x": "veng-vart, -e",
    "y": " b. veng-varit, -e"
  },
  {
    "x": "veng-veng",
    "y": " 1)boş boş\r\n~2)abes"
  },
  {
    "x": "vengandaya (m)",
    "y": " tanınmış, ünlü"
  },
  {
    "x": "vengandaye (n)",
    "y": " tanınmış, ünlü"
  },
  {
    "x": "vengane-I (n)",
    "y": " boşluk"
  },
  {
    "x": "venganeyê hoka ewnîyayîşî (sn)",
    "y": " bakış açısı boşluğu"
  },
  {
    "x": "venganeyê hoka nîyadayîşî (sn)",
    "y": " bakış açısı boşluğu"
  },
  {
    "x": "vengane-II (n)",
    "y": " gürültü"
  },
  {
    "x": "venganlî, -ye",
    "y": " b. vengandaya (m), vengandaye (n)"
  },
  {
    "x": "vengbîyayîş (n)",
    "y": " boşalma, deşarj, tahliye edilme"
  },
  {
    "x": "vengdayîş (n)",
    "y": " 1)çağırma, çağrı\r\n~2)ötme (horoz için)\r\n~3)yankı yapma"
  },
  {
    "x": "vengdayîşê pêşnîyaze (n)",
    "y": " teklif çağrısı"
  },
  {
    "x": "vengdayîşê teklîfî (n)",
    "y": " teklif çağrısı"
  },
  {
    "x": "vengin, -e",
    "y": " sesli, ünlü"
  },
  {
    "x": "herfa vengine (m)",
    "y": " sesli harf, ünlü harf"
  },
  {
    "x": "vengkar, -e (sn)",
    "y": " ses yapımcısı, sesçi"
  },
  {
    "x": "vengker (n)",
    "y": " boşaltıcı"
  },
  {
    "x": "vengkerdiş (n)",
    "y": " boşaltma, tahliye, tahliye etme, boşaltım"
  },
  {
    "x": "vengkewtiş (n)",
    "y": " ses kısıklığı, ses kısılması"
  },
  {
    "x": "vengkotiş (n)",
    "y": " b. vengkewtiş (n)"
  },
  {
    "x": "vengok (n)",
    "y": " ünlem"
  },
  {
    "x": "vengone (n)",
    "y": " b. vengane (n)"
  },
  {
    "x": "vengpêdayîş (n)",
    "y": " seslendirme, efekt"
  },
  {
    "x": "vengpêdayox, -e (sn)",
    "y": " seslendirici"
  },
  {
    "x": "vengpiroginayîş (n)",
    "y": " yellenme, hava kaçırma, osurma"
  },
  {
    "x": "vengracikewtiş (n)",
    "y": " 1)ses çıkarma\r\n~2)yellenme, hava kaçırma, osurma"
  },
  {
    "x": "vengûne (n) ",
    "y": " b. vengane (n)"
  },
  {
    "x": "vengûvahart, -e",
    "y": " b. vengûvarit, -e, veng-varit, -e"
  },
  {
    "x": "vengûvaj (n)",
    "y": " 1)ses seda\r\n~2)haber"
  },
  {
    "x": "vengûvarit, -e",
    "y": " bomboş"
  },
  {
    "x": "vengûvart, -e",
    "y": " b. vengûvarit, -e"
  },
  {
    "x": "vengûvaz (n)",
    "y": " b. vengûvaj (n)"
  },
  {
    "x": "vengvetiş (n)",
    "y": " 1)ses çıkarma\r\n~2)ağız kavgası yapma\r\n~3)yellenme, hava kaçırma, osurma"
  },
  {
    "x": "ver-I (n)",
    "y": " 1)ön, huzur\r\n~2)yaka, kıyı, yan, sahil\r\n~verê çemî (n): akarsu kıyısı, nehir kıyısı\r\n~Ez şîya verê ey. (Onun yanına gittim.)\r\n~3)ekin veya ot biçerken biçmek için yönelinen ve hedeflenen mesafe\r\n~4)astar"
  },
  {
    "x": "ver a ...",
    "y": " b. ver bi "
  },
  {
    "x": "ver bi ...",
    "y": " -e doğru \r\n~Ez ver bi Dîyarbekirî şina. (Diyarbakır’a doğru gidiyorum.)"
  },
  {
    "x": "ver bi rasteyî (n)",
    "y": " bayır aşağı"
  },
  {
    "x": "ver bi raşteyî",
    "y": " bayır aşağı"
  },
  {
    "x": "ver bi şan",
    "y": " akşama doğru"
  },
  {
    "x": "ver d’ berdene",
    "y": " b. aver berdene"
  },
  {
    "x": "ver d’ şîyene",
    "y": " b. aver şîyene"
  },
  {
    "x": "ver ... dayene",
    "y": " bulaştırmak (hastalık için)"
  },
  {
    "x": "ver ... dayîş",
    "y": " bulaştırma (hastalık için)"
  },
  {
    "x": "ver de mendene",
    "y": " kekelemek, kekeme olmak, kekeç olmak"
  },
  {
    "x": "ver di berdene",
    "y": " b. aver berdene"
  },
  {
    "x": "ver di şîyene",
    "y": " b. aver şîyene"
  },
  {
    "x": "ver kewtene",
    "y": " üzülmek"
  },
  {
    "x": "ver mosla dayene",
    "y": " altına kaçırmak, altına etmek, altına yapmak (çok korkmak)"
  },
  {
    "x": "ver pê dayene",
    "y": " çarpıştırmak\r\n~Girewtî ver pê day. (Alıp çarpıştırdı./Alıp birbirine çarptı.)"
  },
  {
    "x": "ver ra mendene",
    "y": " artmak (bir miktar geri kalma)"
  },
  {
    "x": "ver ro ameyene",
    "y": " çemkirmek, sert cevap vermek"
  },
  {
    "x": "ver têmîyan dayene",
    "y": " karıştırmak (birbirine karıştırmak, birbirine katmak)"
  },
  {
    "x": "ver têmîyan dîyayene",
    "y": " 1)karıştırılmak (birbirine karıştırılmak)\r\n~2)karışmak (birbirine karışmak)"
  },
  {
    "x": "ver têmîyon dayene",
    "y": " b. ver têmîyan dayene"
  },
  {
    "x": "ver têmîyon dîyayene",
    "y": " b. ver têmîyan dîyayene"
  },
  {
    "x": "ver têmîyûn dayene",
    "y": " b. ver têmîyan dayene"
  },
  {
    "x": "ver têmîyûn dîyayene",
    "y": " b. ver têmîyan dîyayene"
  },
  {
    "x": "ver va ...",
    "y": " b. ver bi ..."
  },
  {
    "x": "verê berzînî (n)",
    "y": " baş ucu"
  },
  {
    "x": "verê berzînî de",
    "y": " baş ucunda"
  },
  {
    "x": "verê ... dayene",
    "y": " yönlendirmek, kanalize etmek\r\n~Verê karkeran da patronî./Verê karkeran raçarna patronî. (İşçileri patrona yönlendirdi.)"
  },
  {
    "x": "verê ... dayîş",
    "y": " yönlendirme, kanalize etme, oryantasyon"
  },
  {
    "x": "verê fekî",
    "y": " dopdolu, ağzına kadar"
  },
  {
    "x": "verê kêberî/çêverî de kemere ronayene (elewîyîye de)",
    "y": " düşkün ilan etmek (Alevilikte), aforoz etmek"
  },
  {
    "x": "verê kêberî/çêverî de kemereronayîş (n)",
    "y": " düşkün ilan etme (Alevilikte), aforoz etme"
  },
  {
    "x": "verê ... raçarnayîş",
    "y": " yönlendirme, kanalize etme, oryantasyon"
  },
  {
    "x": "verê serî (n)",
    "y": " baş ucu"
  },
  {
    "x": "verê serî de",
    "y": " baş ucunda"
  },
  {
    "x": "verê sodirî (n)",
    "y": " b. verê şodirî (n)"
  },
  {
    "x": "verê şodirî (n)",
    "y": " şafak vakti"
  },
  {
    "x": "verê veran",
    "y": " ilkönce, en önce"
  },
  {
    "x": "verê verkan",
    "y": " ilkönce, en önce"
  },
  {
    "x": "verê verkon",
    "y": " b. verê verkan"
  },
  {
    "x": "verê verkûn",
    "y": " b. verê verkan"
  },
  {
    "x": "verê veron",
    "y": " b. verê veran"
  },
  {
    "x": "verê verûn",
    "y": " b. verê veran"
  },
  {
    "x": "verî fekî",
    "y": " b. verê fekî"
  },
  {
    "x": "verî sarî (n)",
    "y": " b. verê serî (n)"
  },
  {
    "x": "verî sarî de",
    "y": " b. verê serî de"
  },
  {
    "x": "verê çemî (n)",
    "y": " akarsu kıyısı, nehir kıyısı"
  },
  {
    "x": "verê robarî (n)",
    "y": " akarsu kıyısı, nehir kıyısı"
  },
  {
    "x": "verê royî (n)",
    "y": " akarsu kıyısı, nehir kıyısı\r\n~\t"
  },
  {
    "x": "ver-II (m)",
    "y": " b. vere-I (m)"
  },
  {
    "x": "ver eştene",
    "y": " b. vere eştene"
  },
  {
    "x": "verê mar (m)",
    "y": " b. vera marî (m)"
  },
  {
    "x": "verê marî (m)",
    "y": " b. vera marî (m)"
  },
  {
    "x": "ver-III",
    "y": " nedeniyle, sebebiyle, yüzünden, dolayı, ötürü"
  },
  {
    "x": "ver-IV",
    "y": " b. ber-IV"
  },
  {
    "x": "ver eştene",
    "y": " b. ber eştene"
  },
  {
    "x": "ver ginayene",
    "y": " (keçi için) çiftleşmek"
  },
  {
    "x": "ver şîyene",
    "y": " (insan için) düşük yapmak"
  },
  {
    "x": "vera-I",
    "y": " karşı, karşısında\r\n~Vera mi mevinde! (Karşımda durma!)"
  },
  {
    "x": "vera vindetene",
    "y": " çemkirmek, sert cevap vermek"
  },
  {
    "x": "vera ci",
    "y": " bilmukabele"
  },
  {
    "x": "vera-II (m)",
    "y": " b. vereke (m)"
  },
  {
    "x": "vera-III",
    "y": " b. ver bi"
  },
  {
    "x": "vera-vera",
    "y": " giderek, gittikçe, yavaş yavaş"
  },
  {
    "x": "veracê (n)",
    "y": " halay"
  },
  {
    "x": "veracêr (n)",
    "y": " aşağıya doğru, iniş"
  },
  {
    "x": "veracîyer (n)",
    "y": " b. veracêr (n)"
  },
  {
    "x": "veracor (n)",
    "y": " yukarıya doğru, yokuş"
  },
  {
    "x": "veracuar (n)",
    "y": " b. veracor (n)"
  },
  {
    "x": "verad-I (n)",
    "y": " b. verarde-I (m)"
  },
  {
    "x": "verad-II (m)",
    "y": " b. verarde-I (m)"
  },
  {
    "x": "verad-III (m)",
    "y": " b. verardî (m)"
  },
  {
    "x": "veradaya (m)",
    "y": " 1)başıboş, serseri\r\n~2)boşanmış kişi"
  },
  {
    "x": "veradaye (n)",
    "y": " 1)başıboş, serseri\r\n~2)boşanmış kişi"
  },
  {
    "x": "veradayene",
    "y": " 1)bırakmak\r\n~2)salıvermek, tahliye etmek\r\n~3)boşamak"
  },
  {
    "x": "heywan veradayene",
    "y": " otlaması için hayvanı araziye başıboş salmak"
  },
  {
    "x": "veradayena şertine (m)",
    "y": " şartlı salıvermek"
  },
  {
    "x": "veradayî, -ye",
    "y": " 1)başıboş, serseri\r\n~2)boşanmış kişi"
  },
  {
    "x": "veradayîş (n)",
    "y": " 1)bırakma\r\n~2)salıverme, tahliye, tahliye etme\r\n~3)boşama"
  },
  {
    "x": "veradayîşo şertin (n)",
    "y": " şartlı salıverme, şartlı tahliye"
  },
  {
    "x": "veradayîye (m)",
    "y": " serseri"
  },
  {
    "x": "veradayîyey (m)",
    "y": " b. veradayîyîye (m)"
  },
  {
    "x": "veradayîyê (m)",
    "y": " b. veradayîyî (m)"
  },
  {
    "x": "veradayîyî (m)",
    "y": " serserilik"
  },
  {
    "x": "veradayîyîye (m)",
    "y": " serserilik"
  },
  {
    "x": "verade (m)",
    "y": " b. verardî (m)"
  },
  {
    "x": "veradi-I (m)",
    "y": " b. verarde-I (m)"
  },
  {
    "x": "veradi-II(m)",
    "y": " b. verardî (m)"
  },
  {
    "x": "veradî (m)",
    "y": " b. verardî (m)"
  },
  {
    "x": "veradîyayene",
    "y": " 1)bırakılmak\r\n~2)salıverilmek, tahliye edilmek\r\n~3)boşanmak"
  },
  {
    "x": "veradîyayîş (n)",
    "y": " 1)bırakılma\r\n~2)salıverilme, tahliye edilme\r\n~3)boşanma"
  },
  {
    "x": "veraj (n)",
    "y": " yokuş"
  },
  {
    "x": "verajo qij (n)",
    "y": " bayır"
  },
  {
    "x": "verapey",
    "y": " ters\r\n~Kêneke pirênê xo verapey xo ra dayo. (Kız entarisini ters giymiş.)"
  },
  {
    "x": "verapê",
    "y": " b. verapey"
  },
  {
    "x": "verar kerdene",
    "y": " b. xo verare kerdene"
  },
  {
    "x": "verar pira şîyayîne",
    "y": " b. verare pira şîyene "
  },
  {
    "x": "verara yewbînî de",
    "y": " kucak kucağa"
  },
  {
    "x": "verard-I (n)",
    "y": " b. verarde-I (m)"
  },
  {
    "x": "verard-II (m)",
    "y": " b. verardî (m)"
  },
  {
    "x": "verard kerdene",
    "y": " b. xo verardî kerdene"
  },
  {
    "x": "verarde-I (m)",
    "y": " yamaç"
  },
  {
    "x": "verarde-II (n)",
    "y": " b. verarde-I (m)"
  },
  {
    "x": "verardî (m)",
    "y": " 1)kucak\r\n~2)kucak (bir kucak odun vb.)\r\n~3)kulaç"
  },
  {
    "x": "verardkerdiş (n)",
    "y": " b. xoverardîkerdiş (n)"
  },
  {
    "x": "verardkî",
    "y": " yatay"
  },
  {
    "x": "verare (m)",
    "y": " 1)kucak \r\n~2)kucak (bir kucak odun vb.)\r\n~verarê îzimî: bir kucak odun\r\n~verarê kolî: bir kucak odun\r\n~3)kulaç"
  },
  {
    "x": "verare pira fîştene",
    "y": " kucaklamak"
  },
  {
    "x": "verare pira şîyene",
    "y": " kucaklamak\r\n~Senî ke o dî verare şî pira. (Onu görür görmez kucakladı.) "
  },
  {
    "x": "verare tira fîştene",
    "y": " kucaklamak"
  },
  {
    "x": "verare tira kerdene",
    "y": " kucaklamak\r\n~Şî ey het û verare kerde tira. (Onun yanına gitti ve kucakladı.)\t"
  },
  {
    "x": "verare tira şîyene",
    "y": " kucaklamak"
  },
  {
    "x": "verarê îzimî",
    "y": " bir kucak odun"
  },
  {
    "x": "verarê kolî",
    "y": " bir kucak odun"
  },
  {
    "x": "verarepirafîştiş (n)",
    "y": " kucaklama"
  },
  {
    "x": "verarepiraşîyayîş (n)",
    "y": " kucaklama"
  },
  {
    "x": "veraretirafîştiş (n)",
    "y": " kucaklama"
  },
  {
    "x": "veraretirakerdiş (n)",
    "y": " kucaklama"
  },
  {
    "x": "veraretiraşîyayîş (n)",
    "y": " kucaklama"
  },
  {
    "x": "verari (m)",
    "y": " b. verare (m)"
  },
  {
    "x": "verarkerdiş (n)",
    "y": " b. xoverarekerdiş (n)"
  },
  {
    "x": "verarojik (n)",
    "y": " b. verrojik (n)"
  },
  {
    "x": "verarpiraşîyayîş (n)",
    "y": " b. verarepiraşîyayîş (n)"
  },
  {
    "x": "veraruec (n)",
    "y": " b. verroj (n)"
  },
  {
    "x": "veraruecik (m)",
    "y": " b. verrojik (n)"
  },
  {
    "x": "veraruej (n)",
    "y": " b. verroj (n)"
  },
  {
    "x": "verasan (n)",
    "y": " b. veraşan (n)"
  },
  {
    "x": "verasanî (m)",
    "y": " b. veraşan (n)"
  },
  {
    "x": "verasnî (m)",
    "y": " b. versî (m)"
  },
  {
    "x": "verason (n)",
    "y": " b. veraşan (n)"
  },
  {
    "x": "verasû (n)",
    "y": " b. veraşan (n)"
  },
  {
    "x": "verasûn (n)",
    "y": " b. veraşan (n)"
  },
  {
    "x": "veraşan (n)",
    "y": " akşama doğru, akşamüstü, akşamüzeri, ikindinin son anları"
  },
  {
    "x": "veraşanî (m)",
    "y": " b. veraşan (n)"
  },
  {
    "x": "veraşon (n)",
    "y": " b. veraşan (n)"
  },
  {
    "x": "veraşûn (n)",
    "y": " b. veraşan (n)"
  },
  {
    "x": "veraştene",
    "y": " b. viraştene"
  },
  {
    "x": "veraşûnî (m)",
    "y": " b. veraşan (n)"
  },
  {
    "x": "veravindetiş (n)",
    "y": " çemkirme, çemkiriş"
  },
  {
    "x": "verba-I (n)",
    "y": " harman savrulurken biriken çok ince saman"
  },
  {
    "x": "verba-II",
    "y": " karşı, karşısında\r\n~Verba mi vindeto qisey keno. (Karşımda durmuş konuşuyor.)"
  },
  {
    "x": "verbend (n)",
    "y": " palanın geri kaymasını önlemek için ön kısmına bağlanan ip"
  },
  {
    "x": "verbendik (n)",
    "y": " önlük"
  },
  {
    "x": "vercêr (n)",
    "y": " b. veracêr (n)"
  },
  {
    "x": "vercik (n)",
    "y": " b. verrojik (n), verroj (n)"
  },
  {
    "x": "vercor (n)",
    "y": " b. veracor (n)"
  },
  {
    "x": "verçimikî (zh)",
    "y": " gözlük"
  },
  {
    "x": "verçimikkar, -e",
    "y": " gözlükçü"
  },
  {
    "x": "verd eştene",
    "y": " b. ber eştene"
  },
  {
    "x": "verdaya (m)",
    "y": " boşanmış kişi"
  },
  {
    "x": "verdaye (n)",
    "y": " boşanmış kişi"
  },
  {
    "x": "verdayene",
    "y": " 1)bırakmak\r\n~2)boşamak"
  },
  {
    "x": "verdayî, -ye",
    "y": " boşanmış kişi"
  },
  {
    "x": "verdayîş (n)",
    "y": " 1)bırakma\r\n~2)boşama"
  },
  {
    "x": "verde",
    "y": " b. verê-I"
  },
  {
    "x": "verdemendiş (n)",
    "y": " kekeleme, kekeme olma, kekeç olma"
  },
  {
    "x": "verdest, -e",
    "y": " hizmetçi"
  },
  {
    "x": "verdestîye (m)",
    "y": " ev işlerini yapmakla görevli olan kuma"
  },
  {
    "x": "verdeyîye (m)",
    "y": " öncelik"
  },
  {
    "x": "verdeyîyî (zh)",
    "y": " öncelikler"
  },
  {
    "x": "verdibistane (n)",
    "y": " anaokulu, ana mektebi"
  },
  {
    "x": "verdîyayene",
    "y": " 1)bırakılmak\r\n~2)boşanmak"
  },
  {
    "x": "verdîyayîş (n)",
    "y": " 1)bırakılma\r\n~2)boşanma"
  },
  {
    "x": "vere-I (m)",
    "y": " kav (yılan gömleği, yılan derisi)"
  },
  {
    "x": "vera marî (m)",
    "y": " kav (yılan gömleği, yılan derisi)"
  },
  {
    "x": "vere eştene",
    "y": " kavlamak (yılan kavı için)"
  },
  {
    "x": "vere fîştene",
    "y": " kavlamak (yılan kavı için)"
  },
  {
    "x": "vere-II (n)",
    "y": " b. verek (n)"
  },
  {
    "x": "veredat (n) ",
    "y": "1)önedat\r\n~2)edat, ilgeç"
  },
  {
    "x": "veredaya (m)",
    "y": " b. veradaya (m)"
  },
  {
    "x": "veredaye (n)",
    "y": " b. veradaye (n)"
  },
  {
    "x": "veredayene",
    "y": " b. veradayene"
  },
  {
    "x": "veredayîş (n)",
    "y": " b. veradayîş (n)"
  },
  {
    "x": "vereeştiş (n)",
    "y": " kavlama (yılan kavı için)"
  },
  {
    "x": "verefîştiş (n)",
    "y": " kavlama (yılan kavı için)"
  },
  {
    "x": "verek, -e",
    "y": " kuzu"
  },
  {
    "x": "verek ardene",
    "y": " (koyun için) doğurmak, kuzulamak, kuzu doğurmak"
  },
  {
    "x": "verek ardeni",
    "y": " b. verek ardene"
  },
  {
    "x": "verek eştene",
    "y": " (koyun için) düşük yapmak"
  },
  {
    "x": "verekwan, -e",
    "y": " kuzu çobanı"
  },
  {
    "x": "verekwanî (m)",
    "y": "  kuzu çobanlığı   "
  },
  {
    "x": "verekwanîye (m)",
    "y": " kuzu çobanlığı"
  },
  {
    "x": "verendam, -e",
    "y": " aday üye"
  },
  {
    "x": "verendamîye (m)",
    "y": " aday üyelik"
  },
  {
    "x": "verende",
    "y": " b. verê-I"
  },
  {
    "x": "verenî (m)",
    "y": " b. vernî (m)"
  },
  {
    "x": "veresî (m)",
    "y": " b. versî (m)"
  },
  {
    "x": "veresnîye (m)",
    "y": " b. versî (m)"
  },
  {
    "x": "verestî (m)",
    "y": " b. versî (m)"
  },
  {
    "x": "vereşîyayene",
    "y": " b. verişîyayene"
  },
  {
    "x": "ez vereşîya",
    "y": " b. mi verişna"
  },
  {
    "x": "vereşnayene",
    "y": " b. verişnayene"
  },
  {
    "x": "mi vereşna",
    "y": " b. mi verişna"
  },
  {
    "x": "vereşnayîş (n)",
    "y": " b. verişnayîş (n)"
  },
  {
    "x": "vereştiş (n)",
    "y": " b. vereeştiş (n)"
  },
  {
    "x": "verê-I",
    "y": " 1)önce\r\n~2)eskiden, zamanında (eskiden)"
  },
  {
    "x": "ver’ can",
    "y": " b. verê cûyan"
  },
  {
    "x": "ver’ cî",
    "y": " b. verê cû"
  },
  {
    "x": "ver’ con",
    "y": " b. verê cûyan"
  },
  {
    "x": "ver’ coy",
    "y": " b. verê cû "
  },
  {
    "x": "ver’ cû",
    "y": " b. verê cû"
  },
  {
    "x": "verê co",
    "y": " b. verê cû"
  },
  {
    "x": "verê coan",
    "y": " b. verê cûyan"
  },
  {
    "x": "verê cowan",
    "y": " b. verê cûyan"
  },
  {
    "x": "verê cowû",
    "y": " b. verê cûyan"
  },
  {
    "x": "verê coy",
    "y": " b. verê cû"
  },
  {
    "x": "verê coyî",
    "y": " b. verê cû"
  },
  {
    "x": "verê cû",
    "y": " 1)eskiden, zamanında (eskiden)\r\n~2)ondan önce"
  },
  {
    "x": "verê cûyan",
    "y": " eski zamanlarda, eski dönemlerde"
  },
  {
    "x": "verê der",
    "y": " b. verê-2"
  },
  {
    "x": "verê dihîre (n)",
    "y": " öğleden önce"
  },
  {
    "x": "verê joy",
    "y": " b. verê cû"
  },
  {
    "x": "verê joyî",
    "y": " b. verê cû"
  },
  {
    "x": "verê nêmroje (n)",
    "y": " öğleden önce"
  },
  {
    "x": "verê perojî (n)",
    "y": " öğleden önce"
  },
  {
    "x": "verê taştarî (n)",
    "y": " b. verê teştareyî (n)"
  },
  {
    "x": "verê teştareyî (n)",
    "y": " öğleden önce"
  },
  {
    "x": "vero joy",
    "y": " b. verê cû"
  },
  {
    "x": "verû cî",
    "y": " b. verê cû"
  },
  {
    "x": "vêr cîyon",
    "y": " b. verê cûyan"
  },
  {
    "x": "vêr con",
    "y": " b. verê cûyan"
  },
  {
    "x": "vêr cu",
    "y": " b. verê cûyan"
  },
  {
    "x": "vêr cû",
    "y": " b. verê cûyan"
  },
  {
    "x": "verê-II",
    "y": " 1)başta\r\n~2)b. verên (n)"
  },
  {
    "x": "verên, -e",
    "y": " 1)eski\r\n~2)ilk\r\n~3)öndeki, baştaki\r\n~4)önceki, evvelki"
  },
  {
    "x": "verênîya vîyartişî (n)",
    "y": " geçiş üstünlüğü (trafik için)"
  },
  {
    "x": "verênde",
    "y": " b. verê-I"
  },
  {
    "x": "verêney (m)",
    "y": " b. verênî (m)"
  },
  {
    "x": "verêni (m)",
    "y": " b. verêne (m)"
  },
  {
    "x": "verênî (m)",
    "y": " antikite, eskilik"
  },
  {
    "x": "verênîye (m)",
    "y": " antikite, eskilik"
  },
  {
    "x": "verg, -e",
    "y": " kurt"
  },
  {
    "x": "vergi (m)",
    "y": " b. verge (m)"
  },
  {
    "x": "vergir (n)",
    "y": " önek"
  },
  {
    "x": "vergmasa (n)",
    "y": " b. vergmase (n)"
  },
  {
    "x": "vergmase (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "vergolim (n)",
    "y": " kasık"
  },
  {
    "x": "vergul (n)",
    "y": " b. vergolim (n)"
  },
  {
    "x": "verguman, -e",
    "y": " maznun, sanık"
  },
  {
    "x": "verhel (n)",
    "y": " kahvaltı ile öğle yemeği arasında yenen hafif yemek"
  },
  {
    "x": "verhet (n)",
    "y": " (yapılar için) cephe"
  },
  {
    "x": "verhetê parselî (n)",
    "y": " parsel cephesi"
  },
  {
    "x": "veri-I (m)",
    "y": " b. vere-I (m)"
  },
  {
    "x": "veri vistene",
    "y": " b. vere fîştene"
  },
  {
    "x": "veri-II (m)",
    "y": " vereke (m)"
  },
  {
    "x": "veri-III (n)",
    "y": " b. verrî (n)"
  },
  {
    "x": "verik (n)",
    "y": " b. verike (m)"
  },
  {
    "x": "verike (m)",
    "y": " kucak"
  },
  {
    "x": "verika yewbînan de",
    "y": " kucak kucağa"
  },
  {
    "x": "verika yewbînî de",
    "y": " kucak kucağa"
  },
  {
    "x": "veriki (m)",
    "y": " b. verike (m)"
  },
  {
    "x": "verikey yemnon di",
    "y": " b. verika yewbînan de, b. verika yewbînî de"
  },
  {
    "x": "verin, -e",
    "y": " 1)geniş \r\n~2)enli\r\n~qumaşo verin (n): enli kumaş"
  },
  {
    "x": "verinde",
    "y": " b. verê-I"
  },
  {
    "x": "verinîye (m)",
    "y": " 1)genişlik\r\n~2)en (mat)"
  },
  {
    "x": "verinîya parselî (m)",
    "y": " parsel genişliği"
  },
  {
    "x": "verinîya zemînî (m)",
    "y": " taban genişliği"
  },
  {
    "x": "verişîyayene",
    "y": " 1)kusulmak\r\n~2)b. verişnayene"
  },
  {
    "x": "ez verişîya",
    "y": " b. mi verişna"
  },
  {
    "x": "verişnayene",
    "y": " kusmak, istifrağ etmek\r\n~mi verişna: ben kustum"
  },
  {
    "x": "mi verişna",
    "y": " ben kustum"
  },
  {
    "x": "verişnayîş (n)",
    "y": " kusma, istifrağ etme"
  },
  {
    "x": "verite",
    "y": " b. virite (n)"
  },
  {
    "x": "veritine",
    "y": " b. viritene"
  },
  {
    "x": "veritiş (n)",
    "y": " b. viritiş (n)"
  },
  {
    "x": "veritiş ameyene",
    "y": " b. viritiş ameyene"
  },
  {
    "x": "veritiş ardene",
    "y": " b. viritiş ardene"
  },
  {
    "x": "veritişameyîş (n)",
    "y": " b. viritişameyîş (n)"
  },
  {
    "x": "veritişardiş (n)",
    "y": " b. viritişardiş (n)"
  },
  {
    "x": "verivistiş (n)",
    "y": " b. verefîştiş (n)"
  },
  {
    "x": "verî-I",
    "y": " b. verê-I"
  },
  {
    "x": "verî co",
    "y": " b. verê cû"
  },
  {
    "x": "verî coan",
    "y": " b. verê cûyan"
  },
  {
    "x": "verî coy",
    "y": " b. verê cû "
  },
  {
    "x": "verî cû",
    "y": " b. verê cû"
  },
  {
    "x": "verî nîmeroc (n)",
    "y": " b. verê nêmroje (n)"
  },
  {
    "x": "verî nîmrueci (n)",
    "y": " b. verê nêmroje (n)"
  },
  {
    "x": "verî têştarî (n)",
    "y": " b. verê teştareyî (n)"
  },
  {
    "x": "verî vergulû (n)",
    "y": " b. vergolim (n)"
  },
  {
    "x": "verî-II",
    "y": " b. verên, -e"
  },
  {
    "x": "verîn, -e",
    "y": " b. verên, -e"
  },
  {
    "x": "verînê (m)",
    "y": " b. verênî (m)"
  },
  {
    "x": "verînî (m)",
    "y": " b. verênî (m)"
  },
  {
    "x": "verjik (n)",
    "y": " b. verrojik (n), verroj (n)"
  },
  {
    "x": "verkar (n)",
    "y": " taslak"
  },
  {
    "x": "verkewta (m)",
    "y": " üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü"
  },
  {
    "x": "verkewte (n)",
    "y": " üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü"
  },
  {
    "x": "verkewtê (m)",
    "y": " b. verkewta (m), verkewtîye (m)"
  },
  {
    "x": "verkewti (n)",
    "y": " b. verkewte (n)"
  },
  {
    "x": "verkewtiş (n)",
    "y": " üzüntü, üzünç"
  },
  {
    "x": "verkewtîye (m)",
    "y": " üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü"
  },
  {
    "x": "verkole (n)",
    "y": " b. berkole (n)"
  },
  {
    "x": "verkule (n)",
    "y": " b. berkole (n)"
  },
  {
    "x": "vermende (n)",
    "y": " kalıntı"
  },
  {
    "x": "vermeqlub, -e",
    "y": " ters\r\n~Mêrikî şelwarê xo vermeqlub pay kerdê. (Adam şalvarını ters [iç tarafı dışa gelecek biçimde] giymiş.)"
  },
  {
    "x": "vermerc (n)",
    "y": " ön koşul, ön şart"
  },
  {
    "x": "vermudet (n)",
    "y": " ön süre"
  },
  {
    "x": "vernamzed, -e",
    "y": " aday adayı "
  },
  {
    "x": "vernamzedîye (m)",
    "y": " aday adaylığı"
  },
  {
    "x": "vernayene",
    "y": " b. vîyarnayene"
  },
  {
    "x": "vernayîş (n)",
    "y": " b. vîyarnayîş (n)"
  },
  {
    "x": "vernî (m)",
    "y": " ön"
  },
  {
    "x": "vernî girewtene",
    "y": " engel olmak, engellemek, mâni olmak, önlemek"
  },
  {
    "x": "vernî gurotene",
    "y": " b. vernî girewtene"
  },
  {
    "x": "vernî piro girewtene",
    "y": " bastırmak (... önlemek)"
  },
  {
    "x": "vernî ra",
    "y": " baştan (zr)"
  },
  {
    "x": "vernî ra heta peynî",
    "y": " baştan sona (zr)"
  },
  {
    "x": "vernî ra hetanî peynî",
    "y": " baştan sona (zr)"
  },
  {
    "x": "vernîya ... girewtene",
    "y": " bastırmak (... önlemek)"
  },
  {
    "x": "vernîya ... girewtiş",
    "y": " bastırma (önleme)"
  },
  {
    "x": "vernîgirewtiş (n)",
    "y": " engel olma, engelleme, mâni olma, önleme\r\n~vernîgirewtişê gemarinîye (n): kirliliği önleme"
  },
  {
    "x": "vernîgirewtişê gemarinîye (n)",
    "y": " kirliliği önleme"
  },
  {
    "x": "vernîgurotiş (n)",
    "y": " b. vernîgirewtiş (n)"
  },
  {
    "x": "vernîpirogirewtiş (n)",
    "y": " bastırma (önleme)"
  },
  {
    "x": "vernîyîye (m)",
    "y": " öncelik"
  },
  {
    "x": "vernîyîyî (zh)",
    "y": " öncelikler"
  },
  {
    "x": "vero ameyene",
    "y": " itiraz etmek"
  },
  {
    "x": "vero cê",
    "y": " b. verê cû "
  },
  {
    "x": "vero co",
    "y": " b. verê cû "
  },
  {
    "x": "vero cû",
    "y": " b. verê cû "
  },
  {
    "x": "veroameyîş (n)",
    "y": " itiraz etme, itiraz"
  },
  {
    "x": "veroc (n)",
    "y": " b. verroj (n)"
  },
  {
    "x": "verocik (n)",
    "y": " b. verrojik (n), verroj (n)"
  },
  {
    "x": "veroj (n)",
    "y": " b. verroj (n)"
  },
  {
    "x": "verojik (n)",
    "y": " b. verrojik (n), verroj (n)"
  },
  {
    "x": "verpay",
    "y": " ters\r\n~Lajekî solê xo verpay pay kerdê. (Oğlan ayakkabılarını ters giymiş.)"
  },
  {
    "x": "verpêdayîş (n)",
    "y": " çarpıştırma"
  },
  {
    "x": "verproje (n)",
    "y": " ön proje, avan proje"
  },
  {
    "x": "verr (m)",
    "y": " b. vere-I (m)"
  },
  {
    "x": "verrê marî (m)",
    "y": " b. vera marî (m)"
  },
  {
    "x": "verra-verra",
    "y": " b. vera-vera"
  },
  {
    "x": "verradaya (m)",
    "y": " b. veradaya (m)"
  },
  {
    "x": "verradaye (n)",
    "y": " b. veradaye (n)"
  },
  {
    "x": "verradayene",
    "y": " b. veradayene"
  },
  {
    "x": "verradayî (m)",
    "y": " b. veradayî (m)"
  },
  {
    "x": "verradayîş (n)",
    "y": " b. veradayîş (n)"
  },
  {
    "x": "verradayîye (m)",
    "y": " b. veradayîye (m)"
  },
  {
    "x": "verradayîyî (m)",
    "y": " b. veradayîyî (m)"
  },
  {
    "x": "verradayîyîye (m)",
    "y": " b. veradayîyîye (m)"
  },
  {
    "x": "verradi (m)",
    "y": " b. verardî (m)"
  },
  {
    "x": "verradî (m)",
    "y": " b. verardî (m)"
  },
  {
    "x": "verradîyayene",
    "y": " b. veradîyayene"
  },
  {
    "x": "verradîyayîş (n)",
    "y": " b. veradîyayîş (n)"
  },
  {
    "x": "verraj (n)",
    "y": " b. veraj (n)"
  },
  {
    "x": "verramendiş (n)",
    "y": " artma (bir miktar geri kalma)"
  },
  {
    "x": "verre (m)",
    "y": " b. vere-I (m)"
  },
  {
    "x": "verra marî (m)",
    "y": " b. vera marî (m)"
  },
  {
    "x": "verre eştene",
    "y": " b. vere eştene"
  },
  {
    "x": "verreeştiş (n)",
    "y": " b. vereeştiş (n)"
  },
  {
    "x": "verri (m)",
    "y": " b. vere-I (m)"
  },
  {
    "x": "verri vistene",
    "y": " b. vere fîştene"
  },
  {
    "x": "verri (n)",
    "y": " b. verrî (n)"
  },
  {
    "x": "verrivistiş (n)",
    "y": " b. verefîştiş (n)"
  },
  {
    "x": "verrî (n)",
    "y": " elbisenin boyundan göbeğe kadar olan açık kısmı"
  },
  {
    "x": "verroc (n)",
    "y": " b. verroj (n)"
  },
  {
    "x": "verrocik (n)",
    "y": " b. verrojik (n)"
  },
  {
    "x": "verroj (n)",
    "y": " 1)güneç, güneşlik (güneş ışınlarını alan yer), güneşleme yeri\r\n~2)ayçiçeği, gündöndü, günebakan"
  },
  {
    "x": "verrojik (n)",
    "y": " ayçiçeği, gündöndü, günebakan"
  },
  {
    "x": "verroz (n)",
    "y": " b. veraj (n)"
  },
  {
    "x": "verru (n)",
    "y": " b. verrî (n)"
  },
  {
    "x": "verrûj (n)",
    "y": " b. verroj (n)"
  },
  {
    "x": "verrûjik (n)",
    "y": " b. verrojik (n), verroj (n)"
  },
  {
    "x": "verrûni (n)",
    "y": " b. verrî (n)"
  },
  {
    "x": "versî (m)",
    "y": " gölge"
  },
  {
    "x": "versîya dare (m)",
    "y": " ağacın gölgesi"
  },
  {
    "x": "verşert (n)",
    "y": " ön koşul, ön şart"
  },
  {
    "x": "verşîyaye (n)",
    "y": " cenin"
  },
  {
    "x": "vertêmîyandaya (m",
    "y": " karışık "
  },
  {
    "x": "vertêmîyandaye (n)",
    "y": " karışık"
  },
  {
    "x": "vertêmîyandayîş (n)",
    "y": " karıştırma (birbirine karıştırma)"
  },
  {
    "x": "vertêmîyandayîye (m)",
    "y": " karışık"
  },
  {
    "x": "vertêmîyandîyayîş (n)",
    "y": " 1)karışma (birbirine karışma)\r\n~2)karıştırılma (birbirine karıştırılma)"
  },
  {
    "x": "vertêmîyondayîş (n)",
    "y": " b. vertêmîyandayîş"
  },
  {
    "x": "vertêmîyondîyayîş (n)",
    "y": " b. vertêmîyandîyayîş (n)"
  },
  {
    "x": "vertêmîyûndayîş (n)",
    "y": " b. vertêmîyandayîş"
  },
  {
    "x": "vertêmîyûndîyayîş (n)",
    "y": " b. vertêmîyandîyayîş (n)"
  },
  {
    "x": "veru (n)",
    "y": " b. verrî (n)"
  },
  {
    "x": "veruec (n)",
    "y": " b. verroj (n)"
  },
  {
    "x": "verû",
    "y": " b. verê"
  },
  {
    "x": "verû cû",
    "y": " b. verê cû"
  },
  {
    "x": "verû cûn",
    "y": " b. verê cûyan"
  },
  {
    "x": "verûc (n)",
    "y": " b. verroj (n)"
  },
  {
    "x": "verûcik (n)",
    "y": " b. verrojik (n)"
  },
  {
    "x": "verûj (n)",
    "y": " b. verroj (n)"
  },
  {
    "x": "verûjik (n)",
    "y": " b. verrojik (n)"
  },
  {
    "x": "verûni (n)",
    "y": " b. verrî (n)"
  },
  {
    "x": "verva-I (n)",
    "y": " harman savrulurken biriken çok ince saman"
  },
  {
    "x": "vervayê simerî (n)",
    "y": " harman savrulurken biriken çok ince saman"
  },
  {
    "x": "verva-II",
    "y": " b. verba-II"
  },
  {
    "x": "vervason (n)",
    "y": " b. veraşan (n)"
  },
  {
    "x": "vervay (n)",
    "y": " b. verva-I (n)"
  },
  {
    "x": "verxebate (m)",
    "y": " etüt"
  },
  {
    "x": "verye (n)",
    "y": " b. vêre (n)"
  },
  {
    "x": "veryen, -e",
    "y": " b. verên, -e"
  },
  {
    "x": "veryenê (m)",
    "y": " b. verênî (m)"
  },
  {
    "x": "veryeni (m)",
    "y": " b. verêne (m)"
  },
  {
    "x": "verzîn, -e",
    "y": " eyer vurma yaşına gelmiş at"
  },
  {
    "x": "vesayet (n)",
    "y": " b. wesayet (n)"
  },
  {
    "x": "vesn (n)",
    "y": " b. vosn (n)"
  },
  {
    "x": "vesn dayene",
    "y": " b. vosn dayene"
  },
  {
    "x": "vestîyer (n)",
    "y": " askılık, vestiyer"
  },
  {
    "x": "vestîyervan, -e",
    "y": " vestiyerci"
  },
  {
    "x": "vestîyervanênî (m)",
    "y": " vestiyercilik"
  },
  {
    "x": "vestîyervanî (m)",
    "y": " vestiyercilik"
  },
  {
    "x": "vestîyervanîye (m)",
    "y": " vestiyercilik"
  },
  {
    "x": "veş (n)",
    "y": " ateş (hastalıkta), humma, yangın  (hasta için)"
  },
  {
    "x": "veşê nêweşî/e",
    "y": " hastanın ateşi"
  },
  {
    "x": "veşata (m)",
    "y": " yanık"
  },
  {
    "x": "veşate (n)",
    "y": " yanık"
  },
  {
    "x": "veşatê (m)",
    "y": " b. veşata (m), veşatîye (m)"
  },
  {
    "x": "veşatîye (m)",
    "y": " yanık"
  },
  {
    "x": "veşaya (m)",
    "y": " yanık"
  },
  {
    "x": "veşaye (n)",
    "y": " yanık"
  },
  {
    "x": "veşayene",
    "y": " 1)yanmak\r\n~2)kamaşmak (göz için)\r\n~Çimê mi veşenê. (Gözlerim kamaşıyor.)"
  },
  {
    "x": "veşayi (n)",
    "y": " b. veşaye (n)"
  },
  {
    "x": "veşayî (m)",
    "y": " b. veşayîye (m)"
  },
  {
    "x": "veşayîş (n)",
    "y": " yanma"
  },
  {
    "x": "veşayîye (m)",
    "y": " yanık"
  },
  {
    "x": "veşîyayene",
    "y": " b. veşayene"
  },
  {
    "x": "veşîyayîş (n)",
    "y": " b. veşayîş (n)"
  },
  {
    "x": "veşnaya (m)",
    "y": " yakılmış olan"
  },
  {
    "x": "veşnaye (n)",
    "y": " yakılmış olan"
  },
  {
    "x": "veşnayene",
    "y": " yakmak, yandırmak"
  },
  {
    "x": "veşnayîş (n)",
    "y": " yakma, yandırma"
  },
  {
    "x": "veşnayîşê eşteyî (n)",
    "y": " atık yakımı"
  },
  {
    "x": "veşnayî (m)",
    "y": " b. veşnayîye (m)"
  },
  {
    "x": "veşnayîye (m)",
    "y": " yakılmış olan"
  },
  {
    "x": "veşnîyayene",
    "y": " yakılmak"
  },
  {
    "x": "veşnîyayîş (n)",
    "y": " yakılma"
  },
  {
    "x": "veşnîyayîşê lince (n)",
    "y": " çamurun yakılması"
  },
  {
    "x": "veştiş (n)",
    "y": " b. vazdayîş (n)"
  },
  {
    "x": "vete (n)",
    "y": " çıkarılan (mat)"
  },
  {
    "x": "vetene-I",
    "y": " 1)çıkarmak\r\n~2)ürün toplamak (toprak altından çıkarılan ürünler için)"
  },
  {
    "x": "vetene meydan",
    "y": " afişe etmek"
  },
  {
    "x": "vetene teber",
    "y": " dışarıya çıkarmak"
  },
  {
    "x": "vetene-II",
    "y": " b. vitene"
  },
  {
    "x": "veterîner, -e",
    "y": " baytar, veteriner"
  },
  {
    "x": "vetiş-I (n)",
    "y": " 1)çıkarma (mat)\r\n~2)ürün toplama (toprak altından çıkarılan ürünler için)\r\n~3)ihraç"
  },
  {
    "x": "vetiş-II (n)",
    "y": " b. vitiş (n)"
  },
  {
    "x": "veve (n)",
    "y": " b. bivo (n)"
  },
  {
    "x": "vevo (n)",
    "y": " b. bivo (n)"
  },
  {
    "x": "vewr (m)",
    "y": " b. vewre (m)"
  },
  {
    "x": "vewrbelek",
    "y": " alacalık (karlı yer için), karın erimesi sonucu yer yer toprağın görünmesi ve bazı yerlerin henüz karla kaplı olması durumu"
  },
  {
    "x": "vewre (m)",
    "y": " kar\r\n~vewra kirtine (m): karın sertleşmiş üst tabakası"
  },
  {
    "x": "vewra kirtine (m)",
    "y": " karın sertleşmiş üst tabakası"
  },
  {
    "x": "vewremerdim (n)",
    "y": " kardan adam"
  },
  {
    "x": "vewrês (n)",
    "y": " çığ"
  },
  {
    "x": "vewrgude (m)",
    "y": " kar topu"
  },
  {
    "x": "vewri (m)",
    "y": " b. vewre (m)"
  },
  {
    "x": "vewris (n)",
    "y": " b. vewrês (n)"
  },
  {
    "x": "vewrive (m)",
    "y": " b. vewrribe (m)"
  },
  {
    "x": "vewrribe (m)",
    "y": " kar helvası"
  },
  {
    "x": "vewrîs (n)",
    "y": " b. vewrês (n)"
  },
  {
    "x": "vewrlîke (m)",
    "y": " sulusepken kar, yağmurla karışık yağan kar"
  },
  {
    "x": "vexêr",
    "y": " b. bixeyr"
  },
  {
    "x": "ma vexêr",
    "y": " b. ma bixeyr dî"
  },
  {
    "x": "ma vexêr dî.",
    "y": " b. ma bixeyr dî"
  },
  {
    "x": "vey-I (n, nm)",
    "y": " b. veng (n, nm)"
  },
  {
    "x": "vey dayene",
    "y": " b. veng dayene"
  },
  {
    "x": "vey-II (n)",
    "y": " b. ver-I (n)"
  },
  {
    "x": "vey a",
    "y": " b. aver (n)"
  },
  {
    "x": "vey a şîyene",
    "y": " b. aver şîyene"
  },
  {
    "x": "vey dayene",
    "y": " b. vay dayene"
  },
  {
    "x": "veyayîş (n)",
    "y": " karışma (dokunma)"
  },
  {
    "x": "veyayene",
    "y": " karışmak (dokunmak)\r\n~Qeçekî mevey! (Çocuğa karışma!)\r\n~\t\r\n~Kam veya ci? (Kim ona karıştı?)\r\n~\t\r\n~Cimevey! (Ona karışma!)"
  },
  {
    "x": "veyb (m)",
    "y": " b. veyve-I (m)"
  },
  {
    "x": "veycîyayene",
    "y": " b. vejîyayene"
  },
  {
    "x": "veycîyayîş (n)",
    "y": " b. vejîyayîş (n)"
  },
  {
    "x": "veydayîş (n)",
    "y": " b. vengdayîş (n)"
  },
  {
    "x": "veyeki (m)",
    "y": " b. veyveke (m)"
  },
  {
    "x": "veyjîyayene",
    "y": " b. vejîyayene"
  },
  {
    "x": "veyjîyayîş (n)",
    "y": " b. vejîyayîş (n)"
  },
  {
    "x": "veyn (n, nm)",
    "y": " b. veng (n, nm)"
  },
  {
    "x": "veyn dayene",
    "y": " b. veng dayene"
  },
  {
    "x": "veyn dayeni",
    "y": " b. veng dayene"
  },
  {
    "x": "veyndayena melayî (m)",
    "y": " b. vengdayena melayî (m)"
  },
  {
    "x": "veynayene",
    "y": " b. vînayene"
  },
  {
    "x": "veyni",
    "y": " b. bivîne"
  },
  {
    "x": "vey",
    "y": " b. bivîne"
  },
  {
    "x": "veynayîş (n)",
    "y": " b. vînayîş (n)"
  },
  {
    "x": "veync (n, nm)",
    "y": " b. veng (n, nm)"
  },
  {
    "x": "veyndayîş (n)",
    "y": " b. vengdayîş (n)"
  },
  {
    "x": "veyndayîşê melayî (n)",
    "y": " b. vengdayîşê melayî (n)"
  },
  {
    "x": "veyng (n, nm)",
    "y": " b. veng (n, nm)"
  },
  {
    "x": "veyng dayene",
    "y": " b. veng dayene"
  },
  {
    "x": "veyngdayîş (n)",
    "y": " b. vengdayîş (n)"
  },
  {
    "x": "veynî (m)",
    "y": " b. vernî (m)"
  },
  {
    "x": "veynîyayîne",
    "y": " b. vînîyayene"
  },
  {
    "x": "veyretiş (n)",
    "y": " b. vîyartiş (n)"
  },
  {
    "x": "veyşa kerdene",
    "y": " b. vêşan kerdene"
  },
  {
    "x": "veyşakerdiş (n)",
    "y": " b. vêşankerdiş (n)"
  },
  {
    "x": "veyşan, -i",
    "y": " b. vêşan, -e"
  },
  {
    "x": "veyşan bîyayene",
    "y": " b. vêşan bîyene"
  },
  {
    "x": "veyşan kerdene",
    "y": " b. vêşan kerdene"
  },
  {
    "x": "veyşanbîyayîş (n)",
    "y": " b. vêşanbîyayîş (n)"
  },
  {
    "x": "veyşaney (m)",
    "y": " b. vêşanîye (m), vêşanî (m)"
  },
  {
    "x": "veyşanî (m)",
    "y": " b. vêşanîye (m), vêşanî (m)"
  },
  {
    "x": "veyşankerdiş (n)",
    "y": " b. vêşankerdiş (n)"
  },
  {
    "x": "veyşaya (m)",
    "y": " b. veşaya (m), veşayîye (m)"
  },
  {
    "x": "veyşaye (n)",
    "y": " b. veşaye (n)"
  },
  {
    "x": "veyşayene",
    "y": " b. veşayene"
  },
  {
    "x": "veyşayi (n)",
    "y": " b. veşaye (n)"
  },
  {
    "x": "veyşayîş (n)",
    "y": " b. veşayîş (n)"
  },
  {
    "x": "veyşayîye (m)",
    "y": " b. veşaya (m), veşayîye (m)"
  },
  {
    "x": "veyşnaye (n)",
    "y": " b. veşnaye (n)"
  },
  {
    "x": "veyşnayene",
    "y": " b. veşnayene"
  },
  {
    "x": "veyşnayê (m)",
    "y": " b. veşnaya (m), veşnayîye (m)"
  },
  {
    "x": "veyşnayîş (n)",
    "y": " b. veşnayîş (n)"
  },
  {
    "x": "veyşo (n)",
    "y": " b. vêşan (n)"
  },
  {
    "x": "veyşo bîyayene",
    "y": " b. vêşan bîyene"
  },
  {
    "x": "veyşo kerdene",
    "y": " b. vêşan kerdene"
  },
  {
    "x": "veyşobîyayîş (n)",
    "y": " b. vêşanbîyayîş (n)"
  },
  {
    "x": "veyşokerdiş (n)",
    "y": " b. vêşankerdiş (n)"
  },
  {
    "x": "veyşon",
    "y": " b. vêşan, -e"
  },
  {
    "x": "veyşon bîyayene",
    "y": " b. vêşan bîyene"
  },
  {
    "x": "veyşon kerdene",
    "y": " b. vêşan kerdene"
  },
  {
    "x": "veyşonbîyayîş (n)",
    "y": " b. vêşanbîyayîş (n)"
  },
  {
    "x": "veyşoney (m)",
    "y": " b. vêşanîye (m), vêşanî (m)"
  },
  {
    "x": "veyşonkerdiş (n)",
    "y": " b. vêşankerdiş (n)"
  },
  {
    "x": "veyşû (n)",
    "y": " b. vêşan (n)"
  },
  {
    "x": "veyşû bîyayene",
    "y": " b. vêşan bîyene"
  },
  {
    "x": "veyşû kerdene",
    "y": " b. vêşan kerdene"
  },
  {
    "x": "veyşûbîyayîş (n)",
    "y": " b. vêşanbîyayîş (n)"
  },
  {
    "x": "veyşûkerdiş (n)",
    "y": " b. vêşankerdiş (n)"
  },
  {
    "x": "veyşûn",
    "y": " b. vêşan, -e"
  },
  {
    "x": "veyşûn bîyayene",
    "y": " b. vêşan bîyene"
  },
  {
    "x": "veyşûn kerdene",
    "y": " b. vêşan kerdene"
  },
  {
    "x": "veyşûnbîyayîş (n)",
    "y": " b. vêşanbîyayîş (n)"
  },
  {
    "x": "veyşûnê (m)",
    "y": " b. vêşanîye (m), vêşanî (m)"
  },
  {
    "x": "veyşûnkerdiş (n)",
    "y": " b. vêşankerdiş (n)"
  },
  {
    "x": "veyv (m)",
    "y": " b. veyve-I (m)"
  },
  {
    "x": "veyve-I (m)",
    "y": " gelin"
  },
  {
    "x": "veyve-II (n)",
    "y": " düğün"
  },
  {
    "x": "veyve kerdene",
    "y": " düğün yapmak"
  },
  {
    "x": "veyvewo bisaz (n)",
    "y": " b. veyveyo bisaz (n)"
  },
  {
    "x": "veyvey cinan (n)",
    "y": " b. veyveyê cinan (n)"
  },
  {
    "x": "veyvey cindûn (n)",
    "y": " b. veyveyê cinan (n)"
  },
  {
    "x": "veyvey cinon (n)",
    "y": " b. veyveyê cinan (n)"
  },
  {
    "x": "veyveyê cinan (n)",
    "y": " hortum"
  },
  {
    "x": "veyveyo bisaz (n)",
    "y": " çalgılı düğün"
  },
  {
    "x": "veyvê vesaz (n)",
    "y": " b. veyveyo bisaz (n)"
  },
  {
    "x": "veyvek (m)",
    "y": " b. veyveke (m)"
  },
  {
    "x": "veyvekanî (m)",
    "y": " gelinlik (gelin olma durumu)"
  },
  {
    "x": "veyveke (m)",
    "y": " 1)gelin (evlenmek üzere süslenmiş kız ya da yeni evlenmiş kadın)\r\n~2)bebek (gelin biçimindeki bebek)\r\n~3)gelincik (bitkisi)\r\n~4)arpacık, itdirseği"
  },
  {
    "x": "veyvekey (m)",
    "y": " b. veyvekî (m), veyvekîye (m)"
  },
  {
    "x": "veyvekê (m)",
    "y": " b. veyvekî (m), veyvekîye (m)"
  },
  {
    "x": "veyvekênî (m)",
    "y": " gelinlik (gelin olma durumu)"
  },
  {
    "x": "veyveki (m)",
    "y": " b. veyveke (m)"
  },
  {
    "x": "veyvekinî (n)",
    "y": " gelinlik (gelinin nikâh veya düğünde giydiği giysi)"
  },
  {
    "x": "veyvekî-I (m)",
    "y": " gelinlik (gelin olma durumu)"
  },
  {
    "x": "veyvekî-II (zh)",
    "y": " evcilik"
  },
  {
    "x": "veyvekî kay kerdene",
    "y": " evcilik oynamak"
  },
  {
    "x": "veyvekîni (m)",
    "y": " b. veyvekênî (m)"
  },
  {
    "x": "veyvekîye (m)",
    "y": " gelinlik (gelin olma durumu)"
  },
  {
    "x": "veyvekînî (m)",
    "y": " b. veyvekênî (m)"
  },
  {
    "x": "veyvekonî (m)",
    "y": " b. veyvekanî (m)"
  },
  {
    "x": "veyvekûnî (m)",
    "y": " b. veyvekanî (m)"
  },
  {
    "x": "veyvende (n)",
    "y": " şenlik (şen olma durumu)"
  },
  {
    "x": "veyvi-I (m)",
    "y": " b. veyve-I (m) "
  },
  {
    "x": "veyvi-II (n)",
    "y": " b. veyve-II (n)"
  },
  {
    "x": "veyvi kerdene",
    "y": " b. veyve kerdene"
  },
  {
    "x": "veyvik (m)",
    "y": " b. veyveke (m)"
  },
  {
    "x": "veyvike (m)",
    "y": " b. veyveke (m)"
  },
  {
    "x": "veyviki (m)",
    "y": " b. veyveke (m)"
  },
  {
    "x": "veyw (m)",
    "y": " b. veyve-I (m)"
  },
  {
    "x": "veywe-I (m)",
    "y": " b. veyve-I (m)"
  },
  {
    "x": "veywe-II (n)",
    "y": " b. veyve-II (n)"
  },
  {
    "x": "veywe kerdene",
    "y": " b. veyve kerdene"
  },
  {
    "x": "veywek (m)",
    "y": " b. veyveke (m)"
  },
  {
    "x": "veywende (n)",
    "y": " b. veyvende (n)"
  },
  {
    "x": "veywi (m)",
    "y": " b. veyve-I (m)"
  },
  {
    "x": "vezd (n)",
    "y": " içyağı"
  },
  {
    "x": "vezne (n)",
    "y": " b. wezne (n)"
  },
  {
    "x": "veznedar, -e",
    "y": " b. weznedar, -e"
  },
  {
    "x": "vê-I",
    "y": " b. bi"
  },
  {
    "x": "vê-II",
    "y": " b. vîye (n)"
  },
  {
    "x": "vêcîyayene",
    "y": " b. vejîyayene"
  },
  {
    "x": "vêcîyayêne",
    "y": " b. vejîyayene"
  },
  {
    "x": "vêcyayene",
    "y": " b. vejîyayene"
  },
  {
    "x": "vêjîyaîş (n)",
    "y": " b. vejîyayîş (n)"
  },
  {
    "x": "vêjîyayene",
    "y": " b. vejîyayene"
  },
  {
    "x": "vêjîyayêne",
    "y": " b. vejîyayene"
  },
  {
    "x": "vêjîyayîş (n)",
    "y": " b. vejîyayîş (n)"
  },
  {
    "x": "vêkaney (m)",
    "y": " b. vêkanayî (m)"
  },
  {
    "x": "vêkanayî (m)",
    "y": " gelinlik (gelin olma durumu)"
  },
  {
    "x": "vêkanî (m)",
    "y": " gelinlik (gelin olma durumu)"
  },
  {
    "x": "vêke (m)",
    "y": " b. veyveke (m) "
  },
  {
    "x": "vêki (m)",
    "y": " b. veyveke (m)"
  },
  {
    "x": "vêkoney (m)",
    "y": " b. vêkanayî (m)"
  },
  {
    "x": "vêkûnê (m)",
    "y": " b. vêkanayî (m)"
  },
  {
    "x": "vên (n, nm)",
    "y": " b. veng (n, nm)"
  },
  {
    "x": "vên dayene",
    "y": " b. veng dayene"
  },
  {
    "x": "vênayêne",
    "y": " b. vînayene"
  },
  {
    "x": "bivêni",
    "y": " b. bivîne"
  },
  {
    "x": "vên",
    "y": " b. bivîne"
  },
  {
    "x": "vênayîş (n)",
    "y": " b. vînayîş (n)"
  },
  {
    "x": "vêndayîş (n)",
    "y": " b. vengdayîş (n)"
  },
  {
    "x": "vêne (m)",
    "y": " b. vernî (m)"
  },
  {
    "x": "vênî (m)",
    "y": " b. vernî (m)"
  },
  {
    "x": "vênîyayene",
    "y": " b. vînîyayene"
  },
  {
    "x": "vênîyayêne",
    "y": " b. vînîyayene"
  },
  {
    "x": "vêr-I (n)",
    "y": " b. vîyer (n)"
  },
  {
    "x": "vêr-II",
    "y": " b. verê-I"
  },
  {
    "x": "vêr cîyon",
    "y": " b. verê cûyan"
  },
  {
    "x": "vêr con",
    "y": " b. verê cûyan"
  },
  {
    "x": "vêr cu",
    "y": " b. verê cûyan"
  },
  {
    "x": "vêr cû",
    "y": " b. verê cûyan"
  },
  {
    "x": "vêr nêmrueci (n)",
    "y": " b. verê nêmroje (n)"
  },
  {
    "x": "vêr nêmruej (n)",
    "y": " b. verê nêmroje (n)"
  },
  {
    "x": "vêr nîmroj (n)",
    "y": " b. verê nêmroje (n)"
  },
  {
    "x": "vêr-III",
    "y": " b. vêre (n)"
  },
  {
    "x": "vêram (m)",
    "y": " b. werem (n)"
  },
  {
    "x": "vêrason (n)",
    "y": " b. veraşan (n)"
  },
  {
    "x": "vêrda (m)",
    "y": " b. vîyarta (m)"
  },
  {
    "x": "vêrde (n)",
    "y": " b. vîyarte (n)"
  },
  {
    "x": "vêrdene",
    "y": " b. vîyartene"
  },
  {
    "x": "vêrdeni",
    "y": " b. vîyartene "
  },
  {
    "x": "vêrdiş (n)",
    "y": " b. vîyartiş (n)"
  },
  {
    "x": "vêre (n)",
    "y": " işkembe "
  },
  {
    "x": "vêrey ... dekerdene",
    "y": " b. vêreyê ... dekerdene "
  },
  {
    "x": "vêreyê ... dekerdene",
    "y": " karnını doldurmak"
  },
  {
    "x": "vêreher, -e",
    "y": " pisboğaz"
  },
  {
    "x": "vêrem (e)",
    "y": " b. werem (n)"
  },
  {
    "x": "vêreta (m)",
    "y": " b. vîyartîye (m)"
  },
  {
    "x": "vêrete (n)",
    "y": " b. vîyarte (n)"
  },
  {
    "x": "vêretene",
    "y": " b. vîyartene"
  },
  {
    "x": "vêretiş (n)",
    "y": " b. vîyartiş (n)"
  },
  {
    "x": "vêrg, -i",
    "y": " b. verg, -e"
  },
  {
    "x": "vêritiş (n)",
    "y": " b. viritiş (n)"
  },
  {
    "x": "vêritiş amayene",
    "y": " b. viritiş ameyene"
  },
  {
    "x": "vêritiş ardene",
    "y": " b. viritiş ardene"
  },
  {
    "x": "vêritişamayîş (n)",
    "y": " b. viritişameyîş (n)"
  },
  {
    "x": "vêritişardiş (n)",
    "y": " b. viritişardiş (n)"
  },
  {
    "x": "vêrî",
    "y": " b. verê-I"
  },
  {
    "x": "vêrîn, -e",
    "y": " b. verên, -e"
  },
  {
    "x": "vêrînê (m)",
    "y": " b. verênî (m)"
  },
  {
    "x": "vêrnayene",
    "y": " b. vîyarnayene"
  },
  {
    "x": "vêrnayîş (n)",
    "y": " b. vîyarnayîş (n)"
  },
  {
    "x": "vêrnî (m)",
    "y": " b. vernî (m)"
  },
  {
    "x": "vêrsî (m)",
    "y": " b. versî (m)"
  },
  {
    "x": "vêrta (m)",
    "y": " b. vîyartîye (m)"
  },
  {
    "x": "vêrte (n)",
    "y": " b. vîyarte (n)"
  },
  {
    "x": "vêrtene",
    "y": " b. vîyartene"
  },
  {
    "x": "vêrtiş (n)",
    "y": " b. vîyartiş (n)"
  },
  {
    "x": "vêsa (n)",
    "y": " b. vêşan (n)"
  },
  {
    "x": "vêsa bîyayene",
    "y": " b. vêşan bîyene"
  },
  {
    "x": "vêsan, -e",
    "y": " b. vêşan, -e"
  },
  {
    "x": "vêsan bîyayene",
    "y": " b. vêşan bîyene"
  },
  {
    "x": "vêsan kerdene",
    "y": " b. vêşan kerdene"
  },
  {
    "x": "vêsanbîyayîş (n)",
    "y": " b. vêşanbîyayîş (n)"
  },
  {
    "x": "vêsanîye (m)",
    "y": " b. vêşanîye (m), vêşanî (m)"
  },
  {
    "x": "vêsankerdiş (n)",
    "y": " b. vêşankerdiş (n)"
  },
  {
    "x": "vêsaye (n)",
    "y": " b. veşaye (n)"
  },
  {
    "x": "vêsayene",
    "y": " b. veşayene"
  },
  {
    "x": "vêsayêne",
    "y": " b. veşayene"
  },
  {
    "x": "vêsayî (m)",
    "y": " b. veşayîye (m)"
  },
  {
    "x": "vêsayîş (n)",
    "y": " b. veşayîş (n)"
  },
  {
    "x": "vêsayîye (m)",
    "y": " b. veşaya (m), veşayîye (m)"
  },
  {
    "x": "vêsî",
    "y": " b. vêşî"
  },
  {
    "x": "vêsnaye (n)",
    "y": " b. veşnaye (n)"
  },
  {
    "x": "vêsnayene",
    "y": " b. veşnayene"
  },
  {
    "x": "vêsnayêne",
    "y": " b. veşnayene"
  },
  {
    "x": "vêsnayî (m)",
    "y": " b. veşnayîye (m)"
  },
  {
    "x": "vêsnayîye (m)",
    "y": " b. veşnayîye (m)"
  },
  {
    "x": "vêsnayîs (n)",
    "y": " b. veşnayîş (n)"
  },
  {
    "x": "vêsnayîş (n)",
    "y": " b. veşnayîş (n)"
  },
  {
    "x": "vêsnayîye (m)",
    "y": " b. veşnaya (m), veşnayîye (m)"
  },
  {
    "x": "vêş (n)",
    "y": " b. veş (n)"
  },
  {
    "x": "vêşa (n)",
    "y": " b. vêşan (n)"
  },
  {
    "x": "vêşa kerdene",
    "y": " b. vêşan kerdene"
  },
  {
    "x": "vêşan, -e",
    "y": " aç"
  },
  {
    "x": "vêşan bîyene",
    "y": " acıkmak"
  },
  {
    "x": "vêşan kerdene",
    "y": " acıktırmak"
  },
  {
    "x": "vêşan mendene",
    "y": " aç kalmak"
  },
  {
    "x": "vêşan verdayene",
    "y": " aç bırakmak"
  },
  {
    "x": "vêşanbîyayîş (n)",
    "y": " acıkma"
  },
  {
    "x": "vêşane",
    "y": " 1)çoğunluk\r\n~2)genellikle"
  },
  {
    "x": "vêşanê (m)",
    "y": " b. vêşanîye (m), vêşanî (m)"
  },
  {
    "x": "vêşanî (m)",
    "y": " açlık"
  },
  {
    "x": "vêşanî de merdene",
    "y": " açlıktan ölmek, acından ölmek"
  },
  {
    "x": "vêşanî ra merdene",
    "y": " açlıktan ölmek, acından ölmek"
  },
  {
    "x": "vêşanî ver merdene",
    "y": " açlıktan ölmek, acından ölmek"
  },
  {
    "x": "vêşanîye (m)",
    "y": " açlık"
  },
  {
    "x": "vêşanîye de merdene",
    "y": " açlıktan ölmek, acından ölmek"
  },
  {
    "x": "vêşanîye ra merdene",
    "y": " açlıktan ölmek, acından ölmek"
  },
  {
    "x": "vêşanîye ver merdene",
    "y": " açlıktan ölmek, acından ölmek"
  },
  {
    "x": "vêşankerdiş (n)",
    "y": " acıktırma"
  },
  {
    "x": "vêşaye (n)",
    "y": " b. veşaye (n)"
  },
  {
    "x": "vêşayene",
    "y": " b. veşayene"
  },
  {
    "x": "vêşayê (m)",
    "y": " b. veşaya (m), veşayîye (m)"
  },
  {
    "x": "vêşayêne",
    "y": " b. veşayene"
  },
  {
    "x": "vêşayîş (n)",
    "y": " b. veşayîş (n)"
  },
  {
    "x": "vêşî",
    "y": " fazla, çok"
  },
  {
    "x": "vêşê vêşan",
    "y": " azami"
  },
  {
    "x": "vêşî bîyene",
    "y": " artmak"
  },
  {
    "x": "vêşî mendene",
    "y": " artmak (bir miktar geri kalma)"
  },
  {
    "x": "vêşî vatene",
    "y": " yalan söylemek"
  },
  {
    "x": "vêşî-kemî",
    "y": " b. vêşî-kêmî"
  },
  {
    "x": "vêşî-kêmî",
    "y": " aşağı yukarı, yaklaşık, takriben, yaklaşık olarak, takribî"
  },
  {
    "x": "vêşîbîyayîş (n)",
    "y": " artış (mat), artma"
  },
  {
    "x": "vêşîerje (m)",
    "y": " artık değer"
  },
  {
    "x": "vêşîmende (n)",
    "y": " artan (mat)"
  },
  {
    "x": "vêşîmendiş (n)",
    "y": " artma (bir miktar geri kalma)"
  },
  {
    "x": "vêşîroje (m)",
    "y": " artık gün"
  },
  {
    "x": "vêşîserre (m)",
    "y": " artık yıl"
  },
  {
    "x": "vêşîtî (m)",
    "y": " fazlalık"
  },
  {
    "x": "vêşîvatiş (n)",
    "y": " yalan söyleme"
  },
  {
    "x": "vêşn (n)",
    "y": " b. vosn (n)"
  },
  {
    "x": "vêşn ameyîne",
    "y": " b. vosn ameyene"
  },
  {
    "x": "vêşn dayene",
    "y": " b. vosn dayene"
  },
  {
    "x": "vêşnameyîş (n)",
    "y": " b. vosnameyîş (n) "
  },
  {
    "x": "vêşnaye (n)",
    "y": " b. veşnaye (n)"
  },
  {
    "x": "vêşnayene",
    "y": " b. veşnayene"
  },
  {
    "x": "vêşnayê (m)",
    "y": " b. veşnaya (m), veşnayîye (m)"
  },
  {
    "x": "vêşnayêne",
    "y": " b. veşnayene"
  },
  {
    "x": "vêşnayîş (n)",
    "y": " b. veşnayîş (n)"
  },
  {
    "x": "vêşo (n)",
    "y": " b. vêşan (n)"
  },
  {
    "x": "vêşo bîyayene",
    "y": " b. vêşan bîyene"
  },
  {
    "x": "vêşo kerdene",
    "y": " b. vêşan kerdene"
  },
  {
    "x": "vêşobîyayîş (n)",
    "y": " b. vêşanbîyayîş (n)"
  },
  {
    "x": "vêşokerdiş (n)",
    "y": " b. vêşankerdiş (n)"
  },
  {
    "x": "vêşon (n)",
    "y": " b. vêşan (n)"
  },
  {
    "x": "vêşon bîyayene",
    "y": " b. vêşan bîyene"
  },
  {
    "x": "vêşon kerdene",
    "y": " b. vêşan kerdene"
  },
  {
    "x": "vêşonbîyayîş (n)",
    "y": " b. vêşanbîyayîş (n)"
  },
  {
    "x": "vêşone",
    "y": " b. vêşane"
  },
  {
    "x": "vêşonkerdiş (n)",
    "y": " b. vêşankerdiş (n)"
  },
  {
    "x": "vêşû (n)",
    "y": " b. vêşan (n)"
  },
  {
    "x": "vêşû bîyayene",
    "y": " b. vêşan bîyene"
  },
  {
    "x": "vêşû kerdene",
    "y": " b. vêşan kerdene"
  },
  {
    "x": "vêşûbîyayîş (n)",
    "y": " b. vêşanbîyayîş (n)"
  },
  {
    "x": "vêşûkerdiş (n)",
    "y": " b. vêşankerdiş (n)"
  },
  {
    "x": "vêşûn (n)",
    "y": " b. vêşan (n)"
  },
  {
    "x": "vêşûn bîyayene",
    "y": " b. vêşan bîyene"
  },
  {
    "x": "vêşûn kerdene",
    "y": " b. vêşan kerdene"
  },
  {
    "x": "vêşûnbîyayîş (n)",
    "y": " b. vêşanbîyayîş (n)"
  },
  {
    "x": "vêşûne",
    "y": " b. vêşane"
  },
  {
    "x": "vêşûnê (m)",
    "y": " b. vêşanîye (m), vêşanî (m)"
  },
  {
    "x": "vêşûnkerdiş (n)",
    "y": " b. vêşankerdiş (n)"
  },
  {
    "x": "vêtiş (n)",
    "y": " b. vitiş (n)"
  },
  {
    "x": "vêv (m)",
    "y": " b. veyve-I (m)"
  },
  {
    "x": "vêve kerdene",
    "y": " b. veyve kerdene"
  },
  {
    "x": "vêve-III (n)",
    "y": " b. bivo (n)"
  },
  {
    "x": "vêvek (m)",
    "y": " b. veyveke (m)"
  },
  {
    "x": "vêvi (m)",
    "y": " b. veyve-I (m)"
  },
  {
    "x": "vêvike (m)",
    "y": " b. veyveke"
  },
  {
    "x": "vêw (m)",
    "y": " b. veyve-I (m)"
  },
  {
    "x": "vêwe-I (m)",
    "y": " b. veyve-I (m), veyveke (m)"
  },
  {
    "x": "vêwe-II (n)",
    "y": " b. veyve-II (n)"
  },
  {
    "x": "vêwe kerdene",
    "y": " b. veyve kerdene"
  },
  {
    "x": "vêweke (m)",
    "y": " b. veyveke-2 (m)"
  },
  {
    "x": "vêweki (m)",
    "y": " b. veyveke-2 (m)"
  },
  {
    "x": "vêwi-I (m)",
    "y": " b. veyve (m)"
  },
  {
    "x": "vêwi-II (n)",
    "y": " b. veyve-II (n)"
  },
  {
    "x": "vêy (m)",
    "y": " b. vaye (m)"
  },
  {
    "x": "vêyernî (m)",
    "y": " b. vernî (m)"
  },
  {
    "x": "vêyve-I (m)",
    "y": " b. veyve-I (m), veyveke (m)"
  },
  {
    "x": "vêyve-II (n)",
    "y": " b. veyve-II (n)"
  },
  {
    "x": "vêyvi (n)",
    "y": " b. veyve-II (n)"
  },
  {
    "x": "vêyvike (m)",
    "y": " b. veyveke (m)"
  },
  {
    "x": "vi ... ra",
    "y": " b. bi ... ra"
  },
  {
    "x": "vicag (n)",
    "y": " b. vicak (n)"
  },
  {
    "x": "vicag eştene",
    "y": " b. vicak eştene"
  },
  {
    "x": "vicak (n)",
    "y": " kura"
  },
  {
    "x": "vicak eştene",
    "y": " kura çekmek"
  },
  {
    "x": "vicdan (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "vicînayîş (n)",
    "y": " b. vîjnayîş (n)"
  },
  {
    "x": "vicîyayene",
    "y": " b. vejîyayene"
  },
  {
    "x": "vicîyayêne",
    "y": " b. vejîyayene"
  },
  {
    "x": "vicîyayîş (n)",
    "y": " b. vejîyayîş (n)"
  },
  {
    "x": "viçikê",
    "y": " b. piçe"
  },
  {
    "x": "vierg, -i",
    "y": " b. verg, -e"
  },
  {
    "x": "vijêr (n)",
    "y": " b. vizêr (n)"
  },
  {
    "x": "vijêrên, -e",
    "y": " b. vizêrên, -e"
  },
  {
    "x": "vijêrin, -e",
    "y": " b. vizêrên, -e"
  },
  {
    "x": "vijêrî (n)",
    "y": " b. vizêr (n)"
  },
  {
    "x": "vijike (m)",
    "y": " ishal, amel, ötürük, sürgün; insan veya hayvanın cıvık dışkısı"
  },
  {
    "x": "vijike eştene",
    "y": " ishal olmak"
  },
  {
    "x": "vijike kewtene",
    "y": " ishal olmak"
  },
  {
    "x": "vijînayene",
    "y": " b. vîjnayene"
  },
  {
    "x": "vijînayîş (n)",
    "y": " b. vîjnayîş (n)"
  },
  {
    "x": "vijîyayene",
    "y": " b. vejîyayene"
  },
  {
    "x": "vijîyayêne",
    "y": " b. vejîyayene"
  },
  {
    "x": "vijîyayîş (n)",
    "y": " b. vejîyayîş (n)"
  },
  {
    "x": "vijyayene",
    "y": " b. vejîyayene"
  },
  {
    "x": "vijyayêne",
    "y": " b. vejîyayene"
  },
  {
    "x": "vijyayîş (n)",
    "y": " b. vejîyayîş (n)"
  },
  {
    "x": "vil (m)",
    "y": " b. vile-II (m)"
  },
  {
    "x": "vilê rojî (m)",
    "y": " b. vila rojî (m)"
  },
  {
    "x": "vilê ruecî (m)",
    "y": " b. vila rojî (m)"
  },
  {
    "x": "vilê ruêc (m)",
    "y": " b. vila rojî (m)"
  },
  {
    "x": "vilê ruêj (m)",
    "y": " b. vila rojî (m)"
  },
  {
    "x": "vilê verbiruêj (m)",
    "y": " b. vila rojî (m)"
  },
  {
    "x": "vilê verbruêj (m)",
    "y": " b. vila rojî (m)"
  },
  {
    "x": "vila bîyayene",
    "y": " b. vila bîyene"
  },
  {
    "x": "vila bîyene",
    "y": " 1)dağılmak\r\n~2)yayılmak"
  },
  {
    "x": "vila kerdene",
    "y": " 1)dağıtmak\r\n~2)yaymak"
  },
  {
    "x": "vila kewtene",
    "y": " yayılmak"
  },
  {
    "x": "vila kotene",
    "y": " b. vila kewtene"
  },
  {
    "x": "vilabeyîş (n)",
    "y": " b. vilabîyayîş (n)"
  },
  {
    "x": "vilabîyaya (m)",
    "y": " 1)dağılmış olan, dağınık\r\n~2)yaygın"
  },
  {
    "x": "vilabîyaye (n)",
    "y": " 1)dağılmış olan, dağınık\r\n~2)yaygın"
  },
  {
    "x": "vilabîyayê (m)",
    "y": " b. vilabîyayî (m)"
  },
  {
    "x": "vilabîyayî, -ye",
    "y": " 1)dağılmış olan, dağınık\r\n~2)yaygın"
  },
  {
    "x": "vilabîyayîs (n)",
    "y": " b. vilabîyayîş (n)"
  },
  {
    "x": "vilabîyayîş (n)",
    "y": " 1)dağılma\r\n~2)yayılma"
  },
  {
    "x": "vilaker, -e",
    "y": " dağıtıcı, müvezzi"
  },
  {
    "x": "vilakerda (m)",
    "y": " dağıtılmış olan"
  },
  {
    "x": "vilakerde (n)",
    "y": " dağıtılmış olan"
  },
  {
    "x": "vilakerdê (m)",
    "y": " b. vilakerdî (m)"
  },
  {
    "x": "vilakerdiş (n)",
    "y": " 1)dağıtım, dağıtma\r\n~2)yayma"
  },
  {
    "x": "vilakerdişê xidayan (n)",
    "y": " gıda dağıtımı"
  },
  {
    "x": "vilakerdî, -ye",
    "y": " dağıtılmış olan"
  },
  {
    "x": "vilakewta (m)",
    "y": " dağınık"
  },
  {
    "x": "vilakewte (n)",
    "y": " dağınık"
  },
  {
    "x": "vilakewtê (m)",
    "y": " b. vilakewtî (m)"
  },
  {
    "x": "vilakewtiş (n)",
    "y": " yayılma"
  },
  {
    "x": "vilakewtî, -ye",
    "y": " dağınık"
  },
  {
    "x": "vilakota (m)",
    "y": " b. vilakewta (m)"
  },
  {
    "x": "vilakote (n)",
    "y": " b. vilakewte (n)"
  },
  {
    "x": "vilakotiş (n)",
    "y": " b. vilakewtiş (n)"
  },
  {
    "x": "vilakotî, -ye",
    "y": " b. vilakewtî, -ye"
  },
  {
    "x": "vilakowta (m)",
    "y": " b. vilakewta (m)"
  },
  {
    "x": "vilakowte (n)",
    "y": " b. vilakewte (n)"
  },
  {
    "x": "vilal (m)",
    "y": " b. verare (m)"
  },
  {
    "x": "vilal kerdene",
    "y": " b. xo verare kerdene"
  },
  {
    "x": "vilalkerdiş (n)",
    "y": " b. xoverarekerdiş (n)"
  },
  {
    "x": "vilaweke (m)",
    "y": " bildiri"
  },
  {
    "x": "vilay bîyayene",
    "y": " b. vila bîyene"
  },
  {
    "x": "vilay bîyene",
    "y": " 1)dağılmak\r\n~2)yayılmak"
  },
  {
    "x": "vilay kerdene",
    "y": " 1)dağıtmak\r\n~2)yaymak"
  },
  {
    "x": "vilayêr (m)",
    "y": " b. vîyale (m)"
  },
  {
    "x": "vilayêri (m)",
    "y": " b. vîyale (m)"
  },
  {
    "x": "vilcew (n)",
    "y": " b. vînce (n)"
  },
  {
    "x": "vildane (m)",
    "y": " vazo"
  },
  {
    "x": "vildar, -e",
    "y": " çiçekli"
  },
  {
    "x": "vile-I (n)",
    "y": " 1)boyun\r\n~2)geçit, derbent"
  },
  {
    "x": "vile çewt kerdene",
    "y": " boyun bükmek"
  },
  {
    "x": "vile çot kerdene",
    "y": " b. vile çewt kerdene"
  },
  {
    "x": "vile ronayene",
    "y": " boyun eğmek, itaat etmek"
  },
  {
    "x": "vileyê cile/çinayî/kincî (n)",
    "y": " elbisenin/giysinin boyna arkadan oturan bölümü"
  },
  {
    "x": "vileyê xo çewt kerdene",
    "y": " boynunu bükmek"
  },
  {
    "x": "vileyê xo çot kerdene",
    "y": " b. vileyê xo çewt kerdene"
  },
  {
    "x": "vile-II (m)",
    "y": " çiçek"
  },
  {
    "x": "vila rojî (m)",
    "y": " ayçiçeği, gündöndü, günebakan"
  },
  {
    "x": "viley rocî (m)",
    "y": " b. vila rojî (m)"
  },
  {
    "x": "vileçewt, -e",
    "y": " boynu bükük"
  },
  {
    "x": "vileçewtkerdiş (n)",
    "y": " boyun bükme"
  },
  {
    "x": "vileçot, -e",
    "y": " b. vileçewt, -e"
  },
  {
    "x": "vileçotkerdiş (n)",
    "y": " b. vileçewtkerdiş (n)"
  },
  {
    "x": "vilence (n)",
    "y": " b. vînce (n)"
  },
  {
    "x": "vilenci (n)",
    "y": " b. vînce (n)"
  },
  {
    "x": "vilencik (n)",
    "y": " b. vînce (n)"
  },
  {
    "x": "vileronaîs",
    "y": " b. vileronayîş (n)"
  },
  {
    "x": "vileronayîş (n)",
    "y": " boyun eğme, itaat etme"
  },
  {
    "x": "vileyêxoçewtkerdiş (n)",
    "y": " boynunu bükme"
  },
  {
    "x": "vileyêxoçotkerdiş (n)",
    "y": " b. vileyêxoçewtkerdiş (n)"
  },
  {
    "x": "vileynayene",
    "y": " b. vilênayene"
  },
  {
    "x": "vileynayîş (n)",
    "y": " b. vilênayîş (n)"
  },
  {
    "x": "vilênayene",
    "y": " 1)okşamak\r\n~2)ovmak"
  },
  {
    "x": "vilênayîş (n)",
    "y": " 1)okşama\r\n~2)ovma"
  },
  {
    "x": "vilêşeyîş (n)",
    "y": " b. vilêşîyayîş (n)"
  },
  {
    "x": "vilêşîyayene",
    "y": " erimek, çözülmek (sert şeyler için)"
  },
  {
    "x": "vilêşîyayîş (n)",
    "y": " erime, çözülme (sert şeyler için)"
  },
  {
    "x": "vilêşnayene",
    "y": " eritmek, çözmek (sert şeyler için)"
  },
  {
    "x": "vilêşnayîş (n)",
    "y": " eritme, çözme"
  },
  {
    "x": "vilheşik (m)",
    "y": " b. vilheşike (m)"
  },
  {
    "x": "vilheşike (m)",
    "y": " şakayık"
  },
  {
    "x": "vili (m)",
    "y": " b. vile-II (m)"
  },
  {
    "x": "vilik-I (m)",
    "y": " b. vilike (m)"
  },
  {
    "x": "vilik-II (m)",
    "y": " b. vilik-III (n)"
  },
  {
    "x": "vilik-III (n)",
    "y": " püskül"
  },
  {
    "x": "vilike (m)",
    "y": " çiçek"
  },
  {
    "x": "vilika cahnim-cinetî (m)",
    "y": " b. vilika cehenem-cenetî (m)"
  },
  {
    "x": "vilika cehenem-cenetî (m)",
    "y": " papatya"
  },
  {
    "x": "vilika cenet-cahnimî (m)",
    "y": " b. vilika cehenem-cenetî (m)"
  },
  {
    "x": "vilika heran (m)",
    "y": " papatya"
  },
  {
    "x": "vilika mastêrin (m)",
    "y": " b. vilika mastêrine (m)"
  },
  {
    "x": "vilika mastêrine (m)",
    "y": " sarmaşık"
  },
  {
    "x": "vilika rocî (m)",
    "y": " b. vilika rojî (m)"
  },
  {
    "x": "vilika rojî (m)",
    "y": " ayçiçeği, gündöndü, günebakan"
  },
  {
    "x": "viliki (m)",
    "y": " b. vilike (m)"
  },
  {
    "x": "vilikin, -e",
    "y": " 1)çiçekli\r\n~2)püsküllüvi"
  },
  {
    "x": "vilin, -e",
    "y": " çiçekli "
  },
  {
    "x": "vilinci (n)",
    "y": " b. vînce (n)"
  },
  {
    "x": "vilisk (n)",
    "y": " b. bilusk (n)"
  },
  {
    "x": "vilisker (n)",
    "y": " b. vinişkêre (m)"
  },
  {
    "x": "viliskêr (n)",
    "y": " b. vinişkêre (m)"
  },
  {
    "x": "viliskêre (m)",
    "y": " b. vinişkêre (m)"
  },
  {
    "x": "vilînce (n)",
    "y": " b. vînce (n)"
  },
  {
    "x": "vill (m)",
    "y": " b. vile-II (m)"
  },
  {
    "x": "villi (m)",
    "y": " b. vile-II (m)"
  },
  {
    "x": "villik (m)",
    "y": " b. vilike (m)"
  },
  {
    "x": "villike (m)",
    "y": " b. vilike (m)"
  },
  {
    "x": "villiki (m)",
    "y": " b. vilike (m)"
  },
  {
    "x": "vilnayene",
    "y": " b. vilênayene"
  },
  {
    "x": "vilnayîş (n)",
    "y": " b. vilênayîş (n)"
  },
  {
    "x": "vilnerm, -e",
    "y": " ılık"
  },
  {
    "x": "vilnerm bîyene",
    "y": " ılıklaşmak"
  },
  {
    "x": "vilnerm kerdene",
    "y": " ılıklaştırmak"
  },
  {
    "x": "vilnermbîyayîş (n)",
    "y": " ılıklaşma"
  },
  {
    "x": "vilnermkerdiş (n)",
    "y": " ılıklaştırma"
  },
  {
    "x": "vilqe (m)",
    "y": " b. forqe (m)"
  },
  {
    "x": "vilqê (m)",
    "y": " b. forqî (m)"
  },
  {
    "x": "vilqêy",
    "y": " b. forqî (m)"
  },
  {
    "x": "vilroş, -e",
    "y": " çiçekçi"
  },
  {
    "x": "vin",
    "y": " b. vîndî"
  },
  {
    "x": "vin bîyayene",
    "y": " b. vîndî bîyene"
  },
  {
    "x": "vin kerdene",
    "y": " b. vîndî kerdene"
  },
  {
    "x": "vinarnayene",
    "y": " b. vindarnayene"
  },
  {
    "x": "vinarnayîş (n)",
    "y": " b. vindarnayîş (n)"
  },
  {
    "x": "vinarta (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vinarte (n)",
    "y": " b. vindete (n)"
  },
  {
    "x": "vinartiş (n)",
    "y": " b. vindetiş (n)"
  },
  {
    "x": "vincele (n)",
    "y": " b. vînce (n)"
  },
  {
    "x": "vinceli (n)",
    "y": " b. vînce (n)"
  },
  {
    "x": "vincew (n)",
    "y": " b. vîncewe (m)"
  },
  {
    "x": "vindarnayene",
    "y": " 1)durdurmak\r\n~2)bekletmek\r\n~3)bastırmak (... önlemek)"
  },
  {
    "x": "vindarnayêne",
    "y": " b. vindarnayene"
  },
  {
    "x": "vindarnayîş (n)",
    "y": " 1)durdurma\r\n~2)bekletme\r\n~3)bastırma (önleme)"
  },
  {
    "x": "vindarta (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vindarte (n)",
    "y": " b. vindete (n)"
  },
  {
    "x": "vindartene",
    "y": " b. vindetene"
  },
  {
    "x": "vindartê (m)",
    "y": " b. vindetî (m) "
  },
  {
    "x": "vindartiş (n)",
    "y": " b. vindetiş (n)"
  },
  {
    "x": "vindartî (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vindartîye (m)",
    "y": " b. vindetîye (m)"
  },
  {
    "x": "vindayene",
    "y": " b. vindetene"
  },
  {
    "x": "vindayine",
    "y": " b. vindetene"
  },
  {
    "x": "vindayîs (n)",
    "y": " b. vindetiş (n)"
  },
  {
    "x": "vindepres (n)",
    "y": " stop press"
  },
  {
    "x": "vinderda (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vinderde (n)",
    "y": " b. vindete (n)"
  },
  {
    "x": "vinderdene",
    "y": " b. vindetene"
  },
  {
    "x": "Vind!",
    "y": " b. Vinde!"
  },
  {
    "x": "Vindere!",
    "y": " b. Vinde!"
  },
  {
    "x": "vinderdeni",
    "y": " b. vindetene"
  },
  {
    "x": "vinderdê (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vinderdiş (n)",
    "y": " b. vindetiş (n)"
  },
  {
    "x": "vinderdî (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vinderdîye (m)",
    "y": " b. vindetîye (m)"
  },
  {
    "x": "vindernayene",
    "y": " b. vindarnayene"
  },
  {
    "x": "vindernayîş (n)",
    "y": " b. vindarnayîş (n)"
  },
  {
    "x": "vinderta (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vinderte (n)",
    "y": " b. vindete (n)"
  },
  {
    "x": "vindertene",
    "y": " b. vindetene"
  },
  {
    "x": "Vindere!",
    "y": " b. Vinde!"
  },
  {
    "x": "Vindir!",
    "y": " b. Vinde!"
  },
  {
    "x": "Vindiri!",
    "y": " b. Vinde!"
  },
  {
    "x": "vindertê (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vindertiş (n)",
    "y": " b. vindetiş (n)"
  },
  {
    "x": "vindertî (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vindertîye (m)",
    "y": " b. vindetîye (m)"
  },
  {
    "x": "vinderyayeni",
    "y": " b. vindetene"
  },
  {
    "x": "vinderyayîş (n)",
    "y": " b. vindetiş (n)"
  },
  {
    "x": "vindeta (m)",
    "y": " durgun, sakin, duran"
  },
  {
    "x": "vindete (n)",
    "y": " durgun, sakin, duran"
  },
  {
    "x": "vindetene",
    "y": " 1)durmak, beklemek\r\n~2)stop etmek"
  },
  {
    "x": "Vinde!",
    "y": " Dur!"
  },
  {
    "x": "Vindi!",
    "y": " b. Vinde!"
  },
  {
    "x": "vindetê m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vindetiş (n)",
    "y": " 1)durma, bekleme\r\n~2)bekleyiş"
  },
  {
    "x": "vindetî (m)",
    "y": " durgun, sakin, duran"
  },
  {
    "x": "vindetîye (m)",
    "y": " durgun, sakin, duran"
  },
  {
    "x": "vindirnayîne",
    "y": " b. vindarnayene"
  },
  {
    "x": "vindirnayîş (n)",
    "y": " b. vindarnayîş (n)"
  },
  {
    "x": "vindiş (n)",
    "y": " b. vindetiş (n)"
  },
  {
    "x": "vinerta (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vinerte (m)",
    "y": " b. vindete (n)"
  },
  {
    "x": "vinertene",
    "y": " b. vindetene"
  },
  {
    "x": "vinertê",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vinertiş (n)",
    "y": " b. vindetiş (n)"
  },
  {
    "x": "vinertî (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vinertîye (m)",
    "y": " b. vindetîye (m)"
  },
  {
    "x": "vineta (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vinete (n)",
    "y": " b. vindete (n)"
  },
  {
    "x": "vinetene",
    "y": " b. vindetene"
  },
  {
    "x": "vinetê (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vinetiş (n)",
    "y": " b. vindetiş (n)"
  },
  {
    "x": "vinetiş (n)",
    "y": " b. vindetiş (n)"
  },
  {
    "x": "vinetî (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vinetîye (m)",
    "y": " b. vindetîye (m)"
  },
  {
    "x": "viney (m)",
    "y": " b. vinî-I (m)"
  },
  {
    "x": "vinêrtiş (n)",
    "y": " b. vindetiş (n)"
  },
  {
    "x": "vinişkêre (m)",
    "y": " çitlembik ağacı, melengiç ağacı"
  },
  {
    "x": "vinişkêri (m)",
    "y": " b. vinişkêre (m)"
  },
  {
    "x": "vinita (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vinite (n)",
    "y": " b. vindete (n)"
  },
  {
    "x": "vinitene",
    "y": " b. vindetene"
  },
  {
    "x": "vinitê (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vinitî (m)",
    "y": " b. vindetî (m)"
  },
  {
    "x": "vinitîye (m)",
    "y": " b. vindetîye (m)"
  },
  {
    "x": "vinî-I (m)",
    "y": " burun"
  },
  {
    "x": "qula vinî (m)",
    "y": " burun deliği"
  },
  {
    "x": "vinî-II",
    "y": " b. vîndî"
  },
  {
    "x": "vinî bîyayene",
    "y": " b. vîndî bîyene"
  },
  {
    "x": "vinî kerdene",
    "y": " b. vîndî kerdene"
  },
  {
    "x": "vinîbîyaya (m)",
    "y": " b. vîndîbîyaya (m)"
  },
  {
    "x": "vinîbîyaye (n)",
    "y": " b. vîndîbîyaye (n)"
  },
  {
    "x": "vinîbîyayê (m)",
    "y": " b. vîndîbîyaya (m)"
  },
  {
    "x": "vinîbîyayîş (n)",
    "y": " b. vîndîbîyayîş (n)"
  },
  {
    "x": "vinîske (m)",
    "y": " hıçkırık"
  },
  {
    "x": "vira (n)",
    "y": " b. vire (n)"
  },
  {
    "x": "virad (n)",
    "y": " b. verarde-I (m)"
  },
  {
    "x": "virad (m)",
    "y": " b. verardî (m)"
  },
  {
    "x": "viradayeni",
    "y": " b. veradayene"
  },
  {
    "x": "viradayîş (n)",
    "y": " b. veradayîş (n)"
  },
  {
    "x": "virade-I (n)",
    "y": " b. verarde-I (m)"
  },
  {
    "x": "virade-II (m)",
    "y": " b. verardî (m)"
  },
  {
    "x": "viradi (m)",
    "y": " b. verardî (m)"
  },
  {
    "x": "viradik (n)",
    "y": " ceviz vb. yuvarlanabilen şeyleri eğimli bir yerden bırakıp birbirine değdirmeye çalışmak suretiyle oynanan bir oyun"
  },
  {
    "x": "viradî (m)",
    "y": " b. verardî (m)"
  },
  {
    "x": "viradîyayeni",
    "y": " b. veradîyayene"
  },
  {
    "x": "viradîyayîş (n)",
    "y": " b. veradîyayîş (n)"
  },
  {
    "x": "viran, -e",
    "y": " örtüsüz, çıplak"
  },
  {
    "x": "viran kerdene",
    "y": " soymak (birinin parasını vb. zorla almak)"
  },
  {
    "x": "virane (m)",
    "y": " 1)kucak\r\n~2)kulaç"
  },
  {
    "x": "virane kerdene",
    "y": " b. xo virane kerdene"
  },
  {
    "x": "viranekerdiş (n)",
    "y": " b. xoviranekerdiş (n) "
  },
  {
    "x": "viranîye (m)",
    "y": " b. virane (m)"
  },
  {
    "x": "virankerdiş (n)",
    "y": " soyma (birinin parasını vb. zorla alma)"
  },
  {
    "x": "virar (m)",
    "y": " b. verare (m)"
  },
  {
    "x": "virar kerdene",
    "y": " b. xo verare kerdene"
  },
  {
    "x": "virar tira şîyayîne",
    "y": " b. verare tira şîyene "
  },
  {
    "x": "virara xo girewtene",
    "y": " b. xo verare girewtene"
  },
  {
    "x": "virara xo kerdene",
    "y": " b. xo verare kerdene"
  },
  {
    "x": "viraraxokerdiş (n)",
    "y": " b. xoverarekerdiş (n)"
  },
  {
    "x": "virard (m)",
    "y": " b. verardî (m)"
  },
  {
    "x": "virarde (n)",
    "y": " b. verarde-I (m)"
  },
  {
    "x": "virardî (m)",
    "y": " b. verardî (m)"
  },
  {
    "x": "virare (m)",
    "y": " b. verare (m)"
  },
  {
    "x": "virare kerdene",
    "y": " b. xo verare kerdene"
  },
  {
    "x": "virare pafîştene",
    "y": " b. verare pira fîştene"
  },
  {
    "x": "virare pira fîstene",
    "y": " b. verare pira fîştene"
  },
  {
    "x": "virare tira fîstene",
    "y": " b. verare tira fîştene "
  },
  {
    "x": "virarê kolî",
    "y": " b. verarê kolî "
  },
  {
    "x": "virarekerdiş (n)",
    "y": " b. xoverarekerdiş (n)"
  },
  {
    "x": "virarepafîştiş (n)",
    "y": " b. verarepirafîştiş (n) "
  },
  {
    "x": "virarepirafîstiş (n)",
    "y": " b. verarepirafîştiş (n)"
  },
  {
    "x": "viraretirafîstiş (n)",
    "y": " b. veraretirafîştiş (n) "
  },
  {
    "x": "virari (m)",
    "y": " b. verare (m)"
  },
  {
    "x": "virari kerdene",
    "y": " b. xo verare kerdene"
  },
  {
    "x": "virarikerdiş (n)",
    "y": " b. xoverarekerdiş (n)"
  },
  {
    "x": "virarî (m)",
    "y": " b. verare (m)"
  },
  {
    "x": "virarî kerdene",
    "y": " b. xo verare kerdene"
  },
  {
    "x": "virarîya xo kerdene",
    "y": " b. xo verare kerdene"
  },
  {
    "x": "virarîya zumîn de",
    "y": " b. verara yewbînî de"
  },
  {
    "x": "virarîkerdiş (n)",
    "y": " b. xoverarekerdiş (n)"
  },
  {
    "x": "virarîyaxokerdiş (n)",
    "y": " b. xoverarekerdiş (n)"
  },
  {
    "x": "virarkerdiş (n)",
    "y": " b. xoverarekerdiş (n)"
  },
  {
    "x": "virartiraşîyayîş (n)",
    "y": " b. veraretiraşîyayîş (n) "
  },
  {
    "x": "viraruej (n)",
    "y": " b. verroj (n)-1"
  },
  {
    "x": "viraste (n)",
    "y": " b. viraşte (n)"
  },
  {
    "x": "virastene",
    "y": " b. viraştene "
  },
  {
    "x": "virastê (m)",
    "y": " b. versî (m)"
  },
  {
    "x": "virastis (n)",
    "y": " b. viraştiş (n)"
  },
  {
    "x": "virastiş (n)",
    "y": " b. viraştiş (n)"
  },
  {
    "x": "virastîye (m)",
    "y": " b. viraştîye (m)"
  },
  {
    "x": "virastox, -e",
    "y": " b. viraştox, -e"
  },
  {
    "x": "viraşta (m)",
    "y": " yapma, yapay, suni\r\n~2)türemiş\r\n~3)yapılmış olan, mamul"
  },
  {
    "x": "viraşte (n)",
    "y": " 1)yapma, yapay, suni\r\n~2)türemiş\r\n~3)yapılmış olan, mamul\r\n~4)kurgu"
  },
  {
    "x": "viraştene",
    "y": " 1)yapmak\r\n~2)inşa etmek\r\n~3)yaratmak\r\n~4)(keçi, koyun, at, eşek için) çiftleştirmek"
  },
  {
    "x": "viraştiş (n)",
    "y": " 1)yapma, inşa etme\r\n~2)yapım, prodüksiyon\r\n~3)yaratma"
  },
  {
    "x": "viraştîye (m)",
    "y": " 1)yapma, yapay, suni\r\n~2)türemiş\r\n~3)yapılmış olan, mamul"
  },
  {
    "x": "viraştox, -e",
    "y": " 1)yapan, yapıcı\r\n~2)prodüktör, yapımcı"
  },
  {
    "x": "viraştoxa/ê îdarî",
    "y": " idari yapımcı"
  },
  {
    "x": "viraştuex",
    "y": " b. viraştox, -e"
  },
  {
    "x": "vire (n)",
    "y": " şimşek, yıldırım\r\n~Vire akewt. (Şimşek çaktı.)"
  },
  {
    "x": "vire akewtene",
    "y": " şimşek çakmak, şimşeklenmek"
  },
  {
    "x": "Vire kewt.",
    "y": " b. Vire akewt."
  },
  {
    "x": "vire virsayene",
    "y": " şimşek çakmak, şimşeklenmek\r\n~Vire virseno. (Şimşek çakıyor.)"
  },
  {
    "x": "virec (n)",
    "y": " b. virite (n)"
  },
  {
    "x": "virende (m)",
    "y": " b. verê (m)"
  },
  {
    "x": "virendî (m)",
    "y": " b. virênî (m)"
  },
  {
    "x": "virendî guretene",
    "y": " b. vernî girewtene"
  },
  {
    "x": "virendîguretiş (n)",
    "y": " b. vernîgirewtiş (n)"
  },
  {
    "x": "virenîye (m)",
    "y": " b. virênîye (m)"
  },
  {
    "x": "virestî (m)",
    "y": " b. versî (m)"
  },
  {
    "x": "vireznayini",
    "y": " b. verişnayene"
  },
  {
    "x": "virê (n)",
    "y": " b. verên (n)"
  },
  {
    "x": "virêcayene",
    "y": " b. viritene"
  },
  {
    "x": "ez virêcîyaya",
    "y": " b. mi virit"
  },
  {
    "x": "virêcîyayene",
    "y": " b. virêjîyayene"
  },
  {
    "x": "virêcnayene",
    "y": " b. ravirêjnayene, viritene"
  },
  {
    "x": "mi virêcna ra",
    "y": " b. mi virêjna ra, mi virit ra"
  },
  {
    "x": "virêjîyayene",
    "y": " kusulmak"
  },
  {
    "x": "virên, -e",
    "y": " b. verên, -e"
  },
  {
    "x": "virênde",
    "y": " b. verê-I"
  },
  {
    "x": "virêndi",
    "y": " b. verê-I"
  },
  {
    "x": "virêndî (m)",
    "y": " b. virênî (m)"
  },
  {
    "x": "virênî (m)",
    "y": " ön"
  },
  {
    "x": "virênî guretene",
    "y": " b. vernî girewtene"
  },
  {
    "x": "virênîguretiş (n)",
    "y": " b. vernîgirewtiş (n)"
  },
  {
    "x": "virênîye (m)",
    "y": " 1)ön\r\n~2)b. verênîye (m)"
  },
  {
    "x": "virêştiş (n)",
    "y": " b. viraştiş (n)"
  },
  {
    "x": "virik (m)",
    "y": " b. verike (m)"
  },
  {
    "x": "virike (m)",
    "y": " ishal, amel, ötürük, sürgün; insan veya hayvanın cıvık dışkısı"
  },
  {
    "x": "virike eştene",
    "y": " ishal olmak"
  },
  {
    "x": "virike kewtene",
    "y": " ishal olmak"
  },
  {
    "x": "viriki (m)",
    "y": " b. verike (m)"
  },
  {
    "x": "viring (n)",
    "y": " b. hevring (n)"
  },
  {
    "x": "viring kedene",
    "y": " b. hevring kerdene"
  },
  {
    "x": "virisknayene",
    "y": " b. virsiknayene"
  },
  {
    "x": "virisnê (n)",
    "y": " b. versî (m)"
  },
  {
    "x": "virisnî (n)",
    "y": " b. versî (m)"
  },
  {
    "x": "viristey (m)",
    "y": " b. versî (m)"
  },
  {
    "x": "viristê (m)",
    "y": " b. versî (m)"
  },
  {
    "x": "virite (n)",
    "y": " kusmuk"
  },
  {
    "x": "viritene",
    "y": " kusmak, istifrağ etmek"
  },
  {
    "x": "mi virit",
    "y": " ben kustum"
  },
  {
    "x": "viritini",
    "y": " b. viritene"
  },
  {
    "x": "viritiş (n)",
    "y": " kusma, istifrağ etme"
  },
  {
    "x": "viritiş ameyene",
    "y": " mide(si) bulanmak"
  },
  {
    "x": "viritiş ardene",
    "y": " mide bulandırmak"
  },
  {
    "x": "viritişameyîş (n)",
    "y": " mide(si) bulanma"
  },
  {
    "x": "viritişardiş (n)",
    "y": " mide bulandırma"
  },
  {
    "x": "virîcayene",
    "y": " b. viritene"
  },
  {
    "x": "virîn, -e",
    "y": " b. verên, -e"
  },
  {
    "x": "virîyaya (m)",
    "y": " b. vurîyaya (m)"
  },
  {
    "x": "virîyaye (n)",
    "y": " b. vurîyaye (n)"
  },
  {
    "x": "virîyayene",
    "y": " b. vurîyayene"
  },
  {
    "x": "virîyayî, -ye",
    "y": " b. vurîyayî, -ye"
  },
  {
    "x": "virîyayîş (n)",
    "y": " b. vurîyayîş (n)"
  },
  {
    "x": "virnaya (m)",
    "y": " b. vurnaya (m)"
  },
  {
    "x": "virnaye (n)",
    "y": " b. vurnaye (n)"
  },
  {
    "x": "virnayene",
    "y": " b. vurnayene"
  },
  {
    "x": "virnayî, -ye",
    "y": " b. vurnayî, -ye"
  },
  {
    "x": "virnayîş (n)",
    "y": " b. vurnayîş (n)"
  },
  {
    "x": "virnik, -e",
    "y": " güzün doğan oğlak, kuzu vb. hayvan "
  },
  {
    "x": "virnî, -ye-I",
    "y": " güzün doğan oğlak, kuzu vb. hayvan"
  },
  {
    "x": "virnî-II (m)",
    "y": " b. vernî (m)"
  },
  {
    "x": "virnîyaw (m)",
    "y": " b. qirnawî (m)"
  },
  {
    "x": "virnîye (m)",
    "y": " b. vernî (m)"
  },
  {
    "x": "viro (n)",
    "y": " b. virsik (n)"
  },
  {
    "x": "viron",
    "y": " b. viran, -e"
  },
  {
    "x": "virran, -e",
    "y": " b. viran, -e"
  },
  {
    "x": "virrik (m)",
    "y": " b. virike (m)"
  },
  {
    "x": "virrike (m)",
    "y": " b. virike (m)"
  },
  {
    "x": "virrike estene",
    "y": " b. virike eştene"
  },
  {
    "x": "virrike kotene",
    "y": " b. virike kewtene"
  },
  {
    "x": "virriki (m)",
    "y": " b. virike (m)"
  },
  {
    "x": "virriki eştene",
    "y": " b. virike eştene"
  },
  {
    "x": "virriki kotene",
    "y": " b. virike kewtene"
  },
  {
    "x": "virrîyayene",
    "y": " b. vurîyayene"
  },
  {
    "x": "virrîyayîş (n)",
    "y": " b. vurîyayîş (n)"
  },
  {
    "x": "virrnayene",
    "y": " b. vurnayene"
  },
  {
    "x": "virrnayîş (n)",
    "y": " b. vurnayîş (n)"
  },
  {
    "x": "virsa (m)",
    "y": " b. versî (m)"
  },
  {
    "x": "virsayene",
    "y": " yayılmak (koku için)"
  },
  {
    "x": "virsayîş (n)",
    "y": " yayılma (koku için)"
  },
  {
    "x": "virse (n)",
    "y": " b. virsik (n)"
  },
  {
    "x": "virsê (m)",
    "y": " b. versî (m)"
  },
  {
    "x": "virsik (n)",
    "y": " şimşek, yıldırım"
  },
  {
    "x": "virsik akewtene",
    "y": " şimşek çakmak, şimşeklenmek\r\n~Virsik akeweno. (Şimşek çakıyor.)\r\n~\t\r\n~Virsik akewt. (Şimşek çaktı.)"
  },
  {
    "x": "virsikgir (n)",
    "y": " yıldırımkıran, yıldırımsavar, yıldırımlık, paratoner"
  },
  {
    "x": "virsikî dayene",
    "y": " yaymak (koku için)"
  },
  {
    "x": "virsikî dîyayene",
    "y": " yayılmak (koku için)"
  },
  {
    "x": "virsikîdayîş (n)",
    "y": " yayma (koku için)"
  },
  {
    "x": "virsikîdîyayîş (n)",
    "y": " yayılma (koku için)"
  },
  {
    "x": "virsikîyayene",
    "y": " yayılmak (koku için)"
  },
  {
    "x": "virsikîyayîş (n)",
    "y": " yayılma (koku için)"
  },
  {
    "x": "virsiknayene",
    "y": " yaymak (koku için)"
  },
  {
    "x": "virsiknayîş (n)",
    "y": " yayma (koku için)"
  },
  {
    "x": "virsî (m)",
    "y": " b. versî (m)"
  },
  {
    "x": "virsnayene",
    "y": " 1)tiftiklemek\r\n~2)yaymak (koku için)"
  },
  {
    "x": "virsnayîş (n)",
    "y": " yayma (koku için)"
  },
  {
    "x": "virsnê (n)",
    "y": " b. versî (m)"
  },
  {
    "x": "virso (n)",
    "y": " b. virsik (n)"
  },
  {
    "x": "virso akewtene",
    "y": " b. virsik akewtene"
  },
  {
    "x": "virtiki (m)",
    "y": " b. virike (m)"
  },
  {
    "x": "virtis (n)",
    "y": " b. viritiş (n)"
  },
  {
    "x": "virtis amaene",
    "y": " b. viritiş ameyene"
  },
  {
    "x": "virtis ardene",
    "y": " b. viritiş ardene"
  },
  {
    "x": "virtisamaîs (n)",
    "y": " b. viritişameyîş (n)"
  },
  {
    "x": "virtisardiş (n)",
    "y": " b. viritişardiş (n)"
  },
  {
    "x": "virtiş (n)",
    "y": " b. viritiş (n)"
  },
  {
    "x": "virtiş amaene",
    "y": " b. viritiş ameyene"
  },
  {
    "x": "virtiş ardene",
    "y": " b. viritiş ardene"
  },
  {
    "x": "virtişamayîş (n)",
    "y": " b. viritişameyîş (n)"
  },
  {
    "x": "virtişardiş (n)",
    "y": " b. viritişardiş (n)"
  },
  {
    "x": "virtîs (n)",
    "y": " b. viritiş (n)"
  },
  {
    "x": "virtîs amaene",
    "y": " b. viritiş ameyene"
  },
  {
    "x": "virtîs ardene",
    "y": " b. viritiş ardene"
  },
  {
    "x": "virtîsamaîs (n)",
    "y": " b. viritişameyîş (n)"
  },
  {
    "x": "virtîsardiş (n)",
    "y": " b. viritişardiş (n)"
  },
  {
    "x": "virtûvila, -ye",
    "y": " darmadağın, darmadağınık"
  },
  {
    "x": "virtûviran, -e",
    "y": " çırılçıplak, çırçıplak"
  },
  {
    "x": "virûsik (n)",
    "y": " b. virsik (n)"
  },
  {
    "x": "virûsk (n)",
    "y": " b. virsik (n)"
  },
  {
    "x": "viryayene",
    "y": " b. vurîyayene"
  },
  {
    "x": "viryayîş (n)",
    "y": " b. vurîyayîş (n)"
  },
  {
    "x": "virye-I (n)",
    "y": " b. vire (n)"
  },
  {
    "x": "virye akewt",
    "y": " b. vire akewt."
  },
  {
    "x": "virye-II (n)",
    "y": " b. virên (n)"
  },
  {
    "x": "viryen (n)",
    "y": " b. verên (n)"
  },
  {
    "x": "viryenê (m)",
    "y": " b. verênî (m)"
  },
  {
    "x": "viryenî (m)",
    "y": " b. verênî (m)"
  },
  {
    "x": "visîyayene",
    "y": " koparılmak"
  },
  {
    "x": "visîyayîş (n)",
    "y": " koparılma"
  },
  {
    "x": "visnayene",
    "y": " koparmak"
  },
  {
    "x": "visnayêne",
    "y": " b. visnayene"
  },
  {
    "x": "visnayîş (n)",
    "y": " koparma"
  },
  {
    "x": "vista (m)",
    "y": " b. eşta (m)"
  },
  {
    "x": "vistaneke (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "vistanik (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "vistanike (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "viste (n)",
    "y": " b. eşte (n)"
  },
  {
    "x": "vistene",
    "y": " b. fîştene, eştene"
  },
  {
    "x": "vistene fekê tirpanan",
    "y": " b. eştene fekê tirpanan ver"
  },
  {
    "x": "vistene ... ser",
    "y": " baskın vermek, baskın yapmak"
  },
  {
    "x": "visteni",
    "y": " b. fîştene, eştene"
  },
  {
    "x": "visterîye (m)",
    "y": " b. vistewrî (m)"
  },
  {
    "x": "vistewre (n)",
    "y": " 1)kayınbaba, kayınpeder\r\n~2)kayınbirader, kayın "
  },
  {
    "x": "vistewrî (m)",
    "y": " kaynana, kayınvalide"
  },
  {
    "x": "vistirî (m)",
    "y": " b. vistewrî (m)"
  },
  {
    "x": "vistirîye (m)",
    "y": " b. vistewrî (m)"
  },
  {
    "x": "vistirû (m)",
    "y": " b. vistewrî (m)"
  },
  {
    "x": "vistiş (n)",
    "y": " b. fîştiş (n), eştiş (n)"
  },
  {
    "x": "vistişser (n)",
    "y": " baskın"
  },
  {
    "x": "vistîrî (m)",
    "y": " b. vistewrî (m)"
  },
  {
    "x": "vistonik (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "vistore (n)",
    "y": " b. vistewre (n)"
  },
  {
    "x": "vistorî (m)",
    "y": " b. vistewrî (m) "
  },
  {
    "x": "vistowre (n)",
    "y": " b. vistewre (n) "
  },
  {
    "x": "vistowrî (m)",
    "y": " b. vistewrî (m) "
  },
  {
    "x": "vistrû (m)",
    "y": " b. vistewrî (m) "
  },
  {
    "x": "visturî (m)",
    "y": " b. vistewrî (m) "
  },
  {
    "x": "visturîye (m)",
    "y": " b. vistewrî (m)"
  },
  {
    "x": "visturu (m)",
    "y": " b. vistewrî (m)"
  },
  {
    "x": "vistûnik (m)",
    "y": " b. estaneke (m)"
  },
  {
    "x": "visyayene",
    "y": " b. visîyayene"
  },
  {
    "x": "visyayîş (n)",
    "y": " b. visîyayîş (n)"
  },
  {
    "x": "vişîyayene",
    "y": " b. visîyayene"
  },
  {
    "x": "vişîyayêne",
    "y": " b. visîyayene"
  },
  {
    "x": "vişîyayîş (n)",
    "y": " b. visîyayîş (n)"
  },
  {
    "x": "vişko (n)",
    "y": " b. vişkuwe (n)"
  },
  {
    "x": "vişko abîyayîne",
    "y": " b. vişko abîyene"
  },
  {
    "x": "vişko abîyene",
    "y": " yapraklanmak\r\n~Vişko abîyo./Dare bişkivîya ra./Dare pel kerdo ra./Dare pel dayo. (Ağaç yapraklanmış.)"
  },
  {
    "x": "vişkoyayene",
    "y": " b. vişkuwîyayene"
  },
  {
    "x": "vişkuwe (n)",
    "y": " yeni açılmış ağaç yaprağı"
  },
  {
    "x": "vişkuwîyayene",
    "y": " yapraklanmak"
  },
  {
    "x": "vitene",
    "y": " elemek"
  },
  {
    "x": "vitês (n)",
    "y": " vites"
  },
  {
    "x": "vitêsê apey (n)",
    "y": " geri vites"
  },
  {
    "x": "vitike (m)",
    "y": " bıldırcın"
  },
  {
    "x": "vitiki (m)",
    "y": " b. vitike (m)"
  },
  {
    "x": "vitiş (n)",
    "y": " eleme"
  },
  {
    "x": "vitûviran, -e",
    "y": " b. virtûviran, -e"
  },
  {
    "x": "vitûviron",
    "y": " b. virtûviran, -e"
  },
  {
    "x": "vitvit kerdene",
    "y": " söylenmek (kendi kendine konuşmak)"
  },
  {
    "x": "vitvitîyayene",
    "y": " söylenmek (kendi kendine konuşmak)"
  },
  {
    "x": "vitvitîyayîş (n)",
    "y": " söylenme (kendi kendine konuşma)"
  },
  {
    "x": "vitvitkerdiş (n)",
    "y": " söylenme (kendi kendine konuşma)"
  },
  {
    "x": "viz-viz",
    "y": " vızıldama, vızıltı"
  },
  {
    "x": "vizd, -e",
    "y": " ağzı gevşek"
  },
  {
    "x": "vize-vize",
    "y": " vızıldama, vızıltı"
  },
  {
    "x": "vizêr (n)",
    "y": " dün "
  },
  {
    "x": "vizêrên, -e",
    "y": " dünden kalma, dünkü "
  },
  {
    "x": "vizêrin, -e",
    "y": " b. vizêrên, -e"
  },
  {
    "x": "vizêrî (n)",
    "y": " b. vizêr (n)"
  },
  {
    "x": "vizêrîn (n)",
    "y": " b. vizêrên (n)"
  },
  {
    "x": "vizêrna",
    "y": " dünden beri"
  },
  {
    "x": "vizik-I (n)",
    "y": " sivrisinek "
  },
  {
    "x": "vizik-II (m)",
    "y": " b. vizike-I (m)"
  },
  {
    "x": "vizike-I (m)",
    "y": " sinek, karasinek"
  },
  {
    "x": "vizike-II (m)",
    "y": " topaç"
  },
  {
    "x": "vizike-III (m)",
    "y": " b. şilikî (m)"
  },
  {
    "x": "vizikê sibayî (n)",
    "y": " sabahın en erken vakti"
  },
  {
    "x": "vizikê siway (n)",
    "y": " b. vizikê sibayî (n)"
  },
  {
    "x": "viziki-I (m)",
    "y": " b. vizike-I (m)"
  },
  {
    "x": "viziki-II (m)",
    "y": " b. vizike-II (m)"
  },
  {
    "x": "vizîêr (n)",
    "y": " b. vizêr (n)"
  },
  {
    "x": "vizîgî (zh)",
    "y": " yuvağı çekmek için kullanılan çatallı aracın yuvağın girintili yerine geçen uçları"
  },
  {
    "x": "vizîr (n)",
    "y": " b. vizêr (n)"
  },
  {
    "x": "vizvizik, -e",
    "y": " ciddiyetsiz, gayriciddi"
  },
  {
    "x": "vizyer (n)",
    "y": " b. vizêr (n)"
  },
  {
    "x": "vizyerin, -i",
    "y": " b. vizêrên, -e"
  },
  {
    "x": "vizyêr (n)",
    "y": " b. vizêr (n)"
  },
  {
    "x": "vizyêri (n)",
    "y": " b. vizêrên (n)"
  },
  {
    "x": "vizyêrin (n)",
    "y": " b. vizêrên (n)"
  },
  {
    "x": "vizyêrini (m)",
    "y": " b. vizêrêne (m)"
  },
  {
    "x": "vî (n)",
    "y": " b. vîye (n)"
  },
  {
    "x": "vîale (m)",
    "y": " b. vîyale (m)"
  },
  {
    "x": "vîcdan (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "vîceyî (m)",
    "y": " b. vîncewe (m)"
  },
  {
    "x": "vîcnayene",
    "y": " b. vîjnayene"
  },
  {
    "x": "vîcnayîş (n)",
    "y": " b. vîjnayîş (n)"
  },
  {
    "x": "vîco (n)",
    "y": " b. vîncewe (m)"
  },
  {
    "x": "vîç (n)",
    "y": " kuş sesi"
  },
  {
    "x": "vîç-vîç",
    "y": " kuş ses"
  },
  {
    "x": "vîçevîça xo bîyayene",
    "y": " ötüp durmak"
  },
  {
    "x": "vîçê",
    "y": " b. piçe"
  },
  {
    "x": "vîçik",
    "y": " b. piçe"
  },
  {
    "x": "vîçikê",
    "y": " b. piçe"
  },
  {
    "x": "vîçnayene",
    "y": " ötmek (serçe vb. kuşlar için)"
  },
  {
    "x": "vîçnayîş (n)",
    "y": " ötme (serçe vb. kuşlar için)"
  },
  {
    "x": "vîçûnikî (zh)",
    "y": " dedikodu"
  },
  {
    "x": "vîçvîç kerdene",
    "y": " ötmek (serçe vb. kuşlar için) "
  },
  {
    "x": "vîçvîçkerdiş (n)",
    "y": " ötme (serçe vb. kuşlar için)  "
  },
  {
    "x": "vîçvîçnayene",
    "y": " ötmek (serçe vb. kuşlar için)"
  },
  {
    "x": "vîçvîçnayîş (n)",
    "y": " ötme (serçe vb. kuşlar için)"
  },
  {
    "x": "vîdanjör (n)",
    "y": " boşaltıcı"
  },
  {
    "x": "vîdeo (n)",
    "y": " video"
  },
  {
    "x": "vîdeo (m)",
    "y": " b. vîdeo (n)"
  },
  {
    "x": "vîdeoyi (m)",
    "y": " b. vîdeo (n)"
  },
  {
    "x": "vîdo (n)",
    "y": " b. vîdeo (n)"
  },
  {
    "x": "vîdon (n)",
    "y": " b. bîdon (n)"
  },
  {
    "x": "vîdonê awi (n)",
    "y": " b. bîdonê awe (n)"
  },
  {
    "x": "vîdun (n)",
    "y": " b. bîdon (n)"
  },
  {
    "x": "vîdûn (n)",
    "y": " b. bîdon (n)"
  },
  {
    "x": "Vîetnam (n)",
    "y": " Vietnam"
  },
  {
    "x": "vijayîş (n)",
    "y": " b. vejîyayîş (n)"
  },
  {
    "x": "vîjda (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "vîjdan (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "vîjdon (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "vîjdûn (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "vîjer (n)",
    "y": " b. vizêr (n)"
  },
  {
    "x": "vîjêr (n)",
    "y": " b. vizêr (n)"
  },
  {
    "x": "vîjêrên, -e",
    "y": " b. vizêrên, -e"
  },
  {
    "x": "vîjîk (m)",
    "y": " b. vijike (m)"
  },
  {
    "x": "vîjîk eştene",
    "y": " b. vijike eştene"
  },
  {
    "x": "vîjîk kotene",
    "y": " b. vijike kewtene"
  },
  {
    "x": "vîjnaya (m)",
    "y": " seçilen, seçilmiş, seçkin "
  },
  {
    "x": "vîjnaye (n)",
    "y": " seçilen, seçilmiş, seçkin"
  },
  {
    "x": "vîjnayek (n)",
    "y": " seçenek"
  },
  {
    "x": "vîjnayene",
    "y": " 1)seçmek\r\n~2)ayıklamak"
  },
  {
    "x": "vîjnayîş (n)",
    "y": " 1)seçme, seçim\r\n~2)ayıklama"
  },
  {
    "x": "vîjnayîşê çimeyan (n)",
    "y": " kaynak seçimi"
  },
  {
    "x": "vîjnayîşê elemanî/e",
    "y": " eleman seçme"
  },
  {
    "x": "vîjnayîşê projeyî (n)",
    "y": " proje seçimi"
  },
  {
    "x": "vîjnayîşo mîyanên (n)",
    "y": " ara seçim"
  },
  {
    "x": "vîjnayîye (m)",
    "y": " seçilen, seçilmiş, seçkin"
  },
  {
    "x": "vîjnayox, -e",
    "y": " seçmen"
  },
  {
    "x": "vîjnîyayene",
    "y": " ayıklanmak"
  },
  {
    "x": "vîjnîyayîş (n)",
    "y": " ayıklanma"
  },
  {
    "x": "vîjnîyayîşo tebîî (n)",
    "y": " doğal ayıklanma"
  },
  {
    "x": "vîke (m)",
    "y": " b. veyveke (m) "
  },
  {
    "x": "vîki (m)",
    "y": " b. veyveke (m)"
  },
  {
    "x": "vîlike (m)",
    "y": " b. vilike (m)"
  },
  {
    "x": "vîlikin, -e",
    "y": " b. vilikin, -e"
  },
  {
    "x": "vîlla (m)",
    "y": " villa"
  },
  {
    "x": "vîllike (m)",
    "y": " b. vilike (m)"
  },
  {
    "x": "vîllikin, -e",
    "y": " b. vilikin, -e"
  },
  {
    "x": "Vîlnîyus (n)",
    "y": " Vilnius"
  },
  {
    "x": "vîlyencele (n)",
    "y": " b. vînce (n)"
  },
  {
    "x": "vîn",
    "y": " b. vîndî"
  },
  {
    "x": "vîn bîyayene",
    "y": " b. vîndî bîyene"
  },
  {
    "x": "vîn bîyene",
    "y": " b. vîndî bîyene"
  },
  {
    "x": "vîn kerdene",
    "y": " b. vîndî kerdene"
  },
  {
    "x": "vînayene",
    "y": " görmek"
  },
  {
    "x": "bivîne",
    "y": " gör"
  },
  {
    "x": "vînayêne",
    "y": " b. vînayene"
  },
  {
    "x": "vînayine",
    "y": " b. vînayene"
  },
  {
    "x": "vînayîne",
    "y": " b. vînayene"
  },
  {
    "x": "vîne",
    "y": " b. bivîne"
  },
  {
    "x": "vînayîş (n)",
    "y": " 1)görme\r\n~2)görüş"
  },
  {
    "x": "vînbîyaya (m)",
    "y": " b. vîndîbîyaya (m)"
  },
  {
    "x": "vînbîyaye (n)",
    "y": " b. vîndîbîyaye (n)"
  },
  {
    "x": "vînbîyayîş (n)",
    "y": " b. vîndîbîyayîş (n)"
  },
  {
    "x": "vînce-I (n)",
    "y": " sakız, ciklet, çiklet"
  },
  {
    "x": "vînce-II (n)",
    "y": " b. vîncewe (m)"
  },
  {
    "x": "vîncele (n)",
    "y": " b. vînce (n)"
  },
  {
    "x": "vînceli (n)",
    "y": " b. vînce (n)"
  },
  {
    "x": "vîncew (n)",
    "y": " b. vîncewe (m)"
  },
  {
    "x": "vîncewe (m)",
    "y": " karış"
  },
  {
    "x": "vînç (n)",
    "y": " vinç"
  },
  {
    "x": "vînço bisele (n)",
    "y": " sepetli vinç"
  },
  {
    "x": "vînço bisepete (n)",
    "y": " sepetli vinç"
  },
  {
    "x": "vînd",
    "y": " b. vîndî"
  },
  {
    "x": "vînd bîyene",
    "y": " b. vîndî bîyene"
  },
  {
    "x": "vîndî",
    "y": " kayıp"
  },
  {
    "x": "vîndî bîyene",
    "y": " 1)kaybolmak\r\n~2)batmak (yıldız için)\r\n~Estare vîndî bi. (Yıldız battı.)"
  },
  {
    "x": "vîndî kerdene",
    "y": " 1)kaybetmek\r\n~2)yenilmek, yenilgiye uğramak, bozguna uğramak, bozguna vermek, hezimete uğramak"
  },
  {
    "x": "vîndîbîyaya (m)",
    "y": " kayıp"
  },
  {
    "x": "vîndîbîyaye (n)",
    "y": " kayıp"
  },
  {
    "x": "vîndîbîyayîş (n)",
    "y": " 1)kaybolma\r\n~2)batma (yıldız için)"
  },
  {
    "x": "vîndîbîyayîşê dejî (n)",
    "y": " analjezi"
  },
  {
    "x": "vîndîbîyayîye (m)",
    "y": " kayıp"
  },
  {
    "x": "vîndîkerdiş (n)",
    "y": " 1)kaybetme\r\n~2)yenilgi, bozgun, hezimet"
  },
  {
    "x": "vîneyîş (n)",
    "y": " b. vînayîş (n)"
  },
  {
    "x": "vînitene",
    "y": " b. vînayene"
  },
  {
    "x": "vînitiş (n)",
    "y": " b. vînayîş (n)"
  },
  {
    "x": "vînî",
    "y": " b. vîndî"
  },
  {
    "x": "vînî bîyayene",
    "y": " b. vîndî bîyene"
  },
  {
    "x": "vînî bîyene",
    "y": " b. vîndî bîyene"
  },
  {
    "x": "vînî kerdene",
    "y": " b. vîndî kerdene"
  },
  {
    "x": "vinî kerdeni",
    "y": " b. vîndî kerdene"
  },
  {
    "x": "vînîbîyaya (m)",
    "y": " b. vîndîbîyaya (m)"
  },
  {
    "x": "vînîbîyaye (n)",
    "y": " b. vîndîbîyaye (n)"
  },
  {
    "x": "vînîbîyayîş (n)",
    "y": " b. vîndîbîyayîş (n)"
  },
  {
    "x": "vînîkerdiş (n)",
    "y": " b. vîndîkerdiş (n)"
  },
  {
    "x": "vînîyayene",
    "y": " görülmek"
  },
  {
    "x": "vînîyayine",
    "y": " b. vînîyayene"
  },
  {
    "x": "vînîyayîne",
    "y": " b. vînîyayene"
  },
  {
    "x": "vînkerdiş (n)",
    "y": " b. vîndîkerdiş (n)"
  },
  {
    "x": "vîqayene",
    "y": " ötmek (kartal için)  "
  },
  {
    "x": "vîqayîş (n)",
    "y": " ötme (kartal için)"
  },
  {
    "x": "vîr (n)",
    "y": " bellek, hafıza\r\n~Vîrê mi çin o. (Belleğim yok/çok zayıf.)\r\n~\t\r\n~Vîro ke kewt mi ez ha kirmanckî qalî kena. (Kendimi bildim bileli Kırmancca konuşuyorum.)"
  },
  {
    "x": "vîr a şîyayene",
    "y": " b. vîr ra şîyene"
  },
  {
    "x": "vîr a şîyene",
    "y": " b. vîr ra şîyene"
  },
  {
    "x": "vîr ameyene",
    "y": " anımsamak, hatırlamak, aklına gelmek"
  },
  {
    "x": "vîr ardene",
    "y": " 1)anımsatmak, hatırlatmak; çağrıştırmak (akla getirmek)"
  },
  {
    "x": "vîr de bîyene",
    "y": " aklında olmak"
  },
  {
    "x": "vîr de mendene",
    "y": " aklında kalmak"
  },
  {
    "x": "vîr kewtene",
    "y": " anımsamak, hatırlamak, aklına gelmek\r\n~A game çimê aye kewtî vîrê mi. (O anda onun gözlerini anımsadım.)"
  },
  {
    "x": "vîr ra şîyene",
    "y": " unutmak\r\n~A game heme çî mi vîr ra şi. (O anda her şeyi unuttum.)"
  },
  {
    "x": "vîr şîyene",
    "y": " b. vîr ra şîyene"
  },
  {
    "x": "vîrdê mi ra",
    "y": " b. vîrê mi ra"
  },
  {
    "x": "vîrê mi ra",
    "y": " anımsadığımdan beri, hatırladığımdan beri"
  },
  {
    "x": "vîro flaş (n) (TE)",
    "y": " kalıcı bellek"
  },
  {
    "x": "vîraca (m)",
    "y": " geveze"
  },
  {
    "x": "vîrace (n)",
    "y": " geveze"
  },
  {
    "x": "vîrace kerdene",
    "y": " söylenmek (kendi kendine konuşmak)"
  },
  {
    "x": "vîracekerdiş (n)",
    "y": " söylenme (kendi kendine konuşma)"
  },
  {
    "x": "vîraceyê (m)",
    "y": " b. vîraceyîye (m), vîraceyî (m)"
  },
  {
    "x": "vîraceyê kerdene",
    "y": " b. vîraceyî kerdene, vîraceyîye kerdene"
  },
  {
    "x": "vîraceyêkerdiş (n)",
    "y": " b. vîraceyîkerdiş (n), vîraceyîyekerdiş (n)"
  },
  {
    "x": "vîraceyî (m)",
    "y": " gevezelik"
  },
  {
    "x": "vîraceyî kerdene",
    "y": " gevezelik etmek"
  },
  {
    "x": "vîraceyîkerdiş (n)",
    "y": " gevezelik etme"
  },
  {
    "x": "vîraceyîye (m)",
    "y": " gevezelik"
  },
  {
    "x": "vîraceyîye kerdene",
    "y": " gevezelik etmek"
  },
  {
    "x": "vîraceyîyekerdiş (n)",
    "y": " gevezelik etme"
  },
  {
    "x": "vîraj (n)",
    "y": " dönemeç, viraj"
  },
  {
    "x": "vîrameyîş (n)",
    "y": " 1)anımsama, hatırlama\r\n~2)anı, hatıra"
  },
  {
    "x": "vîrardiş (n)",
    "y": " 1)anımsatma, hatırlatma; çağrıştırma (akla getirme), çağrışım\r\n~2)ihtar"
  },
  {
    "x": "vîre (n)",
    "y": " b. vêre (n) "
  },
  {
    "x": "vîreta (m)",
    "y": " b. vîyartîye (m)"
  },
  {
    "x": "vîrete (n)",
    "y": " b. vîyarte (n)"
  },
  {
    "x": "vîretene",
    "y": " b. vîyartene"
  },
  {
    "x": "vîretiş (n)",
    "y": " b. vîyartiş (n)"
  },
  {
    "x": "vîrgul (n)",
    "y": " virgül"
  },
  {
    "x": "vîrhameyîş (n)",
    "y": " b. vîrameyîş (n)"
  },
  {
    "x": "vîrî (m)",
    "y": "  b. vîr (n)"
  },
  {
    "x": "vîrî kewtene",
    "y": " b. vîr kewtene"
  },
  {
    "x": "vîrî ra şîyene",
    "y": " b. vîr ra şîyene"
  },
  {
    "x": "vîrî şîyayîne",
    "y": " b. vîr ra şîyene"
  },
  {
    "x": "vîrîkewtiş (n)",
    "y": " b. vîrkewtiş (n)"
  },
  {
    "x": "vîrkewtiş (n)",
    "y": " anımsama, hatırlama"
  },
  {
    "x": "vîromeyîş (n)",
    "y": " b. vîrameyîş (n)"
  },
  {
    "x": "vîrtiş (n)",
    "y": " b. vîyartiş (n)"
  },
  {
    "x": "vîrus (n)",
    "y": " virüs\r\n~vîrusê korona (n): korona virüsü"
  },
  {
    "x": "vîrusê korona (n)",
    "y": " korona virüsü"
  },
  {
    "x": "vîrûmeyîş (n)",
    "y": " b. vîrameyîş (n)"
  },
  {
    "x": "vîskî (m)",
    "y": " b. wîskî (m)"
  },
  {
    "x": "vîst",
    "y": " yirmi"
  },
  {
    "x": "vîst kerdene",
    "y": " başarmak"
  },
  {
    "x": "vîstkerdiş (n)",
    "y": " başarma"
  },
  {
    "x": "vîste (m)",
    "y": " an, lahza"
  },
  {
    "x": "vîsteke (m)",
    "y": " b. vîstike (m)"
  },
  {
    "x": "vîsteki (m)",
    "y": " b. vîstike (m)"
  },
  {
    "x": "vîsti (m)",
    "y": " b. vîste (m)"
  },
  {
    "x": "vîstik (m)",
    "y": " b. vîstike (m)"
  },
  {
    "x": "vîstike (m)",
    "y": " an, lahza"
  },
  {
    "x": "vîstiki (m)",
    "y": " b. vîstike (m)"
  },
  {
    "x": "vîstin, -e",
    "y": " yirminci"
  },
  {
    "x": "vîş",
    "y": " b. vêşî"
  },
  {
    "x": "vîşane",
    "y": " b. vêşane"
  },
  {
    "x": "vîşî",
    "y": " b. vêşî"
  },
  {
    "x": "vîşî-kemî",
    "y": " b. vêşî-kêmî"
  },
  {
    "x": "vîşt",
    "y": " b. vîst"
  },
  {
    "x": "vîştare (n)",
    "y": " b. vîştira (n)"
  },
  {
    "x": "vîştene",
    "y": " b. fîştene"
  },
  {
    "x": "vîştera (n)",
    "y": " b. vîştira (n)"
  },
  {
    "x": "vîşteri (n)",
    "y": " b. vîştira (n)"
  },
  {
    "x": "vîştira (n)",
    "y": " tosun"
  },
  {
    "x": "vîştire (n)",
    "y": " tosun"
  },
  {
    "x": "vîştîra (n)",
    "y": " b. vîştira (n)"
  },
  {
    "x": "vîştra (n)",
    "y": " b. vîştira (n)"
  },
  {
    "x": "vît, -e-I",
    "y": " atik, çevik"
  },
  {
    "x": "vît, -e-II",
    "y": " (dikleşmiş olan) dik"
  },
  {
    "x": "vît kerdene",
    "y": " (hayvan kulağı vb. için) dikleştirmek, dikeltmek"
  },
  {
    "x": "Vîte (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "vîtene",
    "y": " b. vitene"
  },
  {
    "x": "vîtes (n)",
    "y": " b. vitês (n)"
  },
  {
    "x": "vîtevîta xo bîyayene",
    "y": " ötüp durmak"
  },
  {
    "x": "vîtê (m)",
    "y": " b. vîtî (m)"
  },
  {
    "x": "vîtî (m)",
    "y": " atiklik, çeviklik"
  },
  {
    "x": "vîtrîn (n)",
    "y": " camekan, vitrin"
  },
  {
    "x": "vîtrîne (m)",
    "y": " b. vîtrîn (n) "
  },
  {
    "x": "vîtvît kerdene",
    "y": " ötmek (serçe vb. kuşlar için)"
  },
  {
    "x": "vîtvîtkerdiş (n)",
    "y": " ötme (serçe vb. kuşlar için)  "
  },
  {
    "x": "vîyaduk (n)",
    "y": " köprü yol, viyadük"
  },
  {
    "x": "vîyal (m)",
    "y": " b. vîyale (m)"
  },
  {
    "x": "vîyale (m)",
    "y": " söğüt"
  },
  {
    "x": "vîyalêr (m)",
    "y": " b. vîyale (m)"
  },
  {
    "x": "vîyalêre (m)",
    "y": " b. vîyale (m)"
  },
  {
    "x": "Vîyana (m)",
    "y": " Viyana"
  },
  {
    "x": "Vîyangşan (n)",
    "y": " Vientian"
  },
  {
    "x": "vîyardene (n)",
    "y": " b. vîyartene"
  },
  {
    "x": "vîyardiş (n)",
    "y": " b. vîyartiş (n)"
  },
  {
    "x": "vîyarnayene",
    "y": " 1)geçirmek\r\n~2)geçinmek\r\n~3)geçinmek (başkasıyla geçinmek)\r\n~4)bulaştırmak (hastalık için)"
  },
  {
    "x": "vîyarnayini",
    "y": " b. vîyarnayene"
  },
  {
    "x": "vîyarnayîş (n)",
    "y": " 1)geçirme\r\n~2)geçinme\r\n~3)geçinme (başkasıyla geçinme)\r\n~4)bulaştırma (hastalık için)"
  },
  {
    "x": "vîyarnker, -e",
    "y": " iletken"
  },
  {
    "x": "vîyarnkerîye (m)",
    "y": " iletkenlik"
  },
  {
    "x": "vîyarta (m)",
    "y": " 1)geçmiş\r\n~2)kart (gençliği ve körpeliği kalmamış olan)"
  },
  {
    "x": "vîyartbar, -e",
    "y": " geçerli, meri; cari"
  },
  {
    "x": "vîyartbarî (m)",
    "y": " geçerlilik, meriyet, yürürlük"
  },
  {
    "x": "vîyartbarîye (m)",
    "y": " geçerlilik, meriyet, yürürlük"
  },
  {
    "x": "vîyarte (n)",
    "y": " 1)geçmiş\r\n~2)kart (gençliği ve körpeliği kalmamış olan)"
  },
  {
    "x": "vîyartene",
    "y": " 1)geçmek\r\n~2)akmak (zaman için)\r\n~3)bulaşmak (hastalık için)"
  },
  {
    "x": "vîyartiş (n)",
    "y": " 1)geçme\r\n~2)geçiş\r\n~3)akma (zaman için)\r\n~4)bulaşma (hastalık için)\r\n~5)çıkarsama (felsefede), intikal (felsefede)"
  },
  {
    "x": "vîyartişê demî (n)",
    "y": " süre aşımı, zaman aşımı, müruruzaman"
  },
  {
    "x": "vîyartişê sehneyan (zh, sn)",
    "y": " sahne geçişleri"
  },
  {
    "x": "vîyartişo vilêşîyaye (sn)",
    "y": " erime geçiş, zincirleme geçiş"
  },
  {
    "x": "vîyartîye (m)",
    "y": " kart (gençliği ve körpeliği kalmamış olan)"
  },
  {
    "x": "vîyaş (m)",
    "y": " b. vîyaşa (m)"
  },
  {
    "x": "vîyaşa (m)",
    "y": " akarsuyun ana kolundan ayrılan yan kolu"
  },
  {
    "x": "vîye-I (n)",
    "y": " boyun"
  },
  {
    "x": "vîyeyê cile/çinayî/kincî (n)",
    "y": " elbisenin boyna arkadan oturan bölümü"
  },
  {
    "x": "vîye-II (n)",
    "y": " b. ver-I (n)"
  },
  {
    "x": "vîyeçewt, -e",
    "y": " boynu bükük"
  },
  {
    "x": "vîyer-I (n)",
    "y": " geçit, derbent"
  },
  {
    "x": "vîyerê asinrayîrî",
    "y": " demiryolu geçidi, hemzemin geçit"
  },
  {
    "x": "vîyerê mektebî (n)",
    "y": " okul geçidi"
  },
  {
    "x": "vîyerê wendegehî (n)",
    "y": " okul geçidi"
  },
  {
    "x": "vîyero hemzemîn",
    "y": " demiryolu geçidi, hemzemin geçit"
  },
  {
    "x": "vîyer-II (n)",
    "y": " b. ver-I (n)"
  },
  {
    "x": "vîyernayene",
    "y": " b. vîyarnayene"
  },
  {
    "x": "vîyerr (n)",
    "y": " b. vîyer (n)"
  },
  {
    "x": "vîyerta (n)",
    "y": " b. vîyartîye (m)"
  },
  {
    "x": "vîyerte (n)",
    "y": " b. vîyarte (n)"
  },
  {
    "x": "vîyertene",
    "y": " b. vîyartene"
  },
  {
    "x": "vîyertiş (n)",
    "y": " b. vîyartiş (n)"
  },
  {
    "x": "vîyêrr (m)",
    "y": " b. vere-I (m)"
  },
  {
    "x": "vîyêrr eştene",
    "y": " b. vere eştene"
  },
  {
    "x": "vîyêrr vistene",
    "y": " b. vere fîştene"
  },
  {
    "x": "vîyêrrey mar (m)",
    "y": " b. vera marî (m)"
  },
  {
    "x": "vîyêrreştiş (n)",
    "y": " b. vereeştiş (n)"
  },
  {
    "x": "vîyêrrvistiş (n)",
    "y": " b. verefîştiş (n)"
  },
  {
    "x": "vîyolonsele (m)",
    "y": " viyolonsel "
  },
  {
    "x": "vîyolonselîst, -e",
    "y": " viyolonselci"
  },
  {
    "x": "vîyvi-I (m)",
    "y": " b. veyve-I (m)"
  },
  {
    "x": "vîyvi-II (n)",
    "y": " b. veyve-II (n)"
  },
  {
    "x": "vîzêr (n)",
    "y": " b. vizêr (n)"
  },
  {
    "x": "vîzile (m)",
    "y": " parçacık"
  },
  {
    "x": "vîzilî bîyene",
    "y": " parçacıklara ayrılmak"
  },
  {
    "x": "vîzilî kerdene",
    "y": " parçacıklara ayırmak"
  },
  {
    "x": "vîzili (m)",
    "y": " b. vîzile (m)"
  },
  {
    "x": "vîzilîbîyayîş (n)",
    "y": " parçacıklara ayrılma"
  },
  {
    "x": "vîzilîkerdiş (n)",
    "y": " parçacıklara ayırma"
  },
  {
    "x": "vîzîr (n)",
    "y": " b. vizêr (n)"
  },
  {
    "x": "vîznayene",
    "y": " b. vîjnayene"
  },
  {
    "x": "vîznayîş (n)",
    "y": " b. vîjnayîş (n)"
  },
  {
    "x": "vîzo",
    "y": " b. vîzoye (m)"
  },
  {
    "x": "vîzoye (m)",
    "y": " karış"
  },
  {
    "x": "vîzyon (n)",
    "y": " vizyon"
  },
  {
    "x": "vîzzili (m)",
    "y": " b. vîzile (m)"
  },
  {
    "x": "vîzzilî bîyayene",
    "y": " b. vîzilî bîyene"
  },
  {
    "x": "vîzzilî kerdene",
    "y": " b. vîzilî kerdene"
  },
  {
    "x": "vîzzilîbîyayîş (n)",
    "y": " b. vîzilîbîyayîş (n)"
  },
  {
    "x": "vîzzilîkerdiş (n)",
    "y": " b. vîzilîkerdiş (n)"
  },
  {
    "x": "vojdayene",
    "y": " b. vazdayene"
  },
  {
    "x": "voleybol (n)",
    "y": " voleybol"
  },
  {
    "x": "volkan (n)",
    "y": " yanardağ, volkan"
  },
  {
    "x": "volkanin, -e",
    "y": " volkanik"
  },
  {
    "x": "volkanîk, -e",
    "y": " volkanik"
  },
  {
    "x": "Volta (m)",
    "y": " b. Bûrkîna Faso"
  },
  {
    "x": "vom (m)",
    "y": " b. vame (m)"
  },
  {
    "x": "vomeki (m)",
    "y": " b. vameke (m)"
  },
  {
    "x": "vomêri (m)",
    "y": " b. vamêre (m)"
  },
  {
    "x": "vomêr (m)",
    "y": " b. vamêre (m)"
  },
  {
    "x": "vomi (m)",
    "y": " b. vame (m)"
  },
  {
    "x": "vor (m)",
    "y": " b. vewre (m)"
  },
  {
    "x": "vor de",
    "y": " aşağıya doğru"
  },
  {
    "x": "vor ro",
    "y": " yukarıya doğru"
  },
  {
    "x": "vorayene",
    "y": " b. vareyene"
  },
  {
    "x": "vorayîs (n)",
    "y": " b. varayîş (n)"
  },
  {
    "x": "vorayîş (n)",
    "y": " b. varayîş (n)"
  },
  {
    "x": "vorbelek",
    "y": " b. vewrbelek"
  },
  {
    "x": "vore (m)",
    "y": " b. vewre (m)"
  },
  {
    "x": "voregude (m)",
    "y": " b. vewrgude (m)"
  },
  {
    "x": "vorek, -e",
    "y": " b. verek, -e"
  },
  {
    "x": "vorek ardene",
    "y": " b. verek ardene"
  },
  {
    "x": "vorgud (m)",
    "y": " b. vewrgude (m)"
  },
  {
    "x": "vorguid (m)",
    "y": " b. vewrgude (m)"
  },
  {
    "x": "vori (m)",
    "y": " b. vewre (m)"
  },
  {
    "x": "vorib (m)",
    "y": " b. vewrribe (m)"
  },
  {
    "x": "vorînayeni",
    "y": " b. vurnayene"
  },
  {
    "x": "vorînayiş (n)",
    "y": " b. vurnayîş (n)"
  },
  {
    "x": "vornaene",
    "y": " b. varnayene"
  },
  {
    "x": "vornaîs (n)",
    "y": " b. varnayîş (n)"
  },
  {
    "x": "vornayene",
    "y": " b. varnayene"
  },
  {
    "x": "vornayîs (n)",
    "y": " b. varnayîş (n)"
  },
  {
    "x": "vornayîş (n)",
    "y": " b. varnayîş (n)"
  },
  {
    "x": "vorte (m)",
    "y": " b. varte (m)"
  },
  {
    "x": "vortiş (n)",
    "y": " b. varitiş (n)"
  },
  {
    "x": "voryes (n)",
    "y": " b. vewrês (n)"
  },
  {
    "x": "vos ci kerdene",
    "y": " azarlamak, paylamak, tekdir etmek"
  },
  {
    "x": "vos ra ... bîyene",
    "y": " azarlanmak"
  },
  {
    "x": "vos ra ... kerdene",
    "y": " azarlamak, paylamak, tekdir etmek"
  },
  {
    "x": "vosn (n)",
    "y": " koç"
  },
  {
    "x": "vosn ameyene",
    "y": " kösnümek (koyun için)"
  },
  {
    "x": "vosn dayene",
    "y": " (koyun için) çiftleştirmek"
  },
  {
    "x": "voscikerdîs (n)",
    "y": " b. voscikerdiş (n)"
  },
  {
    "x": "voscikerdiş (n)",
    "y": " azarlama, melamet, paylama, tekdir etme"
  },
  {
    "x": "voscikerdox, -e",
    "y": " azarlayan"
  },
  {
    "x": "vosnameyîş (n)",
    "y": " kösnüme (koyun için)"
  },
  {
    "x": "vosra ... bîyayîş",
    "y": " azarlanma"
  },
  {
    "x": "vosra ... kerdiş",
    "y": " azarlama, melamet, paylama, tekdir etme"
  },
  {
    "x": "vostaîne",
    "y": " b. vostayene"
  },
  {
    "x": "vostaîs (n)",
    "y": " b. vostayîş (n)"
  },
  {
    "x": "vostayene",
    "y": " azarlamak, paylamak, tekdir etmek"
  },
  {
    "x": "vostayîne",
    "y": " b. vostayene"
  },
  {
    "x": "vostayîs (n)",
    "y": " b. vostayîş (n)"
  },
  {
    "x": "vostayîş (n)",
    "y": " azarlama, melamet, paylama, tekdir etme"
  },
  {
    "x": "vostene",
    "y": " b. vazdayene"
  },
  {
    "x": "vostis (n)",
    "y": " b. vazdayîş (n)"
  },
  {
    "x": "voşîyayene",
    "y": " azarlanmak"
  },
  {
    "x": "voşîyayîş (n)",
    "y": " azarlanma"
  },
  {
    "x": "voştiş (n)",
    "y": " b. vazdayîş (n)"
  },
  {
    "x": "votka (m)",
    "y": " votka"
  },
  {
    "x": "vowr (m)",
    "y": " b. vewre (m)"
  },
  {
    "x": "vowrbelek",
    "y": " b. vewrbelek"
  },
  {
    "x": "vowre (m)",
    "y": " b. vewre (m)"
  },
  {
    "x": "vowrês (n)",
    "y": " b. vewrês (n)"
  },
  {
    "x": "vowri (m)",
    "y": " b. vewre (m)"
  },
  {
    "x": "vowrib (m)",
    "y": " b. vewrribe (m)"
  },
  {
    "x": "vowrîs (n)",
    "y": " b. vewrês (n)"
  },
  {
    "x": "voyri (m)",
    "y": " b. vewre (m)"
  },
  {
    "x": "vozd (n)",
    "y": " b. vezd (n)"
  },
  {
    "x": "vozdaene",
    "y": " b. vazdayene"
  },
  {
    "x": "vozdaîs (n)",
    "y": " b. vazdayîş (n)"
  },
  {
    "x": "vozdayene",
    "y": " b. vazdayene"
  },
  {
    "x": "vozdayêne",
    "y": " b. vazdayene "
  },
  {
    "x": "vozdayîs (n)",
    "y": " b. vazdayîş (n)"
  },
  {
    "x": "vozdayîş (n)",
    "y": " b. vazdayîş (n)"
  },
  {
    "x": "voznaene",
    "y": " b. vazdnayene"
  },
  {
    "x": "voznaîs (n)",
    "y": " b. vazdnayîş (n)"
  },
  {
    "x": "voznayene",
    "y": " b. vazdnayene"
  },
  {
    "x": "voznayîs (n)",
    "y": " b. vazdnayîş (n)"
  },
  {
    "x": "vucut (n)",
    "y": " b. wucud (n)"
  },
  {
    "x": "vucût (n)",
    "y": " b. wucud (n)"
  },
  {
    "x": "vuncue (n)",
    "y": " b. vîncewe (m)"
  },
  {
    "x": "vuncuwe (n)",
    "y": " b. vîncewe (m)"
  },
  {
    "x": "vurîbar, -e",
    "y": " değişken"
  },
  {
    "x": "vurîyaya (m)",
    "y": " değişik"
  },
  {
    "x": "vurîyaye (n)",
    "y": " değişik"
  },
  {
    "x": "vurîyayene",
    "y": " 1)değişmek\r\n~2)başkalaşmak"
  },
  {
    "x": "vurîyayî, -ye",
    "y": " değişik"
  },
  {
    "x": "vurîyayîs (n)",
    "y": " b. vurîyayîş (n)"
  },
  {
    "x": "vurîyayîş (n)",
    "y": " 1)değişme, değişim, değişiklik\r\n~2)başkalaşma"
  },
  {
    "x": "vurîyayîşê adrese (n)",
    "y": " adres değişikliği"
  },
  {
    "x": "vurîyayîşê awanî (n)",
    "y": " imar değişikliği"
  },
  {
    "x": "vurîyayîşê cayî (n)",
    "y": " deplase"
  },
  {
    "x": "vurnaya (m)",
    "y": " değişik"
  },
  {
    "x": "vurnaye (n)",
    "y": " değişik"
  },
  {
    "x": "vurnayene",
    "y": " değiştirmek\r\n~\tBivurne! (Değiştir!)"
  },
  {
    "x": "vurnayî, -ye",
    "y": " değişik"
  },
  {
    "x": "vurnayîne",
    "y": " b. vurnayene"
  },
  {
    "x": "vurnayîs (n)",
    "y": " b. vurnayîş (n)"
  },
  {
    "x": "vurnayîş (n)",
    "y": " değiştirme"
  },
  {
    "x": "vurnayîşê awanî (zh)",
    "y": " imar tadilatı"
  },
  {
    "x": "vurnayîşê cayî (n)",
    "y": " deplase"
  },
  {
    "x": "vurnayîşê planî (zh)",
    "y": " plan tadilatı"
  },
  {
    "x": "vurnayîşî (zh)",
    "y": " tadilat"
  },
  {
    "x": "vurnker (n)",
    "y": " değiştirgeç, konvertisör, röle\r\n~vurnkerê germî (n): ısı değiştirgeci\r\n~vurnkerê tanî (n): ısı değiştirgeci"
  },
  {
    "x": "vurnkerê germî (n)",
    "y": " ısı değiştirgeci"
  },
  {
    "x": "vurnkerê tanî (n)",
    "y": " ısı değiştirgeci"
  },
  {
    "x": "vurrîyayene",
    "y": " b. vurîyayene"
  },
  {
    "x": "vurrîyayêne",
    "y": " b. vurîyayene"
  },
  {
    "x": "vurrîyayîs (n)",
    "y": " b. vurîyayîş (n)"
  },
  {
    "x": "vurrîyayîş (n)",
    "y": " b. vurîyayîş (n)"
  },
  {
    "x": "vurrnayene",
    "y": " b. vurnayene"
  },
  {
    "x": "vurrnayêne",
    "y": " b. vurnayene"
  },
  {
    "x": "vurrnayîs (n)",
    "y": " b. vurnayîş (n)"
  },
  {
    "x": "vurrnayîş (n)",
    "y": " b. vurnayîş (n)"
  },
  {
    "x": "vuzêr (n)",
    "y": " b. vizêr (n)"
  },
  {
    "x": "vûm (m)",
    "y": " b. vame (m)"
  },
  {
    "x": "vûmeki (m)",
    "y": " b. vameke (m)"
  },
  {
    "x": "vûmêri (m)",
    "y": " b. vamêre (m)"
  },
  {
    "x": "vûmêr (m)",
    "y": " b. vamêre (m)"
  },
  {
    "x": "vûmi (m)",
    "y": " b. vame (m)"
  },
  {
    "x": "vûmîyer (m)",
    "y": " b. vamêre (m)"
  },
  {
    "x": "vûmyer (m)",
    "y": " b. vamêre (m)"
  },
  {
    "x": "WWW"
  },
  {
    "x": "wa",
    "y": " b. ya-IV"
  },
  {
    "x": "wa-II (m)",
    "y": " b. waye (m)"
  },
  {
    "x": "wa-III (pd)",
    "y": " b. ra-II (pd)"
  },
  {
    "x": "waar (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "waarê (m)",
    "y": " b. wayîrîye (m)"
  },
  {
    "x": "waaz (n)",
    "y": " b. weaze (m)"
  },
  {
    "x": "wacîyayîş (n)",
    "y": " b. vajîyayîş (n)"
  },
  {
    "x": "wad (n)",
    "y": " 1)söz\r\n~2)adak"
  },
  {
    "x": "wad kerdene",
    "y": " 1)söz vermek\r\n~2)adamak"
  },
  {
    "x": "wadardene",
    "y": " b. wedaritene"
  },
  {
    "x": "wadardiş (n)",
    "y": " b. wedaritiş (n)"
  },
  {
    "x": "wadarnayini",
    "y": " b. wedarnayene"
  },
  {
    "x": "wade-I (n)",
    "y": " mühlet, vade"
  },
  {
    "x": "wade dayene",
    "y": " mühlet vermek"
  },
  {
    "x": "wade-II (n)",
    "y": " b. oda-I (m)"
  },
  {
    "x": "wadedayîş (n)",
    "y": " mühlet verme"
  },
  {
    "x": "wadi (n)",
    "y": " b. oda-I (m)"
  },
  {
    "x": "wadkerde (n)",
    "y": " adak"
  },
  {
    "x": "wadkerdiş (n)",
    "y": " adama"
  },
  {
    "x": "wadkerdî, -ye",
    "y": " adak"
  },
  {
    "x": "wadnaye (n)",
    "y": " adak"
  },
  {
    "x": "wadnayene",
    "y": " adamak"
  },
  {
    "x": "wadnayêne",
    "y": " b. wadnayene"
  },
  {
    "x": "wadnayî, -ye",
    "y": " adak"
  },
  {
    "x": "wadnayîş (n)",
    "y": " adama"
  },
  {
    "x": "wae (m)",
    "y": " b. waye (m) "
  },
  {
    "x": "waeqî (m)",
    "y": " tilki sesi"
  },
  {
    "x": "waez (n)",
    "y": " b. weaze (m)"
  },
  {
    "x": "Wagadûgû (n)",
    "y": " Uagadugu"
  },
  {
    "x": "wahar (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "waharê derdî (m)",
    "y": " b. wayîra derdî (m)"
  },
  {
    "x": "waharî derdî (n)",
    "y": " b. wayîrê derdî (n)"
  },
  {
    "x": "waharê (m)",
    "y": " b. wayîrîye (m)"
  },
  {
    "x": "wahd (n)",
    "y": " b. wad (n)"
  },
  {
    "x": "wahd kerdene",
    "y": " b. wad kerdene"
  },
  {
    "x": "wahdkerdiş (n)",
    "y": " b. wadkerdiş (n)"
  },
  {
    "x": "wahdnayene",
    "y": " b. wadnayene"
  },
  {
    "x": "wahdnayîş (n)",
    "y": " b. wadnayîş (n)"
  },
  {
    "x": "wahêr (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "wahir (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "wahirê (m)",
    "y": " b. wayîrîye (m)"
  },
  {
    "x": "wahîr, -e",
    "y": " b. wayîr, -e"
  },
  {
    "x": "wahîrê (m)",
    "y": " b. wayîrîye (m)"
  },
  {
    "x": "waiz, -e",
    "y": " vaiz"
  },
  {
    "x": "waîz, -e",
    "y": " b. waiz, -e"
  },
  {
    "x": "wak (m)",
    "y": " b. wake (m)"
  },
  {
    "x": "wake (m)",
    "y": " 1)abla\r\n~2)ahbap\r\n~3)b. wayike (m)"
  },
  {
    "x": "wakê",
    "y": " abla (hitap hali)"
  },
  {
    "x": "wakey (m)",
    "y": " b. wakîye (m)"
  },
  {
    "x": "wakêyey (m)",
    "y": " b. wakîye (m)"
  },
  {
    "x": "wakêyî (m)",
    "y": " ablalık"
  },
  {
    "x": "waki (m)",
    "y": " b. wake (m)"
  },
  {
    "x": "wakile (m)",
    "y": " ablacık "
  },
  {
    "x": "wakilê (m)",
    "y": " ablacığım (hitap hali)"
  },
  {
    "x": "wakîye (m)",
    "y": " 1)ablalık\r\n~2)ahbaplık "
  },
  {
    "x": "walêze (m)",
    "y": " b. valîze (m)"
  },
  {
    "x": "walî, -ye",
    "y": " vali"
  },
  {
    "x": "walîtî (m)",
    "y": " vilayet"
  },
  {
    "x": "walîyîye (m)",
    "y": " vilayet"
  },
  {
    "x": "walîze (m)",
    "y": " b. valîze (m)"
  },
  {
    "x": "wallayî",
    "y": " b. willayî"
  },
  {
    "x": "wandene",
    "y": " b. wendene"
  },
  {
    "x": "wandiş (n)",
    "y": " b. wendiş (n)"
  },
  {
    "x": "wanîyayene",
    "y": " okunmak"
  },
  {
    "x": "nêwanîya, -ye",
    "y": " okunmadı"
  },
  {
    "x": "wanîyayîş (n)",
    "y": " okunma"
  },
  {
    "x": "waqayene",
    "y": " çığlık atmak, çığlığı basmak, çığlık koparmak"
  },
  {
    "x": "waqayîş (n)",
    "y": " çığlık atma, çığlığı basma, çığlık koparma"
  },
  {
    "x": "waqewaq (m)",
    "y": " b. waqewaqe (m)"
  },
  {
    "x": "waqewaq kerdene",
    "y": " b. waqewaqe kerdene"
  },
  {
    "x": "waqe-waqe",
    "y": " b. waqewaqe (m)"
  },
  {
    "x": "waqewaqe (m)",
    "y": " bağırtı, bağırış, çığlık çığlığa"
  },
  {
    "x": "waqewaqe kerdene",
    "y": " bağırmak"
  },
  {
    "x": "waqewaqekerdiş (n)",
    "y": " bağırma"
  },
  {
    "x": "waqewaqkerdiş (n)",
    "y": " waqewaqekerdiş (n)"
  },
  {
    "x": "waqit (n)",
    "y": " b. wext (n)"
  },
  {
    "x": "waqî (m)",
    "y": " bağırtı, bağırış, çığlık"
  },
  {
    "x": "waqî dekewtene",
    "y": " çığlık atmak, çığlığı basmak, çığlık koparmak"
  },
  {
    "x": "waqî dekotene",
    "y": " b. waqî dekewtene"
  },
  {
    "x": "waqî dekowtene",
    "y": " b. waqî dekewtene"
  },
  {
    "x": "waqîdekewtiş (n)",
    "y": " çığlık atma, çığlığı basma, çığlık koparma"
  },
  {
    "x": "waqîdekotiş (n)",
    "y": " b. waqîdekewtiş (n)"
  },
  {
    "x": "waqîdekowtiş (n)",
    "y": " b. waqîdekewtiş (n)"
  },
  {
    "x": "waqînî (m)",
    "y": " bağırtı, bağırış, çığlık"
  },
  {
    "x": "waqît (n)",
    "y": " b. wext (n)"
  },
  {
    "x": "war-I (n)",
    "y": " alan, saha"
  },
  {
    "x": "warê afatan (zh)",
    "y": " afet alanları"
  },
  {
    "x": "warê arîyetî (n)",
    "y": " ariyet alanı (huq.)"
  },
  {
    "x": "warê banan (n)",
    "y": " konut alanı"
  },
  {
    "x": "warê bîna (n)",
    "y": " bina alanı"
  },
  {
    "x": "warê biserûberkerdişî (n)",
    "y": " düzenleme sahası"
  },
  {
    "x": "warê caardişî (n)",
    "y": " uygulama alanı"
  },
  {
    "x": "warê ceza (n)",
    "y": " ceza alanı, ceza sahası (futbol, hentbol vb. de)"
  },
  {
    "x": "warê çatrayîrî yo hempar (n)",
    "y": " kavşak ortak alanı"
  },
  {
    "x": "warê dezgehê fermî (n)",
    "y": " resmî kurum alanı"
  },
  {
    "x": "warê eleqeyî (n)",
    "y": " ilgi alanı"
  },
  {
    "x": "warê endustrîyî (n)",
    "y": " sanayi alanı"
  },
  {
    "x": "warê gurenîyayîşî (n)",
    "y": " kullanım alanı"
  },
  {
    "x": "warê herême",
    "y": " mücavir alan"
  },
  {
    "x": "warê înşaatî (n)",
    "y": " inşaat alanı"
  },
  {
    "x": "warê kêşe (n)",
    "y": " stok sahası"
  },
  {
    "x": "warê koxbanî (n)",
    "y": " gecekondu alanı"
  },
  {
    "x": "warê lode (n)",
    "y": " stok sahası"
  },
  {
    "x": "warê mektebî (n)",
    "y": " okul alanı"
  },
  {
    "x": "warê mera (n)",
    "y": " mera alanı"
  },
  {
    "x": "warê meskenan (n)",
    "y": " konut alanı"
  },
  {
    "x": "warê mîrasî (n)",
    "y": " miras alanı"
  },
  {
    "x": "warê muhafezeyî yo tebîî (n)",
    "y": " doğal koruma alanı"
  },
  {
    "x": "warê pawitişî yo xozayî (n)",
    "y": " doğal koruma alanı"
  },
  {
    "x": "warê pergalkerdişî (n)",
    "y": " düzenleme sahası"
  },
  {
    "x": "warê pêserkerdişê lince (n)",
    "y": " çamur depolama alanı"
  },
  {
    "x": "warê projeyî (n)",
    "y": " proje alanı"
  },
  {
    "x": "warê rîverîye (n)",
    "y": " yüzey alanı"
  },
  {
    "x": "warê sazgehê dînî (n)",
    "y": " dini tesis alanı"
  },
  {
    "x": "warê sazgehê kulturî (n)",
    "y": " kültürel tesis alanı"
  },
  {
    "x": "warê sazgehê perwerdeyî (n)",
    "y": " eğitim tesisi alanı"
  },
  {
    "x": "warê sazgehî yo komelkî (n)",
    "y": " sosyal tesis alanı"
  },
  {
    "x": "warê sazgehî yo sosyal (n)",
    "y": " sosyal tesis alanı"
  },
  {
    "x": "warê seveknayîşî yo tebîî (n)",
    "y": " doğal koruma alanı"
  },
  {
    "x": "warê sîtî (n)",
    "y": " sit alanı"
  },
  {
    "x": "warê stokî (n)",
    "y": " stok sahası"
  },
  {
    "x": "warê şumûlî (n)",
    "y": " kapsama alanı"
  },
  {
    "x": "warê şuxulnîyayîşî (n)",
    "y": " kullanım alanı"
  },
  {
    "x": "warê tapûyî (n)",
    "y": " tapu alanı"
  },
  {
    "x": "warê tenîsî (n)",
    "y": " tenis sahası"
  },
  {
    "x": "warê tertîbkerdişî (n)",
    "y": " düzenleme sahası"
  },
  {
    "x": "warê tesîsê dînî (n)",
    "y": " dini tesis alanı"
  },
  {
    "x": "warê tesîsê kulturî (n)",
    "y": " kültürel tesis alanı"
  },
  {
    "x": "warê tesîsê perwerdeyî (n)",
    "y": " eğitim tesisi alanı"
  },
  {
    "x": "warê tesîsî yo cematkî (n)",
    "y": " sosyal tesis alanı"
  },
  {
    "x": "warê wendegehî (n)",
    "y": " okul alanı"
  },
  {
    "x": "warê xebitnîyayîşî (n)",
    "y": " kullanım alanı"
  },
  {
    "x": "warê xizmetî yê şaredarî",
    "y": " belediye hizmet alanı"
  },
  {
    "x": "warê yewbînîserotesîrkerdişî (n)",
    "y": " etkileşim sahası"
  },
  {
    "x": "warê zemînî (n)",
    "y": " taban alanı"
  },
  {
    "x": "warê zemînî yo brut (n)",
    "y": " brüt taban alanı"
  },
  {
    "x": "warê zîretî (n)",
    "y": " tarım alanı"
  },
  {
    "x": "waro akerde (n)",
    "y": " açık alan"
  },
  {
    "x": "waro bajarkî (n)",
    "y": " kentsel alan"
  },
  {
    "x": "waro bazirganî (n)",
    "y": " ticari alan"
  },
  {
    "x": "waro cîran (n)",
    "y": " mücavir alan"
  },
  {
    "x": "waro embiryan (n)",
    "y": " mücavir alan"
  },
  {
    "x": "waro manyetîk (n)",
    "y": " manyetik alan"
  },
  {
    "x": "waro nêherimîyaye (n)",
    "y": " bozulmamış alan"
  },
  {
    "x": "waro nêxeripîyaye (n)",
    "y": " bozulmamış alan"
  },
  {
    "x": "waro pêroyî (n)",
    "y": " kamusal alan"
  },
  {
    "x": "waro rakerde (n)",
    "y": " açık alan"
  },
  {
    "x": "waro şaristankî (n)",
    "y": " kentsel alan"
  },
  {
    "x": "waro tîcarî (n)",
    "y": " ticari alan"
  },
  {
    "x": "waro umûmî (n)",
    "y": " kamusal alan"
  },
  {
    "x": "war-II",
    "y": " aşağı\r\n~Şi war. (Aşağı indi.)"
  },
  {
    "x": "war ameyene",
    "y": " inmek"
  },
  {
    "x": "war ardene (TE)",
    "y": " indirmek"
  },
  {
    "x": "war bîyene",
    "y": " dökülmek"
  },
  {
    "x": "war kerdene",
    "y": " dökmek"
  },
  {
    "x": "war-III (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "war-IV (m)",
    "y": " b. ware-I (m)"
  },
  {
    "x": "warameyîş (n)",
    "y": " inme"
  },
  {
    "x": "warardiş (n) (TE)",
    "y": " indirme"
  },
  {
    "x": "waraza (n)",
    "y": " b. wareza (n)"
  },
  {
    "x": "waraznayene",
    "y": " b. weriznayene"
  },
  {
    "x": "waraznayîş (n",
    "y": " b. weriznayîş (n)"
  },
  {
    "x": "warbay, -i",
    "y": " b. warpay, -e"
  },
  {
    "x": "warbîyayîş (n)",
    "y": " dökülme"
  },
  {
    "x": "wardene",
    "y": " b. werdene"
  },
  {
    "x": "wardiş (n)",
    "y": " b. werdiş (n)"
  },
  {
    "x": "wardîyes (m)",
    "y": " b. herdose (m)"
  },
  {
    "x": "ware-I (m)",
    "y": " bacı, kız kardeş"
  },
  {
    "x": "ware-II (n)",
    "y": " 1)yayla\r\n~2)yerleşim yeri"
  },
  {
    "x": "ware-III (n)",
    "y": " 1)meydan\r\n~2)b. war-I (n)"
  },
  {
    "x": "ware ameyene",
    "y": " oluşmak"
  },
  {
    "x": "ware ardene",
    "y": " 1)oluşturmak\r\n~2)yaratmak\r\n~3)ihdas etmek"
  },
  {
    "x": "wareameyîş (n)",
    "y": " oluşma, oluşum"
  },
  {
    "x": "wareardiş (n)",
    "y": " 1)oluşturma\r\n~2)yaratma\r\n~3)ihdas"
  },
  {
    "x": "wareardişê kadroyî",
    "y": " kadro ihdası"
  },
  {
    "x": "wareza (n)",
    "y": " kız kardeşin oğlu, yeğen"
  },
  {
    "x": "warezaye (m)",
    "y": " kız kardeşin kızı, yeğen"
  },
  {
    "x": "wareznayene",
    "y": " b. weriznayene"
  },
  {
    "x": "wareznayîş (n)",
    "y": " b. weriznayîş (n)"
  },
  {
    "x": "wari-I (m)",
    "y": " b. ware-I (m)"
  },
  {
    "x": "wari-II (n)",
    "y": " 1)b. ware-II (n)\r\n~2)b. war-I (n)"
  },
  {
    "x": "waris, -e",
    "y": " kalıtçı, varis"
  },
  {
    "x": "wariştene",
    "y": " b. weriştene"
  },
  {
    "x": "wariştiş (n)",
    "y": " b. weriştiş (n)"
  },
  {
    "x": "wariza (n)",
    "y": " b. wareza (n)"
  },
  {
    "x": "wariznayene",
    "y": " b. weriznayene"
  },
  {
    "x": "wariznayîş (n)",
    "y": " b. weriznayîş (n)"
  },
  {
    "x": "warî-I (m)",
    "y": " bacılık (kardeşlik)"
  },
  {
    "x": "warî-II (m)",
    "y": " b. wayîrî (m)"
  },
  {
    "x": "warîdat (n)",
    "y": " gelir, hâsılat, varidat\r\n~warîdato rojane: günlük gelir"
  },
  {
    "x": "warîdato rojane",
    "y": " günlük gelir"
  },
  {
    "x": "waret (n)",
    "y": " b. warîdat (n)"
  },
  {
    "x": "warîştene",
    "y": " b. weriştene"
  },
  {
    "x": "warîştiş (n)",
    "y": " b. weriştiş (n)"
  },
  {
    "x": "warîyat (n)",
    "y": " b. warîdat (n)"
  },
  {
    "x": "warîye (m)",
    "y": " bacılık (kardeşlik)"
  },
  {
    "x": "warîyet (n)",
    "y": " b. warîdat (n)"
  },
  {
    "x": "warkerdiş (n)",
    "y": " dökme"
  },
  {
    "x": "warkeyna (m)",
    "y": " b. warkêna (m)"
  },
  {
    "x": "warkêna (m)",
    "y": " kız kardeşin kızı, yeğen"
  },
  {
    "x": "waro",
    "y": " aşağı"
  },
  {
    "x": "waro dayene (elewîyîye de)",
    "y": " düşkün ilan etmek (Alevilikte)\r\n~Pîrî Hesen raye ra do waro. (Pir, Hasan’ı düşkün ilan etmiş.)"
  },
  {
    "x": "waroginaye (n) (elewîyîye de)",
    "y": " düşkün (Alevilikte)"
  },
  {
    "x": "waroginayîye (m) (elewîyîye de)",
    "y": " düşkün (Alevilikte)"
  },
  {
    "x": "warpay, -e",
    "y": " yalın ayak"
  },
  {
    "x": "warro",
    "y": " b. waro"
  },
  {
    "x": "warroginaye (n) (elewîyîye de)",
    "y": " b. waroginaye (n) (elewîyîye de)"
  },
  {
    "x": "warroginayîye (m) (elewîyîye de)",
    "y": " b. waroginayîye (m) (elewîyîye de)"
  },
  {
    "x": "wars (n)",
    "y": " b. wers-I (n)"
  },
  {
    "x": "Warşowa (m)",
    "y": " Varşova"
  },
  {
    "x": "warway, -i",
    "y": " b. warpay, -e"
  },
  {
    "x": "warwey, -e",
    "y": " b. warpay, -e"
  },
  {
    "x": "warxan (n)",
    "y": " b. orxan (n)"
  },
  {
    "x": "warye (n)",
    "y": " 1)b. war-I (n)\r\n~2)b. ware-III (n)"
  },
  {
    "x": "warye ardene",
    "y": " b. ware ardene"
  },
  {
    "x": "waryeardiş (n)",
    "y": " b. wareardiş (n)"
  },
  {
    "x": "warza (n)",
    "y": " b. wareza (n)"
  },
  {
    "x": "warzaynayene",
    "y": " b. weriznayene"
  },
  {
    "x": "warzaynayîş (n)",
    "y": " b. weriznayîş (n)"
  },
  {
    "x": "wasar (n)",
    "y": " b. wisar (n)"
  },
  {
    "x": "wasil (n)",
    "y": " b. wesle (m)"
  },
  {
    "x": "wasita (m)",
    "y": " araç, taşıt, vasıta"
  },
  {
    "x": "wasitaya girane (m)",
    "y": " ağır vasıta"
  },
  {
    "x": "wasitaya kombî (m)",
    "y": " kombi araç"
  },
  {
    "x": "wasitaya zafmeqsedine (m)",
    "y": " çok maksatlı araç"
  },
  {
    "x": "wasitayê arêdayîşê dayîyan",
    "y": " veri toplama araçları"
  },
  {
    "x": "wasitayê arêdayîşê doneyan",
    "y": " veri toplama araçları"
  },
  {
    "x": "wasitayê resî (zh)",
    "y": " ulaşım araçları"
  },
  {
    "x": "wasî, -ye (huq.)",
    "y": " vasi"
  },
  {
    "x": "wasîte (n)",
    "y": " b. wasita (m)"
  },
  {
    "x": "wasîyî (m)",
    "y": " vasilik, vesayet"
  },
  {
    "x": "wasîyîye (m)",
    "y": " vasilik, vesayet"
  },
  {
    "x": "waste (n)",
    "y": " b. waşte (n)"
  },
  {
    "x": "wastene",
    "y": " b. waştene"
  },
  {
    "x": "wastis (n)",
    "y": " b. waştiş (n)"
  },
  {
    "x": "wastiş (n)",
    "y": " b. waştiş (n)"
  },
  {
    "x": "wastî (n)",
    "y": " b. waştî (n)"
  },
  {
    "x": "wastîye (m)",
    "y": " b. waştîye (m)"
  },
  {
    "x": "waşite (m)",
    "y": " kız sütkardeş, sütkardeş"
  },
  {
    "x": "Waşîngtin (n)",
    "y": " Washington"
  },
  {
    "x": "waşta (m)",
    "y": " 1)nişanlı, sözlü\r\n~2)sevgili\r\n~Waştaya mi meşte yena. (Sevgilim yarın gelecek.)"
  },
  {
    "x": "waşte (n)",
    "y": " 1)nişanlı, sözlü\r\n~2)sevgili"
  },
  {
    "x": "waştene",
    "y": " istemek, talep etmek, talep eylemek"
  },
  {
    "x": "waştê (m)",
    "y": " b. waşta (m)"
  },
  {
    "x": "waştiş (n)",
    "y": " istem, talep, istek, dilek"
  },
  {
    "x": "waştişê neqlî (n)",
    "y": " nakil talebi"
  },
  {
    "x": "waştişê randevuyî (n)",
    "y": " randevu talebi"
  },
  {
    "x": "waştişname (n)",
    "y": " dilekçe, arzuhâl"
  },
  {
    "x": "waştişnameyê dewa (n)",
    "y": " dava dilekçesi"
  },
  {
    "x": "waştişnameyê hemwelatî, -ye",
    "y": " vatandaş dilekçesi"
  },
  {
    "x": "waştişnameyo cewabî (n)",
    "y": " cevap dilekçesi"
  },
  {
    "x": "waştî, -ye",
    "y": " 1)nişanlı, sözlü\r\n~2)sevgili"
  },
  {
    "x": "waştox, -e",
    "y": " istekli, talepkar"
  },
  {
    "x": "wat (n)",
    "y": " b. wet (n)"
  },
  {
    "x": "wat ra",
    "y": " b. wet ra"
  },
  {
    "x": "wata",
    "y": " b. weta"
  },
  {
    "x": "wate (n)",
    "y": " b. vate (n)"
  },
  {
    "x": "watene",
    "y": " b. vatene"
  },
  {
    "x": "mewaci ki",
    "y": " b. mewaje ke"
  },
  {
    "x": "ti mewaci ki",
    "y": " b. ti mevaje ke"
  },
  {
    "x": "ti nêwana ke",
    "y": " b. ti nêvana ke"
  },
  {
    "x": "ti nêwanî ke",
    "y": " b. ti nêvanî ke"
  },
  {
    "x": "watene qey",
    "y": " b. vatene qey"
  },
  {
    "x": "ti wana",
    "y": " b. ti vana qey"
  },
  {
    "x": "ti wana qay",
    "y": " b. ti vana qey"
  },
  {
    "x": "ti wana qey",
    "y": " b. ti vana qey"
  },
  {
    "x": "ti wanî",
    "y": " b. ti vanî qey"
  },
  {
    "x": "ti wanî qay",
    "y": " b. ti vanî qey"
  },
  {
    "x": "ti wanî qey",
    "y": " b. ti vanî qey"
  },
  {
    "x": "wateni",
    "y": " b. vatene"
  },
  {
    "x": "watiş (n)",
    "y": " b. vatiş (n)"
  },
  {
    "x": "waxir (n)",
    "y": " b. oxir (n)"
  },
  {
    "x": "waxt (n)",
    "y": " b. wext (n)"
  },
  {
    "x": "waxt derbaz kerdene",
    "y": " b. wext derbaz kerdene "
  },
  {
    "x": "waxt girotene",
    "y": " b. wext girewtene"
  },
  {
    "x": "waxt guretene",
    "y": " b. wext girewtene"
  },
  {
    "x": "waxt omeyîne",
    "y": " b. wext ameyene"
  },
  {
    "x": "waxt ravîyarnayene",
    "y": " b. wext ravîyarnayene"
  },
  {
    "x": "waxt ûmeyîne",
    "y": " b. wext ameyene"
  },
  {
    "x": "waxt vîn kerdene",
    "y": " b. wext vîndî kerdene"
  },
  {
    "x": "waxt vîyarnayene",
    "y": " b. wext vîyarnayene"
  },
  {
    "x": "waxtê ... omeyîne",
    "y": " b. wextê ... ameyene"
  },
  {
    "x": "waxtî ... ûmeyîne",
    "y": " b. wextê ... ameyene"
  },
  {
    "x": "waxtî vendayîşî dîkû",
    "y": " b. wextê vengdayîşê dîkan"
  },
  {
    "x": "waxto derg (n)",
    "y": " b. wexto derg (n)"
  },
  {
    "x": "waxtderbazkerdiş (n)",
    "y": " b. wextderbazkerdiş (n)"
  },
  {
    "x": "waxte (m)",
    "y": " b. wext (n)"
  },
  {
    "x": "waxtewaxt",
    "y": " b. wext ra wext"
  },
  {
    "x": "waxtêk",
    "y": " b. wextêk"
  },
  {
    "x": "waxtgirotiş (n)",
    "y": " b. wextgirewtiş (n)"
  },
  {
    "x": "waxtguretiş (n)",
    "y": " b. wextgirewtiş (n)"
  },
  {
    "x": "waxti (m)",
    "y": " b. wext (n)"
  },
  {
    "x": "waxtîk",
    "y": " b. wextêk"
  },
  {
    "x": "waxto",
    "y": " b. wexto ke"
  },
  {
    "x": "waxtomeyîş (n)",
    "y": " b. wextameyîş (n)"
  },
  {
    "x": "waxtravîyarnayîş (n)",
    "y": " b. wextravîyarnayîş (n)"
  },
  {
    "x": "waxtûmeyîş (n)",
    "y": " b. wextameyîş (n)"
  },
  {
    "x": "waxtvînkerdiş (n)",
    "y": " b. wext vîndîkerdiş (n)"
  },
  {
    "x": "waxtvîyarnayîş (n)",
    "y": " b. wextvîyarnayîş (n)"
  },
  {
    "x": "way (m)",
    "y": " b. waye (m)"
  },
  {
    "x": "way şitî (m)",
    "y": " b. waşite (m), waya şitî (m)"
  },
  {
    "x": "waye (m)",
    "y": " 1)bacı, kız kardeş\r\n~2)abla"
  },
  {
    "x": "waya pîle (m)",
    "y": " abla"
  },
  {
    "x": "waya şitî (m)",
    "y": " kız sütkardeş, sütkardeş "
  },
  {
    "x": "wayê",
    "y": " abla (hitap hali)"
  },
  {
    "x": "wayene",
    "y": " b. huyayene"
  },
  {
    "x": "wayer (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "wayerey (m)",
    "y": " b. wayîrîye (m)"
  },
  {
    "x": "wayerê (m)",
    "y": " b. wayîrîye (m)"
  },
  {
    "x": "wayey (m)",
    "y": " b. wayîye (m)"
  },
  {
    "x": "wayê-I (m)",
    "y": " b. wayîye (m)"
  },
  {
    "x": "wayê-II",
    "y": " abla (hitap hali)"
  },
  {
    "x": "wayêr (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "wayêrey (m)",
    "y": " b. wayîrîye (m)"
  },
  {
    "x": "wayêtî (m)",
    "y": " ablalık"
  },
  {
    "x": "wayêyey (m)",
    "y": " b. wayîye (m)"
  },
  {
    "x": "wayêyî (m)",
    "y": " ablalık"
  },
  {
    "x": "wayi (m)",
    "y": " b. waye (m)"
  },
  {
    "x": "wayik (m)",
    "y": " b. wayike (m)"
  },
  {
    "x": "wayike (m)",
    "y": " 1)bacı, kız kardeş\r\n~2)abla"
  },
  {
    "x": "wayikey (m)",
    "y": " b. wayikêyî (m)"
  },
  {
    "x": "wayikê",
    "y": " abla (hitap hali)"
  },
  {
    "x": "wayikêyey (m)",
    "y": " b. wayikêyî (m)"
  },
  {
    "x": "wayikêyî (m)",
    "y": " ablalık"
  },
  {
    "x": "wayir, -e",
    "y": " b. wayîr, -e"
  },
  {
    "x": "wayirey (m)",
    "y": " b. wayîrîye (m)"
  },
  {
    "x": "wayî (m)",
    "y": " bacılık (kardeşlik)"
  },
  {
    "x": "wayîr, -e",
    "y": " sahip, iye, malik, sahip"
  },
  {
    "x": "wayîr vejîyayene",
    "y": " benimsemek, sahip çıkmak, sahiplenmek"
  },
  {
    "x": "wayîra derdî (m)",
    "y": " dert sahibi kadın veya kız"
  },
  {
    "x": "wayîra ferasetî (m)",
    "y": " anlayışlı kadın veya kız"
  },
  {
    "x": "wayîra îmanî (m)",
    "y": " inançlı kadın veya kız, inanlı kadın veya kız, mümine"
  },
  {
    "x": "wayîra karî (n)",
    "y": " işyeri sahibi"
  },
  {
    "x": "wayîra keyeyî (m)",
    "y": " ev sahibi"
  },
  {
    "x": "wayîra milkî (m)",
    "y": " mülk sahibi kadın veya kız"
  },
  {
    "x": "wayîra qabîlîyetî (m)",
    "y": " kabiliyetli, yetenekli"
  },
  {
    "x": "wayîra qebîlîyetî (m)",
    "y": " b. wayîra qabîlîyetî (m)"
  },
  {
    "x": "wayîra wesayîtî",
    "y": " araç sahibi"
  },
  {
    "x": "wayîrê derdî (n)",
    "y": " dert sahibi"
  },
  {
    "x": "wayîrê ferasetî (n)",
    "y": " anlayışlı"
  },
  {
    "x": "wayîrê îmanî (n)",
    "y": " inançlı, inanlı, mümin"
  },
  {
    "x": "wayîrê karî (n)",
    "y": " işyeri sahibi"
  },
  {
    "x": "wayîrê keyeyî (n)",
    "y": " ev sahibi"
  },
  {
    "x": "wayîrê milkî (n)",
    "y": " mülk sahibi erkek"
  },
  {
    "x": "wayîrê qabîlîyetî (n)",
    "y": " kabiliyetli, yetenekli"
  },
  {
    "x": "wayîrê qebîlîyetî (n)",
    "y": " wayîrê qabîlîyetî (n)"
  },
  {
    "x": "wayîrê wesayîtî",
    "y": " araç sahibi"
  },
  {
    "x": "wayîrey (m)",
    "y": " b. wayîrîye (m), wayîrî (m)"
  },
  {
    "x": "wayîrê (m)",
    "y": " b. wayîrîye (m), wayîrî (m)"
  },
  {
    "x": "wayîrênî (m)",
    "y": " b. wayîrîye (m), wayîrî (m)"
  },
  {
    "x": "wayîrî (m)",
    "y": " sahiplik, iyelik, malikiyet, mülkiyet"
  },
  {
    "x": "wayîrîya dayîreyî (n)",
    "y": " kat mülkiyeti"
  },
  {
    "x": "wayîrîya hempare (m)",
    "y": " müşterek mülkiyet"
  },
  {
    "x": "wayîrîya huşke (m)",
    "y": " kuru mülkiyet, çıplak mülkiyet"
  },
  {
    "x": "wayîrîye (m)",
    "y": " sahiplik, iyelik, malikiyet, mülkiyet"
  },
  {
    "x": "wayîrvejîyayîş (n)",
    "y": " benimseme, sahip çıkma, sahiplenme"
  },
  {
    "x": "wayîs (n)",
    "y": " b. huyayîş (n)"
  },
  {
    "x": "wayîye (m)",
    "y": " 1)bacılık (kardeşlik)\r\n~2)ablalık"
  },
  {
    "x": "wayke (m)",
    "y": " ahbap"
  },
  {
    "x": "waykîye (m)",
    "y": " ahbaplık"
  },
  {
    "x": "waynefsî (m)",
    "y": " can pazarı"
  },
  {
    "x": "waynefsîye (m)",
    "y": " can pazarı"
  },
  {
    "x": "wayr, -e",
    "y": " b. wayîr, -e"
  },
  {
    "x": "wayştiş (n)",
    "y": " b. waştiş (n)"
  },
  {
    "x": "waytîye (m)",
    "y": " b. wayîye (m)"
  },
  {
    "x": "waz (m)",
    "y": " b. weaze (m)"
  },
  {
    "x": "wazan, -e",
    "y": " geveze, laübali, ukala"
  },
  {
    "x": "wazanî (m)",
    "y": " gevezelik, laübalilik, ukalalık"
  },
  {
    "x": "wazanî kerdene",
    "y": " gevezelik etmek"
  },
  {
    "x": "wazanîkerdiş (n)",
    "y": " gevezelik etme   "
  },
  {
    "x": "wazanîye (m)",
    "y": " gevezelik, laübalilik, ukalalık"
  },
  {
    "x": "wazanîye kerdene",
    "y": " gevezelik etmek"
  },
  {
    "x": "wazanîyekerdiş (n)",
    "y": " gevezelik etme"
  },
  {
    "x": "waze (m)",
    "y": " b. weaze (m)"
  },
  {
    "x": "wazi (m)",
    "y": " b. weaze (m)"
  },
  {
    "x": "wazîyet (n)",
    "y": " b. wezîyet (n)"
  },
  {
    "x": "wazo (m)",
    "y": " b. vazo (n)"
  },
  {
    "x": "wazûn",
    "y": " b. wazan, -e"
  },
  {
    "x": "wazûnê (m)",
    "y": " b. wazanîye (m), wazanî (m)"
  },
  {
    "x": "wazûnê kerdene",
    "y": " b. wazanî kerdene"
  },
  {
    "x": "wazûnêkerdiş (n)",
    "y": " b. wazanîkerdiş (n)"
  },
  {
    "x": "wazyet (n)",
    "y": " b. wezîyet (n)"
  },
  {
    "x": "WC-camêrd",
    "y": " WC-bay"
  },
  {
    "x": "WC-cinî",
    "y": " WC-bayan"
  },
  {
    "x": "we (n, z)",
    "y": " b. o-I (n, z)\r\n~Ez û we ma şîbîy. (Ben ve o gitmiştik.)"
  },
  {
    "x": "we ame",
    "y": " o geldi"
  },
  {
    "x": "we- (pr)",
    "y": " “o” anlamı veren bir önek"
  },
  {
    "x": "wet",
    "y": " öte, o taraf, karşı yaka"
  },
  {
    "x": "wever (n)",
    "y": " öte, o taraf, karşı yaka"
  },
  {
    "x": "wealizîyayene",
    "y": " tırmanmak"
  },
  {
    "x": "wealizîyayîş (n)",
    "y": " tırmanma"
  },
  {
    "x": "weameye (n)",
    "y": " mayalı (hamur için)"
  },
  {
    "x": "weameyene",
    "y": " mayalanmak (hamur için)"
  },
  {
    "x": "weameyîş (n)",
    "y": " mayalanma (hamur için)"
  },
  {
    "x": "weancîyayene",
    "y": " tırmanmak"
  },
  {
    "x": "weancîyayîş (n)",
    "y": " tırmanma"
  },
  {
    "x": "weantene",
    "y": " b. werantene"
  },
  {
    "x": "weaz (m)",
    "y": " b. weaze (m)"
  },
  {
    "x": "weaz (n)",
    "y": " b. weaze (m)"
  },
  {
    "x": "weaz dayene",
    "y": " b. weazî dayene"
  },
  {
    "x": "weaze (m)",
    "y": " vaaz"
  },
  {
    "x": "weazî dayene",
    "y": " vaaz vermek"
  },
  {
    "x": "weazkar, -e",
    "y": " vaiz"
  },
  {
    "x": "weazkarênî (m)",
    "y": " vaizlik"
  },
  {
    "x": "weazkarî (m)",
    "y": " vaizlik"
  },
  {
    "x": "weazkarîye (m)",
    "y": " vaizlik"
  },
  {
    "x": "web server (n) (TE)",
    "y": " web server "
  },
  {
    "x": "weba (m)",
    "y": " veba"
  },
  {
    "x": "weberdene",
    "y": " söndürmek"
  },
  {
    "x": "weberdiş (n)",
    "y": " söndürme"
  },
  {
    "x": "webîyayîş (n)",
    "y": " çözülme (düğüm vb. için)"
  },
  {
    "x": "webîyene",
    "y": " çözülmek (düğüm vb. için)"
  },
  {
    "x": "webwendox (n) (TE)",
    "y": " web tarayıcı"
  },
  {
    "x": "wecax (n)",
    "y": " b. ocax (n)"
  },
  {
    "x": "wecaxkor, -e",
    "y": " b. ocaxkor, -e"
  },
  {
    "x": "wecêrayene",
    "y": " b. agêrayene"
  },
  {
    "x": "wecîze (m)",
    "y": " aforizma, aforizm"
  },
  {
    "x": "weçelnayene",
    "y": " b. weçînayene"
  },
  {
    "x": "weçelnayîş (n)",
    "y": " b. weçînayîş (n)"
  },
  {
    "x": "weçîlnayene",
    "y": " b. weçînayene"
  },
  {
    "x": "weçîlnayîş (n)",
    "y": " b. weçînayîş (n)"
  },
  {
    "x": "weçînayene",
    "y": " 1)ayıklamak\r\n~2)seçmek\r\n~3)çemremek (kol, paça, etek vb. için), sıvamak (kol, paça, etek vb. için) "
  },
  {
    "x": "weçînayîş (n)",
    "y": " 1)ayıklama\r\n~2)seçme, seçim\r\n~3)çemreme (kol, paça, etek vb. için)"
  },
  {
    "x": "weçînayîşê çimeyan (n)",
    "y": " kaynak seçimi"
  },
  {
    "x": "weçînayîşê elemanî/e",
    "y": " eleman seçme"
  },
  {
    "x": "weçînayîşê stajyeran (n)",
    "y": " stajyer seçme"
  },
  {
    "x": "weçînayîşê projeyî (n)",
    "y": " proje seçimi"
  },
  {
    "x": "weçînayîşo mîyanên (n)",
    "y": " ara seçim"
  },
  {
    "x": "weçînek (n)",
    "y": " seçenek\r\n~weçîneko çorşmeyî yo tewr xebatbar: en kullanışlı çevresel seçenek"
  },
  {
    "x": "weçîneko çorşmeyî yo tewr xebatbar",
    "y": " en kullanışlı çevresel seçenek"
  },
  {
    "x": "weçîneko dorûverî yo tewr xebatbar",
    "y": " en kullanışlı çevresel seçenek"
  },
  {
    "x": "weçînite (n)",
    "y": " seçilen, seçilmiş, seçkin"
  },
  {
    "x": "weçînitene",
    "y": " 1)ayıklamak\r\n~2)seçmek\r\n~3)çemremek (kol, paça, etek vb. için), sıvamak (kol, paça, etek vb. için)"
  },
  {
    "x": "weçînitiş (n)",
    "y": " 1)ayıklama\r\n~2)seçme, seçim\r\n~3)çemreme (kol, paça, etek vb. için)"
  },
  {
    "x": "weçînitişê çimeyan (n)",
    "y": " kaynak seçimi"
  },
  {
    "x": "weçînitişê elemanî/e",
    "y": " eleman seçme"
  },
  {
    "x": "weçînitişê karmusan (n)",
    "y": " stajyer seçme"
  },
  {
    "x": "weçînitişê projeyî (n)",
    "y": " proje seçimi"
  },
  {
    "x": "weçînitişo mîyanên (n)",
    "y": " ara seçim"
  },
  {
    "x": "weçînitîye (m)",
    "y": " seçilen, seçilmiş, seçkin"
  },
  {
    "x": "weçînitox, -e",
    "y": " seçmen"
  },
  {
    "x": "weçînîyayene",
    "y": " ayıklanmak"
  },
  {
    "x": "weçînîyayîş (n)",
    "y": " ayıklanma"
  },
  {
    "x": "weçînîyayîşo xozayî (n)",
    "y": " doğal ayıklanma"
  },
  {
    "x": "wed (n)",
    "y": " b. wad (n)"
  },
  {
    "x": "wed kerdene",
    "y": " b. wad kerdene"
  },
  {
    "x": "weda (m)",
    "y": " b. oda-I (m)"
  },
  {
    "x": "wedaene",
    "y": " b. wedayene"
  },
  {
    "x": "wedardaene",
    "y": " b. wedardayene"
  },
  {
    "x": "wedardayene",
    "y": " 1)kaldırmak\r\n~2)defnetmek, saklamak"
  },
  {
    "x": "wedardayêne",
    "y": " b. wedardayene"
  },
  {
    "x": "wedardayîş (n)",
    "y": " 1)kaldırma)\r\n~2)defnetme, saklama"
  },
  {
    "x": "wedardene",
    "y": " b. wedaritene"
  },
  {
    "x": "wedardiş (n)",
    "y": " b. wedaritiş (n)"
  },
  {
    "x": "wedardîş (n)",
    "y": " b. wedaritiş (n)"
  },
  {
    "x": "wedardîyayene",
    "y": " kaldırılmak"
  },
  {
    "x": "wedardîyayîş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "wedaritene",
    "y": " 1)kaldırmak\r\n~2)gömmek, defnetmek\r\n~3)saklamak (TE), kaydetmek (TE)"
  },
  {
    "x": "wedaritene sey ...",
    "y": " ... olarak saklamak (TE), ... olarak kaydetmek (TE)"
  },
  {
    "x": "wedariteni",
    "y": " b. wedaritene"
  },
  {
    "x": "wedaritiş (n)",
    "y": " 1)kaldırma\r\n~2)saklama\r\n~3)gömme, defnetme \r\n~4)kaydetme (TE)"
  },
  {
    "x": "wedaritişê eşteyî (n)",
    "y": " atık gömme"
  },
  {
    "x": "wedarîyayene",
    "y": " kaldırılmak"
  },
  {
    "x": "wedarîyayîş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "wedarnayene",
    "y": " 1)kaldırmak\r\n~2)defnetmek, saklamak"
  },
  {
    "x": "wedarnayîş (n)",
    "y": " 1)kaldırma\r\n~2)defnetme, saklama"
  },
  {
    "x": "wedarnîyayene",
    "y": " kaldırılmak"
  },
  {
    "x": "wedarnîyayîş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "wedartene",
    "y": " b. wedaritene"
  },
  {
    "x": "wedartiş (n)",
    "y": " b. wedaritiş (n)"
  },
  {
    "x": "wedarutene",
    "y": " b. wedaritene"
  },
  {
    "x": "wedarutiş (n)",
    "y": " b. wedaritiş (n)"
  },
  {
    "x": "wedaya (m)",
    "y": " uçuk (dudak vb. için)"
  },
  {
    "x": "wedaye (n)",
    "y": " uçuk (dudak vb. için)"
  },
  {
    "x": "wedayene",
    "y": " 1)kaldırmak\r\n~2)defnetmek\r\n~3)uçuklamak\r\n~Lewê to wedayo/dayo we. (Dudağın uçuklamış.)"
  },
  {
    "x": "wedayîş (n)",
    "y": " b. wedarnayîş (n)"
  },
  {
    "x": "wedayîye (m)",
    "y": " uçuk (dudak vb. için)"
  },
  {
    "x": "wede-I (n)",
    "y": " b. wade-I (n)"
  },
  {
    "x": "wede dayene",
    "y": " b. wade dayene"
  },
  {
    "x": "wede-II (n)",
    "y": " b. oda-I (m)"
  },
  {
    "x": "wededayîş (n)",
    "y": " b. wadedayîş (n)"
  },
  {
    "x": "wederdayene",
    "y": " b. wedardayene"
  },
  {
    "x": "wederdayîş (n)",
    "y": " b. wedardayîş (n)"
  },
  {
    "x": "wederîyayene",
    "y": " b. wedarîyayene"
  },
  {
    "x": "wederîyayîş (n)",
    "y": " b. wedarîyayîş (n)"
  },
  {
    "x": "wedernayene",
    "y": " b. wedarnayene"
  },
  {
    "x": "wedernayîş (n)",
    "y": " b. wedarnayîş (n)"
  },
  {
    "x": "wedertene",
    "y": " b. wedaritene"
  },
  {
    "x": "wedertiş (n)",
    "y": " b. wedaritiş (n)"
  },
  {
    "x": "wederyayene",
    "y": " b. wedarîyayene"
  },
  {
    "x": "wedetene",
    "y": " b. wedaritene"
  },
  {
    "x": "wedetiş (n)",
    "y": " b. wedaritiş (n)"
  },
  {
    "x": "wedîyayene",
    "y": " kaldırılmak"
  },
  {
    "x": "wedîyayîş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "wedkerdiş (n)",
    "y": " b. wadkerdiş (n)"
  },
  {
    "x": "weer, -i",
    "y": " b. wayîr, -e"
  },
  {
    "x": "weeze (m)",
    "y": " b. weaze (m)"
  },
  {
    "x": "wefat (n)",
    "y": " vefat"
  },
  {
    "x": "wefat kerdene",
    "y": " vefat etmek"
  },
  {
    "x": "wefatkerdiş (n)",
    "y": " vefat etme"
  },
  {
    "x": "weferîyayene",
    "y": " b. wefirîyayene"
  },
  {
    "x": "wefernayene",
    "y": " b. wefirnayene"
  },
  {
    "x": "wefirîyayene",
    "y": " şişmek, kabarmak"
  },
  {
    "x": "wefirnayene",
    "y": " şişirmek, kabartmak"
  },
  {
    "x": "wefîsta (m)",
    "y": " b. wefîşta (m)"
  },
  {
    "x": "wefîste (m)",
    "y": " b. wefîşte (m)"
  },
  {
    "x": "wefîstene",
    "y": " b. wefîştene"
  },
  {
    "x": "wefîstiş (n)",
    "y": " b. wefîştiş (n)"
  },
  {
    "x": "wefîstîye(m)",
    "y": " b. wefîştîye (m)"
  },
  {
    "x": "wefîşta (m)",
    "y": " 1)uçuk (dudak vb. için), uçuklamış olan\r\n~2)serili (çamaşır vb.)"
  },
  {
    "x": "wefîştayîye (m)",
    "y": " b. wefîştîye (m)"
  },
  {
    "x": "wefîşte (n)",
    "y": " 1)uçuk (dudak vb. için), uçuklamış olan\r\n~2)serili (çamaşır vb.)"
  },
  {
    "x": "wefîştene",
    "y": " 1)uçuklamak\r\n~2)iltihaplanmak, yangılanmak\r\n~3)sermek (çamaşır vb. için)\r\n~Kincan çeper ra wefîye, va/wa zuya bê. (Çamaşırları çite as, kurusunlar.)"
  },
  {
    "x": "wefîştiş (n)",
    "y": " serme (çamaşır vb. için)"
  },
  {
    "x": "wefîştîye (m)",
    "y": " 1)uçuk (dudak vb. için), uçuklamış olan\r\n~2)serili (çamaşır vb.)"
  },
  {
    "x": "wegirewtene",
    "y": " yüklenmek\r\n~(Mi çenteyê xo wegirewt la giran bi. Çantamı yüklendim ama ağırdı.)"
  },
  {
    "x": "wegirewtiş (n)",
    "y": " yüklenme"
  },
  {
    "x": "wegirgirîyayene",
    "y": " hıçkırma"
  },
  {
    "x": "wegirgirîyayîş (n)",
    "y": " hıçkırma"
  },
  {
    "x": "wegîrgîrîyayene",
    "y": " b. wegirgirîyayene"
  },
  {
    "x": "wegîrgîrîyayêne",
    "y": " b. wegirgirîyayene"
  },
  {
    "x": "wegîrgîrîyayîş (n)",
    "y": " b wegirgirîyayîş (n)"
  },
  {
    "x": "wegîrîyayene",
    "y": " 1)iltihaplanmak, yangılanmak\r\n~2)azmak (yara vb. için)\r\n~Birîna mi wegîrîyaya. (Yaram iltihaplanmış/azmış.)"
  },
  {
    "x": "wegîrîyayîne",
    "y": " b. wegîrîyayene"
  },
  {
    "x": "wegîrîyayîş (n)",
    "y": " azma (yara vb. için)"
  },
  {
    "x": "weglik (n)",
    "y": " b. onlig (n)"
  },
  {
    "x": "weh",
    "y": " sığırın yürümesi için veya kovarken söylenen bir söz"
  },
  {
    "x": "weh weh",
    "y": " sığırın yürümesi için veya kovarken söylenen bir söz"
  },
  {
    "x": "wehar (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "weharê (m)",
    "y": " b. wayîrîye (m)"
  },
  {
    "x": "wehd (n)",
    "y": " b. wad (n)"
  },
  {
    "x": "wehd kerdene",
    "y": " b. wad kerdene"
  },
  {
    "x": "wehde (n)",
    "y": " b. wade (n)"
  },
  {
    "x": "wehde dayene",
    "y": " b. wade dayene"
  },
  {
    "x": "wehdedayîş (n)",
    "y": " b. wadedayîş (n)"
  },
  {
    "x": "wehdkerdiş (n)",
    "y": " b. wadkerdiş (n)"
  },
  {
    "x": "wehdnayene",
    "y": " b. wadnayene"
  },
  {
    "x": "wehdnayîş (n)",
    "y": " b. wadnayîş (n)"
  },
  {
    "x": "wehe",
    "y": " b. weh"
  },
  {
    "x": "wehed (n)",
    "y": " b. wad (n)"
  },
  {
    "x": "weher (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "weherê (m)",
    "y": " b. wayîrîye (m)"
  },
  {
    "x": "wehîd, -e",
    "y": " biricik"
  },
  {
    "x": "wehîdi (m)",
    "y": " b. wehîd, -e"
  },
  {
    "x": "wehîr (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "wehşî, -ye",
    "y": " vahşi, yabanıl, yabani"
  },
  {
    "x": "wek-I (n)",
    "y": " b. ewk (n)"
  },
  {
    "x": "wek-II (m)",
    "y": " b. hoke (m)"
  },
  {
    "x": "wekalet (n)",
    "y": " vekillik, vekâlet"
  },
  {
    "x": "wekaletname (n)",
    "y": " vekâletname "
  },
  {
    "x": "weke-I (m)",
    "y": " ayak bileği"
  },
  {
    "x": "weke-II (m)",
    "y": " b. hoke (m)"
  },
  {
    "x": "wekek kerdene",
    "y": " b. hokeke kerdene"
  },
  {
    "x": "wekekkerdiş (n)",
    "y": " b. hokekekerdiş (n)"
  },
  {
    "x": "wekerdayene",
    "y": " b. wekerdene"
  },
  {
    "x": "wekerdayîs",
    "y": " b. wekerdiş"
  },
  {
    "x": "wekerdene",
    "y": " 1)açmak, çözmek (düğüm vb. için)\r\n~2)sıyırmak (kabuk vb. için)\r\n~3)sökmek"
  },
  {
    "x": "wekerd/kerd we",
    "y": " açtı"
  },
  {
    "x": "wekerdiş (n)",
    "y": " 1)açma, açış; çözme (düğüm vb. için)\r\n~2)sıyırma (kabuk vb. için)\r\n~3)sökme, söküm"
  },
  {
    "x": "wekerdişê hûmarkerî (n)",
    "y": " sayaç sökümü"
  },
  {
    "x": "wekîl, -e",
    "y": " vekil"
  },
  {
    "x": "wekîla/wekîlê sekretera pêroyîye",
    "y": " genel sekreter vekili"
  },
  {
    "x": "wekîla/wekîlê sekreterê pêroyî",
    "y": " genel sekreter vekili"
  },
  {
    "x": "wekîla/wekilê sereka beledîya",
    "y": " belediye başkan vekili (kadın başkan için)"
  },
  {
    "x": "wekîla/wekilê sereka şaredarî",
    "y": " belediye başkan vekili (kadın başkan için)"
  },
  {
    "x": "wekîla/wekilê serekê beledîya",
    "y": " belediye başkan vekili"
  },
  {
    "x": "wekîla/wekilê serekê şaredarî",
    "y": " belediye başkan vekili"
  },
  {
    "x": "wekîlella",
    "y": " “Allah'a bırakıyorum” anlamında bir söz, “Allah'ı vekil kabul ediyorum” anlamında bir söz"
  },
  {
    "x": "wekîlîye (m)",
    "y": " vekâlet, vekillik"
  },
  {
    "x": "wel (m)",
    "y": " b. wele-I (m)"
  },
  {
    "x": "wela",
    "y": " b. willayî"
  },
  {
    "x": "welade (m)",
    "y": " bağ kulübesi"
  },
  {
    "x": "weladeke (m)",
    "y": " bağ kulübesi"
  },
  {
    "x": "weladeki (m)",
    "y": " b. weladeke (m)"
  },
  {
    "x": "weladi (m)",
    "y": " b. welade (m)"
  },
  {
    "x": "welaqi (m)",
    "y": " b. golîge (m)"
  },
  {
    "x": "welat (n)",
    "y": " ülke, vatan, yurt"
  },
  {
    "x": "welatê bawkalan (n)",
    "y": " ana vatan"
  },
  {
    "x": "welatê pîrbaban (n)",
    "y": " ana yurt, ana vatan"
  },
  {
    "x": "welatê pîrbavan (n)",
    "y": " ana yurt, ana vatan"
  },
  {
    "x": "welatê xerîbî (zh)",
    "y": " yabancı ülkeler"
  },
  {
    "x": "welato ecnebî (n)",
    "y": " yabancı ülke"
  },
  {
    "x": "welato pêse (n)",
    "y": " b. welato pêşe (n)"
  },
  {
    "x": "welato pêşe (n)",
    "y": " yabancı ülke"
  },
  {
    "x": "welato xerîb (n)",
    "y": " yabancı ülke"
  },
  {
    "x": "welatperwer, -e",
    "y": " yurtsever"
  },
  {
    "x": "welayet (n)",
    "y": " velayet"
  },
  {
    "x": "welca (n)",
    "y": " küllük"
  },
  {
    "x": "welcax (n)",
    "y": " b. olacax (n)"
  },
  {
    "x": "welç-I (n)",
    "y": " bir “welçeke” (42, 40 veya 20 kg) miktarında tahılın ekilebileceği arazi"
  },
  {
    "x": "welç-II (m)",
    "y": " 1)b. welçeke (m)\r\n~2)b. welç-I (n)"
  },
  {
    "x": "welçeg-I (m)",
    "y": " b. welçeke (m)"
  },
  {
    "x": "welçeg-II (n)",
    "y": " b. welçeke (m)"
  },
  {
    "x": "welçegi (m)",
    "y": " b. welçeke (m)"
  },
  {
    "x": "welçek (m)",
    "y": " b. welçeke (m)"
  },
  {
    "x": "welçeke (m)",
    "y": " 42, 40 veya 20 kg ağırlığında bir tahıl ölçüsü"
  },
  {
    "x": "welçeki (m)",
    "y": " b. welçeke (m)"
  },
  {
    "x": "welçêg (n)",
    "y": " b. welçeke (m)"
  },
  {
    "x": "weldane (m)",
    "y": " b. weledane (m)"
  },
  {
    "x": "weldîyayene",
    "y": " b. welidîyayene"
  },
  {
    "x": "weldyayene",
    "y": " b. welidîyayene"
  },
  {
    "x": "wele-I (m)",
    "y": " 1)kül\r\n~2)toprak"
  },
  {
    "x": "wela firaya (m)",
    "y": " uçucu kül"
  },
  {
    "x": "wela firayîye (m)",
    "y": " uçucu kül"
  },
  {
    "x": "wela raperayîye (m)",
    "y": " uçucu kül"
  },
  {
    "x": "wele-II",
    "y": " b. willayî"
  },
  {
    "x": "wele-III (n)",
    "y": " b. weyra (n)"
  },
  {
    "x": "welecax (n)",
    "y": " b. olacax (n)"
  },
  {
    "x": "weledane (m)",
    "y": " kül tablası"
  },
  {
    "x": "weledîyayene",
    "y": " b. welidîyayene"
  },
  {
    "x": "weleh",
    "y": " b. willayî"
  },
  {
    "x": "weleke (m)",
    "y": " 1)taneleri küçücük olan kar\r\n~2)tipi, kar fırtınası"
  },
  {
    "x": "weleki-I (m)",
    "y": " b. weleke (m)"
  },
  {
    "x": "weleki-II (m)",
    "y": " b. welweleke (m)"
  },
  {
    "x": "welenge (m)",
    "y": " büğelek, bevelek, böğelek, büğe, bükelek, büve, büvelek, nokra sineği, iğrice, gübre sineği, güğüm sineği"
  },
  {
    "x": "welenge kerdene",
    "y": " bükelemek, büğelemek (büğeleğin -özellikle baharın- sığırı sokmasıyla bu hayvanların alabildiğine koşması)"
  },
  {
    "x": "welet (n)",
    "y": " b. welat (n)"
  },
  {
    "x": "weli (m)",
    "y": " b. wele-I (m)"
  },
  {
    "x": "welidîyayene",
    "y": " doğurmak (insan için), doğum yapmak"
  },
  {
    "x": "welidyayene",
    "y": " b. welidîyayene"
  },
  {
    "x": "welik (m)",
    "y": " b. weleke (m)"
  },
  {
    "x": "welike (m)",
    "y": " b. weleke (m)"
  },
  {
    "x": "weliki (m)",
    "y": " b. weleke (m)"
  },
  {
    "x": "welî (m)",
    "y": " b. wele-I (m)"
  },
  {
    "x": "welîyî (m)",
    "y": " velayet"
  },
  {
    "x": "welîyîye (m)",
    "y": " velayet"
  },
  {
    "x": "welladi (m)",
    "y": " b. welade (m)"
  },
  {
    "x": "wellaqi (m)",
    "y": " b. golîge (m)"
  },
  {
    "x": "wellay",
    "y": " b. willayî"
  },
  {
    "x": "welle",
    "y": " b. willayî"
  },
  {
    "x": "welleh",
    "y": " b. willayî"
  },
  {
    "x": "welley",
    "y": " b. willayî"
  },
  {
    "x": "welli (m)",
    "y": " b. wele-I (m)"
  },
  {
    "x": "Wellîngtin (n)",
    "y": " Wellington"
  },
  {
    "x": "welwele (n)",
    "y": " gürültü, gürültü patırtı, hengâme, şamata"
  },
  {
    "x": "welwele kerdene",
    "y": " gürültü etmek"
  },
  {
    "x": "welwele ronayene",
    "y": " gürültü etmek"
  },
  {
    "x": "welweleyê zemîne (n)",
    "y": " ortam gürültüsü"
  },
  {
    "x": "welweleke (m)",
    "y": " hortum"
  },
  {
    "x": "welweliki (m)",
    "y": " welweleke (m)"
  },
  {
    "x": "wemûnitene",
    "y": " gözemek, örmek (kumaş ve örgülerdeki delikleri elde iplikle besleyerek kapatmak)"
  },
  {
    "x": "wemûnitiş (n)",
    "y": " gözeme, örme (kumaş ve örgülerdeki delikleri elde iplikle besleyerek kapatma)"
  },
  {
    "x": "wenaya (m)",
    "y": " yüklü"
  },
  {
    "x": "wenaye (n)",
    "y": " yüklü"
  },
  {
    "x": "wenayene",
    "y": " 1)yüklemek\r\n~2)göç etmek, göçmek\r\n~3)bindirmek"
  },
  {
    "x": "wenayî, -ye",
    "y": " yüklü"
  },
  {
    "x": "wenayîş (n)",
    "y": " 1)bindirme\r\n~2)göç, göç etme, göçme\r\n~3)yükleme, şarj"
  },
  {
    "x": "wenca-I (m)",
    "y": " b. unca (m)"
  },
  {
    "x": "wenca-II",
    "y": " b. ancîna"
  },
  {
    "x": "wendegeh (n)",
    "y": " okul, mektep"
  },
  {
    "x": "wendegehê trafîkî (n)",
    "y": " trafik okulu"
  },
  {
    "x": "wendegeho mîyanên (n)",
    "y": " ortaokul"
  },
  {
    "x": "wendekar, -e",
    "y": " öğrenci, talebe"
  },
  {
    "x": "wendekarîye (m)",
    "y": " öğrencilik, talebelik"
  },
  {
    "x": "wenden",
    "y": " b. wendene"
  },
  {
    "x": "wendene",
    "y": " 1)okumak\r\n~2)ötmek (keklik, serçe vb. kuşlar için)"
  },
  {
    "x": "wendeni",
    "y": " b. wendene "
  },
  {
    "x": "wendex (n)",
    "y": " b. wendox, -e"
  },
  {
    "x": "wendêr, -e",
    "y": " b. ondêr, -e"
  },
  {
    "x": "wendiş (n)",
    "y": " 1)okuma\r\n~2)ötme (keklik, serçe vb. kuşlar için)"
  },
  {
    "x": "wendiş çin o",
    "y": " okuma yok"
  },
  {
    "x": "wendox, -e",
    "y": " okuyucu"
  },
  {
    "x": "wenduex (n)",
    "y": " b. wendox, -e"
  },
  {
    "x": "wenik",
    "y": " b. honik, -e"
  },
  {
    "x": "wenişnayene",
    "y": " bindirmek"
  },
  {
    "x": "wenişnayîş (n)",
    "y": " bindirme"
  },
  {
    "x": "weniştene",
    "y": " binmek"
  },
  {
    "x": "weniştiş (n)",
    "y": " binme"
  },
  {
    "x": "wenîştene",
    "y": " b. weniştene"
  },
  {
    "x": "wenîştiş (n)",
    "y": " b. weniştiş (n)"
  },
  {
    "x": "wenîyayene",
    "y": " yüklenmek\r\n~No ciwal key wenîyayo nê herî? (Bu çuval ne zaman bu eşeğe yüklenmiş?)"
  },
  {
    "x": "wenîyayîş (n)",
    "y": " yüklenme"
  },
  {
    "x": "wenlig (n)",
    "y": " b. onlig (n)"
  },
  {
    "x": "weontene",
    "y": " b. werantene"
  },
  {
    "x": "weontiş (n)",
    "y": " b. werantiş (n)"
  },
  {
    "x": "weozîyayîş (n)",
    "y": " b. wealizîyayîş (n)"
  },
  {
    "x": "weozîyene",
    "y": " b. wealizîyayene"
  },
  {
    "x": "wepindofîştene",
    "y": " şişmek"
  },
  {
    "x": "wepindofîyene",
    "y": " b. wepindofîştene"
  },
  {
    "x": "weqa (m)",
    "y": " okka"
  },
  {
    "x": "weqf (n)",
    "y": " vakıf"
  },
  {
    "x": "weqif (n)",
    "y": " vakıf"
  },
  {
    "x": "weqilaynayene",
    "y": " sökmek"
  },
  {
    "x": "weqilaynayîş (n)",
    "y": " sökme "
  },
  {
    "x": "weqofîyayene",
    "y": " kabarmak"
  },
  {
    "x": "weqofnayene",
    "y": " kabartmak"
  },
  {
    "x": "weqofyayene",
    "y": " b. weqofîyayene"
  },
  {
    "x": "weqt (n)",
    "y": " b. wext (n)"
  },
  {
    "x": "weqta",
    "y": " b. wexto ke"
  },
  {
    "x": "weqto",
    "y": " b. wexto ke"
  },
  {
    "x": "wer-I (n)",
    "y": " 1)rüşvet\r\n~2)b. werd (n)"
  },
  {
    "x": "wer dayene",
    "y": " rüşvet vermek"
  },
  {
    "x": "wer dayene werdene",
    "y": " rüşvet yedirmek"
  },
  {
    "x": "wer werdene",
    "y": " rüşvet yemek"
  },
  {
    "x": "wer dayene",
    "y": " b. werd dayene "
  },
  {
    "x": "wer ra birîyayene",
    "y": " b. werd ra birîyayene"
  },
  {
    "x": "wer ra kotene",
    "y": " b. werd ra kewtene"
  },
  {
    "x": "wer ra kowtene",
    "y": " b. werd ra kewtene"
  },
  {
    "x": "wer vetene",
    "y": " b. werd vetene"
  },
  {
    "x": "werê veyvî (n)",
    "y": " b. werdê veyveyî (n)"
  },
  {
    "x": "werê veywî (n)",
    "y": " b. werdê veyveyî (n)"
  },
  {
    "x": "werî vêwî (m)",
    "y": " b. werdê veyveyî (n)"
  },
  {
    "x": "wero awin (n)",
    "y": " b. werdo awin (n)"
  },
  {
    "x": "wer, -i-II",
    "y": " b. wayîr, -e"
  },
  {
    "x": "weradayene",
    "y": " b. wedardayene"
  },
  {
    "x": "weradayîş (n)",
    "y": " b. wedardayîş (n)"
  },
  {
    "x": "weradîyayene",
    "y": " b. wedardîyayene"
  },
  {
    "x": "weradîyayîş (n)",
    "y": " b. wedardîyayîş (n)"
  },
  {
    "x": "weranayîş (n)",
    "y": " b. werênayîş (n)"
  },
  {
    "x": "werancîyayene",
    "y": " yerin altından çekilip çıkarılmak"
  },
  {
    "x": "werantene",
    "y": " 1)yerin altından çekip çıkarmak\r\n~2)ürün toplamak (toprak altından çıkarılan ürünler için) "
  },
  {
    "x": "werantiş (n)",
    "y": " ürün toplama (toprak altından çıkarılan ürünler için) "
  },
  {
    "x": "weraset (n)",
    "y": " veraset"
  },
  {
    "x": "werat, -e",
    "y": " b. wêret, -e"
  },
  {
    "x": "weraznayene",
    "y": " b. weriznayene"
  },
  {
    "x": "weraznayîş (n)",
    "y": " b. weriznayîş (n)"
  },
  {
    "x": "werbîn",
    "y": " b. yewbînî"
  },
  {
    "x": "werbîna",
    "y": " b. yewbînî"
  },
  {
    "x": "werbînan",
    "y": " b. yewbînî"
  },
  {
    "x": "werd (n)",
    "y": " yemek, aş\r\n~Ewro werdê şima çi yo? (Bugün yemeğiniz nedir?)"
  },
  {
    "x": "werd dayene",
    "y": " yemek vermek"
  },
  {
    "x": "werd ra birîyayene",
    "y": " yemeden içmeden kesilmek, yiyemez olmak"
  },
  {
    "x": "werd ra kewtene",
    "y": " yemeden içmeden kesilmek, yiyemez olmak"
  },
  {
    "x": "werd vetene",
    "y": " yemek çıkarmak"
  },
  {
    "x": "werdê maseyan (n)",
    "y": " balık yemi"
  },
  {
    "x": "werdê sewzîyî (n)",
    "y": " sebze yemeği"
  },
  {
    "x": "werdê veyveyî (n)",
    "y": " düğün yemeği (topluca yenir)"
  },
  {
    "x": "werdo awin (n)",
    "y": " sulu yemek"
  },
  {
    "x": "werdeg (m)",
    "y": " b. ordege (m)"
  },
  {
    "x": "werdege (m)",
    "y": " b. ordege (m)"
  },
  {
    "x": "werdegi (m)",
    "y": " b. ordege (m)"
  },
  {
    "x": "werdek (m)",
    "y": " b. ordege (m)"
  },
  {
    "x": "werdeke (m)",
    "y": " b. ordege (m)"
  },
  {
    "x": "werdeki (m)",
    "y": " b. ordege (m)"
  },
  {
    "x": "werdene",
    "y": " 1)yemek\r\n~2)içmek (su, şarap vb. için)\r\n~3)ütmek, utmak (oyun için)"
  },
  {
    "x": "werdi",
    "y": " b. hurdî, -ye"
  },
  {
    "x": "werdi kerdene",
    "y": " b. hurdî kerdene"
  },
  {
    "x": "werdi-werdi",
    "y": " b. hurdî-hurdî"
  },
  {
    "x": "werdikek",
    "y": " b. hurdî"
  },
  {
    "x": "werdikerdiş (n)",
    "y": " b. hurdîkerdiş (n)"
  },
  {
    "x": "werdiş (n)",
    "y": " yeme"
  },
  {
    "x": "werdî",
    "y": " b. hurdî, -ye"
  },
  {
    "x": "werdî-werdî",
    "y": " b. hurdî-hurdî"
  },
  {
    "x": "werdîkerdiş (n)",
    "y": " b. hurdîkerdiş (n)"
  },
  {
    "x": "werdpewj, -e",
    "y": " aşçı"
  },
  {
    "x": "werdpewjîye (m)",
    "y": " aşçılık"
  },
  {
    "x": "were (n)",
    "y": " b. werrek (n)"
  },
  {
    "x": "werey...",
    "y": " b. werrekê..."
  },
  {
    "x": "werê...",
    "y": " b. werrekê..."
  },
  {
    "x": "werek (n)",
    "y": " b. werrek (n)"
  },
  {
    "x": "wereka",
    "y": " b. werrekna "
  },
  {
    "x": "werekîna",
    "y": " b. werrekna"
  },
  {
    "x": "werekna",
    "y": " b. werrekna"
  },
  {
    "x": "werem (n)",
    "y": " verem, tüberküloz"
  },
  {
    "x": "werey ameyene",
    "y": " b. werê ameyene"
  },
  {
    "x": "werey ardene",
    "y": " b. werê ardene"
  },
  {
    "x": "werey geyrayene",
    "y": " b. werê gêrayene"
  },
  {
    "x": "werey kewtene",
    "y": " b. werê kewtene"
  },
  {
    "x": "werey kowtene",
    "y": " b. werê kewtene"
  },
  {
    "x": "wereyameyîş (n)",
    "y": " b. werêameyîş (n)"
  },
  {
    "x": "wereygeyrayîş (n)",
    "y": " b. werêgêrayîş (n)"
  },
  {
    "x": "wereyîş (n)",
    "y": " b. werîyayîş (n)"
  },
  {
    "x": "wereykewtiş (n)",
    "y": " b. werêkewtiş (n)"
  },
  {
    "x": "wereykowtiş (n)",
    "y": " b. werêkewtiş (n)"
  },
  {
    "x": "wereza (n)",
    "y": " b. wareza (n)"
  },
  {
    "x": "werezaye (m)",
    "y": " b. warezaye (m)"
  },
  {
    "x": "wereznayene",
    "y": " b. weriznayene"
  },
  {
    "x": "wereznayîş (n)",
    "y": " b. weriznayîş (n)"
  },
  {
    "x": "wereznîyayene",
    "y": " b. weriznîyayene"
  },
  {
    "x": "wereznîyayîş (n",
    "y": " b. weriznîyayîş (n)"
  },
  {
    "x": "werê amayene",
    "y": " b. werê ameyene"
  },
  {
    "x": "werê ameyene",
    "y": " 1)barışmak\r\n~2)bağdaşmak"
  },
  {
    "x": "werê ardene",
    "y": " barıştırmak"
  },
  {
    "x": "werê dayene",
    "y": " karıştırmak (birbirine karıştırmak, birbirine katmak)"
  },
  {
    "x": "werê dîyayene",
    "y": " karıştırılmak (birbirine karıştırılmak)"
  },
  {
    "x": "werê fînîyayene",
    "y": " karıştırılmak (birbirine karıştırılmak)"
  },
  {
    "x": "werê fîştene",
    "y": " karıştırmak (birbirine karıştırmak, birbirine katmak)"
  },
  {
    "x": "werê geyrayene",
    "y": " b. werê gêrayene"
  },
  {
    "x": "werê gêrayene",
    "y": " acımak\r\n~Ez Hişyarî rê zaf werê gêrena. (Hişyar’a çok acıyorum.)"
  },
  {
    "x": "werê kewtene",
    "y": " acımak\r\n~Ez Fîraze rê werê kewta. (Fîraze’ye acıdım.)"
  },
  {
    "x": "werê kotene",
    "y": " b. werê kewtene"
  },
  {
    "x": "werê vistene",
    "y": " b. werê fîştene"
  },
  {
    "x": "werê visteni",
    "y": " b. werê fîştene"
  },
  {
    "x": "werêameyîş (n)",
    "y": " barışma"
  },
  {
    "x": "werêarde (n, sn)",
    "y": " kurgu, montaj"
  },
  {
    "x": "werêardiş (n)",
    "y": " barıştırma"
  },
  {
    "x": "werêdayîş (n)",
    "y": " karıştırma (birbirine karıştırma)"
  },
  {
    "x": "werêdîyayîş (n)",
    "y": " karıştırılma (birbirine karıştırılma)"
  },
  {
    "x": "werêfînîyayîş (n)",
    "y": " karıştırılma (birbirine karıştırılma)"
  },
  {
    "x": "werêfîştiş (n)",
    "y": " karıştırma (birbirine karıştırma)"
  },
  {
    "x": "werêgeyrayîş (n)",
    "y": " b. werêgêrayîş (n)"
  },
  {
    "x": "werêgêrayîş (n)",
    "y": " acıma"
  },
  {
    "x": "werêkewtiş (n)",
    "y": " acıma"
  },
  {
    "x": "werêkotiş (n)",
    "y": " b. werêkewtiş (n)"
  },
  {
    "x": "werênayîş (n)",
    "y": " sertçe tartışma"
  },
  {
    "x": "werêt, -e",
    "y": " b. wêret, -e"
  },
  {
    "x": "werêvistiş (n)",
    "y": " b. werêfîştiş (n)"
  },
  {
    "x": "werêy (n)",
    "y": " b. berey (n), erey (n)"
  },
  {
    "x": "werfe (m)",
    "y": " b. vewre (m)"
  },
  {
    "x": "werfi (m)",
    "y": " b. vewre (m)"
  },
  {
    "x": "weri (n)",
    "y": " b. werrek (n)"
  },
  {
    "x": "werikna",
    "y": " b. werrekna"
  },
  {
    "x": "weriştene",
    "y": " kalkmak"
  },
  {
    "x": "weriştiş (n)",
    "y": " kalkma"
  },
  {
    "x": "weriştiş-roniştiş",
    "y": " tutum"
  },
  {
    "x": "weriznayene",
    "y": " kaldırmak"
  },
  {
    "x": "weriznayîş (n)",
    "y": " kaldırma"
  },
  {
    "x": "weriznîyayene",
    "y": " kaldırılmak"
  },
  {
    "x": "weriznîyayîş (n)",
    "y": " kaldırılma"
  },
  {
    "x": "werî (m)",
    "y": " b. wayîrî (m)"
  },
  {
    "x": "werîbîna",
    "y": " b. yewbînî"
  },
  {
    "x": "werîbînan",
    "y": " b. yewbînî"
  },
  {
    "x": "werîkena",
    "y": " b. werrekna"
  },
  {
    "x": "werîkna",
    "y": " b. werrekna"
  },
  {
    "x": "werîyayene",
    "y": " 1)yenilmek, yenmek (yiyecek vb. için)\r\n~Gelo no vaş werîyêno? (Bu ot yenir mi?)\r\n~\t\r\n~Tîya şamî saete çend de werîyêna? (Burada akşam yemeği saat kaçta yenir?)\r\n~ 2)aşınmak \r\n~3)çürümek (diş için)"
  },
  {
    "x": "werîyayini",
    "y": " b. werîyayene"
  },
  {
    "x": "werîyayîs (n)",
    "y": " b. werîyayîş (n)"
  },
  {
    "x": "werîyayîş (n)",
    "y": " 1)yenilme, yenme (yiyecek vb. için)\r\n~2)aşınma\r\n~3)çürüme (diş için)"
  },
  {
    "x": "werre (n)",
    "y": " b. werrek (n)"
  },
  {
    "x": "werre bi...",
    "y": " b. werrek bi..."
  },
  {
    "x": "werrey...",
    "y": " b. werrekê..."
  },
  {
    "x": "werrê...",
    "y": " b. werrekê..."
  },
  {
    "x": "werrek (n)",
    "y": " gıpta, imrenti, imrenme\r\n~Werrek bi to! (Ne mutlu sana!)\r\n~\t\r\n~Werrekê to! (Ne mutlu sana!)"
  },
  {
    "x": "werrek û heşmetkar bîyene",
    "y": " hayran kalmak, çok beğenmek, hayran olmak"
  },
  {
    "x": "werreka",
    "y": " b. werrekna"
  },
  {
    "x": "werrekîna",
    "y": " b. werrekna"
  },
  {
    "x": "werrekna",
    "y": " keşke\r\n~Werrekna/kaşka/xwezila/xwezî ti ewro dewe de bibîyênê. (Keşke bugün köyde olsaydın.)"
  },
  {
    "x": "werrekûheşmetkarbîyayîş (n)",
    "y": " hayran kalma, çok beğenme, hayran olma"
  },
  {
    "x": "werri (n)",
    "y": " b. werrek (n)"
  },
  {
    "x": "werrikîna",
    "y": " b. werrekna"
  },
  {
    "x": "werrîkna",
    "y": " b. werrekna"
  },
  {
    "x": "wers-I (n)",
    "y": " ardıç"
  },
  {
    "x": "wers-II (n)",
    "y": " b. ors-II (n)"
  },
  {
    "x": "wertaq, -e",
    "y": " b. ortax, -e"
  },
  {
    "x": "wertaq bîyayîne",
    "y": " b. ortax bîyene"
  },
  {
    "x": "wertaqey (m)",
    "y": " b. ortaxîye (m)"
  },
  {
    "x": "wertaqi (m)",
    "y": " b. ortaxe (m)"
  },
  {
    "x": "wertax, -e",
    "y": " b. ortax, -e"
  },
  {
    "x": "wertaxey (m)",
    "y": " b. ortaxîye (m)"
  },
  {
    "x": "wertaxê (m)",
    "y": " b. ortaxî (m)"
  },
  {
    "x": "wertaxi (m)",
    "y": " b. ortaxe (m)"
  },
  {
    "x": "wertaxî (m)",
    "y": " b. ortaxî (m)"
  },
  {
    "x": "wertaxîye (m)",
    "y": " b. ortaxîye (m)"
  },
  {
    "x": "wertaxtî (m)",
    "y": " b. ortaxîye (m)"
  },
  {
    "x": "werte (n)",
    "y": " b. orte (n)"
  },
  {
    "x": "werte amayene",
    "y": " b. ameyene orte"
  },
  {
    "x": "werte ardene",
    "y": " b. ware ardene"
  },
  {
    "x": "werteamayîş (n)",
    "y": " b. wareameyîş (n)"
  },
  {
    "x": "werteardiş (n)",
    "y": " b. wareardiş (n)"
  },
  {
    "x": "werti (n)",
    "y": " b. orte (n)"
  },
  {
    "x": "wertî (zh)",
    "y": " b. ortî (zh)"
  },
  {
    "x": "wertmî (m)",
    "y": " b. ortme (n)"
  },
  {
    "x": "werway",
    "y": " b. warpay, -e"
  },
  {
    "x": "werxan (n)",
    "y": " b. orxan (n)"
  },
  {
    "x": "werxon (n)",
    "y": " b. orxan (n)"
  },
  {
    "x": "werzanayene",
    "y": " b. weriznayene"
  },
  {
    "x": "werzanayîş (n)",
    "y": " b. weriznayîş (n)"
  },
  {
    "x": "werznayene",
    "y": " b. weriznayene"
  },
  {
    "x": "werznayîş (n)",
    "y": " b. weriznayîş (n)"
  },
  {
    "x": "wes, -e",
    "y": " b. weş, -e"
  },
  {
    "x": "wes bîyayene",
    "y": " b. weş bîyene"
  },
  {
    "x": "wes-war, -e",
    "y": " b. weşûwar, -e"
  },
  {
    "x": "wesaît (n)",
    "y": " b. wesayît (n)"
  },
  {
    "x": "wesaîte (m)",
    "y": " b. wesayît (n)"
  },
  {
    "x": "wesanayene-I",
    "y": " b. weşanayene"
  },
  {
    "x": "wesanayene-II",
    "y": " b. weşaynayene"
  },
  {
    "x": "wesanayeni",
    "y": " b. weşanayene"
  },
  {
    "x": "wesanayêne",
    "y": " b. weşanayene"
  },
  {
    "x": "wesanayîş-I (n)",
    "y": " b. weşanayîş (n)"
  },
  {
    "x": "wesanayîş-II (n)",
    "y": " b. weşaynayîş (n)"
  },
  {
    "x": "wesar-I (n)",
    "y": " b. wisar (n)"
  },
  {
    "x": "wesar-II (n)",
    "y": " b. hevsar (n)"
  },
  {
    "x": "wesarê",
    "y": " b. wisarî"
  },
  {
    "x": "wesarî",
    "y": " b. wisarî"
  },
  {
    "x": "wesayet (n)",
    "y": " vasilik, vesayet"
  },
  {
    "x": "wesayît (n)",
    "y": " araç, taşıt, vasıta"
  },
  {
    "x": "wesayîtê cenaza (n)",
    "y": " cenaze nakil aracı"
  },
  {
    "x": "wesayîtê erazî (n)",
    "y": " arazi taşıtı"
  },
  {
    "x": "wesayîtê mektebî (n)",
    "y": " okul taşıtı"
  },
  {
    "x": "wesayîtê resî (zh)",
    "y": " ulaşım araçları"
  },
  {
    "x": "wesayîtê servîsî yê pêroyî (n)",
    "y": " umum servis aracı"
  },
  {
    "x": "wesayîtê servîsî yê umûmî (n)",
    "y": " umum servis aracı"
  },
  {
    "x": "wesayîtê servîsî yê xebatkaran (n)",
    "y": " personel servis aracı"
  },
  {
    "x": "wesayîtê wendegehî (n)",
    "y": " okul taşıtı"
  },
  {
    "x": "wesayîtê xizmetê pêroyî (n)",
    "y": " kamu hizmet taşıtı"
  },
  {
    "x": "wesayîtê xizmetê umûmîya (n)",
    "y": " kamu hizmet taşıtı"
  },
  {
    "x": "wesayîto giran (n)",
    "y": " ağır vasıta"
  },
  {
    "x": "wesayîto kaşker (n)",
    "y": " çekici araç"
  },
  {
    "x": "wesaynayene",
    "y": " b. weşaynayene"
  },
  {
    "x": "wesaynayîş (n)",
    "y": " b. weşaynayîş (n)"
  },
  {
    "x": "wesayneyene",
    "y": " b. weşaynayene"
  },
  {
    "x": "wesayneyîş (n)",
    "y": " b. weşaynayîş (n)"
  },
  {
    "x": "wesb (m)",
    "y": " b. wesfe (m)"
  },
  {
    "x": "wesb dayene",
    "y": " b. wesfî dayene"
  },
  {
    "x": "wesemernayene",
    "y": " çemremek (kol, paça, etek vb. için), sıvamak (kol, paça, etek vb. için) "
  },
  {
    "x": "wesemernayîne",
    "y": " b. wesemernayene"
  },
  {
    "x": "wesemernayîş (n)",
    "y": " çemreme (kol, paça, etek vb. için)"
  },
  {
    "x": "weset (n)",
    "y": " ortam"
  },
  {
    "x": "wesênayene",
    "y": " b. weşanayene-I"
  },
  {
    "x": "wesf (m)",
    "y": " b. wesfe (m)"
  },
  {
    "x": "wesfe (m)",
    "y": " övgü"
  },
  {
    "x": "wesfî dayene",
    "y": " övmek"
  },
  {
    "x": "wesfi (m)",
    "y": " b. wesfe (m)"
  },
  {
    "x": "wesfîdayîş (n)",
    "y": " övme, övgü"
  },
  {
    "x": "wesib (m)",
    "y": " b. wesfe (m)"
  },
  {
    "x": "wesif (m)",
    "y": " b. wesfe (m)"
  },
  {
    "x": "wesî (m)",
    "y": " referans, tavsiye\r\n~Min û to wesî. (Sana tavsiyem./Benden sana tavsiye.)"
  },
  {
    "x": "wesîqe (n)",
    "y": " belge, vesika"
  },
  {
    "x": "wesîqeyan rê",
    "y": " vesikalık"
  },
  {
    "x": "wesle (m)",
    "y": " organ, vücut organı, uzuv"
  },
  {
    "x": "wesla canî (m)",
    "y": " vücut organı, uzuv"
  },
  {
    "x": "wesli (m)",
    "y": " b. wesle (m)"
  },
  {
    "x": "wesnî (m)",
    "y": " kuma"
  },
  {
    "x": "westa, -ye",
    "y": " usta"
  },
  {
    "x": "westay (m)",
    "y": " b. westaye (m)"
  },
  {
    "x": "western (n, sn)",
    "y": " kovboy filmi, western"
  },
  {
    "x": "wesûwar, -e",
    "y": " b. weşûwar, -e"
  },
  {
    "x": "Ez wesûwar ûne.",
    "y": " b. Ez weşûwar a."
  },
  {
    "x": "weş, -e",
    "y": " 1)sağ, canlı\r\n~2)hoş\r\n~3)yakışıklı\r\n~4)sağlıklı, sağlam"
  },
  {
    "x": "weş bi ... şîyayîş",
    "y": " beğenme"
  },
  {
    "x": "weş bi ... şîyene",
    "y": " beğenmek\r\n~Qiseyê ey weş bi mi nêşîy. (Sözlerini beğenmedim.)"
  },
  {
    "x": "weş bîyayene",
    "y": " b. weş bîyene"
  },
  {
    "x": "weş bîyene",
    "y": " 1)sağlıklı olmak, sıhhatli olmak\r\n~2)iyileşmek\r\n~Ti weş be. (Sen sağ ol.)"
  },
  {
    "x": "Ti weş be. ",
    "y": "Sen sağ ol."
  },
  {
    "x": "Ti weş bi.",
    "y": " b. Ti weş be."
  },
  {
    "x": "Ti weş ib.",
    "y": " b. Ti weş be."
  },
  {
    "x": "Weş be. ",
    "y": " Sağ ol"
  },
  {
    "x": "(Ti) Weş bî/bê/ba. ",
    "y": " Sağ olasın."
  },
  {
    "x": "weşê ... ra şîyene",
    "y": " beğenmek"
  },
  {
    "x": "weşê ... raşîyayîş",
    "y": " beğenme"
  },
  {
    "x": "weşî ... ra şîyayîne",
    "y": " b. weşê ... ra şîyene"
  },
  {
    "x": "weş-war",
    "y": " b. weşûwar, -e"
  },
  {
    "x": "weşan (n)",
    "y": " yayın, neşriyat"
  },
  {
    "x": "weşano cande (n)",
    "y": " canlı yayın"
  },
  {
    "x": "weşano ganî (n)",
    "y": " canlı yayın"
  },
  {
    "x": "weşanayene-I",
    "y": " yayımlamak, neşretmek"
  },
  {
    "x": "weşanayene-II",
    "y": " yıkmak"
  },
  {
    "x": "weşanayeni",
    "y": " b. weşanayene-II"
  },
  {
    "x": "weşanayîne",
    "y": " b. weşanayene-II"
  },
  {
    "x": "weşanayîş-I (n)",
    "y": " 1)yayımlama, neşretme\r\n~2)yayım, neşir"
  },
  {
    "x": "weşanayîş-II (n)",
    "y": " 1)yıkma, yıkım\r\n~2)bozma"
  },
  {
    "x": "weşanger, -e",
    "y": " editör, naşir, yayımcı"
  },
  {
    "x": "weşangerênî (m)",
    "y": " editörlük, naşirlik, yayımcılık"
  },
  {
    "x": "weşangerî (m)",
    "y": " editörlük, naşirlik, yayımcılık"
  },
  {
    "x": "weşangerîye (m)",
    "y": " editörlük, naşirlik, yayımcılık"
  },
  {
    "x": "weşanîyaya/e",
    "y": " bozuk, bozulmuş olan"
  },
  {
    "x": "weşanîyayene",
    "y": " yayımlanmak, neşredilmek"
  },
  {
    "x": "weşanîyayene",
    "y": " 1)yıkılmak\r\n~2)bozulmak"
  },
  {
    "x": "weşanîyayîne",
    "y": " b. weşanîyayene"
  },
  {
    "x": "weşanîyayîş-I (n)",
    "y": " yayımlanma, neşredilme"
  },
  {
    "x": "weşanîyayîş-II (n)",
    "y": " 1)yıkılma\r\n~2)çöküş, çöküş (devlet veya uygarlık için), inhitat, dekadans 2)bozulma"
  },
  {
    "x": "weşanxane (n)",
    "y": " yayınevi"
  },
  {
    "x": "weşaynakerê şewatî (n)",
    "y": " yangın söndürücü"
  },
  {
    "x": "weşaynayene",
    "y": " söndürmek\r\n~Va mume şayneno we. (Rüzgâr mumu söndürür.)"
  },
  {
    "x": "weşaynayîş (n)",
    "y": " söndürme"
  },
  {
    "x": "weşbîyayîş (n)",
    "y": " 1)sağlıklı olma\r\n~2)şifa, iyileşme, sağalma"
  },
  {
    "x": "weşboye (m)",
    "y": " aroma"
  },
  {
    "x": "weşe-I (n)",
    "y": " başak"
  },
  {
    "x": "weşe arê dayene",
    "y": " taraşlamak (başak için), başak toplamak"
  },
  {
    "x": "weşe girewtene",
    "y": " başaklanmak, başak bağlamak, başak düzmek"
  },
  {
    "x": "weşe kerdene",
    "y": " ballandırmak"
  },
  {
    "x": "weşeyê genimî (n)",
    "y": " buğday başağı"
  },
  {
    "x": "weşeyê xeleyî (n)",
    "y": " buğday başağı"
  },
  {
    "x": "weşe-II (n)",
    "y": " b. gûşe-I (n)"
  },
  {
    "x": "weşearêdayîş (n)",
    "y": " taraşlama (başak için)"
  },
  {
    "x": "weşeke (m)",
    "y": " tütün bulunamayınca onun yerine içilen kurumuş ağaç/bitki yaprağı"
  },
  {
    "x": "weşekerdiş (n)",
    "y": " ballandırma"
  },
  {
    "x": "weşeki (m)",
    "y": " b. weşeke (m)"
  },
  {
    "x": "weşemernayîne",
    "y": " b. wesemernayene"
  },
  {
    "x": "weşemernayîş (n)",
    "y": " wesemernayîş (n)"
  },
  {
    "x": "weşeq, -e",
    "y": " vaşak"
  },
  {
    "x": "weşey (m)",
    "y": " b. weşîye (m)"
  },
  {
    "x": "Weşey di bimane",
    "y": " b. Weşîye/weşî de bimane."
  },
  {
    "x": "weşeyin (n)",
    "y": " başaklı"
  },
  {
    "x": "weşeyîn (n)",
    "y": " b. weşeyin (n)"
  },
  {
    "x": "weşê (m)",
    "y": " b. weşîye (m), weşî (m)"
  },
  {
    "x": "Weşê di bimûn",
    "y": " b. Weşîye/weşî de bimane"
  },
  {
    "x": "weşi-I (n)",
    "y": " b. weşe-I (n)"
  },
  {
    "x": "weşê xelî (n)",
    "y": " b. weşeyê xeleyî (n)"
  },
  {
    "x": "weşi arê dayîne",
    "y": " b. weşe arê dayene"
  },
  {
    "x": "weşi guretiş",
    "y": " b. weşe girewtene"
  },
  {
    "x": "weşi-II (n)",
    "y": " b. gûşe-I (n)"
  },
  {
    "x": "weşi-III (m)",
    "y": " b. weşe (m)"
  },
  {
    "x": "weşiarêdayîş (n)",
    "y": " b. weşearêdayîş (n)"
  },
  {
    "x": "weşiyin (n)",
    "y": " b. weşeyin (n)"
  },
  {
    "x": "weşî (m)",
    "y": " 1)hoşluk\r\n~2)sağlık, sıhhat\r\n~3)iyilik\r\n~4)esenlik"
  },
  {
    "x": "weşî de",
    "y": " sağlıcakla, esenlikle\r\n~Weşî de bimane. (Esenlikle/sağlıcakla/hoşça kal.)\r\n~Weşî di bimûn/bimûne. b. Weşî de bimane.\r\n~Bimane weşî de. (Esenlikle/sağlıcakla/hoşça kal.)\r\n~Bimûni weşî di. b. Bimane weşî de"
  },
  {
    "x": "weşî ... raşîyayîş",
    "y": " b. weşê ... raşîyayîş"
  },
  {
    "x": "weşî ... şîyayîne",
    "y": " b. weş bi ... şîyene"
  },
  {
    "x": "weşî ... şîyayîş",
    "y": " b. weş bi ... şîyayîş"
  },
  {
    "x": "weşîya canî (m)",
    "y": " can sağlığı"
  },
  {
    "x": "weşîya doruverî (m)",
    "y": " çevre sağlığı"
  },
  {
    "x": "weşîya ganî (m)",
    "y": " can sağlığı"
  },
  {
    "x": "weşîye (m)",
    "y": " 1)hoşluk\r\n~2)sağlık, sıhhat\r\n~3)iyilik\r\n~4)esenlik"
  },
  {
    "x": "Biweşî!",
    "y": " Sağlığa!\r\n~Ez to rê bi weşîye vana. (Sana iyilikle söylüyorum.)"
  },
  {
    "x": "weşîye de",
    "y": " sağlıcakla, esenlikle\r\n~Weşîye de bimane. (Esenlikle/sağlıcakla/hoşça kal.)\r\n~\t\r\n~Bimane weşîye de. (Esenlikle/sağlıcakla/hoşça kal.)"
  },
  {
    "x": "weşîye rê zerardar",
    "y": " sağlığa zararlı"
  },
  {
    "x": "weşîye rê zîyandar",
    "y": " sağlığa zararlı"
  },
  {
    "x": "weşker, -e",
    "y": " sağaltıcı, sağaltımcı, terapist"
  },
  {
    "x": "weşkerdiş (n)",
    "y": " sağaltım, sağaltma, tedavi, terapi"
  },
  {
    "x": "weşkerdişê îmajî (n)",
    "y": " imaj düzeltme"
  },
  {
    "x": "weşnus, -e",
    "y": " hattat"
  },
  {
    "x": "weşonayîş (n)",
    "y": " b. weşanayîş (n)"
  },
  {
    "x": "weştiş (n)",
    "y": " b. waştiş (n)"
  },
  {
    "x": "weşûnayene",
    "y": " b. weşanayene"
  },
  {
    "x": "weşûnayîş (n)",
    "y": " b. weşanayîş (n)"
  },
  {
    "x": "weşûnîyayene",
    "y": " b. weşanîyayene"
  },
  {
    "x": "weşûnîyayîş (n)",
    "y": " b. weşanîyayîş (n)"
  },
  {
    "x": "weşûwahar",
    "y": " b. weşûwar, -e"
  },
  {
    "x": "weşûwar, -e",
    "y": " sapasağlam, capcanlı\r\n~Ez weşûwar a. (Ben sapasağlamım.)"
  },
  {
    "x": "weşvîn, -e",
    "y": " iyimser"
  },
  {
    "x": "weşvînîye (m)",
    "y": " iyimserlik"
  },
  {
    "x": "wet (n)",
    "y": " öte, o taraf, karşı yaka"
  },
  {
    "x": "wet a",
    "y": " b. wet ra"
  },
  {
    "x": "wet ra",
    "y": " öteden, o taraftan, karşı taraftan"
  },
  {
    "x": "weta",
    "y": " öteye, o tarafa, karşı yakaya"
  },
  {
    "x": "wetanayîne",
    "y": " b. ewtanayene"
  },
  {
    "x": "wetanayîş (n)",
    "y": " b. ewtanayîş (n)"
  },
  {
    "x": "wetard",
    "y": " cesaret etti/korkmadı "
  },
  {
    "x": "wetardene",
    "y": " cesaret etmek, cüret etmek"
  },
  {
    "x": "wetardeni",
    "y": " b. wetardene"
  },
  {
    "x": "wetardiş (n)",
    "y": " cesaret etme, cüret etme"
  },
  {
    "x": "wetên, -e",
    "y": " ötedeki"
  },
  {
    "x": "wetonayîne",
    "y": " b. ewtanayene"
  },
  {
    "x": "wetonayîş (n)",
    "y": " b. ewtanayîş (n)"
  },
  {
    "x": "wetûnayîne",
    "y": " b. ewtanayene"
  },
  {
    "x": "wetûnayîş (n)",
    "y": " b. ewtanayîş (n)"
  },
  {
    "x": "wever (n)",
    "y": " öte, o taraf, karşı yaka"
  },
  {
    "x": "weverên, -e",
    "y": " ötedeki, o taraftaki, karşı yakadaki, karşıdaki"
  },
  {
    "x": "wevirisknayene",
    "y": " b. wevirsiknayene"
  },
  {
    "x": "wevirsiknayene",
    "y": " tiftiklemek"
  },
  {
    "x": "weviznayene",
    "y": " b. vîjnayene"
  },
  {
    "x": "weviznayîş (n)",
    "y": " b. vîjnayîş (n)"
  },
  {
    "x": "wevîznayene",
    "y": " b. vîjnayene"
  },
  {
    "x": "wevîznayîş (n)",
    "y": " b. vîjnayîş (n)"
  },
  {
    "x": "wexir (n)",
    "y": " b. oxir (n)"
  },
  {
    "x": "wext (n)",
    "y": " vakit, zaman, çağ"
  },
  {
    "x": "wext ameyene",
    "y": " vakit gelmek"
  },
  {
    "x": "wext bîlasebeb vîyarnayene",
    "y": " vakit öldürmek, zaman öldürmek"
  },
  {
    "x": "wext bîlasebebvîyarnayîş (n)",
    "y": " vakit öldürme, zaman öldürme"
  },
  {
    "x": "wext de",
    "y": " 1)vaktinde, zamanında (tam vaktinde)\r\n~2)vakitli, zamanlı"
  },
  {
    "x": "wext de bîyaye",
    "y": " vakitli, zamanlı"
  },
  {
    "x": "wext de bîyayî, -ye",
    "y": " vakitli, zamanlı"
  },
  {
    "x": "wext derbaz kerdene",
    "y": " vakit geçirmek, zaman geçirmek"
  },
  {
    "x": "wext di",
    "y": " b. wext de"
  },
  {
    "x": "wext girewtene",
    "y": " vakit almak, zaman almak"
  },
  {
    "x": "wext id",
    "y": " b. wext de"
  },
  {
    "x": "wext mirênayene",
    "y": " vakit öldürmek, zaman öldürmek"
  },
  {
    "x": "wext mirênayîş (n)",
    "y": " vakit öldürme, zaman öldürme"
  },
  {
    "x": "wext ra wext",
    "y": " bütün gün (tam gün, yirmi dört saat)"
  },
  {
    "x": "wext ravîyarnayene",
    "y": " vakit geçirmek, zaman geçirmek"
  },
  {
    "x": "wext tal derbaz kerdene",
    "y": " vakit öldürmek, zaman öldürmek"
  },
  {
    "x": "wext tal vîyarnayene",
    "y": " vakit öldürmek, zaman öldürmek"
  },
  {
    "x": "wext talderbazkerdiş (n)",
    "y": " vakit öldürme, zaman öldürme"
  },
  {
    "x": "wext talvîyarnayîş (n)",
    "y": " vakit öldürme, zaman öldürme"
  },
  {
    "x": "wext veng derbaz kerdene",
    "y": " vakit öldürmek, zaman öldürmek"
  },
  {
    "x": "wext veng ravêrnayene",
    "y": " b. wext veng ravîyarnayene"
  },
  {
    "x": "wext veng ravîyarnayene",
    "y": " vakit öldürmek, zaman öldürmek"
  },
  {
    "x": "wext veng vîyarnayene",
    "y": " vakit öldürmek, zaman öldürmek"
  },
  {
    "x": "wext vengderbazkerdiş (n)",
    "y": " vakit öldürme, zaman öldürme"
  },
  {
    "x": "wext vengravêrnayîş (n)",
    "y": " b. wext vengravîyarnayîş (n)"
  },
  {
    "x": "wext vengravîyarnayîş (n)",
    "y": " vakit öldürme, zaman öldürme"
  },
  {
    "x": "wext vengvîyarnayîş (n)",
    "y": " vakit öldürme, zaman öldürme"
  },
  {
    "x": "wext vîndî kerdene",
    "y": " vakit kaybetmek, zaman kaybetmek"
  },
  {
    "x": "wext vîndîkerdiş (n)",
    "y": " vakit kaybetme, zaman kaybetme"
  },
  {
    "x": "wext vîyarnayene",
    "y": " vakit geçirmek, zaman geçirmek"
  },
  {
    "x": "wexta kî",
    "y": " b. wexto ke"
  },
  {
    "x": "wextano ke",
    "y": " -diği zaman, -diği vakit, ne zaman ki, vakta ki"
  },
  {
    "x": "wextano ki",
    "y": " b. wextano ke"
  },
  {
    "x": "wextê ... ameyene",
    "y": " vakti gelmek, zamanı gelmek, çağı gelmek"
  },
  {
    "x": "wextê akewtişê rojî",
    "y": " güneşin doğuş vakti"
  },
  {
    "x": "wextê citan (n)",
    "y": " b. wextê cite (n)"
  },
  {
    "x": "wextê cite (n)",
    "y": " çift zamanı"
  },
  {
    "x": "wextê citi (n)",
    "y": " b. wextê cite (n)"
  },
  {
    "x": "wextê debare (n)",
    "y": " hasat zamanı"
  },
  {
    "x": "wextê debari (n)",
    "y": " b. wextê debare (n)"
  },
  {
    "x": "wextê debri (n)",
    "y": " b. wextê debare (n)"
  },
  {
    "x": "wextê debrî (n)",
    "y": " b. wextê debare (n)"
  },
  {
    "x": "wextê ... derbaz bîyene",
    "y": " çağı geçmek"
  },
  {
    "x": "wextê ... girewtene",
    "y": " vaktini almak, zamanını almak"
  },
  {
    "x": "wextê girnawî",
    "y": " b. wextê girnîyawî"
  },
  {
    "x": "wextê girnawû",
    "y": " b. wextê girnîyawî"
  },
  {
    "x": "wextê girnîyawî",
    "y": " kedilerin çiftleşme dönemi"
  },
  {
    "x": "wextê girrnawî",
    "y": " b. wextê girnîyawî"
  },
  {
    "x": "wextê girrnawû",
    "y": " b. wextê girnîyawî"
  },
  {
    "x": "wextê ... guretene",
    "y": " b. wextê ... girewtene"
  },
  {
    "x": "wextê hesadan (n)",
    "y": " hasat zamanı"
  },
  {
    "x": "wextê hesadî (n)",
    "y": " b. wextê hesadan (n)"
  },
  {
    "x": "wextê kergeyan (n)",
    "y": " bağbozumu (bağbozumu zamanı)"
  },
  {
    "x": "wextê leglegan (n)",
    "y": " mart dokuzu (baharın başlangıcı)"
  },
  {
    "x": "wextê lenglengan (n)",
    "y": " b. wextê leglegan (n)"
  },
  {
    "x": "wextê leylegan (n)",
    "y": " b. wextê leglegan (n)"
  },
  {
    "x": "wextê mahseran (n)",
    "y": " bağbozumu (bağbozumu zamanı)"
  },
  {
    "x": "wextê margî (n)",
    "y": " b. wextê mergî (n)"
  },
  {
    "x": "wextê margî omeyene",
    "y": " b. wextê mergî ameyene"
  },
  {
    "x": "wextê mergî (n)",
    "y": " ecel"
  },
  {
    "x": "wextê mergî ameyene",
    "y": " eceli gelmek, vakti gelmek (eceli gelmek), zamanı gelmek (eceli gelmek)"
  },
  {
    "x": "wextê paleyî (n)",
    "y": " hasat zamanı"
  },
  {
    "x": "wextê palî (n)",
    "y": " b. wextê paleyî (n)"
  },
  {
    "x": "wextê qirnawî",
    "y": " kedilerin çiftleşme dönemi"
  },
  {
    "x": "wextê rezbirînan (n)",
    "y": " bağ budama zamanı"
  },
  {
    "x": "wextê serê rezan (n)",
    "y": " bağbozumu (bağbozumu zamanı)"
  },
  {
    "x": "wextê vengdayîşê dîkan",
    "y": " güneşin doğuş vakti"
  },
  {
    "x": "wextê veyndayîşê dîkan",
    "y": " b. wextê vengdayîşê dîkan"
  },
  {
    "x": "wextê veyndayîşê dîkon",
    "y": " b. wextê vengdayîşê dîkan"
  },
  {
    "x": "wextê veyndayîşî dîkûn",
    "y": " b. wextê vengdayîşê dîkan"
  },
  {
    "x": "wextê virnaw",
    "y": " b. wextê qirnawî"
  },
  {
    "x": "wextê virnîyawî",
    "y": " b. wextê qirnawî"
  },
  {
    "x": "wextê ... vîyartene",
    "y": " çağı geçmek"
  },
  {
    "x": "wextê xo de",
    "y": " 1)vaktinde, zamanında (tam vaktinde)\r\n~2)vaktiyle, bir zamanlar"
  },
  {
    "x": "wextê xo derbaz kerdene",
    "y": " oyalanmak"
  },
  {
    "x": "wextê xo derbazkerdiş (n)",
    "y": " oyalanma"
  },
  {
    "x": "wextê xo tal vîyarnayene",
    "y": " abesle iştigal etmek"
  },
  {
    "x": "wextê xo veng vîyarnayene",
    "y": " abesle iştigal etmek"
  },
  {
    "x": "wextê xo vîyarnayene",
    "y": " oyalanmak"
  },
  {
    "x": "wextê xo vîyarnayîş (n)",
    "y": " oyalanma"
  },
  {
    "x": "wextê xu di",
    "y": " b. wextê xo de"
  },
  {
    "x": "wextê xwi di",
    "y": " b. wextê xo de"
  },
  {
    "x": "wextino ke",
    "y": " b. wextano ke"
  },
  {
    "x": "wextî citûn (n)",
    "y": " b. wextê cite (n)"
  },
  {
    "x": "wextî\t... Girotene",
    "y": "b. wextê ... Girewtene"
  },
  {
    "x": "wextî leglegûn (n)",
    "y": " b. wextê leglegan (n)"
  },
  {
    "x": "wextî xwe di",
    "y": " b. wextê xo de"
  },
  {
    "x": "wexto derg (n)",
    "y": " hayli zaman, uzun zaman"
  },
  {
    "x": "wexto g’",
    "y": " b. wexto ke"
  },
  {
    "x": "wexto ke",
    "y": " -diği zaman, -diği vakit, ne zaman ki, vakta ki"
  },
  {
    "x": "wexto ki",
    "y": " b. wexto ke"
  },
  {
    "x": "wext (m)",
    "y": " b. wext (n)"
  },
  {
    "x": "wext-bêwext",
    "y": " vakitli vakitsiz, zamanlı zamansız"
  },
  {
    "x": "wextameyîş (n)",
    "y": " vakit gelme"
  },
  {
    "x": "wextano",
    "y": " b. wextano ke"
  },
  {
    "x": "wextderbazkerdiş (n)",
    "y": " vakit geçirme, zaman geçirme"
  },
  {
    "x": "wexte (m)",
    "y": " b. wext (n)"
  },
  {
    "x": "wextê",
    "y": " vaktiyle, bir zamanlar"
  },
  {
    "x": "wextêk",
    "y": " vaktiyle, bir zamanlar"
  },
  {
    "x": "wextgirewtiş (n)",
    "y": " vakit alma, zaman alma"
  },
  {
    "x": "wexti (m)",
    "y": " b. wext (n)"
  },
  {
    "x": "wexto",
    "y": " b. wexto ke"
  },
  {
    "x": "wextravîyarnayîş (n)",
    "y": " vakit geçirme, zaman geçirme"
  },
  {
    "x": "wextvîyarnayîş (n)",
    "y": " vakit geçirme, zaman geçirme"
  },
  {
    "x": "wey-I",
    "y": " 1)o\r\n~2)bu"
  },
  {
    "x": "weyca (n)",
    "y": " ora, orası"
  },
  {
    "x": "weyra (n)",
    "y": " ora, orası, orada"
  },
  {
    "x": "weyta (n)",
    "y": " bura, burası"
  },
  {
    "x": "wey-II (m)",
    "y": " b. waye (m)"
  },
  {
    "x": "wey-III (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "wey kerdene",
    "y": " b. weyî kerdene"
  },
  {
    "x": "weykerdiş (n)",
    "y": " b. weyîkerdiş (n)"
  },
  {
    "x": "weyca (n)",
    "y": " ora, orası"
  },
  {
    "x": "weye kerdene",
    "y": " bakmak"
  },
  {
    "x": "weyekerda (m)",
    "y": " bakımlı"
  },
  {
    "x": "weyekerde (n)",
    "y": " bakımlı"
  },
  {
    "x": "weyekerdeyîye (m)",
    "y": " bakımlılık"
  },
  {
    "x": "weyekerdiş (n)",
    "y": " bakma"
  },
  {
    "x": "weyekerdî, -ye",
    "y": " bakımlı"
  },
  {
    "x": "weyekerdîyîye (m)",
    "y": " bakımlılık"
  },
  {
    "x": "weyekerdoxîye (m)",
    "y": " bakıcılık"
  },
  {
    "x": "weyeyîye (m)",
    "y": " b. wayîrîye (m)"
  },
  {
    "x": "weyin (n)",
    "y": " b. oyn (n)"
  },
  {
    "x": "weyî (m)",
    "y": " b. weyîye (m)"
  },
  {
    "x": "weyî kerdene",
    "y": " bakmak"
  },
  {
    "x": "weyîkerda (m)",
    "y": " bakımlı"
  },
  {
    "x": "weyîkerde (n)",
    "y": " bakımlı"
  },
  {
    "x": "weyîkerdeyîye (m)",
    "y": " bakımlılık"
  },
  {
    "x": "weyîkerdiş (n)",
    "y": " bakma"
  },
  {
    "x": "weyîkerdî, -ye",
    "y": " bakımlı"
  },
  {
    "x": "weyîkerdîyîye (m)",
    "y": " bakımlılık"
  },
  {
    "x": "weyîkerdoxîye (m)",
    "y": " bakıcılık"
  },
  {
    "x": "weyîn (n)",
    "y": " b. oyn (n)"
  },
  {
    "x": "weyîtî (m)",
    "y": " bakım"
  },
  {
    "x": "weyîtîye (m)",
    "y": " b. wayîrîye (m)"
  },
  {
    "x": "weyîye (m)",
    "y": " bakım"
  },
  {
    "x": "weyîyîye (m)",
    "y": " b. wayîrîye (m)"
  },
  {
    "x": "weyk (n)",
    "y": " b. ewk-I (n)"
  },
  {
    "x": "weykerdiş (n)",
    "y": " b. weyîkerdiş (n)"
  },
  {
    "x": "weykerdoxîye (m)",
    "y": " b. weyekerdoxîye (m), weyîkerdoxîye (m)"
  },
  {
    "x": "weyn (n)",
    "y": " b. oyn (n)"
  },
  {
    "x": "weynayîş (n)",
    "y": " b. ewnîyayîş (n) "
  },
  {
    "x": "weyniker, -e",
    "y": " b. oyniker, -e"
  },
  {
    "x": "weyniştiş (n)",
    "y": " b. weniştiş (n)"
  },
  {
    "x": "weyra (n)",
    "y": " ora, orası, orada\r\n~A weyra ya. (O oradadır.)\r\n~\t\r\n~O weyra yo. (O oradadır.)"
  },
  {
    "x": "weyre (n)",
    "y": " b. weyra (n)"
  },
  {
    "x": "weysiz, -e",
    "y": " meret (b. bêwa, bêwe)"
  },
  {
    "x": "weysizi (m)",
    "y": " weysize (m)"
  },
  {
    "x": "weyştiş (n)",
    "y": " b. waştiş (n)"
  },
  {
    "x": "weyta (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "weytî (m)",
    "y": " b. weyîtî (m)"
  },
  {
    "x": "weytîye (m)",
    "y": " b. wayîrîye (m)"
  },
  {
    "x": "wez (m)",
    "y": " b. weaze (m)"
  },
  {
    "x": "weza, -ye",
    "y": " zayıf, nahif"
  },
  {
    "x": "weza bîyayene",
    "y": " b. weza bîyene"
  },
  {
    "x": "weza bîyene",
    "y": " zayıflamak"
  },
  {
    "x": "weza kerdene",
    "y": " zayıflatmak"
  },
  {
    "x": "wezabîyayîş (n)",
    "y": " zayıflama"
  },
  {
    "x": "wezakerdiş (n)",
    "y": " zayıflatma"
  },
  {
    "x": "wezan",
    "y": " b. wazan, -e"
  },
  {
    "x": "wezanê (m)",
    "y": " b. wazanîye (m), wazanî (m)"
  },
  {
    "x": "wezanê kerdene",
    "y": " b. wazanî kerdene, wazanîye kerdene"
  },
  {
    "x": "wezanêkerdiş (n)",
    "y": " b. wazanîkerdiş (n), wazanîyekerdiş (n)"
  },
  {
    "x": "wezaret (n)",
    "y": " bakanlık, vekillik, vekâlet"
  },
  {
    "x": "wezaretan reyde yewbînîrênuştiş",
    "y": " bakanlıklarla yazışma"
  },
  {
    "x": "wezaretan reyra pêrênuştiş",
    "y": " bakanlıklarla yazışma"
  },
  {
    "x": "wezaretê awanîye (n)",
    "y": " bayındırlık bakanlığı "
  },
  {
    "x": "wezaretê bazirganîye (n)",
    "y": " ticaret bakanlığı"
  },
  {
    "x": "wezaretê çorşme û bajarawanîye (n)",
    "y": " çevre ve şehircilik bakanlığı"
  },
  {
    "x": "wezaretê dewlete (n)",
    "y": " devlet bakanlığı "
  },
  {
    "x": "wezaretê dormeyî (n)",
    "y": " çevre bakanlığı"
  },
  {
    "x": "wezaretê dorûver û şaristanawanîye (n)",
    "y": " çevre ve şehircilik bakanlığı"
  },
  {
    "x": "wezaretê dorûverî (n)",
    "y": " çevre bakanlığı "
  },
  {
    "x": "wezaretê edaletî (n)",
    "y": " adalet bakanlığı "
  },
  {
    "x": "wezaretê enerjî (n)",
    "y": " enerji bakanlığı "
  },
  {
    "x": "wezaretê karî (n)",
    "y": " çalışma bakanlığı "
  },
  {
    "x": "wezaretê kulturî (n)",
    "y": " kültür bakanlığı "
  },
  {
    "x": "wezaretê malîye (n)",
    "y": " maliye bakanlığı"
  },
  {
    "x": "wezaretê pawitişî (n)",
    "y": " savunma bakanlığı "
  },
  {
    "x": "wezaretê perwerdeyî (n)",
    "y": " eğitim bakanlığı "
  },
  {
    "x": "wezaretê petrolî (n)",
    "y": " petrol bakanlığı "
  },
  {
    "x": "wezaretê weşîye (n)",
    "y": " sağlık bakanlığı"
  },
  {
    "x": "wezaretê zîretî (n)",
    "y": " tarım bakanlığı, ziraat bakanlığı "
  },
  {
    "x": "wezayênî (m)",
    "y": " zayıflık"
  },
  {
    "x": "wezayî (m)",
    "y": " zayıflık"
  },
  {
    "x": "wezayîye (m)",
    "y": " zayıflık"
  },
  {
    "x": "wezêt (n)",
    "y": " b. wezîyet (n)"
  },
  {
    "x": "wezik (m)",
    "y": " b. wezike (m)"
  },
  {
    "x": "wezike (m)",
    "y": " hıçkırık"
  },
  {
    "x": "wezîfe (n)",
    "y": " görev, ödev, vazife"
  },
  {
    "x": "wezîfe dayene",
    "y": " görevlendirmek, vazifelendirmek, tavzif etmek"
  },
  {
    "x": "wezîfeyo demdemî (n)",
    "y": " geçici görev"
  },
  {
    "x": "wezîfeyo muweqet (n)",
    "y": " geçici görev"
  },
  {
    "x": "wezîfedar, -e",
    "y": " görevli, vazifeli"
  },
  {
    "x": "wezîfedar kerdene",
    "y": " görevlendirmek, vazifelendirmek, tavzif etmek"
  },
  {
    "x": "wazîfedara/ê banderîye",
    "y": " öğretim görevlisi"
  },
  {
    "x": "wezîfedara/ê cigêrayîşî",
    "y": " araştırma görevlisi"
  },
  {
    "x": "wezîfedara/ê hemetkerdişî",
    "y": " koruma görevlisi"
  },
  {
    "x": "wezîfedara/ê muhafezeyî",
    "y": " koruma görevlisi"
  },
  {
    "x": "wezîfedara/ê pawitişî",
    "y": " koruma görevlisi"
  },
  {
    "x": "wezîfedara/ê protokolî",
    "y": " protokol görevlisi"
  },
  {
    "x": "wezîfedara/ê seveknayîşî",
    "y": " koruma görevlisi"
  },
  {
    "x": "wezîfedara/ê warî",
    "y": " alan görevlisi"
  },
  {
    "x": "wezîfedarkerdiş (n)",
    "y": " görevlendirme, vazifelendirme, tavzif etme"
  },
  {
    "x": "wezîfedarkerdişê wesayîtî (n)",
    "y": " araç görevlendirmesi"
  },
  {
    "x": "wezîfedayîş (n)",
    "y": " görev verme, görevlendirme, vazifelendirme, tavzif etme"
  },
  {
    "x": "wezîferaeştiş (n)",
    "y": " azil"
  },
  {
    "x": "wezîferavetiş (n)",
    "y": " azil"
  },
  {
    "x": "wezîr, -e",
    "y": " bakan\r\n~Mi wezîrî de qisey/qalî kerd. (Bakanla konuştum.)"
  },
  {
    "x": "wezîra/ê zîretî",
    "y": " tarım bakanı, ziraat bakanı"
  },
  {
    "x": "wezîrî/e de dîdarî (zh)",
    "y": " bakanla görüşmeler"
  },
  {
    "x": "wezîrî/e reyde mulaqatî (zh)",
    "y": " bakanla görüşmeler"
  },
  {
    "x": "wezîrî/e reyra dîdarî (zh)",
    "y": " bakanla görüşmeler"
  },
  {
    "x": "wezîrîye (m)",
    "y": " bakanlık, vekillik, vekâlet"
  },
  {
    "x": "wezît (n)",
    "y": " b. wezîyet (n)"
  },
  {
    "x": "wezîyet (n)",
    "y": " 1)durum, vaziyet\r\n~2)pozisyon, konum"
  },
  {
    "x": "wezn (n)",
    "y": " ölçü, vezin"
  },
  {
    "x": "weznê erûzî (n)",
    "y": " aruz ölçüsü, aruz vezni"
  },
  {
    "x": "wezne (n)",
    "y": " vezne"
  },
  {
    "x": "weznedar, -e",
    "y": " veznedar"
  },
  {
    "x": "wezûn",
    "y": " b. wazan, -e"
  },
  {
    "x": "wezûnê (m)",
    "y": " b. wazanîye (m), wazanî (m)"
  },
  {
    "x": "wezûnê kerdene",
    "y": " b. wazanî kerdene, wazanîye kerdene"
  },
  {
    "x": "wezûnêkerdiş (n)",
    "y": " b. wazanîkerdiş (n), wazanîyekerdiş (n)"
  },
  {
    "x": "wezyet (n)",
    "y": " b. wezîyet (n)"
  },
  {
    "x": "wêde (n)",
    "y": " b. weyra (n)"
  },
  {
    "x": "wêhêr (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "wêja (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "wêlidîyayene",
    "y": " b. welidîyayene "
  },
  {
    "x": "wêr (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "wêra (n)",
    "y": " b. weyra (n)"
  },
  {
    "x": "Wêranşar (n)",
    "y": " Viranşehir "
  },
  {
    "x": "wêrat, -e",
    "y": " b. wêret, -e"
  },
  {
    "x": "wêret, -e",
    "y": " akraba"
  },
  {
    "x": "wêretîye (m)",
    "y": " akrabalık "
  },
  {
    "x": "wêrey (n)",
    "y": " b. berey (n), erey (n)"
  },
  {
    "x": "wêrî ameyene",
    "y": " b. werê ameyene, bwêrîyayêne b. Werîyayene"
  },
  {
    "x": "wêrîyayêne b. werîyayene"
  },
  {
    "x": "wêrîyayîş (n)",
    "y": " b. werîyayîş (n)"
  },
  {
    "x": "wêsar (n)",
    "y": " b. wisar (n)"
  },
  {
    "x": "wêşîye (m)",
    "y": " b. weşîye (m)"
  },
  {
    "x": "wêy (m)",
    "y": " b. waye (m) "
  },
  {
    "x": "wêye (n)",
    "y": " b. huye-I (n) "
  },
  {
    "x": "wêyî kerdene",
    "y": " b. weyî kerdene"
  },
  {
    "x": "wêyîd, -e",
    "y": " b. wehîd, -e"
  },
  {
    "x": "wêyîkerdiş (n)",
    "y": " b. weyîkerdiş (n)"
  },
  {
    "x": "wêyîn (n)",
    "y": " b. oyn (n)"
  },
  {
    "x": "wêyînkar, -e",
    "y": " b. oyniker, -e"
  },
  {
    "x": "wi (n, z)",
    "y": " 1)b. o-I (n, z)\r\n~“Wi zî bawer kero.” (O da inanır.)\r\n~2)b. ey-I (n, z)\r\n~“Wi ma dê wa.” (O bizi sakladı.)"
  },
  {
    "x": "wi- (pr)",
    "y": " 1)“o” anlamı veren bir önek (b. ew-)\r\n~2)“bu” anlamı veren bir önek (b. ew-)"
  },
  {
    "x": "wica (n)",
    "y": " ora, orası"
  },
  {
    "x": "wita (n)",
    "y": " bura, burası"
  },
  {
    "x": "wica (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "wicax (n)",
    "y": " b. ocax (n)"
  },
  {
    "x": "wicaxkor",
    "y": " b. ocaxkor, -e"
  },
  {
    "x": "wicaxkuar",
    "y": " b. ocaxkor, -e"
  },
  {
    "x": "wicdan (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "wicdon (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "wicdûn (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "wicid (n)",
    "y": " b. wucud (n)"
  },
  {
    "x": "wicud (n)",
    "y": " b. wucud (n)"
  },
  {
    "x": "wicûd (n)",
    "y": " b. wucud (n) "
  },
  {
    "x": "widarîyayene",
    "y": " b. wedarîyayene"
  },
  {
    "x": "widarîyayîş (n)",
    "y": " b. wedarîyayîş (n)"
  },
  {
    "x": "wide (n)",
    "y": " b. weyra (n)"
  },
  {
    "x": "widi (n)",
    "y": " b. weyra (n)"
  },
  {
    "x": "wiher (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "wihêr (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "wihêri (m)",
    "y": " b. wayîre (m)"
  },
  {
    "x": "wihêrê (m)",
    "y": " b. wayîrîye (m)"
  },
  {
    "x": "wihîr (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "wija (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "wijdan (n)",
    "y": " vicdan"
  },
  {
    "x": "wijdîya (n)",
    "y": " b. ejdeha (n)"
  },
  {
    "x": "wijdon (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "wijdûn (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "wijîya (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "wik (m)",
    "y": " 1)b. wake (m)\r\n~2)b. wayike (m)"
  },
  {
    "x": "wikê",
    "y": " b. wakê"
  },
  {
    "x": "wikê (m)",
    "y": " b. wakîye (m)"
  },
  {
    "x": "wiki (m)",
    "y": " b. wake (m)"
  },
  {
    "x": "wiko",
    "y": " b. biko"
  },
  {
    "x": "wila",
    "y": " b. willayî"
  },
  {
    "x": "wilar (n)",
    "y": " yular"
  },
  {
    "x": "wilat (n)",
    "y": " b. welat (n)"
  },
  {
    "x": "wilay",
    "y": " b. willayî"
  },
  {
    "x": "wilayî",
    "y": " b. willayî"
  },
  {
    "x": "wile",
    "y": " b. willayî"
  },
  {
    "x": "wiley",
    "y": " b. willayî"
  },
  {
    "x": "wilê",
    "y": " b. willayî"
  },
  {
    "x": "wili",
    "y": " b. willayî"
  },
  {
    "x": "willa",
    "y": " b. willayî"
  },
  {
    "x": "willah",
    "y": " b. willayî"
  },
  {
    "x": "willahî",
    "y": " b. willayî"
  },
  {
    "x": "willay",
    "y": " b. willayî"
  },
  {
    "x": "willayî",
    "y": " vallahi"
  },
  {
    "x": "wille",
    "y": " b. willayî"
  },
  {
    "x": "willey",
    "y": " b. willayî"
  },
  {
    "x": "willê",
    "y": " b. willayî"
  },
  {
    "x": "willi",
    "y": " b. willayî"
  },
  {
    "x": "wina",
    "y": " 1)böyle\r\n~2)böylece, böylecene, böylelikle"
  },
  {
    "x": "winasî, -ye",
    "y": " böylesi"
  },
  {
    "x": "winayen",
    "y": " b. winasî"
  },
  {
    "x": "winayên",
    "y": " b. winasî"
  },
  {
    "x": "winayin",
    "y": " b. winasî"
  },
  {
    "x": "winayîn",
    "y": " b. winasî"
  },
  {
    "x": "winiç",
    "y": " b. honik, -e"
  },
  {
    "x": "winiyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "winî",
    "y": " öyle"
  },
  {
    "x": "winîyayen",
    "y": " b. ewnîyayene"
  },
  {
    "x": "winîyayîne",
    "y": " b. ewnîyayene"
  },
  {
    "x": "winîyayîş (n)",
    "y": " b. ewnîyayîş (n) "
  },
  {
    "x": "winyayîne",
    "y": " b. ewnîyayene"
  },
  {
    "x": "winyayîş (n)",
    "y": " b. ewnîyayîş (n) "
  },
  {
    "x": "wir-I (n)",
    "y": " fıtık"
  },
  {
    "x": "wir-II",
    "y": " b. her"
  },
  {
    "x": "wir çar",
    "y": " b. her çar"
  },
  {
    "x": "wir d’",
    "y": " b. her di "
  },
  {
    "x": "wir di",
    "y": " b. her di "
  },
  {
    "x": "wir din",
    "y": " b. her dîyan"
  },
  {
    "x": "wir dî",
    "y": " b. her di "
  },
  {
    "x": "wir dîn",
    "y": " b. her dîyan"
  },
  {
    "x": "wir dîna",
    "y": " b. her dîyan, her di"
  },
  {
    "x": "wir dînan",
    "y": " b. her dîyan, her di"
  },
  {
    "x": "wir dîni",
    "y": " b. her dîyan, her dîyine"
  },
  {
    "x": "wir dînî",
    "y": " b. her dîyan, her dîyine"
  },
  {
    "x": "wir go",
    "y": " b. her yew "
  },
  {
    "x": "wir gû",
    "y": " b. her yew "
  },
  {
    "x": "wir kes",
    "y": " b. her kes"
  },
  {
    "x": "wir ro",
    "y": " b. her roje"
  },
  {
    "x": "wir yew",
    "y": " b. her yew"
  },
  {
    "x": "wirak (n)",
    "y": " b. hurak (n)"
  },
  {
    "x": "wiraznayene",
    "y": " b. weriznayene"
  },
  {
    "x": "wiraznayîş (n)",
    "y": " b. weriznayîş (n)"
  },
  {
    "x": "wirdêk, -i",
    "y": " b. hurdî, -ye"
  },
  {
    "x": "werdêkek, -i",
    "y": " b. hurdî, -ye"
  },
  {
    "x": "wirdês (m)",
    "y": " b. herdose (m)"
  },
  {
    "x": "wirdêsi (m)",
    "y": " b. herdose (m)"
  },
  {
    "x": "wirdi (n)",
    "y": " b. hurdî (n)"
  },
  {
    "x": "wirdi kerdene",
    "y": " b. hurdî kerdene"
  },
  {
    "x": "wirdî belxulî (n)",
    "y": " b. hurdîyê belxurî (n), hûrikê belxurî (n)"
  },
  {
    "x": "wirdî nanî (n)",
    "y": " b. hurdîyê nanî (n)"
  },
  {
    "x": "wirdî nonî (n)",
    "y": " b. hurdîyê nanî (n)"
  },
  {
    "x": "wirdî nûnî (n)",
    "y": " b. hurdîyê nanî (n)"
  },
  {
    "x": "wirdi-wirdi",
    "y": " b. hurdî-hurdî"
  },
  {
    "x": "wirdi-wirdi varayene",
    "y": " hurdî-hurdî varayene"
  },
  {
    "x": "wirdi-wirdivarayîş (n)",
    "y": " b. hurdî-hurdîvarayîş (n)"
  },
  {
    "x": "wirdikerdiş (n)",
    "y": " b. hurdîkerdiş (n)"
  },
  {
    "x": "wirdî (m)",
    "y": " b. hurdîye (m)"
  },
  {
    "x": "wirdîk, -i",
    "y": " b. hurdî, -ye"
  },
  {
    "x": "wirdînî",
    "y": " b. her dîyan, her dîyine"
  },
  {
    "x": "wirdyes (m)",
    "y": " b. herdose (m)"
  },
  {
    "x": "wirêardi (n)",
    "y": " b. werêarde (n, sn)"
  },
  {
    "x": "wiriştene",
    "y": " b. weriştene"
  },
  {
    "x": "wiriştiş (n)",
    "y": " b. weriştiş (n)"
  },
  {
    "x": "wirîk!",
    "y": " gelmesi için keçiye seslenirken kullanılan sözcük"
  },
  {
    "x": "wirîyayîne",
    "y": " b. werîyayene"
  },
  {
    "x": "wirîyayîni",
    "y": " b. werîyayene"
  },
  {
    "x": "wirîyayîş (n)",
    "y": " b. werîyayîş (n)"
  },
  {
    "x": "wirna",
    "y": " b. her di, her dîyan, her dîyine"
  },
  {
    "x": "wirrîk!",
    "y": " b. wirîk!"
  },
  {
    "x": "wirte (n)",
    "y": " b. orte (n)"
  },
  {
    "x": "wirznayene",
    "y": " b. weriznayene"
  },
  {
    "x": "wirznayîş (n)",
    "y": " b. weriznayîş (n)"
  },
  {
    "x": "wisar (n)",
    "y": " bahar, ilkbahar, ilkyaz"
  },
  {
    "x": "wisarê",
    "y": " b. wisarî"
  },
  {
    "x": "wisarî",
    "y": " baharın"
  },
  {
    "x": "wiskira (m)",
    "y": " b. uskura (m)"
  },
  {
    "x": "wiskura (m)",
    "y": " b. uskura (m)"
  },
  {
    "x": "wista",
    "y": " b. westa, -ye"
  },
  {
    "x": "wişire (m)",
    "y": " b. uşîre (m)"
  },
  {
    "x": "wişîre (m)",
    "y": " b. uşîre (m)"
  },
  {
    "x": "wişk, -i",
    "y": " b. huşk, -e"
  },
  {
    "x": "wişk bîyene",
    "y": " b. huşk bîyene"
  },
  {
    "x": "wişk kerdene",
    "y": " b. huşk kerdene"
  },
  {
    "x": "wişk û biring, -e",
    "y": " b. huşk û biring, -e"
  },
  {
    "x": "wişk û wad, -i",
    "y": " b. huşk û wad, -e"
  },
  {
    "x": "wişk û war, -e",
    "y": " b. huşk û war, -e"
  },
  {
    "x": "wişk-biring, -i",
    "y": "  b. huşk-biring, -e"
  },
  {
    "x": "wişka (n)",
    "y": " ayaz"
  },
  {
    "x": "wişkayî (m)",
    "y": " b. huşkîye (m), huşkî (m)"
  },
  {
    "x": "wişkbîyayîş (n)",
    "y": " b. huşkbîyayîş (n)"
  },
  {
    "x": "wişkdês (n)",
    "y": " b. huşkdês (n)"
  },
  {
    "x": "wişkey (m)",
    "y": " b. huşkîye (m), huşkî (m)"
  },
  {
    "x": "wişkê (m)",
    "y": " b. huşkîye (m), huşkî (m)"
  },
  {
    "x": "wişkkerdiş (n)",
    "y": " b. huşkkerdiş (n)"
  },
  {
    "x": "wişna (m)",
    "y": " vişne"
  },
  {
    "x": "wişnayêre (m)",
    "y": " vişne ağacı"
  },
  {
    "x": "wit (n)",
    "y": " nem, rutubet, gönen"
  },
  {
    "x": "wit antene",
    "y": " nemlenmek, rutubetlenmek"
  },
  {
    "x": "wit girewtene",
    "y": " nemlenmek, rutubetlenmek"
  },
  {
    "x": "wita (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "wite-I (m)",
    "y": " yudum"
  },
  {
    "x": "wite-II (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "witene",
    "y": " b. wutene"
  },
  {
    "x": "witêl (m)",
    "y": " b. otêle"
  },
  {
    "x": "witêle (m)",
    "y": " b. otêle"
  },
  {
    "x": "witi (m)",
    "y": " b. wite-I (m)"
  },
  {
    "x": "witin, -e",
    "y": " nemli, rutubetli"
  },
  {
    "x": "witinîye (m)",
    "y": " nemlilik, rutubetlilik"
  },
  {
    "x": "witiş (n)",
    "y": " b. wutiş (n)"
  },
  {
    "x": "witîya (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "witpeym (n)",
    "y": " nemölçer, higrometre"
  },
  {
    "x": "witwit kerdene",
    "y": " söylenmek (kendi kendine konuşmak)"
  },
  {
    "x": "witwitîyayene",
    "y": " söylenmek (kendi kendine konuşmak)"
  },
  {
    "x": "witwitîyayîş (n)",
    "y": " söylenme (kendi kendine konuşma)"
  },
  {
    "x": "witwitkerdiş (n)",
    "y": " söylenme (kendi kendine konuşma)"
  },
  {
    "x": "witya (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "wiza (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "wî (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "wîcdan (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "wîcdon (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "wîcdûn (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "wîç",
    "y": " kuş sesi"
  },
  {
    "x": "wîç-wîç (n)",
    "y": "kuş sesi\t"
  },
  {
    "x": "wîçewîça xo bîyene",
    "y": " ötüp durmak"
  },
  {
    "x": "wîçnayene",
    "y": " ötmek (serçe vb. kuşlar için)"
  },
  {
    "x": "wîçnayîş (n)",
    "y": " ötme (serçe vb. kuşlar için)"
  },
  {
    "x": "wîçwîç kerdene",
    "y": " ötmek (serçe vb. kuşlar için)"
  },
  {
    "x": "wîçwîçkerdiş (n)",
    "y": " ötme (serçe vb. kuşlar için)"
  },
  {
    "x": "wîçwîçnayene",
    "y": " ötmek (serçe vb. kuşlar için)"
  },
  {
    "x": "wîçwîçnayîş (n)",
    "y": " ötme (serçe vb. kuşlar için)"
  },
  {
    "x": "wîjdan (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "wîjdon (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "wîjdûn (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "wîlayet (n)",
    "y": " il, vilayet"
  },
  {
    "x": "wîn, -e",
    "y": " b. bîn, -e"
  },
  {
    "x": "wînayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "Wîndhûk (n)",
    "y": " Windhoek"
  },
  {
    "x": "wîne (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "wîneyîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "wîr",
    "y": " b. her"
  },
  {
    "x": "wîr d’",
    "y": " b. her di"
  },
  {
    "x": "wîr dî",
    "y": " b. her di"
  },
  {
    "x": "wîr dîn",
    "y": " b. her dîyan"
  },
  {
    "x": "wîr dîyûn",
    "y": " b. her dîyan"
  },
  {
    "x": "wîskî (m)",
    "y": " viski"
  },
  {
    "x": "wîşna (m)",
    "y": " b. wişna (m)"
  },
  {
    "x": "wîşne (m)",
    "y": " b. wişna (m)"
  },
  {
    "x": "wîşneyêri (m)",
    "y": " b. wişnayêre (m)"
  },
  {
    "x": "wît",
    "y": " b. wîç"
  },
  {
    "x": "wît-wît",
    "y": " b. wîç-wîç (n)"
  },
  {
    "x": "wîta (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "wîte (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "wîtewîta xo bîyayene",
    "y": " ötüp durmak"
  },
  {
    "x": "wîtnayene",
    "y": " ötmek (serçe vb. kuşlar için)"
  },
  {
    "x": "wîtnayîş (n)",
    "y": " ötme (serçe vb. kuşlar için)"
  },
  {
    "x": "wîtwît kerdene",
    "y": " 1)ötmek (serçe vb. kuşlar için)\r\n~2)b. witwit kerdene"
  },
  {
    "x": "wîtwîtkerdiş (n)",
    "y": " ötme (serçe vb. kuşlar için)"
  },
  {
    "x": "wîtwîtnayene",
    "y": " ötmek (serçe vb. kuşlar için)"
  },
  {
    "x": "wîtwîtnayîş (n)",
    "y": " ötme (serçe vb. kuşlar için)"
  },
  {
    "x": "wîy (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "wîyatene",
    "y": " b. huyayene"
  },
  {
    "x": "wîyatiş (n)",
    "y": " b. huyayîş (n)"
  },
  {
    "x": "wîyayene",
    "y": " b. huyayene"
  },
  {
    "x": "wîyayîş (n)",
    "y": " b. huyayîş (n)"
  },
  {
    "x": "wîye (n)",
    "y": " b. huye-I (n) "
  },
  {
    "x": "wîyeyê vowri (n)",
    "y": " b. huyeyê vewre (n)"
  },
  {
    "x": "wîyê vor (n)",
    "y": " b. huyeyê vewre (n)"
  },
  {
    "x": "wîye kerdene",
    "y": " b. weyî kerdene"
  },
  {
    "x": "wîyekerdiş (n)",
    "y": " b. weyîkerdiş (n)"
  },
  {
    "x": "wîyeyîş (n)",
    "y": " b. huyayîş (n)"
  },
  {
    "x": "wîyi (n)",
    "y": " b. huye-I (n)"
  },
  {
    "x": "wîyi (n)",
    "y": " b. huye-II (n)"
  },
  {
    "x": "wîynayene",
    "y": " b. huynayene"
  },
  {
    "x": "wîynayîş (n)",
    "y": " b. huynayîş (n)"
  },
  {
    "x": "wo-I",
    "y": " b. yo-II"
  },
  {
    "x": "wo-II (n, z)",
    "y": " b. o-I (n, z)"
  },
  {
    "x": "wo-III (m)",
    "y": " b. waye (m)"
  },
  {
    "x": "wodet (n)",
    "y": " b. adet (n)"
  },
  {
    "x": "wole (m)",
    "y": " b. wele-I (m)"
  },
  {
    "x": "Wolî",
    "y": " b. Elî, Alî"
  },
  {
    "x": "womîya (m)",
    "y": " b. yewmîye (n)"
  },
  {
    "x": "womr (n)",
    "y": " b. emr-I (n)"
  },
  {
    "x": "wonca",
    "y": " b. ancîna"
  },
  {
    "x": "woncî",
    "y": " b. ancî"
  },
  {
    "x": "woncîa",
    "y": " b. ancî"
  },
  {
    "x": "woncîya",
    "y": " b. ancî"
  },
  {
    "x": "wondêr, -e",
    "y": " b. ondêr, -e"
  },
  {
    "x": "wonî",
    "y": " b. winî"
  },
  {
    "x": "woriştiş (n)",
    "y": " b. weriştiş (n)"
  },
  {
    "x": "worte (n)",
    "y": " b. orte (n)"
  },
  {
    "x": "wortî (zh)",
    "y": " b. ortî (zh)"
  },
  {
    "x": "worxan (n)",
    "y": " b. orxan (n)"
  },
  {
    "x": "wostor (n)",
    "y": " b. estor (n)"
  },
  {
    "x": "woxt (n)",
    "y": " b. wext (n)"
  },
  {
    "x": "woye (m)",
    "y": " b. waye (m)"
  },
  {
    "x": "woyîye (m)",
    "y": " b. wayîye (m)"
  },
  {
    "x": "wu-I (n, z)",
    "y": " b. o-I (n, z)"
  },
  {
    "x": "wu-II (n, s)",
    "y": " b. o-II (n, s)"
  },
  {
    "x": "wu- (pr)",
    "y": " 1)b. wi- (pr)-1\r\n~2)b. wi- (pr)-2"
  },
  {
    "x": "wuca (n)",
    "y": " ora, orası, orada"
  },
  {
    "x": "wuta (n)",
    "y": " bura, burası"
  },
  {
    "x": "wuar (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "wuare (n)",
    "y": " b. war-I (n)"
  },
  {
    "x": "wuca (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "wucax (n)",
    "y": " b. ocax (n)"
  },
  {
    "x": "wucaxkuar (n)",
    "y": " b. ocaxkor (n)"
  },
  {
    "x": "wucid (n)",
    "y": " b. wucud (n) "
  },
  {
    "x": "wucud (n)",
    "y": " beden, vücut"
  },
  {
    "x": "wucûd (n)",
    "y": " b. wucud (n) "
  },
  {
    "x": "wudarîyayene",
    "y": " b. wedarîyayene"
  },
  {
    "x": "wudarîyayîş (n)",
    "y": " b. wedarîyayîş (n)"
  },
  {
    "x": "wude (n)",
    "y": " b. weyra (n)"
  },
  {
    "x": "wuer (n)",
    "y": " b. werd (n)"
  },
  {
    "x": "wuhar (n)",
    "y": " b. wayîr (n)"
  },
  {
    "x": "wuharê (m)",
    "y": " b. wayîrîye (m)"
  },
  {
    "x": "wuhartî (m)",
    "y": " b. wayîrîye (m)"
  },
  {
    "x": "wui (n, z)",
    "y": " b. o-I (n, z)"
  },
  {
    "x": "wuiriştiş (n)",
    "y": " b. weriştiş (n)"
  },
  {
    "x": "wuit (m)",
    "y": " b. wite-I (m)"
  },
  {
    "x": "wuja (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "wujax (n)",
    "y": " b. ocax (n)"
  },
  {
    "x": "wujdan (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "wujdon (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "wujdûn (n)",
    "y": " b. wijdan (n)"
  },
  {
    "x": "wula",
    "y": " b. willayî"
  },
  {
    "x": "wulahî",
    "y": " b. willayî"
  },
  {
    "x": "wulat (n)",
    "y": " b. welat (n)"
  },
  {
    "x": "wulay",
    "y": " b. willayî"
  },
  {
    "x": "wulayî",
    "y": " b. willayî"
  },
  {
    "x": "wule",
    "y": " b. willayî"
  },
  {
    "x": "wuley",
    "y": " b. willayî"
  },
  {
    "x": "wulla",
    "y": " b. willayî"
  },
  {
    "x": "wullahî",
    "y": " b. willayî "
  },
  {
    "x": "wullay",
    "y": " b. willayî"
  },
  {
    "x": "wullayî",
    "y": " b. willayî"
  },
  {
    "x": "wulle",
    "y": " b. willayî"
  },
  {
    "x": "wulleh",
    "y": " b. willayî"
  },
  {
    "x": "wullehî",
    "y": " b. willayî"
  },
  {
    "x": "wullehîn",
    "y": " b. willayî"
  },
  {
    "x": "wulley",
    "y": " b. willayî"
  },
  {
    "x": "wullê",
    "y": " b. willayî"
  },
  {
    "x": "wuna",
    "y": " b. wina"
  },
  {
    "x": "wunaz (n)",
    "y": " b. hunaz, -e"
  },
  {
    "x": "wunazê (m)",
    "y": " b. hunazî (m)"
  },
  {
    "x": "wunca",
    "y": " b. ancîna"
  },
  {
    "x": "wuncilî (n)",
    "y": " b. anculî (n)"
  },
  {
    "x": "wuncîya",
    "y": " b. ancîna"
  },
  {
    "x": "wunî",
    "y": " b. winî"
  },
  {
    "x": "wunîyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "wuntene",
    "y": " b. antene"
  },
  {
    "x": "wunyayîş (n)",
    "y": " b. ewnîyayîş (n)"
  },
  {
    "x": "wur-I (n)",
    "y": " b. wir-I (n)"
  },
  {
    "x": "wur-II",
    "y": " b. her"
  },
  {
    "x": "wur çar",
    "y": " b. her çar"
  },
  {
    "x": "wur d’",
    "y": " b. her di"
  },
  {
    "x": "wur dê",
    "y": " b. her di"
  },
  {
    "x": "wur di",
    "y": " b. her di"
  },
  {
    "x": "wur dî",
    "y": " b. her di "
  },
  {
    "x": "wur dînî",
    "y": " b. her dîyan, her dîyine"
  },
  {
    "x": "wur duna",
    "y": " b. her dîyan, her di"
  },
  {
    "x": "wur go",
    "y": " b. her yew "
  },
  {
    "x": "wur gû",
    "y": " b. her yew "
  },
  {
    "x": "wur kes",
    "y": " b. her kes"
  },
  {
    "x": "wur ro",
    "y": " b. her roje"
  },
  {
    "x": "wur yew",
    "y": " b. her yew "
  },
  {
    "x": "wurag (n)",
    "y": " b. hurak (n)"
  },
  {
    "x": "wuraznayîş (n)",
    "y": " b. weriznayîş (n)"
  },
  {
    "x": "wurde",
    "y": " b. hurdî, -ye"
  },
  {
    "x": "wurde-wurde",
    "y": " b. hurdî-hurdî"
  },
  {
    "x": "wurdes (m)",
    "y": " b. herdose (m)"
  },
  {
    "x": "wurdêk, -i",
    "y": " b. hurdî, -ye"
  },
  {
    "x": "wurdês (m)",
    "y": " b. herdose (m)"
  },
  {
    "x": "wurdi",
    "y": " b. hurdî"
  },
  {
    "x": "wurdi kerdene",
    "y": " b. hurdî kerdene"
  },
  {
    "x": "wurdi-wurdi",
    "y": " b. hurdî-hurdî"
  },
  {
    "x": "wurdikerdiş (n)",
    "y": " b. hurdîkerdiş (n)"
  },
  {
    "x": "wurêarde (n)",
    "y": " b. werêarde (n, sn)"
  },
  {
    "x": "wurik (n)",
    "y": " b. hûrik (n)"
  },
  {
    "x": "wuriştene",
    "y": " b. weriştene"
  },
  {
    "x": "wuriştiş (n)",
    "y": " b. weriştiş (n)"
  },
  {
    "x": "wuriznayene",
    "y": " b. weriznayene"
  },
  {
    "x": "wuriznayîş (n)",
    "y": " b. weriznayîş (n)"
  },
  {
    "x": "wurmî",
    "y": " b. her di"
  },
  {
    "x": "wurna",
    "y": " b. her di, her dîyan, her dîyine"
  },
  {
    "x": "wuruştiş (n)",
    "y": " b. weriştiş (n)"
  },
  {
    "x": "wurznayene",
    "y": " b. weriznayene"
  },
  {
    "x": "wusar (n)",
    "y": " b. wisar (n)"
  },
  {
    "x": "wuska (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "wuskera (m)",
    "y": " b. uskura (m)"
  },
  {
    "x": "wusta",
    "y": " b. westa, -ye"
  },
  {
    "x": "wuşîre (m)",
    "y": " b. uşîre (m)"
  },
  {
    "x": "wuşk, -i",
    "y": " b. huşk, -e"
  },
  {
    "x": "wuşk bîyene",
    "y": " b. huşk bîyene"
  },
  {
    "x": "wuşk kerdene",
    "y": " b. huşk kerdene"
  },
  {
    "x": "wuşk-biring, -i",
    "y": "  b. huşk-biring, -e"
  },
  {
    "x": "wuşka (n)",
    "y": " b. wişka (n), huşka (n)"
  },
  {
    "x": "wuşkbîyayîş (n)",
    "y": " b. huşkbîyayîş (n)"
  },
  {
    "x": "wuşkey (m)",
    "y": " b. huşkîye (m), huşkî (m)"
  },
  {
    "x": "wuşkê (m)",
    "y": " b. huşkîye (m), huşkî (m)"
  },
  {
    "x": "wuşkkerdiş (n)",
    "y": " b. huşkkerdiş (n)"
  },
  {
    "x": "wuşna (m)",
    "y": " b. wişna (m)"
  },
  {
    "x": "wuşnayêre (m)",
    "y": " b. wişnayêre (m)"
  },
  {
    "x": "wut (n)",
    "y": " b. wit (n)"
  },
  {
    "x": "wuta (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "wutene",
    "y": " uyumak"
  },
  {
    "x": "wutin, -i",
    "y": " b. witin, -e"
  },
  {
    "x": "wutiney (m)",
    "y": " b. witinîye (m)"
  },
  {
    "x": "wutiş (n)",
    "y": " uyuma"
  },
  {
    "x": "wutîya (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "wuver (n)",
    "y": " b. wever (n)"
  },
  {
    "x": "wuwe-I (m)",
    "y": " b. awe-I (m)"
  },
  {
    "x": "wuwe-II (m)",
    "y": " b. waye (m)"
  },
  {
    "x": "wuyayene",
    "y": " b. huyayene"
  },
  {
    "x": "wuyayîs (n)",
    "y": " b. huyayîş (n)"
  },
  {
    "x": "wuyayîş (n)",
    "y": " b. huyayîş (n)"
  },
  {
    "x": "wuye-I (n)",
    "y": " b. huye-I (n)"
  },
  {
    "x": "wuye-II (n)",
    "y": " b. waye (m)"
  },
  {
    "x": "wuye kerdene",
    "y": " b. weyî kerdene"
  },
  {
    "x": "wuyekerdiş (n)",
    "y": " b. weyîkerdiş (n)"
  },
  {
    "x": "wuyi (n)",
    "y": " b. huye-I (n)"
  },
  {
    "x": "wuynayene",
    "y": " b. huynayene"
  },
  {
    "x": "wuynayîş (n)",
    "y": " b. huynayîş (n)"
  },
  {
    "x": "wuza (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "wuzax (n)",
    "y": " b. ocax (n)"
  },
  {
    "x": "wuzka (n)",
    "y": " b. uca (n)"
  },
  {
    "x": "wû-I (n, z)",
    "y": " b. o-I (n, z)"
  },
  {
    "x": "wû-II (n, s)",
    "y": " b. o-II (n, s)"
  },
  {
    "x": "wûncilî, -ye",
    "y": " b. anculî, -ye"
  },
  {
    "x": "XXX"
  },
  {
    "x": "xaç (n)",
    "y": " haç"
  },
  {
    "x": "xaç, -e",
    "y": " alnı akıtmalı hayvan"
  },
  {
    "x": "xaçepers (n)",
    "y": " bulmaca"
  },
  {
    "x": "xaçirgan (n)",
    "y": " sacayağı, sacayak"
  },
  {
    "x": "xaçîrgan (n)",
    "y": " b. xaçirgan (n)"
  },
  {
    "x": "xaçray (m)",
    "y": " b. xaçraye (m)"
  },
  {
    "x": "xaçraye (m)",
    "y": " patika"
  },
  {
    "x": "xafe (m)",
    "y": " sarık"
  },
  {
    "x": "xafi (m)",
    "y": " b. xafe (m)"
  },
  {
    "x": "xafil de",
    "y": " aniden, ansızın, birdenbire; ani"
  },
  {
    "x": "xafil de merdene",
    "y": " aniden ölmek"
  },
  {
    "x": "xafil di",
    "y": " b. xafil de"
  },
  {
    "x": "xafil di merdene",
    "y": " b. xafil de merdene"
  },
  {
    "x": "xafila",
    "y": " aniden, ansızın, birdenbire; ani"
  },
  {
    "x": "xafila merdene",
    "y": " aniden ölmek"
  },
  {
    "x": "xafile (m)",
    "y": " felaket, yıkım"
  },
  {
    "x": "xafili (m)",
    "y": " b. xafile (m)"
  },
  {
    "x": "xafla",
    "y": " b. xafila"
  },
  {
    "x": "xafla merdene",
    "y": " b. xafila merdene"
  },
  {
    "x": "xaflin de",
    "y": " b. xafil de"
  },
  {
    "x": "xaflin de merdene",
    "y": " b. xafil de merdene"
  },
  {
    "x": "xafte",
    "y": " b. xaftela"
  },
  {
    "x": "xaftela",
    "y": " aniden, ansızın, birdenbire; ani"
  },
  {
    "x": "xaftela merdene",
    "y": " aniden ölmek"
  },
  {
    "x": "xaftila",
    "y": " b. xaftela"
  },
  {
    "x": "xag, -e",
    "y": " ham (sebze ve meyve için), olgunlaşmamış (sebze ve meyve için)"
  },
  {
    "x": "xaj (n)",
    "y": " b. xêze (m)"
  },
  {
    "x": "xajnî (m)",
    "y": " b. xalcinîye (m), xalcinî (m)"
  },
  {
    "x": "xal-I (n)",
    "y": " dayı"
  },
  {
    "x": "xalo",
    "y": " dayı (hitap hali) "
  },
  {
    "x": "xal-II (m)",
    "y": " b. xale (m)"
  },
  {
    "x": "xalcen (m)",
    "y": " b. xalcinîye (m), xalcinî (m)"
  },
  {
    "x": "xalcenî (m)",
    "y": " b. xalcinîye (m), xalcinî (m)"
  },
  {
    "x": "xalcê (m) (hitap halî)",
    "y": " b. xalcinî (m)"
  },
  {
    "x": "xalcênî (m)",
    "y": " b. xalcinîye (m), xalcinî (m)"
  },
  {
    "x": "xalcênîye (m)",
    "y": " b. xalcinîye (m), xalcinî (m)"
  },
  {
    "x": "xalci (m)",
    "y": " b. xalcinîye (m), xalcinî (m)"
  },
  {
    "x": "xalcik (m)",
    "y": " b. xalcinîye (m), xalcinî (m)"
  },
  {
    "x": "xalciki (m)",
    "y": " b. xalcinîye (m), xalcinî (m)"
  },
  {
    "x": "xalcinî (m)",
    "y": " dayı karısı, yenge"
  },
  {
    "x": "xalcinîye (m)",
    "y": " dayı karısı, yenge"
  },
  {
    "x": "xalcînîye (m)",
    "y": " b. xalcinîye (m)"
  },
  {
    "x": "xalcunîye (m)",
    "y": " b. xalcinîye (m), xalcinî (m)"
  },
  {
    "x": "xale (m)",
    "y": " teyze"
  },
  {
    "x": "xalê",
    "y": " teyze (hitap hali) "
  },
  {
    "x": "xaleke (m)",
    "y": " b. xaloke (m)"
  },
  {
    "x": "xalekêna (m)",
    "y": " teyze kızı, teyzezade"
  },
  {
    "x": "xalet (m)",
    "y": " b. xalete (m)"
  },
  {
    "x": "xalete (m)",
    "y": " teyze"
  },
  {
    "x": "xaleza (n)",
    "y": " teyze oğlu, teyzezade"
  },
  {
    "x": "xalête (m)",
    "y": " b. xelate (m)"
  },
  {
    "x": "xali-I (m)",
    "y": " b. xale (m) "
  },
  {
    "x": "xali-II (n)",
    "y": " b. xalî (m)"
  },
  {
    "x": "xalike (m)",
    "y": " teyze"
  },
  {
    "x": "xalikê",
    "y": " teyze (hitap hali)"
  },
  {
    "x": "xalikeyna (m)",
    "y": " b. xalekêna (m) "
  },
  {
    "x": "xaliki (m)",
    "y": " b. xalike (m)"
  },
  {
    "x": "xalis, -e",
    "y": " 1)halis, has, arı (katışıksız), saf (nesne için) \r\n~2)salt, sırf, sadece"
  },
  {
    "x": "xalisey (m)",
    "y": " b. xalisîye (m)"
  },
  {
    "x": "xalisê (m)",
    "y": " b. xalisî (m)"
  },
  {
    "x": "xalisî (m)",
    "y": " saflık (nesne için)"
  },
  {
    "x": "xalisîye (m)",
    "y": " saflık (nesne için)"
  },
  {
    "x": "xalî-I (m)",
    "y": " halı"
  },
  {
    "x": "xalîya dêsî (m)",
    "y": " duvar halısı"
  },
  {
    "x": "xalîya dîwarî (m)",
    "y": " duvar halısı"
  },
  {
    "x": "xalî-II (n)",
    "y": " b. xalî (m)"
  },
  {
    "x": "xalî dîyêsî (n)",
    "y": " b. xalîya dêsî (m)"
  },
  {
    "x": "xalîyê dêsî (n)",
    "y": " b. xalîya dêsî (m)"
  },
  {
    "x": "xalîça (m)",
    "y": " küçük halı"
  },
  {
    "x": "xalîçe (n)",
    "y": " b. xalîça (m)"
  },
  {
    "x": "xalîkorsker, -e",
    "y": " halıcı"
  },
  {
    "x": "xalîroş, -e",
    "y": " halıcı"
  },
  {
    "x": "xalîtî (m)",
    "y": " yalnızlık"
  },
  {
    "x": "xalîviraştox, -e",
    "y": " halıcı"
  },
  {
    "x": "xalîye (m)",
    "y": " halı"
  },
  {
    "x": "xalîyey (m)",
    "y": " b. xalîyî (m)"
  },
  {
    "x": "xalîyê (m)",
    "y": " b. xalîyî (m)"
  },
  {
    "x": "xalîyî (m)",
    "y": " yalnızlık"
  },
  {
    "x": "xalîza (n)",
    "y": " dayı oğlu, dayızade"
  },
  {
    "x": "xaljen (m)",
    "y": " b. xalcinîye (m), xalcinî (m)"
  },
  {
    "x": "xalkeyna (m)",
    "y": " b. xalkêna (m)"
  },
  {
    "x": "xalkêna (m)",
    "y": " 1)dayı kızı, dayızade\r\n~2)b. xalekêna (m)"
  },
  {
    "x": "xalmaş, -e",
    "y": " b. xilmaş, -e"
  },
  {
    "x": "xalocinî (m)",
    "y": " b. xalcinîye (m), xalcinî (m)"
  },
  {
    "x": "xalofir (n)",
    "y": " uğurböceği"
  },
  {
    "x": "xalohemze (n)",
    "y": " uğurböceği"
  },
  {
    "x": "xalojine (m)",
    "y": " b. xalcinîye (m), xalcinî (m)"
  },
  {
    "x": "xaloke (m)",
    "y": " beştaş [oyunu]  "
  },
  {
    "x": "Xalpêt (n)",
    "y": " b. Xarpêt (n)"
  },
  {
    "x": "xalxalikî (zh)",
    "y": " beştaş [oyunu]"
  },
  {
    "x": "xalxalo (n)",
    "y": " beştaş [oyunu]"
  },
  {
    "x": "xalxalok (n)",
    "y": " uğurböceği"
  },
  {
    "x": "xalza (n)",
    "y": " 1)b. xaleza (n)\r\n~2)b. xalîza (n)"
  },
  {
    "x": "xam, -e",
    "y": " 1)ham, olgunlaşmamış, olmamış \r\n~2)yabancı, acemi\r\n~Nê cayî mi rê zaf xam î. (Bu yerler bana çok yabancı.) \r\n~3)çömez (mecazî)\r\n~ \r\n~4)acayip, garip, gariplik \r\n~5)alışılmadık, alışılmamış"
  },
  {
    "x": "xama (m)",
    "y": " genç kız"
  },
  {
    "x": "xamayî (m)",
    "y": " genç kızlık"
  },
  {
    "x": "xamazek (m)",
    "y": " b. xanzeke (m)"
  },
  {
    "x": "xamey (m)",
    "y": " b. xamîye (m)"
  },
  {
    "x": "xamezek (m)",
    "y": " b. xanzeke (m)"
  },
  {
    "x": "xamê (m)",
    "y": " b. xamî (m)"
  },
  {
    "x": "xamî (m)",
    "y": " acemilik"
  },
  {
    "x": "xamîye (m)",
    "y": " acemilik"
  },
  {
    "x": "xanceri (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xançerî (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xane (n)",
    "y": " hane"
  },
  {
    "x": "xanedan, -e",
    "y": " asil, soylu, asilzade"
  },
  {
    "x": "xanedanîye (m)",
    "y": " asilzadelik, soyluluk"
  },
  {
    "x": "xanime (m)",
    "y": " hanım, bayan"
  },
  {
    "x": "xanimi (m)",
    "y": " b. xanime (m)"
  },
  {
    "x": "xanzeke (m)",
    "y": " ebegümeci"
  },
  {
    "x": "xanzeki (m)",
    "y": " b. xanzeke (m)"
  },
  {
    "x": "xanzûl (n)",
    "y": " b. xizal-I (n)"
  },
  {
    "x": "xapanî (m)",
    "y": " b. qapan (n)"
  },
  {
    "x": "xapanos bîyene",
    "y": " afiş yutmak, yalana dolana kanmak"
  },
  {
    "x": "xape-I (m)",
    "y": " bilek\r\n~xapa harmî (m): el bileği\r\n~xapa linge (m): ayak bileği"
  },
  {
    "x": "xapa harmî (m)",
    "y": " el bileği"
  },
  {
    "x": "xapa linge (m)",
    "y": " ayak bileği"
  },
  {
    "x": "xape-II (m)",
    "y": " kısa urgan"
  },
  {
    "x": "xapa nîreyî (m)",
    "y": " saban zıvanasını boyunduruğa bağlamakta kulanılan kalın bağ"
  },
  {
    "x": "xapa nîrî (m)",
    "y": " b. xapa nîreyî"
  },
  {
    "x": "xapeynayene",
    "y": " b. xapênayene"
  },
  {
    "x": "xapeynayîş (n)",
    "y": " b. xapênayîş (n)"
  },
  {
    "x": "xapênayene",
    "y": " aldatmak, kandırmak, yanıltmak"
  },
  {
    "x": "xapênayîş (n)",
    "y": " aldatma, kandırma, yanıltma"
  },
  {
    "x": "xapênok (n)",
    "y": " b. xapxapik, -e"
  },
  {
    "x": "xapitene",
    "y": " aldatmak, kandırmak, yanıltmak"
  },
  {
    "x": "xapitiş (n)",
    "y": " aldatma, kandırma, yanıltma"
  },
  {
    "x": "xapînayîne",
    "y": " b. xapênayene"
  },
  {
    "x": "xapînayîş (n)",
    "y": " b. xapênayîş (n)"
  },
  {
    "x": "xapîyayene",
    "y": " afiş yutmak, , yalana dolana kanmak"
  },
  {
    "x": "xapnayene",
    "y": " b. xapênayene"
  },
  {
    "x": "xapnayêne",
    "y": " b. xapênayene"
  },
  {
    "x": "xapnayîş (n)",
    "y": " b. xapênayîş (n)"
  },
  {
    "x": "xapxapik, -e",
    "y": " bir kuş çeşidi"
  },
  {
    "x": "xar-I (n)",
    "y": " binek hayvanının koşması"
  },
  {
    "x": "xar dayene",
    "y": " binek hayvanına binmiş olarak onu koşturmak"
  },
  {
    "x": "xar-II (n)",
    "y": " b. gar (n)"
  },
  {
    "x": "xar-II (m)",
    "y": " b. xare (m)"
  },
  {
    "x": "xarcirax (n)",
    "y": " b. xercêrah (n)"
  },
  {
    "x": "xardayîş (n)",
    "y": " koşturma (hayvan için)"
  },
  {
    "x": "xardilobi (m)",
    "y": " b. gardirobe (m)"
  },
  {
    "x": "xardos (n)",
    "y": " eğe"
  },
  {
    "x": "xardos kerdene",
    "y": " eğelemek"
  },
  {
    "x": "xare (m)",
    "y": " bilye"
  },
  {
    "x": "xari (m)",
    "y": " b. xare (m)"
  },
  {
    "x": "xaricîya (m)",
    "y": " yabancı ülkeler"
  },
  {
    "x": "Xarpêt (n)",
    "y": " Elazığ "
  },
  {
    "x": "Xarpit (n)",
    "y": " b. Xarpêt (n)"
  },
  {
    "x": "Xarpîyet (n)",
    "y": " b. Xarpêt (n)"
  },
  {
    "x": "xart (n)",
    "y": " eğe"
  },
  {
    "x": "xart bîyayîne",
    "y": " b. xart bîyene"
  },
  {
    "x": "xart bîyene",
    "y": " bilenmek"
  },
  {
    "x": "xart kerdene",
    "y": " eğelemek"
  },
  {
    "x": "xartîg (n)",
    "y": " b. xartîk (n)"
  },
  {
    "x": "xartîg kerdene",
    "y": " b. xartîk kerdene"
  },
  {
    "x": "xartîk (n)",
    "y": " eğe"
  },
  {
    "x": "xartîk kerdene",
    "y": " eğelemek"
  },
  {
    "x": "Xertûm (n)",
    "y": " Hartum"
  },
  {
    "x": "xarz (n)",
    "y": " b. xerz (n)"
  },
  {
    "x": "xarze (m)",
    "y": " b. xerze (m)"
  },
  {
    "x": "xarzek (n)",
    "y": " xerzek (n)"
  },
  {
    "x": "xas, -e-I",
    "y": " 1)halis, has, arı (katışıksız), saf (nesne için)\r\n~2)özel, hususi"
  },
  {
    "x": "xas-II (n)",
    "y": " marul"
  },
  {
    "x": "xasek, -e",
    "y": " güzel"
  },
  {
    "x": "xasekey (m)",
    "y": " b. xasekîye (m)"
  },
  {
    "x": "xasekê (m)",
    "y": " b. xasekîye (m)"
  },
  {
    "x": "xaseki (m)",
    "y": " b. xaseke (m)"
  },
  {
    "x": "xasekî (m)",
    "y": " güzellik"
  },
  {
    "x": "xasekîye (m)",
    "y": " güzellik"
  },
  {
    "x": "xaseten",
    "y": " özellikle, başta"
  },
  {
    "x": "xasey (m)",
    "y": " b. xasîye (m)"
  },
  {
    "x": "xasê (m)",
    "y": " b. xasî (m)"
  },
  {
    "x": "xasir (n)",
    "y": " b. hesîre (m)"
  },
  {
    "x": "xasî-I (m)",
    "y": " saflık (nesne için)"
  },
  {
    "x": "xasî-II (zh)",
    "y": " b. xaşî (zh)"
  },
  {
    "x": "xasîye (m)",
    "y": " saflık (nesne için)"
  },
  {
    "x": "xasîyet (n)",
    "y": " özellik, hususiyet"
  },
  {
    "x": "xassîyet (n)",
    "y": " b. xasîyet (n)"
  },
  {
    "x": "xasûg, -e",
    "y": " b. xasûk, -e"
  },
  {
    "x": "xasûk, -e",
    "y": " kurnaz"
  },
  {
    "x": "xaş (zh)",
    "y": " b. xaşî (zh)"
  },
  {
    "x": "xaşa (m)",
    "y": " b. xaşîye (m)"
  },
  {
    "x": "xaşabî-I (m)",
    "y": " yontma kalemi"
  },
  {
    "x": "xaşabî-II (m)",
    "y": " b. xeşebî (m)"
  },
  {
    "x": "xaşelnayene",
    "y": " b. xaşênayene"
  },
  {
    "x": "xaşelnayîş (n)",
    "y": " b. xaşênayîş (n)"
  },
  {
    "x": "xaşelnîyayene",
    "y": " b. xaşênîyayene"
  },
  {
    "x": "xaşelnîyayîş (n)",
    "y": " b. xaşênîyayîş (n)"
  },
  {
    "x": "xaşenayene",
    "y": " b. xaşênayene"
  },
  {
    "x": "xaşenayîş (n)",
    "y": " b. xaşênayîş (n)"
  },
  {
    "x": "xaşenîyayene",
    "y": " b. xaşênîyayene"
  },
  {
    "x": "xaşenîyayîş (n)",
    "y": " b. xaşênîyayîş (n)"
  },
  {
    "x": "xaşeynayeni",
    "y": " b. xaşênayene"
  },
  {
    "x": "xaşeynayîş (n)",
    "y": " b. xaşênayîş (n)"
  },
  {
    "x": "xaşeynîyayeni",
    "y": " b. xaşênîyayene"
  },
  {
    "x": "xaşeynîyayîş (n)",
    "y": " b. xaşênîyayîş (n)"
  },
  {
    "x": "xaşênaye (n)",
    "y": " haşlama (yemeği)"
  },
  {
    "x": "xaşênayene",
    "y": " haşlamak"
  },
  {
    "x": "xaşênayîş (n)",
    "y": " haşlama"
  },
  {
    "x": "xaşênîyayene",
    "y": " haşlanmak"
  },
  {
    "x": "xaşênîyayîş (n)",
    "y": " haşlanma"
  },
  {
    "x": "xaşile (m)",
    "y": " bulgur ve undan yapılan, tereyağı ve ayran dökülerek yenen bir yemek"
  },
  {
    "x": "xaşî (zh)",
    "y": " kelle-paça"
  },
  {
    "x": "xaşîye (m)",
    "y": " harar"
  },
  {
    "x": "xaşîya vêrî (m)",
    "y": " göbek (şişkin karın)"
  },
  {
    "x": "xaşxaş (n)",
    "y": " haşhaş"
  },
  {
    "x": "xaşxaşika kutikan (m)",
    "y": " gelincik (bitkisi)"
  },
  {
    "x": "xatir (n)",
    "y": " hatır"
  },
  {
    "x": "Xatir bi to",
    "y": " Allah’a ısmarladık"
  },
  {
    "x": "Xatir be to",
    "y": " b. Xatir bi to."
  },
  {
    "x": "Xatir bê to",
    "y": " b. Xatir bi to."
  },
  {
    "x": "Xatir bu to",
    "y": " b. Xatir bi to."
  },
  {
    "x": "Xatir ib tu",
    "y": " b. Xatir bi to."
  },
  {
    "x": "Xatir ve to",
    "y": " b. Xatir bi to."
  },
  {
    "x": "Xatir ve tu",
    "y": " b. Xatir bi to."
  },
  {
    "x": "Xatirdê to",
    "y": " b. Xatir bi to."
  },
  {
    "x": "Xatirê to",
    "y": " b. Xatir bi to."
  },
  {
    "x": "Xatiri b’ tu",
    "y": " b. Xatir bi to."
  },
  {
    "x": "Xatirî ti",
    "y": " b. Xatir bi to."
  },
  {
    "x": "xauj, -e",
    "y": " b. xawiz, -e"
  },
  {
    "x": "xav (m)",
    "y": " b. xave (m)"
  },
  {
    "x": "xavi (m)",
    "y": " b. xave (m)"
  },
  {
    "x": "xave (m)",
    "y": " 1)kuşak\r\n~2)b. xafe (m)"
  },
  {
    "x": "xavik (n)",
    "y": " b. xavike (m)"
  },
  {
    "x": "xavike (m)",
    "y": " kaymak"
  },
  {
    "x": "xavîg, -e",
    "y": " b. xag, -e"
  },
  {
    "x": "xavlîye (m)",
    "y": " b. xewlîye (m)"
  },
  {
    "x": "xavuz, -e",
    "y": " b. xawiz, -e"
  },
  {
    "x": "xawij, -e",
    "y": " b. xawiz, -e"
  },
  {
    "x": "xawiz, -e",
    "y": " (sebze ve meyve için) ham, olgunlaşmamış, olmamış"
  },
  {
    "x": "xawlî (m)",
    "y": " b. xewlî (m)"
  },
  {
    "x": "xawlîye (m)",
    "y": " b. xewlîye (m)"
  },
  {
    "x": "xawuj, -e",
    "y": " b. xawiz, -e"
  },
  {
    "x": "xawuz, -i",
    "y": " b. xawiz, -e"
  },
  {
    "x": "xawyar (n)",
    "y": " balık yumurtası, havyar"
  },
  {
    "x": "xax, -e",
    "y": " b. xag, -e"
  },
  {
    "x": "xaxu (n)",
    "y": " b. qerqot (n)"
  },
  {
    "x": "xaxud, -e",
    "y": " b. xaxût, -e"
  },
  {
    "x": "xaxûd, -e",
    "y": " b. xaxût, -e"
  },
  {
    "x": "xaxût, -e",
    "y": " yıkılmak üzere olan"
  },
  {
    "x": "xaye (n)",
    "y": " amaç, erek, gaye"
  },
  {
    "x": "xaye kerdene",
    "y": " amaçlamak"
  },
  {
    "x": "xayeyê projeyî (n)",
    "y": " proje amacı"
  },
  {
    "x": "xayîle (n)",
    "y": " gaile"
  },
  {
    "x": "xayîn, -e",
    "y": " 1)hain\r\n~2)çıyan (mecazî), çıyan gibi"
  },
  {
    "x": "xayîn vejîyayene",
    "y": " hain çıkmak, hain olduğu anlaşılmak "
  },
  {
    "x": "xayînane",
    "y": " haince"
  },
  {
    "x": "xayîney (m)",
    "y": " b. xayînîye (m)"
  },
  {
    "x": "xayîney reydi",
    "y": " b. xayînî reyde"
  },
  {
    "x": "xayîney reyra",
    "y": " b. xayînî reyra"
  },
  {
    "x": "xayînê (m)",
    "y": " b. xayînî (m)"
  },
  {
    "x": "xayînênî (m)",
    "y": " hainlik, hıyanet, ihanet"
  },
  {
    "x": "xayîni (m)",
    "y": " b. xayîn, -e"
  },
  {
    "x": "xayînî (m)",
    "y": " hainlik, hıyanet, ihanet"
  },
  {
    "x": "xayînî kerdene",
    "y": " hainlik etmek, haince davranmak, hıyanet etmek, ihanet etmek"
  },
  {
    "x": "xayînî reyde",
    "y": " h hainlikle, hıyanetle, ihanetle"
  },
  {
    "x": "xayînî reyra",
    "y": " hainlikle, hıyanetle, ihanetle"
  },
  {
    "x": "xayînîkerdiş (n)",
    "y": " hainlik etmek, haince davranma, hıyanet etme, ihanet etme"
  },
  {
    "x": "xayînîye (m)",
    "y": " hainlik, hıyanet, ihanet"
  },
  {
    "x": "xayle (n)",
    "y": " b. xayîle (n)"
  },
  {
    "x": "xaylî (m)",
    "y": " b. xalî (m)"
  },
  {
    "x": "xayma (m)",
    "y": " b. xeyme (m)"
  },
  {
    "x": "xayme (m)",
    "y": " b. xeyme (m)"
  },
  {
    "x": "xaz",
    "y": " b. xêze (m)"
  },
  {
    "x": "xaz bîyayene",
    "y": " b. xaz bîyene"
  },
  {
    "x": "xaz bîyene",
    "y": " sıyrılmak (deri vb. için)"
  },
  {
    "x": "xaz kerdene",
    "y": " sıyırmak (deri vb. için)"
  },
  {
    "x": "xazkerdiş (n)",
    "y": " sıyırma (deri vb. için)"
  },
  {
    "x": "xazmik (n)",
    "y": " geniz"
  },
  {
    "x": "xebar (n)",
    "y": " b. xubar (n)"
  },
  {
    "x": "xebare (n)",
    "y": " b. xubar (n)"
  },
  {
    "x": "xebat (m)",
    "y": " b. xebate (m)"
  },
  {
    "x": "xebat (n)",
    "y": " b. xebate (m)"
  },
  {
    "x": "xebate (m)",
    "y": " 1)çalışma, mesai\r\n~2)iş\r\n~3)faaliyet"
  },
  {
    "x": "xebata bidestî (m)",
    "y": " manuel çalışma"
  },
  {
    "x": "xebata ceribîye (m)",
    "y": " deneysel çalışma"
  },
  {
    "x": "xebata manuele (m)",
    "y": " manuel çalışma"
  },
  {
    "x": "xebata otomatîke (m)",
    "y": " otomatik çalışma"
  },
  {
    "x": "xebata tecrubîye (m)",
    "y": " deneysel çalışma"
  },
  {
    "x": "xebata vêşî (m)",
    "y": " fazla mesai"
  },
  {
    "x": "xebatbar, -e",
    "y": " kullanışlı, ergonomik"
  },
  {
    "x": "xebati (m)",
    "y": " b. xebate (m)"
  },
  {
    "x": "xebatkar, -e",
    "y": " 1)işçi\r\n~2)personel"
  },
  {
    "x": "xebatkara/ê asayîşî",
    "y": " güvenlik personeli"
  },
  {
    "x": "xebatkara/ê pakîye",
    "y": " temizlik personeli"
  },
  {
    "x": "xebatkara/ê vardîya",
    "y": " vardiya personeli"
  },
  {
    "x": "xebatkarey (m)",
    "y": " b. xebatkarîye (m), xebatkarî (m)"
  },
  {
    "x": "xebatkarê (m)",
    "y": " b. xebatkarîye (m), xebatkarî (m)"
  },
  {
    "x": "xebatkarênî (m)",
    "y": " işçilik"
  },
  {
    "x": "xebatkarî (m)",
    "y": " işçilik"
  },
  {
    "x": "xebatkarîye (m)",
    "y": " işçilik"
  },
  {
    "x": "xebatker, -e",
    "y": " çalışkan"
  },
  {
    "x": "xeber (m)",
    "y": " xebere (m)"
  },
  {
    "x": "xeber dayene",
    "y": " b. xeberî dayene"
  },
  {
    "x": "xeberber, -e",
    "y": " haberci"
  },
  {
    "x": "xeberdar, -e",
    "y": " haberdar"
  },
  {
    "x": "xeberdar bîyene",
    "y": " haberdar olmak"
  },
  {
    "x": "xeberdar kerdene",
    "y": " haberdar etmek"
  },
  {
    "x": "xeberdari (m)",
    "y": " b. xeberdare (m)"
  },
  {
    "x": "xeberdarkerdiş (n)",
    "y": " ihbar, haberdar etme"
  },
  {
    "x": "xeberdayîş (n)",
    "y": " b. xeberîdayîş (n)"
  },
  {
    "x": "xebere (m)",
    "y": " 1)haber\r\n~xebera deqaya peyêne (m): son dakika haberi\r\n~2)söz \r\n~3)sövgü, küfür"
  },
  {
    "x": "xebera deqaya peyêne (m)",
    "y": " son dakika haberi"
  },
  {
    "x": "xebera gama peyêne (m)",
    "y": " son dakika haberi"
  },
  {
    "x": "xebera sansasyonele (m)",
    "y": " sansasyonel haber"
  },
  {
    "x": "xebera xo jû kerdene",
    "y": " b. xebera xo yew kerdene"
  },
  {
    "x": "xebera xo yew kerdene",
    "y": " ağız birliği etmek, söz birliği etmek"
  },
  {
    "x": "xebera xo zû kerdene",
    "y": " b. xebera xo yew kerdene"
  },
  {
    "x": "xeberî dayene",
    "y": " 1)konuşmak\r\n~2)sövmek, küfretmek"
  },
  {
    "x": "xeberi (m)",
    "y": " b. xebere (m)"
  },
  {
    "x": "xeberî (zh)",
    "y": " sövgü, küfür"
  },
  {
    "x": "xeberî dayene",
    "y": " sövmek, küfretmek\r\n~Xeberî mede! (Sövme!)"
  },
  {
    "x": "xeberî dayeni",
    "y": " b. xeberî dayene"
  },
  {
    "x": "xeberîdayîş (n)",
    "y": " 1)konuşma\r\n~2)sövme, küfretme"
  },
  {
    "x": "xeberîdayox, -e",
    "y": " konuşmacı"
  },
  {
    "x": "xeberyewî (m)",
    "y": " ağız birliği, söz birliği"
  },
  {
    "x": "xebetayîş (n)",
    "y": " b. xebitîyayîş (n)"
  },
  {
    "x": "xebetîyayene",
    "y": " b. xebitîyayene"
  },
  {
    "x": "xebetîyayîne",
    "y": " b. xebitîyayene"
  },
  {
    "x": "xebetîyayîş (n)",
    "y": " b. xebitîyayîş (n)"
  },
  {
    "x": "xebetnayene",
    "y": " b. xebitnayene"
  },
  {
    "x": "xebetnayîne",
    "y": " b. xebitnayene"
  },
  {
    "x": "xebetnayîş (n)",
    "y": " b. xebitnayîş (n)"
  },
  {
    "x": "xebitîyayene",
    "y": " çalışmak"
  },
  {
    "x": "xebitîyayîne",
    "y": " b. xebitîyayene"
  },
  {
    "x": "xebitîyayîş (n)",
    "y": " çalışma"
  },
  {
    "x": "xebitnayene",
    "y": " 1)çalıştırmak\r\n~2)işletmek\r\n~3)kullanmak"
  },
  {
    "x": "xebitnayîne",
    "y": " b. xebitnayene"
  },
  {
    "x": "xebitnayîş (n)",
    "y": " 1)çalıştırma, çalıştırış\r\n~2)işletme\r\n~3)kullanma"
  },
  {
    "x": "xebitnayîşê otobusan (n)",
    "y": " otobüs işletme"
  },
  {
    "x": "xebitnayox, -e",
    "y": " 1)çalıştıran\r\n~2)işleten\r\n~3)kullanıcı"
  },
  {
    "x": "xebitnîyayîş (n)",
    "y": " kullanım"
  },
  {
    "x": "xebityayîş (n)",
    "y": " b. xebitîyayîş (n)"
  },
  {
    "x": "xebîtîyayîş (n)",
    "y": " b. xebitîyayîş (n)"
  },
  {
    "x": "xebteyîne",
    "y": " b. xebitîyayene"
  },
  {
    "x": "xebteyîş (n)",
    "y": " b. xebitîyayîş (n)"
  },
  {
    "x": "xebtîyayîne",
    "y": " b. xebitîyayene"
  },
  {
    "x": "xebtîyayîş (n)",
    "y": " b. xebitîyayîş (n)"
  },
  {
    "x": "xebtnayîne",
    "y": " b. xebitnayene"
  },
  {
    "x": "xebtnayîş (n)",
    "y": " b. xebitnayîş (n)"
  },
  {
    "x": "xecelîyayene",
    "y": " b. xejilîyayene"
  },
  {
    "x": "xecelîyayîş (n)",
    "y": " b. xejilîyayîş (n)"
  },
  {
    "x": "xecilîyayene",
    "y": " b. xejilîyayene"
  },
  {
    "x": "xecilîyayîş (n)",
    "y": " b. xejilîyayîş (n)"
  },
  {
    "x": "xecît (m)",
    "y": " b. xejîte (m)"
  },
  {
    "x": "xecîti (m)",
    "y": " b. xejîte (m)"
  },
  {
    "x": "xedar, -e",
    "y": " 1)gaddar, kıyıcı, acımasız, merhametsiz\r\n~2)ölümcül"
  },
  {
    "x": "xedarane",
    "y": " acımasızca"
  },
  {
    "x": "xedeb (n)",
    "y": " 1)b. xedev (n)\r\n~2)b. xezeb (n)"
  },
  {
    "x": "xedema (m)",
    "y": " hademe, odacı"
  },
  {
    "x": "xedeme (n)",
    "y": " hademe, odacı"
  },
  {
    "x": "xedev-I (n)",
    "y": " 1)tipi, kar fırtınası\r\n~2)b. xezeb (n)"
  },
  {
    "x": "xedev, -e-II",
    "y": " b. xezeb, -e"
  },
  {
    "x": "xedew (n)",
    "y": " b. xedev (n)"
  },
  {
    "x": "xedir (n)",
    "y": " acımasızlık, kıygı, merhametsizlik, gadir, haksızlık"
  },
  {
    "x": "xedir kerdene",
    "y": " gadretmek, haksızlık etmek"
  },
  {
    "x": "xedirkerdox, -e",
    "y": " gaddar, kıyıcı, merhametsiz"
  },
  {
    "x": "xefitîyayîş (n)",
    "y": " b. xebitîyayîş (n)"
  },
  {
    "x": "xefîf, -e",
    "y": " hafif"
  },
  {
    "x": "xefîfek, -e",
    "y": " hafifçe"
  },
  {
    "x": "xefîya (m)",
    "y": " ajan, casus; hafiye, dedektif"
  },
  {
    "x": "xefîye (n)",
    "y": " ajan, casus; hafiye, dedektif"
  },
  {
    "x": "xefîyetî (m)",
    "y": " ajanlık, casusluk; hafiyelik, dedektiflik"
  },
  {
    "x": "xefîyeyî (m)",
    "y": " ajanlık, casusluk; hafiyelik, dedektiflik"
  },
  {
    "x": "xefle (n)",
    "y": " b. xelfe-I (n)"
  },
  {
    "x": "xefle dayene",
    "y": " b. xelfe dayene"
  },
  {
    "x": "xeftale",
    "y": " b. xaftela"
  },
  {
    "x": "xeftan (m)",
    "y": " b. xeftane (m)"
  },
  {
    "x": "xeftane (m)",
    "y": " kaftan"
  },
  {
    "x": "xeftani (m)",
    "y": " b. xeftane (m)"
  },
  {
    "x": "xefteyîne",
    "y": " b. xebitîyayene"
  },
  {
    "x": "xefteyîş (n)",
    "y": " b. xebitîyayîş (n)"
  },
  {
    "x": "xeftîyayîne",
    "y": " b. xebitîyayene"
  },
  {
    "x": "xeftîyayîş (n)",
    "y": " b. xebitîyayîş (n)"
  },
  {
    "x": "xeftnayîne",
    "y": " b. xebitnayene"
  },
  {
    "x": "xeftnayîş (n)",
    "y": " b. xebitnayîş (n)"
  },
  {
    "x": "xeftoni (m)",
    "y": " b. xeftane (m)"
  },
  {
    "x": "xeftûn (m)",
    "y": " b. xeftane (m)"
  },
  {
    "x": "xeftyayîş (n)",
    "y": " b. xebitîyayîş (n)"
  },
  {
    "x": "xejilîyayene",
    "y": " 1)oyalanmak\r\n~2)meşgul olnmak\r\n~3)eğlenmek"
  },
  {
    "x": "xejilîyayîş (n)",
    "y": " 1)oyalanma\r\n~2)meşgul olma\r\n~3)eğlenme"
  },
  {
    "x": "xejî",
    "y": " b. xwezî"
  },
  {
    "x": "xejît (m)",
    "y": " b. xejîte (m)"
  },
  {
    "x": "xejîte (m)",
    "y": " bağ kulübesi"
  },
  {
    "x": "xejîti (m)",
    "y": " b. xejîte (m)"
  },
  {
    "x": "xela-I (m)",
    "y": " kıtlık"
  },
  {
    "x": "xela-II (m)",
    "y": " b. xelate (m)"
  },
  {
    "x": "xelaqey (m)",
    "y": " b. xelayiqîye (m)"
  },
  {
    "x": "xelaqi (m)",
    "y": " b. xelayiqe (m)"
  },
  {
    "x": "xelaqi (m)",
    "y": " b. xelayiqe (m)"
  },
  {
    "x": "xelas (m)",
    "y": " b. xelase (m)"
  },
  {
    "x": "xelas bîyene",
    "y": " kurtulmak"
  },
  {
    "x": "xelasbîyayîş (n)",
    "y": " kurtulma"
  },
  {
    "x": "xelase (m)",
    "y": " kurtuluş\r\n~Eke o wina zulm biko, xelasa ey çin a. (Böyle zulüm yaparsa, kurtuluşu yok.)"
  },
  {
    "x": "xelasey (m)",
    "y": " b. xelasîye (m)"
  },
  {
    "x": "xelasê (m)",
    "y": " b. xelasî (m)"
  },
  {
    "x": "xelasi (m)",
    "y": " b. xelase (m)"
  },
  {
    "x": "xelasî (m)",
    "y": " kurtuluş\r\n~Ewro roja xelasî ya. (Bugün kurtuluş günüdür.)"
  },
  {
    "x": "xelasîye (m)",
    "y": " kurtuluş"
  },
  {
    "x": "xelat-I (n)",
    "y": " halat"
  },
  {
    "x": "xelat-II (m)",
    "y": " b. xelate (m)"
  },
  {
    "x": "Xelat (n)",
    "y": " Ahlat"
  },
  {
    "x": "xelate (m)",
    "y": " ödül, armağan"
  },
  {
    "x": "Xelata Federasyonê Nuştoxanê Sînema",
    "y": " Sinema Yazarları Federasyonu Ödülü"
  },
  {
    "x": "Xelata Palmîyeya Zerrêne (sn)",
    "y": " Altın Palmiye Ödülü"
  },
  {
    "x": "Xelata Pilingê Zerrênî (sn)",
    "y": " Altın Kaplan Ödülü"
  },
  {
    "x": "xelati (m)",
    "y": " b. xelate (m)"
  },
  {
    "x": "xelayiqe (m)",
    "y": " kadın hizmetçi; cariye, halayık"
  },
  {
    "x": "xelayiqey (m)",
    "y": " b. xelayiqîye (m)"
  },
  {
    "x": "xelayiqi (m)",
    "y": " b. xelayiqe (m)"
  },
  {
    "x": "xelayiqîye (m)",
    "y": " cariyelik, halayıklık"
  },
  {
    "x": "xele (n)",
    "y": " buğday"
  },
  {
    "x": "xelefe (m)",
    "y": " b. xîlafe (m)"
  },
  {
    "x": "xelefe vatene",
    "y": " b. xîlafe vatene"
  },
  {
    "x": "xelefevatiş (n)",
    "y": " b. xîlafevatiş (n)"
  },
  {
    "x": "xeleke-I (m)",
    "y": " halka"
  },
  {
    "x": "xeleka nîreyî (m)",
    "y": " saban zıvanası"
  },
  {
    "x": "xeleka nîrî (m)",
    "y": " b. xeleka nîreyî (m)"
  },
  {
    "x": "xeleka xelaskere (m)",
    "y": " cankurtaran simidi, can simidi"
  },
  {
    "x": "xeleke-II (n)",
    "y": " döven sürerken ürünü yememesi için öküzün ağzına takılan ağızlık"
  },
  {
    "x": "xeleq (n)",
    "y": " yaratma"
  },
  {
    "x": "xeleq kerdene",
    "y": " yaratmak"
  },
  {
    "x": "xeleqe-I (m)",
    "y": " b. xeleke (m)"
  },
  {
    "x": "xeleqe-II (n)",
    "y": " b. xeniqe (n)"
  },
  {
    "x": "xeleqkerdiş (n)",
    "y": " yaratma"
  },
  {
    "x": "xeleqnayene",
    "y": " yaratmak"
  },
  {
    "x": "xeleqnayîş (n)",
    "y": " yaratma"
  },
  {
    "x": "xelese (m)",
    "y": " b. xelase (m)"
  },
  {
    "x": "xelesîyayene",
    "y": " b. xelisîyayene"
  },
  {
    "x": "xelesîyayîş (n)",
    "y": " b. xelisîyayîş (n)"
  },
  {
    "x": "xelesîyene",
    "y": " b. xelisîyayene"
  },
  {
    "x": "xelesnayene",
    "y": " b. xelisnayene"
  },
  {
    "x": "xelesnayîş (n)",
    "y": " b. xelisnayîş (n)"
  },
  {
    "x": "xeleşayêne",
    "y": " b. xelisîyayene"
  },
  {
    "x": "xeleşîyayene",
    "y": " b. xelisîyayene"
  },
  {
    "x": "xeleşîyayêne",
    "y": " b. xelisîyayene"
  },
  {
    "x": "xeleşîyayîş (n)",
    "y": " b. xelisîyayîş (n)"
  },
  {
    "x": "xelet, -e",
    "y": " hatalı, yanlış"
  },
  {
    "x": "xeletey (m)",
    "y": " b. xeletî (m), xeletîye (m)"
  },
  {
    "x": "xeletê (m)",
    "y": " b. xeletî (m), xeletîye (m)"
  },
  {
    "x": "xeletênî (m)",
    "y": " hata, yanlışlık"
  },
  {
    "x": "xeletî (m)",
    "y": " hata, yanlışlık"
  },
  {
    "x": "xeletîye (m)",
    "y": " hata, yanlışlık"
  },
  {
    "x": "xeletnayene",
    "y": " çarpıtılmak (mecazî), çarpıtmak (mecazî)"
  },
  {
    "x": "xeletnayîş (n)",
    "y": " çarpıtma (mecazî)"
  },
  {
    "x": "xelê",
    "y": " b. xeylê"
  },
  {
    "x": "xelf (n)",
    "y": " b. xelfe (n)"
  },
  {
    "x": "xelf dayene",
    "y": " b. xelfe dayene"
  },
  {
    "x": "xelfe-I (n)",
    "y": " filiz, sürgün (bitki için)"
  },
  {
    "x": "xelfe dayene",
    "y": " filizlenmek, filiz vermek"
  },
  {
    "x": "xelfe-II (n)",
    "y": " b. qelfe-I (n)"
  },
  {
    "x": "xelfek (n)",
    "y": " filiz, sürgün (bitki için)"
  },
  {
    "x": "xelfek dayene",
    "y": " filizlenmek, filiz vermek"
  },
  {
    "x": "xelfete",
    "y": " b. xaftela"
  },
  {
    "x": "Xelfetî",
    "y": " Halfeti"
  },
  {
    "x": "xelfi",
    "y": " b. xafila"
  },
  {
    "x": "xelfi ra",
    "y": " b. xafila"
  },
  {
    "x": "xeli (n)",
    "y": " b. xele (n)"
  },
  {
    "x": "xeliqnayene",
    "y": " b. xeleqnayene"
  },
  {
    "x": "xeliqnayîş (n)",
    "y": " b. xeleqnayîş"
  },
  {
    "x": "xelisîyayene",
    "y": " 1)kurtulmak\r\n~2)doğurmak (insan için), doğum yapmak\r\n~3)bitmek"
  },
  {
    "x": "xelisîyayîş (n)",
    "y": " 1)kurtulma\r\n~2)doğurma (insan için)\r\n~3)bitme, bitiş"
  },
  {
    "x": "xelisnayene",
    "y": " 1)kurtarmak\r\n~2)bitirmek, tüketmek"
  },
  {
    "x": "xelisnayîş (n)",
    "y": " 1)kurtarma\r\n~2)bitirme, tüketme"
  },
  {
    "x": "xelisyayîş (n)",
    "y": " b. xelisîyayîş (n)"
  },
  {
    "x": "xelîfe (n)",
    "y": " b. xelfe (n)"
  },
  {
    "x": "xelîfe dayene",
    "y": " b. xelfe dayene"
  },
  {
    "x": "xelîyek",
    "y": " b. xeylêk"
  },
  {
    "x": "xelle (n)",
    "y": " b. xele (n)"
  },
  {
    "x": "xelli (n)",
    "y": " b. xele (n)"
  },
  {
    "x": "xelmas, -e",
    "y": " b. xilmaş, -e"
  },
  {
    "x": "xelmasîyê (m)",
    "y": " b. xilmaşîye (m)"
  },
  {
    "x": "xelmaş, -e",
    "y": " b. xilmaş, -e"
  },
  {
    "x": "xelmaşey (m)",
    "y": " b. xilmaşîye (m)"
  },
  {
    "x": "xelmaşê (m)",
    "y": " b. xilmaşî (m)"
  },
  {
    "x": "xelq (n)",
    "y": " b. xeleq (n)"
  },
  {
    "x": "xelq kerdene",
    "y": " b. xeleq kerdene"
  },
  {
    "x": "xelq bîyene",
    "y": " b. xerq bîyene"
  },
  {
    "x": "xelqbîyayîş (n)",
    "y": " b. xerqbîyayîş (n)"
  },
  {
    "x": "xelqkerdiş (n)",
    "y": " b. xeleqkerdiş (n)"
  },
  {
    "x": "xelsinayîş (n)",
    "y": " b. xelisnayîş (n)"
  },
  {
    "x": "xelta (m)",
    "y": " tasma (hayvan için)"
  },
  {
    "x": "xelfi ra",
    "y": " b. xafila"
  },
  {
    "x": "xem (n)",
    "y": " üzüntü, üzünç, gam"
  },
  {
    "x": "xem kerdene",
    "y": " gamlanmak, kederlenmek"
  },
  {
    "x": "xemdar, -e",
    "y": " gamlı"
  },
  {
    "x": "xeme (m)",
    "y": " b. xeyme (m)"
  },
  {
    "x": "xemel",
    "y": " b. xeml (n)"
  },
  {
    "x": "xemelaye (n)",
    "y": " b. xemilîyaye (n)"
  },
  {
    "x": "xemelayîye (m)",
    "y": " b. xemilîyayîye (m)"
  },
  {
    "x": "xemelîyaya (m)",
    "y": " b. xemilîyaya (m)"
  },
  {
    "x": "xemelîyaye (n)",
    "y": " b. xemilîyaye (n)"
  },
  {
    "x": "xemelîyayene",
    "y": " b. xemilîyayene"
  },
  {
    "x": "xemelîyayîş (n)",
    "y": " b. xemilîyayîş (n)"
  },
  {
    "x": "xemelîyayîye (m)",
    "y": " b. xemilîyayîye (m)"
  },
  {
    "x": "xemelnaya (m)",
    "y": " b. xemilnaya (m)"
  },
  {
    "x": "xemelnaye (n)",
    "y": " b. xemilnaye (n)"
  },
  {
    "x": "xemelnayene",
    "y": " b. xemilnayene"
  },
  {
    "x": "xemelnayî (m)",
    "y": " b. xemilnayîye (m)"
  },
  {
    "x": "xemelnayîş (n)",
    "y": " b. xemilnayîş (n)"
  },
  {
    "x": "xemelnayîye (m)",
    "y": " b. xemilnayîye (m)"
  },
  {
    "x": "xemelyaye (n)",
    "y": " b. xemilîyaye (n)"
  },
  {
    "x": "xemelyayene",
    "y": " b. xemilîyayene"
  },
  {
    "x": "xemelyayî (m)",
    "y": " b. xemilîyayîye (m)"
  },
  {
    "x": "xemelyayîş (n)",
    "y": " b. xemilîyayîş (n)"
  },
  {
    "x": "xemgîn, -e",
    "y": " üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü, üzgün, kederli, gamlı"
  },
  {
    "x": "xemgîn bîyene",
    "y": " gamlanmak, kederlenmek, üzgün olmak"
  },
  {
    "x": "xemgîn kerdene",
    "y": " üzmek"
  },
  {
    "x": "xemgînbîyayîş (n)",
    "y": " gamlanma"
  },
  {
    "x": "xemgîney (m)",
    "y": " b. xemgînî (m), xemgînîye (m)"
  },
  {
    "x": "xemgînê (m)",
    "y": " b. xemgînî (m), xemgînîye (m)"
  },
  {
    "x": "xemgînî (m)",
    "y": " üzgünlük, üzüntülülük"
  },
  {
    "x": "xemgînîye (m)",
    "y": " üzgünlük, üzüntülülük"
  },
  {
    "x": "xemgînkerdox, -e",
    "y": " üzüntülü (üzen durum veya olay), üzücü (durum veya olay)"
  },
  {
    "x": "xemil (n)",
    "y": " b. xeml (n)"
  },
  {
    "x": "xemilîyaya (m)",
    "y": " süslü"
  },
  {
    "x": "xemilîyaye (n)",
    "y": " süslü"
  },
  {
    "x": "xemilîyayene",
    "y": " süslenmek (başkası tarafından süslenmek)"
  },
  {
    "x": "xemilîyayîş (n)",
    "y": " süslenme (başkası tarafından süslenme)"
  },
  {
    "x": "xemilîyayîye (m)",
    "y": " süslü"
  },
  {
    "x": "xemilnaya (m)",
    "y": " süslü"
  },
  {
    "x": "xemilnaye (n)",
    "y": " süslü"
  },
  {
    "x": "xemilnayene",
    "y": " süslemek"
  },
  {
    "x": "xemilnayîş (n)",
    "y": " süsleme"
  },
  {
    "x": "xemilnayîye (m)",
    "y": " süslü"
  },
  {
    "x": "xemînaya (m)",
    "y": " bayıltılmış olan"
  },
  {
    "x": "xemînaye (n)",
    "y": " bayıltılmış olan"
  },
  {
    "x": "xemînayene",
    "y": " bayıltmak"
  },
  {
    "x": "xemînayîş (n)",
    "y": " bayıltma"
  },
  {
    "x": "xemîyaya (m)",
    "y": " baygın"
  },
  {
    "x": "xemîyaye (n)",
    "y": " baygın"
  },
  {
    "x": "xemîyayene",
    "y": " bayılmak"
  },
  {
    "x": "xemîyayîş (n)",
    "y": " bayılma"
  },
  {
    "x": "xeml (n)",
    "y": " süs"
  },
  {
    "x": "xemleyîş (n)",
    "y": " b. xemilîyayîş (n)"
  },
  {
    "x": "xemlûxêz (n)",
    "y": " süs püs"
  },
  {
    "x": "xemlûxêzin, -e",
    "y": " süslü püslü"
  },
  {
    "x": "xemnak, -e",
    "y": " üzüntülü (üzüntüsü olan, üzülen kişi), üzünçlü, gamlı"
  },
  {
    "x": "xemnakey (m)",
    "y": " b. xemnakî (m), xemnakîye (m)"
  },
  {
    "x": "xemnakê (m)",
    "y": " b. xemnakî (m), xemnakîye (m)"
  },
  {
    "x": "xemnakî (m)",
    "y": " üzüntülülük"
  },
  {
    "x": "xemnakîye (m)",
    "y": " üzüntülülük"
  },
  {
    "x": "xemûm (n)",
    "y": " b. xumam (n)"
  },
  {
    "x": "xenaqîyayene",
    "y": " b. xeniqîyayene"
  },
  {
    "x": "xenaqîyayîş (n)",
    "y": " b. xeniqîyayîş (n)"
  },
  {
    "x": "xenaqnayîne",
    "y": " b. xeniqnayene"
  },
  {
    "x": "xenaqnayîş (n)",
    "y": " b. xeniqnayîş (n)"
  },
  {
    "x": "xencer (n)",
    "y": " hançer"
  },
  {
    "x": "xencere (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xencîr (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xençer (n)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xençere (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xençeri (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xençerî (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xençêrî (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xendeknayene",
    "y": " b. xeniqnayene"
  },
  {
    "x": "xendeknayîs (n)",
    "y": " b. xeniqnayîş (n)"
  },
  {
    "x": "xendeqîyayene",
    "y": " b. xeniqîyayene"
  },
  {
    "x": "xendeqîyayîş (n)",
    "y": " b. xeniqîyayîş (n)"
  },
  {
    "x": "xendeqîyene",
    "y": " b. xeniqîyayene"
  },
  {
    "x": "xendeqnayene",
    "y": " b. xeniqnayene"
  },
  {
    "x": "xendeqnayîş (n)",
    "y": " b. xeniqnayîş (n)"
  },
  {
    "x": "xeneçîyayene",
    "y": " b. xeniqîyayene"
  },
  {
    "x": "xeneçîyayîş (n)",
    "y": " b. xeniqîyayîş (n)"
  },
  {
    "x": "xeneçnayene",
    "y": " b. xeniqnayene"
  },
  {
    "x": "xeneçnayîş (n)",
    "y": " b. xeniqnayîş (n)"
  },
  {
    "x": "xenekîyayene",
    "y": " b. xeniqîyayene"
  },
  {
    "x": "xenekîyayîne",
    "y": " b. xeniqîyayene"
  },
  {
    "x": "xenekîyayîş (n)",
    "y": " b. xeniqîyayîş (n)"
  },
  {
    "x": "xeneknayene",
    "y": " b. xeniqnayene"
  },
  {
    "x": "xeneknayîs (n)",
    "y": " b. xeniqnayîş (n) "
  },
  {
    "x": "xeneknayîş (n)",
    "y": " b. xeniqnayîş (n)"
  },
  {
    "x": "xeneqayîne",
    "y": " b. xeniqîyayene"
  },
  {
    "x": "xeneqayîş (n)",
    "y": " b. xeniqîyayîş (n)"
  },
  {
    "x": "xeneqîyayene",
    "y": " b. xeniqîyayene"
  },
  {
    "x": "xeneqîyayîne",
    "y": " b. xeniqîyayene"
  },
  {
    "x": "xeneqîyayîş (n)",
    "y": " b. xeniqîyayîş (n)"
  },
  {
    "x": "xeneqnayene",
    "y": " b. xeniqnayene"
  },
  {
    "x": "xeneqnayîne",
    "y": " b. xeniqnayene"
  },
  {
    "x": "xeneqnayîni",
    "y": " b. xeniqnayene"
  },
  {
    "x": "xeneqnayîş (n)",
    "y": " b. xeniqnayîş (n) "
  },
  {
    "x": "xeniqe (n)",
    "y": " boyunduruk çubuklarını birbirine bağlayan ip "
  },
  {
    "x": "xeniqeyîne",
    "y": " b. xeniqîyayene "
  },
  {
    "x": "xeniqeyîş (n)",
    "y": " b. xeniqîyayîş (n)"
  },
  {
    "x": "xeniqîyayene",
    "y": " 1)boğulmak\r\n~2)asılmak (idam edilmek)"
  },
  {
    "x": "xeniqîyayîş (n)",
    "y": " 1)boğulma\r\n~2)asılma (idam edilme)"
  },
  {
    "x": "xeniqnaya (m)",
    "y": " asılı"
  },
  {
    "x": "xeniqnaye (n)",
    "y": " asılı"
  },
  {
    "x": "xeniqnayene",
    "y": " 1)boğmak (suda vb.)\r\n~2)asmak (idam etmek)"
  },
  {
    "x": "xeniqnayîş (n)",
    "y": " 1)boğma (suda vb.)\r\n~2)asma (idam etme)"
  },
  {
    "x": "xeniqnayîye (m)",
    "y": " asılı"
  },
  {
    "x": "xeniqnayox, -e",
    "y": " boğucu"
  },
  {
    "x": "xeniqyayîş (n)",
    "y": " b. xeniqîyayîş (n)"
  },
  {
    "x": "xenqe (n)",
    "y": " b. xeniqe (n)"
  },
  {
    "x": "xenzîr, -e",
    "y": " domuz"
  },
  {
    "x": "xenzîri (m)",
    "y": " b. xenzîre (m)"
  },
  {
    "x": "xepînayîş (n)",
    "y": " b. xapênayîş (n)"
  },
  {
    "x": "xerab, -e",
    "y": " b. xirab, -e"
  },
  {
    "x": "xerabîyayene",
    "y": " b. xeripîyayene"
  },
  {
    "x": "xerabîyayîş (n)",
    "y": " b. xeripîyayîş (n)"
  },
  {
    "x": "xerabnayene",
    "y": " b. xeripnayene"
  },
  {
    "x": "xerabnayîş (n)",
    "y": " b. xeripnayîş (n)"
  },
  {
    "x": "xerac (n)",
    "y": " b. garaj (n)"
  },
  {
    "x": "xeraj (n)",
    "y": " b. garaj (n)"
  },
  {
    "x": "xerar (m)",
    "y": " b. xirare (m)"
  },
  {
    "x": "xerare (m)",
    "y": " b. xirare (m) "
  },
  {
    "x": "xerbelek, -e",
    "y": " b. qerbelek, -e"
  },
  {
    "x": "xerc-I (n)",
    "y": " işleme, elbisedeki sırmalı nakış"
  },
  {
    "x": "xerc kerdene",
    "y": " işlemek"
  },
  {
    "x": "xerc-II (n)",
    "y": " harç, (resmi ödemelerde) harç"
  },
  {
    "x": "xerc bîyene",
    "y": " harcanmak"
  },
  {
    "x": "xerc kerdene",
    "y": " harcamak"
  },
  {
    "x": "xercbîyayîş (n)",
    "y": " harcanma"
  },
  {
    "x": "xercêrah (n)",
    "y": " harcırah, yolluk (yol parası)"
  },
  {
    "x": "xercêrahê xebatkaran (n)",
    "y": " personel yolluğu"
  },
  {
    "x": "xercêraye (n)",
    "y": " harcırah, yolluk (yol parası)"
  },
  {
    "x": "xercêrayeyê xebatkaran (n)",
    "y": " personel yolluğu"
  },
  {
    "x": "xercilîyayene",
    "y": " b. xejilîyayene"
  },
  {
    "x": "xercilîyayîş (n)",
    "y": " b. xejilîyayîş (n)"
  },
  {
    "x": "xercin, -e",
    "y": " işlemeli"
  },
  {
    "x": "xercirah (n)",
    "y": " b. xercêrah (n)"
  },
  {
    "x": "xercirahi (m)",
    "y": " b. xercêrah (n)"
  },
  {
    "x": "xercirax (n)",
    "y": " b. xercêrah (n)"
  },
  {
    "x": "xercî",
    "y": " b. xeyrja"
  },
  {
    "x": "xercît (n)",
    "y": " yeni doğan oğlak, kuzu veya buzağının içine konduğu özel bölme"
  },
  {
    "x": "xerckerda (m)",
    "y": " işlemeli"
  },
  {
    "x": "xerckerde (n)",
    "y": " işlemeli"
  },
  {
    "x": "xerckerdiş-I (n)",
    "y": " işleme (işlemek eylemi)"
  },
  {
    "x": "xerckerdiş-II (n)",
    "y": " harcama"
  },
  {
    "x": "xerckerdîye (m)",
    "y": " işlemeli"
  },
  {
    "x": "xerdal, -e",
    "y": " b. xerdel, -e"
  },
  {
    "x": "xerdalin",
    "y": " b. xerdel, -e, xerdelin, -e"
  },
  {
    "x": "xerdel, -e",
    "y": " alaca (siyah-beyaz renkli)"
  },
  {
    "x": "xerdelin, -e",
    "y": " alaca (siyah-beyaz renkli)"
  },
  {
    "x": "xerefîyane",
    "y": " b. xerifîyane"
  },
  {
    "x": "xerefîyaya (m)",
    "y": " b. xerifîyaya (m)"
  },
  {
    "x": "xerefîyaye (n)",
    "y": " b. xerifîyaye (n)"
  },
  {
    "x": "xerefîyayene",
    "y": " b. xerifîyayene"
  },
  {
    "x": "xerefîyayî, -ye",
    "y": " b. xerifîyayî, -ye"
  },
  {
    "x": "xerefîyayîş (n)",
    "y": " b. xerifîyayîş (n)"
  },
  {
    "x": "xerefîyîye (m)",
    "y": " b. xerifîyîye (m)"
  },
  {
    "x": "xerefkî",
    "y": " b. xerifkî"
  },
  {
    "x": "xerepîyaya (m)",
    "y": " b. xeripîyaya (m)"
  },
  {
    "x": "xerepîyaye (n)",
    "y": " b. xeripîyaye (n)"
  },
  {
    "x": "xerepîyayene",
    "y": " b. xeripîyayene"
  },
  {
    "x": "xerepîyayey (m)",
    "y": " b. xeripîyayeyî (m)"
  },
  {
    "x": "xerepîyayeyî (m)",
    "y": " b. xeripîyayeyî (m)"
  },
  {
    "x": "xerepîyayê (m)",
    "y": " b. xeripîyayî, -ye"
  },
  {
    "x": "xerepîyayênî (m)",
    "y": " b. xeripîyayênî (m)"
  },
  {
    "x": "xerepîyayi (n)",
    "y": " b. xeripîyaye (n)"
  },
  {
    "x": "xerepîyayî, -ye",
    "y": " b. xeripîyayî, -ye"
  },
  {
    "x": "xerepîyayîş (n)",
    "y": " b. xeripîyayîş (n)"
  },
  {
    "x": "xerepnayene",
    "y": " b. xeripnayene"
  },
  {
    "x": "xerepnayîş (n)",
    "y": " b. xeripnayîş (n)"
  },
  {
    "x": "rez xerepnayîş  (n)",
    "y": " b. rez xeripnayîş (n)"
  },
  {
    "x": "xereq (n)",
    "y": " b. xeriq (n)"
  },
  {
    "x": "xereqîyaya (m)",
    "y": " b. xeriqîyaya (m), xeriqîyayîye (m)"
  },
  {
    "x": "xereqîyaye (n)",
    "y": " b. xeriqîyaye (n)"
  },
  {
    "x": "xereqîyayene",
    "y": " b. xeriqîyayene"
  },
  {
    "x": "xereqîyayîş (n)",
    "y": " b. xeriqîyayîş (n)"
  },
  {
    "x": "xereqîyayîye (m)",
    "y": " b. xeriqîyayîye (m)"
  },
  {
    "x": "xereqnaya (m)",
    "y": " b. xeriqnaya (m), xeriqnayîye (m)"
  },
  {
    "x": "xereqnaye (n)",
    "y": " b. xeriqnaye (n)"
  },
  {
    "x": "xereqnayene",
    "y": " b. xeriqnayene "
  },
  {
    "x": "xereqnayîş (n)",
    "y": " b. xeriqnayîş (n)"
  },
  {
    "x": "xereqnayîye (m)",
    "y": " b. xeriqnayîye (m)"
  },
  {
    "x": "xereqyayîş (n)",
    "y": " b. xeriqîyayîş (n)"
  },
  {
    "x": "xeretnayîş (n)",
    "y": " b. xeripnayîş (n)"
  },
  {
    "x": "rez xeretnayîş (n)",
    "y": " b. rez xeripnayîş (n)"
  },
  {
    "x": "xerez (n)",
    "y": " garaz, garez, kin"
  },
  {
    "x": "xerifîtîye (m)",
    "y": " bunaklık"
  },
  {
    "x": "xerifîyane",
    "y": " bunakça"
  },
  {
    "x": "xerifîyaya (m)",
    "y": " bunak, matuh"
  },
  {
    "x": "xerifîyaye (n)",
    "y": " bunak, matuh "
  },
  {
    "x": "xerifîyayene",
    "y": " bunamak, ateh getirmek "
  },
  {
    "x": "xerifîyayî, -ye",
    "y": " bunak, matuh"
  },
  {
    "x": "xerifîyayîş (n)",
    "y": " bunama, bunayış, ateh "
  },
  {
    "x": "xerifîyîye (m)",
    "y": " bunaklık "
  },
  {
    "x": "xerifkî",
    "y": " bunakça"
  },
  {
    "x": "xeripîyaya (m)",
    "y": " bozuk, bozulmuş olan; çürük"
  },
  {
    "x": "xeripîyaye (n)",
    "y": " bozuk, bozulmuş olan, çürük"
  },
  {
    "x": "xeripîyayene",
    "y": " 1)bozulmak\r\n~2)çürümek (yumurta vb. için)\r\n~3)başkalaşmak (mecazi)"
  },
  {
    "x": "xeripîyayey (m)",
    "y": " b. xeripîyayeyî (m)"
  },
  {
    "x": "xeripîyayeyî (m)",
    "y": " çürüklük, bozukluk"
  },
  {
    "x": "xeripîyayeyîye (m)",
    "y": " çürüklük, bozukluk"
  },
  {
    "x": "xeripîyayênî (m)",
    "y": " çürüklük"
  },
  {
    "x": "xeripîyayî, -ye",
    "y": " bozuk, çürük"
  },
  {
    "x": "xeripîyayîne",
    "y": " b. xeripîyayene"
  },
  {
    "x": "xeripîyayîş (n)",
    "y": " 1)bozulma\r\n~2)çürüme\r\n~3)başkalaşma (mecazi)\r\n~4)b. xerifîyayîş (n)"
  },
  {
    "x": "xeripnayene",
    "y": " 1)bozmak, yıkmak\r\n~2)çürütmek\r\n~3)meyve veya sebze bahçesinin son ürününü de toplayarak ürünsüz bırakmak\r\n~4)(namus) kirletmek, tecavüz etmek\r\n~5)(mecazi) soyup soğana çevirmek"
  },
  {
    "x": "rez xeripnayene",
    "y": " bağ bozmak"
  },
  {
    "x": "xeripnayîne",
    "y": " b. xeripnayene"
  },
  {
    "x": "xeripnayîş (n)",
    "y": " 1)bozma, yıkma, yıkım\r\n~2)çürütme\r\n~3)meyve veya sebze bahçesinin son ürününü de toplayarak ürünsüz bırakma\r\n~4)(namus) kirletme, tecavüz etme\r\n~5)(mecazi) soyup soğana çevirme"
  },
  {
    "x": "rez xeripnayîş (n)",
    "y": " bağ bozma"
  },
  {
    "x": "xeripnayox, -e",
    "y": " kirletici (namus vb. için)"
  },
  {
    "x": "xeriq (n)",
    "y": " bayılma"
  },
  {
    "x": "xeriqeyîş (n)",
    "y": " b. xeriqîyayîş (n)"
  },
  {
    "x": "xeriqîya (m)",
    "y": " b. xeriqîyaya (m), xeriqîyayîye (m)"
  },
  {
    "x": "xeriqîyaya (m)",
    "y": " baygın"
  },
  {
    "x": "xeriqîyaye (n)",
    "y": " baygın"
  },
  {
    "x": "xeriqîyayene",
    "y": " bayılmak"
  },
  {
    "x": "xeriqîyayîş (n)",
    "y": " bayılma"
  },
  {
    "x": "xeriqîyayîye (m)",
    "y": " baygın"
  },
  {
    "x": "xeriqîye (n)",
    "y": " b. xeriqîyaye (n)"
  },
  {
    "x": "xeriqnaya (m)",
    "y": " bayıltılmış olan"
  },
  {
    "x": "xeriqnaye (n)",
    "y": " bayıltılmış olan"
  },
  {
    "x": "xeriqnayene",
    "y": " bayıltmak"
  },
  {
    "x": "xeriqnayîş (n)",
    "y": " bayıltma"
  },
  {
    "x": "xeriqnayîye (m)",
    "y": " bayıltılmış olan"
  },
  {
    "x": "xeritnayîş (n)",
    "y": " b. xeripnayîş (n)"
  },
  {
    "x": "rez xeritnayîş (n)",
    "y": " b. rez xeripnayîş (n)"
  },
  {
    "x": "xerîb, -e",
    "y": " 1)yabancı, bigâne\r\n~2)gariban\r\n~No xort xerîb o. (Bu delikanlı yabancıdır.)"
  },
  {
    "x": "xerîbey (m)",
    "y": " b. xerîbîye (m)"
  },
  {
    "x": "xerîbey kerdene",
    "y": " b. xerîbîye kerdene"
  },
  {
    "x": "xerîbeykerdiş (n)",
    "y": " b. xerîbîyekerdiş (n)"
  },
  {
    "x": "xerîbê (m)",
    "y": " b. xerîbî (m)"
  },
  {
    "x": "xerîbê kerdene",
    "y": " b. xerîbî kerdene, xerîbîye kerdene"
  },
  {
    "x": "xerîbêkerdiş (n)",
    "y": " b. xerîbîkerdiş (n), xerîbîyekerdiş (n)"
  },
  {
    "x": "xerîbi (m)",
    "y": " b. xerîb, -e"
  },
  {
    "x": "xerîbî-I (m)",
    "y": " gariplik"
  },
  {
    "x": "xerîbî kerdene",
    "y": " 1)yabancılık çekmek\r\n~2)hasretini çekmek, özlemini çekmek, garipsemek"
  },
  {
    "x": "xerîbî-II (zh)",
    "y": " ağyar"
  },
  {
    "x": "xerîbîkerdiş (n)",
    "y": " 1)yabancılık çekme\r\n~2)hasretini çekme, özlemini çekme, garipseme"
  },
  {
    "x": "xerîbîye (m)",
    "y": " gariplik"
  },
  {
    "x": "xerîbîye kerdene",
    "y": " 1)yabancılık çekmek\r\n~2)hasretini çekmek, özlemini çekmek"
  },
  {
    "x": "xerîbîyekerdiş (n)",
    "y": " 1)yabancılık çekme\r\n~2)hasretini çekme, özlemini çekme"
  },
  {
    "x": "xerîta (m)",
    "y": " harita"
  },
  {
    "x": "xerîtaya kadastroyî (m)",
    "y": " kadastro haritası"
  },
  {
    "x": "xerîtaya rewşa nikayine (m)",
    "y": " halihazır harita"
  },
  {
    "x": "xerîtaya wayîrîye (m)",
    "y": " mülkiyet haritası"
  },
  {
    "x": "xerîtakêş, -e",
    "y": " haritacı"
  },
  {
    "x": "xerîv",
    "y": " b. xerîb, -e"
  },
  {
    "x": "xerîw",
    "y": " b. xerîb, -e"
  },
  {
    "x": "xerj (n)",
    "y": " b. xerc (n)"
  },
  {
    "x": "xerj bîyayene",
    "y": " b. xerc bîyene"
  },
  {
    "x": "xerj kerdene",
    "y": " b. xerc kerdene"
  },
  {
    "x": "xerjbîyayîş (n)",
    "y": " b. xercbîyayîş (n)"
  },
  {
    "x": "xerjê",
    "y": " b. xeyrja"
  },
  {
    "x": "xerjin, -i",
    "y": " b. xercin, -e "
  },
  {
    "x": "xerjkerdiş (n)",
    "y": " b. xerckerdiş (n)"
  },
  {
    "x": "xerq bîyene",
    "y": " batmak (suya, çamura vb. batmak)\r\n~\tXerq bi. (Suya battı.)"
  },
  {
    "x": "xerqayîş (n)",
    "y": " b. xeriqîyayîş (n)"
  },
  {
    "x": "xerqbîyayîş (n)",
    "y": " batma (suya, çamura vb. batma)"
  },
  {
    "x": "xerqeya (m)",
    "y": " b. xeriqîyaya (m), xeriqîyayîye (m)"
  },
  {
    "x": "xerqeye (n)",
    "y": " b. xeriqîyaye (n)"
  },
  {
    "x": "xerqeyîş (n)",
    "y": " b. xeriqîyayîş (n)"
  },
  {
    "x": "xerqîyayîş (n)",
    "y": " b. xeriqîyayîş (n)"
  },
  {
    "x": "xerqnayîş (n)",
    "y": " b. xeriqnayîş (n)"
  },
  {
    "x": "xerrepîyayene",
    "y": " b. xeripîyayene"
  },
  {
    "x": "xerrepîyayey (m)",
    "y": " b. xeripîyayeyî (m)"
  },
  {
    "x": "xerrepîyayeyî (m)",
    "y": " b. xeripîyayeyî (m)"
  },
  {
    "x": "xerrepîyayênî (m)",
    "y": " b. xeripîyayênî (m)"
  },
  {
    "x": "xerrepîyayî, -ye",
    "y": " b. xeripîyayî, -ye"
  },
  {
    "x": "xerrepîyayîş (n)",
    "y": " b. xeripîyayîş (n)"
  },
  {
    "x": "xerrepnayene",
    "y": " b. xeripnayene"
  },
  {
    "x": "xerrepnayîş (n)",
    "y": " b. xeripnayîş (n)"
  },
  {
    "x": "xerripîyayene",
    "y": " b. xeripîyayene"
  },
  {
    "x": "xerripîyayey (m)",
    "y": " b. xeripîyayeyî (m)"
  },
  {
    "x": "xerripîyayênî (m)",
    "y": " b. xeripîyayênî (m)"
  },
  {
    "x": "xerripîyayî, -ye",
    "y": " b. xeripîyayî, -ye"
  },
  {
    "x": "xerripîyayîş (n)",
    "y": " b. xeripîyayîş (n)"
  },
  {
    "x": "xerripnayene",
    "y": " b. xeripnayene"
  },
  {
    "x": "xerripnayîş (n)",
    "y": " b. xeripnayîş (n)"
  },
  {
    "x": "xerritnayîş (n)",
    "y": " b. xeritnayîş (n)"
  },
  {
    "x": "(rez) xerritnayîş (n)",
    "y": " b. (rez) xeritnayîş (n)"
  },
  {
    "x": "xervelek, -e",
    "y": " b. qerbelek, -e"
  },
  {
    "x": "xerxal (m)",
    "y": " b. xirxale (m)"
  },
  {
    "x": "xerxel (n)",
    "y": " b. xirxil (n)"
  },
  {
    "x": "xerxike (m)",
    "y": " bağ budamakta kullanılan bir tür bıçak"
  },
  {
    "x": "xerxiki (m)",
    "y": " b. xerxike (m)"
  },
  {
    "x": "xerz (n)",
    "y": " kütükleri yeni dikilmiş olan veya üzüm verme çağına yeni girmiş olan bağ "
  },
  {
    "x": "xerza",
    "y": " b. xeyrja"
  },
  {
    "x": "xerze (m)",
    "y": " 1)fidan (asma fidanı)\r\n~2)bağ budamakta kullanılan bir tür bıçak"
  },
  {
    "x": "xerze ronayene",
    "y": " fidan dikmek"
  },
  {
    "x": "xerzek (n)",
    "y": " kütükleri yeni dikilmiş olan veya üzüm verme çağına yeni girmiş olan bağ "
  },
  {
    "x": "xerzi-I (m)",
    "y": " b. xeze (m)"
  },
  {
    "x": "xerzi-II (m)",
    "y": " b. rîçike (m)"
  },
  {
    "x": "xesar (n)",
    "y": " hasar\r\n~Xesar dîyo. (Hasar görmüş.)"
  },
  {
    "x": "xesenayene",
    "y": " b. xesênayene"
  },
  {
    "x": "xesenayîş (n)",
    "y": " b. xesênayîş (n)"
  },
  {
    "x": "xesênayene",
    "y": " hadımlaştırmak, enemek, iğdiş etmek"
  },
  {
    "x": "xesênayîş (n)",
    "y": " hadımlaştırma, eneme, iğdiş etme"
  },
  {
    "x": "xesênîyayene",
    "y": " hadımlaştırılmak, enenmek, iğdiş edilmek"
  },
  {
    "x": "xesênîyayîş (n)",
    "y": " hadımlaştırılma, enenme, iğdiş edilme"
  },
  {
    "x": "xesêyayîne",
    "y": " b. xesênîyayene"
  },
  {
    "x": "xesêyayîş (n)",
    "y": " b. xesênîyayîş (n)"
  },
  {
    "x": "xesilxane (n)",
    "y": " gasilhane"
  },
  {
    "x": "xesir (m)",
    "y": " b. hesîre (m)"
  },
  {
    "x": "xesîl (m)",
    "y": " b. hesîre (m)"
  },
  {
    "x": "xesîyayene",
    "y": " b. xesênîyayene"
  },
  {
    "x": "xesîyayîş (n)",
    "y": " b. xesênîyayîş (n)"
  },
  {
    "x": "xestexane (n)",
    "y": " hastane, hastahane"
  },
  {
    "x": "xestexaneyê nêweşîyanê psîkolojîkan (n)",
    "y": " akıl hastanesi"
  },
  {
    "x": "xestexaneyê nêweşîyanê ruhîyan (n)",
    "y": " akıl hastanesi"
  },
  {
    "x": "xestexone (n)",
    "y": " b. xestexane (n)"
  },
  {
    "x": "xestexûne (n)",
    "y": " b. xestexane (n)"
  },
  {
    "x": "xestexûni (n)",
    "y": " b. xestexane (n)"
  },
  {
    "x": "xestixûne (n)",
    "y": " b. xestexane (n)"
  },
  {
    "x": "xestxûne (n)",
    "y": " b. xestexane (n)"
  },
  {
    "x": "xeşabî (m)",
    "y": " b. xeşebî (m)"
  },
  {
    "x": "xeşebî (m)",
    "y": " matkap"
  },
  {
    "x": "xeşîm, -e",
    "y": " 1)acemi, toy\r\n~2)çömez (mecazî)"
  },
  {
    "x": "xeşîmey (m)",
    "y": " b. xeşîmîye (m)"
  },
  {
    "x": "xeşîmê (m)",
    "y": " b. xeşîmî (m)"
  },
  {
    "x": "xeşîmî (m)",
    "y": " acemilik"
  },
  {
    "x": "xeşîmîye (m)",
    "y": " acemilik"
  },
  {
    "x": "xet (m)",
    "y": " b. xete (m)"
  },
  {
    "x": "xeta (m)",
    "y": " yanlışlık, hata"
  },
  {
    "x": "xetat, -e",
    "y": " hattat"
  },
  {
    "x": "xetayin, -e",
    "y": " yanlış, hatalı"
  },
  {
    "x": "xete (m)",
    "y": " 1) çizgi, çizi, hat; kâğıt\r\n~2)çizi (saban ya da pulluğun açtığı iz), karık (küçük ark)\r\n~3)arık"
  },
  {
    "x": "xeta cite (m)",
    "y": " çizi (saban ya da pulluğun açtığı iz), karık (toprakta sabanla açılan çizi)"
  },
  {
    "x": "xeta citi (m)",
    "y": " b. xeta cite (m)"
  },
  {
    "x": "xeta dayîş-girewtişê agahî",
    "y": " iletişim hattı"
  },
  {
    "x": "xeta dayîş-girewtişê xeberan",
    "y": " iletişim hattı"
  },
  {
    "x": "xeta gerreyan (m)",
    "y": " şikayet hattı"
  },
  {
    "x": "xeta komunîkasyonî",
    "y": " iletişim hattı"
  },
  {
    "x": "xetê rayîrî (zh)",
    "y": " yol çizgileri"
  },
  {
    "x": "xeta sînorê bejrayîrî (m)",
    "y": " karayolu sınır çizgisi"
  },
  {
    "x": "xeta şîyayîşî (m)",
    "y": " rota"
  },
  {
    "x": "xeter (n)",
    "y": " tehlike\r\n~xetero kîmyayî (n): kimyasal tehlike"
  },
  {
    "x": "xetero kîmyayî (n)",
    "y": " kimyasal tehlike"
  },
  {
    "x": "xetero nukleer (n)",
    "y": " nükleer tehlike"
  },
  {
    "x": "xetero radyolojîk (n)",
    "y": " radyolojik tehlike"
  },
  {
    "x": "xeternak, -e",
    "y": " tehlikeli"
  },
  {
    "x": "xeti (m)",
    "y": " b. xete (m)"
  },
  {
    "x": "xettat, -e",
    "y": " b. xetat, -e"
  },
  {
    "x": "xevat (m)",
    "y": " b. xebate (m)"
  },
  {
    "x": "xever (m)",
    "y": " b. xebere (m)"
  },
  {
    "x": "xever dayene",
    "y": " b. xeberî dayene"
  },
  {
    "x": "xeverdar, -e",
    "y": " b. xeberdar, -e"
  },
  {
    "x": "xeverdayîş (n)",
    "y": " b. xeberîdayîş (n)"
  },
  {
    "x": "xevere (m)",
    "y": " b. xebere (m)"
  },
  {
    "x": "xeverî dayene",
    "y": " b. xeberî dayene"
  },
  {
    "x": "xeverî (zh)",
    "y": " b. xeberî (zh)"
  },
  {
    "x": "xeverî dayene",
    "y": " b. xeberî dayene"
  },
  {
    "x": "xeverîdayîs (n)",
    "y": " b. xeberîdayîş (n)"
  },
  {
    "x": "xeverîdayîş (n)",
    "y": " b. xeberîdayîş (n)"
  },
  {
    "x": "xevetîyayene",
    "y": " b. xebitîyayene"
  },
  {
    "x": "xevetîyayîne",
    "y": " b. xebitîyayene"
  },
  {
    "x": "xevetnayîne",
    "y": " b. xebitnayene"
  },
  {
    "x": "xevetnayîş (n)",
    "y": " b. xebitnayîş (n)"
  },
  {
    "x": "xevetyayîne",
    "y": " b. xebitîyayene"
  },
  {
    "x": "xevetyayîş (n)",
    "y": " b. xebitîyayîş (n)"
  },
  {
    "x": "xevêr (zh)",
    "y": " b. xeberî (zh)"
  },
  {
    "x": "xevêr dayene",
    "y": " b. xeberî dayene"
  },
  {
    "x": "xevêrdayîş (n)",
    "y": " b. xeberîdayîş (n)"
  },
  {
    "x": "xevitîyayîne",
    "y": " b. xebitîyayene"
  },
  {
    "x": "xevitîyayîş (n)",
    "y": " b. xebitîyayîş (n)"
  },
  {
    "x": "xevtîyayîne",
    "y": " b. xebitîyayene"
  },
  {
    "x": "xevtîyayîş (n)",
    "y": " b. xebitîyayîş (n)"
  },
  {
    "x": "xevtnayîne",
    "y": " b. xebitnayene"
  },
  {
    "x": "xevtnayîş (n)",
    "y": " b. xebitnayîş (n)"
  },
  {
    "x": "xewat (m)",
    "y": " b. xebate (m)"
  },
  {
    "x": "xewati (m)",
    "y": " b. xebate (m)"
  },
  {
    "x": "xewer (m)",
    "y": " b. xebere (m)"
  },
  {
    "x": "xewerdar, -e",
    "y": " b. xeberdar, -e"
  },
  {
    "x": "xewere (m)",
    "y": " b. xebere (m)"
  },
  {
    "x": "xewerî dayene",
    "y": " b. xeberî dayene"
  },
  {
    "x": "xewetîyayîş (n)",
    "y": " b. xebitîyayîş (n)"
  },
  {
    "x": "xewetnayîş (n)",
    "y": " b. xebitnayîş (n)"
  },
  {
    "x": "xewf kerdene",
    "y": " ürküp sıçramak (hayvan için)"
  },
  {
    "x": "xewirîyaya (m)",
    "y": " baygın"
  },
  {
    "x": "xewirîyaye (n)",
    "y": " baygın"
  },
  {
    "x": "xewirîyayene",
    "y": " bayılmak"
  },
  {
    "x": "xewirîyayîş (n)",
    "y": " bayılma"
  },
  {
    "x": "xewirîyayîye (m)",
    "y": " baygın"
  },
  {
    "x": "xewirnaya (m)",
    "y": " bayıltılmış olan"
  },
  {
    "x": "xewirnaye (n)",
    "y": " bayıltılmış olan"
  },
  {
    "x": "xewirnayene",
    "y": " bayıltmak"
  },
  {
    "x": "xewirnayîş (n)",
    "y": " bayıltma"
  },
  {
    "x": "xewirnayîye (m)",
    "y": " bayıltılmış olan"
  },
  {
    "x": "xewitîyayîş (n)",
    "y": " b. xebitîyayîş (n)"
  },
  {
    "x": "xewlî (m)",
    "y": " havlu"
  },
  {
    "x": "xewlîye (m)",
    "y": " havlu"
  },
  {
    "x": "xewlîya banyoyî (m)",
    "y": " banyo havlusu"
  },
  {
    "x": "xewlîya destan (m)",
    "y": " el havlusu"
  },
  {
    "x": "xewrîyaya (m)",
    "y": " b. xewirîyaya (m)"
  },
  {
    "x": "xewrîyaye (n)",
    "y": " b. xewirîyaye (n)"
  },
  {
    "x": "xewrîyayene",
    "y": " b. xewirîyayene "
  },
  {
    "x": "xewrîyayîş (n)",
    "y": " b. xewirîyayîş (n)"
  },
  {
    "x": "xewrîyayîye (m)",
    "y": " b. xewirîyayîye (m)"
  },
  {
    "x": "xewrnaya (m)",
    "y": " b. xewirnaya (m), xewirnayîye (m)"
  },
  {
    "x": "xewrnaye (n)",
    "y": " b. xewirnaye (n)"
  },
  {
    "x": "xewrnayene",
    "y": " b. xewirnayene"
  },
  {
    "x": "xewrnayîş (n)",
    "y": " b. xewirnayîş (n)"
  },
  {
    "x": "xewtîyayîne",
    "y": " b. xebitîyayene"
  },
  {
    "x": "xextîyayîş (n)",
    "y": " b. xebitîyayîş (n)"
  },
  {
    "x": "xewx (m)",
    "y": " b. xewxe (m)"
  },
  {
    "x": "xewxe (m)",
    "y": " şeftali"
  },
  {
    "x": "xewxêre (m)",
    "y": " şeftali ağacı"
  },
  {
    "x": "xewxêri (m)",
    "y": " b. xewxêre (m)"
  },
  {
    "x": "xewxi (m)",
    "y": " b. xewxe (m)"
  },
  {
    "x": "xewxyer (m)",
    "y": " b. xewxêre (m)"
  },
  {
    "x": "xex, -e",
    "y": " b. xêx, -e"
  },
  {
    "x": "xex bîyayene",
    "y": " b. xêx bîyene"
  },
  {
    "x": "xex kerdene",
    "y": " b. xêx kerdene"
  },
  {
    "x": "xexîye (m)",
    "y": " b. xêxîye (m)"
  },
  {
    "x": "xeyal (n)",
    "y": " hayal, imge, düş"
  },
  {
    "x": "xeyalî, -ye",
    "y": " hayali, imgesel, düşsel, fantastik"
  },
  {
    "x": "xeyar (n)",
    "y": " b. xîyar (n)"
  },
  {
    "x": "xeybane (n)",
    "y": " gulyabani"
  },
  {
    "x": "xeybet kerdene",
    "y": " b. xîybet kerdene"
  },
  {
    "x": "xeybetkerdiş (n)",
    "y": " b. xîybetkerdiş (n)"
  },
  {
    "x": "xeybone (n)",
    "y": " b. xeybane (n)"
  },
  {
    "x": "xeybûne (n)",
    "y": " b. xeybane (n)"
  },
  {
    "x": "xeyca",
    "y": " b. xeyrja"
  },
  {
    "x": "xeyd (m)",
    "y": " b. xeyde (m)"
  },
  {
    "x": "Xeyd (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "xeyde (m)",
    "y": " ağaç topluluğu (söğüt, kavak vb. için)"
  },
  {
    "x": "Xeyde (m)",
    "y": " inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "xeydûxerîb, -i",
    "y": " b. xeyrûxerîb, -e"
  },
  {
    "x": "xeyel (n)",
    "y": " b. xeyal (n)"
  },
  {
    "x": "xeyja",
    "y": " b. xeyrja"
  },
  {
    "x": "xeyle",
    "y": " b. xeylê"
  },
  {
    "x": "xeylek",
    "y": " b. xeylêk"
  },
  {
    "x": "xeylekê",
    "y": " b. xeylêk"
  },
  {
    "x": "xeylê",
    "y": " çok, epey, epeyce, hayli, bayağı"
  },
  {
    "x": "xeylê wext (n)",
    "y": " hayli zaman, uzun zaman"
  },
  {
    "x": "xeylê zeman (n)",
    "y": " hayli zaman, uzun zaman"
  },
  {
    "x": "xeylêk",
    "y": " bir hayli, birçok, ağız dolusu [birçok]"
  },
  {
    "x": "xeylêke",
    "y": " b. xeylêk"
  },
  {
    "x": "xeylêkî",
    "y": " b. xeylêk"
  },
  {
    "x": "xeylên",
    "y": " b. xeylêk"
  },
  {
    "x": "xeylî",
    "y": " b. xeylê"
  },
  {
    "x": "xeylî wext (n)",
    "y": " b. xeylê wext (n)"
  },
  {
    "x": "xeylî zeman (n)",
    "y": " b. xeylê zeman (n)"
  },
  {
    "x": "xeylîk",
    "y": " b. xeylêk"
  },
  {
    "x": "xeylîyek",
    "y": " b. xeylêk"
  },
  {
    "x": "xeylyek",
    "y": " b. xeylêk"
  },
  {
    "x": "xeym (m)",
    "y": " b. xeyme (m)"
  },
  {
    "x": "xeyme (m)",
    "y": " çadır"
  },
  {
    "x": "xeymi (m)",
    "y": " b. xeyme (m)"
  },
  {
    "x": "xeyr, -e –I (nm & s)",
    "y": " hayır\r\n~Şima xeyrî mîyan de bîy/bê. (Hoş bulduk.)\r\n~\t\r\n~Ti xeyr şêrî, silam bêrî. (Güle güle git, güle güle gel.)\r\n~\t\r\n~Ti xeyrî mîyan de bî/ba. (Hoş bulduk.)\r\n~\t\r\n~Wa xeyr bo./Va xeyr bo. (Hayırlı olsun)\r\n~\t\r\n~Xeyr şo, silam bê. (Güle güle git, güle güle gel.)\r\n~\t\r\n~Xeyrî mîyan de bîme. (Hoş bulduk.)"
  },
  {
    "x": "xeyrê xo",
    "y": " lütfen"
  },
  {
    "x": "xeyr-II (zr)",
    "y": " b. xêr-II (zr)"
  },
  {
    "x": "xeyrame (m)",
    "y": " b. hêramî (n)"
  },
  {
    "x": "xeyrca",
    "y": " b. xeyrja"
  },
  {
    "x": "xeyret (n)",
    "y": " b. xîret (n)"
  },
  {
    "x": "xeyret kerdene",
    "y": " b. xîret kerdene"
  },
  {
    "x": "xeyretkar, -e",
    "y": " b. xîretkar, -e"
  },
  {
    "x": "xeyretkarey (m)",
    "y": " b. xîretkarîye (m)"
  },
  {
    "x": "xeyretkarê (m)",
    "y": " b. xîretkarî (m)"
  },
  {
    "x": "xeyretkerdiş (n)",
    "y": " b. xîretkerdiş (n)"
  },
  {
    "x": "xeyretkêş, -e",
    "y": " b. xîretkêş, -e"
  },
  {
    "x": "xeyretkêşey (m)",
    "y": " b. xîretkêşî (m)"
  },
  {
    "x": "xeyretkêşê (m)",
    "y": " b. xîretkêşî (m)"
  },
  {
    "x": "xeyrê ...",
    "y": " -den başka, hariç"
  },
  {
    "x": "xeyrêexlaqî, -ye",
    "y": " gayriahlaki"
  },
  {
    "x": "xeyrêmeşrû",
    "y": " gayri meşru"
  },
  {
    "x": "xeyrên, -e",
    "y": " hayırlı\r\n~Xeyrên/Xeyrêne bo. (Hayırlı olsun)"
  },
  {
    "x": "xeyrêni (m)",
    "y": " b. xeyrêne (m)"
  },
  {
    "x": "xeyrêqanûnî, -ye",
    "y": " yasadışı"
  },
  {
    "x": "xeyrin, -e",
    "y": " b. xeyrên, -e"
  },
  {
    "x": "xeyrja",
    "y": " -den başka, hariç\r\n~Xeyrja to çi kes nêzano. (Senden başka hiç kimse bilmiyor.)"
  },
  {
    "x": "xeyrûxerîb, -e",
    "y": " tamamen yabancı"
  },
  {
    "x": "xeyrûxerîbi (m)",
    "y": " b. xeyrûxerîbe (m)"
  },
  {
    "x": "xeyşa (m)",
    "y": " b. xaşîye (m)"
  },
  {
    "x": "xeyte (m)",
    "y": " dikiş çizgisi"
  },
  {
    "x": "xeyte kerdene",
    "y": " 1)dikişle çizgi çizmek\r\n~2)dikişle çizgi çizerek süslemek"
  },
  {
    "x": "xezab (n)",
    "y": " b. xezeb (n)"
  },
  {
    "x": "xezal, -e",
    "y": " ceylan"
  },
  {
    "x": "xezali (m)",
    "y": " b. xezale (m)"
  },
  {
    "x": "xeze (m)",
    "y": " duvak"
  },
  {
    "x": "Xeze (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "xezeb-I (n)",
    "y": " 1)gazap, felaket, yıkım\r\n~2)kızgınlık (öfke)"
  },
  {
    "x": "xezeb, -e-II",
    "y": " acımasız"
  },
  {
    "x": "xezebi (m)",
    "y": " b. xezeb, -e"
  },
  {
    "x": "xezebnak, -e",
    "y": " öfkeli, kızgın"
  },
  {
    "x": "xezev-I (n)",
    "y": " b. xezeb (n)"
  },
  {
    "x": "xezev, -e-II",
    "y": " b. xezeb, -e"
  },
  {
    "x": "xezika",
    "y": " b. xwezî"
  },
  {
    "x": "xezila",
    "y": " b. xwezila"
  },
  {
    "x": "xezî",
    "y": " b. xwezî"
  },
  {
    "x": "xezîka",
    "y": " b. xwezî"
  },
  {
    "x": "xezîna (m)",
    "y": " hazine"
  },
  {
    "x": "xezîne (m)",
    "y": " b. xezîna (m)"
  },
  {
    "x": "xezît",
    "y": " b. xejîte (m)"
  },
  {
    "x": "xezna (m)",
    "y": " b. xezîna (m)"
  },
  {
    "x": "xeznag, -e",
    "y": " kurnaz"
  },
  {
    "x": "xezne (m)",
    "y": " b. xezîna (m)"
  },
  {
    "x": "xezuka",
    "y": " b. xwezî"
  },
  {
    "x": "xezul",
    "y": " b. xwezila"
  },
  {
    "x": "xêca",
    "y": " b. xeyrja"
  },
  {
    "x": "xêd (n)",
    "y": " b. xeyde (m)"
  },
  {
    "x": "xêle",
    "y": " b. xeylê"
  },
  {
    "x": "xêlek",
    "y": " b. xeylêk"
  },
  {
    "x": "xêlekê",
    "y": " b. xeylêk"
  },
  {
    "x": "xêlê",
    "y": " b. xeylê"
  },
  {
    "x": "xêlê waxt (n)",
    "y": " b. xeylê wext (n)"
  },
  {
    "x": "xêlê zamûn (n)",
    "y": " b. xeylê zeman (n)"
  },
  {
    "x": "xêlêk",
    "y": " b. xeylêk"
  },
  {
    "x": "xêlisîyayene",
    "y": " b. xelisîyayene"
  },
  {
    "x": "xêlisîyayîş (n)",
    "y": " b. xelisîyayîş (n)"
  },
  {
    "x": "xêlisnayene",
    "y": " b. xelisnayene"
  },
  {
    "x": "xêlisnayîş (n)",
    "y": " xelisnayîş (n)"
  },
  {
    "x": "xêlî-I (m)",
    "y": " duvak"
  },
  {
    "x": "xêlî-II",
    "y": " b. xeylê"
  },
  {
    "x": "xêlî wext (n)",
    "y": " b. xeylê wext (n)"
  },
  {
    "x": "xêlî zamûn (n)",
    "y": " b. xeylê zeman (n)"
  },
  {
    "x": "xêlîk",
    "y": " b. xeylêk"
  },
  {
    "x": "xêlîyek",
    "y": " b. xeylêk"
  },
  {
    "x": "xêlke",
    "y": " b. xeylêk"
  },
  {
    "x": "xêlyek",
    "y": " b. xeylêk"
  },
  {
    "x": "xêm (m)",
    "y": " b. xeyme (m)"
  },
  {
    "x": "xême (m)",
    "y": " b. xeyme (m)"
  },
  {
    "x": "xênca",
    "y": " b. xêncê"
  },
  {
    "x": "xêncê",
    "y": " -den başka, hariç\r\n~Xêncê to her kes amebî. (Senden başka herkes gelmişti.)"
  },
  {
    "x": "xêr, -e -I (nm & s)",
    "y": " b. xeyr, -e-I (nm & s)"
  },
  {
    "x": "Bixêr şue-bîye.",
    "y": " b. Bixeyr şo-bê."
  },
  {
    "x": "xêrê xo",
    "y": " b. xeyrê xo"
  },
  {
    "x": "xêr-II (zr)",
    "y": " hayır, yok"
  },
  {
    "x": "xerza",
    "y": " b. xeyrja"
  },
  {
    "x": "xêrên, -e",
    "y": " b. xeyrên, -e"
  },
  {
    "x": "Xêrên/Xêrêne bo.",
    "y": " b. Xeyrên/Xeyrêne bo."
  },
  {
    "x": "xêri (m)",
    "y": " b. xeyre (m)"
  },
  {
    "x": "xêrîb, -e",
    "y": " b. xerîb, -e"
  },
  {
    "x": "xêrîv, -e",
    "y": " b. xerîb, -e"
  },
  {
    "x": "xêrza",
    "y": " b. xeyrja"
  },
  {
    "x": "xêşa (m)",
    "y": " b. xaşîye (m)"
  },
  {
    "x": "xêv, -e",
    "y": " ahmak, aptal, avanak, salak"
  },
  {
    "x": "xêv bîyene",
    "y": " aptallaşmak, ahmaklaşmak"
  },
  {
    "x": "xêv kerdene",
    "y": " aptallaştırmak, ahmaklaştırmak"
  },
  {
    "x": "xêvane",
    "y": " aptalca, ahmakça"
  },
  {
    "x": "xêvbîyayîş (n)",
    "y": " ahmaklaşma"
  },
  {
    "x": "xêvek, -e",
    "y": " ahmak, aptal, avanak, salak"
  },
  {
    "x": "xêvek bîyene",
    "y": " ahmaklaşmak"
  },
  {
    "x": "xêvek kerdene",
    "y": " ahmaklaştırmak"
  },
  {
    "x": "xêvekbîyayîş (n)",
    "y": " aptallaşma, ahmaklaşma"
  },
  {
    "x": "xêvekî (m)",
    "y": " ahmaklık, aptallık, avanaklık, salaklık"
  },
  {
    "x": "xêvekîye (m)",
    "y": " ahmaklık, aptallık, avanaklık, salaklık"
  },
  {
    "x": "xêvekkerdiş (n)",
    "y": " ahmaklaştırma"
  },
  {
    "x": "xêvey (m)",
    "y": " b. xêvîye (m)"
  },
  {
    "x": "xêvê (m)",
    "y": " b. xêvî (m)"
  },
  {
    "x": "xêvi (m)",
    "y": " b. xêv, -e"
  },
  {
    "x": "xêvî (m)",
    "y": " ahmaklık, aptallık, avanaklık, salaklık"
  },
  {
    "x": "xêvîye (m)",
    "y": " ahmaklık, aptallık, avanaklık, salaklık"
  },
  {
    "x": "xêvkerdiş (n)",
    "y": " aptallaştırma, ahmaklaştırma"
  },
  {
    "x": "xêvo (n)",
    "y": " ahmak, aptal, avanak, salak"
  },
  {
    "x": "xêwat (m)",
    "y": " b. xebate (m)"
  },
  {
    "x": "xêx, -e",
    "y": " 1)saf (kişi için)\r\n~2)ahmak, aptal\r\n~3)deli, çılgın"
  },
  {
    "x": "xêx bîyayene",
    "y": " b. xêx bîyene"
  },
  {
    "x": "xêx bîyene",
    "y": " 1)delirmek, çıldırmak, çılgına dönmek, çılgınlaşmak\r\n~2)ahmaklaşmak"
  },
  {
    "x": "xêx kerdene",
    "y": " 1)delirtmek, çıldırtmak\r\n~2)ahmaklaştırmak"
  },
  {
    "x": "xêxane",
    "y": " 1)ahmakça\r\n~2)delice, çılgınca"
  },
  {
    "x": "xêxbîyayîş (n)",
    "y": " 1)ahmaklaşma\r\n~2)çıldırma, çıldırış, çılgınlaşma, delirme, deliriş"
  },
  {
    "x": "xêxîye (m)",
    "y": " delilik, çılgınlık"
  },
  {
    "x": "xêxîye kerdene",
    "y": " çılgınlık etmek"
  },
  {
    "x": "xêxkerdiş (n)",
    "y": " 1)ahmaklaştırma\r\n~2)çıldırtma, delirtme"
  },
  {
    "x": "xêxtîye (m)",
    "y": " b. xêxîye (m)"
  },
  {
    "x": "xêyal (n)",
    "y": " b. xeyal (n)"
  },
  {
    "x": "xêyar (n)",
    "y": " b. xîyar (n)"
  },
  {
    "x": "xêyca",
    "y": " b. xeyrja"
  },
  {
    "x": "xêyle",
    "y": " b. xeylê"
  },
  {
    "x": "xêylê",
    "y": " b. xeylê"
  },
  {
    "x": "xêze (m)",
    "y": " 1)çizgi, çizi, hat\r\n~2)çizgi (bazı oyunlarda başlangıç veya sınır çizgisi)"
  },
  {
    "x": "xêza asoyî (m)",
    "y": " ufuk çizgisi"
  },
  {
    "x": "xêza nîşanî (m)",
    "y": " oyunda başlangıç çizgisi"
  },
  {
    "x": "xêza parekî (m)",
    "y": " bölü çizgisi (mat)"
  },
  {
    "x": "xêza rayîrî ya mîyanêne (m)",
    "y": " yol orta çizgisi"
  },
  {
    "x": "xêza sînorê bejrayîrî (m)",
    "y": " karayolu sınır çizgisi"
  },
  {
    "x": "xêze bîyene",
    "y": " çizilmek"
  },
  {
    "x": "xêze kerdene",
    "y": " çizmek"
  },
  {
    "x": "xêzê rayîrî (zh)",
    "y": " yol çizgileri"
  },
  {
    "x": "xêzeantiş (n)",
    "y": " çizim"
  },
  {
    "x": "xêzebîyayîş (n)",
    "y": " çizilme, çiziliş"
  },
  {
    "x": "xêzefilm (n)",
    "y": " çizgi film"
  },
  {
    "x": "xêzekerdiş (n)",
    "y": " çizme, çizim"
  },
  {
    "x": "xêzeroman (n)",
    "y": " çizgi roman"
  },
  {
    "x": "xêzi (m)",
    "y": " b. xêze (m)"
  },
  {
    "x": "xêzikin, -e",
    "y": " çizgili"
  },
  {
    "x": "xêzin, -e",
    "y": " çizgili"
  },
  {
    "x": "xi",
    "y": " b. xo-I"
  },
  {
    "x": "xib (m)",
    "y": " b. xibe (m)"
  },
  {
    "x": "xibe (m)",
    "y": " iğde"
  },
  {
    "x": "xibeyr-I (m) ",
    "y": " b. xibêre (m)"
  },
  {
    "x": "xibeyr-II (m)",
    "y": " b. xebere (m)"
  },
  {
    "x": "xibeyrî dayene",
    "y": " b. xeberî dayene"
  },
  {
    "x": "xibeyrîdayîş",
    "y": " b. xeberîdayîş (n)"
  },
  {
    "x": "xibêre (m)",
    "y": " iğde ağacı"
  },
  {
    "x": "xibêyr (m)",
    "y": " b. xibêre (m)"
  },
  {
    "x": "xibik (m)",
    "y": " b. xibe (m)"
  },
  {
    "x": "xic bîyene",
    "y": " b. xij bîyene"
  },
  {
    "x": "xicbîyayîş (n)",
    "y": " b. xijbîyayîş (n)"
  },
  {
    "x": "xicikîyayene",
    "y": " b. xijikîyayene"
  },
  {
    "x": "xicikîyayîş (n)",
    "y": " b. xijikîyayîş (n)"
  },
  {
    "x": "xida (m)",
    "y": " besin, gıda"
  },
  {
    "x": "xidaya huşke (m)",
    "y": " kuru gıda"
  },
  {
    "x": "xidar, -e",
    "y": " 1)b. xedar, -e"
  },
  {
    "x": "xidar bîyayîne",
    "y": " b. xidar bîyene"
  },
  {
    "x": "xidar bîyene",
    "y": " azmak (yara vb. için) "
  },
  {
    "x": "xidar nêweş bîyene",
    "y": " ağır hasta olmak"
  },
  {
    "x": "xidar niweş bîyene",
    "y": " b. xidar nêweş bîyene"
  },
  {
    "x": "xidarbeyîş (n)",
    "y": " b. xidarbîyayîş (n)"
  },
  {
    "x": "xidarbîyayîş (n)",
    "y": " azma (yara vb. için)"
  },
  {
    "x": "Xide (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "xidxid (n)",
    "y": " b. qitqit (n)"
  },
  {
    "x": "xifle (n)",
    "y": " b. xelfe (n)"
  },
  {
    "x": "xifle dayene",
    "y": " b. xelfe dayene"
  },
  {
    "x": "xij, -e",
    "y": " b. qij, -e"
  },
  {
    "x": "xij bîyayene",
    "y": " b. xij bîyene"
  },
  {
    "x": "xij bîyayêne",
    "y": " b. xij bîyene"
  },
  {
    "x": "xij bîyene",
    "y": " kaymak"
  },
  {
    "x": "xij kerdene",
    "y": " kaydırmak"
  },
  {
    "x": "xijalik (n)",
    "y": " b. xizalek (n)"
  },
  {
    "x": "xijalokî (zh)",
    "y": " içyağının kızartılmasıyla meydana gelen bir yiyecek"
  },
  {
    "x": "xijbîyayîş (n)",
    "y": " kayma"
  },
  {
    "x": "xijike (m)",
    "y": " daldaki salkımdan koparılmadan kurumuş olan üzüm tanesi"
  },
  {
    "x": "xijikî (zh)",
    "y": " içyağının kızartılmasıyla meydana gelen bir yiyecek"
  },
  {
    "x": "xijikîyayene",
    "y": " kaymak"
  },
  {
    "x": "xijikîyayîş (n)",
    "y": " kayma"
  },
  {
    "x": "xijiknayene",
    "y": " kaydırmak"
  },
  {
    "x": "xijiknayîş (n)",
    "y": " kaydırma"
  },
  {
    "x": "xijîyayene",
    "y": " b. xijikîyayene"
  },
  {
    "x": "xijîyayîş (n)",
    "y": " b. xijikîyayîş (n)"
  },
  {
    "x": "xijjikî (zh)",
    "y": " b. xijikî (zh)"
  },
  {
    "x": "xijkerdiş (n)",
    "y": " kaydırma"
  },
  {
    "x": "xijnok, -e",
    "y": " b. xijok, -e"
  },
  {
    "x": "xijok, -e",
    "y": " 1)kaygan \r\n~2)sürüngen\r\n~xijokê axuyinî (zh): zehirli sürüngenler"
  },
  {
    "x": "xijokê axuyinî (zh)",
    "y": " zehirli sürüngenler"
  },
  {
    "x": "xijokê jehrinî (zh)",
    "y": " zehirli sürüngenler"
  },
  {
    "x": "xijokî (zh)",
    "y": " sürüngenler"
  },
  {
    "x": "xil (m)",
    "y": " b. xile (m)"
  },
  {
    "x": "xilamîtî (m)",
    "y": " b. xinamîye (m)"
  },
  {
    "x": "xilas (m)",
    "y": " b. xelase (m)"
  },
  {
    "x": "xilas bîyene",
    "y": " b. xelas bîyene"
  },
  {
    "x": "xilasbîyayîş (n)",
    "y": " b. xelasbîyayîş (n)"
  },
  {
    "x": "xilç (n)",
    "y": " b. gulç (n)"
  },
  {
    "x": "xilçî (zh)",
    "y": " b. gulçî (zh)"
  },
  {
    "x": "xildayîş (n)",
    "y": " durduğu yerden atlama"
  },
  {
    "x": "xile (m)",
    "y": " küp"
  },
  {
    "x": "xilerdê murayan (n)",
    "y": " boncuk dizisi"
  },
  {
    "x": "xilerdê murûn (n)",
    "y": " b. xilerdê murayan (n)"
  },
  {
    "x": "xilfe (n)",
    "y": " b. xelfe (n)"
  },
  {
    "x": "xilfe dayene",
    "y": " b. xelfe dayene"
  },
  {
    "x": "xili (m)",
    "y": " b. xile (m)"
  },
  {
    "x": "xilik (n)",
    "y": " b. xilike (m)"
  },
  {
    "x": "xilike (m)",
    "y": " küçük küp"
  },
  {
    "x": "xilmaş, -e",
    "y": " (yarı uykuda, yarı uyanık) uyur uyanık "
  },
  {
    "x": "xilmaşey (m)",
    "y": " b. xilmaşîye (m)"
  },
  {
    "x": "xilmaşê (m)",
    "y": " b. xilmaşî (m)"
  },
  {
    "x": "xilmaşî (m)",
    "y": " (yarı uykuda, yarı uyanık olma) uyur uyanıklık"
  },
  {
    "x": "xilmaşîye (m)",
    "y": " (yarı uykuda, yarı uyanık olma) uyur uyanıklık"
  },
  {
    "x": "xilnayene",
    "y": " yıkmak"
  },
  {
    "x": "xilnayîş (n)",
    "y": " yıkma, yıkım"
  },
  {
    "x": "xilomê (m)",
    "y": " b. xinamîye (m)"
  },
  {
    "x": "xilomî (n)",
    "y": " b. xinamî (n)"
  },
  {
    "x": "xilorike (m)",
    "y": " 1)ufak taneli dolu\r\n~2)köfte (ince yarmadan veya kıymadan yapılan)"
  },
  {
    "x": "xiloriki (m)",
    "y": " b. xilorike (m)"
  },
  {
    "x": "xilt (n)",
    "y": " 1)eklektik\r\n~2)hile\r\n~3)karışım (buğday ile darı veya buğday ile arpa karışımı)"
  },
  {
    "x": "xilt bîyene",
    "y": " karışmak (karışık olmak)"
  },
  {
    "x": "xilt kerdene",
    "y": " karıştırmak (karışık duruma getirmek)"
  },
  {
    "x": "xiltbîyayîş (n)",
    "y": " karışma (karışık olma)"
  },
  {
    "x": "xiltkerdiş (n)",
    "y": " karıştırma (karışık duruma getirme)"
  },
  {
    "x": "xilwar, -e",
    "y": " eğri büğrü, çarpık çurpuk, çarpuk çurpuk"
  },
  {
    "x": "xilxil (n)",
    "y": " b. xirxil (n)"
  },
  {
    "x": "ximarekî (zh)",
    "y": " b. kemerekî (zh) "
  },
  {
    "x": "ximazek (m)",
    "y": " b. xanzeke (m)"
  },
  {
    "x": "ximzikar, -i",
    "y": " b. xizmetkar, -e"
  },
  {
    "x": "ximzkar, -i",
    "y": " b. xizmetkar, -e"
  },
  {
    "x": "xinamey (m)",
    "y": " b. xinamîye (m) "
  },
  {
    "x": "xinamênî (m)",
    "y": " dünürlük"
  },
  {
    "x": "xinamî, -ye",
    "y": " dünür"
  },
  {
    "x": "xinamîye (m)",
    "y": " dünürlük "
  },
  {
    "x": "xinamîyîye (m)",
    "y": " b. xinamîyîye (m)"
  },
  {
    "x": "xinamîyên (m)",
    "y": " b. xinamênî (m)"
  },
  {
    "x": "xincer (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xincere (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xincêr (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xincikîyayene",
    "y": " ezilmek"
  },
  {
    "x": "xinciknayene",
    "y": " ezmek"
  },
  {
    "x": "xinciknayîş (n)",
    "y": " ezme"
  },
  {
    "x": "xincîke (m)",
    "y": " bağ kulübesi"
  },
  {
    "x": "xincîka mêşan (m)",
    "y": " bal arısı kovanlarının istiflenerek konulduğu -evden uzak- yer "
  },
  {
    "x": "xincîr (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xincyer (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xinçer (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xinçeri (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xinçêr (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xinçêrî (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xinçîr (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xinçîyer (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xinuq, -e",
    "y": " b. xunuq, -e"
  },
  {
    "x": "xinûq, -e",
    "y": " b. xunuq, -e"
  },
  {
    "x": "xinûqî (m)",
    "y": " b. xunuqî (m)"
  },
  {
    "x": "Xinûs (n)",
    "y": " Hınıs"
  },
  {
    "x": "xinzir, -e",
    "y": " b. xenzîr, -e"
  },
  {
    "x": "xinzîr, -e",
    "y": " b. xenzîr, -e"
  },
  {
    "x": "xir (n)",
    "y": " sik, penis, kamış"
  },
  {
    "x": "xir bîyene",
    "y": " yuvarlanmak"
  },
  {
    "x": "xir kerdene",
    "y": " yuvarlamak"
  },
  {
    "x": "xirab, -e",
    "y": " 1)kötü, fena; adi\r\n~2)aşağılık"
  },
  {
    "x": "xirab bîyayîş (n)",
    "y": " başkalaşma (mecazi)"
  },
  {
    "x": "xirab bîyayene",
    "y": " b. xirab bîyene"
  },
  {
    "x": "xirab bîyene",
    "y": " 1)kötüleşmek, adileşmek\r\n~2)başkalaşmak (mecazi)"
  },
  {
    "x": "xirab kerdene",
    "y": " kötüleştirmek, adileştirmek"
  },
  {
    "x": "xiraba (m)",
    "y": " bozuk, bozulmuş olan (makine vb. için), arızalı"
  },
  {
    "x": "xirabe (n)",
    "y": " bozuk, bozulmuş olan (makine vb. için), arızalı"
  },
  {
    "x": "xirabe bîyene",
    "y": " bozulmak (makine vb. için)"
  },
  {
    "x": "xirabe kerdene",
    "y": " bozmak (makine vb. için)"
  },
  {
    "x": "xirabebîyaya (m)",
    "y": " bozuk, bozulmuş olan (makine vb. için)"
  },
  {
    "x": "xirabebîyaye (m)",
    "y": " bozuk, bozulmuş olan (makine vb. için)"
  },
  {
    "x": "xirabebîyayî, -ye",
    "y": " bozuk"
  },
  {
    "x": "xirabebîyayîş (n)",
    "y": " bozulma (makine vb. için)"
  },
  {
    "x": "xirabekerdiş (n)",
    "y": " bozma (makine vb. için)"
  },
  {
    "x": "xirabey (m)",
    "y": " b. xirabîye (m), xirabî (m)"
  },
  {
    "x": "xirabeyî (m)",
    "y": " bozukluk"
  },
  {
    "x": "xirabeyîya bestîyayîşê reaktîfî (m)",
    "y": " reaktif bağlanma bozukluğu"
  },
  {
    "x": "xirabeyîya genetîke (m)",
    "y": " genetik bozukluk"
  },
  {
    "x": "xirabeyîya girêdîyayîşê reaktîfî (m)",
    "y": " reaktif bağlanma bozukluğu"
  },
  {
    "x": "xirabeyîya qalîkerdişî (m)",
    "y": " konuşma bozukluğu"
  },
  {
    "x": "xirabeyîya qiseykerdişî (m)",
    "y": " konuşma bozukluğu"
  },
  {
    "x": "xirabeyîya ziwan û qalîkerdişî (m)",
    "y": " dil ve konuşma bozukluğu"
  },
  {
    "x": "xirabeyîyê anksîyete (zh)",
    "y": " anksiyete bozuklukları"
  },
  {
    "x": "xirabeyîyê cîfgirewtişî (zh)",
    "y": " solunum bozuklukları"
  },
  {
    "x": "xirabeyîyê kesîtî (zh)",
    "y": " kişilik bozuklukları"
  },
  {
    "x": "xirabeyîyê komelkîyî (zh)",
    "y": " sosyal bozukluklar"
  },
  {
    "x": "xirabeyîyê livdarîye (zh)",
    "y": " mobilite bozuklukları"
  },
  {
    "x": "xirabeyîyê mobîlîte (zh)",
    "y": " mobilite bozuklukları"
  },
  {
    "x": "xirabeyîyê sosyalî (zh)",
    "y": " sosyal bozukluklar"
  },
  {
    "x": "xirabeyîyê şexsîyetî (zh)",
    "y": " kişilik bozuklukları"
  },
  {
    "x": "xirabeyîyê tevgerdarîye (zh)",
    "y": " mobilite bozuklukları"
  },
  {
    "x": "xirabeyîye (m)",
    "y": " bozukluk"
  },
  {
    "x": "xirabeypeykerdiş (n)",
    "y": " b. xirabîpêkerdiş (n)"
  },
  {
    "x": "xirabê (m)",
    "y": " b. xirabîye (m), xirabî (m)"
  },
  {
    "x": "xirabênî (m)",
    "y": " kötülük, fenalık"
  },
  {
    "x": "xirabi (n)",
    "y": " b. xirabe (n)"
  },
  {
    "x": "xirabinî (m)",
    "y": " b. xirabênî (m)"
  },
  {
    "x": "xirabinîye (m)",
    "y": " b. xirabênî (m)"
  },
  {
    "x": "xirabî (m)",
    "y": " kötülük, fenalık"
  },
  {
    "x": "xirabî pê kerdene",
    "y": " 1)-e kötülük yapmak\r\n~2)(namusunu) kirletmek, -e tecavüz etmek"
  },
  {
    "x": "xirabîni (m)",
    "y": " b. xirabî (m)"
  },
  {
    "x": "xirabîpêkerdiş (n)",
    "y": " 1)-e kötülük yapma\r\n~2)(namusunu) kirletme, -e tecavüz etme"
  },
  {
    "x": "xirabîpêkerdox, -e",
    "y": " kirletici (namus vb. için)"
  },
  {
    "x": "xirabîyayîş (n)",
    "y": " b. xirabebîyayîş (n)"
  },
  {
    "x": "xirabîye (m)",
    "y": " kötülük, fenalık; adilik"
  },
  {
    "x": "xirabkerdiş (n)",
    "y": " kötüleştirme"
  },
  {
    "x": "xirabtî (m)",
    "y": " b. xirabî (m)"
  },
  {
    "x": "xirabvîn, -e",
    "y": " kötümser, bedbin"
  },
  {
    "x": "xirabvîn bîyene",
    "y": " kötümserleşmek, bedbinleşmek"
  },
  {
    "x": "xirabvînbîyayîş (n)",
    "y": " kötümserleşme, bedbinleşme"
  },
  {
    "x": "xirabvînî (m)",
    "y": " kötümserlik, bedbinlik"
  },
  {
    "x": "xirabvînîye (m)",
    "y": " kötümserlik, bedbinlik"
  },
  {
    "x": "xirakerdiş (n)",
    "y": " b. xirabekerdiş (n)"
  },
  {
    "x": "xirar (m)",
    "y": " b. xirare (m) "
  },
  {
    "x": "xirare (m)",
    "y": " harar"
  },
  {
    "x": "xirari (m)",
    "y": " b. xirare (m) "
  },
  {
    "x": "xirav, -e",
    "y": " b. xirab, -e"
  },
  {
    "x": "xirav bîyayene",
    "y": " b. xirab bîyene"
  },
  {
    "x": "xirav bîyayêne",
    "y": " b. xirab bîyene"
  },
  {
    "x": "xirava (m)",
    "y": " b. xiraba (m)"
  },
  {
    "x": "xirave (n)",
    "y": " b. xirabe (n)"
  },
  {
    "x": "xirave bîyayene",
    "y": " b. xirabe bîyene"
  },
  {
    "x": "xirave bîyayeni",
    "y": " b. xirabe bîyene"
  },
  {
    "x": "xirave kerdene",
    "y": " b. xirabe kerdene"
  },
  {
    "x": "xirave kerdeni",
    "y": " b. xirabe kerdene"
  },
  {
    "x": "xiravebîyayîş (n)",
    "y": " b. xirabebîyayîş (n)"
  },
  {
    "x": "xiravekerdiş (n)",
    "y": " b. xirabekerdiş (n)"
  },
  {
    "x": "xiravên (m)",
    "y": " b. xirabênî (m)"
  },
  {
    "x": "xiravênî (m)",
    "y": " b. xirabênî (m)"
  },
  {
    "x": "xiravin, -e",
    "y": " b. xirab, -e"
  },
  {
    "x": "xiravinenî (m)",
    "y": " b. xirabênî (m)"
  },
  {
    "x": "xiravinênî (m)",
    "y": " b. xirabênî (m)"
  },
  {
    "x": "xiravinîye (m)",
    "y": " b. xirabênî (m)"
  },
  {
    "x": "xiravî (m)",
    "y": " b. xirabîye (m), xirabî (m)"
  },
  {
    "x": "xiravîye (m)",
    "y": " b. xirabîye (m), xirabî (m)"
  },
  {
    "x": "xiraw, -e",
    "y": " b. xirab, -e"
  },
  {
    "x": "xirawî (m)",
    "y": " b. xirabî (m)"
  },
  {
    "x": "xirawîye (m)",
    "y": " b. xirabîye (m), xirabî (m)"
  },
  {
    "x": "xirb (m)",
    "y": " b. xirbe (m)"
  },
  {
    "x": "xirbe (m)",
    "y": " yıkıntı, enkaz; harabe"
  },
  {
    "x": "Xird (n)",
    "y": " köpek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "xirefîyaya (m) ",
    "y": " b. xerifîyaya (m)"
  },
  {
    "x": "xirefîyaye (n)",
    "y": " b. xerifîyaye (n)"
  },
  {
    "x": "xirefîyayene",
    "y": " b. xerifîyayene"
  },
  {
    "x": "xirefîyayî, -ye",
    "y": " b. xerifîyayî, -ye"
  },
  {
    "x": "xirefîyayîş (n)",
    "y": " b. xerifîyayîş (n)xi"
  },
  {
    "x": "xirik (n)",
    "y": " çırpı (kuru dal parçası)"
  },
  {
    "x": "xirikî (zh)",
    "y": " çalı çırpı"
  },
  {
    "x": "xiripîyayîne",
    "y": " b. xeripîyayene"
  },
  {
    "x": "xiripîyayîş (n)",
    "y": " b. xeripîyayîş (n)"
  },
  {
    "x": "xiripnayene",
    "y": " b. xeripnayene"
  },
  {
    "x": "xiripnayîş (n) ",
    "y": " b. xeripnayîş (n)"
  },
  {
    "x": "xiristîyan, -e",
    "y": " b. xirîstîyan, -e"
  },
  {
    "x": "xiristîyaney",
    "y": " b. xirîstîyanîye (m), xirîstîyanî (m)"
  },
  {
    "x": "xiristîyanê",
    "y": " b. xirîstîyanîye (m), xirîstîyanî (m)"
  },
  {
    "x": "xiristîyanî (m)",
    "y": " b. xirîstîyanî (m)"
  },
  {
    "x": "xirîstîyan, -e",
    "y": " Hristiyan"
  },
  {
    "x": "xirîstîyaney",
    "y": " b. xirîstîyanîye (m), xirîstîyanî (m)"
  },
  {
    "x": "xirîstîyanê",
    "y": " b. xirîstîyanîye (m), xirîstîyanî (m)"
  },
  {
    "x": "xirîstîyanî (m)",
    "y": " Hristiyanlık"
  },
  {
    "x": "xirîstîyanîye (m)",
    "y": " Hristiyanlık"
  },
  {
    "x": "xirîstîyon, -i",
    "y": " b. xirîstîyan, -e"
  },
  {
    "x": "xirîstîyoney",
    "y": " b. xirîstîyanîye (m), xirîstîyanî (m)"
  },
  {
    "x": "xirîstîyonê",
    "y": " b. xirîstîyanîye (m), xirîstîyanî (m)"
  },
  {
    "x": "xirîstîyûn, -i ",
    "y": " b. xirîstîyan, -e"
  },
  {
    "x": "xirîstîyûnê",
    "y": " b. xirîstîyanîye (m), xirîstîyanî (m)"
  },
  {
    "x": "xirnik (m)",
    "y": " b. xirnike (m) "
  },
  {
    "x": "xirnike (m)",
    "y": " sümük"
  },
  {
    "x": "xirnikin, -e",
    "y": " sümüklü"
  },
  {
    "x": "xirok (n)",
    "y": " sonbaharda erken sürülen çift, güzlük çift"
  },
  {
    "x": "xirqe (n)",
    "y": " hırka"
  },
  {
    "x": "xirr (n)",
    "y": " b. xir (n)"
  },
  {
    "x": "xirripîyayîne",
    "y": " b. xeripîyayene"
  },
  {
    "x": "xirripîyayîş (n)",
    "y": " b. xeripîyayîş (n)"
  },
  {
    "x": "xirripnayene",
    "y": " b. Xeripnayene"
  },
  {
    "x": "xirripnayîş (n) ",
    "y": " b. xeripnayîş (n)"
  },
  {
    "x": "xirrok (n)",
    "y": " b. xirok (n)"
  },
  {
    "x": "xirt-I (n)",
    "y": " uçkurluk"
  },
  {
    "x": "xirtê doxîne (n)",
    "y": " uçkurluk"
  },
  {
    "x": "xirtê şilabendî (n)",
    "y": " uçkurluk"
  },
  {
    "x": "xirt, -e",
    "y": " b. xurt, -e"
  },
  {
    "x": "xirt bîyene",
    "y": " cesaretlenmek"
  },
  {
    "x": "xirtbîyayîş (n)",
    "y": " cesaretlenme"
  },
  {
    "x": "xirtik-I (n)",
    "y": " nefes borusu iltihabı, soluk borusu iltihabı"
  },
  {
    "x": "xirtik-II (n)",
    "y": " acur"
  },
  {
    "x": "xirtik-III (n)",
    "y": " pürüz"
  },
  {
    "x": "xirtikin, -e",
    "y": " pürüzlü"
  },
  {
    "x": "xirtîye (m)",
    "y": " b. xurtîye (m)"
  },
  {
    "x": "xirtûm (n)",
    "y": " b. xortume (m)"
  },
  {
    "x": "Xirvatîstan (n)",
    "y": " Hırvatistan "
  },
  {
    "x": "xirx (m)",
    "y": " xirxe (m)"
  },
  {
    "x": "xirxal (m)",
    "y": " b. xirxale (m)"
  },
  {
    "x": "xirxale (m)",
    "y": " halhal"
  },
  {
    "x": "Xirxale (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "xirxali (m)",
    "y": " b. xirxale (m)"
  },
  {
    "x": "xirxe (m)",
    "y": " taş"
  },
  {
    "x": "xirxe (n)",
    "y": " b. xirqe (n)"
  },
  {
    "x": "xirxi (n)",
    "y": " b. xirqe (n)"
  },
  {
    "x": "xirxil (n)",
    "y": " taşlık"
  },
  {
    "x": "xirxiz, -e",
    "y": " hırsız (b. dizd, -e)"
  },
  {
    "x": "xirxizi (m)",
    "y": " b. xirxize (m)"
  },
  {
    "x": "xisim, -e",
    "y": " akraba"
  },
  {
    "x": "xisimi (m)",
    "y": " b. xisime (m)"
  },
  {
    "x": "xisk (n)",
    "y": " b. xîsk (n)"
  },
  {
    "x": "xiskaene",
    "y": " b. xîskayene"
  },
  {
    "x": "xiskayene",
    "y": " b. xîskayene"
  },
  {
    "x": "xiskayîs (n)",
    "y": " b. xîskayîş (n)"
  },
  {
    "x": "xismete (m)",
    "y": " b. xizmet (n)"
  },
  {
    "x": "xisus (n)",
    "y": " b. xusus (n)"
  },
  {
    "x": "xisûs (n)",
    "y": " b. xusus (n)"
  },
  {
    "x": "xisûsî, –ye",
    "y": " b. xususî, -ye"
  },
  {
    "x": "xisûsîyet (n)",
    "y": " b. xususîyet (n)"
  },
  {
    "x": "xiş bîyene",
    "y": " b. xij bîyene"
  },
  {
    "x": "xişawî (m)",
    "y": " b. xoşawe (m)"
  },
  {
    "x": "xişbîyayîş (n)",
    "y": " b. xijbîyayîş (n)"
  },
  {
    "x": "xişike (m)",
    "y": " ufak taneli dolu"
  },
  {
    "x": "xişiki (m)",
    "y": " b. xişike (m)"
  },
  {
    "x": "xişin, -i",
    "y": " b. xişn, -e"
  },
  {
    "x": "xişîm, -e",
    "y": " b. xeşîm, -e"
  },
  {
    "x": "xişîmê (m)",
    "y": " b. xeşîmî (m)"
  },
  {
    "x": "xişîmî (m)",
    "y": " b. xeşîmî (m)"
  },
  {
    "x": "xişn, -e",
    "y": " büyük"
  },
  {
    "x": "xişn kardene",
    "y": " b. xişn kerdene"
  },
  {
    "x": "xişn kerdene",
    "y": " büyütmek"
  },
  {
    "x": "xişney",
    "y": " b. xişnîye (m)"
  },
  {
    "x": "xişnê (m)",
    "y": " b. xişnîye (m)"
  },
  {
    "x": "xişni (m)",
    "y": " b. xişne (m)"
  },
  {
    "x": "xişnîye (m)",
    "y": " büyüklük"
  },
  {
    "x": "xişt kerdene",
    "y": " b. xij kerdene"
  },
  {
    "x": "xiştkerdîs (n)",
    "y": " b. xijkerdiş (n)"
  },
  {
    "x": "xitim kerdene",
    "y": " hepsini okumak (kitap vb. için)"
  },
  {
    "x": "xitima Quranî (m)",
    "y": " Kur’an hatmi, Kur’an’ın tümüyle okunması"
  },
  {
    "x": "xitime (m)",
    "y": " Kur’an’ın tamamını okuma"
  },
  {
    "x": "xitimi (m)",
    "y": " b. xitime (m)"
  },
  {
    "x": "xitma Quranî (m)",
    "y": " b. xitima Quranî (m)"
  },
  {
    "x": "xitmey Quronî (m)",
    "y": " b. xitima Quranî (m)"
  },
  {
    "x": "xitmi (m)",
    "y": " b. xitime (m)"
  },
  {
    "x": "xivête (m)",
    "y": " çadır"
  },
  {
    "x": "xiwarde (n)",
    "y": " b. xwarde (m)"
  },
  {
    "x": "xiwardeyîn (m)",
    "y": " b. xwardeyî (m)"
  },
  {
    "x": "xiwer (m)",
    "y": " xebere (m)"
  },
  {
    "x": "xiyal (n)",
    "y": " b. xeyal (n)"
  },
  {
    "x": "xiyrê ...",
    "y": " b. xeyrê ..."
  },
  {
    "x": "xiyrî ...",
    "y": " b. xeyrê ..."
  },
  {
    "x": "xiz-I (n)",
    "y": " çelmik, kesmik (başakla karışık iri saman)"
  },
  {
    "x": "xiz-II (n)",
    "y": " b. xîz (n)"
  },
  {
    "x": "xiz-III (n) ",
    "y": " kısık (ses için), basık (ses için)"
  },
  {
    "x": "xiz bîyayene",
    "y": " b. xij bîyene"
  },
  {
    "x": "xiz bîyayêne",
    "y": " b. xij bîyene"
  },
  {
    "x": "xiz bîyene",
    "y": " b. xij bîyene"
  },
  {
    "x": "xiz kerdene",
    "y": " b. xij kerdene"
  },
  {
    "x": "xizag (n)",
    "y": " kızak"
  },
  {
    "x": "xizal-I (n)",
    "y": " kömür (odun kömürü)"
  },
  {
    "x": "xizal-II (n)",
    "y": " (yaprak için) gazel"
  },
  {
    "x": "xizal, -e-III",
    "y": " xezal, -e"
  },
  {
    "x": "xizalek (n)",
    "y": " kömür (odun kömürü)"
  },
  {
    "x": "xizali (m)",
    "y": " b. xezale (m)"
  },
  {
    "x": "xizalik (n)",
    "y": " b. xizalek (n)"
  },
  {
    "x": "xizan, -e",
    "y": " yoksul, fakir"
  },
  {
    "x": "xizaney (m)",
    "y": " b. xizanîye (m)"
  },
  {
    "x": "xizanênî (m)",
    "y": " yoksulluk, yokluk, fakirlik"
  },
  {
    "x": "xizanî (m)",
    "y": " yoksulluk, yokluk, fakirlik"
  },
  {
    "x": "xizanîn (m)",
    "y": " b. xizanênî (m), xizanîye (m)"
  },
  {
    "x": "xizanîye (m)",
    "y": " yoksulluk, yokluk, fakirlik"
  },
  {
    "x": "xizar (n)",
    "y": " bıçkı (büyük testere), hızar "
  },
  {
    "x": "xizbîyayîş (n)",
    "y": " b. xijbîyayîş (n)"
  },
  {
    "x": "xize (m)",
    "y": " b. xezîna (m)"
  },
  {
    "x": "xizelik (n)",
    "y": " b. xizalek (n)"
  },
  {
    "x": "xizêm (n)",
    "y": " b. xizme (m)"
  },
  {
    "x": "xizêmine (m)",
    "y": " b. xizmeyine (m)"
  },
  {
    "x": "xizi-I (m)",
    "y": " b. xizik-II (n)"
  },
  {
    "x": "xizi bîyayene",
    "y": " b. xizik bîyene"
  },
  {
    "x": "xizi-II (m)",
    "y": " b. xêze (m)"
  },
  {
    "x": "xizibîyayîş (n)",
    "y": " b. xizikbîyayîş (n)"
  },
  {
    "x": "xizik-I (n)",
    "y": " bakraç, küçük kova"
  },
  {
    "x": "xizik-II (n)",
    "y": " 1)kısık (ses için), basık (ses için)\r\n~2)ses kısıklığı"
  },
  {
    "x": "xizik bîyene",
    "y": " sesi kısılmak"
  },
  {
    "x": "xizikbîyayîş (n)",
    "y": " ses kısılması"
  },
  {
    "x": "xiziki (m)",
    "y": " b. xizik-II (n)"
  },
  {
    "x": "xiziki bîyayene",
    "y": " b. xizik bîyene"
  },
  {
    "x": "xizikibîyayîş (n)",
    "y": " b. xizikbîyayîş (n)"
  },
  {
    "x": "xizikîye (m)",
    "y": " basıklık (ses için) "
  },
  {
    "x": "xizimkar, -i",
    "y": " b. xizmetkar, -e"
  },
  {
    "x": "xizîna (m)",
    "y": " b. xezîna (m)"
  },
  {
    "x": "xizîne (m)",
    "y": " b. xezîna (m)"
  },
  {
    "x": "xizîye (m)",
    "y": " basıklık (ses için)"
  },
  {
    "x": "xizkerdîs (n)",
    "y": " b. xijkerdiş (n)"
  },
  {
    "x": "xizlixey (m)",
    "y": " b. xizlixîye (m)"
  },
  {
    "x": "xizlixîye (m)",
    "y": " kıtlık"
  },
  {
    "x": "xizma (m)",
    "y": " b. xizme (m)"
  },
  {
    "x": "xizmayine (m)",
    "y": " b. xizmeyine (m)"
  },
  {
    "x": "xizme (m)",
    "y": " hızma"
  },
  {
    "x": "xizmekar",
    "y": " b. xizmetkar, -e"
  },
  {
    "x": "xizmet (n)",
    "y": " hizmet"
  },
  {
    "x": "xizmetê kontrolî (zh)",
    "y": " denetim hizmetleri"
  },
  {
    "x": "xizmetê pêroyîyî (zh)",
    "y": " kamu hizmetleri"
  },
  {
    "x": "xizmetê teknîkîyî (zh)",
    "y": " teknik hizmetler"
  },
  {
    "x": "xizmetê umûmîya (zh)",
    "y": " kamu hizmetleri"
  },
  {
    "x": "xizmeto leşkerî (n)",
    "y": " askeri hizmet"
  },
  {
    "x": "sosyal hizmet",
    "y": " xizmeto sosyal (n)"
  },
  {
    "x": "xizmete (m)",
    "y": " b. xizmet (n)"
  },
  {
    "x": "xizmetkar, -e",
    "y": " hizmetçi"
  },
  {
    "x": "xizmetpeydakerdoxê Înternetî (n) (TE)",
    "y": " İnternet hizmet sağlayıcısı"
  },
  {
    "x": "xizmeyine (m)",
    "y": " hızmalı"
  },
  {
    "x": "xizmikar, -i",
    "y": " b. xizmetkar, -e"
  },
  {
    "x": "xizna (m)",
    "y": " b. xezîna (m)"
  },
  {
    "x": "xiznag, -e",
    "y": " b. xeznag, -e"
  },
  {
    "x": "xizne (m)",
    "y": " b. xezîna (m)"
  },
  {
    "x": "xizon",
    "y": " b. xizan, -e"
  },
  {
    "x": "xizonê (m)",
    "y": " b. xizanî (m)"
  },
  {
    "x": "xizonî (m)",
    "y": " b. xizanî (m)"
  },
  {
    "x": "xizûn",
    "y": " b. xizan, -e"
  },
  {
    "x": "xizûnê (m)",
    "y": " b. xizanî (m)"
  },
  {
    "x": "xîbet (n)",
    "y": " b. xîybet (n)"
  },
  {
    "x": "xîcelek (n)",
    "y": " b. xîçelek (n)"
  },
  {
    "x": "xîç (n)",
    "y": " çakıl"
  },
  {
    "x": "xîçe (m)",
    "y": " çatallı bir ağaç parçasının çatalları arasına yerleştirilip omuzda taşınan yük (çalı çırpı vb.) "
  },
  {
    "x": "xîçelek (n)",
    "y": " çakıllık"
  },
  {
    "x": "xîçin,-e",
    "y": " çakıllı"
  },
  {
    "x": "xîlafe (m)",
    "y": " yalan"
  },
  {
    "x": "xîlafe vatene",
    "y": " yalan söylemek"
  },
  {
    "x": "xîlafevatiş (n)",
    "y": " yalan söyleme"
  },
  {
    "x": "xîlafi (m)",
    "y": " b. xîlafe (m)"
  },
  {
    "x": "xîlafi vatene",
    "y": " b. xîlafe vatene"
  },
  {
    "x": "xîlafivatiş (n)",
    "y": " b. xîlafevatiş (n)"
  },
  {
    "x": "xînç (n)",
    "y": " b. xîç (n)"
  },
  {
    "x": "xînçer (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xînçi (m)",
    "y": " b. xîç (n)"
  },
  {
    "x": "xînçin, -i",
    "y": " b. xîçin,-e"
  },
  {
    "x": "xînt, -e",
    "y": " 1)sersem, alık\r\n~2)deli, çılgın"
  },
  {
    "x": "xînt bîyayene",
    "y": " b. xînt bîyene"
  },
  {
    "x": "xînt bîyene",
    "y": " 1)sersemleşmek, sersemlemek\r\n~2)delirmek, çıldırmak, çılgına dönmek, çılgınlaşma"
  },
  {
    "x": "xînt kerdene",
    "y": " 1)sersemletmek, sersem etmek\r\n~2)delirtmek, çıldırtmak"
  },
  {
    "x": "xîntane",
    "y": " delice, çılgınca"
  },
  {
    "x": "xîntbîyayîş (n)",
    "y": " çılgınlaşma, çıldırma, çıldırış, delirme, deliriş"
  },
  {
    "x": "xîntey (m)",
    "y": " b. xîntîye (m)"
  },
  {
    "x": "xîntê (m)",
    "y": " b. xîntî (m)"
  },
  {
    "x": "xîntî (m)",
    "y": " 1)sersemlik, alıklık\r\n~2)delilik"
  },
  {
    "x": "xîntîye (m)",
    "y": " 1)sersemlik, alıklık\r\n~2)delilik, çılgınlık"
  },
  {
    "x": "xîntîye kerdene",
    "y": " çılgınlık etmek"
  },
  {
    "x": "xîntkerdiş (n)",
    "y": " çıldırtma, delirtme"
  },
  {
    "x": "xînzîr, -e",
    "y": " b. xenzîr, -e"
  },
  {
    "x": "xîret (n)",
    "y": " ceht, çaba, gayret"
  },
  {
    "x": "xîret bîyene",
    "y": " çabalanmak"
  },
  {
    "x": "xîret kerdene",
    "y": " çabalamak, çaba göstermek, çaba harcamak, cehdetmek, gayret etmek, gayret göstermek"
  },
  {
    "x": "xîretbîyayîş (n)",
    "y": " çabalanma"
  },
  {
    "x": "xîretkar, -e",
    "y": " gayretli"
  },
  {
    "x": "xîretkarî (m)",
    "y": " gayretlilik"
  },
  {
    "x": "xîretkarîye (m)",
    "y": " gayretlilik"
  },
  {
    "x": "xîretkerdiş (n)",
    "y": " cehdetme, çabalama, gayret etme"
  },
  {
    "x": "xîretkêş, -e",
    "y": " gayretkeş, gayretli"
  },
  {
    "x": "xîretkêşî (m)",
    "y": " gayretlilik"
  },
  {
    "x": "xîretkêşîye (m)",
    "y": " gayretlilik"
  },
  {
    "x": "xîs (n)",
    "y": " b. xoz (n)"
  },
  {
    "x": "xîsk (n)",
    "y": " hıçkırık\r\n~Xaftela vake xîsk, kakilî kerdî war. (Aniden hıçkırıp gözyaşı döktü.)"
  },
  {
    "x": "xîska xo weameyene",
    "y": " hık tutmak"
  },
  {
    "x": "xîska xo weameyîne",
    "y": " b. xîska xo weameyene"
  },
  {
    "x": "xîskayene",
    "y": " hıçkırmak"
  },
  {
    "x": "xîskayîş (n)",
    "y": " hıçkırma"
  },
  {
    "x": "xîskxîska xo bîyene",
    "y": " hıçkırmak"
  },
  {
    "x": "xîskxîskaxobîyayîş (n)",
    "y": " hıçkırma"
  },
  {
    "x": "xîya",
    "y": " b. nix"
  },
  {
    "x": "xîya bîyayene",
    "y": " b. nix bîyene"
  },
  {
    "x": "xîya kerdene",
    "y": " b. nix kerdene"
  },
  {
    "x": "xîyabî",
    "y": " gıyabi\r\n~qerarê tewqîfî yo xîyabî (n): gıyabi tutuklama kararı, gıyabi tevkif kararı"
  },
  {
    "x": "qerarê tewqîfî yo xîyabî (n)",
    "y": " gıyabi tutuklama kararı, gıyabi tevkif kararı"
  },
  {
    "x": "xîyabîyayîş (n)",
    "y": " b. nixbîyayîş (n)"
  },
  {
    "x": "xîyal (n)",
    "y": " b. xeyal (n)"
  },
  {
    "x": "xîyale (n)",
    "y": " b. xayîle (n)"
  },
  {
    "x": "xîyanet (n)",
    "y": " hainlik, hıyanet, ihanet"
  },
  {
    "x": "xîyanet kerdene",
    "y": " hainlik etmek, haince davranmak, hıyanet etmek, ihanet etmek"
  },
  {
    "x": "xîyanetî reyde",
    "y": " hainlikle, hıyanetle, ihanetle"
  },
  {
    "x": "xîyanetî reydi",
    "y": " b. xîyanetî reyde"
  },
  {
    "x": "xîyanetî reyra",
    "y": " hainlikle, hıyanetle, ihanetle"
  },
  {
    "x": "xîyanetkerdiş (n)",
    "y": " hainlik etmek, haince davranma, hıyanet etme, ihanet etme"
  },
  {
    "x": "xîyar-I (n)",
    "y": " hıyar, salatalık"
  },
  {
    "x": "xîyar-II",
    "y": " b. nix"
  },
  {
    "x": "xîyar bîyayene",
    "y": " b. nix bîyene"
  },
  {
    "x": "xîyar kerdene",
    "y": " b. nix kerdene"
  },
  {
    "x": "xîyarbîyayîş (n)",
    "y": " b. nixbîyayîş (n)"
  },
  {
    "x": "xîyarek (n)",
    "y": " kabakulak"
  },
  {
    "x": "xîyarek girewtene",
    "y": " kabakulak olmak"
  },
  {
    "x": "xîyari (m)",
    "y": " b. xîyar (n)"
  },
  {
    "x": "xîyabet (n)",
    "y": " b. xîybet (n)"
  },
  {
    "x": "xîybet (n)",
    "y": " dedikodu"
  },
  {
    "x": "xîybet kerdene",
    "y": " çekiştirmek"
  },
  {
    "x": "xîybetkerdiş (n)",
    "y": " çekiştirme"
  },
  {
    "x": "xîyç (m)",
    "y": " b. xîç (n)"
  },
  {
    "x": "xîyçin",
    "y": " b. xîçin,-e"
  },
  {
    "x": "xîyret (n)",
    "y": " b. xîret (n)"
  },
  {
    "x": "xîyret kerdene",
    "y": " b. xîret kerdene"
  },
  {
    "x": "xîyretkerdiş (n)",
    "y": " b. xîretkerdiş (n)"
  },
  {
    "x": "xîyretkêş, -e",
    "y": " b. xîretkêş, -e"
  },
  {
    "x": "xîyretkêşî (m)",
    "y": " b. xîretkêşî (m)"
  },
  {
    "x": "xîz (n)",
    "y": " kum"
  },
  {
    "x": "xîzar (n)",
    "y": " b. xizar (n)"
  },
  {
    "x": "xîzikoke (m)",
    "y": " kayak, ski"
  },
  {
    "x": "xîzikyayene",
    "y": " b. xijikîyayene"
  },
  {
    "x": "xîzikyayeni",
    "y": " b. xijikîyayene"
  },
  {
    "x": "xîzikyayîş (n)",
    "y": " b. xijikîyayîş (n)"
  },
  {
    "x": "xîzin, -e",
    "y": " kumlu, kumsal"
  },
  {
    "x": "xîzîke (m)",
    "y": " b. xizik-I (n)"
  },
  {
    "x": "xîzon",
    "y": " b. xizan, -e"
  },
  {
    "x": "xîzonê (m)",
    "y": " b. xizanî (m)"
  },
  {
    "x": "xo-I",
    "y": " kendi"
  },
  {
    "x": "xo amayene",
    "y": " b. xo ameyene"
  },
  {
    "x": "xo ameyene",
    "y": " iyileşmek"
  },
  {
    "x": "xo ameyîne",
    "y": " b. xo ameyene"
  },
  {
    "x": "xo antene yewbînî",
    "y": " çekişmek (dövüşmek için çekişmek)"
  },
  {
    "x": "xo aqilîyayene",
    "y": " b. xo arqilîyayene"
  },
  {
    "x": "xo aqilnayene",
    "y": " b. xo arqilnayene"
  },
  {
    "x": "xo arqilîyayene",
    "y": " farkına varmak"
  },
  {
    "x": "xo arqilnayene",
    "y": " farkına vardırmak"
  },
  {
    "x": "xo bane kerdene",
    "y": " soyunmak"
  },
  {
    "x": "xo bêkes dîyene",
    "y": " garipsemek"
  },
  {
    "x": "xo bi xo qalî kerdene",
    "y": " söylenmek (kendi kendine konuşmak)"
  },
  {
    "x": "xo bi xo qisey kerdene",
    "y": " söylenmek (kendi kendine konuşmak)"
  },
  {
    "x": "xo bi xo vatene",
    "y": " söylenmek (kendi kendine konuşmak)"
  },
  {
    "x": "xo bi xo xeberî dayene",
    "y": " söylenmek (kendi kendine konuşmak)"
  },
  {
    "x": "xo bi xo xebêr dayene",
    "y": " b. xo bi xo xeberî dayene"
  },
  {
    "x": "xo cêde vetene",
    "y": " soyunmak"
  },
  {
    "x": "xo cêdi vetene",
    "y": " b. xo cêde vetene"
  },
  {
    "x": "xo çim a kerdene",
    "y": " göze almak"
  },
  {
    "x": "xo ... daritene",
    "y": " dayanmak (yaslanmak, kendini dayamak)\r\n~Xo dêsî darit. (Duvara dayandı/yaslandı.)\t"
  },
  {
    "x": "xo ... daritiş",
    "y": " dayanma (yaslanma, kendini dayama)"
  },
  {
    "x": "xo dayene dest",
    "y": " teslim olmak"
  },
  {
    "x": "xo dayene dest û linganê ...",
    "y": " ayağına kapanmak, ayaklarına kapanmak"
  },
  {
    "x": "xo dayene tanî ver",
    "y": " ısınmak, kendini ısıtmak (ısınmak)"
  },
  {
    "x": "xo dayene tayn ver",
    "y": " b. xo dayene tanî ver"
  },
  {
    "x": "xo dayene ... ver",
    "y": " yüklenmek (üstlenmek)"
  },
  {
    "x": "xo de kerdene",
    "y": " atıştırmak"
  },
  {
    "x": "xo dekerdene",
    "y": " koşmak"
  },
  {
    "x": "xo dest de",
    "y": " dinç, zinde"
  },
  {
    "x": "xo dest de bîyene",
    "y": " dinç olmak, zinde olmak\r\n~Sînaye kokim bibi labelê xo dest de bi. (Sînaye yaşlanmıştı ama dinçti.)"
  },
  {
    "x": "xo eştene dest û linganê ...",
    "y": " ayağına kapanmak, ayaklarına kapanmak"
  },
  {
    "x": "xo fek a kerdene",
    "y": " b. xo fek ra kerdene"
  },
  {
    "x": "xo fek a nayene",
    "y": " b. xo fek ra nayene"
  },
  {
    "x": "xo fek de çarnayene",
    "y": " gevelemek (söz için), ağzında gevelemek"
  },
  {
    "x": "xo fek de gebexnayene",
    "y": " gevelemek (söz için), ağzında gevelemek"
  },
  {
    "x": "xo fek de gebixnayene",
    "y": " b. xo fek de gebexnayene"
  },
  {
    "x": "xo fek de gewexnayene",
    "y": " b. xo fek de gebexnayene"
  },
  {
    "x": "xo fek de pilayene",
    "y": " gevelemek (söz için)"
  },
  {
    "x": "xo fek kerdene",
    "y": " b. xo fek ra kerdene"
  },
  {
    "x": "xo fek ra feletnayene",
    "y": " ağzından kaçırmak"
  },
  {
    "x": "xo fek ra kerdene",
    "y": " (kendi) ağzına koymak, atıştırmak"
  },
  {
    "x": "xo fek ra nayene",
    "y": " (kendi) ağzına koymak"
  },
  {
    "x": "xo fek ra pernayene",
    "y": " ağzından kaçırmak"
  },
  {
    "x": "xo fek ra remnayene",
    "y": " ağzından kaçırmak"
  },
  {
    "x": "xo fek ra reynayene",
    "y": " ağzından kaçırmak"
  },
  {
    "x": "xo fek ra vetene",
    "y": " ağzından çıkarmak (söz vb. için)"
  },
  {
    "x": "xo fek te çarnayene",
    "y": " b. xo fek de çarnayene"
  },
  {
    "x": "xo fek te pilayene",
    "y": " b. xo fek de pilayene"
  },
  {
    "x": "xo feke ra rînayene",
    "y": " b. xo fek ra reynayene"
  },
  {
    "x": "xo germ kerdene",
    "y": " ısınmak, kendini ısıtmak (ısınmak)"
  },
  {
    "x": "xo germin kerdene",
    "y": " ısınmak, kendini ısıtmak (ısınmak)"
  },
  {
    "x": "xo gevez dayene",
    "y": " b. xo geviz dayene"
  },
  {
    "x": "xo geveznayîne",
    "y": " b. xo geviznayene"
  },
  {
    "x": "xo geviz dayene",
    "y": " ağınmak"
  },
  {
    "x": "xo geviznayene",
    "y": " ağınmak"
  },
  {
    "x": "xo giranî nayene",
    "y": " ağırdan almak"
  },
  {
    "x": "xo heremnayene",
    "y": " b. xo herimnayene"
  },
  {
    "x": "xo herimnayene",
    "y": " 1)üstünü başını kirletmek, kendini kirletmek\r\n~2)altına etmek, altını kirletmek"
  },
  {
    "x": "xo hesîyayene",
    "y": " farkına varmak"
  },
  {
    "x": "xo hesnayene",
    "y": " farkına vardırmak"
  },
  {
    "x": "xo kerdene",
    "y": " çekinmek"
  },
  {
    "x": "xo lingî kerdene",
    "y": " b. xo lingî ra kerdene "
  },
  {
    "x": "xo lingî ra kerdene",
    "y": " giymek (ayakkabı, pantolon vb. için)"
  },
  {
    "x": "xo maraqay kerdene",
    "y": " b. xo meriqî kerdene"
  },
  {
    "x": "xo marqe dayene",
    "y": " b. xo meriqî dayene"
  },
  {
    "x": "xo marqê kerdene",
    "y": " b. xo meriqî kerdene"
  },
  {
    "x": "xo marqî kerdene",
    "y": " b. xo meriqî kerdene"
  },
  {
    "x": "xo meqle kerdene",
    "y": " b. xo meriqî kerdene"
  },
  {
    "x": "xo mereqanî dayene",
    "y": " b. xo meriqanî dayene"
  },
  {
    "x": "xo mereqanî kerdene",
    "y": " b. xo meriqanî kerdene"
  },
  {
    "x": "xo mereqey dayene",
    "y": " b. xo meriqî dayene"
  },
  {
    "x": "xo mereqî kerdene",
    "y": " b. xo meriqî kerdene"
  },
  {
    "x": "xo mereqnayîne",
    "y": " b. xo meriqnayene"
  },
  {
    "x": "xo meriqanî dayene",
    "y": " ağınmak"
  },
  {
    "x": "xo meriqanî kerdene",
    "y": " ağınmak"
  },
  {
    "x": "xo meriqî dayene",
    "y": " ağınmak"
  },
  {
    "x": "xo meriqî kerdene",
    "y": " ağınmak"
  },
  {
    "x": "xo meriqnayene",
    "y": " ağınmak"
  },
  {
    "x": "xo merqey dayene",
    "y": " b. xo meriqî dayene"
  },
  {
    "x": "xo mêra bestene",
    "y": " b. xo mîyane ra bestene "
  },
  {
    "x": "xo mêra dayene",
    "y": " b. xo mîyane ra dayene"
  },
  {
    "x": "xo milqî dayene",
    "y": " b. xo meriqî dayene"
  },
  {
    "x": "xo milqî kerdene",
    "y": " b. xo meriqî kerdene"
  },
  {
    "x": "xo milqnayeni",
    "y": " b. xo meriqnayene"
  },
  {
    "x": "xo mîyane ra bestene",
    "y": " kuşanmak (kuşak, fişeklik vb. için)"
  },
  {
    "x": "xo mîyane ra dayene",
    "y": " kuşanmak (kuşak, fişeklik vb. için)"
  },
  {
    "x": "xo mîyane sanayene",
    "y": " b. xo mîyane şanayene"
  },
  {
    "x": "xo mîyane şanayene",
    "y": " kuşanmak (kuşak, fişeklik vb. için)"
  },
  {
    "x": "xo mîye sanayene",
    "y": " b. xo mîyane şanayene"
  },
  {
    "x": "xo mîye sanayêne",
    "y": " b. xo mîyane şanayene"
  },
  {
    "x": "xo nayene",
    "y": " b. ro xo nayene"
  },
  {
    "x": "xo pay kerdene",
    "y": " giymek (ayakkabı, pantolon vb. için)\r\n~Solê xo xo pay kerdî û şi. (Ayakkabılarını giydi ve gitti.)"
  },
  {
    "x": "xo pêkirantene",
    "y": " çekişmek (dövüşmek için çekişmek)"
  },
  {
    "x": "xo pêşanayene",
    "y": " bitişmek"
  },
  {
    "x": "xo pêt kerdene",
    "y": " cesaretini toplamak"
  },
  {
    "x": "xo pîze de vatene",
    "y": " b. pîzeyê xo de vatene"
  },
  {
    "x": "xo ra",
    "y": " kendinden, kendisinden"
  },
  {
    "x": "xo ra cêr dîyene",
    "y": " aşağılamak"
  },
  {
    "x": "xo ra cidayene",
    "y": " alışmak"
  },
  {
    "x": "xo ra dayene",
    "y": " giymek (giysi için)\r\n~\t\tXo ra de! (Giy!)"
  },
  {
    "x": "xo ra girewtene",
    "y": " giymek (giysi için)"
  },
  {
    "x": "xo ra şîyayene",
    "y": " b. xow ra şîyene"
  },
  {
    "x": "xo ra şîyayêne",
    "y": " b. xo ra şîyene"
  },
  {
    "x": "xo ra şîyene",
    "y": " 1)bayılmak \r\n~2)b. xow ra şîyene"
  },
  {
    "x": "xo raantene",
    "y": " çekişmek (dövüşmek için çekişmek)"
  },
  {
    "x": "xo ragirewtene",
    "y": " dayanmak (tahammül etmek, sabretmek)"
  },
  {
    "x": "xo raontene",
    "y": " b. xo raantene"
  },
  {
    "x": "xo rê derd kerdene",
    "y": " dert edinmek, dert etmek"
  },
  {
    "x": "xo rê mal kerdene",
    "y": " benimsemek, kendine mal etmek"
  },
  {
    "x": "xo ro kerdene",
    "y": " 1)giymek (giysi için)\r\n~2)(kendi) üstüne dökmek\r\n~3)altına etmek, altını kirletmek\r\n~4)b. xo ser ro kerdene"
  },
  {
    "x": "xo ro rijnayene",
    "y": " (kendi) üstüne dökmek"
  },
  {
    "x": "xo ronayene",
    "y": " diz çökmek"
  },
  {
    "x": "xo rut kerdene",
    "y": " soyunmak"
  },
  {
    "x": "xo sanayene ...",
    "y": " b. xo şanayene ..."
  },
  {
    "x": "xo ... sanayene",
    "y": " b. xo ... şanayene"
  },
  {
    "x": "xo ... sanayîne",
    "y": " b. xo ... şanayene"
  },
  {
    "x": "xo ... sanayîş",
    "y": " b. xo ... şanayîş"
  },
  {
    "x": "xo sentene",
    "y": " tartılmak"
  },
  {
    "x": "xo ... ser daritene",
    "y": " -nin üzerine abanmak"
  },
  {
    "x": "xo ... ser daritiş",
    "y": " -nin üzerine abanma"
  },
  {
    "x": "xo ser girewtene",
    "y": " taahhüt etmek"
  },
  {
    "x": "xo ser nayene",
    "y": " giymek (şapka, külah vb. için)"
  },
  {
    "x": "xo ser ro kerdene",
    "y": " aşırı harcamak (mecazi)"
  },
  {
    "x": "xo setene",
    "y": " b. xo sentene"
  },
  {
    "x": "xo şanayene ...",
    "y": " dayanmak (yaslanmak, kendini dayamak)"
  },
  {
    "x": "xo şanayene ... ver",
    "y": " yüklenmek (üstlenmek)\r\n~Çi kes xo nêşaneno nê wezîfeyan ver. (Hiç kimse bu görevleri yüklenmez.)"
  },
  {
    "x": "xo ... şanayîş",
    "y": " dayanma (yaslanma, kendini dayama)"
  },
  {
    "x": "xo şelenayene",
    "y": " b. xo şelênayene"
  },
  {
    "x": "xo şelênayene",
    "y": " soyunmak"
  },
  {
    "x": "xo şellênayene",
    "y": " b. xo şelênayene"
  },
  {
    "x": "xo şilnayene",
    "y": " b. xo şelênayene"
  },
  {
    "x": "xo şutene",
    "y": " banyo yapmak, banyo almak, yıkanmak"
  },
  {
    "x": "xo tepêştene",
    "y": " b. xo tepîştene"
  },
  {
    "x": "xo tepîştene",
    "y": " dayanmak (tahammül etmek, sabretmek), tahammül etmek"
  },
  {
    "x": "xo têbestene",
    "y": " giyinip kuşanmak, giyinmek"
  },
  {
    "x": "xo têdayene",
    "y": " b. xo têwdayene"
  },
  {
    "x": "xo têpiringnayene",
    "y": " büzülmek"
  },
  {
    "x": "xo têrapîstene",
    "y": " b. xo têrapîştene"
  },
  {
    "x": "xo têrapiştene",
    "y": " b. xo têrapîştene"
  },
  {
    "x": "xo têrapîştene",
    "y": " giyinmek"
  },
  {
    "x": "xo têvdayene",
    "y": " b. xo têwdayene"
  },
  {
    "x": "xo têwdayene",
    "y": " hareket etmek, kımıldamak, kımıldanmak, deprenmek"
  },
  {
    "x": "xo têwo dayene",
    "y": " b. xo têwdayene"
  },
  {
    "x": "xo tîtevît kerdene",
    "y": " b. xo tîtûvît kerdene"
  },
  {
    "x": "xo tîtiknayene",
    "y": " süslenmek (kendi kendini süslemek)"
  },
  {
    "x": "xo tîtovît kerdene",
    "y": " b. xo tîtûvît kerdene"
  },
  {
    "x": "xo tîtûvît kerdene",
    "y": " süslenmek (kendi kendini süslemek)"
  },
  {
    "x": "xo tîyo dayîne",
    "y": " b. xo têwdayene"
  },
  {
    "x": "xo varar girewtiş",
    "y": " b. xo verare girewtene"
  },
  {
    "x": "xo ver dayene",
    "y": " 1)direnmek\r\n~2)çabalamak, çaba göstermek, çaba harcamak, cehdetmek, gayret etmek, gayret göstermek"
  },
  {
    "x": "xo ver eştene",
    "y": " direnmek"
  },
  {
    "x": "xo ver kewtene",
    "y": " utanmak"
  },
  {
    "x": "xo ver o dayene",
    "y": " b. xo ver ro dayene"
  },
  {
    "x": "xo ver ro dayene",
    "y": " 1)direnmek\r\n~Mêrikî sey camêrdan xo ver ro da. (Adam yiğitçe direndi.) \r\n~2)çabalamak, çaba göstermek, çaba harcamak, cehdetmek, gayret etmek, gayret göstermek"
  },
  {
    "x": "xo ver ro ewnîyayene",
    "y": " utanmak (mecazi)"
  },
  {
    "x": "xo vera girewtene",
    "y": " kucaklamak, kucağına almak"
  },
  {
    "x": "xo vera nayene",
    "y": " b. xo verike nayene"
  },
  {
    "x": "xo verar kerdene",
    "y": " b. xo verare kerdene"
  },
  {
    "x": "xo verard kerdene",
    "y": " b. xo verardî kerdene"
  },
  {
    "x": "xo verardî kerdene",
    "y": " kucaklamak\r\n~Qicê xo kerd verardîya xo. (Çocuğunu kucakladı.)"
  },
  {
    "x": "xo verare girewtene",
    "y": " kucağına almak"
  },
  {
    "x": "xo verare kerdene",
    "y": " kucaklamak\r\n~Lajê xo kerd verara xo./Lajê xo kerd xo verare. (Oğlunu kucakladı)"
  },
  {
    "x": "xo verike girewtene",
    "y": " kucağına almak\r\n~Mizgîne lajê xo girewt verika xo./Mizgîne lajê xo girewt xo verike. (Mızgine oğlunu kucağına aldı.)"
  },
  {
    "x": "xo verike kerdene",
    "y": " kucaklamak\r\n~Gede kerd verika xo./Gede xo verike kerd. (Çocuğu kucakladı.)\t "
  },
  {
    "x": "xo verike nayene",
    "y": "kucağına almak\r\n~Şorişî lajê xo na verika xo./Şorişî lajê xo xo verike na. (Şorış oğlunu kucağına aldı.)"
  },
  {
    "x": "xo virane girewtene",
    "y": " kucağına almak"
  },
  {
    "x": "xo virane kerdene",
    "y": " kucaklamak "
  },
  {
    "x": "xo virare girewtene",
    "y": " b. xo verare girewtene"
  },
  {
    "x": "xo virare kerdene",
    "y": " b. xo verare kerdene"
  },
  {
    "x": "xo virari girotiş",
    "y": " b. xo verare girewtene "
  },
  {
    "x": "xo virari kerdene",
    "y": " b. xo verare kerdene"
  },
  {
    "x": "xo virarî guretene",
    "y": " b. xo verare girewtene"
  },
  {
    "x": "xo viraştene",
    "y": " makyaj yapmak"
  },
  {
    "x": "xo vîr a kerdene",
    "y": " b. xo vîr ra kerdene"
  },
  {
    "x": "xo vîr ardene",
    "y": " b. ardene vîrê xo"
  },
  {
    "x": "xo vîr ra kerdene",
    "y": " unutmak\r\n~Mi xo vîr ra kerd, to ra nêvat. (Unuttum, sana söylemedim.)"
  },
  {
    "x": "xo vîrî ardene",
    "y": " b. ardene vîrê xo"
  },
  {
    "x": "xo vîrî kerdene",
    "y": " b. xo vîr ra kerdene"
  },
  {
    "x": "xo vîrî ra kerdene",
    "y": " b. xo vîr ra kerdene"
  },
  {
    "x": "xo xapênayene",
    "y": " oyalanmak"
  },
  {
    "x": "xo xemelnayene",
    "y": " b. xo xemilnayene"
  },
  {
    "x": "xo xemilnayene",
    "y": " süslenmek (kendi kendini süslemek)"
  },
  {
    "x": "xo xerepnayene",
    "y": " b. xo xeripnayene"
  },
  {
    "x": "xo xeripnayene",
    "y": " 1)üstünü başını kirletmek\r\n~2)altına etmek, altını kirletmek"
  },
  {
    "x": "xo xerîb dîyene",
    "y": " garipsemek"
  },
  {
    "x": "xo xic kerdene",
    "y": " b. xo xij kerdene"
  },
  {
    "x": "xo xij kerdene",
    "y": " kaymak"
  },
  {
    "x": "xo xiz kerdene",
    "y": " b. xo xij kerdene"
  },
  {
    "x": "xo xo de qesi kerdene",
    "y": " b. xo xo de qisey kerdene"
  },
  {
    "x": "xo xo de qisey kerdene",
    "y": " söylenmek (kendi kendine konuşmak)"
  },
  {
    "x": "xo yew kerdene",
    "y": " birleşmek"
  },
  {
    "x": "xo zeft nêkerdene",
    "y": " çılgına dönmek"
  },
  {
    "x": "xo zere de vatene",
    "y": " b. zereyê xo de vatene"
  },
  {
    "x": "xo zit kerdene",
    "y": " soyunmak"
  },
  {
    "x": "xo-II (n)",
    "y": " b. xow (n)"
  },
  {
    "x": "xo ra şîyayene",
    "y": " b. xow ra şîyene"
  },
  {
    "x": "xo ra şîyene",
    "y": " b. xow ra şîyene"
  },
  {
    "x": "xoameyîş (n)",
    "y": " iyileşme, sağalma"
  },
  {
    "x": "xoantiş yewbînî",
    "y": " çekişme (dövüşmek için çekişme)"
  },
  {
    "x": "xoaqilîyayîş (n)",
    "y": " b. xoarqilîyayîş (n)"
  },
  {
    "x": "xoaqilnayîş (n)",
    "y": " b. xoarqilnayîş (n)"
  },
  {
    "x": "xoarqilîyayîş (n)",
    "y": " farkına varma"
  },
  {
    "x": "xoarqilnayîş (n)",
    "y": " farkına vardırma"
  },
  {
    "x": "xoawguznayîş (n)",
    "y": " özarıtım"
  },
  {
    "x": "xobanekerdiş (n)",
    "y": " soyunma"
  },
  {
    "x": "xobêkesdîyayîş (n)",
    "y": " garipseme "
  },
  {
    "x": "xobixoqalîkerdiş (n)",
    "y": " söylenme (kendi kendine konuşma)"
  },
  {
    "x": "xobixoqiseykerdiş (n)",
    "y": " söylenme (kendi kendine konuşma)"
  },
  {
    "x": "xobixovatiş (n)",
    "y": " söylenme (kendi kendine konuşma)"
  },
  {
    "x": "xobixoxeberîdayîş (n)",
    "y": " söylenme (kendi kendine konuşma)"
  },
  {
    "x": "xobixoxebêrdayîş (n)",
    "y": " b. xobixoxeberîdayîş (n)"
  },
  {
    "x": "xoca (n, m)",
    "y": " hoca"
  },
  {
    "x": "xocaya dînî (m)",
    "y": " din hocası"
  },
  {
    "x": "xocayê dînî (n)",
    "y": " din hocası"
  },
  {
    "x": "xoce (n, m)",
    "y": " b. xoca (n, m)"
  },
  {
    "x": "xocêdevetiş (n)",
    "y": " soyunma"
  },
  {
    "x": "xocêdivetiş (n)",
    "y": " b. xocêdevetiş (n)"
  },
  {
    "x": "xoçimakerdiş (n)",
    "y": " göze alma"
  },
  {
    "x": "xodayîs tayn ver",
    "y": " b. xodayîş tanî ver"
  },
  {
    "x": "xodayîş tanî ver",
    "y": " ısınma"
  },
  {
    "x": "xodayîş ... ver",
    "y": " yüklenme (üstlenme)"
  },
  {
    "x": "xodayîşdest (n)",
    "y": " teslim olma, teslimiyet"
  },
  {
    "x": "xodekerdiş-I (n)",
    "y": " atıştırma"
  },
  {
    "x": "xodekerdiş-II (n)",
    "y": " koşma"
  },
  {
    "x": "xoera",
    "y": " b. xora"
  },
  {
    "x": "xoeştiş (n)",
    "y": " atlama (uzun atlama)"
  },
  {
    "x": "xoewnîyaya (m)",
    "y": " bakımlı"
  },
  {
    "x": "xoewnîyaye (n)",
    "y": " bakımlı"
  },
  {
    "x": "xoewnîyayeyîye (m)",
    "y": " bakımlılık"
  },
  {
    "x": "xoewnîyayox, -e",
    "y": " bakımlı"
  },
  {
    "x": "xoewnîyayoxîye (m)",
    "y": " bakımlılık"
  },
  {
    "x": "xof kerdene",
    "y": " b. xewf kerdene"
  },
  {
    "x": "xofekakerdiş (n)",
    "y": " b. xofekrakerdiş (n)"
  },
  {
    "x": "xofekdeçarnayîş (n)",
    "y": " geveleme (söz için)"
  },
  {
    "x": "xofekdegebexnayîş (n)",
    "y": " geveleme (söz için)"
  },
  {
    "x": "xofekdegebixnayîş (n)",
    "y": " b. xofekdegebexnayîş (n)"
  },
  {
    "x": "xofekdegewexnayîş (n)",
    "y": " b. xofekdegebexnayîş (n)"
  },
  {
    "x": "xofekdepilayîş (n)",
    "y": " geveleme (söz için)"
  },
  {
    "x": "xofekkerdiş (n)",
    "y": " b. xofekrakerdiş (n)"
  },
  {
    "x": "xofekrafeletnayîş (n)",
    "y": " ağzından kaçırma"
  },
  {
    "x": "xofekrakerdiş (n)",
    "y": " atıştırma"
  },
  {
    "x": "xofekrapernayîş (n)",
    "y": " ağzından kaçırma"
  },
  {
    "x": "xofekraremnayîş (n)",
    "y": " ağzından kaçırma"
  },
  {
    "x": "xofekravetiş (n)",
    "y": " ağzından çıkarma (söz vb. için)"
  },
  {
    "x": "xofekteçarnayîş (n)",
    "y": " b. xofekdeçarnayîş (n)"
  },
  {
    "x": "xofektepilayîş (n)",
    "y": " b. xofekdepilayîş (n)"
  },
  {
    "x": "xogerminkerdiş (n)",
    "y": " ısınma"
  },
  {
    "x": "xogermkerdiş (n)",
    "y": " ısınma"
  },
  {
    "x": "xogevezdayîş (n)",
    "y": " b. xogevizdayîş"
  },
  {
    "x": "xogeveznayîş (n)",
    "y": " b. xogeviznayîş (n)"
  },
  {
    "x": "xogevizdayîş (n)",
    "y": " ağınma"
  },
  {
    "x": "xogeviznayîş (n)",
    "y": " ağınma"
  },
  {
    "x": "xoheremnayîş (n)",
    "y": " b. xoherimnayîş (n)"
  },
  {
    "x": "xoherimnayîş (n)",
    "y": " 1)üstünü başını kirletme, kendini kirletme\r\n~2)altına etme, altını kirletme"
  },
  {
    "x": "xohesîyayîş (n)",
    "y": " farkına varma"
  },
  {
    "x": "xohesnayîş (n)",
    "y": " farkına vardırma"
  },
  {
    "x": "xoja (n)",
    "y": " b. xoca (n, m)"
  },
  {
    "x": "xojî",
    "y": " b. xwezî"
  },
  {
    "x": "xokerdiş (n)",
    "y": " çekinme"
  },
  {
    "x": "xolingîkerdiş (n)",
    "y": " b. xolingîrakerdiş (n)"
  },
  {
    "x": "xolingîrakerdiş (n)",
    "y": " giyme (ayakkabı, pantolon vb. için)"
  },
  {
    "x": "xolî (m)",
    "y": " b. xewlî (m)"
  },
  {
    "x": "xom, -i",
    "y": " b. xam, -e"
  },
  {
    "x": "xoma",
    "y": " b. xora"
  },
  {
    "x": "xoma ra",
    "y": " b. xora"
  },
  {
    "x": "xomar (n)",
    "y": " b. qumar (n)"
  },
  {
    "x": "xomaraqaykerdiş (n)",
    "y": " b. xomeriqîkerdiş (n)"
  },
  {
    "x": "xomarbaz, -e",
    "y": " b. qumarbaz, -e"
  },
  {
    "x": "xomarqedayîş (n)",
    "y": " b. xomeriqîdayîş (n)"
  },
  {
    "x": "xomarqêkerdiş (n)",
    "y": " b. xomeriqîkerdiş (n)"
  },
  {
    "x": "xomarqîkerdiş (n)",
    "y": " b. xomeriqîkerdiş (n)"
  },
  {
    "x": "xomeqlekerdiş (n)",
    "y": " b. xomeriqîkerdiş (n)"
  },
  {
    "x": "xomereqanîdayîş (n)",
    "y": " b. xomeriqanîdayîş (n)"
  },
  {
    "x": "xomereqanîkerdiş (n)",
    "y": " b. xomeriqanîkerdiş (n)"
  },
  {
    "x": "xomereqeydayîş (n)",
    "y": " b. xomeriqîdayîş (n)"
  },
  {
    "x": "xomereqîkerdiş (n)",
    "y": " b. xomeriqîkerdiş (n)"
  },
  {
    "x": "xomereqnayîş (n)",
    "y": " b. xomeriqnayîş (n)"
  },
  {
    "x": "xomeriqanîdayîş (n)",
    "y": " ağınma"
  },
  {
    "x": "xomeriqanîkerdiş (n)",
    "y": " ağınma"
  },
  {
    "x": "xomeriqîdayîş (n)",
    "y": " ağınma"
  },
  {
    "x": "xomeriqîkerdiş (n)",
    "y": " ağınma"
  },
  {
    "x": "xomeriqnayîş (n)",
    "y": " ağınma"
  },
  {
    "x": "xomerkezî, -ye",
    "y": " beniçinci¸ benmerkezci, egosantrist"
  },
  {
    "x": "xomerkezîyîye (m)",
    "y": " beniçincilik, benmerkezcilik, egosantrizm"
  },
  {
    "x": "xomerqeydayîş (n)",
    "y": " b. xomeriqîdayîş (n)"
  },
  {
    "x": "xomêrabestiş (n)",
    "y": " b. xomîyanerabestiş (n)"
  },
  {
    "x": "xomêradayîş (n)",
    "y": " b. xomîyaneradayîş (n)"
  },
  {
    "x": "xomilqîdayîs (n)",
    "y": " b. xomeriqîdayîş (n)"
  },
  {
    "x": "xomilqîdayîş (n)",
    "y": " b. xomeriqîdayîş (n)"
  },
  {
    "x": "xomilqîkerdis (n)",
    "y": " b. xomeriqîkerdiş (n)"
  },
  {
    "x": "xomilqnayîş (n)",
    "y": " b. xomeriqnayîş (n)"
  },
  {
    "x": "xomîyanerabestiş (n)",
    "y": " kuşanma (kuşak, fişeklik vb. için) "
  },
  {
    "x": "xomîyaneradayîş (n)",
    "y": " kuşanma (kuşak, fişeklik vb. için) "
  },
  {
    "x": "xomîyanesanayîş (n)",
    "y": " b. xomîyaneşanayîş (n)"
  },
  {
    "x": "xomîyaneşanayîş (n)",
    "y": " kuşanma (kuşak, fişeklik vb. için) "
  },
  {
    "x": "xomîyesanayîş (n)",
    "y": " b. xomîyaneşanayîş (n)"
  },
  {
    "x": "xonaz, -e",
    "y": " b. hunaz, -e"
  },
  {
    "x": "xonc, -e",
    "y": " b. xoz, -e"
  },
  {
    "x": "xonça (m)",
    "y": " küçük yemek masası"
  },
  {
    "x": "xonçerî (m)",
    "y": " b. xencer (n)"
  },
  {
    "x": "xonçik (m) ",
    "y": " b. xonçike (m)"
  },
  {
    "x": "xonçike (m)",
    "y": " küçük yemek masası"
  },
  {
    "x": "xone (n)",
    "y": " erkek kedi"
  },
  {
    "x": "xonim (m)",
    "y": " b. xanime (m)"
  },
  {
    "x": "xonimi (m)",
    "y": " b. xanime (m)"
  },
  {
    "x": "xonî (n)",
    "y": " koni"
  },
  {
    "x": "xonîkin, -e",
    "y": " konik"
  },
  {
    "x": "xonz, -e",
    "y": " b. xoz, -e"
  },
  {
    "x": "xopay",
    "y": " yürüyerek, yayan"
  },
  {
    "x": "xopaykerdiş (n)",
    "y": " giyme (ayakkabı, pantolon vb. için)"
  },
  {
    "x": "xoperest, -e",
    "y": " narsist, özsever"
  },
  {
    "x": "xopêkirantiş (n)",
    "y": " çekişme (dövüşmek için çekişme)"
  },
  {
    "x": "xopêşanayîş (n)",
    "y": " bitişme"
  },
  {
    "x": "xopîzedevatiş (n)",
    "y": " b. pîzeyêxodevatiş (n)"
  },
  {
    "x": "xor",
    "y": " b. xuwar"
  },
  {
    "x": "xora",
    "y": " zaten"
  },
  {
    "x": "xoraantiş (n)",
    "y": " çekişme (dövüşmek için çekişme)  "
  },
  {
    "x": "xoracêrdîyayîş (n)",
    "y": " aşağılama"
  },
  {
    "x": "xoracidayîş (n)",
    "y": " alışma "
  },
  {
    "x": "xoradayîş (n)",
    "y": " giyme (giysi için)"
  },
  {
    "x": "xoragirewtiş-I (n)",
    "y": " giyme (giysi için)"
  },
  {
    "x": "xoragirewtiş-II (n)",
    "y": " dayanma (tahammül etme, sabretme)"
  },
  {
    "x": "xoraontiş (n)",
    "y": " b. xoraantiş (n)"
  },
  {
    "x": "xoraşîyaya (m)",
    "y": " baygın"
  },
  {
    "x": "xoraşîyaye (n)",
    "y": " baygın"
  },
  {
    "x": "xoraşîyayîş-I (n)",
    "y": " bayılma"
  },
  {
    "x": "xoraşîyayîş-II (n)",
    "y": " b. xowraşîyayîş (n)"
  },
  {
    "x": "xoraşîyayîye (m)",
    "y": " baygın"
  },
  {
    "x": "xorayene",
    "y": " b. qorayene"
  },
  {
    "x": "xorayîş (n)",
    "y": " b. qorayîş (n)"
  },
  {
    "x": "Xoresan (n)",
    "y": " Horasan"
  },
  {
    "x": "xorêmalkerdiş (n)",
    "y": " benimseme"
  },
  {
    "x": "xori (n)",
    "y": " b. xorîn (n)"
  },
  {
    "x": "xorimi (m)",
    "y": " b. xuremî (m)"
  },
  {
    "x": "xorî (n)",
    "y": " b. xorîn (n)"
  },
  {
    "x": "xorîn, -e",
    "y": " derin"
  },
  {
    "x": "xorîney (m)",
    "y": " b. xorînîye (m)"
  },
  {
    "x": "xorînênî (m)",
    "y": " derinlik"
  },
  {
    "x": "xorînî (m)",
    "y": " derinlik"
  },
  {
    "x": "xorînîya parselî (m)",
    "y": " parsel derinliği"
  },
  {
    "x": "xorînîye (m)",
    "y": " derinlik"
  },
  {
    "x": "xorînîya krîtîke (m)",
    "y": " kritik derinlik (su derinliği için)"
  },
  {
    "x": "xorînîya warî (sn)",
    "y": " alan derinliği"
  },
  {
    "x": "xorîy (n)",
    "y": " b. xorîn (n)"
  },
  {
    "x": "xorofeqîrîyenayîş (n)",
    "y": " acındırma"
  },
  {
    "x": "xorokerdiş (n)",
    "y": " 1)giyme (giysi için)\r\n~2)(kendi) üstüne dökme\r\n~3)altına etme, altını kirletme\r\n~4)b. xoserrokerdiş (n)"
  },
  {
    "x": "xoronayîş (n)",
    "y": " diz çökme"
  },
  {
    "x": "xororijnayîş (n)",
    "y": " (kendi) üstüne dökme"
  },
  {
    "x": "xort (n)",
    "y": " delikanlı, genç"
  },
  {
    "x": "xortaney (m)",
    "y": " b. xortanî (m)"
  },
  {
    "x": "xortanî (m)",
    "y": " delikanlılık, gençlik"
  },
  {
    "x": "xortanîye (m)",
    "y": " delikanlılık, gençlik"
  },
  {
    "x": "xortey (m)",
    "y": " b. xortî (m)"
  },
  {
    "x": "xortê (m)",
    "y": " b. xortî (m)"
  },
  {
    "x": "xortênî (m)",
    "y": " delikanlılık"
  },
  {
    "x": "xortim-I (m)",
    "y": " b. xortume (m)"
  },
  {
    "x": "xortim-II (n)",
    "y": " b. xortume (m)"
  },
  {
    "x": "xortimi (m)",
    "y": " b. xortume (m)"
  },
  {
    "x": "xortî (m)",
    "y": " delikanlılık, gençlik\r\n~Xortî a kêneke berde. (Delikanlı o kızı kaçırdı.)"
  },
  {
    "x": "xortîye (m)",
    "y": " delikanlılık, gençlik"
  },
  {
    "x": "xortum (m)",
    "y": " b. xortume (m)"
  },
  {
    "x": "xortum (n)",
    "y": " b. xortume (m)"
  },
  {
    "x": "xortume (m)",
    "y": " hortum"
  },
  {
    "x": "xortuma A-yî",
    "y": " A tipi hortum (itfaiye için)"
  },
  {
    "x": "xortuma B-yî",
    "y": " B tipi hortum (itfaiye için)"
  },
  {
    "x": "xortuma C-yî",
    "y": " C tipi hortum (itfaiye için)"
  },
  {
    "x": "xortuma D-yî",
    "y": " D tipi hortum (itfaiye için)  "
  },
  {
    "x": "xorutkerdiş (n)",
    "y": " soyunma"
  },
  {
    "x": "Xorxol (n)",
    "y": " Yayladere"
  },
  {
    "x": "xosanayîş ...",
    "y": " b. xoşanayîş ..."
  },
  {
    "x": "xosavî (m)",
    "y": " b. xoşawe (m)"
  },
  {
    "x": "xosebare (m)",
    "y": " b. xoşebere (m)"
  },
  {
    "x": "xosentiş (n)",
    "y": " tartılma"
  },
  {
    "x": "xoser, -e",
    "y": " 1)bağımsız, müstakil \r\n~2)kendi başına\r\n~3)yalın\r\n~nameyo xoser (n): yalın ad"
  },
  {
    "x": "xoser bîyene",
    "y": " bağımsız olmak \t"
  },
  {
    "x": "nameyo xoser (n)",
    "y": " yalın ad"
  },
  {
    "x": "xoserbîyayîş (n)",
    "y": " bağımsız olma"
  },
  {
    "x": "xosergirewtiş (n)",
    "y": " taahhüt"
  },
  {
    "x": "xoserî (m)",
    "y": " bağımsızlık "
  },
  {
    "x": "xoserîye (m)",
    "y": " bağımsızlık"
  },
  {
    "x": "xosernayîş (n)",
    "y": " giyme (şapka, külah vb. için)"
  },
  {
    "x": "xosero",
    "y": " dinç, zinde, sağlıklı"
  },
  {
    "x": "xosero bîyene",
    "y": " 1)sağlıklı olmak, sıhhatli olmak\r\n~2)dinç olmak, zinde olmak"
  },
  {
    "x": "xoserobîyayîş (n)",
    "y": " sağlıklı olma"
  },
  {
    "x": "xoserrokerdiş (n)",
    "y": " (mecazi) aşırı harcama"
  },
  {
    "x": "xoşab (m)",
    "y": " b. xoşawe (m)"
  },
  {
    "x": "xoşabî (m)",
    "y": " b. xoşawe (m)"
  },
  {
    "x": "xoşanayîş ...",
    "y": " dayanma (yaslanma, kendini dayama)"
  },
  {
    "x": "xoşanayîş ... ver",
    "y": " yüklenme (üstlenme)"
  },
  {
    "x": "xoşavî (m)",
    "y": " b. xoşawe (m)"
  },
  {
    "x": "xoşaw (m)",
    "y": " b. xoşawe (m)"
  },
  {
    "x": "xoşawe (m)",
    "y": " hoşaf, komposto"
  },
  {
    "x": "xoşawi (m)",
    "y": " b. xoşawe (m)"
  },
  {
    "x": "xoşawî (m)",
    "y": " b. xoşawe (m)"
  },
  {
    "x": "xoşebere (m)",
    "y": " sohbet, söyleşi"
  },
  {
    "x": "xoşelenayîş (n)",
    "y": " b. xoşelênayîş (n)"
  },
  {
    "x": "xoşelênayîş (n)",
    "y": " soyunma"
  },
  {
    "x": "xoşellênayîş (n)",
    "y": " b. xoşelênayîş (n)"
  },
  {
    "x": "xoşilnayîş (n)",
    "y": " b. xoşelênayîş (n)"
  },
  {
    "x": "xoşutiş (n)",
    "y": " yıkanma"
  },
  {
    "x": "xotepêştiş (n)",
    "y": " b. xotepîştiş (n)"
  },
  {
    "x": "xotepîştiş (n)",
    "y": " dayanma (tahammül etme, sabretme), tahammül etme"
  },
  {
    "x": "xotêbestiş (n)",
    "y": " giyinme"
  },
  {
    "x": "xotêdayîş (n)",
    "y": " b. xotêwdayîş (n)"
  },
  {
    "x": "xotêpiringnayîş (n)",
    "y": " büzülme"
  },
  {
    "x": "xotêrapiştiş (n)",
    "y": " b. xotêrapîştiş (n)"
  },
  {
    "x": "xotêrapîstiş (n)",
    "y": " b. xotêrapîştiş (n)"
  },
  {
    "x": "xotêrapîştiş (n)",
    "y": " giyinme"
  },
  {
    "x": "xotêvdayîş (n)",
    "y": " b. xotêwdayîş (n)"
  },
  {
    "x": "xotêwdayîş (n)",
    "y": " hareket etme"
  },
  {
    "x": "xotêwodayîş (n)",
    "y": " b. xotêwdayîş (n)"
  },
  {
    "x": "xotîtevîtkerdiş (n)",
    "y": " b. xotîtûvîtkerdiş (n)"
  },
  {
    "x": "xotîtiknayîş (n)",
    "y": " süslenme (kendi kendini süsleme)"
  },
  {
    "x": "xotîtovîtkerdiş (n)",
    "y": " b. xotîtûvîtkerdiş (n)"
  },
  {
    "x": "xotîtûvîtkerdiş (n)",
    "y": " süslenme (kendi kendini süsleme)"
  },
  {
    "x": "xotîyodayîş (n)",
    "y": " b. xotêwdayîş (n)"
  },
  {
    "x": "xoveragirewtiş (n)",
    "y": " kucaklama"
  },
  {
    "x": "xoverardîkerdiş (n)",
    "y": " kucaklama"
  },
  {
    "x": "xoverarekerdiş (n)",
    "y": " kucaklama"
  },
  {
    "x": "xoverarkerdiş (n)",
    "y": " b. xoverarekerdiş (n)"
  },
  {
    "x": "xoverdayîş (n)",
    "y": " 1)direniş\r\n~2)cehdetme, çabalama, gayret etme"
  },
  {
    "x": "xoverestiş (n)",
    "y": " b. xovereştiş (n)"
  },
  {
    "x": "xovereştiş (n)",
    "y": " direniş"
  },
  {
    "x": "xoverikekerdiş (n)",
    "y": " kucaklama"
  },
  {
    "x": "xoverkewtiş (n)",
    "y": " utanma"
  },
  {
    "x": "xoverodayîş (n)",
    "y": " b. xoverrodayîş (n)"
  },
  {
    "x": "xoverrodayîş (n)",
    "y": " 1)direniş\r\n~2)cehdetme, çabalama, gayret etme"
  },
  {
    "x": "xoverroewnîyayîş (n)",
    "y": " utanma (mecazi)"
  },
  {
    "x": "xoviranekerdiş (n)",
    "y": " kucaklama "
  },
  {
    "x": "xovirarekerdiş (n)",
    "y": " b. xoverarekerdiş (n)"
  },
  {
    "x": "xovirarikerdiş (n)",
    "y": " b. xoverarekerdiş (n)"
  },
  {
    "x": "xoviraştiş (n)",
    "y": " makyaj yapma"
  },
  {
    "x": "xovîrakerdiş (n)",
    "y": " b. xovîrrakerdiş (n)"
  },
  {
    "x": "xovîrîkerdiş (n)",
    "y": " b. xovîrrakerdiş (n)"
  },
  {
    "x": "xovîrîrakerdiş (n)",
    "y": " b. xovîrrakerdiş (n)"
  },
  {
    "x": "xovîrrakerdiş (n)",
    "y": " unutma"
  },
  {
    "x": "xow (n)",
    "y": " 1)uyku\r\n~2)düş, rüya"
  },
  {
    "x": "xow de kerdene",
    "y": " uyutmak"
  },
  {
    "x": "xow de şîyene",
    "y": " uyumak"
  },
  {
    "x": "xow ra kerdene",
    "y": " uyutmak, söndürmek"
  },
  {
    "x": "xow ra şîyene",
    "y": " 1)uyumak \r\n~2)batmak (güneş, ay vb. için)\r\n~Roj xow ra şi. (Güneş battı.)\r\n~Tîje xow ra şîye. (Güneş battı.) \r\n~3)sönmek"
  },
  {
    "x": "xowarde (n)",
    "y": " b. xwarde (m)"
  },
  {
    "x": "xowardeyî (m)",
    "y": " b. xwardeyî (m)"
  },
  {
    "x": "xowdekerdiş (n)",
    "y": " uyutma"
  },
  {
    "x": "xowdeşîyayîş (n)",
    "y": " uyuma"
  },
  {
    "x": "xowlî (m)",
    "y": " b. xewlî (m)"
  },
  {
    "x": "xowrakerdiş (n)",
    "y": " uyutma, söndürme"
  },
  {
    "x": "xowraşîyayîş (n)",
    "y": " 1)uyuma\r\n~2)batma (güneş, ay vb. için)\r\n~3)sönme"
  },
  {
    "x": "xox (m)",
    "y": " b. xewxe (m)"
  },
  {
    "x": "xoxapênayîş (n)",
    "y": " oyalanma"
  },
  {
    "x": "xoxe (m)",
    "y": " b. xewxe (m)"
  },
  {
    "x": "xoxemelnayîş (n)",
    "y": " b. xoxemilnayîş (n)"
  },
  {
    "x": "xoxemilnayîş (n)",
    "y": " süslenme (kendi kendini süsleme)"
  },
  {
    "x": "xoxeripnayîş (n)",
    "y": " 1)üstünü başını kirletme\r\n~2)altına etme, altını kirletme"
  },
  {
    "x": "xoxerîbdîyayîş (n)",
    "y": " garipseme"
  },
  {
    "x": "xoxêre (m)",
    "y": " b. xewxêre (m)"
  },
  {
    "x": "xoxêri (m)",
    "y": " b. xewxêre (m)"
  },
  {
    "x": "xoxi (m)",
    "y": " b. xewxe (m)"
  },
  {
    "x": "xoxickerdiş (n)",
    "y": " xoxijkerdiş (n)"
  },
  {
    "x": "xoxijkerdiş (n)",
    "y": " kayma"
  },
  {
    "x": "xoxizkerdiş (n)",
    "y": " xoxijkerdiş (n)"
  },
  {
    "x": "xoxodeqesikerdiş (n)",
    "y": " b. xoxodeqiseykerdiş (n)"
  },
  {
    "x": "xoxodeqiseykerdiş (n)",
    "y": " söylenme (kendi kendine konuşma)"
  },
  {
    "x": "xoxyer (m)",
    "y": " b. xewxêre (m)"
  },
  {
    "x": "xoyewkerdiş (n)",
    "y": " birleşme"
  },
  {
    "x": "xoz, -e",
    "y": " domuz"
  },
  {
    "x": "xoza (m)",
    "y": " doğa, tabiat"
  },
  {
    "x": "xozan (n)",
    "y": " anız, firez"
  },
  {
    "x": "xozayî, -ye",
    "y": " doğal, tabii"
  },
  {
    "x": "Xozat (n)",
    "y": " Hozat"
  },
  {
    "x": "xozeredevatiş (n)",
    "y": " b. zereyêxodevatiş (n)"
  },
  {
    "x": "xozeregirewtiş (n)",
    "y": " ithal"
  },
  {
    "x": "xozi (m)",
    "y": " b. xoze (m)"
  },
  {
    "x": "xozitkerdiş (n)",
    "y": " soyunma"
  },
  {
    "x": "xozî",
    "y": " b. xwezî"
  },
  {
    "x": "xu-I",
    "y": " b. xo-I"
  },
  {
    "x": "xu aqilîyayene",
    "y": " b. xo arqilîyayene"
  },
  {
    "x": "xu aqilnayene",
    "y": " b. xo arqilnayene"
  },
  {
    "x": "xu bêkes dîyene",
    "y": " b. xo bêkes dîyene"
  },
  {
    "x": "xu bi xu qalî kerdene",
    "y": " b. xo bi xo qalî kerdene"
  },
  {
    "x": "xu di kerdene",
    "y": " b. xo de kerdene"
  },
  {
    "x": "xu di şîyene",
    "y": " b. xow de şîyene"
  },
  {
    "x": "xu hesîyayene",
    "y": " b. xo hesîyayene"
  },
  {
    "x": "xu hesnayene",
    "y": " b. xo hesnayene"
  },
  {
    "x": "xu kerdene",
    "y": " b. xo kerdene"
  },
  {
    "x": "xu ro kerdene",
    "y": " b. xo ro kerdene"
  },
  {
    "x": "xu ronayîne",
    "y": " b. xo ronayene"
  },
  {
    "x": "xu şitene",
    "y": " b. xo şutene"
  },
  {
    "x": "xu ver dayene",
    "y": " b. xo ver dayene"
  },
  {
    "x": "xu ver o dayene",
    "y": " b. xo ver ro dayene"
  },
  {
    "x": "xu ver kewtene",
    "y": " b. xo ver kewtene"
  },
  {
    "x": "xu ver ro ewnîyayene",
    "y": " b. xo ver ro ewnîyayene"
  },
  {
    "x": "xu vera gerewtene",
    "y": " b. xo vera girewtene"
  },
  {
    "x": "xu virar kerdene",
    "y": " b. xo verare kerdene"
  },
  {
    "x": "xu vîr ardene",
    "y": " b. ardene vîrê xo"
  },
  {
    "x": "xu vîr a kerdene",
    "y": " b. xo vîr ra kerdene"
  },
  {
    "x": "xu vîr kerdene",
    "y": " b. xo vîr ra kerdene"
  },
  {
    "x": "xu vîrî kerdene",
    "y": " b. xo vîr ra kerdene"
  },
  {
    "x": "xu xemelnayene",
    "y": " b. xo xemilnayene"
  },
  {
    "x": "xu xerîb dîyene",
    "y": " b. xo xerîb dîyene"
  },
  {
    "x": "xu-II (n)",
    "y": " b. xow (n)"
  },
  {
    "x": "xu di şîyayene",
    "y": " b. xow de şîyene"
  },
  {
    "x": "xu-III (n)",
    "y": " b. xuy (n)"
  },
  {
    "x": "xu kerdene",
    "y": " b. xuy kerdene"
  },
  {
    "x": "xuaqileyîş (n)",
    "y": " b. xoarqilîyayîş (n)"
  },
  {
    "x": "xuaqilîyayîş (n)",
    "y": " b. xoarqilîyayîş (n)"
  },
  {
    "x": "xuaqilnayîş (n)",
    "y": " b. xoarqilnayîş (n)"
  },
  {
    "x": "xubar (n)",
    "y": " sonbaharda erken sürülen çift, güzlük çift"
  },
  {
    "x": "xubêkesdîyayîş (n)",
    "y": " b. xobêkesdîyayîş (n)"
  },
  {
    "x": "xubixuqalîkerdiş (n)",
    "y": " b. xobixoqalîkerdiş (n)"
  },
  {
    "x": "xuçe (m)",
    "y": " yüzeyi donmuş kar"
  },
  {
    "x": "xudikerdiş (n)",
    "y": " b. xodekerdiş (n)"
  },
  {
    "x": "xudişîyayîş (n)",
    "y": " b. xowdeşîyayîş (n)"
  },
  {
    "x": "xue",
    "y": " b. xo-I"
  },
  {
    "x": "xueci (n)",
    "y": " b. xoca (n, m)"
  },
  {
    "x": "xueji (n)",
    "y": " b. xoca (n, m)"
  },
  {
    "x": "xueni (n)",
    "y": " b. xone (n)"
  },
  {
    "x": "xuera",
    "y": " b. xora"
  },
  {
    "x": "xueradayîş (n)",
    "y": " b. xoradayîş (n)"
  },
  {
    "x": "xueragirewtiş (n)",
    "y": " b. xoragirewtiş (n)"
  },
  {
    "x": "xueraşaya (m)",
    "y": " b. xoraşîyaya (m)"
  },
  {
    "x": "xueraşaye (n)",
    "y": " b. xoraşîyaye (n)"
  },
  {
    "x": "xueraşayi (n)",
    "y": " b. xoraşîyaye (n)"
  },
  {
    "x": "xueraşayîş (n)",
    "y": " b. xoraşîyayîş-I (n)"
  },
  {
    "x": "xuerde (n)",
    "y": " b. xwarde (m)"
  },
  {
    "x": "xuerdeyê (m)",
    "y": " b. xwardeyî (m)"
  },
  {
    "x": "xuere (n)",
    "y": " b. xorîn (n)"
  },
  {
    "x": "xueri (n)",
    "y": " b. xorîn (n)"
  },
  {
    "x": "xuerin",
    "y": " b. xorîn, -e"
  },
  {
    "x": "xuerinê (m)",
    "y": " b. xorînîye (m)"
  },
  {
    "x": "xuerî (n)",
    "y": " b. xorîn (n)"
  },
  {
    "x": "xuerîn",
    "y": " b. xorîn, -e"
  },
  {
    "x": "xuerînê (m)",
    "y": " b. xorînîye (m)"
  },
  {
    "x": "xuerîyê (m)",
    "y": " b. xorînîye (m)"
  },
  {
    "x": "xuert-I (n)",
    "y": " b. xort (n)"
  },
  {
    "x": "xuert-II (n)",
    "y": " b. xurt (n)"
  },
  {
    "x": "xuertê (m)",
    "y": " b. xortî (m)"
  },
  {
    "x": "xuertim (m)",
    "y": " b. xortume (m)"
  },
  {
    "x": "xuertî (m)",
    "y": " b. xortî (m)"
  },
  {
    "x": "xuertonî (m)",
    "y": " b. xortanî (m)"
  },
  {
    "x": "xueser",
    "y": " b. xoser"
  },
  {
    "x": "xueşaw (m)",
    "y": " b. xoşawe (m)"
  },
  {
    "x": "xuez (n)",
    "y": " b. xoz (n)"
  },
  {
    "x": "xuhesîyayîş (n)",
    "y": " b. xohesîyayîş (n)"
  },
  {
    "x": "xuhesnayîş (n)",
    "y": " b. xohesnayîş (n)"
  },
  {
    "x": "xui",
    "y": " b. xo-I"
  },
  {
    "x": "xukerdiş (n)",
    "y": " b. xokerdiş (n)"
  },
  {
    "x": "xul, -e",
    "y": " kısa kulaklı (daha çok hayvan için)"
  },
  {
    "x": "xulam, -e",
    "y": " 1)köle, kul, abd\r\n~2)hizmetçi"
  },
  {
    "x": "Xulaman (n)",
    "y": " Alacakaya"
  },
  {
    "x": "xulamey (m)",
    "y": " b. xulamîye (m)"
  },
  {
    "x": "xulamî (m)",
    "y": " kölelik, kulluk"
  },
  {
    "x": "xulamîye (m)",
    "y": " kölelik, kulluk"
  },
  {
    "x": "xulasa (m)",
    "y": " özet\r\n~xulasaya hîrakerdî (m): genişletilmiş özet"
  },
  {
    "x": "xulasaya hîrakerdî (m)",
    "y": " genişletilmiş özet"
  },
  {
    "x": "xulasaya hîrakerdîye (m)",
    "y": " genişletilmiş özet"
  },
  {
    "x": "xulim (n)",
    "y": " b. xulam, -e"
  },
  {
    "x": "xuliqnayene",
    "y": " b. xeleqnayene"
  },
  {
    "x": "xuliqnayîş (n)",
    "y": " b. xeleqnayîş"
  },
  {
    "x": "xulium (n)",
    "y": " b. xulam, -e"
  },
  {
    "x": "xulom (n)",
    "y": " b. xulam, -e"
  },
  {
    "x": "xulowar",
    "y": " b. xilwar, -e"
  },
  {
    "x": "xulqteng, -e",
    "y": " sinirli"
  },
  {
    "x": "xulûm (m)",
    "y": " b. xulam, -e"
  },
  {
    "x": "xulxutik (n)",
    "y": " boğmaca"
  },
  {
    "x": "xuma",
    "y": " b. xora"
  },
  {
    "x": "xuma ra",
    "y": " b. xora"
  },
  {
    "x": "xumam (n)",
    "y": " pus, bulanık hava"
  },
  {
    "x": "xumar (n)",
    "y": " b. qumar (n)"
  },
  {
    "x": "xumarbaz, -e",
    "y": " b. qumarbaz, -e"
  },
  {
    "x": "xumare (m)",
    "y": " b. qumar (n)"
  },
  {
    "x": "xumayene",
    "y": " çağlamak"
  },
  {
    "x": "xumayîş (n)",
    "y": " çağlama"
  },
  {
    "x": "xunav (n)",
    "y": " b. xûnav (n)"
  },
  {
    "x": "xunî (n)",
    "y": " huni"
  },
  {
    "x": "xunte (n)",
    "y": " büyük bıçkı (iki kişi tarafından kullanılan)"
  },
  {
    "x": "xunti (n)",
    "y": " b. xunte (n)"
  },
  {
    "x": "xunuq, -e",
    "y": " kekre, buruk (tadı kekre olan)"
  },
  {
    "x": "xunuqek, -e",
    "y": " kekremsi"
  },
  {
    "x": "xunuqey (m)",
    "y": " b. xunuqî (m), xunuqîye (m)"
  },
  {
    "x": "xunuqî (m)",
    "y": " kekrelik, burukluk"
  },
  {
    "x": "xunuqîye (m)",
    "y": " kekrelik, burukluk"
  },
  {
    "x": "xupay",
    "y": " b. xopay"
  },
  {
    "x": "xura",
    "y": " b. xora"
  },
  {
    "x": "xuradayîş (n)",
    "y": " b. xoradayîş (n)"
  },
  {
    "x": "xurafe (m)",
    "y": " batıl inanç, boş inanç"
  },
  {
    "x": "xuragirewtiş (n)",
    "y": " b. xoragirewtiş (n)"
  },
  {
    "x": "xuraşîyaya (m)",
    "y": " b. xoraşîyaya (m)"
  },
  {
    "x": "xuraşîyaye (n)",
    "y": " b. xoraşîyaye (n)"
  },
  {
    "x": "xuraşîyayîş (n)",
    "y": " b. xoraşîyayîş-I (n)"
  },
  {
    "x": "xurde (n)",
    "y": " hurda"
  },
  {
    "x": "xurdeyo madenî",
    "y": " metalik hurda"
  },
  {
    "x": "xurdeyo metalîk",
    "y": " metalik hurda"
  },
  {
    "x": "xurderoş, -e",
    "y": " hırdavatçı"
  },
  {
    "x": "xurek (n)",
    "y": " alerji (kaşıntılı alerji)"
  },
  {
    "x": "xurekkerdoxî (zh)",
    "y": " alerji yapıcılar"
  },
  {
    "x": "xuremî (m)",
    "y": " balya"
  },
  {
    "x": "xuremî kerdene",
    "y": " balyalamak"
  },
  {
    "x": "xuremîkerdiş (n)",
    "y": " balyalama"
  },
  {
    "x": "xurik (n)",
    "y": " b. xurek"
  },
  {
    "x": "xurî (n)",
    "y": " b. xorîn (n)"
  },
  {
    "x": "xurîn, -e-I",
    "y": " aç karnına olan (sabahleyin henüz bir şey yememiş olan)\r\n~Ti hema xurîn î? (Daha aç karnına mısın?) "
  },
  {
    "x": "xurîn, -i-II",
    "y": " b. xorîn, -e"
  },
  {
    "x": "xurînbîyayîş (n)",
    "y": " aç karnına olma"
  },
  {
    "x": "xurîney (m)",
    "y": " b. xorînîye (m)"
  },
  {
    "x": "xurînî (m)",
    "y": " aç karnına olma durumu"
  },
  {
    "x": "xurînîya xo kerdene",
    "y": " kahvaltı öncesi birşey(ler) yemek\r\n~To xurînîya xo kerde? Kahvaltı öncesi birşey(ler) yedin mi?"
  },
  {
    "x": "xurma (m)",
    "y": " hurma"
  },
  {
    "x": "xurmayêre (m)",
    "y": " hurma ağacı"
  },
  {
    "x": "xurmayêri (m)",
    "y": " b. xurmayêre (m)"
  },
  {
    "x": "xurme (m)",
    "y": " b. xurma (m) "
  },
  {
    "x": "xurmîyer (m)",
    "y": " b. xurmayêre (m)"
  },
  {
    "x": "xurokerdiş (n)",
    "y": " b. xorokerdiş (n)"
  },
  {
    "x": "xurrayene",
    "y": " gürlemek"
  },
  {
    "x": "xurrayêne",
    "y": " b. xurrayene"
  },
  {
    "x": "xurt, -e",
    "y": " 1)üvey çocuk, üvey evlat\r\n~2)oğulluk, üvey oğul"
  },
  {
    "x": "xurt bîyene",
    "y": " gelişmek"
  },
  {
    "x": "xurt kerdene",
    "y": " geliştirmek"
  },
  {
    "x": "xurtbîyayîş (n)",
    "y": " gelişme, gelişim"
  },
  {
    "x": "xurtbîyayîşê kapasîte (n)",
    "y": " kapasite gelişimi"
  },
  {
    "x": "xurtbîyayîşo dewambar (n)",
    "y": " sürdürülebilir gelişme"
  },
  {
    "x": "xurte (m)",
    "y": " üvey kız"
  },
  {
    "x": "xurtey (m)",
    "y": " b. xurtîye (m)"
  },
  {
    "x": "xurtê (m)",
    "y": " b. xurtîye (m)"
  },
  {
    "x": "xurtîye (m)",
    "y": " babayiğitlik"
  },
  {
    "x": "xurtkerdiş (n)",
    "y": " geliştirme"
  },
  {
    "x": "xurtkerdişê çimeyan (n)",
    "y": " kaynak geliştirme"
  },
  {
    "x": "xurtkerdişê taximî (n)",
    "y": " takım geliştirme"
  },
  {
    "x": "xuser",
    "y": " 1)b. xoser, -e \r\n~2)b. xosero"
  },
  {
    "x": "xuser bîyayene",
    "y": " b. xoser bîyene"
  },
  {
    "x": "xusero bîyayîne",
    "y": " b. xosero bîyene"
  },
  {
    "x": "xusis (n)",
    "y": " b. xusus (n)"
  },
  {
    "x": "xusisî, -ye",
    "y": " b. xususî, -ye"
  },
  {
    "x": "xusîsî, -ye",
    "y": " b. xususî, -ye"
  },
  {
    "x": "xusîsîyet (n)",
    "y": " b. xususîyet (n)"
  },
  {
    "x": "xusk (n)",
    "y": " kırağı"
  },
  {
    "x": "xusk girê dayene",
    "y": " kırağı düşmek, kırağı yağmak\r\n~Xusk girê da. (Kırağı düştü./Kırağı yağdı.)"
  },
  {
    "x": "xuskî piro dayene",
    "y": " kırağı çalmak, kırağı vurmak\r\n~\tXuskî da piro. (Kırağı çaldı./Kırağı vurdu.)"
  },
  {
    "x": "xusus (n)",
    "y": " husus"
  },
  {
    "x": "xususî, -ye",
    "y": " özel, hususi\r\n~Havildarî derso xususî girewto. (Havıldar özel ders almış.)"
  },
  {
    "x": "xususîyet (n)",
    "y": " özellik, hususiyet"
  },
  {
    "x": "xusûs (n)",
    "y": " b. xusus (n)"
  },
  {
    "x": "xusûsî, -ye",
    "y": " b. xususî, -ye"
  },
  {
    "x": "xuşab (n)",
    "y": " b. xoşawe (m)"
  },
  {
    "x": "xuşawe (m)",
    "y": " b. xoşawe (m)"
  },
  {
    "x": "xuşk (n)",
    "y": " b. xusk (n)"
  },
  {
    "x": "xuşxuşik (n)",
    "y": " ufak taneli dolu"
  },
  {
    "x": "xutêbestiş (n)",
    "y": " b. xotêbestiş (n)"
  },
  {
    "x": "xuverdayîş (n)",
    "y": " b. xoverdayîş (n)"
  },
  {
    "x": "xuverkewtiş (n)",
    "y": " b. xoverkewtiş (n)"
  },
  {
    "x": "xuverodayîş (n)",
    "y": " b. xoverrodayîş (n)"
  },
  {
    "x": "xuverroewnîyayîş (n)",
    "y": " b. xoverroewnîyayîş (n)"
  },
  {
    "x": "xuvirarkerdiş (n)",
    "y": " b. xoverarekerdiş (n)"
  },
  {
    "x": "xuvîrakerdiş (n)",
    "y": " b. xovîrrakerdiş (n)"
  },
  {
    "x": "xuvîrîkerdiş (n)",
    "y": " b. xovîrrakerdiş (n)"
  },
  {
    "x": "xuvîrkerdiş (n)",
    "y": " b. xovîrrakerdiş (n)"
  },
  {
    "x": "xuw de şîyene",
    "y": " b. xow de şîyene"
  },
  {
    "x": "xuwar",
    "y": " aşağı"
  },
  {
    "x": "xuweci (n)",
    "y": " b. xoca (n, m)"
  },
  {
    "x": "xuwerde (n)",
    "y": " b. xwarde (m)"
  },
  {
    "x": "xuwerdeyî (m)",
    "y": " b. xwardeyî (m)"
  },
  {
    "x": "xuwert-I (n)",
    "y": " b. xort (n)"
  },
  {
    "x": "xuwert-II (n)",
    "y": " b. xurt (n)"
  },
  {
    "x": "xuwertê (m)",
    "y": " b. xortî (m)"
  },
  {
    "x": "xuxemelnayîş (n)",
    "y": " b. xoxemilnayîş (n)"
  },
  {
    "x": "xuxerîbdîyayîş (n)",
    "y": " b. xoxerîbdîyayîş (n)"
  },
  {
    "x": "xuy (n)",
    "y": " 1)huy, alışkanlık, alışkınlık\r\n~2)ahlak"
  },
  {
    "x": "xuy kerdene",
    "y": " 1)huylanmak\r\n~2)ürküp sıçramak (hayvan için)"
  },
  {
    "x": "xuye (m)",
    "y": " b. xuy (n)"
  },
  {
    "x": "xuye kerdene",
    "y": " b. xuy kerdene"
  },
  {
    "x": "xuykerdiş (n)",
    "y": " huylanma"
  },
  {
    "x": "xuyteng, -e",
    "y": " sinirli"
  },
  {
    "x": "xû-I (n)",
    "y": " b. xuy (n)"
  },
  {
    "x": "xû kerdene",
    "y": " b. xuy kerdene"
  },
  {
    "x": "xû-II",
    "y": " b. xo-I"
  },
  {
    "x": "xû kerdene",
    "y": " b. xo kerdene"
  },
  {
    "x": "xûkerdiş (n)",
    "y": " b. xokerdiş (n)"
  },
  {
    "x": "xûm, -e",
    "y": " b. xam, -e"
  },
  {
    "x": "xûm, -i",
    "y": " b. xam, -e"
  },
  {
    "x": "xûmar (n)",
    "y": " b. qumar (n)"
  },
  {
    "x": "xûmarbaz, -e",
    "y": " b. qumarbaz, -e"
  },
  {
    "x": "xûnav (n)",
    "y": " cüzzam, miskin hastalığı, miskin illeti"
  },
  {
    "x": "xûnçe (n)",
    "y": " b. xonça (m)"
  },
  {
    "x": "xûnim (m)",
    "y": " b. xanime (m)"
  },
  {
    "x": "xûnimi (m)",
    "y": " b. xanime (m)"
  },
  {
    "x": "xûrtey (m)",
    "y": " b. xortî (m)"
  },
  {
    "x": "xûrtimi (m)",
    "y": " b. xortume (m)"
  },
  {
    "x": "xûrtî (m)",
    "y": " b. xortî (m)"
  },
  {
    "x": "xûrtumi (m)",
    "y": " b. xortume (m)"
  },
  {
    "x": "xûz, -e",
    "y": " kambur"
  },
  {
    "x": "xûz bîyayene",
    "y": " b. xûz bîyene"
  },
  {
    "x": "xûz bîyene",
    "y": " kamburlaşmak"
  },
  {
    "x": "xûz, -i",
    "y": " b. xoz, -e"
  },
  {
    "x": "xûzey (m)",
    "y": " b. xûzîye (m)"
  },
  {
    "x": "xûzê (m)",
    "y": " b. xûzîye (m)"
  },
  {
    "x": "xûzî (m)",
    "y": " kamburluk"
  },
  {
    "x": "xûzîye (m)",
    "y": " kamburluk"
  },
  {
    "x": "xwarde (m)",
    "y": " çapkın, hovarda"
  },
  {
    "x": "xwardeyey m)",
    "y": " b. xwardeyî (m)"
  },
  {
    "x": "xwardeyî (m)",
    "y": " çapkınlık, hovardalık"
  },
  {
    "x": "xwazî",
    "y": " b. xwezî"
  },
  {
    "x": "xwe",
    "y": " b. xo-I"
  },
  {
    "x": "xwe bîyekes dîyene",
    "y": " b. xo bêkes dîyene"
  },
  {
    "x": "xwe mereqûnî dayene",
    "y": " b. xo meriqanî dayene"
  },
  {
    "x": "xwe mereqûnî kerdene",
    "y": " b. xo meriqanî kerdene"
  },
  {
    "x": "xwe varar kerdene",
    "y": " b. xo verare kerdene"
  },
  {
    "x": "xwe verard kerdene",
    "y": " b. xo verardî kerdene"
  },
  {
    "x": "xwe xemelnayene",
    "y": " b. xo xemilnayene"
  },
  {
    "x": "xwebîyekesdîyayîş (n)",
    "y": " b. xobêkesdîyayîş (n)"
  },
  {
    "x": "xweci (n)",
    "y": " b. xoca (n, m)"
  },
  {
    "x": "xwemereqûnîdayîş (n)",
    "y": " b. xomeriqanîdayîş (n)"
  },
  {
    "x": "xwemereqûnîkerdiş (n)",
    "y": " b. xomeriqanîkerdiş (n)"
  },
  {
    "x": "xwera",
    "y": " b. xora"
  },
  {
    "x": "xweri (n)",
    "y": " b. xorîn (n)"
  },
  {
    "x": "xwert (n)",
    "y": " b. xort (n)"
  },
  {
    "x": "xwertê (m)",
    "y": " b. xortî (m)"
  },
  {
    "x": "xwertî (m)",
    "y": " b. xortî (m)"
  },
  {
    "x": "xwertonî (m)",
    "y": " b. xortanî (m)"
  },
  {
    "x": "xweser",
    "y": " b. xoser"
  },
  {
    "x": "xweşvînî (m)",
    "y": " iyimserlik"
  },
  {
    "x": "xwevararkerdiş (n)",
    "y": " b. xoverarekerdiş (n)"
  },
  {
    "x": "xweverardkerdiş (n)",
    "y": " b. xoverardîkerdiş (n)"
  },
  {
    "x": "xwexemelnayîş (n)",
    "y": " b. xoxemilnayîş (n)"
  },
  {
    "x": "xwezika",
    "y": " b. xwezî"
  },
  {
    "x": "xwezila",
    "y": " keşke"
  },
  {
    "x": "xwezî",
    "y": " keşke"
  },
  {
    "x": "xwezîka",
    "y": " b. xwezî"
  },
  {
    "x": "xwi-I",
    "y": " b. xo-I"
  },
  {
    "x": "xwi aqilîyayene",
    "y": " b. xo arqilîyayene"
  },
  {
    "x": "xwi aqilnayene",
    "y": " b. xo arqilnayene"
  },
  {
    "x": "xwi bêkes dîyene",
    "y": " b. xo bêkes dîyene"
  },
  {
    "x": "xwi ... daritene",
    "y": " b. xo ... daritene"
  },
  {
    "x": "xwi ... daritiş",
    "y": " b. xo ... daritiş"
  },
  {
    "x": "xwi dekerdene",
    "y": " b. xo dekerdene"
  },
  {
    "x": "xwi di kerdene",
    "y": " b. xo de kerdene"
  },
  {
    "x": "xwi di şîyene",
    "y": " b. xow de şîyene"
  },
  {
    "x": "xwi fek a kerdene",
    "y": " b. xo fek ra kerdene"
  },
  {
    "x": "xwi fek ra kerdene",
    "y": " b. xo fek ra kerdene"
  },
  {
    "x": "xwi garm kardene",
    "y": " b. xo germ kerdene"
  },
  {
    "x": "xwi garmin kardene",
    "y": " b. xo germin kerdene"
  },
  {
    "x": "xwi hesîyayene",
    "y": " b. xo hesîyayene"
  },
  {
    "x": "xwi hesnayene",
    "y": " b. xo hesnayene"
  },
  {
    "x": "xwi kerdene",
    "y": " b. xo kerdene"
  },
  {
    "x": "xwi mereqaney dayene",
    "y": " b. xo meriqanî dayene"
  },
  {
    "x": "xwi mereqonî dayene",
    "y": " b. xo meriqanî dayene"
  },
  {
    "x": "xwi mereqonî kerdene",
    "y": " b. xo meriqanî kerdene"
  },
  {
    "x": "xwi nayene",
    "y": " b. ro xo nayene"
  },
  {
    "x": "xwi pêkirrantene",
    "y": " b. xo pêkirantene"
  },
  {
    "x": "xwi ra dayene",
    "y": " b. xo ra dayene"
  },
  {
    "x": "xwi ra girewtene",
    "y": " b. xo ra girewtene"
  },
  {
    "x": "xwi ra grotene",
    "y": " b. xo ragirewtene"
  },
  {
    "x": "xwi ra şîyayene",
    "y": " b. xo ra şîyene"
  },
  {
    "x": "xwi rî mal kerdene",
    "y": " b. xo rê mal kerdene"
  },
  {
    "x": "xwi ro kerdene",
    "y": " b. xo ro kerdene"
  },
  {
    "x": "xwi ro rijnayene",
    "y": " b. xo ro rijnayene"
  },
  {
    "x": "xwi ronayîne",
    "y": " b. xo ronayene"
  },
  {
    "x": "xwi ... ser daritiş",
    "y": " b. xo ... ser daritiş"
  },
  {
    "x": "xwi ser ro kerdene",
    "y": " b. xo ser ro kerdene"
  },
  {
    "x": "xwi setene",
    "y": " b. xo sentene"
  },
  {
    "x": "xwi şanayene ...",
    "y": " b. xo şanayene ..."
  },
  {
    "x": "xwi ... şanayene",
    "y": " b. xo ... şanayene"
  },
  {
    "x": "xwi ... şanayîş",
    "y": " b. xo ... şanayîş"
  },
  {
    "x": "xwi şitene",
    "y": " b. xo şutene"
  },
  {
    "x": "xwi şutene",
    "y": " b. xo şutene"
  },
  {
    "x": "xwi tebîştene",
    "y": " b. xo tepîştene"
  },
  {
    "x": "xwi tepîştene",
    "y": " b. xo tepîştene"
  },
  {
    "x": "xwi tewîştene",
    "y": " b. xo tepîştene"
  },
  {
    "x": "xwi têdayîne",
    "y": " b. xotêwday"
  },
  {
    "x": "xwi têpiringnayene",
    "y": " b. xo têpiringnayene"
  },
  {
    "x": "xwi têwo dayîne",
    "y": " b. xo têwdayene"
  },
  {
    "x": "xwi ver dayene",
    "y": " b. xo ver dayene"
  },
  {
    "x": "xwi ver o dayene",
    "y": " b. xo ver ro dayene"
  },
  {
    "x": "xwi ver kewtene",
    "y": " b. xo ver kewtene"
  },
  {
    "x": "xwi ver ro hewnîyayene",
    "y": " b. xo ver ro ewnîyayene"
  },
  {
    "x": "xwi vera girewtene",
    "y": " b. xo vera girewtene"
  },
  {
    "x": "xwi veriki girewtene",
    "y": " b. xo verike girewtene"
  },
  {
    "x": "xwi veriki kardene",
    "y": " b. xo verike kerdene "
  },
  {
    "x": "xwi veriki nayene",
    "y": " b. xo verike nayene"
  },
  {
    "x": "xwi vîr kerdene",
    "y": " b. xo vîr ra kerdene"
  },
  {
    "x": "xwi vîrî kerdene",
    "y": " b. xo vîr ra kerdene"
  },
  {
    "x": "xwi xemelnayene",
    "y": " b. xo xemilnayene"
  },
  {
    "x": "xwi xerîb dîyene",
    "y": " b. xo xerîb dîyene"
  },
  {
    "x": "xwi-II (n)",
    "y": " b. xow (n)"
  },
  {
    "x": "xwi-III (n)",
    "y": " b. xuy (n)"
  },
  {
    "x": "xwi kerdene",
    "y": " b. xuy kerdene"
  },
  {
    "x": "xwiaqileyîş (n)",
    "y": " b. xoarqilîyayîş (n)"
  },
  {
    "x": "xwiaqilîyayîş (n)",
    "y": " b. xoarqilîyayîş (n)"
  },
  {
    "x": "xwiaqilnayîş (n)",
    "y": " b. xoarqilnayîş (n)"
  },
  {
    "x": "xwibêkesdîyayîş (n)",
    "y": " b. xobêkesdîyayîş (n)"
  },
  {
    "x": "xwidekerdiş (n)",
    "y": " b. xodekerdiş-II (n)"
  },
  {
    "x": "xwidikerdiş (n)",
    "y": " b. xodekerdiş-I (n)"
  },
  {
    "x": "xwidişîyayîş (n)",
    "y": " b. xowdeşîyayîş (n)"
  },
  {
    "x": "xwifekrakerdiş (n)",
    "y": " b. xofekrakerdiş (n)"
  },
  {
    "x": "xwigarminkardiş (n)",
    "y": " b. xogerminkerdiş (n)"
  },
  {
    "x": "xwigarmkardiş (n)",
    "y": " b. xogermkerdiş (n)"
  },
  {
    "x": "xwigerminkardiş (n)",
    "y": " b. xogerminkerdiş (n)"
  },
  {
    "x": "xwigermkardiş (n)",
    "y": " b. xogermkerdiş (n)"
  },
  {
    "x": "xwihesîyayîş (n)",
    "y": " b. xohesîyayîş (n)"
  },
  {
    "x": "xwihesnayîş (n)",
    "y": " b. xohesnayîş (n)"
  },
  {
    "x": "xwikerdiş (n)",
    "y": " b. xokerdiş (n)"
  },
  {
    "x": "xwima",
    "y": " b. xora"
  },
  {
    "x": "xwima ra",
    "y": " b. xora"
  },
  {
    "x": "xwimereqaneydayîş (n)",
    "y": " b. xomeriqanîdayîş (n)"
  },
  {
    "x": "xwimereqonîdayîş (n)",
    "y": " b. xomeriqanîdayîş (n)"
  },
  {
    "x": "xwimereqonîkerdiş (n)",
    "y": " b. xomeriqanîkerdiş (n)"
  },
  {
    "x": "xwimêrabestiş (n)",
    "y": " b. xomîyanerabestiş (n)"
  },
  {
    "x": "xwipay",
    "y": " b. xopay"
  },
  {
    "x": "xwipaykardiş (n)",
    "y": " b. xopaykerdiş (n)"
  },
  {
    "x": "xwipêkirrantiş (n)",
    "y": " b. xopêkirantiş (n)"
  },
  {
    "x": "xwira",
    "y": " b. xora"
  },
  {
    "x": "xwiradayîş (n)",
    "y": " b. xoradayîş (n)"
  },
  {
    "x": "xwiragirewtiş (n)",
    "y": " b. xoragirewtiş (n)"
  },
  {
    "x": "xwiragirotiş (n)",
    "y": " b. xoragirewtiş (n)"
  },
  {
    "x": "xwiragrotiş (n)",
    "y": " b. xoragirewtiş (n)"
  },
  {
    "x": "xwiragurotiş (n)",
    "y": " b. xoragirewtiş (n)"
  },
  {
    "x": "xwiraşîyaya (m)",
    "y": " b. xoraşîyaya (m), xoraşîyayîye (m)"
  },
  {
    "x": "xwiraşîyayîş (n)",
    "y": " b. xoraşîyayîş-I (n)"
  },
  {
    "x": "xwiraşîyayîye (m)",
    "y": " b. xoraşîyaya (m), xoraşîyayîye (m)"
  },
  {
    "x": "xurokerdiş (n)",
    "y": " b. xorokerdiş (n)"
  },
  {
    "x": "xwirorijnayîş (n)",
    "y": " b. xororijnayîş (n)"
  },
  {
    "x": "xwiser",
    "y": " b. xoser"
  },
  {
    "x": "xwisero bîyayene",
    "y": " b. xosero bîyene"
  },
  {
    "x": "xwiserrokerdiş (n)",
    "y": " b. xoserrokerdiş (n)"
  },
  {
    "x": "xwisetiş (n)",
    "y": " b. xosentiş (n)"
  },
  {
    "x": "xwişanayîş ...",
    "y": " b. xoşanayîş ..."
  },
  {
    "x": "xwitebîştiş (n)",
    "y": " b. xotepîştiş (n)"
  },
  {
    "x": "xwitepîştiş (n)",
    "y": " b. xotepîştiş (n)"
  },
  {
    "x": "xwitewîştiş (n)",
    "y": " b. xotepîştiş (n)"
  },
  {
    "x": "xwitêdayîş (n)",
    "y": " b. xotêwdayîş (n)"
  },
  {
    "x": "xwitêpiringnayîş (n)",
    "y": " b. xotêpiringnayîş (n)"
  },
  {
    "x": "xwitêwodayîş (n)",
    "y": " b. xotêwdayîş (n)"
  },
  {
    "x": "xwiveragirewtiş (n)",
    "y": " b. xoveragirewtiş (n) "
  },
  {
    "x": "xwiverdayîş (n)",
    "y": " b. xoverdayîş (n)"
  },
  {
    "x": "xwiverikikardiş (n)",
    "y": " b. xoverikekerdiş (n)"
  },
  {
    "x": "xwiverikikerdiş (n)",
    "y": " b. xoverikekerdiş (n)"
  },
  {
    "x": "xwiverkewtiş (n)",
    "y": " b. xoverkewtiş (n)"
  },
  {
    "x": "xwiverodayîş (n)",
    "y": " b. xoverrodayîş (n)"
  },
  {
    "x": "xwiverrohewnîyayîş (n)",
    "y": " b. xoverroewnîyayîş (n)"
  },
  {
    "x": "xwixemelnayîş (n)",
    "y": " b. xoxemilnayîş (n)"
  },
  {
    "x": "xwixerîbdîyayîş (n)",
    "y": " b. xoxerîbdîyayîş (n)"
  },
  {
    "x": "xwîy (n)",
    "y": " b. xuy (n)"
  },
  {
    "x": "xwîy kerdene",
    "y": " b. xuy kerdene"
  },
  {
    "x": "xwîykerdiş (n)",
    "y": " b. xuykerdiş (n)"
  },
  {
    "x": "xwo",
    "y": " b. xo-I"
  },
  {
    "x": "xwoca (n)",
    "y": " b. xoca (n, m)"
  },
  {
    "x": "xwu",
    "y": " b. xo-I"
  },
  {
    "x": "YYY"
  },
  {
    "x": "ya-I (zr)",
    "y": " 1)evet\r\n~2)olur (izin)"
  },
  {
    "x": "ya-II (m, z)",
    "y": " 1)b. a-I (m, z)\r\n~2)b. aye (m, z)"
  },
  {
    "x": "yay (m, z)",
    "y": " b. aye (m, z)"
  },
  {
    "x": "ya-III (m, s)",
    "y": " b. a-II (m, s)"
  },
  {
    "x": "ya-IV",
    "y": " fiilin şimdiki zamanında kullanılan bir partikel"
  },
  {
    "x": "ya-V",
    "y": " b. yan-I"
  },
  {
    "x": "ya ... ya ...",
    "y": " b. yan ... yan ..."
  },
  {
    "x": "ya-VI",
    "y": " b. yan-II"
  },
  {
    "x": "ya-VII (pd)",
    "y": " b. ra-II (pd)"
  },
  {
    "x": "ya wet",
    "y": " b. ra wet"
  },
  {
    "x": "ya-VIII",
    "y": " belirtme edatı olarak kullanılan bir sözcük\r\n~deyîra kayî ya palîyijan: Paluluların oyun türküsü\r\n~ya dereceya dîyine: tali\r\n~ya dîyine: tali\r\n~ya yewine: birincil"
  },
  {
    "x": "ya-IX (çoğula hitap için)",
    "y": " ey\r\n~Ya merdim! (Ey insan! / Ey adam!)"
  },
  {
    "x": "yaba (n)",
    "y": " b. yaban (n)"
  },
  {
    "x": "yaban (n)",
    "y": " kır, yaban"
  },
  {
    "x": "yabanî, -ye",
    "y": " yabani, yabanıl"
  },
  {
    "x": "yabaş-yabaş",
    "y": " b. yewaş-yewaş"
  },
  {
    "x": "yabirnayîs (n)",
    "y": " b. abirnayîş (n)"
  },
  {
    "x": "yabîyayene",
    "y": " b. webîyene"
  },
  {
    "x": "yabîyayîş (n)",
    "y": " b. webîyayîş (n)"
  },
  {
    "x": "yabo (n)",
    "y": " b. yaban (n)"
  },
  {
    "x": "yabon (n)",
    "y": " b. yaban (n)"
  },
  {
    "x": "yabonî",
    "y": " b. yabanî, -ye"
  },
  {
    "x": "yabû (n)",
    "y": " b. yaban (n)"
  },
  {
    "x": "yabûn (n)",
    "y": " b. yaban (n)"
  },
  {
    "x": "yabûnî",
    "y": " b. yabanî, -ye"
  },
  {
    "x": "yacêrayene",
    "y": " dönmek\r\n~a cêrê ya: o döndü"
  },
  {
    "x": "yacêrayîş (n)",
    "y": " dönme, dönüş"
  },
  {
    "x": "yaçarnayene",
    "y": " b. açarnayene"
  },
  {
    "x": "yaçarnayîş (n)",
    "y": " b. açarnayîş (n)"
  },
  {
    "x": "yad kerdene",
    "y": " anmak"
  },
  {
    "x": "yadîgar, -e",
    "y": " anı (hatırlanmak için birine verilen), hatıra, yadigâr "
  },
  {
    "x": "yadkerdiş (n)",
    "y": " anma"
  },
  {
    "x": "yah",
    "y": " keçiyi kovmak için kullanılan seslenme sözcüğü"
  },
  {
    "x": "yah yah",
    "y": " b. yah"
  },
  {
    "x": "yahudênî (m)",
    "y": " Yahudilik"
  },
  {
    "x": "yahudî, -ye",
    "y": " Yahudi"
  },
  {
    "x": "yahudîtê (m)",
    "y": " b. yahudîtî (m)"
  },
  {
    "x": "yahudîtî (m)",
    "y": " Yahudilik"
  },
  {
    "x": "yahudîyey (m)",
    "y": " b. yahudîyîye (m)"
  },
  {
    "x": "yahudîyê (m)",
    "y": " b. yahudîyî (m)"
  },
  {
    "x": "yahudîyin (m)",
    "y": " b. yahudênî (m)"
  },
  {
    "x": "yahudîyî (m)",
    "y": " Yahudilik"
  },
  {
    "x": "yahudîyîye (m)",
    "y": " Yahudilik"
  },
  {
    "x": "yahûdî",
    "y": " b. yahudî, -ye"
  },
  {
    "x": "yahûdîte (m)",
    "y": " b. yahudîtî (m)"
  },
  {
    "x": "yahûdîyê (m)",
    "y": " b. yahudîyî (m)"
  },
  {
    "x": "yajî (n)",
    "y": " yazı (b. nuşte)"
  },
  {
    "x": "yakerde (n)",
    "y": " b. akerde (n)"
  },
  {
    "x": "yakerdene",
    "y": " b. akerdene"
  },
  {
    "x": "kerd ya",
    "y": " b. akerd"
  },
  {
    "x": "yakerdiş (n)",
    "y": " b. akerdiş (n)"
  },
  {
    "x": "yakerdîye (m)",
    "y": " b. akerdîye (m)"
  },
  {
    "x": "yakerdox, -e",
    "y": " b. akerdox, -e"
  },
  {
    "x": "yali (n)",
    "y": " b. yalî (m)"
  },
  {
    "x": "yalî (m)",
    "y": " yalı"
  },
  {
    "x": "yamno",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "yamnû",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "yamû",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "yan-I",
    "y": " ya"
  },
  {
    "x": "yan ... yan ...",
    "y": " ya ... ya ... \r\n~Yan ez yena yan ti. (Ya ben gelirim ya sen.) "
  },
  {
    "x": "yan-II",
    "y": " yoksa\r\n~Ti nusena yan nênusena? (Yazıyor musun yoksa yazmıyor musun?)\r\n~\t\t\r\n~Hecûn o yan hecûn nû? (Öyle mi yoksa öyle değil mi?)"
  },
  {
    "x": "yandes",
    "y": " b. yewendes"
  },
  {
    "x": "yanê",
    "y": " b. yanî"
  },
  {
    "x": "yanis",
    "y": " b. yaniş, -e"
  },
  {
    "x": "yaniş, -e",
    "y": " hatalı, yanlış"
  },
  {
    "x": "yanişê (m)",
    "y": " b. yanişî (m), yanişîye (m)"
  },
  {
    "x": "yanişênî (m)",
    "y": " hata (b. yanişîye)"
  },
  {
    "x": "yanişey (m)",
    "y": " b. yanişî (m), yanişîye (m)"
  },
  {
    "x": "yanişî (m)",
    "y": " hata, yanlışlık"
  },
  {
    "x": "yanişîye (m)",
    "y": " hata, yanlışlık"
  },
  {
    "x": "yanî",
    "y": " 1)yani\r\n~2)güya"
  },
  {
    "x": "yaqişlî (n)",
    "y": " yakışıklı (b. semt)"
  },
  {
    "x": "yar, -e",
    "y": " 1)dost\r\n~2)sevgili, yâr, metres"
  },
  {
    "x": "yaran, -e",
    "y": " şakacı"
  },
  {
    "x": "yaranîye (m)",
    "y": " b. yarenîye (m)"
  },
  {
    "x": "yaranîye kerdene",
    "y": " b. yarenîye kerdene"
  },
  {
    "x": "yaranîyekerdiş (n)",
    "y": " b. yarenîyekerdiş (n)"
  },
  {
    "x": "yardim (n)",
    "y": " b. ardim (n)"
  },
  {
    "x": "yardim kerdene",
    "y": " b. ardim kerdene"
  },
  {
    "x": "yare (m)",
    "y": " metres"
  },
  {
    "x": "Yaren (n)",
    "y": " Yaren"
  },
  {
    "x": "yarenî (m)",
    "y": " şaka"
  },
  {
    "x": "yarenî kerdene",
    "y": " şaka yapmak\r\n~Yarenî meke! (Şaka yapma!)"
  },
  {
    "x": "yarenîkerdiş (n)",
    "y": " şaka yapma"
  },
  {
    "x": "yarenîye (m)",
    "y": " şaka"
  },
  {
    "x": "yarenîye kerdene",
    "y": " şaka yapmak\r\n~Yarenîye meke! (Şaka yapma!)\t"
  },
  {
    "x": "yarenîyekerdiş (n)",
    "y": " şaka yapma"
  },
  {
    "x": "yarey (m)",
    "y": " b. yarîye (m)"
  },
  {
    "x": "yarê (m)",
    "y": " b. yarîye (m)"
  },
  {
    "x": "yarî (m)",
    "y": " şaka"
  },
  {
    "x": "yarî kerdene",
    "y": " şaka yapmak\r\n~Yarî meke! (Şaka yapma!)"
  },
  {
    "x": "yarîkerdiş (n)",
    "y": " şaka yapma"
  },
  {
    "x": "yarîye (m)",
    "y": " dostluk"
  },
  {
    "x": "yaro (n)",
    "y": " b. yaran, -e"
  },
  {
    "x": "yarûn (n)",
    "y": " b. yaran, -e"
  },
  {
    "x": "yasaq (n)",
    "y": " b. yasax (n)"
  },
  {
    "x": "yasax, -e",
    "y": " yasak (b. qedexe)"
  },
  {
    "x": "yasaxkerdiş (n)",
    "y": " yasaklama, men"
  },
  {
    "x": "yasayene",
    "y": " b. asayene"
  },
  {
    "x": "yasayîş (n)",
    "y": " b. asayîş-II (n)"
  },
  {
    "x": "yasi (n)",
    "y": " yatsı (b. eşa [n])"
  },
  {
    "x": "yasu (n)",
    "y": " b. eşa (n)"
  },
  {
    "x": "yaşamîşbîyayîş (n)",
    "y": " b. ciwîyayîş (n)"
  },
  {
    "x": "yaşil, -e",
    "y": " b. kesk, -e; hewz, -e-I"
  },
  {
    "x": "yaşt (n)",
    "y": " b. wet (n)"
  },
  {
    "x": "yate (m)",
    "y": " b. heyate (m)"
  },
  {
    "x": "yatme (n)",
    "y": " sahte"
  },
  {
    "x": "Yaûnde",
    "y": " Yaounde"
  },
  {
    "x": "yavan (n)",
    "y": " b. yaban (n)"
  },
  {
    "x": "yavanî, -ye",
    "y": " b. yabanî, -ye"
  },
  {
    "x": "yaverde (n)",
    "y": " yatay"
  },
  {
    "x": "yaverdîye (m)",
    "y": " yatay"
  },
  {
    "x": "yawas-yawas",
    "y": " b. yewaş-yewaş"
  },
  {
    "x": "yawasek",
    "y": " b. yewaşek"
  },
  {
    "x": "yawaş-yawaş",
    "y": " b. yewaş-yewaş"
  },
  {
    "x": "yawaşek",
    "y": " b. yewaşek"
  },
  {
    "x": "yawaşekêna",
    "y": " b. yewaşek"
  },
  {
    "x": "yawdî",
    "y": " b. yahudî, -ye"
  },
  {
    "x": "yawdîyin (m)",
    "y": " b. yahudênî (m)"
  },
  {
    "x": "yawerpîlot, -e",
    "y": " yardımcı pilot"
  },
  {
    "x": "yawerrektor, -e",
    "y": " rektör yardımcısı"
  },
  {
    "x": "yawudî, -ye",
    "y": " b. yahudî, -ye"
  },
  {
    "x": "yawudîyey (m)",
    "y": " b. yahudîyîye (m)"
  },
  {
    "x": "yaxe (n)",
    "y": " yaka"
  },
  {
    "x": "yaxeyê yewbînî girewtene",
    "y": " boğuşmak"
  },
  {
    "x": "yaxeyê yewbînî tepîştene",
    "y": " boğuşmak"
  },
  {
    "x": "yaxer (n)",
    "y": " yağmur (b. varan (n)"
  },
  {
    "x": "yaxerin (n)",
    "y": " yağmurlu (b. varanin (n)"
  },
  {
    "x": "yaxeyêyewbînîgirewtiş (n)",
    "y": " boğuşma"
  },
  {
    "x": "yaxeyêyewbînîtepîştiş (n)",
    "y": " boğuşma"
  },
  {
    "x": "yaxi (n)",
    "y": " b. girane (n), pêsîr (n)"
  },
  {
    "x": "yaxir (n)",
    "y": " b. varan (n)"
  },
  {
    "x": "yaxirin (n)",
    "y": " b. varanin (n)"
  },
  {
    "x": "yaxme (n)",
    "y": " çapul, plaçka, talan, yağma"
  },
  {
    "x": "yaxme kerdene",
    "y": " yağmalamak, talanlamak, talan etmek, yağma etmek "
  },
  {
    "x": "yaxme nîyo",
    "y": " yağma yok"
  },
  {
    "x": "yaxmecî, -ye",
    "y": " b. yaxmekerdox, -e"
  },
  {
    "x": "yaxmecîyîye (m)",
    "y": " b. yaxmekerdoxîye (m)"
  },
  {
    "x": "yaxmekerdiş (n)",
    "y": " yağmalama, talanlama, talan etme, yağma etme"
  },
  {
    "x": "yaxmekerdox, -e",
    "y": " çapulcu, talancı, yağmacı "
  },
  {
    "x": "yaxmekerdoxîye (m)",
    "y": " çapulculuk, talancılık, yağmacılık"
  },
  {
    "x": "yaxte (n)",
    "y": " at"
  },
  {
    "x": "yay-I (m, nm)",
    "y": " 1)teyze\r\n~2)b. yayê"
  },
  {
    "x": "yayê",
    "y": " teyze (hitap hali)"
  },
  {
    "x": "yay-II (m, z)",
    "y": " b. aye (m, z)"
  },
  {
    "x": "yayî (m)",
    "y": " 1)b. yay-I (m, nm)\r\n~2)b. yayê"
  },
  {
    "x": "yayke (m)",
    "y": " teyze "
  },
  {
    "x": "yaykê",
    "y": " teyze (hitap hali)"
  },
  {
    "x": "yaykeyna (m)",
    "y": " b. yaykêna (m)"
  },
  {
    "x": "yaykeza (n)",
    "y": " teyze oğlu, teyzezade"
  },
  {
    "x": "yaykêna (m)",
    "y": " teyze kızı, teyzezade"
  },
  {
    "x": "yayki (m)",
    "y": " b. yayke (m)"
  },
  {
    "x": "yaykiza (n)",
    "y": " b. yaykeza (n) "
  },
  {
    "x": "yaykkeyna (m)",
    "y": " b. yaykêna (m) "
  },
  {
    "x": "yaykza (n)",
    "y": " b. yaykeza (n)"
  },
  {
    "x": "yazi (n)",
    "y": " yazı (b. nuşte)"
  },
  {
    "x": "yazi-tura",
    "y": " b. yazi-ture"
  },
  {
    "x": "yazi-tura kerdene",
    "y": " b. yazi-ture kerdene"
  },
  {
    "x": "yazi-ture",
    "y": " yazı tura"
  },
  {
    "x": "yazi-ture kerdene",
    "y": " yazı tura atmak"
  },
  {
    "x": "yazi-turre",
    "y": " b. yazi-ture"
  },
  {
    "x": "yazi-turre kerdene",
    "y": " b. yazi-ture kerdene"
  },
  {
    "x": "ye (zr)",
    "y": " b. ya-I (zr)"
  },
  {
    "x": "yebaş-yebaş",
    "y": " b. yewaş-yewaş"
  },
  {
    "x": "yeç",
    "y": " b. hîç"
  },
  {
    "x": "yega (n)",
    "y": " b. hêga (n)"
  },
  {
    "x": "yegana (m)",
    "y": " biricik"
  },
  {
    "x": "yegane, -ye",
    "y": " biricik"
  },
  {
    "x": "yege (n)",
    "y": " b. êge (n)"
  },
  {
    "x": "yegin (n)",
    "y": " b. hêgin, -e"
  },
  {
    "x": "yeginê (m)",
    "y": " b. hêginîye (m), hêginî (m)"
  },
  {
    "x": "yehudî",
    "y": " b. yahudî, -ye"
  },
  {
    "x": "yehudîtî (m)",
    "y": " b. yahudîtî (m)"
  },
  {
    "x": "yehudîyê (m)",
    "y": " b. yahudîyî (m)"
  },
  {
    "x": "yel (n)",
    "y": " b. êl (n)"
  },
  {
    "x": "yelawiz",
    "y": " b. labelê"
  },
  {
    "x": "yelawuz",
    "y": " b. labelê"
  },
  {
    "x": "yeleg (n)",
    "y": " yelek"
  },
  {
    "x": "yelege (m)",
    "y": " b. yeleg (n)\r\n~yelegê xelase (n): cankurtaran yeleği, can yeleği\r\n~yelego ganxelasker (n): cankurtaran yeleği, can yeleği"
  },
  {
    "x": "yelegê xelase (n)",
    "y": " cankurtaran yeleği, can yeleği"
  },
  {
    "x": "yelego ganxelasker (n) ",
    "y": "cankurtaran yeleği, can yeleği"
  },
  {
    "x": "yelego poladên/polayên (n)",
    "y": " çelik yelek"
  },
  {
    "x": "yelego polayên (n)",
    "y": " çelik yelek"
  },
  {
    "x": "yelewuz",
    "y": " yalnız (b. labelê)"
  },
  {
    "x": "yelik (n)",
    "y": " b. hêlik (n) "
  },
  {
    "x": "yeling (m)",
    "y": " b. hêluge (m)"
  },
  {
    "x": "yelxe (n)",
    "y": " b. yêlxî (n)"
  },
  {
    "x": "yem-I (n)",
    "y": " b. êm (n)"
  },
  {
    "x": "yem-II (n)",
    "y": " b. hêm (n)"
  },
  {
    "x": "Yemen (n)",
    "y": " Yemen"
  },
  {
    "x": "yemenî (n)",
    "y": " ayakkabı, pabuç, başmak, yemeni (bir tür ayakkabı)"
  },
  {
    "x": "yemenîyê mewsimî (zh)",
    "y": " mevsimlik ayakkabı"
  },
  {
    "x": "yemenîderz, -e",
    "y": " yemenici "
  },
  {
    "x": "yemenîrotox, -e",
    "y": " yemenici"
  },
  {
    "x": "yemîs (n)",
    "y": " b. êmîş (n)"
  },
  {
    "x": "yemîş (n)",
    "y": " b. êmîş (n)"
  },
  {
    "x": "yemnan",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "yemnan dîyayene",
    "y": " b. yewbînan dîyene"
  },
  {
    "x": "yemnandîyayîş (n)",
    "y": " b. yewbînandîyayîş (n)"
  },
  {
    "x": "yemno",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "yemno dîyayene",
    "y": " b. yewbînan dîyene"
  },
  {
    "x": "yemnodîyayîş (n)",
    "y": " b. yewbînandîyayîş (n)"
  },
  {
    "x": "yemnon",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "yemnû",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "yemnûn",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "yemûn (m)",
    "y": " b. emanete (m)"
  },
  {
    "x": "yen",
    "y": " b. yan-II"
  },
  {
    "x": "...yen (sf)",
    "y": " b. -ên (sf), -in-I (sf)"
  },
  {
    "x": "yene-I (n)",
    "y": " b. îne-I (n)"
  },
  {
    "x": "yene-II (n)",
    "y": " b. hênî-I (n)"
  },
  {
    "x": "yengîdinya (m)",
    "y": " maltaeriği, yenidünya"
  },
  {
    "x": "yengîdinyayêre (m)",
    "y": " yenidünya ağacı"
  },
  {
    "x": "yeni-I (n)",
    "y": " b. îne-I (n)"
  },
  {
    "x": "yeni-II (n)",
    "y": " b. hênî-I (n)"
  },
  {
    "x": "yenî (n)",
    "y": " b. hênî-I (n)"
  },
  {
    "x": "yeqîn bestîyayene",
    "y": " inanmak"
  },
  {
    "x": "yeqîn kerdene",
    "y": " inanmak"
  },
  {
    "x": "yeqînbestîyayîş (n)",
    "y": " inan, inanma"
  },
  {
    "x": "yeqînkerdiş (n)",
    "y": " inan, inanma"
  },
  {
    "x": "yere (n)",
    "y": " b. êre (n)"
  },
  {
    "x": "yerewo teng (n)",
    "y": " b. êreyo teng (n)"
  },
  {
    "x": "yero teng (n)",
    "y": " b. êreyo teng (n)"
  },
  {
    "x": "yerey",
    "y": " b. êreyî"
  },
  {
    "x": "yeri (n)",
    "y": " b. êre (n)"
  },
  {
    "x": "yers (n)",
    "y": " b. hêrs (n)"
  },
  {
    "x": "yers bîyayîne",
    "y": " b. hêrs bîyene"
  },
  {
    "x": "yers kerdene",
    "y": " b. hêrs kerdene"
  },
  {
    "x": "yers kowtene",
    "y": " b. hêrs kewtene"
  },
  {
    "x": "yersî ... ameyîne",
    "y": " b. hêrsê ... ameyene"
  },
  {
    "x": "yersî ... ameyîş",
    "y": " b. hêrsê ... ameyîş"
  },
  {
    "x": "yersî ... ardene",
    "y": " b. hêrsê ... ardene"
  },
  {
    "x": "yersî ... ardiş",
    "y": " b. hêrsê ... ardiş"
  },
  {
    "x": "yersî ... ameyîne",
    "y": " b. hêrsê ... ameyene"
  },
  {
    "x": "yersbîyaya (m)",
    "y": " b. hêrsbîyaya (m)"
  },
  {
    "x": "yersbîyaye (n)",
    "y": " b. hêrsbîyaye (n)"
  },
  {
    "x": "yersbîyaye bîyene",
    "y": " b. hêrsbîyaye bîyene"
  },
  {
    "x": "yersbîyayê (m)",
    "y": " b. hêrsbîyayîye (m), hêrsbîyaya (m)"
  },
  {
    "x": "yersbîyayi (n)",
    "y": " b. hêrsbîyaye (n)"
  },
  {
    "x": "yersbîyayîş (n)",
    "y": " b. hêrsbîyayîş (n)"
  },
  {
    "x": "yersin",
    "y": " b. hêrsin, -e"
  },
  {
    "x": "yerskerdiş (n)",
    "y": " b. hêrskerdiş (n)"
  },
  {
    "x": "yerskowtiş (n)",
    "y": " b. hêrskewtiş (n"
  },
  {
    "x": "yersokin (n)",
    "y": " b. hêrsin, -e, b. hêrsokin, -e"
  },
  {
    "x": "yerxat, -e",
    "y": " b. êrxat, -e"
  },
  {
    "x": "yerxatê (m)",
    "y": " b. êrxatî (m), êrxatîye (m)"
  },
  {
    "x": "yerxe (n)",
    "y": " b. xirqe (n)"
  },
  {
    "x": "yesax, -i",
    "y": " b. qedexe (n), qedexa (m)"
  },
  {
    "x": "yetim, -e",
    "y": " b. yetîm, -e"
  },
  {
    "x": "yetîm, -e",
    "y": " yetim, babasız"
  },
  {
    "x": "yetîmîye (m)",
    "y": " yetimlik, babasızlık"
  },
  {
    "x": "yew, -e",
    "y": " 1)bir\r\n~2)birisi, biri, kimse\r\n~Yew ameyo./Yewe ameya. (Birisi gelmiş.)\r\n~3)birey, fert\r\n~4)tek\r\n~5)(s) eşit, bir, aynı"
  },
  {
    "x": "yew bar",
    "y": " (çok fazla) yüklü, çok fazla, pek çok"
  },
  {
    "x": "yew bi yew",
    "y": " birer birer, bir bir, teker teker"
  },
  {
    "x": "yew bîyene",
    "y": " birleşmek, birlik olmak"
  },
  {
    "x": "yew citaya solan",
    "y": " bir çift ayakkabı"
  },
  {
    "x": "yew çî",
    "y": " bir şey"
  },
  {
    "x": "yew dem",
    "y": " vaktiyle, bir zamanlar"
  },
  {
    "x": "yew deme",
    "y": " b. yew dem"
  },
  {
    "x": "yew hekmete",
    "y": " bir hayli"
  },
  {
    "x": "yew karmat",
    "y": " b. yew keremate"
  },
  {
    "x": "yew kerdene",
    "y": " birleştirmek"
  },
  {
    "x": "yew keremate",
    "y": " bir hayli"
  },
  {
    "x": "yew kermati",
    "y": " b. yew keremate"
  },
  {
    "x": "yew merdim",
    "y": " adamın biri, bir adam"
  },
  {
    "x": "yew mêrdek",
    "y": " adamın biri, bir adam, bir insan"
  },
  {
    "x": "yew verada îziman",
    "y": " bir kucak odun"
  },
  {
    "x": "yew verradey îzimon",
    "y": " b. yew verada îziman"
  },
  {
    "x": "yew virara îzimon",
    "y": " b. verarê îzimî"
  },
  {
    "x": "yew wext",
    "y": " vaktiyle, bir zamanlar"
  },
  {
    "x": "yew zeman",
    "y": " vaktiyle, bir zamanlar"
  },
  {
    "x": "yew zemon",
    "y": " b. yew zeman"
  },
  {
    "x": "yewî (n, z)",
    "y": " 1-birisi, biri\r\n~Yewî vato. (Birisi söylemiş.)\r\n~2-kimse (hiçbir kişi)\r\n~\t"
  },
  {
    "x": "yewan (zh)",
    "y": " birler (mat)"
  },
  {
    "x": "yewas-yewas",
    "y": " b. yewaş-yewaş"
  },
  {
    "x": "yewaş-yewaş",
    "y": " giderek, gittikçe, yavaş yavaş"
  },
  {
    "x": "yewaşek",
    "y": " yavaşça"
  },
  {
    "x": "yewbeyîş (n)",
    "y": " b. yewbîyayîş (n)"
  },
  {
    "x": "yewbîn",
    "y": " b. yewbînî"
  },
  {
    "x": "yewbîna",
    "y": " b. yewbînî"
  },
  {
    "x": "yewbînan",
    "y": " birbiri\r\n~Ma dostê yewbînan î. (Biz birbirimizin dostuyuz.)"
  },
  {
    "x": "yewbînan dîyene",
    "y": " buluşmak"
  },
  {
    "x": "yewbînandîyayîş (n)",
    "y": " buluşma"
  },
  {
    "x": "yewbînî",
    "y": " birbiri\r\n~Ma dostê yewbînî yê. (Biz birbirimizin dostuyuz.)"
  },
  {
    "x": "yewbînî de henekî kerdene",
    "y": " şakalaşmak"
  },
  {
    "x": "yewbînî de kar kerdene",
    "y": " etkileşmek"
  },
  {
    "x": "yewbînî de kuşat kerdene",
    "y": " şakalaşmak"
  },
  {
    "x": "yewbînî de lexî kerdene",
    "y": " şakalaşmak"
  },
  {
    "x": "yewbînî de tesîr kerdene",
    "y": " etkileşmek"
  },
  {
    "x": "yewbînî de xeyr kerdene",
    "y": " geçinmek (başkasıyla geçinmek)"
  },
  {
    "x": "yewbînî de yarenî kerdene",
    "y": " şakalaşmak"
  },
  {
    "x": "yewbînî de yarenîye kerdene",
    "y": " şakalaşmak"
  },
  {
    "x": "yewbînî de yarî kerdene",
    "y": " şakalaşmak"
  },
  {
    "x": "yewbînî dîyene",
    "y": " buluşmak"
  },
  {
    "x": "yewbînî girewtene",
    "y": " 1)bağdaşmak\r\n~2)boğuşmak, güreşmek, güreş tutmak"
  },
  {
    "x": "yewbînî kewtene",
    "y": " çatışmak"
  },
  {
    "x": "yewbînî kowtene",
    "y": " b. yewbînî kewtene"
  },
  {
    "x": "yewbînî nêgirewtene",
    "y": " çelişmek¸ mütenakız olmak"
  },
  {
    "x": "yewbînî nêtepîştene",
    "y": " çelişmek, mütenakız olmak"
  },
  {
    "x": "yewbînî ra bestena sehneyan (sn)",
    "y": " sahneleri birbirine bağlamak"
  },
  {
    "x": "yewbînî ra cîyabîyene",
    "y": " ayrışmak"
  },
  {
    "x": "yewbînî ra cîyakerdene",
    "y": " ayrıştırmak"
  },
  {
    "x": "yewbînî ra ewnîyayene",
    "y": " bakışmak"
  },
  {
    "x": "yewbînî ra girê dayena sehneyan (sn)",
    "y": " sahneleri birbirine bağlamak"
  },
  {
    "x": "yewbînî ra nayene",
    "y": " birleştirmek"
  },
  {
    "x": "yewbînî rê nuştene",
    "y": " yazışmak"
  },
  {
    "x": "yewbînî ro barrayene",
    "y": " bağrışmak"
  },
  {
    "x": "yewbînî ro berizîyayene",
    "y": " bağrışmak"
  },
  {
    "x": "yewbînî ro birrayene",
    "y": " bağrışmak"
  },
  {
    "x": "yewbînî ro qarayene",
    "y": " bağrışmak"
  },
  {
    "x": "yewbînî ro qîjayene",
    "y": " bağrışmak"
  },
  {
    "x": "yewbînî ro qîrayene",
    "y": " bağrışmak"
  },
  {
    "x": "yewbînî ro zirçayene",
    "y": " bağrışmak"
  },
  {
    "x": "yewbînî ser o tesîr kerdene",
    "y": " etkileşmek"
  },
  {
    "x": "yewbînî veradayene",
    "y": " boşanmak"
  },
  {
    "x": "yewbînî verdayene",
    "y": " boşanmak"
  },
  {
    "x": "yewbînîdehenekîkerdiş (n)",
    "y": " şakalaşma"
  },
  {
    "x": "yewbînîdekarkerdiş (n)",
    "y": " etkileşme"
  },
  {
    "x": "yewbînîdekuşatkerdiş (n)",
    "y": " şakalaşma"
  },
  {
    "x": "yewbînîdelexîkerdiş (n)",
    "y": " şakalaşma"
  },
  {
    "x": "yewbînîdetesîrkerdiş (n)",
    "y": " etkileşme"
  },
  {
    "x": "yewbînîdexeyrkerdiş (n)",
    "y": " geçinme (başkasıyla geçinme)"
  },
  {
    "x": "yewbînîdeyarenîkerdiş (n)",
    "y": " şakalaşma"
  },
  {
    "x": "yewbînîdeyarenîyekerdiş (n)",
    "y": " şakalaşma"
  },
  {
    "x": "yewbînîdeyarîkerdiş (n)",
    "y": " şakalaşma"
  },
  {
    "x": "yewbînîdîyayîş (n)",
    "y": " buluşma"
  },
  {
    "x": "yewbînîgirewtiş (n)",
    "y": " 1)bağdaşma\r\n~2)boğuşma"
  },
  {
    "x": "yewbînîkewtiş (n)",
    "y": " çatışma"
  },
  {
    "x": "yewbînîkowtiş (n)",
    "y": " b. yewbînîkewtiş (n)"
  },
  {
    "x": "yewbînînêgirewtiş (n)",
    "y": " çelişme"
  },
  {
    "x": "yewbînînêtepîştiş (n)",
    "y": " çelişme"
  },
  {
    "x": "yewbînîpersayîş (n)",
    "y": " (karşılıklı) hal hatır sorma"
  },
  {
    "x": "yewbînîracîyabîyayîş (n)",
    "y": " ayrışma"
  },
  {
    "x": "yewbînîracîyabîyayîşo aerobîk (n)",
    "y": " aerobik ayrışma "
  },
  {
    "x": "yewbînîracîyaker, -e",
    "y": " ayrıştırıcı"
  },
  {
    "x": "yewbînîracîyakerdiş (n)",
    "y": " ayrıştırma"
  },
  {
    "x": "yewbînîracîyakerdox, -e",
    "y": " ayrıştırıcı"
  },
  {
    "x": "yewbînîraewnîyayîş (n)",
    "y": " bakışma"
  },
  {
    "x": "yewbînîranayîş (n)",
    "y": " birleştirme"
  },
  {
    "x": "yewbînîrênuştiş (n)",
    "y": " yazışma"
  },
  {
    "x": "yewbînîrobarrayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "yewbînîroberizîyayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "yewbînîrobirrayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "yewbînîroqarayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "yewbînîroqîjayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "yewbînîroqîrayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "yewbînîrozirçayîş (n)",
    "y": " bağrışma"
  },
  {
    "x": "yewbînîserotesîrkerdiş (n)",
    "y": " etkileşme, etkileşim"
  },
  {
    "x": "yewbînîveradayiş (n)",
    "y": " boşanma"
  },
  {
    "x": "yewbînîverdayîş (n)",
    "y": " boşanma"
  },
  {
    "x": "yewbîno",
    "y": " b. yewbînî"
  },
  {
    "x": "yewbînon",
    "y": " b. yewbînî"
  },
  {
    "x": "yewbînû",
    "y": " b. yewbînî"
  },
  {
    "x": "yewbînûn",
    "y": " b. yewbînî"
  },
  {
    "x": "yewbîyaye (n)",
    "y": " birleşik"
  },
  {
    "x": "yewbîyayîş (n)",
    "y": " birleşme, birlik"
  },
  {
    "x": "yewbîyayîye (m)",
    "y": " birleşik"
  },
  {
    "x": "yewbno",
    "y": " b. yewbînî"
  },
  {
    "x": "yewbnû",
    "y": " b. yewbînî"
  },
  {
    "x": "yewcaardiş (n)",
    "y": " devşirme"
  },
  {
    "x": "yewe (m)",
    "y": " b. yew, -e"
  },
  {
    "x": "yewek",
    "y": " 1)biricik\r\n~2)hiç kimse"
  },
  {
    "x": "yewek û hayek",
    "y": " biricik"
  },
  {
    "x": "yewendes",
    "y": " on bir"
  },
  {
    "x": "yewendesin, -e",
    "y": " onbirinci "
  },
  {
    "x": "yewer, -e",
    "y": " 1)birisi, biri \r\n~Yewer ameyo. (Birisi gelmiş.)\r\n~Yewere ameya. (Birisi gelmiş.)\r\n~2)hiç kimse"
  },
  {
    "x": "yewerî (n)",
    "y": " 1)birisi, biri \r\n~Yewerî vato. (Birisi söylemiş.)\r\n~2)kimse (hiçbir kişi)"
  },
  {
    "x": "yeweri (m)",
    "y": " b. yewere (m)"
  },
  {
    "x": "yewey (m)",
    "y": " b. yewîye (m), yewî-I (m)"
  },
  {
    "x": "yewfek (n)",
    "y": " 1)ağız birliği, söz birliği\r\n~2)çapa"
  },
  {
    "x": "yewfek bîyene",
    "y": " ağız birliği etmek, söz birliği etmek"
  },
  {
    "x": "yewfek veng kerdene",
    "y": " ağız birliği etmek, söz birliği etmek\r\n~Her di yewfek veng kenê. (Her ikisi ağız birliğiyle konuşuyorlar.)"
  },
  {
    "x": "yewfekey (m)",
    "y": " b. yewfekî (m)"
  },
  {
    "x": "yewfekê (m)",
    "y": " b. yewfekî (m)"
  },
  {
    "x": "yewfekî (m)",
    "y": " ağız birliği, söz birliği"
  },
  {
    "x": "yewhesibnayîş (n)",
    "y": " tevhit"
  },
  {
    "x": "yewhomayî (m)",
    "y": " tevhit"
  },
  {
    "x": "yewhûmar, -e",
    "y": " tekil"
  },
  {
    "x": "yewi (m)",
    "y": " b. yewe (m)"
  },
  {
    "x": "yewin, -e",
    "y": " birinci"
  },
  {
    "x": "yewini (m)",
    "y": " b. yewine (m)"
  },
  {
    "x": "yewî, -ye-I (m)",
    "y": " 1)birlik\r\n~2)ünite\r\n~üniteyewîya amadekerdişê polîmerî (m): polimer hazırlama ünitesi\r\n~3)bireysel, ferdi"
  },
  {
    "x": "yewîya amadekerdişê polîmerî (m)",
    "y": " polimer hazırlama ünitesi"
  },
  {
    "x": "yewîya bajaranê tarîxîyan (m)",
    "y": " tarihi kentler birliği"
  },
  {
    "x": "yewîya denişnayîşê verênî (m)",
    "y": " ön çökeltme ünitesi"
  },
  {
    "x": "Yewîya Ewropa (YE)",
    "y": " Avrupa Birliği (AB)"
  },
  {
    "x": "yewîya gazveşnayîşî (m)",
    "y": " gaz yakma ünitesi"
  },
  {
    "x": "yewîya piştî (m)",
    "y": " sırt ünitesi (itfaiye için)"
  },
  {
    "x": "yewîya şaristananê tarîxîyan (m)",
    "y": " tarihi kentler birliği"
  },
  {
    "x": "yewî-II (zh)",
    "y": " birler (mat)"
  },
  {
    "x": "yewîn, -e",
    "y": " b. yewin, -e"
  },
  {
    "x": "yewîne (m)",
    "y": " birim\r\n~yewîna arizeya awe: su arıza birimi"
  },
  {
    "x": "yewîna arizeya awe",
    "y": " su arıza birimi"
  },
  {
    "x": "yewîna kontrolê xomîyanî",
    "y": " iç denetim birimi"
  },
  {
    "x": "yewîna perwerdeyê meslekî",
    "y": " mesleki eğitim birimi"
  },
  {
    "x": "yewîna serrnameyî (m)",
    "y": " takvim birimi"
  },
  {
    "x": "yewîna sporî (m)",
    "y": " spor birimi"
  },
  {
    "x": "yewîna teqwîmî (m)",
    "y": " takvim birimi"
  },
  {
    "x": "yewîna têkilîyanê teberî (m)",
    "y": " dış ilişkiler birimi"
  },
  {
    "x": "yewîna xerckerdişî (m)",
    "y": " harcama birimi"
  },
  {
    "x": "yewîna zewicnayîşî (m)",
    "y": " evlendirme birimi"
  },
  {
    "x": "yewkerdiş (n)",
    "y": " birleştirme, tevhit"
  },
  {
    "x": "yewme (n)",
    "y": " b. yewmîye (n)"
  },
  {
    "x": "yewmin",
    "y": " b. yewbînî"
  },
  {
    "x": "yewmîye (n)",
    "y": " gündelik, yevmiye"
  },
  {
    "x": "yewna-I",
    "y": " bir tane daha, başka bir"
  },
  {
    "x": "yewna-II",
    "y": " b. yewbînî"
  },
  {
    "x": "yewnan",
    "y": " b. yewbînî"
  },
  {
    "x": "yewndes",
    "y": " b. yewendes"
  },
  {
    "x": "yewno",
    "y": " b. yewbînî"
  },
  {
    "x": "yewnon",
    "y": " b. yewbînî"
  },
  {
    "x": "yewnû",
    "y": " b. yewbînî"
  },
  {
    "x": "yewnûn",
    "y": " b. yewbînî"
  },
  {
    "x": "yewqebulkerdiş (n)",
    "y": " tevhit"
  },
  {
    "x": "yewray (m)",
    "y": " monoray"
  },
  {
    "x": "yewrayî (zr)",
    "y": " b. yewreyî (zr)"
  },
  {
    "x": "yewreyî",
    "y": " 1)baştan aşağı (zr)\r\n~2)baştan başa\r\n~3)baştan sona (zr)\r\n~4)başlı başına"
  },
  {
    "x": "yewşeme (n)",
    "y": " Pazar"
  },
  {
    "x": "yewver",
    "y": " toptan (ticaret için)"
  },
  {
    "x": "yewxe (n)",
    "y": " b. yowxe (n)"
  },
  {
    "x": "yexe (n)",
    "y": " b. yaxe (n)"
  },
  {
    "x": "yey-I (n, z)",
    "y": " b. ey-I (n, z)"
  },
  {
    "x": "yey-II (m, z)",
    "y": " 1)b. aye (m, z)\r\n~2)b. a-I (m, z)"
  },
  {
    "x": "...yey (sf)",
    "y": " b. -îye (sf), -î (sf)"
  },
  {
    "x": "yeyke (m)",
    "y": " b. yayke (m)"
  },
  {
    "x": "yeykeza (n)",
    "y": " b. yaykeza (n) "
  },
  {
    "x": "yeyki (m)",
    "y": " b. yayke (m)"
  },
  {
    "x": "yeykiza (n)",
    "y": " b. yaykeza (n) "
  },
  {
    "x": "yeykkeyna (m)",
    "y": " b. yaykêna (m)"
  },
  {
    "x": "yeykza (n)",
    "y": " b. yaykeza (n)"
  },
  {
    "x": "Yezit",
    "y": " b. Yezîd"
  },
  {
    "x": "Yezîd",
    "y": " Yezit"
  },
  {
    "x": "yezîdî, -ye",
    "y": " b. êzidî, -ye"
  },
  {
    "x": "yezîdîyê (m)",
    "y": " b. êzidîyî (m)"
  },
  {
    "x": "yezîdîyîye (m)",
    "y": " b. êzidîyîye (m)"
  },
  {
    "x": "Yezît",
    "y": " b. Yezîd"
  },
  {
    "x": "yê-I (n, z)",
    "y": " 1)b. ey-I (n, z)"
  },
  {
    "x": "yê-II (m, z)",
    "y": " b. aye (m, z)\r\n~Qalê yê mekir! (Ondan söz etme!)"
  },
  {
    "x": "yê-III (zh, z)",
    "y": " b. ê-III (zh, z)"
  },
  {
    "x": "yê-IV (n, s)",
    "y": " b. ê-II (n, s)"
  },
  {
    "x": "yê-V",
    "y": " fiilin şimdiki zamanında kullanılan bir partikel"
  },
  {
    "x": "yê-VI (n, m, zh)",
    "y": " iyelik adılı olarak kullanılan bir sözcük\r\n~No yê Roşanî yo. (Bu Roşanınkidir.)\r\n~Na yê Roşanî ya. (Bu Roşanınkidir.)\r\n~Nê yê Roşanî yê. (Bunlar Roşanınkilerdir.)"
  },
  {
    "x": "yê amnanî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "yê amnûnî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "yê dereceya dîyine",
    "y": " tali"
  },
  {
    "x": "yê dîyine",
    "y": " tali"
  },
  {
    "x": "yê hamnanî (n, m)",
    "y": " yazlık"
  },
  {
    "x": "yê imnanî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "yê mewsimî (n, m)",
    "y": " mevsimlik"
  },
  {
    "x": "yê omnonî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "yê omnûnî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "yê pêroyî (m)",
    "y": " kamusal (kamunun olan)"
  },
  {
    "x": "yê umûmîya (m)",
    "y": " kamusal (kamunun olan)"
  },
  {
    "x": "yê ûmnûnî (n, m)",
    "y": " b. yê hamnanî (n, m)"
  },
  {
    "x": "yê yewine",
    "y": " birincil"
  },
  {
    "x": "yê zimistanî (n, m)",
    "y": " kışlık"
  },
  {
    "x": "yê zimistonî (n, m)",
    "y": " b. yê zimistanî (n, m)"
  },
  {
    "x": "yê zimistûnî (n, m)",
    "y": " b. yê zimistanî (n, m)"
  },
  {
    "x": "yê zimûstûnî (n, m)",
    "y": " b. yê zimistanî (n, m)"
  },
  {
    "x": "yê zumustonî (n, m)",
    "y": " b. yê zimistanî (n, m)"
  },
  {
    "x": "yê zumustûnî (n, m)",
    "y": " b. yê zimistanî (n, m)"
  },
  {
    "x": "yê-VII",
    "y": " belirtme edatı olarak kullanılan bir sözcük\r\n~deyîrê kayî yê palîyijan: Paluluların oyun türküleri\r\n~kombîyayîşê ziwanî yê unîversîte: üniversitenin dil toplantısı"
  },
  {
    "x": "yê-VIII",
    "y": " b. hê"
  },
  {
    "x": "yê yê",
    "y": " b. hê hê"
  },
  {
    "x": "...yê (sf)",
    "y": " b. -îye (sf), -î (sf)"
  },
  {
    "x": "yêdek, -e",
    "y": " yedek, ihtiyat"
  },
  {
    "x": "yêdî-yêdî",
    "y": " b. hêdî-hêdî"
  },
  {
    "x": "yêdîg",
    "y": " b. hêdîka"
  },
  {
    "x": "yêdîka",
    "y": " b. hêdîka"
  },
  {
    "x": "yêl (n)",
    "y": " êl (n)"
  },
  {
    "x": "yêlxî (n)",
    "y": " yılkı"
  },
  {
    "x": "yêm-I (n)",
    "y": " b. êm (n)"
  },
  {
    "x": "yêm-II (n)",
    "y": " b. hêm (n)"
  },
  {
    "x": "yêmenî (n)",
    "y": " b. yemenî (n)"
  },
  {
    "x": "yêne (n)",
    "y": " b. îne-I (n)"
  },
  {
    "x": "yênê (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yêni (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yênî (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yêre (n)",
    "y": " b. êre (n)"
  },
  {
    "x": "yêrey",
    "y": " b. êreyî"
  },
  {
    "x": "yêri (n)",
    "y": " b. êre (n)"
  },
  {
    "x": "yêrs (n)",
    "y": " b. hêrs (n)"
  },
  {
    "x": "yêrxuçe (m)",
    "y": " b. êrxuçe (m)"
  },
  {
    "x": "yêrxuçebîyayîş (n)",
    "y": " b. êrxuçebîyayîş (n)"
  },
  {
    "x": "yêtim, -e",
    "y": " b. yetîm, -e"
  },
  {
    "x": "yêtîm",
    "y": " b. yetîm, -e"
  },
  {
    "x": "Yêzid",
    "y": " b. Yezîd"
  },
  {
    "x": "Yêzîd",
    "y": " b. Yezîd"
  },
  {
    "x": "Yêzît",
    "y": " b. Yezîd"
  },
  {
    "x": "yi-I (n, z)",
    "y": " b. ey-I (n, z)"
  },
  {
    "x": "yi-II (m, z)",
    "y": " b. aye (m, z)"
  },
  {
    "x": "yi-III (zh, z)",
    "y": " b. ê-III (zh, z)"
  },
  {
    "x": "yim (n)",
    "y": " b. hêm (n)"
  },
  {
    "x": "doyim n)",
    "y": " b. ohêm (n)"
  },
  {
    "x": "nayim n)",
    "y": " b. nohêm (n)"
  },
  {
    "x": "yin (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yinam (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yinam kerdene",
    "y": " b. înan kerdene"
  },
  {
    "x": "yine (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yini (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yinî (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yinon (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yinû (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yislige (m)",
    "y": " b. îşlig (n)"
  },
  {
    "x": "yiw",
    "y": " b. yew"
  },
  {
    "x": "yiwî (n, z)",
    "y": " b. yewî (n, z)"
  },
  {
    "x": "yiwer (n)",
    "y": " b. yewer (n)"
  },
  {
    "x": "yiwerî (n)",
    "y": " b. yewerî (n)"
  },
  {
    "x": "yiweri (m)",
    "y": " b. yewere (m)"
  },
  {
    "x": "yiwêr (n)",
    "y": " b. yewerî (n)"
  },
  {
    "x": "yix",
    "y": " b. nix"
  },
  {
    "x": "yix bîyayene",
    "y": " b. nix bîyene"
  },
  {
    "x": "yix kerdene",
    "y": " b. nix kerdene"
  },
  {
    "x": "yixli (n)",
    "y": " b. yêlxî (n)"
  },
  {
    "x": "yixtîyar, -e",
    "y": " b. extîyar, -e"
  },
  {
    "x": "yî-I (n, z)",
    "y": " b. ey-I (n, z)"
  },
  {
    "x": "yî-II (n, s)",
    "y": " b. ê-II (n, s)"
  },
  {
    "x": "yî-III (zh, z)",
    "y": " b. ê-III (zh, z)"
  },
  {
    "x": "yî-IV (zr)",
    "y": " b. zî (zr)"
  },
  {
    "x": "yîfada (m)",
    "y": " b. îfade (n)"
  },
  {
    "x": "yîk",
    "y": " b. zî (zr)"
  },
  {
    "x": "yîki",
    "y": " b. zî (zr)"
  },
  {
    "x": "yîm (n)",
    "y": " b. hêm (n)"
  },
  {
    "x": "doym n)",
    "y": " b. ohêm (n)"
  },
  {
    "x": "naym n)",
    "y": " b. nohêm (n)"
  },
  {
    "x": "doyîm n)",
    "y": " b. ohêm (n)"
  },
  {
    "x": "nayîm n)",
    "y": " b. nohêm (n)"
  },
  {
    "x": "yîmam (n)",
    "y": " b. îmam (n)"
  },
  {
    "x": "yîmîş (n)",
    "y": " b. ûmiş (n)"
  },
  {
    "x": "yîmîş bîyayene",
    "y": " b. ûmiş bîyene"
  },
  {
    "x": "yîn (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yîna (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yînam (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yînam kerdene",
    "y": " b. înan kerdene"
  },
  {
    "x": "yînan (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yîne (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yîni (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yînî (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yînkar (n)",
    "y": " b. înkar (n)"
  },
  {
    "x": "yîno (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yînon (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yîntam (n)",
    "y": " b. îmtihan (n)"
  },
  {
    "x": "yînû (zh, z)",
    "y": " b. înan (zh, z)"
  },
  {
    "x": "yîs (n)",
    "y": " b. îş (n)"
  },
  {
    "x": "yîsala",
    "y": " b. înşalah"
  },
  {
    "x": "yîso",
    "y": " b. însan (n)"
  },
  {
    "x": "yîson, -e",
    "y": " b. însan, -e"
  },
  {
    "x": "yîsû (n)",
    "y": " b. însan (n)"
  },
  {
    "x": "yîsûn, -e",
    "y": " b. însan, -e"
  },
  {
    "x": "yîşlige (m)",
    "y": " b. îşlig (n)"
  },
  {
    "x": "yîta (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "yîtîqat (n)",
    "y": " b. bawerîye (m), îtîqad (n)"
  },
  {
    "x": "yîtka (n)",
    "y": " b. tîya (n)"
  },
  {
    "x": "yîye",
    "y": " b. yê-VI (n, m, zh)"
  },
  {
    "x": "yîyê",
    "y": " b. yê-VI (n, m, zh)"
  },
  {
    "x": "yo-I",
    "y": " fiilin gelecek zamanında kullanılan bir partikel"
  },
  {
    "x": "yo-II",
    "y": " fiilin şimdiki zamanında kullanılan bir partikel"
  },
  {
    "x": "yo-III",
    "y": " 1)b. ya-IV\r\n~2)b. yê-V"
  },
  {
    "x": "yo-IV (n, z)",
    "y": " b. o-I (n, z)"
  },
  {
    "x": "yo-V (n, s)",
    "y": " b. o-II (n, s)"
  },
  {
    "x": "yo-VI",
    "y": " b. yew"
  },
  {
    "x": "yo waxt",
    "y": " b. yew wext"
  },
  {
    "x": "yo zemûn",
    "y": " b. yew zeman"
  },
  {
    "x": "yoy (n, m)",
    "y": " b. yewî (n, z)"
  },
  {
    "x": "yoyî (n, m)",
    "y": " b. yewî (n, z)"
  },
  {
    "x": "yobîn",
    "y": " b. yewbînî"
  },
  {
    "x": "yobîn dîyayene",
    "y": " b. yewbînî dîyene"
  },
  {
    "x": "yobîn gurotene",
    "y": " b. yewbînî girewtene"
  },
  {
    "x": "yobîndîyayîş (n)",
    "y": " b. yewbînîdîyayîş (n)"
  },
  {
    "x": "yobîngurotiş (n)",
    "y": " b. yewbînîgirewtiş (n)"
  },
  {
    "x": "yobînî",
    "y": " b. yewbînî"
  },
  {
    "x": "yobînî kowtene",
    "y": " b. yewbînî kewtene"
  },
  {
    "x": "yobînî ra nayene",
    "y": " b. yewbînî ra nayene"
  },
  {
    "x": "yobînîkowtiş (n)",
    "y": " b. yewbînîkewtiş (n)"
  },
  {
    "x": "yobînîranayîş (n)",
    "y": " b. yewbînîranayîş (n)"
  },
  {
    "x": "yobîno",
    "y": " b. yewbînî"
  },
  {
    "x": "yobînû",
    "y": " b. yewbînî"
  },
  {
    "x": "yobîyaya (m)",
    "y": " b. yewbîyayîye (m) "
  },
  {
    "x": "yobîyaye (n)",
    "y": " b. yewbîyaye (n) "
  },
  {
    "x": "yobîyayîş (n)",
    "y": " b. yewbîyayîş (n)"
  },
  {
    "x": "yodes",
    "y": " b. yewendes"
  },
  {
    "x": "yofek (n)",
    "y": " b. yewfek (n)"
  },
  {
    "x": "yofekey (m)",
    "y": " b. yewfekî (m)"
  },
  {
    "x": "yomîye (n)",
    "y": " b. yewmîye (n)"
  },
  {
    "x": "yon-I",
    "y": " b. yan-I"
  },
  {
    "x": "yon... yon...",
    "y": " b. yan... yan..."
  },
  {
    "x": "yon-II",
    "y": " b. yan-II"
  },
  {
    "x": "yona-I",
    "y": " b. yewna-I"
  },
  {
    "x": "yona-II",
    "y": " b. yewbînî"
  },
  {
    "x": "yonan",
    "y": " b. yewbînî"
  },
  {
    "x": "yondas",
    "y": " b. yewendes"
  },
  {
    "x": "yondes",
    "y": " b. yewendes"
  },
  {
    "x": "yonê",
    "y": " b. yewna-I"
  },
  {
    "x": "yonga",
    "y": " b. yewna-I"
  },
  {
    "x": "yoniş",
    "y": " b. yaniş, -e"
  },
  {
    "x": "yonişey (m)",
    "y": " b. yanişî (m), yanişîye (m)"
  },
  {
    "x": "yonişê (m)",
    "y": " b. yanişî (m), yanişîye (m)"
  },
  {
    "x": "yonişî (m)",
    "y": " b. yanişî (m), yanişîye (m)"
  },
  {
    "x": "yonî",
    "y": " b. yanî"
  },
  {
    "x": "yonû",
    "y": " b. yewbînî"
  },
  {
    "x": "yonûn",
    "y": " b. yewbînî"
  },
  {
    "x": "yondes",
    "y": " b. yewendes"
  },
  {
    "x": "yondesin, -e",
    "y": " b. yewendesin, -e"
  },
  {
    "x": "yonî (bs)",
    "y": " b. yanî"
  },
  {
    "x": "yorxuçe (m)",
    "y": " b. êrxuçe (m)"
  },
  {
    "x": "yoşemi (n)",
    "y": " b. yewşeme"
  },
  {
    "x": "yoşembi (n)",
    "y": " b. yewşeme"
  },
  {
    "x": "yovin",
    "y": " b. yewbînî"
  },
  {
    "x": "yovîn",
    "y": " b. yewbînî"
  },
  {
    "x": "yow",
    "y": " b. yew"
  },
  {
    "x": "yow hekmeti",
    "y": " b. yew hekmete"
  },
  {
    "x": "yowbîn",
    "y": " b. yewbînî"
  },
  {
    "x": "yowbîno",
    "y": " b. yewbînî"
  },
  {
    "x": "yowbînû",
    "y": " b. yewbînî"
  },
  {
    "x": "yowê (m)",
    "y": " b. yewî-I (m)"
  },
  {
    "x": "yowfek (n)",
    "y": " b. yewfek (n)"
  },
  {
    "x": "yowfekey (m)",
    "y": " b. yewfekî (m)"
  },
  {
    "x": "yowin, -e",
    "y": " b. yewin, -e"
  },
  {
    "x": "yowî (m)",
    "y": " b. yewîye (m), yewî (m)"
  },
  {
    "x": "yowndes",
    "y": " b. yewendes"
  },
  {
    "x": "yowna-I",
    "y": " b. yewna-I"
  },
  {
    "x": "yowna-II",
    "y": " b. yewbînî"
  },
  {
    "x": "yownan",
    "y": " b. yewbînî"
  },
  {
    "x": "yownû",
    "y": " b. yewbînî"
  },
  {
    "x": "yownûn",
    "y": " b. yewbînî"
  },
  {
    "x": "yowxe (n)",
    "y": " yufka, yuka (ekmeği)"
  },
  {
    "x": "yoxe (n)",
    "y": " b. yowxe (n)"
  },
  {
    "x": "yoxleme (n)",
    "y": " kontrol, kontrol etme, yoklama"
  },
  {
    "x": "yoxleme kerdene",
    "y": " kontrol etmek"
  },
  {
    "x": "yoxlemeyê leşkerîye (n)",
    "y": " askerlik yoklaması"
  },
  {
    "x": "yoxlemekerdiş (n)",
    "y": " kontrol, yoklama"
  },
  {
    "x": "yoxro",
    "y": " b. oxro"
  },
  {
    "x": "yoxro ke",
    "y": " b. oxro"
  },
  {
    "x": "yoyî (m)",
    "y": " b. yewîye (m), yewî (m)"
  },
  {
    "x": "yuke (m)",
    "y": " yüklük, yük"
  },
  {
    "x": "yuka cilan (m)",
    "y": " yüklük, yük"
  },
  {
    "x": "yuki (m)",
    "y": " b. yuke (m)"
  },
  {
    "x": "Yunanîstan (n)",
    "y": " Yunanistan"
  },
  {
    "x": "yû-I (n, z)",
    "y": " b. o-I (n, z)"
  },
  {
    "x": "yû-II",
    "y": " b. yew"
  },
  {
    "x": "yû-III",
    "y": " 1)b. yo-II\r\n~2)b. ya-IV\r\n~3)yê-V"
  },
  {
    "x": "yûbîn",
    "y": " b. yewbînî"
  },
  {
    "x": "yûbînû",
    "y": " b. yewbînî"
  },
  {
    "x": "Yûgoslavya (m)",
    "y": " Yugoslavya"
  },
  {
    "x": "yûn",
    "y": " b. yan"
  },
  {
    "x": "yûn... yûn...",
    "y": " b. yan... yan..."
  },
  {
    "x": "yûna",
    "y": " b. yewna-I"
  },
  {
    "x": "yûndas",
    "y": " b. yewendes"
  },
  {
    "x": "yûniş",
    "y": " b. yaniş, -e"
  },
  {
    "x": "yûnişey (m)",
    "y": " b. yanişî (m), yanişîye (m)"
  },
  {
    "x": "yûnişê (m)",
    "y": " b. yanişî (m), yanişîye (m)"
  },
  {
    "x": "yûnişî (m)",
    "y": " b. yanişî (m), yanişîye (m)"
  },
  {
    "x": "yûnî (bs)",
    "y": " b. yanî"
  },
  {
    "x": "ZZZ"
  },
  {
    "x": "z’ (zr)",
    "y": " b. zî (zr)"
  },
  {
    "x": "za-I (n)",
    "y": " b. ziwan (n) "
  },
  {
    "x": "za-II (n)",
    "y": " b. zak (n)"
  },
  {
    "x": "zabit (n)",
    "y": " tutanak, zabıt"
  },
  {
    "x": "zabit, -e",
    "y": " subay, zabit"
  },
  {
    "x": "zabita (m)",
    "y": " zabıta"
  },
  {
    "x": "zabite (n)",
    "y": " b. zabita (m)"
  },
  {
    "x": "zabitey (m)",
    "y": " b. zabitî (m), zabitîye (m)"
  },
  {
    "x": "zabitê (m)",
    "y": " b. zabitî (m), zabitîye (m)"
  },
  {
    "x": "zabitê makîna",
    "y": " makina subayı, makina zabiti"
  },
  {
    "x": "zabitî (m)",
    "y": " subaylık, zabitlik"
  },
  {
    "x": "zabitîye (m)",
    "y": " subaylık, zabitlik"
  },
  {
    "x": "zabitname (n)",
    "y": " tutanak, zabıt\r\n~zabitnameyê çinkerdişî (n): imha etme tutanağı"
  },
  {
    "x": "zabitnameyê çinkerdişî (n)",
    "y": " imha etme tutanağı"
  },
  {
    "x": "zabitnameyê îmhakerdişî (n)",
    "y": " imha etme tutanağı"
  },
  {
    "x": "zabitnameyê kombîyayîşî (n)",
    "y": " toplantı tutanağı"
  },
  {
    "x": "zabut, -e",
    "y": " b. zabit, -e"
  },
  {
    "x": "zabutey (m)",
    "y": " b. zabitî (m), zabitîye (m)"
  },
  {
    "x": "zabutê (m)",
    "y": " b. zabitî (m), zabitîye (m)"
  },
  {
    "x": "zacilane (m)",
    "y": " lohusa, loğusa"
  },
  {
    "x": "zacilaney (m)",
    "y": " b. zacilanî (m)"
  },
  {
    "x": "zacilani (m)",
    "y": " b. zacilane (m)"
  },
  {
    "x": "zacilanî (m)",
    "y": " lohusalık, loğusalık"
  },
  {
    "x": "zaciloney (m)",
    "y": " b. zacilanî (m)"
  },
  {
    "x": "zaciloni (m)",
    "y": " b. zacilane (m)"
  },
  {
    "x": "zacilûn (m)",
    "y": " b. zacilane (m)"
  },
  {
    "x": "zacilûnê (m)",
    "y": " b. zacilanî (m)"
  },
  {
    "x": "zad (n)",
    "y": " 1)tahıl, hububat\r\n~2)yiyecek maddesi"
  },
  {
    "x": "zaf, -e",
    "y": " 1)çok\r\n~2)dolgun (ücret vb. için)"
  },
  {
    "x": "zaf baş",
    "y": " çılgın, olağanüstü"
  },
  {
    "x": "zaf biferq, -e",
    "y": " apayrı, bambaşka"
  },
  {
    "x": "zaf bîyene",
    "y": " artmak, çoğalmak"
  },
  {
    "x": "zaf cîya, -ye",
    "y": " apayrı, bambaşka"
  },
  {
    "x": "zaf dejnayene",
    "y": " yürek paralamak"
  },
  {
    "x": "zaf gird, -e",
    "y": " çılgın"
  },
  {
    "x": "zaf hêrs bîyene",
    "y": " ateş püskürmek"
  },
  {
    "x": "zaf hol",
    "y": " çılgın, olağanüstü"
  },
  {
    "x": "zaf kerdene",
    "y": " artırmak, çoğaltmak"
  },
  {
    "x": "zaf nêweş bîyene",
    "y": " çok/ağır hasta olmak"
  },
  {
    "x": "zaf pesênayene",
    "y": " ballandırmak"
  },
  {
    "x": "zaf pesn dayene",
    "y": " ballandırmak"
  },
  {
    "x": "zaf ra tay",
    "y": " aşağı yukarı, yaklaşık, takriben yaklaşık olarak, takribî"
  },
  {
    "x": "zaf ra tayê",
    "y": " b. zaf ra tay"
  },
  {
    "x": "zaf ra tayî",
    "y": " b. zaf ra tay"
  },
  {
    "x": "zaf ra toy",
    "y": " b. zaf ra tay"
  },
  {
    "x": "zaf ra tûy",
    "y": " b. zaf ra tay"
  },
  {
    "x": "zaf rind",
    "y": " çılgın, olağanüstü"
  },
  {
    "x": "zaf wesfî dayene",
    "y": " ballandırmak"
  },
  {
    "x": "zaf-zaf",
    "y": " 1)aşırı\r\n~2)çılgınca"
  },
  {
    "x": "zaf-zaf hes kerdene",
    "y": " delice sevmek"
  },
  {
    "x": "zaf-zaf leze",
    "y": " baş döndürücü (mecazi)"
  },
  {
    "x": "zaf-zaf rew",
    "y": " baş döndürücü (mecazi)"
  },
  {
    "x": "zafê zafan",
    "y": " azami"
  },
  {
    "x": "zafane",
    "y": " 1)çoğunluk\r\n~2)genellikle"
  },
  {
    "x": "zafbîyayîş (n)",
    "y": " artma, çoğalma"
  },
  {
    "x": "zafdejnayîş (n)",
    "y": " yürek paralama"
  },
  {
    "x": "zafêrîye",
    "y": " çoğunluk"
  },
  {
    "x": "zafhûmar, -e",
    "y": " çoğul"
  },
  {
    "x": "zafîne",
    "y": " b. zafane"
  },
  {
    "x": "zafînê",
    "y": " b. zafane"
  },
  {
    "x": "zafkenar (n)",
    "y": " çokgen"
  },
  {
    "x": "zafker (n)",
    "y": " çoğaltıcı"
  },
  {
    "x": "zafkerdiş (n)",
    "y": " artırma, çoğaltma"
  },
  {
    "x": "zafmiletin, -e",
    "y": " çok uluslu"
  },
  {
    "x": "zafneteweyin, -e",
    "y": " çok uluslu"
  },
  {
    "x": "zafpesênayîş",
    "y": " ballandırma"
  },
  {
    "x": "zafpesndayîş",
    "y": " ballandırma"
  },
  {
    "x": "zafwesfîdayîş (n)",
    "y": " ballandırma"
  },
  {
    "x": "zafzanaya (m)",
    "y": " çokbilmiş"
  },
  {
    "x": "zafzanaye (n)",
    "y": " çokbilmiş"
  },
  {
    "x": "zafzanayî, -ye",
    "y": " çokbilmiş"
  },
  {
    "x": "Zagreb (n)",
    "y": " Zagreb"
  },
  {
    "x": "zahf",
    "y": " b. zaf"
  },
  {
    "x": "zahmet, -e",
    "y": " b. zehmet, -e"
  },
  {
    "x": "zahmet beno",
    "y": " b. zehmet beno"
  },
  {
    "x": "zahmet bîyayene",
    "y": " b. zehmet bîyene"
  },
  {
    "x": "zahmet dayene",
    "y": " b. zehmet dayene"
  },
  {
    "x": "zahmet mebo",
    "y": " b. zehmet mebo"
  },
  {
    "x": "zahmetê xo ra",
    "y": " b. zehmetê xo ra"
  },
  {
    "x": "zahmetî (m)",
    "y": " b. zehmetî (m)"
  },
  {
    "x": "zaîf, -e",
    "y": " b. zeîf, -e"
  },
  {
    "x": "zaîf bîyayene",
    "y": " b. zeîf bîyene"
  },
  {
    "x": "zaîf kerdene",
    "y": " b. zeîf kerdene"
  },
  {
    "x": "zaîfbîyayîş (n)",
    "y": " b. zeîfbîyayîş (n)"
  },
  {
    "x": "zaîfê (m)",
    "y": " b. zeîfîye (m)"
  },
  {
    "x": "zaîfkerdiş (n)",
    "y": " b. zeîfkerdiş (n)"
  },
  {
    "x": "Zaîre (n)",
    "y": " Kongo, Zaire"
  },
  {
    "x": "zajilûn (m)",
    "y": " b. zacilane (m)"
  },
  {
    "x": "zak (n)",
    "y": " erkek çocuk, oğlan"
  },
  {
    "x": "zak bîyene",
    "y": " çocuklaşmak"
  },
  {
    "x": "zaka (m)",
    "y": " kız çocuğu"
  },
  {
    "x": "zakbîyayîş (n)",
    "y": " çocuklaşma"
  },
  {
    "x": "zalal kerdene",
    "y": " b. zelal kerdene"
  },
  {
    "x": "zalancêre (m)",
    "y": " b. calancêre (m)"
  },
  {
    "x": "zalim, -e",
    "y": " zalim"
  },
  {
    "x": "zalimey (m)",
    "y": " b. zalimîye (m)"
  },
  {
    "x": "zalimê (m)",
    "y": " b. zalimî (m)"
  },
  {
    "x": "zalimênî (m)",
    "y": " cellatlık (mecazen katı zalimlik)"
  },
  {
    "x": "zalimî (m)",
    "y": " zalimlik, cellatlık (mecazen katı zalimlik)"
  },
  {
    "x": "zalimîye (m)",
    "y": " zalimlik, cellatlık (mecazen katı zalimlik)"
  },
  {
    "x": "zama-I (n)",
    "y": " damat, enişte"
  },
  {
    "x": "zama-II (n)",
    "y": " b. zeman (n)"
  },
  {
    "x": "zaman (n)",
    "y": " b. zeman (n)"
  },
  {
    "x": "Zambîya (m)",
    "y": " Zambiya"
  },
  {
    "x": "zamet, -e",
    "y": " b. zehmet, -e"
  },
  {
    "x": "zamet ancitene",
    "y": " b. zehmet antene"
  },
  {
    "x": "zamet anitene",
    "y": " b. zehmet antene"
  },
  {
    "x": "zamet beno",
    "y": " b. zehmet beno"
  },
  {
    "x": "zamet bîyayene",
    "y": " b. zehmet bîyene"
  },
  {
    "x": "zamet dayene",
    "y": " b. zehmet dayene"
  },
  {
    "x": "zamet kerdene",
    "y": " b. zehmet kerdene"
  },
  {
    "x": "zamet nêbo se",
    "y": " b. zehmet nêbo"
  },
  {
    "x": "zametê xo ra",
    "y": " b. zehmetê xo ra"
  },
  {
    "x": "zametîye (m)",
    "y": " b. zehmetîye (m)"
  },
  {
    "x": "zametkêş, -e",
    "y": " b. zehmetkêş, -e"
  },
  {
    "x": "zametkêşi (m)",
    "y": " b. zehmetkêşe (m)"
  },
  {
    "x": "zamq (n)",
    "y": " b. zamqe (m)"
  },
  {
    "x": "zamqe (m)",
    "y": " zamk"
  },
  {
    "x": "zamqi (m)",
    "y": " b. zamqe (m)"
  },
  {
    "x": "zamûn (n)",
    "y": " b. zeman (n)"
  },
  {
    "x": "zan-I (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "zano qic (n)",
    "y": " b. ziwano qij (n)"
  },
  {
    "x": "zan-II (n)",
    "y": " b. jan (n)"
  },
  {
    "x": "zan dayene",
    "y": " b. jan dayene"
  },
  {
    "x": "zanakirarî (m)",
    "y": " bilgiişlem"
  },
  {
    "x": "zanaya (m)",
    "y": " bilen, bilgili"
  },
  {
    "x": "zanaye (n)",
    "y": " bilen, bilgili"
  },
  {
    "x": "zanayene",
    "y": " bilmek"
  },
  {
    "x": "zanayene dayene",
    "y": " b. dayene zanayene"
  },
  {
    "x": "zanayêne",
    "y": " b. zanayene"
  },
  {
    "x": "zanayîş (n)",
    "y": " 1)bilme\r\n~2)bilgi, malumat\r\n~Derheqê nê meseleyî de zanayîşê birayê mi zaf o. (Bu sorun hakkında kardeşimin bilgisi çoktur.)\r\n~zanayîşê cuye (m): hayat bilgisi\r\n~zanayîşo fermî (n): resmî bilgi"
  },
  {
    "x": "zanayîşê cuye (m)",
    "y": " hayat bilgisi"
  },
  {
    "x": "zanayîşê fenî (n)",
    "y": " fen bilgisi"
  },
  {
    "x": "zanayîşê komelkîyî (zh)",
    "y": " sosyal bilgiler"
  },
  {
    "x": "zanayîşê weşî (n)",
    "y": " hijyen"
  },
  {
    "x": "zanayîşo fermî (n)",
    "y": " resmî bilgi"
  },
  {
    "x": "zanayîşdayîş (n)",
    "y": " b. dayîşzanayîş (n)"
  },
  {
    "x": "zanayîye (m)",
    "y": " bilen, bilgili"
  },
  {
    "x": "zanayox, -e",
    "y": " bilen"
  },
  {
    "x": "zanc (m)",
    "y": " b. zewnce (m)"
  },
  {
    "x": "zanc-fer (n)",
    "y": " b. zewnce-fert (n)"
  },
  {
    "x": "zandes",
    "y": " b. yewendes"
  },
  {
    "x": "zaneyîş (n)",
    "y": " b. zanayîş (n)"
  },
  {
    "x": "zangil (n)",
    "y": " b. zanqile (n)"
  },
  {
    "x": "zangiran, -i",
    "y": " b. ziwangiran, -e"
  },
  {
    "x": "zangiraney (m)",
    "y": " b. ziwangiranî (m)"
  },
  {
    "x": "zanik (n)",
    "y": " b. ziwanik (n)"
  },
  {
    "x": "zanist (n)",
    "y": " bilim"
  },
  {
    "x": "zanistê karî (n)",
    "y": " ergonomi"
  },
  {
    "x": "zanistî ra teber",
    "y": " bilim dışı"
  },
  {
    "x": "zanistî, -ye",
    "y": " bilimsel"
  },
  {
    "x": "zanistîtî, -ye",
    "y": " bilimsellik"
  },
  {
    "x": "zanistîyî, -ye",
    "y": " bilimsellik"
  },
  {
    "x": "zanitene",
    "y": " b. zanayene"
  },
  {
    "x": "zanitox, -e",
    "y": " b. zanayox, -e"
  },
  {
    "x": "zanî (n)",
    "y": " diz"
  },
  {
    "x": "zanî ameyene",
    "y": " boyun eğmek, itaat etmek, diz çökmek¸ dize gelmek"
  },
  {
    "x": "zanî ronayene",
    "y": " diz çökmek"
  },
  {
    "x": "zanîameyîş (n)",
    "y": " boyun eğme, itaat etme, diz çökme¸ dize gelme"
  },
  {
    "x": "zanîraantiş (sn)",
    "y": " diz çekim"
  },
  {
    "x": "zanîronayîş (n)",
    "y": " diz çökme"
  },
  {
    "x": "zanîyanseroroniştiş (n)",
    "y": " çöküş"
  },
  {
    "x": "zanox, -e",
    "y": " b. zanayox, -e"
  },
  {
    "x": "zanq (m)",
    "y": " b. zamqe (m)"
  },
  {
    "x": "zanqi (m)",
    "y": " b. zamqe (m)"
  },
  {
    "x": "zanqil (n)",
    "y": " b. zanqile (n)"
  },
  {
    "x": "zanqile (n)",
    "y": " çıngıl, cıngıl, şıngıl (salkım parçası, küçük salkım)"
  },
  {
    "x": "zanqole (n)",
    "y": " b. zanqile (n)"
  },
  {
    "x": "zanû (n)",
    "y": " b. zanî (n)"
  },
  {
    "x": "zanyar, -e",
    "y": " bilgin, âlim, bilim adamı, bilimci"
  },
  {
    "x": "zap-I (n)",
    "y": " b. zompe (m)"
  },
  {
    "x": "zap-II(m)",
    "y": " b. zape (m)"
  },
  {
    "x": "zape (m)",
    "y": " büyük çömlek"
  },
  {
    "x": "zar, -e",
    "y": " zayıf, nahif; cılız, sıska, eneze"
  },
  {
    "x": "zar bîyene",
    "y": " zayıflamak, cılızlaşmak"
  },
  {
    "x": "zar kerdene",
    "y": " zayıflatmak"
  },
  {
    "x": "zar û zêç",
    "y": " çoluk çocuk"
  },
  {
    "x": "zar-zêç",
    "y": " b. zar û zêç"
  },
  {
    "x": "zarab (n)",
    "y": " b. seref (n)"
  },
  {
    "x": "zarac, -e",
    "y": " b. zerenc, -e"
  },
  {
    "x": "zaraj, -e",
    "y": " b. zerenc, -e "
  },
  {
    "x": "zaranc, -e",
    "y": " b. zerenc, -e "
  },
  {
    "x": "zarava kerdene",
    "y": " 1)sohbet etmek\r\n~2)şaka yapmak"
  },
  {
    "x": "zaravakerdiş (n)",
    "y": " 1)sohbet etme\r\n~2)şaka yapma"
  },
  {
    "x": "zarbe (n)",
    "y": " b. zerbe (n)"
  },
  {
    "x": "zarbi (n)",
    "y": " b. zerbe (n)"
  },
  {
    "x": "zarbîyayîş (n)",
    "y": " zayıflama, cılızlaşma"
  },
  {
    "x": "zard, -i-I",
    "y": " bak. zerd, -e"
  },
  {
    "x": "zardik (n)",
    "y": " b. zerdik (n)"
  },
  {
    "x": "zardikê hakî (n)",
    "y": " b. zerdikê hakî (n)"
  },
  {
    "x": "zare-I (n)",
    "y": " safra kesesi "
  },
  {
    "x": "zare-II (n)",
    "y": " b. zere-I (n)"
  },
  {
    "x": "zare-III (m)",
    "y": " b. zerrî (m)"
  },
  {
    "x": "zarec (n)",
    "y": " b. zerenc (n)"
  },
  {
    "x": "zarej, -e",
    "y": " b. zerenc, -e"
  },
  {
    "x": "zarenc, -e",
    "y": " b. zerenc, -e"
  },
  {
    "x": "zarenclîr (n)",
    "y": " b. zerencleyîr (n)"
  },
  {
    "x": "zarênî (m)",
    "y": " zayıflık"
  },
  {
    "x": "zarik (n)",
    "y": " b. zare-I (n)"
  },
  {
    "x": "zarî-I (m)",
    "y": " zayıflık"
  },
  {
    "x": "zarî-II (m)",
    "y": " b. zerrî (m)"
  },
  {
    "x": "zarîye (m)",
    "y": " zayıflık"
  },
  {
    "x": "zarjilonî (m)",
    "y": " b. zacilanî (m)"
  },
  {
    "x": "zarkerdiş (n)",
    "y": " zayıflatma"
  },
  {
    "x": "zarpi (n)",
    "y": " b. zerbe (n)"
  },
  {
    "x": "zarre (m)",
    "y": " b. zerrî (m)"
  },
  {
    "x": "zarre (n)",
    "y": " b. zere-I (n)"
  },
  {
    "x": "zarrî (m)",
    "y": " b. zerrî (m)"
  },
  {
    "x": "zarzawet (n)",
    "y": " b. zerzewat (n)"
  },
  {
    "x": "zarzawut (n)",
    "y": " b. zerzewat (n)"
  },
  {
    "x": "zat (n)",
    "y": " b. zad (n)"
  },
  {
    "x": "zatî, -ye",
    "y": " özel, hususi"
  },
  {
    "x": "zatorî",
    "y": " b. zature-I (n)"
  },
  {
    "x": "zatura (m)",
    "y": " b. zature-I (n)"
  },
  {
    "x": "zature-I (n)",
    "y": " zatürre"
  },
  {
    "x": "zature-II (n)",
    "y": " b. cehtire (n)"
  },
  {
    "x": "zaturîya (m)",
    "y": " b. zature-I (n)"
  },
  {
    "x": "zav",
    "y": " b. zaf"
  },
  {
    "x": "zav ra tanî",
    "y": " b. zaf ra tay"
  },
  {
    "x": "zav û zêç",
    "y": " b. zar û zêç"
  },
  {
    "x": "zav-zêç",
    "y": " b. zar û zêç"
  },
  {
    "x": "zava (n)",
    "y": " b. zama-I (n)"
  },
  {
    "x": "zavane",
    "y": " b. zafane"
  },
  {
    "x": "zavit, -e",
    "y": " b. zabit, -e"
  },
  {
    "x": "zavone",
    "y": " b. zafane"
  },
  {
    "x": "zawit (m)",
    "y": " b. zewte (m)"
  },
  {
    "x": "zawte (m)",
    "y": " b. zewte (m)"
  },
  {
    "x": "zax (n)",
    "y": " b. xizal-I (n)"
  },
  {
    "x": "zaxîri (n)",
    "y": " b. zexîre (n)"
  },
  {
    "x": "zayene",
    "y": " 1)doğurmak (bazı hayvanlar için)\r\n~2)doğmak (bazı hayvanlar için)\r\n~3)kuzulamak, kuzu doğurmak; yavrulamak (doğuran bazı hayvanlar için)"
  },
  {
    "x": "zayeni",
    "y": " b. zayene"
  },
  {
    "x": "zayif, -e",
    "y": " b. zeîf, -e"
  },
  {
    "x": "zayif bîyayîne",
    "y": " b. zeîf bîyene"
  },
  {
    "x": "zayif kerdene",
    "y": " b. zeîf kerdene"
  },
  {
    "x": "zayifbîyayîş (n)",
    "y": " b. zeîfbîyayîş (n)"
  },
  {
    "x": "zayifênî (m)",
    "y": " b. zeîfênî (m) "
  },
  {
    "x": "zayifî (m)",
    "y": " b. zeîfî (m)"
  },
  {
    "x": "zayifîye (m)",
    "y": " b. zeîfîye (m)"
  },
  {
    "x": "zayifkerdiş (n)",
    "y": " b. zeîfkerdiş (n)"
  },
  {
    "x": "zayîato giran (n)",
    "y": " ağır kayıp"
  },
  {
    "x": "zayîato giran ... ginayene",
    "y": " ağır kayba uğramak"
  },
  {
    "x": "zayîş (n)",
    "y": " doğma (bazı hayvanlar için), doğuş (bazı hayvanlar için)"
  },
  {
    "x": "zayîş ra",
    "y": " doğuştan, fıtri, yaratılıştan"
  },
  {
    "x": "zayîş ra reyayeyîya qinesteyî",
    "y": " doğuştan kalça çıkıklığı"
  },
  {
    "x": "zaza, -ye",
    "y": " b. kirmanc, -e "
  },
  {
    "x": "zazakî (m)",
    "y": " b. kirmanckî (m)"
  },
  {
    "x": "ze-I (n)",
    "y": " b. zak (n)"
  },
  {
    "x": "ze-II",
    "y": " b. se-III"
  },
  {
    "x": "zebeş (m)",
    "y": " b. zebeşe (m)"
  },
  {
    "x": "zebeşe (m)",
    "y": " karpuz"
  },
  {
    "x": "zebeşi (m)",
    "y": " b. zebeşe (m)"
  },
  {
    "x": "zebeşroş, -e",
    "y": " karpuzcu"
  },
  {
    "x": "zebr (n)",
    "y": " zebra"
  },
  {
    "x": "zebra (m)",
    "y": " zebra (dişi zebra)"
  },
  {
    "x": "zef",
    "y": " b. zaf"
  },
  {
    "x": "zeferan (n)",
    "y": " safran"
  },
  {
    "x": "zeferane (m)",
    "y": " b. zeferan (n)"
  },
  {
    "x": "zefero (n)",
    "y": " b. zeferan (n)"
  },
  {
    "x": "zeg",
    "y": " b. seke "
  },
  {
    "x": "zege",
    "y": " b. seke, sey-I"
  },
  {
    "x": "zegi",
    "y": " b. seke"
  },
  {
    "x": "zeh bîyene",
    "y": " (gereksiz yere harcanmak) harcanmak"
  },
  {
    "x": "zeh kerdene",
    "y": " (gereksiz yere harcamak) harcamak"
  },
  {
    "x": "zehbîyayîş (n)",
    "y": " (gereksiz yere harcanma) harcanma"
  },
  {
    "x": "zeher (n)",
    "y": " b. zehîr (n)"
  },
  {
    "x": "zehf",
    "y": " b. zaf"
  },
  {
    "x": "zehfane",
    "y": " b. zafane"
  },
  {
    "x": "zehferan (n)",
    "y": " b. zeferan (n)"
  },
  {
    "x": "zehîr (n)",
    "y": " zehir, toksin"
  },
  {
    "x": "zehîr dayene",
    "y": " zehirlemek, ağılamak"
  },
  {
    "x": "zehîr werdene",
    "y": " zehirlenmek"
  },
  {
    "x": "zehîrdayîş (n)",
    "y": " zehirleme, ağılama"
  },
  {
    "x": "zehîrin, -e",
    "y": " zehirli, toksik"
  },
  {
    "x": "zehîrwerdiş (n)",
    "y": " zehirlenme"
  },
  {
    "x": "zehkerdiş (n)",
    "y": " (gereksiz yere harcama) harcama"
  },
  {
    "x": "zehmet, -e",
    "y": " 1)güç, müşkül\r\n~2)güçlük, zahmet"
  },
  {
    "x": "zehmet antene",
    "y": " zahmet çekmek"
  },
  {
    "x": "zehmet beno",
    "y": " zahmet olur"
  },
  {
    "x": "zehmet bîyene",
    "y": " zahmet olmak"
  },
  {
    "x": "zehmet dayene",
    "y": " zahmet vermek"
  },
  {
    "x": "zehmet deyîne",
    "y": " b. zehmet dîyene"
  },
  {
    "x": "zehmet dîyene",
    "y": " zahmet çekmek"
  },
  {
    "x": "zehmet kardene",
    "y": " b. zehmet kerdene"
  },
  {
    "x": "zehmet kerdene",
    "y": " zahmet etmek; zahmete girmek, zahmete katlanmak\r\n~Zehmet meke./Zehmet mekewe./Zehmet mewere. (Zahmet etme.)"
  },
  {
    "x": "zehmet kewtene",
    "y": " zahmet etmek, zahmete girmek, zahmete katlanmak"
  },
  {
    "x": "zehmet kowtene",
    "y": " b. zehmet kewtene"
  },
  {
    "x": "zehmet mebo",
    "y": " zahmet olmazsa"
  },
  {
    "x": "zehmet nêbo",
    "y": " zahmet olmazsa"
  },
  {
    "x": "zehmet nêbo se",
    "y": " b. zehmet nêbo"
  },
  {
    "x": "zehmet ûntene",
    "y": " b. zehmet antene"
  },
  {
    "x": "zehmet veynayîne",
    "y": " b. zehmet vînayene"
  },
  {
    "x": "zehmet vênayene",
    "y": " b. zehmet vînayene"
  },
  {
    "x": "zehmet vînayene",
    "y": " zahmet çekmek"
  },
  {
    "x": "zehmet werdene",
    "y": " zahmet etmek, zahmete girmek, zahmete katlanmak"
  },
  {
    "x": "zehmetê xo ra",
    "y": " zahmet olmazsa"
  },
  {
    "x": "zehmetbîyayîş (n)",
    "y": " çatallaşma (mecazi) "
  },
  {
    "x": "zehmetî (m)",
    "y": " zahmet, zorluk, güçlük"
  },
  {
    "x": "zehmet bîyene, (mecazî)",
    "y": " çatallaşmak (mecazi)"
  },
  {
    "x": "zehmetîye (m)",
    "y": " zorluk, müşkül, güçlük\r\n~zehmetîya banderbîyayîşî (m): öğrenme güçlüğü"
  },
  {
    "x": "zehmetîya banderbîyayîşî (m)",
    "y": " öğrenme güçlüğü"
  },
  {
    "x": "zehmetîya banderbîyayîşî ya taybetî (m)",
    "y": " özel öğrenme güçlüğü"
  },
  {
    "x": "zehmetîya banderbîyayîşî ya xususî (m)",
    "y": " özel öğrenme güçlüğü"
  },
  {
    "x": "zehmetîya hewnî (m)",
    "y": " uyku problemi"
  },
  {
    "x": "zehmetîya musayîşî (m)",
    "y": " öğrenme güçlüğü"
  },
  {
    "x": "zehmetîya musayîşî ya taybetî (m)",
    "y": " özel öğrenme güçlüğü"
  },
  {
    "x": "zehmetîye (m)",
    "y": " zahmet, zorluk, güçlük"
  },
  {
    "x": "zehmetkêş, -e",
    "y": " emekçi"
  },
  {
    "x": "zehr (n)",
    "y": " b. zehîr (n)"
  },
  {
    "x": "zeîf, -e",
    "y": " zayıf, nahif"
  },
  {
    "x": "zeîf bîyene",
    "y": " zayıflamak"
  },
  {
    "x": "zeîf kerdene",
    "y": " zayıflatmak"
  },
  {
    "x": "zeîfbîyayîş (n)",
    "y": " zayıflama"
  },
  {
    "x": "zeîfênî (m)",
    "y": " zayıflık"
  },
  {
    "x": "zeîfî (m)",
    "y": " zayıflık"
  },
  {
    "x": "zeîfîye (m)",
    "y": " zayıflık"
  },
  {
    "x": "zeîfkerdiş (n)",
    "y": " zayıflatma"
  },
  {
    "x": "zek",
    "y": " b. seke"
  },
  {
    "x": "zeka (m)",
    "y": " zekâ"
  },
  {
    "x": "zeke",
    "y": " b. seke"
  },
  {
    "x": "zeki",
    "y": " b. seke"
  },
  {
    "x": "zel-I (n)",
    "y": " sap (bitki için)"
  },
  {
    "x": "zel-II (n)",
    "y": " b. zîl (n)"
  },
  {
    "x": "zel-III (m)",
    "y": " b. zele (m)"
  },
  {
    "x": "zel-IV (n)",
    "y": " parçacık"
  },
  {
    "x": "zelal, -e",
    "y": " 1)duru, berrak\r\n~2)açık, net, bariz"
  },
  {
    "x": "zelal bîyene",
    "y": " 1)berraklaşmak\r\n~2)açıklanmak"
  },
  {
    "x": "zelal kerdene",
    "y": " 1)duru olmasını sağlamak, duru hale getirmek, berraklaştırmak\r\n~2)açıklamak, netleştirmek"
  },
  {
    "x": "zelalê (m)",
    "y": " b. zelalî (m)"
  },
  {
    "x": "zelalî (m)",
    "y": " 1)duruluk, berraklık\r\n~2)açıklık, netlik"
  },
  {
    "x": "zelalîye (m)",
    "y": " 1)duruluk, berraklık\r\n~2)açıklık, netlik"
  },
  {
    "x": "zelalker, -e",
    "y": " açıklayıcı"
  },
  {
    "x": "zelalkerdiş (n)",
    "y": " 1)duru olmasını sağlama, duru hale getirme, berraklaştırma\r\n~2)açıklama, netleştirme"
  },
  {
    "x": "Zelandaya Newîye (m)",
    "y": " Yeni Zelanda"
  },
  {
    "x": "zelbûn, -e",
    "y": " b. zelwan, -e"
  },
  {
    "x": "zele (m)",
    "y": " 1)kaval\r\n~2)kuburluk (barut kabı)"
  },
  {
    "x": "zela barutî (m)",
    "y": " kuburluk (barut kabı)"
  },
  {
    "x": "zelê barutî (m)",
    "y": " b. zela barutî (m)"
  },
  {
    "x": "zeleb (n)",
    "y": " b. seref (n)"
  },
  {
    "x": "zelemele (m)",
    "y": " gürültü patırtı"
  },
  {
    "x": "zeleqîyaya (m)",
    "y": " b. zeliqîyaya (m)"
  },
  {
    "x": "zeleqîyaye (n)",
    "y": " b. zeliqîyaye (n)"
  },
  {
    "x": "zeleqîyayene",
    "y": " b. zeliqîyayene"
  },
  {
    "x": "zeleqîyayî, -ye",
    "y": " b. zeliqîyayî, -ye"
  },
  {
    "x": "zeleqîyayîş (n)",
    "y": " b. zeliqîyayîş (n)"
  },
  {
    "x": "zeleqnayene",
    "y": " b. zeliqnayene"
  },
  {
    "x": "zeleqnayîş (n)",
    "y": " b. zeliqnayîş (n)"
  },
  {
    "x": "zeleqoq, -e",
    "y": " b. zeliqok, -e"
  },
  {
    "x": "zeli (m)",
    "y": " b. zele (m)"
  },
  {
    "x": "zelik (n)",
    "y": " sapçık, çöp"
  },
  {
    "x": "zelikê nimûne (n)",
    "y": " numune çubuğu"
  },
  {
    "x": "zeliqîyaya (m)",
    "y": " yapışık"
  },
  {
    "x": "zeliqîyaye (n)",
    "y": " yapışık"
  },
  {
    "x": "zeliqîyayene",
    "y": " yapışmak"
  },
  {
    "x": "zeliqîyayî, -ye",
    "y": " yapışık"
  },
  {
    "x": "zeliqîyayîş (n)",
    "y": " yapışma"
  },
  {
    "x": "zeliqnayene",
    "y": " yapıştırmak\r\n~Bizeliqne! (Yapıştır!)"
  },
  {
    "x": "zeliqnayîş (n)",
    "y": " yapıştırma"
  },
  {
    "x": "zeliqnayox, -e",
    "y": " yapıştırıcı"
  },
  {
    "x": "zeliqok, -e",
    "y": " yapışkan"
  },
  {
    "x": "zeliqoq, -e",
    "y": " b. zeliqok, -e"
  },
  {
    "x": "zelî (n)",
    "y": " b. zelik (n)"
  },
  {
    "x": "zelliqîyayene",
    "y": " b. zeliqîyayene"
  },
  {
    "x": "zelliqnayene",
    "y": " b. zeliqnayene"
  },
  {
    "x": "zeloq, -e",
    "y": " yapışkan"
  },
  {
    "x": "zelqîyayîş (n)",
    "y": " b. zeliqîyayîş (n)"
  },
  {
    "x": "zelqnayene",
    "y": " b. zeliqnayene"
  },
  {
    "x": "zelvan, -i",
    "y": " b. zelwan, -e"
  },
  {
    "x": "zelvûn (n)",
    "y": " b. zelwan (n)"
  },
  {
    "x": "zelwan, -e",
    "y": " kavalcı (kaval çalan)"
  },
  {
    "x": "zelwûn (n)",
    "y": " b. zelwan (n)"
  },
  {
    "x": "zelzele (n)",
    "y": " deprem, zelzele"
  },
  {
    "x": "zem (n)",
    "y": " çekiştirme"
  },
  {
    "x": "zem kerdene",
    "y": " çekiştirmek, kötülemek, zemmetmek"
  },
  {
    "x": "zemê ... kerdene",
    "y": " çekiştirmek, kötülemek, zemmetmek, aleyhinde konuşmak"
  },
  {
    "x": "zemê ... kerdiş",
    "y": " çekiştirme"
  },
  {
    "x": "zema (n)",
    "y": " b. zeman (n)"
  },
  {
    "x": "zeman (n)",
    "y": " zaman, çağ, vakit"
  },
  {
    "x": "zeman de",
    "y": " vakitli, zamanlı"
  },
  {
    "x": "zeman de bîyaye",
    "y": " vakitli, zamanlı"
  },
  {
    "x": "zeman de bîyayî, -ye",
    "y": " vakitli, zamanlı"
  },
  {
    "x": "zeman girewtene",
    "y": " vakit almak, zaman almak"
  },
  {
    "x": "zeman tal derbaz kerdene",
    "y": " vakit öldürmek, zaman öldürmek"
  },
  {
    "x": "zeman tal vîyarnayene",
    "y": " vakit öldürmek, zaman öldürmek"
  },
  {
    "x": "zeman talderbazkerdiş (n)",
    "y": " vakit öldürme, zaman öldürme"
  },
  {
    "x": "zeman talvîyarnayîş (n)",
    "y": " vakit öldürme, zaman öldürme"
  },
  {
    "x": "zeman veng derbaz kerdene",
    "y": " vakit öldürmek, zaman öldürmek"
  },
  {
    "x": "zeman veng vîyarnayene",
    "y": " vakit öldürmek, zaman öldürmek"
  },
  {
    "x": "zeman vengderbazkerdiş (n)",
    "y": " vakit öldürme, zaman öldürme"
  },
  {
    "x": "zeman vengvîyarnayîş (n)",
    "y": " vakit öldürme, zaman öldürme"
  },
  {
    "x": "zeman vîn kerdene",
    "y": " b. zeman vîndî kerdene"
  },
  {
    "x": "zeman vîndî kerdene",
    "y": " vakit kaybetmek, zaman kaybetmek"
  },
  {
    "x": "zeman vîndîkerdiş",
    "y": " vakit kaybetme, zaman kaybetme   "
  },
  {
    "x": "zeman vînkerdiş (n)",
    "y": " b. zeman vîndîkerdiş"
  },
  {
    "x": "zeman vîyarnayene",
    "y": " vakit geçirmek, zaman geçirmek"
  },
  {
    "x": "zeman vîyarnayîş (n)",
    "y": " vakit geçirme, zaman geçirme"
  },
  {
    "x": "zemanê ... ameyene",
    "y": " zamanı gelmek, çağı gelmek, vakti gelmek"
  },
  {
    "x": "zemanê ... derbaz bîyene",
    "y": " çağı geçmek"
  },
  {
    "x": "zemanê ... girewtene",
    "y": "vaktini almak, zamanını almak"
  },
  {
    "x": "zemanê ... vîyartene",
    "y": " çağı geçmek"
  },
  {
    "x": "zemanê xo de",
    "y": " vaktiyle, bir zamanlar"
  },
  {
    "x": "zemanê xu di",
    "y": " b. zemanê xo de"
  },
  {
    "x": "zemano derg (n)",
    "y": " hayli zaman, uzun zaman"
  },
  {
    "x": "zemano ke",
    "y": " -diği zaman, -diği vakit, ne zaman ki, vakta ki"
  },
  {
    "x": "zemano ki",
    "y": " b. zemano ke"
  },
  {
    "x": "Zemano Paleozoîk (n)",
    "y": " Paleozoik Zaman"
  },
  {
    "x": "zemanê",
    "y": " vaktiyle, bir zamanlar"
  },
  {
    "x": "zemanêk",
    "y": " vaktiyle, bir zamanlar"
  },
  {
    "x": "zemangirewtiş (n)",
    "y": " vakit alma, zaman alma"
  },
  {
    "x": "zemano",
    "y": " b. zemano ke"
  },
  {
    "x": "zemazîq (n)",
    "y": " tahterevalli, çöğüncek"
  },
  {
    "x": "zembîle (m)",
    "y": " sele (büyük sele), altında yiyecek ve eşya saklamak için de kullanılan büyük yayvan sepet "
  },
  {
    "x": "zemîn (n)",
    "y": " taban (bina vb. tabanı)"
  },
  {
    "x": "zemîne (m)",
    "y": " ortam\r\n~zemîneya xebate (n): çalışma ortamı"
  },
  {
    "x": "zemîneya xebate (n)",
    "y": " çalışma ortamı"
  },
  {
    "x": "zemîr (n)",
    "y": " adıl, zamir"
  },
  {
    "x": "zemîrê kesî (n)",
    "y": " şahıs zamiri"
  },
  {
    "x": "zemîrê nedîyarîye (n)",
    "y": " belgisiz zamir"
  },
  {
    "x": "zemîrê nîşankerdene (n)",
    "y": " işaret zamiri"
  },
  {
    "x": "zemîrê persî (n)",
    "y": " soru zamiri"
  },
  {
    "x": "zemîrê wayîrîye (n)",
    "y": " iyelik zamiri, iyelik adılı, mülkiyet zamiri"
  },
  {
    "x": "zemkerdiş (n)",
    "y": " çekiştirme"
  },
  {
    "x": "zemo (n)",
    "y": " b. zeman (n)"
  },
  {
    "x": "zemon (n)",
    "y": " b. zeman (n)"
  },
  {
    "x": "zemon girotene",
    "y": " b. zeman girewtene"
  },
  {
    "x": "zemon vîn kerdene",
    "y": " b. zeman vîndî kerdene"
  },
  {
    "x": "zemon vînkerdiş (n)",
    "y": " b. zeman vîndîkerdiş"
  },
  {
    "x": "zemon vîyarnayene",
    "y": " b. zeman vîyarnayene"
  },
  {
    "x": "zemon vîyarnayîş (n)",
    "y": " b. zeman vîyarnayîş (n)"
  },
  {
    "x": "zemonê ... guretene",
    "y": " b. zemanê ... girewtene"
  },
  {
    "x": "zemonê ... omeyîne",
    "y": " b. zemanê ... ameyene"
  },
  {
    "x": "zemonê xwi di",
    "y": " b. zemanê xo de"
  },
  {
    "x": "zemono derg (n)",
    "y": " b. zemano derg (n)"
  },
  {
    "x": "zemono ki",
    "y": " b. zemano ke"
  },
  {
    "x": "zemonê",
    "y": " b. zemanê"
  },
  {
    "x": "zemonêk",
    "y": " b. zemanêk"
  },
  {
    "x": "zemongirotiş (n)",
    "y": " b. zemangirewtiş (n)"
  },
  {
    "x": "zemonîk",
    "y": " b. zemanêk"
  },
  {
    "x": "zemq (m)",
    "y": " b. zamqe (m)"
  },
  {
    "x": "zemqi (m)",
    "y": " b. zamqe (m)"
  },
  {
    "x": "zemû (n)",
    "y": " b. zeman (n)"
  },
  {
    "x": "zemûn (n)",
    "y": " b. zeman (n)"
  },
  {
    "x": "zemûn guretene",
    "y": " b. zeman girewtene"
  },
  {
    "x": "zemûn vîn kerdene",
    "y": " b. zeman vîndî kerdene"
  },
  {
    "x": "zemûn vînkerdiş (n)",
    "y": " b. zeman vîndîkerdiş"
  },
  {
    "x": "zemûn vîyarnayene",
    "y": " b. zeman vîyarnayene"
  },
  {
    "x": "zemûn vîyarnayîş (n)",
    "y": " b. zeman vîyarnayîş (n)"
  },
  {
    "x": "zemûnî ... girotene",
    "y": " b. zemanê ... girewtene"
  },
  {
    "x": "zemûnî ... ûmeyene",
    "y": " b. zemanê ... ameyene"
  },
  {
    "x": "zemûnî xwe di",
    "y": " b. zemanê xo de"
  },
  {
    "x": "zemûno derg (n)",
    "y": " b. zemano derg (n)"
  },
  {
    "x": "zemûno g’",
    "y": " b. zemano ke"
  },
  {
    "x": "zemûnê",
    "y": " b. zemanê"
  },
  {
    "x": "zemûnguretiş (n)",
    "y": " b. zemangirewtiş (n)"
  },
  {
    "x": "zenat (n)",
    "y": " b. senet (n)"
  },
  {
    "x": "zencefîl (n)",
    "y": " zencefil"
  },
  {
    "x": "zencîl (m)",
    "y": " b. zincîre (m)"
  },
  {
    "x": "zencîr (m)",
    "y": " b. zincîre (m)"
  },
  {
    "x": "zencîre (m)",
    "y": " b. zincîre (m)"
  },
  {
    "x": "zend (n)",
    "y": " dirsek, önkol"
  },
  {
    "x": "zenderme (n)",
    "y": " b. cendirme (n)"
  },
  {
    "x": "zendik (n)",
    "y": " kolluk, kolçak (çalışırken giysiyi korumak için kullanılan)"
  },
  {
    "x": "zenet (n)",
    "y": " b. zîret (n)"
  },
  {
    "x": "zeng-I (n)",
    "y": " zil; çan, kampana"
  },
  {
    "x": "zeng-II (m)",
    "y": " b. zenge (m)"
  },
  {
    "x": "zeng bestene",
    "y": " b. zenge bestene"
  },
  {
    "x": "zengar-I (m)",
    "y": " b. zengare (m)"
  },
  {
    "x": "zengar-II (n)",
    "y": " bakır çalığı"
  },
  {
    "x": "zengar bestene",
    "y": " b. zengare bestene"
  },
  {
    "x": "zengar girotene",
    "y": " b. zengare girewtene"
  },
  {
    "x": "zengare (m)",
    "y": " pas"
  },
  {
    "x": "zengare bestene",
    "y": " pas tutmak, paslanmak"
  },
  {
    "x": "zengare girewtene",
    "y": " pas tutmak, paslanmak"
  },
  {
    "x": "zengarebestiş (n)",
    "y": " paslanma"
  },
  {
    "x": "zengaregirewtiş (n)",
    "y": " paslanma"
  },
  {
    "x": "zengari (m)",
    "y": " b. zengare (m)"
  },
  {
    "x": "zengari bestene",
    "y": " b. zengare bestene"
  },
  {
    "x": "zengari girotene",
    "y": " b. zengare girewtene"
  },
  {
    "x": "zengarin, -e",
    "y": " paslı"
  },
  {
    "x": "zenge-I (m)",
    "y": " pas"
  },
  {
    "x": "zenge bestene",
    "y": " pas tutmak, paslanmak"
  },
  {
    "x": "zenge girewtene",
    "y": " pas tutmak, paslanmak"
  },
  {
    "x": "zenge perayene bide",
    "y": " pas kapmak"
  },
  {
    "x": "zenge-II (n)",
    "y": " b. zengen (n)"
  },
  {
    "x": "zengebestiş (n)",
    "y": " paslanma"
  },
  {
    "x": "zengegirewtiş (n)",
    "y": " paslanma"
  },
  {
    "x": "zengel (n)",
    "y": " b. zengen (n)"
  },
  {
    "x": "zengen (n)",
    "y": " kazma"
  },
  {
    "x": "zengene (n)",
    "y": " b. zengen (n)"
  },
  {
    "x": "zengi (m)",
    "y": " b. zenge (m)"
  },
  {
    "x": "zengi bestene",
    "y": " b. zenge bestene"
  },
  {
    "x": "zengil-I (n)",
    "y": " zil; çan, kampana"
  },
  {
    "x": "zengil cenayene",
    "y": " zil çalmak\r\n~Zengilî cena. (Zil çaldı.)"
  },
  {
    "x": "zengil cinitene",
    "y": " zil çalmak\r\n~Zengilî cinit. (Zil çaldı.)"
  },
  {
    "x": "zengil piro dayene",
    "y": " zil çalmak\r\n~Zengilî da piro. (Zil çaldı.)"
  },
  {
    "x": "zengil-II (n)",
    "y": " b. zanqile (n)"
  },
  {
    "x": "zengile (n)",
    "y": " b. zengen (n) "
  },
  {
    "x": "zengilok (n)",
    "y": " âdemelması (gırtlak çıkıntısı)"
  },
  {
    "x": "zengin, -e",
    "y": " paslı"
  },
  {
    "x": "zengini (n)",
    "y": " b. zengen (n)"
  },
  {
    "x": "zengiri (n)",
    "y": " b. zingire (n)"
  },
  {
    "x": "zengî (n)",
    "y": " b. zengu (n)"
  },
  {
    "x": "zengîn, -e",
    "y": " zengin, varlıklı"
  },
  {
    "x": "zengîney (m)",
    "y": " b. zengînî (m), zengînîye (m)"
  },
  {
    "x": "zengînê (m)",
    "y": " b. zengînî (m), zengînîye (m)"
  },
  {
    "x": "zengînênî (m)",
    "y": " zenginlik, varlık, varlıklılık"
  },
  {
    "x": "zengînî (m)",
    "y": " zenginlik, varlık, varlıklılık"
  },
  {
    "x": "zengînîye (m)",
    "y": " zenginlik, varlık, varlıklılık"
  },
  {
    "x": "zengni (n)",
    "y": " b. zengen (n) "
  },
  {
    "x": "zengu (n)",
    "y": " üzengi"
  },
  {
    "x": "zengul (n)",
    "y": " b. zengil-I (n)"
  },
  {
    "x": "zengû (n)",
    "y": " b. zengu (n)"
  },
  {
    "x": "zenî",
    "y": " b. senî "
  },
  {
    "x": "zep bîyayene",
    "y": " b. zep bîyene"
  },
  {
    "x": "zep bîyene",
    "y": " şişmek"
  },
  {
    "x": "zep kardene",
    "y": " b. zep kerdene"
  },
  {
    "x": "zep kerdene",
    "y": " şişirmek"
  },
  {
    "x": "zepi (m)",
    "y": " b. zompe (m)"
  },
  {
    "x": "zepikîyayene",
    "y": " şişmek"
  },
  {
    "x": "zepiknayene",
    "y": " şişirmek"
  },
  {
    "x": "zer-I (m)",
    "y": " b. zerrî (m)"
  },
  {
    "x": "zer debîyayene",
    "y": " b. zerrî debîyene"
  },
  {
    "x": "zer pê şîyayîne",
    "y": " b. zerrî pê şîyene"
  },
  {
    "x": "zer qelivîyayene",
    "y": " b. zerrî qelibîyayene"
  },
  {
    "x": "zer qelivnayene",
    "y": " b. zerrî qelibnayene"
  },
  {
    "x": "zer zîz bîyayene",
    "y": " b. zerrî zîz bîyene"
  },
  {
    "x": "zer zîz kerdene",
    "y": " b. zerrî zîz kerdene"
  },
  {
    "x": "zer-II",
    "y": " b. zerd"
  },
  {
    "x": "zeranc, -e",
    "y": " b. zerenc, -e"
  },
  {
    "x": "zeranci (m)",
    "y": " b. zerence (m)"
  },
  {
    "x": "zerar (n)",
    "y": " zarar"
  },
  {
    "x": "zerar dayene",
    "y": " zarar vermek"
  },
  {
    "x": "zerar dîyene",
    "y": " zarar görmek, zarara uğramak"
  },
  {
    "x": "zerar kerdene",
    "y": " zarar etmek\r\n~Zerar nêkeno. (1-Zararı yok. 2-Önemli değil.)\r\n~Zerarê xo çin o. (1-Zararı yok. 2-Önemli değil.)"
  },
  {
    "x": "zeraro giran (n)",
    "y": " ağır kayıp"
  },
  {
    "x": "zeraro giran ginayene ...",
    "y": " ağır kayba uğramak"
  },
  {
    "x": "zeraro vêşî (n)",
    "y": " ağır kayıp"
  },
  {
    "x": "zerardar, -e",
    "y": " zararlı"
  },
  {
    "x": "zerarkar, -e",
    "y": " zararlı"
  },
  {
    "x": "zeravîye kerdene",
    "y": " b. zarava kerdene"
  },
  {
    "x": "zeravîyekerdiş (n)",
    "y": " b. zaravakerdiş (n)"
  },
  {
    "x": "zerbe (n)",
    "y": " sürgü-kilit (sürgülü kapılarda) "
  },
  {
    "x": "zerçal (m)",
    "y": " b. zerencçale (m)"
  },
  {
    "x": "zerd, -e-I",
    "y": " sarı "
  },
  {
    "x": "zerdê hakî (n)",
    "y": " yumurta sarısı"
  },
  {
    "x": "zerdî hakî (n)",
    "y": " b. zerdê hakî (n)"
  },
  {
    "x": "zêrd hakî (n)",
    "y": " b. zerdê hakî (n)"
  },
  {
    "x": "zerd-II (n)",
    "y": " altın"
  },
  {
    "x": "zerdalî (m)",
    "y": " zerdali"
  },
  {
    "x": "zerdalîye (m)",
    "y": " zerdali"
  },
  {
    "x": "zerdalîyêre (m)",
    "y": " zerdali ağacı"
  },
  {
    "x": "zerdalîyêri (m)",
    "y": " b. zerdalîyêre (m)"
  },
  {
    "x": "zerdawe (m)",
    "y": " öd, öt, safra"
  },
  {
    "x": "zerdawi (m)",
    "y": " b. zerdawe (m)"
  },
  {
    "x": "zerde (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "zerdebîyayîş (n)",
    "y": " b. zerrîdebîyayîş (n)"
  },
  {
    "x": "zerdeçal (n)",
    "y": " zerdeçal"
  },
  {
    "x": "zerdik (n)",
    "y": " yumurta sarısı"
  },
  {
    "x": "zerdikê hakî (n)",
    "y": " yumurta sarısı"
  },
  {
    "x": "zerdo (n)",
    "y": " bir kuş çeşidi"
  },
  {
    "x": "zerdroş, -e",
    "y": " altıncı (altın alıp satan kimse)"
  },
  {
    "x": "zere-I (n)",
    "y": " 1)iç, içeri\r\n~2)cezaevi (mecazî), içeri (mecazen cezaevi)\r\n~3)karın"
  },
  {
    "x": "zereyê xo de vatene",
    "y": " düşünmek"
  },
  {
    "x": "zere dejayene",
    "y": " karnı ağrımak"
  },
  {
    "x": "zere girewtene",
    "y": " karnı ağrımak (karnı ağrımaya başlamak)"
  },
  {
    "x": "zere şîyene",
    "y": " ishal olmak"
  },
  {
    "x": "zere-II (m)",
    "y": " b. zerrî (m)"
  },
  {
    "x": "zere hurêkêwtene",
    "y": " b. zerrî hurêkewtene"
  },
  {
    "x": "zere têwefiştaene",
    "y": " b. zerrî têwfîstene"
  },
  {
    "x": "zere têwekerdene",
    "y": " b. zerrî têwkerdene"
  },
  {
    "x": "zere têwekotene",
    "y": " b. zerrî têwkewtene"
  },
  {
    "x": "Zere-III (m)",
    "y": " inek için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "zerec, -e",
    "y": " b. zerenc, -e"
  },
  {
    "x": "zerecçal (m)",
    "y": " b. zerencçale (m)"
  },
  {
    "x": "zerecçale (m)",
    "y": " b. zerencçale (m)"
  },
  {
    "x": "zereci (m)",
    "y": " b. zerence (m)"
  },
  {
    "x": "zereçale (m)",
    "y": " b. zerencçale (m)"
  },
  {
    "x": "zeredej (n)",
    "y": " karın ağrısı"
  },
  {
    "x": "zerehoke (m)",
    "y": " iç açı"
  },
  {
    "x": "zerehokin, -e",
    "y": " iç açılı"
  },
  {
    "x": "zerehurêkêwtis (n)",
    "y": " b. zerrîhurêkewtiş (n)"
  },
  {
    "x": "zerehurêkêwtiş (n)",
    "y": " b. zerrîhurêkewtiş (n)"
  },
  {
    "x": "zerej, -e",
    "y": " b. zerenc, -e "
  },
  {
    "x": "zereji (m)",
    "y": " b. zerence (m)"
  },
  {
    "x": "zeremekan (n)",
    "y": " iç mekân"
  },
  {
    "x": "zerenc, -e",
    "y": " keklik"
  },
  {
    "x": "zerenca gozele (m)",
    "y": " kınalı keklik"
  },
  {
    "x": "zerenca gozere (m)",
    "y": " kınalı keklik"
  },
  {
    "x": "zerencçale (m)",
    "y": " keklik yuvası"
  },
  {
    "x": "zerenci (m)",
    "y": " b. zerence (m)"
  },
  {
    "x": "zerencleyîr (n)",
    "y": " küçük yaştaki erkek keklik"
  },
  {
    "x": "zerenç, -e",
    "y": " b. zerenc, -e"
  },
  {
    "x": "zereşîyayîş (n)",
    "y": " 1)amel, ishal, ötürük, sürgün\r\n~2)ishal olma"
  },
  {
    "x": "zeretêwefîştaîs (n)",
    "y": " b. zerrîtêwfîstiş (n)"
  },
  {
    "x": "zeretêwekerdis (n)",
    "y": " b. zerrîtêwkerdiş (n)"
  },
  {
    "x": "zeretêwekotis (n)",
    "y": " b. zerrîtêwkewtiş (n)"
  },
  {
    "x": "zereyêxodevatiş (n)",
    "y": " düşünme"
  },
  {
    "x": "zerez, -e",
    "y": " b. zerenc, -e"
  },
  {
    "x": "zereze (n)",
    "y": " b. zerze (n)"
  },
  {
    "x": "zerfe (m)",
    "y": " 1)zarf\r\n~2)zarf, belirteç\r\n~zerfa cayî (m): yer zarfı, mekân zarfı"
  },
  {
    "x": "zerfa cayî (m)",
    "y": " yer zarfı, mekân zarfı"
  },
  {
    "x": "zerfa demî (m)",
    "y": " zaman zarfı"
  },
  {
    "x": "zerfa hetî (m)",
    "y": " yön bildiren zarf "
  },
  {
    "x": "zerfa persî (m)",
    "y": " soru zarfı "
  },
  {
    "x": "zerfa rewşe (m)",
    "y": " durum zarfı "
  },
  {
    "x": "zerfkî",
    "y": " zarfsal"
  },
  {
    "x": "zergu",
    "y": " b. zergûn, -e"
  },
  {
    "x": "zergun, -e",
    "y": " b. zergûn, -e"
  },
  {
    "x": "zergun bîyayene",
    "y": " b. zergûn bîyene"
  },
  {
    "x": "zergû",
    "y": " b. zergûn, -e"
  },
  {
    "x": "zergûn, -e",
    "y": " sarıya çalan yeşil"
  },
  {
    "x": "zergûn bîyene",
    "y": " filizlenmek, filiz vermek"
  },
  {
    "x": "zeri-I (m)",
    "y": " b. zerrî (m)"
  },
  {
    "x": "zeri-II (n)",
    "y": " b. zere-I (n)"
  },
  {
    "x": "zeri (m)",
    "y": " b. zere (m)"
  },
  {
    "x": "zerik (n)",
    "y": " 1)sarılık \r\n~zeriko sîya (n): hepatit B\r\n~2)şap hastalığı "
  },
  {
    "x": "zerik bîyene",
    "y": " sarılığa yakalanmak, sarılık olmak"
  },
  {
    "x": "zeriko sîya (n)",
    "y": " hepatit B"
  },
  {
    "x": "zeriki (m)",
    "y": " b. zerik (n)"
  },
  {
    "x": "zerinc, -e",
    "y": " b. zerenc, -e "
  },
  {
    "x": "zerinci",
    "y": " b. zerence (m)"
  },
  {
    "x": "zerî (m)",
    "y": " b. zerrî (m)"
  },
  {
    "x": "zerî ra",
    "y": " b. zerrî ra"
  },
  {
    "x": "zerk, -e-I",
    "y": " b. zerd, -e"
  },
  {
    "x": "zerk-II (n)",
    "y": " b. zerik (n)"
  },
  {
    "x": "zerk bîyayene",
    "y": " b. zerik bîyene"
  },
  {
    "x": "zerko sîya (n)",
    "y": " b. zeriko sîya (n)"
  },
  {
    "x": "zern (n)",
    "y": " b. zerrn (n)"
  },
  {
    "x": "zernemir, -i",
    "y": " b. zerrînerm, -e"
  },
  {
    "x": "zernerm, -i",
    "y": " b. zerrînerm, -e"
  },
  {
    "x": "zernin, -e",
    "y": " b. zerrnin, -e"
  },
  {
    "x": "Zero (n)",
    "y": " öküz için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "zerp (m)",
    "y": " b. zerpe (m)"
  },
  {
    "x": "zerpe-I (m)",
    "y": " afi, caka, fiyaka, çalım, gösteriş"
  },
  {
    "x": "zerpê xo nawitene",
    "y": " afi kesmek, afi satmak, afi yapmak, caka satmak, caka yapmak, fiyaka satmak, çalım satmak, gösteriş yapmak"
  },
  {
    "x": "zerpe-II (n)",
    "y": " b. zerbe (n)"
  },
  {
    "x": "zerpêtey (m)",
    "y": " b. zerrîpêtî (m)"
  },
  {
    "x": "zerpêtê (m)",
    "y": " b. zerrîpêtî (m)"
  },
  {
    "x": "zerpêtî (m)",
    "y": " b. zerrîpêtî (m)"
  },
  {
    "x": "zerpi (m)",
    "y": " b. zerpe (m)"
  },
  {
    "x": "zerq (n)",
    "y": " 1)güneş ışını\r\n~2) güneşin doğarken ya da batarken saçtığı kızıllık, ışık hüzmesi"
  },
  {
    "x": "zerqê rojî (n)",
    "y": " güneşin doğarken ya da batarken saçtığı kızıllık"
  },
  {
    "x": "zerqe (m)",
    "y": " b. zerq (n)"
  },
  {
    "x": "zerqelivîyayîş (n)",
    "y": " b. zerrîqelibîyayîş (n)"
  },
  {
    "x": "zerqelivnayîş (n)",
    "y": " b. zerrîqelibnayîş (n)"
  },
  {
    "x": "zerqet (m)",
    "y": " b. zerqete (m)"
  },
  {
    "x": "zerqete (m)",
    "y": " yabanarısı"
  },
  {
    "x": "zerqeti (m)",
    "y": " b. zerqete (m) "
  },
  {
    "x": "zerqetik (m)",
    "y": " b. zerqete (m) "
  },
  {
    "x": "zerr-I (m)",
    "y": " b. zerrî (m)"
  },
  {
    "x": "zerr dayene",
    "y": " b. zerrî dayene"
  },
  {
    "x": "zerr şiktene",
    "y": " b. zerrî şikitene"
  },
  {
    "x": "zerr şikyayene",
    "y": " b. zerrî şikîyayene"
  },
  {
    "x": "zerr ... şîyene",
    "y": " b. zerrî şîyene ..."
  },
  {
    "x": "zerr-II (n)",
    "y": " b. zere-I (n)"
  },
  {
    "x": "zerr gurewtene",
    "y": " b. zere girewtene"
  },
  {
    "x": "zerrdayîş (n)",
    "y": " b. zerrîdayîş (n)"
  },
  {
    "x": "zerre-I (m)",
    "y": " b. zerrî (m)"
  },
  {
    "x": "zerre hurêfîstene",
    "y": " b. zerrî hurêfîstene"
  },
  {
    "x": "zerre hurêkewtene",
    "y": " b. zerrî hurêkewtene"
  },
  {
    "x": "zerre qelebîyayene",
    "y": " b. zerrî qelibîyayene"
  },
  {
    "x": "zerre şikitene",
    "y": " b. zerrî şikitene"
  },
  {
    "x": "zerre şikîyayene",
    "y": " b. zerrî şikîyayene"
  },
  {
    "x": "zerre têwfîstene",
    "y": " b. zerrî têwfîstene"
  },
  {
    "x": "zerre têwkerdene",
    "y": " b. zerrî têwkerdene"
  },
  {
    "x": "zerre têwkewtene",
    "y": " b. zerrî têwkewtene"
  },
  {
    "x": "zerre tîodayene",
    "y": " b. zerrî têwdayene"
  },
  {
    "x": "zerre tîyodayene",
    "y": " b. zerrî têwdayene"
  },
  {
    "x": "zerre tîyodîyayene",
    "y": " b. zerrî têwdîyayene"
  },
  {
    "x": "zerre-II (n)",
    "y": " b. zere-I (n)"
  },
  {
    "x": "zerre decayene",
    "y": " b. zere dejayene"
  },
  {
    "x": "zerre dejayene",
    "y": " b. zere dejayene"
  },
  {
    "x": "zerre dezayene",
    "y": " b. zere dejayene"
  },
  {
    "x": "zerre girewtene",
    "y": " b. zere girewtene"
  },
  {
    "x": "zerre girotene",
    "y": " b. zere girewtene"
  },
  {
    "x": "zerre guretene",
    "y": " b. zere girewtene"
  },
  {
    "x": "zerre şîyayene",
    "y": " b. zere şîyene"
  },
  {
    "x": "zerre şîyene",
    "y": " b. zere şîyene"
  },
  {
    "x": "zerreçole (m)",
    "y": " b. zerencçale (m)"
  },
  {
    "x": "zerredec (n)",
    "y": " b. zeredej (n)"
  },
  {
    "x": "zerredej (n)",
    "y": " b. zeredej (n)"
  },
  {
    "x": "zerredez (n)",
    "y": " b. zeredej (n)"
  },
  {
    "x": "zerrehurêfîstiş (n)",
    "y": " b. zerrîhurêfîstiş (n)"
  },
  {
    "x": "zerrehurêkewtiş (n)",
    "y": " b. zerrîhurêkewtiş (n)"
  },
  {
    "x": "zerrenerm, -i",
    "y": " b. zerrînerm, -e"
  },
  {
    "x": "zerrepêtey (m)",
    "y": " b. zerrîpêtî (m), zerrîpêtîye (m)"
  },
  {
    "x": "zerreşîyayîş (n)",
    "y": " b. zereşîyayîş (n)"
  },
  {
    "x": "zerretenik, -e",
    "y": " b. zerrîtenik, -e"
  },
  {
    "x": "zerretêkewtiş (n)",
    "y": " b. zerrîtêwkewtiş (n)"
  },
  {
    "x": "zerretêwfîstiş (n)",
    "y": " b. zerrîtêwfîstiş (n)"
  },
  {
    "x": "zerretêwkerdiş (n)",
    "y": " b. zerrîtêwkerdiş (n)"
  },
  {
    "x": "zerretêwkewtiş (n)",
    "y": " b. zerrîtêwkewtiş (n)"
  },
  {
    "x": "zerretîodayîş (n)",
    "y": " b. zerrîtêwdayîş (n)"
  },
  {
    "x": "zerretîyodayîş (n)",
    "y": " b. zerrîtêwdayîş (n)"
  },
  {
    "x": "zerre tîyodayene",
    "y": " b. zerrî têwdayene"
  },
  {
    "x": "zerrewişkey (m)",
    "y": " b. zerrîhuşkîye (m)"
  },
  {
    "x": "zerrezîz, -e",
    "y": " b. zerrîzîz, -e"
  },
  {
    "x": "zerrezîzey (m)",
    "y": " b. zerrîzîzî (m), zerrîzîzîye (m)"
  },
  {
    "x": "zerrên, -e",
    "y": " altından yapılma"
  },
  {
    "x": "zerri-I (n)",
    "y": " b. zere-I (n)"
  },
  {
    "x": "zerri-II (m)",
    "y": " b. zerrî (m)"
  },
  {
    "x": "zerri bede şîyayîne",
    "y": " b. zerrî bide şîyene"
  },
  {
    "x": "zerri bere şîyayîne",
    "y": " b. zerrî bide şîyene"
  },
  {
    "x": "zerri bide şîyayîne",
    "y": " b. zerrî bide şîyene"
  },
  {
    "x": "zerri debîyayene",
    "y": " b. zerrî debîyene"
  },
  {
    "x": "zerri dekewtene",
    "y": " b. zerrî dekewtene"
  },
  {
    "x": "zerri dekowtene",
    "y": " b. zerrî dekewtene"
  },
  {
    "x": "zerri devistene",
    "y": " b. zerrî devistene"
  },
  {
    "x": "zerri qelebîyayene",
    "y": " b. zerrî qelibîyayene"
  },
  {
    "x": "zerri qelebnayene",
    "y": " b. zerrî qelibnayene"
  },
  {
    "x": "zerri şikitene",
    "y": " b. zerrî şikitene"
  },
  {
    "x": "zerri şikîyayene",
    "y": " b. zerrî şikîyayene"
  },
  {
    "x": "zerri têdayene",
    "y": " b. zerrî têwdayene"
  },
  {
    "x": "zerri têdîyayene",
    "y": " b. zerrî têwdîyayene"
  },
  {
    "x": "zerri zîz bîyayene",
    "y": " b. zerrî zîz bîyene"
  },
  {
    "x": "zerri zîz kerdene",
    "y": " b. zerrî zîz kerdene"
  },
  {
    "x": "zerrizîz bîyayene",
    "y": " b. zerrîzîz bîyene"
  },
  {
    "x": "zerrizîz kerdene",
    "y": " b. zerrîzîz kerdene"
  },
  {
    "x": "zerridebîyayîş (n)",
    "y": " b. zerrîdebîyayîş (n)"
  },
  {
    "x": "zerrizîzbîyayîş (n)",
    "y": " b. zerrîzîzbîyayîş (n)"
  },
  {
    "x": "zerrizîzkerdiş (n)",
    "y": " b. zerrîzîzkerdiş (n)"
  },
  {
    "x": "zerrizîzbîyayîş (n)",
    "y": " b. zerrîzîzbîyayîş (n)"
  },
  {
    "x": "zerrizîzkerdiş (n)",
    "y": " b. zerrîzîzkerdiş (n)"
  },
  {
    "x": "zerrî (m)",
    "y": " kalp, yürek, gönül, canevi"
  },
  {
    "x": "zerrî bede şîyayene",
    "y": " b. zerrî ... şîyene"
  },
  {
    "x": "zerrî beder şîyayene",
    "y": " b. zerrî ... şîyene"
  },
  {
    "x": "zerrî bere şîyayene",
    "y": " b. zerrî ... şîyene"
  },
  {
    "x": "zerrî bide şîyayene",
    "y": " b. zerrî ... şîyene"
  },
  {
    "x": "zerrî bide şîyene",
    "y": " (bir şeyi) yemek istemek"
  },
  {
    "x": "zerrî bider şîyayene",
    "y": " b. zerrî ... şîyene"
  },
  {
    "x": "zerrî bijîyayene ...",
    "y": " canı istemek, canı çekmek"
  },
  {
    "x": "zerrî bijyayene ...",
    "y": " b. zerrî bijîyayene ..."
  },
  {
    "x": "zerrî ci fiştene",
    "y": " b. zerrî ci fîştene"
  },
  {
    "x": "zerrî ci fîştene",
    "y": " gönül vermek, âşık olmak, sevmek"
  },
  {
    "x": "zerrî ci kerdene",
    "y": " gönül vermek, âşık olmak, sevmek"
  },
  {
    "x": "zerrî ci kewtene",
    "y": " gönül vermek, âşık olmak, sevmek"
  },
  {
    "x": "zerrî ci şîyayene",
    "y": " b. zerrî ... şîyene"
  },
  {
    "x": "zerrî dayene",
    "y": " cesaretlendirmek, cesaret vermek, yüreklendirmek"
  },
  {
    "x": "zerrî debîyene",
    "y": " duygulanmak, hislenmek"
  },
  {
    "x": "zerrî dekewtene",
    "y": " gönül vermek, âşık olmak, sevmek\r\n~Zerrîya Zîne dekewtibî Memî. (Zîn Mem’i sevmişti./Zîn Mem’e âşık olmuştu.)"
  },
  {
    "x": "zerrî dekewtene yewbînan",
    "y": " birbirine âşık olmak"
  },
  {
    "x": "zerrî dekewtene yewbînî",
    "y": " birbirine âşık olmak\r\n~Zerrîya Zîne û Memî dekewtibî yewbînî. (Zîn ile Mem birbirlerine âşık olmuşlardı.)"
  },
  {
    "x": "zerrî devistene",
    "y": " gönül vermek, âşık olmak, sevmek\r\n~Memî zerrî devista Zîne. (Mem Zîn’e gönül vermiş/âşık olmuş.)"
  },
  {
    "x": "zerrî devistene yewbînan",
    "y": " birbirine âşık olmak"
  },
  {
    "x": "zerrî devistene yewbînî",
    "y": " birbirine âşık olmak\r\n~Zîne û Memî zerrî devistibî yewbînî. (Zîn ile Mem birbirlerine âşık olmuşlardı.)"
  },
  {
    "x": "zerrî hurefîştene",
    "y": " b. zerrî hurê fîstene"
  },
  {
    "x": "zerrî hurekewtene",
    "y": " b. zerrî hurêkewtene"
  },
  {
    "x": "zerrî hurêfîstene",
    "y": " mide bulandırmak"
  },
  {
    "x": "zerrî hurêkewtene",
    "y": " mide(si) bulanmak"
  },
  {
    "x": "zerrî perayene ...",
    "y": " canı istemek, canı çekmek\r\n~\tZerrîya mi peray engure. (Canım üzüm istedi.)"
  },
  {
    "x": "zerrî perrayene ...",
    "y": " b. zerrî perayene ..."
  },
  {
    "x": "zerrî pey şîyayene",
    "y": " b. zerrî pê şîyene"
  },
  {
    "x": "zerrî pey şîyayîne",
    "y": " b. zerrî pê şîyene"
  },
  {
    "x": "zerrî pê şîyene",
    "y": " (bir şeyi) yemek istemek\r\n~Zerrîya mi zaf pê şina. (Yemeyi çok istiyorum.)"
  },
  {
    "x": "zerrî piranayene",
    "y": " gönül vermek, âşık olmak, sevmek\r\n~Ferhad zerrî nano Şîrine ra. (Ferhad Şirin’e gönül verir/âşık olur.)"
  },
  {
    "x": "zerrî qelebîyayene",
    "y": " b. zerrî qelibîyayene"
  },
  {
    "x": "zerrî qelebnayene",
    "y": " b. zerrî qelibnayene"
  },
  {
    "x": "zerrî qelbinayene",
    "y": " b. zerrî qelibnayene"
  },
  {
    "x": "zerrî qelbnayene",
    "y": " b. zerrî qelibnayene"
  },
  {
    "x": "zerrî qelibîyayene",
    "y": " mide(si) bulanmak\r\n~Zerrîya ey qelibîyaya. (Midesi bulanmış.)"
  },
  {
    "x": "zerrî qelibîyayeni",
    "y": " b. zerrî qelibîyayene"
  },
  {
    "x": "zerrî qelibnayene",
    "y": " mide bulandırmak"
  },
  {
    "x": "zerrî ra",
    "y": " canıgönülden, canla başla"
  },
  {
    "x": "zerrî ... ra mendene",
    "y": " (mecazi) alınmak"
  },
  {
    "x": "zerrî şikitene",
    "y": " üzmek"
  },
  {
    "x": "zerrî şikîyayene",
    "y": " üzülmek"
  },
  {
    "x": "zerrî şîyayene ...",
    "y": " b. zerrî şîyene ..."
  },
  {
    "x": "zerrî şîyene ...",
    "y": " canı istemek, canı çekmek, (bir şeyi) yemek istemek\r\n~Ewro zerrîya to şina çi? (Bugün ne yemek istiyorsun?/Bugün canın ne [yemek] istiyor?)"
  },
  {
    "x": "zerrî ... şîyene",
    "y": " canı istemek, canı çekmek, (bir şeyi) yemek istemek\r\n~Zerrîya mi engure şina. (Canım üzüm istiyor.)"
  },
  {
    "x": "zerrî têdayene",
    "y": " b. zerrî têwdayene"
  },
  {
    "x": "zerrî têdîyayene",
    "y": " b. zerrî têwdîyayene"
  },
  {
    "x": "zerrî têkewtene",
    "y": " b. zerrî têwkewtene"
  },
  {
    "x": "zerrî têwdayene",
    "y": " mide bulandırmak"
  },
  {
    "x": "zerrî têwdîyayene",
    "y": " mide(si) bulanmak\r\n~Zerrîya ey têwdîyaya. (Midesi bulanmış.)"
  },
  {
    "x": "zerrî têwfîştene",
    "y": " mide bulandırmak"
  },
  {
    "x": "zerrî têwkerdene",
    "y": " mide bulandırmak"
  },
  {
    "x": "zerrî têwkewtene",
    "y": " mide(si) bulanmak"
  },
  {
    "x": "zerrî tîodîyayene",
    "y": " b. zerrî têwdîyayene"
  },
  {
    "x": "zerrî wereykewtene",
    "y": " b. zerrî hurêkewtene"
  },
  {
    "x": "zerrî zîz bîyene",
    "y": " duygulanmak, hislenmek"
  },
  {
    "x": "zerrî zîz kerdene",
    "y": " duygulandırmak"
  },
  {
    "x": "zerrîya bele (m)",
    "y": " kalp, yürek"
  },
  {
    "x": "zerrîya ...  ... ra weş bîyene",
    "y": " -den hoşnut olmak\r\n~Wa zerrîya şarî to ra weş bo. (Halk senden hoşnut olsun.)"
  },
  {
    "x": "zerrîya ... rê socîyayene",
    "y": " çok acımak\r\n~Zerrîya mi Mence rê socîyaye. (Mence’ye çok acıdım.)"
  },
  {
    "x": "zerrîya ... rê veşayene",
    "y": " çok acımak"
  },
  {
    "x": "zerrîya ... rê vêsayene",
    "y": " b. zerrîya ... rê veşayene "
  },
  {
    "x": "zerrîya ... rêsocîyayîş",
    "y": " çok acıma"
  },
  {
    "x": "zerrîya ... rêveşayîş",
    "y": " çok acıma"
  },
  {
    "x": "zerrîya ... rêvêsayîş",
    "y": " b. zerrîya ... rêveşayîş"
  },
  {
    "x": "zerrîya safî ra",
    "y": " canıgönülden"
  },
  {
    "x": "zerrîya ...  ... waştene",
    "y": " canı istemek, canı çekmek"
  },
  {
    "x": "zerrîya ... weş bîyene",
    "y": " hoşnut olmak"
  },
  {
    "x": "zerrîya ... weş kerdene",
    "y": " hoşnut etmek"
  },
  {
    "x": "zerrîcifiştiş (n)",
    "y": " b. zerrîcifîştiş (n)"
  },
  {
    "x": "zerrîcifîştiş (n)",
    "y": " gönül verme, âşık olma, sevme"
  },
  {
    "x": "zerrîcikerdiş (n)",
    "y": " gönül verme, âşık olma, sevme"
  },
  {
    "x": "zerrîcikewtiş (n)",
    "y": " gönül verme, âşık olma, sevme"
  },
  {
    "x": "zerrîdayîş (n)",
    "y": " cesaretlendirme, yüreklendirme"
  },
  {
    "x": "zerrîdebîyayîş (n)",
    "y": " duygulanma, hislenme"
  },
  {
    "x": "zerrîdekewtiş (n)",
    "y": " gönül verme, âşık olma, sevme"
  },
  {
    "x": "zerrîdevistiş (n)",
    "y": " gönül verme, âşık olma, sevme"
  },
  {
    "x": "zerrîhurefîştiş (n)",
    "y": " b. zerrîhurêfîstiş (n)"
  },
  {
    "x": "zerrîhurekewtiş (n)",
    "y": " b. zerrîhurêkewtiş (n)"
  },
  {
    "x": "zerrîhurêfîstiş (n)",
    "y": " mide bulandırma"
  },
  {
    "x": "zerrîhurêkewtiş (n)",
    "y": " bulantı, mide(si) bulanma"
  },
  {
    "x": "zerrîhusk, -e",
    "y": " b. zerrîhuşk, -e"
  },
  {
    "x": "zerrîhuskîye (m)",
    "y": " b. zerrîhuşkîye (m)"
  },
  {
    "x": "zerrîhuşk, -e",
    "y": " duygusuz, hissiz; katı yürekli"
  },
  {
    "x": "zerrîhuşkênî (m)",
    "y": " duygusuzluk, hissizlik, katı yüreklilik"
  },
  {
    "x": "zerrîhuşkî (m)",
    "y": " duygusuzluk, hissizlik, katı yüreklilik"
  },
  {
    "x": "zerrîhuşkîye (m)",
    "y": " duygusuzluk, hissizlik, katı yüreklilik"
  },
  {
    "x": "zerrîn (n)",
    "y": " b. zerrên (n)"
  },
  {
    "x": "zerrînas, -e",
    "y": " kardiyolog"
  },
  {
    "x": "zerrînasî (m)",
    "y": " kardiyoloji"
  },
  {
    "x": "zerrînasîye (m)",
    "y": " kardiyoloji"
  },
  {
    "x": "zerrînerm, -e",
    "y": " yufka yürekli"
  },
  {
    "x": "zerrînêweş, -e",
    "y": " mutsuz"
  },
  {
    "x": "zerrînêweşî (m)",
    "y": " mutsuzluk"
  },
  {
    "x": "zerrînêweşîye (m)",
    "y": " mutsuzluk"
  },
  {
    "x": "zerrîpak, -e",
    "y": " açık kalpli, açık yürekli, samimi"
  },
  {
    "x": "zerrîpakîye (m)",
    "y": " açık kalplilik, açık yüreklilik, samimiyet"
  },
  {
    "x": "zerrîpêt, -e",
    "y": " 1)duygusuz, hissiz; katı yürekli\r\n~2)canı pek"
  },
  {
    "x": "zerrîpêtî (m)",
    "y": " duygusuzluk, hissizlik; katı yüreklilik"
  },
  {
    "x": "zerrîpêtîye (m)",
    "y": " duygusuzluk, hissizlik; katı yüreklilik"
  },
  {
    "x": "zerrîpiranayîş (n)",
    "y": " gönül verme, âşık olma, sevme"
  },
  {
    "x": "zerrîpît, -e",
    "y": " b. zerrîpêt, -e"
  },
  {
    "x": "zerrîqelebîyayîş (n)",
    "y": " b. zerrîqelibîyayîş (n)"
  },
  {
    "x": "zerrîqelebnayîş (n)",
    "y": " b. zerrîqelibnayîş (n)"
  },
  {
    "x": "zerrîqelibîyayîş (n)",
    "y": " mide(si) bulanma"
  },
  {
    "x": "zerrîqelibnayîş (n)",
    "y": " mide bulandırma"
  },
  {
    "x": "zerrîsenik, -e",
    "y": " b. zerrîşenik, -e"
  },
  {
    "x": "zerrîsoj, -e",
    "y": " acıklı, üzücü, üzüntülü"
  },
  {
    "x": "zerrîşenik, -e",
    "y": " alçakgönüllü, mütevazı"
  },
  {
    "x": "zerrşenikey (m)",
    "y": " b. zerrîşenikî (m)"
  },
  {
    "x": "zerrîşenikê (m)",
    "y": " b. zerrîşenikî (m)"
  },
  {
    "x": "zerrîşenikî (m)",
    "y": " alçakgönüllülük, mütevazılık"
  },
  {
    "x": "zerrîtengî (m)",
    "y": " bunaltı, can sıkıntısı"
  },
  {
    "x": "zerrîtengîye (m)",
    "y": " bunaltı, can sıkıntısı"
  },
  {
    "x": "zerrîtenik, -e",
    "y": " yufka yürekli"
  },
  {
    "x": "zerrîtêdayîş (n)",
    "y": " b. zerrîtêwdayîş (n)"
  },
  {
    "x": "zerrîtêdîyayîş (n)",
    "y": " b. zerrîtêwdîyayîş (n)"
  },
  {
    "x": "zerrîtêkewtiş (n)",
    "y": " b. zerrîtêwkewtiş (n)"
  },
  {
    "x": "zerrîtêwdayîş (n)",
    "y": " mide bulandırma"
  },
  {
    "x": "zerrîtêwdîyayîş (n)",
    "y": " bulantı, mide(si) bulanma"
  },
  {
    "x": "zerrîtêwfîstiş (n)",
    "y": " mide bulandırma"
  },
  {
    "x": "zerrîtêwkerdiş (n)",
    "y": " mide bulandırma"
  },
  {
    "x": "zerrîtêwkewtiş (n)",
    "y": " bulantı, mide(si) bulanma"
  },
  {
    "x": "zerrîtîodîyayîş (n)",
    "y": " b. zerrîtêwdîyayîş (n)"
  },
  {
    "x": "zerrîveşn, -e",
    "y": " merhametli"
  },
  {
    "x": "zerrîveşn bîyene",
    "y": " merhametli olmak"
  },
  {
    "x": "zerrîveşnênî (m)",
    "y": " merhametlilik"
  },
  {
    "x": "zerrîveşnî (m)",
    "y": " merhametlilik"
  },
  {
    "x": "zerrîveşnîye (m)",
    "y": " merhametlilik"
  },
  {
    "x": "zerrîwaz, -e",
    "y": " gönüllü"
  },
  {
    "x": "zerrîwazî (m)",
    "y": " gönüllülük"
  },
  {
    "x": "zerrîwereykewtiş (n)",
    "y": " b. zerrîhurêkewtiş (n)"
  },
  {
    "x": "zerrîweş, -e",
    "y": " mutlu, bahtiyar"
  },
  {
    "x": "zerrîweşî (m)",
    "y": " mutluluk, bahtiyarlık"
  },
  {
    "x": "zerrîweşîye (m)",
    "y": " mutluluk, bahtiyarlık"
  },
  {
    "x": "zerrîzîz, -e",
    "y": " duygulu, duygun, duygusal; hissi"
  },
  {
    "x": "zerrîzîz bîyene",
    "y": " ağlamaklı olmak"
  },
  {
    "x": "zerrîzîz kerdene",
    "y": " ağlamaklı duruma getirmek"
  },
  {
    "x": "zerrîzîzbîyayîş (n)",
    "y": " 1)ağlamaklı olma, ağlamsı olma, ağlamsama, doluklama, dolukma\r\n~2)duygulanma, hislenme"
  },
  {
    "x": "zerrîzîzênî (m)",
    "y": " duygululuk, duygunluk, duygusallık"
  },
  {
    "x": "zerrîzîzî (m)",
    "y": " duygululuk, duygunluk, duygusallık"
  },
  {
    "x": "zerrîzîzîye (m)",
    "y": " duygululuk, duygunluk, duygusallık"
  },
  {
    "x": "zerrîzîzkerdiş (n)",
    "y": " 1)ağlamaklı duruma getirme\r\n~2)duygulandırma"
  },
  {
    "x": "zerrn (n)",
    "y": " altın"
  },
  {
    "x": "zerrnemr, -i",
    "y": " b. zerrînerm, -e"
  },
  {
    "x": "zerrnên, -e",
    "y": " altından yapılma"
  },
  {
    "x": "zerrnin, -e",
    "y": " altınlı"
  },
  {
    "x": "zerrnroş, -e",
    "y": " altıncı (altın alıp satan kimse)"
  },
  {
    "x": "zerrpêt, -i",
    "y": " b. zerrîpêt, -e"
  },
  {
    "x": "zerrpêtey (m)",
    "y": " b. zerrîpêtî (m)"
  },
  {
    "x": "zerrpêtî (m)",
    "y": " b. zerrîpêtî (m)"
  },
  {
    "x": "zerrtengey (m)",
    "y": " b. zerrîtengîye (m)"
  },
  {
    "x": "zerrtengî (m)",
    "y": " b. zerrîtengîye (m)"
  },
  {
    "x": "zerrtenik, -i",
    "y": " b. zerrîtenik, -e"
  },
  {
    "x": "zerrveşn, -i",
    "y": " b. zerrîveşn, -e"
  },
  {
    "x": "zerrveşn bîyene",
    "y": " b. zerrîveşn bîyene"
  },
  {
    "x": "zerrveşney (m)",
    "y": " b. zerrîveşnî (m), zerrîveşnîye (m)"
  },
  {
    "x": "zerrweş, -e",
    "y": " b. zerrîweş, -e"
  },
  {
    "x": "zerrweşey (m)",
    "y": " b. zerrîweşîye (m)"
  },
  {
    "x": "zerrweşê (m)",
    "y": " b. zerrîweşî (m)"
  },
  {
    "x": "zerrweşî (m)",
    "y": " b. zerrîweşî (m)"
  },
  {
    "x": "zerrwişkey (m)",
    "y": " b. zerrîhuşkîye (m)"
  },
  {
    "x": "zerrzîz, -i",
    "y": " b. zerrîzîz, -e"
  },
  {
    "x": "zerrzîzê (m)",
    "y": " b. zerrîzîzî (m), zerrîzîzîye (m)"
  },
  {
    "x": "zertengî (m)",
    "y": " b. zerrîtengîye (m)"
  },
  {
    "x": "zertenik, -i",
    "y": " b. zerrîtenik, -e"
  },
  {
    "x": "zerveşn, -i",
    "y": " b. zerrîveşn, -e"
  },
  {
    "x": "zerveşn bîyene",
    "y": " b. zerrîveşn bîyene"
  },
  {
    "x": "zerveşney (m)",
    "y": " b. zerrîveşnî (m)"
  },
  {
    "x": "zerveşnê (m)",
    "y": " b. zerrîveşnî (m)"
  },
  {
    "x": "zerweş, -e",
    "y": " b. zerrîweş, -e"
  },
  {
    "x": "zerweşey (m)",
    "y": " b. zerrîweşîye (m)"
  },
  {
    "x": "zerweşê (m)",
    "y": " b. zerrîweşî (m)"
  },
  {
    "x": "zerweşî (m)",
    "y": " b. zerrîweşî (m)"
  },
  {
    "x": "zerweşîye (m)",
    "y": " b. zerrîweşîye (m)"
  },
  {
    "x": "zerwişk, -i",
    "y": " b. zerrîhuşk, -e"
  },
  {
    "x": "zerwişkey (m)",
    "y": " b. zerrîhuşkîye (m)"
  },
  {
    "x": "zerwişkê (m)",
    "y": " b. zerrîhuşkîye (m)"
  },
  {
    "x": "zerwişkî (m)",
    "y": " b. zerrîhuşkîye (m)"
  },
  {
    "x": "zerya bele (m)",
    "y": " b. zerrîya bele (m)"
  },
  {
    "x": "zerze (n)",
    "y": " kilit (asma kilit)"
  },
  {
    "x": "zerzewat (n)",
    "y": " sebze, göveri, göverti"
  },
  {
    "x": "zerzîz, -i",
    "y": " b. zerrîzîz, -e"
  },
  {
    "x": "zerzîzbîyayîş (n)",
    "y": " b. zerrîzîzbîyayîş (n)"
  },
  {
    "x": "zerzîzey (m) ",
    "y": " b. zerrîzîzî (m), zerrîzîzîye (m)"
  },
  {
    "x": "zerzîzê (m) ",
    "y": " b. zerrîzîzî (m), zerrîzîzîye (m)"
  },
  {
    "x": "zerzîzkerdiş (n)",
    "y": " b. zerrîzîzkerdiş (n)"
  },
  {
    "x": "zerzur (n)",
    "y": " bir kuş çeşidi"
  },
  {
    "x": "zerzûr (n)",
    "y": " b. zerzur (n)"
  },
  {
    "x": "zeveş (m)",
    "y": " b. zebeşe (m)"
  },
  {
    "x": "zew (n)",
    "y": " b. yew (n)"
  },
  {
    "x": "zew camêrd",
    "y": " bir erkek"
  },
  {
    "x": "zewac (n)",
    "y": " evlilik"
  },
  {
    "x": "zewahîr (n)",
    "y": " görünüş"
  },
  {
    "x": "zewaj (n)",
    "y": " b. zewac (n)"
  },
  {
    "x": "zewbî",
    "y": " b. sewbîn"
  },
  {
    "x": "zewbîn",
    "y": " b. sewbîn"
  },
  {
    "x": "zewbînî",
    "y": " b. yewbînî"
  },
  {
    "x": "zewci (m)",
    "y": " b. zewac (n)"
  },
  {
    "x": "zewci-feri",
    "y": " b. zewnce-fert"
  },
  {
    "x": "zewec (n)",
    "y": " b. zewac (n)"
  },
  {
    "x": "zewecîyaya (m)",
    "y": " b. zewicîyaya (m)"
  },
  {
    "x": "zewecîyaye (n)",
    "y": " b. zewicîyaye (n)"
  },
  {
    "x": "zewecîyayene",
    "y": " b. zewicîyayene"
  },
  {
    "x": "zewecîyayî, -ye",
    "y": " b. zewicîyayî, -ye"
  },
  {
    "x": "zewecîyayîş (n)",
    "y": " b. zewicîyayîş (n)"
  },
  {
    "x": "zewecnayene",
    "y": " b. zewicnayene"
  },
  {
    "x": "zewecnayêne",
    "y": " b. zewicnayene"
  },
  {
    "x": "zewecnayîş (n)",
    "y": " b. zewicnayîş (n)"
  },
  {
    "x": "zewej (n)",
    "y": " b. zewac (n)"
  },
  {
    "x": "zewejîyaya (m)",
    "y": " b. zewicîyaya (m)"
  },
  {
    "x": "zewejîyaye (n)",
    "y": " b. zewicîyaye (n)"
  },
  {
    "x": "zewejîyayî, -ye",
    "y": " b. zewicîyayî, -ye"
  },
  {
    "x": "zewejnayene",
    "y": " b. zewicnayene"
  },
  {
    "x": "zewejnayîş (n)",
    "y": " b. zewicnayîş (n)"
  },
  {
    "x": "zewez (n)",
    "y": " b. zewac (n)"
  },
  {
    "x": "zeweznayene",
    "y": " b. zewicnayene"
  },
  {
    "x": "zeweznayêne",
    "y": " b. zewicnayene"
  },
  {
    "x": "zeweznayîş (n)",
    "y": " b. zewecnayîş (n)"
  },
  {
    "x": "zewg",
    "y": " b. seke"
  },
  {
    "x": "zewicîyaya (m)",
    "y": " başı bağlı, evli"
  },
  {
    "x": "zewicîyaye (n)",
    "y": " başı bağlı, evli"
  },
  {
    "x": "zewicîyayene",
    "y": " evlenmek"
  },
  {
    "x": "zewicîyayî, -ye",
    "y": " evli, başı bağlı"
  },
  {
    "x": "zewicîyayîş (n)",
    "y": " evlenme"
  },
  {
    "x": "zewicnayene",
    "y": " evlendirmek"
  },
  {
    "x": "zewicnayîş (n)",
    "y": " evlendirme"
  },
  {
    "x": "zewicyayîş (n)",
    "y": " b. zewicîyayîş (n)"
  },
  {
    "x": "zewijîyaya (m)",
    "y": " b. zewicîyaya (m)"
  },
  {
    "x": "zewijîyaye (n)",
    "y": " b. zewicîyaye (n)"
  },
  {
    "x": "zewijîyayî, -ye",
    "y": " b. zewicîyayî, -ye"
  },
  {
    "x": "zewijîyayîş (n)",
    "y": " b. zewicîyayîş (n)"
  },
  {
    "x": "zewî (m)",
    "y": " b. yewî-I (m), yewîye (m)"
  },
  {
    "x": "zewmbî",
    "y": " b. sewbîn"
  },
  {
    "x": "zewmbîna",
    "y": " b. sewbîn"
  },
  {
    "x": "zewmbînî",
    "y": " b. yewbînî"
  },
  {
    "x": "zewmî",
    "y": " b. sewbîn"
  },
  {
    "x": "zewmîna",
    "y": " b. sewbîn"
  },
  {
    "x": "zewnc (m)",
    "y": " b. zewnce (m)"
  },
  {
    "x": "zewnc û fert",
    "y": " tek ve çift"
  },
  {
    "x": "zewnc û tek",
    "y": " tek ve çift"
  },
  {
    "x": "zewnc-fer",
    "y": " b. zewnce-fert"
  },
  {
    "x": "zewnc-fert",
    "y": " b. zewnce-fert"
  },
  {
    "x": "zewnc-tek",
    "y": " b. zewnce-tek"
  },
  {
    "x": "zewnce (m)",
    "y": " çift"
  },
  {
    "x": "zewnce-fert",
    "y": " tek-çift, tek mi çift mi (oyunu)"
  },
  {
    "x": "zewnce-tek",
    "y": " tek-çift"
  },
  {
    "x": "zewnci (m)",
    "y": " b. zewnce (m)"
  },
  {
    "x": "zewt (n)",
    "y": " b. zewte (m)"
  },
  {
    "x": "zewte (m)",
    "y": " beddua, ilenç, kargış"
  },
  {
    "x": "zewti (m)",
    "y": " b. zewte (m)"
  },
  {
    "x": "zewunce (m)",
    "y": " b. zewnce (m)"
  },
  {
    "x": "zewzîyaya (m)",
    "y": " b. zewicîyaya (m)"
  },
  {
    "x": "zewzîyaye (n)",
    "y": " b. zewicîyaye (n)"
  },
  {
    "x": "zewzîyayî, -ye",
    "y": " b. zewicîyayî, -ye"
  },
  {
    "x": "zewzîyayîş (n)",
    "y": " b. zewicîyayîş (n)"
  },
  {
    "x": "zexer, -e",
    "y": " küçük cins köpek"
  },
  {
    "x": "zexîra (m)",
    "y": " b. zexîre (n)"
  },
  {
    "x": "zexîre (n)",
    "y": " zahire"
  },
  {
    "x": "zexîri (n)",
    "y": " b. zexîre (n)"
  },
  {
    "x": "zext (n)",
    "y": " nodul"
  },
  {
    "x": "zextî (m)",
    "y": " b. zext (n)"
  },
  {
    "x": "zey",
    "y": " b. sey-I"
  },
  {
    "x": "zey xo kerdene",
    "y": " b. sey xo kerdene"
  },
  {
    "x": "zeydîyayene",
    "y": " b. zêdîyayene"
  },
  {
    "x": "zeydîyayîş (n)",
    "y": " b zêdîyayîş (n)"
  },
  {
    "x": "zeydnayene",
    "y": " b. zêdnayene"
  },
  {
    "x": "zeydnayîş (n)",
    "y": " b. zêdnayîş (n)"
  },
  {
    "x": "zeyî ver de bîyene",
    "y": " (hayvan için) doğum sancısı çekmek"
  },
  {
    "x": "zeyîf, -e",
    "y": " b. zeîf, -e"
  },
  {
    "x": "zeyîf bîyayene",
    "y": " b. zeîf bîyene"
  },
  {
    "x": "zeyîf kerdene",
    "y": " b. zeîf kerdene"
  },
  {
    "x": "zeyîfbîyayîş (n)",
    "y": " b. zeîfbîyayîş (n)"
  },
  {
    "x": "zeyîfê (m)",
    "y": " b. zeîfîye (m)"
  },
  {
    "x": "zeyîfkerdiş (n)",
    "y": " b. zeîfkerdiş (n)"
  },
  {
    "x": "zeyîr (n)",
    "y": " b. zehîr (n)"
  },
  {
    "x": "zeyîr werdene",
    "y": " b. zehîr werdene "
  },
  {
    "x": "zeyîrdayîş (n)",
    "y": " b. zehîrdayîş (n)"
  },
  {
    "x": "zeyîye (m)",
    "y": " görümce"
  },
  {
    "x": "zeyn (n)",
    "y": " b. zihn (n)"
  },
  {
    "x": "zeynayene",
    "y": " b. Zêdnayene"
  },
  {
    "x": "zeynayîş (n)",
    "y": "b. zêdnayîş (n)"
  },
  {
    "x": "zeynet (n)",
    "y": " b. zîynet (n)"
  },
  {
    "x": "zeynetin, -e",
    "y": " b. zîynetin, -e"
  },
  {
    "x": "zeynetini (m)",
    "y": " b. zîynetin, -e"
  },
  {
    "x": "zeynetlî, -ye",
    "y": " b. zîynetlî, -ye"
  },
  {
    "x": "zeyr (n)",
    "y": " b. zehîr (n)"
  },
  {
    "x": "zeyt (n)",
    "y": " zeytinyağı"
  },
  {
    "x": "zeytin (m)",
    "y": " b. zeytune (m)"
  },
  {
    "x": "zeytin, -e",
    "y": " zeytinyağlı"
  },
  {
    "x": "zeytine (m)",
    "y": " b. zeytune (m)"
  },
  {
    "x": "zeytinêre (m)",
    "y": " b. zeytunêre (m)"
  },
  {
    "x": "zeytini (m)",
    "y": " b. zeytune (m)"
  },
  {
    "x": "zeytune (m)",
    "y": " zeytin"
  },
  {
    "x": "zeytunêre (m)",
    "y": " zeytin ağacı"
  },
  {
    "x": "zeytûne (m)",
    "y": " b. zeytune (m)"
  },
  {
    "x": "zeytûnêri (m)",
    "y": " b. zeytunêre (m)"
  },
  {
    "x": "zeytuni (m)",
    "y": " b. zeytune (m)"
  },
  {
    "x": "zeytûnyer (m)",
    "y": " b. zeytunêre (m)"
  },
  {
    "x": "zeyxokerdiş (n)",
    "y": " b. seyxokerdiş (n)"
  },
  {
    "x": "zê-I",
    "y": " b. sey-I"
  },
  {
    "x": "zê-II (zr)",
    "y": " b. zî (zr)"
  },
  {
    "x": "zê-III (n)",
    "y": " b. zîn (n)"
  },
  {
    "x": "zêd",
    "y": " b. zîyade"
  },
  {
    "x": "zêd bîyayene",
    "y": " b. zîyade bîyene"
  },
  {
    "x": "zêdanî (m)",
    "y": " fazlalık"
  },
  {
    "x": "zêdbîyayîş (n)",
    "y": " b. zîyadebîyayîş (n)"
  },
  {
    "x": "zêde",
    "y": " b. zîyade"
  },
  {
    "x": "Heq zêde kero.",
    "y": " b. Heq zîyade biko."
  },
  {
    "x": "Wa zêde bo.",
    "y": " b. Wa zîyade bo."
  },
  {
    "x": "zêde bîyayene",
    "y": " b. zîyade bîyene"
  },
  {
    "x": "Zêde bo.",
    "y": " b. Zîyade bo."
  },
  {
    "x": "zêdebîyayîş (n)",
    "y": " b. zîyadebîyayîş (n)"
  },
  {
    "x": "zêdeerje (m)",
    "y": " artık değer"
  },
  {
    "x": "zêdek (n)",
    "y": " 1)artı (mat)\r\n~2)toplama işareti (mat)\r\n~Des desna, kenê vîst. (10+10= 20) (On on daha yirmi eder.)"
  },
  {
    "x": "zêderoje (m)",
    "y": " artık gün"
  },
  {
    "x": "zêdeserre (m)",
    "y": " artık yıl"
  },
  {
    "x": "zêdi",
    "y": " b. zîyade"
  },
  {
    "x": "zêdî",
    "y": " b. zîyade"
  },
  {
    "x": "zêdîyayene",
    "y": " artmak, çoğalmak"
  },
  {
    "x": "zêdîyayîş (n)",
    "y": " artma, çoğalma"
  },
  {
    "x": "zêdnayene",
    "y": " artırmak (fiyat vb. için)"
  },
  {
    "x": "zêdnayîş (n)",
    "y": " artırma (fiyat vb. için)"
  },
  {
    "x": "zêhîr (n)",
    "y": " b. zehîr (n)"
  },
  {
    "x": "zên-I (n)",
    "y": " b. zîn (n)"
  },
  {
    "x": "zên-II (n)",
    "y": " b. zihn (n)"
  },
  {
    "x": "zêndan (n)",
    "y": " b. zîndan (n)"
  },
  {
    "x": "zengîn (n)",
    "y": " b. zengîn (n)"
  },
  {
    "x": "zêr (m)",
    "y": " b. zerrî (m)"
  },
  {
    "x": "zêr dayene",
    "y": " b. zerrî dayene"
  },
  {
    "x": "zêr qelbyayene",
    "y": " b. zerrî qelibîyayene"
  },
  {
    "x": "zêrdayîş (n)",
    "y": " b. zerrîdayîş (n)"
  },
  {
    "x": "zêrr (m)",
    "y": " b. zerrî (m)"
  },
  {
    "x": "zêrr ci kotene",
    "y": " b. zerrî ci kewtene"
  },
  {
    "x": "zêrr dekotene",
    "y": " b. zerrî dekewtene"
  },
  {
    "x": "zêrrî (m)",
    "y": " b. zerrî (m)"
  },
  {
    "x": "zêrweş (n)",
    "y": " b. zerrîweş, -e"
  },
  {
    "x": "zêrweşê (m)",
    "y": " b. zerrîweşî (m)"
  },
  {
    "x": "zêrweşî (m)",
    "y": " b. zerrîweşî (m)"
  },
  {
    "x": "zêtun (n)",
    "y": " b. zeytune (m)"
  },
  {
    "x": "zêtune (m)",
    "y": " b. zeytune (m)"
  },
  {
    "x": "zêtûn (n)",
    "y": " b. zeytune (m)"
  },
  {
    "x": "zêyîr (n)",
    "y": " b. zehîr (n)"
  },
  {
    "x": "zi (zr)",
    "y": " b. zî (zr)"
  },
  {
    "x": "zibala (m)",
    "y": " gübrelik, çöplük"
  },
  {
    "x": "zibil (n)",
    "y": " gübre, kemre (hayvan gübresi)"
  },
  {
    "x": "zibil dekerdene",
    "y": " gübrelemek "
  },
  {
    "x": "zibil kerdene",
    "y": " gübrelemek"
  },
  {
    "x": "zibilê kîmyayî (n)",
    "y": " kimyasal gübre"
  },
  {
    "x": "zibilo kîmyayî (n)",
    "y": " kimyasal gübre"
  },
  {
    "x": "zibildekerdiş (n)",
    "y": " gübreleme"
  },
  {
    "x": "zibilca (n)",
    "y": " gübrelik"
  },
  {
    "x": "zibilge (n)",
    "y": " gübrelik"
  },
  {
    "x": "zibilin, -e",
    "y": " gübreli"
  },
  {
    "x": "zibilkerda (m)",
    "y": " gübreli"
  },
  {
    "x": "zibilkerde (n)",
    "y": " gübreli"
  },
  {
    "x": "zibilkerdiş (n)",
    "y": " gübreleme"
  },
  {
    "x": "zibilkerdîye (m)",
    "y": " gübreli"
  },
  {
    "x": "zibl (n)",
    "y": " b. zibil (n)"
  },
  {
    "x": "ziblûzebale (m)",
    "y": " gübre, kemre (hayvan gübresi)"
  },
  {
    "x": "ziblûzebali (m)",
    "y": " b. ziblûzebale (m)"
  },
  {
    "x": "ziçile (n)",
    "y": " 1)sağı, kuş dışkısı, kuş tersi; kuş gübresi\r\n~2) tavuk dışkısı"
  },
  {
    "x": "ziçile kerdene",
    "y": " (kuş, tavuk vb. için) dışkı yapmak"
  },
  {
    "x": "ziçili (n)",
    "y": " b. ziçile (n)"
  },
  {
    "x": "ziçili kardene",
    "y": " b. ziçile kerdene"
  },
  {
    "x": "zifqêli (m)",
    "y": " b. zifqêre (m)"
  },
  {
    "x": "zifqêre (m)",
    "y": " eşik"
  },
  {
    "x": "zifqêri (m)",
    "y": " b. zifqêre (m)"
  },
  {
    "x": "zift (n)",
    "y": " zift"
  },
  {
    "x": "zigar (n)",
    "y": " çakıl"
  },
  {
    "x": "zigarin, -e",
    "y": " çakıllı"
  },
  {
    "x": "zihin (n)",
    "y": " b. zihn (n)"
  },
  {
    "x": "zihinî, -ye",
    "y": " b. zihnî, -ye"
  },
  {
    "x": "zihîf, -i",
    "y": " b. zeîf, -e"
  },
  {
    "x": "zihîf bîyayene",
    "y": " b. zeîf bîyene"
  },
  {
    "x": "zihîf kerdene",
    "y": " b. zeîf kerdene"
  },
  {
    "x": "zihîfbîyayîş (n)",
    "y": " b. zeîfbîyayîş (n)"
  },
  {
    "x": "zihîfey (m)",
    "y": " b. zeîfîye (m)"
  },
  {
    "x": "zihîfkerdiş (n)",
    "y": " b. zeîfkerdiş (n)"
  },
  {
    "x": "zihn (n)",
    "y": " zihin"
  },
  {
    "x": "zihnî, -ye",
    "y": " mental, zihinsel, zihnî"
  },
  {
    "x": "ziîf, -i",
    "y": " b. zeîf, -e"
  },
  {
    "x": "ziîf bîyayîne",
    "y": " b. zeîf bîyene"
  },
  {
    "x": "ziîf kerdene",
    "y": " b. zeîf kerdene"
  },
  {
    "x": "ziîfbeyîş (n)",
    "y": " b. zeîfbîyayîş (n)"
  },
  {
    "x": "ziîfey (m)",
    "y": " b. zeîfîye (m)"
  },
  {
    "x": "ziîfkerdiş (n)",
    "y": " b. zeîfkerdiş (n)"
  },
  {
    "x": "zik û ziwa, -ye",
    "y": " kupkuru"
  },
  {
    "x": "zik û zuwa, ye",
    "y": " b. zik û ziwa, -ye"
  },
  {
    "x": "zik-ziwa, -ye",
    "y": " kupkuru"
  },
  {
    "x": "zik-zuwa, -ye",
    "y": " b. zik-ziwa, -ye"
  },
  {
    "x": "zikak (n)",
    "y": " b. ziqaq (n)"
  },
  {
    "x": "zikir kerdene",
    "y": " anmak"
  },
  {
    "x": "zikirkerdiş (n)",
    "y": " anma"
  },
  {
    "x": "zikirnayene",
    "y": " anmak"
  },
  {
    "x": "zikirnayîş (n)",
    "y": " anma"
  },
  {
    "x": "zil (n)",
    "y": " b. zîl (n)"
  },
  {
    "x": "zilb (n)",
    "y": " b. zibil (n)"
  },
  {
    "x": "zileyl (n)",
    "y": " b. zerencleyîr (n)"
  },
  {
    "x": "zilim (n)",
    "y": " b. zulm (n)"
  },
  {
    "x": "zilim kerdene",
    "y": " b. zulm kerdene"
  },
  {
    "x": "zilimkerdiş (n)",
    "y": " b. zulmkerdiş (n)"
  },
  {
    "x": "zilm (n)",
    "y": " b. zulm (n)"
  },
  {
    "x": "zilm kerdene",
    "y": " b. zulm kerdene"
  },
  {
    "x": "zilmkar, -e",
    "y": " b. zulmkar, -e"
  },
  {
    "x": "zilmkarî (m)",
    "y": " b. zulmkarî (m)"
  },
  {
    "x": "zilmkerdiş (n)",
    "y": " b. zulmkerdiş (n)"
  },
  {
    "x": "zilqêr (n)",
    "y": " b. zifqêre (m)"
  },
  {
    "x": "zilqit (m)",
    "y": " b. zerqete (m)"
  },
  {
    "x": "zilqiti (m)",
    "y": " b. zerqete (m)"
  },
  {
    "x": "zilut, -e",
    "y": " çırılçıplak, çırçıplak"
  },
  {
    "x": "zilv (n)",
    "y": " b. zibil (n)"
  },
  {
    "x": "zimag (n)",
    "y": " güneş almayan yer"
  },
  {
    "x": "ziman (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "zimayene",
    "y": " 1)yankı yapmak\r\n~2)çağlamak"
  },
  {
    "x": "zimayîş (n)",
    "y": " 1)yankı yapma\r\n~2)çağlama"
  },
  {
    "x": "zimbêlikî (zh)",
    "y": " kılçık(lar) (başak için)"
  },
  {
    "x": "zimbêlî (zh)",
    "y": " 1)bıyık(lar)\r\n~2)kılçık(lar) (başak için)"
  },
  {
    "x": "zimbêlkî (zh)",
    "y": " b. zimbêlikî (zh) "
  },
  {
    "x": "zimbîyel (zh)",
    "y": " b. zimbêlî (zh)"
  },
  {
    "x": "zimbîyelî (zh)",
    "y": " b. zimbêlî (zh)"
  },
  {
    "x": "zimbîyêl (zh)",
    "y": " b. zimbêlî (zh)"
  },
  {
    "x": "zime (n)",
    "y": " 1)güneş almayan yer\r\n~2)tarla"
  },
  {
    "x": "zimêl (zh)",
    "y": " b. zimbêlî (zh)"
  },
  {
    "x": "zimêlî (zh)",
    "y": " b. zimbêlî (zh)"
  },
  {
    "x": "zimi (n)",
    "y": " b. zime (n)"
  },
  {
    "x": "zimista (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "zimistan (n)",
    "y": " kış"
  },
  {
    "x": "zimistano peyên (n)",
    "y": " kışın sonları"
  },
  {
    "x": "zimistono peyen (n)",
    "y": " b. zimistano peyên (n)"
  },
  {
    "x": "zimistana (m)",
    "y": " kışlık"
  },
  {
    "x": "zimistane (n)",
    "y": " kışlık"
  },
  {
    "x": "zimistanê",
    "y": " b. zimistanî-I"
  },
  {
    "x": "zimistanên, -e",
    "y": " kışlık"
  },
  {
    "x": "zimistanî-I",
    "y": " kışın"
  },
  {
    "x": "zimistanî-II (m, n)",
    "y": " kışlık"
  },
  {
    "x": "zimistanîn, -e",
    "y": " b. zimistanên, -e"
  },
  {
    "x": "zimistanîye (m)",
    "y": " kışlık"
  },
  {
    "x": "zimisto (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "zimiston (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "zimistona (m)",
    "y": " b. zimistana (m)"
  },
  {
    "x": "zimistone (n)",
    "y": " b. zimistane (n)"
  },
  {
    "x": "zimistonê",
    "y": " b. zimistanî-I"
  },
  {
    "x": "zimistonên, -e",
    "y": " b. zimistanên, -e"
  },
  {
    "x": "zimistonî",
    "y": " b. zimistanî-I"
  },
  {
    "x": "zimistonîn, -e",
    "y": " b. zimistanên, -e"
  },
  {
    "x": "zimistonîye (m)",
    "y": " b. zimistanîye (m)"
  },
  {
    "x": "zimistû (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "zimistûn (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "zimistûn peyin (n)",
    "y": " b. zimistano peyên (n)"
  },
  {
    "x": "zimistûna (m)",
    "y": " b. zimistana (m)"
  },
  {
    "x": "zimistûne (n)",
    "y": " b. zimistane (n)"
  },
  {
    "x": "zimistûnên, -e",
    "y": " b. zimistanên, -e"
  },
  {
    "x": "zimistûnîn, -e",
    "y": " b. zimistanên, -e"
  },
  {
    "x": "zimistûnîye (m)",
    "y": " b. zimistanîye (m)"
  },
  {
    "x": "zimistûnyen",
    "y": " b. zimistanên, -e"
  },
  {
    "x": "zimî (m)",
    "y": " yankı"
  },
  {
    "x": "zimnî, -ye",
    "y": " zımnî"
  },
  {
    "x": "zimpara (n)",
    "y": " b. zimpere (n)"
  },
  {
    "x": "zimpara kerdene",
    "y": " b. zimpere kerdene"
  },
  {
    "x": "zimparakerdiş (n)",
    "y": " b. zimperekerdiş (n)"
  },
  {
    "x": "zimpere (n)",
    "y": " zımpara"
  },
  {
    "x": "zimpere kerdene",
    "y": " zımparalamak, taşlamak (metal parçalar için)"
  },
  {
    "x": "zimpereker, -e",
    "y": " taşlamacı"
  },
  {
    "x": "zimperekerdiş (n)",
    "y": " zımparalama"
  },
  {
    "x": "zimuston (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "zimustû (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "zimustûn (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "zimûn (n)",
    "y": " b. zeman (n)"
  },
  {
    "x": "zimûstû (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "zimûstûna (m)",
    "y": " b. zimistana (m)"
  },
  {
    "x": "zimûstûne (n)",
    "y": " b. zimistane (n)"
  },
  {
    "x": "zimûstûnên, -e",
    "y": " b. zimistanên, -e"
  },
  {
    "x": "zimûstûnîn, -e",
    "y": " b. zimistanên, -e"
  },
  {
    "x": "zimûstûnîye (m)",
    "y": " b. zimistanîye (m)"
  },
  {
    "x": "zimziq (m)",
    "y": " b. zumzuqe (m)"
  },
  {
    "x": "zimziqî piro dayene",
    "y": " b. zumzuqî piro dayene"
  },
  {
    "x": "zimziqî piro ginayene",
    "y": " b. zumzuqî piro ginayene"
  },
  {
    "x": "zimziqî werdeni",
    "y": " b. zumzuqî werdene"
  },
  {
    "x": "zimziqîkerdiş (n)",
    "y": " b. zumzuqîkerdiş (n)"
  },
  {
    "x": "zimziqîpirodayîş (n)",
    "y": " zumzuqîpirodayîş (n)"
  },
  {
    "x": "zimziqîpiroginayîş (n)",
    "y": " zumzuqîpiroginayîş (n)"
  },
  {
    "x": "zimziqîwerdiş (n)",
    "y": " b. zumzuqîwerdiş (n)"
  },
  {
    "x": "zinar (n)",
    "y": " kaya"
  },
  {
    "x": "zinarin, -e",
    "y": " kayalık"
  },
  {
    "x": "zinbêlî (zh)",
    "y": " b. zimbêlî (zh)"
  },
  {
    "x": "zinc-I (m)",
    "y": " 1)b. zincî (m)\r\n~2)b. zince (m)"
  },
  {
    "x": "zinc-II (m)",
    "y": " b. zewnce (m)"
  },
  {
    "x": "zince-I (m)",
    "y": " çene "
  },
  {
    "x": "zince-II (m)",
    "y": " b. zincî (m)"
  },
  {
    "x": "qula zince (m)",
    "y": " b. qula zincî (m)"
  },
  {
    "x": "zinci (m)",
    "y": " 1)b. zincî (m)\r\n~2)b. zince (m)"
  },
  {
    "x": "qula zinci (m)",
    "y": " b. qula zincî (m)"
  },
  {
    "x": "zincile (m)",
    "y": " b. zincîre (m)"
  },
  {
    "x": "zincî (m)",
    "y": " 1)burun\r\n~2)b. zîncî (m)"
  },
  {
    "x": "qula zincî (m)",
    "y": " burun deliği"
  },
  {
    "x": "zincîya ... veşayene",
    "y": " utanmak (mecazi)"
  },
  {
    "x": "zincîya ... veşayîş",
    "y": " utanma (mecazi)"
  },
  {
    "x": "zincîya wirdî (m)",
    "y": " b. zîncîya hurdî (m)"
  },
  {
    "x": "zincîle (m)",
    "y": " b. zincîre (m)"
  },
  {
    "x": "zincîli (m)",
    "y": " b. zincîre (m)"
  },
  {
    "x": "zincîre (m)",
    "y": " zincir, fermuar"
  },
  {
    "x": "zincîra zerrêne/zerrnêne (m)",
    "y": " altın zincir"
  },
  {
    "x": "zincîri (m)",
    "y": " b. zincîre (m)"
  },
  {
    "x": "zing (n)",
    "y": " çınlama sesi"
  },
  {
    "x": "zingayene",
    "y": " çınlamak"
  },
  {
    "x": "zingayîş (n)",
    "y": " çınlama"
  },
  {
    "x": "zingil-I (n)",
    "y": " b. zengil-I (n)"
  },
  {
    "x": "zingil-II (n)",
    "y": " b. zanqile (n)"
  },
  {
    "x": "zingile (m)",
    "y": " yumurta katılmış hamurun yağda kızartılmasıyla yapılan hamur işi"
  },
  {
    "x": "zingili (m)",
    "y": " b. zingile (m)"
  },
  {
    "x": "zingire (n)",
    "y": " çelmik, kesmik (başakla karışık iri saman)"
  },
  {
    "x": "zingiri (n)",
    "y": " b. zingire (n)"
  },
  {
    "x": "zingî (m)",
    "y": " çınlama, çınlama sesi"
  },
  {
    "x": "zingîye (m)",
    "y": " çınlama, çınlama sesi"
  },
  {
    "x": "zingnayene",
    "y": " çınlatmak"
  },
  {
    "x": "zingri (n)",
    "y": " b. zingire (n)"
  },
  {
    "x": "Zingure (m)",
    "y": " keçi için ad olarak kullanılan bir sözcük"
  },
  {
    "x": "zip û ziwa, -ye",
    "y": " kupkuru"
  },
  {
    "x": "zip û joa, -ye",
    "y": " b. zip û ziwa, -ye"
  },
  {
    "x": "zip û zuya, -ye",
    "y": " b. zip û ziwa, -ye"
  },
  {
    "x": "zip û zulit, -e",
    "y": " b. zit-bulit, -e"
  },
  {
    "x": "zip-ziwa, -ye",
    "y": " kupkuru"
  },
  {
    "x": "zipî ziwa, -ye",
    "y": " b. zip û ziwa, -ye"
  },
  {
    "x": "ziqaq (n)",
    "y": " sokak"
  },
  {
    "x": "ziqayini",
    "y": " b. zîqayene"
  },
  {
    "x": "ziqayîs (n)",
    "y": " b. zîqayîş (n)"
  },
  {
    "x": "ziqayîş (n)",
    "y": " b. zîqayene"
  },
  {
    "x": "ziqî (m)",
    "y": " b. zirî (m)"
  },
  {
    "x": "ziql (n)",
    "y": " b. zanqile (n)"
  },
  {
    "x": "ziqlik (n)",
    "y": " b. zanqile (n)"
  },
  {
    "x": "ziqluki (m)",
    "y": " b. zuqlike (m)"
  },
  {
    "x": "ziqlûlik (m)",
    "y": " b. zuqlike (m)"
  },
  {
    "x": "ziqûlîliki (m)",
    "y": " b. zuqlike (m)"
  },
  {
    "x": "zir (n)",
    "y": " anırma, anırtı"
  },
  {
    "x": "zir-zir (n)",
    "y": " anırma, anırtı"
  },
  {
    "x": "zir-zir kerdene",
    "y": " anırmak"
  },
  {
    "x": "zir-zirkerdiş (n)",
    "y": " anırma, anırış"
  },
  {
    "x": "zirê … ameyene",
    "y": " şımarmak (argo)"
  },
  {
    "x": "zir- (vergir)",
    "y": " üvey"
  },
  {
    "x": "zirac, -e",
    "y": " b. zerenc, -e"
  },
  {
    "x": "ziranc, -e",
    "y": " b. zerenc, -e"
  },
  {
    "x": "zirar (n)",
    "y": " b. zerar (n)"
  },
  {
    "x": "Zirar nêkeno. ",
    "y": " b. Zerar nêkeno."
  },
  {
    "x": "zirare (m)",
    "y": " düğme (giysi için)"
  },
  {
    "x": "zirare devistene/girewtene/qefilnayene /tavistene",
    "y": " düğme iliklemek"
  },
  {
    "x": "zirari (m)",
    "y": " b. zirare (m)"
  },
  {
    "x": "zirav, -e",
    "y": " ince, zarif"
  },
  {
    "x": "ziravey (m)",
    "y": " b. ziravî (m), ziravîye (m)"
  },
  {
    "x": "ziravê (m)",
    "y": " b. ziravî (m), ziravîye (m)"
  },
  {
    "x": "ziravî (m)",
    "y": " incelik, zariflik"
  },
  {
    "x": "ziravîye (m)",
    "y": " incelik, zariflik"
  },
  {
    "x": "zirayene",
    "y": " 1)anırmak\r\n~2)ağlamak (argo)"
  },
  {
    "x": "zirayeni",
    "y": " b. zirayene"
  },
  {
    "x": "zirayîş (n)",
    "y": " 1)anırma, anırış\r\n~2)ağlama (argo)"
  },
  {
    "x": "zirbaw (n)",
    "y": " üvey baba, babalık"
  },
  {
    "x": "zirbe (n)",
    "y": " b. zerbe (n)"
  },
  {
    "x": "zirç (n)",
    "y": " b. zîrç (n)"
  },
  {
    "x": "zirce-zirce",
    "y": " b. zirçe-zirçe"
  },
  {
    "x": "zirçayene",
    "y": " bağırmak, çığlık atmak, çığlığı basmak, çığlık koparmak"
  },
  {
    "x": "zirçayêne",
    "y": " b. zirçayene"
  },
  {
    "x": "zirçayîş (n)",
    "y": " bağırma, çığlık atma, çığlığı basma, çığlık koparma"
  },
  {
    "x": "zirçe-zirçe",
    "y": " çığlık çığlığa"
  },
  {
    "x": "zirçêna (m)",
    "y": " b. zirkêna (m)"
  },
  {
    "x": "zirçênî (m)",
    "y": " bağırtı, bağırış, çığlık"
  },
  {
    "x": "zirçî (m)",
    "y": " bağırtı, bağırış, çığlık"
  },
  {
    "x": "zirçî dekewtene",
    "y": " çığlık atmak, çığlığı basmak, çığlık koparmak"
  },
  {
    "x": "zirçî dekotene",
    "y": " b. zirçî dekewtene"
  },
  {
    "x": "zirçî dekowtene",
    "y": " b. zirçî dekewtene"
  },
  {
    "x": "zirçîdekewtiş (n)",
    "y": " çığlık atma, çığlığı basma, çığlık koparma"
  },
  {
    "x": "zirçîdekotiş (n)",
    "y": " b. zirçîdekewtiş (n)"
  },
  {
    "x": "zirçîn (m)",
    "y": " b. zirçênî (m)"
  },
  {
    "x": "zirçîye (m)",
    "y": " bağırtı, bağırış, çığlık"
  },
  {
    "x": "zirî (m)",
    "y": " anırma, anırtı"
  },
  {
    "x": "zirkeyna (m)",
    "y": " b. zirkêna (m) "
  },
  {
    "x": "zirkêna (m)",
    "y": " üvey kız"
  },
  {
    "x": "zirna (m)",
    "y": " zurna"
  },
  {
    "x": "zirnayene",
    "y": " anırtmak"
  },
  {
    "x": "zirnayîş (n)",
    "y": " anırtma"
  },
  {
    "x": "zirne (n)",
    "y": " b. zirna (m)"
  },
  {
    "x": "zirq (n)",
    "y": " b. zerq (n)"
  },
  {
    "x": "zirqit (m)",
    "y": " b. zerqete (m) "
  },
  {
    "x": "zirqite (m)",
    "y": " b. zerqete (m)"
  },
  {
    "x": "zirqiti (m)",
    "y": " b. zerqete (m) "
  },
  {
    "x": "zirqitiki (m)",
    "y": " b. zerqete (m) "
  },
  {
    "x": "zirrayene",
    "y": " b. zirayene"
  },
  {
    "x": "zirrayîş (n)",
    "y": " b. zirayîş (n)"
  },
  {
    "x": "zirtik (n)",
    "y": " çifte (at, eşek vb. tekmesi)"
  },
  {
    "x": "zirtik çekerdene",
    "y": " çifte atmak"
  },
  {
    "x": "zirtik çekerdeni",
    "y": " b. zirtik çekerdene"
  },
  {
    "x": "zirtik eştene",
    "y": " çifte atmak"
  },
  {
    "x": "zirtik eşteni",
    "y": " b. zirtik eştene"
  },
  {
    "x": "zirtile (n)",
    "y": " b. ziçile (n)"
  },
  {
    "x": "zirtile kerdene",
    "y": " b. ziçile kerdene"
  },
  {
    "x": "zirtzirt (n)",
    "y": " zart zurt"
  },
  {
    "x": "zirtzirt kerdene",
    "y": " zart zurt etmek"
  },
  {
    "x": "zirze-I (n)",
    "y": " fermuar"
  },
  {
    "x": "zirze-II (n)",
    "y": " b. zerze (n)"
  },
  {
    "x": "zirzir (n)",
    "y": " ağlama sesi (argo)"
  },
  {
    "x": "zit, -e",
    "y": " çıplak"
  },
  {
    "x": "zit-bana (m)",
    "y": " çırılçıplak, çırçıplak"
  },
  {
    "x": "zit-bane (n)",
    "y": " çırılçıplak, çırçıplak"
  },
  {
    "x": "zit-bit",
    "y": " b. zit-bulit, -e"
  },
  {
    "x": "zit-bulit, -e",
    "y": " çırılçıplak, çırçıplak"
  },
  {
    "x": "zit-but",
    "y": " b. zit-bulit, -e"
  },
  {
    "x": "zit û bit",
    "y": " b. zit-bulit, -e"
  },
  {
    "x": "zit û but",
    "y": " b. zit-bulit, -e"
  },
  {
    "x": "zitle (n)",
    "y": " b. ziçile (n)"
  },
  {
    "x": "zitle kerdene",
    "y": " b. ziçile kerdene"
  },
  {
    "x": "zivil (n)",
    "y": " b. zibil (n)"
  },
  {
    "x": "zivilca (n)",
    "y": " b. zibilca (n)"
  },
  {
    "x": "zivir (n)",
    "y": " pürüz"
  },
  {
    "x": "zivirin, -e",
    "y": " pürüzlü"
  },
  {
    "x": "zivl (n)",
    "y": " b. zibil (n)"
  },
  {
    "x": "zivlûzebali (m)",
    "y": " b. ziblûzebale (m)"
  },
  {
    "x": "ziwa, -ye",
    "y": " kuru"
  },
  {
    "x": "ziwa bîyene",
    "y": " kurumak"
  },
  {
    "x": "ziwa kerdene",
    "y": " kurutmak"
  },
  {
    "x": "ziwabîyayîş (n)",
    "y": " kuruma"
  },
  {
    "x": "ziwakerdiş (n)",
    "y": " kurutma"
  },
  {
    "x": "ziwan (n)",
    "y": " dil, lisan"
  },
  {
    "x": "ziwan deginayene",
    "y": " kekelemek, kekeme olmak, kekeç olmak"
  },
  {
    "x": "ziwan girê dîyayene",
    "y": " ağzı dili tutulmak"
  },
  {
    "x": "ziwan gîrîyayene",
    "y": " ağzı dili tutulmak"
  },
  {
    "x": "ziwan têver û têpeyî eştene",
    "y": " ağzında gevelemek\r\n~Qey ti ziwan erzena têver û têpeyî? (Niçin ağzında geveliyorsun?)"
  },
  {
    "x": "ziwanê aqrebayî (zh)",
    "y": " akraba diller"
  },
  {
    "x": "ziwanê dayîke (n)",
    "y": " ana dili"
  },
  {
    "x": "ziwanê fîlmî (n)",
    "y": " filmin dili"
  },
  {
    "x": "ziwanê îşaretî (n)",
    "y": " işaret dili"
  },
  {
    "x": "ziwanê kilîtî (n)",
    "y": " anahtar"
  },
  {
    "x": "ziwanê mare (n)",
    "y": " b. ziwanê dayîke (n)"
  },
  {
    "x": "ziwanê mari (n)",
    "y": " b. ziwanê dayîke (n)"
  },
  {
    "x": "ziwanê maye (n)",
    "y": " b. ziwanê dayîke (n)"
  },
  {
    "x": "ziwanê nîşanî (n)",
    "y": " işaret dili"
  },
  {
    "x": "ziwanê nuştişî (n)",
    "y": " yazı dili"
  },
  {
    "x": "ziwanê wêretî (zh)",
    "y": " akraba diller"
  },
  {
    "x": "ziwano bingeyî (n)",
    "y": " ana dil (kendisinden başka diller türemiş olan dil)"
  },
  {
    "x": "ziwano nuştekî (n)",
    "y": " yazılı dil"
  },
  {
    "x": "ziwano qij (n)",
    "y": " küçükdil"
  },
  {
    "x": "ziwano qît (n)",
    "y": " küçükdil"
  },
  {
    "x": "ziwano yewin (n)",
    "y": " ana dili"
  },
  {
    "x": "ziwandeginaya (m)",
    "y": " kekeme, keke, kekeç"
  },
  {
    "x": "ziwandeginaye (n)",
    "y": " kekeme, keke, kekeç"
  },
  {
    "x": "ziwandeginayeyey (m)",
    "y": " b. ziwandeginayeyî  (m)"
  },
  {
    "x": "ziwandeginayeyî (m)",
    "y": " kekemelik, kekelik"
  },
  {
    "x": "ziwandeginayî, -ye",
    "y": " kekeme, keke, kekeç"
  },
  {
    "x": "ziwandeginayîş (n)",
    "y": " kekeleme, kekeme olma, kekeç olma"
  },
  {
    "x": "ziwangiran, -e",
    "y": " kekeme, keke, kekeç"
  },
  {
    "x": "ziwangiranî (m)",
    "y": " kekemelik, kekelik"
  },
  {
    "x": "ziwangiranîye (m)",
    "y": " kekemelik, kekelik"
  },
  {
    "x": "ziwanik (n)",
    "y": " küçükdil"
  },
  {
    "x": "ziwannas, -e",
    "y": " dilbilimci, dilci, lengüist"
  },
  {
    "x": "ziwannasî (m)",
    "y": " dilbilim, lengüistik"
  },
  {
    "x": "ziwannasîye (m)",
    "y": " dilbilim, lengüistik"
  },
  {
    "x": "ziwanzan, -e",
    "y": " dilci (dil ile ilgili çalışmaları olan)"
  },
  {
    "x": "ziwapakker, -e",
    "y": " kuru temizleyici"
  },
  {
    "x": "ziwar",
    "y": " b. zor, -e"
  },
  {
    "x": "ziwayê (m)",
    "y": " b. ziwayîye (m), ziwayî (m)"
  },
  {
    "x": "ziwayî (m)",
    "y": " kuruluk"
  },
  {
    "x": "ziwayîye (m)",
    "y": " kuruluk"
  },
  {
    "x": "ziwen (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "ziwo (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "ziwon (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "ziwû (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "ziwûn (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "zixal (n)",
    "y": " b. xizal-I (n)"
  },
  {
    "x": "zixar (n)",
    "y": " b. xizar (n) "
  },
  {
    "x": "zixêra (m)",
    "y": " b. zexîre (n)"
  },
  {
    "x": "zixm, -e",
    "y": " güçlü, çelimli"
  },
  {
    "x": "zixre (n)",
    "y": " b. zexîre (n)"
  },
  {
    "x": "zixt (n)",
    "y": " b. zext (n)"
  },
  {
    "x": "zî (zr)",
    "y": " 1)de\r\n~Ez zî zana ti zî. (Ben de biliyorum sen de.)\r\n~2)bile "
  },
  {
    "x": "zîbayêne",
    "y": " b. jîvayene"
  },
  {
    "x": "zîbayîş (n)",
    "y": " b. jîvayîş (n)"
  },
  {
    "x": "zîç (n)",
    "y": " 1)çapak\r\n~2)b. zîrç (n)-1"
  },
  {
    "x": "zîç kerdene",
    "y": " 1)çapaklanmak\r\n~2)b. zîrç kerdene-1"
  },
  {
    "x": "zîçkerdiş (n)",
    "y": " çapaklanma, çapaklanış"
  },
  {
    "x": "zîd",
    "y": " b. zîyade"
  },
  {
    "x": "zîde",
    "y": " b. zîyade"
  },
  {
    "x": "zîl (n)",
    "y": " 1)filiz\r\n~2)kamış"
  },
  {
    "x": "zîl dayene",
    "y": " filizlenmek, filiz vermek"
  },
  {
    "x": "zîl teber kerdene",
    "y": " filizlenmek, filiz vermek"
  },
  {
    "x": "zîl tever kerdene",
    "y": " b. zîl teber kerdene"
  },
  {
    "x": "zîleyîne",
    "y": " b. zîlîyayene"
  },
  {
    "x": "zîlik (n)",
    "y": " 1)filiz\r\n~2)bızır, klitoris"
  },
  {
    "x": "zîlik dayene",
    "y": " filizlenmek, filiz vermek"
  },
  {
    "x": "zîlikê pîyazî (n)",
    "y": " cücük (soğan cücüğü)"
  },
  {
    "x": "zîlî (m)",
    "y": " kemer (yünden yapılıp kadınlarca kullanılan)"
  },
  {
    "x": "zîlîyayene",
    "y": " filizlenmek, filiz vermek"
  },
  {
    "x": "zîlyed, -e (huq.)",
    "y": " zilyet"
  },
  {
    "x": "zîlyedî (m)",
    "y": " zilyetlik"
  },
  {
    "x": "Zîmbabwe",
    "y": " Zimbabwe"
  },
  {
    "x": "zîmet (n)",
    "y": " zimmet"
  },
  {
    "x": "zîmetê ... kewtene",
    "y": " zimmetine geçmek"
  },
  {
    "x": "zîmziq (m)",
    "y": " b. zumzuqe (m)"
  },
  {
    "x": "zîn (n)",
    "y": " eyer"
  },
  {
    "x": "zîn ronayene",
    "y": " hayvanın eyerini indirmek"
  },
  {
    "x": "zîn ser kerdene",
    "y": " hayvanın eyerini indirmek"
  },
  {
    "x": "zîn ser o kerdene",
    "y": " b. zîn ser kerdene"
  },
  {
    "x": "zîncil (n)",
    "y": " b. zincîre (m)"
  },
  {
    "x": "zîncile (m)",
    "y": " b. zincîre (m)"
  },
  {
    "x": "zîncir (n)",
    "y": " b. zincîre (m)"
  },
  {
    "x": "zîncî (m)",
    "y": " et parçası"
  },
  {
    "x": "zîncîya hurdî (m)",
    "y": " kuşbaşı et"
  },
  {
    "x": "zîncîle (m)",
    "y": " b. zincîre (m)"
  },
  {
    "x": "zîndan (n)",
    "y": " zindan, hapishane"
  },
  {
    "x": "zînet (n)",
    "y": " b. zîynet (n)"
  },
  {
    "x": "zînetin, -e",
    "y": " b. zîynetin, -e"
  },
  {
    "x": "zînetini (m)",
    "y": " b. zîynetin (m)"
  },
  {
    "x": "zînetlî, -ye",
    "y": " b. zîynetlî, -ye"
  },
  {
    "x": "zînhar",
    "y": " sakın"
  },
  {
    "x": "zîp (n) (TE)",
    "y": " sıkıştırma"
  },
  {
    "x": "zîpe (n)",
    "y": " b. sipe-I (n)"
  },
  {
    "x": "zîpike (m)",
    "y": " buz sarkıntısı, buz saçağı"
  },
  {
    "x": "zîpiki (m)",
    "y": " b. zîpike (m)"
  },
  {
    "x": "zîppiki (m)",
    "y": " b. zîpike (m)"
  },
  {
    "x": "zîqayene",
    "y": " anırmak"
  },
  {
    "x": "zîqayîş (n)",
    "y": " anırma"
  },
  {
    "x": "zîqî (m)",
    "y": " b. zirî (m)"
  },
  {
    "x": "zîqnayene",
    "y": " anırtmak"
  },
  {
    "x": "zîqnayîş (n)",
    "y": " anırtma"
  },
  {
    "x": "zîr (m)",
    "y": " b. zure (m)"
  },
  {
    "x": "zîrat (n)",
    "y": " b. zîret (n) "
  },
  {
    "x": "zîrç (n)",
    "y": " 1)sağı, kuş dışkısı, kuş tersi; kuş gübresi \r\n~2)tavuk dışkısı\r\n~3)b. zîç (n)-1"
  },
  {
    "x": "zîrç kerdene",
    "y": " 1)(kuş, tavuk vb. için) dışkı yapmak\r\n~2)b. zîç kerdene-1"
  },
  {
    "x": "zîreat (n)",
    "y": " b. zîret (n) "
  },
  {
    "x": "zîreet (n)",
    "y": " b. zîret (n)"
  },
  {
    "x": "zîrekî (m)",
    "y": " zekâ"
  },
  {
    "x": "zîret (n)",
    "y": " tarım, ziraat"
  },
  {
    "x": "zîreto awî (n)",
    "y": " sulu ziraat, sulu tarım"
  },
  {
    "x": "zîretnasî (n)",
    "y": " agronomi"
  },
  {
    "x": "zîrhet (n)",
    "y": " b. zîret (n) "
  },
  {
    "x": "zîrik (n)",
    "y": " b. zerq (n)"
  },
  {
    "x": "zîrt (n)",
    "y": " b. zîrç (n)"
  },
  {
    "x": "zîrt kerdene",
    "y": " b. zîrç kerdene"
  },
  {
    "x": "zîv-zixal (m)",
    "y": " b. ziblûzebale (m)"
  },
  {
    "x": "zîvayêne",
    "y": " b. jîvayene"
  },
  {
    "x": "zîvayîş (n)",
    "y": " b. jîvayîş (n)"
  },
  {
    "x": "zîvker, -e",
    "y": " kuyumcu, mücevherci"
  },
  {
    "x": "zîvkerî (m)",
    "y": " kuyumculuk, mücevhercilik"
  },
  {
    "x": "zîvkerîye (m)",
    "y": " kuyumculuk, mücevhercilik"
  },
  {
    "x": "zîwon (n)",
    "y": " b. zîyan-II (n)"
  },
  {
    "x": "zîwonî, -ye",
    "y": " b. zîyanî, -ye"
  },
  {
    "x": "zîwûnî",
    "y": " b. zîyanî, -ye"
  },
  {
    "x": "zîya (n)",
    "y": " b. zîyan (n)"
  },
  {
    "x": "zîyade",
    "y": " ziyade, çok, fazla"
  },
  {
    "x": "tewr zîyade",
    "y": " en çok, azami, en fazla"
  },
  {
    "x": "zîyade bîyene",
    "y": " artmak, çoğalmak\r\n~Wa zîyade bo. (Ziyade olsun.)\r\n~\t\r\n~Zîyade bo. (Ziyade olsun.)"
  },
  {
    "x": "zîyade kerdene",
    "y": " artırmak, çoğaltmak\r\n~Heq zîyade biko. (Tanrı ziyade etsin/artırsın.) \r\n~\t\r\n~Homa zîyade biko. (Tanrı ziyade etsin/artırsın.)"
  },
  {
    "x": "zîyadeyê zîyadeyan",
    "y": " azami"
  },
  {
    "x": "zîyadebîyayîş (n)",
    "y": " artma, çoğalma"
  },
  {
    "x": "zîyadeker (n)",
    "y": " çoğaltıcı"
  },
  {
    "x": "zîyadekerdiş (n)",
    "y": " artırma, çoğaltma"
  },
  {
    "x": "zîyadeyî (m)",
    "y": " fazlalık"
  },
  {
    "x": "zîyafet (n)",
    "y": " şölen, ziyafet"
  },
  {
    "x": "zîyafet dayene",
    "y": " ziyafet çekmek, şölen çekmek"
  },
  {
    "x": "zîyafet dayêne",
    "y": " b. zîyafet dayene"
  },
  {
    "x": "zîyan-I (n)",
    "y": " ekin"
  },
  {
    "x": "zîyan-II (n)",
    "y": " zarar"
  },
  {
    "x": "zîyan ... resnayene",
    "y": " zarar vermek"
  },
  {
    "x": "zîyandar, -e",
    "y": " zararlı"
  },
  {
    "x": "zîyanî, -ye",
    "y": " 1)zararlı\r\n~2)sık sık ekin tarlasına girip ekin yiyerek zarar veren küçükbaş ve büyükbaş hayvan, yeğe"
  },
  {
    "x": "zîyankar, -e",
    "y": " zararlı"
  },
  {
    "x": "zîyanok, -e",
    "y": " sık sık ekin tarlasına girip ekin yiyerek zarar veren küçükbaş ve büyükbaş hayvan"
  },
  {
    "x": "zîyaret (n)",
    "y": " ziyaret"
  },
  {
    "x": "zîyaret kerdene",
    "y": " ziyaret etmek"
  },
  {
    "x": "zîyaretê esnafan (n)",
    "y": " esnaf ziyareti"
  },
  {
    "x": "zîyaretkerdiş (n)",
    "y": " ziyaret etme"
  },
  {
    "x": "zîyd",
    "y": " b. zîyade"
  },
  {
    "x": "zîyed",
    "y": " b. zîyade"
  },
  {
    "x": "zîyede",
    "y": " b. zîyade"
  },
  {
    "x": "zîyedi",
    "y": " b. zîyade"
  },
  {
    "x": "zîynet (n)",
    "y": " süs"
  },
  {
    "x": "zîynetin, -e",
    "y": " süslü"
  },
  {
    "x": "zîynetlî, -ye",
    "y": " süslü"
  },
  {
    "x": "zîyon (n)",
    "y": " b. zîyan (n)"
  },
  {
    "x": "zîyûn-I (n)",
    "y": " b. zîyan (n)"
  },
  {
    "x": "zîyûn-II (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "zîyûnî",
    "y": " b. zîyanî, -ye"
  },
  {
    "x": "zîyt (n)",
    "y": " b. zîrç (n)"
  },
  {
    "x": "zîyt kerdene",
    "y": " b. zîrç kerdene"
  },
  {
    "x": "zîz, -e",
    "y": " duygulu, duygun"
  },
  {
    "x": "zîzey (m)",
    "y": " b. zîzî (m), zîzîye (m)"
  },
  {
    "x": "zîzê (m)",
    "y": " b. zîzî (m), zîzîye (m)"
  },
  {
    "x": "zîzênî (m)",
    "y": " duygululuk, duygunluk"
  },
  {
    "x": "zîzi (m)",
    "y": " b. zîze (m)"
  },
  {
    "x": "zîzî (m)",
    "y": " duygululuk, duygunluk"
  },
  {
    "x": "zîzîye (m)",
    "y": " duygululuk, duygunluk"
  },
  {
    "x": "zo-I (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "zo-II (n)",
    "y": " b. jan-I (n)"
  },
  {
    "x": "zobî",
    "y": " b. sewbîn"
  },
  {
    "x": "zobîna",
    "y": " b. sewbîna"
  },
  {
    "x": "zof",
    "y": " b. zaf"
  },
  {
    "x": "zolal, -e",
    "y": " b. zelal, -e"
  },
  {
    "x": "zoma-I (n)",
    "y": " b. zama-I (n)"
  },
  {
    "x": "zomon (n)",
    "y": " b. zeman (n)"
  },
  {
    "x": "zomp (n)",
    "y": " b. zompe (m)"
  },
  {
    "x": "zompe (m)",
    "y": " balyoz"
  },
  {
    "x": "zompe piro dayene",
    "y": " balyozlamak"
  },
  {
    "x": "zompepirodayîş (n)",
    "y": " balyozlama"
  },
  {
    "x": "zompi (m)",
    "y": " b. zompe (m)"
  },
  {
    "x": "zomq (n)",
    "y": " b. zamqe (m)"
  },
  {
    "x": "zomqi (m)",
    "y": " b. zamqe (m)"
  },
  {
    "x": "zon-I (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "zon degunayene",
    "y": " b. ziwan deginayene"
  },
  {
    "x": "zonê kilîtî (n)",
    "y": " b. ziwanê kilîtî (m)"
  },
  {
    "x": "zono qic (n)",
    "y": " b. ziwano qij (n)"
  },
  {
    "x": "zon-II (n)",
    "y": " b. jan-I (n)"
  },
  {
    "x": "zon ci kewtene",
    "y": " b. jan dekewtene"
  },
  {
    "x": "zon dayêne",
    "y": " b. jan dayene"
  },
  {
    "x": "zonê sarî (n)",
    "y": " b. janê sereyî (n)"
  },
  {
    "x": "zonaya (m)",
    "y": " b. zanaya (m), zanayîye (m)"
  },
  {
    "x": "zonaye (n)",
    "y": " b. zanaye (n)"
  },
  {
    "x": "zonayene",
    "y": " b. zanayene"
  },
  {
    "x": "zonayêne",
    "y": " b. zanayene"
  },
  {
    "x": "zonayîne",
    "y": " b. zanayene"
  },
  {
    "x": "zonayîş (n)",
    "y": " b. zanayîş (n)"
  },
  {
    "x": "zonayîye (m)",
    "y": " b. zanayîye (m)"
  },
  {
    "x": "zonc (m)",
    "y": " b. zewnce (m)"
  },
  {
    "x": "zonc-fart",
    "y": " b. zewnce-fert"
  },
  {
    "x": "zonc-fert",
    "y": " b. zewnce-fert"
  },
  {
    "x": "zonc-tek",
    "y": " b. zewnce-tek"
  },
  {
    "x": "zonc û fart",
    "y": " b. zewnc û fert"
  },
  {
    "x": "zonc û fert",
    "y": " b. zewnc û fert"
  },
  {
    "x": "zonc û tek",
    "y": " b. zewnc û tek"
  },
  {
    "x": "zonce (m)",
    "y": " b. zewnce (m)"
  },
  {
    "x": "zonci (m)",
    "y": " b. zewnce (m)"
  },
  {
    "x": "zondegunayîş (n)",
    "y": " b. ziwandeginayîş (n)"
  },
  {
    "x": "zondes",
    "y": " b. yewendes"
  },
  {
    "x": "zone (n)",
    "y": " b. zanî (n)"
  },
  {
    "x": "zongiron, -i",
    "y": " b. ziwangiran, -e"
  },
  {
    "x": "zongironey (m)",
    "y": " b. ziwangiranî (m)"
  },
  {
    "x": "zongironê (m)",
    "y": " b. ziwangiranî (m)"
  },
  {
    "x": "zoni (n)",
    "y": " b. zanî (n)"
  },
  {
    "x": "zonî (n)",
    "y": " b. zanî (n)"
  },
  {
    "x": "zonî amayene",
    "y": " b. zanî ameyene"
  },
  {
    "x": "zonî ronayene",
    "y": " b. zanî ronayene"
  },
  {
    "x": "zonîamayîs (n)",
    "y": " b. zanîameyîş (n)"
  },
  {
    "x": "zonîronayîs (n)",
    "y": " b. zanîronayîş (n)"
  },
  {
    "x": "zonîyonseroroniştiş (n)",
    "y": " b. zanîyanseroroniştiş (n) "
  },
  {
    "x": "zonqil (n)",
    "y": " b. zanqile (n)"
  },
  {
    "x": "zonqile (n)",
    "y": " b. zanqile (n)"
  },
  {
    "x": "zoom (n, sn)",
    "y": " zoom"
  },
  {
    "x": "zoom kerdene (sn)",
    "y": " zoomlamak"
  },
  {
    "x": "zoomkerdiş (n, sn)",
    "y": " zoomlama"
  },
  {
    "x": "zope-I (n)",
    "y": " sopa"
  },
  {
    "x": "zope-II (m)",
    "y": " b. zompe (m)"
  },
  {
    "x": "zopi-I (n)",
    "y": " b. zope (n)"
  },
  {
    "x": "zopi-II (m)",
    "y": " b. zompe (m)"
  },
  {
    "x": "zor, -e",
    "y": " 1)güç\r\n~2)zor (n), zor (zr), çetin (s), müşkül"
  },
  {
    "x": "zor bîyene (mecazî)",
    "y": " çatallaşmak (mecazi)"
  },
  {
    "x": "zor dayene",
    "y": " 1)zorlamak\r\n~2)sıkıştırmak (bir şeyi yapması için sıkıştırmak)"
  },
  {
    "x": "zor bê ... şîyene",
    "y": " b. bi zorê ... şîyene"
  },
  {
    "x": "zorê ... bardene",
    "y": " b. zorê ... berdene"
  },
  {
    "x": "zorê ... berdene",
    "y": " ütmek, utmak (oyun için), yenmek"
  },
  {
    "x": "zorê ... şîyene",
    "y": " yenilmek, yenilgiye uğramak, bozguna uğramak, bozguna vermek, hezimete uğramak"
  },
  {
    "x": "zor-çiqam (zr)",
    "y": " b. zor-çiqîyame (zr)"
  },
  {
    "x": "zor çiqiyam (zr)",
    "y": " b. zor-çiqîyame (zr)"
  },
  {
    "x": "zor-çiqîyame (zr)",
    "y": " güç bela (zr), güçlükle (zr), zorlukla (zr), bata çıka (zr), zor (zr)"
  },
  {
    "x": "zorba (m)",
    "y": " despot, tiran, zorba"
  },
  {
    "x": "zorbakî",
    "y": " b. zorbekî"
  },
  {
    "x": "zorbaye (m)",
    "y": " despot, tiran, zorba"
  },
  {
    "x": "zorbayey (m)",
    "y": " b. zorbeyîye (m)"
  },
  {
    "x": "zorbaz, -e",
    "y": " despot, tiran, zorba"
  },
  {
    "x": "zorbazane",
    "y": " despotça, despotik"
  },
  {
    "x": "zorbe-I (n)",
    "y": " despot, tiran, zorba"
  },
  {
    "x": "zorbe-II (n)",
    "y": " b. zerbe (n)"
  },
  {
    "x": "zorbekî",
    "y": " despotça, despotik"
  },
  {
    "x": "zorbeyey (m)",
    "y": " b. zorbeyîye (m)"
  },
  {
    "x": "zorbeyê (m)",
    "y": " b. zorbeyî (m)"
  },
  {
    "x": "zorbeyî (m)",
    "y": " despotizm, despotluk, istibdat"
  },
  {
    "x": "zorbeyîye (m)",
    "y": " despotizm, despotluk, istibdat"
  },
  {
    "x": "zorbîyayîş (mecazî)  (n)",
    "y": " çatallaşma (mecazi)"
  },
  {
    "x": "zordar, -e",
    "y": " despot, tiran, zorba"
  },
  {
    "x": "zordarane",
    "y": " despotça, despotik"
  },
  {
    "x": "zordarey (m)",
    "y": " b. zordarîye (m)"
  },
  {
    "x": "zordarê (m)",
    "y": " b. zordarî (m)"
  },
  {
    "x": "zordarî (m)",
    "y": " despotizm, despotluk, istibdat"
  },
  {
    "x": "zordarîye (m)",
    "y": " despotizm, despotluk, istibdat"
  },
  {
    "x": "zordayîş (n)",
    "y": " 1)zorlama\r\n~2)sıkıştırma (bir şeyi yapması için sıkıştırma)"
  },
  {
    "x": "zorê (m)",
    "y": " b. zorî (m)"
  },
  {
    "x": "zorî (m)",
    "y": " 1)zorluk\r\n~2)müşkül"
  },
  {
    "x": "zorîye (m)",
    "y": " 1)zorluk\r\n~2)müşkül"
  },
  {
    "x": "zorkarî (m)",
    "y": " zorlama, zorunda bırakma, icbar"
  },
  {
    "x": "zorkarîye (m)",
    "y": " zorlama, zorunda bırakma, icbar"
  },
  {
    "x": "zorşîyayîş (n)",
    "y": " yenilgi, bozgun, hezimet"
  },
  {
    "x": "zot (m)",
    "y": " b. zewte (m)"
  },
  {
    "x": "zoti (m)",
    "y": " b. zewte (m)"
  },
  {
    "x": "zotik (n)",
    "y": " b. zutike"
  },
  {
    "x": "zov",
    "y": " b. sewbîn"
  },
  {
    "x": "zov û zêç",
    "y": " b. zar û zêç"
  },
  {
    "x": "zovî",
    "y": " b. sewbîn"
  },
  {
    "x": "zovîn",
    "y": " b. sewbîn"
  },
  {
    "x": "zovîna",
    "y": " b. sewbîna"
  },
  {
    "x": "zovna",
    "y": " b. sewbîna"
  },
  {
    "x": "zowjîyayene",
    "y": " b. zewicîyayene"
  },
  {
    "x": "zowjîyayîş (n)",
    "y": " b. zewicîyayîş (n)"
  },
  {
    "x": "zownc (m)",
    "y": " b. zewnce (m)"
  },
  {
    "x": "zownci (m)",
    "y": " b. zewnce (m)"
  },
  {
    "x": "zowopi (n)",
    "y": " zope-I (n)"
  },
  {
    "x": "zowt (n)",
    "y": " b. zewte (m)"
  },
  {
    "x": "zowti (m)",
    "y": " b. zewte (m)"
  },
  {
    "x": "zoycîyayene",
    "y": " b. zewicîyayene"
  },
  {
    "x": "zoycîyayîş (n)",
    "y": " b. zewicîyayîş (n)"
  },
  {
    "x": "zoyvî",
    "y": " b. sewbîn"
  },
  {
    "x": "zoyvîna",
    "y": " b. sewbîna"
  },
  {
    "x": "zozan (n)",
    "y": " yayla"
  },
  {
    "x": "zozo (n)",
    "y": " b. zozan (n)"
  },
  {
    "x": "zozon (n)",
    "y": " b. zozan (n)"
  },
  {
    "x": "zozû (n)",
    "y": " b. zozan (n)"
  },
  {
    "x": "zozûn (n)",
    "y": " b. zozan (n)"
  },
  {
    "x": "zu, -ye",
    "y": " b. yew, -e"
  },
  {
    "x": "zu kerdene",
    "y": " b. yew kerdene"
  },
  {
    "x": "zu ve zu",
    "y": " b. yew bi yew"
  },
  {
    "x": "zu vîyayene",
    "y": " b. yew bîyayene "
  },
  {
    "x": "zu vîyayêne",
    "y": " b. yew bîyayene"
  },
  {
    "x": "zua (n)",
    "y": " b. ziwa (n)"
  },
  {
    "x": "zuar",
    "y": " b. zor, -e"
  },
  {
    "x": "zuarba (m)",
    "y": " b. zorba (m)"
  },
  {
    "x": "zuarbaz",
    "y": " b. zorbaz, -e"
  },
  {
    "x": "zuarbazûne",
    "y": " b. zorbazane"
  },
  {
    "x": "zuarbe (n)",
    "y": " b. zorbe (n)"
  },
  {
    "x": "zuarbekî",
    "y": " b. zorbekî"
  },
  {
    "x": "zuarbeyê (m)",
    "y": " b. zorbeyî (m)"
  },
  {
    "x": "zuarbeyî (m)",
    "y": " b. zorbeyî (m)"
  },
  {
    "x": "zuardar",
    "y": " b. zordar, -e"
  },
  {
    "x": "zuardarê (m)",
    "y": " b. zordarî (m)"
  },
  {
    "x": "zuardarî (m)",
    "y": " b. zordarî (m)"
  },
  {
    "x": "zuardarûne",
    "y": " b. zordarane"
  },
  {
    "x": "zuarê (m)",
    "y": " b. zorî (m)"
  },
  {
    "x": "zubîyaye (n)",
    "y": " b. yewbîyaye (n)"
  },
  {
    "x": "zubîyayîş (n)",
    "y": " b. yewbîyayîş (n)"
  },
  {
    "x": "zubîyayîye (n)",
    "y": " b. yewbîyayîye (m)"
  },
  {
    "x": "zuer",
    "y": " b. zor, -e"
  },
  {
    "x": "zuerê (m)",
    "y": " b. zorî (m)"
  },
  {
    "x": "zugir kerdene",
    "y": " başıboş dolaşmak, başıboş gezmek"
  },
  {
    "x": "zuiq (n)",
    "y": " b. quz (n)"
  },
  {
    "x": "zuk û zuwa, -ye",
    "y": " b. zik û ziwa, -ye"
  },
  {
    "x": "zuk-zuwa, -ye",
    "y": " b. zik-ziwa, -ye"
  },
  {
    "x": "zukaîs (n)",
    "y": " b. zukayîş (n)"
  },
  {
    "x": "zukayene",
    "y": " (mecazi) ağlamak"
  },
  {
    "x": "zukayîs (n)",
    "y": " b. zukayîş (n)"
  },
  {
    "x": "zukayîş (n)",
    "y": " (mecazi) ağlama"
  },
  {
    "x": "zukek, -e",
    "y": " b. yewek, -e"
  },
  {
    "x": "zukerdiş (n)",
    "y": " b. yewkerdiş (n)"
  },
  {
    "x": "zul (n)",
    "y": " bir balık çeşidi"
  },
  {
    "x": "zulim (n)",
    "y": " b. zulm (n)"
  },
  {
    "x": "zulim kerdene",
    "y": " b. zulm kerdene"
  },
  {
    "x": "zulimkar, -e",
    "y": " b. zulmkar, -e"
  },
  {
    "x": "zulimkarey (m)",
    "y": " b. zulmkarîye (m)"
  },
  {
    "x": "zulimkarê (m)",
    "y": " b. zulmkarî (m)"
  },
  {
    "x": "zulimkerdiş (n)",
    "y": " b. zulmkerdiş (n)"
  },
  {
    "x": "zulit, -e",
    "y": " b. zilut, -e"
  },
  {
    "x": "zulm (n)",
    "y": " zulüm"
  },
  {
    "x": "zulm kerdene",
    "y": " zulmetmek, zulümde bulunmak"
  },
  {
    "x": "zulmkar, -e",
    "y": " zalim"
  },
  {
    "x": "zulmkarey (m)",
    "y": " b. zulmkarîye (m)"
  },
  {
    "x": "zulmkarê (m)",
    "y": " b. zulmkarî (m)"
  },
  {
    "x": "zulmkarî (m)",
    "y": " zalimlik"
  },
  {
    "x": "zulmkarîye (m)",
    "y": " zalimlik"
  },
  {
    "x": "zulmkerdiş (n)",
    "y": " zulmetme"
  },
  {
    "x": "zulum (n)",
    "y": " b. zulm (n)"
  },
  {
    "x": "zulum kerdene",
    "y": " b. zulm kerdene"
  },
  {
    "x": "zulumkar, -e",
    "y": " b. zulmkar, -e"
  },
  {
    "x": "zulumkarey (m)",
    "y": " b. zulmkarîye (m)"
  },
  {
    "x": "zulumkarê (m)",
    "y": " b. zulmkarî (m)"
  },
  {
    "x": "zulumkerdiş (n)",
    "y": " b. zulmkerdiş (n)"
  },
  {
    "x": "zulut, -e",
    "y": " b. zilut, -e"
  },
  {
    "x": "zulzulike (m)",
    "y": " soluk borusu, nefes borusu "
  },
  {
    "x": "zumburê çarixî (n)",
    "y": " çarık burnu"
  },
  {
    "x": "zumburê solî (n)",
    "y": " ayakkabı burnu"
  },
  {
    "x": "zumburberz, -e",
    "y": " kibirli, büyüklenen"
  },
  {
    "x": "zumburberz bîyene",
    "y": " kibirlenmek"
  },
  {
    "x": "zumburberzî (m)",
    "y": " kibirlilik"
  },
  {
    "x": "zumburberzîye (m)",
    "y": " kibirlilik"
  },
  {
    "x": "zumistan (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "zumistanî",
    "y": " b. zimistanî-I"
  },
  {
    "x": "zumistû (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "zumistûn (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "zumust (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "zumusto (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "zumuston (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "zumustona (m)",
    "y": " b. zimistana (m)"
  },
  {
    "x": "zumustone (n)",
    "y": " b. zimistane (n)"
  },
  {
    "x": "zumustonên,-e",
    "y": " b. zimistanên, -e"
  },
  {
    "x": "zumustonîn,-e",
    "y": " b. zimistanên, -e"
  },
  {
    "x": "zumustonîye (m)",
    "y": " b. zimistanîye (m)"
  },
  {
    "x": "zumustû (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "zumustûn (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "zumustûna (m)",
    "y": " b. zimistana (m)"
  },
  {
    "x": "zumustûne (n)",
    "y": " b. zimistane (n)"
  },
  {
    "x": "zumustûnên, -e",
    "y": " b. zimistanên, -e"
  },
  {
    "x": "zumustûnîn, -e",
    "y": " b. zimistanên, -e"
  },
  {
    "x": "zumustûnîye (m)",
    "y": " b. zimistanîye (m)"
  },
  {
    "x": "zumzuq (m)",
    "y": " b. zumzuqe (m)"
  },
  {
    "x": "zumzuqe (m)",
    "y": " yumruk"
  },
  {
    "x": "zumzuqî eştene",
    "y": " yumruk atmak, yumruk indirmek"
  },
  {
    "x": "zumzuqî kerdene",
    "y": " yumruklamak"
  },
  {
    "x": "zumzuqî kerdeni",
    "y": " b. zumzuqî kerdene"
  },
  {
    "x": "zumzuqî piro dayene",
    "y": " yumruk atmak, yumruk indirmek"
  },
  {
    "x": "zumzuqî piro ginayene",
    "y": " yumruklanmak"
  },
  {
    "x": "zumzuqî werdene",
    "y": " yumruklanmak"
  },
  {
    "x": "zumzuqîeştiş (n)",
    "y": " yumruk atma"
  },
  {
    "x": "zumzuqîkerdiş (n)",
    "y": " yumruklama"
  },
  {
    "x": "zumzuqîpirodayîş (n)",
    "y": " yumruk atma"
  },
  {
    "x": "zumzuqîpiroginayîş (n)",
    "y": " yumruklanma"
  },
  {
    "x": "zumzuqîwerdiş (n)",
    "y": " yumruklanma"
  },
  {
    "x": "zuna",
    "y": " b. yewna-I"
  },
  {
    "x": "zunburê çarixî (n)",
    "y": " b. zumburê çarixî (n)"
  },
  {
    "x": "zunburê solî (n)",
    "y": " b. zumburê solî (n)"
  },
  {
    "x": "zup û zuya, -ye",
    "y": " b. zip û ziwa, -ye"
  },
  {
    "x": "zuqaq (n)",
    "y": " b. ziqaq (n)"
  },
  {
    "x": "zuqlike (m)",
    "y": " soluk borusu; nefes borusu "
  },
  {
    "x": "zuqliki (m)",
    "y": " b. zuqlike (m)"
  },
  {
    "x": "zuqliqe (m)",
    "y": " b. zuqlike (m)"
  },
  {
    "x": "zuqliqi (m)",
    "y": " b. zuqlike (m)"
  },
  {
    "x": "zuqlorik (m)",
    "y": " b. zuqlike (m)"
  },
  {
    "x": "zuqloriki (m)",
    "y": " b. zuqlike (m)"
  },
  {
    "x": "zuqluq (m)",
    "y": " b. zuqlike (m)"
  },
  {
    "x": "zur-I (m)",
    "y": " b. zure (m)"
  },
  {
    "x": "zur-II (n)",
    "y": " b. zure (m)"
  },
  {
    "x": "zuro bêçever (n)",
    "y": " b. zura bêkeybere (m)"
  },
  {
    "x": "zura (n)",
    "y": " 1)yalancı\r\n~2) gerçek olmayan, hakiki olmayan"
  },
  {
    "x": "zuraf (n)",
    "y": " zürafa"
  },
  {
    "x": "zurafa (m)",
    "y": " zürafa"
  },
  {
    "x": "zuray (m)",
    "y": " b. zuraye (m)"
  },
  {
    "x": "zuraye (m)",
    "y": " 1)yalancı\r\n~2) gerçek olmayan, hakiki olmayan"
  },
  {
    "x": "zurayene",
    "y": " b. zûrayene"
  },
  {
    "x": "zurayîş (n)",
    "y": " b. zûrayîş (n)"
  },
  {
    "x": "zure (m)",
    "y": " yalan\r\n~zura bêbinge (m): aslı astarı olmayan yalan\r\n~zura bêboçe (m):  aslı astarı olmayan yalan"
  },
  {
    "x": "zura bêbinge (m)",
    "y": " aslı astarı olmayan yalan"
  },
  {
    "x": "zura bêboçe (m)",
    "y": " aslı astarı olmayan yalan"
  },
  {
    "x": "zura bêboçi (m)",
    "y": " b. zura bêboçe (m)"
  },
  {
    "x": "zura bêçevere (m)",
    "y": " b. zura bêkeybere (m)"
  },
  {
    "x": "zura bêkeybere (m)",
    "y": " aslı astarı olmayan yalan"
  },
  {
    "x": "zura boçine (m)",
    "y": " aslı astarı olmayan yalan"
  },
  {
    "x": "zura boçqole (m)",
    "y": " aslı astarı olmayan yalan"
  },
  {
    "x": "zura boçqoli (m)",
    "y": " b. zura boçqole (m)"
  },
  {
    "x": "zuran ra bawer kerdene",
    "y": " afiş yutmak"
  },
  {
    "x": "zureker, -e",
    "y": " yalancı "
  },
  {
    "x": "zurekêr, -e",
    "y": " b. zureker, -e"
  },
  {
    "x": "zuret (n)",
    "y": " b. zurîyet (n)"
  },
  {
    "x": "zuri (m)",
    "y": " b. zure (m)"
  },
  {
    "x": "zuriqe (m)",
    "y": " soluk borusu, nefes borusu"
  },
  {
    "x": "zurî kerdene",
    "y": " yalan söylemek"
  },
  {
    "x": "zurîya ci nuştene",
    "y": " tekzip etmek, yalanlamak"
  },
  {
    "x": "zurîya ci vatene",
    "y": " tekzip etmek, yalanlamak"
  },
  {
    "x": "zurîker, -e",
    "y": " b. zureker, -e"
  },
  {
    "x": "zurîkerdiş (n)",
    "y": " yalan söyleme "
  },
  {
    "x": "zurîya (n)",
    "y": " b. zura (n)"
  },
  {
    "x": "zurîyacinuştiş (n)",
    "y": " tekzip, yalanlama"
  },
  {
    "x": "zurîyacivatiş (n)",
    "y": " tekzip, yalanlama"
  },
  {
    "x": "zurîyay (m)",
    "y": " b. zuraye (m)"
  },
  {
    "x": "zurîyet (n)",
    "y": " soy"
  },
  {
    "x": "zurker, -e",
    "y": " b. zureker, -e"
  },
  {
    "x": "zurna (m)",
    "y": " b. zirna (m)"
  },
  {
    "x": "zurrayene",
    "y": " b. zûrayene"
  },
  {
    "x": "zurrayêne",
    "y": " b. zûrayene"
  },
  {
    "x": "zurrayîş (n)",
    "y": " b. zûrayîş (n)"
  },
  {
    "x": "zurret (n)",
    "y": " b. zurîyet (n)"
  },
  {
    "x": "zurvanê pesewe (n)",
    "y": " gulyabani"
  },
  {
    "x": "zurya (n)",
    "y": " b. zura (n)"
  },
  {
    "x": "zurzuluk (m)",
    "y": " b. zulzulike (m)"
  },
  {
    "x": "zut, -e",
    "y": " b. zit, -e"
  },
  {
    "x": "zut-bana (m)",
    "y": " b. zit-bana (m)"
  },
  {
    "x": "zut-bane (n)",
    "y": " b. zit-bane (n)"
  },
  {
    "x": "zutik (n)",
    "y": " b. zutike"
  },
  {
    "x": "zutike (m)",
    "y": " anüs çıkıntısı"
  },
  {
    "x": "zuvin",
    "y": " b. yewbînî"
  },
  {
    "x": "zuvinî",
    "y": " b. yewbînî"
  },
  {
    "x": "zuvîn",
    "y": " b. yewbînî"
  },
  {
    "x": "zuvîn dîyayene",
    "y": " b. yewbînî dîyene"
  },
  {
    "x": "zuvîn guretene",
    "y": " b. yewbînî girewtene"
  },
  {
    "x": "zuvîn ra nayene",
    "y": " b. yewbînî ra nayene"
  },
  {
    "x": "zuvîndîyayîş (n)",
    "y": " b. yewbînîdîyayîş (n)"
  },
  {
    "x": "zuvînguretiş (n)",
    "y": " b. yewbînîgirewtiş (n)"
  },
  {
    "x": "zuvînî",
    "y": " b. yewbînî"
  },
  {
    "x": "zuvînî kotene",
    "y": " b. yewbînî kewtene"
  },
  {
    "x": "zuvînîkotiş (n)",
    "y": " b. yewbînîkewtiş (n)"
  },
  {
    "x": "zuvînranayîş (n)",
    "y": " b. yewbînîranayîş (n)"
  },
  {
    "x": "zuvîyaye (n)",
    "y": " b. yewbîyaye (n)"
  },
  {
    "x": "zuvîyayîş (n)",
    "y": " b. yewbîyayîş (n)"
  },
  {
    "x": "zuvîyayîye (m)",
    "y": " b. yewbîyayîye (m) "
  },
  {
    "x": "zuwa (n)",
    "y": " b. ziwa (n)"
  },
  {
    "x": "zuwa bîyayene",
    "y": " b. ziwa bîyene"
  },
  {
    "x": "zuwa kerdene",
    "y": " b. ziwa kerdene"
  },
  {
    "x": "zuwabîyayîş (n)",
    "y": " b. ziwabîyayîş (n)"
  },
  {
    "x": "zuwakerdiş (n)",
    "y": " b. ziwakerdiş (n)"
  },
  {
    "x": "zuwan (n)",
    "y": " b. ziwan (n) "
  },
  {
    "x": "zuwar",
    "y": " b. zor, -e"
  },
  {
    "x": "zuway (m)",
    "y": " b. ziwaye (m)"
  },
  {
    "x": "zuwayey (m)",
    "y": " b. ziwayîye (m)"
  },
  {
    "x": "zuwayî (m)",
    "y": " b. ziwayî (m)"
  },
  {
    "x": "zuwen (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "zuwer",
    "y": " b. zor, -e"
  },
  {
    "x": "zuwon (n)",
    "y": " b. ziwan (n) "
  },
  {
    "x": "zuwûn (n)",
    "y": " b. ziwan (n) "
  },
  {
    "x": "zux (n)",
    "y": " sümük"
  },
  {
    "x": "zuya, -e",
    "y": " b. ziwa, -ye"
  },
  {
    "x": "zuya bîyayêne",
    "y": " b. ziwa bîyene"
  },
  {
    "x": "zuya bîyene",
    "y": " b. ziwa bîyene"
  },
  {
    "x": "zuya kerdene",
    "y": " b. ziwa kerdene"
  },
  {
    "x": "zuyabîyayîş (n)",
    "y": " b. ziwabîyayîş (n)"
  },
  {
    "x": "zuyakerdiş (n)",
    "y": " b. ziwakerdiş (n)"
  },
  {
    "x": "zuyayîye (m)",
    "y": " b. ziwayîye (m), ziwayî (m)"
  },
  {
    "x": "zuyî (m)",
    "y": " b. yewî-I (m)"
  },
  {
    "x": "zuzaq (n)",
    "y": " b. ziqaq (n)"
  },
  {
    "x": "zuze (n)",
    "y": " b. jûje (n), dije (n)"
  },
  {
    "x": "zû-I (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "zû-II (n)",
    "y": " b. jan-I (n)"
  },
  {
    "x": "zû, -ye-III",
    "y": " b. yew, -e"
  },
  {
    "x": "zû bîyayene",
    "y": " b. yew bîyene "
  },
  {
    "x": "zû bîyayêne",
    "y": " b. yew bîyene"
  },
  {
    "x": "zû cinî",
    "y": " bir kadın"
  },
  {
    "x": "zû ve zû",
    "y": " b. yew bi yew"
  },
  {
    "x": "zûbin",
    "y": " b. yewbînî"
  },
  {
    "x": "zûbinî",
    "y": " b. yewbînî"
  },
  {
    "x": "zûbîn",
    "y": " b. yewbînî"
  },
  {
    "x": "zûbîna",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "zûbînî",
    "y": " b. yewbînî, yewbînan"
  },
  {
    "x": "zûbînî kotene",
    "y": " b. yewbînî kewtene"
  },
  {
    "x": "zûbînîkotiş (n)",
    "y": " b. yewbînîkewtiş (n)"
  },
  {
    "x": "zûbîyaye (n)",
    "y": " b. yewbîyaye (n)"
  },
  {
    "x": "zûbîyayîş (n)",
    "y": " b. yewbîyayîş (n)"
  },
  {
    "x": "zûbîyayîye (m)",
    "y": " b. yewbîyayîye (m)"
  },
  {
    "x": "zûfek (n)",
    "y": " b. yewfek (n)"
  },
  {
    "x": "zûfek veng kerdene",
    "y": " b. yewfek veng kerdene"
  },
  {
    "x": "zûma (n)",
    "y": " b. zama (n)"
  },
  {
    "x": "zûmin",
    "y": " b. yewbînî"
  },
  {
    "x": "zûminî",
    "y": " b. yewbînî"
  },
  {
    "x": "zûmistan (n)",
    "y": " b. zimistan (n)"
  },
  {
    "x": "zûmistanî",
    "y": " b. zimistanî-I"
  },
  {
    "x": "zûmîn",
    "y": " b. yewbînî"
  },
  {
    "x": "zûmînî",
    "y": " b. yewbînî"
  },
  {
    "x": "zûmq (n)",
    "y": " b. zamqe (m)"
  },
  {
    "x": "zûn-I (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "zûn dadî (n)",
    "y": " b. ziwanê dayîke (n)"
  },
  {
    "x": "zûn degunayene",
    "y": " b. ziwan deginayene"
  },
  {
    "x": "zûnî dadî (n)",
    "y": " b. ziwanê dayîke (n)"
  },
  {
    "x": "zûno qît (n)",
    "y": " b. ziwano qît (n)"
  },
  {
    "x": "zûn-II (n)",
    "y": " b. jan-I (n)"
  },
  {
    "x": "zûn dayene",
    "y": " b. jan dayene"
  },
  {
    "x": "zûna",
    "y": " b. yewna-I"
  },
  {
    "x": "zûnaya (m)",
    "y": " b. zanaya (m), zanayîye (m)"
  },
  {
    "x": "zûnaye (n)",
    "y": " b. zanaye (n)"
  },
  {
    "x": "zûnayene",
    "y": " b. zanayene"
  },
  {
    "x": "zûnayêne",
    "y": " b. zanayene"
  },
  {
    "x": "zûnayîş (n)",
    "y": " b. zanayîş (n)"
  },
  {
    "x": "zûnayîye (m)",
    "y": " b. zanayîye (m)"
  },
  {
    "x": "zûndegunayîş (n)",
    "y": " b. ziwandeginayîş (n)"
  },
  {
    "x": "zûneyîş (n)",
    "y": " b. zanayîş (n)"
  },
  {
    "x": "zûngiron, -i",
    "y": " b. ziwangiran, -e"
  },
  {
    "x": "zûngironey (m)",
    "y": " b. ziwangiranî (m)"
  },
  {
    "x": "zûngirûn (n)",
    "y": " b. ziwangiran, -e"
  },
  {
    "x": "zûngirûni (m)",
    "y": " b. ziwangirane (m)"
  },
  {
    "x": "zûngirûnê (m)",
    "y": " b. ziwangiranî (m)"
  },
  {
    "x": "zûni (n)",
    "y": " b. zanî (n)"
  },
  {
    "x": "zûnî (n)",
    "y": " b. zanî (n)"
  },
  {
    "x": "zûnîyûnseroroniştiş (n)",
    "y": " b. zanîyanseroroniştiş (n) "
  },
  {
    "x": "zûqlik (m)",
    "y": " b. zuqlike (m)"
  },
  {
    "x": "zûqliki (m)",
    "y": " b. zuqlike (m)"
  },
  {
    "x": "zûqliq (m)",
    "y": " b. zuqlike (m)"
  },
  {
    "x": "zûqliqi (m)",
    "y": " b. zuqlike (m)"
  },
  {
    "x": "zûqlorik (m)",
    "y": " b. zuqlike (m)"
  },
  {
    "x": "zûqloriki (m)",
    "y": " b. zuqlike (m)"
  },
  {
    "x": "zûr (m)",
    "y": " b. zure (m)"
  },
  {
    "x": "zûr, -i",
    "y": " b. zor, -e"
  },
  {
    "x": "zûra (n)",
    "y": " b. zura (n)"
  },
  {
    "x": "zûrayene",
    "y": " 1)ulumak\r\n~2)ağlamak (argo)"
  },
  {
    "x": "zûrayîş (n)",
    "y": " 1)uluma\r\n~2)ağlama (argo)"
  },
  {
    "x": "zûre (m)",
    "y": " b. zure (m)"
  },
  {
    "x": "zûra boçini (m)",
    "y": " b. zura boçine (m)"
  },
  {
    "x": "zûreker, -e",
    "y": " b. zureker, -e"
  },
  {
    "x": "zûri (m)",
    "y": " b. zure (m)"
  },
  {
    "x": "zûrî kerdene",
    "y": " b. zurî kerdene"
  },
  {
    "x": "zûrîkerdiş (n)",
    "y": " b. zurîkerdiş (n)"
  },
  {
    "x": "zûrîya (n)",
    "y": " b. zura (n)"
  },
  {
    "x": "zûrker (n)",
    "y": " b. zureker, -e"
  },
  {
    "x": "zûrna (m)",
    "y": " b. zirna"
  },
  {
    "x": "zûtik (n)",
    "y": " b. zutike"
  },
  {
    "x": "zûtîkê sibayî (n)",
    "y": " sabahın en erken vakti"
  },
  {
    "x": "zûvinî",
    "y": " b. yewbînî"
  },
  {
    "x": "zûvîn",
    "y": " b. yewbînî"
  },
  {
    "x": "zûvînî",
    "y": " b. yewbînî"
  },
  {
    "x": "zûwen (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "zûwn (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "zûwn qît (n)",
    "y": " b. ziwano qît (n)"
  },
  {
    "x": "zûye (m)",
    "y": " b. yewe (m)"
  },
  {
    "x": "zûyî (m)",
    "y": " b. yewî-I (m)"
  },
  {
    "x": "zûyin, -e",
    "y": " b. yewin, -e"
  },
  {
    "x": "zûyîye (m)",
    "y": " b. yewîye (m), yewî (m)"
  },
  {
    "x": "zûzû (n)",
    "y": " b. zozan (n)"
  },
  {
    "x": "zûzûn (n)",
    "y": " b. zozan (n)"
  },
  {
    "x": "zwan (n)",
    "y": " b. ziwan (n)"
  },
  {
    "x": "zwar",
    "y": " b. zor, -e"
  }
];
  export default kt;
