import { BrowserRouter, Routes, Route } from "react-router-dom";
//import Policy from "./Policy";
import Web from "./Web";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Web />} />
        <Route path="/policy" element={<Web />} />
        <Route path="/gramer" element={<Web />} />
      </Routes>
    </BrowserRouter>
  );
}
