export const policy = `<div style="font-size: 20px;" id="yazi" class="yazi">
                    <strong>Gizlilik Politikası</strong><br>
<strong>Yürürlük Tarihi:</strong> 09/17/2024<br>
<strong>1. Giriş</strong><br>
Zazano uygulamasına hoş geldiniz. Gizliliğinize önem veriyoruz. Bu Gizlilik Politikası, mobil uygulamamız Zazano aracılığıyla topladığımız, kullandığımız, açıkladığımız ve koruduğumuz bilgiler hakkında sizi bilgilendirir. Lütfen bu politikayı dikkatlice okuyun. Bu Gizlilik Politikasının şartlarını kabul etmiyorsanız, lütfen Uygulamayı kullanmayın.<br>
<strong>2. Topladığımız Bilgiler</strong><br>
Şu tür bilgileri toplayabiliriz:
<ul>
	<li><strong>Kişisel Bilgiler:</strong> Uygulama üzerinden kaydolduğunuzda veya kullandığınızda, adınız, e-posta adresiniz ve telefon numaranız gibi kişisel bilgileri toplayabiliriz.</li>
	<li><strong>Kullanım Verileri:</strong> Uygulamayı nasıl kullandığınız hakkında bilgiler toplarız; etkileşimleriniz, kullandığınız özellikler ve görüntülediğiniz içerikler dahil.</li>
	<li><strong>Cihaz Bilgileri:</strong> Cihazınız hakkında, cihaz kimliği, işletim sistemi ve model gibi bilgiler toplayabiliriz.</li>
	<li><strong>Konum Verileri:</strong> İzin vermeniz durumunda, cihazınızdan konum verilerini toplayabiliriz.</li>
	<li><strong>Çerezler ve İzleme Teknolojileri:</strong> Aktivitenizi takip etmek ve Uygulamayı geliştirmek için çerezler ve benzeri teknolojiler kullanıyoruz.</li>
</ul>
<strong>3. Bilgilerinizi Nasıl Kullanıyoruz</strong><br>
Bilgilerinizi aşağıdaki amaçlarla kullanabiliriz:
<ul>
	<li>Uygulamayı sağlamak, işletmek ve sürdürmek.</li>
	<li>Uygulamayı geliştirmek, kişiselleştirmek ve genişletmek.</li>
	<li>Uygulamanızı nasıl kullandığınızı anlamak ve analiz etmek.</li>
	<li>Size iletişim kurmak, güncellemeler, bültenler ve promosyon materyalleri göndermek.</li>
	<li>İşlemleri işlemek ve hesabınızı yönetmek.</li>
	<li>Yasal yükümlülükleri yerine getirmek ve haklarımızı korumak.</li>
</ul>
<strong>4. Bilgilerinizi Kimlerle Paylaşıyoruz</strong><br>
Bilgilerinizi aşağıdaki durumlarda paylaşabiliriz:
<ul>
	<li><strong>Hizmet Sağlayıcılarıyla:</strong> Uygulamayı işletmemize yardımcı olan üçüncü taraf hizmet sağlayıcılarıyla (örneğin analiz sağlayıcıları, ödeme işlemcileri ve barındırma hizmetleri) bilgilerinizi paylaşabiliriz.</li>
	<li><strong>Yasal Nedenlerle:</strong> Yasal gereklilikler doğrultusunda veya kamu yetkililerinin geçerli taleplerine yanıt olarak bilgilerinizi açıklayabiliriz.</li>
	<li><strong>İş Transferleri:</strong> Bir birleşme, satın alma veya varlık satışında, bilgilerinizi işlemin bir parçası olarak aktarabiliriz.</li>
</ul>
<strong>5. Güvenlik</strong><br>
Bilgilerinizi yetkisiz erişim, kullanım veya açıklamadan korumak için makul önlemler alıyoruz. Ancak, internet üzerinden iletim veya elektronik depolama yöntemlerinin tamamen güvenli olmadığını ve mutlak güvenliği garanti edemeyeceğimizi unutmamalısınız.<br>
<strong>6. Seçimleriniz</strong><br>
Bilgilerinizin bazı yönlerini kontrol edebilirsiniz:
<ul>
	<li><strong>Erişim ve Güncelleme:</strong> Kişisel bilgilerinize uygulamanın ayarları üzerinden erişebilir ve güncelleyebilirsiniz.</li>
	<li><strong>Opt-Out:</strong> Size gönderilen promosyon iletişimlerinden çıkabilirsiniz; bu tür iletilerdeki talimatları takip ederek bunu yapabilirsiniz.</li>
	<li><strong>Konum Verileri:</strong> Konum verileri ayarlarını cihazınızın ayarlarından yönetebilirsiniz.</li>
</ul>
<strong>7. Çocukların Gizliliği</strong><br>
Uygulamamız 13 yaş altı çocuklar için tasarlanmamıştır. 13 yaş altı çocuklardan kişisel bilgi toplamadığımızı bilmenizi isteriz. Bu tür bilgilerin toplandığını fark edersek, bu bilgileri silmek için gerekli adımları atacağız.<br>
<strong>8. Bu Gizlilik Politikasındaki Değişiklikler</strong><br>
Bu Gizlilik Politikasını zaman zaman güncelleyebiliriz. Herhangi bir önemli değişiklik yaptığımızda, yeni Gizlilik Politikasını Uygulamada veya diğer yollarla bildireceğiz. Politikanın güncellenmiş hali, Uygulamanın devam eden kullanımıyla kabul edilmiş sayılacaktır.<br>
<strong>9. Bizimle İletişime Geçin</strong><br>
Bu Gizlilik Politikası veya veri uygulamalarımız hakkında sorularınız veya endişeleriniz varsa, bizimle şu şekilde iletişime geçebilirsiniz:<br>
Zazano<br>
Mehmet Akif Ersoy Sitesi Blok: 5, Yenimahalle / ANKARA<br>
zazanorg@gmail.com<br>
+905466109057<br>
&nbsp;<br>
 </div>`


export const menu = [
  "FERHENGEK (LÜGATÇE)",
  "SEBA ÇAPA HÎRÊYINE VATEYO VERÊN",
  "1.HERF Û VENGÎ",
  "1.1.ALFABE",
  "1.2.KILMNUŞTEYÎ (KISALTMALAR)",
  "1.3.VURÎYAYÎŞÊ (BEDILÎYAYÎŞÊ) VENGAN",
  "1.3.1.-an",
  "1.3.2.-am",
  "1.3.3.Vengê “J”, “C”, “Z”yî",
  "1.3.4.Vengê “K” û “Ç”yî",
  "1.3.5.Vengê “O” û “WE”yî",
  "1.4.HERFA PÊRAGIRÊDAYENE",
  "2.NAME",
  "2.1.Nameyê Rojan",
  "2.2.Nameyê Mewsiman",
  "2.3.Nameyê Mengan (Aşman)",
  "2.4.Nameyê Hetan (Cîhetan)",
  "2.5.Nameyê Qitayan (Kontînentan)",
  "2.6.Nameyê Ziwanan û Lehçeyan",
  "2.7.Nameyê Dîn, Mezheb û Terîqetan",
  "2.8.Nameyê Mensûbanê Miletan",
  "2.9.Nameyê Mensûbanê Dewan, Bajaran (Şaristanan), Mintiqayan, Dewletan û Qitayan",
  "2.10.Nameyê Kesan yê Xerîban",
  "2.11.Tayê Nameyê ke bi Herfa Girde Dest Pêkenê",
  "2.12.Nameyê Kitaban, Nuşteyan (Yazîyan) û Notanê Bîblîyografîkan",
  "2.13.Nuştişê Tarîxî yê bi Roje, Menge (Aşme) û Serre",
  "2.14.Sifetî ra Nameviraştiş",
  "2.15.Çekuyê Anteyî (Bükünlü Sözcükler)",
  "2.16.Nêancîyayîşê Çekuya Makî ya ke bi “-e” Yan zî “-î” Qedîyena",
  "2.17.Name û Sifetê Makî",
  "2.17.1.Nameyê Makî yê ke bi “-e” (“-i”) Qedîyenê û Kopula",
  "2.18.Teql û Manaya Çekuyan",
  "2.19.Bi “-î” Zafhûmarbîyayîşê Nameyan",
  "2.20.Cumle de Nuştişê Name yan zî Sifetê Zafhûmarî û Kopula",
  "2.21.Îzafe (Tamlama)",
  "2.22.Nameyê Tayê Bajaran (Şaristanan) û Qezayanê Kurdan",
  "2.23.Nameyê Welatan, Dewletan û Paytextan",
  "2.23.1.Afrîka",
  "2.23.2.Amerîka",
  "2.23.3.Asya",
  "2.23.4.Ewropa",
  "2.23.5.Okyanûsya",
  "3.ZEMÎR (ADIL, ZAMİR)",
  "3.1.Zemîrê Kesî (Zemîrê Şexsî)",
  "3.1.1.Grûba Yewine",
  "3.1.2.Grûba Dîyine",
  "3.1.3.Nusîyayîşê Çekuya “Xo”yî",
  "3.2.Zemîrê Nîşanî (Zemîrê Îşaretî)",
  "3.2.1.Semedê Nêzdî",
  "3.2.2.Semedê Dûrî",
  "3.2.3.Rastnuştişê Zemîranê Nîşanî “Ney” û “Ey”",
  "3.4. Zemîrê Wayîrîye (İyelik Zamiri)",
  "4.SIFET (SIFAT)",
  "4.1.Tayê Rengî",
  "4.2.Sifetê Nîşanî",
  "4.2.1.Semedê Nêzdî",
  "4.2.2.Semedê Dûrî",
  "4.3.Hûmarnameyî",
  "4.3.1.Hûmarnameyê Rêzkî (Sıra Sayılar)",
  "4.4.Nuştişê Partîsîpê Makî û Zafhûmarî",
  "4.5.Nuştişê Çekuya “Her” û “Heme”yî",
  "5.EDAT (İLGEÇ, EDAT)",
  "5.1.Veredat (Önedat)",
  "5.1.1.bi",
  "5.1.2.bê",
  "5.1.3.se, sey",
  "5.1.4.pê",
  "5.1.5.ver bi",
  "5.2.Peyedat (Artedat)",
  "5.2.1.rê",
  "5.2.2.r’",
  "5.2.3.de",
  "5.2.4.der",
  "5.2.5.ra",
  "5.2.6.ro",
  "5.2.7.ya",
  "5.2.8.a",
  "5.3.Veredat û Peyedat Pîya (Önedat ve Artedat Birlikte)",
  "5.3.1.bi ... ra",
  "5.3.2.di ... de",
  "5.3.3.ede ... de",
  "5.3.4.a ... ra",
  "5.3.5.beyntarê ... de",
  "5.3.6.bi ... ya/bi ... a",
  "5.3.7.bi ... bê/ebi ... bê",
  "5.3.8.peyê ... ra",
  "6.BESTOXÎ (BAĞLAÇLAR)",
  "6.1.Çend Bestoxî",
  "6.1.1.eke",
  "6.1.2.ke",
  "6.1.2.1.“Ke” û Vîrgul",
  "6.1.3.Nuştişê Tayê Bestoxanê ke Di-hîrê Çekuyan ra Virazîyayê",
  "7.KAR (FÎÎL)",
  "7.1.Mesderê Karî (Mesderê Fîîlî) û Nameyê Mesderî",
  "7.1.1.Mesderê Karî bi Peygirê “-ene”/“-yene” û “-îyayene”",
  "7.2.Nameyê Mesderî bi Peygirê “-iş”/“-yîş” û “-îyayîş”î",
  "7.3.Karê Pêrabesteyî (Bileşik Fiil) de",
  "7.4.Kopula (Karo Nêmcet) 7.4.1.Nusîyayîşê Cinsîyetê Kopula",
  "7.5.Nusîyayîşê Çekuya “Çin” û Karî (Fîîlî)",
  "7.6.Nusîyayîşê Çekuya “Est” û Karî (Fîîlî)",
  "7.7.Karê “Bîyene”/“Bîyayîş”î",
  "7.7.1.“B” yan “V”?",
  "7.8.Karo Pêrabeste (Bileşik Fiil)",
  "7.8.1.Preverbo Yewheceyin + Kar",
  "7.8.2.Preverbo Zafheceyin + Kar",
  "7.8.3.Name + Kar",
  "7.8.4.Sifet + Kar",
  "7.8.5.Kar + Kar",
  "7.9.Kesê Zafhûmarê Yewinî yê Karê Întransîtîfî de: -(y)îme",
  "7.10.Karan de Negatîfîye (Fillerde Olumsuzluk)",
  "7.11.Demo Nikayin",
  "7.11.1.Demo nikayin-I",
  "7.11.2.Demo nikayin-II (Bi Partîkel)",
  "7.12.Partîkelê Demê Ameyoxî yê Karî (Fiilin Gelecek Zaman Partikeli)",
  "7.13.Nuştişê Karan û Vîrgul",
  "8.ÇEND PEYGIRÎ (BİRKAÇ SONEK)",
  "8.1.-ên",
  "8.2.-in",
  "9.RASTNUŞTIŞÊ TAYÊ ÇEKUYAN (BAZI SÖZCÜKLERİN DOĞRU YAZIMI)",
  "9.1.zî, kî",
  "9.2.Çekuyê Tekrarbîyayeyî",
  "9.3.Çekuyê Diletî",
  "9.4.Rastnuştişê Tayêna Çekuyan",
  "***",
];

export const body = `
<h1>FERHENGEK (LÜGATÇE)</h1><br>
<strong>alfabe</strong>: alfabe<br>
<strong>anta </strong>(m): bükünlü, çekilmiş olan<br>
<strong>ante </strong>(n): bükünlü, çekilmiş olan<br>
<strong>halo ante</strong>: bükünlü durum<br>
<strong>antiş</strong>: çekim, çekme<br>
<strong>antişê karî</strong>: fiil çekimi <strong>antîye </strong>(m): bükünlü, çekilmiş olan <strong>apostrof</strong>: apostrof<br>
<strong>bajar</strong>: il, vilayet, kent, şehir <strong>bedilîyayîş</strong>: değişme <strong>bedilnayîş</strong>: değiştirme <strong>bestox</strong>: bağlaç<br>
<strong>beyntar</strong>: ara<br>
<strong>bêveng</strong>, -e: sessiz, ünsüz<br>
<strong>herfa bêvenge</strong>: sessiz harf<br>
<strong>cîhet</strong>: yön, cihet<br>
<strong>cîld</strong>: cilt<br>
<strong>çekuye</strong>: sözcük, kelime<br>
<strong>çekuya anta/çekuya antîye</strong>: bükünlü sözcük<br>
<strong>dem</strong>: zaman<br>
<strong>demo ameyox</strong>: gelecek zaman<br>
<strong>demo nikayin</strong>: şimdiki zaman<br>
<strong>demo vîyarteyo dîyar</strong>: -di’li geçmiş zaman<br>
<strong>dilet</strong>: ikiz<br>
<strong>dimbilî</strong>: b. <strong>dimilî</strong><br>
<strong>dimilî</strong>: 1-Kırmanc, Zaza 2-Kırmancca, Zazaca<br>
<strong>dimilkî</strong>: Kırmancca, Zazaca<br>
<strong>dimlî</strong>: b. <strong>dimilî dîyar</strong>, -e: belirtili<br>
<strong>îzafeya nameyî ya dîyare</strong>: belirtili isim tamlaması<br>
<strong>edat</strong>: ilgeç, edat<br>
<strong>eser</strong>: eser<br>
<strong>fek</strong>: ağız (konuşma dili) <strong>gurenayîş</strong>: çalıştırma, kullanma <strong>hemmana</strong>: eş anlamlı <strong>hemveng</strong>: eş sesli<br>
<strong>herfe</strong>: harf<br>
<strong>herfa bêvenge</strong>: sessiz harf <strong>herfa girde</strong>: büyük harf <strong>herfa hurdîye</strong>: küçük harf <strong>herfa vengine</strong>: sesli harf<br>
<strong>herfa pêragirêdayene</strong>: kaynaştırma harfi<br>
<strong>het</strong>: taraf, yön<br>
<strong>hûmarname</strong>: sayı sözcüğü<br>
<strong>hûmarnameyê bingeyî</strong>: asıl sayılar<br>
<strong>hûmarnameyê rêzkî</strong>: sıra sayılar<br>
<strong>îşaret</strong>: im, işaret<br>
<strong>izafe</strong>: tamlama<br>
<strong>îzafeya nameyî</strong>: ad tamlaması, isim tamlaması<br>
<strong>îzafeya rêzilkî</strong>: zincirleme tamlama<br>
<strong>kar</strong>: fiil, eylem<br>
<strong>karo hetkar</strong>: yardımcı fiil <strong>karo întransîtîf</strong>: geçişsiz fiil <strong>karo nêmcet</strong>: b. <strong>kopula karo pêrabeste</strong>: bileşik fiil <strong>karo refleksîf</strong>: dönüşlü fiil <strong>karo transîtîf</strong>: geçişli fiil<br>
<strong>kerdox</strong>, -e: 1-yapan 2-özne<br>
<strong>kes</strong>: şahıs <strong>kilmnuşte</strong>: kısaltma <strong>kird</strong>: Kırmanc, Zaza <strong>kirdas</strong>: Kurmanc<br>
<strong>kirdaskî</strong>: Kurmancca <strong>kirdkî</strong>: Kırmancca, Zazaca <strong>kirmanc</strong>: Kırmanc, Zaza<br>
<strong>kirmanckî</strong>: Kırmancca, Zazaca<br>
<strong>kombîyayîş</strong>: toplantı <strong>kopula</strong>: ek fiil <strong>kurmanckî</strong>: Kurmancca<br>
<strong>kurdkîya mîyanêne</strong>: Orta Kürtçe, Soranca<br>
<strong>lehçe</strong>: lehçe <strong>mabên</strong>: ara <strong>makî</strong>: dişil<br>
<strong>mavajî</strong>: örneğin, mesela <strong>mavajîme</strong>: örneğin, mesela <strong>mesder</strong>: mastar<br>
<strong>name</strong>: ad, isim<br>
<strong>nameyo taybetî</strong>: özel ad, özel isim<br>
<strong>nameyo xoser</strong>: yalın ad, yalın isim<br>
<strong>nedîyar</strong>, -e: belirtisiz, belgisiz<br>
<strong>îzafeya nameyî ya nedîyare</strong>: belirtisiz isim tamlaması<br>
<strong>sifetê nedîyarîye </strong>(n): belgisiz sıfat<br>
<strong>zemîrê nedîyarîye </strong>(n): belgisiz zamir<br>
<strong>negatîf</strong>: olumsuz <strong>negatîfîye</strong>: olumsuzluk <strong>nêrî</strong>: eril<br>
<strong>nimûne</strong>: örnek, misal<br>
<strong>nîşan</strong>: im, işaret<br>
<strong>nîşanê tîre</strong>: tire işareti<br>
<strong>numre</strong>: numara <strong>nuşte</strong>: yazı <strong>nuştox</strong>, -e: yazar <strong>obje</strong>: nesne <strong>oblîk</strong>, -e: bükünlü<br>
<strong>halo oblîk</strong>: bükünlü durum<br>
<strong>partîkel</strong>: partikel (bi franskî “particule”, bi îngilizkî “particle”)<br>
<strong>peyedat</strong>: artedat (postposition)<br>
<strong>peygir</strong>: sonek <strong>preverb</strong>: preverb <strong>qita</strong>: kıta<br>
<strong>rast</strong>, -e / <strong>raşt</strong>, -e: doğru <strong>rastnuştış</strong>: yazım, imlâ <strong>rewşe</strong>: durum<br>
<strong>rêze</strong>: sıra, satır<br>
<strong>Rojhelato Mîyanên</strong>: Ortadoğu<br>
<strong>sifet</strong>: sıfat<br>
<strong>şaristan</strong>: il, vilayet, kent, şehir<br>
<strong>şaş</strong>, -e: b. <strong>xelet</strong>, -e<br>
<strong>temambîyayîye/temambîyaya</strong>: muzaf, tamlanan (belirtilen)<br>
<strong>tîre</strong>: tire (tire işareti)<br>
<strong>veng</strong>: ses<br>
<strong>vengin</strong>, -e: sesli, ünlü <strong>veredat</strong>: önedat (préposition) <strong>vergir</strong>: önek<br>
<strong>vîrgul</strong>: virgül <strong>vurîyayîş</strong>: değişme <strong>vurnayene</strong>: değiştirmek <strong>vurnayîş</strong>: değiştirme<br>
<strong>xebitnayîş</strong>: çalıştırma, kullanma<br>
<strong>xelet</strong>, -e: yanlış<br>
<strong>xeletîye</strong>: yanlışlık<br>
<strong>xoser</strong>: bağımsız, kendi başına<br>
<strong>halo xoser</strong>: yalın durum<br>
<strong>nameyo xoser</strong>: yalın ad, yalın isim<br>
<strong>yewheceyin</strong>, -e: tek heceli <strong>yewhûmar</strong>: tekil <strong>zafhûmar</strong>: çoğul <strong>zafheceyin</strong>, -e: çok heceli <strong>zaza</strong>: Kırmanc, Zaza <strong>zazakî</strong>: Kırmancca, Zazaca <strong>zemîr</strong>: adıl, zamir<br>
<strong>zemîrê kesî</strong>: şahıs zamiri<br>
<strong>zemîrê nîşanî</strong>: işaret zamiri<br>
<strong>zerfe</strong>: belirteç, zarf<br>
<strong>ziwan</strong>: dil<br>
<strong>ziwano nuştekî</strong>: yazılı dil

<h1>SEBA ÇAPA HÎRÊYINE VATEYO VERÊN</h1><br>
Kirmanckî lehçeyanê kurdkî ra yew a. Goreyê mintiqa, na lehçe ra kirdkî, zazakî û dimilkî/dimilî/dimbilî zî vajîyeno. Seba ke ziwanê nuştekî yê kirmanckî aver şêro, ganî seba nuştişê ci yew alfabe û qaydeyê rastnuştişî yê muşterekî est bê. Ganî ma xeletîyan û rastîyan cêmîyan ra vejê û giranîye bidê hetanê muşterekanê kirmanckî ser.<br>
Grûba Xebate ya Vateyî, serra 1996 ra nat standardîzekerdiş û rastnuştişê kirmanckî ser o xebatêka muştereke kerde. Hetanî ewro derheqê kirmanckî de hîris û panc kombîyayîşî (toplantı) viraştî û hewtay û çar hûmarî kovara Vateyî neşr kerdî. Nê kombîyayîşan de, grûbe hetêk ra term û çekuyan (terim ve sözcükler) hetêk ra zî qaydeyanê rastnuştişî yê kirmanckî ser o vindete. Term û çekuyê ke nê kombîyayîşan de tesbît bîyê, nê di ferhengan de neşr bîy; kesê ke wazenê term û çekuyanê kirmanckî ra hîna hol xeberdar bibê, şênê (bese kenê) nê ferhengan ra îstîfade bikê:<br>
<em>-Ferhengê Tirkî-Kirmanckî (Zazakî) / Türkçe-Kırmancca (Zazaca) Sözlük</em><br>
<em>-Ferhengê Kirmanckî (Zazakî)-Tirkî / Kırmancca (Zazaca)-Türkçe Sözlük</em><br>
&nbsp;<br>
Netîceya na xebate de, reya verêne kirmancan bi xo rayberê (rêberê) rastnuştişî yê kirmanckî zî amade kerd û serra 2005î de sey kitabêk neşr kerd. 2012 de eynî rayber reya dîyine neşr bi. Çapa (baskı) ke destê şima de, çapa hîrêyin a. Na çape de tayê qaydeyê rastnuştişî îlawe bîy û tayê çekuyî goreyê ferhengê Vateyî amey vurnayene.<br>
Yew xususîyetê nê rayberî zî no yo ke tena goreyê yew fekê (ağız) kirmanckî amade nêbîyo, qaydeyê ci bi muqayesekerdişê fekanê mintiqayanê cîya-cîyayan tesbît bîyê.<br>
Nê kesî Kombîyayîşanê Kirmanckî de amade bîy. (Reqemê mîyanê parantezan musnenê/nawnenê ke her kes çend kombîyayîşan de amade bîyo.)<br>
<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:253px">Tekîn Agacik (Erzingan) (4)</td>
<td style="height:17px; width:208px">Doxan Karasu (Çewlîg) (17)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Nadîre Guntaş Aldatmaz (Dêrsim) (10)</td>
<td style="height:17px; width:208px">Wisif Kaymak (Pîran) (11)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Netîce Altun (Pasûr) (13)</td>
<td style="height:17px; width:208px">Elîf Kiliç (Dêrsim) (2)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Şukrî Atîk (Gimgim) (1)</td>
<td style="height:17px; width:208px">Ehmed Kirkan (Sêwregi) (9)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Polat Aydin (Çêrmûge) (16)</td>
<td style="height:17px; width:208px">Çeko Kocadag (Gimgim) (9)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Saît Aydogmuş (Pîran) (1)</td>
<td style="height:17px; width:208px">Huseyîn Kulu (Dêrsim) (22)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Osman Aytar (Sêwregi) (2)</td>
<td style="height:17px; width:208px">Seyîdxan Kurij (Çewlîg) (27)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Serdar Bedirxan (Pîran) (18)</td>
<td style="height:17px; width:208px">Roşan Lezgîn (Licê) (9)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Yildiray Beyazgul (Gimgim) (6)</td>
<td style="height:17px; width:208px">M. Malmîsanij (Pîran) (35)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Aydin Bîngol (Gimgim) (16)</td>
<td style="height:17px; width:208px">Selîm Mûrat (Pali) (35)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Îbrahîm Bîngol (Gimgim) (12)</td>
<td style="height:17px; width:208px">Mehmud Nêşite (Licê) (18)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Nureddîn Celalî (Çewlîg) (17)</td>
<td style="height:17px; width:208px">Mehmet Olmez (Gêl) (1)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Îrfan Çelîk (Pasûr) (1)</td>
<td style="height:17px; width:208px">Hamdî Ozyurt (Gimgim) (4)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Munzur Çem (Dêrsim) (26)</td>
<td style="height:17px; width:208px">Cemal Pîranij (Pîran) (11)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Hûmanê Çîyan (Depe) (2)</td>
<td style="height:17px; width:208px">Wisif Pîrbav (Dêrsim) (1)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Memo Darêz (Bongilan) (3)</td>
<td style="height:17px; width:208px">Robîn Rewşen (Licê) (3)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Zulkuf Demirtaş (Pali) (12)</td>
<td style="height:17px; width:208px">Ugur Sermîyan (Dêrsim) (5)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Haydar Diljen (Sêwregi) (29)</td>
<td style="height:17px; width:208px">Kamer Soylemez (Dêrsim) (4)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Ehmedê Dirihî (Çewlîg) (11)</td>
<td style="height:17px; width:208px">Mehmet Taş (Dêrsim) (16)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Nîhat Elî (Sêwregi) (3)</td>
<td style="height:17px; width:208px">Kazim Temurlenk (Dêrsim) (2)</td>
</tr>
<tr>
<td style="height:17px; width:253px">J. Îhsan Espar (Pîran) (34)</td>
<td style="height:17px; width:208px">Îshak Tepe (Modan/Motkan) (3)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Nevzat Gedîk (Gimgim) (1)</td>
<td style="height:17px; width:208px">Harun Turgut (Pali) (3)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Huseyîn Girmit (Erzingan) (1)</td>
<td style="height:17px; width:208px">Şukrî Urgun (Hêni) (17)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Cemîl Gundogan (Dêrsim) (3)</td>
<td style="height:17px; width:208px">Muzafer Xeylanij (Pali) (14)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Deniz Gunduz (Gimgim) (16)</td>
<td style="height:17px; width:208px">Pinar Yildiz (Licê) (6)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Neymetullah Gunduz (Gêl) (2)</td>
<td style="height:17px; width:208px">Weysel Yıldızhan (Çêrmûge) (4)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Ronya Îlbasmiş (Sêwregi) (3)</td>
<td style="height:17px; width:208px">Suleyman Yilmaz (Pîran) (4)</td>
</tr>
<tr>
<td style="height:17px; width:253px">Lerzan Jandîl (Gimgim) (6)</td>
<td style="height:17px; width:208px">Orhan Zoxpayij (Çewlîg) (1)</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;<br>
Seke aseno, kesê ke kombîyayîşan de amade bîy nê qeza û bajaran ra yê: Bongilan, Çewlîg, Çêrmûge, Depe, Dêrsim, Erzingan, Gêl, Gimgim, Hêni, Licê, Modan/Motkan, Pali, Pasûr, Pîran, Sêwregi.<br>
Hêvîya ma a ya ke kovara Vateyî û di ferhenganê Grûba Xebate ya Vateyî ra pey, no rayberê rastnuştişî zî standardbîyayîşê kirmanckî rê xizmet biko û nuştişê ziwanê ma hîna asan biko.<br>
&nbsp;
<h1>Grûba Xebate ya Vateyî</h1><br>

<h1>1.HERF Û VENGÎ</h1><br>
Kurdê ke bi lehçeya ma qisey kenê ca ra ca xo ra vanê “kirmanc”, “kird” , “zaza” yan zî “dimilî”/“dimlî”/“dimbilî”; lehçeya xo ra vanê “kirmanckî”, “kirdkî”, “zazakî” yan zî “dimilkî”/“dimilî”/“dimlî”/“dimbilî”.<br>
Meylê kombîyayîşê ma nê çekuyan ra “kirmanc” û “kirmanckî” ser o yo. Nê kitabî de zî ma çekuya “kirmanc”î manaya “zaza” de, çekuya “kirmanckî” manaya “zazakî” de xebitnenê.<br>
&nbsp;
<h1>1.1.ALFABE</h1><br>
Alfabeya kirmanckî de nê 31 herfî est ê:<br>
A B C Ç D E Ê F G H I Î J K L M N O P Q R S Ş T U Û V W X Y Z<br>
a b c ç d e ê f g h i î j k l m n o p q r s ş t u û v w x y z<br>
<br>
Wendişê herfanê alfabe wina yo:<br>
a, be, ce, çe, de, e, ê, fe, ge, he, i, î, je, ke, le, me, ne, o, pe, qe, re, se, şe, te, u, û, ve, we, xe, ye, ze.<br>
<br>
Tayê nuştoxî nuşteyanê xo de herfanê sey “Ü” û “Ğ” nusenê yan zî cayê herfa <span dir="RTL">ع</span> (eyn) ya erebkî de işaretê apostrofî (’) ronanê. Seba ke alfabeya ma de çin ê, ganî nê herfî û îşaretî nênusîyê. Mavajî, ganî cayê “ğerîb”î de “xerîb”, cayê “Hezretî ’Elî” de “Hezretî Elî” binusîyo.<br>
&nbsp;
<h1>Nameyê Herfan</h1><br>
Kirmanckî de çekuya “<strong>herfe</strong>” bi xo <strong>makî ya</strong>. Nimûne:<br>
To na <strong>herfe </strong>key nuşt<strong>e</strong>? (Bu harfi ne zaman yazdın?)<br>
&nbsp;<br>
Goreyê meylê kombîyayîşî, <strong>nameyê herfan nêrî yê</strong>. Nimûne:<br>
Noyo ke to <strong>nuşto </strong>T <strong>nîyo</strong>, Z <strong>yo</strong>. (Bu yazdığın T değil, Z’dir.)<br>
Nameyê Kurdîstanî bi “K”yê girdî dest pê <strong>keno</strong>. (Kürdistan adı büyük K ile başlar.) Kamî no A <strong>nuşto</strong>, kamî no Î <strong>nuşto</strong>? (Bu A’yı kim yazdı, bu Î’yi kim yazdı?)<br>
&nbsp;<br>
&nbsp;
<h1>1.2.KILMNUŞTEYÎ (KISALTMALAR)</h1><br>

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:83px"><strong>kilmnuşte</strong></td>
<td style="height:17px; width:208px"><strong>kirmanckî</strong></td>
<td style="height:17px; width:302px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:83px">b</td>
<td style="height:17px; width:208px">biewnî (nîyade)</td>
<td style="height:17px; width:302px">bak</td>
</tr>
<tr>
<td style="height:17px; width:83px">bs</td>
<td style="height:17px; width:208px">bestox</td>
<td style="height:17px; width:302px">bağlaç</td>
</tr>
<tr>
<td style="height:17px; width:83px">c</td>
<td style="height:17px; width:208px">cîld</td>
<td style="height:17px; width:302px">cilt</td>
</tr>
<tr>
<td style="height:17px; width:83px">çc</td>
<td style="height:17px; width:208px">çimeyo corên</td>
<td style="height:17px; width:302px">y.a.g.k. (yukarıda adı geçen kaynak)</td>
</tr>
<tr>
<td style="height:17px; width:83px">ec</td>
<td style="height:17px; width:208px">esero corên</td>
<td style="height:17px; width:302px">y.a.g.e. (yukarıda adı geçen eser)</td>
</tr>
<tr>
<td style="height:17px; width:83px">ed</td>
<td style="height:17px; width:208px">edat</td>
<td style="height:17px; width:302px">ilgeç, edat</td>
</tr>
<tr>
<td style="height:17px; width:83px">env</td>
<td style="height:17px; width:208px">esero ke nameyê xo vîyareno</td>
<td style="height:17px; width:302px">a.g.e. (adı geçen eser)</td>
</tr>
<tr>
<td style="height:17px; width:83px">ÎP</td>
<td style="height:17px; width:208px">Îsayî ra pey</td>
<td style="height:17px; width:302px">M. S. (Milattan Sonra)</td>
</tr>
<tr>
<td style="height:17px; width:83px">ÎV</td>
<td style="height:17px; width:208px">Îsayî ra ver</td>
<td style="height:17px; width:302px">M. Ö. (Milattan Önce)</td>
</tr>
<tr>
<td style="height:17px; width:83px">m</td>
<td style="height:17px; width:208px">makî</td>
<td style="height:17px; width:302px">dişil</td>
</tr>
<tr>
<td style="height:17px; width:83px">mat</td>
<td style="height:17px; width:208px">matematîk</td>
<td style="height:17px; width:302px">matematik</td>
</tr>
<tr>
<td style="height:17px; width:83px">n</td>
<td style="height:17px; width:208px">nêrî</td>
<td style="height:17px; width:302px">eril</td>
</tr>
<tr>
<td style="height:17px; width:83px">nc</td>
<td style="height:17px; width:208px">nuşteyo corên</td>
<td style="height:17px; width:302px">y.a.g.y. (yukarıda adı geçen yazı)</td>
</tr>
<tr>
<td style="height:17px; width:83px">nm</td>
<td style="height:17px; width:208px">name</td>
<td style="height:17px; width:302px">ad, isim</td>
</tr>
<tr>
<td style="height:17px; width:83px">nnv</td>
<td style="height:17px; width:208px">nuşteyo ke nameyê xo vîyareno</td>
<td style="height:17px; width:302px">a.g.e. (adı geçen yazı)</td>
</tr>
<tr>
<td style="height:17px; width:83px">nr</td>
<td style="height:17px; width:208px">numre</td>
<td style="height:17px; width:302px">numara</td>
</tr>
<tr>
<td style="height:17px; width:83px">ny</td>
<td style="height:17px; width:208px">nîyade (biewnî)</td>
<td style="height:17px; width:302px">bak</td>
</tr>
<tr>
<td style="height:17px; width:83px">pd</td>
<td style="height:17px; width:208px">peyedat</td>
<td style="height:17px; width:302px">artedat</td>
</tr>
<tr>
<td style="height:17px; width:83px">pg</td>
<td style="height:17px; width:208px">peygir</td>
<td style="height:17px; width:302px">sonek</td>
</tr>
<tr>
<td style="height:17px; width:83px">r</td>
<td style="height:17px; width:208px">rîpel</td>
<td style="height:17px; width:302px">sayfa</td>
</tr>
<tr>
<td style="height:17px; width:83px">s</td>
<td style="height:17px; width:208px">sifet</td>
<td style="height:17px; width:302px">sıfat</td>
</tr>
<tr>
<td style="height:17px; width:83px">ûêb</td>
<td style="height:17px; width:208px">û ê bînî</td>
<td style="height:17px; width:302px">vd. (ve diğerleri), vb. (ve başkaları),vs. (ve saire)</td>
</tr>
<tr>
<td style="height:17px; width:83px">ûsn</td>
<td style="height:17px; width:208px">û sey nînan</td>
<td style="height:17px; width:302px">vb. (ve benzeri, ve bunun gibi)</td>
</tr>
<tr>
<td style="height:17px; width:83px">vg</td>
<td style="height:17px; width:208px">vergir</td>
<td style="height:17px; width:302px">önek</td>
</tr>
<tr>
<td style="height:17px; width:83px">vr</td>
<td style="height:17px; width:208px">veredat</td>
<td style="height:17px; width:302px">önedat</td>
</tr>
<tr>
<td style="height:17px; width:83px">yh</td>
<td style="height:17px; width:208px">yewhûmar</td>
<td style="height:17px; width:302px">tekil</td>
</tr>
<tr>
<td style="height:17px; width:83px">z</td>
<td style="height:17px; width:208px">zemîr</td>
<td style="height:17px; width:302px">adıl, zamir</td>
</tr>
<tr>
<td style="height:17px; width:83px">zh</td>
<td style="height:17px; width:208px">zafhûmar</td>
<td style="height:17px; width:302px">çoğul</td>
</tr>
<tr>
<td style="height:17px; width:83px">zr</td>
<td style="height:17px; width:208px">zerfe</td>
<td style="height:17px; width:302px">belirteç, zarf</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Seke cor ra zî aseno, herfanê kilmkerdeyan dima <strong>nuqta nêronîyena</strong>.<br>
Mîyanê cumle de eke nameyo kilmkerde halê anteyî (oblîkî) de bibo, ey dima <strong>apostrof ronîyeno</strong>.<br>
Nimûneyî:<br>
<br>
&nbsp;<br>
Kurdê welatperwerî lejê YNK û PDK’î nêwazenê. Hukmatê Tirkîya şertê IMF’î qebul kerdî.<br>
&nbsp;
<h1>1.3.VURÎYAYÎŞÊ (BEDILÎYAYÎŞÊ) VENGAN</h1><br>
Kirmanckî de, tayê vengî est ê ke fek ra fek vurîyenê. Ma cêr ra nê tewir vengan ra çend nimûmeyan û tercîhanê Kombîyayîşê Grûba Xebate ya Vateyî nusenê.<br>
&nbsp;
<h1>1.3.1.-an</h1><br>
Tayê fekanê kirmanckî de, cayê “<strong>-an</strong>”î de “<strong>-ûn</strong>”, “<strong>-on</strong>”, “<strong>-û</strong>” yan zî “<strong>-o</strong>” vajîyeno. Kombîyayîşê ma de nînan ra “<strong>-an</strong>” tercîh bîyo. Çend nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:132px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:234px"><strong>formê bînî</strong></td>
<td style="height:17px; width:113px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:132px"><strong>-an</strong></td>
<td style="height:17px; width:234px"><strong>-on, -o; -ûn, -û</strong></td>
<td style="height:17px; width:113px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:132px">m<strong>an</strong>g<strong>an</strong></td>
<td style="height:17px; width:234px">m<strong>on</strong>g<strong>on</strong>, m<strong>on</strong>g<strong>o</strong>; m<strong>ûn</strong>g<strong>ûn</strong>, m<strong>ûn</strong>g<strong>û</strong></td>
<td style="height:17px; width:113px">inekler</td>
</tr>
<tr>
<td style="height:17px; width:132px">dew<strong>an</strong></td>
<td style="height:17px; width:234px">dew<strong>on</strong>, dew<strong>o</strong>; dew<strong>ûn</strong>, dew<strong>û</strong></td>
<td style="height:17px; width:113px">köyler</td>
</tr>
<tr>
<td style="height:17px; width:132px">b<strong>anan</strong></td>
<td style="height:17px; width:234px">b<strong>onon</strong>, b<strong>ono</strong>; b<strong>ûnûn</strong>, b<strong>ûnû</strong></td>
<td style="height:17px; width:113px">1-evler 2-binalar</td>
</tr>
<tr>
<td style="height:17px; width:132px">z<strong>an</strong>ay<strong>an</strong></td>
<td style="height:17px; width:234px">z<strong>on</strong>ay<strong>on</strong>, z<strong>on</strong>ay<strong>o</strong>; z<strong>ûn</strong>ay<strong>ûn</strong>, z<strong>ûn</strong>ay<strong>û</strong></td>
<td style="height:17px; width:113px">bilenler, bigililer</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>1.3.2.-am</h1><br>
Tayê fekan de, cayê “<strong>-am</strong>”î de “<strong>-om</strong>” yan zî “<strong>-ûm</strong>” vajîyeno. Kombîyayîşê ma de nînan ra formê “<strong>-am</strong>”î tercîh bîyo. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:132px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:140px"><strong>formê bînî</strong></td>
<td style="height:17px; width:76px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:132px"><strong>-am</strong></td>
<td style="height:17px; width:140px"><strong>-om, -ûm</strong></td>
<td style="height:17px; width:76px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:132px">k<strong>am</strong></td>
<td style="height:17px; width:140px">k<strong>om</strong>, k<strong>ûm</strong></td>
<td style="height:17px; width:76px">kim</td>
</tr>
<tr>
<td style="height:17px; width:132px">l<strong>am</strong>ba</td>
<td style="height:17px; width:140px">l<strong>om</strong>ba, l<strong>ûm</strong>ba</td>
<td style="height:17px; width:76px">lamba</td>
</tr>
<tr>
<td style="height:17px; width:132px">n<strong>am</strong>e</td>
<td style="height:17px; width:140px">n<strong>om</strong>e, n<strong>ûm</strong>e</td>
<td style="height:17px; width:76px">ad, isim</td>
</tr>
<tr>
<td style="height:17px; width:132px">t<strong>am</strong></td>
<td style="height:17px; width:140px">t<strong>om</strong>, t<strong>ûm</strong></td>
<td style="height:17px; width:76px">tam</td>
</tr>
<tr>
<td style="height:17px; width:132px">tem<strong>am</strong></td>
<td style="height:17px; width:140px">tem<strong>om</strong>, tem<strong>ûm</strong></td>
<td style="height:17px; width:76px">tamam</td>
</tr>
<tr>
<td style="height:17px; width:132px">z<strong>am</strong>a</td>
<td style="height:17px; width:140px">z<strong>om</strong>a, z<strong>ûm</strong>a</td>
<td style="height:17px; width:76px">damat</td>
</tr>
</tbody>
</table>
&nbsp;

<ol>
<li><h1>1.3.3.Vengê “J”, “C”, “Z”yî</h1></li>
</ol>
Kirmanckî de tayê çekuyî est ê ke tayê fekan de bi “<strong>J</strong>”, tayê fekan de zî bi “<strong>C</strong>” yan zî “<strong>Z</strong>” vajîyenê. Kombîyayîş de nînan ra formê “J”yinî tercîh bîyo. Yanî eke çekuyêk (yew çekuye) wayîrê nê hîrê forman bibo, nînan ra formê “J”yinî tercîh beno. Nimûneyî:<br>
<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:132px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:178px"><strong>formê bînî</strong></td>
<td style="height:17px; width:170px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:132px"><strong>J</strong></td>
<td style="height:17px; width:178px"><strong>C, Z</strong></td>
<td style="height:17px; width:170px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:132px">çi<strong>j</strong>e</td>
<td style="height:17px; width:178px">çi<strong>c</strong>e, çi<strong>z</strong>ik, ci<strong>z</strong>ik, ci<strong>z</strong>e, ci<strong>z</strong></td>
<td style="height:17px; width:170px">meme</td>
</tr>
<tr>
<td style="height:17px; width:132px">de<strong>j</strong></td>
<td style="height:17px; width:178px">de<strong>c</strong>, de<strong>z</strong></td>
<td style="height:17px; width:170px">ağrı, acı, üzüntü</td>
</tr>
<tr>
<td style="height:17px; width:132px">de<strong>j</strong>ayene</td>
<td style="height:17px; width:178px">de<strong>c</strong>ayene, de<strong>z</strong>ayene</td>
<td style="height:17px; width:170px">ağrımak, acımak, üzülmek</td>
</tr>
<tr>
<td style="height:17px; width:132px">dewi<strong>j</strong>, -e</td>
<td style="height:17px; width:178px">dewi<strong>c</strong>, dewi<strong>z</strong></td>
<td style="height:17px; width:170px">köylü</td>
</tr>
<tr>
<td style="height:17px; width:132px">dêrsimi<strong>j</strong>, -e</td>
<td style="height:17px; width:178px">dêrsimi<strong>c</strong>, dêrsimi<strong>z</strong></td>
<td style="height:17px; width:170px">Dersimli</td>
</tr>
<tr>
<td style="height:17px; width:132px">er<strong>j</strong>an, -e</td>
<td style="height:17px; width:178px">er<strong>c</strong>an, ar<strong>c</strong>on, er<strong>z</strong>an</td>
<td style="height:17px; width:170px">ucuz</td>
</tr>
<tr>
<td style="height:17px; width:132px">er<strong>j</strong>anîye</td>
<td style="height:17px; width:178px">er<strong>c</strong>oney, ar<strong>c</strong>oney, er<strong>z</strong>anîye</td>
<td style="height:17px; width:170px">ucuzluk</td>
</tr>
<tr>
<td style="height:17px; width:132px">eski<strong>j</strong>e</td>
<td style="height:17px; width:178px">eski<strong>c</strong>i, eski<strong>z</strong>e</td>
<td style="height:17px; width:170px">kuru üzüm</td>
</tr>
<tr>
<td style="height:17px; width:132px">espi<strong>j</strong>e</td>
<td style="height:17px; width:178px">espi<strong>c</strong>i, aspi<strong>z</strong>e</td>
<td style="height:17px; width:170px">bit</td>
</tr>
<tr>
<td style="height:17px; width:132px">gi<strong>j</strong>ik</td>
<td style="height:17px; width:178px">gi<strong>c</strong>ik, gi<strong>z</strong>ik</td>
<td style="height:17px; width:170px">saç</td>
</tr>
<tr>
<td style="height:17px; width:132px">ke<strong>j</strong>, -e</td>
<td style="height:17px; width:178px">ke<strong>c</strong>, ke<strong>z</strong></td>
<td style="height:17px; width:170px">sarışın</td>
</tr>
<tr>
<td style="height:17px; width:132px">la<strong>j</strong></td>
<td style="height:17px; width:178px">la<strong>c</strong>, la<strong>z</strong></td>
<td style="height:17px; width:170px">oğul, oğlan</td>
</tr>
<tr>
<td style="height:17px; width:132px">la<strong>j</strong>ek</td>
<td style="height:17px; width:178px">la<strong>c</strong>ek, la<strong>z</strong>ek</td>
<td style="height:17px; width:170px">erkek çocuk, oğlan</td>
</tr>
<tr>
<td style="height:17px; width:132px">le<strong>j</strong></td>
<td style="height:17px; width:178px">le<strong>c</strong>, le<strong>z</strong></td>
<td style="height:17px; width:170px">kavga</td>
</tr>
<tr>
<td style="height:17px; width:132px">le<strong>j </strong>kerdene</td>
<td style="height:17px; width:178px">le<strong>c </strong>kerdene, le<strong>z </strong>kerdene</td>
<td style="height:17px; width:170px">kavga etmek</td>
</tr>
<tr>
<td style="height:17px; width:132px">lo<strong>j</strong>ine</td>
<td style="height:17px; width:178px">lo<strong>c</strong>ini, lo<strong>z</strong>ine</td>
<td style="height:17px; width:170px">baca</td>
</tr>
<tr>
<td style="height:17px; width:132px">mi<strong>j</strong></td>
<td style="height:17px; width:178px">mi<strong>c</strong>, mi<strong>z</strong></td>
<td style="height:17px; width:170px">sis</td>
</tr>
<tr>
<td style="height:17px; width:132px">nima<strong>j</strong></td>
<td style="height:17px; width:178px">nima<strong>c</strong>, nima<strong>z</strong>, nema<strong>z</strong>, nime</td>
<td style="height:17px; width:170px">namaz</td>
</tr>
<tr>
<td style="height:17px; width:132px">palîyi<strong>j</strong>, -e</td>
<td style="height:17px; width:178px">paliwi<strong>c</strong>, paliyi<strong>c</strong>, paliyi<strong>z</strong></td>
<td style="height:17px; width:170px">Palulu</td>
</tr>
<tr>
<td style="height:17px; width:132px">piro<strong>j</strong>ine</td>
<td style="height:17px; width:178px">pir<strong>c</strong>ini, piro<strong>z</strong>ine</td>
<td style="height:17px; width:170px">kalbur</td>
</tr>
<tr>
<td style="height:17px; width:132px">qi<strong>j</strong>, -e</td>
<td style="height:17px; width:178px">qi<strong>c</strong>, -e; qi<strong>z</strong>, -e</td>
<td style="height:17px; width:170px">küçük</td>
</tr>
<tr>
<td style="height:17px; width:132px">ro<strong>j</strong></td>
<td style="height:17px; width:178px">ro<strong>c</strong>, ro<strong>z</strong></td>
<td style="height:17px; width:170px">güneş</td>
</tr>
<tr>
<td style="height:17px; width:132px">ro<strong>j</strong>e</td>
<td style="height:17px; width:178px">ro<strong>c</strong>e, ro<strong>z</strong>e</td>
<td style="height:17px; width:170px">gün</td>
</tr>
<tr>
<td style="height:17px; width:132px">ro<strong>j</strong>e</td>
<td style="height:17px; width:178px">ro<strong>c</strong>e, ro<strong>z</strong>e</td>
<td style="height:17px; width:170px">oruç</td>
</tr>
<tr>
<td style="height:17px; width:132px">şaristani<strong>j</strong>, -e</td>
<td style="height:17px; width:178px">şaristoni<strong>c</strong>, -i</td>
<td style="height:17px; width:170px">kentli, şehirli</td>
</tr>
<tr>
<td style="height:17px; width:132px">va<strong>j</strong>e</td>
<td style="height:17px; width:178px">va<strong>c</strong>e, va<strong>c</strong>i, va<strong>z</strong>e</td>
<td style="height:17px; width:170px">söyle</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>1.3.4.Vengê “K” û “Ç”yî</h1><br>
Kirmanckî de tayê çekuyî est ê ke tayê fekan de bi “<strong>K</strong>”, tayê fekan de zî bi “<strong>Ç</strong>” vajîyenê. Hîna zaf gama ke vengê “<strong>K</strong>”yî dima vengê “<strong>Î</strong>” yan zî “<strong>Ê</strong>”yî yeno, no “<strong>K</strong>” beno “<strong>Ç</strong>”:

<h1>K + Î → Ç K + Ê → Ç</h1><br>
Kombîyayîşê ma nînan ra “<strong>K</strong>” tercîh kerdo. Çend nimûneyî:<br>
<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:34px; width:253px"><strong>Kirmanckî K</strong></td>
<td style="height:34px; width:132px"><strong>Kirmanckî Ç</strong></td>
<td style="height:34px; width:198px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>k</strong>efî, <strong>k</strong>efîye</td>
<td style="height:17px; width:132px"><strong>ç</strong>êfî, <strong>ç</strong>efî, <strong>ç</strong>efye</td>
<td style="height:17px; width:198px">kefiye, bir çeşit başörtüsü</td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>k</strong>eya, -ye, <strong>k</strong>êwa, -ye</td>
<td style="height:17px; width:132px"><strong>ç</strong>êwa, -ye</td>
<td style="height:17px; width:198px">muhtar, önde gelen, yönetici</td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>k</strong>eyayîye</td>
<td style="height:17px; width:132px"><strong>ç</strong>êwayîye</td>
<td style="height:17px; width:198px">muhtarlık, yöneticilik, liderlik</td>
</tr>
<tr>
<td style="height:34px; width:253px"><strong>k</strong>eyber, <strong>k</strong>êber, <strong>k</strong>êver</td>
<td style="height:34px; width:132px"><strong>ç</strong>êber, <strong>ç</strong>êver, <strong>ç</strong>êwer</td>
<td style="height:34px; width:198px">
<ol>
<li>evin önü, ev önündeki bahçe</li>
<li>kapı</li>
</ol>
</td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>k</strong>eye, <strong>k</strong>îye, <strong>k</strong>ehe, <strong>k</strong>ê, <strong>k</strong>î</td>
<td style="height:17px; width:132px"><strong>ç</strong>eye, <strong>ç</strong>ê, <strong>ç</strong>îye, <strong>ç</strong>e</td>
<td style="height:17px; width:198px">ev</td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>k</strong>eyeker, -e</td>
<td style="height:17px; width:132px"><strong>ç</strong>êyker, -e; <strong>ç</strong>êker, -e</td>
<td style="height:17px; width:198px">evcimen, hamarat</td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>k</strong>eyf, <strong>k</strong>êf</td>
<td style="height:17px; width:132px"><strong>ç</strong>êf</td>
<td style="height:17px; width:198px">keyif</td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>k</strong>eyveşaye, <strong>k</strong>êveşaye</td>
<td style="height:17px; width:132px"><strong>ç</strong>êvesaye</td>
<td style="height:17px; width:198px">evi yanasıca</td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>k</strong>êna, <strong>k</strong>ena, <strong>k</strong>eyna, <strong>k</strong>eyne, <strong>k</strong>eyni, <strong>k</strong>êyna</td>
<td style="height:17px; width:132px"><strong>ç</strong>êna, <strong>ç</strong>êne, <strong>ç</strong>ena</td>
<td style="height:17px; width:198px">kız</td>
</tr>
<tr>
<td style="height:34px; width:253px"><strong>k</strong>êneke, <strong>k</strong>êneki, <strong>k</strong>ênek, <strong>k</strong>eyneke, <strong>k</strong>eyneki, <strong>k</strong>îyneki</td>
<td style="height:34px; width:132px"><strong>ç</strong>êneke, <strong>ç</strong>êneki, <strong>ç</strong>eneke</td>
<td style="height:34px; width:198px">kız</td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>k</strong>êran</td>
<td style="height:17px; width:132px"><strong>ç</strong>êran</td>
<td style="height:17px; width:198px">çatı direği, kiriş</td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>k</strong>ês</td>
<td style="height:17px; width:132px"><strong>ç</strong>ês</td>
<td style="height:17px; width:198px">iri saman, ot samanı</td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>k</strong>în</td>
<td style="height:17px; width:132px"><strong>ç</strong>în</td>
<td style="height:17px; width:198px">kin</td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>k</strong>îp</td>
<td style="height:17px; width:132px"><strong>ç</strong>îp</td>
<td style="height:17px; width:198px">sıkı</td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>k</strong>îse, <strong>k</strong>îsik, <strong>k</strong>êsik</td>
<td style="height:17px; width:132px"><strong>ç</strong>êsik</td>
<td style="height:17px; width:198px">kese, torba</td>
</tr>
<tr>
<td style="height:17px; width:253px">qeze<strong>k</strong>î</td>
<td style="height:17px; width:132px">qeze<strong>ç</strong>î</td>
<td style="height:17px; width:198px">cepken</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;<br>
Tayê ziwanan û lehçeyanê bînan de zî nê çekuyan de “<strong>K</strong>” est o. Nimûneyî:

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:34px; width:253px"><strong>kirmanckî K</strong></td>
<td style="height:34px; width:350px"><strong>tayê lehçe û ziwanê bînî K</strong></td>
</tr>
<tr>
<td style="height:34px; width:253px"><strong>k</strong>eya, -ye, <strong>k</strong>êwa, -ye</td>
<td style="height:34px; width:350px"><strong>k</strong>etekxwetay (pehlevkî), <strong>k</strong>eya&nbsp; &nbsp;(kurmanckî),&nbsp; &nbsp;<strong>k</strong>eyxwa<br>
(kurdkîya mîyanêne)</td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>k</strong>eye, <strong>k</strong>îye, <strong>k</strong>ehe, <strong>k</strong>ê, <strong>k</strong>î</td>
<td style="height:17px; width:350px"><strong>k</strong>ete (ziwanê Avesta), <strong>k</strong>etek (pehlevkî)</td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>k</strong>eyf</td>
<td style="height:17px; width:350px"><strong>k</strong>eyf (erebkî)</td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>k</strong>êna, <strong>k</strong>ena, <strong>k</strong>eyna, <strong>k</strong>eyne, <strong>k</strong>eyni, <strong>k</strong>êyna</td>
<td style="height:17px; width:350px"><strong>k</strong>einya/<strong>k</strong>einî/<strong>k</strong>enya (ziwanê Avesta)</td>
</tr>
<tr>
<td style="height:34px; width:253px"><strong>k</strong>êneke,&nbsp;&nbsp;&nbsp;&nbsp; <strong>k</strong>êneki,&nbsp;&nbsp;&nbsp;&nbsp; <strong>k</strong>ênek,&nbsp;&nbsp;&nbsp;&nbsp; <strong>k</strong>eyneke,<br>
<strong>k</strong>eyneki, <strong>k</strong>îyneki</td>
<td style="height:34px; width:350px"><strong>k</strong>einika&nbsp;&nbsp;&nbsp; (ziwanê&nbsp;&nbsp;&nbsp; Avesta),&nbsp;&nbsp;&nbsp; <strong>k</strong>enîg&nbsp;&nbsp;&nbsp; (partkî),&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>k</strong>enîg<br>
(pehlevkî), <strong>k</strong>inaçê (hewramkî)</td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>k</strong>êran</td>
<td style="height:17px; width:350px"><strong>g</strong>eranion (yunankî), <strong>g</strong>êran (armenîkî), <strong>k</strong>êran (kurmanckî)</td>
</tr>
<tr>
<td style="height:34px; width:253px"><strong>k</strong>îse, <strong>k</strong>îsik, <strong>k</strong>êsik</td>
<td style="height:34px; width:350px"><strong>k</strong>îsa (aramkî),&nbsp; &nbsp;<strong>k</strong>îs&nbsp; &nbsp;(erebkî),&nbsp; &nbsp;<strong>k</strong>îs&nbsp; &nbsp;(kurmanckî),&nbsp; &nbsp;<strong>k</strong>îse<br>
(hewramkî); <strong>k</strong>îsek (pehlevkî), <strong>k</strong>îsik (kurmanckî)</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>1.3.5.Vengê “O” û “WE”yî</h1><br>
Kirmanckî de tayê çekuyî est ê ke eslê xo tirkî yo û bi “<strong>o-</strong>” dest pêkenê labelê “<strong>o-</strong>”yê verênê nê çekuyan tayê fekan de sey “<strong>we-</strong>”yî vajîyeno. Mavajî cayê “ortax”î de “wertax” vajîyeno. Kombîyayîş de nînan ra formê “<strong>o</strong>”yinî tercîh bîyo. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:170px"><strong>formê bînî</strong></td>
<td style="height:17px; width:246px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>o-</strong></td>
<td style="height:17px; width:170px"><strong>we-</strong></td>
<td style="height:17px; width:246px">← <strong>o-</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>o</strong>cax</td>
<td style="height:17px; width:170px"><strong>we</strong>cax</td>
<td style="height:17px; width:246px">← <strong>o</strong>cak</td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>o</strong>caxkor, -e</td>
<td style="height:17px; width:170px"><strong>we</strong>caxkor, -e</td>
<td style="height:17px; width:246px">← çocuğu olmayan</td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>o</strong>da</td>
<td style="height:17px; width:170px"><strong>we</strong>da, <strong>we</strong>de</td>
<td style="height:17px; width:246px">← <strong>o</strong>da</td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>o</strong>rdege</td>
<td style="height:17px; width:170px"><strong>we</strong>rdeki, <strong>we</strong>rdege</td>
<td style="height:17px; width:246px">← <strong>ö</strong>rdek</td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>o</strong>rtax, -e</td>
<td style="height:17px; width:170px"><strong>we</strong>rtax, -e</td>
<td style="height:17px; width:246px">← <strong>o</strong>rtak</td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>o</strong>rte</td>
<td style="height:17px; width:170px"><strong>we</strong>rte, <strong>we</strong>rti</td>
<td style="height:17px; width:246px">← <strong>o</strong>rta</td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>o</strong>yn</td>
<td style="height:17px; width:170px"><strong>we</strong>yn, <strong>we</strong>yîn</td>
<td style="height:17px; width:246px">← <strong>o</strong>yun (hile, numara)</td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>o</strong>yniker, -e</td>
<td style="height:17px; width:170px"><strong>we</strong>yniker, -e; <strong>we</strong>yînkar, -e</td>
<td style="height:17px; width:246px">← <strong>o</strong>yuncu (hilekâr, numaracı)</td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>o</strong>lecax, <strong>o</strong>lacax</td>
<td style="height:17px; width:170px"><strong>we</strong>lecax, <strong>we</strong>lcax</td>
<td style="height:17px; width:246px">kaza [←<strong>o</strong>lacak]</td>
</tr>
</tbody>
</table>
<br>
&nbsp;<br>
&nbsp;
<h1>1.4.HERFA PÊRAGIRÊDAYENE</h1><br>
Kirmanckî de nê di herfê pêragirêdayene (pêrabestene) est ê: <strong>y</strong>, <strong>w</strong>. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:113px"><strong>formê bînî</strong></td>
<td style="height:17px; width:66px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>-y-</strong></td>
<td style="height:17px; width:113px"><strong>-w-</strong></td>
<td style="height:17px; width:66px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:140px">ga<strong>y</strong>an (ga+<strong>y</strong>+an)</td>
<td style="height:17px; width:113px">ga<strong>w</strong>û (ga+<strong>w</strong>+û)</td>
<td style="height:17px; width:66px">öküzler</td>
</tr>
<tr>
<td style="height:17px; width:140px">ko<strong>y</strong>an (ko+<strong>y</strong>+an)</td>
<td style="height:17px; width:113px">ko<strong>w</strong>û (ko+<strong>w</strong>+û)</td>
<td style="height:17px; width:66px">dağlar</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Nê nimûneyan de “ga” û “ko” yewhûmar (tekil) ê. Gama ke nê çekuyî peygirê zafhûmarîye (çoğul takısı) gênê, benê “g<strong>a </strong>+ <strong>a</strong>n” û “k<strong>o </strong>+ <strong>a</strong>n”. Labelê semedo ke di venginî (iki sesli) yanî “<strong>a</strong>” û “<strong>a</strong>” yan zî “<strong>o</strong>” û “<strong>a</strong>” yenê têhet, herfa pêragirêdayene (<strong>y</strong>, <strong>w</strong>) dekewena mabênê nê di venginan û benê “gayan” (g<strong>a + y + a</strong>n) û “koyan” (k<strong>o </strong>+ <strong>y </strong>+ <strong>a</strong>n) yan zî gawû (g<strong>a </strong>+ <strong>w </strong>+ <strong>û</strong>) û kowû (k<strong>o </strong>+ <strong>w </strong>+ <strong>û</strong>).<br>
Kombîyayîşê ma nê di herfan ra “<strong>y</strong>” tercîh kerd. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:149px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:132px"><strong>formê bînî</strong></td>
<td style="height:17px; width:123px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:149px"><strong>-y-</strong></td>
<td style="height:17px; width:132px"><strong>-w-</strong></td>
<td style="height:17px; width:123px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:149px">Ga<strong>y</strong>an bîyare.</td>
<td style="height:17px; width:132px">Ga<strong>w</strong>û bîyare.</td>
<td style="height:17px; width:123px">Öküzleri getir.</td>
</tr>
<tr>
<td style="height:17px; width:149px">Ko<strong>y</strong>an ra vewre est a.</td>
<td style="height:17px; width:132px">Ko<strong>w</strong>û ra vore est a.</td>
<td style="height:17px; width:123px">Dağlarda kar var.</td>
</tr>
<tr>
<td style="height:17px; width:149px">Hesen o <strong>y</strong>o.</td>
<td style="height:17px; width:132px">Hesen o <strong>w</strong>o.</td>
<td style="height:17px; width:123px">Hasan odur.</td>
</tr>
<tr>
<td style="height:17px; width:149px">Roşan no <strong>y</strong>o.</td>
<td style="height:17px; width:132px">Roşan no <strong>w</strong>o.</td>
<td style="height:17px; width:123px">Roşan budur.</td>
</tr>
</tbody>
</table>
Tayê kesî cayê ke îcab keno herfa pêragirêdayene nênusenî û di yan zî hîrê herfanê venginan têhet nusenê labelê ganî nê çekuyan de herfa pêragirêdayene binusîyo. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:149px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:132px"><strong>formê bînî</strong></td>
<td style="height:17px; width:123px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:149px">a<strong>y</strong>e</td>
<td style="height:17px; width:132px"><strong>ae</strong></td>
<td style="height:17px; width:123px">o, ona</td>
</tr>
<tr>
<td style="height:17px; width:149px">bira<strong>y</strong>ê mi</td>
<td style="height:17px; width:132px">bir<strong>aê </strong>mi</td>
<td style="height:17px; width:123px">kardeşim</td>
</tr>
<tr>
<td style="height:17px; width:149px">cî<strong>y</strong>a</td>
<td style="height:17px; width:132px">c<strong>îa</strong></td>
<td style="height:17px; width:123px">ayrı</td>
</tr>
<tr>
<td style="height:17px; width:149px">da<strong>y</strong>îş</td>
<td style="height:17px; width:132px">d<strong>aî</strong>s</td>
<td style="height:17px; width:123px">verme</td>
</tr>
<tr>
<td style="height:17px; width:149px">ga<strong>y</strong>o girs</td>
<td style="height:17px; width:132px">g<strong>ao </strong>girs</td>
<td style="height:17px; width:123px">büyük öküz</td>
</tr>
<tr>
<td style="height:17px; width:149px">hîrê<strong>y</strong>ine</td>
<td style="height:17px; width:132px">hîr<strong>eî</strong>ne</td>
<td style="height:17px; width:123px">üçüncü</td>
</tr>
<tr>
<td style="height:17px; width:149px">ko<strong>y</strong>o nizm</td>
<td style="height:17px; width:132px">k<strong>oo </strong>nizm</td>
<td style="height:17px; width:123px">alçak dağ</td>
</tr>
<tr>
<td style="height:17px; width:149px">pî<strong>y</strong>a</td>
<td style="height:17px; width:132px">p<strong>îa</strong></td>
<td style="height:17px; width:123px">birlikte</td>
</tr>
<tr>
<td style="height:17px; width:149px">qelema sî<strong>y</strong>a<strong>y</strong>e</td>
<td style="height:17px; width:132px">qelema ş<strong>îae</strong></td>
<td style="height:17px; width:123px">siyah kalem</td>
</tr>
<tr>
<td style="height:17px; width:149px">vejî<strong>y</strong>a<strong>y</strong>îş</td>
<td style="height:17px; width:132px">vejîy<strong>aî</strong>ş</td>
<td style="height:17px; width:123px">çıkma</td>
</tr>
<tr>
<td style="height:17px; width:149px">vî<strong>y</strong>a<strong>y</strong>e</td>
<td style="height:17px; width:132px">v<strong>îae</strong></td>
<td style="height:17px; width:123px">dul kadın</td>
</tr>
<tr>
<td style="height:17px; width:149px">wa<strong>y</strong>e</td>
<td style="height:17px; width:132px">w<strong>ae</strong></td>
<td style="height:17px; width:123px">bacı, kız kardeş</td>
</tr>
<tr>
<td style="height:17px; width:149px">Hîra <strong>y</strong>o.</td>
<td style="height:17px; width:132px">Hîr<strong>a o</strong>.</td>
<td style="height:17px; width:123px">Geniştir.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>2.NAME</h1><br>
Ma cêr ra derheqê tayê nameyan de meylê kombîyayîşî nusenê.<br>
&nbsp;
<h1>2.1.Nameyê Rojan</h1><br>
Nameyê rojan bi herfa hurdîye (herfa qije) dest pêkenê û wina yê:<br>
<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:255px"><strong>formê bînî</strong></td>
<td style="height:17px; width:76px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">şeme</td>
<td style="height:17px; width:255px">şembe, seme, şenbe</td>
<td style="height:17px; width:76px">cumartesi</td>
</tr>
<tr>
<td style="height:17px; width:140px">yewşeme</td>
<td style="height:17px; width:255px">yoşemi, yoşembi, zewşeme</td>
<td style="height:17px; width:76px">pazar</td>
</tr>
<tr>
<td style="height:17px; width:140px">dişeme</td>
<td style="height:17px; width:255px">dişime, diseme, duşime</td>
<td style="height:17px; width:76px">pazartesi</td>
</tr>
<tr>
<td style="height:17px; width:140px">sêşeme</td>
<td style="height:17px; width:255px">sêşime, sêseme, şêseme, şeseme, şêşeme</td>
<td style="height:17px; width:76px">salı</td>
</tr>
<tr>
<td style="height:17px; width:140px">çarşeme</td>
<td style="height:17px; width:255px">çarşime, çarseme, çaşme</td>
<td style="height:17px; width:76px">çarşamba</td>
</tr>
<tr>
<td style="height:34px; width:140px">panşeme</td>
<td style="height:34px; width:255px">pojşeme, paşeme, paşme, peşeme,<br>
peşime, poseme, panzşeme</td>
<td style="height:34px; width:76px">perşembe</td>
</tr>
<tr>
<td style="height:17px; width:140px">îne</td>
<td style="height:17px; width:255px">êne, yene</td>
<td style="height:17px; width:76px">cuma</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>2.2.Nameyê Mewsiman</h1><br>
&nbsp;

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:302px"><strong>formê bînî</strong></td>
<td style="height:17px; width:132px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">wisar</td>
<td style="height:17px; width:302px">wesar, wusar, wasar, usar, ûsar</td>
<td style="height:17px; width:132px">ilkbahar</td>
</tr>
<tr>
<td style="height:101px; width:140px">hamnan</td>
<td style="height:101px; width:302px">amin, aminan, amna, amnan, amno, amnon, amnû, amnûn, eminû, eminûn, emna, emnan, emno, emnon, emnû, emnûn, haminan, hemnon, hemnûn, iminon, imnan, imno, imnon, omin, omno, omnon, omnû, omnûn, umnû, umnûn,<br>
ûmnû, ûmnûn</td>
<td style="height:101px; width:132px">yaz</td>
</tr>
<tr>
<td style="height:17px; width:140px">payîz</td>
<td style="height:17px; width:302px">payiz</td>
<td style="height:17px; width:132px">sonbahar</td>
</tr>
<tr>
<td style="height:67px; width:140px">zimistan</td>
<td style="height:67px; width:302px">zimista, zimisto, zimiston, zimistû, zimistûn, zimuston, zimustû, zimustûn, zimûstû, zumistan, zumistû, zumistûn, zumust, zumusto, zumuston,<br>
zumustû, zumustûn</td>
<td style="height:67px; width:132px">kış</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<ol>
<li><h1>2.3.Nameyê Mengan (Aşman)</h1></li>
</ol>
Kirmanckî de hem çekuya “menge” hem zî “aşme” est a. Tayê fekan de “menge” yena manaya “ay (yılın on iki bölümünden her biri)”ya tirkî, “aşme” yena manaya “ay (kamer)”a tirkî. Tayê fekan de zî çekuya “menge” nêvajîyena, “aşme” nê her di manayan de vajîyena.<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:189px"><strong>formê bînî</strong></td>
<td style="height:17px; width:246px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:34px; width:140px">aşme</td>
<td style="height:34px; width:189px">haşme, aşmi,&nbsp; &nbsp;aşmî,&nbsp; &nbsp;aşim,<br>
aşm, asme, asmî, asmi</td>
<td style="height:34px; width:246px">ay, kamer</td>
</tr>
<tr>
<td style="height:17px; width:140px">menge</td>
<td style="height:17px; width:189px">mengi, meng</td>
<td style="height:17px; width:246px">ay (yılın on iki bölümünden her biri)</td>
</tr>
<tr>
<td style="height:17px; width:140px">ma</td>
<td style="height:17px; width:189px">-</td>
<td style="height:17px; width:246px">ay (yılın on iki bölümünden her biri)</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Nameyê diwês menganê serre:<br>
<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:168px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:85px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:168px">1. çele (n)</td>
<td style="height:17px; width:85px">1. ocak</td>
</tr>
<tr>
<td style="height:17px; width:168px">2. sibate (m), gucige (m)</td>
<td style="height:17px; width:85px">2. şubat</td>
</tr>
<tr>
<td style="height:17px; width:168px">3. adare (m)</td>
<td style="height:17px; width:85px">3. mart</td>
</tr>
<tr>
<td style="height:17px; width:168px">4. nîsane (m)</td>
<td style="height:17px; width:85px">4. nisan</td>
</tr>
<tr>
<td style="height:17px; width:168px">5. gulane (m)</td>
<td style="height:17px; width:85px">5. mayıs</td>
</tr>
<tr>
<td style="height:17px; width:168px">6. hezîrane (m)</td>
<td style="height:17px; width:85px">6. haziran</td>
</tr>
<tr>
<td style="height:17px; width:168px">7. temmuze (m)</td>
<td style="height:17px; width:85px">7. temmuz</td>
</tr>
<tr>
<td style="height:17px; width:168px">8. tebaxe (m)</td>
<td style="height:17px; width:85px">8. ağustos</td>
</tr>
<tr>
<td style="height:17px; width:168px">9. êlule (m)</td>
<td style="height:17px; width:85px">9. eylül</td>
</tr>
<tr>
<td style="height:17px; width:168px">10. teşrîna verêne (m)</td>
<td style="height:17px; width:85px">10. ekim</td>
</tr>
<tr>
<td style="height:17px; width:168px">11. teşrîna peyêne (m)</td>
<td style="height:17px; width:85px">11. kasım</td>
</tr>
<tr>
<td style="height:17px; width:168px">12. kanûne (m)</td>
<td style="height:17px; width:85px">12. aralık</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Seke ma cor ra zî bellî kerdo, nê nameyan ra “çele” nêrî yo, nameyê bînî makî yê. “Çele”/“çile” eslê xo de çewres rojê zimistanî yê û nê rojan zaf serd o.<br>
Nameyê mengan bi herfa hurdîye dest pêkenê.<br>
&nbsp;<br>
&nbsp;
<h1>2.4.Nameyê Hetan (Cîhetan)</h1><br>
&nbsp;

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:111px"><strong>kirmanckî</strong></td>
<td style="height:17px; width:85px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:111px">rojawan</td>
<td style="height:17px; width:85px">batı</td>
</tr>
<tr>
<td style="height:17px; width:111px">rojhelat</td>
<td style="height:17px; width:85px">doğu</td>
</tr>
<tr>
<td style="height:17px; width:111px">vakur</td>
<td style="height:17px; width:85px">kuzey</td>
</tr>
<tr>
<td style="height:17px; width:111px">başûr</td>
<td style="height:17px; width:85px">güney</td>
</tr>
<tr>
<td style="height:17px; width:111px">vakurê rojawanî</td>
<td style="height:17px; width:85px">kuzeybatı</td>
</tr>
<tr>
<td style="height:17px; width:111px">vakurê rojhelatî</td>
<td style="height:17px; width:85px">kuzeydoğu</td>
</tr>
<tr>
<td style="height:17px; width:111px">başûrê rojawanî</td>
<td style="height:17px; width:85px">güneybatı</td>
</tr>
<tr>
<td style="height:17px; width:111px">başûrê rojhelatî</td>
<td style="height:17px; width:85px">güneydoğu</td>
</tr>
</tbody>
</table>
&nbsp;<br>
<em>“Hemê way mi, Hemîlay mi <strong>Rojawan </strong>o, <strong>rojhelat </strong>o Şewqê to dawo heyato</em><br>
<em>Wenganê to dawo welato” (Yew deyîra Pîranî ra, Vate, hûmare: 30, s. 67)</em><br>
&nbsp;
<h1>2.5.Nameyê Qitayan (Kontînentan)</h1><br>
Nameyê qitayan bi herfa girde dest pêkenê û wina nusîyenê:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:92px"><strong>kirmanckî</strong></td>
<td style="height:17px; width:104px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:92px">Afrîka</td>
<td style="height:17px; width:104px">Afrika</td>
</tr>
<tr>
<td style="height:17px; width:92px">Amerîka</td>
<td style="height:17px; width:104px">Amerika</td>
</tr>
<tr>
<td style="height:17px; width:92px">Antarktîka</td>
<td style="height:17px; width:104px">Antarktika</td>
</tr>
<tr>
<td style="height:17px; width:92px">Asya</td>
<td style="height:17px; width:104px">Asya</td>
</tr>
<tr>
<td style="height:17px; width:92px">Ewropa</td>
<td style="height:17px; width:104px">Avrupa</td>
</tr>
<tr>
<td style="height:17px; width:92px">Okyanûsya</td>
<td style="height:17px; width:104px">Okyanusya</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>2.6.Nameyê Ziwanan û Lehçeyan</h1><br>
Nameyê ziwanan û lehçeyan bi herfa hurdîye dest pêkenê. Nimûneyî:<br>
kirmanckî (kirdkî, dimilkî, zazakî), kurmanckî, kurdkî, tirkî, fariskî, erebkî, franskî, îngilizkî Kirmanckî de, nameyê ziwanan tayê fekan de sey çekuya nêrî, tayê fekan de zî sey çekuya makî<br>
vajîyenê. Kombîyayîşê ma nînan ra formê <strong>makî </strong>qebul kerd.<br>
<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:168px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:151px"><strong>formê bînî</strong></td>
<td style="height:17px; width:170px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:168px">Kurdkî<strong>ya </strong>ey weş <strong>a</strong>.</td>
<td style="height:17px; width:151px">Kurdkî<strong>yê </strong>ey weş <strong>o</strong>.</td>
<td style="height:17px; width:170px">Onun Kürtçesi güzeldir.</td>
</tr>
<tr>
<td style="height:17px; width:168px">Fariskî zaf nusîya<strong>ya</strong>.</td>
<td style="height:17px; width:151px">Fariskî zaf nusîya<strong>wo</strong>.</td>
<td style="height:17px; width:170px">Farsça çok yazılmıştır.</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>2.7.Nameyê Dîn, Mezheb û Terîqetan</h1><br>
Nameyê dîn, mezheb û terîqetan bi herfa hurdîye dest pêkenê. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:340px"><strong>formê bînî</strong></td>
<td style="height:17px; width:104px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">elewîyîye</td>
<td style="height:17px; width:340px">elewîyey, elewîyê, alevîyîye</td>
<td style="height:17px; width:104px">Alevilik</td>
</tr>
<tr>
<td style="height:17px; width:140px">elewîtîye</td>
<td style="height:17px; width:340px">alevîtîye</td>
<td style="height:17px; width:104px">Alevilik</td>
</tr>
<tr>
<td style="height:17px; width:140px">elewîyênî</td>
<td style="height:17px; width:340px">alevîyênî</td>
<td style="height:17px; width:104px">Alevilik</td>
</tr>
<tr>
<td style="height:34px; width:140px">muslumanî</td>
<td style="height:34px; width:340px">misilmanê, mislumanê, muslumanê, muslumonê, muslumûnê, bisilmûnê</td>
<td style="height:34px; width:104px">Müslümanlık</td>
</tr>
<tr>
<td style="height:67px; width:140px">muslumanîye</td>
<td style="height:67px; width:340px">misilmanîye, misilmaney, mislimaney, mislumaney, muslimaney, muslumaney, muslumoney, muslumûney, bisilmaney, bisilmaneyey, bisilmoney,<br>
bisilmûneyê</td>
<td style="height:67px; width:104px">Müslümanlık</td>
</tr>
<tr>
<td style="height:17px; width:140px">muslumanênî</td>
<td style="height:17px; width:340px">muslumanenî</td>
<td style="height:17px; width:104px">Müslümanlık</td>
</tr>
<tr>
<td style="height:17px; width:140px">neqşîbendîyî</td>
<td style="height:17px; width:340px">neqşîbendîyê</td>
<td style="height:17px; width:104px">Nakşibendilik</td>
</tr>
<tr>
<td style="height:17px; width:140px">neqşîbendîyîye</td>
<td style="height:17px; width:340px">neqşîbendîyey</td>
<td style="height:17px; width:104px">Nakşibendilik</td>
</tr>
<tr>
<td style="height:17px; width:140px">neqşîbendîtî</td>
<td style="height:17px; width:340px">neqşîbendîtê</td>
<td style="height:17px; width:104px">Nakşibendilik</td>
</tr>
<tr>
<td style="height:17px; width:140px">sunîyî</td>
<td style="height:17px; width:340px">sunnîyî, sunîyê, sinîyê</td>
<td style="height:17px; width:104px">Sünnilik</td>
</tr>
<tr>
<td style="height:17px; width:140px">sunîyîye</td>
<td style="height:17px; width:340px">sunnîyîye, sunnîyey, sunîyey</td>
<td style="height:17px; width:104px">Sünnilik</td>
</tr>
<tr>
<td style="height:17px; width:140px">sunîyênî</td>
<td style="height:17px; width:340px">sunîyênî</td>
<td style="height:17px; width:104px">Sünnilik</td>
</tr>
<tr>
<td style="height:17px; width:140px">sunîtî</td>
<td style="height:17px; width:340px">sunnîtî</td>
<td style="height:17px; width:104px">Sünnilik</td>
</tr>
<tr>
<td style="height:17px; width:140px">sunîtîye</td>
<td style="height:17px; width:340px">sunnîtîye</td>
<td style="height:17px; width:104px">Sünnilik</td>
</tr>
<tr>
<td style="height:17px; width:140px">xirîstîyanî</td>
<td style="height:17px; width:340px">xirîstîyanê</td>
<td style="height:17px; width:104px">Hristiyanlık</td>
</tr>
<tr>
<td style="height:17px; width:140px">xirîstîyanîye</td>
<td style="height:17px; width:340px">xirîstîyaney</td>
<td style="height:17px; width:104px">Hristiyanlık</td>
</tr>
<tr>
<td style="height:17px; width:140px">fileyî</td>
<td style="height:17px; width:340px">fileyê</td>
<td style="height:17px; width:104px">Hristiyanlık</td>
</tr>
<tr>
<td style="height:17px; width:140px">fileyîye</td>
<td style="height:17px; width:340px">fileyeyey</td>
<td style="height:17px; width:104px">Hristiyanlık</td>
</tr>
</tbody>
</table>
<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;
<h1>2.8.Nameyê Mensûbanê Miletan</h1><br>
Nameyê mensûbanê miletan bi herfa hurdîye dest pêkenê. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:66px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">alman, -e</td>
<td style="height:17px; width:66px">Alman</td>
</tr>
<tr>
<td style="height:17px; width:140px">ereb, -e</td>
<td style="height:17px; width:66px">Arap</td>
</tr>
<tr>
<td style="height:17px; width:140px">faris, -e</td>
<td style="height:17px; width:66px">Fars</td>
</tr>
<tr>
<td style="height:17px; width:140px">kurd, -e</td>
<td style="height:17px; width:66px">Kürt</td>
</tr>
<tr>
<td style="height:17px; width:140px">tirk, -e</td>
<td style="height:17px; width:66px">Türk</td>
</tr>
</tbody>
</table>
Name, sifet û zerfê ke nameyanê miletan ra virazîyenê zî bi herfa hurdîye dest pêkenê. Nimûneyî: Kêneka <strong>k</strong>urde <strong>kurdkî </strong>wend, kêneka <strong>t</strong>irke <strong>tirkî </strong>wend.<br>
Tayê <strong>k</strong>urdê <strong>kurdperwerî </strong>ewro zîndanan de yê.<br>
Gelek unîversîteyan de <strong>îranolojî </strong>est o labelê <strong>kurdolojî </strong>çin o.<br>
&nbsp;
<h1>2.9.Nameyê Mensûbanê Dewan, Bajaran (Şaristanan), Mintiqayan, Dewletan û Qitayan </h1>

<p>Nameyê mensûbanê dewan, bajaran, mintiqayan, dewletan û qitayan bi herfa hurdîye dest pêkenê. Nimûneyî:</p>

&nbsp;

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:170px"><strong>formê bînî</strong></td>
<td style="height:17px; width:85px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">afrîkayij</td>
<td style="height:17px; width:170px">afrîkayic, afrîkayiz</td>
<td style="height:17px; width:85px">Afrikalı</td>
</tr>
<tr>
<td style="height:17px; width:140px">çewlîgij</td>
<td style="height:17px; width:170px">çolîgic, çewlîgic, çewlîgiz</td>
<td style="height:17px; width:85px">Bingöllü</td>
</tr>
<tr>
<td style="height:17px; width:140px">dêrsimij</td>
<td style="height:17px; width:170px">dêrsimic, dêrsimiz</td>
<td style="height:17px; width:85px">Dersimli</td>
</tr>
<tr>
<td style="height:17px; width:140px">gurcîstanij</td>
<td style="height:17px; width:170px">gurcîstanic, gurcîstaniz</td>
<td style="height:17px; width:85px">Gürcistanlı</td>
</tr>
<tr>
<td style="height:17px; width:140px">hezanij</td>
<td style="height:17px; width:170px">hezanic, hezaniz</td>
<td style="height:17px; width:85px">Hezanlı</td>
</tr>
<tr>
<td style="height:17px; width:140px">îranij</td>
<td style="height:17px; width:170px">îranic, îraniz</td>
<td style="height:17px; width:85px">İranlı</td>
</tr>
<tr>
<td style="height:17px; width:140px">pîranij</td>
<td style="height:17px; width:170px">pîranic, pîraniz</td>
<td style="height:17px; width:85px">Piranlı</td>
</tr>
<tr>
<td style="height:17px; width:140px">sêwregij</td>
<td style="height:17px; width:170px">soyrekij, sêwrekic</td>
<td style="height:17px; width:85px">Siverekli</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;<br>
Gedeyîya mi de mûndîya <strong>lawure(1) </strong>Pîran de biqîymet bî.<br>
Ma het camêrdan di tewirî şalwarê sîyayî xo pay kerdinê. Tewiro yew qeremendû, o bîn zî<br>
<strong>awrûpa </strong>bi; <strong>awrûpa </strong>hîna vay bi.<br>
Sifet û zerfê ke nameyanê dewan, bajaran, mintiqayan, dewletan û qitayan ra virazîyenê zî bi herfa hurdîye dest pêkenê.<br>
&nbsp;
<h1>2.10.Nameyê Kesan yê Xerîban</h1><br>

<ol>
<li>Nameyê kesan yê xerîban ke orîjînalê xo bi alfabeya latînkî nusîyenê -mavajî nameyê ewropayijan û amerîkayijan-, sey orîjînalê nê nameyan nusîyenê, telefuzê înan parantezan mîyan de nusîyenê. Nimûneyî:</li>
</ol>
René Descartes (Rone Dekart), Shakespeare (Şekspîr), George Bush (Corc Bûş).

<ol>
<li>Nameyê kesan yê orisan, ereban, farisan... ke orîjînalê xo bi alfabeya krîlkî, erebkî/fariskî... nusîyenê, sey telefuzê nê nameyan nusîyenê. Nimûneyî:</li>
</ol>
Gorbaçov, Îbnî Xeldûn, Sedîyê Şîrazî.

<ol>
<li>Nameyê kesan yê muslumanan û mensûbanê şaranê Rojhelatê Mîyanênî (Ortadoğu), sey telefuzbîyayîşê nê nameyan nusîyenê. Nimûneyî:</li>
</ol>
Hafiz Esed, Seddam Huseyn, Mehmûd Derwîş, Xumeynî, Muhemmed Îqbal.

<ol>
<li>Eke îmkanê nuştena orîjînalê nameyan yê xerîban -hetê teknîkî ra- çin bo, herfê nêzdîyê nê herfanê xerîban tercîh benê. Mavajî cayê “Kungsträdgården”î -ke nameyêko swêdkî (îsveçkî) yo- de merdim şêno (bese keno) binuso “Kungstradgarden”. Çunke herfê “ä” û “å”yî kurdkî de çin ê û beno ke</li>
</ol>
<br>
&nbsp;<br>
klavyeyê kurdan de zî çin bê.<br>
&nbsp;
<h1>2.11.Tayê Nameyê ke bi Herfa Girde Dest Pêkenê</h1><br>

<ol>
<li>Eke unwan yan zî rutbe sey nameyê taybetî (özel ad) vajîyo, yanî bibo parçeyê nameyê merdimî, bi herfa girde dest pêkeno. Eke winî nêbo û tena semedê bellîkerdişê mesleg yan zî meqamê merdimî vajîyo, bi herfa hurdîye dest pêkeno. Nimûneyî:</li>
</ol>
mîreyê Gêlî, begê kurdan, axayê dewa şima, melayê dewa ma, şêxê neqşîbendîyan, babayo dêrsimij, pîrê elewîyan, mudirê nahîye, mudiro umûmî Evdila Beg, qaymeqamê Xozatî, walîyo newe, bînbaşîyo zalim, albayê tirkan, qumandarê cendirmeyan, qumandarê alayîye Elî Heyder, Elî Heydero qumandarê alayîye.<br>
Qaymeqam û mudirê nahîye ameyî dewe. Kumandanê alayîye û walîyê Xarpêtî ameyî dewe.<br>
Bi herfa girde nimûneyî: Seyîd Riza, Şêx Seîd, Mela Mistefa Barzanî, Mela Ehmedê Xasî, Cemşîd Axa, Hesen Beg, Mistefa Başçawuş.
<ol>
<li>Her çekuya nameyê cografîkî yê taybetî bi herfa girde dest pêkena. Nimûneyî: Koyê Munzurî, Deşta Dîyarbekirî, Royê Muradî, Çemê Dîcle.</li>
<li>Nameyê cîhetî (rojawan, vakur ûêb) eke sey nameyê taybetî vajîyo, yanî bibo parçeyê nameyê cayêkî, bi herfa girde dest pêkeno. Nimûneyî:</li>
</ol>
Kurdîstanê Başûrî, Koreya Vakurî, Afrîkaya Başûrî.<br>
Labelê eke nameyê cîhetî sey nameyê taybetî ney, tena seba îfadekerdişê cîhetî vajîyo, bi herfa hurdîye dest pêkeno. Nimûneyî:<br>
Vewre û varan hetê vakurê Kurdîstanî de zaf, başûrê Kurdîstanî de tay varenê. Îraq û Sûrîye başûrê Kurdîstanî de, Îran rojhelatê Kurdîstanî de yo.
<ol>
<li>Herfa verêne ya her çekuya nameyanê sazgeh û dezgehanê taybetîyan gird nusîyena. Nimûneyî: Enstîtuyê Kurdî yê Parîsî, Federasyona Komeleyanê Kurdîstanî, Kitabxaneyê Selahedînê Eyûbî. <strong>5)</strong>Herfa verêne ya her çekuya nameyê bîna ya taybetî gird nusîyena. Nimûneyî:</li>
</ol>
Koşka Cemşîd Begî, Kitabxaneyê Unîversîteya Dîcle, Berajê Kebanî.<br>
&nbsp;
<h1>2.12.Nameyê Kitaban, Nuşteyan (Yazîyan) û Notanê Bîblîyografîkan</h1><br>
Her herfa verêne ya her çekuya kitab yan zî nuşteyan (yazîyan) gird nusîyena labelê nê nameyan de herfa verêne ya edatan û bestoxan (bağlaçlar) gird binusîyo zî beno, hurdî binusîyo zî beno. Mavajî nameyê kitaban ê Munzur Çem, J. Îhsan Espar û Qemerê Alî wina nusîyenê: Hotay Serra Usifê Qurzkizî, Tanî Estanikî û Deyîrê Ma, Adir û Asme.<br>
Notê bîblîyografikî heme welatan de bi eynî ûsulî nênusîyenê. No xusus de standardê cîya-cîyayî est ê. Kombîyayîşî munasib dî ke notanê bîblîyografîkan de bi rêze (bi sira) nê malumatî est bê:<br>
1-Nameyê peyên yê nuştoxî (yazarın soyadı), 2-nameyê nuştoxî, 3-nameyê kitabî, 4-nameyê weşanxaneyî (yayınevinin adı), 5-nameyê şaristanî yo ke weşanxane tede yo (yayınevinin bulunduğu şehrin adı), 6-serra neşrbîyayîşî (yayım tarihi), 7-rîpelo ke ey ra îstîfade bîyo.<br>
Beyntarê (mabênê) nê malumatan hemîne de vîrgul est o. Nimûne:<br>
Çem, Mûnzûr, Hotay Serra Usifê Qurzkizî, Weşanên Roja Nû, Stokholm, 1992, r. 124.<br>
&nbsp;<br>
Eke cayê kitabî de nuşteyê bînî (meqale ûsn) bibîy, wina nusîyenê:<br>
1-Nameyê peyên yê nuştoxî (yazarın soyadı), 2-nameyê nuştoxî, 3-“nameyê nuşteyî”, 4-nameyê kovare yan zî rojnameyî (dergi veya gazetenin adı), 5-hûmara rojnameyî yan zi kovare (gazete veya derginin sayısı), 6-tarîxê rojnameyî yan zî kovare, 7-nameyê şaristanî yo ke rojname yan zî kovare tede neşr bîya (gazete veya derginin yayınlandığı şehrin adı), 8-rîpelo ke ey ra îstîfade bîyo.<br>
Beyntarê nê malumatan hemîne de vîrgul est o û nameyê nuşteyî bi xo îşaretê çengalekî (tırnak işareti) mîyan de yo. Nimûne:<br>
Diljen, Haydar, “Programandê Partîyandê Kurdan di Persa Ziwanî”, Vate: kovara kulturî, hûmare: 4, wisar 1998, Stokholm, r. 19.<br>
&nbsp;
<h1>2.13.Nuştişê Tarîxî yê bi Roje, Menge (Aşme) û Serre</h1><br>
Tayê kesî nuştişê tarîxan de nîşanê tîre (-) yan zî apostrofî (’) xebitnenê. Goreyê meylê kombîyayîşî, ganî tarîxî bê nê îşaretan binusîyê.<br>
Nimûneyî:<br>
<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:236px"><strong>formê bînî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">25<strong>ê </strong>aşma nîsane</td>
<td style="height:17px; width:236px">25-ê aşma nîsane, 25’ê aşma Nîsane</td>
</tr>
<tr>
<td style="height:17px; width:140px">1<strong>ê </strong>gulane</td>
<td style="height:17px; width:236px">1-ê gulane, 1’ê Gulane</td>
</tr>
<tr>
<td style="height:17px; width:140px">17<strong>ê </strong>temmuze</td>
<td style="height:17px; width:236px">17-ê temmuze, 17’ê Temmuze</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;<br>
Tarîxo bi roje, menge û serre zî sey nimûneya cêrêne nusîyeno:
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:208px"><strong>formê bînî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">03<strong>.</strong>04<strong>.</strong>1938</td>
<td style="height:17px; width:208px">3.4.1938, 03-04-1938, 3/4/1938</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>2.14.Sifetî ra Nameviraştiş</h1><br>
Kirmanckî de, peygirê sifetî ra nameviraştişî goreyê fekan vurîyeno. No peygir, tayê fekan de <strong>“- îye”</strong>, <strong>“-î”</strong>, <strong>“-ey” </strong>yan zî <strong>“-ê” </strong>yo. Kombîyayîşê ma nê forman ra <strong>“-îye” </strong>tercîh kerd. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:170px"><strong>formê bînî</strong></td>
<td style="height:17px; width:85px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>-îye</strong></td>
<td style="height:17px; width:170px"><strong>-ey, -ê</strong></td>
<td style="height:17px; width:85px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:140px">azad<strong>îye</strong></td>
<td style="height:17px; width:170px">azad<strong>î</strong>, azad<strong>ey</strong>, azad<strong>ê</strong></td>
<td style="height:17px; width:85px">özgürlük</td>
</tr>
<tr>
<td style="height:17px; width:140px">bira<strong>yîye</strong></td>
<td style="height:17px; width:170px">bira<strong>yî</strong>, bira<strong>yey</strong>, bira<strong>yê</strong></td>
<td style="height:17px; width:85px">kardeşlik</td>
</tr>
<tr>
<td style="height:17px; width:140px">xoser<strong>îye</strong></td>
<td style="height:17px; width:170px">xoser<strong>î</strong>, xoser<strong>ey</strong>, xoser<strong>ê</strong></td>
<td style="height:17px; width:85px">bağımsızlık</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>2.15.Çekuyê Anteyî (Bükünlü Sözcükler)</h1><br>
Eke mîyanê cumle de mabênê çend çekuyan de “<strong>û</strong>” est bo, tena çekuya peyêne bi halê anteyî (oblîkî) nusîyena, çekuyê bînî bi halê xoserî (yalın) nusîyenê. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:225px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:340px"><strong>formê bînî</strong></td>
</tr>
<tr>
<td style="height:17px; width:225px">Şarê Dêrsim û Erzingan<strong>î </strong>koç kerd.</td>
<td style="height:17px; width:340px">Şarê Dêrsim<strong>î </strong>û <strong>şarê </strong>Erzingan<strong>î </strong>koç kerd.</td>
</tr>
<tr>
<td style="height:17px; width:225px">Xal û dat<strong>î </strong>ey ra va.</td>
<td style="height:17px; width:340px">Xal<strong>î </strong>û dat<strong>î </strong>ey ra va.</td>
</tr>
<tr>
<td style="height:17px; width:225px">Mi Kajîn û Mîran<strong>î ra </strong>va.</td>
<td style="height:17px; width:340px">Mi Kajîn<strong>î </strong>û Mîran<strong>î ra </strong>va./Mi Kajîn<strong>î ra </strong>û Mîran<strong>î ra </strong>va.</td>
</tr>
<tr>
<td style="height:17px; width:225px">Pancês kêneke û lajek<strong>î </strong>ameyî.</td>
<td style="height:17px; width:340px">Pancês kênek<strong>î </strong>û lajek<strong>î </strong>ameyî.</td>
</tr>
<tr>
<td style="height:17px; width:225px">Waye û bira<strong>yê to </strong>ewro ameyî.</td>
<td style="height:17px; width:340px">Way<strong>a to </strong>û bira<strong>yê to </strong>ewro ameyî.</td>
</tr>
<tr>
<td style="height:17px; width:225px">Yew waye û di bira<strong>yî </strong>ewro ameyî.</td>
<td style="height:17px; width:340px">Yew wa<strong>y </strong>û di bira<strong>y </strong>ewro ameyî.</td>
</tr>
<tr>
<td style="height:17px; width:225px">Di wa<strong>yî </strong>û yew bira ewro ameyî.</td>
<td style="height:17px; width:340px">Di wa<strong>y </strong>û yew bira ewro ameyî.</td>
</tr>
<tr>
<td style="height:17px; width:225px">Di wa<strong>yî </strong>û di bira<strong>yî </strong>ewro ameyî.</td>
<td style="height:17px; width:340px">Di wa<strong>y </strong>û di bira<strong>y </strong>ewro ameyî.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Nimûneyanê corênan ra nimûneya verêne de, beyntarê “şarê Dêrsimî” û “Erzinganî” de “<strong>û</strong>” est o. No “<strong>û</strong>”, sey bestoxî (bağlaç) “şarê Dêrsimî” û “Erzinganî” pêra besteno. Semedê naye ra “<strong>-î</strong>”ya “Dêrsim<strong>î</strong>” û çekuya “şarê” newe ra -Erzinganî ra ver- nênusîyo zî beno. Çunke tîya de “<strong>-î</strong>”ya “Erzinganî”, “<strong>-î</strong>”ya “Dêrsimî” zî temsîl kena.<br>
&nbsp;
<h1>2.16.Nêancîyayîşê Çekuya Makî ya ke bi “-e” Yan zî “-î” Qedîyena</h1><br>
Çekuya makî ya ke bi “<strong>-e</strong>” yan zî “<strong>-î</strong>” qedîyena, cumle de nêancîyena, yanî peygirê “<strong>-e</strong>”/“<strong>-ye</strong>”yî nêgêna, halê xoserî (yalın durum) de nusîyêna.<br>
Nimûne: gul<strong>e </strong>(m), aw<strong>e </strong>(m), kard<strong>î </strong>(m).<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:102px"><strong>rast/raşt</strong></td>
<td style="height:17px; width:189px"><strong>xelet (şaş)</strong></td>
<td style="height:17px; width:113px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:102px">Gul<strong>e </strong>bide mi.</td>
<td style="height:17px; width:189px">Gul<strong>eye </strong>bide mi.</td>
<td style="height:17px; width:113px">Gülü bana ver.</td>
</tr>
<tr>
<td style="height:17px; width:102px">Aw<strong>e </strong>bide mi.</td>
<td style="height:17px; width:189px">Aw<strong>eye </strong>bide mi./Aw bide mi.</td>
<td style="height:17px; width:113px">Suyu bana ver.</td>
</tr>
<tr>
<td style="height:17px; width:102px">Kard<strong>î </strong>bide mi.</td>
<td style="height:17px; width:189px">Kard<strong>îye </strong>bide mi.</td>
<td style="height:17px; width:113px">Bıçağı bana ver.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Labelê çekuya nêrî ya ke bi “<strong>-e</strong>” yan zî “<strong>-î</strong>” qedîyena, cumle de ancîyena, yanî halê anteyî (oblîkî)<br>
<br>
&nbsp;<br>
gêna û no “<strong>-e</strong>” yan zî “<strong>-î</strong>”yê peynîya çekuye sey xo maneno.<br>
Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:73px"><strong>çekuye</strong></td>
<td style="height:17px; width:132px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:95px"><strong>formê bînî</strong></td>
<td style="height:17px; width:123px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:73px">gul<strong>e </strong>(n)</td>
<td style="height:17px; width:132px">Gul<strong>e</strong>yî bide mi.</td>
<td style="height:17px; width:95px">Gul<strong>î </strong>bide mi.</td>
<td style="height:17px; width:123px">Gülleyi bana ver.</td>
</tr>
<tr>
<td style="height:17px; width:73px">mas<strong>e </strong>(n)</td>
<td style="height:17px; width:132px">Mas<strong>e</strong>yî mekişe!</td>
<td style="height:17px; width:95px">Mas<strong>î </strong>mekişe!</td>
<td style="height:17px; width:123px">Balığı öldürme!</td>
</tr>
<tr>
<td style="height:17px; width:73px">p<strong>î </strong>(n)</td>
<td style="height:17px; width:132px">P<strong>î</strong>yî ra vato.</td>
<td style="height:17px; width:95px">P<strong>î </strong>ra vato.</td>
<td style="height:17px; width:123px">Babaya söylemiş.</td>
</tr>
<tr>
<td style="height:17px; width:73px">ç<strong>î </strong>(n)</td>
<td style="height:17px; width:132px">Nê ç<strong>î</strong>yî bere.</td>
<td style="height:17px; width:95px">Nê ç<strong>î </strong>bere.</td>
<td style="height:17px; width:123px">Bu şeyi götür.</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>2.17.Name û Sifetê Makî</h1><br>
Kirmanckî de name û sifetê makî yê ke tayê fekan de bi “<strong>-e</strong>” qedîyenê, tayê fekan de bi “<strong>-i</strong>”, tayê fekanê bînan de zî bê “-e” û bê “-i” vajîyenê. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:170px"><strong>fekê bînî</strong></td>
<td style="height:17px; width:161px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>-e</strong></td>
<td style="height:17px; width:170px"><strong>-i</strong></td>
<td style="height:17px; width:161px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:140px">dewlet<strong>e</strong></td>
<td style="height:17px; width:170px">dewlet<strong>i</strong>, dewlet</td>
<td style="height:17px; width:161px">devlet</td>
</tr>
<tr>
<td style="height:17px; width:140px">dişmen<strong>e</strong></td>
<td style="height:17px; width:170px">dismen<strong>e</strong>, dişmen<strong>i</strong>, dişmen</td>
<td style="height:17px; width:161px">düşman (kadın veya kız)</td>
</tr>
<tr>
<td style="height:17px; width:140px">zerd<strong>e</strong></td>
<td style="height:17px; width:170px">zerd<strong>i</strong>, zerd</td>
<td style="height:17px; width:161px">sarı</td>
</tr>
<tr>
<td style="height:17px; width:140px">pîl<strong>e</strong></td>
<td style="height:17px; width:170px">pîl<strong>i</strong>, pil</td>
<td style="height:17px; width:161px">(yaşça) büyük</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>2.17.1.Nameyê Makî yê ke bi “-e” (“-i”) Qedîyenê û Kopula</h1><br>
Nameyê makî yê ke xoser bi herfa “<strong>-e</strong>” yan zî “<strong>-i</strong>” qedîyenê, gama ke mîyanê cumle de kopula ra ver bêrê, na herfa xo ya peyêne (yanî “<strong>-e</strong>” yan zî “<strong>-i</strong>”) nêvajîyena û nênusîyena. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:149px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:180px"><strong>formê bînî</strong></td>
<td style="height:17px; width:161px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:149px">Na da<strong>r </strong>a.</td>
<td style="height:17px; width:180px">Na dar<strong>e </strong>a./Na dar<strong>i </strong>ya.</td>
<td style="height:17px; width:161px">Bu ağaçtır.</td>
</tr>
<tr>
<td style="height:17px; width:149px">Na qele<strong>m </strong>a.</td>
<td style="height:17px; width:180px">Na qelem<strong>e </strong>a./Na qelem<strong>i </strong>ya.</td>
<td style="height:17px; width:161px">Bu kalemdir.</td>
</tr>
<tr>
<td style="height:17px; width:149px">Na qelema Sorgu<strong>l </strong>a.</td>
<td style="height:17px; width:180px">Na qelema Sorgul<strong>e </strong>ya.</td>
<td style="height:17px; width:161px">Bu Sorgul’ün kalemidir.</td>
</tr>
<tr>
<td style="height:17px; width:149px">No kitabê Bêrîwa<strong>n </strong>o.</td>
<td style="height:17px; width:180px">No kitabê Bêrîwan<strong>e </strong>yo.</td>
<td style="height:17px; width:161px">Bu Bêrîwan’ın kitabıdır.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Nê nimûneyan ra “dar”, “qelem”, “Sorgul” û “Bêrîwan” gama ke xoser ê, bi “<strong>-e</strong>” yan zî “<strong>-i</strong>” vajîyenê (dare/dari, qeleme/qelemi, Sorgule/Sorguli, Bêrîwane/Bêrîwani). Labelê mîyanê cumle de, eke nê çekuyan dima kopula ameye, di herfê venginî (iki sesli harf) pêdima yenê û semedo ke vatişê xo zor o, nê venginan ra vengina verêne, yanî “<strong>-e</strong>” yan zî “<strong>-i</strong>” kewena. Mavajî cumleya “Na dar<strong>e a</strong>” yan zî “Na dar<strong>i a</strong>” de “<strong>-e</strong>” yan zî “<strong>-i</strong>”ya “dar<strong>e</strong>”/“dar<strong>i</strong>” kewta, cumle bîya “Na da<strong>r </strong>a”. (Seke ma cor ra zî nuşt, tayê fekan de, gama ke xoser bibî zî peynîya nê çekuyanê makîyan de “<strong>-e</strong>” û “<strong>-i</strong>” çin ê.)<br>
&nbsp;<br>
&nbsp;
<h1>2.18.Teql û Manaya Çekuyan</h1><br>
Kirmanckî de tayê çekuyê ke hemveng (eş sesli) ê la mana û cinsîyetê înan cîya yê est ê. Nimûne: “<strong>veyve</strong>”. Na çekuye hem manaya “<strong>gelin</strong>” hem zî “<strong>düğün</strong>”ê tirkî dana. Eke teql (vurgu, stress) <strong>heceya yewine </strong>ser o bo na çekuye manaya“gelin”, eke <strong>dîyine </strong>ser o bo manaya “düğün”î dana.<br>
&nbsp;<br>
Çendna nimûneyî:
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>teql (vurgu, stress)</strong></td>
<td style="height:17px; width:47px"><strong>tirkî</strong></td>
<td style="height:17px; width:19px">&nbsp;</td>
<td style="height:17px; width:132px"><strong>teql (vurgu, stress)</strong></td>
<td style="height:17px; width:208px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:34px; width:140px"><strong>vey</strong>ve/<strong>vey</strong>vi (m)<br>
<strong>vê</strong>we/<strong>vê</strong>wi (m)</td>
<td style="height:34px; width:47px">gelin</td>
<td style="height:34px; width:19px">&nbsp;</td>
<td style="height:34px; width:132px">vey<strong>ve</strong>/vey<strong>vi </strong>(n)</td>
<td style="height:34px; width:208px">düğün</td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>ro</strong>je/<strong>ro</strong>ze (m)</td>
<td style="height:17px; width:47px">gün</td>
<td style="height:17px; width:19px">&nbsp;</td>
<td style="height:17px; width:132px">ro<strong>je </strong>(n)</td>
<td style="height:17px; width:208px">oruç</td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>gu</strong>le/<strong>gu</strong>li (m)</td>
<td style="height:17px; width:47px">gül</td>
<td style="height:17px; width:19px">&nbsp;</td>
<td style="height:17px; width:132px">gu<strong>le/</strong>gu<strong>li </strong>(n)</td>
<td style="height:17px; width:208px">gülle, mermi</td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>vi</strong>le/<strong>vi</strong>li (m)</td>
<td style="height:17px; width:47px">çiçek</td>
<td style="height:17px; width:19px">&nbsp;</td>
<td style="height:17px; width:132px">vi<strong>le </strong>(n)</td>
<td style="height:17px; width:208px">boyun</td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>do</strong>re/<strong>do</strong>ri (m)</td>
<td style="height:17px; width:47px">küp</td>
<td style="height:17px; width:19px">&nbsp;</td>
<td style="height:17px; width:132px">do<strong>re/</strong>do<strong>ri </strong>(n)</td>
<td style="height:17px; width:208px">testi</td>
</tr>
</tbody>
</table>
<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
Nimûneyê corênî çekuyê hemveng û diheceyin (iki heceli) ê. Qaydeyo corên seba înan o.<br>
Çekuyê diheceyinê ke nêrî yê, eke hemveng nêbê zî zafê zafan tede teql heceya dîyine ser o yo. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:142px"><strong>teql (vurgu, stress)</strong></td>
<td style="height:17px; width:123px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:142px">baw<strong>kal </strong>(n)</td>
<td style="height:17px; width:123px">dede</td>
</tr>
<tr>
<td style="height:17px; width:142px">bi<strong>zêk </strong>(n)</td>
<td style="height:17px; width:123px">oğlak</td>
</tr>
<tr>
<td style="height:17px; width:142px">es<strong>te</strong>/es<strong>ti </strong>(n)</td>
<td style="height:17px; width:123px">kemik</td>
</tr>
<tr>
<td style="height:17px; width:142px">ge<strong>re</strong>/ge<strong>ri </strong>(n)</td>
<td style="height:17px; width:123px">şikayet</td>
</tr>
<tr>
<td style="height:17px; width:142px">go<strong>lik </strong>(n)</td>
<td style="height:17px; width:123px">buzağı</td>
</tr>
<tr>
<td style="height:17px; width:142px">ka<strong>te</strong>/ka<strong>ti </strong>(n)</td>
<td style="height:17px; width:123px">kemik</td>
</tr>
<tr>
<td style="height:17px; width:142px">ker<strong>diş</strong>/kar<strong>diş </strong>(n)</td>
<td style="height:17px; width:123px">yapma</td>
</tr>
<tr>
<td style="height:17px; width:142px">me<strong>ru</strong>/me<strong>ri </strong>(n)</td>
<td style="height:17px; width:123px">testi</td>
</tr>
<tr>
<td style="height:17px; width:142px">nam<strong>dar </strong>(n)</td>
<td style="height:17px; width:123px">ünlü, tanınmış</td>
</tr>
<tr>
<td style="height:17px; width:142px">şe<strong>ker </strong>(n)</td>
<td style="height:17px; width:123px">şeker</td>
</tr>
<tr>
<td style="height:17px; width:142px">va<strong>te</strong>/va<strong>ti </strong>(n)</td>
<td style="height:17px; width:123px">söz</td>
</tr>
<tr>
<td style="height:17px; width:142px">va<strong>tiş </strong>(n)</td>
<td style="height:17px; width:123px">söyleme</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>2.19.Bi “-î” Zafhûmarbîyayîşê Nameyan</h1><br>
Kirmanckî de peygiranê zafhûmarîye ra yew zî “<strong>-î</strong>” yo. Eke name bi herfa bêvenge (sessiz harf) biqedîyo, bi nê “<strong>-î</strong>”yî beno zafhûmar.<br>
&nbsp;<br>
Nimûneyî:
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:92px"><strong>yewhûmar</strong></td>
<td style="height:17px; width:142px"><strong>zafhûmar</strong></td>
</tr>
<tr>
<td style="height:17px; width:92px">dest</td>
<td style="height:17px; width:142px">Dest<strong>î </strong>berz kerdî.</td>
</tr>
<tr>
<td style="height:17px; width:92px">xort</td>
<td style="height:17px; width:142px">Xort<strong>î </strong>ha yenê.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Eke name bi herfanê venginan yê sey “<strong>-a</strong>”, “<strong>-ê</strong>”, “<strong>-o</strong>”, “<strong>-u</strong>” “<strong>-û</strong>”yî biqedîyo, tayê fekan de no “<strong>- î</strong>”yê zafhûmarîye nêvajîyeno. Mavajî gama ke çekuya “ga”yî bena zafhûmare, bena “g<strong>a </strong>+ <strong>î</strong>”, labelê semedo ke di herfê venginî ameyê têhet, herfa pêragirêdayene dekewena mîyanê nê di herfanê bêvengan û çekuye bena “g<strong>ayî</strong>”. Tayê fekan de semedê asaneya telefuzî ra “<strong>-î</strong>”yo peyên vîndî beno û no name bê “<strong>-î</strong>”yî vajîyeno, beno “ga<strong>y</strong>”. Herçiqas ke ziwanê qiseykerdişî de tayê fekan de wina bibo zî ziwanê nuştişî (yazı dili) de ganî no “<strong>-î</strong>” binusîyo. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:83px"><strong>yewhûmar</strong></td>
<td style="height:17px; width:208px"><strong>zafhûmar, meylê kombîyayîşî</strong></td>
<td style="height:17px; width:199px"><strong>zafhûmar, formê bînî</strong></td>
</tr>
<tr>
<td style="height:17px; width:83px">g<strong>a</strong></td>
<td style="height:17px; width:208px">Ga<strong>yî </strong>vaş wenê.</td>
<td style="height:17px; width:199px">Ga<strong>y </strong>vaş wenê.</td>
</tr>
<tr>
<td style="height:17px; width:83px">g<strong>a</strong></td>
<td style="height:17px; width:208px">Mi ga<strong>yî </strong>ardî.</td>
<td style="height:17px; width:199px">Mi ga<strong>y </strong>ardî.</td>
</tr>
<tr>
<td style="height:17px; width:83px">bir<strong>a</strong></td>
<td style="height:17px; width:208px">Di bira<strong>yî </strong>şonê uca.</td>
<td style="height:17px; width:199px">Di bira<strong>y </strong>şonê uca</td>
</tr>
<tr>
<td style="height:17px; width:83px">hêg<strong>a</strong></td>
<td style="height:17px; width:208px">Cityeran/citkaran hêga<strong>yî </strong>ramitî.</td>
<td style="height:17px; width:199px">Citêran/citkaran hêga<strong>y </strong>ramitî.</td>
</tr>
<tr>
<td style="height:17px; width:83px">k<strong>o</strong></td>
<td style="height:17px; width:208px">Dêrsim de ko<strong>yî </strong>berz ê.</td>
<td style="height:17px; width:199px">Dêrsim de ko<strong>y </strong>berz î.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Nameyê nêrî yê ke bi “<strong>-e</strong>” qedîyenê zî goreyê nê qaydeyê corênî “<strong>-î</strong>” gênê. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:83px"><strong>yewhûmar</strong></td>
<td style="height:17px; width:208px"><strong>zafhûmar, meylê kombîyayîşî</strong></td>
<td style="height:17px; width:151px"><strong>zafhûmar, formê bînî</strong></td>
</tr>
<tr>
<td style="height:17px; width:83px">ser<strong>e</strong></td>
<td style="height:17px; width:208px">Di sere<strong>yî </strong>asenê.</td>
<td style="height:17px; width:151px">Di sere<strong>y </strong>asenê.</td>
</tr>
<tr>
<td style="height:17px; width:83px">xel<strong>e</strong></td>
<td style="height:17px; width:208px">Ma xele<strong>yî </strong>çînay.</td>
<td style="height:17px; width:151px">Ma xele<strong>y </strong>çînay.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Labelê nameyê makî yê ke bi “<strong>-e</strong>” yan zî “<strong>-i</strong>” qedîyenê, gama ke “<strong>-î</strong>”yê zafhûmarîye bigîrî, no “<strong>-</strong><br>
<br>
&nbsp;<br>
<strong>e</strong>” yan zî “<strong>-i</strong>”yê xo yo peyên vîndî beno. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:83px"><strong>yewhûmar</strong></td>
<td style="height:17px; width:208px"><strong>zafhûmar, meylê kombîyayîşî</strong></td>
</tr>
<tr>
<td style="height:17px; width:83px">biz<strong>e</strong>, biz<strong>i</strong></td>
<td style="height:17px; width:208px">To biz<strong>î </strong>berdî.</td>
</tr>
<tr>
<td style="height:17px; width:83px">dar<strong>e</strong>, dar<strong>i</strong></td>
<td style="height:17px; width:208px">To dar<strong>î </strong>birnayî.</td>
</tr>
<tr>
<td style="height:17px; width:83px">dew<strong>e</strong>, dew<strong>i</strong></td>
<td style="height:17px; width:208px">Kamî nê dew<strong>î </strong>veşnayî?</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Tayê fekan de zî nê tewir çekuyê makî bi herfa bêvenge qedîyenê û zafhûmarîye de “<strong>-î</strong>”yî gênê.<br>
Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:83px"><strong>yewhûmar</strong></td>
<td style="height:17px; width:208px"><strong>zafhûmar, meylê kombîyayîşî</strong></td>
</tr>
<tr>
<td style="height:17px; width:83px">biz (m)</td>
<td style="height:17px; width:208px">To biz<strong>î </strong>berdî.</td>
</tr>
<tr>
<td style="height:17px; width:83px">dar (m)</td>
<td style="height:17px; width:208px">To dar<strong>î </strong>birnayî.</td>
</tr>
<tr>
<td style="height:17px; width:83px">dew (m)</td>
<td style="height:17px; width:208px">Kamî nê dew<strong>î </strong>veşnayî?</td>
</tr>
</tbody>
</table>
Çekuyê ke yewhûmarê înan bi “<strong>-î</strong>” qedîyeno, zafhûmarîya formê “<strong>-î</strong>”yî de newe ra “<strong>-î</strong>” nêgênê, sey xo manenê. Yanî nê tewir çekuyî, yewhûmarîye û zafhûmarîye de eynî nusîyenê. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:102px"><strong>yewhûmar</strong></td>
<td style="height:17px; width:208px"><strong>zafhûmar, meylê kombîyayîşî</strong></td>
<td style="height:17px; width:180px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:102px">yew kard<strong>î </strong>(yh)</td>
<td style="height:17px; width:208px">Mi yew kard<strong>î </strong>şute.</td>
<td style="height:17px; width:180px">Bir bıçak yıkadım.</td>
</tr>
<tr>
<td style="height:17px; width:102px">di kard<strong>î </strong>(zh)</td>
<td style="height:17px; width:208px">Mi di kard<strong>î </strong>şutî.</td>
<td style="height:17px; width:180px">İki bıçak yıkadım.</td>
</tr>
<tr>
<td style="height:17px; width:102px">yew hên<strong>î </strong>(yh)</td>
<td style="height:17px; width:208px">Ma yew hên<strong>î </strong>viraşt.</td>
<td style="height:17px; width:180px">Bir çeşme yaptık.</td>
</tr>
<tr>
<td style="height:17px; width:102px">di hên<strong>î </strong>(zh)</td>
<td style="height:17px; width:208px">Ma di hên<strong>î </strong>viraştî.</td>
<td style="height:17px; width:180px">İki çeşme yaptık.</td>
</tr>
<tr>
<td style="height:17px; width:102px">yew El<strong>î </strong>(yh)</td>
<td style="height:17px; width:208px">El<strong>î </strong>zano.</td>
<td style="height:17px; width:180px">Ali biliyor.</td>
</tr>
<tr>
<td style="height:17px; width:102px">di El<strong>î </strong>(zh)</td>
<td style="height:17px; width:208px">Her di El<strong>î </strong>zî zanî.</td>
<td style="height:17px; width:180px">Her iki Ali de biliyor(lar).</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>2.20.Cumle de Nuştişê Name yan zî Sifetê Zafhûmarî û Kopula</h1><br>
Mîyanê cumle de, name yan zî sifeto ke kopula ra ver sey objeyî (nesne) yeno, <strong>zafhûmar bibo zî sey yewhûmarî nusîyeno </strong>labelê nê halî de kopula zafhûmar nusîyena. Yewhûmarî û zafhûmarîya objeyî, <strong>yewhûmar yan zî zafhûmarbîyayîşê na kopula ra fehm bena</strong>. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:168px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:170px"><strong>formê bînî</strong></td>
<td style="height:17px; width:246px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:168px">Nê vilik <strong>ê</strong>.</td>
<td style="height:17px; width:170px">Nê vîlik<strong>î yê</strong>./Nê vîlik <strong>î</strong>.</td>
<td style="height:17px; width:246px">Bunlar çiçeklerdir./Bunlar çiçektir.</td>
</tr>
<tr>
<td style="height:17px; width:168px">Nê dar <strong>ê</strong>.</td>
<td style="height:17px; width:170px">Nê dar<strong>î yê</strong>./Nê dar <strong>î</strong>.</td>
<td style="height:17px; width:246px">Bunlar ağaçtırlar./Bunlar ağaçtır.</td>
</tr>
<tr>
<td style="height:17px; width:168px">Nê biz <strong>ê</strong>.</td>
<td style="height:17px; width:170px">Nê biz<strong>î yê</strong>. Nê biz <strong>î</strong>.</td>
<td style="height:17px; width:246px">Bunlar keçidirler./ Bunlar keçilerdir.</td>
</tr>
<tr>
<td style="height:17px; width:168px">Her di <strong>yê</strong>.</td>
<td style="height:17px; width:170px">Herdi<strong>yî yê/yî</strong>.</td>
<td style="height:17px; width:246px">Her ikisidir.</td>
</tr>
<tr>
<td style="height:17px; width:168px">Nê kuçe <strong>yê</strong>.</td>
<td style="height:17px; width:170px">Nê kuçe<strong>y ê/î</strong>.</td>
<td style="height:17px; width:246px">Bunlar sokaktırlar./Bunlar sokaklardır.</td>
</tr>
<tr>
<td style="height:17px; width:168px">Nê ga <strong>yê</strong>.</td>
<td style="height:17px; width:170px">Nê ga<strong>yî yê</strong>./Nê ga<strong>y ê</strong>.</td>
<td style="height:17px; width:246px">Bunlar öküzdürler./Bunlar öküzlerdir.</td>
</tr>
<tr>
<td style="height:17px; width:168px">Nê rind <strong>ê</strong>.</td>
<td style="height:17px; width:170px">Nê rind<strong>î yê</strong>.</td>
<td style="height:17px; width:246px">Bunlar güzeldirler./Bunlar güzellerdir.</td>
</tr>
<tr>
<td style="height:17px; width:168px">Nê dewij <strong>ê</strong>.</td>
<td style="height:17px; width:170px">Nê dewij<strong>î yê</strong>.</td>
<td style="height:17px; width:246px">Bunlar köylülerdir/köylüdürler.</td>
</tr>
<tr>
<td style="height:17px; width:168px">Nê merdimê wende <strong>yê</strong>.</td>
<td style="height:17px; width:170px">Nê merdimê wendey<strong>î yê</strong>.</td>
<td style="height:17px; width:246px">Bunlar okumuş/tahsilli adamlardır.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>2.21.Îzafe (Tamlama)</h1><br>

<ol>
<li>Kirmanckî de îzafeya nameyî (isim tamlaması) fek ra fek cîya vajîyena. Heta tayê fekan de vengo ke îzafeyî îfade keno vîndî zî bîyo. Mavajî, cayê “laj<strong>ê </strong>Hesen<strong>î</strong>” (Hasan’ın oğlu) de tayê fekan de vanê “la<strong>c </strong>Hese<strong>n</strong>”. Labelê ziwanê nuştişî de ganî herfa ke îzafeyî musnena (nawnena) binusîyo.</li>
</ol>
Derheqê îzafeyî de tercîhê kombîyayîşî û formê bînî wina yê: Nêrî (Eril)<br>
<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:161px"><strong>formê bînî</strong></td>
<td style="height:17px; width:104px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">a)her<strong>ê </strong>mi</td>
<td style="height:17px; width:161px">hêr mi, her mi</td>
<td style="height:17px; width:104px">eşeğim</td>
</tr>
<tr>
<td style="height:17px; width:140px">b)her<strong>î </strong>mi</td>
<td style="height:17px; width:161px">hêr mi, her mi</td>
<td style="height:17px; width:104px">eşeğim</td>
</tr>
<tr>
<td style="height:17px; width:140px">a)laj<strong>ê </strong>Hesenî</td>
<td style="height:17px; width:161px">lac Hesenî, lac Hesen</td>
<td style="height:17px; width:104px">Hasan’ın oğlu</td>
</tr>
<tr>
<td style="height:17px; width:140px">b)laj<strong>î </strong>Hesenî</td>
<td style="height:17px; width:161px">lac Hesên, lac Hesen</td>
<td style="height:17px; width:104px">Hasan’ın oğlu</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Makî (Dişil)
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:161px"><strong>formê bînî</strong></td>
<td style="height:17px; width:104px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">a)her<strong>a </strong>mi</td>
<td style="height:17px; width:161px">herey mi, hêr mi, her mi</td>
<td style="height:17px; width:104px">eşeğim</td>
</tr>
<tr>
<td style="height:17px; width:140px">b)her<strong>ê </strong>mi</td>
<td style="height:17px; width:161px">herey mi, hêr mi, her mi</td>
<td style="height:17px; width:104px">eşeğim</td>
</tr>
</tbody>
</table>
Zafhûmar (Çoğul)

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:161px"><strong>formê bînî</strong></td>
<td style="height:17px; width:123px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">a)her<strong>ê </strong>mi</td>
<td style="height:17px; width:161px">hêr mi, her mi</td>
<td style="height:17px; width:123px">eşeklerim</td>
</tr>
<tr>
<td style="height:17px; width:140px">b)her<strong>î </strong>mi</td>
<td style="height:17px; width:161px">hêr mi, her mi</td>
<td style="height:17px; width:123px">eşeklerim</td>
</tr>
<tr>
<td style="height:17px; width:140px">a)laj<strong>ê </strong>Hesenî</td>
<td style="height:17px; width:161px">lac Hesên, lac Hesen</td>
<td style="height:17px; width:123px">Hasan’ın oğulları</td>
</tr>
<tr>
<td style="height:17px; width:140px">b)laj<strong>î </strong>Hesenî</td>
<td style="height:17px; width:161px">lac Hesên, lac Hesen</td>
<td style="height:17px; width:123px">Hasan’ın oğulları</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Seke nimûneyanê corênan ra zî fehm beno, goreyê tercîhê kombîyayîşî, kirmanckî de ewro merdim şêno bi formê “<strong>a</strong>” yan zî “<strong>b</strong>”yî binuso.<br>
&nbsp;
<h1>b)Îzafe de, çekuya makî ya temambîyayîye (muzaf, tamlanan) ya ke bi “-e” yan zî “-i” qedîyena</h1><br>
Îzafe de, çekuya makî ya ke bi “<strong>-e</strong>” yan zî “<strong>-i</strong>” qedîyena, gama ke biba temambîyayîye, yanî biba çekuya yewine, no “<strong>-e</strong>” yan zî “<strong>-i</strong>” beno “<strong>-a</strong>”.<br>
&nbsp;<br>
Nimûneyî:
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:132px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:253px"><strong>formê bînî</strong></td>
<td style="height:17px; width:180px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:132px">gul<strong>e </strong>(m)</td>
<td style="height:17px; width:253px">gul<strong>i </strong>(m), gul (m)</td>
<td style="height:17px; width:180px">gül</td>
</tr>
<tr>
<td style="height:17px; width:132px">gul<strong>a </strong>rezî (m)</td>
<td style="height:17px; width:253px">gul<strong>ey </strong>rezî (m), gul<strong>ê </strong>rezî (m)</td>
<td style="height:17px; width:180px">bağın gülü</td>
</tr>
<tr>
<td style="height:17px; width:132px">vil<strong>e </strong>(m)</td>
<td style="height:17px; width:253px">vil<strong>i </strong>(m), vil (m)</td>
<td style="height:17px; width:180px">çiçek</td>
</tr>
<tr>
<td style="height:17px; width:132px">vil<strong>a </strong>wesarî (m)</td>
<td style="height:17px; width:253px">vil<strong>ey </strong>wesarî (m), vil<strong>ê </strong>wisarî (m)</td>
<td style="height:17px; width:180px">baharın çiçeği, bahar çiçeği</td>
</tr>
<tr>
<td style="height:34px; width:132px">veyv<strong>e </strong>(m)</td>
<td style="height:34px; width:253px">veyv<strong>i </strong>(m), veyw<strong>i </strong>(m), vêv<strong>i </strong>(m), vêv<br>
(m), vêw<strong>e </strong>(m), vêw (m), vêyv<strong>e </strong>(m)</td>
<td style="height:34px; width:180px">gelin</td>
</tr>
<tr>
<td style="height:34px; width:132px">veyv<strong>a </strong>Havildarî</td>
<td style="height:34px; width:253px">veyv<strong>ey </strong>Havildarî, veyv<strong>ê </strong>Havildarî<br>
vêw<strong>ê </strong>Havildarî, vêw<strong>a </strong>Havildarî</td>
<td style="height:34px; width:180px">Havildar’ın gelini.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Labelê îzafe de, çekuyanê nêrîyan de, <strong>no “-e”yê peynîya çekuye vîndî nêbeno, sey xo maneno</strong>.<br>
Yewna qayde zî no yo: <strong>Eke “-i”, “y”yî ra ver bêro beno “-î”</strong>. Nimûneyî:<br>
<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:134px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:327px"><strong>formê bînî</strong></td>
<td style="height:17px; width:132px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:134px">gul<strong>e </strong>(n)</td>
<td style="height:17px; width:327px">&nbsp;</td>
<td style="height:17px; width:132px">gülle</td>
</tr>
<tr>
<td style="height:17px; width:134px">gul<strong>e</strong>yê tope (n)</td>
<td style="height:17px; width:327px">gul<strong>e</strong>yî tope (n), gul<strong>e</strong>y topi (n)</td>
<td style="height:17px; width:132px">topun güllesi</td>
</tr>
<tr>
<td style="height:17px; width:134px">vil<strong>e </strong>(n)</td>
<td style="height:17px; width:327px">&nbsp;</td>
<td style="height:17px; width:132px">boyun</td>
</tr>
<tr>
<td style="height:17px; width:134px">vil<strong>e</strong>yê Berzoyî (n)</td>
<td style="height:17px; width:327px">vil<strong>e</strong>yî Berzoyî (n), vil<strong>e</strong>y Berzoyî (n)</td>
<td style="height:17px; width:132px">Berzo’nun boynu</td>
</tr>
<tr>
<td style="height:17px; width:134px">veyv<strong>e </strong>(n)</td>
<td style="height:17px; width:327px">&nbsp;</td>
<td style="height:17px; width:132px">düğün</td>
</tr>
<tr>
<td style="height:17px; width:134px">veyv<strong>e</strong>yê Zerrîweşî</td>
<td style="height:17px; width:327px">veyv<strong>e</strong>yî Zerrîweşî, veyv<strong>e</strong>y Zerrîweşî</td>
<td style="height:17px; width:132px">Zerrîweş’in düğünü</td>
</tr>
<tr>
<td style="height:17px; width:134px">nam<strong>e </strong>(n)</td>
<td style="height:17px; width:327px">nom<strong>e </strong>(n), nûm<strong>e </strong>(n)</td>
<td style="height:17px; width:132px">ad, isim</td>
</tr>
<tr>
<td style="height:17px; width:134px">nam<strong>e</strong>yê lajekî (n)</td>
<td style="height:17px; width:327px">nam<strong>e</strong>y lajekî (n), nom<strong>e</strong>y/nûm<strong>e</strong>y lacekî (n)</td>
<td style="height:17px; width:132px">oğlanın adı</td>
</tr>
<tr>
<td style="height:17px; width:134px">çerm<strong>e </strong>(n)</td>
<td style="height:17px; width:327px">çerm<strong>i </strong>(n)</td>
<td style="height:17px; width:132px">deri</td>
</tr>
<tr>
<td style="height:17px; width:134px">çerm<strong>e</strong>yê gayî (n)</td>
<td style="height:17px; width:327px">çerm<strong>î</strong>yî gayî (n), çerm<strong>î</strong>y gayî (n), çerm<strong>e</strong>y gayî (n)</td>
<td style="height:17px; width:132px">öküzün derisi)</td>
</tr>
<tr>
<td style="height:17px; width:134px">berm<strong>e </strong>(n)</td>
<td style="height:17px; width:327px">berm<strong>i </strong>(n), barm<strong>i </strong>(n)</td>
<td style="height:17px; width:132px">ağlama</td>
</tr>
<tr>
<td style="height:17px; width:134px">berm<strong>e</strong>yê Felatî (n)</td>
<td style="height:17px; width:327px">berm<strong>î</strong>yê Felatî (n),berm<strong>î</strong>y Felatî (n),barm<strong>î</strong>y Felatî (n)</td>
<td style="height:17px; width:132px">Felat’ın ağlaması</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<ol>
<li>Kirmanckî de îzafeya sifetî zî fek ra fek vurîyena. Mavajî manaya “yeni bir imkan” û “siyah bir kalem”a tirkî de, kirmanckî de nê formê cîya-cîyayî est ê:</li>
</ol>
&nbsp;<br>
Çekuya Nêrî
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:302px"><strong>formê bînî</strong></td>
<td style="height:17px; width:104px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:34px; width:140px">îmkan<strong>êko </strong>newe<br>
<strong>yew </strong>îmkan<strong>o </strong>newe</td>
<td style="height:34px; width:302px">îmkanêk newe, îmkanko newe, îmkankew newe</td>
<td style="height:34px; width:104px">yeni bir imkan</td>
</tr>
<tr>
<td style="height:34px; width:140px">îmkan<strong>êdo </strong>newe<br>
îmkan<strong>êde </strong>newe</td>
<td style="height:34px; width:302px">îmkanêndo newe</td>
<td style="height:34px; width:104px">yeni bir imkan</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Nimûne:<br>
Îmkanêko newe ewro est o la vizêr çin bi. (Yeni bir imkan bugün var ama dün yoktu.) Na cumle de “îmkan<strong>êko </strong>newe”, demê nikayinî de, kerdox (özne) o.<br>
&nbsp;
<h1>Çekuya Makî</h1><br>

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:227px"><strong>formê bînî</strong></td>
<td style="height:17px; width:123px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:34px; width:140px">qelem<strong>êka </strong>sîyaye<br>
yew qelem<strong>a </strong>sîyaye</td>
<td style="height:34px; width:227px">qelem<strong>êka </strong>sîyay, qelem<strong>ka </strong>sîyay</td>
<td style="height:34px; width:123px">siyah bir kalem</td>
</tr>
<tr>
<td style="height:17px; width:140px">qelem<strong>êda </strong>sîyaye</td>
<td style="height:17px; width:227px">qelem<strong>ênda </strong>sîyay, qelem<strong>ade </strong>sîyaye</td>
<td style="height:17px; width:123px">siyah bir kalem</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;<br>
ç)Nuştişê Îzafeya Rêzilkî (Zincirleme Tamlama) û Manaya Aye
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:300px"><strong>îzafeya rêzilkî</strong></td>
<td style="height:17px; width:246px"><strong>manaya cumle</strong></td>
</tr>
<tr>
<td style="height:17px; width:300px">Ehmedê Hesenê dergî vano.</td>
<td style="height:17px; width:246px">Na cumle de <strong>Hesen </strong>derg o.</td>
</tr>
<tr>
<td style="height:17px; width:300px">Ehmedê Hesenî <strong>yo </strong>derg vano.</td>
<td style="height:17px; width:246px">Na cumle de <strong>Ehmed </strong>derg o.</td>
</tr>
<tr>
<td style="height:17px; width:300px">Ehmedê Hesen<strong>ê </strong>dergî va.</td>
<td style="height:17px; width:246px">Na cumle de <strong>Hesen </strong>derg o.</td>
</tr>
<tr>
<td style="height:17px; width:300px">Ehmedê Hesenî <strong>yê </strong>dergî va.</td>
<td style="height:17px; width:246px">Na cumle de <strong>Ehmed </strong>derg o.</td>
</tr>
<tr>
<td style="height:17px; width:300px">Lajê bira<strong>yê </strong>pîlî waneno.</td>
<td style="height:17px; width:246px">Na cumle de <strong>bira </strong>pîl o.</td>
</tr>
<tr>
<td style="height:17px; width:300px">Lajê birayî <strong>yo </strong>pîl waneno.</td>
<td style="height:17px; width:246px">Na cumle de <strong>laj </strong>pîl o.</td>
</tr>
<tr>
<td style="height:17px; width:300px">Lajê bira<strong>yê </strong>pîlî wend.</td>
<td style="height:17px; width:246px">Na cumle de <strong>bira </strong>pîl o.</td>
</tr>
<tr>
<td style="height:17px; width:300px">Lajê birayî <strong>yê </strong>pîlî wend.</td>
<td style="height:17px; width:246px">Na cumle de <strong>laj </strong>pîl o.</td>
</tr>
<tr>
<td style="height:17px; width:300px">Komeleya Cinîyan <strong>ya </strong>Demokratan<strong>ê </strong>Kurdistanî</td>
<td style="height:17px; width:246px">Tîya de <strong>mêrdeyê cinîyan </strong>demokrat ê.</td>
</tr>
<tr>
<td style="height:17px; width:300px">Komeleya Cinîyanê Demokratan <strong>ya </strong>Kurdistanî</td>
<td style="height:17px; width:246px">Tîya de <strong>cinî </strong>bi xo demokrat ê.</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>2.22.Nameyê Tayê Bajaran (Şaristanan) û Qezayanê Kurdan</h1><br>
Goreyê îdareyê dewlete tayê nameyê îdarî nê yê:<br>
<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:227px"><strong>formê bînî</strong></td>
<td style="height:17px; width:142px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">dewe (m)</td>
<td style="height:17px; width:227px">dewi, dew, daw, diwi, dow, do (m)</td>
<td style="height:17px; width:142px">köy</td>
</tr>
<tr>
<td style="height:17px; width:140px">nahîye (m)</td>
<td style="height:17px; width:227px">nehîye, nahye, naye (m)</td>
<td style="height:17px; width:142px">nahiye</td>
</tr>
<tr>
<td style="height:17px; width:140px">qeza (m)</td>
<td style="height:17px; width:227px">qeza (n)</td>
<td style="height:17px; width:142px">ilçe, kaza</td>
</tr>
<tr>
<td style="height:17px; width:140px">bajar (n)</td>
<td style="height:17px; width:227px">bacar (n)</td>
<td style="height:17px; width:142px">il, vilayet; şehir, kent</td>
</tr>
<tr>
<td style="height:17px; width:140px">şaristan (n)</td>
<td style="height:17px; width:227px">şahrestan, şeher, şehîr (n)</td>
<td style="height:17px; width:142px">il, vilayet; şehir, kent</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
<strong>Sûke</strong>: Tayê fekan de çekuya “sûke”/“suki”/“sûk”/“suk” manayanê “şehir, kent, il merkezi, ilçe merkezi” yan zî “çarşı”yê tirkî de vajîyena. Mavajî Dîyarbekir, Erzingan û Sêwregi ra “sûke” yan zî “suki” vajîyeno. Ziwanê erebkî de zî “sûq” manaya “çarşu”yî de yo.<br>
Sey prensîpî, nameyê her bajarê kurdan, sey vatişê şarê ê bajarî nusîyeno. Mavajî: Dêrsim, Gimgim, Bongilan, Dîyarbekir, Mêrdîn. Cêr ra, ma goreyê tercîhê kombîyayîşî nameyanê tayê bajaran û qezayanê kurdan nusenê.<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:34px; width:177px"><strong>nameyo kirmanckî</strong></td>
<td style="height:34px; width:123px"><strong>nameyo ke</strong><br>
<strong>dewlete panawo</strong></td>
</tr>
<tr>
<td style="height:101px; width:177px"><strong>I)Bidlîs</strong>
<ol>
<li>Elcewaz</li>
<li>Hîzan</li>
<li>Motkî</li>
<li>Tûx</li>
<li>Xelat</li>
</ol>
</td>
<td style="height:101px; width:123px"><strong>I)Bitlis</strong>
<ol>
<li>Adilcevaz</li>
<li>Hizan</li>
<li>Mutki</li>
<li>Tatvan</li>
<li>Ahlat</li>
</ol>
</td>
</tr>
<tr>
<td style="height:135px; width:177px"><strong>II)Çewlîg</strong>
<ol>
<li>Azarpêrt</li>
<li>Bongilan</li>
<li>Çêrme</li>
<li>Dara Hênî</li>
<li>Gêxî</li>
<li>Kanîreş</li>
<li>Xorxol</li>
</ol>
</td>
<td style="height:135px; width:123px"><strong>II)Bingöl</strong>
<ol>
<li>Adaklı</li>
<li>Solhan</li>
<li>Yedisu</li>
<li>Genç</li>
<li>Kiği</li>
<li>Karlıova</li>
<li>Yayladere</li>
</ol>
</td>
</tr>
<tr>
<td style="height:202px; width:177px"><strong>III)Mamekîye</strong>, <strong>Dêrsim</strong>
<ol>
<li>Çemişgezek</li>
<li>Mazgêrd</li>
<li>Pêrtage</li>
<li>Pilemurîye</li>
<li>Pulur</li>
<li>Qisle</li>
<li>Xozat</li>
</ol>
<strong>Dêrsim </strong>eslê xo de nameyê yew mintiqa yo. Nameyê şaristanî bi xo <strong>Mamekîye</strong><br>
ya, ci ra <strong>Kalan </strong>zî vajîyeno.</td>
<td style="height:202px; width:123px"><strong>III)Tunceli</strong>
<ol>
<li>Çemişgezek</li>
<li>Mazgirt</li>
<li>Pertek</li>
<li>Pülümür</li>
<li>Ovacık</li>
<li>Nazımiye</li>
<li>Hozat</li>
</ol>
</td>
</tr>
<tr>
<td style="height:236px; width:177px"><strong>IV)Dîyarbekir</strong>
<ol>
<li>Bismil</li>
<li>Çêrmûge</li>
<li>Çinar</li>
<li>Erxenî</li>
<li>Gêl</li>
<li>Hezro</li>
<li>Hêni</li>
<li>Karaz</li>
<li>Licê</li>
<li>Pasûr</li>
<li>Pîran</li>
<li>Silîvan</li>
<li>Şankuş</li>
</ol>
</td>
<td style="height:236px; width:123px"><strong>IV)Diyarbakır</strong>
<ol>
<li>Bismil</li>
<li>Çermik</li>
<li>Çınar</li>
<li>Ergani</li>
<li>Eğil</li>
<li>Hazro</li>
<li>Hani</li>
<li>Kocaköy</li>
<li>Lice</li>
<li>Kulp</li>
<li>Dicle</li>
<li>Silvan</li>
<li>Çüngüş</li>
</ol>
</td>
</tr>
<tr>
<td style="height:135px; width:177px"><strong>V)Erzingan</strong>
<ol>
<li>Ciminî</li>
<li>Îlîç</li>
<li>Kemalîye</li>
<li>Kemax</li>
<li>Mose</li>
<li>Refahîye</li>
<li>Têrcan</li>
</ol>
</td>
<td style="height:135px; width:123px"><strong>V)Erzincan</strong>
<ol>
<li>Üzümlü</li>
<li>İliç</li>
<li>Kemaliye</li>
<li>Kemah</li>
<li>Çayırlı</li>
<li>Refahiye</li>
<li>Tercan</li>
</ol>
</td>
</tr>
<tr>
<td style="height:219px; width:177px"><strong>VI)Erzirom</strong>
<ol>
<li>Aşqele</li>
<li>Çat</li>
<li>Gogsî</li>
<li>Hesenqele</li>
<li>Îspîr</li>
<li>Narman</li>
<li>Oltî</li>
<li>Olur</li>
<li>Ortîlî</li>
<li>Tatos</li>
<li>Xinûs, Kela</li>
<li>Xoresan</li>
</ol>
</td>
<td style="height:219px; width:123px"><strong>VI)Erzurum</strong>
<ol>
<li>Aşkale</li>
<li>Çat</li>
<li>Karayazı</li>
<li>Pasinler</li>
<li>İspir</li>
<li>Narman</li>
<li>Oltu</li>
<li>Olur</li>
<li>Şenkaya</li>
<li>Tekman</li>
<li>Hınıs</li>
<li>Horasan</li>
</ol>
</td>
</tr>
</tbody>
</table>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:67px; width:177px"><strong>VII)Mûş</strong>

<ol>
<li>Gimgim</li>
<li>Kop</li>
<li>Milazgir</li>
</ol>
</td>
<td style="height:67px; width:123px"><strong>VII)Muş</strong>
<ol>
<li>Varto</li>
<li>Bulanık</li>
<li>Malazgirt</li>
</ol>
</td>
</tr>
<tr>
<td style="height:152px; width:177px"><strong>VIII)Riha</strong>
<ol>
<li>Bêrecûg</li>
<li>Curnê Reş</li>
<li>Hewenc</li>
<li>Serê Kanîyê</li>
<li>Sêwregi</li>
<li>Sirûc</li>
<li>Wêranşar</li>
<li>Xelfetî</li>
</ol>
</td>
<td style="height:152px; width:123px"><strong>VIII)Urfa</strong>
<ol>
<li>Birecik</li>
<li>Hilvan</li>
<li>Bozova</li>
<li>Ceylanpınar</li>
<li>Siverek</li>
<li>Suruç</li>
<li>Viranşehir</li>
<li>Halfeti</li>
</ol>
</td>
</tr>
<tr>
<td style="height:118px; width:177px"><strong>IX)Semsûr</strong>
<ol>
<li>Aldûş</li>
<li>Bêsnî</li>
<li>Çêlikan</li>
<li>Kolik</li>
<li>Semsat</li>
<li>Serê Golan</li>
</ol>
</td>
<td style="height:118px; width:123px"><strong>IX)Adıyaman</strong>
<ol>
<li>Gerger</li>
<li>Besni</li>
<li>Çelikhan</li>
<li>Kâhta</li>
<li>Samsat</li>
<li>Gölbaşı</li>
</ol>
</td>
</tr>
<tr>
<td style="height:101px; width:177px"><strong>X)Sêrt</strong>
<ol>
<li>Berwarî</li>
<li>Dih</li>
<li>Hewêl</li>
<li>Misriç</li>
<li>Şêrvan</li>
</ol>
</td>
<td style="height:101px; width:123px"><strong>X)Siirt</strong>
<ol>
<li>Pervari</li>
<li>Eruh</li>
<li>Baykan</li>
<li>Kurtalan</li>
<li>Şirvan</li>
</ol>
</td>
</tr>
<tr>
<td style="height:186px; width:177px"><strong>XI)Xarpêt</strong>
<ol>
<li>Axin</li>
<li>Baskîl</li>
<li>Depe</li>
<li>Keban</li>
<li>Meden</li>
<li>Mîyaran</li>
<li>Pali</li>
<li>Qowancîyan</li>
<li>Sivrice</li>
<li>Xulaman</li>
</ol>
</td>
<td style="height:186px; width:123px"><strong>XI)Elazığ</strong>
<ol>
<li>Ağın</li>
<li>Baskil</li>
<li>Karakoçan</li>
<li>Keban</li>
<li>Maden</li>
<li>Arıcak</li>
<li>Palu</li>
<li>Kovancılar</li>
<li>Sivrice</li>
<li>Alacakaya</li>
</ol>
</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>2.23.Nameyê Welatan, Dewletan û Paytextan</h1><br>
Tîya de vêşane (zafane) nameyê dewletan û paytextanê înan nusîyayê labelê nameyê tayê welatanê bêdewletan yan zî welatanê otonoman zî nusîyayê.<br>
&nbsp;
<h1>2.23.1.Afrîka</h1><br>
&nbsp;

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:225px"><strong>kirmanckî</strong></td>
<td style="height:17px; width:236px"><strong>îngilizkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:225px"><strong>dewlete/welat, paytext</strong></td>
<td style="height:17px; width:236px"><strong>dewlete/welat, paytext</strong></td>
</tr>
<tr>
<td style="height:17px; width:225px">1. Afrîkaya Başûrî, Pretorya</td>
<td style="height:17px; width:236px">1. South Africa, Pretoria</td>
</tr>
<tr>
<td style="height:17px; width:225px">2. Afrîkaya Mîyanêne, Bangî</td>
<td style="height:17px; width:236px">2. Central African Republic, Bangui</td>
</tr>
<tr>
<td style="height:17px; width:225px">3. Angola, Lûanda</td>
<td style="height:17px; width:236px">3. Angola, Luanda</td>
</tr>
<tr>
<td style="height:17px; width:225px">4. Benîn, Porto-Novo</td>
<td style="height:17px; width:236px">4. Benin, Porto-Novo</td>
</tr>
<tr>
<td style="height:17px; width:225px">5. Botswana, Gaborone</td>
<td style="height:17px; width:236px">5. Botswana, Gaborone</td>
</tr>
<tr>
<td style="height:17px; width:225px">6. Brundî, Bujumbura</td>
<td style="height:17px; width:236px">6. Brundi, Bujumbura</td>
</tr>
<tr>
<td style="height:34px; width:225px">7. Bûrkîna Faso/Voltaya Corêne, Wagadûgû</td>
<td style="height:34px; width:236px">7. Burkina Faso, Ouagadougou</td>
</tr>
<tr>
<td style="height:17px; width:225px">8. Çad, Ncamena</td>
<td style="height:17px; width:236px">8. Chad, N’Djamena</td>
</tr>
<tr>
<td style="height:17px; width:225px">9. Cezayîr, Cezayîr</td>
<td style="height:17px; width:236px">9. Algeria, Algiers</td>
</tr>
<tr>
<td style="height:17px; width:225px">10. Cîbûtî, Cîbûtî</td>
<td style="height:17px; width:236px">10. Djibouti, Djibouti</td>
</tr>
<tr>
<td style="height:17px; width:225px">11. Erître, Asmara</td>
<td style="height:17px; width:236px">11. Eritrea, Asmara</td>
</tr>
<tr>
<td style="height:17px; width:225px">12. Etyopya, Addîs Ababa</td>
<td style="height:17px; width:236px">12. Ethiopia, Addis Ababa</td>
</tr>
<tr>
<td style="height:17px; width:225px">13. Gabon, Lîbrvîl</td>
<td style="height:17px; width:236px">13. Gabon, Libreville</td>
</tr>
<tr>
<td style="height:17px; width:225px">14. Gambîya, Banjûl</td>
<td style="height:17px; width:236px">14. The Gambia, Banjul</td>
</tr>
<tr>
<td style="height:17px; width:225px">15. Gana, Akkra</td>
<td style="height:17px; width:236px">15. Ghana, Accra</td>
</tr>
<tr>
<td style="height:17px; width:225px">16. Gîne, Konakrî</td>
<td style="height:17px; width:236px">16. Guinea, Conakry</td>
</tr>
<tr>
<td style="height:17px; width:225px">17. Gîneya Bîssaoyî, Bîssao</td>
<td style="height:17px; width:236px">17. Guinea-Bissao, Bissau</td>
</tr>
<tr>
<td style="height:17px; width:225px">18. Gîneya Ekwatore, Malabo</td>
<td style="height:17px; width:236px">18. Equatorial Guinea, Malabo</td>
</tr>
<tr>
<td style="height:17px; width:225px">19. Kamerûn, Yaûnde</td>
<td style="height:17px; width:236px">19. Cameroon, Yaoundé</td>
</tr>
<tr>
<td style="height:17px; width:225px">20. Kenya, Naîrobî</td>
<td style="height:17px; width:236px">20. Kenya, Nairobi</td>
</tr>
<tr>
<td style="height:17px; width:225px">21. Kongo (Zaîre), Kînşasa</td>
<td style="height:17px; width:236px">21. Congo (Zaire), Kinshasa</td>
</tr>
<tr>
<td style="height:17px; width:225px">22. Kongo-Brazzavîlle, Brazzavîlle</td>
<td style="height:17px; width:236px">22. Congo, Brazzaville</td>
</tr>
<tr>
<td style="height:17px; width:225px">23. Lîberya, Monrovya</td>
<td style="height:17px; width:236px">23. Liberia, Monrovia</td>
</tr>
<tr>
<td style="height:17px; width:225px">24. Lîbya, Trablûsê Rojawanî</td>
<td style="height:17px; width:236px">24. Libya, Tripoli</td>
</tr>
<tr>
<td style="height:17px; width:225px">25. Madagaskar, Antananarîvo</td>
<td style="height:17px; width:236px">25. Madagascar, Antananarivo</td>
</tr>
<tr>
<td style="height:17px; width:225px">26. Malawî, Lîlongwe</td>
<td style="height:17px; width:236px">26. Malawi, Lilongwe</td>
</tr>
<tr>
<td style="height:17px; width:225px">27. Malî, Bamako</td>
<td style="height:17px; width:236px">27. Mali, Bamako</td>
</tr>
<tr>
<td style="height:17px; width:225px">28. Marok (Fas), Rabat</td>
<td style="height:17px; width:236px">28. Morocco, Rabat</td>
</tr>
<tr>
<td style="height:17px; width:225px">29. Misir, Qahîre</td>
<td style="height:17px; width:236px">29. Egypt, Cairo</td>
</tr>
<tr>
<td style="height:17px; width:225px">30. Morîtanya, Nûakşot</td>
<td style="height:17px; width:236px">30. Mauritania, Nouakchott</td>
</tr>
<tr>
<td style="height:17px; width:225px">31. Mozambîk, Mapûto</td>
<td style="height:17px; width:236px">31. Mozambîque, Maputo</td>
</tr>
<tr>
<td style="height:17px; width:225px">32. Namîbya, Wîndhûk</td>
<td style="height:17px; width:236px">32. Namibia, Windhoek</td>
</tr>
<tr>
<td style="height:17px; width:225px">33. Nîjer, Nîyamey</td>
<td style="height:17px; width:236px">33. Niger, Niamey</td>
</tr>
<tr>
<td style="height:17px; width:225px">34. Nîjerya, Abuja</td>
<td style="height:17px; width:236px">34. Nigeria, Abuja</td>
</tr>
<tr>
<td style="height:17px; width:225px">35. Rwanda, Kîgalî</td>
<td style="height:17px; width:236px">35. Rwanda, Kigali</td>
</tr>
<tr>
<td style="height:17px; width:225px">36. Sehraya Rojawanî, El-Eyûn</td>
<td style="height:17px; width:236px">36. Western Sahara, El-Aaiún</td>
</tr>
<tr>
<td style="height:17px; width:225px">37. Senegal, Dakar</td>
<td style="height:17px; width:236px">37. Senegal, Dakar</td>
</tr>
<tr>
<td style="height:17px; width:225px">38. Sîyerra Leone, Frîtawn</td>
<td style="height:17px; width:236px">38. Sierra Leone, Freetown</td>
</tr>
<tr>
<td style="height:17px; width:225px">39. Somalya, Mogadîşû</td>
<td style="height:17px; width:236px">39. Somalia, Mogadishu</td>
</tr>
<tr>
<td style="height:17px; width:225px">40. Sûdan, Xertûm</td>
<td style="height:17px; width:236px">40. Sudan, Khartoum</td>
</tr>
<tr>
<td style="height:17px; width:225px">41. Tanzanya, Dodoma</td>
<td style="height:17px; width:236px">41. Tanzania, Dodoma</td>
</tr>
<tr>
<td style="height:17px; width:225px">42. Togo, Lome</td>
<td style="height:17px; width:236px">42. Togo, Lomé</td>
</tr>
<tr>
<td style="height:17px; width:225px">43. Tûnis, Tûnis</td>
<td style="height:17px; width:236px">43. Tunisia, Tunis</td>
</tr>
<tr>
<td style="height:17px; width:225px">44. Ûganda, Kampala</td>
<td style="height:17px; width:236px">44. Uganda, Kampala</td>
</tr>
<tr>
<td style="height:17px; width:225px">45. Zambîya, Lûsaka</td>
<td style="height:17px; width:236px">45. Zambia, Lusaka</td>
</tr>
<tr>
<td style="height:17px; width:225px">46. Zîmbabwe, Harare</td>
<td style="height:17px; width:236px">46. Zîmbabwe, Harare</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;<br>
&nbsp;

<h1>2.23.2.Amerîka</h1>
&nbsp;

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:225px"><strong>kirmanckî</strong></td>
<td style="height:17px; width:255px"><strong>îngilizkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:225px"><strong>dewlete/welat, paytext</strong></td>
<td style="height:17px; width:255px"><strong>dewlete/welat, paytext</strong></td>
</tr>
<tr>
<td style="height:17px; width:225px">1. Arjantin, Buenos Aîres</td>
<td style="height:17px; width:255px">1. Argentina, Buenos Aires</td>
</tr>
<tr>
<td style="height:17px; width:225px">2. Belîz, Belmopan</td>
<td style="height:17px; width:255px">2. Belize, Belmopan</td>
</tr>
<tr>
<td style="height:17px; width:225px">3. Bolîvya, Sukre</td>
<td style="height:17px; width:255px">3. Bolivia, Sucre</td>
</tr>
<tr>
<td style="height:17px; width:225px">4. Brezîlya, Brezîl</td>
<td style="height:17px; width:255px">4. Brazil, Brasilia</td>
</tr>
<tr>
<td style="height:34px; width:225px">5.&nbsp;&nbsp;&nbsp;&nbsp; Dewletê&nbsp;&nbsp;&nbsp;&nbsp; Yewbîyayeyî&nbsp;&nbsp;&nbsp;&nbsp; yê<br>
Amerîka, Waşîngtin</td>
<td style="height:34px; width:255px">5.United States of America, Washington</td>
</tr>
<tr>
<td style="height:17px; width:225px">6. Domînîk, Santo Domîngo</td>
<td style="height:17px; width:255px">6. Dominican Republic, Santo Domingo</td>
</tr>
<tr>
<td style="height:17px; width:225px">7. Ekwador, Kuîto</td>
<td style="height:17px; width:255px">7. Ecuador, Quito</td>
</tr>
<tr>
<td style="height:17px; width:225px">8. Girawê Falklandî, Stanley</td>
<td style="height:17px; width:255px">8. Falkland Islands, Stanley</td>
</tr>
<tr>
<td style="height:17px; width:225px">9. Guatemala, Guatemala City</td>
<td style="height:17px; width:255px">9. Guatemala, Guatemala City</td>
</tr>
<tr>
<td style="height:17px; width:225px">10. Guyana, Corctawn</td>
<td style="height:17px; width:255px">10. Guyana, Georgetown</td>
</tr>
<tr>
<td style="height:17px; width:225px">11. Guyana Fransa, Kayen</td>
<td style="height:17px; width:255px">11. French Guiana, Cayenne</td>
</tr>
<tr>
<td style="height:17px; width:225px">12. Haîtî, Port-o-Prens</td>
<td style="height:17px; width:255px">12. Haiti, Port-au-Prince</td>
</tr>
<tr>
<td style="height:17px; width:225px">13. Hondûras, Tegucîgalpa</td>
<td style="height:17px; width:255px">13. Honduras, Tegucigalpa</td>
</tr>
<tr>
<td style="height:17px; width:225px">14. Jamaîka, Kîngston</td>
<td style="height:17px; width:255px">14. Jamaica, Kingston</td>
</tr>
<tr>
<td style="height:17px; width:225px">15. Kanada, Ottawa</td>
<td style="height:17px; width:255px">15. Canada, Ottawa</td>
</tr>
<tr>
<td style="height:17px; width:225px">16. Kolombîya, Bogota</td>
<td style="height:17px; width:255px">16. Colombia, Bogotá</td>
</tr>
<tr>
<td style="height:17px; width:225px">17. Kosta Rîka, San Jose</td>
<td style="height:17px; width:255px">17. Costa Rica, San José</td>
</tr>
<tr>
<td style="height:17px; width:225px">18. Kuba, Havana</td>
<td style="height:17px; width:255px">18. Cuba, Havana</td>
</tr>
<tr>
<td style="height:17px; width:225px">19. Meksîka, Meksîko City</td>
<td style="height:17px; width:255px">19. Mexico, Mexico City</td>
</tr>
<tr>
<td style="height:17px; width:225px">20. Nîkaragua, Managua</td>
<td style="height:17px; width:255px">20. Nicaragua, Managua</td>
</tr>
<tr>
<td style="height:17px; width:225px">21. Panama, Panama City</td>
<td style="height:17px; width:255px">22. Panama, Panama City</td>
</tr>
<tr>
<td style="height:17px; width:225px">22. Paragûay, Asunsîyon</td>
<td style="height:17px; width:255px">23. Paraguay, Asunción</td>
</tr>
<tr>
<td style="height:17px; width:225px">23. Perû, Lîma</td>
<td style="height:17px; width:255px">24. Peru, Lima</td>
</tr>
<tr>
<td style="height:17px; width:225px">24. Salvador, San Salvador</td>
<td style="height:17px; width:255px">25. El Salvador, San Salvador</td>
</tr>
<tr>
<td style="height:17px; width:225px">25. Surînam, Paramarîbo</td>
<td style="height:17px; width:255px">26. Suriname, Paramaribo</td>
</tr>
<tr>
<td style="height:17px; width:225px">26. Şîlî, Santîyago</td>
<td style="height:17px; width:255px">21. Chile, Santiago</td>
</tr>
<tr>
<td style="height:17px; width:225px">27. Ûrûgûay, Montevîdeo</td>
<td style="height:17px; width:255px">27. Uruguay, Montevideo</td>
</tr>
<tr>
<td style="height:17px; width:225px">28. Venezuela, Karakas</td>
<td style="height:17px; width:255px">28. Venezuela, Caracas</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;<br>
&nbsp;
<h1>2.23.3.Asya</h1><br>
&nbsp;

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:225px"><strong>kirmanckî</strong></td>
<td style="height:17px; width:265px"><strong>îngilizkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:225px"><strong>dewlete/welat, paytext</strong></td>
<td style="height:17px; width:265px"><strong>dewlete/welat, paytext</strong></td>
</tr>
<tr>
<td style="height:17px; width:225px">1. Armenîstan, Erîwan</td>
<td style="height:17px; width:265px">1. Armenia, Yerevan</td>
</tr>
<tr>
<td style="height:17px; width:225px">2. Azerbaycan, Baku</td>
<td style="height:17px; width:265px">2. Azerbaijan, Baku</td>
</tr>
<tr>
<td style="height:17px; width:225px">3. Bahreyn, Manama</td>
<td style="height:17px; width:265px">3. Bahrain, Manama</td>
</tr>
<tr>
<td style="height:17px; width:225px">4. Bengladeş, Dakka</td>
<td style="height:17px; width:265px">4. Bangladesh, Dhaka</td>
</tr>
<tr>
<td style="height:17px; width:225px">5. Bîrmanya (Bûrma), Rangûn</td>
<td style="height:17px; width:265px">5. Burma, Rangoon (Yangon)</td>
</tr>
<tr>
<td style="height:17px; width:225px">6. Bûtan, Thîmbu</td>
<td style="height:17px; width:265px">6. Bhutan, Thimphu</td>
</tr>
<tr>
<td style="height:17px; width:225px">7. Çeçenîstan, Groznî</td>
<td style="height:17px; width:265px">7. Chechenia, Grozny</td>
</tr>
<tr>
<td style="height:17px; width:225px">8. Çîn, Pekîn</td>
<td style="height:17px; width:265px">8. China, Beijing (Peking)</td>
</tr>
<tr>
<td style="height:17px; width:225px">9. Efxanîstan, Kabul</td>
<td style="height:17px; width:265px">9. Afghanistan, Kabul</td>
</tr>
<tr>
<td style="height:17px; width:225px">10. Erebîstanê Seûdî, Rîyad</td>
<td style="height:17px; width:265px">10. Saudi Arabia, Riyadh</td>
</tr>
<tr>
<td style="height:17px; width:225px">11. Fîlîpîn, Manîla</td>
<td style="height:17px; width:265px">11. Philippines, Manila</td>
</tr>
<tr>
<td style="height:17px; width:225px">12. Gurcîstan, Tiflîs</td>
<td style="height:17px; width:265px">12. Georgia, Tbilisi</td>
</tr>
<tr>
<td style="height:17px; width:225px">13. Hindîstan, Delhî</td>
<td style="height:17px; width:265px">13. India, New Delhi</td>
</tr>
<tr>
<td style="height:17px; width:225px">14. Îndonezya, Jakarta</td>
<td style="height:17px; width:265px">14. Indonesia, Jakarta</td>
</tr>
<tr>
<td style="height:17px; width:225px">15. Îran, Tehran</td>
<td style="height:17px; width:265px">15. Iran, Tehran</td>
</tr>
<tr>
<td style="height:17px; width:225px">16. Îraq, Bexdad</td>
<td style="height:17px; width:265px">16. Iraq, Baghdad</td>
</tr>
<tr>
<td style="height:17px; width:225px">17. Îsraîl, Quddus</td>
<td style="height:17px; width:265px">17. Israel, Jerusalem</td>
</tr>
<tr>
<td style="height:17px; width:225px">18. Japonya, Tokyo</td>
<td style="height:17px; width:265px">18. Japan, Tokyo</td>
</tr>
<tr>
<td style="height:17px; width:225px">19. Kaledonyaya Newîye, Nûmeya</td>
<td style="height:17px; width:265px">19. New Caledonia, Nouméa</td>
</tr>
<tr>
<td style="height:17px; width:225px">20. Kamboçya, Phnom Penh</td>
<td style="height:17px; width:265px">20. Cambodia (Kampuchea), Phnom Penh</td>
</tr>
<tr>
<td style="height:17px; width:225px">21. Koreya Başûrî, Seûl</td>
<td style="height:17px; width:265px">21. South Korea, Seoul</td>
</tr>
<tr>
<td style="height:17px; width:225px">22. Koreya Vakurî, Pîyongyang</td>
<td style="height:17px; width:265px">22. North Korea, Pyongyang</td>
</tr>
<tr>
<td style="height:17px; width:225px">23. Kurdîstan</td>
<td style="height:17px; width:265px">23. Kurdistan</td>
</tr>
<tr>
<td style="height:17px; width:225px">24. Kuweyt, Kuweyt</td>
<td style="height:17px; width:265px">24. Kuwait, Kuwait</td>
</tr>
<tr>
<td style="height:17px; width:225px">25. Laos, Vîyangşan</td>
<td style="height:17px; width:265px">25. Laos, Vientiane</td>
</tr>
<tr>
<td style="height:17px; width:225px">26. Lubnan, Beyrûd</td>
<td style="height:17px; width:265px">26. Lebanon, Beirut</td>
</tr>
<tr>
<td style="height:17px; width:225px">27. Maldîv, Male</td>
<td style="height:17px; width:265px">27. Maldives, Malé</td>
</tr>
<tr>
<td style="height:17px; width:225px">28. Malezya, Kuala Lumpur</td>
<td style="height:17px; width:265px">28. Malaysia, Kuala Lumpur</td>
</tr>
<tr>
<td style="height:17px; width:225px">29. Mîreyîyê Ereban, Ebû Dabî</td>
<td style="height:17px; width:265px">29. United Arab Emirates, Abu Dhabi</td>
</tr>
<tr>
<td style="height:17px; width:225px">30. Moxolîstan, Ûlan Bator</td>
<td style="height:17px; width:265px">30. Mongolia, Ulan Bator</td>
</tr>
<tr>
<td style="height:17px; width:225px">31. Nepal, Katmandû</td>
<td style="height:17px; width:265px">31. Nepal, Kathmandu</td>
</tr>
<tr>
<td style="height:17px; width:225px">32. Ozbekîstan, Taşkent</td>
<td style="height:17px; width:265px">32. Uzbekistan, Tashkent</td>
</tr>
<tr>
<td style="height:17px; width:225px">33. Pakîstan, Îslamabad</td>
<td style="height:17px; width:265px">33. Pakistan, Islamabad</td>
</tr>
<tr>
<td style="height:17px; width:225px">34. Qazaxîstan, Akmola</td>
<td style="height:17px; width:265px">34. Kasakhistan, Astana</td>
</tr>
<tr>
<td style="height:17px; width:225px">35. Qeter, Doha</td>
<td style="height:17px; width:265px">35. Qatar, Doha</td>
</tr>
<tr>
<td style="height:17px; width:225px">36. Qibris, Lefkoşe</td>
<td style="height:17px; width:265px">36. Cyprus, Nicosia</td>
</tr>
<tr>
<td style="height:17px; width:225px">37. Qirxizîstan, Bîşkek</td>
<td style="height:17px; width:265px">37. Kyrgyzstan, Bishkek</td>
</tr>
<tr>
<td style="height:17px; width:225px">38. Sîngapûr, Sîngapûr</td>
<td style="height:17px; width:265px">38. Singapore, Singapore</td>
</tr>
<tr>
<td style="height:17px; width:225px">39. Srî Lanka, Kolombo</td>
<td style="height:17px; width:265px">39. Sri Lanka, Colombo</td>
</tr>
<tr>
<td style="height:17px; width:225px">40. Sûrîye, Şam</td>
<td style="height:17px; width:265px">40. Syria, Damascus</td>
</tr>
<tr>
<td style="height:17px; width:225px">41. Tacikîstan, Duşenbe</td>
<td style="height:17px; width:265px">41. Tajikistan, Dushanbe</td>
</tr>
<tr>
<td style="height:17px; width:225px">42. Tayland, Bangkok</td>
<td style="height:17px; width:265px">42. Thailand, Bangkok</td>
</tr>
<tr>
<td style="height:17px; width:225px">43. Taywan, Taypê</td>
<td style="height:17px; width:265px">43. Taiwan, Taipei</td>
</tr>
<tr>
<td style="height:17px; width:225px">44. Tirkîya, Anqara</td>
<td style="height:17px; width:265px">44. Turkey, Ankara</td>
</tr>
<tr>
<td style="height:17px; width:225px">45. Tirkmenîstan, Eşqabad</td>
<td style="height:17px; width:265px">45. Turkmenistan, Ashkhabad</td>
</tr>
<tr>
<td style="height:17px; width:225px">46. Uman, Mesqet</td>
<td style="height:17px; width:265px">46. Oman, Muscat</td>
</tr>
<tr>
<td style="height:17px; width:225px">47. Urdun, Emman</td>
<td style="height:17px; width:265px">47. Jordan, Amman</td>
</tr>
<tr>
<td style="height:17px; width:225px">48. Vîetnam, Hanoî</td>
<td style="height:17px; width:265px">48. Vietnam, Hanoi</td>
</tr>
<tr>
<td style="height:17px; width:225px">49. Yemen, Sena</td>
<td style="height:17px; width:265px">49. Yemen, Sana’a</td>
</tr>
</tbody>
</table>
&nbsp;


<h1>2.23.4.Ewropa</h1>
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:225px"><strong>kirmanckî</strong></td>
<td style="height:17px; width:265px"><strong>îngilizkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:225px"><strong>dewlete/welat, paytext</strong></td>
<td style="height:17px; width:265px"><strong>dewlete/welat, paytext</strong></td>
</tr>
<tr>
<td style="height:17px; width:225px">1. Albanya (Arnawudîstan), Tîrana</td>
<td style="height:17px; width:265px">1. Albania, Tirana</td>
</tr>
<tr>
<td style="height:17px; width:225px">2. Almanyaya Federale, Berlîn</td>
<td style="height:17px; width:265px">2. Germany, Berlin</td>
</tr>
<tr>
<td style="height:17px; width:225px">3. Andorra, Andorra-la-Vella</td>
<td style="height:17px; width:265px">3. Andorra, Andorra-la-Vella</td>
</tr>
<tr>
<td style="height:17px; width:225px">4. Awusturya, Vîyana</td>
<td style="height:17px; width:265px">4. Austria, Vienna</td>
</tr>
<tr>
<td style="height:17px; width:225px">5. Belçîka, Bruksel</td>
<td style="height:17px; width:265px">5. Belgium, Brussels</td>
</tr>
<tr>
<td style="height:17px; width:225px">6. Bosna-Hersek, Sarayevo</td>
<td style="height:17px; width:265px">6. Bosnia-Hercegovina, Sarajevo</td>
</tr>
<tr>
<td style="height:17px; width:225px">7. Bûlgarîstan, Sofya</td>
<td style="height:17px; width:265px">7. Bulgaria, Sofia</td>
</tr>
<tr>
<td style="height:17px; width:225px">8. Çekîstan, Prag</td>
<td style="height:17px; width:265px">8. Czech Republic, Prague</td>
</tr>
<tr>
<td style="height:17px; width:225px">9. Danîmarka, Kopenhag</td>
<td style="height:17px; width:265px">9. Denmark, Copenhagen</td>
</tr>
<tr>
<td style="height:17px; width:225px">10. Estonya, Tallîn</td>
<td style="height:17px; width:265px">10. Estonia, Tallin</td>
</tr>
<tr>
<td style="height:17px; width:225px">11. Faroarna</td>
<td style="height:17px; width:265px">11. The Faroe Islands</td>
</tr>
<tr>
<td style="height:17px; width:225px">12. Fînlanda, Helsînkî</td>
<td style="height:17px; width:265px">12. Finland, Helsinki</td>
</tr>
<tr>
<td style="height:17px; width:225px">13. Fransa, Parîs</td>
<td style="height:17px; width:265px">13. France, Paris</td>
</tr>
<tr>
<td style="height:17px; width:225px">14. Gronlanda, Godthab (Nuuk)</td>
<td style="height:17px; width:265px">14. Greenland, Nuuk (Godthåb)</td>
</tr>
<tr>
<td style="height:17px; width:225px">15. Hollanda, Amsterdam</td>
<td style="height:17px; width:265px">15. The Netherlands, Amsterdam</td>
</tr>
<tr>
<td style="height:17px; width:225px">16. Îngilîstan, Londra</td>
<td style="height:17px; width:265px">16. United Kingdom, London</td>
</tr>
<tr>
<td style="height:17px; width:225px">17. Îrlanda, Dublîn</td>
<td style="height:17px; width:265px">17. Ireland, Dublin</td>
</tr>
<tr>
<td style="height:17px; width:225px">18. Îslanda, Reykyavîk</td>
<td style="height:17px; width:265px">18. Iceland, Reykjavik</td>
</tr>
<tr>
<td style="height:17px; width:225px">19. Îtalya, Roma</td>
<td style="height:17px; width:265px">19. Italy, Rome</td>
</tr>
<tr>
<td style="height:17px; width:225px">20. Korsîka, Ajakkîyo</td>
<td style="height:17px; width:265px">20. Corsica, Ajaccio</td>
</tr>
<tr>
<td style="height:17px; width:225px">21. Letonya, Rîga</td>
<td style="height:17px; width:265px">21. Latvia, Riga</td>
</tr>
<tr>
<td style="height:17px; width:225px">22. Lîtvanya, Vîlnîyus</td>
<td style="height:17px; width:265px">22. Lithuania, Vilnius</td>
</tr>
<tr>
<td style="height:17px; width:225px">23. Lîxtinştayn, Vaduz</td>
<td style="height:17px; width:265px">23. Liechtenstein, Vaduz</td>
</tr>
<tr>
<td style="height:17px; width:225px">24. Luksembûrg, Luksembûrg</td>
<td style="height:17px; width:265px">24. Luxembourg, Luxembourg</td>
</tr>
<tr>
<td style="height:17px; width:225px">25. Macarîstan, Bûdapeşt</td>
<td style="height:17px; width:265px">25. Hungary, Budapest</td>
</tr>
<tr>
<td style="height:17px; width:225px">26. Makedonya, Uskup</td>
<td style="height:17px; width:265px">26. Macedonia, Skopje</td>
</tr>
<tr>
<td style="height:17px; width:225px">27. Malta, Valletta</td>
<td style="height:17px; width:265px">27. Malta, Valletta</td>
</tr>
<tr>
<td style="height:17px; width:225px">28. Moldova, Kîşînev</td>
<td style="height:17px; width:265px">28. Moldova, Chisinau</td>
</tr>
<tr>
<td style="height:17px; width:225px">29. Monako, Monako</td>
<td style="height:17px; width:265px">29. Monaco, Monaco</td>
</tr>
<tr>
<td style="height:17px; width:225px">30. Norweç, Oslo</td>
<td style="height:17px; width:265px">30. Norway, Oslo</td>
</tr>
<tr>
<td style="height:17px; width:225px">31. Polonya, Warşowa</td>
<td style="height:17px; width:265px">31. Poland, Warsaw</td>
</tr>
<tr>
<td style="height:17px; width:225px">32. Portugal, Lîzbon</td>
<td style="height:17px; width:265px">32. Portugal, Lisbon</td>
</tr>
<tr>
<td style="height:17px; width:225px">33. Romanya, Bûkarest</td>
<td style="height:17px; width:265px">33. Romania, Bucharest</td>
</tr>
<tr>
<td style="height:17px; width:225px">34. Rûsya, Moskova</td>
<td style="height:17px; width:265px">34. Russia, Moscow</td>
</tr>
<tr>
<td style="height:17px; width:225px">35. Rûsyaya Sipîye, Mînsk</td>
<td style="height:17px; width:265px">35. Belorussia, Minsk</td>
</tr>
<tr>
<td style="height:17px; width:225px">36. San Marîno, San Marîno</td>
<td style="height:17px; width:265px">36. San Marino, San Marino</td>
</tr>
<tr>
<td style="height:17px; width:225px">37. Sardînya, Kaylerî</td>
<td style="height:17px; width:265px">37. Sardinia, Cagliari</td>
</tr>
<tr>
<td style="height:17px; width:225px">38. Slovakya, Bratîslava</td>
<td style="height:17px; width:265px">38. Slovakia, Bratislava</td>
</tr>
<tr>
<td style="height:17px; width:225px">39. Slovenya, Lyublyana</td>
<td style="height:17px; width:265px">39. Slovenia, Ljubljana</td>
</tr>
<tr>
<td style="height:17px; width:225px">40. Spanya, Madrîd</td>
<td style="height:17px; width:265px">40. Spain, Madrid</td>
</tr>
<tr>
<td style="height:17px; width:225px">41. Swêd, Stokholm</td>
<td style="height:17px; width:265px">41. Sweden, Stockholm</td>
</tr>
<tr>
<td style="height:17px; width:225px">42. Swîs, Bern</td>
<td style="height:17px; width:265px">42. Switzerland, Berne</td>
</tr>
<tr>
<td style="height:17px; width:225px">43. Ukrayna, Kîev</td>
<td style="height:17px; width:265px">43. Ukraine, Kiev</td>
</tr>
<tr>
<td style="height:17px; width:225px">44. Xirvatîstan, Zagreb</td>
<td style="height:17px; width:265px">44. Croatia, Zagreb</td>
</tr>
<tr>
<td style="height:17px; width:225px">45. Yunanîstan, Atîna</td>
<td style="height:17px; width:265px">45. Greece, Athens</td>
</tr>
<tr>
<td style="height:17px; width:225px">46. Yûgoslavya, Belgrad</td>
<td style="height:17px; width:265px">46. Yugoslavia, Belgrade</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;<br>
&nbsp;
<h1>2.23.5.Okyanûsya</h1><br>
&nbsp;

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:253px"><strong>kirmanckî</strong></td>
<td style="height:17px; width:255px"><strong>îngilizkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>dewlete/welat, paytext</strong></td>
<td style="height:17px; width:255px"><strong>dewlete/welat, paytext</strong></td>
</tr>
<tr>
<td style="height:17px; width:253px">1. Awustralya, Kanberra</td>
<td style="height:17px; width:255px">1. Australia, Canberra</td>
</tr>
<tr>
<td style="height:17px; width:253px">2. Fîjî, Suva</td>
<td style="height:17px; width:255px">2. Fiji, Suva</td>
</tr>
<tr>
<td style="height:17px; width:253px">3. Girawa Solomonî, Honyara</td>
<td style="height:17px; width:255px">3. Solomon Island, Honiara</td>
</tr>
<tr>
<td style="height:17px; width:253px">4. Girawê Marşalî, Dalap-Ulîga-Darrît</td>
<td style="height:17px; width:255px">4. Marshall Islands, Dalap-Uliga-Darrit</td>
</tr>
<tr>
<td style="height:17px; width:253px">5. Kîrîbatî, Tarawa</td>
<td style="height:17px; width:255px">5. Kiribati, Tarawa</td>
</tr>
<tr>
<td style="height:17px; width:253px">6. Mîkroneyza, Palîkîr</td>
<td style="height:17px; width:255px">6. Micronesia, Palikir</td>
</tr>
<tr>
<td style="height:17px; width:253px">7. Nauru, Yaren</td>
<td style="height:17px; width:255px">7. Nauru, Yaren</td>
</tr>
<tr>
<td style="height:17px; width:253px">8. Zelandaya Newîye, Wellîngtin</td>
<td style="height:17px; width:255px">8. New Zealand, Wellington</td>
</tr>
<tr>
<td style="height:17px; width:253px">9. Papua-Gîneya Newîye, Port Moresbî</td>
<td style="height:17px; width:255px">9. Papua New Guinea, Port Moresby</td>
</tr>
<tr>
<td style="height:17px; width:253px">10. Samoaya Rojawanî, Apya</td>
<td style="height:17px; width:255px">10. Western Samoa, Apia</td>
</tr>
<tr>
<td style="height:17px; width:253px">11. Tonga, Nukualofa</td>
<td style="height:17px; width:255px">11. Tonga, Nuku’alofa</td>
</tr>
<tr>
<td style="height:17px; width:253px">12. Tuvalu, Fongafale</td>
<td style="height:17px; width:255px">12. Tuvalu, Fongafale</td>
</tr>
<tr>
<td style="height:17px; width:253px">13. Vanuatu, Port Vîla</td>
<td style="height:17px; width:255px">13. Vanuatu, Port Vila</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>3.ZEMÎR (ADIL, ZAMİR)</h1><br>
<h1>3.1.Zemîrê Kesî (Zemîrê Şexsî)</h1><br>
Kirmanckî de di grûbê zemîrê kesî est ê:<br>
&nbsp;
<h1>3.1.1.Grûba Yewine</h1><br>

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:208px"><strong>formê bînî</strong></td>
<td style="height:17px; width:66px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">ez</td>
<td style="height:17px; width:208px">e</td>
<td style="height:17px; width:66px">ben</td>
</tr>
<tr>
<td style="height:17px; width:140px">ti</td>
<td style="height:17px; width:208px">to, tû, tu</td>
<td style="height:17px; width:66px">sen</td>
</tr>
<tr>
<td style="height:17px; width:140px">o (n)</td>
<td style="height:17px; width:208px">ew, aw, we, wi, wo, û, ay, yo, yû</td>
<td style="height:17px; width:66px">o</td>
</tr>
<tr>
<td style="height:17px; width:140px">a (m)</td>
<td style="height:17px; width:208px">ya</td>
<td style="height:17px; width:66px">o</td>
</tr>
<tr>
<td style="height:17px; width:140px">ma</td>
<td style="height:17px; width:208px">-</td>
<td style="height:17px; width:66px">biz</td>
</tr>
<tr>
<td style="height:17px; width:140px">şima</td>
<td style="height:17px; width:208px">sima</td>
<td style="height:17px; width:66px">siz</td>
</tr>
<tr>
<td style="height:17px; width:140px">ê</td>
<td style="height:17px; width:208px">yê, yî, î</td>
<td style="height:17px; width:66px">onlar</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;<br>
&nbsp;

<h1>3.1.2.Grûba Dîyine</h1>
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:340px"><strong>formê bînî</strong></td>
<td style="height:17px; width:47px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">mi</td>
<td style="height:17px; width:340px">min</td>
<td style="height:17px; width:47px">ben</td>
</tr>
<tr>
<td style="height:17px; width:140px">to</td>
<td style="height:17px; width:340px">tû, tu</td>
<td style="height:17px; width:47px">sen</td>
</tr>
<tr>
<td style="height:17px; width:140px">ey (n)</td>
<td style="height:17px; width:340px">yi, yê, hî, jey, ê, êy, êyî, ay</td>
<td style="height:17px; width:47px">o</td>
</tr>
<tr>
<td style="height:17px; width:140px">aye (m)</td>
<td style="height:17px; width:340px">yê, ya, yay, ja, aya, ay, a</td>
<td style="height:17px; width:47px">o</td>
</tr>
<tr>
<td style="height:17px; width:140px">ma</td>
<td style="height:17px; width:340px">man</td>
<td style="height:17px; width:47px">biz</td>
</tr>
<tr>
<td style="height:17px; width:140px">şima</td>
<td style="height:17px; width:340px">şiman, sima</td>
<td style="height:17px; width:47px">siz</td>
</tr>
<tr>
<td style="height:17px; width:140px">înan</td>
<td style="height:17px; width:340px">yîn, yînî, yîne, jînî, îna, îne, înê, înû, aynan, ayna, eyno</td>
<td style="height:17px; width:47px">onlar</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Yewna zemîrê kesî zî “xo” yo. Franskî de nê zemîrî ra <em>“pronom personnel réfléchi” </em>(bi tirkî <em>“dönüşlülük zamiri”</em>) vajîyeno. Kombîyayîşî formê “xo”yî tercîh kerd labelê sewbîna formê nê zemîrî zî est ê:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:170px"><strong>formê bînî</strong></td>
<td style="height:17px; width:47px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">xo</td>
<td style="height:17px; width:170px">xwe, xwi, xwo, xu, ho, hu</td>
<td style="height:17px; width:47px">kendi</td>
</tr>
</tbody>
</table>
<br>
&nbsp;<br>
&nbsp;
<h1>3.1.3.Nusîyayîşê Çekuya “Xo”yî</h1><br>
Eke çekuya “xo”yî sey zemîrî bêro xebitnayîş yan zî karê refleksîfî (dönüşlü fiil) de ca bigîro, çekuyanê bînan ra cîya nusîyena. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:272px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:265px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:272px">Cinîke lajê <strong>xo </strong>berd.</td>
<td style="height:17px; width:265px">Kadın oğlunu götürdü.</td>
</tr>
<tr>
<td style="height:17px; width:272px"><strong>Xo </strong>ver de nîyade!</td>
<td style="height:17px; width:265px">Önüne bak!</td>
</tr>
<tr>
<td style="height:17px; width:272px">O <strong>xo </strong>ver o dano.</td>
<td style="height:17px; width:265px">O kendiliğinden veriyor.</td>
</tr>
<tr>
<td style="height:17px; width:272px">O <strong>xo </strong>ver ro dano.</td>
<td style="height:17px; width:265px">O direniyor.</td>
</tr>
<tr>
<td style="height:17px; width:272px"><strong>Xo </strong>bi <strong>xo </strong>qisey keno./<strong>Xo </strong>bi <strong>xo </strong>qalî keno.</td>
<td style="height:17px; width:265px">Kendi kendine konuşuyor.</td>
</tr>
<tr>
<td style="height:34px; width:272px">“Kerge awe wena, <strong>xo </strong>ra cor Homayî ra<br>
ewnîyena.”</td>
<td style="height:34px; width:265px">“Tavuk [bile] su içer, yukarıdaki Tanrı’ya<br>
bakar.”</td>
</tr>
<tr>
<td style="height:17px; width:272px">ruhê <strong>xo </strong>teslîm kerdene</td>
<td style="height:17px; width:265px">ruhunu teslim etmek, vefat etmek</td>
</tr>
<tr>
<td style="height:17px; width:272px">awe <strong>xo </strong>ro kerdene</td>
<td style="height:17px; width:265px">su dökünmek, duş almak</td>
</tr>
<tr>
<td style="height:17px; width:272px"><strong>xo </strong>mîyane ra bestene</td>
<td style="height:17px; width:265px">beline bağlamak</td>
</tr>
<tr>
<td style="height:34px; width:272px"><strong>xo </strong>ser de kerdene</td>
<td style="height:34px; width:265px">1-kafaya dikmek, iştahla içmek<br>
2-[giysi] çıkarmak</td>
</tr>
<tr>
<td style="height:17px; width:272px"><strong>xo </strong>ser o bîyene</td>
<td style="height:17px; width:265px">dinç olmak, sağlığı yerinde olmak</td>
</tr>
<tr>
<td style="height:17px; width:272px"><strong>xo </strong>ser ro kerdene</td>
<td style="height:17px; width:265px">kendi başına dökmek</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Seke aseno, no karo peyên çar çekuyan ra virazîyayo û nînan ra “xo” zemîr, “ser” [sere] name, “ro” peyedat (artedat), “kerdene” kar o; tîya de “<strong>xo</strong>” cîya nusîyayo. Labelê tayê çekuyanê pêrabesteyan de “<strong>xo</strong>” û çekuya bîne pîya (yewbinîrabeste) nusîyenê. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:85px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>xo</strong>ra</td>
<td style="height:17px; width:85px">zaten</td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>xo</strong>ser</td>
<td style="height:17px; width:85px">bağımsız</td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>xo</strong>serîye, <strong>xo</strong>serî</td>
<td style="height:17px; width:85px">bağımsızlık</td>
</tr>
</tbody>
</table>
&nbsp;<br>
<br>
&nbsp;<br>
&nbsp;<br>
gênê.<br>
<br>
&nbsp;
<h1>3.2.Zemîrê Nîşanî (Zemîrê Îşaretî)</h1><br>
Zemîrê nîşanî, ê zemîrî yê ke, gama ke mêrdim çîyêk yan zî çîyan îşaret keno cayê nameyê ci<br>
&nbsp;
<h1>3.2.1.Semedê Nêzdî</h1><br>
&nbsp;

<table cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td>
<table cellpadding="0" cellspacing="0" style="width:100%">
<tbody>
<tr>
<td>
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
	<tr>
		<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
		<td style="height:17px; width:180px"><strong>formê bînî</strong></td>
		<td style="height:17px; width:132px"><strong>tirkî</strong></td>
	</tr>
	<tr>
		<td style="height:34px; width:140px">no (n)<br>
		<strong>No </strong>vano.</td>
		<td style="height:34px; width:180px">nû, eno, enû, eni, ino, ini, in</td>
		<td style="height:34px; width:132px">bu<br>
		Bu söylüyor.</td>
	</tr>
	<tr>
		<td style="height:34px; width:140px">na (m)<br>
		<strong>Na </strong>vana.</td>
		<td style="height:34px; width:180px">ena, ina, hena</td>
		<td style="height:34px; width:132px">bu<br>
		Bu söylüyor.</td>
	</tr>
	<tr>
		<td style="height:34px; width:140px">nê (zh)<br>
		<strong>Nê </strong>vanê.</td>
		<td style="height:34px; width:180px">nî, enî, inî, enê, inê</td>
		<td style="height:34px; width:132px">bunlar<br>
		Bunlar söylüyorlar.</td>
	</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
<strong>halê xoserî (nominatif, rectus) de</strong>&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;
<h1>halê anteyî (oblîkî) de</h1><br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:312px"><strong>formê bînî</strong></td>
<td style="height:17px; width:123px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:34px; width:140px">ney (n)<br>
<strong>Ney </strong>va.</td>
<td style="height:34px; width:312px">nê, enî, inî, enê, inê</td>
<td style="height:34px; width:123px">bu<br>
Bu söyledi.</td>
</tr>
<tr>
<td style="height:34px; width:140px">naye (m)<br>
<strong>Naye </strong>va.</td>
<td style="height:34px; width:312px">nay, ena, enay, enê, ina, inay, iney, inê, inêy, hena</td>
<td style="height:34px; width:123px">bu<br>
Bu söyledi.</td>
</tr>
<tr>
<td style="height:51px; width:140px">nînan (zh)<br>
&nbsp;<br>
<strong>Nînan </strong>va.</td>
<td style="height:51px; width:312px">nîna, nînû, nayîne, enîyan, enîyûn, inîyan, inûn, inîn, îno, ino</td>
<td style="height:51px; width:123px">bunlar<br>
&nbsp;<br>
Bunlar söylediler.</td>
</tr>
</tbody>
</table>
<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;
<h1>3.2.2.Semedê Dûrî</h1>
<br>
<p> halê xoserî de</p>
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:189px"><strong>formê bînî</strong></td>
<td style="height:17px; width:123px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:34px; width:140px">o (n)<br>
<strong>O </strong>vano.</td>
<td style="height:34px; width:189px">ew, ewi, hew, aw, awi, ay, û</td>
<td style="height:34px; width:123px">o<br>
O söylüyor.</td>
</tr>
<tr>
<td style="height:34px; width:140px">a (m)<br>
<strong>A </strong>vana.</td>
<td style="height:34px; width:189px">eya, aya</td>
<td style="height:34px; width:123px">o<br>
O söylüyor.</td>
</tr>
<tr>
<td style="height:34px; width:140px">ê (zh)<br>
<strong>Ê </strong>vanê.</td>
<td style="height:34px; width:189px">î, eyî, heyî, ay</td>
<td style="height:34px; width:123px">onlar<br>
Onlar söylüyorlar.</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>halê anteyî (oblîkî) de</h1><br>

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:170px"><strong>formê bînî</strong></td>
<td style="height:17px; width:95px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:34px; width:140px">ey (n)<br>
<strong>Ey </strong>ra vaje.</td>
<td style="height:34px; width:170px">ay, ayî</td>
<td style="height:34px; width:95px">o<br>
Ona söyle.</td>
</tr>
<tr>
<td style="height:34px; width:140px">aye (m)<br>
<strong>Aye </strong>ra vaje.</td>
<td style="height:34px; width:170px">ay, aya, ayay, eya</td>
<td style="height:34px; width:95px">o<br>
Ona söyle.</td>
</tr>
<tr>
<td style="height:34px; width:140px">înan (zh)<br>
<strong>Înan </strong>ra vaje.</td>
<td style="height:34px; width:170px">aynan, ayna, aynon, ayno</td>
<td style="height:34px; width:95px">onlar<br>
Onlara söyle.</td>
</tr>
</tbody>
</table>
&nbsp;
<h1>3.2.3.Rastnuştişê Zemîranê Nîşanî “Ney” û “Ey”</h1>
<br>

Cayê zemîranê îşaretî yê “ney” û “ey” de nuştişê “neyî”, “eyî” rast nîyo. Çunke formê anteyê (oblîkê) nê zemîran xora “<strong>ney</strong>” û “<strong>ey</strong>” ê.<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>rast</strong></td>
<td style="height:17px; width:170px"><strong>xelet</strong></td>
<td style="height:17px; width:123px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>Ney </strong>ra vaje.</td>
<td style="height:17px; width:170px"><strong>Neyî </strong>ra vaje.</td>
<td style="height:17px; width:123px">Buna söyle.</td>
</tr>
<tr>
<td style="height:17px; width:140px">Defterê <strong>ney </strong>est o.</td>
<td style="height:17px; width:170px">Defterê <strong>neyî </strong>est o.</td>
<td style="height:17px; width:123px">Bunun defteri var.</td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>Ey </strong>ra mevaje.</td>
<td style="height:17px; width:170px"><strong>Eyî </strong>ra mevaje.</td>
<td style="height:17px; width:123px">Ona söyleme.</td>
</tr>
<tr>
<td style="height:17px; width:140px">Defterê <strong>ey </strong>çin o.</td>
<td style="height:17px; width:170px">Defterê <strong>eyî </strong>çin o.</td>
<td style="height:17px; width:123px">Onun defteri yok.</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>3.4. Zemîrê Wayîrîye (İyelik Zamiri)</h1><br>
Kirmanckî de zemîrê wayîrîye, “<strong>yê</strong>” yo. No zemîr seba nêrî, makî, yewhûmar û zafhûmarî eynî yo, nêvurîyeno.<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:132px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:132px"><strong>formê bînî</strong></td>
<td style="height:17px; width:161px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:132px"><strong>seba nêrî</strong></td>
<td style="height:17px; width:132px">&nbsp;</td>
<td style="height:17px; width:161px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:132px"><strong>yê</strong></td>
<td style="height:17px; width:132px"><strong>ê</strong>, <strong>ey</strong></td>
<td style="height:17px; width:161px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:132px"><strong>yê </strong>mi</td>
<td style="height:17px; width:132px"><strong>ê </strong>mi, <strong>ey </strong>mi</td>
<td style="height:17px; width:161px">benimki, benim</td>
</tr>
<tr>
<td style="height:17px; width:132px">No <strong>yê </strong>Roşanî yo.</td>
<td style="height:17px; width:132px">No <strong>ê</strong>/<strong>ey </strong>Roşanî yo.</td>
<td style="height:17px; width:161px">Bu Roşanınkidir.</td>
</tr>
<tr>
<td style="height:17px; width:132px"><strong>seba makî</strong></td>
<td style="height:17px; width:132px">&nbsp;</td>
<td style="height:17px; width:161px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:132px"><strong>yê</strong></td>
<td style="height:17px; width:132px"><strong>ê</strong>, <strong>ey</strong></td>
<td style="height:17px; width:161px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:132px"><strong>yê </strong>mi</td>
<td style="height:17px; width:132px"><strong>ê </strong>mi, <strong>ey </strong>mi</td>
<td style="height:17px; width:161px">benimki, benim</td>
</tr>
<tr>
<td style="height:17px; width:132px">Na <strong>yê </strong>Roşanî ya.</td>
<td style="height:17px; width:132px">Na <strong>ê</strong>/<strong>ey </strong>Roşanî ya.</td>
<td style="height:17px; width:161px">Bu Roşanınkidir.</td>
</tr>
<tr>
<td style="height:17px; width:132px"><strong>seba zafhûmarî</strong></td>
<td style="height:17px; width:132px">&nbsp;</td>
<td style="height:17px; width:161px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:132px"><strong>yê</strong></td>
<td style="height:17px; width:132px"><strong>ê</strong>, <strong>ey</strong></td>
<td style="height:17px; width:161px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:132px"><strong>yê </strong>mi</td>
<td style="height:17px; width:132px"><strong>ê </strong>mi, <strong>ey </strong>mi</td>
<td style="height:17px; width:161px">benimkiler</td>
</tr>
<tr>
<td style="height:17px; width:132px">Nê <strong>yê </strong>Roşanî yê.</td>
<td style="height:17px; width:132px">Nê <strong>ê</strong>/<strong>ey </strong>Roşanî yê.</td>
<td style="height:17px; width:161px">Bunlar Roşanınkilerdir.</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>Di Tewirî “yê”</h1><br>
<br>
&nbsp;<br>
Kirmanckî de di tewirî “yê” est ê:<br>
Nînan ra yew wezîfeyê <strong>zemîrê wayîrîye </strong>vîneno ke ma cor ra behs kerd.<br>
Yewna “yê”, îzafeya rêzilkî (zincirleme tamlama) de est o. No, partîkelê dîyarkerdişî yo (particule déterminative). Seke nimûneyanê cêrênan ra fehm beno, no “<strong>yê</strong>” seba nêrî û zafhûmarî yo labelê seba makî beno “<strong>ya</strong>”.<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:132px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:217px"><strong>meylê kombîyayîşî </strong>(nimûneyî)</td>
<td style="height:17px; width:170px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:132px"><strong>seba nêrî</strong></td>
<td style="height:17px; width:217px">&nbsp;</td>
<td style="height:17px; width:170px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:132px"><strong>yê</strong></td>
<td style="height:17px; width:217px">kombîyayîşê ziwanî <strong>yê </strong>unîversîte</td>
<td style="height:17px; width:170px">üniversitenin dil toplantısı</td>
</tr>
<tr>
<td style="height:17px; width:132px">&nbsp;</td>
<td style="height:17px; width:217px">karê peyên <strong>yê </strong>grûba ma</td>
<td style="height:17px; width:170px">grubumuzun son işi</td>
</tr>
<tr>
<td style="height:17px; width:132px"><strong>seba makî</strong></td>
<td style="height:17px; width:217px">&nbsp;</td>
<td style="height:17px; width:170px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:132px"><strong>ya</strong></td>
<td style="height:17px; width:217px">deyîra kayî <strong>ya </strong>palîyijan</td>
<td style="height:17px; width:170px">Paluluların oyun türküsü</td>
</tr>
<tr>
<td style="height:17px; width:132px">&nbsp;</td>
<td style="height:17px; width:217px">Grûba Xebate <strong>ya </strong>Vateyî</td>
<td style="height:17px; width:170px">Vate Çalışma Grubu</td>
</tr>
<tr>
<td style="height:17px; width:132px">&nbsp;</td>
<td style="height:17px; width:217px">&nbsp;</td>
<td style="height:17px; width:170px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:132px"><strong>seba zafhûmarî</strong></td>
<td style="height:17px; width:217px">&nbsp;</td>
<td style="height:17px; width:170px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:132px"><strong>yê</strong></td>
<td style="height:17px; width:217px">karê peyênî <strong>yê </strong>grûba ma</td>
<td style="height:17px; width:170px">grubumuzun son işleri</td>
</tr>
<tr>
<td style="height:17px; width:132px">&nbsp;</td>
<td style="height:17px; width:217px">deyîrê kayî <strong>yê </strong>palîyijan</td>
<td style="height:17px; width:170px">Paluluların oyun türküleri</td>
</tr>
<tr>
<td style="height:17px; width:132px">&nbsp;</td>
<td style="height:17px; width:217px">cezayê înan <strong>yê </strong>neweyî</td>
<td style="height:17px; width:170px">onların yeni cezaları</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>4.SIFET (SIFAT)</h1><br>
&nbsp;


<h1>4.1.Tayê Rengî</h1>
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:246px"><strong>formê bînî</strong></td>
<td style="height:17px; width:85px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">çeqer, -e</td>
<td style="height:17px; width:246px">-</td>
<td style="height:17px; width:85px">sarı</td>
</tr>
<tr>
<td style="height:17px; width:140px">hewz, -e</td>
<td style="height:17px; width:246px">sewz, -e</td>
<td style="height:17px; width:85px">yeşil</td>
</tr>
<tr>
<td style="height:17px; width:140px">kesk, -e</td>
<td style="height:17px; width:246px">-</td>
<td style="height:17px; width:85px">yeşil</td>
</tr>
<tr>
<td style="height:17px; width:140px">kewe, -ye<strong>(2)</strong></td>
<td style="height:17px; width:246px">kêwe, kewîye, kewo, kiho, kuhe, kûhe</td>
<td style="height:17px; width:85px">mavi</td>
</tr>
<tr>
<td style="height:17px; width:140px">qer, -e</td>
<td style="height:17px; width:246px">-</td>
<td style="height:17px; width:85px">siyah, kara</td>
</tr>
<tr>
<td style="height:17px; width:140px">qeweyî, -ye</td>
<td style="height:17px; width:246px">rengê qehwî, rengê qawî</td>
<td style="height:17px; width:85px">kahverengi</td>
</tr>
<tr>
<td style="height:17px; width:140px">sipî, -ye</td>
<td style="height:17px; width:246px">sipe, sipê, sipye</td>
<td style="height:17px; width:85px">beyaz, ak</td>
</tr>
<tr>
<td style="height:17px; width:140px">sîs, -e</td>
<td style="height:17px; width:246px">şîs, -e</td>
<td style="height:17px; width:85px">beyaz, ak</td>
</tr>
<tr>
<td style="height:17px; width:140px">sîya, -ye</td>
<td style="height:17px; width:246px">şîya, şa</td>
<td style="height:17px; width:85px">siyah, kara</td>
</tr>
<tr>
<td style="height:17px; width:140px">sûr, -e</td>
<td style="height:17px; width:246px">sur, -e</td>
<td style="height:17px; width:85px">kırmızı</td>
</tr>
<tr>
<td style="height:17px; width:140px">şalên, -e</td>
<td style="height:17px; width:246px">şalîn, -i</td>
<td style="height:17px; width:85px">kahverengi</td>
</tr>
<tr>
<td style="height:17px; width:140px">zerd, -e<strong>(3)</strong></td>
<td style="height:17px; width:246px">zer, zerk</td>
<td style="height:17px; width:85px">sarı</td>
</tr>
<tr>
<td style="height:17px; width:140px">zergûn, -e<strong>(4)</strong></td>
<td style="height:17px; width:246px">zergun, -e</td>
<td style="height:17px; width:85px">yeşil</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>4.2.Sifetê Nîşanî</h1><br>

<h1>4.2.1.Semedê Nêzdî</h1><br>
<p><strong> halê xoserî de</strong></p>
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:208px"><strong>formê bînî</strong></td>
<td style="height:17px; width:161px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:34px; width:140px">no (n)<br>
<strong>No </strong>lajek vano.</td>
<td style="height:34px; width:208px">nû, eno, enû, eni, ino, ini, in, na</td>
<td style="height:34px; width:161px">bu<br>
Bu oğlan söylüyor.</td>
</tr>
<tr>
<td style="height:34px; width:140px">na (m)<br>
<strong>Na </strong>kêneke vana.</td>
<td style="height:34px; width:208px">ena, ina, hena</td>
<td style="height:34px; width:161px">bu<br>
Bu kız söylüyor.</td>
</tr>
<tr>
<td style="height:51px; width:140px">nê (zh)<br>
<strong>Nê </strong>lajekî vanê.<br>
<strong>Nê </strong>kênekî vanê.</td>
<td style="height:51px; width:208px">nî, enî, inî, enê, inê, na</td>
<td style="height:51px; width:161px">bunlar<br>
Bu oğlanlar söylüyorlar. Bu kızlar söylüyorlar.</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>halê anteyî (oblîkî) de</h1><br>
&nbsp;

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:208px"><strong>formê bînî</strong></td>
<td style="height:17px; width:161px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:34px; width:140px">nê (n)<br>
<strong>Nê </strong>lajekî ra vaje.</td>
<td style="height:34px; width:208px">enê, enî, inî, na</td>
<td style="height:34px; width:161px">bu<br>
Bu oğlana söyle.</td>
</tr>
<tr>
<td style="height:34px; width:140px">na (m)<br>
<strong>Na </strong>kêneke ra vaje.</td>
<td style="height:34px; width:208px">ena, ina, hena</td>
<td style="height:34px; width:161px">bu<br>
Bu kıza söyle.</td>
</tr>
<tr>
<td style="height:51px; width:140px">nê (zh)<br>
<strong>Nê </strong>lajekan ra vaje.<br>
<strong>Nê </strong>kênekan ra vaje.</td>
<td style="height:51px; width:208px">nî, enî, inî, enê, inê, na</td>
<td style="height:51px; width:161px">bunlar<br>
Bu oğlanlara söyle. Bu kızlara söyle.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;<br>
&nbsp;
<h1>4.2.2.Semedê Dûrî</h1>
<p> halê xoserî de</p>


<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:208px"><strong>formê bînî</strong></td>
<td style="height:17px; width:161px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:34px; width:140px">o (n)<br>
<strong>O </strong>lajek vano.</td>
<td style="height:34px; width:208px">ew, ewi, aw, hew, ay, û</td>
<td style="height:34px; width:161px">o<br>
O oğlan söylüyor.</td>
</tr>
<tr>
<td style="height:34px; width:140px">a (m)<br>
<strong>A </strong>kêneke vana.</td>
<td style="height:34px; width:208px">haw, eya, aya</td>
<td style="height:34px; width:161px">o<br>
O kız söylüyor.</td>
</tr>
<tr>
<td style="height:50px; width:140px">ê (zh)<br>
<strong>Ê </strong>lajekî vanê.<br>
<strong>Ê </strong>kênekî vanê.</td>
<td style="height:50px; width:208px">î, eyî, heyî, ay</td>
<td style="height:50px; width:161px">onlar<br>
O oğlanlar söylüyor. O kızlar söylüyorlar.</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>halê anteyî (oblîkî) de</h1><br>

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:208px"><strong>formê bînî</strong></td>
<td style="height:17px; width:161px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:34px; width:140px">ê (n)<br>
<strong>Ê </strong>lajekî ra vaje.</td>
<td style="height:34px; width:208px">ay, î</td>
<td style="height:34px; width:161px">o<br>
O oğlana söyle.</td>
</tr>
<tr>
<td style="height:34px; width:140px">a (m)<br>
<strong>A </strong>kêneke ra vaje.</td>
<td style="height:34px; width:208px">eya, aya</td>
<td style="height:34px; width:161px">o<br>
O kıza söyle.</td>
</tr>
<tr>
<td style="height:51px; width:140px">ê (zh)<br>
<strong>Ê </strong>lajekan ra vaje.<br>
<strong>Ê </strong>kênekan ra vaje.</td>
<td style="height:51px; width:208px">î, eyî, heyî, ay</td>
<td style="height:51px; width:161px">onlar<br>
O oğlanlara söyle. O kızlara söyle.</td>
</tr>
</tbody>
</table>
&nbsp;
<h1>4.3.Hûmarnameyî</h1><br>


Kirmanckî de hûmarnameyî (sayı sözcükleri) fek ra fek cîya vajîyenî. Tercîhê kombîyayîşî û formê bînî wina yê:<br>
<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:397px"><strong>formê bînî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">1 yew</td>
<td style="height:17px; width:397px">yow, yo, jew, jû, ju, zew, zû, zu</td>
</tr>
<tr>
<td style="height:17px; width:140px">2 di</td>
<td style="height:17px; width:397px">du, didi, dudu</td>
</tr>
<tr>
<td style="height:17px; width:140px">3 hîrê</td>
<td style="height:17px; width:397px">hîre, hîri, hîrye</td>
</tr>
<tr>
<td style="height:17px; width:140px">4 çar</td>
<td style="height:17px; width:397px">çehar, çihar, çeher, çîyer, çer, çor</td>
</tr>
<tr>
<td style="height:17px; width:140px">5 panc</td>
<td style="height:17px; width:397px">panj, ponc, pûnc, ponz</td>
</tr>
<tr>
<td style="height:17px; width:140px">6 şeş</td>
<td style="height:17px; width:397px">ses</td>
</tr>
<tr>
<td style="height:17px; width:140px">7 hewt</td>
<td style="height:17px; width:397px">hawt, howt, hot</td>
</tr>
<tr>
<td style="height:17px; width:140px">8 heşt</td>
<td style="height:17px; width:397px">heyşt</td>
</tr>
<tr>
<td style="height:17px; width:140px">9 new</td>
<td style="height:17px; width:397px">now, no</td>
</tr>
<tr>
<td style="height:17px; width:140px">10 des</td>
<td style="height:17px; width:397px">-</td>
</tr>
<tr>
<td style="height:34px; width:140px">11 yewendes</td>
<td style="height:34px; width:397px">yewndes, yowndes, yondes, yondas, zûyes;<br>
des î yiw, des û jû, des û jew, des û zû</td>
</tr>
<tr>
<td style="height:17px; width:140px">12 diwês</td>
<td style="height:17px; width:397px">duyes, duyês; des û di, des û didi, des î di</td>
</tr>
<tr>
<td style="height:17px; width:140px">13 hîrês</td>
<td style="height:17px; width:397px">hîres, hîryes; des û hîrê, des û hîre, des û hîri, des î hîrê</td>
</tr>
<tr>
<td style="height:17px; width:140px">14 çarês</td>
<td style="height:17px; width:397px">çares; des û çar, des û çihar, des î çeer</td>
</tr>
<tr>
<td style="height:17px; width:140px">15 pancês</td>
<td style="height:17px; width:397px">poncês, pûncês; des û panc, des û ponz, des î pûnc</td>
</tr>
<tr>
<td style="height:17px; width:140px">16 şîyês</td>
<td style="height:17px; width:397px">şîyes, sêyês; des û şeş, des û ses, des î seş</td>
</tr>
<tr>
<td style="height:17px; width:140px">17 hewtês</td>
<td style="height:17px; width:397px">howtês, hotês; des û hewt, des û hot, des û huwt</td>
</tr>
<tr>
<td style="height:17px; width:140px">18 heştês</td>
<td style="height:17px; width:397px">heyştês; des û heşt, des î heşt</td>
</tr>
<tr>
<td style="height:17px; width:140px">19 newês</td>
<td style="height:17px; width:397px">newîyes, newyes, nêwyes, nebîyes, nebyes; des û new, des î niw</td>
</tr>
<tr>
<td style="height:17px; width:140px">20 vîst</td>
<td style="height:17px; width:397px">vîşt</td>
</tr>
<tr>
<td style="height:17px; width:140px">30 hîris</td>
<td style="height:17px; width:397px">hîriş</td>
</tr>
<tr>
<td style="height:17px; width:140px">40 çewres</td>
<td style="height:17px; width:397px">çewreş, çores, çoras</td>
</tr>
<tr>
<td style="height:17px; width:140px">50 pancas</td>
<td style="height:17px; width:397px">poncas, pûncas</td>
</tr>
<tr>
<td style="height:17px; width:140px">60 şeştî</td>
<td style="height:17px; width:397px">şêst, şest, şêştî, şêşt, şeyştî</td>
</tr>
<tr>
<td style="height:17px; width:140px">70 hewtay</td>
<td style="height:17px; width:397px">hawtay, hotay, hotayê</td>
</tr>
<tr>
<td style="height:17px; width:140px">80 heştay</td>
<td style="height:17px; width:397px">hêştay, hêştayê, heyştay</td>
</tr>
<tr>
<td style="height:17px; width:140px">90 neway</td>
<td style="height:17px; width:397px">newayê, noway</td>
</tr>
<tr>
<td style="height:17px; width:140px">100 se</td>
<td style="height:17px; width:397px">-</td>
</tr>
<tr>
<td style="height:17px; width:140px">200 di sey</td>
<td style="height:17px; width:397px">di sê</td>
</tr>
<tr>
<td style="height:17px; width:140px">300 hîrê sey</td>
<td style="height:17px; width:397px">hîri sey, hîr sey, hîr sê</td>
</tr>
<tr>
<td style="height:17px; width:140px">400 çar sey</td>
<td style="height:17px; width:397px">çar sê, çeher sey, çer sey</td>
</tr>
<tr>
<td style="height:17px; width:140px">500 panc sey</td>
<td style="height:17px; width:397px">pan sey, ponc sey, pon sey, pûn sey</td>
</tr>
<tr>
<td style="height:17px; width:140px">600 şeş sey</td>
<td style="height:17px; width:397px">şeş sê, ses sey</td>
</tr>
<tr>
<td style="height:17px; width:140px">700 hewt sey</td>
<td style="height:17px; width:397px">howt sey, hot sey, hot sê</td>
</tr>
<tr>
<td style="height:17px; width:140px">800 heşt sey</td>
<td style="height:17px; width:397px">heyşt sey, hêşt sey, heşt sê</td>
</tr>
<tr>
<td style="height:17px; width:140px">900 new sey</td>
<td style="height:17px; width:397px">now sey, no sey, no sê</td>
</tr>
<tr>
<td style="height:17px; width:140px">1000 hezar</td>
<td style="height:17px; width:397px">henzar, hinzar, honzar, hûnzar, hazar, himzar</td>
</tr>
<tr>
<td style="height:17px; width:140px">1000 000 mîlyon</td>
<td style="height:17px; width:397px">mîlun</td>
</tr>
<tr>
<td style="height:17px; width:140px">1000 000 000 mîlyar</td>
<td style="height:17px; width:397px">mîlar</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Nimûneyî:<br>
1925 hezar û new sey û vîst û panc 1938 hezar û new sey û hîris û heşt 1997 hezar û new sey û neway û hewt 2005 di hezarî û panc<br>
1567849 yew mîlyon û panc sey û şeştî û hewt hezarî û heşt sey û çewres û new<br>
&nbsp;<br>
&nbsp;
<h1>a)Kirmanckî de hûmarnameyî heme (pêro) nêrî yê.</h1><br>
Nimûne: 1, 7, 10, 40.<br>
-No çi yo?<br>
<br>
&nbsp;<br>
-No yew o, no hewt o, no des o, no zî çewres o. Yewê aşme, hewtê aşme, desê aşme, vîstê aşme. Çewresê Paksar û Polatî tê kewtîy.<br>
Dewa ma de se ra neway û newê kênekan nêşîbîy wendegeh.<br>
&nbsp;<br>
“Êro hot o, hot o, hot o; wî dayê, wî dayê<br>
Êro hot o, hot o, hot o; wî leyro, Huznayê.” <em>(Malmîsanij, Folklorê Ma ra Çend Numûney, r. 29)</em><br>
“Erê, -wilay mi va- “şeş o”, lo, to va “şeş o”. Erê, wilay şeş o, şeş o, lo ez nêweş o.<br>
Erê, wilay ez nêweşê lo beşna weş o.” <em>(Malmîsanij, Folklorê Ma ra Çend Numûney, r. 80)</em><br>
&nbsp;<br>
Serrî bi reqeman yan zî bi herfan nusîyenî. Nimûne:
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:208px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:227px"><strong>formê bînî</strong></td>
<td style="height:17px; width:180px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:208px">serra 1925î</td>
<td style="height:17px; width:227px">serra 1925ine</td>
<td style="height:17px; width:180px">1925 yılı</td>
</tr>
<tr>
<td style="height:17px; width:208px">serra 1938î</td>
<td style="height:17px; width:227px">serra 1938ine</td>
<td style="height:17px; width:180px">1938 yılı</td>
</tr>
<tr>
<td style="height:34px; width:208px">serra hezar û new sey û vîst û<br>
pancî</td>
<td style="height:34px; width:227px">serra hezar û new sey û vîst û<br>
pancine</td>
<td style="height:34px; width:180px">bin dokuz yüz yirmi beş yılı</td>
</tr>
<tr>
<td style="height:34px; width:208px">serra hezar û new sey û hîris û<br>
heştî</td>
<td style="height:34px; width:227px">serra hezar û new sey û hîris û<br>
heştine</td>
<td style="height:34px; width:180px">bin dokuz yüz otuz sekiz<br>
yılı</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>b)Eke seba peran ûsn. vajîyo, hûmarname bi formê makî vajîyeno.</h1><br>
Nimûne:<br>
Dilşa yew hezarine, yew seyine û yew desine day mi. (Dılşa bana bir binlik [bin liralık], bir yüzlük ve bir onluk verdi.)<br>
&nbsp;
<h1>4.3.1.Hûmarnameyê Rêzkî (Sıra Sayılar)</h1><br>
Hûmarnameyê bingeyî (asıl sayılar) tayê fekan de bi peygirê “<strong>-in</strong>”î tayê fekan de zî bi peygirê “- <strong>în</strong>”î benê hûmarnameyê rêzkî (sıra sayılar). Meylê kombîyayîşî formê bi “<strong>-in</strong>”î ser o yo. Mavajî “çar”, beno “çarin” (n) û “çarine” (m).<br>
<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:177px"><strong>kirmanckî</strong></td>
<td style="height:17px; width:104px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:177px">1. yewin, -e</td>
<td style="height:17px; width:104px">birinci</td>
</tr>
<tr>
<td style="height:17px; width:177px">2. dîyin, -e</td>
<td style="height:17px; width:104px">ikinci</td>
</tr>
<tr>
<td style="height:17px; width:177px">3. hîrêyin, -e</td>
<td style="height:17px; width:104px">üçüncü</td>
</tr>
<tr>
<td style="height:17px; width:177px">4. çarin, -e</td>
<td style="height:17px; width:104px">dördüncü</td>
</tr>
<tr>
<td style="height:17px; width:177px">5. pancin, -e</td>
<td style="height:17px; width:104px">beşinci</td>
</tr>
<tr>
<td style="height:17px; width:177px">6. şeşin, -e</td>
<td style="height:17px; width:104px">altıncı</td>
</tr>
<tr>
<td style="height:17px; width:177px">7. hewtin, -e</td>
<td style="height:17px; width:104px">yedinci</td>
</tr>
<tr>
<td style="height:17px; width:177px">8. heştin, -e</td>
<td style="height:17px; width:104px">sekizinci</td>
</tr>
<tr>
<td style="height:17px; width:177px">9. newin, -e</td>
<td style="height:17px; width:104px">dokuzuncu</td>
</tr>
<tr>
<td style="height:17px; width:177px">10. desin, -e</td>
<td style="height:17px; width:104px">onuncu</td>
</tr>
<tr>
<td style="height:17px; width:177px">11. yewendesin, -e</td>
<td style="height:17px; width:104px">onbirinci</td>
</tr>
<tr>
<td style="height:17px; width:177px">12. diwêsin, -e</td>
<td style="height:17px; width:104px">onikinci</td>
</tr>
<tr>
<td style="height:17px; width:177px">13. hîrêsin, -e</td>
<td style="height:17px; width:104px">onüçüncü</td>
</tr>
<tr>
<td style="height:17px; width:177px">14. çarêsin, -e</td>
<td style="height:17px; width:104px">ondördüncü</td>
</tr>
<tr>
<td style="height:17px; width:177px">15. pancêsin, -e</td>
<td style="height:17px; width:104px">onbeşinci</td>
</tr>
<tr>
<td style="height:17px; width:177px">16. şîyêsin, -e</td>
<td style="height:17px; width:104px">onaltıncı</td>
</tr>
<tr>
<td style="height:17px; width:177px">17. hewtêsin, -e</td>
<td style="height:17px; width:104px">onyedinci</td>
</tr>
<tr>
<td style="height:17px; width:177px">18. heştêsin, -e</td>
<td style="height:17px; width:104px">onsekizinci</td>
</tr>
<tr>
<td style="height:17px; width:177px">19. newêsin, -e</td>
<td style="height:17px; width:104px">ondokuzuncu</td>
</tr>
<tr>
<td style="height:17px; width:177px">20. vîstin, -e</td>
<td style="height:17px; width:104px">yirminci</td>
</tr>
<tr>
<td style="height:17px; width:177px">30. hîrisin, -e</td>
<td style="height:17px; width:104px">otuzuncu</td>
</tr>
<tr>
<td style="height:17px; width:177px">40. çewresin, -e</td>
<td style="height:17px; width:104px">kırkıncı</td>
</tr>
<tr>
<td style="height:17px; width:177px">50. pancasin, -e</td>
<td style="height:17px; width:104px">ellinci</td>
</tr>
<tr>
<td style="height:17px; width:177px">60. şeştîyin, -e</td>
<td style="height:17px; width:104px">altmışıncı</td>
</tr>
<tr>
<td style="height:17px; width:177px">70. hewtayin, -e</td>
<td style="height:17px; width:104px">yetmişinci</td>
</tr>
<tr>
<td style="height:17px; width:177px">80. heştayin, -e</td>
<td style="height:17px; width:104px">sekseninci</td>
</tr>
<tr>
<td style="height:17px; width:177px">90. newayin, -e</td>
<td style="height:17px; width:104px">doksanıncı</td>
</tr>
<tr>
<td style="height:17px; width:177px">100. seyin, -e</td>
<td style="height:17px; width:104px">yüzüncü</td>
</tr>
<tr>
<td style="height:17px; width:177px">500. pancseyin, -e</td>
<td style="height:17px; width:104px">beşyüzüncü</td>
</tr>
<tr>
<td style="height:17px; width:177px">700. hewtseyin, -e</td>
<td style="height:17px; width:104px">yediyüzüncü</td>
</tr>
<tr>
<td style="height:17px; width:177px">900. newseyin, -e</td>
<td style="height:17px; width:104px">dokuzyüzüncü</td>
</tr>
<tr>
<td style="height:17px; width:177px">1000. hezarin, -e</td>
<td style="height:17px; width:104px">bininci</td>
</tr>
<tr>
<td style="height:17px; width:177px">1000 000. mîlyonin, -e</td>
<td style="height:17px; width:104px">milyonuncu</td>
</tr>
<tr>
<td style="height:17px; width:177px">1000 000 000. mîlyarin, -e</td>
<td style="height:17px; width:104px">milyarıncı</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Hûmarnameyê rêzkî, cayê herfan de ge-ge bi hûmaran (sayılar) zî nusîyenî. <strong>Eke hûmarêke dima nuqta ronîyaye, a hûmare bena hûmara rêzkî</strong>. Nimûne:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:34px; width:123px"><strong>Kirmanckî bi herfan</strong></td>
<td style="height:34px; width:123px"><strong>Kirmanckî bi hûmaran</strong></td>
<td style="height:34px; width:85px"><strong>Tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:123px">yew</td>
<td style="height:17px; width:123px">1</td>
<td style="height:17px; width:85px">bir</td>
</tr>
<tr>
<td style="height:17px; width:123px"><strong>yewin, -e</strong></td>
<td style="height:17px; width:123px">1<strong>.</strong></td>
<td style="height:17px; width:85px"><strong>birinci</strong></td>
</tr>
<tr>
<td style="height:17px; width:123px">vîst</td>
<td style="height:17px; width:123px">20</td>
<td style="height:17px; width:85px">yirmi</td>
</tr>
<tr>
<td style="height:17px; width:123px"><strong>vîstine</strong></td>
<td style="height:17px; width:123px">20<strong>.</strong></td>
<td style="height:17px; width:85px"><strong>yirminci</strong></td>
</tr>
</tbody>
</table>
&nbsp;<br>
<br>
&nbsp;<br>
de.<br>
<br>
Wendekarî verê mektebî de rêz bîy. Bengîn rêza <strong>vîst û çarine </strong>de yo, Şarîbane yê <strong>vîst û pancine</strong><br>
&nbsp;<br>
Wendekarî verê mektebî de rêz bîy. Bengîn rêza <strong>24. </strong>de yo, Şarîbane yê <strong>25. </strong>de. Seba serran (tarîxan) hûmarnameyê rêzkî wina nusîyenî:<br>
<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:187px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:180px"><strong>formê bînî</strong></td>
<td style="height:17px; width:208px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:187px">serra 1925.</td>
<td style="height:17px; width:180px">serra 1925ine</td>
<td style="height:17px; width:208px">1925. yıl</td>
</tr>
<tr>
<td style="height:17px; width:187px">serra 1938.</td>
<td style="height:17px; width:180px">serra 1938ine</td>
<td style="height:17px; width:208px">1938. yıl</td>
</tr>
<tr>
<td style="height:17px; width:187px">serra 1983.</td>
<td style="height:17px; width:180px">serra 1983yine</td>
<td style="height:17px; width:208px">1983. yıl</td>
</tr>
<tr>
<td style="height:34px; width:187px">serra hezar û new sey û vîst<br>
û pancine</td>
<td style="height:34px; width:180px">serra hezar û new sey û vîst<br>
û pancîne</td>
<td style="height:34px; width:208px">bin dokuz yüz yirmi beşinci yıl</td>
</tr>
<tr>
<td style="height:34px; width:187px">serra hezar û new sey û hîris<br>
û heştine</td>
<td style="height:34px; width:180px">serra hezar û new sey û<br>
hîris û heştîne</td>
<td style="height:34px; width:208px">bin dokuz yüz otuz sekizinci yıl</td>
</tr>
<tr>
<td style="height:34px; width:187px">serra hezar û new sey û<br>
heştay û hîrêyine</td>
<td style="height:34px; width:180px">serra hezar û new sey û<br>
heştay û hêriyîne</td>
<td style="height:34px; width:208px">bin dokuz yüz seksen üçüncü yıl</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>4.4.Nuştişê Partîsîpê Makî û Zafhûmarî</h1><br>
“Partîsîp”anê ke tîya de behsê înan beno ra franskî de “participe passé” yan zî “adjectif verbal”, tirkî de “sıfat-fiil, ortaç” vajîyeno.<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:262px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:180px"><strong>formê bînî</strong></td>
<td style="height:17px; width:132px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:262px">A <strong>wendîye </strong>ya./A <strong>wenda </strong>ya./A <strong>wendê </strong>ya.</td>
<td style="height:17px; width:180px">A wend<strong>a </strong>a./A wend<strong>a </strong>wa.</td>
<td style="height:17px; width:132px">O tahsillidir.</td>
</tr>
<tr>
<td style="height:34px; width:262px">Ê <strong>wende </strong>yê.</td>
<td style="height:34px; width:180px">Ê wend<strong>eyî </strong>yê./Ê wend<strong>e </strong>yî./<br>
Ê wend <strong>ê</strong>.</td>
<td style="height:34px; width:132px">Onlar tahsillidirler.</td>
</tr>
</tbody>
</table>
Seke aseno, kopula ra ver partîsîpo zafhûmar zî bi formê yewhûmarî nusîyeno; <strong>zafmûmarîya ey kopula ra û karî ra fehm bena</strong>. Yanî cumleya peyêne de “<strong>yê</strong>” seba zafhûmarî yo, coka merdim fehm keno ke partîsîp (yanî “wende”) <strong>bi formê yewhûmarî yo la zafhûmarîye îfade keno</strong>.<br>
&nbsp;
<h1>4.5.Nuştişê Çekuya “Her” û “Heme”yî</h1><br>

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:225px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:274px"><strong>formê bînî</strong></td>
<td style="height:17px; width:104px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:225px">her</td>
<td style="height:17px; width:274px">hir, hur, wir, wur</td>
<td style="height:17px; width:104px">her</td>
</tr>
<tr>
<td style="height:34px; width:225px">her yew</td>
<td style="height:34px; width:274px">her go, her gû, her gi, her g’, hir g’,<br>
wir yew, wir go, wir gû</td>
<td style="height:34px; width:104px">her bir, her biri</td>
</tr>
<tr>
<td style="height:34px; width:225px">her di</td>
<td style="height:34px; width:274px">hur di, hur dî,<br>
wir di, wir dî, wir d’, wur di, wur dî</td>
<td style="height:34px; width:104px">her iki</td>
</tr>
<tr>
<td style="height:67px; width:225px">her dîyan, her dîyine</td>
<td style="height:67px; width:274px">hur dêna, hur dimêna, hur demêna, hur dimîn, hur dimîna, hur dimîne, hur demîne, hur dîn, hur dêmîna,<br>
wir dînî, wirnan, wirna, wur dînî, wurna</td>
<td style="height:67px; width:104px">her ikisi</td>
</tr>
<tr>
<td style="height:17px; width:225px">her hîrê, heme hîrê, hîrê heme</td>
<td style="height:17px; width:274px">hîrême</td>
<td style="height:17px; width:104px">her üç</td>
</tr>
<tr>
<td style="height:34px; width:225px">her&nbsp;&nbsp;&nbsp; hîrine,&nbsp;&nbsp; heme&nbsp;&nbsp;&nbsp; hîrine,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; hîrê<br>
hemîne</td>
<td style="height:34px; width:274px">hîrêmîna, hîremêna, hîremîna, hîremîne</td>
<td style="height:34px; width:104px">her üçü</td>
</tr>
<tr>
<td style="height:34px; width:225px">her çar<br>
heme çar, çar heme</td>
<td style="height:34px; width:274px">hur çar, wir çar, wur çar<br>
çareme, çihar heme</td>
<td style="height:34px; width:104px">her dört</td>
</tr>
<tr>
<td style="height:34px; width:225px">her çarine, heme çarine, çar hemîne</td>
<td style="height:34px; width:274px">çaremîna, çarêmêna, çaremêne, çoremêna,<br>
çoremîne</td>
<td style="height:34px; width:104px">her dördü</td>
</tr>
<tr>
<td style="height:17px; width:225px">her panc, heme panc, panc heme</td>
<td style="height:17px; width:274px">&nbsp;</td>
<td style="height:17px; width:104px">her beş</td>
</tr>
<tr>
<td style="height:34px; width:225px">her pancine, heme pancine, panc<br>
hemîne</td>
<td style="height:34px; width:274px">&nbsp;</td>
<td style="height:34px; width:104px">her beşi</td>
</tr>
<tr>
<td style="height:17px; width:225px">her kes</td>
<td style="height:17px; width:274px">wir kes</td>
<td style="height:17px; width:104px">herkes</td>
</tr>
<tr>
<td style="height:17px; width:225px">her roje</td>
<td style="height:17px; width:274px">her roj, wir ro</td>
<td style="height:17px; width:104px">her gün</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>5.EDAT (İLGEÇ, EDAT)</h1><br>

Kirmanckî de edatî hîrê çeşîtî yê û çekuyanê bînan ra cîya nusîyenê. Tîya de ma çend nimûneyan nusenî:<br>
<br>
&nbsp;
<h1>5.1.Veredat (Önedat)</h1><br>
<h1>5.1.1.bi</h1><br>

Edatê <strong>“bi”</strong>, ca ra ca cîya vajîyeno. Sey <strong>“bi”</strong>, <strong>“be”</strong>, <strong>“ve”</strong>, <strong>“ebi”</strong>, <strong>“ebe”</strong>, <strong>“eve”</strong>. Kombîyayîşî nînan ra “bi” tercîh kerd. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:149px"><strong>kirmanckî</strong></td>
<td style="height:17px; width:161px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:149px"><strong>bi</strong></td>
<td style="height:17px; width:161px">ile</td>
</tr>
<tr>
<td style="height:17px; width:149px"><strong>Bi </strong>qeleme nuşt.</td>
<td style="height:17px; width:161px">Kalemle yazdı.</td>
</tr>
<tr>
<td style="height:17px; width:149px"><strong>Bi </strong>dest werd.</td>
<td style="height:17px; width:161px">Elle yedi.</td>
</tr>
<tr>
<td style="height:17px; width:149px"><strong>Bi </strong>peranê xo erînayo.</td>
<td style="height:17px; width:161px">Parasıyla satın almış.</td>
</tr>
<tr>
<td style="height:17px; width:149px"><strong>bi</strong></td>
<td style="height:17px; width:161px">-e</td>
</tr>
<tr>
<td style="height:17px; width:149px">Ez şina <strong>bi </strong>Dîyarbekir.</td>
<td style="height:17px; width:161px">Diyarbekir’e gidiyorum.</td>
</tr>
<tr>
<td style="height:17px; width:149px">Yeno <strong>bi </strong>dewe.</td>
<td style="height:17px; width:161px">Köye gelir.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
“Bi”ya ke <strong>veredat </strong>(önedat) o, cîya nusîyena labelê “<strong>bi</strong>”ya ke rolê <strong>vergirî </strong>(önek) vînena pîya (pêra) nusîyena. Yanî, eke “<strong>bi-</strong>” çekuyêk ra ver bêro û <strong>sifet virazo</strong>, “<strong>bi-</strong>” û çekuya ke dima yena pîya nusîyenê. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:92px"><strong>kirmanckî</strong></td>
<td style="height:17px; width:76px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:92px"><strong>bi-</strong></td>
<td style="height:17px; width:76px">-lı</td>
</tr>
<tr>
<td style="height:17px; width:92px"><strong>bi</strong>aqil, -e</td>
<td style="height:17px; width:76px">akıllı</td>
</tr>
<tr>
<td style="height:17px; width:92px"><strong>bi</strong>qewet, -e</td>
<td style="height:17px; width:76px">kuvvetli</td>
</tr>
<tr>
<td style="height:17px; width:92px"><strong>bi</strong>veng, -e</td>
<td style="height:17px; width:76px">sesli</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>5.1.2.bê</h1><br>
Ma <strong>bê </strong>to nêşinê. (Biz sensiz gitmeyiz.)<br>
O <strong>bê </strong>wendiş ranêkewtinî. (O okumadan uyumazdı.)<br>
Seke cor ra aseno, “<strong>bê</strong>”ya ke <strong>veredat </strong>(önedat) o, cîya nusîyena. Labelê seke cêr ra aseno, “<strong>bê-</strong>”ya ke rolê <strong>vergirî </strong>vînena pîya nusîyena. Yanî, eke “<strong>bê-</strong>” çekuyêk ra ver bêro û <strong>sifet virazo</strong>, “<strong>bê-</strong>” û çekuya ke dima yena pîya nusîyenê. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:234px"><strong>kirmanckî</strong></td>
<td style="height:17px; width:104px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:234px"><strong>bê</strong>aqil, -e</td>
<td style="height:17px; width:104px">akılsız</td>
</tr>
<tr>
<td style="height:17px; width:234px"><strong>bê</strong>bext, -e</td>
<td style="height:17px; width:104px">kalleş</td>
</tr>
<tr>
<td style="height:17px; width:234px"><strong>bê</strong>çare (n), <strong>bê</strong>çara (m), <strong>bê</strong>çarîye (m)</td>
<td style="height:17px; width:104px">çaresiz, biçare</td>
</tr>
<tr>
<td style="height:17px; width:234px"><strong>bê</strong>kar, -e</td>
<td style="height:17px; width:104px">işsiz</td>
</tr>
<tr>
<td style="height:17px; width:234px"><strong>bê</strong>kes, -e</td>
<td style="height:17px; width:104px">kimsesiz</td>
</tr>
<tr>
<td style="height:17px; width:234px"><strong>bê</strong>namûs, -e</td>
<td style="height:17px; width:104px">namussuz</td>
</tr>
<tr>
<td style="height:17px; width:234px"><strong>bê</strong>sol, -e</td>
<td style="height:17px; width:104px">tuzsuz</td>
</tr>
<tr>
<td style="height:17px; width:234px"><strong>bê</strong>şeref, -e</td>
<td style="height:17px; width:104px">şerefsiz</td>
</tr>
<tr>
<td style="height:17px; width:234px"><strong>bê</strong>veng, -e</td>
<td style="height:17px; width:104px">sessiz</td>
</tr>
</tbody>
</table>

<h1>5.1.3.se, sey</h1><br>
Kombîyayîşî formê “se” û “sey”î tercîh kerdo labelê seke cêr ra aseno, edatê “se”/“sey”î ca ra ca cîya vajîyeno.<br>
<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:215px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:170px"><strong>formê bînî</strong></td>
<td style="height:17px; width:217px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:215px"><strong>se</strong></td>
<td style="height:17px; width:170px"><strong>ze</strong>, <strong>heze</strong>, <strong>je</strong></td>
<td style="height:17px; width:217px">gibi</td>
</tr>
<tr>
<td style="height:17px; width:215px"><strong>sey</strong></td>
<td style="height:17px; width:170px"><strong>zey</strong>, <strong>hezey</strong>, <strong>hezê</strong>, <strong>sê</strong>,<strong>zê</strong>, <strong>jê</strong></td>
<td style="height:17px; width:217px">gibi, kadar</td>
</tr>
<tr>
<td style="height:17px; width:215px">Xortî <strong>sey </strong>camêrdan xo ver ro da.</td>
<td style="height:17px; width:170px">&nbsp;</td>
<td style="height:17px; width:217px">Delikanlı erkekçe/yiğitçe direndi.</td>
</tr>
<tr>
<td style="height:17px; width:215px">Ez <strong>sey </strong>to nîya.</td>
<td style="height:17px; width:170px">&nbsp;</td>
<td style="height:17px; width:217px">Ben senin gibi/kadar değilim.</td>
</tr>
<tr>
<td style="height:17px; width:215px">A <strong>sey </strong>yew vila pulisîyaya bî.</td>
<td style="height:17px; width:170px">&nbsp;</td>
<td style="height:17px; width:217px">O solgun bir çiçek gibiydi.</td>
</tr>
<tr>
<td style="height:17px; width:215px">Sipî yo <strong>se </strong>vewre.</td>
<td style="height:17px; width:170px">&nbsp;</td>
<td style="height:17px; width:217px">Kar gibi beyazdır.</td>
</tr>
<tr>
<td style="height:17px; width:215px">Hende nemr o ke, <strong>se </strong>peme.</td>
<td style="height:17px; width:170px">&nbsp;</td>
<td style="height:17px; width:217px">O kadar yumuşak ki, pamuk gibi.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>5.1.4.pê</h1><br>

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:215px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:180px"><strong>formê bînî</strong></td>
<td style="height:17px; width:198px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:215px"><strong>pê</strong></td>
<td style="height:17px; width:180px"><strong>pey</strong></td>
<td style="height:17px; width:198px">ile</td>
</tr>
<tr>
<td style="height:17px; width:215px"><strong>Pê </strong>otobuse ame.</td>
<td style="height:17px; width:180px"><strong>Pey </strong>otobuse ame.</td>
<td style="height:17px; width:198px">Otobüsle geldi.</td>
</tr>
<tr>
<td style="height:17px; width:215px"><strong>Pê </strong>pereyan gureyê xo viraşto.</td>
<td style="height:17px; width:180px"><strong>Pey </strong>peran gurey xo viraşto.</td>
<td style="height:17px; width:198px">Parayla işini yapmış.</td>
</tr>
<tr>
<td style="height:34px; width:215px">To <strong>pê </strong>qalanê xo zerrîya ey şikite.</td>
<td style="height:34px; width:180px">To <strong>pey </strong>qalanê xo zerrîya ey<br>
şikite.</td>
<td style="height:34px; width:198px">Sözlerinle onun kalbini kırdın.</td>
</tr>
</tbody>
</table>
&nbsp;

<ol>
<li><h1>5.1.5.ver bi</h1></li>
</ol>
Ez şina <strong>ver bi </strong>Dîyarbekir. (Diyarbekir’e doğru gidiyorum.)<br>
Ez peykanî şona la o zî <strong>ver bi </strong>mi yeno. (Ben gerisin geri gidiyorum fakat o da bana doğru geliyor.)<br>
&nbsp;
<h1>5.2.Peyedat (Artedat)</h1><br>

<ol>
<li><h1>5.2.1.rê</h1></li>
</ol>
Tayê fekan de cayê “<strong>rê</strong>”yî de “<strong>rî</strong>”, “<strong>ri</strong>”, “<strong>re</strong>” yan zî “<strong>r</strong>” vajîyeno. Kombîyayîşî nê forman ra “rê” tercîh kerdo. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:132px"><strong>formê bînî</strong></td>
<td style="height:17px; width:132px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>rê</strong></td>
<td style="height:17px; width:132px"><strong>rî, ri, re, r’</strong></td>
<td style="height:17px; width:132px">-e</td>
</tr>
<tr>
<td style="height:17px; width:140px">Kamî <strong>rê</strong>?</td>
<td style="height:17px; width:132px">Komî <strong>rî</strong>?/Kûmî <strong>ri</strong>?</td>
<td style="height:17px; width:132px">Kime?</td>
</tr>
<tr>
<td style="height:17px; width:140px">mi <strong>rê</strong></td>
<td style="height:17px; width:132px">mi <strong>rî</strong>, mi <strong>re</strong>, mi <strong>r’</strong></td>
<td style="height:17px; width:132px">bana</td>
</tr>
<tr>
<td style="height:17px; width:140px">Wa to <strong>rê </strong>xeyrên bo.</td>
<td style="height:17px; width:132px">Wa to <strong>rî </strong>xêrên bo.</td>
<td style="height:17px; width:132px">Sana hayırlı olsun.</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>5.2.2.r’</h1><br>
Tayê vateyan de kopula û “<strong>r</strong>” pîya vajîyenê labelê nuştiş de ganî no “<strong>r</strong>” cîya binusîyo. Nimûne: Lajek uca <strong>r’ </strong>o. (Oğlan ordadır.)<br>
Na cumle de “uca” zerf a, “o” kopula, “r” zî <strong>peyedat </strong>o. No “r” eslê xo de yew çeku ya labelê kilm bîyo, tena sey yew herfe mendo, coka cîya nusîyeno. Seke nimûneyanê cêrênan de aseno, gama ke merdim lehçeyanê bînan yê kurdkî de muqayese keno, eslê nê “r”yî hîna weş fehm beno.<br>
Çend nimûneyê bînî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:113px"><strong>formê bînî</strong></td>
<td style="height:17px; width:198px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">uca <strong>r’ </strong>o</td>
<td style="height:17px; width:113px">uca ro, uzaro</td>
<td style="height:17px; width:198px">ordadır</td>
</tr>
<tr>
<td style="height:17px; width:140px">uca <strong>r’ </strong>ê</td>
<td style="height:17px; width:113px">uca rê, uzarê</td>
<td style="height:17px; width:198px">ordadırlar</td>
</tr>
<tr>
<td style="height:17px; width:140px">dime <strong>r’ </strong>o</td>
<td style="height:17px; width:113px">dime ro, dimero</td>
<td style="height:17px; width:198px">peşindedir, arkasındadır</td>
</tr>
<tr>
<td style="height:17px; width:140px">dime <strong>r’ </strong>a</td>
<td style="height:17px; width:113px">dime ra, dimera</td>
<td style="height:17px; width:198px">peşindedir, arkasındadır</td>
</tr>
<tr>
<td style="height:17px; width:140px">dime <strong>r’ </strong>ê</td>
<td style="height:17px; width:113px">dime rê, dimerê</td>
<td style="height:17px; width:198px">peşindedirler, arkasındadırlar</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Muqabilê nê “<strong>rê</strong>” û “<strong>r</strong>”yî -ke cor ra ma behsê înan kerd- kurmanckî (kirdaskî) de “<strong>li</strong>”, kurdkîya mîyanêne (sorankî) de “<strong>le</strong>” est o. Yanî no “<strong>r</strong>”yê kirmanckî nê lehçeyan de “<strong>l</strong>” yo. Muqayeseyanê cêrênan ra no vurîyayîş hîna weş bellî beno:<br>
<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:206px"><strong>kirmanckî (zazakî)</strong></td>
<td style="height:17px; width:161px"><strong>kurmanckî (kirdaskî)</strong></td>
<td style="height:17px; width:198px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:206px"><strong>rê</strong>, <strong>re</strong>, <strong>rî</strong>, <strong>r’</strong>, <strong>ra</strong>, <strong>era</strong>, <strong>ro</strong></td>
<td style="height:17px; width:161px"><strong>li</strong></td>
<td style="height:17px; width:198px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:206px">Uca <strong>r’ </strong>a. Uza <strong>r’ </strong>a.</td>
<td style="height:17px; width:161px"><strong>Li </strong>wî cî ye.</td>
<td style="height:17px; width:198px">Oradadır.</td>
</tr>
<tr>
<td style="height:17px; width:206px">Dime <strong>r’ </strong>a.</td>
<td style="height:17px; width:161px"><strong>Li </strong>dû ye./<strong>Li </strong>dûv e.</td>
<td style="height:17px; width:198px">Peşindedir</td>
</tr>
<tr>
<td style="height:17px; width:206px">Da mi <strong>ro</strong>./Mi <strong>ro </strong>da.</td>
<td style="height:17px; width:161px"><strong>Li </strong>min da/xist.</td>
<td style="height:17px; width:198px">Bana vurdu.</td>
</tr>
<tr>
<td style="height:17px; width:206px"><strong>era </strong>dime kotene</td>
<td style="height:17px; width:161px"><strong>li </strong>dû ketin</td>
<td style="height:17px; width:198px">peşine düşmek</td>
</tr>
<tr>
<td style="height:17px; width:206px">Ku<strong>re </strong>ya?/Ku<strong>ra </strong>ya?</td>
<td style="height:17px; width:161px"><strong>Li </strong>ku ye?</td>
<td style="height:17px; width:198px">Nerededir?</td>
</tr>
<tr>
<td style="height:17px; width:206px">Wê<strong>ra </strong>ya./Wey<strong>re </strong>ya./Wey<strong>ra </strong>ya.</td>
<td style="height:17px; width:161px"><strong>Li </strong>wê ye.</td>
<td style="height:17px; width:198px">Oradadır.</td>
</tr>
<tr>
<td style="height:34px; width:206px">hu<strong>re </strong>vîştene, hu<strong>rê </strong>fîstene,<br>
we<strong>rê </strong>fîştene, we<strong>rê </strong>visteni</td>
<td style="height:34px; width:161px"><strong>li </strong>hev xistin</td>
<td style="height:34px; width:198px">karıştırmak</td>
</tr>
<tr>
<td style="height:17px; width:206px">hu<strong>rê </strong>dayene, we<strong>rê </strong>dayene</td>
<td style="height:17px; width:161px"><strong>li </strong>hev dan</td>
<td style="height:17px; width:198px">(birbirine) karıştırmak</td>
</tr>
<tr>
<td style="height:34px; width:206px">hu<strong>rê </strong>ameyene, hu<strong>rê </strong>amayene, we<strong>rê </strong>ameyene, we<strong>rê </strong>amayene</td>
<td style="height:34px; width:161px"><strong>li </strong>hev hatin</td>
<td style="height:34px; width:198px">barışmak</td>
</tr>
<tr>
<td style="height:17px; width:206px">hu<strong>rê </strong>ardene, we<strong>rê </strong>ardene</td>
<td style="height:17px; width:161px"><strong>li </strong>hev anîn</td>
<td style="height:17px; width:198px">barıştırmak</td>
</tr>
<tr>
<td style="height:17px; width:206px">Heywanî ko <strong>ra </strong>çerenî.</td>
<td style="height:17px; width:161px">Heywan <strong>li </strong>çiyê diçêrin.</td>
<td style="height:17px; width:198px">Hayvanlar dağda otlanıyorlar.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:206px"><strong>kirmanckî (zazakî)</strong></td>
<td style="height:17px; width:199px"><strong>kurdkîya mîyanêne (sorankî)</strong></td>
<td style="height:17px; width:142px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:206px"><strong>rê</strong>, <strong>re</strong>, <strong>rî</strong>, <strong>r’</strong>, <strong>ra</strong>, <strong>era</strong>, <strong>ro</strong></td>
<td style="height:17px; width:199px"><strong>le</strong></td>
<td style="height:17px; width:142px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:206px">yê to <strong>ra</strong></td>
<td style="height:17px; width:199px"><strong>le </strong>hî to</td>
<td style="height:17px; width:142px">seninkinden</td>
</tr>
<tr>
<td style="height:17px; width:206px">înan <strong>ra </strong>di kesî</td>
<td style="height:17px; width:199px"><strong>le </strong>wane du kes</td>
<td style="height:17px; width:142px">onlardan iki kişi</td>
</tr>
<tr>
<td style="height:17px; width:206px">vîr <strong>ra </strong>nêşî</td>
<td style="height:17px; width:199px"><strong>le </strong>bîr neçû</td>
<td style="height:17px; width:142px">unutmadı</td>
</tr>
<tr>
<td style="height:17px; width:206px">Ku<strong>re</strong>?/Ku<strong>ra</strong>?</td>
<td style="height:17px; width:199px"><strong>Le </strong>kwê?</td>
<td style="height:17px; width:142px">Nerede?</td>
</tr>
<tr>
<td style="height:17px; width:206px">Wê<strong>ra </strong>ya./Wey<strong>re </strong>ya./Wey<strong>ra </strong>ya.</td>
<td style="height:17px; width:199px"><strong>Le </strong>wê ye.</td>
<td style="height:17px; width:142px">Oradadır.</td>
</tr>
<tr>
<td style="height:17px; width:206px">Hewn <strong>ra </strong>werzên!</td>
<td style="height:17px; width:199px"><strong>Le </strong>xew helsîn!</td>
<td style="height:17px; width:142px">Uykudan kalkınız!<strong>(5)</strong></td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>5.2.3.de</h1><br>

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:177px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:227px"><strong>formê bînî</strong></td>
<td style="height:17px; width:151px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:177px"><strong>de</strong></td>
<td style="height:17px; width:227px"><strong>di</strong>, <strong>d’</strong></td>
<td style="height:17px; width:151px">-de</td>
</tr>
<tr>
<td style="height:17px; width:177px">Lajek tenge <strong>de </strong>yo.</td>
<td style="height:17px; width:227px">Lajek tengi <strong>d’ </strong>o./Lajek tengi <strong>di </strong>wo.</td>
<td style="height:17px; width:151px">Oğlan dardadır.</td>
</tr>
<tr>
<td style="height:17px; width:177px">Qeleme çanteyê mi <strong>de </strong>ya.</td>
<td style="height:17px; width:227px">Qelem çûntey mi <strong>d’ </strong>a.</td>
<td style="height:17px; width:151px">Kalem çantamdadır.</td>
</tr>
<tr>
<td style="height:17px; width:177px">Dewe <strong>de </strong>çi est o, çi çin o?</td>
<td style="height:17px; width:227px">Dow/do <strong>di </strong>çi est o, çi çinî yo?</td>
<td style="height:17px; width:151px">Köyde ne var, ne yok?</td>
</tr>
</tbody>
</table>
&nbsp;

<ol>
<li><h1>5.2.4.der</h1></li>
</ol>

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:149px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:293px"><strong>formê bînî</strong></td>
<td style="height:17px; width:123px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:149px"><strong>der</strong></td>
<td style="height:17px; width:293px"><strong>dir</strong></td>
<td style="height:17px; width:123px">-de</td>
</tr>
<tr>
<td style="height:17px; width:149px"><strong>der </strong>o, <strong>der </strong>a</td>
<td style="height:17px; width:293px"><strong>de </strong>ro, <strong>der</strong>ro, <strong>der </strong>ro, <strong>dir </strong>o, <strong>dir</strong>o</td>
<td style="height:17px; width:123px">-dedir (içindedir)</td>
</tr>
<tr>
<td style="height:17px; width:149px">Lajek keye <strong>der </strong>o.</td>
<td style="height:17px; width:293px">Lajek keye <strong>der</strong>o/<strong>dir</strong>o./Lajek keye <strong>de </strong>ro/<strong>di </strong>ro.</td>
<td style="height:17px; width:123px">Oğlan evdedir.</td>
</tr>
<tr>
<td style="height:17px; width:149px">Kêneke mekteb <strong>der </strong>a.</td>
<td style="height:17px; width:293px">Keyneke mekteb <strong>der</strong>a/<strong>dir</strong>a.</td>
<td style="height:17px; width:123px">Kız okuldadır.</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>5.2.5.ra</h1><br>
Mi <strong>ra </strong>pey ame. (Benden sonra geldi.) To <strong>ra </strong>girewt. (Senden aldı.)<br>
Ma <strong>ra </strong>va. (Bize söyledi.)<br>
Ez semedê şima <strong>ra </strong>ameya. (Ben sizin için geldim.) Lajekî lewe kêneke <strong>ra </strong>day. (Oğlan kızı öptü.)<br>
&nbsp;
<h1>5.2.6.ro</h1><br>
Şiwanî bizê xo deşte <strong>ro </strong>ney, ko <strong>ro </strong>berdî. (Çoban keçilerini ovadan değil, dağdan götürdü.)<br>
<br>
&nbsp;<br>
Mêrdek nêrdîwane <strong>ro </strong>vejîya cor. (Adam merdivenden yukarı çıktı.)<br>
&nbsp;
<h1>5.2.7.ya</h1><br>
Mêrdek di xizmetkaran zî kênaya xo <strong>ya </strong>şaweno. (Adam iki hizmetçiyi de kızıyla gönderir.) Ez semedê şima <strong>ya </strong>ameya. (Ben sizin için geldim.)<br>
&nbsp;
<h1>5.2.8.a</h1><br>
Lajekî lewe kênek <strong>a </strong>day. (Oğlan kızı öptü.)<br>
&nbsp;
<h1>5.3.Veredat û Peyedat Pîya (Önedat ve Artedat Birlikte)</h1><br>

<ol>
<li><h1>5.3.1.bi ... ra</h1></li>
</ol>
Ez <strong>bi </strong>Roşanî <strong>ra </strong>şîya. (Roşan ile -birlikte- gittim.) Ez <strong>bi </strong>to <strong>ra </strong>ma pîya şonî. (Seninle birlikte gideriz.)<br>
&nbsp;
<h1>5.3.2.di ... de</h1><br>
Dewijî <strong>di </strong>camî <strong>de </strong>bîy. (Köylüler camideydiler.)<br>
“Zey <strong>di </strong>Bedr û hem Huneyn <strong>de </strong>bo şehîd.” (Bedr ve Huneyn’de şehit olmuş gibi) (Mela Ehmedê Xasî)<br>
“Yo hevalê min <strong>di </strong>cennet <strong>de </strong>mudam.” (O cenette sürekli arkadaşım.) (Mela Ehmedê Xasî)<br>
“Ez <strong>di </strong>no hal <strong>di </strong>bîyan, amey, min dî” (Ben bu haldeydim, geldiler, gördüm) (Usman Efendîyo<br>
Babij)<br>
“Ey <strong>di </strong>textê Ehmedî <strong>di </strong>ya Xuda!” (Onu Ehmed’in tahtında, ey Tanrı!) (Usman Efendîyo Babij)<br>
&nbsp;
<h1>5.3.3.ede ... de</h1><br>
“Xidê Momid, <strong>ede </strong>raya trêna Erzingan <strong>de </strong>tasaron bî.”<br>
“Tayê cênî, cûwamêrd, domon, eskeronê şuwarû day arê, <strong>ede </strong>Korta Hêmz <strong>de </strong>bêrd.” “Hen eve o qêyde <strong>ede </strong>dustê bononê Çê Ferê Bilêj <strong>de </strong>bî vînd şî.”<br>
“Çiqas bon, <strong>ede </strong>o Estemol <strong>de</strong>, cawonê bînû de, hemêgê ma ra amê virastene nîno hesavkerdene.” “Naye ser o ez niştûne ro taxsî, tekit û şûne Hêyder Pasa (Haydar Paşa), mi <strong>ede </strong>trêne <strong>de</strong><br>
pêguret.”<strong>(6)</strong><br>
&nbsp;
<h1>5.3.4.a ... ra</h1><br>
Lajek çinayanê xo dano <strong>a </strong>xo <strong>ra</strong>. (Oğlan giysilerini giyiyor.)<br>
&nbsp;
<h1>5.3.5.beyntarê ... de</h1><br>
<strong>Beyntarê </strong>min û ey <strong>de </strong>çîyêko xirab çin bi. (Onunla aramızda kötü bir şey yoktu.)<br>
&nbsp;
<h1>5.3.6.bi ... ya/bi ... a</h1><br>
Mêrdek <strong>bi </strong>yew cenî <strong>ya </strong>ameyo. (Adam bir kadınla gelmiş.) Ti zî <strong>bi </strong>min <strong>a </strong>bê. (Sen de benimle gel.)<br>
&nbsp;
<h1>5.3.7.bi ... bê/ebi ... bê</h1><br>
Kêneke <strong>bi </strong>fek <strong>bê </strong>xo vato./Keyneki <strong>ebi </strong>fek <strong>bê </strong>xwi vato. (Kız kendi ağzıyla söylemiş.)<br>
&nbsp;
<h1>5.3.8.peyê ... ra</h1><br>
Mêrdek <strong>peyê </strong>berî <strong>ra </strong>vejîya. (Adam kapının arkasından çıktı.)<br>
&nbsp;<br>
&nbsp;
<h1>6.BESTOXÎ (BAĞLAÇLAR)</h1><br>

<ol>
<li><h1>6.1.Çend Bestoxî</h1></li>
</ol>
&nbsp;

<h1>6.1.1.eke</h1><br>
Cayê çekuya “<strong>eke</strong>” de, tayê fekan de nê varyantî est ê: <strong>ek, eke, ekê, eki, eg, heki, ke, ki, ku</strong>.<br>
<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:310px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:246px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:310px"><strong>eke</strong></td>
<td style="height:17px; width:246px">eğer</td>
</tr>
<tr>
<td style="height:17px; width:310px">Biko, <strong>eke </strong>ti zanî vaje eke ti nêzanî biperse.</td>
<td style="height:17px; width:246px">Oğul, biliyorsan söyle, bilmiyorsan sor.</td>
</tr>
<tr>
<td style="height:17px; width:310px"><strong>Eke </strong>a kêneke bêra ez zî yena.</td>
<td style="height:17px; width:246px">Eğer o kız gelirse ben de gelirim.</td>
</tr>
<tr>
<td style="height:34px; width:310px"><strong>Eke </strong>ti nêzanî ti çi rê nusenî, ti mecbur î ke binusî?</td>
<td style="height:34px; width:246px">Bilmiyorsan neden yazıyorsun, yazmak<br>
zorunda mısın?</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;<br>
&nbsp;
<h1>6.1.2.ke</h1><br>
Cayê çekuya “<strong>ke</strong>” de tayê fekan de nê varyantî est ê: <strong>gi, go, ig, ik, ki, ko, ku, kû</strong>.<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:187px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:236px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:187px"><strong>ke</strong></td>
<td style="height:17px; width:236px">ki</td>
</tr>
<tr>
<td style="height:17px; width:187px">Ti mevaje <strong>ke </strong>zurî keno.</td>
<td style="height:17px; width:236px">Meğer yalan söylüyormuş.</td>
</tr>
<tr>
<td style="height:17px; width:187px">Çîyo <strong>ke </strong>ez zana ti zî zanî.</td>
<td style="height:17px; width:236px">Benim bildiğimi sen de biliyorsun.</td>
</tr>
<tr>
<td style="height:17px; width:187px">Xorto <strong>ke </strong>yeno birayê min o.</td>
<td style="height:17px; width:236px">Gelen delikanlı kardeşimdir.</td>
</tr>
<tr>
<td style="height:17px; width:187px">Mi nêwaşt <strong>ke </strong>a kêneke bêra.</td>
<td style="height:17px; width:236px">O kızın gelmesini istemedim.</td>
</tr>
<tr>
<td style="height:17px; width:187px">O ameyo tîya <strong>ke </strong>biwano.</td>
<td style="height:17px; width:236px">O buraya okumak üzere gelmiş.</td>
</tr>
<tr>
<td style="height:17px; width:187px">Şima vajîn <strong>ke </strong>ma zî vajê.</td>
<td style="height:17px; width:236px">Siz söyleyin ki biz de söyliyelim.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>6.1.2.1.“Ke” û Vîrgul</h1><br>
Tayê kesî mîyanê cumle de çekuya “ke” ra ver yan zî pey vîrgul ronanî labelê rewşa normale de ganî bestoxan dima vîrgul nêronîyo. Çunke bestoxî zî xora sey vîrgulî çekuyan û cumleyan pêra girê danê. Nimûne:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:149px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:161px"><strong>formê bînî</strong></td>
<td style="height:17px; width:180px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:34px; width:149px">Ez ameya <strong>ke </strong>ti zî bêrî.</td>
<td style="height:34px; width:161px">Ez ameya <strong>ke, </strong>ti zî bêrî./<br>
Ez ameya<strong>, ke </strong>ti zî bêrî.</td>
<td style="height:34px; width:180px">Sen de gelesin diye geldim.</td>
</tr>
</tbody>
</table>
Bi bestoxanê “<strong>ke</strong>”, “<strong>labelê</strong>”/“<strong>la</strong>”, “<strong>û</strong>”yî çend nimûneyê bînî:<br>
Ti mevaje <strong>ke </strong>birayê ey ameyo. (Meğer kardeşi gelmiş.) Vaje <strong>ke </strong>wa bimuso! (Söyle ki öğrensin!)<br>
A wazena biwano <strong>ke </strong>hîna zaf bizano. (O daha çok bilmek için okumak istiyor.)<br>
Ez ewro rew şîya wendegeh <strong>labelê </strong>mamosta nêamebi. (Bugün okula erken gittim fakat öğretmen gelmemişti.)<br>
Ame, gamê ronişt, dima dest bi qiseykerdişî kerd <strong>û </strong>keyfê ma ard. (Geldi, birazcık oturdu, sonra konuşmaya başladı ve bizi neşelendirdi.)<br>
&nbsp;<br>
Tayê rewşan (halan) de “<strong>ke</strong>”yî ra pey yan zî ver vîrgul ronîyeno. Nimûneyî:<br>
Par tîya de hende vewre varaybî <strong>ke</strong>, simerê dewijan zî heywanan rê nêvetbi. (Geçen yıl burada o kadar kar yağmıştı ki, köylülerin samanı da hayvanlara yetmemişti.)<br>
A roje panc xortî, <strong>ke </strong>Kajîn zî înan ra yew bi, uca birîndar bîbîy. (O gün beş genç, ki Kajîn de onlardan biriydi, orada yaralanmışlardı.)<br>
&nbsp;
<h1>6.1.3.Nuştişê Tayê Bestoxanê ke Di-hîrê Çekuyan ra Virazîyayê</h1><br>
&nbsp;

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:284px"><strong>formê bînî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">çend... hende...</td>
<td style="height:17px; width:284px">çende… ende..., çindi… indi…</td>
</tr>
<tr>
<td style="height:17px; width:140px">çi... çi...</td>
<td style="height:17px; width:284px">-</td>
</tr>
<tr>
<td style="height:17px; width:140px">çiqas... hende...</td>
<td style="height:17px; width:284px">çiqas… ende, çiqas… indi, çiqaşî… honde…</td>
</tr>
<tr>
<td style="height:17px; width:140px">çiqas ke... hende...</td>
<td style="height:17px; width:284px">çiqas ki… indi…, çiqaşî ke… honde…</td>
</tr>
<tr>
<td style="height:17px; width:140px">ge... ge...</td>
<td style="height:17px; width:284px">gê… gê</td>
</tr>
<tr>
<td style="height:17px; width:140px">ge-ge... ge-ge...</td>
<td style="height:17px; width:284px">gê-gê... gê-gê...</td>
</tr>
<tr>
<td style="height:17px; width:140px">ge-gane... ge-gane...</td>
<td style="height:17px; width:284px">gê-gane… gê-gane…</td>
</tr>
<tr>
<td style="height:17px; width:140px">ha... ha...</td>
<td style="height:17px; width:284px">-</td>
</tr>
<tr>
<td style="height:17px; width:140px">hem... hem...</td>
<td style="height:17px; width:284px">him… him, him… himi…</td>
</tr>
<tr>
<td style="height:17px; width:140px">ne... ne...</td>
<td style="height:17px; width:284px">-</td>
</tr>
<tr>
<td style="height:17px; width:140px">ne... ne zî...</td>
<td style="height:17px; width:284px">ne... ne jî...</td>
</tr>
<tr>
<td style="height:17px; width:140px">ne... ne kî...</td>
<td style="height:17px; width:284px">ne... ne jî...</td>
</tr>
<tr>
<td style="height:17px; width:140px">yan... yan...</td>
<td style="height:17px; width:284px">ya… ya…</td>
</tr>
<tr>
<td style="height:17px; width:140px">yan... yan zî...</td>
<td style="height:17px; width:284px">ya... ya jî...</td>
</tr>
<tr>
<td style="height:17px; width:140px">yan... yan kî...</td>
<td style="height:17px; width:284px">ya... ya jî...</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Tayê kesî gama ke nê bestoxan nusenî, beyntar de vîrgul ronanî labelê goreyê meylê kombîyayîşî bêvîrgul nusîyenê:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:196px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:322px"><strong>nuştişê bînî</strong></td>
</tr>
<tr>
<td style="height:17px; width:196px"><strong>Çend </strong>ez zana ti zî <strong>hende </strong>zanî.</td>
<td style="height:17px; width:322px">Çend ez zana<strong>, </strong>ti zî hende zanî.</td>
</tr>
<tr>
<td style="height:17px; width:196px"><strong>Çi </strong>cinî <strong>çi </strong>camêrd, heme amey.</td>
<td style="height:17px; width:322px">Çi cinî<strong>, </strong>çi camêrd heme amey.</td>
</tr>
<tr>
<td style="height:17px; width:196px"><strong>Ge </strong>ez yena <strong>ge </strong>ti yenî.</td>
<td style="height:17px; width:322px">Ge ez yena<strong>, </strong>ge ti yenî.</td>
</tr>
<tr>
<td style="height:17px; width:196px"><strong>Ge-ge </strong>ez yena <strong>ge-ge </strong>ti yenî.</td>
<td style="height:17px; width:322px">Ge-ge ez yena<strong>, </strong>ge-ge ti yenî.</td>
</tr>
<tr>
<td style="height:17px; width:196px"><strong>Ha </strong>ez <strong>ha </strong>ti, ferqê ma çin o.</td>
<td style="height:17px; width:322px">Ha ez<strong>, </strong>ha ti, ferqê ma çin o.</td>
</tr>
<tr>
<td style="height:17px; width:196px"><strong>Hem </strong>ez yena <strong>hem </strong>ti yenî.</td>
<td style="height:17px; width:322px">Hem ez yena<strong>, </strong>hem ti yenî./Him ez yena, him ti yenî.</td>
</tr>
<tr>
<td style="height:17px; width:196px">To <strong>ne </strong>wendo <strong>ne </strong>nuşto.</td>
<td style="height:17px; width:322px">To ne wendo<strong>, </strong>ne nuşto.</td>
</tr>
<tr>
<td style="height:17px; width:196px"><strong>Yan </strong>ez yena <strong>yan </strong>ti yenî.</td>
<td style="height:17px; width:322px">Ya ez yena<strong>, </strong>ya ti yenî./Yan ez yena<strong>, </strong>yan ti yenî.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>7.KAR (FÎÎL)</h1><br>

<ol>
<li><h1>7.1.Mesderê Karî (Mesderê Fîîlî) û Nameyê Mesderî</h1></li>
</ol>
Kirmanckî de peygirê mesderî “-ene”/“-yene”, “-îyayene” yan zî “-iş”/“-yîş” û “-îyayîş” o. Mesela “kerdene”/“kerdiş” (yapmak, yapma) û “dayene”/“dayîş” (yapmak, yapma). Seba ke ziwanê nuştişî de asanî bibo Kombîyayîşê ma, karê ke bi peygirê “<strong>-ene</strong>”/“<strong>-yene</strong>” û “<strong>-îyayene</strong>” qedîyênê sey mesderî (mastar); êyê ke bi “<strong>-iş</strong>”/“<strong>-yîş</strong>”, û “<strong>-îyayîş</strong>”î qedîyênê zî sey nameyî (ad, isim) qebul kerdî.<br>
&nbsp;
<h1>7.1.1.Mesderê Karî bi Peygirê “-ene”/“-yene” û “-îyayene”</h1><br>
Nimûneyî:

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:149px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:246px"><strong>formê bînî</strong></td>
<td style="height:17px; width:217px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:149px"><strong>-(y)ene</strong></td>
<td style="height:17px; width:246px"><strong>-(y)eni</strong>, <strong>-(y)êne</strong>, <strong>-(y)îne</strong>, <strong>-(y)en</strong>, <strong>-(y)in</strong></td>
<td style="height:17px; width:217px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:149px">berd<strong>ene </strong>(berd + <strong>ene</strong>)</td>
<td style="height:17px; width:246px">bard<strong>eni</strong></td>
<td style="height:17px; width:217px">götürmek</td>
</tr>
<tr>
<td style="height:17px; width:149px">da<strong>yene </strong>(da + <strong>y </strong>+ <strong>ene</strong>)</td>
<td style="height:17px; width:246px">da<strong>yeni</strong>, da<strong>yêne</strong>, da<strong>yîne</strong></td>
<td style="height:17px; width:217px">vermek</td>
</tr>
<tr>
<td style="height:34px; width:149px">bî<strong>yene</strong></td>
<td style="height:34px; width:246px">bî<strong>yayeni</strong>, bî<strong>yayêne</strong>, bî<strong>yayîne</strong>,<br>
bî<strong>yayen</strong>, bî<strong>yayin</strong></td>
<td style="height:34px; width:217px">olmak</td>
</tr>
<tr>
<td style="height:17px; width:149px">gêra<strong>yene</strong></td>
<td style="height:17px; width:246px">geyra<strong>yêne</strong></td>
<td style="height:17px; width:217px">gezmek, dolaşmak</td>
</tr>
<tr>
<td style="height:17px; width:149px">rona<strong>yene</strong></td>
<td style="height:17px; width:246px">rona<strong>yêne</strong>, rûna<strong>yîne</strong></td>
<td style="height:17px; width:217px">koymak, indirmek, yere bırakmak</td>
</tr>
<tr>
<td style="height:17px; width:149px">şî<strong>yene</strong></td>
<td style="height:17px; width:246px">şî<strong>yayeni</strong>, şî<strong>yayêne</strong></td>
<td style="height:17px; width:217px">gitmek</td>
</tr>
<tr>
<td style="height:17px; width:149px">zana<strong>yene</strong></td>
<td style="height:17px; width:246px">zana<strong>yine</strong>, zonayêne, zûna<strong>yîne</strong></td>
<td style="height:17px; width:217px">bilmek</td>
</tr>
</tbody>
</table>
<br>

&nbsp;
<h1>-îyayene</h1><br>

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:177px"><strong>-îyayene</strong></td>
<td style="height:17px; width:236px"><strong>-iyayêne</strong>, <strong>-îyayîne</strong>, <strong>-iyayîne</strong></td>
<td style="height:17px; width:85px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:177px">kiş<strong>îyayene </strong>(kiş + <strong>îyayene</strong>)</td>
<td style="height:17px; width:236px">kiş<strong>iyayêne</strong>, kiş<strong>îyayîne</strong>, kiş<strong>iyayîne</strong></td>
<td style="height:17px; width:85px">öldürülmek</td>
</tr>
<tr>
<td style="height:17px; width:177px">nus<strong>îyayene</strong></td>
<td style="height:17px; width:236px">nus<strong>iyayêne</strong>, nus<strong>îyayîne</strong>, nus<strong>iyayîne</strong></td>
<td style="height:17px; width:85px">yazılmak</td>
</tr>
<tr>
<td style="height:17px; width:177px">vaj<strong>îyayene</strong></td>
<td style="height:17px; width:236px">vaj<strong>iyayêne</strong>, vaj<strong>îyayîne</strong>, vaj<strong>iyayîne</strong></td>
<td style="height:17px; width:85px">söylenmek</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<ol>
<li><h1>7.2.Nameyê Mesderî bi Peygirê “-iş”/“-yîş” û “-îyayîş”î</h1></li>
</ol>
Nimûneyî:

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:113px"><strong>formê bînî</strong></td>
<td style="height:17px; width:198px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>-iş</strong>, <strong>-(y)îş</strong></td>
<td style="height:17px; width:113px"><strong>-iş</strong>, <strong>-(y)iş</strong></td>
<td style="height:17px; width:198px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:140px">berd<strong>iş </strong>(berd + <strong>iş</strong>)</td>
<td style="height:17px; width:113px">bard<strong>iş</strong></td>
<td style="height:17px; width:198px">götürme</td>
</tr>
<tr>
<td style="height:17px; width:140px">da<strong>yîş </strong>(da + <strong>y</strong>+ <strong>îş</strong>)</td>
<td style="height:17px; width:113px">da<strong>yiş</strong></td>
<td style="height:17px; width:198px">verme</td>
</tr>
<tr>
<td style="height:17px; width:140px">gêra<strong>yîş</strong></td>
<td style="height:17px; width:113px">geyra<strong>yiş</strong></td>
<td style="height:17px; width:198px">gezme, dolaşma</td>
</tr>
<tr>
<td style="height:17px; width:140px">rona<strong>yîş</strong></td>
<td style="height:17px; width:113px">rûna<strong>yiş</strong></td>
<td style="height:17px; width:198px">koyma, indirme, yere bırakma</td>
</tr>
<tr>
<td style="height:17px; width:140px">zana<strong>yîş</strong></td>
<td style="height:17px; width:113px">zona<strong>yiş</strong>, zûna<strong>yiş</strong></td>
<td style="height:17px; width:198px">bilme</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>-îyayîş</h1><br>

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:158px"><strong>-îyayîş</strong></td>
<td style="height:17px; width:198px"><strong>-iyayiş</strong>, <strong>-îyayiş</strong>, <strong>-iyayîş</strong></td>
<td style="height:17px; width:85px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:158px">kiş<strong>îyayîş </strong>(kiş + <strong>îyayîş</strong>)</td>
<td style="height:17px; width:198px">kiş<strong>iyayiş</strong>, kiş<strong>îyayiş</strong>, kiş<strong>iyayîş</strong></td>
<td style="height:17px; width:85px">öldürülme</td>
</tr>
<tr>
<td style="height:17px; width:158px">nus<strong>îyayîş</strong></td>
<td style="height:17px; width:198px">nus<strong>iyayiş</strong>, nus<strong>îyayiş</strong>, nus<strong>iyayîş</strong></td>
<td style="height:17px; width:85px">yazılma</td>
</tr>
<tr>
<td style="height:17px; width:158px">vaj<strong>îyayîş</strong></td>
<td style="height:17px; width:198px">vaj<strong>iyayiş</strong>, vaj<strong>îyayiş</strong>, vaj<strong>iyayîş</strong></td>
<td style="height:17px; width:85px">söylenme</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Nê corênan ra “-yîş” eslê xo de “-iş” o labelê gama ke herfa vengine dima “-iş” bêro, di venginî yenê têhet, o wext “<strong>y</strong>” sey herfa pêragirêdayene dekeweno mabênê înan û “-iş” beno “-<strong>yîş</strong>”. Yanî, <strong>cayê “-yiş”ê nameyê mesderî de ganî tim “-yîş” binusîyo.</strong><br>
Nimûne:<br>
da + <strong>iş </strong>→ daiş → da + <strong>y </strong>+ <strong>îş </strong>= da<strong>yîş </strong>(bi tirkî “verme”)<br>
gêra + <strong>iş</strong>→ gêraiş → gêra + <strong>y </strong>+ <strong>îş </strong>= gêra<strong>yîş </strong>(bi tirkî “gezme”, “dolaşma”).<br>
&nbsp;<br>
Labelê eke eslê karî bi herfa bêvenge (sessiz harf) biqedîyo, no “<strong>-iş</strong>” nevurîyeno. Nimûne: berd + <strong>iş </strong>= berd<strong>iş </strong>(bi tirkî “götürme”)<br>
kerd + <strong>iş </strong>= kerd<strong>iş </strong>(bi tirkî “yapma”) ant + <strong>iş </strong>= ant<strong>iş </strong>(bi tirkî “çekme”)<br>
&nbsp;<br>
Herfa pêragirêadayene, seba mesderî zî eynî fonksîyonî ana ca. Nimûne:<br>
<br>
&nbsp;<br>
da + <strong>ene </strong>→ daene → da + <strong>y </strong>+ <strong>ene </strong>= da<strong>yene </strong>(bi tirkî “vermek”)<br>
gêra + <strong>ene </strong>→ gêraene → gêra + <strong>y </strong>+ <strong>ene </strong>= gêra<strong>yene </strong>(bi tirkî “gezmek”, “dolaşmak”).<br>
&nbsp;<br>
Labelê eke eslê karî bi herfa bêvenge (sessiz harf) biqedîyo, no “-ene” nevurîyeno. Nimûne: berd + <strong>ene </strong>= berd<strong>ene </strong>(bi tirkî “götürmek”)<br>
kerd + <strong>ene </strong>= kerd<strong>iş </strong>(bi tirkî “yapmak”) ant + <strong>ene </strong>= ant<strong>ene </strong>(bi tirkî “çekmek”)<br>
&nbsp;<br>
&nbsp;
<h1>7.3.Karê Pêrabesteyî (Bileşik Fiil) de</h1><br>

<ol>
<li>Eke karo pêrabeste çend çekuyan ra viraşte bibo, <strong>mesderê </strong>bi peygirê “<strong>-ene</strong>”/“<strong>-yene</strong>” û çekuyê bînî <strong>cîya nusîyenê</strong>, yanî her çekuya nê çeşît mesderanê karanê pêrabesteyan cîya nusîyena. Labelê <strong>nameyê mesderî </strong>yê bi peygirê “<strong>-iş</strong>”/“<strong>-yîş</strong>”î û çekuyê bînî <strong>pîya nusîyenê</strong>; çunke manaya înan de zî ferq est o. Nimûneyî:</li>
</ol>
&nbsp;

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:319px"><strong>mesder</strong></td>
<td style="height:17px; width:246px"><strong>nameyê mesderî</strong></td>
</tr>
<tr>
<td style="height:17px; width:319px"><strong>-(y)ene</strong></td>
<td style="height:17px; width:246px"><strong>-iş, -yîş</strong></td>
</tr>
<tr>
<td style="height:17px; width:319px">awe da<strong>yene </strong>(sulamak, suvarmak)</td>
<td style="height:17px; width:246px">aweda<strong>yîş </strong>(sulama, suvarma)</td>
</tr>
<tr>
<td style="height:34px; width:319px">awe xo ro kerd<strong>ene </strong>(duş almak, su dökünmek,<br>
yıkanmak)</td>
<td style="height:34px; width:246px">awexorokerd<strong>iş </strong>(duş alma, su dökünme,<br>
yıkanma)</td>
</tr>
<tr>
<td style="height:34px; width:319px">awe xo ser o zelal kerd<strong>ene </strong>(çok gecikmek) (îdyom)</td>
<td style="height:34px; width:246px">awexoserozelal kerd<strong>iş </strong>(çok gecikme)<br>
(îdyom)</td>
</tr>
<tr>
<td style="height:34px; width:319px">herre û wele xo ser ro kerd<strong>ene </strong>(pişmanlık duyup<br>
yakınmak) (îdyom)</td>
<td style="height:34px; width:246px">herreûwelexoserrokerd<strong>iş&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong>(pişmanlık<br>
duyup yakınma) (îdyom)</td>
</tr>
<tr>
<td style="height:17px; width:319px">berz kerd<strong>ene </strong>(yükseltmek)</td>
<td style="height:17px; width:246px">berzkerd<strong>iş </strong>(yükseltme)</td>
</tr>
<tr>
<td style="height:17px; width:319px">germ kerd<strong>ene </strong>(ısıtmak)</td>
<td style="height:17px; width:246px">germkerd<strong>iş </strong>(ısıtma)</td>
</tr>
<tr>
<td style="height:17px; width:319px">pêro da<strong>yene </strong>(dövüşmek)</td>
<td style="height:17px; width:246px">pêroda<strong>yîş </strong>(dövüşme)</td>
</tr>
<tr>
<td style="height:17px; width:319px">piro da<strong>yene </strong>(vurmak)</td>
<td style="height:17px; width:246px">piroda<strong>yîş </strong>(vurma)</td>
</tr>
<tr>
<td style="height:17px; width:319px">da<strong>yene </strong>zana<strong>yene </strong>(bildirmek, beyan etmek)</td>
<td style="height:17px; width:246px">da<strong>yîş</strong>zana<strong>yîş </strong>(bildirme beyan etme)</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<ol>
<li>Seke nimûneyanê corênan de aseno, mesder û nameyê mesderî yê karê pêrabesteyî de, çekuya karî (fîîlî) peynî de nusîyena. Nê karanê corênan de, çekuyê “<strong>dayene</strong>”, “<strong>kerdene</strong>” û “<strong>dayîş</strong>”, “<strong>kerdiş</strong>”î kar ê û peynî de yê. Sey “berz <strong>kerdene</strong>” û “berz<strong>kerdiş</strong>”î.</li>
</ol>
Tayê fekan de, nuştişê mesderî û nameyê mesderî yê tayê karanê pêrabesteyan de, çekuya karî vernî de yena labelê <strong>nimûneyê winasî tay ê</strong>. Fekê Pîranî ra çend nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:291px"><strong>mesder</strong></td>
<td style="height:17px; width:265px"><strong>nameyê mesderî</strong></td>
</tr>
<tr>
<td style="height:17px; width:291px"><strong>dekerdiş </strong>quli (deliğe tıkmak, deliğe koymak)</td>
<td style="height:17px; width:265px"><strong>dekerdiş</strong>quli (deliğe tıkma, deliğe koyma)</td>
</tr>
<tr>
<td style="height:17px; width:291px">&nbsp;</td>
<td style="height:17px; width:265px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:291px">quli <strong>dekerdiş </strong>(delmek)</td>
<td style="height:17px; width:265px">quli<strong>dekerdiş </strong>(delme)</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;<br>
Seke nimûneyanê corênan de aseno, çekuya “dekerdiş”’î kar (fiil) o û goreyê vernîdebîyayîş yan zî peynîdebîyayîşê na çekuye, manaya karê pêrabesteyî vurîyena. Nê nimûneyanê cêrênan de zî kar (dekewtiş, ramitiş) vernî de ameyo:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:385px"><strong>ramitiş </strong>ser (üzerine saldırmak)</td>
</tr>
<tr>
<td style="height:17px; width:385px"><strong>dekewtiş </strong>cili (1-yatağa düşmek, yatağa girmek 2-hasta olmak)</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>7.4.Kopula (Karo Nêmcet) 7.4.1.Nusîyayîşê Cinsîyetê Kopula</h1><br>
<br>
&nbsp;<br>
Kirmanckî de yew kar est o ke temam nîyo, “karo nêmcet” o, tena demê nikayinî de ancîyêno; ziwananê rojawanî de ney ra kopula (“copula”, “copule”), tirkî de “koşaç”, “ek fiil” vajîyeno; name, zemîr yan zî sifetan dima yeno. Muqabilê nê karî, franskî de “être”, îngilizkî de “to be” yo. Herçiqas ke no kar karê “bîyene”/“bîyayene” maneno zî no yewna kar o. Muqabilê karê “bîyene”/“bîyayene” franskî de “devenir”, îngilizkî de “to become” o.<br>
Kopula zemîr, sifet û nameyan ra cîya nusîyena. Fekanê cîya-cîyayanê kirmanckî de formê kopula vurîyenê (bedilîyenê). Ma cêr ra meylê kombîyayîşî û formanê bînan û nusenê:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:121px">&nbsp;</td>
<td style="height:17px; width:170px"><strong>name û kopula</strong></td>
<td style="height:17px; width:180px"><strong>sifet û kopula</strong></td>
</tr>
<tr>
<td style="height:17px; width:121px">ez <strong>a </strong>(n, m)</td>
<td style="height:17px; width:170px">Ez kurd <strong>a</strong>.</td>
<td style="height:17px; width:180px">Ez gird <strong>a</strong>.</td>
</tr>
<tr>
<td style="height:17px; width:121px">ti <strong>yî </strong>(n)</td>
<td style="height:17px; width:170px">Ti kurd <strong>î</strong>.</td>
<td style="height:17px; width:180px">Ti pîl <strong>î</strong>.</td>
</tr>
<tr>
<td style="height:17px; width:121px">ti <strong>ya </strong>(m)</td>
<td style="height:17px; width:170px">Ti kurd <strong>a</strong>.</td>
<td style="height:17px; width:180px">Ti qij <strong>a</strong>.</td>
</tr>
<tr>
<td style="height:17px; width:121px">o <strong>yo </strong>(n)</td>
<td style="height:17px; width:170px">O kurd <strong>o</strong>.</td>
<td style="height:17px; width:180px">O pîr <strong>o</strong>.</td>
</tr>
<tr>
<td style="height:17px; width:121px">a <strong>ya </strong>(m)</td>
<td style="height:17px; width:170px">A kurd <strong>a</strong>.</td>
<td style="height:17px; width:180px">A nêweş <strong>a</strong>.</td>
</tr>
<tr>
<td style="height:17px; width:121px">ma <strong>yê / </strong>ma <strong>yîme</strong></td>
<td style="height:17px; width:170px">Ma kurd <strong>ê</strong>. / Ma kurd <strong>îme</strong>.</td>
<td style="height:17px; width:180px">Ma zaf <strong>ê</strong>. Ma wende <strong>yîme</strong>.</td>
</tr>
<tr>
<td style="height:17px; width:121px">şima <strong>yê</strong></td>
<td style="height:17px; width:170px">Şima/sima kurd <strong>ê</strong>.</td>
<td style="height:17px; width:180px">Şima/sima biaqil <strong>ê</strong>.</td>
</tr>
<tr>
<td style="height:17px; width:121px">ê <strong>yê</strong></td>
<td style="height:17px; width:170px">Ê kurd <strong>ê</strong>.</td>
<td style="height:17px; width:180px">Ê zanaye <strong>yê</strong>.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;<br>
&nbsp;
<table border="0" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:22px; width:113px"><strong>Zemîrê Kesî</strong></td>
<td style="height:22px; width:252px"><strong>Meylê Kombîyayîşî</strong></td>
<td style="height:22px; width:255px"><strong>Formê Bînî</strong></td>
</tr>
<tr>
<td style="height:163px; width:113px">1. ez (n, m)</td>
<td style="height:163px; width:252px"><strong>a</strong>........................................................<br>
Ez <strong>a</strong>. (Benim.)...................................<br>
Ez kurd <strong>a</strong>. (Ben Kürdüm.)................<br>
&nbsp;<br>
<strong>ane</strong>....................................................<br>
Ez <strong>ane</strong>. (Benim)................................<br>
Ez dêrsimiz <strong>ane</strong>. (Ben Dêrsimliyim.) Ez de baş <strong>ane</strong>.(Ben iyiyim.) (Hewêl)<strong>(8)</strong></td>
<td style="height:163px; width:255px"><strong>o </strong>/ <strong>û</strong><br>
Ez <strong>o</strong>. / Ez <strong>û</strong>. (Benim.)<br>
Ez kurd <strong>o</strong>. / Ez kurd <strong>û. </strong>(Ben Kürdüm.)<br>
&nbsp;<br>
<strong>ûne / ûni / onê / an</strong><br>
Ez <strong>ûne</strong>. (Dêrsim) / Ez <strong>ûni</strong>. (Şankuş)<strong>(7)</strong> Ez dêrsimiz <strong>ûne</strong>. (Ben Dêrsimliyim.) Ez pîl <strong>onê</strong>.(Ben büyüğüm.)(Şankuş)<strong>(9) </strong>Ez <strong>an</strong>. (Benim.) (Sêwregi)<strong>(10)</strong></td>
</tr>
<tr>
<td style="height:96px; width:113px">2. ti (n)</td>
<td style="height:96px; width:252px"><strong>î</strong>...........................................................<br>
Ti <strong>yî</strong>. (Sensin.)....................................<br>
Ti ciwan <strong>î</strong>. (Sen gençsin.)...................<br>
Ti kurd <strong>î</strong>. (Sen Kürtsün.)....................<br>
Ti kês <strong>î</strong>.(Sen iyisin.)(Modan/Motkan)</td>
<td style="height:96px; width:255px"><strong>ê</strong><br>
Ti <strong>yê</strong>. (Sensin.)<br>
Ti ciwan <strong>ê</strong>. (Sen gençsin.) Ti kurd <strong>ê</strong>. (Sen Kürtsün.) Ti baş <strong>ê</strong>. (Sen iyisin.)</td>
</tr>
<tr>
<td style="height:79px; width:113px">2. ti (m)</td>
<td style="height:79px; width:252px"><strong>a</strong>..........................................................<br>
Ti <strong>ya</strong>. (Sensin.)...................................<br>
Ti ciwan <strong>a</strong>. (Sen gençsin.)..................<br>
Ti kurd <strong>a</strong>. (Sen Kürtsün.)....................</td>
<td style="height:79px; width:255px"><strong>ay</strong><br>
Ti <strong>wa</strong>. (Sensin.)<br>
Ti pîl <strong>ay</strong>. (Sen büyüksün.) (Şankuş)<strong>(11) </strong>Ti kurd <strong>ay</strong>. (Sen Kürtsün.) (Dêrsim)</td>
</tr>
<tr>
<td style="height:62px; width:113px">3. o (n)</td>
<td style="height:62px; width:252px"><strong>o</strong><br>
O <strong>yo</strong>. (Odur.)......................................<br>
O kurd <strong>o</strong>. (O Kürttür.)</td>
<td style="height:62px; width:255px">&nbsp;<br>
O <strong>wo</strong>. (Odur.)</td>
</tr>
<tr>
<td style="height:62px; width:113px">3. a (m)</td>
<td style="height:62px; width:252px"><strong>a</strong><br>
A <strong>ya</strong>. (Odur.)......................................<br>
A kurd <strong>a</strong>. (O Kürttür.)</td>
<td style="height:62px; width:255px">&nbsp;<br>
A <strong>wa</strong>. (Odur.)</td>
</tr>
<tr>
<td style="height:90px; width:113px">1. ma</td>
<td style="height:90px; width:252px"><strong>ê</strong>..........................................................<br>
Ma kurd <strong>ê</strong>. (Biz Kürdüz.)...................<br>
Ma <strong>yê</strong>. (Biziz.)....................................<br>
&nbsp;<br>
<strong>îme</strong>......................................................</td>
<td style="height:90px; width:255px"><strong>î</strong><br>
Ma kurd <strong>î</strong>. (Biz Kürdüz.) Ma <strong>yî</strong>. (Biziz.)<br>
&nbsp;<br>
<strong>îmi </strong>/ <strong>îm</strong></td>
</tr>
</tbody>
</table>
<br>
&nbsp;<br>
&nbsp;
<table border="0" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:39px; width:96px">&nbsp;</td>
<td style="height:39px; width:269px">Ma <strong>yîme</strong>. (Biziz.)...............................<br>
Ma kurd <strong>îme</strong>. (Biz Kürdüz.)...............</td>
<td style="height:39px; width:219px">Ma <strong>yîmê</strong>/<strong>yîmi</strong>. / Ma <strong>yîm</strong>. (Biziz.) Ma kurd <strong>îm</strong>. (Biz Kürdüz.)</td>
</tr>
<tr>
<td style="height:62px; width:96px">2. şima</td>
<td style="height:62px; width:269px"><strong>ê</strong>..........................................................<br>
Şima kurd <strong>ê</strong>. (Siz Kürtsünüz.)............<br>
Şima <strong>yê</strong>. (Sizsiniz.).............................</td>
<td style="height:62px; width:219px"><strong>î</strong><br>
Şima kurd <strong>î</strong>. /Sima kurd <strong>ê</strong>. Şima <strong>yî</strong>. / Sima <strong>yê</strong>. (Sizsiniz.)</td>
</tr>
<tr>
<td style="height:56px; width:96px">3. ê</td>
<td style="height:56px; width:269px"><strong>ê</strong>..........................................................<br>
Ê kurd <strong>ê</strong>. (Onlar Kürttür.)...................<br>
Ê <strong>yê</strong>. (Onlardır.)..................................</td>
<td style="height:56px; width:219px"><strong>î</strong><br>
Ê kurd <strong>î</strong>. (Onlar Kürttür.) Ê <strong>yî</strong>.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Negatîfê (menfîyê) kopula wina yo:<br>
&nbsp;
<table border="0" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:22px; width:288px"><strong>Meylê Kombîyayîşî</strong></td>
<td style="height:22px; width:313px"><strong>Formê Bînî</strong></td>
</tr>
<tr>
<td style="height:146px; width:288px"><strong>nîya </strong>(n, m).............................................<br>
Ez <strong>nîya </strong>(Ben değilim.) ..................................<br>
Ez kurd <strong>nîya</strong>. (Ben Kürt değilim.)..................<br>
&nbsp;<br>
<strong>nîyane </strong>(n, m).........................................<br>
Ez <strong>nîyane</strong>. (Ben değilim.)..............................<br>
E kurd <strong>nîyane</strong>. (Ben Kürt değilm)..................<br>
Ez pîl <strong>nîyane</strong>. (Ben büyük değilim.)</td>
<td style="height:146px; width:313px"><strong>nîyo </strong>/ <strong>nîyû</strong><br>
Ez <strong>nîyo</strong>. / Ez <strong>nîyû</strong>. (Ben değilim.)<br>
Ez kurd <strong>nîyo</strong>. / Ez kurd <strong>nîyû. </strong>(Ben Kürt değilim.)<br>
&nbsp;<br>
<strong>nîyûne / nîyûni / nîyonê / nîyan</strong><br>
Ez <strong>nîyûne</strong>./Ez <strong>nîyûni</strong>./Ez <strong>nîyan</strong>. (Ben değilim.) Ez kurd <strong>nîyûne</strong>. (Ben Kürt değilim.)<br>
Ez pîl <strong>nîyonê</strong>. (Ben büyük değilim.)</td>
</tr>
<tr>
<td style="height:96px; width:288px"><strong>nîyî </strong>(n)........................................................<br>
Ti <strong>nîyî</strong>. (Sen değilsin.)....................................<br>
Ti ciwan <strong>nîyî</strong>. (Sen genç değilsin.).................<br>
Ti kurd <strong>nîyî</strong>. (Sen Kürt değilsin.)...................<br>
Ti kês <strong>nîyî</strong>. (Sen iyi değilsin.)</td>
<td style="height:96px; width:313px"><strong>nîyî</strong><br>
Ti <strong>nîyê</strong>. (Sen değilsin.)<br>
Ti ciwan <strong>nîyê</strong>. (Sen genç değilsin.) Ti kurd <strong>nîyê</strong>. (Sen Kürt değilsin.) Ti baş <strong>nîyê</strong>. (Sen iyi değilsin.)</td>
</tr>
<tr>
<td style="height:96px; width:288px"><strong>nîya </strong>(m) .........................................................<br>
Ti <strong>nîya</strong>. (Sen değilsin.)...................................<br>
Ti ciwan <strong>nîya</strong>. (Sen genç değilsin.)................<br>
Ti kurd <strong>nîya</strong>. (Sen Kürt değilsin.)..................<br>
Ti kês <strong>nîya</strong>. (Sen iyi değilsin.)</td>
<td style="height:96px; width:313px">-<br>
-<br>
-<br>
-<br>
-</td>
</tr>
<tr>
<td style="height:96px; width:288px"><strong>nîyo </strong>(n) ..........................................................<br>
O <strong>nîyo</strong>. (O değil.)............................................<br>
O ciwan <strong>nîyo</strong>. (O genç değil.)........................<br>
O kurd <strong>nîyo</strong>. (O Kürt değil.)...........................<br>
O kês <strong>nîyo</strong>. (O iyi değil.)................................</td>
<td style="height:96px; width:313px"><strong>nîyû</strong><br>
O <strong>nîyû</strong>. (O değil.)<br>
O ciwan <strong>nîyû</strong>. (O genç değil.) O kurd <strong>nîyû</strong>. (O Kürt değil.) O baş <strong>nîyû</strong>. (O iyi değil.)</td>
</tr>
<tr>
<td style="height:96px; width:288px"><strong>nîya </strong>(m) .........................................................<br>
A <strong>nîya</strong>. (O değil.)............................................<br>
A ciwan <strong>nîya</strong>. (O genç değil.)........................<br>
A kurd <strong>nîya</strong>. (O Kürt değil.)...........................<br>
A kês <strong>nîya</strong>. (O iyi değil.)................................</td>
<td style="height:96px; width:313px"><strong>-</strong><br>
<strong>-</strong><br>
<strong>-</strong><br>
<strong>-</strong><br>
<strong>-</strong></td>
</tr>
<tr>
<td style="height:95px; width:288px"><strong>nîyê </strong>................................................................<br>
Ma <strong>nîyê</strong>. (Biz değiliz.)....................................<br>
Ma ciwan <strong>nîyê</strong>. (Biz genç değiliz.).................<br>
Ma kurd <strong>nîyê</strong>. (Biz Kürt değiliz.)...................<br>
Ma kês <strong>nîyê</strong>. (Biz iyi değiliz.)</td>
<td style="height:95px; width:313px"><strong>nîyî</strong><br>
Ma <strong>nîyî</strong>. / Ma <strong>nîy</strong>. (Biz değiliz.) Ma ciwan <strong>nîyî</strong>. (Biz genç değiliz.) Ma kurd <strong>nîyî</strong>. (Biz Kürt değiliz.) Ma kês <strong>nîyî</strong>. (Biz iyi değiliz.)</td>
</tr>
<tr>
<td style="height:56px; width:288px"><strong>nîme</strong>................................................................<br>
Ma <strong>nîme</strong>. (Biz değiliz.)..................................<br>
Ma kurd <strong>nîme</strong>. (Biz Kürt değiliz.)..................</td>
<td style="height:56px; width:313px"><strong>nîmi </strong>/ <strong>nîm</strong><br>
Ma <strong>nîmi</strong>. / Ma <strong>nîm</strong>. (Biz değiliz.) Ma kurd <strong>nîm</strong>. (Biz Kürt değiliz.)</td>
</tr>
</tbody>
</table>
<br>
&nbsp;<br>
&nbsp;
<table border="0" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:56px; width:288px"><strong>nîyê</strong>.................................................................<br>
Şima <strong>nîyê</strong>. (Siz değilsiniz.).............................<br>
Şima kurd <strong>nîyê</strong>. (Siz Kürt değilsiniz.)............</td>
<td style="height:56px; width:338px"><strong>nîyî</strong><br>
Şima <strong>nîyî</strong>. / Sima <strong>nîyê</strong>. (Siz değilsiniz.)<br>
Şima kurd <strong>nîyî</strong>./ Sima kurd <strong>nîyê</strong>.(Siz Kürt değilsiniz.)</td>
</tr>
<tr>
<td style="height:56px; width:288px"><strong>nîyê</strong>.................................................................<br>
Ê <strong>nîyê</strong>. (Onlar değildir.)..................................<br>
Ê kurd <strong>nîyê</strong>. (Onlar Kürt değildir.).................</td>
<td style="height:56px; width:338px"><strong>nîyî</strong><br>
Î <strong>nîyî</strong>. / Î <strong>nîyê</strong>. (Onlar değildir.)<br>
Î kurd <strong>nîyî</strong>. / Î kurd <strong>nîyê</strong>. (Onlar Kürt değildir.)</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>7.4.1.Nusîyayîşê Cinsîyetê Kopula</h1><br>
Ge-ge nuştişê cînsîyetê kopula de xeletîye (şaşîye) bena. Nimûne:

<ol>
<li>Kitab çekuyêka nêrî <strong>ya</strong>.</li>
<li>Kitab çekuyêka nêrî <strong>yo</strong>.</li>
</ol>
Kitab nêrî, çeku makî ya. Coka cumleyanê corênan ra cumleya verêne rast, cumleya dîyine xelet<br>
a. Cumleya verêne de kopulaya “<strong>ya</strong>” semedê makî ya çunke “çekuye” makî ya; tîya de kopula goreyê “kitabî” nîya, goreyê “çekuye” ya. Yanî kopula goreyê kerdoxî nênusîyaya, goreyê objeyî (nesne) nusîyaya û rast a.<br>
Çend nimûneyê bînî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:291px"><strong>rast</strong></td>
<td style="height:17px; width:302px"><strong>xelet</strong></td>
</tr>
<tr>
<td style="height:17px; width:291px">Şamîya mi riz <strong>o</strong>.</td>
<td style="height:17px; width:302px">Şamîya mi riz <strong>a</strong>.</td>
</tr>
<tr>
<td style="height:17px; width:291px">Sebeb, nezanîya min <strong>a</strong>.</td>
<td style="height:17px; width:302px">Sebeb, nezanîya min <strong>o</strong>.</td>
</tr>
<tr>
<td style="height:17px; width:291px">Karê to başîya/rindîya to <strong>ya</strong>.</td>
<td style="height:17px; width:302px">Karê to başîya/rindîya to <strong>yo</strong>.</td>
</tr>
<tr>
<td style="height:34px; width:291px">Kêmaneyê na partî yo tewr gird, xebata ziwanê<br>
kurdkî <strong>ya</strong>.</td>
<td style="height:34px; width:302px">Kemaneyê na partî yo tewr gird, xebata ziwanê<br>
kurdkî <strong>yo</strong>.</td>
</tr>
<tr>
<td style="height:17px; width:291px">Nameyê mi Delal <strong>a</strong>, nameyê naye zî Zelal <strong>a</strong>.</td>
<td style="height:17px; width:302px">Nameyê mi Delal <strong>o</strong>, nameyê na zî Zelal <strong>o</strong>.</td>
</tr>
<tr>
<td style="height:17px; width:291px">Werdê to mercû <strong>ya</strong>.</td>
<td style="height:17px; width:302px">Werdê to mercû <strong>yo</strong>.</td>
</tr>
<tr>
<td style="height:17px; width:291px">Karê ey qeşmerî <strong>ya</strong>.</td>
<td style="height:17px; width:302px">Karê ey qeşmerî <strong>yo</strong>.</td>
</tr>
<tr>
<td style="height:17px; width:291px">Para ey hêga <strong>yo</strong>.</td>
<td style="height:17px; width:302px">Para ey hêga <strong>ya</strong>.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>7.5.Nusîyayîşê Çekuya “Çin” û Karî (Fîîlî)</h1><br>
Kirmanckî de çekuya “<strong>çin</strong>”î, manaya “<strong>tin</strong>”/“<strong>tun</strong>”a kurmanckî dana. Na çekuye, kopula ra cîya nusîyena.<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:340px"><strong>formê bînî</strong></td>
<td style="height:17px; width:95px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>çin</strong></td>
<td style="height:17px; width:340px">çîn, çîne, çîni, çinê, çinî, çini, çinik</td>
<td style="height:17px; width:95px">yok</td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>çin </strong>a (m)</td>
<td style="height:17px; width:340px">çîn a, çîne ya, çîni ya, çinê ya, çinî ya, çini ya, çinik a</td>
<td style="height:17px; width:95px">yoktur</td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>çin </strong>o (n)</td>
<td style="height:17px; width:340px">çîn o, çîne yo, çîni yû, çinê yo, çinî yo, çini yo, çinik o</td>
<td style="height:17px; width:95px">yoktur</td>
</tr>
<tr>
<td style="height:34px; width:140px"><strong>çin </strong>ê</td>
<td style="height:34px; width:340px">çîn ê/î, çîne yê, çîni yê, çinê yê/yî, çinî yê/yî,<br>
çini yê/yî, çinik ê/î</td>
<td style="height:34px; width:95px">yokturlar</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Bi “bîyene” û “kerdene”, çekuya “çin”î ra karê “çin bîyene” û “çin kerdene” virazîyeno.<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:255px"><strong>formê bînî</strong></td>
<td style="height:17px; width:180px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>çin </strong>bîyene</td>
<td style="height:17px; width:255px">çîn bîyene, çînê bîyene, çîni bîyene...</td>
<td style="height:17px; width:180px">yok olmak</td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>çin </strong>kerdene</td>
<td style="height:17px; width:255px">çîn kerdene, çînê kerdene, çîni kerdene...</td>
<td style="height:17px; width:180px">yok etmek</td>
</tr>
<tr>
<td style="height:17px; width:140px">Kitabê ey <strong>çin </strong>bi.</td>
<td style="height:17px; width:255px">Kitabê ey çîn bi./Kitabê ey çinê/çîni bi...</td>
<td style="height:17px; width:180px">Onun kitabı yoktu.</td>
</tr>
<tr>
<td style="height:34px; width:140px">Eskeran&nbsp;&nbsp;&nbsp; kitabê&nbsp;&nbsp; ey<br>
veşnayê, <strong>çin </strong>kerdê.</td>
<td style="height:34px; width:255px">Eskeran kitabê ey veşnay, çîn/çinê/çîni<br>
kerdê...</td>
<td style="height:34px; width:180px">Askerler&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; kitaplarını<br>
yakmışlar, yok etmişler.</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>7.6.Nusîyayîşê Çekuya “Est” û Karî (Fîîlî)</h1><br>
Na çekuye hetê gramerî ra sifet û name ya. Pehlevkî de zî bi şekilê “hest”î est a. Nuştiş de<br>
<br>
&nbsp;<br>
çekuyanê bînan ra cîya nusîyena.<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:149px"><strong>yewhûmar</strong></td>
<td style="height:17px; width:151px"><strong>zafhûmar</strong></td>
</tr>
<tr>
<td style="height:17px; width:149px">1.ez <strong>est </strong>a (n, m)</td>
<td style="height:17px; width:151px">1.ma <strong>est </strong>ê / ma <strong>est </strong>îme</td>
</tr>
<tr>
<td style="height:17px; width:149px">2.ti <strong>est </strong>î (n)</td>
<td style="height:17px; width:151px">2.şima <strong>est </strong>ê</td>
</tr>
<tr>
<td style="height:17px; width:149px">2.ti <strong>est </strong>a (m)</td>
<td style="height:17px; width:151px">3.ê <strong>est </strong>ê</td>
</tr>
<tr>
<td style="height:17px; width:149px">3.o <strong>est </strong>o (n)</td>
<td style="height:17px; width:151px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:149px">3.a <strong>est </strong>a (m)</td>
<td style="height:17px; width:151px">&nbsp;</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>7.7.Karê “Bîyene”/“Bîyayîş”î</h1><br>

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:177px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:227px"><strong>formê bînî</strong></td>
<td style="height:17px; width:114px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:177px">ez ben<strong>a </strong>/ ez ben<strong>ane </strong>(n, m)</td>
<td style="height:17px; width:227px">ez ben<strong>ay</strong>, ez ben<strong>o</strong>, ez ben<strong>û</strong>, ez ben</td>
<td style="height:17px; width:114px">ben oluyorum</td>
</tr>
<tr>
<td style="height:17px; width:177px">ti ben<strong>î </strong>(n)</td>
<td style="height:17px; width:227px">ti ben</td>
<td style="height:17px; width:114px">sen oluyorsun</td>
</tr>
<tr>
<td style="height:17px; width:177px">ti ben<strong>a </strong>(m)</td>
<td style="height:17px; width:227px">-</td>
<td style="height:17px; width:114px">sen oluyorsun</td>
</tr>
<tr>
<td style="height:17px; width:177px">o ben<strong>o </strong>(n)</td>
<td style="height:17px; width:227px">o ben<strong>û</strong></td>
<td style="height:17px; width:114px">o oluyor</td>
</tr>
<tr>
<td style="height:17px; width:177px">a ben<strong>a </strong>(m)</td>
<td style="height:17px; width:227px">-</td>
<td style="height:17px; width:114px">o oluyor</td>
</tr>
<tr>
<td style="height:17px; width:177px">ma ben<strong>ê </strong>/ ma ben<strong>îme</strong></td>
<td style="height:17px; width:227px">ma be<strong>me</strong></td>
<td style="height:17px; width:114px">biz oluyoruz</td>
</tr>
<tr>
<td style="height:17px; width:177px">şima ben<strong>ê</strong></td>
<td style="height:17px; width:227px">sima ben<strong>ê</strong>, şima ben</td>
<td style="height:17px; width:114px">siz oluyorsunuz</td>
</tr>
<tr>
<td style="height:17px; width:177px">ê ben<strong>ê</strong></td>
<td style="height:17px; width:227px">î ben<strong>ê</strong>, ê ben</td>
<td style="height:17px; width:114px">onlar oluyorlar</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<ol>
<li><h1>7.7.1.“B” yan “V”?</h1></li>
</ol>
Tayê fekan de gama ke karê “bîyene”/“bîyayîş”î ancîyeno, vengê “<strong>B</strong>”yî vurîyeno beno “<strong>V</strong>” labelê tayê fekan de no veng nêvurîyeno û “B” sey eslê xo maneno. Kombîyayîşî formê “<strong>B</strong>”yî tercîh kerd.<br>
Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:168px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:170px"><strong>formê bînî</strong></td>
<td style="height:17px; width:170px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:168px">O bî<strong>b</strong>î.</td>
<td style="height:17px; width:170px">O bî<strong>v</strong>î./O bî<strong>v</strong>.</td>
<td style="height:17px; width:170px">O olmuştu.</td>
</tr>
<tr>
<td style="height:17px; width:168px">Her di şehîd bî<strong>b</strong>î.</td>
<td style="height:17px; width:170px">Hur dî şehîd bî<strong>v</strong>î.</td>
<td style="height:17px; width:170px">Her ikisi şehit olmuştu.</td>
</tr>
<tr>
<td style="height:17px; width:168px">Di cayan ra dirbetin bî<strong>b</strong>î.</td>
<td style="height:17px; width:170px">Di cayan ra dirvetin bî<strong>v</strong>î.</td>
<td style="height:17px; width:170px">İki yerden yaralanmıştı.</td>
</tr>
<tr>
<td style="height:17px; width:168px">Mi murayî kerdî<strong>b</strong>î la ra.</td>
<td style="height:17px; width:170px">Mi morekî kerdî<strong>v</strong>î la ra.</td>
<td style="height:17px; width:170px">Boncukları ipe takmıştım.</td>
</tr>
<tr>
<td style="height:17px; width:168px">Şima xapitî<strong>b</strong>î.</td>
<td style="height:17px; width:170px">Şima xapitî<strong>v</strong>î.</td>
<td style="height:17px; width:170px">Sizi kandırmışlardı.</td>
</tr>
<tr>
<td style="height:17px; width:168px">Kênekî şî<strong>b</strong>îy.</td>
<td style="height:17px; width:170px">Çênekî şî<strong>v</strong>î.</td>
<td style="height:17px; width:170px">Kızlar gitmişlerdi.</td>
</tr>
<tr>
<td style="height:17px; width:168px">O ame<strong>b</strong>î.</td>
<td style="height:17px; width:170px">O ame<strong>v</strong>î.</td>
<td style="height:17px; width:170px">O gelmişti.</td>
</tr>
<tr>
<td style="height:17px; width:168px">Mi vat<strong>b</strong>i.</td>
<td style="height:17px; width:170px">Mi vati<strong>v</strong>.</td>
<td style="height:17px; width:170px">Ben demiştim.</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>7.8.Karo Pêrabeste (Bileşik Fiil)</h1><br>
Karo pêrabeste çend çeşîtî yo. Cêr ra, ma goreyê tercîhê kombîyayîşî, rastnuştişê panc çeşîtan nusenê:<br>
&nbsp;
<h1>7.8.1.Preverbo Yewheceyin + Kar</h1><br>
Preverb, yew çeku ya ke yena vernîya karî. Ge-ge yew preverb û yew kar pîya yewna karê neweyî virazenê. Yanî preverb + kar = karo pêrabeste.<br>
<strong>Çend preverbê yewheceyinî</strong>: a-, de-, ra-, ro-, we-, pa-, pê-, po-, tê-, to-.<br>
Çend karê ke hîna zaf nê preverban dima yenê û karêkê neweyî virazenê nê yê: Bîyene, dayene, girewtene, kerdene, kewtene.<br>
Preverbo yewheceyin û kar, mesder de pîya nusîyenê. Nimûneyî:<br>
<strong>A</strong>kewtene, <strong>de</strong>kewtene, <strong>ra</strong>bîyene, <strong>ra</strong>kerdene, <strong>pa</strong>kerdene, <strong>we</strong>kerdene, <strong>we</strong>dayene.<br>
<strong>De</strong>kerdiş, <strong>de</strong>kewtiş, <strong>pa</strong>dayîş, <strong>pa</strong>kerdiş, <strong>pê</strong>şanayîş, <strong>po</strong>kerdiş, <strong>ra</strong>dayîş, <strong>ro</strong>dayîş<strong>, ro</strong>nayîş, têşanayîş,<br>
<strong>to</strong>kerdiş, <strong>we</strong>kerdiş.<br>
&nbsp;<br>
Nê karî, goreyê fekan, di çeşîtî ancîyenê. Tayê fekan de kar ke ancîyeno, preverb karî ra ver yeno.<br>
<br>
&nbsp;<br>
Tayê fekan de zî kar ke ancîyeno, preverb karî ra pey yeno. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:102px"><strong>grûba yewine</strong></td>
<td style="height:17px; width:94px"><strong>grûba dîyine</strong></td>
</tr>
<tr>
<td style="height:17px; width:102px"><strong>a</strong>kerd</td>
<td style="height:17px; width:94px">kerd <strong>a</strong></td>
</tr>
<tr>
<td style="height:17px; width:102px"><strong>de</strong>kerd</td>
<td style="height:17px; width:94px">kerd <strong>de</strong></td>
</tr>
<tr>
<td style="height:17px; width:102px"><strong>pa</strong>kerd</td>
<td style="height:17px; width:94px">kerd <strong>pa</strong></td>
</tr>
<tr>
<td style="height:17px; width:102px"><strong>ra</strong>kerd</td>
<td style="height:17px; width:94px">kerd <strong>ra</strong></td>
</tr>
<tr>
<td style="height:17px; width:102px"><strong>ro</strong>na</td>
<td style="height:17px; width:94px">na <strong>ro</strong></td>
</tr>
<tr>
<td style="height:17px; width:102px"><strong>we</strong>kerd</td>
<td style="height:17px; width:94px">kerd <strong>we</strong></td>
</tr>
</tbody>
</table>
&nbsp;<br>
Seke nimûneyanê corênan de zî aseno, eke antişê karî de <strong>preverbo yewheceyin karî dima bêro, kar û preverb cîya nusîyenê </strong>labelê eke antişî de <strong>preverbo yewheceyin karî ra ver bêro, preverb û kar pîya nusîyenê</strong>.<br>
&nbsp;
<h1>7.8.2.Preverbo Zafheceyin + Kar</h1><br>
Eke preverb zafheceyin, yanî yew hece ra zafêr bibo, <strong>preverbo zafheceyin û kar cîya nusîyenê</strong>. Yanî preverbo zafheceyin sey yew çekuye, kar zî sey yew çekuye xoser nusîyenê. Nê her di çekuyî yew karê neweyî virazenê.<br>
<strong>Çend preverbê zafheceyinî: </strong>bider-/bere-/bede-, pede-, pêra-, pêro-, pira-, piro-/pero-, tede-/tedi-, têra-, têro-, tiro-. Nimûneyî:<br>
<strong>Pira </strong>girewtene, <strong>piro </strong>dayene, <strong>piro </strong>ginayene, <strong>piro </strong>kerdene, <strong>têra </strong>çarnayene, <strong>têra </strong>kerdene, <strong>têro</strong><br>
cinitene.<br>
Nê karê bi preverbanê zafheceyinan zî goreyê fekan di çeşît ancîyenê. Tayê fekan de kar ke ancîyeno, preverb karî ra ver yeno. Tayê fekan de zî kar ke ancîyeno, preverb karî ra pey yeno. Nê her di grûban de zî preverb û kar cîya nusîyenê. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:102px"><strong>grûba yewine</strong></td>
<td style="height:17px; width:94px"><strong>grûba dîyine</strong></td>
</tr>
<tr>
<td style="height:17px; width:102px"><strong>pêro </strong>da</td>
<td style="height:17px; width:94px">da <strong>pêro</strong></td>
</tr>
<tr>
<td style="height:17px; width:102px"><strong>piro </strong>gina</td>
<td style="height:17px; width:94px">gina <strong>piro</strong></td>
</tr>
<tr>
<td style="height:17px; width:102px"><strong>pêra </strong>na</td>
<td style="height:17px; width:94px">na <strong>pêra</strong></td>
</tr>
<tr>
<td style="height:17px; width:102px"><strong>têra </strong>kerd</td>
<td style="height:17px; width:94px">kerd <strong>têra</strong></td>
</tr>
</tbody>
</table>
&nbsp;

<h1>7.8.3.Name + Kar</h1><br>
Ge-ge name û kar yew karê neweyî virazenê. Nê çeşît karanê pêrabesteyan de name û kar cîya nusîyenê. Nimûneyî:<br>
<strong>awe </strong>dayene, <strong>pare </strong>bîyene, <strong>bawer </strong>kerdene, <strong>ca </strong>ardene, <strong>silayî </strong>kerdene, <strong>dawet </strong>kerdene, <strong>pers</strong><br>
kerdene<br>
&nbsp;
<h1>7.8.4.Sifet + Kar</h1><br>
Ge-ge sifet û kar yew karê neweyî virazenê. Nê çeşît karanê pêrabesteyan de sifet û kar cîya nusîyenê. Nimûneyî:<br>
<strong>berz </strong>kerdene, <strong>derg </strong>bîyene, <strong>pîl </strong>kerdene, <strong>pak </strong>bîyene, <strong>sîya </strong>kerdene, <strong>sûr </strong>kerdene, <strong>weş </strong>kerdene<br>
&nbsp;
<h1>7.8.5.Kar + Kar</h1><br>
Ge-ge yew kar û yewna kar (karo hetkar) yenê têhet, yew karê neweyî virazenê. Çekuyê nê çeşît karanê pêrabesteyan yewbînî ra cîya nusîyenê. Nimûneyî:<br>
Ameyene kerdene, ameyene vatene, dayene girewtene, dayene kîştene, dayene vatene, dayene wendene, dayene zanayene.<br>
&nbsp;
<h1>7.9.Kesê Zafhûmarê Yewinî yê Karê Întransîtîfî de: -(y)îme</h1><br>
Tayê fekanê kirmanckî de, kesê zafhûmarê yewinî yê karê întransîtîfî, demê vîyarteyê dîyarî de (- di’li geçmiş zamanda) ca ra ca bi nê forman qedîyeno: <strong>-(y)îme</strong>, <strong>-(y)îmi</strong>, <strong>-(y)me</strong>, <strong>-(y)mi</strong>, <strong>-(y)îm. </strong>Kombîyayîşî nînan ra formê “<strong>-(y)îme</strong>” tercîh kerd. Nimûneyî:<br>
<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:187px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:198px"><strong>formê bînî</strong></td>
<td style="height:17px; width:189px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:187px"><strong>-(y)îme </strong>(<strong>y </strong>+ <strong>îme</strong>)</td>
<td style="height:17px; width:198px">-<strong>(y)</strong><strong>me</strong>, -<strong>îmi</strong>, -<strong>(y)mi</strong>, -<strong>(y)îm</strong></td>
<td style="height:17px; width:189px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:187px">ma ame<strong>yîme</strong></td>
<td style="height:17px; width:198px">ma ama<strong>yme</strong>/ame<strong>ymi</strong>/ome<strong>ymi</strong></td>
<td style="height:17px; width:189px">biz geldik</td>
</tr>
<tr>
<td style="height:17px; width:187px">ma b<strong>îme</strong></td>
<td style="height:17px; width:198px">ma b<strong>îmi</strong></td>
<td style="height:17px; width:189px">biz olduk</td>
</tr>
<tr>
<td style="height:17px; width:187px">ma kewt<strong>îme</strong></td>
<td style="height:17px; width:198px">ma kewt<strong>îmi</strong>/kewt<strong>îm</strong></td>
<td style="height:17px; width:189px">biz düştük</td>
</tr>
<tr>
<td style="height:17px; width:187px">ma mend<strong>îme</strong></td>
<td style="height:17px; width:198px">ma mend<strong>îmi</strong></td>
<td style="height:17px; width:189px">biz kaldık</td>
</tr>
<tr>
<td style="height:17px; width:187px">ma resa<strong>yîme</strong></td>
<td style="height:17px; width:198px">ma resa<strong>ymi</strong></td>
<td style="height:17px; width:189px">biz yetiştik</td>
</tr>
<tr>
<td style="height:17px; width:187px">ma ş<strong>îme</strong></td>
<td style="height:17px; width:198px">ma ş<strong>îmi</strong></td>
<td style="height:17px; width:189px">biz gittik</td>
</tr>
<tr>
<td style="height:17px; width:187px">Xortan ma ard<strong>îme</strong>.</td>
<td style="height:17px; width:198px">Xortan ma ard<strong>îmi</strong>.</td>
<td style="height:17px; width:189px">Gençler bizi getirdiler.</td>
</tr>
<tr>
<td style="height:17px; width:187px">Xortan ma fîşt<strong>îme </strong>ra xo ver.</td>
<td style="height:17px; width:198px">Xortan ma fîşt<strong>îmi </strong>ra xo ver.</td>
<td style="height:17px; width:189px">Gençler bizi önlerine kattılar.</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>7.10.Karan de Negatîfîye (Fillerde Olumsuzluk)</h1><br>
Kirmanckî de karî, goreyê fekan, bi vergirê (önek) <strong>“nê-”</strong>, <strong>“ni-/nî-/nîye-” </strong>yan zî <strong>“me-</strong>”yî benê negatîf. Kar û nê vergirî pîya nusîyenê.<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:92px"><strong>Vergir</strong></td>
<td style="height:17px; width:151px"><strong>Nimûneyî</strong></td>
<td style="height:17px; width:142px"><strong>Nimûneyî</strong></td>
<td style="height:17px; width:170px"><strong>Nimûneyî</strong></td>
</tr>
<tr>
<td style="height:152px; width:92px"><strong>nê-</strong></td>
<td style="height:152px; width:151px">bawer <strong>nê</strong>kerd <strong>nê</strong>antbî <strong>nê</strong>ard ca<br>
<strong>nê</strong>da pêro/pêro <strong>nê</strong>da <strong>nê</strong>da wendiş <strong>nê</strong>dekerdbî/de<strong>nê</strong>kerdbî <strong>nê</strong>eşkeno<br>
<strong>nê</strong>gina piro/piro <strong>nê</strong>gina <strong>nê</strong>kerdo</td>
<td style="height:152px; width:142px"><strong>nê</strong>rakerd/ra<strong>nê</strong>kerd <strong>nê</strong>rakewto/ra<strong>nê</strong>kewto <strong>nê</strong>ramit ser<br>
<strong>nê</strong>şimeno <strong>nê</strong>şutbî <strong>nê</strong>va <strong>nê</strong>veşenê <strong>nê</strong>waneno<br>
<strong>nê</strong>wena</td>
<td style="height:152px; width:170px"><strong>nê</strong>zano<br>
pira <strong>nê</strong>girewt/<strong>nê</strong>girewt pira sûr <strong>nê</strong>kerd<br>
venga … <strong>nê</strong>da<br>
wa <strong>nê</strong>agêro/wa <strong>nê</strong>gêro a wa <strong>nê</strong>deko/wa de<strong>nê</strong>ko wa <strong>nê</strong>ako/wa a<strong>nê</strong>ko<br>
wa <strong>nê</strong>wano/va <strong>nê</strong>wano weş <strong>nê</strong>kerd</td>
</tr>
<tr>
<td style="height:67px; width:92px"><strong>ni-/nî-/nîye-</strong></td>
<td style="height:67px; width:151px"><strong>ni</strong>kenû <strong>ni</strong>zono/<strong>ni</strong>zûnû <strong>ni</strong>eşkenû/<strong>ni</strong>şkeno<br>
<strong>ni</strong>şkenû/<strong>ni</strong>şno</td>
<td style="height:67px; width:142px"><strong>nî</strong>kenû <strong>nî</strong>zûnû<br>
<strong>nî</strong>şkenû/<strong>nî</strong>şkîyeno<br>
<strong>nî</strong>kerd</td>
<td style="height:67px; width:170px"><strong>nîye</strong>bû/<strong>nye</strong>bû <strong>nîye</strong>do/<strong>nye</strong>dû <strong>nîye</strong>dûnû/<strong>nye</strong>dûnû<br>
<strong>nîye</strong>zûnû/<strong>nye</strong>zûnû</td>
</tr>
<tr>
<td style="height:84px; width:92px"><strong>me-</strong></td>
<td style="height:84px; width:151px"><strong>me</strong>agêre/<strong>me</strong>gêre a <strong>me</strong>ake/a<strong>me</strong>ke <strong>me</strong>bere<br>
<strong>me</strong>de wendene<br>
<strong>me</strong>ke</td>
<td style="height:84px; width:142px"><strong>me</strong>rakewe/ra<strong>me</strong>kewe <strong>me</strong>raşane/ra<strong>me</strong>şane <strong>me</strong>şo war<br>
<strong>me</strong>vaje <strong>me</strong>wane</td>
<td style="height:84px; width:170px">bawer <strong>me</strong>ke cira <strong>me</strong>ke tira <strong>me</strong>ke sûr <strong>me</strong>ke<br>
venga … <strong>me</strong>de</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>7.11.Demo Nikayin</h1><br>
Kirmanckî de demo nikayin di çeşîtî yo. Yew <strong>bê partîkel </strong>o, yew zî <strong>bi partîkelê “ha”/“- a”/“yê”</strong>yî yo. Tîya de, ma yê yewinî ser o vindenê.<br>
&nbsp;
<h1>7.11.1.Demo nikayin-I</h1><br>
Kirmanckî de, reyaya demê vîyarteyî yê karî (fiilin geçmiş zaman kökü) û reyaya demê nikayinî yê karî (fiilin şimdiki zaman kökü) est ê. Qaydeyê antişî yê demê nikayinî yê karî wina yo:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:34px; width:121px"><strong>zemîrê kesî yo xoser (rectus) +</strong></td>
<td style="height:34px; width:151px"><strong>reyaya demê nikayinî yê karî +</strong></td>
<td style="height:34px; width:38px"><strong>en +</strong></td>
<td style="height:34px; width:142px"><strong>peygirê antişî yê karî</strong></td>
<td style="height:34px; width:113px"><strong>=demo nikayin</strong></td>
</tr>
<tr>
<td style="height:17px; width:121px">ez</td>
<td style="height:17px; width:151px"><strong>k</strong></td>
<td style="height:17px; width:38px"><strong>en</strong></td>
<td style="height:17px; width:142px"><strong>a</strong></td>
<td style="height:17px; width:113px">= ez kena</td>
</tr>
<tr>
<td style="height:17px; width:121px">ez</td>
<td style="height:17px; width:151px"><strong>nus</strong></td>
<td style="height:17px; width:38px"><strong>en</strong></td>
<td style="height:17px; width:142px"><strong>a</strong></td>
<td style="height:17px; width:113px">= ez nusena</td>
</tr>
<tr>
<td style="height:17px; width:121px">ez</td>
<td style="height:17px; width:151px"><strong>w</strong></td>
<td style="height:17px; width:38px"><strong>en</strong></td>
<td style="height:17px; width:142px"><strong>a</strong></td>
<td style="height:17px; width:113px">= ez wena</td>
</tr>
<tr>
<td style="height:17px; width:121px">ez</td>
<td style="height:17px; width:151px"><strong>taş</strong></td>
<td style="height:17px; width:38px"><strong>en</strong></td>
<td style="height:17px; width:142px"><strong>a</strong></td>
<td style="height:17px; width:113px">= ez taşena</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Eke merdim emrê karî bizano, antişê demê nikayinî asan beno. Qaydeyê ci wina yo:<br>
<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:34px; width:102px"><strong>zemîrê kesî yo xoser +</strong></td>
<td style="height:34px; width:151px"><strong>emrî ra vergirê [bi]yî bierze +</strong></td>
<td style="height:34px; width:38px"><strong>n +</strong></td>
<td style="height:34px; width:95px"><strong>peygirê antişî yê karî</strong></td>
<td style="height:34px; width:104px"><strong>=demo nikayin</strong></td>
<td style="height:34px; width:85px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:102px">ez</td>
<td style="height:17px; width:151px">[<strong>bi</strong>ke →] <strong>ke</strong></td>
<td style="height:17px; width:38px"><strong>n</strong></td>
<td style="height:17px; width:95px"><strong>a</strong></td>
<td style="height:17px; width:104px">= ez kena</td>
<td style="height:17px; width:85px">yapıyorum</td>
</tr>
<tr>
<td style="height:17px; width:102px">ez</td>
<td style="height:17px; width:151px">[<strong>bi</strong>erze →] <strong>erze</strong></td>
<td style="height:17px; width:38px"><strong>n</strong></td>
<td style="height:17px; width:95px"><strong>a</strong></td>
<td style="height:17px; width:104px">= ez erzena</td>
<td style="height:17px; width:85px">atıyorum</td>
</tr>
<tr>
<td style="height:17px; width:102px">ez</td>
<td style="height:17px; width:151px">[<strong>bi</strong>nuse →] <strong>nuse</strong></td>
<td style="height:17px; width:38px"><strong>n</strong></td>
<td style="height:17px; width:95px"><strong>a</strong></td>
<td style="height:17px; width:104px">= ez nusena</td>
<td style="height:17px; width:85px">yazıyorum</td>
</tr>
<tr>
<td style="height:17px; width:102px">ez</td>
<td style="height:17px; width:151px">[<strong>bi</strong>rame →] <strong>rame</strong></td>
<td style="height:17px; width:38px"><strong>n</strong></td>
<td style="height:17px; width:95px"><strong>a</strong></td>
<td style="height:17px; width:104px">= ez ramena</td>
<td style="height:17px; width:85px">sürüyorum</td>
</tr>
<tr>
<td style="height:17px; width:102px">ez</td>
<td style="height:17px; width:151px">[<strong>bi</strong>şuwe →] <strong>şuwe</strong></td>
<td style="height:17px; width:38px"><strong>n</strong></td>
<td style="height:17px; width:95px"><strong>a</strong></td>
<td style="height:17px; width:104px">= ez şuwena</td>
<td style="height:17px; width:85px">yıkıyorum</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Tayê karan de no qaydeyo peyên nêvîyareno. Nimûne: Antişê demê nikayinî yê nê karan de, heceya peyêne ya emrê karî vîndî bena.<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:34px; width:85px"><strong>mesder</strong></td>
<td style="height:34px; width:64px"><strong>emrê karî</strong></td>
<td style="height:34px; width:265px"><strong>demo nikayin</strong></td>
<td style="height:34px; width:97px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:85px">berdene</td>
<td style="height:17px; width:64px">[<strong>bi</strong>]<strong>bere</strong></td>
<td style="height:17px; width:265px">cayê “ez be<strong>re</strong>na”yî de “ez <strong>be</strong>na”</td>
<td style="height:17px; width:97px">götürüyorum</td>
</tr>
<tr>
<td style="height:17px; width:85px">girewtene</td>
<td style="height:17px; width:64px">[<strong>bi</strong>]<strong>gîre</strong></td>
<td style="height:17px; width:265px">cayê “ez gî<strong>re</strong>na”yî de “ez <strong>gî</strong>na/ez <strong>gê</strong>na”</td>
<td style="height:17px; width:97px">alıyorum</td>
</tr>
<tr>
<td style="height:17px; width:85px">vatene</td>
<td style="height:17px; width:64px">[<strong>bi</strong>]<strong>vaje</strong></td>
<td style="height:17px; width:265px">cayê “ez va<strong>je</strong>na”yî de “ez <strong>va</strong>na”</td>
<td style="height:17px; width:97px">söylüyorum</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Peygirê antişî yê karî (fiilin çekim ekleri) sey kopula yê. Çend nimûneyî:<br>
<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:51px; width:104px">Peygirê Antişî<br>
<strong>Meylê Kombîyayîşî</strong></td>
<td style="height:51px; width:140px"><strong>Meylê Kombîyayîşî</strong></td>
<td style="height:51px; width:95px">Peygirê Antişî<br>
<strong>Formê Bînî</strong></td>
<td style="height:51px; width:265px"><strong>Formê Bînî</strong></td>
</tr>
<tr>
<td style="height:270px; width:104px"><strong>a </strong>(n, m)<br>
&nbsp;<br>
&nbsp;<br>
<strong>ane </strong>(n, m)</td>
<td style="height:270px; width:140px">ez ke+n+<strong>a</strong>→ ez ken<strong>a </strong>(yapıyorum)<br>
&nbsp;<br>
ez&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ke+n+<strong>ane</strong>→ez ken<strong>ane(12)</strong><br>
ez&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ben<strong>ane</strong><br>
(götürüyorum) (Dêrsim,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Hewêl, Sêwregi)<br>
ez&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; yen<strong>ane</strong><br>
(geliyorum)(Erzinga n)</td>
<td style="height:270px; width:95px"><strong>an</strong>...............<br>
&nbsp;<br>
&nbsp;<br>
<strong>ûne</strong>.............<br>
&nbsp;<br>
<strong>ûn</strong>..............<br>
&nbsp;<br>
&nbsp;<br>
<strong>û</strong>.................<br>
<strong>one</strong>.............<br>
<strong>o</strong>.................<br>
<strong>ay</strong>...............<br>
<strong>i</strong>..................<br>
&nbsp;<br>
-.................<br>
-.................</td>
<td style="height:270px; width:265px">ez&nbsp;&nbsp;&nbsp;&nbsp; ben<strong>an&nbsp;&nbsp;&nbsp;&nbsp; </strong>(götürüyorum)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (Dêrsim, Sêwregi)<br>
ez musen<strong>an </strong>(öğreniyorum) (Sêwregi)<br>
&nbsp;<br>
ez ben<strong>ûne </strong>(götürüyorum) (Dêrsim) ez van<strong>ûne </strong>(söylüyorum) (Gimgim)<br>
“Ez hûm ken<strong>ûn</strong>, gûm ken<strong>ûn</strong>, verdê kîdê şima di tirri ken<strong>ûn</strong>.” (Aksaray)<strong>(13)</strong><br>
ez ben<strong>û</strong>, ez bon<strong>û </strong>(götürüyorum) (Dêrsim) ez ben<strong>one </strong>(götürüyorum) (Dêrsim)<br>
ez ben<strong>o </strong>(götürüyorum)<br>
ez ben<strong>ay </strong>(götürüyorum) (Dêrsim) ez wan<strong>i </strong>(yiyorum) (Şankuş<strong>(14) </strong>ez nişan<strong>i </strong>ro<strong>(15)</strong><br>
ez ben (götürüyorum) (Pali, Dêrsim) ez bon (götürüyorum) (Dêrsim)</td>
</tr>
<tr>
<td style="height:84px; width:104px"><strong>î </strong>(n)...............</td>
<td style="height:84px; width:140px">ti ke n+ <strong>î</strong>→ ti ken<strong>î </strong>(yapıyorsun)<br>
ti&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ben<strong>î</strong><br>
(götürüyorsun)</td>
<td style="height:84px; width:95px"><strong>ê</strong>...................<br>
&nbsp;<br>
<strong>a</strong>...................<br>
<strong>ay</strong>.................<br>
<strong>-</strong>.................</td>
<td style="height:84px; width:265px">ti şê<strong>nê </strong>(gidiyorsun) (Modan/Motkan)<strong>(16) </strong>ti ben<strong>ê </strong>(götürüyorsun)<br>
ti ben<strong>a </strong>(götürüyorsun) (Dêrsim, Gimgim) ti ben<strong>ay </strong>(götürüyorsun) (Dêrsim)<br>
ti bên (götürüyorsun) (Pali)</td>
</tr>
<tr>
<td style="height:51px; width:104px"><strong>a </strong>(m)............</td>
<td style="height:51px; width:140px">ti ken<strong>a </strong>(yapıyorsun) ti&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ben<strong>a</strong><br>
(götürüyorsun)</td>
<td style="height:51px; width:95px"><strong>ay</strong>.................</td>
<td style="height:51px; width:265px">ti ben<strong>ay </strong>(götürüyorsun) (Dêrsim)<br>
“Ellayê xo sînen<strong>ay</strong>” (Allahını seversen) (Şankuş)<strong>(17)</strong></td>
</tr>
<tr>
<td style="height:34px; width:104px"><strong>o </strong>(n)..............</td>
<td style="height:34px; width:140px">o ken<strong>o </strong>(yapıyor).......<br>
o ben<strong>o </strong>(götürüyor)...</td>
<td style="height:34px; width:95px"><strong>û</strong>..................</td>
<td style="height:34px; width:265px">o ken<strong>û </strong>(yapıyor)<br>
o ben<strong>û </strong>(götürüyor)</td>
</tr>
<tr>
<td style="height:34px; width:104px"><strong>a </strong>(m).............</td>
<td style="height:34px; width:140px">a ken<strong>a </strong>(yapıyor).......<br>
a ben<strong>a </strong>(götürüyor)</td>
<td style="height:34px; width:95px"><strong>ay</strong>.................</td>
<td style="height:34px; width:265px">a ben<strong>ay </strong>(götürüyor) (Dêrsim)</td>
</tr>
<tr>
<td style="height:169px; width:104px"><strong>ê</strong>........................<br>
&nbsp;<br>
&nbsp;<br>
<strong>îme</strong>....................</td>
<td style="height:169px; width:140px">ma ken<strong>ê </strong>(yapıyoruz) ma&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ben<strong>ê</strong><br>
(götürüyoruz)<br>
ma&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ken<strong>îme</strong><br>
(yapıyoruz)<br>
ma&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ben<strong>îme</strong><br>
(götürüyoruz</td>
<td style="height:169px; width:95px"><strong>î</strong>....................<br>
&nbsp;<br>
-...................<br>
<strong>îmi</strong>...............<br>
<strong>îm</strong>................<br>
<strong>me</strong>................<br>
<strong>mi</strong>................<br>
<strong>mî</strong>................<br>
<strong>m</strong>.................</td>
<td style="height:169px; width:265px">ma ken<strong>î </strong>(yapıyoruz) ma ben<strong>î </strong>(götürüyoruz) ma bên (Pali)<br>
ma&nbsp;&nbsp;&nbsp;&nbsp; ben<strong>îmi&nbsp;&nbsp;&nbsp;&nbsp; </strong>(götürüyoruz)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (Dêrsim, Gimgim)<br>
ma ben<strong>îm </strong>(götürüyoruz) (Dêrsim) ma be<strong>me </strong>(götürüyoruz) (Dêrsim) em be<strong>mi </strong>(götürüyoruz) (Dêrsim)<br>
ma we<strong>mî </strong>(yiyoruz) (Şankuş)<strong>(18) </strong>bey<strong>m </strong>(götürüyoruz) (Dêrsim)</td>
</tr>
<tr>
<td style="height:67px; width:104px"><strong>ê</strong>........................</td>
<td style="height:67px; width:140px">şima&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ken<strong>ê</strong><br>
(yapıyorsunuz)<br>
şima&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ben<strong>ê</strong><br>
(götürüyorsunuz)</td>
<td style="height:67px; width:95px"><strong>î</strong>....................<br>
&nbsp;<br>
-...................</td>
<td style="height:67px; width:265px">şima ken<strong>î </strong>(yapıyorsunuz) şima ben<strong>î </strong>(götürüyorsunuz) şima bên (Pali)</td>
</tr>
<tr>
<td style="height:51px; width:104px">ê........................</td>
<td style="height:51px; width:140px">ê ken<strong>ê </strong>(yapıyorlar)<br>
ê ben<strong>ê </strong>(götürüyorlar)</td>
<td style="height:51px; width:95px"><strong>î</strong>....................<br>
&nbsp;<br>
-...................</td>
<td style="height:51px; width:265px">ê ken<strong>î </strong>(yapıyorlar)<br>
ê ben<strong>î </strong>(götürüyorlar) ê bên (Pali)</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>7.11.2.Demo nikayin-II (Bi Partîkel)</h1><br>
Tewirê dîyin yê demê nikayinî, bi partîkelê “<strong>ha</strong>”/“<strong>-a</strong>”/“<strong>yê</strong>”yî yo. Nimûneyî:<br>
<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:24px; width:170px"><strong>Meylê Kombîyayîşî</strong><br>
(partîkel ... peygirê antişî)</td>
<td style="height:24px; width:151px"><strong>Meylê Kombîyayîşî</strong><br>
(Demo Nikayin-II)</td>
<td style="height:24px; width:170px"><strong>Formê Bînî</strong><br>
(partîkel ... peygirê antişî)</td>
<td style="height:24px; width:161px"><strong>Formê Bînî</strong><br>
(Demo Nikayin-II)</td>
</tr>
<tr>
<td style="height:110px; width:170px">1-ha ... <strong>a </strong>(n, m).................</td>
<td style="height:110px; width:151px">ez ha şin<strong>a </strong>(n, m) ez ha ken<strong>a </strong>(n, m)<br>
ez ha tersen<strong>a </strong>(n, m)</td>
<td style="height:110px; width:170px">ha ... <strong>o</strong>...............................<br>
ha ... <strong>û</strong>...............................<br>
ho ... <strong>a</strong>...............................<br>
ho ... <strong>o</strong>...............................<br>
ho ... <strong>û</strong>...............................<br>
ha ... -................................<br>
ho ... -................................<br>
ha ... -................................<br>
ho ... -................................</td>
<td style="height:110px; width:161px">ez ha şin<strong>o</strong>, ez ha tersen<strong>o </strong>ez ha şin<strong>û</strong><br>
ez ho şin<strong>a</strong>, ez ho tersen<strong>a </strong>ez ho şin<strong>o</strong>, ez ho tersen<strong>o </strong>ez ho şin<strong>û</strong><br>
ez ha şin ez ho şin<br>
ez ha tersen ez ho tersen</td>
</tr>
<tr>
<td style="height:49px; width:170px">1-a ... <strong>a </strong>(n, m)..................</td>
<td style="height:49px; width:151px">eza şin<strong>a </strong>(n, m) eza ken<strong>a </strong>(n, m)</td>
<td style="height:49px; width:170px">o ... <strong>a</strong>.................................<br>
o ... <strong>û</strong>.................................<br>
o ... <strong>i</strong>.................................<br>
û ... <strong>a</strong>.................................</td>
<td style="height:49px; width:161px">ezo şin<strong>a</strong>, ezo wanen<strong>a </strong>ezo ben<strong>û</strong><br>
ezo nişan<strong>i </strong>ro<strong>(19) </strong>ezû wanen<strong>a</strong></td>
</tr>
<tr>
<td style="height:25px; width:170px">1-a ... <strong>ane </strong>(n, m)...............</td>
<td style="height:25px; width:151px">eza ben<strong>ane </strong>(n, m)<br>
eza ken<strong>ane </strong>(n, m)</td>
<td style="height:25px; width:170px">o ... <strong>ûne</strong>.............................<br>
o ... -..................................</td>
<td style="height:25px; width:161px">ezo ben<strong>ûne</strong><br>
ezo ben</td>
</tr>
<tr>
<td style="height:61px; width:170px">2-yê ... <strong>î </strong>(n).......................</td>
<td style="height:61px; width:151px">ti yê şin<strong>î </strong>(n)..............<br>
ti yê ken<strong>î </strong>(n).............</td>
<td style="height:61px; width:170px">yê ... <strong>ê</strong>...............................<br>
hê ... <strong>ê</strong>...............................<br>
hê ... <strong>î</strong>................................<br>
ha ... <strong>î</strong>................................<br>
hê ... -................................</td>
<td style="height:61px; width:161px">ti yê şin<strong>ê </strong>ti hê şin<strong>ê</strong><br>
ti hê ken<strong>î</strong>, ti hê tersen<strong>î </strong>ti ha ken<strong>î</strong><br>
ti hê tersîn</td>
</tr>
<tr>
<td style="height:37px; width:170px">2-ya ... <strong>a </strong>(m)................</td>
<td style="height:37px; width:151px">ti ya şin<strong>a </strong>(m)..........<br>
ti ya ken<strong>a </strong>(m).........<br>
ti ya wanen<strong>a </strong>(m).....</td>
<td style="height:37px; width:170px">ha ... a ..............................<br>
ha ... a ..............................<br>
yo ... a ..............................</td>
<td style="height:37px; width:161px">ti ha şina, ti ha tersen<strong>a </strong>ti ha ken<strong>a</strong><br>
ti yo wanen<strong>a</strong></td>
</tr>
<tr>
<td style="height:74px; width:170px">3-yo ... <strong>o </strong>(n)..................</td>
<td style="height:74px; width:151px">o yo şin<strong>o</strong>......................<br>
o yo ken<strong>o</strong>.....................<br>
o yo wanen<strong>o</strong><br>
o yo ben<strong>o</strong></td>
<td style="height:74px; width:170px">ho ... <strong>o</strong>...............................<br>
ho ... <strong>o</strong>...............................<br>
ha ... <strong>o</strong>...............................<br>
wo ... <strong>o</strong>..............................<br>
ho ... <strong>û</strong>...............................<br>
ho ... <strong>-</strong>................................</td>
<td style="height:74px; width:161px">o ho şin<strong>o</strong><br>
o ho ken<strong>o </strong>we ha ken<strong>o</strong><br>
o wo ken<strong>o</strong>, owo wanen<strong>o </strong>wi ho şin<strong>û</strong>, wi ho tersen<strong>û </strong>wi ho şin</td>
</tr>
<tr>
<td style="height:37px; width:170px">3-ya ... <strong>a </strong>(m)................</td>
<td style="height:37px; width:151px">a ya şin<strong>a</strong>.......................<br>
a ya wanena.................<br>
a ya kena......................</td>
<td style="height:37px; width:170px">ha ... <strong>a</strong>...............................<br>
yo ... <strong>a</strong>...............................<br>
wa ... <strong>a</strong>..............................</td>
<td style="height:37px; width:161px">a ha şin<strong>a</strong>, ya ha tersen<strong>a </strong>a yo wanen<strong>a</strong><br>
a wa ken<strong>a</strong>, a wa ben<strong>a</strong></td>
</tr>
<tr>
<td style="height:98px; width:170px">1-yê ... <strong>ê</strong>............................<br>
&nbsp;<br>
1-yê ... <strong>îme</strong>........................</td>
<td style="height:98px; width:151px">ma yê şin<strong>ê</strong>, ma yê ken<strong>ê </strong>ma yê şin<strong>îme</strong>..............<br>
ma yê wanen<strong>îme</strong>........</td>
<td style="height:98px; width:170px">hê ... <strong>î</strong>................................<br>
hê ... -................................<br>
yê ... <strong>im</strong>.............................<br>
yê ...<strong>î</strong>.................................<br>
yo ... <strong>îme</strong>...........................<br>
o ... <strong>îme</strong>.............................<br>
yê ... <strong>m</strong>..............................<br>
yê ... <strong>mî</strong>.............................</td>
<td style="height:98px; width:161px">ma hê şin<strong>î</strong>, ma hê ken<strong>î </strong>mi hê ... tersîn<br>
ma yê şin<strong>im </strong>ma yê nişen<strong>î </strong>ro<br>
ma yo wanen<strong>îme </strong>mao ben<strong>îme</strong><br>
ma yê şi<strong>m</strong><br>
ma yê nişe<strong>mî </strong>ro<strong>(20)</strong></td>
</tr>
<tr>
<td style="height:61px; width:170px">2-yê ... <strong>ê</strong>............................</td>
<td style="height:61px; width:151px">şima yê şin<strong>ê</strong>.................<br>
şima yê ken<strong>ê</strong>................<br>
şima yê wanen<strong>ê</strong>...........</td>
<td style="height:61px; width:170px">hê ... <strong>î</strong>................................<br>
ha ... <strong>î</strong>................................<br>
yo ... <strong>ê</strong>...............................<br>
ê ... <strong>ê</strong>.................................<br>
hê ... -................................</td>
<td style="height:61px; width:161px">şima hê şin<strong>î</strong>, şima hê ken<strong>î </strong>şima ha ken<strong>î</strong><br>
şima yo wanen<strong>ê </strong>simaê ben<strong>ê</strong><br>
şima hê tersîn</td>
</tr>
<tr>
<td style="height:61px; width:170px">3-yê ... <strong>ê</strong>............................</td>
<td style="height:61px; width:151px">ê yê şin<strong>ê</strong>.......................<br>
ê yê ken<strong>ê</strong>......................<br>
ê yê wanen<strong>ê</strong>.................</td>
<td style="height:61px; width:170px">hê ... <strong>î</strong>................................<br>
hê ... <strong>î</strong>................................<br>
yo ... <strong>ê</strong>...............................<br>
ê ... <strong>ê</strong>.................................<br>
hê ... -................................</td>
<td style="height:61px; width:161px">yî hê şin<strong>î</strong>, yî hê tersen<strong>î </strong>ê hê ken<strong>î</strong><br>
ê yo wanen<strong>ê </strong>îê ben<strong>ê</strong><br>
yî hê tersîn</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;<br>
Partîkelê demê nikayinî yê karî, ge-ge kilm beno û sey “<strong>a</strong>” yan zî “<strong>o</strong>”yî vajîyeno. Nê partîkelanê corênan ra <strong>partîkelo ke tena yew herf a, o û zemîr pîya</strong>; <strong>partîkelo ke yew herfe ra vêşêr (zîyadeyêr) o, o û zemîr cîya nusîyenê. </strong>Yanî partîkelê “<strong>a</strong>” û “<strong>o</strong>”yî û zemîr pîya nusîyenê. Nimûne: Ez<strong>a </strong>vana/Ez<strong>o </strong>vana (ben söylüyorum). Labelê partîkelê corênî yê bînî û zemîr cîya nusîyenê.<br>
&nbsp;
<h1>7.12.Partîkelê Demê Ameyoxî yê Karî (Fiilin Gelecek Zaman Partikeli)</h1><br>
&nbsp;

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:187px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:198px"><strong>formê bînî</strong></td>
<td style="height:17px; width:189px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:187px"><strong>do</strong></td>
<td style="height:17px; width:198px"><strong>dê</strong>, <strong>ko</strong>, <strong>go</strong>, <strong>ro</strong></td>
<td style="height:17px; width:189px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:187px">ez <strong>do </strong>bêra, ez <strong>do </strong>bêrane</td>
<td style="height:17px; width:198px">ez <strong>ko </strong>bêra, ez <strong>ko </strong>bêro</td>
<td style="height:17px; width:189px">ben geleceğim</td>
</tr>
<tr>
<td style="height:17px; width:187px">ez <strong>do </strong>bigîra, ez <strong>do </strong>bigîrane</td>
<td style="height:17px; width:198px">ez <strong>ko </strong>bigîra, ez <strong>ko </strong>bigîro</td>
<td style="height:17px; width:189px">ben alacağım</td>
</tr>
<tr>
<td style="height:17px; width:187px">ti <strong>do </strong>bêrî</td>
<td style="height:17px; width:198px">ti <strong>ko </strong>bêrî, ti <strong>go </strong>bêrî, ti <strong>go </strong>bêrê</td>
<td style="height:17px; width:189px">sen geleceksin</td>
</tr>
<tr>
<td style="height:17px; width:187px">ti <strong>do </strong>bimirî</td>
<td style="height:17px; width:198px">ti <strong>dê </strong>bimirê</td>
<td style="height:17px; width:189px">sen öleceksin</td>
</tr>
<tr>
<td style="height:17px; width:187px">ti <strong>do </strong>bişimî</td>
<td style="height:17px; width:198px">ti <strong>yo </strong>bişimê, tu <strong>yo </strong>bişimê</td>
<td style="height:17px; width:189px">sen içeceksin</td>
</tr>
<tr>
<td style="height:17px; width:187px">ê <strong>do </strong>bêrê</td>
<td style="height:17px; width:198px">ê <strong>ro </strong>bêrê</td>
<td style="height:17px; width:189px">onlar gelecekler</td>
</tr>
<tr>
<td style="height:17px; width:187px"><strong>-o</strong></td>
<td style="height:17px; width:198px"><strong>o, ko</strong></td>
<td style="height:17px; width:189px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:187px">ez<strong>o </strong>bêrî</td>
<td style="height:17px; width:198px">ez <strong>o </strong>bêrî</td>
<td style="height:17px; width:189px">ben geleceğim</td>
</tr>
<tr>
<td style="height:34px; width:187px">ez<strong>o </strong>vaja, ez<strong>o </strong>vajî</td>
<td style="height:34px; width:198px">ez <strong>ko </strong>vaja, ez <strong>o </strong>vaja / ez <strong>o </strong>vajî<br>
ez <strong>o </strong>vacû / ez <strong>o </strong>vacî</td>
<td style="height:34px; width:189px">ben söyliyeceğim</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<ol>
<li><h1>7.13.Nuştişê Karan û Vîrgul</h1></li>
</ol>
Tayê cumleyan de yewî ra zafêr karî est ê. Eke nê karî eynî game de pîya bibê, yanî bi caardişê yew karî karo bîn zî bibo, beyntarê nê karan de vîrgul nêronîyeno. Seke cumleyanê cêrênan de aseno, gama ke vîrgul ronîyo, manaya cumle vurneno. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:225px"><strong>kirmanckî</strong></td>
<td style="height:17px; width:208px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:225px">Da piro şi.</td>
<td style="height:17px; width:208px">Çekip gitti.</td>
</tr>
<tr>
<td style="height:17px; width:225px">Da piro<strong>, </strong>şi.</td>
<td style="height:17px; width:208px">Vurdu<strong>, </strong>[sonra] gitti.</td>
</tr>
<tr>
<td style="height:17px; width:225px">Girewt kaş kerd berd.</td>
<td style="height:17px; width:208px">Alıp çekti götürdü.</td>
</tr>
<tr>
<td style="height:17px; width:225px">Girewt<strong>, </strong>kaş kerd berd.</td>
<td style="height:17px; width:208px">Aldı<strong>, </strong>çekti götürdü.</td>
</tr>
<tr>
<td style="height:17px; width:225px">Tifingî pa/pira nayî kiştî.</td>
<td style="height:17px; width:208px">Tüfek sıkıp öldürmüşler.</td>
</tr>
<tr>
<td style="height:17px; width:225px">Tifingî pa/pira nayî<strong>, </strong>kiştî.</td>
<td style="height:17px; width:208px">Tüfek sıkmışlar<strong>, </strong>öldürmüşler.</td>
</tr>
<tr>
<td style="height:17px; width:225px">Werzeno vazdano şono.</td>
<td style="height:17px; width:208px">Kalkıp koşar gider.</td>
</tr>
<tr>
<td style="height:17px; width:225px">Werzeno<strong>, </strong>vazdano şono.</td>
<td style="height:17px; width:208px">Kalkar<strong>, </strong>[sonra] koşar gider.</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>8.ÇEND PEYGIRÎ (BİRKAÇ SONEK)</h1><br>

<ol>
<li><h1>8.1.-ên</h1></li>
</ol>
Peygirê “<strong>-ên</strong>”î manayanê cîya-cîyayan dano çekuyan. Mavajî ke mana “<strong>-den kalma</strong>”, “<strong>-den yapılma</strong>” û “<strong>-daki</strong>”ya tirki dano. Cayê nê peygirî de, tayê fekan de “<strong>-în</strong>” yan zî “<strong>-in</strong>”/“<strong>-en</strong>”/“<strong>-yen</strong>” vajîyeno. Meylê kombîyayîşî nînan ra formê “<strong>-ên</strong>”î ser o yo. Nimûneyî:<br>
&nbsp;<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:170px"><strong>formê bînî</strong></td>
<td style="height:17px; width:227px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>-(y)ên, -e</strong></td>
<td style="height:17px; width:170px"><strong>-(y)în</strong>, <strong>-(y)in</strong>, <strong>-en</strong>, <strong>-yen</strong></td>
<td style="height:17px; width:227px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:140px">peme<strong>yên</strong>, -e</td>
<td style="height:17px; width:170px">peme<strong>yîn</strong>, pemi<strong>yen</strong></td>
<td style="height:17px; width:227px">pamuktan yapılma</td>
</tr>
<tr>
<td style="height:17px; width:140px">pere<strong>yên</strong>, -e</td>
<td style="height:17px; width:170px">pere<strong>yîn</strong>, pere<strong>yin</strong>, perê<strong>yen</strong></td>
<td style="height:17px; width:227px">önceki günden kalma, önceki günkü</td>
</tr>
<tr>
<td style="height:17px; width:140px">asin<strong>ên</strong>, -e</td>
<td style="height:17px; width:170px">asin<strong>în</strong>, asin<strong>yen</strong></td>
<td style="height:17px; width:227px">demirden yapılma</td>
</tr>
<tr>
<td style="height:17px; width:140px">bin<strong>ên</strong>, -e</td>
<td style="height:17px; width:170px">bin<strong>în</strong>, bin<strong>yen</strong></td>
<td style="height:17px; width:227px">alttaki, alt taraftaki</td>
</tr>
<tr>
<td style="height:17px; width:140px">cêr<strong>ên</strong>, -e</td>
<td style="height:17px; width:170px">cêr<strong>în</strong>, cîr<strong>yen</strong></td>
<td style="height:17px; width:227px">aşağıdaki, aşağı taraftaki</td>
</tr>
<tr>
<td style="height:17px; width:140px">cor<strong>ên</strong>, -e</td>
<td style="height:17px; width:170px">cor<strong>în</strong>, cuar<strong>yen</strong></td>
<td style="height:17px; width:227px">yukarıdaki, yukarı taraftaki</td>
</tr>
<tr>
<td style="height:17px; width:140px">par<strong>ên</strong>, -e</td>
<td style="height:17px; width:170px">par<strong>în</strong>, par<strong>yen</strong></td>
<td style="height:17px; width:227px">geçen yıldan kalma, geçen yılki</td>
</tr>
<tr>
<td style="height:17px; width:140px">pey<strong>ên</strong>, -e</td>
<td style="height:17px; width:170px">pêy<strong>ên</strong>, pêy<strong>ê</strong>, pey<strong>en</strong></td>
<td style="height:17px; width:227px">sonuncu, sondaki</td>
</tr>
<tr>
<td style="height:17px; width:140px">ser<strong>ên</strong>, -e</td>
<td style="height:17px; width:170px">ser<strong>în</strong></td>
<td style="height:17px; width:227px">üstteki, üst taraftaki</td>
</tr>
<tr>
<td style="height:17px; width:140px">sêm<strong>ên</strong>, e</td>
<td style="height:17px; width:170px">sêm<strong>în</strong></td>
<td style="height:17px; width:227px">gümüşten yapılma</td>
</tr>
<tr>
<td style="height:17px; width:140px">ver<strong>ên</strong>, -e</td>
<td style="height:17px; width:170px">ver<strong>în</strong>, vir<strong>ên</strong>, vir<strong>yen</strong></td>
<td style="height:17px; width:227px">önceki, ilk, eski</td>
</tr>
<tr>
<td style="height:17px; width:140px">vizêr<strong>ên</strong>, -e</td>
<td style="height:17px; width:170px">vizêr<strong>în</strong>, vizêr<strong>in</strong></td>
<td style="height:17px; width:227px">dünkü, dünden kalma</td>
</tr>
<tr>
<td style="height:17px; width:140px">zerr<strong>ên</strong>, -e</td>
<td style="height:17px; width:170px">zerr<strong>în</strong></td>
<td style="height:17px; width:227px">altından yapılma</td>
</tr>
<tr>
<td style="height:17px; width:140px">zerrn<strong>ên</strong>, -e</td>
<td style="height:17px; width:170px">zerrn<strong>în</strong></td>
<td style="height:17px; width:227px">altından yapılma</td>
</tr>
</tbody>
</table>
<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;
<h1>8.2.-in</h1><br>
Peygirê “<strong>-in</strong>”î manaya peygirê “-lı/-li”ya tirkî dano. Cayê nê peygirî de, tayê fekan de “<strong>-în</strong>” vajîyeno. Meylê kombîyayîşî nînan ra formê “<strong>-in</strong>”î ser o yo.<br>
&nbsp;<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:142px"><strong>formê bînî</strong></td>
<td style="height:17px; width:255px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px"><strong>-in</strong>, <strong>-e</strong></td>
<td style="height:17px; width:142px"><strong>-în</strong></td>
<td style="height:17px; width:255px">&nbsp;</td>
</tr>
<tr>
<td style="height:17px; width:140px">sol<strong>in</strong>, -e</td>
<td style="height:17px; width:142px">sual<strong>in</strong>, suel<strong>in</strong></td>
<td style="height:17px; width:255px">tuzlu (içinde tuz bulunan)</td>
</tr>
<tr>
<td style="height:17px; width:140px">asin<strong>in</strong>, -e</td>
<td style="height:17px; width:142px">asin<strong>în</strong></td>
<td style="height:17px; width:255px">demirli (içinde demir bulunan)</td>
</tr>
<tr>
<td style="height:17px; width:140px">peme<strong>yin</strong>, -e</td>
<td style="height:17px; width:142px">peme<strong>yîn</strong></td>
<td style="height:17px; width:255px">pamuklu (içinde pamuk bulunan)</td>
</tr>
<tr>
<td style="height:17px; width:140px">sêm<strong>in</strong>, -e</td>
<td style="height:17px; width:142px">sêm<strong>în</strong>, sîm<strong>in</strong>, sîem<strong>in</strong></td>
<td style="height:17px; width:255px">gümüşlü (içinde gümüş bulunan)</td>
</tr>
<tr>
<td style="height:17px; width:140px">zerrn<strong>in</strong>, -e</td>
<td style="height:17px; width:142px">zerrn<strong>în</strong></td>
<td style="height:17px; width:255px">altınlı (içinde altın bulunan)</td>
</tr>
</tbody>
</table>
&nbsp;<br>
Tayê nuştoxî, bi tesîrê tirkî, vera peygirê “<strong>-lı/-li</strong>”yê tirkî de peygirê “<strong>-in</strong>” yê kirmanckî nusenê. Kirmanckî de peygirê “-in”î manaya peygirê “-lı/-li”yê tirkî dano la ganî merdim xo vîr ra nêko ke kirmanckî de, vera nê peygirî de <strong>tim peygirê “-in”î nêbeno</strong>. Peygirê “-in”î zafane seba çîyanê <strong>maddî</strong>yan o. Nimûneyî:<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>kirmanckî</strong></td>
<td style="height:17px; width:76px"><strong>tirkî</strong></td>
<td style="height:17px; width:236px"><strong>kirmanckî</strong></td>
<td style="height:17px; width:151px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">sol<strong>in</strong>, -e</td>
<td style="height:17px; width:76px">tuz<strong>lu</strong></td>
<td style="height:17px; width:236px">dew<strong>ij</strong>, -e</td>
<td style="height:17px; width:151px">köy<strong>lü</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">rûn<strong>in</strong>, -e; ruwen<strong>in</strong>, -i</td>
<td style="height:17px; width:76px">yağ<strong>lı</strong></td>
<td style="height:17px; width:236px">paye<strong>ke</strong>r, -e</td>
<td style="height:17px; width:151px">kibir<strong>li</strong>, gösteriş yapan</td>
</tr>
<tr>
<td style="height:34px; width:140px">toraq<strong>in</strong>, -e;<br>
tantûr<strong>in</strong>, -e</td>
<td style="height:34px; width:76px">çökele<strong>kli</strong></td>
<td style="height:34px; width:236px">heq<strong>dar</strong>, -e</td>
<td style="height:34px; width:151px">hak<strong>lı</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">simer<strong>in</strong>, -e</td>
<td style="height:17px; width:76px">saman<strong>lı</strong></td>
<td style="height:17px; width:236px"><strong>bi</strong>namûs, -e</td>
<td style="height:17px; width:151px">namus<strong>lu</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">telî<strong>yin</strong>, -e</td>
<td style="height:17px; width:76px">dike<strong>nli</strong></td>
<td style="height:17px; width:236px"><strong>bi</strong>bar, -e; bar<strong>dar</strong>, -e</td>
<td style="height:17px; width:151px">yük<strong>lü</strong></td>
</tr>
<tr>
<td style="height:34px; width:140px">este<strong>yin</strong>, -e;<br>
kate<strong>yin</strong>, -e</td>
<td style="height:34px; width:76px">kemik<strong>li</strong></td>
<td style="height:34px; width:236px"><strong>bi</strong>maye, <strong>bi</strong>mare, <strong>bi</strong>dadî</td>
<td style="height:34px; width:151px">ana<strong>lı </strong>(anası var olan)</td>
</tr>
<tr>
<td style="height:34px; width:140px">aw<strong>in</strong>, -e</td>
<td style="height:34px; width:76px">su<strong>lu</strong></td>
<td style="height:34px; width:236px"><strong>bi</strong>destmaj,-e;<strong>bi</strong>awdest, -e,<strong>bi</strong>ondaz,-e;<br>
destmaj ra, awdest ra, ondaz ra</td>
<td style="height:34px; width:151px">abdest<strong>li</strong></td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>9.RASTNUŞTIŞÊ TAYÊ ÇEKUYAN (BAZI SÖZCÜKLERİN DOĞRU YAZIMI)</h1><br>

<ol>
<li><h1>9.1.zî, kî</h1></li>
</ol>
Kirmanckî de, manaya “jî”yê kurmanckî û “de”yê tirkî de, fek ra fek <strong>“zî”</strong>, <strong>“kî”</strong>, <strong>“jî”</strong>, <strong>“iz”</strong>, yan zî<br>
<strong>“çî” </strong>vajîyeno. Meylê kombîyayîşî nê forman ra “<strong>zî</strong>” û “<strong>kî</strong>”yî ser o yo.<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:253px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:170px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:253px"><strong>zî, kî</strong></td>
<td style="height:17px; width:170px">de, dahi</td>
</tr>
<tr>
<td style="height:17px; width:253px">Ez <strong>zî </strong>zana ti <strong>zî</strong>./Ez <strong>kî </strong>zanena ti <strong>kî</strong>.</td>
<td style="height:17px; width:170px">Ben de biliyorum sen de.</td>
</tr>
<tr>
<td style="height:17px; width:253px">Ma <strong>zî </strong>va şima <strong>zî</strong>./Ma <strong>kî </strong>va şima <strong>kî</strong>.</td>
<td style="height:17px; width:170px">Biz de söyledik siz de.</td>
</tr>
<tr>
<td style="height:17px; width:253px">Erzingan <strong>zî </strong>bajar o./Erzingan <strong>kî </strong>bajar o.</td>
<td style="height:17px; width:170px">Erzincan da kenttir.</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>9.2.Çekuyê Tekrarbîyayeyî</h1><br>
Mabênê çekuyanê tekrarbîyayeyan û diletan (çutan) de nîşanê tîre (tire işareti) ronîyeno. Çend nimûneyî:<br>
<br>
&nbsp;
<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:236px"><strong>formê bînî</strong></td>
<td style="height:17px; width:217px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">ge-ge</td>
<td style="height:17px; width:236px">ge ge, gege</td>
<td style="height:17px; width:217px">bazen, ara sıra</td>
</tr>
<tr>
<td style="height:17px; width:140px">ge-gane</td>
<td style="height:17px; width:236px">ge gane, gegane</td>
<td style="height:17px; width:217px">bazen, ara sıra</td>
</tr>
<tr>
<td style="height:17px; width:140px">gurr-gurr</td>
<td style="height:17px; width:236px">gurr gurr</td>
<td style="height:17px; width:217px">gümbür gümbür, hüngür hüngür</td>
</tr>
<tr>
<td style="height:17px; width:140px">gurre-gurre</td>
<td style="height:17px; width:236px">gurre gurre</td>
<td style="height:17px; width:217px">gümbürtü</td>
</tr>
<tr>
<td style="height:17px; width:140px">rew-rew</td>
<td style="height:17px; width:236px">rew rew, rewrew, row-row, ro-ro</td>
<td style="height:17px; width:217px">kolay kolay, çabuk çabuk, sık sık</td>
</tr>
<tr>
<td style="height:17px; width:140px">rey-rey</td>
<td style="height:17px; width:236px">rey rey, reyrey, ray-ray, ra-ray, rê-rê</td>
<td style="height:17px; width:217px">bazen, ara sıra</td>
</tr>
<tr>
<td style="height:17px; width:140px">vit-vit</td>
<td style="height:17px; width:236px">vitvit</td>
<td style="height:17px; width:217px">dırdır, vıdı vıdı</td>
</tr>
<tr>
<td style="height:17px; width:140px">viz-viz</td>
<td style="height:17px; width:236px">viz viz, vizviz</td>
<td style="height:17px; width:217px">vızıltı, vızıldama</td>
</tr>
<tr>
<td style="height:17px; width:140px">zirt-zirt</td>
<td style="height:17px; width:236px">zirt zirt</td>
<td style="height:17px; width:217px">zart zurt</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<h1>9.3.Çekuyê Diletî</h1><br>

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:140px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:199px"><strong>formê bînî</strong></td>
<td style="height:17px; width:264px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:140px">çar-panc reyî</td>
<td style="height:17px; width:199px">çar panc reyî, çar pûnc ray</td>
<td style="height:17px; width:264px">dört beş kez</td>
</tr>
<tr>
<td style="height:17px; width:140px">hal-hewal</td>
<td style="height:17px; width:199px">hal hewal</td>
<td style="height:17px; width:264px">1-ahval, hal, durum 2-hülasa, kısacası</td>
</tr>
<tr>
<td style="height:17px; width:140px">meşte-bîro</td>
<td style="height:17px; width:199px">meşte-bêro, meste-bîro</td>
<td style="height:17px; width:264px">yarın öbür gün</td>
</tr>
<tr>
<td style="height:17px; width:140px">nohêm-ohêm</td>
<td style="height:17px; width:199px">na hêm do hêm, nahêm dohêm</td>
<td style="height:17px; width:264px">bu taraf o taraf, o yaka bu yaka</td>
</tr>
<tr>
<td style="height:17px; width:140px">naşta-daşta</td>
<td style="height:17px; width:199px">naş ta daş ta, naşta daşta</td>
<td style="height:17px; width:264px">ötede beride, bu tarafta o tarafta</td>
</tr>
<tr>
<td style="height:17px; width:140px">nata-bota</td>
<td style="height:17px; width:199px">na ta bo ta, nata bota</td>
<td style="height:17px; width:264px">o yana bu yana</td>
</tr>
<tr>
<td style="height:17px; width:140px">nover-bover</td>
<td style="height:17px; width:199px">na ver bo ver, naver bover</td>
<td style="height:17px; width:264px">öte beri, bu yaka karşı yaka</td>
</tr>
<tr>
<td style="height:17px; width:140px">nover-wever</td>
<td style="height:17px; width:199px">na ver we ver, naver wever</td>
<td style="height:17px; width:264px">öte beri, bu yaka karşı yaka</td>
</tr>
<tr>
<td style="height:17px; width:140px">qîj-wîj</td>
<td style="height:17px; width:199px">qîj wîj, qîjwîj</td>
<td style="height:17px; width:264px">bağırış, çığlık, gürültü</td>
</tr>
<tr>
<td style="height:17px; width:140px">rep-tep</td>
<td style="height:17px; width:199px">rep tep, reptep</td>
<td style="height:17px; width:264px">pat küt, gürültü patırtı</td>
</tr>
<tr>
<td style="height:17px; width:140px">req-teq</td>
<td style="height:17px; width:199px">req teq, reqteq</td>
<td style="height:17px; width:264px">tak tuk, pat küt,takırtı,tıkırtı,gürültü patırtı</td>
</tr>
<tr>
<td style="height:17px; width:140px">reyê-di reyî</td>
<td style="height:17px; width:199px">rêyî di rêyî, rayê di rayî</td>
<td style="height:17px; width:264px">bir iki kez</td>
</tr>
<tr>
<td style="height:17px; width:140px">teq-req</td>
<td style="height:17px; width:199px">teq req, teqreq</td>
<td style="height:17px; width:264px">tak tuk, pat küt,takırtı,tıkırtı,gürültü patırtı</td>
</tr>
<tr>
<td style="height:17px; width:140px">rew-erey</td>
<td style="height:17px; width:199px">rew erey</td>
<td style="height:17px; width:264px">er geç</td>
</tr>
<tr>
<td style="height:17px; width:140px">sax-silamet</td>
<td style="height:17px; width:199px">sax silamet</td>
<td style="height:17px; width:264px">sağ salim</td>
</tr>
<tr>
<td style="height:17px; width:140px">tek-tuk</td>
<td style="height:17px; width:199px">tek tuk</td>
<td style="height:17px; width:264px">tek tük</td>
</tr>
<tr>
<td style="height:17px; width:140px">teq-tuq</td>
<td style="height:17px; width:199px">teq tuq</td>
<td style="height:17px; width:264px">tak tuk, takır tukur, pat küt</td>
</tr>
<tr>
<td style="height:17px; width:140px">xeyr-silam</td>
<td style="height:17px; width:199px">xêr-silam</td>
<td style="height:17px; width:264px">hayırlısıyla, selametle</td>
</tr>
<tr>
<td style="height:17px; width:140px">zar-zêç</td>
<td style="height:17px; width:199px">zav-zêç</td>
<td style="height:17px; width:264px">çoluk çocuk</td>
</tr>
</tbody>
</table>
&nbsp;<br>
&nbsp;
<ol>
<li><h1>9.4.Rastnuştişê Tayêna Çekuyan</h1></li>
</ol>
&nbsp;

<table border="1" cellpadding="0" cellspacing="0">
<tbody>
<tr>
<td style="height:17px; width:177px"><strong>meylê kombîyayîşî</strong></td>
<td style="height:17px; width:227px"><strong>formê bînî</strong></td>
<td style="height:17px; width:198px"><strong>tirkî</strong></td>
</tr>
<tr>
<td style="height:17px; width:177px">acêr</td>
<td style="height:17px; width:227px">a cêr</td>
<td style="height:17px; width:198px">aşağıya doğru</td>
</tr>
<tr>
<td style="height:17px; width:177px">acor</td>
<td style="height:17px; width:227px">a cor</td>
<td style="height:17px; width:198px">yukarıya doğru</td>
</tr>
<tr>
<td style="height:17px; width:177px">badê cû</td>
<td style="height:17px; width:227px">badîcû, badêcû, badê coy, bedê cû</td>
<td style="height:17px; width:198px">ondan sonra, sonra</td>
</tr>
<tr>
<td style="height:17px; width:177px">cêr ra</td>
<td style="height:17px; width:227px">cêra</td>
<td style="height:17px; width:198px">aşağıdan</td>
</tr>
<tr>
<td style="height:17px; width:177px">cêr ro</td>
<td style="height:17px; width:227px">cêro</td>
<td style="height:17px; width:198px">aşağıdan</td>
</tr>
<tr>
<td style="height:17px; width:177px">cor de</td>
<td style="height:17px; width:227px">corde, cor di</td>
<td style="height:17px; width:198px">yukarıdan</td>
</tr>
<tr>
<td style="height:17px; width:177px">cor ra</td>
<td style="height:17px; width:227px">cora, corra</td>
<td style="height:17px; width:198px">yukarıdan</td>
</tr>
<tr>
<td style="height:17px; width:177px">çi yo</td>
<td style="height:17px; width:227px">çiyo</td>
<td style="height:17px; width:198px">nedir</td>
</tr>
<tr>
<td style="height:17px; width:177px">çik o</td>
<td style="height:17px; width:227px">çiko</td>
<td style="height:17px; width:198px">nedir</td>
</tr>
<tr>
<td style="height:17px; width:177px">devacêr</td>
<td style="height:17px; width:227px">dev a cêr, deva cêr, davacêr</td>
<td style="height:17px; width:198px">aşağıya doğru, yokuş aşağı</td>
</tr>
<tr>
<td style="height:17px; width:177px">devacor</td>
<td style="height:17px; width:227px">dev a cor, deva cor, davacor</td>
<td style="height:17px; width:198px">yukarıya doğru, yokuş yukarı</td>
</tr>
<tr>
<td style="height:17px; width:177px">ha ya</td>
<td style="height:17px; width:227px">haya, ha wa, hawa</td>
<td style="height:17px; width:198px">işte, odur</td>
</tr>
<tr>
<td style="height:17px; width:177px">ha yê</td>
<td style="height:17px; width:227px">hayê, hay</td>
<td style="height:17px; width:198px">işte, onlardır</td>
</tr>
<tr>
<td style="height:17px; width:177px">ha yo</td>
<td style="height:17px; width:227px">ha wo, hawo, haw, hew</td>
<td style="height:17px; width:198px">işte, odur</td>
</tr>
<tr>
<td style="height:17px; width:177px">na ya</td>
<td style="height:17px; width:227px">naya, na wa, nawa</td>
<td style="height:17px; width:198px">işte, budur</td>
</tr>
<tr>
<td style="height:17px; width:177px">nara</td>
<td style="height:17px; width:227px">na ra</td>
<td style="height:17px; width:198px">bir de</td>
</tr>
<tr>
<td style="height:17px; width:177px">nê yê</td>
<td style="height:17px; width:227px">nêyê, nî yê</td>
<td style="height:17px; width:198px">işte, bunlardır</td>
</tr>
<tr>
<td style="height:17px; width:177px">no yo</td>
<td style="height:17px; width:227px">noyo, no wo, na wo</td>
<td style="height:17px; width:198px">işte, budur</td>
</tr>
<tr>
<td style="height:17px; width:177px">peyê cû</td>
<td style="height:17px; width:227px">peyêcû, peyê coy, pêyê cû</td>
<td style="height:17px; width:198px">ondan sonra</td>
</tr>
<tr>
<td style="height:34px; width:177px">peyser</td>
<td style="height:34px; width:227px">pey ser, pêyser</td>
<td style="height:34px; width:198px">geri, geri geri, gerisin geri,<br>
gerisin geriye</td>
</tr>
<tr>
<td style="height:34px; width:177px">seracêr</td>
<td style="height:34px; width:227px">ser a cêr, sera cêr,<br>
sinacîyer, sinacyer, sinacîer</td>
<td style="height:34px; width:198px">aşağıya doğru, yokuş aşağı</td>
</tr>
<tr>
<td style="height:17px; width:177px">seke</td>
<td style="height:17px; width:227px">se ke, zeke, zeki, hezeki, hezek</td>
<td style="height:17px; width:198px">1-gibi 2-sanki</td>
</tr>
<tr>
<td style="height:17px; width:177px">vera ...</td>
<td style="height:17px; width:227px">ver a ...</td>
<td style="height:17px; width:198px">karşı, karşısında</td>
</tr>
<tr>
<td style="height:17px; width:177px">veracêr</td>
<td style="height:17px; width:227px">ver a cêr, vera cêr, veracîyer</td>
<td style="height:17px; width:198px">aşağıya doğru, iniş</td>
</tr>
<tr>
<td style="height:17px; width:177px">veracor</td>
<td style="height:17px; width:227px">ver a cor, vera cor, veracuar</td>
<td style="height:17px; width:198px">yukarıya doğru, yokuş</td>
</tr>
<tr>
<td style="height:17px; width:177px">verba…</td>
<td style="height:17px; width:227px">ver ba…, verva ..., ver va ...</td>
<td style="height:17px; width:198px">karşı, karşısında</td>
</tr>
<tr>
<td style="height:152px; width:177px">yewbînî<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
<strong>Ma dostê yewbînî me/yê.</strong></td>
<td style="height:152px; width:227px">yewbîn, yobîn, yobînî, yowbîn, cîmna, jewbîn, jewbînî, jewmbînî, jobîn, jobînî, jubîn, jubînî, jumîn, jumînî, jumunî, juwîn, jûbîn, jûbînî, jûmîn, jûmînî, jûvinî, jûvîn, jûvînî, jûwîn, jûwînî, zûbin, zûbinî, zûbîn, zûbînî, zûmin, zûminî, zûmîn, zûmînî, zûvinî, zûvîn, zûvînî</td>
<td style="height:152px; width:198px">birbiri<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
<strong>Biz birbirimizin dostuyuz.</strong></td>
</tr>
<tr>
<td style="height:101px; width:177px">yewbînan<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
<strong>Ma dostê yewbînan îme/ê</strong>.</td>
<td style="height:101px; width:227px">yemno, yemnon, yewbîna, yewbîno, yewbînon, yewbînû, yewbînûn, yewna, yewnan, yobîno, yobînon, yobînû, yobînûn, yowbîno, yowbînû,<br>
yowna, yownan, yownû, yownûn, cîmna, jewmbîna, jobînan</td>
<td style="height:101px; width:198px">birbiri<br>
&nbsp;<br>
&nbsp;<br>
&nbsp;<br>
<strong>Biz birbirimizin dostuyuz</strong>.</td>
</tr>
</tbody>
</table>
&nbsp;

<h1>DİPNOTLAR</h1><br>

<ol>
<li>Çekuya “<strong>lawur</strong>e”, nameyê şaristanê “Lahor”î ra yena.</li>
<li><strong>kewe </strong>(kêwe, kewîye, kewo, kiho, kuhe, kûhe): Tayê fekan de yeno manaya “yeşil”ê tirkî. (3)<strong>zerd</strong>: Tayê fekan de yeno manaya “altın sarısı”yê tirkî.</li>
</ol>
(4)<strong>zergûn</strong>: Tayê fekan de yeno manaya “hewzê zerdinî” yan zî “zerdbîyaye”yî (bi tirkî “sarartı”). (5)Malmîsanij, “Di Kurdî de Guherîna Dengên R û L”, Çira: kovara kulturî, hejmar: 7, 1996, Stokholm, r. 50-55 (6)Çem, Mûnzûr, Hotay Serra Usifê Qurzkizî, Weşanên Roja Nû, Stokholm, 1992, r. 83, 96, 104, 205, 209 (7)Kilîseyij, Serdar; “Dewda Kilîseyî ra Çend Teneyî Deyîr û Estanekî”, Vate, Hûmare: 64, r. 37

<ol>
<li>Malmîsanij; “Kirmanckîya (Zazakîya) Motkî û Hewêlî ra”, <em>Vate: </em>Kovara Kulturî, Hûmar: 6, Skärholmen-Sweden, Payîz-Zimistan 1998, r. 23Hewramkîya Nowsûdî de: “[Min] weş <strong>ena</strong>. (Ben iyiyim.)“Min xo şew û ro arezûy to-m <strong>en</strong>” (Mewlewî Tawegozî) (Ben gece gündüz seni arzulayanım.)</li>
<li>Qetekonij, M. Mamet; Tayê Xususîyetê Fekê Şankuşî, Weşanxaneyê Roşna, Dîyarbekir, 2018, r. 29</li>
<li>Azad Dilêr, Dîyarbekiro Xopan o, Almanya, 1986, r. 4 (11)Qetekonij, r. 29</li>
<li>Hewramkî (Gorankî) de:</li>
</ol>
“Çî kûrey ayir min ne coşan<strong>an </strong>/ Ce sîney Seydî sîyaw poşen<strong>an</strong>.” (Seydî Hewramî, Vate, Hûmare: 8, r. 35-36) “Bu ateş ocağından ben coşuyorum (kaynıyorum) / Seydi’nin sinesinden karalara bürünüyorum.”

<ol>
<li>Hilkecikî, Memê (Arêkerdox); “Weyki w Huwt Birî”, Vate: Kovara Kulturî, Hûmare: 7, Skärholmen-Sweden, Hamnan 1999, r.</li>
</ol>
43

<ol>
<li>Qetekonij, r. 37</li>
<li>Qetekonij, r. 37</li>
<li>Malmîsanij; Mi Şêx Seîd Dî, Weşanxaneyê Vateyî, Îstanbul, 2009, r. 108 (17)Kilîseyij, r. 49</li>
</ol>

<ol>
<li>Qetekonij, s. 37</li>
<li>Qetekonij, s. 34</li>
<li>Qetekonij, s. 34</li>
</ol>
Çime: vate.com.tr
    ***
`;
